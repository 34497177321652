import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

const ConfirmDialog = ({
  text = 'Do it?',
  onConfirm,
  confirmText = 'Yes',
  abortText = 'No',
  isOpen,
  onClose,
  content,
  confirmButtonProps = {},
  abortButtonProps = {},
  titleText,
}) => {
  // TODO: MPAUC-94: This doesn't seem to be stopping propagation of clicks
  // outside of the modal dialog.  Not sure how to fix that....
  const handleClose = e => {
    e.stopPropagation();
    onClose();
  };

  const handleConfirm = e => {
    e.stopPropagation();
    onConfirm();
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      {titleText && <DialogTitle>{titleText}</DialogTitle>}
      <DialogContent>
        {text && text !== '' && <DialogContentText>{text}</DialogContentText>}
        {content}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" {...abortButtonProps}>
          {abortText}
        </Button>
        <Button
          onClick={handleConfirm}
          color="primary"
          autoFocus
          {...confirmButtonProps}
        >
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
