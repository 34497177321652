import { isEmpty, startCase } from 'lodash';
// External
import moment from 'moment';
import React, { useState } from 'react';
import gql from 'graphql-tag';

// Material UI
import {
  CardContent,
  CardHeader,
  FormControlLabel,
  Grid,
  IconButton,
  Menu,
  Typography,
} from '@mui/material';
import { green } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import { Email, MoreVert, Smartphone } from '@mui/icons-material';

import UpdateCardStatus from '../CustomerDetails/GoCard/UpdateCardStatus';

const useStyles = makeStyles(theme => ({
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  identity: { backgroundColor: green[400] },
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  avatarCell: {
    whiteSpace: 'pre-line',
    width: '1rem',
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '10px',
    paddingLeft: '16px',
    paddingBottom: '2px',
    paddingTop: '2px',
  },
}));

const AdvancedSearchResultMobile = ({ fields, customer }) => {
  const {
    city,
    date_created,
    description,
    email_addresses,
    full_name,
    phone_numbers,
    province,
    urn,
  } = fields;
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  // Format location
  const location =
    !isEmpty(city) && !isEmpty(province) ? `${city[0]}, ${province[0]}` : '';
  const date = !isEmpty(date_created)
    ? moment(date_created[0]).format('LL')
    : 'N/A';

  const goCardUrn =
    fields?.record_type?.[0] === 'gocard'
      ? urn
          .filter(r => r.startsWith('goauto.gocard:gocard:'))
          .map(x => x.split(':')[2])
          .map(id => ({ id }))
      : null;

  const goCardRecord = customer?.gocards?.filter(
    goCard => goCard?.id === parseInt(goCardUrn?.[0]?.id, 10),
  );

  const menuItemHandler = event => {
    event.stopPropagation();
    handleClose();
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <CardHeader
        title={startCase(full_name?.[0] ?? 'Unknown Customer')}
        subheader={location}
        style={{ paddingBottom: '5px' }}
      ></CardHeader>
      <CardContent className={classes.cardContent}>
        <Grid container>
          {!isEmpty(phone_numbers) && (
            <Grid item xs={4}>
              <Typography style={{ fontSize: '0.75rem' }}>
                <Smartphone fontSize="small" /> {phone_numbers[0]}
              </Typography>
            </Grid>
          )}
          {!isEmpty(email_addresses) && (
            <Grid item xs={4}>
              <Typography style={{ fontSize: '0.75rem' }}>
                <Email fontSize="small" /> {email_addresses[0]}
              </Typography>
            </Grid>
          )}
        </Grid>
      </CardContent>
      <CardContent
        className={classes.cardContent}
        style={{ paddingBottom: '5px' }}
      >
        <Grid container alignItems="center">
          <Grid item xs={12}>
            Description: {description?.[0] ?? 'N/A'}
          </Grid>
          <Grid item xs={fields?.record_type?.[0] === 'gocard' ? 2 : 12}>
            Record Type: {startCase(fields?.record_type?.[0] ?? 'Unknown Type')}
          </Grid>
          {/* GO CARD */}
          {fields?.record_type?.[0] === 'gocard' && (
            <Grid container item xs={6} alignItems="center">
              <Grid item xs={3}>
                <b>Card Status: </b>
                {goCardRecord?.[0].accountV2?.active ? 'Active' : 'Inactive'}
              </Grid>
              {goCardRecord?.[0].accountV2?.permissions?.manageAccounts && (
                <Grid item xs={1}>
                  <FormControlLabel
                    onClick={event => event.stopPropagation()}
                    onFocus={event => event.stopPropagation()}
                    control={
                      <IconButton size="small" onClick={handleClick}>
                        <MoreVert />
                      </IconButton>
                    }
                  />
                  <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={event => menuItemHandler(event)}
                  >
                    <UpdateCardStatus
                      active={goCardRecord[0]?.accountV2?.active}
                      cardId={goCardRecord[0]?.accountV2?.cardId}
                      setAnchorEl={setAnchorEl}
                    />
                  </Menu>
                </Grid>
              )}
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography style={{ fontSize: '0.6rem' }} align="right">
              Created: {date}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </>
  );
};

AdvancedSearchResultMobile.fragments = {
  fields: gql`
    fragment AdvancedSearchResultMobileFragment on CustomerSearchHitFields {
      urn
      city
      date_created
      description
      email_addresses
      full_name
      phone_numbers
      province
      record_type
    }
  `,

  customer: gql`
    fragment AdvancedSearchResultMobileCustomer on Customer {
      gocards {
        id
        accountV2 {
          active
          cardId
          permissions {
            manageAccounts
          }
        }
      }
    }
  `,
};

export default AdvancedSearchResultMobile;
