import React, { useState } from 'react';
import gql from 'graphql-tag';

import { TextField } from '@mui/material';

import { useSnackMutation } from 'utils/useSnackMutation';
import { LoadingBackdrop } from 'components/MaterialUI';

const CREATE_MODEL = gql`
  mutation createModel($name: String!, $makeId: Int!, $npvTypeId: Int!) {
    npv {
      createModel(name: $name, makeId: $makeId, npvTypeId: $npvTypeId) {
        id
        name
      }
    }
  }
`;

export default function CreateModel({
  makeId,
  npvTypeId,
  formMethods,
  onCompleted = () => {},
  ...rest
}) {
  const [createModelName, setCreateModelName] = useState('');

  const [createNpvModel, { loading }] = useSnackMutation(
    CREATE_MODEL,
    'Model created successfully!',
    e => `Error creating model: ${e}`,
    data => {
      formMethods.setValue('modelId', data.npv.createModel.id);
      setCreateModelName('');
      onCompleted(data);
    },
  );

  return (
    <>
      <LoadingBackdrop open={loading}>Loading...</LoadingBackdrop>
      <TextField
        disabled={!makeId || !npvTypeId || loading}
        fullWidth
        helperText="After entering a model, press Enter to create it."
        label="Create Model"
        onChange={e => setCreateModelName(e.target.value)}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            e.preventDefault();
            if (createModelName?.trim()) {
              createNpvModel({
                variables: {
                  name: createModelName,
                  makeId,
                  npvTypeId,
                },
              });
            }
          }
        }}
        value={createModelName}
        variant="outlined"
        {...rest}
      />
    </>
  );
}
