import gql from 'graphql-tag';
import React, { useState } from 'react';

// Material UI
import { Grid, IconButton, Link, Typography } from '@mui/material';
import { yellow } from '@mui/material/colors';
// MUI Icons
import { Close, MoreVert, OpenInNew, Star } from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';

// Internal
import { useUserContext } from 'components/MaterialUI/UserContext';

import ConversationActions from './ConversationActions/ConversationActions';

const CONVERSATION_FRAGMENT = gql`
  fragment ConversationHeaderConversation on Conversation {
    stars {
      id
      conversation_id
      username
    }
    operator_username
    operator_user {
      display_name
      username
    }
    archived
    has_unread_messages
    customer_name
    customer {
      _id
    }
  }
`;

const useStyles = makeStyles(theme => ({
  appBarText: {
    textAlign: 'center',
    color: 'white',
  },
  customerInfo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconButton: {
    color: 'white',
    backgroundColor: 'primary',
  },
  identityLink: {
    '&:hover': {
      cursor: 'pointer',
    },
    fontWeight: 'bold',
  },
}));

const ConversationHeaderDesktop = ({
  customerName,
  customerPhone,
  gatewayId,
  refetch,
  conversation,
  setSelectedChatId,
  conversationQueryVariables,
}) => {
  const classes = useStyles();
  const { currentUser } = useUserContext();

  const [menuAnchor, setMenuAnchor] = useState(null);
  const handleMenuClick = e => setMenuAnchor(e.currentTarget);

  const conversationId = parseInt(conversation.id, 10);

  const conversationStarred = _conversation =>
    _conversation.stars
      .filter(x => x.username === currentUser.username)
      .some(x => x.conversation_id === conversationId);

  const isStarred = conversationStarred(conversation);

  const convoCustomerName = conversation.customer_name ?? customerName;

  const customerProfileURL = conversation?.customer?._id
    ? `/customers/${conversation.customer._id}/details`
    : null;

  return (
    <>
      <Grid container direct="row" alignItems="center">
        <Grid item xs={1}>
          {isStarred && (
            <Star style={{ color: yellow[700], marginRight: '15px' }} />
          )}
        </Grid>

        <Grid container item xs={10} className={classes.appBarText}>
          <Grid item xs={12} className={classes.customerInfo}>
            <Typography variant={'body1'} style={{ marginRight: '10px' }}>
              <b>{convoCustomerName}</b>
            </Typography>
            <Typography variant={'body2'}>({customerPhone})</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'body2'}>
              CRM Match:{'  '}
              {customerProfileURL ? (
                <Link
                  onClick={() => window.open(customerProfileURL, '_blank')}
                  color="inherit"
                  className={classes.identityLink}
                >
                  {customerName}
                </Link>
              ) : (
                'None'
              )}
            </Typography>
          </Grid>
        </Grid>
        {/* ACTION ICONS */}
        <Grid
          item
          xs={1}
          style={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          <IconButton
            className={classes.iconButton}
            onClick={handleMenuClick}
            size="large"
          >
            <MoreVert fontSize={'medium'} />
          </IconButton>
          <IconButton
            className={classes.iconButton}
            onClick={() =>
              window.open(
                `/sms/gateways/${gatewayId}/${conversationId}`,
                '_blank',
              )
            }
            size="large"
          >
            <OpenInNew fontSize={'medium'} />
          </IconButton>
          <IconButton
            className={classes.iconButton}
            onClick={() => setSelectedChatId(0)}
            size="large"
          >
            <Close fontSize={'medium'} />
          </IconButton>
          <ConversationActions
            conversationQueryVariables={conversationQueryVariables}
            gatewayId={gatewayId}
            refetch={refetch}
            conversation={conversation}
            menuAnchor={menuAnchor}
            setMenuAnchor={setMenuAnchor}
            setSelectedChatId={setSelectedChatId}
          />
        </Grid>
      </Grid>
    </>
  );
};

ConversationHeaderDesktop.fragments = {
  conversation: CONVERSATION_FRAGMENT,
};

export default ConversationHeaderDesktop;
