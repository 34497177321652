import React from 'react';

import goLogo from '../assets/go.svg';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    marginBottom: '1rem',
  },
  logo: {
    height: '100px',
    width: '100px',
  },
  title: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
}));

const GoHeader = ({ title, subtitle }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.logo}>
        <img src={goLogo} alt="Go Auto Logo" />
      </Box>
      <Box className={classes.title}>
        <Box component="h1" fontSize="1.75rem" color="primary.main">
          {title || 'Market Platform'}
        </Box>
        {subtitle !== null && (
          <Box component="h2" fontSize="1rem" color="text.secondary">
            {subtitle || 'MOBILE'}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default GoHeader;
