import React from 'react';

import { Box, Chip } from '@mui/material';

/* Material UI */
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  chipBox: {
    paddingRight: '5px',
    paddingBottom: '5px',
  },
}));

const ServiceFacetChips = ({ assigneeFilter, setAssigneeFilter }) => {
  const classes = useStyles();

  return (
    <Box display="flex" flexWrap="wrap">
      {assigneeFilter.map(assignee => (
        <Box component="span" key={assignee} className={classes.chipBox}>
          <Chip
            label={assignee || 'Unknown'}
            value={assignee}
            onDelete={() =>
              setAssigneeFilter(prev => prev.filter(a => a !== assignee))
            }
          />
        </Box>
      ))}
    </Box>
  );
};

export default ServiceFacetChips;
