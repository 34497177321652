import React, { useRef, useState } from 'react';
import Drawer from '@mui/material/Drawer';
import MainNav from './MainNav';
import GoHeader from '../../../components/GoHeader';
import clsx from 'clsx';

import NavBar from './NavBar';
import { makeStyles } from '@mui/styles';
import { useMediaQuery, useTheme } from '@mui/material';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 56,
    height: '100vh',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64,
    },
  },
  shiftContent: {
    paddingLeft: drawerWidth,
  },
  content: {
    height: '100%',
    width: '100%',
  },
  contentScrollbarPad: {
    height: '100%',
    width: '100%',
  },
  drawerScrollFix: {
    [theme.breakpoints.up('lg')]: {
      '&> .MuiDrawer-paper': {
        maxWidth: drawerWidth,
        zIndex: '1100',
        overflowY: 'auto',
        overflowX: 'hidden',
        '&::-webkit-scrollbar': {
          width: '8px',
        },

        '&::-webkit-scrollbar-track': {
          background: 'none',
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#ddd',
          borderRadius: '4px',
        },
      },
    },
  },
}));

const Layout = props => {
  const { title, children } = props;
  const classes = useStyles();
  const navDivRef = useRef(null);

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  });
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: isDesktop,
      })}
    >
      <NavBar onMenuClick={handleDrawerOpen} title={title} />
      <Drawer
        variant={isDesktop ? 'permanent' : 'temporary'}
        open={isDesktop || drawerOpen}
        onClose={handleDrawerClose}
        className={classes.drawerScrollFix}
        sx={{ zIndex: 1100 }}
      >
        <div style={{ width: drawerWidth, paddingTop: 64 }} ref={navDivRef}>
          <GoHeader title="Market" subtitle={null} />
          <MainNav />
        </div>
      </Drawer>

      <main className={classes.content}>{children}</main>
    </div>
  );
};

export default Layout;
