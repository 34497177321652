import SyndicationDesktop from './SyndicationDesktop';
import SyndicationMobile from './SyndicationMobile';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import Hidden from '@mui/material/Hidden';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import DealerPicker from 'components/MaterialUI/DealerPicker';
import gql from 'graphql-tag';
import { uniqBy, unionBy, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';

const DEALER_DETAILS_QUERY = gql`
  query dealerQuery($dealerId: Int!) {
    inventory {
      getDealer(id: $dealerId) {
        id
        import_overwrite_stock_type
        new_import_overwrite_cost
        new_import_overwrite_description
        new_import_overwrite_photos
        new_import_overwrite_price
        new_import_overwrite_stock_status
        used_import_overwrite_cost
        used_import_overwrite_description
        used_import_overwrite_photos
        used_import_overwrite_price
        used_import_overwrite_stock_status
      }
    }
  }
`;

const EXPORTERS_QUERY = gql`
  query exportersQuery {
    inventory {
      getExporters {
        id
        exporter_name
      }
    }
  }
`;

const LATEST_IMPORT = gql`
  query latestImport($dealerId: JSON, $name: JSON) {
    inventory {
      getImportLogs(
        filters: [
          { model: "ImportLog", field: "dealer_id", op: "eq", value: $dealerId }
          { model: "ImportLog", field: "name", op: "eq", value: $name }
        ]
        page: 1
        page_size: 1
        sort: [
          { model: "ImportLog", field: "date_ended_at", direction: "desc" }
        ]
      ) {
        results {
          id
          updated_count
          date_ended_at
          name
        }
      }
    }
  }
`;

const LATEST_EXPORT = gql`
  query latestExport(
    $dealerId: JSON
    $names: JSON
    $page: Int
    $page_size: Int
  ) {
    inventory {
      getExportLogs(
        filters: [
          { model: "ExportLog", field: "dealer_id", op: "eq", value: $dealerId }
          {
            model: "ExportLog"
            field: "exporter_name"
            op: "in"
            value: $names
          }
          { model: "ExportLog", field: "is_active", op: "eq", value: true }
        ]
        page: $page
        page_size: $page_size
        sort: [
          { model: "ExportLog", field: "date_ended_at", direction: "desc" }
        ]
      ) {
        results {
          id
          date_ended_at
          is_active
          is_active_both
          is_active_new
          is_active_used
          exporter_name
          frequency
        }
        pagination {
          page
        }
      }
    }
  }
`;

const NEW_IMPORT_FIELDS = [
  'new_import_overwrite_cost',
  'new_import_overwrite_description',
  'new_import_overwrite_photos',
  'new_import_overwrite_price',
  'new_import_overwrite_stock_status',
];

const USED_IMPORT_FIELDS = [
  'used_import_overwrite_cost',
  'used_import_overwrite_description',
  'used_import_overwrite_photos',
  'used_import_overwrite_price',
  'used_import_overwrite_stock_status',
];

const SYNDICATION_MAPPER = {
  bulk_dart: ['CDK', 'Every 3 hours'],
  carguru: ['CarGuru', 'Nightly'],
  carfax: ['CarFax', 'Twice a day'],
  car_media: ['Car Media', 'Twice a day'],
  used_trader: ['Trader', 'Nightly'],
  new_trader: ['Trader', 'Nightly'],
  trader: ['Trader', 'Nightly'],
  strathcom: ['Strathcom', 'Every 4 hours'],
  garystock: ['GaryStock', 'Nightly'],
  kijiji: ['Kijiji', 'Nightly'],
  vauto: ['vAuto', 'Nightly'],
  carpics: ['CarPics', 'Nightly'],
  motoinsight: ['MotoInsight', 'Nightly'],
  dealer_dot_com: ['Dealer.com', 'Nightly'],
  d2c: ['D2C', 'Nightly'],
  serti: ['SertiImporter', 'Nightly'],
};

const IMPORT_CLASSES = {
  bulk_dart: 'BulkDARTImporter',
  car_media: 'CarMediaImporter',
  carpics: 'CarPicsImporter',
  dealer_dot_com: 'DealerDotComImporter',
  delta_dart: 'DeltaDARTImporter',
  new_trader: 'NewTraderImporter',
  pbs: 'PBSImporter',
  serti: 'SertiImporter',
  strathcom: 'StrathcomImporter',
  used_trader: 'UsedTraderImporter',
  vauto: 'VAutoImporter',
};

const Syndication = () => {
  const mobile = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const { dealerId } = useDealerContext();
  const dealerDetailsQuery = useQuery(DEALER_DETAILS_QUERY, {
    variables: { dealerId },
  });
  const exportersQuery = useQuery(EXPORTERS_QUERY);

  const [newImportNames, setNewImportNames] = useState([]);
  const [usedImportNames, setUsedImportNames] = useState([]);
  const [allImportNames, setAllImportNames] = useState([]);
  const [imports, setImports] = useState([]);
  const [exports, setExports] = useState([]);

  const [latestImport, latestImportQuery] = useLazyQuery(LATEST_IMPORT, {
    onCompleted: r => {
      const results = r?.inventory.getImportLogs.results || [];
      if (results.length > 0) {
        setImports(prev =>
          prev.map(_import =>
            results[0].name === IMPORT_CLASSES[_import.snakeName]
              ? {
                  ..._import,
                  updated_count: results[0].updated_count,
                  date_ended_at: results[0].date_ended_at,
                  gotData: true,
                }
              : _import,
          ),
        );
      } else {
        const name = latestImportQuery.variables.name;
        setImports(prev =>
          prev.map(_import =>
            name === IMPORT_CLASSES[_import.snakeName]
              ? {
                  ..._import,
                  gotData: true,
                }
              : _import,
          ),
        );
      }
    },
  });

  const [latestExport, { loading, data: latestExportData }] =
    useLazyQuery(LATEST_EXPORT);

  useEffect(() => {
    setNewImportNames(
      Array.from(
        new Set(
          dealerDetailsQuery.data
            ? NEW_IMPORT_FIELDS.map(
                x => dealerDetailsQuery.data.inventory.getDealer[x],
              ).filter(x => x)
            : [],
        ),
      ),
    );

    setUsedImportNames(
      Array.from(
        new Set(
          dealerDetailsQuery.data
            ? USED_IMPORT_FIELDS.map(
                x => dealerDetailsQuery.data.inventory.getDealer[x],
              ).filter(x => x)
            : [],
        ),
      ),
    );
  }, [dealerDetailsQuery]);

  useEffect(() => {
    setAllImportNames(
      Array.from(new Set([...usedImportNames, ...newImportNames])).sort(
        (a, b) => a.localeCompare(b),
      ),
    );
  }, [newImportNames, usedImportNames]);

  useEffect(() => {
    setImports(
      allImportNames.map(name => ({
        used: usedImportNames.includes(name),
        new: newImportNames.includes(name),
        name: SYNDICATION_MAPPER[name]?.[0] ?? name,
        frequency: SYNDICATION_MAPPER[name]?.[1] ?? 'UNKNOWN',
        snakeName: name,
        gotData: false,
        updated_count: 0,
        date_ended_at: null,
      })),
    );
  }, [allImportNames, newImportNames, usedImportNames]);

  useEffect(() => {
    if (!latestImportQuery.loading)
      imports
        .filter(({ gotData }) => !gotData)
        .forEach(({ snakeName }) =>
          latestImport({
            variables: { dealerId, name: IMPORT_CLASSES[snakeName] },
          }),
        );
  }, [dealerId, imports, latestImport, latestImportQuery]);

  useEffect(() => {
    if (exportersQuery.data)
      setExports(
        uniqBy(
          exportersQuery.data.inventory.getExporters,
          x => x.exporter_name,
        ).map(x => ({
          name: SYNDICATION_MAPPER[x.exporter_name]?.[0] ?? x.exporter_name,
          frequency: SYNDICATION_MAPPER[x.exporter_name]?.[1] ?? 'UNKNOWN',
          snakeName: x.exporter_name,
          gotData: false,
          date_ended_at: null,
          is_active: false,
          new: false,
          used: false,
        })),
      );
  }, [exportersQuery, dealerId]);

  useEffect(() => {
    if (!loading && !isEmpty(exports)) {
      const snakeNames = exports
        .filter(({ gotData }) => !gotData)
        .map(({ snakeName }) => snakeName);
      latestExport({
        variables: { dealerId, names: snakeNames, page: 1, page_size: 25 },
      });
    }
  }, [dealerId, exports, latestExport, loading]);

  useEffect(() => {
    if (latestExportData) {
      const results = latestExportData.inventory.getExportLogs.results;
      if (results.length === 0)
        setExports(prev =>
          prev.map(_export => ({ ..._export, gotData: true })),
        );
      else {
        const retrievedResults = results.map(result => ({
          ...result,
          gotData: true,
          is_active: result.is_active,
          snakeName: result.exporter_name,
          used: result.is_active_both || result.is_active_used,
          new: result.is_active_both || result.is_active_new,
          frequency: result.frequency || 'UNKNOWN',
          name:
            SYNDICATION_MAPPER[result.exporter_name]?.[0] ??
            result.exporter_name,
        }));

        setExports(prev => unionBy(retrievedResults, prev, 'snakeName'));
      }
    }
  }, [latestExportData]);

  return (
    <>
      <div style={{ padding: '1rem 0 0 1rem' }}>
        <DealerPicker />
      </div>
      <Typography style={{ padding: '1rem' }} variant="h5">
        Inventory Syndication
      </Typography>
      <div style={{ padding: '1rem' }}>
        <Paper style={{ width: `${mobile ? '80%' : '100%'}`, padding: '1rem' }}>
          <Hidden smDown>
            <div>
              <SyndicationDesktop
                imports={imports}
                exports={exports
                  .filter(x => x.new || x.used)
                  .sort(({ name: a }, { name: b }) => a.localeCompare(b))}
              />
            </div>
          </Hidden>
          <Hidden smUp>
            <div>
              <SyndicationMobile
                imports={imports}
                exports={exports
                  .filter(x => x.new || x.used)
                  .sort(({ name: a }, { name: b }) => a.localeCompare(b))}
              />
            </div>
          </Hidden>
        </Paper>
      </div>
    </>
  );
};

export default Syndication;
