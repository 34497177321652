import React from 'react';

import TextField from '@mui/material/TextField';
import { InputAdornment } from '@mui/material';

const PriceFilterField = props => {
  const cleanupInput = e => {
    // Prevent '-'
    if (e.key === '-') e.preventDefault();
  };

  return (
    <TextField
      InputLabelProps={{ shrink: true }}
      InputProps={{
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
      }}
      inputProps={{ min: '0' }}
      onKeyDown={e => cleanupInput(e)}
      type="number"
      {...props}
    />
  );
};

export default PriceFilterField;
