/* External */
import { useMutation, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

/* Material UI */
import {
  Box,
  Button,
  Grid,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  Edit as EditIcon,
  GetApp,
  Visibility as VisibilityIcon,
} from '@mui/icons-material';
import { DateTimePicker } from '@mui/x-date-pickers';
import ErrorDisplay from 'components/MaterialUI/ErrorDisplay';
import Loading from 'components/MaterialUI/Loading';
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';

/* Internal */
import BulkSendStatusChip from './BulkSendStatusChip';
import BulkSendAlerts from './BulkSendAlerts';
import BulkSendCountCards from './BulkSendCountCards';
import {
  UNEDITABLE_STATUSES,
  CANCELLABLE_STATUSES,
  CONVERSATION_ACTIVITY,
} from './const.js';
import {
  EDIT_BULK_SEND,
  SCHEDULE_BULK_SEND,
  STOP_BULK_SEND,
} from './BulkSendsMutations';

const GATEWAY_QUERY = gql`
  query gatewayQuery($gatewayId: Int!) {
    sms {
      gateway(id: $gatewayId) {
        id
        name
        phone_number
      }
      gatewayUsers(id: $gatewayId) {
        display_name
        username
      }
    }
  }
`;

const BULK_SEND_QUERY = gql`
  query bulkSendQuery($bulkSendId: Int!) {
    bulkSends {
      getBulkSend(bulksendId: $bulkSendId) {
        messages {
          id
          body
        }
        name
        statusLabel
        scheduledStart
        id
        discardedRows
        deliveredCount
        deleted
        dateStarted
        dateRevoked
        dateQueued
        dateModified
        dateFinished
        dateCreated
        createdBy
        listUrl
        template
        inactiveDays
        ...BulkSendCountCardsBulkSend
        ...BulkSendAlertsBulkSend
      }
    }
  }
  ${BulkSendAlerts.fragments.bulkSend}
  ${BulkSendCountCards.fragments.bulkSend}
`;

const useStyles = makeStyles(theme => ({
  phoneNumber: {
    fontWeight: 400,
    color: theme.colours.greys.greytext1,
    marginLeft: '10px',
  },
  h6: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
  },
  editButton: {
    color: theme.colours.add,
    border: `1px solid ${theme.colours.add}`,
    backgroundColor: 'white',
  },
  detailsHeader: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  saveButton: {
    marginLeft: '15px',
    backgroundColor: theme.colours.add,
    color: 'white',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginTop: '15px',
    },
  },
  sendButton: {
    marginLeft: '15px',
    backgroundColor: theme.actions.confirm.backgroundColor,
    color: 'white',
    minWidth: '150px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginTop: '15px',
    },
  },
  cancelButton: {
    marginLeft: '15px',
    backgroundColor: theme.actions.danger.backgroundColor,
    color: 'white',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginTop: '15px',
    },
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '20px',
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
}));

const BulkSendDetailView = ({ bulkSendId, gatewayId }) => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const { enqueueSnackbar } = useSnackbar();

  const [placeholder, setPlaceholder] = useState('');
  const [inactiveDays, setInactiveDays] = useState(1);
  const [sendScheduledDate, setSendScheduledDate] = useState(null);
  const [placeholderList, setPlaceholderList] = useState([]);
  const [editMessageState, setEditMessageState] = useState(false);
  const [messagePreview, setMessagePreview] = useState('');
  const [messageTemplate, setMessageTemplate] = useState('');
  const [csvFileData, setCsvFileData] = useState([]);

  const { loading, data, error } = useQuery(GATEWAY_QUERY, {
    variables: {
      gatewayId,
    },
  });

  const {
    loading: bulkSendLoading,
    data: bulkSendData,
    error: bulkSendError,
    refetch,
  } = useQuery(BULK_SEND_QUERY, {
    variables: {
      bulkSendId,
    },
    onCompleted: () => {
      setMessagePreview(
        bulkSendData?.bulkSends?.getBulkSend?.messages[0]?.body,
      );
      setMessageTemplate(bulkSendData?.bulkSends?.getBulkSend?.template);
      setSendScheduledDate(
        bulkSendData?.bulkSends?.getBulkSend?.scheduledStart,
      );
      setInactiveDays(bulkSendData?.bulkSends?.getBulkSend?.inactiveDays ?? 1);
    },
  });

  const [editBulkSend, { loading: editBulkSendLoading }] = useMutation(
    EDIT_BULK_SEND,
    {
      onCompleted: () => {
        enqueueSnackbar('Bulk send saved successfully!', {
          variant: 'success',
        });
        refetch();
      },
      onError: err => {
        enqueueSnackbar(`Unable to save bulk send. Error: ${err.message}`, {
          variant: 'error',
        });
      },
    },
  );

  const [scheduleBulkSend, { loading: scheduleBulkSendLoading }] = useMutation(
    SCHEDULE_BULK_SEND,
    {
      onCompleted: () => {
        enqueueSnackbar('Bulk send scheduled!', {
          variant: 'success',
        });
        refetch();
      },
      onError: err => {
        enqueueSnackbar(`Unable to schedule bulk send. Error: ${err.message}`, {
          variant: 'error',
        });
      },
    },
  );

  const [stopBulkSend, { loading: stopBulkSendLoading }] = useMutation(
    STOP_BULK_SEND,
    {
      onCompleted: () => {
        enqueueSnackbar('Bulk send stopped!', {
          variant: 'success',
        });
        refetch();
      },
      onError: err => {
        enqueueSnackbar(`Unable to stop bulk send. Error: ${err.message}`, {
          variant: 'error',
        });
      },
    },
  );

  const fileURL = bulkSendData?.bulkSends?.getBulkSend?.listUrl ?? '';

  // check if there is a fileURL from S3
  // fetch the file and set the response to text/a string
  // replace the carriage return and split the string into an array
  // grab the first element of the array (the header row) and split it into an array
  // voila! placeholder data
  // also need to grab the first row of the file data and split it into an array
  useEffect(() => {
    if (fileURL) {
      fetch(fileURL)
        .then(response => {
          response.text().then(text => {
            const fileData = text.replace('\r', '').split('\n');
            setPlaceholderList(
              text.replace('\r', '\n').split('\n')[0].split(','),
            );
            setCsvFileData(fileData[1].split(','));
          });
        })
        .catch(err => {
          enqueueSnackbar(`Unable to load CSV file data. Error: ${err}`, {
            variant: 'error',
          });
        });
    }
  }, [fileURL, setPlaceholderList, enqueueSnackbar]);

  if (loading || bulkSendLoading) {
    return <Loading />;
  }

  if (error || bulkSendError) {
    return <ErrorDisplay error={error} action="Loading Bulk Send Data" />;
  }

  const bulkSend = bulkSendData.bulkSends.getBulkSend;

  const scheduledDate = bulkSend.scheduledDate
    ? moment(bulkSend.scheduledDate).format('MMM Do YYYY, h:mm:ss a')
    : ' —';

  const startedOn = bulkSend.dateStarted
    ? moment(bulkSend.dateStarted).format('MMM Do YYYY, h:mm:ss a')
    : ' —';

  const finishedOn = bulkSend.dateFinished
    ? moment(bulkSend.dateFinished).format('MMM Do YYYY, h:mm:ss a')
    : ' —';

  const gatewayUsers = data.sms.gatewayUsers ?? [];

  const handleAddPlaceholder = event => {
    const placeholder = event.target.value;
    const index = placeholderList.indexOf(placeholder);
    setPlaceholder(placeholder);
    setMessageTemplate(`${messageTemplate} {${placeholder}}`);
    // in order to view the "value" of the placeholder, we need to
    // get the index of the placeholder in the placeholderList and apply that to
    // the csvFileData array (index == the file "column")
    setMessagePreview(`${messagePreview} ${csvFileData[index]}`);
  };

  const handleMessageTextChange = event => {
    setMessageTemplate(event.target.value);

    let previewMsg = event.target.value;
    // first row of "data" from the CSV file
    const row = csvFileData;

    placeholderList.forEach((placeholder, index) => {
      previewMsg = previewMsg.replace(`{${placeholder}}`, row[index]);
    });
    setMessagePreview(previewMsg);
  };

  const handleSaveBulkSend = () => {
    editBulkSend({
      variables: {
        bulkSendId,
        template: messageTemplate,
        inactiveDays,
      },
    });
  };

  const handleScheduleBulkSend = () => {
    scheduleBulkSend({
      variables: {
        bulkSendId,
        data: {
          inactiveDays,
          scheduledDate: sendScheduledDate,
        },
      },
    });
  };

  const handleStopBulkSend = () => {
    stopBulkSend({
      variables: {
        bulkSendId,
      },
    });
  };

  const uneditable = UNEDITABLE_STATUSES.includes(bulkSend.statusLabel);
  const cancellable = CANCELLABLE_STATUSES.includes(bulkSend.statusLabel);

  return (
    <Box margin="40px 20px" display="flex" flexDirection="column">
      <LoadingBackdrop
        open={
          editBulkSendLoading || scheduleBulkSendLoading || stopBulkSendLoading
        }
      >
        Saving...
      </LoadingBackdrop>
      <Box
        display="flex"
        alignItems="flex-end"
        mb="30px"
        flexWrap="wrap"
        flexGrow={1}
      >
        <Typography variant="h5" style={{ fontWeight: 'bold' }}>
          Bulk Send Detail View - {data?.sms.gateway.name}
        </Typography>
        <Typography variant="body1" className={classes.phoneNumber}>
          {data?.sms.gateway.phone_number}
        </Typography>
        <BulkSendAlerts bulkSend={bulkSend} />
      </Box>
      <Grid container spacing={1} style={{ flexGrow: 1 }}>
        <Grid item xs={12} className={classes.detailsHeader}>
          <Box mr="30px">
            <Typography variant="h6" className={classes.h6}>
              {bulkSend.name}
            </Typography>

            <Typography variant="body2">
              {`Created on
              ${moment(bulkSend.dateCreated).format(
                'MMM Do YYYY, h:mm:ss a',
              )} by
              ${
                gatewayUsers.find(x => x.username === bulkSend.createdBy)
                  ?.display_name ?? bulkSend.createdBy
              }`}
            </Typography>
          </Box>
          <BulkSendStatusChip status={bulkSend.statusLabel} />
        </Grid>
        <Grid container item xs={12} style={{ marginBottom: '5px' }}>
          <Grid item xs={12} md={2}>
            <Typography variant={mobile ? 'body2' : 'body1'}>
              <b>Scheduled For: </b> <br />
              {scheduledDate}
            </Typography>
          </Grid>
          <Grid item xs={12} md={2}>
            <Typography variant={mobile ? 'body2' : 'body1'}>
              <b>Started On: </b> <br />
              {startedOn}
            </Typography>
          </Grid>
          <Grid item xs={12} md={2}>
            <Typography variant={mobile ? 'body2' : 'body1'}>
              <b>Finished On: </b> <br />
              {finishedOn}
            </Typography>
          </Grid>
        </Grid>
        <BulkSendCountCards bulkSend={bulkSend} gatewayId={gatewayId} />
      </Grid>
      <Grid container spacing={2} style={{ marginTop: '20px', flexGrow: 2 }}>
        <Grid item xs={12} md={8} style={{ marginRight: '5px' }}>
          {/* change message preview to template view if user is editing the message */}
          <TextField
            label="Message Preview"
            value={editMessageState ? messageTemplate : messagePreview}
            onChange={event => handleMessageTextChange(event)}
            multiline
            minRows={5}
            maxRows={5}
            disabled={!editMessageState}
            style={{ width: '95%', padding: '5px' }}
          />
        </Grid>
        <Grid container item xs={12} md={4} spacing={1}>
          <Grid
            item
            xs={12}
            sm={6}
            md={12}
            style={{ display: 'flex', alignItems: 'flex-end' }}
          >
            <TextField
              select
              label="Placeholders"
              value={placeholder}
              onChange={event => handleAddPlaceholder(event)}
              variant="outlined"
              disabled={!editMessageState}
              style={{ width: '95%' }}
            >
              {placeholderList.map(placeholder => (
                <MenuItem value={placeholder} key={placeholder}>
                  {placeholder}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
            <Button
              endIcon={<GetApp />}
              disabled={!bulkSend.listUrl}
              style={{ marginLeft: '5px', color: theme.colours.add }}
              onClick={() => window.open(bulkSend.listUrl, '_blank')}
            >
              {bulkSend.listUrl ? 'Download CSV File' : 'No CSV File'}
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="outlined"
            startIcon={editMessageState ? <VisibilityIcon /> : <EditIcon />}
            className={classes.editButton}
            disabled={uneditable}
            onClick={() =>
              setEditMessageState(editMessageState => !editMessageState)
            }
          >
            {editMessageState ? 'Preview' : 'Edit Message'}
          </Button>
        </Grid>
        <Grid
          container
          item
          xs={12}
          spacing={1}
          alignItems="center"
          style={{ marginTop: '10px' }}
        >
          <Grid item xs={12} md={3}>
            <Typography>Skip numbers with conversation activity</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              select
              value={inactiveDays}
              onChange={e => setInactiveDays(e.target.value)}
              disabled={uneditable}
              variant="outlined"
              style={{ width: '100%' }}
            >
              {CONVERSATION_ACTIVITY.map(activity => (
                <MenuItem value={activity.value} key={activity.id}>
                  {activity.title}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          spacing={1}
          style={{ margin: '10px 0 20px 0' }}
          alignItems="center"
        >
          <Grid
            container
            item
            style={{
              marginTop: '10px',
            }}
            alignItems="center"
            xs={12}
          >
            <Typography
              style={{
                color: uneditable ? theme.colours.greys.greytext2 : 'black',
                marginRight: '10px',
              }}
            >
              <b>Schedule the bulk send to start on:</b>
            </Typography>
            <DateTimePicker
              clearable
              variant="dialog"
              format="YYYY-MM-DD @ hh:mm a"
              id="date-picker-inline"
              value={moment(sendScheduledDate)}
              onChange={date => setSendScheduledDate(date)}
              disabled={uneditable}
            />
          </Grid>
        </Grid>
      </Grid>
      <Box className={classes.buttonGroup}>
        <Button
          variant="outlined"
          onClick={() => history.push(`/sms/gateways/${gatewayId}/bulk-sends`)}
        >
          Back
        </Button>
        {cancellable && (
          <Button
            variant="contained"
            className={classes.cancelButton}
            onClick={() => handleStopBulkSend()}
          >
            Cancel Bulk Send
          </Button>
        )}
        {bulkSend.statusLabel !== 'Scheduled' &&
          bulkSend.statusLabel !== 'Running' && (
            <Button
              variant="contained"
              className={classes.saveButton}
              disabled={uneditable || Boolean(sendScheduledDate)}
              onClick={() => handleSaveBulkSend()}
            >
              Save (But Don't Send)
            </Button>
          )}
        <Button
          variant="contained"
          className={classes.sendButton}
          disabled={
            uneditable ||
            (sendScheduledDate === null && bulkSend.statusLabel !== 'Scheduled')
          }
          onClick={() => handleScheduleBulkSend()}
        >
          {bulkSend.statusLabel === 'Scheduled'
            ? 'Save'
            : 'Send at Scheduled Time'}
        </Button>
      </Box>
    </Box>
  );
};

export default BulkSendDetailView;
