// External
import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import cloudinary from 'cloudinary-core';
import { NetworkStatus } from 'apollo-boost';
import { Waypoint } from 'react-waypoint';

// Material UI
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FilterListIcon from '@mui/icons-material/FilterList';
import Fab from '@mui/material/Fab';
import Drawer from '@mui/material/Drawer';
import CloseIcon from '@mui/icons-material/Close';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import CardMedia from '@mui/material/CardMedia';
import DeleteIcon from '@mui/icons-material/Delete';
import ShareIcon from '@mui/icons-material/Share';

// Internal
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import { usePersistedState } from '../../../../utils/utils';
import { snackBarSettings } from '../../../../constants';
import ROPhotoCard from '../RODetailsComponents/ROPhotoCard';
import { cloudinaryConfig, cloudinaryRoParams } from '../../../../constants';
import Loading from 'components/MaterialUI/Loading';
import FacetChips from '../FacetChips';
import { useUserContext } from 'components/MaterialUI/UserContext';
import FilterDrawer from '../FilterDrawer';
import { defaultFacet } from '../../const';
import { getMediumDate, defaultTZ } from 'utils';

const PAGE_SIZE = 20;

const cloudinaryCore = new cloudinary.Cloudinary({
  cloud_name: cloudinaryConfig.cloud_name,
});

const PHOTOS_QUERY = gql`
    query ROTrackersQuery(
      $dealer_id: Int!
      $queryFacets: [QueryFacet]!
      $filters: [QueryFilter]
      $sort: [QuerySortElement]
      $page: Int!
    ) {
      rotracker {
        getPhotos(filters: $filters, page: $page, page_size: ${PAGE_SIZE}, sort: $sort) {
          results {
            id
            created_at
            created_by
            created_by_display_name
            cloudinary_public_id
            deleted
            shared
            title
            service_ro {
              ro_number
              dealer_id
              customer_name
              cdk_service_advisor_display_name
              vehicle_make
              vehicle_year
              vehicle_model
              permissions {
                allow
              }
            }
          }
          pagination {
            next_page
            total
          }
        }
        getPhotoFacets(
          dealer_id: $dealer_id
          filters: $filters
          facets: $queryFacets
        ) {
          data {
            count
            value
          }
          field
          model
        }
      }
    }
  `;

const REMOVE_PHOTO = gql`
  mutation removePhoto($dealerId: Int!, $roNumber: String!, $photoId: ID!) {
    rotracker {
      removePhotoFromRO(
        dealer_id: $dealerId
        ro_number: $roNumber
        photo_id: $photoId
      ) {
        id
        dealer_id
        ro_number
        photo_count
        photos {
          ...ROPhotoCardPhoto
        }
      }
    }
  }
  ${ROPhotoCard.fragments.photo}
`;

const SHARE_PHOTO = gql`
  mutation sharePhoto($dealerId: Int!, $roNumber: String!, $photoId: ID!) {
    rotracker {
      sharePhoto(
        dealer_id: $dealerId
        ro_number: $roNumber
        photo_id: $photoId
      ) {
        ...ROPhotoCardPhoto
      }
    }
  }
  ${ROPhotoCard.fragments.photo}
`;
const headCells = [
  {
    id: 'action',
    numeric: false,
    sortable: false,
    disablePadding: false,
    label: 'Action',
  },
  {
    id: 'service_ro.ro_number',
    numeric: false,
    sortable: true,
    disablePadding: true,
    label: 'RO #',
  },
  {
    id: 'created_at',
    numeric: false,
    sortable: true,
    disablePadding: true,
    label: 'Added',
  },
  {
    id: 'cloudinary_public_id',
    numeric: false,
    disablePadding: false,
    label: 'Photo',
  },
  {
    id: 'created_by_display_name',
    sortable: true,
    numeric: false,
    disablePadding: false,
    label: 'Added By',
  },
  {
    id: 'service_ro.cdk_service_advisor_display_name',
    numeric: false,
    sortable: true,
    disablePadding: false,
    label: 'Service Advisor',
  },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              disabled={!headCell.sortable}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const UnsharedPhotoTable = ({
  isDesktop,
  toggleDrawer,
  state,
  classes,
  order,
  orderBy,
  setOrder,
  setOrderBy,
}) => {
  const { dealerId } = useDealerContext();
  const { currentUser } = useUserContext();
  const timezone = currentUser?.goUserProfile?.settings?.timezone || defaultTZ;
  const [searchFilters, setSearchFilters] = useState([]);
  const [searchKeywords, setSearchKeywords] = useState('');
  const [createdAtFromFilter, setCreatedAtFromFilter] = useState(null);
  const [createdAtToFilter, setCreatedAtToFilter] = useState(null);
  const [removePhoto] = useMutation(REMOVE_PHOTO);
  const [sharePhoto] = useMutation(SHARE_PHOTO);
  const [filterFacets, setFilterFacets] = usePersistedState(
    'PhotoFilterFacets',
    defaultFacet,
  );
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [sort, setSort] = useState([
    { model: 'Photo', field: 'created_at', direction: 'desc' },
  ]);

  const setSearchFiltersFromKeywords = () =>
    setSearchFilters(
      searchKeywords.split(' ').map(keyword => ({
        or: [
          {
            model: 'ServiceRO',
            field: 'ro_number',
            op: 'ilike',
            value: `%${keyword}%`,
          },
          {
            model: 'Photo',
            field: 'created_by_display_name',
            op: 'ilike',
            value: `%${keyword}%`,
          },
          {
            model: 'ServiceRO',
            field: 'cdk_service_advisor_display_name',
            op: 'ilike',
            value: `%${keyword}%`,
          },
        ],
      })),
    );

  useEffect(() => {
    if (searchKeywords) setSearchFiltersFromKeywords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = e =>
    e.key === 'Enter' ? setSearchFiltersFromKeywords() : null;

  let dateFilters = [];
  let otherFacets = [];

  if (createdAtFromFilter) {
    dateFilters.push({
      model: 'Photo',
      field: 'created_at',
      op: '>=',
      value: createdAtFromFilter.startOf('day').tz(timezone).toISOString(),
    });
    otherFacets.push({
      label: `> ${createdAtFromFilter.format('YYYY-MM-DD')}`,
      onDelete: () => setCreatedAtFromFilter(null),
    });
  }

  if (createdAtToFilter) {
    dateFilters.push({
      model: 'Photo',
      field: 'created_at',
      op: '<=',
      value: createdAtToFilter.startOf('day').tz(timezone).toISOString(),
    });
    otherFacets.push({
      label: `< ${createdAtToFilter.format('YYYY-MM-DD')}`,
      onDelete: () => setCreatedAtToFilter(null),
    });
  }

  const queryFacets = [
    { model: 'Photo', field: 'created_by_display_name' },
    { model: 'ServiceRO', field: 'is_open' },
    { model: 'ServiceRO', field: 'cdk_service_advisor_display_name' },
  ];

  const handleDeleteFacet = (_model, _field, _value) =>
    setFilterFacets(prev =>
      prev.filter(
        ({ model, field, value }) =>
          model !== _model || field !== _field || value !== _value,
      ),
    );

  const formatFilterFacets = filterFacet => {
    let returnFilters = [];
    filterFacet.forEach(({ model, field, value }) =>
      returnFilters.push({
        model,
        field,
        op: '==',
        value: value === 'True' ? true : value === 'False' ? false : value,
      }),
    );
    return returnFilters;
  };

  const filters = [
    { model: 'ServiceRO', field: 'dealer_id', op: '==', value: dealerId },
    { model: 'Photo', field: 'shared', op: '==', value: false },
    { model: 'Photo', field: 'deleted', op: '==', value: false },
    ...formatFilterFacets(filterFacets),
    ...dateFilters,
    ...searchFilters,
  ];

  const handleRequestSort = (event, property) => {
    let value;
    if (property === 'service_ro.ro_number') {
      value = [
        {
          model: 'ServiceRO',
          field: 'ro_number',
          direction: order === 'asc' ? 'desc' : 'asc',
        },
      ];
    } else if (property === 'service_ro.cdk_service_advisor_display_name') {
      value = [
        {
          model: 'ServiceRO',
          field: 'cdk_service_advisor_display_name',
          direction: order === 'asc' ? 'desc' : 'asc',
        },
      ];
    } else {
      value = [
        {
          model: 'Photo',
          field: property,
          direction: order === 'asc' ? 'desc' : 'asc',
        },
      ];
    }

    setSort(value);
    setOrder(order === 'asc' ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const {
    data: facetsPhotoData,
    fetchMore,
    loading,
    networkStatus,
    refetch,
  } = useQuery(PHOTOS_QUERY, {
    variables: {
      dealer_id: dealerId,
      queryFacets,
      filters,
      sort,
      page: 1,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const rows = facetsPhotoData?.rotracker.getPhotos.results || [];
  const facetResults = facetsPhotoData?.rotracker.getPhotoFacets || [];
  const pagination = facetsPhotoData?.rotracker.getPhotos.pagination || {};
  const { next_page, total } = pagination;

  const handleDelete = photo => {
    if (window.confirm('Delete photo?')) {
      removePhoto({
        variables: {
          dealerId: photo.service_ro.dealer_id,
          roNumber: photo.service_ro.ro_number,
          photoId: photo.id,
        },
      }).then(r => {
        enqueueSnackbar('Photo removed', snackBarSettings);
        refetch();
      });
    }
  };

  const handleShare = photo => {
    if (
      !photo.shared &&
      !window.confirm('Share photo with customer via SMS?')
    ) {
      return;
    }
    if (photo?.service_ro?.permissions?.allow.includes('share_media')) {
      sharePhoto({
        variables: {
          dealerId: photo.service_ro.dealer_id,
          roNumber: photo.service_ro.ro_number,
          photoId: photo.id,
        },
      }).then(r =>
        photo.shared
          ? enqueueSnackbar(`Link revoked`, snackBarSettings)
          : enqueueSnackbar(`Link sent`, snackBarSettings),
      );
    }
  };
  const fetchMoreHandler = () => {
    if (next_page) {
      fetchMore({
        variables: {
          page: next_page,
        },
        updateQuery: (prev, { fetchMoreResult: more }) => {
          if (!more.rotracker.getPhotos.results) return prev;
          return Object.assign({}, prev, {
            rotracker: {
              __typename: prev.rotracker.__typename,
              getPhotos: {
                __typename: prev.rotracker.getPhotos.__typename,
                results: [
                  ...prev.rotracker.getPhotos.results,
                  ...more.rotracker.getPhotos.results,
                ],
                pagination: more.rotracker.getPhotos.pagination,
              },
              getPhotoFacets: more.rotracker.getPhotoFacets,
            },
          });
        },
      });
    }
  };

  return (
    <>
      <Grid
        container
        direction="row"
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Grid item xs={12} sm={6}>
          <div style={{ paddingBottom: '1rem' }}>
            {isDesktop && (
              <Grid
                container
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Grid
                  item
                  style={{
                    paddingRight: '1rem',
                    paddingTop: '1rem',
                    paddingBottom: '1rem',
                  }}
                >
                  <Tooltip title="Filter list">
                    <Fab
                      color="primary"
                      onClick={toggleDrawer('left', true)}
                      size="medium"
                    >
                      <FilterListIcon />
                    </Fab>
                  </Tooltip>
                </Grid>
                <Grid item style={{ display: 'flex', paddingTop: '1rem' }}>
                  {(!_.isEmpty(filterFacets) || !_.isEmpty(otherFacets)) && (
                    <Box paddingTop="0.5rem" paddingBottom="0.5rem">
                      <FacetChips
                        facets={filterFacets}
                        onDeleteFacet={handleDeleteFacet}
                        others={otherFacets}
                      />
                    </Box>
                  )}
                </Grid>
              </Grid>
            )}
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          style={{
            display: 'flex',
          }}
        >
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginLeft: `${isDesktop ? 'auto' : ''}`,
              marginRight: `${isDesktop ? 0 : ''}`,
              width: `${isDesktop ? '' : '100%'}`,
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              style={{ width: `${isDesktop ? '' : '100%'}` }}
            >
              <Box>
                <TextField
                  placeholder="Search ..."
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          disabled={!searchKeywords}
                          onClick={() => {
                            setSearchKeywords('');
                            setSearchFilters([]);
                          }}
                          size="large"
                        >
                          <CloseIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  value={searchKeywords}
                  onChange={e => setSearchKeywords(e.target.value)}
                  style={{
                    paddingRight: '5px',
                    textAlign: 'right',
                    width: `${isDesktop ? '' : '100%'}`,
                  }}
                  onKeyPress={handleSearch}
                  variant={`${isDesktop ? 'standard' : 'outlined'}`}
                />
              </Box>
              <Box
                style={{
                  display: 'flex',
                  marginLeft: 'auto',
                  marginRight: 0,
                  fontSize: '18px',
                  paddingTop: '1rem',
                }}
              >
                Showing {rows.length} of {total || '0'}
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {!isDesktop && (
        <>
          {(!_.isEmpty(filterFacets) || !_.isEmpty(otherFacets)) && (
            <Box paddingTop="0.5rem" paddingBottom="0.5rem">
              <FacetChips
                facets={filterFacets}
                onDeleteFacet={handleDeleteFacet}
                others={otherFacets}
              />
            </Box>
          )}
        </>
      )}
      <Drawer
        anchor={'left'}
        open={state['left']}
        onClose={toggleDrawer('left', false)}
      >
        <div style={{ display: 'flex' }}>
          <IconButton
            style={{
              display: 'flex',
              marginLeft: 'auto',
              height: '50px',
              zIndex: '1000',
            }}
            onClick={toggleDrawer('left', false)}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </div>
        <FilterDrawer
          facetResults={facetResults}
          loading={loading}
          openedAtFromFilter={createdAtFromFilter}
          openedAtToFilter={createdAtToFilter}
          setOpenedAtFromFilter={setCreatedAtFromFilter}
          setOpenedAtToFilter={setCreatedAtToFilter}
          setSelectedFacets={setFilterFacets}
          selectedFacets={filterFacets}
          type="photos"
        />
      </Drawer>
      {isDesktop && (
        <Paper>
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={facetsPhotoData?.length}
              />
              {loading && <Loading />}
              {rows.length === 0 && !loading && (
                <Box style={{ fontSize: '20px', padding: '1rem' }}>
                  This dealer has no unshared photos.
                </Box>
              )}
              <TableBody>
                {rows.map((row, index) => {
                  return (
                    <TableRow
                      hover
                      onClick={() =>
                        history.push(
                          `/rotracker/${row?.service_ro?.dealer_id}/${row?.service_ro?.ro_number}`,
                        )
                      }
                      key={row?.id}
                    >
                      <TableCell>
                        {' '}
                        <>
                          <Tooltip title={'Delete Photo'}>
                            <IconButton
                              onClick={e => {
                                handleDelete(row);
                                e.stopPropagation();
                              }}
                              size="large"
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={'Share Photo'}>
                            <IconButton
                              aria-label="share"
                              onClick={e => {
                                handleShare(row);
                                e.stopPropagation();
                              }}
                              color={row.shared ? 'secondary' : 'default'}
                              size="large"
                            >
                              <ShareIcon />
                            </IconButton>
                          </Tooltip>
                        </>
                      </TableCell>
                      <TableCell>{row?.service_ro?.ro_number}</TableCell>
                      <TableCell>{getMediumDate(row.created_at)}</TableCell>
                      <TableCell>
                        <CardMedia
                          src={cloudinaryCore.url(
                            row.cloudinary_public_id,
                            cloudinaryRoParams,
                          )}
                          component="img"
                          height={180}
                          controls
                          bgcolor="text.secondary"
                        />
                      </TableCell>
                      <TableCell>{row?.created_by_display_name}</TableCell>
                      <TableCell>
                        {row?.service_ro?.cdk_service_advisor_display_name}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      )}
      {!isDesktop && (
        <>
          {rows.length === 0 && !loading && (
            <Box style={{ fontSize: '20px', padding: '1rem' }}>
              This dealer has no unshared photos.
            </Box>
          )}
          <>
            {rows.map(photo => (
              <Box
                style={{ paddingBottom: '1rem' }}
                key={photo.id}
                onClick={() =>
                  history.push(
                    `/rotracker/${photo?.service_ro?.dealer_id}/${photo?.service_ro?.ro_number}`,
                  )
                }
              >
                <ROPhotoCard
                  photo={photo}
                  onDelete={() =>
                    removePhoto({
                      variables: {
                        dealerId: photo.service_ro.dealer_id,
                        roNumber: photo.service_ro.ro_number,
                        photoId: photo.id,
                      },
                    }).then(r =>
                      enqueueSnackbar('Photo removed', snackBarSettings),
                    )
                  }
                  onShare={
                    photo?.service_ro?.permissions?.allow.includes(
                      'share_media',
                    ) &&
                    (() =>
                      sharePhoto({
                        variables: {
                          dealerId: photo.service_ro.dealer_id,
                          roNumber: photo.service_ro.ro_number,
                          photoId: photo.id,
                        },
                      }).then(r =>
                        photo.shared
                          ? enqueueSnackbar('Link revoked', snackBarSettings)
                          : enqueueSnackbar('Link sent', snackBarSettings),
                      ))
                  }
                  photoFeed={true}
                  timezone={timezone}
                />
              </Box>
            ))}
          </>
          {loading && <Loading />}
        </>
      )}
      {!loading && (
        <Box height="1px">
          <Waypoint onEnter={fetchMoreHandler} />
        </Box>
      )}
      {networkStatus === NetworkStatus.fetchMore && (
        <Box p={2} style={{ fontSize: '16px' }}>
          Loading more Photos...
        </Box>
      )}
    </>
  );
};

export default UnsharedPhotoTable;
