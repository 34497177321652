/* External */
import gql from 'graphql-tag';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import React, { useState } from 'react';
import { useSnackbar } from 'notistack';

/* Material UI */
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';

/* Internal */
import { parseErrors } from 'modules/sms/utils';
import BulkSendStep1 from './BulkSendStep1';
import BulkSendStep2 from './BulkSendStep2';
import BulkSendStep3 from './BulkSendStep3';
import BulkSendStep4 from './BulkSendStep4';

import {
  CREATE_BULK_SEND,
  SCHEDULE_BULK_SEND,
  SPLIT_BULK_SEND,
} from './BulkSendsMutations';

const GET_PRESIGNED_URL = gql`
  query getPresignedUploadURL($file_name: String!) {
    bulkSends {
      getPresignedUploadURL(file_name: $file_name) {
        url
        fields
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  nextButton: {
    backgroundColor: theme.colours.add,
    color: 'white',
    marginLeft: '5px',
  },
  backButton: {
    border: `1px solid${theme.colours.add}`,
    color: theme.colours.add,
    marginLeft: '5px',
  },
  input: {
    display: 'none',
  },
  errorMsg: {
    color: 'red',
    fontWeight: 'bold',
  },
  sendButton: {
    backgroundColor: theme.actions.confirm.backgroundColor,
    color: 'white',
    marginLeft: '5px',
    [theme.breakpoints.down('sm')]: {
      margin: '10px 0',
    },
  },
  buttonGroup: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: '10px',
    justifyContent: 'flex-end',
    [theme.breakpoints.up('md')]: {
      minWidth: '575px',
    },
  },
}));

const NewBulkSendDialog = ({
  gatewayId,
  newBulkSend,
  onClose,
  step,
  setStep,
  handleCancel,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [bulkSendName, setBulkSendName] = useState('');
  const [fileType, setFileType] = useState('csv');
  const [file, setFile] = useState(null);
  const [upload, setUpload] = useState({
    name: '',
    dataURI: '',
    extension: '',
  });
  const [urlForUpload, setUrlForUpload] = useState('');
  const [urlUploaded, setUrlUploaded] = useState(false);
  const [placeholderData, setPlaceholderData] = useState('');
  const [previewMessage, setPreviewMessage] = useState('');
  const [message, setMessage] = useState('');
  const [rowData, setRowData] = useState([]);
  const [batches, setBatches] = useState([]);
  const [sendBatches, setSendBatches] = useState([]);
  const [inactiveDays, setInactiveDays] = useState(1);
  const [sendScheduledDate, setSendScheduledDate] = useState(null);
  // default sendScheduledDate to null so that the user must explicitly choose the date.  Otherwise, the
  // scheduleSend state will not get set if the user doesn't choose a date.  TODO: refactor the whole submit process.
  const [scheduleSend, setScheduleSend] = useState(false);
  const [createBatches, setCreateBatches] = useState(false);

  const [createBulkSend, { loading: createBulkSendLoading }] = useMutation(
    CREATE_BULK_SEND,
    {
      onCompleted: () => {
        enqueueSnackbar('Bulk send created!', {
          variant: 'success',
        });
      },
      onError: err => {
        enqueueSnackbar(
          `Unable to create bulk send. Error: ${parseErrors(err)}`,
          {
            variant: 'error',
          },
        );
      },
    },
  );

  const [scheduleBulkSend, { loading: scheduleBulkSendLoading }] = useMutation(
    SCHEDULE_BULK_SEND,
    {
      onCompleted: () => {
        enqueueSnackbar('Bulk send scheduled!', {
          variant: 'success',
        });
      },
      onError: err => {
        enqueueSnackbar(
          `Unable to schedule bulk send. Error: ${parseErrors(err)}`,
          {
            variant: 'error',
          },
        );
      },
    },
  );

  const [splitBulkSend, { loading: splitBulkSendLoading }] = useMutation(
    SPLIT_BULK_SEND,
    {
      onCompleted: () => {
        enqueueSnackbar('Batches created', {
          variant: 'success',
        });
      },
      onError: err => {
        enqueueSnackbar(
          `Unable to create batches for bulk send. Error: ${parseErrors(err)}`,
          {
            variant: 'error',
          },
        );
      },
    },
  );

  const [getFileURL] = useLazyQuery(GET_PRESIGNED_URL, {
    fetchPolicy: 'network-only',
    onCompleted: data => {
      let { url, fields } = data.bulkSends.getPresignedUploadURL;
      fields = JSON.parse(fields);

      const formData = new FormData();
      formData.append('Content-Type', file.type);
      formData.append(
        'Content-Disposition',
        `attachment; filename="${file.name}"`,
      );
      for (let [key, value] of Object.entries(fields)) {
        formData.append(key, value);
      }
      formData.append('file', file, file.name);

      fetch(url, {
        method: 'POST',
        body: formData,
        mode: 'no-cors',
      })
        // there's different paths to creating a bulk send:
        // Send at Scheduled Time, Save (but don't send) and Split into Batches
        .then(response => {
          createBulkSend({
            variables: {
              gatewayId,
              name: bulkSendName,
              key: fields.key,
              template: message,
              inactiveDays,
            },
          })
            .catch(e => {
              enqueueSnackbar(`Error uploading CSV file to S3: ${e}`, {
                variant: 'error',
              });
            })
            .then(data => {
              const bulkSendId = parseInt(data.data.sms.createBulkSend.id);
              if (scheduleSend && !createBatches) {
                // schedule bulk send
                scheduleBulkSend({
                  variables: {
                    bulkSendId,
                    data: {
                      inactiveDays,
                      scheduledDate: sendScheduledDate,
                    },
                  },
                }).catch(e => {
                  enqueueSnackbar(
                    `Error scheduling bulk send: ${parseErrors(e)}`,
                    {
                      variant: 'error',
                    },
                  );
                });
              }

              // if we are splitting the bulk send into batches
              if (createBatches) {
                const batchDates = batches.map(batch => {
                  return batch.date;
                });
                const sendOptions = batches.map(batch => {
                  return batch.when;
                });
                splitBulkSend({
                  variables: {
                    bulkSendId,
                    data: {
                      batchDates,
                      sendOptions,
                    },
                  },
                });
              }
            })
            .catch(e => {
              enqueueSnackbar(`Error creating bulk send: ${parseErrors(e)}`, {
                variant: 'error',
              });
            })
            .then(() => onClose());
        })
        .catch(e => {
          enqueueSnackbar(`Error creating bulk send: ${parseErrors(e)}`, {
            variant: 'error',
          });
        });
    },
  });

  const goodString = /\S/.test(bulkSendName);

  const disableButton =
    (fileType === 'csv' && (!upload.name || !bulkSendName || !goodString)) ||
    (fileType === 'url' && (!urlUploaded || !bulkSendName || !goodString));

  const handleUploadFile = file => {
    getFileURL({
      variables: {
        file_name: file.name,
      },
    });
  };

  const handleBackButton = () => {
    setScheduleSend(false);
    setCreateBatches(false);
    setStep(step - 1);
  };

  return (
    <Dialog onClose={onClose} open={newBulkSend}>
      <LoadingBackdrop
        open={
          createBulkSendLoading ||
          scheduleBulkSendLoading ||
          splitBulkSendLoading
        }
      >
        Loading...
      </LoadingBackdrop>
      <DialogTitle>New Bulk Send</DialogTitle>
      <DialogContent>
        <Grid container>
          {/* **** STEP 1 (set name and file) **** */}
          {step === 1 && (
            <BulkSendStep1
              bulkSendName={bulkSendName}
              setBulkSendName={setBulkSendName}
              fileType={fileType}
              setFileType={setFileType}
              upload={upload}
              setUpload={setUpload}
              urlForUpload={urlForUpload}
              setUrlForUpload={setUrlForUpload}
              setPlaceholderData={setPlaceholderData}
              setRowData={setRowData}
              setFile={setFile}
              urlUploaded={urlUploaded}
              setUrlUploaded={setUrlUploaded}
            />
          )}
          {/* **** STEP 2 (create message) **** */}
          {step === 2 && (
            <BulkSendStep2
              placeholderData={placeholderData}
              rowData={rowData}
              setPreviewMessage={setPreviewMessage}
              previewMessage={previewMessage}
              message={message}
              setMessage={setMessage}
            />
          )}
          {/* **** STEP 3 (Confirmation) **** */}
          {step === 3 && (
            <BulkSendStep3
              bulkSendName={bulkSendName}
              messagePreview={previewMessage}
              messageCount={rowData.length}
              message={message}
              sendScheduledDate={sendScheduledDate}
              setSendScheduledDate={setSendScheduledDate}
              inactiveDays={inactiveDays}
              setInactiveDays={setInactiveDays}
              scheduleSend={scheduleSend}
              setScheduleSend={setScheduleSend}
            />
          )}
          {/* **** STEP 4 (batches) **** */}
          {step === 4 && (
            <BulkSendStep4
              messageCount={rowData.length}
              batches={batches}
              setBatches={setBatches}
              setCreateBatches={setCreateBatches}
              sendBatches={sendBatches}
              setSendBatches={setSendBatches}
            />
          )}
        </Grid>
      </DialogContent>
      <DialogActions style={{ flexWrap: 'wrap' }}>
        {step === 3 && (
          <Box className={classes.buttonGroup}>
            <Button
              disabled={sendScheduledDate}
              variant="contained"
              className={classes.nextButton}
              onClick={() => handleUploadFile(file)}
            >
              Save (But don't send)
            </Button>
            <Button
              disabled={!sendScheduledDate}
              variant="contained"
              className={classes.sendButton}
              onClick={() => handleUploadFile(file)}
            >
              Send at Scheduled Time
            </Button>
            <Button
              variant="outlined"
              className={classes.backButton}
              onClick={() => setStep(4)}
              style={{ marginTop: '5px' }}
            >
              Split Into Batches
            </Button>
          </Box>
        )}
        <Button onClick={handleCancel}>Cancel</Button>
        {step > 1 && (
          <Button
            variant="outlined"
            className={classes.backButton}
            onClick={() => handleBackButton()}
          >
            Back
          </Button>
        )}
        {step < 3 && (
          <Button
            type="submit"
            variant="contained"
            disabled={disableButton}
            className={classes.nextButton}
            onClick={() => setStep(step + 1)}
          >
            Next
          </Button>
        )}
        {step === 4 && (
          <Button
            variant="contained"
            className={classes.sendButton}
            disabled={batches.length === 0}
            onClick={() => handleUploadFile(file)}
          >
            Create Batches
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default NewBulkSendDialog;
