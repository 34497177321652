import React from 'react';

/* Material UI */
import { Box, Typography } from '@mui/material';

const Title = ({ title, ...rest }) => (
  <Box {...rest}>
    <Typography variant="h5">
      <b>{title}</b>
    </Typography>
  </Box>
);

export default Title;
