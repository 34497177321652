// * TABLE HEAD CELLS *
export const HEAD_CELLS = [
  {
    model: 'Message',
    id: 'date_created',
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: 'Date',
  },
  {
    model: 'Message',
    id: 'direction',
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: 'Direction',
  },
  {
    model: 'Conversation',
    id: 'operator_username',
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: 'Operator',
  },
  {
    model: 'Message',
    id: 'recipient_name',
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: 'Customer',
  },
  {
    model: 'Message',
    id: 'to_number',
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: 'To',
  },
  {
    model: 'Message',
    id: 'message',
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: 'Message',
  },
  {
    model: 'Message',
    id: 'bulk_label',
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: 'Bulk Label',
  },
  {
    model: 'Message',
    id: 'delivery_status',
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: 'Status',
  },
  {
    model: 'Message',
    id: 'archived',
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: 'Archived',
  },
];
