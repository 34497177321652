/* external */
import React, { useState, useEffect } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import gql from 'graphql-tag';

/* Material UI */
import { Box } from '@mui/material';

/* internal */
import { LoadingBackdrop } from 'components/MaterialUI';
import { useSnackMutation } from 'utils/useSnackMutation';

import { PhotoBox } from '../common';
import StepButtons from './StepButtons';
import { useSnackbar } from 'notistack';

const UPLOAD_PHOTOS = gql`
  mutation uploadNpvPhotos($id: Int!, $data: [NpvPhotoInput!]!) {
    npv {
      createVehiclePhotos(id: $id, data: $data) {
        id
        photos {
          cloudinaryPublicId
          id
          md5
          isFloorPlan
          vehicleId
          labelId
        }
      }
    }
  }
`;

const UnitPhotos = ({ next, back, vehicle, setVehicle }) => {
  // Rip Photos out of vehicle
  const { id: vehicleId, photos = [] } = vehicle ?? {};

  // Form Stuff
  const formMethods = useForm({
    defaultValues: {
      photos: photos.map(
        ({ id, cloudinaryPublicId, md5, isFloorPlan, labelId, vehicleId }) => ({
          id,
          cloudinaryPublicId,
          md5,
          isFloorPlan,
          labelId,
          vehicleId,
        }),
      ),
    },
  });

  const photosFieldArray = useFieldArray({
    control: formMethods.control,
    name: 'photos',
    keyName: 'key',
  });

  const [photoList, setPhotoList] = useState(photosFieldArray.fields);
  const [labelId, setLabelId] = useState(
    photoList.find(photo => photo.isFloorPlan)?.labelId ?? null,
  );

  // Mutations
  const [uploadPhotosMutation, { loading: photosLoading }] = useSnackMutation(
    UPLOAD_PHOTOS,
    'Photos uploaded',
    'Error uploading photos',
    data => {
      setVehicle(prevState => ({
        ...prevState,
        photos: data.npv.createVehiclePhotos.photos,
      }));
      next();
    },
  );

  const { enqueueSnackbar } = useSnackbar();

  // Add labelId to the floor plan photo
  const onSubmit = data => {
    const floorplan = data.photos.find(photo => photo.isFloorPlan);
    if (floorplan && !labelId) {
      enqueueSnackbar('Please select a label for the floor plan', {
        variant: 'error',
      });
      return;
    }

    uploadPhotosMutation({
      variables: {
        id: vehicleId,
        data: data.photos.map(
          ({ chosen, selected, __typename, key, isFloorPlan, ...rest }) => ({
            ...rest,
            isFloorPlan,
            labelId: isFloorPlan ? labelId : null,
            vehicleId,
          }),
        ),
      },
    });
  };
  // Whenever the photoList is changed, replace the form array
  useEffect(() => {
    photosFieldArray.replace(photoList);
    // eslint-disable-next-line
  }, [photoList]);

  return (
    <FormProvider {...formMethods}>
      <LoadingBackdrop open={photosLoading}>
        Uploading Photos...
      </LoadingBackdrop>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <Box margin={1}>
          <PhotoBox
            vehicle={vehicle}
            photoList={photoList}
            setPhotoList={setPhotoList}
            labelId={labelId}
            setLabelId={setLabelId}
          />
        </Box>
        <StepButtons back={back} />
      </form>
    </FormProvider>
  );
};

UnitPhotos.fragments = {
  vehicle: gql`
    fragment NpvVehiclePhotos on NpvVehicle {
      id
      photos {
        cloudinaryPublicId
        id
        labelId
        md5
        isFloorPlan
        vehicleId
      }
      photoUploadParams {
        apiKey
        folder
        signature
        timestamp
        uploadPreset
        url
      }
      ...NpvVehiclePhotoBox
    }
    ${PhotoBox.fragments.vehicle}
  `,
};

export default UnitPhotos;
