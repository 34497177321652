import React, { useState } from 'react';

import { DATE_NO_TIME_FORMAT } from 'constants.js';
import moment from 'moment';
import { dateToFormatString } from 'utils';

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import EmailIcon from '@mui/icons-material/Email';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import PhoneIcon from '@mui/icons-material/Phone';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';

import { TaskStatus, TaskStatusColor } from '../constants';
import TasksAction from './TasksAction';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '300px',
    paddingBottom: 0,
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  tableRow: {
    paddingBottom: 0,
    paddingTop: 0,
    border: 'none',
    fontWeight: 'bold',
  },
  tableCell: {
    paddingBottom: 0,
    paddingTop: 0,
    border: 'none',
  },
  tableValue: {
    paddingBottom: 0,
    paddingTop: 0,
    border: 'none',
  },
}));

const formatDate = d => dateToFormatString(d, DATE_NO_TIME_FORMAT);

const TaskMobileRow = ({ task: currentTask, refetch }) => {
  const classes = useStyles();
  const [task, setTask] = useState(currentTask);
  const [taskStatus, setTaskStatus] = useState(task.status);
  const statusColor = (status, overdue) => {
    const highLightColor =
      (status === TaskStatus.CANCELLED && TaskStatusColor.GREY) ||
      (status === TaskStatus.TODO && overdue && TaskStatusColor.RED) ||
      (status === TaskStatus.TODO && TaskStatusColor.YELLOW) ||
      (status === TaskStatus.COMPLETE && TaskStatusColor.GREEN) ||
      TaskStatusColor.GREY;
    return highLightColor;
  };

  return (
    <>
      <ListItem
        key={currentTask._id}
        style={{
          borderLeftWidth: '5px',
          borderLeftColor: statusColor(
            taskStatus,
            moment(task.due_date) < moment(),
          ),
          borderLeftStyle: 'solid',
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
        >
          <Grid item xs={1}>
            {task.type === 'opportunity' ? (
              <ThumbUpIcon />
            ) : task.type === 'email' ? (
              <EmailIcon />
            ) : task.type === 'call' ? (
              <PhoneIcon />
            ) : (
              <HelpOutlineIcon />
            )}
          </Grid>
          <Grid item xs={11}>
            <Table className={classes.table}>
              <Table>
                <TableRow>
                  <TableCell className={classes.tableRow} align="left">
                    {`${formatDate(task.due_date)} @ ${
                      task.dealer.dealer_name
                    } `}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left" className={classes.tableCell}>
                    <Typography
                      style={{
                        fontWeight: 'bold',
                        display: 'inline-block',
                        fontSize: 14,
                      }}
                    >
                      Customer
                    </Typography>
                    <Typography
                      style={{ display: 'inline-block', fontSize: 14 }}
                    >
                      {`: ${task?.customer?.fullname ?? 'N/A'}`}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.tableRow} align="left">
                    {task.description}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.tableRow} align="left">
                    <Typography
                      style={{ display: 'inline-block', fontSize: 14 }}
                    >
                      Assigned to: {` ${task.assignee?.display_name ?? 'N/A'}`}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.tableRow} align="left">
                    <Typography
                      style={{ display: 'inline-block', fontSize: 14 }}
                    >
                      Added on: {` ${formatDate(task.created)}`}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.tableRow} align="right">
                    <TasksAction
                      task={task}
                      setTask={setTask}
                      setTaskStatus={setTaskStatus}
                      taskStatus={taskStatus}
                      refetch={refetch}
                    />
                  </TableCell>
                </TableRow>
              </Table>
            </Table>
          </Grid>
        </Grid>
      </ListItem>
      <Divider />
    </>
  );
};

export default TaskMobileRow;
