import { groupBy } from 'lodash';

export const filtersFromFacets = facetFilters =>
  Object.entries(groupBy(facetFilters, x => [x.model, x.field])).map(
    ([modelField, entries]) => ({
      model: modelField.split(',')[0],
      field: modelField.split(',')[1],
      op: 'in',
      value: entries.map(({ value }) =>
        value === 'True' ? true : value === 'False' ? false : value,
      ),
    }),
  );
