export const cloudinaryVideoParams = {
  width: 640,
  height: 480,
  resource_type: 'video',
  crop: 'fit',
  format: 'mp4',
};

export const defaultFacet = [
  {
    model: 'ServiceRO',
    field: 'is_open',
    value: 'True',
    options: { label: 'Open ROs' },
  },
];
