// External
import React, { useState } from 'react';
import { useFormContext, useFieldArray } from 'react-hook-form';

// Material UI
import { useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Internal
import ConfirmDialog from 'components/MaterialUI/ConfirmDialog';
import TextFieldControl from 'components/MaterialUI/TextFieldControl';

const TransportationOption = ({ canUpdate }) => {
  const theme = useTheme();
  const { control } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'transportationOptions',
    keyName: 'key',
  });

  const [confirmRemoveItemIndex, setConfirmRemoveItemIndex] = useState(null);

  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>
          <b>Transportation Options</b>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Button
              type="button"
              variant="contained"
              onClick={() =>
                append({
                  cdk_transportation_code: '',
                  name: '',
                  description: '',
                  daily_limit: '',
                  instructions: '',
                })
              }
              style={canUpdate ? theme.actions.confirm : theme.actions.disabled}
              disabled={!canUpdate}
            >
              Add Transportation Option
            </Button>
          </Grid>
          {fields.map((transportationOption, index) => (
            <Grid item xs={12} key={transportationOption.key}>
              <Grid container spacing={3} alignItems="stretch">
                <Grid item xs={10} md={11}>
                  <Grid container spacing={3}>
                    <Grid item xs={6} md={3}>
                      <TextFieldControl
                        label="CDK Transportation Code"
                        name={`transportationOptions.${index}.cdk_transportation_code`}
                        required
                        disabled={!canUpdate}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <TextFieldControl
                        label="Name"
                        name={`transportationOptions.${index}.name`}
                        required
                        disabled={!canUpdate}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={8} md={4}>
                      <TextFieldControl
                        label="Description"
                        name={`transportationOptions.${index}.description`}
                        required
                        disabled={!canUpdate}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={4} md={2}>
                      <TextFieldControl
                        label="Daily Limit"
                        name={`transportationOptions.${index}.daily_limit`}
                        disabled={!canUpdate}
                        fullWidth
                        type="number"
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <TextFieldControl
                        label="Instructions"
                        name={`transportationOptions.${index}.instructions`}
                        required
                        disabled={!canUpdate}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={2} md={1} style={{ margin: 'auto' }}>
                  <Button
                    type="button"
                    color="primary"
                    variant="contained"
                    onClick={() =>
                      transportationOption.id
                        ? setConfirmRemoveItemIndex(index)
                        : remove(index)
                    }
                    style={{
                      backgroundColor: 'red',
                      minWidth: '36px',
                      padding: '10px',
                    }}
                    disabled={!canUpdate}
                  >
                    <i className="fas fa-trash"></i>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
        <ConfirmDialog
          isOpen={confirmRemoveItemIndex !== null}
          onConfirm={() => {
            remove(confirmRemoveItemIndex);
          }}
          onClose={() => setConfirmRemoveItemIndex(null)}
          text="Are you sure you want to remove that transportation option?"
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default TransportationOption;
