import { startCase, toLower } from 'lodash';

/* external */
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useHistory } from 'react-router-dom';

import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DateRangeIcon from '@mui/icons-material/DateRange';
import DriveEtaIcon from '@mui/icons-material/DriveEta';

/* Material UI */
import Alert from '@mui/material/Alert';

import { getFullVehicle } from '../utils';

/* internal */
import { STATUS_ICON } from 'constants.js';

const useStyles = makeStyles(theme => ({
  mainTitle: {
    flexGrow: 1,
    fontWeight: 'bold',
    padding: theme.spacing(2),
  },
  card: {
    margin: '14px',
  },
  cardContent: {
    paddingBottom: '0px !important',
  },
  list: {
    paddingTop: '0px',
    paddingBottom: '0px',
  },
  assignees: {
    paddingLeft: '0px',
    paddingRight: '0px',
  },
}));

const OpportunitiesListMobile = ({ opps, showing }) => {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const clickOpportunity = ({ _id, permissions, customer }) => {
    if (permissions.can_read_details) {
      history.push(`${_id}/${customer._id}/details`);
    } else {
      enqueueSnackbar(
        'You do not have access to this feature. Please contact Market Platform Support.',
        { variant: 'error' },
      );
    }
  };

  if (!opps) {
    return 'No Opportunities Found';
  }

  return (
    <>
      {opps.map(opportunity => (
        <Card key={opportunity._id} className={classes.card}>
          <CardActionArea onClick={() => clickOpportunity(opportunity)}>
            <CardContent className={classes.cardContent}>
              <Grid container spacing={1}>
                <Grid item xs={5}>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography
                        variant="body2"
                        style={{ fontWeight: 'bold' }}
                        onClick={e => {
                          e.stopPropagation();
                          if (opportunity.customer)
                            history.push(
                              `/customers/${opportunity.customer._id}/details`,
                            );
                          else
                            alert(
                              `Error: no customer attached to opportunity ${opportunity._id}. Please contact market support.`,
                            );
                        }}
                      >
                        {opportunity?.customer?.fullname || 'Unknown Customer'}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2">
                        {getFullVehicle(opportunity.dms_deal ?? {})}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2">
                        {opportunity.dms_deal?.stock_number
                          ? `Stock: ${opportunity.dms_deal.stock_number}`
                          : ''}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="caption"
                        style={{ fontSize: '.7rem' }}
                      >
                        <b>Channel:</b>
                        {` ${startCase(
                          toLower(opportunity.marketing.lead_direction ?? ''),
                        )}
                            ${startCase(
                              toLower(
                                opportunity.marketing.lead_channel ?? 'N/A',
                              ),
                            )}`}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="caption"
                        style={{ fontSize: '.7rem' }}
                      >
                        <b>Source:</b>
                        {` ${opportunity.marketing.lead_source ?? 'N/A'}`}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={7} style={{ textAlign: 'end' }}>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography variant="caption">
                        {opportunity.dms_deal?.deal_number
                          ? `Deal #: ${opportunity.dms_deal?.deal_number}`
                          : ''}
                      </Typography>
                      <Icon
                        style={{
                          marginLeft: '5px',
                          fontSize: '.85rem',
                          color: STATUS_ICON[opportunity.status].color,
                          overflow: 'inherit',
                        }}
                        className={STATUS_ICON[opportunity.status].icon}
                      />
                      {'\n'}
                      <Chip
                        size="small"
                        style={{
                          fontSize: '.7rem',
                          color: 'white',
                          backgroundColor:
                            opportunity.deal_type === 'Used'
                              ? STATUS_ICON[1].color
                              : opportunity.deal_type === 'New'
                              ? STATUS_ICON[0].color
                              : 'grey',
                        }}
                        label={opportunity.deal_type}
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant="caption" color="textSecondary">
                        {`${opportunity.status_display} on ${moment(
                          opportunity.last_status_change[opportunity.status],
                        ).format('MMM Do, YYYY')}`}
                      </Typography>
                    </Grid>
                    {opportunity?.sales_reps[0]?.display_name && (
                      <Grid item>
                        <Typography
                          variant="caption"
                          style={{ fontSize: '.7rem' }}
                        >
                          {`SP: ${opportunity.sales_reps[0].display_name}`}
                        </Typography>
                      </Grid>
                    )}
                    {opportunity?.bdc_reps[0]?.display_name && (
                      <Grid item>
                        <Typography
                          variant="caption"
                          style={{ fontSize: '.7rem' }}
                        >
                          {`BDC: ${opportunity.bdc_reps[0].display_name}`}
                        </Typography>
                      </Grid>
                    )}
                    {opportunity?.sales_managers[0]?.display_name && (
                      <Grid item>
                        <Typography
                          variant="caption"
                          style={{ fontSize: '.7rem' }}
                        >
                          {`SM: ${
                            opportunity?.sales_managers[0]?.display_name ?? ''
                          }`}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                {opportunity?.appointments.length > 0 && (
                  <Grid item xs={12}>
                    <List style={{ padding: '0px' }}>
                      <Alert severity="info">
                        <ListItem
                          style={{
                            justifyContent: 'flex-end',
                            paddingRight: '0px',
                            paddingTop: '0px',
                          }}
                        >
                          <ListItemAvatar
                            style={{ minWidth: '0px', fontSize: '.7rem' }}
                          >
                            <DateRangeIcon fontSize="small" />
                          </ListItemAvatar>
                          <Typography
                            variant="caption"
                            style={{ fontSize: '.7rem' }}
                          >
                            <b>Upcoming Appt:</b>{' '}
                            {moment(
                              opportunity.appointments[0].start_date,
                            ).format('MMM D, YYYY @ h:mm')}
                          </Typography>
                        </ListItem>
                      </Alert>
                    </List>
                  </Grid>
                )}
              </Grid>
              <CardActions>
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <List style={{ padding: '0px' }}>
                      <ListItem
                        style={{
                          padding: '0px',
                          justifyContent: 'center',
                          alignItems: 'initial',
                        }}
                      >
                        <ListItemAvatar style={{ minWidth: '0px' }}>
                          <CheckBoxIcon style={{ fontSize: '15' }} />
                        </ListItemAvatar>
                        <Typography variant="caption">
                          {`Tasks (0/${opportunity.tasks.length})`}
                        </Typography>
                      </ListItem>
                    </List>
                  </Grid>
                  <Grid item xs={4}>
                    <List style={{ padding: '0px' }}>
                      <ListItem
                        style={{
                          padding: '0px',
                          justifyContent: 'center',
                          alignItems: 'initial',
                        }}
                      >
                        <ListItemAvatar style={{ minWidth: '0px' }}>
                          <Icon
                            className="fas fa-file-invoice-dollar"
                            style={{ textAlign: 'center', fontSize: '15px' }}
                          />
                        </ListItemAvatar>
                        <Typography variant="caption">
                          {`Credit App (${opportunity.credit_application_ids.length})`}
                        </Typography>
                      </ListItem>
                    </List>
                  </Grid>
                  <Grid item xs={4}>
                    <List style={{ padding: '0px' }}>
                      <ListItem
                        style={{
                          padding: '0px',
                          justifyContent: 'center',
                          alignItems: 'initial',
                        }}
                      >
                        <ListItemAvatar style={{ minWidth: '0px' }}>
                          <DriveEtaIcon style={{ fontSize: '15' }} />
                        </ListItemAvatar>
                        <Typography variant="caption">
                          {`Trade In (${
                            opportunity.dms_deal.trades?.length ?? 0
                          })`}
                        </Typography>
                      </ListItem>
                    </List>
                  </Grid>
                </Grid>
              </CardActions>
            </CardContent>
          </CardActionArea>
        </Card>
      ))}
    </>
  );
};

export default OpportunitiesListMobile;
