/* External */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

/* Material UI */
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
  Email as EmailIcon,
  ExpandMore as ExpandMoreIcon,
  Smartphone as SmartphoneIcon,
} from '@mui/icons-material';

/* internal */
import CustomerDetailsModal from './CustomerDetailsModal';
import CustomerAvatar from 'components/CustomerAvatar';
import EditCustomerDetails from 'modules/customers/components/CustomerDetails/EditCustomerDetails';
import { formatPhoneNumber } from 'utils';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '200px',
    height: '100%',
  },
  fullName: {
    fontWeight: 'bold',
    fontSize: '20px',
  },
  sizeAvatar: {
    height: theme.spacing(7),
    width: theme.spacing(7),
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
  },
}));

const CustomerCard = ({ customer, refetchOpportunity }) => {
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const desktop = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const [openCustomerModal, setOpenCustomerModal] = useState(false);

  const handleClose = () => {
    setOpenCustomerModal(false);
  };

  const handleCloseEditCustomer = () => {
    refetchOpportunity();
  };

  const clickCustomerPage = () => {
    history.push(`/customers/${customer._id}/details`);
  };

  // yoinked from customers > SearchResult.jsx
  const identityTypeIcon = identityType => {
    switch (identityType) {
      case 'email':
        return <EmailIcon fontSize="small" />;
      case 'sms':
        return <SmartphoneIcon fontSize="small" />;
      default:
        return <EmailIcon fontSize="small" />;
    }
  };

  const CustomerContent = () => {
    return (
      <Grid
        container
        direction={'row'}
        alignItems={'center'}
        style={{ padding: '5px' }}
      >
        <Grid item xs={2} style={{ marginRight: '10px' }}>
          <CustomerAvatar customer={customer} className={classes.sizeAvatar} />
        </Grid>

        <Grid item xs={9}>
          <Typography variant="h6" gutterBottom>
            {customer.fullname}{' '}
            <IconButton size="small">
              <EditCustomerDetails
                customer={customer}
                extraCompletionHandler={handleCloseEditCustomer}
              />
            </IconButton>
          </Typography>
        </Grid>
        {customer.identities?.length > 0 && (
          <Grid container item xs={12} style={{ marginTop: '5px' }} spacing={2}>
            <Grid item xs={12} style={{ marginBottom: '5px' }}>
              <Typography>
                <b>Identities</b>
              </Typography>
            </Grid>
            {customer.identities?.map(ident => {
              return (
                <Grid container item xs={12} key={ident.identity_type}>
                  <Grid item xs={1} style={{ marginRight: '5px' }}>
                    {identityTypeIcon(ident.identity_type)}
                  </Grid>
                  <Grid item xs={10}>
                    <Typography>
                      {ident.identity_type === 'sms'
                        ? formatPhoneNumber(ident.identity_value)
                        : ident.identity_value}
                    </Typography>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        )}
      </Grid>
    );
  };

  if (!desktop) {
    return (
      <>
        <Dialog
          maxWidth="sm"
          open={openCustomerModal}
          onClose={() => setOpenCustomerModal(false)}
        >
          <CustomerDetailsModal customer={customer} handleClose={handleClose} />
        </Dialog>
        <Accordion defaultExpanded={true}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography style={{ paddingTop: 0 }}>
              <b>Customer</b>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CustomerContent />
          </AccordionDetails>
          <AccordionActions>
            <Button
              size="small"
              style={theme.actions.add}
              onClick={() => clickCustomerPage()}
            >
              Customer Page
            </Button>
            <Button
              size="small"
              style={theme.actions.add}
              onClick={() => setOpenCustomerModal(true)}
            >
              More Details
            </Button>
          </AccordionActions>
        </Accordion>
      </>
    );
  }

  return (
    <>
      <Dialog
        maxWidth="sm"
        open={openCustomerModal}
        onClose={() => setOpenCustomerModal(false)}
      >
        <CustomerDetailsModal customer={customer} handleClose={handleClose} />
      </Dialog>
      <Card className={classes.root}>
        <CardContent style={{ minHeight: '200px' }}>
          <Typography className={classes.title} gutterBottom>
            Customer
          </Typography>
          <CustomerContent />
        </CardContent>
        <CardActions
          style={{ justifyContent: 'flex-end', verticalAlign: 'bottom' }}
        >
          <Button
            size="small"
            style={theme.actions.add}
            onClick={() => clickCustomerPage()}
          >
            Customer Page
          </Button>
          <Button
            size="small"
            style={theme.actions.add}
            onClick={() => setOpenCustomerModal(true)}
          >
            More Details
          </Button>
        </CardActions>
      </Card>
    </>
  );
};
export default CustomerCard;
