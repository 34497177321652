/* External */
import React, { useState } from 'react';
import moment from 'moment-timezone';

/* Material UI */
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Cancel } from '@mui/icons-material';

import { DateTimePicker } from '@mui/x-date-pickers';

/* Internal */
import { WHEN_TO_SEND } from './const';

const useStyles = makeStyles(theme => ({
  subheader: {
    fontWeight: 'bold',
    margin: '10px 0',
  },
  addBatchContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  addButton: {
    backgroundColor: theme.colours.add,
    color: 'white',
    marginLeft: '5px',
  },
  batchRow: {
    borderBottom: '1px solid #ccc',
    marginBottom: '15px',
    paddingBottom: '15px',
  },
  delete: {
    '&> .MuiIconButton-root': {
      color: theme.actions.danger.backgroundColor,
    },
  },
}));

const BulkSendStep4 = ({
  messageCount,
  batches,
  setBatches,
  setCreateBatches,
}) => {
  const classes = useStyles();

  const [sendScheduledDate, setSendScheduledDate] = useState(moment());
  const [whenToSend, setWhenToSend] = useState('schedule');
  const [batchId, setBatchId] = useState(1);

  const handleAddBatch = () => {
    setBatches(prev => [
      ...prev,
      {
        id: batchId,
        date: moment(sendScheduledDate).format(),
        when: whenToSend,
      },
    ]);
    setCreateBatches(true);
    setBatchId(batchId + 1);
  };

  const handleDelete = batch => {
    setBatches(prev => prev.filter(x => x.id !== batch.id));
    if (batches.length === 0) {
      setCreateBatches(false);
    }
  };

  return (
    <>
      <Grid item xs={12} style={{ marginBottom: '15px' }}>
        <Typography>
          To split this bulk send of <b>{messageCount} messages</b> into
          multiple batches, add the dates and times you want each batch to send
          on, and whether the batch should send at the scheduled time or save to
          manually send it later.
        </Typography>
      </Grid>
      <Grid
        container
        item
        xs={12}
        spacing={1}
        justifyContent="space-between"
        className={classes.addBatchContainer}
      >
        <Grid item xs={12}>
          <Typography className={classes.subheader}>Add batch:</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <DateTimePicker
            variant="dialog"
            format="YYYY-MM-DD @ hh:mm a"
            id="date-picker-inline"
            value={sendScheduledDate}
            onChange={date => setSendScheduledDate(date)}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl variant="outlined" style={{ width: '100%' }}>
            <InputLabel shrink id="when-to-send">
              When to Send
            </InputLabel>
            <Select
              value={whenToSend}
              onChange={e => setWhenToSend(e.target.value)}
              labelId="when-to-send"
              label="When to Send"
            >
              {WHEN_TO_SEND.map(when => (
                <MenuItem value={when.value} key={when.id}>
                  {when.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={2}>
          <Button
            variant="contained"
            className={classes.addButton}
            onClick={() => handleAddBatch()}
          >
            Add
          </Button>
        </Grid>
      </Grid>
      <Grid container item xs={12} style={{ marginTop: '10px' }}>
        <Grid item xs={12}>
          <Typography className={classes.subheader}>Batches</Typography>
        </Grid>
        {batches.length === 0 && <Typography>No batches created.</Typography>}
        {batches.length > 0 &&
          batches.map((batch, index) => (
            <Grid
              container
              item
              xs={12}
              className={classes.batchRow}
              key={batch.id}
              alignItems="center"
              spacing={1}
            >
              <Grid item xs={6}>
                <Typography variant="body2">
                  <b>Batch {index + 1}: </b>
                  {` ${moment(batch.date).format('MMM Do YYYY, h:mm:ss a')}`}
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography variant="body2">
                  {batch.when === 'schedule'
                    ? 'Send at scheduled time'
                    : 'Save, but do not send'}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <IconButton size="small" onClick={() => handleDelete(batch)}>
                  <Cancel style={{ color: 'red' }} />
                </IconButton>
              </Grid>
            </Grid>
          ))}
      </Grid>
    </>
  );
};

export default BulkSendStep4;
