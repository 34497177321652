import React, { useEffect, useState } from 'react';

/* external */
import { ErrorMessage } from '@hookform/error-message';
import { useForm, useWatch } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

/* Material UI */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';

/* internal */
import { usePersistedState } from 'utils';
import { outOfProvinceWarning } from 'modules/auctions/const';
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import { useTimeSyncContext } from 'components/MaterialUI/TimeSyncContext';
import { errorHandler } from 'modules/auctions/utils';
import ConfirmDialog from 'components/MaterialUI/ConfirmDialog';
import TextFieldControl from 'components/MaterialUI/TextFieldControl';

const CREATE_BID = gql`
  mutation createBid($bid: BidInput!) {
    auctions {
      createBid(bid: $bid) {
        id
      }
    }
  }
`;

const BidForm = ({ open, onClose, auctionItem, refetch }) => {
  const { dealerId, dealerProvinceName } = useDealerContext();
  const [hideWarningSelection, setHideWarningSelection] = useState(false);
  const [ignoreWarningItemIds, setIgnoreWarningItemIds] = usePersistedState(
    'ignoreOutOfProvinceWarningAuctionItemIds',
    '[]',
  );
  const { isElapsed } = useTimeSyncContext();
  const [showConfirmBidDialog, setShowConfirmBidDialog] = useState(false);
  const {
    clearErrors,
    control,
    formState: { errors, isDirty },
    handleSubmit,
    setError,
  } = useForm();
  const amount = useWatch({ name: 'amount', control });

  const [createBid] = useMutation(CREATE_BID);

  const {
    id: auctionItemId,
    startsAt,
    endsAt,
    isActive,
    vehicle,
  } = auctionItem;
  const { locatedProvince } = vehicle;
  const ignoreWarning =
    JSON.parse(ignoreWarningItemIds)?.includes(auctionItemId);
  const addToIgnoreList = () =>
    setIgnoreWarningItemIds(
      JSON.stringify([auctionItemId, ...JSON.parse(ignoreWarningItemIds)]),
    );

  const handleCreateBid = amount => {
    if (hideWarningSelection) addToIgnoreList();
    createBid({
      variables: {
        bid: {
          amount: parseInt(amount, 10),
          auctionItemId,
          dealerId,
        },
      },
    })
      .then(() => {
        onClose();
        refetch(); // I hate refetching!  But the subscription doesn't seem to be
        // consistently getting the dealerBids consistently, so here we are....
        // Might want to do some fancier stuff on the subscription, but this works
        // for now.
      })
      .catch(err => errorHandler(() => {}, setError)(err));
  };

  const onSubmit = ({ amount }) =>
    dealerProvinceName !== locatedProvince && !ignoreWarning
      ? setShowConfirmBidDialog(true)
      : handleCreateBid(amount);

  useEffect(() => {
    // Must manually clear errors when form isDirty (to get rid of the manually set errors.error)
    if (isDirty) clearErrors();
  }, [clearErrors, isDirty, amount]);

  const handleConfirmBid = () =>
    handleSubmit(({ amount }) => handleCreateBid(amount))();
  // NOTE: Using isElapsed from useTimeSyncContext here instead of in ItemDetails
  // component, since it causes a LOT of re-renders!

  // If the item status is notActive, but the current time is between the
  // start and end times, refetch.
  if (isElapsed(startsAt) && !isActive && !isElapsed(endsAt)) refetch();
  // If the item is active, but the current time is past the end time, refetch.
  if (isElapsed(endsAt) && isActive) refetch();

  return (
    <Dialog open={open} onClose={onClose}>
      <ConfirmDialog
        isOpen={showConfirmBidDialog}
        onClose={() => setShowConfirmBidDialog(false)}
        text={outOfProvinceWarning(dealerProvinceName, locatedProvince)}
        onConfirm={handleConfirmBid}
        content={
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={hideWarningSelection}
                  onChange={() => setHideWarningSelection(p => !p)}
                />
              }
              label="Don't show this warning again for this item."
            />
          </Box>
        }
      />
      <DialogTitle>CreateBid</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Box>
            <ErrorMessage errors={errors} name="error" />
          </Box>
          <TextFieldControl
            autoFocus
            control={control}
            label="Amount"
            name="amount"
            variant="currencyDollars"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit">Submit</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

BidForm.fragments = {
  auctionItem: gql`
    fragment BidFormAuctionItemFragment on AuctionItem {
      endsAt
      startsAt
      isActive
      vehicle {
        locatedProvince
      }
    }
  `,
};
export default BidForm;
