import { useEffect, useState } from 'react';
import gql from 'graphql-tag';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';

const YEARS_QUERY = gql`
  query getYears {
    inventory {
      years: getYearsDrilldown
    }
  }
`;

const MAKES_QUERY = gql`
  query getMakes($year: Int!) {
    inventory {
      makes: getMakesDrilldown(year: $year) {
        name
        id
      }
    }
  }
`;

const MODELS_QUERY = gql`
  query getModels($year: Int!, $makeId: Int!) {
    inventory {
      models: getModelsDrilldown(year: $year, make_id: $makeId) {
        name
        id
      }
    }
  }
`;

const useYearMakeModel = ({ selectedYear, selectedMakeId }) => {
  const [year, setYear] = useState(selectedYear);
  const [makeId, setMakeId] = useState(selectedMakeId);

  const { data: yearsData, loading: yearsLoading } = useQuery(YEARS_QUERY);

  const [getMakes, makesQuery] = useLazyQuery(MAKES_QUERY);
  const [getModels, modelsQuery] = useLazyQuery(MODELS_QUERY);

  const years = yearsData?.inventory?.years || [];
  const makes = (year !== null && year !== ''
    ? makesQuery.data?.inventory?.makes || []
    : []
  ).sort((a, b) => a.name.localeCompare(b.name));
  const models = (year !== null && year !== '' && makeId
    ? modelsQuery.data?.inventory?.models || []
    : []
  ).sort((a, b) => a.name.localeCompare(b.name));

  useEffect(() => {
    if (year) getMakes({ variables: { year } });
  }, [year, getMakes]);

  useEffect(() => {
    if (makeId && year) getModels({ variables: { year, makeId } });
  }, [makeId, year, getModels]);

  const loading = yearsLoading || makesQuery.loading || modelsQuery.loading; // combine loading states of all the queries

  return { loading, makes, models, setMakeId, setYear, years };
};

export default useYearMakeModel;
