import React from 'react';
import {
  Grid,
  Typography,
  Box,
  InputAdornment,
  TextField,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import ScatterChart from './charts/ScatterChart';
import BellCurve from './charts/BellCurveChart';
import PriceTrendChart from './charts/PriceTrendChart';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import { formatPrice, usePersistedState } from 'utils';
import VehicleRank from './VehicleRank';
import gql from 'graphql-tag';
import { useFormContext, useWatch } from 'react-hook-form';
import { cashAdjustments } from 'modules/inventory/utils';
import { useUserContext } from 'components/MaterialUI/UserContext';
import TextFieldControl from 'components/MaterialUI/TextFieldControl';

const dollarStart = {
  startAdornment: <InputAdornment position="start">$</InputAdornment>,
};

const useStyles = makeStyles(() => ({
  gridSection: {
    width: '100%',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  gridTop: {
    borderRadius: '10px 10px 0 0',
    backgroundColor: '#fd5600',
    color: 'white',
  },
  gridBottom: {
    borderRadius: '0 0 10px 10px',
  },
  gridItemContainer: {
    boxShadow: '0px 1px 2px black',
    borderRadius: '10px',
  },
  chartIcon: {
    width: '15px',
    height: '15px',
  },
}));

const StatGridItem = ({ title, value, suffix, dollars }) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} sm={3}>
      <Box className={classes.gridItemContainer}>
        <Box className={`${classes.gridSection} ${classes.gridTop}`}>
          <Typography variant="body2">
            <span style={{ fontWeight: 'bold' }}>{title}</span>
            {suffix && (
              <>
                &nbsp;
                <span>{suffix}</span>
              </>
            )}
          </Typography>
        </Box>
        <Box className={`${classes.gridSection} ${classes.gridBottom}`}>
          <Typography variant="body2">
            {dollars && '$'}
            {value?.toLocaleString('en-US')}
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
};

const ListingStatsCard = ({
  allListingsData,
  listingsMetadata,
  dealers,
  vehicle,
}) => {
  const [selectedGraph, setSelectedGraph] = usePersistedState(
    'selected-graph',
    'scatter',
  );
  const { chartIcon } = useStyles();

  const { control } = useFormContext();

  const regular = useWatch({ control, name: 'regular_price' });
  const detailedPricing = useWatch({ control, name: 'detailed_pricing' });

  const { currentUser } = useUserContext();

  const listPrice =
    (regular ? parseFloat(regular) : 0) +
    cashAdjustments(
      detailedPricing?.length > 0 ? detailedPricing : [],
      currentUser?.goUserProfile?.settings?.timezone,
    );

  return (
    <Grid container xs={12} spacing={2}>
      <Grid item xs={12} md={6}>
        <Grid container xs={12} display="flex" flexDirection="row">
          <Typography
            variant="body2"
            style={{ display: 'inline', marginRight: '20px' }}
          >
            <span style={{ fontWeight: 'bold' }}>Mean Days to Turn:</span>{' '}
            {listingsMetadata.mean_days_to_turn}
          </Typography>
          <Typography variant="body2" style={{ display: 'inline' }}>
            <span style={{ fontWeight: 'bold' }}>Market Days Supply:</span>{' '}
            {listingsMetadata.market_days_supply}
          </Typography>
        </Grid>
        <Grid container xs={12} spacing={3} style={{ margin: '10px 0' }}>
          <StatGridItem
            title="Min Price"
            value={listingsMetadata?.minimum_price}
            dollars
          />
          <StatGridItem
            title="Max Price"
            value={listingsMetadata?.maximum_price}
            dollars
          />
          <StatGridItem
            title="Avg. Price"
            value={listingsMetadata?.mean_price}
            dollars
          />
          <StatGridItem
            title="Median Price"
            value={listingsMetadata?.median_price}
            dollars
          />
          <StatGridItem
            title="Min Mileage"
            value={listingsMetadata?.minimum_mileage}
            suffix="(km)"
          />
          <StatGridItem
            title="Max Mileage"
            value={listingsMetadata?.maximum_mileage}
            suffix="(km)"
          />
          <StatGridItem
            title="Avg. Mileage"
            value={listingsMetadata?.mean_mileage}
            suffix="(km)"
          />
          <StatGridItem
            title="Median Mileage"
            value={listingsMetadata?.median_mileage}
            suffix="(km)"
          />
          <Grid item xs={6} md={3}>
            <TextFieldControl
              control={control}
              name="regular_price"
              muiVariant="filled"
              label="Regular Price"
              InputProps={{
                ...dollarStart,
              }}
              defaultValue=""
              autoFocus
              onKeyDown={e => {
                if (e.key === 'Enter') e.preventDefault();
              }}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <TextField
              label="List Price"
              variant="filled"
              value={listPrice}
              InputProps={{
                ...dollarStart,
              }}
              disabled
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <TextField
              label="Unit Cost"
              variant="filled"
              value={vehicle?.cost}
              InputProps={{
                ...dollarStart,
              }}
              disabled
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <TextField
              label="Profit"
              variant="filled"
              value={formatPrice(listPrice - vehicle?.cost, {
                leading: '',
                cents: true,
              })}
              InputProps={{
                ...dollarStart,
              }}
              disabled
            />
          </Grid>
        </Grid>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          margin="15px 0"
        >
          <VehicleRank
            vehicle={vehicle}
            listingsData={allListingsData}
            listPrice={listPrice}
          />
        </Box>
      </Grid>

      <Grid item xs={12} md={6}>
        <Grid
          container
          xs={12}
          display="flex"
          flexDirection="row"
          alignItems="end"
          justifyContent="flex-end"
        >
          <ToggleButtonGroup
            value={selectedGraph}
            onChange={(e, newGraph) => setSelectedGraph(newGraph)}
            exclusive
            style={{ marginRight: '10px' }}
          >
            <ToggleButton value="scatter">
              <BubbleChartIcon className={chartIcon} />
            </ToggleButton>
            <ToggleButton value="bellcurve">
              <EqualizerIcon className={chartIcon} />
            </ToggleButton>
            <ToggleButton value="price-trend">
              <ShowChartIcon className={chartIcon} />
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        {selectedGraph === 'scatter' ? (
          <ScatterChart
            listingsData={allListingsData}
            dealers={dealers}
            vehicle={vehicle}
            listPrice={listPrice}
          />
        ) : selectedGraph === 'bellcurve' ? (
          <BellCurve
            listingsData={allListingsData}
            vehicle={vehicle}
            listPrice={listPrice}
          />
        ) : (
          <PriceTrendChart listingsData={allListingsData} vehicle={vehicle} />
        )}
      </Grid>
    </Grid>
  );
};

ListingStatsCard.fragments = {
  vehicle: gql`
    fragment ListingStatsCardVehicle on GreaseInventoryVehicle {
      special_price_expires
      special_price
      regular_price
      is_special_price_enabled
      cost
    }
  `,
  listingStatistics: gql`
    fragment ListingStatsCardListingStatistics on ListingsStatisticsType {
      active_statistics {
        maximum_mileage
        maximum_price
        mean_mileage
        mean_price
        median_mileage
        median_price
        minimum_mileage
        minimum_price
        vehicle_count
      }
      mean_days_to_turn
      market_days_supply
      sold_statistics {
        maximum_mileage
        maximum_price
        mean_mileage
        mean_price
        median_mileage
        median_price
        minimum_mileage
        minimum_price
        vehicle_count
      }
    }
  `,
};

export default ListingStatsCard;
