import React from 'react';

/* Material UI */
import { useMediaQuery } from '@mui/material';

/* Experimental - not sure if we should bother keeping this.  Open to comments/updates/changes! */
const ResponsiveComponent = ({
  children,
  Mobile,
  Desktop,
  MobileProps = {},
  DesktopProps = {},
  breakpoint = 'xs',
  ...rest
}) =>
  useMediaQuery(theme => theme.breakpoints.down(breakpoint), {
    noSsr: true, // avoids double-render: https://mui.com/material-ui/react-use-media-query/
  }) ? (
    Mobile ? (
      <Mobile {...rest} {...MobileProps}>
        {children}
      </Mobile>
    ) : null
  ) : Desktop ? (
    <Desktop {...rest} {...DesktopProps}>
      {children}
    </Desktop>
  ) : null;

export default ResponsiveComponent;
