import React from 'react';

/* External */
import gql from 'graphql-tag';

/* Material UI */
import { cloudinaryCore } from 'utils';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

const photoUrl = ({ stock_photos: { results }, stock_image }) =>
  results.length > 0
    ? cloudinaryCore.url(results[0].cloudinary_public_id, {
        width: 150,
        crop: 'fit',
      })
    : stock_image?.url ?? '';

const TrimSelector = ({ decodeStyles, selectedStyleId, handleStyleSelect }) => {
  return (
    <Box width="100%">
      <DialogTitle>Choose a Style</DialogTitle>
      <List>
        {decodeStyles.map(decodeStyle => (
          <ListItem
            key={decodeStyle.style_id}
            selected={decodeStyle.style_id === selectedStyleId}
          >
            <Hidden smDown>
              <Grid container direction="row">
                <Grid item>
                  <img
                    alt="Stock"
                    style={{ width: '150px', paddingRight: '1rem' }}
                    src={photoUrl(decodeStyle)}
                  ></img>
                </Grid>
                <Grid item style={{ paddingRight: '1rem' }}>
                  <Box fontSize="20px">
                    {decodeStyle.year} {decodeStyle.make_name}{' '}
                    {decodeStyle.model_name} {decodeStyle.trim}
                  </Box>
                  <Box fontSize="14px" color="#999999">
                    {decodeStyle.name}
                  </Box>
                </Grid>
                <Grid item>
                  <Box display="block" marginTop="auto" marginBottom="auto">
                    <Button
                      onClick={() => handleStyleSelect(decodeStyle)}
                      style={{
                        backgroundColor: '#5bc236',
                        color: 'white',
                      }}
                    >
                      <CheckCircleIcon style={{ paddingRight: '5px' }} /> SELECT
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Hidden>
            <Hidden smUp>
              <Grid container direction="row">
                <Grid item>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    flexDirection="row"
                    width="100%"
                  >
                    <img
                      alt="Stock"
                      style={{ width: '150px', paddingRight: '2rem' }}
                      src={photoUrl(decodeStyle)}
                    ></img>
                    <Button
                      onClick={() => handleStyleSelect(decodeStyle)}
                      style={{
                        backgroundColor: '#5bc236',
                        color: 'white',
                        height: '30px',
                        marginTop: 'auto',
                        marginBottom: 'auto',
                        display: 'flex',
                      }}
                    >
                      <CheckCircleIcon style={{ paddingRight: '5px' }} /> SELECT
                    </Button>
                  </Box>
                </Grid>
                <Grid item direction="column" style={{ paddingRight: '1rem' }}>
                  <Box fontSize="14px">
                    {decodeStyle.year} {decodeStyle.make_name}{' '}
                    {decodeStyle.model_name} {decodeStyle.trim}
                  </Box>
                  <Box fontSize="12px" color="#999999">
                    {decodeStyle.name}
                  </Box>
                </Grid>
              </Grid>
            </Hidden>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

TrimSelector.fragments = {
  VINDecodeResult: gql`
    fragment TrimSelectorVINDecodeResult on VINDecodeResult {
      styles {
        style_id
        stock_image {
          url
        }
        stock_photos {
          results {
            cloudinary_public_id
          }
        }
        year
        make_name
        model_name
        trim
      }
    }
  `,
};

export default TrimSelector;
