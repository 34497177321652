import { useMutation, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';

import { useDealerContext } from 'components/MaterialUI/DealerContext';
import Loading from 'components/MaterialUI/Loading';
import { useUserContext } from 'components/MaterialUI/UserContext';

import { getFullDateTime, withSuffix } from 'utils';

const ADD_ATTACHMENT_MUTATION = gql`
  mutation attachments(
    $dataURI: String!
    $label: String!
    $vin: String!
    $dealerId: Int!
    $created_by: String!
    $extension: String!
  ) {
    attachment {
      createAttachment(
        dataURI: $dataURI
        label: $label
        tags: "RECON"
        vin: $vin
        dealer_id: $dealerId
        created_by: $created_by
        app_id: "smi.inventory"
        extension: $extension
      ) {
        id
      }
    }
  }
`;

const CONDITION_QUERY = gql`
  query getReconReports($vin: String!) {
    attachment {
      getAttachments(vin: $vin, tag: "RECON") {
        id
        tags
        label
        created_by
        date_created
        url
        user {
          display_name
        }
      }
    }
  }
`;

function getBase64(file) {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      resolve(reader.result);
    };
    reader.onerror = reject;
  });
}

const DEFAULT_UPLOAD = {
  name: '',
  dataURI: '',
  extension: '',
};

const ReconConditionModal = ({ open, handleClose, vin }) => {
  const theme = useTheme();
  const { dealerId } = useDealerContext();
  const { currentUser } = useUserContext();
  const { enqueueSnackbar } = useSnackbar();
  const [label, setLabel] = useState('');
  const [upload, setUpload] = useState(DEFAULT_UPLOAD);

  const [createAttachment, { loading: mutationLoading }] = useMutation(
    ADD_ATTACHMENT_MUTATION,
    {
      refetchQueries: ['getReconReports'],
      onCompleted: () => {
        setUpload(DEFAULT_UPLOAD);
        setLabel('');
      },
    },
  );

  const { loading, data } = useQuery(CONDITION_QUERY, {
    variables: { vin },
  });

  const reports = data?.attachment?.getAttachments ?? [];

  async function handleChange(event) {
    const files = event.target.files;
    const extension = files[0].name.split('.').slice(-1)[0];
    let base64file = await getBase64(files[0]);
    setUpload({
      name: files[0].name,
      dataURI: base64file,
      extension,
    });
  }

  const handleLabel = event => {
    setLabel(event.target.value);
  };

  if (loading) return <Loading />;

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Recon Condition Report</DialogTitle>
      <DialogContent>
        {reports.length === 0 ? (
          <Typography>No Condition Reports</Typography>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Report</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>By</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reports.map(report => (
                <TableRow key={report.id}>
                  <TableCell>
                    <a href={report.url}>{report.label}</a>
                  </TableCell>
                  <TableCell>
                    {getFullDateTime(
                      withSuffix(report.date_created, 'Z'),
                      currentUser?.goUserProfile?.settings?.timezone,
                    )}
                  </TableCell>
                  <TableCell>{report.user.display_name}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </DialogContent>
      <DialogTitle>Upload Condition Report</DialogTitle>
      <DialogContent>
        <form style={{ paddingTop: '1rem' }}>
          <TextField
            variant="outlined"
            size="small"
            style={{
              maxWidth: '175px',
              width: '100%',
            }}
            value={upload.name || ''}
            disabled
          />
          <Box component="span" style={{ paddingLeft: '1rem' }}>
            <input
              style={{ display: 'none' }}
              id="attachment-file"
              multiple
              type="file"
              onChange={handleChange}
            />
            <label htmlFor="attachment-file">
              <Button variant="contained" color="primary" component="span">
                <FolderIcon style={{ paddingRight: '5px' }} /> BROWSE
              </Button>
            </label>
          </Box>
          <Box component="div" style={{ padding: '1rem 0 1rem 0' }}>
            <TextField
              label="Label"
              size="small"
              style={{
                maxWidth: '250px',
                width: '100%',
              }}
              value={label}
              onChange={handleLabel}
            />
          </Box>
        </form>
      </DialogContent>
      <DialogActions>
        {!mutationLoading && (
          <Button
            variant="text"
            style={{ ...theme.actions.create }}
            onClick={() =>
              createAttachment({
                variables: {
                  dataURI: upload.dataURI,
                  label,
                  vin,
                  dealerId,
                  created_by: currentUser.username,
                  extension: upload.extension,
                },
              })
                .catch(e => {
                  console.log(e);
                })
                .then(r => {
                  if (r.data?.attachment.createAttachment.id) {
                    return enqueueSnackbar(
                      `Recon Condition Report Successfully Uploaded!`,
                    );
                  } else {
                    return enqueueSnackbar(
                      `Error creating condition report. Please make sure all data is provided in the fields.`,
                    );
                  }
                })
                .then(handleClose)
            }
          >
            Upload
          </Button>
        )}
        <Button
          onClick={handleClose}
          style={theme.actions.close}
          variant="text"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReconConditionModal;
