import React from 'react';
import { useUserContext } from 'components/MaterialUI/UserContext';
import { Redirect } from 'react-router-dom';


const withRequiresLogin = WrappedComponent => {
  return ({ location, ...props }) => {
    const { currentUser } = useUserContext();
    return currentUser ? (
      <WrappedComponent {...props} />
    ) : (
      <Redirect
        to={{
          pathname: '/login',
          state: { from: location, fromUrl: window.location.href },
        }}
      />
    );
  }
}

export default withRequiresLogin;
