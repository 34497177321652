import React, { useState } from 'react';
import gql from 'graphql-tag';

import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';

import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { Editor } from '@tinymce/tinymce-react';

import { tinyMCEApiUrl } from '../../../../constants';
import { useVehiclesQueryContext } from '../contexts/VehiclesQueryContext';
import ErrorDisplay from 'components/MaterialUI/ErrorDisplay';

const UPDATE_PUBLISHED_NOTES = gql`
  mutation updatePublishedNotes(
    $filters: [QueryFilter!]!
    $description: String!
  ) {
    inventory {
      updateBulkVehicles(
        filters: $filters
        data: { published_notes: $description }
      ) {
        id
        date_user_modified
        published_notes
      }
    }
  }
`;

const BulkVehicleDescription = ({ ids, open, onClose, selectByFilter }) => {
  const { filters: vehicleFilters, refetch } = useVehiclesQueryContext();
  const { enqueueSnackbar } = useSnackbar();

  const [description, setDescription] = useState();

  const [update, updateStatus] = useMutation(UPDATE_PUBLISHED_NOTES, {
    onCompleted: data => {
      // This _should_ refetch vehicles with the current sorting/filtering.
      refetch({ variables: { page: 1 } });
      onClose();
      enqueueSnackbar(
        `Successfully updated ${data.inventory.updateBulkVehicles.length} vehicles' descriptions.`,
      );
    },
    onError: () => null,
  });

  const filters = selectByFilter
    ? vehicleFilters
    : [{ model: 'Vehicle', field: 'id', op: 'in', value: ids }];
  const handleSubmit = () => update({ variables: { filters, description } });
  const handleEditorChange = x => setDescription(x);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{'Set Bulk Vehicle Description'}</DialogTitle>
      <DialogContent>
        {updateStatus.error && (
          <Alert severity="warning">
            <ErrorDisplay
              error={updateStatus.error}
              action="Updating Vehicles' Descriptions"
            />
          </Alert>
        )}
        <Editor
          apiKey={tinyMCEApiUrl}
          onEditorChange={handleEditorChange}
          plugins="wordcount"
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        {updateStatus.loading ? (
          <CircularProgress
            size={20}
            style={{ marginLeft: '40px', marginRight: '30px' }}
          />
        ) : (
          <Button
            autoFocus
            disabled={!description}
            onClick={handleSubmit}
            variant="contained"
            style={{
              backgroundColor: `${!description ? '#bfbebe' : '#74B72E'}`,
            }}
          >
            Save
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default BulkVehicleDescription;
