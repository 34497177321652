import React from 'react';

/* external */
import gql from 'graphql-tag';

/* Material UI */
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

/* internal */
import { formatPrice, titleize } from 'utils';
import { FREQUENCY_ABBREVIATIONS } from 'modules/pitches/const';

const useStyles = makeStyles(theme => ({
  headerRow: {
    '& th': {
      [theme.breakpoints.down('sm')]: {
        paddingTop: 0,
        paddingBottom: 0,
        lineHeight: '1rem',
        fontSize: '0.65rem',
      },
      fontWeight: 'bold',
    },
  },
  row: {
    '& td:first-child': {
      [theme.breakpoints.down('sm')]: {
        fontWeight: 'bold',
      },
    },
    '& td': {
      [theme.breakpoints.down('sm')]: {
        fontSize: '10px',
      },
    },
  },
}));

const PaymentOptionsTable = ({ vehicle, ...rest }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { paymentOptions = [] } = vehicle;

  const formatRate = rate => (rate ? `${rate.toFixed(2)}%` : 'N/A');
  const formatTerm = term => (term ? `${term} mo` : 'N/A');
  const formatBucks = bucks => formatPrice(bucks, { nullDisplay: 'N/A' });
  const freqAbbrev = freq => (freq ? FREQUENCY_ABBREVIATIONS[freq] ?? '' : '');

  const headerFields = mobile
    ? ['', ...paymentOptions.map(({ paymentType }) => titleize(paymentType))]
    : [
        'Method',
        'Rate',
        'Term',
        'Down',
        'Payment',
        'Residual',
        'Due at Delivery',
      ];

  const tableData = mobile
    ? [
        {
          key: 'rate',
          data: ['Rate', ...paymentOptions.map(x => formatRate(x.rate))],
        },
        {
          key: 'term',
          data: ['Term', ...paymentOptions.map(x => formatTerm(x.term))],
        },
        {
          key: 'down',
          data: ['Down', ...paymentOptions.map(x => formatBucks(x.cashDown))],
        },
        {
          key: 'payment',
          data: [
            'Payment',
            ...paymentOptions.map(
              x => `${formatBucks(x.amount)}${freqAbbrev(x.frequency)}`,
            ),
          ],
        },
        {
          key: 'residual',
          data: [
            'Residual',
            ...paymentOptions.map(x => formatBucks(x.residualAmount)),
          ],
        },
        {
          key: 'dueAtDelivery',
          data: [
            'Due at Delivery',
            ...paymentOptions.map(x => formatBucks(x.dueAtDelivery)),
          ],
        },
      ]
    : paymentOptions.map(x => ({
        key: x.id,
        data: [
          titleize(x.paymentType),
          formatRate(x.rate),
          formatTerm(x.term),
          formatBucks(x.cashDown),
          `${formatBucks(x.amount)} ${freqAbbrev(x.frequency)}`,
          formatBucks(x.residualAmount),
          formatBucks(x.dueAtDelivery),
        ],
      }));

  return (
    <TableContainer>
      <Table {...rest} size="small">
        <TableHead>
          <TableRow className={classes.headerRow}>
            {headerFields.map(x => (
              <TableCell key={x}>{x}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.map(({ key, data }) => (
            <TableRow key={key} className={classes.row}>
              {data.map((datum, index) => (
                <TableCell key={`${key}${index}`}>{datum}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

PaymentOptionsTable.fragments = {
  paymentOption: gql`
    fragment PaymentOptionsTablePaymentOption on PitchPaymentOption {
      id
      amount
      cashDown
      dueAtDelivery
      frequency
      paymentType
      rate
      residualAmount
      term
    }
  `,
};
export default PaymentOptionsTable;
