/* external */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

/* Material UI */
import {
  Box,
  Badge,
  Card,
  CardContent,
  Grid,
  Hidden,
  Typography,
  CardActionArea,
  Checkbox,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CancelIcon from '@mui/icons-material/Cancel';

/* internal */
import {
  getFullDateTime,
  formatPrice,
  withSuffix,
  cloudinaryCore,
} from 'utils';
import { useUserContext } from 'components/MaterialUI/UserContext';
import InventoryIconGroup from './InventoryIconGroup';
import noIMG from '../../img/no-image.jpg';

const useStyles = makeStyles(theme => ({
  multiLine: {
    whiteSpace: 'pre-line',
    fontSize: '12px',
    color: '#999999',
  },
  maybe: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    width: '100%',
  },
  smallText: {
    fontSize: '14px',
  },
}));

const formatPhoto = photo => {
  const displayPhoto = photo
    ? cloudinaryCore.url(photo.cloudinaryPublicId, {
        width: 200,
        crop: 'fit',
      })
    : noIMG;

  return displayPhoto;
};

const InventoryTableCardView = ({
  onSelectAllClick,
  selectByFilter,
  selected,
  setSelected,
  vehicles,
  setSelectByFilter,
}) => {
  const classes = useStyles();
  const { me } = useUserContext();
  const isSelected = ({ id }) => selected.includes(id) || selectByFilter;
  const handleClick = (e, { id }) => {
    // Add the new id in the same order as displayed vehicles.
    if (e.target.checked)
      setSelected(prev =>
        vehicles.map(v => v.id).filter(x => [...prev, id].includes(x)),
      );
    else setSelected(prev => prev.filter(x => x !== id));
    setSelectByFilter(false);
  };

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Checkbox
          onChange={onSelectAllClick}
          inputProps={{ 'aria-label': 'Select All Vehicles' }}
          color="secondary"
        />
        <strong>Select All</strong>
      </div>
      {vehicles.map(vehicle => {
        const isItemSelected = isSelected(vehicle);
        return (
          <div
            key={vehicle.id}
            style={{ paddingBottom: '0.5rem', display: 'flex' }}
          >
            <Hidden lgDown>
              <Checkbox
                color="secondary"
                style={{
                  display: 'block',
                  marginTop: 'auto',
                  marginBottom: 'auto',
                  marginRight: '10px',
                }}
                checked={isItemSelected}
                onClick={event => {
                  event.stopPropagation();
                  handleClick(event, vehicle);
                }}
              />
            </Hidden>
            <CardActionArea
              component={Link}
              to={`/npv-inventory/${vehicle.id}`}
            >
              <Card
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <CardContent className={classes.maybe}>
                  <div style={{ display: 'flex' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Badge
                        overlap="rectangular"
                        color="secondary"
                        badgeContent={vehicle.photoCount + '+'}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                      >
                        <img
                          alt="Vehicle"
                          style={{
                            width: '150px',
                            marginBottom: '0.5em',
                            objectFit: 'cover',
                          }}
                          src={formatPhoto(vehicle?.photos[0])}
                        />
                      </Badge>
                      <div>
                        <Hidden mdUp>
                          <InventoryIconGroup
                            vehicle={vehicle}
                            style={{ marginTop: '1em' }}
                          />
                        </Hidden>
                      </div>
                    </Box>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        paddingLeft: '25px',
                      }}
                    >
                      <div>
                        <Typography>
                          {vehicle?.model?.make?.manufacturer?.name}
                        </Typography>
                        <strong>{vehicle.displayName}</strong>
                      </div>
                      <div>
                        <Typography className={classes.multiLine}>
                          {vehicle.stockNumber} / {vehicle.stockType} /
                          {vehicle?.stockStatus?.name ?? ''}
                        </Typography>
                      </div>
                      <div>
                        <Typography className={classes.smallText}>
                          <strong>Type: </strong>
                          {vehicle?.model?.npvType?.name ?? ''} /
                          <strong>Model: </strong>
                          {vehicle?.model?.name ?? ''}
                        </Typography>
                        <Typography className={classes.smallText}>
                          <strong>Days in stock: </strong>
                          {vehicle?.daysInStock ?? ''}
                        </Typography>
                      </div>
                      <div>
                        <Hidden mdDown>
                          <InventoryIconGroup vehicle={vehicle} />
                        </Hidden>
                      </div>
                    </div>
                  </div>
                  <Box
                    sx={{
                      display: 'flex',
                      width: { sm: '100%', md: 'auto' },
                      marginTop: { sm: '1em', md: '0' },
                      flexDirection: { xs: 'column', sm: 'row', md: 'column' },
                      textAlign: { sm: 'left', md: 'right' },
                    }}
                  >
                    <Grid container style={{ marginTop: '0.5em' }}>
                      <Grid item xs={6} md={12}>
                        <strong>Cost: </strong>{' '}
                        {` ${formatPrice(vehicle?.cost)}`}
                      </Grid>
                      <Grid item xs={6} md={12}>
                        <strong>Regular Price: </strong>{' '}
                        {` ${formatPrice(vehicle?.regularPrice)}`}
                      </Grid>
                      <Grid item xs={6} md={12}>
                        <Box
                          sx={{
                            width: '150px',
                            display: { sm: 'block', md: 'inline-block' },
                            padding: { md: '0 0.5em' },
                            borderBottom: { sm: 'none', md: '1px solid black' },
                            alignItems: 'center',
                          }}
                        >
                          {vehicle.isOnSpecial ? (
                            <CheckCircleRoundedIcon
                              style={{
                                color: 'green',
                                fontSize: '1em',
                                marginRight: '0.5em',
                              }}
                            />
                          ) : (
                            <CancelIcon
                              style={{
                                color: 'red',
                                fontSize: '1em',
                                marginRight: '0.5em',
                              }}
                            />
                          )}
                          <strong>On Special</strong>
                        </Box>
                      </Grid>
                      <Grid item xs={6} md={12} style={{ marginLeft: 'auto' }}>
                        <strong>Sale Price: </strong>{' '}
                        {` ${formatPrice(vehicle?.specialPrice)}`}
                      </Grid>
                    </Grid>
                    <Box
                      className="classes.smallText"
                      sx={{
                        width: { sm: '100%' },
                        textAlign: { xs: 'left', md: 'right' },
                        marginLeft: { xs: '0', sm: 'auto' },
                        marginTop: { xs: '0.5em', md: 'auto' },
                      }}
                    >
                      <Box
                        sx={{
                          display: { xs: 'inline', sm: 'block', md: 'inline' },
                        }}
                      >
                        <strong>Last Updated: </strong>
                      </Box>
                      {getFullDateTime(
                        withSuffix(vehicle.modifiedAt, 'Z'),
                        me?.goUserProfile?.settings?.timezone,
                      )}
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </CardActionArea>
          </div>
        );
      })}
    </>
  );
};
export default InventoryTableCardView;
