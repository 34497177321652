import React, { useEffect } from 'react';

import ErrorDisplay from 'components/MaterialUI/ErrorDisplay';
import Loading from 'components/MaterialUI/Loading';
import gql from 'graphql-tag';
import { formatPrice, numberWithCommas } from 'utils';

import { useQuery } from '@apollo/react-hooks';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { formatEngineDescription, titleCase } from '../../utils';
import WizardImageCarousel from '../misc/WizardImageCarousel';
import VehicleAttachmentsReadOnlyTable from '../tables/VehicleAttachmentsReadOnlyTable';
import GroupInventoryCBBDetails from '../vehicle/cbb/GroupInventoryCBBDetails';

const VEHICLE_INFO = gql`
  query vehicleInfo($id: Int!) {
    inventory {
      getGroupVehicle(id: $id) {
        id
        cbb {
          condition
          equipment
          style
          trim
          vid
        }
        cost
        days_in_stock
        dealer_name
        displayName
        drive_type_name
        engine_config_name
        engine_cylinders
        engine_litres
        exterior_colour_name
        fuel_type_name
        grease_dealer {
          id
          address
          phone_number
        }
        group_age
        interior_colour_name
        list_price
        make_name
        model_name
        odometer
        passengers
        permissions {
          read_cost
        }
        photos {
          id
          cloudinary_public_id
        }
        published_trim
        stock_number
        stock_type
        transmission_name
        trim
        trim_variation
        upholstery_name
        vin
        year
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  vehicleName: {
    fontWeight: 'bold',
    fontSize: '16px',
  },
}));

const GroupVehicleInfoMobile = ({ vehicleId }) => {
  const classes = useStyles();

  const vehicleQuery = useQuery(VEHICLE_INFO, {
    skip: !vehicleId,
    variables: { id: vehicleId },
  });

  const vehicle = vehicleQuery.data?.inventory?.getGroupVehicle || {};

  useEffect(() => {
    const script = document.createElement('script');

    script.src = '//badging.carproof.com/Scripts/CarProofBadges.min.js';
    script.setAttribute('data-thirdpartykey', 'aZcGWDqHpEpQGMD2ssGVBQ==');
    script.id = 'carproof-badge-js';
    script.type = 'text/javascript';

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  });

  if (vehicleQuery.loading) return <Loading />;

  if (vehicleQuery.error)
    return (
      <ErrorDisplay action="fetching vehicle data" error={vehicleQuery.error} />
    );

  return (
    <Box m={1}>
      <Grid container spacing={2} m={1}>
        {/* Vehicle Details */}
        <Grid item xs={12}>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">Vehicle Details</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <Grid container>
                  <Grid container alignItems="center">
                    <Grid item>
                      <span className={classes.vehicleName}>
                        {vehicle.displayName}
                      </span>
                    </Grid>
                    <Grid container item>
                      <Grid item>
                        {vehicle.trim_variation}
                        <Chip
                          style={
                            vehicle.trim_variation ? { marginLeft: '1rem' } : {}
                          }
                          size="small"
                          label={titleCase(vehicle.stock_type)}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container item xs={12} justifyContent="center">
                    <Box maxWidth="350px">
                      {/* height specified keep the Dialog from 'jumping' on each photo render */}
                      <WizardImageCarousel
                        photos={vehicle.photos}
                        height="250px"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} style={{ paddingBottom: '2rem' }}>
                    <div
                      className="carproof detail carproof-badge"
                      data-vin={vehicle.vin}
                      data-type="slim"
                      data-language="en"
                    ></div>
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item xs={12}>
                    <div>
                      {vehicle.stock_number} / {vehicle.vin} /{' '}
                      {numberWithCommas(vehicle.odometer)} km
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <p>
                      <b>Days In Stock: </b>
                      {`${vehicle.days_in_stock || 'N/A'} (Group Age: ${
                        vehicle.group_age || 'N/A'
                      })`}
                    </p>
                    <p>
                      <b>Published Trim: </b>
                      {vehicle.published_trim || 'N/A'}
                    </p>
                    <p>
                      <b>Ext. Color: </b>
                      {vehicle.exterior_colour_name || 'N/A'}
                    </p>
                    <p>
                      <b>Int. Color: </b>
                      {vehicle.interior_colour_name || 'N/A'}
                    </p>
                    <p>
                      <b>Upholstery: </b>
                      {vehicle.upholstery_name || 'N/A'}
                    </p>
                    <p>
                      <b>Passengers: </b>
                      {vehicle.passengers || 'N/A'}
                    </p>
                  </Grid>
                  <Grid item xs={6}>
                    <p>
                      <b>Price: </b>
                      {formatPrice(vehicle.list_price, { nullDisplay: 'N/A' })}
                    </p>
                    {vehicle?.permissions?.read_cost && (
                      <p>
                        <b>Cost: </b>
                        {vehicle?.permissions?.read_cost === true
                          ? formatPrice(vehicle.cost, { nullDisplay: 'N/A' })
                          : '-'}
                      </p>
                    )}
                    <p>
                      <b>Engine: </b>
                      {formatEngineDescription(
                        vehicle.engine_config_name,
                        vehicle.engine_cylinders,
                        vehicle.engine_litres,
                      ) || 'N/A'}
                    </p>
                    <p>
                      <b>Transmission: </b>
                      {vehicle.transmission_name || 'N/A'}
                    </p>
                    <p>
                      <b>Fuel: </b>
                      {vehicle.fuel_type_name || 'N/A'}
                    </p>
                    <p>
                      <b>Drive: </b>
                      {vehicle.drive_type_name || 'N/A'}
                    </p>
                  </Grid>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>

        {/* Location */}
        <Grid item xs={12}>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">Location</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <Grid item xs={12}>
                  <p>{vehicle.dealer_name || 'N/A'}</p>
                  <p>{vehicle.grease_dealer?.address || 'N/A'}</p>
                  <p>{vehicle.grease_dealer?.phone_number || 'N/A'}</p>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>

        {/* CBB Details */}
        <Grid item xs={12}>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">Canadian Black Book Details</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <GroupInventoryCBBDetails vehicle={vehicle} />
            </AccordionDetails>
          </Accordion>
        </Grid>

        {/* Attachments */}
        <Grid item xs={12}>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">Attachments</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid item xs={12}>
                <VehicleAttachmentsReadOnlyTable vin={vehicle.vin} />
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </Box>
  );
};

export default GroupVehicleInfoMobile;
