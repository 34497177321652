import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useSnackbar } from 'notistack';
import { isEmpty } from 'lodash';

// MaterialUI
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Button,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

// internal
import {
  formatPrice,
  getMediumDate,
  getMediumDateTimeWithWeekday,
} from 'utils';
import { RoleGroup } from 'constants.js';
import { useUserContext } from 'components/MaterialUI/UserContext';
import { useDealerContext } from 'components/MaterialUI/DealerContext';

const CREATE_BID = gql`
  mutation createBid($bid: CarSenseBidInput!) {
    carSense {
      createBid(bid: $bid) {
        id
        amount
      }
    }
  }
`;

const BiddingSubmit = ({
  bidAmount,
  setBidAmount,
  biddingEndsAt,
  bids,
  vehicleId,
  myBid,
  refetch,
}) => {
  const theme = useTheme();
  const { currentUser } = useUserContext();
  const isManager = RoleGroup.MANAGERS.includes(currentUser.role);
  const [createBid] = useMutation(CREATE_BID, {
    onCompleted: res => {
      enqueueSnackbar('Bid Submitted.', { variant: 'success' });
      refetch();
    },
  });
  const { dealerId } = useDealerContext();
  const { enqueueSnackbar } = useSnackbar();
  return (
    <>
      <Box
        style={{
          fontWeight: 'bold',
          paddingRight: '1rem',
          paddingBottom: '0.5rem',
        }}
      >
        PLACE YOUR BID
      </Box>
      <Grid
        container
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingBottom: '1rem',
        }}
        direction="row"
        xs={12}
      >
        <Grid
          item
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
          xs={6}
        >
          <Box>
            You have until{' '}
            {biddingEndsAt
              ? getMediumDateTimeWithWeekday(biddingEndsAt + 'Z')
              : '-'}{' '}
            to bid on this vehicle. <b>NOTE:</b> Once you place it, your bid
            cannot be changed!
          </Box>
        </Grid>
        <Grid
          item
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
          xs={6}
        >
          {isEmpty(myBid) && (
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                paddingBottom: '0.5rem',
              }}
            >
              <Box style={{ paddingRight: '1rem' }}>
                <TextField
                  placeholder="Bid Amount"
                  value={bidAmount || ''}
                  onChange={e => setBidAmount(e.target.value)}
                  variant="outlined"
                  size="small"
                />
              </Box>
              <Box>
                <Button
                  style={theme.actions.confirm}
                  variant="contained"
                  onClick={() => {
                    createBid({
                      variables: {
                        bid: {
                          dealerId,
                          amount: parseInt(bidAmount, 10),
                          vehicleId,
                        },
                      },
                    });
                  }}
                >
                  {isEmpty(myBid) ? 'Submit' : 'Update Bid'}
                </Button>
              </Box>
            </Box>
          )}
          <Box
            style={{
              color: 'grey',
              fontSize: !isEmpty(myBid) ? '20px' : '',
              paddingLeft: !isEmpty(myBid) ? '1rem' : '',
            }}
          >
            <b>Your current bid:</b>{' '}
            {isEmpty(myBid) ? "You haven't bid yet" : formatPrice(myBid.amount)}
          </Box>
        </Grid>
      </Grid>
      {isManager && (
        <>
          <Box style={{ fontWeight: 'bold' }}>All Bids</Box>
          <Box>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableCell>Bid</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Bidder</TableCell>
                </TableHead>
                <TableBody>
                  {bids.map((bid, index) => (
                    <TableRow key={index}>
                      <TableCell>{formatPrice(bid.amount)}</TableCell>
                      <TableCell>{getMediumDate(bid.createdAt)}</TableCell>
                      <TableCell>{bid.createdByUser.display_name}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </>
      )}
    </>
  );
};

export default BiddingSubmit;
