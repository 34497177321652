import React, { useEffect, useState } from 'react';

/* external */
import { NetworkStatus } from 'apollo-boost';
import { startCase } from 'lodash';
import { useQuery } from '@apollo/react-hooks';
import { Waypoint } from 'react-waypoint';
import gql from 'graphql-tag';

/* Material UI */
import {
  Box,
  Checkbox,
  Fab,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Tooltip,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';

/* internal */
import {
  BID_COUNT_SORT,
  DEALER_SORT,
  ENDS_AT_SORT,
  FAVE_FIRST_SORT,
  IS_FAVOURITE_SORT,
  LEADING_BID_SORT,
  MIN_START_BID_SORT,
  RESERVE_SORT,
  SORT_BY_ID,
  VEHICLE_SORT_OPTIONS,
} from './const';
import { filtersFromFacets } from './utils';
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import { usePersistedState } from 'utils';
import { WHOLESALER_DEALER_IDS } from 'constants.js';
import ActiveItemsRow from './ActiveItemsRow';
import FilterChips from './common/FilterChips';
import FilterDrawer from './common/FilterDrawer';
import Loading from 'components/MaterialUI/Loading';
import SortPopover from './common/SortPopover';
import { ItemCountAlert } from './common/ItemCountAlert';
import { AuctionBulkActions } from './AuctionBulkActions';

const PAGE_SIZE = 20;

const ACTIVE_ITEMS_QUERY = gql`
  query activeItems(
    $dealerId: Int!
    $page: Int!
    $pageSize: Int!
    $filters: [QueryFilter]
    $sort: [QuerySortElement]
  ) {
    auctions {
      items(page: $page, filters: $filters, pageSize: $pageSize, sort: $sort) {
        results {
          id
          createdAt
          isActive
          ...ActiveItemsRowAuctionItem
        }
        pagination {
          total
          page
          nextPage
        }
      }
    }
  }
  ${ActiveItemsRow.fragments.auctionItem}
`;

const ITEM_FEED_SUBSCRIPTION = gql`
  subscription onAuctionItemUpdated($auctionIds: [Int!]!) {
    auctionItemFeed(auctionIds: $auctionIds) {
      type
      auctionItem {
        id
        createdAt
        isActive
        ...AuctionItemsFeed
      }
    }
  }
  ${ActiveItemsRow.fragments.auctionItemFeed}
`;

const ACTIVE_ITEMS_CARFAX_QUERY = gql`
  query activeItemsCarfax($filters: [QueryFilter]) {
    auctions {
      carfaxBulkBadging(filters: $filters) {
        vehicleId
        badgeUrl
        reportUrl
      }
    }
  }
`;

const ActiveItemsTable = ({ auction }) => {
  const { id, isDaily } = auction;
  const { dealerId } = useDealerContext();

  const [scrollRestoration, setScrollRestoration] = usePersistedState(
    'auctions.components.items.scrollRestoration',
    {},
  );

  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
  const [newIds, setNewIds] = useState([]);
  const [checkedIds, setCheckedIds] = useState(new Set());
  const [sortSelection, setSortSelection] = usePersistedState(
    'auctions.components.items.ActiveItemsTable.sortSelection',
    null,
  );
  const [sortFavesFirst, setSortFavesFirst] = usePersistedState(
    'auctions.components.items.ActiveItemsTable.sortFavesFirst',
    false,
  );
  const [searchKeywords, setSearchKeywords] = usePersistedState(
    'auctions.components.items.ActiveItemsTable.searchKeywords',
    '',
  );
  const [searchFilters, setSearchFilters] = useState([]);
  const [facetFilters, setFacetFilters] = usePersistedState(
    'auctions.components.items.ActiveItemsTable.facetFilters',
    [],
  );
  const [popoverAnchor, setPopoverAnchor] = useState();

  /*
    year: exact match,
    make: starts with keyword,
    model: starts with keyword,
    stock_number: contains keyword
  */
  const setSearchFiltersFromKeywords = () =>
    setSearchFilters(
      searchKeywords.split(' ').map(keyword => ({
        or: [
          {
            model: 'Vehicle',
            field: 'make',
            op: 'ilike',
            value: `${keyword}%`,
          },
          {
            model: 'Vehicle',
            field: 'model',
            op: 'ilike',
            value: `${keyword}%`,
          },
          {
            model: 'Vehicle',
            field: 'stockNumber',
            op: 'ilike',
            value: `%${keyword}%`,
          },
          {
            model: 'Vehicle',
            field: 'vin',
            op: 'ilike',
            value: `%${keyword}%`,
          },
          {
            model: 'Vehicle',
            field: 'year',
            op: '==',
            value: parseInt(keyword) || 0,
          },
        ],
      })),
    );

  useEffect(() => {
    if (searchKeywords) setSearchFiltersFromKeywords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const inThisAuction = {
    model: 'AuctionItem',
    field: 'auctionId',
    op: 'eq',
    value: id,
  };

  const baseActiveItemsFilters = [
    inThisAuction,
    { model: 'AuctionItem', field: 'isActive', op: 'eq', value: true },
  ];

  const filters = [
    ...baseActiveItemsFilters,
    ...searchFilters,
    ...filtersFromFacets(facetFilters),
  ];

  const sort = [
    ...(sortFavesFirst ? [FAVE_FIRST_SORT] : []),
    sortSelection,
    SORT_BY_ID,
  ].filter(x => x);

  const { data, loading, fetchMore, networkStatus, subscribeToMore } = useQuery(
    ACTIVE_ITEMS_QUERY,
    {
      variables: {
        dealerId,
        filters,
        page: 1,
        pageSize: scrollRestoration?.index
          ? Math.ceil((parseInt(scrollRestoration.index, 10) + 1) / PAGE_SIZE) *
            PAGE_SIZE
          : PAGE_SIZE,
        sort,
      },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onCompleted: () => {
        if (scrollRestoration?.scrollY) {
          window.scrollTo(0, scrollRestoration.scrollY);
          setScrollRestoration(null);
        }
      },
    },
  );

  const items = data?.auctions.items.results ?? [];
  const deletableItemIds = new Set(
    items
      .filter(
        x => dealerId === x.dealerId && x.permissions.delete && !x.deleted,
      )
      .map(x => x.id),
  );
  const { nextPage, total } = data?.auctions.items.pagination ?? {};

  const carfaxQuery = useQuery(ACTIVE_ITEMS_CARFAX_QUERY, {
    variables: {
      filters: [
        {
          model: 'Vehicle',
          field: 'id',
          op: 'in',
          value: items.map(x => x.vehicle.id),
        },
      ],
    },
    skip: !items?.length > 0,
  });

  const carfaxes = carfaxQuery.data?.auctions.carfaxBulkBadging ?? [];

  useEffect(
    () =>
      subscribeToMore({
        document: ITEM_FEED_SUBSCRIPTION,
        variables: { auctionIds: [id] },
        updateQuery: (prev, { subscriptionData }) => {
          const { type, auctionItem } = subscriptionData?.data.auctionItemFeed;
          if (type === 'created' && auctionItem.isActive) {
            // Yes, let's add these in, since we are no longer refetching the entire
            // auction when it's "stale".  Note that once we have staggered start
            // time auctions again, we'll have to do something like have an indicator
            // that there are new items added with a button to reload or something,
            // since we don't want to insert items in during live bidding.
            // Also, these will need to be inserted in the current sorting order,
            // which is going to be a bit tricky once we add in sort headers.

            // Also, this really messes up pagination, since the new item
            // could be inserted anywhere, and we could potentially be getting
            // duplicate items on subsequent fetchMore ops.  Just refetch for now.

            // Solution: insert at top of list, mark as new, omit from fetchMore
            // results.

            // If we don't like this, we'll just change this to refetch()
            setNewIds(prev => [...prev, auctionItem.id]);

            return Object.assign({}, prev, {
              auctions: {
                __typename: prev.auctions.__typename,
                items: {
                  __typename: prev.auctions.items.__typename,
                  results: [
                    {
                      ...auctionItem,
                      dealerBids: [],
                      dealerProxyBids: [],
                      dealerBlindBid: null,
                      permissions: {
                        update: false,
                        delete: false,
                        __typename: 'AuctionItemPermissions',
                      },
                      isFavourite: null,
                    },
                    ...prev.auctions.items.results,
                  ],
                  pagination: Object.assign(
                    {},
                    prev.auctions.items.pagination,
                    { total: prev.auctions.items.pagination.total + 1 },
                  ),
                },
              },
            });
          }
          // should be no need for special handling of type === 'deleted', since it
          // will have the 'deleted' field set to True
        },
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleMoreItems = () =>
    nextPage
      ? fetchMore({
          variables: { page: nextPage, pageSize: PAGE_SIZE },
          updateQuery: (prev, { fetchMoreResult: more }) => {
            if (!more.auctions.items.results) return prev;
            return Object.assign({}, prev, {
              auctions: {
                __typename: prev.auctions.__typename,
                items: {
                  __typename: prev.auctions.items.__typename,
                  results: [
                    ...prev.auctions.items.results,
                    ...more.auctions.items.results.filter(
                      item =>
                        !prev.auctions.items.results
                          .map(x => x.id)
                          .includes(item.id),
                    ),
                  ],
                  pagination: more.auctions.items.pagination,
                },
              },
            });
          },
        })
      : null;

  function handleCheckItem(id) {
    setCheckedIds(prev => {
      const ids = new Set(prev);
      if (!ids.delete(id)) {
        ids.add(id);
      }
      return ids;
    });
  }

  function handleCheckAllItems(e) {
    setCheckedIds(new Set(e.target.checked ? deletableItemIds : null));
  }

  const handleSearch = e =>
    e.key === 'Enter' ? setSearchFiltersFromKeywords() : null;

  const isSortingBy = ({ model, field, direction }) =>
    sortSelection?.field === field &&
    sortSelection?.model === model &&
    (!direction || sortSelection?.direction === direction);

  const sortDirection = sortSelection?.direction;
  const clickSortHandler =
    ({ model, field }) =>
    () =>
      setSortSelection(prev => ({
        model,
        field,
        direction:
          prev?.model === model &&
          prev?.field === field &&
          prev?.direction === 'asc'
            ? 'desc'
            : 'asc',
      }));

  const vehicleSort = VEHICLE_SORT_OPTIONS.find(isSortingBy);

  const vehicleSortLabel = vehicleSort
    ? vehicleSort.options?.headerLabel ?? `(${startCase(vehicleSort.field)})`
    : null;

  // If it's a daily auction type, and we're a wholesaler, show the reserve
  // column.
  const showReserveColumn = isDaily && WHOLESALER_DEALER_IDS.includes(dealerId);

  if (loading && networkStatus !== NetworkStatus.fetchMore) return <Loading />;

  return (
    <>
      <FilterDrawer
        anchor="left"
        open={filterDrawerOpen}
        onClose={() => setFilterDrawerOpen(false)}
        filters={filters}
        facetFilters={facetFilters}
        setFacetFilters={setFacetFilters}
      />
      <SortPopover
        anchorEl={popoverAnchor}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={Boolean(popoverAnchor)}
        onClose={() => setPopoverAnchor(null)}
        sortOptions={VEHICLE_SORT_OPTIONS}
        setSortSelection={setSortSelection}
        sortSelection={sortSelection}
      />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <Box display="flex">
          <AuctionBulkActions
            checkedIds={checkedIds}
            filters={filters}
            sort={sort}
          />
          <Box paddingRight={2}>
            <Tooltip title="Filter list">
              <Fab
                color="primary"
                onClick={() => setFilterDrawerOpen(true)}
                size="medium"
              >
                <FilterListIcon />
              </Fab>
            </Tooltip>
          </Box>
          <Box paddingLeft={2}>
            <FormControlLabel
              label="Sort Favourites First"
              control={
                <Switch
                  color="primary"
                  checked={sortFavesFirst}
                  onChange={() => setSortFavesFirst(p => !p)}
                />
              }
            />
          </Box>
        </Box>

        <Box padding={1} flexGrow={2}>
          <FilterChips filters={facetFilters} setFilters={setFacetFilters} />
        </Box>
        <Box display="flex" flexDirection="column" alignItems="flex-end">
          <Box>
            <TextField
              placeholder="Search ..."
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      disabled={!searchKeywords}
                      onClick={() => {
                        setSearchKeywords('');
                        setSearchFilters([]);
                      }}
                      size="large"
                    >
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              value={searchKeywords}
              onChange={e => setSearchKeywords(e.target.value)}
              style={{ paddingRight: '5px', textAlign: 'right' }}
              onKeyDown={handleSearch}
            />
          </Box>

          <Box paddingTop={1} fontSize="18px">
            Showing {items.length} of {total}
          </Box>
        </Box>
      </Box>

      <ItemCountAlert nItems={checkedIds.size} />

      <Table>
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox">
              <Tooltip
                title={`Click to ${
                  checkedIds.size === deletableItemIds.size
                    ? 'uncheck'
                    : 'check'
                } all deletable items`}
              >
                <Checkbox
                  indeterminate={
                    0 < checkedIds.size &&
                    checkedIds.size < deletableItemIds.size
                  }
                  checked={
                    0 < checkedIds.size &&
                    checkedIds.size === deletableItemIds.size
                  }
                  onChange={handleCheckAllItems}
                />
              </Tooltip>
            </TableCell>
            <TableCell />
            {/* bid/delete */}
            <TableCell />
            {/* photo */}
            <TableCell>
              <TableSortLabel
                onClick={e => setPopoverAnchor(e.currentTarget)}
                active={VEHICLE_SORT_OPTIONS.some(isSortingBy)}
                direction={sortDirection}
              >
                Vehicle {vehicleSortLabel}
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={isSortingBy(IS_FAVOURITE_SORT)}
                direction={sortDirection}
                onClick={clickSortHandler(IS_FAVOURITE_SORT)}
              >
                Favourite
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={isSortingBy(DEALER_SORT)}
                direction={sortDirection}
                onClick={clickSortHandler(DEALER_SORT)}
              >
                Seller
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={isSortingBy(ENDS_AT_SORT)}
                direction={sortDirection}
                onClick={clickSortHandler(ENDS_AT_SORT)}
              >
                End
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={isSortingBy(BID_COUNT_SORT)}
                direction={sortDirection}
                onClick={clickSortHandler(BID_COUNT_SORT)}
              >
                Bids
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={isSortingBy(MIN_START_BID_SORT)}
                direction={sortDirection}
                onClick={clickSortHandler(MIN_START_BID_SORT)}
              >
                Min Bid
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={isSortingBy(LEADING_BID_SORT)}
                direction={sortDirection}
                onClick={clickSortHandler(LEADING_BID_SORT)}
              >
                High Bid
              </TableSortLabel>
            </TableCell>
            {showReserveColumn && (
              <TableCell>
                <TableSortLabel
                  active={isSortingBy(RESERVE_SORT)}
                  direction={sortDirection}
                  onClick={clickSortHandler(RESERVE_SORT)}
                >
                  Reserve
                </TableSortLabel>
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {items?.map((item, index) => (
            <ActiveItemsRow
              isNew={newIds.includes(item.id)}
              isChecked={checkedIds.has(item.id)}
              isDeletable={deletableItemIds.has(item.id)}
              handleCheckItem={handleCheckItem}
              item={item}
              key={item.id}
              showReserveColumn={showReserveColumn}
              index={index}
              carfax={carfaxes.find(x => x.vehicleId === item?.vehicle.id)}
            />
          ))}
        </TableBody>
      </Table>
      {!loading && <Waypoint onEnter={handleMoreItems} />}
      {loading && networkStatus === NetworkStatus.fetchMore ? (
        <Loading text="Loading more auction items..." />
      ) : (
        nextPage && <Box height="6rem" />
      )}
    </>
  );
};

ActiveItemsTable.fragments = {
  auction: gql`
    fragment ActiveItemsTableAuction on Auction {
      isDaily
    }
  `,
};

export default ActiveItemsTable;
