import React, { useState } from 'react';

/* external */
import gql from 'graphql-tag';
import { useSnackbar } from 'notistack';
import { useMutation } from '@apollo/react-hooks';

/* Material UI */
import AddIcon from '@mui/icons-material/Add';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material';

/* internal */
import OpportunityAppointmentList from './OpportunityAppointmentList';
import OpportunityAppointmentModal from './OpportunityAppointmentModal';
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import { useUserContext } from 'components/MaterialUI/UserContext';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '50px',
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  addIcon: {
    padding: '0px',
    margin: '0px 5px 0px 10px',
  },
}));

const UPDATE_APPOINTMENT = gql`
  mutation updateAppointment($id: ID!, $input: UpdateAppointmentInput!) {
    updateAppointment(_id: $id, input: $input) {
      _id
      attendees {
        username
        display_name
        profile {
          cell_phone
          desk_phone
          email_address
          first_name
          last_name
          image_id
        }
      }
      created_by_username
      customer_id
      dealer_id
      dealer {
        dealer_name
      }
      is_confirmed
      is_upcoming
      opportunity_id
      start_date
      status
      subject
      type_id
      type_name
      status_name
    }
  }
`;

const OpportunityAppointments = ({
  appointments,
  customer,
  opportunity,
  refetch,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const { dealerId } = useDealerContext();
  const { currentUser } = useUserContext();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [showAddAppointmentModal, setShowAddAppointmentModal] = useState(false);

  const [updateAppointment] = useMutation(UPDATE_APPOINTMENT, {
    onCompleted: data => {
      enqueueSnackbar(`Successfully updated appointment`, {
        variant: 'success',
      });
      refetch();
    },
    onError: e =>
      enqueueSnackbar(`Error in updating appointment`, {
        variant: 'error',
      }),
  });
  const updateStatus = (id, value) => {
    updateAppointment({
      variables: {
        id: id,
        input: {
          status: value,
        },
      },
    });
  };

  const confirmAppointment = appointment_id => {
    updateAppointment({
      variables: {
        id: appointment_id,
        input: {
          is_confirmed: true,
          confirmation: {
            dealer_id: dealerId,
            username: currentUser.username,
          },
        },
      },
    });
  };

  const setAppointmentAndOpenEditModal = appointment => {
    setSelectedAppointment(appointment);
    setShowAddAppointmentModal(true);
  };

  return (
    <>
      <Dialog
        maxWidth="md"
        fullWidth={true}
        scroll="paper"
        open={showAddAppointmentModal}
        onClose={() => {
          setShowAddAppointmentModal(false);
        }}
      >
        <OpportunityAppointmentModal
          customer={customer}
          updateStatus={updateStatus}
          opportunity={opportunity}
          appointment={selectedAppointment}
          handleClose={() => {
            setShowAddAppointmentModal(false);
          }}
          refetch={refetch}
        />
      </Dialog>
      <Card className={classes.root}>
        <CardContent>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            spacing={3}
          >
            <Grid item>
              <Typography className={classes.title} gutterBottom>
                Appointments
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                onClick={setAppointmentAndOpenEditModal.bind(this, null)}
                size="small"
              >
                <AddIcon style={theme.actions.add} />
              </IconButton>
            </Grid>
          </Grid>
          <OpportunityAppointmentList
            appointments={appointments}
            updateStatus={updateStatus}
            setAppointmentAndOpenEditModal={setAppointmentAndOpenEditModal}
            confirmAppointment={confirmAppointment}
          />
        </CardContent>
      </Card>
    </>
  );
};

OpportunityAppointments.fragments = {
  opportunity: gql`
    fragment OpportunityAppointments on Opportunity {
      _id
      appointments {
        _id
        attendees {
          display_name
          username
        }
        attendee_usernames
        created_by {
          username
        }
        dealer {
          dealer_name
        }
        status
        dealer_id
        status_name
        is_upcoming
        is_confirmed
        start_date
        type_id
        type_name
      }
    }
  `,
};

export default OpportunityAppointments;
