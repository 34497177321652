import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import ErrorPage from 'components/MaterialUI/ErrorPage';
import { useUserContext } from 'components/MaterialUI/UserContext';

/* https://www.robinwieruch.de/local-storage-react */
const useSessionStateInt = (key, defaultValue) => {
  const [value, setValue] = useState(
    isNaN(parseInt(sessionStorage.getItem(key)))
      ? defaultValue ?? null
      : parseInt(sessionStorage.getItem(key)),
  );

  useEffect(() => {
    if (value) sessionStorage.setItem(key, value);
  }, [key, value]);

  return [value, setValue];
};

const useLocalStateInt = (key, defaultValue = null, stash = true) => {
  const itemValue = parseInt(localStorage.getItem(key) || defaultValue);
  if (stash) localStorage.removeItem(key);
  const [value, _setValue] = useState(itemValue);

  const setValue = newValue => {
    _setValue(newValue);
    localStorage.setItem(key, newValue);
  };

  return [value, setValue];
};

const DealerContext = createContext({
  dealers: [],
  dealerId: null,
  setDealerId: () => {},
  dealerProvinceName: null,
  greaseDealer: null,
  greaseDealers: [], // TODO: "dealers" should be "greaseDealers"
  //(ie. we want to remove greaseDealer and greaseDealers in the future)
  // But we'll have to modify all usage of "dealers" everywhere useDealerContext
  // is used before we can do that.
});

// This requires UserContext to work now (updated 2022-11-07 with changes for global dealer id)
export const DealerContextProvider = ({
  children,
  sessionKey = 'dealerId',
}) => {
  const { currentUser } = useUserContext() || {};

  const userDealers = useMemo(() => currentUser?.dealers ?? [], [currentUser]);

  const greaseDealers = useMemo(
    () => currentUser?.greaseDealers ?? [],
    [currentUser],
  );

  const userDealerIds = useMemo(
    () => userDealers?.map(x => x.dealer_id),
    [userDealers],
  );
  const userDefaultDealerId = useMemo(
    () => currentUser?.goUserProfile?.settings?.default_dealer_id,
    [currentUser],
  );

  // This is used for the helper app (see HelperAppVinScanner.jsx)
  const [stashedDealerId, stashDealerId] = useLocalStateInt(sessionKey);
  const [dealerId, setDealerId] = useSessionStateInt(sessionKey);
  const [dealerProvinceName, setDealerProvinceName] = useState();
  const [greaseDealer, setGreaseDealer] = useState();

  useEffect(() => {
    if (currentUser) {
      if (!dealerId) {
        // No dealer id at all?  Set it to (in order or preference)
        // - stashedDealerId
        // - defaultDealerId (verify if in list of dealers)
        // - first dealer id in user's list of dealers
        if (stashedDealerId && userDealerIds.includes(stashedDealerId))
          setDealerId(stashedDealerId);
        else if (
          userDefaultDealerId &&
          userDealerIds.includes(userDefaultDealerId)
        )
          setDealerId(userDefaultDealerId);
        else setDealerId(userDealerIds?.[0]); // note: could be null if no user dealer ids.  That's okay.
      } else if (!userDealerIds.includes(dealerId)) {
        // dealer id is set, BUT it's not in the list of userDealerIds
        if (userDealerIds.length > 0) {
          if (
            userDefaultDealerId &&
            userDealerIds.includes(userDefaultDealerId)
          )
            setDealerId(userDefaultDealerId);
          else setDealerId(userDealerIds?.[0]); // first on in the list, or null
        }
      }
    }
  }, [
    currentUser,
    dealerId,
    setDealerId,
    stashedDealerId,
    userDealerIds,
    userDefaultDealerId,
  ]);

  useEffect(() => {
    if (dealerId && userDealers) {
      const currentDealer = userDealers.find(x => x.dealer_id === dealerId);
      if (greaseDealers) {
        const currentGreaseDealer = greaseDealers.find(x => x.id === dealerId);
        if (currentGreaseDealer?.id !== greaseDealer?.id)
          setGreaseDealer(currentGreaseDealer);
      }

      if (currentDealer?.province_name !== dealerProvinceName)
        setDealerProvinceName(currentDealer.province_name);
    }
  }, [
    dealerId,
    greaseDealer,
    greaseDealers,
    dealerProvinceName,
    setDealerProvinceName,
    userDealers,
  ]);

  if (currentUser && (userDealers.length === 0 || !dealerId))
    return (
      <ErrorPage>
        {`No dealers found for ${currentUser?.display_name ?? 'current user'}`}.
        Contact support to add dealer access to your account.
      </ErrorPage>
    );
  return (
    <DealerContext.Provider
      value={{
        dealers: userDealers,
        greaseDealers,
        greaseDealer,
        dealerId,
        setDealerId,
        stashDealerId,
        dealerProvinceName, // TODO: We don't need this now that we're setting the
        // greaseDealer in our DealerContext provider (though we'll want to change it
        // from greaseDealer -> just dealer)
      }}
    >
      {children}
    </DealerContext.Provider>
  );
};

export const useDealerContext = () => useContext(DealerContext);

export default DealerContextProvider;
