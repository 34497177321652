import { Tab, Paper } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import gql from 'graphql-tag';
import React, { useState } from 'react';
import ListingStatsCard from './ListingStatsCard';

const ListingStats = ({ data, vehicle }) => {
  const [currentTab, setCurrentTab] = useState(0);

  const extraMetadata = {
    market_days_supply:
      data?.listingsstatistics?.listings_statistics.market_days_supply,
    mean_days_to_turn:
      data?.listingsstatistics?.listings_statistics.mean_days_to_turn,
  };

  const StatsCard = props => (
    <ListingStatsCard
      {...props}
      dealers={data.organization.greaseDealers}
      vehicle={vehicle}
    />
  );

  return (
    <>
      <TabContext value={currentTab}>
        <TabList onChange={(_, newValue) => setCurrentTab(newValue)}>
          <Tab label="Active Listing Stats" />
          <Tab label="Sold Listing Stats" />
        </TabList>
        <Paper style={{ padding: '10px 15px' }} elevation={4}>
          <TabPanel value={0}>
            <StatsCard
              allListingsData={data.listings.listings.filter(
                l => l.listing_type === 'Active' && l.vin !== vehicle.vin,
              )}
              listingsMetadata={{
                ...data.listingsstatistics.listings_statistics
                  .active_statistics,
                ...extraMetadata,
              }}
            />
          </TabPanel>
          <TabPanel value={1}>
            <StatsCard
              allListingsData={data.listings.listings.filter(
                l => l.listing_type === 'Sold' && l.vin !== vehicle.vin,
              )}
              listingsMetadata={{
                ...data.listingsstatistics.listings_statistics.sold_statistics,
                ...extraMetadata,
              }}
            />
          </TabPanel>
        </Paper>
      </TabContext>
    </>
  );
};

ListingStats.fragments = {
  listingStatistics: gql`
    fragment ListingStatsListingStatistics on ListingsStatisticsType {
      market_days_supply
      mean_days_to_turn
    }
  `,
  listings: gql`
    fragment ListingStatsListingsResult on ListingType {
      listing_type
      vin
    }
  `,
  vehicle: gql`
    fragment ListingStatsVehicle on GreaseInventoryVehicle {
      vin
    }
  `,
};

export default ListingStats;
