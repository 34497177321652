import React, { useRef, useState } from 'react';
import { withStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import { cloneDeep } from 'lodash';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import InspectionCheckboxes from '../InspectionCheckboxes';

const HondaCPOForm = props => {
  const { enqueueSnackbar } = useSnackbar();

  // Honda certified used vehicles pre-owned mechanical inspection checklist form fields
  const history = {
    h00: 'Maintenance/Repair Records',
    h01: 'Safety Recalls/Product Updates',
    h02: 'Owner, Accessory and Warranty Manuals',
    h03: 'Radio Anti-Theft Code/Key Code',
    h04: 'Remote Keys/Proximity Keys (2)/ Wheel Lock Key (if appl.)',
    h05: 'Transfer HondaLink Ownership',
    h06: 'Head Unit Factory Data Reset',
    h07: 'Homelink Reset (if appl.)',
  };
  const replacement = {
    h1: '1. Oil and filter',
    h2: '2. Windshield Wiper Inserts',
    h3: '3. In-cabin Air Filter and Engine Air Filter (only if required after inspection)',
    h4: '4. Tires - if less than 5/32" tread remaining',
    h5: '5. Brakes - if less than 4mm pad thickness remaining',
    h6: '6. Bring Maintenance to current levels for age/mileage',
  };
  const road = {
    h7: '7. Braking, ABS operation (noise, vibration, effort)',
    h8: '8. Transmission shift quality/ effort / noise / operation (clutch if appl.)',
    h9: '9. CV joint noise (full lock turn R/L)',
    h10: '10. Steering (effort, drifting, pull)',
    h11: '11. Cruise control operation',
    h12: '12. (Electronic) parking brake operation (incl. free movement ond adjustment)',
    h13: '13. Horn operation',
    h14: '14. Speedometer/odometer operation',
    h15: '15. Heater/defroster operation',
    h16: '16. Climate control function and performance',
    h17: '17. Tire vibration',
    h18: '18. Engine operation (performance, temperature, idle & throttle)',
  };
  const underhood = {
    h19: '19. Battery (corrosion, oge, load test)',
    h20: '20. Radiator (leaks, road hazard damage)',
    h21: '21. Cooling fan operation',
    h22: '22. Coolant (level, strength, replace per maintenance schedule)',
    h23: '23. Power steering (fluid level, belt tension)',
    h24: '24. Fuel system connections (leakage)',
    h25: '25. A/C compressor (proper cycling, belt tension, leaks)',
    h26: '26. Condenser (corrosion, road hazard damage)',
    h27: '27. Alternator belt tension (replace if necessary)',
    h28: '28. Timing belt replace if within 5,000km of maintenance schedule (if appl.)',
  };
  const tires = {
    h29: '29. All four matching brand and tread design',
    h30: '30. OEM size/min. speed and load rating',
    h31: '31. OEM or Honda accessory or wheel of equivalent specifications',
    h32: '32. Wheels (balanced and properly torqued)',
    h33: '33. Tire repair kit or spare tire/tools (condition and pressure)',
    h34: '34. Tread depth, tire pressure, condition and TPMS operation',
  };
  const engine = {
    h35: '35. Engine (oil leaks, drain plug threads)',
    h36: '36. Driveshaft boots (cracks, leakage)',
    h37: '37. Transmission (condition, leaks, fluid level, replace fluid based on condition or per maintenance schedule)',
    h38: '38. AWD / 4WD system (if appl.)',
    h39: '39. Test compression if engine shows any signs of excessive smoke, poor performance or rough idle',
  };
  const brakes = {
    h40: '40. Pad lining thickness',
    h41: '41. Caliper (movement, condition ond leaks)',
    h42: '42. Brake fluid (replace if contaminated or per maintenance schedule)',
    h43: '43. Rotor thickness and surface (rear drum incl. condition of rear wheel cylinder if appl.)',
    h44: '44. Hydraulic hose/line (leakage)',
  };
  const suspension = {
    h45: '45. Damage/wear',
    h46: '46. Shock absorber (leakage)',
    h47: '47. Bushing condition',
    h48: '48. Ball joint tightness/boot condition',
  };
  const steering = {
    h49: '49. Power steering rack (leakage, wear and boot condition)',
    h50: 'SO. Tie rod end tightness/boot condition',
  };
  const exhaust = {
    h51: '51. Leaks/rattles damage (must be OEM or Honda approved exhaust system)',
    h52: '52. Tail pipe inspection for oil/anti-freeze residue',
  };
  const exterior = {
    h53: '53. Headlights operational (incl. daytime running lights, high/low beam, turn and fog lights if appl.)',
    h54: '54. Tail lights operational (incl. brake, turn/bocking/hazard warning lights)',
    h55: '55. Water leaks',
    h56: '56. Moonroof/water drains clear',
    h57: '57. Power trunk operational (incl. Handsfree if appl.)',
    h58: '58. Fuel door opening operation',
  };
  const interior = {
    h59: '59. Instrument/gauge cluster operation and condition',
    h60: '60. All warning lights (illuminated with ignition "on")',
    h61: '61. Audio & Infotainment function (incl. navigation system, touch screen, DVD entertainment system if appl.)',
    h62: '62. Window operation',
    h63: '63. Interior mirrors condition and operation',
    h64: '64. Door locks operation (incl. Smart Entry and Security System function)',
    h65: '65, Seatbelts (proper latching/retraction, webbing condition)',
    h66: '66, Windshield wiper/washer operation/fluid level',
    h67: '67. Rear defogger operation',
    h68: '68. Seat/power seat/headrest operation (incl. heated/ventilated operation if appl.)',
    h69: '69. Interior lights operation (switch/door)',
    h70: '70. Moonroof operation (if appl.)',
    h71: '71. Exterior mirrors condition and operation (incl. blind spot information, adjustment, heater, power-folding operation)',
    h72: '72. Rear view camera operation',
    h73: '73. Honda Sensing system operational',
    h74: '74. Remote/proximity key entry function (incl remote start if appl.)',
    h75: '75. Steering wheel controls function (incl. heated steering wheel if appl.)',
  };
  const exterior2 = {
    h76: '76. Hood/Underhood (finish, emblems, insulation, decals)',
    h77: '77.	Front Bumper (fascia, guard, finish)',
    h78: '78.	Grille (headlights, other lights, trim, emblems)',
    h79: '79.	LF Fender (finish, trim)',
    h80: '80. LF Door (finish, trim)',
    h81: '81. LR Fender (finish, trim)',
    h82: '82.	LR Door (finish, trim)',
    h83: '83.	Trunk Lid (finish, trim)',
    h84: '84.	Rear Bumper (fascia, guards, finish)',
    h85: '85.	RR Fender (finish, trim)',
    h86: '86.	RR Door (finish, trim)',
    h87: '87.	RF Fender (finish, trim)',
    h88: '88.	RF Door (finish, trim)',
    h89: '89.	Roof (finish, trim)',
    h90: '90.	Wheel & Wheel Cover (scratches, pitting)',
    h91: '91. Window Glass (Front, Rear, Side)',
  };
  const interior2 = {
    h92: '92. Front Seats (cushions, trim)',
    h93: '93. Driver Door (cushions, trim)',
    h94: '94.	Passenger Door (cushions, trim)',
    h95: '95.	Carpet & Mats (condition)',
    h96: '96.	Rear Seats (cushions, trim)',
    h97: '97.	LR Door/Quarter Trim (controls, trim)',
    h98: '98.	RR Door/Quarter Trim (controls, trim)',
    h99: '99. Headliner, Visors, Package Tray, and Sunglasses Holder ',
    h100: '100. Luggage Compartment (trim)',
  };

  let historyObj = cloneDeep(history);
  let replacementObj = cloneDeep(replacement);
  let roadObj = cloneDeep(road);
  let underhoodObj = cloneDeep(underhood);
  let tiresObj = cloneDeep(tires);
  let engineObj = cloneDeep(engine);
  let brakesObj = cloneDeep(brakes);
  let suspensionObj = cloneDeep(suspension);
  let steeringObj = cloneDeep(steering);
  let exhaustObj = cloneDeep(exhaust);
  let exteriorObj = cloneDeep(exterior);
  let interiorObj = cloneDeep(interior);
  let exterior2Obj = cloneDeep(exterior2);
  let interior2Obj = cloneDeep(interior2);

  Object.entries(historyObj).map(([name]) => (historyObj[name] = 'Yes'));
  Object.entries(replacementObj).map(([name]) => (replacementObj[name] = 'OK'));
  Object.entries(roadObj).map(([name]) => (roadObj[name] = 'OK'));
  Object.entries(underhoodObj).map(([name]) => (underhoodObj[name] = 'OK'));
  Object.entries(tiresObj).map(([name]) => (tiresObj[name] = 'OK'));
  Object.entries(engineObj).map(([name]) => (engineObj[name] = 'OK'));
  Object.entries(brakesObj).map(([name]) => (brakesObj[name] = 'OK'));
  Object.entries(suspensionObj).map(([name]) => (suspensionObj[name] = 'OK'));
  Object.entries(steeringObj).map(([name]) => (steeringObj[name] = 'OK'));
  Object.entries(exhaustObj).map(([name]) => (exhaustObj[name] = 'OK'));
  Object.entries(exteriorObj).map(([name]) => (exteriorObj[name] = 'OK'));
  Object.entries(interiorObj).map(([name]) => (interiorObj[name] = 'OK'));
  Object.entries(exterior2Obj).map(([name]) => (exterior2Obj[name] = 'OK'));
  Object.entries(interior2Obj).map(([name]) => (interior2Obj[name] = 'OK'));

  const [historyValues, setHistoryValues] = useState(historyObj);
  const [replacementValues, setReplacementValues] = useState(replacementObj);
  const [roadValues, setRoadValues] = useState(roadObj);
  const [underhoodValues, setUnderhoodValues] = useState(underhoodObj);
  const [tiresValues, setTiresValues] = useState(tiresObj);
  const [engineValues, setEngineValues] = useState(engineObj);
  const [brakesValues, setBrakesValues] = useState(brakesObj);
  const [suspensionValues, setSuspensionValues] = useState(suspensionObj);
  const [steeringValues, setSteeringValues] = useState(steeringObj);
  const [exhaustValues, setExhaustValues] = useState(exhaustObj);
  const [exteriorValues, setExteriorValues] = useState(exteriorObj);
  const [interiorValues, setInteriorValues] = useState(interiorObj);
  const [exterior2Values, setExterior2Values] = useState(exterior2Obj);
  const [interior2Values, setInterior2Values] = useState(interior2Obj);

  const handleHistory = e => {
    const { name, value } = e.target;
    setHistoryValues(prevHistoryValues => ({
      ...prevHistoryValues,
      [name]: value,
    }));
  };
  const handleReplacement = e => {
    const { name, value } = e.target;
    setReplacementValues(prevReplacementValues => ({
      ...prevReplacementValues,
      [name]: value,
    }));
  };
  const handleRoad = e => {
    const { name, value } = e.target;
    setRoadValues(prevRoadValues => ({
      ...prevRoadValues,
      [name]: value,
    }));
  };
  const handleUnderhood = e => {
    const { name, value } = e.target;
    setUnderhoodValues(prevUnderhoodValues => ({
      ...prevUnderhoodValues,
      [name]: value,
    }));
  };
  const handleTires = e => {
    const { name, value } = e.target;
    setTiresValues(prevTiresValues => ({
      ...prevTiresValues,
      [name]: value,
    }));
  };
  const handleEngine = e => {
    const { name, value } = e.target;
    setEngineValues(prevEngineValues => ({
      ...prevEngineValues,
      [name]: value,
    }));
  };
  const handleBrakes = e => {
    const { name, value } = e.target;
    setBrakesValues(prevBrakesValues => ({
      ...prevBrakesValues,
      [name]: value,
    }));
  };
  const handleSuspension = e => {
    const { name, value } = e.target;
    setSuspensionValues(prevSuspensionValues => ({
      ...prevSuspensionValues,
      [name]: value,
    }));
  };
  const handleSteering = e => {
    const { name, value } = e.target;
    setSteeringValues(prevSteeringValues => ({
      ...prevSteeringValues,
      [name]: value,
    }));
  };
  const handleExhaust = e => {
    const { name, value } = e.target;
    setExhaustValues(prevExhaustValues => ({
      ...prevExhaustValues,
      [name]: value,
    }));
  };
  const handleExterior = e => {
    const { name, value } = e.target;
    setExteriorValues(prevExteriorValues => ({
      ...prevExteriorValues,
      [name]: value,
    }));
  };
  const handleInterior = e => {
    const { name, value } = e.target;
    setInteriorValues(prevInteriorValues => ({
      ...prevInteriorValues,
      [name]: value,
    }));
  };
  const handleExterior2 = e => {
    const { name, value } = e.target;
    setExterior2Values(prevExterior2Values => ({
      ...prevExterior2Values,
      [name]: value,
    }));
  };
  const handleInterior2 = e => {
    const { name, value } = e.target;
    setInterior2Values(prevInterior2Values => ({
      ...prevInterior2Values,
      [name]: value,
    }));
  };

  const [consent, setConsent] = useState(false);
  const handleConsent = () => {
    setConsent(consent => !consent);
  };

  const [confirm, setConfirm] = useState(false);
  const handleConfirm = () => {
    setConfirm(confirm => !confirm);
  };

  const wholeForm = useRef(null);

  const handleSubmit = e => {
    e.preventDefault();

    if (!consent) {
      enqueueSnackbar(`Please check 'I agree' and then submit the inspection!`);
      return;
    }

    tiresValues['h34_lf'] = wholeForm.current['h34_lf'].value;
    tiresValues['h34_rf'] = wholeForm.current['h34_rf'].value;
    tiresValues['h34_lr'] = wholeForm.current['h34_lr'].value;
    tiresValues['h34_rr'] = wholeForm.current['h34_rr'].value;
    brakesValues['h40_lf'] = wholeForm.current['h40_lf'].value;
    brakesValues['h40_rf'] = wholeForm.current['h40_rf'].value;
    brakesValues['h40_lr'] = wholeForm.current['h40_lr'].value;
    brakesValues['h40_rr'] = wholeForm.current['h40_rr'].value;
    for (let i = 76; i <= 91; i++) {
      exterior2Values['h' + i + '_estimate'] =
        wholeForm.current['h' + i + '_estimate'].value;
      exterior2Values['h' + i + '_describe'] =
        wholeForm.current['h' + i + '_describe'].value;
    }
    exterior2Values['exterior_note'] = wholeForm.current['exterior_note'].value;
    exterior2Values['exterior_total'] =
      wholeForm.current['exterior_total'].value;
    for (let i = 92; i <= 100; i++) {
      interior2Values['h' + i + '_estimate'] =
        wholeForm.current['h' + i + '_estimate'].value;
      interior2Values['h' + i + '_describe'] =
        wholeForm.current['h' + i + '_describe'].value;
    }
    exterior2Values['interior_note'] = wholeForm.current['interior_note'].value;
    exterior2Values['interior_total'] =
      wholeForm.current['interior_total'].value;

    const values = {
      ...historyValues,
      ...replacementValues,
      ...roadValues,
      ...underhoodValues,
      ...tiresValues,
      ...engineValues,
      ...brakesValues,
      ...suspensionValues,
      ...steeringValues,
      ...exhaustValues,
      ...exteriorValues,
      ...interiorValues,
      ...exterior2Values,
      ...interior2Values,
    };
    const form_data = {
      honda: values,
      amvic_number: wholeForm.current['amvic_number'].value,
    };

    props.formData(form_data);
    props.formClose();
  };

  const GreenButton = withStyles({
    root: {
      color: '#fff',
      backgroundColor: '#198754',
      '&:hover': {
        backgroundColor: '#157347',
      },
    },
  })(Button);

  return (
    <form onSubmit={handleSubmit} ref={wholeForm}>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Vehicle History & Information Items</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(history).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={historyValues}
                      handleGroup={handleHistory}
                      name={name}
                      orange={false}
                      greenValue="Yes"
                      redValue="No"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>
              Mandatory Replacement Items (Replaced with Genuine Honda parts,
              Fluids & Accessories)
            </b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(replacement).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={replacementValues}
                      handleGroup={handleReplacement}
                      name={name}
                      orange={false}
                      greenValue="OK"
                      redValue="SR"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Mechanical Inspection (including road test)</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(road).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={roadValues}
                      handleGroup={handleRoad}
                      name={name}
                      orange={false}
                      greenValue="OK"
                      redValue="SR"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Underhood</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(underhood).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={underhoodValues}
                      handleGroup={handleUnderhood}
                      name={name}
                      orange={false}
                      greenValue="OK"
                      redValue="SR"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Tires/Wheels</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(tires).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={tiresValues}
                      handleGroup={handleTires}
                      name={name}
                      orange={false}
                      greenValue="OK"
                      redValue="SR"
                    />
                  </Grid>
                  {name === 'h34' && (
                    <Grid
                      container
                      alignItems="center"
                      style={{ marginBottom: '1rem' }}
                    >
                      <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                        <TextField label="LF" name={'h34_lf'} />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField label="RF" name={'h34_rf'} />
                      </Grid>
                      <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                        <TextField label="LR" name={'h34_lr'} />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField label="RR" name={'h34_rr'} />
                      </Grid>
                    </Grid>
                  )}
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Engine/Driveline</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(engine).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={engineValues}
                      handleGroup={handleEngine}
                      name={name}
                      orange={false}
                      greenValue="OK"
                      redValue="SR"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Brakes</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(brakes).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={brakesValues}
                      handleGroup={handleBrakes}
                      name={name}
                      orange={false}
                      greenValue="OK"
                      redValue="SR"
                    />
                  </Grid>
                  {name === 'h40' && (
                    <Grid
                      container
                      alignItems="center"
                      style={{ marginBottom: '1rem' }}
                    >
                      <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                        <TextField label="LF" name={'h40_lf'} />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField label="RF" name={'h40_rf'} />
                      </Grid>
                      <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                        <TextField label="LR" name={'h40_lr'} />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField label="RR" name={'h40_rr'} />
                      </Grid>
                    </Grid>
                  )}
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Suspension</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(suspension).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={suspensionValues}
                      handleGroup={handleSuspension}
                      name={name}
                      orange={false}
                      greenValue="OK"
                      redValue="SR"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Steering</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(steering).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={steeringValues}
                      handleGroup={handleSteering}
                      name={name}
                      orange={false}
                      greenValue="OK"
                      redValue="SR"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Exhaust System</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(exhaust).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={exhaustValues}
                      handleGroup={handleExhaust}
                      name={name}
                      orange={false}
                      greenValue="OK"
                      redValue="SR"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Exterior</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(exterior).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={exteriorValues}
                      handleGroup={handleExterior}
                      name={name}
                      orange={false}
                      greenValue="OK"
                      redValue="SR"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Interior</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(interior).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={interiorValues}
                      handleGroup={handleInterior}
                      name={name}
                      orange={false}
                      greenValue="OK"
                      redValue="SR"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>EXTERIOR</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(exterior2).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={exterior2Values}
                      handleGroup={handleExterior2}
                      name={name}
                      orange={false}
                      greenValue="OK"
                      redValue="SR"
                    />
                  </Grid>
                  <Grid
                    container
                    alignItems="center"
                    style={{ marginBottom: '1rem' }}
                  >
                    <Grid item xs={4} style={{ paddingLeft: '1rem' }}>
                      <TextField
                        label="Estimate"
                        name={name + '_estimate'}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={8} style={{ paddingLeft: '1rem' }}>
                      <TextField
                        label="Describe"
                        name={name + '_describe'}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  {name === 'h91' && (
                    <Grid
                      container
                      alignItems="center"
                      style={{ marginBottom: '1rem' }}
                    >
                      <Grid item xs={12} style={{ paddingLeft: '1rem' }}>
                        <TextField
                          label="Note"
                          name={'exterior_note'}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={4} style={{ paddingLeft: '1rem' }}>
                        <TextField
                          label="Total Exterior"
                          name={'exterior_total'}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  )}
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>INTERIOR</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(interior2).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={interior2Values}
                      handleGroup={handleInterior2}
                      name={name}
                      orange={false}
                      greenValue="OK"
                      redValue="SR"
                    />
                  </Grid>
                  <Grid
                    container
                    alignItems="center"
                    style={{ marginBottom: '1rem' }}
                  >
                    <Grid item xs={4} style={{ paddingLeft: '1rem' }}>
                      <TextField
                        label="Estimate"
                        name={name + '_estimate'}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={8} style={{ paddingLeft: '1rem' }}>
                      <TextField
                        label="Describe"
                        name={name + '_describe'}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  {name === 'h100' && (
                    <Grid
                      container
                      alignItems="center"
                      style={{ marginBottom: '1rem' }}
                    >
                      <Grid item xs={12} style={{ paddingLeft: '1rem' }}>
                        <TextField
                          label="Note"
                          name={'interior_note'}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={4} style={{ paddingLeft: '1rem' }}>
                        <TextField
                          label="Total Interior"
                          name={'interior_total'}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  )}
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Typography style={{ marginTop: '15px' }}>
        I verify the contents of this document are true and correct.
      </Typography>
      <FormControlLabel
        control={<Checkbox />}
        label="I agree"
        onChange={handleConsent}
        value={consent}
      />
      <br />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Technician Name"
            value={props.user.display_name}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Tech #"
            value={props.user.goUserProfile.profile.sales_licence}
            fullWidth
            name={'amvic_number'}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Date"
            value={new Date().toLocaleString()}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <Button
            type="button"
            variant="contained"
            style={{ marginRight: '15px' }}
            onClick={handleConfirm}
            color="default"
          >
            Cancel
          </Button>
          <Dialog
            open={confirm}
            onClose={handleConfirm}
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Current inspection will be closed and unsaved data will be gone.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleConfirm}>Cancel</Button>
              <Button onClick={props.formClose} color="secondary">
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
          <GreenButton type="submit" variant="contained">
            Save
          </GreenButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default HondaCPOForm;
