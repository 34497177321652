import React, { useEffect } from 'react';
import GoTemplate from './GoTemplate';
import { URL } from '../../../constants';
import { useUserContext } from 'components/MaterialUI/UserContext';

const Logout = () => {
  const { setToken } = useUserContext();
  useEffect(() => {
    setToken(null);
    window.location.replace(URL.auth_logout);
    setTimeout(() => {}, 3000);
  }, [setToken]);
  return (
    <GoTemplate>
      {/* eslint-disable jsx-a11y/accessible-emoji */}
      <div>Peace out, homie ✌️</div>
    </GoTemplate>
  );
};

export default Logout;
