import React from 'react';

/* external */
import { useHistory } from 'react-router-dom';

/* Material UI */
import { Box, Button, Paper, Typography } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';

const DealerQuestionSubmitted = () => {
  const history = useHistory();
  return (
    <Box margin={1}>
      <Paper
        style={{
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center',
          height: '150px',
          marginLeft: 'auto',
          marginRight: 'auto',
          marginTop: 'auto',
          marginBottom: 'auto',
          padding: '1rem',
        }}
      >
        <Typography variant="h5">Your question has been submitted!</Typography>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
            height: '200px',
            marginLeft: 'auto',
            marginRight: 'auto',
            padding: '1rem',
          }}
        >
          {/* history.push back to main page. */}
          <Button
            variant="outlined"
            style={{ width: '100px' }}
            onClick={() => history.push('/used-vehicles/')}
          >
            <HomeIcon /> Home
          </Button>
        </div>
      </Paper>
    </Box>
  );
};

export default DealerQuestionSubmitted;
