import moment from 'moment-timezone';
import React from 'react';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';

import { AppointmentStatus } from 'constants.js';

const Attended = () => (
  <Box>
    <i className="far fa-thumbs-up" /> Attended
  </Box>
);
const Cancelled = () => (
  <Box>
    <i className="far fa-ban" /> Cancelled
  </Box>
);
const NoShow = () => (
  <Box>
    <i className="far fa-thumbs-down" /> No Show
  </Box>
);
const RequiresAction = () => (
  <Box>
    <i className="fas fa-exclamation-circle" /> Requires Action
  </Box>
);

const AppointmentStatusComponent = ({ appointment }) => (
  <Box>
    {appointment.status === AppointmentStatus.ATTENDED && (
      <Chip
        size="small"
        label={<Attended />}
        style={{
          color: '#fff',
          backgroundColor: '#468847',
          textTransform: 'uppercase',
        }}
      />
    )}
    {appointment.status === AppointmentStatus.CANCELLED && (
      <Chip
        size="small"
        label={<Cancelled />}
        color="secondary"
        style={{ textTransform: 'uppercase' }}
      />
    )}
    {appointment.status === AppointmentStatus.NO_SHOW && (
      <Chip
        size="small"
        label={<NoShow />}
        color="secondary"
        style={{ textTransform: 'uppercase' }}
      />
    )}
    {appointment.status === AppointmentStatus.UNKNOWN &&
      (appointment.is_confirmed === false ||
        !moment(appointment.start_date).isAfter(moment())) && (
        <Chip
          size="small"
          label={<RequiresAction />}
          style={{
            color: '#fff',
            backgroundColor: '#b94a48',
            textTransform: 'uppercase',
          }}
        />
      )}
  </Box>
);

export default AppointmentStatusComponent;
