import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';

const snackBarSettings = {
  autoHideDuration: 3000,
};

/**
 * @callback ErrorMsgCallback
 * @param {ApolloError} error Error object from {@link useMutation}
 * @return {string} Error message
 */

/**
 * {@link useMutation} wrapper that shows a success/error msg through snackbar.
 *
 * @param {*} mutation Same as in {@link useMutation}.
 * @param {string} completedMsg Snackbar message to show on success.
 * @param {(string|ErrorMsgCallback)} errorMsg Snackbar message to show on failure or an {@link ErrorMsgCallback}.
 * @param {function} onCompleted Gets called after showing Snackbar message.
 * @param {function} onError Gets called after showing Snackbar message.
 * @return Object from {@link useMutation}.
 */
export const useSnackMutation = (
  mutation,
  completedMsg,
  errorMsg,
  onCompleted = () => {},
  onError = () => {},
) => {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(mutation, {
    onCompleted: res => {
      enqueueSnackbar(completedMsg, {
        ...snackBarSettings,
        variant: 'success',
      });
      onCompleted(res);
    },
    onError: e => {
      const msg = errorMsg instanceof Function ? errorMsg(e) : errorMsg;
      enqueueSnackbar(msg, {
        ...snackBarSettings,
        variant: 'error',
      });
      onError(e);
    },
  });
};
