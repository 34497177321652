import React from 'react';

/* external */
import { Switch, Route } from 'react-router-dom';

/* internal */
import OpportunityDetails from './OpportunityDetails';
import OpportunitySearch from './OpportunitiesSearch';

import app from '../../app';

const Opportunities = ({ match }) => (
  <Switch>
    <Route
      exact
      path={`${match.url}/:opportunityId/details`}
      render={({ match }) => (
        <app.components.Layout title="Opportunity Details">
          <OpportunityDetails opportunityId={match.params.opportunityId} />
        </app.components.Layout>
      )}
    />
    {/* Include customerId in the route similar to desking */}
    <Route
      exact
      path={`${match.url}/:opportunityId/:customerId/details`}
      render={({ match }) => (
        <app.components.Layout title="Opportunity Details">
          <OpportunityDetails opportunityId={match.params.opportunityId} />
        </app.components.Layout>
      )}
    />
    <Route
      exact
      path={`${match.url}/search`}
      render={({ match }) => (
        <app.components.Layout title="Opportunities List">
          <OpportunitySearch />
        </app.components.Layout>
      )}
    />
  </Switch>
);

export default Opportunities;
