// external
import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import moment from 'moment';
import { useSnackbar } from 'notistack';

// mui
import { IconButton, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Close as CloseIcon } from '@mui/icons-material';
import useMediaQuery from '@mui/material/useMediaQuery';
import Loading from 'components/MaterialUI/Loading';

// internal
import PermissionsContext from '../contexts/PermissionsContext';
import CBBDetails from './cbb/CBBDetails';
import VehicleEquipment from './VehicleEquipment';
import VehicleForm from './VehicleForm';
import VehicleHistory from './VehicleHistory';
import VehicleOptions from './VehicleOptions';
import VehiclePhotos from './VehiclePhotos';
import DetailedPricing from './vehicle_info/DetailedPricing';
import EngineDetails from './vehicle_info/EngineDetails';
import PricingDetails from './vehicle_info/PricingDetails';
import PurchaseRates from './vehicle_info/PurchaseRates';
import VehicleDescription from './vehicle_info/VehicleDescription';
import VehicleDetails from './vehicle_info/VehicleDetails';
import ReconDetails from './recon/ReconDetails';
import UsedPricingTool from './UsedPricingTool';
import _ from 'lodash';

const VehicleInfo = ({ id }) => {
  const [submittedData, setSubmittedData] = useState();
  const mobile = useMediaQuery(theme => theme.breakpoints.up('sm'));

  const useStyles = makeStyles(theme => ({
    paper: {
      width: '100%',
      minHeight: '100vh',
      paddingBottom: `${mobile ? '2rem' : 0}`,
      marginBottom: theme.spacing(2),
    },
  }));
  const VEHICLE_QUERY = gql`
    query VehicleQuery($id: Int!) {
      inventory {
        getVehicle(id: $id) {
          id
          ...DetailedPricingVehicle
          ...EngineDetailsVehicle
          ...PricingDetailsVehicle
          ...VehicleDescriptionVehicle
          ...VehicleDetailsVehicle
          ...VehicleEquipmentVehicle
          ...VehicleFormVehicle
          ...VehicleHistoryVehicle
          ...VehicleInfoVehicle
          ...VehicleOptionsVehicle
          ...VehiclePhotosVehicle
          ...CBBDetailsVehicle
          ...PurchaseRatesVehicle
          ...ReconDetailsVehicle
          ...UsedPricingToolVehicle
        }
      }
    }
    ${DetailedPricing.fragments.vehicle}
    ${EngineDetails.fragments.vehicle}
    ${PricingDetails.fragments.vehicle}
    ${VehicleDescription.fragments.vehicle}
    ${VehicleDetails.fragments.vehicle}
    ${VehicleEquipment.fragments.vehicle}
    ${VehicleForm.fragments.vehicle}
    ${VehicleHistory.fragments.vehicle}
    ${VehicleInfo.fragments.vehicle}
    ${VehicleOptions.fragments.vehicle}
    ${VehiclePhotos.fragments.vehicle}
    ${CBBDetails.fragments.vehicle}
    ${PurchaseRates.fragments.vehicle}
    ${ReconDetails.fragments.vehicle}
    ${UsedPricingTool.fragments.vehicle}
  `;

  const UPDATE_VEHICLE = gql`
    mutation updateVehicle($id: Int!, $data: GreaseInventoryVehicleInput!) {
      inventory {
        updateVehicle(id: $id, data: $data) {
          id
          ...DetailedPricingVehicle
          ...EngineDetailsVehicle
          ...PricingDetailsVehicle
          ...VehicleDescriptionVehicle
          ...VehicleDetailsVehicle
          ...VehicleEquipmentVehicle
          ...VehicleFormVehicle
          ...VehicleHistoryVehicle
          ...VehicleInfoVehicle
          ...VehicleOptionsVehicle
          ...VehiclePhotosVehicle
          ...CBBDetailsVehicle
          ...PurchaseRatesVehicle
          ...ReconDetailsVehicle
        }
      }
    }
    ${DetailedPricing.fragments.vehicle}
    ${EngineDetails.fragments.vehicle}
    ${PricingDetails.fragments.vehicle}
    ${VehicleDescription.fragments.vehicle}
    ${VehicleDetails.fragments.vehicle}
    ${VehicleEquipment.fragments.vehicle}
    ${VehicleForm.fragments.vehicle}
    ${VehicleHistory.fragments.vehicle}
    ${VehicleInfo.fragments.vehicle}
    ${VehicleOptions.fragments.vehicle}
    ${VehiclePhotos.fragments.vehicle}
    ${CBBDetails.fragments.vehicle}
    ${PurchaseRates.fragments.vehicle}
    ${ReconDetails.fragments.vehicle}
  `;

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const vehicleQuery = useQuery(VEHICLE_QUERY, { variables: { id } });
  const vehicle = vehicleQuery?.data?.inventory?.getVehicle || {};

  const [updateVehicle, updateStatus] = useMutation(UPDATE_VEHICLE, {
    onCompleted: r => {
      enqueueSnackbar('Updated vehicle successfully!', {
        autoHideDuration: 2000,
        action: key => {
          return (
            <IconButton
              onClick={() => {
                closeSnackbar(key);
              }}
              size="large"
            >
              <CloseIcon style={{ color: 'white' }} />
            </IconButton>
          );
        },
      });
      setSubmittedData(null); // only keep submitted Data around if the update
      // fails
    },
  });
  const classes = useStyles();

  const toIntNullOrUndefined = x =>
    x === undefined || x === null ? x : parseInt(x, 10);

  const toDateNullOrUndefined = x =>
    x === undefined || x === null
      ? x
      : moment.isMoment(x)
      ? x.format('YYYY-MM-DD')
      : moment(x).isValid()
      ? moment(x).format('YYYY-MM-DD')
      : null;

  const toDateTimeNullOrUndefined = (x, zeroHour = false) =>
    x === undefined || x === null
      ? x
      : moment.isMoment(x)
      ? zeroHour
        ? x.format('YYYY-MM-DD') + 'T00:00:00'
        : x.toISOString()
      : null;

  const toFloatNullOrUndefined = x =>
    x === undefined || x === null ? x : parseFloat(x);

  const mapPhotos = photos =>
    photos === null || photos === undefined
      ? photos
      : photos.map(({ __typename, ...rest }) => rest);

  const processDetailedPricing = (
    detailed_pricing,
    rebates,
    detailed_pricing_no_rebates,
  ) => {
    detailed_pricing =
      rebates && detailed_pricing_no_rebates
        ? [...rebates, ...detailed_pricing_no_rebates]
        : detailed_pricing;
    return detailed_pricing.map(
      ({ id, value, expiry, __typename, ...rest }) => ({
        id: id ? parseInt(id, 10) : undefined,
        value: toFloatNullOrUndefined(value),
        expiry: toDateNullOrUndefined(expiry),
        ...rest,
      }),
    );
  };

  const processFinanceRates = finance_rates =>
    finance_rates.map(
      ({
        rate,
        __typename,
        bi_weekly_payment,
        residual_amount,
        residual_percent,
        km_allowance,
        ...rest
      }) => ({
        rate: parseFloat(rate),
        ...rest,
      }),
    );

  const processLeaseRates = lease_rates =>
    lease_rates.map(
      ({ rate, __typename, bi_weekly_payment, residual_amount, ...rest }) => ({
        rate: parseFloat(rate),
        ...rest,
      }),
    );

  const onSubmit = data => {
    setSubmittedData(data);
    const {
      cost,
      detailed_pricing,
      detailed_pricing_no_rebates,
      default_down_payment,
      engine_cylinders,
      engine_litres,
      equipment,
      finance_rates,
      is_on_special, // This is a property of vehicle.  Don't submit.
      lease_rates,
      list_price, // This is a property of vehicle.  Don't submit.
      make_name, // Convenience for cbb.  Don't submit.
      model_name, // Convenience for cbb.  Don't submit.
      msrp,
      odometer,
      options,
      photos,
      promotional_expiry_date,
      promotional_start_date,
      regular_price,
      rebates,
      special_price,
      special_price_expires,
      ...rest
    } = data;
    if (data?.cbb) {
      delete data.cbb.__typename;
    }
    updateVehicle({
      variables: {
        id,
        data: {
          default_down_payment: toIntNullOrUndefined(default_down_payment),
          detailed_pricing: detailed_pricing
            ? processDetailedPricing(
                detailed_pricing,
                detailed_pricing_no_rebates,
                rebates,
              )
            : detailed_pricing,
          engine_cylinders: toIntNullOrUndefined(engine_cylinders),
          engine_litres: toFloatNullOrUndefined(engine_litres),
          finance_rates: finance_rates
            ? processFinanceRates(finance_rates)
            : finance_rates,
          lease_rates: lease_rates
            ? processLeaseRates(lease_rates)
            : lease_rates,
          msrp: toIntNullOrUndefined(msrp),
          odometer: toIntNullOrUndefined(odometer),
          options: [
            ...options.map(({ __typename, ...rest }) => rest),
            ...equipment.map(({ __typename, ...rest }) => rest),
          ],
          photos: mapPhotos(photos),
          regular_price: toIntNullOrUndefined(regular_price),
          special_price: toIntNullOrUndefined(special_price),
          special_price_expires: toDateTimeNullOrUndefined(
            special_price_expires,
            true,
          ),
          promotional_expiry_date: toDateNullOrUndefined(
            promotional_expiry_date,
          ),
          promotional_start_date: toDateNullOrUndefined(promotional_start_date),
          ...rest,
        },
      },
    })
      .then(() => {
        if (equipment?.length > 0 && options?.length > 0) {
          enqueueSnackbar(
            'Your vehicle equipment has been automatically updated with your option choices.',
            {
              autoHideDuration: 2000,
              action: key => {
                return (
                  <IconButton
                    onClick={() => {
                      closeSnackbar(key);
                    }}
                    size="large"
                  >
                    <CloseIcon style={{ color: 'white' }} />
                  </IconButton>
                );
              },
            },
          );
        }
      })
      .catch(() => {});
  };

  if (vehicleQuery.loading) return <Loading />;
  if (vehicleQuery.error)
    return <>Error: {JSON.stringify(vehicleQuery.error)}</>;

  if (updateStatus.loading) return <>Saving...</>;
  return (
    <Paper className={classes.paper} elevation={0} square={true}>
      <PermissionsContext permissions={vehicle.permissions}>
        {/* Have to pass in stock_number and vin because if the form submit fails
        then we dont re-send the stock_number/vin in the submittedData. Form needs
        to be reworked after, but this should fix the errors for now until we rework
        the form. */}
        <VehicleForm
          vehicle={{
            ...(submittedData || vehicle),
            stock_number: vehicle.stock_number,
            vin: vehicle.vin,
            cost: vehicle.cost,
            msrp: vehicle.msrp,
            regular_price: vehicle.regular_price,
            special_price: vehicle.special_price,
            id: vehicle.id,
            detailed_pricing: vehicle.detailed_pricing,
          }}
          setDirty={Boolean(submittedData)}
          onSubmit={onSubmit}
          updateVehicleErrors={updateStatus.error}
        />
      </PermissionsContext>
    </Paper>
  );
};

VehicleInfo.fragments = {
  vehicle: gql`
    fragment VehicleInfoVehicle on GreaseInventoryVehicle {
      date_created
      default_down_payment
      displayName
      has_uvi
      is_on_special
      odometer
      permissions {
        read
        read_cost
        update
        update_detailed_pricing
      }
      regular_price
      special_price
      stock_number
      stock_status_name
      trim_variation
      vin
    }
  `,
};

export default VehicleInfo;
