import React from 'react';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';

/* Material UI */
import { makeStyles } from '@mui/styles';

import { useUserContext } from 'components/MaterialUI/UserContext';

import { getMediumDate } from 'utils';

import { TaskStatusNames } from '../constants';

const useStyles = makeStyles(theme => ({
  chipBox: {
    paddingRight: '5px',
    paddingBottom: '5px',
  },
}));

const FacetChips = ({
  tasks,
  dueFromDate,
  dueUntilDate,
  statuses,
  setStatuses,
  setDueFromDate,
  setDueUntilDate,
  types,
  setTypes,
  assignees,
  setAssignees,
  createdDateFrom,
  createdDateTo,
  setCreatedDateTo,
  setCreatedDateFrom,
}) => {
  const classes = useStyles();
  const { currentUser } = useUserContext();
  const lookupDisplayName = username => {
    return (
      tasks?.find(task => task.assignee_username === username)?.assignee
        ?.display_name ?? username
    );
  };

  const FACET_ARR_MAP = {
    status: {
      array: statuses,
      update: value => setStatuses([...statuses].filter(val => val !== value)),
    },
    assignee: {
      array: assignees,
      update: value => setAssignees([...statuses].filter(val => val !== value)),
    },
    type: {
      array: types,
      update: value => setTypes([...statuses].filter(val => val !== value)),
    },
    'Created <': {
      array: [createdDateTo],
      update: () => setCreatedDateTo(null),
    },
    'Created >': {
      array: [createdDateFrom],
      update: () => setCreatedDateFrom(null),
    },
    'Due Date >': {
      array: [dueFromDate],
      update: () => setDueFromDate(null),
    },
    'Due Date <': {
      array: [dueUntilDate],
      update: () => setDueUntilDate(null),
    },
  };
  const chips = [];
  Object.keys(FACET_ARR_MAP).forEach(key => {
    FACET_ARR_MAP[key].array.forEach(value => {
      if (value === currentUser.username) return;
      const label =
        key === 'status'
          ? TaskStatusNames[value]
          : ['Created <', 'Created >', 'Due Date >', 'Due Date <'].includes(key)
          ? `${key} ${getMediumDate(value)}`
          : key === 'assignee'
          ? lookupDisplayName(value)
          : String(value);
      if (value !== null) chips.push({ value, label, key });
    });
  });

  return (
    <Box display="flex" flexWrap="wrap">
      {chips.map(chip => (
        <Box
          component="span"
          key={`${chip.key}${chip.value}`}
          className={classes.chipBox}
        >
          <Chip
            label={chip.label}
            value={chip.value}
            onDelete={() => FACET_ARR_MAP[chip.key].update(chip.value)}
          />
        </Box>
      ))}
    </Box>
  );
};

export default FacetChips;
