import React, { useMemo } from 'react';
import { Typography } from '@mui/material';
import gql from 'graphql-tag';

const VehicleRank = ({ vehicle, listingsData, listPrice }) => {
  const rank = useMemo(
    () =>
      listingsData
        .map(l => (l.price < listPrice ? 1 : 0))
        .reduce((a, b) => a + b, 0) + 1,
    [listPrice, listingsData],
  );

  const percentage = listingsData?.length
    ? rank / (listingsData.length + 1)
    : 0;
  const color =
    percentage < 0.3 ? 'green' : percentage < 0.7 ? '#e6e600' : 'red';

  return (
    <Typography variant="body1">
      <span style={{ fontWeight: 'bold' }}>Vehicle Price Rank: </span>&nbsp;
      <span style={{ color, fontSize: '35px', fontWeight: 'bold' }}>
        {rank}
      </span>
      &nbsp;
      <span>of {listingsData.length + 1} vehicles</span>&nbsp;
    </Typography>
  );
};

VehicleRank.fragments = {
  vehicle: gql`
    fragment VehicleRankVehicle on GreaseInventoryVehicle {
      special_price_expires
      special_price
      regular_price
      is_special_price_enabled
    }
  `,
  listings: gql`
    fragment VehicleRankListingsResult on ListingType {
      price
    }
  `,
};

export default VehicleRank;
