import React from 'react';
import gql from 'graphql-tag';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useLazyQuery } from '@apollo/react-hooks';
import { makeStyles } from '@mui/styles';

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import IconButton from '@mui/material/IconButton';
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import TableCell from '@mui/material/TableCell';

import { snackBarSettings } from '../../../constants';

const useStyles = makeStyles({
  select: {
    display: 'flex',
    justifyContent: 'center',
    margin: '10px 18px 40px 10px',
    paddingTop: '10px',
  },
  formControl: {
    margin: '10px',
    minWidth: 110,
  },
  tableColumn: {
    whiteSpace: 'pre-line',
    padding: '5px 2px 5px 2px',
  },
  tableCell: {
    padding: '5px 2px 5px 2px',
    textAlign: 'center',
  },
});

const GET_RECORDING = gql`
  query recording($call_sid: String!, $number: String!) {
    smsCall {
      callRecording(call_sid: $call_sid, number: $number) {
        url
      }
    }
  }
`;

function formatTime(seconds) {
  const format = val => `0${Math.floor(val)}`.slice(-2);
  const minutes = (seconds % 3600) / 60;

  return [minutes, seconds % 60].map(format).join(':');
}

const CallListRow = ({ call, outbound, gatewayNumber }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const displayNumber = outbound ? call.to_formatted : call.from_formatted;
  const number = outbound ? call.from : gatewayNumber;
  const [getRecordingUrl, { loading }] = useLazyQuery(GET_RECORDING, {
    variables: {
      call_sid: call.sid,
      number,
    },
    fetchPolicy: 'network-only',
    onCompleted: data => {
      const url = data.smsCall.callRecording.url;
      window.open(url);
    },
    onError: e => {
      enqueueSnackbar('No recording found', snackBarSettings);
    },
  });

  const playRecording = () => {
    getRecordingUrl();
  };

  return (
    <>
      <TableCell className={classes.tableCell}>
        {outbound ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
      </TableCell>
      <TableCell className={classes.tableColumn} style={{ textAlign: 'left' }}>
        {`${displayNumber}
        ${moment(call.end_time).format('LT')}
      `}
      </TableCell>
      <TableCell className={classes.tableColumn} style={{ textAlign: 'right' }}>
        {`${formatTime(call.duration)}
        ${moment(call.end_time).format('ll')}
      `}
      </TableCell>
      <TableCell className={classes.tableCell}>
        <IconButton onClick={() => playRecording()} size="large">
          <PlayArrowIcon />
        </IconButton>
        <LoadingBackdrop open={loading} />
      </TableCell>
    </>
  );
};

export default CallListRow;
