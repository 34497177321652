import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';

import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles({
  select: {
    display: 'flex',
    justifyContent: 'center',
    margin: '10px 18px 10px 18px',
    paddingTop: '10px',
    width: '100%',
  },
});

const CallListSearch = ({ handleSearch, handleReset, isOutlined }) => {
  const classes = useStyles();
  const [number, setNumber] = useState('');

  if (!isOutlined) {
    return (
      <TextField
        id="search-field"
        className={classes.select}
        style={{ paddingTop: '0px' }}
        placeholder=" ex. 7807777777"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() => {
                  setNumber('');
                  handleReset();
                }}
                size="large"
              >
                <CloseIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        value={number || ''}
        onKeyPress={e => {
          handleSearch(e);
        }}
        onChange={e => {
          setNumber(e.target.value);
        }}
      />
    );
  }

  return (
    <TextField
      id="search-field"
      label="Customer Number"
      className={classes.select}
      style={{ paddingTop: '0px' }}
      placeholder=" ex. 7807777777"
      variant="outlined"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={() => {
                setNumber('');
                handleReset();
              }}
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      value={number || ''}
      onKeyPress={e => {
        handleSearch(e);
      }}
      onChange={e => {
        setNumber(e.target.value);
      }}
    />
  );
};

export default CallListSearch;
