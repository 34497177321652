export const Status = {
  IDLE: 1,
  ACQUIRING_DEVICE: 2,
  DEVICE_READY: 3,
  DEVICE_UNAVAILABLE: 4,
  STARTING: 5,
  RECORDING: 6,
  STOPPING: 7,
  STOPPED: 8,
  ERROR: 9,
  CONFIGURING: 10,
  PAUSED: 11,
};

export const EncodingPrefs = {
  audioBitsPerSecond: 112000,
  videoBitsPerSecond: 2500000,
  mimeType: 'video/webm',
};

export const VideoDimensions = {
  width: 720,
  height: 480,
};
