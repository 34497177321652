import React from 'react';

/* external */
import moment from 'moment';

/* Material UI */
import Avatar from '@mui/material/Avatar';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

/* internal */
import { initials } from 'utils';
import gql from 'graphql-tag';

const useStyles = makeStyles(theme => ({
  cardBorder: {
    borderBottomStyle: 'inset',
    borderBottomWidth: 'thin',
    paddingBottom: '10px',
  },
  avatarSmall: {
    width: '35px',
    height: '35px',
  },
}));

const PackageNoteCard = ({ note }) => {
  const classes = useStyles();
  const displayName = note.createdBy ? note.createdBy : 'System';
  const displayTime = moment(note.dateCreated).format('MMM DD, YYYY h:mm a');

  return (
    <>
      <Hidden lgDown>
        <Paper>
          <CardContent
            style={{
              paddingBottom: '10px',
              paddingTop: '10px',
              margin: '10px',
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              spacing={0}
            >
              <Grid item xs={2} style={{ flexBasis: '0px', maxWidth: '0px' }}>
                <Avatar>{initials(displayName)}</Avatar>
              </Grid>
              <Grid item xs={6}>
                <h4>{displayName}</h4>
                <Typography variant="body2" component="p">
                  {note.body}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2" align="right">
                  {displayTime}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Paper>
      </Hidden>
      <Hidden lgUp>
        <Paper style={{ margin: '10px' }}>
          <CardHeader
            avatar={
              <Avatar className={classes.avatarSmall}>
                {initials(displayName)}
              </Avatar>
            }
            title={displayName}
            subheader={displayTime}
            style={{ padding: '6px 0px 0px 6px' }}
          />
          <CardContent className={classes.cardBorder}>
            <Typography variant="body2">{note.body}</Typography>
          </CardContent>
        </Paper>
      </Hidden>
    </>
  );
};

PackageNoteCard.fragments = {
  note: gql`
    fragment PackageNoteCardNote on ConciergeNote {
      createdBy
      dateCreated
      body
    }
  `,
};

export default PackageNoteCard;
