import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, styled } from '@mui/styles';

import clsx from 'clsx';

/**
 * View is a full nestable View component that allows for
 * optional fixed header and footer of indeterminite size (Spacer is used for sizing)
 */
const View = props => {
  const { Header, Footer } = props;
  return (
    <div>
      <FixedResponsive>{Header}</FixedResponsive>
      <HiddenSpacer>{Header}</HiddenSpacer>
      {props.children}
      <HiddenSpacer>{Footer}</HiddenSpacer>
      <FixedResponsive bottom>{Footer}</FixedResponsive>
    </div>
  );
};

View.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  Header: PropTypes.node,
  Footer: PropTypes.node,
  resetScroll: PropTypes.bool,
};

View.defaultProps = {
  Header: null,
  Footer: null,
  footerActions: [],
  resetScroll: false,
};

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    right: '0',
    left: '0',
    zIndex: '1',
    [theme.breakpoints.up('lg')]: {
      left: '240px',
    },
  },
  bottom: {
    bottom: '0',
  },
}));

const FixedResponsive = ({ children, bottom }) => {
  const classes = useStyles();
  return (
    <div className={clsx({ [classes.root]: true, [classes.bottom]: bottom })}>
      {children}
    </div>
  );
};

const HiddenSpacer = styled('div')({
  visibility: 'hidden',
});

export default View;
