import linkifyStr from 'linkify-string';
// External
import moment from 'moment-timezone';
import React from 'react';

// Material UI
import {
  Box,
  Button,
  Link,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import GetApp from '@mui/icons-material/GetApp';
import ErrorOutline from '@mui/icons-material/ErrorOutline';

// Internal
import { MESSAGE_DIRECTIONS, MESSAGE_STATUSES } from 'constants.js';

const useStyles = makeStyles(theme => ({
  messageContainer: {
    marginBottom: '20px',
    alignSelf: ({ messageDirection }) =>
      messageDirection === MESSAGE_DIRECTIONS.outbound
        ? 'flex-end'
        : 'flex-start',
  },

  message: {
    backgroundColor: ({ messageDirection }) =>
      messageDirection === MESSAGE_DIRECTIONS.outbound ? '#99eac2' : '#d7d7d7',
    borderRadius: '10px',
    padding: '10px 15px',
  },
  messages: {
    [theme.breakpoints.up('lg')]: {
      width: '40%',
    },
    [theme.breakpoints.down('lg')]: {
      width: '55%',
    },
    [theme.breakpoints.down('md')]: {
      width: '70%',
    },
  },
  messagesDesktop: {
    [theme.breakpoints.up('md')]: {
      width: '65%',
    },
  },
  media: {
    borderRadius: '10px',
    width: ({ desktopDisplay }) => (desktopDisplay ? '90%' : '100%'),
  },
}));

const ConversationMessageItem = ({ message, customerName, desktopDisplay }) => {
  const desktop = useMediaQuery(theme => theme.breakpoints.up('md'));

  const messageDirection = message.direction;

  const classes = useStyles({ messageDirection, desktopDisplay });

  const linkOptions = {
    target: '_blank',
  };

  const messageBody = linkifyStr(message.body, linkOptions);

  return (
    <Box
      key={message.id}
      className={`${
        desktopDisplay ? classes.messagesDesktop : classes.messages
      } ${classes.messageContainer}`}
    >
      <Box display="flex" justifyContent="space-between" flexWrap="wrap">
        <Typography variant="caption" style={{ marginRight: '10px' }}>
          {messageDirection === MESSAGE_DIRECTIONS.inbound
            ? `${customerName} (${message.sent_from})`
            : message.operator_user
            ? message.operator_user.display_name
            : 'Unknown Operator'}
        </Typography>
        <Typography variant="caption">
          <i>{moment(message.date_sent).format('MMM Do YYYY, h:mm:ss a')}</i>
        </Typography>
      </Box>
      <Box boxShadow={2} className={classes.message}>
        {message.media.length > 0 &&
          message.media.map(media => (
            <Box key={media.url}>
              {media.content_type === 'video/mp4' && (
                <video controls className={classes.media}>
                  <source src={media.url} type="video/mp4" />
                </video>
              )}
              {media.content_type === 'image/jpeg' && (
                <img
                  alt={media.url}
                  src={media.url}
                  className={classes.media}
                />
              )}
              <Button startIcon={<GetApp />}>
                <Link
                  href={media.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Download
                </Link>
              </Button>
            </Box>
          ))}
        <Typography
          variant={desktop && !desktopDisplay ? 'body1' : 'body2'}
          style={{ wordWrap: 'break-word', whiteSpace: 'pre-line' }}
          dangerouslySetInnerHTML={{ __html: messageBody }}
        />
      </Box>
      {messageDirection === MESSAGE_DIRECTIONS.outbound && (
        <Box display="flex" alignItems="center" mt="5px">
          <Typography
            variant="caption"
            style={{
              color: message.delivery_status === 'failed' ? 'Red' : 'Black',
            }}
          >
            {MESSAGE_STATUSES[message.delivery_status] ||
              message.delivery_status}
          </Typography>
          {message.delivery_status === 'failed' && message.error_msg && (
            <Tooltip title={message.error_msg}>
              <ErrorOutline
                fontSize="small"
                style={{ color: 'red', marginLeft: '5px' }}
              />
            </Tooltip>
          )}
        </Box>
      )}
    </Box>
  );
};

export default ConversationMessageItem;
