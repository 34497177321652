import { useState } from 'react';

import { isEqual } from 'lodash';

// Used for sorts formatted like [{ model, field, direction}, ...]
// (used in projects that use sqlalchemy-filters sorting)

// Works for multiple sort criteria

export const ASC = 'asc';
export const DESC = 'desc';

const useSorting = defaultSort => {
  const [sort, setSort] = useState(defaultSort);

  const isSortingBy = sortOrder =>
    isEqual(
      sort.map(({ direction, ...rest }) => rest),
      sortOrder.map(({ direction, ...rest }) => rest),
    );
  const isSelected = sortSelection => isEqual(sort, sortSelection);

  const cycleSortOrder = () =>
    setSort(prev => {
      const number =
        prev.reduce(
          (total, { direction }, index) =>
            total + (direction === DESC ? 2 ** (prev.length - index - 1) : 0),
          0,
        ) + 1; // Use a number to represent asc/desc. (ASC: Bit=0, DESC: Bit=1)

      return prev.map(({ direction, ...rest }, index) => ({
        ...rest,
        direction: (number >>> (prev.length - index - 1)) & 0x01 ? DESC : ASC,
      }));
    });

  const sortDirection = sort.slice(-1)?.[0]?.direction; //todo

  const sortHandler = sortOrderIn => () =>
    isSortingBy(sortOrderIn)
      ? cycleSortOrder()
      : setSort(sortOrderIn.map(x => ({ ...x, direction: ASC })));

  const sortProps = sortBy => ({
    onClick: sortHandler(sortBy),
    direction: sortDirection,
    active: isSortingBy(sortBy),
  });

  const onClickOption = sortOption =>
    setSort(isSelected(sortOption) ? defaultSort : sortOption);

  const sortPopoverProps = sortOptions => ({
    sortOptions,
    onClickOption,
    isSelected,
  });

  return {
    isSortingBy,
    sortHandler,
    sortDirection,
    sort,
    sortProps,
    sortPopoverProps,
    setSort,
  };
};

export default useSorting;
