import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/react-hooks';

import {
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';

import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';

const GET_MESSAGES = gql`
  query getPitchMessages($publishingId: Int!) {
    pitches {
      publishing(id: $publishingId) {
        id
        messages {
          id
          body
          createdBy
        }
      }
    }
  }
`;

const SEND_MESSAGE = gql`
  mutation sendMessage($publishingId: Int!, $data: PublishingMessageInput!) {
    pitches {
      sendMessage(id: $publishingId, data: $data) {
        id
        messages {
          id
          body
          createdBy
        }
      }
    }
  }
`;

const PitchChatDialog = ({
  user,
  onClose,
  open,
  messagingDisabled,
  publishingId,
}) => {
  const { data, loading } = useQuery(GET_MESSAGES, {
    variables: { publishingId },
    pollInterval: 5000,
    skip: !publishingId,
  });
  const messages = data?.pitches.publishing?.messages ?? [];
  const [newMessage, setNewMessage] = useState('');
  const [sendMessage, sendMessageMutation] = useMutation(SEND_MESSAGE);

  const handleSendMessage = () => {
    if (newMessage.trim() !== '') {
      const data = {
        body: newMessage,
        createdBy: user.username,
        displayName: user.display_name,
      };
      sendMessage({
        variables: {
          publishingId,
          data,
        },
      });
      setNewMessage('');
    }
  };

  const handleChange = event => {
    setNewMessage(event.target.value);
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <LoadingBackdrop open={sendMessageMutation.loading || loading}>
        Sending Message...
      </LoadingBackdrop>
      <DialogTitle>
        <Box>
          Offer Messages
          <IconButton onClick={onClose} style={{ float: 'right' }} size="large">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        {messages.map(message => (
          <ChatMessage key={message.id} message={message} />
        ))}
      </DialogContent>
      <DialogActions>
        <TextField
          fullWidth
          size="small"
          placeholder="Type a message"
          variant="outlined"
          value={newMessage}
          onChange={handleChange}
          style={{ paddingRight: '5px', paddingLeft: '16px' }}
          disabled={messagingDisabled}
        />
        <Button
          variant="contained"
          color="primary"
          endIcon={<SendIcon />}
          onClick={handleSendMessage}
          disabled={messagingDisabled}
        >
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ChatMessage = ({ message }) => {
  const isManager = message.createdBy !== 'customer';

  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: isManager ? 'flex-end' : 'flex-start',
        mb: 2,
      }}
    >
      <Paper
        variant="outlined"
        style={{
          p: 2,
          backgroundColor: isManager ? 'lightskyblue' : 'lightgrey',
          borderRadius: isManager ? '20px 20px 5px 20px' : '20px 20px 20px 5px',
          padding: '10px',
        }}
      >
        <Typography variant="body1">{message.body}</Typography>
      </Paper>
    </Box>
  );
};

export default PitchChatDialog;
