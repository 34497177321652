import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import moment from 'moment-timezone';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import { useTheme } from '@mui/material';
import TextField from '@mui/material/TextField';
import useMediaQuery from '@mui/material/useMediaQuery';
import Alert from '@mui/material/Alert';
import { StaticDatePicker } from '@mui/x-date-pickers';

import { useUserContext } from 'components/MaterialUI/UserContext';

const RDR_PUNCH_MUTATION = gql`
  mutation rdrPunch(
    $program: String!
    $amount: String!
    $notes: String!
    $punch_date: String!
    $_id: ID!
    $username: String!
    $plate_number: String!
  ) {
    rdrPunch(
      program: $program
      amount: $amount
      notes: $notes
      punch_date: $punch_date
      _id: $_id
      username: $username
      plate_number: $plate_number
    ) {
      _id
      rdr_punch {
        punch_date
        punched_by {
          display_name
        }
      }
    }
  }
`;

const DealLogRDRPunch = ({ opportunity, handleClose, setShowRDR, showRDR }) => {
  const { currentUser } = useUserContext();
  const isDesktop = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const { _id } = opportunity;
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const [program, setProgram] = useState('');
  const [amount, setAmount] = useState('');
  const [notes, setNotes] = useState('');
  const [date, changeDate] = useState(moment());
  const [checked, setChecked] = useState(false);
  const [plate_number, setPlateNumber] = useState('');
  const [rdrPunch] = useMutation(RDR_PUNCH_MUTATION, {
    onCompleted: res =>
      enqueueSnackbar('Successfully RDR Punched Opportunity.', {
        variant: 'success',
      }),
    onError: e =>
      enqueueSnackbar('An error occurred, please try again.', {
        variant: 'error',
      }),
  });

  const handleCheckChange = event => {
    setChecked(event.target.checked);
  };
  const handleProgramChange = event => {
    setProgram(event.target.value);
  };
  const handleAmountChange = event => {
    setAmount(event.target.value);
  };
  const handleNotesChange = event => {
    setNotes(event.target.value);
  };
  const handlePlateNumberChange = event => {
    setPlateNumber(event.target.value);
  };

  const dmsDeal = opportunity?.dms_deal;
  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      scroll="paper"
      open={showRDR}
      onClose={() => {
        setShowRDR(false);
      }}
    >
      <DialogTitle>RDR Deal # {dmsDeal?.deal_number}</DialogTitle>
      <DialogContent>
        <Grid container direction={isDesktop ? 'row' : 'column'}>
          <Grid item xs={12} sm={6}>
            <Box style={{ width: `${isDesktop ? '75%' : '100%'}` }}>
              <Alert severity="info">
                {`${dmsDeal?.year} ${dmsDeal?.make_name} ${dmsDeal?.model_name} (${dmsDeal?.vin})`}
              </Alert>
            </Box>
            <Box
              style={{
                width: `${isDesktop ? '75%' : '100%'}`,
                paddingTop: '1rem',
              }}
            >
              <b>Punched By:</b> {currentUser?.display_name}
            </Box>
            <Box style={{ width: `${isDesktop ? '75%' : '100%'}` }}>
              <TextField
                value={program}
                onChange={handleProgramChange}
                label="Program"
                style={{ width: '100%' }}
              />
            </Box>
            <Box style={{ width: `${isDesktop ? '75%' : '100%'}` }}>
              <TextField
                value={amount}
                onChange={handleAmountChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                label="Amount"
                style={{ width: '100%' }}
              />
            </Box>
            <Box style={{ width: `${isDesktop ? '75%' : '100%'}` }}>
              <TextField
                value={plate_number}
                onChange={handlePlateNumberChange}
                label="Plate Number"
                style={{ width: '100%' }}
              />
            </Box>
            <Box style={{ width: `${isDesktop ? '75%' : '100%'}` }}>
              <TextField
                value={notes}
                onChange={handleNotesChange}
                label="Notes"
                style={{ width: '100%' }}
                multiline
                minRows={4}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box>
              <StaticDatePicker value={date} onChange={changeDate} />
            </Box>
          </Grid>
        </Grid>
        <FormControlLabel
          label="I confirm that punching this deal is permanent and cannot be undone!"
          control={<Checkbox checked={checked} onChange={handleCheckChange} />}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <Button
          disabled={!checked}
          style={theme.actions.confirm}
          onClick={() => {
            rdrPunch({
              variables: {
                program,
                amount,
                notes,
                punch_date: date,
                _id,
                username: currentUser.username,
                plate_number,
              },
            });
            handleClose();
          }}
        >
          PUNCH IT!
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DealLogRDRPunch;
