import React, { useState } from 'react';

/* External */
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useHistory } from 'react-router-dom';

/* Material UI */
import {
  Box,
  Button,
  darken,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  useTheme,
} from '@mui/material';

import { makeStyles } from '@mui/styles';

/* Internal */
import ErrorDisplay from 'components/MaterialUI/ErrorDisplay';
import Loading from 'components/MaterialUI/Loading';
import { useUserContext } from 'components/MaterialUI/UserContext';
import { Role } from 'constants.js';
import GatewayRow from './GatewayRow';
import SearchField from './SearchField';

export const GATEWAYS_QUERY = gql`
  query gatewaysQuery {
    sms {
      gateways {
        id
        ...GatewayRowGateway
      }
    }
  }
  ${GatewayRow.fragments.gateway}
`;

const useStyles = makeStyles(theme => ({
  headerRow: {
    '&>th': {
      fontWeight: 'bold',
      borderBottom: '2px solid black',
      paddingBottom: '5px',
      fontSize: '1rem',
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.875rem',
      },
    },
  },
}));

const Sorts = {
  GATEWAY: (a, b) => a.name?.localeCompare(b.name ?? '') ?? -1,
  TOTAL_UNREAD: (a, b) => a.unread_count - b.unread_count,
  USER_UNREAD: (a, b) => a.user_unread_count - b.user_unread_count,
};

const SORT_DIR = {
  1: 'asc',
  [-1]: 'desc',
};

const Gateways = () => {
  const { currentUser } = useUserContext();
  const { headerRow } = useStyles();
  const theme = useTheme();
  const [sortSelection, setSortSelection] = useState({
    sort: Sorts.GATEWAY,
    direction: 1,
  });
  const [searchFilter, setSearchFilter] = useState('');

  const { loading, data, error } = useQuery(GATEWAYS_QUERY, {
    fetchPolicy: 'network-only',
    pollInterval: 15000,
  });

  const history = useHistory();

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <ErrorDisplay error={error} action="Loading List of Gateways" />;
  }

  const gatewaysList = data.sms.gateways
    .sort((a, b) => sortSelection.sort(a, b) * sortSelection.direction)
    .filter(
      x =>
        x.name.toLowerCase().includes(searchFilter) ||
        x.phone_number.toLowerCase().includes(searchFilter),
    );

  const handleSortClick = sort => () =>
    sortSelection.sort === sort
      ? setSortSelection(({ direction }) => ({
          sort,
          direction: direction * -1,
        }))
      : setSortSelection({ sort, direction: 1 });

  const handleSearch = keywords => setSearchFilter(keywords.toLowerCase());

  const cells = [
    {
      path: id => `/sms/gateways/${id}`,
      content: gateway => (
        <>
          <Box fontWeight="bold">{gateway.name}</Box>
          <Box>{gateway.phone_number}</Box>
        </>
      ),
      clickable: true,
    },
    {
      path: id => `/sms/gateways/${id}`,
      content: gateway => <Box fontWeight="bold">{gateway.unread_count}</Box>,
      clickable: true,
      align: 'center',
    },
    {
      path: id => `/sms/gateways/${id}`,
      content: gateway => (
        <Box fontWeight="bold">{gateway.user_unread_count}</Box>
      ),
      clickable: true,
      align: 'center',
    },
  ];

  return (
    <>
      <Box margin="50px 20px" maxWidth="80%">
        <Box
          display="flex"
          justifyContent="space-between"
          flexWrap="wrap"
          width="100%"
        >
          <Typography
            variant="h5"
            style={{ fontWeight: 'bold', marginBottom: '10px' }}
          >
            Gateway Select
          </Typography>
          <SearchField handleSearch={handleSearch} />
        </Box>
        {currentUser.role === Role.ORGANIZATION_ADMIN && (
          <Button
            style={{
              color: darken(theme.colours.add, 0.2),
              borderColor: darken(theme.colours.add, 0.2),
            }}
            variant="outlined"
            color="primary"
            onClick={() => history.push('/sms/gateways/manage')}
          >
            Manage Gateways
          </Button>
        )}
        <Table>
          <TableHead>
            <TableRow className={headerRow}>
              <TableCell>Actions</TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortSelection.sort === Sorts.GATEWAY}
                  direction={SORT_DIR[sortSelection.direction]}
                  onClick={handleSortClick(Sorts.GATEWAY)}
                >
                  Gateway
                </TableSortLabel>
              </TableCell>
              <TableCell align="center">
                <TableSortLabel
                  active={sortSelection.sort === Sorts.TOTAL_UNREAD}
                  direction={SORT_DIR[sortSelection.direction]}
                  onClick={handleSortClick(Sorts.TOTAL_UNREAD)}
                >
                  Total Unread (Last 60 Days)
                </TableSortLabel>
              </TableCell>
              <TableCell align="center">
                <TableSortLabel
                  active={sortSelection.sort === Sorts.USER_UNREAD}
                  direction={SORT_DIR[sortSelection.direction]}
                  onClick={handleSortClick(Sorts.USER_UNREAD)}
                >
                  Your Unread
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {gatewaysList.length === 0 && (
              <TableRow rowSpan={3}>
                <TableCell align="center" colSpan={3}>
                  No gateways available.
                </TableCell>
              </TableRow>
            )}

            {gatewaysList.map(gateway => (
              <GatewayRow key={gateway.id} gateway={gateway} cells={cells} />
            ))}
          </TableBody>
        </Table>
      </Box>
    </>
  );
};

export default Gateways;
