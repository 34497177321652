import { groupBy, isEmpty } from 'lodash';

import { cloudinaryCore } from 'utils';

export { errorHandler } from 'modules/pitches/utils';

export const filtersFromFacets = filterFacets =>
  ifNullMakeOrIsNone(
    Object.entries(groupBy(filterFacets, x => [x.model, x.field])).map(
      ([modelField, entries]) => ({
        model: modelField.split(',')[0],
        field: modelField.split(',')[1],
        op: 'in',
        value: entries.map(({ value }) =>
          value === 'True' ? true : value === 'False' ? false : value,
        ),
      }),
    ),
  );

// replace any filters that have op: in, value: [something, something, null]
// with {or: [{op: in, value: [something, something]}, {op: is_null}]}
// Don't forget to account for the case when [null] is the only thing in the array.
const ifNullMakeOrIsNone = filters =>
  filters.map(({ model, field, op, value }) =>
    value.includes(null) && !isEmpty(value.filter(x => x))
      ? {
          or: [
            { model, field, op, value: value.filter(x => x) },
            { model, field, op: 'is_null' },
          ],
        }
      : value.includes(null)
      ? { model, field, op: 'is_null' }
      : { model, field, op, value },
  );

export const displayName = ({ year, make, model, trim }) =>
  [year, make, model, trim].filter(x => x).join(' ');

export const formatPhoto = (photo, options = {}) =>
  photo
    ? (options.cloudinaryCore ?? cloudinaryCore).url(photo.cloudinaryPublicId, {
        width: options.width || 100,
        crop: options.crop || 'fit',
        ...options,
      })
    : '';

export const goodOrBad = x => (x ? 'Good' : 'Bad');

export const yesOrNo = x => (x ? 'Yes' : 'No');
