/* external */
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { ReactSortable } from 'react-sortablejs';
import { useHistory } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import gql from 'graphql-tag';

/* Material UI */
import { useTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DoneIcon from '@mui/icons-material/Done';
import {
  Button,
  Fab,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from '@mui/material';

/* internal */
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import Loading from 'components/MaterialUI/Loading';
import TextFieldControl from 'components/MaterialUI/TextFieldControl';
import { errorHandler } from '../utils';

// NOTE: if we ever have more than 100 Go Auto dealers, we'll need to change
// this.
const GET_DEALERS = gql`
  query getDealers {
    inventory {
      getDealers(
        page_size: 100
        sort: [{ model: "Dealer", field: "name", direction: "asc" }]
      ) {
        results {
          id
          name
        }
      }
    }
  }
`;

const SET_APPRAISAL_PRICE = gql`
  mutation setAppraisalPrice($appraisalId: Int!, $price: Float!) {
    appraisals {
      updateAppraisal(id: $appraisalId, data: { price: $price }) {
        id
        price
      }
    }
  }
`;
const CREATE_OFFERS = gql`
  mutation createOffers($offers: [OfferInput]!) {
    appraisals {
      createOffers(offers: $offers) {
        id
      }
    }
  }
`;

const SellVehicle = ({ appraisal, setShowSold }) => {
  const theme = useTheme();
  const { id: appraisalId, price } = appraisal;
  const { enqueueSnackbar } = useSnackbar();
  const [dealerId, setDealerId] = useState('');
  const [selectedDealers, setSelectedDealers] = useState([]);
  const { dealerId: myDealerId } = useDealerContext();

  const { control, handleSubmit, setError } = useForm({
    defaultValues: { price },
  });
  const history = useHistory();

  const { data, loading } = useQuery(GET_DEALERS);
  const [setAppraisalPrice, setAppraisalPriceMutation] =
    useMutation(SET_APPRAISAL_PRICE);
  const [createOffers, createOffersMutation] = useMutation(CREATE_OFFERS);

  const dealers = (data?.inventory.getDealers.results || []).filter(
    x =>
      !selectedDealers.map(dealer => dealer.id).includes(x.id) &&
      x.id !== myDealerId,
  );

  const handleDealerChange = e => setDealerId(e.target.value);

  const handleAddDealer = () => {
    setSelectedDealers(prev => [...prev, dealers.find(x => x.id === dealerId)]);
    setDealerId('');
  };

  const handleCreateOffers = () => {
    const offers = selectedDealers.map(({ id: dealerId }, order) => ({
      appraisalId,
      dealerId,
      order, // should order start with 0?  I'm not sure....
    }));
    createOffers({ variables: { appraisalId, offers } }).then(
      () => {
        enqueueSnackbar(`Offers created successfully!`, { variant: 'success' });
        history.push('/used-vehicles');
      },
      err => errorHandler(enqueueSnackbar, () => {})(err),
    );
  };
  const onSubmit = ({ price }) => {
    if (!selectedDealers.length)
      enqueueSnackbar(
        'Choose at least one dealer (click + to add them to the list)',
        { variant: 'warning' },
      );
    else {
      setAppraisalPrice({
        variables: {
          appraisalId,
          price: parseFloat(price),
          isBuyNow: false, // Reset buy now to false in case this was a buy-now vehicle
        },
      }).then(handleCreateOffers, err =>
        errorHandler(enqueueSnackbar, setError)(err),
      );
    }
  };

  if (
    loading ||
    setAppraisalPriceMutation.loading ||
    createOffersMutation.loading
  )
    return <Loading />;

  return (
    <div>
      <div style={{ padding: '1rem' }}>
        <Typography variant="h6">Sell Vehicle:</Typography>
        <p>
          Select the store(s) you would like to sell this vehicle in order of
          preference:
        </p>
        <div>
          <FormControl style={{ width: '75%' }}>
            <InputLabel>Purchaser</InputLabel>
            <Select value={dealerId} onChange={handleDealerChange}>
              {dealers.map(({ id, name }) => (
                <MenuItem value={id} key={id}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <span style={{ marginLeft: '1rem' }}>
            <Tooltip title="Add Dealer">
              <span>
                <Fab
                  disabled={!dealerId}
                  color="primary"
                  onClick={handleAddDealer}
                  size="small"
                >
                  <AddIcon />
                </Fab>
              </span>
            </Tooltip>
          </span>
        </div>
        <div>
          <ReactSortable list={selectedDealers} setList={setSelectedDealers}>
            {selectedDealers.map(item => (
              <div key={item.id} style={{ padding: '3px' }}>
                <div
                  style={{
                    width: '75%',
                    padding: '0.5rem 1rem',
                    border: '1px solid grey',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                  }}
                >
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                    }}
                  >
                    {item.name}
                  </div>
                  <IconButton
                    onClick={() =>
                      setSelectedDealers(prev =>
                        prev.filter(x => x.id !== item.id),
                      )
                    }
                    style={{ padding: '3px' }}
                    size="large"
                  >
                    <DeleteForeverIcon
                      style={{ color: 'red', fontSize: '26px' }}
                    />
                  </IconButton>
                </div>
              </div>
            ))}
          </ReactSortable>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            marginTop: '1rem',
            marginBottom: '1rem',
          }}
        >
          <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
            <TextFieldControl
              label="Selling Price"
              name="price"
              required
              control={control}
              variant="currency"
              type="number"
              style={{ width: '75%' }}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
                marginTop: '1rem',
              }}
            >
              <Button onClick={() => setShowSold(false)} variant="outlined">
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                style={theme.actions.confirm}
              >
                <DoneIcon /> Sell it!
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

SellVehicle.fragments = {
  appraisal: gql`
    fragment SellVehicleAppraisal on Appraisal {
      id
      price
    }
  `,
};

export default SellVehicle;
