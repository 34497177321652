import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import DealerNotesFeed from './DealerNotesFeed';
import MyNotes from './MyNotes';
import { RequiresFeatureFlag } from '../../app/components';

const Notes = ({ match }) => {
  return (
    <RequiresFeatureFlag flag="customersBeta">
      <Switch>
        <Route path={`${match.url}/my-notes`}>
          <MyNotes />
        </Route>
        <Route path={`${match.url}/dealer-notes/:dealer_id`}>
          <DealerNotesFeed />
        </Route>
        <Route path={`${match.url}/dealer-notes/`}>
          <DealerNotesFeed />
        </Route>
        <Redirect to={`${match.url}/my-notes`} />
      </Switch>
    </RequiresFeatureFlag>
  );
};

export default Notes;
