// External
import React, { useState } from 'react';

import { NetworkStatus } from 'apollo-boost';
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import DealerPicker from 'components/MaterialUI/DealerPicker';
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';
import { useUserContext } from 'components/MaterialUI/UserContext';
import gql from 'graphql-tag';
import { Waypoint } from 'react-waypoint';
import { usePersistedState } from 'utils';
import Loading from 'components/MaterialUI/Loading';

import { useQuery } from '@apollo/react-hooks';
// Material UI
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import CloseIcon from '@mui/icons-material/Close';
import FilterListIcon from '@mui/icons-material/FilterList';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { Role } from 'constants.js';
import FacetChips from './FacetChips';
import FilterDrawer from './FilterDrawer';
import TaskBulkAction from './TaskBulkAction';
import TaskDesktopTableRow from './TaskDesktopTableRow';
import TaskMobileRow from './TaskMobileRow';
import TasksSearch from './TasksSearch';
import TaskTableHeader from './TasksTableHeader';
import TasksToolbar from './TasksToolbar';

const PAGE_SIZE = 20;

const TASK_QRY = gql`
  query TasksForDealer(
        $dealer_ids: [Int],
        $sort_by: [SortTaskBy],
        $page: Int,
        $keywords: String,
        $due_from: Date,
        $due_until: Date,
        $created_date_from: Date,
        $created_date_to: Date,
        $types: [String],
        $assignees: [String],
        $statuses:[Int]) {
    tasks(
      dealer_ids: $dealer_ids
      page: $page
      page_size: ${PAGE_SIZE}
      assignees: $assignees
      sort_by: $sort_by
      statuses: $statuses
      keywords: $keywords
      due_date_from: $due_from
      due_date_to: $due_until
      created_date_from: $created_date_from,
      created_date_to: $created_date_to,
      with_facets: true
      types: $types
    ) {
      pagination {
        page
        pages
        total
      }
      facets {
        type {
          value
          total
        }
        status {
          value
          total
        }
        assignee {
          value
          total
        }
      }
      results {
        _id
        assignee_username
        assignee {
          username
          display_name
        }
        created
        customer_id
        customer {
          _id
          fullname
        }
        dealer_id
        dealer {
          dealer_name
        }
        description
        due_date
        is_all_day_task
        is_due_today
        is_overdue
        notes {
          visibility_display
          body
        }
        opportunity_id
        permissions {
          can_update
        }
        status
        status_name
        type
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const TasksList = () => {
  const classes = useStyles();
  const { currentUser } = useUserContext();
  const isManagerOrAbove =
    Role.ALL_SALES_MANAGERS.includes(currentUser.role) ||
    currentUser.role === Role.ORGANIZATION_ADMIN;
  const [statuses, setStatuses] = usePersistedState(
    'tasks.components.TasksList.statuses',
    [0],
  );
  const [types, setTypes] = usePersistedState(
    'tasks.components.TasksList.types',
    [],
  );
  const [assignees, setAssignees] = usePersistedState(
    'tasks.components.TasksList.assignees',
    isManagerOrAbove ? [] : [currentUser.username],
  );

  const [searchWords, setSearchWords] = usePersistedState(
    'tasks.components.TasksList.searchWords',
    null,
  );
  const { dealerId } = useDealerContext();
  const isDesktop = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const [sort, setSort] = useState([{ key: 'due_date', direction: 1 }]);
  const [dueFromDate, setDueFromDate] = usePersistedState(
    'tasks.components.TasksList.dueFromDate',
    null,
  );
  const [dueUntilDate, setDueUntilDate] = usePersistedState(
    'tasks.components.TasksList.dueUntilDate',
    null,
  );
  const [createdDateFrom, setCreatedDateFrom] = usePersistedState(
    'tasks.components.TasksList.createDateFrom',
    null,
  );
  const [createdDateTo, setCreatedDateTo] = usePersistedState(
    'tasks.components.TasksList.createDateTo',
    null,
  );
  const [order, setOrder] = useState(1);
  const [orderBy, setOrderBy] = useState('due_date');
  const [selected, setSelected] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleAnchorClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleAnchorClose = () => {
    setAnchorEl(null);
  };

  const handleKeywordSearch = keywords => {
    // Check if filters has keyword search
    const isValid = keywords !== null || keywords !== '';
    // if keyword is valid and keywords already in replace
    if (isValid) {
      setSearchWords(keywords);
    } else {
      // If its not valid then don't search.
      setSearchWords(undefined);
    }
  };

  const queryVariables = {
    dealer_ids: [dealerId],
    sort_by: sort,
    page: 1,
    assignees: assignees,
    keywords: searchWords,
    due_until: dueUntilDate,
    due_from: dueFromDate,
    created_date_from: createdDateFrom,
    created_date_to: createdDateTo,
    statuses: statuses,
    types: types,
  };

  const { data, networkStatus, loading, fetchMore, refetch } = useQuery(
    TASK_QRY,
    {
      variables: queryVariables,
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    },
  );

  const handleRequestSort = (event, property) => {
    setSort([
      {
        key: property,
        direction: order === 1 ? -1 : 1,
      },
    ]);
    setOrder(order === 1 ? -1 : 1);
    setOrderBy(property);
  };

  const [state, setState] = useState({
    left: false,
  });

  const pagination = data?.tasks.pagination || {};
  const { page, pages, total } = pagination;
  const facetResults = data?.tasks.facets || [];
  const [selectByFilter, setSelectByFilter] = useState(false);

  const fetchMoreHandler = () => {
    if (page < pages) {
      fetchMore({
        variables: {
          page: page + 1,
        },
        updateQuery: (prev, { fetchMoreResult: more }) => {
          if (!more.tasks.results) return prev;
          return Object.assign({}, prev, {
            tasks: {
              __typename: prev.tasks.__typename,
              results: [...prev.tasks.results, ...more.tasks.results],
              pagination: more.tasks.pagination,
            },
          });
        },
      });
    }
  };

  const rows = data?.tasks.results || [];

  const handleSelectAllClick = e => {
    setSelected(e.target.checked ? rows.map(n => n._id) : []);
    setSelectByFilter(false);
  };

  if (loading && networkStatus !== NetworkStatus.fetchMore) {
    return <Loading />;
  }

  const handleClick = (e, _id) => {
    // Add the new id in the same order as displayed vehicles.
    if (e.target.checked)
      setSelected(prev =>
        rows.map(v => v._id).filter(x => [...prev, _id].includes(x)),
      );
    else setSelected(prev => prev.filter(x => x !== _id));

    setSelectByFilter(false);
  };

  const isSelected = id => selected.includes(id) || selectByFilter;

  const toggleDrawer = (anchor, open) => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  return (
    <Box m={1}>
      <Box>
        <DealerPicker />
      </Box>
      <Grid
        container
        direction="row"
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Grid item xs={12} sm={6}>
          <div style={{ paddingBottom: '1rem' }}>
            {isDesktop && (
              <Grid
                container
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Grid item>
                  <Grid
                    container
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <Grid
                      item
                      style={{
                        paddingRight: '1rem',
                        paddingTop: '1rem',
                        paddingBottom: '1rem',
                      }}
                    >
                      <Tooltip title="Select tasks to use Bulk Actions">
                        <Fab
                          color="primary"
                          onClick={handleAnchorClick}
                          size="medium"
                        >
                          <MoreVertIcon />
                        </Fab>
                      </Tooltip>
                      <TaskBulkAction
                        refetch={refetch}
                        anchorEl={anchorEl}
                        handleAnchorClose={handleAnchorClose}
                        selected={selected}
                        setSelected={setSelected}
                        filters={queryVariables}
                        selectByFilter={selectByFilter}
                      />
                    </Grid>
                    <Grid
                      item
                      style={{
                        paddingRight: '1rem',
                        paddingTop: '1rem',
                        paddingBottom: '1rem',
                      }}
                    >
                      <Tooltip title="Filter list">
                        <Fab
                          color="primary"
                          onClick={toggleDrawer('left', true)}
                          size="medium"
                        >
                          <FilterListIcon />
                        </Fab>
                      </Tooltip>
                    </Grid>
                    <Grid item style={{ display: 'flex', paddingTop: '1rem' }}>
                      {
                        <Box paddingTop="0.5rem" paddingBottom="0.5rem">
                          <FacetChips
                            tasks={rows}
                            statuses={statuses}
                            setStatuses={setStatuses}
                            assignees={assignees}
                            setAssignees={setAssignees}
                            types={types}
                            setTypes={setTypes}
                            dueFromDate={dueFromDate}
                            dueUntilDate={dueUntilDate}
                            setDueFromDate={setDueFromDate}
                            setDueUntilDate={setDueUntilDate}
                            createdDateFrom={createdDateFrom}
                            createdDateTo={createdDateTo}
                            setCreatedDateFrom={setCreatedDateFrom}
                            setCreatedDateTo={setCreatedDateTo}
                          />
                        </Box>
                      }
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <div
                    style={{
                      display: 'flex',
                    }}
                  >
                    {selected.length > 0 && (
                      <TasksToolbar
                        numSelected={
                          selectByFilter ? pagination.total : selected.length
                        }
                        total={pagination.total}
                        onSelectAllFiltered={() => setSelectByFilter(true)}
                      />
                    )}
                  </div>
                </Grid>
              </Grid>
            )}
            <Drawer
              anchor={'left'}
              open={state['left']}
              onClose={toggleDrawer('left', false)}
            >
              <div style={{ display: 'flex', minWidth: '350px' }}>
                <IconButton
                  style={{
                    display: 'flex',
                    marginLeft: 'auto',
                    height: '50px',
                    zIndex: '1000',
                  }}
                  onClick={toggleDrawer('left', false)}
                  size="large"
                >
                  <CloseIcon />
                </IconButton>
              </div>
              <FilterDrawer
                isManagerOrAbove={isManagerOrAbove}
                facetResults={facetResults}
                loading={loading}
                statuses={statuses}
                setStatuses={setStatuses}
                assignees={assignees}
                setAssignees={setAssignees}
                types={types}
                setTypes={setTypes}
                dueFromDate={dueFromDate}
                dueUntilDate={dueUntilDate}
                setDueFromDate={setDueFromDate}
                setDueUntilDate={setDueUntilDate}
                createdDateFrom={createdDateFrom}
                createdDateTo={createdDateTo}
                setCreatedDateFrom={setCreatedDateFrom}
                setCreatedDateTo={setCreatedDateTo}
              />
            </Drawer>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          style={{
            display: 'flex',
          }}
        >
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginLeft: `${isDesktop ? 'auto' : ''}`,
              marginRight: `${isDesktop ? 0 : ''}`,
              width: `${isDesktop ? '' : '100%'}`,
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              style={{ width: `${isDesktop ? '' : '100%'}` }}
            >
              <TasksSearch
                updateFilter={handleKeywordSearch}
                keywords={searchWords}
              />
              <Box
                style={{
                  display: 'flex',
                  marginLeft: 'auto',
                  marginRight: 0,
                  fontSize: '18px',
                  paddingTop: '1rem',
                }}
              >
                Showing {rows.length} of {total || '0'}
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {isDesktop && (
        <Paper>
          <TableContainer>
            <Table className={classes.table}>
              <TaskTableHeader
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {rows.map(task => (
                  <TaskDesktopTableRow
                    key={task._id}
                    task={task}
                    handleClick={handleClick}
                    isSelected={isSelected}
                    selected={selected}
                    selectByFilter={selectByFilter}
                    refetch={refetch}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {rows.length === 0 && !loading && (
            <Typography style={{ fontSize: '20px', padding: '1rem' }}>
              This dealer has no Tasks
            </Typography>
          )}
          {loading && <LoadingBackdrop />}
        </Paper>
      )}
      {!isDesktop && (
        <>
          {
            <Box paddingTop="0.5rem" paddingBottom="0.5rem">
              <FacetChips
                statuses={statuses}
                setStatuses={setStatuses}
                assignees={assignees}
                setAssignees={setAssignees}
                types={types}
                setTypes={setTypes}
                dueFromDate={dueFromDate}
                dueUntilDate={dueUntilDate}
                setDueFromDate={setDueFromDate}
                setDueUntilDate={setDueUntilDate}
                createdDateFrom={createdDateFrom}
                createdDateTo={createdDateTo}
                setCreatedDateFrom={setCreatedDateFrom}
                setCreatedDateTo={setCreatedDateTo}
              />
            </Box>
          }
          {loading && <LoadingBackdrop />}
          {rows.length === 0 && !loading && (
            <Typography style={{ fontSize: '20px', padding: '1rem' }}>
              This dealer has no Tasks
            </Typography>
          )}
          <>
            <List>
              {rows.map(task => (
                <TaskMobileRow task={task} refetch={refetch} />
              ))}
            </List>
          </>
        </>
      )}
      {!isDesktop && (
        <Box position="fixed" bottom="10px" right="10px">
          <Fab
            color="primary"
            onClick={toggleDrawer('left', true)}
            size="medium"
          >
            <FilterListIcon />
          </Fab>
        </Box>
      )}
      <Box style={{ height: 1 }}>
        <Waypoint onEnter={fetchMoreHandler} />
      </Box>

      {loading && networkStatus === NetworkStatus.fetchMore && (
        <>
          <div style={{ padding: '2rem', fontSize: '20px' }}>
            Loading more tasks...
          </div>
          <LoadingBackdrop open={true} />
        </>
      )}
    </Box>
  );
};

export default TasksList;
