import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import moment from 'moment';
import React, { useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

import Loading from 'components/MaterialUI/Loading';

const SERVICE_ROS = gql`
  query ReconServiceRos($filters: [QueryFilter], $cursor: String) {
    rotracker {
      getROsByCursor(filters: $filters, cursor: $cursor, page_size: 50) {
        cursor
        total
        results {
          id
          dealer_id
          ro_number
          opened_date
          remarks
          status
          vin
        }
      }
    }
  }
`;

const headCells = [
  { label: 'Actions', id: 'actions' },
  { label: 'RO#', id: 'ro_number' },
  { label: 'Date', id: 'opened_date' },
  { label: 'Description', id: 'remarks' },
  { label: 'Status', id: 'status' },
];

const ReconRos = ({ dealerId, modalOpen, handleModalClose, vin }) => {
  const rowsPerPage = 5;
  const [page, setPage] = useState(0);

  const filters = [
    {
      model: 'ServiceRO',
      field: 'vin',
      op: '==',
      value: vin,
    },
    {
      model: 'ServiceRO',
      field: 'dealer_id',
      op: '==',
      value: dealerId,
    },
  ];
  const { data, loading } = useQuery(SERVICE_ROS, {
    variables: { filters },
    skip: !modalOpen,
  });

  const results = data?.rotracker?.getROsByCursor?.results ?? [];
  const total = data?.rotracker?.getROsByCursor?.total ?? 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleClickOpenRO = (ro_number, dealer_id) =>
    window.open(`/rotracker/${dealer_id}/${ro_number}`, '_blank');

  return (
    <Dialog
      open={modalOpen}
      onClose={handleModalClose}
      fullWidth
      maxWidth={'md'}
    >
      <DialogTitle>Service ROs</DialogTitle>
      <DialogContent>
        {loading && <Loading />}
        {!loading && results.length === 0 && (
          <Typography variant="body2">No Service ROs Found</Typography>
        )}
        {!loading && results.length > 0 && (
          <>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {headCells.map(headCell => (
                      <TableCell key={headCell.id}>{headCell.label}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {results
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(result => (
                      <TableRow key={result.id}>
                        <TableCell>
                          <Tooltip title={'Open RO Details in a new tab'}>
                            <IconButton
                              onClick={() =>
                                handleClickOpenRO(
                                  result.ro_number,
                                  result.dealer_id,
                                )
                              }
                              size="large"
                            >
                              <InfoIcon
                                style={{
                                  color: '#2196f3',
                                  fontSize: '22px',
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                        <TableCell>{result.ro_number}</TableCell>
                        <TableCell>
                          {moment(result.opened_date).format('ll')}
                        </TableCell>
                        <TableCell>{result?.remarks ?? 'N/A'}</TableCell>
                        <TableCell>{result.status}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPage={5}
              rowsPerPageOptions={[5]}
              page={page}
              count={total}
              onPageChange={handleChangePage}
              style={{ float: 'right' }}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleModalClose} color="primary" variant="outlined">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReconRos;
