import moment from 'moment';

import { Status } from '../../constants';

export const NAME = 'market-mobile/deallog';

export const RefetchFilters = ['assignees', 'date_options'];

export const CheckboxFilters = [
  'wholesale_deal',
  'retail_deal',
  'fleet_deal',
  'keywords',
];

export const DmsDealNames = {
  wholesale_deal: 'Wholesale',
  retail_deal: null,
  fleet_deal: 'Fleet',
};

export const InitialFilters = {
  dms_deal_types: [{ value: 'retail', title: 'Retail Deals' }],
  hide_statuses: [
    {
      value: Status.CARRYOVER,
      title: 'Hide Carryover Deals',
    },
  ],
  selected_statuses: [],
  date_options: [
    {
      value: moment().startOf('month').format('M YYYY'),
      title: 'Month to Date',
    },
  ],
};

export const ArrayFilters = [
  'assignees',
  'deal_types',
  'dms_deal_types',
  'sub_statuses',
];

export const DefaultFilters = {
  statuses: [...Status.CLOSED, ...Status.OPEN],
  month: Number(moment().format('M')),
  year: Number(moment().format('YYYY')),
};

export const NonRetailDeals = ['Wholesale', 'Fleet'];
