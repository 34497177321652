import React, { useState } from 'react';

/* external */
import gql from 'graphql-tag';
import { useFormContext, useWatch } from 'react-hook-form';
import { useQuery } from '@apollo/react-hooks';

/* Material UI */
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import { useMediaQuery } from '@mui/material';

/* internal */
import { formatOdometer, formatPrice } from 'utils';
import { formatPhoto } from 'modules/inventory/utils';
import Loading from 'components/MaterialUI/Loading';
import { pitchStyles } from 'modules/pitches/utils';
import GroupVehicleModal from 'modules/inventory/components/tables/GroupVehicleModal';

const QUERY = gql`
  query Query($id: Int!) {
    inventory {
      getVehicle(id: $id) {
        id
        days_in_stock
        list_price
        make_id
        make_name
        model_id
        model_name
        msrp
        odometer
        photos {
          id
          cloudinary_public_id
        }
        stock_type
        stock_number
        trim
        trim_variation
        vin
        year
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  title: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 20,
    },
    fontWeight: 'bold',
  },
  labelBox: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
      paddingRight: theme.spacing(0.5),
      marginLeft: theme.spacing(0.5),
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '16px',
      paddingRight: theme.spacing(1),
    },
    fontWeight: 'bold',
  },
  valueBox: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '16px',
    },
  },
  actionButton: {
    backgroundColor: theme.actions.create.color,
    color: 'white',
  },
  button: pitchStyles.responsiveButton(theme),
}));

const LabelBox = ({ children }) => (
  <Box className={useStyles().labelBox} component="span">
    {children}
  </Box>
);

const DataBox = ({ children }) => (
  <Box className={useStyles().valueBox} component="span">
    {children}
  </Box>
);

const PitchVehicleSummaryCard = ({ skipSync, pitchVehicle, ...rest }) => {
  const { setValue, control } = useFormContext();
  const theme = useTheme();
  const classes = useStyles();
  const id = useWatch({ control, name: 'vehicles.0.inventoryVehicleId' });

  const { data, loading } = useQuery(QUERY, {
    variables: { id },
    skip: !id,
  });

  const vehicle = data?.inventory.getVehicle;
  const formVehicle = useWatch({ control, name: 'vehicles.0' });
  const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  let {
    days_in_stock,
    list_price,
    make_id,
    model_id,
    make_name,
    model_name,
    msrp,
    odometer,
    photos,
    stock_number,
    stock_type,
    trim_variation,
    trim,
    vin,
    year,
  } = vehicle ?? {};

  // If no inventory vehicle has been selected, just display whatever info
  // we can from the form.
  if (!id) {
    make_name = formVehicle.make ?? pitchVehicle?.make;
    model_name = formVehicle.model ?? pitchVehicle?.model;
    odometer = formVehicle.odometer ?? pitchVehicle?.odometer;
    year = formVehicle.year ?? pitchVehicle?.year;
    trim = formVehicle.trim ?? pitchVehicle?.trim;
    stock_number = formVehicle.stockNumber ?? pitchVehicle?.stockNumber;
    vin = formVehicle.vin ?? pitchVehicle?.vin;
  }

  // If there is a vehicle id, and the form data is out of sync with it, prompt
  // the user to update the form with the data from the inventory vehicle.
  let outOfSync = [];
  if (id && vehicle && !skipSync) {
    if (make_id !== formVehicle.makeId) outOfSync.push('Make');
    if (model_id !== formVehicle.modelId) outOfSync.push('Model');
    if (year !== formVehicle.year) outOfSync.push('Year');
    if (parseInt(odometer, 10) !== parseInt(formVehicle.odometer, 10))
      outOfSync.push('Odometer');
    if (stock_number !== formVehicle.stockNumber)
      outOfSync.push('Stock Number');
    if (vin !== formVehicle.vin) outOfSync.push('VIN');
    if (
      list_price !== null &&
      parseFloat(list_price) !== parseFloat(formVehicle.regularPrice)
    )
      outOfSync.push('Regular Price');
  }

  const displayPhoto = photos?.[0];

  const shouldDirty = true;

  const resyncFields = () => {
    setValue('vehicles.0.year', year, { shouldDirty });
    setValue('vehicles.0.modelId', model_id, { shouldDirty });
    setValue('vehicles.0.makeId', make_id, { shouldDirty });
    setValue('vehicles.0.odometer', odometer, { shouldDirty });
    setValue('vehicles.0.trim', trim, { shouldDirty });
    setValue('vehicles.0.stockNumber', stock_number, { shouldDirty });
    setValue('vehicles.0.vin', vin, { shouldDirty });
    setValue('vehicles.0.regularPrice', list_price, { shouldDirty });
  };

  const [showVehicleModal, setShowVehicleModal] = useState(false);

  if (loading) return <Loading />;
  return (
    <Card {...rest}>
      <CardContent>
        <Box display="flex">
          <Box width="25%">
            <img
              alt="Vehicle"
              style={{ width: '100%', objectFit: 'scale-down' }}
              src={formatPhoto(displayPhoto, 150)}
            />
          </Box>
          <Box paddingLeft={1}>
            <Box display="flex">
              <Box className={classes.title}>
                {[year, make_name, model_name, trim]
                  .filter(x => x && x !== '')
                  .join(' ')}
              </Box>
              {stock_type && (
                <Chip
                  size="small"
                  style={{ marginLeft: theme.spacing(1) }}
                  label={(stock_type ?? '').toUpperCase()}
                />
              )}
            </Box>
            <Box>{trim_variation}</Box>
          </Box>
        </Box>
        <Grid container>
          <Grid item xs={6}>
            <LabelBox>Stock #:</LabelBox>
            <DataBox>{stock_number}</DataBox>
          </Grid>
          <Grid item xs={6}>
            <LabelBox>Odometer:</LabelBox>
            <DataBox>{formatOdometer(odometer)}</DataBox>
          </Grid>
          <Grid item xs={6}>
            <LabelBox>VIN:</LabelBox>
            <DataBox>{vin}</DataBox>
          </Grid>
          <Grid item xs={6}>
            <LabelBox>Days In Stock:</LabelBox>
            <DataBox>{days_in_stock}</DataBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <LabelBox>MSRP:</LabelBox>
            <DataBox>{formatPrice(msrp)}</DataBox>
          </Grid>
          <Grid item xs={12}>
            <LabelBox>List Price:</LabelBox>
            <DataBox>{formatPrice(list_price)}</DataBox>
          </Grid>
        </Grid>
        {outOfSync.length > 0 && (
          <>
            <p>
              The following inventory vehicle fields are different than the form
              values:
            </p>
            <ul>
              {outOfSync.map(x => (
                <li key={x}>{x}</li>
              ))}
            </ul>
            <p>
              Click
              <Button
                style={{ margin: theme.spacing(1) }}
                variant="contained"
                onClick={resyncFields}
              >
                HERE
              </Button>
              to update them.
            </p>
          </>
        )}
        {id && (
          <>
            <Button
              className={`${classes.actionButton} ${classes.button}`}
              onClick={() => setShowVehicleModal(true)}
              style={{ whiteSpace: 'nowrap' }}
              variant="contained"
              size={mobile ? 'small' : 'medium'}
            >
              View in Inventory
            </Button>
            <GroupVehicleModal
              vehicleId={id}
              showCost={true}
              showVehicleModal={showVehicleModal}
              setShowVehicleModal={setShowVehicleModal}
            />
          </>
        )}
      </CardContent>
    </Card>
  );
};

PitchVehicleSummaryCard.fragments = {
  pitch: gql`
    fragment PitchVehicleSummaryCardPitch on Pitch {
      vehicles {
        id
        make
        model
        odometer
        regularPrice
        stockNumber
        trim
        vin
        year
      }
    }
  `,
};
export default PitchVehicleSummaryCard;
