import React from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material';
import Badge from '@mui/material/Badge';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { CONFIRMED_STATUSES } from 'constants.js';
import { formatPhoneNumber, defaultTZ } from 'utils';
import { identityTypeIcon, initials } from '../utils';
import gql from 'graphql-tag';

const MergeSuccessful = ({ defaultCustomer, selectedFields }) => {
  const customer = { ...defaultCustomer, ...selectedFields };
  const theme = useTheme();
  const history = useHistory();
  const { app_account: goAutoAppAcct } = customer;
  const customerHasGoAutoAppAcct =
    CONFIRMED_STATUSES.includes(goAutoAppAcct?.status) &&
    goAutoAppAcct?.isEnabled;

  const identities = customer?.identities || [];

  return (
    <Card elevation={3} style={{ width: '100%' }}>
      <CardHeader
        title={'Success!'}
        titleTypographyProps={{
          align: 'center',
          variant: 'h6',
        }}
        style={{ color: 'green' }}
      />
      <CardContent>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginLeft: 'auto',
              marginRight: 'auto',
              paddingBottom: '2rem',
            }}
          >
            Customer profiles have been successfully merged!
          </Box>
        </Box>
        <Grid
          container
          direction="row"
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
          xs={12}
        >
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            <Grid item xs={2}>
              <Badge
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                overlap="circular"
                invisible={!customerHasGoAutoAppAcct}
                badgeContent={
                  <CheckCircleIcon
                    fontSize="small"
                    style={{ color: 'green' }}
                  />
                }
              >
                <Avatar>{initials(customer)}</Avatar>
              </Badge>
            </Grid>
            <Grid item xs={10}>
              <Box>{customer?.fullname} </Box>
              <Box
                style={{
                  color: '#808080',
                  fontSize: '12px',
                  paddingBottom: '1rem',
                }}
              >
                <Grid
                  container
                  direction="row"
                  style={{
                    display: 'flex',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                  }}
                  xs={12}
                >
                  <Grid item xs={6}>
                    Profile Created {moment(customer.created).format('ll')}
                  </Grid>
                  {customerHasGoAutoAppAcct && (
                    <Grid item xs={6}>
                      {`Go Auto App Account Created: ${moment(
                        goAutoAppAcct.createdAt,
                      )
                        .tz(defaultTZ)
                        .format('MMM YYYY')}`}
                    </Grid>
                  )}
                </Grid>
              </Box>
              <Box
                style={{
                  display: 'flex',
                  marginTop: 'auto',
                  marginBottom: 'auto',
                }}
              >
                <Grid
                  container
                  direction="row"
                  style={{
                    display: 'flex',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                  }}
                >
                  {identities.map(identity => (
                    <Grid
                      item
                      style={{
                        fontSize: '14px',
                        display: 'flex',
                        marginTop: 'auto',
                        marginBottom: 'auto',
                        paddingRight: '1rem',
                      }}
                    >
                      {identityTypeIcon(identity.identity_type)}
                      <Box style={{ paddingLeft: '0.5rem' }}>
                        {identity.identity_type === 'sms'
                          ? formatPhoneNumber(identity.identity_value)
                          : identity.identity_value}
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Grid>
          </Box>
        </Grid>
      </CardContent>
      <CardActions
        style={{ justifyContent: 'flex-end', verticalAlign: 'bottom' }}
      >
        <Button
          size="small"
          style={theme.actions.add}
          onClick={() => history.push('/customers/search')}
        >
          Customers Search
        </Button>
        <Button
          size="small"
          style={theme.actions.add}
          onClick={() => history.push(`/customers/${customer._id}/details`)}
        >
          Merged Customer Details
        </Button>
      </CardActions>
    </Card>
  );
};

MergeSuccessful.fragments = {
  customer: gql`
    fragment MergeSuccessfulFragment on Customer {
      app_account {
        createdAt
        status
        isEnabled
      }
      identities {
        identity_type
        identity_value
      }
      first_name
      last_name
      fullname
      created
    }
  `,
};

export default MergeSuccessful;
