import { formatPercent } from 'utils';

import {
  CBB_COMPLETE_FACET,
  MISSING_BOTH_FACET,
  NEEDS_NUMBERS_FACET,
} from './const';
import { URL } from 'constants.js';

export const paveCaptureLink = paveSessionId =>
  `${URL.paveBase}/launch/${paveSessionId}`;

export const paveReportLink = paveSessionId =>
  `${URL.paveReports}/${paveSessionId}`;

export const smsMessageBody = paveSessionId =>
  `Click the following link to start your PAVE vehicle inspection session: ${paveCaptureLink(
    paveSessionId,
  )}`;

export const convertDateFilters = ({ to, from }, { model, field }) =>
  [
    to
      ? {
          model,
          field,
          op: '<=',
          value: to.endOf('day').toISOString(),
        }
      : null,
    from
      ? {
          model,
          field,
          op: '>=',
          value: from.startOf('day').toISOString(),
        }
      : null,
  ].filter(x => x);

export const convertSearchFilters = keywords =>
  keywords
    ? keywords.split(' ').map(keyword => ({
        or: [
          ...['make', 'model', 'trim', 'firstName', 'lastName'].map(field => ({
            model: 'Appraisal',
            field,
            op: 'ilike',
            value: `${keyword}%`,
          })),
          {
            model: 'Appraisal',
            field: 'vin',
            op: 'ilike',
            value: `%${keyword}%`,
          },
          {
            model: 'Appraisal',
            field: 'year',
            op: '==',
            value: parseInt(keyword) || 0,
          },
        ],
      }))
    : [];

export const convertCategoryFilters = ({ facet, value }) =>
  value !== null && facet !== null
    ? [
        {
          ...facet,
          value,
        },
      ]
    : [];

const findFacet = ({ model, field }, _facets) =>
  _facets?.find(x => model === x.model && field === x.field);

const getFacetCount = (facet, value, _facets) =>
  findFacet(facet, _facets)?.data?.find(x => x.value === value)?.count ?? 0;

const getFacetTotal = (facet, _facets) =>
  findFacet(facet, _facets)?.data?.reduce(
    (total, { count }) => total + count,
    0,
  ) ?? 0;

const getFacetPercent = (facet, value, _facets) => {
  const total = getFacetTotal(facet, _facets);

  return formatPercent(
    total === 0 ? 0 : (getFacetCount(facet, value, _facets) * 100) / total,
    '%',
    { places: 0 },
  );
};

const getFacetFraction = (facet, value, _facets) =>
  `${getFacetCount(facet, value, _facets)}/${getFacetTotal(facet, _facets)}`;

export const categoryOptions = stats => {
  // there's probably a smarter way to do this, but sometimes
  // dumb and simple is better.
  const total = getFacetTotal(NEEDS_NUMBERS_FACET, stats);
  const needsNumbersPercent = getFacetPercent(
    NEEDS_NUMBERS_FACET,
    'True',
    stats,
  );
  const needsNumbersFraction = getFacetFraction(
    NEEDS_NUMBERS_FACET,
    'True',
    stats,
  );
  const missingCbbPercent = getFacetPercent(CBB_COMPLETE_FACET, 'False', stats);
  const missingCbbFraction = getFacetFraction(
    CBB_COMPLETE_FACET,
    'False',
    stats,
  );
  const missingBothPercent = getFacetPercent(MISSING_BOTH_FACET, 'True', stats);
  const missingBothFraction = getFacetFraction(
    MISSING_BOTH_FACET,
    'True',
    stats,
  );

  return [
    {
      title: 'Appraisals Created',
      value: total,
      subValue: null,
      mobileLabel: `All (${total})`,
    },
    {
      title: 'Missing Appraised Values',
      value: needsNumbersPercent,
      subValue: needsNumbersFraction,
      mobileLabel: `No Value (${needsNumbersPercent})`,
    },
    {
      title: 'Missing CBB Values',
      value: missingCbbPercent,
      subValue: missingCbbFraction,
      mobileLabel: `No CBB Val (${missingCbbPercent})`,
    },
    {
      title: 'Missing Both',
      value: missingBothPercent,
      subValue: missingBothFraction,
      mobileLabel: `Missing Both (${missingBothPercent})`,
    },
  ];
};

export const countryFromVin = vin => {
  let country = undefined;
  if (vin) {
    vin = vin.toUpperCase();
    country = {
      1: 'USA',
      2: 'Canada',
      3: 'Mexico',
      4: 'USA',
      5: 'USA',
      J: 'Japan',
      K: 'South Korea',
      S: 'England',
      W: 'Germany',
      Y: 'Sweden/Finland',
    }[vin[0]];

    // get ascii code of second character
    let code = vin.charCodeAt(1);

    if (
      (country === 'South Korea' && (code < 76 || code > 82)) ||
      (country === 'England' && (code < 65 || code > 77)) ||
      (country === 'Sweden/Finland' &&
        !((code >= 70 && code <= 75) || (code >= 83 && code <= 87)))
    )
      country = 'Unknown';
  }
  return country ?? 'Unknown';
};
