import React from 'react';

/* Material UI */
import { AccordionSummary, CardHeader, useMediaQuery } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const ResponsiveCardHeader = ({ children, expandable = true, ...rest }) =>
  useMediaQuery(theme => theme.breakpoints.down('sm')) ? (
    <AccordionSummary
      expandIcon={expandable ? <ExpandMoreIcon /> : null}
      {...rest}
    >
      {children}
    </AccordionSummary>
  ) : (
    <CardHeader title={children} {...rest} />
  );

export default ResponsiveCardHeader;
