import React from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';

import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { isEmpty } from 'underscore';
import TextFieldControl from 'components/MaterialUI/TextFieldControl';

const ADD_LEAD_SOURCE = gql`
  mutation extendOrganizationDeskingSetting(
    $setting_key: String!
    $value: JSON!
  ) {
    extendOrganizationDeskingSetting(setting_key: $setting_key, value: $value) {
      lead_sources
    }
  }
`;

const AddLeadSourceModal = ({ handleClose, refetch }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit } = useForm();

  const [addLeadSource] = useMutation(ADD_LEAD_SOURCE, {
    onCompleted: data => {
      handleClose();
      refetch();
      enqueueSnackbar('Lead Source Added!', { variant: 'success' });
    },
    onError: err => {
      enqueueSnackbar(`Could not add lead source error: ${err}`, {
        variant: 'error',
      });
    },
  });

  const onSubmit = ({ leadSource }) => {
    if (!isEmpty(leadSource))
      addLeadSource({
        variables: {
          setting_key: 'lead_sources',
          value: leadSource,
        },
      });
  };

  return (
    <>
      <DialogTitle>Add a New Lead Source</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <TextFieldControl
            control={control}
            defaultValue=""
            label="Lead Source"
            name="leadSource"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button type="submit">Add</Button>
        </DialogActions>
      </form>
    </>
  );
};

export default AddLeadSourceModal;
