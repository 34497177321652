// Internal
import moment from 'moment-timezone';
import React from 'react';
import { useHistory } from 'react-router-dom';

// Material UI
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import { DatePicker } from '@mui/x-date-pickers';

import { sessionKeys } from '../constants';
import { getMediumDate } from 'utils';
import { getSalesDashboardNumbers } from '../utils';
import SalesDashboardMobileRow from './SalesDashboardMobileRow';
import { AppointmentStatus, Status, STATUS_ICON } from 'constants.js';

const useStyles = makeStyles(theme => ({
  card: {
    width: '150px',
    height: '125px',
    textAlign: 'center',
    padding: '1rem',
    fontSize: '18px',
    fontWeight: 'bold',
  },
}));

const SalesDashboardMobile = ({
  data,
  loading,
  handleUpdateStatus,
  dealerName,
  buttonColor,
  statusColor,
  dealerId,
  selectedUser,
  refetch,
  dateFromFilter,
  dateToFilter,
  handleDateFromChange,
  handleDateToChange,
}) => {
  const history = useHistory();
  const { card } = useStyles();

  const {
    openOpportunitiesCount,
    completedOpportunitiesCount,
    tubedOpportunitiesCount,
    lostOpportunitiesCount,
    pendingDealsCount,
    bookedDealsCount,
    openOpps,
    completedOpps,
    tasks,
    tasksCount,
    upcomingCount,
    attendedCount,
    createdTodayCount,
    appointmentDateRangeCount,
  } = getSalesDashboardNumbers(data);

  const setOppFiltersStatus = statuses => {
    const filters = {
      sort_by: [
        {
          key: 'created',
          direction: -1,
        },
      ],
      statuses,
      sales_assignees: [selectedUser],
    };
    window.sessionStorage.setItem(
      sessionKeys.oppFilters,
      JSON.stringify(filters),
    );
    window.sessionStorage.setItem(sessionKeys.oppDealer, dealerId);
    history.push('/opportunities/search');
  };

  return (
    <>
      <Box paddingTop={2} paddingLeft={1}>
        <Typography variant="h5" style={{ fontWeight: 'bold' }}>
          Sales
        </Typography>
        <DatePicker
          disableToolbar
          variant="inline"
          format="MM/DD/YYYY"
          margin="normal"
          id="date-picker-inline"
          label="Date From"
          style={{
            padding: '1em',
          }}
          onChange={date => handleDateFromChange(date)}
          value={moment(dateFromFilter)}
        />
        <DatePicker
          disableToolbar
          variant="inline"
          format="MM/DD/YYYY"
          margin="normal"
          id="date-picker-inline"
          label="Date To"
          style={{
            padding: '1em',
          }}
          onChange={date => handleDateToChange(date)}
          value={moment(dateToFilter)}
        />
      </Box>
      <Box paddingTop={2} paddingLeft={1}>
        <Paper
          elevation={0}
          style={{ backgroundColor: '#f2f2f2', maxWidth: '1000px' }}
        >
          <Grid
            container
            direction="row"
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
              padding: '1rem',
            }}
            xs={12}
          >
            <Grid item xs={6} style={{ paddingBottom: '1rem' }}>
              <Card
                className={card}
                onClick={() => setOppFiltersStatus(Status.OPEN_OPPS)}
              >
                <Box>{openOpportunitiesCount}</Box>
                <Box>Open Opportunities</Box>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card
                className={card}
                onClick={() => setOppFiltersStatus(Status.COMPLETED)}
              >
                <Box>{completedOpportunitiesCount}</Box>
                <Box>Sold Deals</Box>
              </Card>
            </Grid>
            <Grid item xs={6} style={{ paddingBottom: '1rem' }}>
              <Card
                className={card}
                onClick={() => setOppFiltersStatus([Status.TUBED])}
              >
                <Box>{tubedOpportunitiesCount}</Box>
                <Box>Tubed Opportunities</Box>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card
                className={card}
                onClick={() => setOppFiltersStatus([Status.LOST])}
              >
                <Box>{lostOpportunitiesCount}</Box>
                <Box>Lost Opportunities</Box>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card
                className={card}
                onClick={() => {
                  window.sessionStorage.setItem(
                    sessionKeys.taskAssignee,
                    JSON.stringify([selectedUser]),
                  );
                  window.sessionStorage.setItem(
                    sessionKeys.taskStatus,
                    JSON.stringify([0]),
                  );
                  window.sessionStorage.setItem(
                    sessionKeys.taskDealer,
                    dealerId,
                  );
                  history.push('/tasks');
                }}
              >
                <Box>{tasksCount}</Box>
                <Box>Pending Tasks</Box>
              </Card>
            </Grid>
          </Grid>
          <Box style={{ padding: '0 1rem' }}>
            <hr />
          </Box>
          <Box style={{ padding: '0 1rem' }}>
            <Typography variant="h6" style={{ fontWeight: 'bold' }}>
              Open Opportunities
            </Typography>
            {openOpps?.length === 0 && !loading && (
              <Box paddingTop={1}>
                You Currently Have No Open Opportunities.
              </Box>
            )}
            {openOpps?.length > 0 && (
              <>
                {openOpps?.map(opp => (
                  <Box style={{ paddingBottom: '1rem' }}>
                    <Card
                      xs={12}
                      key={opp._id}
                      onClick={() =>
                        history.push(
                          `/opportunities/${opp._id}/${opp.customer_id}/details`,
                        )
                      }
                    >
                      <Grid
                        container
                        direction="row"
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          padding: '1rem',
                        }}
                      >
                        <Grid
                          item
                          xs={8}
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginRight: 0,
                            marginLeft: 'auto',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Box
                            style={{
                              paddingBottom: '1rem',
                              fontWeight: 'bold',
                            }}
                          >
                            {opp.customer_name}
                          </Box>
                          <Box>Created on {getMediumDate(opp.created)}</Box>
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginRight: 0,
                            marginLeft: 'auto',
                          }}
                        >
                          <Box
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              marginRight: 0,
                              marginLeft: 'auto',
                              paddingBottom: '1rem',
                            }}
                          >
                            <Box
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                              }}
                            >
                              <Icon
                                style={{
                                  fontSize: '1rem',
                                  color: STATUS_ICON[opp.status].color,
                                }}
                                className={STATUS_ICON[opp.status].icon}
                              />
                              <Box style={{ paddingLeft: '0.5rem' }}>
                                <Chip
                                  color="secondary"
                                  label={`${opp?.stock_type}`}
                                  size="small"
                                />
                              </Box>
                            </Box>
                          </Box>
                          <Box
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              marginRight: 0,
                              marginLeft: 'auto',
                            }}
                          >
                            Deal #: {opp?.dms_deal?.deal_number || 'N/A'}
                          </Box>
                        </Grid>
                      </Grid>
                    </Card>
                  </Box>
                ))}
              </>
            )}
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginRight: 0,
                  marginLeft: 'auto',
                  padding: '1rem 0',
                }}
              >
                <Button
                  onClick={() => setOppFiltersStatus(Status.OPEN_OPPS)}
                  variant="contained"
                  color="default"
                >
                  View All
                </Button>
              </Box>
            </Box>
            <Box>
              <hr />
            </Box>
            <Box style={{ padding: '1rem 0' }}>
              <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                Tasks
              </Typography>
              {tasks?.length === 0 && (
                <Box paddingTop={1}>You Currently Have No Pending Tasks.</Box>
              )}
              {tasks?.map(task => (
                <SalesDashboardMobileRow
                  task={task}
                  handleUpdateStatus={handleUpdateStatus}
                  dealerName={dealerName}
                  buttonColor={buttonColor}
                  statusColor={statusColor}
                  refetch={refetch}
                />
              ))}
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginRight: 0,
                    marginLeft: 'auto',
                    padding: '1rem 0',
                  }}
                >
                  <Button
                    variant="contained"
                    color="default"
                    onClick={() => {
                      window.sessionStorage.setItem(
                        sessionKeys.taskAssignee,
                        JSON.stringify([selectedUser]),
                      );
                      window.sessionStorage.setItem(
                        sessionKeys.taskStatus,
                        JSON.stringify([0]),
                      );
                      window.sessionStorage.setItem(
                        sessionKeys.taskDealer,
                        dealerId,
                      );
                      history.push('/tasks');
                    }}
                  >
                    View All
                  </Button>
                </Box>
              </Box>
              <Box>
                <hr />
              </Box>
              <Box style={{ padding: '1rem 0' }}>
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                  Deal Log Summary
                </Typography>
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    paddingTop: '1rem',
                  }}
                >
                  <Box style={{ paddingRight: '1rem' }}>
                    <Card
                      className={card}
                      onClick={() => setOppFiltersStatus([Status.PENDING])}
                    >
                      <Box>{pendingDealsCount}</Box>
                      <Box>Pending Deals</Box>
                    </Card>
                  </Box>
                  <Box style={{ paddingRight: '1rem' }}>
                    <Card
                      className={card}
                      onClick={() => setOppFiltersStatus(Status.OPEN)}
                    >
                      <Box>{bookedDealsCount}</Box>
                      <Box>Booked Deals</Box>
                    </Card>
                  </Box>
                </Box>
                <Box style={{ paddingTop: '1rem' }}>
                  {completedOpps?.map(opp => (
                    <Box style={{ paddingBottom: '1rem' }}>
                      <Card
                        xs={12}
                        key={opp._id}
                        onClick={() =>
                          history.push(
                            `/opportunities/${opp._id}/${opp.customer_id}/details`,
                          )
                        }
                      >
                        <Grid
                          container
                          direction="row"
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            padding: '1rem',
                          }}
                        >
                          <Grid
                            item
                            xs={8}
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              marginRight: 0,
                              marginLeft: 'auto',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Box
                              style={{
                                paddingBottom: '1rem',
                                fontWeight: 'bold',
                              }}
                            >
                              {opp.customer_name}
                            </Box>
                            <Box>Created on {getMediumDate(opp.created)}</Box>
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              marginRight: 0,
                              marginLeft: 'auto',
                            }}
                          >
                            <Box
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                marginRight: 0,
                                marginLeft: 'auto',
                                paddingBottom: '1rem',
                              }}
                            >
                              <Box
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                }}
                              >
                                <Icon
                                  style={{
                                    fontSize: '1rem',
                                    color: STATUS_ICON[opp.status].color,
                                  }}
                                  className={STATUS_ICON[opp.status].icon}
                                />
                                <Box style={{ paddingLeft: '0.5rem' }}>
                                  <Chip
                                    color="secondary"
                                    label={`${opp?.stock_type}`}
                                    size="small"
                                  />
                                </Box>
                              </Box>
                            </Box>
                            <Box
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                marginRight: 0,
                                marginLeft: 'auto',
                              }}
                            >
                              Deal #: {opp?.dms_deal?.deal_number || 'N/A'}
                            </Box>
                          </Grid>
                        </Grid>
                      </Card>
                    </Box>
                  ))}
                </Box>
              </Box>
              <Box>
                <hr />
              </Box>
              <Box style={{ padding: '1rem 0' }}>
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                  Appointments
                </Typography>
                <Grid
                  container
                  direction="row"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                  }}
                  xs={12}
                >
                  <Grid item xs={6} style={{ paddingBottom: '1rem' }}>
                    <Card
                      className={card}
                      onClick={() => {
                        window.sessionStorage.setItem(
                          sessionKeys.startDateFromFilter,
                          JSON.stringify(moment().startOf('day')),
                        );
                        window.sessionStorage.setItem(
                          sessionKeys.apptDealer,
                          dealerId,
                        );
                        history.push('/appointments');
                      }}
                    >
                      <Box>{upcomingCount}</Box>
                      <Box>Upcoming Appointments</Box>
                    </Card>
                  </Grid>
                  <Grid item xs={6} style={{ paddingBottom: '1rem' }}>
                    <Card
                      className={card}
                      onClick={() => {
                        window.sessionStorage.setItem(
                          sessionKeys.apptFilterFacets,
                          JSON.stringify([
                            {
                              model: 'Appointment',
                              field: 'statuses',
                              key: AppointmentStatus.ATTENDED,
                              value: 'Attended',
                              options: {},
                            },
                          ]),
                        );
                        window.sessionStorage.setItem(
                          sessionKeys.apptDealer,
                          dealerId,
                        );
                        history.push('/appointments');
                      }}
                    >
                      <Box>{attendedCount}</Box>
                      <Box>Appointments Attended (Last 7 Days)</Box>
                    </Card>
                  </Grid>
                  <Grid item xs={6} style={{ paddingBottom: '1rem' }}>
                    <Card
                      className={card}
                      onClick={() => {
                        window.sessionStorage.setItem(
                          sessionKeys.createdUntilFilter,
                          JSON.stringify(moment().endOf('day').toISOString()),
                        );
                        window.sessionStorage.setItem(
                          sessionKeys.createdFromFilter,
                          JSON.stringify(moment().startOf('day').toISOString()),
                        );
                        window.sessionStorage.setItem(
                          sessionKeys.apptDealer,
                          dealerId,
                        );
                        history.push('/appointments');
                      }}
                    >
                      <Box>{createdTodayCount}</Box>
                      <Box style={{ fontSize: '14px' }}>
                        Appointments Created Today
                      </Box>
                    </Card>
                  </Grid>
                  <Grid item xs={6} style={{ paddingBottom: '1rem' }}>
                    <Card
                      className={card}
                      onClick={() => {
                        window.sessionStorage.setItem(
                          sessionKeys.createdUntilFilter,
                          JSON.stringify(
                            moment(dateToFilter).endOf('day').toISOString(),
                          ),
                        );
                        window.sessionStorage.setItem(
                          sessionKeys.createdFromFilter,
                          JSON.stringify(
                            moment(dateFromFilter).startOf('day').toISOString(),
                          ),
                        );
                        window.sessionStorage.setItem(
                          sessionKeys.apptDealer,
                          dealerId,
                        );
                        history.push('/appointments');
                      }}
                    >
                      <Box>{appointmentDateRangeCount}</Box>
                      <Box style={{ fontSize: '14px' }}>
                        {`Appointments from ${moment(dateFromFilter).format(
                          'MM/DD/YYYY',
                        )} to ${moment(dateToFilter).format('MM/DD/YYYY')}`}
                      </Box>
                    </Card>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Paper>
      </Box>
    </>
  );
};

export default SalesDashboardMobile;
