import React, { useRef, useState } from 'react';

/* external */
import { useSnackbar } from 'notistack';
import { cloneDeep } from 'lodash';

/* Material UI */
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';

/* external */
import InspectionCheckboxes from '../InspectionCheckboxes';

const AuroraCPOForm = props => {
  const { enqueueSnackbar } = useSnackbar();

  // Aurora Dodge CPO form fields
  const initial = {
    a1: 'Vehicle is less than six years of age from original in-service date.',
    a2: 'Vehicle has less than 180,000 km.',
    a3: 'Odometer shows no sign of tampering and the metreage is supported by the body computer and by the general condition of the vehicle.',
    a4: 'On-board diagnostic system fault codes, if any, have been repaired.',
  };
  const hood = {
    a5: 'Engine block, cylinder head(s), intake and exhaust manifolds are free of cracks, damage or obvious improper repair.',
    a6: 'Oil and filter have been changed at the time of inspection.',
    a7: 'Oil leaks, if any, have been corrected.',
    a8: 'Automatic transmission fluid is at prescribed level and shows no signs of additives or foreign material, nor is it discoloured.',
    a9: 'Cooling system has been pressure-tested and shows no signs of leaks, additives or defective hoses.',
    a10: 'A cylinder compression test has been performed. The results are recorded here:',
    a11: 'Power steering pump and gear assembly operate properly, show no sign of leaks and fluid is at prescribed level. Condition of fluid is good.',
    a12: 'Brake master cylinder shows no signs of leaks or damaged lines and fluid is a prescribed level.',
    a13: 'Air conditioning system shows no signs or leaks or abnormal noises and cools vehicle properly.',
  };
  const vehicle = {
    a14: 'Transmission case is free of cracks or damage.',
    a15: 'All fluids are at the prescribed levels and show no sign of additives.',
    a16: 'Fluid and oil leaks, if any, have been corrected.',
    a17: 'There is no sign of physical damage that could cause a future mechanical failure.',
    a18: 'Upper and lower ball joints are lubricated and show no sign of excessive freeplay.',
    a19: 'Disc brake calipers, wheel cylinders, brake lines and flexible hoses show no sign of leakage or deterioration.',
    a20: 'Struts display no sign of leakage or weakness.',
    a21: 'Suspension is not modified (e.g. oversized tires, lift kit).',
  };
  const road = {
    a22: 'Engine is operating smoothly at idle and normal driving speeds and has no abnormal noises.',
    a23: 'Engine oil pressure and water temperature are within specifications.',
    a24: 'Automatic or standard transmission shifts properly under all driving conditions and has no abnormal noises.',
    a25: 'Vibrations, excessive backlash, abnormal whine or noises, if any, have been corrected.',
    a26: 'Vehicle does not exhibit signs of excessive oil consumption (blue exhaust smoke, oil-fouled spark plug(s)).',
    a27: 'Brake pedal application effort is normal and no brake pull or noise is evident.',
    a28: 'Steering effort shows no sign of excessive play, wander or effort.',
  };

  let initialObj = cloneDeep(initial);
  let hoodObj = cloneDeep(hood);
  let vehicleObj = cloneDeep(vehicle);
  let roadObj = cloneDeep(road);

  Object.entries(initialObj).map(([name]) => (initialObj[name] = 'yes'));
  Object.entries(hoodObj).map(([name]) => (hoodObj[name] = 'yes'));
  Object.entries(vehicleObj).map(([name]) => (vehicleObj[name] = 'yes'));
  Object.entries(roadObj).map(([name]) => (roadObj[name] = 'yes'));

  const [initialValues, setInitialValues] = useState(initialObj);
  const [hoodValues, setHoodValues] = useState(hoodObj);
  const [vehicleValues, setVehicleValues] = useState(vehicleObj);
  const [roadValues, setRoadValues] = useState(roadObj);
  const [testMethod, setTestMethod] = useState('');

  const handleInitial = e => {
    const { name, value } = e.target;
    setInitialValues(prevInitialValues => ({
      ...prevInitialValues,
      [name]: value,
    }));
  };
  const handleHood = e => {
    const { name, value } = e.target;
    setHoodValues(prevHoodValues => ({
      ...prevHoodValues,
      [name]: value,
    }));
  };
  const handleVehicle = e => {
    const { name, value } = e.target;
    setVehicleValues(prevVehicleValues => ({
      ...prevVehicleValues,
      [name]: value,
    }));
  };
  const handleRoad = e => {
    const { name, value } = e.target;
    setRoadValues(prevRoadValues => ({
      ...prevRoadValues,
      [name]: value,
    }));
  };
  const handleTestMethod = e => {
    const { value } = e.target;
    setTestMethod(value);
  };

  const [consent, setConsent] = useState(false);
  const handleConsent = () => {
    setConsent(consent => !consent);
  };

  const [confirm, setConfirm] = useState(false);
  const handleConfirm = () => {
    setConfirm(confirm => !confirm);
  };

  const wholeForm = useRef(null);

  const handleSubmit = e => {
    e.preventDefault();

    if (!consent) {
      enqueueSnackbar(`Please check 'I agree' and then submit the inspection!`);
      return;
    }

    hoodValues['test_method'] = testMethod;
    hoodValues['test_1'] = wholeForm.current['test_1'].value;
    hoodValues['test_2'] = wholeForm.current['test_2'].value;
    hoodValues['test_3'] = wholeForm.current['test_3'].value;
    hoodValues['test_4'] = wholeForm.current['test_4'].value;
    hoodValues['test_5'] = wholeForm.current['test_5'].value;
    hoodValues['test_6'] = wholeForm.current['test_6'].value;
    hoodValues['test_7'] = wholeForm.current['test_7'].value;
    hoodValues['test_8'] = wholeForm.current['test_8'].value;
    hoodValues['test_9'] = wholeForm.current['test_9'].value;
    hoodValues['test_10'] = wholeForm.current['test_10'].value;

    const values = {
      ...initialValues,
      ...hoodValues,
      ...vehicleValues,
      ...roadValues,
    };
    const form_data = {
      aurora: values,
      amvic_number: wholeForm.current['amvic_number'].value,
    };

    props.formData(form_data);
    props.formClose();
  };

  const GreenButton = withStyles({
    root: {
      color: '#fff',
      backgroundColor: '#198754',
      '&:hover': {
        backgroundColor: '#157347',
      },
    },
  })(Button);

  return (
    <form onSubmit={handleSubmit} ref={wholeForm}>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>1. INITIAL INSPECTION</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(initial).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid
                    item
                    xs={8}
                    style={{ paddingLeft: '1rem', marginBottom: '.5rem' }}
                  >
                    {display_name}
                  </Grid>
                  <Grid item xs={4}>
                    <InspectionCheckboxes
                      valueGroup={initialValues}
                      handleGroup={handleInitial}
                      name={name}
                      orange={false}
                      greenValue="yes"
                      redValue="no"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>2. UNDER HOOD</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(hood).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid
                    item
                    xs={8}
                    style={{ paddingLeft: '1rem', marginBottom: '.5rem' }}
                  >
                    {display_name}
                  </Grid>
                  <Grid item xs={4}>
                    <InspectionCheckboxes
                      valueGroup={hoodValues}
                      handleGroup={handleHood}
                      name={name}
                      orange={false}
                      greenValue="yes"
                      redValue="no"
                    />
                  </Grid>
                  {name === 'a10' && (
                    <Grid
                      container
                      alignItems="center"
                      style={{ marginBottom: '1rem' }}
                    >
                      <Grid item xs={3} style={{ paddingLeft: '1rem' }}>
                        Test method:
                      </Grid>
                      <Grid item xs={9}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={testMethod === 'Test1'}
                              onChange={handleTestMethod}
                              value="Test1"
                              name="test_method"
                            />
                          }
                          label="Cylinder Balance"
                          labelPlacement="start"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={testMethod === 'Test2'}
                              onChange={handleTestMethod}
                              value="Test2"
                              name="test_method"
                            />
                          }
                          label="Mechanical Compression Test"
                          labelPlacement="start"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs>
                            <TextField label="1" name={'test_1'} fullWidth />
                          </Grid>
                          <Grid item xs>
                            <TextField label="2" name={'test_2'} fullWidth />
                          </Grid>
                          <Grid item xs>
                            <TextField label="3" name={'test_3'} fullWidth />
                          </Grid>
                          <Grid item xs>
                            <TextField label="4" name={'test_4'} fullWidth />
                          </Grid>
                          <Grid item xs>
                            <TextField label="5" name={'test_5'} fullWidth />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs>
                            <TextField label="6" name={'test_6'} fullWidth />
                          </Grid>
                          <Grid item xs>
                            <TextField label="7" name={'test_7'} fullWidth />
                          </Grid>
                          <Grid item xs>
                            <TextField label="8" name={'test_8'} fullWidth />
                          </Grid>
                          <Grid item xs>
                            <TextField label="9" name={'test_9'} fullWidth />
                          </Grid>
                          <Grid item xs>
                            <TextField label="10" name={'test_10'} fullWidth />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>3. UNDER VEHICLE</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(vehicle).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid
                    item
                    xs={8}
                    style={{ paddingLeft: '1rem', marginBottom: '.5rem' }}
                  >
                    {display_name}
                  </Grid>
                  <Grid item xs={4}>
                    <InspectionCheckboxes
                      valueGroup={vehicleValues}
                      handleGroup={handleVehicle}
                      name={name}
                      orange={false}
                      greenValue="yes"
                      redValue="no"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>4. ROAD TEST</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(road).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid
                    item
                    xs={8}
                    style={{ paddingLeft: '1rem', marginBottom: '.5rem' }}
                  >
                    {display_name}
                  </Grid>
                  <Grid item xs={4}>
                    <InspectionCheckboxes
                      valueGroup={roadValues}
                      handleGroup={handleRoad}
                      name={name}
                      orange={false}
                      greenValue="yes"
                      redValue="no"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Typography style={{ marginTop: '15px' }}>
        This is to certify that the above inspections have been performed and
        all necessary repairs completed. (Attach copy of work order.)
      </Typography>
      <FormControlLabel
        control={<Checkbox />}
        label="I agree"
        onChange={handleConsent}
        value={consent}
      />
      <br />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Technician Name"
            value={props.user.display_name}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Tech #"
            value={props.user.goUserProfile.profile.sales_licence}
            fullWidth
            name={'amvic_number'}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Date"
            value={new Date().toLocaleString()}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <Button
            type="button"
            variant="contained"
            style={{ marginRight: '15px' }}
            onClick={handleConfirm}
            color="default"
          >
            Cancel
          </Button>
          <Dialog
            open={confirm}
            onClose={handleConfirm}
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Current inspection will be closed and unsaved data will be gone.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleConfirm}>Cancel</Button>
              <Button onClick={props.formClose} color="secondary">
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
          <GreenButton type="submit" variant="contained">
            Save
          </GreenButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default AuroraCPOForm;
