import { Role } from 'constants.js';
import gql from 'graphql-tag';

export const DEALER_OPPORTUNITY_METRICS = gql`
  query OpportunityMetricsForDealer(
    $dealerId: Int!
    $statuses: [Int]!
    $year: Int!
    $assignees: [String]
    $deal_types: [String]
    $dms_deal_types: [String]
    $has_variance: Boolean
    $missing_attachments: Boolean
    $include_open: Boolean
    $keywords: String
    $month: Int
    $sub_statuses: [String]
    $year_to_date: Boolean
  ) {
    getDealLogMetrics(
      dealer_ids: [$dealerId]
      statuses: $statuses
      year: $year
      assignees: $assignees
      deal_types: $deal_types
      dms_deal_types: $dms_deal_types
      has_variance: $has_variance
      missing_attachments: $missing_attachments
      include_open: $include_open
      keywords: $keywords
      month: $month
      sub_statuses: $sub_statuses
      year_to_date: $year_to_date
    ) {
      metrics {
        approved_used_backend_gross
        approved_used_frontend_gross
        approved_new_backend_gross
        approved_new_frontend_gross
        carryover_used_frontend_gross
        carryover_new_frontend_gross
        carryover_used_backend_gross
        carryover_new_backend_gross
        cash_used_frontend_gross
        cash_new_frontend_gross
        cash_used_backend_gross
        cash_new_backend_gross
        delivered_used_frontend_gross
        delivered_used_backend_gross
        delivered_new_frontend_gross
        delivered_new_backend_gross
        new_approved_count
        new_carryover_count
        new_cash_count
        new_delivered_count
        new_pending_count
        new_posted_count
        new_pre_app_count
        new_signed_count
        new_tubed_count
        new_variance_gross
        pending_used_backend_gross
        pending_used_frontend_gross
        pending_new_backend_gross
        pending_new_frontend_gross
        posted_used_frontend_gross
        posted_used_backend_gross
        posted_new_frontend_gross
        posted_new_backend_gross
        pre_app_used_frontend_gross
        pre_app_new_frontend_gross
        pre_app_used_backend_gross
        pre_app_new_backend_gross
        rdr_vehicle_count
        signed_used_backend_gross
        signed_used_frontend_gross
        signed_new_backend_gross
        signed_new_frontend_gross
        total_approved_count
        total_cash_count
        total_count
        total_delivered_count
        total_delivered_gross
        total_gross
        total_pending_count
        total_posted_count
        total_posted_gross
        total_signed_count
        total_tubed_count
        used_approved_count
        used_carryover_count
        used_cash_count
        used_delivered_count
        used_pending_count
        used_posted_count
        used_pre_app_count
        used_signed_count
        used_tubed_count
        used_variance_gross
      }
    }
    getDealLogRoleMetrics(
      dealer_ids: [$dealerId]
      statuses: $statuses
      year: $year
      assignees: $assignees
      deal_types: $deal_types
      dms_deal_types: $dms_deal_types
      has_variance: $has_variance
      missing_attachments: $missing_attachments
      include_open: $include_open
      keywords: $keywords
      month: $month
      sub_statuses: $sub_statuses
      year_to_date: $year_to_date
    ) {
      sales_reps_metrics {
        _id
        open_count
        open_front_gross
        sold_count
        sold_back_gross
        sold_front_gross
        sold_total_gross
        tubed_count
        writes
        user {
          display_name
          role
        }
      }
      sales_managers_metrics {
        _id
        open_count
        open_front_gross
        sold_count
        sold_back_gross
        sold_front_gross
        sold_total_gross
        tubed_count
        writes
        user {
          display_name
          role
        }
      }
      finance_managers_metrics {
        _id
        open_count
        open_front_gross
        sold_count
        sold_back_gross
        sold_front_gross
        sold_total_gross
        tubed_count
        writes
        user {
          display_name
          role
        }
      }
    }
  }
`;

export const USERS_FOR_DEALER = gql`
  query UsersForDealer($dealerId: Int!) {
    users: users(
      dealer_ids: [$dealerId]
      status: active
      roles: [
        "${Role.SALES_REP}"
        "${Role.SALES_MANAGER}"
        "${Role.FINANCE_MANAGER}"
        "${Role.INTERNET_SALES_REP}"
        "${Role.FINANCE_DIRECTOR}"
      ]
    ) {
      display_name
      username
      role
    }
  }
`;

export const CURRENT_DEALER_QRY = gql`
  query Dealer($dealerId: Int!) {
    dealer(dealer_id: $dealerId) {
      dealer_name
      desking_settings {
        sub_statuses
      }
    }
  }
`;

export const ALL_USERS_FOR_DEALER = gql`
  query UsersForDealer($dealerId: [Int]!) {
    users: users(dealer_ids: $dealerId, status: active) {
      display_name
      username
      role
    }
  }
`;

export const DEALER_OPPORTUNITIES = gql`
  query OpportunitiesForDealer(
    $dealerId: Int!
    $includeGross: Boolean!
    $statuses: [Int]!
    $year: Int!
    $after: String
    $assignees: [String]
    $deal_types: [String]
    $dms_deal_types: [String]
    $has_variance: Boolean
    $missing_attachments: Boolean
    $include_open: Boolean
    $keywords: String
    $month: Int
    $sub_statuses: [String]
    $year_to_date: Boolean
    $page: Int!
    $sort_by: [SortOpportunityBy]
  ) {
    opportunities: dealLogOpportunities(
      dealer_ids: [$dealerId]
      statuses: $statuses
      year: $year
      assignees: $assignees
      deal_types: $deal_types
      dms_deal_types: $dms_deal_types
      has_variance: $has_variance
      missing_attachments: $missing_attachments
      include_open: $include_open
      keywords: $keywords
      month: $month
      sub_statuses: $sub_statuses
      year_to_date: $year_to_date
      after: $after
      page: $page
      page_size: 25
      sort_by: $sort_by
    ) {
      results {
        _id
        customer_name
        customer_id
        customer {
          _id
          company_name
          app_account {
            username
            isEnabled
            status
          }
        }
        appointments {
          _id
          attendee_usernames
          start_date
          opportunity_id
          status
          type_id
          subject
          type_name
          status_name
          created
          created_by_username
        }
        attachments {
          _id
          attachment_type
          deleted
        }
        status
        status_display
        sub_status
        dealer_id
        dms_deal {
          frontend_gross @include(if: $includeGross)
          backend_gross @include(if: $includeGross)
          total_gross @include(if: $includeGross)
          deal_number
          year
          make_name
          model_name
          stock_number
          payment_type
          sales_deal_type
          trades {
            acv
            color
            gross
            make_name
            model_name
            net
            odometer
            pay_off
            stock_number
            style
            vin
            year
          }
        }
        sales_deal @include(if: $includeGross) {
          frontend_gross {
            value
            updated
            updated_by
          }
          backend_gross {
            value
            updated
            updated_by
          }
          comment {
            content
            updated_by
            updated
          }
        }
        accounting_deal @include(if: $includeGross) {
          frontend_gross {
            value
            updated
            updated_by
          }
          backend_gross {
            value
            updated
            updated_by
          }
          comment {
            content
            updated_by
            updated
          }
        }
        lost_reason
        last_status_change
        preferences
        deal_type
        carryover_date
        sent_to_fi_date
        rdr_punch {
          assigned_to
          notes
          plate_number
          program
          punch_date
          username
        }
        sales_manager_usernames
        sales_rep_usernames
        finance_manager_usernames
        sales_managers {
          username
          display_name
        }
        sales_reps {
          username
          display_name
        }
        finance_managers {
          username
          display_name
        }
        vehicle_recon_complete
        primary_pitch_deallog {
          id
          vehicles {
            id
            vehicleName
          }
        }
        accounting_checklist
        finance_checklist
        extra_checklist
      }
      pagination {
        page
        total
        page_size
      }
    }
  }
`;

export const CSV_DEALER_OPPORTUNITIES = gql`
  query dealLogCSV(
    $dealerId: Int!
    $statuses: [Int]!
    $year: Int!
    $after: String
    $assignees: [String]
    $deal_types: [String]
    $dms_deal_types: [String]
    $has_variance: Boolean
    $missing_attachments: Boolean
    $include_open: Boolean
    $keywords: String
    $month: Int
    $sub_statuses: [String]
    $year_to_date: Boolean
    $sort_by: [SortOpportunityBy]
  ) {
    opportunitiesCSV: dealLogCSV(
      dealer_ids: [$dealerId]
      statuses: $statuses
      year: $year
      assignees: $assignees
      deal_types: $deal_types
      dms_deal_types: $dms_deal_types
      has_variance: $has_variance
      missing_attachments: $missing_attachments
      include_open: $include_open
      keywords: $keywords
      month: $month
      sub_statuses: $sub_statuses
      year_to_date: $year_to_date
      after: $after
      sort_by: $sort_by
    ) {
      key
    }
  }
`;
