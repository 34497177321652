import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import moment from 'moment-timezone';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { DateTimePicker } from '@mui/x-date-pickers';

import { AppointmentStatus } from 'constants.js';

const UPDATE_APPOINTMENT_STATUS = gql`
  mutation updateAppointment($_id: ID!, $status: String!) {
    updateAppointment(_id: $_id, input: { status: $status }) {
      _id
      status
    }
  }
`;

const CONFIRM_APPOINTMENT = gql`
  mutation updateAppointment($_id: ID!, $is_confirmed: Boolean!) {
    updateAppointment(_id: $_id, input: { is_confirmed: $is_confirmed }) {
      _id
      is_confirmed
    }
  }
`;

const RESCHEDULE_APPOINTMENT = gql`
  mutation updateAppointment(
    $_id: ID!
    $start_date: Date!
    $is_confirmed: Boolean!
    $status: String!
  ) {
    updateAppointment(
      _id: $_id
      input: {
        start_date: $start_date
        is_confirmed: $is_confirmed
        status: $status
      }
    ) {
      _id
      start_date
      is_confirmed
      status
    }
  }
`;

const AppointmentActions = ({ appointment, classes }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [isOpen, setIsOpen] = useState(false);
  const [selectedDateTime, setSelectedDateTime] = useState(moment());
  const [rowId, setRowId] = useState('');

  const openPicker = id => {
    setRowId(id);
    setIsOpen(true);
  };

  const handleDateTimeChange = datetime => {
    setSelectedDateTime(datetime);
  };

  const [rescheduleAppointment] = useMutation(RESCHEDULE_APPOINTMENT, {
    onCompleted: () => {
      enqueueSnackbar('Appointment is rescheduled!', { variant: 'success' });
    },
    onError: () => {
      enqueueSnackbar('Cannot update the appointment.', { variant: 'error' });
    },
  });

  const updateRowDate = datetime => {
    rescheduleAppointment({
      variables: {
        _id: rowId,
        start_date: datetime,
        is_confirmed: false,
        status: AppointmentStatus.UNKNOWN,
      },
    });
  };

  const [updateAppointment] = useMutation(UPDATE_APPOINTMENT_STATUS, {
    onCompleted: () => {
      enqueueSnackbar('Status updated successfully!', { variant: 'success' });
    },
    onError: () => {
      enqueueSnackbar('Cannot update the status.', { variant: 'error' });
    },
  });

  const [confirmAppointment] = useMutation(CONFIRM_APPOINTMENT, {
    onCompleted: () => {
      enqueueSnackbar('Appointment is confirmed!', { variant: 'success' });
    },
    onError: () => {
      enqueueSnackbar('Cannot update the appointment.', { variant: 'error' });
    },
  });

  return (
    <Box>
      {appointment.status === AppointmentStatus.UNKNOWN &&
        appointment.is_confirmed === false &&
        moment(appointment.start_date).isAfter(moment()) && (
          <Box className={classes.action} style={{ marginTop: '.5rem' }}>
            <Tooltip title="Confirm">
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  confirmAppointment({
                    variables: {
                      _id: appointment._id,
                      is_confirmed: true,
                    },
                  })
                }
              >
                <i className="fas fa-calendar-alt fa-fw" />
              </Button>
            </Tooltip>
            <Tooltip title="Cancel">
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  updateAppointment({
                    variables: {
                      _id: appointment._id,
                      status: AppointmentStatus.CANCELLED,
                    },
                  })
                }
              >
                <i className="fas fa-times fa-fw" />
              </Button>
            </Tooltip>
          </Box>
        )}
      {appointment.status === AppointmentStatus.UNKNOWN &&
        !moment(appointment.start_date).isAfter(moment()) && (
          <Box className={classes.action} style={{ marginTop: '.5rem' }}>
            <Tooltip title="Show">
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  updateAppointment({
                    variables: {
                      _id: appointment._id,
                      status: AppointmentStatus.ATTENDED,
                    },
                  })
                }
              >
                <i className="fas fa-check fa-fw" />
              </Button>
            </Tooltip>
            <Tooltip title="No Show">
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  updateAppointment({
                    variables: {
                      _id: appointment._id,
                      status: AppointmentStatus.NO_SHOW,
                    },
                  })
                }
              >
                <i className="fas fa-ban fa-fw" />
              </Button>
            </Tooltip>
            <Tooltip title="Cancel">
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  updateAppointment({
                    variables: {
                      _id: appointment._id,
                      status: AppointmentStatus.CANCELLED,
                    },
                  })
                }
              >
                <i className="fas fa-times fa-fw" />
              </Button>
            </Tooltip>
          </Box>
        )}
      {(appointment.status === AppointmentStatus.CANCELLED ||
        appointment.status === AppointmentStatus.NO_SHOW) && (
        <Box className={classes.action} style={{ marginTop: '.5rem' }}>
          <DateTimePicker
            id={appointment._id}
            open={isOpen}
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
            value={selectedDateTime}
            onChange={handleDateTimeChange}
            onAccept={updateRowDate}
            slotProps={{
              textField: { style: { display: 'none' } },
            }}
          />
          <Tooltip title="Reschedule">
            <Button
              variant="contained"
              color="primary"
              onClick={() => openPicker(appointment._id)}
            >
              <i className="fas fa-redo fa-fw" />
            </Button>
          </Tooltip>
        </Box>
      )}
    </Box>
  );
};

export default AppointmentActions;
