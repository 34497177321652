import React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';

import { formatPrice } from '../../../utils';
import BreakdownSummaryHeader from './DealLogMoreMetricsBreakdownSummaryHeader';
import { generateAverage, generatePercent } from './utils';

const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: 'bold',
  },
}));

const MetricsTable = ({ metrics, title }) => {
  const classes = useStyles();

  const sumTotalForMetric = (data, key) => {
    let initialValue = 0;
    let sum = data.reduce((x, d) => {
      return x + d[key];
    }, initialValue);

    return sum;
  };

  const rowMapper = (metric, header = false) => {
    const rowMap = [
      {
        title: 'Name',
        value:
          header || !metric.hasOwnProperty('user')
            ? null
            : metric['user']['display_name'] ?? metric['user']['username'],
      },
      {
        title: 'Writes',
        value: metric['writes'],
      },
      {
        title: 'Open # (Pending, Cash, Approved, Signed)',
        value: metric['open_count'],
      },
      {
        title: 'Open Front Gross ($)',
        value: formatPrice(metric['open_front_gross']),
      },
      {
        title: 'Sold # (Delivered, Posted)',
        value: metric['sold_count'],
      },
      {
        title: 'Sold Front Gross ($)',
        value: formatPrice(metric['sold_front_gross']),
      },
      {
        title: 'Avg Sold Front Gross ($)',
        value: generateAverage(
          metric['sold_front_gross'],
          metric['sold_count'],
        ),
      },
      {
        title: 'Sold Back Gross ($)',
        value: formatPrice(metric['sold_back_gross']),
      },
      {
        title: 'Avg Sold Back Gross ($)',
        value: generateAverage(metric['sold_back_gross'], metric['sold_count']),
      },
      {
        title: ' Sold Total Gross ($)',
        value: formatPrice(metric['sold_total_gross']),
      },
      {
        title: 'Avg Sold Total Gross ($)',
        value: generateAverage(
          metric['sold_total_gross'],
          metric['sold_count'],
        ),
      },
      {
        title: '% Tubed',
        value: generatePercent(metric['writes'], metric['tubed_count']),
      },
    ];
    return header ? rowMap.map(row => row.title) : rowMap;
  };
  return (
    <>
      <Box style={{ marginTop: 30 }}>
        <Typography className={classes.bold} variant="h5">
          {title}
        </Typography>
      </Box>
      <TableContainer>
        <Table>
          <TableHead style={{ backgroundColor: '#cccccc' }}>
            <TableRow>
              {rowMapper([], true).map(header => (
                <TableCell key={header}>
                  <Typography
                    style={{ textAlign: 'center' }}
                    className={classes.bold}
                    variant="body2"
                  >
                    {header}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {metrics.map(metric => {
              const obj = rowMapper(metric, false);
              return (
                <TableRow key={metric['_id']}>
                  {Object.keys(obj).map((k, index) => {
                    return (
                      <TableCell
                        align="right"
                        style={{ width: 125 }}
                        key={`${k.title}-${index}`}
                      >
                        {obj[k].value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
            <TableRow>
              <TableCell></TableCell>
              <TableCell align="right">
                {sumTotalForMetric(metrics, 'writes')}
              </TableCell>
              <TableCell align="right">
                {sumTotalForMetric(metrics, 'open_count')}
              </TableCell>
              <TableCell align="right">
                {formatPrice(sumTotalForMetric(metrics, 'open_front_gross'))}
              </TableCell>
              <TableCell align="right">
                {sumTotalForMetric(metrics, 'sold_count')}
              </TableCell>
              <TableCell align="right">
                {formatPrice(sumTotalForMetric(metrics, 'sold_front_gross'))}
              </TableCell>
              <TableCell align="right">
                {generateAverage(
                  sumTotalForMetric(metrics, 'sold_front_gross'),
                  sumTotalForMetric(metrics, 'sold_count'),
                )}
              </TableCell>
              <TableCell align="right">
                {formatPrice(sumTotalForMetric(metrics, 'sold_back_gross'))}
              </TableCell>
              <TableCell align="right">
                {generateAverage(
                  sumTotalForMetric(metrics, 'sold_back_gross'),
                  sumTotalForMetric(metrics, 'sold_count'),
                )}
              </TableCell>
              <TableCell align="right">
                {formatPrice(sumTotalForMetric(metrics, 'sold_total_gross'))}
              </TableCell>
              <TableCell align="right">
                {generateAverage(
                  sumTotalForMetric(metrics, 'sold_total_gross'),
                  sumTotalForMetric(metrics, 'sold_count'),
                )}
              </TableCell>
              <TableCell align="right">
                {generatePercent(
                  sumTotalForMetric(metrics, 'writes'),
                  sumTotalForMetric(metrics, 'tubed_count'),
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const MoreMetricsBreakdown = ({ dealer, metrics, roleMetrics }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = value => {
    setOpen(false);
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={event => {
          event.stopPropagation();
          handleClickOpen();
        }}
        onFocus={event => event.stopPropagation()}
      >
        <InfoIcon style={{ marginRight: 10 }} /> More
      </Button>
      <Dialog
        open={open}
        onClose={event => {
          event.stopPropagation();
          handleClose();
        }}
        maxWidth={false}
      >
        <DialogTitle>
          <Typography
            className={classes.bold}
            variant="h5"
          >{`${dealer} Deal Log Metrics`}</Typography>
        </DialogTitle>
        <DialogContent>
          <Box style={{ margin: 10 }}>
            <BreakdownSummaryHeader metrics={metrics} stock_type={'new'} />
          </Box>
          <Box style={{ margin: 10 }}>
            <BreakdownSummaryHeader metrics={metrics} stock_type={'used'} />
          </Box>

          <MetricsTable
            metrics={roleMetrics.sales_reps_metrics}
            title={'Sales Reps'}
          />
          <MetricsTable
            metrics={roleMetrics.sales_managers_metrics}
            title={'Sales Managers'}
          />
          <MetricsTable
            metrics={roleMetrics.finance_managers_metrics}
            title={'Finance Producers'}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={event => {
              event.stopPropagation();
              handleClose();
            }}
            color="primary"
            variant="contained"
          >
            <Box>Close</Box>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MoreMetricsBreakdown;
