/* external */
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';

/* Material UI */
import {
  FormControlLabel,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CreditCard, FileCopy, MoreVert } from '@mui/icons-material';

/* internal */
import { refCode } from 'utils';

import UpdateCardStatus from './UpdateCardStatus';

const GENERATE_WELCOME_LETTER = gql`
  mutation generateWelcomeLetter($cardId: ID!) {
    gocard {
      generateWelcomeLetter(cardId: $cardId) {
        key
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  text: {
    fontSize: 15,
  },
}));

const GoCardOverview = ({
  gocard,
  openTransactionModal,
  openRedeemModal,
  openGiftModal,
  openReferralModal,
  openMergeModal,
  openDetachModal,
  openShareModal,
  openRevokeModal,
  openAmaModal,
  openRvModal,
  totalCards,
  isGoAutoEmployee,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState(null);
  const amaMember = gocard?.amaMembership || '';

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuItemHandler = event => {
    event.stopPropagation();
    handleClose();
  };

  const copyGoCardNumber = () => {
    navigator.clipboard.writeText(gocard.cardNumber);
    enqueueSnackbar('Copied Go Card Number to clipboard!', {
      variant: 'success',
    });
  };

  const [generateWelcomeLetter] = useMutation(GENERATE_WELCOME_LETTER, {
    variables: {
      cardId: gocard?.cardId,
    },
    onCompleted: data => {
      //open window to pdfUrl
      window.open(data.gocard.generateWelcomeLetter.key, '_blank');
    },
    onError: e => {
      enqueueSnackbar(`Error generating PDF`, {
        variant: 'error',
      });
    },
  });

  const handleWelcomeLetter = () => {
    generateWelcomeLetter();
  };

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
        style={{ marginLeft: '8px' }}
      >
        {/* BALANCE */}
        <Grid
          item
          sm={3}
          xs={12}
          style={{ fontWeight: 'bold', display: 'flex' }}
          className={classes.text}
        >
          <CreditCard style={{ marginRight: '5px' }} />$
          {gocard?.cashBalance.toFixed(2) || 0}
        </Grid>
        {/* CARD NUMBER */}
        <Grid container item xs={12} sm={4} spacing={1}>
          <Grid item style={{ fontWeight: 'bold' }} className={classes.text}>
            {`${gocard?.cardNumber}`}
          </Grid>
          <Grid
            item
            className={classes.text}
            style={{ color: '#999999', fontWeight: 'bold' }}
          >{` | ${refCode(gocard?.cardId)}`}</Grid>
        </Grid>
        {/* CARD STATUS */}
        <Grid item className={classes.text} xs={7} sm={3}>
          <b>Card Status: </b>
          {`${gocard?.active ? ' Active' : ' Inactive'}`}
        </Grid>
        {/* MENU */}
        <Grid item xs={5} sm={2}>
          <FormControlLabel
            onClick={event => event.stopPropagation()}
            onFocus={event => event.stopPropagation()}
            control={
              <Tooltip title="Copy">
                <IconButton onClick={() => copyGoCardNumber()} size="small">
                  <FileCopy style={{ fontSize: '20' }} />
                </IconButton>
              </Tooltip>
            }
          />
          <FormControlLabel
            onClick={event => event.stopPropagation()}
            onFocus={event => event.stopPropagation()}
            control={
              <IconButton size="small" onClick={handleClick}>
                <MoreVert />
              </IconButton>
            }
          />
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={event => menuItemHandler(event)}
          >
            <MenuItem
              onClick={event => {
                menuItemHandler(event);
                openTransactionModal();
              }}
              disabled={!gocard.active}
            >
              View Transactions
            </MenuItem>
            {gocard.permissions.redeemPoints && !isGoAutoEmployee && (
              <MenuItem
                onClick={event => {
                  menuItemHandler(event);
                  openRedeemModal();
                }}
                disabled={!gocard.active}
              >
                Redeem Points
              </MenuItem>
            )}
            {gocard.permissions.giftPoints && !isGoAutoEmployee && (
              <MenuItem
                onClick={event => {
                  menuItemHandler(event);
                  openGiftModal();
                }}
                disabled={!gocard.active}
              >
                Gift Points
              </MenuItem>
            )}
            {gocard.permissions.referPoints && !isGoAutoEmployee && (
              <MenuItem
                onClick={event => {
                  menuItemHandler(event);
                  openReferralModal();
                }}
                disabled={!gocard.active}
              >
                Award Referral Credits
              </MenuItem>
            )}
            {totalCards > 1 && gocard.permissions.mergeAccounts && (
              <MenuItem
                onClick={event => {
                  menuItemHandler(event);
                  openMergeModal();
                }}
                disabled={!gocard.active}
              >
                Merge Go Cards
              </MenuItem>
            )}
            {gocard.permissions.manageAccounts && (
              <MenuItem
                onClick={event => {
                  menuItemHandler(event);
                  openDetachModal();
                }}
                disabled={!gocard.active}
              >
                Detach Go Card
              </MenuItem>
            )}
            {gocard.permissions.manageAccounts && (
              <MenuItem
                onClick={event => {
                  menuItemHandler(event);
                  openShareModal();
                }}
                disabled={!gocard.active}
              >
                Share Go Card
              </MenuItem>
            )}
            {gocard.permissions.manageAccounts && (
              <MenuItem
                onClick={event => {
                  menuItemHandler(event);
                  openRevokeModal();
                }}
                disabled={!gocard.active}
              >
                Revoke Go Card
              </MenuItem>
            )}
            {gocard.permissions.manageAccounts && (
              <UpdateCardStatus
                active={gocard.active}
                cardId={gocard.cardId}
                handleClose={handleClose}
              />
            )}
            {gocard.permissions.manageAccounts && (
              <MenuItem
                onClick={event => {
                  menuItemHandler(event);
                  openAmaModal();
                }}
                disabled={!gocard.active}
              >
                AMA Membership Details
              </MenuItem>
            )}
            {gocard.permissions.manageAccounts && (
              <MenuItem
                onClick={event => {
                  menuItemHandler(event);
                  openRvModal();
                }}
                disabled={!gocard.active}
              >
                RV Membership Details
              </MenuItem>
            )}
            {/* PRINT WELCOME LETTER */}
            <MenuItem
              onClick={event => {
                menuItemHandler(event);
                handleWelcomeLetter();
              }}
              disabled={!gocard.active}
            >
              Print Welcome Letter
            </MenuItem>
          </Menu>
        </Grid>
        {/* MEMBERSHIPS */}
        <Grid item xs={6} sm={3}>
          <b>AMA Member:</b> {`${amaMember !== '' ? ' Yes' : ' No'}`}
        </Grid>
        <Grid item xs={6} sm={3}>
          <b>RV Member:</b> {`${gocard?.rvClubMembership ? ' Yes' : ' No'}`}
        </Grid>
      </Grid>
    </>
  );
};

GoCardOverview.fragments = {
  gocard: gql`
    fragment GoCardOverviewGoCard on GoCardAccountV2 {
      cardNumber
      active
      cardId
      rvClubMembership
      cashBalance
      amaMembership
    }
  `,
  gocardPermissions: gql`
    fragment GoCardOverviewGoCardPermissions on GoCardPermissions {
      redeemPoints
      giftPoints
      referPoints
      mergeAccounts
      manageAccounts
    }
  `,
};

export default GoCardOverview;
