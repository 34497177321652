/* external */
import gql from 'graphql-tag';
import React from 'react';

/* Material UI */
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material';

/* internal */
import {
  CheckboxControl,
  KeyboardDatePickerControl,
  LoadingBackdrop,
  RadioControl,
  SelectControl,
  TextFieldControl,
} from 'components/MaterialUI';
import { useSnackMutation } from 'utils/useSnackMutation';

const DELETE_DETAILED_PRICING = gql`
  mutation deleteDetailedPricing($id: Int!) {
    npv {
      deleteDetailedPricing(id: $id) {
        id
      }
    }
  }
`;

const DetailedPricing = ({ fields, remove }) => {
  const [deleteDetailedPricing, { loading: deleteDetailedPricingLoading }] =
    useSnackMutation(
      DELETE_DETAILED_PRICING,
      'Detailed pricing deleted!',
      e => `Unable to delete detailed pricing: ${e}`,
    );

  const handleRemoveClick = index => {
    const selectedDetailedPricing = fields[index];
    if (selectedDetailedPricing?.id) {
      deleteDetailedPricing({
        variables: { id: selectedDetailedPricing.id },
      }).then(remove(index));
    } else {
      remove(index);
    }
  };

  return (
    <Box margin={1}>
      <LoadingBackdrop open={deleteDetailedPricingLoading} />
      <Grid container spacing={2}>
        {fields.map((detailedPricing, index) => (
          <Grid
            container
            item
            xs={12}
            key={detailedPricing.key}
            spacing={3}
            alignItems="stretch"
          >
            <Grid container item xs={12} md={11} spacing={3}>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth>
                  <SelectControl
                    noNull
                    variant="outlined"
                    name={`detailedPricing.${index}.priceModifierTypeName`}
                    options={[
                      { name: 'Add-on', value: 'addon' },
                      { name: 'Discount', value: 'discount' },
                    ]}
                    label="Type"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={9}>
                <TextFieldControl
                  label="Name"
                  name={`detailedPricing.${index}.priceDescription`}
                  fullWidth
                  muiVariant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextFieldControl
                  label="Value"
                  name={`detailedPricing.${index}.value`}
                  variant="currency"
                  fullWidth
                  muiVariant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={7} md={3}>
                <KeyboardDatePickerControl
                  label="Expires"
                  margin="normal"
                  name={`detailedPricing.${index}.expiry`}
                  fullWidth
                  variant="inline"
                />
              </Grid>
              <Grid item xs={12} sm={5} md={3}>
                <CheckboxControl
                  label="No Expiry"
                  name={`detailedPricing.${index}.noExpiry`}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <RadioControl
                  label="Payment Type"
                  name={`detailedPricing.${index}.paymentType`}
                  as={
                    <RadioGroup row>
                      <FormControlLabel
                        value="BOTH"
                        control={<Radio color="secondary" />}
                        label="Both"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value="CASH"
                        control={<Radio color="secondary" />}
                        label="Cash"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value="FINANCE"
                        control={<Radio color="secondary" />}
                        label="Finance"
                        labelPlacement="end"
                      />
                    </RadioGroup>
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <RadioControl
                  label="Show on Website?"
                  name={`detailedPricing.${index}.showOnWebsite`}
                  as={
                    <RadioGroup row>
                      <FormControlLabel
                        value="true"
                        control={<Radio color="secondary" />}
                        label="Yes"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value="false"
                        control={<Radio color="secondary" />}
                        label="No"
                        labelPlacement="end"
                      />
                    </RadioGroup>
                  }
                />
              </Grid>
            </Grid>
            <Grid item xs={2} md={1} style={{ margin: 'auto' }}>
              <Button
                type="button"
                color="primary"
                variant="contained"
                onClick={() => handleRemoveClick(index)}
                style={{
                  backgroundColor: 'red',
                  minWidth: '36px',
                  padding: '10px',
                }}
              >
                <i className="fas fa-trash" />
              </Button>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

DetailedPricing.fragments = {
  vehicle: gql`
    fragment NpvVehicleDetailedPricing on NpvVehicle {
      id
      detailedPricing {
        id
        vehicleId
        priceDescription
        priceModifierTypeName
        value
        allowCash
        allowFinance
        showOnWebsite
        expiry
      }
    }
  `,
};

export default DetailedPricing;
