/* External */
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import React, { useState } from 'react';
import { Waypoint } from 'react-waypoint';
import { NetworkStatus } from '@apollo/client';

/* Material UI */
import {
  Box,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import ErrorDisplay from 'components/MaterialUI/ErrorDisplay';
import Loading from 'components/MaterialUI/Loading';

/* Internal */
import BulkSendTableRows from './BulkSendTableRows';
import NewBulkSendDialog from './NewBulkSendDialog';
import ActionsMenu from '../ActionsMenu';

// !TODO: Update gatewayUsers with the users resolver on the gateway once it is ready to go
const GATEWAY_QUERY = gql`
  query gatewayQuery($gatewayId: Int!, $gatewayUsersId: Int!) {
    sms {
      gateway(id: $gatewayId) {
        id
        name
        phone_number
      }
      gatewayUsers(id: $gatewayUsersId) {
        display_name
        username
      }
    }
  }
`;

const BULK_SEND_QUERY = gql`
  query bulkSendsQuery(
    $filters: [QueryFilter]
    $sort: [QuerySortElement]
    $pageSize: Int
    $page: Int
  ) {
    bulkSends {
      bulkSends(
        filters: $filters
        sort: $sort
        pageSize: $pageSize
        page: $page
      ) {
        pagination {
          total
          nextPage
          page
          totalPages
        }
        results {
          id
          statusLabel
          name
          createdBy
          dateCreated
          scheduledStart
          dateStarted
          scheduledCount
          replyCount
          skippedCount
          totalCount
          failedCount
          deliveredCount
          dateRevoked
          dateFinished
        }
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  phoneNumber: {
    fontWeight: 400,
    color: theme.colours.greys.greytext1,
    marginLeft: '10px',
  },
  h6: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
    marginBottom: '15px',
  },
  button: {
    backgroundColor: theme.actions.confirm.backgroundColor,
    color: 'white',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableHeight: {
    flexGrow: 2,
    marginBottom: '10px',
  },
}));

const BulkSends = ({ gatewayId }) => {
  const classes = useStyles();

  const [sort, setSort] = useState([
    { model: 'BulkSend', field: 'dateCreated', direction: 'desc' },
  ]);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('dateCreated');
  const handleRequestSort = (event, property) => {
    setSort({
      model: 'BulkSend',
      field: property,
      direction: order === 'asc' ? 'desc' : 'asc',
    });
    setOrder(order === 'asc' ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const [newBulkSend, setNewBulkSend] = useState(false);
  const [step, setStep] = useState(1);

  const { loading, data, error } = useQuery(GATEWAY_QUERY, {
    variables: {
      gatewayId,
      gatewayUsersId: gatewayId,
    },
  });

  const {
    loading: bulkSendsLoading,
    data: bulkSendsData,
    error: bulkSendsError,
    fetchMore,
    networkStatus,
    refetch,
  } = useQuery(BULK_SEND_QUERY, {
    variables: {
      filters: [
        {
          model: 'BulkSend',
          field: 'gatewayId',
          op: 'eq',
          value: gatewayId,
        },
      ],
      sort,
      pageSize: 20,
      page: 1,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  if (
    loading ||
    (bulkSendsLoading && networkStatus !== NetworkStatus.fetchMore)
  ) {
    return <Loading />;
  }

  if (error || bulkSendsError) {
    return <ErrorDisplay error={error} action="Loading Bulk Send Data" />;
  }

  const bulkSends = bulkSendsData?.bulkSends?.bulkSends.results ?? [];
  const { total, page, totalPages } =
    bulkSendsData?.bulkSends?.bulkSends?.pagination ?? {};

  const gatewayUsers = data.sms.gatewayUsers ?? [];

  const fetchMoreHandler = () => {
    if (page < totalPages) {
      fetchMore({
        variables: {
          page: page + 1,
        },
        updateQuery: (prev, { fetchMoreResult: more }) => {
          if (!more.bulkSends.bulkSends.results) return prev;
          const newData = {
            bulkSends: {
              bulkSends: {
                results: [
                  ...prev.bulkSends.bulkSends.results,
                  ...more.bulkSends.bulkSends.results,
                ],
                pagination: more.bulkSends.bulkSends.pagination,
                __typename: prev.bulkSends.bulkSends.__typename,
              },
              __typename: prev.bulkSends.__typename,
            },
          };
          return newData;
        },
      });
    }
  };

  const createSortHandler = property => event => {
    handleRequestSort(event, property);
  };

  const handleClose = () => {
    setNewBulkSend(false);
    setStep(1);
    refetch();
  };

  const handleCancel = () => {
    setNewBulkSend(false);
    setStep(1);
  };

  const headCells = [
    {
      id: 'statusLabel',
      numeric: false,
      disablePadding: false,
      sortable: false,
      label: 'Status',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      sortable: false,
      label: 'Name',
    },
    {
      id: 'createdBy',
      numeric: false,
      disablePadding: false,
      sortable: true,
      label: 'Created By',
    },
    {
      id: 'dateCreated',
      numeric: false,
      disablePadding: false,
      sortable: true,
      label: 'Created On',
    },
    {
      id: 'scheduledStart',
      numeric: false,
      disablePadding: false,
      sortable: true,
      label: 'Scheduled For',
    },
    {
      id: 'dateStarted',
      numeric: false,
      disablePadding: false,
      sortable: true,
      label: 'Started On',
    },
    {
      id: 'dateFinished',
      numeric: false,
      disablePadding: false,
      sortable: true,
      label: 'Finished On',
    },
    {
      id: 'scheduledCount',
      numeric: true,
      disablePadding: false,
      sortable: true,
      label: 'Scheduled',
    },
    {
      id: 'deliveredCount',
      numeric: true,
      disablePadding: false,
      sortable: true,
      label: 'Delivered',
    },
    {
      id: 'totalCount',
      numeric: true,
      disablePadding: false,
      sortable: true,
      label: 'Total',
    },
    {
      id: 'replyCount',
      numeric: true,
      disablePadding: false,
      sortable: true,
      label: 'Replies',
    },
  ];

  return (
    <Box
      margin="40px 20px 0 20px"
      height="95%"
      display="flex"
      flexDirection="column"
    >
      <Box
        display="flex"
        alignItems="flex-end"
        mb="30px"
        flexWrap="wrap"
        flexGrow={1}
      >
        <Typography variant="h5" style={{ fontWeight: 'bold' }}>
          Bulk Sends - {data?.sms.gateway.name}
        </Typography>
        <Typography variant="body1" className={classes.phoneNumber}>
          {data?.sms.gateway.phone_number}
        </Typography>
      </Box>
      <Box marginLeft="auto">
        <ActionsMenu gateway={data?.sms.gateway} version="detailed" />
      </Box>
      <Grid container spacing={2} style={{ flexGrow: 1 }}>
        <Grid item xs={12} sm={6}>
          <Button
            className={classes.button}
            onClick={() => setNewBulkSend(true)}
          >
            New Bulk Send
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography style={{ textAlign: 'right' }}>
            Showing {bulkSends.length} of {total || '0'}
          </Typography>
        </Grid>
      </Grid>
      <TableContainer className={classes.tableHeight}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {headCells.map(headCell => (
                <TableCell
                  key={headCell.id}
                  align={headCell.numeric ? 'right' : 'left'}
                  padding={headCell.disablePadding ? 'none' : 'normal'}
                  sortDirection={
                    orderBy === headCell.id
                      ? order > 0
                        ? 'asc'
                        : 'desc'
                      : false
                  }
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={
                      orderBy === headCell.id
                        ? order > 0
                          ? 'asc'
                          : 'desc'
                        : 'asc'
                    }
                    onClick={createSortHandler(headCell.id)}
                    disabled={!headCell.sortable}
                  >
                    {headCell.label}
                    {orderBy === headCell.id ? (
                      <span className={classes.visuallyHidden}>
                        {order === -1
                          ? 'sorted descending'
                          : 'sorted ascending'}
                      </span>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {bulkSends.length === 0 ? (
              <TableRow>
                <TableCell colSpan={11}>
                  There are no bulk sends to display
                </TableCell>
              </TableRow>
            ) : (
              <BulkSendTableRows
                bulkSends={bulkSends}
                gatewayUsers={gatewayUsers}
                gatewayId={gatewayId}
              />
            )}
            {(!loading || !bulkSendsLoading) && (
              <TableRow style={{ height: '1px' }}>
                <TableCell colSpan={11} style={{ borderBottom: 0 }}>
                  <Waypoint onEnter={fetchMoreHandler} />
                </TableCell>
              </TableRow>
            )}
            {networkStatus === NetworkStatus.fetchMore && (
              <TableRow>
                <TableCell colSpan={5}>
                  <Typography
                    variant="body1"
                    style={{
                      fontWeight: 'bold',
                      padding: '10px',
                    }}
                  >
                    Loading more bulk sends...
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <NewBulkSendDialog
        newBulkSend={newBulkSend}
        gatewayId={gatewayId}
        step={step}
        setStep={setStep}
        onClose={handleClose}
        handleCancel={handleCancel}
      />
    </Box>
  );
};

export default BulkSends;
