import React from 'react';
import { Alert } from '@mui/material';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import WarningIcon from '@mui/icons-material/Warning';
import { FRAUD_BULLETIN } from '../constant';

const BulletinAlert = ({ bulletins, after }) => {
  const hasFraudBulletin = bulletins.some(
    bulletin => bulletin.type === FRAUD_BULLETIN,
  );
  return (
    <Alert
      icon={
        hasFraudBulletin ? (
          <WarningIcon style={{ fontSize: 28 }} />
        ) : (
          <NewReleasesIcon style={{ fontSize: 28 }} />
        )
      }
      severity={hasFraudBulletin ? 'error' : 'warning'}
      style={{ color: 'black', fontWeight: 'bold', fontSize: '16px' }}
    >
      {hasFraudBulletin
        ? 'FRAUDULENT CUSTOMER BULLETIN POSTED FOR REVIEW'
        : 'CUSTOMER HAS BULLETIN(S) TO REVIEW'}
      {after}
    </Alert>
  );
};

export default BulletinAlert;
