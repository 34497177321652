import { useMutation, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { Box, Grid, useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import InputAdornment from '@mui/material/InputAdornment';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import HelpIcon from '@mui/icons-material/Help';
import { makeStyles } from '@mui/styles';

import { useCodeTablesContext } from 'components/MaterialUI/CodeTablesContext';
import Loading from 'components/MaterialUI/Loading';
import TextFieldControl from 'components/MaterialUI/TextFieldControl';
import { useUserContext } from 'components/MaterialUI/UserContext';

import { usePermissionsContext } from '../../contexts/PermissionsContext';
import DetailedPricing from './DetailedPricing';
import PurchaseOptions from './PurchaseOptions';
import PurchaseRates from './PurchaseRates';
import UsedDetailedPricing from './UsedDetailedPricing';
import { RoleGroup } from 'constants.js';
import _ from 'lodash';
import { RateTypes } from '../constants';

const RATES_QUERY = gql`
  query AllRatesQuery($id: Int!) {
    inventory {
      getAllVehicleRates(id: $id) {
        name
        program_id
        rates {
          bi_weekly_payment
          expiry
          months
          name
          rate
          show_on_website
          residual_amount
          program_id
          residual_percent
          km_allowance
        }
        type
      }
    }
  }
`;

const DELETE_RATES_AND_INCENTIVES = gql`
  mutation deleteIncentivesRatesVehicle($id: Int!) {
    inventory {
      deleteIncentivesRatesVehicle(id: $id) {
        id
        regular_price
        special_price
        detailed_pricing {
          id
          cash
          expiry
          finance
          lease
          price_description
          price_modifier_type_name
          show_on_website
          value
          program_id
        }
        finance_rates {
          id
          months
          name
          expiry
          show_on_website
          bi_weekly_payment
          rate
          program_id
        }
        lease_rates {
          id
          months
          name
          expiry
          show_on_website
          bi_weekly_payment
          rate
          residual_amount
          program_id
          residual_percent
          km_allowance
        }
        stock_type
        special_price_expires
      }
    }
  }
`;

const POPULATE_RATES_AND_INCENTIVES = gql`
  mutation updateAndPopulateIncentivesRatesVehicle(
    $id: Int!
    $data: GreaseInventoryVehicleInput!
  ) {
    inventory {
      updateAndPopulateIncentivesRatesVehicle(id: $id, data: $data) {
        id
        regular_price
        special_price
        detailed_pricing {
          id
          cash
          expiry
          finance
          lease
          price_description
          price_modifier_type_name
          show_on_website
          value
          program_id
        }
        finance_rates {
          id
          months
          name
          expiry
          show_on_website
          bi_weekly_payment
          rate
          program_id
        }
        lease_rates {
          id
          months
          name
          expiry
          show_on_website
          bi_weekly_payment
          rate
          residual_amount
          program_id
          residual_percent
          km_allowance
        }
        stock_type
        special_price_expires
      }
    }
  }
`;

const populateFinanceRates = finance_rates =>
  [60, 72, 84, 96].map(
    months =>
      finance_rates.find(x => x.months === months) ?? {
        months,
        rate: 0,
        show_on_website: false,
      },
  );

const useStyles = makeStyles(theme => ({
  pricingDetails: {
    display: 'flex',
    alignItems: 'flex-end',
    [theme.breakpoints.up('sm')]: {
      minWidth: '150px',
      maxWidth: '200px',
    },
  },

  pricingInputFields: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.75rem',
    },
  },
  button: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.75rem',
    },
  },
}));

const PricingDetails = ({
  vehicle: {
    msrp,
    year,
    regular_price,
    special_price,
    detailed_pricing,
    finance_rates,
    lease_rates,
    stock_type,
    special_price_expires,
    vin,
    id,
    style_id,
    default_down_payment,
  },
}) => {
  const { currentUser } = useUserContext();
  const [addNewLine, setAddNewLine] = useState();
  const [addNewRebate, setAddNewRebate] = useState();
  const [financeRates, setFinanceRates] = useState(
    stock_type === 'USED'
      ? populateFinanceRates(finance_rates)
      : finance_rates?.length > 0
      ? finance_rates[0].name === null
        ? populateFinanceRates(finance_rates)
        : finance_rates
      : finance_rates,
  );
  const [leaseRates, setLeaseRates] = useState(
    stock_type === 'USED' ? [] : lease_rates,
  );
  const [rates, setFinRates] = useState(financeRates || []);
  const [lease, setLease] = useState(leaseRates || []);
  const [nameSelect, setNameSelect] = useState(
    financeRates?.[0]?.program_id || '',
  );
  const [leaseNameSelect, setLeaseNameSelect] = useState(
    leaseRates?.[0]?.program_id || '',
  );
  const ratesQuery = useQuery(RATES_QUERY, { variables: { id } });
  const {
    formState: { errors },
    control,
    setValue,
    register,
    watch,
  } = useFormContext();

  const allRates = ratesQuery?.data?.inventory?.getAllVehicleRates || [];
  const staleRates = _.cloneDeep(allRates);
  const ratePrograms = allRates
    .filter(x => x.type === RateTypes.FINANCE)
    .map(x => [x.program_id, x.name]);
  const leaseRatePrograms = allRates
    .filter(x => x.type === RateTypes.LEASE)
    .map(x => [x.program_id, x.name]);

  register('rebates');
  register('detailed_pricing_no_rebates');

  const detailedPricing = watch('detailed_pricing', detailed_pricing);

  const rebates = detailedPricing.filter(
    x => x.price_modifier_type_name === 'rebate',
  );

  const detailedPricingNoRebates = detailedPricing.filter(
    x => x.price_modifier_type_name !== 'rebate',
  );

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'detailed_pricing_no_rebates',
    keyName: 'key',
  });

  const {
    fields: fieldsRebates,
    append: appendRebates,
    remove: removeRebates,
  } = useFieldArray({
    control,
    name: 'rebates',
    keyName: 'key2',
  });

  useEffect(() => {
    setValue('rebates', rebates);
    setValue('detailed_pricing_no_rebates', detailedPricingNoRebates);
  }, []);

  const classes = useStyles();

  let defaultNewFinanceRates =
    stock_type === 'NEW' &&
    (financeRates?.length > 0 ? financeRates[0].name === null : false);

  const setRates = vehicle => {
    setFinanceRates(
      stock_type === 'USED'
        ? populateFinanceRates(vehicle?.finance_rates)
        : vehicle?.finance_rates?.length > 0
        ? vehicle?.finance_rates[0].name === null
          ? populateFinanceRates(vehicle?.finance_rates)
          : vehicle?.finance_rates
        : vehicle?.finance_rates,
    );
    setValue(
      'finance_rates',
      stock_type === 'USED'
        ? populateFinanceRates(vehicle?.finance_rates)
        : vehicle?.finance_rates?.length > 0
        ? vehicle?.finance_rates[0].name === null
          ? populateFinanceRates(vehicle?.finance_rates)
          : vehicle?.finance_rates
        : vehicle?.finance_rates,
    );
    setLeaseRates(stock_type === 'USED' ? [] : vehicle.lease_rates);
    setValue('lease_rates', stock_type === 'USED' ? [] : vehicle.lease_rates);

    defaultNewFinanceRates =
      stock_type === 'NEW' &&
      (financeRates?.length > 0 ? financeRates[0].name === null : false);

    const output = vehicle.detailed_pricing.filter(obj => {
      return !fields.filter(obj_2 => {
        return obj_2['id'] === obj['id'];
      }).length;
    });

    if (output.length > 0) {
      setAddNewLine(true);
      for (let i = 0; i < output.length; i++) {
        append({
          cash: output[i].cash,
          expiry: moment(output[i].expiry).format('YYYY-MM-DD'),
          finance: output[i].finance,
          lease: output[i].lease,
          price_description: output[i].price_description,
          price_modifier_type_name: output[i].price_modifier_type_name,
          value: output[i].value,
          show_on_website: output[i].show_on_website,
          program_id: output[i].program_id,
        });
      }
    }
  };

  const [deleteIncentivesRatesMutation, { loading: deleteLoading }] =
    useMutation(DELETE_RATES_AND_INCENTIVES, {
      variables: {
        id,
      },
      onCompleted: res => {
        const vehicle = res?.inventory?.updateAndPopulateIncentivesRatesVehicle;
        if (vehicle) {
          setRates(vehicle);
          setNameSelect('');
          setLeaseNameSelect('');
        }
      },
    });

  const [updateAndPopulateIncentivesRatesVehicleMutation, { loading }] =
    useMutation(POPULATE_RATES_AND_INCENTIVES, {
      variables: {
        id,
        data: {
          vin,
          style_id,
        },
      },
      onCompleted: res => {
        const vehicle = res?.inventory?.updateAndPopulateIncentivesRatesVehicle;
        if (vehicle) {
          setRates(vehicle);
          // Finance Name Setter
          setNameSelect(vehicle?.finance_rates[0]?.program_id);
          getRate(vehicle?.finance_rates[0]?.program_id, RateTypes.FINANCE);
          // Lease name Setter
          setLeaseNameSelect(vehicle?.lease_rates[0]?.program_id);
          getRate(vehicle?.lease_rates[0]?.program_id, RateTypes.LEASE);
        }
      },
    });

  useEffect(() => {
    setFinanceRates(
      stock_type === 'USED'
        ? populateFinanceRates(finance_rates)
        : finance_rates?.length > 0
        ? finance_rates[0].name === null
          ? populateFinanceRates(finance_rates)
          : finance_rates
        : finance_rates,
    );
    setValue('finance_rates', financeRates, { shouldDirty: false });
    setLeaseRates(stock_type === 'USED' ? [] : lease_rates);
    setValue('lease_rates', stock_type === 'USED' ? [] : lease_rates);
    defaultNewFinanceRates =
      // eslint-disable-next-line react-hooks/exhaustive-deps
      stock_type === 'NEW' &&
      (financeRates?.length > 0 ? financeRates[0].name === null : false);
  }, [finance_rates, setValue, setFinanceRates, lease_rates, setLeaseRates]);

  useEffect(() => {
    setFinRates(financeRates);
  }, [financeRates, setFinRates]);

  useEffect(() => {
    setLease(leaseRates);
  }, [leaseRates, setLease]);

  register('finance_rates');
  register('lease_rates');

  const { loading: codeTablesLoading, error: codeTablesError } =
    useCodeTablesContext();

  const mobile = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const xsDevice = useMediaQuery('(max-width:575px)');
  const {
    read_cost: canReadCost,
    update_detailed_pricing: canUpdateDetailPrice,
    update,
  } = usePermissionsContext();

  if (codeTablesLoading) return <>Loading Code Tables...</>;
  if (codeTablesError) return <>Error{JSON.stringify(codeTablesError)}</>;

  const dollarStart = {
    startAdornment: <InputAdornment position="start">$</InputAdornment>,
  };

  const stockType = stock_type;

  const handlePopulateRates = () => {
    updateAndPopulateIncentivesRatesVehicleMutation();
  };

  const handleResetRates = () => {
    deleteIncentivesRatesMutation();
  };

  const getRate = (program_id, type) => {
    const program = allRates.filter(x => x.program_id === program_id);
    if (program.length === 1) {
      if (type === RateTypes.FINANCE) {
        setFinRates(program[0].rates);
        setFinanceRates(program[0].rates);
      } else {
        setLease(program[0].rates);
        setLeaseRates(program[0].rates);
      }
    } else {
      if (type === RateTypes.FINANCE) {
        setFinRates([]);
        setFinanceRates([]);
      } else {
        setLease([]);
        setLeaseRates([]);
      }
    }
  };

  return (
    <Paper>
      <Container style={{ margin: '0' }}>
        <Box
          style={
            !xsDevice
              ? {
                  display: 'flex',
                }
              : {
                  display: 'flex',
                  flexDirection: 'column',
                }
          }
        >
          <div>
            <Typography
              style={{
                padding: `${mobile ? '15px 0' : '15px 0'}`,
              }}
              variant="h6"
            >
              Pricing Details
            </Typography>

            <Grid
              container
              spacing={mobile ? 1 : 2}
              justifyContent={mobile || xsDevice ? 'flex-start' : 'center'}
            >
              <Grid item xs={6} sm={3} className={classes.pricingDetails}>
                {canReadCost ? (
                  <>
                    <TextFieldControl
                      control={control}
                      error={errors.cost}
                      InputProps={{
                        ...dollarStart,
                        classes: { input: classes.pricingInputFields },
                      }}
                      label="Cost"
                      name="cost"
                      defaultValue=""
                      disabled={!update}
                    />

                    {mobile && (
                      <span>
                        <Tooltip title={'CDK Field is Balance'}>
                          <HelpIcon
                            style={{ fontSize: '18px', marginRight: '25px' }}
                          />
                        </Tooltip>
                      </span>
                    )}
                  </>
                ) : (
                  <TextField value="XXXXX" label="Cost" disabled />
                )}
              </Grid>

              <Grid item xs={6} sm={3} className={classes.pricingDetails}>
                <TextFieldControl
                  control={control}
                  error={errors.msrp}
                  InputProps={{
                    ...dollarStart,
                    classes: { input: classes.pricingInputFields },
                  }}
                  label="MSRP"
                  name="msrp"
                  type="number"
                  defaultValue=""
                  disabled={!update}
                />
                {mobile && (
                  <span>
                    <Tooltip title={'CDK Field is BaseRetailPrice'}>
                      <HelpIcon
                        style={{ fontSize: '18px', marginRight: '25px' }}
                      />
                    </Tooltip>
                  </span>
                )}
              </Grid>
              <Grid item xs={6} sm={3} className={classes.pricingDetails}>
                <TextFieldControl
                  control={control}
                  error={errors.regular_price}
                  InputProps={{
                    ...dollarStart,
                    classes: { input: classes.pricingInputFields },
                  }}
                  label="Regular Price"
                  name="regular_price"
                  type="number"
                  defaultValue=""
                  disabled={!update}
                />
                {mobile && (
                  <span>
                    <Tooltip title={'CDK Field is Price1'}>
                      <HelpIcon
                        style={{ fontSize: '18px', marginRight: '25px' }}
                      />
                    </Tooltip>
                  </span>
                )}
              </Grid>

              <Grid item xs={6} sm={3} className={classes.pricingDetails}>
                <TextFieldControl
                  control={control}
                  disabled={true}
                  error={errors.special_price}
                  InputProps={{
                    ...dollarStart,
                    classes: { input: classes.pricingInputFields },
                  }}
                  label="Special Price"
                  name="special_price"
                  type="number"
                  defaultValue=""
                />
                {/* Make Special Price field the same width/alignment as the others */}
                {mobile && (
                  <span
                    style={{
                      width: '18px',
                      height: '18px',
                      marginRight: '25px',
                    }}
                  ></span>
                )}
              </Grid>
            </Grid>
          </div>
          <div>
            <div
              style={{
                backgroundColor: '#EAE9E9',
                padding: '15px',
                margin: `${xsDevice ? '10px 0 0 0' : '15px 10px'}`,
              }}
            >
              <PurchaseOptions
                regular_price={regular_price}
                special_price={special_price}
                detailed_pricing={detailed_pricing}
                leaseRates={leaseRates}
                financeRates={financeRates}
                stock_type={stock_type}
                default_down_payment={default_down_payment}
              />
            </div>
          </div>
        </Box>
      </Container>
      <Box
        style={{
          padding: '1rem',
          margin: '10px 0',
          display: 'flex',
          flexWrap: 'wrap',
        }}
      >
        <Button
          disabled={!canUpdateDetailPrice}
          variant="contained"
          color="secondary"
          style={{ marginTop: '15px' }}
          className={classes.button}
          onClick={() => handlePopulateRates()}
        >
          {loading ? <Loading /> : <>Populate Incentives And Rates</>}
        </Button>
        <Tooltip
          title={
            'Make sure your vehicle has been decoded with an accurate style for the most accurate rates to be returned.'
          }
        >
          <HelpIcon
            style={{
              fontSize: '18px',
              marginLeft: '5px',
              marginTop: 'auto',
              marginBottom: 'auto',
              marginRight: '15px',
            }}
          />
        </Tooltip>
        {RoleGroup.RESET_VEHICLE_RATES.includes(currentUser.role) && (
          <Button
            variant="contained"
            style={{ marginTop: '15px' }}
            className={classes.button}
            onClick={() => handleResetRates()}
            color="default"
          >
            {deleteLoading ? <Loading /> : <>Reset Rates and Incentives</>}
          </Button>
        )}
      </Box>

      <Container style={{ margin: 0, padding: 0 }}>
        {stockType === 'NEW' && (
          <DetailedPricing
            vehicle={detailed_pricing}
            special_price_expires={special_price_expires}
            special_price={special_price}
            append={append}
            remove={remove}
            fields={fields}
            addNewLine={addNewLine}
            setAddNewLine={setAddNewLine}
            addNewRebate={addNewRebate}
            setAddNewRebate={setAddNewRebate}
            appendRebates={appendRebates}
            removeRebates={removeRebates}
            fieldsRebates={fieldsRebates}
          />
        )}
        {stockType === 'USED' && (
          <UsedDetailedPricing
            append={append}
            fields={fields}
            remove={remove}
            vehicle={detailed_pricing}
            appendRebates={appendRebates}
            removeRebates={removeRebates}
            fieldsRebates={fieldsRebates}
          />
        )}
      </Container>
      <Container style={{ margin: 0 }}>
        <PurchaseRates
          stock_type={stock_type}
          financeRates={financeRates}
          setFinanceRates={setFinanceRates}
          leaseRates={leaseRates}
          setLeaseRates={setLeaseRates}
          defaultNewFinanceRates={defaultNewFinanceRates}
          id={id}
          staleRates={staleRates}
          rates={rates}
          setRates={setFinRates}
          lease={lease}
          setLease={setLease}
          nameSelect={nameSelect}
          setNameSelect={setNameSelect}
          leaseNameSelect={leaseNameSelect}
          setLeaseNameSelect={setLeaseNameSelect}
          ratePrograms={ratePrograms}
          leaseRatePrograms={leaseRatePrograms}
          allRates={allRates}
          getRate={getRate}
        />
      </Container>
    </Paper>
  );
};

PricingDetails.fragments = {
  vehicle: gql`
    fragment PricingDetailsVehicle on GreaseInventoryVehicle {
      cost
      detailed_pricing_base
      detailed_pricing {
        id
        cash
        expiry
        finance
        lease
        price_description
        price_modifier_type_name
        program_id
        show_on_website
        value
      }
      finance_rates {
        id
        months
        name
        expiry
        show_on_website
        bi_weekly_payment
        rate
        program_id
      }
      is_certified
      is_special_price_enabled
      lease_rates {
        id
        months
        name
        expiry
        show_on_website
        bi_weekly_payment
        rate
        residual_amount
        program_id
        residual_percent
        km_allowance
      }
      msrp
      regular_price
      special_price
      special_price_expires
      stock_type
    }
  `,
};

export default PricingDetails;
