import React, { useRef, useState } from 'react';
import { withStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import { cloneDeep } from 'lodash';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import InspectionCheckboxes from '../InspectionCheckboxes';

const VWCPOForm = props => {
  const { enqueueSnackbar } = useSnackbar();

  // VW certified pre owned inspection and condition report form fields
  const history = {
    v1: 'As indicated in ElsaPro, is there any existing/warranty keys detecting vehicle modifications?',
  };
  const inspection = {
    v1: 'Are there any open campaigns? (If yes, all campaigns must be completed and reported prior to Volkswagen Certification.)',
    v2: 'All books? (If no, replace as needed)',
    v3: 'All keys? (If no, replace as needed)',
    v4: 'Was the vehicle ever reported totalled?',
    v5: 'Is there any structural damage?',
    v6: 'Does the vehicle show signs of flood damage?',
    v7: 'Is the mileage displayed on the odometer different from the actual vehicle mileage?',
    v8: 'Is there a discrepancy in the VIN plate and other VINs on the vehicle and paperwork?',
  };
  const interior = {
    v1: ['Keys', 'Remote Open/Close'], //[title, secondary title]
    v2: ['Steering Wheel', 'Adjustments/Lock'],
    v3: ['Doors/Handles', 'Function, Feel'],
    v4: ['Warning Chimes', 'Function'],
    v5: ['Window Operation', 'Switches, Regulator'],
    v6: ['Console', 'Damage'],
    v7: ['Side and Back Windows', 'Chips, Cracks, Leaks'],
    v8: ['Windshield', 'Chips, Cracks, Leaks'],
    v9: ['Seats/Headrests', 'Function, Damage'],
    v10: ['Seat Heaters', 'Function'],
    v11: ['Seat Belts/Retractors', 'Function, Wear'],
    v12: ['Air Bags', 'Function, Damage'],
    v13: ['Mirrors (in and out)', 'Function, Damage'],
    v14: ['Clock', 'Set to Correct Time'],
    v15: ['Glovebox/Ashtray', 'Function, Light, Lock'],
    v16: ['Instrument Lights', 'Function'],
    v17: ['Sunroof', 'Function, Sealing'],
    v18: ['Lighter/12V Outlets', 'Function'],
    v19: ['Dome, Map, Visor Lights', 'Function'],
    v20: ['Door/Child Safety Locks', 'Function'],
    v21: ['Alarm System', 'Function'],
    v22: ['Upholstery', 'Wear, Damage'],
    v23: ['Carpet/Floor Mats/Trunk', 'Wear, Damage'],
    v24: ['Door Panels, Fuel/Trunk release', 'Damage'],
    v25: ['Headliner/Sun Visors', 'Damage'],
    v26: ['Ignition Switch', 'Wear, Damage'],
    v27: ['Ignition Switch', 'All I.P. Warning Lights'],
    v28: [
      'Check Readiness Code (If Readiness Code is not set, establish during Road Test)',
      '',
    ],
    v29: ['ATF Fluid', 'Level, Condition'],
  };
  const road = {
    v1: ['Engine', 'Starting Performance'],
    v2: ['Engine', 'Idle, Knock, Vibration'],
    v3: ['Engine', 'Valve Noise'],
    v4: ['Engine', 'Smoke'],
    v5: ['Automatic Transmission (Including Shift Lock)', 'Performance'],
    v6: ['Automatic Transmission (Including Shift Lock)', 'Slipapge, Noise'],
    v7: ['Automatic Transmission (Including Shift Lock)', 'Shift Points'],
    v8: ['Automatic Transmission (Including Shift Lock)', 'Down Shift'],
    v9: ['Manual Transmission', 'Performance'],
    v10: ['Manual Transmission', 'Shifting'],
    v11: ['Manual Transmission', 'Noise'],
    v12: ['Differential', 'Noise'],
    v13: ['Clutch', 'Operation, Slippage'],
    v14: ['Clutch', 'Performance'],
    v15: ['Steering', 'Noise, Effort'],
    v16: ['Steering', 'Vibration, Pulling'],
    v17: ['Steering Wheel Aligned', ''],
    v18: ['Suspension', 'Function, Feel, Noise'],
    v19: ['Brakes (and ABS)', 'Function, Feel, Noise'],
    v20: ['Parking Brake', 'Function'],
    v21: ['A/C System / Defroster', 'Operation, Noise'],
    v22: ['Heater', 'Operation, Noise'],
    v23: ['Speedometer', 'Function, Noise'],
    v24: ['Horn(s)', 'Function'],
    v25: ['Radio Antenna', 'Function/Pre-Sets'],
    v26: ['CD/Cassette/Navigation', 'Function'],
    v27: ['Speakers', 'Noise/Vibration'],
    v28: ['Wipers/Washers (F and R)', 'Function/Jets Aim'],
    v29: ['Wiper Blades/Arms', 'Function/Performance'],
    v30: ['Crusie Control', 'Function'],
  };
  const after = {
    v1: ['Cooling System', 'Locks, Hoses, Radiator/Pumps'],
    v2: ['Coolant', 'Level'],
    v3: ['Coolant', 'Antifreeze Content'],
    v4: ['Coolant', 'Protected to Degrees'],
    v5: ['Battery/Charing System', 'Function, Condition'],
    v6: ['Battery Cables/Clamps', 'Condition'],
    v7: ['Ignition Wires', 'Condition'],
    v8: ['Electrical System', 'Wire Routing, Connections'],
    v9: ['V-belts/Air Filter', 'Loose, Condition'],
    v10: ['A/C Compressor, Clutch', 'Function, Noise'],
    v11: ['Cooling Fan', 'Function, Noise'],
    v12: ['Washer Reservoir', 'Fluid Level, Leaks'],
    v13: ['Engine Oil', 'Level, Condition'],
    v14: ['Power Steering', 'Fluid Level'],
    v15: ['Brake Reservoir, Master Cylinder', 'Fluid Level'],
    v16: ['Fuel System', 'Leaks, Fittings, Filter'],
  };
  const exterior = {
    v1: ['Turn Signal/Marker', 'Damage, Operation'],
    v2: ['Emergency Flashers', 'Operation'],
    v3: ['Antenna Mast', 'Condition'],
    v4: ['Headlights', 'Lens Broken'],
    v5: ['Headlights', 'High/Low Beam'],
    v6: ['Fog Lights', 'Lens Broken, Operation'],
    v7: ['Tail/Brake/Reverse/License Plate/Lights', 'Lens Broken, Operation'],
    v8: ['High Mount Brake light', 'Operation'],
    v9: ['Trun/Rear Hatch Trim/Roof Rack', 'Function, Feel, Lights'],
    v10: ['Spare Tire, Jack, Tool Kit', 'Damage, Tire Pressure'],
    v11: ['Cabrio, Top', 'Condition, Function'],
  };
  const under = {
    v1: ['Wheels', 'Damage, Matched'],
    v2: ['Tires', 'Damage, Pressure'],
    v3: ['Wheel Covers', 'Damage, Missing'],
    v4: ['Transmission', 'Oil Leaks'],
    v5: ['Transmission', 'Worn Mounts'],
    v6: ['C/V Joint Boots', 'Leaks, Worn'],
    v7: ['Axles, Suspension', 'Damage'],
    v8: ['Control Arms, Bushings, etc.', 'Damage'],
    v9: ['Engine', 'Leaks, Mounts'],
    v10: ['Shocks/Struts/Springs', 'Leaks, Damage, Weak'],
    v11: ['Brake System', 'Damaged, Leaking'],
    v12: ['Brake Pad Lining', 'Minimum 50% Remaining'],
    v13: ['Brake Rotors/Calipers', 'Leaks, Specs'],
    v14: ['Exhaust', 'Leaks, Alignment'],
    v15: ['Exhaust', 'Damage, Wear'],
    v16: ['Steering Rack, Pump', 'Leaks, Noise'],
    v17: ['Hoses', 'Damaged, Leaks'],
    v18: ['Hydraulic Pump', 'Leaks, Noise'],
    v19: ['Underbody', 'Other Damage, Leaks'],
  };
  const under2 = {
    v1: 'Hybrid/Electric Vehicle Battery Operational/No damage No Error Codes',
    v2: 'Hybrid/Electric Vehicle Battery Management Controller: Operational/No damage No Error Codes',
    v3: 'Hybrid/Electric Vehicle Battery Cooling System: Operation/No damage No Error Codes',
    v4: 'Hybrid/Electric Vehicle Battery Capacity At least 70% of Original Capacity',
    v5: 'Electric Motor/Generators: Operational/No damage No Error Codes',
    v6: 'Hybrid/Electric Vehicle Information Display Operational',
    v7: 'Regenerative Braking: All Modes Operational/No Error Codes',
    v8: 'Low-Speed Pedestrian Sound System (if Applicable): Operational',
    v9: 'Vehicle Charging Port (If Applicable): Charging Cable Connects Properly/No Damage',
    v10: 'Portable 110V-120V Charging Cable (If Applicable): Present/Operational/No Damage',
  };

  let historyObj = cloneDeep(history);
  let inspectionObj = cloneDeep(inspection);
  let interiorObj = cloneDeep(interior);
  let roadObj = cloneDeep(road);
  let afterObj = cloneDeep(after);
  let exteriorObj = cloneDeep(exterior);
  let underObj = cloneDeep(under);
  let under2Obj = cloneDeep(under2);

  Object.entries(historyObj).map(([name]) => (historyObj[name] = 'Yes'));
  Object.entries(inspectionObj).map(([name]) => (inspectionObj[name] = 'Yes'));
  Object.entries(interiorObj).map(([name]) => (interiorObj[name] = 'OK'));
  Object.entries(roadObj).map(([name]) => (roadObj[name] = 'OK'));
  Object.entries(afterObj).map(([name]) => (afterObj[name] = 'OK'));
  Object.entries(exteriorObj).map(([name]) => (exteriorObj[name] = 'OK'));
  Object.entries(underObj).map(([name]) => (underObj[name] = 'OK'));
  Object.entries(under2Obj).map(([name]) => (under2Obj[name] = 'OK'));

  const [historyValues, setHistoryValues] = useState(historyObj);
  const [inspectionValues, setInspectionValues] = useState(inspectionObj);
  const [interiorValues, setInteriorValues] = useState(interiorObj);
  const [roadValues, setRoadValues] = useState(roadObj);
  const [afterValues, setAfterValues] = useState(afterObj);
  const [exteriorValues, setExteriorValues] = useState(exteriorObj);
  const [underValues, setUnderValues] = useState(underObj);
  const [under2Values, setUnder2Values] = useState(under2Obj);

  const handleHistory = e => {
    const { name, value } = e.target;
    setHistoryValues(prevHistoryValues => ({
      ...prevHistoryValues,
      [name]: value,
    }));
  };
  const handleInspection = e => {
    const { name, value } = e.target;
    setInspectionValues(prevInspectionValues => ({
      ...prevInspectionValues,
      [name]: value,
    }));
  };
  const handleInterior = e => {
    const { name, value } = e.target;
    setInteriorValues(prevInteriorValues => ({
      ...prevInteriorValues,
      [name]: value,
    }));
  };
  const handleRoad = e => {
    const { name, value } = e.target;
    setRoadValues(prevRoadValues => ({
      ...prevRoadValues,
      [name]: value,
    }));
  };

  const handleAfter = e => {
    const { name, value } = e.target;
    setAfterValues(prevAfterValues => ({
      ...prevAfterValues,
      [name]: value,
    }));
  };
  const handleExterior = e => {
    const { name, value } = e.target;
    setExteriorValues(prevExteriorValues => ({
      ...prevExteriorValues,
      [name]: value,
    }));
  };
  const handleUnder = e => {
    const { name, value } = e.target;
    setUnderValues(prevUnderValues => ({
      ...prevUnderValues,
      [name]: value,
    }));
  };
  const handleUnder2 = e => {
    const { name, value } = e.target;
    setUnder2Values(prevUnder2Values => ({
      ...prevUnder2Values,
      [name]: value,
    }));
  };

  const [consent, setConsent] = useState(false);
  const handleConsent = () => {
    setConsent(consent => !consent);
  };

  const [confirm, setConfirm] = useState(false);
  const handleConfirm = () => {
    setConfirm(confirm => !confirm);
  };

  const wholeForm = useRef(null);

  const handleSubmit = e => {
    e.preventDefault();

    if (!consent) {
      enqueueSnackbar(`Please check 'I agree' and then submit the inspection!`);
      return;
    }

    underValues['lf'] = wholeForm.current['lf'].value;
    underValues['rf'] = wholeForm.current['rf'].value;
    underValues['lr'] = wholeForm.current['lr'].value;
    underValues['rr'] = wholeForm.current['rr'].value;

    const form_data = {
      history: historyValues,
      inspection: inspectionValues,
      interior: interiorValues,
      road: roadValues,
      after: afterValues,
      exterior: exteriorValues,
      under: underValues,
      under2: under2Values,
      amvic_number: wholeForm.current['amvic_number'].value,
    };

    props.formData(form_data);
    props.formClose();
  };

  const GreenButton = withStyles({
    root: {
      color: '#fff',
      backgroundColor: '#198754',
      '&:hover': {
        backgroundColor: '#157347',
      },
    },
  })(Button);

  return (
    <form onSubmit={handleSubmit} ref={wholeForm}>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Vehicle history</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(history).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={historyValues}
                      handleGroup={handleHistory}
                      name={name}
                      orange={false}
                      greenValue="Yes"
                      redValue="No"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Check Warranty/CARFAX/Visual Inspection</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(inspection).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={inspectionValues}
                      handleGroup={handleInspection}
                      name={name}
                      orange={false}
                      greenValue="Yes"
                      redValue="No"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Interior</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            {Object.entries(interior).map(([name, display_name]) => {
              return (
                <Grid item xs={12} key={name}>
                  <Grid container alignItems="center">
                    <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                      <Typography
                        style={{ fontSize: '0.8rem', lineHeight: '1.43' }}
                      >
                        {display_name[0]}
                        <br />
                        <span style={{ fontSize: '.8rem', color: 'grey' }}>
                          {display_name[1]}
                        </span>
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <InspectionCheckboxes
                        valueGroup={interiorValues}
                        handleGroup={handleInterior}
                        name={name}
                        orange={false}
                        greenValue="OK"
                        redValue="Problem"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Road Test (15 – 20 minutes—must reach operating temperature)</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            {Object.entries(road).map(([name, display_name]) => {
              return (
                <Grid item xs={12} key={name}>
                  <Grid container alignItems="center">
                    <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                      <Typography
                        style={{ fontSize: '0.8rem', lineHeight: '1.43' }}
                      >
                        {display_name[0]}
                        <br />
                        <span style={{ fontSize: '.8rem', color: 'grey' }}>
                          {display_name[1]}
                        </span>
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <InspectionCheckboxes
                        valueGroup={roadValues}
                        handleGroup={handleRoad}
                        name={name}
                        orange={false}
                        greenValue="OK"
                        redValue="Problem"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>After Road Test (Engine Off)</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            {Object.entries(after).map(([name, display_name]) => {
              return (
                <Grid item xs={12} key={name}>
                  <Grid container alignItems="center">
                    <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                      <Typography
                        style={{ fontSize: '0.8rem', lineHeight: '1.43' }}
                      >
                        {display_name[0]}
                        <br />
                        <span style={{ fontSize: '.8rem', color: 'grey' }}>
                          {display_name[1]}
                        </span>
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <InspectionCheckboxes
                        valueGroup={afterValues}
                        handleGroup={handleAfter}
                        name={name}
                        orange={false}
                        greenValue="OK"
                        redValue="Problem"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Exterior</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            {Object.entries(exterior).map(([name, display_name]) => {
              return (
                <Grid item xs={12} key={name}>
                  <Grid container alignItems="center">
                    <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                      <Typography
                        style={{ fontSize: '0.8rem', lineHeight: '1.43' }}
                      >
                        {display_name[0]}
                        <br />
                        <span style={{ fontSize: '.8rem', color: 'grey' }}>
                          {display_name[1]}
                        </span>
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <InspectionCheckboxes
                        valueGroup={exteriorValues}
                        handleGroup={handleExterior}
                        name={name}
                        orange={false}
                        greenValue="OK"
                        redValue="Problem"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Under Body (Vehicle On Left)</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(under).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={underValues}
                      handleGroup={handleUnder}
                      name={name}
                      orange={false}
                      greenValue="OK"
                      redValue="Problem"
                    />
                  </Grid>
                  {name === 'v2' && (
                    <Grid
                      container
                      alignItems="center"
                      style={{ marginBottom: '1rem' }}
                    >
                      <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                        <TextField label="LF" name={'lf'} />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField label="RF" name={'rf'} />
                      </Grid>
                      <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                        <TextField label="LR" name={'lr'} />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField label="RR" name={'rr'} />
                      </Grid>
                    </Grid>
                  )}
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Under Body (Vehicle On Left)</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(under2).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={under2Values}
                      handleGroup={handleUnder2}
                      name={name}
                      orange={false}
                      greenValue="OK"
                      redValue="Problem"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Typography style={{ marginTop: '15px' }}>
        I verify the contents of this document are true and correct.
      </Typography>
      <FormControlLabel
        control={<Checkbox />}
        label="I agree"
        onChange={handleConsent}
        value={consent}
      />
      <br />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Technician Name"
            value={props.user.display_name}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Tech #"
            value={props.user.goUserProfile.profile.sales_licence}
            fullWidth
            name={'amvic_number'}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Date"
            value={new Date().toLocaleString()}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <Button
            type="button"
            variant="contained"
            style={{ marginRight: '15px' }}
            onClick={handleConfirm}
            color="default"
          >
            Cancel
          </Button>
          <Dialog
            open={confirm}
            onClose={handleConfirm}
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Current inspection will be closed and unsaved data will be gone.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleConfirm}>Cancel</Button>
              <Button onClick={props.formClose} color="secondary">
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
          <GreenButton type="submit" variant="contained">
            Save
          </GreenButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default VWCPOForm;
