import React from 'react';

/* external */
import gql from 'graphql-tag';

/* Material UI */
import TableCell from '@mui/material/TableCell';

const StatusCell = ({ vehicle, ...rest }) => {
  const { preselected_aged_inventory_vehicle, open_auction_items } = vehicle;

  const isPreselected = Boolean(preselected_aged_inventory_vehicle);
  const isActive = open_auction_items.some(x => x.isActive);
  const isUpcoming = open_auction_items.some(x => x.isUpcoming);

  return (
    <TableCell {...rest}>
      {isPreselected
        ? 'Preselected'
        : isActive
        ? 'At Auction'
        : isUpcoming
        ? 'Upcoming'
        : ''}
    </TableCell>
  );
};

StatusCell.fragments = {
  vehicle: gql`
    fragment StatusCellVehicle on GreaseInventoryVehicle {
      preselected_aged_inventory_vehicle {
        id
      }
      open_auction_items {
        id
        isActive
        isUpcoming
      }
    }
  `,
};
export default StatusCell;
