import React from 'react';
import gql from 'graphql-tag';

/* material ui */
import { useMediaQuery } from '@mui/material';

import { GridWithLabel, GridItem, UintItem, FtInItem } from './GridWrappers';

export default function SizesWeightsGrid() {
  const mobile = useMediaQuery(theme => theme.breakpoints.down('md'));

  return (
    <>
      {mobile ? (
        <GridWithLabel label="Sizes & Weights">
          <FtInItem
            label="Length"
            nameFt="specLengthFeet"
            nameIn="specLengthInches"
          />
          <FtInItem
            label="Ext. Width"
            nameFt="specExtWidthFeet"
            nameIn="specExtWidthInches"
          />
          <FtInItem
            label="Ext. Height"
            nameFt="specExtHeightFeet"
            nameIn="specExtHeightInches"
          />
          <FtInItem
            label="Int. Height"
            nameFt="specIntHeightFeet"
            nameIn="specIntHeightInches"
          />
          <UintItem label="Axle Width" name="specAxlePounds" />
          <UintItem label="Axle Count" name="specAxleCount" />
          <UintItem label="GVWR" name="specGvwrPounds" />
          <UintItem label="Dry Weight" name="specDryWeightPounds" />
          <GridItem label="Tire Size" name="specTireSize" />
          <UintItem label="Cargo Capacity" name="specCargoPounds" />
          <UintItem label="Hitch Weight" name="specHitchPounds" />
        </GridWithLabel>
      ) : (
        <GridWithLabel label="Sizes & Weights">
          <FtInItem
            label="Length"
            nameFt="specLengthFeet"
            nameIn="specLengthInches"
          />
          <UintItem label="Axle Width" name="specAxlePounds" />
          <FtInItem
            label="Ext. Width"
            nameFt="specExtWidthFeet"
            nameIn="specExtWidthInches"
          />
          <UintItem label="Axle Count" name="specAxleCount" />
          <FtInItem
            label="Ext. Height"
            nameFt="specExtHeightFeet"
            nameIn="specExtHeightInches"
          />
          <UintItem label="GVWR" name="specGvwrPounds" />
          <FtInItem
            label="Int. Height"
            nameFt="specIntHeightFeet"
            nameIn="specIntHeightInches"
          />
          <UintItem label="Dry Weight" name="specDryWeightPounds" />
          <GridItem label="Tire Size" name="specTireSize" />
          <UintItem label="Cargo Capacity" name="specCargoPounds" />
          <UintItem label="Hitch Weight" name="specHitchPounds" />
        </GridWithLabel>
      )}
    </>
  );
}

SizesWeightsGrid.fragments = {
  vehicle: gql`
    fragment NpvVehicleSpecsSizesWeights on NpvVehicle {
      id
      specLengthInches
      specLengthFeet
      specExtWidthInches
      specExtWidthFeet
      specExtHeightInches
      specExtHeightFeet
      specIntHeightInches
      specIntHeightFeet
      specTireSize
      specHitchPounds
      specAxlePounds
      specAxleCount
      specGvwrPounds
      specDryWeightPounds
      specCargoPounds
    }
  `,
};
