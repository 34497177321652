import React from 'react';

/* external */
import gql from 'graphql-tag';

/* Material UI */
import TableCell from '@mui/material/TableCell';

/* internal */
import { Countdown } from 'components/MaterialUI/TimeSyncContext';
import FormatDateTZ from 'components/MaterialUI/FormatDateTZ';

const ItemEndTimeCell = ({ item, countdown, format = 'MMM D Y @ h:mm A' }) => {
  const { endsAt } = item;
  return (
    <TableCell>
      <FormatDateTZ date={endsAt} format={format} />
      {countdown && (
        <>
          <br />
          <Countdown
            component="span"
            elapsedDisplay="Ended"
            endTime={endsAt}
            runningPrefix="Ends in"
          />
        </>
      )}
    </TableCell>
  );
};

ItemEndTimeCell.fragments = {
  auctionItem: gql`
    fragment ItemEndTimeCellAuctionItem on AuctionItem {
      endsAt
    }
  `,
};

export default ItemEndTimeCell;
