import React from 'react';

import { isEmpty } from 'lodash';
import gql from 'graphql-tag';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/material';

const useStyles = makeStyles({
  box: {
    fontSize: ({ small }) => (small ? '10px' : '16px'),
  },
});

/* TODO: there was an issue where we weren't setting the first/last name on the appraisal.
   So, for now, we must just use the customer fullname, which isn't ideal, since it can change
   due to crm customer updates/merges.

   If we backfill the firstName/lastName on appraisals, we should update this display to use
   the names from the appraisal, and display the customer name in parentheses after it if it 
   is different.

   Updated this 2022-10-27 (while still in dev) to also check the opportunity for customer_name

   */

const CustomerDisplay = ({
  appraisal,
  clickable = false,
  withAddress = false,
  small = false,
}) => {
  const classes = useStyles({ small });
  const theme = useTheme();
  const { customer, opportunity, firstName, lastName } = appraisal;
  const {
    fullname,
    _id,
    identities = [],
    emails = [],
    cell_phone,
    home_phone,
    primary_phone,
    primary_email,
    work_phone,
    address,
    city,
    province,
    postal_code,
  } = customer ?? {};

  const { customer_name } = opportunity ?? {};

  const phoneNumber =
    identities.find(x => x.identity_type === 'sms')?.value ??
    primary_phone ??
    cell_phone ??
    home_phone ??
    work_phone;

  const email =
    identities.find(x => x.identity_type === 'email')?.value ??
    primary_email ??
    emails?.[0]?.email;

  const displayName =
    !isEmpty(firstName) || !isEmpty(lastName)
      ? `${firstName} ${lastName}`
      : !isEmpty(fullname)
      ? fullname
      : !isEmpty(customer_name)
      ? customer_name
      : null;

  return (
    <Box display="flex" flexDirection="column" rowGap={theme.spacing(1)}>
      <Box fontWeight="bold" fontSize="18px">
        {_id && clickable ? (
          <Link
            href={`/customers/${_id}/details`}
            onClick={e => e.stopPropagation()}
          >
            {fullname}
          </Link>
        ) : (
          displayName ?? 'Unknown Customer'
        )}
      </Box>
      {withAddress && (
        <>
          <Box className={classes.box}>{address}</Box>
          <Box className={classes.box}>
            {city}, {province} {postal_code}
          </Box>
        </>
      )}
      <Box className={classes.box}>Phone: {phoneNumber}</Box>
      <Box className={classes.box}>Email: {email}</Box>
    </Box>
  );
};

CustomerDisplay.fragments = {
  appraisal: gql`
    fragment CustomerDisplayAppraisal on Appraisal {
      firstName
      lastName
      customer {
        _id
        fullname
        identities {
          identity_type
          identity_value
        }
        emails {
          email
        }
        cell_phone
        home_phone
        primary_phone
        primary_email
        work_phone
        address
        city
        province
        postal_code
      }
      opportunity {
        _id
        customer_name
      }
    }
  `,
};
export default CustomerDisplay;
