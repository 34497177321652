import React from 'react';

/* external */
import moment from 'moment-timezone';

/* Material UI */
import { makeStyles } from '@mui/styles';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Popover from '@mui/material/Popover';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

/* internal */
import { useUserContext } from 'components/MaterialUI/UserContext';

const useStyles = makeStyles(theme => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

const QuickHistoryPopover = ({
  id,
  anchorEl,
  onClose,
  history = [],
  loading,
}) => {
  const { me } = useUserContext();
  const tz =
    me?.goUserProfile?.settings?.timezone ||
    Intl.DateTimeFormat().resolvedOptions().timeZone;
  const classes = useStyles();

  let tableValues = [];
  let sourceTableValues = Array.from(history);
  sourceTableValues.reverse();

  // Now we need to go through the table and insert new entries if special_price_expires
  // happens between entries

  sourceTableValues.forEach((cur, index) => {
    const next = sourceTableValues[index + 1];

    let temp;
    if (index > 0) {
      const prev = sourceTableValues[index - 1];

      temp = {
        ...sourceTableValues[index],
        leads_since: cur.leads_count - prev.leads_count,
        opps_since: cur.opportunities_count - prev.opportunities_count,
      };
    } else {
      temp = {
        ...sourceTableValues[index],
        leads_since: 0,
        opps_since: 0,
      };
    }
    tableValues.push(temp);
    // if the next value in sourceTable is after the current special price expires
    // date, then we need to add in a new interpolated list price entry here

    // if it's the last value in the sourceTable, then we can add a "future" list price
    // change
    if (
      cur.is_special_price_enabled &&
      cur.special_price_expires &&
      (!next || cur.special_price_expires.localeCompare(next.date_modified) < 0)
    )
      tableValues.push({
        username: 'expiry',
        list_price: cur.regular_price,
        regular_price: cur.regular_price,
        date_modified: cur.special_price_expires,
        future: index === sourceTableValues.length - 1,
      });
  });

  tableValues = tableValues
    .filter(
      (cur, index, arr) =>
        // list price didn't change?  Toss it!
        cur.list_price !== arr[index - 1]?.list_price,
    )
    .slice(-5); // Latest 5 entries

  tableValues = tableValues.map((value, index, arr) => ({
    // calculate difference
    ...value,
    difference: index > 0 ? value.list_price - arr[index - 1].list_price : null,
  }));

  tableValues = tableValues.map(x => ({
    ...x,
    description: x.difference
      ? `From $${x.list_price - x.difference} to $${x.list_price}`
      : `$${x.list_price}`,
  }));

  tableValues.reverse();

  const open = Boolean(anchorEl);
  return (
    <>
      <Popover
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        onClose={onClose}
        disableRestoreFocus
        disableAutoFocus
        disableEnforceFocus
      >
        {tableValues.length === 0 && loading && (
          <div>Loading Pricing Changes...</div>
        )}
        {tableValues.length === 0 && !loading && (
          <span style={{ padding: '1rem' }}>No Pricing Changes to Display</span>
        )}
        {tableValues && (
          <span>
            <Table size="small">
              <colgroup>
                <col style={{ width: '100px' }} />
                <col style={{ width: '200px' }} />
              </colgroup>
              <TableBody>
                {tableValues.map((row, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>
                        {row.future ? 'FUTURE: ' : ''}
                        {moment(row.date_modified).tz(tz).format('LL')}
                      </TableCell>
                      <TableCell>
                        {row.list_price < row.regular_price && (
                          <i className={`fas fa-tag`} />
                        )}
                        {row.difference > 0 && (
                          <ArrowUpwardIcon
                            style={{ fontSize: '16px', color: '#74B72E' }}
                          />
                        )}
                        {row.difference < 0 && (
                          <ArrowDownwardIcon
                            style={{ fontSize: '16px', color: 'red' }}
                          />
                        )}
                        {row.description}
                        <Typography
                          style={{
                            whiteSpace: 'pre-line',
                            fontSize: '12px',
                            color: '#999999',
                          }}
                        >
                          {row.modified_by?.display_name || row.username}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          style={{
                            fontSize: '12px',
                            color: '#999999',
                          }}
                        >
                          Leads Since: {row.leads_since} <br />
                          Opps Since: {row.opps_since}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </span>
        )}
      </Popover>
    </>
  );
};

export default QuickHistoryPopover;
