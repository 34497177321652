import React from 'react';

import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import PersonIcon from '@mui/icons-material/Person';
import RefreshIcon from '@mui/icons-material/Refresh';
import gql from 'graphql-tag';

const TasksAction = ({
  task,
  handleUpdateStatus,
  handleReschedule,
  handleReassign,
}) => {
  const theme = useTheme();

  return (
    <Grid
      container
      direction="row"
      justifyContent={'flex-end'}
      alignItems="center"
      spacing={1}
    >
      {task.status === 1 ? (
        <>
          <Grid item>
            <Typography style={{ color: '#82AF6F', fontWeight: 'bold' }}>
              <CheckIcon /> Completed!
            </Typography>
          </Grid>
        </>
      ) : task.status === 2 ? (
        <>
          <Grid item>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
              }}
            >
              <Typography style={{ fontWeight: 'bold', paddingRight: '4px' }}>
                Cancelled
              </Typography>
              <Tooltip title="Reschedule task">
                <IconButton
                  size="small"
                  onClick={() => handleReschedule(task._id)}
                >
                  <RefreshIcon style={theme.actions.add} />
                </IconButton>
              </Tooltip>
            </div>
          </Grid>
        </>
      ) : (
        <>
          <Grid item>
            <Tooltip title="Complete task">
              <IconButton
                size="small"
                onClick={() => handleUpdateStatus(task._id, 1)}
              >
                <CheckIcon style={theme.actions.add} />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title="Reschedule task">
              <IconButton
                size="small"
                onClick={() => handleReschedule(task._id)}
              >
                <RefreshIcon style={theme.actions.add} />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title="Reassign task">
              <IconButton size="small" onClick={() => handleReassign(task._id)}>
                <PersonIcon style={theme.actions.add} />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title="Cancel task">
              <IconButton
                size="small"
                onClick={() => handleUpdateStatus(task._id, 2)}
              >
                <CloseIcon style={theme.actions.add} />
              </IconButton>
            </Tooltip>
          </Grid>
        </>
      )}
    </Grid>
  );
};

TasksAction.fragments = {
  customer: gql`
    fragment TasksActionFragment on Customer {
      tasks {
        _id
        status
      }
    }
  `,
};

export default TasksAction;
