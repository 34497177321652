// https://stackoverflow.com/questions/6195335/linear-regression-in-javascript
export const linearRegression = (x, y) => {
  var n = y.length;
  var sum_x = 0;
  var sum_y = 0;
  var sum_xy = 0;
  var sum_xx = 0;

  for (var i = 0; i < y.length; i++) {
    sum_x += x[i];
    sum_y += y[i];
    sum_xy += x[i] * y[i];
    sum_xx += x[i] * x[i];
  }

  let slope = (n * sum_xy - sum_x * sum_y) / (n * sum_xx - sum_x * sum_x);
  let intercept = (sum_y - slope * sum_x) / n;
  return x => x * slope + intercept;
};

export const kFormatter = num => {
  return Math.abs(num) > 999
    ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + 'k'
    : num;
};

export const bellCurve = data => {
  const mean = data.reduce((a, b) => a + b) / data.length;
  const stddev = Math.sqrt(
    data.map(x => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / data.length,
  );

  return {
    func: x =>
      Math.exp(-Math.pow(x - mean, 2) / (2 * Math.pow(stddev, 2))) /
      (stddev * Math.sqrt(2 * Math.PI)),
    stddev,
    mean,
  };
};

export const phoneStrip = x => x?.replace(/^1|\D/g, '') ?? '';
export const dealerPhoneNumbers = dealers =>
  dealers?.map(d => phoneStrip(d.phone_number))?.filter(p => p) ?? [];
