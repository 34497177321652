import React from 'react';
import Layout from 'modules/app/components/Layout';

import ErrorDisplay from 'components/MaterialUI/ErrorDisplay';

const ErrorPage = ({ action, children, error }) => (
  <Layout title="Error">
    <ErrorDisplay action={action} error={error}>
      {children}
    </ErrorDisplay>
  </Layout>
);

export default ErrorPage;
