import React from 'react';

import Typography from '@mui/material/Typography';

import { getFullVehicle } from '../utils';

const returnVehicle = vehicle =>
  `${vehicle.year || ''} ${vehicle.make || ''} ${vehicle.model || ''} ${
    vehicle.trim || ''
  }`;

const OpportunityVehicleData = ({ opportunity }) => {
  return (
    <>
      <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
        {opportunity.dms_deal.deal_number
          ? getFullVehicle(opportunity.dms_deal || {})
          : opportunity.pitches.length > 0
          ? returnVehicle(opportunity?.pitches[0]?.vehicles[0] || {})
          : null}
        {'\n'}
        {opportunity.dms_deal?.stock_number
          ? `Stock: ${opportunity.dms_deal.stock_number}`
          : opportunity.pitches.length > 0
          ? `Stock: ${
              opportunity?.pitches[0]?.vehicles[0]?.stockNumber || 'N/A'
            }`
          : ''}
      </Typography>
      <Typography variant="caption" color="textSecondary">
        {opportunity.dms_deal?.deal_number
          ? `Deal #: ${opportunity.dms_deal?.deal_number}`
          : opportunity.pitches.length > 0
          ? 'Deal #: N/A'
          : ''}
      </Typography>
    </>
  );
};

export default OpportunityVehicleData;
