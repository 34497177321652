export const MAKE_SORT = {
  model: 'Make',
  field: 'name',
  options: { headerLabel: '(Make Name)' },
};
export const MODEL_SORT = {
  model: 'Model',
  field: 'name',
  options: { headerLabel: '(Model Name)' },
};
export const ODOMETER_SORT = { model: 'Vehicle', field: 'odometer' };
export const SORT_BY_ID = {
  model: 'Vehicle',
  field: 'id',
  direction: 'desc',
};
export const STOCK_NUMBER_SORT = {
  model: 'Vehicle',
  field: 'stock_number',
  options: { headerLabel: '(Stk#)' },
};
export const YEAR_SORT = { model: 'Vehicle', field: 'year' };
export const VEHICLE_SORT_OPTIONS = [
  { ...YEAR_SORT, direction: 'asc' },
  { ...YEAR_SORT, direction: 'desc' },
  { ...MAKE_SORT, direction: 'asc' },
  { ...MAKE_SORT, direction: 'desc' },
  { ...MODEL_SORT, direction: 'asc' },
  { ...MODEL_SORT, direction: 'desc' },
  { ...STOCK_NUMBER_SORT, direction: 'asc' },
  { ...STOCK_NUMBER_SORT, direction: 'desc' },
  { ...ODOMETER_SORT, direction: 'asc' },
  { ...ODOMETER_SORT, direction: 'desc' },
];
export const COST_SORT = { model: 'Vehicle', field: 'cost' };
export const PRICE_SORT = { model: 'Vehicle', field: 'regular_price' };
export const DAYS_IN_STOCK_SORT = { model: 'Vehicle', field: 'days_in_stock' };
export const PHOTO_COUNT_SORT = { model: 'Vehicle', field: 'photo_count' };
export const CBB_CONDITION_SORT = { model: 'CBB', field: 'condition' };
export const CBB_VALUE_SORT = { model: 'CBB', field: 'selected_price' };
