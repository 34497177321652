import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import FormatDateTZ from 'components/MaterialUI/FormatDateTZ';
import Loading from 'components/MaterialUI/Loading';

const ATTACHMENT_QUERY = gql`
  query attachments($vin: String!) {
    attachment {
      getAttachments(vin: $vin) {
        id
        tags
        label
        created_by
        date_created
        url
        user {
          display_name
        }
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  multipleLine: {
    whiteSpace: 'pre-line',
    fontSize: '12px',
    color: '#999999',
  },
}));

const VehicleAttachmentsReadOnlyTable = ({ vin }) => {
  const classes = useStyles();
  const { loading, data } = useQuery(ATTACHMENT_QUERY, {
    variables: { vin },
    skip: !vin,
  });

  const attachments = (data && data.attachment.getAttachments) || [];

  if (loading) return <Loading />;
  if (attachments.length === 0) return <Box marginTop="1rem">None</Box>;

  return (
    <Table aria-label="spanning table">
      <TableHead>
        <TableRow>
          <TableCell>File</TableCell>
          <TableCell>Date</TableCell>
          <TableCell>By</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {attachments.map(attachment => (
          <TableRow key={attachment.id}>
            <TableCell>
              <a
                href={attachment.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {attachment.label}
              </a>
              <Typography className={classes.multipleLine}>
                {attachment.tags}
              </Typography>
            </TableCell>
            <TableCell>
              <FormatDateTZ date={attachment.date_created} format="LLLL" />
            </TableCell>
            <TableCell>{attachment.user.display_name}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default VehicleAttachmentsReadOnlyTable;
