import React from 'react';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Typography,
  TextField,
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import InspectionCheckboxes from '../InspectionCheckboxes';

export default function SuperAccordion({
  title,
  fields,
  checkboxValues,
  checkboxHandleFunc,
  xs1 = 9,
  xs2 = 2,
  greenValue = 'Yes',
  orange = false,
  children,
  ...checkboxesProps
}) {
  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>
          <b>{title}</b>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={1} alignItems="center">
          {Object.entries(fields).map(([name, display_name]) => {
            return (
              <React.Fragment key={name}>
                <Grid
                  item
                  xs={xs1}
                  style={{
                    // 1rem for regular items (e.g. vw1), 2.5rem for subitems (e.g. vw1a)
                    paddingLeft: /[a-z]$/i.test(name) ? '2.5rem' : '1rem',
                  }}
                >
                  <Typography>{display_name?.text || display_name}</Typography>
                </Grid>
                <Grid item xs={xs2}>
                  <InspectionCheckboxes
                    name={name}
                    valueGroup={checkboxValues}
                    handleGroup={checkboxHandleFunc}
                    greenValue={greenValue}
                    orange={orange}
                    {...checkboxesProps}
                  />
                </Grid>
                {display_name?.type === 'odometer' && (
                  <Grid container style={{ paddingLeft: '2.5rem' }}>
                    <Grid item xs={6} style={{ width: '100%' }}>
                      <TextField
                        type="number"
                        inputProps={{ min: 0, step: '1' }}
                        label="Before"
                        name="odometer_before"
                      />
                    </Grid>
                    <Grid item xs={6} style={{ width: '100%' }}>
                      <TextField
                        type="number"
                        inputProps={{ min: 0, step: '1' }}
                        label="After"
                        name="odometer_after"
                      />
                    </Grid>
                  </Grid>
                )}
              </React.Fragment>
            );
          })}
          {children}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
