import React from 'react';
import moment from 'moment';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { snackBarSettings } from '../../../../constants';
import { useSnackbar } from 'notistack';
import MaterialTable from 'material-table';
import cloudinary from 'cloudinary-core';
import CardMedia from '@mui/material/CardMedia';
import DeleteIcon from '@mui/icons-material/Delete';
import ShareIcon from '@mui/icons-material/Share';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { useUserContext } from 'components/MaterialUI/UserContext';
import ROPhotoCard from './ROPhotoCard';
import { cloudinaryConfig, cloudinaryRoParams } from '../../../../constants';
import CloudinaryPhotoUploader from '../CloudinaryPhotoUploader';
import Box from '@mui/material/Box';
import { getFullDateTime } from 'utils';
import gql from 'graphql-tag';

const RODetailsPhotos = ({
  isDesktop,
  ro,
  removePhoto,
  sharePhoto,
  addPhoto,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { me } = useUserContext();

  const handleDelete = photo => {
    if (window.confirm('Delete photo?')) {
      removePhoto({
        variables: {
          dealerId: ro.dealer_id,
          roNumber: ro.ro_number,
          photoId: photo.id,
        },
      }).then(r => enqueueSnackbar('Photo removed', snackBarSettings));
    }
  };

  const handleShare = photo => {
    if (
      !photo.shared &&
      !window.confirm('Share photo with customer via SMS?')
    ) {
      return;
    }
    if (ro.permissions.allow.includes('share_media')) {
      sharePhoto({
        variables: {
          dealerId: ro.dealer_id,
          roNumber: ro.ro_number,
          photoId: photo.id,
        },
      }).then(r =>
        photo.shared
          ? enqueueSnackbar(`Link revoked`, snackBarSettings)
          : enqueueSnackbar(`Link sent`, snackBarSettings),
      );
    }
  };

  const cloudinaryCore = new cloudinary.Cloudinary({
    cloud_name: cloudinaryConfig.cloud_name,
  });

  const columns = [
    {
      width: '1%',
      cellStyle: { whiteSpace: 'nowrap' },
      title: 'Actions',
      field: 'actions',
      filtering: false,
      render: rowData => (
        <>
          <Tooltip title={'Delete Photo'}>
            <IconButton onClick={() => handleDelete(rowData)} size="large">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={'Share Photo'}>
            <IconButton
              aria-label="share"
              onClick={() => handleShare(rowData)}
              color={rowData.shared ? 'secondary' : 'default'}
              size="large"
            >
              <ShareIcon />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
    {
      title: 'Added',
      field: 'created_at',
      filtering: true,
      render: rowData =>
        getFullDateTime(
          rowData.created_at,
          me?.goUserProfile?.settings?.timezone,
        ),
      defaultSort: 'desc',
    },
    {
      title: 'Photo',
      field: 'cloudinary_public_id',
      filtering: false,
      render: rowData => (
        <CardMedia
          src={cloudinaryCore.url(
            rowData.cloudinary_public_id,
            cloudinaryRoParams,
          )}
          component="img"
          height={180}
          controls
          bgcolor="text.secondary"
        />
      ),
    },
    {
      title: 'Added by',
      field: 'created_by_display_name',
      filtering: false,
    },
  ];

  const tableOptions = {
    pageSize: ro.photos.length,
    pageSizeOptions: [{ value: -1, label: 'All' }],
    filtering: false,
  };

  return (
    <Container maxWidth="xl">
      {isDesktop && (
        <MaterialTable
          title={'Photos Details'}
          data={ro.photos.filter(p => !p.deleted)}
          columns={columns}
          options={tableOptions}
          localization={{
            body: {
              emptyDataSourceMessage: (
                <Box p="1rem">
                  <h1>There are no photos for this vehicle.</h1>
                </Box>
              ),
            },
          }}
        />
      )}
      <Grid container>
        {!isDesktop && (
          <>
            {ro.photos.length === 0 && (
              <Box p="1rem" style={{ fontSize: '20px' }}>
                There are no photos for this vehicle.
              </Box>
            )}
            {ro.photos
              .filter(p => !p.deleted)
              .sort((a, b) => moment(b.created_at) - moment(a.created_at))
              .map(photo => (
                <Grid
                  item
                  key={photo.cloudinary_public_id}
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                >
                  <ROPhotoCard
                    photo={photo}
                    onDelete={() =>
                      removePhoto({
                        variables: {
                          dealerId: ro.dealer_id,
                          roNumber: ro.ro_number,
                          photoId: photo.id,
                        },
                      }).then(r =>
                        enqueueSnackbar('Photo removed', snackBarSettings),
                      )
                    }
                    onShare={
                      ro.permissions.allow.includes('share_media') &&
                      (() =>
                        sharePhoto({
                          variables: {
                            dealerId: ro.dealer_id,
                            roNumber: ro.ro_number,
                            photoId: photo.id,
                          },
                        }).then(r =>
                          photo.shared
                            ? enqueueSnackbar('Link revoked', snackBarSettings)
                            : enqueueSnackbar('Link sent', snackBarSettings),
                        ))
                    }
                  />
                </Grid>
              ))}
          </>
        )}
        <Grid item xs={12} style={{ paddingTop: '1rem' }}>
          <CloudinaryPhotoUploader
            params={ro.photoUploadParams}
            onUploaded={cloudinaryId =>
              addPhoto({
                variables: {
                  dealerId: ro.dealer_id,
                  roNumber: ro.ro_number,
                  cloudinaryId,
                },
              }).then(r => enqueueSnackbar('Photo uploaded', snackBarSettings))
            }
          />
        </Grid>
      </Grid>
    </Container>
  );
};

RODetailsPhotos.fragments = {
  ro: gql`
    fragment RODetailsPhotosRO on ROTrackerRO {
      dealer_id
      ro_number
      permissions {
        allow
      }
      photos {
        deleted
        created_at
        cloudinary_public_id
        shared
      }
    }
  `,
};

export default RODetailsPhotos;
