import React, { useRef, useEffect } from 'react';
import { Fab } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import StopIcon from '@mui/icons-material/Stop';
import PauseIcon from '@mui/icons-material/Pause';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { Status } from './constants';

const Camera = ({
  userMedia,
  status,
  onStart,
  onStop,
  onPause,
  onResume,
  onClose,
  onSave,
  playbackURL,
  message,
}) => {
  const previewElem = useRef(null);
  const playbackElem = useRef(null);
  useEffect(() => {
    if (previewElem.current) {
      previewElem.current.srcObject = userMedia;
    }
  });
  const recordingControls = {
    [Status.DEVICE_READY]: (
      <>
        <Fab color="primary" disabled size="small">
          <SaveIcon />
        </Fab>
        <Fab color="secondary" onClick={onStart}>
          <FiberManualRecordIcon />
        </Fab>
        <Fab color="primary" onClick={onClose} size="small">
          <CancelIcon />
        </Fab>
      </>
    ),
    [Status.RECORDING]: (
      <>
        <Fab color="primary" onClick={onStop} size="small">
          <StopIcon />
        </Fab>
        <Fab color="secondary" disabled>
          <FiberManualRecordIcon />
        </Fab>
        <Fab color="primary" onClick={onPause} size="small">
          <PauseIcon />
        </Fab>
      </>
    ),
    [Status.STOPPED]: (
      <>
        <Fab color="primary" onClick={onSave} size="small">
          <SaveIcon />
        </Fab>
        <Fab color="secondary" onClick={onStart}>
          <FiberManualRecordIcon />
        </Fab>
        <Fab color="primary" onClick={onClose} size="small">
          <CancelIcon />
        </Fab>
      </>
    ),
    [Status.PAUSED]: (
      <>
        <Fab color="primary" onClick={onStop} size="small">
          <StopIcon />
        </Fab>
        <Fab color="secondary" onClick={onResume}>
          <FiberManualRecordIcon />
        </Fab>
        <Fab color="primary" disabled size="small">
          <PauseIcon />
        </Fab>
      </>
    ),
  }[status];

  return (
    <div style={{ maxHeight: '100vh' }}>
      <div style={{ width: '100vw', height: '100vh', position: 'fixed' }}>
        {status === Status.STOPPED ? (
          <video
            key="playbackElem"
            ref={playbackElem}
            src={playbackURL}
            width="100%"
            controls
          />
        ) : (
          <video
            key="previewElem"
            ref={previewElem}
            autoPlay
            muted
            width="100%"
          />
        )}
      </div>
      <div
        style={{
          position: 'absolute',
          bottom: '1em',
          display: 'flex',
          justifyContent: 'space-evenly',
          alignItems: 'center',
          width: '100vw',
        }}
      >
        {recordingControls}
      </div>
    </div>
  );
};

export default Camera;
