import React, { useState } from 'react';

/* external */
import { Link } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { useFormContext } from 'react-hook-form';
import gql from 'graphql-tag';
import { useSnackbar } from 'notistack';

/* Material UI */
import {
  Box,
  Checkbox,
  IconButton,
  Input,
  TableCell,
  TableRow,
  Tooltip,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';

/* internal */
import { formatPrice } from 'utils';
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import DeleteItemForm from '../items/DeleteItemForm';
import ItemDescriptionCell from './common/ItemDescriptionCell';
import ItemFavouriteCell from './common/ItemFavouriteCell';
import ItemPhotoCell from './common/ItemPhotoCell';
import ItemStartTimeCell from './common/ItemStartTimeCell';
import TextFieldControl from 'components/MaterialUI/TextFieldControl';
import { errorHandler } from 'modules/auctions/utils';

const DELETE_AUCTION_ITEM = gql`
  mutation deleteAuctionItem($id: Int!, $deletionReason: String!) {
    auctions {
      deleteAuctionItem(id: $id, deletionReason: $deletionReason) {
        id
        deleted
        deletionReason
      }
    }
  }
`;

const UpcomingItemsRow = ({
  carfax,
  item,
  auction,
  index,
  showReserveColumn,
  isChecked,
  isDeletable,
  handleCheckItem,
  ...rest
}) => {
  const { dealerId } = useDealerContext();
  const { enqueueSnackbar: snackIt } = useSnackbar();
  const { register } = useFormContext();
  const [showDeleteForm, setShowDeleteForm] = useState(false);
  const {
    dealer: { name: seller, province_name: sellerProvinceName },
    dealerProxyBids = [],
    dealerBlindBid,
    dealerId: itemDealerId,
    deleted,
    deletionReason,
    id,
    minimumStartingBid,
    reserve,
    vehicle,
  } = item;
  const { make, model, stockNumber, year } = vehicle;
  const { canBlindBid, isWeekly } = auction;

  const [
    deleteAuctionItem,
    { loading: deletingItem, errors: deletingItemErrors },
  ] = useMutation(DELETE_AUCTION_ITEM, {
    onError: err => errorHandler(snackIt, () => {})(err),
  });

  const hasProxy =
    dealerProxyBids.length > 0 &&
    dealerProxyBids.sort((a, b) => b.id - a.id)[0].amount > 0;

  const hasBlind = Boolean(dealerBlindBid);

  const canDelete = isDeletable && !deletingItem;

  const handleSubmitDelete = ({ deletionReason }) => {
    deleteAuctionItem({
      variables: { id, deletionReason },
    }).then(() => setShowDeleteForm(false));
  };
  const handleClickDelete = e => {
    setShowDeleteForm(true);
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    //   <TableRow
    //   component={Link}
    //   underline="none"
    //   href={deleted ? null : `/auctions/items/${id}`}
    //   hover={!deleted}
    //   {...rest}
    // >
    // Note: this works, but the console complains about invalid DOM nesting
    // (since <a> can't be a child of <tbody>)
    <TableRow hover={!deleted} {...rest}>
      <DeleteItemForm
        isOpen={showDeleteForm}
        submitErrors={deletingItemErrors}
        onClose={() => setShowDeleteForm(false)}
        onSubmit={handleSubmitDelete}
      />
      <TableCell padding="checkbox">
        <Checkbox
          checked={isChecked}
          disabled={!canDelete}
          onClick={_ => handleCheckItem(id)}
        />
      </TableCell>
      <TableCell>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Tooltip title={'Click me to find out more about this auction item!'}>
            <Link
              to={`/auctions/items/${id}?scrollY=${window.scrollY}&index=${index}`}
              style={{ height: '24px' }}
            >
              <InfoIcon
                style={{
                  color: '#2196f3',
                  fontSize: '24px',
                }}
              />
            </Link>
          </Tooltip>
          {canDelete ? (
            <IconButton onClick={handleClickDelete} size="large">
              <DeleteIcon />
            </IconButton>
          ) : (
            <Box component="span" width="24px" paddingLeft="24px" />
          )}
        </Box>
      </TableCell>
      <ItemPhotoCell item={item} />
      <ItemDescriptionCell carfax={carfax} item={item} hasProxy={hasProxy} />
      {deleted ? (
        <TableCell colSpan={showReserveColumn ? 5 : 4}>
          Deleted because: "{deletionReason}"
        </TableCell>
      ) : (
        <>
          <ItemFavouriteCell item={item} />
          <TableCell>{seller}</TableCell>
          <ItemStartTimeCell auction={auction} item={item} countdown />
          <TableCell>{formatPrice(minimumStartingBid)}</TableCell>
          {showReserveColumn && (
            <TableCell>
              {dealerId === itemDealerId && reserve !== null
                ? formatPrice(reserve)
                : null}
            </TableCell>
          )}
          {isWeekly && (
            <TableCell onClick={e => e.stopPropagation()}>
              {canBlindBid && dealerId !== itemDealerId && !hasBlind && (
                <>
                  <Input
                    type="hidden"
                    {...register(`${id}.order`)}
                    defaultValue={index}
                  />
                  <Input
                    {...register(`${id}.displayName`)}
                    defaultValue={`${year} ${make} ${model} (${
                      stockNumber ?? 'no stock number'
                    })`}
                    type="hidden"
                  />
                  <Input
                    type="hidden"
                    {...register(`${id}.sellerProvinceName`)}
                    defaultValue={sellerProvinceName}
                  />
                  <TextFieldControl
                    name={`${id}.amount`}
                    variant="currencyDollars"
                  />
                </>
              )}
              {hasBlind && formatPrice(dealerBlindBid.amount, '$', true)}
            </TableCell>
          )}
        </>
      )}
    </TableRow>
  );
};

UpcomingItemsRow.fragments = {
  auction: gql`
    fragment UpcomingItemsRowAuction on Auction {
      ...ItemStartTimeCellAuction
    }
    ${ItemStartTimeCell.fragments.auction}
  `,
  auctionItem: gql`
    fragment UpcomingItemsRowAuctionItem on AuctionItem {
      id
      bidCount
      dealer {
        id
        name
        province_name
      }
      dealerProxyBids(dealerId: $dealerId) {
        id
        amount
      }
      dealerBlindBid(dealerId: $dealerId) {
        id
        amount
      }
      dealerId
      deleted
      deletionReason
      endsAt
      isActive
      isClosed
      isUpcoming
      leadingBidId
      minimumBidIncrement
      minimumStartingBid
      permissions {
        delete
        update
      }
      reserve
      vehicle {
        id
        make
        model
        stockNumber
        year
      }
      ...ItemDescriptionCellAuctionItem
      ...ItemFavouriteCellAuctionItem
      ...ItemPhotoCellAuctionItem
      ...ItemStartTimeCellAuctionItem
    }
    ${ItemDescriptionCell.fragments.auctionItem}
    ${ItemFavouriteCell.fragments.auctionItem}
    ${ItemPhotoCell.fragments.auctionItem}
    ${ItemStartTimeCell.fragments.auctionItem}
  `,
  auctionItemFeed: gql`
    fragment AuctionItemFeed on AuctionItem {
      id
      bidCount
      dealer {
        id
        name
        province_name
      }
      dealerId
      deleted
      deletionReason
      endsAt
      isActive
      isClosed
      isUpcoming
      leadingBidId
      minimumBidIncrement
      minimumStartingBid
      reserve
      startsAt
      vehicle {
        id
        make
        model
        stockNumber
        year
      }
      ...ItemDescriptionCellAuctionItem
      ...ItemPhotoCellAuctionItem
      ...ItemStartTimeCellAuctionItem
    }
    ${ItemDescriptionCell.fragments.auctionItem}
    ${ItemPhotoCell.fragments.auctionItem}
    ${ItemStartTimeCell.fragments.auctionItem}
  `,
};
export default UpcomingItemsRow;
