import React from 'react';

/* Material UI */
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

/* internal */
import { categoryOptions } from 'modules/appraisals/utils';

const AppraisalsCategoryTabs = ({
  categoryStats,
  categoryFiltersIndex,
  setCategoryFiltersIndex,
  ...rest
}) => (
  <Tabs
    textColor="primary"
    indicatorColor="secondary"
    variant="scrollable"
    scrollButtons="auto"
    value={categoryFiltersIndex}
    onChange={(_event, tabIndex) => setCategoryFiltersIndex(tabIndex)}
    {...rest}
  >
    {categoryOptions(categoryStats).map(({ title, value, subValue }, index) => (
      <Tab
        key={index}
        label={
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box>{title}</Box>
            <Box
              columnGap="16px"
              display="flex"
              justifyContent="space-between"
              alignItems="baseline"
            >
              <Box fontWeight="bold" fontSize="16px">
                {value}
              </Box>
              <Box>{subValue}</Box>
            </Box>
          </Box>
        }
        style={{ paddingLeft: 30, paddingRight: 30, flexGrow: '1' }}
      />
    ))}
  </Tabs>
);

export default AppraisalsCategoryTabs;
