import gql from 'graphql-tag';
import moment from 'moment';
import React, { Fragment, useMemo, useState } from 'react';

import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  Info as InfoIcon,
  KeyboardArrowDown,
  KeyboardArrowUp,
  PersonPinCircle as PersonPinCircleIcon,
  Room as PinCircleIcon,
} from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material';

import { useDealerContext } from 'components/MaterialUI/DealerContext';

import { formatOdometer, formatPrice } from 'utils';
import { phoneStrip, dealerPhoneNumbers } from './utils';

const useStyles = makeStyles((theme, props) => ({
  noBottomBorder: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  borderless: {
    '& > *': {
      border: 'none',
      padding: theme.spacing(1),
    },
  },
  subTableHeader: {
    '& > *': {
      borderBottom: `1px solid ${theme.palette.secondary.main}`,
      fontWeight: 'bold',
    },
  },
  atDealerRow: {
    backgroundColor: 'yellow',
  },
  boldKids: {
    '& > *': {
      fontWeight: 'bold',
    },
  },
}));

const DataBox = ({ label, data, ...rest }) => (
  <Box display="flex" {...rest}>
    <Box mr={1}>{label}:</Box>
    <Box fontWeight="bold">{data}</Box>
  </Box>
);

const UsedPricingToolTableRow = ({ listing, organization, myVin, ...rest }) => {
  const {
    days_on_market: daysOnMarket,
    dealer_city: dealerCity,
    dealer_name: dealerName,
    dealer_phone: dealerPhone,
    dealer_postal_code: dealerPostalCode,
    dealer_province: dealerProvince,
    dealer_street: dealerStreet,
    distance_to_dealer: distanceToDealer,
    exterior_color: exteriorColor,
    interior_color: interiorColor,
    listing_type: listingType,
    listing_url: listingUrl,
    make,
    mileage,
    model_year: modelYear,
    model,
    price,
    price_history = [],
    series,
    vin,
  } = listing;

  const orgDealerPhoneNumbers = useMemo(
    () => dealerPhoneNumbers(organization?.greaseDealers),
    [organization],
  );

  const vehicleTitle = [modelYear, make, model].filter(x => x).join(' ');

  const theme = useTheme();
  const { noBottomBorder, borderless, subTableHeader, boldKids } = useStyles(
    theme,
    {},
  );
  const [open, setOpen] = useState(false);

  const {
    greaseDealer: {
      name: currentDealerName,
      phone_number: currentDealerPhoneNumber,
    },
  } = useDealerContext();

  const atActiveDealer =
    phoneStrip(currentDealerPhoneNumber) === phoneStrip(dealerPhone);

  const atOrgDealer = orgDealerPhoneNumbers.includes(phoneStrip(dealerPhone));

  const myCar = vin?.toLowerCase() === myVin?.toLowerCase();

  const changeDateSorter = ({ change_date: date1 }, { change_date: date2 }) =>
    moment(date2, 'MM-DD-YYYY').diff(moment(date1, 'MM-DD-YYYY'));

  return (
    <Fragment>
      <TableRow
        {...rest}
        className={noBottomBorder}
        style={myCar ? { backgroundColor: '#fff5d6' } : undefined}
      >
        <TableCell>
          <Box>
            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
              {vehicleTitle}
            </Typography>
            {series}
          </Box>
        </TableCell>
        <TableCell>{formatPrice(price)}</TableCell>
        <TableCell>{formatOdometer(mileage)}</TableCell>
        <TableCell>{listingType}</TableCell>
        <TableCell>{dealerName}</TableCell>
        <TableCell>
          <Box display="flex" justifyContent="space-between">
            <Box>{formatOdometer(distanceToDealer.toFixed(0))}</Box>
            {atActiveDealer ? (
              <Tooltip
                title={`This vehicle is at the active dealer (${currentDealerName}).`}
              >
                <PersonPinCircleIcon
                  color="secondary"
                  style={{
                    fontSize: '24px',
                  }}
                />
              </Tooltip>
            ) : atOrgDealer ? (
              <Tooltip title={`This vehicle is at a Go Auto dealer.`}>
                <PinCircleIcon
                  color="secondary"
                  style={{
                    fontSize: '24px',
                  }}
                />
              </Tooltip>
            ) : null}
          </Box>
        </TableCell>
        <TableCell>
          <IconButton onClick={() => setOpen(prev => !prev)} size="large">
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={6} style={{ paddingBottom: 0, paddingTop: 0 }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box display="flex" alignItems="flex-start">
              <Table style={{ width: '60%' }} size="small">
                <TableHead>
                  <TableRow className={subTableHeader}>
                    <TableCell>Vehicle Info</TableCell>
                    <TableCell>Age</TableCell>
                    <TableCell>Seller</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow className={borderless}>
                    <TableCell>
                      <DataBox label="VIN" data={vin} />
                    </TableCell>
                    <TableCell>{daysOnMarket} days</TableCell>
                    <TableCell>
                      <Box fontWeight="bold">{dealerName}</Box>
                    </TableCell>
                  </TableRow>
                  <TableRow className={borderless}>
                    <TableCell>
                      <DataBox
                        label="Colour"
                        data={exteriorColor || 'Unknown'}
                      />
                    </TableCell>
                    <TableCell />
                    <TableCell>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Box>{dealerStreet}</Box>
                        <Box>
                          {listingUrl && listingType === 'Active' && (
                            <Tooltip title="Click me to open the VDP on the dealer's website in a new tab!">
                              <a
                                href={listingUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <InfoIcon
                                  style={{
                                    color: theme.actions.create.color,
                                    fontSize: '24px',
                                  }}
                                />
                              </a>
                            </Tooltip>
                          )}
                        </Box>
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow className={borderless}>
                    <TableCell>
                      <DataBox
                        label="Interior"
                        data={interiorColor || 'Unknown'}
                      />
                    </TableCell>
                    <TableCell />
                    <TableCell>
                      {dealerCity}, {dealerProvince} {dealerPostalCode}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Table style={{ width: '40%', marginLeft: '30px' }} size="small">
                <TableHead>
                  <TableRow className={subTableHeader}>
                    <TableCell colSpan={3} style={{ textAlign: 'left' }}>
                      Price History
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow className={boldKids}>
                    <TableCell align="left">Price</TableCell>
                    <TableCell align="left">Change Date</TableCell>
                    <TableCell align="left">Mileage</TableCell>
                  </TableRow>
                  {price_history
                    .sort(changeDateSorter)
                    .map(
                      ({ price, mileage, change_date: changeDate }, index) => (
                        <TableRow key={index}>
                          <TableCell align="left">
                            {formatPrice(price)}
                          </TableCell>
                          <TableCell align="left">{changeDate}</TableCell>
                          <TableCell align="left">
                            {formatOdometer(mileage)}
                          </TableCell>
                        </TableRow>
                      ),
                    )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

UsedPricingToolTableRow.fragments = {
  listing: gql`
    fragment UsedPricingToolTableRowListingType on ListingType {
      days_on_market
      dealer_city
      dealer_name
      dealer_phone
      dealer_postal_code
      dealer_province
      dealer_street
      distance_to_dealer
      exterior_color
      interior_color
      listing_id
      listing_type
      listing_url
      make
      mileage
      model
      model_year
      price
      price_history {
        price
        change_date
        mileage
      }
      series
      vin
    }
  `,
  organization: gql`
    fragment UsedPricingToolTableRowOrganization on Organization {
      greaseDealers {
        id
        name
        phone_number
      }
    }
  `,
};

export default UsedPricingToolTableRow;
