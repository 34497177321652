import React, { useState } from 'react';

/* external */
import gql from 'graphql-tag';
import moment from 'moment-timezone';
import { NetworkStatus } from 'apollo-boost';
import { startCase, toLower } from 'lodash';
import { useSnackbar } from 'notistack';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useHistory, Link } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import _ from 'lodash';

/* Material UI */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EmailIcon from '@mui/icons-material/Email';
import Grid from '@mui/material/Grid';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Paper from '@mui/material/Paper';
import SearchIcon from '@mui/icons-material/Search';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import FilterListIcon from '@mui/icons-material/FilterList';
import Fab from '@mui/material/Fab';
import Drawer from '@mui/material/Drawer';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material';

/* internal */
import AppointmentsTableHeader from './AppointmentsTableHeader';
import ConfirmDialog from 'components/MaterialUI/ConfirmDialog';
import FacetChips from '../FacetChips';
import FilterDrawer from '../FilterDrawer';
import GoCardScanner from '../GoCardScanner';
import Loading from 'components/MaterialUI/Loading';
import { CONFIRMED_STATUSES } from 'constants.js';
import { formatPrice } from 'utils';
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import { usePersistedState } from '../../../../utils/utils';
import CustomerMerge from '../../../customers/components/CustomerMerge/CustomerMerge';

const PAGE_SIZE = 50;

const RO_APPOINTMENTS = gql`
  query appointments(
    $dealer_id: Int!
    $queryFacets: [QueryFacet]!
    $filters: [QueryFilter]
    $page: Int
    $sort: [QuerySortElement]
  ) {
    rotracker {
      getAppointments(filters: $filters, page: $page, page_size: ${PAGE_SIZE}, sort: $sort) {
        results {
          id
          dealer_id
          ro {
            id
            cdk_service_advisor_display_name
            gocard_id
            has_gocard_attached
            ro_number
            gocard {
              cardId
              cardNumber
              cashBalance
            }
          }
          ...GoCardScannerAppointment
          customer {
            app_account {
              isEnabled
              status
            }
          }
          vin
          vehicle_make
          vehicle_model
          vehicle_odometer
          vehicle_year
          appointment_date
          appointment_time
          comments
          complaint_summary
          customer_name
          customer_phone
          customer_email
          gocard_id
          has_gocard_attached
          is_cancelled
          gocard {
            cardId
            cardNumber
            cashBalance
          }
        }
        pagination {
          next_page
          total
        }
      }
      getAppointmentFacets(
        dealer_id: $dealer_id
        filters: $filters
        facets: $queryFacets
      ) {
        data {
          count
          value
        }
        field
        model
      }
    }
  }
  ${GoCardScanner.fragments.appointment}
`;

const DETACH_GOCARD = gql`
  mutation detachGoCardFromAppointment(
    $dealerId: Int!
    $appointmentId: String!
  ) {
    rotracker {
      detachGoCardFromAppointment(
        dealer_id: $dealerId
        appointment_id: $appointmentId
      ) {
        id
      }
    }
  }
`;

const DETACH_GOCARD_RO = gql`
  mutation updateRO($dealerId: Int!, $roNumber: String!, $update: ROUpdate!) {
    rotracker {
      updateRO(dealer_id: $dealerId, ro_number: $roNumber, update: $update) {
        id
        has_gocard_attached
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 750,
  },
  dateCell: {
    whiteSpace: 'pre-line',
    textAlign: 'center',
    paddingRight: '50px',
  },
  customerCell: {
    whiteSpace: 'pre-line',
    '& a': {
      color: '#0093a7',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  icon: {
    verticalAlign: 'text-bottom',
    marginRight: '5px',
  },
  check: {
    color: 'green',
    verticalAlign: 'text-bottom',
    padding: '2px',
  },
}));

const AppointmentsTable = ({
  isDesktop,
  datePickerDate,
  toggleDrawer,
  state,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('appointment_time');
  const [keywords, setKeywords] = useState('');
  const [keywordFilters, setKeywordFilters] = useState([]);
  const [detachConfirmId, setDetachConfirmId] = useState(false);
  const [sort, setSort] = useState([
    { model: 'Appointment', field: 'appointment_time', direction: 'desc' },
  ]);
  const [showMergeDialog, setShowMergeDialog] = useState(false);
  const [mergeCustomers, setMergeCustomers] = useState('');
  const [mergeInProgress, setMergeInProgress] = useState(false);
  const [filterFacets, setFilterFacets] = usePersistedState(
    'ServiceAppointmentFilterFacets',
    [],
  );
  const { dealerId } = useDealerContext();
  const date = moment(datePickerDate).format('YYYY-MM-DD');

  const handleRequestSort = (event, property) => {
    let value;
    if (property === 'ro.cdk_service_advisor_display_name') {
      value = [
        {
          model: 'ServiceRO',
          field: 'cdk_service_advisor_display_name',
          direction: order === 'asc' ? 'desc' : 'asc',
        },
      ];
    } else {
      value = [
        {
          model: 'Appointment',
          field: property,
          direction: order === 'asc' ? 'desc' : 'asc',
        },
      ];
    }
    setSort(value);
    setOrder(order === 'asc' ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const setSearchFiltersFromKeywords = () =>
    setKeywordFilters(
      keywords.split(' ').map(keyword => ({
        or: [
          {
            model: 'Appointment',
            field: 'customer_name',
            op: 'ilike',
            value: `%${keyword}%`,
          },
          {
            model: 'Appointment',
            field: 'vehicle_make',
            op: 'ilike',
            value: `%${keyword}%`,
          },
          {
            model: 'Appointment',
            field: 'vehicle_model',
            op: 'ilike',
            value: `%${keyword}%`,
          },
          {
            model: 'Appointment',
            field: 'customer_email',
            op: 'ilike',
            value: `%${keyword}%`,
          },
          {
            model: 'Appointment',
            field: 'customer_phone',
            op: 'ilike',
            value: `%${keyword}%`,
          },
        ],
      })),
    );

  const handleSearch = e =>
    e.key === 'Enter' ? setSearchFiltersFromKeywords() : null;

  const formatFilterFacets = filterFacet => {
    let returnFilters = [];
    filterFacet.forEach(({ model, field, value }) =>
      returnFilters.push({
        model,
        field,
        op: '==',
        value: value === 'True' ? true : value === 'False' ? false : value,
      }),
    );
    return returnFilters;
  };

  const filters = [
    { model: 'Appointment', field: 'dealer_id', op: '==', value: dealerId },
    { model: 'Appointment', field: 'appointment_date', op: '==', value: date },
    { model: 'Appointment', field: 'is_cancelled', op: '!=', value: true },
    ...formatFilterFacets(filterFacets),
    ...keywordFilters,
  ];

  const queryFacets = [
    { model: 'ServiceRO', field: 'cdk_service_advisor_display_name' },
  ];

  const { data, loading, networkStatus, fetchMore, refetch } = useQuery(
    RO_APPOINTMENTS,
    {
      variables: {
        dealer_id: dealerId,
        queryFacets,
        filters,
        sort,
        page: 1,
      },
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      notifyOnNetworkStatusChange: true,
    },
  );
  const [detachGoCardFromAppointment] = useMutation(DETACH_GOCARD, {
    onCompleted: data => {
      enqueueSnackbar('Go Card Detached from Appointment!', {
        variant: 'success',
      });
      refetch();
    },
    onError: e =>
      enqueueSnackbar(`Could not detach Go Card Error: ${e}`, {
        variant: 'error',
      }),
  });

  const [detachGoCardFromRO] = useMutation(DETACH_GOCARD_RO, {
    onCompleted: data => {
      enqueueSnackbar('Go Card Detached from RO!', { variant: 'success' });
      refetch();
    },
    onError: e =>
      enqueueSnackbar(`Could not detach Go Card Error: ${e}`, {
        variant: 'error',
      }),
  });
  const rows = data?.rotracker?.getAppointments?.results || [];
  const pagination = data?.rotracker?.getAppointments?.pagination || {};
  const { next_page, total } = pagination;
  const facetResults = data?.rotracker.getAppointmentFacets || [];

  const handleDeleteFacet = (_model, _field, _value) =>
    setFilterFacets(prev =>
      prev.filter(
        ({ model, field, value }) =>
          model !== _model || field !== _field || value !== _value,
      ),
    );

  const fetchMoreHandler = () => {
    if (next_page) {
      fetchMore({
        variables: {
          page: next_page,
        },
        updateQuery: (prev, { fetchMoreResult: newData }) => {
          if (!newData.rotracker.getAppointments.results) return prev;
          return Object.assign({}, prev, {
            rotracker: {
              __typename: prev.rotracker.__typename,
              getAppointments: {
                __typename: prev.rotracker.getAppointments.__typename,
                results: [
                  ...prev.rotracker.getAppointments.results,
                  ...newData.rotracker.getAppointments.results,
                ],
                pagination: newData.rotracker.getAppointments.pagination,
              },
              getAppointmentFacets: newData.rotracker.getAppointmentFacets,
            },
          });
        },
      });
    }
  };

  // Use Go Card from RO
  const cashBalance = row => {
    if (row.ro?.has_gocard_attached && row.ro?.gocard) {
      return row.ro.gocard.cashBalance;
    }
    if (row.has_gocard_attached && row.gocard) {
      return row.gocard.cashBalance;
    }
    return null;
  };

  const cardNumber = row => {
    if (row.ro?.has_gocard_attached && row.ro?.gocard) {
      return row.ro.gocard.cardNumber;
    }
    if (row.has_gocard_attached && row.gocard) {
      return row.gocard.cardNumber;
    }
    return null;
  };

  const hasGoCardAttached = row =>
    Boolean(
      (row.ro?.has_gocard_attached && row.ro?.gocard) ||
        (row.has_gocard_attached && row.gocard),
    );

  const hasAppAccount = row => {
    if (row.customer && row.customer.app_account) {
      const { app_account: appAccount } = row.customer;
      return (
        CONFIRMED_STATUSES.includes(appAccount.status) && appAccount.isEnabled
      );
    }
    return false;
  };

  const handleDetachGoCard = row => {
    if (row.ro && row.ro.has_gocard_attached) {
      detachGoCardFromRO({
        variables: {
          dealerId: dealerId,
          roNumber: row.ro.ro_number,
          update: { gocard_id: null },
        },
      });
    } else {
      detachGoCardFromAppointment({
        variables: {
          dealerId: dealerId,
          appointmentId: row.cdk_appointment_id,
        },
      });
    }
  };

  const handleConfirm = () => {
    setShowMergeDialog(false);
    setMergeInProgress(true);
  };
  const stopMerge = () => {
    setShowMergeDialog(false);
  };

  return (
    <>
      <Grid
        container
        direction="row"
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Grid item xs={12} sm={6}>
          <div style={{ paddingBottom: '1rem' }}>
            {isDesktop && (
              <Grid
                container
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Grid
                  item
                  style={{
                    paddingRight: '1rem',
                    paddingTop: '1rem',
                    paddingBottom: '1rem',
                  }}
                >
                  <Tooltip title="Filter list">
                    <Fab
                      color="primary"
                      onClick={toggleDrawer('left', true)}
                      size="medium"
                    >
                      <FilterListIcon />
                    </Fab>
                  </Tooltip>
                </Grid>
                <Grid item style={{ display: 'flex', paddingTop: '1rem' }}>
                  {!_.isEmpty(filterFacets) && (
                    <Box paddingTop="0.5rem" paddingBottom="0.5rem">
                      <FacetChips
                        facets={filterFacets}
                        onDeleteFacet={handleDeleteFacet}
                      />
                    </Box>
                  )}
                </Grid>
              </Grid>
            )}
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          style={{
            display: 'flex',
          }}
        >
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginLeft: `${isDesktop ? 'auto' : ''}`,
              marginRight: `${isDesktop ? 0 : ''}`,
              width: `${isDesktop ? '' : '100%'}`,
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              style={{ width: `${isDesktop ? '' : '100%'}` }}
            >
              <Box>
                <TextField
                  placeholder="Search ..."
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          disabled={!keywords}
                          onClick={() => {
                            setKeywords('');
                            setKeywordFilters([]);
                          }}
                          size="large"
                        >
                          <CloseIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  value={keywords}
                  onChange={e => setKeywords(e.target.value)}
                  style={{
                    paddingRight: '5px',
                    textAlign: 'right',
                    width: `${isDesktop ? '' : '100%'}`,
                  }}
                  onKeyPress={handleSearch}
                  variant={`${isDesktop ? 'standard' : 'outlined'}`}
                />
              </Box>
              <Box
                style={{
                  display: 'flex',
                  marginLeft: 'auto',
                  marginRight: 0,
                  fontSize: '18px',
                  paddingTop: '1rem',
                }}
              >
                Showing {rows.length} of {total || '0'}
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {!isDesktop && (
        <>
          {!_.isEmpty(filterFacets) && (
            <Box paddingTop="0.5rem" paddingBottom="0.5rem">
              <FacetChips
                facets={filterFacets}
                onDeleteFacet={handleDeleteFacet}
              />
            </Box>
          )}
          <Box position="fixed" bottom="10px" right="10px">
            <Fab
              color="primary"
              onClick={toggleDrawer('left', true)}
              size="medium"
            >
              <FilterListIcon />
            </Fab>
          </Box>
        </>
      )}
      <Drawer
        anchor={'left'}
        open={state['left']}
        onClose={toggleDrawer('left', false)}
      >
        <div style={{ display: 'flex' }}>
          <IconButton
            style={{
              display: 'flex',
              marginLeft: 'auto',
              height: '50px',
              zIndex: '1000',
            }}
            onClick={toggleDrawer('left', false)}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </div>
        <FilterDrawer
          facetResults={facetResults}
          setSelectedFacets={setFilterFacets}
          selectedFacets={filterFacets}
          loading={loading}
          type="appointments"
        />
      </Drawer>

      {isDesktop && !mergeInProgress && (
        <Paper>
          <TableContainer>
            <Table>
              <AppointmentsTableHeader
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              {loading && networkStatus !== NetworkStatus.fetchMore && (
                <Loading />
              )}
              {rows.length === 0 && !loading && (
                <Box style={{ fontSize: '20px', padding: '1rem' }}>
                  There are no appointments for today
                </Box>
              )}
              <TableBody>
                {rows.map(row => (
                  <TableRow key={row.id}>
                    <TableCell>
                      {!hasGoCardAttached(row) && (
                        <GoCardScanner
                          ro={row?.ro}
                          appointment={row}
                          refetch={refetch}
                          iconOnly={true}
                          updateOverview={null}
                          startMerge={setShowMergeDialog}
                          mergeCustomers={setMergeCustomers}
                        />
                      )}
                      {hasGoCardAttached(row) && (
                        <Button
                          variant="contained"
                          color="secondary"
                          size="small"
                          endIcon={<HighlightOffIcon />}
                          onClick={() => setDetachConfirmId(row.id)}
                        >
                          Detach Go Card
                        </Button>
                      )}
                    </TableCell>
                    <TableCell className={classes.dateCell}>
                      {`${moment(
                        `${row.appointment_date} ${row.appointment_time}`,
                      ).format('lll')}`}
                    </TableCell>
                    <TableCell className={classes.customerCell}>
                      {row?.customer ? (
                        <Link
                          to={`/customers/${row.customer._id}/details`}
                          style={{ fontWeight: 600, fontSize: 'larger' }}
                        >
                          {row?.customer_name
                            ? startCase(toLower(row.customer_name))
                            : 'N/A'}
                          {hasAppAccount(row) ? (
                            <CheckCircleIcon
                              fontSize="small"
                              className={classes.check}
                            />
                          ) : (
                            ''
                          )}
                        </Link>
                      ) : (
                        <Tooltip title="No RO or Customer for appointment">
                          <Typography variant="body1">
                            {row?.customer_name
                              ? startCase(toLower(row.customer_name))
                              : 'N/A'}
                            {hasAppAccount(row) ? (
                              <CheckCircleIcon
                                fontSize="small"
                                className={classes.check}
                              />
                            ) : (
                              ''
                            )}
                          </Typography>
                        </Tooltip>
                      )}
                      <Box>
                        <Typography variant="caption">
                          <EmailIcon
                            fontSize="small"
                            className={classes.icon}
                          />
                          {row?.customer_email || ''}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="caption">
                          <SmartphoneIcon
                            fontSize="small"
                            className={classes.icon}
                          />
                          {row?.customer_phone || ''}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell className={classes.customerCell}>
                      <Typography
                        variant="caption"
                        style={{ fontWeight: 'bold', fontSize: '.9rem' }}
                      >
                        {cardNumber(row) || 'No Go Card'}
                      </Typography>
                      {'\n'}
                      <Typography variant="caption">
                        {formatPrice(cashBalance(row) || 0, {
                          cents: true,
                          zeroIfNull: true,
                        })}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {[
                        row?.vehicle_year,
                        row?.vehicle_make,
                        row?.vehicle_model,
                      ]
                        .filter(x => x)
                        .join(' ')}
                    </TableCell>
                    <TableCell>
                      {row?.ro?.cdk_service_advisor_display_name || ''}
                    </TableCell>
                    <TableCell>{row.complaint_summary}</TableCell>
                    <ConfirmDialog
                      text={`Are you sure you want to detach Go Card from ${startCase(
                        toLower(row?.customer_name || 'Unknown Customer'),
                      )}`}
                      onConfirm={() => handleDetachGoCard(row)}
                      isOpen={row.id === detachConfirmId}
                      onClose={() => setDetachConfirmId(false)}
                    />
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {!loading && (
            <Box height="1px">
              <Waypoint onEnter={fetchMoreHandler} />
            </Box>
          )}
          {networkStatus === NetworkStatus.fetchMore && (
            <Box p={2} style={{ fontSize: '16px' }}>
              Loading more appointments
            </Box>
          )}
        </Paper>
      )}
      {!isDesktop && !mergeInProgress && (
        <>
          {loading && networkStatus !== NetworkStatus.fetchMore && <Loading />}
          {rows.length === 0 && !loading && (
            <Box style={{ fontSize: '20px', padding: '1rem' }}>
              There are no appointments for today
            </Box>
          )}
          <>
            {rows.map(row => (
              <Box style={{ paddingBottom: '1rem' }} key={row.id}>
                <Card>
                  <CardHeader
                    avatar={
                      hasAppAccount(row) ? (
                        <CheckCircleIcon style={{ color: 'green' }} />
                      ) : null
                    }
                    title={`${
                      row?.customer_name
                        ? startCase(toLower(row.customer_name))
                        : 'N/A'
                    }`}
                    subheader={`${moment(
                      `${row.appointment_date} ${row.appointment_time}`,
                    ).format('lll')}
                  `}
                    action={
                      !hasGoCardAttached(row) ? (
                        <GoCardScanner
                          ro={row?.ro}
                          appointment={row}
                          refetch={refetch}
                          iconOnly={true}
                          updateOverview={null}
                          startMerge={setShowMergeDialog}
                          mergeCustomers={setMergeCustomers}
                        />
                      ) : null
                    }
                  />
                  <CardContent>
                    <Grid container direction="row">
                      <Grid item xs={8}>
                        {row?.customer_email && (
                          <Box>
                            <Typography variant="caption">
                              <EmailIcon
                                fontSize="small"
                                className={classes.icon}
                              />
                              {row.customer_email}
                            </Typography>
                          </Box>
                        )}
                      </Grid>
                      <Grid item xs={4}>
                        {row?.customer_phone && (
                          <Box>
                            <Typography variant="caption">
                              <SmartphoneIcon
                                fontSize="small"
                                className={classes.icon}
                              />
                              {row.customer_phone}
                            </Typography>
                          </Box>
                        )}
                      </Grid>
                      <Grid item xs={12} style={{ paddingTop: '10px' }}>
                        Vehicle:{' '}
                        {`${row?.ro?.vehicle_year || ''} ${
                          row?.vehicle_make || ''
                        } ${row?.vehicle_model || ''}`}
                      </Grid>
                      <Grid item xs={8}>
                        {cardNumber(row) || 'No Go Card'}
                        {hasGoCardAttached(row) && (
                          <IconButton
                            onClick={() => setDetachConfirmId(row.id)}
                            size="small"
                          >
                            <HighlightOffIcon />
                          </IconButton>
                        )}
                      </Grid>
                      <Grid item xs={4}>
                        {formatPrice(cashBalance(row) || 0, {
                          cents: true,
                          zeroIfNull: true,
                        })}
                      </Grid>
                      <Grid item xs={12} style={{ paddingTop: '10px' }}>
                        Advisor:{' '}
                        {row?.ro?.cdk_service_advisor_display_name || 'N/A'}
                      </Grid>
                      <Grid item xs={12} style={{ paddingTop: '10px' }}>
                        Comments:
                      </Grid>
                      <Grid item xs={12}>
                        {row.complaint_summary}
                      </Grid>
                    </Grid>
                    <ConfirmDialog
                      text={`Are you sure you want to detach Go Card from ${startCase(
                        toLower(row?.customer_name || 'Unknown Customer'),
                      )}`}
                      onConfirm={() => handleDetachGoCard(row)}
                      isOpen={row.id === detachConfirmId}
                      onClose={() => setDetachConfirmId(false)}
                    />
                  </CardContent>
                  {row?.customer && (
                    <CardActions>
                      <Button
                        size="small"
                        style={theme.actions.create}
                        onClick={() => {
                          history.push(
                            `/customers/${row.customer._id}/details`,
                          );
                        }}
                      >
                        Customer Page
                      </Button>
                    </CardActions>
                  )}
                </Card>
                {!loading && (
                  <Box height="1px">
                    <Waypoint onEnter={fetchMoreHandler} />
                  </Box>
                )}
                {networkStatus === NetworkStatus.fetchMore && (
                  <Box p={2} style={{ fontSize: '16px' }}>
                    Loading more appointments
                  </Box>
                )}
              </Box>
            ))}
          </>
        </>
      )}
      <ConfirmDialog
        text="The card you scanned is not currently linked to this profile. Would you like to merge the profiles?"
        onConfirm={handleConfirm}
        onClose={stopMerge}
        isOpen={showMergeDialog}
      />
      {mergeInProgress && <CustomerMerge ids={mergeCustomers} />}
    </>
  );
};

export default AppointmentsTable;
