import moment from 'moment';
import React from 'react';
import gql from 'graphql-tag';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DateRangeIcon from '@mui/icons-material/DateRange';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';

import { AppointmentStatus } from 'constants.js';
import { defaultTZ } from 'utils';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '50px',
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
  },
}));

const MobileAppointment = ({ appointments, updateStatus }) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Accordion defaultExpanded={true}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.title} gutterBottom>
          Appointments
        </Typography>
      </AccordionSummary>
      <AccordionDetails style={{ paddingTop: 0 }}>
        {appointments.length === 0 && (
          <Typography style={{ fontSize: 14 }}>
            There are no appointments for this customer.
          </Typography>
        )}
        <List style={{ paddingTop: 0 }}>
          {appointments.map(appointment => (
            <ListItem style={{ padding: 0 }}>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs={1}>
                  <DateRangeIcon />
                </Grid>
                <Grid item xs={11}>
                  <List dense={true}>
                    <ListItem>
                      <Typography style={{ fontSize: 14 }}>
                        {`${appointment.type_name} @ ${appointment.dealer.dealer_name}`}
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography style={{ fontSize: 14 }}>
                        {`${moment(appointment.start_date)
                          .tz(defaultTZ)
                          .format('LLL')}`}
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography style={{ fontSize: 14 }}>
                        {`Assigned to: ${appointment.attendees?.[0]?.display_name}`}
                      </Typography>
                    </ListItem>
                  </List>
                </Grid>
                <Grid item xs={12}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="flex-start"
                  >
                    <Grid item>
                      <IconButton
                        onClick={() =>
                          updateStatus(
                            appointment._id,
                            AppointmentStatus.ATTENDED,
                          )
                        }
                        size="large"
                      >
                        <CheckIcon style={theme.actions.add} />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <IconButton
                        onClick={() =>
                          updateStatus(
                            appointment._id,
                            AppointmentStatus.NO_SHOW,
                          )
                        }
                        size="large"
                      >
                        <SentimentVeryDissatisfiedIcon
                          style={theme.actions.add}
                        />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <IconButton
                        onClick={() =>
                          updateStatus(
                            appointment._id,
                            AppointmentStatus.CANCELLED,
                          )
                        }
                        size="large"
                      >
                        <CloseIcon style={theme.actions.add} />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </ListItem>
          ))}
        </List>
      </AccordionDetails>
    </Accordion>
  );
};

MobileAppointment.fragments = {
  customer: gql`
    fragment MobileAppointmentFragment on Customer {
      appointments {
        _id
        type_name
        dealer {
          dealer_name
        }
        start_date
        attendees {
          display_name
        }
      }
    }
  `,
};

export default MobileAppointment;
