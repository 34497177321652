import React from 'react';

import ErrorPage from './ErrorPage';
import { useUserContext } from 'components/MaterialUI/UserContext';

const RequiresRoles = ({ renderOnSuccess, requiredRoles, renderOnFailure }) => {
  const {
    currentUser: { role },
  } = useUserContext();
  const elseReturn = renderOnFailure ? (
    renderOnFailure()
  ) : (
    <ErrorPage
      errorMessage={
        'You do not have access to this feature. Please contact Market Platform Support.'
      }
    />
  );

  return role && requiredRoles.includes(role) ? renderOnSuccess() : elseReturn;
};

export default RequiresRoles;
