import moment from 'moment-timezone';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { useUserContext } from 'components/MaterialUI/UserContext';

import { formatPrice } from 'utils';

// List price is calculated here as a convenience (list_price is a calculated
// value ie. a property on the vehicle model) but we'd like to see what it
// will be as we make changes to pricing.  Logic for list_price is as follows:

// if special_price and is_special_price_enabled and special_price_expires < now:
//    list_price = special_price
// else
//    list_price = regular_price

// In app-inventory, the displayed list price uses the special_price EVEN IF
// the expiry date has passed.  I'm not sure why this is, but it seems
// incorrect, so we will apply the above logic here.

// special_price is updated from detailed pricing as follows:
// (NOTE: special_price is a submitted value, so the UI is responsible for
// setting special_price based on detailed pricing)

export const useVehicleListPrice = vehicle => {
  const { control } = useFormContext();
  const { me } = useUserContext();

  const specialPriceExpires = useWatch({
    control,
    name: 'special_price_expires',
    defaultValue: vehicle.special_price_expires,
  });
  const specialPrice = useWatch({
    control,
    name: 'special_price',
    defaultValue: vehicle.special_price,
  });
  const regularPrice = useWatch({
    control,
    name: 'regular_price',
    defaultValue: vehicle.regular_price,
  });
  const isSpecialPriceEnabled = useWatch({
    control,
    name: 'is_special_price_enabled',
    defaultValue: vehicle.is_special_price_enabled,
  });

  const tz =
    me?.goUserProfile?.settings?.timezone ||
    Intl.DateTimeFormat().resolvedOptions().timeZone;

  const specialPriceExpired =
    specialPriceExpires &&
    moment.tz(tz).isAfter(moment.tz(specialPriceExpires, tz));

  const specialPriceFloat = specialPrice ? parseFloat(specialPrice) : 0;
  const regularPriceFloat = regularPrice ? parseFloat(regularPrice) : 0;

  return specialPrice &&
    specialPriceFloat !== 0 &&
    isSpecialPriceEnabled &&
    specialPriceFloat < regularPriceFloat &&
    !specialPriceExpired
    ? specialPriceFloat
    : regularPrice
    ? regularPriceFloat
    : null;
};

const VehicleFormListPrice = ({ vehicle }) => {
  return <>{formatPrice(useVehicleListPrice(vehicle))}</>;
};

export default VehicleFormListPrice;
