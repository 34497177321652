import React from 'react';
import moment from 'moment';
import gql from 'graphql-tag';

import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import EmailIcon from '@mui/icons-material/Email';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import Typography from '@mui/material/Typography';
import { green } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';

import CustomerAvatar from 'components/CustomerAvatar';

const useStyles = makeStyles(theme => ({
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  identity: { backgroundColor: green[400] },
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  avatarCell: {
    whiteSpace: 'pre-line',
    width: '1rem',
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '10px',
    paddingLeft: '16px',
    paddingBottom: '2px',
    paddingTop: '2px',
  },
}));

const identityTypeIcon = identityType => {
  switch (identityType) {
    case 'email':
      return <EmailIcon style={{ fontSize: '0.75rem' }} />;
    case 'sms':
      return <SmartphoneIcon style={{ fontSize: '0.75rem' }} />;
    default:
      return <EmailIcon style={{ fontSize: '0.75rem' }} />;
  }
};

const SearchResultMobile = ({ customer }) => {
  const classes = useStyles();
  // Length starts at 1 while arrays start at 0
  const numVehicles = customer.vehicles.length >= 2 ? 1 : 0;
  // Format location
  const location =
    customer.city && customer.province
      ? `${customer.city}, ${customer.province}`
      : '';

  return (
    <>
      <CardHeader
        avatar={<CustomerAvatar customer={customer} />}
        title={customer.fullname}
        subheader={location}
        style={{ paddingBottom: '5px' }}
      ></CardHeader>
      <CardContent className={classes.cardContent}>
        <Grid container>
          {customer.identities &&
            customer.identities.map(ident => {
              if (ident.identity_type === 'sms') {
                return (
                  <Grid item xs={4} key={ident.identity_value}>
                    <Typography style={{ fontSize: '0.75rem' }}>
                      {identityTypeIcon(ident.identity_type)}{' '}
                      {ident.identity_value}
                    </Typography>
                  </Grid>
                );
              } else {
                return (
                  <Grid item xs={8} key={ident.identity_value}>
                    <Typography style={{ fontSize: '0.75rem' }}>
                      {identityTypeIcon(ident.identity_type)}{' '}
                      {ident.identity_value}
                    </Typography>
                  </Grid>
                );
              }
            })}
        </Grid>
      </CardContent>
      {customer.vehicles &&
        customer.vehicles
          .filter((v, index) => index < 2)
          .map((v, index) => {
            if (index < 2 && index === numVehicles) {
              return (
                <CardContent
                  className={classes.cardContent}
                  style={{ paddingBottom: '5px' }}
                  key={v.vin}
                >
                  <Grid container>
                    <Grid item xs={7}>
                      <Typography style={{ fontSize: '0.75rem' }} align="left">
                        <DriveEtaIcon style={{ fontSize: '0.75rem' }} />
                        {` ${v.year} ${v.make} ${v.model}`}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        style={{ fontSize: '0.75rem', color: 'grey' }}
                        align="left"
                      >
                        {`VIN: ${v.vin}`}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography style={{ fontSize: '0.6rem' }} align="right">
                        Created: {moment(customer.created).format('LL')}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              );
            } else {
              return (
                <CardContent
                  className={classes.cardContent}
                  style={{ paddingBottom: '5px' }}
                  key={v.vin}
                >
                  <Grid container>
                    <Grid item xs={7}>
                      <Typography style={{ fontSize: '0.75rem' }} align="left">
                        <DriveEtaIcon style={{ fontSize: '0.75rem' }} />
                        {` ${v.year} ${v.make} ${v.model}`}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        style={{ fontSize: '0.75rem', color: 'grey' }}
                        align="left"
                      >
                        {`VIN: ${v.vin}`}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              );
            }
          })}
      {customer.vehicles.length === 0 && (
        <CardContent
          className={classes.cardContent}
          style={{ paddingBottom: '5px' }}
        >
          <Grid container>
            <Grid item xs={12}>
              <Typography style={{ fontSize: '0.6rem' }} align="right">
                Created: {moment(customer.created).format('LL')}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      )}
    </>
  );
};

SearchResultMobile.fragments = {
  customer: gql`
    fragment SearchResultFragment on Customer {
      _id
      fullname
      first_name
      last_name
      city
      province
      created
      identities {
        identity_type
        identity_value
      }
      vehicles {
        year
        make
        model
        vin
      }
      ...CustomerAvatarCustomer
    }
    ${CustomerAvatar.fragments.customer}
  `,
};

export default SearchResultMobile;
