import { useMutation, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material';
import Alert from '@mui/material/Alert';

import { useDealerContext } from 'components/MaterialUI/DealerContext';
import DealerPicker from 'components/MaterialUI/DealerPicker';
import SelectControl from 'components/MaterialUI/SelectControl2';
import { useUserContext } from 'components/MaterialUI/UserContext';

import { Role } from 'constants.js';

const LEAD_CHANNELS_INBOUND = [
  'Inbound Chat',
  'Inbound Email',
  'Inbound Event',
  'Inbound Phone',
  'Inbound Service',
  'Inbound Text',
  'Inbound Social',
  'Inbound Web',
  'Inbound Walk',
];

const LEAD_CHANNELS_OUTBOUND = [
  'Outbound Email',
  'Outbound Phone',
  'Outbound Text',
];

const DEFAULT_LEAD_SOURCES = [
  '!Test',
  '3rd Party Credit Lead',
  'Alert',
  'Auto Trader',
  'Autocatch',
  'Canada Drives',
  'Car Cost Canada',
  'Car Gurus',
  'Car Loans Canada',
  'Dealer Website',
  'Edmonton Journal',
  'Edmonton Sun',
  'Facebook',
  'Go Auto Credit Site',
  'GoAuto.ca',
  'Happy to Help',
  'Kijiji',
  'Location',
  'OEM Lead',
  'Referral - Customer',
  'Referral - Staff',
  'Repeat Customer',
  'Revenue Radar/Dealer Socket',
  'Unhaggle',
  'Used Cars Canada',
  'Wheels.ca',
];

const DETAILS_QUERY = gql`
  query customerDetails($customer_id: ID!) {
    customer(_id: $customer_id) {
      _id
      fullname
      opportunities(statuses: [6, 7, 8, 10, 11]) {
        _id
      }
    }
  }
`;

const CREATE_OPPORTUNITY_MUTATION = gql`
  mutation createOpportunity($input: CreateOpportunityInput!) {
    createOpportunity(input: $input) {
      _id
      dealer {
        dealer_name
        dealer_id
      }
      status_display
      status
      crm_lead_ids
      marketing {
        lead_source
        lead_channel
        lead_direction
      }
      sales_reps {
        display_name
      }
      finance_managers {
        display_name
      }
      sales_managers {
        display_name
      }
      stock_type
      created
      updated
      last_status_change
      dms_deal {
        vin
        deal_type
        deal_number
        stock_number
        year
        make_name
        model_name
      }
    }
  }
`;

const ORGANIZATION_LEAD_SOURCES = gql`
  query getOrgLeadSources($organization_id: ID!) {
    getOrganizationLeadSources(organization_id: $organization_id) {
      results
    }
  }
`;

const STOCK_TYPES = ['New', 'Used'];

const AddOpportunityModal = ({ customer, handleClose }) => {
  const desktop = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { currentUser } = useUserContext();
  const { dealerId } = useDealerContext();
  const { control, handleSubmit } = useForm();
  const [createOpportunityMutation, { loading }] = useMutation(
    CREATE_OPPORTUNITY_MUTATION,
    {
      onCompleted: data => {
        history.push(
          `/opportunities/${data.createOpportunity._id}/${customer._id}/details`,
        );
      },
      onError: e =>
        enqueueSnackbar(`Error in creating opportunity`, {
          variant: 'error',
        }),
      // To avoid refetching an entire customer manually add to cache
      update(cache, { data }) {
        const cachedQuery = cache.readQuery({
          query: DETAILS_QUERY,
          variables: { customer_id: customer._id },
        });
        cachedQuery.customer.allOpps = [
          data.createOpportunity,
          ...cachedQuery.customer.allOpps,
        ];
        cachedQuery.customer.allOppsEverywhere = [
          data.createOpportunity,
          ...cachedQuery.customer.allOpps,
        ];
        cache.writeQuery({
          query: DETAILS_QUERY,
          variables: { customer_id: customer._id },
          data: cachedQuery,
        });
      },
    },
  );
  const history = useHistory();

  const { data: orgLeadSources, loading: orgLeadSourcesLoading } = useQuery(
    ORGANIZATION_LEAD_SOURCES,
    {
      variables: {
        organization_id: currentUser.organization_id,
      },
    },
  );

  const hasOpenOpp = customer.opportunities.length > 0;

  const onSubmit = formValue => {
    const [direction, channel] = formValue.lead_channel.split(' ');
    const payload = {
      input: {
        dealer_id: dealerId,
        customer_id: customer._id,
        marketing: {
          lead_source: formValue.lead_source,
          lead_direction: direction.toLowerCase(),
          lead_channel: channel === 'Text' ? 'sms' : channel.toLowerCase(),
        },
        stock_type: formValue.stock_type,
        ...(Role.ALL_BDC.includes(currentUser.role)
          ? { bdc_reps: [currentUser.username] }
          : { sales_reps: [currentUser.username] }),
      },
    };

    createOpportunityMutation({ variables: payload });
  };

  return (
    <>
      {hasOpenOpp && (
        <Alert severity="warning">
          Customer already has an open opportunity. Are you sure you want to
          create another one?
        </Alert>
      )}
      <DialogTitle style={{ whiteSpace: 'pre-line' }}>
        {`Create a New Opportunity for${desktop ? '\n' : ' '}${
          customer.fullname
        }`}
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent
          style={{ padding: '8px 20px 8px 20px', overflowY: 'hidden' }}
        >
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={3}
          >
            <Grid item>
              <DealerPicker style={{ width: '100%' }} variant="standard" />
            </Grid>
            <Grid item>
              <SelectControl
                control={control}
                InputLabelProps={{ shrink: true }}
                label="Lead Channel"
                name="lead_channel"
                noNull
                options={LEAD_CHANNELS_INBOUND.concat(
                  LEAD_CHANNELS_OUTBOUND,
                ).map(x => ({ value: x, name: x }))}
                style={{ width: '100%' }}
              />
            </Grid>
            <Grid item>
              <SelectControl
                control={control}
                InputLabelProps={{ shrink: true }}
                readOnly={orgLeadSourcesLoading}
                label="Lead Source"
                name="lead_source"
                noNull
                options={(orgLeadSources?.getOrganizationLeadSources?.results
                  .length
                  ? orgLeadSources.getOrganizationLeadSources.results
                  : DEFAULT_LEAD_SOURCES
                ).map(x => ({ value: x, name: x }))}
                style={{ width: '100%' }}
              />
            </Grid>
            <Grid item>
              <SelectControl
                control={control}
                defaultValue="New"
                InputLabelProps={{ shrink: true }}
                label="Deal Type"
                name="stock_type"
                noNull
                options={STOCK_TYPES.map(x => ({ value: x, name: x }))}
                style={{ width: '100%' }}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="outlined">
            Cancel
          </Button>
          <Button
            style={theme.actions.confirm}
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading}
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </>
  );
};

export default AddOpportunityModal;
