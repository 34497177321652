import { Status } from '../../../constants';
import { formatPercent, formatPrice } from '../../../utils';
import {
  getBackEndGross,
  getDmsDealBackEndGross,
  getDmsDealFrontEndGross,
  getDmsDealTotalGross,
  getFrontEndGross,
  getSalesDealBackGrossValue,
  getSalesDealFrontGrossValue,
} from './Metrics/metrics';
import { useMutation, useQuery } from '@apollo/react-hooks';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Loading from 'components/MaterialUI/Loading';
import { useUserContext } from 'components/MaterialUI/UserContext';
import gql from 'graphql-tag';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React from 'react';

const OPPORTUNITY_GROSS_PROFIT = gql`
  query opportunityGrossProfit($_id: ID!) {
    opportunity(_id: $_id) {
      gross_profit {
        buyRateAPR
        commCost
        costAdjustmentsAmount
        costAdjustmentsDealerGross
        dealerCost
        feesAmount
        feesDealerGross
        financingAmount
        financingDealerGross
        frontWeOweFeesAmount
        frontWeOweFeesDealerGross
        insMbiServContAmount
        insMbiServContDealerGross
        leinHolderName
        reserveMethod
        saleAmount
        saleDealerGross
        sellRateAPR
        totalBackGross
        totalComm
        totalDealerGross
        totalDealerNet
        totalDealerNetPercent
        totalFrontGross
        trade1ACV
        trade1Allow
        trade2ACV
        trade2Allow
      }
    }
  }
`;

const UPDATE_OPP_STATUSES = gql`
  mutation updateOppStatuses(
    $id: ID!
    $statuses: UpdateOpportunityStatusInput!
  ) {
    updateOpportunityStatus(_id: $id, input: $statuses) {
      _id
      status
      sub_status
    }
  }
`;

const UPDATE_ACCOUNTING_DEAL = gql`
  mutation updateAccountingDeal(
    $id: ID!
    $field_name: String!
    $input: DealDataInput!
  ) {
    updateDealDataOnOpportunity(
      _id: $id
      field_name: $field_name
      input: $input
    ) {
      _id
      accounting_deal {
        frontend_gross {
          value
          updated_by
          updated
        }
        backend_gross {
          value
          updated_by
          updated
        }
        comment {
          content
          updated
          updated_by
        }
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  computerIcon: {
    color: 'primary',
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bolder',
    padding: '15px 15px 0px 15px',
  },
  salesAndAccountingGrid: {
    padding: '0px 15px 15px 15px',
  },
  gridAxis: {
    padding: theme.spacing(2, 2, 2, 2),
    textAlign: 'left',
    border: '1px',
    borderStyle: 'solid',
    borderColor: '#e0e0e0',
    borderRadius: '0px',
    boxShadow: 'none',
    fontSize: '16px',
    fontWeight: '600',
    wordWrap: 'break-word',
  },
  gridCells: {
    padding: theme.spacing(2, 2, 2, 2),
    textAlign: 'left',
    border: '1px',
    borderStyle: 'solid',
    borderColor: '#e0e0e0',

    borderRadius: '0px',
    boxShadow: 'none',
    fontSize: '16px',
    wordWrap: 'break-word',
  },
  dealBreakdownCellKey: {
    padding: theme.spacing(2, 0, 0, 2),
    textAlign: 'left',
    boxShadow: 'none',
    fontSize: '16px',
  },
  dealBreakdownCellValue: {
    padding: theme.spacing(2, 2, 0, 0),
    textAlign: 'left',
    boxShadow: 'none',
    fontSize: '16px',
    fontWeight: '600',
  },
  centerText: {
    textAlign: 'center',
  },
  blackDivider: {
    backgroundColor: 'black',
    padding: theme.spacing(0.25),
    margin: theme.spacing(4, 0, 4, 0),
  },
  normalDivider: {
    opacity: 0,
    margin: theme.spacing(2, 0, 0, 0),
    backgroundColor: 'black',
  },
  placeholder: {
    opacity: 0,
  },
  grossTotalCell: {
    textAlign: 'center',
    borderStyle: 'solid',
    borderColor: '#707070',
    borderRadius: '0px',
    boxShadow: 'none',
  },
  grossTotalHeader: {
    fontSize: 15,
    fontWeight: 'normal !important',
    wordWrap: 'break-word',
    paddingBottom: '5px',
  },
  grossTotalAmount: {
    fontSize: 20,
    fontWeight: 'bold',
    wordWrap: 'break-word',
  },
  dlrGrossAndAmountSubHeading: {
    fontSize: 16,
    wordWrap: 'break-word',
    color: '#707070',
  },
  dlrGrossAndAmountValue: {
    fontSize: 20,
    wordWrap: 'break-word',
  },
  dlrGrossAndAmountCells: {
    padding: theme.spacing(2, 2, 2, 2),
    border: '1px',
    textAlign: 'center',
    boxShadow: 'none',
    fontSize: '16px',
    fontWeight: '600',
    backgroundColor: '#EEEEEE',
    wordWrap: 'break-word',
  },
  dlrGrossAndAmountCellStyle: {
    boxShadow: 'none',
    borderRadius: '0px',
    textAlign: 'center',
    paddingBottom: '10px',
    fontSize: '14px',
  },
  closeButton: {
    fontWeight: 'bolder',
    fontSize: 16,
    color: '#24a0ed',
  },
  loss: {
    color: 'red',
  },
  accountingCommentUpdatedDetails: {
    paddingTop: '5px',
    boxShadow: 'none',
    borderRadius: '0px',
    fontSize: 16,
    wordWrap: 'break-word',
    color: '#707070',
    fontWeight: '400',
  },
}));

const GrossProfitModal = ({ opportunity, handleClose, includeGross }) => {
  const { currentUser } = useUserContext();
  const isDesktop = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const username = currentUser.username;
  const hasPermissions = currentUser.dealer_ids.includes(opportunity.dealer_id);
  const isPosted = opportunity.status === Status.POSTED;
  const { data, loading } = useQuery(OPPORTUNITY_GROSS_PROFIT, {
    variables: { _id: opportunity._id },
    skip: !includeGross,
  });

  const grossProfit = data?.opportunity?.gross_profit ?? {};

  const useSalesData = () => getDmsDealTotalGross(opportunity) === 0;

  const getSalesDealFrontGross = useSalesData()
    ? getSalesDealFrontGrossValue(opportunity)
    : getDmsDealFrontEndGross(opportunity);

  const getSalesDealBackGross = useSalesData()
    ? getSalesDealBackGrossValue(opportunity)
    : getDmsDealBackEndGross(opportunity);

  const getAccountingDealFrontGrossValue = () =>
    opportunity?.accounting_deal?.frontend_gross?.value || 0;

  const getAccountingDealFrontGrossValueUpdated = () =>
    opportunity?.accounting_deal?.frontend_gross?.updated || '';

  const getAccountingDealFrontGrossValueUpdatedBy = () =>
    opportunity?.accounting_deal?.frontend_gross?.updated_by || '';

  const getAccountingDealBackGrossValue = () =>
    opportunity?.accounting_deal?.backend_gross?.value || 0;

  const getAccountingDealBackGrossValueUpdated = () =>
    opportunity?.accounting_deal?.backend_gross?.updated || '';

  const getAccountingDealBackGrossValueUpdatedBy = () =>
    opportunity?.accounting_deal?.backend_gross?.updated_by || '';

  const getAccountingDealComment = () =>
    opportunity?.accounting_deal?.comment?.content || '';

  const getAccountingDealCommentUpdatedBy = () =>
    opportunity?.accounting_deal?.comment?.updated_by || '';

  const getAccountingDealCommentUpdated = () =>
    opportunity?.accounting_deal?.comment?.updated || '';

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [updateStatus] = useMutation(UPDATE_OPP_STATUSES);
  const [updateAccountingDeal] = useMutation(UPDATE_ACCOUNTING_DEAL);
  const [values, setValues] = React.useState({
    frontend_gross: getAccountingDealFrontGrossValue(),
    frontend_gross_updated: getAccountingDealFrontGrossValueUpdated(),
    frontend_gross_updated_by: getAccountingDealFrontGrossValueUpdatedBy(),

    backend_gross: getAccountingDealBackGrossValue(),
    backend_gross_updated: getAccountingDealBackGrossValueUpdated(),
    backend_gross_updated_by: getAccountingDealBackGrossValueUpdatedBy(),

    comment: getAccountingDealComment(),
    comment_updated: getAccountingDealCommentUpdated(),
    comment_updated_by: getAccountingDealCommentUpdatedBy(),
  });

  const postDeal = () => {
    if (!isPosted && hasPermissions) {
      updateStatus({
        variables: {
          id: opportunity._id,
          statuses: {
            status: Status.POSTED,
            sub_status: opportunity.sub_status,
          },
        },
      })
        .then(r => {
          enqueueSnackbar(
            `Deal #${opportunity.dms_deal.deal_number} has been posted successfully`,
          );
        })
        .catch(e => {
          enqueueSnackbar(
            `Error in posting Deal# ${opportunity.dms_deal.deal_number}`,
            {
              variant: 'error',
            },
          );
        });
    }
  };

  const updatedAccountingGrossData = (prop, value) => {
    const isComment = prop === 'comment';
    const input = {
      [prop]: {
        [isComment ? 'content' : 'value']: isComment
          ? value
          : parseFloat(value),
        updated_by: username,
      },
    };
    updateAccountingDeal({
      variables: {
        id: opportunity._id,
        field_name: 'accounting_deal',
        input: input,
      },
    })
      .then(r => {
        const accountingDeal =
          r.data.updateDealDataOnOpportunity.accounting_deal;
        setValues({
          ...values,
          [prop]: value,
          [prop.concat('_updated')]: accountingDeal[prop].updated,
          [prop.concat('_updated_by')]: username,
        });
        enqueueSnackbar(
          `Changes to accounting ${prop.replace('_', ' ')} saved successfully`,
        );
      })
      .catch(e => {
        enqueueSnackbar(
          `Error in saving to accounting ${prop.replace('_', ' ')}`,
          {
            variant: 'error',
          },
        );
      });
  };
  const handleChange = prop => event => {
    if (event.target.value.replace(/\n/g, '') === values[prop]) {
      return;
    }
    setValues({
      ...values,
      [prop]: event.target.value,
    });
  };

  const commentFormatter = key =>
    moment(values[key.concat('_updated')]).isValid()
      ? `${values[key.concat('_updated_by')]} ${moment(
          values[key.concat('_updated')],
        ).format('LLL')}`
      : '';

  if (loading) {
    return (
      <DialogContent>
        <Loading />
      </DialogContent>
    );
  }
  return (
    <div>
      <DialogContent>
        <Box className={classes.salesAndAccountingGrid}>
          {!isDesktop && (
            <>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h6">Sales/Finance</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container direction="row">
                    <Grid item xs={6}>
                      <Box>Front Gross</Box> <Box>Back Gross</Box> <hr />
                      <Box>Total Gross</Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box textAlign="right">
                        {formatPrice(getSalesDealFrontGross, { cents: true })}
                      </Box>
                      <Box textAlign="right">
                        {formatPrice(getSalesDealBackGross, { cents: true })}
                      </Box>
                      <hr />
                      <Box textAlign="right">
                        {formatPrice(
                          +getSalesDealFrontGross + +getSalesDealBackGross,
                          { cents: true },
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h6">Accounting</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container direction="row">
                    <Grid item xs={6}>
                      <Box style={{ padding: '0.5rem 0' }}>Front Gross</Box>{' '}
                      <Box style={{ paddingTop: '0.5rem' }}>Back Gross</Box>{' '}
                      <hr />
                      <Box style={{ paddingTop: '0.5rem' }}>
                        Total Gross
                      </Box>{' '}
                      <Box style={{ paddingTop: '0.5rem' }}>Comments</Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box textAlign="right">
                        <Tooltip
                          title={`${commentFormatter('frontend_gross')}`}
                        >
                          <Input
                            type="number"
                            disabled={!hasPermissions}
                            value={values.frontend_gross}
                            onChange={handleChange('frontend_gross')}
                            onBlur={() =>
                              updatedAccountingGrossData(
                                'frontend_gross',
                                values.frontend_gross,
                              )
                            }
                            onKeyDown={event => {
                              if (
                                event.key === 'Enter' ||
                                event.key === 'Tab'
                              ) {
                                updatedAccountingGrossData(
                                  'frontend_gross',
                                  event.target.value,
                                );
                              }
                            }}
                            startAdornment={
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            }
                          />
                        </Tooltip>
                      </Box>
                      <Box textAlign="right">
                        <Tooltip title={`${commentFormatter('backend_gross')}`}>
                          <Input
                            type="number"
                            disabled={!hasPermissions}
                            value={values.backend_gross}
                            onChange={handleChange('backend_gross')}
                            onBlur={() =>
                              updatedAccountingGrossData(
                                'backend_gross',
                                values.backend_gross,
                              )
                            }
                            onKeyDown={event => {
                              if (
                                event.key === 'Enter' ||
                                event.key === 'Tab'
                              ) {
                                updatedAccountingGrossData(
                                  'backend_gross',
                                  event.target.value,
                                );
                              }
                            }}
                            startAdornment={
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            }
                          />
                        </Tooltip>
                      </Box>
                      <hr />
                      <Box textAlign="right">
                        <Input
                          value={`${
                            +values.backend_gross + +values.frontend_gross
                          }`}
                          startAdornment={
                            <InputAdornment position="start">$</InputAdornment>
                          }
                        />
                      </Box>
                      <Box>
                        <Input
                          style={{ width: '100%' }}
                          multiline
                          fullWidth
                          maxRows={2}
                          value={values.comment}
                          onBlur={() =>
                            updatedAccountingGrossData(
                              'comment',
                              values.comment,
                            )
                          }
                          onKeyDown={event => {
                            if (event.key === 'Enter' || event.key === 'Tab') {
                              updatedAccountingGrossData(
                                'comment',
                                event.target.value,
                              );
                            }
                          }}
                          onChange={handleChange('comment')}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <Grid container alignItems="flex-start" spacing={0}>
                <Grid style={{ fontSize: '14px' }} item xs={8}>
                  <Box style={{ paddingTop: '1rem', paddingBottom: '0.5rem' }}>
                    1. Fin. Inst.
                  </Box>
                  <Box pb="0.5rem">2. Buy Rate</Box>
                  <Box pb="0.5rem">3. Reserve Method</Box>
                  <Box pb="0.5rem">Sell Rate</Box>
                  <Box pb="0.5rem">4. Dealer Cost</Box>
                  <Box pb="0.5rem">5. Comm Cost</Box>
                  <Box pb="0.5rem">6. Comm Pack</Box>
                  <Box pb="0.5rem">7. Trade1 Allow</Box>
                  <Box pb="0.5rem">Trade1 ACV</Box>
                  <Box pb="0.5rem">8. Trade2 Allow</Box>
                  <Box pb="0.5rem">Trade2 ACV</Box>
                </Grid>
                <Grid style={{ fontSize: '14px' }} item xs={4}>
                  <Box
                    style={{
                      textAlign: 'right',
                      paddingTop: '1rem',
                      paddingBottom: '0.5rem',
                    }}
                  >
                    {grossProfit.leinHolderName || '-'}
                  </Box>
                  <Box textAlign="right" pb="0.5rem">{`${formatPercent(
                    grossProfit.buyRateAPR,
                  )} APR`}</Box>
                  <Box textAlign="right" pb="0.5rem">
                    {formatPrice(grossProfit.reserveMethod)}
                  </Box>
                  <Box textAlign="right" pb="0.5rem">{`${formatPercent(
                    grossProfit.sellRateAPR,
                  )} APR`}</Box>
                  <Box textAlign="right" pb="0.5rem">
                    {formatPrice(grossProfit.dealerCost)}
                  </Box>
                  <Box textAlign="right" pb="0.5rem">
                    {formatPrice(grossProfit.commCost)}
                  </Box>
                  <Box textAlign="right" pb="0.5rem">
                    {formatPrice(grossProfit.commPack)}
                  </Box>
                  <Box textAlign="right" pb="0.5rem">
                    {formatPrice(grossProfit.trade1Allow)}
                  </Box>
                  <Box textAlign="right" pb="0.5rem">
                    {formatPrice(grossProfit.trade1ACV)}
                  </Box>
                  <Box textAlign="right" pb="0.5rem">
                    {formatPrice(grossProfit.trade2Allow)}
                  </Box>
                  <Box textAlign="right" pb="0.5rem">
                    {formatPrice(grossProfit.trade2ACV)}
                  </Box>
                </Grid>
              </Grid>
            </>
          )}
          {isDesktop && (
            <Grid container direction="row" spacing={0} alignItems="stretch">
              <Grid item xs={2}>
                <Paper
                  className={classes.gridAxis}
                  style={{ borderRight: '0' }}
                >
                  <Box className={classes.placeholder}>Index</Box>
                </Paper>
              </Grid>
              <Grid item xs={2}>
                <Paper
                  className={classes.gridAxis}
                  style={{ borderLeft: '0', borderRight: '0' }}
                >
                  Front Gross
                </Paper>
              </Grid>
              <Grid item xs={2}>
                <Paper
                  className={classes.gridAxis}
                  style={{ borderLeft: '0', borderRight: '0' }}
                >
                  Back Gross
                </Paper>
              </Grid>
              <Grid item xs={2}>
                <Paper
                  className={classes.gridAxis}
                  style={{ borderLeft: '0', borderRight: '0' }}
                >
                  Total Gross
                </Paper>
              </Grid>
              <Grid item xs={4}>
                <Paper className={classes.gridAxis} style={{ borderLeft: '0' }}>
                  Comments
                </Paper>
              </Grid>
              <Grid item xs={2}>
                <Paper
                  className={classes.gridAxis}
                  style={{ borderRight: '0' }}
                >
                  <span
                    style={{ fontWeight: useSalesData ? 'bold' : 'normal!' }}
                  >
                    Sales
                  </span>{' '}
                  /{' '}
                  <span
                    style={{ fontWeight: useSalesData ? 'normal' : 'bold' }}
                  >
                    Finance
                  </span>
                </Paper>
              </Grid>
              <Grid item xs={2}>
                <Paper
                  className={classes.gridCells}
                  style={{ borderLeft: '0', borderRight: '0' }}
                >
                  {formatPrice(getSalesDealFrontGross, { cents: true })}
                </Paper>
              </Grid>
              <Grid item xs={2}>
                <Paper
                  className={classes.gridCells}
                  style={{ borderLeft: '0', borderRight: '0' }}
                >
                  {formatPrice(getSalesDealBackGross, { cents: true })}
                </Paper>
              </Grid>
              <Grid item xs={2}>
                <Paper
                  className={classes.gridCells}
                  style={{ borderLeft: '0', borderRight: '0' }}
                >
                  {formatPrice(
                    +getSalesDealFrontGross + +getSalesDealBackGross,
                    { cents: true },
                  )}
                </Paper>
              </Grid>
              <Grid item xs={4}>
                <Paper className={classes.gridAxis} style={{ borderLeft: '0' }}>
                  <Box className={classes.placeholder}>Placeholder</Box>
                </Paper>
              </Grid>
              <Grid item xs={2}>
                <Paper
                  className={classes.gridAxis}
                  style={{ height: '100%', borderRight: '0' }}
                >
                  <Grid
                    container
                    spacing={0}
                    direction="column"
                    justifyContent="center"
                    style={{ minHeight: '5vh' }}
                  >
                    <Grid item>Accounting</Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={2}>
                <Paper
                  className={classes.gridAxis}
                  style={{ height: '100%', borderLeft: '0', borderRight: '0' }}
                >
                  <Grid
                    container
                    spacing={0}
                    direction="column"
                    justifyContent="center"
                    style={{ minHeight: '5vh' }}
                  >
                    <Grid item>
                      <Tooltip title={`${commentFormatter('frontend_gross')}`}>
                        <Input
                          type="number"
                          disabled={!hasPermissions}
                          value={values.frontend_gross}
                          onChange={handleChange('frontend_gross')}
                          onBlur={() =>
                            updatedAccountingGrossData(
                              'frontend_gross',
                              values.frontend_gross,
                            )
                          }
                          onKeyDown={event => {
                            if (event.key === 'Enter' || event.key === 'Tab') {
                              updatedAccountingGrossData(
                                'frontend_gross',
                                event.target.value,
                              );
                            }
                          }}
                          startAdornment={
                            <InputAdornment position="start">$</InputAdornment>
                          }
                        />
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={2}>
                <Paper
                  className={classes.gridAxis}
                  style={{ height: '100%', borderLeft: '0', borderRight: '0' }}
                >
                  <Grid
                    container
                    spacing={0}
                    direction="column"
                    justifyContent="center"
                    style={{ minHeight: '5vh' }}
                  >
                    <Grid item>
                      <Tooltip title={`${commentFormatter('backend_gross')}`}>
                        <Input
                          type="number"
                          disabled={!hasPermissions}
                          value={values.backend_gross}
                          onChange={handleChange('backend_gross')}
                          onBlur={() =>
                            updatedAccountingGrossData(
                              'backend_gross',
                              values.backend_gross,
                            )
                          }
                          onKeyDown={event => {
                            if (event.key === 'Enter' || event.key === 'Tab') {
                              updatedAccountingGrossData(
                                'backend_gross',
                                event.target.value,
                              );
                            }
                          }}
                          startAdornment={
                            <InputAdornment position="start">$</InputAdornment>
                          }
                        />
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={2}>
                <Paper
                  className={classes.gridCells}
                  style={{ height: '100%', borderLeft: '0', borderRight: '0' }}
                >
                  <Grid
                    container
                    spacing={0}
                    direction="column"
                    justifyContent="center"
                    style={{ minHeight: '5vh' }}
                  >
                    <Grid item>
                      <Input
                        value={`${
                          +values.backend_gross + +values.frontend_gross
                        }`}
                        startAdornment={
                          <InputAdornment position="start">$</InputAdornment>
                        }
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={4}>
                <Paper
                  className={classes.gridAxis}
                  style={{
                    height: '100%',
                    paddingTop: '2.5vh',
                    borderLeft: '0',
                  }}
                >
                  <Grid
                    container
                    direction="row"
                    spacing={0}
                    justifyContent="space-between"
                  >
                    <Grid item xs={8}>
                      <Input
                        style={{ width: '100%' }}
                        multiline
                        fullWidth
                        maxRows={2}
                        value={values.comment}
                        onBlur={() =>
                          updatedAccountingGrossData('comment', values.comment)
                        }
                        onKeyDown={event => {
                          if (event.key === 'Enter' || event.key === 'Tab') {
                            updatedAccountingGrossData(
                              'comment',
                              event.target.value,
                            );
                          }
                        }}
                        onChange={handleChange('comment')}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      {!isPosted &&
                        Boolean(
                          +values.frontend_gross || +values.backend_gross,
                        ) && (
                          <Button
                            variant="contained"
                            style={{
                              backgroundColor: '#228B22',
                              color: 'white',
                            }}
                            onClick={postDeal}
                          >
                            Post Deal
                          </Button>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                      <Paper
                        className={`${classes.accountingCommentUpdatedDetails}`}
                      >
                        {commentFormatter('comment')}
                      </Paper>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          )}

          <Divider className={classes.normalDivider} />

          {isDesktop && (
            <Grid container alignItems="flex-start" spacing={0}>
              <Grid item xs={1}>
                <Paper className={classes.dealBreakdownCellKey}>
                  1. Fin. Inst.
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper
                  className={classes.dealBreakdownCellValue}
                  style={{ textAlign: 'center', paddingLeft: '10px' }}
                >
                  {grossProfit.leinHolderName}
                </Paper>
              </Grid>
              <Grid item xs={2}>
                <Paper className={classes.dealBreakdownCellKey}>
                  4. Dealer Cost
                </Paper>
              </Grid>
              <Grid item xs={2}>
                <Paper className={classes.dealBreakdownCellValue}>
                  {formatPrice(grossProfit.dealerCost)}
                </Paper>
              </Grid>
              <Grid item xs={2}>
                <Paper className={classes.dealBreakdownCellKey}>
                  7. Trade1 Allow
                </Paper>
              </Grid>
              <Grid item xs={2}>
                <Paper className={classes.dealBreakdownCellValue}>
                  {formatPrice(grossProfit.trade1Allow)}
                </Paper>
              </Grid>
              <Grid item xs={2}>
                <Paper className={classes.dealBreakdownCellKey}>
                  2. Buy Rate
                </Paper>
              </Grid>
              <Grid item xs={2}>
                <Paper className={classes.dealBreakdownCellValue}>
                  {`${formatPercent(grossProfit.buyRateAPR)} APR`}
                </Paper>
              </Grid>
              <Grid item xs={2}>
                <Paper className={classes.dealBreakdownCellKey}>
                  5. Comm Cost
                </Paper>
              </Grid>
              <Grid item xs={2}>
                <Paper className={classes.dealBreakdownCellValue}>
                  {formatPrice(grossProfit.commCost)}
                </Paper>
              </Grid>
              <Grid item xs={2}>
                <Paper className={classes.dealBreakdownCellKey}>
                  Trade1 ACV
                </Paper>
              </Grid>
              <Grid item xs={2}>
                <Paper className={classes.dealBreakdownCellValue}>
                  {formatPrice(grossProfit.trade1ACV)}
                </Paper>
              </Grid>
              <Grid item xs={2}>
                <Paper className={classes.dealBreakdownCellKey}>
                  3. Reserve Method
                </Paper>
              </Grid>
              <Grid item xs={2}>
                <Paper className={classes.dealBreakdownCellValue}>
                  {formatPrice(grossProfit.reserveMethod)}
                </Paper>
              </Grid>
              <Grid item xs={2}>
                <Paper className={classes.dealBreakdownCellKey}>
                  6. Comm Pack
                </Paper>
              </Grid>
              <Grid item xs={2}>
                <Paper className={classes.dealBreakdownCellValue}>
                  {formatPrice(grossProfit.commPack)}
                </Paper>
              </Grid>
              <Grid item xs={2}>
                <Paper className={classes.dealBreakdownCellKey}>
                  8. Trade2 Allow
                </Paper>
              </Grid>
              <Grid item xs={2}>
                <Paper className={classes.dealBreakdownCellValue}>
                  {formatPrice(grossProfit.trade2Allow)}
                </Paper>
              </Grid>
              <Grid item xs={2}>
                <Paper className={classes.dealBreakdownCellKey}>
                  Sell Rate
                </Paper>
              </Grid>
              <Grid item xs={2}>
                <Paper className={classes.dealBreakdownCellValue}>
                  {`${formatPercent(grossProfit.sellRateAPR)} APR`}
                </Paper>
              </Grid>
              <Grid item xs={2}>
                <Paper
                  className={`${classes.dealBreakdownCellValue} ${classes.placeholder}`}
                >
                  {' '}
                </Paper>
              </Grid>
              <Grid item xs={2}>
                <Paper
                  className={`${classes.dealBreakdownCellValue} ${classes.placeholder}`}
                >
                  {' '}
                </Paper>
              </Grid>
              <Grid item xs={2}>
                <Paper className={classes.dealBreakdownCellKey}>
                  Trade2 ACV
                </Paper>
              </Grid>
              <Grid item xs={2}>
                <Paper className={classes.dealBreakdownCellValue}>
                  {formatPrice(grossProfit.trade2ACV)}
                </Paper>
              </Grid>
            </Grid>
          )}

          <Divider className={classes.blackDivider} />

          <Grid container spacing={2}>
            <Grid
              item
              xs={4}
              style={{ minWidth: `${isDesktop ? '300px' : '100%'}` }}
            >
              <Paper className={`${classes.gridAxis} ${classes.centerText}`}>
                <Box className={classes.grossTotalHeader}>
                  Total Front End Gross
                </Box>
                <Box className={classes.grossTotalAmount}>
                  {formatPrice(getFrontEndGross(opportunity), { cents: true })}
                </Box>
              </Paper>
            </Grid>
            <Grid
              item
              xs={4}
              style={{ minWidth: `${isDesktop ? '300px' : '100%'}` }}
            >
              <Paper className={`${classes.gridAxis} ${classes.centerText}`}>
                <Box className={classes.grossTotalHeader}>
                  Total Back End Gross
                </Box>
                <Box className={classes.grossTotalAmount}>
                  {formatPrice(getBackEndGross(opportunity), { cents: true })}
                </Box>
              </Paper>
            </Grid>
            <Grid
              item
              xs={4}
              style={{ minWidth: `${isDesktop ? '300px' : '100%'}` }}
            >
              <Paper className={`${classes.gridAxis} ${classes.centerText}`}>
                <Box className={classes.grossTotalHeader}>
                  Total Dealer Gross
                </Box>
                <Box className={classes.grossTotalAmount}>
                  {formatPrice(
                    getFrontEndGross(opportunity) +
                      getBackEndGross(opportunity),
                    {
                      cents: true,
                    },
                  )}
                </Box>
              </Paper>
            </Grid>
          </Grid>

          <Divider className={classes.normalDivider} />

          <Grid container spacing={2}>
            <Grid
              item
              xs={4}
              style={{ minWidth: `${isDesktop ? '300px' : '100%'}` }}
            >
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Paper className={classes.dlrGrossAndAmountCells}>
                    Sale of Vehicle
                  </Paper>
                </Grid>
                <Grid item xs={6}>
                  <Paper
                    className={`${classes.dlrGrossAndAmountSubHeading} ${classes.dlrGrossAndAmountCellStyle}`}
                  >
                    Amount
                  </Paper>
                  <Paper
                    className={`${classes.dlrGrossAndAmountValue} ${classes.dlrGrossAndAmountCellStyle}`}
                  >
                    {formatPrice(grossProfit.saleAmount, {
                      cents: true,
                    })}
                  </Paper>
                </Grid>
                <Grid item xs={1}>
                  <Divider orientation="vertical" />
                </Grid>
                <Grid item xs={5}>
                  <Paper
                    className={`${classes.dlrGrossAndAmountSubHeading} ${classes.dlrGrossAndAmountCellStyle}`}
                  >
                    Dlr. Gross
                  </Paper>
                  <Paper
                    className={`${classes.dlrGrossAndAmountValue} ${classes.dlrGrossAndAmountCellStyle}`}
                  >
                    {formatPrice(grossProfit.saleDealerGross, {
                      cents: true,
                    })}
                  </Paper>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              xs={4}
              style={{ minWidth: `${isDesktop ? '300px' : '100%'}` }}
            >
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Paper className={classes.dlrGrossAndAmountCells}>
                    Financing
                  </Paper>
                </Grid>
                <Grid item xs={6}>
                  <Paper
                    className={`${classes.dlrGrossAndAmountSubHeading} ${classes.dlrGrossAndAmountCellStyle}`}
                  >
                    Amount
                  </Paper>
                  <Paper
                    className={`${classes.dlrGrossAndAmountValue} ${classes.dlrGrossAndAmountCellStyle}`}
                  >
                    {formatPrice(grossProfit.financingAmount, {
                      cents: true,
                    })}
                  </Paper>
                </Grid>
                <Grid item xs={1}>
                  <Divider orientation="vertical" />
                </Grid>
                <Grid item xs={5}>
                  <Paper
                    className={`${classes.dlrGrossAndAmountSubHeading} ${classes.dlrGrossAndAmountCellStyle}`}
                  >
                    Dlr. Gross
                  </Paper>
                  <Paper
                    className={`${classes.dlrGrossAndAmountValue} ${classes.dlrGrossAndAmountCellStyle}`}
                  >
                    {formatPrice(grossProfit.financingDealerGross, {
                      cents: true,
                    })}
                  </Paper>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              xs={4}
              style={{ minWidth: `${isDesktop ? '300px' : '100%'}` }}
            >
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Paper className={classes.dlrGrossAndAmountCells}>
                    Total Comm
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper
                    className={`${classes.dlrGrossAndAmountValue} ${classes.dlrGrossAndAmountCellStyle}`}
                  >
                    {formatPrice(grossProfit.totalComm, {
                      cents: true,
                    })}
                  </Paper>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              xs={4}
              style={{ minWidth: `${isDesktop ? '300px' : '100%'}` }}
            >
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Paper className={classes.dlrGrossAndAmountCells}>
                    Front WeOwe/Fees
                  </Paper>
                </Grid>
                <Grid item xs={6}>
                  <Paper
                    className={`${classes.dlrGrossAndAmountSubHeading} ${classes.dlrGrossAndAmountCellStyle}`}
                  >
                    Amount
                  </Paper>
                  <Paper
                    className={`${classes.dlrGrossAndAmountValue} ${classes.dlrGrossAndAmountCellStyle}`}
                  >
                    {formatPrice(grossProfit.frontWeOweFeesAmount, {
                      cents: true,
                    })}
                  </Paper>
                </Grid>
                <Grid item xs={1}>
                  <Divider orientation="vertical" />
                </Grid>
                <Grid item xs={5}>
                  <Paper
                    className={`${classes.dlrGrossAndAmountSubHeading} ${classes.dlrGrossAndAmountCellStyle}`}
                  >
                    Dlr. Gross
                  </Paper>
                  <Paper
                    className={`${classes.dlrGrossAndAmountValue} ${classes.dlrGrossAndAmountCellStyle}`}
                  >
                    {formatPrice(grossProfit.frontWeOweFeesDealerGross, {
                      cents: true,
                    })}
                  </Paper>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              xs={4}
              style={{ minWidth: `${isDesktop ? '300px' : '100%'}` }}
            >
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Paper className={classes.dlrGrossAndAmountCells}>
                    Back WeOwe/Fees
                  </Paper>
                </Grid>
                <Grid item xs={6}>
                  <Paper
                    className={`${classes.dlrGrossAndAmountSubHeading} ${classes.dlrGrossAndAmountCellStyle}`}
                  >
                    Amount
                  </Paper>
                  <Paper
                    className={`${classes.dlrGrossAndAmountValue} ${classes.dlrGrossAndAmountCellStyle}`}
                  >
                    {formatPrice(grossProfit.feesAmount, {
                      cents: true,
                    })}
                  </Paper>
                </Grid>
                <Grid item xs={1}>
                  <Divider orientation="vertical" />
                </Grid>
                <Grid item xs={5}>
                  <Paper
                    className={`${classes.dlrGrossAndAmountSubHeading} ${classes.dlrGrossAndAmountCellStyle}`}
                  >
                    Dlr. Gross
                  </Paper>
                  <Paper
                    className={`${classes.dlrGrossAndAmountValue} ${classes.dlrGrossAndAmountCellStyle}`}
                  >
                    {formatPrice(grossProfit.feesDealerGross, {
                      cents: true,
                    })}
                  </Paper>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              xs={4}
              style={{ minWidth: `${isDesktop ? '300px' : '100%'}` }}
            >
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Paper className={classes.dlrGrossAndAmountCells}>
                    Total Dealer Net
                  </Paper>
                </Grid>
                <Grid item xs={6}>
                  <Paper
                    className={`${classes.dlrGrossAndAmountValue} ${
                      classes.dlrGrossAndAmountCellStyle
                    } ${+grossProfit.totalDealerNet < 0 ? classes.loss : ''}`}
                  >
                    {formatPrice(grossProfit.totalDealerNet, {
                      cents: true,
                    })}
                  </Paper>
                </Grid>
                <Grid item xs={1}>
                  <Divider orientation="vertical" />
                </Grid>
                <Grid item xs={5}>
                  <Paper
                    className={`${classes.dlrGrossAndAmountValue} ${classes.dlrGrossAndAmountCellStyle}`}
                  >
                    {formatPercent(grossProfit.totalDealerNetPercent)}
                  </Paper>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              xs={4}
              style={{ minWidth: `${isDesktop ? '300px' : '100%'}` }}
            >
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Paper className={classes.dlrGrossAndAmountCells}>
                    Cost Adjustment
                  </Paper>
                </Grid>
                <Grid item xs={6}>
                  <Paper
                    className={`${classes.dlrGrossAndAmountSubHeading} ${classes.dlrGrossAndAmountCellStyle}`}
                  >
                    Amount
                  </Paper>
                  <Paper
                    className={`${classes.dlrGrossAndAmountValue} ${classes.dlrGrossAndAmountCellStyle}`}
                  >
                    {formatPrice(grossProfit.costAdjustmentsAmount, {
                      cents: true,
                    })}
                  </Paper>
                </Grid>
                <Grid item xs={1}>
                  <Divider orientation="vertical" />
                </Grid>
                <Grid item xs={5}>
                  <Paper
                    className={`${classes.dlrGrossAndAmountSubHeading} ${classes.dlrGrossAndAmountCellStyle}`}
                  >
                    Dlr. Gross
                  </Paper>
                  <Paper
                    className={`${classes.dlrGrossAndAmountValue} ${classes.dlrGrossAndAmountCellStyle}`}
                  >
                    {formatPrice(grossProfit.costAdjustmentsDealerGross, {
                      cents: true,
                    })}
                  </Paper>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              xs={4}
              style={{ minWidth: `${isDesktop ? '300px' : '100%'}` }}
            >
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Paper className={classes.dlrGrossAndAmountCells}>
                    Ins/MBI/servCont
                  </Paper>
                </Grid>
                <Grid item xs={6}>
                  <Paper
                    className={`${classes.dlrGrossAndAmountSubHeading} ${classes.dlrGrossAndAmountCellStyle}`}
                  >
                    Amount
                  </Paper>
                  <Paper
                    className={`${classes.dlrGrossAndAmountValue} ${classes.dlrGrossAndAmountCellStyle}`}
                  >
                    {formatPrice(grossProfit.insMbiServContAmount, {
                      cents: true,
                    })}
                  </Paper>
                </Grid>
                <Grid item xs={1}>
                  <Divider orientation="vertical" />
                </Grid>
                <Grid item xs={5}>
                  <Paper
                    className={`${classes.dlrGrossAndAmountSubHeading} ${classes.dlrGrossAndAmountCellStyle}`}
                  >
                    Dlr. Gross
                  </Paper>
                  <Paper
                    className={`${classes.dlrGrossAndAmountValue} ${classes.dlrGrossAndAmountCellStyle}`}
                  >
                    {formatPrice(grossProfit.insMbiServContDealerGross, {
                      cents: true,
                    })}
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          <Box className={classes.closeButton}>Close</Box>
        </Button>
      </DialogActions>
    </div>
  );
};

export default GrossProfitModal;
