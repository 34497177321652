import React, { createRef, useState } from 'react';

/* external */
import gql from 'graphql-tag';
import moment from 'moment';
import Pdf from 'react-to-pdf';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';

/* Material UI */
import Button from '@mui/material/Button';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import ReceiptIcon from '@mui/icons-material/Receipt';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

/* internal */
import Loading from 'components/MaterialUI/Loading';
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';

const REFUND_TRANSACTION = gql`
  mutation refundTransaction($input: createTransactionInput!) {
    gocard {
      createTransaction(input: $input) {
        transactionId
      }
    }
  }
`;

const TRANSACTIONS_BY_CARD = gql`
  query transactions(
    $filters: [QueryFilter]
    $page: Int!
    $sort: [QuerySortElement]
  ) {
    gocard {
      transactionsByCardId(
        filters: $filters
        page: $page
        pageSize: 25
        sort: $sort
      ) {
        results {
          amount
          cardId
          code
          createBy
          description
          transactionId
          transactionDate
          oldBalance
          newBalance
          location {
            locationName
            locationId
          }
        }
        pagination {
          total
          page
        }
      }
    }
  }
`;

const TransactionsModal = ({ gocard, onClose, isOpen, refetch }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [transaction, setTransaction] = useState(null);
  const ref = createRef();
  const {
    data,
    loading,
    refetch: transactionRefetch,
  } = useQuery(TRANSACTIONS_BY_CARD, {
    variables: {
      filters: [
        {
          field: 'cardId',
          model: 'CashTransaction',
          op: 'eq',
          value: gocard.cardId,
        },
      ],
      page: 1,
    },
    skip: !isOpen,
  });

  const [refundTransaction, { loading: refundLoading }] = useMutation(
    REFUND_TRANSACTION,
    {
      onCompleted: e => {
        enqueueSnackbar('Refund completed successfully!', {
          variant: 'success',
        });
        onClose();
        refetch();
        transactionRefetch();
      },
      onError: e =>
        enqueueSnackbar(`Error completing refund: ${e}`, {
          variant: 'error',
        }),
    },
  );

  const handleRefund = transaction => {
    const payload = {
      cardId: transaction.cardId,
      locationId: transaction.location.locationId,
      amount: Number(transaction.amount) * -1,
      code: 'REF',
      description: `Refund from transaction # ${transaction.transactionId}`,
    };
    refundTransaction({
      variables: { input: payload },
    });
  };

  const transactions = data?.gocard?.transactionsByCardId.results || [];

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        setTransaction(null);
        onClose();
      }}
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle>Transactions</DialogTitle>
      {!transaction && (
        <>
          <DialogContent>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item>
                <CreditCardIcon style={{ marginTop: '5px' }} />
              </Grid>
              <Grid item style={{ paddingLeft: '5px' }}>
                ${gocard?.cashBalance || 0}
              </Grid>
              <Grid item style={{ paddingLeft: '5px' }}>
                |
              </Grid>
              <Grid
                item
                style={{ paddingLeft: '5px' }}
              >{`${gocard?.cardNumber}`}</Grid>
            </Grid>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Actions</TableCell>
                    <TableCell>Transactions #</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Location</TableCell>
                    <TableCell>Created By</TableCell>
                    <TableCell>Code</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>Description</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading && <Loading />}
                  {!loading &&
                    transactions.map(row => (
                      <TableRow key={row.transactionId}>
                        <TableCell>
                          <IconButton
                            size="small"
                            onClick={() => setTransaction(row)}
                          >
                            <ReceiptIcon fontSize="small" />
                          </IconButton>
                          {gocard.permissions.refundTransaction && (
                            <IconButton
                              size="small"
                              onClick={() => {
                                if (
                                  window.confirm(
                                    'Are you sure you want to refund this transaction?',
                                  )
                                ) {
                                  handleRefund(row);
                                }
                              }}
                            >
                              <Icon
                                className="fa fa-solid fa-rotate-left"
                                fontSize="small"
                              />
                            </IconButton>
                          )}
                        </TableCell>
                        <TableCell>{row.transactionId}</TableCell>
                        <TableCell>
                          {moment(row.transactionDate).format('YYYY-MM-DD')}
                        </TableCell>
                        <TableCell>{row.location.locationName}</TableCell>
                        <TableCell>{row.createBy}</TableCell>
                        <TableCell>{row.code}</TableCell>
                        <TableCell>${row.amount}</TableCell>
                        <TableCell>{row.description}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="primary" variant="outlined">
              Cancel
            </Button>
          </DialogActions>
        </>
      )}
      {transaction && (
        <>
          <DialogContent>
            <div ref={ref}>
              <h1>Transaction Receipt</h1>
              <h4>
                By {transaction.locationName} ({transaction.createBy}) at{' '}
                {moment(transaction.transactionDate).format(
                  'MMMM Do YYYY, h:mm a',
                )}
              </h4>
              <h4>
                For {gocard.firstName} {gocard.lastName} ({gocard.cardNumber})
              </h4>
              <table>
                <tr>
                  <th>Transaction #</th>
                  <td>{transaction.transactionId}</td>
                </tr>
                <tr>
                  <th>Amount</th>
                  <td>{transaction.amount}</td>
                </tr>
                <tr>
                  <th>Old Balance</th>
                  <td>{transaction.oldBalance}</td>
                </tr>
                <tr>
                  <th>New Balance</th>
                  <td>{transaction.newBalance}</td>
                </tr>
                <tr>
                  <th>Description</th>
                  <td>{transaction.description}</td>
                </tr>
                <tr>
                  <th>Code</th>
                  <td>{transaction.code}</td>
                </tr>
              </table>
              <h3
                style={{
                  marginTop: '50px',
                  width: '500px',
                  borderBottomWidth: '2px',
                  borderBottomStyle: 'dotted',
                  borderBottomColor: '#000',
                }}
              >
                <small>Signature</small>
              </h3>
            </div>
          </DialogContent>
          <DialogActions>
            <Pdf targetRef={ref} filename="receipt.pdf">
              {({ toPdf }) => (
                <Button onClick={toPdf} color="primary" variant="outlined">
                  Print Receipt
                </Button>
              )}
            </Pdf>
            <Button
              onClick={() => {
                setTransaction(null);
              }}
              color="primary"
              variant="outlined"
            >
              Back
            </Button>
            <Button
              onClick={() => {
                setTransaction(null);
                onClose();
              }}
              color="primary"
              variant="outlined"
            >
              Close
            </Button>
          </DialogActions>
        </>
      )}
      <LoadingBackdrop open={refundLoading}>
        Refunding Transaction...
      </LoadingBackdrop>
    </Dialog>
  );
};

TransactionsModal.fragments = {
  gocard: gql`
    fragment TransactionsModalGoCard on GoCardAccountV2 {
      permissions {
        refundTransaction
      }
      firstName
      lastName
      cardNumber
      cashBalance
    }
  `,
};

export default TransactionsModal;
