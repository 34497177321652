import React from 'react';

// Material UI
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import CloseIcon from '@mui/icons-material/Close';
import Drawer from '@mui/material/Drawer';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { makeStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';

// Internal
import { PROVINCES, FACET_FILTER_DISPLAY } from '../constant';
import { useUserContext } from 'components/MaterialUI/UserContext';
import { usePersistedState } from 'utils';

const useStyles = makeStyles({
  panel: {
    width: '100%',
    paddingLeft: '25px',
    paddingRight: '25px',
  },
  title: {
    fontSize: '20px',
    paddingBottom: '20px',
    paddingLeft: '25px',
    fontWeight: 'bold',
  },
  margin: {
    marginBottom: '10px',
  },
  indent: {
    marginLeft: '10px',
  },
  drawer: {
    '&> .MuiDrawer-paper': {
      maxWidth: '410px',
    },
  },
});

const CustomerSearchFilters = ({
  isOpen,
  filters,
  setFilters,
  closeDrawer,
  advancedSearch,
  advancedFilter,
  setAdvancedFilter,
  facetResults,
}) => {
  const classes = useStyles();
  const { currentUser } = useUserContext();
  const [city, setCity] = usePersistedState('CustomerSearchCityFilter', '');
  const resourceTypes = facetResults?.resource_type?.buckets ?? [];

  const handleFilter = (value, selectedFilter) => {
    const updatedFilters = filters.map(filter => {
      if (filter.name === selectedFilter) {
        if (filter.value !== value) {
          return { ...filter, value: value !== '' ? value : null };
        }
        return { ...filter, value: null };
      }
      return filter;
    });
    setFilters(updatedFilters);
  };

  const handleAdvancedFilter = value => {
    setAdvancedFilter(value !== advancedFilter ? value : '');
  };

  const isAdvancedSelected = value => value === advancedFilter;

  const isSelected = (value, selectedFilter) => {
    const isFound = filters.find(
      filter => filter.name === selectedFilter && filter.value === value,
    );

    if (isFound !== undefined) return true;
    return false;
  };

  return (
    <Drawer
      anchor="left"
      open={isOpen}
      onClose={closeDrawer}
      className={classes.drawer}
    >
      <div style={{ display: 'flex' }}>
        <IconButton
          style={{
            display: 'flex',
            marginLeft: 'auto',
            height: '50px',
            zIndex: '1000',
          }}
          onClick={closeDrawer}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </div>
      <div className={classes.title}>Filters</div>
      <List className={classes.panel}>
        <FormControlLabel
          control={
            <Switch
              checked={isSelected(currentUser.username, 'users')}
              onChange={() => handleFilter(currentUser.username, 'users')}
            />
          }
          label="My Customers"
        />
        {advancedSearch && (
          <Accordion className={classes.margin}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              Search In:
            </AccordionSummary>
            <List className={classes.indent}>
              {resourceTypes.map(({ count, value }) => (
                <ListItem
                  key={value}
                  dense
                  button
                  onClick={() => handleAdvancedFilter(value)}
                  selected={isAdvancedSelected(value)}
                >
                  <ListItemText
                    primary={`${FACET_FILTER_DISPLAY[value]} (${count})`}
                  />
                </ListItem>
              ))}
            </List>
          </Accordion>
        )}
        <Accordion className={classes.margin}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            Provinces
          </AccordionSummary>
          <List className={classes.indent}>
            {PROVINCES.map(({ text }) => (
              <ListItem
                key={text}
                dense
                button
                onClick={() => handleFilter(text, 'province')}
                selected={isSelected(text, 'province')}
              >
                <ListItemText primary={text} />
              </ListItem>
            ))}
          </List>
        </Accordion>
        <TextField
          label="City"
          value={city}
          onChange={e => setCity(e.target.value)}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              handleFilter(e.target.value, 'city');
            }
          }}
          variant="outlined"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  size="small"
                  onClick={() => handleFilter(city, 'city')}
                >
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </List>
    </Drawer>
  );
};

export default CustomerSearchFilters;
