/* External */
import React, { useState } from 'react';
import moment from 'moment';

/* Material UI */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { DatePicker } from '@mui/x-date-pickers';

import { ExpandMore as ExpandMoreIcon, Search } from '@mui/icons-material';

/* Internal */

const useStyles = makeStyles(theme => ({
  filtersContainer: {
    margin: '20px 0 30px 0',
    padding: '10px',
    border: 0,
    flexGrow: 1,
    [theme.breakpoints.down('md')]: {
      margin: '10px 0 20px 0',
    },
  },
  filterField: {
    width: '100%',
  },
  searchButton: {
    backgroundColor: theme.colours.add,
    color: 'white',
    width: '90%',
  },
  resetButton: {
    color: theme.actions.danger.backgroundColor,
    borderColor: theme.actions.danger.backgroundColor,
    width: '90%',
  },
  h6: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
  },
  chipLabel: {
    '& .MuiChip-label': {
      overflow: 'visible',
      whiteSpace: 'wrap',
      padding: '12px',
    },
  },
}));

const MESSAGE_DIRECTIONS = [
  { id: 'all', name: 'All' },
  { id: 'inbound', name: 'Inbound' },
  { id: 'outbound', name: 'Outbound' },
];
const STATUSES = [
  { id: 'all', name: 'All' },
  { id: 'delivered', name: 'Delivered' },
  { id: 'failed', name: 'Failed' },
  { id: 'queued', name: 'Queued' },
  { id: 'scheduled', name: 'Scheduled' },
  { id: 'undelivered', name: 'Skipped' },
];
const ARCHIVE_STATUS = [
  { id: 'all', name: 'All' },
  { id: 'true', name: 'Archived' },
  { id: 'false', name: 'Not Archived' },
];

const MessageSearchFilters = ({
  gatewayUsers,
  setSearchFilters,
  bulkSendName,
  bulkSendFilters,
  setBulkSendFilters,
}) => {
  const classes = useStyles();

  const mobile = useMediaQuery(theme => theme.breakpoints.down('md'));

  const bulkSendFilter = bulkSendFilters[1] ?? [];

  const [messageDirection, setMessageDirection] = useState('all');
  const [status, setStatus] = useState('all');
  const [operator, setOperator] = useState('all');
  const [dateFrom, setDateFrom] = useState(
    moment().startOf('day').subtract(1, 'month'),
  );
  const [dateTo, setDateTo] = useState(moment().endOf('day'));
  const [customerName, setCustomerName] = useState('');
  const [customerPhone, setCustomerPhone] = useState('');
  const [bulkLabel, setBulkLabel] = useState('');
  const [archiveStatus, setArchiveStatus] = useState('all');

  const filteringOn = bulkSendFilter
    ? bulkSendFilter.value === 'delivered'
      ? 'Delivered'
      : bulkSendFilter.value === 'undelivered'
      ? 'Skipped'
      : 'Replies'
    : '';

  const chipMessage = bulkSendFilter
    ? `Bulksend: ${bulkSendName}. Filtering: ${filteringOn}`
    : '';

  const sortedUsers = gatewayUsers.sort((a, b) =>
    a.display_name.localeCompare(b.display_name),
  );

  const directionFilter =
    messageDirection !== 'all'
      ? {
          model: 'Message',
          field: 'direction',
          op: 'eq',
          value: messageDirection,
        }
      : null;

  const statusFilter =
    status !== 'all'
      ? { model: 'Message', field: 'delivery_status', op: 'eq', value: status }
      : null;

  const operatorFilter =
    operator !== 'all'
      ? {
          model: 'Conversation',
          field: 'operator_username',
          op: 'eq',
          value: operator,
        }
      : null;

  const dateFromFilter = dateFrom
    ? { model: 'Message', field: 'date_created', op: '>=', value: dateFrom }
    : null;

  const dateToFilter = dateTo
    ? { model: 'Message', field: 'date_created', op: '<=', value: dateTo }
    : null;

  const customerNameFilter = customerName
    ? {
        model: 'Conversation',
        field: 'full_name',
        op: 'ilike',
        value: `%${customerName}%`,
      }
    : null;

  const customerPhoneFilter = customerPhone
    ? {
        model: 'Message',
        field: 'sent_to',
        op: 'ilike',
        value: `%${customerPhone}%`,
      }
    : null;

  const bulkLabelFilter = bulkLabel
    ? {
        model: 'Message',
        field: 'bulk_send_label',
        op: 'ilike',
        value: `%${bulkLabel}%`,
      }
    : null;

  const archiveStatusFilter =
    archiveStatus !== 'all'
      ? {
          model: 'Conversation',
          field: 'archived',
          op: 'eq',
          value: archiveStatus,
        }
      : null;

  const handleSearchClick = () => {
    setSearchFilters(
      [
        directionFilter,
        statusFilter,
        operatorFilter,
        dateFromFilter,
        dateToFilter,
        customerNameFilter,
        customerPhoneFilter,
        bulkLabelFilter,
        archiveStatusFilter,
      ].filter(x => x),
    );
  };

  const handleResetClick = () => {
    setMessageDirection('all');
    setOperator('all');
    setStatus('all');
    setDateFrom(null);
    setDateTo(null);
    setCustomerName('');
    setCustomerPhone('');
    setBulkLabel('');
    setArchiveStatus('all');
    setBulkSendFilters([]);
    setSearchFilters([]);
  };

  // have an initial date filter that displays messages only from the last year

  return (
    <Accordion className={classes.filtersContainer}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.h6}>Filters</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid
          container
          spacing={3}
          justifyContent={mobile ? 'flex-start' : 'space-between'}
          alignItems="flex-end"
        >
          <Grid item xs={6} sm={4} md={2}>
            {/* MESSAGE DIRECTION */}
            <FormControl variant="outlined" className={classes.filterField}>
              <InputLabel shrink id="message-directions-label">
                Message Direction
              </InputLabel>
              <Select
                labelId="message-directions-label"
                label="Message Direction"
                value={messageDirection}
                onChange={e => setMessageDirection(e.target.value)}
              >
                {MESSAGE_DIRECTIONS.map(({ id, name }) => (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={4} md={2}>
            {/* STATUS */}
            <FormControl variant="outlined" className={classes.filterField}>
              <InputLabel shrink id="status-label">
                Status
              </InputLabel>
              <Select
                labelId="status-label"
                label="Status"
                value={status}
                onChange={e => setStatus(e.target.value)}
              >
                {STATUSES.map(({ id, name }) => (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={7} sm={4} md={3}>
            {/* OPERATORS */}
            <FormControl variant="outlined" className={classes.filterField}>
              <InputLabel shrink id="operators-label">
                Operator
              </InputLabel>
              <Select
                labelId="operators-label"
                label="Operator"
                value={operator}
                onChange={e => setOperator(e.target.value)}
              >
                <MenuItem key={'all'} value={'all'}>
                  All
                </MenuItem>
                {sortedUsers.map(({ display_name, username }) => (
                  <MenuItem key={username} value={username}>
                    {display_name ?? username}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={4} md={2}>
            {/* DATE FROM */}
            <DatePicker
              className={classes.filterField}
              autoOk
              clearable
              label="Date From"
              format="YYYY-MM-DD"
              variant="dialog"
              value={dateFrom}
              onChange={date => setDateFrom(moment(date).startOf('day'))}
              disableFuture
            />
          </Grid>
          <Grid item xs={6} sm={4} md={2}>
            {/* DATE TO */}
            <DatePicker
              className={classes.filterField}
              autoOk
              clearable
              label="Date To"
              format="YYYY-MM-DD"
              variant="dialog"
              value={dateTo}
              onChange={date => setDateTo(moment(date).endOf('day'))}
            />
          </Grid>
          <Grid item xs={6} md={4} lg={3}>
            {/* CUSTOMER NAME */}
            <TextField
              className={classes.filterField}
              label="Customer Name"
              value={customerName}
              onChange={e => setCustomerName(e.target.value)}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={2}>
            {/* CUSTOMER PHONE */}
            <TextField
              className={classes.filterField}
              label="Customer Phone"
              value={customerPhone}
              onChange={e => setCustomerPhone(e.target.value)}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            {/* BULK LABEL */}
            <TextField
              className={classes.filterField}
              label="Bulk Label"
              value={bulkLabel}
              onChange={e => setBulkLabel(e.target.value)}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={2}>
            {/* ARCHIVE STATUS */}
            <FormControl variant="outlined" className={classes.filterField}>
              <InputLabel shrink id="archive-status-label">
                Archive Status
              </InputLabel>
              <Select
                labelId="archive-status-label"
                label="Archive Status"
                value={archiveStatus}
                onChange={e => setArchiveStatus(e.target.value)}
              >
                {ARCHIVE_STATUS.map(({ id, name }) => (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {/* BUTTONS */}
          <Grid container item xs={12} md={6} lg={2} spacing={1}>
            <Grid item xs={6} lg={12}>
              {/* SEARCH BUTTON */}
              <Button
                variant="contained"
                startIcon={<Search />}
                className={classes.searchButton}
                onClick={() => handleSearchClick()}
              >
                Search
              </Button>
            </Grid>
            <Grid item xs={6} lg={12}>
              {/* Reset BUTTON */}
              <Button
                variant="outlined"
                className={classes.resetButton}
                onClick={() => handleResetClick()}
              >
                Reset
              </Button>
            </Grid>
          </Grid>
          {bulkSendFilter.length !== 0 && (
            <Grid item xs={12}>
              <Chip
                label={chipMessage}
                onDelete={() => setBulkSendFilters([])}
                className={classes.chipLabel}
              />
            </Grid>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default MessageSearchFilters;
