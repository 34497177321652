import React from 'react';

/* external */
import { startCase } from 'lodash';

/* Material UI */
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import DoneIcon from '@mui/icons-material/Done';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Popover from '@mui/material/Popover';

const useStyles = makeStyles(theme => ({
  iconSpacer: {
    paddingRight: '10px',
    width: '25px',
  },
  icon: {
    paddingRight: '10px',
    width: '25px',
    fontSize: 'small',
  },
}));

const SortPopover = ({
  sortSelection,
  setSortSelection,
  sortOptions,
  onClose,
  ...rest
}) => {
  const classes = useStyles();
  const isSelected = ({ model, field, direction }) =>
    sortSelection?.model === model &&
    sortSelection?.field === field &&
    sortSelection?.direction === direction;

  const clickOptionHandler = sortOption => () => {
    setSortSelection(isSelected(sortOption) ? null : sortOption);
    onClose();
  };

  const defaultLabel = ({ model, field, direction }) =>
    `${startCase(model)} ${startCase(field)} ${startCase(direction)}`;

  return (
    <Popover onClose={onClose} {...rest}>
      <List>
        {sortOptions.map(({ options, ...sortOption }) => (
          <ListItem
            button
            dense
            onClick={clickOptionHandler(sortOption)}
            selected={isSelected(sortOption)}
            key={sortOption.model + sortOption.field + sortOption.direction}
          >
            {isSelected(sortOption) ? (
              <DoneIcon className={classes.icon} />
            ) : (
              <Box className={classes.iconSpacer} />
            )}
            <ListItemText
              primary={options?.label ?? defaultLabel(sortOption)}
            />
          </ListItem>
        ))}
      </List>
    </Popover>
  );
};

export default SortPopover;
