import React from 'react';

/* external */
import gql from 'graphql-tag';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';

/* material ui */
import {
  Box,
  Button,
  Grid,
  Hidden,
  InputAdornment,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

/* internal */
import { formatPrice } from 'utils';
import { pitchStyles, errorHandler } from 'modules/pitches/utils';
import CBBCalculator from 'components/MaterialUI/CBBCalculator/CBBCalculator';
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';
import TextFieldControl from 'components/MaterialUI/TextFieldControl';

const useStyles = makeStyles(theme => ({
  sectionTitle: pitchStyles.sectionTitle(theme),
  paragraph: pitchStyles.responsiveParagraph(theme),
  boldMobile: {
    [theme.breakpoints.down('sm')]: {
      fontWeight: 'bold',
    },
  },
  biggerMobile: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
  },
}));

const FRAGMENT = gql`
  fragment TradeValuationFormBoxAppraisal on Appraisal {
    cbbAveragePrice
    cbbCleanPrice
    cbbCondition
    cbbEquipment
    cbbExtraCleanPrice
    cbbRoughPrice
    cbbSelectedPrice
    cbbStyle
    cbbTrim
    cbbVid
    estimatedReconCost
    make
    model
    odometer
    trim
    value
    vin
    year
  }
`;

const UPDATE_APPRAISAL = gql`
  mutation updateAppraisal($id: Int!, $data: UpdateAppraisalInput!) {
    appraisals {
      updateAppraisal(id: $id, data: $data) {
        id
        ...TradeValuationFormBoxAppraisal
      }
    }
  }
  ${FRAGMENT}
`;

const TradeValuationFormBox = ({
  appraisal,
  onBack,
  onCancel,
  onUpdate,
  onSkip,
  ...rest
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const classes = useStyles();
  const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const {
    id,
    cbbAveragePrice,
    cbbCleanPrice,
    cbbCondition,
    cbbEquipment,
    cbbExtraCleanPrice,
    cbbRoughPrice,
    cbbSelectedPrice,
    cbbStyle,
    cbbTrim,
    cbbVid,
    estimatedReconCost,
    make,
    model,
    odometer,
    value,
    vin,
    year,
  } = appraisal;
  const { clearErrors, control, formState, handleSubmit, setError } = useForm({
    defaultValues: {
      cbb: {
        vid: cbbVid,
        trim: cbbTrim,
        style: cbbStyle,
        selectedPrice: cbbSelectedPrice,
        condition: cbbCondition,
        equipment: cbbEquipment,
        averagePrice: cbbAveragePrice,
        cleanPrice: cbbCleanPrice,
        roughPrice: cbbRoughPrice,
        extraCleanPrice: cbbExtraCleanPrice,
      },
      estimatedReconCost,
      value,
    },
  });

  const estimatedReconCostWatch = useWatch({
    control,
    name: 'estimatedReconCost',
  });
  const cbbWatch = useWatch({ control, name: 'cbb' });
  const { isDirty } = formState;

  const [updateAppraisal, updateAppraisalMutation] = useMutation(
    UPDATE_APPRAISAL,
    {
      onCompleted: data => onUpdate(data.appraisals.updateAppraisal),
      onError: err => errorHandler(enqueueSnackbar, setError)(err),
    },
  );

  const onSubmit = ({
    cbb: {
      vid,
      trim,
      style,
      averagePrice,
      selectedPrice,
      condition,
      equipment,
      cleanPrice,
      roughPrice,
      extraCleanPrice,
    },
    ...rest
  }) =>
    updateAppraisal({
      variables: {
        id,
        data: {
          cbbCleanPrice: cleanPrice,
          cbbAveragePrice: averagePrice,
          cbbCondition: condition,
          cbbEquipment: equipment,
          cbbExtraCleanPrice: extraCleanPrice,
          cbbRoughPrice: roughPrice,
          cbbSelectedPrice: selectedPrice,
          cbbStyle: style,
          cbbTrim: trim,
          cbbVid: vid,
          ...rest,
        },
      },
    });

  return (
    <Box {...rest}>
      <LoadingBackdrop open={updateAppraisalMutation.loading}>
        Updating appraisal
      </LoadingBackdrop>
      <Box className={classes.sectionTitle}>Appraised Value/CBB Valuation</Box>
      <Box className={classes.paragraph}>
        Use the Canadian Black Book information below to help determine the
        appraised value for this trade-in vehicle. You can also list any recon
        costs below.
      </Box>
      <form
        onSubmit={e => {
          clearErrors(); // Need to manually clear errors, since we've manually
          // used setError from server. handleSubmit will not fire otherwise.
          handleSubmit(onSubmit)(e); // Needed to prevent page navigate with data in url params
        }}
      >
        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <CBBCalculator
              onChangeCbbData={data => onChange(data)}
              cbbData={value}
              year={year}
              make={make}
              model={model}
              vin={vin}
              odometer={odometer}
              liveUpdate
            />
          )}
          name="cbb"
        />
        <Grid
          container
          width="100%"
          justifyContent="center"
          style={{ marginTop: theme.spacing(2) }}
        >
          {/* Column 1 */}
          <Grid container item xs={12} sm={6} alignItems="center" spacing={2}>
            <Hidden smUp>
              <Grid item xs={8}>
                Estimated Recon Cost:
              </Grid>
            </Hidden>
            <Grid item xs={4} sm={12}>
              <TextFieldControl
                control={control}
                rules={{
                  required: 'You must enter recon estimate (it can be $0).',
                }}
                label={mobile ? null : 'Estimated Recon Cost'}
                name="estimatedReconCost"
                variant="currency"
                muiVariant="filled"
              />
            </Grid>

            <Hidden smUp>
              <Grid item xs={8}>
                List Value (CBB):
              </Grid>
            </Hidden>
            <Grid item xs={4} sm={12}>
              <TextField
                value={cbbWatch.selectedPrice}
                type="number"
                InputProps={{
                  readOnly: true,
                  style: { color: 'grey' },
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                label={mobile ? null : 'List Value (CBB)'}
                variant="filled"
              />
            </Grid>

            <Grid item xs={8}>
              <Box className={classes.boldMobile}>Suggested Appraisal:</Box>
            </Grid>
            <Grid item xs={4}>
              <Box className={classes.boldMobile}>
                {formatPrice(
                  parseFloat(cbbWatch.selectedPrice || 0) -
                    parseFloat(estimatedReconCostWatch || 0),
                )}
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            sm={6}
            alignItems={mobile ? 'center' : 'flex-start'}
            spacing={2}
            style={{
              marginTop: mobile ? theme.spacing(2) : undefined,
              marginBottom: mobile ? theme.spacing(2) : undefined,
            }}
          >
            <Hidden smUp>
              <Grid
                item
                xs={7}
                className={`${classes.biggerMobile} ${classes.boldMobile}`}
              >
                Appraised Value:
              </Grid>
            </Hidden>
            <Grid
              item
              xs={4}
              sm={12}
              className={`${classes.biggerMobile} ${classes.boldMobile}`}
            >
              <TextFieldControl
                control={control}
                InputProps={{
                  className: `${classes.biggerMobile} ${classes.boldMobile}`,
                }}
                label={mobile ? null : 'Appraised Value'}
                name="value"
                required
                rules={{ required: 'You must enter an appraised value.' }}
                variant="currency"
                muiVariant="filled"
              />
            </Grid>
          </Grid>
        </Grid>

        <Box display="flex" justifyContent="flex-end" m={2} gap="8px">
          <Button variant="contained" onClick={onCancel} color="default">
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={onBack}
            disabled={isDirty}
            color="default"
          >
            Back
          </Button>
          {isDirty ? (
            <Button variant="contained" type="submit" color="default">
              Save & Continue
            </Button>
          ) : (
            <Button variant="contained" onClick={onSkip} color="default">
              Skip
            </Button>
          )}
        </Box>
      </form>
    </Box>
  );
};

TradeValuationFormBox.fragments = {
  appraisal: FRAGMENT,
};
export default TradeValuationFormBox;
