import PropTypes from 'prop-types';
import React from 'react';

import Checkbox from '@mui/material/Checkbox';
import { makeStyles } from '@mui/styles';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';

const useStyles = makeStyles(theme => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const headCells = [
  { label: 'Actions', id: null, numeric: false },
  { label: 'Stock #', id: 'stock_number', numeric: false },
  { label: 'Vehicle', id: 'displayName', numeric: false },
  { label: 'Cost', id: 'cost', numeric: false },
  { label: 'Price', id: 'regular_price', numeric: false },
  { label: 'Special Price', id: 'special_price', numeric: false },
  { label: 'Kilometers', id: 'odometer', numeric: false },
  { label: 'Color', id: 'exterior_colour_name', numeric: false },
  { label: 'Days', id: 'days_in_stock', numeric: false },
  { label: 'Leads Created', id: 'leads_count', numeric: false },
  { label: 'Opps Created', id: 'opportunities_count', numeric: false },
  { label: 'Photos', id: 'photo_count', numeric: false },
  { label: 'Updated', id: 'date_user_modified', numeric: false },
  { label: 'Recon Source', id: 'recon_source_name', numeric: false },
];

function InventoryHeader(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;

  const { visuallyHidden } = useStyles();
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'Select All Vehicles' }}
            color="secondary"
          />
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              disabled={headCell.label === 'Actions'}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

InventoryHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default InventoryHeader;
