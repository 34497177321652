import { useMutation, useQuery } from '@apollo/react-hooks';

/* external */
import axios from 'axios';
import gql from 'graphql-tag';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';

import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  useTheme,
} from '@mui/material';

import { makeStyles } from '@mui/styles';

/* Material UI */
import Alert from '@mui/material/Alert';

/* internal */
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';
import { useUserContext } from 'components/MaterialUI/UserContext';

import { formatPrice } from 'utils';

import { RV_ADVENTURE_CLUB_CHOICES, URL } from 'constants.js';

const UPDATE_GOCARD_MEMBERSHIPS = gql`
  mutation updateAccountById($cardId: ID!, $input: GoCardMembershipInput!) {
    gocard {
      updateAccountById(cardId: $cardId, input: $input) {
        cardId
        amaMembership
        rvClubMembership
      }
    }
  }
`;

const CREATE_TRANSACTION = gql`
  mutation createTransaction($input: createTransactionInput!) {
    gocard {
      createTransaction(input: $input) {
        transactionId
      }
    }
  }
`;

const HAS_RV_ADVENTURE_CLUB_TRANSACTION = gql`
  query transactions(
    $filters: [QueryFilter]
    $page: Int!
    $sort: [QuerySortElement]
  ) {
    gocard {
      transactionsByCardId(
        filters: $filters
        page: $page
        pageSize: 10
        sort: $sort
      ) {
        results {
          cardId
          code
          description
          transactionId
          transactionDate
          location {
            locationName
            locationId
          }
        }
        pagination {
          total
          page
        }
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  width: {
    width: '42%',
  },
}));

const defaultItems = {
  RV_FIREW: false,
  RV_PROPA: false,
  RV_BATHR: false,
};

const LabelBox = ({ children }) => (
  <Box component="span" fontWeight="bold" fontSize={14} paddingRight={1}>
    {children}
  </Box>
);
const DataBox = ({ children }) => (
  <Box component="span" fontSize={14}>
    {children}
  </Box>
);

const RvMemberModal = ({
  gocard,
  isOpen,
  onClose,
  locations,
  allowSignup,
  scannedCard = false,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { currentUser } = useUserContext();

  const isRvMember = gocard?.rvClubMembership || false;
  const {
    _id,
    fullname,
    first_name,
    last_name,
    vehicles = [],
    identities = [],
  } = gocard?.customer || {};
  const displayName = fullname
    ? fullname
    : first_name && last_name
    ? `${first_name} ${last_name}`
    : 'N/A';
  const smsIdentity = identities.find(
    identity => identity.identity_type === 'sms',
  );
  const emailIdentity = identities.find(
    identity => identity.identity_type === 'email',
  );
  const [rvMember, setRvMember] = useState(gocard?.rvClubMembership || false);
  const [rvLocationId, setRvLocationId] = useState('');
  const [rvItems, setRvItems] = useState(defaultItems);

  const [updateAccount, { loading: accountLoading }] = useMutation(
    UPDATE_GOCARD_MEMBERSHIPS,
    {
      onCompleted: e =>
        enqueueSnackbar('Updated account successfully!', {
          variant: 'success',
        }),
      onError: e =>
        enqueueSnackbar(`Error updating account: ${e}`, {
          variant: 'error',
        }),
    },
  );

  const [createTransaction, { loading: transactionLoading }] = useMutation(
    CREATE_TRANSACTION,
    {
      onCompleted: e => {
        enqueueSnackbar('Updated account successfully!', {
          variant: 'success',
        });
      },
      onError: e =>
        enqueueSnackbar(`Error creating transaction: ${e}`, {
          variant: 'error',
        }),
    },
  );

  const { data: transactionData, loading: adventureClubLoading } = useQuery(
    HAS_RV_ADVENTURE_CLUB_TRANSACTION,
    {
      variables: {
        filters: [
          {
            field: 'cardId',
            model: 'CashTransaction',
            op: 'eq',
            value: gocard?.cardId,
          },
          {
            field: 'code',
            model: 'CashTransaction',
            op: 'in',
            value: RV_ADVENTURE_CLUB_CHOICES.map(item => item.code),
          },
        ],
        sort: [
          {
            field: 'transactionDate',
            model: 'CashTransaction',
            direction: 'desc',
          },
        ],
        page: 1,
      },
      skip: !gocard,
      errorPolicy: 'all',
    },
  );

  const adventureClubTransactions =
    transactionData?.gocard?.transactionsByCardId?.results || [];

  // Wait for a transaction to be completed before creating another
  async function submitTransactions(items) {
    for (const input of items) {
      await createTransaction({ variables: { input } });
    }
    // If its a go RV scanned card send a lead to crm
    if (scannedCard) {
      const selectedLocation = locations.find(
        location => location.locationId === rvLocationId,
      );

      const vehicle = vehicles.length > 0 ? vehicles[0] : {};
      const profileUrl = `${URL.mobile}/customers/${_id}/details`;
      const leadData = {
        lead_type: 'service',
        dealer_id: selectedLocation.dealerId,
        form_name: 'RV Adventure Club Pickup',
        first_name: first_name || '',
        last_name: last_name || '',
        email: emailIdentity?.identity_value ?? '',
        primary_phone: smsIdentity?.identity_value ?? '',
        message: `Customer is currently in service picking up their RV Adventure Club supplies. The customer's RV vehicle details can be viewed at their profile: ${profileUrl}`,
        lead_source: 'RV Adventure Club',
        lead_direction: 'inbound',
        lead_channel: 'service',
        customer_vehicle_make: vehicle?.make ?? '',
        customer_vehicle_model: vehicle?.model ?? '',
        customer_vehicle_trim: vehicle?.trim ?? '',
        customer_vehicle_year: vehicle?.year ?? '',
      };
      const url = `https://leads-api.goauto.io/leads/${currentUser.organization_id}/json`;
      axios
        .post(url, leadData)
        .then(function (res) {
          enqueueSnackbar('Service Lead Generated', { variant: 'success' });
        })
        .catch(function (error) {
          enqueueSnackbar('Could not generate Service Lead', {
            variant: 'error',
          });
        });
    }
  }

  const onSubmit = () => {
    let update = false;
    let input = {};
    if (isRvMember !== rvMember && allowSignup) {
      input['rvClubMembership'] = rvMember;
      update = true;
    }
    if (update) {
      // Update AMA memberships
      updateAccount({
        variables: {
          cardId: gocard.cardId,
          input,
        },
      });
    }

    const items = RV_ADVENTURE_CLUB_CHOICES.filter(
      item => rvItems[item.code] === true,
    );
    if ((rvMember || isRvMember) && rvLocationId && !isEmpty(items)) {
      // Create a transaction for each item
      let itemList = [];
      items.forEach(item => {
        const input = {
          cardId: gocard.cardId,
          locationId: rvLocationId,
          amount: Number(0.0),
          code: item.code,
          description: `Picked up ${item.text} from RV Adventure Club`,
        };
        itemList.push(input);
      });
      submitTransactions(itemList);
    }
  };

  const handleItemChange = event => {
    setRvItems({ ...rvItems, [event.target.name]: event.target.checked });
  };

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth>
      <DialogTitle>RV Membership</DialogTitle>
      <DialogContent>
        <FormControlLabel
          control={
            <Checkbox
              defaultChecked={rvMember || isRvMember}
              onChange={e => setRvMember(e.target.checked)}
              disabled={!allowSignup}
            />
          }
          label="RV Adventure Club Member"
          labelPlacement="start"
          style={{ marginLeft: '0px' }}
        />
        {!allowSignup && !isRvMember && !rvMember && (
          <Alert severity="error">
            Go Card is not associated with an RV Adventure Club membership.
          </Alert>
        )}
        {adventureClubTransactions.length > 0 && (
          <Alert severity="info">
            {`The last RV Adventure Club transaction was on ${moment(
              adventureClubTransactions[0].transactionDate,
            ).format('MM/DD/YYYY')} at ${
              adventureClubTransactions[0].location.locationName
            }.`}
          </Alert>
        )}
        <Grid
          container
          justifyContent="flex-start"
          style={{ paddingTop: '1rem' }}
        >
          <Grid item xs={12}>
            <LabelBox>Customer Name:</LabelBox>
            <DataBox>{displayName}</DataBox>
          </Grid>
          <Grid item xs={12}>
            <LabelBox>Go Card Balance:</LabelBox>
            <DataBox>
              {formatPrice(gocard?.cashBalance, {
                cents: true,
                zeroIfNull: true,
              })}
            </DataBox>
          </Grid>
        </Grid>
      </DialogContent>
      {isRvMember && (
        <DialogContent>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item xs={12} style={{ marginBottom: '15px' }}>
              <FormControl className={classes.width}>
                <InputLabel>Location</InputLabel>
                <Select
                  value={rvLocationId}
                  onChange={e => setRvLocationId(e.target.value)}
                  disabled={!rvMember && !isRvMember}
                >
                  {locations.map(location => (
                    <MenuItem
                      key={location.locationId}
                      value={location.locationId}
                    >
                      {location.locationName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl className={classes.width}>
                <FormLabel>Items</FormLabel>
                <FormGroup>
                  {RV_ADVENTURE_CLUB_CHOICES.map(item => (
                    <FormControlLabel
                      key={item.code}
                      control={
                        <Checkbox
                          checked={rvItems[item.code]}
                          onChange={handleItemChange}
                          name={item.code}
                          disabled={!rvMember && !isRvMember}
                        />
                      }
                      label={item.text}
                    />
                  ))}
                </FormGroup>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
      )}
      <DialogActions>
        <Button style={theme.actions.close} onClick={onClose} variant="text">
          Close
        </Button>
        <Button
          style={theme.actions.confirm}
          onClick={() => onSubmit()}
          variant="contained"
          color="primary"
          disabled={!allowSignup && !rvMember && !isRvMember}
        >
          Save
        </Button>
      </DialogActions>
      <LoadingBackdrop open={accountLoading}>
        {accountLoading
          ? 'Updating Go Card Memberships'
          : 'Getting Go Card Data'}
        ...
      </LoadingBackdrop>
      <LoadingBackdrop open={adventureClubLoading}>
        Getting recent RV Adventure Club transactions...
      </LoadingBackdrop>
      <LoadingBackdrop open={transactionLoading}>
        Creating transaction...
      </LoadingBackdrop>
    </Dialog>
  );
};

RvMemberModal.fragments = {
  gocard: gql`
    fragment RvMemberModalGoCard on GoCardAccountV2 {
      rvClubMembership
      cashBalance
      customer {
        _id
        fullname
        first_name
        last_name
        vehicles {
          make
          model
          trim
          year
        }

        identities {
          identity_type
          identity_value
        }
      }
    }
  `,
};

export default RvMemberModal;
