import React from 'react';

/* external */
import gql from 'graphql-tag';
import { useHistory } from 'react-router-dom';

/* Material UI */
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import PersonIcon from '@mui/icons-material/Person';
import PrintIcon from '@mui/icons-material/Print';
import { useTheme } from '@mui/material';

import { formatOdometer, formatPrice, vehicleTitle } from 'utils';
import CustomerDisplay from 'modules/appraisals/components/common/CustomerDisplay';

const ItemCard = ({
  appraisal,
  onClickDelete,
  onClickDetails,
  onClickPrint,
  ...rest
}) => {
  const {
    customer,
    estimatedReconCost,
    opportunity,
    odometer,
    permissions,
    trimVariation,
    value,
    vin,
  } = appraisal;
  const history = useHistory();
  const theme = useTheme();

  const onClickCustomer = customer?._id
    ? () => history.push(`/customers/${customer._id}/details`)
    : opportunity?.customer_id
    ? () => history.push(`/customers/${opportunity.customer_id}/details`)
    : null;

  return (
    <Card style={{ marginBottom: useTheme().spacing(2) }} {...rest}>
      <CardHeader
        titleTypographyProps={{
          variant: 'h6',
        }}
        title={`${vehicleTitle(appraisal)} ${trimVariation ?? ''}`}
        subheader={vin}
        style={{
          margin: theme.spacing(1),
          padding: theme.spacing(1),
          color: 'primary',
        }}
      />
      <CardContent
        style={{ margin: theme.spacing(1), padding: theme.spacing(1) }}
      >
        <Grid container width="100%">
          <Grid item xs={4}>
            <Box fontSize="12px">
              <Box>
                <Box component="span" fontWeight="bold">
                  Value:{' '}
                </Box>
                {formatPrice(value)}
              </Box>
              <Box>
                <Box component="span" fontWeight="bold">
                  Recon:{' '}
                </Box>
                {formatPrice(estimatedReconCost)}
              </Box>
              <Box>{formatOdometer(odometer)}</Box>
            </Box>
          </Grid>
          <Grid item xs={8}>
            <CustomerDisplay appraisal={appraisal} small />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Box display="flex" justifyContent="space-around" width="100%">
          <IconButton onClick={onClickDetails} color="primary" size="large">
            <EditIcon />
          </IconButton>
          <IconButton
            disabled={!permissions?.delete}
            onClick={onClickDelete}
            color="primary"
            size="large"
          >
            <DeleteIcon />
          </IconButton>
          <IconButton
            disabled={!onClickCustomer}
            onClick={onClickCustomer}
            color="primary"
            size="large"
          >
            <PersonIcon />
          </IconButton>
          <IconButton onClick={onClickPrint} color="primary" size="large">
            <PrintIcon />
          </IconButton>
        </Box>
      </CardActions>
    </Card>
  );
};

ItemCard.fragments = {
  appraisal: gql`
    fragment AppraisalsListItemCardAppraisal on Appraisal {
      id
      opportunity {
        _id
        customer_name
        customer_id
      }
      permissions {
        delete
      }
      customer {
        _id
        fullname
        identities {
          identity_type
          identity_value
        }
        phone
        cell_phone
        home_phone
        work_phone
        primary_email
        primary_phone
        emails {
          email
          primary
        }
      }
      dealer {
        id
        name
      }
      year
      make
      model
      trim
      trimVariation
      vin
      ...CustomerDisplayAppraisal
    }
    ${CustomerDisplay.fragments.appraisal}
  `,
};
export default ItemCard;
