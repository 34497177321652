import React from 'react';

import VWPDIBaseForm from './VWPDIBaseForm';

const VWPDIAtlasForm = props => {
  const initialInspection = {
    vw1: 'Was the vehicle delivered free of damage and was the by-pack opened?',
    vw1A: 'Note damage and missing items relating to the transportation using the AIAG 5-digit damage code',
    vw1B: 'If the by-pack bag is found open in any way and parts are missing, a transportation claim should be filed',
    vw1C: 'If the bag is completely sealed and parts are missing, a claim should be filed under warranty',
    vw1D: 'Document the condition of the by-pack whether it is sealed (undisturbed) or opened (disturbed) on the transportation delivery form',
    vw1E: 'If the by-pack is opened (disturbed) list the parts that are missing on the transportation form',
    vw1F: 'Pictures of the condition of the by-pack bag are important when submitting a warranty or transportation claim',
    vw2: 'Perform a battery test',
    vw2A: 'The engine must not have been started for 12 hours prior to the battery test. The VAS 6161 is the only approved Volkswagen tool that can be used to test the batteries in Volkswagen vehicles',
    vw3: 'Inspect the interior for damages and cleanliness',
    vw3A: 'Check the interior for dirt or damage. Especially, if the vehicle has been used for a demo drive. If you find any defects, contact your Service Manager to schedule immediate repair',
    vw4: 'Inspect the exterior for damage and cleanliness',
    vw4A: 'Make sure the vehicle exterior is clean and free of environmental damage, scratches, dents, and dings. Especially, if the vehicle has been used for a demo drive. If you find any defects, contact your Service Manager to schedule immediate repair',
    vw5: 'Check tire pressure',
    vw5A: "For vehicles that have been PDI'd and are on your lot, the tires should be at the normal operational settings. For vehicles in off-site storage, set the tire pressures to the transportation settings to help prevent tire flat spotting",
    vw6: 'Drive the vehicle two kilometers applying the brakes several times',
    vw6A: 'Drive the vehicle for a minimum of 2 km. At 30 km/h, gradually apply the brakes several times. This eliminates brake rotor deposits and prevents flat spotting of the tires',
  };

  const technicalInspection = {
    vw7: 'Make note of condition of BY-Pack and label at the time of receipt. Verify that all items are accounted for. Make note (i.e sealed, torn open, missing part, etc.) on the RO',
    vw8: 'Review the contents label on the by-pack and verify all items are accounted for',
    vw8A: 'For more information, please reference Technical Bulletin 2054073 - By-Pack at Delivery for additional information',
    vw9: 'Place tow hook and wheel lock adapter (if equipped) in the tool kit. Discard 2nd tow hook (if applicable)',
    vw10: 'Removal of LATC (Liquid Applied Transit Coating). For more information, please reference Technical Bulletin 2060637',
    vw11: 'Check hood latch and safety catch',
    vw12: 'Test the battery with the Midtronics VAS 6161 in warranty mode within 48 hours of vehicle arrival at dealership. Record and document test code. If the battery requires charging, use a Volkswagen approved charger',
    vw13: 'Using the ODIS guided functions, disable vehicle transport mode',
    vw14: 'Check engine coolant level (engine off/cold). Level must be between upper marking and bottle seam',
    vw15: 'Top-off windshield washer solvent',
    vw16: 'Check brake fluid levels. Do not exceed max fill line',
    vw17: 'Torque wheels to specification. (Refer to Elsa), and install center/lug caps',
    vw18: 'Adjust tire pressure to specification on vehicle sticker at "B" Pillar',
    vw19: 'Install front license plate bracket, if required',
    vw20: 'Raise vehicle and perform a visual inspection for leaks and/or under body damage',
    vw21: 'Remove suspension blocks located in the suspensions coil springs (If applicable)',
    vw22: 'Install rear tow hook cover (if applicable)',
  };

  const interiorInspection = {
    vw23: 'Check seat belts, seat adjusters, heated seats, ventilated seats (if applicable)',
    vw24: 'Check 2nd and 3rd row seats (when equipped) functionality, including folding the seat.',
    vw25: 'Verify child safety lock is set to unlocked position',
    vw26: 'Install floor mats (only install ONE floor mat per seating position, do not double stack floor mats)',
    vw27: "Confirm the online Warranty/Maintenance & Tire Booklet location card is included in the owner's kit",
    vw28: 'Check that all keys and remotes lock, unlock, and start vehicle',
    vw29: 'Check all instrument cluster warning lights by cycling the ignition from off to on',
    vw30: 'Set clock, date, and year (if applicable) when available, dependent on trim level set the clock using the GPS function',
    vw31: 'Reset inspection/oil service interval',
    vw32: 'Check operation of all exterior lights and AFS using a mirror or another technician',
    vw33: 'Check function of all power windows, including the sunroof (if equipped); program One-Touch feature, and check function of pinch protection feature',
    vw34: 'Check proper fuel door operation',
    vw35: 'Check front windshield wiper/washer functions',
    vw36: 'Verify HD Radio is turned "off" in Radio/Settings menu',
    vw37: 'Verify radio operates in AM, FM, CD, MP3 and SiriusXM (if applicable) modes using applicable radio buttons and steering wheel controls.',
    vw38: 'Confirm SiriusXM is on and in an active state, if applicable (can scroll through channels and hear music)',
  };

  const roadTest = {
    vw39: { type: 'odometer', text: 'Odometer reading' },
    vw40: 'Check engine performance and acceleration',
    vw41: 'Check automatic transmission operation, including automatic shift lock and Tiptronic (if applicable)',
    vw42: 'Check steering for pulling, vibration and steering wheel alignment',
    vw43: 'Check climate control for mode functions (Defrost/Vent/Floor), air conditioning operation and heater operation',
    vw44: 'Check brake system function (including ABS)/Electronic parking brake operation engage and disengage',
    vw45: 'Check proper trunk lid operation using the remote and the exterior button',
  };

  const afterRoadTest = {
    vw46: 'Verify no warning lights are illuminated',
    vw47: "Retrieve fault memory, correct any DTC's, set readiness code (if necessary). Save and attach printout to Repair Order",
    vw48: 'All product updates for this VIN have been checked and verified in Elsa',
    vw49: 'Check engine oil level',
    vw50: 'Dealer 30-day maintenance process has been initiated',
    vw51: 'Re-enable transport mode (for unsold vehicles)',
    vw52: 'With the vehicle ignition on, observe that the green light is illuminated in the VW Car-Net 3 button module above rear view mirror (if vehicle equipped with Car-Net). Refer to TSB 2064536 for more information about Car-Net connectivity. Should you still experience issues after TSB 2064536, please contact the VW Technical Assistance Centre (TAC).',
    vw52A:
      'Confirm the QR code is displayed on the radio confirming Car-Net is ready for the Perfect Delivery experience for the customer',
    vw53: 'Install Roadside sticker on the very top left corner of the inside of the windshield',
  };

  const vehicleUpdate = {
    vw54: 'All product updates for this VIN have been checked and verified in Elsa',
    vw55: 'Dealer 30-day maintenance process has been initiated',
  };

  const detailerProcess = {
    vw56: 'Install Dealer accessories package',
  };

  const detailerInterior = {
    vw57: 'Install front/rear floor mats, check colour match. If monster mats are installed, please put factory mats in trunk',
    vw58: 'Confirm that the Technician completed the PDI',
    vw59: 'Remove unnecessary stickers and protective film/plastic. WARNING! Do not remove airbag warning triangle/warning labels',
    vw60: 'Ensure final detail was completed, install front license plate bracket (if required or requested by customer)',
    vw60A:
      'Confirm prior to delivery if the customer would like front plate bracket installed',
    vw61: 'Confirm there are no open campaigns, recalls or required vehicle updates',
    vw62: 'Verify air bag warning triangle is affixed',
    vw63: 'Check interior for cleanliness, grease marks and damage. Address all issues',
  };

  const detailerExterior = {
    vw64: 'Check vehicle exterior for damage, dings, dents, scratches, and cleanliness (including wheels & windows). Address all issues',
    vw65: 'Check tires for damage or over/under inflation, including spare. Address all issues',
    vw66: 'Confirm if this customer will be or has purchased a Winter Wheel and Tire Package, and determine if this vehicle will be delivered with Winter Package installed prior to delivery (During winter months only)',
    vw67: 'Test function of ALL remote keys; including remote engine start',
    vw68: 'Set multi-function display to correct language and units of measurement (C)',
    vw69: 'Set clock to correct time',
    vw70: 'Set memory for AM and FM stations with good reception',
    vw71: 'Activate SiriusXM satellite radio trial; pre-set channels',
  };

  return (
    <VWPDIBaseForm
      initialInspection={initialInspection}
      technicalInspection={technicalInspection}
      interiorInspection={interiorInspection}
      roadTest={roadTest}
      afterRoadTest={afterRoadTest}
      vehicleUpdate={vehicleUpdate}
      detailerProcess={detailerProcess}
      detailerInterior={detailerInterior}
      detailerExterior={detailerExterior}
      {...props}
    />
  );
};

export default VWPDIAtlasForm;
