import React, { useState, useEffect } from 'react';

/* external */
import gql from 'graphql-tag';
import { useSnackbar } from 'notistack';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';

/* Material UI */
import { makeStyles } from '@mui/styles';
import AddIcon from '@mui/icons-material/Add';
import { useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import CarIcon from '@mui/icons-material/DriveEta';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PdfIcon from '@mui/icons-material/PictureAsPdf';

/* internal */
import { lengthOrNull } from 'utils';
import { errorHandler, pitchStyles } from 'modules/pitches/utils';
import {
  ResponsiveCard,
  ResponsiveCardContent,
  ResponsiveCardHeader,
} from 'components/MaterialUI/ResponsiveCard';
import { Role } from 'constants.js';
import { useUserContext } from 'components/MaterialUI/UserContext';
import AppraisalSummary from './AppraisalSummary';
import ConfirmDialog from 'components/MaterialUI/ConfirmDialog';
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';
import SendSMSDialog from 'modules/pitches/components/SendSMSDialog';
import TradeFormDialog from './TradeFormDialog';

const useStyles = makeStyles(theme => ({
  // root: {
  //   minHeight: '50px',
  // },
  title: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 18,
    },
    fontWeight: 'bold',
  },
  addIcon: {
    padding: '0px',
    margin: '0px 5px 0px 10px',
  },
  editIcon: pitchStyles.roundedBlueIconButton(theme),
  deleteIcon: pitchStyles.roundedRedIconButton(theme),
  pdfIcon: pitchStyles.roundedBlueIconButton(theme),
}));

const DELETE_APPRAISAL = gql`
  mutation deleteappraisal($id: Int!) {
    appraisals {
      deleteAppraisal(id: $id) {
        id
      }
    }
  }
`;

const GREEN_SHEET = gql`
  query greenSheet($id: Int!) {
    appraisals {
      tradeBookGreenSheet(appraisalId: $id) {
        url
      }
    }
  }
`;

const USED_TRUCK = gql`
  query usedTruck($id: Int!) {
    appraisals {
      usedTruckBuyerReport(appraisalId: $id) {
        url
      }
    }
  }
`;

const USED_VEHICLE = gql`
  query usedVehicle($id: Int!) {
    appraisals {
      usedVehicleBuyerReport(appraisalId: $id) {
        url
      }
    }
  }
`;

const APPRAISAL_SHEET = gql`
  query appraisalSheet($id: Int!) {
    appraisals {
      appraisalSheet(appraisalId: $id) {
        url
      }
    }
  }
`;

const PDFMenu = ({ appraisal }) => {
  const { id } = appraisal;
  const { enqueueSnackbar } = useSnackbar();
  const [menuAnchor, setMenuAnchor] = useState();
  const handleMenuClick = e => setMenuAnchor(e.currentTarget);
  const handleMenuClose = () => setMenuAnchor(null);
  const classes = useStyles();

  const [getGreenSheet, { loading: loadingGreen }] = useLazyQuery(GREEN_SHEET, {
    fetchPolicy: 'network-only',
    variables: { id },
    onCompleted: data => {
      window.open(data.appraisals.tradeBookGreenSheet.url, '_blank');
      handleMenuClose();
    },
  });

  const [getUsedTruck, { loading: loadingTruck }] = useLazyQuery(USED_TRUCK, {
    fetchPolicy: 'network-only',
    variables: { id },
    onCompleted: data => {
      window.open(data.appraisals.usedTruckBuyerReport.url, '_blank');
      handleMenuClose();
    },
  });

  const [getUsedVehicle, { loading: loadingVehicle }] = useLazyQuery(
    USED_VEHICLE,
    {
      fetchPolicy: 'network-only',
      variables: { id },
      onCompleted: data => {
        window.open(data.appraisals.usedVehicleBuyerReport.url, '_blank');
        handleMenuClose();
      },
    },
  );

  const [getAppraisalSheet, { loading: loadingSheet }] = useLazyQuery(
    APPRAISAL_SHEET,
    {
      fetchPolicy: 'network-only',
      variables: { id },
      onCompleted: data => {
        window.open(data.appraisals.appraisalSheet.url, '_blank');
        handleMenuClose();
      },
      onError: ({ graphQLErrors }) =>
        enqueueSnackbar(
          `Error creating appraisal sheet: ${graphQLErrors
            .map(x => x.message)
            .join(', ')}`,
          { variant: 'error' },
        ),
    },
  );

  return (
    <>
      <LoadingBackdrop
        open={loadingGreen || loadingTruck || loadingVehicle || loadingSheet}
      >
        Generating PDF
      </LoadingBackdrop>
      <Menu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={getAppraisalSheet}>Appraisal Sheet</MenuItem>
        <MenuItem onClick={getGreenSheet}>Trade Book Green Sheet</MenuItem>
        <MenuItem onClick={getUsedTruck}>Used Truck Buyer's Report</MenuItem>
        <MenuItem onClick={getUsedVehicle}>
          Used Vehicle Buyer's Report
        </MenuItem>
      </Menu>
      <IconButton
        className={classes.pdfIcon}
        onClick={handleMenuClick}
        size="small"
      >
        <PdfIcon />
      </IconButton>
    </>
  );
};

const TradeInsCard = ({
  appraisals,
  defaultAppraisal = {},
  emptyMessage = 'No trades found.',
  allowNew,
  allowEdit,
  refetch,
  customer = {},
  ...rest
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const { enqueueSnackbar: snackIt } = useSnackbar();
  const { currentUser } = useUserContext();
  const isLocked = customer?.do_not_contact || false;
  const isHidden = currentUser.role !== Role.ORGANIZATION_ADMIN && isLocked;
  const [editAppraisal, setEditAppraisal] = useState();
  const [dialogPaveSessionId, setDialogPaveSessionId] = useState(null);
  const [deleteAppraisalCandidate, setDeleteAppraisalCandidate] =
    useState(null);
  const [deleteAppraisal, { loading: deleting }] =
    useMutation(DELETE_APPRAISAL);

  const sorted = appraisals.sort((a, b) =>
    b?.createdAt.localeCompare(a.createdAt),
  );

  const handleCreateAppraisal = (newAppraisal, options = {}) => {
    refetch().then(({ data }) =>
      defaultAppraisal.opportunityId
        ? setEditAppraisal(
            options.closeEditor
              ? null
              : data.opportunity.appraisals.find(
                  ({ id }) => id === newAppraisal.id,
                ),
          )
        : setEditAppraisal(
            options.closeEditor
              ? null
              : data.customer.appraisals.find(
                  ({ id }) => id === newAppraisal.id,
                ),
          ),
    );
  };

  const handleUpdateAppraisal = updatedAppraisal => {
    refetch(); // I don't like refetching, but to update the customer.appraisals in the
    // cache is really complicated.  TODO: don't be lazy and update the cache properly!
  };

  const handleDeleteAppraisal = () =>
    deleteAppraisal({ variables: { id: deleteAppraisalCandidate.id } }).then(
      () => refetch(),
      errorHandler(snackIt),
    );

  useEffect(() => {
    const newEditAppraisal = appraisals.find(
      ({ id }) => id === editAppraisal?.id,
    );
    if (newEditAppraisal) setEditAppraisal(newEditAppraisal);
  }, [appraisals, editAppraisal]);

  const showDelete = appraisal => appraisal.permissions.delete;

  const showEdit = appraisal =>
    allowEdit &&
    appraisal.permissions.update &&
    !(appraisal.paveSessionId && !appraisal.isPaveSessionCompleted);

  const showPDFs = appraisal => appraisal.opportunityId;

  return (
    <ResponsiveCard {...rest}>
      <LoadingBackdrop open={deleting}>Deleting appraisal...</LoadingBackdrop>
      <ConfirmDialog
        abortText="Cancel"
        confirmText="Delete"
        isOpen={Boolean(deleteAppraisalCandidate)}
        onClose={() => setDeleteAppraisalCandidate(null)}
        onConfirm={handleDeleteAppraisal}
        text={`Do you want to delete this appraisal?${
          deleteAppraisalCandidate?.pitches.length
            ? '  There are pitches that use this appraisal!'
            : ''
        }`}
      />

      <SendSMSDialog
        open={Boolean(dialogPaveSessionId)}
        onClose={() => setDialogPaveSessionId(null)}
        paveSessionId={dialogPaveSessionId}
      />

      <TradeFormDialog
        open={Boolean(editAppraisal)}
        fullWidth
        fullScreen={mobile}
        maxWidth="md"
        onClose={() => setEditAppraisal(null)}
        onCreate={handleCreateAppraisal}
        onUpdate={handleUpdateAppraisal}
        appraisal={editAppraisal}
      />
      <ResponsiveCardHeader style={{ marginBottom: '10px' }}>
        <Box display="flex" alignItems="center">
          <Box component="span" className={classes.title}>
            Trade Ins {lengthOrNull(appraisals)}
          </Box>
          {allowNew && !isHidden && (
            <Box component="span" marginLeft={3}>
              <IconButton
                onClick={() => setEditAppraisal(defaultAppraisal)}
                size="small"
              >
                <AddIcon style={theme.actions.add} />
              </IconButton>
            </Box>
          )}
        </Box>
      </ResponsiveCardHeader>
      <ResponsiveCardContent>
        {appraisals.length === 0 && emptyMessage}
        {sorted.map(appraisal => (
          <Box display="flex" key={appraisal.id} alignItems="center">
            <Box flexBasis="48px" flexGrow={0} flexShrink={0}>
              <CarIcon />
            </Box>
            <AppraisalSummary
              appraisal={appraisal}
              onClickResendSMS={() =>
                setDialogPaveSessionId(appraisal.paveSessionId)
              }
              width="100%"
            />
            <Box flexBasis="48px" flexGrow={0} flexShrink={0}>
              {showPDFs(appraisal) && <PDFMenu appraisal={appraisal} />}
            </Box>
            <Box flexBasis="48px" flexGrow={0} flexShrink={0}>
              {showEdit(appraisal) && (
                <IconButton
                  size="small"
                  className={classes.editIcon}
                  onClick={() => setEditAppraisal(appraisal)}
                >
                  <EditIcon />
                </IconButton>
              )}
            </Box>
            <Box flexBasis="48px" flexGrow={0} flexShrink={0}>
              {showDelete(appraisal) && (
                <IconButton
                  size="small"
                  className={classes.deleteIcon}
                  onClick={() => setDeleteAppraisalCandidate(appraisal)}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </Box>
          </Box>
        ))}
      </ResponsiveCardContent>
    </ResponsiveCard>
  );
};

TradeInsCard.fragments = {
  appraisal: gql`
    fragment TradeInsCardAppraisal on Appraisal {
      id
      createdAt
      isPaveSessionComplete
      opportunityId
      paveSessionId
      permissions {
        delete
        update
      }
      pitches {
        id
      }
      ...AppraisalSummaryAppraisal
      ...TradeFormDialogAppraisal
    }
    ${AppraisalSummary.fragments.appraisal}
    ${TradeFormDialog.fragments.appraisal}
  `,
};
export default TradeInsCard;
