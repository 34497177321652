// External
import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { startCase, toLower } from 'lodash';

// MUI
import {
  Box,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
} from '@mui/material';

// Internal
import {
  LoadingBackdrop,
  RadioControl,
  TextFieldControl,
} from 'components/MaterialUI';

import PanelTitle from './PanelTitle';
import {
  CreateMake,
  CreateModel,
  LazyEditor,
  LazySelect,
  TagsContainer,
  YearSelect,
} from '../common';
import {
  GET_TYPES_AND_MANUFACTURERS,
  GET_MAKES,
  GET_MODELS,
  GET_STOCK_STATUSES,
} from '../common/Queries';

const EditUnitInfo = ({ vehicle, formMethods }) => {
  const { watch } = formMethods;

  const npvTypeId = watch('npvTypeId');
  const npvManufacturerId = watch('npvManufacturerId');
  const npvMakeId = watch('npvMakeId');
  const selectedYear = watch('selectedYear');

  // Conditionally disallow 'null' value for odometer if odometerUnits is 'KM' or 'MI'
  // we want to allow 'null' values if the npv type does not have an odometer
  const odometerUnitValue = watch('odometerUnits');

  const { data: npvInitialData, loading: npvInitialLoading } = useQuery(
    GET_TYPES_AND_MANUFACTURERS,
  );

  const {
    data: npvMakeData,
    loading: npvMakesLoading,
    refetch: refetchMakes,
  } = useQuery(GET_MAKES, {
    variables: { year: selectedYear, manufacturerId: npvManufacturerId },
    skip: !npvManufacturerId,
  });

  const {
    data: npvModelData,
    loading: npvModelsLoading,
    refetch: refetchModels,
  } = useQuery(GET_MODELS, {
    variables: {
      makeId: npvMakeId,
      npvTypeId,
    },
    skip: !npvMakeId || !npvTypeId,
  });

  const { data: stockStatusesData, loading: stockStatusesLoading } =
    useQuery(GET_STOCK_STATUSES);

  const npvTypes = npvInitialData?.npv?.getNpvTypes ?? [];
  const npvManufacturers = npvInitialData?.npv?.getManufacturers ?? [];
  const npvMakes = npvMakeData?.npv?.getMakes ?? [];
  const npvModels = npvModelData?.npv?.getModels ?? [];
  const stockStatuses = stockStatusesData?.npv?.getStockStatuses ?? [];

  const loading =
    npvInitialLoading ||
    npvModelsLoading ||
    npvMakesLoading ||
    stockStatusesLoading;

  const titleCase = x => startCase(toLower(x));
  const formattedStockStatuses = stockStatuses.map(({ id, name }) => ({
    id,
    name: titleCase(name),
  }));

  return (
    <Paper>
      <LoadingBackdrop open={loading}>Loading...</LoadingBackdrop>
      <Box>
        <PanelTitle title="Details" vehicle={vehicle} />
      </Box>
      <Box p="20px">
        <Grid container spacing={4} style={{ padding: '10px 20px' }}>
          <Grid item xs={12} md={4}>
            <TextFieldControl
              label="Stock Type"
              name="stockType"
              muiVariant="outlined"
              disabled
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextFieldControl
              label="Stock Number"
              name="stockNumber"
              muiVariant="outlined"
              disabled
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <LazySelect
              label="Status"
              name="stockStatusId"
              options={formattedStockStatuses}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <LazySelect label="Type" name="npvTypeId" options={npvTypes} />
          </Grid>
          <Grid item xs={12} md={4}>
            <YearSelect name="selectedYear" />
          </Grid>
          <Grid item xs={12} md={4}>
            <LazySelect
              label="Manufacturer"
              name="npvManufacturerId"
              options={npvManufacturers}
            />
          </Grid>
          <Grid container item xs={12} md={4}>
            <Grid item xs={12} style={{ marginBottom: '8px' }}>
              <LazySelect label="Make" name="npvMakeId" options={npvMakes} />
            </Grid>
            <Grid item xs={12}>
              <CreateMake
                manufacturerId={npvManufacturerId}
                year={selectedYear}
                formMethods={formMethods}
                onCompleted={() => refetchMakes()}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} md={4}>
            <Grid item xs={12} style={{ marginBottom: '8px' }}>
              <LazySelect label="Model" name="modelId" options={npvModels} />
            </Grid>
            <Grid item xs={12}>
              <CreateModel
                makeId={npvMakeId}
                npvTypeId={npvTypeId}
                formMethods={formMethods}
                onCompleted={() => refetchModels()}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextFieldControl
              label="VIN"
              name="vin"
              muiVariant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextFieldControl
              fullWidth
              label="Odometer"
              muiVariant="outlined"
              name="odometer"
              type="number"
              disabled={odometerUnitValue === 'NA'}
              rules={{
                required:
                  odometerUnitValue !== 'NA' ? 'Odometer is required!' : false,
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <RadioControl
              label="Odometer Units"
              name="odometerUnits"
              as={
                <RadioGroup row>
                  <FormControlLabel
                    value="KM"
                    control={<Radio color="secondary" />}
                    label="Kilometers"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="MI"
                    control={<Radio color="secondary" />}
                    label="Miles"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="NA"
                    control={<Radio color="secondary" />}
                    label="N/A"
                    labelPlacement="end"
                  />
                </RadioGroup>
              }
            />
          </Grid>
          <Grid item xs={12}>
            <LazyEditor name="description" />
          </Grid>
          <Grid item xs={12}>
            <TagsContainer />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

EditUnitInfo.fragments = {
  vehicle: gql`
    fragment NpvVehicleInfo on NpvVehicle {
      id
      odometer
      odometerUnits
      description
      modelId
      model {
        id
        make {
          id
          manufacturer {
            id
            name
          }
          name
          year
        }
        name
        npvType {
          id
          name
        }
      }
      stockNumber
      stockStatusId
      stockStatus {
        id
        name
      }
      stockType
      trim
      vin
      ...NpvVehiclePanelTitle
      ...NpvVehicleTagsContainer
    }
    ${PanelTitle.fragments.vehicle}
    ${TagsContainer.fragments.vehicle}
  `,
};

export default EditUnitInfo;
