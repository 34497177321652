import React from 'react';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  textButton: {
    '-webkit-appearance': 'none',
    color: theme.colours.greys.grey2,
    background: 'transparent',
    textAlign: 'left',
    padding: '0.1rem',
    borderRadius: 0,
    '&:hover': {
      color: theme.palette.secondary.main,
      background: 'transparent',
    },
    '&:disabled': {
      color: theme.colours.greys.greytext2,
    },
    justifyContent: 'left',
    textTransform: 'unset',
    fontWeight: 500,
    fontSize: '1.4rem',
  },
}));

const TextButton = ({ children }) => (
  <Button className={useStyles().textButton}>{children}</Button>
);

export default TextButton;
