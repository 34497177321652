import React from 'react';

/* External */
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';

/* Material UI */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

// Internal
import { TextFieldControl } from 'components/MaterialUI';
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';
import { formatPhoneNumber, numberWithCommas } from 'utils';
import { errorHandler } from 'modules/pitches/utils';

const useStyles = makeStyles(theme => ({
  boldTitle: {
    fontWeight: 'bold',
    fontSize: '1.2rem',
  },
  textField: {
    width: '90%',
    margin: '5px 0',
  },
}));

const GET_GLOBAL_SUPPRESION = gql`
  query getGlobalSuppression($number: String!) {
    sms {
      globalSuppression(number: $number) {
        id
        number
        normalized_number
        suppressed
      }
    }
  }
`;

const CREATE_GLOBAL_SUPPRESSION = gql`
  mutation createGlobalSuppression($suppression: GlobalSuppressionInput!) {
    sms {
      createGlobalSuppression(suppression: $suppression) {
        id
        number
        normalized_number
        suppressed
      }
    }
  }
`;

const DELETE_GLOBAL_SUPPRESSION = gql`
  mutation deleteGlobalSuppression($number: String!) {
    sms {
      deleteGlobalSuppression(number: $number) {
        id
        number
        normalized_number
        suppressed
      }
    }
  }
`;

const GET_GLOBAL_SUPPRESSION_COUNT = gql`
  query getGlobalSuppressionCount {
    sms {
      globalSuppressionCount {
        count
      }
    }
  }
`;

const GlobalSuppressionModal = ({ open, onClose }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit, setError } = useForm({
    defaultValues: {
      number: '',
    },
  });

  const { data: globalSuppressionCountData } = useQuery(
    GET_GLOBAL_SUPPRESSION_COUNT,
  );

  const [createGlobalSuppression, { loading }] = useMutation(
    CREATE_GLOBAL_SUPPRESSION,
    {
      onCompleted: () => {
        enqueueSnackbar('Global suppression created successfully', {
          variant: 'success',
        });
        onClose();
      },
      onError: error => {
        errorHandler(enqueueSnackbar, setError, { snackAll: true })(error);
      },
    },
  );

  const [deleteGlobalSuppression, { loading: deleteLoading }] = useMutation(
    DELETE_GLOBAL_SUPPRESSION,
    {
      onCompleted: () => {
        enqueueSnackbar('Global suppression deleted successfully', {
          variant: 'success',
        });
        onClose();
      },
      onError: error => {
        errorHandler(enqueueSnackbar, setError, { snackAll: true })(error);
      },
    },
  );

  const [getGlobalSuppression, { data: globalSuppressionData }] = useLazyQuery(
    GET_GLOBAL_SUPPRESION,
    {
      onError: error => {
        errorHandler(enqueueSnackbar, setError, { snackAll: true })(error);
      },
      fetchPolicy: 'network-only',
    },
  );

  const onSubmit = data => {
    getGlobalSuppression({ variables: { number: data.number } });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle style={{ paddingBottom: '10px' }}>
        <Typography className={classes.boldTitle}>
          Global Suppression
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography style={{ fontWeight: 'bold' }}>
          {numberWithCommas(
            globalSuppressionCountData?.sms?.globalSuppressionCount.count,
          )}{' '}
          phone numbers suppressed
        </Typography>
        <Typography style={{ padding: '20px 0' }}>
          Enter a phone number to check its suppression status:
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextFieldControl
            name="number"
            control={control}
            label="Phone Number"
            className={classes.textField}
            required
            muiVariant="outlined"
            rules={{
              validate: value =>
                Boolean(formatPhoneNumber(value)) || 'Invalid phone number',
            }}
          />
          <DialogActions
            style={{ justifyContent: 'flex-start', paddingLeft: '0' }}
          >
            <Button
              style={{
                color: theme.colours.add,
                borderColor: theme.colours.add,
              }}
              variant="outlined"
              type="submit"
            >
              Check Number
            </Button>
          </DialogActions>
          {globalSuppressionData?.sms.globalSuppression?.suppressed ===
            false && (
            <>
              <Typography style={{ padding: '10px 0', fontWeight: 'bold' }}>
                NUMBER NOT FOUND
              </Typography>
              <Typography>
                Would you like to add{' '}
                <strong>
                  {globalSuppressionData.sms.globalSuppression.number}
                </strong>{' '}
                to the global suppression list?
              </Typography>
              <DialogActions
                style={{ justifyContent: 'flex-start', paddingLeft: '0' }}
              >
                <Button
                  style={{ ...theme.actions.add, marginRight: '10px' }}
                  variant="contained"
                  onClick={() =>
                    createGlobalSuppression({
                      variables: {
                        suppression: {
                          number:
                            globalSuppressionData.sms.globalSuppression.number,
                        },
                      },
                    })
                  }
                >
                  Yes
                </Button>
                <Button
                  style={{
                    color: theme.colours.add,
                    borderColor: theme.colours.add,
                  }}
                  variant="outlined"
                  onClick={() => onClose()}
                >
                  No
                </Button>
              </DialogActions>
            </>
          )}
          {globalSuppressionData?.sms.globalSuppression.suppressed && (
            <>
              <Typography style={{ padding: '10px 0', fontWeight: 'bold' }}>
                NUMBER FOUND
              </Typography>
              <Typography>
                Would you like to remove{' '}
                <strong>
                  {globalSuppressionData.sms.globalSuppression.number}
                </strong>{' '}
                from the global suppression list?
              </Typography>
              <DialogActions
                style={{ justifyContent: 'flex-start', paddingLeft: '0' }}
              >
                <Button
                  style={theme.actions.add}
                  variant="contained"
                  onClick={() =>
                    deleteGlobalSuppression({
                      variables: {
                        number:
                          globalSuppressionData.sms.globalSuppression.number,
                      },
                    })
                  }
                >
                  Yes
                </Button>
                <Button
                  style={{
                    color: theme.colours.add,
                    borderColor: theme.colours.add,
                  }}
                  variant="outlined"
                  onClick={() => onClose()}
                >
                  No
                </Button>
              </DialogActions>
            </>
          )}
          <DialogActions>
            <Button variant="outlined" onClick={() => onClose()}>
              Close
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
      <LoadingBackdrop open={loading || deleteLoading} />
    </Dialog>
  );
};

export default GlobalSuppressionModal;
