import { flatten } from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import { useHistory } from 'react-router-dom';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import Typography from '@mui/material/Typography';
import Loading from 'components/MaterialUI/Loading';

import {
  IN_STOCK_FACET,
  LEADS_REQUIRING_ATTENTION_FACET,
  MISSING_STUFF_FACET,
  sessionKeys,
} from '../../constants';
import {
  getDaysInStockFilter,
  getInventoryNumbers,
  getLastPriceChangeFilter,
  getManagerDashboardStatistics,
} from '../../utils';
import LeadSummaryTables from './LeadSummaryTables';
import { Status } from 'constants.js';
import {
  AppointmentsTable,
  CompleteGross,
  DealTable,
  IncompletePhotos,
  InStockGtView,
  InventoryHeader,
  OpportunityTable,
  PriceChanges,
  SalesHeader,
  TotalGross,
  useManagerDashboardLogic,
  VehiclesInfo,
} from './shared';

const useStyles = makeStyles(theme => ({
  inventoryCard: {
    width: '150px',
    height: '125px',
    textAlign: 'center',
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  card: {
    width: '150px',
    height: '125px',
    textAlign: 'center',
    padding: '1rem',
    fontSize: '18px',
    fontWeight: 'bold',
  },
}));

const ManagerDashboardMobile = ({ classes, dealerId }) => {
  const history = useHistory();
  const { card, inventoryCard } = useStyles();
  const {
    opportunityDateFromFilter,
    opportunityDateToFilter,
    inventoryDateFromFilter,
    inventoryDateToFilter,
    handleDateFromChange,
    handleDateToChange,
    handleInventoryDateFromChange,
    managerQuery: { results, loading },
    inventoryQuery: { data: inventoryData },
    opp_totals,
    opp_table,
    deallog_table,
    deallog_totals,
    appt_totals,
    inventoryFilterHandler,
    handleAppointmentsCardClick,
    handleUpcomingAppointmentsCardClick,
    handleAttendedAppointmentsCardClick,
    inventoryDateSince,
  } = useManagerDashboardLogic('managerDashboardMobile', dealerId);
  if (loading) return <Loading text="Loading Your Dashboard! Please wait." />;
  const daysInStockFilter = getDaysInStockFilter(inventoryDateSince);
  const lastPriceChangeFilter = getLastPriceChangeFilter(
    inventoryDateFromFilter,
  );

  const { upcomingCount, attendedCount, createdTodayCount } =
    getManagerDashboardStatistics(results);

  const {
    newVehiclesInStock,
    usedVehiclesInStock,
    totalIncomplete,
    lastPriceChangeLt,
    inStockGt,
  } = getInventoryNumbers(inventoryData, inventoryDateSince);

  let appt_table = results?.appt_table?.filter(x => x.date_range > 0) || [];

  appt_table.sort((a, b) => b.next_7_days - a.next_7_days);

  const totalVehicles = newVehiclesInStock + usedVehiclesInStock;

  const setOppFiltersStatus = (
    statuses,
    today = false,
    MTD = false,
    unassigned = false,
    dateFrom = null,
    dateTo = null,
  ) => {
    const dateToday = moment().format('YYYY-MM-DD');

    const status_date =
      dateFrom && dateTo
        ? { date_to: dateTo, date_from: dateFrom }
        : today
        ? { date_to: dateToday, date_from: dateToday }
        : MTD
        ? {
            date_to: moment().endOf('month').format('YYYY-MM-DD'),
            date_from: moment().startOf('month').format('YYYY-MM-DD'),
          }
        : null;

    const filters = {
      statuses,
      sort_by: [
        {
          key: 'created',
          direction: -1,
        },
      ],
    };

    const variables = unassigned
      ? { ...filters, assignees: ['unassigned'] }
      : status_date
      ? { ...filters, status_date }
      : filters;
    window.sessionStorage.setItem(
      sessionKeys.oppFilters,
      JSON.stringify({
        ...variables,
        ...{ sort_by: [{ key: 'created', direction: -1 }] },
      }),
    );
    window.sessionStorage.setItem(sessionKeys.oppDealer, dealerId);
  };

  const inventoryFacetHandler = (...facets) => {
    if (facets) {
      window.sessionStorage.setItem(
        sessionKeys.inventoryFacets,
        JSON.stringify(flatten(facets)),
      );
    }
    window.sessionStorage.setItem(sessionKeys.inventoryDealerId, dealerId);
    history.push('/inventory');
  };

  const leadsFacetHandler = facets => {
    window.sessionStorage.setItem(
      sessionKeys.leadsFacets,
      JSON.stringify(facets),
    );
    window.sessionStorage.setItem(sessionKeys.leadsDealerId, dealerId);
    history.push('/leads?filters=dashboard');
  };

  return (
    <>
      <InventoryHeader
        handleInventoryDateFromChange={handleInventoryDateFromChange}
        inventoryDateFromFilter={inventoryDateFromFilter}
        inventoryDateToFilter={inventoryDateToFilter}
      />
      <Box paddingTop={2} paddingLeft={1}>
        <Paper
          elevation={0}
          style={{ backgroundColor: '#f2f2f2', maxWidth: '1000px' }}
        >
          <Grid
            container
            direction="row"
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
              padding: '1rem',
            }}
            xs={12}
          >
            <Grid item xs={6} style={{ paddingBottom: '1rem' }}>
              <Card
                className={inventoryCard}
                onClick={() => {
                  inventoryFacetHandler(IN_STOCK_FACET);
                  inventoryFilterHandler();
                }}
              >
                <VehiclesInfo
                  totalVehicles={totalVehicles}
                  newVehiclesInStock={newVehiclesInStock}
                  usedVehiclesInStock={usedVehiclesInStock}
                />
              </Card>
            </Grid>
            <Grid item xs={6} style={{ paddingBottom: '1rem' }}>
              <Card
                className={inventoryCard}
                onClick={() => {
                  inventoryFacetHandler(IN_STOCK_FACET);
                  inventoryFilterHandler(daysInStockFilter);
                }}
              >
                <InStockGtView
                  inStockGt={inStockGt}
                  totalVehicles={totalVehicles}
                  inventoryDateSince={inventoryDateSince}
                />
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card
                className={inventoryCard}
                onClick={() => {
                  inventoryFacetHandler(IN_STOCK_FACET);
                  inventoryFilterHandler(lastPriceChangeFilter);
                }}
              >
                <PriceChanges
                  lastPriceChangeLt={lastPriceChangeLt}
                  inventoryDateSince={inventoryDateSince}
                />
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card
                className={inventoryCard}
                onClick={() => inventoryFacetHandler(MISSING_STUFF_FACET)}
              >
                <IncompletePhotos totalIncomplete={totalIncomplete} />
              </Card>
            </Grid>
          </Grid>
        </Paper>
      </Box>
      <SalesHeader
        handleDateFromChange={handleDateFromChange}
        handleDateToChange={handleDateToChange}
        opportunityDateFromFilter={opportunityDateFromFilter}
        opportunityDateToFilter={opportunityDateToFilter}
      />
      <Box paddingTop={2} paddingLeft={1}>
        <Paper
          elevation={0}
          style={{ backgroundColor: '#f2f2f2', maxWidth: '1000px' }}
        >
          <Grid
            container
            direction="row"
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
              padding: '1rem',
            }}
            xs={12}
          >
            <Grid item xs={6} style={{ paddingBottom: '1rem' }}>
              <Card
                className={card}
                onClick={() => {
                  setOppFiltersStatus([Status.FRESH], true);
                  history.push('/opportunities/search');
                }}
              >
                <Box>{results?.new_opps_today}</Box>
                <Box>New Opportunities (Today)</Box>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card
                className={card}
                onClick={() => {
                  setOppFiltersStatus(
                    [Status.FRESH],
                    false,
                    false,
                    false,
                    opportunityDateFromFilter,
                    opportunityDateToFilter,
                  );
                  history.push('/opportunities/search');
                }}
              >
                <Box>{results?.new_opps_date_range || 0}</Box>
                <Box>New Opportunities</Box>
              </Card>
            </Grid>
            <Grid item xs={6} style={{ paddingBottom: '1rem' }}>
              <Card
                className={card}
                onClick={() => {
                  setOppFiltersStatus(Status.OPEN_OPPS, false, false, true);
                  history.push('/opportunities/search');
                }}
              >
                <Box>{results?.unassigned_opps || 0}</Box>
                <Box>Unassigned Opportunities</Box>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card
                className={card}
                onClick={() => {
                  setOppFiltersStatus([Status.LOST], false, false);
                  history.push('/opportunities/search');
                }}
              >
                <Box>{results?.lost_opps || 0}</Box>
                <Box>Lost Opportunities</Box>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card
                className={card}
                onClick={() =>
                  leadsFacetHandler(LEADS_REQUIRING_ATTENTION_FACET)
                }
              >
                <Box>{results?.leads_needing_attention || 0}</Box>
                <Box>Leads Requiring Attention</Box>
              </Card>
            </Grid>
          </Grid>
          <Box>
            <hr />
          </Box>
          <Box paddingTop={2} paddingLeft={1}>
            <Typography variant="h6" style={{ fontWeight: 'bold' }}>
              Open Opportunities
            </Typography>
          </Box>
          <Grid
            container
            direction="row"
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
              padding: '1rem',
            }}
            xs={12}
          >
            <Grid item xs={6}>
              <Box style={{ paddingRight: '1rem' }}>
                <Card
                  className={card}
                  onClick={() => {
                    setOppFiltersStatus(
                      Status.OPEN_OPPS,
                      false,
                      false,
                      false,
                      opportunityDateFromFilter,
                      opportunityDateToFilter,
                    );
                    history.push('/opportunities/search');
                  }}
                >
                  <Box>{results?.open_opps}</Box>
                  <Box>Open Opportunities</Box>
                </Card>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box style={{ paddingRight: '1rem' }}>
                <Card
                  style={{
                    width: '150px',
                    height: '125px',
                    textAlign: 'center',
                    padding: '1rem',
                    fontSize: '14px',
                    fontWeight: 'bold',
                  }}
                  onClick={() => {
                    setOppFiltersStatus(
                      Status.OPEN_OPPS,
                      false,
                      false,
                      false,
                      opportunityDateFromFilter,
                      opportunityDateToFilter,
                    );
                    history.push('/opportunities/search');
                  }}
                >
                  <Box>{results?.not_updated_opps}</Box>
                  <Box>Open Opportunities No Updates</Box>
                </Card>
              </Box>
            </Grid>
          </Grid>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              padding: '5px',
            }}
          >
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginLeft: 'auto',
                marginRight: 'auto',
                paddingTop: '1rem',
              }}
            >
              <Paper>
                <Box>
                  <TableContainer style={{ maxHeight: 230 }}>
                    <Table stickyHeader size="small">
                      <OpportunityTable
                        opportunityDateFromFilter={opportunityDateFromFilter}
                        opportunityDateToFilter={opportunityDateToFilter}
                        opp_table={opp_table}
                        opp_totals={opp_totals}
                        classes={classes}
                        mobile
                      />
                    </Table>
                  </TableContainer>
                </Box>
              </Paper>
            </Box>
          </Box>
          <Box>
            <hr />
          </Box>
          <Box paddingTop={2} paddingLeft={1}>
            <Typography variant="h6" style={{ fontWeight: 'bold' }}>
              Deal Log Summary
            </Typography>
            <Grid
              container
              direction="row"
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                padding: '1rem',
              }}
            >
              <Grid item xs={12} style={{ paddingBottom: '1rem' }}>
                <Grid container direction="column">
                  <Grid item xs={12}>
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Card
                        className={card}
                        onClick={() => {
                          setOppFiltersStatus([Status.PENDING]);
                          history.push('/opportunities/search');
                        }}
                      >
                        <Box>{results?.pending_opps}</Box>
                        <Box>Pending Deals</Box>
                      </Card>
                      <Card
                        className={card}
                        onClick={() => {
                          setOppFiltersStatus(Status.OPEN);
                          history.push('/opportunities/search');
                        }}
                      >
                        <Box>{results?.booked_opps}</Box>
                        <Box>Booked Deals</Box>
                      </Card>
                    </Box>
                  </Grid>
                  <TotalGross results={results} />
                </Grid>
                <Grid item xs={12}>
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-around',
                    }}
                  >
                    <Card
                      className={card}
                      onClick={() => {
                        setOppFiltersStatus(Status.COMPLETED);
                        history.push('/opportunities/search');
                      }}
                    >
                      <Box>{results?.sold_opps}</Box>
                      <Box>Sold Deals</Box>
                    </Card>
                  </Box>
                  <CompleteGross results={results} />
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Paper style={{ margin: '5px' }}>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginBottom: '15px',
              }}
            >
              <TableContainer style={{ maxHeight: 230 }}>
                <DealTable
                  classes={classes}
                  deallog_table={deallog_table}
                  deallog_totals={deallog_totals}
                />
              </TableContainer>
            </Box>
          </Paper>
          <LeadSummaryTables
            data={results}
            dealerId={dealerId}
            isDesktop={false}
            dateFrom={opportunityDateFromFilter}
            dateTo={opportunityDateToFilter}
          />
          <Box>
            <hr />
          </Box>
          <Box paddingTop={2} paddingLeft={1}>
            <Typography variant="h6" style={{ fontWeight: 'bold' }}>
              Appointments
            </Typography>
            <Grid
              container
              direction="row"
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
              }}
              xs={12}
            >
              <Grid item xs={6} style={{ paddingBottom: '1rem' }}>
                <Card
                  className={card}
                  onClick={handleUpcomingAppointmentsCardClick}
                >
                  <Box>{upcomingCount}</Box>
                  <Box>Upcoming Appointments</Box>
                </Card>
              </Grid>
              <Grid item xs={6} style={{ paddingBottom: '1rem' }}>
                <Card
                  className={card}
                  onClick={handleAttendedAppointmentsCardClick}
                >
                  <Box>{attendedCount}</Box>
                  <Box>Appointments Attended (Last 7 days)</Box>
                </Card>
              </Grid>
              <Grid item xs={6} style={{ paddingBottom: '1rem' }}>
                <Card className={card} onClick={handleAppointmentsCardClick}>
                  <Box>{createdTodayCount}</Box>
                  <Box style={{ fontSize: '14px' }}>
                    Appointments Created Today
                  </Box>
                </Card>
              </Grid>
            </Grid>
          </Box>
          <Box>
            <Paper>
              <Box>
                <TableContainer style={{ maxHeight: 230 }}>
                  <AppointmentsTable
                    classes={classes}
                    opportunityDateFromFilter={opportunityDateFromFilter}
                    opportunityDateToFilter={opportunityDateToFilter}
                    appt_table={appt_table}
                    appt_totals={appt_totals}
                  />
                </TableContainer>
              </Box>
            </Paper>
          </Box>
        </Paper>
      </Box>
    </>
  );
};

export default ManagerDashboardMobile;
