/* external */
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';

/* Material UI */
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import FolderIcon from '@mui/icons-material/Folder';

/* internal */
import { useUserContext } from 'components/MaterialUI/UserContext';

import { Role } from 'constants.js';

const ADD_ATTACHMENT_MUTATION = gql`
  mutation addAttachment($_id: ID!, $input: AttachmentUpload!) {
    uploadAttachment(_id: $_id, input: $input) {
      attachments {
        _id
        attachment_type
        key
        label
        created_by
        created_by_name
        date_created
        deleted
        file_tag
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  button: {
    color: theme.colours.add,
    fontWeight: 'bold',
  },
  uploadButton: {
    backgroundColor: theme.colours.add,
    marginLeft: '15px',
    height: '40px',
  },
  mobileUpload: {
    backgroundColor: theme.colours.add,
    marginTop: '10px',
    height: '40px',
  },
}));

function getBase64(file) {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      resolve(reader.result);
    };
    reader.onerror = reject;
  });
}

const OpportunityAttachmentModal = ({
  handleClose,
  opportunityId,
  dealNumber,
  refetch,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { currentUser } = useUserContext();
  const desktop = useMediaQuery(theme => theme.breakpoints.up('sm'));

  const [label, setLabel] = useState('');
  const [type, setType] = useState('Other');
  const [uploadData, setUploadData] = useState({});
  const [addAttachment] = useMutation(ADD_ATTACHMENT_MUTATION);

  const handleTypeChange = event => {
    setType(event.target.value);
  };

  const handleUpload = event => {
    addAttachment({
      variables: {
        _id: opportunityId,
        input: {
          filename: uploadData.label,
          data: uploadData.dataURI,
          extension: uploadData.extension,
          attachment_type: 'opportunity',
          file_tag: type,
        },
      },
    })
      .then(r => {
        enqueueSnackbar('Attachment added!', { variant: 'success' });
        refetch();
        handleClose();
      })
      .catch(e => {
        enqueueSnackbar(`An error has occurred: ${JSON.stringify(e)}`, {
          variant: 'error',
        });
      });
  };

  async function handleLabelChange(event) {
    const files = event.target.files;
    const extension = files[0].name.split('.').slice(-1)[0];
    let base64file = await getBase64(files[0]);
    const data = {
      label: files[0].name,
      dataURI: base64file,
      extension: extension,
    };
    setUploadData(data);
    setLabel(files[0].name);
  }

  const menuItems = ['Funding Notice', 'Deal Jacket', 'AMVIC Inspection'];

  return (
    <>
      <DialogTitle>
        <Typography variant="h5">
          Add attachment to Deal #{dealNumber} - {currentUser.display_name}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <form style={{ paddingTop: '1rem' }}>
          <TextField
            variant="outlined"
            size="small"
            style={{
              maxWidth: '350px',
            }}
            disabled
            value={label}
          />
          <span
            style={{
              paddingLeft: '1rem',
            }}
          >
            <input
              className={classes.input}
              id="attachment-file"
              type="file"
              onChange={handleLabelChange}
              hidden
            />
            <label htmlFor="attachment-file">
              <Button
                variant="contained"
                component="span"
                color="primary"
                className={
                  desktop ? classes.uploadButton : classes.mobileUpload
                }
              >
                <FolderIcon fontSize="small" style={{ marginRight: '5px' }} />
                BROWSE
              </Button>
            </label>
          </span>
          <div style={{ padding: '1rem 0 1rem 0' }}>
            <FormControl>
              <InputLabel id="attachment-type">Type</InputLabel>
              <Select
                labelId="attachment-type"
                value={type}
                style={{ width: '200px' }}
                onChange={handleTypeChange}
              >
                <MenuItem value="Other">Other</MenuItem>
                {Role.SALES_REP !== currentUser.role &&
                  menuItems.map(menuItem => (
                    <MenuItem value={menuItem}>{menuItem}</MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
        </form>
      </DialogContent>
      <DialogActions>
        <Button className={classes.button} onClick={handleClose}>
          Close
        </Button>
        <Button
          className={classes.button}
          onClick={handleUpload}
          disabled={label === ''}
        >
          Save
        </Button>
      </DialogActions>
    </>
  );
};

export default OpportunityAttachmentModal;
