import React from 'react';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import ServiceAppointmentActions from './ServiceAppointmentActions';

const ServiceAppointmentDialog = ({
  modalOpen,
  handleModalClose,
  singleData,
  formatName,
  classes,
  handleOpenUpdateSalesperson,
}) => (
  <Dialog open={modalOpen} onClose={handleModalClose} maxWidth="md">
    <DialogContent>
      {!singleData && (
        <Box fontSize="20px" padding="1rem">
          No Appointments Available.
        </Box>
      )}
      {singleData && (
        <Box paddingBottom={1}>
          <Grid container direction="row">
            <Grid item xs={12}>
              <Box paddingBottom={1}>
                <Typography
                  style={{
                    fontSize: '1.875rem',
                    fontWeight: '700',
                  }}
                >
                  {`${moment
                    .utc(singleData.appointment_date)
                    .format('h:mma')} - ${moment
                    .utc(singleData.appointment_date, 'YYYY-MM-DDTHH:mm:ssZ')
                    .add(singleData.duration, 'hours')
                    .format('h:mma')}`}
                </Typography>
                <Typography
                  style={{
                    fontSize: '1.875rem',
                    fontWeight: '700',
                    paddingBottom: '.5rem',
                  }}
                >
                  {`${moment
                    .utc(singleData.appointment_date)
                    .format('dddd, MMMM Do YYYY')}`}
                </Typography>
                <b>
                  Service Advisor:{' '}
                  {singleData.service_person &&
                    formatName(singleData.service_person)}
                </b>
                <hr />
              </Box>
            </Grid>
          </Grid>
          <Grid container direction="row">
            <Grid item xs={6}>
              <Box marginBottom=".5rem" className={classes.customer}>
                <b>Customer: </b>
                {singleData.customer ? (
                  <>
                    <Link to={`/customers/${singleData.customer._id}/details`}>
                      {singleData.customer.fullname || ''}
                    </Link>
                    {singleData.customer.primary_email ? (
                      <>
                        <br />
                        <i className="fas fa-envelope fa-fw" />{' '}
                        {singleData.customer.primary_email || ''}
                      </>
                    ) : (
                      ''
                    )}
                    {singleData.customer.primary_phone ? (
                      <>
                        <br />
                        <i className="fas fa-mobile-alt fa-fw" />{' '}
                        {singleData.customer.primary_phone || ''}
                      </>
                    ) : (
                      ''
                    )}
                  </>
                ) : singleData.first_name ? (
                  `${singleData.first_name} `
                ) : `${singleData.last_name}` ? (
                  singleData.last_name
                ) : (
                  ''
                )}
              </Box>
              <ServiceAppointmentActions
                classes={classes}
                handleOpenUpdateSalesperson={handleOpenUpdateSalesperson}
                appointment={singleData}
              />
            </Grid>
            <Grid item xs={6} style={{ textAlign: 'right' }}>
              <Box fontSize="1.25rem" fontWeight="700">
                {singleData.year} {singleData.make_name} {singleData.model_name}
                <Typography
                  style={{
                    border: '1px solid #C5C5C5',
                    padding: '5px',
                    marginTop: '.5rem',
                  }}
                >
                  VIN: {singleData.vin}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </DialogContent>
    <DialogActions style={{ margin: '5px' }}>
      <Button onClick={handleModalClose} color="primary" variant="outlined">
        Close
      </Button>
    </DialogActions>
  </Dialog>
);

export default ServiceAppointmentDialog;
