import { useMutation, useQuery } from '@apollo/react-hooks';
import Avatar from '@mui/material/Avatar';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import CheckIcon from '@mui/icons-material/Check';
import FilterListIcon from '@mui/icons-material/FilterList';
import { NetworkStatus } from 'apollo-boost';
import { useUserContext } from 'components/MaterialUI/UserContext';
import { Role, RoleBadgeDisplay } from 'constants.js';
import gql from 'graphql-tag';
import moment from 'moment-timezone';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { defaultTZ } from 'utils';

const ADD_NOTE_TO_OPPORTUNITY = gql`
  mutation addNote($_id: ID!, $noteData: AddNoteToOpportunityInput!) {
    addNoteOnOpportunity(_id: $_id, input: $noteData) {
      _id
    }
  }
`;

const GET_NOTES_FOR_OPP = gql`
  query oppNote($_id: ID!) {
    opportunity(_id: $_id) {
      notes {
        _id
        body
        created
        visibility_display
        visibility
        dealer_id
        created_by {
          display_name
          role
        }
        dealer {
          dealer_name
        }
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  input: {
    display: 'none',
  },
  backdrop: {
    zIndex: 1000,
    color: '#fff',
  },
  formControl: {
    margin: theme.spacing(1),
    width: '30%',
    paddingRight: '1rem',
    marginLeft: '22px',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  tableDateCell: {
    width: '30%',
    whiteSpace: 'pre-line',
  },
  tableBodyCell: {
    width: '55%',
  },
  div: {
    padding: '0px 1rem 1rem',
    display: 'flex',
    flexDirection: 'row',
  },
  textField: {
    maxWidth: '60%',
    width: '100%',
    display: 'inline-grid',
    margin: '8px 0px',
  },
  saveButton: {
    height: 'fit-content',
    marginTop: '25px',
    backgroundColor: '#74B72E',
  },
  dialogTitle: {
    padding: '16px 38px',
  },
  closeButton: {
    fontWeight: '600',
    fontSize: 16,
    color: '#24a0ed',
  },
  avatarCell: {
    maxWidth: '60px',
    verticalAlign: 'initial',
  },
}));

const DealLogOpportunityNotes = ({ handleClose, opportunity }) => {
  const isDesktop = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const { currentUser } = useUserContext();
  const timezone = currentUser?.goUserProfile?.settings?.timezone || defaultTZ;
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const theme = useTheme();
  const [newNote, setNewNote] = useState('');
  const [noteVisiblity, setNoteVisibility] = useState('store_only');
  const [hideSysNotes, setHideSysNotes] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const deal_number = opportunity.dms_deal?.deal_number || 'No deal found';
  const userDealers = currentUser.dealers.map(({ dealer_id }) => dealer_id);

  const [addNote, { loading: mutationLoading }] = useMutation(
    ADD_NOTE_TO_OPPORTUNITY,
  );
  const {
    data: oppData,
    loading: oppLoading,
    refetch,
    networkStatus,
  } = useQuery(GET_NOTES_FOR_OPP, {
    variables: { _id: opportunity._id },
    notifyOnNetworkStatusChange: true,
  });

  const notes = oppData?.opportunity?.notes || [];
  const isRefetching = networkStatus === NetworkStatus.refetch;

  const handleNewNote = event => {
    setNewNote(event.target.value);
  };

  const handleVisibility = event => {
    setNoteVisibility(event.target.value);
  };

  const changeSystemNotes = () => setHideSysNotes(prev => !prev);

  const handleFilterClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const handleAddNote = () => {
    const noteData = {
      body: newNote,
      visibility: noteVisiblity,
    };
    const opp_id = opportunity._id;
    if (!noteData.body) {
      enqueueSnackbar('Did not save, note is empty.');
    } else {
      addNote({
        variables: {
          _id: opp_id,
          noteData: noteData,
        },
      })
        .catch(e => {
          enqueueSnackbar(`Could not save note error: ${e}`);
          console.log(e);
        })
        .then(opp => {
          refetch();
          enqueueSnackbar('Note saved!');
          setNewNote('');
          setNoteVisibility('store_only');
        });
    }
  };

  const canViewNote = note => {
    if (note.visibility === 'store_only') {
      return userDealers.includes(note.dealer_id);
    } else if (note.visibility === 'managers_only') {
      return (
        Role.ALL_SALES_MANAGERS.includes(currentUser.role) ||
        currentUser.role === Role.ORGANIZATION_ADMIN
      );
    }
    // Only other option is org wide
    return true;
  };

  return (
    <>
      <DialogTitle className={classes.dialogTitle}>
        <h2>
          Notes for Deal #{deal_number} - {currentUser.display_name} -{' '}
          {opportunity.customer_name}
        </h2>
      </DialogTitle>
      <DialogContent>
        <div className={isDesktop ? classes.div : ''}>
          <IconButton
            className={classes.filterButton}
            color="primary"
            onClick={handleFilterClick}
            size="large"
          >
            <FilterListIcon style={theme.actions.filter} />
          </IconButton>
          <Menu
            id="filter-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleFilterClose}
          >
            <MenuItem>
              <FormControlLabel
                label="Hide System Notes"
                control={
                  <Checkbox
                    name="system-notes"
                    onChange={changeSystemNotes}
                    checked={hideSysNotes}
                    color="primary"
                  />
                }
              />
            </MenuItem>
          </Menu>
          <TextField
            className={classes.textField}
            label="New Note"
            value={newNote}
            onChange={handleNewNote}
            style={{
              width: `${isDesktop ? '' : '150px'}`,
            }}
          />
          <FormControl
            className={classes.formControl}
            style={{
              width: `${isDesktop ? '' : '150px'}`,
              margin: `${isDesktop ? '' : 0}`,
              padding: `${isDesktop ? '' : 0}`,
            }}
          >
            <InputLabel shrink>Visibility</InputLabel>
            <Select
              value={noteVisiblity}
              onChange={handleVisibility}
              label="Visibility"
            >
              <MenuItem value="store_only">Store Only</MenuItem>
              <MenuItem value="managers_only">Managers Only</MenuItem>
              <MenuItem value="organization_wide">Organization Wide</MenuItem>
            </Select>
          </FormControl>
          <Button
            className={classes.saveButton}
            variant="contained"
            color="secondary"
            onClick={handleAddNote}
            startIcon={<CheckIcon />}
            size="small"
            style={{ width: `${isDesktop ? '' : '150px'}` }}
          >
            Save
          </Button>
        </div>
        <Table>
          <TableBody>
            {notes &&
              notes
                .filter(
                  note => !hideSysNotes || !note.body.includes('- System'),
                )
                .map(
                  note =>
                    canViewNote(note) && (
                      <TableRow key={note._id}>
                        <TableCell className={classes.avatarCell}>
                          {note.created_by && (
                            <Avatar>
                              {RoleBadgeDisplay[note.created_by.role]}
                            </Avatar>
                          )}
                          {!note.created_by ?? ''}
                          <div
                            style={{
                              whiteSpace: 'pre-line',
                              paddingTop: '1rem',
                            }}
                          />
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {note.created_by && (
                            <h4 style={{ font: 'icon' }}>
                              {' '}
                              {note.created_by?.display_name}{' '}
                            </h4>
                          )}
                          <div
                            style={{
                              whiteSpace: 'pre-line',
                              paddingTop: '1rem',
                            }}
                          >
                            {note.body}
                          </div>
                        </TableCell>
                        <TableCell className={classes.tableDateCell}>
                          {`${moment(note.created)
                            .tz(timezone)
                            .format('MMMM Do YYYY, h:mm a')}\n@ ${
                            note.dealer.dealer_name
                          }`}
                        </TableCell>
                        <TableCell style={{ width: '10%' }} />
                      </TableRow>
                    ),
                )}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} className={classes.closeButton}>
          Close
        </Button>
      </DialogActions>
      {mutationLoading ||
        isRefetching ||
        (oppLoading && (
          <>
            <p>Loading notes...</p>
            <Backdrop className={classes.backdrop} open={true}>
              <CircularProgress color="inherit" />
            </Backdrop>
          </>
        ))}
    </>
  );
};

export default DealLogOpportunityNotes;
