import React from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';

import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { isEmpty } from 'underscore';
import TextFieldControl from 'components/MaterialUI/TextFieldControl';
import { useDealerContext } from 'components/MaterialUI/DealerContext';

const UPDATE_SUBSTATUSES = gql`
  mutation updateSubStatuses($dealer_id: Int!, $input: [String]!) {
    updateSubStatuses(dealer_id: $dealer_id, input: $input) {
      dealer_id
      sub_statuses
    }
  }
`;

const AddSubStatusModal = ({ handleClose, refetch, subStatuses }) => {
  const { dealerId } = useDealerContext();
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit } = useForm();

  const [addSubStatus] = useMutation(UPDATE_SUBSTATUSES, {
    onCompleted: data => {
      handleClose();
      refetch();
      enqueueSnackbar('Sub Status Added!', { variant: 'success' });
    },
    onError: err => {
      enqueueSnackbar(`Could not add sub status error: ${err}`, {
        variant: 'error',
      });
    },
  });

  const onSubmit = ({ dealerId, subStatus }) => {
    if (!isEmpty(subStatus))
      addSubStatus({
        variables: { dealer_id: dealerId, input: [...subStatuses, subStatus] },
      });
  };

  return (
    <>
      <DialogTitle>Add a New Sub Status</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <TextFieldControl
            control={control}
            defaultValue={dealerId}
            name="dealerId"
            style={{ display: 'none' }}
          />
          <TextFieldControl
            control={control}
            defaultValue=""
            label="Sub Status"
            name="subStatus"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button type="submit">Add</Button>
        </DialogActions>
      </form>
    </>
  );
};

export default AddSubStatusModal;
