import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Box } from '@mui/material';

import ErrorDisplay from 'components/MaterialUI/ErrorDisplay';
import Loading from 'components/MaterialUI/Loading';

const CREATE_OR_GET_CONVERSATION = gql`
  mutation createOrGetConversation(
    $gatewayId: Int!
    $conversation: ConversationInput!
  ) {
    sms {
      createOrGetConversation(
        gatewayId: $gatewayId
        conversation: $conversation
      ) {
        id
        gateway_id
      }
    }
  }
`;
const ChatFromNumber = ({ gatewayId, phoneNumber }) => {
  const [createOrGetConversation, { data, loading, error }] = useMutation(
    CREATE_OR_GET_CONVERSATION,
  );
  const history = useHistory();

  useEffect(() => {
    if (!loading && !data && !error) {
      const conversation = { customer_phone: phoneNumber };
      createOrGetConversation({ variables: { gatewayId, conversation } }).then(
        ({ data }) =>
          history.push(
            `/sms/gateways/${data.sms.createOrGetConversation.gateway_id}/${data.sms.createOrGetConversation.id}`,
          ),
      );
    }
  });

  return (
    <Box>
      {loading && <Loading text="Redirecting to conversation..." />}
      {error && (
        <ErrorDisplay error={error} action="Redirecting to conversation" />
      )}
    </Box>
  );
};

export default ChatFromNumber;
