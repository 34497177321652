import React from 'react';

import { Route, Switch } from 'react-router-dom';

import app from '../../app';
import TasksList from './TasksList';
import TaskTemplates from './TaskTemplates';
import AddTaskTemplate from './AddTaskTemplate';

const Tasks = ({ match }) => {
  return (
    <Switch>
      <Route
        exact
        path={`${match.url}/`}
        render={({ match }) => (
          <app.components.Layout title="Tasks">
            <TasksList />
          </app.components.Layout>
        )}
      />
      <Route
        exact
        path={`${match.url}/templates`}
        render={({ match }) => (
          <app.components.Layout title="Task Templates">
            <TaskTemplates />
          </app.components.Layout>
        )}
      />
      <Route
        exact
        path={`${match.url}/templates/create/:taskId?`}
        render={({ match }) => (
          <app.components.Layout title="Create Task Template">
            <AddTaskTemplate taskId={match.params.taskId} />
          </app.components.Layout>
        )}
      />
    </Switch>
  );
};

export default Tasks;
