import React from 'react';
import { makeStyles } from '@mui/styles';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ActivityDateDisplay from './ActivityDateDisplay';
import gql from 'graphql-tag';

const useStyles = makeStyles(theme => ({
  details: {
    whiteSpace: 'pre-line',
    fontSize: '.75rem',
  },
  date: {
    minWidth: '75px',
    paddingTop: '25px',
  },
}));

const ActivityCard = ({ act }) => {
  const classes = useStyles();
  const date = act?.timestamp;
  const event = act?.event;

  return (
    <CardContent>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={2}
      >
        <Grid item xs={1}>
          <ActivityDateDisplay date={date} />
        </Grid>
        <Grid item xs={2}>
          <Typography variant="h6">{event}</Typography>
        </Grid>
        <Grid item xs={9}>
          <h5>Details:</h5>
          <Typography
            variant="body2"
            component="p"
            style={{ fontSize: '.75rem' }}
            className={classes.details}
          >
            {`${act.description}`}
          </Typography>
        </Grid>
      </Grid>
    </CardContent>
  );
};

ActivityCard.fragments = {
  event: gql`
    fragment ActivityCardFragment on CustomerEvent {
      description
      timestamp
      event
    }
  `,
};

export default ActivityCard;
