/* External */
import React from 'react';
import gql from 'graphql-tag';
import moment from 'moment-timezone';

/* Material UI */
import { Alert } from '@mui/material';

/* Internal */
import { formatSeconds } from 'utils';

const BulkSendAlerts = ({ bulkSend }) => {
  const {
    secondsElapsed,
    secondsRemaining,
    statusLabel,
    dateFinished,
    dateRevoked,
    messages,
    scheduledStart,
  } = bulkSend;

  const finishedOn = dateFinished
    ? moment(dateFinished).format('MMM Do YYYY @ h:mm:ss a')
    : '—';

  const cancelledOn = dateRevoked
    ? moment(dateRevoked).format('MMM Do YYYY @ h:mm:ss a')
    : '—';

  const messageCount = messages?.length ?? 0;

  const severity =
    statusLabel === 'Finished'
      ? 'success'
      : statusLabel === 'Cancelled'
      ? 'error'
      : statusLabel === 'Scheduled'
      ? 'warning'
      : statusLabel === 'Running'
      ? 'success'
      : 'info';

  const timeElapsed = secondsElapsed > 0 ? formatSeconds(secondsElapsed) : '—';
  const timeRemaining =
    secondsRemaining > 0 ? formatSeconds(secondsRemaining) : '—';

  const finishedMsg = `Bulk send finished on ${finishedOn}. Time elapsed: ${timeElapsed}`;
  const scheduledMsg = `This bulk send has been scheduled for ${moment(
    scheduledStart,
  ).format(
    'MMM Do YYYY @ h:mm:ss a',
  )}. Estimated time to complete bulk send: ${timeRemaining}`;
  const draftMsg = `This bulk send ${
    scheduledStart
      ? 'has not been started'
      : 'has not been started or scheduled'
  }. Estimated time to complete bulk send: ${timeRemaining}`;
  const cancelledMsg = `Bulk send cancelled on ${cancelledOn}. Time elapsed: ${timeElapsed}`;
  const sendingMsg = `Sending ${messageCount} message(s)...`;

  const alertMsg =
    statusLabel === 'Finished'
      ? finishedMsg
      : statusLabel === 'Cancelled'
      ? cancelledMsg
      : statusLabel === 'Running'
      ? sendingMsg
      : statusLabel === 'Scheduled'
      ? scheduledMsg
      : draftMsg;

  return (
    <Alert severity={severity} style={{ width: '100%', marginTop: '20px' }}>
      {alertMsg}
    </Alert>
  );
};

BulkSendAlerts.fragments = {
  bulkSend: gql`
    fragment BulkSendAlertsBulkSend on BulkSend {
      secondsElapsed
      secondsRemaining
      statusLabel
      dateFinished
      dateRevoked
      messages {
        id
        body
      }
      scheduledStart
    }
  `,
};

export default BulkSendAlerts;
