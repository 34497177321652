import React from 'react';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import { formatPrice } from 'utils';
import moment from 'moment-timezone';
import { defaultTZ } from 'utils';
import { SEGMENT_DATE_TRAITS_LIST } from '../constant';

const useStyles = makeStyles(theme => ({
  data: {
    fontSize: '14px',
  },
  label: {
    fontSize: '14px',
    fontWeight: 'bold',
  },
  underline: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.5)',
  },
}));

const SegmentTraitDisplay = ({ traitValue, traitName, fieldName }) => {
  const classes = useStyles();
  return (
    <>
      <Grid item xs={12} md={3} alignItems="center">
        <Typography className={classes.label}>{traitName}</Typography>
      </Grid>
      <Grid item xs={12} md={3} className={classes.data}>
        {traitName === 'Go Card Balance' ? (
          <Typography className={classes.data}>
            {formatPrice(String(traitValue), { cents: true })}
          </Typography>
        ) : // if the trait is a date, format it in the default timezone
        SEGMENT_DATE_TRAITS_LIST.filter(trait => trait.value === fieldName)
            .length > 0 ? (
          <Typography className={classes.data}>
            {moment(traitValue).tz(defaultTZ).format('MMM DD, YYYY')}
          </Typography>
        ) : (
          <Typography className={classes.data}>{String(traitValue)}</Typography>
        )}
      </Grid>
      <Grid item md={6} />
      <Grid item xs={12} md={6} className={classes.underline} />
      <Grid item md={6} />
    </>
  );
};

export default SegmentTraitDisplay;
