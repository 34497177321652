import React from 'react';

import { Box } from '@mui/material';

import Layout from 'modules/app/components/Layout';

const Debug = () => (
  <Layout title="Debug">
    <Box>Testing 2023-03-27T17:30:00</Box>
  </Layout>
);
export default Debug;
