import React, { useState } from 'react';

/* external */
import gql from 'graphql-tag';

/* Material UI */
import {
  Box,
  Step,
  StepButton,
  StepContent,
  StepLabel,
  Stepper,
  useMediaQuery,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

/* internal */
import TradeDetailsFormBox from './TradeDetailsFormBox';
import TradePhotosFormBox from './TradePhotosFormBox';
import TradeValuationFormBox from './TradeValuationFormBox';
import TradeVautoFormBox from './TradeVAutoFormBox';

const useStepperStyles = makeStyles(theme => ({
  root: {
    '& .MuiStepIcon-root.Mui-active': {
      color: theme.actions.add.backgroundColor,
    },
    '& .MuiStepIcon-root.Mui-completed': {
      color: theme.actions.add.backgroundColor,
    },
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

const TradeFormStepper = ({
  autoDecode,
  onCancel,
  onCreate,
  onUpdate,
  appraisal,
  ...rest
}) => {
  const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const stepperClasses = useStepperStyles();

  const [activeStep, setActiveStep] = useState(0);

  const handleCreate = _appraisal => {
    setActiveStep(1);
    onCreate(_appraisal);
  };

  const handleUpdate = _appraisal => {
    if (activeStep === 3) onCancel();
    else setActiveStep(x => x + 1);
    onUpdate(_appraisal);
  };

  const handleSkip = () =>
    activeStep === 3 ? onCancel() : setActiveStep(x => x + 1);

  const handleBack = () => setActiveStep(x => (x > 0 ? x - 1 : 0));

  return (
    <Box>
      <Stepper
        activeStep={activeStep}
        classes={stepperClasses}
        orientation={mobile ? 'vertical' : 'horizontal'}
        {...rest}
      >
        <Step>
          <StepButton onClick={() => setActiveStep(0)}>
            <StepLabel>Basic Vehicle Details</StepLabel>
          </StepButton>
          {mobile && (
            <StepContent>
              <TradeDetailsFormBox
                appraisal={appraisal}
                autoDecode={autoDecode}
                onCancel={onCancel}
                onCreate={handleCreate}
                onSkip={handleSkip}
                onUpdate={handleUpdate}
              />
            </StepContent>
          )}
        </Step>
        <Step>
          <StepButton onClick={() => setActiveStep(1)}>
            <StepLabel>Photos</StepLabel>
          </StepButton>
          {mobile && (
            <StepContent>
              <TradePhotosFormBox
                appraisal={appraisal}
                onBack={handleBack}
                onCancel={onCancel}
                onSkip={handleSkip}
                onUpdate={handleUpdate}
              />
            </StepContent>
          )}
        </Step>
        <Step>
          <StepButton onClick={() => setActiveStep(2)}>
            <StepLabel>Appraised Value</StepLabel>
          </StepButton>
          {mobile && (
            <StepContent>
              <TradeValuationFormBox
                appraisal={appraisal}
                onBack={handleBack}
                onCancel={onCancel}
                onSkip={handleSkip}
                onUpdate={handleUpdate}
              />
            </StepContent>
          )}
        </Step>
        <Step>
          <StepButton onClick={() => setActiveStep(3)}>
            <StepLabel>Send to vAuto</StepLabel>
          </StepButton>
          {mobile && (
            <StepContent>
              <TradeVautoFormBox
                appraisal={appraisal}
                onBack={handleBack}
                onCancel={onCancel}
                onUpdate={handleUpdate}
              />
            </StepContent>
          )}
        </Step>
      </Stepper>

      {activeStep === 0 && !mobile && (
        <TradeDetailsFormBox
          appraisal={appraisal}
          autoDecode={autoDecode}
          onCancel={onCancel}
          onCreate={handleCreate}
          onSkip={handleSkip}
          onUpdate={handleUpdate}
        />
      )}
      {activeStep === 1 && !mobile && (
        <TradePhotosFormBox
          appraisal={appraisal}
          onBack={handleBack}
          onCancel={onCancel}
          onSkip={handleSkip}
          onUpdate={handleUpdate}
        />
      )}
      {activeStep === 2 && !mobile && (
        <TradeValuationFormBox
          appraisal={appraisal}
          onBack={handleBack}
          onCancel={onCancel}
          onSkip={handleSkip}
          onUpdate={handleUpdate}
        />
      )}
      {activeStep === 3 && !mobile && (
        <TradeVautoFormBox
          appraisal={appraisal}
          onBack={handleBack}
          onCancel={onCancel}
          onUpdate={handleUpdate}
        />
      )}
    </Box>
  );
};

TradeFormStepper.fragments = {
  appraisal: gql`
    fragment TradeFormStepperAppraisal on Appraisal {
      id
      ...TradeDetailsFormBoxAppraisal
      ...TradePhotosFormBoxAppraisal
      ...TradeValuationFormBoxAppraisal
      ...TradeVautoFormBoxAppraisal
    }
    ${TradeDetailsFormBox.fragments.appraisal}
    ${TradePhotosFormBox.fragments.appraisal}
    ${TradeValuationFormBox.fragments.appraisal}
    ${TradeVautoFormBox.fragments.appraisal}
  `,
};

export default TradeFormStepper;
