import React, { useRef, useState } from 'react';
import { withStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import { cloneDeep } from 'lodash';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import InspectionCheckboxes from '../InspectionCheckboxes';

const HyundaiCPOForm = props => {
  const { enqueueSnackbar } = useSnackbar();

  // HyundaiCPO form fields
  const roadTest1 = {
    h1: 'Engine Start-Up Cold',
    h2: 'Engine Warm-Up',
    h3: 'Engine Start-Up Warm',
    h4: 'Transaxle Shifting (Vehicle stopped)',
    h5: 'Acceleration (Light, Moderate, Hard)',
    h6: 'Transaxle Shifting (City, Highway)',
    h7: 'Braking (Light, Moderate, Hard)',
    h8: 'Driving Speed (Slow, Moderate, Highway)',
    h9: 'Directional Stability (Alignment)',
    h10: 'Noise/Vibration/Harshness',
    h11: 'Cruise Control (Operation)',
    h12: 'Rattles/Wind Noise',
    h13: 'Accessory/Component Operation',
    h14: 'Other',
  };
  const roadTest2 = {
    h15: 'VIN Stamp (Complete)',
    h16: 'Engine',
    h17: 'Transaxle',
    h18: 'Electrical System',
    h19: 'Cooling System',
    h20: 'Cooling Fan',
    h21: 'Steering System',
    h22: 'Brake System (Master Cylinder, Lines)',
    h23: 'Spark Plug Wires',
    h24: 'Ignition Coil(s)',
    h25: 'Fuel System (Injectors, Filters, Lines)',
    h26: 'Air Conditioning System',
    h27: '(Hoses, Refrigerant)',
    h28: 'Air Inlet Systems/Filters',
    h29: 'Passed Emission Test (If required)',
    h30: 'Other',
  };
  const underVehicle = {
    h31: 'Under Carriage Inspection',
    h32: 'Radiator/Hoses/Cooling Lines',
    h33: 'Fuel tank/Lines',
    h34: 'Catalytic Converter/02 Sensors',
    h35: 'Exhaust Pipes/Muffler',
    h36: 'Wheels',
    h37: 'Tires (4/32” minimum tread depth remaining)',
    h38: 'BRAKES - Rotors, Pads, Shoes, Drums (4mm minimum lining)',
    h39: 'BRAKES - Hydraulics (Calipers, Lines, Hoses)',
    h40: 'DRIVELINE - Engine',
    h41: 'DRIVELINE - Clutch (If equipped)',
    h42: 'DRIVELINE - Transaxle',
    h43: 'DRIVELINE - CV Joints & Boots',
    h44: 'DRIVELINE - Transfer Case (If equipped)',
    h45: 'DRIVELINE - Rear Differential (If equipped)',
    h46: 'SUSPENSION - Control Arms/Link',
    h47: 'SUSPENSION - Springs & Sway Bars & Bushings',
    h48: 'SUSPENSION - Shock Absorbers',
    h49: 'SUSPENSION - Rack & Pinion',
    h50: 'SUSPENSION - Hydraulics (Hoses, Seals)',
    h51: 'SUSPENSION - Linkage (Tie Rods/Ends)',
    h52: 'SUSPENSION - Other',
  };
  const exterior1 = {
    h53: 'Headlamps',
    h54: 'Tail/Brake Lamps',
    h55: 'Signal/Marker Lights',
    h56: 'Wipers/Washers',
    h57: 'Outside Mirrors',
    h58: 'Other',
  };
  const interior1 = {
    h59: 'Gauges & Instrumentation',
    h60: 'Primary Controls (Steering, Shift, Brakes)',
    h61: 'Controls (WS Wiper, Washer, etc.)',
    h62: 'Safety Restraints (SAS, Seatbelts, Child Seat Anchors)',
    h63: 'Climate Control System',
    h64: 'Fuel Door Control',
    h65: 'Sound System (Radio/Tape/CD, Speakers, Antenna)',
    h66: 'Windows',
    h67: 'Seats',
    h68: 'Doors/Door Locks (Keys, Lock, Child Locks)',
    h69: 'Mirrors',
    h70: 'Interior Lights',
    h71: 'Keyless Entry & Alarm',
    h72: 'Cargo Area (Spare Tire, Tools)',
    h73: 'Sun/Moon Roof',
    h74: 'Other',
  };
  const exterior2 = {
    h75: 'Front Bumper',
    h76: 'Grille',
    h77: 'Hood',
    h78: 'LF Fender',
    h79: 'LF Wheel Well',
    h80: 'LF Door',
    h81: 'LR Door',
    h82: 'LR Fender',
    h83: 'LR Wheel Well',
    h84: 'Trunk Lid, Tailgate',
    h85: 'Spoiler (If equipped)',
    h86: 'Rear Bumper',
    h87: 'RR Fender',
    h88: 'RR Door',
    h89: 'RR Wheel Well',
    h90: 'RF Door',
    h91: 'RF Fender',
    h92: 'RF Wheel Well',
    h93: 'Roof',
    h94: 'Windshield/Door/Rear Glass',
    h95: 'Wheels/Wheel Covers',
    h96: 'Other',
  };
  const interior2 = {
    h97: 'VIN Plate /Sticker',
    h98: 'Instrument Panel',
    h99: 'Dash Panel',
    h100: 'Glove Box (Owners Literature)',
    h101: 'Drivers Seat',
    h102: 'Driver Door Panel',
    h103: 'Passenger Seat',
    h104: 'Passenger Door Panel',
    h105: 'Front Carpet',
    h106: 'Floor Mats',
    h107: 'Rear Seat',
    h108: 'LR Door Panel',
    h109: 'LR Quarter Trim',
    h110: 'RR Door Panel',
    h111: 'RR Quarter Trim',
    h112: 'Rear Carpet',
    h113: 'Floor Mats',
    h114: 'Headliner',
    h115: 'Trunk/Cargo Area',
    h116: 'Other',
  };
  const servicing = {
    h117: 'Engine (Engine Oil & Filter Inspections/Change)',
    h118: 'Mechanical Services (Within 3 months/6,000kms)',
    h119: 'Outstanding Recalls',
    h120: 'Diagnostic Code Check (Scan test, ECM, TCM, SRS, ABS)',
    h121: 'Appearance (Exterior clean, wax Interior clean)',
  };

  let roadTest1Obj = cloneDeep(roadTest1);
  let roadTest2Obj = cloneDeep(roadTest2);
  let underVehicleObj = cloneDeep(underVehicle);
  let exterior1Obj = cloneDeep(exterior1);
  let interior1Obj = cloneDeep(interior1);
  let exterior2Obj = cloneDeep(exterior2);
  let interior2Obj = cloneDeep(interior2);
  let servicingObj = cloneDeep(servicing);

  Object.entries(roadTest1Obj).map(([name]) => (roadTest1Obj[name] = 'OK'));
  Object.entries(roadTest2Obj).map(([name]) => (roadTest2Obj[name] = 'OK'));
  Object.entries(underVehicleObj).map(
    ([name]) => (underVehicleObj[name] = 'OK'),
  );
  Object.entries(exterior1Obj).map(([name]) => (exterior1Obj[name] = 'OK'));
  Object.entries(interior1Obj).map(([name]) => (interior1Obj[name] = 'OK'));
  Object.entries(exterior2Obj).map(([name]) => (exterior2Obj[name] = 'OK'));
  Object.entries(interior2Obj).map(([name]) => (interior2Obj[name] = 'OK'));
  Object.entries(servicingObj).map(([name]) => (servicingObj[name] = 'OK'));

  const [roadTest1Values, setRoadTest1Values] = useState(roadTest1Obj);
  const [roadTest2Values, setRoadTest2Values] = useState(roadTest2Obj);
  const [underVehicleValues, setUnderVehicleValues] = useState(underVehicleObj);
  const [exterior1Values, setExterior1Values] = useState(exterior1Obj);
  const [interior1Values, setInterior1Values] = useState(interior1Obj);
  const [exterior2Values, setExterior2Values] = useState(exterior2Obj);
  const [interior2Values, setInterior2Values] = useState(interior2Obj);
  const [servicingValues, setServicingValues] = useState(servicingObj);

  const handleRoadTest1 = e => {
    const { name, value } = e.target;
    if (value === 'na') {
      e.target.closest('div').previousSibling.style.textDecoration =
        'line-through';
    } else {
      e.target.closest('div').previousSibling.style.textDecoration = 'none';
    }
    setRoadTest1Values(prevRoadTest1Values => ({
      ...prevRoadTest1Values,
      [name]: value,
    }));
  };
  const handleRoadTest2 = e => {
    const { name, value } = e.target;
    if (value === 'na') {
      e.target.closest('div').previousSibling.style.textDecoration =
        'line-through';
    } else {
      e.target.closest('div').previousSibling.style.textDecoration = 'none';
    }
    setRoadTest2Values(prevRoadTest2Values => ({
      ...prevRoadTest2Values,
      [name]: value,
    }));
  };
  const handleUnderVehicle = e => {
    const { name, value } = e.target;
    if (value === 'na') {
      e.target.closest('div').previousSibling.style.textDecoration =
        'line-through';
    } else {
      e.target.closest('div').previousSibling.style.textDecoration = 'none';
    }
    setUnderVehicleValues(prevUnderVehicleValues => ({
      ...prevUnderVehicleValues,
      [name]: value,
    }));
  };
  const handleExterior1 = e => {
    const { name, value } = e.target;
    if (value === 'na') {
      e.target.closest('div').previousSibling.style.textDecoration =
        'line-through';
    } else {
      e.target.closest('div').previousSibling.style.textDecoration = 'none';
    }
    setExterior1Values(prevExterior1Values => ({
      ...prevExterior1Values,
      [name]: value,
    }));
  };
  const handleInterior1 = e => {
    const { name, value } = e.target;
    if (value === 'na') {
      e.target.closest('div').previousSibling.style.textDecoration =
        'line-through';
    } else {
      e.target.closest('div').previousSibling.style.textDecoration = 'none';
    }
    setInterior1Values(prevInterior1Values => ({
      ...prevInterior1Values,
      [name]: value,
    }));
  };
  const handleExterior2 = e => {
    const { name, value } = e.target;
    if (value === 'na') {
      e.target.closest('div').previousSibling.style.textDecoration =
        'line-through';
    } else {
      e.target.closest('div').previousSibling.style.textDecoration = 'none';
    }
    setExterior2Values(prevExterior2Values => ({
      ...prevExterior2Values,
      [name]: value,
    }));
  };
  const handleInterior2 = e => {
    const { name, value } = e.target;
    if (value === 'na') {
      e.target.closest('div').previousSibling.style.textDecoration =
        'line-through';
    } else {
      e.target.closest('div').previousSibling.style.textDecoration = 'none';
    }
    setInterior2Values(prevInterior2Values => ({
      ...prevInterior2Values,
      [name]: value,
    }));
  };
  const handleServicing = e => {
    const { name, value } = e.target;
    if (value === 'na') {
      e.target.closest('div').previousSibling.style.textDecoration =
        'line-through';
    } else {
      e.target.closest('div').previousSibling.style.textDecoration = 'none';
    }
    setServicingValues(prevServicingValues => ({
      ...prevServicingValues,
      [name]: value,
    }));
  };

  const [consent, setConsent] = useState(false);
  const handleConsent = () => {
    setConsent(consent => !consent);
  };

  const [confirm, setConfirm] = useState(false);
  const handleConfirm = () => {
    setConfirm(confirm => !confirm);
  };

  const wholeForm = useRef(null);

  const handleSubmit = e => {
    e.preventDefault();

    if (!wholeForm.current['amvic_number'].value) {
      enqueueSnackbar(`Please fill out 'Tech #'.`);
      return;
    }

    if (!consent) {
      enqueueSnackbar(`Please check 'I agree' and then submit the inspection!`);
      return;
    }

    roadTest1Values['h14_field'] = wholeForm.current['h14_field'].value;
    roadTest2Values['h30_field'] = wholeForm.current['h30_field'].value;
    underVehicleValues['h52_field'] = wholeForm.current['h52_field'].value;
    exterior1Values['h58_field'] = wholeForm.current['h58_field'].value;
    interior1Values['h74_field'] = wholeForm.current['h74_field'].value;

    const values = {
      ...roadTest1Values,
      ...roadTest2Values,
      ...underVehicleValues,
      ...exterior1Values,
      ...interior1Values,
      ...exterior2Values,
      ...interior2Values,
      ...servicingValues,
    };
    const form_data = {
      hyundai: values,
      amvic_number: wholeForm.current['amvic_number'].value,
    };

    props.formData(form_data);
    props.formClose();
  };

  const GreenButton = withStyles({
    root: {
      color: '#fff',
      backgroundColor: '#198754',
      '&:hover': {
        backgroundColor: '#157347',
      },
    },
  })(Button);

  return (
    <form onSubmit={handleSubmit} ref={wholeForm}>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>1. Road Test</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            {Object.entries(roadTest1).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {name !== 'h14' && <Typography>{display_name}</Typography>}
                    {name === 'h14' && (
                      <TextField label="Other" name={'h14_field'} />
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={roadTest1Values}
                      handleGroup={handleRoadTest1}
                      name={name}
                      orange={false}
                      white
                      greenValue="OK"
                      redValue="Needs Repair"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>2. Road Test</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            {Object.entries(roadTest2).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {name !== 'h30' && <Typography>{display_name}</Typography>}
                    {name === 'h30' && (
                      <TextField label="Other" name={'h30_field'} />
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={roadTest2Values}
                      handleGroup={handleRoadTest2}
                      name={name}
                      orange={false}
                      white
                      greenValue="OK"
                      redValue="Needs Repair"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>3. Under Vehicle</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            {Object.entries(underVehicle).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {name !== 'h52' && <Typography>{display_name}</Typography>}
                    {name === 'h52' && (
                      <TextField label="Other" name={'h52_field'} />
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={underVehicleValues}
                      handleGroup={handleUnderVehicle}
                      name={name}
                      orange={false}
                      greenValue="OK"
                      redValue="Needs Repair"
                      white
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>4. Exterior</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            {Object.entries(exterior1).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {name !== 'h58' && <Typography>{display_name}</Typography>}
                    {name === 'h58' && (
                      <TextField label="Other" name={'h58_field'} />
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={exterior1Values}
                      handleGroup={handleExterior1}
                      name={name}
                      orange={false}
                      greenValue="OK"
                      redValue="Needs Repair"
                      white
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>5. Interior</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            {Object.entries(interior1).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {name !== 'h74' && <Typography>{display_name}</Typography>}
                    {name === 'h74' && (
                      <TextField label="Other" name={'h74_field'} />
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={interior1Values}
                      handleGroup={handleInterior1}
                      name={name}
                      orange={false}
                      greenValue="OK"
                      redValue="Needs Repair"
                      white
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>6. Exterior</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            {Object.entries(exterior2).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    <Typography>{display_name}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={exterior2Values}
                      handleGroup={handleExterior2}
                      name={name}
                      orange={false}
                      greenValue="OK"
                      redValue="Needs Repair"
                      white
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>7. Interior</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            {Object.entries(interior2).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    <Typography>{display_name}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={interior2Values}
                      handleGroup={handleInterior2}
                      name={name}
                      orange={false}
                      greenValue="OK"
                      redValue="Needs Repair"
                      white
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>8. Servicing (Required for all CPO vehicles)</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            {Object.entries(servicing).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    <Typography>{display_name}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={servicingValues}
                      handleGroup={handleServicing}
                      name={name}
                      orange={false}
                      greenValue="OK"
                      redValue="Needs Repair"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Typography style={{ marginTop: '15px' }}>
        I certify that I have inspected this vehicle and made an evaluation
        against Hyundai Certification Standards.
      </Typography>
      <FormControlLabel
        control={<Checkbox />}
        label="I agree"
        onChange={handleConsent}
        value={consent}
      />
      <br />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Technician Name"
            value={props.user.display_name}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Tech #"
            value={props.user.goUserProfile.profile.sales_licence}
            fullWidth
            name={'amvic_number'}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Date"
            value={new Date().toLocaleString()}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <Button
            type="button"
            variant="contained"
            style={{ marginRight: '15px' }}
            onClick={handleConfirm}
            color="default"
          >
            Cancel
          </Button>
          <Dialog
            open={confirm}
            onClose={handleConfirm}
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Current inspection will be closed and unsaved data will be gone.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleConfirm}>Cancel</Button>
              <Button onClick={props.formClose} color="secondary">
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
          <GreenButton type="submit" variant="contained">
            Save
          </GreenButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default HyundaiCPOForm;
