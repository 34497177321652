import React from 'react';

/* external */
import { Route, Switch } from 'react-router-dom';

/* internal */
import Layout from 'modules/app/components/Layout';

import RVGocardScanner from './RVGocardScanner';

const RVAdventureClub = ({ match }) => (
  <Layout title="RV Adventure Club">
    <Switch>
      <Route exact path={`${match.url}/`} component={RVGocardScanner} />
      <Route
        exact
        path={`${match.url}/:id(\\d+)`}
        render={({ match: matched }) => (
          <RVGocardScanner id={parseInt(matched.params.id, 10)} />
        )}
      />
    </Switch>
  </Layout>
);

export default RVAdventureClub;
