import React from 'react';
import Alert from '@mui/material/Alert';

export const NoValuationAlert = () => {
  return (
    <Alert severity="warning">
      No valuation information is available for this vehicle.
    </Alert>
  );
};

export const NotEnoughDataAlert = ({ year, make_name, model_name }) => {
  return (
    <Alert severity="warning">
      There was not enough data returned from the Canadian Black Book for this
      vehicle. {!year && 'Vehicle is missing year.'}{' '}
      {!make_name && 'Vehicle is missing make.'}{' '}
      {!model_name && 'Vehicle is missing model.'}
    </Alert>
  );
};
