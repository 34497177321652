import React from 'react';

import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';

import { Box, Button, Tooltip } from '@mui/material';

import { Role } from 'constants.js';
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import { useUserContext } from 'components/MaterialUI/UserContext';

const CREATE_OPPORTUNITY = gql`
  mutation createOpportunity($input: CreateOpportunityInput!) {
    createOpportunity(input: $input) {
      _id
    }
  }
`;

const ServiceAppointmentActions = ({
  classes,
  handleOpenUpdateSalesperson,
  appointment,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const { currentUser } = useUserContext();
  const { dealerId } = useDealerContext();

  const [createOpportunity] = useMutation(CREATE_OPPORTUNITY, {
    onCompleted: data => {
      history.push(`/opportunities/${data.createOpportunity._id}/details`);
    },
    onError: () =>
      enqueueSnackbar(`Error in creating opportunity`, {
        variant: 'error',
      }),
  });

  const onCreate = appointment => {
    if (appointment.customer) {
      const payload = {
        dealer_id: dealerId,
        customer_id: appointment.customer._id,
        marketing: {
          lead_channel: 'service',
          lead_direction: 'inbound',
          lead_source: 'Service Drive',
        },
        sales_reps: Role.ALL_SALES_REPS.includes(currentUser.role)
          ? [currentUser.username]
          : [], // if we're a sales rep, assign ourselves to this opp.
      };

      createOpportunity({
        variables: {
          input: payload,
        },
      });
    } else {
      enqueueSnackbar('Customer has no CRM records!', { variant: 'error' });
    }
  };

  return (
    <Box className={classes.action}>
      <Tooltip title="Update Saleperson">
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleOpenUpdateSalesperson(appointment.customer?._id)}
        >
          <i className="fas fa-user-plus fa-fw" />
        </Button>
      </Tooltip>
      <Tooltip title="Create Opportunity">
        <Button
          variant="contained"
          color="primary"
          onClick={() => onCreate(appointment)}
        >
          <i className="fas fa-thumbs-up fa-fw" />
        </Button>
      </Tooltip>
    </Box>
  );
};

export default ServiceAppointmentActions;
