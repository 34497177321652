/* external */
import React, { useEffect } from 'react';
import gql from 'graphql-tag';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';

/* Material UI */
import { Step, StepButton, StepContent } from '@mui/material';
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';
import TextFieldControl from 'components/MaterialUI/TextFieldControl';

/* internal */
import { errorHandler } from '../utils';
import HelperAppVINScanner from './HelperAppVinScanner';
import StepActions from './StepActions';
import { PlaceholderVin } from 'modules/used_vehicles/const';
import { VinValidator } from 'utils/VinValidator';

const StepOne = ({
  appraisal,
  label,
  onNext,
  setAppraisalId,
  setActiveStep,
  setIsDirty,
  ...rest
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { dealerId } = useDealerContext();

  const CREATE_APPRAISAL = gql`
    mutation createAppraisal($appraisal: AppraisalInput!) {
      appraisals {
        newAppraisal: createAppraisal(appraisal: $appraisal) {
          id
          ...StepOneAppraisal
        }
      }
    }
    ${StepOne.fragments.appraisal}
  `;

  const UPDATE_APPRAISAL = gql`
    mutation updateAppraisal($id: Int!, $data: UpdateAppraisalInput!) {
      appraisals {
        updateAppraisal(id: $id, data: $data) {
          id
          ...StepOneAppraisal
        }
      }
    }
    ${StepOne.fragments.appraisal}
  `;

  const [createAppraisal, { loading }] = useMutation(CREATE_APPRAISAL);
  const [updateAppraisal] = useMutation(UPDATE_APPRAISAL);
  const { control, handleSubmit, reset, setError, setValue } = useForm({
    defaultValues: appraisal,
  });

  useEffect(() => {
    if (!isEmpty(appraisal)) reset(appraisal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appraisal]);

  const onSubmit = ({ vin }) => {
    if (!appraisal.id)
      createAppraisal({ variables: { appraisal: { dealerId, vin } } }).then(
        r => {
          setAppraisalId(r.data.appraisals.newAppraisal.id);
          onNext();
        },
        err => errorHandler(enqueueSnackbar, setError)(err),
      );
    else if (
      appraisal.id &&
      (appraisal.isCustomerAppraisal || !appraisal.vin)
    ) {
      updateAppraisal({
        variables: { id: appraisal.id, data: { vin } },
      }).then(
        () => onNext(),
        err => errorHandler(enqueueSnackbar, setError)(err),
      );
    } else onNext();
  };

  return (
    <Step {...rest} key={label}>
      <StepButton
        disabled={
          appraisal.id && appraisal.vin && appraisal.vin !== PlaceholderVin
        }
        onClick={() => setActiveStep(0)}
      >
        {label}
      </StepButton>
      <LoadingBackdrop open={loading}>
        <p>Creating Used Vehicle Appraisal</p>
      </LoadingBackdrop>
      <StepContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextFieldControl
            label="Vehicle VIN"
            name="vin"
            control={control}
            disabled={
              appraisal.id &&
              (!appraisal.isCustomerAppraisal ||
                (appraisal.vin && appraisal.vin !== PlaceholderVin))
            }
            rules={{
              validate: val =>
                new VinValidator(val).isValid ||
                val === PlaceholderVin ||
                'Invalid Vin',
            }}
            style={{ width: '150px' }}
          />
          <span style={{ paddingLeft: '1rem' }}>
            <HelperAppVINScanner
              disabled={
                appraisal.id &&
                (!appraisal.isCustomerAppraisal || appraisal.vin)
              }
              onUploaded={vin => setValue('vin', vin, { shouldDirty: true })}
            />
          </span>
          <StepActions
            onNext={() => {}} // don't do anything since this is a form
            nextButtonProps={{ disabled: loading, type: 'submit' }}
          />
        </form>
      </StepContent>
    </Step>
  );
};

StepOne.fragments = {
  appraisal: gql`
    fragment StepOneAppraisal on Appraisal {
      id
      vin
      isCustomerAppraisal
    }
  `,
};
export default StepOne;
