function AppError(error: Error) {
  this.name = this.constructor.name;
  this.message = error.message;
  this.stack = error.stack;
}
AppError.prototype = Object.create(Error.prototype);

export class AuthenticationError extends AppError {
  friendlyMessage: string =
    'Username or password is incorrect. Please verify and try again.';

  toString() {
    return this.friendlyMessage;
  }
}

export class NetworkError extends AppError {
  friendlyMessage: string =
    'A network error has occurred. Please check your wifi connection and refresh the page.';

  toString() {
    return this.friendlyMessage;
  }
}

export class ServerError extends AppError {
  friendlyMessage: string =
    'We appear to be having an issue on our end. Please contact support if the issue persists.';

  toString() {
    return this.friendlyMessage;
  }
}
