import React from 'react';

import GoTemplate from '../GoTemplate';
import goLogo from '../../../../assets/go.svg';

import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  maintenancePageHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    color: '#f26524',
    marginBottom: '1rem',
  },
  maintenancePageLogo: {
    height: '75px',
    width: '75px',
  },
}));

function MaintenancePage() {
  const classes = useStyles();
  return (
    <GoTemplate showHeader={false}>
      <Box>
        <Box className={classes.maintenancePageHeader}>
          <Box>
            <img
              src={goLogo}
              alt="Go Auto Logo"
              className={classes.maintenancePageLogo}
            />
          </Box>
        </Box>
        <h2>We'll be back soon!</h2>
        <p>
          Market Mobile is currently undergoing maintenance. Please check back
          soon!
        </p>
      </Box>
    </GoTemplate>
  );
}

export default MaintenancePage;
