import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import moment from 'moment';

import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import PersonIcon from '@mui/icons-material/Person';
import RefreshIcon from '@mui/icons-material/Refresh';

import { TaskStatus, TaskStatusColor } from '../constants';
import AddTaskModal from './AddTaskModal';
import TaskCancel from './TaskCancel';
import TaskComplete from './TaskComplete';
import TaskReassign from './TaskReassign';
import TaskReschedule from './TaskReschedule';

const UPDATE_TASK_QUERY = gql`
  mutation UpdateTask($id: ID!, $input: UpdateTaskInput!) {
    updateTask(id: $id, input: $input) {
      _id
      status
    }
  }
`;

const TasksAction = ({ task, setTask, taskStatus, setTaskStatus, refetch }) => {
  const theme = useTheme();
  const [reassignTaskModalOpen, setReassignTaskModalOpen] = useState(false);
  const [rescheduleTaskModalOpen, setRescheduleTaskModalOpen] = useState(false);
  const [completeTaskModalOpen, setCompleteTaskModalOpen] = useState(false);
  const [cancelTaskModalOpen, setCancelTaskModalOpen] = useState(false);
  const [addTaskModalOpen, setAddTaskModalOpen] = useState(false);
  const isDesktop = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const { enqueueSnackbar } = useSnackbar();
  const [updateTask] = useMutation(UPDATE_TASK_QUERY, {
    onCompleted: data => {
      enqueueSnackbar('Task updated', { variant: 'success' });
    },
    onError: err => {
      enqueueSnackbar(`Could not update task error: ${err}`, {
        variant: 'error',
      });
    },
  });
  const handleUpdateStatus = status => {
    updateTask({
      variables: {
        id: task._id,
        input: {
          status: status,
        },
      },
    }).then(data => {
      setTaskStatus(status);
    });
  };

  const handleReassignTask = assignee => {
    const updatedTask = { ...task, ...assignee };
    setTask(updatedTask);
  };

  const handleRescheduleTask = due_date => {
    const updatedTask = { ...task, ...due_date };
    setTask(updatedTask);
    if (taskStatus === TaskStatus.CANCELLED) {
      handleUpdateStatus(0);
    }
  };

  const handleCompleteTask = status => {
    setTaskStatus(status);
  };

  const handleCancelTask = status => {
    setTaskStatus(status);
  };

  return (
    <>
      <TaskReassign
        open={reassignTaskModalOpen}
        setOpen={setReassignTaskModalOpen}
        task={task}
        action={handleReassignTask}
      />
      <TaskReschedule
        open={rescheduleTaskModalOpen}
        setOpen={setRescheduleTaskModalOpen}
        task={task}
        action={handleRescheduleTask}
      />
      <TaskComplete
        open={completeTaskModalOpen}
        setOpen={setCompleteTaskModalOpen}
        task={task}
        action={handleCompleteTask}
        setOpenNew={setAddTaskModalOpen}
        isNoteOnOpportunity={false}
      />
      <TaskCancel
        open={cancelTaskModalOpen}
        setOpen={setCancelTaskModalOpen}
        task={task}
        action={handleCancelTask}
      />
      <AddTaskModal
        open={addTaskModalOpen}
        setOpen={setAddTaskModalOpen}
        customerId={task.customer_id}
        refetch={refetch}
      />
      <Grid
        container
        direction="row"
        justifyContent={isDesktop ? 'center' : 'flex-end'}
        alignItems="center"
        spacing={1}
      >
        {taskStatus === TaskStatus.COMPLETE ? (
          <>
            <Grid item xs={isDesktop ? null : 6}>
              <CheckIcon
                style={{ color: TaskStatusColor.GREEN, fontWeight: 'bold' }}
              />
            </Grid>
            <Grid item>
              <Typography
                style={{ color: TaskStatusColor.GREEN, fontWeight: 'bold' }}
              >
                Completed!
              </Typography>
            </Grid>
          </>
        ) : taskStatus === TaskStatus.CANCELLED ? (
          <>
            <Grid item>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                }}
              >
                <Typography style={{ fontWeight: 'bold', paddingRight: '4px' }}>
                  Cancelled
                </Typography>
                <Tooltip title="Reschedule task">
                  <IconButton
                    size="small"
                    onClick={() => setRescheduleTaskModalOpen(true)}
                  >
                    <RefreshIcon style={theme.actions.add} />
                  </IconButton>
                </Tooltip>
              </div>
            </Grid>
          </>
        ) : (
          <>
            {task.status_name === 'To Do' &&
              moment(task.due_date) < moment() && (
                <Grid item>
                  <Typography
                    style={{ color: TaskStatusColor.RED, fontWeight: 'bold' }}
                  >
                    Overdue
                  </Typography>
                </Grid>
              )}
            <Grid item xs={isDesktop ? null : 6}>
              <Tooltip title="Complete task">
                <IconButton
                  size="small"
                  onClick={() => setCompleteTaskModalOpen(true)}
                >
                  <CheckIcon style={theme.actions.add} />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title="Reschedule task">
                <IconButton
                  size="small"
                  onClick={() => setRescheduleTaskModalOpen(true)}
                >
                  <RefreshIcon style={theme.actions.add} />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title="Ressign task">
                <IconButton
                  size="small"
                  onClick={() => setReassignTaskModalOpen(true)}
                >
                  <PersonIcon style={theme.actions.add} />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title="Cancel task">
                <IconButton
                  size="small"
                  onClick={() => setCancelTaskModalOpen(true)}
                >
                  <CloseIcon style={theme.actions.add} />
                </IconButton>
              </Tooltip>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default TasksAction;
