import { useLazyQuery } from '@apollo/react-hooks';
import { Editor } from '@tinymce/tinymce-react';
import gql from 'graphql-tag';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSnackbar } from 'notistack';

import { Container, Grid, Paper, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';

import {
  KeyboardDatePickerControl,
  RadioControl,
  RenderableRadioGroup,
  LoadingBackdrop,
} from 'components/MaterialUI';

import { tinyMCEApiUrl } from '../../../../../constants';
import { usePermissionsContext } from '../../contexts/PermissionsContext';
import Controller from '../../contexts/PermissionsController';

const GET_GENERATED_DESCRIPTION = gql`
  query getVehicleGeneratedDescription($id: Int) {
    inventory {
      getVehicleGeneratedDescription(id: $id) {
        content
      }
    }
  }
`;

const VehicleDescription = ({ vehicle }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { update: canUpdate } = usePermissionsContext();
  const [tabIndex, setTabIndex] = useState(0);
  const {
    formState: { errors },
    control,
    setValue,
  } = useFormContext();

  const handleChange = (e, value) => setTabIndex(value);

  const PromotionalPositionRadioGroup = props => (
    <RenderableRadioGroup
      {...props}
      RadioProps={{ required: true }}
      options={[
        { name: 'Display Above Description', value: 'above' },
        { name: 'Display Below Description', value: 'below' },
      ]}
    />
  );

  const [getGeneratedDescription, { loading: generatedDescriptionLoading }] =
    useLazyQuery(GET_GENERATED_DESCRIPTION, {
      variables: {
        id: vehicle.id,
      },
      fetchPolicy: 'network-only',
      onCompleted: r => {
        const content =
          r.inventory.getVehicleGeneratedDescription.content.replaceAll(
            '\n',
            '<br>',
          );
        setValue('published_notes', content);
      },
      onError: e => {
        enqueueSnackbar(`Error while fetching generated description: ${e}`, {
          variant: 'error',
        });
      },
    });

  return (
    <Paper>
      <LoadingBackdrop open={generatedDescriptionLoading} />
      <Container maxWidth="xl">
        <TabContext value={tabIndex}>
          <TabList
            onChange={handleChange}
            style={{ padding: '15px 15px 15px 0' }}
            variant="scrollable"
            indicatorColor="secondary"
          >
            <Tab label="Vehicle Description" value={0} />
            <Tab label="Promotional Info" value={1} />
            <Tab label="Private Notes" value={2} />
          </TabList>
          <TabPanel value={0}>
            <Controller
              error={errors.published_notes}
              name="published_notes"
              render={({ field: { onChange, onBlur, value, name } }) => (
                <Editor
                  init={{
                    height: 400,
                    selector: 'textarea',
                    plugins: [
                      'textpattern lists link image paste help wordcount',
                    ],
                    toolbar:
                      'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | help | generate',
                    setup: editor => {
                      editor.ui.registry.addButton('generate', {
                        text: 'Generate Description',
                        onAction: () => getGeneratedDescription(),
                      });
                    },
                  }}
                  apiKey={tinyMCEApiUrl}
                  disabled={!canUpdate}
                  onBlur={onBlur}
                  onEditorChange={onChange}
                  value={value}
                  textareaName={name}
                />
              )}
            />
          </TabPanel>
          <TabPanel value={1}>
            <Controller
              error={errors.promotional_info}
              name="promotional_info"
              render={({ field: { onChange, onBlur, value, name } }) => (
                <Editor
                  init={{
                    height: 400,
                    selector: 'textarea',
                    plugins: ['lists link image paste help wordcount'],
                    toolbar:
                      'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | help',
                  }}
                  apiKey={tinyMCEApiUrl}
                  disabled={!canUpdate}
                  onBlur={onBlur}
                  onEditorChange={onChange}
                  value={value}
                  textareaName={name}
                />
              )}
            />
            <Grid
              container
              style={{
                paddingTop: '1rem',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Grid item>
                <RadioControl
                  name="promotional_position"
                  as={
                    <PromotionalPositionRadioGroup
                      disabled={!canUpdate}
                      style={{ display: 'flex', flexDirection: 'column' }}
                    />
                  }
                />
              </Grid>
              <Grid
                item
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-evenly',
                  flexGrow: 1,
                }}
              >
                <div>
                  <KeyboardDatePickerControl
                    control={control}
                    error={errors.promotional_start_date}
                    label="Display From"
                    margin="normal"
                    name="promotional_start_date"
                    style={{ width: '175px', marginTop: 0 }}
                    disabled={!canUpdate}
                  />
                </div>
                <div>
                  <KeyboardDatePickerControl
                    control={control}
                    error={errors.promotional_expiry_date}
                    label="Display To"
                    margin="normal"
                    name="promotional_expiry_date"
                    style={{ width: '175px', marginTop: 0 }}
                    disabled={!canUpdate}
                  />
                </div>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={2}>
            <Controller
              error={errors.private_notes}
              name="private_notes"
              render={({ field: { onChange, onBlur, value, name } }) => (
                <Editor
                  init={{
                    height: 400,
                    plugins: ['lists link image paste help wordcount'],
                    toolbar:
                      'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | help',
                  }}
                  apiKey={tinyMCEApiUrl}
                  disabled={!canUpdate}
                  onBlur={onBlur}
                  onEditorChange={onChange}
                  value={value}
                  textareaName={name}
                />
              )}
            />
          </TabPanel>
        </TabContext>
      </Container>
    </Paper>
  );
};

VehicleDescription.fragments = {
  vehicle: gql`
    fragment VehicleDescriptionVehicle on GreaseInventoryVehicle {
      private_notes
      promotional_expiry_date
      promotional_info
      promotional_position
      promotional_start_date
      published_notes
    }
  `,
};

export default VehicleDescription;
