import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { startCase, toLower } from 'lodash';
import { useQuery } from '@apollo/react-hooks';

import Box from '@mui/material/Box';
import Hidden from '@mui/material/Hidden';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { makeStyles } from '@mui/styles';

import ErrorDisplay from 'components/MaterialUI/ErrorDisplay';
import Loading from 'components/MaterialUI/Loading';
import { CBB_CONDITIONS } from '../../const';
import { formatPrice } from 'utils';

const VALUATION_VALUE_QUERY = gql`
  query ValuationValueQuery($vid: Int!, $odometer: Int) {
    valuation {
      getValue(vid: $vid, kilometers: $odometer) {
        options {
          average
          clean
          description
          extra_clean
          option_code
          rough
        }
        wholesale_prices {
          average
          clean
          extra_clean
          rough
        }
        retail_prices {
          average
          clean
          extra_clean
          rough
        }
      }
    }
  }
`;

const propTypes = {
  condition: PropTypes.string,
  equipment: PropTypes.string,
  odometer: PropTypes.number,
  vid: PropTypes.number,
};

const defaultProps = {
  condition: undefined,
  equipment: undefined,
  odometer: undefined,
  vid: undefined,
};

const useStyles = makeStyles(theme => ({
  tableHeading: {
    textAlign: 'center',
  },
  tableNumber: {
    textAlign: 'right',
  },
}));

const CBBValueTable = ({ condition, equipment, odometer, vid }) => {
  const { data, loading, error } = useQuery(VALUATION_VALUE_QUERY, {
    skip: !vid,
    variables: {
      odometer,
      vid,
    },
  });

  const classes = useStyles();

  const vehicleValue = data?.valuation?.getValue;

  const getWholesalePrice = key => vehicleValue?.wholesale_prices?.[key];
  const getRetailPrice = key => vehicleValue?.retail_prices?.[key];

  const getPriceAdjustments = key => {
    if (equipment)
      return vehicleValue?.options
        ?.filter(x => equipment.includes(x.option_code))
        .reduce((total, option) => total + option[key], 0);
    else return null;
  };

  const getWholesaleTotal = key =>
    getWholesalePrice(key) !== null && getPriceAdjustments(key) !== null
      ? getWholesalePrice(key) + getPriceAdjustments(key)
      : null;

  const getRetailTotal = key =>
    getRetailPrice(key) !== null && getPriceAdjustments(key) !== null
      ? getRetailPrice(key) + getPriceAdjustments(key)
      : null;

  if (error) return <ErrorDisplay error={error} action="Loading Values" />;
  if (loading) return <Loading />;

  return (
    <Box>
      <Hidden smDown>
        <Table style={{ minWidth: 700 }} aria-label="spanning table">
          <TableHead>
            <TableRow>
              <TableCell rowSpan={1} />
              <TableCell className={classes.tableHeading} colSpan={3}>
                Wholesale
              </TableCell>
              <TableCell className={classes.tableHeading} colSpan={3}>
                Retail
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell rowSpan={1} />
              <TableCell align="left">Price</TableCell>
              <TableCell className={classes.tableHeading}>Adjustment</TableCell>
              <TableCell className={classes.tableHeading}>Total</TableCell>
              <TableCell className={classes.tableHeading}>Price</TableCell>
              <TableCell className={classes.tableHeading}>Adjustment</TableCell>
              <TableCell className={classes.tableHeading}>Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {CBB_CONDITIONS.map(key => (
              <TableRow selected={key === condition} key={key}>
                <TableCell
                  style={key === condition ? { fontWeight: 'bold' } : {}}
                >
                  {startCase(toLower(key))}
                </TableCell>
                <TableCell className={classes.tableNumber}>
                  {formatPrice(getWholesalePrice(key))}
                </TableCell>
                <TableCell className={classes.tableNumber}>
                  {formatPrice(getPriceAdjustments(key))}
                </TableCell>
                <TableCell className={classes.tableNumber}>
                  {formatPrice(getWholesaleTotal(key))}
                </TableCell>
                <TableCell className={classes.tableNumber}>
                  {formatPrice(getRetailPrice(key))}
                </TableCell>
                <TableCell className={classes.tableNumber}>
                  {formatPrice(getPriceAdjustments(key))}
                </TableCell>
                <TableCell className={classes.tableNumber}>
                  {formatPrice(getRetailTotal(key))}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Hidden>
      <Hidden smUp>
        <Table aria-label="spanning table">
          <TableHead>
            <TableRow>
              <TableCell rowSpan={1} />
              <TableCell className={classes.tableHeading}>Wholesale</TableCell>
              <TableCell className={classes.tableHeading}>Retail</TableCell>
            </TableRow>
            <TableRow>
              <TableCell rowSpan={1} />
              <TableCell className={classes.tableHeading}>Total</TableCell>
              <TableCell className={classes.tableHeading}>Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {CBB_CONDITIONS.map(key => (
              <TableRow selected={key === condition} key={key}>
                <TableCell
                  style={key === condition ? { fontWeight: 'bold' } : {}}
                >
                  {startCase(toLower(key))}
                </TableCell>
                <TableCell className={classes.tableNumber}>
                  {formatPrice(getWholesaleTotal(key))}
                </TableCell>
                <TableCell className={classes.tableNumber}>
                  {formatPrice(getRetailTotal(key))}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Hidden>
    </Box>
  );
};

CBBValueTable.propTypes = propTypes;
CBBValueTable.defaultProps = defaultProps;

export default CBBValueTable;
