import React from 'react';

/* external */
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';

/* Material UI */
import { Box, Button, Grid } from '@mui/material';

/* internal */
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';

const FRAGMENT = gql`
  fragment TradeVautoFormBoxAppraisal on Appraisal {
    id
    vautoId
    vautoDeepLink
  }
`;
const SEND_TO_VAUTO = gql`
  mutation sendToVauto($id: Int!) {
    appraisals {
      sendToVauto(id: $id) {
        id
        ...TradeVautoFormBoxAppraisal
      }
    }
  }
  ${FRAGMENT}
`;

const TradeVautoFormBox = ({
  appraisal,
  onCancel,
  onBack,
  onUpdate,
  ...rest
}) => {
  const { id, vautoId } = appraisal;
  const { enqueueSnackbar: snackify } = useSnackbar();
  const [sendToVauto, sendToVautoMutation] = useMutation(SEND_TO_VAUTO, {
    onCompleted: data => {
      onUpdate(data.appraisals.sendToVauto);
      snackify('Appraisal sent to vAuto!', { variant: 'success' });
    },
    onError: error =>
      snackify(`Failed to send appraisal: ${JSON.stringify(error)}`, {
        variant: 'error',
      }),
  });

  const handleSend = () => sendToVauto({ variables: { id } });
  return (
    <Box {...rest}>
      <LoadingBackdrop open={sendToVautoMutation.loading}>
        Sending appraisal to vAuto.
      </LoadingBackdrop>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {vautoId
            ? 'Appraisal has been sent to vAuto.'
            : 'Send Appraisal to vAuto?'}
        </Grid>
        <Grid item xs={12} md={3}>
          {!vautoId && (
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={handleSend}
            >
              Send
            </Button>
          )}
        </Grid>
      </Grid>

      <Box display="flex" justifyContent="flex-end" m={2} gap="8px">
        <Button variant="contained" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="contained" onClick={onBack}>
          Back
        </Button>
        <Button variant="contained" onClick={onCancel}>
          Close
        </Button>
      </Box>
    </Box>
  );
};

TradeVautoFormBox.fragments = {
  appraisal: FRAGMENT,
};

export default TradeVautoFormBox;
