import React, { useRef, useState } from 'react';
import { withStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import { cloneDeep } from 'lodash';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import InspectionCheckboxes from '../InspectionCheckboxes';

const WinterizingForm = props => {
  const { enqueueSnackbar } = useSnackbar();

  // form fields
  const items = {
    w1: 'Fresh tank drained',
    w2: 'Water heater drained',
    w3: 'Check and report holding tank levels',
    w4: 'All caps/plugs inside exterior water heater access winterized',
    w5: 'All taps, including outside kitchens’ winterized',
    w6: 'All exterior showers/spray ports winterized',
    w7: 'All toilets (check for second/third bathroom) winterized',
    w8: 'City water connection winterized (NOT under pressure)',
    w9: 'Washer and dryer connections winterized',
    w10: 'Dishwasher winterized',
    w11: 'Refrigerator icemaker winterized',
    w12: 'Pump reassembled and back into “summer mode”',
    w13: 'All access panels reinstalled and secure',
    w14: '***If unit has docking station, winterize selector valve in both positions.***',
  };

  let itemsObj = cloneDeep(items);

  Object.entries(itemsObj).map(([name]) => (itemsObj[name] = 'Done'));

  const [itemsValues, setItemsValues] = useState(itemsObj);

  const handleItems = e => {
    const { name, value } = e.target;
    setItemsValues(prevItemsValues => ({
      ...prevItemsValues,
      [name]: value,
    }));
  };

  const [consent, setConsent] = useState(false);
  const handleConsent = () => {
    setConsent(consent => !consent);
  };

  const [confirm, setConfirm] = useState(false);
  const handleConfirm = () => {
    setConfirm(confirm => !confirm);
  };

  const wholeForm = useRef(null);

  const handleSubmit = e => {
    e.preventDefault();

    if (!consent) {
      enqueueSnackbar(`Please check 'I agree' and then submit the inspection!`);
      return;
    }

    const form_data = {
      winterizing: itemsValues,
      amvic_number: wholeForm.current['amvic_number'].value,
    };

    props.formData(form_data);
    props.formClose();
  };

  const GreenButton = withStyles({
    root: {
      color: '#fff',
      backgroundColor: '#198754',
      '&:hover': {
        backgroundColor: '#157347',
      },
    },
  })(Button);

  return (
    <form onSubmit={handleSubmit} ref={wholeForm}>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Checklist</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(items).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={8} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={4}>
                    <InspectionCheckboxes
                      valueGroup={itemsValues}
                      handleGroup={handleItems}
                      name={name}
                      orange={false}
                      greenValue="Done"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Typography style={{ marginTop: '15px' }}>
        I verify the contents of this document are true and correct.
      </Typography>
      <FormControlLabel
        control={<Checkbox />}
        label="I agree"
        onChange={handleConsent}
        value={consent}
      />
      <br />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Technician Name"
            value={props.user.display_name}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Tech #"
            value={props.user.goUserProfile.profile.sales_licence}
            fullWidth
            name={'amvic_number'}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Date"
            value={new Date().toLocaleString()}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <Button
            type="button"
            variant="contained"
            style={{ marginRight: '15px' }}
            onClick={handleConfirm}
            color="default"
          >
            Cancel
          </Button>
          <Dialog
            open={confirm}
            onClose={handleConfirm}
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Current inspection will be closed and unsaved data will be gone.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleConfirm}>Cancel</Button>
              <Button onClick={props.formClose} color="secondary">
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
          <GreenButton type="submit" variant="contained">
            Save
          </GreenButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default WinterizingForm;
