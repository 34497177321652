import { formatPrice } from '../../../utils';
import { NonRetailDeals } from '../constants';
import DealLogAttachments from './DealLogAttachmentsModal';
import DealLogDelivery from './DealLogDelivery';
import GrossProfitModal from './DealLogGrossProfit';
import DealLogOpportunityNotes from './DealLogNoteModal';
import DealLogRDRPunch from './DealLogRDRPunch';
import DealLogStatusModal from './DealLogStatusModal';
import DealLogSubStatusModal from './DealLogSubStatusModal';
import TubeOpportunityModal from './TubeOpportunityModal';
import { useMutation } from '@apollo/react-hooks';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PublishIcon from '@mui/icons-material/Publish';
import { useUserContext } from 'components/MaterialUI/UserContext';
import { Role, Status } from 'constants.js';
import gql from 'graphql-tag';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ReconCompleteIndicator from './ReconCompleteIndicator';

const UPDATE_OPP_STATUSES = gql`
  mutation updateOppStatuses(
    $id: ID!
    $statuses: UpdateOpportunityStatusInput!
  ) {
    updateOpportunityStatus(_id: $id, input: $statuses) {
      _id
      status
      sub_status
    }
  }
`;

const DealLogMobileCard = ({
  opportunity,
  subStatuses,
  userData,
  className,
  notASalesRole,
  notAServiceRole,
}) => {
  const { currentUser } = useUserContext();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showNotes, setShowNotes] = useState(false);
  const [showAttachments, setShowAttachments] = useState(false);
  const [showProfit, setShowProfit] = useState(false);
  const [showDelivery, setShowDelivery] = useState(false);
  const [showRDR, setShowRDR] = useState(false);
  const [status, setStatus] = useState(opportunity.status);
  const [subStatus, setSubStatus] = useState(opportunity.sub_status);
  const [updateStatus] = useMutation(UPDATE_OPP_STATUSES);
  const [showTubedModal, setShowTubedModal] = useState(false);
  const [showSubStatus, setShowSubStatus] = useState(false);
  const [showStatus, setShowStatus] = useState(false);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [opportunityDelivery, setOpportunityDelivery] = useState(
    opportunity?.appointments.length > 0
      ? opportunity.appointments.find(app => app.type_name === 'Delivery')
      : null,
  );
  const [user, setUser] = React.useState(null);
  const dmsDeal = opportunity.dms_deal;
  const isSalesRole = !notASalesRole;
  const isServiceRole = !notAServiceRole;
  const accountingData = opportunity.accounting_deal;
  const storeTotalGross = dmsDeal?.total_gross || 0;
  const frontendGross = accountingData?.frontend_gross.value || 0;
  const backendGross = accountingData?.backend_gross.value || 0;
  const postedTotalGross = frontendGross + backendGross;
  const totalGross = postedTotalGross - storeTotalGross;
  const salesDeal = dmsDeal?.sales_deal_type
    ? NonRetailDeals.includes(dmsDeal.sales_deal_type)
      ? dmsDeal.sales_deal_type
      : 'Retail'
    : 'Retail';

  let dates = Object.values(opportunity.last_status_change).map(d => moment(d));
  const firstCreatedDate = moment.min(dates);
  const lastUpdatedDate = moment.max(dates);
  const subStatusDropDown = [...subStatuses];
  const isPostedAndNotOrgAdmin =
    opportunity.status === Status.POSTED &&
    currentUser.role !== Role.ORGANIZATION_ADMIN;

  const vehicleNameGlued = [dmsDeal.year, dmsDeal.make_name, dmsDeal.model_name]
    .filter(x => x)
    .join(' ');

  const vehicleName =
    dmsDeal.stock_number === 'FACTORY'
      ? opportunity?.primary_pitch_deallog?.vehicles[0]?.vehicleName ??
        vehicleNameGlued
      : vehicleNameGlued;

  const tradeName =
    dmsDeal.trades !== null
      ? [
          dmsDeal.trades[0]?.year,
          dmsDeal.trades[0]?.make_name,
          dmsDeal.trades[0]?.model_name,
        ]
          .filter(x => x)
          .join(' ')
      : 'No Trade';

  useEffect(() => {
    if (opportunityDelivery) {
      setUser(
        userData.users.find(
          user => user.username === opportunityDelivery.attendee_usernames[0],
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opportunityDelivery]);

  const hasDeallogAttachments = ({ attachments }) =>
    attachments.some(a => a.deleted === false);

  return (
    <Grid item style={{ padding: '0.25rem 0' }}>
      <Dialog
        disabled={isSalesRole}
        maxWidth="md"
        open={showAttachments}
        onClose={() => {
          setShowAttachments(false);
        }}
      >
        <DealLogAttachments
          handleClose={() => {
            setShowAttachments(false);
          }}
          opportunityID={opportunity._id}
          dealNumber={opportunity.dms_deal?.deal_number || 'No deal found'}
        />
      </Dialog>
      <Dialog
        disabled={isSalesRole || isServiceRole}
        maxWidth="md"
        fullWidth={true}
        scroll="paper"
        open={showNotes}
        onClose={() => {
          setShowNotes(false);
        }}
      >
        <DealLogOpportunityNotes
          handleClose={() => {
            setShowNotes(false);
          }}
          opportunity={opportunity}
        />
      </Dialog>
      <Dialog
        disabled={isSalesRole || isServiceRole}
        maxWidth="md"
        fullWidth={true}
        scroll="paper"
        open={showProfit}
        onClose={() => {
          setShowProfit(false);
        }}
      >
        <DialogTitle id="GrossProfitScreen">
          <Box>
            Gross Profit on Deal #{opportunity.dms_deal.deal_number} -{' '}
            {opportunity.customer?.company_name || opportunity.customer_name}
          </Box>
        </DialogTitle>
        <GrossProfitModal
          opportunity={opportunity}
          includeGross={notASalesRole && notAServiceRole}
          handleClose={() => {
            setShowProfit(false);
          }}
        />
      </Dialog>
      <Dialog
        disabled={isSalesRole || isServiceRole}
        fullWidth={true}
        maxWidth="sm"
        scroll="paper"
        open={showDelivery}
        onClose={() => {
          setShowDelivery(false);
        }}
      >
        <DealLogDelivery
          handleClose={() => {
            setShowDelivery(false);
          }}
          userData={userData}
          opportunity={opportunity}
          delivery={opportunityDelivery}
          setDelivery={setOpportunityDelivery}
          user={user}
          setUser={setUser}
        />
      </Dialog>
      <DealLogRDRPunch
        opportunity={opportunity}
        handleClose={() => {
          setShowRDR(false);
        }}
        setShowRDR={setShowRDR}
        showRDR={showRDR}
      />
      <TubeOpportunityModal
        opportunityId={opportunity._id}
        onClose={() => setShowTubedModal(false)}
        isOpen={showTubedModal}
        updateStatus={() => setStatus(Status.TUBED)}
      />
      <DealLogStatusModal
        showStatus={showStatus}
        setShowStatus={setShowStatus}
        setShowTubedModal={setShowTubedModal}
        updateStatus={updateStatus}
        opportunity={opportunity}
        setStatus={setStatus}
        isPostedAndNotOrgAdmin={isPostedAndNotOrgAdmin}
        isSalesRole={isSalesRole}
        isServiceRole={isServiceRole}
        status={status}
        handleClose={() => setShowStatus(false)}
      />
      <DealLogSubStatusModal
        showSubStatus={showSubStatus}
        setShowSubStatus={setShowSubStatus}
        updateStatus={updateStatus}
        opportunity={opportunity}
        setSubStatus={setSubStatus}
        isSalesRole={isSalesRole}
        isServiceRole={isServiceRole}
        subStatus={subStatus}
        handleClose={() => setShowSubStatus(false)}
        subStatusDropDown={subStatusDropDown}
      />
      <Card
        key={opportunity._id}
        className={className}
        style={{ width: '100%' }}
      >
        <CardContent>
          <Grid
            container
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Grid
              item
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                width: '50%',
              }}
            >
              <Box
                style={{ fontWeight: 'bold' }}
                onClick={() =>
                  opportunity.customer
                    ? history.push(
                        `/customers/${opportunity.customer_id}/details`,
                      )
                    : alert(
                        `No customer attached to opportunity ${opportunity._id}. Please contact market support!`,
                      )
                }
              >
                {opportunity.customer?.company_name ||
                  opportunity.customer_name}
              </Box>
              <Box
                style={{
                  fontSize: '13px',
                  color: '#999999',
                  fontWeight: 'bold',
                  display: 'flex',
                  paddingBottom: '0.5rem',
                }}
              >
                Deal #:{' '}
                {`${dmsDeal.deal_number} (${
                  dmsDeal.payment_type
                }) (${salesDeal}/${opportunity.deal_type || ''}) `}
              </Box>
              <Box style={{ fontSize: '12px', fontWeight: 'bold' }}>
                {vehicleName}
              </Box>
              <Box style={{ fontSize: '12px', fontWeight: 'bold' }}>
                Stock: {dmsDeal.stock_number}
                {opportunity?.vehicle_recon_complete && (
                  <ReconCompleteIndicator />
                )}
              </Box>
              <Box style={{ fontSize: '12px' }}>Trade: {tradeName}</Box>
              <Box style={{ fontSize: '12px' }}>
                SP:{' '}
                {opportunity.sales_reps.length > 0
                  ? opportunity.sales_reps[0].display_name
                  : 'No SP'}
              </Box>
              <Box style={{ fontSize: '12px' }}>
                SM:{' '}
                {opportunity.sales_managers.length > 0
                  ? opportunity.sales_managers[0].display_name
                  : 'No SM'}
              </Box>
              <Box style={{ fontSize: '12px' }}>
                FP:{' '}
                {opportunity.finance_managers.length > 0
                  ? opportunity.finance_managers[0].display_name
                  : 'No FP'}
              </Box>
              <Box
                style={{
                  fontSize: '11px',
                  color: '#999999',
                  paddingTop: '0.25rem',
                }}
              >
                Added: {firstCreatedDate.format('MMM Do YYYY')}
              </Box>
              <Box
                style={{
                  fontSize: '11px',
                  color: '#999999',
                  paddingTop: '0.25rem',
                }}
              >
                Delivery:{' '}
                {opportunityDelivery?.start_date !== undefined
                  ? moment(opportunityDelivery.start_date).format('YYYY-MM-DD')
                  : ''}
              </Box>
            </Grid>
            <Grid
              item
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <Box textAlign="right">
                <Checkbox
                  disabled
                  style={{ color: 'black' }}
                  checked={opportunity?.rdr_punch?.punch_date}
                />{' '}
                <span style={{ paddingRight: '1rem' }}>RDR</span>
                {hasDeallogAttachments(opportunity) && (
                  <Badge color="secondary" variant="dot" overlap="rectangular">
                    <PublishIcon />
                  </Badge>
                )}
                {notASalesRole && (
                  <IconButton
                    color="primary"
                    onClick={handleClick}
                    size="large"
                  >
                    <MoreVertIcon />
                  </IconButton>
                )}
                <Menu
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={() => setShowProfit(true)}>
                    G-Screen
                  </MenuItem>
                  <MenuItem onClick={() => setShowNotes(true)}>Notes</MenuItem>
                  <MenuItem onClick={() => setShowAttachments(true)}>
                    Attachments
                  </MenuItem>
                  <MenuItem onClick={() => setShowDelivery(true)}>
                    Delivery
                  </MenuItem>
                  {opportunity.deal_type === 'New' &&
                    !opportunity?.rdr_punch?.punch_date && (
                      <MenuItem onClick={() => setShowRDR(true)}>
                        RDR Punch
                      </MenuItem>
                    )}
                  {!(
                    isPostedAndNotOrgAdmin ||
                    isSalesRole ||
                    isServiceRole
                  ) && (
                    <MenuItem onClick={() => setShowStatus(true)}>
                      Change Status
                    </MenuItem>
                  )}
                  {!(isSalesRole || isServiceRole) && (
                    <MenuItem onClick={() => setShowSubStatus(true)}>
                      Change Sub Status
                    </MenuItem>
                  )}
                </Menu>
              </Box>
              <Box>
                <div style={{ textAlign: 'right', fontWeight: 'bold' }}>
                  {opportunity.status_display}
                </div>
              </Box>
              <Box>
                <div style={{ textAlign: 'right' }}>
                  {opportunity?.sub_status?.length > 20
                    ? opportunity?.sub_status?.slice(0, 20) + ' ...'
                    : opportunity?.substatus}
                </div>
              </Box>
              {notASalesRole && notAServiceRole && (
                <>
                  <Box>
                    <div
                      style={{
                        color: `${totalGross >= 0 ? 'black' : 'red'}`,
                        textAlign: 'right',
                      }}
                    >
                      {formatPrice(totalGross)}
                    </div>
                  </Box>
                  <Box>
                    <div style={{ textAlign: 'right' }}>
                      Store: {formatPrice(dmsDeal?.total_gross)}
                    </div>
                  </Box>
                  <Box>
                    <div style={{ textAlign: 'right' }}>
                      Posted: {formatPrice(postedTotalGross)}
                    </div>
                  </Box>
                </>
              )}
              <Box>
                <div
                  style={{
                    textAlign: 'right',
                    fontSize: '11px',
                    color: '#999999',
                    paddingTop: '0.25rem',
                  }}
                >
                  Updated: {lastUpdatedDate.format('MMM Do YYYY')}
                </div>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default DealLogMobileCard;
