import React from 'react';
import { useHistory } from 'react-router-dom';

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  mobileWidth: {
    paddingRight: '5px',
    width: '100%',
  },
  desktopWidth: {
    paddingRight: '5px',
    width: '400px',
  },
}));

const InventorySearch = ({ mobile, dashboard, queryContext }) => {
  const classes = useStyles();
  const { searchKeywords, setSearchKeywords } = queryContext();
  const history = useHistory();

  const [value, setValue] = React.useState(searchKeywords);

  const handleChange = event => {
    setValue(event.target.value);
  };

  const redirectToInventory = value => {
    history.push(`/inventory?keywords=${value}`);
  };

  const handleSearch = event => {
    if (event.key === 'Enter') {
      setSearchKeywords(value.toString());
      if (dashboard) {
        redirectToInventory(value.toString());
      }
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <TextField
        placeholder="Search Vehicles ..."
        className={mobile ? classes.mobileWidth : classes.desktopWidth}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                disabled={!value}
                onClick={() => {
                  setValue('');
                  setSearchKeywords('');
                }}
                size="large"
              >
                <CloseIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        value={value || ''}
        onChange={handleChange}
        onKeyPress={handleSearch}
      />
    </div>
  );
};

export default InventorySearch;
