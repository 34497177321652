/* external */
import { useQuery } from '@apollo/react-hooks';
import { NetworkStatus } from 'apollo-boost';
import { cloneDeep, isEmpty } from 'lodash';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';
import { Waypoint } from 'react-waypoint';

/* material ui */
import {
  Box,
  Card,
  Chip,
  Drawer,
  Fab,
  FormControlLabel,
  FormGroup,
  Grid,
  Hidden,
  IconButton,
  Paper,
  Switch,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import FilterListIcon from '@mui/icons-material/FilterList';
import MoreVertIcon from '@mui/icons-material/MoreVert';

/* internal */
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import DealerPicker from 'components/MaterialUI/DealerPicker';
import Loading from 'components/MaterialUI/Loading';
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';
import { useUserContext } from 'components/MaterialUI/UserContext';

import { formatPrice } from 'utils';

import { ArrayFilters, InitialFilters } from '../constants';
import DealLogBulkActions from './DealLogBulkActions';
import DealLogMobileCard from './DealLogMobileCard';
import DealLogMobileMetrics from './DealLogMobileMetrics';
import DealLogTableHeader from './DealLogTableHeader';
import DealLogTableMetrics from './DealLogTableMetrics';
import DealLogTableRow from './DealLogTableRow';
import DealLogSearch from './DealLogTableSearch';
import DropDownFilters from './DropDownFilters';
import {
  ALL_USERS_FOR_DEALER,
  CURRENT_DEALER_QRY,
  DEALER_OPPORTUNITIES,
  DEALER_OPPORTUNITY_METRICS,
  USERS_FOR_DEALER,
} from './queries';
import { tubedPercent, usePersistedState } from './utils';
import { RoleGroup, Status } from 'constants.js';

const PAGE_SIZE = 25;
const LOADING_STATUSES = [NetworkStatus.loading, NetworkStatus.setVariables];

const useStyles = makeStyles({
  card: {
    width: '125px',
    height: '100px',
    textAlign: 'center',
    paddingTop: '1rem',
    fontSize: '15px',
  },
  multiLine: {
    whiteSpace: 'pre-line',
    fontWeight: 'bold',
    fontSize: '18px',
  },
  tableCell: {
    width: '100px',
    padding: '1rem',
  },
  deallogGreen: {
    backgroundColor: '#ccff90',
  },
  deallogPurple: {
    backgroundColor: '#e1bee7',
  },
  deallogYellow: {
    backgroundColor: '#FFFF8D',
  },
  deallogApprovedMobile: {
    backgroundImage:
      'linear-gradient(90deg, #ccff90, #ccff90 33%, white 33%, white 33%)',
  },
  deallogSignedMobile: {
    backgroundImage: 'linear-gradient(90deg, #ccff90 66%, white 33%)',
  },
});

const DealLogTable = () => {
  const classes = useStyles();
  const { dealerId } = useDealerContext();
  const { currentUser } = useUserContext();
  const { enqueueSnackbar } = useSnackbar();
  const notASalesRole = !RoleGroup.SALES_PEOPLE.includes(currentUser.role);
  const notAServiceRole = !RoleGroup.SERVICE_PEOPLE.includes(currentUser.role);
  const [order, setOrder] = usePersistedState('dealLogOrder', 'asc');
  const [orderBy, setOrderBy] = usePersistedState('dealLogOrderBy', '');
  const [showFilters, setShowFilters] = useState(false);
  const [showColorCode, setColorCode] = useState(true);
  const [filters, setFilters] = usePersistedState(
    'deallogFilters',
    InitialFilters,
  );
  const [searchWords, setSearchWords] = useState(null);
  const { data: userData } = useQuery(USERS_FOR_DEALER, {
    variables: { dealerId },
  });
  const { data: allUserData } = useQuery(ALL_USERS_FOR_DEALER, {
    variables: { dealerId },
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const handleAnchorClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleAnchorClose = () => {
    setAnchorEl(null);
  };

  const handleKeywordSearch = keywords => {
    // Check if filters has keyword search
    const isValid = keywords !== null || keywords !== '';
    // if keyword is valid and keywords already in replace
    if (isValid && keywords) {
      setSearchWords(keywords);
      refetchOpps({ keywords });
      metricsRefetch({ keywords });
    } else {
      // If its not valid then don't search.
      setSearchWords(null);
      refetchOpps({ keywords: undefined });
      metricsRefetch({ keywords: undefined });
    }
  };

  const generateFilters = vars => {
    // Generate refetch filters and refetch
    let variables = {};
    const keys = Object.keys(vars);
    // Since were using a persistent we need to include the dealer id
    if (!keys.includes('dealerId')) {
      variables['dealerId'] = dealerId;
    }
    if (!keys.includes('includeGross')) {
      variables['includeGross'] = notASalesRole && notAServiceRole;
    }

    keys.forEach(key => {
      // Handle deal types and assignees
      if (ArrayFilters.includes(key)) {
        variables[key] = vars[key].map(option => option.value);
        // If its an empty list set to undefined
        if (variables[key].length === 0) {
          variables[key] = undefined;
        }
      }
      // Generate Statuses
      // Only generate once.
      else if (
        !Object.keys(variables).includes('statuses') &&
        (key === 'hide_statuses' ||
          key === 'selected_statuses' ||
          key === 'saved_deal_statuses')
      ) {
        const ignore = vars?.hide_statuses?.map(option => option.value) ?? [];
        // If any statuses are selected use them otherwise use all statuses
        let select = vars?.selected_statuses?.map(option => option.value) ?? [];
        if (select.length === 0) {
          select = Status.DEALLOG_OPPS;
        }
        // Saved Deal overwrites all other statuses
        if (!isEmpty(vars?.saved_deal_statuses ?? [])) {
          select = Status.SAVE_DEAL_STATUSES;
        }
        // Filter out ignored statuses
        variables['statuses'] = select.filter(
          status => !ignore.includes(status),
        );
      } else if (key === 'has_variance') {
        // If variance is included in vars set to true
        variables[key] = vars[key].length > 0;
      } else if (key === 'missing_attachments') {
        variables[key] = vars[key].length > 0;
      } else if (key === 'date_options') {
        // There will always be a date filter selected
        // Calculate if we need to include open opps
        // If its the year to date filter apply correct filter
        const [month, year] = vars[key][0].value.split(' ');
        const yearToDate = vars[key][0].title === 'Year to Date';
        const includeOpen =
          moment(new Date(year, month - 1)).isSame(moment(), 'month') &&
          moment(new Date(year, month - 1)).isSame(moment(), 'year');
        variables['month'] = Number(month);
        variables['year'] = Number(year);
        variables['include_open'] = includeOpen && !yearToDate;
        variables['year_to_date'] = yearToDate;
      }
    });
    return variables;
  };

  const toggleFilter = (field, value, title = '') => {
    // Don't manipulate state directly
    let clonedFilters = cloneDeep(filters);
    // If filter isn't present add it
    if (!clonedFilters.hasOwnProperty(field)) {
      clonedFilters[field] = [{ value: value, title: title }];
    } else {
      // If its in the list.... remove it.
      let hasFilter = false;
      let filterIndex = -1;
      clonedFilters[field].forEach((option, index) => {
        if (option.value === value) {
          hasFilter = true;
          filterIndex = index;
        }
      });
      if (hasFilter && filterIndex !== -1) {
        clonedFilters[field].splice(filterIndex, 1);
        if (clonedFilters[field].length === 0) {
          // If a date filter got removed, default to MTD filter
          if (field === 'date_options') {
            clonedFilters[field] = [
              {
                value: moment().format('M YYYY'),
                title: 'Month to Date',
              },
            ];
          }
        }
      } else {
        if (field === 'date_options') {
          // Only a single date option should be selected
          clonedFilters[field] = [{ value: value, title: title }];
        } else {
          clonedFilters[field].push({
            value: value,
            title: title,
          });
        }
      }
    }
    const newFilters = generateFilters(clonedFilters);
    metricsRefetch(newFilters);
    refetchOpps(newFilters);
    setFilters(clonedFilters);
  };

  const { data: dealerData } = useQuery(CURRENT_DEALER_QRY, {
    variables: { dealerId },
  });

  const dealer = dealerData?.dealer ?? {};

  const {
    loading: metricsLoading,
    data: metricsData,
    refetch: metricsRefetch,
    networkStatus: metricsNetwork,
  } = useQuery(DEALER_OPPORTUNITY_METRICS, {
    variables: generateFilters(filters),
    notifyOnNetworkStatusChange: true,
  });

  const metrics = metricsData?.getDealLogMetrics?.metrics || {};
  const roleMetrics = metricsData?.getDealLogRoleMetrics || {};

  const sort_by =
    (order &&
      orderBy && [{ key: orderBy, direction: order === 'asc' ? 1 : -1 }]) ||
    undefined;

  const {
    loading: oppsLoading,
    data: oppsData,
    refetch: refetchOpps,
    fetchMore,
    networkStatus: oppsNetwork,
  } = useQuery(DEALER_OPPORTUNITIES, {
    variables: { ...generateFilters(filters), sort_by, page: 1 },
    notifyOnNetworkStatusChange: true,
  });

  const opps = oppsData?.opportunities?.results || [];
  const pageInfo = oppsData?.opportunities?.pagination || {};
  const loadMoreResults = useCallback(() => {
    if (pageInfo.page * PAGE_SIZE < pageInfo.total) {
      fetchMore({
        variables: {
          page: pageInfo.page + 1,
        },
        updateQuery: (previous, { fetchMoreResult }) => {
          const newResults = fetchMoreResult.opportunities.results;
          const newPagination = fetchMoreResult.opportunities.pagination;
          return newResults.length
            ? {
                opportunities: {
                  __typename: previous.opportunities.__typename,
                  results: [...previous.opportunities.results, ...newResults],
                  pagination: newPagination,
                },
              }
            : previous;
        },
      });
    }
  }, [fetchMore, pageInfo]);

  const handleRequestSort = (_, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const resetFilters = () => {
    const resetFilters = generateFilters(InitialFilters);
    metricsRefetch(resetFilters);
    refetchOpps(resetFilters);
    setFilters(InitialFilters);
  };

  const removeFilter = (key, title) => {
    // Don't directly manipulate state
    let updatedFilters = cloneDeep(filters);
    updatedFilters[key] = updatedFilters[key].filter(
      option => option.title !== title,
    );

    // We always need a date filter
    if (key === 'date_options') {
      if (updatedFilters[key].length === 0) {
        updatedFilters[key] = [
          {
            value: moment().startOf('month').format('M YYYY'),
            title: 'Month to Date',
          },
        ];
        enqueueSnackbar('A date filter is required.', { variant: 'warning' });
      }
    }
    const newFilters = generateFilters(updatedFilters);
    metricsRefetch(newFilters);
    refetchOpps(newFilters);
    setFilters(updatedFilters);
  };

  const statusBackgroundColor = (opp, mobile) => {
    if (!showColorCode) return;

    if (mobile) {
      if (opp.status === Status.APPROVED) {
        return classes.deallogApprovedMobile;
      } else if (opp.status === Status.SIGNED) {
        return classes.deallogSignedMobile;
      }
    }
    if (Status.COMPLETED.includes(opp.status)) {
      return classes.deallogGreen;
    } else if ([Status.LOST, Status.TUBED].includes(opp.status)) {
      return classes.deallogPurple;
    } else if (opp.status === Status.CARRYOVER) {
      return classes.deallogYellow;
    }
  };
  const queryLoading =
    (metricsLoading && LOADING_STATUSES.includes(metricsNetwork)) ||
    (oppsLoading && LOADING_STATUSES.includes(oppsNetwork));

  const fetchMoreLoading =
    (metricsLoading && metricsNetwork === NetworkStatus.fetchMore) ||
    (oppsLoading && oppsNetwork === NetworkStatus.fetchMore);

  return (
    <>
      <Box margin={1}>
        <DealerPicker />
        <Typography variant="h5">{dealer?.dealer_name} Deal Log</Typography>
      </Box>
      <Hidden smUp>
        <FormGroup row style={{ paddingRight: '10px' }}>
          <FormControlLabel
            value="start"
            control={
              <Switch
                checked={showColorCode}
                onChange={() => setColorCode(!showColorCode)}
                color="secondary"
              />
            }
            label="Toggle Deal Log Colors"
            labelPlacement="start"
          />
        </FormGroup>
        <Box margin={1}>
          <DealLogSearch
            updateFilter={handleKeywordSearch}
            keywords={searchWords}
            style={{
              display: 'flex',
              marginRight: 0,
              marginLeft: 'auto',
              marginBottom: 0,
            }}
          />
        </Box>
      </Hidden>
      <Box margin={1}>
        <Grid
          container
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
          }}
        >
          <Grid item>
            <div
              style={{
                display: 'flex',
              }}
            >
              <Hidden smDown>
                {notASalesRole && notAServiceRole && (
                  <div style={{ paddingRight: '1rem' }}>
                    <Tooltip title="Select Opportunities to use Bulk Actions">
                      <Fab
                        color="primary"
                        onClick={handleAnchorClick}
                        size="medium"
                      >
                        <MoreVertIcon />
                      </Fab>
                    </Tooltip>
                    <DealLogBulkActions
                      anchorEl={anchorEl}
                      filters={filters}
                      generateFilters={generateFilters}
                      handleAnchorClose={handleAnchorClose}
                    />
                  </div>
                )}
                <div style={{ paddingRight: '1rem' }}>
                  <Tooltip title="Filter list">
                    <Fab
                      color="primary"
                      onClick={() => setShowFilters(true)}
                      size="medium"
                    >
                      <FilterListIcon />
                    </Fab>
                  </Tooltip>
                </div>
              </Hidden>
              <Drawer
                anchor={'left'}
                open={showFilters}
                onClose={() => setShowFilters(false)}
              >
                <div style={{ display: 'flex' }}>
                  <IconButton
                    style={{
                      display: 'flex',
                      marginLeft: 'auto',
                      height: '50px',
                      zIndex: '1000',
                    }}
                    onClick={() => setShowFilters(false)}
                    size="large"
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
                <DropDownFilters
                  subStatuses={dealer?.desking_settings?.sub_statuses}
                  users={userData?.users}
                  filters={filters}
                  updateFilter={toggleFilter}
                  resetFilters={resetFilters}
                />
              </Drawer>
              {Object.keys(filters).map(key =>
                filters[key].map(chip => (
                  <Box
                    key={`${key}-${chip.title}`}
                    component="span"
                    style={{
                      paddingRight: '5px',
                      display: 'flex',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                    }}
                  >
                    <Chip
                      label={chip.title}
                      value={chip.value}
                      onDelete={() => removeFilter(key, chip.title)}
                    />
                  </Box>
                )),
              )}
            </div>
          </Grid>
          <Grid
            item
            style={{
              display: 'flex',
              marginRight: 0,
              marginLeft: 'auto',
              marginBottom: 0,
            }}
          >
            <Hidden smDown>
              <FormGroup row style={{ paddingRight: '10px' }}>
                <FormControlLabel
                  value="start"
                  control={
                    <Switch
                      checked={showColorCode}
                      onChange={() => setColorCode(!showColorCode)}
                      color="secondary"
                    />
                  }
                  label="Toggle Deal Log Colors"
                  labelPlacement="start"
                />
              </FormGroup>
              <DealLogSearch
                updateFilter={handleKeywordSearch}
                keywords={searchWords}
                style={{
                  display: 'flex',
                  marginRight: 0,
                  marginLeft: 'auto',
                  marginBottom: 0,
                }}
              />
            </Hidden>
          </Grid>
        </Grid>
      </Box>
      {queryLoading && <Loading />}
      {!queryLoading && (
        <>
          {notASalesRole && notAServiceRole && (
            <Hidden smUp>
              <Box margin={1}>
                <DealLogMobileMetrics metrics={metrics} />
              </Box>
            </Hidden>
          )}
          <Hidden smDown>
            <>
              <Box
                margin={1}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <Card className={classes.card}>
                  Writes
                  <div className={classes.multiLine}>
                    {(metrics?.total_count ?? 0) -
                      (metrics?.total_tubed_count ?? 0)}
                  </div>
                </Card>
                <Card className={classes.card}>
                  Pending
                  <div className={classes.multiLine}>
                    {metrics?.total_pending_count ?? 0}
                  </div>
                </Card>
                <Card className={classes.card}>
                  Cash
                  <div className={classes.multiLine}>
                    {metrics?.total_cash_count ?? 0}
                  </div>
                </Card>
                <Card className={classes.card}>
                  Approved
                  <div className={classes.multiLine}>
                    {metrics?.total_approved_count ?? 0}
                  </div>
                </Card>
                <Card className={classes.card}>
                  Signed
                  <div className={classes.multiLine}>
                    {metrics?.total_signed_count ?? 0}
                  </div>
                </Card>
                <Card className={classes.card}>
                  Delivered
                  <div className={classes.multiLine}>
                    {metrics?.total_delivered_count ?? 0}
                  </div>
                  {notASalesRole && notAServiceRole && (
                    <div className={classes.multiLine}>
                      {formatPrice(Math.round(metrics.total_delivered_gross))}
                    </div>
                  )}
                </Card>
                <Card className={classes.card}>
                  Posted
                  <div className={classes.multiLine}>
                    {metrics?.total_posted_count ?? 0}
                  </div>
                  {notASalesRole && notAServiceRole && (
                    <div className={classes.multiLine}>
                      {formatPrice(Math.round(metrics.total_posted_gross))}
                    </div>
                  )}
                </Card>
                <Card className={classes.card}>
                  RDR
                  <div className={classes.multiLine}>
                    {metrics?.rdr_vehicle_count ?? 0}
                  </div>
                </Card>
                <Card className={classes.card}>
                  Tubed
                  <div className={classes.multiLine}>
                    {metrics?.total_tubed_count ?? 0}
                  </div>
                  {notASalesRole && notAServiceRole && (
                    <div className={classes.multiLine}>
                      {tubedPercent(metrics)}%
                    </div>
                  )}
                </Card>
                {notASalesRole && notAServiceRole && (
                  <Card className={classes.card}>
                    Total Gross
                    <div className={classes.multiLine}>
                      {formatPrice(Math.round(metrics.total_gross))}
                    </div>
                  </Card>
                )}
              </Box>
              {notASalesRole && notAServiceRole && (
                <Box margin={1}>
                  <DealLogTableMetrics
                    dealer={dealer?.dealer_name}
                    metrics={metrics}
                    roleMetrics={roleMetrics}
                  />
                </Box>
              )}
            </>
          </Hidden>
          <Box margin={1}>
            <Hidden smUp>
              <>
                <Box
                  display="flex"
                  justifyContent="right"
                  paddingTop={1}
                  fontSize="18px"
                >
                  Showing {opps.length} of {pageInfo?.total ?? 0}
                </Box>
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  {opps.map(opportunity => (
                    <DealLogMobileCard
                      className={statusBackgroundColor(opportunity, true)}
                      userData={allUserData}
                      subStatuses={dealer?.desking_settings?.sub_statuses ?? []}
                      opportunity={opportunity}
                      key={opportunity._id}
                      notASalesRole={notASalesRole}
                      notAServiceRole={notAServiceRole}
                    />
                  ))}
                </Grid>
                {opps.length !== pageInfo.total && (
                  <Box height="1px">
                    <Waypoint onEnter={loadMoreResults} />
                  </Box>
                )}
              </>
            </Hidden>
            <Hidden smDown>
              <>
                <Box
                  display="flex"
                  justifyContent="right"
                  paddingTop={1}
                  fontSize="18px"
                >
                  Showing {opps.length} of {pageInfo.total}
                </Box>
                <TableContainer component={Paper}>
                  <Table className={classes.table}>
                    <DealLogTableHeader
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                      {opps.map(opportunity => (
                        <TableRow
                          key={opportunity._id}
                          style={{ borderBottom: '2px solid grey' }}
                          className={statusBackgroundColor(opportunity, false)}
                        >
                          <DealLogTableRow
                            userData={allUserData}
                            opportunity={opportunity}
                            subStatuses={
                              dealer?.desking_settings?.sub_statuses ?? []
                            }
                            className={
                              [
                                Status.APPROVED,
                                Status.SIGNED,
                                Status.CASH,
                              ].includes(opportunity.status)
                                ? classes.deallogGreen
                                : null
                            }
                            notASalesRole={notASalesRole}
                            notAServiceRole={notAServiceRole}
                            onUpdateStatus={() => metricsRefetch()}
                          />
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                {opps.length !== pageInfo.total && (
                  <Box height="1px">
                    <Waypoint onEnter={loadMoreResults} />
                  </Box>
                )}
              </>
            </Hidden>
          </Box>
          <Hidden smUp>
            {notASalesRole && notAServiceRole && (
              <Box position="fixed" bottom="75px" right="10px">
                <Fab color="primary" onClick={handleAnchorClick} size="medium">
                  <MoreVertIcon />
                </Fab>
                <DealLogBulkActions
                  anchorEl={anchorEl}
                  filters={filters}
                  generateFilters={generateFilters}
                  handleAnchorClose={handleAnchorClose}
                />
              </Box>
            )}
            <Box position="fixed" bottom="10px" right="10px">
              <Fab
                size="medium"
                color="primary"
                onClick={() => setShowFilters(true)}
              >
                <FilterListIcon />
              </Fab>
            </Box>
          </Hidden>
        </>
      )}
      {fetchMoreLoading && (
        <LoadingBackdrop open={true}>
          Loading more opportunities...
        </LoadingBackdrop>
      )}
    </>
  );
};

export default DealLogTable;
