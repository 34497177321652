import React from 'react';

/* Material UI */
import Box from '@mui/material/Box';

// [ application-received, application-submitted, awaiting-decision, conditional-approval, approved, declined, documents-received, documents-incomplete, booked ]
const StatusBox = ({ applicationStatus, applicationError, ...rest }) => {
  return (
    <Box display="flex" alignItems="center" {...rest}>
      {applicationError ? 'Error' : applicationStatus ?? 'Not submitted'}
    </Box>
  );
};

export default StatusBox;
