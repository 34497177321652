/* External */
import React from 'react';
import gql from 'graphql-tag';
import { useSnackbar } from 'notistack';
import { useMutation } from '@apollo/react-hooks';

/* Material UI */
import { Box, Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Cancel, CheckCircle } from '@mui/icons-material';

/* Internal */
import { CONVERSATIONS_QUERY } from './queries';
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';

const useStyles = makeStyles(theme => ({
  archiveButtonIconColour: {
    '& .MuiButton-startIcon': {
      color: theme.actions.confirm.backgroundColor,
    },
  },
  cancelButtonIconColour: {
    '& .MuiButton-startIcon': {
      color: theme.actions.danger.backgroundColor,
    },
  },
  buttonText: {
    textTransform: 'capitalize',
    fontWeight: 'normal',
  },
  countBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px',
    marginBottom: '5px',
    border: '3px solid ' + theme.colours.add,
  },
}));

const BULK_ARCHIVE_CONVERSATIONS = gql`
  mutation bulkArchiveConversations(
    $gatewayId: Int!
    $conversationIds: [Int!]
    $archived: Boolean
  ) {
    sms {
      bulkArchiveConversations(
        gatewayId: $gatewayId
        conversationIds: $conversationIds
        archived: $archived
      ) {
        id
      }
    }
  }
`;

const MultiSelect = ({
  setHandleMultiSelect,
  selectedItemsIds,
  setSelectedItemsIds,
  gatewayId,
  conversationQueryVariables,
  tabIndex,
  setSelectedChatId,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [updateConversations, { loading: updatingConversation }] = useMutation(
    BULK_ARCHIVE_CONVERSATIONS,
  );

  const handleSuccess = message => {
    enqueueSnackbar(message, { variant: 'success' });
  };

  const handleError = message => {
    enqueueSnackbar(message, { variant: 'error' });
  };

  const handleCancel = () => {
    setSelectedItemsIds([]);
    setHandleMultiSelect(false);
  };

  const conversationIds = selectedItemsIds.map(conversationId =>
    parseInt(conversationId, 10),
  );

  // "unarchive" if using bulk archive on the "Archived" tab (index 3)
  const archived = tabIndex === '3' ? false : true;

  const successMessage = `Conversation(s) ${
    archived ? 'Archived!' : 'Unarchived!'
  }`;

  const errorMessage = err =>
    `Unable to ${archived ? 'archive' : 'unarchive'} conversation(s). ${err}`;

  const handleArchive = () => {
    updateConversations({
      variables: {
        gatewayId,
        conversationIds,
        archived,
      },
      update: cache => {
        const ids = selectedItemsIds;
        const { page, ...otherVars } = conversationQueryVariables;
        for (let curPage = 1; curPage < page + 1; curPage++) {
          const curCache = cache.readQuery({
            query: CONVERSATIONS_QUERY,
            variables: { ...otherVars, page: curPage },
          });
          for (let i = 0; i < ids.length; i++) {
            let id = ids[i];
            if (
              curCache.sms.conversations.results.map(x => x.id).includes(id)
            ) {
              const newCache = Object.assign({}, curCache);
              newCache.sms.conversations.results =
                newCache.sms.conversations.results.filter(x => x.id !== id);
              newCache.sms.conversations.pagination.total--;
              cache.writeQuery({
                query: CONVERSATIONS_QUERY,
                variables: { ...otherVars, page: curPage },
                data: newCache,
              });
            }
          }
        }
      },
    }).then(
      () => {
        handleSuccess(successMessage);
        setSelectedItemsIds([]);
        setHandleMultiSelect(false);
        setSelectedChatId(0);
      },
      err => {
        handleError(errorMessage(err));
        setSelectedChatId(0);
      },
    );
  };

  return (
    <>
      <LoadingBackdrop open={updatingConversation}>
        Updating Conversation
      </LoadingBackdrop>
      <Box>
        <Box className={classes.countBox}>
          <Typography variant={'body1'} style={{ fontWeight: 'bold' }}>
            {selectedItemsIds.length} Selected
          </Typography>
          <Button
            startIcon={<CheckCircle />}
            className={`${classes.buttonText} ${
              selectedItemsIds.length > 0 ? classes.archiveButtonIconColour : ''
            }`}
            disabled={selectedItemsIds.length === 0}
            onClick={() => handleArchive()}
          >
            {`${archived ? 'Archive ' : 'Unarchive '} Selected`}
          </Button>
          <Button
            startIcon={<Cancel />}
            onClick={() => handleCancel()}
            className={`${classes.buttonText} ${classes.cancelButtonIconColour}`}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default MultiSelect;
