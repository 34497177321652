import React from 'react';

import { Grid } from '@mui/material';

const ErrorDisplay = ({ action, children, error }) => (
  <Grid container style={{ padding: '20px' }}>
    <Grid
      item
      container
      direction="column"
      xs={12}
      alignItems="center"
      spacing={2}
    >
      {action && <Grid item>Error while {action}</Grid>}
      {error && <Grid item>{error.message}</Grid>}
      {error?.networkError?.result?.errors?.map(({ message }) => (
        <Grid item>{message}</Grid>
      ))}
      {error?.graphQLErrors?.map(({ extensions }) => (
        <>
          {Object.entries(extensions.query || {}).map(([field, errors]) => (
            <Grid item>
              {field}: {errors.join(', ')}
            </Grid>
          ))}
          {extensions?.response?.body?.message && (
            <Grid item>{extensions.response.body.message}</Grid>
          )}
        </>
      ))}
      {children}
    </Grid>
  </Grid>
);

export default ErrorDisplay;
