import React from 'react';

/* Material UI */
import Grid from '@mui/material/Grid';

/* internal */
import { parseApolloError } from '../../../utils';

const Error = ({ error }) => (
  <Grid
    container
    justifyContent="center"
    alignContent="center"
    style={{ minHeight: '80%', position: 'fixed' }}
  >
    {error && (
      <Grid item xs={8}>
        {parseApolloError(error)}
      </Grid>
    )}
  </Grid>
);

export default Error;
