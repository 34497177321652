import React from 'react';

/* Material UI */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import StarIcon from '@mui/icons-material/Star';

const DecodeButton = ({ disabled, loading, isDecoded, ...rest }) => (
  <Button
    size="small"
    variant="contained"
    color="secondary"
    disabled={loading || disabled}
    {...rest}
  >
    {loading ? (
      <Box ml={0.5} mr={1} mt={0.5} mb={0}>
        <CircularProgress size={18} />
      </Box>
    ) : isDecoded ? null : (
      <StarIcon style={{ marginRight: '5px' }} />
    )}
    {isDecoded ? 'Re-Decode' : 'Decode'}
  </Button>
);

export default DecodeButton;
