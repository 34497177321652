import React from 'react';

import { Checkbox, FormControlLabel } from '@mui/material';
import { withStyles } from '@mui/styles';

const GreenCheckbox = withStyles({
  root: {
    color: '#66bb6a',
    '&$checked': {
      color: '#43a047',
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

const BlueCheckbox = withStyles({
  root: {
    color: '#24A0ED',
    '&$checked': {
      color: '#2196F3',
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

const OrangeCheckbox = withStyles({
  root: {
    color: '#FFA500',
    '&$checked': {
      color: '#FF8C00',
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

const RedCheckbox = withStyles({
  root: {
    color: '#FF0000',
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

const RAMInspCheckboxes = ({
  valueGroup,
  handleGroup,
  name,
  green = true,
  blue = true,
  orange = true,
  red = true,
  white = true,
  greenValue = 'OK',
  blueValue = 'N',
  orangeValue = 'R',
  redValue = 'X',
  whiteValue = '—',
  greenLabel = '',
  blueLabel = '',
  orangeLabel = '',
  redLabel = '',
  labelPlacement = 'start',
}) => {
  return (
    <>
      {green && (
        <FormControlLabel
          control={
            <GreenCheckbox
              checked={valueGroup[name] === greenValue}
              onChange={handleGroup}
              value={greenValue}
              name={name}
            />
          }
          label={greenLabel}
          labelPlacement={labelPlacement}
        />
      )}
      {blue && (
        <FormControlLabel
          control={
            <BlueCheckbox
              checked={valueGroup[name] === blueValue}
              value={blueValue}
              name={name}
              onChange={handleGroup}
            />
          }
          label={blueLabel}
          labelPlacement={labelPlacement}
        />
      )}
      {orange && (
        <FormControlLabel
          control={
            <OrangeCheckbox
              checked={valueGroup[name] === orangeValue}
              value={orangeValue}
              name={name}
              onChange={handleGroup}
            />
          }
          label={orangeLabel}
          labelPlacement={labelPlacement}
        />
      )}
      {red && (
        <FormControlLabel
          control={
            <RedCheckbox
              checked={valueGroup[name] === redValue}
              value={redValue}
              name={name}
              onChange={handleGroup}
            />
          }
          label={redLabel}
          labelPlacement={labelPlacement}
        />
      )}
      {white && (
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={valueGroup[name] === whiteValue}
              value={whiteValue}
              name={name}
              onChange={handleGroup}
            />
          }
          label="N/A"
          labelPlacement="start"
        />
      )}
    </>
  );
};
export default RAMInspCheckboxes;
