import React, { useState } from 'react';

/* external */
import { Link } from 'react-router-dom';
import { useQuery, useSubscription } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import gql from 'graphql-tag';

/* Material UI */
import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Fab,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import LinkIcon from '@mui/icons-material/Link';

import { INSPECTION_TYPES } from 'modules/pitches/const';
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import DealerPicker from 'components/MaterialUI/DealerPicker';
import DealerSettingsFormDialog from './DealerSettingsFormDialog';
import KioskFormDialog from './KioskFormDialog';

const KIOSK_FRAGMENT = gql`
  fragment KiosksKiosk on PitchKiosk {
    jwt
    dealer {
      name
    }
    kioskNumber
    currentPublishingId
    currentPublishing {
      id
      status
      pitch {
        id
        createdByUser {
          display_name
        }
        opportunityId
        vehicles {
          id
          make
          model
          year
        }
      }
    }
  }
`;

const KIOSKS_QUERY = gql`
  query kiosksQuery($dealerId: JSON!) {
    pitches {
      kiosks(
        filters: [
          { model: "Kiosk", field: "dealerId", op: "==", value: $dealerId }
        ]
        page: 1
        pageSize: 20
        sort: [{ model: "Kiosk", field: "KioskNumber", direction: "asc" }]
      ) {
        results {
          id
          ...KiosksKiosk
          ...KioskFormDialogKiosk
        }
        pagination {
          total
          nextPage
        }
      }
    }
  }
  ${KIOSK_FRAGMENT}
  ${KioskFormDialog.fragments.kiosk}
`;

const KIOSK_SUBSCRIPTION = gql`
  subscription onKioskUpdates($kioskIds: [Int!]!) {
    kioskUpdated(kioskIds: $kioskIds) {
      type
      kiosk {
        id
        ...KiosksKiosk
      }
    }
  }

  ${KIOSK_FRAGMENT}
`;

const PUBLISHING_SUBSCRIPTION = gql`
  subscription onPublishUpdates($publishingIds: [Int!]!) {
    publishingUpdated(publishingIds: $publishingIds) {
      type
      publishing {
        id
        status
        pitch {
          id
          createdByUser {
            display_name
          }
          vehicles {
            id
            make
            model
            year
          }
        }
      }
    }
  }
`;

const DEALER_SETTINGS_QUERY = gql`
  query dealerSettingsQuery($dealerId: Int!) {
    pitches {
      dealerSettings(dealerId: $dealerId) {
        id
        dealerId
        defaultURL
        docusignBrandId
        includeHistoryReport
        inspectionType
        smsGatewayId
        smsGatewaySecretKey
      }
    }
  }
`;

const GATEWAYS_QUERY = gql`
  query gateways {
    gateways {
      getGateways {
        gateways {
          id
          gateway_type
          name
          phone_number
        }
      }
    }
  }
`;

const Kiosks = () => {
  const { dealerId } = useDealerContext();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const [editKiosk, setEditKiosk] = useState(null);
  const [editDealerSettings, setEditDealerSettings] = useState(null);

  const clickLinkHandler =
    ({ kioskNumber, jwt }) =>
    () => {
      navigator.clipboard.writeText(jwt);
      enqueueSnackbar(`Copied JWT for Kiosk #${kioskNumber} to clipboard`);
    };

  const { data, refetch } = useQuery(KIOSKS_QUERY, {
    variables: { dealerId },
  });
  const kiosks = data?.pitches.kiosks.results ?? [];
  useSubscription(KIOSK_SUBSCRIPTION, {
    variables: { kioskIds: kiosks.map(x => x.id) },
    skip: kiosks.length === 0,
    shouldResubscribe: true,
  });

  const publishingIds = kiosks.map(k => k.currentPublishing?.id).filter(x => x);

  useSubscription(PUBLISHING_SUBSCRIPTION, {
    variables: {
      publishingIds,
    },
    shouldResubscribe: true, //?
    skip: publishingIds.length === 0,
  });

  const settingsQuery = useQuery(DEALER_SETTINGS_QUERY, {
    variables: { dealerId },
  });
  const settings = settingsQuery.data?.pitches.dealerSettings;
  const gatewaysQuery = useQuery(GATEWAYS_QUERY);
  const gateways = (
    gatewaysQuery.data?.gateways.getGateways.gateways ?? []
  ).sort((a, b) => a.name.localeCompare(b.name));
  const gatewayOptions = gateways.map(({ id, name, phone_number }) => ({
    id,
    name: `${name} (${phone_number})`,
  }));

  return (
    <Box height="100%">
      <KioskFormDialog
        kiosk={editKiosk}
        onClose={() => setEditKiosk(null)}
        onCreate={() => {
          setEditKiosk(null);
          refetch();
        }}
      />
      <DealerSettingsFormDialog
        dealerSettings={editDealerSettings}
        gatewayOptions={gatewayOptions}
        onClose={() => setEditDealerSettings(null)}
        onCreate={() => {
          setEditDealerSettings(null);
          settingsQuery.refetch();
        }}
      />
      <Box padding={1}>
        <DealerPicker />
      </Box>
      <Grid container style={{ padding: theme.spacing(2) }} spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h4">
            Settings{' '}
            <IconButton
              onClick={() => setEditDealerSettings(settings ?? {})}
              size="large"
            >
              <EditIcon />
            </IconButton>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={2}>
          Default URL:
        </Grid>
        <Grid item xs={12} sm={10}>
          {settings?.defaultURL ?? '<default>'}
        </Grid>
        <Grid item xs={12} sm={2}>
          Docusign Brand Id:
        </Grid>
        <Grid item xs={12} sm={10}>
          {settings?.docusignBrandId ?? '<default>'}
        </Grid>
        <Grid item xs={12} sm={2}>
          SMS Gateway:
        </Grid>
        <Grid item xs={12} sm={10}>
          {settings?.smsGatewayId
            ? gatewayOptions.find(x => x.id === settings.smsGatewayId)?.name
            : '<default>'}
        </Grid>

        <Grid item xs={12} sm={2}>
          Gateway Secret Key:
        </Grid>
        <Grid item xs={12} sm={10}>
          {settings?.smsGatewaySecretKey ?? '<default>'}
        </Grid>
        <Grid item xs={12} sm={2}>
          Publishing Inspection Type:
        </Grid>
        <Grid item xs={12} sm={10}>
          {INSPECTION_TYPES[settings?.inspectionType] ?? '<default>'}
        </Grid>
        <Grid item xs={12} sm={2}>
          Include VHR:
        </Grid>
        <Grid item xs={12} sm={10}>
          {settings?.includeHistoryReport ? 'Yes' : 'No'}
        </Grid>
      </Grid>
      <Grid container style={{ padding: theme.spacing(2) }}>
        <Grid item xs={12}>
          <Typography variant="h4">Kiosks</Typography>
        </Grid>
        <Grid item xs={2}>
          <Fab
            variant="extended"
            style={theme.actions.confirm}
            onClick={() => setEditKiosk({})}
          >
            <AddIcon /> Add Kiosk
          </Fab>
        </Grid>
      </Grid>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Kiosk #</TableCell>
            <TableCell>Username</TableCell>
            <TableCell>Password</TableCell>
            <TableCell>Pitch</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {kiosks.map(kiosk => (
            <TableRow key={kiosk.id}>
              <TableCell>
                <IconButton onClick={() => setEditKiosk(kiosk)} size="large">
                  <EditIcon />
                </IconButton>
                <IconButton onClick={clickLinkHandler(kiosk)} size="large">
                  <LinkIcon />
                </IconButton>
              </TableCell>
              <TableCell>
                {kiosk.dealer.name} #{kiosk.kioskNumber}
              </TableCell>
              <TableCell>{kiosk.username}</TableCell>
              <TableCell>{kiosk.password}</TableCell>
              <TableCell>
                {kiosk.currentPublishing?.pitch?.id ? (
                  <Link
                    to={`/opportunities/${kiosk.currentPublishing.pitch.opportunityId}/details`}
                  >
                    Pitch {kiosk.currentPublishing.pitch.id}
                  </Link>
                ) : (
                  'None'
                )}
              </TableCell>
              <TableCell>{kiosk.currentPublishing?.status ?? '-'}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default Kiosks;
