import React from 'react';
import moment from 'moment';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import gql from 'graphql-tag';
import { makeStyles } from '@mui/styles';

import DateDisplay from './DateDisplay';
import { getUpdatedDate } from '../utils';
import { EVENT_TITLES } from '../constant';
import { defaultTZ } from 'utils';

const useStyles = makeStyles(theme => ({
  details: {
    whiteSpace: 'pre-line',
    fontSize: '.75rem',
    overflowWrap: 'anywhere',
  },
  cardDefault: {
    padding: '4px 0px 0px 0px',
    alignItems: 'initial',
    whiteSpace: 'pre-line',
  },
  cardContent: {
    padding: '2px 0px 2px 0px',
  },
  card: {
    margin: '15px 1px 15px 1px',
    padding: '0px 10px 0px 10px',
  },
}));

const CommunicationCardMobile = ({ comm, customerName, key }) => {
  const classes = useStyles();
  const date = comm?.timestamp || comm.date_created;
  const sentTo = comm?.email || comm.channel_number;
  const event = comm?.event || comm.channel_type;
  const fromFlex = comm?.date_created ? true : false;

  // Reverse messages so most recent is first
  const messages = comm.messages.slice().reverse();
  const updated = getUpdatedDate(fromFlex, messages);

  const updatedDate = updated ?? date;
  const subheader = (
    <p>
      {`Sent to: ${sentTo}`}
      <br />
      {`Started Date: ${moment(date).tz(defaultTZ).format('MMM Do YY')}`}
    </p>
  );
  return (
    <>
      <CardHeader
        avatar={<DateDisplay date={updatedDate} />}
        title={EVENT_TITLES[event]}
        subheader={subheader}
      />
      <CardContent>
        {comm?.integration_name === 'Market SMS' && (
          <Typography
            variant="body2"
            component="p"
            style={{ fontSize: '.75rem' }}
          >
            {comm.subject}
          </Typography>
        )}
        {comm?.integration_name === 'Iterable' && (
          <Typography variant="body2" component="p" className={classes.details}>
            {`Campaign: ${comm.campaign_name}
              Integration: ${comm.integration_name}  `}
          </Typography>
        )}
        <Grid container spacing={1}>
          {/* Conversations are reversed to have most recent shown at top */}
          {messages &&
            messages.map(message => (
              <Grid key={message.index} xs={12}>
                <Grid item xs={5}>
                  <Typography variant="body2" style={{ fontSize: '.65rem' }}>
                    {/* If the message is from the flex channel display flex number, if not display customer name */}
                    <b>Author: </b>{' '}
                    {message?.from_
                      ? message.from_.startsWith('CH')
                        ? comm.channel_number
                        : customerName
                      : 'N/A'}
                  </Typography>
                </Grid>
                <Grid item xs={7} style={{ fontSize: '.65rem' }}>
                  <b>Date Sent: </b>{' '}
                  {moment(message.date_created)
                    .tz(defaultTZ)
                    .format('MMM Do YY, h:mm')}
                </Grid>
                <Grid item xs={12}>
                  {message.body}
                </Grid>
              </Grid>
            ))}
        </Grid>
      </CardContent>
    </>
  );
};

CommunicationCardMobile.fragments = {
  conversationHistory: gql`
    fragment CommunicationCardMobileConversationHistory on ConversationHistory {
      date_created
      channel_number
      channel_type
      channel_number
      messages {
        date_created
        index
        from_
        body
      }
    }
  `,
  communicationHistory: gql`
    fragment CommunicationCardMobileCommunicationHistory on CommunicationHistory {
      timestamp
      email
      event
      integration_name
      subject
      campaign_name
    }
  `,
};

export default CommunicationCardMobile;
