import React from 'react';

/* external */
import { camelCase, startCase } from 'lodash';

/* Material UI */
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';

const useStyles = makeStyles(theme => ({
  chipBox: {
    paddingRight: '5px',
    paddingBottom: '5px',
  },
}));

const titleize = str => startCase(camelCase(str));

const FilterChips = ({ filters, setFilters, others }) => {
  const classes = useStyles();

  const handleDeleteFilter = (_model, _field, _value) => () =>
    setFilters(prev =>
      prev.filter(
        ({ field, model, value }) =>
          _model !== model || _field !== field || _value !== value,
      ),
    );

  return (
    <Box display="flex" flexWrap="wrap">
      {filters.map(({ field, model, value }) => (
        <Box
          component="span"
          key={`${model}${field}${value}`}
          className={classes.chipBox}
        >
          <Chip
            label={
              value === 'True'
                ? titleize(field)
                : value === 'False'
                ? `Not ${titleize(field)}`
                : value
            }
            value={value}
            onDelete={handleDeleteFilter(model, field, value)}
          />
          {others?.map(({ label, onDelete }, index) => (
            <Box component="span" key={`${label}${index}`}>
              <Chip label={label} value={label} onDelete={onDelete} />
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  );
};

export default FilterChips;
