import React from 'react';
import Button from '@mui/material/Button';
import { cloudinaryConfig } from '../../../constants';
import gql from 'graphql-tag';

const CloudinaryPhotoUploader = ({ params, onUploaded }) => {
  const showWidget = () => {
    let widget = window.cloudinary.createUploadWidget(
      {
        cloudName: cloudinaryConfig.cloud_name,
        apiKey: params.api_key,
        uploadSignature: params.signature,
        uploadSignatureTimestamp: params.timestamp,
        folder: params.folder,
        uploadPreset: params.upload_preset,
        text: {
          en: {
            local: {
              browse: 'Open Camera',
            },
          },
        },
        sources: ['local', 'camera'],
      },
      (error, result) => {
        if (!error && result && result.event === 'success') {
          onUploaded(result.info.public_id);
        }
      },
    );
    widget.open();
  };
  return (
    <>
      <Button variant="contained" onClick={showWidget}>
        Take a Photo
      </Button>
    </>
  );
};

CloudinaryPhotoUploader.fragments = {
  photoUploadParams: gql`
    fragment CloudinaryPhotoUploaderUploadParams on CloudinaryUploadParams {
      api_key
      signature
      timestamp
      folder
      upload_preset
    }
  `,
};

export default CloudinaryPhotoUploader;
