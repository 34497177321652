import React from 'react';

/* Material UI */
import { Box } from '@mui/material';

/* internal */
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import { WHOLESALER_DEALER_IDS } from 'constants.js';
import BuyNowDashboard from './Dealer/BuyNowDashboard';
import DealerPicker from 'components/MaterialUI/DealerPicker';

const BuyNowList = () => {
  const { dealerId } = useDealerContext();

  return (
    <Box height="100%">
      <Box padding={1}>
        <DealerPicker
          formControlProps={{ style: { width: '100%' } }}
          fullWidth
        />
      </Box>
      {WHOLESALER_DEALER_IDS.includes(dealerId) ? (
        <Box padding={1} mt={3}>
          Wholesalers can view their buy-now vehicles in their used vehicle
          dashboard.
        </Box>
      ) : (
        <BuyNowDashboard />
      )}
    </Box>
  );
};

export default BuyNowList;
