import React from 'react';

/* Material UI */
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Fab from '@mui/material/Fab';
import FilterListIcon from '@mui/icons-material/FilterList';
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/material';

/* internal */
import AppraisalsCategoryTabs from './AppraisalsCategoryTabs';
import FilterChipsBox from 'components/MaterialUI/FilterChipsBox';
import SearchField from './SearchField';

const DesktopHeader = ({
  onClickFilter,
  categoryStats,
  categoryFiltersIndex,
  setCategoryFiltersIndex,
  facetFilters,
  setFacetFilters,
  dateFilters,
  setDateFilters,
  search,
  onChangeSearch,
  length,
  total,
  ...rest
}) => {
  const theme = useTheme();

  return (
    <Box {...rest}>
      <Box
        display="flex"
        style={{ gap: theme.spacing(1) }}
        marginTop={2}
        marginBottom={2}
        alignItems="center"
      >
        <Box>
          <Tooltip title="Filter list">
            <Fab color="primary" onClick={onClickFilter} size="medium">
              <FilterListIcon />
            </Fab>
          </Tooltip>
        </Box>
        <FilterChipsBox
          alignItems="center"
          filters={facetFilters}
          flexGrow="1"
          setFilters={setFacetFilters}
        >
          {(dateFilters.to || dateFilters.from) && (
            <Chip
              onDelete={() => setDateFilters({ to: null, from: null })}
              label={`${dateFilters.from?.format('YYYY-MM-DD') ?? 'All'} - ${
                dateFilters.to?.format('YYYY-MM-DD') ?? 'Now'
              }`}
            />
          )}
        </FilterChipsBox>
        <Box>
          <SearchField onChange={onChangeSearch} search={search} />
        </Box>
      </Box>
      <AppraisalsCategoryTabs
        categoryStats={categoryStats}
        categoryFiltersIndex={categoryFiltersIndex}
        setCategoryFiltersIndex={setCategoryFiltersIndex}
      />
      <Box display="flex" justifyContent="flex-end" width="100%">
        <Box whiteSpace="nowrap">
          Showing {length} of {total}
        </Box>
      </Box>
    </Box>
  );
};

export default DesktopHeader;
