import React from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import useMediaQuery from '@mui/material/useMediaQuery';
import ButtonBase from '@mui/material/ButtonBase';

const DashBoardItem = ({
  title,
  value,
  icon,
  color,
  onClick,
  subValue,
  ...rest
}) => {
  const mobile = useMediaQuery(theme => theme.breakpoints.up('sm'));
  return (
    <Grid
      item
      style={{ padding: '1rem', maxWidth: `${mobile ? '' : '50%'}` }}
      {...rest}
    >
      <ButtonBase focusRipple>
        <Card
          style={{
            width: `${mobile ? '200px' : '140px'}`,
            height: `${mobile ? '' : '125px'}`,
            padding: '1rem',
          }}
          onClick={onClick}
        >
          <CardContent>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
              }}
            >
              <i
                className={icon}
                style={{ fontSize: `${mobile ? '30px' : '16px'}` }}
              ></i>
              <div
                style={{
                  fontSize: `${mobile ? '24px' : '16px'}`,
                  color,
                }}
              >
                {value}
              </div>
            </div>
            <div
              style={{
                paddingTop: '0.2rem',
                textAlign: 'right',
                fontSize: `${mobile ? '10px' : '8px'}`,
              }}
            >
              {subValue || ' '}
            </div>
            <div
              style={{
                textAlign: 'right',
                fontSize: `${mobile ? '16px' : '12px'}`,
                fontWeight: 'bold',
                paddingTop: '1rem',
              }}
            >
              {title}
            </div>
          </CardContent>
        </Card>
      </ButtonBase>
    </Grid>
  );
};

export default DashBoardItem;
