import React, { createContext, useContext, useState } from 'react';
import gql from 'graphql-tag';
import { NetworkStatus } from 'apollo-boost';
import { groupBy } from 'lodash';
import { useQuery } from '@apollo/react-hooks';

import ErrorPage from 'components/MaterialUI/ErrorPage';
import Loading from 'components/MaterialUI/Loading';
import {
  GOAUTO_GROUP_ID,
  GOAUTO_ORG_ID,
  TEST_DEALERS_GROUP_ID,
  TEST_ORG_ID,
} from '../../../../constants';
import { usePersistedState } from '../../utils';
import { useUserContext } from 'components/MaterialUI/UserContext';

const GroupVehiclesQueryContext = createContext({
  error: null,
  facetResults: [],
  facets: [],
  fetchMore: () => {},
  filters: [],
  loading: null,
  networkStatus: null,
  order: null,
  orderBy: null,
  pagination: {},
  processor: () => {},
  refetch: () => {},
  searchKeywords: null,
  setFacets: () => {},
  setOrder: () => {},
  setOrderBy: () => {},
  setSearchKeywords: () => {},
  vehicles: [],
});

// Any fields that require processing from string values before updating
const PROCESSORS = {
  regular_price: x => parseFloat(x.replace(',', '')),
};

const DEFAULT_FACETS = [
  {
    model: 'StockStatus',
    field: 'name',
    value: 'In Stock',
    options: {},
  },
];

const GroupVehiclesQueryProvider = ({
  children,
  facetsQueries,
  defaultOrder = 'desc',
  defaultOrderBy = 'days_in_stock',
  fragments,
}) => {
  const [order, setOrder] = useState(defaultOrder);
  const [orderBy, setOrderBy] = useState(defaultOrderBy);
  const [searchKeywords, setSearchKeywords] = usePersistedState(
    'groupInventorySearchKeywords',
    '',
  );
  const [facets, setFacets] = usePersistedState(
    'groupInventoryFacets',
    DEFAULT_FACETS,
  );
  const { currentUser } = useUserContext();

  const QUERY = gql`
    query VehiclesQuery(
      $page: Int
      $page_size: Int
      $sort: [QuerySortElement]
      $filters: [QueryFilter]
      $facetsQueries: [QueryFacet]!
    ) {
      inventory {
        getGroupVehicles(
          page: $page
          page_size: $page_size
          filters: $filters
          sort: $sort
        ) {
          results {
            id
            ${Object.keys(fragments)
              .map(fragName => `...${fragName}`)
              .join('\n')}
          }
          pagination {
            next_page
            page
            page_size
            pages
            total
          }
        }
        getGroupVehicleFacets(filters: $filters, facets: $facetsQueries)
        {
          field
          model
          data {
            value
            count
          }
        }
      }
    }
    ${Object.values(fragments).reduce(
      (acc, fragment) =>
        gql`
          ${acc}
          ${fragment}
        `,
    )}
  `;

  let dealerGroupIdValue = 0;

  if (currentUser.organization_id === GOAUTO_ORG_ID)
    dealerGroupIdValue = GOAUTO_GROUP_ID;
  else if (currentUser.organization_id === TEST_ORG_ID)
    dealerGroupIdValue = TEST_DEALERS_GROUP_ID;

  const filters = [
    {
      model: 'DealerGroupMember',
      field: 'group_id',
      op: '==',
      value: dealerGroupIdValue,
    },
    {
      model: 'Vehicle',
      field: 'keywords',
      op: 'ilike',
      value: '%' + searchKeywords + '%' || '%%',
    },
    ...Object.entries(groupBy(facets, x => [x.model, x.field])).map(
      ([modelField, facet]) => {
        let filter = {};

        facet.forEach(element => {
          if (element?.options?.rangeInput === true) {
            const minFilter = {
              model: modelField.split(',')[0],
              field: modelField.split(',')[1],
              op: 'ge',
              value: element?.value?.min,
            };

            const maxFilter = {
              model: modelField.split(',')[0],
              field: modelField.split(',')[1],
              op: 'le',
              value: element?.value?.max,
            };

            if (
              element?.value?.min !== undefined &&
              element?.value?.max === undefined
            )
              filter = minFilter;
            else if (
              element?.value?.min === undefined &&
              element?.value?.max !== undefined
            )
              filter = maxFilter;
            else if (element?.value?.min === element?.value?.max) {
              filter = {
                model: modelField.split(',')[0],
                field: modelField.split(',')[1],
                op: 'eq',
                value: element?.value?.min,
              };
            } else filter = { and: [minFilter, maxFilter] };
          } else {
            filter = {
              model: modelField.split(',')[0],
              field: modelField.split(',')[1],
              op: 'in',
              value: facet.map(({ value }) =>
                value === 'True' ? true : value === 'False' ? false : value,
              ),
            };
          }
        });

        return filter;
      },
    ),
  ];

  let sort = [
    {
      model: 'Vehicle',
      field: orderBy,
      direction: order,
    },
  ];

  if (orderBy === 'displayName') {
    sort = [
      {
        model: 'Vehicle',
        field: 'year',
        direction: order,
      },
      {
        model: 'Make',
        field: 'name',
        direction: order,
      },
      {
        model: 'Model',
        field: 'name',
        direction: order,
      },
    ];
  } else if (orderBy === 'dealer_name') {
    sort = [
      {
        model: 'Dealer',
        field: 'name',
        direction: order,
      },
    ];
  }

  const {
    data,
    loading,
    error,
    fetchMore: fetchMoreVehicles,
    networkStatus,
    refetch,
  } = useQuery(QUERY, {
    variables: {
      page: 1,
      page_size: 20,
      sort,
      filters,
      facetsQueries,
    },
    notifyOnNetworkStatusChange: true,
  });

  const vehicles = data?.inventory?.getGroupVehicles?.results || [];
  const pagination = data?.inventory?.getGroupVehicles?.pagination || {};
  const facetResults = data?.inventory?.getGroupVehicleFacets || [];
  const { next_page } = pagination;

  const fetchMore = () =>
    next_page &&
    fetchMoreVehicles({
      variables: {
        page: next_page,
      },

      updateQuery: (prev, { fetchMoreResult: more }) => {
        if (!more.inventory.getGroupVehicles.results) return prev;
        return Object.assign({}, prev, {
          inventory: {
            __typename: prev.inventory.__typename,
            getGroupVehicles: {
              __typename: prev.inventory.getGroupVehicles.__typename,
              results: [
                ...prev.inventory.getGroupVehicles.results,
                ...more.inventory.getGroupVehicles.results,
              ],
              pagination: more.inventory.getGroupVehicles.pagination,
            },
            getGroupVehicleFacets: more.inventory.getGroupVehicleFacets,
          },
        });
      },
    });

  const processor = field => PROCESSORS[field] || (x => x);

  if (error) return <ErrorPage error={error} action="Loading Vehicles" />;
  if (loading && networkStatus === NetworkStatus.loading) return <Loading />;

  return (
    <GroupVehiclesQueryContext.Provider
      value={{
        error,
        facetResults,
        facets,
        fetchMore,
        filters,
        loading,
        networkStatus,
        order,
        orderBy,
        pagination,
        processor,
        refetch,
        searchKeywords,
        setFacets,
        setOrder,
        setOrderBy,
        setSearchKeywords,
        vehicles,
      }}
    >
      {children}
    </GroupVehiclesQueryContext.Provider>
  );
};

export const useGroupVehiclesQueryContext = () =>
  useContext(GroupVehiclesQueryContext);

export default GroupVehiclesQueryProvider;
