import gql from 'graphql-tag';

export const GET_TYPES_AND_MANUFACTURERS = gql`
  query getTypesAndManufacturers {
    npv {
      getNpvTypes {
        id
        name
      }
      getManufacturers {
        id
        name
      }
    }
  }
`;

export const GET_MAKES = gql`
  query getMakes($year: Int!, $manufacturerId: Int!) {
    npv {
      getMakes(year: $year, manufacturerId: $manufacturerId) {
        id
        name
        brochureUrl
      }
    }
  }
`;

export const GET_MODELS = gql`
  query getModels($makeId: Int!, $npvTypeId: Int!) {
    npv {
      getModels(makeId: $makeId, npvTypeId: $npvTypeId) {
        id
        name
      }
    }
  }
`;

export const CREATE_DETAILED_PRICING = gql`
  mutation createDetailedPricing($data: NpvDetailedPriceInput!) {
    npv {
      createDetailedPricing(data: $data) {
        id
      }
    }
  }
`;

export const UPDATE_DETAILED_PRICING = gql`
  mutation updateDetailedPricing($id: Int!, $data: NpvDetailedPriceInput!) {
    npv {
      updateDetailedPricing(id: $id, data: $data) {
        id
      }
    }
  }
`;

export const UPLOAD_PHOTOS = gql`
  mutation uploadNpvPhotos($id: Int!, $data: [NpvPhotoInput!]!) {
    npv {
      createVehiclePhotos(id: $id, data: $data) {
        id
        photos {
          cloudinaryPublicId
          id
          md5
          isFloorPlan
          vehicleId
        }
      }
    }
  }
`;

export const UPLOAD_VIDEOS = gql`
  mutation uploadNpvVideos($id: Int!, $data: [NpvVideoInput!]!) {
    npv {
      createVehicleVideos(id: $id, data: $data) {
        id
        videos {
          id
          cloudinaryPublicId
          md5
          isWalkAround
          vehicleId
        }
      }
    }
  }
`;

export const GET_STOCK_STATUSES = gql`
  query getStockStatuses {
    npv {
      getStockStatuses {
        id
        name
      }
    }
  }
`;
