import React from 'react';
import { Redirect } from 'react-router-dom';

import { useUserContext } from 'components/MaterialUI/UserContext';

const withRequiresUserRole = WrappedComponent => {
  return props => {
    const { currentUser: {role} } = useUserContext();

    return role ? (
      <WrappedComponent {...props} />
    ) : (
      <Redirect
        to={{
          pathname: '/403',
          state: {
            errorMessage:
              'A role is required to use this application. Please contact Market Support.',
          },
        }}
      />
    );

  }
}

export default withRequiresUserRole;
