import EditDealNumberModal from './EditDealNumberModal';
import LostOpportunityModal from './LostOpportunityModal';
import OpportunitySubStatusModal from './OpportunitySubStatusModal';
import PendingOpportunityModal from './PendingOpportunityModal';

/* internal */
import ReviveOpportunityModal from './ReviveOpportunityModal';
import UpdateOpportunityDealerModal from './UpdateOpportunityDealerModal';
import UpdateOpportunityStockTypeModal from './UpdateOpportunityStockTypeModal';
import CustomerIdentityCheckerModal from './CustomerIdentityCheckerModal';
import CustomerIdentityEditorModal from './CustomerIdentityEditorModal';
import VehicleHeaderData from './VehicleHeaderData';
import { useMutation } from '@apollo/react-hooks';

/* Material UI */
import {
  Box,
  Button,
  Chip,
  Grid,
  Icon,
  IconButton,
  Menu,
  MenuItem,
  Step,
  StepLabel,
  Stepper,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { makeStyles, styled, withStyles } from '@mui/styles';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import MoreVertIcon from '@mui/icons-material/MoreVert';

/* external */
import clsx from 'clsx';
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import DealerPicker from 'components/MaterialUI/DealerPicker';
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';
import {
  AppointmentTypes,
  GSP_DEALER_ID,
  MGM_FORD_LINCOLN_DEALER_ID,
  KNOW_YOUR_CUSTOMER_PDF_TYPES,
  PCE_DEALER_ID,
  Status,
  STATUS_ICON,
  StatusDisplay,
} from 'constants.js';
import gql from 'graphql-tag';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';

const GENERATE_GUEST_SHEET_PDF = gql`
  mutation generateGuestSheetPDF($id: ID!) {
    generateGuestSheetPDF(_id: $id) {
      key
    }
  }
`;

const GENERATE_LOANER_SHEET_PDF = gql`
  mutation generateLoanerSheetPDF($id: ID!) {
    generateLoanerSheetPDF(_id: $id) {
      key
    }
  }
`;

const GENERATE_LOANER48_SHEET_PDF = gql`
  mutation generateLoaner48SheetPDF($id: ID!) {
    generateLoaner48SheetPDF(_id: $id) {
      key
    }
  }
`;

const GENERATE_KNOW_YOUR_CUSTOMER_SHEET_PDF = gql`
  mutation generateKnowYourCustomerSheetPDF($id: ID!, $pdf_type: Int!) {
    generateKnowYourCustomerSheetPDF(_id: $id, pdf_type: $pdf_type) {
      key
    }
  }
`;

const GENERATE_PRINTABLE_PDF = gql`
  mutation generatePrintablePdf($id: ID!) {
    generatePrintablePDF(_id: $id) {
      key
    }
  }
`;

const GENERATE_BOOKING_SHEET_PDF = gql`
  mutation generateBookingSheetPdf($id: ID!) {
    generateBookingSheetPdf(_id: $id) {
      key
    }
  }
`;

const useStyles = makeStyles(theme => ({
  mainTitle: {
    flexGrow: 1,
    fontWeight: 'bold',
    padding: theme.spacing(2),
  },
  headerChips: {
    margin: '0px 10px 0px 0px',
  },
  stepper: {
    padding: '0px',
  },
  subStatus: {
    verticalAlign: 'bottom',
    backgroundColor: 'white',
    color: 'grey',
    fontWeight: 600,
  },
}));

// Based on https://material-ui.com/components/steppers/#linear-alternative-label
const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#E9D8CD',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: '#ff5a00',
  },
  circle: {
    width: 5,
    height: 3,
    backgroundColor: 'currentColor',
  },
  circleCompleted: {
    width: 2,
    height: 8,
    borderRadius: '50%',
    backgroundColor: '#ff5a00',
  },
  completed: {
    color: '#ff5a00',
    zIndex: 1,
    fontSize: 18,
  },
});

const QontoConnector = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-100% + 2px)',
    right: 'calc(100% + 0px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#ff5a00',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#ff5a00',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: '#E9D8CD',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const MobileQontoConnector = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 1px)',
    right: 'calc(50% + 1px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#ff5a00',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#ff5a00',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: '#E9D8CD',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {completed ? (
        <div className={classes.circleCompleted} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

const Header = ({ opportunity, refetch }) => {
  const classes = useStyles();
  const { dealerId } = useDealerContext();
  const { enqueueSnackbar } = useSnackbar();
  const desktop = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const stepIndex =
    opportunity.status !== Status.LOST
      ? Status.TIMELINE.findIndex(elm => elm === opportunity.status)
      : Status.LOST_TIMELINE.findIndex(elm => elm === opportunity.status);
  const activeStep = stepIndex !== -1 ? stepIndex : 0;
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [lostModal, setLostModal] = useState(false);
  const [dealerModal, setDealerModal] = useState(false);
  const [showPendingModal, setShowPendingModal] = useState(false);
  const [editDealModal, setEditDealModal] = useState(false);
  const [subStatusModal, setSubStatusModal] = useState(false);
  const [stockTypeModal, setStockTypeModal] = useState(false);
  const [customerIdentityCheckerModal, setCustomerIdentityCheckerModal] =
    useState(false);
  const [customerIdentityEditor, setCustomerIdentityEditor] = useState(false);

  const { dms_deal, permissions } = opportunity ?? {};
  const { can_edit_deal_number, can_set_pending, can_update_dealer_id } =
    permissions ?? {};
  const { deal_number } = dms_deal ?? {};
  const statusChanges = opportunity.last_status_change;
  const appointments = opportunity?.appointments || [];
  const testDrive = appointments.filter(
    app => app.type_id === AppointmentTypes.TEST_DRIVE,
  );
  const delivery = appointments.filter(
    app => app.type_id === AppointmentTypes.DELIVERY,
  );
  const docusignPdfUrl = opportunity?.pitches?.find(
    ({ id }) => id === opportunity?.primary_new_pitch_id,
  )?.docusignPdfUrl;

  const displayVehicleData =
    opportunity.status_display === 'Posted' && opportunity.pitches.length === 0;

  const [createGuestSheetPDF, createGuestSheetPDFMutation] = useMutation(
    GENERATE_GUEST_SHEET_PDF,
  );
  const [createLoanerSheetPDF, createLoanerSheetPDFMutation] = useMutation(
    GENERATE_LOANER_SHEET_PDF,
  );
  const [createLoaner48SheetPDF, createLoaner48SheetPDFMutation] = useMutation(
    GENERATE_LOANER48_SHEET_PDF,
  );
  const [createKnowYourCustomerSheetPDF, createKnowYourCustomerPDFMutation] =
    useMutation(GENERATE_KNOW_YOUR_CUSTOMER_SHEET_PDF);

  const [generatePrintablePDF, generatePrintablePDFMutation] = useMutation(
    GENERATE_PRINTABLE_PDF,
  );

  const [createBookingSheetPDF, createBookingSheetPDFMutation] = useMutation(
    GENERATE_BOOKING_SHEET_PDF,
  );

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleError = error =>
    enqueueSnackbar(`Error generating PDF: ${JSON.stringify(error)}`, {
      variant: 'error',
    });

  const generateGuestSheetPDF = id => {
    createGuestSheetPDF({ variables: { id } }).then(
      ({ data }) => window.open(data.generateGuestSheetPDF.key, '_blank'),
      handleError,
    );
  };

  const generateLoanerSheetPDF = id => {
    createLoanerSheetPDF({ variables: { id } }).then(
      ({ data }) => window.open(data.generateLoanerSheetPDF.key, '_blank'),
      handleError,
    );
  };

  const generateLoaner48SheetPDF = id => {
    createLoaner48SheetPDF({ variables: { id } }).then(
      ({ data }) => window.open(data.generateLoaner48SheetPDF.key, '_blank'),
      handleError,
    );
  };

  const generateKnowYourCustomerSheetPDF = (id, pdf_type) => {
    createKnowYourCustomerSheetPDF({ variables: { id, pdf_type } }).then(
      ({ data }) =>
        window.open(data.generateKnowYourCustomerSheetPDF.key, '_blank'),
      handleError,
    );
  };

  const generateFordDeliveryAllowanceWorksheet = id => {
    generatePrintablePDF({ variables: { id } }).then(
      ({ data }) => window.open(data.generatePrintablePDF.key, '_blank'),
      handleError,
    );
  };

  const generateBookingSheetPDF = id => {
    createBookingSheetPDF({ variables: { id } }).then(
      ({ data }) => window.open(data.generateBookingSheetPdf.key, '_blank'),
      handleError,
    );
  };

  const OPEN_AND_LOST_OPPS = [...Status.OPEN_OPPS, Status.LOST];
  const hasPitches = opportunity?.pitches?.length > 0;

  // identity checker
  const email = opportunity?.customer?.identities?.some(
    identity => identity.identity_type === 'email',
  );
  const sms = opportunity?.customer?.identities?.some(
    identity => identity.identity_type === 'sms',
  );

  return (
    <>
      <ReviveOpportunityModal
        opportunity={opportunity}
        refetch={refetch}
        onClose={() => setOpenModal(false)}
        isOpen={openModal}
      />
      <LostOpportunityModal
        opportunityId={opportunity._id}
        refetch={refetch}
        onClose={() => setLostModal(false)}
        isOpen={lostModal}
      />
      <PendingOpportunityModal
        opportunityId={opportunity._id}
        onClose={() => {
          setAnchorEl(null);
          setShowPendingModal(false);
        }}
        isOpen={showPendingModal}
      />
      <EditDealNumberModal
        opportunity={opportunity}
        onClose={() => setEditDealModal(false)}
        isOpen={editDealModal}
      />
      <UpdateOpportunityDealerModal
        opportunity={opportunity}
        onClose={() => setDealerModal(false)}
        isOpen={dealerModal}
        refetch={refetch}
      />
      <OpportunitySubStatusModal
        opportunity={opportunity}
        onClose={() => setSubStatusModal(false)}
        isOpen={subStatusModal}
      />
      <UpdateOpportunityStockTypeModal
        opportunity={opportunity}
        onClose={() => setStockTypeModal(false)}
        isOpen={stockTypeModal}
        refetch={refetch}
      />
      <CustomerIdentityCheckerModal
        opportunity={opportunity}
        refetch={refetch}
        onClose={() => {
          setCustomerIdentityCheckerModal(false);
        }}
        isOpen={customerIdentityCheckerModal}
        setShowPendingModal={setShowPendingModal}
      />

      <CustomerIdentityEditorModal
        opportunity={opportunity}
        refetch={refetch}
        onClose={() => setCustomerIdentityEditor(false)}
        isOpen={customerIdentityEditor}
        setShowPendingModal={setShowPendingModal}
      />

      <Box style={{ marginBottom: '15px' }}>
        <LoadingBackdrop
          open={[
            createGuestSheetPDFMutation,
            createLoanerSheetPDFMutation,
            createLoaner48SheetPDFMutation,
            createKnowYourCustomerPDFMutation,
            generatePrintablePDFMutation,
            createBookingSheetPDFMutation,
          ].some(x => x.loading)}
        >
          {createGuestSheetPDFMutation.loading &&
            'Generating Guest Sheet PDF...'}
          {createLoanerSheetPDFMutation.loading &&
            'Generating Loaner Sheet PDF...'}
          {createLoanerSheetPDFMutation.loading &&
            'Generating 48-Hour Loaner Sheet PDF...'}
          {createKnowYourCustomerPDFMutation.loading &&
            'Generating Know Your Customer Sheet PDF...'}
          {generatePrintablePDFMutation.loading && 'Generating PDF...'}
          {createBookingSheetPDFMutation.loading &&
            'Generating Booking Sheet PDF...'}
        </LoadingBackdrop>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
          style={{ width: '100%' }}
        >
          <Grid item xs={12} style={{ padding: '16px' }}>
            <DealerPicker disabled={true} />
          </Grid>
          {desktop && (
            <>
              <Grid item xs={5}>
                <Typography variant="h5" className={classes.mainTitle}>
                  Opportunity for{' '}
                  {opportunity?.customer_name ||
                    opportunity?.customer?.company_name ||
                    'Customer'}
                  {opportunity?.dms_deal?.deal_number
                    ? ` (# ${opportunity.dms_deal.deal_number})`
                    : ''}
                </Typography>
                {displayVehicleData && (
                  <VehicleHeaderData opportunity={opportunity} />
                )}
              </Grid>
              <Grid item xs={7}>
                <Chip
                  label={opportunity.deal_type}
                  className={classes.headerChips}
                  style={{ verticalAlign: 'bottom', fontSize: 'medium' }}
                />
                <Button
                  size="large"
                  color="primary"
                  disabled={true}
                  className={classes.headerChips}
                  style={{
                    backgroundColor: STATUS_ICON[opportunity.status].color,
                    color: 'white',
                  }}
                  startIcon={
                    <Icon className={STATUS_ICON[opportunity.status].icon} />
                  }
                >
                  {opportunity.status_display === 'Lost' &&
                  opportunity.lost_reason
                    ? `${opportunity.status_display} — ${opportunity.lost_reason}`
                    : opportunity.status_display}
                </Button>
                {opportunity?.sub_status && (
                  <Button
                    size="small"
                    variant="outlined"
                    disabled={true}
                    color="primary"
                    className={classes.subStatus}
                  >
                    {opportunity?.sub_status || 'sub status'}
                  </Button>
                )}

                <IconButton size="small" onClick={handleClick}>
                  <MoreVertIcon />
                </IconButton>

                <Menu
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  {OPEN_AND_LOST_OPPS.includes(opportunity.status) && (
                    <>
                      {/* These menu items should not render for posted and delivered opps */}
                      <MenuItem
                        onClick={() => setOpenModal(true)}
                        disabled={opportunity.status !== Status.LOST}
                      >
                        Revive Opportunity
                      </MenuItem>
                      <MenuItem
                        onClick={() => setLostModal(true)}
                        disabled={
                          ![Status.FRESH, Status.DESK].includes(
                            opportunity.status,
                          )
                        }
                      >
                        Lose Opportunity
                      </MenuItem>
                      {can_set_pending && (
                        <MenuItem
                          disabled={
                            !(
                              [Status.DESK, Status.FI].includes(
                                opportunity.status,
                              ) && deal_number
                            )
                          }
                          onClick={() => {
                            if (!sms || !email) {
                              setCustomerIdentityCheckerModal(true);
                            }
                            setShowPendingModal(true);
                          }}
                        >
                          Send To Deal Log
                        </MenuItem>
                      )}
                      {can_edit_deal_number && deal_number && (
                        <MenuItem onClick={() => setEditDealModal(true)}>
                          Edit Deal Number
                        </MenuItem>
                      )}
                      {can_update_dealer_id && (
                        <MenuItem
                          onClick={() => setDealerModal(true)}
                          disabled={hasPitches}
                        >
                          Change Dealer
                        </MenuItem>
                      )}
                      <MenuItem onClick={() => setSubStatusModal(true)}>
                        Edit Sub Status
                      </MenuItem>
                      {Status.OPEN_OPPS.includes(opportunity.status) && (
                        <MenuItem onClick={() => setStockTypeModal(true)}>
                          Edit Stock Type
                        </MenuItem>
                      )}
                    </>
                  )}
                  {/* These menu items could render for posted and delivered opps */}
                  {docusignPdfUrl && (
                    <MenuItem
                      onClick={e => {
                        e.preventDefault();
                        window.open(docusignPdfUrl, '_blank');
                      }}
                    >
                      Print Signed Docs
                    </MenuItem>
                  )}
                  <MenuItem
                    onClick={() => generateGuestSheetPDF(opportunity._id)}
                  >
                    Guest Sheet
                  </MenuItem>
                  {dealerId === GSP_DEALER_ID && (
                    <MenuItem
                      onClick={() => generateLoanerSheetPDF(opportunity._id)}
                    >
                      Loaner Sheet
                    </MenuItem>
                  )}
                  {dealerId === GSP_DEALER_ID && (
                    <MenuItem
                      onClick={() => generateLoaner48SheetPDF(opportunity._id)}
                    >
                      48 Hour Loaner Sheet
                    </MenuItem>
                  )}
                  {dealerId === MGM_FORD_LINCOLN_DEALER_ID && (
                    <>
                      <MenuItem
                        onClick={() =>
                          generateKnowYourCustomerSheetPDF(
                            opportunity._id,
                            KNOW_YOUR_CUSTOMER_PDF_TYPES.ford_individual,
                          )
                        }
                      >
                        KYC Individual Checklist - Ford
                      </MenuItem>
                      <MenuItem
                        onClick={() =>
                          generateKnowYourCustomerSheetPDF(
                            opportunity._id,
                            KNOW_YOUR_CUSTOMER_PDF_TYPES.ford_business,
                          )
                        }
                      >
                        KYC Business Checklist - Ford
                      </MenuItem>
                      <MenuItem
                        onClick={() =>
                          generateKnowYourCustomerSheetPDF(
                            opportunity._id,
                            KNOW_YOUR_CUSTOMER_PDF_TYPES.lincoln_individual,
                          )
                        }
                      >
                        KYC Individual Checklist - Lincoln
                      </MenuItem>
                      <MenuItem
                        onClick={() =>
                          generateKnowYourCustomerSheetPDF(
                            opportunity._id,
                            KNOW_YOUR_CUSTOMER_PDF_TYPES.lincoln_business,
                          )
                        }
                      >
                        KYC Business Checklist - Lincoln
                      </MenuItem>
                      {/* This is only used for MGM FL for now */}
                      <MenuItem
                        onClick={() =>
                          generateFordDeliveryAllowanceWorksheet(
                            opportunity._id,
                          )
                        }
                      >
                        Ford Delivery Allowance Worksheet
                      </MenuItem>
                    </>
                  )}
                  {dealerId === PCE_DEALER_ID && (
                    <MenuItem
                      onClick={() => generateBookingSheetPDF(opportunity._id)}
                    >
                      Booking Sheet
                    </MenuItem>
                  )}
                </Menu>
              </Grid>
              <Grid item xs={7} style={{ paddingLeft: '15px' }}>
                <Stepper
                  activeStep={activeStep}
                  alternativeLabel
                  connector={<QontoConnector />}
                  className={classes.stepper}
                >
                  {opportunity.status === Status.LOST
                    ? Status.LOST_TIMELINE.map(status => (
                        <Step
                          key={status}
                          style={{ paddingLeft: '0px', paddingRight: '0px' }}
                        >
                          <StepLabel
                            StepIconComponent={QontoStepIcon}
                            optional={
                              statusChanges.hasOwnProperty(status) ? (
                                <Typography variant="caption">
                                  {moment(statusChanges[status]).format('L')}
                                </Typography>
                              ) : (
                                ''
                              )
                            }
                            style={{ textAlign: 'left', alignItems: 'start' }}
                          >
                            <div style={{ textAlign: 'left' }}>
                              {StatusDisplay[status]}
                            </div>
                          </StepLabel>
                        </Step>
                      ))
                    : Status.TIMELINE.map(status => (
                        <Step
                          key={status}
                          style={{ paddingLeft: '0px', paddingRight: '0px' }}
                        >
                          <StepLabel
                            StepIconComponent={QontoStepIcon}
                            optional={
                              statusChanges.hasOwnProperty(status) ? (
                                <Typography variant="caption">
                                  {moment(statusChanges[status]).format('L')}
                                </Typography>
                              ) : (
                                ''
                              )
                            }
                            style={{ textAlign: 'left', alignItems: 'start' }}
                          >
                            <div style={{ textAlign: 'left' }}>
                              {StatusDisplay[status]}
                            </div>
                          </StepLabel>
                        </Step>
                      ))}
                </Stepper>
              </Grid>
              <Grid item xs={5} style={{ paddingBottom: '80px' }}>
                <Typography variant="body2">
                  <b>Test Drive:</b>{' '}
                  {testDrive.length !== 0
                    ? moment(testDrive[0].start_date).format('ll')
                    : 'TBD'}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <b>Delivery:</b>{' '}
                  {delivery.length !== 0
                    ? moment(delivery[0].start_date).format('ll')
                    : 'TBD'}
                </Typography>
              </Grid>
            </>
          )}
          {!desktop && (
            <>
              <Grid item xs={12} style={{ paddingLeft: '16px' }}>
                <Button
                  size="medium"
                  color="primary"
                  disabled={true}
                  className={classes.headerChips}
                  style={{
                    backgroundColor: STATUS_ICON[opportunity.status].color,
                    color: 'white',
                  }}
                  startIcon={
                    <Icon className={STATUS_ICON[opportunity.status].icon} />
                  }
                >
                  {opportunity.status_display}
                </Button>
                <Chip
                  label={opportunity.deal_type}
                  className={classes.headerChips}
                  style={{ verticalAlign: 'bottom' }}
                />
                {opportunity?.sub_status && (
                  <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    style={{ verticalAlign: 'bottom' }}
                  >
                    {opportunity?.sub_status || 'sub status'}
                  </Button>
                )}
              </Grid>
              <Grid item xs={11}>
                <Typography
                  variant="h6"
                  className={classes.mainTitle}
                  style={{ paddingBottom: '0px' }}
                >
                  Opportunity for
                </Typography>
                <Typography
                  variant="h6"
                  className={classes.mainTitle}
                  style={{ paddingTop: '5px' }}
                >
                  {opportunity?.customer_name || 'Customer'}
                  {opportunity?.dms_deal?.deal_number
                    ? ` (# ${opportunity.dms_deal.deal_number})`
                    : ''}
                </Typography>
                {displayVehicleData && (
                  <VehicleHeaderData opportunity={opportunity} />
                )}
              </Grid>
              <Grid item xs={1}>
                <IconButton size="small" onClick={handleClick}>
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  {OPEN_AND_LOST_OPPS.includes(opportunity.status) && (
                    <>
                      {/* These menu items should not render for posted and delivered opps */}
                      <MenuItem
                        onClick={() => setOpenModal(true)}
                        disabled={opportunity.status !== Status.LOST}
                      >
                        Revive Opportunity
                      </MenuItem>
                      <MenuItem
                        onClick={() => setLostModal(true)}
                        disabled={
                          ![Status.FRESH, Status.DESK].includes(
                            opportunity.status,
                          )
                        }
                      >
                        Lose Opportunity
                      </MenuItem>
                      {can_set_pending && (
                        <MenuItem
                          disabled={
                            !(opportunity.status === Status.DESK && deal_number)
                          }
                          onClick={() => setShowPendingModal(true)}
                        >
                          Send To Deal Log
                        </MenuItem>
                      )}
                      {can_edit_deal_number && deal_number && (
                        <MenuItem onClick={() => setEditDealModal(true)}>
                          Edit Deal Number
                        </MenuItem>
                      )}
                      {can_update_dealer_id && (
                        <MenuItem
                          onClick={() => setDealerModal(true)}
                          disabled={hasPitches}
                        >
                          Change Dealer
                        </MenuItem>
                      )}
                      <MenuItem onClick={() => setSubStatusModal(true)}>
                        Edit Sub Status
                      </MenuItem>
                      {Status.OPEN_OPPS.includes(opportunity.status) && (
                        <MenuItem onClick={() => setStockTypeModal(true)}>
                          Edit Stock Type
                        </MenuItem>
                      )}
                    </>
                  )}
                  {/* 
                  These menu items could be rendered for posted and delivered opps
                  */}
                  {docusignPdfUrl && (
                    <MenuItem
                      onClick={e => {
                        e.preventDefault();
                        window.open(docusignPdfUrl, '_blank');
                      }}
                    >
                      Print Signed Docs
                    </MenuItem>
                  )}
                  <MenuItem
                    onClick={() => generateGuestSheetPDF(opportunity._id)}
                  >
                    Guest Sheet
                  </MenuItem>
                  {dealerId === GSP_DEALER_ID && (
                    <MenuItem
                      onClick={() => generateLoanerSheetPDF(opportunity._id)}
                    >
                      Loaner Sheet
                    </MenuItem>
                  )}
                  {dealerId === GSP_DEALER_ID && (
                    <MenuItem
                      onClick={() => generateLoaner48SheetPDF(opportunity._id)}
                    >
                      48 Hour Loaner Sheet
                    </MenuItem>
                  )}
                </Menu>
              </Grid>
              <Grid item xs={12}>
                <Stepper
                  activeStep={activeStep}
                  alternativeLabel
                  connector={<MobileQontoConnector />}
                  className={classes.stepper}
                >
                  {opportunity.status === Status.LOST
                    ? Status.LOST_TIMELINE.map(status => (
                        <Step key={status} style={{ padding: '0px' }}>
                          <StepLabel
                            StepIconComponent={QontoStepIcon}
                            optional={
                              statusChanges.hasOwnProperty(status) &&
                              status === opportunity.status ? (
                                <Typography
                                  variant="caption"
                                  style={{ fontSize: '0.6rem' }}
                                >
                                  {moment(statusChanges[status]).format('L')}
                                </Typography>
                              ) : (
                                ''
                              )
                            }
                            style={{ textAlign: 'center', fontSize: '0.5rem' }}
                          >
                            <div style={{ fontSize: '0.6rem' }}>
                              {StatusDisplay[status]}
                            </div>
                          </StepLabel>
                        </Step>
                      ))
                    : Status.TIMELINE.map(status => (
                        <Step key={status} style={{ padding: '0px' }}>
                          <StepLabel
                            StepIconComponent={QontoStepIcon}
                            optional={
                              statusChanges.hasOwnProperty(status) &&
                              status === opportunity.status ? (
                                <Typography
                                  variant="caption"
                                  style={{ fontSize: '0.6rem' }}
                                >
                                  {moment(statusChanges[status]).format('L')}
                                </Typography>
                              ) : (
                                ''
                              )
                            }
                            style={{ textAlign: 'center', fontSize: '0.5rem' }}
                          >
                            <div style={{ fontSize: '0.6rem' }}>
                              {StatusDisplay[status]}
                            </div>
                          </StepLabel>
                        </Step>
                      ))}
                </Stepper>
              </Grid>
              <Grid item xs={6} style={{ textAlign: 'center' }}>
                <Typography variant="body2">
                  <b>Test Drive:</b>{' '}
                  {testDrive.length !== 0
                    ? moment(testDrive[0].start_date).format('ll')
                    : 'TBD'}
                </Typography>
              </Grid>
              <Grid item xs={6} style={{ textAlign: 'center' }}>
                <Typography variant="body2">
                  <b>Delivery:</b>{' '}
                  {delivery.length !== 0
                    ? moment(delivery[0].start_date).format('ll')
                    : 'TBD'}
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </>
  );
};

Header.fragments = {
  opportunity: gql`
    fragment HeaderOpportunity on Opportunity {
      _id
      appointments {
        _id
        type_id
        start_date
      }
      dms_deal {
        deal_number
      }
      last_status_change
      permissions {
        can_set_pending
        can_update_dealer_id
        can_edit_deal_number
      }
      pitches {
        id
        docusignPdfUrl
      }
      primary_new_pitch_id
      status
      status_display
    }
  `,
};
export default Header;
