import React from 'react';

import { SelectControl } from 'components/MaterialUI';

export default function LazySelect({ label, required, rules = {}, ...rest }) {
  if (required) rules = { required: `${label} is required!`, ...rules };

  return (
    <SelectControl
      label={label}
      fullWidth
      noNull
      optionValueKey="id"
      rules={rules}
      variant="outlined"
      {...rest}
    />
  );
}
