import React from 'react';

import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';

import MergedCustomerCard from './MergedCustomerCard';

const StepThree = ({ selectedFields, defaultCustomer }) => {
  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: '1rem',
      }}
    >
      <Box>
        Verify that the information below is correct. Pressing merge will
        combine the data from the customers in the previous step into one.
      </Box>
      <Box>
        <Alert severity="warning">
          Make sure that the information below is correct. This CANNOT be
          undone!
        </Alert>
      </Box>
      <Box
        style={{
          display: 'flex',
          marginLeft: 'auto',
          marginRight: 'auto',
          width: '75%',
        }}
      >
        <MergedCustomerCard
          selectedFields={selectedFields}
          defaultCustomer={defaultCustomer}
        />
      </Box>
    </Box>
  );
};

export default StepThree;
