import React, { useMemo } from 'react';
import { bellCurve, kFormatter } from '../../utils';
import { Line } from 'react-chartjs-2';
import gql from 'graphql-tag';

const POINTS_PER_STDDEV = 15;
const STDDEV_RANGE = 3;

const COLORS = [
  'rgba(12, 247, 91, 0.2)',
  'rgba(247, 228, 12, 0.2)',
  'rgba(247, 12, 12, 0.2)',
];

const options = {
  scales: {
    xAxes: [
      {
        type: 'linear',
        scaleLabel: {
          display: true,
          labelString: 'List Price',
          fontSize: 16,
          fontStyle: 'bold',
          fontColor: 'black',
        },
        position: 'bottom',
        ticks: {
          callback: value => kFormatter(value),
          beginAtZero: false,
        },
      },
    ],
    yAxes: [
      {
        display: false,
      },
    ],
  },
  legend: {
    labels: {
      filter: () => false,
    },
  },
  tooltips: {
    enabled: false,
  },
  animation: {
    duration: 0,
  },
};

const BellCurve = ({ listingsData, vehicle, listPrice }) => {
  const { func, stddev, mean } = useMemo(
    () => bellCurve(listingsData.map(l => l.price)),
    [listingsData],
  );
  const curveDatasets = useMemo(
    () =>
      Array(STDDEV_RANGE * 2)
        .fill(0)
        .map((_, i) => {
          const offset = i - STDDEV_RANGE;
          let minRange = mean + offset * stddev;
          if (offset === -3) minRange = Math.min(minRange, listPrice);
          let maxRange = mean + (offset + 1) * stddev;
          if (offset === 2) maxRange = Math.max(maxRange, listPrice);

          return {
            label: `${offset}`,
            borderColor: '#FF5400',
            pointHoverColor: '#FF5400',
            pointHoverBackgroundColor: '#FF5400',
            type: 'line',
            data: Array(POINTS_PER_STDDEV + 1)
              .fill(0)
              .map((_, k) => {
                let x =
                  minRange + (k * (maxRange - minRange)) / POINTS_PER_STDDEV;
                return { x, y: func(x) };
              }),
            radius: 0,
            hoverRadius: 0,
            pointStyle: false,
            fill: true,
            backgroundColor: COLORS[offset < 0 ? Math.abs(offset) - 1 : offset],
            borderWidth: 2,
          };
        }),
    [mean, stddev, func, listPrice],
  );

  const vehicleDataset = {
    label: 'Vehicle',
    backgroundColor: 'black',
    borderColor: 'black',
    pointHoverColor: 'black',
    pointHoverBackgroundColor: 'black',
    type: 'scatter',
    data: [{ x: listPrice, y: func(listPrice) }],
    radius: 8,
    hoverRadius: 8,
  };

  return (
    <Line
      options={options}
      data={{
        datasets: [vehicleDataset, ...curveDatasets],
      }}
    />
  );
};

BellCurve.fragments = {
  listings: gql`
    fragment BellCurveChartListingsResult on ListingType {
      price
    }
  `,
};

export default BellCurve;
