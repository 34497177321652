import React from 'react';
import PropTypes from 'prop-types';
import Link from '@mui/material/Link';

import withSupportHref from './withSupportHref';

import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  supportText: {
    fontSize: '14px',
  },
  supportContact: {
    marginTop: '10px',
  },
}));

const propTypes = {
  supportHref: PropTypes.string.isRequired,
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
};

const Support = ({ supportHref, className }) => {
  const classes = useStyles();

  return (
    <Box className={className}>
      <Box className={classes.supportText}>Need Assistance?</Box>
      <Box className={classes.supportContact}>
        <i className="fa fa-phone fa-fw" aria-hidden="true" />
        <Link href="tel:1-888-364-7966">1-888-384-7966</Link>
      </Box>
      <Box>
        <Link href={supportHref}>MarketSupport@goauto.ca</Link>
      </Box>
    </Box>
  );
};

Support.propTypes = propTypes;
Support.defaultProps = defaultProps;

export default withSupportHref(Support);
