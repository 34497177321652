import React from 'react';

import gql from 'graphql-tag';

/* Material UI */
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import LaunchIcon from '@mui/icons-material/Launch';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

/* internal */
import { paveReportLink } from 'modules/appraisals/utils';
import CustomerDisplay from 'modules/appraisals/components/common/CustomerDisplay';
import FormatDateTZ from 'components/MaterialUI/FormatDateTZ';

const AppraisalDetailsHeaderBox = ({ appraisal, ...rest }) => {
  const {
    createdAt,
    createdByUser,
    customerAppraisalSource,
    isPaveSessionComplete,
    paveSessionId,
    vautoDeepLink,
  } = appraisal;

  return (
    <Box {...rest}>
      <Box my={2}>
        <Typography variant="h4">Appraisal Details</Typography>
      </Box>
      <Grid container alignItems="center">
        <Grid item xs={12} sm={6}>
          <CustomerDisplay appraisal={appraisal} withAddress />
          <Box my={3}>
            <Box>
              Created <FormatDateTZ date={createdAt} /> by{' '}
              {createdByUser?.display_name ?? 'Unknown User'}
            </Box>
            {customerAppraisalSource && (
              <Box mt="5px">Source: {customerAppraisalSource}</Box>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sm={3}>
          {vautoDeepLink && (
            <Link
              style={{ textUnderlineOffset: '0.3em' }}
              href={vautoDeepLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Box display="flex" alignItems="center">
                View Appraisal in vAuto <LaunchIcon />
              </Box>
            </Link>
          )}
        </Grid>
        <Grid item xs={12} sm={3}>
          {paveSessionId && isPaveSessionComplete && (
            <Link
              style={{ textUnderlineOffset: '0.3em' }}
              href={paveReportLink(paveSessionId)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Box display="flex" alignItems="center">
                View PAVE Report{' '}
                <Icon
                  className="fas fa-road"
                  style={{ width: 'auto', marginLeft: '5px' }}
                />
              </Box>
            </Link>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

AppraisalDetailsHeaderBox.fragments = {
  appraisal: gql`
    fragment AppraisalDetailsHeaderBoxAppraisal on Appraisal {
      id
      createdAt
      createdByUser {
        username
        display_name
      }
      customer {
        _id
        fullname
        address
        city
        postal_code
        province
        identities {
          identity_type
          identity_value
        }
      }
      customerAppraisalSource
      isPaveSessionComplete
      opportunity {
        _id
      }
      paveSessionId
      vautoDeepLink
      ...CustomerDisplayAppraisal
    }
    ${CustomerDisplay.fragments.appraisal}
  `,
};

export default AppraisalDetailsHeaderBox;
