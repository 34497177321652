import React from 'react';

/* Material UI */
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';

/* internal */
import { formatOdometer, formatPrice } from 'utils';

const useStyles = makeStyles(theme => ({
  labelBox: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
      paddingRight: theme.spacing(0.5),
      marginLeft: theme.spacing(0.5),
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '16px',
      paddingRight: theme.spacing(1),
    },
    fontWeight: 'bold',
  },
  valueBox: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '16px',
    },
  },
}));
const LabelAndData = ({ label, data, labelProps, dataProps, align }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Grid container item xs={12}>
      <Grid item xs={align ? 4 : null}>
        <Box className={classes.labelBox} component="span" {...labelProps}>
          {label}:{' '}
        </Box>
      </Grid>
      <Grid item xs={align ? 8 : null}>
        <Box className={classes.valueBox} component="span" {...dataProps}>
          {data}
        </Box>
      </Grid>
    </Grid>
  );
};

const VehicleBoxDetails = ({ vehicle, pitch }) => {
  const {
    discounts = [],
    accessories = [],
    inventoryVehicle,
    odometer,
    salePrice,
    type,
    vin,
  } = vehicle;
  const {
    msrp,
    stock_number: stockNumber,
    days_in_stock: daysInStock,
  } = inventoryVehicle || {};
  const { appraisals = [], dealNumber } = pitch;

  const tradeInTotal = appraisals
    .filter(x => x)
    .reduce((total, appraisal) => total + appraisal.value ?? 0, 0);

  const totalDiscounts = discounts.reduce(
    (total, discount) => total + discount.price ?? 0,
    0,
  );

  const totalAccessories = accessories.reduce(
    (total, accessory) => total + accessory.price ?? 0,
    0,
  );

  const priceAdjust = totalAccessories - totalDiscounts;

  const totalPrice = salePrice + priceAdjust - tradeInTotal;

  return (
    <Grid container item xs={12}>
      <Grid container item xs={6} md={4} alignContent="flex-start">
        {[
          ['Deal #', dealNumber],
          ['Stock #', stockNumber ?? type],
          ['VIN', vin],
        ].map(([label, data], index) => (
          <LabelAndData key={index} label={label} data={data} />
        ))}
      </Grid>
      <Grid container item xs={6} md={4} alignContent="flex-start">
        {[
          ['Odometer', formatOdometer(odometer)],
          ['Days in Stock', daysInStock ?? 'N/A'],
          ['MSRP', formatPrice(msrp, { nullDisplay: 'N/A' })],
        ].map(([label, data], index) => (
          <LabelAndData key={index} label={label} data={data} />
        ))}
      </Grid>
      <Hidden only="xs">
        <Grid item xs={12} md={4}>
          {[
            ['Price', formatPrice(salePrice)],
            ['Trade In', formatPrice(tradeInTotal)],
            ['Adjust', formatPrice(priceAdjust)],
            ['Total Price', formatPrice(totalPrice), { fontWeight: 'bold' }],
          ].map(([label, data, dataProps], index) => (
            <LabelAndData
              key={index}
              label={label}
              data={data}
              dataProps={dataProps}
              align
            />
          ))}
        </Grid>
      </Hidden>
    </Grid>
  );
};

export default VehicleBoxDetails;
