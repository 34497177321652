import React from 'react';

/* external */
import { useHistory } from 'react-router-dom';
import gql from 'graphql-tag';

/* Material UI */
import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';

/* internal */
import { displayName } from '../utils';

const DealerDecline = ({ appraisal }) => {
  const history = useHistory();

  const { trimVariation, vin } = appraisal;

  return (
    <>
      <Box margin={1}>
        <Grid container direction="column">
          <Grid item>
            <Typography style={{ width: '100%' }}>
              <b>{displayName(appraisal)}</b> {trimVariation}
            </Typography>
          </Grid>
          <Grid item style={{ paddingBottom: '1rem' }}>
            {vin}
          </Grid>
        </Grid>
      </Box>
      <Box margin={1}>
        <Paper
          style={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
            height: '150px',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: 'auto',
            marginBottom: 'auto',
            padding: '1rem',
          }}
        >
          <Typography variant="h5">You have declined this offer!</Typography>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'center',
              height: '200px',
              marginLeft: 'auto',
              marginRight: 'auto',
              padding: '1rem',
            }}
          >
            <Button
              variant="outlined"
              style={{ width: '100px' }}
              onClick={() => history.push('/used-vehicles/')}
            >
              <HomeIcon /> Home
            </Button>
          </div>
        </Paper>
      </Box>
    </>
  );
};

DealerDecline.fragments = {
  appraisal: gql`
    fragment DealerDeclineAppraisal on Appraisal {
      id
      make
      model
      price
      trim
      trimVariation
      vin
      year
    }
  `,
};

export default DealerDecline;
