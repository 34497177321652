import React, { useCallback, useEffect, useState } from 'react';

/* external */
import { isEmpty } from 'lodash';
import { useFormContext, useWatch } from 'react-hook-form';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

/* Material UI */
import Alert from '@mui/material/Alert';
import Hidden from '@mui/material/Hidden';
import Paper from '@mui/material/Paper';

/* internal */
import CBBDetailsDesktop from './CBBDetailsDesktop';
import CBBDetailsMobile from './CBBDetailsMobile';
import Loading from 'components/MaterialUI/Loading';
import { CBB_CONDITIONS } from 'modules/inventory/const';

const shouldDirty = false;

const VALUATION_TRIM_QUERY = gql`
  query ValuationTrimQuery(
    $make_name: String!
    $model_name: String!
    $year: Int!
  ) {
    valuation {
      getTrims(make: $make_name, model: $model_name, year: $year)
    }
  }
`;

const VALUATION_STYLE_QUERY = gql`
  query ValuationStyleQuery(
    $make_name: String!
    $model_name: String!
    $trim: String!
    $year: Int!
  ) {
    valuation {
      getStyles(make: $make_name, model: $model_name, trim: $trim, year: $year)
    }
  }
`;

// needed to get the vid and available options
// can query with either vin and year or make, model, trim, style, year
const VALUATION_VEHICLES_QUERY = gql`
  query ValuationVehiclesQuery(
    $vin: String
    $year: Int
    $make_name: String
    $model_name: String
    $trim: String
    $style: String
  ) {
    valuation {
      getVehicles(
        vin: $vin
        year: $year
        make: $make_name
        model: $model_name
        trim: $trim
        style: $style
      ) {
        options {
          option_code
          description
        }
        make_name
        model_name
        style_name
        trim_name
        vid
        year
      }
    }
  }
`;

const VALUATION_VALUE_QUERY = gql`
  query ValuationValueQuery($vid: Int!, $odometer: Int) {
    valuation {
      getValue(vid: $vid, kilometers: $odometer) {
        condition
        options {
          average
          clean
          description
          extra_clean
          option_code
          rough
        }
        wholesale_prices {
          average
          clean
          extra_clean
          rough
        }
        retail_prices {
          average
          clean
          extra_clean
          rough
        }
      }
    }
  }
`;

const CBBDetails = ({ vehicle, vehicle: { odometer, trim, vin, cbb } }) => {
  const { register, setValue, control } = useFormContext();

  const [trimNames, setTrimNames] = useState([]);
  const [styleNames, setStyleNames] = useState([]);
  const [equipmentSelections, setEquipmentSelections] = useState([]);
  const [valuationVehicles, setValuationVehicles] = useState([]);

  register('cbb.vid');
  register('cbb.rough_price');
  register('cbb.average_price');
  register('cbb.clean_price');
  register('cbb.extra_clean_price');
  register('cbb.selected_price');

  const cbbVid = useWatch({ control, name: 'cbb.vid', defaultValue: cbb?.vid });
  const formOdometer = useWatch({
    control,
    name: 'odometer',
    defaultValue: odometer,
  });
  const [vid, setVid] = useState(cbbVid);

  const [vehicleValue, setVehicleValue] = useState({});
  const equipmentData = useWatch({
    control,
    name: 'cbb.equipment',
    defaultValue: cbb?.equipment,
  });
  const selectedCondition = useWatch({
    control,
    name: 'cbb.condition',
    defaultValue: cbb?.condition,
  });
  const selectedStyle = useWatch({
    control,
    name: 'cbb.style',
    defaultValue: cbb?.style,
  });
  const selectedTrim = useWatch({
    control,
    name: 'cbb.trim',
    defaultValue: cbb?.trim,
  });

  const year = useWatch({ control, name: 'year', defaultValue: vehicle.year });
  const make_name = useWatch({
    control,
    name: 'make_name',
    defaultValue: vehicle.make_name,
  });
  const model_name = useWatch({
    control,
    name: 'model_name',
    defaultValue: vehicle.model_name,
  });

  const [selectedEquipment, setSelectedEquipment] = useState(
    JSON.parse(equipmentData || '[]'),
  );

  const trimQuery = useQuery(VALUATION_TRIM_QUERY, {
    variables: {
      year,
      make_name,
      model_name,
    },
  });
  const [getStyles, styleQuery] = useLazyQuery(VALUATION_STYLE_QUERY);
  const [getValuationVehicles, getValuationVehiclesQuery] = useLazyQuery(
    VALUATION_VEHICLES_QUERY,
  );
  const [getValue, getValueQuery] = useLazyQuery(VALUATION_VALUE_QUERY, {
    onCompleted: data => {
      if (data?.valuation?.getValue?.condition)
        setValue(
          'cbb.condition',
          cbb?.condition || data?.valuation?.getValue?.condition,
          {
            shouldDirty,
          },
        );
    },
  });

  const getWholesalePrice = useCallback(
    key => vehicleValue?.wholesale_prices?.[key],
    [vehicleValue],
  );

  const getRetailPrice = useCallback(
    key => vehicleValue?.retail_prices?.[key],
    [vehicleValue],
  );

  const getPriceAdjustments = useCallback(
    key =>
      vehicleValue?.options
        ?.filter(x => selectedEquipment.includes(x.option_code))
        .reduce((total, option) => total + option[key], 0),
    [vehicleValue, selectedEquipment],
  );

  const getWholesaleTotal = useCallback(
    key =>
      getWholesalePrice(key) !== null && getPriceAdjustments(key) !== null
        ? getWholesalePrice(key) + getPriceAdjustments(key)
        : null,
    [getWholesalePrice, getPriceAdjustments],
  );

  const getRetailTotal = useCallback(
    key =>
      getRetailPrice(key) !== null && getPriceAdjustments(key) !== null
        ? getRetailPrice(key) + getPriceAdjustments(key)
        : null,
    [getRetailPrice, getPriceAdjustments],
  );

  useEffect(() => {
    if (trimQuery.data) setTrimNames(trimQuery.data.valuation.getTrims);
  }, [trimQuery]);

  useEffect(() => {
    if (trimNames && trimNames.length > 0) {
      // shouldn't have to do anything if there's a saved cbb.trim value
      if (cbb && cbb.trim && trimNames.includes(cbb.trim));
      else if (trimNames.length === 1)
        // Only 1 trim?  Select that one!
        setValue('cbb.trim', trimNames[0], { shouldDirty });
      else if (trim && trimNames.includes(trim))
        // if magically the vehicle's trim is in the
        // list of CBB trims, just go with that!
        setValue('cbb.trim', trim, { shouldDirty });
    }
  }, [cbb, setValue, trim, trimNames]);

  useEffect(() => {
    // Some models have no (empty) trims
    if (trimNames && (selectedTrim || selectedTrim === ''))
      getStyles({
        variables: { year, make_name, model_name, trim: selectedTrim },
      });
  }, [getStyles, make_name, model_name, selectedTrim, trimNames, year]);

  useEffect(() => {
    if (styleQuery.data) setStyleNames(styleQuery.data.valuation.getStyles);
  }, [styleQuery]);

  useEffect(() => {
    if (
      styleNames &&
      styleNames.length === 1 &&
      selectedStyle !== styleNames[0]
    )
      setValue('cbb.style', styleNames[0], { shouldDirty });
  }, [selectedStyle, setValue, styleNames]);

  useEffect(() => {
    // Some models have no (empty) trims
    if (selectedStyle && (selectedTrim || selectedTrim === '')) {
      getValuationVehicles({
        variables: {
          year,
          make_name,
          model_name,
          trim: selectedTrim,
          style: selectedStyle,
        },
      });
    } else {
      // try again with just vin and year
      getValuationVehicles({
        variables: {
          year,
          vin,
        },
      });
    }
  }, [
    getValuationVehicles,
    make_name,
    model_name,
    selectedStyle,
    selectedTrim,
    vin,
    year,
  ]);

  useEffect(() => {
    if (JSON.stringify(selectedEquipment) !== equipmentData)
      setValue('cbb.equipment', JSON.stringify(selectedEquipment), {
        shouldDirty: true,
      });
  }, [equipmentData, selectedEquipment, setValue]);

  useEffect(() => {
    if (getValuationVehiclesQuery.data)
      setValuationVehicles(
        getValuationVehiclesQuery.data.valuation.getVehicles,
      );
  }, [getValuationVehiclesQuery]);

  useEffect(() => {
    if (valuationVehicles && valuationVehicles.length > 0) {
      // app-inventory just uses the first vehicle returned, even if there are
      // multiples.  So, I guess we'll do that here?
      if (!vid || !valuationVehicles.map(x => x.vid).includes(vid))
        setVid(valuationVehicles[0].vid);
      setEquipmentSelections(valuationVehicles[0].options);
    }
  }, [valuationVehicles, vid]);

  useEffect(() => {
    if (vid) {
      getValue({
        variables: {
          odometer: formOdometer ? parseInt(formOdometer, 10) : null,
          vid,
        },
      });
      if (vid !== cbbVid) setValue('cbb.vid', vid, { shouldDirty });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formOdometer, vid]);

  useEffect(() => {
    if (getValueQuery.data)
      setVehicleValue(getValueQuery.data.valuation.getValue);
  }, [getValueQuery]);

  useEffect(() => {
    CBB_CONDITIONS.forEach(cbbCondition => {
      setValue(`cbb.${cbbCondition}_price`, getWholesaleTotal(cbbCondition), {
        shouldDirty,
      });
    });
    setValue('cbb.selected_price', getWholesaleTotal(selectedCondition), {
      shouldDirty,
    });
  }, [getWholesaleTotal, selectedCondition, setValue, vehicleValue]);

  const handleEquipment = ({ target: { value } }) => {
    if (selectedEquipment.includes(value))
      setSelectedEquipment(selectedEquipment.filter(x => x !== value));
    else setSelectedEquipment(prev => Array.from(new Set([...prev, value])));
  };

  if (trimQuery.loading)
    return (
      <Paper style={{ width: '100%', paddingBottom: '2rem' }}>
        <Loading />
      </Paper>
    );

  if (isEmpty(trimNames))
    return (
      <Alert severity="warning">
        There was not enough data returned from the Canadian Black Book for this
        vehicle.
      </Alert>
    );

  return (
    <Paper style={{ width: '100%' }}>
      <Hidden smUp>
        <CBBDetailsMobile
          equipmentSelections={equipmentSelections}
          getPriceAdjustments={getPriceAdjustments}
          getRetailPrice={getRetailPrice}
          getRetailTotal={getRetailTotal}
          getWholesalePrice={getWholesalePrice}
          getWholesaleTotal={getWholesaleTotal}
          handleEquipment={handleEquipment}
          selectedCondition={selectedCondition}
          selectedEquipment={selectedEquipment}
          styleNames={styleNames}
          trimNames={trimNames}
        />
      </Hidden>
      <Hidden smDown>
        <CBBDetailsDesktop
          equipmentSelections={equipmentSelections}
          getPriceAdjustments={getPriceAdjustments}
          getRetailPrice={getRetailPrice}
          getRetailTotal={getRetailTotal}
          getWholesalePrice={getWholesalePrice}
          getWholesaleTotal={getWholesaleTotal}
          handleEquipment={handleEquipment}
          selectedCondition={selectedCondition}
          selectedEquipment={selectedEquipment}
          styleNames={styleNames}
          trimNames={trimNames}
        />
      </Hidden>
    </Paper>
  );
};

CBBDetails.fragments = {
  vehicle: gql`
    fragment CBBDetailsVehicle on GreaseInventoryVehicle {
      cbb {
        average_price
        clean_price
        condition
        equipment
        extra_clean_price
        rough_price
        selected_price
        style
        trim
        vid
      }
      make_name
      model_name
      odometer
      year
      trim
      vin
    }
  `,
};

export default CBBDetails;
