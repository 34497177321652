import React, { useState } from 'react';

/* external */
import { useMutation, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useSnackbar } from 'notistack';

/* Material UI */
import { useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

/* internal */
import { smsMessageBody } from 'modules/appraisals/utils';
import ErrorDisplay from 'components/MaterialUI/ErrorDisplay';
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';

const SEND_SMS = gql`
  mutation sendSMS($gatewayId: ID!, $phoneNumber: String, $body: String) {
    sendSMS(
      gateway_id: $gatewayId
      message: { body: $body, sent_to: $phoneNumber }
      shorten_urls: true
    ) {
      message {
        id
      }
    }
  }
`;

const GATEWAYS_QUERY = gql`
  query gateways {
    gateways {
      getGateways {
        gateways {
          id
          gateway_type
          name
          phone_number
        }
      }
    }
  }
`;

const SendSMSDialog = ({ onClose, paveSessionId, ...rest }) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const [gatewayId, setGatewayId] = useState();
  const [phoneNumber, setPhoneNumber] = useState();

  const gatewaysQuery = useQuery(GATEWAYS_QUERY);
  const [sendSMS, sendSMSMutation] = useMutation(SEND_SMS);

  const gateways = (
    gatewaysQuery.data?.gateways.getGateways.gateways ?? []
  ).sort((a, b) => a.name.localeCompare(b.name));

  const body = smsMessageBody(paveSessionId);

  const handleConfirm = () => {
    sendSMS({
      variables: { gatewayId, phoneNumber, body },
    }).then(
      () => {
        handleClose();
        enqueueSnackbar('Sent SMS successfully!', { variant: 'success' });
      },
      () => {},
    );
  };
  const handleGatewaySelect = e => setGatewayId(e.target.value);

  const handleChangePhoneNumber = e => setPhoneNumber(e.target.value);

  const handleClose = () => {
    setGatewayId(null);
    setPhoneNumber(null);
    onClose();
  };

  return (
    <Dialog {...{ onClose, ...rest }} fullWidth>
      <DialogTitle>Resend Pave SMS</DialogTitle>
      <DialogContent>
        <LoadingBackdrop open={sendSMSMutation.loading}>
          Sending SMS...
        </LoadingBackdrop>
        <Grid container>
          <Grid item xs={12}>
            <TextField
              label="Gateway"
              onChange={handleGatewaySelect}
              select
              fullWidth
            >
              {gateways.map(({ id, name, phone_number }) => (
                <MenuItem key={id} value={id}>
                  {name} ({phone_number})
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="SMS Number"
              onChange={handleChangePhoneNumber}
              value={phoneNumber}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <ErrorDisplay error={sendSMSMutation.error} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          style={{ color: theme.actions.cancel.color }}
        >
          Cancel
        </Button>
        <Button
          style={{ color: theme.actions.create.color }}
          onClick={handleConfirm}
        >
          Re-Send
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SendSMSDialog;
