import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';

import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

// Internal
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';

const useStyles = makeStyles(theme => ({
  boldTitle: {
    fontWeight: 'bold',
    fontSize: '1.025rem',
  },
}));

const UPDATE_SUBSCRIPTION_SETTINGS = gql`
  mutation UpdateSubscription(
    $gatewayId: Int!
    $subscription: SubscriptionInput!
  ) {
    sms {
      updateSubscription(gatewayId: $gatewayId, subscription: $subscription) {
        id
      }
    }
  }
`;

const SMSNotificationsDialog = ({
  gatewayId,
  onClose,
  open,
  gatewayName,
  phoneNumber,
  subscriptions,
  ...rest
}) => {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const [unreadNotification, setUnreadNotification] = useState(
    subscriptions?.notifyAllUnreadItems ?? false,
  );
  const [unassignedNotification, setUnassignedNotification] = useState(
    subscriptions?.notifyAllUnassignedItems ?? false,
  );

  const [updateSusbcriptionSettings, updateSubscriptionSettingsMutation] =
    useMutation(UPDATE_SUBSCRIPTION_SETTINGS, {
      onCompleted: () => {
        enqueueSnackbar('Notification settings updated!', {
          variant: 'success',
        });
      },
      onError: err => {
        enqueueSnackbar(`Unable to save changes: ${err}`, { variant: 'error' });
      },
    });

  const updateSubscription = (event, checkbox) => {
    /* the mutation will run when the user clicks one of the two checkboxes
        if that checkbox is the one that was clicked, we use the event.target.checked value for the mutation
        and use the current ("old") value in the state for the other checkbox that wasn't clicked
        the state will be updated when the page re-renders after the mutation has run
    */
    checkbox === 'unassigned'
      ? setUnassignedNotification(event.target.checked)
      : setUnreadNotification(event.target.checked);

    updateSusbcriptionSettings({
      variables: {
        gatewayId,
        subscription: {
          notifyAllUnassignedItems:
            checkbox === 'unassigned'
              ? event.target.checked
              : unassignedNotification,
          notifyAllUnreadItems:
            checkbox === 'unread' ? event.target.checked : unreadNotification,
        },
      },
    });
  };

  return (
    <Dialog onClose={onClose} open={open} {...rest}>
      <LoadingBackdrop open={updateSubscriptionSettingsMutation.loading}>
        Saving...
      </LoadingBackdrop>
      <DialogTitle>Notification Settings</DialogTitle>
      <DialogContent>
        <Box>
          <Typography variant="body1" className={classes.boldTitle}>
            Gateway
          </Typography>
          <Typography variant="body1">{`${gatewayName} (${phoneNumber})`}</Typography>
        </Box>
        <Box mt="15px">
          <Typography variant="body1" className={classes.boldTitle}>
            To receive notifications please select from below:
          </Typography>
          <FormControlLabel
            control={<Checkbox />}
            checked={unassignedNotification}
            onChange={event => updateSubscription(event, 'unassigned')}
            label="Notify me of any conversations that are unassigned"
          />
          <FormControlLabel
            control={<Checkbox />}
            checked={unreadNotification}
            onChange={event => updateSubscription(event, 'unread')}
            label="Notify me of any conversations that are unread"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SMSNotificationsDialog;
