//  EXTERNAL
import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

// MUI

import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { makeStyles } from '@mui/styles';

import ErrorDisplay from 'components/MaterialUI/ErrorDisplay';
import Loading from 'components/MaterialUI/Loading';

// INTERNAL

const GATEWAYS_QUERY = gql`
  query gatewaysQuery {
    sms {
      gateways {
        id
        name
        phone_number
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  formControl: {
    maxWidth: '45%',
    marginBottom: '20px',
  },
}));

const GatewayPicker = ({ selectedGatewayId, setSelectedGatewayId }) => {
  const classes = useStyles();

  const { loading, data, error } = useQuery(GATEWAYS_QUERY);

  if (loading) {
    return <Loading text="Loading list of gateways..." />;
  }

  if (error) {
    return <ErrorDisplay error={error} action="Loading List of Gateways" />;
  }

  const gateways = data.sms.gateways;
  const gatewayOptions = [
    <MenuItem key={0} value="">
      All
    </MenuItem>,
    ...gateways
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(gateway => (
        <MenuItem key={gateway.id} value={gateway.id}>
          {`${gateway.name} (${gateway.phone_number})`}
        </MenuItem>
      )),
  ];
  return (
    <>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel shrink id="gateway-select-label">
          Gateway
        </InputLabel>
        <Select
          labelId="gateway-select-label"
          label="Gateway"
          value={selectedGatewayId}
          onChange={e =>
            setSelectedGatewayId(
              e.target.value !== ''
                ? parseInt(e.target.value, 10)
                : e.target.value,
            )
          }
          displayEmpty
        >
          {gatewayOptions}
        </Select>
      </FormControl>
    </>
  );
};
export default GatewayPicker;
