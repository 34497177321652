import React from 'react';

/* external */
import gql from 'graphql-tag';

/* Material UI */
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles(theme => ({
  contentPadding: {
    padding: theme.spacing(2),
  },
  fullHeight: {
    height: '100%',
  },
}));

const VehicleNotes = ({ item }) => {
  const { contentPadding, fullHeight } = useStyles();
  const { vehicle } = item;
  const { notes } = vehicle;

  return (
    <Paper className={fullHeight}>
      <Box className={contentPadding}>
        <Typography variant="h5">Vehicle Notes</Typography>
        {notes ? <Box dangerouslySetInnerHTML={{ __html: notes }} /> : ''}
      </Box>
    </Paper>
  );
};

VehicleNotes.fragments = {
  item: gql`
    fragment VehicleNotesItem on AuctionItem {
      vehicle {
        id
        notes
      }
    }
  `,
};
export default VehicleNotes;
