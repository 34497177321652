/* external */
import React from 'react';
import gql from 'graphql-tag';

/* Material UI */
import { Box, Paper } from '@mui/material';

/* internal */
import { PhotoBox, MediaBox } from '../common';
import PanelTitle from './PanelTitle';

const EditPhotosMedia = ({
  vehicle,
  photoList,
  setPhotoList,
  videoList,
  setVideoList,
  labelId,
  setLabelId,
}) => (
  <Paper>
    <Box>
      <PanelTitle title="Photos & Media" vehicle={vehicle} />
      <Box margin={1}>
        <PhotoBox
          vehicle={vehicle}
          photoList={photoList}
          setPhotoList={setPhotoList}
          labelId={labelId}
          setLabelId={setLabelId}
        />
        <MediaBox
          vehicle={vehicle}
          videoList={videoList}
          setVideoList={setVideoList}
        />
      </Box>
    </Box>
  </Paper>
);

EditPhotosMedia.fragments = {
  vehicle: gql`
    fragment NpvVehiclePhotos on NpvVehicle {
      id
      ...NpvVehiclePhotoBox
    }
    ${PhotoBox.fragments.vehicle}
    fragment NpvVehicleVideos on NpvVehicle {
      ...NpvVehicleMediaBox
    }
    ${MediaBox.fragments.vehicle}
  `,
};

export default EditPhotosMedia;
