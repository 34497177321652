import React from 'react';
import gql from 'graphql-tag';
import cloudinary from 'cloudinary-core';
import { useHistory } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import ShareIcon from '@mui/icons-material/Share';
import InfoIcon from '@mui/icons-material/Info';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { cloudinaryConfig } from '../../../../constants';
import { cloudinaryVideoParams } from '../../const';
import { getMediumDate } from 'utils';

const cloudinaryCore = new cloudinary.Cloudinary({
  cloud_name: cloudinaryConfig.cloud_name,
});

const ROVideoCard = ({
  video,
  onDelete,
  onShare,
  toDetails = false,
  videoFeed = false,
  timezone,
}) => {
  const showShareButton = Boolean(onShare);
  const history = useHistory();
  const handleShare = () => {
    if (
      !video.shared &&
      !window.confirm('Share video with customer via SMS?')
    ) {
      return;
    }
    onShare();
  };
  const handleDelete = () => {
    if (window.confirm('Delete video?')) {
      onDelete();
    }
  };
  const dealerId = video.service_ro.dealer_id;
  const roNumber = video.service_ro.ro_number;

  return (
    <Card>
      <Grid container direction="row">
        <Grid
          item
          xs={6}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Box p={1}>
            {!videoFeed && (
              <>
                <b>Added By:</b> {video.created_by_display_name}
              </>
            )}
            {videoFeed && (
              <>
                <b>RO Number:</b> {video.service_ro.ro_number}
              </>
            )}
          </Box>
          <Box p={1}>
            <CardContent>
              {onDelete && (
                <IconButton
                  aria-label="delete"
                  onClick={handleDelete}
                  size="large"
                >
                  <DeleteIcon />
                </IconButton>
              )}
              {showShareButton && (
                <IconButton
                  aria-label="share"
                  onClick={handleShare}
                  color={video.shared ? 'secondary' : 'default'}
                  size="large"
                >
                  <ShareIcon />
                </IconButton>
              )}
              {toDetails && (
                <IconButton
                  aria-label="share"
                  onClick={() =>
                    history.push(`/rotracker/${dealerId}/${roNumber}`)
                  }
                  size="large"
                >
                  <InfoIcon />
                </IconButton>
              )}
            </CardContent>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box p={1}>
            {videoFeed && (
              <>
                <b>Added by:</b> {video.created_by_display_name}
              </>
            )}
          </Box>
          <Box p={1}>
            <b>Added on:</b> {getMediumDate(video.created_at, timezone)}
          </Box>
          <Box p={1}>
            <CardMedia
              src={cloudinaryCore.url(
                video.cloudinary_public_id,
                cloudinaryVideoParams,
              )}
              component="video"
              height={100}
              width={50}
              controls
              bgcolor="text.secondary"
            />
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};

ROVideoCard.fragments = {
  video: gql`
    fragment ROVideoCardVideo on ROTrackerVideo {
      id
      cloudinary_public_id
      created_by_display_name
      created_at
      deleted
      shared
      title
      service_ro {
        ro_number
        dealer_id
        customer_name
        vehicle_make
        vehicle_year
        vehicle_model
      }
    }
  `,
};

export default ROVideoCard;
