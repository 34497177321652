/* external */
import gql from 'graphql-tag';
import React from 'react';
import { useQuery } from '@apollo/react-hooks';

/* Material UI */
import {
  Box,
  ButtonBase,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { newWindowUrlHandler } from 'utils';

const useStyles = makeStyles(theme => ({
  contentPadding: {
    padding: theme.spacing(2),
  },
  fullHeight: {
    height: '100%',
  },
  labelCell: {
    width: '33%',
  },
}));

const AUCTION_CARFAX_QUERY = gql`
  query Auctions($vehicleId: Int!) {
    auctions {
      carfaxBadging(vehicleId: $vehicleId) {
        badgeUrl
        reportUrl
        vehicleId
      }
    }
  }
`;

const VehicleDetails = ({ item }) => {
  const { contentPadding, fullHeight, labelCell } = useStyles();

  const { vehicle } = item;
  const {
    id: vehicleId,
    driveType,
    engineCompressor,
    engineConfig,
    engineCylinders,
    engineLitres,
    exteriorColourName,
    fuelType,
    interiorColourName,
    transmission,
    upholsteryName,
    vin,
  } = vehicle;

  const { data } = useQuery(AUCTION_CARFAX_QUERY, {
    variables: { vehicleId },
  });

  const { badgeUrl, reportUrl } = data?.auctions?.carfaxBadging ?? {};

  const engine = [
    engineLitres ? `${engineLitres}L` : '',
    engineConfig && engineCylinders ? `${engineConfig}${engineCylinders}` : ' ',
    engineCompressor,
  ].join(' ');

  const interior = [interiorColourName, upholsteryName]
    .filter(x => x)
    .join(' ');

  return (
    <Paper className={fullHeight}>
      <Box className={contentPadding}>
        <Typography variant="h5">Vehicle Details</Typography>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className={labelCell}>VIN:</TableCell>
              <TableCell>{vin}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={labelCell}>Transmission:</TableCell>
              <TableCell>{transmission}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={labelCell}>Drive Type:</TableCell>
              <TableCell>{driveType}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={labelCell}>Engine:</TableCell>
              <TableCell>{engine}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={labelCell}>Fuel Type:</TableCell>
              <TableCell>{fuelType}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={labelCell}>Colour:</TableCell>
              <TableCell>{exteriorColourName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={labelCell}>Interior:</TableCell>
              <TableCell>{interior}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={labelCell}>CarFax:</TableCell>
              <TableCell>
                {badgeUrl && (
                  <ButtonBase onClick={newWindowUrlHandler(reportUrl)}>
                    <img src={badgeUrl} alt="carfax-badge" />
                  </ButtonBase>
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </Paper>
  );
};

VehicleDetails.fragments = {
  item: gql`
    fragment VehicleDetailsItem on AuctionItem {
      vehicle {
        id
        driveType
        engineCompressor
        engineConfig
        engineCylinders
        engineLitres
        exteriorColourName
        fuelType
        interiorColourName
        transmission
        upholsteryName
        vin
      }
    }
  `,
};
export default VehicleDetails;
