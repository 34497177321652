import React from 'react';

import { Box, Divider, Typography, Grid } from '@mui/material';
import { TextFieldControl, CheckboxControl } from 'components/MaterialUI';

export const GridWithLabel = ({ label, children }) => (
  <>
    <Box component="div" marginBottom={3}>
      <Box component="div" marginBottom={3}>
        <Typography variant="h5">{label}</Typography>
        <Divider />
      </Box>
      <Grid container spacing={3} style={{ padding: '0px 8px' }}>
        {children}
      </Grid>
    </Box>
  </>
);

export const GridItem = ({ label, name, type = 'string', ...rest }) => (
  <>
    <Grid item xs={12} sm={6}>
      <TextFieldControl
        label={label}
        fullWidth
        muiVariant="outlined"
        name={name}
        type={type}
        {...rest}
      />
    </Grid>
  </>
);

export const UintItem = props => (
  <GridItem defaultValue={0} type="number" inputProps={{ min: 0 }} {...props} />
);

export const FtInItem = ({ label, nameFt, nameIn }) => (
  <>
    <Grid item xs={2} style={{ textAlign: 'end', alignSelf: 'center' }}>
      <Typography variant="body1">{label}</Typography>
    </Grid>
    <Grid item xs={2}>
      <TextFieldControl
        defaultValue={0}
        fullWidth
        label="Ft."
        muiVariant="outlined"
        name={nameFt}
        type="number"
        inputProps={{ min: 0 }}
      />
    </Grid>
    <Grid item xs={2}>
      <TextFieldControl
        defaultValue={0}
        fullWidth
        label="In."
        muiVariant="outlined"
        name={nameIn}
        type="number"
        inputProps={{ min: 0, max: 12 }}
      />
    </Grid>
  </>
);

export const BoolItem = ({ label, name }) => (
  <>
    <Grid item xs={2} style={{ textAlign: 'end', alignSelf: 'center' }}>
      <Typography variant="body1">{label}</Typography>
    </Grid>
    <Grid item xs={4} style={{ alignSelf: 'center', textAlign: 'center' }}>
      <CheckboxControl name={name} />
    </Grid>
  </>
);
