import React from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Controller, useFormContext } from 'react-hook-form';

import { tinyMCEApiUrl } from 'constants.js';

export default function LazyEditor(props) {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      render={({ field: { onChange, onBlur, value, name } }) => (
        <Editor
          init={{
            height: 400,
            selector: 'textarea',
            plugins: ['lists link image paste help wordcount'],
            toolbar:
              'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | help',
          }}
          apiKey={tinyMCEApiUrl}
          onBlur={onBlur}
          onEditorChange={onChange}
          textareaName={name}
          value={value}
        />
      )}
      {...props}
    />
  );
}
