/* external */

/* Material UI */
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DateRangeIcon from '@mui/icons-material/DateRange';
import EditIcon from '@mui/icons-material/Edit';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { AppointmentStatus } from 'constants.js';
import moment from 'moment';
import React from 'react';
import { defaultTZ } from 'utils';

const OpportunityAppointmentList = ({
  appointments,
  updateStatus,
  setAppointmentAndOpenEditModal,
  confirmAppointment,
}) => {
  const theme = useTheme();

  return (
    <List>
      {appointments.length === 0 && (
        <Typography style={{ fontSize: 14 }}>
          There are no appointments for this customer.
        </Typography>
      )}
      {appointments
        .sort((a, b) => a.start_date > b.start_date)
        .map(appointment => {
          const isCancelled = appointment?.status_name === 'Cancelled';
          const isConfirmed = appointment?.is_confirmed;
          const isAttended = appointment?.status_name === 'Show';

          return (
            <ListItem key={appointment._id}>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <ListItemAvatar>
                    {isCancelled ? (
                      <Typography variant="caption">Cancelled</Typography>
                    ) : isAttended ? (
                      <CheckIcon style={{ color: 'green' }} />
                    ) : (
                      <DateRangeIcon />
                    )}
                  </ListItemAvatar>
                </Grid>
                <Grid item xs={4}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item>
                      <ListItemText
                        primaryTypographyProps={{
                          style: {
                            textDecoration: isCancelled
                              ? 'line-through'
                              : 'none',
                          },
                        }}
                        secondaryTypographyProps={{
                          style: {
                            textDecoration: isCancelled
                              ? 'line-through'
                              : 'none',
                          },
                        }}
                        primary={`${appointment.type_name} @ ${appointment.dealer.dealer_name}`}
                        secondary={`${moment(appointment.start_date)
                          .tz(defaultTZ)
                          .format('LLL')}  Assigned to: ${
                          appointment.attendees?.[0]?.display_name || 'N/A'
                        }`}
                      />
                    </Grid>

                    {isConfirmed && (
                      <Grid item>
                        <VerifiedUserIcon style={{ color: 'green' }} />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                {!isAttended && (
                  <>
                    <Grid item>
                      <IconButton
                        disabled={isCancelled}
                        onClick={setAppointmentAndOpenEditModal.bind(
                          this,
                          appointment,
                        )}
                        size="large"
                      >
                        <EditIcon />
                      </IconButton>
                    </Grid>
                    {!isConfirmed && (
                      <Grid item>
                        <Button
                          disabled={isCancelled}
                          onClick={() => confirmAppointment(appointment._id)}
                          color="primary"
                          variant="contained"
                          style={{ backgroundColor: theme.actions.info }}
                        >
                          <VerifiedUserIcon />
                          {'CONFIRM'}
                        </Button>
                      </Grid>
                    )}
                    <Grid item>
                      <Button
                        disabled={isCancelled}
                        onClick={() =>
                          updateStatus(
                            appointment._id,
                            AppointmentStatus.ATTENDED,
                          )
                        }
                        color="primary"
                        variant="contained"
                        style={{ backgroundColor: theme.actions.info }}
                      >
                        <CheckIcon />
                        {'SHOW'}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        disabled={isCancelled}
                        onClick={() =>
                          updateStatus(
                            appointment._id,
                            AppointmentStatus.NO_SHOW,
                          )
                        }
                        color="primary"
                        variant="contained"
                        style={{ backgroundColor: theme.actions.info }}
                      >
                        <SentimentVeryDissatisfiedIcon />
                        {'NO SHOW'}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        disabled={isCancelled}
                        onClick={() =>
                          updateStatus(
                            appointment._id,
                            AppointmentStatus.CANCELLED,
                          )
                        }
                        color="primary"
                        style={{ backgroundColor: theme.actions.info }}
                        variant="contained"
                      >
                        <CloseIcon />
                        {'CANCEL'}
                      </Button>
                    </Grid>
                  </>
                )}
              </Grid>
            </ListItem>
          );
        })}
    </List>
  );
};

export default OpportunityAppointmentList;
