import { isEqual } from 'lodash';
// Utility functions for calcuating deal log metrics
import compose from 'lodash.flowright';

import { Status } from '../../../../constants';
import { formatPrice } from '../../../../utils';

const filterByStatus = (opportunities, ...status) =>
  opportunities.filter(o => status.indexOf(o.status) > -1);

export const getAccountingDeal = opportunity =>
  opportunity.accounting_deal || {};

export const getAccountingDealFrontGross = opportunity =>
  getAccountingDeal(opportunity).frontend_gross || {};

export const getAccountingDealBackGross = opportunity =>
  getAccountingDeal(opportunity).backend_gross || {};

export const getAccountingDealTotalGross = opportunity =>
  (getAccountingDealFrontGross(opportunity).value || 0) +
  (getAccountingDealBackGross(opportunity).value || 0);

export const getDmsDeal = opportunity => opportunity.dms_deal || {};

export const getSalesDeal = opportunity => opportunity.sales_deal || {};

export const getSalesDealFrontGross = opportunity =>
  getSalesDeal(opportunity).frontend_gross || {};

export const getSalesDealFrontGrossValue = opportunity =>
  getSalesDealFrontGross(opportunity).value || 0;

export const getSalesDealBackGross = opportunity =>
  getSalesDeal(opportunity).backend_gross || {};

export const getSalesDealBackGrossValue = opportunity =>
  getSalesDealBackGross(opportunity).value || 0;

export const getSalesDealTotalGross = opportunity =>
  getSalesDeal(opportunity).total_gross || {};

export const getSalesDealTotalGrossValue = opportunity =>
  getSalesDealTotalGross(opportunity).value ||
  getSalesDealFrontGrossValue(opportunity) +
    getSalesDealBackGrossValue(opportunity);

export const getDmsDealTotalGross = opportunity =>
  getDmsDeal(opportunity).total_gross || 0;

export const getDmsDealFrontEndGross = opportunity =>
  getDmsDeal(opportunity).frontend_gross || 0;

export const getDmsDealBackEndGross = opportunity =>
  getDmsDeal(opportunity).backend_gross || 0;

// the below two functions use the same logic as sumTotalGross to determine
// which front end or back end gross value to use:
// - check accounting deal first
// - then check dms_deal
// - then check the sales deal
export const getFrontEndGross = opportunity => {
  return (
    +getAccountingDealFrontGross(opportunity).value ||
    +getDmsDeal(opportunity).frontend_gross ||
    +getSalesDealFrontGross(opportunity).value
  );
};

export const getBackEndGross = opportunity => {
  return (
    +getAccountingDealBackGross(opportunity).value ||
    +getDmsDeal(opportunity).backend_gross ||
    +getSalesDealBackGross(opportunity).value
  );
};

export const sumFrontEndGross = opportunities => {
  return opportunities.reduce((sum, o) => {
    const frontEndGross = getFrontEndGross(o);
    if (frontEndGross) return sum + frontEndGross;
    return sum;
  }, 0);
};

export const sumBackEndGross = opportunities => {
  return opportunities.reduce((sum, o) => {
    const backEndGross = getBackEndGross(o);
    if (backEndGross) return sum + backEndGross;
    return sum;
  }, 0);
};

export const getOpen = opportunities =>
  filterByStatus(opportunities, ...Status.OPEN);

export const getPending = opportunities =>
  filterByStatus(opportunities, Status.PENDING);

export const getApproved = opportunities =>
  filterByStatus(opportunities, Status.APPROVED);

export const getSigned = opportunities =>
  filterByStatus(opportunities, Status.SIGNED);

export const getCompleted = opportunities =>
  filterByStatus(opportunities, ...Status.COMPLETED);

export const getDelivered = opportunities =>
  filterByStatus(opportunities, Status.DELIVERED);

export const getPosted = opportunities =>
  filterByStatus(opportunities, Status.POSTED);

export const getTubed = opportunities =>
  filterByStatus(opportunities, Status.TUBED);

export const getPreApp = opportunities =>
  filterByStatus(opportunities, Status.PRE_APP);

export const getCarryover = opportunities =>
  filterByStatus(opportunities, Status.CARRYOVER);

export const getPreAppCarryOver = opportunities =>
  filterByStatus(opportunities, Status.PRE_APP, Status.CARRYOVER);

export const getTotalGross = opportunity => {
  let gross = 0;
  if (getAccountingDeal(opportunity)) {
    gross = getAccountingDealTotalGross(opportunity);
    if (gross !== 0) return gross;
  }
  gross = getDmsDealTotalGross(opportunity);

  if (gross !== 0) return gross;

  if (getSalesDeal(opportunity)) {
    gross = getSalesDealTotalGrossValue(opportunity);

    if (gross !== 0) return gross;
  }
  return 0;
};

export const sumTotalGross = opportunities =>
  opportunities.reduce((sum, o) => {
    let totalSum = 0;
    const backGrss = getBackEndGross(o);
    const frontGrss = getFrontEndGross(o);
    if (backGrss) totalSum = totalSum + backGrss;
    if (frontGrss) totalSum = totalSum + frontGrss;
    return sum + totalSum;
  }, 0);

export const sumBackFrontTotal = opportunities =>
  opportunities.reduce((sum, o) => {
    let totalSum = 0;
    const backGross = getBackEndGross(o);
    const frontGross = getFrontEndGross(o);
    if (backGross) totalSum = totalSum + backGross;
    if (frontGross) totalSum = totalSum + frontGross;
    return sum + totalSum;
  }, 0);

export const getDealPostedGross = opportunity => {
  const frontGross = getAccountingDealFrontGross(opportunity);
  const backGross = getAccountingDealBackGross(opportunity);

  return (frontGross.value || 0) + (backGross.value || 0);
};

export const getVariance = opportunity => {
  const postedGross = getDealPostedGross(opportunity);
  const storeGross = getDmsDealTotalGross(opportunity);
  const variance = postedGross - storeGross;

  return Math.round(variance * 100) / 100;
};

export const sumTotalVariance = opportunities =>
  opportunities
    .filter(o => getAccountingDealTotalGross(o) !== 0)
    .reduce((sum, o) => sum + getVariance(o), 0);

export const getPendingGross = compose(sumTotalGross, getPending);

export const getApprovedGross = compose(sumTotalGross, getApproved);

export const getSignedGross = compose(sumTotalGross, getSigned);

export const getFrontCompleteGross = compose(sumFrontEndGross, getCompleted);

export const getBackCompleteGross = compose(sumBackEndGross, getCompleted);

export const getTotalCompleteGross = compose(sumTotalGross, getCompleted);

export const getTotalDeliveredGross = compose(sumTotalGross, getDelivered);

export const getTotalPostedGross = compose(sumTotalGross, getPosted);

export const getFrontPotentialGross = compose(sumFrontEndGross, getOpen);

export const getBackPotentialGross = compose(sumBackEndGross, getOpen);

export const getTotalPotentialGross = compose(sumTotalGross, getOpen);

export const getTotalPostedSum = compose(sumBackFrontTotal, getPosted);

const getStatusMetrics = opportunities => {
  const statusMetrics = {
    frontEndAvg:
      (sumFrontEndGross(opportunities) / opportunities.length).toFixed(0) || 0,
    backEndAvg:
      (sumBackEndGross(opportunities) / opportunities.length).toFixed(0) || 0,
    totalAvg:
      (sumTotalGross(opportunities) / opportunities.length).toFixed(0) || 0,
    totalGross: sumTotalGross(opportunities).toFixed(0),
  };
  return statusMetrics;
};

const calculateMetrics = (opportunities, role) => {
  let metrics = [];
  for (var i = 0; i < opportunities.length; i++) {
    const opportunity = opportunities[i];

    const roleData = opportunity[role];
    if (roleData.length > 0) {
      roleData.forEach(user => {
        let index = metrics.findIndex(x => isEqual(x.user, user));
        let row =
          index > -1
            ? metrics[index]
            : {
                user: user,
                total_writes: 0,
                count_open: 0,
                gross_open: 0,
                count_sold: 0,
                count_delivered: 0,
                front_gross_sold: 0,
                avg_front_gross_sold: 0,
                back_gross_sold: 0,
                avg_back_gross_sold: 0,
                total_gross_sold: 0,
                avg_total_gross_sold: 0,
                count_tubed: 0,
                ratio_tubed: 0,
                back_gross: 0,
                front_gross: 0,
                total_gross: 0,
                pre_app_count: 0,
                carryover_count: 0,
              };

        row['total_writes'] += 1 / roleData.length;

        if (Status.OPEN.includes(opportunity.status)) {
          row['count_open'] += 1 / roleData.length;
          row['gross_open'] +=
            role === 'finance_managers'
              ? getBackEndGross(opportunity) / roleData.length
              : getFrontEndGross(opportunity) / roleData.length;
        }

        if (Status.COMPLETED.includes(opportunity.status)) {
          row['count_sold'] += 1 / roleData.length;
          row['front_gross_sold'] +=
            getFrontEndGross(opportunity) / roleData.length;
          row['back_gross_sold'] +=
            getBackEndGross(opportunity) / roleData.length;
        }

        if (Status.TUBED === opportunity.status) {
          row['count_tubed'] += 1 / roleData.length;
        }

        if (Status.DELIVERED === opportunity.status) {
          row['count_delivered'] += 1 / roleData.length;
          row['gross_delivered'] +=
            getFrontEndGross(opportunity) / roleData.length;
        }

        if (Status.PRE_APP === opportunity.status) {
          row['pre_app_count'] += 1 / roleData.length;
        }

        if (Status.CARRYOVER === opportunity.status) {
          row['carryover_count'] += 1 / roleData.length;
        }

        if (index > -1) {
          metrics[index] = row;
        } else {
          metrics.push(row);
        }
      });
    }
  }

  metrics.forEach(metric => {
    const { count_sold, front_gross_sold, back_gross_sold } = metric;
    const total_gross_sold = front_gross_sold + back_gross_sold;
    metric['total_gross_sold'] = total_gross_sold;

    if (count_sold > 0) {
      metric['avg_front_gross_sold'] = front_gross_sold / count_sold;
      metric['avg_back_gross_sold'] = back_gross_sold / count_sold;
      metric['avg_total_gross_sold'] = total_gross_sold / count_sold;
    }

    let total_delivered = metric.count_sold + metric.count_delivered;
    if (total_delivered > 0 && metric.total_writes > 0) {
      metric['ratio_delivered'] = total_delivered / metric.total_writes;
    }

    if (metric.count_tubed > 0 && metric.total_writes > 0) {
      metric['ratio_tubed'] = metric.count_tubed / metric.total_writes;
    }
  });

  return metrics;
};

export const getRoleMetrics = opportunities => {
  return {
    salesRepMetrics: calculateMetrics(opportunities, 'sales_reps'),
    salesManagerMetrics: calculateMetrics(opportunities, 'sales_managers'),
    financeManagerMetrics: calculateMetrics(opportunities, 'finance_managers'),
  };
};

const getMetrics = opportunities => {
  const metrics = {
    allOpps: opportunities.length,
    writes: opportunities.length - getTubed(opportunities).length,
    open:
      getOpen(opportunities).length - getPreAppCarryOver(opportunities).length,
    pending: getPending(opportunities).length,
    pendingMetrics: getStatusMetrics(getPending(opportunities)),
    approved: getApproved(opportunities).length,
    approvedMetrics: getStatusMetrics(getApproved(opportunities)),
    signed: getSigned(opportunities).length,
    signedMetrics: getStatusMetrics(getSigned(opportunities)),
    completed: getCompleted(opportunities).length,
    delivered: getDelivered(opportunities).length,
    deliveredMetrics: getStatusMetrics(getDelivered(opportunities)),
    posted: getPosted(opportunities).length,
    postedMetrics: getStatusMetrics(getPosted(opportunities)),
    totalPotentialGross: getTotalPotentialGross(opportunities),
    backPotentialGross: getBackPotentialGross(opportunities),
    frontPotentialGross: getFrontPotentialGross(opportunities),

    backCompleteGross: getBackCompleteGross(opportunities),
    frontCompleteGross: getFrontCompleteGross(opportunities),

    completePosted: getTotalPostedSum(opportunities),
    totalVariance: sumTotalVariance(opportunities),

    tubed: getTubed(opportunities).length,
    tubedPercent: (
      (getTubed(opportunities).length / opportunities.length) *
      100
    ).toFixed(0),
    carryOver: getCarryover(opportunities).length,
    preApp: getPreApp(opportunities).length,
    totalDeliveredGross: formatPrice(
      Math.round(getTotalDeliveredGross(opportunities)),
    ),
    totalPostedGross: formatPrice(
      Math.round(getTotalPostedGross(opportunities)),
    ),
    totalCompleteGross: formatPrice(
      Math.round(getTotalCompleteGross(opportunities)),
    ),
    totalMetrics: getStatusMetrics(opportunities),
  };
  return metrics;
};

export default getMetrics;
