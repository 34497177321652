// External
import { useQuery } from '@apollo/react-hooks';
import { NetworkStatus } from 'apollo-boost';
import gql from 'graphql-tag';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';

// Material UI
import {
  Box,
  Button,
  Card,
  Dialog,
  Fab,
  FormControlLabel,
  Grid,
  Hidden,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Paper,
  Switch,
  Table,
  TableBody,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { green } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import {
  Add,
  Close,
  FilterList,
  Help,
  MoreVert,
  Search,
} from '@mui/icons-material';
import Alert from '@mui/material/Alert';
import { useTheme } from '@mui/material';

// Internal
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';
import { useUserContext } from 'components/MaterialUI/UserContext';

import { formatPhoneNumber, formatVin, usePersistedState } from 'utils';

import { FACET_FILTERS } from '../constant';
import AddCustomerModal from './AddCustomerModal';
import AdvancedSearchResult from './AdvancedSearchResult';
import AdvancedSearchResultMobile from './AdvancedSearchResultMobile';
import CustomerAdvancedSearchHeader from './CustomerAdvancedSearchHeader';
import CustomerSearchFilters from './CustomerSearchFilters';
import CustomerSearchHeader from './CustomerSearchHeader';
import FilterChips from './FilterChips';
import SearchResult from './SearchResult';
import SearchResultMobile from './SearchResultMobile';
import { RoleGroup } from 'constants.js';

const PAGE_SIZE = 25;

const SEARCH_QUERY = gql`
  query customerSearch($q: String!, $cursor: String!, $filterQuery: String, $facet: String, $sort: String ) {
    customerSearch(
      params: {
        query: $q
        size: ${PAGE_SIZE}
        cursor: $cursor
        filterQuery: $filterQuery
        sort: $sort
        facet: $facet
      }
    ) {
      facets
      hits {
        found
        cursor
        hit {
          id
          customer {
            _id
            ...AdvancedSearchResultCustomer
            ...AdvancedSearchResultMobileCustomer
            ...SearchResultFragment
          }
          fields {
            ...AdvancedSearchResultFragment
            ...AdvancedSearchResultMobileFragment
          }
        }
      }
    }
  }
  ${SearchResult.fragments.customer}
  ${AdvancedSearchResult.fragments.fields}
  ${AdvancedSearchResult.fragments.customer}
  ${AdvancedSearchResultMobile.fragments.fields}
  ${AdvancedSearchResultMobile.fragments.customer}
`;

// NOTE: if we ever have more than 100 Go Auto dealers, we'll need to change
// this.
const GET_DEALERS = gql`
  query getDealers {
    inventory {
      getDealers(
        page_size: 100
        sort: [{ model: "Dealer", field: "name", direction: "asc" }]
      ) {
        results {
          id
          name
        }
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
  },
  filterGrid: {
    paddingTop: '1rem !important',
  },
  searchGrid: {
    padding: '24px 12px 0px 24px !important',
  },
  searchField: {
    display: 'flex',
    padding: '2px 4px',
    flex: 1,
  },
  button: {
    padding: '24px 12px 0px 12px !important',
  },
  fabAbove: {
    bottom: '70px',
    right: '10px',
    position: 'fixed',
    backgroundColor: green[500],
  },
  fabBelow: {
    bottom: '10px',
    right: '10px',
    position: 'fixed',
    backgroundColor: green[500],
  },
  results: {
    alignSelf: 'flex-end',
    textAlign: 'right',
    paddingRight: '10px !important',
  },
  mTextField: {
    width: '100%',
    paddingLeft: '4px',
    paddingRight: '4px',
  },
  mHeader: {
    paddingLeft: '10px',
    paddingTop: '5px',
  },
  mRoot: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  },
  mResults: {
    alignSelf: 'flex-end',
    textAlign: 'right',
    fontSize: '10px',
    paddingRight: '20px',
    paddingBottom: '5px',
  },
}));

const CustomerSearch = () => {
  const location = useLocation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { currentUser } = useUserContext();
  const theme = useTheme();
  const { role, username, organization_id } = currentUser;
  const [advancedSearch, setAdvancedSearch] = usePersistedState(
    'CustomerSearchSwitch',
    false,
  );
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('full_name');
  const [showModal, setShowModal] = useState(false);
  const [returnState, setReturnState] = useState('');
  const [selected, setSelected] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openFilters, setOpenFilters] = useState(false);
  const [advancedOrder, setAdvancedOrder] = useState('desc');
  const [advancedOrderBy, setAdvancedOrderBy] = useState('date_created');
  const [advancedFilter, setAdvancedFilter] = useState('');
  const [filters, setFilters] = usePersistedState('CustomerSearchFilters', [
    { name: 'province', value: null },
    {
      name: 'users',
      value: RoleGroup.SALES_PEOPLE.includes(role) ? username : null,
    },
    { name: 'city', value: null },
  ]);

  // Only grab dealers if we don't have any
  // Store in a persisted state since these wont be changing so only get them once
  const { data: dealerData, loading: dealerLoading } = useQuery(GET_DEALERS);
  const dealers = dealerData?.inventory?.getDealers?.results || [];

  // Build out filterQuery to be a string
  let filterQuery = !advancedSearch
    ? `(and (and organization:'${organization_id}' (not deleted:1)) (and resource_type:'smi.crm:customer')`
    : '(and';
  // If advanced search is enabled and we have a filter selected
  // Vehicle records do not have an organization so we cannot include it in advanced search
  filters.forEach(filter => {
    if (filter.value !== null) {
      filterQuery += ` (and ${filter.name}:'${filter.value}')`;
    }
  });
  // This is where it gets F U N
  if (advancedSearch) {
    if (advancedFilter !== '') {
      filterQuery += ` (and resource_type:'${advancedFilter}')`;
    }
    // Or all dealers to query
    // organization has to be nested in all the or's to ensure vehicle records are returned
    dealers.forEach(({ id }, index) => {
      filterQuery += ` (or dealer_id:'${id}'`;
      // Add organization to end of nested ors
      filterQuery +=
        index + 1 === dealers.length
          ? ` (and organization:'${organization_id}' (not deleted:1))`
          : '';
      // Close out all the nested ors
    });
    filterQuery += ')'.repeat(dealers.length);
  }
  // Close out entire query
  filterQuery += ')';

  // If we can format a number lets do it
  const formatPhone = phoneNumber =>
    formatPhoneNumber(phoneNumber) ?? phoneNumber;

  const facet = JSON.stringify({ resource_type: { buckets: FACET_FILTERS } });
  let searchText = decodeURIComponent(location.search).substring(1);
  if (advancedSearch) {
    searchText =
      formatVin(searchText, false) ?? formatPhone(searchText) ?? searchText;
  }
  const classes = useStyles();

  // Our index is too large to sort by score, this will cause issues with loading new results by cursor
  // https://docs.aws.amazon.com/cloudsearch/latest/developerguide/paginating-results.html
  const qry = useQuery(SEARCH_QUERY, {
    variables: {
      q: searchText,
      cursor: 'initial',
      filterQuery,
      facet,
      sort: advancedSearch
        ? `${advancedOrderBy} ${advancedOrder}`
        : `${orderBy} ${order}`,
    },
    onError: () => {
      enqueueSnackbar('Error loading search results', { variant: 'error' });
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    skip: dealers.length === 0,
  });

  const { data, loading, fetchMore, networkStatus } = qry;
  const hits = (data && data.customerSearch.hits.hit) || [];
  const facetResults = (data && data.customerSearch.facets) || {};
  const cursor = (data && data.customerSearch.hits.cursor) || 'initial';
  const found = (data && data.customerSearch.hits.found) || 0;
  const loadMoreResults = () =>
    fetchMore({
      variables: { q: searchText, cursor },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        const newData = {
          ...fetchMoreResult,
        };
        newData.customerSearch.hits.hit = [
          ...prev.customerSearch.hits.hit,
          ...fetchMoreResult.customerSearch.hits.hit,
        ];
        return newData;
      },
    });

  const handleModalClick = () => {
    setShowModal(prevShowModal => !prevShowModal);
  };

  const handleRequestSort = (event, property) => {
    if (!advancedSearch) {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    } else {
      const isAsc = advancedOrderBy === property && advancedOrder === 'asc';
      setAdvancedOrder(isAsc ? 'desc' : 'asc');
      setAdvancedOrderBy(property);
    }
  };

  const isSelected = ({ customer }) => selected.includes(customer?._id);

  const handleClick = (e, { _id }) => {
    if (e.target.checked) {
      setSelected(prev =>
        hits.map(h => h?.customer?._id).filter(x => [...prev, _id].includes(x)),
      );
    } else setSelected(prev => prev.filter(x => x !== _id));
  };

  const handleAnchorClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleAnchorClose = () => {
    setAnchorEl(null);
  };

  const handleSwitchClick = event => {
    if (!event.target.checked) {
      setAdvancedFilter('');
    }
    setAdvancedSearch(event.target.checked);
  };
  return (
    <>
      <LoadingBackdrop open={dealerLoading}>
        Loading dealers for advanced search...
      </LoadingBackdrop>
      <Dialog
        maxWidth="sm"
        open={showModal}
        onClose={() => {
          setShowModal(false);
        }}
      >
        <AddCustomerModal
          handleClose={() => {
            setShowModal(false);
          }}
        />
      </Dialog>
      <CustomerSearchFilters
        isOpen={openFilters}
        filters={filters}
        setFilters={setFilters}
        closeDrawer={() => setOpenFilters(false)}
        advancedSearch={advancedSearch}
        advancedFilter={advancedFilter}
        setAdvancedFilter={setAdvancedFilter}
        facetResults={facetResults}
      />
      <Hidden lgDown>
        <div style={{ overflowX: 'hidden' }}>
          <Box m={[0, 1]}>
            <Grid container spacing={3}>
              <Grid item xs={9} className={classes.searchGrid}>
                <Paper
                  elevation={0}
                  component="form"
                  className={classes.root}
                  onSubmit={e => {
                    e.preventDefault();
                    history.push({
                      search: returnState.value,
                    });
                  }}
                >
                  <TextField
                    className={classes.searchField}
                    inputRef={el => {
                      setReturnState(el);
                    }}
                    placeholder="Search by name, phone or email"
                    defaultValue={searchText}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={e => {
                              e.preventDefault();
                              history.push({
                                search: '',
                              });
                            }}
                            size="large"
                          >
                            <Close />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Paper>
                <Box pt={1}>
                  <Tooltip title="Select Customers to use Bulk Actions">
                    <Box component="span">
                      <Fab
                        disabled={advancedSearch}
                        color="primary"
                        onClick={handleAnchorClick}
                        size="medium"
                      >
                        <MoreVert />
                      </Fab>
                    </Box>
                  </Tooltip>
                  <Tooltip title="Filter List">
                    <Fab
                      color="primary"
                      onClick={() => setOpenFilters(true)}
                      size="medium"
                      style={{ marginLeft: theme.spacing(1) }}
                    >
                      <FilterList />
                    </Fab>
                  </Tooltip>
                  <Box style={{ float: 'right' }}>
                    <FormControlLabel
                      style={{ marginRight: '5px' }}
                      control={
                        <Switch
                          checked={advancedSearch}
                          onClick={handleSwitchClick}
                        />
                      }
                      label="Advanced Search"
                    />
                    <Tooltip title="Advanced search will search all customer profiles and search records including leads, opportunities, etc.">
                      <Help
                        fontSize="small"
                        style={{ verticalAlign: 'text-bottom' }}
                      />
                    </Tooltip>
                  </Box>
                </Box>

                <Menu
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={() => handleAnchorClose()}
                  style={{ width: '200px' }}
                >
                  <MenuItem
                    disabled={selected.length <= 1}
                    onClick={() => history.push(`/customers/merge/${selected}`)}
                  >
                    Merge Customers
                  </MenuItem>
                </Menu>
              </Grid>
              <Grid item xs={3} className={classes.button}>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<Add />}
                  onClick={handleModalClick}
                  style={{ backgroundColor: green[500], marginTop: '16px' }}
                >
                  New Customer
                </Button>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={9} style={{ alignSelf: 'end', marginTop: '8px' }}>
                <FilterChips
                  filters={filters}
                  updateFilters={setFilters}
                  advancedFilter={advancedFilter}
                  setAdvancedFilter={setAdvancedFilter}
                />
              </Grid>
              <Grid item xs={3} className={classes.results}>
                <Typography>
                  Showing {hits.length} of {found}
                </Typography>
              </Grid>
            </Grid>
            <Table>
              {!advancedSearch ? (
                <CustomerSearchHeader
                  classes={classes}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
              ) : (
                <CustomerAdvancedSearchHeader
                  classes={classes}
                  order={advancedOrder}
                  orderBy={advancedOrderBy}
                  onRequestSort={handleRequestSort}
                />
              )}
              <TableBody>
                {hits.map(hit => {
                  const isItemSelected = isSelected(hit);
                  if (!hit.customer && !advancedSearch)
                    return (
                      <Alert
                        severity="error"
                        elevation={1}
                        key={`${hit.id}:alert`}
                      >
                        Search result customer does not exist ({hit.id})
                      </Alert>
                    );
                  return (
                    <TableRow
                      key={`${hit.id}:row`}
                      hover
                      role="checkbox"
                      selected={isItemSelected}
                      onClick={() => {
                        if (!hit.customer) {
                          enqueueSnackbar(
                            `No customer associated for result ${hit.id}`,
                          );
                        } else {
                          history.push(
                            `/customers/${hit.customer._id}/details`,
                          );
                        }
                      }}
                    >
                      {!advancedSearch ? (
                        <SearchResult
                          customer={hit.customer}
                          isItemSelected={isItemSelected}
                          handleClick={handleClick}
                        />
                      ) : (
                        <AdvancedSearchResult
                          fields={hit.fields}
                          customer={hit.customer}
                        />
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            {!hits.length && !loading && (
              <Box textAlign="center" mt={4}>
                <Search fontSize="large" />
                <Typography variant="subtitle1" gutterBottom>
                  No Results
                </Typography>
                <Typography variant="subtitle2">
                  Search for a customer...
                </Typography>
              </Box>
            )}
            <LoadingBackdrop open={loading}>Loading Results...</LoadingBackdrop>
          </Box>
        </div>
        <Box height="1px">
          {!loading &&
            hits.length !== found &&
            NetworkStatus.fetchMore !== networkStatus && (
              <Waypoint onEnter={loadMoreResults} />
            )}
        </Box>
      </Hidden>
      <Hidden lgUp>
        <div>
          <Box>
            <h3 className={classes.mHeader}>Customer Search</h3>
            <Paper
              elevation={0}
              component="form"
              className={classes.mRoot}
              onSubmit={e => {
                e.preventDefault();
                history.push({ search: returnState.value });
              }}
            >
              <TextField
                className={classes.mTextField}
                inputRef={el => {
                  setReturnState(el);
                }}
                placeholder="Search by name, phone or email"
                defaultValue={searchText}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={e => {
                          e.preventDefault();
                          history.push({ search: '' });
                        }}
                        size="large"
                      >
                        <Close />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Paper>
          </Box>
          <Grid container>
            <Grid item xs={12}>
              <Box style={{ float: 'right' }}>
                <FormControlLabel
                  style={{ marginRight: '5px' }}
                  control={
                    <Switch
                      checked={advancedSearch}
                      onClick={handleSwitchClick}
                    />
                  }
                  label="Advanced Search"
                />
                <Tooltip title="Advanced search will search all customer profiles and search records including leads, opportunities, etc.">
                  <Help
                    fontSize="small"
                    style={{ verticalAlign: 'text-bottom', marginRight: '5px' }}
                  />
                </Tooltip>
              </Box>
            </Grid>
            <Grid item xs={8} style={{ paddingLeft: '10px' }}>
              <FilterChips
                filters={filters}
                updateFilters={setFilters}
                advancedFilter={advancedFilter}
                setAdvancedFilter={setAdvancedFilter}
              />
            </Grid>
            <Grid item xs={4} className={classes.mResults}>
              Showing {hits.length} of {found}
            </Grid>
          </Grid>
          {hits.map((hit, n) => (
            <div key={`${hit.id}:mobile`}>
              {(!hit.customer && (
                <Alert severity="error" elevation={1} key={`${hit.id}:m-alert`}>
                  Search result customer does not exist ({hit.id})
                </Alert>
              )) || (
                <div
                  style={{ padding: '5px 10px 5px 10px' }}
                  key={`${hit.id}:m-search`}
                >
                  <Card
                    onClick={() =>
                      history.push(`/customers/${hit.customer._id}/details`)
                    }
                  >
                    {!advancedSearch ? (
                      <SearchResultMobile customer={hit.customer} />
                    ) : (
                      <AdvancedSearchResultMobile
                        fields={hit.fields}
                        customer={hit.customer}
                      />
                    )}
                  </Card>
                </div>
              )}
            </div>
          ))}
          {!hits.length && !loading && (
            <Box textAlign="center" mt={4}>
              <Search fontSize="large" />
              <Typography variant="subtitle1" gutterBottom>
                No Results
              </Typography>
              <Typography variant="subtitle2">
                Search for a customer...
              </Typography>
            </Box>
          )}
          <LoadingBackdrop open={loading}>Loading Results...</LoadingBackdrop>
          <Tooltip title="Add Customer">
            <span disabled={advancedSearch}>
              <Fab
                className={classes.fabAbove}
                color="primary"
                onClick={handleModalClick}
                size="small"
              >
                <Add />
              </Fab>
            </span>
          </Tooltip>
          <Tooltip title="Filter List">
            <Fab
              className={classes.fabBelow}
              color="primary"
              onClick={() => setOpenFilters(true)}
              size="small"
            >
              <FilterList />
            </Fab>
          </Tooltip>
        </div>
        <Box height="1px">
          {!loading &&
            hits.length !== found &&
            NetworkStatus.fetchMore !== networkStatus && (
              <Waypoint onEnter={loadMoreResults} />
            )}
        </Box>
      </Hidden>
    </>
  );
};

export default CustomerSearch;
