import { useLazyQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';

// Material UI
import { Box, Button, Dialog, DialogContent, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';

// internal
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';

const GET_GOCARD = gql`
  query getGoCard($card_id: ID!) {
    gocard {
      accountById(card_id: $card_id) {
        cardId
        active
        rvClubMembership
        customer {
          _id
          fullname
          first_name
          last_name
          identities {
            identity_type
            identity_value
          }
          vehicles {
            _id
            make
            model
            year
            trim
          }
        }
        cashBalance
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  whiteFont: {
    color: '#FFFFFF',
  },
}));

const Mode = {
  IDLE: 'Idle',
  LOADING: 'Loading',
  DETECTING: 'Detecting',
  WAITING: 'Waiting',
};

// Scans a Go Card using the device camera or QR code scanner peripheral
const GoCardScanner = ({ setGocard, iconOnly, onScan }) => {
  const theme = useTheme();
  const { whiteFont } = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [value, setValue] = useState('');
  const [mode, setMode] = useState(Mode.IDLE);
  const [buf, setBuf] = useState('');
  const barcodeDetector = useRef(null);
  const captureRef = useRef();
  const bufTimeoutRef = useRef();
  const video = useRef(null);

  const [getGoCard, { loading: cardLoading }] = useLazyQuery(GET_GOCARD, {
    onCompleted: ({ gocard }) => {
      // Must be an active card
      if (gocard.accountById.active) {
        setGocard(gocard);
        onScan();
      } else {
        enqueueSnackbar('Go Card not active', { variant: 'error' });
      }
    },
    onError: e =>
      enqueueSnackbar('Go Card could not be found', { variant: 'error' }),
  });

  const parseQRPayload = payload => {
    const parsed = {
      cardId: null,
      valid: false,
      customerId: null,
      createdAt: null,
    };
    if (payload?.startsWith('GOCARD:')) {
      const parts = payload.split(':');
      parsed.cardId = Number(parts[1]);
      parsed.createdAt = new Date(Number(parts[2]) * 1000);
      parsed.valid = Math.abs(new Date() - parsed.createdAt) < 1000 * 60 * 15;
      parsed.customerId = Number(parts[3]);
    }

    return parsed;
  };

  const capture = async () => {
    if (barcodeDetector.current && video.current) {
      try {
        const barcodes = await barcodeDetector.current.detect(video.current);
        if (barcodes.length > 0) {
          setValue(barcodes[0].rawValue);
          setMode(Mode.IDLE);
        }
      } catch (e) {
        // pass
      }
    }
    captureRef.current = requestAnimationFrame(capture);
  };

  useEffect(() => {
    setMode(Mode.LOADING);
    const initBarcodeDetector = async () => {
      if ('BarcodeDetector' in window) {
        barcodeDetector.current = new window.BarcodeDetector();
      } else {
        barcodeDetector.current = null;
      }
      setMode(Mode.IDLE);
    };
    initBarcodeDetector();
  }, []);

  useEffect(() => {
    const start = async () => {
      const media = await navigator.mediaDevices.getUserMedia({
        audio: false,
        video: { facingMode: 'environment' },
      });
      video.current.srcObject = media;
      video.current.autoplay = true;
    };
    if (mode === Mode.DETECTING) {
      captureRef.current = requestAnimationFrame(capture);
      start();
    }
    return () => cancelAnimationFrame(captureRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, barcodeDetector.current]);

  useEffect(() => {
    const handleKey = e => {
      clearTimeout(bufTimeoutRef.current);
      if (e.key === 'Enter') {
        setValue(buf);
        setMode(Mode.IDLE);
      } else {
        setBuf(buf + e.key);
      }
      setTimeout(() => setBuf(''), 200);
    };
    if (mode === Mode.WAITING) {
      window.addEventListener('keypress', handleKey);
    }
    return () => window.removeEventListener('keypress', handleKey);
  }, [mode, buf]);

  const scan = () => {
    setMode(barcodeDetector.current ? Mode.DETECTING : Mode.WAITING);
  };

  useEffect(() => {
    if (value) {
      const parsed = parseQRPayload(value);
      if (!parsed.valid) {
        enqueueSnackbar(
          'QR Code is not valid, refresh the QR code and try again',
          { variant: 'error' },
        );
      } else {
        getGoCard({
          variables: {
            card_id: parsed.cardId,
          },
        });
        enqueueSnackbar('Successfully Scanned QR Code', { variant: 'success' });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, setValue]);

  if (mode === Mode.IDLE) {
    return (
      <Box maxWidth={400} p={2}>
        <Button
          onClick={scan}
          variant="outlined"
          style={theme.actions.info}
          size="large"
          className={whiteFont}
        >
          <CenterFocusStrongIcon
            style={!iconOnly ? { paddingRight: '5px' } : null}
          />
          {!iconOnly && <>Scan Card</>}
        </Button>
        <LoadingBackdrop open={cardLoading}>Scanning card...</LoadingBackdrop>
      </Box>
    );
  }

  if (mode === Mode.LOADING) {
    return <div>Loading...</div>;
  }

  if (mode === Mode.DETECTING) {
    return (
      <Dialog open onClose={() => setMode(Mode.IDLE)}>
        <DialogContent>
          <p>Use your device camera to scan the QR code</p>
          <Box>
            <video ref={video} style={{ width: '100%' }}></video>
          </Box>
        </DialogContent>
      </Dialog>
    );
  }

  if (mode === Mode.WAITING) {
    return (
      <Dialog open onClose={() => setMode(Mode.IDLE)}>
        <DialogContent>
          <p>Use your scanner to scan the QR code</p>
        </DialogContent>
      </Dialog>
    );
  }
};

export default GoCardScanner;
