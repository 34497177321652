import React, { useRef, useState } from 'react';
import { withStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import { cloneDeep } from 'lodash';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import InspectionCheckboxes from '../InspectionCheckboxes';

const AudiCPOForm = props => {
  const { enqueueSnackbar } = useSnackbar();

  // AudiCPO form fields
  const audi = {
    a1: '1. Has the vehicle passed the CARFAX Canada Vehicle History Report? Enter eight digit CARFAX Canada verification code:',
    a2: '2. Has the vehicle been in operation for at least 4 months or for a minimum of 3,000 kilometres?',
    a3: '3. Is the vehicle within the Model Year minimum, with no more than 112,000 km (0-6 MYs old) or 140,000 km (7-8 MYs old)?',
    a4: '4. Is the vehicle free from current or evidence of past modifications (chassis, body, or performance chip)? Perform the Warranty Information Test Plan.',
    a5: '5. If applicable, is the vehicle able to charge using levels 1, 2?',
    a6: '6. Is it certain the vehicle does not have an extensive or unreasonable service history?',
    a7: '7. Do all Canadian VINS match? (frame/windshield/B-pillar; all original, no alterations)',
  };
  const preWork = {
    a8: '8. Does the paperwork match the VIN and vehicle?',
    a9: '9. Is the model year of the vehicle confirmed?',
    a10: '10. Are all major scheduled maintenance (including oil changes) performed up to date?',
    a11: "11. Is the Owner's Manual present?",
    a12: '12. Perform HV battery classification as per test plan and visual (Service bulletin in Elsa #2055203)',
    a13: "13. Has the diagnostic test and appropriate VAS tester (GFF) been run? Note: Any DTC's present must be addressed before proceeding. If report uses in-charge, use GRX 3000.",
    a14: '14. Has the 12V battery condition test (VAS Test) been run?',
    a15: '15. Upload complete diagnostic log to the Paperless GFF Server (once before and once after road test)',
    a16: '16. Has it been confirmed that all Safety Recalls, RVUs and Service Actions have been closed?',
    a17: "17. Is the vehicle still covered under the original manufacturer's warranty?",
  };
  const preDetermination = {
    a18: '18. In your opinion, is this vehicle a good candidate to become certified? If no, stop and notify the Service Manager and the Certified Pre-Owned Manager. If yes, continue through the checklist. Note: All electrified vehicle inspections are required to be performed by an HVT - High Voltage Technician or HVE - High Voltage Expert.',
  };
  const roadTest = {
    a19: '19. Starter operation/starting',
    a20: '20. Fuel pump noise',
    a21: '21. Engine performance idle/noise/vibrations',
    a22: '22. Smooth acceleration/deceleration',
    a23: '23. Transmission and shift points',
    a24: '24. Transmission/transaxle noise',
    a25: '25. Shift interlock',
    a26: '26. Clutch operation',
    a27: '27. Exhaust noises/odours',
    a28: '28. Brakes',
    a29: '29. ABS function (warning lamp not illuminated)',
    a30: '30. Steering',
    a31: '31. Suspension noise/operation/performance',
    a32: '32. Overall vehicle driveability',
    a33: '33. Speedometer, odometer, trip-meter function',
    a34: '34. Navigation system, media devices',
    a35: '35. Driver assist: side assist, lane assist, adaptive cruise control, night vision',
    a36: '36. Check operation of regenerative braking, confirm power enters charge range while braking. Note: cannot regenerate at 100% battery',
    a37: '37. Check operation of EV mode (PHEV)',
  };
  const frontInterior = {
    a38: '38. All keys are present (two main keys minimum)',
    a39: '39. Warning lights and messages',
    a40: '40. Onboard computer',
    a41: '41. Hazard lights, dash/console switches',
    a42: '42. Driver/passenger airbags',
    a43: '43. Speakers',
    a44: '44. Dash lights/dimmers',
    a45: '45. Cup holders',
    a46: '46. Steering wheel/lock/key/tilt/telescope',
    a47: '47. Horn',
    a48: '48. Exterior mirror control, heater and folding function L/R',
    a49: '49. Rear-view mirror/compass/auto-dimmer',
    a50: '50. Dome and front courtesy lights',
    a51: '51. Hood release mechanism',
    a52: '52. Trunk/tailgate release/kick open entry',
    a53: '53. Fuel door release',
    a54: '54. Accessory power socket',
    a55: '55. AM/FM radio, CD changer, SAT radio, AMI/Jukebox functionality',
    a56: '56. Clock',
    a57: '57. Windshield wiper controls',
    a58: '58. Defogger/defroster',
    a59: '59. HVAC/blower/climate control/vents',
    a60: '60. Gear shift handle/indicator/shift interlock',
    a61: '61. Parking brake switch',
    a62: '62. Foot pedals/operation and covers',
    a63: '63. Warning chimes and bells/alarms/theft deterrent',
    a64: '64. Bluetooth functionality',
    a65: '65. MMI functionality and control buttons',
  };
  const leftFrontInterior = {
    a66: '66. Left front interior trim and door handle',
    a67: '67. Left front visor/vanity mirror/light',
    a68: '68. Left curtain airbag',
    a69: '69. Left front seat airbag',
    a70: '70. Left front power window/door lock',
    a71: '71. Left front seat controls/power/memory function',
    a72: '72. Left front seat heater',
    a73: '73. Left front seat ventilator',
    a74: '74. Left front massage seat function',
    a75: '75. Left front controls/vents',
    a76: '76. Left front carpet/floor mat/footwell',
    a77: '77. Left front safety belt/height adjuster',
    a78: '78. Left front seat cushion and cover',
    a79: '79. Driver seat headrest/headrest release/headliner',
    a80: '80. Left front door light/reflector',
    a81: '81. Left front headliner assist handle',
  };
  const leftRearInterior = {
    a82: '82. Left rear interior trim and door handle',
    a83: '83. Left rear power window/door lock',
    a84: '84. Left rear seat heater and ventilator',
    a85: '85. Left rear seat massaging function',
    a86: '86. Left rear carpet, floor mat and footwell',
    a87: '87. Left rear safety belt',
    a88: '88. Left rear seat cushion and cover',
    a89: '89. Rear seat folding function',
    a90: '90. Left rear headrest, headrest release and headliner',
    a91: '91. Left rear door lights/reflector',
    a92: '92. Left rear window sunshade',
    a93: '93. Left rear side airbag',
    a94: '94. Left rear headliner assist handle',
    a95: '95. Rear armrest/ski sack',
    a96: '96. Rear controls/vents',
    a97: '97. Rear cup holders',
    a98: '98. Rear storage compartments',
    a99: '99. Rear window sunshade',
    a100: '100. Rear seat entertainment system',
    a101: '101. First aid kit in centre armrest',
    a102: '102. Centre headrest and release',
    a103: '103. Centre seatbelt',
    a104: '104. Child seat tethers and LATCH',
  };
  const rightRearInterior = {
    a105: '105. Right rear interior trim and door handle',
    a106: '106. Right rear power window/door lock',
    a107: '107. Right rear seat heater and ventilator',
    a108: '108. Right rear seat massaging function',
    a109: '109. Right rear carpet, floor mat and footwell',
    a110: '110. Right rear safety belt',
    a111: '111. Right rear seat cushion and cover',
    a112: '112. Right rear headrest, headrest release and headliner',
    a113: '113. Right rear door lights/reflector',
    a114: '114. Right rear window sunshade',
    a115: '115. Right rear side airbag',
    a116: '116. Right rear headliner assist handle',
  };
  const rightFrontInterior = {
    a117: '117. Right front interior trim and door handle',
    a118: '118. Right front visor/vanity mirror/light',
    a119: '119. Right front power window/door lock',
    a120: '120. Right front seat controls/power/memory function',
    a121: '121. Right front seat heater and ventilator',
    a122: '122. Right front seat massaging function',
    a123: '123. Right front controls/vents',
    a124: '124. Right front dash',
    a125: '125. Right front seat airbag',
    a126: '126. Right curtain airbag',
    a127: '127. Glove box, lock and light',
    a128: '128. Right front carpet, floor mat and footwell',
    a129: '129. Right front safety belt/height adjuster',
    a130: '130. Right front seat cover and cushion',
    a131: '131. Right front headrest, headrest release and headliner',
    a132: '132. Right front door light/reflector',
    a133: '133. Right front headliner assist handle',
  };
  const frontExterior = {
    a134: '134. Front bumper',
    a135: '135. License plate bracket and filler panel',
    a136: '136. Check function of charging port and access panel (L/R) if applicable',
    a137: '137. Parking sensors',
    a138: '138. Front headlight lens',
    a139: '139. Headlamps high/low beam',
    a140: '140. Marker lights, turn signals and daytime running lights',
    a141: '141. Parking and hazard lights',
    a142: '142. Fog/all weather lights',
    a143: '143. Headlamp washer spray heads and covers',
    a144: '144. Audi emblem',
    a145: '145. Grill condition',
    a146: '146. Lower right fascia grill',
    a147: '147. Front tow eye cover',
    a148: '148. Lower centre grill',
    a149: '149. Lower left fascia grill',
    a150: '150. Front hood condition and paint condition',
    a151: '151. Front end alignment of panels (gaps)',
    a152: '152. Windshield glass',
    a153: '153. Windshield wipers',
    a154: '154. Windshield wiper arms',
    a155: '155. Windshield washer spray nozzles',
    a156: '156. Wet arm operation (if equipped)',
    a157: '157. Hood release latch operation',
    a158: '158. Hood struts',
    a159: '159. Audi advanced key',
    a160: '160. Automatic headlamp sensors',
    a161: '161. Check functionality of rain sensors',
    a162: '162. Check functionality of camera sensor',
    a163: '163. Electric door handles interior/exterior',
    a164: '164. Manual emergency release for electric door handles',
    a165: '165. Soft close function of all door locks',
  };
  const leftFrontExterior = {
    a166: '166. Remote entry',
    a167: '167. Left front fender alignment',
    a168: '168. Left front side turn indicator light',
    a169: '169. Left pillars (A and B)',
    a170: '170. VIN plate',
    a171: '171. Left front side mirror housing, glass and flasher',
    a172: '172. Left front door panel alignment and paint',
    a173: '173. Left front body mouldings',
    a174: '174. Left front window glass',
    a175: '175. Left front window trim and mouldings',
    a176: '176. Driver exterior door handle',
    a177: '177. Driver door lock cylinder (auto up/down/lock)',
    a178: '178. Left front door hinge and alignment',
    a179: '179. Left front door seals',
    a180: '180. Left front door sill plate',
    a181: '181. Left front side moulding',
    a182: '182. Left front side rocker panel and trim',
  };
  const leftRearExterior = {
    a183: '183. Left rear door panel alignment and paint',
    a184: '184. Left rear door side moulding',
    a185: '185. Left rear side rocker panel and trim',
    a186: '186. Left rear door window trim and mouldings',
    a187: '187. Left rear door window glass',
    a188: '188. Left rear door handle',
    a189: '189. Left rear child lock',
    a190: '190. Left rear door hinge and alignment',
    a191: '191. Left rear door seals',
    a192: '192. Left rear sill plate',
    a193: '193. Left rear C-pillar',
    a194: '194. Left rear quarter window glass',
    a195: '195. Left rear body panel alignment and paint',
  };
  const rearExterior = {
    a196: '196. Rear end tailgate paint',
    a197: '197. Rear Audi emblem and tailgate trim',
    a198: '198. Rear window glass',
    a199: '199. Rear window defrost element',
    a200: '200. Rear camera',
    a201: '201. Tailgate/trunk hinges, struts and alignment',
    a202: '202. Trunk seal',
    a203: '203. Trunk/luggage compartment light',
    a204: '204. Compartment covers, hold-down latches',
    a205: '205. Roadside kit present',
    a206: '206. Tire Mobility System (TMS) expiration date',
    a207: '207. Removable cargo cover',
    a208: '208. Rear park sensors',
    a209: '209. Rear parking, reverse, fog, all weather, hazard and tail lights',
    a210: '210. Rear marker lamps and turn signals',
    a211: '211. Rear brake lights',
    a212: '212. Rear bumper',
    a213: '213. Rear exterior trunk release',
    a214: '214. License plate bracket and light',
    a215: '215. Rear window wiper',
    a216: '216. High-centre third brake light',
    a217: '217. Rear window wiper arm',
    a218: '218. Rear window washer/spray head',
    a219: '219. Boot cover (cabriolet/roadster)',
    a220: '220. Retractable rear spoiler function',
  };
  const rightRearExterior = {
    a221: '221. Right rear quarter panel alignment and paint',
    a222: '222. Right rear panel trim pieces and mouldings',
    a223: '223. Fuel door, rubber stops and fuel cap',
    a224: '224. Right rear quarter window glass',
    a225: '225. Right C-pillar',
    a226: '226. Right rear side rocker panel and trim',
    a227: '227. Right rear door window trim and mouldings',
    a228: '228. Right rear door window glass',
    a229: '229. Right rear door paint and gap alignment',
    a230: '230. Right rear exterior door handle',
    a231: '231. Right rear child lock',
    a232: '232. Right rear sill plate',
    a233: '233. Right rear door hinge and alignment',
  };
  const rightFrontExterior = {
    a234: '234. Right pillar (A and B)',
    a235: '235. Right front door panel paint and gap alignment',
    a236: '236. Right front door handle',
    a237: '237. Right front door lock',
    a238: '238. Right front door sill plate',
    a239: '239. Right front door hinge and alignment',
    a240: '240. Right front door seals',
    a241: '241. Right front side rocker panel and trim',
    a242: '242. Right front window trim and mouldings',
    a243: '243. Right side mirror housing, glass and flasher',
    a244: '244. Right front side marker light',
    a245: '245. Right front trim pieces and mouldings',
    a246: '246. Right front fender alignment and paint',
  };
  const topExterior = {
    a247: '247. Sunroof glass and seals',
    a248: '248. Roof panel/luggage rack/roof rails',
    a249: '249. Roof antenna/SiriusXM/nav housing',
    a250: '250. Convertible top (if equipped)',
    a251: '251. Windscreen operation',
  };
  const engine = {
    a252: '252. Under hood insulation',
    a253: '253. Engine compartment labels EGR/PCV/high voltage',
    a254: '254. Tire pressure and recommended fuel label',
    a255: '255. Covers: engine compartment/cowl cover/emblems',
    a256: '256. Windshield washer fluid level',
    a257: '257.	Hoses checked for air and fluid leaks',
    a258: '258. Power steering, engine oil',
    a259: '259.	Check for water in the oil',
    a260: '260.	Fuel injection system, fuel filter/lines',
    a261: '261.	Throttle linkage/e-gas',
    a262: '262.	Air filter',
    a263: '263.	Accessory belt',
    a264: '264.	Power steering pump/hoses and fluid level',
    a265: '265.	Oil level and condition',
    a266: '266.	Engine and transmission mounts',
    a267: '267.	Engine and transmission seals and gaskets',
    a268: '268. A/C compressor/lines/condenser',
    a269: '269. Visual leak inspection',
  };
  const cooling = {
    a270: '270. Engine coolant reservoir cap',
    a271: '271. Engine coolant level',
    a272: '272. Cooling system pressure test',
    a273: '273. Radiator hoses upper/lower and heater hoses',
    a274: '274. Cooling fan, cooling fan motor',
    a275: '275. Radiator/thermostat',
    a276: '276. Upper core support, radiator housing',
    a277: '277. Water pump/belt (noise/leaks)',
    a278: '278. Check condition and presence of warning labels specific to the HV cooling system',
    a279: '279. High-voltage system coolant level (see repair manual) if applicable',
    a280: '280. Test coolant for protection temperature (including HV cooling system)',
  };
  const transmission = {
    a281: '281. Transmission system condition',
    a282: '282. Transmission fluid auto/manual',
  };
  const electrical = {
    a283: '283. Visual inspection of 12V battery condition',
    a284: '284. Visual inspection of 12V wiring',
    a285: '285. Visual inspection of high-voltage battery, check condition of	battery casing',
    a286: '286. Visual inspection high-voltage components and cables',
    a287: '287. Check signs of corrosion',
    a288: '288. Visual inspection of wire harnesses for wear and tear',
  };
  const brake = {
    a289: '289. Brake fluid reservoir cap',
    a290: '290. Brake fluid level/condition',
    a291: '291. Brake master cylinder and booster',
    a292: '292. Parking brake',
    a293: '293. Parking brake cable and connection tension',
    a294: '294. Brake calipers and hoses',
    a295: '295. Electro-mechanical brake',
    a296: '296. Brake pads',
  };
  const undercarriage = {
    a297: '297. Bumper flanges and brackets',
    a298: '298. Frame spot welds and seams',
    a299: '299. Sheet metal',
    a300: '300. Water damage',
    a301: '301. Front sub-frames and front side aprons',
    a302: '302. Centre support bearing',
    a303: '303. Brake lines',
    a304: '304.	Bottom of front and rear bumpers',
    a305: '305.	Lower splash shield',
    a306: '306.	Lower engine mounts',
    a307: '307.	Lower radiator hoses',
    a308: '308. Suspension arms and CV joints',
    a309: '309. Adaptive suspension systems',
    a310: '310. Sway bars/links/bushings',
    a311: '311. Struts, shocks and springs',
    a312: '312. Control arms and steering rack',
    a313: '313. Check visual condition of HV components (battery and cables)',
  };
  const exhaust = {
    a314: '314. Hangers and clamps',
    a315: '315.	Exhaust pipes, catalytic converter and heat shield',
    a316: '316. Check for system leaks',
    a317: '317.	Rear axle fluid level',
  };
  const differentials = {
    a318: '318. Engine/transmission/differentials/transaxle seals and gaskets',
    a319: '319. Transmission lines',
    a320: '320. Axle housing/final drive',
    a321: '321. Transmission/mounts/control linkages',
    a322: '322. Differential/Torsen/quattro®',
  };
  const tires = {
    a323: '323.	Wheels: run out/curb damage/finish/correct match/spare tire',
    a324: '324.	Tires: correct size/match/damage/Audi approved',
    a325: '325.	Tire tread depth and pressure (L/R)',
    a326: '326.	Speed and load rating',
  };
  const fuel = {
    a328: '328. Fuel tank and holding straps',
    a329: '329.	Fuel line',
    a330: '330.	Fuel filter',
  };
  const charging = {
    a331: '331. Charging capsule (check for updates)',
    a332: '332. Check charging functionality of 120V cable',
    a333: '333. Check charging functionality of 240V cable',
    a334: '334.	Wall clip brackets check if available',
    a335: '335.	Ability to charge to a minimum of 80%',
    a336: '336. Reset charging capacity in MMI to the recommended owners manual value',
    a337: '337. Visual inspection of all potential equalization cables on all EV components',
    a338: '338. Visual inspection ofTW High Voltage maintenance disconnect de-energize, re-energize connector, warning labels',
    a339: '339. High-voltage safety locks/warning labels/emissions/air conditioning',
    a340: '340. Check HV HVACoperation (monitor measuring value blocks for cooling circuit temperatures during operation)',
  };

  let audiObj = cloneDeep(audi);
  let preWorkObj = cloneDeep(preWork);
  let preDeterminationObj = cloneDeep(preDetermination);
  let roadTestObj = cloneDeep(roadTest);
  let frontInteriorObj = cloneDeep(frontInterior);
  let leftFrontInteriorObj = cloneDeep(leftFrontInterior);
  let leftRearInteriorObj = cloneDeep(leftRearInterior);
  let rightRearInteriorObj = cloneDeep(rightRearInterior);
  let rightFrontInteriorObj = cloneDeep(rightFrontInterior);
  let frontExteriorObj = cloneDeep(frontExterior);
  let leftFrontExteriorObj = cloneDeep(leftFrontExterior);
  let leftRearExteriorObj = cloneDeep(leftRearExterior);
  let rearExteriorObj = cloneDeep(rearExterior);
  let rightRearExteriorObj = cloneDeep(rightRearExterior);
  let rightFrontExteriorObj = cloneDeep(rightFrontExterior);
  let topExteriorObj = cloneDeep(topExterior);
  let engineObj = cloneDeep(engine);
  let coolingObj = cloneDeep(cooling);
  let transmissionObj = cloneDeep(transmission);
  let electricalObj = cloneDeep(electrical);
  let brakeObj = cloneDeep(brake);
  let undercarriageObj = cloneDeep(undercarriage);
  let exhaustObj = cloneDeep(exhaust);
  let differentialsObj = cloneDeep(differentials);
  let tiresObj = cloneDeep(tires);
  let fuelObj = cloneDeep(fuel);
  let chargingObj = cloneDeep(charging);

  Object.entries(audiObj).map(([name]) => (audiObj[name] = 'YES'));
  Object.entries(preWorkObj).map(([name]) => (preWorkObj[name] = 'YES'));
  Object.entries(preDeterminationObj).map(
    ([name]) => (preDeterminationObj[name] = 'YES'),
  );
  Object.entries(roadTestObj).map(([name]) => (roadTestObj[name] = 'YES'));
  Object.entries(frontInteriorObj).map(
    ([name]) => (frontInteriorObj[name] = 'YES'),
  );
  Object.entries(leftFrontInteriorObj).map(
    ([name]) => (leftFrontInteriorObj[name] = 'YES'),
  );
  Object.entries(leftRearInteriorObj).map(
    ([name]) => (leftRearInteriorObj[name] = 'YES'),
  );
  Object.entries(rightRearInteriorObj).map(
    ([name]) => (rightRearInteriorObj[name] = 'YES'),
  );
  Object.entries(rightFrontInteriorObj).map(
    ([name]) => (rightFrontInteriorObj[name] = 'YES'),
  );
  Object.entries(frontExteriorObj).map(
    ([name]) => (frontExteriorObj[name] = 'YES'),
  );
  Object.entries(leftFrontExteriorObj).map(
    ([name]) => (leftFrontExteriorObj[name] = 'YES'),
  );
  Object.entries(leftRearExteriorObj).map(
    ([name]) => (leftRearExteriorObj[name] = 'YES'),
  );
  Object.entries(rearExteriorObj).map(
    ([name]) => (rearExteriorObj[name] = 'YES'),
  );
  Object.entries(rightRearExteriorObj).map(
    ([name]) => (rightRearExteriorObj[name] = 'YES'),
  );
  Object.entries(rightFrontExteriorObj).map(
    ([name]) => (rightFrontExteriorObj[name] = 'YES'),
  );
  Object.entries(topExteriorObj).map(
    ([name]) => (topExteriorObj[name] = 'YES'),
  );
  Object.entries(engineObj).map(([name]) => (engineObj[name] = 'YES'));
  Object.entries(coolingObj).map(([name]) => (coolingObj[name] = 'YES'));
  Object.entries(transmissionObj).map(
    ([name]) => (transmissionObj[name] = 'YES'),
  );
  Object.entries(electricalObj).map(([name]) => (electricalObj[name] = 'YES'));
  Object.entries(brakeObj).map(([name]) => (brakeObj[name] = 'YES'));
  Object.entries(undercarriageObj).map(
    ([name]) => (undercarriageObj[name] = 'YES'),
  );
  Object.entries(exhaustObj).map(([name]) => (exhaustObj[name] = 'YES'));
  Object.entries(differentialsObj).map(
    ([name]) => (differentialsObj[name] = 'YES'),
  );
  Object.entries(tiresObj).map(([name]) => (tiresObj[name] = 'YES'));
  Object.entries(fuelObj).map(([name]) => (fuelObj[name] = 'YES'));
  Object.entries(chargingObj).map(([name]) => (chargingObj[name] = 'YES'));

  const [audiValues, setAudiValues] = useState(audiObj);
  const [preWorkValues, setPreWorkValues] = useState(preWorkObj);
  const [preDeterminationValues, setPreDeterminationValues] =
    useState(preDeterminationObj);
  const [roadTestValues, setRoadTestValues] = useState(roadTestObj);
  const [frontInteriorValues, setFrontInteriorValues] =
    useState(frontInteriorObj);
  const [leftFrontInteriorValues, setLeftFrontInteriorValues] =
    useState(leftFrontInteriorObj);
  const [leftRearInteriorValues, setLeftRearInteriorValues] =
    useState(leftRearInteriorObj);
  const [rightRearInteriorValues, setRightRearInteriorValues] =
    useState(rightRearInteriorObj);
  const [rightFrontInteriorValues, setRightFrontInteriorValues] = useState(
    rightFrontInteriorObj,
  );
  const [frontExteriorValues, setFrontExteriorValues] =
    useState(frontExteriorObj);
  const [leftFrontExteriorValues, setLeftFrontExteriorValues] =
    useState(leftFrontExteriorObj);
  const [leftRearExteriorValues, setLeftRearExteriorValues] =
    useState(leftRearExteriorObj);
  const [rearExteriorValues, setRearExteriorValues] = useState(rearExteriorObj);
  const [rightRearExteriorValues, setRightRearExteriorValues] =
    useState(rightRearExteriorObj);
  const [rightFrontExteriorValues, setRightFrontExteriorValues] = useState(
    rightFrontExteriorObj,
  );
  const [topExteriorValues, setTopExteriorValues] = useState(topExteriorObj);
  const [engineValues, setEngineValues] = useState(engineObj);
  const [coolingValues, setCoolingValues] = useState(coolingObj);
  const [transmissionValues, setTransmissionValues] = useState(transmissionObj);
  const [electricalValues, setElectricalValues] = useState(electricalObj);
  const [brakeValues, setBrakeValues] = useState(brakeObj);
  const [undercarriageValues, setUndercarriageValues] =
    useState(undercarriageObj);
  const [exhaustValues, setExhaustValues] = useState(exhaustObj);
  const [differentialsValues, setDifferentialsValues] =
    useState(differentialsObj);
  const [tiresValues, setTiresValues] = useState(tiresObj);
  const [fuelValues, setFuelValues] = useState(fuelObj);
  const [chargingValues, setChargingValues] = useState(chargingObj);

  const handleAudi = e => {
    const { name, value } = e.target;
    setAudiValues(prevAudiValues => ({
      ...prevAudiValues,
      [name]: value,
    }));
  };
  const handlePreWork = e => {
    const { name, value } = e.target;
    setPreWorkValues(prevPreWorkValues => ({
      ...prevPreWorkValues,
      [name]: value,
    }));
  };
  const handlePreDetermination = e => {
    const { name, value } = e.target;
    setPreDeterminationValues(prevPreDeterminationValues => ({
      ...prevPreDeterminationValues,
      [name]: value,
    }));
  };
  const handleRoadTest = e => {
    const { name, value } = e.target;
    setRoadTestValues(prevRoadTestValues => ({
      ...prevRoadTestValues,
      [name]: value,
    }));
  };
  const handleFrontInterior = e => {
    const { name, value } = e.target;
    setFrontInteriorValues(prevFrontInteriorValues => ({
      ...prevFrontInteriorValues,
      [name]: value,
    }));
  };
  const handleLeftFrontInterior = e => {
    const { name, value } = e.target;
    setLeftFrontInteriorValues(prevLeftFrontInteriorValues => ({
      ...prevLeftFrontInteriorValues,
      [name]: value,
    }));
  };
  const handleLeftRearInterior = e => {
    const { name, value } = e.target;
    setLeftRearInteriorValues(prevLeftRearInteriorValues => ({
      ...prevLeftRearInteriorValues,
      [name]: value,
    }));
  };
  const handleRightRearInterior = e => {
    const { name, value } = e.target;
    setRightRearInteriorValues(prevRightRearInteriorValues => ({
      ...prevRightRearInteriorValues,
      [name]: value,
    }));
  };
  const handleRightFrontInterior = e => {
    const { name, value } = e.target;
    setRightFrontInteriorValues(prevRightFrontInteriorValues => ({
      ...prevRightFrontInteriorValues,
      [name]: value,
    }));
  };
  const handleFrontExterior = e => {
    const { name, value } = e.target;
    setFrontExteriorValues(prevFrontExteriorValues => ({
      ...prevFrontExteriorValues,
      [name]: value,
    }));
  };
  const handleLeftFrontExterior = e => {
    const { name, value } = e.target;
    setLeftFrontExteriorValues(prevLeftFrontExteriorValues => ({
      ...prevLeftFrontExteriorValues,
      [name]: value,
    }));
  };
  const handleLeftRearExterior = e => {
    const { name, value } = e.target;
    setLeftRearExteriorValues(prevLeftRearExteriorValues => ({
      ...prevLeftRearExteriorValues,
      [name]: value,
    }));
  };
  const handleRearExterior = e => {
    const { name, value } = e.target;
    setRearExteriorValues(prevRearExteriorValues => ({
      ...prevRearExteriorValues,
      [name]: value,
    }));
  };
  const handleRightRearExterior = e => {
    const { name, value } = e.target;
    setRightRearExteriorValues(prevRightRearExteriorValues => ({
      ...prevRightRearExteriorValues,
      [name]: value,
    }));
  };
  const handleRightFrontExterior = e => {
    const { name, value } = e.target;
    setRightFrontExteriorValues(prevRightFrontExteriorValues => ({
      ...prevRightFrontExteriorValues,
      [name]: value,
    }));
  };
  const handleTopExterior = e => {
    const { name, value } = e.target;
    setTopExteriorValues(prevTopExteriorValues => ({
      ...prevTopExteriorValues,
      [name]: value,
    }));
  };
  const handleEngine = e => {
    const { name, value } = e.target;
    setEngineValues(prevEngineValues => ({
      ...prevEngineValues,
      [name]: value,
    }));
  };
  const handleCooling = e => {
    const { name, value } = e.target;
    setCoolingValues(prevCoolingValues => ({
      ...prevCoolingValues,
      [name]: value,
    }));
  };
  const handleTransmission = e => {
    const { name, value } = e.target;
    setTransmissionValues(prevTransmissionValues => ({
      ...prevTransmissionValues,
      [name]: value,
    }));
  };
  const handleElectrical = e => {
    const { name, value } = e.target;
    setElectricalValues(prevElectricalValues => ({
      ...prevElectricalValues,
      [name]: value,
    }));
  };
  const handleBrake = e => {
    const { name, value } = e.target;
    setBrakeValues(prevBrakeValues => ({
      ...prevBrakeValues,
      [name]: value,
    }));
  };
  const handleUndercarriage = e => {
    const { name, value } = e.target;
    setUndercarriageValues(prevUndercarriageValues => ({
      ...prevUndercarriageValues,
      [name]: value,
    }));
  };
  const handleExhaust = e => {
    const { name, value } = e.target;
    setExhaustValues(prevExhaustValues => ({
      ...prevExhaustValues,
      [name]: value,
    }));
  };
  const handleDifferentials = e => {
    const { name, value } = e.target;
    setDifferentialsValues(prevDifferentialsValues => ({
      ...prevDifferentialsValues,
      [name]: value,
    }));
  };
  const handleTires = e => {
    const { name, value } = e.target;
    setTiresValues(prevTiresValues => ({
      ...prevTiresValues,
      [name]: value,
    }));
  };
  const handleFuel = e => {
    const { name, value } = e.target;
    setFuelValues(prevFuelValues => ({
      ...prevFuelValues,
      [name]: value,
    }));
  };
  const handleCharging = e => {
    const { name, value } = e.target;
    setChargingValues(prevChargingValues => ({
      ...prevChargingValues,
      [name]: value,
    }));
  };

  const [consent, setConsent] = useState(false);
  const handleConsent = () => {
    setConsent(consent => !consent);
  };

  const [confirm, setConfirm] = useState(false);
  const handleConfirm = () => {
    setConfirm(confirm => !confirm);
  };

  const wholeForm = useRef(null);

  const handleSubmit = e => {
    e.preventDefault();

    if (!wholeForm.current['amvic_number'].value) {
      enqueueSnackbar(`Please fill out 'Tech #'.`);
      return;
    }

    if (!consent) {
      enqueueSnackbar(`Please check 'I agree' and then submit the inspection!`);
      return;
    }

    audiValues['carfax'] = wholeForm.current['carfax'].value;
    brakeValues['pads_fl'] = wholeForm.current['pads_fl'].value;
    brakeValues['pads_rl'] = wholeForm.current['pads_rl'].value;
    brakeValues['pads_rr'] = wholeForm.current['pads_rr'].value;
    brakeValues['pads_fr'] = wholeForm.current['pads_fr'].value;
    brakeValues['rotor_fl'] = wholeForm.current['rotor_fl'].value;
    brakeValues['rotor_rl'] = wholeForm.current['rotor_rl'].value;
    brakeValues['rotor_rr'] = wholeForm.current['rotor_rr'].value;
    brakeValues['rotor_fr'] = wholeForm.current['rotor_fr'].value;
    tiresValues['dot_1'] = wholeForm.current['dot_1'].value;
    tiresValues['dot_2'] = wholeForm.current['dot_2'].value;
    tiresValues['dot_3'] = wholeForm.current['dot_3'].value;
    tiresValues['dot_4'] = wholeForm.current['dot_4'].value;
    tiresValues['inner_lf'] = wholeForm.current['inner_lf'].value;
    tiresValues['middle_lf'] = wholeForm.current['middle_lf'].value;
    tiresValues['outer_lf'] = wholeForm.current['outer_lf'].value;
    tiresValues['pressure_lf'] = wholeForm.current['pressure_lf'].value;
    tiresValues['inner_lr'] = wholeForm.current['inner_lr'].value;
    tiresValues['middle_lr'] = wholeForm.current['middle_lr'].value;
    tiresValues['outer_lr'] = wholeForm.current['outer_lr'].value;
    tiresValues['pressure_lr'] = wholeForm.current['pressure_lr'].value;
    tiresValues['inner_rr'] = wholeForm.current['inner_rr'].value;
    tiresValues['middle_rr'] = wholeForm.current['middle_rr'].value;
    tiresValues['outer_rr'] = wholeForm.current['outer_rr'].value;
    tiresValues['pressure_rr'] = wholeForm.current['pressure_rr'].value;
    tiresValues['inner_rf'] = wholeForm.current['inner_rf'].value;
    tiresValues['middle_rf'] = wholeForm.current['middle_rf'].value;
    tiresValues['outer_rf'] = wholeForm.current['outer_rf'].value;
    tiresValues['pressure_rf'] = wholeForm.current['pressure_rf'].value;

    const values = {
      ...audiValues,
      ...preWorkValues,
      ...preDeterminationValues,
      ...roadTestValues,
      ...frontInteriorValues,
      ...leftFrontInteriorValues,
      ...leftRearInteriorValues,
      ...rightRearInteriorValues,
      ...rightFrontInteriorValues,
      ...frontExteriorValues,
      ...leftFrontExteriorValues,
      ...leftRearExteriorValues,
      ...rearExteriorValues,
      ...rightRearExteriorValues,
      ...rightFrontExteriorValues,
      ...topExteriorValues,
      ...engineValues,
      ...coolingValues,
      ...transmissionValues,
      ...electricalValues,
      ...brakeValues,
      ...undercarriageValues,
      ...exhaustValues,
      ...differentialsValues,
      ...tiresValues,
      ...fuelValues,
      ...chargingValues,
    };
    const form_data = {
      audi: values,
      technician_comments: wholeForm.current['technician_comments'].value,
      amvic_number: wholeForm.current['amvic_number'].value,
    };

    props.formData(form_data);
    props.formClose();
  };

  const GreenButton = withStyles({
    root: {
      color: '#fff',
      backgroundColor: '#198754',
      '&:hover': {
        backgroundColor: '#157347',
      },
    },
  })(Button);

  return (
    <form onSubmit={handleSubmit} ref={wholeForm}>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>1.1 Audi Achtungs (Stops!)</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(audi).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid
                    item
                    xs={9}
                    style={{ paddingLeft: '1rem', paddingBottom: '.5rem' }}
                  >
                    <Typography>{display_name}</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <InspectionCheckboxes
                      valueGroup={audiValues}
                      handleGroup={handleAudi}
                      name={name}
                      orange={false}
                      greenValue="YES"
                      redValue="NO"
                    />
                  </Grid>
                  {name === 'a1' && (
                    <Grid
                      item
                      xs={12}
                      style={{ paddingLeft: '1rem', paddingBottom: '1rem' }}
                    >
                      <TextField label="CARFAX" name={'carfax'} />
                    </Grid>
                  )}
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>1.2 Pre-work</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(preWork).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid
                    item
                    xs={9}
                    style={{ paddingLeft: '1rem', paddingBottom: '.5rem' }}
                  >
                    <Typography>{display_name}</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <InspectionCheckboxes
                      valueGroup={preWorkValues}
                      handleGroup={handlePreWork}
                      name={name}
                      orange={false}
                      greenValue="YES"
                      redValue="NO"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>1.3 Pre-determination</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(preDetermination).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid
                    item
                    xs={9}
                    style={{ paddingLeft: '1rem', paddingBottom: '.5rem' }}
                  >
                    <Typography>{display_name}</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <InspectionCheckboxes
                      valueGroup={preDeterminationValues}
                      handleGroup={handlePreDetermination}
                      name={name}
                      orange={false}
                      greenValue="YES"
                      redValue="NO"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Typography>
        <b>2.0 Road Test</b>
      </Typography>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>2.1 Engine, drivetrain and operation</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(roadTest).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    <Typography>{display_name}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={roadTestValues}
                      handleGroup={handleRoadTest}
                      name={name}
                      white
                      greenValue="YES"
                      redValue="NO"
                      orangeValue="REPAIRABLE"
                      redAtSecond
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Typography>
        <b>3.0 Interior of Vehicle</b>
      </Typography>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>3.1 Front Interior</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(frontInterior).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    <Typography>{display_name}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={frontInteriorValues}
                      handleGroup={handleFrontInterior}
                      name={name}
                      white
                      greenValue="YES"
                      redValue="NO"
                      orangeValue="REPAIRABLE"
                      redAtSecond
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>3.2 Left Front Interior</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(leftFrontInterior).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    <Typography>{display_name}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={leftFrontInteriorValues}
                      handleGroup={handleLeftFrontInterior}
                      name={name}
                      white
                      greenValue="YES"
                      redValue="NO"
                      orangeValue="REPAIRABLE"
                      redAtSecond
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>3.3 Left Rear Interior</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(leftRearInterior).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    <Typography>{display_name}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={leftRearInteriorValues}
                      handleGroup={handleLeftRearInterior}
                      name={name}
                      white
                      greenValue="YES"
                      redValue="NO"
                      orangeValue="REPAIRABLE"
                      redAtSecond
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>3.4 Right Rear Interior</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(rightRearInterior).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    <Typography>{display_name}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={rightRearInteriorValues}
                      handleGroup={handleRightRearInterior}
                      name={name}
                      white
                      greenValue="YES"
                      redValue="NO"
                      orangeValue="REPAIRABLE"
                      redAtSecond
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>3.5 Right Front Interior</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(rightFrontInterior).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    <Typography>{display_name}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={rightFrontInteriorValues}
                      handleGroup={handleRightFrontInterior}
                      name={name}
                      white
                      greenValue="YES"
                      redValue="NO"
                      orangeValue="REPAIRABLE"
                      redAtSecond
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Typography>
        <b>4.0 Exterior of Vehicle</b>
      </Typography>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>4.1 Front Exterior</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(frontExterior).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    <Typography>{display_name}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={frontExteriorValues}
                      handleGroup={handleFrontExterior}
                      name={name}
                      white
                      greenValue="YES"
                      redValue="NO"
                      orangeValue="REPAIRABLE"
                      redAtSecond
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>4.2 Left Front Exterior</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(leftFrontExterior).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    <Typography>{display_name}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={leftFrontExteriorValues}
                      handleGroup={handleLeftFrontExterior}
                      name={name}
                      white
                      greenValue="YES"
                      redValue="NO"
                      orangeValue="REPAIRABLE"
                      redAtSecond
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>4.3 Left Rear Exterior</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(leftRearExterior).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    <Typography>{display_name}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={leftRearExteriorValues}
                      handleGroup={handleLeftRearExterior}
                      name={name}
                      white
                      greenValue="YES"
                      redValue="NO"
                      orangeValue="REPAIRABLE"
                      redAtSecond
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>4.4 Rear Exterior</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(rearExterior).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    <Typography>{display_name}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={rearExteriorValues}
                      handleGroup={handleRearExterior}
                      name={name}
                      white
                      greenValue="YES"
                      redValue="NO"
                      orangeValue="REPAIRABLE"
                      redAtSecond
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>4.5 Right Rear Exterior</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(rightRearExterior).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    <Typography>{display_name}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={rightRearExteriorValues}
                      handleGroup={handleRightRearExterior}
                      name={name}
                      white
                      greenValue="YES"
                      redValue="NO"
                      orangeValue="REPAIRABLE"
                      redAtSecond
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>4.6 Right Front Exterior</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(rightFrontExterior).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    <Typography>{display_name}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={rightFrontExteriorValues}
                      handleGroup={handleRightFrontExterior}
                      name={name}
                      white
                      greenValue="YES"
                      redValue="NO"
                      orangeValue="REPAIRABLE"
                      redAtSecond
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>4.7 Top Exterior</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(topExterior).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    <Typography>{display_name}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={topExteriorValues}
                      handleGroup={handleTopExterior}
                      name={name}
                      white
                      greenValue="YES"
                      redValue="NO"
                      orangeValue="REPAIRABLE"
                      redAtSecond
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Typography>
        <b>5.0 Mechanical Components</b>
      </Typography>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>5.1 Engine</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(engine).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    <Typography>{display_name}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={engineValues}
                      handleGroup={handleEngine}
                      name={name}
                      white
                      greenValue="YES"
                      redValue="NO"
                      orangeValue="REPAIRABLE"
                      redAtSecond
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>5.2 Cooling System</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(cooling).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    <Typography>{display_name}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={coolingValues}
                      handleGroup={handleCooling}
                      name={name}
                      white
                      greenValue="YES"
                      redValue="NO"
                      orangeValue="REPAIRABLE"
                      redAtSecond
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>5.3 Transmission</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(transmission).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    <Typography>{display_name}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={transmissionValues}
                      handleGroup={handleTransmission}
                      name={name}
                      white
                      greenValue="YES"
                      redValue="NO"
                      orangeValue="REPAIRABLE"
                      redAtSecond
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>5.4 Electrical System</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(electrical).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    <Typography>{display_name}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={electricalValues}
                      handleGroup={handleElectrical}
                      name={name}
                      white
                      greenValue="YES"
                      redValue="NO"
                      orangeValue="REPAIRABLE"
                      redAtSecond
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>5.5 Brake System</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(brake).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    <Typography>{display_name}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={brakeValues}
                      handleGroup={handleBrake}
                      name={name}
                      white
                      greenValue="YES"
                      redValue="NO"
                      orangeValue="REPAIRABLE"
                      redAtSecond
                    />
                  </Grid>
                  {name === 'a296' && (
                    <Grid
                      container
                      alignItems="center"
                      style={{ marginBottom: '1rem' }}
                    >
                      <Grid
                        item
                        xs={12}
                        style={{ paddingLeft: '1rem', paddingTop: '1rem' }}
                      >
                        <Typography>Pad thickness 50%: </Typography>
                      </Grid>
                      <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                        <TextField label="Front left (mm)" name={'pads_fl'} />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField label="Rear left (mm)" name={'pads_rl'} />
                      </Grid>
                      <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                        <TextField label="Rear right (mm)" name={'pads_rr'} />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField label="Front right (mm)" name={'pads_fr'} />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{ paddingLeft: '1rem', paddingTop: '1rem' }}
                      >
                        <Typography>Rotor width: </Typography>
                      </Grid>
                      <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                        <TextField label="Front left (mm)" name={'rotor_fl'} />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField label="Rear left (mm)" name={'rotor_rl'} />
                      </Grid>
                      <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                        <TextField label="Rear right (mm)" name={'rotor_rr'} />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField label="Front right (mm)" name={'rotor_fr'} />
                      </Grid>
                    </Grid>
                  )}
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Typography>
        <b>6.0 Under the Vehicle</b>
      </Typography>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>6.1 Undercarriage</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(undercarriage).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    <Typography>{display_name}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={undercarriageValues}
                      handleGroup={handleUndercarriage}
                      name={name}
                      white
                      greenValue="YES"
                      redValue="NO"
                      orangeValue="REPAIRABLE"
                      redAtSecond
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>6.2 Exhaust System </b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(exhaust).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    <Typography>{display_name}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={exhaustValues}
                      handleGroup={handleExhaust}
                      name={name}
                      white
                      greenValue="YES"
                      redValue="NO"
                      orangeValue="REPAIRABLE"
                      redAtSecond
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>
              6.3 Transmission / Differentials / Transaxle Seals and Gaskets
            </b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(differentials).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    <Typography>{display_name}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={differentialsValues}
                      handleGroup={handleDifferentials}
                      name={name}
                      white
                      greenValue="YES"
                      redValue="NO"
                      orangeValue="REPAIRABLE"
                      redAtSecond
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>6.4 Tires and Wheels</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(tires).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    <Typography>{display_name}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={tiresValues}
                      handleGroup={handleTires}
                      name={name}
                      white
                      greenValue="YES"
                      redValue="NO"
                      orangeValue="REPAIRABLE"
                      redAtSecond
                    />
                  </Grid>
                  {name === 'a326' && (
                    <Grid
                      container
                      alignItems="center"
                      style={{ marginBottom: '1rem' }}
                    >
                      <Grid item xs={3} style={{ paddingLeft: '1rem' }}>
                        <TextField label="Dot #" name={'dot_1'} />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField label="Dot #" name={'dot_2'} />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField label="Dot #" name={'dot_3'} />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField label="Dot #" name={'dot_4'} />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{ paddingLeft: '1rem', paddingTop: '1rem' }}
                      >
                        <Typography>
                          <b>Thread Depth</b>
                        </Typography>
                        <Typography>Left front tire: </Typography>
                      </Grid>
                      <Grid item xs={3} style={{ paddingLeft: '1rem' }}>
                        <TextField label="Inner (mm)" name={'inner_lf'} />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField label="Middle (mm)" name={'middle_lf'} />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField label="Outer (mm)" name={'outer_lf'} />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          label="Tire Pressure (PSI)"
                          name={'pressure_lf'}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{ paddingLeft: '1rem', paddingTop: '1rem' }}
                      >
                        <Typography>Left rear tire: </Typography>
                      </Grid>
                      <Grid item xs={3} style={{ paddingLeft: '1rem' }}>
                        <TextField label="Inner (mm)" name={'inner_lr'} />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField label="Middle (mm)" name={'middle_lr'} />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField label="Outer (mm)" name={'outer_lr'} />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          label="Tire Pressure (PSI)"
                          name={'pressure_lr'}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{ paddingLeft: '1rem', paddingTop: '1rem' }}
                      >
                        <Typography>Right rear tire: </Typography>
                      </Grid>
                      <Grid item xs={3} style={{ paddingLeft: '1rem' }}>
                        <TextField label="Inner (mm)" name={'inner_rr'} />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField label="Middle (mm)" name={'middle_rr'} />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField label="Outer (mm)" name={'outer_rr'} />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          label="Tire Pressure (PSI)"
                          name={'pressure_rr'}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{ paddingLeft: '1rem', paddingTop: '1rem' }}
                      >
                        <Typography>Right front tire: </Typography>
                      </Grid>
                      <Grid item xs={3} style={{ paddingLeft: '1rem' }}>
                        <TextField label="Inner (mm)" name={'inner_rf'} />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField label="Middle (mm)" name={'middle_rf'} />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField label="Outer (mm)" name={'outer_rf'} />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          label="Tire Pressure (PSI)"
                          name={'pressure_rf'}
                        />
                      </Grid>
                    </Grid>
                  )}
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>6.5 Fuel Tank and Lines</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(fuel).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    <Typography>{display_name}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={fuelValues}
                      handleGroup={handleFuel}
                      name={name}
                      white
                      greenValue="YES"
                      redValue="NO"
                      orangeValue="REPAIRABLE"
                      redAtSecond
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Typography>
        <b>7.0 PHEV Specific (e.g. A3 e-tron, QS TFSI e)</b>
      </Typography>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>7.1 Charging System</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(charging).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    <Typography>{display_name}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={chargingValues}
                      handleGroup={handleCharging}
                      name={name}
                      white
                      greenValue="YES"
                      redValue="NO"
                      orangeValue="REPAIRABLE"
                      redAtSecond
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>

      <TextField
        label="Technician Comments"
        multiline
        minRows={2}
        variant="outlined"
        name={'technician_comments'}
        fullWidth
        style={{ marginTop: '15px' }}
      />
      <Typography style={{ marginTop: '15px' }}>
        All items checked have been thoroughly inspected and repaired by a
        registered Audi technician with genuine Audi approved parts.
      </Typography>
      <FormControlLabel
        control={<Checkbox />}
        label="I agree"
        onChange={handleConsent}
        value={consent}
      />
      <br />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Technician Name"
            value={props.user.display_name}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Tech #"
            value={props.user.goUserProfile.profile.sales_licence}
            fullWidth
            name={'amvic_number'}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Date"
            value={new Date().toLocaleString()}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <Button
            type="button"
            variant="contained"
            style={{ marginRight: '15px' }}
            onClick={handleConfirm}
            color="default"
          >
            Cancel
          </Button>
          <Dialog
            open={confirm}
            onClose={handleConfirm}
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Current inspection will be closed and unsaved data will be gone.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleConfirm}>Cancel</Button>
              <Button onClick={props.formClose} color="secondary">
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
          <GreenButton type="submit" variant="contained">
            Save
          </GreenButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default AudiCPOForm;
