/* external */
import gql from 'graphql-tag';
import React from 'react';

/* material-ui */
import { Box, Paper } from '@mui/material';

/* internal */
import { PricingBox } from '../common';

import PanelTitle from './PanelTitle';

const EditPricing = ({ vehicle }) => {
  return (
    <Paper style={{ marginBottom: '30px' }}>
      <Box>
        <PanelTitle title="Pricing" vehicle={vehicle} />
      </Box>
      <PricingBox vehicle={vehicle} showOnWebsite />
    </Paper>
  );
};

EditPricing.fragments = {
  vehicle: gql`
    fragment NpvVehiclePricing on NpvVehicle {
      id
      ...NpvVehiclePanelTitle
      ...NpvVehiclePricingBox
    }
    ${PanelTitle.fragments.vehicle}
    ${PricingBox.fragments.vehicle}
  `,
};

export default EditPricing;
