import React, { useState } from 'react';

/* external */
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';

/* Material UI */
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material';

/* internal */
import { NoteVisibility } from '../../../constants';

const REVIVE_OPPORTUNITY = gql`
  mutation reviveOpportunity(
    $_id: ID!
    $input: AddNoteToOpportunityInput!
    $note: String!
  ) {
    reviveOpportunity(_id: $_id, note: $note) {
      _id
    }
    addNoteOnOpportunity(_id: $_id, input: $input) {
      _id
    }
  }
`;

const ReviveOpportunityModal = ({ opportunity, refetch, onClose, isOpen }) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [note, setNote] = useState('');
  const [reviveOpportunity] = useMutation(REVIVE_OPPORTUNITY, {
    onCompleted: () => {
      refetch();
      enqueueSnackbar('Opportunity Revived!', { variant: 'success' });
      onClose();
    },
    onError: err => {
      enqueueSnackbar('Opportunity could not be revived.', {
        variant: 'error',
      });
    },
  });

  const handleNote = event => {
    setNote(event.target.value);
  };

  const handleSubmit = () => {
    const noteData = {
      body: note,
      visibility: NoteVisibility.STORE_ONLY,
    };
    if (!noteData.body) {
      enqueueSnackbar('A note must be added when reviving an opportunity.', {
        variant: 'error',
      });
    } else {
      reviveOpportunity({
        variables: {
          _id: opportunity._id,
          input: noteData,
          note: noteData.body,
        },
      });
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Revive Opportunity</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Reviving the opportunity will open it in the last status it was in
          before it was lost.
        </DialogContentText>
        <TextField
          label="Add a Note"
          value={note}
          onChange={handleNote}
          multiline
          maxRows={4}
          style={{ width: '100%' }}
        ></TextField>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="outlined">
          Cancel
        </Button>
        <Button
          style={theme.actions.confirm}
          onClick={handleSubmit}
          variant="contained"
          color="primary"
        >
          Revive
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReviveOpportunityModal;
