import React from 'react';

/* external */
import gql from 'graphql-tag';

/* Material UI */
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';

/* internal */
import { Countdown } from 'components/MaterialUI/TimeSyncContext';

const useStyles = makeStyles(theme => ({
  capitalize: {
    textTransform: 'capitalize',
  },
}));

const AuctionStatusCountdown = ({ auction }) => {
  const classes = useStyles();

  const { statusText, canAdd, canBlindBid, canBid, isWeekly, active, weekly } =
    auction ?? {};

  const { lastEnd, nextBlind, nextAdd, nextStart } = weekly ?? {};

  return (
    <Typography className={classes.capitalize} variant="subtitle1">
      {statusText && (isWeekly || !active) && <>Status: {statusText}</>}
      {isWeekly && active && (
        <>
          {canAdd && <Countdown endTime={nextBlind} runningPrefix="Blind in" />}
          {canBlindBid && (
            <Countdown endTime={nextStart} runningPrefix="Bid in" />
          )}
          {canBid && <Countdown endTime={lastEnd} runningPrefix="Ends in" />}
          {!canAdd && !canBlindBid && !canBid && (
            <Countdown endTime={nextAdd} runningPrefix="Add items in" />
          )}
        </>
      )}
    </Typography>
  );
};

AuctionStatusCountdown.fragments = {
  auction: gql`
    fragment AuctionStatusCountdownAuction on Auction {
      statusText
      canAdd
      canBid
      canBlindBid
      active
      activeItemsCount
      weekly {
        lastEnd
        nextBlind
        nextAdd
        nextStart
      }
      isWeekly
    }
  `,
};

export default AuctionStatusCountdown;
