import React from 'react';

import gql from 'graphql-tag';
import moment from 'moment';

import { useMediaQuery } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import DateRangeIcon from '@mui/icons-material/DateRange';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { defaultTZ } from 'utils';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '250px',
  },
  rootMedium: {
    minHeight: '100px',
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  text: {
    fontSize: 14,
  },
  listItemAvatar: {
    minWidth: 40,
  },
}));
const AppointmentList = ({ appointments }) => {
  const desktop = useMediaQuery(theme => theme.breakpoints.up('sm'));

  return (
    <List
      style={{
        width: '100%',
        position: 'relative',
        overflow: 'overlay',
        maxHeight: 200,
        paddingTop: desktop ? 10 : 0,
      }}
    >
      {appointments.length === 0 &&
        'There are no appointments for this customer.'}
      {appointments.map(appointment => (
        <ListItem key={appointment._id}>
          <ListItemAvatar>
            <DateRangeIcon />
          </ListItemAvatar>
          <ListItemText
            primaryTypographyProps={{
              style: { fontSize: 14 },
            }}
            secondaryTypographyProps={{
              style: { fontSize: 14 },
            }}
            primary={`${appointment.type_name} @ ${appointment.dealer.dealer_name}`}
            secondary={moment(appointment.start_date)
              .tz(defaultTZ)
              .format('LLL')}
          />
        </ListItem>
      ))}
    </List>
  );
};
const AppointmentCard = ({ customer }) => {
  const classes = useStyles();
  const desktop = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const medium = useMediaQuery(theme => theme.breakpoints.between('sm', 'lg'));
  const appointments = customer.appointments
    .filter(
      appointment =>
        appointment.is_upcoming &&
        moment(appointment.start_date).isSameOrBefore(
          moment().tz(defaultTZ).day(30),
        ),
    )
    .sort((a, b) => moment(a.start_date).isSameOrAfter(b.start_date, 'day'))
    .sort((a, b) => a.id - b.id);

  return (
    <>
      {desktop ? (
        <Card className={medium ? classes.rootMedium : classes.root}>
          <CardContent>
            <Typography className={classes.title} gutterBottom>
              {`Next Appointment${appointments.length > 1 ? 's' : ''}:`}
            </Typography>
            <AppointmentList appointments={appointments} />
          </CardContent>
        </Card>
      ) : (
        <Accordion defaultExpanded={true}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.title} gutterBottom>
              {`Next Appointment${appointments.length > 1 ? 's' : ''}:`}
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{ paddingTop: 0 }}>
            <AppointmentList appointments={appointments} />
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
};

AppointmentCard.fragments = {
  customer: gql`
    fragment AppointmentCardFragment on Customer {
      _id
      appointments {
        _id
        attendees {
          username
          display_name
          profile {
            cell_phone
            desk_phone
            email_address
            first_name
            last_name
            image_id
          }
        }
        created_by_username
        customer_id
        dealer_id
        dealer {
          dealer_name
        }
        is_confirmed
        is_upcoming
        opportunity_id
        start_date
        status
        subject
        type_id
        type_name
        status_name
      }
    }
  `,
};
export default AppointmentCard;
