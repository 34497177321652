/* external */
import { useForm, FormProvider } from 'react-hook-form';
import gql from 'graphql-tag';
import React from 'react';
import moment from 'moment';

/* internal */
import { useDealerContext, LoadingBackdrop } from 'components/MaterialUI';
import { useSnackMutation } from 'utils/useSnackMutation';

import { NO_EXPIRY_DATE, PAYMENT_TYPES } from '../../constants';
import { PricingBox } from '../common';

import StepButtons from './StepButtons';

const CREATE_DETAILED_PRICING = gql`
  mutation createDetailedPricing($data: NpvDetailedPriceInput!) {
    npv {
      createDetailedPricing(data: $data) {
        id
      }
    }
  }
`;

const UnitPricing = ({ back, vehicle, updateNpvVehicle, updateLoading }) => {
  const { dealerId } = useDealerContext();
  const [createDetailedPricing, { loading: createDetailedPricingLoading }] =
    useSnackMutation(
      CREATE_DETAILED_PRICING,
      'Successfully created detailed pricing',
      'Unable to create detailed pricing, please check your input values.',
    );

  const formMethods = useForm({
    defaultValues: {
      dealerId,
      cost: vehicle?.cost ?? 0,
      msrp: vehicle?.msrp ?? 0,
      regularPrice: vehicle?.regularPrice ?? 0,
      defaultDownPayment: vehicle?.defaultDownPayment ?? 0,
    },
  });
  const onSubmit = data => {
    const detailedPricing = data.detailedPricing.map(
      ({ expiry, noExpiry, paymentType, showOnWebsite, ...rest }) => ({
        ...rest,
        expiry: noExpiry ? NO_EXPIRY_DATE : moment(expiry).format('YYYY-MM-DD'),
        allowCash:
          paymentType === PAYMENT_TYPES.BOTH ||
          paymentType === PAYMENT_TYPES.CASH,
        allowFinance:
          paymentType === PAYMENT_TYPES.BOTH ||
          paymentType === PAYMENT_TYPES.FINANCE,
        showOnWebsite: showOnWebsite === 'YES',
      }),
    );

    Promise.all(
      detailedPricing.map(detailedPricing =>
        createDetailedPricing({
          variables: {
            data: {
              vehicleId: vehicle.id,
              ...detailedPricing,
            },
          },
        }),
      ),
    ).then(() => {
      const { detailedPricing, ...rest } = data;
      const variables = {
        id: vehicle.id,
        data: { ...rest },
      };
      updateNpvVehicle({ variables });
    });
  };

  return (
    <FormProvider {...formMethods}>
      <LoadingBackdrop open={updateLoading || createDetailedPricingLoading} />
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <PricingBox vehicle={vehicle} />
        <StepButtons back={back} />
      </form>
    </FormProvider>
  );
};

UnitPricing.fragments = {
  vehicle: gql`
    fragment NpvVehicleUnitPricing on NpvVehicle {
      id
      ...NpvVehiclePricingBox
    }
    ${PricingBox.fragments.vehicle}
  `,
};

export default UnitPricing;
