import React, { useState, useEffect } from 'react';

// MaterialUI
import {
  Box,
  Fab,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  IconButton,
  InputAdornment,
  useMediaQuery,
  TextField,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import FilterListIcon from '@mui/icons-material/FilterList';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';

// internal
import { defaultTZ } from 'utils';
import Loading from 'components/MaterialUI/Loading';
import FilterDrawer from '../components/FilterDrawer';
import FilterChips from '../components/FilterChips';
import { filtersFromFacets } from '../utils';
import { useUserContext } from 'components/MaterialUI/UserContext';

// external
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import moment from 'moment-timezone';
import { Waypoint } from 'react-waypoint';
import { NetworkStatus } from 'apollo-boost';
import SellMyRideTableRow from './SellMyRideTableRow';

const VEHICLE_QUERY = gql`
  query VehicleQuery(
    $filters: [QueryFilter]
    $page: Int
    $pageSize: Int
    $sort: [QuerySortElement]
  ) {
    sellMyRide {
      vehicles(
        filters: $filters
        page: $page
        pageSize: $pageSize
        sort: $sort
      ) {
        results {
          id
          acceptedBid {
            id
            customerAcceptedDate
          }
          appointment {
            id
            date
            appointmentSlot {
              id
              name
              startsAt
              endsAt
              dayOfWeek
              location {
                id
                name
              }
            }
            notes
          }
          attachments {
            id
            attachmentType
            attachmentUrl
            createdAt
          }
          biddingStartsAt
          biddingEndsAt
          bids {
            id
            amount
            createdAt
            customerAcceptedDate
            customerDeclinedDate
            isCustomerAccepted
            isCustomerDeclined
            createdByUser {
              display_name
            }
          }
          bidResponseDueAt
          cbbVid
          createdAt
          customer {
            id
            firstName
            lastName
            phoneNumber
            email
            address
          }
          declinedAt
          declineReason
          disclosure {
            beenInAccident
            hasBeenSmokedIn
            hasExistingInteriorDamage
            hasExistingExteriorDamage
            hasWindshieldDamage
            isFinanced
            isLeased
            isOwned
            numberOfKeys
          }
          hasRegistration
          hasLicence
          isPaveSessionComplete
          make
          model
          modifiedAt
          myBid {
            id
            amount
            createdAt
            customerAcceptedDate
            customerDeclinedDate
            isCustomerAccepted
            isCustomerDeclined
          }
          odometer
          paveSessionId
          photos {
            id
            cloudinaryPublicId
          }
          pickupDate
          status
          trim
          trimVariation
          vin
          winningBidAmount
          winningBidCreatedAt
          year
        }
        pagination {
          total
          page
          nextPage
          totalPages
        }
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  roomForFABs: {
    paddingBottom: `${48 + 10 + 10}px`,
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  mobileWidth: {
    paddingRight: '5px',
    width: '100%',
  },
  desktopWidth: {
    paddingRight: '5px',
    width: '400px',
  },
}));

const headCells = [
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'vehicle',
    numeric: false,
    disablePadding: true,
    label: 'Vehicle',
  },
  {
    id: 'createdAt',
    numeric: false,
    sortable: true,
    disablePadding: true,
    label: 'Submitted',
  },
  {
    id: 'biddingStartsAt',
    sortable: true,
    numeric: false,
    disablePadding: false,
    label: 'Bidding Starts',
  },
  {
    id: 'biddingEndsAt',
    sortable: true,
    numeric: false,
    disablePadding: false,
    label: 'Bidding Ends',
  },
  {
    id: 'highestBid',
    sortable: false,
    numeric: false,
    disablePadding: false,
    label: 'Highest Bid',
  },
  {
    id: 'customer_response',
    numeric: false,
    disablePadding: false,
    label: 'Customer Response',
  },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              disabled={!headCell.sortable}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const SellMyRideListView = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const classes = useStyles();
  const desktop = useMediaQuery(data => data.breakpoints.up('sm'));
  const { currentUser } = useUserContext();
  const timezone = currentUser?.goUserProfile?.settings?.timezone || defaultTZ;
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('createdAt');
  const [searchKeywords, setSearchKeywords] = React.useState('');
  const [searchFilters, setSearchFilters] = useState([]);
  const [facetFilters, setFacetFilters] = useState([]);
  const [biddingStartsAtFilter, setBiddingStartsAtFilter] = useState(null);
  const [biddingEndsAtFilter, setBiddingEndsAtFilter] = useState(null);
  const [sort, setSort] = useState([
    { model: 'Vehicle', field: 'createdAt', direction: 'desc' },
  ]);
  const dateFilters = [];
  const otherFacets = [];

  if (biddingStartsAtFilter) {
    dateFilters.push({
      model: 'Vehicle',
      field: 'bidding_starts_at',
      op: '>=',
      value: moment(biddingStartsAtFilter)
        .startOf('day')
        .tz(timezone)
        .toISOString(),
    });
    dateFilters.push({
      model: 'Vehicle',
      field: 'bidding_starts_at',
      op: '<=',
      value: moment(biddingStartsAtFilter)
        .endOf('day')
        .tz(timezone)
        .toISOString(),
    });
    otherFacets.push({
      label: `= ${moment(biddingStartsAtFilter).format('YYYY-MM-DD')}`,
      onDelete: () => setBiddingStartsAtFilter(null),
    });
  }

  if (biddingEndsAtFilter) {
    dateFilters.push({
      model: 'Vehicle',
      field: 'bidding_ends_at',
      op: '>=',
      value: moment(biddingEndsAtFilter)
        .startOf('day')
        .tz(timezone)
        .toISOString(),
    });
    dateFilters.push({
      model: 'Vehicle',
      field: 'bidding_ends_at',
      op: '<=',
      value: moment(biddingEndsAtFilter)
        .endOf('day')
        .tz(timezone)
        .toISOString(),
    });
    otherFacets.push({
      label: `= ${moment(biddingEndsAtFilter).format('YYYY-MM-DD')}`,
      onDelete: () => setBiddingEndsAtFilter(null),
    });
  }

  const filters = [
    ...searchFilters,
    ...filtersFromFacets(facetFilters),
    ...dateFilters,
  ];

  const { data, loading, fetchMore, networkStatus, refetch } = useQuery(
    VEHICLE_QUERY,
    {
      variables: {
        filters,
        sort,
        page: 1,
        pageSize: 25,
      },
      notifyOnNetworkStatusChange: true,
    },
  );

  const handleSearch = e =>
    e.key === 'Enter' ? setSearchFiltersFromKeywords() : null;

  const handleRequestSort = (event, property) => {
    setSort([
      {
        model: 'Vehicle',
        field: property,
        direction: order === 'asc' ? 'desc' : 'asc',
      },
    ]);
    setOrder(order === 'asc' ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const vehicleRows = data?.sellMyRide?.vehicles?.results || [];
  const { total, nextPage } = data?.sellMyRide?.vehicles?.pagination || {};

  const setSearchFiltersFromKeywords = () =>
    setSearchFilters(
      searchKeywords.split(' ').map(keyword => ({
        or: [
          {
            model: 'Vehicle',
            field: 'make',
            op: 'ilike',
            value: `${keyword}%`,
          },
          {
            model: 'Vehicle',
            field: 'model',
            op: 'ilike',
            value: `${keyword}%`,
          },
          {
            model: 'Vehicle',
            field: 'vin',
            op: 'ilike',
            value: `%${keyword}%`,
          },
          {
            model: 'Vehicle',
            field: 'year',
            op: '==',
            value: parseInt(keyword) || 0,
          },
          {
            model: 'Customer',
            field: 'first_name',
            op: 'ilike',
            value: `%${keyword}%`,
          },
          {
            model: 'Customer',
            field: 'last_name',
            op: 'ilike',
            value: `%${keyword}%`,
          },
        ],
      })),
    );

  const fetchMoreHandler = () => {
    if (nextPage)
      fetchMore({
        variables: { page: nextPage },
        updateQuery: (prev, { fetchMoreResult: more }) => {
          if (!more.sellMyRide.vehicles.results) return prev;

          return Object.assign({}, prev, {
            sellMyRide: {
              __typename: prev.sellMyRide.__typename,
              vehicles: {
                __typename: prev.sellMyRide.vehicles.__typename,
                results: [
                  ...prev.sellMyRide.vehicles.results,
                  ...more.sellMyRide.vehicles.results,
                ],
                pagination: more.sellMyRide.vehicles.pagination,
              },
            },
          });
        },
      });
  };

  useEffect(() => {
    if (searchKeywords && searchFilters.length === 0)
      setSearchFiltersFromKeywords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKeywords, searchFilters]);

  useEffect(() => {
    if (
      urlParams.get('keywords') &&
      searchKeywords !== urlParams.get('keywords')
    )
      setSearchKeywords(urlParams.get('keywords'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box height="100%">
      <FilterDrawer
        anchor="left"
        open={filterDrawerOpen}
        onClose={() => setFilterDrawerOpen(false)}
        filters={filters}
        facetFilters={facetFilters}
        setFacetFilters={setFacetFilters}
        facetDefinitions={[{ model: 'Vehicle', field: 'status' }]}
        biddingStartsAtFilter={biddingStartsAtFilter}
        setBiddingStartsAtFilter={setBiddingStartsAtFilter}
        biddingEndsAtFilter={biddingEndsAtFilter}
        setBiddingEndsAtFilter={setBiddingEndsAtFilter}
      />
      <Box
        className={classes.roomForFABs}
        marginLeft={2}
        marginTop={2}
        marginRight={1}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          paddingBottom={2}
        >
          <Typography variant="h5">Sell My Ride Vehicles</Typography>
        </Box>
        {desktop && (
          <Box paddingBottom={2} style={{ display: 'flex' }}>
            <Grid
              container
              direction="row"
              xs={12}
              md={12}
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <Grid item>
                <Fab
                  size="medium"
                  color="primary"
                  onClick={() => setFilterDrawerOpen(true)}
                >
                  <FilterListIcon />
                </Fab>
                <Box padding={1} flexGrow={2}>
                  <FilterChips
                    filters={facetFilters}
                    setFilters={setFacetFilters}
                    others={otherFacets}
                  />
                </Box>
              </Grid>
              <Grid item style={{ paddingRight: '1rem' }}>
                <Box>
                  <TextField
                    placeholder="Search Vehicles ..."
                    className={
                      !desktop ? classes.mobileWidth : classes.desktopWidth
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            disabled={!searchKeywords}
                            onClick={() => {
                              setSearchKeywords('');
                              setSearchFilters([]);
                            }}
                            size="large">
                            <CloseIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    value={searchKeywords || ''}
                    onChange={e => setSearchKeywords(e.target.value)}
                    onKeyPress={handleSearch}
                  />
                </Box>
                <Box
                  style={{
                    textAlign: 'right',
                    width: '100%',
                    fontSize: '18px',
                    alignItems: 'flex-end',
                    alignSelf: 'flex-end',
                  }}
                >
                  Showing {vehicleRows.length} of {total || 0}
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}
        {desktop && (
          <Paper>
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  classes={classes}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
                {loading && !networkStatus === NetworkStatus.fetchMore && (
                  <Loading />
                )}
                {vehicleRows?.length === 0 && !loading && (
                  <Box style={{ fontSize: '20px', padding: '1rem' }}>
                    There are no vehicles to display.
                  </Box>
                )}
                <TableBody>
                  {vehicleRows?.map((row, index) => {
                    return (
                      <SellMyRideTableRow
                        key={index}
                        row={row}
                        refetch={refetch}
                      />
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        )}
        {!loading && <Waypoint onEnter={fetchMoreHandler} />}
        {networkStatus === NetworkStatus.fetchMore && (
          <Box>Loading more vehicles...</Box>
        )}
        {!desktop && <Box>Page not available in mobile view</Box>}
      </Box>
    </Box>
  );
};

export default SellMyRideListView;
