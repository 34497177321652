import React from 'react';

/* external */
import { Switch, Route } from 'react-router-dom';

/* internal */
import Layout from 'modules/app/components/Layout';
import PartsTable from './PartsTable';

const Parts = ({ match }) => (
  <Layout title="Parts">
    <Switch>
      <Route exact path={`${match.url}/`} component={PartsTable} />
      <Route
        exact
        path={`${match.url}/:id(\\d+)`}
        render={({ match: matched }) => (
          <PartsTable id={parseInt(matched.params.id, 10)} />
        )}
      />
    </Switch>
  </Layout>
);

export default Parts;
