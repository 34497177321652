import { titleize } from 'utils';

const /* Dealer Track */ AddressType = {
    POSTAL_BOX: 'PB',
    RURAL_ROUTE: 'RR',
    STREET: 'ST',
  };

const /* Dealer Track */ EmploymentStatus = {
    FULLTIME: 'FT',
    FULLTIME_PROBATION: 'FTP',
    PARTTIME_CASUAL: 'PTC',
    PARTTIME_REGULAR: 'PTR',
    RETIRED: 'RET',
    SELF_EMPLOYED: 'SE',
    SEASONAL_SUMMER: 'SEAS',
    SEASONAL_WINTER: 'SEAW',
  };
const /* Dealer Track */ EmploymentType = {
    AT_HOME: 'At home',
    EXECUTIVE: 'Executive',
    LABOURER: 'Labourer',
    MANAGEMENT: 'Management',
    OFFICE_STAFF: 'Office Staff',
    OTHER: 'Other',
    PRODUCTION: 'Production',
    PROFESSIONAL: 'Professional',
    RETIRED: 'Retired',
    SALES: 'Sales',
    SELF_EMPLOYED: 'Self-Employed',
    SERVICE: 'Service',
    STUDENT: 'Student',
    TRADES: 'Trades',
    UNEMPLOYED: 'Unemployed',
  };

const /* Dealer Track */ Gender = {
    MALE: 'Male',
    FEMALE: 'Female',
  };

const /* Dealer Track */ HomeOwnershipType = {
    OWN_FREE_AND_CLEAR: 'OF',
    OWN_MOBILE_HOME: 'OM',
    OWN_WITH_MORTGAGE: 'OW',
    RENT: 'RE',
    RESERVE_HOUSING: 'RH',
    PARENTS: 'PA',
    OTHER: 'OT',
  };

const /* Dealer Track */ Province = {
    /* These should correlate with the allowed provinces in credit apps */
    /* ie. users can only select the keys below (or null apparently....) */
    AB: 'CA-AB',
    BC: 'CA-BC',
    MB: 'CA-MB',
    NL: 'CA-NL',
    NS: 'CA-NS',
    NT: 'CA-NT',
    NU: 'CA-NU',
    ON: 'CA-ON',
    PE: 'CA-PE',
    QC: 'CA-QC',
    SK: 'CA-SK',
    YT: 'CA-YT',
  };

const /* Dealer Track */ StreetDirection = {
    E: 'E',
    N: 'N',
    NE: 'NE',
    NW: 'NW',
    S: 'S',
    SE: 'SE',
    SW: 'SW',
    W: 'W',
  };
export const DealerTrack = {
  AddressType,
  EmploymentStatus,
  EmploymentType,
  HomeOwnershipType,
  Province,
  StreetDirection,
  Gender,
};

const _map = ([name, value]) => ({ name, value });
const _maplize = ([name, value]) => ({ name: titleize(name), value });
const makeOpts = (obj, func = _maplize) => Object.entries(obj).map(func);

export const DealerTrackOptions = {
  AddressType: makeOpts(AddressType),
  EmploymentStatus: makeOpts(EmploymentStatus),
  EmploymentType: makeOpts(EmploymentType),
  HomeOwnershipType: makeOpts(HomeOwnershipType),
  Gender: makeOpts(Gender),
  Province: makeOpts(Province, _map),
  StreetDirection: makeOpts(StreetDirection, _map),
};
