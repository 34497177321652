/* external */
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

/* Material UI */
import { useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';

import { useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import BuildIcon from '@mui/icons-material/Build';
import CreateIcon from '@mui/icons-material/Create';

/* internal */
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';
import SelectControl from 'components/MaterialUI/SelectControl2';
import TextFieldControl from 'components/MaterialUI/TextFieldControl';
import { useUserContext } from 'components/MaterialUI/UserContext';
import KeyboardDatePickerControl from 'components/MaterialUI/KeyboardDatePickerControl';
import TrimSelector from 'modules/inventory/components/vehicle/vehicle_info/TrimSelector';

import { VinValidator } from 'utils';

import DealRecap from './DealRecap';
import { Role } from 'constants.js';

const CREATE_VEHICLE_FOR_CUSTOMER = gql`
  mutation createVehicleForCustomer(
    $customer_id: ID!
    $input: CustomerVehicleInput!
  ) {
    createVehicleForCustomer(_id: $customer_id, input: $input) {
      _id
      year
      make
      model
      trim
      purchased_at {
        dealer_id
        dealer_name
      }
      date_purchased
      payment_amount
      last_payment_date
      payment_frequency
    }
  }
`;

const UPDATE_VEHICLE_FOR_CUSTOMER = gql`
  mutation updateVehicleForCustomer(
    $customer_id: ID!
    $_id: ID!
    $input: CustomerVehicleInput!
  ) {
    updateCustomerVehicle(_id: $customer_id, vehicle_id: $_id, input: $input) {
      _id
      vehicles {
        _id
        acode
        balance_owing
        balloon_amount
        body_type
        cash_price
        date_purchased
        deal_number
        deal_type
        deal_recap {
          costAdjustmentsBackItems {
            cost
            gross
            name
            sale
          }
          costAdjustmentsFrontItems {
            cost
            gross
            name
            sale
          }
          insuranceItems {
            cost
            gross
            name
            sale
          }
          serviceContract {
            cost
            gross
            name
            sale
          }
          totalBackGross
          totalDealerGross
          totalFrontGross
        }
        equity
        exterior_colour
        finance_amount
        finance_term
        first_payment_date
        last_payment_date
        lender
        make
        model
        odometer
        payment_amount
        payment_frequency
        payment_type
        purchase_mbi_cost
        purchased_at {
          dealer_name
        }
        rate
        sales_deal_type
        sell_rate_apr
        trim
        vin
        year
      }
    }
  }
`;

const RESTORE_CUSTOMER_PAST_VEHICLE = gql`
  mutation restoreCustomerVehicle($_id: ID!, $vehicle_id: ID!) {
    restoreCustomerVehicle(_id: $_id, past_vehicle_id: $vehicle_id) {
      vehicles {
        _id
        acode
        balance_owing
        balloon_amount
        body_type
        cash_price
        date_purchased
        deal_number
        deal_type
        deal_recap {
          costAdjustmentsBackItems {
            cost
            gross
            name
            sale
          }
          costAdjustmentsFrontItems {
            cost
            gross
            name
            sale
          }
          insuranceItems {
            cost
            gross
            name
            sale
          }
          serviceContract {
            cost
            gross
            name
            sale
          }
          totalBackGross
          totalDealerGross
          totalFrontGross
        }
        equity
        exterior_colour
        finance_amount
        finance_term
        first_payment_date
        last_payment_date
        lender
        make
        model
        odometer
        payment_amount
        payment_frequency
        payment_type
        purchase_mbi_cost
        purchased_at {
          dealer_name
        }
        rate
        sales_deal_type
        sell_rate_apr
        trim
        vin
        year
      }
      past_vehicles {
        _id
        acode
        balance_owing
        balloon_amount
        body_type
        cash_price
        date_purchased
        deal_number
        deal_type
        deal_recap {
          costAdjustmentsBackItems {
            cost
            gross
            name
            sale
          }
          costAdjustmentsFrontItems {
            cost
            gross
            name
            sale
          }
          insuranceItems {
            cost
            gross
            name
            sale
          }
          serviceContract {
            cost
            gross
            name
            sale
          }
          totalBackGross
          totalDealerGross
          totalFrontGross
        }
        equity
        exterior_colour
        finance_amount
        finance_term
        first_payment_date
        last_payment_date
        lender
        make
        model
        odometer
        payment_amount
        payment_frequency
        payment_type
        purchase_mbi_cost
        purchased_at {
          dealer_name
        }
        rate
        sales_deal_type
        sell_rate_apr
        trim
        vin
        year
      }
    }
  }
`;

const YEARS_QUERY = gql`
  query getYears {
    inventory {
      years: getYearsDrilldown
    }
  }
`;

const MAKES_QUERY = gql`
  query getMakes($selectedYear: Int!) {
    inventory {
      makes: getMakesDrilldown(year: $selectedYear) {
        name
        id
      }
    }
  }
`;

const MODELS_QUERY = gql`
  query getModels($selectedYear: Int!, $selectedMakeId: Int!) {
    inventory {
      models: getModelsDrilldown(
        year: $selectedYear
        make_id: $selectedMakeId
      ) {
        name
        id
      }
    }
  }
`;

const TRIMS_QUERY = gql`
  query getTrims(
    $selectedYear: Int!
    $selectedMakeName: String!
    $selectedModelName: String!
  ) {
    inventory {
      trims: getTrimsDrilldown(
        year: $selectedYear
        make_name: $selectedMakeName
        model_name: $selectedModelName
      )
    }
  }
`;

const DECODE = gql`
  query decodeVehicle($vin: String) {
    inventory {
      decodeVehicle(vin: $vin) {
        styles {
          year
          acode
          make_name
          model_name
          trim
        }
        ...TrimSelectorVINDecodeResult
      }
    }
  }
  ${TrimSelector.fragments.VINDecodeResult}
`;

const VehicleDetailsModal = ({
  customer,
  defaultValues,
  vehicleStatus,
  refetchCustomer,
}) => {
  const desktop = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { currentUser } = useUserContext();
  const [showTrimSelect, setShowTrimSelect] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  const lastService = defaultValues?.service_history?.slice(-1)[0];

  const { control, handleSubmit, formState, register, reset, setValue, watch } =
    useForm({
      defaultValues,
      shouldUnregister: true,
    });
  register('_id');

  useEffect(() => {
    if (open) reset(defaultValues);
  }, [defaultValues, open, reset]);

  const isManagerOrAbove =
    Role.ALL_SALES_MANAGERS.includes(currentUser.role) ||
    currentUser.role === Role.ORGANIZATION_ADMIN;
  const isLocked = customer?.do_not_contact || false;
  const isHidden = currentUser.role !== Role.ORGANIZATION_ADMIN && isLocked;
  const customer_id = customer._id;

  const setVehicleDataFromStyle = style => {
    setValue('year', style.year, { shouldDirty });
    setValue('make', style.make_name, { shouldDirty });
    setValue('model', style.model_name, { shouldDirty });
    setValue('trim', style.trim, { shouldDirty });
    setValue('acode', style.acode, { shouldDirty });
    setShowTrimSelect(false);
  };

  const [decodeVehicle, decodeVehicleQuery] = useLazyQuery(DECODE, {
    onCompleted: ({
      inventory: {
        decodeVehicle: { styles },
      },
    }) => {
      if (styles.length > 1) setShowTrimSelect(true);
      else if (styles.length === 1) {
        setVehicleDataFromStyle(styles[0]);
      }
    },
    fetchPolicy: 'network-only',
  });

  const decodeStyles =
    decodeVehicleQuery.data?.inventory.decodeVehicle.styles ?? [];

  const handleRestore = () => {
    restoreVehicleForCustomer({
      variables: {
        _id: customer._id,
        vehicle_id: defaultValues._id,
      },
    });
    handleClose();
  };

  const [createVehicleForCustomer] = useMutation(CREATE_VEHICLE_FOR_CUSTOMER);
  const [updateVehicleForCustomer] = useMutation(UPDATE_VEHICLE_FOR_CUSTOMER);
  const [restoreVehicleForCustomer] = useMutation(
    RESTORE_CUSTOMER_PAST_VEHICLE,
    {
      onCompleted: () => {
        enqueueSnackbar(`Successfully restored vehicle`, {
          variant: 'success',
        });
        refetchCustomer();
      },
      onError: () =>
        enqueueSnackbar(`Error in restoring vehicle`, {
          variant: 'error',
        }),
    },
  );
  const selectedYear = watch('year', null);
  const selectedMakeName = watch('make', null);
  const selectedModelName = watch('model', null);
  const selectedTrim = watch('trim', null);
  const selectedVIN = watch('vin', defaultValues?.vin ?? '');
  const shouldDirty = true;

  const yearsQuery = useQuery(YEARS_QUERY, { skip: !open });
  const years = yearsQuery.data ? yearsQuery.data.inventory.years : [];

  const [getMakes, makesQuery] = useLazyQuery(MAKES_QUERY, {
    onCompleted: ({ inventory: { makes } }) => {
      if (!makes.map(x => x.name).includes(selectedMakeName))
        setValue('make', null, { shouldDirty });
    },
  });
  const { isDirty } = formState;

  const makes = makesQuery.data ? makesQuery.data.inventory.makes : [];

  const [getModels, modelsQuery] = useLazyQuery(MODELS_QUERY, {
    onCompleted: ({ inventory: { models } }) => {
      if (!models.map(x => x.name).includes(selectedModelName))
        setValue('model', null, { shouldDirty });
    },
  });
  const models = modelsQuery.data ? modelsQuery.data.inventory.models : [];

  const [getTrims, trimsQuery] = useLazyQuery(TRIMS_QUERY, {
    onCompleted: ({ inventory: { trims } }) => {
      if (!trims.includes(selectedTrim))
        setValue('trim', null, { shouldDirty });
    },
  });

  // Trim dropdown query can return multiple duplicate trims, since it actually gets
  // vehicle styles in chrome service (there can be different styles for the same trim level)
  const trims = [...new Set(trimsQuery.data?.inventory.trims)];

  // Retrieve makes based on year.  Only do this when dialog is open.
  useEffect(() => {
    if (open && selectedYear) getMakes({ variables: { selectedYear } });
  }, [open, selectedYear, getMakes]);

  // Retrieve models based on make and year.  Only do this when the dialog is open.
  useEffect(() => {
    if (open && selectedYear && selectedMakeName) {
      const selectedMakeId = makes.find(x => x.name === selectedMakeName)?.id;
      if (selectedMakeId)
        getModels({ variables: { selectedYear, selectedMakeId } });
    }
  }, [getModels, makes, open, selectedMakeName, selectedYear]);

  // Retrieve trims based on year, make and model.  Only do this when the dialog is open.
  useEffect(() => {
    if (open && selectedYear && selectedMakeName && selectedModelName) {
      if (!isEmpty(selectedMakeName) && !isEmpty(selectedModelName))
        getTrims({
          variables: {
            selectedYear,
            selectedMakeName,
            selectedModelName,
          },
        });
    }
  }, [getTrims, open, selectedMakeName, selectedModelName, selectedYear]);

  const handleKeyDown = (event, callback) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      callback(onSubmit);
    }
  };

  const handleDecode = () => {
    const validator = new VinValidator(selectedVIN);
    if (!validator.isValid) alert(validator.error);
    else {
      decodeVehicle({
        variables: { vin: selectedVIN },
        fetchPolicy: 'network-only',
      });
    }
  };

  const onSubmit = ({
    _id,
    __typename,
    balance_owing,
    balloon_amount,
    cash_price,
    date_purchased,
    deal_number,
    deal_recap,
    equity,
    finance_amount,
    first_payment_date,
    last_payment_date,
    payment_amount,
    purchase_mbi_cost,
    purchased_at,
    rate,
    sales_deal_type,
    sell_rate_apr,
    ...data
  }) => {
    if (!isDirty) {
      handleClose();
      return;
    }

    const formatDate = x =>
      x ? moment(x).utc().format('YYYY-MM-DDThh:mm:ssZ') : undefined;

    const formatFloat = x =>
      x && x !== '' ? parseFloat(parseFloat(x).toFixed(2)) : undefined;

    const input = {
      balloon_amount: formatFloat(balloon_amount),
      cash_price: formatFloat(cash_price),
      date_purchased: formatDate(date_purchased),
      finance_amount: formatFloat(finance_amount),
      first_payment_date: formatDate(first_payment_date),
      last_payment_date: formatDate(last_payment_date),
      payment_amount: formatFloat(payment_amount),
      rate: formatFloat(rate),
      ...data,
    };

    if (!_id) {
      createVehicleForCustomer({
        variables: {
          customer_id,
          input,
        },
      })
        .then(r => {
          enqueueSnackbar(
            `Successfully added vehicle to ${customer.fullname}`,
            {
              variant: 'success',
            },
          );
          refetchCustomer();
        })
        .catch(e => {
          enqueueSnackbar(
            `Error in creating vehicle for ${customer.fullname}`,
            {
              variant: 'error',
            },
          );
        });
    } else {
      updateVehicleForCustomer({
        variables: {
          customer_id,
          _id,
          input,
        },
      })
        .then(r => {
          enqueueSnackbar(`Successfully updated vehicle`, {
            variant: 'success',
          });
          refetchCustomer();
        })
        .catch(e => {
          enqueueSnackbar(`Error in updating vehicle ${JSON.stringify(e)}`, {
            variant: 'error',
          });
        });
    }
    handleClose();
  };

  const textfield250 = {
    control,
    InputLabelProps: { shrink: true },
    style: { width: '250px' },
  };

  return (
    <Box>
      {defaultValues === null && !isHidden && (
        <IconButton size="small" onClick={handleOpen}>
          <AddIcon style={theme.actions.add} />
        </IconButton>
      )}
      {defaultValues !== null && !isHidden && (
        <IconButton size="small" onClick={handleOpen}>
          <CreateIcon />
        </IconButton>
      )}
      <Dialog open={showTrimSelect} onClose={() => setShowTrimSelect(false)}>
        <TrimSelector
          handleStyleSelect={setVehicleDataFromStyle}
          decodeStyles={decodeStyles}
        />
      </Dialog>
      <Dialog open={open} onClose={handleClose} maxWidth="md">
        <DialogTitle>Customer Vehicle</DialogTitle>
        <form
          onSubmit={handleSubmit(onSubmit)}
          onKeyDown={e => {
            handleKeyDown(e, handleSubmit);
          }}
        >
          <DialogContent>
            <LoadingBackdrop
              open={[yearsQuery, makesQuery, modelsQuery, trimsQuery].some(
                x => x.loading,
              )}
            />
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              spacing={3}
            >
              <Grid item>
                <Grid container direction="column">
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item>
                      <TextFieldControl
                        InputLabelProps={{ shrink: true }}
                        label="VIN"
                        name="vin"
                        control={control}
                        style={{ width: desktop ? '250px' : '175px' }}
                      />
                    </Grid>
                    <Grid>
                      <Button
                        onClick={handleDecode}
                        color="primary"
                        style={{
                          margin: '3px',
                          backgroundColor: '#ff5a00',
                          color: 'white',
                          padding: desktop ? undefined : '3px 4px',
                          marginTop: desktop ? undefined : '20px',
                        }}
                      >
                        DECODE
                      </Button>
                    </Grid>
                  </Grid>
                  <TextFieldControl
                    {...textfield250}
                    label="Acode"
                    name="acode"
                  />
                  <SelectControl
                    {...textfield250}
                    label="Year"
                    name="year"
                    options={years
                      .sort((a, b) => b - a)
                      .map(year => ({ name: year, value: year }))}
                    required
                  />
                  <SelectControl
                    {...textfield250}
                    label="Make"
                    name="make"
                    options={makes}
                    optionValueKey="name"
                    required
                  />
                  <SelectControl
                    {...textfield250}
                    label="Model"
                    name="model"
                    options={models}
                    optionValueKey="name"
                    required
                  />
                  <SelectControl
                    {...textfield250}
                    label="Trim"
                    name="trim"
                    options={trims.map(trim => ({ name: trim, value: trim }))}
                  />
                  <TextFieldControl
                    {...textfield250}
                    label="Exterior Colour"
                    name="exterior_colour"
                  />
                  <TextFieldControl
                    {...textfield250}
                    label="Body Type"
                    name="body_type"
                  />
                  <TextFieldControl
                    {...textfield250}
                    label="Deal Type"
                    name="deal_type"
                  />
                  <TextFieldControl
                    {...textfield250}
                    label="Purchased At"
                    name="purchased_at.dealer_name"
                    InputProps={{ readOnly: true }}
                  />
                  <TextFieldControl
                    {...textfield250}
                    label="Deal Number"
                    name="deal_number"
                    InputProps={{ readOnly: true }}
                  />
                  <TextFieldControl
                    {...textfield250}
                    label="Rate"
                    name="rate"
                  />
                  <TextFieldControl
                    {...textfield250}
                    label="Cash Price"
                    name="cash_price"
                    variant="currency"
                  />
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="column">
                  <KeyboardDatePickerControl
                    {...textfield250}
                    label="Date Purchased"
                    name="date_purchased"
                  />

                  <KeyboardDatePickerControl
                    {...textfield250}
                    label="First Payment Date"
                    name="first_payment_date"
                  />

                  <KeyboardDatePickerControl
                    {...textfield250}
                    label="Last Payment Date"
                    name="last_payment_date"
                  />

                  <TextFieldControl
                    {...textfield250}
                    label="Payment Amount"
                    name="payment_amount"
                    variant="currency"
                  />

                  <TextFieldControl
                    {...textfield250}
                    label="Payment Type"
                    name="payment_type"
                  />

                  <TextFieldControl
                    {...textfield250}
                    label="Lender"
                    name="lender"
                  />

                  <TextFieldControl
                    {...textfield250}
                    label="Payment Frequency"
                    name="payment_frequency"
                  />

                  <TextFieldControl
                    {...textfield250}
                    label="Finance Term"
                    name="finance_term"
                  />
                  <TextFieldControl
                    {...textfield250}
                    label="Finance Amount"
                    name="finance_amount"
                    variant="currency"
                  />
                  <TextFieldControl
                    {...textfield250}
                    label="Sell Rate"
                    name="sell_rate_apr"
                    variant="rate"
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
              </Grid>
            </Grid>
            {defaultValues != null && isManagerOrAbove && (
              <DealRecap customer={customer} vehicle={defaultValues} />
            )}
            {vehicleStatus === 'current' && (
              <>
                <br />
                <Typography
                  variant="h6"
                  fontSize={16}
                  fontWeight="bold"
                  gutterBottom
                >
                  Last Service
                </Typography>
                <Divider />
                {lastService ? (
                  <>
                    <Typography
                      variant="subtitle1"
                      display="block"
                      gutterBottom
                    >
                      <BuildIcon fontSize="inherit" />{' '}
                      <strong>
                        {moment(lastService.date).format('YYYY-MM-DD')} at{' '}
                        {lastService.dealer?.dealer_name ?? 'Unknown Dealer'}
                      </strong>
                    </Typography>
                    {lastService.details.map(detail => {
                      return <li>{detail.op_code_description}</li>;
                    })}
                  </>
                ) : (
                  <Typography variant="caption" display="block" gutterBottom>
                    No last service details found.
                  </Typography>
                )}
              </>
            )}
          </DialogContent>
          <DialogActions style={{ margin: '5px' }}>
            {vehicleStatus === 'previous' && (
              <Button
                onClick={handleRestore}
                color="primary"
                variant="outlined"
              >
                Restore
              </Button>
            )}
            <Button onClick={handleClose} color="primary" variant="outlined">
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              style={theme.actions.confirm}
            >
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
};

VehicleDetailsModal.fragments = {
  customer: gql`
    fragment VehicleDetailsModalFragment on Customer {
      _id
      do_not_contact
      fullname
    }
  `,
};

export default VehicleDetailsModal;
