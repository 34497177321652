import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import KeyboardDatePickerControl from 'components/MaterialUI/KeyboardDatePickerControl';
import TextFieldControl from 'components/MaterialUI/TextFieldControl';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
  multipleLine: {
    whiteSpace: 'pre-line',
    fontSize: '12px',
    color: '#999999',
  },
  grey: {
    fontSize: '12px',
    color: '#999999',
  },
}));

const VehiclePriceForm = ({
  updateErrors,
  isOpen,
  onClose,
  onSubmit,
  vehicle,
}) => {
  const classes = useStyles();
  const {
    control,
    formState: { errors },
    handleSubmit,
    setError,
  } = useForm({ shouldUnregister: true });

  const handleCancel = () => {
    onClose();
  };

  useEffect(() => {
    if (updateErrors?.message) {
      setError('general', { message: updateErrors.message });
    }

    const { graphQLErrors } = updateErrors;
    if (graphQLErrors && graphQLErrors.length) {
      const jsonErrors = graphQLErrors[0].extensions.json || {};
      for (const [k, v] of Object.entries(jsonErrors))
        setError(k, { message: v });
    }
  }, [setError, updateErrors]);
  return (
    <Dialog
      open={isOpen}
      onClose={handleCancel}
      TransitionComponent={Transition}
      fullScreen
    >
      <DialogTitle>Edit Pricing</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <ErrorMessage errors={errors} name="_schema" />
              <ErrorMessage errors={errors} name="general" />
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <TextFieldControl
                  control={control}
                  defaultValue={vehicle.regular_price || ''}
                  name="regular_price"
                  type="number"
                  inputProps={{ step: '0.01' }}
                  label="Price:"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <TextFieldControl
                  control={control}
                  defaultValue={vehicle.special_price || ''}
                  name="special_price"
                  label="Special Price:"
                  type="number"
                  inputProps={{ step: '0.01' }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.multipleLine}>Expiry: </Typography>
              <KeyboardDatePickerControl
                control={control}
                margin="dense"
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                InputAdornmentProps={{ position: 'start' }}
                style={{ width: '50%' }}
                name="special_price_expires"
                defaultValue={vehicle.special_price_expires}
              />
              <ErrorMessage errors={errors} name="special_price_expires" />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button type="submit" color="primary">
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default VehiclePriceForm;
