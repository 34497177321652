import React from 'react';

/* Material UI */
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '50px',
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  addIcon: {
    padding: '0px',
    margin: '0px 5px 0px 10px',
  },
}));

const OpportunityCommunications = ({ communications }) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          spacing={3}
          style={{ marginBottom: '10px' }}
        >
          <Grid item>
            <Typography className={classes.title} gutterBottom>
              Communications
            </Typography>
          </Grid>
        </Grid>
        There are no communications on this opportunity
      </CardContent>
    </Card>
  );
};

export default OpportunityCommunications;
