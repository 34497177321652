import React from 'react';

import { Switch, Route } from 'react-router-dom';

import app from '../../app';

import ManagerOverviewDashboard from './ManagerOverviewDashboard';
import MainDashboardLayout from './MainDashboardLayout';

const MainDashboard = props => {
  const { match } = props;
  return (
    <app.components.Layout title="Home">
      <Switch>
        <Route exact path={`${match.url}/`}>
          <MainDashboardLayout />
        </Route>
        <Route exact path={`${match.url}/overview`}>
          <ManagerOverviewDashboard />
        </Route>
      </Switch>
    </app.components.Layout>
  );
};

export default MainDashboard;
