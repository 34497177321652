import React from 'react';

/* external */
import gql from 'graphql-tag';

/* Material UI */
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Icon from '@mui/material/Icon';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles(theme => ({
  contentPadding: {
    padding: theme.spacing(2),
    // paddingBottom: 0, // Add if we want to remove the space at the bottom of
    // the table (to hide the last line separator)
  },
  fullHeight: {
    height: '100%',
  },
  labelCell: {
    width: '33%',
  },
}));

const VehicleAttachments = ({ item }) => {
  const { contentPadding, fullHeight, labelCell } = useStyles();
  const { vehicle } = item ?? {};
  const { attachments = [] } = vehicle ?? {};

  return (
    <Paper className={fullHeight}>
      <Box className={contentPadding}>
        <Typography variant="h5">Attachments</Typography>
        <Table>
          <TableBody>
            {[
              ...attachments.filter(x => x.tags !== 'UVI'),
              ...attachments.filter(x => x.tags === 'UVI'),
            ].map(({ id, label, url }) => (
              <TableRow key={id}>
                <TableCell className={labelCell}>
                  <Icon className="fas fa-file fa-fw" />
                  <Link href={url} target="_blank" rel="noopener noreferrer">
                    {label}
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Paper>
  );
};

VehicleAttachments.fragments = {
  item: gql`
    fragment VehicleAttachmentsItem on AuctionItem {
      vehicle {
        id
        attachments {
          id
          label
          tags
          url
          vin
        }
      }
    }
  `,
};

export default VehicleAttachments;
