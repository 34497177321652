import React from 'react';

import { RoleGroup } from 'constants.js';
import { useUserContext } from 'components/MaterialUI/UserContext';

const DashboardRoleOnly = ({ children, className }) => {
  const { currentUser: { role } } = useUserContext();
  if (
    role &&
    (RoleGroup.MANAGERS.includes(role) || RoleGroup.SALES_PEOPLE.includes(role))
  ) {
    return <div className={className}>{children}</div>;
  }

  return null;
};

export default DashboardRoleOnly;
