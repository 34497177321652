import React, { useState } from 'react';

/* external */
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';

/* Material UI */
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Loading from 'components/MaterialUI/Loading';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

const EDIT_DEAL_NUMBER = gql`
  mutation editDealNumber($_id: ID!, $dealNumber: String!) {
    editOpportunityDealNumber(_id: $_id, dealNumber: $dealNumber) {
      _id
      dms_deal {
        deal_number
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  icon: {
    backgroundColor: '#ff803b',
    color: 'white',
  },
}));

const EditDealNumberModal = ({ opportunity, onClose, isOpen }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [dealNumber, setDealNumber] = useState(
    opportunity.dms_deal.deal_number,
  );
  const [editDealNumber, { loading }] = useMutation(EDIT_DEAL_NUMBER, {
    onCompleted: () => {
      enqueueSnackbar('Deal Number Updated!', { variant: 'success' });
      onClose();
    },
    onError: err => {
      enqueueSnackbar('Deal Number could not be updated', { variant: 'error' });
    },
  });
  const handleChange = event => {
    setDealNumber(event.target.value);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        Edit Deal Number -{' '}
        {opportunity?.customer?.fullname || 'Unknown Customer'}
      </DialogTitle>
      <DialogContent>
        <Alert icon={false} severity="error" style={{ whiteSpace: 'pre-line' }}>
          Changing the deal number will update the stock number, opportunity
          status, and gross values according to the last sync from CDK.
          {'\n\n'}
          You can also click "Update Deal Number" without changing the deal
          number to force an update according to the last sync from CDK.
          {'\n\n'}
          Changes made to the deal in CDK in the last 24 hours may not be
          reflected here until the following day, however it should happen
          automatically.
        </Alert>
        <Typography variant="body1" gutterBottom style={{ marginTop: '10px' }}>
          Current Deal Number: {opportunity.dms_deal.deal_number}
        </Typography>
        <TextField
          label={'New Deal Number'}
          value={dealNumber}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose}>
          Close
        </Button>
        <Button
          className={classes.icon}
          onClick={() =>
            editDealNumber({
              variables: {
                _id: opportunity._id,
                dealNumber: dealNumber,
              },
            })
          }
          variant="contained"
          color="primary"
        >
          Update Deal Number
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditDealNumberModal;
