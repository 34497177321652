import React, { useRef, useState } from 'react';
import { withStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import { cloneDeep } from 'lodash';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import InspectionCheckboxes from '../InspectionCheckboxes';

const InfinitiCPOForm = props => {
  const { enqueueSnackbar } = useSnackbar();

  // Infiniti CPO form fields
  const vin = {
    i1: 'VIN Matches Other VINs on Vehicle and Paperwork/Proper Attachment (Original/No Alterations)',
    i2: 'Vehicle has correct manufacturer’s door jamb & nameplate decals.',
    i3: 'All OEM Service Campaigns and Recalls have been Performed',
    i4: 'Check, Document, Repair and Retest Any Diagnostic Trouble Codes',
    i5: 'Oil Change/Scheduled Maintenance Performed',
  };
  const consult = {
    i6: 'Check for All DTC Sub-systems Inspection',
    i7: 'Perform Applicable Software Updates',
    i8: 'Perform Idle Air Volume Learn (IAVL) (If Required)',
  };
  const engine = {
    i9: 'Fluid Leakages (Record Where and What is Leaking)',
    i10: 'Oil in Air Cleaner (Can Indicate Excessive Blow-by or Faulty PCV System',
    i11: 'Water in Oil (Check Underside of Oil Filler Cap)',
    i12: 'Engine Oil (Condition/Level)',
    i13: 'Timing Chain (Noise)',
    i14: 'Timing Belt Replaced per OEM Maintenance Schedule',
    i15: 'Cylinder Power Balance – Run Diagnostics (Address Error Codes, if applicable)',
    i16: 'Fluids (Condition/Levels)',
    i17: 'Vacuum Hoses (Condition)',
    i18: 'Engine Mounts (Condition)',
  };
  const cooling = {
    i19: 'Pressure Test Cooling System and Cap (Operational/Leaks)',
    i20: 'Hoses (Condition)',
    i21: 'Coolant (Condition/Level/Leaks/Freeze Point/Voltage/Corrosion)',
    i22: 'Radiator (Condition/Tube/Fins) Pressure Test Cap/System',
    i23: 'Cooling Fan/Fan Clutch/Fan Motor (Condition/Operation/Auxiliary Fan Operation)',
    i24: 'Water Pump (Noise/Leaks)',
    i25: 'Recovery Tank (Condition/Fluid Level/Sensor Operation)',
    i26: 'Belts (Condition/Cracks/Glazed/Frayed/Adjustment)',
  };
  const fuel = {
    i27: 'Fuel Pump Noise/External Fuel Pump Leakage',
    i28: 'Fuel Lines/Hoses (Condition/Check for Signs of Leakage)',
    i29: 'Fuel Filter (Condition/Replace per Maintenance Schedule)',
    i30: 'Air Filter (Condition/Replace per Maintenance Schedule)',
    i31: 'Check Injection Pump Timing (Diesel)',
    i32: 'Perform Boost Pressure Test (Turbo Diesel)',
    i33: 'Check Vacuum Pump Operation (Diesel)',
  };
  const electrical = {
    i34: 'Remote Keyless Entry Fob (Operation/Battery Condition)',
    i35: 'Wiring (Condition)',
    i36: 'Starter Operation (Draw/Amperage)',
    i37: 'Alternator Charging (Voltage and Amperage Output) Record Regulated/Maximum',
    i38: 'Ignition System Operation',
    i39: 'Battery Condition: (Fluid/Level/Corrosion/Voltage/Load Test) Record Readings',
    i40: 'Check Pre-Glow System (Amperage Draw Diesel)',
  };
  const transmission = {
    i41: 'Transmission System (Condition/Operation)',
    i42: 'Automatic Transmission/Transaxle Fluids (Condition/Level/Leaks/Fill or Change Oil and Filters)',
    i43: 'Manual Transmission/Drive Axle/Transfer Case Fluids (Condition/Level/Leaks/Fill or Change Oil)',
    i44: 'Transmission Mounts (Condition/Cracked/Oil Soaked/Broken/Replace Worn Mounts)',
    i45: 'Transfer Case (Operation)',
    i46: 'Universal Joints/CV Joints/Boots (Condition)',
    i47: 'Differential/Drive Axles (condition/Leaks/Damage)',
    i48: 'Clutch (Operation/Fluid Level)',
  };
  const hybrid = {
    i49: 'Inverter Coolant (Level/Condition/Concentration)',
    i50: 'Hybrid Battery (Charge Level)',
    i51: '12-V Auxiliary Battery (Correct Application)',
    i52: 'Inspect Integrity of Protective Shields for High Voltage Harnesss Under Vehicle from HV Battery to Engine Compartment',
    i53: 'Hybrid Electronic Control Unit (Run Diagnostic)',
    i54: 'Multi-Information Display (Operation)',
    i55: 'Diagnostic Scan (Check for Codes and Software Updates)',
  };
  const suspension = {
    i56: 'Tires (Check for Uneven Wear)',
    i57: 'Steering/Suspension (Fluid/Condition/Level of Fluid)',
    i58: 'Steering Gear and Linkage (Condition/Leaks/Boots)',
    i59: 'Power Steering Pump and Hoses (Leaks/Noise/Operation)',
    i60: 'Suspension System/Control Arms/Ball Joints/Bushings (Condition)',
    i61: 'Tie Rods and Idler Arm (Condition)',
    i62: 'Sway Bars/Links/Bushings (Condition)',
    i63: 'Springs (Condition/Ride Height)',
    i64: 'Struts/Shocks (Leakage)',
  };
  const brakes = {
    i65: 'Brakes/Calipers/Lines Operate Properly with No Signs of Damage',
    i66: 'Brake Fluid (Level/Condition Top Off as Necessary)',
    i67: 'Pads/Shoes: 50% Remaining',
    i68: 'Rotors/Drums (Condition/Wear with No Abnormal Sign of Grooving or Discoloration)',
    i69: 'Brake System Lines/Hydraulics/Hoses/Fittings (Condition/Wear/Leaks)',
    i70: 'Parking Brake (Operation/Adjustment/Condition)',
    i71: 'Master Cylinder and Booster (Operation/Leaks)',
    i72: 'Entire Exhaust System (Condition/Leaks/Damage/Noise)',
    i73: 'Catalytic Converter and Shielding',
    i74: 'Perform Emission Control Tests and Record Readings',
  };
  const unibody = {
    i75: 'Visually Inspect for Repairs, Damage, Abnormal Tire Wear. Note: Overall Vehicle Inspection Should Take Place at This Point for Rust and Damage Due to Some Natural Disaster. Look Under Carpet in Trunk, Interior and Under Dash for Flood Damage.',
    i76: 'Release Mechanisms Manual/Automatic, Hinges, Alignment and Proper Attaching Devices (Bolts And Over-spray Indications of Damage Repairs/Proper Operation and Closing)',
  };
  const road = {
    i77: 'Starting Cold/Hot',
    i78: 'Engine Idle Quality',
    i79: 'Engine Performance/Smooth Acceleration',
    i80: 'Engine Noise (Cold/Hot and High/Low Speeds)',
    i81: 'Transmission/Transaxle Noise (Cold/Hot)',
    i82: 'Automatic Transmission Shift Points/Slippage (Operational/Smooth/Proper Shift/Points/Check at All Shift Points - Overdrive Control Switch and Indicator Light)',
    i83: 'Shift Interlock (Operation)',
    i84: 'Drive Axle and Transfer Case Bearings or Gear Noise/Vibration (Adjust or Replace)',
    i85: 'Manual Transmission (Gear Change/Operation/No Grinding)',
    i86: 'Clutch Operation (Smooth Engagement / Slippage/Chatter)',
    i87: 'Steering/Rack and Pinion/Steering Linkage Performance (Steering Wheel Free Play/Center)',
    i88: 'Brakes/ABS (Effectiveness/Operation/Noise/No Pulling or Pulsating) Note: ABS May Pulsate Under Hard Braking',
    i89: 'Cruise Control Operation (Including Resume)',
    i90: 'Intelligent Cruise Control (Operation)',
    i91: 'Gauge/Speedometer/Tachometer/Odometer/Oil',
    i92: 'Body/Suspension (Performance/Noise/Rough Road Test)',
    i93: 'Tire Pressure Monitoring System (Operational/Indicator Light)',
  };
  const heating = {
    i94: 'Air Conditioning System (Cold/Condition/Operation)',
    i95: 'Heating System (Hot/Condition/Operation)',
    i96: 'Defogger/Defroster (Operation)',
    i97: 'Air Cabin Filter (Condition: Change per Maintenance Schedule)',
  };
  const window = {
    i98: 'Door Locks/Controls (Manual/Automatic Operation)',
    i99: 'Child Safety Locks (Operation)',
    i100: 'Windows/Controls (Manual/Automatic Operation, Locks)',
  };
  const air = {
    i101: 'Seat Belts (Condition/Operation)',
    i102: 'Air Bags (Exterior Condition/Intact/No Codes/Dash Light Operation) Note: When Inspecting the Air Bag System, Look Carefully at the Steering Wheel for Abnormal Wear (Could Indicate Vehicle has been Repaired and a Used Steering Wheel and Air Bags have been used).',
  };
  const audio = {
    i103: 'Audio System - Radio, Cassette, CD, Speaker (Condition/Operation)',
    i104: 'Antenna (Condition/Operation)',
    i105: 'Alarm/Theft Deterrent System (Condition/Operation)',
    i106: 'Navigation System (Operation)',
  };
  const interior = {
    i107: 'Clock (Condition/Operation)',
    i108: 'Horn (Condition/Operation)',
    i109: 'Lighter/Power Outlet(s) (Condition/Operation)',
    i110: 'Ashtray/Storage (Condition/Operation)',
    i111: 'Glove Compartment/Glove Compartment Light/Center Armrest/Console (Condition/Operation)',
    i112: 'Tilt/Telescopic Steering Wheel (Operation)',
    i113: 'Steering Wheel Lock (Operation)',
    i114: 'Mirrors/Rear View (Condition/Operation)',
    i115: 'Instrument Panel/Lights and Warning Lights (Condition//Operation/Bulb Check',
    i116: 'Warning Chimes (Operational)',
    i117: 'Interior Courtesy/Dome/Map Lights (Operation)',
    i118: 'Fuel Filler Door Release (Operation)',
    i119: 'Interior Door Handle (Operation)',
    i120: 'First-aid Kit: (for Vehicles so Equipped) Check/replace/update Contents or Complete Kit',
    i121: 'Cargo Area Liner (Condition)',
  };
  const interiorTrim = {
    i122: 'Carpet (Condition/Appearance)',
    i123: 'Floor Mats (Condition/Appearance)',
    i124: 'Door Trim/Panels/Dashboard/Package Shelf (Condition/Appearance/Attachment)',
    i125: 'Headliner (Condition/Appearance)',
    i126: 'Sun Visors (Condition/Appearance Vanity Mirror and Light)',
  };
  const luggage = {
    i127: 'Luggage Compartment Mat/Trim/Carpet/Cargo Net (Condition)',
    i128: 'Luggage Compartment Light (Operation)',
    i129: 'Jack/Tools (Condition)',
    i130: 'Emergency Trunk Release (Operation)',
  };
  const seats = {
    i131: 'Seat Upholstery (Condition/Worn/Ripped/Cracked/Faded)',
    i132: 'Seat/Headrest Adjustments (Manual/Automatic Operation)',
    i133: 'Heated/Cooled Seats (Operation)',
  };
  const sunroof = {
    i134: 'Sunroof/Moonroof (Condition/Operation)',
    i135: 'Convertible Top (Condition/Operation/Rear Window and Zipper Operation)',
  };
  const body = {
    i136: 'Inspect for Damage, Dings, Dents, Alignment, Mismatched Paint and Overall Paint Condition (Clear Coat)',
    i137: 'Inspect Bumpers for Damage, Alignment and Improper Repairs',
  };
  const grill = {
    i138: 'Condition: Bent, Dings, Parts Missing and Proper Attachment',
  };
  const glass = {
    i139: 'Windshield (Damaged, Pitted, Repaired, Wiper Marks, Cracked)',
    i140: 'Side/Rear Windows (Damaged, Pitted, Repaired)',
    i141: 'Mirrors (Condition of Mirror, Hinge, Operation)',
    i142: 'Wipers (Condition of Mechanism/Blade)',
    i143: 'Wipers (Operation/Speeds/Delay)',
    i144: 'Washer (Fluid Level/Spray Pattern)',
  };
  const exterior = {
    i145: 'Headlights, High Beams (Alignment/Condition/Auto Function/Operation)',
    i146: 'Taillights (Lens Condition/Operation)',
    i147: 'Brake Lights/High Mount Brake Light (Lens Condition/Operation)',
    i148: 'Parking Lights (Lens Condition/Operation)',
    i149: 'Hazard Lights (Lens Condition/Operation)',
    i150: 'Reverse Lights (Lens Condition/Operation)',
    i151: 'Turn Signal/Side Marker Lights (Lens Condition/Operation)',
    i152: 'License Plate Lights (Lens Condition/Operation)',
    i153: 'Fog/Driving/Daytime Running Lights (Lens Condition/Operation)',
  };
  const tires = {
    i154: 'OEM Size and Speed Rating (Same Brand per Axle) (AWD All Same Brand)',
    i155: 'Tread Depth (Minimum 4mm)',
    i156: 'Condition/Abnormal Wear (Sidewalls/Vibration/Alignment Problems)',
    i157: 'Pressure (Record Reading for Each Tire)',
    i158: 'Alignment (Refer to #56, #75 and #87) If Problem Noted Alignment Should be Checked',
    i159: 'Spare Tire (Condition/Tread/Pressure)',
    i160: 'Wheels (Condition/Visual Runout/Curb Damage/Finish)',
  };
  const additional = {
    i161: 'Owner’s Manuals and Minimum Two Keys or Keyless Remotes',
    i162: 'Exterior is Clean and Free of Swirl Marks and Removable Scratches',
    i163: 'Body is Free of all Dents and Reasonably Free of Dings',
    i164: 'Bumpers and Fascia Free of Gouges and Mismatched Paint',
    i165: 'Wheels/Wheel Covers and Tires are Clean and Properly Dressed',
    i166: 'Engine Compartment is Clean and Properly Dressed',
    i167: 'Interior is Free of Debris/Odor, Clean and Properly Dressed',
    i168: 'Seats, Carpets, Headliner and Floor Mats (If Equipped) are Free of Stains',
    i169: 'Interior Surfaces are Free of Holes, Tears, Fading or Excessive Wear',
  };

  let vinObj = cloneDeep(vin);
  let consultObj = cloneDeep(consult);
  let engineObj = cloneDeep(engine);
  let coolingObj = cloneDeep(cooling);
  let fuelObj = cloneDeep(fuel);
  let electricalObj = cloneDeep(electrical);
  let transmissionObj = cloneDeep(transmission);
  let hybridObj = cloneDeep(hybrid);
  let suspensionObj = cloneDeep(suspension);
  let brakesObj = cloneDeep(brakes);
  let unibodyObj = cloneDeep(unibody);
  let roadObj = cloneDeep(road);
  let heatingObj = cloneDeep(heating);
  let windowObj = cloneDeep(window);
  let airObj = cloneDeep(air);
  let audioObj = cloneDeep(audio);
  let interiorObj = cloneDeep(interior);
  let interiorTrimObj = cloneDeep(interiorTrim);
  let luggageObj = cloneDeep(luggage);
  let seatsObj = cloneDeep(seats);
  let sunroofObj = cloneDeep(sunroof);
  let bodyObj = cloneDeep(body);
  let grillObj = cloneDeep(grill);
  let glassObj = cloneDeep(glass);
  let exteriorObj = cloneDeep(exterior);
  let tiresObj = cloneDeep(tires);
  let additionalObj = cloneDeep(additional);

  Object.entries(vinObj).map(([name]) => (vinObj[name] = 'Yes'));
  Object.entries(consultObj).map(([name]) => (consultObj[name] = 'Yes'));
  Object.entries(engineObj).map(([name]) => (engineObj[name] = 'Yes'));
  Object.entries(coolingObj).map(([name]) => (coolingObj[name] = 'Yes'));
  Object.entries(fuelObj).map(([name]) => (fuelObj[name] = 'Yes'));
  Object.entries(electricalObj).map(([name]) => (electricalObj[name] = 'Yes'));
  Object.entries(transmissionObj).map(
    ([name]) => (transmissionObj[name] = 'Yes'),
  );
  Object.entries(hybridObj).map(([name]) => (hybridObj[name] = 'Yes'));
  Object.entries(suspensionObj).map(([name]) => (suspensionObj[name] = 'Yes'));
  Object.entries(brakesObj).map(([name]) => (brakesObj[name] = 'Yes'));
  Object.entries(unibodyObj).map(([name]) => (unibodyObj[name] = 'Yes'));
  Object.entries(roadObj).map(([name]) => (roadObj[name] = 'Yes'));
  Object.entries(heatingObj).map(([name]) => (heatingObj[name] = 'Yes'));
  Object.entries(windowObj).map(([name]) => (windowObj[name] = 'Yes'));
  Object.entries(airObj).map(([name]) => (airObj[name] = 'Yes'));
  Object.entries(audioObj).map(([name]) => (audioObj[name] = 'Yes'));
  Object.entries(interiorObj).map(([name]) => (interiorObj[name] = 'Yes'));
  Object.entries(interiorTrimObj).map(
    ([name]) => (interiorTrimObj[name] = 'Yes'),
  );
  Object.entries(luggageObj).map(([name]) => (luggageObj[name] = 'Yes'));
  Object.entries(seatsObj).map(([name]) => (seatsObj[name] = 'Yes'));
  Object.entries(sunroofObj).map(([name]) => (sunroofObj[name] = 'Yes'));
  Object.entries(bodyObj).map(([name]) => (bodyObj[name] = 'Yes'));
  Object.entries(grillObj).map(([name]) => (grillObj[name] = 'Yes'));
  Object.entries(glassObj).map(([name]) => (glassObj[name] = 'Yes'));
  Object.entries(exteriorObj).map(([name]) => (exteriorObj[name] = 'Yes'));
  Object.entries(tiresObj).map(([name]) => (tiresObj[name] = 'Yes'));
  Object.entries(additionalObj).map(([name]) => (additionalObj[name] = 'Yes'));

  const [vinValues, setVinValues] = useState(vinObj);
  const [consultValues, setConsultValues] = useState(consultObj);
  const [engineValues, setEngineValues] = useState(engineObj);
  const [coolingValues, setCoolingValues] = useState(coolingObj);
  const [fuelValues, setFuelValues] = useState(fuelObj);
  const [electricalValues, setElectricalValues] = useState(electricalObj);
  const [transmissionValues, setTransmissionValues] = useState(transmissionObj);
  const [hybridValues, setHybridValues] = useState(hybridObj);
  const [suspensionValues, setSuspensionValues] = useState(suspensionObj);
  const [brakesValues, setBrakesValues] = useState(brakesObj);
  const [unibodyValues, setUnibodyValues] = useState(unibodyObj);
  const [roadValues, setRoadValues] = useState(roadObj);
  const [heatingValues, setHeatingValues] = useState(heatingObj);
  const [windowValues, setWindowValues] = useState(windowObj);
  const [airValues, setAirValues] = useState(airObj);
  const [audioValues, setAudioValues] = useState(audioObj);
  const [interiorValues, setInteriorValues] = useState(interiorObj);
  const [interiorTrimValues, setInteriorTrimValues] = useState(interiorTrimObj);
  const [luggageValues, setLuggageValues] = useState(luggageObj);
  const [seatsValues, setSeatsValues] = useState(seatsObj);
  const [sunroofValues, setSunroofValues] = useState(sunroofObj);
  const [bodyValues, setBodyValues] = useState(bodyObj);
  const [grillValues, setGrillValues] = useState(grillObj);
  const [glassValues, setGlassValues] = useState(glassObj);
  const [exteriorValues, setExteriorValues] = useState(exteriorObj);
  const [tiresValues, setTiresValues] = useState(tiresObj);
  const [additionalValues, setAdditionalValues] = useState(additionalObj);

  const handleVin = e => {
    const { name, value } = e.target;
    setVinValues(prevVinValues => ({
      ...prevVinValues,
      [name]: value,
    }));
  };
  const handleConsult = e => {
    const { name, value } = e.target;
    setConsultValues(prevConsultValues => ({
      ...prevConsultValues,
      [name]: value,
    }));
  };
  const handleEngine = e => {
    const { name, value } = e.target;
    setEngineValues(prevEngineValues => ({
      ...prevEngineValues,
      [name]: value,
    }));
  };
  const handleCooling = e => {
    const { name, value } = e.target;
    setCoolingValues(prevCoolingValues => ({
      ...prevCoolingValues,
      [name]: value,
    }));
  };
  const handleFuel = e => {
    const { name, value } = e.target;
    setFuelValues(prevFuelValues => ({
      ...prevFuelValues,
      [name]: value,
    }));
  };
  const handleElectrical = e => {
    const { name, value } = e.target;
    setElectricalValues(prevElectricalValues => ({
      ...prevElectricalValues,
      [name]: value,
    }));
  };
  const handleTransmission = e => {
    const { name, value } = e.target;
    setTransmissionValues(prevTransmissionValues => ({
      ...prevTransmissionValues,
      [name]: value,
    }));
  };
  const handleHybrid = e => {
    const { name, value } = e.target;
    setHybridValues(prevHybridValues => ({
      ...prevHybridValues,
      [name]: value,
    }));
  };
  const handleSuspension = e => {
    const { name, value } = e.target;
    setSuspensionValues(prevSuspensionValues => ({
      ...prevSuspensionValues,
      [name]: value,
    }));
  };
  const handleBrakes = e => {
    const { name, value } = e.target;
    setBrakesValues(prevBrakesValues => ({
      ...prevBrakesValues,
      [name]: value,
    }));
  };
  const handleUnibody = e => {
    const { name, value } = e.target;
    setUnibodyValues(prevUnibodyValues => ({
      ...prevUnibodyValues,
      [name]: value,
    }));
  };
  const handleRoad = e => {
    const { name, value } = e.target;
    setRoadValues(prevRoadValues => ({
      ...prevRoadValues,
      [name]: value,
    }));
  };
  const handleHeating = e => {
    const { name, value } = e.target;
    setHeatingValues(prevHeatingValues => ({
      ...prevHeatingValues,
      [name]: value,
    }));
  };
  const handleWindow = e => {
    const { name, value } = e.target;
    setWindowValues(prevWindowValues => ({
      ...prevWindowValues,
      [name]: value,
    }));
  };
  const handleAir = e => {
    const { name, value } = e.target;
    setAirValues(prevAirValues => ({
      ...prevAirValues,
      [name]: value,
    }));
  };
  const handleAudio = e => {
    const { name, value } = e.target;
    setAudioValues(prevAudioValues => ({
      ...prevAudioValues,
      [name]: value,
    }));
  };
  const handleInterior = e => {
    const { name, value } = e.target;
    setInteriorValues(prevInteriorValues => ({
      ...prevInteriorValues,
      [name]: value,
    }));
  };
  const handleInteriorTrim = e => {
    const { name, value } = e.target;
    setInteriorTrimValues(prevInteriorTrimValues => ({
      ...prevInteriorTrimValues,
      [name]: value,
    }));
  };
  const handleLuggage = e => {
    const { name, value } = e.target;
    setLuggageValues(prevLuggageValues => ({
      ...prevLuggageValues,
      [name]: value,
    }));
  };
  const handleSeats = e => {
    const { name, value } = e.target;
    setSeatsValues(prevSeatsValues => ({
      ...prevSeatsValues,
      [name]: value,
    }));
  };
  const handleSunroof = e => {
    const { name, value } = e.target;
    setSunroofValues(prevSunroofValues => ({
      ...prevSunroofValues,
      [name]: value,
    }));
  };
  const handleBody = e => {
    const { name, value } = e.target;
    setBodyValues(prevBodyValues => ({
      ...prevBodyValues,
      [name]: value,
    }));
  };
  const handleGrill = e => {
    const { name, value } = e.target;
    setGrillValues(prevGrillValues => ({
      ...prevGrillValues,
      [name]: value,
    }));
  };
  const handleGlass = e => {
    const { name, value } = e.target;
    setGlassValues(prevGlassValues => ({
      ...prevGlassValues,
      [name]: value,
    }));
  };
  const handleExterior = e => {
    const { name, value } = e.target;
    setExteriorValues(prevExteriorValues => ({
      ...prevExteriorValues,
      [name]: value,
    }));
  };
  const handleTires = e => {
    const { name, value } = e.target;
    setTiresValues(prevTiresValues => ({
      ...prevTiresValues,
      [name]: value,
    }));
  };
  const handleAdditional = e => {
    const { name, value } = e.target;
    setAdditionalValues(prevAdditionalValues => ({
      ...prevAdditionalValues,
      [name]: value,
    }));
  };

  const [consent, setConsent] = useState(false);
  const handleConsent = () => {
    setConsent(consent => !consent);
  };

  const [confirm, setConfirm] = useState(false);
  const handleConfirm = () => {
    setConfirm(confirm => !confirm);
  };

  const wholeForm = useRef(null);

  const handleSubmit = e => {
    e.preventDefault();

    if (!consent) {
      enqueueSnackbar(`Please check 'I agree' and then submit the inspection!`);
      return;
    }

    const values = {
      ...vinValues,
      ...consultValues,
      ...engineValues,
      ...coolingValues,
      ...fuelValues,
      ...electricalValues,
      ...transmissionValues,
      ...hybridValues,
      ...suspensionValues,
      ...brakesValues,
      ...unibodyValues,
      ...roadValues,
      ...heatingValues,
      ...windowValues,
      ...airValues,
      ...audioValues,
      ...interiorValues,
      ...interiorTrimValues,
      ...luggageValues,
      ...seatsValues,
      ...sunroofValues,
      ...bodyValues,
      ...grillValues,
      ...glassValues,
      ...exteriorValues,
      ...tiresValues,
      ...additionalValues,
    };
    const form_data = {
      infiniti: values,
      amvic_number: wholeForm.current['amvic_number'].value,
    };

    props.formData(form_data);
    props.formClose();
  };

  const GreenButton = withStyles({
    root: {
      color: '#fff',
      backgroundColor: '#198754',
      '&:hover': {
        backgroundColor: '#157347',
      },
    },
  })(Button);

  return (
    <form onSubmit={handleSubmit} ref={wholeForm}>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>VIN/TSBs/RECALLS/DTCs/MAINTENANCE</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(vin).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid
                    item
                    xs={6}
                    style={{ paddingLeft: '1rem', paddingBottom: '.5rem' }}
                  >
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={vinValues}
                      handleGroup={handleVin}
                      name={name}
                      orange={false}
                      white={true}
                      greenValue="Yes"
                      redValue="No"
                      whiteValue="NA"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>CONSULT III PLUS</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(consult).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid
                    item
                    xs={6}
                    style={{ paddingLeft: '1rem', paddingBottom: '.5rem' }}
                  >
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={consultValues}
                      handleGroup={handleConsult}
                      name={name}
                      orange={false}
                      white={true}
                      greenValue="Yes"
                      redValue="No"
                      whiteValue="NA"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Engine Mechanical Condition</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(engine).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid
                    item
                    xs={6}
                    style={{ paddingLeft: '1rem', paddingBottom: '.5rem' }}
                  >
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={engineValues}
                      handleGroup={handleEngine}
                      name={name}
                      orange={false}
                      white={true}
                      greenValue="Yes"
                      redValue="No"
                      whiteValue="NA"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Cooling System</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(cooling).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid
                    item
                    xs={6}
                    style={{ paddingLeft: '1rem', paddingBottom: '.5rem' }}
                  >
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={coolingValues}
                      handleGroup={handleCooling}
                      name={name}
                      orange={false}
                      white={true}
                      greenValue="Yes"
                      redValue="No"
                      whiteValue="NA"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Fuel System</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(fuel).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid
                    item
                    xs={6}
                    style={{ paddingLeft: '1rem', paddingBottom: '.5rem' }}
                  >
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={fuelValues}
                      handleGroup={handleFuel}
                      name={name}
                      orange={false}
                      white={true}
                      greenValue="Yes"
                      redValue="No"
                      whiteValue="NA"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Electrical System</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(electrical).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid
                    item
                    xs={6}
                    style={{ paddingLeft: '1rem', paddingBottom: '.5rem' }}
                  >
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={electricalValues}
                      handleGroup={handleElectrical}
                      name={name}
                      orange={false}
                      white={true}
                      greenValue="Yes"
                      redValue="No"
                      whiteValue="NA"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Transmission/Differential/Transaxle/Transfer Case</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(transmission).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid
                    item
                    xs={6}
                    style={{ paddingLeft: '1rem', paddingBottom: '.5rem' }}
                  >
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={transmissionValues}
                      handleGroup={handleTransmission}
                      name={name}
                      orange={false}
                      white={true}
                      greenValue="Yes"
                      redValue="No"
                      whiteValue="NA"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Hybrid System</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(hybrid).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid
                    item
                    xs={6}
                    style={{ paddingLeft: '1rem', paddingBottom: '.5rem' }}
                  >
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={hybridValues}
                      handleGroup={handleHybrid}
                      name={name}
                      orange={false}
                      white={true}
                      greenValue="Yes"
                      redValue="No"
                      whiteValue="NA"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Suspension/Steering</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(suspension).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid
                    item
                    xs={6}
                    style={{ paddingLeft: '1rem', paddingBottom: '.5rem' }}
                  >
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={suspensionValues}
                      handleGroup={handleSuspension}
                      name={name}
                      orange={false}
                      white={true}
                      greenValue="Yes"
                      redValue="No"
                      whiteValue="NA"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Brakes/Exhaust System</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(brakes).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid
                    item
                    xs={6}
                    style={{ paddingLeft: '1rem', paddingBottom: '.5rem' }}
                  >
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={brakesValues}
                      handleGroup={handleBrakes}
                      name={name}
                      orange={false}
                      white={true}
                      greenValue="Yes"
                      redValue="No"
                      whiteValue="NA"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Body Frame</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(unibody).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid
                    item
                    xs={6}
                    style={{ paddingLeft: '1rem', paddingBottom: '.5rem' }}
                  >
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={unibodyValues}
                      handleGroup={handleUnibody}
                      name={name}
                      orange={false}
                      white={true}
                      greenValue="Yes"
                      redValue="No"
                      whiteValue="NA"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>ROAD TEST</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(road).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid
                    item
                    xs={6}
                    style={{ paddingLeft: '1rem', paddingBottom: '.5rem' }}
                  >
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={roadValues}
                      handleGroup={handleRoad}
                      name={name}
                      orange={false}
                      white={true}
                      greenValue="Yes"
                      redValue="No"
                      whiteValue="NA"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Heating/Ventilation/AC/Defogger/Defrost</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(heating).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid
                    item
                    xs={6}
                    style={{ paddingLeft: '1rem', paddingBottom: '.5rem' }}
                  >
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={heatingValues}
                      handleGroup={handleHeating}
                      name={name}
                      orange={false}
                      white={true}
                      greenValue="Yes"
                      redValue="No"
                      whiteValue="NA"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Window/Door Lock Operation</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(window).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid
                    item
                    xs={6}
                    style={{ paddingLeft: '1rem', paddingBottom: '.5rem' }}
                  >
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={windowValues}
                      handleGroup={handleWindow}
                      name={name}
                      orange={false}
                      white={true}
                      greenValue="Yes"
                      redValue="No"
                      whiteValue="NA"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Air Bags/Seat Belts</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(air).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid
                    item
                    xs={6}
                    style={{ paddingLeft: '1rem', paddingBottom: '.5rem' }}
                  >
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={airValues}
                      handleGroup={handleAir}
                      name={name}
                      orange={false}
                      white={true}
                      greenValue="Yes"
                      redValue="No"
                      whiteValue="NA"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Audio/Alarm/Navigation Systems</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(audio).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid
                    item
                    xs={6}
                    style={{ paddingLeft: '1rem', paddingBottom: '.5rem' }}
                  >
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={audioValues}
                      handleGroup={handleAudio}
                      name={name}
                      orange={false}
                      white={true}
                      greenValue="Yes"
                      redValue="No"
                      whiteValue="NA"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Interior Amenities</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(interior).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid
                    item
                    xs={6}
                    style={{ paddingLeft: '1rem', paddingBottom: '.5rem' }}
                  >
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={interiorValues}
                      handleGroup={handleInterior}
                      name={name}
                      orange={false}
                      white={true}
                      greenValue="Yes"
                      redValue="No"
                      whiteValue="NA"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Interior Trim/Carpet/Floor Mats</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(interiorTrim).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid
                    item
                    xs={6}
                    style={{ paddingLeft: '1rem', paddingBottom: '.5rem' }}
                  >
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={interiorTrimValues}
                      handleGroup={handleInteriorTrim}
                      name={name}
                      orange={false}
                      white={true}
                      greenValue="Yes"
                      redValue="No"
                      whiteValue="NA"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Luggage Compartment/Trunk</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(luggage).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid
                    item
                    xs={6}
                    style={{ paddingLeft: '1rem', paddingBottom: '.5rem' }}
                  >
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={luggageValues}
                      handleGroup={handleLuggage}
                      name={name}
                      orange={false}
                      white={true}
                      greenValue="Yes"
                      redValue="No"
                      whiteValue="NA"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Seats</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(seats).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid
                    item
                    xs={6}
                    style={{ paddingLeft: '1rem', paddingBottom: '.5rem' }}
                  >
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={seatsValues}
                      handleGroup={handleSeats}
                      name={name}
                      orange={false}
                      white={true}
                      greenValue="Yes"
                      redValue="No"
                      whiteValue="NA"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Sunroof/Moonroof/Convertible Top</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(sunroof).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid
                    item
                    xs={6}
                    style={{ paddingLeft: '1rem', paddingBottom: '.5rem' }}
                  >
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={sunroofValues}
                      handleGroup={handleSunroof}
                      name={name}
                      orange={false}
                      white={true}
                      greenValue="Yes"
                      redValue="No"
                      whiteValue="NA"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Body Panels/Bumpers/Fascias</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(body).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid
                    item
                    xs={6}
                    style={{ paddingLeft: '1rem', paddingBottom: '.5rem' }}
                  >
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={bodyValues}
                      handleGroup={handleBody}
                      name={name}
                      orange={false}
                      white={true}
                      greenValue="Yes"
                      redValue="No"
                      whiteValue="NA"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Grill/Trim/Molding/Roof Rack, Etc.</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(grill).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid
                    item
                    xs={6}
                    style={{ paddingLeft: '1rem', paddingBottom: '.5rem' }}
                  >
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={grillValues}
                      handleGroup={handleGrill}
                      name={name}
                      orange={false}
                      white={true}
                      greenValue="Yes"
                      redValue="No"
                      whiteValue="NA"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Glass/Mirror/Wiper and Washer Condition</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(glass).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid
                    item
                    xs={6}
                    style={{ paddingLeft: '1rem', paddingBottom: '.5rem' }}
                  >
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={glassValues}
                      handleGroup={handleGlass}
                      name={name}
                      orange={false}
                      white={true}
                      greenValue="Yes"
                      redValue="No"
                      whiteValue="NA"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Exterior Lights</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(exterior).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid
                    item
                    xs={6}
                    style={{ paddingLeft: '1rem', paddingBottom: '.5rem' }}
                  >
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={exteriorValues}
                      handleGroup={handleExterior}
                      name={name}
                      orange={false}
                      white={true}
                      greenValue="Yes"
                      redValue="No"
                      whiteValue="NA"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Tires & Wheels</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(tires).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid
                    item
                    xs={6}
                    style={{ paddingLeft: '1rem', paddingBottom: '.5rem' }}
                  >
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={tiresValues}
                      handleGroup={handleTires}
                      name={name}
                      orange={false}
                      white={true}
                      greenValue="Yes"
                      redValue="No"
                      whiteValue="NA"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>ADDITIONAL DETAIL ITEMS</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(additional).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid
                    item
                    xs={6}
                    style={{ paddingLeft: '1rem', paddingBottom: '.5rem' }}
                  >
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={additionalValues}
                      handleGroup={handleAdditional}
                      name={name}
                      orange={false}
                      white={true}
                      greenValue="Yes"
                      redValue="No"
                      whiteValue="NA"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Typography style={{ marginTop: '15px' }}>
        I verify the contents of this document are true and correct.
      </Typography>
      <FormControlLabel
        control={<Checkbox />}
        label="I agree"
        onChange={handleConsent}
        value={consent}
      />
      <br />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Technician Name"
            value={props.user.display_name}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Tech #"
            value={props.user.goUserProfile.profile.sales_licence}
            fullWidth
            name={'amvic_number'}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Date"
            value={new Date().toLocaleString()}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <Button
            type="button"
            variant="contained"
            style={{ marginRight: '15px' }}
            onClick={handleConfirm}
            color="default"
          >
            Cancel
          </Button>
          <Dialog
            open={confirm}
            onClose={handleConfirm}
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Current inspection will be closed and unsaved data will be gone.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleConfirm}>Cancel</Button>
              <Button onClick={props.formClose} color="secondary">
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
          <GreenButton type="submit" variant="contained">
            Save
          </GreenButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default InfinitiCPOForm;
