import React, { useEffect } from 'react';

/* external */
import { NetworkStatus } from 'apollo-boost';
import { useHistory } from 'react-router-dom';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { Waypoint } from 'react-waypoint';
import gql from 'graphql-tag';

/* Material-UI */
import { makeStyles } from '@mui/styles';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { useTheme } from '@mui/material';

/* internal */
import {
  ACTIVE_ITEMS_COUNT_SORT,
  NAME_SORT,
  SORT_BY_ID,
  UPCOMING_ITEMS_COUNT_SORT,
} from './const';
import { usePersistedState } from 'utils';
import { useTimeSyncContext } from 'components/MaterialUI/TimeSyncContext';
import AuctionsTableRow from './AuctionsTableRow';
import DealerPicker from 'components/MaterialUI/DealerPicker';
import ErrorPage from 'components/MaterialUI/ErrorPage';
import Loading from 'components/MaterialUI/Loading';

const AUCTION_FRAGMENT = gql`
  fragment AuctionFragment on Auction {
    active
    canAdd
    canBid
    canBlindBid
    weekly {
      id
      lastEnd
      nextAdd
      nextBlind
      nextStart
    }
  }
`;
const AUCTIONS_QUERY = gql`
  query auctionsQuery(
    $filters: [QueryFilter]
    $page: Int!
    $sort: [QuerySortElement]
  ) {
    auctions {
      auctions(filters: $filters, page: $page, pageSize: 20, sort: $sort) {
        pagination {
          nextPage
          page
          total
          totalPages
        }
        results {
          id
          ...AuctionFragment
          ...AuctionsTableRowAuction
          ...AuctionsTableRowAuctionPermissions
        }
      }
    }
  }
  ${AUCTION_FRAGMENT}
  ${AuctionsTableRow.fragments.auction}
  ${AuctionsTableRow.fragments.auctionPermissions}
`;

const AUCTION_QUERY = gql`
  query auctionQuery($id: Int!) {
    auctions {
      auction(id: $id) {
        id
        ...AuctionFragment
        ...AuctionsTableRowAuction
        ...AuctionsTableRowAuctionPermissions
      }
    }
  }
  ${AUCTION_FRAGMENT}
  ${AuctionsTableRow.fragments.auction}
  ${AuctionsTableRow.fragments.auctionPermissions}
`;

// Note: Since statusText is only a property of an auction, it does not trigger
// a subscription update, so we must requery the auction when there are transition
// times (using the isAuctionStale helper function helps with this)
// TODO: verify that a change in auction item status (ie. upcoming -> active) will
// trigger sending an auction updated message.  This is important, since the requerying
// mentioned above will ONLY work for daily/weekly auctions where there is a
// specific pre-determined time where we know the status will change.

const AUCTION_SUBSCRIPTION = gql`
  subscription onAuctionUpdated($ids: [Int!]!) {
    auctionFeed(ids: $ids) {
      type
      auction {
        id
        ...AuctionFragment
        ...AuctionsTableRowAuction
      }
    }
  }
  ${AUCTION_FRAGMENT}
  ${AuctionsTableRow.fragments.auction}
`;

const useStyles = makeStyles(theme => ({
  roomForFab: {
    // bottom edge of fab box at 25, fab is 48 high, + 25 spacing on top
    paddingBottom: `${25 + 48 + 25}px`,
  },
  singleFab: {
    position: 'fixed',
    bottom: '25px',
    right: '25px',
  },
}));

// Modifications will be automatically merged in to query results, but
// we need to manually add/remove when creating/deleting an auction
const updateQuery = (prev, { subscriptionData }) => {
  const feedData = subscriptionData?.data.auctionFeed;
  const { results, pagination } = prev.auctions.auctions;
  if (feedData.type === 'deleted') {
    return Object.assign(
      {},
      {
        auctions: {
          auctions: {
            pagination: Object.assign({}, pagination, {
              total: pagination.total - 1,
            }),
            results: results.filter(x => x.id !== feedData.auction.id),
            __typename: prev.auctions.auctions.__typename,
          },
          __typename: prev.auctions.__typename,
        },
      },
    );
  } else if (feedData.type === 'created') {
    return Object.assign(
      {},
      {
        auctions: {
          auctions: {
            pagination: Object.assign({}, pagination, {
              total: pagination.total + 1,
            }),
            results: [
              ...results,
              Object.assign({}, feedData.auction, {
                permissions: {
                  update: false,
                  delete: false,
                  __typename: 'AuctionPermissions',
                },
              }),
            ],
            // just shove it at the end for now
            __typename: prev.auctions.auctions.__typename,
          },
          __typename: prev.auctions.__typename,
        },
      },
    );
  }
};

const AuctionsTable = () => {
  const [getAuction] = useLazyQuery(AUCTION_QUERY, {
    fetchPolicy: 'network-only',
  });
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const { clock, isElapsed } = useTimeSyncContext();
  const [sortSelection, setSortSelection] = usePersistedState(
    'auctions.components.auctions.AuctionsTable.sortSelection',
    null,
  );
  const [hideInactive, setHideInactive] = usePersistedState(
    'auctions.components.auctions.AuctionsTable.hideInactive',
    false,
  );
  const sort = [sortSelection, SORT_BY_ID].filter(x => x);

  const filters = [
    ...(hideInactive
      ? [{ model: 'Auction', field: 'active', op: '==', value: true }]
      : []),
  ];
  const { data, error, fetchMore, loading, networkStatus, subscribeToMore } =
    useQuery(AUCTIONS_QUERY, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      variables: { filters, page: 1, sort },
    });
  const auctions = data?.auctions.auctions.results ?? [];
  const { total, nextPage } = data?.auctions.auctions.pagination ?? {};

  // Check to see if any of the auctions that are loaded are stale.  If so, refetch that auction.

  const isAuctionStale = _auction => {
    const { active, canAdd, canBid, canBlindBid, weekly } = _auction;
    const { nextAdd, lastEnd, nextStart, nextBlind } = weekly || {};

    if (weekly && active) {
      if (!canAdd && !canBlindBid && !canBid && isElapsed(nextAdd)) return true;

      if (!canBlindBid && !canBid && isElapsed(nextBlind)) return true;

      if (!canBid && isElapsed(nextStart)) return true;

      if (canBid && isElapsed(lastEnd)) return true;
    }
    return false;
  };

  useEffect(() => {
    auctions.forEach(auction => {
      if (isAuctionStale(auction))
        getAuction({ variables: { id: auction.id } });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auctions, clock]);

  // Subscribe to updates once we load in some auctions.
  // This will automatically update our query results
  useEffect(() => {
    if (auctions.length) {
      // returns an unsubscribe function, so let's return that for cleanup
      return subscribeToMore({
        document: AUCTION_SUBSCRIPTION,
        variables: {
          ids: auctions.map(x => x.id),
        },
        updateQuery,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auctions]);

  const fetchMoreHandler = () => {
    if (nextPage)
      fetchMore({
        variables: { page: nextPage },
        updateQuery: (prev, { fetchMoreResult: more }) => {
          if (!more.auctions.auctions.results) return prev;

          return Object.assign({}, prev, {
            auctions: {
              __typename: prev.auctions.__typename,
              auctions: {
                __typename: prev.auctions.auctions.__typename,
                results: [
                  ...prev.auctions.auctions.results,
                  ...more.auctions.auctions.results,
                ],
                pagination: more.auctions.auctions.pagination,
              },
            },
          });
        },
      });
  };

  const isSortingBy = ({ model, field, direction }) =>
    sortSelection?.field === field &&
    sortSelection?.model === model &&
    (!direction || sortSelection?.direction === direction);

  const sortDirection = sortSelection?.direction;
  const clickSortHandler =
    ({ model, field }) =>
    () =>
      setSortSelection(prev => ({
        model,
        field,
        direction:
          prev?.model === model &&
          prev?.field === field &&
          prev?.direction === 'asc'
            ? 'desc'
            : 'asc',
      }));

  if (error) return <ErrorPage error={error} />;
  if (loading && !networkStatus === NetworkStatus.fetchMore) return <Loading />;

  return (
    <Box m={1} className={classes.roomForFab}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="stretch"
        marginBottom={1}
      >
        <DealerPicker />
        <Box marginRight={1} fontSize="18px" alignSelf="flex-end">
          Showing {auctions.length} of {total}
        </Box>
      </Box>
      <Box display="flex">
        <Box>
          <FormControlLabel
            control={
              <Switch
                checked={hideInactive}
                onChange={() => setHideInactive(prev => !prev)}
                color="primary"
              />
            }
            label="Hide Inactive"
          />
        </Box>
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>
              <TableSortLabel
                active={isSortingBy(NAME_SORT)}
                direction={sortDirection}
                onClick={clickSortHandler(NAME_SORT)}
              >
                Auction
              </TableSortLabel>
            </TableCell>
            <TableCell>Status</TableCell>
            <TableCell>
              <TableSortLabel
                active={isSortingBy(ACTIVE_ITEMS_COUNT_SORT)}
                direction={sortDirection}
                onClick={clickSortHandler(ACTIVE_ITEMS_COUNT_SORT)}
              >
                Active
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={isSortingBy(UPCOMING_ITEMS_COUNT_SORT)}
                direction={sortDirection}
                onClick={clickSortHandler(UPCOMING_ITEMS_COUNT_SORT)}
              >
                Upcoming
              </TableSortLabel>
            </TableCell>
            <TableCell>Add Items At</TableCell>
            <TableCell>BlindBid At</TableCell>
            <TableCell>Live Bidding At</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {auctions.map(auction => (
            <AuctionsTableRow auction={auction} key={auction.id} />
          ))}
        </TableBody>
      </Table>
      {!loading && <Waypoint onEnter={fetchMoreHandler} />}
      {networkStatus === NetworkStatus.fetchMore && (
        <Box>Loading more auctions...</Box>
      )}
      <Box className={classes.singleFab}>
        <Fab
          style={theme.actions.confirm}
          onClick={() => history.push('/auctions/new')}
          size="medium"
        >
          <AddIcon />
        </Fab>
      </Box>
    </Box>
  );
};

export default AuctionsTable;
