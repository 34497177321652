import React, { useState } from 'react';

/* external */
import { useSnackbar } from 'notistack';
import gql from 'graphql-tag';

/* internal */
import { paveCaptureLink } from 'modules/appraisals/utils';
import CodeTablesContext from 'components/MaterialUI/CodeTablesContext';
import PaveFormBox from './PaveFormBox';
import PaveOrManualSelectionBox from './PaveOrManualSelectionBox';
import TradeFormStepper from './TradeFormStepper';

const APPRAISAL_FRAGMENT = gql`
  fragment TradeFormContainerAppraisal on Appraisal {
    id
    opportunityId
    appraisalType
    firstName
    lastName
    ...TradeFormStepperAppraisal
  }
  ${TradeFormStepper.fragments.appraisal}
`;

const TradeFormContainer = ({
  appraisal,
  onCancel,
  onCreate,
  onUpdate,
  ...rest
}) => {
  const [showPaveForm, setShowPaveForm] = useState(null);

  const { enqueueSnackbar } = useSnackbar();

  const { id, vin } = appraisal ?? {};

  const handleUpdateSuccess = _appraisal => {
    enqueueSnackbar('Updated appraisal', { variant: 'success' });
    onUpdate(_appraisal);
  };

  const handleCreateSuccess = (_appraisal, options = {}) => {
    enqueueSnackbar('Created appraisal', { variant: 'success' });
    onCreate(_appraisal, options);
  };

  const handleOpenPave = appraisal => {
    handleCreateSuccess(appraisal, { closeEditor: true });
    window.open(
      paveCaptureLink(appraisal.paveSessionId),
      '_blank',
      'noopener, noreferrer',
    );
  };

  const handleCancel = () => {
    setShowPaveForm(null);
    onCancel();
  };

  const showPaveOrManual = showPaveForm === null && !id && !vin;
  const showTradeForm = id || vin || showPaveForm === false;
  const autoDecode = vin && !id; // We've started with a vin, so we should start by decoding it in the details step.

  return (
    <CodeTablesContext>
      {showPaveOrManual && (
        <PaveOrManualSelectionBox
          onClickPave={() => setShowPaveForm(true)}
          onClickManual={() => setShowPaveForm(false)}
          onCancel={handleCancel}
        />
      )}
      {showPaveForm && (
        <PaveFormBox
          appraisal={appraisal}
          onOpenPave={handleOpenPave}
          onCreate={handleCreateSuccess}
          onCancel={handleCancel}
        />
      )}
      {showTradeForm && (
        <TradeFormStepper
          autoDecode={autoDecode}
          onCancel={handleCancel}
          onCreate={handleCreateSuccess}
          onUpdate={handleUpdateSuccess}
          nonLinear={Boolean(id)}
          appraisal={appraisal}
        />
      )}
    </CodeTablesContext>
  );
};

TradeFormContainer.fragments = {
  appraisal: APPRAISAL_FRAGMENT,
};
export default TradeFormContainer;
