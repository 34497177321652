/* external */
import { useQuery } from '@apollo/react-hooks';
import { NetworkStatus } from 'apollo-boost';
import gql from 'graphql-tag';
import { cloneDeep, isEmpty } from 'lodash';
import React, { useState } from 'react';

/* Material UI */
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import FilterListIcon from '@mui/icons-material/FilterList';

/* internal */
import { NoteCard } from '../../notes/components';
import AddOpportunityNoteModal from './AddOpportunityNoteModal';

const useStyles = makeStyles(theme => ({
  addNote: {
    padding: '0px',
    margin: '0px 5px 0px 10px',
  },
  filterButton: {
    padding: '0px',
    margin: '0px 10px 0px 5px',
  },
  paper: {
    padding: '10px',
    margin: '10px',
    minHeight: '200px',
  },
  card: {
    marginBottom: '15px',
    marginTop: '15px',
  },
}));

const VISIBILITY_NOTES = {
  store_only: 'Store Only',
  managers_only: 'Managers Only',
  organization_wide: 'Org Wide Only',
  opp_assignees_only: 'Opp Assignees Only',
};

const NEW_NOTES_QUERY = gql`
  query getOpportunityNotes($_id: ID!) {
    opportunity(_id: $_id) {
      _id
      customer_id
      notes {
        body
        created
        visibility_display
        visibility
        dealer_id
        created_by {
          display_name
          role
        }
        created_by_username
        dealer {
          dealer_name
        }
      }
    }
  }
`;

const OpportunityNotes = ({ opportunity }) => {
  const classes = useStyles();
  const theme = useTheme();

  const [openModal, setOpenModal] = useState(false);
  const [hideSysNotes, setHideSysNotes] = useState('hide');
  const [visibility, setVisibility] = useState(undefined);
  const [visibilityList, setVisibilityList] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  const { data, loading, refetch, networkStatus } = useQuery(NEW_NOTES_QUERY, {
    variables: {
      _id: opportunity._id,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const handleVisibility = event => {
    let visibilities = cloneDeep(visibilityList);
    setVisibility(
      visibility === event.target.name ? undefined : event.target.name,
    );
    const index = visibilityList.indexOf(event.target.name);
    if (index === -1) {
      visibilities = [...visibilities, event.target.name];
    } else {
      visibilities.splice(index, 1);
    }
    setVisibilityList([...visibilities]);
  };

  const handleChipDelete = chipName => {
    let visibilities = cloneDeep(visibilityList);
    const index = visibilityList.indexOf(chipName);
    visibilities.splice(index, 1);
    setVisibilityList([...visibilities]);
  };

  const changeSystemNotes = () => {
    // Based on the previous state set to hide or show.
    setHideSysNotes(prev => (prev === 'hide' ? 'show' : 'hide'));
  };

  const handleFilterClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const refetchOnNewNote = () => {
    refetch();
  };

  if ((loading && !data) || networkStatus === NetworkStatus.refetch) {
    return (
      <Box textAlign="center" mt={4}>
        <CircularProgress />
        <Typography variant="subtitle1" gutterBottom>
          Loading
        </Typography>
      </Box>
    );
  }

  const results = (data?.opportunity?.notes ?? []).filter(note =>
    !isEmpty(visibilityList) ? visibilityList.includes(note.visibility) : true,
  );

  return (
    <>
      <Dialog
        maxWidth="sm"
        open={openModal}
        onClose={() => setOpenModal(false)}
        fullWidth={true}
      >
        <AddOpportunityNoteModal
          handleClose={() => setOpenModal(false)}
          onSave={refetchOnNewNote}
          opportunityId={opportunity._id}
        />
      </Dialog>
      <Paper className={classes.paper}>
        <span style={{ display: 'flex', padding: '10px' }}>
          <h3>Customer Notes</h3>
          <IconButton
            className={classes.addNote}
            onClick={() => setOpenModal(true)}
            size="small"
          >
            <AddIcon style={theme.actions.add} />
          </IconButton>
          <IconButton
            className={classes.filterButton}
            color="primary"
            onClick={handleFilterClick}
            size="large"
          >
            <FilterListIcon style={theme.actions.filter} />
          </IconButton>
          <Menu
            id="filter-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleFilterClose}
          >
            <MenuItem>
              <FormControlLabel
                label="Hide System Notes"
                control={
                  <Checkbox
                    name="system-notes"
                    onChange={changeSystemNotes}
                    checked={hideSysNotes === 'hide'}
                    color="primary"
                  />
                }
              />
            </MenuItem>
            <MenuItem>
              <FormControlLabel
                control={
                  <Checkbox
                    name="store_only"
                    onChange={handleVisibility}
                    checked={visibilityList.includes('store_only')}
                    color="primary"
                  />
                }
                label="Store Only"
              />
            </MenuItem>
            <MenuItem>
              <FormControlLabel
                label="Organization Wide"
                control={
                  <Checkbox
                    name="organization_wide"
                    onChange={handleVisibility}
                    checked={visibilityList.includes('organization_wide')}
                    color="primary"
                  />
                }
              />
            </MenuItem>
            <MenuItem>
              <FormControlLabel
                label="Manager Only"
                control={
                  <Checkbox
                    name="managers_only"
                    onChange={handleVisibility}
                    checked={visibilityList.includes('managers_only')}
                    color="primary"
                  />
                }
              />
            </MenuItem>
            <MenuItem>
              <FormControlLabel
                label="Opp Assignees Only"
                control={
                  <Checkbox
                    name="opp_assignees_only"
                    onChange={handleVisibility}
                    checked={visibilityList.includes('opp_assignees_only')}
                    color="primary"
                  />
                }
              />
            </MenuItem>
          </Menu>
        </span>
        <span style={{ display: 'flex', paddingLeft: '10px' }}>
          {hideSysNotes === 'hide' && (
            <Chip
              label="Hide System Notes"
              onDelete={changeSystemNotes}
              style={{ marginRight: '5px' }}
            />
          )}
          {visibilityList.map(vis => (
            <Chip
              name={vis}
              label={`${VISIBILITY_NOTES[vis]}`}
              onDelete={() => handleChipDelete(vis)}
              style={{ marginRight: '5px' }}
            />
          ))}
        </span>
        {results.length === 0 && 'There are no notes for this opportunity'}
        {results
          .filter(
            note =>
              hideSysNotes !== 'hide' || note.created_by_username !== null,
          )
          .map(note => (
            <Card className={classes.card} key={note._id}>
              <NoteCard note={note} />
            </Card>
          ))}
      </Paper>
    </>
  );
};

export default OpportunityNotes;
