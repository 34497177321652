import React from 'react';

/* External */
import gql from 'graphql-tag';
import { useHistory } from 'react-router-dom';

/* Material UI */
import { TableCell, TableRow } from '@mui/material';

/* Internal */
import ActionsMenu from './SMSActions/ActionsMenu';

const TableCellContent = ({
  path,
  children,
  clickable = true,
  align = 'left',
}) => {
  const history = useHistory();

  const handleClick = () => {
    if (clickable && path) {
      history.push(path);
    }
  };

  return (
    <TableCell
      onClick={handleClick}
      align={align}
      {...(clickable && { style: { cursor: 'pointer' } })}
    >
      {children}
    </TableCell>
  );
};

const GatewayRow = ({ gateway, cells, children }) => {
  return (
    <>
      <TableRow style={{ padding: '10px 0' }}>
        <TableCell>
          <ActionsMenu gateway={gateway} version="compact" />
        </TableCell>
        {cells.map((cell, index) => (
          <TableCellContent
            key={index}
            path={cell.path ? cell.path(gateway.id) : null}
            clickable={cell.clickable}
            align={cell.align}
          >
            {cell.content(gateway)}
          </TableCellContent>
        ))}
      </TableRow>
      {children}
    </>
  );
};

GatewayRow.fragments = {
  gateway: gql`
    fragment GatewayRowGateway on Gateway {
      id
      name
      phone_number
      unread_count
      user_unread_count
      username
      casl_stop_word
      enable_casl
      gateway_type
      user_subscription {
        gatewayId
        id
        notifyAllUnassignedItems
        notifyAllUnreadItems
        subscriberUsername
      }
    }
  `,
};

export default GatewayRow;
