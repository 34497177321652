import { groupBy, isEmpty } from 'lodash';

export const isSelectionSortingBy =
  sortSelection =>
  ({ model, field, direction }) =>
    sortSelection?.field === field &&
    sortSelection?.model === model &&
    (!direction || sortSelection?.direction === direction);

export const makeClickSortHandler =
  setSortSelection =>
  ({ model, field }) =>
  () =>
    setSortSelection(prev => ({
      model,
      field,
      direction:
        prev?.model === model &&
        prev?.field === field &&
        prev?.direction === 'asc'
          ? 'desc'
          : 'asc',
    }));

export const filtersFromFacets = filterFacets =>
  ifNullMakeOrIsNone(
    Object.entries(groupBy(filterFacets, x => [x.model, x.field])).map(
      ([modelField, entries]) => ({
        model: modelField.split(',')[0],
        field: modelField.split(',')[1],
        op: 'in',
        value: entries.map(({ value }) =>
          value === 'True' ? true : value === 'False' ? false : value,
        ),
      }),
    ),
  );

// replace any filters that have op: in, value: [something, something, null]
// with {or: [{op: in, value: [something, something]}, {op: is_null}]}
// Don't forget to account for the case when [null] is the only thing in the array.
const ifNullMakeOrIsNone = filters =>
  filters.map(({ model, field, op, value }) =>
    value.includes(null) && !isEmpty(value.filter(x => x))
      ? {
          or: [
            { model, field, op, value: value.filter(x => x) },
            { model, field, op: 'is_null' },
          ],
        }
      : value.includes(null)
      ? { model, field, op: 'is_null' }
      : { model, field, op, value },
  );

export const parseErrors = ({ graphQLErrors, networkError, message }) => {
  let returnErrors = [];
  if (!isEmpty(graphQLErrors))
    graphQLErrors.forEach(({ message, location, path, extensions }) => {
      if (extensions.response?.body) {
        const {
          body: { code, message: bodyMessage },
          status,
        } = extensions.response;
        returnErrors.push(
          `Error code: ${code}(${status}): ${bodyMessage ?? message}`,
        );
      } else if (message) {
        returnErrors.push(message);
      }
    });
  else if (!isEmpty(networkError))
    returnErrors.push(networkError.result?.errors.map(x => x.message));
  else if (!isEmpty(message)) returnErrors.push(message);
  else returnErrors.push('An unknown error occured.');
  return returnErrors.join(', ');
};
