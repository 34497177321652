import { CircularProgress, IconButton, InputAdornment } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import React, { useCallback, useEffect } from 'react';
import ValidationTextField from './ValidationTextField';

const InlineEdit = ({
  id,
  label,
  value,
  onCancel = () => null,
  onValidate = () => null,
  onSubmit,
  saving,
  startAdornment,
  ...props
}) => {
  const [showSubmit, setShowSubmit] = React.useState(false);
  const [submitDisabled, setSubmitDisabled] = React.useState(false);
  const [newValue, setNewValue] = React.useState(value ?? '');

  const submit = useCallback(() => {
    if (!submitDisabled) {
      if (value !== newValue) onSubmit(newValue);
      setShowSubmit(false);
    }
  }, [submitDisabled, value, newValue, onSubmit]);

  const abort = useCallback(() => {
    setNewValue(value || '');
    setShowSubmit(false);
    onCancel();
  }, [value, onCancel]);

  const onKeyDown = useCallback(
    e => {
      if (e.key === 'Enter') submit();
      else if (e.key === 'Escape' || e.key === 'Esc') abort();
    },
    [submit, abort],
  );

  const onFocus = useCallback(
    e => {
      if (!props.inputProps?.readOnly) {
        setShowSubmit(true);
        e.target.select();
      }
    },
    [props.inputProps],
  );

  const confirmButtonId = `confirm-button-${id}`;

  // This basically a MITM function that makes sure to dis/enable the submit button
  const validate = useCallback(
    value => {
      const res = onValidate(value);
      setSubmitDisabled(Boolean(res));
      return res;
    },
    [onValidate],
  );

  useEffect(() => {
    setNewValue(value);
  }, [value]);

  return (
    <ValidationTextField
      InputProps={{
        disableUnderline: props.inputProps?.readOnly,
        startAdornment: (
          <InputAdornment position="start">
            {startAdornment ?? ''}
          </InputAdornment>
        ),
        endAdornment:
          (showSubmit && !saving && (
            <InputAdornment position="end">
              <IconButton
                id={confirmButtonId}
                onClick={submit}
                style={{ margin: 0, padding: 0 }}
                disabled={submitDisabled}
                size="large"
              >
                <CheckCircleIcon />
              </IconButton>
            </InputAdornment>
          )) ||
          (saving && (
            <InputAdornment position="end">
              <CircularProgress size={20} />
            </InputAdornment>
          )),
      }}
      label={label}
      value={newValue}
      onBlur={e => {
        if (e.relatedTarget && e.relatedTarget.id === confirmButtonId) submit();
        else abort();
      }}
      onKeyDown={onKeyDown}
      onFocus={onFocus}
      onChange={e => setNewValue(e.target.value)}
      onValidate={validate}
      {...props}
    />
  );
};

export default InlineEdit;
