import React, { useState } from 'react';

// MaterialUI
import {
  Box,
  Chip,
  Grid,
  TableCell,
  TableRow,
  useMediaQuery,
  Dialog,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

// internal
import noIMG from 'modules/inventory/static/img/no-image.jpg';
import { RoleGroup } from 'constants.js';
import { FormatDateTZ } from 'components/MaterialUI';
import { useUserContext } from 'components/MaterialUI/UserContext';
import {
  cloudinaryCore,
  defaultTZ,
  getFullDateTime,
  formatPrice,
  formatOdometer,
  withSuffix,
} from 'utils';
import { COLOUR_MATCH, STATUS } from '../const';
import SellMyRideDetailView from './SellMyRideDetailView';

const SellMyRideTableRow = ({ row, refetch }) => {
  const [showDetails, setShowDetails] = useState(false);
  const { me, currentUser } = useUserContext();
  const isManager = RoleGroup.MANAGERS.includes(currentUser.role);

  const timezone = me?.goUserProfile?.settings?.timezone || defaultTZ;
  const desktop = useMediaQuery(data => data.breakpoints.up('sm'));

  const formatPhoto = (photo, options = {}) =>
    photo
      ? cloudinaryCore.url(photo.cloudinaryPublicId, {
          width: options.width || 100,
          crop: 'fit',
        })
      : noIMG;

  return (
    <>
      <Dialog
        maxWidth="md"
        fullWidth={true}
        scroll="paper"
        open={showDetails}
        onClose={() => {
          setShowDetails(false);
        }}
      >
        <SellMyRideDetailView
          row={row}
          handleClose={() => {
            setShowDetails(false);
          }}
          refetch={refetch}
        />
      </Dialog>
      <TableRow hover key={row?.id} onClick={() => setShowDetails(true)}>
        <TableCell>
          <Chip
            label={row.status}
            size={!desktop ? 'small' : 'medium'}
            style={{
              backgroundColor: COLOUR_MATCH[row.status].backgroundColor,
              color: COLOUR_MATCH[row.status].color,
            }}
          />
          {row.isPaveSessionComplete && (
            <Box>
              <br />
              <Chip
                label={
                  <Box
                    style={{
                      display: 'flex',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                      fontSize: '16px',
                    }}
                  >
                    <CheckCircleIcon style={{ marginRight: '0.25rem' }} /> Pave{' '}
                  </Box>
                }
                variant="outlined"
                size={!desktop ? 'small' : 'medium'}
                style={{ color: 'green', borderColor: 'green' }}
              />
            </Box>
          )}
        </TableCell>
        <TableCell>
          <Grid container direction="row" xs={12}>
            <Grid item xs={12} md={6}>
              {
                <img
                  alt="Vehicle"
                  style={{
                    width: 125,
                    objectFit: 'cover',
                  }}
                  src={formatPhoto(row.photos[0], {
                    width: 300,
                  })}
                />
              }
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              style={{
                display: 'flex',
                marginTop: 'auto',
                marginBottom: 'auto',
              }}
            >
              <Box>
                <Box style={{ fontWeight: 'bold' }}>
                  {row.customer.firstName} {row.customer.lastName}
                </Box>
                <Box>
                  {row.year} {row.make} {row.model} {row.trim}
                </Box>
                <Box style={{ color: 'grey' }}>{row.trimVariation}</Box>
                <Box>Odometer: {formatOdometer(row.odometer)}</Box>
              </Box>
            </Grid>
          </Grid>
        </TableCell>
        <TableCell>
          <FormatDateTZ date={row.createdAt} format="ll" nullDisplay="-" />
        </TableCell>
        <TableCell>
          <FormatDateTZ
            date={row.biddingStartsAt}
            format="ddd, D MMM YYYY, h:mm:ss a"
            nullDisplay="-"
            style={{ whiteSpace: 'nowrap' }}
          />
        </TableCell>
        <TableCell>
          <FormatDateTZ
            date={row.biddingEndsAt}
            format="ddd, D MMM YYYY, h:mm:ss a"
            nullDisplay="-"
            style={{ whiteSpace: 'nowrap' }}
          />
        </TableCell>
        <TableCell>
          {isManager
            ? formatPrice(
                row.bids?.sort((a, b) => b.amount - a.amount)[0]?.amount,
              )
            : row.winningBidAmount
            ? formatPrice(row.winningBidAmount, { nullDisplay: '-' })
            : formatPrice(row.myBid?.amount, { nullDisplay: '-' })}
        </TableCell>
        <TableCell>
          {row.winningBidAmount ? (
            <Box>
              <Box style={{ color: 'green' }}>ACCEPTED</Box>
              <Box>
                {getFullDateTime(
                  withSuffix(row.acceptedBid?.customerAcceptedDate, 'Z'),
                  timezone,
                )}
              </Box>
              {row.status !== STATUS.PURCHASED && row.pickupDate && (
                <Box fontStyle="italic" fontSize="10px">
                  Pickup Date:{' '}
                  {getFullDateTime(withSuffix(row.pickupDate, 'Z'), timezone)}
                </Box>
              )}
            </Box>
          ) : row.status === STATUS.DECLINED ? (
            <Box>
              <Box style={{ color: 'red' }}>DECLINED</Box>
              <Box>
                {getFullDateTime(withSuffix(row.declinedAt, 'Z'), timezone)}
              </Box>
            </Box>
          ) : (
            '-'
          )}
        </TableCell>
      </TableRow>
    </>
  );
};

export default SellMyRideTableRow;
