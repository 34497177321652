import React from 'react';

/* Material UI */
import { makeStyles } from '@mui/styles';
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import { Close as CloseIcon, Done as DoneIcon } from '@mui/icons-material';

/* internal */
import {
  YEAR_SORT,
  MAKE_SORT,
  MANUFACTURER_SORT,
  MODEL_SORT,
  COST_SORT,
  SPECIAL_PRICE_SORT,
  PRICE_SORT,
  PHOTO_COUNT_SORT,
  DAYS_SORT,
  STOCK_NUMBER_SORT,
  UPDATED_SORT,
} from 'modules/npv_inventory/const';
import { isSelectionSortingBy } from 'modules/npv_inventory/utils';

const useStyles = makeStyles(theme => ({
  iconSpacer: {
    paddingRight: '10px',
    width: '25px',
  },
  icon: {
    paddingRight: '10px',
    width: '25px',
    fontSize: 'small',
  },
}));

const sortOptions = [
  { ...COST_SORT, label: 'Cost' },
  { ...DAYS_SORT, label: 'Days In Stock' },
  { ...UPDATED_SORT, label: 'Last Modified' },
  { ...MAKE_SORT, label: 'Make' },
  { ...MANUFACTURER_SORT, label: 'Manufacturer' },
  { ...MODEL_SORT, label: 'Model' },
  { ...PHOTO_COUNT_SORT, label: 'Photo Count' },
  { ...PRICE_SORT, label: 'Price' },
  { ...SPECIAL_PRICE_SORT, label: 'Special Price' },
  { ...STOCK_NUMBER_SORT, label: 'Stock Number' },
  { ...YEAR_SORT, label: 'Year' },
]
  .map(sort => [
    { ...sort, direction: 'asc' },
    { ...sort, direction: 'desc' },
  ])
  .flat();

const SortDrawer = ({ onClose, sortSelection, setSortSelection, ...rest }) => {
  const isSortingBy = isSelectionSortingBy(sortSelection);
  const classes = useStyles();
  return (
    <Drawer onClose={onClose} {...rest}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box m={2}>
          <Typography variant="h5">Sort</Typography>
        </Box>
        <IconButton onClick={onClose} size="large">
          <CloseIcon />
        </IconButton>
      </Box>
      <Box>
        <List>
          {sortOptions.map(({ label, ...sortOption }) => (
            <ListItem
              button
              dense
              key={label}
              selected={isSortingBy(sortOption)}
              onClick={() => {
                setSortSelection(sortOption);
                onClose();
              }}
            >
              {isSortingBy(sortOption) ? (
                <DoneIcon className={classes.icon} />
              ) : (
                <Box className={classes.iconSpacer} />
              )}
              <ListItemText primary={`${label} ${sortOption.direction}`} />
            </ListItem>
          ))}
        </List>
      </Box>
    </Drawer>
  );
};

export default SortDrawer;
