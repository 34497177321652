import React, { useState } from 'react';

/* external */
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';

/* Material UI */
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useTheme } from '@mui/material';

const DEALER_SUB_STATUSES_QRY = gql`
  query Dealer($dealerId: Int!) {
    dealer(dealer_id: $dealerId) {
      dealer_name
      desking_settings {
        sub_statuses
      }
    }
  }
`;

const UPDATE_OPP_SUB_STATUS = gql`
  mutation updateOppStatuses(
    $id: ID!
    $statuses: UpdateOpportunityStatusInput!
  ) {
    updateOpportunityStatus(_id: $id, input: $statuses) {
      _id
      status
      sub_status
    }
  }
`;

const OpportunitySubStatusModal = ({ opportunity, onClose, isOpen }) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [subStatus, setSubStatus] = useState(opportunity.sub_status);
  const { loading, data } = useQuery(DEALER_SUB_STATUSES_QRY, {
    variables: { dealerId: opportunity.dealer_id },
  });
  const [updateSubStatus] = useMutation(UPDATE_OPP_SUB_STATUS, {
    onCompleted: () => {
      onClose();
      enqueueSnackbar('Sub Status Updated!', { variant: 'success' });
    },
    onError: e => {
      enqueueSnackbar(`An error occurred: ${e}`, { variant: 'error' });
    },
  });

  const { dealer } = !loading ? data : [];
  const subStatuses = dealer?.desking_settings?.sub_statuses || [];

  const handleChange = event => {
    setSubStatus(event.target.value);
  };

  const handleSubmit = () => {
    updateSubStatus({
      variables: {
        id: opportunity._id,
        statuses: {
          status: opportunity.status,
          sub_status: subStatus,
        },
      },
    });
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Update Opportunity Sub Status</DialogTitle>
      <DialogContent>
        <DialogContentText>Select a new sub status</DialogContentText>
      </DialogContent>
      <FormControl style={{ padding: '0px 24px 8px 24px' }}>
        <Select value={subStatus} onChange={handleChange}>
          <MenuItem value={null}>None</MenuItem>
          {subStatuses.map(value => (
            <MenuItem key={value} value={value}>
              {value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="outlined">
          Cancel
        </Button>
        <Button
          style={theme.actions.confirm}
          onClick={handleSubmit}
          variant="contained"
          color="primary"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OpportunitySubStatusModal;
