import React from 'react';

import { useDealerContext } from 'components/MaterialUI/DealerContext';
import gql from 'graphql-tag';
import { capitalize } from 'utils';

import { useQuery } from '@apollo/react-hooks';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { makeStyles } from '@mui/styles';
import DoneIcon from '@mui/icons-material/Done';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
/* Material UI */
import { DatePicker } from '@mui/x-date-pickers';
import Loading from 'components/MaterialUI/Loading';

import { Role } from 'constants.js';
import { TaskStatusNames, TaskStatusType } from '../constants';

const useStyles = makeStyles(theme => ({
  panel: {
    width: '100%',
    paddingLeft: '25px',
    paddingRight: '25px',
  },
  title: {
    fontSize: '20px',
    paddingBottom: '10px',
    fontWeight: 'bold',
  },
  section: {
    paddingBottom: '10px',
    outlineColor: 'black',
  },
  subPanel: {
    width: '100%',
  },
}));

const USERS_FOR_DEALER = gql`
  query UsersForDealer($dealerId: Int!) {
    users: users(
      dealer_ids: [$dealerId]
      status: active
      roles: [
        "${Role.SALES_REP}"
        "${Role.SALES_MANAGER}"
        "${Role.INTERNET_SALES_REP}"
        "${Role.BDC_REP}"
        "${Role.FINANCE_MANAGER}"
      ]
    ) {
      display_name
      username
      role
    }
  }
`;

const FilterDrawer = ({
  isManagerOrAbove,
  facetResults,
  dueFromDate,
  dueUntilDate,
  statuses,
  setStatuses,
  setDueFromDate,
  setDueUntilDate,
  types,
  setTypes,
  assignees,
  setAssignees,
  createdDateFrom,
  createdDateTo,
  setCreatedDateTo,
  setCreatedDateFrom,
  loading,
}) => {
  const classes = useStyles();
  const { dealerId } = useDealerContext();
  const { loading: usersLoading, data: usersData } = useQuery(
    USERS_FOR_DEALER,
    { variables: { dealerId } },
  );

  const deleteAllFacets = () => {
    setStatuses([]);
    setAssignees([]);
    setTypes([]);
    setDueUntilDate(null);
    setDueFromDate(null);
    setCreatedDateFrom(null);
    setCreatedDateTo(null);
  };

  const showReset =
    dueUntilDate ||
    dueFromDate ||
    createdDateFrom ||
    createdDateTo ||
    statuses.length > 0 ||
    assignees.length > 0 ||
    types.length > 0;

  const FACET_ARR_MAP = {
    status: {
      array: statuses,
      update: setStatuses,
      filter: ['0', '2', '1'],
    },
    assignee: {
      array: assignees,
      update: setAssignees,
      filter: usersData?.users
        .sort((a, b) =>
          String(a?.display_name).localeCompare(String(b?.display_name)),
        )
        .map(user => user.username),
    },
    type: {
      array: types,
      update: setTypes,
      filter: TaskStatusType,
    },
  };

  const isSelected = (key, value) => {
    if (key === 'status') {
      value = parseInt(value);
    }
    return FACET_ARR_MAP[key].array.includes(value);
  };

  const toggleFacet = (key, value) => {
    const delIfExistElseAppend = (key, val) => {
      if (key === 'status') {
        val = parseInt(val);
      }
      let arr = [...FACET_ARR_MAP[key].array];
      if (!arr.includes(val)) {
        arr.push(val);
      } else {
        arr.splice(arr.indexOf(val), 1);
      }
      return arr;
    };
    if (key !== 'assignee') {
      FACET_ARR_MAP[key].update(delIfExistElseAppend(key, value));
    } else if (key === 'assignee' && isManagerOrAbove) {
      FACET_ARR_MAP[key].update(delIfExistElseAppend(key, value));
    }
  };

  const lookUp = (arr, key) =>
    arr.find(({ value }) => key === value) ?? { value: key, total: 0 };

  return usersLoading || loading ? (
    <Loading />
  ) : (
    <List className={classes.panel}>
      <Box className={classes.title}>Filters</Box>
      {showReset && (
        <Box style={{ paddingBottom: '10px' }}>
          <Button style={{ color: 'red' }} onClick={deleteAllFacets}>
            <HighlightOffIcon style={{ paddingRight: '5px' }} /> RESET ALL
          </Button>
        </Box>
      )}
      {!usersLoading &&
        ['assignee', 'status', 'type'].map(facet => {
          return (
            <Box style={{ paddingBottom: '10px' }} key={`${facet}`}>
              <Accordion
                key={facet}
                defaultExpanded={FACET_ARR_MAP[facet].array.length > 0}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  {capitalize(facet)}
                </AccordionSummary>
                <AccordionDetails>
                  <List
                    component="div"
                    disablePadding
                    className={classes.subPanel}
                  >
                    {FACET_ARR_MAP[facet].filter.map((filter, index) => {
                      const { value, total } = lookUp(
                        facetResults[facet],
                        filter,
                      );
                      return (
                        <ListItem
                          disabled={loading || usersLoading}
                          dense
                          button
                          onClick={() => toggleFacet(facet, value)}
                          key={`${facet}${value}`}
                          selected={isSelected(facet, value)}
                        >
                          {isSelected(facet, value) ? (
                            <DoneIcon
                              style={{
                                fontSize: 'small',
                                paddingRight: '10px',
                                width: '25px',
                              }}
                            />
                          ) : (
                            <div
                              style={{ paddingRight: '10px', width: '25px' }}
                            >
                              {' '}
                            </div>
                          )}
                          <ListItemText
                            primary={`${
                              facet === 'status'
                                ? capitalize(TaskStatusNames[parseInt(filter)])
                                : facet === 'type'
                                ? capitalize(filter)
                                : usersData?.users[index].display_name
                            } (${total})`}
                          />
                        </ListItem>
                      );
                    })}
                  </List>
                </AccordionDetails>
              </Accordion>
            </Box>
          );
        })}
      <Box style={{ paddingBottom: '10px', maxWidth: '100%' }}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            Created
          </AccordionSummary>
          <AccordionDetails>
            <Box flexDirection="column" alignItems="stretch">
              <Box>From</Box>
              <Box>
                <DatePicker
                  autoOk
                  clearable
                  emptyLabel=""
                  format="YYYY-MM-DD"
                  onChange={date => setCreatedDateFrom(date)}
                  value={createdDateFrom}
                  variant="dialog"
                />
              </Box>
              <Box>To</Box>
              <Box>
                <DatePicker
                  autoOk
                  clearable
                  emptyLabel=""
                  format="YYYY-MM-DD"
                  onChange={date => setCreatedDateTo(date)}
                  value={createdDateTo}
                  variant="dialog"
                />
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
      <Box style={{ paddingBottom: '10px', maxWidth: '100%' }}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            Due Date
          </AccordionSummary>
          <AccordionDetails>
            <Box flexDirection="column" alignItems="stretch">
              <Box>From</Box>
              <Box>
                <DatePicker
                  autoOk
                  clearable
                  emptyLabel=""
                  format="YYYY-MM-DD"
                  onChange={date => setDueFromDate(date)}
                  value={dueFromDate}
                  variant="dialog"
                />
              </Box>
              <Box>To</Box>
              <Box>
                <DatePicker
                  autoOk
                  clearable
                  emptyLabel=""
                  format="YYYY-MM-DD"
                  onChange={date => setDueUntilDate(date)}
                  value={dueUntilDate}
                  variant="dialog"
                />
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    </List>
  );
};

export default FilterDrawer;
