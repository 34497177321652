import React from 'react';

/* Material UI */
import {
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  Typography,
} from '@mui/material';

import { makeStyles } from '@mui/styles';

import {
  Edit as EditIcon,
  NewReleases as NewReleasesIcon,
  Warning as WarningIcon,
  InsertDriveFile as InsertDriveFileIcon,
} from '@mui/icons-material';

/* internal */
import { BULLETIN_STATUS_DISPLAY, FRAUD_BULLETIN } from '../constant';
import { getMediumDate } from 'utils';

const useStyles = makeStyles(theme => ({
  listItem: {
    padding: ' 4px 6px 4px 6px',
    margin: '1em 0 1em 0',
  },
  caption: {
    fontSize: '0.75rem',
    color: '#6c757d',
  },
  bulletinIcon: {
    color: '#f89406',
    fontSize: 28,
    textAlign: 'center',
  },

  iconBox: {
    display: 'flex',
    justifyContent: 'center',
    padding: '0.5em 0 0.5em 0',
  },

  bulletinBody: {
    fontSize: 14,
    padding: '0.5em 0 0.5em 0',
    width: '95%',
  },
}));

const BulletinsList = ({
  bulletins,
  setSelected,
  setShowBulletinModal,
  setShowAttachmentModal,
}) => {
  const classes = useStyles();

  return (
    <List>
      {bulletins.length === 0 && 'There are no bulletins for this customer.'}
      {bulletins
        .sort((a, b) =>
          a.type === FRAUD_BULLETIN ? -1 : b.type === FRAUD_BULLETIN ? 1 : 0,
        )
        .map(bulletin => {
          const isFraud = bulletin.type === FRAUD_BULLETIN;
          return (
            <ListItem
              className={classes.listItem}
              key={bulletin?._id}
              style={{
                border: isFraud ? '1px solid #ff4c56' : '1px solid black',
              }}
            >
              <Grid container direction="row" justifyContent="space-around">
                <Grid>
                  <Box className={classes.iconBox}>
                    {isFraud ? (
                      <WarningIcon
                        className={classes.bulletinIcon}
                        style={{ color: '#ff4c56' }}
                      />
                    ) : (
                      <NewReleasesIcon className={classes.bulletinIcon} />
                    )}
                  </Box>
                </Grid>
                <Grid item xs={11}>
                  <Grid container direction="row">
                    <Grid
                      item
                      xs={6}
                      alignItems="flex-end"
                      style={{ display: 'flex' }}
                    >
                      <Box>
                        <Typography
                          style={{
                            fontSize: 16,
                            fontWeight: 'bold',
                            margin: 0,
                            color: isFraud ? '#ff4c56' : 'black',
                          }}
                        >
                          {BULLETIN_STATUS_DISPLAY[bulletin.type]}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box display="flex" justifyContent="flex-end">
                        {isFraud && (
                          <IconButton
                            style={{ marginRight: '0.5rem' }}
                            size="small"
                            onClick={() => {
                              setSelected(bulletin);
                              setShowAttachmentModal(true);
                            }}
                          >
                            <InsertDriveFileIcon />
                          </IconButton>
                        )}
                        <IconButton
                          size="small"
                          onClick={() => {
                            setSelected(bulletin);
                            setShowBulletinModal(true);
                          }}
                        >
                          <EditIcon style={{ color: '#6c757d' }} />
                        </IconButton>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      lg={4}
                      justifyContent="space-between"
                    >
                      <Typography variant="caption" className={classes.caption}>
                        {`Added: ${getMediumDate(bulletin.created)} by ${
                          bulletin.created_by?.display_name
                        }`}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <Typography
                        variant="caption"
                        className={classes.caption}
                        style={{ textAlign: 'end' }}
                      >
                        {`Edited: ${getMediumDate(bulletin.updated)}`}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Box display="flex">
                      <Typography className={classes.bulletinBody}>
                        {bulletin.body}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </ListItem>
          );
        })}
    </List>
  );
};

export default BulletinsList;
