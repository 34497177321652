import React from 'react';

import { useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Grid';

import AppointmentCard from './AppointmentCard';
import ContactInformationCard from './ContactInformationCard';
import Profile from './Profile';

const SummaryCards = ({ customer, refetch }) => {
  const desktop = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const medium = useMediaQuery(theme => theme.breakpoints.between('sm', 'lg'));

  if (medium) {
    return (
      <>
        <Grid
          container
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'stretch'}
          style={{ paddingLeft: '12px', paddingRight: '26px', margin: 0 }}
          spacing={1}
        >
          <Grid item xs={6} style={{ minWidth: '300px' }}>
            <Profile customer={customer} refetch={refetch} />
          </Grid>
          <Grid item xs={6} style={{ minWidth: '300px' }}>
            <ContactInformationCard customer={customer} />
          </Grid>
        </Grid>
        <Grid
          container
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'stretch'}
          style={{ paddingLeft: '12px', paddingRight: '12px', margin: 0 }}
          spacing={2}
        >
          <Grid item xs={12} style={{ minWidth: '350px' }}>
            <AppointmentCard customer={customer} />
          </Grid>
        </Grid>
      </>
    );
  }

  return (
    <Grid
      container
      direction={desktop ? 'row' : 'column'}
      justifyContent={desktop ? 'space-between' : 'center'}
      alignItems={desktop ? 'stretch' : 'center'}
      style={{ paddingLeft: '12px', paddingRight: '26px', margin: 0 }}
      spacing={2}
    >
      <Grid
        item
        xs={4}
        style={desktop ? { minWidth: '250px' } : { minWidth: '100%' }}
      >
        <Profile customer={customer} refetch={refetch} />
      </Grid>
      <Grid
        item
        xs={4}
        style={desktop ? { minWidth: '250px' } : { minWidth: '100%' }}
      >
        <ContactInformationCard customer={customer} />
      </Grid>
      <Grid
        item
        xs={4}
        style={desktop ? { minWidth: '250px' } : { minWidth: '100%' }}
      >
        <AppointmentCard customer={customer} />
      </Grid>
    </Grid>
  );
};
export default SummaryCards;
