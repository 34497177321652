export const PREV_ACCOUNTING_LIST = [
  {
    desc: 'Cheque Req / Copy of Payout / CarProof',
    a: 'CHECK1',
    f: 'CHECK2',
  },
  {
    desc: 'Profit Summary / ADP Deal Recap',
    a: 'CHECK3',
    f: 'CHECK4',
  },
  {
    desc: 'Bill of Sale',
    a: 'CHECK5',
    f: 'CHECK6',
  },
  {
    desc: 'Receipts for Down Payment / Go Card Redemption (Attach BoS)',
    a: 'CHECK7',
    f: 'CHECK8',
  },
  {
    desc: 'Programs and Rebates',
    a: 'CHECK9',
    f: 'CHECK10',
  },
  {
    desc: 'Signed Bank Contracts',
    a: 'CHECK11',
    f: 'CHECK12',
  },
  {
    desc: 'Registration for New / Subprime Deal',
    a: 'CHECK13',
    f: 'CHECK14',
  },
  {
    desc: 'Proof of Insurance',
    a: 'CHECK15',
    f: 'CHECK16',
  },
  {
    desc: 'Copy of Payout (lienholder name on BOS)',
    a: 'CHECK17',
    f: 'CHECK18',
  },
];

export const PREV_TRADE_LIST = [
  {
    desc: 'CarProof (manager sign on decs, liens signed off by director',
    a: 'CHECK19',
    f: 'CHECK20',
  },
  {
    desc: 'Letter of Release (for all existing liens other than payout)',
    a: 'CHECK21',
    f: 'CHECK22',
  },
  {
    desc: 'Registration for Trade (signed and dated)',
    a: 'CHECK23',
    f: 'CHECK24',
  },
  {
    desc: 'Market Appraisal',
    a: 'CHECK25',
    f: 'CHECK26',
  },
];

export const PREV_USED_ONLY_LIST = [
  {
    desc: 'CarProof for purchased vehicle (signed and decs signed as well)',
    a: 'CHECK27',
    f: 'CHECK28',
    pdf: false,
  },
  {
    desc: 'Vehicle inspection signed',
    a: 'CHECK29',
    f: 'CHECK30',
    pdf: false,
  },
  {
    desc: 'B Car Disclosure Form',
    a: 'CHECK31',
    f: 'CHECK32',
    pdf: 'used_disclosure',
  },
];

export const PREV_CAR_POLICY = [
  {
    desc: 'Disclosure Form',
    a: 'CHECK33',
    f: 'CHECK34',
    info: 'https://goauto-deallog-info-sheets.s3.us-west-2.amazonaws.com/GA_2CarDisclosureInfo.pdf',
    pdf: 'two_car_disclosure',
  },
  {
    desc: 'Notes from both lenders acknowledging 2 units',
    a: 'CHECK35',
    f: 'CHECK36',
    info: false,
    pdf: false,
  },
];

export const PREV_US_DISCLAIMER = [
  {
    desc: 'If ever registered in the US.',
    a: 'CHECK37',
    f: 'CHECK38',
    pdf: false,
  },
  {
    desc: 'Disclaimer Form',
    a: 'CHECK39',
    f: 'CHECK40',
    pdf: 'us_disclaimer',
  },
];

export const PREV_GST_EXEMPT_LIST = [
  {
    desc: 'Picture from Reserve',
    a: 'CHECK41',
    f: 'CHECK42',
    info: false,
    pdf: false,
  },
  {
    desc: '2 Exemption Forms',
    a: 'CHECK43',
    f: 'CHECK44',
    info: 'https://goauto-deallog-info-sheets.s3.us-west-2.amazonaws.com/GA_FirstNationsInfo.pdf',
    pdf: 'gst_exemption',
  },
  {
    desc: 'Treaty Card (both sides and not expired)',
    a: 'CHECK45',
    f: 'CHECK46',
    info: false,
    pdf: false,
  },
];

export const PREV_NO_TITLE_CHECKLIST = [
  {
    desc: 'Worksheet',
    a: 'CHECK47',
  },
  {
    desc: 'RDR',
    a: 'CHECK48',
  },
  {
    desc: 'Invoice',
    a: 'CHECK49',
  },
  {
    desc: 'Void Cheque',
    a: 'CHECK50',
  },
  {
    desc: 'PDI Checklists',
    a: 'CHECK51',
  },
  {
    desc: 'Quality Delivery Checklists',
    a: 'CHECK3000',
  },
];

export const PREV_ADDS_CHECKLIST = [
  {
    desc: 'Warranty',
    a: 'CHECK52',
  },
  {
    desc: 'Propack',
    a: 'CHECK53',
  },
  {
    desc: 'Life and Disability',
    a: 'CHECK54',
  },
  {
    desc: 'Etch',
    a: 'CHECK55',
  },
  {
    desc: 'Road Hazard',
    a: 'CHECK56',
  },
  {
    desc: 'Other',
    a: 'CHECK57',
  },
  {
    desc: 'Cert Numbers in C screen',
    a: 'CHECK58',
  },
];

export const PREV_STANDARD_CHECKLIST = [
  {
    desc: 'Pitch Sheet',
    a: 'CHECK60',
  },
  {
    desc: 'Go Auto Product Waiver',
    a: 'CHECK61',
  },
  {
    desc: 'Privacy Act - Buyer and Co-buyer',
    a: 'CHECK62',
  },
  {
    desc: 'Go Auto Communication Consent',
    a: 'CHECK63',
  },
  {
    desc: 'Logged in First Canadian',
    a: 'CHECK64',
  },
  {
    desc: 'Deal Booked in ADP',
    a: 'CHECK65',
  },
  {
    desc: 'Go Card Activated',
    a: 'CHECK3001',
  },
  {
    desc: 'Go Card Referral',
    a: 'CHECK66',
  },
];

export const PREV_STIPS_CHECKLIST = [
  {
    desc: 'ID (matches BOS and Contract)',
    a: 'CHECK67',
  },
  {
    desc: 'Signed Go Auto Credit Application',
    a: 'CHECK68',
  },
  {
    desc: 'Void Cheque',
    a: 'CHECK69',
  },
  {
    desc: 'Proof of Income',
    a: 'CHECK70',
  },
  {
    desc: 'References',
    a: 'CHECK71',
  },
  {
    desc: 'Insurance and Registration',
    a: 'CHECK72',
  },
  {
    desc: 'Proof of Residence',
    a: 'CHECK73',
  },
  {
    desc: 'Email must be entered into ADP',
    a: 'CHECK74',
  },
];

export const ACCOUNTING_LIST = [
  {
    desc: 'Profit Summary/CDK Deal Recap',
    a: 'accounting_1_a',
    f: 'accounting_1_f',
  },
  {
    desc: 'Bill of Sale (BOS)',
    a: 'accounting_2_a',
    f: 'accounting_2_f',
  },
  {
    desc: 'Receipts for down payment/Go Card redemption (attach BOS)',
    a: 'accounting_3_a',
    f: 'accounting_3_f',
  },
  {
    desc: 'Programs & Rebates',
    a: 'accounting_4_a',
    f: 'accounting_4_f',
  },
  {
    desc: 'Signed Bank Contracts',
    a: 'accounting_5_a',
    f: 'accounting_5_f',
  },
  {
    desc: 'Registration for New/Subprime Deal',
    a: 'accounting_6_a',
    f: 'accounting_6_f',
  },
  {
    desc: 'Proof of Insurance & Registration',
    a: 'accounting_7_a',
    f: 'accounting_7_f',
  },
];

export const GENERATE_TRADE_LIST = tradeNum => {
  const TRADE_LIST = [
    {
      desc: 'CarProof (manager sign on decs, liens signed off by Director',
      a: `trade${tradeNum}_1_a`,
      f: `trade${tradeNum}_1_f`,
    },
    {
      desc: 'Letter of Release (for all existing liens other than payout)',
      a: `trade${tradeNum}_2_a`,
      f: `trade${tradeNum}_2_f`,
    },
    {
      desc: 'Registration for Trade (signed and dated)',
      a: `trade${tradeNum}_3_a`,
      f: `trade${tradeNum}_3_f`,
    },
    {
      desc: 'Market Appraisal (ACV)',
      a: `trade${tradeNum}_4_a`,
      f: `trade${tradeNum}_4_f`,
    },
    {
      desc: 'Cheque Req/Copy of Payout/CarProof',
      a: `trade${tradeNum}_5_a`,
      f: `trade${tradeNum}_5_f`,
    },
    {
      desc: 'Copy of Payout (lienholder name on BOS)',
      a: `trade${tradeNum}_6_a`,
      f: `trade${tradeNum}_6_f`,
    },
  ];
  return TRADE_LIST;
};

export const USED_ONLY_LIST = [
  {
    desc: 'CarProof for purchased vehicle (signed and decs signed)',
    a: 'used_1_a',
    f: 'used_1_f',
    pdf: false,
  },
  {
    desc: 'Vehicle Safety/Inspection signed',
    a: 'used_2_a',
    f: 'used_2_f',
    pdf: false,
  },
  {
    desc: 'B Car Disclosure Form',
    a: 'used_3_a',
    f: 'used_3_f',
    pdf: 'used_disclosure',
  },
];

export const CAR_POLICY = [
  {
    desc: 'Disclosure Form',
    a: '2car_1_a',
    f: '2car_1_f',
    info: 'https://goauto-deallog-info-sheets.s3.us-west-2.amazonaws.com/GA_2CarDisclosureInfo.pdf',
    pdf: 'two_car_disclosure',
  },
  {
    desc: 'Notes from both lenders acknowledging 2 units',
    a: '2car_2_a',
    f: '2car_2_f',
    info: false,
    pdf: false,
  },
];

export const US_DISCLAIMER = [
  {
    desc: 'If ever registered in the U.S.',
    a: 'us_1_a',
    f: 'us_1_f',
    pdf: false,
  },
  {
    desc: 'Disclaimer Form',
    a: 'us_2_a',
    f: 'us_2_f',
    pdf: 'us_disclaimer',
  },
];

export const GST_EXEMPT_LIST = [
  {
    desc: 'Picture from Reserve',
    a: 'fn_1_a',
    f: 'fn_1_f',
    info: false,
    pdf: false,
  },
  {
    desc: '2 Exemption Forms',
    a: 'fn_2_a',
    f: 'fn_2_f',
    info: 'https://goauto-deallog-info-sheets.s3.us-west-2.amazonaws.com/GA_FirstNationsInfo.pdf',
    pdf: 'gst_exemption',
  },
  {
    desc: 'Treaty Card (both sides and not expired)',
    a: 'fn_3_a',
    f: 'fn_3_f',
    info: false,
    pdf: false,
  },
];

export const LUXURY_TAX_LIST = [
  {
    desc: 'Luxury tax included on BOS',
    a: 'lux_1_a',
    f: 'lux_1_f',
  },
  {
    desc: 'Detail Calculation proven',
    a: 'lux_2_a',
    f: 'lux_2_f',
  },
  {
    desc: 'Exemption certificate provided',
    a: 'lux_3_a',
    f: 'lux_3_f',
  },
];

export const DOCUMENTS_CHECKLIST = [
  {
    desc: 'Worksheet',
    a: 'docs_1',
  },
  {
    desc: 'RDR',
    a: 'docs_2',
  },
  {
    desc: 'Invoice',
    a: 'docs_3',
  },
  {
    desc: 'PDI Checklists',
    a: 'docs_4',
  },
  {
    desc: 'Quality Delivery Checklists',
    a: 'docs_5',
  },
  {
    desc: 'Safety Checklist',
    a: 'docs_6',
  },
];

export const ADDS_CHECKLIST = [
  {
    desc: 'Cert Numbers in C Screen',
    a: 'finance_1',
  },
  {
    desc: 'Warranty',
    a: 'finance_2',
  },
  {
    desc: 'On the side',
    a: 'finance_2_a',
  },
  {
    desc: '3 months | 5,000km (used only)',
    a: 'finance_2_b',
  },
  {
    desc: 'Propack',
    a: 'finance_3',
  },
  {
    desc: 'Life and Disability',
    a: 'finance_4',
  },
  {
    desc: 'Etch',
    a: 'finance_5',
  },
  {
    desc: 'Road Hazard',
    a: 'finance_6',
  },
  {
    desc: 'Other',
    a: 'finance_7',
  },
];

export const STANDARD_CHECKLIST = [
  {
    desc: 'Pitch Sheet',
    a: 'stan_1',
  },
  {
    desc: 'Go Auto Product Waiver',
    a: 'stan_2',
  },
  {
    desc: 'Privacy Act - Buyer and Co-Buyer',
    a: 'stan_3',
  },
  {
    desc: 'Go Auto Communication Consent',
    a: 'stan_4',
  },
  {
    desc: 'Logged in First Canadian',
    a: 'stan_5',
  },
  {
    desc: 'Deal Booked in CDK',
    a: 'stan_6',
  },
  {
    desc: 'Go Card Activated',
    a: 'stan_7',
  },
  {
    desc: 'Number in CDK',
    a: 'stan_7a',
  },
  {
    desc: 'Go Card Referral',
    a: 'stan_8',
  },
];

export const STIPS_CHECKLIST = [
  {
    desc: 'ID (matches BOS and Contract)',
    a: 'stip_1',
  },
  {
    desc: 'Signed Go Auto Credit Application',
    a: 'stip_2',
  },
  {
    desc: 'Void Cheque',
    a: 'stip_3',
  },
  {
    desc: 'Proof of Income',
    a: 'stip_4',
  },
  {
    desc: 'References',
    a: 'stip_5',
  },
  {
    desc: 'Insurance and Registration',
    a: 'stip_6',
  },
  {
    desc: 'Proof of Residence',
    a: 'stip_7',
  },
  {
    desc: 'Email must be entered into CDK',
    a: 'stip_8',
  },
];

// BC DEALERS ONLY

export const BC_IZEV_CEV_CHECKLIST = [
  {
    desc: 'IZEV Federal incentive submission conf.',
    a: 'izev_cev_1',
  },
  {
    desc: 'CEV Customer pre-approval confirmation',
    a: 'izev_cev_2',
  },
  {
    desc: 'CEV Submission confirmation',
    a: 'izev_cev_3',
  },
];

export const BC_PST_CHECKLIST = [
  {
    desc: 'Non-resident PST Exemption form',
    a: 'pst_1',
  },
];

// ON DEALERS ONLY

export const ON_DISCLOSURE_CHECKLIST = [
  {
    desc: 'Ontario Additional Lease Disclosure',
    a: 'ont_1',
  },
  {
    desc: 'If Business, RIN (registrant identification number) attached',
    a: 'ont_1_a',
  },
];

export const DEAL_AUDIT_CHECKLIST = [
  {
    desc: 'Vehicle description page present in file',
    yes: 'deal_1_yes',
    no: 'deal_1_no',
    na: 'deal_1_na',
  },
  {
    desc: 'Net sale price does not exceed advertised price',
    yes: 'deal_2_yes',
    no: 'deal_2_no',
    na: 'deal_2_na',
  },
  {
    desc: 'Excise Tax/Tire/Levies included in net sale price calculation',
    yes: 'deal_3_yes',
    no: 'deal_3_no',
    na: 'deal_3_na',
  },
  {
    desc: 'Parts & Accessories itemized on BOS separate from net sale price',
    yes: 'deal_4_yes',
    no: 'deal_4_no',
    na: 'deal_4_na',
  },
  {
    desc: 'F&I products itemized on Bill of Sale separate from net sale price',
    yes: 'deal_5_yes',
    no: 'deal_5_no',
    na: 'deal_5_na',
  },
  {
    desc: 'Any supplied goods at no charge referenced on BOS as “No charge”',
    yes: 'deal_6_yes',
    no: 'deal_6_no',
    na: 'deal_6_na',
  },
  {
    desc: 'Additional sales tax outside of dealership jurisdiction utilized in CDK',
    yes: 'deal_7_yes',
    no: 'deal_7_no',
    na: 'deal_7_na',
  },
];

// OEM SPECIFIC CHECKLISTS

export const AUDI_CHECKLIST = [
  {
    desc: 'Vehicle reported with the correct “Kind of Sale” in OMD at time of reporting',
    yes: 'oem_1_yes',
    no: 'oem_1_no',
    na: 'oem_1_na',
  },
  {
    desc: 'Vehicle reported sold in OMD within 2 business days of the sale date',
    yes: 'oem_2_yes',
    no: 'oem_2_no',
    na: 'oem_2_na',
  },
  {
    desc: '100% of applicable recalls/campaigns/required vehicle updates completed before delivery',
    yes: 'oem_3_yes',
    no: 'oem_3_no',
    na: 'oem_3_na',
  },
  {
    desc: 'Pre-Delivery Inspection completed prior to vehicle reporting (New and CPO)',
    yes: 'oem_4_yes',
    no: 'oem_4_no',
    na: 'oem_4_na',
  },
];

export const BMW_CHECKLIST = [
  {
    desc: 'OEM program sheet with utilizeds programs highlighted',
    yes: 'oem_1_yes',
    no: 'oem_1_no',
    na: 'oem_1_na',
  },
  {
    desc: 'Loyalty Rate utilized and administered correctly',
    yes: 'oem_2_yes',
    no: 'oem_2_no',
    na: 'oem_2_na',
  },
  {
    desc: 'Loyalty Cash utilized and administered correctly',
    yes: 'oem_3_yes',
    no: 'oem_3_no',
    na: 'oem_3_na',
  },
  {
    desc: 'Proof of export checklist (X5, X7, IX, “M”)',
    yes: 'oem_4_yes',
    no: 'oem_4_no',
    na: 'oem_4_na',
  },
  {
    desc: 'Insurance Binder',
    yes: 'oem_5_yes',
    no: 'oem_5_no',
    na: 'oem_5_na',
  },
  {
    desc: 'Registration in file',
    yes: 'oem_6_yes',
    no: 'oem_6_no',
    na: 'oem_6_na',
  },
];

export const FORD_CHECKLIST = [
  {
    desc: 'Ford Employee and Partner Purchase Program Deal',
    yes: 'oem_1_yes',
    no: 'oem_1_no',
    na: 'oem_1_na',
  },
  {
    desc: 'Net sale price = Plan pricing',
    yes: 'oem_2_yes',
    no: 'oem_2_no',
    na: 'oem_2_na',
  },
  {
    desc: 'Ford Delivery Allowance passed on to customer',
    yes: 'oem_3_yes',
    no: 'oem_3_no',
    na: 'oem_3_na',
  },
  {
    desc: 'Price Protection itemized on BOS if applicable',
    yes: 'oem_4_yes',
    no: 'oem_4_no',
    na: 'oem_4_na',
  },
  {
    desc: 'Trade allowance = Actual Cash Value',
    yes: 'oem_5_yes',
    no: 'oem_5_no',
    na: 'oem_5_na',
  },
  {
    desc: 'No charge incentives included in deal',
    yes: 'oem_6_yes',
    no: 'oem_6_no',
    na: 'oem_6_na',
  },
  {
    desc: '8401 A-E Form present and signed by customer',
    yes: 'oem_7_yes',
    no: 'oem_7_no',
    na: 'oem_7_na',
  },
  {
    desc: 'Delivery Allowance Worksheet - Form 8402D in file',
    yes: 'oem_8_yes',
    no: 'oem_8_no',
    na: 'oem_8_na',
  },
  {
    desc: 'Worksheet details match deal details',
    yes: 'oem_9_yes',
    no: 'oem_9_no',
    na: 'oem_9_na',
  },
  {
    desc: 'End user vehicle registration in file',
    yes: 'oem_10_yes',
    no: 'oem_10_no',
    na: 'oem_10_na',
  },
  {
    desc: 'Owner Loyalty Offer itemized on BOS as pre-tax rebate',
    yes: 'oem_11_yes',
    no: 'oem_11_no',
    na: 'oem_11_na',
  },
];

export const GENESIS_CHECKLIST = [
  {
    desc: 'Delivery confirmation',
    yes: 'oem_1_yes',
    no: 'oem_1_no',
    na: 'oem_1_na',
  },
  {
    desc: 'Cheque and Deposit slip',
    yes: 'oem_2_yes',
    no: 'oem_2_no',
    na: 'oem_2_na',
  },
  {
    desc: 'Bank contract (if a lease or finance deal)',
    yes: 'oem_3_yes',
    no: 'oem_3_no',
    na: 'oem_3_na',
  },
  {
    desc: 'Loyalty customers (Genesis or Hyundai)',
    yes: 'oem_4_yes',
    no: 'oem_4_no',
    na: 'oem_4_na',
  },
  {
    desc: 'Previous registration submitted to Genesis',
    yes: 'oem_5_yes',
    no: 'oem_5_no',
    na: 'oem_5_na',
  },
];

export const GM_CHECKLIST = [
  {
    desc: 'Consolidated Acknowledgement form',
    yes: 'oem_1_yes',
    no: 'oem_1_no',
    na: 'oem_1_na',
  },
  {
    desc: 'Attachment “A” Forms - Preferred/Employee',
    yes: 'oem_2_yes',
    no: 'oem_2_no',
    na: 'oem_2_na',
  },
  {
    desc: 'Authorization Numbers - Costco/Preferred',
    yes: 'oem_3_yes',
    no: 'oem_3_no',
    na: 'oem_3_na',
  },
  {
    desc: 'Preferred/Employee - Proof of relationship for transferability (if applicable)',
    yes: 'oem_4_yes',
    no: 'oem_4_no',
    na: 'oem_4_na',
  },
  {
    desc: 'Preferred/Employee - Proof of Spousal Relationship (if applicable)',
    yes: 'oem_5_yes',
    no: 'oem_5_no',
    na: 'oem_5_na',
  },
  {
    desc: 'GM Vehicle retention standards form/Non-Export',
    yes: 'oem_6_yes',
    no: 'oem_6_no',
    na: 'oem_6_na',
  },
  {
    desc: 'Charge by Chevrolet Acknowledgement Form (On EV)',
    yes: 'oem_7_yes',
    no: 'oem_7_no',
    na: 'oem_7_na',
  },
  {
    desc: 'Rebates itemized on BOS',
    yes: 'oem_8_yes',
    no: 'oem_8_no',
    na: 'oem_8_na',
  },
];

export const HONDA_CHECKLIST = [
  {
    desc: 'All dealer rebates itemized on BOS',
    yes: 'oem_1_yes',
    no: 'oem_1_no',
    na: 'oem_1_na',
  },
  {
    desc: 'Loyalty forms if loyalty was given',
    yes: 'oem_2_yes',
    no: 'oem_2_no',
    na: 'oem_2_na',
  },
  {
    desc: 'Honda Canada invoice in file',
    yes: 'oem_3_yes',
    no: 'oem_3_no',
    na: 'oem_3_na',
  },
  {
    desc: 'PIN redemption claim from E biz for targeted offer',
    yes: 'oem_4_yes',
    no: 'oem_4_no',
    na: 'oem_4_na',
  },
];

export const HYUNDAI_CHECKLIST = [
  {
    desc: 'All dealer rebates itemized on BOS',
    yes: 'oem_1_yes',
    no: 'oem_1_no',
    na: 'oem_1_na',
  },
  {
    desc: 'Loyalty forms if loyalty was given',
    yes: 'oem_2_yes',
    no: 'oem_2_no',
    na: 'oem_2_na',
  },
];

export const INFINITI_CHECKLIST = [
  {
    desc: 'Program landscape page highlighted and signed',
    yes: 'oem_1_yes',
    no: 'oem_1_no',
    na: 'oem_1_na',
  },
  {
    desc: 'Cash rebate itemized on BOS',
    yes: 'oem_2_yes',
    no: 'oem_2_no',
    na: 'oem_2_na',
  },
  {
    desc: 'Loyalty/Grad/Military rebate itemized on BOS',
    yes: 'oem_3_yes',
    no: 'oem_3_no',
    na: 'oem_3_na',
  },
  {
    desc: 'Proof of Loyalty/Grad/Military eligibility',
    yes: 'oem_4_yes',
    no: 'oem_4_no',
    na: 'oem_4_na',
  },
  {
    desc: 'VPP customer claim form with plan code',
    yes: 'oem_5_yes',
    no: 'oem_5_no',
    na: 'oem_5_na',
  },
  {
    desc: 'Non-export form in file',
    yes: 'oem_6_yes',
    no: 'oem_6_no',
    na: 'oem_6_na',
  },
];

export const JLR_CHECKLIST = [
  {
    desc: 'All dealer rebates itemized on BOS',
    yes: 'oem_1_yes',
    no: 'oem_1_no',
    na: 'oem_1_na',
  },
  {
    desc: 'Loyalty forms if loyalty was given',
    yes: 'oem_2_yes',
    no: 'oem_2_no',
    na: 'oem_2_na',
  },
  {
    desc: 'Conquest Rebate itemized on BOS',
    yes: 'oem_3_yes',
    no: 'oem_3_no',
    na: 'oem_3_na',
  },
  {
    desc: 'Conquest Rebate form signed in file',
    yes: 'oem_4_yes',
    no: 'oem_4_no',
    na: 'oem_4_na',
  },
  {
    desc: 'Export review & “know your customer” form in file',
    yes: 'oem_5_yes',
    no: 'oem_5_no',
    na: 'oem_5_na',
  },
];

export const KIA_CHECKLIST = [
  {
    desc: 'Kia Loyalty 1% Rate Reduction',
    yes: 'oem_1_yes',
    no: 'oem_1_no',
    na: 'oem_1_na',
  },
  {
    desc: 'Graduate Incentive',
    yes: 'oem_2_yes',
    no: 'oem_2_no',
    na: 'oem_2_na',
  },
  {
    desc: 'Military Incentive',
    yes: 'oem_3_yes',
    no: 'oem_3_no',
    na: 'oem_3_na',
  },
  {
    desc: 'Mobility Incentive',
    yes: 'oem_4_yes',
    no: 'oem_4_no',
    na: 'oem_4_na',
  },
  {
    desc: 'First-Time Kia Buyer Incentive',
    yes: 'oem_5_yes',
    no: 'oem_5_no',
    na: 'oem_5_na',
  },
  {
    desc: 'Loan Credit',
    yes: 'oem_6_yes',
    no: 'oem_6_no',
    na: 'oem_6_na',
  },
];

export const MAZDA_CHECKLIST = [
  {
    desc: 'Registration in file',
    yes: 'oem_1_yes',
    no: 'oem_1_no',
    na: 'oem_1_na',
  },
  {
    desc: 'Loyalty program form signed and in file',
    yes: 'oem_2_yes',
    no: 'oem_2_no',
    na: 'oem_2_na',
  },
  {
    desc: 'Proof of loyalty eligibility',
    yes: 'oem_3_yes',
    no: 'oem_3_no',
    na: 'oem_3_na',
  },
  {
    desc: 'Grad Rebate utilized',
    yes: 'oem_4_yes',
    no: 'oem_4_no',
    na: 'oem_4_na',
  },
  {
    desc: 'Proof of Grad Rebate eligibility',
    yes: 'oem_5_yes',
    no: 'oem_5_no',
    na: 'oem_5_na',
  },
];

export const MITSUBISHI_CHECKLIST = [
  {
    desc: 'BOS to indicate any rebates: ie: Loyalty, first responder, etc.',
    yes: 'oem_1_yes',
    no: 'oem_1_no',
    na: 'oem_1_na',
  },
  {
    desc: 'Copies of rebate certs',
    yes: 'oem_2_yes',
    no: 'oem_2_no',
    na: 'oem_2_na',
  },
  {
    desc: 'Copy of Manufacturer warranty, if applicable customer',
    yes: 'oem_3_yes',
    no: 'oem_3_no',
    na: 'oem_3_na',
  },
  {
    desc: 'IZEV Rebate disclosed on BOS, all supporting docs signed by customer, reimbursement submitted',
    yes: 'oem_4_yes',
    no: 'oem_4_no',
    na: 'oem_4_na',
  },
  {
    desc: 'CEV Rebate disclosed on BOS, all supporting docs signed by customer, reimbursement submitted',
    yes: 'oem_5_yes',
    no: 'oem_5_no',
    na: 'oem_5_na',
  },
];

export const NISSAN_CHECKLIST = [
  {
    desc: 'Loyalty Rebate itemized on BOS',
    yes: 'oem_1_yes',
    no: 'oem_1_no',
    na: 'oem_1_na',
  },
  {
    desc: 'NCF Loyalty form in file',
    yes: 'oem_2_yes',
    no: 'oem_2_no',
    na: 'oem_2_na',
  },
  {
    desc: 'Proof of loyalty eligibility',
    yes: 'oem_3_yes',
    no: 'oem_3_no',
    na: 'oem_3_na',
  },
  {
    desc: 'VPP (Vehicle Purchase Program) administered correctly',
    yes: 'oem_4_yes',
    no: 'oem_4_no',
    na: 'oem_4_na',
  },
  {
    desc: 'Military Discount Program administered correctly',
    yes: 'oem_5_yes',
    no: 'oem_5_no',
    na: 'oem_5_na',
  },
];

export const PORSCHE_CHECKLIST = [
  {
    desc: 'Vehicle sold with Porsche Warranty Extension',
    yes: 'oem_1_yes',
    no: 'oem_1_no',
    na: 'oem_1_na',
  },
  {
    desc: 'CPO',
    yes: 'oem_2_yes',
    no: 'oem_2_no',
    na: 'oem_2_na',
  },
  {
    desc: 'Extension Certificate competed and signed',
    yes: 'oem_3_yes',
    no: 'oem_3_no',
    na: 'oem_3_na',
  },
  {
    desc: 'Warranty registered',
    yes: 'oem_4_yes',
    no: 'oem_4_no',
    na: 'oem_4_na',
  },
  {
    desc: 'Pre-paid maintenance sold',
    yes: 'oem_5_yes',
    no: 'oem_5_no',
    na: 'oem_5_na',
  },
  {
    desc: 'PSMP registered',
    yes: 'oem_6_yes',
    no: 'oem_6_no',
    na: 'oem_6_na',
  },
  {
    desc: 'Vehicle sold Luxury Tax Exempt with form completed',
    yes: 'oem_7_yes',
    no: 'oem_7_no',
    na: 'oem_7_na',
  },
  {
    desc: 'Non-Export/Know your Customer Checklist completed and signed (targeted vehicles)',
    yes: 'oem_8_yes',
    no: 'oem_8_no',
    na: 'oem_8_na',
  },
  {
    desc: 'Right of First Refusal completed and signed',
    yes: 'oem_9_yes',
    no: 'oem_9_no',
    na: 'oem_9_na',
  },
  {
    desc: 'Porsche Loyalty/Match and applied on contract',
    yes: 'oem_10_yes',
    no: 'oem_10_no',
    na: 'oem_10_na',
  },
  {
    desc: 'All paperwork submitted',
    yes: 'oem_11_yes',
    no: 'oem_11_no',
    na: 'oem_11_na',
  },
];

export const STELLANTIS_CHECKLIST = [
  {
    desc: 'Non-Export Form',
    yes: 'oem_1_yes',
    no: 'oem_1_no',
    na: 'oem_1_na',
  },
  {
    desc: 'Claim Entry Report',
    yes: 'oem_2_yes',
    no: 'oem_2_no',
    na: 'oem_2_na',
  },
  {
    desc: 'Applicable Control Numbers for Programs, loyalty, preferred, etc.',
    yes: 'oem_3_yes',
    no: 'oem_3_no',
    na: 'oem_3_na',
  },
  {
    desc: 'After tax rebate on BOS',
    yes: 'oem_4_yes',
    no: 'oem_4_no',
    na: 'oem_4_na',
  },
  {
    desc: 'Registration present in file',
    yes: 'oem_5_yes',
    no: 'oem_5_no',
    na: 'oem_5_na',
  },
  {
    desc: 'Registration date within 48 hours of BOS date',
    yes: 'oem_6_yes',
    no: 'oem_6_no',
    na: 'oem_6_na',
  },
  {
    desc: 'Invoice initialled by customer next to EP pricing',
    yes: 'oem_7_yes',
    no: 'oem_7_no',
    na: 'oem_7_na',
  },
];

export const SUBARU_CHECKLIST = [
  {
    desc: 'Subaru Grad Rebate disclosed on BOS, signed Rebate Form, registration in name of graduate',
    yes: 'oem_1_yes',
    no: 'oem_1_no',
    na: 'oem_1_na',
  },
  {
    desc: 'Cash Rebate disclosed on BOS, rebate form signed by customer',
    yes: 'oem_2_yes',
    no: 'oem_2_no',
    na: 'oem_2_na',
  },
  {
    desc: 'IZEV Rebate disclosed on BOS, all supporting docs signed by customer, reimbursement submitted',
    yes: 'oem_3_yes',
    no: 'oem_3_no',
    na: 'oem_3_na',
  },
  {
    desc: 'CEV Rebate disclosed on BOS, all supporting docs signed by customer, reimbursement submitted',
    yes: 'oem_4_yes',
    no: 'oem_4_no',
    na: 'oem_4_na',
  },
];

export const TOYOTA_CHECKLIST = [
  {
    desc: 'Cash rebate form',
    yes: 'oem_1_yes',
    no: 'oem_1_no',
    na: 'oem_1_na',
  },
  {
    desc: 'Loyalty rebate form',
    yes: 'oem_2_yes',
    no: 'oem_2_no',
    na: 'oem_2_na',
  },
  {
    desc: 'Proof of loyalty eligibility',
    yes: 'oem_3_yes',
    no: 'oem_3_no',
    na: 'oem_3_na',
  },
  {
    desc: 'Non-export form in file',
    yes: 'oem_4_yes',
    no: 'oem_4_no',
    na: 'oem_4_na',
  },
  {
    desc: 'Make a Date',
    yes: 'oem_5_yes',
    no: 'oem_5_no',
    na: 'oem_5_na',
  },
  {
    desc: 'Registration (New Vehicle)',
    yes: 'oem_6_yes',
    no: 'oem_6_no',
    na: 'oem_6_na',
  },
];

export const VOLKSWAGEN_CHECKLIST = [
  {
    desc: 'Loyalty program form in file',
    yes: 'oem_1_yes',
    no: 'oem_1_no',
    na: 'oem_1_na',
  },
  {
    desc: 'Proof of loyalty eligibility',
    yes: 'oem_2_yes',
    no: 'oem_2_no',
    na: 'oem_2_na',
  },
  {
    desc: 'Lease Worksheet in file',
    yes: 'oem_3_yes',
    no: 'oem_3_no',
    na: 'oem_3_na',
  },
  {
    desc: 'CPO Warranty in deal',
    yes: 'oem_4_yes',
    no: 'oem_4_no',
    na: 'oem_4_na',
  },
  {
    desc: 'Confirmation of CPO certificate',
    yes: 'oem_5_yes',
    no: 'oem_5_no',
    na: 'oem_5_na',
  },
];

// OEMS for PDFs: Audi, BMW, Ford, Genesis, Go, GM, Honda, Hyundai,
// Infiniti, JLR, Kia, Mazda, Mitsubishi, Nissan, Porsche, Stellantis,
// Subaru, Toyota, Volkswagen
// Does not include RV dealers
export const DEALERS_LIST = {
  // * AB DEALERS *
  5765: { name: 'Airdrie Honda', oem: 'Honda', province: 'AB' },
  5949: { name: 'Bavaria BMW', oem: 'BMW', province: 'AB' },
  5328: { name: 'Drayton Valley Toyota', oem: 'Toyota', province: 'AB' },
  5536: { name: 'Genesis South Edmonton', oem: 'Genesis', province: 'AB' },
  5402: { name: 'Go Auto Outlet South', oem: 'Go' },
  5755: { name: 'Go Auto Outlet Yellowhead', oem: 'Go', province: 'AB' },
  5953: { name: 'Go Auto Sherwood Park', oem: 'Go', province: 'AB' },
  5385: { name: 'Go Dodge Red Deer', oem: 'Stellantis', province: 'AB' },
  18: { name: 'Go Honda', oem: 'Honda', province: 'AB' },
  2991: { name: 'Go Kia South', oem: 'Kia', province: 'AB' },
  2772: { name: 'Go Mazda', oem: 'Mazda', province: 'AB' },
  2993: { name: 'Go Nissan North', oem: 'Nissan', province: 'AB' },
  66: { name: 'Go Nissan South', oem: 'Nissan', province: 'AB' },
  270: { name: 'Honda Red Deer', oem: 'Honda', province: 'AB' },
  // this is Go Infiniti South in Grease
  4104: { name: 'Infiniti South Edmonton', oem: 'Infiniti', province: 'AB' },
  133: { name: 'Jaguar Land Rover Calgary', oem: 'JLR', province: 'AB' },
  331: { name: 'Jaguar Land Rover Edmonton', oem: 'JLR', province: 'AB' },
  56: { name: 'Kentwood Ford', oem: 'Ford', province: 'AB' },
  272: { name: 'MGM Ford Linoln Sales Ltd.', oem: 'Ford', province: 'AB' },
  36: { name: 'Norden Volkswagen', oem: 'Volkswagen', province: 'AB' },
  57: { name: 'Northstar Hyundai', oem: 'Hyundai', province: 'AB' },
  2990: { name: 'Peace River Chrysler', oem: 'Stellantis', province: 'AB' },
  192: { name: 'Peace River Ford', oem: 'Ford', province: 'AB' },
  2987: { name: 'Porsche Centre Edmonton', oem: 'Porsche', province: 'AB' },
  70: { name: 'Southtown Chrysler', oem: 'Stellantis', province: 'AB' },
  22: { name: 'Southtown Hyundai', oem: 'Hyundai', province: 'AB' },
  5341: { name: 'Spruce Grove Hyundai', oem: 'Hyundai', province: 'AB' },
  // this is Grove Nissan in Grease
  5728: { name: 'Spruce Grove Nissan', oem: 'Nissan', province: 'AB' },
  253: { name: 'St. Albert Honda', oem: 'Honda', province: 'AB' },
  5956: { name: 'T&T Honda', oem: 'Honda', province: 'AB' },
  71: { name: 'Team Ford', oem: 'Ford', province: 'AB' },
  52: { name: 'Toyota on the Trail', oem: 'Toyota', province: 'AB' },
  // * BC DEALERS *
  5946: { name: 'Audi Richmond', oem: 'Audi', province: 'BC' },
  400: { name: 'Columbia Chrysler', oem: 'Stellantis', province: 'BC' },
  5952: { name: 'Dams Ford Lincoln', oem: 'Ford', province: 'BC' },
  5950: { name: 'Go Auto Kelowna', oem: 'Go', province: 'BC' },
  3893: { name: 'Go Dodge Surrey', oem: 'Stellantis', province: 'BC' },
  5955: { name: 'Go Langley Mitsubishi', oem: 'Mitsubishi', province: 'BC' },
  5954: { name: 'Go Langley Subaru', oem: 'Subaru', province: 'BC' },
  5941: { name: 'Go North Surrey GM', oem: 'GM', province: 'BC' },
  5960: { name: 'Go Richmond Chrysler', oem: 'Stellantis', province: 'BC' },
  2250: { name: 'Jaguar Land Rover Richmond', oem: 'JLR', province: 'BC' },
  5947: { name: 'Langley Toyota', oem: 'Toyota', province: 'BC' },
  2244: { name: 'Volkswagen of Richmond', oem: 'Volkswagen', province: 'BC' },
  // * MB DEALERS *
  5725: { name: 'Go Autohaus Volkswagen', oem: 'Volkswagen', province: 'MB' },
  5723: { name: 'Porsche Centre Winnipeg', oem: 'Porsche', province: 'MB' },
  // * NWT DEALERS *
  318: { name: 'Aurora Dodge', oem: 'Stellantis', province: 'NW' },
  5376: { name: 'Aurora Ford Hay River', oem: 'Ford', province: 'NW' },
  5375: { name: 'Aurora Ford Yellowknife', oem: 'Ford', province: 'NW' },
  // * ON DEALERS *
  2457: { name: 'Go Terrace Ford Lincoln', oem: 'Ford', province: 'ON' },
};
