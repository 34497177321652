import { useMutation, useQuery } from '@apollo/react-hooks';

/* external */
import gql from 'graphql-tag';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';

/* Material UI */
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {
  Check as CheckIcon,
  Close as CloseIcon,
  Email as EmailIcon,
  Person as PersonIcon,
  Phone as PhoneIcon,
  Refresh as RefreshIcon,
  ThumbUp as ThumbUpIcon,
} from '@mui/icons-material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material';

/* internal */
import ConfirmDialog from 'components/MaterialUI/ConfirmDialog';
import TaskComplete from 'modules/tasks/components/TaskComplete';
import { getTaskBorderColor } from '../utils';

const UPDATE_TASK = gql`
  mutation updateOppTask($id: ID!, $input: UpdateTaskInput!) {
    updateTask(id: $id, input: $input) {
      _id
    }
  }
`;

const USERS_FOR_DEALER = gql`
  query UsersForDealer($dealerIds: [Int]!) {
    users: users(dealer_ids: $dealerIds, status: active) {
      display_name
      username
    }
  }
`;

const useStyles = makeStyles(theme => ({
  cardBorder: {
    borderLeft: 'solid',
    borderLeftWidth: 'thick',
    marginBottom: '10px',
  },
  button: {
    marginRight: '3px',
  },
  mobileContent: {
    padding: '0px 16px 0px 70px',
    whiteSpace: 'pre-line',
  },
  mobileHeader: {
    padding: '0px 16px 2px 16px',
  },
  mobileAvatar: {
    color: 'rgba(0,0,0,0.87)',
    backgroundColor: '#fff',
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
}));

const OpportunityTaskCard = ({ task, refetch, dealerId, setAddModalOpen }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const desktop = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const [dateModal, setDateModal] = useState(false);
  const [reassignModal, setReassignModal] = useState(false);
  const [completeModal, setCompleteModal] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [updateTask] = useMutation(UPDATE_TASK, {
    onCompleted: () => {
      refetch();
      enqueueSnackbar('Task updated!', { variant: 'success' });
    },
    onError: err => {
      enqueueSnackbar(`Could not update task: ${err}`, {
        variant: 'error',
      });
    },
  });

  const { data } = useQuery(USERS_FOR_DEALER, {
    variables: {
      dealerIds: [dealerId],
    },
  });

  const users = data?.users ?? [];

  const cancelTask = () => {
    updateTask({
      variables: {
        id: task._id,
        input: { status: 2 },
      },
    });
  };

  const updateAssignee = event => {
    updateTask({
      variables: {
        id: task._id,
        input: { assignee: event.target.value },
      },
    });
  };

  const rescheduleDueDate = date => {
    updateTask({
      variables: {
        id: task._id,
        input: { due_date: date },
      },
    });
  };

  const mobileIcon = () => {
    if (task.type === 'email') {
      return <EmailIcon />;
    }
    if (task.type === 'call') {
      return <PhoneIcon />;
    }
    return <ThumbUpIcon />;
  };

  const abortButtonProps = {
    variant: 'outlined',
  };

  const confirmButtonProps = {
    variant: 'outlined',
    style: theme.actions.confirm,
  };

  return (
    <>
      <TaskComplete
        open={completeModal}
        setOpen={setCompleteModal}
        task={task}
        action={() => null}
        setOpenNew={setAddModalOpen}
        isNoteOnOpportunity={true}
      />
      <ConfirmDialog
        text="Do you want to cancel this task?"
        isOpen={cancelModal}
        onClose={() => setCancelModal(false)}
        onConfirm={cancelTask}
        abortButtonProps={abortButtonProps}
        confirmButtonProps={confirmButtonProps}
      />
      <Dialog
        maxWidth="sm"
        open={reassignModal}
        onClose={() => setReassignModal(false)}
      >
        <DialogTitle>Reassign Task</DialogTitle>
        <DialogContent>
          <FormControl>
            <InputLabel shrink>Assignee</InputLabel>
            <Select value={task.assignee_username} onChange={updateAssignee}>
              <MenuItem value="Reassign Task">Reassign Task</MenuItem>
              {users.map(user => (
                <MenuItem key={user.username} value={user.username}>
                  {user.display_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setReassignModal(false)}
            color="primary"
            variant="outlined"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        maxWidth="sm"
        open={dateModal}
        onClose={() => setDateModal(false)}
      >
        <DialogTitle>Reschedule Task</DialogTitle>
        <DialogContent>
          <DateTimePicker
            disableToolbar
            variant="dialog"
            format="YYYY-MM-DD, hh:mm"
            id="date-picker-inline"
            label="Due Date"
            value={moment(task.due_date)}
            onChange={rescheduleDueDate}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setDateModal(false)}
            color="primary"
            variant="outlined"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Card
        key={task._id}
        className={classes.cardBorder}
        style={getTaskBorderColor(task)}
      >
        {!desktop && (
          <>
            <CardHeader
              titleTypographyProps={{
                variant: 'body1',
                style: { fontWeight: 'bold' },
              }}
              subheaderTypographyProps={{ variant: 'body2' }}
              title={`${moment(task.due_date).format(
                'MMM Do YYYY, h:mm a',
              )} @ ${task.dealer.dealer_name}`}
              subheader={task.description}
              avatar={
                <Avatar className={classes.mobileAvatar}>{mobileIcon()}</Avatar>
              }
              className={classes.mobileHeader}
            />
            <CardContent className={classes.mobileContent}>
              <Typography variant="caption" gutterBottom>
                {`Assigned to: ${task.assignee?.display_name || ''} \n`}
              </Typography>
              <Typography variant="caption" gutterBottom>
                {`Added on: ${moment(task.created).format('MMMM Do YYYY')}`}
              </Typography>
            </CardContent>
            {task.status !== 1 && (
              <CardActions style={{ float: 'right' }}>
                <IconButton onClick={() => setCompleteModal(true)} size="small">
                  <CheckIcon style={theme.actions.add} />
                </IconButton>
                <IconButton onClick={() => setDateModal(true)} size="small">
                  <RefreshIcon style={theme.actions.add} />
                </IconButton>
                <IconButton onClick={() => setReassignModal(true)} size="small">
                  <PersonIcon style={theme.actions.add} />
                </IconButton>
                <IconButton onClick={() => setCancelModal(true)} size="small">
                  <CloseIcon style={theme.actions.add} />
                </IconButton>
              </CardActions>
            )}
          </>
        )}
        {desktop && (
          <CardContent>
            <Grid container direction="row">
              <Grid item xs={1} style={{ alignSelf: 'center' }}>
                {task.type === 'email' && <EmailIcon />}
                {task.type === 'call' && <PhoneIcon />}
                {task.type === 'other' ||
                  (task.type === 'opportunity' && <ThumbUpIcon />)}
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                  {`${moment(task.due_date).format('MMM Do YYYY, h:mm a')} @ ${
                    task.dealer.dealer_name
                  }`}
                </Typography>
                <Typography variant="body1">{task.description}</Typography>
                <Grid container direction="row">
                  <Grid item xs={6}>
                    <Typography variant="caption">
                      {`Assigned to: ${task.assignee?.display_name || ''}`}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption">
                      {`Added on: ${moment(task.created).format(
                        'MMMM Do YYYY',
                      )}`}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={5} style={{ alignSelf: 'center' }}>
                {task.status === 0 && (
                  <>
                    <Button
                      variant="contained"
                      onClick={() => setCompleteModal(true)}
                      startIcon={<CheckIcon />}
                      size="small"
                      className={classes.button}
                      style={theme.actions.add}
                    >
                      Complete
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => setDateModal(true)}
                      startIcon={<RefreshIcon />}
                      size="small"
                      className={classes.button}
                      style={theme.actions.add}
                    >
                      Reschedule
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => setReassignModal(true)}
                      startIcon={<PersonIcon />}
                      size="small"
                      className={classes.button}
                      style={theme.actions.add}
                    >
                      Reassign
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => setCancelModal(true)}
                      startIcon={<CloseIcon />}
                      size="small"
                      className={classes.button}
                      style={theme.actions.add}
                    >
                      Cancel
                    </Button>
                  </>
                )}
                {task.status === 1 && (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <CheckIcon style={{ marginLeft: '5px', color: 'green' }} />
                    <Typography
                      variant="h6"
                      style={{ fontWeight: 'bold', color: 'green' }}
                    >
                      Completed
                    </Typography>
                  </Box>
                )}
                {task.status === 2 && (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <CloseIcon style={{ marginLeft: '5px', color: 'gray' }} />
                    <Typography
                      variant="h6"
                      style={{ fontWeight: 'bold', color: 'gray' }}
                    >
                      Cancelled
                    </Typography>
                  </Box>
                )}
              </Grid>
            </Grid>
          </CardContent>
        )}
      </Card>
    </>
  );
};

export default OpportunityTaskCard;
