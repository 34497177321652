import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { Select, Toolbar } from '@mui/material';
import app from '../../app';
import NotesFeed from './NotesFeed';

const DealerNotesFeed = () => {
  const history = useHistory();
  let { dealer_id } = useParams();
  const { data } = useQuery(gql`
    query DealerNotesFeed {
      currentUser {
        dealers {
          dealer_id
          dealer_name
        }
      }
    }
  `);
  const dealers =
    (data &&
      data.currentUser.dealers.sort((a, b) =>
        a.dealer_name.localeCompare(b.dealer_name),
      )) ||
    [];
  if (dealers.length < 1) return <div></div>;
  return (
    <app.components.Layout title="Notes">
      <Toolbar>
        <Select
          native
          fullWidth
          onChange={e => history.replace(e.target.value)}
        >
          {dealers.map(d => (
            <option value={d.dealer_id} selected={d.dealer_id === dealer_id}>
              {d.dealer_name}
            </option>
          ))}
        </Select>
      </Toolbar>
      <NotesFeed dealerIds={[Number(dealer_id) || dealers[0].dealer_id]} />
    </app.components.Layout>
  );
};

export default DealerNotesFeed;
