import { isEmpty, startCase } from 'lodash';
import React from 'react';
import { useHistory } from 'react-router-dom';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import { sessionKeys } from '../../constants';

const useStyles = makeStyles(theme => ({
  titleCell: {
    fontWeight: 'bold',
  },
}));

const LeadSummaryTables = ({ data, dealerId, isDesktop, dateFrom, dateTo }) => {
  const history = useHistory();
  const { titleCell } = useStyles();

  const outboundTotal = { total: 0, sold: 0, title: 'Total' };
  const sourceTotal = { total: 0, sold: 0, title: 'Total' };
  const inboundChannelSummaryData = data?.channel_summary?.inbound || {
    total: { title: 'Total', sold: 0, total: 0 },
  };

  const noTypenameOrNull = ([key, value]) =>
    key !== '__typename' && value !== null;

  const inboundChannelSummary = Object.entries(inboundChannelSummaryData)
    .filter(noTypenameOrNull)
    .map(([key, value]) => value);
  const outboundChannelSummaryData = data?.channel_summary?.outbound || {
    total: { title: 'Total', sold: 0, total: 0 },
  };
  const outboundChannelSummary = Object.entries(outboundChannelSummaryData)
    .filter(noTypenameOrNull)
    .map(([key, value]) => value);
  const otherChannelSummaryData = data?.channel_summary?.other || {};
  const otherChannelSummary = Object.entries(otherChannelSummaryData)
    .filter(noTypenameOrNull)
    .map(([key, value]) => value);
  const sourceSummary = data?.source_summary || {};
  const displaySourceSummary = [];
  Object.entries(sourceSummary).forEach(([key, value], index) => {
    if (index < 10) {
      displaySourceSummary.push({ ...value[1] });
    }
  });

  // Set up totals
  const inboundTotal = inboundChannelSummary
    .filter(({ title }) => title === 'Total')
    .map(value => value);
  outboundTotal.total += outboundChannelSummaryData?.total?.total ?? 0;
  outboundTotal.total += otherChannelSummaryData?.other?.total ?? 0;
  outboundTotal.sold += outboundChannelSummaryData?.total?.sold ?? 0;
  outboundTotal.sold += otherChannelSummaryData?.other?.sold ?? 0;
  displaySourceSummary.forEach(({ total, sold }) => {
    sourceTotal.total += total ?? 0;
    sourceTotal.sold += sold ?? 0;
  });

  const calculateClosing = (total, sold) => {
    if (sold === null || sold === undefined || sold === 0) return 0;
    return Math.round((sold / total) * 100);
  };

  const setOppChannelStatus = (lead_dir_channel, date_from, date_to) => {
    const variables = {
      status_date: {
        date_from,
        date_to,
      },
      sort_by: [
        {
          key: 'created',
          direction: -1,
        },
      ],
      lead_dir_channel,
    };
    window.sessionStorage.setItem(
      sessionKeys.oppFilters,
      JSON.stringify(variables),
    );
    window.sessionStorage.setItem(sessionKeys.oppDealer, dealerId);
    history.push('/opportunities/search');
  };

  return (
    <>
      {isDesktop && (
        <>
          <Grid item xs={5}>
            <Paper style={{ width: '100%' }}>
              <Box style={{ padding: '1rem' }}>
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                  {'Opportunities by Channel'}
                </Typography>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className={titleCell}>Inbound</TableCell>
                        <TableCell className={titleCell}>Total</TableCell>
                        <TableCell className={titleCell}>Sold</TableCell>
                        <TableCell className={titleCell}>Closing %</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {inboundChannelSummary
                        .filter(({ title }) => title !== 'Total')
                        .map(({ title, sold, total }) => (
                          <TableRow key={`${title}-inbound`}>
                            <TableCell>
                              <Link
                                style={{ color: 'dodgerblue' }}
                                component="button"
                                variant="body2"
                                onClick={() =>
                                  setOppChannelStatus(
                                    `inbound ${title}`,
                                    dateFrom,
                                    dateTo,
                                  )
                                }
                              >
                                {startCase(title)}
                              </Link>
                            </TableCell>
                            <TableCell>{total ?? 0}</TableCell>
                            <TableCell>{sold ?? 0}</TableCell>
                            <TableCell>
                              {calculateClosing(total, sold)}%
                            </TableCell>
                          </TableRow>
                        ))}
                      <TableRow>
                        <TableCell className={titleCell}>
                          {startCase(inboundTotal[0].title)}
                        </TableCell>
                        <TableCell className={titleCell}>
                          {inboundTotal[0]?.total ?? 0}
                        </TableCell>
                        <TableCell className={titleCell}>
                          {inboundTotal[0]?.sold ?? 0}
                        </TableCell>
                        <TableCell className={titleCell}>
                          {calculateClosing(
                            inboundTotal[0]?.total ?? 0,
                            inboundTotal[0]?.sold ?? 0,
                          )}
                          %
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className={titleCell}>Outbound</TableCell>
                        <TableCell className={titleCell}>Total</TableCell>
                        <TableCell className={titleCell}>Sold</TableCell>
                        <TableCell className={titleCell}>Closing %</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!isEmpty(outboundChannelSummary) &&
                        outboundChannelSummary
                          .filter(({ title }) => title !== 'Total')
                          .map(({ title, sold, total }) => (
                            <TableRow key={`${title}-outbound`}>
                              <TableCell>
                                <Link
                                  style={{ color: 'dodgerblue' }}
                                  component="button"
                                  variant="body2"
                                  onClick={() =>
                                    setOppChannelStatus(
                                      `outbound ${title}`,
                                      dateFrom,
                                      dateTo,
                                    )
                                  }
                                >
                                  {startCase(title)}
                                </Link>
                              </TableCell>
                              <TableCell>{total ?? 0}</TableCell>
                              <TableCell>{sold ?? 0}</TableCell>
                              <TableCell>
                                {calculateClosing(total, sold)}%
                              </TableCell>
                            </TableRow>
                          ))}
                      {otherChannelSummary.map(({ title, sold, total }) => (
                        <TableRow key={`${title}-other`}>
                          <TableCell>
                            <Link
                              style={{ color: 'dodgerblue' }}
                              component="button"
                              variant="body2"
                              onClick={() =>
                                setOppChannelStatus(
                                  `other ${title}`,
                                  dateFrom,
                                  dateTo,
                                )
                              }
                            >
                              {startCase(title)}
                            </Link>
                          </TableCell>
                          <TableCell>{total ?? 0}</TableCell>
                          <TableCell>{sold ?? 0}</TableCell>
                          <TableCell>
                            {calculateClosing(total, sold)}%
                          </TableCell>
                        </TableRow>
                      ))}
                      <TableRow>
                        <TableCell className={titleCell}>
                          {startCase(outboundTotal.title)}
                        </TableCell>
                        <TableCell className={titleCell}>
                          {outboundTotal.total}
                        </TableCell>
                        <TableCell className={titleCell}>
                          {outboundTotal.sold}
                        </TableCell>
                        <TableCell className={titleCell}>
                          {calculateClosing(
                            outboundTotal.total,
                            outboundTotal.sold,
                          )}
                          %
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={7}>
            <Paper style={{ width: '100%' }}>
              <Box style={{ padding: '1rem' }}>
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                  {'Top 10 Opportunities by Source'}
                </Typography>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className={titleCell}>Source</TableCell>
                        <TableCell className={titleCell}>Total</TableCell>
                        <TableCell className={titleCell}>Sold</TableCell>
                        <TableCell className={titleCell}>Closing %</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {displaySourceSummary.map(({ title, sold, total }) => (
                        <TableRow key={`${title}-source`}>
                          <TableCell>{title}</TableCell>
                          <TableCell>{total ?? 0}</TableCell>
                          <TableCell>{sold ?? 0}</TableCell>
                          <TableCell>
                            {calculateClosing(total, sold)}%
                          </TableCell>
                        </TableRow>
                      ))}
                      <TableRow>
                        <TableCell className={titleCell}>
                          {startCase(sourceTotal.title)}
                        </TableCell>
                        <TableCell className={titleCell}>
                          {sourceTotal.total}
                        </TableCell>
                        <TableCell className={titleCell}>
                          {sourceTotal.sold}
                        </TableCell>
                        <TableCell className={titleCell}>
                          {calculateClosing(
                            sourceTotal.total,
                            sourceTotal.sold,
                          )}
                          %
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Paper>
          </Grid>
        </>
      )}
      {!isDesktop && (
        <>
          <Paper style={{ margin: '5px' }}>
            <Box
              style={{
                marginBottom: '15px',
              }}
            >
              <Box style={{ paddingLeft: '8px' }}>
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                  {'Opportunities by Channel'}
                </Typography>
              </Box>
              <TableContainer>
                <Table
                  stickyHeader
                  size="small"
                  style={{ height: 'max-content' }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell className={titleCell}>Inbound</TableCell>
                      <TableCell className={titleCell}>Total</TableCell>
                      <TableCell className={titleCell}>Sold</TableCell>
                      <TableCell className={titleCell}>Closing %</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {inboundChannelSummary
                      .filter(({ title }) => title !== 'Total')
                      .map(({ title, sold, total }) => (
                        <TableRow key={`${title}-inbound`}>
                          <TableCell>
                            <Link
                              style={{ color: 'dodgerblue' }}
                              component="button"
                              variant="body2"
                              onClick={() =>
                                setOppChannelStatus(
                                  `inbound ${title}`,
                                  dateFrom,
                                  dateTo,
                                )
                              }
                            >
                              {startCase(title)}
                            </Link>
                          </TableCell>
                          <TableCell>{total ?? 0}</TableCell>
                          <TableCell>{sold ?? 0}</TableCell>
                          <TableCell>
                            {calculateClosing(total, sold)}%
                          </TableCell>
                        </TableRow>
                      ))}
                    <TableRow>
                      <TableCell className={titleCell}>
                        {startCase(inboundTotal[0].title)}
                      </TableCell>
                      <TableCell className={titleCell}>
                        {inboundTotal[0]?.total ?? 0}
                      </TableCell>
                      <TableCell className={titleCell}>
                        {inboundTotal[0]?.sold ?? 0}
                      </TableCell>
                      <TableCell className={titleCell}>
                        {calculateClosing(
                          inboundTotal[0]?.total ?? 0,
                          inboundTotal[0]?.sold ?? 0,
                        )}
                        %
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className={titleCell}>Outbound</TableCell>
                      <TableCell className={titleCell}>Total</TableCell>
                      <TableCell className={titleCell}>Sold</TableCell>
                      <TableCell className={titleCell}>Closing %</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {outboundChannelSummary
                      .filter(({ title }) => title !== 'Total')
                      .map(({ title, sold, total }) => (
                        <TableRow key={`${title}-outbound`}>
                          <TableCell>
                            <Link
                              style={{ color: 'dodgerblue' }}
                              component="button"
                              variant="body2"
                              onClick={() =>
                                setOppChannelStatus(
                                  `outbound ${title}`,
                                  dateFrom,
                                  dateTo,
                                )
                              }
                            >
                              {startCase(title)}
                            </Link>
                          </TableCell>
                          <TableCell>{total ?? 0}</TableCell>
                          <TableCell>{sold ?? 0}</TableCell>
                          <TableCell>
                            {calculateClosing(total, sold)}%
                          </TableCell>
                        </TableRow>
                      ))}
                    {otherChannelSummary.map(({ title, sold, total }) => (
                      <TableRow key={`${title}-other`}>
                        <TableCell>
                          <Link
                            style={{ color: 'dodgerblue' }}
                            component="button"
                            variant="body2"
                            onClick={() =>
                              setOppChannelStatus(
                                `other ${title}`,
                                dateFrom,
                                dateTo,
                              )
                            }
                          >
                            {startCase(title)}
                          </Link>
                        </TableCell>
                        <TableCell>{total ?? 0}</TableCell>
                        <TableCell>{sold ?? 0}</TableCell>
                        <TableCell>{calculateClosing(total, sold)}%</TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell className={titleCell}>
                        {startCase(outboundTotal.title)}
                      </TableCell>
                      <TableCell className={titleCell}>
                        {outboundTotal.total}
                      </TableCell>
                      <TableCell className={titleCell}>
                        {outboundTotal.sold}
                      </TableCell>
                      <TableCell className={titleCell}>
                        {calculateClosing(
                          outboundTotal.total,
                          outboundTotal.sold,
                        )}
                        %
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Paper>
          <Paper style={{ margin: '5px' }}>
            <Box
              style={{
                marginBottom: '15px',
              }}
            >
              <Box style={{ paddingLeft: '8px' }}>
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                  {'Top 10 Opportunities by Source'}
                </Typography>
              </Box>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className={titleCell}>Source</TableCell>
                      <TableCell className={titleCell}>Total</TableCell>
                      <TableCell className={titleCell}>Sold</TableCell>
                      <TableCell className={titleCell}>Closing %</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {displaySourceSummary.map(({ title, sold, total }) => (
                      <TableRow key={`${title}-source`}>
                        <TableCell>{title}</TableCell>
                        <TableCell>{total ?? 0}</TableCell>
                        <TableCell>{sold ?? 0}</TableCell>
                        <TableCell>{calculateClosing(total, sold)}%</TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell className={titleCell}>
                        {startCase(sourceTotal.title)}
                      </TableCell>
                      <TableCell className={titleCell}>
                        {sourceTotal.total}
                      </TableCell>
                      <TableCell className={titleCell}>
                        {sourceTotal.sold}
                      </TableCell>
                      <TableCell className={titleCell}>
                        {calculateClosing(sourceTotal.total, sourceTotal.sold)}%
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Paper>
        </>
      )}
    </>
  );
};

export default LeadSummaryTables;
