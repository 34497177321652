import React from 'react';
import Alert from '@mui/material/Alert';

export const ItemCountAlert = ({ nItems }) => {
  if (!nItems) {
    return null;
  }

  return (
    <Alert style={{ width: '500px' }} severity="warning">
      {nItems} item(s) checked.
    </Alert>
  );
};
