import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { Box, CircularProgress, Container, Grid } from '@mui/material';
import { Waypoint } from 'react-waypoint';
import moment from 'moment';
import NoteCard from './NoteCard';

const NOTES_QUERY = gql`
  query NotesFeed(
    $since: Date!
    $page: Int!
    $dealerIds: [Int]
    $users: [String]
  ) {
    notes(
      page: $page
      page_size: 50
      filters: {
        created: { date_from: $since }
        system_notes: hide
        dealer_ids: $dealerIds
        created_by_usernames: $users
      }
      sort_by: { key: created, direction: -1 }
    ) {
      pagination {
        page
      }
      results {
        _id
        created
        ...NoteCardFragment
      }
    }
  }
  ${NoteCard.fragments.note}
`;

const NotesFeed = ({ dealerIds, users }) => {
  // For performance reasons we restrict how far back we look for notes based on what we're filtering for
  let feedSince = moment().subtract(1, 'days').startOf('day');
  if (users && users.length === 1)
    feedSince = moment().subtract(45, 'days').startOf('day');
  else if (dealerIds && dealerIds.length === 1)
    feedSince = moment().subtract(7, 'days').startOf('day');
  const { data, fetchMore } = useQuery(NOTES_QUERY, {
    variables: { since: feedSince, page: 1, dealerIds, users },
  });
  const page = (data && data.notes.pagination.page) || 1;
  const loadMoreResults = () =>
    fetchMore({
      variables: { since: feedSince, page: page + 1, dealerIds, users },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        const newData = {
          ...fetchMoreResult,
        };
        // not using cursors so we might have dupes...
        const seen = prev.notes.results.map(n => n._id);
        const newNotes = fetchMoreResult.notes.results.filter(
          r => !seen.includes(r._id),
        );
        newData.notes.results = [...prev.notes.results, ...newNotes].sort(
          (a, b) => (a.created > b.created ? -1 : 1),
        );
        return newData;
      },
    });

  if (!data) {
    return (
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '90vh', position: 'fixed' }}
      >
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    );
  }

  const results = data.notes.results;

  return (
    <Container>
      {results.map((note, n) => (
        <Box mt={2} mb={2} key={note._id}>
          {n === results.length - 20 && <Waypoint onEnter={loadMoreResults} />}
          <NoteCard note={note} linkToCustomer />
        </Box>
      ))}
    </Container>
  );
};
export default NotesFeed;
