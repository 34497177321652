import React, { useEffect, useState } from 'react';

import { NetworkStatus } from 'apollo-boost';
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';
import gql from 'graphql-tag';
import noIMG from 'modules/inventory/static/img/no-image.jpg';
import { Waypoint } from 'react-waypoint';
import { cloudinaryCore, formatPrice, numberWithCommas } from 'utils';

import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Drawer from '@mui/material/Drawer';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import FilterListIcon from '@mui/icons-material/FilterList';

import { titleCase } from '../../utils';
import { useGroupVehiclesQueryContext } from '../contexts/GroupVehiclesQueryContext';
import FilterDropDown from './GroupInventoryFilterDropDown';
import GroupInventoryHeader from './GroupInventoryHeader';
import GroupVehicleModal from './GroupVehicleModal';
import InventorySearch from './InventorySearch';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  colorMultipleLine: {
    color: '#999999',
    fontSize: '14px',
    whiteSpace: 'pre-line',
  },
  multipleLine: {
    color: '#999999',
    fontSize: '12px',
    whiteSpace: 'pre-line',
  },
  table: {
    minWidth: 750,
  },
  progressNotificationText: {
    fontSize: '20px',
    padding: '2rem',
  },
  vehicleDisplayCountText: {
    textAlign: 'right',
    width: '100%',
    fontSize: '18px',
    alignItems: 'flex-end',
    alignSelf: 'flex-end',
  },
  vehiclePhoto: {
    width: '150px',
    height: '90px',
    objectFit: 'cover',
  },
}));

const GroupInventoryTable = () => {
  const classes = useStyles();
  const [filterDrawerState, setFilterDrawerState] = useState({
    left: false,
  });
  const [showVehicleModal, setShowVehicleModal] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState();
  const [showCost, setShowCost] = useState(false);

  const {
    facets,
    fetchMore,
    loading,
    networkStatus,
    pagination,
    setFacets,
    vehicles,
  } = useGroupVehiclesQueryContext();

  useEffect(
    () =>
      setShowCost(vehicles.length > 0 && vehicles[0]?.permissions?.read_cost),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loading],
  );

  const formatInventoryPhoto = photo => {
    const displayPhotoURL = photo
      ? cloudinaryCore.url(photo.cloudinary_public_id, {
          width: 150,
          height: 90,
          crop: 'fill',
        })
      : noIMG;

    return displayPhotoURL;
  };

  const handleRowClick = (event, vehicleId) => {
    setSelectedVehicle(vehicleId);
    setShowVehicleModal(true);
  };

  const toggleDrawer = (anchor, open) => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setFilterDrawerState({ ...filterDrawerState, [anchor]: open });
  };

  const handleDeleteFacet = (_model, _field, _value) => () =>
    setFacets(prev =>
      prev.filter(
        ({ model, field, value }) =>
          model !== _model || field !== _field || value !== _value,
      ),
    );

  return (
    <Box>
      <GroupVehicleModal
        vehicleId={selectedVehicle}
        showCost={showCost}
        showVehicleModal={showVehicleModal}
        setShowVehicleModal={setShowVehicleModal}
      />
      <Box marginLeft={1} marginTop={1}>
        <Drawer
          anchor="left"
          open={filterDrawerState['left']}
          onClose={toggleDrawer('left', false)}
        >
          <div style={{ display: 'flex' }}>
            <IconButton
              style={{
                display: 'flex',
                marginLeft: 'auto',
              }}
              onClick={toggleDrawer('left', false)}
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </div>
          <FilterDropDown />
        </Drawer>
        <Grid container justifyContent="space-between">
          <Grid container item xs={8} justifyContent="flex-start" spacing={2}>
            <Grid item>
              <Tooltip title="Filter list">
                <Fab
                  color="primary"
                  onClick={toggleDrawer('left', true)}
                  size="medium"
                >
                  <FilterListIcon />
                </Fab>
              </Tooltip>
            </Grid>
            <Grid item xs={10}>
              {facets.map(({ model, field, value, options: { label } }) => (
                <span
                  style={{
                    paddingRight: '5px',
                  }}
                  key={`${model}${field}${value}`}
                >
                  <Chip
                    label={label || value}
                    style={{ marginTop: '5px' }}
                    onDelete={handleDeleteFacet(model, field, value)}
                  />
                </span>
              ))}
            </Grid>
          </Grid>
          <Grid container item xs={4} justifyContent="flex-end">
            <Grid item>
              <InventorySearch queryContext={useGroupVehiclesQueryContext} />
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box marginLeft={1} marginTop={1} marginRight={1}>
        <Grid
          container
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            flexDirection: 'row',
          }}
        >
          <Grid item>
            <Box className={classes.vehicleDisplayCountText}>
              Showing {vehicles.length} of {pagination.total}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <TableContainer>
        <Table className={classes.table}>
          <GroupInventoryHeader showCost={showCost} />

          {networkStatus !== NetworkStatus.setVariables && (
            <TableBody>
              {vehicles.map(vehicle => (
                <TableRow
                  hover
                  tabIndex={-1}
                  key={vehicle.id}
                  onClick={event => handleRowClick(event, vehicle.id)}
                >
                  {/* Vehicle */}
                  <TableCell>
                    <Grid container spacing={2}>
                      <Grid item>
                        <Badge
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                          overlap="circular"
                          max={20}
                          badgeContent={vehicle.photo_count}
                          color="secondary"
                        >
                          <img
                            className={classes.vehiclePhoto}
                            alt="Vehicle"
                            src={formatInventoryPhoto(vehicle.photos[0])}
                          />
                        </Badge>
                      </Grid>
                      <Grid item xs={12} sm>
                        {vehicle.displayName}
                        <Typography className={classes.multipleLine}>
                          {vehicle.trim_variation}
                        </Typography>
                        <Typography className={classes.multipleLine}>
                          {vehicle.vin ?? ''}
                        </Typography>
                      </Grid>
                    </Grid>
                  </TableCell>

                  {/* Stock number */}
                  <TableCell>
                    {vehicle.stock_number}
                    <Typography className={classes.multipleLine}>
                      {vehicle.stock_type} /{' '}
                      {titleCase(vehicle.stock_status_name)}
                    </Typography>
                  </TableCell>

                  {/* Price */}
                  <TableCell>{formatPrice(vehicle.list_price)}</TableCell>

                  {/* Cost */}
                  {showCost && (
                    <TableCell>
                      {vehicle?.permissions?.read_cost === true
                        ? formatPrice(vehicle.cost, { nullDisplay: 'N/A' })
                        : '-'}
                    </TableCell>
                  )}

                  {/* Dealer */}
                  <TableCell>{vehicle.dealer_name}</TableCell>

                  {/* Kms */}
                  <TableCell>
                    {numberWithCommas(vehicle.odometer) + ' km'}
                  </TableCell>

                  {/* Color */}
                  <TableCell>
                    Ext: {vehicle.exterior_colour_name || 'N/A'}
                    <Typography className={classes.colorMultipleLine}>
                      Int: {vehicle.interior_colour_name || 'N/A'}
                    </Typography>
                  </TableCell>

                  {/* Days */}
                  <TableCell>{vehicle.days_in_stock}</TableCell>

                  {/* Group Age */}
                  <TableCell>{vehicle.group_age}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {networkStatus === NetworkStatus.setVariables && (
        <>
          <div className={classes.progressNotificationText}>Sorting...</div>
          <LoadingBackdrop open={true} />
        </>
      )}
      {!loading && <Waypoint onEnter={fetchMore} bottomOffset="-10%" />}
      {loading && networkStatus === NetworkStatus.fetchMore && (
        <>
          <div className={classes.progressNotificationText}>
            Loading more vehicles...
          </div>
          <LoadingBackdrop open={true} />
        </>
      )}
    </Box>
  );
};

GroupInventoryTable.fragments = {
  vehicle: gql`
    fragment GroupInventoryTableVehicle on GreaseInventoryVehicle {
      id
      cost
      days_in_stock
      dealer_name
      displayName
      exterior_colour_name
      group_age
      interior_colour_name
      list_price
      odometer
      permissions {
        read_cost
      }
      photos {
        id
        cloudinary_public_id
      }
      photo_count
      stock_number
      stock_status_name
      stock_type
      trim_variation
      vin
    }
  `,
};

export default GroupInventoryTable;
