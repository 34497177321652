import React, { useState, useEffect } from 'react';
import { useUserContext } from 'components/MaterialUI/UserContext';
import Zendesk, { ZendeskAPI } from 'react-zendesk';
import { ZENDESK_KEY, isProduction } from 'constants.js';

const getPrefilledDescriptionText = () => {
  return `\n\n<<DO NOT EDIT BELOW THIS LINE>>\nurl: ${window.location.href}`;
};

const ZendeskWidget = () => {
  const { currentUser } = useUserContext();
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    ZendeskAPI('webWidget', 'hide');
    ZendeskAPI('webWidget:on', 'close', () => {
      ZendeskAPI('webWidget', 'hide');
    });
  }, [loaded]);

  useEffect(() => {
    if (loaded) {
      ZendeskAPI('webWidget', 'identify', {
        name: currentUser?.display_name,
        email: currentUser?.goUserProfile?.profile?.email_address,
      });
      ZendeskAPI('webWidget:on', 'userEvent', data => {
        if (data.action === 'Contact Form Shown')
          ZendeskAPI('webWidget', 'updateSettings', {
            webWidget: {
              contactForm: {
                fields: [
                  {
                    id: 'name',
                    prefill: { '*': currentUser?.display_name },
                  },
                  {
                    id: 'email',
                    prefill: {
                      '*': currentUser?.goUserProfile?.profile?.email_address,
                    },
                  },
                  {
                    id: 'description',
                    prefill: { '*': getPrefilledDescriptionText() },
                  },
                ],
              },
            },
          });
      });
    }
  }, [loaded, currentUser]);

  return (
    <>
      <Zendesk
        zendeskKey={ZENDESK_KEY}
        onLoaded={() => setLoaded(true)}
        color={{ theme: '#ff5400' }}
        contactForm={{
          suppress: !isProduction,
        }}
        helpCenter={{
          suppress: false,
        }}
      />
    </>
  );
};

export default ZendeskWidget;
