/* External */
import React from 'react';
import gql from 'graphql-tag';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import { uniqBy } from 'lodash';

/* Material UI */
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import RadioControl from 'components/MaterialUI/RadioControl2';
import RenderableRadioGroup from 'components/MaterialUI/RenderableRadioGroup';
import SelectControl from 'components/MaterialUI/SelectControl2';

/* Internal */
import { LEAD_TYPES } from 'modules/leads/constants';
import { UPDATE_SETTINGS } from './GeneralSettings';
import { useUserContext } from 'components/MaterialUI/UserContext';
import { snackBarSettings } from 'constants.js';
import { useSnackbar } from 'notistack';
import Loading from 'components/MaterialUI/Loading';

const useStyles = makeStyles(theme => ({
  h6: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
    marginBottom: '5px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
  titleLine: {
    borderBottom: '1px solid' + theme.colours.greys.grey3,
    marginBottom: '15px',
  },
  textField: {
    width: '90%',
    margin: '5px 0',
  },
  settingsContainer: {
    width: '80%',
    paddingBottom: '20px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  blackoutLabel: {
    fontWeight: 600,
    margin: '5px 0',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.875rem',
    },
  },
  saveButton: {
    width: '100%',
    backgroundColor: theme.actions.confirm.backgroundColor,
    color: theme.actions.confirm.color,
  },
}));

// value in hours
const LeadGenerateTimeRadioGroup = [
  { name: '1 Hour', value: '1' },
  { name: '1 Day', value: '24' },
  { name: '15 Days', value: '360' },
  { name: '30 Days', value: '720' },
  { name: '60 Days', value: '1440' },
];
const AutoLeadStateRadioGroup = props => (
  <RenderableRadioGroup
    {...props}
    options={[
      { name: 'Enabled', value: 'true' },
      { name: 'Disabled', value: 'false' },
    ]}
  />
);

const AutomaticLeadGeneration = ({ gateway }) => {
  const classes = useStyles();
  const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const { enqueueSnackbar: snackIt } = useSnackbar();
  const { currentUser } = useUserContext();
  const [updateSettings, { loading }] = useMutation(UPDATE_SETTINGS, {
    onCompleted: () => {
      snackIt('Updated successfully!', {
        ...snackBarSettings,
        variant: 'success',
      });
    },
    onError: error => {
      snackIt(error.message, {
        ...snackBarSettings,
        variant: 'error',
      });
    },
  });

  // makes it less of a PITA to grab the data in the fields below
  const gatewayData = gateway?.sms.gateway;

  // RHF Stuff
  const {
    control,
    handleSubmit,
    formState: { isDirty },
    reset,
  } = useForm({
    defaultValues: {
      enable_automatic_lead_generation:
        gatewayData.enable_automatic_lead_generation,
      default_lead_type: gatewayData.default_lead_type,
      send_lead_after_number_of_hours:
        gatewayData.send_lead_after_number_of_hours?.toString() || null,
      lead_dealer_id: gatewayData.lead_dealer_id,
    },
  });

  const hasPermissions = gatewayData.permissions?.length > 0;
  const permissionDealers = uniqBy(
    gatewayData.permissions?.map(({ dealer }) => dealer),
    'id',
  );
  const gatewayDealerships = hasPermissions
    ? permissionDealers
    : currentUser.dealers;

  const processSettings = settings => {
    // null -> undefined so that the backend doesn't try to update them
    Object.keys(settings).forEach(
      i => (settings[i] = settings[i] ?? undefined),
    );
    settings.enable_automatic_lead_generation =
      settings.enable_automatic_lead_generation === 'true';
    settings.send_lead_after_number_of_hours =
      parseInt(settings.send_lead_after_number_of_hours, 10) || undefined;
  };

  const onSubmit = settings => {
    processSettings(settings);
    updateSettings({
      variables: {
        gatewayId: gatewayData.id,
        settings,
      },
    });
  };

  if (loading) return <Loading />;

  return (
    <Box p={0}>
      <Box className={classes.titleLine}>
        <Typography className={classes.h6}>
          Automatic Lead Generation
        </Typography>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={4} className={classes.settingsContainer}>
          <Grid item xs={12}>
            <RadioControl
              control={control}
              name="enable_automatic_lead_generation"
              as={<AutoLeadStateRadioGroup />}
            />
          </Grid>
          <Grid item xs={6}>
            <SelectControl
              control={control}
              fullWidth
              label="Dealership"
              name="lead_dealer_id"
              options={gatewayDealerships}
              optionValueKey={hasPermissions ? 'id' : 'dealer_id'}
              optionNameKey={hasPermissions ? 'name' : 'dealer_name'}
              variant="outlined"
              noNull
            />
          </Grid>
          <Grid item xs={6}>
            <SelectControl
              control={control}
              fullWidth
              label="Lead Type"
              name="default_lead_type"
              options={LEAD_TYPES}
              optionValueKey="value"
              optionNameKey="name"
              variant="outlined"
              noNull
            />
          </Grid>
          <Grid item xs={12}>
            <Typography style={{ fontWeight: 'bold' }}>
              Only generate leads when there has been no prior activity for the
              last...
            </Typography>
            <FormControl>
              <RadioControl
                name="send_lead_after_number_of_hours"
                control={control}
                as={
                  <RadioGroup style={{ flexDirection: 'row' }}>
                    {LeadGenerateTimeRadioGroup.map(({ name, value }) => (
                      <FormControlLabel
                        key={value}
                        value={value}
                        label={name}
                        control={<Radio />}
                      />
                    ))}
                  </RadioGroup>
                }
              />
            </FormControl>
          </Grid>
          <Grid
            container
            item
            xs={12}
            justifyContent={mobile ? 'center' : 'flex-end'}
            alignItems="center"
            spacing={mobile ? 2 : 4}
            wrap="wrap"
          >
            <Grid item xs={12} sm={5} md={4} xl={2}>
              <Button
                variant="outlined"
                style={{ width: '100%' }}
                onClick={() => reset()}
              >
                Discard Changes
              </Button>
            </Grid>
            <Grid item xs={12} sm={5} md={4} xl={2}>
              <Button
                variant="contained"
                type="submit"
                disabled={!isDirty}
                className={classes.saveButton}
              >
                Save Changes
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

AutomaticLeadGeneration.fragments = {
  gateway: gql`
    fragment AutoLeadGenerationGatewayData on Gateway {
      enable_automatic_lead_generation
      lead_dealer_id
      send_lead_after_number_of_hours
      default_lead_type
    }
  `,
};

export default AutomaticLeadGeneration;
