import { flatten } from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import Loading from 'components/MaterialUI/Loading';

import AppointmentActions from '../../../appointments/components/AppointmentActions';
import {
  IN_STOCK_FACET,
  LEADS_REQUIRING_ATTENTION_FACET,
  MISSING_STUFF_FACET,
  sessionKeys,
} from '../../constants';
import {
  getDaysInStockFilter,
  getInventoryNumbers,
  getLastPriceChangeFilter,
  getManagerDashboardStatistics,
} from '../../utils';
import LeadSummaryTables from './LeadSummaryTables';
import { Status } from 'constants.js';
import {
  AppointmentsTable,
  CompleteGross,
  DealTable,
  IncompletePhotos,
  InStockGtView,
  InventoryHeader,
  OpportunityTable,
  PriceChanges,
  SalesHeader,
  TotalGross,
  useManagerDashboardLogic,
  VehiclesInfo,
} from './shared';
import { getFullDateTime } from 'utils';

const useStyles = makeStyles(theme => ({
  action: {
    '& > *': {
      margin: theme.spacing(0.5),
    },
    '& button': {
      padding: '6px 4px',
      minWidth: 'unset',
      backgroundColor: '#2196f3',
    },
  },
  customer: {
    '& a': {
      color: '#0093a7',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  inventoryCard: {
    width: '175px',
    height: '125px',
    textAlign: 'center',
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  card: {
    width: '175px',
    height: '125px',
    textAlign: 'center',
    padding: '1rem',
    fontSize: '18px',
    fontWeight: 'bold',
  },
}));

const ManagerDashboardDesktop = ({ classes, dealerId }) => {
  const history = useHistory();
  const { inventoryCard, card, ...actionClasses } = useStyles();
  const {
    opportunityDateFromFilter,
    opportunityDateToFilter,
    inventoryDateFromFilter,
    inventoryDateToFilter,
    handleDateFromChange,
    handleDateToChange,
    handleInventoryDateFromChange,
    managerQuery: { results, loading },
    inventoryQuery: { data: inventoryData },
    opp_totals,
    opp_table,
    deallog_table,
    deallog_totals,
    appt_totals,
    inventoryFilterHandler,
    handleAppointmentsCardClick,
    handleUpcomingAppointmentsCardClick,
    handleAttendedAppointmentsCardClick,
    inventoryDateSince,
  } = useManagerDashboardLogic('managerDashboard', dealerId);
  if (loading) return <Loading text="Loading Your Dashboard! Please wait." />;
  const daysInStockFilter = getDaysInStockFilter(inventoryDateSince);
  const lastPriceChangeFilter = getLastPriceChangeFilter(
    inventoryDateFromFilter,
  );

  const { upcomingCount, attendedCount, createdTodayCount } =
    getManagerDashboardStatistics(results);

  const {
    newVehiclesInStock,
    usedVehiclesInStock,
    totalIncomplete,
    lastPriceChangeLt,
    inStockGt,
  } = getInventoryNumbers(inventoryData, inventoryDateSince);

  let appt_table = results?.appt_table?.filter(x => x.date_range > 0) || [];

  appt_table.sort((a, b) => b.date_range - a.date_range);

  const totalVehicles = newVehiclesInStock + usedVehiclesInStock;

  const setOppFiltersStatus = (
    statuses,
    today = false,
    MTD = false,
    unassigned = false,
    dateFrom = null,
    dateTo = null,
    createdToday = false,
    createdFrom = null,
    createdTo = null,
  ) => {
    const dateToday = moment().format('YYYY-MM-DD');
    const dateTomorrow = moment().add(1, 'days').format('YYYY-MM-DD');

    const status_date =
      dateFrom && dateTo
        ? { date_to: dateTo, date_from: dateFrom }
        : today
        ? { date_to: dateToday, date_from: dateToday }
        : MTD
        ? {
            date_to: moment().endOf('month').format('YYYY-MM-DD'),
            date_from: moment().startOf('month').format('YYYY-MM-DD'),
          }
        : null;
    const created =
      createdFrom && createdTo
        ? { date_to: createdTo, date_from: createdFrom }
        : createdToday
        ? { date_to: dateTomorrow, date_from: dateToday }
        : null;
    const filters = {
      statuses,
      sort_by: [
        {
          key: 'created',
          direction: -1,
        },
      ],
    };

    const variables = unassigned
      ? { ...filters, assignees: ['unassigned'] }
      : status_date
      ? { ...filters, status_date }
      : created
      ? { ...filters, created }
      : filters;
    window.sessionStorage.setItem(
      sessionKeys.oppFilters,
      JSON.stringify({
        ...variables,
        ...{ sort_by: [{ key: 'created', direction: -1 }] },
      }),
    );
    window.sessionStorage.setItem(sessionKeys.oppDealer, dealerId);
    history.push('/opportunities/search');
  };

  const inventoryFacetHandler = (...facets) => {
    window.sessionStorage.setItem(
      sessionKeys.inventoryFacets,
      JSON.stringify(flatten(facets)),
    );
    window.sessionStorage.setItem(sessionKeys.inventoryDealerId, dealerId);
    history.push('/inventory');
  };

  const leadsFacetHandler = facets => {
    window.sessionStorage.setItem(
      sessionKeys.leadsFacets,
      JSON.stringify(facets),
    );
    window.sessionStorage.setItem(sessionKeys.leadsCreatedFrom, null);
    window.sessionStorage.setItem(sessionKeys.leadsDealerId, dealerId);
    history.push('/leads?filters=dashboard');
  };

  return (
    <>
      <InventoryHeader
        handleInventoryDateFromChange={handleInventoryDateFromChange}
        inventoryDateFromFilter={inventoryDateFromFilter}
        inventoryDateToFilter={inventoryDateToFilter}
      />
      <Box paddingTop={2} paddingLeft={1}>
        <Paper
          elevation={0}
          style={{ backgroundColor: '#f2f2f2', maxWidth: '1700px' }}
        >
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: '1rem',
            }}
          >
            <Card
              onClick={() => {
                inventoryFacetHandler(IN_STOCK_FACET);
                inventoryFilterHandler();
              }}
            >
              <CardActionArea>
                <CardContent className={inventoryCard}>
                  <VehiclesInfo
                    totalVehicles={totalVehicles}
                    newVehiclesInStock={newVehiclesInStock}
                    usedVehiclesInStock={usedVehiclesInStock}
                  />
                </CardContent>
              </CardActionArea>
            </Card>
            <Card
              onClick={() => {
                inventoryFacetHandler(IN_STOCK_FACET);
                inventoryFilterHandler(daysInStockFilter);
              }}
            >
              <CardActionArea>
                <CardContent className={inventoryCard}>
                  <InStockGtView
                    inStockGt={inStockGt}
                    totalVehicles={totalVehicles}
                    inventoryDateSince={inventoryDateSince}
                  />
                </CardContent>
              </CardActionArea>
            </Card>
            <Card
              onClick={() => {
                inventoryFacetHandler(IN_STOCK_FACET);
                inventoryFilterHandler(lastPriceChangeFilter);
              }}
            >
              <CardActionArea>
                <CardContent className={inventoryCard}>
                  <PriceChanges
                    lastPriceChangeLt={lastPriceChangeLt}
                    inventoryDateSince={inventoryDateSince}
                  />
                </CardContent>
              </CardActionArea>
            </Card>
            <Card
              onClick={() => {
                inventoryFacetHandler(IN_STOCK_FACET, MISSING_STUFF_FACET);
                inventoryFilterHandler();
              }}
            >
              <CardActionArea>
                <CardContent className={inventoryCard}>
                  <IncompletePhotos totalIncomplete={totalIncomplete} />
                </CardContent>
              </CardActionArea>
            </Card>
          </Box>
        </Paper>
      </Box>
      <SalesHeader
        handleDateFromChange={handleDateFromChange}
        handleDateToChange={handleDateToChange}
        opportunityDateFromFilter={opportunityDateFromFilter}
        opportunityDateToFilter={opportunityDateToFilter}
      />
      <Box paddingTop={2} paddingLeft={1}>
        <Paper
          elevation={0}
          style={{ backgroundColor: '#f2f2f2', maxWidth: '1700px' }}
        >
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: '1rem',
            }}
          >
            <Card>
              <CardActionArea
                onClick={() =>
                  setOppFiltersStatus(
                    Status.ALL,
                    false,
                    false,
                    false,
                    null,
                    null,
                    true,
                  )
                }
              >
                <CardContent className={card}>
                  <Box>{results?.new_opps_today || 0}</Box>
                  <Box>New Opportunities (Today)</Box>
                </CardContent>
              </CardActionArea>
            </Card>
            <Card>
              <CardActionArea
                onClick={() =>
                  setOppFiltersStatus(
                    Status.ALL,
                    false,
                    false,
                    false,
                    null,
                    null,
                    false,
                    opportunityDateFromFilter,
                    opportunityDateToFilter,
                  )
                }
              >
                <CardContent className={card}>
                  <Box>{results?.new_opps_date_range || 0}</Box>
                  <Box> New Opportunities </Box>
                </CardContent>
              </CardActionArea>
            </Card>
            <Card>
              <CardActionArea
                onClick={() =>
                  setOppFiltersStatus(Status.OPEN_OPPS, false, false, true)
                }
              >
                <CardContent className={card}>
                  <Box>{results?.unassigned_opps || 0}</Box>
                  <Box>Unassigned Opportunities (Total)</Box>
                </CardContent>
              </CardActionArea>
            </Card>
            <Card>
              <CardActionArea
                onClick={() =>
                  setOppFiltersStatus(
                    [Status.LOST],
                    false,
                    false,
                    false,
                    opportunityDateFromFilter,
                    opportunityDateToFilter,
                  )
                }
              >
                <CardContent className={card}>
                  <Box>{results?.lost_opps || 0}</Box>
                  <Box>Lost Opportunities</Box>
                </CardContent>
              </CardActionArea>
            </Card>
            <Card>
              <CardActionArea
                onClick={() =>
                  leadsFacetHandler(LEADS_REQUIRING_ATTENTION_FACET)
                }
              >
                <CardContent className={card}>
                  <Box>{results?.leads_needing_attention || 0}</Box>
                  <Box>Leads Requiring Attention</Box>
                </CardContent>
              </CardActionArea>
            </Card>
          </Box>
          <Box style={{ padding: '0 1rem' }}>
            <hr />
          </Box>
          <Box style={{ padding: '0 1rem' }}>
            <Typography variant="h6" style={{ fontWeight: 'bold' }}>
              Open Opportunities
            </Typography>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                paddingTop: '1rem',
              }}
            >
              <Box style={{ paddingRight: '1rem' }}>
                <Card>
                  <CardActionArea
                    onClick={() =>
                      setOppFiltersStatus(
                        Status.OPEN_OPPS,
                        false,
                        false,
                        false,
                        null,
                        null,
                        false,
                        opportunityDateFromFilter,
                        opportunityDateToFilter,
                      )
                    }
                  >
                    <CardContent className={card}>
                      <Box>{results?.open_opps}</Box>
                      <Box>Open Opportunities</Box>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Box>
              <Box style={{ paddingRight: '1rem' }}>
                <Card>
                  <CardActionArea
                    onClick={() =>
                      setOppFiltersStatus(
                        Status.OPEN_OPPS,
                        false,
                        false,
                        false,
                        opportunityDateFromFilter,
                        opportunityDateToFilter,
                      )
                    }
                  >
                    <CardContent
                      style={{
                        width: '175px',
                        height: '125px',
                        textAlign: 'center',
                        padding: '1rem',
                        fontSize: '14px',
                        fontWeight: 'bold',
                      }}
                    >
                      <Box>{results?.not_updated_opps}</Box>
                      <Box>Open Opportunities with No Updates</Box>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Box>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginLeft: 'auto',
                  marginRight: 0,
                  width: '100%',
                }}
              >
                <Paper style={{ width: '100%' }}>
                  <Box style={{ padding: '1rem' }}>
                    <TableContainer style={{ maxHeight: 200 }}>
                      <Table
                        stickyHeader
                        size="small"
                        style={{ height: 'max-content' }}
                      >
                        <OpportunityTable
                          opportunityDateFromFilter={opportunityDateFromFilter}
                          opportunityDateToFilter={opportunityDateToFilter}
                          opp_table={opp_table}
                          opp_totals={opp_totals}
                          classes={classes}
                        />
                      </Table>
                    </TableContainer>
                  </Box>
                </Paper>
              </Box>
            </Box>
          </Box>
          <Box style={{ padding: '0 1rem' }}>
            <hr />
          </Box>
          <Box style={{ padding: '0 1rem' }}>
            <Typography variant="h6" style={{ fontWeight: 'bold' }}>
              Deal Log Summary
            </Typography>
            <Grid
              container
              spacing={1}
              direction="row"
              style={{
                display: 'flex',
                flexDirection: 'row',
                paddingTop: '1rem',
                justifyContent: 'space-between',
              }}
            >
              <Grid item xs={4} style={{ paddingRight: '1rem' }}>
                <Grid container direction="column">
                  <Grid item xs={12}>
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Card>
                        <CardActionArea
                          onClick={() =>
                            setOppFiltersStatus(
                              [Status.PENDING],
                              false,
                              false,
                              false,
                              opportunityDateFromFilter,
                              opportunityDateToFilter,
                            )
                          }
                        >
                          <CardContent className={card}>
                            <Box>{results?.pending_opps}</Box>
                            <Box>Pending Deals</Box>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                      <Card>
                        <CardActionArea
                          onClick={() =>
                            setOppFiltersStatus(
                              Status.OPEN,
                              false,
                              false,
                              false,
                              opportunityDateFromFilter,
                              opportunityDateToFilter,
                            )
                          }
                        >
                          <CardContent className={card}>
                            <Box>{results?.booked_opps}</Box>
                            <Box>Booked Deals</Box>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Box>
                  </Grid>
                  <TotalGross results={results} />
                </Grid>
              </Grid>
              <Grid item style={{ paddingRight: '1rem' }}>
                <Card>
                  <CardActionArea
                    onClick={() =>
                      setOppFiltersStatus(
                        Status.COMPLETED,
                        false,
                        false,
                        false,
                        opportunityDateFromFilter,
                        opportunityDateToFilter,
                      )
                    }
                  >
                    <CardContent className={card}>
                      <Box>{results?.sold_opps}</Box>
                      <Box>Sold Deals</Box>
                    </CardContent>
                  </CardActionArea>
                </Card>
                <CompleteGross results={results} />
              </Grid>
              <Grid item xs={5}>
                <Paper style={{ width: '100%' }}>
                  <Box style={{ padding: '1rem' }}>
                    <TableContainer style={{ maxHeight: 200 }}>
                      <DealTable
                        classes={classes}
                        deallog_table={deallog_table}
                        deallog_totals={deallog_totals}
                      />
                    </TableContainer>
                  </Box>
                </Paper>
              </Grid>
              <LeadSummaryTables
                data={results}
                dealerId={dealerId}
                isDesktop={true}
                dateFrom={opportunityDateFromFilter}
                dateTo={opportunityDateToFilter}
              />
            </Grid>
          </Box>
          <Box style={{ padding: '0 1rem' }}>
            <hr />
          </Box>
          <Box style={{ padding: '0 1rem' }}>
            <Typography variant="h6" style={{ fontWeight: 'bold' }}>
              Appointments
            </Typography>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                paddingTop: '1rem',
              }}
            >
              <Box style={{ paddingRight: '1rem' }}>
                <Card onClick={handleUpcomingAppointmentsCardClick}>
                  <CardActionArea>
                    <CardContent className={card}>
                      <Box>{upcomingCount}</Box>
                      <Box>Upcoming Appointments</Box>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Box>
              <Box style={{ paddingRight: '1rem' }}>
                <Card>
                  <CardActionArea onClick={handleAttendedAppointmentsCardClick}>
                    <CardContent className={card}>
                      <Box>{attendedCount}</Box>
                      <Box>Appointments Attended (Last 7 Days)</Box>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Box>
              <Box style={{ paddingRight: '1rem' }}>
                <Card>
                  <CardActionArea onClick={handleAppointmentsCardClick}>
                    <CardContent className={card}>
                      <Box>{createdTodayCount}</Box>
                      <Box>Appointments Created Today</Box>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Box>
              <Box style={{ width: '100%' }}>
                <Paper style={{ width: '100%' }}>
                  <Box style={{ padding: '1rem' }}>
                    <TableContainer style={{ maxHeight: 200 }}>
                      <AppointmentsTable
                        classes={classes}
                        opportunityDateFromFilter={opportunityDateFromFilter}
                        opportunityDateToFilter={opportunityDateToFilter}
                        appt_table={appt_table}
                        appt_totals={appt_totals}
                      />
                    </TableContainer>
                  </Box>
                </Paper>
              </Box>
            </Box>
            <Box paddingTop={2} paddingLeft={1}>
              <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                Upcoming Appointments
              </Typography>
            </Box>
            <Box>
              {results?.todays_appointments?.length === 0 && (
                <>You Currently Have No Appointments Today.</>
              )}
              {results?.todays_appointments?.length > 0 && (
                <Paper>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Actions</TableCell>
                        <TableCell>Scheduled For</TableCell>
                        <TableCell>Customer</TableCell>
                        <TableCell>Contact Info</TableCell>
                        <TableCell>Assigned To</TableCell>
                      </TableRow>
                    </TableHead>
                    {results?.todays_appointments?.map(appointment => (
                      <TableRow>
                        <TableCell>
                          <AppointmentActions
                            appointment={appointment}
                            classes={actionClasses}
                          />
                        </TableCell>
                        <TableCell>
                          {getFullDateTime(appointment.start_date)}
                        </TableCell>
                        <TableCell className={actionClasses.customer}>
                          <Link
                            to={`/customers/${appointment.customer_id}/details`}
                          >
                            {appointment.customer?.fullname}
                          </Link>
                        </TableCell>
                        <TableCell>
                          {appointment.customer?.primary_phone}
                        </TableCell>
                        <TableCell>
                          {appointment.attendees.length > 0 &&
                            appointment.attendees[0].display_name}
                        </TableCell>
                      </TableRow>
                    ))}
                  </Table>
                </Paper>
              )}
            </Box>
          </Box>
        </Paper>
      </Box>
    </>
  );
};

export default ManagerDashboardDesktop;
