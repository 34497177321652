import React from 'react';
import { useUserContext } from 'components/MaterialUI/UserContext';
import { RoleGroup } from 'constants.js';

const ManagersOnly = ({ children, className }) => {
  const {
    currentUser: { role },
  } = useUserContext();

  if (role && RoleGroup.MANAGERS.includes(role)) {
    return <div className={className}>{children}</div>;
  }

  return null;
};

export default ManagersOnly;
