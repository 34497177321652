import React, { useState } from 'react';

import { DATE_NO_TIME_FORMAT } from 'constants.js';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import { capitalize, dateToFormatString } from 'utils';

import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import EmailIcon from '@mui/icons-material/Email';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import PhoneIcon from '@mui/icons-material/Phone';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';

import { TaskStatus, TaskStatusColor } from '../constants';
import TasksAction from './TasksAction';

const formatDate = d => dateToFormatString(d, DATE_NO_TIME_FORMAT);

const TaskDesktopTableRow = ({
  task: currentTask,
  selected,
  handleClick,
  refetch,
}) => {
  const [task, setTask] = useState(currentTask);
  const [taskStatus, setTaskStatus] = useState(task.status);
  const statusColor = (status, overdue) => {
    const highLightColor =
      (status === TaskStatus.CANCELLED && TaskStatusColor.GREY) ||
      (status === TaskStatus.TODO && overdue && TaskStatusColor.RED) ||
      (status === TaskStatus.TODO && TaskStatusColor.YELLOW) ||
      (status === TaskStatus.COMPLETE && TaskStatusColor.GREEN) ||
      TaskStatusColor.GREY;
    return highLightColor;
  };

  return (
    <TableRow
      hover
      role="checkbox"
      tabIndex={-1}
      key={task._id}
      selected={selected.includes(task._id)}
      style={{
        borderLeftWidth: '5px',
        borderLeftColor: statusColor(
          taskStatus,
          moment(task.due_date) < moment(),
        ),
        borderLeftStyle: 'solid',
      }}
    >
      <TableCell padding="checkbox">
        <Checkbox
          checked={selected.includes(task._id)}
          onClick={event => handleClick(event, task._id)}
          selected={selected.includes(task._id)}
        />
      </TableCell>
      <TableCell>
        <TasksAction
          task={task}
          setTask={setTask}
          setTaskStatus={setTaskStatus}
          taskStatus={taskStatus}
          refetch={refetch}
        />
      </TableCell>
      <TableCell>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
        >
          <Grid item>
            {task.type === 'opportunity' ? (
              <ThumbUpIcon />
            ) : task.type === 'email' ? (
              <EmailIcon />
            ) : task.type === 'call' ? (
              <PhoneIcon />
            ) : (
              <HelpOutlineIcon />
            )}
          </Grid>
          <Grid item>{capitalize(task.type)}</Grid>
        </Grid>
      </TableCell>
      <TableCell>
        <Link to={`/customers/${task.customer_id}/details`}>
          {task?.customer?.fullname ?? 'N/A'}
        </Link>
      </TableCell>
      <TableCell>{task.description}</TableCell>
      <TableCell>{task.assignee?.display_name ?? 'N/A'}</TableCell>
      <TableCell>{formatDate(task.created)}</TableCell>
      <TableCell>{formatDate(task.due_date)}</TableCell>
    </TableRow>
  );
};
export default TaskDesktopTableRow;
