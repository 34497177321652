import React from 'react';

/* external */
import { startCase, camelCase } from 'lodash';
import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import gql from 'graphql-tag';

/* Material UI */
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CircularProgress from '@mui/material/CircularProgress';
import InfoIcon from '@mui/icons-material/Info';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import Tooltip from '@mui/material/Tooltip';

/* internal */
import { formatOdometer, formatPrice } from 'utils';
import { greaseVehicleToAuctionsVehicle } from 'modules/auctions/utils';
import AuctionabilityCell from './AuctionabilityCell';
import StatusCell from './StatusCell';

// TODO: MPAUC-94: remove unused fields from auctions in cleanup phase:
// Confirm with Christina
// - country_of_origin
// - registered_outside_alberta
// - registered_province_outside_alberta

const VEHICLE_FRAGMENT = gql`
  fragment AgedInventoryRowVehicle on GreaseInventoryVehicle {
    id
    acode
    open_auction_items {
      id
      isActive
      isUpcoming
    }
    cbb {
      condition
      selected_price
      vid
    }
    cost
    days_in_stock
    dealer_id
    drive_type_name
    engine_compressor_name
    engine_config_name
    engine_cylinders
    engine_litres
    exterior_colour_name
    fuel_type_name
    interior_colour_name
    item_key
    make_name
    model_name
    odometer
    photo_count
    photos {
      id
      cloudinary_public_id
    }
    preselected_aged_inventory_vehicle {
      id
    }
    published_notes
    regular_price
    stock_number
    style_id
    transmission_name
    trim
    upholstery_name
    vin
    year
    ...AuctionabilityCellVehicle
    ...StatusCellVehicle
  }
  ${AuctionabilityCell.fragments.vehicle}
  ${StatusCell.fragments.vehicle}
`;

const PRESELECT_VEHICLE = gql`
  mutation preselectVehicle($vehicle: AuctionVehicleInput!) {
    auctions {
      createVehicle(vehicle: $vehicle) {
        id
        inventoryVehicle {
          id
          ...AgedInventoryRowVehicle
        }
      }
    }
  }
  ${VEHICLE_FRAGMENT}
`;

const DESELECT_VEHICLE = gql`
  mutation unpreselectVehicle($id: Int!) {
    auctions {
      deleteVehicle(id: $id) {
        id
        inventoryVehicle {
          id
          ...AgedInventoryRowVehicle
        }
      }
    }
  }
  ${VEHICLE_FRAGMENT}
`;

const titleize = str => startCase(camelCase(str));

const AgedInventoryRow = ({ vehicle, ...rest }) => {
  const history = useHistory();
  const [preselectVehicle, { loading: preselecting }] =
    useMutation(PRESELECT_VEHICLE);
  const [deselectVehicle, { loading: deselecting }] =
    useMutation(DESELECT_VEHICLE);

  const {
    id,
    cbb,
    cost,
    days_in_stock,
    make_name,
    model_name,
    odometer,
    open_auction_items,
    photo_count,
    preselected_aged_inventory_vehicle,
    regular_price,
    stock_number,
    year,
    is_auctionable_without_cbb,
  } = vehicle;

  const { condition: cbb_condition, selected_price: cbb_selected_price } =
    cbb ?? {};

  const isPreselected = Boolean(preselected_aged_inventory_vehicle);
  const atAuctionOrUpcoming = open_auction_items.length > 0;

  const handleClickPreselect = () => {
    preselectVehicle({
      variables: {
        vehicle: greaseVehicleToAuctionsVehicle(vehicle),
      },
    });
  };

  const handleClickDeselect = () =>
    deselectVehicle({
      variables: { id: preselected_aged_inventory_vehicle.id },
    });

  return (
    <TableRow {...rest}>
      <TableCell>
        {preselecting || deselecting ? (
          <CircularProgress />
        ) : (
          <Box display="flex" alignItems="stretch">
            <Tooltip title={'Click me to find out more about this vehicle!'}>
              <IconButton
                onClick={() => history.push(`/inventory/${id}`)}
                size="large"
              >
                <InfoIcon style={{ color: '#2196f3', fontSize: '22px' }} />
              </IconButton>
            </Tooltip>
            {!isPreselected &&
              is_auctionable_without_cbb &&
              !atAuctionOrUpcoming && (
                <Tooltip title="Preselect">
                  <IconButton onClick={handleClickPreselect} size="large">
                    <AddCircleIcon />
                  </IconButton>
                </Tooltip>
              )}
            {isPreselected && !atAuctionOrUpcoming && (
              <Tooltip title="Deselect">
                <IconButton onClick={handleClickDeselect} size="large">
                  <RemoveCircleIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        )}
      </TableCell>
      <StatusCell vehicle={vehicle} />
      <AuctionabilityCell vehicle={vehicle} />
      <TableCell>
        {`${year} ${make_name} ${model_name} (${stock_number})`} <br />
        {formatOdometer(odometer)}
      </TableCell>
      <TableCell>{formatPrice(regular_price, { cents: true })}</TableCell>
      <TableCell>{formatPrice(cost, { cents: true })}</TableCell>
      <TableCell>{photo_count}</TableCell>
      <TableCell>{days_in_stock}</TableCell>
      <TableCell>{titleize(cbb_condition)}</TableCell>
      <TableCell>{formatPrice(cbb_selected_price)}</TableCell>
    </TableRow>
  );
};
AgedInventoryRow.fragments = {
  vehicle: VEHICLE_FRAGMENT,
};

export default AgedInventoryRow;
