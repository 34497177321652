import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material';
import gql from 'graphql-tag';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { green } from '@mui/material/colors';
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';
import TextField from '@mui/material/TextField';

import { Regexps } from 'constants.js';

const CREATE_CUSTOMER = gql`
  mutation addCustomer($customer: CreateCustomerInput!) {
    createCustomer(input: $customer) {
      _id
    }
  }
`;

const CUSTOMER_SEARCH_QUERY = gql`
  query CustomerSearch($q: String!) {
    customerSearch(
      params: {
        filterQuery: "(and (and organization:'goauto' (not deleted:1)) (and resource_type:'smi.crm:customer'))"
        query: $q
        size: 25
      }
    ) {
      hits {
        hit {
          customer {
            _id
          }
        }
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  closeButton: {
    color: '#24a0ed',
  },
  saveButton: {
    backgroundColor: green[500],
  },
}));

const AddCustomerModal = ({ handleClose }) => {
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [firstError, setFirstError] = useState(false);
  const [lastError, setLastError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [emailHelperText, setEmailHelperText] = useState('');

  const [addCustomer, { loading: addLoading }] = useMutation(CREATE_CUSTOMER, {
    onCompleted: data => {
      enqueueSnackbar('Customer created!', { variant: 'success' });
      history.push(`/customers/${data.createCustomer._id}/details`);
    },
    onError: error =>
      enqueueSnackbar(`An error occurred: ${error.message}`, {
        variant: 'error',
      }),
  });

  const [searchCustomer, { loading: searchLoading }] = useLazyQuery(
    CUSTOMER_SEARCH_QUERY,
    {
      onError: error =>
        enqueueSnackbar(
          `Error checking phone number or email: ${error.message}`,
          {
            variant: 'error',
          },
        ),
      onCompleted: data => {
        if (data.customerSearch.hits.hit.length > 0) {
          const customerText =
            data.customerSearch.hits.hit.length > 1
              ? 'Customers'
              : 'A customer';
          enqueueSnackbar(
            `${customerText} with this phone number or email already exists.`,
            { variant: 'error' },
          );
          history.push(`/customers/search?${phoneNumber}|${email}`);
        } else {
          addCustomer({
            variables: {
              customer: {
                first_name: firstName,
                last_name: lastName,
                home_phone: phoneNumber,
                emails: [{ email }],
                company_name: companyName || undefined,
              },
            },
          });
        }
      },
    },
  );

  const handleFirstName = event => {
    setFirstName(event.target.value);
  };

  const handleLastName = event => {
    setLastName(event.target.value);
  };

  const handlePhoneNumber = event => {
    setPhoneNumber(event.target.value);
  };

  const handleEmail = event => {
    const emailValue = event.target.value;
    setEmail(emailValue);
    if (emailValue && !Regexps.EmailFormat.test(emailValue)) {
      setEmailError(true);
      setEmailHelperText('Invalid email format');
    } else {
      setEmailError(false);
      setEmailHelperText('');
    }
  };

  const handleCompanyName = event => {
    setCompanyName(event.target.value);
  };

  const handleSubmit = () => {
    const isEmailValid = !email || Regexps.EmailFormat.test(email);

    setFirstError(firstName === '');
    setLastError(lastName === '');
    setPhoneError(phoneNumber === '' && email === '');
    setEmailError((email === '' && phoneNumber === '') || !isEmailValid);

    const query =
      phoneNumber && email ? `${phoneNumber}|${email}` : phoneNumber || email;

    if (firstName && lastName && (phoneNumber || (email && isEmailValid))) {
      searchCustomer({
        variables: {
          q: query,
        },
      });
    } else {
      enqueueSnackbar(
        'New Customer requires first name, last name, and either phone number or valid email.',
        { variant: 'warning' },
      );
    }
  };

  return (
    <>
      <DialogTitle>Create a new Customer</DialogTitle>
      <DialogContent>
        <TextField
          label="First Name"
          value={firstName}
          onChange={handleFirstName}
          error={firstError}
        />
      </DialogContent>
      <DialogContent>
        <TextField
          label="Last Name"
          value={lastName}
          onChange={handleLastName}
          error={lastError}
        />
      </DialogContent>
      <DialogContent>
        <TextField
          label="Phone Number"
          value={phoneNumber}
          onChange={handlePhoneNumber}
          error={phoneError}
        />
      </DialogContent>
      <DialogContent>
        <TextField
          label="Email"
          value={email}
          onChange={handleEmail}
          error={emailError}
          helperText={emailHelperText}
        />
      </DialogContent>
      <DialogContent>
        <TextField
          label="Company Name"
          value={companyName}
          onChange={handleCompanyName}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} className={classes.closeButton}>
          Close
        </Button>
        <Button
          onClick={handleSubmit}
          style={theme.actions.confirm}
          variant="contained"
        >
          Create
        </Button>
      </DialogActions>
      <LoadingBackdrop open={addLoading || searchLoading} />
    </>
  );
};

export default AddCustomerModal;
