import React, { useState } from 'react';

/* external */
import { useMutation } from '@apollo/react-hooks';
import { Link } from 'react-router-dom';
import gql from 'graphql-tag';
import { useSnackbar } from 'notistack';

/* Material UI */
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteIcon from '@mui/icons-material/Delete';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Tooltip from '@mui/material/Tooltip';

/* internal */
import { formatPrice } from 'utils';
import { outOfProvinceWarning } from 'modules/auctions/const';
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import { usePersistedState } from 'utils';
import ConfirmDialog from 'components/MaterialUI/ConfirmDialog';
import { errorHandler } from 'modules/auctions/utils';
import DeleteItemForm from './DeleteItemForm';
import ItemDescriptionCell from './common/ItemDescriptionCell';
import ItemEndTimeCell from './common/ItemEndTimeCell';
import ItemFavouriteCell from './common/ItemFavouriteCell';
import ItemPhotoCell from './common/ItemPhotoCell';

// Added in the info from the auction item feed stuff so that
// we update the auction item "immediately" after the bid is
// placed.  Otherwise, we'll get a brief blip of time when the bid
// button could re-appear but not be valid (since the new bid may
// not have come through via subscriptions yet)
const CREATE_BID = gql`
  mutation createBid($bid: BidInput!) {
    auctions {
      createBid(bid: $bid) {
        id
        amount
        auctionItemId
        dealerId
        auctionItem {
          id
          bidCount
          dealer {
            id
            name
            province_name
          }
          dealerId
          deleted
          deletionReason
          isActive
          isClosed
          leadingBid {
            id
            dealer {
              id
              name
            }
            amount
          }
          minimumBidIncrement
          minimumStartingBid
          reserve
          ...ItemDescriptionCellAuctionItem
          ...ItemEndTimeCellAuctionItem
          ...ItemPhotoCellAuctionItem
        }
      }
    }
  }
  ${ItemDescriptionCell.fragments.auctionItem}
  ${ItemEndTimeCell.fragments.auctionItem}
  ${ItemPhotoCell.fragments.auctionItem}
`;

const DELETE_AUCTION_ITEM = gql`
  mutation deleteAuctionItem($id: Int!, $deletionReason: String!) {
    auctions {
      deleteAuctionItem(id: $id, deletionReason: $deletionReason) {
        id
        deleted
        deletionReason
      }
    }
  }
`;

const ActiveItemsRow = ({
  carfax,
  item,
  isNew,
  showReserveColumn,
  index,
  isChecked,
  isDeletable,
  handleCheckItem,
  ...rest
}) => {
  const { dealerId, dealerProvinceName } = useDealerContext();
  const theme = useTheme();
  const { enqueueSnackbar: snackIt } = useSnackbar();
  const [showDeleteForm, setShowDeleteForm] = useState(false);
  const [showConfirmBidDialog, setShowConfirmBidDialog] = useState(false);

  const [hideWarningSelection, setHideWarningSelection] = useState(false);
  const {
    bidCount,
    dealer: { name: seller },
    dealerBids = [],
    dealerProxyBids = [],
    dealerId: itemDealerId,
    deleted,
    deletionReason,
    id,
    isActive,
    leadingBid,
    minimumBidIncrement,
    minimumStartingBid,
    reserve,
    vehicle,
  } = item;

  const { locatedProvince } = vehicle;

  // If you don't handle errors, market-mobile crashes
  const [createBid, { loading: bidding }] = useMutation(CREATE_BID, {
    onError: err => errorHandler(snackIt, () => {})(err),
    onCompleted: ({
      auctions: {
        createBid: {
          amount,
          auctionItem: {
            vehicle: { year, make, model },
          },
        },
      },
    }) =>
      snackIt(`Placed Bid for $${amount} on ${year} ${make} ${model}`, {
        variant: 'success',
      }),
  });
  const [
    deleteAuctionItem,
    { loading: deletingItem, errors: deletingItemErrors },
  ] = useMutation(DELETE_AUCTION_ITEM, {
    onError: err => errorHandler(snackIt, () => {})(err),
  });
  const [ignoreWarningItemIds, setIgnoreWarningItemIds] = usePersistedState(
    'ignoreOutOfProvinceWarningAuctionItemIds',
    '[]',
  );

  const ignoreWarning = JSON.parse(ignoreWarningItemIds)?.includes(id);

  const addToIgnoreList = () =>
    setIgnoreWarningItemIds(
      JSON.stringify([id, ...JSON.parse(ignoreWarningItemIds)]),
    );

  const hasBids = !deleted && dealerBids.length > 0;
  const hasProxy =
    dealerProxyBids.length > 0 &&
    dealerProxyBids.sort((a, b) => b.id - a.id)[0].amount > 0;

  const isWinning =
    !deleted && leadingBid && leadingBid.dealer?.id === dealerId;
  const isOutbid = hasBids && !isWinning;

  const canBid =
    isActive && itemDealerId !== dealerId && !isWinning && !deleted;

  const canDelete = isDeletable && !deletingItem;

  const minimumBid = leadingBid
    ? leadingBid.amount + minimumBidIncrement
    : minimumStartingBid;

  const handleCreateBid = () => {
    if (hideWarningSelection) addToIgnoreList();

    createBid({
      variables: { bid: { dealerId, amount: minimumBid, auctionItemId: id } },
    });
  };

  const handleClickBid = e => {
    e.stopPropagation();
    if (dealerProvinceName !== locatedProvince && !ignoreWarning) {
      setShowConfirmBidDialog(true);
    } else handleCreateBid();
  };

  const handleSubmitDelete = ({ deletionReason }) => {
    deleteAuctionItem({
      variables: { id, deletionReason },
    }).then(() => setShowDeleteForm(false));
  };
  const handleClickDelete = e => {
    setShowDeleteForm(true);
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <TableRow hover={!deleted} {...rest}>
      <DeleteItemForm
        isOpen={showDeleteForm}
        submitErrors={deletingItemErrors}
        onClose={() => setShowDeleteForm(false)}
        onSubmit={handleSubmitDelete}
      />
      <ConfirmDialog
        isOpen={showConfirmBidDialog}
        onClose={() => setShowConfirmBidDialog(false)}
        text={outOfProvinceWarning(dealerProvinceName, locatedProvince)}
        onConfirm={handleCreateBid}
        content={
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={hideWarningSelection}
                  onChange={() => setHideWarningSelection(p => !p)}
                />
              }
              label="Don't show this warning again for this item."
            />
          </Box>
        }
      />
      <TableCell padding="checkbox">
        <Checkbox
          checked={isChecked}
          disabled={!canDelete}
          onClick={_ => handleCheckItem(id)}
        />
      </TableCell>
      <TableCell>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Tooltip title={'Click me to find out more about this auction item!'}>
            <Link
              to={`/auctions/items/${id}?scrollY=${window.scrollY}&index=${index}`}
              style={{ height: '24px' }}
            >
              <InfoIcon
                style={{
                  color: '#2196f3',
                  fontSize: '24px',
                }}
              />
            </Link>
          </Tooltip>
          {canDelete ? (
            <IconButton onClick={handleClickDelete} size="large">
              <DeleteIcon />
            </IconButton>
          ) : (
            <Box component="span" width="24px" paddingLeft="24px" />
          )}
          {canBid && !bidding && (
            <Button
              disabled={bidding}
              variant="contained"
              onClick={handleClickBid}
              style={theme.actions.confirm}
            >
              Bid {formatPrice(minimumBid)}
            </Button>
          )}
          {bidding && (
            <>
              <CircularProgress />
              <Box component="span" ml={1} fontWeight="bold">
                Bidding...
              </Box>
            </>
          )}
          {isNew && <Box textAlign="center">NEW</Box>}
        </Box>
      </TableCell>
      <ItemPhotoCell item={item} />
      <ItemDescriptionCell
        carfax={carfax}
        item={item}
        isWinning={isWinning}
        isOutbid={isOutbid}
        hasProxy={hasProxy}
      />
      {deleted ? (
        <TableCell colSpan={showReserveColumn ? 7 : 6}>
          Deleted because: "{deletionReason}"
        </TableCell>
      ) : (
        <>
          <ItemFavouriteCell item={item} />
          <TableCell>{seller}</TableCell>
          <ItemEndTimeCell item={item} countdown />
          <TableCell>{bidCount}</TableCell>
          <TableCell>{formatPrice(minimumStartingBid)}</TableCell>
          <TableCell>
            {formatPrice(leadingBid?.amount)}
            <br />
            {leadingBid?.dealer?.name}
          </TableCell>
          {showReserveColumn && (
            <TableCell>
              {dealerId === itemDealerId && reserve !== null
                ? formatPrice(reserve)
                : null}
            </TableCell>
          )}
        </>
      )}
    </TableRow>
  );
};

ActiveItemsRow.fragments = {
  auctionItem: gql`
    fragment ActiveItemsRowAuctionItem on AuctionItem {
      id
      bidCount
      dealer {
        id
        name
        province_name
      }
      dealerBids(dealerId: $dealerId) {
        id
        amount
      }
      dealerProxyBids(dealerId: $dealerId) {
        id
        amount
      }
      dealerBlindBid(dealerId: $dealerId) {
        id
        amount
      }
      dealerId
      deleted
      deletionReason
      isActive
      isClosed
      leadingBid {
        id
        dealer {
          id
          name
        }
        amount
      }
      minimumBidIncrement
      minimumStartingBid
      permissions {
        delete
        update
      }
      reserve
      vehicle {
        id
        year
        make
        model
        locatedProvince
      }
      ...ItemDescriptionCellAuctionItem
      ...ItemEndTimeCellAuctionItem
      ...ItemFavouriteCellAuctionItem
      ...ItemPhotoCellAuctionItem
    }
    ${ItemDescriptionCell.fragments.auctionItem}
    ${ItemEndTimeCell.fragments.auctionItem}
    ${ItemFavouriteCell.fragments.auctionItem}
    ${ItemPhotoCell.fragments.auctionItem}
  `,
  auctionItemFeed: gql`
    fragment AuctionItemsFeed on AuctionItem {
      id
      bidCount
      dealer {
        id
        name
        province_name
      }
      dealerId
      deleted
      deletionReason
      isActive
      isClosed
      leadingBid {
        id
        dealer {
          id
          name
        }
        amount
      }
      minimumBidIncrement
      minimumStartingBid
      reserve
      ...ItemDescriptionCellAuctionItem
      ...ItemEndTimeCellAuctionItem
      ...ItemPhotoCellAuctionItem
    }
    ${ItemDescriptionCell.fragments.auctionItem}
    ${ItemEndTimeCell.fragments.auctionItem}
    ${ItemPhotoCell.fragments.auctionItem}
  `,
};
export default ActiveItemsRow;
