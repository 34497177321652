// External
import React from 'react';

// MUI
import { AppBar, Button, Toolbar, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

// Internal

const useStyles = makeStyles(theme => ({
  appBar: {
    top: 'auto',
    bottom: 0,
    zIndex: 1000,
    backgroundColor: theme.colours.greys.grey6,
  },
  toolBar: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '240px',
    },
  },
  text: {
    bottom: '15px',
    color: 'black',
    textAlign: 'right',
    fontWeight: 'bold',
    marginRight: '30px',
  },
  button: {
    backgroundColor: theme.colours.add,
    color: 'white',
    width: '100px',
  },
}));

const SaveChanges = () => {
  const classes = useStyles();

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolBar}>
        <Typography variant="h6" className={classes.text}>
          Remember to save your changes!
        </Typography>
        <Button variant="contained" type="submit" className={classes.button}>
          Save
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default SaveChanges;
