import React from 'react';

/* external */
import gql from 'graphql-tag';
import { orderBy } from 'lodash';

/* Material UI */
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Tooltip from '@mui/material/Tooltip';
import makeStyles from '@mui/styles/makeStyles';

/* internal */
import FormatDateTZ from 'components/MaterialUI/FormatDateTZ';
import StatusBox from './StatusBox';

const useStyles = makeStyles(theme => ({
  infoIcon: {
    color: theme.actions.info.backgroundColor,
    fontSize: '22px',
  },
}));

const ItemRow = ({
  creditApp: {
    id,
    firstName,
    lastName,
    dateCreated,
    dealer,
    dealerTrackSubmissions = [],
  },
  onClickDetails,
  ...rest
}) => {
  const latestSubmission = orderBy(
    dealerTrackSubmissions,
    'dateCreated',
    'desc',
  )?.[0];

  return (
    <TableRow {...rest}>
      <TableCell>
        <Tooltip
          title={`Click me to find out more about this credit app (#${id})!`}
        >
          <IconButton onClick={onClickDetails} size="large">
            <InfoIcon className={useStyles().infoIcon} />
          </IconButton>
        </Tooltip>
      </TableCell>
      <TableCell>
        {firstName} {lastName}
      </TableCell>
      <TableCell>{dealer?.name}</TableCell>
      <TableCell>
        <FormatDateTZ date={dateCreated} />
      </TableCell>
      <TableCell>
        <StatusBox
          applicationError={latestSubmission?.error}
          applicationStatus={latestSubmission?.applicationStatus}
        />
      </TableCell>
    </TableRow>
  );
};

ItemRow.fragments = {
  creditApp: gql`
    fragment ItemRowCreditApp on CreditApp {
      id
      dateCreated
      dealer {
        name
      }
      firstName
      lastName
      dealerTrackSubmissions {
        id
        applicationStatus
        createdBy
        dateCreated
        dealer {
          id
          name
        }
        dealerTrackDealId
        error
      }
    }
  `,
};
export default ItemRow;
