import React from 'react';

/* external */
import gql from 'graphql-tag';

/* Material UI */
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

/* internal */
import TradeFormContainer from './TradeFormContainer';

const TradeFormDialog = ({
  appraisal,
  onClose,
  onCreate,
  onUpdate,
  ...rest
}) => {
  return (
    <Dialog onClose={onClose} {...rest}>
      <DialogTitle>
        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          alignItems="center"
        >
          <Box>Appraisal Details</Box>
          <IconButton onClick={onClose} size="large">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        {appraisal && (
          <TradeFormContainer
            appraisal={appraisal}
            onCreate={onCreate}
            onUpdate={onUpdate}
            onCancel={onClose}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

TradeFormDialog.fragments = {
  appraisal: gql`
    fragment TradeFormDialogAppraisal on Appraisal {
      ...TradeFormContainerAppraisal
    }
    ${TradeFormContainer.fragments.appraisal}
  `,
};

export default TradeFormDialog;
