import PropTypes from 'prop-types';
import React from 'react';

import { makeStyles } from '@mui/styles';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';

const useStyles = makeStyles(theme => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const headCells = [
  { label: 'Actions', id: 'actions', numeric: false, sortable: false },
  {
    label: 'Added On',
    id: 'last_status_change',
    numeric: false,
    sortable: true,
  },
  { label: 'Customer', id: 'customer_name', numeric: false, sortable: true },
  { label: 'Deal #', id: 'deal_number', numeric: false, sortable: false },
  { label: 'Vehicle', id: 'vehicle', numeric: false, sortable: false },
  { label: 'Trade', id: 'trade', numeric: false, sortable: false },
  { label: 'Assignees', id: 'assignees', numeric: false, sortable: false },
  {
    label: 'Status',
    id: 'deallog_status_order',
    numeric: false,
    sortable: true,
  },
  { label: 'Sub Status', id: 'sub_status', numeric: false, sortable: true },
  { label: 'Gross', id: 'gross', numeric: false, sortable: false },
  { label: 'Last Updated', id: 'updated', numeric: false, sortable: true },
  {
    label: 'Delivery Date',
    id: 'delivery_date',
    numeric: false,
    sortable: false,
  },
];

function DealLogTableHeader({ order, orderBy, onRequestSort }) {
  const { visuallyHidden } = useStyles();
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align="left"
            padding="normal"
            style={{
              width: ['Added On', 'Gross', 'Trade'].includes(headCell.label)
                ? 150
                : '',
            }}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              disabled={!headCell.sortable}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

DealLogTableHeader.propTypes = {
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  onRequestSort: PropTypes.func.isRequired,
};

export default DealLogTableHeader;
