import moment from 'moment-timezone';
import React from 'react';
import { useHistory } from 'react-router-dom';

// Material UI
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Icon from '@mui/material/Icon';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { DatePicker } from '@mui/x-date-pickers';

import Loading from 'components/MaterialUI/Loading';

// Internal
import { sessionKeys } from '../constants';
import { getFullDateTime } from 'utils';
import { getSalesDashboardNumbers } from '../utils';
import SalesDashboardDesktopRow from './SalesDashboardDesktopRow';
import { AppointmentStatus, Status, STATUS_ICON } from 'constants.js';

const useStyles = makeStyles(theme => ({
  card: {
    width: '175px',
    height: '125px',
    textAlign: 'center',
    padding: '1rem',
    fontSize: '18px',
    fontWeight: 'bold',
  },
}));

const SalesDashboardDesktop = ({
  data,
  loading,
  handleUpdateStatus,
  dealerName,
  buttonColor,
  statusColor,
  dealerId,
  selectedUser,
  refetch,
  dateFromFilter,
  dateToFilter,
  handleDateFromChange,
  handleDateToChange,
}) => {
  const { card } = useStyles();
  const history = useHistory();

  const {
    openOpportunitiesCount,
    completedOpportunitiesCount,
    tubedOpportunitiesCount,
    lostOpportunitiesCount,
    pendingDealsCount,
    bookedDealsCount,
    openOpps,
    completedOpps,
    tasks,
    tasksCount,
    appointments,
    upcomingCount,
    attendedCount,
    createdTodayCount,
    appointmentDateRangeCount,
  } = getSalesDashboardNumbers(data);

  const setOppFiltersStatus = statuses => {
    const filters = {
      sort_by: [
        {
          key: 'created',
          direction: -1,
        },
      ],
      statuses,
      sales_assignees: [selectedUser],
    };
    window.sessionStorage.setItem(
      sessionKeys.oppFilters,
      JSON.stringify(filters),
    );
    window.sessionStorage.setItem(sessionKeys.oppDealer, dealerId);
    history.push('/opportunities/search');
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <>
      <Box paddingTop={2} paddingLeft={1}>
        <Typography variant="h5" style={{ fontWeight: 'bold' }}>
          Sales
        </Typography>
        <DatePicker
          disableToolbar
          variant="inline"
          format="MM/DD/YYYY"
          margin="normal"
          id="date-picker-inline"
          label="Date From"
          style={{
            padding: '1em',
          }}
          onChange={date => handleDateFromChange(date)}
          value={moment(dateFromFilter)}
        />
        <DatePicker
          disableToolbar
          variant="inline"
          format="MM/DD/YYYY"
          margin="normal"
          id="date-picker-inline"
          label="Date To"
          style={{
            padding: '1em',
          }}
          onChange={date => handleDateToChange(date)}
          value={moment(dateToFilter)}
        />
      </Box>
      <Box paddingTop={2} paddingLeft={1}>
        <Paper
          elevation={0}
          style={{ backgroundColor: '#f2f2f2', maxWidth: '1700px' }}
        >
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: '1rem',
            }}
          >
            <Card onClick={() => setOppFiltersStatus(Status.OPEN_OPPS)}>
              <CardActionArea>
                <CardContent className={card}>
                  <Box>{openOpportunitiesCount}</Box>
                  <Box>Open Opportunities</Box>
                </CardContent>
              </CardActionArea>
            </Card>
            <Card onClick={() => setOppFiltersStatus(Status.COMPLETED)}>
              <CardActionArea>
                <CardContent className={card}>
                  <Box>{completedOpportunitiesCount}</Box>
                  <Box>Sold Deals</Box>
                </CardContent>
              </CardActionArea>
            </Card>
            <Card onClick={() => setOppFiltersStatus([Status.TUBED])}>
              <CardActionArea>
                <CardContent className={card}>
                  <Box>{tubedOpportunitiesCount}</Box>
                  <Box>Tubed Opportunities</Box>
                </CardContent>
              </CardActionArea>
            </Card>
            <Card onClick={() => setOppFiltersStatus([Status.LOST])}>
              <CardActionArea>
                <CardContent className={card}>
                  <Box>{lostOpportunitiesCount}</Box>
                  <Box>Lost Opportunities</Box>
                </CardContent>
              </CardActionArea>
            </Card>
            <Card
              onClick={() => {
                window.sessionStorage.setItem(
                  sessionKeys.taskAssignee,
                  JSON.stringify([selectedUser]),
                );
                window.sessionStorage.setItem(
                  sessionKeys.taskStatus,
                  JSON.stringify([0]),
                );
                window.sessionStorage.setItem(sessionKeys.taskDealer, dealerId);
                history.push('/tasks');
              }}
            >
              <CardActionArea>
                <CardContent className={card}>
                  <Box>{tasksCount}</Box>
                  <Box>Pending Tasks</Box>
                </CardContent>
              </CardActionArea>
            </Card>
          </Box>
          <Box style={{ padding: '0 1rem' }}>
            <hr />
          </Box>
          <Box style={{ padding: '0 1rem' }}>
            <Typography variant="h6" style={{ fontWeight: 'bold' }}>
              Open Opportunities
            </Typography>
            {openOpps?.length === 0 && !loading && (
              <Box paddingTop={1}>
                You Currently Have No Open Opportunities.
              </Box>
            )}
            {openOpps?.length > 0 && (
              <Paper elevation={0}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Status</TableCell>
                      <TableCell>Created</TableCell>
                      <TableCell>Customer</TableCell>
                      <TableCell>Deal # / Stock Type</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {openOpps?.map(opp => (
                      <TableRow
                        key={opp._id}
                        onClick={() =>
                          history.push(
                            `/opportunities/${opp._id}/${opp.customer_id}/details`,
                          )
                        }
                      >
                        <TableCell>
                          {' '}
                          <Icon
                            style={{
                              fontSize: '1rem',
                              color: STATUS_ICON[opp.status].color,
                            }}
                            className={STATUS_ICON[opp.status].icon}
                          />
                        </TableCell>
                        <TableCell>{getFullDateTime(opp.created)}</TableCell>
                        <TableCell>{opp.customer_name}</TableCell>
                        <TableCell>
                          {opp?.dms_deal?.deal_number || 'No Deal Number'} /{' '}
                          <Chip
                            color="secondary"
                            label={`${opp?.stock_type ?? 'N/A'}`}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Paper>
            )}
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginRight: 0,
                  marginLeft: 'auto',
                  padding: '1rem 0',
                }}
              >
                <Button
                  onClick={() => setOppFiltersStatus(Status.OPEN_OPPS)}
                  variant="contained"
                  color="default"
                >
                  View All
                </Button>
              </Box>
            </Box>
            <Box>
              <hr />
            </Box>
            <Box style={{ padding: '1rem 0' }}>
              <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                Tasks
              </Typography>
              {tasks?.length === 0 && (
                <Box paddingTop={1}>You Currently Have No Pending Tasks.</Box>
              )}
              {tasks?.map(task => (
                <SalesDashboardDesktopRow
                  task={task}
                  handleUpdateStatus={handleUpdateStatus}
                  dealerName={dealerName}
                  buttonColor={buttonColor}
                  statusColor={statusColor}
                  refetch={refetch}
                />
              ))}
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginRight: 0,
                    marginLeft: 'auto',
                    padding: '1rem 0',
                  }}
                >
                  <Button
                    onClick={() => {
                      window.sessionStorage.setItem(
                        sessionKeys.taskAssignee,
                        JSON.stringify([selectedUser]),
                      );
                      window.sessionStorage.setItem(
                        sessionKeys.taskStatus,
                        JSON.stringify([0]),
                      );
                      window.sessionStorage.setItem(
                        sessionKeys.taskDealer,
                        dealerId,
                      );
                      history.push('/tasks');
                    }}
                    variant="contained"
                    color="default"
                  >
                    View All
                  </Button>
                </Box>
              </Box>
              <Box>
                <hr />
              </Box>
              <Box style={{ padding: '1rem 0' }}>
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                  Deal Log Summary
                </Typography>
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    paddingTop: '1rem',
                  }}
                >
                  <Box style={{ paddingRight: '1rem' }}>
                    <Card onClick={() => setOppFiltersStatus([Status.PENDING])}>
                      <CardActionArea>
                        <CardContent className={card}>
                          <Box>{pendingDealsCount}</Box>
                          <Box>Pending Deals</Box>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Box>
                  <Box style={{ paddingRight: '1rem' }}>
                    <Card onClick={() => setOppFiltersStatus(Status.OPEN)}>
                      <CardActionArea>
                        <CardContent className={card}>
                          <Box>{bookedDealsCount}</Box>
                          <Box>Booked Deals</Box>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Box>
                  <Box style={{ width: '100%' }}>
                    <Paper>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>Status</TableCell>
                            <TableCell>Completed</TableCell>
                            <TableCell>Customer</TableCell>
                            <TableCell>Deal # / Stock Type</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {completedOpps?.map(opp => (
                            <TableRow
                              key={opp._id}
                              onClick={() =>
                                history.push(
                                  `/opportunities/${opp._id}/${opp.customer_id}/details`,
                                )
                              }
                            >
                              <TableCell>
                                {' '}
                                <Icon
                                  style={{
                                    fontSize: '1rem',
                                    color: STATUS_ICON[opp.status].color,
                                  }}
                                  className={STATUS_ICON[opp.status].icon}
                                />
                              </TableCell>
                              <TableCell>
                                {getFullDateTime(opp.created)}
                              </TableCell>
                              <TableCell>{opp.customer_name}</TableCell>
                              <TableCell>
                                {opp.dms_deal.deal_number || 'No Deal Number'} /
                                <Chip
                                  color="secondary"
                                  label={`${opp?.stock_type ?? 'N/A'}`}
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Paper>
                  </Box>
                </Box>
              </Box>
              <Box>
                <hr />
              </Box>
              <Box style={{ padding: '1rem 0' }}>
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                  Appointments
                </Typography>
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    paddingTop: '1rem',
                    paddingBottom: '1rem',
                  }}
                >
                  <Box style={{ paddingRight: '1rem' }}>
                    <Card
                      onClick={() => {
                        window.sessionStorage.setItem(
                          sessionKeys.startDateFromFilter,
                          JSON.stringify(moment().startOf('day')),
                        );
                        window.sessionStorage.setItem(
                          sessionKeys.apptDealer,
                          dealerId,
                        );
                        history.push('/appointments');
                      }}
                    >
                      <CardActionArea>
                        <CardContent className={card}>
                          <Box>{upcomingCount}</Box>
                          <Box>Upcoming Appointments</Box>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Box>
                  <Box style={{ paddingRight: '1rem' }}>
                    <Card
                      onClick={() => {
                        window.sessionStorage.setItem(
                          sessionKeys.apptFilterFacets,
                          JSON.stringify([
                            {
                              model: 'Appointment',
                              field: 'statuses',
                              key: AppointmentStatus.ATTENDED,
                              value: 'Attended',
                              options: {},
                            },
                          ]),
                        );
                        window.sessionStorage.setItem(
                          sessionKeys.apptDealer,
                          dealerId,
                        );
                        history.push('/appointments');
                      }}
                    >
                      <CardActionArea>
                        <CardContent className={card}>
                          <Box>{attendedCount}</Box>
                          <Box>Appointments Attended (Last 7 Days)</Box>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Box>
                  <Box style={{ paddingRight: '1rem' }}>
                    <Card
                      onClick={() => {
                        window.sessionStorage.setItem(
                          sessionKeys.createdUntilFilter,
                          JSON.stringify(moment().endOf('day').toISOString()),
                        );
                        window.sessionStorage.setItem(
                          sessionKeys.createdFromFilter,
                          JSON.stringify(moment().startOf('day').toISOString()),
                        );
                        window.sessionStorage.setItem(
                          sessionKeys.apptDealer,
                          dealerId,
                        );
                        history.push('/appointments');
                      }}
                    >
                      <CardActionArea>
                        <CardContent className={card}>
                          <Box>{createdTodayCount}</Box>
                          <Box>Appointments Created Today</Box>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Box>
                  <Box style={{ paddingRight: '1rem' }}>
                    <Card
                      onClick={() => {
                        window.sessionStorage.setItem(
                          sessionKeys.createdUntilFilter,
                          JSON.stringify(
                            moment(dateToFilter).endOf('day').toISOString(),
                          ),
                        );
                        window.sessionStorage.setItem(
                          sessionKeys.createdFromFilter,
                          JSON.stringify(
                            moment(dateFromFilter).startOf('day').toISOString(),
                          ),
                        );
                        window.sessionStorage.setItem(
                          sessionKeys.apptDealer,
                          dealerId,
                        );
                        history.push('/appointments');
                      }}
                    >
                      <CardActionArea>
                        <CardContent className={card}>
                          <Box>{appointmentDateRangeCount}</Box>
                          <Box>
                            {`Appointments from ${moment(dateFromFilter).format(
                              'MM/DD/YYYY',
                            )} to ${moment(dateToFilter).format('MM/DD/YYYY')}`}
                          </Box>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Box>
                </Box>
                <Box paddingTop={2} paddingLeft={1}>
                  <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                    Upcoming Appointments
                  </Typography>
                </Box>
                <Box style={{ width: '100%' }}>
                  {appointments.length === 0 && (
                    <>You Currently Have No Appointments Today.</>
                  )}
                  {appointments.length > 0 && (
                    <Paper>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>Scheduled For</TableCell>
                            <TableCell>Customer</TableCell>
                            <TableCell>Contact Info</TableCell>
                            <TableCell>Assigned To</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {appointments.map(appointment => (
                            <TableRow>
                              <TableCell>
                                {getFullDateTime(appointment.start_date)}
                              </TableCell>
                              <TableCell>
                                {appointment.customer.fullname}
                              </TableCell>
                              <TableCell>
                                {appointment.customer.primary_phone}
                              </TableCell>
                              <TableCell>
                                {appointment.attendees.length > 0 &&
                                  appointment.attendees[0].display_name}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Paper>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Paper>
      </Box>
    </>
  );
};

export default SalesDashboardDesktop;
