import React, { useRef, useState } from 'react';

/* external */
import { useSnackbar } from 'notistack';
import { cloneDeep } from 'lodash';

/* Material UI */
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';

/* external */
import InspectionCheckboxes from '../InspectionCheckboxes';

function CustomAccordion(props) {
  const { title, list, values, handles, greenValue, redValue } = props;
  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>
          <b>{title}</b>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container alignItems="center">
          {Object.entries(list).map(([name, display_name]) => {
            return (
              <React.Fragment key={name}>
                {name === 'f1' && (
                  <Grid
                    container
                    alignItems="center"
                    style={{ marginBottom: '1rem' }}
                  >
                    <Grid item xs={12} style={{ paddingLeft: '1rem' }}>
                      <TextField
                        label="Odometer before test"
                        name={'odometer_before'}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} style={{ paddingLeft: '1rem' }}>
                      <TextField
                        label="Odometer after test"
                        name={'odometer_after'}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                )}
                <Grid
                  item
                  xs={8}
                  style={{ paddingLeft: '1rem', marginBottom: '.5rem' }}
                >
                  {display_name}
                </Grid>
                <Grid item xs={4}>
                  <InspectionCheckboxes
                    valueGroup={values}
                    handleGroup={handles}
                    name={name}
                    orange={false}
                    greenValue={greenValue}
                    redValue={redValue}
                  />
                </Grid>
                {name === 'd4' && (
                  <Grid
                    container
                    alignItems="center"
                    style={{ marginBottom: '1rem' }}
                  >
                    <Grid item xs={12} style={{ paddingLeft: '1rem' }}>
                      <TextField
                        label="Battery %"
                        name={'d4_battery'}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                )}
              </React.Fragment>
            );
          })}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

const ToyotaPDIForm = props => {
  const { enqueueSnackbar } = useSnackbar();

  // Toyota PDI form fields
  const bulletins = {
    tsb1: 'TSB 0961',
    tsb2: 'TSB 2077',
    tsb3: 'TSB 3134',
    tsb4: 'TSB 5329',
    tsb5: 'TSB 5330',
    tsb6: 'TSB 5331',
    tsb7: 'TSB 5337',
    tsb8: 'TSB 5339',
    tsb9: 'TSB 5340',
    tsb10: 'TSB 5341',
  };
  const before = {
    a1: 'Install short pin',
  };
  const functional = {
    b1: 'Check dome, courtesy, map and sun visor lights*',
    b2: 'Check warning/indicator lights, gauges and horn',
    b3: 'Check windshield wipers and washers',
    b4: 'Check headlights, instrument lights, turn signals, emergency flashers and brake lights',
    b5: 'Check inside/outside rear view mirror operation/adjustment',
    b6: 'Check cigarette lighter* and power outlets',
    b7: 'Check sliding roof*',
    b8: 'Check audio/navigation/backup camera* system and set clock',
    b9: 'Check Rear Seat Entertainment System* operation',
    b10: 'Check that green light for SOS button is on with vehicle started *',
    b11: 'Install shift-lock override button cover',
    b12: 'Check Parking Assist System*',
    b13: 'Set first maintenance reminder (8000 km), Dealer contact information and units of measure in Navigation system*',
  };
  const walkaround = {
    c1: 'Check Smart Key system*',
    c2: 'Check window operation',
    c3: 'Check door and door lock operation, including wireless remote control/theft deterrent system*',
    c4: 'Check that engine starts with all keys',
    c5: 'Check that Child Safety Door Locks are in normal (unlock) position',
    c6: 'Check seats and seat belt operation',
    c7: 'Check rear defogger and rear view mirror defogger',
    c8: 'Check side marker, tail, backup and license plate lights',
    c9: 'Check luggage compartment light and trim appearance',
    c10: 'Check spare tire pressure and jack and tool installation',
    c11: 'Check headlight aim',
    c12: 'Install roadside assistance decal',
    c13: 'Install French “Objects in mirror are closer than they appear” decal*',
  };
  const underhood = {
    d1: 'Check engine oil and ATM fluid level',
    d2: 'Check brake and power steering fluid levels',
    d3: 'Check engine coolant level and strength',
    d4: 'Check battery state-of-charge using a DCA-8000 tester',
    d5: 'Inspect for fuel, oil, coolant or other fluid leaks',
    d6: 'Fill windshield washer fluid',
  };
  const vehicle = {
    e1: 'Remove disc brake anti-rust covers/anti-corrosion wheel film (Remove protective film just before delivery to customer)',
    e2: 'Inspect tires for defects/damage and adjust tire pressure (including spare tire)',
    e3: 'Initialize Tire Pressure Warning System (TPMS)',
    e4: 'Install wheel covers/caps/spare tire wheel covers*',
    e5: 'Inspect for fuel, oil, coolant or other fluid leaks',
    e6: 'Inspect under the vehicle for damage, rust, etc.',
    e7: 'Visually check bolts and nuts on chassis and powertrain for looseness',
    e8: 'Install rubber body plugs',
    e9: 'Install mudguards* (Refer to Customization section on TIS)',
  };
  const roadtest = {
    f1: 'Check cold engine operation',
    f2: 'Check engine operation during warm-up',
    f3: 'Check engine at normal operating temperature',
    f4: 'Check automatic transmission operation',
    f5: 'Check brake and parking brake operation',
    f6: 'Check steering operation and off-center/vehicle pull/flutter',
    f7: 'Inspect for abnormal noises and vibrations',
    f8: 'Inspect for squeaks and rattles',
    f9: 'Check front and rear* heating and A/C operation',
    f10: 'Check cruise control operation',
    f11: 'Check Blind Spot Monitor System',
    f12: 'Check front seat heater/ventilation operation*',
    f13: 'Set/calibrate compass*',
    f14: 'Check Rear Height Control Air Suspension*',
  };
  const preparation = {
    g1: 'Visually inspect all interior parts for installation, damage, fit, dirt, etc',
    g2: 'Remove Rapgard TM protective film',
    g3: 'Verify floor mat application and install using retaining clips*',
    g4: 'Wash and clean vehicle',
    g5: 'Inspect paint finish for scratches, chips, rust, dents, damage, etc',
    g6: 'Inspect exterior body parts for proper installation, damage, rust, etc',
    g7: 'Confirm tires are adjusted to specified storage tire pressure',
    g8: 'Place maintenance sticker on inside of windshield, top left corner',
    g9: 'Place owner’s guide and first aid kit portfolio* in glove box',
    g10: 'Install front license plate and mounting bracket',
    g11: 'Perform Techstream health check',
  };

  let bulletinsObj = cloneDeep(bulletins);
  let beforeObj = cloneDeep(before);
  let functionalObj = cloneDeep(functional);
  let walkaroundObj = cloneDeep(walkaround);
  let underhoodObj = cloneDeep(underhood);
  let vehicleObj = cloneDeep(vehicle);
  let roadtestObj = cloneDeep(roadtest);
  let preparationObj = cloneDeep(preparation);

  Object.entries(bulletinsObj).map(([name]) => (bulletinsObj[name] = 'Yes'));
  Object.entries(beforeObj).map(([name]) => (beforeObj[name] = 'OK'));
  Object.entries(functionalObj).map(([name]) => (functionalObj[name] = 'OK'));
  Object.entries(walkaroundObj).map(([name]) => (walkaroundObj[name] = 'OK'));
  Object.entries(underhoodObj).map(([name]) => (underhoodObj[name] = 'OK'));
  Object.entries(vehicleObj).map(([name]) => (vehicleObj[name] = 'OK'));
  Object.entries(roadtestObj).map(([name]) => (roadtestObj[name] = 'OK'));
  Object.entries(preparationObj).map(([name]) => (preparationObj[name] = 'OK'));

  const [bulletinsValues, setBulletinsValues] = useState(bulletinsObj);
  const [beforeValues, setBeforeValues] = useState(beforeObj);
  const [functionalValues, setFunctionalValues] = useState(functionalObj);
  const [walkaroundValues, setWalkaroundValues] = useState(walkaroundObj);
  const [underhoodValues, setUnderhoodValues] = useState(underhoodObj);
  const [vehicleValues, setVehicleValues] = useState(vehicleObj);
  const [roadtestValues, setRoadtestValues] = useState(roadtestObj);
  const [preparationValues, setPreparationValues] = useState(preparationObj);

  const handleBulletins = e => {
    const { name, value } = e.target;
    setBulletinsValues(prevBulletinsValues => ({
      ...prevBulletinsValues,
      [name]: value,
    }));
  };
  const handleBefore = e => {
    const { name, value } = e.target;
    setBeforeValues(prevBeforeValues => ({
      ...prevBeforeValues,
      [name]: value,
    }));
  };
  const handleFunctional = e => {
    const { name, value } = e.target;
    setFunctionalValues(prevFunctionalValues => ({
      ...prevFunctionalValues,
      [name]: value,
    }));
  };
  const handleWalkaround = e => {
    const { name, value } = e.target;
    setWalkaroundValues(prevWalkaroundValues => ({
      ...prevWalkaroundValues,
      [name]: value,
    }));
  };
  const handleUnderhood = e => {
    const { name, value } = e.target;
    setUnderhoodValues(prevUnderhoodValues => ({
      ...prevUnderhoodValues,
      [name]: value,
    }));
  };
  const handleVehicle = e => {
    const { name, value } = e.target;
    setVehicleValues(prevVehicleValues => ({
      ...prevVehicleValues,
      [name]: value,
    }));
  };
  const handleRoadtest = e => {
    const { name, value } = e.target;
    setRoadtestValues(prevRoadtestValues => ({
      ...prevRoadtestValues,
      [name]: value,
    }));
  };
  const handlePreparation = e => {
    const { name, value } = e.target;
    setPreparationValues(prevPreparationValues => ({
      ...prevPreparationValues,
      [name]: value,
    }));
  };

  const [consent, setConsent] = useState(false);
  const handleConsent = () => {
    setConsent(consent => !consent);
  };

  const [confirm, setConfirm] = useState(false);
  const handleConfirm = () => {
    setConfirm(confirm => !confirm);
  };

  const wholeForm = useRef(null);

  const handleSubmit = e => {
    e.preventDefault();

    if (!consent) {
      enqueueSnackbar(`Please check 'I agree' and then submit the inspection!`);
      return;
    }

    underhoodValues['d4_battery'] = wholeForm.current['d4_battery'].value;
    roadtestValues['odometer_before'] =
      wholeForm.current['odometer_before'].value;
    roadtestValues['odometer_after'] =
      wholeForm.current['odometer_after'].value;

    const values = {
      ...bulletinsValues,
      ...beforeValues,
      ...functionalValues,
      ...walkaroundValues,
      ...underhoodValues,
      ...vehicleValues,
      ...roadtestValues,
      ...preparationValues,
    };
    const form_data = {
      toyota: values,
      amvic_number: wholeForm.current['amvic_number'].value,
    };

    props.formData(form_data);
    props.formClose();
  };

  const GreenButton = withStyles({
    root: {
      color: '#fff',
      backgroundColor: '#198754',
      '&:hover': {
        backgroundColor: '#157347',
      },
    },
  })(Button);

  return (
    <form onSubmit={handleSubmit} ref={wholeForm}>
      <CustomAccordion
        title="APPLICABLE PDS TECHNICAL SERVICE BULLETINS"
        list={bulletins}
        values={bulletinsValues}
        handles={handleBulletins}
        greenValue="Yes"
        redValue="No"
      />
      <CustomAccordion
        title="A. BEFORE INSPECTION"
        list={before}
        values={beforeValues}
        handles={handleBefore}
        greenValue="OK"
        redValue="Repair"
      />
      <CustomAccordion
        title="B. FUNCTIONAL OPERATION"
        list={functional}
        values={functionalValues}
        handles={handleFunctional}
        greenValue="OK"
        redValue="Repair"
      />
      <CustomAccordion
        title="C. WALK-AROUND INSPECTION"
        list={walkaround}
        values={walkaroundValues}
        handles={handleWalkaround}
        greenValue="OK"
        redValue="Repair"
      />
      <CustomAccordion
        title="D. UNDER HOOD"
        list={underhood}
        values={underhoodValues}
        handles={handleUnderhood}
        greenValue="OK"
        redValue="Repair"
      />
      <CustomAccordion
        title="E. UNDER VEHICLE (ON HOIST)"
        list={vehicle}
        values={vehicleValues}
        handles={handleVehicle}
        greenValue="OK"
        redValue="Repair"
      />
      <CustomAccordion
        title="F. ROAD TEST"
        list={roadtest}
        values={roadtestValues}
        handles={handleRoadtest}
        greenValue="OK"
        redValue="Repair"
      />
      <CustomAccordion
        title="G. PREPARATION, INSPECTION & STORAGE"
        list={preparation}
        values={preparationValues}
        handles={handlePreparation}
        greenValue="OK"
        redValue="Repair"
      />
      <Typography style={{ marginTop: '15px' }}>
        This is to certify that the above inspections have been performed and
        all necessary repairs completed. (Attach copy of work order.)
      </Typography>
      <FormControlLabel
        control={<Checkbox />}
        label="I agree"
        onChange={handleConsent}
        value={consent}
      />
      <br />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Technician Name"
            value={props.user.display_name}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Tech #"
            value={props.user.goUserProfile.profile.sales_licence}
            fullWidth
            name={'amvic_number'}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Date"
            value={new Date().toLocaleString()}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <Button
            type="button"
            variant="contained"
            style={{ marginRight: '15px' }}
            onClick={handleConfirm}
            color="default"
          >
            Cancel
          </Button>
          <Dialog
            open={confirm}
            onClose={handleConfirm}
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Current inspection will be closed and unsaved data will be gone.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleConfirm}>Cancel</Button>
              <Button onClick={props.formClose} color="secondary">
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
          <GreenButton type="submit" variant="contained">
            Save
          </GreenButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default ToyotaPDIForm;
