import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

export const useFeatureFlags = () => {
  const { data } = useQuery(gql`
    query feature_flags {
      currentUser {
        feature_flags
      }
    }
  `);
  return data?.currentUser?.feature_flags ?? [];
};
