/* External */
import gql from 'graphql-tag';
import moment from 'moment-timezone';
import React from 'react';
import { useHistory } from 'react-router-dom';

/* Material UI */
import { Box, Card, CardActionArea, Grid, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { green, red, yellow } from '@mui/material/colors';

/* MUI Icons */
import {
  Announcement,
  ChatBubble,
  ChevronRight,
  ContactSupport,
  QuestionAnswer,
  Star,
} from '@mui/icons-material';

/* Internal */
import { useUserContext } from 'components/MaterialUI/UserContext';
import { MESSAGE_DIRECTIONS } from 'constants.js';

const useStyles = makeStyles(theme => ({
  gridHeader: {
    fontWeight: 'bold',
    [theme.breakpoints.up('md')]: {
      fontSize: '1rem',
    },
  },
  gridContainer: {
    [theme.breakpoints.up('md')]: {
      margin: '5px 0',
    },
    [theme.breakpoints.down('lg')]: {
      margin: '5px',
    },
  },
  cardStyles: {
    display: 'flex',
    maxWidth: '1250px',
    [theme.breakpoints.up('sm')]: {
      borderRadius: 0,
      padding: '10px',
      borderBottom: '1px solid #d7d7d7',
      justifyContent: 'space-between',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '10px',
      justifyContent: 'space-between',
    },
  },
  cardActionArea: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  subTitle: {
    fontWeight: 400,
    color: 'grey',
    marginRight: '5px',
    fontSize: '0.75rem',
  },
  title: {
    fontWeight: 500,
    fontSize: '0.75rem',
  },
  date: {
    fontWeight: 'bold',
    fontSize: '0.75rem',
    marginTop: '5px',
  },
  infoText: {
    display: 'flex',
    alignItems: 'center',
  },
  textStyles: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.75rem',
    },
  },
  customerGrid: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center',
    },
  },
  chevronIconContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    [theme.breakpoints.down('md')]: {
      borderRadius: '5px',
      minWidth: '15px',
      maxWidth: '45px',
      backgroundColor: ({ statusColour }) => statusColour,
    },
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0,
    },
  },
  chevronIcon: {
    color: 'white',
    [theme.breakpoints.up('md')]: {
      backgroundColor: ({ statusColour }) => statusColour,
      padding: '2px',
      borderRadius: '100%',
    },
  },
}));

const ConversationListItem = ({
  conversation: {
    id,
    customer_name,
    customer_phone,
    customer,
    has_unread_messages,
    last_inbound_message,
    last_message_date,
    last_message_direction,
    last_outbound_message,
    operator_user,
    stars,
  },
  setSelectedChatId,
  handleMultiSelect,
  bgColor,
  gatewayId,
  selectMultiple,
}) => {
  const history = useHistory();

  const lrgDevice = useMediaQuery(theme => theme.breakpoints.up('md'));
  const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const statusColour = !has_unread_messages
    ? last_message_direction
      ? last_message_direction === MESSAGE_DIRECTIONS.outbound
        ? green[500]
        : red[500]
      : '#d7d7d7'
    : yellow[700];

  const classes = useStyles({ statusColour });

  const { currentUser } = useUserContext();

  const myStarred = stars.filter(x => x.username === currentUser.username);

  const isStarred = myStarred.find(x => x.conversation_id === parseInt(id, 10));

  function getMessagePreview() {
    let msg = '';
    if (last_message_direction === MESSAGE_DIRECTIONS.inbound) {
      msg = last_inbound_message?.body;
    } else if (last_message_direction === MESSAGE_DIRECTIONS.outbound) {
      msg = last_outbound_message?.body;
    }

    return msg || <i>Message preview unavailable</i>;
  }

  return (
    <Card className={classes.cardStyles} style={{ backgroundColor: bgColor }}>
      <CardActionArea
        className={classes.cardActionArea}
        onClick={() =>
          !handleMultiSelect
            ? lrgDevice
              ? setSelectedChatId(parseInt(id, 10))
              : history.push(`/sms/gateways/${gatewayId}/${id}`)
            : selectMultiple(id)
        }
      >
        {!lrgDevice && (
          // Star for smaller screens
          <Box
            display="flex"
            alignItems="center"
            style={{
              padding: '5px',
              minWidth: '25px',
            }}
          >
            {isStarred && (
              <Star
                style={{
                  color: yellow[700],
                }}
                fontSize={mobile ? 'small' : 'medium'}
              />
            )}
          </Box>
        )}
        {/* *** Conversation Info *** */}
        <Grid
          container
          item
          xs={8}
          sm={10}
          spacing={1}
          className={classes.gridContainer}
        >
          {/* *** CUSTOMER *** */}
          <Grid
            item
            xs={6}
            sm={4}
            md={12}
            className={lrgDevice ? classes.customerGrid : classes.textStyles}
            style={{ order: 1 }}
          >
            {!lrgDevice && (
              <Box className={classes.gridHeader}>Customer Name</Box>
            )}
            <Box className={lrgDevice ? classes.gridHeader : ''}>
              {(customer_name ?? customer?.fullname) || 'Unknown Customer'} (
              {customer_phone})
            </Box>
            {/* *** Star -> large devices */}
            <Box>
              {lrgDevice && isStarred && (
                <Star style={{ color: yellow[700], paddingLeft: '5px' }} />
              )}
            </Box>
          </Grid>
          {/* *** OPERATOR *** */}
          <Grid
            item
            xs={6}
            sm={4}
            md={6}
            className={lrgDevice ? classes.infoText : classes.textStyles}
            style={{ order: mobile ? 3 : 2 }}
          >
            <Box
              className={lrgDevice ? classes.subTitle : classes.gridHeader}
            >{`Operator${lrgDevice ? ': ' : ''}`}</Box>
            <Box className={lrgDevice ? classes.title : ''}>
              {operator_user?.display_name || 'Unknown Operator'}
            </Box>
          </Grid>
          {/* *** STATUS *** */}
          <Grid
            item
            xs={6}
            sm={4}
            className={lrgDevice ? classes.infoText : classes.textStyles}
            style={{ order: mobile ? 2 : 3 }}
          >
            <Box
              className={lrgDevice ? classes.subTitle : classes.gridHeader}
            >{`Status${lrgDevice ? ': ' : ''}`}</Box>
            {/* Replied */}
            {last_message_direction === MESSAGE_DIRECTIONS.outbound && (
              <Box display="flex" alignItems="center">
                <QuestionAnswer
                  fontSize="small"
                  style={{ paddingRight: '5px', color: green[500] }}
                />
                <p
                  style={{ margin: 0 }}
                  className={lrgDevice ? classes.title : ''}
                >
                  Replied
                </p>
              </Box>
            )}
            {/* Unread || Unreplied */}
            {last_message_direction === MESSAGE_DIRECTIONS.inbound &&
              (has_unread_messages ? (
                <Box display="flex" alignItems="center">
                  <ChatBubble
                    fontSize="small"
                    style={{ paddingRight: '5px', color: yellow[700] }}
                  />
                  <p
                    style={{ margin: 0 }}
                    className={lrgDevice ? classes.title : ''}
                  >
                    Unread
                  </p>
                </Box>
              ) : (
                <Box display="flex" alignItems="center">
                  <Announcement
                    fontSize="small"
                    style={{ paddingRight: '5px', color: red[500] }}
                  />{' '}
                  <p
                    style={{ margin: 0 }}
                    className={lrgDevice ? classes.title : ''}
                  >
                    Unreplied
                  </p>
                </Box>
              ))}
            {/* Unknown */}
            {!last_message_direction && (
              <Box display="flex" alignItems="center">
                <ContactSupport
                  fontSize="small"
                  style={{ paddingRight: '5px', color: 'grey' }}
                />
                <p
                  style={{ margin: 0 }}
                  className={lrgDevice ? classes.title : ''}
                >
                  Unknown
                </p>
              </Box>
            )}
          </Grid>
          {/* *** DATE & MESSAGE *** */}
          <Grid
            item
            xs={6}
            sm={10}
            md={12}
            className={!lrgDevice ? classes.textStyles : ''}
            style={{ order: 4 }}
          >
            <Box className={lrgDevice ? classes.date : classes.gridHeader}>
              {moment(last_message_date).format(
                `${
                  mobile ? 'MMM DD YYYY, h:mm a' : 'dddd MMM DD YYYY, h:mm:ss a'
                }`,
              )}
            </Box>
            <Box
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {getMessagePreview()}
            </Box>
          </Grid>
        </Grid>
        {/* *** Coloured Chevron Icon *** */}

        <Box className={classes.chevronIconContainer}>
          <ChevronRight
            className={classes.chevronIcon}
            fontSize={mobile || lrgDevice ? 'medium' : 'large'}
          />
        </Box>
      </CardActionArea>
    </Card>
  );
};

ConversationListItem.fragments = {
  conversation: gql`
    fragment ConversationListItem on Conversation {
      id
      last_message_date
      customer_name
      customer {
        _id
        fullname
      }
      operator_user {
        display_name
      }
      last_inbound_message {
        conversation_id
        direction
        delivery_status
        body
      }
      last_outbound_message {
        conversation_id
        direction
        delivery_status
        body
      }
      last_message_direction
      has_unread_messages
      stars {
        conversation_id
        username
      }
      archived
    }
  `,
};

export default ConversationListItem;
