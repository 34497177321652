import { cloneDeep } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useRef, useState } from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardContent,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import InspectionCheckboxes from '../InspectionCheckboxes';

const BMWCPOForm = props => {
  const { enqueueSnackbar } = useSnackbar();

  // BMW CPO form fields
  const general = {
    bmw1: 'Check Warranty review indicators',
    bmw2: 'Check for software/odometer manipulation, as per diagnostic test.',
    bmw3: 'Meets all items on Provincial Safety Standards?',
    bmw4: 'All Recalls & Service campaigns completed?',
    bmw5: 'Door Jamb labels in place as applicable? ',
    bmw6: 'Verify vehicle status including warranty (S-Gate)',
    bmw7: 'Perform vehicle data reset',
  };
  const maintenance = {
    bmw8: 'Is the vehicle maintenance current, as per vehicle maintenance history?',
    bmw9: 'Has the full maintenance history been verified via the AIR application? (If serviced outside of an authorized BMW Retailer, a copy of the maintenance records must be obtained and put in the file).',
    bmw10:
      'Service Manager & Technician certify that this vehicle has been inspected and all nonconformities addressed and that only BMW Group OEM Parts have been or are installed on the vehicle',
  };
  const vehicleInspection = {
    bmw11: 'Vehicle has no structural damage',
    bmw12: 'Inspect all tires for Tread Depth across entire surface (min. 3mm)',
    bmw13:
      'All tires must be matching and meet the vehicles original tire specifications (size, load index, speed rating, run-flat); Refer to the approved list of BMW approved tire Brands',
    bmw14: 'Inspect ABS system and brake rotors, pads (min. pad thickness 5mm)',
    bmw15: 'OEM windshield has no damage.',
    bmw16: 'Inspect all vehicle fluid levels and signs for leaks',
    bmw17: 'Visual inspection of drive belts and tensioners',
    bmw18: 'Visual inspection of cooling system/hydraulic/AC hoses.',
    bmw19: 'Battery is correct size, amp rating with no physical damage',
    bmw20: 'Open circuit battery voltage of 12.5 V min, if not charge and test',
  };
  const generalEquip = {
    bmw21:
      'Central locking system, incl. trunk/tailgate, fuel filler door and remote (as equipped)',
    bmw22:
      'All power options, comfort, navigation, camera, driver assistance and entertainment functions have been inspected and verified to be in full operation',
    bmw23:
      'All warning indicator lights OK (incl. check control (cc) messages check)',
    bmw24: 'Air conditioning and climate control',
    bmw25: 'Hood release mechanism (primary and secondary)',
    bmw26: 'Air bags and seat belts assembly.',
  };
  const extAppearance = {
    bmw27:
      '*Inspection and reconditioning in-line with BMW Group CPO Optical Guidelines. Indicate any notable stone chips, scratches and body damage to be addressed.',
  };
  const additionalCheck = {
    bmw28: 'Inspect electric motor function',
    bmw29:
      'Check High Voltage Battery and housing (on lifting platform) and ensure 100% charge at time of customer delivery',
    bmw30: 'Check charging and plug function are without damage',
    bmw31: 'Check high voltage cables/connections (PHEV only)',
    bmw32: 'Confirm no charging error displayed',
    bmw33: 'Check all xEV specific function keys for iDrive',
  };
  const testDrive = {
    bmw34:
      'TEST DRIVE TO ENSURE OVERALL ENGINE PERFORMANCE, HANDLING, TRANSMISSION SHIFTS, SAFETY & DRIVEABILITY',
  };

  let generalObj = cloneDeep(general);
  let maintenanceObj = cloneDeep(maintenance);
  let vehicleInspectionObj = cloneDeep(vehicleInspection);
  let generalEquipObj = cloneDeep(generalEquip);
  let extAppearanceObj = cloneDeep(extAppearance);
  let additionalCheckObj = cloneDeep(additionalCheck);
  let testDriveObj = cloneDeep(testDrive);

  Object.entries(generalObj).map(([name]) => (generalObj[name] = ''));
  Object.entries(maintenanceObj).map(([name]) => (maintenanceObj[name] = ''));
  Object.entries(vehicleInspectionObj).map(
    ([name]) => (vehicleInspectionObj[name] = ''),
  );
  Object.entries(generalEquipObj).map(([name]) => (generalEquipObj[name] = ''));
  Object.entries(extAppearanceObj).map(
    ([name]) => (extAppearanceObj[name] = ''),
  );
  Object.entries(additionalCheckObj).map(
    ([name]) => (additionalCheckObj[name] = ''),
  );
  Object.entries(testDriveObj).map(([name]) => (testDriveObj[name] = ''));

  const [generalValues, setGeneralValues] = useState(generalObj);
  const [maintenanceValues, setMaintenanceValues] = useState(maintenanceObj);
  const [vehicleInspectionValues, setVehicleInspectionValues] =
    useState(vehicleInspectionObj);
  const [generalEquipValues, setGeneralEquipValues] = useState(generalEquipObj);
  const [extAppearanceValues, setExtAppearanceValues] =
    useState(extAppearanceObj);
  const [additionalCheckValues, setAdditionalCheckValues] =
    useState(additionalCheckObj);
  const [testDriveValue, setTestDriveValue] = useState(testDriveObj);

  const handleGeneral = e => {
    const { name, value, checked } = e.target;
    setGeneralValues(prevGeneralValues => ({
      ...prevGeneralValues,
      [name]: checked ? value : '',
    }));
  };

  const handleMaintenance = e => {
    const { name, value, checked } = e.target;
    setMaintenanceValues(prevMaintenanceValues => ({
      ...prevMaintenanceValues,
      [name]: checked ? value : '',
    }));
  };

  const handleVehicleInspection = e => {
    const { name, value, checked } = e.target;
    setVehicleInspectionValues(prevVehicleInspectionValues => ({
      ...prevVehicleInspectionValues,
      [name]: checked ? value : '',
    }));
  };

  const handleGeneralEquip = e => {
    const { name, value, checked } = e.target;
    setGeneralEquipValues(prevGeneralEquipValues => ({
      ...prevGeneralEquipValues,
      [name]: checked ? value : '',
    }));
  };

  const handleExtAppearance = e => {
    const { name, value, checked } = e.target;
    setExtAppearanceValues(prevExtAppearanceValues => ({
      ...prevExtAppearanceValues,
      [name]: checked ? value : '',
    }));
  };

  const handleAdditionalCheck = e => {
    const { name, value, checked } = e.target;
    setAdditionalCheckValues(prevAdditionalCheckValues => ({
      ...prevAdditionalCheckValues,
      [name]: checked ? value : '',
    }));
  };

  const handleTestDrive = e => {
    const { name, value, checked } = e.target;
    setTestDriveValue(prevTestDriveValue => ({
      ...prevTestDriveValue,
      [name]: checked ? value : '',
    }));
  };

  const [consent, setConsent] = useState(false);
  const handleConsent = () => {
    setConsent(consent => !consent);
  };

  const [confirm, setConfirm] = useState(false);
  const handleConfirm = () => {
    setConfirm(confirm => !confirm);
  };

  const wholeForm = useRef(null);

  const handleSubmit = e => {
    e.preventDefault();

    if (!consent) {
      enqueueSnackbar(`Please check 'I agree' and then submit the inspection!`);
      return;
    }

    const values = {
      ...generalValues,
      ...maintenanceValues,
      ...vehicleInspectionValues,
      ...generalEquipValues,
      ...extAppearanceValues,
      ...additionalCheckValues,
      ...testDriveValue,
    };

    const form_data = {
      bmw: values,
      amvic_number: wholeForm.current['amvic_number'].value,
    };
    props.formData(form_data);
    props.formClose();
  };

  const GreenButton = withStyles({
    root: {
      color: '#fff',
      backgroundColor: '#198754',
      '&:hover': {
        backgroundColor: '#157347',
      },
    },
  })(Button);

  return (
    <form onSubmit={handleSubmit} ref={wholeForm}>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>GENERAL INFORMATION</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center" spacing={1}>
            <Typography style={{ marginBottom: '15px' }}>
              Before the itemized check, the displayed ISPA vehicle data,
              Warranty data, ISTA data and in-car displayed data should be
              checked for integrity.
            </Typography>
            {Object.entries(general).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid
                    item
                    xs={6}
                    style={{ paddingLeft: '1rem', paddingBottom: '.5rem' }}
                  >
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={generalValues}
                      handleGroup={handleGeneral}
                      name={name}
                      orange={false}
                      red={false}
                      greenValue="Yes"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      {/* Maintenance Section */}
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>MAINTENANCE</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center" spacing={1}>
            {Object.entries(maintenance).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid
                    item
                    xs={6}
                    style={{ paddingLeft: '1rem', paddingBottom: '.5rem' }}
                  >
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={maintenanceValues}
                      handleGroup={handleMaintenance}
                      name={name}
                      orange={false}
                      red={false}
                      greenValue="Yes"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      {/* Vehicle Inspection Section */}
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>VEHICLE INSPECTION</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center" spacing={1}>
            {Object.entries(vehicleInspection).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid
                    item
                    xs={6}
                    style={{ paddingLeft: '1rem', paddingBottom: '.5rem' }}
                  >
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={vehicleInspectionValues}
                      handleGroup={handleVehicleInspection}
                      name={name}
                      orange={false}
                      red={false}
                      greenValue="Yes"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      {/* General Equipment Section */}
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>GENERAL EQUIPMENT</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center" spacing={1}>
            {Object.entries(generalEquip).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid
                    item
                    xs={6}
                    style={{ paddingLeft: '1rem', paddingBottom: '.5rem' }}
                  >
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={generalEquipValues}
                      handleGroup={handleGeneralEquip}
                      name={name}
                      orange={false}
                      red={false}
                      greenValue="Yes"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>

      {/* Ext. Appearance & Condition Section */}
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>EXTERIOR APPEARANCE & CONDITION</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center" spacing={1}>
            {Object.entries(extAppearance).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid
                    item
                    xs={6}
                    style={{ paddingLeft: '1rem', paddingBottom: '.5rem' }}
                  >
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={extAppearanceValues}
                      handleGroup={handleExtAppearance}
                      name={name}
                      orange={false}
                      red={false}
                      greenValue="Yes"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>

      {/* Additional Check Section */}
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>ADDITIONAL CHECK FOR xEV VEHICLE</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center" spacing={1}>
            {Object.entries(additionalCheck).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid
                    item
                    xs={6}
                    style={{ paddingLeft: '1rem', paddingBottom: '.5rem' }}
                  >
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={additionalCheckValues}
                      handleGroup={handleAdditionalCheck}
                      name={name}
                      orange={false}
                      red={false}
                      greenValue="Yes"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Card>
        <CardContent>
          <Grid container spacing={1}>
            {Object.entries(testDrive).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={testDriveValue}
                      handleGroup={handleTestDrive}
                      name={name}
                      orange={false}
                      red={false}
                      greenValue="Yes"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </CardContent>
      </Card>
      <Grid
        container
        spacing={1}
        alignItems="center"
        style={{ margin: '10px' }}
      >
        <Grid item xs={12} sm={9}>
          <Typography style={{ fontWeight: 'bold' }}>
            I verify the contents of this document are true and correct
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormControlLabel
            control={<Checkbox />}
            label="I agree"
            onChange={handleConsent}
            value={consent}
          />
          <br />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Technician Name"
            value={props.user.display_name}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Tech #"
            value={props.user.goUserProfile.profile.sales_licence}
            fullWidth
            name={'amvic_number'}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Date"
            value={new Date().toLocaleString()}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <Button
            type="button"
            variant="contained"
            style={{ marginRight: '15px' }}
            onClick={handleConfirm}
            color="default"
          >
            Cancel
          </Button>
          <Dialog
            open={confirm}
            onClose={handleConfirm}
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Current inspection will be closed and unsaved data will be gone.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleConfirm}>Cancel</Button>
              <Button onClick={props.formClose} color="secondary">
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
          <GreenButton type="submit" variant="contained">
            Save
          </GreenButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default BMWCPOForm;
