import React from 'react';

import gql from 'graphql-tag';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';

import { useMutation } from '@apollo/react-hooks';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';

const UPDATE_TASK_QUERY = gql`
  mutation UpdateTask($id: ID!, $input: UpdateTaskInput!) {
    updateTask(id: $id, input: $input) {
      _id
      due_date
      status
    }
  }
`;

const TaskReschedule = ({ open, setOpen, task, action }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [rescheduleTask] = useMutation(UPDATE_TASK_QUERY, {
    onCompleted: data => {
      action(data.updateTask);
      enqueueSnackbar('Task updated', { variant: 'success' });
    },
    onError: err => {
      enqueueSnackbar(`Could not update task error: ${err}`, {
        variant: 'error',
      });
    },
  });

  const handleClose = () => setOpen(false);
  const { control, handleSubmit } = useForm();

  const onSubmit = value => {
    rescheduleTask({
      variables: {
        id: task._id,
        input: {
          due_date: value.due_date,
        },
      },
    });
    setOpen(false);
  };

  const theme = useTheme();
  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Reschedule task</DialogTitle>
        <form
          onSubmit={handleSubmit(onSubmit)}
          onClick={event => event.stopPropagation()}
          onFocus={event => event.stopPropagation()}
        >
          <DialogContent>
            <Controller
              control={control}
              name={'due_date'}
              defaultValue={null}
              render={({ field: { ref, onChange, ...field } }) => (
                <DateTimePicker
                  autoOk
                  variant="inline"
                  format="YYYY-MM-DD hh:mm A"
                  {...field}
                  onChange={onChange}
                />
              )}
              style={{ width: '250px' }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" variant="outlined">
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              style={theme.actions.confirm}
            >
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default TaskReschedule;
