import { startCase } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

/* Material UI */
import {
  CircularProgress,
  Dialog,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';

import ConfirmDialog from 'components/MaterialUI/ConfirmDialog';

import { stringify, titleCase } from 'utils';

/* internal */
import OpportunitiesBulkReassign from './OpportunitiesBulkReassign';

const csvFields = [
  'status_display',
  'sub_status',
  'customer_name',
  'phone',
  'vehicle',
  'vehicle_vin',
  'lead_channel',
  'lead_source',
  'sales_person_one',
  'sales_person_two',
  'bdc_rep_one',
  'bdc_rep_two',
  'sales_manager',
  'next_appointment',
  'created',
];

const csvCustom = {
  status_display: {
    name: 'Status',
  },
  sub_status: {
    name: 'Sub Status',
  },
  customer_name: {
    name: 'Customer',
    render: ({ customer }) => customer?.fullname ?? '',
  },
  phone: {
    name: 'Phone',
    render: ({ customer }) =>
      customer?.identities?.find(identity => identity?.identity_type === 'sms')
        ?.identity_value ?? '',
  },
  vehicle: {
    name: 'Vehicle',
    render: ({ dms_deal }) =>
      [dms_deal?.year, dms_deal?.make_name, dms_deal?.model_name]
        .filter(x => x)
        .join(' '),
  },
  vehicle_vin: {
    name: 'Vehicle Vin',
    render: ({ dms_deal }) => dms_deal?.vin,
  },
  lead_channel: {
    name: 'Lead Channel',
    render: ({ marketing }) =>
      `${startCase(marketing?.lead_direction ?? '')} ${startCase(
        marketing?.lead_channel ?? 'N/A',
      )}`,
  },
  lead_source: {
    name: 'Lead Source',
    render: ({ marketing }) => marketing?.lead_source ?? 'N/A',
  },
  sales_person_one: {
    name: 'Sales Person One',
    render: ({ sales_reps }) =>
      sales_reps?.length > 0 ? sales_reps[0]?.display_name : '',
  },
  sales_person_two: {
    name: 'Sales Person Two',
    render: ({ sales_reps }) =>
      sales_reps?.length > 1 ? sales_reps[1]?.display_name : '',
  },
  bdc_rep_one: {
    name: 'BDC Rep One',
    render: ({ bdc_reps }) =>
      bdc_reps?.length > 0 ? bdc_reps[0]?.display_name : '',
  },
  bdc_rep_two: {
    name: 'BDC Rep Two',
    render: ({ bdc_reps }) =>
      bdc_reps?.length > 1 ? bdc_reps[1]?.display_name : '',
  },
  sales_manager: {
    name: 'Sales Manager',
    render: ({ sales_managers }) =>
      sales_managers?.length > 0 ? sales_managers[0]?.display_name : '',
  },
  next_appointment: {
    name: 'Next Appointment',
    render: ({ appointments }) =>
      appointments?.length > 0
        ? moment(appointments[0].start_date).format('MMM D, YYYY @ h:mm')
        : '',
  },
  created: {
    name: 'Created',
    render: ({ created }) => moment(created).format('MMM D, YYYY'),
  },
};

const csvHeader = csvFields.map(
  field => csvCustom[field]?.name || titleCase(field),
);

const BulkActions = ({
  anchorEl,
  selected,
  handleAnchorClose,
  opps,
  loadMore,
  hasMorePages,
  totalOpps,
}) => {
  const [openBulkReassign, setOpenBulkReassign] = useState(false);
  const [csvClicked, setCsvClicked] = useState(false);
  const [creatingCsv, setCreatingCsv] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  useEffect(() => {
    const createCSV = async () => {
      const rows = [[...csvHeader]].concat(
        opps
          .filter(
            opportunity =>
              selected.length === 0 || selected.indexOf(opportunity._id) !== -1,
          )
          .map(opportunity =>
            csvFields.map(
              field =>
                csvCustom[field]?.render?.(opportunity) || opportunity[field],
            ),
          ),
      );

      const csvContent = `data:text/csv;base64,${btoa(
        decodeURIComponent(encodeURIComponent(stringify(rows))),
      )}`;
      const csvFileName = `opportunities_export_${moment().format(
        'YYYYMMDD_HHmm',
      )}.csv`;

      const element = document.createElement('a');
      element.href = csvContent;
      element.download = csvFileName;
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);

      setCreatingCsv(false);
      handleAnchorClose();
    };
    if (creatingCsv) createCSV();
  }, [creatingCsv, handleAnchorClose, opps]);

  useEffect(() => {
    if (csvClicked) {
      if (selected.length === 0 && hasMorePages) {
        loadMore();
      } else {
        setCsvClicked(false);
        setCreatingCsv(true);
      }
    }
  }, [csvClicked, hasMorePages, loadMore]);

  return (
    <>
      <ConfirmDialog
        isOpen={openConfirmDialog}
        onConfirm={() => setCsvClicked(true)}
        onClose={() => setOpenConfirmDialog(false)}
        text={`This will download ${
          selected.length || totalOpps
        } opportunities. Are you sure you want to download them?`}
      />
      <Dialog
        maxWidth="sm"
        fullWidth
        scroll="paper"
        open={openBulkReassign}
        onClose={() => {
          setOpenBulkReassign(false);
        }}
      >
        <OpportunitiesBulkReassign
          selected={selected}
          opps={opps}
          handleClose={() => {
            setOpenBulkReassign(false);
          }}
        />
      </Dialog>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => handleAnchorClose()}
        style={{ width: '200px' }}
      >
        <MenuItem
          disabled={selected.length === 0}
          onClick={() => setOpenBulkReassign(true)}
        >
          Bulk Reassign
        </MenuItem>
        {creatingCsv || csvClicked ? (
          <MenuItem disabled>
            <ListItemIcon style={{ minWidth: '25px' }}>
              <CircularProgress size={20} />
            </ListItemIcon>
            <ListItemText primary="Generating CSV..." />
          </MenuItem>
        ) : (
          <MenuItem
            disabled={creatingCsv}
            onClick={() => setOpenConfirmDialog(true)}
          >
            Export CSV
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default BulkActions;
