// External
import FullCalendar from '@fullcalendar/react';

import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import { NetworkStatus } from 'apollo-boost';
import gql from 'graphql-tag';
import _ from 'lodash';
import moment from 'moment-timezone';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { Waypoint } from 'react-waypoint';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Drawer from '@mui/material/Drawer';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import CloseIcon from '@mui/icons-material/Close';
import FilterListIcon from '@mui/icons-material/FilterList';
import PrintIcon from '@mui/icons-material/Print';
import SearchIcon from '@mui/icons-material/Search';
// Material UI
import Alert from '@mui/material/Alert';

// Internal
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import DealerPicker from 'components/MaterialUI/DealerPicker';
import Loading from 'components/MaterialUI/Loading';
import { useUserContext } from 'components/MaterialUI/UserContext';

import { usePersistedState } from '../../../utils/utils';
import AppointmentActions from './AppointmentActions';
import AppointmentStatusComponent from './AppointmentStatusComponent';
import FacetChips from './FacetChips';
import FilterDrawer from './FilterDrawer';
import { AppointmentStatus, RoleGroup } from 'constants.js';
import { defaultTZ } from 'utils';

const APPOINTMENTS_QUERY = gql`
  query AppointmentsQuery(
    $dealer_id: Int!
    $page_size: Int!
    $page: Int!
    $sort_by: [SortAppointmentBy]
    $attendees: [String]
    $is_confirmed: Boolean
    $statuses: [String]
    $type_ids: [Int]
    $start_date_from: Date
    $start_date_until: Date
    $created_from: Date
    $created_until: Date
    $search: String
  ) {
    appointments(
      dealer_ids: [$dealer_id]
      page_size: $page_size
      page: $page
      sort_by: $sort_by
      attendees: $attendees
      is_confirmed: $is_confirmed
      statuses: $statuses
      type_ids: $type_ids
      start_date_from: $start_date_from
      start_date_until: $start_date_until
      created_from: $created_from
      created_until: $created_until
      search: $search
    ) {
      appointments {
        _id
        type_name
        customer {
          _id
          fullname
          primary_phone
          primary_email
        }
        attendees {
          display_name
        }
        start_date
        status
        is_confirmed
        subject
      }
      pagination {
        page
        pages
        total
      }
    }
  }
`;

const SINGLE_APPOINTMENT_QUERY = gql`
  query AppointmentQuery($_id: ID!) {
    appointment(_id: $_id) {
      _id
      type_name
      customer {
        _id
        fullname
        primary_phone
        primary_email
      }
      attendees {
        display_name
      }
      start_date
      status
      is_confirmed
      subject
    }
  }
`;

const APPOINTMENT_COUNT_ATTENDEES = gql`
  query AppointmentCountAttendeesQuery(
    $dealer_id: Int!
    $attendees: [String]
    $is_confirmed: Boolean
    $statuses: [String]
    $type_ids: [Int]
    $start_date_from: Date
    $start_date_until: Date
    $created_from: Date
    $created_until: Date
  ) {
    appointmentsCountAttendees(
      dealer_ids: [$dealer_id]
      attendees: $attendees
      is_confirmed: $is_confirmed
      statuses: $statuses
      type_ids: $type_ids
      start_date_from: $start_date_from
      start_date_until: $start_date_until
      created_from: $created_from
      created_until: $created_until
    ) {
      key
      count
    }
  }
`;

const APPOINTMENT_FACETS = gql`
  query AppointmentFacetsQuery(
    $dealer_id: Int!
    $attendees: [String]
    $is_confirmed: Boolean
    $statuses: [String]
    $type_ids: [Int]
    $start_date_from: Date
    $start_date_until: Date
    $created_from: Date
    $created_until: Date
  ) {
    appointmentFacets(
      dealer_ids: [$dealer_id]
      attendees: $attendees
      is_confirmed: $is_confirmed
      statuses: $statuses
      type_ids: $type_ids
      start_date_from: $start_date_from
      start_date_until: $start_date_until
      created_from: $created_from
      created_until: $created_until
    ) {
      type_id {
        key
        count
      }
      is_confirmed {
        key
        count
      }
      status {
        key
        count
      }
    }
  }
`;

const USER_DISPLAY_NAMES = gql`
  query UsersQuery($dealer_id: Int!) {
    users(dealer_ids: [$dealer_id]) {
      display_name
      username
    }
  }
`;

const headCells = [
  {
    id: 'actions',
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: 'Actions',
  },
  {
    id: 'subject',
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: 'Type / Details',
  },
  {
    id: 'customer',
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: 'Customer',
  },
  {
    id: 'attendee',
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: 'Assignee',
  },
  {
    id: 'start_date',
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: 'Scheduled For',
  },
  {
    id: 'is_confirmed',
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: 'Confirmation',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: 'Status',
  },
];

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  action: {
    '& > *': {
      margin: theme.spacing(0.5),
    },
    '& button': {
      padding: '6px 4px',
      minWidth: 'unset',
      backgroundColor: '#2196f3',
    },
  },
  customer: {
    '& a': {
      color: '#0093a7',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
}));

const AppointmentTable = ({ type }) => {
  const classes = useStyles();
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [sort, setSort] = useState([{ key: 'start_date', direction: -1 }]);
  const [order, setOrder] = useState(-1);
  const [orderBy, setOrderBy] = useState('start_date');
  const handleRequestSort = (event, property) => {
    setSort([
      {
        key: property,
        direction: order === 1 ? -1 : 1,
      },
    ]);
    setOrder(order === 1 ? -1 : 1);
    setOrderBy(property);
  };

  const createSortHandler = property => event => {
    handleRequestSort(event, property);
  };

  const [searchKeywords, setSearchKeywords] = useState('');
  const [searchFilters, setSearchFilters] = useState('');
  const [listView, setListView] = useState(true);
  const [filterFacets, setFilterFacets] = usePersistedState(
    'AppointmentFilterFacets',
    [],
  );
  const [startDateFromFilter, setStartDateFromFilter] = usePersistedState(
    'AppointmentStartDateFrom',
    null,
  );
  const [startDateUntilFilter, setStartDateUntilFilter] = usePersistedState(
    'AppointmentStartDateUntil',
    null,
  );
  const [createdFromFilter, setCreatedFromFilter] = usePersistedState(
    'AppointmentCreateDateFrom',
    null,
  );
  const [createdUntilFilter, setCreatedUntilFilter] = usePersistedState(
    'AppointmentCreateDateUntil',
    null,
  );
  const { dealerId } = useDealerContext();
  const { currentUser } = useUserContext();
  const timezone = currentUser?.goUserProfile?.settings?.timezone || defaultTZ;

  const isDesktop = useMediaQuery(theme => theme.breakpoints.up('sm'));

  const role = currentUser?.role || 'none';

  const setSearchFiltersFromKeywords = () => setSearchFilters(searchKeywords);

  useEffect(() => {
    if (searchKeywords) setSearchFiltersFromKeywords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = e =>
    e.key === 'Enter' ? setSearchFiltersFromKeywords() : null;

  const handleCalendarDates = arg => {
    setStartDateFromFilter(moment(arg.startStr));
    setStartDateUntilFilter(moment(arg.endStr));
  };

  const dateFilters = [];
  const otherFacets = [];

  if (startDateFromFilter) {
    dateFilters.push({
      model: 'Appointment',
      field: 'start_date',
      op: '>=',
      value: moment(startDateFromFilter)
        .startOf('day')
        .tz(timezone)
        .toISOString(),
    });
    otherFacets.push({
      label: `> ${moment(startDateFromFilter).format('YYYY-MM-DD')}`,
      onDelete: () => setStartDateFromFilter(null),
    });
  }

  if (startDateUntilFilter) {
    dateFilters.push({
      model: 'Appointment',
      field: 'start_date',
      op: '<=',
      value: moment(startDateUntilFilter)
        .startOf('day')
        .tz(timezone)
        .toISOString(),
    });
    otherFacets.push({
      label: `< ${moment(startDateUntilFilter).format('YYYY-MM-DD')}`,
      onDelete: () => setStartDateUntilFilter(null),
    });
  }

  if (createdFromFilter) {
    dateFilters.push({
      model: 'Appointment',
      field: 'created',
      op: '>=',
      value: moment(createdFromFilter)
        .startOf('day')
        .tz(timezone)
        .toISOString(),
    });
    otherFacets.push({
      label: `> ${moment(createdFromFilter).format('YYYY-MM-DD')}`,
      onDelete: () => setCreatedFromFilter(null),
    });
  }

  if (createdUntilFilter) {
    dateFilters.push({
      model: 'Appointment',
      field: 'created',
      op: '<=',
      value: moment(createdUntilFilter)
        .startOf('day')
        .tz(timezone)
        .toISOString(),
    });
    otherFacets.push({
      label: `< ${moment(createdUntilFilter).format('YYYY-MM-DD')}`,
      onDelete: () => setCreatedUntilFilter(null),
    });
  }

  const toggleDrawer = (anchor, open) => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const [state, setState] = useState({
    left: false,
  });

  const queryVariables = {
    dealer_id: dealerId,
    page_size: listView ? 20 : 60,
    page: 1,
    sort_by: sort,
    attendees:
      role && !RoleGroup.MANAGERS.includes(role) ? [currentUser.username] : [],
    statuses: [],
    type_ids: type === 'Delivery' ? [4] : [],
    search: searchFilters,
  };
  if (startDateFromFilter) {
    queryVariables.start_date_from = moment(startDateFromFilter)
      .startOf('day')
      .tz(timezone)
      .toISOString();
  }
  if (startDateUntilFilter) {
    queryVariables.start_date_until = moment(startDateUntilFilter)
      .startOf('day')
      .tz(timezone)
      .toISOString();
  }
  if (createdFromFilter) {
    queryVariables.created_from = moment(createdFromFilter)
      .startOf('day')
      .tz(timezone)
      .toISOString();
  }
  if (createdUntilFilter) {
    queryVariables.created_until = moment(createdUntilFilter)
      .startOf('day')
      .tz(timezone)
      .toISOString();
  }

  filterFacets.forEach(data => {
    if (data.field === 'is_confirmed') {
      if (data.value === 'Awaiting Confirmation') {
        queryVariables.is_confirmed = false;
      } else {
        queryVariables.is_confirmed = true;
      }
    }
    if (data.field === 'statuses') {
      queryVariables.statuses.push(data.key);
    }
    if (data.field === 'type_ids') {
      queryVariables.type_ids.push(data.key);
    }
    if (data.field === 'attendees') {
      queryVariables.attendees.push(data.key);
    }
  });

  const { data, fetchMore, loading, networkStatus } = useQuery(
    APPOINTMENTS_QUERY,
    {
      variables: queryVariables,
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    },
  );

  const rows = data?.appointments.appointments || [];
  const events = rows.map(x => ({
    id: x._id,
    title: `${x.type_name}: ${x.customer?.fullname}`,
    start: x.start_date,
  }));
  const [modalOpen, setModalOpen] = useState(false);
  const handleModalClose = () => setModalOpen(false);

  const [
    getSingleAppointment,
    { data: single_appointment, loading: modalWait },
  ] = useLazyQuery(SINGLE_APPOINTMENT_QUERY, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });
  const single_data = single_appointment?.appointment || '';

  const handleEvent = arg => {
    getSingleAppointment({
      variables: {
        _id: arg.event.id,
      },
    });
    setModalOpen(true);
  };

  // attendee
  const { data: attendeesWithCounts } = useQuery(APPOINTMENT_COUNT_ATTENDEES, {
    variables: queryVariables,
  });

  // get user display names
  const { data: displayNames } = useQuery(USER_DISPLAY_NAMES, {
    variables: { dealer_id: dealerId },
  });

  // facets
  const { data: facets } = useQuery(APPOINTMENT_FACETS, {
    variables: queryVariables,
  });

  const facetIsConfirmed = facets?.appointmentFacets.is_confirmed || [];
  const facetTypeId = facets?.appointmentFacets.type_id || [];
  const facetStatus = facets?.appointmentFacets.status || [];

  const isConfirmedFacetData = [];
  const typeIdFacetData = [];
  const statusFacetData = [];

  facetIsConfirmed.forEach(data => {
    if (data.key) {
      let value = '';
      if (data.key === 'true') {
        value = 'Appointment Confirmed';
      } else {
        value = 'Awaiting Confirmation';
      }
      isConfirmedFacetData.push({
        count: data.count,
        value,
      });
    }
  });

  facetStatus.forEach(data => {
    if (data.key) {
      let value = '';
      if (data.key === AppointmentStatus.ATTENDED) {
        value = 'Attended';
      } else if (data.key === AppointmentStatus.NO_SHOW) {
        value = 'No Show';
      } else if (data.key === AppointmentStatus.CANCELLED) {
        value = 'Cancelled';
      } else if (data.key === AppointmentStatus.UNKNOWN) {
        value = 'Unknown';
      }
      statusFacetData.push({
        count: data.count,
        key: data.key,
        value,
      });
    }
  });

  facetTypeId.forEach(data => {
    if (data.key) {
      const key = parseInt(data.key);
      let value = '';
      if (key === 1) {
        value = 'Consultation';
      } else if (key === 2) {
        value = 'Test Drive';
      } else if (key === 3) {
        value = 'Finance';
      } else if (key === 4) {
        value = 'Delivery';
      } else if (key === 5) {
        value = 'Other';
      }
      typeIdFacetData.push({
        count: data.count,
        key,
        value,
      });
    }
  });

  const facetResults = [
    {
      data: isConfirmedFacetData,
      field: 'is_confirmed',
      model: 'Appointment',
    },
    {
      data: statusFacetData,
      field: 'statuses',
      model: 'Appointment',
    },
    {
      data: typeIdFacetData,
      field: 'type_ids',
      model: 'Appointment',
    },
  ];

  if (role && RoleGroup.MANAGERS.includes(role)) {
    const attendeesList = attendeesWithCounts?.appointmentsCountAttendees || [];
    const usernameList = displayNames?.users || [];

    attendeesList.forEach(data => {
      const found = usernameList.find(element => element.username === data.key);
      if (found) {
        data.value = found.display_name;
      } else {
        data.value = data.key;
      }
    });

    facetResults.push({
      data: attendeesList || [],
      field: 'attendees',
      model: 'Appointment',
    });
  }

  const handleDeleteFacet = (_model, _field, _value) =>
    setFilterFacets(prev =>
      prev.filter(
        ({ model, field, value }) =>
          model !== _model || field !== _field || value !== _value,
      ),
    );

  const pagination = data?.appointments.pagination || {};
  const { page, pages, total } = pagination;

  const fetchMoreHandler = () => {
    if (page < pages) {
      fetchMore({
        variables: {
          page: page + 1,
        },
        updateQuery: (prev, { fetchMoreResult: more }) => {
          if (!more.appointments.appointments) return prev;
          return Object.assign({}, prev, {
            appointments: {
              __typename: prev.appointments.__typename,
              appointments: [
                ...prev.appointments.appointments,
                ...more.appointments.appointments,
              ],
              pagination: more.appointments.pagination,
            },
          });
        },
      });
    }
  };

  const handleCalendarView = () => {
    // Only add the keys if they are not included
    const statusKeys = [
      AppointmentStatus.UNKNOWN,
      AppointmentStatus.NO_SHOW,
      AppointmentStatus.ATTENDED,
    ];
    const addToFilters = [];
    statusKeys.forEach(key => {
      if (!filterFacets.some(filter => filter.key === key)) {
        addToFilters.push({
          model: 'Appointment',
          field: 'statuses',
          key: key,
          value: _.capitalize(key).replace('-', ' '),
          options: {},
        });
      }
    });
    setFilterFacets(prev => [...prev, ...addToFilters]);
    setListView(false);
  };

  return (
    <Box m={1}>
      <Box>
        <DealerPicker />
        <Dialog open={modalOpen} onClose={handleModalClose} maxWidth="md">
          <DialogTitle>Appointment Details</DialogTitle>
          <DialogContent>
            {modalWait && <Loading />}
            {!modalWait && !single_data && (
              <Box style={{ fontSize: '20px', padding: '1rem' }}>
                No Appointments Available.
              </Box>
            )}
            {!modalWait && single_data && (
              <Box style={{ paddingBottom: '1rem' }}>
                <Grid container direction="row">
                  <Grid item xs={6}>
                    <Box paddingBottom={1}>
                      <Typography
                        style={{
                          fontSize: '.875rem',
                          fontWeight: '700',
                          paddingBottom: '.5rem',
                        }}
                      >{`${moment(single_data.start_date)
                        .tz(defaultTZ)
                        .format('MMM D, YYYY @ h:mma')}`}</Typography>
                      <b>{single_data.type_name}</b>
                      <br />
                      <span style={{ fontSize: '.8rem', color: 'gray' }}>
                        {single_data.subject}
                      </span>
                    </Box>
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: 'right' }}>
                    <AppointmentStatusComponent appointment={single_data} />
                    {single_data.is_confirmed && (
                      <p style={{ color: 'green' }}>
                        <i className="fas fa-check" /> Confirmed
                      </p>
                    )}
                  </Grid>
                </Grid>
                <Grid container direction="row">
                  <Grid item xs={6}>
                    <Box
                      style={{ fontSize: '.8rem' }}
                      className={classes.customer}
                    >
                      <b>Customer: </b>
                      {single_data.customer ? (
                        <>
                          <Link
                            to={`/customers/${single_data.customer._id}/details`}
                          >
                            {single_data.customer?.fullname || ''}
                          </Link>
                          {single_data.customer.primary_email && (
                            <>
                              <br />
                              <i className="fas fa-envelope fa-fw" />{' '}
                              {single_data.customer?.primary_email || ''}
                            </>
                          )}
                          {single_data.customer.primary_phone && (
                            <>
                              <br />
                              <i className="fas fa-mobile-alt fa-fw" />{' '}
                              {single_data.customer?.primary_phone || ''}
                            </>
                          )}
                        </>
                      ) : (
                        ''
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: 'right' }}>
                    <Box style={{ fontSize: '.8rem' }}>
                      Assigned to:{' '}
                      {single_data.attendees.map(
                        attendee => attendee.display_name,
                      )}
                      <br />
                      <AppointmentActions
                        appointment={single_data}
                        classes={classes}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            )}
          </DialogContent>
          <DialogActions style={{ margin: '5px' }}>
            <Button
              onClick={handleModalClose}
              color="primary"
              variant="outlined"
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
      <Box>
        <Grid
          container
          direction="row"
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          {isDesktop && (
            <Grid
              item
              style={{
                paddingRight: '1rem',
                paddingTop: '1rem',
                paddingBottom: '1rem',
              }}
            >
              <Tooltip title="Filter list">
                <Fab
                  color="primary"
                  onClick={toggleDrawer('left', true)}
                  size="medium"
                >
                  <FilterListIcon />
                </Fab>
              </Tooltip>
            </Grid>
          )}
          {isDesktop && (
            <Grid
              item
              style={{
                display: 'flex',
                paddingTop: '1rem',
                marginRight: 'auto',
              }}
            >
              {(!_.isEmpty(filterFacets) || !_.isEmpty(otherFacets)) && (
                <Box paddingTop="0.5rem" paddingBottom="0.5rem">
                  <FacetChips
                    facets={filterFacets}
                    onDeleteFacet={handleDeleteFacet}
                    others={otherFacets}
                  />
                </Box>
              )}
            </Grid>
          )}
          <Grid
            item
            xs={12}
            sm={4}
            style={{
              display: 'flex',
            }}
          >
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: `${isDesktop ? '' : '1rem'}`,
                marginLeft: `${isDesktop ? 'auto' : ''}`,
                marginRight: `${isDesktop ? 0 : ''}`,
                width: `${isDesktop ? '' : '100%'}`,
              }}
            >
              <Box
                display="flex"
                flexDirection="column"
                style={{ width: `${isDesktop ? '' : '100%'}` }}
              >
                {isDesktop && (
                  <>
                    <Box
                      display="flex"
                      flexDirection="row"
                      style={{ marginLeft: 'auto', marginRight: 0 }}
                      className={classes.action}
                    >
                      <Button
                        size="small"
                        variant="contained"
                        startIcon={<PrintIcon />}
                        onClick={handlePrint}
                      >
                        Print
                      </Button>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="row"
                      style={{ marginLeft: 'auto', marginRight: 0 }}
                      className={classes.action}
                    >
                      <b>View as:</b>
                      {listView && (
                        <Tooltip title="Calendar">
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleCalendarView()}
                          >
                            <i className="fas fa-calendar-alt fa-fw" />
                          </Button>
                        </Tooltip>
                      )}
                      {!listView && (
                        <Tooltip title="List">
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setListView(true)}
                          >
                            <i className="fas fa-list fa-fw" />
                          </Button>
                        </Tooltip>
                      )}
                    </Box>
                  </>
                )}
                <Box>
                  <TextField
                    placeholder="Search ..."
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            disabled={!searchKeywords}
                            onClick={() => {
                              setSearchKeywords('');
                              setSearchFilters('');
                            }}
                            size="large"
                          >
                            <CloseIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    value={searchKeywords}
                    onChange={e => setSearchKeywords(e.target.value)}
                    style={{
                      paddingRight: '5px',
                      textAlign: 'right',
                      width: `${isDesktop ? '' : '100%'}`,
                    }}
                    onKeyPress={handleSearch}
                    variant={`${isDesktop ? 'standard' : 'outlined'}`}
                  />
                </Box>
                {listView && (
                  <Box
                    style={{
                      display: 'flex',
                      marginLeft: 'auto',
                      marginRight: 0,
                      fontSize: '18px',
                      paddingTop: '1rem',
                    }}
                  >
                    Showing {rows.length} of {total || '0'}
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {isDesktop && !listView && (
        <>
          {!loading && total > events.length && (
            <Alert severity="info" style={{ margin: '10px 0px' }}>
              There are more appointments than the calendar can display
            </Alert>
          )}
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            headerToolbar={{
              left: 'prev,next today',
              center: 'title',
              right: 'dayGridMonth,timeGridWeek,timeGridDay',
            }}
            initialView="dayGridMonth"
            datesSet={arg => handleCalendarDates(arg)}
            editable
            dayMaxEvents
            eventClick={arg => handleEvent(arg)}
            events={events}
          />
        </>
      )}
      {isDesktop && listView && (
        <div ref={componentRef}>
          <TableContainer>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  {headCells.map(headCell => (
                    <TableCell
                      key={headCell.id}
                      align={headCell.numeric ? 'right' : 'left'}
                      padding={headCell.disablePadding ? 'none' : 'normal'}
                      sortDirection={
                        orderBy === headCell.id
                          ? order > 0
                            ? 'asc'
                            : 'desc'
                          : false
                      }
                    >
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={
                          orderBy === headCell.id
                            ? order > 0
                              ? 'asc'
                              : 'desc'
                            : 'asc'
                        }
                        onClick={createSortHandler(headCell.id)}
                        disabled={!headCell.sortable}
                      >
                        {headCell.label}
                        {orderBy === headCell.id ? (
                          <span className={classes.visuallyHidden}>
                            {order === -1
                              ? 'sorted descending'
                              : 'sorted ascending'}
                          </span>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {loading && <Loading />}
              {rows.length === 0 && !loading && (
                <Box style={{ fontSize: '20px', padding: '1rem' }}>
                  No Appointments Found.
                </Box>
              )}
              <TableBody>
                {rows.map(row => (
                  <TableRow hover key={row._id}>
                    <TableCell>
                      <AppointmentActions appointment={row} classes={classes} />
                    </TableCell>
                    <TableCell>
                      {row.type_name}
                      <br />
                      <span style={{ fontSize: '.8rem', color: 'gray' }}>
                        {row.subject}
                      </span>
                    </TableCell>
                    <TableCell className={classes.customer}>
                      {row.customer ? (
                        <>
                          <Link to={`/customers/${row.customer._id}/details`}>
                            {row.customer?.fullname || ''}
                          </Link>
                          {row.customer.primary_email ? (
                            <>
                              <br />
                              <i className="fas fa-envelope fa-fw" />{' '}
                              {row.customer?.primary_email || ''}
                            </>
                          ) : (
                            ''
                          )}
                          {row.customer.primary_phone ? (
                            <>
                              <br />
                              <i className="fas fa-mobile-alt fa-fw" />{' '}
                              {row.customer?.primary_phone || ''}
                            </>
                          ) : (
                            ''
                          )}
                        </>
                      ) : (
                        ''
                      )}
                    </TableCell>
                    <TableCell>
                      {row.attendees.length > 0 &&
                        row.attendees[0].display_name}
                    </TableCell>
                    <TableCell>
                      {`${moment(row?.start_date)
                        .tz(defaultTZ)
                        .format('MMM D, YYYY @ h:mma')}`}
                    </TableCell>
                    <TableCell>
                      {row.is_confirmed && (
                        <p style={{ color: 'green' }}>
                          <i className="fas fa-check" /> Confirmed
                        </p>
                      )}
                    </TableCell>
                    <TableCell>
                      <AppointmentStatusComponent appointment={row} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
      {!isDesktop && (
        <>
          {(!_.isEmpty(filterFacets) || !_.isEmpty(otherFacets)) && (
            <Box paddingTop="0.5rem" paddingBottom="0.5rem">
              <FacetChips
                facets={filterFacets}
                onDeleteFacet={handleDeleteFacet}
                others={otherFacets}
              />
            </Box>
          )}
        </>
      )}
      {!isDesktop && (
        <Box style={{ paddingTop: '1rem' }}>
          {loading && <Loading />}
          {rows.length === 0 && !loading && (
            <Box style={{ fontSize: '20px', padding: '1rem' }}>
              No Appointments Found.
            </Box>
          )}
          {rows.map(row => (
            <Box style={{ paddingBottom: '1rem' }} key={row._id}>
              <Card>
                <CardContent>
                  <Grid container direction="row">
                    <Grid item xs={6}>
                      <Box paddingBottom={1}>
                        <Typography
                          style={{
                            fontSize: '.875rem',
                            fontWeight: '700',
                            paddingBottom: '.5rem',
                          }}
                        >{`${moment(row?.start_date)
                          .tz(defaultTZ)
                          .format('MMM D, YYYY @ h:mma')}`}</Typography>
                        <b>{row.type_name}</b>
                        <br />
                        <span style={{ fontSize: '.8rem', color: 'gray' }}>
                          {row.subject}
                        </span>
                      </Box>
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: 'right' }}>
                      <AppointmentStatusComponent appointment={row} />
                      <br />
                      {row.is_confirmed && (
                        <p style={{ color: 'green' }}>
                          <i className="fas fa-check" /> Confirmed
                        </p>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container direction="row">
                    <Grid item xs={6}>
                      <Box
                        style={{ fontSize: '.8rem' }}
                        className={classes.customer}
                      >
                        <b>Customer: </b>
                        {row.customer ? (
                          <>
                            <Link to={`/customers/${row.customer._id}/details`}>
                              {row.customer?.fullname || ''}
                            </Link>
                            {row.customer.primary_email ? (
                              <>
                                <br />
                                <i className="fas fa-envelope fa-fw" />{' '}
                                {row.customer?.primary_email || ''}
                              </>
                            ) : (
                              ''
                            )}
                            {row.customer.primary_phone ? (
                              <>
                                <br />
                                <i className="fas fa-mobile-alt fa-fw" />{' '}
                                {row.customer?.primary_phone || ''}
                              </>
                            ) : (
                              ''
                            )}
                          </>
                        ) : (
                          ''
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: 'right' }}>
                      <Box style={{ fontSize: '.8rem' }}>
                        Assigned to:{' '}
                        {row.attendees.length > 0 &&
                          row.attendees[0].display_name}
                        <br />
                        <AppointmentActions
                          appointment={row}
                          classes={classes}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Box>
          ))}
        </Box>
      )}
      {!isDesktop && (
        <Box position="fixed" bottom="10px" right="10px">
          <Fab
            color="primary"
            onClick={toggleDrawer('left', true)}
            size="medium"
          >
            <FilterListIcon />
          </Fab>
        </Box>
      )}
      <Drawer
        anchor="left"
        open={state.left}
        onClose={toggleDrawer('left', false)}
      >
        <Box style={{ display: 'flex' }}>
          <IconButton
            style={{
              display: 'flex',
              marginLeft: 'auto',
              height: '50px',
              zIndex: '1000',
            }}
            onClick={toggleDrawer('left', false)}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <FilterDrawer
          facetResults={facetResults}
          loading={loading}
          startDateFromFilter={startDateFromFilter}
          startDateUntilFilter={startDateUntilFilter}
          setStartDateFromFilter={setStartDateFromFilter}
          setStartDateUntilFilter={setStartDateUntilFilter}
          createdFromFilter={createdFromFilter}
          createdUntilFilter={createdUntilFilter}
          setCreatedFromFilter={setCreatedFromFilter}
          setCreatedUntilFilter={setCreatedUntilFilter}
          setSelectedFacets={setFilterFacets}
          selectedFacets={filterFacets}
          type="appointment"
        />
      </Drawer>
      {!loading && listView && (
        <Box height="1px">
          <Waypoint onEnter={fetchMoreHandler} />
        </Box>
      )}
      {networkStatus === NetworkStatus.fetchMore && (
        <Box p={2} style={{ fontSize: '16px' }}>
          Loading more appointments...
        </Box>
      )}
    </Box>
  );
};

export default AppointmentTable;
