import React, { useEffect, useState } from 'react';

/* external */
import { isEmpty, startCase } from 'lodash';
import { NetworkStatus } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import { Waypoint } from 'react-waypoint';
import gql from 'graphql-tag';

/* Material UI */
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import Fab from '@mui/material/Fab';
import FilterListIcon from '@mui/icons-material/FilterList';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';

/* internal */
import {
  BID_COUNT_SORT,
  ENDS_AT_SORT,
  FAVE_FIRST_SORT,
  IS_FAVOURITE_SORT,
  LEADING_BID_SORT,
  MIN_START_BID_SORT,
  SORT_BY_ID,
  VEHICLE_SORT_OPTIONS,
} from './const';
import { filtersFromFacets } from './utils';
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import { usePersistedState } from 'utils';
import FilterChips from './common/FilterChips';
import FilterDrawer from './common/FilterDrawer';
import Loading from 'components/MaterialUI/Loading';
import SortPopover from './common/SortPopover';
import WinningItemsRow from './WinningItemsRow';

const PAGE_SIZE = 20;

// This is called Winning Items, but it also includes won items
// (WonOrWinningItemsTable was a little too cumbersome)

const WINNING_ITEMS_QUERY = gql`
  query winningItems($dealerId: Int!, $page: Int!, $filters: [QueryFilter], $sort: [QuerySortElement]){
    auctions {
      items(page: $page, filters: $filters, pageSize: ${PAGE_SIZE}, sort: $sort){
        results {
          id
          ...WinningItemsRowAuctionItem
        }
        pagination {
          total
          page
          nextPage
        }
      }
    }
  }
  ${WinningItemsRow.fragments.auctionItem}
`;

const WINNING_ITEMS_SUBSCRIPTION = gql`
  subscription winningItemsSubscription($auctionIds: [Int!]!) {
    auctionItemFeed(auctionIds: $auctionIds) {
      type
      auctionItem {
        id
        ...AuctionItemFeed
      }
    }
  }
  ${WinningItemsRow.fragments.auctionItemFeed}
`;

const WINNING_ITEMS_CARFAX_QUERY = gql`
  query winningItemsCarfax($filters: [QueryFilter]) {
    auctions {
      carfaxBulkBadging(filters: $filters) {
        vehicleId
        badgeUrl
        reportUrl
      }
    }
  }
`;

const WinningItemsTable = ({ id }) => {
  const { dealerId } = useDealerContext();
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
  const [sortSelection, setSortSelection] = usePersistedState(
    'auctions.components.items.WinningItemsTable.sortSelection',
    null,
  );
  const [sortFavesFirst, setSortFavesFirst] = usePersistedState(
    'auctions.components.items.WinningItemsTable.sortFavesFirst',
    false,
  );
  const [searchKeywords, setSearchKeywords] = usePersistedState(
    'auctions.components.items.WinningItemsTable.searchKeywords',
    '',
  );
  const [searchFilters, setSearchFilters] = useState([]);
  const [facetFilters, setFacetFilters] = usePersistedState(
    'auctions.components.items.WinningItemsTable.facetFilters',
    [],
  );
  const [popoverAnchor, setPopoverAnchor] = useState();

  /*
    year: exact match,
    make: starts with keyword,
    model: starts with keyword,
    stock_number: contains keyword
  */
  const setSearchFiltersFromKeywords = () =>
    setSearchFilters(
      searchKeywords.split(' ').map(keyword => ({
        or: [
          {
            model: 'Vehicle',
            field: 'make',
            op: 'ilike',
            value: `${keyword}%`,
          },
          {
            model: 'Vehicle',
            field: 'model',
            op: 'ilike',
            value: `${keyword}%`,
          },
          {
            model: 'Vehicle',
            field: 'stockNumber',
            op: 'ilike',
            value: `%${keyword}%`,
          },
          {
            model: 'Vehicle',
            field: 'vin',
            op: 'ilike',
            value: `%${keyword}%`,
          },
          {
            model: 'Vehicle',
            field: 'year',
            op: '==',
            value: parseInt(keyword) || 0,
          },
        ],
      })),
    );

  useEffect(() => {
    if (searchKeywords) setSearchFiltersFromKeywords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const inThisAuctionFilter = {
    model: 'AuctionItem',
    field: 'auctionId',
    op: 'eq',
    value: id,
  };
  const baseWinningItemsFilters = [
    inThisAuctionFilter,
    {
      or: [
        {
          model: 'AuctionItem',
          field: 'leadingDealerId',
          op: '==',
          value: dealerId,
        },
        {
          and: [
            {
              model: 'Bid',
              field: 'dealerId',
              op: '==',
              value: dealerId,
            },
            {
              model: 'AuctionItem',
              field: 'isClosed',
              op: '==',
              value: false,
            },
          ],
        },
      ],
    },
  ];

  const filters = [
    ...baseWinningItemsFilters,
    ...searchFilters,
    ...filtersFromFacets(facetFilters),
  ].filter(x => !isEmpty(x));

  const sort = [
    ...(sortFavesFirst ? [FAVE_FIRST_SORT] : []),
    sortSelection,
    SORT_BY_ID,
  ].filter(x => x);

  const { data, loading, fetchMore, networkStatus, subscribeToMore } = useQuery(
    WINNING_ITEMS_QUERY,
    {
      variables: { dealerId, filters, page: 1, sort },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    },
  );

  const items = data?.auctions.items.results ?? [];
  const { total, nextPage } = data?.auctions.items.pagination ?? {};

  const carfaxQuery = useQuery(WINNING_ITEMS_CARFAX_QUERY, {
    variables: {
      filters: [
        {
          model: 'Vehicle',
          field: 'id',
          op: 'in',
          value: items.map(x => x.vehicle.id),
        },
      ],
    },
    skip: !items?.length > 0,
  });

  const carfaxes = carfaxQuery.data?.auctions.carfaxBulkBadging ?? [];

  useEffect(
    () =>
      subscribeToMore({
        document: WINNING_ITEMS_SUBSCRIPTION,
        variables: { auctionIds: [id] },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;
          const { type } = subscriptionData.data.auctionItemFeed;
          // when does the item resolve the nested fields?  Do we get that data here?
          // YES we do!  Awesome!
          if (type === 'created'); // don't do anything for created items, since
          // newly created items won't have any bids
          if (type === 'updated');
          // we can just look at the leadingBid data (if we have it here) to determine
          // if we want to add it to the list (still not sure if we want to live add)
          // Since this page has live bidding ability, let's not live add them.
          // TODO: add a snackbar notification?  Add a link at the top to indicate
          // new items?  Note that usually one person from each dealer does the
          // bidding, so this isn't really a major issue anyway.
        },
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleMoreItems = () =>
    nextPage
      ? fetchMore({
          variables: { page: nextPage },
          updateQuery: (prev, { fetchMoreResult: more }) => {
            if (!more.auctions.items.results) return prev;
            return Object.assign({}, prev, {
              auctions: {
                __typename: prev.auctions.__typename,
                items: {
                  __typename: prev.auctions.items.__typename,
                  results: [
                    ...prev.auctions.items.results,
                    ...more.auctions.items.results,
                  ],
                  pagination: more.auctions.items.pagination,
                },
              },
            });
          },
        })
      : null;

  const handleSearch = e =>
    e.key === 'Enter' ? setSearchFiltersFromKeywords() : null;

  const isSortingBy = ({ model, field, direction }) =>
    sortSelection?.field === field &&
    sortSelection?.model === model &&
    (!direction || sortSelection?.direction === direction);

  const sortDirection = sortSelection?.direction;
  const clickSortHandler =
    ({ model, field }) =>
    () =>
      setSortSelection(prev => ({
        model,
        field,
        direction:
          prev?.model === model &&
          prev?.field === field &&
          prev?.direction === 'asc'
            ? 'desc'
            : 'asc',
      }));

  const vehicleSort = VEHICLE_SORT_OPTIONS.find(isSortingBy);

  const vehicleSortLabel = vehicleSort
    ? vehicleSort.options?.headerLabel ?? `(${startCase(vehicleSort.field)})`
    : null;

  if (loading && networkStatus !== NetworkStatus.fetchMore) return <Loading />;

  return (
    <>
      <FilterDrawer
        anchor="left"
        open={filterDrawerOpen}
        onClose={() => setFilterDrawerOpen(false)}
        filters={filters}
        facetFilters={facetFilters}
        setFacetFilters={setFacetFilters}
        facetDefinitions={[
          { model: 'AuctionItem', field: 'isFavourite' },
          { model: 'Vehicle', field: 'make' },
          { model: 'Vehicle', field: 'model' },
          { model: 'Vehicle', field: 'year' },
          {
            model: 'AuctionItem',
            field: 'isClosed',
            options: { label: 'Closed' },
          },
        ]}
      />
      <SortPopover
        anchorEl={popoverAnchor}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={Boolean(popoverAnchor)}
        onClose={() => setPopoverAnchor(null)}
        sortOptions={VEHICLE_SORT_OPTIONS}
        setSortSelection={setSortSelection}
        sortSelection={sortSelection}
      />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <Box display="flex">
          <Box paddingRight={2}>
            <Tooltip title="Filter list">
              <Fab
                color="primary"
                onClick={() => setFilterDrawerOpen(true)}
                size="medium"
              >
                <FilterListIcon />
              </Fab>
            </Tooltip>
          </Box>
          <Box paddingLeft={2}>
            <FormControlLabel
              label="Sort Favourites First"
              control={
                <Switch
                  color="primary"
                  checked={sortFavesFirst}
                  onChange={() => setSortFavesFirst(p => !p)}
                />
              }
            />
          </Box>
        </Box>

        <Box padding={1} flexGrow={2}>
          <FilterChips filters={facetFilters} setFilters={setFacetFilters} />
        </Box>
        <Box display="flex" flexDirection="column" alignItems="flex-end">
          <Box>
            <TextField
              placeholder="Search ..."
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      disabled={!searchKeywords}
                      onClick={() => {
                        setSearchKeywords('');
                        setSearchFilters([]);
                      }}
                      size="large"
                    >
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              value={searchKeywords}
              onChange={e => setSearchKeywords(e.target.value)}
              style={{ paddingRight: '5px', textAlign: 'right' }}
              onKeyPress={handleSearch}
            />
          </Box>

          <Box paddingTop={1} fontSize="18px">
            Showing {items.length} of {total}
          </Box>
        </Box>
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell />
            <TableCell>
              <TableSortLabel
                onClick={e => setPopoverAnchor(e.currentTarget)}
                active={VEHICLE_SORT_OPTIONS.some(isSortingBy)}
                direction={sortDirection}
              >
                Vehicle {vehicleSortLabel}
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={isSortingBy(IS_FAVOURITE_SORT)}
                direction={sortDirection}
                onClick={clickSortHandler(IS_FAVOURITE_SORT)}
              >
                Favourite
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={isSortingBy(ENDS_AT_SORT)}
                direction={sortDirection}
                onClick={clickSortHandler(ENDS_AT_SORT)}
              >
                End
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={isSortingBy(BID_COUNT_SORT)}
                direction={sortDirection}
                onClick={clickSortHandler(BID_COUNT_SORT)}
              >
                Bids
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={isSortingBy(MIN_START_BID_SORT)}
                direction={sortDirection}
                onClick={clickSortHandler(MIN_START_BID_SORT)}
              >
                Min Bid
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={isSortingBy(LEADING_BID_SORT)}
                direction={sortDirection}
                onClick={clickSortHandler(LEADING_BID_SORT)}
              >
                High Bid
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map(item => (
            <WinningItemsRow
              key={item.id}
              item={item}
              carfax={carfaxes.find(x => x.vehicleId === item?.vehicle.id)}
            />
          ))}
        </TableBody>
      </Table>
      {!loading && <Waypoint onEnter={handleMoreItems} />}
      {loading && networkStatus === NetworkStatus.fetchMore ? (
        <Loading text="Loading more auction items..." />
      ) : (
        nextPage && <Box height="6rem" />
      )}
    </>
  );
};

export default WinningItemsTable;
