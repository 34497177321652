// External
import { useMutation, useQuery } from '@apollo/react-hooks';
import { NetworkStatus } from 'apollo-boost';
import gql from 'graphql-tag';
import _ from 'lodash';
import moment from 'moment-timezone';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';

// Material UI
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Drawer from '@mui/material/Drawer';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import CloseIcon from '@mui/icons-material/Close';
import FilterListIcon from '@mui/icons-material/FilterList';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SearchIcon from '@mui/icons-material/Search';

// Internal
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import DealerPicker from 'components/MaterialUI/DealerPicker';
import Loading from 'components/MaterialUI/Loading';
import { useUserContext } from 'components/MaterialUI/UserContext';
import { Role } from 'constants.js';
import { capitalize, usePersistedState, defaultTZ } from 'utils';
import { format_seconds, get_age } from '../utils.js';
import FacetChips from './FacetChips';
import FilterDrawer from './FilterDrawer';
import LeadActions from './LeadActions';
import LeadsListActions from './LeadsListActions';

const LEADS_QUERY = gql`
  query getLeads(
    $page_size: Int
    $page: Int
    $query: LeadQuery
    $filters: LeadFilters
    $search: String
  ) {
    leads(
      page_size: $page_size
      page: $page
      query: $query
      search: $search
      with_facets: true
      filters: $filters
    ) {
      total
      page
      pages
      facets {
        type {
          value
          total
          typeName
        }
        is_archived {
          value
          total
        }
        assignee {
          value
          total
          user {
            username
            display_name
          }
        }
        form_data_lead_channel {
          value
          total
        }
        form_data_form_name {
          value
          total
        }
        form_data_lead_source {
          value
          total
        }
        is_junk {
          value
          total
        }
      }
      results {
        _id
        created
        assigned
        is_archived
        is_junk
        tags
        type
        typeName
        form_data {
          lead_type
          first_name
          last_name
          form_name
          lead_source
        }
        customer {
          _id
          fullname
          opportunities {
            _id
            crm_lead_ids
          }
        }
        assigneeNode {
          username
          display_name
        }
        first_response {
          username
          response_type
          responded
        }
        permissions {
          can_create_or_link_opportunity
          can_assign_lead
          can_archive_lead
        }
      }
    }
  }
`;

const USERS_FOR_DEALER = gql`
  query getUsers($dealerId: Int!) {
    users(dealer_ids: [$dealerId], status: active) {
      display_name
      username
      role
    }
  }
`;

const ASSIGN_LEAD = gql`
  mutation assignLead($leadId: ID!, $username: String!) {
    assignLead(_id: $leadId, username: $username) {
      _id
      assignee
      assigned
      assigneeNode {
        username
        display_name
      }
    }
  }
`;

const headCells = [
  {
    id: 'actions',
    numeric: false,
    disablePadding: false,
    label: 'Actions',
  },
  {
    id: 'lead_type',
    numeric: false,
    disablePadding: false,
    label: 'Type',
  },
  {
    id: 'lead_source',
    numeric: false,
    disablePadding: false,
    label: 'Source',
  },
  {
    id: 'form_name',
    numeric: false,
    disablePadding: false,
    label: 'Form Name',
  },
  {
    id: 'customer',
    numeric: false,
    disablePadding: false,
    label: 'Customer',
  },
  {
    id: 'assignee',
    numeric: false,
    disablePadding: false,
    label: 'Assignee',
  },
  {
    id: 'age',
    numeric: false,
    disablePadding: false,
    label: 'Age',
  },
  {
    id: 'claim_time',
    numeric: false,
    disablePadding: false,
    label: 'Claim Time',
  },
];

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 750,
  },
  customer: {
    '& a': {
      color: '#0093a7',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  fabs: {
    '& button': {
      margin: '0 0.25rem',
    },
  },
}));

const LEAD_TYPES = [
  { value: 'new_sales', name: 'New Sales' },
  { value: 'used_sales', name: 'Used Sales' },
  { value: 'service', name: 'Service' },
  { value: 'parts', name: 'Parts' },
  { value: 'contact', name: 'Customer Service' },
];

const LeadTable = () => {
  const { currentUser } = useUserContext();
  const filterVariables = {
    type: [],
    is_archived: [],
    assignee: currentUser.role === Role.SALES_REP ? [currentUser.username] : [],
    form_data_lead_channel: [],
    form_data_form_name: [],
    form_data_lead_source: [],
    is_junk: [],
    is_responded: [],
  };
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [searchKeywords, setSearchKeywords] = useState('');
  const [searchFilters, setSearchFilters] = useState('');
  const filtersParams = new URLSearchParams(useLocation().search).get(
    'filters',
  ); // today, active, notReplied
  var initFilterFacets = [
    {
      model: 'Lead',
      field: 'is_archived',
      key: false,
      value: 'Not Archived',
      options: {},
    },
  ];
  if (filtersParams === 'notReplied') {
    initFilterFacets.push({
      model: 'Lead',
      field: 'is_responded',
      key: false,
      value: 'Not Replied',
      options: {},
    });
  }

  if (LEAD_TYPES.map(obj => obj.value).includes(filtersParams)) {
    // Handle type based filters
    filterVariables.type.push(filtersParams);
    initFilterFacets.push({
      model: 'Lead',
      field: 'type',
      key: filtersParams,
      value: capitalize(filtersParams.replace('_', ' ')),
      options: {},
    });
  }

  const [filterFacets, setFilterFacets] = usePersistedState(
    'leads.components.LeadTable.filterFacets',
    initFilterFacets,
  );
  var initCreatedFrom = moment().subtract(3, 'months');
  if (filtersParams === 'today') {
    initCreatedFrom = moment().subtract(1, 'days');
  } else if (filtersParams) {
    initCreatedFrom = null;
  }
  const [createdFromFilter, setCreatedFromFilter] = usePersistedState(
    'leads.components.LeadTable.createdFromFilter',
    initCreatedFrom,
  );
  const [createdUntilFilter, setCreatedUntilFilter] = useState(null);
  const { dealerId } = useDealerContext();
  const timezone = currentUser?.goUserProfile?.settings?.timezone || defaultTZ;

  const theme = useTheme();

  const isDesktop = useMediaQuery(theme => theme.breakpoints.up('sm'));

  const [anchorEl, setAnchorEl] = useState(null);
  const handleAnchorClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleAnchorClose = () => {
    setAnchorEl(null);
  };

  const queryVariables = {
    dealer_id: dealerId,
  };

  if (createdFromFilter || createdUntilFilter) {
    queryVariables.created = {
      gte: createdFromFilter,
      lte: createdUntilFilter,
    };
  }

  filterFacets.forEach(data => {
    if (data.field === 'is_archived') {
      filterVariables.is_archived.push(data.key);
    }
    if (data.field === 'assignee') {
      filterVariables.assignee.push(data.key);
    }
    if (data.field === 'form_data_lead_channel') {
      filterVariables.form_data_lead_channel.push(data.key);
    }
    if (data.field === 'form_data_form_name') {
      filterVariables.form_data_form_name.push(data.key);
    }
    if (data.field === 'form_data_lead_source') {
      filterVariables.form_data_lead_source.push(data.key);
    }
    if (data.field === 'type') {
      filterVariables.type.push(data.key);
    }
    if (data.field === 'is_junk') {
      filterVariables.is_junk.push(data.key);
    }
    if (data.field === 'is_responded') {
      filterVariables.is_responded.push(data.key);
    }
  });

  const { data, fetchMore, loading, networkStatus } = useQuery(LEADS_QUERY, {
    variables: {
      query: queryVariables,
      page_size: 20,
      page: 1,
      filters: filterVariables,
      search: searchFilters,
    },
    notifyOnNetworkStatusChange: true,
  });

  const rows = data?.leads.results || [];
  const page = data?.leads.page || 0;
  const pages = data?.leads.pages || 0;
  const total = data?.leads.total || 0;

  const facetArchive = data?.leads.facets.is_archived || [];
  const facetAssignee = data?.leads.facets.assignee || [];
  const facetChannel = data?.leads.facets.form_data_lead_channel || [];
  const facetFormName = data?.leads.facets.form_data_form_name || [];
  const facetSource = data?.leads.facets.form_data_lead_source || [];
  const facetType = data?.leads.facets.type || [];
  const facetIsJunk = data?.leads.facets.is_junk || [];
  const isArchivedFacetData = [];
  const assigneeFacetData = [];
  const channelFacetData = [];
  const formNameFacetData = [];
  const sourceFacetData = [];
  const typeFacetData = [];
  const isJunkFacetData = [];

  facetArchive.forEach(data => {
    var name = '';
    if (data.value === true) {
      name = 'Archived';
    } else {
      name = 'Not Archived';
    }
    isArchivedFacetData.push({
      key: data.value,
      count: data.total,
      value: name,
    });
  });
  facetAssignee.forEach(data => {
    assigneeFacetData.push({
      key: data.value,
      count: data.total,
      value: data.user?.display_name || data.value,
    });
  });
  facetChannel.forEach(data => {
    channelFacetData.push({
      key: data.value,
      count: data.total,
      value: data.value,
    });
  });
  facetFormName.forEach(data => {
    formNameFacetData.push({
      key: data.value,
      count: data.total,
      value: data.value,
    });
  });
  facetSource.forEach(data => {
    sourceFacetData.push({
      key: data.value,
      count: data.total,
      value: data.value,
    });
  });
  facetType.forEach(data => {
    typeFacetData.push({
      key: data.value,
      count: data.total,
      value: data.typeName,
    });
  });
  facetIsJunk.forEach(data => {
    var name = '';
    if (data.value === true) {
      name = 'Yes';
    } else {
      name = 'No';
    }
    isJunkFacetData.push({
      key: data.value,
      count: data.total,
      value: name,
    });
  });

  const facetResults = [
    {
      data: isArchivedFacetData,
      field: 'is_archived',
      model: 'Lead',
    },
    {
      data: assigneeFacetData,
      field: 'assignee',
      model: 'Lead',
    },
    {
      data: channelFacetData,
      field: 'form_data_lead_channel',
      model: 'Lead',
    },
    {
      data: formNameFacetData,
      field: 'form_data_form_name',
      model: 'Lead',
    },
    {
      data: sourceFacetData,
      field: 'form_data_lead_source',
      model: 'Lead',
    },
    {
      data: typeFacetData,
      field: 'type',
      model: 'Lead',
    },
    {
      data: isJunkFacetData,
      field: 'is_junk',
      model: 'Lead',
    },
  ];

  const handleDeleteFacet = (_model, _field, _value) =>
    setFilterFacets(prev =>
      prev.filter(
        ({ model, field, value }) =>
          model !== _model || field !== _field || value !== _value,
      ),
    );

  // add try catch, await to fix the unmounted component error
  const fetchMoreHandler = async () => {
    if (page < pages) {
      try {
        await fetchMore({
          variables: {
            page: page + 1,
          },
          updateQuery: (prev, { fetchMoreResult: more }) => {
            if (!more.leads.results) return prev;
            return Object.assign({}, prev, {
              leads: {
                __typename: prev.leads.__typename,
                results: [...prev.leads.results, ...more.leads.results],
                page: more.leads.page,
                pages: more.leads.pages,
                total: more.leads.total,
              },
            });
          },
        });
      } catch {}
    }
  };

  const { data: userData } = useQuery(USERS_FOR_DEALER, {
    variables: { dealerId: dealerId },
  });
  const userList = _.sortBy(userData?.users, ['display_name']);

  const setSearchFiltersFromKeywords = () => setSearchFilters(searchKeywords);

  useEffect(() => {
    if (searchKeywords) setSearchFiltersFromKeywords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = e =>
    e.key === 'Enter' ? setSearchFiltersFromKeywords() : null;

  let dateFilters = [];
  let otherFacets = [];

  if (createdFromFilter) {
    const momentDate = moment(createdFromFilter);
    dateFilters.push({
      model: 'Lead',
      field: 'created',
      op: '>=',
      value: momentDate.startOf('day').tz(timezone).toISOString(),
    });
    otherFacets.push({
      label: `> ${momentDate.format('YYYY-MM-DD')}`,
      onDelete: () => setCreatedFromFilter(null),
    });
  }

  if (createdUntilFilter) {
    dateFilters.push({
      model: 'Lead',
      field: 'created',
      op: '<=',
      value: createdUntilFilter.startOf('day').tz(timezone).toISOString(),
    });
    otherFacets.push({
      label: `< ${createdUntilFilter.format('YYYY-MM-DD')}`,
      onDelete: () => setCreatedUntilFilter(null),
    });
  }

  const toggleDrawer = (anchor, open) => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const [state, setState] = useState({
    left: false,
  });

  const [openAssignLead, setOpenAssignLead] = useState(false);
  const [id, setId] = useState('');
  const [username, setUsername] = useState('');

  const [assignLead] = useMutation(ASSIGN_LEAD, {
    onCompleted: () => {
      setId('');
      setUsername('');
      enqueueSnackbar(`Lead successfully assigned`, {
        variant: 'success',
      });
    },
    onError: () =>
      enqueueSnackbar(`Error when assigning lead`, {
        variant: 'error',
      }),
  });

  const handleOpenAssignLead = id => {
    if (id) {
      setId(id);
      setOpenAssignLead(true);
    } else {
      enqueueSnackbar('Customer has no CRM records!', { variant: 'error' });
    }
  };

  const handleClose = () => {
    setOpenAssignLead(false);
  };

  const handleChange = event => {
    setUsername(event.target.value);
  };

  const onSubmit = () => {
    if (username !== '') {
      assignLead({
        variables: {
          leadId: id,
          username,
        },
      });
    }

    handleClose();
  };

  return (
    <Box m={1}>
      <Box>
        <DealerPicker />
        <Dialog open={openAssignLead} onClose={handleClose}>
          <DialogTitle>Assign Lead</DialogTitle>
          <DialogContent>
            <InputLabel>Assignee</InputLabel>
            <Select
              id="username"
              value={username}
              onChange={handleChange}
              style={{ width: '100%' }}
            >
              {userList.map(i => (
                <MenuItem key={i.username} value={i.username}>
                  {i.display_name}
                </MenuItem>
              ))}
            </Select>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" variant="outlined">
              Cancel
            </Button>
            <Button
              onClick={onSubmit}
              color="primary"
              variant="contained"
              style={
                username === '' ? theme.actions.disabled : theme.actions.confirm
              }
              disabled={username === ''}
            >
              Assign
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
      <Box>
        <Grid
          container
          direction="row"
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          {isDesktop && (
            <Grid
              item
              style={{
                paddingRight: '1rem',
                paddingTop: '1rem',
                paddingBottom: '1rem',
              }}
              className={classes.fabs}
            >
              <Tooltip title="List Actions">
                <Fab color="primary" onClick={handleAnchorClick} size="medium">
                  <MoreVertIcon />
                </Fab>
              </Tooltip>
              <LeadsListActions
                anchorEl={anchorEl}
                handleAnchorClose={handleAnchorClose}
                query={queryVariables}
                filter={filterVariables}
              />
              <Tooltip title="Filter list">
                <Fab
                  color="primary"
                  onClick={toggleDrawer('left', true)}
                  size="medium"
                >
                  <FilterListIcon />
                </Fab>
              </Tooltip>
            </Grid>
          )}
          {isDesktop && (
            <Grid
              item
              style={{
                display: 'flex',
                paddingTop: '1rem',
                marginRight: 'auto',
              }}
            >
              {(!_.isEmpty(filterFacets) || !_.isEmpty(otherFacets)) && (
                <Box paddingTop="0.5rem" paddingBottom="0.5rem">
                  <FacetChips
                    facets={filterFacets}
                    onDeleteFacet={handleDeleteFacet}
                    others={otherFacets}
                  />
                </Box>
              )}
            </Grid>
          )}
          <Grid
            item
            xs={12}
            sm={6}
            style={{
              display: 'flex',
            }}
          >
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: `${isDesktop ? '' : '1rem'}`,
                marginLeft: `${isDesktop ? 'auto' : ''}`,
                marginRight: `${isDesktop ? 0 : ''}`,
                width: `${isDesktop ? '' : '100%'}`,
              }}
            >
              <Box
                display="flex"
                flexDirection="column"
                style={{ width: `${isDesktop ? '' : '100%'}` }}
              >
                <Box>
                  <TextField
                    placeholder="Search ..."
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            disabled={!searchKeywords}
                            onClick={() => {
                              setSearchKeywords('');
                              setSearchFilters('');
                            }}
                            size="large"
                          >
                            <CloseIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    value={searchKeywords}
                    onChange={e => setSearchKeywords(e.target.value)}
                    style={{
                      paddingRight: '5px',
                      textAlign: 'right',
                      width: `${isDesktop ? '' : '100%'}`,
                    }}
                    onKeyPress={handleSearch}
                    variant={`${isDesktop ? 'standard' : 'outlined'}`}
                  />
                </Box>
                <Box
                  style={{
                    display: 'flex',
                    marginLeft: 'auto',
                    marginRight: 0,
                    fontSize: '18px',
                    paddingTop: '1rem',
                  }}
                >
                  Showing {rows.length} of {total || '0'}
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {isDesktop && (
        <TableContainer>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                {headCells.map(headCell => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.numeric ? 'right' : 'left'}
                    padding={headCell.disablePadding ? 'none' : 'normal'}
                  >
                    {headCell.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {loading && <Loading />}
            {rows.length === 0 && !loading && (
              <Box style={{ fontSize: '20px', padding: '1rem' }}>
                No Leads Found.
              </Box>
            )}
            {rows.length > 0 && (
              <TableBody>
                {rows.map(row => (
                  <TableRow
                    hover
                    key={row._id}
                    style={{
                      borderLeft: `${
                        row.is_archived
                          ? '#c4c4c4 10px solid'
                          : '#f8df06 10px solid'
                      }`,
                    }}
                  >
                    <TableCell>
                      <LeadActions
                        row={row}
                        handleOpenAssignLead={handleOpenAssignLead}
                      />
                    </TableCell>
                    <TableCell style={{ textTransform: 'capitalize' }}>
                      {row.typeName ?? row.type}
                    </TableCell>
                    <TableCell>{row.form_data.lead_source}</TableCell>
                    <TableCell>{row.form_data.form_name}</TableCell>
                    <TableCell className={classes.customer}>
                      {row.form_data.first_name} {row.form_data.last_name}
                      <br />
                      {row.customer && (
                        <Link to={`/customers/${row.customer._id}/details`}>
                          <i className="fas fa-user" />{' '}
                          {row.customer?.fullname || ''}
                        </Link>
                      )}
                    </TableCell>
                    <TableCell>
                      {row.assigneeNode && row.assigneeNode.display_name}
                    </TableCell>
                    <TableCell>{get_age(row.created)}</TableCell>
                    <TableCell>
                      {row.assigned &&
                        format_seconds(
                          moment
                            .duration(
                              moment(row.assigned).diff(moment(row.created)),
                            )
                            .asSeconds(),
                        )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      )}
      {!isDesktop && (
        <>
          {(!_.isEmpty(filterFacets) || !_.isEmpty(otherFacets)) && (
            <Box paddingTop="0.5rem" paddingBottom="0.5rem">
              <FacetChips
                facets={filterFacets}
                onDeleteFacet={handleDeleteFacet}
                others={otherFacets}
              />
            </Box>
          )}
        </>
      )}
      {!isDesktop && (
        <Box paddingTop={'1rem'}>
          {loading && <Loading />}
          {rows.length === 0 && !loading && (
            <Box fontSize={'20px'} padding={1}>
              No Leads Found.
            </Box>
          )}
          {rows.map(row => (
            <Box paddingBottom={1} key={row._id}>
              <Card>
                <CardContent>
                  <Grid container direction="row">
                    <Grid item xs={6}>
                      <Box lineHeight={'1.5rem'}>
                        <Typography
                          style={{
                            fontSize: '1.125rem',
                            fontWeight: '700',
                            paddingBottom: '.5rem',
                            textTransform: 'capitalize',
                          }}
                        >
                          {row.typeName ?? row.type} Lead
                        </Typography>
                        <b>Source: </b>
                        {row.form_data.lead_source}
                        <br />
                        <b>Form Name: </b>
                        {row.form_data.form_name}
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      style={{ textAlign: 'right', lineHeight: '1.5rem' }}
                    >
                      <Box>
                        {get_age(row.created)}
                        <br />
                        <b>Assignee: </b>
                        {row.assigneeNode && row.assigneeNode.display_name}
                        <br />
                        <b>Claim Time: </b>
                        {row.assigned &&
                          format_seconds(
                            moment
                              .duration(
                                moment(row.assigned).diff(moment(row.created)),
                              )
                              .asSeconds(),
                          )}
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      className={classes.customer}
                      style={{ lineHeight: '1.5rem' }}
                    >
                      <b>Customer Name: </b>
                      {row.form_data.first_name}&nbsp;{row.form_data.last_name}
                      <br />
                      <b>CRM profile: </b>
                      {row.customer && (
                        <Link to={`/customers/${row.customer._id}/details`}>
                          {row.customer?.fullname || ''}
                        </Link>
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{ textAlign: 'right', paddingTop: '0.5rem' }}
                    >
                      <LeadActions
                        row={row}
                        handleOpenAssignLead={handleOpenAssignLead}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Box>
          ))}
        </Box>
      )}
      {!isDesktop && (
        <Box
          position="fixed"
          bottom="10px"
          right="10px"
          className={classes.fabs}
        >
          <Fab color="primary" onClick={handleAnchorClick} size="medium">
            <MoreVertIcon />
          </Fab>
          <LeadsListActions
            anchorEl={anchorEl}
            handleAnchorClose={handleAnchorClose}
            query={queryVariables}
            filter={filterVariables}
          />
          <Fab
            color="primary"
            onClick={toggleDrawer('left', true)}
            size="medium"
          >
            <FilterListIcon />
          </Fab>
        </Box>
      )}
      <Drawer
        anchor={'left'}
        open={state['left']}
        onClose={toggleDrawer('left', false)}
      >
        <Box style={{ display: 'flex' }}>
          <IconButton
            style={{
              display: 'flex',
              marginLeft: 'auto',
              height: '50px',
              zIndex: '1000',
            }}
            onClick={toggleDrawer('left', false)}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <FilterDrawer
          facetResults={facetResults}
          loading={loading}
          createdFromFilter={createdFromFilter}
          createdUntilFilter={createdUntilFilter}
          setCreatedFromFilter={setCreatedFromFilter}
          setCreatedUntilFilter={setCreatedUntilFilter}
          setSelectedFacets={setFilterFacets}
          selectedFacets={filterFacets}
        />
      </Drawer>
      {!loading && (
        <Box height="1px">
          <Waypoint onEnter={fetchMoreHandler} />
        </Box>
      )}
      {networkStatus === NetworkStatus.fetchMore && (
        <Box p={2} style={{ fontSize: '16px' }}>
          Loading more leads...
        </Box>
      )}
    </Box>
  );
};

export default LeadTable;
