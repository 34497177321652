/* external */
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';

import {
  Box,
  Button,
  ButtonBase,
  Card,
  CardActionArea,
  CardContent,
  Grid,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material';
import {
  Check as CheckIcon,
  Email as EmailIcon,
  Phone as PhoneIcon,
} from '@mui/icons-material';

import ConfirmDialog from 'components/MaterialUI/ConfirmDialog';
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import FormatDateTZ from 'components/MaterialUI/FormatDateTZ';

import { displayName } from 'modules/used_vehicles/components/utils';

import {
  formatOdometer,
  formatPhoneNumber,
  formatPrice,
  newWindowUrlHandler,
} from 'utils';

import BuyNowCardDetailsDialog from './BuyNowCardDetailsDialog';

const BUY_NOW = gql`
  mutation buyNowAppraisal($id: Int!, $dealerId: Int!) {
    appraisals {
      buyNowAppraisal(id: $id, data: { dealerId: $dealerId }) {
        id
        myBuyNowOffer(dealerId: $dealerId) {
          id
        }
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  header: {
    fontSize: '14px',
  },
  subHeader: {
    fontSize: '12px',
  },
  content: {
    fontSize: '12px',
    marginTop: theme.spacing(1),
  },
  grey: {
    color: '#999999',
  },
  root: {
    marginBottom: theme.spacing(2),
  },
  killCarfaxPadding: {
    '& div': { padding: '0px !important' },
  },
}));

const BuyNowCard = ({ appraisal }) => {
  const { dealerId } = useDealerContext();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const classes = useStyles();
  const [showDetails, setShowDetails] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [buyNow] = useMutation(BUY_NOW);

  const {
    buyNowPrice,
    buyer,
    buyerUser,
    carfax,
    exteriorColour,
    id,
    interiorColour,
    lastAuctionStartedAt,
    myBuyNowOffer,
    odometer,
    trimVariation,
    vin,
    locatedProvince,
  } = appraisal;
  const {
    cell_phone: cell,
    desk_phone: desk,
    email_address: email,
  } = buyerUser?.profile ?? {};

  const phone = cell ?? desk;

  const { badgeUrl, reportUrl } = carfax ?? {};
  const handleClickBuyNow = e => {
    e.stopPropagation();
    e.preventDefault();
    setShowConfirmDialog(true);
  };

  const SnackbarSuccess = () => (
    <Box>
      <Box fontWeight="bold" fontSize="16px">
        Thank You!
      </Box>
      <Box>
        Your request has been sent to the buyer and they will be in touch with
        you shortly!
      </Box>
    </Box>
  );

  const onBuyNow = e =>
    buyNow({ variables: { id, dealerId } }).then(
      () => enqueueSnackbar(<SnackbarSuccess />, { variant: 'success' }),
      err =>
        enqueueSnackbar(`${err.message || JSON.stringify(err)}`, {
          variant: 'error',
        }),
    );

  useEffect(() => window.CarProofBadge?.refresh());

  return (
    <Card className={classes.root}>
      <BuyNowCardDetailsDialog
        appraisal={appraisal}
        fullWidth
        maxWidth="xl"
        open={showDetails}
        onClose={e => {
          e.stopPropagation();
          e.preventDefault();
          setShowDetails(false);
        }}
      />
      <ConfirmDialog
        text={`Are you sure you want to buy this ${displayName(
          appraisal,
        )} for ${formatPrice(buyNowPrice)}?`}
        confirmText="Buy It!"
        abortText="Cancel"
        isOpen={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
        onConfirm={onBuyNow}
      />
      <CardActionArea onClick={() => setShowDetails(true)}>
        <CardContent style={{ padding: theme.spacing(1) }}>
          <Box>
            <Box display="flex" justifyContent="space-between">
              <Box flexGrow={1}>
                <Box
                  display="flex"
                  flexWrap="wrap"
                  alignItems="baseline"
                  mb={1}
                >
                  <Box fontWeight="bold" mr={1} className={classes.header}>
                    {displayName(appraisal)}
                  </Box>
                  <Box className={`${classes.grey} ${classes.subHeader}`}>
                    {trimVariation}
                  </Box>
                </Box>
                <Box>
                  <Box component="span">{vin} / </Box>
                  <Box component="span" color="#999999">
                    {formatOdometer(odometer)}
                  </Box>
                </Box>
                <Grid className={classes.content} style={{ width: '100%' }}>
                  <Grid container item xs={12} sm={6}>
                    <Grid item xs={6}>
                      <b>Int:</b> {interiorColour || 'Unknown'}
                    </Grid>
                    <Grid item xs={6} className={classes.grey}>
                      <b>Ext:</b> {exteriorColour || 'Unknown'}
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="stretch"
                marginLeft={1}
              >
                <Box
                  fontWeight="bold"
                  display="flex"
                  justifyContent="space-between"
                >
                  <Box>Price:</Box>
                  <Box>{formatPrice(buyNowPrice)}</Box>
                </Box>
                <Box width="106px" mt={1}>
                  <Button
                    disabled={myBuyNowOffer}
                    variant="contained"
                    style={{
                      ...(myBuyNowOffer
                        ? theme.actions.disabled
                        : theme.actions.confirm),
                    }}
                    startIcon={<CheckIcon />}
                    size="small"
                    onClick={handleClickBuyNow}
                  >
                    Buy Now
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box display="flex">
            {badgeUrl && (
              <ButtonBase
                onClick={e => {
                  e.stopPropagation();
                  newWindowUrlHandler(reportUrl)();
                }}
              >
                <img src={badgeUrl} alt="carfax-badge" />
              </ButtonBase>
            )}
          </Box>
          {/* Contact/auction info */}
          <Box marginTop={1} fontSize="14px">
            <Box component="span" fontWeight="bold" mr={1}>
              Last Auction:
            </Box>
            <FormatDateTZ
              format="MMM DD YYYY @ h:mm a"
              component="span"
              date={lastAuctionStartedAt}
            />
          </Box>
          <Box marginTop={1} fontSize="14px">
            <Box component="span" fontWeight="bold" mr={1}>
              Buyer:
            </Box>
            {buyer}
          </Box>
          <Box marginTop={1} fontSize="14px">
            <Box component="span" fontWeight="bold" mr={1}>
              Located Province:
            </Box>
            {locatedProvince}
          </Box>
          <Box>
            {email && (
              <Box component="span" mr={1}>
                <Button
                  startIcon={<EmailIcon />}
                  href={`mailto:${email}`}
                  style={{ textTransform: 'none', paddingLeft: 0 }}
                  onClick={e => e.stopPropagation()}
                >
                  {email}
                </Button>
              </Box>
            )}
            {phone && (
              <Box component="span">
                <Button
                  startIcon={<PhoneIcon />}
                  href={`tel:${phone}`}
                  style={{ textTransform: 'none', paddingLeft: 0 }}
                  onClick={e => e.stopPropagation()}
                >
                  {formatPhoneNumber(phone)}
                </Button>
              </Box>
            )}
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

BuyNowCard.fragments = {
  appraisal: gql`
    fragment BuyNowCardAppraisal on Appraisal {
      id
      buyNowPrice
      buyer
      buyerUser {
        profile {
          cell_phone
          desk_phone
          email_address
        }
      }
      carfax {
        badgeUrl
        reportUrl
      }
      lastAuctionStartedAt
      myBuyNowOffer(dealerId: $dealerId) {
        id
      }
      make
      model
      odometer
      trim
      trimVariation
      vin
      year
      locatedProvince
      ...BuyNowCardDetailsDialogAppraisal
    }
    ${BuyNowCardDetailsDialog.fragments.appraisal}
  `,
};

export default BuyNowCard;
