import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import { Box, Button, Container, Link, TextField } from '@mui/material';

import GoHeader from '../../../../components/GoHeader';
import { RESET_PASSWORD_URL } from 'constants.js';
import Support from '../Support';

import { useUserContext } from 'components/MaterialUI/UserContext';
import { URL } from '../../../../constants';

const Login = ({ location }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [hadIncorrectCreds, setHadIncorrectCreds] = useState(false);

  const { isLoggedIn, setToken } = useUserContext();

  const { from } = location.state || { from: { pathname: '/' } };

  useEffect(() => {
    const input = document.getElementsByTagName('input')[0];
    if (input) {
      input.setAttribute('autocapitalize', 'none');
    }
  }, []);

  const handleSubmit = e => {
    e.preventDefault();
    const body = new FormData();
    body.append('username', username);
    body.append('password', password);
    fetch(`${URL.auth}/jwt`, {
      method: 'POST',
      body,
    })
      .then(data => (data.status !== 200 ? Promise.reject() : data.json()))
      .then(res => setToken(res.access_token))
      .catch(() => {
        setHadIncorrectCreds(true);
        setLoading(false);
      });

    return false;
  };

  const isValid = username && password;

  if (isLoggedIn) return <Redirect to={from} />;

  return (
    <Container maxWidth="xs">
      <Box mt={4} textAlign="center">
        <GoHeader subtitle="Log in to your account" />
      </Box>
      <form noValidate onSubmit={handleSubmit}>
        <TextField
          label="Username"
          value={username}
          onChange={e => setUsername(e.target.value)}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="username"
          name="username"
          autoComplete="username"
          autoFocus
        />

        <TextField
          label="Password"
          type="password"
          value={password}
          onChange={e => setPassword(e.target.value)}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="password"
          name="password"
          autoComplete="password"
          error={hadIncorrectCreds}
          helperText={hadIncorrectCreds && 'Incorrect username or password.'}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={!isValid && !loading}
        >
          Log In
        </Button>
      </form>
      <Box mt={1} textAlign="center">
        <Link href={RESET_PASSWORD_URL} variant="body2">
          Forgot your password?
        </Link>
      </Box>
      <Box textAlign="center" mt={4}>
        <Support />
      </Box>
    </Container>
  );
};

export default Login;
