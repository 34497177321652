import React from 'react';

/* external */
import 'react-image-gallery/styles/css/image-gallery.css';
import gql from 'graphql-tag';
import ImageGallery from 'react-image-gallery';

/* Material UI */
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

/* internal */
import { cloudinaryCore } from 'utils';

const VehiclePhotos = ({ item }) => (
  <Paper>
    <Box padding={2}>
      <Typography variant="h5">Photos</Typography>
      {item.vehicle?.photos.length > 0 && (
        <ImageGallery
          items={item.vehicle.photos.map(({ cloudinaryPublicId }) => ({
            original: cloudinaryCore.url(cloudinaryPublicId, {
              width: 1050,
              height: 750,
              crop: 'pad',
            }),
            thumbnail: cloudinaryCore.url(cloudinaryPublicId, {
              width: 100,
              height: 100,
              crop: 'fill',
            }),
          }))}
        />
      )}
    </Box>
  </Paper>
);

VehiclePhotos.fragments = {
  item: gql`
    fragment VehiclePhotosItem on AuctionItem {
      vehicle {
        id
        photos {
          id
          cloudinaryPublicId
        }
      }
    }
  `,
};

export default VehiclePhotos;
