import { useMutation, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import { InlineEdit } from 'components/MaterialUI';
import Loading from 'components/MaterialUI/Loading';
import { formatOdometer } from 'utils';

const UPDATE_ODOMETER = gql`
  mutation recordOdometer(
    $dealerId: Int!
    $roNumber: String!
    $update: ROUpdate
  ) {
    rotracker {
      updateRO(dealer_id: $dealerId, ro_number: $roNumber, update: $update) {
        id
        recorded_odometer
      }
    }
  }
`;

const UPDATE_PROVINCE = gql`
  mutation previousProvince(
    $dealerId: Int!
    $roNumber: String!
    $update: ROUpdate
  ) {
    rotracker {
      updateRO(dealer_id: $dealerId, ro_number: $roNumber, update: $update) {
        id
        previous_province
      }
    }
  }
`;

const QUERY_CHECKLIST_PARAMS = gql`
  query ro($dealerId: Int!, $roNumber: String!) {
    rotracker {
      ro(dealer_id: $dealerId, ro_number: $roNumber, dart: true) {
        id
        previous_province
        recorded_odometer
        vehicle_odometer
      }
    }
  }
`;

const VehicleChecklistsHeaderTable = ({ ro, dealerId }) => {
  const { vin, recorded_odometer, previous_province, ro_number } = ro;
  const { enqueueSnackbar } = useSnackbar();
  const [updateRO, { loading }] = useMutation(UPDATE_ODOMETER);
  const [updateROProvince, { loading: savingProvince }] =
    useMutation(UPDATE_PROVINCE);
  const [previousProvince, setPreviousProvince] = useState(previous_province);
  const [recordedOdometer, setRecordedOdometer] = useState(recorded_odometer);
  const [vehicleOdometer, setVehicleOdometer] = useState(ro.vehicle_odometer);

  const { loading: isLoading } = useQuery(QUERY_CHECKLIST_PARAMS, {
    variables: {
      dealerId,
      roNumber: ro_number,
    },
    fetchPolicy: 'network-only',
    onCompleted: data => {
      setPreviousProvince(data?.rotracker?.ro?.previous_province);
      setRecordedOdometer(data?.rotracker?.ro?.recorded_odometer);
      setVehicleOdometer(data?.rotracker?.ro?.vehicle_odometer);
    },
  });

  const updateOdometer = useCallback(
    () => newValue => {
      updateRO({
        variables: {
          dealerId,
          roNumber: ro_number,
          update: { recorded_odometer: parseInt(newValue, 10) },
        },
      })
        .then(
          r => enqueueSnackbar('Updated recorded odometer'),
          setRecordedOdometer(newValue),
        )
        .catch(e => enqueueSnackbar('Error updating odometer'));
    },
    [dealerId, ro_number, updateRO, enqueueSnackbar],
  );

  const updateProvince = useCallback(
    () => newValue => {
      updateROProvince({
        variables: {
          dealerId,
          roNumber: ro_number,
          update: { previous_province: newValue },
        },
      })
        .then(
          r => enqueueSnackbar('Updated Previous Province of Registration'),
          setPreviousProvince(newValue),
        )
        .catch(e => enqueueSnackbar('Error updating province'));
    },
    [dealerId, ro_number, updateROProvince, enqueueSnackbar],
  );

  const validateOdometer = useCallback(value => {
    if (!/^\d+$/.test(value)) {
      return 'Please enter positive numbers only';
    }
  }, []);

  return (
    <Paper>
      <Container maxWidth="xl">
        <Box
          paddingTop={2}
          paddingBottom={2}
          style={{ fontSize: '18px', fontWeight: 'bold' }}
        >
          Vehicle Checklists
        </Box>
        <Grid
          container
          direction="row"
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
          xs={12}
        >
          <Grid item style={{ fontSize: '14px' }} xs={6}>
            <Box paddingBottom={1} paddingTop={1}>
              <b>Vehicle: </b>
              {`${ro.vehicle_year} ${ro.vehicle_make} ${ro.vehicle_model}`}
            </Box>
            <Box paddingBottom={1} paddingTop={1}>
              <b>VIN: </b>
              {ro.vin}
            </Box>
            <Box paddingBottom={1} paddingTop={1}>
              <b>Odometer: </b>
              {formatOdometer(vehicleOdometer)}
            </Box>
            <Box paddingBottom={1} paddingTop={1}>
              <b>Recorded Odometer: </b>
              <InlineEdit
                value={recordedOdometer ?? ''}
                id={`recorded_odometer_${vin}`}
                placeholder="Recorded Odometer"
                onSubmit={updateOdometer()}
                saving={loading}
                onValidate={validateOdometer}
                disabled={isLoading}
              />
            </Box>
            <Box paddingBottom={1} paddingTop={1}>
              <b>Previous Province: </b>
              <InlineEdit
                value={previousProvince}
                id={`previous_province_${vin}`}
                placeholder="Province"
                onSubmit={updateProvince()}
                saving={savingProvince}
                disabled={isLoading}
              />
            </Box>
            {isLoading && <Loading text="Fetching latest information" />}
          </Grid>
          <Grid item style={{ fontSize: '14px' }} xs={6}>
            <b>Service Advisor: </b>
            {`${ro.serviceAdvisor ? ro.serviceAdvisor.display_name : 'N/A'}`}
          </Grid>
        </Grid>
      </Container>
    </Paper>
  );
};

VehicleChecklistsHeaderTable.fragments = {
  ro: gql`
    fragment VehicleChecklistsHeaderTableRO on ROTrackerRO {
      vin
      recorded_odometer
      vehicle_odometer
      previous_province
      ro_number
    }
  `,
};

export default VehicleChecklistsHeaderTable;
