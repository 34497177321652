import gql from 'graphql-tag';

export const CREATE_BULK_SEND = gql`
  mutation createBulkSend(
    $gatewayId: Int!
    $name: String!
    $key: String!
    $template: String!
    $inactiveDays: Int
  ) {
    sms {
      createBulkSend(
        gatewayId: $gatewayId
        name: $name
        key: $key
        template: $template
        inactiveDays: $inactiveDays
      ) {
        id
      }
    }
  }
`;

export const EDIT_BULK_SEND = gql`
  mutation editBulkSend(
    $bulkSendId: Int!
    $template: String!
    $inactiveDays: Int
  ) {
    sms {
      editBulkSend(
        bulksendId: $bulkSendId
        template: $template
        inactiveDays: $inactiveDays
      ) {
        id
      }
    }
  }
`;

export const SCHEDULE_BULK_SEND = gql`
  mutation scheduleBulkSend($bulkSendId: Int!, $data: ScheduleBulkSendInput!) {
    sms {
      scheduleBulkSend(bulksendId: $bulkSendId, data: $data) {
        id
      }
    }
  }
`;

export const STOP_BULK_SEND = gql`
  mutation stopBulkSend($bulkSendId: Int!) {
    sms {
      stopBulkSend(bulksendId: $bulkSendId) {
        id
      }
    }
  }
`;

export const SPLIT_BULK_SEND = gql`
  mutation splitBulkSend($bulkSendId: Int!, $data: SplitBulkSendInput!) {
    sms {
      splitBulkSend(bulksendId: $bulkSendId, data: $data) {
        id
      }
    }
  }
`;
