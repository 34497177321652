import React from 'react';
import moment from 'moment';

import {
  Button,
  DialogActions,
  DialogTitle,
  DialogContent,
  Divider,
  ListItem,
  List,
  Grid,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { BULLETIN_STATUS_DISPLAY } from 'modules/customers/components/constant';
import { titleCase } from 'utils';

const useStyles = makeStyles(theme => ({
  caption: {
    fontSize: '12px',
    color: '#6c757d',
  },
}));

const ROBulletinsModal = ({ onClose, bulletins }) => {
  const classes = useStyles();
  return (
    <>
      <DialogTitle
        style={{ paddingBottom: 0, fontSize: '20px', fontWeight: 'bold' }}
      >
        Customer Bulletins
      </DialogTitle>
      <DialogContent>
        <List>
          {bulletins.length === 0 &&
            'There are no bulletins for this customer.'}
          {bulletins.map((bulletin, index) => {
            return (
              <>
                <ListItem
                  key={bulletin._id}
                  style={{ padding: '15px 0 20px 0' }}
                >
                  <Grid container direction="row">
                    <Grid item xs={12}>
                      <Typography
                        style={{ fontWeight: 'bold', paddingBottom: '5px' }}
                      >
                        {titleCase(BULLETIN_STATUS_DISPLAY[bulletin.type])}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="caption" className={classes.caption}>
                        {`Added: ${moment(bulletin.created).format(
                          'MMMM Do, YYYY',
                        )} by ${bulletin.created_by?.display_name}`}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography style={{ paddingTop: '5px' }}>
                        {bulletin.body}
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItem>
                {index < bulletins.length - 1 && (
                  <Divider style={{ border: '1px solid black' }} />
                )}
              </>
            );
          })}
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          style={{ color: 'black', backgroundColor: 'white' }}
          variant="outlined"
          autoFocus
        >
          Close
        </Button>
      </DialogActions>
    </>
  );
};

export default ROBulletinsModal;
