import PropTypes from 'prop-types';
import React from 'react';

import { CircularProgress } from '@mui/material';

const Loading = ({ text = 'Loading...' }) => (
  <div
    style={{
      display: 'flex',
      width: '100%',
      height: '100%',
      justifyContent: 'space-around',
      alignItems: 'center',
    }}
  >
    <span
      style={{
        display: 'flex',
        height: '100%',
        paddingTop: '2rem',
        paddingBottom: '2rem',
      }}
    >
      <CircularProgress />
      <div
        style={{
          fontSize: '20px',
          fontWeight: 'bold',
          display: 'flex',
          padding: '1rem 0 0 1rem',
        }}
      >
        {text}
      </div>
    </span>
  </div>
);

Loading.propTypes = {
  text: PropTypes.string,
};

export default Loading;
