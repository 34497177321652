/* External */
import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import moment from 'moment';

/* Material UI */
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';

import TextFieldControl from 'components/MaterialUI/TextFieldControl';
import RadioControl from 'components/MaterialUI/RadioControl2';
import RenderableRadioGroup from 'components/MaterialUI/RenderableRadioGroup';
import KeyboardDatePickerControl from 'components/MaterialUI/KeyboardDatePickerControl';

import { Add as AddIcon, Edit as EditIcon } from '@mui/icons-material';

/* Internal */
import { titleCase } from 'utils';
import { snackBarSettings } from 'constants.js';
import Loading from 'components/MaterialUI/Loading';

const ADD_AUTO_RESPONDER = gql`
  mutation AddAutoResponder($gatewayId: Int!, $data: AutoResponderInput!) {
    sms {
      addAutoResponder(gatewayId: $gatewayId, data: $data) {
        id
        pattern
        response
        campaignName
        campaignMedium
        campaignSource
        startDate
        endDate
        enabled
        trackingNumber
      }
    }
  }
`;

const UPDATE_AUTO_RESPONDER = gql`
  mutation UpdateAutoResponder(
    $gatewayId: Int!
    $autoresponderId: Int!
    $data: AutoResponderInput!
  ) {
    sms {
      updateAutoResponder(
        gatewayId: $gatewayId
        autoresponderId: $autoresponderId
        data: $data
      ) {
        id
        pattern
        response
        campaignName
        campaignMedium
        campaignSource
        startDate
        endDate
        enabled
        trackingNumber
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  h6: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
    marginBottom: '5px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
  titleLine: {
    borderBottom: '1px solid' + theme.colours.greys.grey3,
    marginBottom: '15px',
  },
  settingsContainer: {
    width: '80%',
    paddingBottom: '20px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  addButton: {
    backgroundColor: theme.actions.add.backgroundColor,
    color: theme.actions.add.color,
  },
  formLabel: {
    fontWeight: 'bold',
    color: 'black',
  },
  filterContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  filterRadioButtons: {
    flexDirection: 'row',
    marginLeft: '15px',
  },
  textField: {
    width: '90%',
    margin: '5px 0',
  },
}));

const headCells = [
  { id: 'trigger', label: 'Trigger', sort: true },
  { id: 'response', label: 'Response', sort: true },
  { id: 'name', label: 'Campaign Name', sort: true },
  { id: 'medium', label: 'Campaign Medium', sort: true },
  { id: 'source', label: 'Campaign Source', sort: true },
  { id: 'start', label: 'Start Date', sort: true },
  { id: 'end', label: 'End Date', sort: true },
  { id: 'enabled', label: 'Enabled', sort: false },
  { id: 'edit', label: '', sort: false },
];

const Sorts = {
  TRIGGER: (a, b) => a.pattern?.localeCompare(b.pattern ?? '') ?? -1,
  RESPONSE: (a, b) => a.response?.localeCompare(b.response ?? '') ?? -1,
  NAME: (a, b) => a.campaignName?.localeCompare(b.campaignName ?? '') ?? -1,
  MEDIUM: (a, b) =>
    a.campaignMedium?.localeCompare(b.campaignMedium ?? '') ?? -1,
  SOURCE: (a, b) =>
    a.campaignSource?.localeCompare(b.campaignSource ?? '') ?? -1,
  START: (a, b) => a.startDate?.localeCompare(b.startDate ?? '') ?? -1,
  END: (a, b) => a.endDate?.localeCompare(b.endDate ?? '') ?? -1,
};

const SORT_DIR = {
  1: 'asc',
  [-1]: 'desc',
};

const STATUS = {
  ENABLED: 'enabled',
  DISABLED: 'disabled',
  ALL: 'all',
};

const AutoResponders = ({ gateway, refetch }) => {
  const classes = useStyles();
  const [filterValue, setFilterValue] = useState(STATUS.ALL);
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState({});

  const [sortSelection, setSortSelection] = useState({
    sort: Sorts.TRIGGER,
    direction: 1,
  });

  const handleFilterChange = e => {
    setFilterValue(e.target.value);
  };

  const handleSortClick = sort => () =>
    sortSelection.sort === sort
      ? setSortSelection(({ direction }) => ({
          sort,
          direction: direction * -1,
        }))
      : setSortSelection({ sort, direction: 1 });

  const gatewayData = gateway?.sms.gateway;

  const autoResponders =
    gatewayData?.autoresponders
      ?.filter(autoResponder => {
        if (filterValue === STATUS.ALL) {
          return true;
        } else if (filterValue === STATUS.ENABLED) {
          return autoResponder.enabled;
        } else {
          return !autoResponder.enabled;
        }
      })
      .sort((a, b) => sortSelection.sort(a, b) * sortSelection.direction) ?? [];

  const [addAutoResponder, { loading: addLoading }] = useMutation(
    ADD_AUTO_RESPONDER,
    {
      onCompleted: () => {
        snackIt('Successfully added auto responder', {
          ...snackBarSettings,
          variant: 'success',
        });
        refetch();
        handleClose();
      },
      onError: error => {
        snackIt(error.message, { ...snackBarSettings, variant: 'error' });
      },
    },
  );

  const [updateAutoResponder, { loading: updateLoading }] = useMutation(
    UPDATE_AUTO_RESPONDER,
    {
      onCompleted: () => {
        snackIt('Successfully updated auto responder', {
          ...snackBarSettings,
          variant: 'success',
        });
        refetch();
        handleClose();
      },
    },
  );

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      pattern: null,
      response: null,
      campaignName: null,
      campaignMedium: null,
      campaignSource: null,
      startDate: null,
      endDate: null,
      enabled: false,
    },
  });
  const { enqueueSnackbar: snackIt } = useSnackbar();

  const EnabledRadioGroup = props => (
    <RenderableRadioGroup
      {...props}
      options={[
        { name: 'Enabled', value: 'true' },
        { name: 'Disabled', value: 'false' },
      ]}
    />
  );

  const handleClose = () => {
    setOpen(false);
    setIsEdit(false);
  };

  const formatDate = x =>
    x ? moment(x).utc().format('YYYY-MM-DDThh:mm:ssZ') : undefined;

  const onSubmit = ({ startDate, endDate, enabled, ...settings }) => {
    const input = {
      startDate: formatDate(startDate) ?? null,
      endDate: formatDate(endDate) ?? null,
      enabled: enabled === 'true',
      // set all other attributes to undefined if the value is null, so those fields are ignored in the mutation
      ...Object.fromEntries(
        Object.entries(settings).map(([key, value]) => [
          key,
          value === null ? undefined : value,
        ]),
      ),
    };
    isEdit
      ? updateAutoResponder({
          variables: {
            gatewayId: gatewayData.id,
            autoresponderId: editData.id,
            data: input,
          },
        })
      : addAutoResponder({
          variables: {
            gatewayId: gatewayData.id,
            data: input,
          },
        });
    handleClose();
  };

  useEffect(() => {
    if (isEdit) {
      reset({
        pattern: editData.pattern,
        response: editData.response,
        trackingNumber: editData.trackingNumber,
        campaignName: editData.campaignName,
        campaignMedium: editData.campaignMedium,
        campaignSource: editData.campaignSource,
        startDate: editData.startDate,
        endDate: editData.endDate,
        enabled: editData.enabled,
      });
    } else {
      reset({
        pattern: null,
        response: null,
        trackingNumber: null,
        campaignName: null,
        campaignMedium: null,
        campaignSource: null,
        startDate: null,
        endDate: null,
        enabled: false,
      });
    }
  }, [isEdit, editData, reset]);

  if (addLoading || updateLoading) return <Loading />;

  return (
    <>
      <Box>
        <Box className={classes.titleLine}>
          <Typography className={classes.h6}>Auto Responders</Typography>
        </Box>
        <Grid
          container
          spacing={2}
          className={classes.settingsContainer}
          alignItems="center"
        >
          <Grid item xs={2}>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              className={classes.addButton}
              onClick={() => setOpen(true)}
            >
              Add
            </Button>
          </Grid>
          <Grid item xs={10}>
            <FormControl className={classes.filterContainer}>
              <FormLabel className={classes.formLabel}>Filter:</FormLabel>
              <RadioGroup
                name="filters"
                value={filterValue}
                onChange={handleFilterChange}
                className={classes.filterRadioButtons}
              >
                <FormControlLabel value="all" control={<Radio />} label="All" />
                <FormControlLabel
                  value="enabled"
                  control={<Radio />}
                  label="Enabled"
                />
                <FormControlLabel
                  value="disabled"
                  control={<Radio />}
                  label="Disabled"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {headCells.map(headCell => (
                  <TableCell key={headCell.id}>
                    <TableSortLabel
                      active={sortSelection.sort === headCell.id}
                      direction={SORT_DIR[sortSelection.direction]}
                      onClick={handleSortClick(
                        Sorts[headCell.id.toUpperCase()],
                      )}
                      disabled={!headCell.sort}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {autoResponders.length === 0 && (
                <TableRow rowSpan={3}>
                  <TableCell align="center" colSpan={3}>
                    No auto responder data available.
                  </TableCell>
                </TableRow>
              )}
              {autoResponders.map((data, index) => (
                <TableRow key={index}>
                  <TableCell>
                    {/* trackingNumber or pattern is in the first cell in in Old SMS... */}
                    {data.pattern?.toUpperCase() ?? data.trackingNumber}
                  </TableCell>
                  <TableCell style={{ maxWidth: '200px' }}>
                    {data.response}
                  </TableCell>
                  <TableCell style={{ maxWidth: '200px' }}>
                    {titleCase(data.campaignName)}
                  </TableCell>
                  <TableCell>{titleCase(data.campaignMedium)}</TableCell>
                  <TableCell>{titleCase(data.campaignSource)}</TableCell>
                  <TableCell>
                    {data.startDate
                      ? moment(data.startDate).format('YYYY-MM-DD HH:mm')
                      : 'N/A'}
                  </TableCell>
                  <TableCell>
                    {data.endDate
                      ? moment(data.endDate).format('YYYY-MM-DD HH:mm')
                      : 'N/A'}
                  </TableCell>
                  <TableCell>{titleCase(data.enabled.toString())}</TableCell>
                  <TableCell>
                    <EditIcon
                      onClick={() => {
                        setIsEdit(true);
                        setEditData(data);
                        setOpen(true);
                      }}
                      style={{ cursor: 'pointer' }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{isEdit ? 'Edit' : 'Add'} Auto Responder</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextFieldControl
                control={control}
                name="pattern"
                label="Pattern"
                className={classes.textField}
                muiVariant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldControl
                control={control}
                name="trackingNumber"
                label="Tracking Number"
                className={classes.textField}
                muiVariant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldControl
                control={control}
                name="response"
                label="Response"
                className={classes.textField}
                muiVariant="outlined"
                multiline
                rules={{ required: 'Must enter a response.' }}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldControl
                control={control}
                name="campaignName"
                label="Campaign Name"
                className={classes.textField}
                muiVariant="outlined"
                rules={{ required: 'Must enter a campaign name.' }}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldControl
                control={control}
                name="campaignMedium"
                label="Campaign Medium"
                className={classes.textField}
                muiVariant="outlined"
                rules={{ required: 'Must enter a campaign medium.' }}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldControl
                control={control}
                name="campaignSource"
                label="Campaign Source"
                className={classes.textField}
                muiVariant="outlined"
                rules={{ required: 'Must enter a campaign source.' }}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <KeyboardDatePickerControl
                control={control}
                name="startDate"
                label="Start Date"
                className={classes.textField}
                muiVariant="outlined"
                datePickerProps={{
                  disablePast: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <KeyboardDatePickerControl
                control={control}
                name="endDate"
                label="End Date"
                className={classes.textField}
                muiVariant="outlined"
                datePickerProps={{
                  disablePast: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <RadioControl
                control={control}
                name="enabled"
                as={<EnabledRadioGroup />}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={handleSubmit(onSubmit)}
            variant="contained"
            color="primary"
            disabled={!isDirty}
          >
            {isEdit ? 'Save' : 'Add'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

AutoResponders.fragments = {
  gateway: gql`
    fragment AutoRespondersGatewayData on Gateway {
      autoresponders {
        id
        pattern
        response
        campaignName
        campaignMedium
        campaignSource
        startDate
        endDate
        enabled
        trackingNumber
      }
    }
  `,
};

export default AutoResponders;
