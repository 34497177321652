import React from 'react';

/* external */
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import gql from 'graphql-tag';

/* Material UI */
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  TextField,
} from '@mui/material';

/* internal */
import { errorHandler } from 'modules/pitches/utils';
import CheckboxControl from 'components/MaterialUI/CheckboxControl';
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';

const CREATE_DEALER_SETTINGS = gql`
  mutation createDealerSettings($dealerSettings: PitchDealerSettingInput!) {
    pitches {
      createDealerSettings(dealerSettings: $dealerSettings) {
        id
        optionalPitchSheets {
          id
          allowNewVehicles
          allowUsedVehicles
          pitchSheetDealerId
          pitchSheetDealer {
            name
          }
        }
      }
    }
  }
`;

const ADD_OPTIONAL_PITCH_SHEET = gql`
  mutation addOptionalPitchSheet(
    $dealerId: Int!
    $data: OptionalPitchSheetInput!
  ) {
    pitches {
      addOptionalPitchSheet(id: $dealerId, data: $data) {
        id
        optionalPitchSheets {
          id
          allowNewVehicles
          allowUsedVehicles
          pitchSheetDealerId
          pitchSheetDealer {
            name
          }
        }
      }
    }
  }
`;

const PitchSheetFormDialog = ({ dealerSettings, open, onClose }) => {
  const { dealerId, greaseDealers } = useDealerContext();
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit, setError } = useForm();
  const [createDealerSettings, { loading: createLoading }] = useMutation(
    CREATE_DEALER_SETTINGS,
  );
  const [addOptionalPitch, { loading: addLoading }] = useMutation(
    ADD_OPTIONAL_PITCH_SHEET,
    {
      onCompleted: data =>
        enqueueSnackbar('Successfully added additional pitch sheet!', {
          variant: 'success',
        }),
    },
  );

  const filterDealers = greaseDealers.filter(dealer => dealer.id !== dealerId);
  const dealerName = dealerSettings?.dealer?.name ?? '';

  const onSubmit = data =>
    dealerSettings?.id
      ? addOptionalPitch({ variables: { dealerId, data } }).then(
          onClose,
          errorHandler(enqueueSnackbar, setError),
        )
      : createDealerSettings({ variables: { dealerSettings: { dealerId } } })
          .then(() => addOptionalPitch({ variables: { dealerId, data } }))
          .then(onClose, errorHandler(enqueueSnackbar, setError));

  return (
    <Dialog open={open} onClose={onClose}>
      <LoadingBackdrop open={addLoading || createLoading}>
        Loading...
      </LoadingBackdrop>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Add Optional Pitch Sheet</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Select another dealer to add their pitch sheet to {dealerName}
          </DialogContentText>
          <CheckboxControl
            control={control}
            name="allowNewVehicles"
            label="Allow Pitch Sheet on New Vehicles"
          />
          <CheckboxControl
            control={control}
            name="allowUsedVehicles"
            label="Allow Pitch Sheet on Used Vehicles"
          />
          <br />
          <Controller
            control={control}
            defaultValue={null}
            name="pitchSheetDealerId"
            render={({
              field: { onChange, value, ...field },
              fieldState: { error },
            }) => (
              <TextField
                {...field}
                select
                error={Boolean(error)}
                helperText={error?.message}
                label="Dealer Pitch Sheet"
                value={value ?? ''}
                style={{ width: '350px' }}
                onChange={e =>
                  onChange(e.target.value === '' ? null : e.target.value)
                }
              >
                <MenuItem key={0} value="">
                  &nbsp;
                </MenuItem>
                {filterDealers.map(({ id, name }) => (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button type="submit">Save</Button>
          <Button onClick={onClose}>Cancel</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default PitchSheetFormDialog;
