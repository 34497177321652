import { useEffect } from 'react';
import moment from 'moment';

import { cloudinaryCore, titleCase, stringify } from 'utils';

const csvFields = [
  'id',
  'stockType',
  'stockNumber',
  'year',
  'make',
  'model',
  'trim',
  'vin',
  'interiorColourName',
  'msrp',
  'regularPrice',
  'cost',
  'specialPrice',
  'odometer',
  'exteriorColourName',
  'photoCount',
  'photos',
  'daysInStock',
  'isFeatured',
  'isOnSpecial',
];

const csvCustom = {
  id: { name: 'Vehicle ID' },
  vin: { name: 'VIN' },
  interiorColourName: { name: 'Int. Colour' },
  exteriorColourName: { name: 'Ext. Colour' },
  msrp: { name: 'MSRP' },
  photoCount: { name: 'Number of Photos' },
  photos: {
    name: 'Photo URL(s)',
    render: ({ photos }) =>
      `[${(
        photos?.map(({ cloudinaryPublicId }) =>
          cloudinaryCore.url(cloudinaryPublicId, {
            width: 600,
            height: 400,
          }),
        ) || []
      ).join(',')}]`,
  },
};

const csvHeader = csvFields.map(
  field => csvCustom[field]?.name || titleCase(field),
);

export const useCSVGenerator = (
  creatingCSV,
  setCreatingCSV,
  handleAnchorClose,
  selectByFilter,
  callBulkQuery,
  filters,
  selected,
  vehicles,
) => {
  useEffect(() => {
    const createCSV = async () => {
      let csvData = [];

      if (selectByFilter) {
        const { data } = await callBulkQuery({ filters });
        csvData = data.npv.getBulkVehicles;
      } else {
        csvData = vehicles.filter(({ id }) => selected.includes(id));
      }

      const rows = [[...csvHeader]].concat(
        csvData.map(v =>
          csvFields.map(field => {
            if (field === 'model' && v[field]) {
              return v[field].name;
            } else if (field === 'make' && v.model && v.model.make) {
              return v.model.make.name;
            } else if (field === 'year' && v.model && v.model.make) {
              return v.model.make.year;
            }
            return csvCustom[field]?.render?.(v) || v[field];
          }),
        ),
      );

      const csvContent =
        'data:text/csv;base64,' +
        btoa(decodeURIComponent(encodeURIComponent(stringify(rows))));
      const csvFileName = `inventory_export_${moment().format(
        'YYYYMMDD_HHmm',
      )}.csv`;

      const element = document.createElement('a');
      element.href = csvContent;
      element.download = csvFileName;
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);

      setCreatingCSV(false);
      handleAnchorClose();
    };

    if (creatingCSV) createCSV();
  }, [
    callBulkQuery,
    creatingCSV,
    filters,
    handleAnchorClose,
    selectByFilter,
    selected,
    setCreatingCSV,
    vehicles,
  ]);
};
