import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import PriceFilterField from './PriceFilterField';
import { useGroupVehiclesQueryContext } from '../contexts/GroupVehiclesQueryContext';

const propTypes = {
  model: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  options: PropTypes.shape({
    rangeInput: PropTypes.bool.isRequired,
  }).isRequired,
};

const GroupInventoryPriceFilter = props => {
  const { model, field, options } = props;
  const { facets, setFacets } = useGroupVehiclesQueryContext();
  const [inputErrors, setInputErrors] = useState({
    invalidRange: false,
  });
  const [range, setRange] = useState({
    min: facets.find(x => x.model === model && x.field === field)?.value?.min,
    max: facets.find(x => x.model === model && x.field === field)?.value?.max,
  });

  const createLabel = newValue => {
    let label = '';

    if (newValue?.min === undefined) label = `Price < $${newValue.max}`;
    else if (newValue?.max === undefined) label = `Price > $${newValue.min}`;
    else if (newValue.min === newValue.max) label = `Price: $${newValue.min}`;
    else label = `Price: $${newValue.min} to $${newValue.max}`;

    return label;
  };

  const mergeWithRange = (newValue, inputProperty) => {
    let mergedValue = newValue;

    if (inputProperty === 'max') {
      if (range?.min !== undefined)
        mergedValue = { ...newValue, ...{ min: range.min } };
    } else if (inputProperty === 'min') {
      if (range?.max !== undefined)
        mergedValue = { ...newValue, ...{ max: range.max } };
    }

    return mergedValue;
  };

  const mergeWithFacets = newInput => {
    setFacets(prev => {
      const match = prev.find(x => x.model === model && x.field === field);
      const inputProperty = newInput?.min !== undefined ? 'min' : 'max';
      let newValue = {};

      if (match) {
        newValue = { ...match.value, ...newInput };
        newValue = mergeWithRange(newValue, inputProperty);
      } else newValue = newInput;

      const newOptions = { ...options, ...{ label: createLabel(newValue) } };

      return [
        ...prev.filter(x => x.model !== model || x.field !== field),
        { model, field, value: newValue, options: newOptions },
      ];
    });
  };

  const removeValuePropertyFromFacets = property => {
    setFacets(prev => {
      const match = prev.find(x => x.model === model && x.field === field);
      let value;

      if (match && match.value?.[property] !== undefined) {
        ({ [property]: value } = match.value);
        value = mergeWithRange(value, property);

        if (isEmpty(value))
          return [...prev.filter(x => x.model !== model || x.field !== field)];
        else {
          const newOptions = { ...options, ...{ label: createLabel(value) } };

          return [
            ...prev.filter(x => x.model !== model || x.field !== field),
            { model, field, value, options: newOptions },
          ];
        }
      } else return prev;
    });
  };

  const updateRangeValues = inputValue => {
    const currentMin = range?.min;
    const currentMax = range?.max;
    const parsedInputMin = parseInt(inputValue.min);
    const parsedInputMax = parseInt(inputValue.max);

    if (inputValue?.min !== undefined) {
      if (inputValue.min === '') {
        setRange(prev => {
          const { min, ...newValue } = prev;
          return newValue;
        });
        removeValuePropertyFromFacets('min');
        setInputErrors(prev => ({ ...prev, invalidRange: false }));
      } else if (currentMax !== undefined && parsedInputMin > currentMax) {
        // Invalid range, don't change facets
        setRange(prev => ({ ...prev, ...{ min: parsedInputMin } }));
        setInputErrors(prev => ({ ...prev, invalidRange: true }));
      } else {
        setRange(prev => ({ ...prev, ...{ min: parsedInputMin } }));
        mergeWithFacets({ min: parsedInputMin });
        setInputErrors(prev => ({ ...prev, invalidRange: false }));
      }
    } else if (inputValue?.max !== undefined) {
      if (inputValue.max === '') {
        setRange(prev => {
          const { max, ...newValue } = prev;
          return newValue;
        });
        removeValuePropertyFromFacets('max');
        setInputErrors(prev => ({ ...prev, invalidRange: false }));
      } else if (currentMin !== undefined && currentMin > parsedInputMax) {
        // Invalid range, don't change facets
        setRange(prev => ({ ...prev, ...{ max: parsedInputMax } }));
        setInputErrors(prev => ({ ...prev, invalidRange: true }));
      } else {
        setRange(prev => ({ ...prev, ...{ max: parsedInputMax } }));
        mergeWithFacets({ max: parsedInputMax });
        setInputErrors(prev => ({ ...prev, invalidRange: false }));
      }
    }
  };

  return (
    <Box>
      <Grid container spacing={3} justifyContent="space-evenly">
        <Grid item xs={5}>
          <PriceFilterField
            defaultValue={range?.min || ''}
            error={inputErrors.invalidRange}
            helperText={inputErrors.invalidRange ? 'Invalid Range' : ''}
            label="From"
            onChange={e => updateRangeValues({ min: e.target.value })}
            placeholder="min"
          />
        </Grid>
        <Grid item xs={2} container alignItems="flex-end">
          <Grid item>
            <Typography>to</Typography>
          </Grid>
        </Grid>
        <Grid item xs={5}>
          <PriceFilterField
            defaultValue={range?.max || ''}
            error={inputErrors.invalidRange}
            helperText={inputErrors.invalidRange ? 'Invalid Range' : ''}
            label="To"
            onChange={e => updateRangeValues({ max: e.target.value })}
            placeholder="max"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

GroupInventoryPriceFilter.propTypes = propTypes;

export default GroupInventoryPriceFilter;
