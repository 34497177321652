import React from 'react';
import { useFormContext } from 'react-hook-form';

import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import RadioControl from 'components/MaterialUI/RadioControl2';
import TextFieldControl from 'components/MaterialUI/TextFieldControl';

import { CBB_CONDITIONS } from 'modules/inventory/const';
import { titleCase } from 'modules/inventory/utils';

import { formatPrice } from 'utils';

import { usePermissionsContext } from '../../contexts/PermissionsContext';
import {
  CBBConditionRadioGroup,
  CBBStyleRadioGroup,
  CBBTrimRadioGroup,
} from './CBBRadioGroups';

const useStyles = makeStyles(theme => ({
  expansionPanel: {
    paddingBottom: '0.5rem',
  },
}));

const CBBDetailsMobile = ({
  equipmentSelections,
  getPriceAdjustments,
  getRetailPrice,
  getRetailTotal,
  getWholesalePrice,
  getWholesaleTotal,
  handleEquipment,
  selectedCondition,
  selectedEquipment,
  styleNames,
  trimNames,
}) => {
  const {
    formState: { errors },
    control,
  } = useFormContext();
  const { update: canUpdate } = usePermissionsContext();
  const { expansionPanel } = useStyles();

  return (
    <Paper style={{ width: '100%', paddingBottom: '2rem' }}>
      <Container>
        <Typography style={{ padding: '1rem 0 1rem 0' }} variant="h6">
          CBB Pricing
        </Typography>
        <Typography style={{ padding: '1rem 0 1rem 0', fontSize: '12px' }}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs container direction="column">
              <Grid item xs={12}>
                <div>
                  <b>Condition:</b>
                </div>
                <div>
                  <b>Total Adjustments:</b>
                </div>
                <div>
                  <b>Wholesale/Retail:</b>
                </div>
              </Grid>
            </Grid>
            <Grid item xs container direction="column">
              <Grid item xs={12}>
                <div>{titleCase(selectedCondition)}</div>
                <div>{formatPrice(getPriceAdjustments(selectedCondition))}</div>
                <div>
                  {formatPrice(getWholesalePrice(selectedCondition))}/
                  {formatPrice(getRetailPrice(selectedCondition))}
                </div>
              </Grid>
            </Grid>
          </Grid>
          <hr />
          <Grid container direction="row" spacing={2}>
            <Grid item xs container direction="column">
              <Grid item xs={12}>
                <div>
                  <b>Total Wholesale:</b>
                </div>
                <div>
                  <b>Total Retail:</b>
                </div>
              </Grid>
            </Grid>
            <Grid item xs container direction="column">
              <Grid item xs={12}>
                <div>
                  <b>{formatPrice(getWholesaleTotal(selectedCondition))}</b>
                </div>
                <div>
                  <b>{formatPrice(getRetailTotal(selectedCondition))}</b>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Typography>
        <Grid container>
          <Grid item xs={12} className={expansionPanel}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                Trim
              </AccordionSummary>
              <AccordionDetails>
                <RadioControl
                  RadioProps={{ style: { color: 'secondary' } }}
                  name="cbb.trim"
                  as={
                    <CBBTrimRadioGroup
                      trimNames={trimNames}
                      style={{ display: 'flex', flexDirection: 'column' }}
                    />
                  }
                  errors={errors.cbb?.trim}
                  disabled={!canUpdate}
                />
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12} className={expansionPanel}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                Style
              </AccordionSummary>
              <AccordionDetails>
                <RadioControl
                  RadioProps={{ style: { color: 'secondary' } }}
                  as={
                    <CBBStyleRadioGroup
                      styleNames={styleNames}
                      style={{ display: 'flex', flexDirection: 'column' }}
                    />
                  }
                  errors={errors.cbb?.style}
                  name="cbb.style"
                  disabled={!canUpdate}
                />
                <TextFieldControl
                  control={control}
                  name="cbb.vid"
                  style={{ display: 'none' }}
                />
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12} className={expansionPanel}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                Equipment
              </AccordionSummary>
              <AccordionDetails>
                <FormGroup>
                  {equipmentSelections.map(({ option_code, description }) => (
                    <FormControlLabel
                      key={`${option_code}`}
                      label={description}
                      control={
                        <Checkbox
                          checked={selectedEquipment.includes(option_code)}
                          disabled={!canUpdate}
                          onChange={handleEquipment}
                          value={option_code}
                        />
                      }
                    />
                  ))}
                </FormGroup>
                <TextFieldControl
                  control={control}
                  name="cbb.equipment"
                  style={{ display: 'none' }}
                />
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12} className={expansionPanel}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                Condition
              </AccordionSummary>
              <AccordionDetails>
                <RadioControl
                  RadioProps={{ style: { color: 'secondary' } }}
                  as={
                    <CBBConditionRadioGroup
                      style={{ display: 'flex', flexDirection: 'column' }}
                    />
                  }
                  errors={errors.cbb?.condition}
                  name="cbb.condition"
                  disabled={!canUpdate}
                />
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                Wholesale/Retail Pricing
              </AccordionSummary>
              <AccordionDetails>
                <Grid item container xs={12}>
                  {/* Wholesale Table */}
                  <Grid item xs={12} style={{ paddingBottom: '2rem' }}>
                    <TableContainer xs={12}>
                      <Table xs={12}>
                        <TableHead>
                          <TableRow>
                            <TableCell>Wholesale</TableCell>
                            <TableCell rowSpan={1} />
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {CBB_CONDITIONS.map(key => (
                            <TableRow
                              key={key}
                              selected={selectedCondition === key}
                            >
                              <TableCell
                                style={
                                  selectedCondition === key
                                    ? { fontWeight: 'bold' }
                                    : {}
                                }
                              >
                                {titleCase(key)}
                              </TableCell>
                              <TableCell>
                                {formatPrice(getWholesaleTotal(key))}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>

                  {/* Retail Table */}
                  <Grid item xs={12} style={{ paddingBottom: '1rem' }}>
                    <Table xs={12}>
                      <TableHead>
                        <TableRow>
                          <TableCell>Retail</TableCell>
                          <TableCell rowSpan={1} />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {CBB_CONDITIONS.map(key => (
                          <TableRow
                            key={key}
                            selected={selectedCondition === key}
                          >
                            <TableCell
                              style={
                                selectedCondition === key
                                  ? { fontWeight: 'bold' }
                                  : {}
                              }
                            >
                              {titleCase(key)}
                            </TableCell>
                            <TableCell>
                              {formatPrice(getRetailTotal(key))}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Container>
    </Paper>
  );
};

export default CBBDetailsMobile;
