import { makeStyles } from '@mui/styles';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import React from 'react';

const useStyles = makeStyles(theme => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const headCells = [
  {
    id: 'action',
    numeric: false,
    disablePadding: false,
    label: 'Action',
    sortable: false,
    style: null,
  },
  {
    id: 'appointment_time',
    numeric: false,
    disablePadding: false,
    label: 'Scheduled For',
    sortable: true,
    style: { minWidth: '140px' },
  },
  {
    id: 'customer_name',
    numeric: false,
    disablePadding: false,
    label: 'Customer',
    sortable: true,
    style: null,
  },
  {
    id: 'gocard',
    numeric: false,
    disablePadding: false,
    label: 'Go Card',
    sortable: false,
    style: null,
  },
  {
    id: 'vin',
    numeric: false,
    disablePadding: false,
    label: 'Vehicle',
    sortable: false,
    style: null,
  },
  {
    id: 'ro.cdk_service_advisor_display_name',
    numeric: false,
    disablePadding: false,
    label: 'Service Advisor',
    sortable: true,
    style: { minWidth: '200px' },
  },
  {
    id: 'request',
    numeric: false,
    disablePadding: false,
    label: 'Request',
    sortable: false,
    style: null,
  },
];

const AppointmentsTableHeader = ({ order, orderBy, onRequestSort }) => {
  const { visuallyHidden } = useStyles();
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align="left"
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}
            style={headCell.style}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              disabled={!headCell.sortable}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default AppointmentsTableHeader;
