import React from 'react';

/* external */
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import gql from 'graphql-tag';

/* Material UI */
import { Button, Typography } from '@mui/material';

/* internal */
import { errorHandler } from '../utils';

const SET_APPRAISAL_IS_OWNED = gql`
  mutation ownAppraisal($id: Int!) {
    appraisals {
      ownAppraisal(id: $id) {
        id
        appraisalStatus
      }
    }
  }
`;

const Own = ({ appraisal }) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [setAppraisalIsOwned] = useMutation(SET_APPRAISAL_IS_OWNED);

  const { id } = appraisal;

  const handleClickBid = () => history.push('/used-vehicles');
  const handleClickOwn = () => {
    setAppraisalIsOwned({ variables: { id } }).then(
      () => {
        enqueueSnackbar('Set appraisal to "Owned" status', {
          variant: 'success',
        });
      },
      err => errorHandler(enqueueSnackbar, () => {})(err),
    );
  };
  return (
    <div style={{ padding: '1rem' }}>
      <Typography variant="h6">
        This vehicle is in bidding status. Do you want to change it to owned?
      </Typography>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '75%',
          marginLeft: 'auto',
          marginRight: 'auto',
          marginTop: '1rem',
          marginBottom: '1rem',
        }}
      >
        <Button variant="contained" color="primary" onClick={handleClickBid}>
          KEEP AS BID
        </Button>
        <Typography variant="h6">OR</Typography>
        <Button variant="contained" color="primary" onClick={handleClickOwn}>
          OWN
        </Button>
      </div>
    </div>
  );
};

export default Own;
