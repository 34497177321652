import React, { useRef, useState } from 'react';
import { withStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import { cloneDeep } from 'lodash';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import InspectionCheckboxes from '../InspectionCheckboxes';

const KiaCPOForm = props => {
  const { enqueueSnackbar } = useSnackbar();

  // KiaCPO form fields
  const visual = {
    k1: 'Check vehicle odometer against service records for accuracy',
    k2: 'Hood release operational',
    k3: 'Valve cover gaskets and head gaskets in good condition',
    k4: 'Condition / Tension of accessory drive belts',
    k5: 'Turbo / Supercharger no abnormal noises or leaks',
    k6: 'Inspect ignition system wires',
    k7: 'Check power steering fluid level',
    k8: 'Check washer fluid level',
    k9: 'Check vacuum system condition',
    k10: 'Check air filter',
    k11: 'Perform oil change',
    k12: 'Check battery and terminals',
  };
  const performance = {
    k13: 'Engine mounts',
    k14: 'Driveability / Hesitation / Stalling / Idle Condition',
    k15: 'Fault codes',
    k16: 'Engine noise (belt squaks, exhaust leaks, internal engine noises)',
    k17: 'Compression test / Leak down test',
  };
  const transmission = {
    k18: 'Check fluid levels and condition',
    k19: 'Slipping or erratic /. Harsh shifting',
    k20: 'Noise',
    k21: 'Transmission mounts',
    k22: 'Check Seals and Gaskets for leaks',
    k23: 'Automatic transmission — Starter operates only in "Park" or "Neutral"',
    k24: 'Manual transmission — Starter operates only when clutch pedal engaged',
    k25: 'Drive shafts, axle shafts',
    k26: 'VC boots / Joints / universal joints',
    k27: 'Clutch operation',
    k28: 'Clutch linkage / Adjustment',
    k29: '4 x 4, All-wheel drive operation',
    k30: 'Shift linkage',
  };
  const cooling = {
    k31: 'Water pump / Thermostat',
    k32: 'Engine cooling fan',
    k33: 'Belts & hoses',
    k34: 'Check radiator for leaks / Blockage / Debris',
    k35: 'Radiator cap',
    k36: 'Coolant condition and strength',
    k37: 'Coolant level',
    k38: 'Pressure test system for leaks',
  };
  const brakes = {
    k39: 'Check brake fluid level / Condition',
    k40: 'Check brake rotor / Drum surfaces',
    k41: 'Front brake pads / Shoes % remaining',
    k42: 'Rear brake pads / Shoes % remaining',
    k43: 'Parking brake operation',
    k44: 'Anti-lock brake system operation',
    k45: 'Lines / Fittings / Hoses / Hardware',
    k46: 'Calipers / Wheel cylinders',
    k47: 'Master cylinder',
  };
  const suspension = {
    k48: 'Check control arms / Ball joints',
    k49: 'Check wheel bearings / Spindles',
    k50: 'Check torsion bars / Radius arms',
    k51: 'Check stabilizer shaft and links',
    k52: 'Check electronic / Air suspension',
  };
  const seats = {
    k53: 'Check seats for wear, rips, or stains',
    k54: 'Seat tracks operational',
    k55: 'Check adjustable seats',
    k56: 'Folding rear seats operational',
    k57: 'Seat belts operational',
    k58: 'Shoulder adjustments operational',
    k59: 'Check child seat latches',
    k60: 'Seat heaters',
  };
  const trim = {
    k61: 'Check for wear, rips, or stains',
    k62: 'Sun visors operational / Lighting / Mirrors',
    k63: 'Check ashtray for broken or melted components',
    k64: 'Glove box operational / Lock',
    k65: 'Console lock / Lid',
  };
  const system = {
    k66: 'ABS system warning light',
    k67: 'Check engine / ML',
    k68: 'SRS system warning light',
    k69: 'Traction control system',
    k70: 'Oil / Charging / Fuel, etc.',
  };
  const sunroof = {
    k71: 'Sunroof operational',
    k72: 'Check for leaks',
    k73: 'Check for cracks and chips',
  };
  const hvac = {
    k74: 'All vents / Modes operational',
    k75: 'Blower motor',
    k76: 'Compressor clutch (noise)',
    k77: 'Idler pulley(s)',
    k78: 'Check climate control dials',
    k79: 'Check heater / AC performance',
    k80: 'Ecaporator drain',
    k81: 'Condenser free of damage / Blockage, etc.',
  };
  const electrical = {
    k82: 'Charging system — AVR Test (over 100,000 km)',
    k83: 'Starting system — AVR Test (over 100,000 km)',
    k84: 'Remote starter',
    k85: 'Cruise control',
    k86: 'Speedometer',
    k87: 'Tilt steering',
    k88: 'Front & Rear wiper / Controls',
    k89: 'Rear window defroster',
    k90: 'Horns',
    k91: 'Radio / CD player',
    k92: 'DVD player',
    k93: 'Navigation system',
    k94: 'Steering wheel controls',
    k95: 'Headlamps & fog lamps',
    k96: 'High beams',
    k97: 'Parking lamps',
    k98: 'Brake lamps',
    k99: 'Turn signal lamps',
    k100: 'Hazard lights',
    k101: 'Night time automatic head lights operational',
    k102: 'Cigarette lighter',
    k103: 'Fuel door release',
    k104: 'Hood latch release',
    k105: 'Trunk latch release',
    k106: 'Power windows',
    k107: 'Power mirrors',
    k108: 'Power locks',
    k109: 'AC Power adapter outlets',
    k110: 'Keyless entry remote',
  };
  const doors = {
    k111: 'Check door locks',
    k112: 'Check interior door handles for damage',
    k113: 'Check weather stripping',
    k114: 'Door hinges and bushings',
  };
  const trunk = {
    k115: 'Check trunk for wear, rips, or stains',
    k116: 'Trunk opens and closes smoothly',
    k117: 'Check sparer tire condition / Air pressure',
  };
  const appearance = {
    k118: 'Check antenna',
    k119: 'Check all vertical and horizontal panels for excessive dents, scratches, and paint chips',
    k120: 'Check condition of front and rear bumpers',
    k121: 'Check all glass for chips, cracks, and scratches',
    k122: 'Check side mirror for chips, cracks and scratches',
    k123: 'Tires meet 4/32” minimum tread depth requirement',
    k124: 'Check wheels for excessive chips or scratches',
    k125: 'Correct tire size as per manufacturer specifications',
    k126: 'Exhaust system hung properly',
    k127: 'Vehicle shows no signs of structural damage',
  };
  const modifications = {
    k128: 'Engine',
    k129: 'Exhaust',
    k130: 'Suspension',
    k131: 'Emissions equipment',
    k132: 'Electrical / Remote starter',
  };
  const final = {
    k133: 'Vehicle is washed and interior is vacuumed',
    k134: 'Engine compartment has been cleared and degreased',
    k135: 'Road test, and verify wheel alignment',
  };

  let visualObj = cloneDeep(visual);
  let performanceObj = cloneDeep(performance);
  let transmissionObj = cloneDeep(transmission);
  let coolingObj = cloneDeep(cooling);
  let brakesObj = cloneDeep(brakes);
  let suspensionObj = cloneDeep(suspension);
  let seatsObj = cloneDeep(seats);
  let trimObj = cloneDeep(trim);
  let systemObj = cloneDeep(system);
  let sunroofObj = cloneDeep(sunroof);
  let hvacObj = cloneDeep(hvac);
  let electricalObj = cloneDeep(electrical);
  let doorsObj = cloneDeep(doors);
  let trunkObj = cloneDeep(trunk);
  let appearanceObj = cloneDeep(appearance);
  let modificationsObj = cloneDeep(modifications);
  let finalObj = cloneDeep(final);

  Object.entries(visualObj).map(([name]) => (visualObj[name] = 'PASS'));
  Object.entries(performanceObj).map(
    ([name]) => (performanceObj[name] = 'PASS'),
  );
  Object.entries(transmissionObj).map(
    ([name]) => (transmissionObj[name] = 'PASS'),
  );
  Object.entries(coolingObj).map(([name]) => (coolingObj[name] = 'PASS'));
  Object.entries(brakesObj).map(([name]) => (brakesObj[name] = 'PASS'));
  Object.entries(suspensionObj).map(([name]) => (suspensionObj[name] = 'PASS'));
  Object.entries(seatsObj).map(([name]) => (seatsObj[name] = 'PASS'));
  Object.entries(trimObj).map(([name]) => (trimObj[name] = 'PASS'));
  Object.entries(systemObj).map(([name]) => (systemObj[name] = 'PASS'));
  Object.entries(sunroofObj).map(([name]) => (sunroofObj[name] = 'PASS'));
  Object.entries(hvacObj).map(([name]) => (hvacObj[name] = 'PASS'));
  Object.entries(electricalObj).map(([name]) => (electricalObj[name] = 'PASS'));
  Object.entries(doorsObj).map(([name]) => (doorsObj[name] = 'PASS'));
  Object.entries(trunkObj).map(([name]) => (trunkObj[name] = 'PASS'));
  Object.entries(appearanceObj).map(([name]) => (appearanceObj[name] = 'PASS'));
  Object.entries(modificationsObj).map(
    ([name]) => (modificationsObj[name] = 'PASS'),
  );
  Object.entries(finalObj).map(([name]) => (finalObj[name] = 'PASS'));

  const [visualValues, setVisualValues] = useState(visualObj);
  const [performanceValues, setPerformanceValues] = useState(performanceObj);
  const [transmissionValues, setTransmissionValues] = useState(transmissionObj);
  const [coolingValues, setCoolingValues] = useState(coolingObj);
  const [brakesValues, setBrakesValues] = useState(brakesObj);
  const [suspensionValues, setSuspensionValues] = useState(suspensionObj);
  const [seatsValues, setSeatsValues] = useState(seatsObj);
  const [trimValues, setTrimValues] = useState(trimObj);
  const [systemValues, setSystemValues] = useState(systemObj);
  const [sunroofValues, setSunroofValues] = useState(sunroofObj);
  const [hvacValues, setHvacValues] = useState(hvacObj);
  const [electricalValues, setElectricalValues] = useState(electricalObj);
  const [doorsValues, setDoorsValues] = useState(doorsObj);
  const [trunkValues, setTrunkValues] = useState(trunkObj);
  const [appearanceValues, setAppearanceValues] = useState(appearanceObj);
  const [modificationsValues, setModificationsValues] =
    useState(modificationsObj);
  const [finalValues, setFinalValues] = useState(finalObj);

  const handleVisual = e => {
    const { name, value } = e.target;
    setVisualValues(prevVisualValues => ({
      ...prevVisualValues,
      [name]: value,
    }));
  };
  const handlePerformance = e => {
    const { name, value } = e.target;
    setPerformanceValues(prevPerformanceValues => ({
      ...prevPerformanceValues,
      [name]: value,
    }));
  };
  const handleTransmission = e => {
    const { name, value } = e.target;
    setTransmissionValues(prevTransmissionValues => ({
      ...prevTransmissionValues,
      [name]: value,
    }));
  };
  const handleCooling = e => {
    const { name, value } = e.target;
    setCoolingValues(prevCoolingValues => ({
      ...prevCoolingValues,
      [name]: value,
    }));
  };
  const handleBrakes = e => {
    const { name, value } = e.target;
    setBrakesValues(prevBrakesValues => ({
      ...prevBrakesValues,
      [name]: value,
    }));
  };
  const handleSuspension = e => {
    const { name, value } = e.target;
    setSuspensionValues(prevSuspensionValues => ({
      ...prevSuspensionValues,
      [name]: value,
    }));
  };
  const handleSeats = e => {
    const { name, value } = e.target;
    setSeatsValues(prevSeatsValues => ({
      ...prevSeatsValues,
      [name]: value,
    }));
  };
  const handleTrim = e => {
    const { name, value } = e.target;
    setTrimValues(prevTrimValues => ({
      ...prevTrimValues,
      [name]: value,
    }));
  };
  const handleSystem = e => {
    const { name, value } = e.target;
    setSystemValues(prevSystemValues => ({
      ...prevSystemValues,
      [name]: value,
    }));
  };
  const handleSunroof = e => {
    const { name, value } = e.target;
    setSunroofValues(prevSunroofValues => ({
      ...prevSunroofValues,
      [name]: value,
    }));
  };
  const handleHvac = e => {
    const { name, value } = e.target;
    setHvacValues(prevHvacValues => ({
      ...prevHvacValues,
      [name]: value,
    }));
  };
  const handleElectrical = e => {
    const { name, value } = e.target;
    setElectricalValues(prevElectricalValues => ({
      ...prevElectricalValues,
      [name]: value,
    }));
  };
  const handleDoors = e => {
    const { name, value } = e.target;
    setDoorsValues(prevDoorsValues => ({
      ...prevDoorsValues,
      [name]: value,
    }));
  };
  const handleTrunk = e => {
    const { name, value } = e.target;
    setTrunkValues(prevTrunkValues => ({
      ...prevTrunkValues,
      [name]: value,
    }));
  };
  const handleAppearance = e => {
    const { name, value } = e.target;
    setAppearanceValues(prevAppearanceValues => ({
      ...prevAppearanceValues,
      [name]: value,
    }));
  };
  const handleModifications = e => {
    const { name, value } = e.target;
    setModificationsValues(prevModificationsValues => ({
      ...prevModificationsValues,
      [name]: value,
    }));
  };
  const handleFinal = e => {
    const { name, value } = e.target;
    setFinalValues(prevFinalValues => ({
      ...prevFinalValues,
      [name]: value,
    }));
  };

  const [consent, setConsent] = useState(false);
  const handleConsent = () => {
    setConsent(consent => !consent);
  };

  const [confirm, setConfirm] = useState(false);
  const handleConfirm = () => {
    setConfirm(confirm => !confirm);
  };

  const wholeForm = useRef(null);

  const handleSubmit = e => {
    e.preventDefault();

    if (!wholeForm.current['amvic_number'].value) {
      enqueueSnackbar(`Please fill out 'Tech #'.`);
      return;
    }

    if (!consent) {
      enqueueSnackbar(`Please check 'I agree' and then submit the inspection!`);
      return;
    }

    const values = {
      ...visualValues,
      ...performanceValues,
      ...transmissionValues,
      ...coolingValues,
      ...brakesValues,
      ...suspensionValues,
      ...seatsValues,
      ...trimValues,
      ...systemValues,
      ...sunroofValues,
      ...hvacValues,
      ...electricalValues,
      ...doorsValues,
      ...trunkValues,
      ...appearanceValues,
      ...modificationsValues,
      ...finalValues,
    };
    const form_data = {
      kia: values,
      technician_comments: wholeForm.current['technician_comments'].value,
      amvic_number: wholeForm.current['amvic_number'].value,
    };

    props.formData(form_data);
    props.formClose();
  };

  const GreenButton = withStyles({
    root: {
      color: '#fff',
      backgroundColor: '#198754',
      '&:hover': {
        backgroundColor: '#157347',
      },
    },
  })(Button);

  return (
    <form onSubmit={handleSubmit} ref={wholeForm}>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Engine: Visual</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(visual).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    <Typography>{display_name}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={visualValues}
                      handleGroup={handleVisual}
                      name={name}
                      orange={false}
                      white
                      greenValue="PASS"
                      redValue="REPAIR"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Engine: Performance</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(performance).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    <Typography>{display_name}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={performanceValues}
                      handleGroup={handlePerformance}
                      name={name}
                      orange={false}
                      white
                      greenValue="PASS"
                      redValue="REPAIR"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Transmission / Drivetrain</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(transmission).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    <Typography>{display_name}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={transmissionValues}
                      handleGroup={handleTransmission}
                      name={name}
                      orange={false}
                      white
                      greenValue="PASS"
                      redValue="REPAIR"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Cooling Systems</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(cooling).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    <Typography>{display_name}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={coolingValues}
                      handleGroup={handleCooling}
                      name={name}
                      orange={false}
                      white
                      greenValue="PASS"
                      redValue="REPAIR"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Brakes</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(brakes).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    <Typography>{display_name}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={brakesValues}
                      handleGroup={handleBrakes}
                      name={name}
                      orange={false}
                      white
                      greenValue="PASS"
                      redValue="REPAIR"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Suspension</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(suspension).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    <Typography>{display_name}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={suspensionValues}
                      handleGroup={handleSuspension}
                      name={name}
                      orange={false}
                      white
                      greenValue="PASS"
                      redValue="REPAIR"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Seats</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(seats).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    <Typography>{display_name}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={seatsValues}
                      handleGroup={handleSeats}
                      name={name}
                      orange={false}
                      white
                      greenValue="PASS"
                      redValue="REPAIR"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Trim</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(trim).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    <Typography>{display_name}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={trimValues}
                      handleGroup={handleTrim}
                      name={name}
                      orange={false}
                      white
                      greenValue="PASS"
                      redValue="REPAIR"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>System Warning</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(system).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    <Typography>{display_name}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={systemValues}
                      handleGroup={handleSystem}
                      name={name}
                      orange={false}
                      white
                      greenValue="PASS"
                      redValue="REPAIR"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Sunroof</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(sunroof).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    <Typography>{display_name}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={sunroofValues}
                      handleGroup={handleSunroof}
                      name={name}
                      orange={false}
                      white
                      greenValue="PASS"
                      redValue="REPAIR"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>HVAC System</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(hvac).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    <Typography>{display_name}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={hvacValues}
                      handleGroup={handleHvac}
                      name={name}
                      orange={false}
                      white
                      greenValue="PASS"
                      redValue="REPAIR"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Electrical</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(electrical).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  {name !== 'k110' && (
                    <Grid container alignItems="center">
                      <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                        <Typography>{display_name}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <InspectionCheckboxes
                          valueGroup={electricalValues}
                          handleGroup={handleElectrical}
                          name={name}
                          orange={false}
                          white
                          greenValue="PASS"
                          redValue="REPAIR"
                        />
                      </Grid>
                    </Grid>
                  )}
                  {name === 'k110' && (
                    <Grid
                      container
                      alignItems="center"
                      style={{ marginBottom: '1rem' }}
                    >
                      <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                        <Typography>{display_name}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={electricalValues[name] === 'PASS'}
                              onChange={handleElectrical}
                              value="PASS"
                              name={name}
                            />
                          }
                          label="2"
                          labelPlacement="start"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={electricalValues[name] === 'REPAIR'}
                              onChange={handleElectrical}
                              value="REPAIR"
                              name={name}
                            />
                          }
                          label="1"
                          labelPlacement="start"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={electricalValues[name] === 'na'}
                              onChange={handleElectrical}
                              value="na"
                              name={name}
                            />
                          }
                          label="0"
                          labelPlacement="start"
                        />
                      </Grid>
                    </Grid>
                  )}
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Doors</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(doors).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    <Typography>{display_name}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={doorsValues}
                      handleGroup={handleDoors}
                      name={name}
                      orange={false}
                      white
                      greenValue="PASS"
                      redValue="REPAIR"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Trunk</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(trunk).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    <Typography>{display_name}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={trunkValues}
                      handleGroup={handleTrunk}
                      name={name}
                      orange={false}
                      white
                      greenValue="PASS"
                      redValue="REPAIR"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Appearance</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(appearance).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    <Typography>{display_name}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={appearanceValues}
                      handleGroup={handleAppearance}
                      name={name}
                      orange={false}
                      white
                      greenValue="PASS"
                      redValue="REPAIR"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Vehicle Modifications</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(modifications).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    <Typography>{display_name}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={modificationsValues}
                      handleGroup={handleModifications}
                      name={name}
                      orange={false}
                      white
                      greenValue="PASS"
                      redValue="REPAIR"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Final Inspection</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(final).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    <Typography>{display_name}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={finalValues}
                      handleGroup={handleFinal}
                      name={name}
                      orange={false}
                      white
                      greenValue="PASS"
                      redValue="REPAIR"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <TextField
        label="Technician Comments"
        multiline
        minRows={2}
        variant="outlined"
        name={'technician_comments'}
        fullWidth
        style={{ marginTop: '15px' }}
      />
      <Typography style={{ marginTop: '15px' }}>
        I authorize that the following condition report has been completed and
        the above mentioned vehicle is eligible to become a Kia Certified
        Pre-Owned vehicle.
      </Typography>
      <FormControlLabel
        control={<Checkbox />}
        label="I agree"
        onChange={handleConsent}
        value={consent}
      />
      <br />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Technician Name"
            value={props.user.display_name}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Tech #"
            value={props.user.goUserProfile.profile.sales_licence}
            fullWidth
            name={'amvic_number'}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Date"
            value={new Date().toLocaleString()}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <Button
            type="button"
            variant="contained"
            style={{ marginRight: '15px' }}
            onClick={handleConfirm}
            color="default"
          >
            Cancel
          </Button>
          <Dialog
            open={confirm}
            onClose={handleConfirm}
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Current inspection will be closed and unsaved data will be gone.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleConfirm}>Cancel</Button>
              <Button onClick={props.formClose} color="secondary">
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
          <GreenButton type="submit" variant="contained">
            Save
          </GreenButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default KiaCPOForm;
