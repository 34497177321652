import React from 'react';

import { Grid, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { formatPrice, titleize } from 'utils';

const useStyles = makeStyles(() => ({
  label: {
    fontSize: '14px',
    fontWeight: 'bold',
  },
  data: {
    fontSize: '14px',
  },
  boldData: {
    fontSize: '14px',
    fontWeight: 'bold',
  },
  underline: {
    borderBottom: '1px solid black',
  },
}));

const ValuationMobileSummary = ({
  condition,
  priceAdjustments,
  retailPrices,
  retailTotals,
  wholesalePrices,
  wholesaleTotals,
}) => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <Grid container spacing={1} style={{ marginBottom: theme.spacing(1) }}>
      <Grid item xs={6} className={classes.label}>
        Condition:
      </Grid>
      <Grid item xs={6} className={classes.data}>
        {titleize(condition)}
      </Grid>
      <Grid item xs={6} className={classes.label}>
        Total Adjustments:
      </Grid>
      <Grid item xs={6} className={classes.data}>
        {formatPrice(priceAdjustments(condition))}
      </Grid>
      <Grid item xs={6} className={classes.label}>
        Wholesale / Retail:
      </Grid>
      <Grid item xs={6} className={classes.data}>
        {formatPrice(wholesalePrices[condition])} /{' '}
        {formatPrice(retailPrices[condition])}
      </Grid>
      <Grid item xs={12} className={classes.underline} />
      <Grid item xs={6} className={classes.label}>
        Total Wholesale:
      </Grid>
      <Grid item xs={6} className={classes.boldData}>
        {formatPrice(wholesaleTotals[condition])}
      </Grid>
      <Grid item xs={6} className={classes.label}>
        Total Retail:
      </Grid>
      <Grid item xs={6} className={classes.boldData}>
        {formatPrice(retailTotals[condition])}
      </Grid>
    </Grid>
  );
};

export default ValuationMobileSummary;
