import React, { useState } from 'react';
import gql from 'graphql-tag';

import { TextField } from '@mui/material';

import { useSnackMutation } from 'utils/useSnackMutation';
import { LoadingBackdrop } from 'components/MaterialUI';

const CREATE_MAKE = gql`
  mutation createMake($name: String!, $manufacturerId: Int!, $year: Int!) {
    npv {
      createMake(name: $name, manufacturerId: $manufacturerId, year: $year) {
        id
        name
      }
    }
  }
`;

export default function CreateMake({
  manufacturerId,
  year,
  formMethods,
  onCompleted = () => {},
  ...rest
}) {
  const [createMakeName, setCreateMakeName] = useState('');

  const [createNpvMake, { loading }] = useSnackMutation(
    CREATE_MAKE,
    'Make created successfully!',
    e => `Error creating make: ${e}`,
    data => {
      formMethods.setValue('npvMakeId', data.npv.createMake.id);
      setCreateMakeName('');
      onCompleted(data);
    },
  );

  return (
    <>
      <LoadingBackdrop open={loading}>Loading...</LoadingBackdrop>
      <TextField
        fullWidth
        helperText="After entering a make, press Enter to create it."
        label="Create Make"
        onChange={e => setCreateMakeName(e.target.value)}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            e.preventDefault();
            if (createMakeName?.trim()) {
              createNpvMake({
                variables: {
                  name: createMakeName.trim(),
                  manufacturerId,
                  year,
                },
              });
            }
          }
        }}
        value={createMakeName}
        variant="outlined"
        disabled={!manufacturerId || loading}
        {...rest}
      />
    </>
  );
}
