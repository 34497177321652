export const ROStatus = {
  WORKING: 'WORKING',
  VEH_DISABLED: 'VEH. DISABLED',
  READY_TO_POST: 'READY TO POST',
  PREASSIGNED: 'PREASSIGNED',
  PRE_INVOICED: 'PRE-INVOICED',
  OPENED: 'OPENED',
  POSTED: 'ALL LAB. POSTED',
};

export const LogType = {
  OWING: 0,
  PAYMENT: 1,
  VOID: 2,
  REFUND: 3,
  SMS: 4,
};

export function intToLogType(value) {
  return Object.keys(LogType).find(k => LogType[k] === value);
}

export const LogRefType = {
  NONE: 0,
  GOCARD: 1,
  GLOBAL_PAYMENTS: 2,
  SMS: 3,
};

export function intToLogRefType(value) {
  return Object.keys(LogRefType).find(k => LogRefType[k] === value);
}

export const LogStatus = {
  CREATED: 0,
  PENDING: 1,
  SUCCESS: 2,
  FAILED: 3,
};

export const cadfmt = new Intl.NumberFormat(undefined, {
  style: 'currency',
  currency: 'CAD',
});

export const TransactionStatus = {
  PENDING: 'pending', // Transaction created, but no response received by GP yet
  SUCCESS: 'success', // Successful transaction as denoted by GP
  FAILURE: 'failure', // Failed transaction as denoted by GP
  INVALID: 'invalid', // Invalid transaction - stored transaction info not in line with GP info returned
  ERROR: 'error', // There was an error creating transaction with GP
  CANCELLED: 'cancelled', // An advisor cancelled the transaction
};
