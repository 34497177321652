import { assignInWith } from 'lodash';

export const parseValuation = (valuation, selectedOptionsJSON) => {
  const equipmentArray = JSON.parse(selectedOptionsJSON ?? '[]');
  const wholesalePrices = valuation.wholesale_prices ?? {};
  const retailPrices = valuation.retail_prices ?? {};
  const options = valuation.options ?? [];

  const priceAdjustments = key =>
    options
      .filter(x => equipmentArray.includes(x.option_code))
      .reduce((t, option) => t + option[key], 0);

  const wholesaleTotals = assignInWith(
    {},
    wholesalePrices,
    (_, val, key) => val + priceAdjustments(key),
  );

  const retailTotals = assignInWith(
    {},
    retailPrices,
    (_, val, key) => val + priceAdjustments(key),
  );

  return {
    priceAdjustments,
    retailPrices,
    retailTotals,
    wholesalePrices,
    wholesaleTotals,
  };
};
