/* External */
import { NetworkStatus } from 'apollo-boost';
import React, { useState } from 'react';
import { Waypoint } from 'react-waypoint';

/* Material UI */
import {
  Box,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Forum, Refresh } from '@mui/icons-material';

import Loading from 'components/MaterialUI/Loading';
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';

import ConversationDesktop from './ConversationDesktop';

/* Internal */
import ConversationListItem from './ConversationListItem';
import ConversationListSubHeader from './ConversationListSubHeader';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    [theme.breakpoints.down('lg')]: {
      margin: '0 20px',
    },
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '45%',
      marginRight: '10px',
    },
  },
  scrollableList: {
    overflowX: 'hidden',
    flexGrow: '1',
    backgroundColor: 'white',
    padding: '10px',
  },
}));

const ConversationsBox = ({
  conversations,
  desktopList,
  newMessages,
  loading,
  loadMoreResults,
  networkStatus,
  gatewayId,
  selectedChatId,
  setSelectedChatId,
  setHandleMultiSelect,
  handleMultiSelect,
  selectMultiple,
  selectedItemsIds,
  ...rest
}) => (
  <Box {...rest} mb={!desktopList && newMessages ? '75px' : 0}>
    {conversations.map(conversation => (
      <Box key={conversation.id}>
        <ConversationListItem
          conversation={conversation}
          gatewayId={gatewayId}
          setSelectedChatId={setSelectedChatId}
          setHandleMultiSelect={setHandleMultiSelect}
          handleMultiSelect={handleMultiSelect}
          selectMultiple={selectMultiple}
          // conversation.id is a string - not sure why it's not an int
          bgColor={
            !handleMultiSelect &&
            parseInt(conversation.id, 10) === selectedChatId
              ? '#D7D7D7'
              : handleMultiSelect && selectedItemsIds.includes(conversation.id)
              ? 'rgba(36, 160, 237, 0.2)'
              : 'white'
          }
        />
      </Box>
    ))}
    <Box style={{ height: 1 }}>
      {!loading && <Waypoint onEnter={loadMoreResults} />}
      {networkStatus === NetworkStatus.fetchMore && (
        <Box>Loading more conversations...</Box>
      )}
    </Box>
  </Box>
);

const ConversationList = ({
  conversationQueryVariables,
  gatewayId,
  tabIndex,
  setTabIndex,
  setSelectedChatId,
  selectedChatId,
  loadMoreResults,
  conversationsList,
  loading,
  networkStatus,
  newMessages,
  fetchingMore,
  filtering,
  total,
  refetchMessages,
  ...rest
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const desktopList = useMediaQuery(theme => theme.breakpoints.up('md'));
  const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const [handleMultiSelect, setHandleMultiSelect] = useState(false);

  const [selectedItemsIds, setSelectedItemsIds] = useState([]);

  const selectMultiple = selectedChatId => {
    // remove selection if item is clicked again after already being selected
    if (selectedItemsIds.includes(selectedChatId)) {
      setSelectedItemsIds(prev => prev.filter(x => x !== selectedChatId));
    } else {
      setSelectedItemsIds(prev =>
        conversationsList
          .map(c => c.id)
          .filter(x => [...prev, selectedChatId].includes(x)),
      );
    }
  };

  if (selectedItemsIds.length > 0 && handleMultiSelect === false) {
    setSelectedItemsIds([]);
  }

  if (networkStatus === NetworkStatus.refetch) {
    return <Loading />;
  }

  return (
    <Box className={classes.container} {...rest}>
      <Box className={classes.list}>
        {/* *** SUB HEADER *** */}
        <ConversationListSubHeader
          selectedItemsIds={selectedItemsIds}
          setSelectedItemsIds={setSelectedItemsIds}
          conversationsList={conversationsList}
          total={total}
          tabIndex={tabIndex}
          setTabIndex={setTabIndex}
          setHandleMultiSelect={setHandleMultiSelect}
          handleMultiSelect={handleMultiSelect}
          gatewayId={gatewayId}
          conversationQueryVariables={conversationQueryVariables}
          setSelectedChatId={setSelectedChatId}
        />

        <Box className={classes.scrollableList}>
          <LoadingBackdrop open={fetchingMore || filtering}>
            {`Loading${fetchingMore ? ' more conversations' : ''}...`}
          </LoadingBackdrop>

          {conversationsList.length > 0 && (
            <ConversationsBox
              conversations={conversationsList}
              desktopList={desktopList}
              newMessages={newMessages}
              loading={loading}
              loadMoreResults={loadMoreResults}
              gatewayId={gatewayId}
              setSelectedChatId={setSelectedChatId}
              setHandleMultiSelect={setHandleMultiSelect}
              handleMultiSelect={handleMultiSelect}
              selectMultiple={selectMultiple}
              selectedItemsIds={selectedItemsIds}
              display="flex"
              flexDirection="column"
            />
          )}
          {conversationsList.length === 0 && !loading && (
            <Box fontWeight="bold" textAlign="center" height="85vh">
              No conversations to display.
            </Box>
          )}
        </Box>
        {newMessages && (
          <Box
            zIndex={1000}
            bgcolor="lightGray"
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt="10px"
          >
            <p
              style={{
                textTransform: 'uppercase',
                fontWeight: 'bold',
                color: 'black',
                fontSize: mobile ? '0.875rem' : '1.125rem',
                margin: '15px',
              }}
            >
              New Conversations to Load
            </p>
            <IconButton
              variant="contained"
              style={theme.actions.confirm}
              size="small"
              onClick={() => refetchMessages()}
            >
              <Refresh style={{ margin: '2px' }} />
            </IconButton>
          </Box>
        )}
      </Box>
      {/* conversation -> large screens only */}
      {desktopList && (
        <Box width="54%" mb="10px">
          {(selectedChatId === 0 || handleMultiSelect) && (
            <Box
              height="100%"
              bgcolor="white"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              color="#cccccc"
            >
              <Forum fontSize="large" />
              <Typography variant="h5" style={{ fontWeight: 'bold' }}>
                Select a Chat
              </Typography>
            </Box>
          )}
          {selectedChatId !== 0 && !handleMultiSelect && (
            <Box height="100%">
              <ConversationDesktop
                conversationQueryVariables={conversationQueryVariables}
                conversationId={selectedChatId}
                gatewayId={gatewayId}
                setSelectedChatId={setSelectedChatId}
              />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default ConversationList;
