import React from 'react';

import { Grid, Typography } from '@mui/material';

import { getFullVehicle } from '../utils';

const VehicleHeaderData = ({ opportunity }) => {
  return (
    <Grid
      display="flex"
      flexDirection="column"
      alignItems="left"
      style={{ marginLeft: '15px' }}
    >
      {/*Extra vehicle data such as the trim isn't available in dms_deal, 
      so show year, make, and model*/}
      <Typography variant="body2">
        <b>Vehicle Sold:</b> {getFullVehicle(opportunity?.dms_deal)}
        <small> (from DMS Deal)</small>
      </Typography>
      <Grid display="flex" flexDirection="row" justifyContent="flex-start">
        <Typography variant="body2">
          <span style={{ marginRight: '10px' }}>
            <b>Stock #:</b> {opportunity?.dms_deal?.stock_number}
          </span>
          <b>Deal #:</b> {opportunity?.dms_deal?.deal_number}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default VehicleHeaderData;
