import React from 'react';
import gql from 'graphql-tag';

import { Grid } from '@mui/material';

import { CheckboxControl } from 'components/MaterialUI';

function Feat({ label, name }) {
  return (
    <Grid item xs={12}>
      <CheckboxControl label={label} name={name} />
    </Grid>
  );
}

export default function FeatureGrids() {
  return (
    <>
      <Grid container item xs={12} sm={6} md={4}>
        <Feat name="featBath15" label="Bath and a Half" />
        <Feat name="featBunkhouse" label="Bunkhouse" />
        <Feat name="featFrontBath" label="Front Bath" />
        <Feat name="featFrontBedroom" label="Front Bedroom" />
        <Feat name="featFrontCargoDeck" label="Front Cargo Deck" />
        <Feat name="featFrontEntertainment" label="Front Entertainment" />
        <Feat name="featFrontKitchen" label="Front Kitchen" />
        <Feat name="featFrontLivingArea" label="Front Living Area" />
        <Feat name="featKitchenIsland" label="Kitchen Island" />
      </Grid>
      <Grid container item xs={12} sm={6} md={4}>
        <Feat name="featLoft" label="Loft" />
        <Feat name="featMurphyBed" label="Murphy Bed" />
        <Feat name="featOutdoorEntertainment" label="Outdoor Entertainment" />
        <Feat name="featOutdoorKitchen" label="Outdoor Kitchen" />
        <Feat name="featRearBath" label="Rear Bath" />
        <Feat name="featRearBedroom" label="Rear Bedroom" />
        <Feat name="featRearEntertainment" label="Rear Entertainment" />
        <Feat name="featRearKitchen" label="Rear Kitchen" />
        <Feat name="featRearLivingArea" label="Rear Living Area" />
      </Grid>
      <Grid container item xs={12} sm={6} md={4} alignContent="flex-start">
        <Feat name="featRearTwin" label="Rear Twin" />
        <Feat name="featSideRamp" label="Side Ramp" />
        <Feat name="featTwoEntryExitDoors" label="Two Entry/Exit Doors" />
        <Feat name="featTwoFullBaths" label="Two Full Baths" />
        <Feat name="featWheelchairAccessible" label="Wheelchair Accessible" />
      </Grid>
    </>
  );
}

FeatureGrids.fragments = {
  vehicle: gql`
    fragment NpvVehicleFeatures on NpvVehicle {
      id
      featBath15
      featBunkhouse
      featFrontBath
      featFrontBedroom
      featFrontCargoDeck
      featFrontEntertainment
      featFrontKitchen
      featFrontLivingArea
      featKitchenIsland
      featLoft
      featMurphyBed
      featOutdoorEntertainment
      featOutdoorKitchen
      featRearBath
      featRearBedroom
      featRearEntertainment
      featRearKitchen
      featRearLivingArea
      featRearTwin
      featSideRamp
      featTwoEntryExitDoors
      featTwoFullBaths
      featWheelchairAccessible
    }
  `,
};
