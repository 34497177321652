import React from 'react';

import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';

const InventoryToolbar = ({ numSelected, onSelectAllFiltered, total }) => {
  return (
    <>
      {numSelected === 1 && (
        <Alert style={{ width: '500px' }} severity="warning">
          {numSelected} vehicle selected.
        </Alert>
      )}
      {numSelected > 1 && (
        <Alert severity="warning">
          {numSelected} vehicles selected.{' '}
          {total !== numSelected && (
            <Button color="primary" onClick={onSelectAllFiltered}>
              Select all {total} from your filtered results
            </Button>
          )}
        </Alert>
      )}
    </>
  );
};

export default InventoryToolbar;
