// External
import FullCalendar from '@fullcalendar/react';

import { useMutation, useQuery } from '@apollo/react-hooks';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import gql from 'graphql-tag';
import { flatten, isEmpty, uniq } from 'lodash';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';

// Material UI
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  Fab,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  Close as CloseIcon,
  FilterList as FilterListIcon,
  Print as PrintIcon,
} from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers';

// Internal
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import DealerPicker from 'components/MaterialUI/DealerPicker';
import Loading from 'components/MaterialUI/Loading';

import { capitalize } from 'utils';

import ServiceAppointmentActions from './ServiceAppointmentActions';
import ServiceAppointmentDialog from './ServiceAppointmentDialog';
import ServiceAppointmentTable from './ServiceAppointmentTable';
import ServiceFacetChips from './ServiceFacetChips';
import ServiceFilterDrawer from './ServiceFilterDrawer';
import { Role } from 'constants.js';

const APPOINTMENT_QUERY = gql`
  query AppointmentQuery(
    $dealer_id: Int!
    $appointment_date__gt: String
    $appointment_date__lt: String
    $sort_by: SortServiceAppointmentBy
  ) {
    serviceAppointments(
      dealer_id: $dealer_id
      appointment_date__gt: $appointment_date__gt
      appointment_date__lt: $appointment_date__lt
      sort_by: $sort_by
    ) {
      id
      appointment_id
      vin
      year
      make_name
      model_name
      first_name
      last_name
      date_created
      appointment_date
      duration
      service_person
      record_urn
      customer {
        _id
        fullname
        primary_phone
        primary_email
        vehicles {
          finance_amount
          last_payment_date
          date_purchased
          equity
          vin
        }
        assigned_salespeople {
          dealer_id
          username
          user {
            display_name
          }
        }
        leads {
          form_data {
            lead_source
          }
        }
      }
    }
  }
`;

const USERS_FOR_DEALER = gql`
  query UsersQuery($dealer_id: Int!) {
    users(
      dealer_ids: [$dealer_id]
      status: active
      roles: [
        "${Role.SALES_REP}"
        "${Role.SALES_MANAGER}"
        "${Role.FINANCE_MANAGER}"
        "${Role.INTERNET_SALES_REP}"
      ]
    ) {
      display_name
      username
      role
    }
  }
`;

const ASSIGN_SALESPERSON = gql`
  mutation assignSalesperson($_id: ID!, $input: AssignSalespersonInput!) {
    assignSalespersonToCustomer(_id: $_id, input: $input) {
      _id
      assigned_salespeople {
        username
        user {
          display_name
        }
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  action: {
    '& > *': {
      margin: theme.spacing(0.5),
    },
    '& button': {
      padding: '6px 4px',
      minWidth: 'unset',
      backgroundColor: '#2196f3',
    },
  },
  customer: {
    '& a': {
      color: '#0093a7',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
}));

const ServiceAppointment = () => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [listView, setListView] = useState(true);
  const { dealerId } = useDealerContext();

  const theme = useTheme();

  const isDesktop = useMediaQuery(theme => theme.breakpoints.up('sm'));

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [sort, setSort] = useState({ key: 'appointment_date', direction: -1 });
  const [order, setOrder] = useState(-1);
  const [orderBy, setOrderBy] = useState('appointment_date');
  const handleRequestSort = (event, property) => {
    setSort({
      key: property,
      direction: order === 1 ? -1 : 1,
    });
    setOrder(order === 1 ? -1 : 1);
    setOrderBy(property);
  };

  const [appointmentDateFromFilter, setAppointmentDateFromFilter] = useState(
    moment(),
  );
  const [appointmentDateUntilFilter, setAppointmentDateUntilFilter] = useState(
    moment().add(1, 'days'),
  );

  const [assigneeFilter, setAssigneeFilter] = useState([]);

  const handleCalendarDates = arg => {
    setAppointmentDateFromFilter(moment(arg.startStr));
    setAppointmentDateUntilFilter(moment(arg.endStr));
  };
  const handleListDates = date => {
    setAppointmentDateFromFilter(moment(date));
    setAppointmentDateUntilFilter(moment(date).add(1, 'days'));
  };
  const showListView = () => {
    setAppointmentDateFromFilter(moment().format('YYYY-MM-DD'));
    setAppointmentDateUntilFilter(moment().add(1, 'days'));
    setListView(true);
  };

  const [assignSalesperson] = useMutation(ASSIGN_SALESPERSON, {
    onCompleted: () => {
      setId('');
      setUsername('');
      enqueueSnackbar(`Successfully updated salesperson`, {
        variant: 'success',
      });
    },
    onError: () =>
      enqueueSnackbar(`Error in updating salesperson`, {
        variant: 'error',
      }),
  });

  const [openUpdateSalesperson, setOpenUpdateSalesperson] = useState(false);
  const [id, setId] = useState('');
  const [username, setUsername] = useState('');

  const handleOpenUpdateSalesperson = id => {
    if (id) {
      setId(id);
      setOpenUpdateSalesperson(true);
    } else {
      enqueueSnackbar('Customer has no CRM records!', { variant: 'error' });
    }
  };

  const handleClose = () => {
    setOpenUpdateSalesperson(false);
  };

  const handleChange = event => {
    setUsername(event.target.value);
  };

  const onSubmit = () => {
    const payload = {
      username,
      dealer_id: dealerId,
    };

    assignSalesperson({
      variables: {
        _id: id,
        input: payload,
      },
    });

    handleClose();
  };

  const [state, setState] = useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setState(prev => ({ ...prev, [anchor]: open }));
  };

  const queryVariables = {
    dealer_id: dealerId,
    appointment_date__gt: appointmentDateFromFilter,
    appointment_date__lt: appointmentDateUntilFilter,
    sort_by: sort,
  };

  // get service appointments
  const { data, loading } = useQuery(APPOINTMENT_QUERY, {
    variables: queryVariables,
  });
  const rows = data?.serviceAppointments || [];

  const isActiveAndCorrectDealer = assignee =>
    assignee?.user?.display_name &&
    parseInt(assignee?.dealer_id, 10) === dealerId;

  const getUniqueAssignees = rows =>
    uniq([
      ...flatten(
        rows?.map(row =>
          row.customer?.assigned_salespeople
            ?.filter(assignee => isActiveAndCorrectDealer(assignee))
            .map(assignee => assignee.user?.display_name),
        ),
      ),
      // undefined represents unassigned service appointments when filtering
      undefined,
    ]);

  const assignees = getUniqueAssignees(rows);

  const filteredRows = rows.filter(row => {
    const assignee = row.customer?.assigned_salespeople?.find(
      assignee =>
        parseInt(assignee.dealer_id, 10) === dealerId && assignee.user,
    );
    return assigneeFilter.length
      ? assigneeFilter.includes(assignee?.user?.display_name)
      : true;
  });

  // get user data for reassign salesperson
  const { data: userData } = useQuery(USERS_FOR_DEALER, {
    variables: { dealer_id: dealerId },
  });
  const userList = userData?.users || [];

  // service appointment names come as Last,First format
  // but sometimes the last name is missing, so we can't assume there's even a comma.
  const formatName = name => {
    const nameArray = (name?.split(',') ?? ['']).reverse();
    return nameArray.map(x => capitalize(x.toLowerCase())).join(' ');
  };

  // add events for calendar view
  const events = filteredRows.map(
    ({ appointment_id, service_person, appointment_date, duration }) => ({
      id: appointment_id,
      title: service_person ? formatName(service_person) : '',
      start: appointment_date,
      end: moment
        .utc(appointment_date, 'YYYY-MM-DDTHH:mm:ssZ')
        .add(duration, 'hours')
        .toISOString(),
    }),
  );

  const [singleData, setSingleData] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const handleModalClose = () => setModalOpen(false);

  const handleEvent = arg => {
    filteredRows.forEach(data => {
      if (data.appointment_id === arg.event.id) {
        setSingleData(data);
      }
    });
    setModalOpen(true);
  };

  return (
    <Box m={1}>
      <Box>
        <DealerPicker />
        <ServiceAppointmentDialog
          modalOpen={modalOpen}
          handleModalClose={handleModalClose}
          singleData={singleData}
          formatName={formatName}
          classes={classes}
          handleOpenUpdateSalesperson={handleOpenUpdateSalesperson}
        />
        <Dialog open={openUpdateSalesperson} onClose={handleClose}>
          <DialogTitle>Update assigned salesperson</DialogTitle>
          <DialogContent>
            <InputLabel>Username</InputLabel>
            <Select
              id="username"
              value={username}
              onChange={handleChange}
              style={{ width: 300 }}
            >
              {userList.map(i => (
                <MenuItem key={i.username} value={i.username}>
                  {i.display_name}
                </MenuItem>
              ))}
            </Select>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" variant="outlined">
              Cancel
            </Button>
            <Button
              onClick={onSubmit}
              color="primary"
              variant="contained"
              style={theme.actions.confirm}
            >
              Update
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
      <Box>
        <Grid
          container
          direction="row"
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Grid
            item
            style={{
              display: 'flex',
              flexDirection: 'row',
              padding: '1rem',
            }}
          >
            {listView && (
              <DatePicker
                autoOk
                clearable
                label="Selected Date"
                format="YYYY-MM-DD"
                onChange={date => handleListDates(date)}
                value={moment(appointmentDateFromFilter)}
                variant="dialog"
                style={{
                  marginRight: '1rem',
                }}
              />
            )}
            {isDesktop && (
              <Tooltip title="Filter list">
                <Fab
                  color="primary"
                  onClick={toggleDrawer('left', true)}
                  size="medium"
                >
                  <FilterListIcon />
                </Fab>
              </Tooltip>
            )}
          </Grid>
          {isDesktop && (
            <Grid
              item
              xs={1}
              style={{
                display: 'flex',
                paddingTop: '1rem',
                marginRight: 'auto',
              }}
            >
              {!isEmpty(assigneeFilter) && (
                <Box paddingTop="0.5rem" paddingBottom="0.5rem">
                  <ServiceFacetChips
                    assigneeFilter={assigneeFilter}
                    setAssigneeFilter={setAssigneeFilter}
                  />
                </Box>
              )}
            </Grid>
          )}
          <Grid
            item
            xs={2}
            style={{
              display: 'flex',
            }}
          >
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-end',
                marginTop: `${isDesktop ? '' : '1rem'}`,
                marginLeft: `${isDesktop ? 'auto' : ''}`,
                marginRight: `${isDesktop ? 0 : ''}`,
                width: `${isDesktop ? '' : '100%'}`,
              }}
            >
              <Box
                display="flex"
                flexDirection="column"
                style={{ width: `${isDesktop ? '' : '100%'}` }}
              >
                {isDesktop && (
                  <>
                    <Box
                      display="flex"
                      flexDirection="row"
                      style={{ marginLeft: 'auto', marginRight: 0 }}
                      className={classes.action}
                    >
                      <Button
                        size="small"
                        variant="contained"
                        startIcon={<PrintIcon />}
                        onClick={handlePrint}
                      >
                        Print
                      </Button>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="row"
                      style={{ marginLeft: 'auto', marginRight: 0 }}
                      className={classes.action}
                    >
                      <b>View as:</b>
                      {listView && (
                        <Tooltip title="Calendar">
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setListView(false)}
                          >
                            <i className="fas fa-calendar-alt fa-fw" />
                          </Button>
                        </Tooltip>
                      )}
                      {!listView && (
                        <Tooltip title="List">
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => showListView()}
                          >
                            <i className="fas fa-list fa-fw" />
                          </Button>
                        </Tooltip>
                      )}
                    </Box>
                  </>
                )}
                {listView && (
                  <Box
                    style={{
                      display: 'flex',
                      marginLeft: 'auto',
                      marginRight: 0,
                      marginTop: 'auto',
                      fontSize: '18px',
                      paddingTop: '.1rem',
                    }}
                  >
                    Showing {filteredRows.length} of {rows.length}
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {isDesktop && !listView && (
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay',
          }}
          timeZone="UTC"
          initialView="dayGridMonth"
          datesSet={arg => handleCalendarDates(arg)}
          editable
          dayMaxEvents
          eventClick={arg => handleEvent(arg)}
          events={events}
        />
      )}
      {isDesktop && listView && (
        <div ref={componentRef}>
          <ServiceAppointmentTable
            loading={loading}
            rows={filteredRows}
            handleOpenUpdateSalesperson={handleOpenUpdateSalesperson}
            formatName={formatName}
            dealerId={dealerId}
            order={order}
            orderBy={orderBy}
            handleRequestSort={handleRequestSort}
          />
        </div>
      )}
      {!isDesktop && (
        <>
          {!isEmpty(assigneeFilter) && (
            <Box paddingTop="0.5rem" paddingBottom="0.5rem">
              <ServiceFacetChips
                assigneeFilter={assigneeFilter}
                setAssigneeFilter={setAssigneeFilter}
              />
            </Box>
          )}
        </>
      )}
      {!isDesktop && (
        <Box style={{ paddingTop: '1rem' }}>
          {loading && <Loading />}
          {!loading && rows.length === 0 && (
            <Box fontSize="20px" padding="1rem">
              No Appointments Found.
            </Box>
          )}
          {!loading &&
            filteredRows.map(row => (
              <Box style={{ paddingBottom: '1rem' }} key={row.appointment_id}>
                <Card>
                  <CardContent>
                    <Grid container direction="row">
                      <Grid item xs={12}>
                        <Box paddingBottom={2}>
                          <Typography
                            style={{
                              fontWeight: '700',
                              paddingBottom: '.5rem',
                            }}
                          >
                            {`${moment
                              .utc(row.appointment_date)
                              .format('MMM D, YYYY @ h:mma')} - ${moment
                              .utc(row.appointment_date, 'YYYY-MM-DDTHH:mm:ssZ')
                              .add(row.duration, 'hours')
                              .format('h:mma')}`}
                          </Typography>
                          {row.year} {row.make_name} {row.model_name}
                          <br />
                          VIN: {row.vin}
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container direction="row">
                      <Grid item xs={6}>
                        <Box
                          style={{ fontSize: '.8rem', marginBottom: '.5rem' }}
                          className={classes.customer}
                        >
                          <b>Customer: </b>
                          {row.customer ? (
                            <>
                              <Link
                                to={`/customers/${row.customer._id}/details`}
                              >
                                {row.customer.fullname || ''}
                              </Link>
                              {row.customer.primary_email ? (
                                <>
                                  <br />
                                  <i className="fas fa-envelope fa-fw" />{' '}
                                  {row.customer.primary_email || ''}
                                </>
                              ) : (
                                ''
                              )}
                              {row.customer.primary_phone ? (
                                <>
                                  <br />
                                  <i className="fas fa-mobile-alt fa-fw" />{' '}
                                  {row.customer.primary_phone || ''}
                                </>
                              ) : (
                                ''
                              )}
                            </>
                          ) : row.first_name ? (
                            `${row.first_name} `
                          ) : `${row.last_name}` ? (
                            row.last_name
                          ) : (
                            ''
                          )}
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        style={{ fontSize: '.8rem', textAlign: 'right' }}
                      >
                        Service Advisor:{' '}
                        {row.service_person && formatName(row.service_person)}
                        <ServiceAppointmentActions
                          classes={classes}
                          handleOpenUpdateSalesperson={
                            handleOpenUpdateSalesperson
                          }
                          appointment={row}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
            ))}
        </Box>
      )}
      {!isDesktop && (
        <Box position="fixed" bottom="10px" right="10px">
          <Fab
            color="primary"
            onClick={toggleDrawer('left', true)}
            size="medium"
          >
            <FilterListIcon />
          </Fab>
        </Box>
      )}
      <Drawer
        anchor="left"
        open={state.left}
        onClose={toggleDrawer('left', false)}
      >
        <Box style={{ display: 'flex' }}>
          <IconButton
            style={{
              display: 'flex',
              marginLeft: 'auto',
              height: '50px',
              zIndex: '1000',
            }}
            onClick={toggleDrawer('left', false)}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <ServiceFilterDrawer
          dealerId={dealerId}
          appointments={rows}
          assignees={assignees}
          assigneeFilter={assigneeFilter}
          setAssigneeFilter={setAssigneeFilter}
          loading={loading}
        />
      </Drawer>
    </Box>
  );
};

export default ServiceAppointment;
