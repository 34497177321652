import React from 'react';

/* external */
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';

/* Material UI */
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material';

/* internal */
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';
import { refCode } from 'utils';

const useStyles = makeStyles(theme => ({
  text: {
    fontSize: 15,
  },
}));

const DETACH_GOCARD_RECORD = gql`
  mutation detachCustomerRecords($_id: ID!, $records: [String]!) {
    detachCustomerRecords(_id: $_id, records: $records) {
      records
    }
  }
`;

const DetachGoCardModal = ({
  gocard,
  customerId,
  isOpen,
  onClose,
  refetch,
  records,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [detachRecord, { loading }] = useMutation(DETACH_GOCARD_RECORD, {
    onCompleted: data => {
      enqueueSnackbar('Successfully detached record!', { variant: 'success' });
      // Exclude removed gocard from refetch
      const card_ids = records
        .filter(record => record.includes('gocard'))
        .filter(record => !record.includes(`${gocard.cardId}`))
        .map(record => record.split(':').slice(-1)[0]);
      refetch({
        filters: [
          {
            model: 'GoCard',
            field: 'cardId',
            op: 'in',
            value: card_ids,
          },
          {
            model: 'GoCard',
            field: 'active',
            op: 'eq',
            value: true,
          },
        ],
      });
    },
    onError: err =>
      enqueueSnackbar(`Could not detach record: ${err}`, { variant: 'error' }),
  });

  const onSubmit = () => {
    detachRecord({
      variables: {
        _id: customerId,
        records: [`goauto.gocard:gocard:${gocard.cardId}`],
      },
    });
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Detach Go Card</DialogTitle>
      <DialogContent>
        <Alert severity="warning">
          This will remove the Go Card from the Customer's Profile
        </Alert>
      </DialogContent>
      <DialogContent style={{ overflowY: 'unset' }}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
          style={{ marginLeft: '8px' }}
        >
          <Grid item>
            <CreditCardIcon style={{ marginTop: '5px' }} />
          </Grid>
          <Grid item style={{ fontWeight: 'bold' }} className={classes.text}>
            ${gocard?.cashBalance || 0}
          </Grid>
          <Grid item style={{ fontWeight: 'bold' }} className={classes.text}>
            {`${gocard?.cardNumber}`}
          </Grid>
          <Grid
            item
            style={{ fontWeight: 'bold', color: '#999999' }}
            className={classes.text}
          >
            {`|`}
          </Grid>
          <Grid
            item
            style={{ fontWeight: 'bold', color: '#999999' }}
            className={classes.text}
          >
            {`${refCode(gocard?.cardId)}`}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button style={theme.actions.close} onClick={onClose} variant="text">
          Cancel
        </Button>
        <Button
          style={theme.actions.confirm}
          onClick={() => onSubmit()}
          variant="contained"
          color="primary"
        >
          Detach
        </Button>
      </DialogActions>
      <LoadingBackdrop open={loading}>Detaching Go Card...</LoadingBackdrop>
    </Dialog>
  );
};

DetachGoCardModal.fragments = {
  gocard: gql`
    fragment DetachGoCardModalGoCard on GoCardAccountV2 {
      cashBalance
      cardNumber
    }
  `,
  customer: gql`
    fragment DetachGoCardModalFragment on Customer {
      records
    }
  `,
};

export default DetachGoCardModal;
