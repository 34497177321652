import React, { useState } from 'react';

/* external */
import gql from 'graphql-tag';

/* Material UI */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  Dialog,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { makeStyles } from '@mui/styles';

import {
  Add as AddIcon,
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material';

/* internal */
import BulletinModal from './BulletinModal';
import BulletinAttachments from './BulletinAttachments';
import BulletinsList from './BulletinsList';
import { MAX_BULLETINS } from '../constant';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '50px',
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
  },
}));

const Bulletins = ({ customer, refetch }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [showBulletinModal, setShowBulletinModal] = useState(false);
  const [showBulletinAttachments, setShowBulletinAttachments] = useState(false);
  const desktop = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const bulletins = customer?.bulletins || [];
  const [selected, setSelected] = useState('');

  const handleClose = () => {
    setShowBulletinModal(false);
    setSelected('');
    refetch();
  };

  const BulletinsHeader = () => {
    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <Typography className={classes.title}>
            Bulletins {bulletins.length ? `(${bulletins.length})` : ''}
          </Typography>
        </Grid>
        <Grid item>
          <IconButton
            size="small"
            onClick={() => setShowBulletinModal(true)}
            disabled={bulletins.length >= MAX_BULLETINS}
          >
            <AddIcon
              style={
                bulletins.length >= MAX_BULLETINS
                  ? theme.actions.disabled
                  : theme.actions.add
              }
            />
          </IconButton>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Dialog maxWidth="sm" open={showBulletinModal} fullWidth={true}>
        <BulletinModal
          handleClose={handleClose}
          customer={customer}
          existingBulletin={selected}
        />
      </Dialog>
      <Dialog maxWidth="sm" open={showBulletinAttachments} fullWidth={true}>
        <BulletinAttachments
          handleClose={() => setShowBulletinAttachments(false)}
          bulletin={selected}
          refetch={refetch}
        />
      </Dialog>
      {desktop ? (
        <Card className={classes.root}>
          <CardContent>
            <BulletinsHeader />
            <BulletinsList
              bulletins={bulletins}
              setSelected={setSelected}
              setShowBulletinModal={setShowBulletinModal}
              setShowAttachmentModal={setShowBulletinAttachments}
            />
          </CardContent>
        </Card>
      ) : (
        <Accordion defaultExpanded={true}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <BulletinsHeader />
          </AccordionSummary>
          <AccordionDetails style={{ paddingTop: 0 }}>
            <BulletinsList
              bulletins={bulletins}
              setSelected={setSelected}
              setShowBulletinModal={setShowBulletinModal}
            />
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
};

Bulletins.fragments = {
  customer: gql`
    fragment BulletinsFragment on Customer {
      _id
      bulletins {
        _id
        body
        type
        created
        updated
        created_by {
          username
          display_name
        }
        attachments {
          _id
          attachment_type
          key
          label
          created_by
          created_by_name
          date_created
          deleted
          url
        }
        dealer_id
      }
    }
  `,
};

export default Bulletins;
