import React from 'react';

/* Material UI */
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material';

const GridCard = ({ title, children, CardHeaderProps = {}, ...rest }) => (
  <Card style={{ marginBottom: useTheme().spacing(2) }} {...rest}>
    <CardHeader
      titleTypographyProps={{
        color: 'secondary',
        style: { fontWeight: 'bold' },
        variant: 'h6',
      }}
      title={title}
      {...CardHeaderProps}
    />
    <CardContent>
      <Grid container spacing={3}>
        {children}
      </Grid>
    </CardContent>
  </Card>
);

export default GridCard;
