export const ACTIVE_ITEMS_COUNT_SORT = {
  model: 'Auction',
  field: 'activeItemsCount',
};

export const SORT_BY_ID = { model: 'Auction', field: 'id', direction: 'desc' };

export const NAME_SORT = { model: 'Auction', field: 'name' };

export const UPCOMING_ITEMS_COUNT_SORT = {
  model: 'Auction',
  field: 'upcomingItemsCount',
};
