import React from 'react';

/* external */
import gql from 'graphql-tag';

/* Material UI */
import { makeStyles } from '@mui/styles';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import TableCell from '@mui/material/TableCell';

const useStyles = makeStyles(theme => ({
  good: {
    color: 'black',
    backgroundColor: '#86ff7f',
  },
  bad: {
    color: 'black',
    backgroundColor: '#ff867f',
  },
}));

const AuctionabilityCell = ({ vehicle, ...rest }) => {
  const classes = useStyles();

  const { cbb, cost, odometer, photo_count, style_id, vin } = vehicle;
  const cbb_vid = cbb?.vid;

  const isNonZero = x => parseFloat(x) > 0.0;
  const isValidString = x => x && x.length > 0;

  return (
    <TableCell {...rest}>
      <Grid container spacing={1}>
        <Grid item>
          <Chip
            label="CBB"
            size="small"
            className={isNonZero(cbb_vid) ? classes.good : classes.bad}
          />
        </Grid>
        <Grid item>
          <Chip
            label="VIN"
            size="small"
            className={isValidString(vin) ? classes.good : classes.bad}
          />
        </Grid>
        <Grid item>
          <Chip
            label="Cost"
            size="small"
            className={isNonZero(cost) ? classes.good : classes.bad}
          />
        </Grid>
        <Grid item>
          <Chip
            label="km"
            size="small"
            className={isNonZero(odometer) ? classes.good : classes.bad}
          />
        </Grid>
        <Grid item>
          <Chip
            label="Pics"
            size="small"
            className={isNonZero(photo_count) ? classes.good : classes.bad}
          />
        </Grid>
        <Grid item>
          <Chip
            label="Decode"
            size="small"
            className={isNonZero(style_id) ? classes.good : classes.bad}
          />
        </Grid>
      </Grid>
    </TableCell>
  );
};

AuctionabilityCell.fragments = {
  vehicle: gql`
    fragment AuctionabilityCellVehicle on GreaseInventoryVehicle {
      cbb {
        vid
      }
      cost
      odometer
      photo_count
      style_id
      vin
    }
  `,
};

export default AuctionabilityCell;
