import React from 'react';
import { useHistory } from 'react-router-dom';

/* Material UI */
import { Box, Button, useTheme } from '@mui/material';
import { green } from '@mui/material/colors';

const StepButtons = ({ step = 1, back }) => {
  const theme = useTheme();
  const history = useHistory();
  const buttonLabel = step === 5 ? 'Complete' : 'Next';
  return (
    <Box margin={2} style={{ textAlign: 'end' }}>
      {step !== 0 && (
        <Button
          onClick={() => history.push('/npv-inventory/inventory')}
          style={{ color: theme.colours.add, margin: '16px' }}
        >
          Cancel
        </Button>
      )}
      <Button
        disabled={step === 0}
        onClick={back}
        style={{ margin: '16px' }}
        variant="contained"
        color="default"
      >
        Back
      </Button>
      <Button
        color="primary"
        style={{ backgroundColor: green[500], color: 'white', margin: '10px' }}
        type="submit"
        variant="contained"
      >
        {buttonLabel}
      </Button>
    </Box>
  );
};

export default StepButtons;
