import React, { useState } from 'react';

/* external */
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import gql from 'graphql-tag';

/* Material UI */
import { Box, Stepper, useMediaQuery } from '@mui/material';

/* internal */
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';
import StepFive from '../components/StepFive';
import StepFour from '../components/StepFour';
import StepOne from '../components/StepOne';
import StepSix from '../components/StepSix';
import StepThree from '../components/StepThree';
import StepTwo from '../components/StepTwo';
import Title from '../components/Title';

const GET_APPRAISAL = gql`
  query getAppraisal($appraisalId: Int!) {
    appraisals {
      appraisal(id: $appraisalId) {
        id
        ...StepOneAppraisal
        ...StepTwoAppraisal
        ...StepThreeAppraisal
        ...StepFourAppraisal
        ...StepFiveAppraisal
        ...StepSixAppraisal
      }
    }
  }
  ${StepOne.fragments.appraisal}
  ${StepTwo.fragments.appraisal}
  ${StepThree.fragments.appraisal}
  ${StepFour.fragments.appraisal}
  ${StepFive.fragments.appraisal}
  ${StepSix.fragments.appraisal}
`;

const CreateVehicle = ({ id }) => {
  const [appraisalId, setAppraisalId] = useState(id);
  const { enqueueSnackbar } = useSnackbar();
  const isDesktop = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const [dirtySteps, setDirtySteps] = useState([]);

  const { data, loading } = useQuery(GET_APPRAISAL, {
    variables: { appraisalId },
    skip: !appraisalId,
  });

  const appraisal = data?.appraisals.appraisal || {};

  const history = useHistory();
  const [activeStep, setActiveStep] = useState(0);
  // TODO: setActiveStep based on what data is null in appraisal if editing
  // an existing appraisal.  Maybe set this when the get appraisal query is completed?

  const goBack = () => setActiveStep(prev => prev - 1);

  const goNext = () => setActiveStep(prev => prev + 1);

  const checkDirtySetActiveStep = stepIndex => {
    if (dirtySteps.some(x => x))
      enqueueSnackbar(
        `Unsaved changes in step(s): ${dirtySteps
          .filter(x => x)
          .map(x => x + 1)
          .join(',')}`,
      );
    else setActiveStep(stepIndex);
  };

  const dirtyStepHandler =
    stepIndex =>
    (isDirty = true) =>
      setDirtySteps(prev =>
        isDirty ? [...prev, stepIndex] : prev.filter(x => x !== stepIndex),
      );

  return (
    <Box height="100%" style={{ backgroundColor: 'white' }}>
      <LoadingBackdrop open={loading}>Fetching Appraisal</LoadingBackdrop>
      <Title
        title="Create a Used Vehicle"
        paddingLeft={isDesktop ? '2rem' : '1rem'}
        paddingTop={isDesktop ? '2rem' : '1rem'}
      />
      <Stepper
        activeStep={activeStep}
        orientation="vertical"
        nonLinear
        style={{
          paddingLeft: `${isDesktop ? '2rem' : ' '}`,
        }}
      >
        <StepOne
          appraisal={appraisal}
          label="Scan/Enter Vehicle VIN"
          onNext={goNext}
          setAppraisalId={setAppraisalId}
          setActiveStep={checkDirtySetActiveStep}
          setIsDirty={dirtyStepHandler(0)}
        />
        <StepTwo
          appraisal={appraisal}
          label="Edit Vehicle Information"
          onBack={goBack}
          onNext={goNext}
          setActiveStep={checkDirtySetActiveStep}
          setIsDirty={dirtyStepHandler(1)}
          stepDirty={dirtySteps.includes(1)}
        />
        <StepThree
          appraisal={appraisal}
          label="Complete Checklist"
          onBack={goBack}
          onNext={goNext}
          setActiveStep={checkDirtySetActiveStep}
          setIsDirty={dirtyStepHandler(2)}
          stepDirty={dirtySteps.includes(2)}
        />
        <StepFour
          appraisal={appraisal}
          label="Upload Photos"
          onBack={goBack}
          onNext={goNext}
          setActiveStep={checkDirtySetActiveStep}
          setIsDirty={dirtyStepHandler(3)}
          stepDirty={dirtySteps.includes(3)}
        />
        <StepFive
          appraisal={appraisal}
          label="Create Appraisal"
          onBack={goBack}
          onNext={goNext}
          setActiveStep={checkDirtySetActiveStep}
          setIsDirty={dirtyStepHandler(4)}
          stepDirty={dirtySteps.includes(4)}
        />
        <StepSix
          appraisal={appraisal}
          label="Buyer/Seller Information"
          onBack={goBack}
          onNext={() => history.push(`/used-vehicles/created/${appraisalId}`)}
          setActiveStep={checkDirtySetActiveStep}
          setIsDirty={dirtyStepHandler(5)}
          stepDirty={dirtySteps.includes(5)}
        />
      </Stepper>
    </Box>
  );
};

export default CreateVehicle;
