import React, { useRef, useState } from 'react';

/* external */
import { useSnackbar } from 'notistack';
import { cloneDeep } from 'lodash';

/* Material UI */
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';

/* internal */
import InspectionCheckboxes from '../InspectionCheckboxes';

const SeasonReadyStartUpForm = props => {
  const { enqueueSnackbar } = useSnackbar();

  // form fields
  const chassis = {
    c1: 'Tire pressure',
    c2: 'Wheel lugs (torqued)',
    c3: 'Safety chains',
    c4: 'Suspension hangers',
    c5: 'Coupler/king pin',
  };
  const propane = {
    p1: 'LP detector/co detector',
    p2: 'Manometer reading (10 min test)',
    p3: 'Regulator',
  };
  const appliances = {
    a1: 'Fridge LP/110v',
    a2: 'Furnace',
    a3: 'Water heater LP/110v',
    a4: 'Dishwasher (if applicable)',
    a5: 'Microwave',
    a6: 'A/C (10 min test)',
    a7: 'Range hood',
  };
  const exterior = {
    e1: 'Electric jacks',
    e2: 'Manual jacks',
    e3: 'Entry steps',
    e4: 'Awning(s)/hardware',
    e5: 'Slide out(s)',
    e6: 'Slide out seal(s)',
    e7: 'Roof seals',
    e8: 'Exterior sealant',
  };
  const brakes = {
    b1: 'Emergency brakes',
    b2: 'Electric brakes lock up test',
    b3: 'Breakaway switch',
    b4: 'Brake wiring',
    b5: 'Brake function through plug',
    b6: 'Brake function breakaway cable',
    b7: 'Jack up and check end play',
    b8: 'Fresh tank drained',
  };
  const lights = {
    l1: 'Brake lights',
    l2: 'Signal lights',
    l3: 'Clearance lights/tail lights',
    l4: 'Reverse lights',
    l5: 'Docking lights',
  };
  const water = {
    w1: 'Pressure test water lines',
    w2: 'Gate valve operation',
    w3: 'Anode rod condition',
  };
  const additional = {
    add1: 'Dewinterize',
    add2: 'Holding tanks empty',
    add3: 'Fresh tank filled as per customer request',
    add4: 'Fuse kit provided',
    add5: 'Moisture remover provided x2',
    add6: 'Wash & wax pack provided x2',
    add7: 'Foaming RV toilet cleaner provided x2',
  };

  let chassisObj = cloneDeep(chassis);
  let propaneObj = cloneDeep(propane);
  let appliancesObj = cloneDeep(appliances);
  let exteriorObj = cloneDeep(exterior);
  let brakesObj = cloneDeep(brakes);
  let lightsObj = cloneDeep(lights);
  let waterObj = cloneDeep(water);
  let additionalObj = cloneDeep(additional);

  Object.entries(chassisObj).map(([name]) => (chassisObj[name] = 'Pass'));
  Object.entries(propaneObj).map(([name]) => (propaneObj[name] = 'Pass'));
  Object.entries(appliancesObj).map(([name]) => (appliancesObj[name] = 'Pass'));
  Object.entries(exteriorObj).map(([name]) => (exteriorObj[name] = 'Pass'));
  Object.entries(brakesObj).map(([name]) => (brakesObj[name] = 'Pass'));
  Object.entries(lightsObj).map(([name]) => (lightsObj[name] = 'Pass'));
  Object.entries(waterObj).map(([name]) => (waterObj[name] = 'Pass'));
  Object.entries(additionalObj).map(([name]) => (additionalObj[name] = 'Yes'));

  const [chassisValues, setChassisValues] = useState(chassisObj);
  const [propaneValues, setPropaneValues] = useState(propaneObj);
  const [appliancesValues, setAppliancesValues] = useState(appliancesObj);
  const [exteriorValues, setExteriorValues] = useState(exteriorObj);
  const [brakesValues, setBrakesValues] = useState(brakesObj);
  const [lightsValues, setLightsValues] = useState(lightsObj);
  const [waterValues, setWaterValues] = useState(waterObj);
  const [additionalValues, setAdditionalValues] = useState(additionalObj);

  const handleChassis = e => {
    const { name, value } = e.target;
    setChassisValues(prevChassisValues => ({
      ...prevChassisValues,
      [name]: value,
    }));
  };
  const handlePropane = e => {
    const { name, value } = e.target;
    setPropaneValues(prevPropaneValues => ({
      ...prevPropaneValues,
      [name]: value,
    }));
  };
  const handleAppliances = e => {
    const { name, value } = e.target;
    setAppliancesValues(prevAppliancesValues => ({
      ...prevAppliancesValues,
      [name]: value,
    }));
  };
  const handleExterior = e => {
    const { name, value } = e.target;
    setExteriorValues(prevExteriorValues => ({
      ...prevExteriorValues,
      [name]: value,
    }));
  };
  const handleBrakes = e => {
    const { name, value } = e.target;
    setBrakesValues(prevBrakesValues => ({
      ...prevBrakesValues,
      [name]: value,
    }));
  };
  const handleLights = e => {
    const { name, value } = e.target;
    setLightsValues(prevLightsValues => ({
      ...prevLightsValues,
      [name]: value,
    }));
  };
  const handleWater = e => {
    const { name, value } = e.target;
    setWaterValues(prevWaterValues => ({
      ...prevWaterValues,
      [name]: value,
    }));
  };
  const handleAdditional = e => {
    const { name, value } = e.target;
    setAdditionalValues(prevAdditionalValues => ({
      ...prevAdditionalValues,
      [name]: value,
    }));
  };

  const [consent, setConsent] = useState(false);
  const handleConsent = () => {
    setConsent(consent => !consent);
  };

  const [confirm, setConfirm] = useState(false);
  const handleConfirm = () => {
    setConfirm(confirm => !confirm);
  };

  const wholeForm = useRef(null);

  const handleSubmit = e => {
    e.preventDefault();

    if (!consent) {
      enqueueSnackbar(`Please check 'I agree' and then submit the inspection!`);
      return;
    }

    Object.entries(chassisObj).map(
      ([name]) =>
        (chassisValues[name + '_hours'] =
          wholeForm.current[name + '_hours'].value),
    );
    Object.entries(propaneObj).map(
      ([name]) =>
        (propaneValues[name + '_hours'] =
          wholeForm.current[name + '_hours'].value),
    );
    Object.entries(appliancesObj).map(
      ([name]) =>
        (appliancesValues[name + '_hours'] =
          wholeForm.current[name + '_hours'].value),
    );
    Object.entries(exteriorObj).map(
      ([name]) =>
        (exteriorValues[name + '_hours'] =
          wholeForm.current[name + '_hours'].value),
    );
    Object.entries(brakesObj).map(
      ([name]) =>
        (brakesValues[name + '_hours'] =
          wholeForm.current[name + '_hours'].value),
    );
    Object.entries(lightsObj).map(
      ([name]) =>
        (lightsValues[name + '_hours'] =
          wholeForm.current[name + '_hours'].value),
    );
    Object.entries(waterObj).map(
      ([name]) =>
        (waterValues[name + '_hours'] =
          wholeForm.current[name + '_hours'].value),
    );

    const values = {
      ...chassisValues,
      ...propaneValues,
      ...appliancesValues,
      ...exteriorValues,
      ...brakesValues,
      ...lightsValues,
      ...waterValues,
      ...additionalValues,
    };
    const form_data = {
      seasonready: values,
      technician_comments: wholeForm.current['technician_comments'].value,
      amvic_number: wholeForm.current['amvic_number'].value,
    };

    props.formData(form_data);
    props.formClose();
  };

  const GreenButton = withStyles({
    root: {
      color: '#fff',
      backgroundColor: '#198754',
      '&:hover': {
        backgroundColor: '#157347',
      },
    },
  })(Button);

  return (
    <form onSubmit={handleSubmit} ref={wholeForm}>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Chassis</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(chassis).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6} style={{ display: 'flex' }}>
                    <InspectionCheckboxes
                      valueGroup={chassisValues}
                      handleGroup={handleChassis}
                      name={name}
                      orange={false}
                      greenValue="Pass"
                      redValue="Fail"
                    />
                    <TextField
                      label="Hours"
                      name={name + '_hours'}
                      fullWidth
                      style={{ marginLeft: '.5rem' }}
                      defaultValue="0"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Propane</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(propane).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6} style={{ display: 'flex' }}>
                    <InspectionCheckboxes
                      valueGroup={propaneValues}
                      handleGroup={handlePropane}
                      name={name}
                      orange={false}
                      greenValue="Pass"
                      redValue="Fail"
                    />
                    <TextField
                      label="Hours"
                      name={name + '_hours'}
                      fullWidth
                      style={{ marginLeft: '.5rem' }}
                      defaultValue="0"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Appliances</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(appliances).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6} style={{ display: 'flex' }}>
                    <InspectionCheckboxes
                      valueGroup={appliancesValues}
                      handleGroup={handleAppliances}
                      name={name}
                      orange={false}
                      greenValue="Pass"
                      redValue="Fail"
                    />
                    <TextField
                      label="Hours"
                      name={name + '_hours'}
                      fullWidth
                      style={{ marginLeft: '.5rem' }}
                      defaultValue="0"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Exterior</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(exterior).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6} style={{ display: 'flex' }}>
                    <InspectionCheckboxes
                      valueGroup={exteriorValues}
                      handleGroup={handleExterior}
                      name={name}
                      orange={false}
                      greenValue="Pass"
                      redValue="Fail"
                    />
                    <TextField
                      label="Hours"
                      name={name + '_hours'}
                      fullWidth
                      style={{ marginLeft: '.5rem' }}
                      defaultValue="0"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Brakes</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(brakes).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6} style={{ display: 'flex' }}>
                    <InspectionCheckboxes
                      valueGroup={brakesValues}
                      handleGroup={handleBrakes}
                      name={name}
                      orange={false}
                      greenValue="Pass"
                      redValue="Fail"
                    />
                    <TextField
                      label="Hours"
                      name={name + '_hours'}
                      fullWidth
                      style={{ marginLeft: '.5rem' }}
                      defaultValue="0"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Running Lights</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(lights).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6} style={{ display: 'flex' }}>
                    <InspectionCheckboxes
                      valueGroup={lightsValues}
                      handleGroup={handleLights}
                      name={name}
                      orange={false}
                      greenValue="Pass"
                      redValue="Fail"
                    />
                    <TextField
                      label="Hours"
                      name={name + '_hours'}
                      fullWidth
                      style={{ marginLeft: '.5rem' }}
                      defaultValue="0"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Water System</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(water).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6} style={{ display: 'flex' }}>
                    <InspectionCheckboxes
                      valueGroup={waterValues}
                      handleGroup={handleWater}
                      name={name}
                      orange={false}
                      greenValue="Pass"
                      redValue="Fail"
                    />
                    <TextField
                      label="Hours"
                      name={name + '_hours'}
                      fullWidth
                      style={{ marginLeft: '.5rem' }}
                      defaultValue="0"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Additional</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(additional).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6} style={{ display: 'flex' }}>
                    <InspectionCheckboxes
                      valueGroup={additionalValues}
                      handleGroup={handleAdditional}
                      name={name}
                      orange={false}
                      greenValue="Yes"
                      redValue="No"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>

      <TextField
        label="Notes"
        multiline
        minRows={2}
        variant="outlined"
        name={'technician_comments'}
        fullWidth
        style={{ marginTop: '15px' }}
      />
      <Typography style={{ marginTop: '15px' }}>
        I verify the contents of this document are true and correct.
      </Typography>
      <FormControlLabel
        control={<Checkbox />}
        label="I agree"
        onChange={handleConsent}
        value={consent}
      />
      <br />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Technician Name"
            value={props.user.display_name}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Tech #"
            value={props.user.goUserProfile.profile.sales_licence}
            fullWidth
            name={'amvic_number'}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Date"
            value={new Date().toLocaleString()}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <Button
            type="button"
            variant="contained"
            style={{ marginRight: '15px' }}
            onClick={handleConfirm}
            color="default"
          >
            Cancel
          </Button>
          <Dialog
            open={confirm}
            onClose={handleConfirm}
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Current inspection will be closed and unsaved data will be gone.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleConfirm}>Cancel</Button>
              <Button onClick={props.formClose} color="secondary">
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
          <GreenButton type="submit" variant="contained">
            Save
          </GreenButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default SeasonReadyStartUpForm;
