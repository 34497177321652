import React, { useEffect } from 'react';

import { formatPrice } from 'utils';

// pretty cool little function yoinked from
// https://dev.to/selbekk/persisting-your-react-state-in-9-lines-of-code-9go
export function usePersistedState(key, defaultValue) {
  const [state, setState] = React.useState(
    JSON.parse(sessionStorage.getItem(key)) || defaultValue,
  );
  useEffect(() => {
    sessionStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);
  return [state, setState];
}

export const tubedPercent = metrics =>
  metrics?.total_count !== 0
    ? Math.round((100 * metrics.total_tubed_count) / metrics.total_count)
    : 0;

export const generatePercent = (total, part) =>
  total !== 0 ? `${Math.round((100 * part) / total)}%` : `0%`;

export const generateAverage = (gross, count, nullValue = '0') =>
  count !== 0 && gross !== 0
    ? formatPrice(Math.round(gross / count))
    : nullValue;

export const displayValue = value => (value !== 0 ? formatPrice(value) : '-');
