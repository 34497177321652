import React from 'react';

/* Material UI */
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';

const YesNoRadioGroup = ({ onChange, value, RadioProps }) => (
  <RadioGroup
    onChange={e => onChange(e.target.value === 'true')}
    style={{ display: 'flex', flexDirection: 'row' }}
    value={String(value)}
  >
    <FormControlLabel
      value="true"
      control={<Radio {...RadioProps} />}
      label="Yes"
    />
    <FormControlLabel
      value="false"
      control={<Radio {...RadioProps} />}
      label="No"
    />
  </RadioGroup>
);

export default YesNoRadioGroup;
