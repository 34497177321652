// External
import { useMutation, useQuery } from '@apollo/react-hooks';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React from 'react';

// Material UI
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

// Internal
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import DealerPicker from 'components/MaterialUI/DealerPicker';
import { useUserContext } from 'components/MaterialUI/UserContext';

import { usePersistedState } from 'utils';

import { TaskStatus, TaskStatusColor } from '../constants';
import ManagerDashboardDesktop from './manager_dashboard/ManagerDashboardDesktop';
import ManagerDashboardMobile from './manager_dashboard/ManagerDashboardMobile';
import { DASHBOARD_QUERY, UPDATE_TASK_QUERY } from './queries';
import SalesDashboardDesktop from './SalesDashboardDesktop';
import SalesDashboardMobile from './SalesDashboardMobile';
import { RoleGroup } from 'constants.js';

const useStyles = makeStyles(theme => ({
  left: {
    borderLeft: '1px solid black',
    borderBottom: '1px solid black',
  },
  middle: {
    borderBottom: '1px solid black',
    borderLeft: '1px solid black',
    borderRight: '1px solid black',
  },
  right: { borderRight: '1px solid black', borderBottom: '1px solid black' },
  totalLeft: {
    borderLeft: '1px solid black',
    borderBottom: '0px solid black',
  },
  totalMiddle: {
    borderLeft: '1px solid black',
    borderRight: '1px solid black',
    borderBottom: '0px solid black',
  },
  totalRight: {
    borderRight: '1px solid black',
    borderBottom: '0px solid black',
  },
  buttonColor: {
    color: '#FFFFFF',
  },
}));

const statusColor = (status, due_date) => {
  const overdue = moment(due_date) < moment();
  const highLightColor =
    (overdue && TaskStatusColor.RED) ||
    (status === TaskStatus.TODO && TaskStatusColor.YELLOW) ||
    TaskStatusColor.GREY;
  return highLightColor;
};

const MainDashboardLayout = () => {
  const { dealerId, dealers } = useDealerContext();
  const { enqueueSnackbar } = useSnackbar();
  const { currentUser } = useUserContext();
  const { buttonColor, ...classes } = useStyles();
  const isDesktop = useMediaQuery(theme => theme.breakpoints.up('sm'));

  const dealerName = dealers.find(x => x.dealer_id === dealerId)?.dealer_name;

  const [dateFromFilter, setDateFromFilter] = usePersistedState(
    'salesDashboard.dateFromFilter',
    moment().startOf('month').format('YYYY-MM-DD'),
  );

  const [dateToFilter, setDateToFilter] = usePersistedState(
    'salesDashboard.dateToFilter',
    moment().format('YYYY-MM-DD'),
  );

  const handleDateFromChange = date => {
    const newFromDate =
      dateToFilter > moment(date).format('YYYY-MM-DD')
        ? moment(date).format('YYYY-MM-DD')
        : moment(dateToFilter).subtract(1, 'days').format('YYYY-MM-DD');
    setDateFromFilter(newFromDate);
  };

  const handleDateToChange = date => {
    const newToDate =
      dateFromFilter < moment(date).format('YYYY-MM-DD')
        ? moment(date).format('YYYY-MM-DD')
        : moment(dateFromFilter).add(1, 'days').format('YYYY-MM-DD');
    setDateToFilter(newToDate);
  };

  const { loading, data, refetch } = useQuery(DASHBOARD_QUERY, {
    variables: {
      dealerId,
      date_from: dateFromFilter,
      date_to: dateToFilter,
    },
    skip: !RoleGroup.SALES_PEOPLE.includes(currentUser?.role),
  });

  const [updateTask] = useMutation(UPDATE_TASK_QUERY, {
    onCompleted: data => {
      enqueueSnackbar('Task updated', { variant: 'success' });
      refetch();
    },
    onError: err => {
      enqueueSnackbar(`Could not update task error: ${err}`, {
        variant: 'error',
      });
    },
  });

  const handleUpdateStatus = (_id, status) => {
    updateTask({
      variables: {
        _id,
        input: {
          status: status,
        },
      },
    });
  };

  return (
    <Box padding={1}>
      <Box>
        <DealerPicker />
      </Box>
      <Box paddingTop={2} paddingLeft={1}>
        <Typography variant="h3" style={{ fontWeight: 'bold' }}>
          Hello {currentUser?.goUserProfile?.profile?.first_name}!
        </Typography>
      </Box>
      {/* <Box paddingTop={2} paddingLeft={1}>
        <Alert severity="info">
          <AlertTitle>Announcements</AlertTitle>
          This is an announcement!
        </Alert>
      </Box> */}
      {RoleGroup.SALES_PEOPLE.includes(currentUser?.role) && (
        <>
          {isDesktop && (
            <SalesDashboardDesktop
              data={data}
              loading={loading}
              handleUpdateStatus={handleUpdateStatus}
              dealerName={dealerName}
              buttonColor={buttonColor}
              statusColor={statusColor}
              dealerId={dealerId}
              selectedUser={currentUser.username}
              dateFromFilter={dateFromFilter}
              dateToFilter={dateToFilter}
              handleDateFromChange={handleDateFromChange}
              handleDateToChange={handleDateToChange}
            />
          )}
          {!isDesktop && (
            <SalesDashboardMobile
              data={data}
              loading={loading}
              handleUpdateStatus={handleUpdateStatus}
              dealerName={dealerName}
              buttonColor={buttonColor}
              statusColor={statusColor}
              dealerId={dealerId}
              selectedUser={currentUser.username}
              dateFromFilter={dateFromFilter}
              dateToFilter={dateToFilter}
              handleDateFromChange={handleDateFromChange}
              handleDateToChange={handleDateToChange}
            />
          )}
        </>
      )}
      {RoleGroup.MANAGERS.includes(currentUser?.role) && (
        <>
          {isDesktop && (
            <ManagerDashboardDesktop classes={classes} dealerId={dealerId} />
          )}
          {!isDesktop && (
            <ManagerDashboardMobile classes={classes} dealerId={dealerId} />
          )}
        </>
      )}
    </Box>
  );
};

export default MainDashboardLayout;
