import { cloneDeep } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useRef, useState } from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { CheckBox, ExpandMore } from '@mui/icons-material';

import InspectionCheckboxes from '../InspectionCheckboxes';

const BMWInspectionForm = props => {
  const { enqueueSnackbar } = useSnackbar();

  // BMW Inspection form fields
  const timingBelt = {
    TB1: 'Timing belt due for replacement?*',
  };

  const roadTest = {
    bmwinsp1: 'Verify car/pedal behaviour under braking conditions',
    bmwinsp2: 'Verify proper operation of wiper blades (FRONT/REAR*)',
    bmwinsp3: 'Verify engine performance',
    bmwinsp4: 'Verify transmission and clutch* performance',
    bmwinsp5: 'Verify road handling, steering and comfort quality',
    bmwinsp6:
      'Verify proper operation of emergency brake system; Adjust handle height if required',
  };
  const roadTestAlignment = {
    alignment1: 'Alignment recommended based on test-drive?',
  };

  const hoist = {
    bmwinsp7: 'Front left',
    bmwinsp8: 'Front right',
    bmwinsp9: 'Rear left',
    bmwinsp10: 'Rear right',
    bmwinsp11: 'Front left',
    bmwinsp12: 'Front right',
    bmwinsp13: 'Rear left',
    bmwinsp14: 'Rear right',
    bmwinsp15: 'Verify front rotors pads and calipers condition',
    bmwinsp16: 'Verify rear rotors pads and calipers condition',
    bmwinsp17: 'Verify differential (sweating, liquid/leaking traces)',
    bmwinsp18: 'Verify level of differential fluid',
    bmwinsp19: 'Verify level of transmission fluid',
    bmwinsp20: 'Verify condition of exhaust system',
    bmwinsp21: 'Verify condition of front ball joints',
    bmwinsp22: 'Verify condition of front tie-rod ends',
    bmwinsp23: 'Verify condition of sway-bar bushings and links',
    bmwinsp24: 'Verify condition of front suspension (shocks, springs)',
    bmwinsp25: 'Verify condition of rear suspension (shocks, springs)',
    bmwinsp26: 'Verify condition of fuel lines',
    bmwinsp27: 'Verify condition of drive shafts and insulators',
    bmwinsp28: 'Verify condition of engine and transmission mounts',
    bmwinsp29: 'Verify condition of clutch slave cylinder*',
  };
  const hoistAlignment = {
    alignment2: 'Alignment recommended based on wear pattern?',
  };
  const hoistOtherCheck = {
    check1: 'Adjust all tires to recommended air pressure, including spare',
  };

  const inside = {
    bmwinsp30: 'Verify proper operation of horn',
    bmwinsp31: 'Verify proper operation of seat belts',
    bmwinsp32: 'Verify proper operation of the central locking system',
    bmwinsp33: 'Verify proper operation of electrical mirrors',
    bmwinsp34:
      'Verify proper operation of controls and instruments (incl. lighting)',
    bmwinsp35: 'Verify temperature from ducts at maximum heat',
    bmwinsp36: 'Verify A/C operation at the coldest setting',
    bmwinsp37: 'Verify proper operation of windows (incl. sunroof*)',
  };

  const exterior = {
    bmwinsp38:
      'Verify proper operation of front headlamps (DRL, low and high beams)',
    bmwinsp39:
      'Verify proper operation of rear brakelights (includes center-mounted 3rd light)',
    bmwinsp40: 'Verify proper operation of front & rear turn signals',
    bmwinsp41: 'Verify proper operation of side repeater lights (L+R)',
    bmwinsp42: 'Verify proper operation of four way flashers',
    bmwinsp43: 'Verify condition of wiper blades (rubber inserts*)',
    bmwinsp44:
      'Verify condition of windshield (cracks, bulls-eye, sandblasting)',
    bmwinsp45: 'Presence of rust on body panels, incl. hood',
    bmwinsp46:
      'Verify windshield-washer jets alignment (including headlights*)',
    bmwinsp47: 'Verify proper operation of rear taillights',
    bmwinsp48:
      'Verify condition of battery cables and terminals (tighten if required)',
  };

  const extOtherChecks = {
    check2: 'Lubricate hood catch(es)',
    check3: 'Lubricate door brakes',
    check4: 'Lubricate door pivots',
    check5: 'Lubricate door catch',
    check6: 'Lubricate trunk/hatch catch',
  };

  const underHood = {
    bmwinsp49: 'Verify condition of air filter',
    bmwinsp50: 'Verify condition of belt(s)*',
    bmwinsp51: 'Verify condition of hoses',
    bmwinsp52: 'Verify tightness of hose clamps.  Re-tighten as required',
    bmwinsp53: 'Verify battery liquid level/magic eye*',
    bmwinsp54: 'Perform battery load test',
    bmwinsp55: 'Verify condition of radiator',
    bmwinsp56: 'Verify anti-freeze concentration/strength',
  };

  const underHoodOtherChecks = {
    check7: 'Verify windshield-washer level. Top-up as required.',
    check8:
      'Top-up fluids as required (fees may apply*) (Motor oil, coolant, power steering fluiddifferential fluid, brake fluid)',
    check9: '(Battery) - Charge as required, re-check condition if required',
  };

  let timingBeltObj = cloneDeep(timingBelt);
  let roadTestObj = cloneDeep(roadTest);
  let roadTestAlignmentObj = cloneDeep(roadTestAlignment);
  let hoistAlignmentObj = cloneDeep(hoistAlignment);
  let hoistObj = cloneDeep(hoist);
  let hoistOtherCheckObj = cloneDeep(hoistOtherCheck);
  let insideObj = cloneDeep(inside);
  let exteriorObj = cloneDeep(exterior);
  let extOtherChecksObj = cloneDeep(extOtherChecks);
  let underHoodObj = cloneDeep(underHood);
  let underHoodOtherChecksObj = cloneDeep(underHoodOtherChecks);

  // Set all "checkbox" values to "unchecked" on form open
  Object.entries(timingBeltObj).map(([name]) => (timingBeltObj[name] = ''));
  Object.entries(roadTestObj).map(([name]) => (roadTestObj[name] = ''));
  Object.entries(roadTestAlignmentObj).map(
    ([name]) => (roadTestAlignmentObj[name] = ''),
  );
  Object.entries(hoistObj).map(([name]) => (hoistObj[name] = ''));
  Object.entries(hoistAlignmentObj).map(
    ([name]) => (hoistAlignmentObj[name] = ''),
  );
  Object.entries(hoistOtherCheckObj).map(
    ([name]) => (hoistOtherCheckObj[name] = ''),
  );
  Object.entries(insideObj).map(([name]) => (insideObj[name] = ''));
  Object.entries(exteriorObj).map(([name]) => (exteriorObj[name] = ''));
  Object.entries(extOtherChecksObj).map(
    ([name]) => (extOtherChecksObj[name] = ''),
  );
  Object.entries(underHoodObj).map(([name]) => (underHoodObj[name] = ''));
  Object.entries(underHoodOtherChecksObj).map(
    ([name]) => (underHoodOtherChecksObj[name] = ''),
  );

  const [timingBeltValue, setTimingBeltValue] = useState(timingBeltObj);
  const [roadTestValues, setRoadTestValues] = useState(roadTestObj);
  const [rtAlignmentValue, setRTAlignmentValue] =
    useState(roadTestAlignmentObj);
  const [hoistValues, setHoistValues] = useState(hoistObj);
  const [hAlignmentValue, setHAlignmentValue] = useState(hoistAlignmentObj);
  const [hoistOtherCheckValue, sethoistOtherCheckValue] =
    useState(hoistOtherCheckObj);
  const [insideValues, setInsideValues] = useState(insideObj);
  const [exteriorValues, setExteriorValues] = useState(exteriorObj);
  const [extOtherChecksValues, setExtOtherChecksValues] =
    useState(extOtherChecksObj);
  const [underHoodValues, setUnderHoodValues] = useState(underHoodObj);
  const [hoodOtherChecksValues, setHoodOtherChecksValues] = useState(
    underHoodOtherChecksObj,
  );

  const handleTimingBelt = e => {
    const { name, value, checked } = e.target;
    setTimingBeltValue(prevTimingBeltValue => ({
      ...prevTimingBeltValue,
      [name]: checked ? value : '',
    }));
  };

  const handleRoadTest = e => {
    const { name, value, checked } = e.target;
    setRoadTestValues(prevRoadTestValues => ({
      ...prevRoadTestValues,
      [name]: checked ? value : '',
    }));
  };

  const handleRTAlignment = e => {
    const { name, value, checked } = e.target;
    setRTAlignmentValue(prevRTAlignmentValue => ({
      ...prevRTAlignmentValue,
      [name]: checked ? value : '',
    }));
  };

  const handleHoist = e => {
    const { name, value, checked } = e.target;
    setHoistValues(prevHoistValues => ({
      ...prevHoistValues,
      [name]: checked ? value : '',
    }));
  };

  const handleHAlignment = e => {
    const { name, value, checked } = e.target;
    setHAlignmentValue(prevHAlignmentValue => ({
      ...prevHAlignmentValue,
      [name]: checked ? value : '',
    }));
  };

  const handleHoistOtherCheck = e => {
    const { name, value, checked } = e.target;
    sethoistOtherCheckValue(prevHoistOtherCheckValue => ({
      ...prevHoistOtherCheckValue,
      [name]: checked ? value : '',
    }));
  };

  const handleInside = e => {
    const { name, value, checked } = e.target;
    setInsideValues(prevInsideValues => ({
      ...prevInsideValues,
      [name]: checked ? value : '',
    }));
  };

  const handleExterior = e => {
    const { name, value, checked } = e.target;
    setExteriorValues(prevExteriorValues => ({
      ...prevExteriorValues,
      [name]: checked ? value : '',
    }));
  };

  const handleExtOtherChecks = e => {
    const { name, value, checked } = e.target;
    setExtOtherChecksValues(prevExtChecksValues => ({
      ...prevExtChecksValues,
      [name]: checked ? value : '',
    }));
  };

  const handleUnderHood = e => {
    const { name, value, checked } = e.target;
    setUnderHoodValues(prevUnderHoodValues => ({
      ...prevUnderHoodValues,
      [name]: checked ? value : '',
    }));
  };

  const handleUnderHoodChecks = e => {
    const { name, value, checked } = e.target;
    setHoodOtherChecksValues(prevHoodOtherChecksValues => ({
      ...prevHoodOtherChecksValues,
      [name]: checked ? value : '',
    }));
  };

  const [consent, setConsent] = useState(false);
  const handleConsent = () => {
    setConsent(consent => !consent);
  };

  const [confirm, setConfirm] = useState(false);
  const handleConfirm = () => {
    setConfirm(confirm => !confirm);
  };

  const wholeForm = useRef(null);

  const handleSubmit = e => {
    e.preventDefault();

    if (!consent) {
      enqueueSnackbar(`Please check 'I agree' and then submit the inspection!`);
      return;
    }

    roadTestValues['remaining_km'] = wholeForm.current['remaining_km'].value;

    roadTestValues['comments1'] = wholeForm.current['comments1'].value;

    hoistValues['tread1'] = wholeForm.current['tread1'].value;
    hoistValues['tread2'] = wholeForm.current['tread2'].value;
    hoistValues['tread3'] = wholeForm.current['tread3'].value;
    hoistValues['tread4'] = wholeForm.current['tread4'].value;
    hoistValues['comments2'] = wholeForm.current['comments2'].value;

    insideValues['temp1'] = wholeForm.current['temp1'].value;
    insideValues['temp2'] = wholeForm.current['temp2'].value;
    insideValues['comments3'] = wholeForm.current['comments3'].value;

    exteriorValues['comments4'] = wholeForm.current['comments4'].value;

    underHoodValues['result1'] = wholeForm.current['result1'].value;
    underHoodValues['result2'] = wholeForm.current['result2'].value;

    const values = {
      ...roadTestValues,
      ...hoistValues,
      ...insideValues,
      ...exteriorValues,
      ...underHoodValues,
    };

    const form_data = {
      bmwinsp: values,
      amvic_number: wholeForm.current['amvic_number'].value,
      hoist_other: hoistOtherCheckValue,
      ext_other: extOtherChecksValues,
      hood_other: hoodOtherChecksValues,
      timing_belt: timingBeltValue,
      rt_alignment: rtAlignmentValue,
      h_alignment: hAlignmentValue,
    };
    props.formData(form_data);
    props.formClose();
  };

  const GreenButton = withStyles({
    root: {
      color: '#fff',
      backgroundColor: '#198754',
      '&:hover': {
        backgroundColor: '#157347',
      },
    },
  })(Button);

  const treadDepthFields = ['bmwinsp11', 'bmwinsp12', 'bmwinsp13', 'bmwinsp14'];

  return (
    <form onSubmit={handleSubmit} ref={wholeForm}>
      <Card>
        <CardContent>
          <Box mb="10px">
            <Typography>
              <b>Legend</b>
            </Typography>
          </Box>
          <Box display="flex" p="10px">
            <Box display="flex" mr="20px">
              <CheckBox style={{ color: '#43a047', marginRight: '5px' }} />{' '}
              <Typography>Good</Typography>
            </Box>
            <Box display="flex" mr="20px">
              <CheckBox style={{ color: '#FF8C00', marginRight: '5px' }} />{' '}
              <Typography>Service Required</Typography>
            </Box>
            <Box display="flex" mr="20px">
              <CheckBox style={{ color: '#FF0000', marginRight: '5px' }} />{' '}
              <Typography>Repaired</Typography>
            </Box>
          </Box>
          <Box p="10px">
            <Typography>* if applicable</Typography>
          </Box>
        </CardContent>
      </Card>
      {/* Service Indicator/Maintenance System */}
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography>
            <b>SERVICE INDICATOR/MAINTENANCE SYSTEM</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs={12} style={{ paddingLeft: '1rem' }}>
              <TextField
                label="Remaining Km until next service*"
                name={'remaining_km'}
                fullWidth
              />
            </Grid>
            {Object.entries(timingBelt).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid
                    item
                    xs={6}
                    style={{ paddingLeft: '1rem', paddingBottom: '.5rem' }}
                  >
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={timingBeltValue}
                      handleGroup={handleTimingBelt}
                      name={name}
                      red={false}
                      white={true}
                      whiteValue={'na'}
                      orangeValue={'Yes'}
                      greenValue={'No'}
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      {/* Road Test Section */}
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography>
            <b>ROAD TEST</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center" spacing={1}>
            {Object.entries(roadTest).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid
                    item
                    xs={6}
                    style={{ paddingLeft: '1rem', paddingBottom: '.5rem' }}
                  >
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={roadTestValues}
                      handleGroup={handleRoadTest}
                      name={name}
                      orangeValue={'Service Required'}
                      greenValue={'Good'}
                      redValue={'Repaired'}
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
            {Object.entries(roadTestAlignment).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid
                    item
                    xs={6}
                    style={{ paddingLeft: '1rem', paddingBottom: '.5rem' }}
                  >
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={rtAlignmentValue}
                      handleGroup={handleRTAlignment}
                      name={name}
                      red={false}
                      orange={false}
                      green={false}
                      greenOther={true}
                      redOther={true}
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
            <Grid item xs={12}>
              <TextField
                label={'Comments'}
                name={'comments1'}
                multiline
                fullWidth
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      {/* On The Hoist Section */}
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography>
            <b>ON THE HOIST</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center" spacing={1}>
            {Object.entries(hoistOtherCheck).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid
                    item
                    xs={6}
                    style={{ paddingLeft: '1rem', paddingBottom: '.5rem' }}
                  >
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={hoistOtherCheckValue}
                      handleGroup={handleHoistOtherCheck}
                      name={name}
                      orange={false}
                      red={false}
                      greenValue={'Yes'}
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
            {Object.entries(hoist).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  {(name === 'bmwinsp7' || name === 'bmwinsp11') && (
                    <Grid
                      item
                      xs={12}
                      style={{ paddingLeft: '1rem', paddingBottom: '.5rem' }}
                    >
                      <Typography>
                        {name === 'bmwinsp7'
                          ? 'Verify wear pattern of tire tread (irregular?)'
                          : 'Verify tread depth'}
                      </Typography>
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={treadDepthFields.includes(name) ? 4 : 6}
                    style={{ paddingLeft: '1rem', paddingBottom: '.5rem' }}
                  >
                    {display_name}
                  </Grid>
                  <Grid item xs={treadDepthFields.includes(name) ? 4 : 6}>
                    <InspectionCheckboxes
                      valueGroup={hoistValues}
                      handleGroup={handleHoist}
                      name={name}
                      orangeValue={'Service Required'}
                      greenValue={'Good'}
                      redValue={'Repaired'}
                    />
                  </Grid>
                  {name === 'bmwinsp10' && (
                    <>
                      {Object.entries(hoistAlignment).map(
                        ([name, display_name]) => {
                          return (
                            <React.Fragment key={name}>
                              <Grid
                                item
                                xs={6}
                                style={{
                                  paddingLeft: '1rem',
                                  paddingBottom: '.5rem',
                                }}
                              >
                                {display_name}
                              </Grid>
                              <Grid item xs={6}>
                                <InspectionCheckboxes
                                  valueGroup={hAlignmentValue}
                                  handleGroup={handleHAlignment}
                                  name={name}
                                  red={false}
                                  orange={false}
                                  green={false}
                                  greenOther={true}
                                  redOther={true}
                                />
                              </Grid>
                            </React.Fragment>
                          );
                        },
                      )}
                    </>
                  )}

                  {treadDepthFields.includes(name) && (
                    <Grid
                      item
                      xs={4}
                      style={{
                        paddingLeft: '1rem',
                        paddingBottom: '.5rem',
                        display: 'flex',
                        alignItems: 'flex-end',
                      }}
                    >
                      <TextField
                        label={display_name}
                        name={
                          'tread' +
                          (treadDepthFields.indexOf(name) + 1).toString()
                        }
                      />
                      <Typography variant="body2">/32"</Typography>
                    </Grid>
                  )}
                </React.Fragment>
              );
            })}
            <Grid item xs={12}>
              <TextField
                label={'Comments'}
                name={'comments2'}
                multiline
                fullWidth
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      {/* Inside the Car Section */}
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography>
            <b>INSIDE THE CAR</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center" spacing={1}>
            {Object.entries(inside).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid
                    item
                    xs={name === 'bmwinsp35' || name === 'bmwinsp36' ? 4 : 6}
                    style={{ paddingLeft: '1rem', paddingBottom: '.5rem' }}
                  >
                    {display_name}
                  </Grid>
                  <Grid
                    item
                    xs={name === 'bmwinsp35' || name === 'bmwinsp36' ? 4 : 6}
                  >
                    <InspectionCheckboxes
                      valueGroup={insideValues}
                      handleGroup={handleInside}
                      name={name}
                      orangeValue={'Service Required'}
                      greenValue={'Good'}
                      redValue={'Repaired'}
                    />
                  </Grid>
                  {(name === 'bmwinsp35' || name === 'bmwinsp36') && (
                    <Grid
                      item
                      xs={4}
                      style={{
                        paddingLeft: '1rem',
                        paddingBottom: '.5rem',
                        display: 'flex',
                        alignItems: 'flex-end',
                      }}
                    >
                      <Typography
                        variant="body2"
                        style={{ marginRight: '5px' }}
                      >
                        Temperature:
                      </Typography>
                      <TextField
                        label={name === 'bmwinsp35' ? 'Heat' : 'A/C'}
                        name={name === 'bmwinsp35' ? 'temp1' : 'temp2'}
                      />
                    </Grid>
                  )}
                </React.Fragment>
              );
            })}
            <Grid item xs={12}>
              <TextField
                label={'Comments'}
                name={'comments3'}
                multiline
                fullWidth
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      {/* Body/Exterior Section */}
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography>
            <b>BODY/EXTERIOR</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center" spacing={1}>
            {Object.entries(extOtherChecks).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid
                    item
                    xs={6}
                    style={{ paddingLeft: '1rem', paddingBottom: '.5rem' }}
                  >
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={extOtherChecksValues}
                      handleGroup={handleExtOtherChecks}
                      name={name}
                      orange={false}
                      red={false}
                      greenValue={'Yes'}
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
            {Object.entries(exterior).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid
                    item
                    xs={6}
                    style={{ paddingLeft: '1rem', paddingBottom: '.5rem' }}
                  >
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={exteriorValues}
                      handleGroup={handleExterior}
                      name={name}
                      orangeValue={'Service Required'}
                      greenValue={'Good'}
                      redValue={'Repaired'}
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
            <Grid item xs={12}>
              <TextField
                label={'Comments'}
                name={'comments4'}
                multiline
                fullWidth
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      {/* Under the Hood Section */}
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography>
            <b>Under the Hood</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center" spacing={1}>
            {Object.entries(underHoodOtherChecks).map(
              ([name, display_name]) => {
                return (
                  <React.Fragment key={name}>
                    <Grid
                      item
                      xs={6}
                      style={{ paddingLeft: '1rem', paddingBottom: '.5rem' }}
                    >
                      {display_name}
                    </Grid>
                    <Grid item xs={6}>
                      <InspectionCheckboxes
                        valueGroup={hoodOtherChecksValues}
                        handleGroup={handleUnderHoodChecks}
                        name={name}
                        orange={false}
                        red={false}
                        greenValue={'Yes'}
                      />
                    </Grid>
                  </React.Fragment>
                );
              },
            )}
            {Object.entries(underHood).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid
                    item
                    xs={name === 'bmwinsp54' || name === 'bmwinsp56' ? 4 : 6}
                    style={{ paddingLeft: '1rem', paddingBottom: '.5rem' }}
                  >
                    {display_name}
                  </Grid>
                  <Grid
                    item
                    xs={name === 'bmwinsp54' || name === 'bmwinsp56' ? 4 : 6}
                  >
                    <InspectionCheckboxes
                      valueGroup={underHoodValues}
                      handleGroup={handleUnderHood}
                      name={name}
                      orangeValue={'Service Required'}
                      greenValue={'Good'}
                      redValue={'Repaired'}
                    />
                  </Grid>
                  {(name === 'bmwinsp54' || name === 'bmwinsp56') && (
                    <Grid
                      item
                      xs={4}
                      style={{
                        paddingLeft: '1rem',
                        paddingBottom: '.5rem',
                        display: 'flex',
                        alignItems: 'flex-end',
                      }}
                    >
                      <Typography
                        variant="body2"
                        style={{ marginRight: '5px' }}
                      >
                        Result:
                      </Typography>
                      <TextField
                        label={'Result'}
                        name={name === 'bmwinsp54' ? 'result1' : 'result2'}
                      />
                    </Grid>
                  )}
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      {/* Consent & Sign Off */}
      <Grid
        container
        spacing={1}
        alignItems="center"
        style={{ margin: '10px' }}
      >
        <Grid item xs={12} sm={9}>
          <Typography style={{ fontWeight: 'bold' }}>
            I verify the contents of this document are true and correct
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormControlLabel
            control={<Checkbox />}
            label="I agree"
            onChange={handleConsent}
            value={consent}
          />
          <br />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Technician Name"
            value={props.user.display_name}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Tech #"
            value={props.user.goUserProfile.profile.sales_licence}
            fullWidth
            name={'amvic_number'}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Date"
            value={new Date().toLocaleString()}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <Button
            type="button"
            variant="contained"
            style={{ marginRight: '15px' }}
            onClick={handleConfirm}
            color="default"
          >
            Cancel
          </Button>
          <Dialog
            open={confirm}
            onClose={handleConfirm}
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Current inspection will be closed and unsaved data will be gone.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleConfirm}>Cancel</Button>
              <Button onClick={props.formClose} color="secondary">
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
          <GreenButton type="submit" variant="contained">
            Save
          </GreenButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default BMWInspectionForm;
