import React from 'react';

/* external */
import { Route, Switch } from 'react-router-dom';

import CodeTablesContext from 'components/MaterialUI/CodeTablesContext';

/* internal */
import app from '../../app';
import BuyNowList from './BuyNowList';
import OfferDetails from './Dealer/OfferDetails';
import UsedVehiclesList from './UsedVehiclesList';
import CreateSuccess from './Wholesale/CreateSuccess';
import CreateVehicle from './Wholesale/CreateVehicle';
import WholesaleAppraisalDetails from './Wholesale/WholesaleAppraisalDetails';

const UsedVehicles = ({ match }) => {
  return (
    <CodeTablesContext>
      <app.components.Layout title="Used Vehicles">
        <Switch>
          <Route
            exact
            path={`${match.url}/`}
            render={() => <UsedVehiclesList />}
          />
          <Route
            exact
            path={`${match.url}/buy-now`}
            render={() => <BuyNowList />}
          />
          <Route
            exact
            path={`${match.url}/create-vehicle`}
            render={() => <CreateVehicle />}
          />
          <Route
            exact
            path={`${match.url}/create-vehicle/:id`}
            render={({ match }) => (
              <CreateVehicle id={parseInt(match.params.id, 10)} />
            )}
          />
          <Route
            exact
            path={`${match.url}/created/:id`}
            render={({ match, location }) => {
              const sell = new URLSearchParams(location.search).get('sell');
              const auction = new URLSearchParams(location.search).get(
                'auction',
              );
              return (
                <CreateSuccess
                  id={parseInt(match.params.id, 10)}
                  sell={sell ? Boolean(sell) : null}
                  auction={auction ? Boolean(auction) : null}
                />
              );
            }}
          />
          <Route
            exact
            path={`${match.url}/offers/:id`}
            render={({ match }) => (
              <OfferDetails id={parseInt(match.params.id, 10)} />
            )}
          />
          <Route
            exact
            path={`${match.url}/appraisals/:id`}
            render={({ match }) => (
              <WholesaleAppraisalDetails id={parseInt(match.params.id, 10)} />
            )}
          />
        </Switch>
      </app.components.Layout>
    </CodeTablesContext>
  );
};

export default UsedVehicles;
