import React, { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';

import { useUserContext } from 'components/MaterialUI/UserContext';

import { formatPrice } from 'utils';

import {
  cashAddonTotal,
  cashDiscountTotal,
  cashRebateTotal,
  financeAddonTotal,
  financeDiscountTotal,
  financeRebateTotal,
  leaseAddonTotal,
  leaseDiscountTotal,
} from '../../../utils';

const useStyles = makeStyles({
  pricingTotals: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  responsivePricingTotals: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '15px',
  },
});

const PurchaseOptions = ({
  regular_price,
  special_price,
  detailed_pricing,
  stock_type: stockType,
  default_down_payment,
  leaseRates,
  financeRates,
}) => {
  const [tabIndex, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const classes = useStyles();

  const mobile = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const mobileSm = useMediaQuery('(max-width:450px)');
  const mdBreakPoint = useMediaQuery('(max-width:800px)');

  const { me } = useUserContext();
  const { control } = useFormContext();

  const detailedPricing = useWatch({
    control,
    name: 'detailed_pricing',
    defaultValue: detailed_pricing,
  });

  const regularPrice = useWatch({
    control,
    name: 'regular_price',
    defaultValue: regular_price,
  });

  const specialPrice = useWatch({
    control,
    name: 'special_price',
    defaultValue: special_price,
  });

  const defaultDownPayment = useWatch({
    control,
    name: 'default_down_payment',
    defaultValue: default_down_payment,
  });

  const financePayments = financeRates
    ?.filter(x => x.rate > 0)
    ?.map(({ bi_weekly_payment }) => bi_weekly_payment);

  const leasePayments = leaseRates?.map(
    ({ bi_weekly_payment }) => bi_weekly_payment,
  );

  const lowestFinancePayment = financePayments
    ? Math.min(...financePayments)
    : 0;
  const lowestLeasePayment = leasePayments ? Math.min(...leasePayments) : 0;

  return (
    <Box
      style={{
        backgroundColor: 'white',
      }}
    >
      <TabContext value={tabIndex} name={'paymentOptionTabs'}>
        <Box style={{ backgroundColor: 'white' }}>
          <Tabs
            TabIndicatorProps={{ style: { background: '#ff5a00' } }}
            textColor="secondary"
            value={tabIndex}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons={`${mobileSm ? 'true' : 'false'}`}
          >
            <Tab
              label="Cash"
              value={0}
              style={{
                minWidth: `${stockType === 'NEW' ? '33%' : '50%'}`,
              }}
            />
            <Tab
              label="Finance"
              value={1}
              style={{
                minWidth: `${stockType === 'NEW' ? '33%' : '50%'}`,
              }}
            />
            {stockType === 'NEW' && (
              <Tab label="Lease" value={2} style={{ minWidth: '33%' }} />
            )}
          </Tabs>
        </Box>
        {/* ***** Cash Panel ***** */}
        <TabPanel value={0} name={'cashPanel'}>
          <Box
            className={
              mdBreakPoint
                ? classes.responsivePricingTotals
                : classes.pricingTotals
            }
          >
            <Box
              style={{
                marginRight: `${mdBreakPoint ? '10px' : '35px'}`,
              }}
            >
              {(!specialPrice || regularPrice === specialPrice) && (
                <Typography
                  style={{
                    fontWeight: 'bolder',
                  }}
                  variant="h4"
                >
                  {formatPrice(regularPrice)}
                </Typography>
              )}

              {specialPrice && specialPrice !== regularPrice && (
                <>
                  <Typography style={{ textDecoration: 'line-through' }}>
                    {formatPrice(regularPrice)}
                  </Typography>
                  <Typography
                    style={{
                      fontWeight: 'bolder',
                      fontSize: `${mobileSm ? '1.5rem' : ''}`,
                    }}
                    variant="h4"
                  >
                    {formatPrice(specialPrice)}
                  </Typography>
                </>
              )}
            </Box>

            <Box
              component="span"
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: 'column',
                rowGap: '10px',
                minWidth: `${mobile ? '200px' : ''}`,
              }}
            >
              <div className={classes.pricingTotals}>
                <Typography
                  style={{ fontWeight: 'bold', marginRight: '10px' }}
                  variant="body2"
                >
                  Vehicle Price
                </Typography>
                <Typography variant="body2">
                  {formatPrice(regularPrice)}
                </Typography>
              </div>
              <div className={classes.pricingTotals}>
                <Typography
                  style={{ fontWeight: 'bold', marginRight: '10px' }}
                  variant="body2"
                >
                  Add-Ons
                </Typography>
                <Typography variant="body2">
                  {formatPrice(
                    cashAddonTotal(
                      detailedPricing,
                      me?.goUserProfile?.settings?.timezone,
                    ),
                  )}
                </Typography>
              </div>
              <div className={classes.pricingTotals}>
                <Typography
                  style={{ fontWeight: 'bold', marginRight: '10px' }}
                  variant="body2"
                >
                  Rebates
                </Typography>
                <Typography variant="body2">
                  (
                  {formatPrice(
                    cashDiscountTotal(
                      detailedPricing,
                      me?.goUserProfile?.settings?.timezone,
                    ) +
                      cashRebateTotal(
                        detailedPricing,
                        me?.goUserProfile?.settings?.timezone,
                      ),
                  )}
                  )
                </Typography>
              </div>
              <div className={classes.pricingTotals}>
                <Typography
                  style={{
                    fontWeight: 'bold',
                    marginRight: '10px',
                    maxWidth: '70%',
                  }}
                  variant="body2"
                >
                  Default Down Payment
                </Typography>

                <Typography variant="body2">
                  {formatPrice(defaultDownPayment)}
                </Typography>
              </div>
            </Box>
          </Box>
        </TabPanel>

        {/* ***** Finance Panel ***** */}
        <TabPanel value={1} name={'financePanel'}>
          <Box
            className={
              mdBreakPoint
                ? classes.responsivePricingTotals
                : classes.pricingTotals
            }
          >
            <Box
              style={{
                marginRight: `${mdBreakPoint ? '10px' : '35px'}`,
              }}
            >
              {lowestFinancePayment !== Infinity && (
                <>
                  <Typography style={{ marginBottom: '15px' }}>from</Typography>
                  <Typography
                    style={{ fontWeight: 'bolder', lineHeight: '1rem' }}
                    variant="h4"
                  >
                    {formatPrice(lowestFinancePayment)}
                    <Box component="span" style={{ fontSize: '40%' }}>
                      {!mdBreakPoint && <br />}
                      &nbsp; /bi-weekly
                    </Box>
                  </Typography>
                </>
              )}
              {lowestFinancePayment === Infinity && (
                <>
                  <Typography
                    style={{ fontWeight: 'bolder', marginBottom: '10px' }}
                    variant="h4"
                  >
                    N/A
                  </Typography>
                </>
              )}
            </Box>

            <Box
              component="span"
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: 'column',
                rowGap: '10px',
                minWidth: `${mobile ? '200px' : ''}`,
              }}
            >
              <div className={classes.pricingTotals}>
                <Typography
                  variant="body2"
                  style={{ fontWeight: 'bold', marginRight: '10px' }}
                >
                  Vehicle Price
                </Typography>
                <Typography variant="body2">
                  {formatPrice(regularPrice)}
                </Typography>
              </div>
              <div className={classes.pricingTotals}>
                <Typography
                  variant="body2"
                  style={{ fontWeight: 'bold', marginRight: '10px' }}
                >
                  Add-Ons
                </Typography>
                <Typography variant="body2">
                  {formatPrice(
                    financeAddonTotal(
                      detailedPricing,
                      me?.goUserProfile?.settings?.timezone,
                    ),
                  )}
                </Typography>
              </div>
              <div className={classes.pricingTotals}>
                <Typography
                  variant="body2"
                  style={{ fontWeight: 'bold', marginRight: '10px' }}
                >
                  Rebates
                </Typography>
                <Typography variant="body2">
                  (
                  {formatPrice(
                    financeDiscountTotal(
                      detailedPricing,
                      me?.goUserProfile?.settings?.timezone,
                    ) +
                      financeRebateTotal(
                        detailedPricing,
                        me?.goUserProfile?.settings?.timezone,
                      ),
                  )}
                  )
                </Typography>
              </div>
              <div className={classes.pricingTotals}>
                <Typography
                  variant="body2"
                  style={{
                    maxWidth: '70%',
                    marginRight: '10px',
                    fontWeight: 'bold',
                  }}
                >
                  Default Down Payment
                </Typography>
                <Typography variant="body2">
                  {formatPrice(defaultDownPayment)}
                </Typography>
              </div>
            </Box>
          </Box>
        </TabPanel>
        {/* ***** Lease Panel ***** */}
        <TabPanel value={2} name={'leasePanel'}>
          <Box
            className={
              mdBreakPoint
                ? classes.responsivePricingTotals
                : classes.pricingTotals
            }
          >
            <Box
              style={{
                marginRight: `${mdBreakPoint ? '10px' : '35px'}`,
              }}
            >
              <Box>
                {lowestLeasePayment !== Infinity && (
                  <>
                    <Typography style={{ marginBottom: '15px' }}>
                      from
                    </Typography>
                    <Typography
                      style={{ fontWeight: 'bolder', lineHeight: '1rem' }}
                      variant="h4"
                    >
                      {formatPrice(lowestLeasePayment)}
                      <Box component="span" style={{ fontSize: '40%' }}>
                        {!mdBreakPoint && <br />}
                        &nbsp; /bi-weekly
                      </Box>
                    </Typography>
                  </>
                )}
                {lowestLeasePayment === Infinity && (
                  <>
                    <Typography
                      style={{ fontWeight: 'bolder', marginBottom: '10px' }}
                      variant="h4"
                    >
                      N/A
                    </Typography>
                  </>
                )}
              </Box>

              <Box
                component="span"
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  flexDirection: 'column',
                  rowGap: '10px',
                  minWidth: `${mobile ? '200px' : ''}`,
                }}
              >
                <div className={classes.pricingTotals}>
                  <Typography
                    variant="body2"
                    style={{ fontWeight: 'bold', marginRight: '10px' }}
                  >
                    Vehicle Price
                  </Typography>
                  <Typography variant="body2">
                    {formatPrice(regularPrice)}
                  </Typography>
                </div>
                <div className={classes.pricingTotals}>
                  <Typography
                    variant="body2"
                    style={{ fontWeight: 'bold', marginRight: '10px' }}
                  >
                    Add-Ons
                  </Typography>
                  <Typography variant="body2">
                    {formatPrice(
                      leaseAddonTotal(
                        detailedPricing,
                        me?.goUserProfile?.settings?.timezone,
                      ),
                    )}
                  </Typography>
                </div>
                <div className={classes.pricingTotals}>
                  <Typography
                    variant="body2"
                    style={{ fontWeight: 'bold', marginRight: '10px' }}
                  >
                    Rebates
                  </Typography>
                  <Typography variant="body2">
                    (
                    {formatPrice(
                      leaseDiscountTotal(
                        detailedPricing,
                        me?.goUserProfile?.settings?.timezone,
                      ),
                    )}
                    )
                  </Typography>
                </div>
                <div className={classes.pricingTotals}>
                  <Typography
                    variant="body2"
                    style={{
                      fontWeight: 'bold',
                      marginRight: '10px',
                      maxWidth: '70%',
                    }}
                  >
                    Default Down Payment
                  </Typography>
                  <Typography variant="body2">
                    {formatPrice(defaultDownPayment)}
                  </Typography>
                </div>
              </Box>
            </Box>
          </Box>
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default PurchaseOptions;
