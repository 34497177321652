import React from 'react';

/* external */
import { camelCase, startCase } from 'lodash';

/* Material UI */
import { makeStyles } from '@mui/styles';
import { Box, Chip } from '@mui/material';

const useStyles = makeStyles(theme => ({
  chipBox: {
    paddingRight: '5px',
    paddingBottom: '5px',
  },
}));

const titleize = str => startCase(camelCase(str));

/*
Deprecated - Use the (simpler) FacetChips component instead
*/
const FilterChipsBox = ({
  filters,
  setFilters,
  capitalize = false,
  children,
  ...rest
}) => {
  const classes = useStyles();

  const handleDeleteFilter = (_model, _field, _value) => () =>
    setFilters(prev =>
      prev.filter(
        ({ field, model, value }) =>
          _model !== model || _field !== field || _value !== value,
      ),
    );

  return (
    <Box display="flex" flexWrap="wrap" {...rest}>
      {filters.map(({ field, model, value }) => (
        <Box
          component="span"
          key={`${model}${field}${value}`}
          className={classes.chipBox}
        >
          <Chip
            label={
              value === 'True'
                ? titleize(field)
                : value === 'False'
                ? `Not ${titleize(field)}`
                : capitalize
                ? titleize(value)
                : value
            }
            value={value}
            onDelete={handleDeleteFilter(model, field, value)}
          />
        </Box>
      ))}
      {children}
    </Box>
  );
};

export default FilterChipsBox;
