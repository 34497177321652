export const SALES_APPOINTMENT_OPTIONS = [
  { value: 'consultation', text: 'Consultation' },
  { value: 'test_drive', text: 'Test Drive' },
  { value: 'finance', text: 'Finance' },
  { value: 'delivery', text: 'Delivery' },
  { value: 'other', text: 'Other' },
];

export const DEAL_TYPE = {
  new: 'New',
  New: 'New',
  na: 'Unknown',
  used: 'Used',
  Used: 'Used',
  Unknown: 'Unknown',
};

export const STATUS = {
  FRESH: 0,
  DESK: 1,
  FI: 2,
  POSTED: 3,
  DELIVERED: 4,
  LOST: 5,
  PENDING: 6,
  APPROVED: 7,
  SIGNED: 8,
  TUBED: 9,
  PRE_APP: 10,
  CARRYOVER: 11,
  OPEN: [6, 7, 8, 10, 11], // PENDING APPROVED SIGNED PREAPP CARRYOVER
  COMPLETED: [3, 4], // POSTED DELIVERED
  CLOSED: [3, 4, 9], // POSTED DELIVERED TUBED
  LOCKED: [3, 9], // POSTED TUBED
};

export const INSURANCE_PROVIDERS = [
  'Allstate',
  'AMA',
  'Armour',
  'Aviva',
  'Bel Air',
  'BrokerLink',
  'Crossfield',
  'Cooperators',
  'Desjardins',
  'Economical',
  'Elite',
  'Freeman',
  'Go Insurance',
  'GoldKey',
  'Ing & McKee',
  'Intact',
  'Marsh',
  'Millenium',
  'Not Listed',
  'Peace Hills',
  'Pembridge',
  'Personal Insurance',
  'Royal Sun Alliance',
  'SGI',
  'TD Insurance',
  'Travelers',
  'Truis',
  'Unifund',
  'Wawanesa',
];

export const PROVINCES = [
  { value: 'AB', text: 'Alberta' },
  { value: 'BC', text: 'British Columbia' },
  { value: 'MB', text: 'Manitoba' },
  { value: 'NB', text: 'New Brunswick' },
  { value: 'NL', text: 'Newfoundland and Labrador' },
  { value: 'NT', text: 'Northwest Territories' },
  { value: 'NS', text: 'Nova Scotia' },
  { value: 'NU', text: 'Nunavut' },
  { value: 'ON', text: 'Ontario' },
  { value: 'PE', text: 'Prince Edward Island' },
  { value: 'QC', text: 'Quebec' },
  { value: 'SK', text: 'Saskatchewan' },
  { value: 'YT', text: 'Yukon' },
];

export const PREFERRED_LANGUAGE = [
  { value: 'en', text: 'English' },
  { value: 'fr', text: 'French' },
  { value: 'zh', text: 'Chinese' },
];

export const EVENT_TITLES = {
  facebook: 'Facebook',
  sms: 'SMS',
  web: 'Web Chat',
};

export const CUSTOM_TRAITS_LIST = [
  { value: 'anonymousId', text: 'Anonymous ID' },
  { value: 'last_inbound_sms_message', text: 'Last Inbound SMS Message' },
  { value: 'last_outbound_sms_message', text: 'Last Outbound SMS Message' },
  { value: 'total_inbound_sms_messages', text: 'Total Inbound SMS Messages' },
  { value: 'total_outbound_sms_messages', text: 'Total Outbound SMS Messages' },
  { value: 'user_id', text: 'User ID' },
];

export const COMPUTED_TRAITS_LIST = [
  { value: 'sent_lead_7_day_count', text: 'Sent Lead 7 Day Count' },
  { value: 'viewed_vehicle_7_day_count', text: 'Viewed Vehicle 7 Day Count' },
  {
    value: 'viewed_vehicle_average_list_price',
    text: 'Viewed Vehicle Average List Price',
  },
  {
    value: 'viewed_vehicle_frequent_model',
    text: 'Viewed Vehicle Frequent Model',
  },
  {
    value: 'Viewed Vehicle Frequent Stock Type',
    text: 'Viewed Vehicle Frequent Stock Type',
  },
];

export const SQL_TRAITS_LIST = [
  { value: 'active_opportunity_count', text: 'Active Opportunity Count' },
  { value: 'email', text: 'Email' },
  { value: 'gocard_balance', text: 'Go Card Balance' },
  { value: 'gocard_balance_date', text: 'Go Card Balance Date' },
  { value: 'gocard_id', text: 'Go Card ID' },
  { value: 'gocard_referral', text: 'Go Card Referral' },
  {
    value: 'casl_implicit_consent_expires',
    text: 'CASL Implicit Consent Expires',
  },
  {
    value: 'casl_implicit_consent_reason',
    text: 'CASL Implicit Consent Reason',
  },
  { value: 'email_consent', text: 'Email Consent' },
  { value: 'sms_consent', text: 'SMS Consent' },
  { value: 'last_opportunity_created', text: 'Last Opportunity Created' },
  { value: 'last_opportunity_updated', text: 'Last Opportunity Updated' },
  {
    value: 'started_opportunity_last_14_days',
    text: 'Started Opportunity Last 14 Days',
  },
  { value: 'veh1_basic_warranty_expires', text: 'Veh1 Basic Warranty Expires' },
  { value: 'veh1_basic_warranty_months', text: 'Veh1 Basic Warranty Months' },
  { value: 'veh1_basic_warranty_kms', text: 'Veh1 Basic Warranty KMs' },
  { value: 'veh1_computed_on', text: 'Veh1 Computed On' },
  { value: 'veh1_date_purchased', text: 'Veh1 Date Purchased' },
  { value: 'veh1_deal_type', text: 'Veh1 Deal Type' },
  { value: 'veh1_last_payment_date', text: 'Veh1 Last Payment Date' },
  { value: 'veh1_last_seen', text: 'Veh1 Last Seen' },
  { value: 'veh1_last_seen_at_dealer_id', text: 'Veh1 Last Seen At Dealer ID' },
  {
    value: 'veh1_last_seen_at_dealer_name',
    text: 'Veh1 Last Seen At Dealer Name',
  },
  { value: 'veh1_last_touch', text: 'Veh1 Last Touch' },
  { value: 'veh1_make', text: 'Veh1 Make' },
  { value: 'veh1_model', text: 'Veh1 Model' },
  { value: 'veh1_odometer', text: 'Veh1 Odometer' },
  { value: 'veh1_payment_type', text: 'Veh1 Payment Type' },
  { value: 'veh1_purchased_at_dealer_id', text: 'Veh1 Purchased at Dealer ID' },
  {
    value: 'veh1_purchased_at_dealer_name',
    text: 'Veh1 Purchased at Dealer Name',
  },
  { value: 'veh1_vin', text: 'Veh1 VIN' },
  { value: 'veh1_year', text: 'Veh1 Year' },
  { value: 'veh_total', text: 'Veh1 Total' },
];

export const SEGMENT_DATE_TRAITS_LIST = [
  { value: 'gocard_balance_date', text: 'Go Card Balance Date' },
  { value: 'last_opportunity_created', text: 'Last Opportunity Created' },
  { value: 'last_opportunity_updated', text: 'Last Opportunity Updated' },
  { value: 'last_seen_at_dealer_id', text: 'Last Seen At Dealer ID' },
  { value: 'last_seen_at_dealer_name', text: 'Last Seen At Dealer Name' },
  { value: 'veh1_date_purchased', text: 'Veh1 Date Purchased' },
  { value: 'veh1_last_payment_date', text: 'Veh1 Last Payment Date' },
  { value: 'veh1_last_seen', text: 'Veh1 Last Seen' },
  { value: 'last_inbound_sms_message', text: 'Last Inbound SMS Message' },
  { value: 'last_outbound_sms_message', text: 'Last Outbound SMS Message' },
  { value: 'veh1_computed_on', text: 'Veh1 Computed On' },
  { value: 'veh1_basic_warranty_expires', text: 'Veh1 Basic Warranty Expires' },
  {
    value: 'casl_implicit_consent_expires',
    text: 'CASL Implicit Consent Expires',
  },
];

export const DEFAULTS = {
  salutation: null,
  first_name: null,
  nickname: null,
  last_name: null,
  preferred_language: null,
  company_name: null,
  home_phone: null,
  cell_phone: null,
  primary_email: null,
  address: null,
  date_of_birth: null,
  drivers_license: null,
  drivers_license_province: null,
  drivers_license_expiry_date: null,
  assigned_salespeople: [],
  vehicles: [],
};

export const FACET_FILTERS = [
  'smi.crm:customer',
  'smi.crm:opportunity',
  'smi.crm:lead',
  'smi.sms:conversation',
  'smi.appraisals:appraisal',
  'smi.desking:credit_application',
  'smi.desking:pitch',
  'goauto.sales_history:vehicle_service',
  'goauto.sales_history:appointment',
  'goauto.goldcard:package',
  'goauto.gocard:gocard',
];

export const FACET_FILTER_DISPLAY = {
  'smi.crm:customer': 'Customer Profiles',
  'smi.crm:opportunity': 'Opportunities',
  'smi.crm:lead': 'Leads',
  'smi.sms:conversation': 'SMS Conversations',
  'smi.appraisals:appraisal': 'Appraisals',
  'smi.desking:credit_application': 'Credit Applications',
  'smi.desking:pitch': 'Pitches',
  'goauto.sales_history:vehicle_service': 'Service ROs',
  'goauto.sales_history:appointment': 'Service Appointments',
  'goauto.goldcard:package': 'Gold Card Packages',
  'goauto.gocard:gocard': 'Go Card Accounts',
};

export const REDEMPTION_OPCODES = {
  DETAILING: 'GOLDDT',
  WINDSHIELD: 'GOLDWS',
  ALIGNMENT: 'GOLDAL',
};

export const FRAUD_BULLETIN = 'fraud';

export const BULLETIN_TYPES = [
  { value: 'sales', text: 'Sales' },
  { value: 'service', text: 'Service' },
  { value: 'parts', text: 'Parts' },
  { value: 'general', text: 'All (General Bulletin)' },
  { value: FRAUD_BULLETIN, text: 'Fraud' },
];

export const BULLETIN_STATUS_DISPLAY = {
  service: 'SERVICE DEPARTMENT',
  sales: 'SALES DEPARTMENT',
  parts: 'PARTS DEPARTMENT',
  general: 'ALL DEPARTMENTS',
  fraud: 'FRAUDULENT CUSTOMER ALERT',
};

export const MAX_BULLETINS = 5;
