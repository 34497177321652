/* external */
import React from 'react';
import moment from 'moment-timezone';
import { useSnackbar } from 'notistack';
import { useMutation, useQuery } from '@apollo/react-hooks';
import unidecode from 'unidecode';
import gql from 'graphql-tag';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

/* Material UI */
import { Button, Link, Typography } from '@mui/material';
import { useTheme } from '@mui/material';
import GavelIcon from '@mui/icons-material/Gavel';
import Alert from '@mui/material/Alert';
import Loading from 'components/MaterialUI/Loading';
import TextFieldControl from 'components/MaterialUI/TextFieldControl';
import { useUserContext } from 'components/MaterialUI/UserContext';

/* internal */
import { getFullDateTime, withSuffix, defaultTZ } from 'utils';
import { errorHandler } from 'modules/auctions/utils';
import { PlaceholderVin } from 'modules/used_vehicles/const';

const GET_DAILY_WHOLESALE_AUCTION_QUERY = gql`
  query getAuction {
    auctions {
      settings {
        dailyWholesaleAuction {
          id
          name
          isDaily
          daily {
            id
            cutoffTime
            durationMinutes
            nextStart
            timezone
            startTime
          }
        }
      }
    }
  }
`;

const CREATE_AUCTION_ITEM = gql`
  mutation createAuctionItem($auctionItem: AuctionItemInput!) {
    auctions {
      createAuctionItem(auctionItem: $auctionItem) {
        id
      }
    }
  }
`;

const AuctionVehicle = ({ appraisal, setShowAuctioned }) => {
  const theme = useTheme();
  const {
    id,
    buyer,
    dealerId,
    driveType,
    engineCompressor,
    engineConfig,
    engineCylinders,
    engineLitres,
    exteriorColour: exteriorColourName,
    fuelType,
    hasLien,
    hasOpi,
    isGlassGood,
    isTiresGood,
    interiorColour: interiorColourName,
    locatedProvince,
    make,
    model,
    notes,
    odometer,
    photos,
    transmission,
    trim,
    value,
    vin,
    year,
  } = appraisal;

  const { clearErrors, control, handleSubmit, setError } = useForm({
    defaultValues: {
      minimumStartingBid: value,
      reserve: null,
    },
  });

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { currentUser } = useUserContext();

  const { data: getAuctionData, loading: loadingAuction } = useQuery(
    GET_DAILY_WHOLESALE_AUCTION_QUERY,
  );
  const {
    id: auctionId,
    name,
    isDaily,
    daily,
  } = getAuctionData?.auctions.settings.dailyWholesaleAuction || {};
  const { cutoffTime, durationMinutes, nextStart, startTime, timezone } =
    daily || {};

  const [createAuctionItem, auctionItemMutation] =
    useMutation(CREATE_AUCTION_ITEM);

  const onSubmit = ({ minimumStartingBid, reserve }) => {
    const notesPlusChecklist =
      // eliminating Unicode characters
      unidecode(notes ?? '') +
      ` \n OPI: ${hasOpi ? 'Yes' : 'No'}, Lien: ${
        hasLien ? 'Yes' : 'No'
      }, Glass: ${isGlassGood ? 'Good' : 'Bad'}, Tires: ${
        isTiresGood ? 'Good' : 'Bad'
      } \n Buyer: ${String(buyer)}
      `;
    createAuctionItem({
      variables: {
        auctionItem: {
          auctionId: auctionId,
          dealerId,
          minimumStartingBid: parseInt(minimumStartingBid, 10),
          reserve:
            reserve && reserve !== '' ? parseInt(reserve, 10) : undefined,
          vehicle: {
            dealerId,
            driveType,
            engineCompressor,
            engineConfig,
            engineCylinders,
            engineLitres,
            exteriorColourName,
            fuelType,
            interiorColourName,
            locatedProvince,
            make,
            model,
            notes: notesPlusChecklist,
            odometer,
            photos: photos.map(({ cloudinaryPublicId }) => ({
              cloudinaryPublicId,
            })),
            sendingService: 'appraisals',
            sendingServiceId: id,
            transmission,
            trim,
            vin,
            year,
          },
        },
      },
    }).then(
      () => {
        enqueueSnackbar(
          `${year} ${make} ${model} (${vin}) sent to ${name} auction.`,
          { variant: 'success' },
        );
        history.push('/used-vehicles');
      },
      err => errorHandler(enqueueSnackbar, setError, { snackAll: true })(err),
    );
  };

  const myTZ = currentUser?.goUserProfile?.settings?.timezone || defaultTZ;

  const timezoneDiff = timezone
    ? moment().tz(timezone).utcOffset() - moment().tz(myTZ).utcOffset()
    : 0;

  let startTimeMoment = moment(startTime, 'HH:mm:ss');

  startTimeMoment.subtract(timezoneDiff, 'minutes');
  let endTime = moment(startTime, 'HH:mm:ss');
  endTime.subtract(timezoneDiff, 'minutes');
  endTime.add(durationMinutes, 'minutes');

  let cutoffTimeMoment = moment(cutoffTime, 'HH:mm:ss');
  cutoffTimeMoment.subtract(timezoneDiff, 'minutes');

  const myTZAbbr = moment().tz(myTZ).format('z');

  const asShortTime = _moment =>
    _moment.minutes() ? _moment.format('h:mma') : _moment.format('ha');

  if (loadingAuction || auctionItemMutation.loading) return <Loading />;
  if (!isDaily)
    return (
      <>
        Error: auction type is invalid or daily auction does not exist. Contact
        support for assistance.{' '}
      </>
    );

  return (
    <div>
      {(!vin || vin === PlaceholderVin) && (
        <Alert severity="error">
          There is no valid VIN on this vehicle. You can't send it to auction
          with no VIN. Click{' '}
          <Link href={`/used-vehicles/create-vehicle/${id}`}>here</Link> to go
          back and edit it.
        </Alert>
      )}
      <div style={{ padding: '1rem' }}>
        <Typography variant="h6">Auction Vehicle:</Typography>
        <p>
          This vehicle will be added to the <b>{name}</b> auction that runs
          daily from {asShortTime(startTimeMoment)}-{asShortTime(endTime)} (
          {myTZAbbr})
        </p>
        <p>
          If you add this vehicle before {asShortTime(cutoffTimeMoment)} (
          {myTZAbbr}) it will run at:{' '}
          {getFullDateTime(withSuffix(nextStart, 'Z'), myTZ)} ({myTZAbbr}
          )!
        </p>
        <form
          onSubmit={e => {
            clearErrors();
            clearErrors('vehicle');
            handleSubmit(onSubmit)(e);
          }}
        >
          <div style={{ paddingTop: '0.5rem' }}>
            <TextFieldControl
              label="Starting Bid"
              name="minimumStartingBid"
              control={control}
              variant="currency"
              rules={{
                required: 'This is required',
                min: { value: 100, message: 'Must be at least $100' },
              }}
            />
          </div>

          <div style={{ paddingTop: '0.5rem' }}>
            <TextFieldControl
              label="Reserve"
              name="reserve"
              control={control}
              variant="currency"
              rules={{
                min: { value: 100, message: 'Must be at least $100' },
              }}
            />
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
              width: '100%',
              marginTop: '1rem',
              marginBottom: '1rem',
            }}
          >
            <Button onClick={() => setShowAuctioned(false)} variant="outlined">
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              style={theme.actions.confirm}
            >
              <GavelIcon fontSize="small" style={{ paddingRight: '3px' }} />
              Auction it!
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

AuctionVehicle.fragments = {
  appraisal: gql`
    fragment AuctionVehicleAppraisal on Appraisal {
      id
      buyer
      dealerId
      driveType
      engineCompressor
      engineConfig
      engineCylinders
      engineLitres
      exteriorColour
      fuelType
      hasLien
      hasOpi
      isGlassGood
      isTiresGood
      interiorColour
      locatedProvince
      make
      model
      notes
      odometer
      photos {
        id
        cloudinaryPublicId
      }
      transmission
      trim
      value
      vin
      year
    }
  `,
};

export default AuctionVehicle;
