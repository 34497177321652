export const NAME = 'market-mobile/tasks';
export const TASK_FORM_NAME = 'task-edit-form';
export const COMPLETE_TASK_SUCCESS_NAME = 'tasks/COMPLETE_TASK_SUCCESS_NAME';
export const COMPLETE_TASK_FAILURE_NAME = 'tasks/COMPLETE_TASK_FAILURE_NAME';
export const CANCEL_TASK_SUCCESS_NAME = 'tasks/CANCEL_TASK_SUCCESS_NAME';
export const CANCEL_TASK_FAILURE_NAME = 'tasks/CANCEL_TASK_FAILURE_NAME';
export const UPDATE_TASK_SUCCESS_NAME = 'tasks/UPDATE_TASK_SUCCESS_NAME';
export const UPDATE_TASK_FAILURE_NAME = 'tasks/UPDATE_TASK_FAILURE_NAME';
export const REASSIGN_TASK_SUCCESS_NAME = 'tasks/REASSIGN_TASK_SUCCESS_NAME';
export const REASSIGN_TASK_FAILURE_NAME = 'tasks/REASSIGN_TASK_FAILURE_NAME';
export const RESCHEDULE_TASK_SUCCESS_NAME =
  'tasks/RESCHEDULE_TASK_SUCCESS_NAME';
export const RESCHEDULE_TASK_FAILURE_NAME =
  'tasks/RESCHEDULE_TASK_FAILURE_NAME';

export const TaskStatus = {
  TODO: 0,
  COMPLETE: 1,
  CANCELLED: 2,
};

export const TaskStatusNames = {
  [TaskStatus.TODO]: 'To Do',
  [TaskStatus.COMPLETE]: 'Complete',
  [TaskStatus.CANCELLED]: 'Cancelled',
};

export const TaskStatusType = ['email', 'call', 'other', 'opportunity'];

export const TaskStatusColor = {
  RED: '#D15B47',
  YELLOW: '#FFEB3B',
  GREEN: '#82AF6F',
  GREY: '#666',
  BLUE: '#0044cc',
};
