/* external */
import gql from 'graphql-tag';
import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { isEmpty } from 'lodash';

/* Material UI */
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

import ResponsiveComponent from 'components/MaterialUI/ResponsiveComponent';

/* internal */
import CreditAppDetailsDialog from 'modules/credit_apps/components/CreditAppDetailsDialog';

import ItemCard from './ItemCard';
import ItemRow from './ItemRow';

const useStyles = makeStyles(() => ({
  card: {
    minHeight: '300px',
  },
  headerRow: {
    '&>th': {
      fontWeight: 'bold',
    },
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
  },
}));

const CreditAppsCard = ({
  creditApps,
  emptyMessage = '',
  title = 'Credit Applications',
  errors = [],
  refetch,
  ...rest
}) => {
  const [creditApp, setCreditApp] = useState();
  const { card, headerRow, ...classes } = useStyles();
  const validCreditApps = creditApps.filter(app => app !== null);
  const { enqueueSnackbar } = useSnackbar();

  if (!isEmpty(errors)) {
    errors.forEach(error => {
      enqueueSnackbar(error.message, { variant: 'error' });
    });
  }

  useEffect(() => {
    // Since we're using a state to pop open the dialog, the credit app object in the state isn't
    // automatically updated when the creditApps prop changes. So we need to update it manually.

    if (creditApp) {
      const newCreditApp = creditApps.find(app => app.id === creditApp.id);
      if (newCreditApp) setCreditApp(newCreditApp);
    }
  }, [creditApp, creditApps, setCreditApp]);

  return (
    <Card {...rest} className={card}>
      <CardContent>
        <CreditAppDetailsDialog
          onClose={() => setCreditApp(null)}
          open={Boolean(creditApp)}
          creditApp={creditApp}
          refetch={refetch}
        />
        <Typography className={classes.title}>{title}</Typography>
        {validCreditApps.length === 0 ? (
          <Box my={4}>{emptyMessage}</Box>
        ) : (
          <ResponsiveComponent Desktop={Table} Mobile={React.Fragment}>
            <ResponsiveComponent Desktop={TableHead}>
              <TableRow className={headerRow}>
                <TableCell>Actions</TableCell>
                <TableCell>Applicant</TableCell>
                <TableCell>Dealership</TableCell>
                <TableCell>Date Started</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </ResponsiveComponent>
            <ResponsiveComponent Desktop={TableBody} Mobile={React.Fragment}>
              {validCreditApps
                .sort((a, b) => b.dateCreated.localeCompare(a.dateCreated)) // Sort date desc
                .map(creditApp => (
                  <ResponsiveComponent
                    creditApp={creditApp}
                    Desktop={ItemRow}
                    key={creditApp.id}
                    Mobile={ItemCard}
                    onClickDetails={() => setCreditApp(creditApp)}
                  />
                ))}
            </ResponsiveComponent>
          </ResponsiveComponent>
        )}
      </CardContent>
    </Card>
  );
};

CreditAppsCard.fragments = {
  creditApp: gql`
    fragment CreditAppsCardCreditApp on CreditApp {
      id
      ...CreditAppDetailsDialogCreditApp
      ...ItemCardCreditApp
      ...ItemRowCreditApp
    }
    ${CreditAppDetailsDialog.fragments.creditApp}
    ${ItemCard.fragments.creditApp}
    ${ItemRow.fragments.creditApp}
  `,
  // guess we may as well include all the credit app details in the fragment; it doesn't cost
  // us any more query time since we're getting the credit apps list on the customer/opp anyway
};

export default CreditAppsCard;
