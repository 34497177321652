/* internal */
import {
  B_WAIVER_DEALERS,
  DELIVERY_CHECKLIST_DEALERS,
  DELIVERY_DUE_BILL_DEALERS,
  FUTURE_TRADE_DISCLOSURE_DEALERS,
  DEPOSIT_AGREEMENT_DEALERS,
  MGMFL_DEPOSIT_AGREEMENT_DEALERS,
  NON_EXPORT_DEALERS,
  PUBLISHING_STATUS,
  PROMISES_SHEET_DEALERS,
} from '../const';
import { useMutation } from '@apollo/react-hooks';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

/* Material UI */
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import useMediaQuery from '@mui/material/useMediaQuery';
import FavouriteIcon from '@mui/icons-material/Favorite';
import FavouriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FeedbackIcon from '@mui/icons-material/Feedback';
import DownloadIcon from '@mui/icons-material/GetApp';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ShareIcon from '@mui/icons-material/Share';
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';
import { DEAL_COVER_SHEET_DEALERS, RoleGroup } from 'constants.js';
import gql from 'graphql-tag';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';

import { useUserContext } from 'components/MaterialUI/UserContext';
import PitchChatDialog from './PitchChatDialog';

/* external */
import { sortBy } from 'underscore';

const GENERATE_PRINTABLE_PDF = gql`
  mutation generatePrintablePDF($pitchId: Int!, $PDFType: String!) {
    pitches {
      generatePrintablePDF(id: $pitchId, PDFType: $PDFType) {
        key
      }
    }
  }
`;

const GENERATE_DEAL_COVER_SHEET_PDF = gql`
  mutation generateDealCoverSheetPDF(
    $id: ID!
    $pitchTrade: String
    $vehicleName: String
    $stockNumber: String
  ) {
    generateDealCoverSheetPDF(
      _id: $id
      pitch_trade: $pitchTrade
      vehicle_name: $vehicleName
      stock_number: $stockNumber
    ) {
      key
    }
  }
`;

const useStyles = makeStyles(theme => ({
  chipBox: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    textTransform: 'uppercase',
  },
  vehicleNameBox: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 28,
    },
    fontWeight: 'bold',
  },

  vehicleTrimVarBox: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 10,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 18,
    },
    paddingLeft: theme.spacing(1),
  },
}));

const VehicleTitle = ({
  primaryPitchID,
  isPrimaryPitch,
  onEditPitch,
  onFavourite,
  onGenerateCarfaxPDF,
  onGeneratePDF,
  onShare,
  carfaxPdfUrl,
  pdfUrl,
  vehicle,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { currentUser } = useUserContext();
  const { enqueueSnackbar } = useSnackbar();
  const { dealerId } = useDealerContext();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    make,
    model,
    stockType,
    stockNumber,
    trim,
    trimVariation,
    vehicleName,
    year,
    pitchId,
    pitch,
  } = vehicle;
  const [openChatDialog, setOpenChatDialog] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState();
  const [generatePDF, generatePDFMutation] = useMutation(
    GENERATE_PRINTABLE_PDF,
  );

  const latestPublishing =
    sortBy(pitch?.publishings ?? [], x => -x.id)?.[0] ?? null;
  const status = latestPublishing?.status;
  const isStartingStatus = [
    PUBLISHING_STATUS.NOT_STARTED,
    PUBLISHING_STATUS.STARTED,
    PUBLISHING_STATUS.STARTED_INITIALED,
  ].includes(status);
  const hasMessages = latestPublishing?.messages ?? [].length > 0;
  const newMessage = hasMessages
    ? latestPublishing.messages[latestPublishing.messages.length - 1]
        ?.createdBy === 'customer'
    : false;
  const handleClickOpenDialog = () => setOpenChatDialog(true);
  const handleMenuClick = e => setMenuAnchor(e.currentTarget);
  const handleMenuClose = () => setMenuAnchor(null);
  const handleGeneratePDF = () => {
    handleMenuClose();
    onGeneratePDF();
  };
  const handleGenerateCarfaxPDF = () => {
    handleMenuClose();
    onGenerateCarfaxPDF();
  };

  const handleError = error =>
    enqueueSnackbar(`Error generating PDF: ${JSON.stringify(error)}`, {
      variant: 'error',
    });

  const generatePrintablePDF = (pitchId, PDFType) => {
    generatePDF({ variables: { pitchId, PDFType } }).then(({ data }) => {
      handleMenuClose();
      window.open(data.pitches.generatePrintablePDF.key, '_blank');
    }, handleError);
  };

  let pitchTrade = '';

  // check if pitch has appraisal(s)
  const pitchAppraisal = pitch?.appraisals?.[0];
  const appraisalCount = pitch?.appraisals?.length;

  if (pitchAppraisal) {
    const { year, make, model } = pitchAppraisal;
    pitchTrade = `${year} ${make} ${model}`;
    if (appraisalCount > 1) {
      pitchTrade += ` + ${appraisalCount - 1} other trade(s)`;
    }
  }

  // if vehicleName does not exist, create a fallback
  let coverSheetVehicleName = '';

  if (vehicleName === '') {
    coverSheetVehicleName = [year, make, model, trim].filter(x => x).join(' ');
  }

  const [createDealCoverSheetPDF, createDealCoverSheetPDFMutation] =
    useMutation(GENERATE_DEAL_COVER_SHEET_PDF);

  const generateDealCoverSheetPDF = (
    id,
    pitchTrade,
    vehicleName,
    stockNumber,
  ) => {
    createDealCoverSheetPDF({
      variables: { id, pitchTrade, vehicleName, stockNumber },
    }).then(
      ({ data }) => window.open(data.generateDealCoverSheetPDF.key, '_blank'),
      handleError,
    );
  };

  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
    >
      <PitchChatDialog
        user={currentUser}
        open={openChatDialog}
        onClose={() => setOpenChatDialog(false)}
        messagingDisabled={!isStartingStatus}
        publishingId={latestPublishing?.id}
      />
      <LoadingBackdrop
        open={
          generatePDFMutation.loading || createDealCoverSheetPDFMutation.loading
        }
      >
        Generating PDF
      </LoadingBackdrop>
      <Box display="flex" flexDirection="row" pt={1} pb={2} alignItems="center">
        <Menu
          anchorEl={menuAnchor}
          open={!!menuAnchor}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={onEditPitch}>Edit</MenuItem>
          <MenuItem onClick={handleGenerateCarfaxPDF}>
            {carfaxPdfUrl && 'Re-'}Generate Carfax Pdf
          </MenuItem>
          <MenuItem onClick={handleGeneratePDF}>
            {pdfUrl && 'Re-'}Generate Pitch Sheet
          </MenuItem>
          <MenuItem onClick={() => generatePrintablePDF(pitchId, 'vdp_pdf')}>
            Print Vehicle Detail Page
          </MenuItem>
          <MenuItem
            onClick={() => generatePrintablePDF(pitchId, 'get_ready_checklist')}
          >
            Print Get Ready Checklist
          </MenuItem>
          <MenuItem onClick={() => generatePrintablePDF(pitchId, 'test_drive')}>
            Print Test Drive
          </MenuItem>
          <MenuItem
            onClick={() => generatePrintablePDF(pitchId, 'four_square_sheet')}
          >
            Print 4 Square Sheet
          </MenuItem>
          <MenuItem
            onClick={() =>
              generatePrintablePDF(pitchId, 'advertising_compliance')
            }
          >
            Print Advertising Compliance
          </MenuItem>
          {carfaxPdfUrl && (
            <MenuItem
              onClick={() => {
                handleMenuClose();
                window.open(carfaxPdfUrl, '_blank');
              }}
            >
              Print Carfax
            </MenuItem>
          )}
          <MenuItem
            onClick={() =>
              generatePrintablePDF(pitchId, 'vehicle_history_checklist')
            }
          >
            Print Vehicle History Check
          </MenuItem>
          {MGMFL_DEPOSIT_AGREEMENT_DEALERS.includes(dealerId) && (
            <MenuItem
              onClick={() =>
                generatePrintablePDF(pitchId, 'mgmfl_deposit_agreement')
              }
            >
              Print Deposit Agreement
            </MenuItem>
          )}
          {DEPOSIT_AGREEMENT_DEALERS.includes(dealerId) && (
            <MenuItem
              onClick={() => generatePrintablePDF(pitchId, 'deposit_agreement')}
            >
              Print Deposit Agreement
            </MenuItem>
          )}
          <MenuItem
            onClick={() => generatePrintablePDF(pitchId, 'offer_to_purchase')}
          >
            Print Offer To Purchase
          </MenuItem>
          <MenuItem
            onClick={() => generatePrintablePDF(pitchId, 'bill_of_sale')}
          >
            Print Bill of Sale
          </MenuItem>
          {B_WAIVER_DEALERS.includes(dealerId) && (
            <MenuItem
              onClick={() => generatePrintablePDF(pitchId, 'b_car_waiver')}
            >
              Print B Car Waiver
            </MenuItem>
          )}
          {NON_EXPORT_DEALERS.includes(dealerId) && (
            <MenuItem
              onClick={() =>
                generatePrintablePDF(pitchId, 'non_export_agreement')
              }
            >
              Print Non-Export Agreement
            </MenuItem>
          )}
          {FUTURE_TRADE_DISCLOSURE_DEALERS.includes(dealerId) && (
            <MenuItem
              onClick={() =>
                generatePrintablePDF(pitchId, 'future_trade_disclosure')
              }
            >
              Print Future Trade Disclosure
            </MenuItem>
          )}
          {DELIVERY_CHECKLIST_DEALERS.includes(dealerId) && (
            <MenuItem
              onClick={() =>
                generatePrintablePDF(pitchId, 'delivery_checklist')
              }
            >
              Print Delivery Checklist
            </MenuItem>
          )}
          {DELIVERY_DUE_BILL_DEALERS.includes(dealerId) && (
            <MenuItem
              onClick={() =>
                generatePrintablePDF(pitchId, 'delivery_and_due_bill')
              }
            >
              Print Delivery Checklist
            </MenuItem>
          )}
          {DEAL_COVER_SHEET_DEALERS.includes(dealerId) && (
            <MenuItem
              onClick={() =>
                generateDealCoverSheetPDF(
                  pitch?.opportunityId,
                  pitchTrade,
                  vehicleName ? vehicleName : coverSheetVehicleName,
                  stockNumber,
                )
              }
              // For multiple pitches - Disable if a primary pitch exists and
              //if the pitch is not the primary pitch
              disabled={primaryPitchID && !isPrimaryPitch}
            >
              Generate Deal Cover Sheet
            </MenuItem>
          )}
          {PROMISES_SHEET_DEALERS.includes(dealerId) && (
            <MenuItem
              onClick={() => generatePrintablePDF(pitchId, 'promises_sheet')}
            >
              Generate Promises Sheet
            </MenuItem>
          )}
        </Menu>
        <Box className={classes.vehicleNameBox}>
          {vehicleName && vehicleName !== ''
            ? vehicleName
            : [year, make, model, trim].filter(x => x).join(' ')}
        </Box>
        <Box className={classes.vehicleTrimVarBox}>{trimVariation}</Box>
        {stockType && (
          <Box
            className={classes.chipBox}
            display="flex"
            style={{ gap: '5px' }}
          >
            <Chip label={stockType} size={mobile ? 'small' : 'medium'} />
            {carfaxPdfUrl && (
              <Chip label="carfax" size={mobile ? 'small' : 'medium'} />
            )}
          </Box>
        )}
        <Box>
          <IconButton onClick={handleMenuClick} size="large">
            <MoreVertIcon />
          </IconButton>
        </Box>
      </Box>
      <Box display="flex" justifyContent="flex-end" alignItems="center">
        {hasMessages &&
          !newMessage &&
          RoleGroup.DIGITAL_PITCH_MESSAGING.includes(currentUser.role) && (
            <IconButton onClick={handleClickOpenDialog} size="large">
              <ChatBubbleIcon />
            </IconButton>
          )}
        {hasMessages &&
          newMessage &&
          RoleGroup.DIGITAL_PITCH_MESSAGING.includes(currentUser.role) && (
            <IconButton onClick={handleClickOpenDialog} size="large">
              <FeedbackIcon style={{ color: '#F54978' }} />
            </IconButton>
          )}
        <IconButton onClick={onShare} size="large">
          <ShareIcon />
        </IconButton>
        {pdfUrl && (
          <IconButton
            onClick={() => window.open(pdfUrl, '_blank')}
            size="large"
          >
            <DownloadIcon />
          </IconButton>
        )}
        <IconButton
          onClick={onFavourite}
          disabled={isPrimaryPitch}
          size="large"
        >
          {isPrimaryPitch ? (
            <FavouriteIcon style={{ color: '#F54978' }} />
          ) : (
            <FavouriteBorderIcon />
          )}
        </IconButton>
      </Box>
    </Box>
  );
};

VehicleTitle.fragments = {
  vehicle: gql`
    fragment VehicleTitleVehicle on PitchVehicle {
      make
      model
      pitchId
      pitch {
        id
        appraisals {
          id
          make
          model
          year
        }
        opportunityId
        publishings {
          id
          status
          messages {
            id
            body
            createdBy
          }
        }
      }
      stockNumber
      stockType
      trim
      trimVariation
      vehicleName
      year
    }
  `,
};
export default VehicleTitle;
