import React, { useState } from 'react';

/* external */
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useSnackbar } from 'notistack';

/* Material UI */
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import { useMediaQuery } from '@mui/material';

const useStyles = makeStyles(theme => ({
  text: {
    fontSize: 14,
    fontWeight: 'bold',
  },
}));

const REVOKE_SHARED_GO_CARD = gql`
  mutation revokeSharedGoCard($gocard_id: Int!, $email: String!) {
    revokeSharedGoCard(gocard_id: $gocard_id, email: $email)
  }
`;

const SharedGoCards = ({ sharedGoCardRefetch, sharedGoCardDetails }) => {
  const classes = useStyles();
  const desktop = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [revokeSharedGoCard] = useMutation(REVOKE_SHARED_GO_CARD, {
    onCompleted: () => {
      enqueueSnackbar(`Successfully deleted Shared Go Card`, {
        variant: 'success',
      });
      sharedGoCardRefetch();
    },
    onError: () =>
      enqueueSnackbar(`Error in deleting Shared Go Card`, {
        variant: 'error',
      }),
  });

  const sharedCard = sharedGoCardDetails?.account;
  const fullName =
    sharedCard?.first_name && sharedCard?.last_name
      ? sharedCard.first_name + ' ' + sharedCard.last_name
      : 'Name Not Found';
  const firstName = sharedCard?.first_name ?? 'Name Not Found';
  const hasName =
    fullName !== 'Name Not Found' || firstName !== 'Name Not Found';

  return (
    <Card>
      <CardContent>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
          style={{ marginLeft: '8px' }}
        >
          <Grid item>
            <CreditCardIcon style={{ marginTop: '5px' }} />
          </Grid>
          <Grid item sm={2} xs={10} className={classes.text}>
            ${sharedCard?.cash_balance.toFixed(2) || 0}
          </Grid>
          <Grid item className={classes.text} xs={desktop ? null : 6}>
            {`${sharedCard?.card_number}`}
          </Grid>
          <Grid item style={{ color: '#999999' }} className={classes.text}>
            {`|`}
          </Grid>
          <Grid
            item
            style={hasName ? { color: '#999999' } : { color: '#FF0000' }}
            className={classes.text}
            sm={2}
            xs={3}
          >
            {fullName ?? firstName}
          </Grid>
          <Grid item>
            <IconButton
              edge="end"
              aria-label="delete"
              size="small"
              onClick={handleClickOpen}
            >
              <DeleteIcon />
            </IconButton>
            <Dialog open={open} onClose={handleClose} maxWidth="xs">
              <DialogTitle>Confirm Delete</DialogTitle>
              <DialogContent>
                <Typography variant="body2">
                  'Are you sure you want to delete this shared card from the
                  customer?'
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="primary"
                  variant="outlined"
                >
                  Cancel
                </Button>
                <Button
                  style={{ backgroundColor: 'firebrick' }}
                  onClick={() => {
                    revokeSharedGoCard({
                      variables: {
                        gocard_id: sharedGoCardDetails?.gocard_id,
                        email: sharedGoCardDetails?.email,
                      },
                    });
                    handleClose();
                  }}
                  variant="contained"
                  color="primary"
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

SharedGoCards.fragments = {
  gocard: gql`
    fragment SharedGoCardsGoCard on GoCardAccountV2 {
      customer {
        _id
      }
      cashBalance
      cardNumber
    }
  `,
  sharedGoCards: gql`
    fragment SharedGoCardsSharedGoCard on SharedGoCard {
      gocard_id
      email
    }
  `,
};

export default SharedGoCards;
