import { useQuery } from '@apollo/react-hooks';
import moment from 'moment';
import { useState } from 'react';

import { stringify } from 'utils';

const CSVExporter = ({
  render,
  onProgress = () => {},
  onComplete = () => {},
  query,
  getResults,
  getPagination,
  pageSize = 10,
  csvFields = new Map([]),
  ...queryArgs
}) => {
  const { refetch } = useQuery(query, { skip: true });

  const csvData = [];

  const loadCSV = () => {
    setPending(true);
    const getPage = async pageNum => {
      let queryResult = await refetch({
        page: pageNum,
        pageSize: pageSize,
        ...queryArgs,
      });

      csvData.push(...getResults(queryResult.data));
      const { nextPage, total } = getPagination(queryResult.data);
      if (nextPage) {
        onProgress(
          Math.round(((nextPage - 1) / Math.ceil(total / pageSize)) * 100) /
            100,
        );
        getPage(nextPage);
      } else {
        const header = [Array.from(csvFields.keys())];
        const rows = header.concat(
          csvData.map(row => Array.from(csvFields, ([_, f]) => f(row))),
        );

        const csvContent =
          'data:text/csv;base64,' +
          btoa(unescape(encodeURIComponent(stringify(rows))));

        const csvFileName = `auction_export_${moment().format(
          'YYYYMMDD_HHmm',
        )}.csv`;

        const element = document.createElement('a');
        element.href = csvContent;
        element.download = csvFileName;
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
        onProgress(1);
        setPending(false);
        onComplete();
      }
    };
    getPage(1);
  };

  const [pending, setPending] = useState(false);
  return render({ loadCSV, pending });
};

export default CSVExporter
