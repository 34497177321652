// External
import { useMutation, useQuery } from '@apollo/react-hooks';
import { NetworkStatus } from 'apollo-boost';
import gql from 'graphql-tag';
import _ from 'lodash';
import moment from 'moment-timezone';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';

// Material UI
import {
  Box,
  Card,
  CardContent,
  Drawer,
  Fab,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Tooltip,
  useMediaQuery,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { DatePicker } from '@mui/x-date-pickers';

// Internal
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import DealerPicker from 'components/MaterialUI/DealerPicker';
import ErrorPage from 'components/MaterialUI/ErrorPage';
import Loading from 'components/MaterialUI/Loading';
import { useUserContext } from 'components/MaterialUI/UserContext';

import {
  formatPrice,
  usePersistedState,
  getMediumDate,
  defaultTZ,
} from 'utils';

import { defaultFacet } from '../const';
import FacetChips from './FacetChips';
import FilterDrawer from './FilterDrawer';
import AppointmentsTable from './ROTablesComponents/AppointmentsTable';
import UnsharedPhotoTable from './ROTablesComponents/UnsharedPhotoTable';
import UnsharedVideoTable from './ROTablesComponents/UnsharedVideoTable';
import ServiceROForm from './ServiceROForm';
import { Role } from 'constants.js';

const PAGE_SIZE = 20;

const TEMP_RO_ROLES = [
  Role.SERVICE_TECHNICIAN,
  Role.SERVICE_ADVISOR,
  Role.SALES_MANAGER,
  Role.GENERAL_MANAGER,
  Role.ORGANIZATION_ADMIN,
  Role.SERVICE_MANAGER,
];

const TEMP_SERVICE_RO = gql`
  mutation createTempRO($dealer_id: Int!, $ro_number: String!) {
    rotracker {
      createTempRO(dealer_id: $dealer_id, ro_number: $ro_number) {
        dealer_id
        ro_number
      }
    }
  }
`;

const FACETS_QUERY = gql`
  query ROTrackersQuery(
    $dealer_id: Int!
    $queryFacets: [QueryFacet]!
    $filters: [QueryFilter]
    $sort: [QuerySortElement]
    $cursor: String
  ) {
    rotracker {
      getROsByCursor(filters: $filters, cursor: $cursor, page_size: ${PAGE_SIZE}, sort: $sort) {
        cursor
        total
        results {
          id
          dealer_id
          ro_number
          opened_date
          remarks
          status
          customer {
            _id
          }
          smsIdentity {
            customer_id
          }
          emailIdentity {
            customer_id
          }
          customer_name
          cdk_customer_number
          cdk_service_advisor_number
          cdk_service_advisor_display_name
          adp_customer_urn
          appointment_date
          video_count
          photo_count
          gocard {
            cardId
            cardNumber
            cashBalance
          }
          vehicle_make
          vehicle_model
          serviceAdvisor {
            display_name
          }
          inspection_count
          last_transaction_payment_status
        }
      }
      getROTrackerFacets(
        dealer_id: $dealer_id
        filters: $filters
        facets: $queryFacets
      ) {
        data {
          count
          value
        }
        field
        model
      }
    }
  }
`;

const DEALER_QUERY = gql`
  query dealerInfo($dealerId: Int!) {
    inventory {
      getDealer(id: $dealerId) {
        id
        fortellis_subscription_id
        global_payments_merchant_id
        global_payments_merchant_shared_secret
        global_payments_merchant_sub_account
      }
    }
  }
`;

const headCells = [
  {
    id: 'ro_number',
    numeric: false,
    disablePadding: false,
    label: 'RO #',
  },
  {
    id: 'opened_date',
    numeric: false,
    disablePadding: false,
    label: 'Opened',
  },
  {
    id: 'customer_name',
    numeric: false,
    disablePadding: false,
    label: 'Customer',
  },
  {
    id: 'gocard_id',
    numeric: false,
    disablePadding: false,
    label: 'Go Card',
  },
  {
    id: 'cdk_service_advisor_display_name',
    numeric: false,
    disablePadding: false,
    label: 'Service Advisor',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'video_count',
    numeric: false,
    disablePadding: false,
    label: 'Videos',
  },
  {
    id: 'photo_count',
    numeric: false,
    disablePadding: false,
    label: 'Photos',
  },
  {
    id: 'inspection_count',
    numeric: false,
    disablePadding: false,
    label: 'Inspections',
  },
  {
    id: 'last_transaction_payment_status',
    numeric: false,
    disablePadding: false,
    label: 'Payment Status',
  },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort, paymentsConfigured } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };
  const displayHeadCell = headCell =>
    (headCell.id === 'last_transaction_payment_status' && paymentsConfigured) ||
    headCell.id !== 'last_transaction_payment_status';

  return (
    <TableHead>
      <TableRow>
        {headCells.map(
          headCell =>
            displayHeadCell(headCell) && (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? 'right' : 'left'}
                padding={headCell.disablePadding ? 'none' : 'normal'}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === 'desc'
                        ? 'sorted descending'
                        : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            ),
        )}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  customerName: {
    color: '#0093a7',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const DEFAULT_OPEN_ROS = {
  field: 'is_open',
  model: 'ServiceRO',
  options: { label: 'Open ROs' },
  value: 'True',
};

const ROTable = () => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('ro_number');
  const [searchFilters, setSearchFilters] = useState([]);
  const [searchKeywords, setSearchKeywords] = useState('');
  const [filterFacets, setFilterFacets] = usePersistedState(
    'ROTableFilterFacets',
    defaultFacet,
  );
  const [openedAtFromFilter, setOpenedAtFromFilter] = useState(null);
  const [openedAtToFilter, setOpenedAtToFilter] = useState(null);
  const { dealerId } = useDealerContext();
  const { currentUser } = useUserContext();
  const timezone = currentUser?.goUserProfile?.settings?.timezone || defaultTZ;
  const isDesktop = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const [sort, setSort] = useState([
    { model: 'ServiceRO', field: 'ro_number', direction: 'desc' },
  ]);
  const [tempModalOpen, setTempModalOpen] = useState(false);
  const [paymentsConfigured, setPaymentsConfigured] = useState(false);
  const role = currentUser?.role || 'none';
  const [lastPage, setLastPage] = useState(false);
  const theme = useTheme();

  const handleClickRO = () => setTempModalOpen(true);
  const handleDeleteAllROsFacet = () => {
    setFilterFacets([...filterFacets, DEFAULT_OPEN_ROS]);
  };
  const [appointmentDate, setAppointmentDate] = useState(
    moment().format('YYYY-MM-DD'),
  );
  const [createTempRO, { loading: tempROLoading }] = useMutation(
    TEMP_SERVICE_RO,
    {
      refetchQueries: ['ROTrackersQuery'],
      onCompleted: ro => {
        enqueueSnackbar(
          `Created temporary RO for RO#: ${ro.rotracker.createTempRO.ro_number}`,
        );
        setTempModalOpen(false);
        setLastPage(false);
      },
      onError: e => {
        enqueueSnackbar('Cannot create Service RO, Service RO already exists!');
        setTempModalOpen(false);
        setLastPage(false);
      },
    },
  );

  const deletePaymentFacets = () =>
    setFilterFacets(prev =>
      prev.filter(
        ({ model, field }) =>
          model !== 'ServiceRO' || field !== 'last_transaction_payment_status',
      ),
    );

  const { data: dealerData, loading: dealerLoading } = useQuery(DEALER_QUERY, {
    variables: { dealerId },
  });

  useEffect(() => {
    if (!dealerLoading) {
      const {
        fortellis_subscription_id,
        global_payments_merchant_id,
        global_payments_merchant_shared_secret,
        global_payments_merchant_sub_account,
      } = dealerData?.inventory.getDealer ?? {};

      if (
        fortellis_subscription_id &&
        global_payments_merchant_id &&
        global_payments_merchant_shared_secret &&
        global_payments_merchant_sub_account
      )
        setPaymentsConfigured(true);
      else {
        setPaymentsConfigured(false);
        deletePaymentFacets();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealerData, dealerLoading]);

  const setSearchFiltersFromKeywords = () =>
    setSearchFilters(
      searchKeywords.split(' ').map(keyword => ({
        or: [
          {
            model: 'ServiceRO',
            field: 'customer_name',
            op: 'ilike',
            value: `%${keyword}%`,
          },
          {
            model: 'ServiceRO',
            field: 'ro_number',
            op: 'ilike',
            value: `%${keyword}%`,
          },
          {
            model: 'ServiceRO',
            field: 'cdk_service_advisor_display_name',
            op: 'ilike',
            value: `%${keyword}%`,
          },
        ],
      })),
    );

  useEffect(() => {
    if (searchKeywords) setSearchFiltersFromKeywords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = e =>
    e.key === 'Enter' ? setSearchFiltersFromKeywords() : null;

  let dateFilters = [];
  let otherFacets = [];

  if (openedAtFromFilter) {
    dateFilters.push({
      model: 'ServiceRO',
      field: 'opened_date',
      op: '>=',
      value: openedAtFromFilter.startOf('day').tz(timezone).toISOString(),
    });
    otherFacets.push({
      label: `> ${openedAtFromFilter.format('YYYY-MM-DD')}`,
      onDelete: () => setOpenedAtFromFilter(null),
    });
  }

  if (openedAtToFilter) {
    dateFilters.push({
      model: 'ServiceRO',
      field: 'opened_date',
      op: '<=',
      value: openedAtToFilter.startOf('day').tz(timezone).toISOString(),
    });
    otherFacets.push({
      label: `< ${openedAtToFilter.format('YYYY-MM-DD')}`,
      onDelete: () => setOpenedAtToFilter(null),
    });
  }

  const queryFacets = [
    { model: 'ServiceRO', field: 'cdk_service_advisor_display_name' },
    { model: 'ServiceRO', field: 'status' },
    { model: 'ServiceRO', field: 'has_video' },
    { model: 'ServiceRO', field: 'has_photo' },
    { model: 'ServiceRO', field: 'is_open' },
    { model: 'ServiceRO', field: 'last_transaction_payment_status' },
  ];

  const formatFilterFacets = filterFacets => {
    const returnFilters = [];

    Object.entries(_.groupBy(filterFacets, x => [x.model, x.field])).forEach(
      ([modelField, entries]) => {
        const nullValues = entries.filter(x => x.value === null);
        const or = [];

        if (nullValues.length > 0) {
          or.push({
            model: modelField.split(',')[0],
            field: modelField.split(',')[1],
            op: 'in',
            value: entries
              .filter(e => e.value !== null)
              .map(({ value }) =>
                value === 'True' ? true : value === 'False' ? false : value,
              ),
          });
          or.push({
            model: modelField.split(',')[0],
            field: modelField.split(',')[1],
            op: 'is_null',
          });
        } else {
          returnFilters.push({
            model: modelField.split(',')[0],
            field: modelField.split(',')[1],
            op: 'in',
            value: entries
              .filter(e => e.value !== null)
              .map(({ value }) =>
                value === 'True' ? true : value === 'False' ? false : value,
              ),
          });
        }
        if (or.length !== 0) returnFilters.push({ or });
      },
    );
    return returnFilters;
  };

  const filters = [
    { model: 'ServiceRO', field: 'dealer_id', op: '==', value: dealerId },
    ...formatFilterFacets(filterFacets),
    ...dateFilters,
    ...searchFilters,
  ];

  const {
    data: facetsData,
    fetchMore,
    loading,
    networkStatus,
    error,
  } = useQuery(FACETS_QUERY, {
    variables: {
      dealer_id: dealerId,
      queryFacets,
      filters,
      sort,
      page: 1,
    },
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
  });

  const handleRequestSort = (event, property) => {
    setSort([
      {
        model: 'ServiceRO',
        field: property,
        direction: order === 'asc' ? 'desc' : 'asc',
      },
    ]);
    setOrder(order === 'asc' ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const [tableIndex, setTableIndex] = usePersistedState(
    'ROTrackerTableIndex',
    3,
  );

  const [state, setState] = useState({
    left: false,
  });
  const history = useHistory();

  const { cursor, total } = facetsData?.rotracker?.getROsByCursor || {};

  const fetchMoreHandler = () => {
    if (!lastPage) {
      fetchMore({
        variables: {
          cursor: cursor,
        },
        updateQuery: (prev, { fetchMoreResult: more }) => {
          if (more.rotracker.getROsByCursor.cursor === cursor) {
            setLastPage(true);
            return prev;
          }
          return Object.assign({}, prev, {
            rotracker: {
              __typename: prev.rotracker.__typename,
              getROsByCursor: {
                __typename: prev.rotracker.getROsByCursor.__typename,
                cursor: more.rotracker.getROsByCursor.cursor,
                total: more.rotracker.getROsByCursor.total,
                results: [
                  ...prev.rotracker.getROsByCursor.results,
                  ...more.rotracker.getROsByCursor.results,
                ],
              },
              getROTrackerFacets: more.rotracker.getROTrackerFacets,
            },
          });
        },
      });
    }
  };

  const rows = facetsData?.rotracker?.getROsByCursor?.results || [];

  const handleChange = (event, newValue) => {
    setTableIndex(newValue);
  };

  const toggleDrawer = (anchor, open) => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const facetResults = facetsData?.rotracker?.getROTrackerFacets || [];

  const handleDeleteFacet = (_model, _field, _value) =>
    setFilterFacets(prev =>
      prev.filter(
        ({ model, field, value }) =>
          model !== _model || field !== _field || value !== _value,
      ),
    );

  const generateCustomerLink = row => {
    const customer_id = row.emailIdentity
      ? row.emailIdentity.customer_id
      : row.smsIdentity
      ? row.smsIdentity.customer_id
      : null;
    if (customer_id !== null) {
      return `/customers/${customer_id}/details`;
    }
    return `/customers/${row.customer._id}/details`;
  };

  useEffect(() => {
    setLastPage(false);
  }, [
    dealerId,
    filterFacets,
    openedAtFromFilter,
    openedAtToFilter,
    searchKeywords,
    sort,
  ]);

  if (error) return <ErrorPage error={error} action="Loading ROs" />;

  return (
    <Box m={1}>
      <Box>
        <DealerPicker />
      </Box>
      <TabContext value={tableIndex}>
        <TabList
          indicatorColor="secondary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          onChange={handleChange}
          value={tableIndex}
        >
          <Tab label="Today's Appointments" value={3}></Tab>
          <Tab label="ROs" value={4}></Tab>
          <Tab label="Unshared Videos" value={1}></Tab>
          <Tab label="Unshared Photos" value={2}></Tab>
          {/* <Tab label="Awaiting Payment" value={3}></Tab>
          <Tab label="Awaiting Inspection" value={4}></Tab> */}
        </TabList>
        <TabPanel value={4} style={{ padding: 0 }}>
          <Grid
            container
            direction="row"
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Grid item xs={12} sm={6}>
              <div style={{ paddingBottom: '1rem' }}>
                {isDesktop && (
                  <Grid
                    container
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <Grid
                      item
                      style={{
                        paddingRight: '1rem',
                        paddingTop: '1rem',
                        paddingBottom: '1rem',
                      }}
                    >
                      {TEMP_RO_ROLES.includes(role) && (
                        <Tooltip title="Add Temporary RO">
                          <Fab
                            color="primary"
                            onClick={handleClickRO}
                            size="medium"
                            style={theme.actions.confirm}
                          >
                            <AddIcon />
                          </Fab>
                        </Tooltip>
                      )}
                    </Grid>
                    <Grid
                      item
                      style={{
                        paddingRight: '1rem',
                        paddingTop: '1rem',
                        paddingBottom: '1rem',
                      }}
                    >
                      <Tooltip title="Filter list">
                        <Fab
                          color="primary"
                          onClick={toggleDrawer('left', true)}
                          size="medium"
                        >
                          <FilterListIcon />
                        </Fab>
                      </Tooltip>
                    </Grid>
                    <Grid item style={{ display: 'flex', paddingTop: '1rem' }}>
                      {(filterFacets || !_.isEmpty(otherFacets)) && (
                        <Box paddingTop="0.5rem" paddingBottom="0.5rem">
                          <FacetChips
                            facets={filterFacets}
                            onDeleteFacet={handleDeleteFacet}
                            handleDeleteAllROsFacet={handleDeleteAllROsFacet}
                            others={otherFacets}
                          />
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                )}
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              style={{
                display: 'flex',
              }}
            >
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginLeft: `${isDesktop ? 'auto' : ''}`,
                  marginRight: `${isDesktop ? 0 : ''}`,
                  width: `${isDesktop ? '' : '100%'}`,
                }}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  style={{ width: `${isDesktop ? '' : '100%'}` }}
                >
                  <Box>
                    <TextField
                      placeholder="Search ..."
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              disabled={!searchKeywords}
                              onClick={() => {
                                setSearchKeywords('');
                                setSearchFilters([]);
                              }}
                              size="large"
                            >
                              <CloseIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      value={searchKeywords}
                      onChange={e => setSearchKeywords(e.target.value)}
                      style={{
                        paddingRight: '5px',
                        textAlign: 'right',
                        width: `${isDesktop ? '' : '100%'}`,
                      }}
                      onKeyPress={handleSearch}
                      variant={`${isDesktop ? 'standard' : 'outlined'}`}
                    />
                  </Box>
                  <Box
                    style={{
                      display: 'flex',
                      marginLeft: 'auto',
                      marginRight: 0,
                      fontSize: '18px',
                      paddingTop: '1rem',
                    }}
                  >
                    Showing {rows.length} of {total || '0'}
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
          {!isDesktop && (
            <>
              {(!_.isEmpty(filterFacets) || !_.isEmpty(otherFacets)) && (
                <Box paddingTop="0.5rem" paddingBottom="0.5rem">
                  <FacetChips
                    facets={filterFacets}
                    onDeleteFacet={handleDeleteFacet}
                    others={otherFacets}
                  />
                </Box>
              )}
            </>
          )}
          <Drawer
            anchor={'left'}
            open={state['left']}
            onClose={toggleDrawer('left', false)}
          >
            <div style={{ display: 'flex' }}>
              <IconButton
                style={{
                  display: 'flex',
                  marginLeft: 'auto',
                  height: '50px',
                  zIndex: '1000',
                }}
                onClick={toggleDrawer('left', false)}
                size="large"
              >
                <CloseIcon />
              </IconButton>
            </div>
            <FilterDrawer
              facetResults={facetResults}
              loading={loading}
              openedAtFromFilter={openedAtFromFilter}
              openedAtToFilter={openedAtToFilter}
              setOpenedAtFromFilter={setOpenedAtFromFilter}
              setOpenedAtToFilter={setOpenedAtToFilter}
              setSelectedFacets={setFilterFacets}
              selectedFacets={filterFacets}
              type="ros"
              paymentsConfigured={paymentsConfigured}
            />
          </Drawer>
          {isDesktop && (
            <Paper>
              <TableContainer>
                <Table className={classes.table}>
                  <EnhancedTableHead
                    classes={classes}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={rows?.length}
                    paymentsConfigured={paymentsConfigured}
                  />
                  {loading && <Loading />}
                  {rows.length === 0 && !loading && (
                    <Box style={{ fontSize: '20px', padding: '1rem' }}>
                      This dealer has no service ROs.
                    </Box>
                  )}
                  <TableBody>
                    {rows.map((row, index) => {
                      return (
                        <TableRow
                          hover
                          onClick={() =>
                            history.push(
                              `/rotracker/${row.dealer_id}/${row.ro_number}`,
                            )
                          }
                          key={row.ro_number}
                        >
                          <TableCell>{row.ro_number}</TableCell>
                          <TableCell>{row.opened_date}</TableCell>
                          <TableCell>
                            {row?.customer ||
                            row?.smsIdentity ||
                            row?.emailIdentity ? (
                              <Link
                                to={() => generateCustomerLink(row)}
                                className={classes.customerName}
                                onClick={e => e.stopPropagation()}
                              >
                                {row.customer_name}
                              </Link>
                            ) : (
                              row.customer_name
                            )}
                          </TableCell>
                          <TableCell style={{ whiteSpace: 'pre-line' }}>
                            {row?.gocard ? row.gocard.cardNumber : 'N/A'}
                            {row?.gocard
                              ? `\n${formatPrice(row.gocard.cashBalance, {
                                  cents: true,
                                  zeroIfNull: true,
                                })}`
                              : ''}
                          </TableCell>
                          <TableCell>
                            {row?.cdk_service_advisor_display_name}
                          </TableCell>
                          <TableCell>{row.status}</TableCell>
                          <TableCell>{row.video_count}</TableCell>
                          <TableCell>{row.photo_count}</TableCell>
                          <TableCell>{row.inspection_count}</TableCell>
                          {paymentsConfigured && (
                            <TableCell>
                              {row?.last_transaction_payment_status}
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          )}
          {!isDesktop && (
            <>
              {loading && <Loading />}
              {rows.length === 0 && !loading && (
                <Box style={{ fontSize: '20px', padding: '1rem' }}>
                  This dealer has no service ROs.
                </Box>
              )}
              <>
                {rows.map(ro => (
                  <Box style={{ paddingBottom: '1rem' }} key={ro.id}>
                    <Card
                      onClick={() =>
                        history.push(
                          `/rotracker/${ro.dealer_id}/${ro.ro_number}`,
                        )
                      }
                    >
                      <CardContent>
                        <Grid container direction="row" xs={12}>
                          <Grid item xs={6}>
                            <Box paddingBottom={1}>
                              <b>RO Number:</b> {ro.ro_number}
                            </Box>
                          </Grid>
                          <Grid item xs={6} style={{ textAlign: 'right' }}>
                            <Box>
                              <b>{ro.status}</b>
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid container direction="row" xs={12}>
                          <Grid item xs={12}>
                            <Box style={{ fontSize: '11px' }} paddingBottom={1}>
                              {ro?.customer ||
                              ro.smsIdentity ||
                              ro.emailIdentity ? (
                                role === Role.SERVICE_TECHNICIAN ? (
                                  <>{ro.customer_name}</>
                                ) : (
                                  <Link
                                    to={() => generateCustomerLink(ro)}
                                    className={classes.customerName}
                                    onClick={e => e.stopPropagation()}
                                  >
                                    {ro.customer_name}
                                  </Link>
                                )
                              ) : (
                                ro.customer_name
                              )}
                            </Box>
                            <Box style={{ fontSize: '11px' }} paddingBottom={1}>
                              <b>Go Card:</b>{' '}
                              {ro?.gocard ? ro.gocard.cardNumber : 'N/A'}
                              {ro?.gocard
                                ? ` | ${formatPrice(ro.gocard.cashBalance, {
                                    cents: true,
                                    zeroIfNull: true,
                                  })}`
                                : ''}
                            </Box>
                            <Box style={{ fontSize: '13px' }} paddingBottom={1}>
                              <b>Service Advisor:</b>{' '}
                              {ro.serviceAdvisor?.display_name}
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          direction="row"
                          xs={12}
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Grid
                            item
                            style={{ display: 'flex', width: '100%' }}
                            xs={7}
                          >
                            <Box style={{ width: '100%' }}>
                              <Grid
                                container
                                direction="row"
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  flexDirection: 'row',
                                  width: '100%',
                                  fontSize: '11px',
                                }}
                              >
                                <Grid item>Videos: {ro.video_count}</Grid>
                                <Grid item>Photos: {ro.photo_count}</Grid>
                                <Grid item>Insp: {ro.inspection_count}</Grid>
                              </Grid>
                            </Box>
                          </Grid>
                          <Grid
                            item
                            style={{ fontSize: '11px', textAlign: 'right' }}
                            xs={5}
                          >
                            <Box>Opened {getMediumDate(ro.opened_date)}</Box>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Box>
                ))}
              </>
            </>
          )}
          {!isDesktop && (
            <>
              {TEMP_RO_ROLES.includes(role) && (
                <Box position="fixed" bottom="75px" right="10px">
                  <Fab
                    color="primary"
                    onClick={handleClickRO}
                    size="medium"
                    style={theme.actions.confirm}
                  >
                    <AddIcon />
                  </Fab>
                </Box>
              )}
              <Box position="fixed" bottom="10px" right="10px">
                <Fab
                  color="primary"
                  onClick={toggleDrawer('left', true)}
                  size="medium"
                >
                  <FilterListIcon />
                </Fab>
              </Box>
            </>
          )}
          <ServiceROForm
            isOpen={tempModalOpen}
            onClose={() => setTempModalOpen(false)}
            isLoading={tempROLoading}
            onSubmit={({ ro_number }) => {
              if (
                window.confirm(
                  'This will start a new RO for the customer. When the data is synced from CDK this RO will be updated with any additional information. Please ensure you are entering the correct RO Number.',
                )
              ) {
                createTempRO({
                  variables: {
                    dealer_id: dealerId,
                    ro_number: ro_number,
                  },
                });
              }
            }}
          />
          {!loading && (
            <Box height="1px">
              <Waypoint onEnter={fetchMoreHandler} />
            </Box>
          )}
          {networkStatus === NetworkStatus.fetchMore && (
            <Box p={2} style={{ fontSize: '16px' }}>
              Loading more Service ROs...
            </Box>
          )}
        </TabPanel>
        <TabPanel value={1} style={{ padding: 0 }}>
          <UnsharedVideoTable
            isDesktop={isDesktop}
            toggleDrawer={toggleDrawer}
            handleDeleteFacet={handleDeleteFacet}
            state={state}
            classes={classes}
            order={order}
            orderBy={orderBy}
            setOrder={setOrder}
            setOrderBy={setOrderBy}
          />
        </TabPanel>
        <TabPanel value={2} style={{ padding: 0 }}>
          <UnsharedPhotoTable
            isDesktop={isDesktop}
            toggleDrawer={toggleDrawer}
            handleDeleteFacet={handleDeleteFacet}
            state={state}
            classes={classes}
            order={order}
            orderBy={orderBy}
            setOrder={setOrder}
            setOrderBy={setOrderBy}
          />
        </TabPanel>
        <TabPanel value={3} style={{ padding: 0 }}>
          <Box
            style={{
              margin: '1em',
              display: 'flex',
              justifyContent: isDesktop ? 'flex-start' : 'flex-end',
            }}
          >
            <DatePicker
              autoOk
              clearable
              label="Selected Date"
              format="YYYY-MM-DD"
              onChange={date => setAppointmentDate(date)}
              value={moment(appointmentDate)}
              variant="dialog"
              disablePast
            />
          </Box>
          <AppointmentsTable
            isDesktop={isDesktop}
            datePickerDate={appointmentDate}
            toggleDrawer={toggleDrawer}
            state={state}
          />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default ROTable;
