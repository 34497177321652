import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useSnackbar } from 'notistack';

import { useMutation } from '@apollo/react-hooks';
import { makeStyles } from '@mui/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import PersonIcon from '@mui/icons-material/Person';

import { TaskStatus } from '../constants';
import TaskReassign from './TaskReassign';

const useStyles = makeStyles({
  center: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
});

const UPDATE_SELECTED_TASKS = gql`
  mutation UpdateTasks($ids: [ID!]!, $input: UpdateTaskInput!) {
    updateTasks(ids: $ids, input: $input) {
      _id
      assignee {
        username
        display_name
        role
      }
    }
  }
`;

const TaskBulkAction = ({
  refetch,
  anchorEl,
  selectByFilter,
  filters,
  selected,
  handleAnchorClose,
  setSelected,
}) => {
  const classes = useStyles();
  const [showReassign, setShowReassign] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [changeSelectedTasks] = useMutation(UPDATE_SELECTED_TASKS, {
    onCompleted: data => {
      refetch({ fetchPolicy: 'network-only' });
      setSelected([]);
      enqueueSnackbar('Successfully changed selected tasks', {
        variant: 'success',
      });
      handleAnchorClose();
    },
    onError: err => {
      enqueueSnackbar(`Could not change selected tasks. Error: ${err}`, {
        variant: 'error',
      });
    },
  });

  const handleCompleteOrCancelBulkClick = status => {
    if (
      window.confirm(
        `Are you sure you want to bulk action ${
          status === TaskStatus.COMPLETE ? 'complete' : 'cancel'
        } tasks`,
      )
    )
      changeSelectedTasks({
        variables: {
          ids: selected,
          input: {
            status: status,
          },
        },
      });
  };

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleAnchorClose}
      >
        <MenuItem
          disabled={selected.length === 0}
          onClick={handleCompleteOrCancelBulkClick.bind(
            this,
            TaskStatus.COMPLETE,
          )}
        >
          <div className={classes.center}>
            <CheckIcon />
            <span>Complete</span>
          </div>
        </MenuItem>
        <MenuItem
          disabled={selected.length === 0}
          onClick={() => setShowReassign(true)}
        >
          <div className={classes.center}>
            <PersonIcon />
            <span>Reassign</span>
          </div>
        </MenuItem>
        <MenuItem
          disabled={selected.length === 0}
          onClick={handleCompleteOrCancelBulkClick.bind(
            this,
            TaskStatus.CANCELLED,
          )}
        >
          <div className={classes.center}>
            <CloseIcon />
            <span>Cancel</span>
          </div>
        </MenuItem>
      </Menu>
      <TaskReassign
        open={showReassign}
        setOpen={() => {
          setShowReassign(false);
          handleAnchorClose();
        }}
        task={selected}
        action={null}
        refetch={refetch}
      />
    </>
  );
};

export default TaskBulkAction;
