import React from 'react';

/* external */
import gql from 'graphql-tag';

/* Material UI */
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import makeStyles from '@mui/styles/makeStyles';
import SendIcon from '@mui/icons-material/Send';
import LaunchIcon from '@mui/icons-material/Launch';

/* internal */
import { formatOdometer, formatPrice } from 'utils';
import { paveCaptureLink, paveReportLink } from 'modules/appraisals/utils';
import FormatDateTZ from 'components/MaterialUI/FormatDateTZ';

const useStyles = makeStyles(theme => ({
  roundedIconButton: {
    backgroundColor: theme.actions.add.backgroundColor,
    fill: 'white',
    color: 'white',
    borderRadius: '4px',
    margin: theme.spacing(1),
  },
}));

const LabelBox = ({ children }) => (
  <Box component="span" fontWeight="bold" fontSize={12} paddingRight={1}>
    {children}
  </Box>
);
const DataBox = ({ children }) => (
  <Box component="span" fontSize={12}>
    {children}
  </Box>
);

const ROW_SIZING = { xs: 12, md: 6 };

const AppraisalSummaryBox = ({
  appraisal,
  onClickResendSMS,
  noControls,
  ...rest
}) => {
  const classes = useStyles();
  const {
    createdAt,
    dealer,
    estimatedReconCost,
    isPaveSessionComplete,
    make,
    model,
    odometer,
    paveSessionId,
    trim,
    value,
    vin,
    year,
    vautoDeepLink,
  } = appraisal;

  const awaitingPave = paveSessionId && !isPaveSessionComplete;

  const handleClickOpenPave = () =>
    window.open(
      paveCaptureLink(paveSessionId),
      '_blank',
      'noopener,noreferrer',
    );

  return (
    <Box {...rest} marginBottom={2} textAlign="left">
      <Grid container>
        <Grid item {...ROW_SIZING}>
          <LabelBox>
            {year} {make} {model} {trim}
          </LabelBox>
        </Grid>
        <Grid item {...ROW_SIZING}>
          <LabelBox>Dealer:</LabelBox>
          <DataBox>{dealer?.name}</DataBox>
        </Grid>
        {awaitingPave ? (
          <Grid item {...ROW_SIZING}>
            <Box>
              <Alert style={{ width: '18em' }} severity="warning">
                Pave Not Completed
              </Alert>
              {!noControls && (
                <IconButton
                  size="small"
                  className={classes.roundedIconButton}
                  onClick={onClickResendSMS}
                >
                  <SendIcon />
                </IconButton>
              )}
              {!noControls && (
                <IconButton
                  size="small"
                  className={classes.roundedIconButton}
                  onClick={handleClickOpenPave}
                >
                  <Icon className="fas fa-road" style={{ width: 'auto' }} />
                </IconButton>
              )}
            </Box>
          </Grid>
        ) : (
          <>
            <Grid item {...ROW_SIZING}>
              <LabelBox>Vin:</LabelBox>
              <DataBox>{vin}</DataBox>
            </Grid>
            <Grid item {...ROW_SIZING}>
              <LabelBox>Odometer:</LabelBox>
              <DataBox>{formatOdometer(odometer)}</DataBox>
            </Grid>
            <Grid item {...ROW_SIZING}>
              <LabelBox>Recon Cost:</LabelBox>
              <DataBox>{formatPrice(estimatedReconCost)}</DataBox>
            </Grid>
            <Grid item {...ROW_SIZING}>
              <LabelBox>Created:</LabelBox>
              <DataBox>
                <FormatDateTZ date={createdAt} />
              </DataBox>
            </Grid>
            <Grid item {...ROW_SIZING}>
              <LabelBox>Appraised Value:</LabelBox>
              <DataBox>{formatPrice(value)}</DataBox>
            </Grid>
          </>
        )}
        {isPaveSessionComplete && (
          <Grid item {...ROW_SIZING}>
            <Alert style={{ width: '18em' }} severity="success">
              Pave Completed
            </Alert>
            {paveSessionId && (
              <Link
                style={{ textUnderlineOffset: '0.3em' }}
                href={paveReportLink(paveSessionId)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Box display="flex" alignItems="center">
                  View PAVE Report{' '}
                  <Icon
                    className="fas fa-road"
                    style={{ width: 'auto', marginLeft: '5px' }}
                  />
                </Box>
              </Link>
            )}
          </Grid>
        )}
        {vautoDeepLink && (
          <Grid item {...ROW_SIZING}>
            <a
              style={{ textUnderlineOffset: '0.3em' }}
              href={vautoDeepLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              View Appraisal in vAuto{' '}
              <LaunchIcon style={{ fontSize: '16px' }} />
            </a>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

AppraisalSummaryBox.fragments = {
  appraisal: gql`
    fragment AppraisalSummaryAppraisal on Appraisal {
      createdAt
      dealer {
        id
        name
      }
      estimatedReconCost
      isPaveSessionComplete
      make
      model
      odometer
      paveSessionId
      trim
      value
      vautoDeepLink
      vautoId
      vin
      year
    }
  `,
};

export default AppraisalSummaryBox;
