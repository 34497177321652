import React, { useState } from 'react';

/* external */
import gql from 'graphql-tag';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';

/* Material UI */
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

/* internal */
import Loading from 'components/MaterialUI/Loading';
import DecodeButton from 'modules/appraisals/components/DecodeButton';

const DECODE = gql`
  query decodeVehicle($vin: String) {
    inventory {
      decodeVehicle(vin: $vin) {
        styles {
          style_id
          make_name
          model_name
          trim
          year
        }
      }
    }
  }
`;

const EDIT_VEHICLE = gql`
  mutation editVehicle($id: ID!, $input: PackageVehicleInput!) {
    concierge {
      editPackageVehicle(id: $id, input: $input) {
        id
        makeName
        modelName
        year
        trim
      }
    }
  }
`;

const EditVehicleModal = ({ goldCard, isOpen, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [make, setMake] = useState(null);
  const [model, setModel] = useState(null);
  const [year, setYear] = useState(null);
  const [trim, setTrim] = useState(null);
  const [vin, setVin] = useState(null);

  const [editVehicle, { loading }] = useMutation(EDIT_VEHICLE, {
    onCompleted: () => {
      enqueueSnackbar('Vehicle successfully updated.', {
        variant: 'success',
      });
      onClose();
    },
    onError: () => {
      enqueueSnackbar('Failed to update vehicle.', { variant: 'error' });
    },
  });

  const setVehicleDataFromStyle = ({ make_name, model_name, trim, year }) => {
    setMake(make_name);
    setModel(model_name);
    setTrim(trim);
    setYear(year);
  };

  const [decodeVehicle, decodeVehicleQuery] = useLazyQuery(DECODE, {
    onCompleted: ({ inventory: { decodeVehicle } }) => {
      if (decodeVehicle.styles?.length > 0) {
        // Set data based on the first style
        setVehicleDataFromStyle(decodeVehicle.styles[0]);
      }
    },
    fetchPolicy: 'network-only',
  });

  const handleClickDecode = () => {
    decodeVehicle({ variables: { vin } });
  };

  const GridRow = ({ children, ...rest }) => (
    <Grid container item xs={12} alignItems="flex-end" spacing={2} {...rest}>
      {children}
    </Grid>
  );

  const handleSubmit = () => {
    editVehicle({
      variables: {
        id: goldCard.id,
        input: {
          makeName: make,
          modelName: model,
          year,
          trim,
        },
      },
    });
  };

  if (loading) return <Loading />;
  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        onClose();
      }}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Update Vehicle</DialogTitle>
      <DialogContent>
        <Grid container row>
          <GridRow>
            <Grid item xs={5}>
              <TextField
                label="Enter a VIN:"
                name="vin"
                value={vin}
                onChange={e => setVin(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={1}>
              <DecodeButton
                disabled={!vin || vin.length !== 17}
                loading={decodeVehicleQuery.loading}
                onClick={handleClickDecode}
              />
            </Grid>
          </GridRow>
        </Grid>
      </DialogContent>
      {year && make && model && (
        <Alert severity="success">
          {year} {make} {model} {trim}
        </Alert>
      )}
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          onClick={handleSubmit}
        >
          Update vehicle
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditVehicleModal;
