export const TaskStatus = {
  TODO: 0,
  COMPLETE: 1,
  CANCELLED: 2,
};

export const TaskStatusColor = {
  RED: '#D15B47',
  YELLOW: '#FFEB3B',
  GREEN: '#82AF6F',
  GREY: '#666',
  BLUE: '#0044cc',
};

export const sessionKeys = {
  oppFilters: 'opportunities.search.filters',
  oppDealer: 'opportunityDealerId',
  taskStatus: 'tasks.components.TasksList.statuses',
  taskAssignee: 'tasks.components.TasksList.assignees',
  taskDealer: 'tasksDealerId',
  apptFilterFacets: 'AppointmentFilterFacets',
  apptDealer: 'appointmentsDealerId',
  createdFromFilter: 'AppointmentCreateDateFrom',
  createdUntilFilter: 'AppointmentCreateDateUntil',
  startDateFromFilter: 'AppointmentStartDateFrom',
  inventoryFacets: 'inventoryFacets',
  inventoryDealerId: 'inventoryDealerId',
  leadsFacets: 'leads.components.LeadTable.filterFacets',
  leadsCreatedFrom: 'leads.components.LeadTable.createdFromFilter',
  leadsDealerId: 'leadsDealerId',
  inventoryFilters: 'inventoryFilters',
};

export const IN_STOCK_FACET = [
  {
    model: 'StockStatus',
    field: 'name',
    value: 'In Stock',
    options: {},
  },
];

export const GREATER_THAN_120_DAY_FACET = [
  {
    model: 'Vehicle',
    field: 'days_in_stock_category',
    value: '>120',
    options: {},
  },
];

export const GREATER_THAN_90_DAY_FACET = [
  {
    model: 'Vehicle',
    field: 'days_in_stock_category',
    value: '>90',
    options: {},
  },
];

export const NO_PRICE_CHANGES_7_DAYS_FACET = [
  {
    model: 'Vehicle',
    field: 'no_price_changes_at_7_days',
    value: 'True',
    options: { label: 'No Price Changes in 7 Days' },
  },
];

export const MISSING_STUFF_FACET = [
  {
    model: 'Vehicle',
    field: 'is_missing_description',
    value: 'True',
    options: { label: 'No Description' },
  },
  {
    model: 'Vehicle',
    field: 'is_missing_photos',
    value: 'True',
    options: { label: 'No Photos' },
  },
];

export const LEADS_REQUIRING_ATTENTION_FACET = [
  {
    model: 'Lead',
    field: 'is_archived',
    key: false,
    value: 'Not Archived',
    options: {},
  },
];
