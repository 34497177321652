import React, { Fragment, useState } from 'react';

/* external */
import { groupBy } from 'lodash';
import { NetworkStatus } from 'apollo-boost';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import gql from 'graphql-tag';
import moment from 'moment';

/* Material UI */
import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material';

/* internal */
import {
  AppraisalType,
  CREATED_SORT,
  CATEGORY_FILTERS,
  RECON_COST_SORT,
  VALUE_SORT,
  VEHICLE_SORT_OPTIONS,
  NEEDS_NUMBERS_FACET,
  CBB_COMPLETE_FACET,
  MISSING_BOTH_FACET,
} from 'modules/appraisals/const';
import {
  convertDateFilters,
  convertSearchFilters,
  convertCategoryFilters,
} from 'modules/appraisals/utils';
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import ConfirmDialog from 'components/MaterialUI/ConfirmDialog';
import DealerPicker from 'components/MaterialUI/DealerPicker';
import DesktopHeader from './DesktopHeader';
import ErrorPage from 'components/MaterialUI/ErrorPage';
import FilterDrawer from './FilterDrawer';
import ItemCard from './ItemCard';
import ItemRow from './ItemRow';
import Loading from 'components/MaterialUI/Loading';
import MobileHeader from './MobileHeader';
import ResponsiveComponent from 'components/MaterialUI/ResponsiveComponent';
import SortPopover from 'components/MaterialUI/SortPopover';
import useSorting from 'components/hooks/useSorting';
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';

const useStyles = makeStyles(() => ({
  headerRow: {
    '&>th': {
      fontWeight: 'bold',
    },
  },
}));

const PAGE_SIZE = 20;

const queryFacets = [
  {
    model: 'Appraisal',
    field: 'year',
  },
  {
    model: 'Appraisal',
    field: 'make',
  },
  {
    model: 'Appraisal',
    field: 'model',
  },
];

const DELETE_APPRAISAL_MUTATION = gql`
  mutation DeleteAppraisal($id: Int!) {
    appraisals {
      deleteAppraisal(id: $id) {
        id
      }
    }
  }
`;

const APPRAISAL_SHEET = gql`
  query appraisalSheet($id: Int!) {
    appraisals {
      appraisalSheet(appraisalId: $id) {
        url
      }
    }
  }
`;

const APPRAISALS_QUERY = gql`
  query AppraisalsQuery(
    $queryFacets: [QueryFacet]!
    $filters: [QueryFilter]
    $statsFilters: [QueryFilter]
    $statsFacets: [QueryFacet]!
    $sort: [QuerySortElement]
    $page: Int!
  ) {
    appraisals {
      appraisals(appraisalType: "${AppraisalType.TRADE_IN}", filters: $filters, page: $page, pageSize: ${PAGE_SIZE}, sort: $sort) {
        results {
          id
          pitches {
            id
          }
          ...AppraisalsListItemCardAppraisal
          ...AppraisalsListItemRowAppraisal
        }
        pagination {
          nextPage
          total
        }
      }
      appraisalsFacets(appraisalType: "${AppraisalType.TRADE_IN}", filters: $filters, facets: $queryFacets)
      {
        data {
          count
          value
        }
        field
        model
      }
      stats: appraisalsFacets(appraisalType: "${AppraisalType.TRADE_IN}", filters: $statsFilters, facets: $statsFacets){
        field
        model
        data {
          value
          count
        }
      }

    }
  }
  ${ItemCard.fragments.appraisal}
  ${ItemRow.fragments.appraisal}
`;

const filtersFromFacets = facetFilters =>
  Object.entries(groupBy(facetFilters, x => [x.model, x.field])).map(
    ([modelField, entries]) => ({
      model: modelField.split(',')[0],
      field: modelField.split(',')[1],
      op: 'in',
      value: entries.map(({ value }) =>
        value === 'True' ? true : value === 'False' ? false : value,
      ),
    }),
  );

const AppraisalsList = () => {
  const { dealerId } = useDealerContext();
  const { enqueueSnackbar } = useSnackbar();
  const { headerRow } = useStyles();
  const history = useHistory();
  const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const theme = useTheme();

  const [getAppraisalSheet, { loading: loadingSheet }] = useLazyQuery(
    APPRAISAL_SHEET,
    {
      fetchPolicy: 'network-only',
      onCompleted: data =>
        window.open(data.appraisals.appraisalSheet.url, '_blank'),
      onError: ({ graphQLErrors }) =>
        enqueueSnackbar(
          `Error creating appraisal sheet: ${graphQLErrors
            .map(x => x.message)
            .join(', ')}`,
          { variant: 'error' },
        ),
    },
  );

  const {
    isSortingBy,
    sort,
    sortProps,
    sortPopoverProps,
    sortDirection,
    setSort,
  } = useSorting([{ ...CREATED_SORT, direction: 'desc' }]);

  const [search, setSearch] = useState(''); // just the raw string used for searching
  const [dateFilters, setDateFilters] = useState({
    to: null,
    from: moment().subtract(90, 'days').startOf('day'),
  }); // { to: some moment, from: some moment }
  const [popoverAnchor, setPopoverAnchor] = useState();
  const [facetFilters, setFacetFilters] = useState([]);
  const [categoryFiltersIndex, setCategoryFiltersIndex] = useState(0);

  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
  const [confirmDeleteAppraisal, setConfirmDeleteAppraisal] = useState(null);

  const dealerFilter = {
    model: 'Appraisal',
    field: 'dealerId',
    op: '==',
    value: dealerId,
  };

  const filters = [
    dealerFilter,
    ...convertCategoryFilters(CATEGORY_FILTERS[categoryFiltersIndex]),
    ...convertSearchFilters(search),
    ...filtersFromFacets(facetFilters),
    ...convertDateFilters(dateFilters, {
      model: 'Appraisal',
      field: 'createdAt',
    }),
  ];

  const statsFilters = [
    dealerFilter,
    ...convertDateFilters(dateFilters, {
      model: 'Appraisal',
      field: 'createdAt',
    }),
  ];

  const statsFacets = [
    NEEDS_NUMBERS_FACET,
    CBB_COMPLETE_FACET,
    MISSING_BOTH_FACET,
  ];

  const { data, error, loading, fetchMore, networkStatus, refetch } = useQuery(
    APPRAISALS_QUERY,
    {
      variables: {
        sort,
        page: 1,
        filters,
        queryFacets,
        statsFilters,
        statsFacets,
      },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    },
  );

  const [deleteAppraisal, deleteMutation] = useMutation(
    DELETE_APPRAISAL_MUTATION,
    {
      // update: (cache, {data}) =>
      // We _could_ write a cache update function here, but the pagination and facets
      // would be all fucked up. So, refetch?
      // Only issue is that we'll only load the first 20 appraisals.  If someone deletes
      // one off of > page 1, they won't see the same appraisals they were seeing before.
      onCompleted: ({ appraisals: { deleteAppraisal } }) => {
        enqueueSnackbar(`Deleted appraisal #${deleteAppraisal.id}`, {
          variant: 'success',
        });
        refetch();
      },
      onError: error =>
        enqueueSnackbar(`Error deleting appraisal: ${JSON.stringify(error)}`),
    },
  );

  const appraisals = data?.appraisals.appraisals.results ?? [];
  const pagination = data?.appraisals.appraisals.pagination ?? {};
  const stats = data?.appraisals.stats ?? [];

  const { nextPage, total } = pagination;

  const handleMoreItems = () =>
    nextPage
      ? fetchMore({
          variables: { page: nextPage },
          updateQuery: (prev, { fetchMoreResult: more }) => {
            more.appraisals.appraisals.results = [
              ...prev.appraisals.appraisals.results,
              ...more.appraisals.appraisals.results,
            ];
            return more;
          },
        })
      : null;

  const vehicleSortLabel = VEHICLE_SORT_OPTIONS.find(
    x =>
      isSortingBy(x.sortOption) &&
      x.sortOption.every(y => y.direction === sortDirection),
  )?.label;

  if (loading && networkStatus !== NetworkStatus.fetchMore) return <Loading />;
  if (error) return <ErrorPage error={error} action="Loading appraisals" />;

  return (
    <Box m={1}>
      <LoadingBackdrop open={deleteMutation.loading}>Deleting</LoadingBackdrop>
      <LoadingBackdrop open={loadingSheet}>Generating PDF</LoadingBackdrop>
      <ConfirmDialog
        isOpen={Boolean(confirmDeleteAppraisal)}
        onClose={() => setConfirmDeleteAppraisal(null)}
        onConfirm={() =>
          deleteAppraisal({ variables: { id: confirmDeleteAppraisal.id } })
        }
        titleText="Delete Appraisal?"
        text={
          confirmDeleteAppraisal?.pitches.length > 0
            ? `WARNING: This appraisal is used on ${confirmDeleteAppraisal.pitches.length} pitches.`
            : ''
        }
        confirmText="Delete"
        abortText="Cancel"
      />
      <FilterDrawer
        anchor="left"
        open={filterDrawerOpen}
        onClose={() => setFilterDrawerOpen(false)}
        filters={filters}
        facetFilters={facetFilters}
        setFacetFilters={setFacetFilters}
        dateFilters={dateFilters}
        setDateFilters={setDateFilters}
      />
      <SortPopover
        anchorEl={popoverAnchor}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={Boolean(popoverAnchor)}
        onClose={() => setPopoverAnchor(null)}
        {...sortPopoverProps(VEHICLE_SORT_OPTIONS)}
      />
      <Box>
        <DealerPicker
          {...(mobile
            ? { style: { width: '100%', marginBottom: theme.spacing(1) } }
            : {})}
        />
      </Box>

      <ResponsiveComponent
        Mobile={MobileHeader}
        Desktop={DesktopHeader}
        onClickFilter={() => setFilterDrawerOpen(true)}
        categoryFiltersIndex={categoryFiltersIndex}
        setCategoryFiltersIndex={setCategoryFiltersIndex}
        facetFilters={facetFilters}
        setFacetFilters={setFacetFilters}
        dateFilters={dateFilters}
        setDateFilters={setDateFilters}
        search={search}
        MobileProps={{ sort, setSort }}
        onChangeSearch={setSearch}
        length={appraisals.length}
        total={total}
        categoryStats={stats}
      />
      <ResponsiveComponent Desktop={Table} Mobile={Fragment}>
        <ResponsiveComponent Desktop={TableHead}>
          <TableRow className={headerRow}>
            {/* Moving Actions to first column (change from mockup) for consistency with other modules */}
            <TableCell>Actions</TableCell>
            <TableCell>
              <TableSortLabel
                onClick={e => setPopoverAnchor(e.currentTarget)}
                active={VEHICLE_SORT_OPTIONS.map(x => x.sortOption).some(
                  isSortingBy,
                )}
                direction={sortDirection}
              >
                Vehicle{' '}
                {vehicleSortLabel && (
                  <Box marginLeft="5px" fontSize="9px">
                    ({vehicleSortLabel})
                  </Box>
                )}
              </TableSortLabel>
            </TableCell>
            <TableCell>Dealer</TableCell>
            {/* Redundant? */}
            <TableCell>Customer</TableCell>
            <TableCell>
              <TableSortLabel {...sortProps([VALUE_SORT])}>
                Appraised Value
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel {...sortProps([RECON_COST_SORT])}>
                Recon Cost
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel {...sortProps([CREATED_SORT])}>
                Created
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </ResponsiveComponent>
        <ResponsiveComponent Desktop={TableBody} Mobile={Fragment}>
          {appraisals.map(appraisal => (
            <ResponsiveComponent
              Desktop={ItemRow}
              Mobile={ItemCard}
              appraisal={appraisal}
              key={appraisal.id}
              onClickDetails={() => history.push(`/appraisals/${appraisal.id}`)}
              onClickDelete={() => setConfirmDeleteAppraisal(appraisal)}
              onClickPrint={() =>
                getAppraisalSheet({ variables: { id: appraisal.id } })
              }
            />
          ))}
        </ResponsiveComponent>
      </ResponsiveComponent>

      <Waypoint onEnter={handleMoreItems} />
      {loading && networkStatus === NetworkStatus.fetchMore ? (
        <Loading text="Loading more appraisals..." />
      ) : (
        nextPage && <Box height="6rem" />
      )}

      {/* space for FAB */}
      <ResponsiveComponent Mobile={Box} MobileProps={{ height: '60px' }} />
    </Box>
  );
};

export default AppraisalsList;
