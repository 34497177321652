import { isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import {
  Box,
  Container,
  Grid,
  IconButton,
  Paper,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import { useUserContext } from 'components/MaterialUI/UserContext';

import { getMediumDate } from 'utils';

import { NoteVisibility, Role, RoleGroup } from '../../../../../constants';
import ReconNoteModal from './ReconNoteModal';

const useStyles = makeStyles(theme => ({
  span: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    padding: '0 15px 15px 0',
  },
  headerSpan: {
    display: 'flex',
    justifyContent: 'start',
    flexWrap: 'wrap',
  },
  paper: {
    padding: '5px 15px',
    marginBottom: '10px',
  },
}));

const ReconNotes = ({ notes, dealer_id }) => {
  const theme = useTheme();
  const { headerSpan, paper } = useStyles();
  const { currentUser } = useUserContext();
  const [isOpen, setIsOpen] = useState(false);
  const { control, register, setValue } = useFormContext();
  register('recon.notes');

  const notesData = useWatch({
    control,
    name: 'recon.notes',
    defaultValue: notes,
  });
  const [notesList, setNotesList] = useState(notesData);

  useEffect(() => {
    // When a vehicle is saved, update the notes list
    if (notes.length > 0) {
      const _notes = notes.map(({ __typename, ...rest }) => rest);
      setValue('recon.notes', _notes, { shouldDirty: false });
      setNotesList(_notes);
    }
  }, [notes, setValue]);

  useEffect(() => {
    const _notesList = notesList.map(({ __typename, ...rest }) => rest);
    const _notesData = notesData.map(({ __typename, ...rest }) => rest);
    if (!isEqual(_notesList, _notesData)) {
      setValue('recon.notes', _notesList, { shouldDirty: true });
    }
  }, [notesList, notesData, setValue]);

  // Display notes based on their visibility
  const displayNotes = notes => {
    const includeVisibilities = [NoteVisibility.ORG_WIDE];
    if (currentUser.role === Role.ORGANIZATION_ADMIN) {
      return notes;
    }
    if (currentUser.dealer_ids.includes(dealer_id)) {
      includeVisibilities.push(NoteVisibility.STORE_ONLY);
    }
    if (RoleGroup.MANAGERS.includes(currentUser.role)) {
      includeVisibilities.push(NoteVisibility.MANAGERS_ONLY);
    }
    return notes.filter(note => includeVisibilities.includes(note.visibility));
  };

  return (
    <Container maxWidth="xl" style={{ paddingBottom: '10px' }}>
      <ReconNoteModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onSubmit={setNotesList}
      />
      <Box component="span" className={headerSpan}>
        <Typography style={{ padding: '15px 15px 15px 0' }} variant="body1">
          Notes
        </Typography>
        <IconButton size="small" onClick={() => setIsOpen(true)}>
          <AddIcon style={theme.actions.add} />
        </IconButton>
      </Box>
      {notesList.length === 0 && (
        <Typography variant="body2">No Notes.</Typography>
      )}
      {displayNotes(notesList).map((item, index) => (
        <Paper key={`${item.created_by}-${index}`} className={paper}>
          <Grid container spacing={1}>
            <Grid item xs={8}>
              <Typography variant="body2">{item.description}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Grid container>
                <Grid item xs={12}>
                  <b>Date Added:</b>{' '}
                  {getMediumDate(
                    item?.date_created ?? Date(),
                    currentUser?.goUserProfile?.settings?.timezone,
                  )}
                </Grid>
                <Grid item xs={12}>
                  <b>Added By:</b>{' '}
                  {item?.display_name ?? currentUser.display_name}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      ))}
    </Container>
  );
};

export default ReconNotes;
