export const INITIALIZE = 'app/INITIALIZE';

/**
 * Auth
 */

export const SET_TOKEN = 'app/SET_TOKEN';
export const SET_SENDING_REQUEST = 'app/SET_SENDING_REQUEST';
export const LOGIN_REQUEST = 'app/LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'app/LOGIN_SUCCESS';
export const LOGOUT = 'app/LOGOUT';

/**
 * UI
 */
export const SET_WINDOW_WIDTH = 'app/SET_WINDOW_WIDTH';
export const SET_APP_ERROR = 'app/SET_APP_ERROR';
export const CLEAR_APP_ERROR = 'app/CLEAR_APP_ERROR';

/**
 * Notification
 */
export const SET_NOTIFICATION = 'appraisals/SET_NOTIFICATION';
export const CLEAR_NOTIFICATION = 'appraisals/CLEAR_NOTIFICATION';
export const CREATE_NOTIFICATION = 'appraisals/CREATE_NOTIFICATION';

// Public Action Types
export default { LOGIN_SUCCESS, LOGOUT };
