import React from 'react';

/* external */
import { Controller, useFormContext } from 'react-hook-form';

/* Material UI */
import { InputAdornment, TextField } from '@mui/material';

/* For use with react-hook-form forms.  Will use control and errors from formContext
if available and not otherwise provided.

NOTE: MUST BE USED WITH FORMS USING RHF VERSION 7 AND UP!!!!!
 */
const TextFieldControl = ({
  control,
  defaultValue = '',
  name,
  helperText,
  variant = 'default',
  inputProps,
  InputProps,
  TextFieldProps,
  rules,
  type: inType,
  muiVariant = 'standard',
  disabled = false,
  ...rest
}) => {
  const inputPropsBase =
    variant === 'currency' || variant === 'percentRate' ? { step: '0.01' } : {};
  const InputPropsBase =
    variant === 'currency' || variant === 'currencyDollars'
      ? { startAdornment: <InputAdornment position="start">$</InputAdornment> }
      : variant === 'percentRate'
      ? { endAdornment: <InputAdornment position="end">%</InputAdornment> }
      : {};

  const type =
    variant === 'currency' ||
    variant === 'percentRate' ||
    variant === 'currencyDollars'
      ? 'number'
      : inType ?? undefined;

  const formContext = useFormContext();
  return (
    <Controller
      control={control ?? formContext.control}
      defaultValue={defaultValue}
      name={name}
      /* Merge incoming inputProps (and InputProps... yes they're different) 
      Incoming props take precedence. */
      render={({
        field: { ref, value, onChange, ...field },
        fieldState: { error },
      }) => (
        <>
          <TextField
            {...field}
            error={Boolean(error)}
            helperText={error?.message ?? helperText}
            inputProps={{ ...inputPropsBase, ...inputProps }}
            InputProps={{ ...InputPropsBase, ...InputProps }}
            onChange={e =>
              onChange(
                type === 'number' && e.target.value !== ''
                  ? parseFloat(e.target.value)
                  : e.target.value,
              )
            }
            type={type}
            value={value ?? ''}
            {...TextFieldProps}
            variant={muiVariant}
            {...rest}
          />
        </>
      )}
      rules={rules} // Rules are rules!
      disabled={disabled}
    />
  );
};

export default TextFieldControl;
