import { useQuery, useSubscription } from '@apollo/react-hooks';
// External
import gql from 'graphql-tag';
import React, { useEffect, useRef, useState } from 'react';

// Material UI
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Alert from '@mui/material/Alert';

import ErrorDisplay from 'components/MaterialUI/ErrorDisplay';
// Internal
import Loading from 'components/MaterialUI/Loading';
import View from 'components/View';

import ConversationFooter from './ConversationFooter';
import ConversationHeader from './ConversationHeader';
import ConversationMessageItem from './ConversationMessageItem';

const CONVERSATION_MESSAGES_SUBSCRIPTION = gql`
  subscription onConversationMessagesUpdates($conversationId: Int!) {
    conversationFeed(conversation_id: $conversationId) {
      id
      direction
      date_sent
      sent_to
      sent_from
      media {
        content_type
        url
      }
      body
      operator_username
      operator_user {
        display_name
        username
      }
      conversation {
        id
        customer {
          _id
          fullname
          identities {
            identity_type
            identity_value
          }
        }
        customer_name
        customer_phone
        operator_user {
          display_name
          username
        }
      }
    }
  }
`;

const CONVERSATION_MESSAGES_QUERY = gql`
  query conversationMessagesQuery($gatewayId: Int!, $conversationId: Int!) {
    sms {
      conversationMessages(
        gatewayId: $gatewayId
        conversationId: $conversationId
      ) {
        conversation {
          id
          customer {
            _id
            fullname
            identities {
              identity_type
              identity_value
            }
          }
          customer_name
          customer_phone
          customer_email
          operator_user {
            display_name
            username
          }
          do_not_contact
          skip_bulk_emails_until
          stars {
            id
            conversation_id
            username
          }
          archived
          has_unread_messages
          ...ConversationHeaderConversation
        }
        messages {
          id
          direction
          date_sent
          sent_to
          sent_from
          media {
            content_type
            url
          }
          body
          operator_username
          operator_user {
            display_name
            username
          }
          delivery_status
          error_msg
        }
      }
    }
  }
  ${ConversationHeader.fragments.conversation}
`;

const useStyles = makeStyles(theme => ({
  appBar: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    alignItems: 'center',
  },
}));

const Conversation = ({ conversationId, gatewayId }) => {
  const classes = useStyles();
  const endOfMessages = useRef();

  const [scrollOptions, setScrollOptions] = useState({});

  const { refetch, loading, data, error } = useQuery(
    CONVERSATION_MESSAGES_QUERY,
    {
      fetchPolicy: 'network-only',
      variables: {
        gatewayId,
        conversationId,
      },
    },
  );

  useSubscription(CONVERSATION_MESSAGES_SUBSCRIPTION, {
    variables: {
      conversationId,
    },
    fetchPolicy: 'network-only',
    shouldResubscribe: true,
    onSubscriptionData: () => refetch(), // hacky way of doing this for now.
  });

  // hacky way of doing THIS for now too.
  // (shouldn't need the timeout, but it doesn't seem to wait until the page fully loads otherwise)
  const scrollToBottom = () =>
    setTimeout(() => endOfMessages.current?.scrollIntoView(scrollOptions), 0);

  const conversationData = data?.sms.conversationMessages ?? {};

  const { conversation, messages = [] } = conversationData;
  const customer = conversation?.customer;

  const customerName = customer?.fullname || 'Unknown Customer';
  const customerPhone = conversation?.customer_phone;

  useEffect(() => {
    if (!loading) {
      scrollToBottom();
      // Instant scroll for initial loading of messages,
      // but smooth scrolling for new/appended messages.
      setScrollOptions({ behavior: 'smooth' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationData]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <ErrorDisplay error={error} action="Loading Conversation Details" />;
  }

  const renderHeader = () => {
    return (
      <Box
        className={classes.appBar}
        boxShadow={3}
        display="flex"
        flexDirection="column"
      >
        <Box p={'10px 13px'} width="100%">
          <ConversationHeader
            customerName={customerName}
            customerPhone={customerPhone}
            operatorName={
              conversation?.operator_user?.display_name || 'Unassigned'
            }
            gatewayId={gatewayId}
            refetch={refetch}
            conversation={conversation}
          />
        </Box>
        <Box width="100%">
          <Alert
            icon={false}
            severity="info"
            style={{ justifyContent: 'center', padding: 0 }}
          >
            Operator:{' '}
            {conversation?.operator_user?.display_name || 'Unassigned'}
          </Alert>
        </Box>
      </Box>
    );
  };

  const renderFooter = () => {
    return (
      <Box className={classes.appBar} boxShadow={3} p={'30px'} mt={'30px'}>
        <ConversationFooter
          gatewayId={gatewayId}
          customerPhone={customerPhone}
          doNotContact={conversation?.do_not_contact}
          refetch={refetch}
        />
      </Box>
    );
  };

  // Need to reverse the order of the messages array from newest first to oldest first
  const conversationMessages = [...messages].reverse();

  return (
    <View Header={renderHeader()} Footer={renderFooter()}>
      <Box display="flex" flexDirection="column" p="10px 30px">
        {conversationMessages.map(message => (
          <ConversationMessageItem
            message={message}
            key={message.id}
            desktopDisplay={false}
            customerName={customerName}
          />
        ))}
        <Box ref={endOfMessages}></Box>
      </Box>
    </View>
  );
};

export default Conversation;
