import React from 'react';

/* Material UI */
import { Accordion, Card, useMediaQuery } from '@mui/material';

/* A component that returns content either in a Card (meant for desktop) or an
   Accordion (meant for mobile), depending on the results of useMediaQuery
   */
const ResponsiveCard = ({ children, ...rest }) =>
  useMediaQuery(theme => theme.breakpoints.down('sm')) ? (
    <Accordion {...rest} defaultExpanded>
      {children}
    </Accordion>
  ) : (
    <Card {...rest}>{children}</Card>
  );

export default ResponsiveCard;
