import React, { useEffect } from 'react';

/* external */
import { useLocation } from 'react-router-dom';
import { useQuery, useSubscription } from '@apollo/react-hooks';
import gql from 'graphql-tag';

/* Material UI */
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

/* internal */
import { formatOdometer, usePersistedState } from 'utils';
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import DealerPicker from 'components/MaterialUI/DealerPicker';
import ErrorPage from 'components/MaterialUI/ErrorPage';
import ItemSummary from './ItemSummary';
import Loading from 'components/MaterialUI/Loading';
import MinimumBidCard from './MinimumBidCard';
import MyBidCard from './MyBidCard';
import ProxyBidCard from './ProxyBidCard';
import RemainingTimeCard from './RemainingTimeCard';
import VehicleAttachments from './VehicleAttachments';
import VehicleDetails from './VehicleDetails';
import VehicleNotes from './VehicleNotes';
import VehiclePhotos from './VehiclePhotos';
import WinningBidCard from './WinningBidCard';

const AUCTION_ITEM_QUERY = gql`
  query auctionItemQuery($id: Int!, $dealerId: Int!) {
    auctions {
      item(id: $id) {
        id
        dealerId
        deleted
        deletionReason
        endsAt
        fee
        feeDescription
        isActive
        isBlind
        isClosed
        isUpcoming
        vehicle {
          id
          year
          make
          model
          odometer
          trim
        }
        ...ItemSummaryItem
        ...MinimumBidCardItem
        ...MyBidCardItem
        ...ProxyBidCardItem
        ...RemainingTimeCardItem
        ...VehicleAttachmentsItem
        ...VehicleDetailsItem
        ...VehicleNotesItem
        ...VehiclePhotosItem
        ...WinningBidCardItem
      }
    }
  }
  ${ItemSummary.fragments.item}
  ${MinimumBidCard.fragments.item}
  ${MyBidCard.fragments.item}
  ${ProxyBidCard.fragments.item}
  ${RemainingTimeCard.fragments.item}
  ${VehicleAttachments.fragments.item}
  ${VehicleDetails.fragments.item}
  ${VehicleNotes.fragments.item}
  ${VehiclePhotos.fragments.item}
  ${WinningBidCard.fragments.item}
`;

const ITEM_FEED_SUBSCRIPTION = gql`
  subscription auctionItemFeed($id: Int!, $dealerId: Int!) {
    auctionItemUpdatedFeed(id: $id) {
      type
      auctionItem {
        id
        dealerId
        deleted
        deletionReason
        endsAt
        fee
        feeDescription
        isActive
        isBlind
        isClosed
        isUpcoming
        vehicle {
          id
          year
          make
          model
          odometer
          trim
        }
        ...ItemSummaryItem
        ...MinimumBidCardItem
        ...MyBidCardItem
        ...ProxyBidCardItem
        ...RemainingTimeCardItem
        ...VehicleAttachmentsItem
        ...VehicleDetailsItem
        ...VehicleNotesItem
        ...VehiclePhotosItem
        ...WinningBidCardItem
      }
    }
  }
  ${ItemSummary.fragments.item}
  ${MinimumBidCard.fragments.item}
  ${MyBidCard.fragments.item}
  ${ProxyBidCard.fragments.item}
  ${RemainingTimeCard.fragments.item}
  ${VehicleAttachments.fragments.item}
  ${VehicleDetails.fragments.item}
  ${VehicleNotes.fragments.item}
  ${VehiclePhotos.fragments.item}
  ${WinningBidCard.fragments.item}
`;

const ItemDetails = ({ id }) => {
  const location = useLocation();
  // eslint-disable-next-line no-unused-vars
  const [scrollRestoration, setScrollRestoration] = usePersistedState(
    'auctions.components.items.scrollRestoration',
    {},
  );
  const { dealerId } = useDealerContext();
  const { loading, data, error, refetch } = useQuery(AUCTION_ITEM_QUERY, {
    variables: { dealerId, id },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const scrollY = queryParams.get('scrollY');
    const index = queryParams.get('index');

    // Save the scroll restoration params in session
    if (scrollY && index) setScrollRestoration({ scrollY, index });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useSubscription(ITEM_FEED_SUBSCRIPTION, {
    variables: { id, dealerId },
  });

  const item = data?.auctions.item || {};

  const { deleted, deletionReason, vehicle } = item;
  const { make, model, odometer, trim, year } = vehicle || {};

  const isMine = dealerId === item.dealerId;

  if (loading) return <Loading />;
  if (error) return <ErrorPage error={error} />;
  if (deleted)
    return (
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        direction="column"
        spacing={0}
        style={{ minHeight: '100vh' }}
      >
        <Grid item>
          <Box>Item deleted: {deletionReason}</Box>
        </Grid>
      </Grid>
    );

  return (
    <Box m={1}>
      <Box marginBottom={1}>
        <DealerPicker />
      </Box>
      <Box>
        <Typography variant="h4">
          {year} {make} {model} {trim} (#{id})
        </Typography>
        <Typography variant="h6">{formatOdometer(odometer)}</Typography>
      </Box>
      <Grid container direction="column" spacing={3}>
        <Grid item container spacing={1} justifyContent="space-between">
          <Grid xs={12} sm={6} md={2} item>
            <RemainingTimeCard item={item} />
          </Grid>
          <Grid xs={12} sm={6} md={2} item>
            <MinimumBidCard item={item} />
          </Grid>
          <Grid xs={12} sm={6} md={2} item>
            <WinningBidCard item={item} />
          </Grid>
          <Grid xs={12} sm={6} md={2} item>
            {!isMine && <MyBidCard item={item} refetch={refetch} />}
          </Grid>
          <Grid xs={12} sm={6} md={2} item>
            {!isMine && <ProxyBidCard item={item} refetch={refetch} />}
          </Grid>
        </Grid>
        <Grid item container spacing={3}>
          <Grid container item direction="column" xs={12} md={6} spacing={3}>
            <Grid item>
              <ItemSummary item={item} />
            </Grid>
            <Grid item>
              <VehicleDetails item={item} />
            </Grid>
            <Grid item>
              <VehicleNotes item={item} />
            </Grid>
            <Grid item>
              <VehicleAttachments item={item} />
            </Grid>
          </Grid>
          <Grid container item direction="column" xs={12} md={6} spacing={3}>
            <Grid item xs={12}>
              <VehiclePhotos item={item} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ItemDetails;
