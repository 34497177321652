import React from 'react';

/* Material UI */
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

const GridItem = ({ label, value, ...rest }) => (
  <Grid
    item
    xs={6}
    sm={4}
    md={2}
    style={{ display: 'flex', flexDirection: 'column' }}
    {...rest}
  >
    <Box fontWeight="bold">{label}</Box>
    <Box style={{ wordWrap: 'break-word' }}>{value}</Box>
  </Grid>
);

export default GridItem;
