import React, { useState } from 'react';

/* external */
import { useQuery } from '@apollo/react-hooks';
import 'chart.js';
import gql from 'graphql-tag';
import MaterialTable from 'material-table';
import moment from 'moment-timezone';
import _ from 'lodash';

/* Material UI */
import Alert from '@mui/material/Alert';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import Paper from '@mui/material/Paper';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

/* internal */
import { currencyFormatter, getFullDateTime, withSuffix } from 'utils';
import { hideCost } from '../../utils';
import { useUserContext } from 'components/MaterialUI/UserContext';
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import { URL } from 'constants.js';

import VehicleHistoryGraph from './VehicleHistoryGraph';

const OPPORTUNITY_QUERY = gql`
  query OpportunitiesQuery(
    $vehicle_vin: String!
    $dealer_id: Int!
    $stock_number: String!
  ) {
    inventory {
      getVehicleOpportunityHistory(
        vehicle_vin: $vehicle_vin
        dealer_id: $dealer_id
        stock_number: $stock_number
      ) {
        vehicle_vin
        opportunity_created_at
        opportunity_id
        customer_id
        opportunity_updated_at
        status
      }
    }
  }
`;

const LEAD_QUERY = gql`
  query LeadsQuery(
    $vehicle_vin: String!
    $dealer_id: Int!
    $stock_number: String!
  ) {
    inventory {
      getVehicleLeadHistory(
        vehicle_vin: $vehicle_vin
        dealer_id: $dealer_id
        stock_number: $stock_number
      ) {
        vehicle_vin
        lead_created_at
        lead_id
      }
    }
  }
`;

const tableOptions = {
  pageSize: 10,
  pageSizeOptions: [10, 25, 50, 100],
  filtering: false,
};

const VehicleHistory = ({ vehicle: { history, vin, stock_number } }) => {
  const { me } = useUserContext();
  const { dealerId } = useDealerContext();
  const oppQuery = useQuery(OPPORTUNITY_QUERY, {
    variables: { vehicle_vin: vin, dealer_id: dealerId, stock_number },
    skip: !vin || !stock_number,
  });
  const leadQuery = useQuery(LEAD_QUERY, {
    variables: { vehicle_vin: vin, dealer_id: dealerId, stock_number },
    skip: !vin || !stock_number,
  });
  const opportunities = oppQuery?.data?.inventory?.getVehicleOpportunityHistory;
  const leads = leadQuery?.data?.inventory?.getVehicleLeadHistory;

  const timezone =
    me?.goUserProfile?.settings?.timezone ||
    Intl.DateTimeFormat().resolvedOptions().timeZone;

  const [toDateTime, setToDateTime] = useState(moment());
  const [fromDateTime, setFromDateTime] = useState(
    moment().subtract(90, 'days'),
  );

  const clearFromArrivedDate = () => {
    setFromDateTime(moment().subtract(90, 'days'));
  };

  const clearToArrivedDate = () => {
    setToDateTime(moment());
  };

  const columns = [
    {
      width: '1%',
      cellStyle: { whiteSpace: 'nowrap' },
      title: 'Action',
      field: 'action',
      filtering: false,
      render: rowData => {
        return (
          <>
            {rowData.url && (
              <Tooltip title={'Go To Opportunity'}>
                <IconButton
                  onClick={() => window.open(rowData.url, '_blank')}
                  size="large"
                >
                  <InfoIcon style={{ color: '#2196f3', fontSize: '22px' }} />
                </IconButton>
              </Tooltip>
            )}
          </>
        );
      },
    },
    {
      title: 'Date',
      field: 'date',
      filtering: false,
      render: rowData =>
        getFullDateTime(withSuffix(rowData.date, 'Z'), timezone),
      defaultSort: 'desc',
    },
    { title: 'User', field: 'username', filtering: false },
    { title: 'Description', field: 'description', filtering: false },
    { title: 'Old Value', field: 'old_value', filtering: false },
    { title: 'New Value', field: 'new_value', filtering: false },
    { title: 'Difference', field: 'difference', filtering: false },
  ];

  const opp_columns = [
    {
      width: '1%',
      cellStyle: { whiteSpace: 'nowrap' },
      title: 'Action',
      field: 'action',
      filtering: false,
      render: rowData => (
        <Tooltip title={'Go To Opportunity'}>
          <IconButton
            onClick={() => window.open(rowData.url, '_blank')}
            size="large"
          >
            <InfoIcon style={{ color: '#2196f3', fontSize: '22px' }} />
          </IconButton>
        </Tooltip>
      ),
    },
    {
      title: 'Date',
      field: 'date',
      filtering: false,
      render: rowData =>
        getFullDateTime(withSuffix(rowData.date, 'Z'), timezone),
      defaultSort: 'desc',
    },
    {
      title: 'Description',
      field: 'description',
      filtering: false,
    },
  ];

  const [alignment, setAlignment] = React.useState('all');
  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  let lead_values = [];
  var table_values = [];
  let pricing_values = [];
  const createLeadTableValues = lead => {
    let lead_value = _.cloneDeep(OPP_HISTORY_TABLE_ROW);

    lead_value.date = lead.lead_created_at;
    lead_value.description = 'Lead Created';
    lead_value.url = `${URL.mobile}/leads/${lead.lead_id}`;

    lead_values.push(lead_value);
    table_values.push(lead_value);
  };

  const OPP_HISTORY_TABLE_ROW = {
    date: '',
    description: '',
    url: '',
  };

  let opp_values = [];
  const createOppTableValues = opportunity => {
    let opp_value = _.cloneDeep(OPP_HISTORY_TABLE_ROW);

    opp_value.date =
      opportunity.opportunity_created_at < opportunity.opportunity_updated_at
        ? opportunity.opportunity_updated_at
        : opportunity.opportunity_created_at;
    opp_value.description = 'Opportunity Created';
    opp_value.url = `${URL.mobile}/opportunities/${opportunity.opportunity_id}/${opportunity.customer_id}/details`;

    opp_values.push(opp_value);
    table_values.push(opp_value);
  };

  const cleanOppData = opportunities => {
    for (let i = 0; i < opportunities?.length; i++) {
      if (
        moment(opportunities[i].opportunity_updated_at + 'Z').isBefore(
          toDateTime,
        ) &&
        moment(opportunities[i].opportunity_updated_at + 'Z').isAfter(
          fromDateTime,
        )
      ) {
        createOppTableValues(opportunities[i]);
      }
    }
  };

  const cleanLeadData = leads => {
    for (let i = 0; i < leads?.length; i++) {
      if (
        moment(leads[i].lead_created_at + 'Z').isBefore(toDateTime) &&
        moment(leads[i].lead_created_at + 'Z').isAfter(fromDateTime)
      ) {
        createLeadTableValues(leads[i]);
      }
    }
  };

  const VEHICLE_HISTORY_TABLE_ROW = {
    date_modified: '',
    username: '',
    description: '',
    old_value: '',
    new_value: '',
    difference: '',
  };

  const createTableValues = (price, delta, name, date, username) => {
    let old_value = 0;

    const description = `${name} ${
      delta < 0 ? 'decreased' : 'increased'
    } by $${currencyFormatter.format(Math.abs(delta)).toString()}`;

    delta < 0
      ? (old_value = price + Math.abs(delta))
      : (old_value = price - delta);

    let table_object = _.cloneDeep(VEHICLE_HISTORY_TABLE_ROW);
    table_object.date = date;
    table_object.username = username;
    table_object.old_value =
      '$' + currencyFormatter.format(old_value).toString();
    table_object.new_value = '$' + currencyFormatter.format(price).toString();
    table_object.difference = '$' + currencyFormatter.format(delta).toString();
    table_object.description = description;

    table_values.push(table_object);
    pricing_values.push(table_object);
  };

  const cleanPriceData = history => {
    for (let i = 0; i < history.length; i++) {
      let username =
        history[i].modified_by?.display_name || history[i].username;
      if (
        moment(history[i].date_modified + 'Z').isBefore(toDateTime) &&
        moment(history[i].date_modified + 'Z').isAfter(fromDateTime)
      ) {
        if (
          history[i].cost_delta &&
          history[i].cost_delta !== 0 &&
          !hideCost(me.role)
        ) {
          createTableValues(
            history[i].cost,
            history[i].cost_delta,
            'Cost',
            history[i].date_modified,
            username,
          );
        }
        if (
          history[i].regular_price_delta &&
          history[i].regular_price_delta !== 0
        ) {
          createTableValues(
            history[i].regular_price,
            history[i].regular_price_delta,
            'Price',
            history[i].date_modified,
            username,
          );
        }
        if (
          history[i].special_price_delta &&
          history[i].special_price_delta !== 0
        ) {
          createTableValues(
            history[i].special_price,
            history[i].special_price_delta,
            'Special price',
            history[i].date_modified,
            username,
          );
        }
        if (history[i].msrp_delta && history[i].msrp_delta !== 0) {
          createTableValues(
            history[i].msrp,
            history[i].msrp_delta,
            'Msrp',
            history[i].date_modified,
            username,
          );
        }
      }
    }
  };

  cleanPriceData(history);
  cleanOppData(opportunities);
  cleanLeadData(leads);

  return (
    <Paper>
      <Container maxWidth="xl">
        <Typography style={{ padding: '15px 15px 15px 0' }} variant="h6">
          Vehicle History
        </Typography>
        {table_values.length > 0 && (
          <>
            <VehicleHistoryGraph
              opportunities={opportunities}
              leads={leads}
              history={history}
              toDateTime={toDateTime}
              setToDateTime={setToDateTime}
              fromDateTime={fromDateTime}
              setFromDateTime={setFromDateTime}
              clearFromArrivedDate={clearFromArrivedDate}
              clearToArrivedDate={clearToArrivedDate}
            />
            <ToggleButtonGroup
              value={alignment}
              exclusive
              onChange={handleAlignment}
              style={{ padding: '1rem 0 1rem 0' }}
            >
              <ToggleButton value="all">All</ToggleButton>
              <ToggleButton value="pricing">Pricing</ToggleButton>
              <ToggleButton value="opps">Opportunities</ToggleButton>
              <ToggleButton value="leads">Leads</ToggleButton>
            </ToggleButtonGroup>
            <div style={{ paddingBottom: '2rem' }}>
              {alignment === 'all' && (
                <MaterialTable
                  title={'All History Changes'}
                  data={table_values}
                  columns={columns}
                  options={tableOptions}
                />
              )}
              {alignment === 'opps' && (
                <MaterialTable
                  title={'Opportunity Changes'}
                  data={opp_values}
                  columns={opp_columns}
                  options={tableOptions}
                />
              )}
              {alignment === 'pricing' && (
                <MaterialTable
                  title={'Pricing Changes'}
                  data={pricing_values}
                  columns={columns}
                  options={tableOptions}
                />
              )}
              {alignment === 'leads' && (
                <MaterialTable
                  title={'Lead Changes'}
                  data={lead_values}
                  columns={opp_columns}
                  options={tableOptions}
                />
              )}
            </div>
          </>
        )}
        {table_values.length === 0 && (
          <div style={{ paddingBottom: '1rem' }}>
            <Alert severity="warning">
              This vehicle has no history. Make a change to see the history of
              the vehicle.
            </Alert>
          </div>
        )}
      </Container>
    </Paper>
  );
};

VehicleHistory.fragments = {
  vehicle: gql`
    fragment VehicleHistoryVehicle on GreaseInventoryVehicle {
      history {
        cost
        cost_delta
        date_modified
        id
        list_price
        list_price_delta
        modified_by {
          display_name
        }
        msrp
        msrp_delta
        regular_price
        regular_price_delta
        special_price
        special_price_delta
        special_price_expires
        username
      }
    }
  `,
};
export default VehicleHistory;
