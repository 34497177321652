import gql from 'graphql-tag';

const SINGLE_PERMISSION_FRAGMENT = gql`
  fragment SinglePermissionGatewayData on GatewayPermissions {
    id
    dealerId
    gatewayId
    permissionType
    role
  }
`;

export const UPDATE_PERMISSIONS = gql`
  mutation updateGatewayPermissions(
    $gatewayId: Int!
    $data: GatewayPermissionsInput!
  ) {
    sms {
      updateGatewayPermissions(gatewayId: $gatewayId, data: $data) {
        ...SinglePermissionGatewayData
      }
    }
  }
  ${SINGLE_PERMISSION_FRAGMENT}
`;
