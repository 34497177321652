import React, { useEffect } from 'react';

/* external */
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';

/* Material UI */
import Button from '@mui/material/Button';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material';

/* internal */
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';
import { refCode } from 'utils';

const SHARE_GO_CARD = gql`
  mutation shareGoCard($crm_id: ID!, $gocard_id: Int!, $email: String!) {
    shareGoCard(crm_id: $crm_id, gocard_id: $gocard_id, email: $email) {
      email
    }
  }
`;

const useStyles = makeStyles(theme => ({
  text: {
    fontSize: 15,
  },
}));

const ShareGoCardModal = ({ gocard, customerId, isOpen, onClose }) => {
  const theme = useTheme();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, register, reset } = useForm({
    defaultValues: {
      email: '',
    },
  });

  useEffect(() => {
    if (isOpen) {
      reset({ email: '' });
    }
  }, [reset, isOpen]);

  const [shareGoCard, { loading }] = useMutation(SHARE_GO_CARD, {
    onCompleted: () => {
      enqueueSnackbar(`Successfully shared Go Card`, {
        variant: 'success',
      });
    },
    onError: () =>
      enqueueSnackbar(`Error in sharing the Go Card`, {
        variant: 'error',
      }),
  });

  const onSubmit = ({ email }) => {
    shareGoCard({
      variables: {
        crm_id: customerId,
        gocard_id: Number(gocard.cardId),
        email,
      },
    });
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Share Go Card</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent style={{ overflowY: 'unset' }}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
            style={{ marginLeft: '8px' }}
          >
            <Grid item>
              <CreditCardIcon style={{ marginTop: '5px' }} />
            </Grid>
            <Grid item style={{ fontWeight: 'bold' }} className={classes.text}>
              ${gocard?.cashBalance.toFixed(2) || 0}
            </Grid>
            <Grid item style={{ fontWeight: 'bold' }} className={classes.text}>
              {`${gocard?.cardNumber}`}
            </Grid>
            <Grid
              item
              style={{ fontWeight: 'bold', color: '#999999' }}
              className={classes.text}
            >
              {`|`}
            </Grid>
            <Grid
              item
              style={{ fontWeight: 'bold', color: '#999999' }}
              className={classes.text}
            >
              {`${refCode(gocard?.cardId)}`}
            </Grid>
          </Grid>
          <TextField
            label="Email"
            placeholder="Email..."
            variant="filled"
            {...register('email')}
          />
        </DialogContent>

        <DialogActions>
          <Button style={theme.actions.close} onClick={onClose} variant="text">
            Cancel
          </Button>
          <Button
            style={theme.actions.confirm}
            type="submit"
            variant="contained"
            color="primary"
          >
            Share
          </Button>
        </DialogActions>
      </form>
      <LoadingBackdrop open={loading}>Sharing Go Card...</LoadingBackdrop>
    </Dialog>
  );
};

ShareGoCardModal.fragments = {
  gocard: gql`
    fragment ShareGoCardModalGoCard on GoCardAccountV2 {
      customer {
        _id
      }
      cardId
      cashBalance
      cardNumber
    }
  `,
};

export default ShareGoCardModal;
