import { isArray, isEmpty, isObject, merge, reduce } from 'lodash';

// Copied from here (https://github.com/lodash/lodash/issues/2240),
// but modified so that it doesn't flatten an array
// if it contains a string in the first element
const _flattenKeys = (obj, path = []) =>
  !isObject(obj) || (isArray(obj) && typeof obj[0] === 'string')
    ? { [path.join('.')]: obj }
    : reduce(
        obj,
        (cum, next, key) => merge(cum, _flattenKeys(next, [...path, key])),
        {},
      );

/* For use with graphql mutations where the connector does NOT modify the response */
/* TODO: proper documentation */
export const parseErrors = ({ graphQLErrors, networkError, message }) => {
  let returnErrors = {};
  if (!isEmpty(graphQLErrors))
    graphQLErrors.forEach(({ message, location, path, extensions }) => {
      if (extensions.json)
        returnErrors = Object.assign({}, returnErrors, extensions.json);
      else if (extensions.response?.body?.errors?.json) {
        returnErrors = Object.assign(
          {},
          returnErrors,
          extensions.response.body.errors.json,
        );
        returnErrors = _flattenKeys(returnErrors);
      } else if (extensions.response?.body?.message) {
        returnErrors.error = [extensions.response.body.message];
      } else if (message)
        returnErrors.error = [
          message + ' ' + extensions?.response?.body?.errors?.join(',') || '',
        ];
    });
  else if (!isEmpty(networkError))
    returnErrors.error = networkError.result?.errors.map(x => x.message);
  else if (!isEmpty(message)) returnErrors.error = [message];
  else returnErrors.error = ['An unknown error occured.'];
  return returnErrors;
};

export const errorHandler =
  (enqueueSnackbar, setError, options = {}) =>
  err => {
    const { error, ...rest } = parseErrors(err);
    if (options.snackAll) {
      // send all errors to snackbar, even "form" type errors
      // TODO: stringifying the "rest" is not exactly the best, but it'll do for now
      enqueueSnackbar(error?.join('\n') ?? '' + JSON.stringify(rest), {
        variant: 'error',
      });
    } else {
      if (error)
        enqueueSnackbar(error.join('\n'), {
          variant: 'error',
        });
    }
    if (setError && !options.snackAll) {
      Object.entries(rest).forEach(([k, v]) =>
        setError(k, { message: v, type: 'APIValidation' }),
      );
      if (error) setError('error', { message: error.join(',') });
    }
    if (error) setError('error', { message: error.join(',') });
  };
