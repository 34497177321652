import gql from 'graphql-tag';

import { Role } from 'constants.js';

const CHANNEL_SUMMARY_COUNT_FRAGMENT = gql`
  fragment Counts on ChannelSummaryCounts {
    sold
    total
    title
  }
`;

export const INVENTORY_DASHBOARD_QUERY = gql`
  query DashboardQuery($dealerId: JSON) {
    inventory {
      newVehicles: getVehicleFacets(
        filters: [
          { model: "Vehicle", field: "dealer_id", op: "eq", value: $dealerId }
          { model: "Vehicle", field: "stock_type", op: "eq", value: "NEW" }
          { model: "StockStatus", field: "name", op: "eq", value: "in stock" }
        ]
        facets: [
          { model: "StockStatus", field: "name" }
          { model: "Vehicle", field: "is_missing_description" }
          { model: "Vehicle", field: "is_missing_photos" }
          { model: "Vehicle", field: "days_in_stock_category" }
          { model: "Vehicle", field: "no_price_changes_at_7_days" }
          { model: "Vehicle", field: "not_enough_photos" }
          { model: "Vehicle", field: "days_in_stock" }
          { model: "Vehicle", field: "last_price_change" }
        ]
      ) {
        data {
          value
          count
        }
        field
        model
      }
      usedVehicles: getVehicleFacets(
        filters: [
          { model: "Vehicle", field: "dealer_id", op: "eq", value: $dealerId }
          { model: "Vehicle", field: "stock_type", op: "eq", value: "USED" }
          { model: "StockStatus", field: "name", op: "eq", value: "in stock" }
        ]
        facets: [
          { model: "StockStatus", field: "name" }
          { model: "Vehicle", field: "is_missing_description" }
          { model: "Vehicle", field: "is_missing_photos" }
          { model: "Vehicle", field: "days_in_stock_category" }
          { model: "Vehicle", field: "no_price_changes_at_7_days" }
          { model: "Vehicle", field: "not_enough_photos" }
          { model: "Vehicle", field: "days_in_stock" }
          { model: "Vehicle", field: "last_price_change" }
        ]
      ) {
        data {
          value
          count
        }
        field
        model
      }
    }
  }
`;

export const MANAGER_DASHBOARD_QUERY = gql`
  query DashboardStatistics(
    $dealerId: Int!
    $date_from: Date!
    $date_to: Date!
  ) {
    getManagerDashboardStatistics(
      dealerId: $dealerId
      date_from: $date_from
      date_to: $date_to
    ) {
      new_opps_today
      new_opps_mtd
      new_opps_date_range
      unassigned_opps
      lost_opps
      open_opps
      not_updated_opps
      opp_table {
        _id
        year_to_date
        month_to_date
        date_range
        today
        user {
          display_name
          profile {
            first_name
            last_name
          }
        }
      }
      booked_opps
      pending_opps
      sold_opps
      deallog_table {
        _id
        pending
        booked
        sold
        user {
          display_name
          profile {
            first_name
            last_name
          }
        }
      }
      appointment_counts {
        _id
        last7days
        last30days
        today
        upcoming
      }
      appt_table {
        _id
        next_7_days
        tomorrow
        today
        date_range
        user {
          display_name
          profile {
            first_name
            last_name
          }
        }
      }
      todays_appointments {
        _id
        start_date
        customer {
          fullname
          primary_phone
        }
        attendees {
          display_name
        }
        status
        is_confirmed
        customer_id
      }
      leads_needing_attention
      potential_gross
      complete_gross
      channel_summary {
        inbound {
          chat {
            ...Counts
          }
          phone {
            ...Counts
          }
          service {
            ...Counts
          }
          sms {
            ...Counts
          }
          walk {
            ...Counts
          }
          web {
            ...Counts
          }
          total {
            ...Counts
          }
        }
        outbound {
          phone {
            ...Counts
          }
          sms {
            ...Counts
          }
          total {
            ...Counts
          }
        }
        system {
          system {
            ...Counts
          }
        }
        other {
          other {
            ...Counts
          }
        }
      }
      source_summary
    }
  }
  ${CHANNEL_SUMMARY_COUNT_FRAGMENT}
`;

export const DASHBOARD_QUERY = gql`
  query DashboardStatistics(
    $dealerId: Int!
    $assignee: String
    $date_from: Date!
    $date_to: Date!
  ) {
    getSalesDashboardStatistics(
      dealerId: $dealerId
      assignee: $assignee
      date_from: $date_from
      date_to: $date_to
    ) {
      open_opportunity_facets {
        status_id
        total
      }
      lost_opportunity_facets {
        status_id
        total
      }
      top_ten_open_opps {
        _id
        status
        status_display
        created
        customer_name
        customer_id
        dms_deal {
          deal_number
        }
        stock_type
      }
      top_ten_posted_opps {
        _id
        status
        status_display
        created
        customer_name
        customer_id
        dms_deal {
          deal_number
        }
        stock_type
      }
      top_ten_pending_tasks {
        _id
        customer {
          _id
          first_name
          last_name
          primary_phone
          primary_email
          fullname
        }
        opportunity_id
        created
        description
        due_date
        is_due_today
        is_overdue
        opportunity_id
        status_name
        type
        assignee {
          display_name
          role
        }
        permissions {
          can_update
        }
      }
      pending_tasks_count {
        status {
          _id
          total
        }
        type {
          _id
          total
        }
        assignee {
          _id
          total
        }
      }
      appointment_counts {
        _id
        last7days
        last30days
        today
        upcoming
        date_range
      }
      todays_appointments {
        _id
        start_date
        customer {
          fullname
          primary_phone
        }
        attendees {
          display_name
        }
      }
    }
  }
`;

export const UPDATE_TASK_QUERY = gql`
  mutation UpdateTask($_id: ID!, $input: UpdateTaskInput!) {
    updateTask(id: $_id, input: $input) {
      _id
      due_date
      status
    }
  }
`;

export const GET_DASHBOARD_USERS_QUERY = gql`
query getUsers($dealerId: Int!) {
  users(
    dealer_ids: [$dealerId]
    status: active
    roles: [
      "${Role.SALES_REP}"
      "${Role.INTERNET_SALES_REP}"
      "${Role.SALES_MANAGER}"
    ]
  ) {
    display_name
    username
    role
  }
}
`;
