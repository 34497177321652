import cloudinary from 'cloudinary-core';

// missing PAVE chip
export const COLOUR_MATCH = {
  BIDDING: {
    backgroundColor: '#b042ff',
    color: 'white',
  },
  ACCEPTED: {
    backgroundColor: '#2196f3',
    color: 'white',
  },
  DECLINED: {
    backgroundColor: '#b94a48',
    color: 'white',
  },
  NEW: {
    backgroundColor: 'orange',
    color: 'white',
  },
  PURCHASED: {
    backgroundColor: 'green',
    color: 'white',
  },
  PENDING: {
    backgroundColor: 'navy',
    color: 'white',
  },
  UNKNOWN: {
    backgroundColor: 'grey',
    color: 'white',
  },
};

export const STATUS = {
  BIDDING: 'BIDDING',
  ACCEPTED: 'ACCEPTED',
  DECLINED: 'DECLINED',
  NEW: 'NEW',
  PURCHASED: 'PURCHASED',
  PENDING: 'PENDING',
  UNKNOWN: 'UNKNOWN',
};

const cloudinaryConfig = {
  api_key: '589284895795542',
  api_secret: 's610MHjIAGKzIZic8ZwARhhDF6I',
  cloud_name: 'vehicle-images',
};

export const cloudinaryCore = new cloudinary.Cloudinary(cloudinaryConfig);
