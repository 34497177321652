import React, { useRef, useState } from 'react';
import { withStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import { cloneDeep } from 'lodash';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import InspectionCheckboxes from '../InspectionCheckboxes';

const RVRDPDIForm = props => {
  const { enqueueSnackbar } = useSnackbar();

  // RV Red Deer PDI form fields
  const sealant = {
    rv1: 'MOULDINGS',
    rv2: 'WINDOWS',
    rv3: 'COMPARTMENTS',
    rv4: 'ENTRY DOORS',
    rv5: 'SIDE WALL VENTS',
    rv6: 'APPLIANCE VENTS',
    rv7: 'CLEARANCE LIGHTS',
    rv8: 'TAILLIGHTS',
    rv9: 'SLIDE FACIAS',
    rv10: 'FENDERSKIRTS',
    rv11: 'OUTSIDE HOOKUPS',
    rv12: 'OUTSIDE SPEAKERS',
    rv13: 'AWNING ARMS',
  };
  const exterior = {
    rv14: 'ENTRY DOOR',
    rv15: 'ENTRY DOOR SCREEN',
    rv16: 'ENTRY DOOR LOCKS',
    rv17: 'ENTRY DOOR KEYS (TWO SETS)',
    rv18: 'ENTRY STEP(S)',
    rv19: 'COMPARTMENTS (SEALS/DOOR/FLOOR)',
    rv20: 'COMPARTMENT LOCKS (KEYS)',
    rv21: 'TOY HAULER KEY(S)',
    rv22: 'LADDER',
    rv23: 'DECALS (ASSESS 2018 OR NEWER)',
    rv24: 'PAINT (ASSESS 2018 OR NEWER)',
    rv25: 'CHECKER PLATE/ROCK GUARD',
    rv26: 'AWNING 1',
    rv27: 'AWNING 2',
    rv28: 'AWNING 3',
    rv29: 'MANUAL CRANKS',
    rv30: 'SLIDE TOPPERS',
    rv31: 'UNDERBELLY/DARCO/COROPLAST',
  };
  const propane = {
    rv32: 'VISUAL INSPECTION, LINES',
    rv33: 'LEAK TEST 10 MIN',
    rv34: 'WORKING PRESSURE',
    rv35: 'LOCK UP PRESSURE',
    rv36: 'LP TANK CONDITIONS',
    rv37: 'ADVENTURE CLUB STICKER',
  };
  const electrical = {
    rv38: '110V POWER/OUTLETS/GFI’S',
    rv39: '12V POWER (USB/CHARING PORT)',
    rv40: 'CONVERTER OUTPUT/CHARGING',
    rv41: 'BATTERY DISCONNECT FUNCTION',
    rv42: 'DRAW TEST ON SYSTEM >0.5',
    rv43: 'BATTERY LOAD TEST',
    rv44: 'BATTERY STRUCTURE',
    rv45: '7 WAY CORD',
    rv46: 'POWER CORD CONDITION',
    rv47: 'RUNNING LIGHTS',
    rv48: 'SIGNAL/BRAKE LIGHTS',
    rv49: 'BACK UP LIGHTS',
    rv50: 'DOCKING LIGHTS',
    rv51: 'PORCH/FLOOD LIGHTS',
    rv52: 'INTERIOR LIGHTS',
    rv53: 'VENT FANS',
    rv54: 'CEILING FANS',
    rv55: 'SOLAR PANELS(S) (IF APPLICABLE)',
    rv56: 'DISTRIBUTION PANEL',
  };
  const slides = {
    rv57: 'IN/OUT ADJUSTMENT',
    rv58: 'ALIGNMENT/SQUARE IN HOLE',
    rv59: 'WIPER SEALS (IN AND OUT)',
    rv60: 'CABLE TENSION 1” TRAVEL',
    rv61: 'CABLE CONDITION',
    rv62: 'SLIDE CABLE GUIDES/WIRING/LPG',
    rv63: 'SMOOTH TRAVEL IN/OUT (NOISE/SKIPPING)',
    rv64: 'HYDRAULIC FLUID',
    rv65: 'HYDRAULIC RAM',
    rv66: 'SLIDE FLOOR CONDITION',
    rv67: 'MANUAL CRANKS',
    rv68: 'ROLLERS',
  };
  const appliances = {
    rv69: 'ROOF AIR CONDITIONER',
    rv70: 'FRIDGE RUNNING ON 12V/LP/110V',
    rv71: 'ICE MAKER',
    rv72: 'EXTERIOR MINI FRIDGE',
    rv73: 'EXTERIOR BBQ',
    rv74: 'EXTERIOR STOVE',
    rv75: 'RANGE HOOD FAN & LIGHT',
    rv76: 'STOVE BURNERS IGNITION/FLAME',
    rv77: 'OVEN FUNCTION PILOT/FLAME',
    rv78: 'MICROWAVE FUNCTION',
    rv79: 'WATER HEATER WORKING ON LP/110V',
    rv80: 'FURNACE /CYCLES',
    rv81: 'FIREPLACE FUNCTION AND REMOTE',
    rv82: 'DISHWASHER (IF APPLICABLE)',
    rv83: 'WASHER/DRYER (IF APPLICABLE)',
    rv84: 'GENERATOR OIL LEVEL',
    rv85: 'GENERATOR STARTS INSIDE/OUTSIDE',
    rv86: 'GENERATOR LOAD TEST & HOUR METER',
  };
  const tentTrailerSpecific = {
    rv87: 'CRANK MECHANISM',
    rv88: 'CABLE CONDITION',
    rv89: 'LIFT ARMS',
    rv90: 'CANVAS CONDITION',
    rv91: 'ZIPPERS',
    rv92: 'BED SLIDES',
    rv93: 'BED SUPPORT',
    rv94: 'DOOR RETAINERS',
    rv95: 'EXTENSION HARDWARE',
    rv96: 'CUT OFF SWITCH',
    rv97: 'POP OUT LATCHES',
  };
  const motorHomeSpecific = {
    rv98: 'OIL LEVEL',
    rv99: 'DEF FLUID LEVEL',
    rv100: 'WASHER FLUID LEVEL',
    rv101: 'WIPER BLADES',
    rv102: 'CHASSIS A/C',
    rv103: 'HEAT',
    rv104: 'DASH FUNCTIONS',
  };
  const roof = {
    rv105: 'SEALANT FIXTURES',
    rv106: 'ROOF MOULDINGS',
    rv107: 'A/C SHROUD',
    rv108: 'MATERIAL',
    rv109: 'DECKING',
    rv110: 'FIXTURES',
    rv111: 'TOP OF FRONT CAP',
  };
  const chassis = {
    rv112: 'TIRES = or >2/32" FAIL',
    rv113: 'SPARE TIRE = or >2/32" FAIL',
    rv114: 'BRAKE FUNCTION THROUGH PLUG',
    rv115: 'BRAKE FUNCTION BREAKAWAY CABLE',
    rv116: 'BREAKAWAY CABLE CONDITION',
    rv117: 'BEARING REPACK',
    rv118: 'BRAKE MAGENTS',
    rv119: 'BRAKE SHOES/PADS >3MM FAIL',
    rv120: 'BACKING PLATES',
    rv121: 'DRUM CONDITION',
    rv122: 'SPRINGS',
    rv123: 'SHACKLE BOLTS/PINS/BUSHINGS',
    rv124: 'SUSPENSION HANGERS',
    rv125: 'AXLE (S)',
    rv126: 'WHEEL NUT TORQ',
    rv127: 'COUPLER/KING PIN',
    rv128: 'SAFETY CHAINS',
    rv129: 'TONUE JACKS/LANDING GEAR',
    rv130: 'ELECTRIC/MANUAL STAB JACKS',
    rv131: 'MANUAL CRANKS',
    rv132: 'HYDRAULICS',
    rv133: 'AUTO LEVEL CHECK',
    rv134: 'BUMPER',
  };
  const interior = {
    rv135: 'WINDOW FUNCTIONS (OPEN/CLOSE/LOCK)',
    rv136: 'EMERGENCY WINDOW(S)',
    rv137: 'SCREENS',
    rv138: 'BLINDS/SHADES/CURTAINS',
    rv139: 'BUNK PRIVACY CURTAINS',
    rv140: 'CARPET AND LINO CONDITION',
    rv141: 'FURNITURE CONDITION',
    rv142: 'SOFA RECLINER FUNCTION',
    rv143: 'DINETTE TABLE/BED FUNCTION',
    rv144: 'AIR MATTRESS FUNCTION (LEAKS)',
    rv145: 'BED STRUTS',
    rv146: 'MATTRESS (BUNK, BED, COUCH BED)',
    rv147: 'COUNTER TOPS',
    rv148: 'WOODWORK AND TRIM',
    rv149: 'CEILING AND TRIM CONDITION',
    rv150: 'ALL WALL TRIMS AND MOLDINGS',
    rv151: 'CEILING/WALL CONDITION',
    rv152: 'CABINET DOORS AND CATCHES',
    rv153: 'DOORS (BEDROOM, BATHROOM)',
    rv154: 'SLIDING DOORS (CLOSETS, POCKET DOORS)',
    rv155: 'DRAWERS AND GUIDES',
    rv156: 'MIRRORS/GLASS CONDITIONS',
    rv157: 'TOY HAULER DOOR',
    rv158: 'TOY HAULER CABLES',
    rv159: 'TOU HAULER RAMPS(DECKING/STAIRS)',
    rv160: 'TOY HAULER BED LIFTS/PINS/LEGS',
    rv161: 'SMOKE DETECTOR (up to date)',
    rv162: 'LP DETECTOR (up to date)',
    rv163: 'CO DETECTOR (up to date)',
    rv164: 'FIRE EXTINGUISHER (up to date)',
  };
  const entertainment = {
    rv165: 'TV’S FUNCTION (IF APPLICABLE)',
    rv166: 'DVD PLAYER/CD PLAYER',
    rv167: 'USB',
    rv168: 'SOUND BAR/RADIO',
    rv169: 'SPEAKER SYSTEM',
    rv170: 'SAT SYSTEM RECEPTION/FUNCTION',
    rv171: 'ANTENNA RECEPTION/FUNCTION',
    rv172: 'REMOTES',
  };
  const waterSystem = {
    rv173: 'SEWER FLUSHER FUNCTION',
    rv174: 'WATER FILTER HOUSING',
    rv175: 'ANODE ROD CONDITION',
    rv176: 'DRAIN PLUGS',
    rv177: 'CITY WATER INLET',
    rv178: 'FILL FRESH WATER TANK',
    rv179: 'FILL HOLDING TANKS',
    rv180: 'WATER HEATER BYPASS',
    rv181: 'WATER HEATER',
    rv182: 'WATER PUMP FUNCTION/PRESSURE',
    rv183: 'GATE VALVES',
    rv184: 'SEWER CAPS',
    rv185: 'MONITOR PANEL FUNCTION',
    rv186: 'ALL TAPS FUNCTION, NO LEAKS',
    rv187: 'TOILET FUNCTION',
    rv188: 'P-TRAPS INSPECTED (FAUCETS, SHWR)',
    rv189: 'OUTSIDE FIXTURES (SPRAY PORTS, SHWR, FAUCET)',
  };
  const misc = {
    rv190: 'WINTERIZE COMPLETED',
    rv191: 'PARTS REQUEST SUBMITTED',
    rv192: 'VIDEO WALKAROUND BY TECH COMPLETE',
    rv193: 'PDI FORM SCANNED INTO SHARED DRIVE',
    rv194: 'SMARTSHEET STATUS UPDATED',
    rv195: 'MOTORHOME SPECIFIC',
    rv196: 'SEAT BELT FUNCTIONS',
    rv197: 'FRONT SEAT WINDOW OPERATIONS',
    rv198: 'ENGINE LIGHTS/CONSOLE',
    rv199: 'SD CARD/NAVIGATION',
    rv200: 'WINDSHIELD',
    rv201: 'AMVIC INSPECTION COMPLETED',
    rv202: 'OUT OF PROVICE INSPECTION (IF APPLICABLE)',
  };

  let sealantObj = cloneDeep(sealant);
  let exteriorObj = cloneDeep(exterior);
  let propaneObj = cloneDeep(propane);
  let electricalObj = cloneDeep(electrical);
  let slidesObj = cloneDeep(slides);
  let appliancesObj = cloneDeep(appliances);
  let tentTrailerSpecificObj = cloneDeep(tentTrailerSpecific);
  let motorHomeSpecificObj = cloneDeep(motorHomeSpecific);
  let roofObj = cloneDeep(roof);
  let chassisObj = cloneDeep(chassis);
  let interiorObj = cloneDeep(interior);
  let entertainmentObj = cloneDeep(entertainment);
  let waterSystemObj = cloneDeep(waterSystem);
  let miscObj = cloneDeep(misc);

  Object.entries(sealantObj).map(([name]) => (sealantObj[name] = 'P'));
  Object.entries(exteriorObj).map(([name]) => (exteriorObj[name] = 'P'));
  Object.entries(propaneObj).map(([name]) => (propaneObj[name] = 'P'));
  Object.entries(electricalObj).map(([name]) => (electricalObj[name] = 'P'));
  Object.entries(slidesObj).map(([name]) => (slidesObj[name] = 'P'));
  Object.entries(appliancesObj).map(([name]) => (appliancesObj[name] = 'P'));
  Object.entries(tentTrailerSpecificObj).map(
    ([name]) => (tentTrailerSpecificObj[name] = 'P'),
  );
  Object.entries(motorHomeSpecificObj).map(
    ([name]) => (motorHomeSpecificObj[name] = 'P'),
  );
  Object.entries(roofObj).map(([name]) => (roofObj[name] = 'P'));
  Object.entries(chassisObj).map(([name]) => (chassisObj[name] = 'P'));
  Object.entries(interiorObj).map(([name]) => (interiorObj[name] = 'P'));
  Object.entries(entertainmentObj).map(
    ([name]) => (entertainmentObj[name] = 'P'),
  );
  Object.entries(waterSystemObj).map(([name]) => (waterSystemObj[name] = 'P'));
  Object.entries(miscObj).map(([name]) => (miscObj[name] = 'Y'));

  const [sealantValues, setSealantValues] = useState(sealantObj);
  const [exteriorValues, setExteriorValues] = useState(exteriorObj);
  const [propaneValues, setPropaneValues] = useState(propaneObj);
  const [electricalValues, setElectricalValues] = useState(electricalObj);
  const [slidesValues, setSlidesValues] = useState(slidesObj);
  const [appliancesValues, setAppliancesValues] = useState(appliancesObj);
  const [tentTrailerSpecificValues, setTentTrailerSpecificValues] = useState(
    tentTrailerSpecificObj,
  );
  const [motorHomeSpecificValues, setMotorHomeSpecificValues] =
    useState(motorHomeSpecificObj);
  const [roofValues, setRoofValues] = useState(roofObj);
  const [chassisValues, setChassisValues] = useState(chassisObj);
  const [interiorValues, setInteriorValues] = useState(interiorObj);
  const [entertainmentValues, setEntertainmentValues] =
    useState(entertainmentObj);
  const [waterSystemValues, setWaterSystemValues] = useState(waterSystemObj);
  const [miscValues, setMiscValues] = useState(miscObj);

  const handleSealant = e => {
    const { name, value } = e.target;
    setSealantValues(prevSealantValues => ({
      ...prevSealantValues,
      [name]: value,
    }));
  };
  const handleExterior = e => {
    const { name, value } = e.target;
    setExteriorValues(prevExteriorValues => ({
      ...prevExteriorValues,
      [name]: value,
    }));
  };
  const handlePropane = e => {
    const { name, value } = e.target;
    setPropaneValues(prevPropaneValues => ({
      ...prevPropaneValues,
      [name]: value,
    }));
  };
  const handleElectrical = e => {
    const { name, value } = e.target;
    setElectricalValues(prevElectricalValues => ({
      ...prevElectricalValues,
      [name]: value,
    }));
  };
  const handleSlides = e => {
    const { name, value } = e.target;
    setSlidesValues(prevSlidesValues => ({
      ...prevSlidesValues,
      [name]: value,
    }));
  };
  const handleAppliances = e => {
    const { name, value } = e.target;
    setAppliancesValues(prevAppliancesValues => ({
      ...prevAppliancesValues,
      [name]: value,
    }));
  };
  const handleTentTrailerSpecific = e => {
    const { name, value } = e.target;
    setTentTrailerSpecificValues(prevTentTrailerSpecificValues => ({
      ...prevTentTrailerSpecificValues,
      [name]: value,
    }));
  };
  const handleMotorHomeSpecific = e => {
    const { name, value } = e.target;
    setMotorHomeSpecificValues(prevMotorHomeSpecificValues => ({
      ...prevMotorHomeSpecificValues,
      [name]: value,
    }));
  };
  const handleRoof = e => {
    const { name, value } = e.target;
    setRoofValues(prevRoofValues => ({
      ...prevRoofValues,
      [name]: value,
    }));
  };
  const handleChassis = e => {
    const { name, value } = e.target;
    setChassisValues(prevChassisValues => ({
      ...prevChassisValues,
      [name]: value,
    }));
  };
  const handleInterior = e => {
    const { name, value } = e.target;
    setInteriorValues(prevInteriorValues => ({
      ...prevInteriorValues,
      [name]: value,
    }));
  };
  const handleEntertainment = e => {
    const { name, value } = e.target;
    setEntertainmentValues(prevEntertainmentValues => ({
      ...prevEntertainmentValues,
      [name]: value,
    }));
  };
  const handleWaterSystem = e => {
    const { name, value } = e.target;
    setWaterSystemValues(prevWaterSystemValues => ({
      ...prevWaterSystemValues,
      [name]: value,
    }));
  };
  const handleMisc = e => {
    const { name, value } = e.target;
    setMiscValues(prevMiscValues => ({
      ...prevMiscValues,
      [name]: value,
    }));
  };

  const [consent, setConsent] = useState(false);
  const handleConsent = () => {
    setConsent(consent => !consent);
  };

  const [confirm, setConfirm] = useState(false);
  const handleConfirm = () => {
    setConfirm(confirm => !confirm);
  };

  const wholeForm = useRef(null);

  const handleSubmit = e => {
    e.preventDefault();

    if (!consent) {
      enqueueSnackbar(`Please check 'I agree' and then submit the inspection!`);
      return;
    }

    exteriorValues['rv19_field'] = wholeForm.current['rv19_field'].value;
    exteriorValues['rv22_field'] = wholeForm.current['rv22_field'].value;
    motorHomeSpecificValues['rv98_field'] =
      wholeForm.current['rv98_field'].value;
    roofValues['rv111_field'] = wholeForm.current['rv111_field'].value;
    waterSystemValues['rv187_field'] = wholeForm.current['rv187_field'].value;

    const values = {
      ...sealantValues,
      ...exteriorValues,
      ...propaneValues,
      ...electricalValues,
      ...slidesValues,
      ...appliancesValues,
      ...tentTrailerSpecificValues,
      ...motorHomeSpecificValues,
      ...roofValues,
      ...chassisValues,
      ...interiorValues,
      ...entertainmentValues,
      ...waterSystemValues,
      ...miscValues,
    };
    const form_data = {
      rv: values,
      amvic_number: wholeForm.current['amvic_number'].value,
    };

    props.formData(form_data);
    props.formClose();
  };

  const GreenButton = withStyles({
    root: {
      color: '#fff',
      backgroundColor: '#198754',
      '&:hover': {
        backgroundColor: '#157347',
      },
    },
  })(Button);

  return (
    <form onSubmit={handleSubmit} ref={wholeForm}>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>SEALANT</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(sealant).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={sealantValues}
                      handleGroup={handleSealant}
                      name={name}
                      orange={false}
                      white={true}
                      greenValue="P"
                      redValue="F"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>EXTERIOR</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(exterior).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={exteriorValues}
                      handleGroup={handleExterior}
                      name={name}
                      orange={false}
                      white={true}
                      greenValue="P"
                      redValue="F"
                    />
                  </Grid>
                  {name === 'rv19' && (
                    <Grid
                      container
                      alignItems="center"
                      style={{ marginBottom: '1rem' }}
                    >
                      <Grid item xs={12} style={{ paddingLeft: '1rem' }}>
                        <TextField
                          label="COMPART. MOISTURE READING (IF APPLICABLE)"
                          fullWidth
                          name={'rv19_field'}
                        />
                      </Grid>
                    </Grid>
                  )}
                  {name === 'rv22' && (
                    <Grid
                      container
                      alignItems="center"
                      style={{ marginBottom: '1rem' }}
                    >
                      <Grid item xs={12} style={{ paddingLeft: '1rem' }}>
                        <TextField
                          label="WALL MOISTURE READING (IF APPLICABLE)"
                          fullWidth
                          name={'rv22_field'}
                        />
                      </Grid>
                    </Grid>
                  )}
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>PROPANE</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(propane).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={propaneValues}
                      handleGroup={handlePropane}
                      name={name}
                      orange={false}
                      white={true}
                      greenValue="P"
                      redValue="F"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>ELECTRICAL</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(electrical).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={electricalValues}
                      handleGroup={handleElectrical}
                      name={name}
                      orange={false}
                      white={true}
                      greenValue="P"
                      redValue="F"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>SLIDES</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(slides).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={slidesValues}
                      handleGroup={handleSlides}
                      name={name}
                      orange={false}
                      white={true}
                      greenValue="P"
                      redValue="F"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>APPLIANCES</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(appliances).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={appliancesValues}
                      handleGroup={handleAppliances}
                      name={name}
                      orange={false}
                      white={true}
                      greenValue="P"
                      redValue="F"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>TENT TRAILER SPECIFIC</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(tentTrailerSpecific).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={tentTrailerSpecificValues}
                      handleGroup={handleTentTrailerSpecific}
                      name={name}
                      orange={false}
                      white={true}
                      greenValue="P"
                      redValue="F"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>MOTORHOME SPECIFIC</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(motorHomeSpecific).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  {name === 'rv98' && (
                    <Grid
                      container
                      alignItems="center"
                      style={{ marginBottom: '1rem' }}
                    >
                      <Grid item xs={12} style={{ paddingLeft: '1rem' }}>
                        <TextField
                          label="ENGINE KMS"
                          fullWidth
                          name={'rv98_field'}
                        />
                      </Grid>
                    </Grid>
                  )}
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={motorHomeSpecificValues}
                      handleGroup={handleMotorHomeSpecific}
                      name={name}
                      orange={false}
                      white={true}
                      greenValue="P"
                      redValue="F"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>ROOF</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(roof).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={roofValues}
                      handleGroup={handleRoof}
                      name={name}
                      orange={false}
                      white={true}
                      greenValue="P"
                      redValue="F"
                    />
                  </Grid>
                  {name === 'rv111' && (
                    <Grid
                      container
                      alignItems="center"
                      style={{ marginBottom: '1rem' }}
                    >
                      <Grid item xs={12} style={{ paddingLeft: '1rem' }}>
                        <TextField
                          label="MOISTURE READING:"
                          fullWidth
                          name={'rv111_field'}
                        />
                      </Grid>
                    </Grid>
                  )}
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>CHASSIS</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(chassis).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={chassisValues}
                      handleGroup={handleChassis}
                      name={name}
                      orange={false}
                      white={true}
                      greenValue="P"
                      redValue="F"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>INTERIOR</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(interior).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={interiorValues}
                      handleGroup={handleInterior}
                      name={name}
                      orange={false}
                      white={true}
                      greenValue="P"
                      redValue="F"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>ENTERTAINMENT</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(entertainment).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={entertainmentValues}
                      handleGroup={handleEntertainment}
                      name={name}
                      orange={false}
                      white={true}
                      greenValue="P"
                      redValue="F"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>WATER SYSTEM</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(waterSystem).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={waterSystemValues}
                      handleGroup={handleWaterSystem}
                      name={name}
                      orange={false}
                      white={true}
                      greenValue="P"
                      redValue="F"
                    />
                  </Grid>
                  {name === 'rv187' && (
                    <Grid
                      container
                      alignItems="center"
                      style={{ marginBottom: '1rem' }}
                    >
                      <Grid item xs={12} style={{ paddingLeft: '1rem' }}>
                        <TextField
                          label="MOISTURE CHECK (TOILET, HWH, DRAINS ETC)"
                          fullWidth
                          name={'rv187_field'}
                        />
                      </Grid>
                    </Grid>
                  )}
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>MISC.</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(misc).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={miscValues}
                      handleGroup={handleMisc}
                      name={name}
                      orange={false}
                      white={true}
                      greenValue="Y"
                      redValue="N"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Typography style={{ marginTop: '15px' }}>
        I verify the contents of this document are true and correct.
      </Typography>
      <FormControlLabel
        control={<Checkbox />}
        label="I agree"
        onChange={handleConsent}
        value={consent}
      />
      <br />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Technician Name"
            value={props.user.display_name}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Tech #"
            value={props.user.goUserProfile.profile.sales_licence}
            fullWidth
            name={'amvic_number'}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Date"
            value={new Date().toLocaleString()}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <Button
            type="button"
            variant="contained"
            style={{ marginRight: '15px' }}
            onClick={handleConfirm}
            color="default"
          >
            Cancel
          </Button>
          <Dialog
            open={confirm}
            onClose={handleConfirm}
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Current inspection will be closed and unsaved data will be gone.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleConfirm}>Cancel</Button>
              <Button onClick={props.formClose} color="secondary">
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
          <GreenButton type="submit" variant="contained">
            Save
          </GreenButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default RVRDPDIForm;
