/* External */
import gql from 'graphql-tag';
import React from 'react';
import { useHistory } from 'react-router-dom';

/* Material UI */
import { Grid, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';

/* Internal */
import CountCard from './CountCard';

const useStyles = makeStyles(theme => ({
  cardTotals: {
    textAlign: 'center',
    padding: '10px',
    margin: '10px',
    width: '125px',
    '& .MuiCardContent-root:last-child': {
      paddingBottom: 16,
    },
    [theme.breakpoints.down('md')]: {
      width: '115px',
    },
  },
  cardActionArea: {
    border: '2px solid' + theme.colours.add,
    color: theme.colours.add,
  },
  diabledCardAction: {
    border: '2px solid black',
    color: 'black',
  },
}));

const BulkSendCountCards = ({ bulkSend, gatewayId }) => {
  const classes = useStyles();
  const history = useHistory();
  const mobile = useMediaQuery(theme => theme.breakpoints.down('md'));

  const {
    id,
    name,
    totalCount,
    scheduledCount,
    queuedCount,
    skippedCount,
    failedCount,
    deliveredCount,
    replyCount,
  } = bulkSend;

  let bulkSendFilters = [
    {
      model: 'BulkSend',
      field: 'id',
      op: 'eq',
      value: id,
    },
  ];

  const deliveredFilter = {
    model: 'Message',
    field: 'delivery_status',
    op: 'eq',
    value: 'delivered',
  };

  const skippedFilter = {
    model: 'Message',
    field: 'delivery_status',
    op: 'eq',
    value: 'undelivered',
  };

  const repliedFilter = {
    model: 'Message',
    field: 'reply_bulksend_id',
    op: 'eq',
    value: id,
  };

  const counts = [
    {
      name: 'Total',
      count: totalCount,
      filter: '',
      className: classes.cardTotals,
      actionAreaDisabled: true,
    },
    {
      name: 'Scheduled',
      count: scheduledCount,
      filter: '',
      className: classes.cardTotals,
      actionAreaDisabled: true,
    },
    {
      name: 'Queued',
      count: queuedCount,
      filter: '',
      className: classes.cardTotals,
      actionAreaDisabled: true,
    },
    {
      name: 'Skipped',
      count: skippedCount,
      filter: skippedFilter,
      className:
        skippedCount === 0
          ? `${classes.disabledCardAction} ${classes.cardTotals}`
          : `${classes.cardActionArea} ${classes.cardTotals}`,
      actionAreaDisabled: skippedCount === 0 ? true : false,
    },
    {
      name: 'Failed',
      count: failedCount,
      filter: '',
      className: classes.cardTotals,
      actionAreaDisabled: true,
    },
    {
      name: 'Delivered',
      count: deliveredCount,
      filter: deliveredFilter,
      className:
        deliveredCount === 0
          ? `${classes.disabledCardAction} ${classes.cardTotals}`
          : `${classes.cardActionArea} ${classes.cardTotals}`,
      actionAreaDisabled: deliveredCount === 0 ? true : false,
    },
    {
      name: 'Replies',
      count: replyCount,
      filter: repliedFilter,
      className:
        replyCount === 0
          ? `${classes.disabledCardAction} ${classes.cardTotals}`
          : `${classes.cardActionArea} ${classes.cardTotals}`,
      actionAreaDisabled: replyCount === 0 ? true : false,
    },
  ];

  const handleCardClick = messageFilter => {
    // Replied messages don't have a bulksend id attached to them,
    // so we don't want to include the bulksend filter (it will return 0 results if we do)
    bulkSendFilters =
      messageFilter.field === 'reply_bulksend_id'
        ? [messageFilter]
        : [...bulkSendFilters, messageFilter];

    history.push({
      pathname: `/sms/gateways/${gatewayId}/message-search`,
      state: [bulkSendFilters, name],
    });
  };

  return (
    <Grid
      container
      item
      xs={12}
      justifyContent={mobile ? 'center' : 'space-between'}
    >
      {counts.map(count => (
        <CountCard
          key={count.name}
          name={count.name}
          count={count.count}
          filter={count.filter}
          handleCardClick={handleCardClick}
          cardClass={count.className}
          actionAreaDisabled={count.actionAreaDisabled}
        />
      ))}
    </Grid>
  );
};

BulkSendCountCards.fragments = {
  bulkSend: gql`
    fragment BulkSendCountCardsBulkSend on BulkSend {
      id
      name
      totalCount
      scheduledCount
      queuedCount
      skippedCount
      failedCount
      deliveredCount
      replyCount
    }
  `,
};

export default BulkSendCountCards;
