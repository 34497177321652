import { useMutation, useQuery } from '@apollo/react-hooks';
import { NetworkStatus } from 'apollo-boost';
import gql from 'graphql-tag';
import { each, groupBy, has, isEmpty, omit } from 'lodash';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import ErrorPage from 'components/MaterialUI/ErrorPage';
import Loading from 'components/MaterialUI/Loading';

import { usePersistedState } from '../../utils';

// TODO: use DealerContext if it exists. (just pass in dealerId for now.)

const VehiclesQueryContext = createContext({
  clearUpdateErrorHandler: () => {},
  error: null,
  facets: [],
  facetResults: [],
  fetchMore: () => {},
  filters: [],
  getUpdateError: () => {},
  inventoryFilters: [],
  isSaving: () => {},
  loading: null,
  networkStatus: null,
  order: null,
  orderBy: null,
  setOrder: () => {},
  setOrderBy: () => {},
  pagination: {},
  processor: () => {},
  refetch: () => {},
  searchKeywords: null,
  setFacets: () => {},
  setInventoryFilters: () => {},
  setSearchKeywords: () => {},
  updateFieldHandler: () => {},
  updateVehicle: () => {},
  vehicles: [],
});

// Any fields that require processing from string values before updating
const PROCESSORS = {
  odometer: x => parseInt(x.replace(',', '')),
  regular_price: x => parseFloat(x.replace(',', '')),
  special_price: x => parseFloat(x.replace(',', '')),
  special_price_expires: x =>
    moment.isMoment(x) ? x.format('YYYY-MM-DD') + 'T00:00:00' : x,
  is_special_price_enabled: e => e.target.checked,
};

const DEFAULT_FACETS = [
  {
    model: 'StockStatus',
    field: 'name',
    value: 'In Stock',
    options: {},
  },
];

export const VehiclesQueryProvider = ({
  children,
  dealerId,
  facetsQueries,
  fragments,
  keywordsParams,
  defaultOrder = 'asc',
  defaultOrderBy = 'displayName',
}) => {
  const [order, setOrder] = usePersistedState('inventoryOrder', defaultOrder);
  const [orderBy, setOrderBy] = usePersistedState(
    'inventoryOrderBy',
    defaultOrderBy,
  );
  const [searchKeywords, setSearchKeywords] = useState('');
  const [updates, setUpdates] = useState({});
  const [updateErrors, setUpdateErrors] = useState({});
  const [facets, setFacets] = usePersistedState(
    'inventoryFacets',
    DEFAULT_FACETS,
  );
  const [inventoryFilters, setInventoryFilters] = usePersistedState(
    'inventoryFilters',
    [],
  );
  const { enqueueSnackbar } = useSnackbar();
  const dealerIdRef = useRef(dealerId);

  useEffect(() => {
    if (dealerId !== dealerIdRef.current) {
      setFacets(DEFAULT_FACETS);
      dealerIdRef.current = dealerId;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealerId]);

  useEffect(() => {
    if (keywordsParams) {
      setSearchKeywords(keywordsParams);
    }
  }, [keywordsParams]);

  const QUERY = gql`
  query VehiclesQuery(
    $page: Int
    $page_size: Int
    $sort: [QuerySortElement]
    $filters: [QueryFilter]
    $facetsQueries: [QueryFacet]!
    ) {
      inventory {
        getVehicles(
          page: $page
          page_size: $page_size
          filters: $filters
          sort: $sort
          ) {
            results {
              id
              ${Object.keys(fragments)
                .map(fragName => `...${fragName}`)
                .join('\n')}
              }
              pagination {
                next_page
                page
                page_size
                pages
                total
              }
            }
            getVehicleFacets(filters: $filters, facets: $facetsQueries)
            {
              field
              model
              data {
                value
                count
              }
            }
          }
        }
        ${Object.values(fragments).reduce(
          (acc, fragment) =>
            gql`
              ${acc}
              ${fragment}
            `,
        )}
    `;

  const UPDATE_VEHICLE = gql`
    mutation updateVehicle($id: Int!, $data: GreaseInventoryVehicleInput!) {
      inventory {
        updateVehicle(id: $id, data: $data) {
          id
          ${Object.keys(fragments)
            .map(fragName => `...${fragName}`)
            .join('\n')}
        }
      }
    }
    ${Object.values(fragments).reduce(
      (acc, fragment) =>
        gql`
          ${acc}
          ${fragment}
        `,
    )}
  `;
  const [updateVehicle] = useMutation(UPDATE_VEHICLE);

  const filters = [
    { model: 'Vehicle', field: 'dealer_id', op: '==', value: dealerId },
    {
      model: 'Vehicle',
      field: 'keywords',
      op: 'ilike',
      value: '%' + searchKeywords + '%' || '%%',
    },
    ...inventoryFilters,
    ...Object.entries(groupBy(facets, x => [x.model, x.field])).map(
      ([modelField, entries]) => ({
        model: modelField.split(',')[0],
        field: modelField.split(',')[1],
        op: 'in',
        value: entries.map(({ value }) =>
          value === 'True' ? true : value === 'False' ? false : value,
        ),
      }),
    ),
  ];

  const sort =
    orderBy === 'displayName'
      ? [
          {
            model: 'Vehicle',
            field: 'year',
            direction: order,
          },
          {
            model: 'Make',
            field: 'name',
            direction: order,
          },
          {
            model: 'Model',
            field: 'name',
            direction: order,
          },
        ]
      : [
          {
            model: 'Vehicle',
            field: orderBy,
            direction: order,
          },
        ];

  const {
    data,
    loading,
    error,
    fetchMore: fetchMoreVehicles,
    networkStatus,
    refetch,
  } = useQuery(QUERY, {
    variables: {
      page: 1,
      page_size: 20,
      sort,
      filters,
      facetsQueries,
    },
    notifyOnNetworkStatusChange: true,
  });

  const vehicles = data?.inventory?.getVehicles?.results || [];
  const pagination = data?.inventory?.getVehicles?.pagination || {};
  const facetResults = data?.inventory?.getVehicleFacets || [];
  const { next_page } = pagination;

  const fetchMore = () =>
    next_page &&
    fetchMoreVehicles({
      variables: {
        page: next_page,
      },

      updateQuery: (prev, { fetchMoreResult: more }) => {
        if (!more.inventory.getVehicles.results) return prev;
        return Object.assign({}, prev, {
          inventory: {
            __typename: prev.inventory.__typename,
            getVehicles: {
              __typename: prev.inventory.getVehicles.__typename,
              results: [
                ...prev.inventory.getVehicles.results,
                ...more.inventory.getVehicles.results,
              ],
              pagination: more.inventory.getVehicles.pagination,
            },
            getVehicleFacets: more.inventory.getVehicleFacets,
          },
        });
      },
    });

  const getUpdateError = (id, field) =>
    updateErrors && updateErrors[id] && updateErrors[id][field];

  const clearUpdateErrorHandler = id => () =>
    setUpdateErrors(prev => omit(prev, id));

  const updateHandler = id => updates => {
    setUpdateErrors(prev => omit(prev, id));
    setUpdates(prev => ({ ...prev, [id]: updates }));
  };

  const processor = field => PROCESSORS[field] || (x => x);

  const updateFieldHandler = (id, field) => newValue =>
    updateHandler(id)({ [field]: processor(field)(newValue) });

  const isSaving = (id, field) => has(updates, [id, field]);

  const parseErrors = ({ graphQLErrors, networkError, message }, data) => {
    // Go through the errors and return an object with {
    // specific_field: [error_messages]
    // errors: [generic_errors]
    // }

    // put the generic errors on the specific field if there's only one key
    // in data
    const destKey =
      Object.keys(data).length === 1 ? Object.keys(data)[0] : 'error';

    let returnErrors = {};
    if (!isEmpty(graphQLErrors))
      graphQLErrors.forEach(({ message, location, path, extensions }) => {
        if (extensions.json)
          returnErrors = Object.assign({}, returnErrors, extensions.json);
        else if (message) returnErrors[destKey] = message;
      });
    else if (!isEmpty(networkError)) returnErrors[destKey] = networkError;
    else if (!isEmpty(message)) returnErrors[destKey] = message;
    else returnErrors.error = 'An unknown error occured.';

    return returnErrors;
  };

  useEffect(() => {
    if (!isEmpty(updates)) {
      each(updates, (data, id) =>
        updateVehicle({ variables: { id: parseInt(id, 10), data } })
          .catch(e =>
            setUpdateErrors(prev => ({ ...prev, [id]: parseErrors(e, data) })),
          )
          .finally(() => setUpdates(prev => omit(prev, id))),
      );
    }
  }, [updates, updateVehicle]);

  useEffect(() => {
    for (const [id, errors] of Object.entries(updateErrors)) {
      if (errors.is_special_price_enabled) {
        enqueueSnackbar(errors.is_special_price_enabled, { variant: 'error' });
        clearUpdateErrorHandler(id)();
      }
    }
  }, [enqueueSnackbar, updateErrors]);

  if (error) return <ErrorPage error={error} action="Loading Vehicles" />;
  if (loading && networkStatus === NetworkStatus.loading) return <Loading />;

  return (
    <VehiclesQueryContext.Provider
      value={{
        clearUpdateErrorHandler,
        error,
        facetResults,
        facets,
        fetchMore,
        filters,
        getUpdateError,
        inventoryFilters,
        isSaving,
        loading,
        networkStatus,
        order,
        orderBy,
        pagination,
        processor,
        refetch,
        searchKeywords,
        setFacets,
        setInventoryFilters,
        setOrder,
        setOrderBy,
        setSearchKeywords,
        updateFieldHandler,
        updateVehicle,
        vehicles,
      }}
    >
      {children}
    </VehiclesQueryContext.Provider>
  );
};

export const useVehiclesQueryContext = () => useContext(VehiclesQueryContext);

export default VehiclesQueryProvider;
