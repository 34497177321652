import React from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import DoneIcon from '@mui/icons-material/Done';
import SmsIcon from '@mui/icons-material/Sms';

/* Internal */
import TextFieldControl from 'components/MaterialUI/TextFieldControl';

import { Regexps } from 'constants.js';
import gql from 'graphql-tag';

const useStyles = makeStyles(theme => ({
  flexBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginLeft: 'auto',
    marginRight: 0,
  },
  whiteFont: {
    color: '#FFFFFF',
  },
}));

const CustomerContactPrefs = ({
  preferred_name,
  sms_contact_number,
  onSubmit,
  dealer,
  customer_email,
}) => {
  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      preferred_name: preferred_name ?? '',
      sms_contact_number: sms_contact_number ?? '',
      customer_email: customer_email ?? '',
    },
  });
  const theme = useTheme();
  const { flexBox, whiteFont } = useStyles();
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2} direction="row">
        <Grid item xs={12}>
          <Box className={flexBox}>
            <b
              className={flexBox}
              style={{
                marginTop: 'auto',
                marginBottom: 'auto',
                minWidth: '120px',
              }}
            >
              {' '}
              Preferred Name:
            </b>
            <TextFieldControl control={control} name="preferred_name" />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box className={flexBox}>
            <b
              className={flexBox}
              style={{
                marginTop: 'auto',
                marginBottom: 'auto',
                minWidth: '120px',
              }}
            >
              Preferred SMS:
            </b>
            <TextFieldControl
              control={control}
              name="sms_contact_number"
              rules={{
                pattern: {
                  value: Regexps.PhoneFormat.ALL,
                  message: 'Invalid phone number',
                },
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box className={flexBox}>
            <b
              className={flexBox}
              style={{
                marginTop: 'auto',
                marginBottom: 'auto',
                minWidth: '120px',
              }}
            >
              Preferred Email:
            </b>
            <TextFieldControl
              control={control}
              name="customer_email"
              rules={{
                pattern: {
                  value: Regexps.EmailFormat,
                  message: 'Invalid email address',
                },
              }}
            />
          </Box>
        </Grid>
        {/* <Grid item xs={12}>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox />}
              label="Send SMS when videos are added"
            />
            <FormControlLabel
              disabled
              control={<Checkbox />}
              label="Send SMS when RO status changes"
            />
            <FormControlLabel
              disabled
              control={<Checkbox />}
              label="Send SMS when vehicle is ready for pickup"
            />
          </FormGroup>
        </Grid> */}
        <Grid item xs={12} className={flexBox}>
          <Box className={flexBox}>
            <Button
              variant="contained"
              style={isValid ? theme.actions.confirm : theme.actions.cancel}
              type="submit"
              disabled={isSubmitting || !isValid}
            >
              <DoneIcon /> Save
            </Button>
          </Box>
        </Grid>
        {!dealer.is_flex && (
          <Grid item xs={12} className={flexBox}>
            <Box className={flexBox}>
              <Button
                variant="contained"
                style={theme.actions.info}
                className={whiteFont}
                component={Link}
                to={`/sms/gateways/${dealer.sms_gateway_id}/number/${sms_contact_number}`}
              >
                <SmsIcon style={{ paddingRight: '5px' }} /> Open SMS
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>
    </form>
  );
};

CustomerContactPrefs.fragments = {
  dealer: gql`
    fragment CustomerContactPrefsDealerData on ROTrackerDealer {
      is_flex
      sms_gateway_id
    }
  `,
};

export default CustomerContactPrefs;
