import React, { useRef, useState } from 'react';
import { withStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import { cloneDeep } from 'lodash';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import InspectionCheckboxes from '../InspectionCheckboxes';

const TrailerCoachSpecificForm = props => {
  const { enqueueSnackbar } = useSnackbar();

  // RV Red Deer Trailer and Coach Specific inspection form fields
  const electrical = {
    rv1: 'Solar Panels',
    rv2: 'Inverter',
    rv3: 'Fireplace',
    rv4: 'Transfer Switch',
    rv5: 'Connections Clean & Tight',
    rv6: 'Running Lights',
    rv7: 'Turn Signals',
    rv8: 'Back up Lights',
    rv9: 'Range Hood',
    rv10: 'Vent Fans',
    rv11: 'Monitor Panel Functions',
    rv12: 'Converter',
    rv13: 'Interior and Exterior Receptacles',
    rv14: 'Microwave',
    rv15: 'Air Conditioner (Roof) Cooling',
    rv16: 'Air Conditioner (Roof) Heating',
    rv17: 'Power Cord (120 Volt)',
  };
  const waterSystem = {
    rv18: 'Complete System Leak Test',
    rv19: 'Water Tank',
    rv20: 'Water Pump',
    rv21: 'City Hook Up',
    rv22: 'Water Filler',
    rv23: 'Check All Lines',
    rv24: 'Check All Faucets',
    rv25: 'Check all Drains',
    rv26: 'Toilet Function',
    rv27: 'Shower Function',
    rv28: 'Outside Showers',
    rv29: 'Water Heater Bypass',
    rv30: 'Fill Gray Water Holding Tank',
    rv31: 'Fill Black Water Holding Tank',
    rv32: 'Gate Valves',
  };
  const gasLP = {
    rv33: 'Complete Leak Test',
    rv34: 'LP Regulator',
    rv35: 'LP Tank / Bottles',
    rv36: 'Pigtails Check For Cracks',
    rv37: 'Range / Oven Pilots',
    rv38: 'Furnace',
    rv39: 'Hot water heater',
  };
  const generator = {
    rv40: 'Wiring Connections',
    rv41: 'Remote Start Operation',
  };
  const awnings = {
    rv42: 'Motor Assemblies',
    rv43: 'Awning Spring Assemblies',
    rv44: 'Electric Step',
  };
  const exterior = {
    rv45: 'Tire Pressure',
    rv46: 'Spare Tire Pressure',
    rv47: 'Tire Wear Left Side',
    rv48: 'Tire Wear Right Side',
    rv49: 'All Wheels Torqued',
    rv50: 'Suspension Coil / Leaf / Torsion',
    rv51: 'Bearing Repack',
    rv52: 'Break Function / Adjustment',
    rv53: 'Break Away Switch',
  };
  const slideOutRooms = {
    rv54: 'Mechanical',
    rv55: 'Electrical Components',
    rv56: 'Hydraulic Components',
    rv57: 'Raised Room Extension System',
    rv58: 'Motors',
    rv59: 'Pump / Jack Assembly',
    rv60: 'Air Bags',
    rv61: 'Lines / Fittings / Cylinders',
    rv62: 'Worm Gear',
    rv63: 'Electric Switch',
    rv64: 'Hydraulic Jack Fluid Level / Leaks',
  };
  const deluxeElectronic = {
    rv65: 'TV',
    rv66: 'VCR',
    rv67: 'DVD Player',
    rv68: 'Stereo',
    rv69: 'CD Player',
    rv70: 'Radio / Cassette Player',
    rv71: 'Antenna Motors',
    rv72: 'Rear Stereo System',
  };
  const deluxeAppliances = {
    rv73: 'Washer',
    rv74: 'Dryer',
    rv75: 'Water Purifier',
    rv76: 'Dishwasher',
    rv77: 'Built-in Vacuum',
    rv78: 'TV Antenna Operation',
    rv79: 'External BBQ',
    rv80: 'Trash Compactor',
    rv81: 'Spot Light',
    rv82: 'Carbon Monoxide Detector',
    rv83: 'Smoke Detector',
    rv84: 'Propane Leak Detector',
    rv85: 'Garbage Disposal',
    rv86: 'Central Locking System',
  };

  let electricalObj = cloneDeep(electrical);
  let waterSystemObj = cloneDeep(waterSystem);
  let gasLPObj = cloneDeep(gasLP);
  let generatorObj = cloneDeep(generator);
  let awningsObj = cloneDeep(awnings);
  let exteriorObj = cloneDeep(exterior);
  let slideOutRoomsObj = cloneDeep(slideOutRooms);
  let deluxeElectronicObj = cloneDeep(deluxeElectronic);
  let deluxeAppliancesObj = cloneDeep(deluxeAppliances);

  Object.entries(electricalObj).map(([name]) => (electricalObj[name] = 'Good'));
  Object.entries(waterSystemObj).map(
    ([name]) => (waterSystemObj[name] = 'Good'),
  );
  Object.entries(gasLPObj).map(([name]) => (gasLPObj[name] = 'Good'));
  Object.entries(generatorObj).map(([name]) => (generatorObj[name] = 'Good'));
  Object.entries(awningsObj).map(([name]) => (awningsObj[name] = 'Good'));
  Object.entries(exteriorObj).map(([name]) => (exteriorObj[name] = 'Good'));
  Object.entries(slideOutRoomsObj).map(
    ([name]) => (slideOutRoomsObj[name] = 'Good'),
  );
  Object.entries(deluxeElectronicObj).map(
    ([name]) => (deluxeElectronicObj[name] = 'Good'),
  );
  Object.entries(deluxeAppliancesObj).map(
    ([name]) => (deluxeAppliancesObj[name] = 'Good'),
  );

  const [electricalValues, setElectricalValues] = useState(electricalObj);
  const [waterSystemValues, setWaterSystemValues] = useState(waterSystemObj);
  const [gasLPValues, setGasLPValues] = useState(gasLPObj);
  const [generatorValues, setGeneratorValues] = useState(generatorObj);
  const [awningsValues, setAwningsValues] = useState(awningsObj);
  const [exteriorValues, setExteriorValues] = useState(exteriorObj);
  const [slideOutRoomsValues, setSlideOutRoomsValues] =
    useState(slideOutRoomsObj);
  const [deluxeElectronicValues, setDeluxeElectronicValues] =
    useState(deluxeElectronicObj);
  const [deluxeAppliancesValues, setDeluxeAppliancesValues] =
    useState(deluxeAppliancesObj);

  const handleElectrical = e => {
    const { name, value } = e.target;
    setElectricalValues(prevElectricalValues => ({
      ...prevElectricalValues,
      [name]: value,
    }));
  };
  const handleWaterSystem = e => {
    const { name, value } = e.target;
    setWaterSystemValues(prevWaterSystemValues => ({
      ...prevWaterSystemValues,
      [name]: value,
    }));
  };
  const handleGasLP = e => {
    const { name, value } = e.target;
    setGasLPValues(prevGasLPValues => ({
      ...prevGasLPValues,
      [name]: value,
    }));
  };
  const handleGenerator = e => {
    const { name, value } = e.target;
    setGeneratorValues(prevGeneratorValues => ({
      ...prevGeneratorValues,
      [name]: value,
    }));
  };
  const handleAwnings = e => {
    const { name, value } = e.target;
    setAwningsValues(prevAwningsValues => ({
      ...prevAwningsValues,
      [name]: value,
    }));
  };
  const handleExterior = e => {
    const { name, value } = e.target;
    setExteriorValues(prevExteriorValues => ({
      ...prevExteriorValues,
      [name]: value,
    }));
  };
  const handleSlideOutRooms = e => {
    const { name, value } = e.target;
    setSlideOutRoomsValues(prevSlideOutRoomsValues => ({
      ...prevSlideOutRoomsValues,
      [name]: value,
    }));
  };
  const handleDeluxeElectronic = e => {
    const { name, value } = e.target;
    setDeluxeElectronicValues(prevDeluxeElectronicValues => ({
      ...prevDeluxeElectronicValues,
      [name]: value,
    }));
  };
  const handleDeluxeAppliances = e => {
    const { name, value } = e.target;
    setDeluxeAppliancesValues(prevDeluxeAppliancesValues => ({
      ...prevDeluxeAppliancesValues,
      [name]: value,
    }));
  };

  const [consent, setConsent] = useState(false);
  const handleConsent = () => {
    setConsent(consent => !consent);
  };

  const [confirm, setConfirm] = useState(false);
  const handleConfirm = () => {
    setConfirm(confirm => !confirm);
  };

  const wholeForm = useRef(null);

  const handleSubmit = e => {
    e.preventDefault();

    if (!consent) {
      enqueueSnackbar(`Please check 'I agree' and then submit the inspection!`);
      return;
    }

    gasLPValues['rv35_field'] = wholeForm.current['rv35_field'].value;
    gasLPValues['rv37_field'] = wholeForm.current['rv37_field'].value;
    generatorValues['rv40_field1'] = wholeForm.current['rv40_field1'].value;
    generatorValues['rv40_field2'] = wholeForm.current['rv40_field2'].value;

    const values = {
      ...electricalValues,
      ...waterSystemValues,
      ...gasLPValues,
      ...generatorValues,
      ...awningsValues,
      ...exteriorValues,
      ...slideOutRoomsValues,
      ...deluxeElectronicValues,
      ...deluxeAppliancesValues,
    };
    const form_data = {
      rv: values,
      technician_comments: wholeForm.current['technician_comments'].value,
      amvic_number: wholeForm.current['amvic_number'].value,
    };

    props.formData(form_data);
    props.formClose();
  };

  const GreenButton = withStyles({
    root: {
      color: '#fff',
      backgroundColor: '#198754',
      '&:hover': {
        backgroundColor: '#157347',
      },
    },
  })(Button);

  return (
    <form onSubmit={handleSubmit} ref={wholeForm}>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Electrical</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(electrical).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={electricalValues}
                      handleGroup={handleElectrical}
                      name={name}
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Water System</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(waterSystem).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={waterSystemValues}
                      handleGroup={handleWaterSystem}
                      name={name}
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Gas LP</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(gasLP).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={gasLPValues}
                      handleGroup={handleGasLP}
                      name={name}
                    />
                  </Grid>
                  {name === 'rv35' && (
                    <Grid
                      container
                      alignItems="center"
                      style={{ marginBottom: '1rem' }}
                    >
                      <Grid item xs={12} style={{ paddingLeft: '1rem' }}>
                        <TextField label="Date" fullWidth name={'rv35_field'} />
                      </Grid>
                    </Grid>
                  )}
                  {name === 'rv37' && (
                    <Grid
                      container
                      alignItems="center"
                      style={{ marginBottom: '1rem' }}
                    >
                      <Grid item xs={12} style={{ paddingLeft: '1rem' }}>
                        <TextField
                          label="Fridge/Freezer (1 Hour Test)"
                          fullWidth
                          name={'rv37_field'}
                        />
                      </Grid>
                    </Grid>
                  )}
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Generator</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(generator).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  {name === 'rv40' && (
                    <Grid
                      container
                      alignItems="center"
                      style={{ marginBottom: '1rem' }}
                    >
                      <Grid item xs={12} style={{ paddingLeft: '1rem' }}>
                        <TextField
                          label="Hour Meter Reading (hours)"
                          fullWidth
                          name={'rv40_field1'}
                        />
                      </Grid>
                      <Grid item xs={12} style={{ paddingLeft: '1rem' }}>
                        <TextField
                          label="Last Oil Change Date"
                          fullWidth
                          name={'rv40_field2'}
                        />
                      </Grid>
                    </Grid>
                  )}
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={generatorValues}
                      handleGroup={handleGenerator}
                      name={name}
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Awnings/Electric Steps</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(awnings).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={awningsValues}
                      handleGroup={handleAwnings}
                      name={name}
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Exterior</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(exterior).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={exteriorValues}
                      handleGroup={handleExterior}
                      name={name}
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Slide Out Rooms/Levellers</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(slideOutRooms).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={slideOutRoomsValues}
                      handleGroup={handleSlideOutRooms}
                      name={name}
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Deluxe Electronic</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(deluxeElectronic).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={deluxeElectronicValues}
                      handleGroup={handleDeluxeElectronic}
                      name={name}
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Deluxe Appliances</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(deluxeAppliances).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={deluxeAppliancesValues}
                      handleGroup={handleDeluxeAppliances}
                      name={name}
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <TextField
        label="Technician Comments"
        multiline
        minRows={2}
        variant="outlined"
        name={'technician_comments'}
        fullWidth
        style={{ marginTop: '15px' }}
      />

      <Typography style={{ marginTop: '15px' }}>
        I verify the contents of this document are true and correct.
      </Typography>
      <FormControlLabel
        control={<Checkbox />}
        label="I agree"
        onChange={handleConsent}
        value={consent}
      />
      <br />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Technician Name"
            value={props.user.display_name}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Tech #"
            value={props.user.goUserProfile.profile.sales_licence}
            fullWidth
            name={'amvic_number'}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Date"
            value={new Date().toLocaleString()}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <Button
            type="button"
            variant="contained"
            style={{ marginRight: '15px' }}
            onClick={handleConfirm}
            color="default"
          >
            Cancel
          </Button>
          <Dialog
            open={confirm}
            onClose={handleConfirm}
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Current inspection will be closed and unsaved data will be gone.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleConfirm}>Cancel</Button>
              <Button onClick={props.formClose} color="secondary">
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
          <GreenButton type="submit" variant="contained">
            Save
          </GreenButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default TrailerCoachSpecificForm;
