import React from 'react';

import { RoleGroup } from 'constants.js';
import { useUserContext } from 'components/MaterialUI/UserContext';

const DealLogRoleOnly = ({ children, className }) => {
  const {
    currentUser: { role },
  } = useUserContext();
  if (role && RoleGroup.DEAL_LOG_ROLE.includes(role)) {
    return <div className={className}>{children}</div>;
  }

  return null;
};

export default DealLogRoleOnly;
