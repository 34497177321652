import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import screenfull from 'screenfull';

import TextButton from 'components/TextButton';
import AuctionRoleOnly from '../AuctionRoleOnly';
import DashboardRoleOnly from '../DashboardRoleOnly';
import DealLogRoleOnly from '../DealLogRoleOnly';
import RequiresDealerIds from '../RequiresDealerIds';
import Support from '../Support';
import Layout from '../Layout';
import NavBar from '../NavBar';
import { RV_DEALERS } from 'constants.js';

import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useUserContext } from 'components/MaterialUI/UserContext';

const useStyles = makeStyles(() => ({
  landingPageUser: {
    margin: '2em',
  },
  landingPageApps: {
    margin: '3em',
    display: 'flex',
    flexDirection: 'column',
    width: 'fit-content',
  },
  landingPageSupport: {
    display: 'flex',
    height: '50vh',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  fa3x: {
    verticalAlign: 'middle',
    fontSize: '3em',
  },
}));

const LandingPage = props => {
  const classes = useStyles();
  const [showSupport, setShowSupport] = useState(false);
  const { currentUser } = useUserContext();

  const renderHeader = () => {
    return <NavBar title="Home" />;
  };

  return (
    <Layout
      Header={renderHeader()}
      footerActions={[
        {
          icon: 'question-circle',
          label: 'Help',
          onClick: () => setShowSupport(!showSupport),
        },
        {
          icon: screenfull.isFullscreen ? 'compress' : 'expand',
          label: 'Fullscreen',
          onClick: () =>
            screenfull.isFullscreen ? screenfull.exit() : screenfull.request(),
        },
        {
          icon: 'sign-out-alt',
          label: 'Sign out',
          onClick: () => props?.history?.push('/logout'),
        },
      ]}
    >
      {!showSupport && (
        <Box>
          <Box className={classes.landingPageUser}>
            <i
              className={`${classes.fa3x} fa fa-user-circle fa-fw`}
              aria-hidden="true"
            />
            {`Hello ${currentUser.display_name}!`}
          </Box>
          <Box className={classes.landingPageApps}>
            <DashboardRoleOnly>
              <Link to={'/dashboard'}>
                <TextButton>Dashboard</TextButton>
              </Link>
            </DashboardRoleOnly>
            <AuctionRoleOnly>
              <Link to={'/auctions'}>
                <TextButton>Auctions</TextButton>
              </Link>
            </AuctionRoleOnly>
            <Link to={'/appointments'}>
              <TextButton>Appointments</TextButton>
            </Link>
            <Link to={'/appraisals'}>
              <TextButton>Appraisals</TextButton>
            </Link>
            <Link to={'/customers/search'}>
              <TextButton>Customers</TextButton>
            </Link>
            <Link to={'/rv-adventure-club'}>
              <TextButton>RV Adventure Club</TextButton>
            </Link>
            <Link to={'/opportunities/search'}>
              <TextButton>Opportunities</TextButton>
            </Link>
            <DealLogRoleOnly>
              <Link to={'/deallog'}>
                <TextButton>Deal Log</TextButton>
              </Link>
            </DealLogRoleOnly>
            <Link to={'/inventory/dashboard'}>
              <TextButton>Inventory</TextButton>
            </Link>
            <Link to={'/leads/dashboard'}>
              <TextButton>Leads</TextButton>
            </Link>
            <Link to={'/parts'}>
              <TextButton>Parts</TextButton>
            </Link>
            <Link to={'/rotracker'}>
              <TextButton>Service</TextButton>
            </Link>
            <Link to={'/sms/gateways'}>
              <TextButton>SMS</TextButton>
            </Link>
            <Link to={'/used-vehicles'}>
              <TextButton>Used Vehicles</TextButton>
            </Link>
            <Link to={'/tasks'}>
              <TextButton>Tasks</TextButton>
            </Link>
            <RequiresDealerIds
              requiredDealerIds={RV_DEALERS}
              renderOnSuccess={() => (
                <Link to={'/npv-inventory/dashboard'}>
                  <TextButton>RV Inventory</TextButton>
                </Link>
              )}
              renderOnFailure={() => null}
            />
          </Box>
        </Box>
      )}
      {showSupport && (
        <Box className={classes.landingPageSupport}>
          <Support />
        </Box>
      )}
    </Layout>
  );
};

export default LandingPage;
