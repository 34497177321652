import React, { useState } from 'react';

import ErrorDisplay from 'components/MaterialUI/ErrorDisplay';
import gql from 'graphql-tag';
import { map } from 'lodash';
import { useSnackbar } from 'notistack';

import { useMutation, useQuery } from '@apollo/react-hooks';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';

const UPDATE_PRICING = gql`
  mutation updateNpvBulkPricing(
    $filters: [QueryFilter!]!
    $data: NpvBulkVehicleInput!
  ) {
    npv {
      updateBulkVehicles(filters: $filters, data: $data) {
        id
        modifiedBy
        regularPrice
        specialPrice
      }
    }
  }
`;

const NO_REGULAR_PRICE_QUERY = gql`
  query noRegularPrice($filters: [QueryFilter], $sort: [QuerySortElement]) {
    npv {
      getVehicles(filters: $filters, sort: $sort) {
        results {
          id
          stockNumber
        }
      }
    }
  }
`;

const BulkVehiclePricing = ({
  ids,
  open,
  onClose,
  selectByFilter,
  vehicleFilters,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [regularSign, setRegularSign] = useState(0);
  const [regularType, setRegularType] = useState('$');
  const [regularAmount, setRegularAmount] = useState();

  const [update, updateStatus] = useMutation(UPDATE_PRICING, {
    onCompleted: data => {
      onClose();
      enqueueSnackbar(
        `Successfully updated ${data.npv.updateBulkVehicles.length} vehicles' pricing.`,
      );
    },
    onError: () => null,
  });

  const baseFilters = selectByFilter
    ? vehicleFilters
    : [
        {
          model: 'Vehicle',
          field: 'id',
          op: 'in',
          value: ids,
        },
      ];

  const noRegPriceFilters = [
    ...baseFilters,
    {
      or: [
        {
          model: 'Vehicle',
          field: 'regularPrice',
          op: 'is_null',
        },
        {
          model: 'Vehicle',
          field: 'regularPrice',
          op: '<=',
          value: 0,
        },
      ],
    },
  ];

  const { data: noRegPriceData, loading: checkingForRegPrice } = useQuery(
    NO_REGULAR_PRICE_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      skip: !open,
      variables: {
        filters: [...noRegPriceFilters],
        sort: [
          {
            model: 'Vehicle',
            field: 'stockNumber',
            direction: 'asc',
          },
        ],
      },
    },
  );

  const vehiclesNoRegPrice = noRegPriceData?.npv?.getVehicles?.results || [];

  const handleChangeRegularSign = e =>
    setRegularSign(parseInt(e.target.value, 10));
  const handleChangeRegularType = e => {
    setRegularType(e.target.value);
  };

  const regularPriceMod =
    regularSign && regularAmount ? regularSign * regularAmount : undefined;

  const regularPriceFilters = [
    {
      model: 'Vehicle',
      field: 'regularPrice',
      op: '>',
      value: 0,
    },
    {
      model: 'Vehicle',
      field: 'regularPrice',
      op: 'is_not_null',
    },
  ];

  const filters = [...baseFilters, ...regularPriceFilters];
  const handleSubmit = () => {
    update({
      variables: {
        filters,
        data: {
          usePercent: regularType === '%',
          regularPriceOffset: regularPriceMod,
        },
      },
    });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{'Set Bulk Vehicle Pricing'}</DialogTitle>
      <DialogContent>
        {updateStatus.error && (
          <Alert severity="warning">
            <ErrorDisplay
              error={updateStatus.error}
              action="Updating Vehicles' Pricing"
            />
          </Alert>
        )}

        {checkingForRegPrice && (
          <>
            <CircularProgress size={20} />
            Verifying selected vehicles' regular prices.
          </>
        )}
        {vehiclesNoRegPrice.length > 0 && (
          <Alert severity="warning">
            The following vehicles are missing a regular price and will not be
            updated. Stock numbers:{' '}
            {map(vehiclesNoRegPrice, 'stockNumber').join(', ')}
          </Alert>
        )}

        <b>Update Regular Price:</b>
        <Grid container style={{ paddingBottom: '1rem', alignItems: 'center' }}>
          <Grid item>
            <RadioGroup value={regularSign} onChange={handleChangeRegularSign}>
              <FormControlLabel
                control={<Radio color="secondary" />}
                value={1}
                label="Increase"
              />
              <FormControlLabel
                control={<Radio color="secondary" />}
                value={0}
                label="Don't Change"
              />
              <FormControlLabel
                control={<Radio color="secondary" />}
                value={-1}
                label="Decrease"
              />
            </RadioGroup>
          </Grid>
          <Grid
            item
            style={{
              paddingRight: '2rem',
              paddingLeft: '2rem',
            }}
          >
            <TextField
              disabled={regularSign === 0}
              label="Amount"
              type="number"
              onChange={e => setRegularAmount(parseFloat(e.target.value))}
              InputProps={{
                startAdornment:
                  regularType === '$' ? (
                    <InputAdornment position="start">
                      {regularType}
                    </InputAdornment>
                  ) : (
                    <InputAdornment position="start"></InputAdornment>
                  ),
                endAdornment:
                  regularType === '%' ? (
                    <InputAdornment position="end">
                      {regularType}
                    </InputAdornment>
                  ) : (
                    <InputAdornment position="end"></InputAdornment>
                  ),
              }}
              style={{ width: '175px' }}
            />
          </Grid>
          <Grid item>
            <RadioGroup value={regularType} onChange={handleChangeRegularType}>
              <FormControlLabel
                control={<Radio color="secondary" />}
                disabled={regularSign === 0}
                value="$"
                label="$"
              />
              <FormControlLabel
                control={<Radio color="secondary" />}
                disabled={regularSign === 0}
                value="%"
                label="%"
              />
            </RadioGroup>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" color="primary">
          Cancel
        </Button>
        {updateStatus.loading ? (
          <CircularProgress
            size={20}
            style={{ marginLeft: '40px', marginRight: '30px' }}
          />
        ) : (
          <Button
            autoFocus
            onClick={handleSubmit}
            disabled={regularSign === 0}
            variant="contained"
            style={{
              backgroundColor: `${regularSign === 0 ? '#bfbebe' : '#74B72E'}`,
            }}
          >
            Submit
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default BulkVehiclePricing;
