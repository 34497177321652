import { isEmpty } from 'lodash';

export const parseErrors = ({ graphQLErrors, networkError, message }) => {
  let returnErrors = [];
  if (!isEmpty(graphQLErrors))
    graphQLErrors.forEach(({ message, location, path, extensions }) => {
      if (extensions.response?.body) {
        const {
          body: { code, message: bodyMessage },
          status,
        } = extensions.response;
        returnErrors.push(
          `Error code: ${code}(${status}): ${bodyMessage ?? message}`,
        );
      } else if (message) {
        returnErrors.push(message);
      }
    });
  else if (!isEmpty(networkError))
    returnErrors.push(networkError.result?.errors.map(x => x.message));
  else if (!isEmpty(message)) returnErrors.push(message);
  else returnErrors.push('An unknown error occured.');
  return returnErrors.join(', ');
};
