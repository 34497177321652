import React, { useEffect } from 'react';
import { createStore } from 'state-pool';
import { withRouter } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import AppBar from '@mui/material/AppBar';
import ToolBar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Hidden from '@mui/material/Hidden';
import { Link } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useUserContext } from 'components/MaterialUI/UserContext';
import { Button } from '@mui/material';
import ConfirmDialog from 'components/MaterialUI/ConfirmDialog';
import ZendeskButton from './ZendeskButton';

// Why is this global state stuff necessary?
// Whenever the route changes, the navbar component gets unmounted and remounted, meaning the state gets reset,
// causing any state corresponding to the current version to be reset and effects with an empty dependency array to be re-run.
// As an alternative, this state could be placed in a higher level component (like App.jsx) and passed down as context.
// However, whenever the App.jsx state changes it seems to cause graphql queries
// corresponding to the current view to be re-run, which is not ideal.
// This global state persists the state of the navbar between unmounts and remounts.
// It's a bit of a hack, but it seems to work.
const store = createStore();
store.setState('manifestConfigured', false);
store.setState('isNewVersion', false);
store.setState('showReloadModal', false);

const NavBar = props => {
  const { title, onMenuClick } = props;
  const { currentUser } = useUserContext();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const nameTitle = currentUser
    ? `${currentUser.display_name} (${currentUser.username})`
    : 'Unknown User';

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const notificationsBadgeContent = null; // TODO: Set this to number of unread notifications

  const [manifestConfigured, setManifestConfigured] =
    store.useState('manifestConfigured');
  const [isNewVersion, setIsNewVersion] = store.useState('isNewVersion');
  const [showReloadModal, setShowReloadModal] =
    store.useState('showReloadModal');

  const setupManifestPolling = async () => {
    setManifestConfigured(true);
    const getVersion = () =>
      fetch('/manifest.json', {
        headers: {
          'Cache-Control': 'no-cache',
        },
      })
        .then(data => data.json())
        .then(data => data?.version)
        .catch(() => null);

    let version = await getVersion();

    let intervalId = setInterval(() => {
      getVersion().then(newVersion => {
        if (newVersion && newVersion !== version) {
          clearInterval(intervalId);
          setShowReloadModal(true);
          setIsNewVersion(true);
        }
      });
    }, 1000 * 60 * 5); // check every 5 minutes
  };

  useEffect(() => {
    if (!manifestConfigured) {
      setupManifestPolling();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const reloadFunc = () => {
    window.location.reload();
  };

  const notificationText =
    'A new version of Market has been released. Please reload the page.';

  const alert = (
    <Alert
      severity="warning"
      action={
        <Button color="inherit" size="small" onClick={reloadFunc}>
          RELOAD
        </Button>
      }
    >
      {notificationText}
    </Alert>
  );

  return (
    <>
      <ConfirmDialog
        text={notificationText}
        onConfirm={reloadFunc}
        confirmText="Reload"
        abortText="Later"
        isOpen={showReloadModal}
        onClose={() => setShowReloadModal(false)}
      />
      <AppBar position="fixed">
        <ToolBar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={onMenuClick}
            size="large"
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6">{title || 'Market'}</Typography>
          <div style={{ flexGrow: 1 }}></div>
          {!mobile && isNewVersion && alert}
          <div style={{ flexGrow: 1 }}></div>
          <Hidden lgDown>
            <Typography variant="subtitle1">{nameTitle}</Typography>
          </Hidden>
          <IconButton color="inherit" size="large">
            <Badge
              badgeContent={notificationsBadgeContent}
              color="secondary"
              overlap="rectangular"
            >
              <NotificationsIcon
                onClick={() => {
                  window.open('https://accounts.goauto.io/notifications');
                }}
              />
            </Badge>
          </IconButton>
          <Tooltip title={nameTitle} arrow>
            <IconButton color="inherit" onClick={handleMenu} size="large">
              <AccountCircle />
            </IconButton>
          </Tooltip>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={open}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                window.open('https://accounts.goauto.io/account/settings');
              }}
            >
              Profile
            </MenuItem>{' '}
            {/* Remove ability to click until Profile feature is complete */}
            <MenuItem onClick={handleClose} component={Link} to="/logout">
              Sign out
            </MenuItem>
          </Menu>
          {process.env.REACT_APP_STAGING && (
            <Alert severity="warning" variant="filled">
              Staging Environment
            </Alert>
          )}
          <ZendeskButton />
        </ToolBar>
        {mobile && isNewVersion && alert}
      </AppBar>
    </>
  );
};

export default withRouter(NavBar);
