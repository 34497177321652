import React from 'react';

import { makeStyles } from '@mui/styles';
import { Button } from '@mui/material';

const useStyles = makeStyles(theme => ({
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
}));

const StepActions = ({
  backButtonProps = {},
  onNext,
  onBack,
  nextButtonProps = {},
  nextLabel = 'Next',
  backLabel = 'Back',
}) => {
  const classes = useStyles();

  return (
    <div className={classes.actionsContainer}>
      <div>
        <Button
          disabled={!onBack}
          onClick={onBack}
          className={classes.button}
          {...backButtonProps}
        >
          {backLabel}
        </Button>
        <Button
          disabled={!onNext}
          variant="contained"
          color="primary"
          onClick={onNext}
          className={classes.button}
          {...nextButtonProps}
        >
          {nextLabel}
        </Button>
      </div>
    </div>
  );
};

export default StepActions;
