import React from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FormAccordion = ({
  title,
  object,
  valueGroup,
  handleGroup,
  CheckboxGroup,
}) => {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>
          <b>{title}</b>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container alignItems="center" spacing={1}>
          {Object.entries(object).map(([name, display_name]) => {
            return (
              <React.Fragment key={name}>
                <Grid
                  item
                  xs={4}
                  style={{ paddingLeft: '1rem', paddingBottom: '.5rem' }}
                >
                  {display_name}
                </Grid>
                <Grid item xs={8}>
                  <CheckboxGroup
                    valueGroup={valueGroup}
                    handleGroup={handleGroup}
                    name={name}
                  />
                </Grid>
              </React.Fragment>
            );
          })}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default FormAccordion;
