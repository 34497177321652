/* external */
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';

/* material ui */
import { useMediaQuery, Box, Tab, Tabs, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { TabContext, TabPanel } from '@mui/lab';

/* internal */
import { formatPrice } from 'utils';

const useStyles = makeStyles({
  pricingTotals: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  responsivePricingTotals: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '15px',
  },
});

const PricingPurchaseOptions = ({ vehicle }) => {
  const { watch } = useFormContext();
  const [tabIndex, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const classes = useStyles();

  const mobile = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const mobileSm = useMediaQuery('(max-width:450px)');
  const mdBreakPoint = useMediaQuery('(max-width:800px)');

  const regularPrice = watch('regularPrice');
  const defaultDownPayment = watch('defaultDownPayment');

  return (
    <Box
      style={{
        backgroundColor: '#EAE9E9',
        padding: '15px',
        margin: '15px 10px',
      }}
    >
      <Box
        style={{
          backgroundColor: 'white',
        }}
      >
        <TabContext value={tabIndex} name="paymentOptionTabs">
          <Box style={{ backgroundColor: 'white' }}>
            <Tabs
              TabIndicatorProps={{ style: { background: '#ff5a00' } }}
              textColor="secondary"
              value={tabIndex}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons={`${mobileSm ? 'true' : 'false'}`}
            >
              <Tab
                label="Cash"
                value={0}
                style={{
                  minWidth: '50%',
                }}
              />
              <Tab
                label="Finance"
                value={1}
                style={{
                  minWidth: '50%',
                }}
              />
            </Tabs>
          </Box>
          {/* ***** Cash Panel ***** */}
          <TabPanel value={0} name="cashPanel">
            <Box
              className={
                mdBreakPoint
                  ? classes.responsivePricingTotals
                  : classes.pricingTotals
              }
            >
              <Box
                style={{
                  marginRight: `${mdBreakPoint ? '10px' : '35px'}`,
                }}
              >
                {regularPrice !== vehicle.specialPrice &&
                  vehicle.isOnSpecial && (
                    <Typography style={{ textDecoration: 'line-through' }}>
                      {formatPrice(regularPrice)}
                    </Typography>
                  )}
                <Typography
                  style={{
                    fontWeight: 'bolder',
                    fontSize: `${mobileSm ? '1.5rem' : ''}`,
                  }}
                  variant="h4"
                >
                  {formatPrice(
                    vehicle.isOnSpecial ? vehicle.specialPrice : regularPrice,
                  )}
                </Typography>
              </Box>

              <Box
                component="span"
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  flexDirection: 'column',
                  rowGap: '10px',
                  minWidth: `${mobile ? '200px' : ''}`,
                }}
              >
                <Box className={classes.pricingTotals}>
                  <Typography
                    style={{ fontWeight: 'bold', marginRight: '10px' }}
                    variant="body2"
                  >
                    Vehicle Price
                  </Typography>
                  <Typography variant="body2">
                    {formatPrice(regularPrice)}
                  </Typography>
                </Box>
                <Box className={classes.pricingTotals}>
                  <Typography
                    style={{ fontWeight: 'bold', marginRight: '10px' }}
                    variant="body2"
                  >
                    Add-Ons
                  </Typography>
                  <Typography variant="body2">
                    {formatPrice(vehicle.specialPriceCashAddOns)}
                  </Typography>
                </Box>
                <Box className={classes.pricingTotals}>
                  <Typography
                    style={{ fontWeight: 'bold', marginRight: '10px' }}
                    variant="body2"
                  >
                    Discounts
                  </Typography>
                  <Typography variant="body2">
                    ({formatPrice(vehicle.specialPriceCashDiscounts)})
                  </Typography>
                </Box>
                <Box className={classes.pricingTotals}>
                  <Typography
                    style={{
                      fontWeight: 'bold',
                      marginRight: '10px',
                      maxWidth: '70%',
                    }}
                    variant="body2"
                  >
                    Default Down Payment
                  </Typography>

                  <Typography variant="body2">
                    {formatPrice(defaultDownPayment)}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </TabPanel>

          {/* ***** Finance Panel ***** */}
          <TabPanel value={1} name="financePanel">
            <Box
              className={
                mdBreakPoint
                  ? classes.responsivePricingTotals
                  : classes.pricingTotals
              }
            >
              <Box
                style={{
                  marginRight: `${mdBreakPoint ? '10px' : '35px'}`,
                }}
              >
                {vehicle?.biWeeklyFinancePayment !== Infinity && (
                  <>
                    <Typography style={{ marginBottom: '15px' }}>
                      from
                    </Typography>
                    <Typography
                      style={{ fontWeight: 'bolder', lineHeight: '1rem' }}
                      variant="h4"
                    >
                      {formatPrice(vehicle.biWeeklyFinancePayment)}
                      <Box component="span" style={{ fontSize: '40%' }}>
                        {!mdBreakPoint && <br />}
                        &nbsp; /bi-weekly*
                      </Box>
                      <Box component="span" style={{ fontSize: '20%' }}>
                        {!mdBreakPoint && <br />}
                        *Based on an interest rate of 7.99% for 240 months
                      </Box>
                      <Box component="span" style={{ fontSize: '20%' }}>
                        {!mdBreakPoint && <br />}
                        Save vehicle for rate to re-calculate
                      </Box>
                    </Typography>
                  </>
                )}
                {vehicle?.biWeeklyFinancePayment === Infinity && (
                  <>
                    <Typography
                      style={{ fontWeight: 'bolder', marginBottom: '10px' }}
                      variant="h4"
                    >
                      N/A
                    </Typography>
                  </>
                )}
              </Box>

              <Box
                component="span"
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  flexDirection: 'column',
                  rowGap: '10px',
                  minWidth: `${mobile ? '200px' : ''}`,
                }}
              >
                <Box className={classes.pricingTotals}>
                  <Typography
                    variant="body2"
                    style={{ fontWeight: 'bold', marginRight: '10px' }}
                  >
                    Vehicle Price
                  </Typography>
                  <Typography variant="body2">
                    {formatPrice(regularPrice)}
                  </Typography>
                </Box>
                <Box className={classes.pricingTotals}>
                  <Typography
                    variant="body2"
                    style={{ fontWeight: 'bold', marginRight: '10px' }}
                  >
                    Add-Ons
                  </Typography>
                  <Typography variant="body2">
                    {formatPrice(vehicle.specialPriceFinanceAddOns)}
                  </Typography>
                </Box>
                <Box className={classes.pricingTotals}>
                  <Typography
                    variant="body2"
                    style={{ fontWeight: 'bold', marginRight: '10px' }}
                  >
                    Discounts
                  </Typography>
                  <Typography variant="body2">
                    ({formatPrice(vehicle.specialPriceFinanceDiscounts)})
                  </Typography>
                </Box>
                <Box className={classes.pricingTotals}>
                  <Typography
                    variant="body2"
                    style={{
                      maxWidth: '70%',
                      marginRight: '10px',
                      fontWeight: 'bold',
                    }}
                  >
                    Default Down Payment
                  </Typography>
                  <Typography variant="body2">
                    {formatPrice(defaultDownPayment)}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </TabPanel>
        </TabContext>
      </Box>
    </Box>
  );
};

export default PricingPurchaseOptions;
