import React, { useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import gql from 'graphql-tag';
import { useForm } from 'react-hook-form';
import { mapValues } from 'lodash';

/* Material UI */
import { Box, Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import TextFieldControl from 'components/MaterialUI/TextFieldControl';
import { useDealerContext } from 'components/MaterialUI/DealerContext';

const UPDATE_USED_BOS_SETTINGS = gql`
  mutation updateUsedBosSettings($dealer_id: Int!, $input: BosSettingsInput!) {
    updateUsedBosSettings(dealer_id: $dealer_id, input: $input) {
      bos_settings_new {
        admin_fee
        anti_theft_transfer_fee
        extended_service_contract_fee
        fed_air_tax
        federal_luxury_tax
        protection_package_fee
        tire_protection_fee
        tire_recycling_fee
      }
      dealer_id
    }
  }
`;

const UPDATE_NEW_BOS_SETTINGS = gql`
  mutation updateNewBosSettings($dealer_id: Int!, $input: BosSettingsInput!) {
    updateNewBosSettings(dealer_id: $dealer_id, input: $input) {
      bos_settings_new {
        admin_fee
        anti_theft_transfer_fee
        extended_service_contract_fee
        fed_air_tax
        federal_luxury_tax
        protection_package_fee
        tire_protection_fee
        tire_recycling_fee
      }
      dealer_id
    }
  }
`;

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  textField: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: '190px',
      marginBottom: '20px',
      paddingRight: '40px',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: '10px',
      paddingRight: '20px',
    },
  },
  fieldGroupContainer: {
    display: 'flex',
    flexFlow: 'column wrap',
  },
}));

const BosSettings = ({ used_bos_settings, new_bos_settings, refetch }) => {
  const { dealerId } = useDealerContext();
  const { enqueueSnackbar } = useSnackbar();
  const { __typename, ...usedBos } = used_bos_settings;
  const { __typename: __typename2, ...newBos } = new_bos_settings;
  const {
    control,
    handleSubmit,
    formState: { isDirty },
    reset,
  } = useForm({
    defaultValues: {
      used: usedBos,
      new: newBos,
    },
  });

  // adding in all dependencies creates an infinite loop which crashes the page
  // including 'eslint' gets rid of the missing dependencies warning in the console
  useEffect(() => {
    reset({ used: usedBos, new: newBos });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [used_bos_settings, new_bos_settings, reset]);

  const [updateUsedBosSettings] = useMutation(UPDATE_USED_BOS_SETTINGS);
  const [updateNewBosSettings] = useMutation(UPDATE_NEW_BOS_SETTINGS);

  const classes = useStyles();

  const onSubmit = ({ used: bos_settings_used, new: bos_settings_new }) => {
    updateUsedBosSettings({
      variables: {
        dealer_id: dealerId,
        input: mapValues(bos_settings_used, val =>
          val === null ? val : String(val),
        ), // convert all values to strings
      },
    })
      .then(() =>
        updateNewBosSettings({
          variables: {
            dealer_id: dealerId,
            input: mapValues(bos_settings_new, val =>
              val === null ? val : String(val),
            ), // convert all values to strings
          },
        }),
      )
      .catch(e => {
        enqueueSnackbar(`Could not update bill of sale settings, error: ${e}`, {
          variant: 'error',
        });
      })
      .then(request => {
        if (request) {
          enqueueSnackbar('Bill of sale settings updated!', {
            variant: 'success',
          });
          refetch();
        }
      });
  };

  return (
    <Box>
      <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
        <Box display="flex">
          <Box className={classes.fieldGroupContainer}>
            <Typography
              style={{
                fontSize: '16px',
                fontWeight: 'bold',
                marginBottom: '20px',
              }}
            >
              Used
            </Typography>
            <Box>
              <TextFieldControl
                control={control}
                name="used.tire_recycling_fee"
                variant="currency"
                label="Tire Recycling Fee"
                className={classes.textField}
              />
              <TextFieldControl
                control={control}
                name="used.fed_air_tax"
                variant="currency"
                label="FED Air Tax"
                className={classes.textField}
              />
              <TextFieldControl
                control={control}
                name="used.protection_package_fee"
                variant="currency"
                label="Protection Package"
                className={classes.textField}
              />
              <TextFieldControl
                control={control}
                name="used.extended_service_contract_fee"
                variant="currency"
                label="Extended Service Contract"
                className={classes.textField}
              />
              <TextFieldControl
                control={control}
                name="used.anti_theft_transfer_fee"
                variant="currency"
                label="Anti-Theft Transfer"
                className={classes.textField}
              />
              <TextFieldControl
                control={control}
                name="used.tire_protection_fee"
                variant="currency"
                label="Tire/Rim Protection"
                className={classes.textField}
              />
              <TextFieldControl
                control={control}
                name="used.federal_luxury_tax"
                variant="currency"
                label="Federal Luxury Tax"
                className={classes.textField}
              />
            </Box>
          </Box>
          <Box
            style={{ marginBottom: '10px' }}
            className={classes.fieldGroupContainer}
          >
            <Typography
              style={{
                fontSize: '16px',
                fontWeight: 'bold',
                marginBottom: '20px',
              }}
            >
              New
            </Typography>
            <Box>
              <TextFieldControl
                control={control}
                name="new.tire_recycling_fee"
                variant="currency"
                label="Tire Recycling Fee"
                className={classes.textField}
              />
              <TextFieldControl
                control={control}
                name="new.fed_air_tax"
                variant="currency"
                label="FED Air Tax"
                className={classes.textField}
              />
              <TextFieldControl
                control={control}
                name="new.protection_package_fee"
                variant="currency"
                label="Protection Package"
                className={classes.textField}
              />
              <TextFieldControl
                control={control}
                name="new.extended_service_contract_fee"
                variant="currency"
                label="Extended Service Contract"
                className={classes.textField}
              />
              <TextFieldControl
                control={control}
                name="new.anti_theft_transfer_fee"
                variant="currency"
                label="Anti-Theft Transfer"
                className={classes.textField}
              />
              <TextFieldControl
                control={control}
                name="new.tire_protection_fee"
                variant="currency"
                label="Tire/Rim Protection"
                className={classes.textField}
              />
              <TextFieldControl
                control={control}
                name="new.federal_luxury_tax"
                variant="currency"
                label="Federal Luxury Tax"
                className={classes.textField}
              />
            </Box>
          </Box>
        </Box>
        <Box>
          <Button
            disabled={!isDirty}
            type="submit"
            style={{
              backgroundColor: `${!isDirty ? '#bfbebe' : '#74B72E'}`,
              width: '150px',
            }}
            variant="contained"
          >
            Save
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default BosSettings;
