import gql from 'graphql-tag';
import React, { useState, useMemo } from 'react';

import {
  Paper,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  FormControlLabel,
  Radio,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import UsedPricingToolTableRow from './UsedPricingToolTableRow';

const useStyles = makeStyles(theme => ({
  header: {
    '& th': {
      borderBottom: `2px solid ${theme.palette.secondary.main}`,
      fontWeight: 'bold',
    },
  },
  altColorRow: {
    '&>tr:nth-of-type(4n)': {
      backgroundColor: 'white',
    },
    '&>tr:nth-of-type(4n+1)': {
      backgroundColor: 'lightGrey',
    },
  },
}));

const UsedPricingToolVehicles = ({
  clickSortHandler,
  listings,
  orderBy,
  recordCount,
  organization,
  myVin,
  ...rest
}) => {
  const classes = useStyles();

  const [selectedType, setSelectedType] = useState('Active');

  const filteredListings = useMemo(
    () =>
      listings.filter(
        listing =>
          selectedType === 'All' || listing.listing_type === selectedType,
      ),
    [selectedType, listings],
  );

  return (
    <Paper
      {...rest}
      style={{ margin: '16px 0', padding: '16px' }}
      elevation={4}
    >
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        Vehicles ({recordCount})
      </Typography>
      <RadioGroup
        row
        value={selectedType}
        onChange={e => setSelectedType(e.target.value)}
      >
        <FormControlLabel
          value="Active"
          control={<Radio />}
          label="Active Vehicles"
        />
        <FormControlLabel
          value="Sold"
          control={<Radio />}
          label="Sold Vehicles"
        />
        <FormControlLabel
          value="All"
          control={<Radio />}
          label="All Vehicles"
        />
      </RadioGroup>
      <Table>
        <TableHead className={classes.header}>
          <TableRow>
            <TableCell>
              <TableSortLabel
                active={orderBy.column === 'SERIES'}
                direction={orderBy.direction.toLowerCase()}
                onClick={clickSortHandler('SERIES')}
              >
                Vehicle
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy.column === 'PRICE'}
                direction={orderBy.direction.toLowerCase()}
                onClick={clickSortHandler('PRICE')}
              >
                Price
              </TableSortLabel>
            </TableCell>
            <TableCell>Odometer</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Dealer</TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy.column === 'DISTANCE_TO_DEALER'}
                direction={orderBy.direction.toLowerCase()}
                onClick={clickSortHandler('DISTANCE_TO_DEALER')}
              >
                Distance
              </TableSortLabel>
            </TableCell>
            <TableCell />
            {/* for expand-y arrow thing */}
          </TableRow>
        </TableHead>
        <TableBody className={classes.altColorRow}>
          {/* Sometimes the cbb api returns duplicate listing_ids.  Not sure why.
          We ran into it searching with this VIN: 3C3CFFHH5DT746241 */}
          {filteredListings.map((listing, index) => (
            <UsedPricingToolTableRow
              key={`${listing.listing_id}${index}`}
              listing={listing}
              organization={organization}
              myVin={myVin}
            />
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

UsedPricingToolVehicles.fragments = {
  listings: gql`
    fragment UsedPricingToolVehiclesListingsResult on ListingType {
      listing_id
      ...UsedPricingToolTableRowListingType
    }
    ${UsedPricingToolTableRow.fragments.listing}
  `,
  organization: gql`
    fragment UsedPricingToolVehiclesOrganization on Organization {
      ...UsedPricingToolTableRowOrganization
    }
    ${UsedPricingToolTableRow.fragments.organization}
  `,
};
export default UsedPricingToolVehicles;
