import React from 'react';

import PropTypes from 'prop-types';

import { makeStyles } from '@mui/styles';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';

import { useGroupVehiclesQueryContext } from '../contexts/GroupVehiclesQueryContext';

const useStyles = makeStyles(theme => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const headCells = [
  { label: 'Vehicle', id: 'displayName', numeric: false },
  { label: 'Stock #', id: 'stock_number', numeric: false },
  { label: 'Price', id: 'list_price', numeric: false },
  { label: 'Cost', id: 'cost', numeric: false },
  { label: 'Dealer', id: 'dealer_name', numeric: false },
  { label: 'Kilometers', id: 'odometer', numeric: false },
  { label: 'Color', id: 'exterior_colour_name', numeric: false },
  { label: 'Days', id: 'days_in_stock', numeric: false },
  { label: 'Group Age', id: 'group_age', numeric: false },
];

const propTypes = {
  showCost: PropTypes.bool,
};

const defaultProps = {
  showCost: false,
};

function GroupInventoryHeader({ showCost }) {
  const classes = useStyles();
  const { order, orderBy, setOrder, setOrderBy } =
    useGroupVehiclesQueryContext();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const displayHeadCell = headCell =>
    (headCell.id === 'cost' && showCost) || headCell.id !== 'cost';

  return (
    <TableHead>
      <TableRow>
        {headCells.map(
          headCell =>
            displayHeadCell(headCell) && (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? 'right' : 'left'}
                padding={headCell.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={event => handleRequestSort(event, headCell.id)}
                  disabled={headCell.label === 'Actions'}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === 'desc'
                        ? 'sorted descending'
                        : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            ),
        )}
      </TableRow>
    </TableHead>
  );
}

GroupInventoryHeader.propTypes = propTypes;
GroupInventoryHeader.defaultProps = defaultProps;

export default GroupInventoryHeader;
