// A collection of utilities for auctions.
import { isEmpty, omitBy } from 'lodash';

export const isAuctionStale = (auction, clock, includeCompleted = true) => {
  const { active, can_add, can_bid, can_blind_bid, weekly } = auction;

  const { next_add, last_end, next_start, next_blind } = weekly || {};

  if (weekly && active) {
    if (!can_add && !can_blind_bid && !can_bid && clock.isAfter(next_add + 'Z'))
      return true;

    if (!can_blind_bid && !can_bid && clock.isAfter(next_blind + 'Z'))
      return true;

    if (!can_bid && clock.isAfter(next_start + 'Z')) return true;

    if (can_bid && clock.isAfter(last_end + 'Z') && includeCompleted)
      return true;
  }
  return false;
};

// TODO: MPAUC-94: This should parse responses that are not modified in market-graph
// (previously, we had modified some error responses)
// If this works well (have not completely tested yet, especially with 422 messages(
// schema errors from marshmallow
// )) then we can move this higher up in the hierarchy and modify the existing
// connectors in graph to _also_ not modify their responses
const parseErrors = ({ graphQLErrors, networkError, message }) => {
  let returnErrors = {};
  if (!isEmpty(graphQLErrors))
    graphQLErrors.forEach(({ message, location, path, extensions }) => {
      if (extensions.json) {
        returnErrors = Object.assign({}, returnErrors, extensions.json);
      } else if (extensions.response?.body?.errors?.json) {
        returnErrors = Object.assign(
          {},
          returnErrors,
          extensions.response.body.errors.json,
        );
        // Resolving nested errors to . notation (NOTE: there's probably a fancy
        // lodash way to do this without having to explicitly go through the fields,
        // but there are only two, so NBD)
        if (returnErrors.weekly) {
          Object.keys(returnErrors.weekly).forEach(
            k => (returnErrors[`weekly.${k}`] = returnErrors.weekly[k]),
          );
          delete returnErrors.weekly;
        }
        if (returnErrors.daily) {
          Object.keys(returnErrors.daily).forEach(
            k => (returnErrors[`daily.${k}`] = returnErrors.daily[k]),
          );
          delete returnErrors.daily;
        }
        if (returnErrors.vehicle) {
          Object.keys(returnErrors.vehicle).forEach(
            k => (returnErrors[`vehicle.${k}`] = returnErrors.vehicle[k]),
          );
          delete returnErrors.vehicle;
        }
      } else if (extensions.response?.body?.message) {
        returnErrors.error = [extensions.response.body.message];
      } else if (message) {
        returnErrors.error = [
          message + ' ' + (extensions?.response?.body?.errors?.join(',') || ''),
        ];
      }
    });
  else if (!isEmpty(networkError))
    returnErrors.error = networkError.result?.errors.map(x => x.message);
  else if (!isEmpty(message)) returnErrors.error = [message];
  else returnErrors.error = ['An unknown error occured.'];
  return returnErrors;
};

export const errorHandler =
  (enqueueSnackbar, setError, options = {}) =>
  err => {
    const { error, ...rest } = parseErrors(err);
    if (options.snackAll) {
      // send all errors to snackbar, even "form" type errors
      // TODO: stringifying the "rest" is not exactly the best, but it'll do for now
      enqueueSnackbar(error?.join('\n') ?? '' + JSON.stringify(rest), {
        variant: 'error',
      });
    } else {
      if (error)
        enqueueSnackbar(error.join('\n'), {
          variant: 'error',
        });
    }
    Object.entries(rest).forEach(([k, v]) =>
      setError(k, { message: v.join(',') }),
    );
    if (error) setError('error', { message: error.join(',') });
  };

const noNulls = _obj => omitBy(_obj, v => v === null);

// converts a vehicle returned from grease to a vehicle suitable for input
// in auctions.  Eliminates fields with null values.
export const greaseVehicleToAuctionsVehicle = vehicle =>
  noNulls({
    acode: vehicle.acode,
    cost: vehicle.cost,
    daysInStock: vehicle.days_in_stock,
    dealerId: vehicle.dealer_id,
    driveType: vehicle.drive_type_name,
    engineCompressor: vehicle.engine_compressor_name,
    engineConfig: vehicle.engine_config_name,
    engineCylinders: vehicle.engine_cylinders,
    engineLitres: vehicle.engine_litres,
    exteriorColourName: vehicle.exterior_colour_name,
    fuelType: vehicle.fuel_type_name,
    interiorColourName: vehicle.interior_colour_name,
    itemKey: vehicle.item_key,
    make: vehicle.make_name,
    model: vehicle.model_name,
    notes: vehicle.published_notes,
    odometer: vehicle.odometer,
    photos: vehicle.photos?.map(x => ({
      cloudinaryPublicId: x.cloudinary_public_id,
    })),
    regularPrice: vehicle.regular_price,
    sendingService: 'inventory',
    sendingServiceId: vehicle.id,
    stockNumber: vehicle.stock_number,
    transmission: vehicle.transmission_name,
    trim: vehicle.trim,
    upholsteryName: vehicle.upholstery_name,
    vin: vehicle.vin,
    year: vehicle.year,
  });
