import React from 'react';
import moment from 'moment';
import { makeStyles } from '@mui/styles';

import CardContent from '@mui/material/CardContent';
import DateRangeIcon from '@mui/icons-material/DateRange';
import Grid from '@mui/material/Grid';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

import { getUpdatedDate } from '../utils';
import { EVENT_TITLES } from '../constant';
import gql from 'graphql-tag';
import { defaultTZ } from 'utils';

const useStyles = makeStyles(theme => ({
  details: {
    whiteSpace: 'pre-line',
    fontSize: '.75rem',
  },
  date: {
    minWidth: '75px',
    paddingTop: '25px',
  },
}));

const CommunicationCard = ({ comm, customerName }) => {
  const classes = useStyles();

  const date = comm?.timestamp || comm.date_created;
  const sentTo = comm?.email || comm.channel_number;
  const event = comm?.event || comm.channel_type;
  const fromFlex = comm?.date_created ? true : false;

  // Reverse messages so most recent is first
  const messages = comm.messages ? comm.messages.slice().reverse() : [];
  const updated = getUpdatedDate(fromFlex, messages);

  const updatedDate = updated ?? date;

  return (
    <CardContent>
      <Grid
        container
        direction="row"
        justifyContent={'flex-start'}
        alignItems="flex-start"
        spacing={2}
      >
        <Grid item xs={1}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
          >
            <Grid item>
              <DateRangeIcon />
            </Grid>
            <Grid item>
              <ListItemText
                primaryTypographyProps={{
                  style: { fontSize: 12 },
                }}
                secondaryTypographyProps={{
                  style: { fontSize: 12 },
                }}
                primary={moment(updatedDate).tz(defaultTZ).format('MMM DD')}
                secondary={moment(updatedDate).tz(defaultTZ).format('YYYY')}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="h6">{EVENT_TITLES[event]}</Typography>
          <Typography variant="body2" style={{ fontSize: '.75rem' }}>
            {`Sent to: ${sentTo}`}
          </Typography>
          <Typography variant="body2" style={{ fontSize: '.75rem' }}>
            {`Started Date: ${moment(date).tz(defaultTZ).format('MMM Do YY')}`}
          </Typography>
        </Grid>
        <Grid item xs={9}>
          {!fromFlex && <h5>Details:</h5>}
          {comm?.integration_name === 'Market SMS' && (
            <Typography
              variant="body2"
              component="p"
              style={{ fontSize: '.75rem' }}
            >
              {comm.subject}
            </Typography>
          )}
          {comm?.integration_name === 'Iterable' && (
            <Typography
              variant="body2"
              component="p"
              className={classes.details}
            >
              {`Campaign: ${comm.campaign_name}
                Integration: ${comm.integration_name}  `}
            </Typography>
          )}
          <Grid container spacing={1}>
            {/* Conversations are reversed to have most recent shown at top */}
            {messages &&
              messages.map(message => (
                <Grid container key={message.index}>
                  <Grid item xs={2}>
                    <Typography variant="body2">
                      <b>Author: </b>{' '}
                      {message?.from_
                        ? message.from_.startsWith('CH')
                          ? comm.channel_number
                          : customerName
                        : 'N/A'}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <b>Date Sent: </b>{' '}
                    {moment(message.date_created)
                      .tz(defaultTZ)
                      .format('MMM Do YY, h:mm')}
                  </Grid>
                  <Grid item xs={12} style={{ padding: '5px 0px' }}>
                    {message.body}
                  </Grid>
                </Grid>
              ))}
          </Grid>
        </Grid>
      </Grid>
    </CardContent>
  );
};

CommunicationCard.fragments = {
  conversationHistory: gql`
    fragment CommunicationCardConversationHistory on ConversationHistory {
      date_created
      channel_number
      channel_type
      channel_number
      messages {
        date_created
        index
        from_
        body
      }
    }
  `,
  communicationHistory: gql`
    fragment CommunicationCardCommunicationHistory on CommunicationHistory {
      timestamp
      email
      event
      integration_name
      subject
      campaign_name
    }
  `,
};

export default CommunicationCard;
