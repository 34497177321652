import React from 'react';

/* external */
import { Controller, useFormContext } from 'react-hook-form';

/* Material UI */
import { MenuItem, TextField } from '@mui/material';

/* For use with react-hook-form forms.  Will use control and errors from formContext
if available and not otherwise provided.

NOTE: MUST BE USED WITH FORMS USING RHF VERSION 7 AND UP!!!!!
 */
const SelectControl = ({
  children,
  control,
  defaultValue,
  errors,
  inputProps = {},
  MenuItemProps = {},
  name,
  noNull = false,
  nullDisplay = 'Unknown',
  options,
  optionValueKey = 'value',
  optionNameKey = 'name',
  rules,
  onChange: origOnChange = () => {},
  ...rest
}) => {
  const formContext = useFormContext();
  const { formState } = formContext ?? {};
  const _errors = errors ?? formState?.errors;

  const { disabled: menuItemDisabled, ...MenuItemPropsRest } = MenuItemProps;

  return (
    <Controller
      control={control ?? formContext.control}
      defaultValue={defaultValue ?? ''}
      name={name}
      render={({
        field: { ref, value, onChange, ...field },
        fieldState: { error },
      }) => (
        <TextField
          error={Boolean(_errors?.[name]) || Boolean(error)}
          helperText={_errors?.[name]?.message || error?.message}
          inputProps={inputProps}
          InputLabelProps={{ shrink: noNull ? undefined : true }}
          select
          // this causes the "input label" area to be an empty space when enabled
          // SelectProps={{ displayEmpty: true }}
          value={value ?? ''}
          onChange={e => {
            const fval =
              e.target.value === '' ? onChange(null) : onChange(e.target.value);
            origOnChange(e.target.value);
            return fval;
          }}
          {...field}
          {...rest}
        >
          {children}
          {!children && !noNull && <MenuItem value="">{nullDisplay}</MenuItem>}
          {!children &&
            options.map(option => (
              <MenuItem
                value={option[optionValueKey]}
                key={option[optionValueKey]}
                disabled={menuItemDisabled ? menuItemDisabled(option) : false}
                {...MenuItemPropsRest}
              >
                {option[optionNameKey]}
              </MenuItem>
            ))}
        </TextField>
      )}
      rules={rules}
    />
  );
};

export default SelectControl;
