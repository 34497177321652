import React from 'react';
import { isEmpty } from 'lodash';

// MaterialUI
import { Box, Button, Grid } from '@mui/material';

// internal
import {
  formatPrice,
  getMediumDate,
  getMediumDateTimeWithWeekday,
} from 'utils';
import { COLOUR_MATCH } from '../const';

const Accepted = ({ onClickPurchase, vehicle }) => {
  const { myBid, status, acceptedBid, winningBidAmount, appointment } = vehicle;
  const { customerAcceptedDate } = acceptedBid ?? {};
  const { appointmentSlot, date: apptDate, notes } = appointment ?? {};
  const { startsAt, endsAt, name: apptSlotName } = appointmentSlot ?? {};
  const { location } = appointmentSlot ?? {};
  const { name: locationName } = location ?? {};

  return (
    <>
      <Grid
        container
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingBottom: '1rem',
        }}
        direction="row"
        xs={12}
      >
        <Grid
          item
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
          xs={6}
        >
          <Box>Bidding has now ended.</Box>
        </Grid>
        <Grid
          item
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
          xs={6}
        >
          <Box style={{ color: 'grey' }}>
            Highest Bid: {formatPrice(winningBidAmount, { nullDisplay: '-' })}
          </Box>
          <Box style={{ color: 'grey' }}>
            Your Bid (
            {myBid?.isCustomerAccepted
              ? 'Accepted'
              : myBid?.isCustomerDeclined
              ? 'Declined'
              : '?'}
            ): {isEmpty(myBid) ? 'You Did Not Bid' : formatPrice(myBid.amount)}
          </Box>
        </Grid>
      </Grid>
      <Box
        style={{
          fontWeight: 'bold',
          paddingRight: '1rem',
          paddingBottom: '0.5rem',
        }}
      >
        CUSTOMER RESPONSE -{' '}
        <span style={{ color: COLOUR_MATCH[status].backgroundColor }}>
          {status}
        </span>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        pb="0.5rem"
        justifyContent="space-between"
      >
        <Box>
          <Box pr="0.5rem" fontWeight="bold">
            Accepted On:
          </Box>
          <Box>
            {customerAcceptedDate
              ? getMediumDateTimeWithWeekday(customerAcceptedDate + 'Z')
              : '-'}
          </Box>
        </Box>
        {appointment && (
          <Box>
            <Box pr="0.5rem" fontWeight="bold">
              Appointment:
            </Box>
            <Box>
              {locationName}: {apptSlotName}
            </Box>
            <Box>
              {apptDate && getMediumDate(apptDate)} {startsAt} - {endsAt}
            </Box>
            <Box>Notes: {notes}</Box>
          </Box>
        )}
        <Box textAlign="right">
          <Button
            variant="contained"
            style={{ backgroundColor: 'green', color: 'white' }}
            onClick={onClickPurchase}
          >
            Purchase
          </Button>
        </Box>
      </Box>
    </>
  );
};
export default Accepted;
