/* external */
import { startCase, toLower } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import InfoIcon from '@mui/icons-material/Info';

/* Material UI */
import Alert from '@mui/material/Alert';

import { getComparator, stableSort } from '../utils';
import OpportunitiesListHeaders from './OpportunitiesListHeader';
import OpportunityVehicleData from './OpportunityVehicleData';

/* internal */
import { AppointmentStatus, STATUS_ICON } from 'constants.js';

const useStyles = makeStyles(theme => ({
  mainTitle: {
    flexGrow: 1,
    fontWeight: 'bold',
    padding: theme.spacing(2),
  },
  details: {
    padding: '0px',
  },
  list: {
    paddingTop: '0px',
    paddingBottom: '0px',
  },
  assignees: {
    paddingLeft: '0px',
    paddingRight: '0px',
  },
}));

const OpportunitiesList = ({
  opps,
  showing,
  selected,
  setSelected,
  filters,
  updateFilters,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [order, setOrder] = useState(
    filters.sort_by[0].direction === 1 ? 'asc' : 'desc',
  );
  const [orderBy, setOrderBy] = useState('created');

  const isSelected = ({ _id }) => selected.includes(_id);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = e => {
    setSelected(e.target.checked ? opps.map(o => o._id) : []);
  };

  const handleClick = (e, { _id }) => {
    // Add the new id in the same order as displayed opps
    if (e.target.checked) {
      setSelected(prev =>
        opps.map(o => o._id).filter(x => [...prev, _id].includes(x)),
      );
    } else setSelected(prev => prev.filter(x => x !== _id));
  };

  if (!opps) {
    return 'No Opportunities Found';
  }

  return (
    <TableContainer>
      <Table>
        <OpportunitiesListHeaders
          classes={classes}
          numSelected={selected.length}
          order={order}
          orderBy={orderBy}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          rowCount={showing}
          filters={filters}
          updateFilters={updateFilters}
        />
        <TableBody>
          {stableSort(opps, getComparator(order, orderBy)).map(
            (opportunity, index) => {
              const isOppSelected = isSelected(opportunity);
              const appointmentsExcludingCancelled =
                opportunity.appointments.filter(
                  appointment =>
                    appointment.status !== AppointmentStatus.CANCELLED,
                );
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={opportunity._id}
                  selected={isOppSelected}
                >
                  {/* Bulk actions not implemented yet */}
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={isOppSelected}
                      onClick={event => handleClick(event, opportunity)}
                    />
                  </TableCell>
                  <TableCell>
                    {opportunity.customer ? (
                      <Tooltip
                        title={
                          'Click me to find out more about this opportunity!'
                        }
                      >
                        <IconButton
                          disabled={!opportunity.permissions.can_read_details}
                          onClick={e => e.stopPropagation()}
                          size="large"
                        >
                          <Link
                            to={`${opportunity._id}/${opportunity.customer._id}/details`}
                          >
                            <InfoIcon
                              style={{
                                color: opportunity.permissions.can_read_details
                                  ? '#2196f3'
                                  : theme.actions.disabled.backgroundColor,
                                fontSize: '22px',
                              }}
                            />
                          </Link>
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Alert severity="warning">
                        NO CUSTOMER ATTACHED! Contact market support.
                      </Alert>
                    )}
                  </TableCell>

                  <TableCell
                    style={{ whiteSpace: 'pre-line', textAlign: 'center' }}
                  >
                    <Icon
                      style={{
                        fontSize: '1rem',
                        color: STATUS_ICON[opportunity.status].color,
                      }}
                      className={STATUS_ICON[opportunity.status].icon}
                    />
                    {'\n'}
                    <Chip
                      size="small"
                      style={{
                        color: 'white',
                        backgroundColor:
                          opportunity.deal_type === 'Used'
                            ? STATUS_ICON[1].color
                            : opportunity.deal_type === 'New'
                            ? STATUS_ICON[0].color
                            : 'grey',
                      }}
                      label={opportunity.deal_type}
                    />
                  </TableCell>
                  <TableCell
                    style={{ whiteSpace: 'pre-line', maxWidth: '200px' }}
                  >
                    <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                      {opportunity?.customer?.fullname || 'Unknown Customer'}
                    </Typography>
                    <Typography variant="caption" color="textSecondary">
                      {`${opportunity.status_display} on ${moment(
                        opportunity.last_status_change[opportunity.status],
                      ).format('MMM Do, YYYY')}`}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      color="textSecondary"
                      style={{ fontSize: '.7rem' }}
                    >
                      {' '}
                      {opportunity.status_display === 'Lost' &&
                      opportunity.lost_reason
                        ? opportunity.lost_reason
                        : ''}
                    </Typography>
                    {'\n'}
                    <Typography variant="caption" color="textSecondary">
                      {opportunity?.sub_status || ''}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <OpportunityVehicleData opportunity={opportunity} />
                  </TableCell>
                  <TableCell>
                    <List
                      dense={true}
                      style={{ paddingTop: '0px', paddingBottom: '0px' }}
                    >
                      <ListItem className={classes.details}>
                        <ListItemAvatar>
                          <CheckBoxIcon />
                        </ListItemAvatar>
                        <Typography variant="body2">
                          {`Tasks (0/${opportunity.tasks.length})`}
                        </Typography>
                      </ListItem>
                      <ListItem className={classes.details}>
                        <ListItemAvatar>
                          <Icon
                            className="fas fa-file-invoice-dollar"
                            style={{ textAlign: 'center', fontSize: '1.4rem' }}
                          />
                        </ListItemAvatar>
                        <Typography variant="body2">
                          {`Credit App (${opportunity.credit_application_ids.length})`}
                        </Typography>
                      </ListItem>
                      <ListItem className={classes.details}>
                        <ListItemAvatar>
                          <DriveEtaIcon />
                        </ListItemAvatar>
                        <Typography variant="body2">
                          {`Trade In (${opportunity.appraisals?.length ?? 0})`}
                        </Typography>
                      </ListItem>
                    </List>
                  </TableCell>
                  <TableCell>
                    <List dense={true} className={classes.list}>
                      <ListItem>
                        <Typography
                          variant="body2"
                          style={{ fontSize: '.8rem' }}
                        >
                          <b>Channel:</b>{' '}
                          {`${startCase(
                            toLower(opportunity.marketing.lead_direction ?? ''),
                          )} ${startCase(
                            toLower(
                              opportunity.marketing.lead_channel ?? 'N/A',
                            ),
                          )}`}
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography
                          variant="body2"
                          style={{ fontSize: '.8rem' }}
                        >
                          <b>Source:</b>{' '}
                          {`${opportunity.marketing.lead_source ?? 'N/A'}`}
                        </Typography>
                      </ListItem>
                    </List>
                  </TableCell>
                  <TableCell>
                    <List dense={true} className={classes.List}>
                      <ListItem className={classes.assignees}>
                        <Typography
                          variant="body2"
                          style={{ fontSize: '.8rem' }}
                        >
                          {`SP: ${
                            opportunity?.sales_reps[0]?.display_name ?? ''
                          }`}
                        </Typography>
                      </ListItem>
                      <ListItem className={classes.assignees}>
                        <Typography
                          variant="body2"
                          style={{ fontSize: '.8rem' }}
                        >
                          {`BDC: ${
                            opportunity?.bdc_reps[0]?.display_name ?? ''
                          }`}
                        </Typography>
                      </ListItem>
                      <ListItem className={classes.assignees}>
                        <Typography
                          variant="body2"
                          style={{ fontSize: '.8rem' }}
                        >
                          {`SM: ${
                            opportunity?.sales_managers[0]?.display_name ?? ''
                          }`}
                        </Typography>
                      </ListItem>
                    </List>
                  </TableCell>
                  <TableCell>
                    {appointmentsExcludingCancelled?.[0] && (
                      <Typography variant="body2">
                        {moment(
                          appointmentsExcludingCancelled[0].start_date,
                        ).format('MMM D, YYYY @ h:mm')}
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">
                      {moment(opportunity.created).format('MMM D, YYYY')}
                    </Typography>
                  </TableCell>
                </TableRow>
              );
            },
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OpportunitiesList;
