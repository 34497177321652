import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MuiAlert from '@mui/material/Alert';
import { useTheme } from '@mui/material';

import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';
import { useUserContext } from 'components/MaterialUI/UserContext';

import AMVICForm from './Forms/AMVICForm';
import AudiCPOForm from './Forms/AudiCPOForm';
import AuroraCPOForm from './Forms/AuroraCPOForm';
import BMWCPOForm from './Forms/BMWCPOForm';
import BMWInspectionForm from './Forms/BMWInspectionForm';
import CourtesyChecklistForm from './Forms/CourtesyChecklistForm';
import FCPPForm from './Forms/FCPPForm';
import FordCPOForm from './Forms/FordCPOForm';
import GoColumbiaChryslerPOForm from './Forms/GoColumbiaChryslerPOForm';
import GoDodgeSurreyPOForm from './Forms/GoDodgeSurreyPOForm';
import HibernationForm from './Forms/HibernationForm';
import HondaCPOForm from './Forms/HondaCPOForm';
import HyundaiCPOForm from './Forms/HyundaiCPOForm';
import InfinitiCPOForm from './Forms/InfinitiCPOForm';
import JaguarCPOForm from './Forms/JaguarCPOForm';
import KiaCPOForm from './Forms/KiaCPOForm';
import LandRoverCPOForm from './Forms/LandRoverCPOForm';
import MOPARCPOForm from './Forms/MOPARCPOForm';
import NissanCPOForm from './Forms/NissanCPOForm';
import PreSeasonPackageForm from './Forms/PreSeasonPackageForm';
import RAMInspectionForm from './Forms/RAMInspectionForm';
import RAMBronzeInspectionForm from './Forms/RAMBronzeInspectionForm';
import RVRDPDIForm from './Forms/RVRDPDIForm';
import SeasonReadyStartUpForm from './Forms/SeasonReadyStartUpForm';
import ToyotaPDIForm from './Forms/ToyotaPDIForm';
import TrailerCoachSpecificForm from './Forms/TrailerCoachSpecificForm';
import VHCForm from './Forms/VHCForm';
import VWCPOForm from './Forms/VWCPOForm';
import VWPDIGolfJettaForm from './Forms/VWPDIGolfJettaForm';
import VWPDITiguanForm from './Forms/VWPDITiguanForm';
import VWPDITaosForm from './Forms/VWPDITaosForm';
import VWPDIID4Form from './Forms/VWPDIID4Form';
import VWPDIAtlasForm from './Forms/VWPDIAtlasForm';
import VWInspectionForm from './Forms/VWInspectionForm';
import WinterizingForm from './Forms/WinterizingForm';
import InspectionTiles from './InspectionTiles';
import {
  AUDI_DEALER_IDS,
  AURORA_DODGE_DEALER_IDS,
  BC_DEALER_IDS,
  BMW_DEALER_IDS,
  DODGE_DEALER_IDS,
  FORD_DEALER_IDS,
  GCC_DEALER_IDS,
  GDS_DEALER_IDS,
  HONDA_DEALER_IDS,
  HYUNDAI_DEALER_IDS,
  INFINITI_DEALER_IDS,
  JLR_DEALER_IDS,
  KELOWNA_DEALER_ID,
  KIA_DEALER_IDS,
  NISSAN_DEALER_IDS,
  RICHMOND_AUTO_MALL_DEALER_IDS,
  RV_DEALER_IDS,
  RVRD_DEALER_IDS,
  TOYOTA_ON_THE_TRAIL_DEALER_ID,
  VW_DEALER_IDS,
} from 'constants.js';

const INSPECTION_QUERY = gql`
  query inspections($vin: String!) {
    inspection {
      getInspections(vin: $vin) {
        id
        dealer_id
        created_by
        data
        created_at
        user {
          display_name
        }
      }
    }
  }
`;

const GET_INSPECTION_URL = gql`
  query inspections($id: Int!) {
    inspection {
      getInspectionUrl(id: $id) {
        url
      }
    }
  }
`;

const ADD_INSPECTION_MUTATION = gql`
  mutation inspection(
    $vin: String!
    $dealer_id: Int!
    $created_by: String!
    $data: JSON!
  ) {
    inspection {
      createInspection(
        vin: $vin
        dealer_id: $dealer_id
        created_by: $created_by
        data: $data
      ) {
        id
      }
    }
  }
`;

const BULK_VEHICLES_QUERY = gql`
  query BulkVehiclesQuery($filters: [QueryFilter]) {
    inventory {
      getBulkVehicles(filters: $filters) {
        id
        has_uvi
      }
    }
  }
`;

const SET_VEHICLE_HAS_UVI = gql`
  mutation setHasUVI($id: Int!, $has_uvi: Boolean!) {
    inventory {
      updateUVIVehicle(id: $id, has_uvi: $has_uvi) {
        id
        has_uvi
      }
    }
  }
`;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const VehicleChecklists = ({ ro, customer }) => {
  const {
    vin,
    dealer_id,
    ro_number,
    vehicle_year,
    vehicle_make,
    vehicle_model,
    vehicle_odometer,
    recorded_odometer,
    previous_province,
    stock_number,
  } = ro;
  const filters = [
    { model: 'Vehicle', field: 'vin', op: '==', value: vin },
    { model: 'StockStatus', field: 'name', op: '==', value: 'in stock' },
  ];
  const [createInspection, { loading: mutationLoading }] = useMutation(
    ADD_INSPECTION_MUTATION,
    {
      onCompleted: () => {
        getVehiclesByVIN({
          variables: {
            filters,
          },
        });
      },
    },
  );

  const [setVehicleHasUVI] = useMutation(SET_VEHICLE_HAS_UVI, {
    onCompleted: () => {
      enqueueSnackbar(
        `Used Vehicle Inspection Uploaded to Vehicle with vin: ${vin}`,
        { variant: 'success' },
      );
    },
  });
  const [getVehiclesByVIN, vehiclesByVINQuery] = useLazyQuery(
    BULK_VEHICLES_QUERY,
    {
      onCompleted: () => {
        refetch();
      },
    },
  );

  useEffect(() => {
    const id = vehiclesByVINQuery?.data?.inventory?.getBulkVehicles[0]?.id;

    if (vehiclesByVINQuery?.data?.inventory?.getBulkVehicles.length > 0) {
      if (id) {
        setVehicleHasUVI({
          variables: {
            id,
            has_uvi: true,
          },
        });
      } else {
        enqueueSnackbar(
          `No Vehicle in Inventory found with this vin to attach this record to.`,
          { variant: 'warning' },
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehiclesByVINQuery?.data?.inventory?.getBulkVehicles]);

  const { loading, data, refetch } = useQuery(INSPECTION_QUERY, {
    variables: { vin },
  });

  const [printable, setPrintable] = useState(true);
  const [getInspectionUrl, inspectionUrlQuery] =
    useLazyQuery(GET_INSPECTION_URL);
  useEffect(() => {
    if (inspectionUrlQuery.loading) {
      setPrintable(false);
    }
    if (inspectionUrlQuery.data) {
      setPrintable(true);
      const url = inspectionUrlQuery.data.inspection.getInspectionUrl.url;
      window.open(url, '_blank');
    }
  }, [inspectionUrlQuery]);

  const handlePrint = e => {
    getInspectionUrl({ variables: { id: Number(e.currentTarget.value) } });
  };

  const { currentUser } = useUserContext();
  const { enqueueSnackbar } = useSnackbar();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  });

  const [type, setType] = useState('');
  const [form, setForm] = useState(false);

  const handleType = e => {
    setType(e.target.value);
    setForm(false);
  };

  const handleForm = () => {
    setForm(true);
  };

  const resetForm = () => {
    setForm(false);
  };

  const handleFormSubmit = form_data => {
    form_data['ro_number'] = ro_number;
    form_data['tech_name'] = currentUser.display_name;
    form_data['vehicle_year'] = vehicle_year;
    form_data['vehicle_make'] = vehicle_make;
    form_data['vehicle_model'] = vehicle_model;
    // If Users recorded a different odometer reading use that one
    form_data['vehicle_odometer'] = recorded_odometer
      ? recorded_odometer
      : vehicle_odometer;
    form_data['previous_province'] = previous_province ?? '';
    form_data['stock_number'] = stock_number ?? '';
    form_data['engine_type'] = '';
    form_data['customer_name'] = customer ?? '';
    form_data['vin'] = vin ?? '';

    createInspection({
      variables: {
        vin: vin,
        dealer_id: dealer_id,
        created_by: currentUser.username,
        data: {
          form_type: type,
          form_data: form_data,
        },
      },
    })
      .then(r => enqueueSnackbar(`New Inspection Added!`))
      .catch(e => {
        console.log(e);
      });
  };

  if ((!data && loading) || mutationLoading)
    return <LoadingBackdrop open={mutationLoading} />;

  if (!data && !loading && !mutationLoading)
    return (
      <Box textAlign="center" mt={4}>
        <MoreVertIcon fontSize="large" />
        <Typography variant="subtitle1" gutterBottom>
          Error
        </Typography>
      </Box>
    );

  if (!vin)
    return (
      <Alert severity="warning">
        A VIN must be present on this RO to add an inspection!
      </Alert>
    );

  return (
    <>
      <Box sx={{ paddingTop: '1rem' }}>
        <Paper style={{ width: '100%', maxWidth: '720px' }}>
          <Container sx={{ paddingBottom: '24px' }}>
            <Typography variant="h6" style={{ paddingTop: '15px' }}>
              Attachments
            </Typography>
            <Grid container spacing={3}>
              <Grid item sm={10} xs={9}>
                <Select value={type} onChange={handleType} fullWidth>
                  <MenuItem value="vhc">Vehicle History Checklist</MenuItem>
                  {!BC_DEALER_IDS.includes(dealer_id) && (
                    <MenuItem value="amvic">AMVIC</MenuItem>
                  )}
                  <MenuItem value="fcpp">FCPP</MenuItem>
                  {NISSAN_DEALER_IDS.includes(dealer_id) && (
                    <MenuItem value="nissancpo">Nissan CPO</MenuItem>
                  )}
                  {JLR_DEALER_IDS.includes(dealer_id) && (
                    <MenuItem value="jaguarcpo">Jaguar CPO</MenuItem>
                  )}
                  {JLR_DEALER_IDS.includes(dealer_id) && (
                    <MenuItem value="landrovercpo">Land Rover CPO</MenuItem>
                  )}
                  {GDS_DEALER_IDS.includes(dealer_id) && (
                    <MenuItem value="gdspo">Go Dodge Surrey PO</MenuItem>
                  )}
                  {GCC_DEALER_IDS.includes(dealer_id) && (
                    <MenuItem value="gccpo">Go Columbia Chrysler PO</MenuItem>
                  )}
                  {RVRD_DEALER_IDS.includes(dealer_id) && (
                    <MenuItem value="rvrdpdi">RV Red Deer PDI</MenuItem>
                  )}
                  {RVRD_DEALER_IDS.includes(dealer_id) && (
                    <MenuItem value="trailercoach">Trailer & Coach</MenuItem>
                  )}
                  {HYUNDAI_DEALER_IDS.includes(dealer_id) && (
                    <MenuItem value="hyundaicpo">Hyundai CPO</MenuItem>
                  )}
                  {FORD_DEALER_IDS.includes(dealer_id) && (
                    <MenuItem value="fordcpo">Ford CPO</MenuItem>
                  )}
                  {RICHMOND_AUTO_MALL_DEALER_IDS.includes(dealer_id) && (
                    <MenuItem value="raminsp">
                      Richmond Auto Mall Silver Inspection Form
                    </MenuItem>
                  )}
                  {RICHMOND_AUTO_MALL_DEALER_IDS.includes(dealer_id) && (
                    <MenuItem value="rambronzeinsp">
                      Richmond Auto Mall Bronze Inspection Form
                    </MenuItem>
                  )}
                  {DODGE_DEALER_IDS.includes(dealer_id) && (
                    <MenuItem value="moparcpo">MOPAR CPO</MenuItem>
                  )}
                  {HONDA_DEALER_IDS.includes(dealer_id) && (
                    <MenuItem value="hondacpo">Honda CPO</MenuItem>
                  )}
                  {VW_DEALER_IDS.includes(dealer_id) && (
                    <MenuItem value="vwcpo">VW CPO</MenuItem>
                  )}
                  {VW_DEALER_IDS.includes(dealer_id) && (
                    <MenuItem value="vwinsp">VW Inspection Form</MenuItem>
                  )}
                  {VW_DEALER_IDS.includes(dealer_id) && (
                    <MenuItem value="vwpdigolfjetta">
                      VW PDI - 2024 GOLF GTI, GOLF R, JETTA, JETTA GLI
                    </MenuItem>
                  )}
                  {VW_DEALER_IDS.includes(dealer_id) && (
                    <MenuItem value="vwpditiguan">
                      VW PDI - 2024 TIGUAN
                    </MenuItem>
                  )}
                  {VW_DEALER_IDS.includes(dealer_id) && (
                    <MenuItem value="vwpditaos">VW PDI - 2024 TAOS</MenuItem>
                  )}
                  {VW_DEALER_IDS.includes(dealer_id) && (
                    <MenuItem value="vwpdiid4">VW PDI - 2024 ID.4</MenuItem>
                  )}
                  {VW_DEALER_IDS.includes(dealer_id) && (
                    <MenuItem value="vwpdiatlas">
                      VW PDI - 2024 ATLAS, ATLAS CROSS SPORT
                    </MenuItem>
                  )}
                  {KIA_DEALER_IDS.includes(dealer_id) && (
                    <MenuItem value="kiacpo">Kia CPO</MenuItem>
                  )}
                  {AUDI_DEALER_IDS.includes(dealer_id) && (
                    <MenuItem value="audicpo">Audi CPO</MenuItem>
                  )}
                  {AURORA_DODGE_DEALER_IDS.includes(dealer_id) && (
                    <MenuItem value="auroracpo">Aurora CPO</MenuItem>
                  )}
                  {INFINITI_DEALER_IDS.includes(dealer_id) && (
                    <MenuItem value="infiniticpo">Infiniti CPO</MenuItem>
                  )}
                  {RV_DEALER_IDS.includes(dealer_id) && (
                    <MenuItem value="preseason">Pre-Season Package</MenuItem>
                  )}
                  {RV_DEALER_IDS.includes(dealer_id) && (
                    <MenuItem value="seasonready">
                      Season Ready Start Up Inspection
                    </MenuItem>
                  )}
                  {RV_DEALER_IDS.includes(dealer_id) && (
                    <MenuItem value="winterizing">
                      Winterizing Checklist
                    </MenuItem>
                  )}
                  {RV_DEALER_IDS.includes(dealer_id) && (
                    <MenuItem value="hibernation">
                      Hibernation Winterization
                    </MenuItem>
                  )}
                  {RV_DEALER_IDS.includes(dealer_id) && (
                    <MenuItem value="courtesy">Courtesy Checklist</MenuItem>
                  )}
                  {KELOWNA_DEALER_ID.includes(dealer_id) && (
                    <MenuItem value="kelowna">
                      Kelowna Vehicle Inspection Form
                    </MenuItem>
                  )}
                  {TOYOTA_ON_THE_TRAIL_DEALER_ID.includes(dealer_id) && (
                    <MenuItem value="toyotapdi">Toyota PDI Form</MenuItem>
                  )}
                  {BMW_DEALER_IDS.includes(dealer_id) && (
                    <MenuItem value="bmwcpo">BMW CPO</MenuItem>
                  )}
                  {BMW_DEALER_IDS.includes(dealer_id) && (
                    <MenuItem value="bmwinsp">
                      BMW 55 Points Inspection
                    </MenuItem>
                  )}
                </Select>
              </Grid>
              <Grid item sm={2} xs={3}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleForm}
                  disabled={mutationLoading || loading}
                >
                  Start
                </Button>
              </Grid>
            </Grid>
          </Container>
        </Paper>
      </Box>
      <Container
        style={
          isMobile
            ? {
                width: '100%',
                maxWidth: '720px',
                marginTop: '15px',
                marginLeft: '0',
              }
            : { width: '100%', maxWidth: '720px', margin: '15px 0' }
        }
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {type === '' && !form && (
              <Typography style={{ padding: '15px 0', fontWeight: '700' }}>
                Completed Checklists:
              </Typography>
            )}
            {type === '' &&
              !form &&
              data.inspection.getInspections
                .filter(value => value?.dealer_id === dealer_id)
                .map((value, index) => {
                  return (
                    <InspectionTiles
                      key={index}
                      value={value}
                      handlePrint={handlePrint}
                      printable={printable}
                    />
                  );
                })
                .reverse()}
            {type !== '' &&
              !form &&
              data.inspection.getInspections
                .filter(
                  value =>
                    type === value?.data?.form_type &&
                    dealer_id === value?.dealer_id,
                )
                .map((value, index) => {
                  return (
                    <InspectionTiles
                      key={index}
                      value={value}
                      handlePrint={handlePrint}
                      printable={printable}
                    />
                  );
                })
                .reverse()}
            {type === 'vhc' && form && (
              <VHCForm
                formData={handleFormSubmit}
                formClose={resetForm}
                user={currentUser}
              />
            )}
            {type === 'amvic' && form && (
              <AMVICForm
                formData={handleFormSubmit}
                formClose={resetForm}
                user={currentUser}
              />
            )}
            {type === 'fcpp' && form && (
              <FCPPForm
                formData={handleFormSubmit}
                formClose={resetForm}
                user={currentUser}
              />
            )}
            {type === 'nissancpo' && form && (
              <NissanCPOForm
                formData={handleFormSubmit}
                formClose={resetForm}
                user={currentUser}
              />
            )}
            {type === 'jaguarcpo' && form && (
              <JaguarCPOForm
                formData={handleFormSubmit}
                formClose={resetForm}
                user={currentUser}
              />
            )}
            {type === 'landrovercpo' && form && (
              <LandRoverCPOForm
                formData={handleFormSubmit}
                formClose={resetForm}
                user={currentUser}
              />
            )}
            {type === 'gdspo' && form && (
              <GoDodgeSurreyPOForm
                formData={handleFormSubmit}
                formClose={resetForm}
                user={currentUser}
              />
            )}
            {type === 'gccpo' && form && (
              <GoColumbiaChryslerPOForm
                formData={handleFormSubmit}
                formClose={resetForm}
                user={currentUser}
              />
            )}
            {type === 'rvrdpdi' && form && (
              <RVRDPDIForm
                formData={handleFormSubmit}
                formClose={resetForm}
                user={currentUser}
              />
            )}
            {type === 'trailercoach' && form && (
              <TrailerCoachSpecificForm
                formData={handleFormSubmit}
                formClose={resetForm}
                user={currentUser}
              />
            )}
            {type === 'fordcpo' && form && (
              <FordCPOForm
                formData={handleFormSubmit}
                formClose={resetForm}
                user={currentUser}
              />
            )}
            {type === 'hyundaicpo' && form && (
              <HyundaiCPOForm
                formData={handleFormSubmit}
                formClose={resetForm}
                user={currentUser}
              />
            )}
            {type === 'raminsp' && form && (
              <RAMInspectionForm
                formData={handleFormSubmit}
                formClose={resetForm}
                user={currentUser}
              />
            )}
            {type === 'rambronzeinsp' && form && (
              <RAMBronzeInspectionForm
                formData={handleFormSubmit}
                formClose={resetForm}
                user={currentUser}
              />
            )}
            {type === 'moparcpo' && form && (
              <MOPARCPOForm
                formData={handleFormSubmit}
                formClose={resetForm}
                user={currentUser}
              />
            )}
            {type === 'hondacpo' && form && (
              <HondaCPOForm
                formData={handleFormSubmit}
                formClose={resetForm}
                user={currentUser}
              />
            )}
            {type === 'vwcpo' && form && (
              <VWCPOForm
                formData={handleFormSubmit}
                formClose={resetForm}
                user={currentUser}
              />
            )}
            {type === 'vwinsp' && form && (
              <VWInspectionForm
                formData={handleFormSubmit}
                formClose={resetForm}
                user={currentUser}
              />
            )}
            {type === 'vwpdigolfjetta' && form && (
              <VWPDIGolfJettaForm
                formData={handleFormSubmit}
                formClose={resetForm}
                user={currentUser}
              />
            )}
            {type === 'vwpditiguan' && form && (
              <VWPDITiguanForm
                formData={handleFormSubmit}
                formClose={resetForm}
                user={currentUser}
              />
            )}
            {type === 'vwpditaos' && form && (
              <VWPDITaosForm
                formData={handleFormSubmit}
                formClose={resetForm}
                user={currentUser}
              />
            )}
            {type === 'vwpdiid4' && form && (
              <VWPDIID4Form
                formData={handleFormSubmit}
                formClose={resetForm}
                user={currentUser}
              />
            )}
            {type === 'vwpdiatlas' && form && (
              <VWPDIAtlasForm
                formData={handleFormSubmit}
                formClose={resetForm}
                user={currentUser}
              />
            )}
            {type === 'kiacpo' && form && (
              <KiaCPOForm
                formData={handleFormSubmit}
                formClose={resetForm}
                user={currentUser}
              />
            )}
            {type === 'audicpo' && form && (
              <AudiCPOForm
                formData={handleFormSubmit}
                formClose={resetForm}
                user={currentUser}
              />
            )}
            {type === 'auroracpo' && form && (
              <AuroraCPOForm
                formData={handleFormSubmit}
                formClose={resetForm}
                user={currentUser}
              />
            )}
            {type === 'infiniticpo' && form && (
              <InfinitiCPOForm
                formData={handleFormSubmit}
                formClose={resetForm}
                user={currentUser}
              />
            )}
            {type === 'preseason' && form && (
              <PreSeasonPackageForm
                formData={handleFormSubmit}
                formClose={resetForm}
                user={currentUser}
              />
            )}
            {type === 'seasonready' && form && (
              <SeasonReadyStartUpForm
                formData={handleFormSubmit}
                formClose={resetForm}
                user={currentUser}
              />
            )}
            {type === 'winterizing' && form && (
              <WinterizingForm
                formData={handleFormSubmit}
                formClose={resetForm}
                user={currentUser}
              />
            )}
            {type === 'hibernation' && form && (
              <HibernationForm
                formData={handleFormSubmit}
                formClose={resetForm}
                user={currentUser}
              />
            )}
            {type === 'courtesy' && form && (
              <CourtesyChecklistForm
                formData={handleFormSubmit}
                formClose={resetForm}
                user={currentUser}
              />
            )}
            {type === 'kelowna' && form && (
              <AMVICForm
                formData={handleFormSubmit}
                formClose={resetForm}
                user={currentUser}
              />
            )}
            {type === 'toyotapdi' && form && (
              <ToyotaPDIForm
                formData={handleFormSubmit}
                formClose={resetForm}
                user={currentUser}
              />
            )}
            {type === 'bmwcpo' && form && (
              <BMWCPOForm
                formData={handleFormSubmit}
                formClose={resetForm}
                user={currentUser}
              />
            )}
            {type === 'bmwinsp' && form && (
              <BMWInspectionForm
                formData={handleFormSubmit}
                formClose={resetForm}
                user={currentUser}
              />
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

VehicleChecklists.fragments = {
  ro: gql`
    fragment VehicleChecklistsRO on ROTrackerRO {
      vin
      dealer_id
      ro_number
      vehicle_year
      vehicle_make
      vehicle_model
      vehicle_odometer
      recorded_odometer
      previous_province
      stock_number
    }
  `,
};

export default VehicleChecklists;
