import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import React, { useEffect, useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
} from '@mui/material';

// Internal
import ErrorDisplay from 'components/MaterialUI/ErrorDisplay';
import Loading from 'components/MaterialUI/Loading';

const GATEWAY_USERS_QUERY = gql`
  query gatewayUsers($gatewayId: Int!) {
    sms {
      gatewayUsers(id: $gatewayId) {
        display_name
        username
      }
    }
  }
`;

const AssignOperatorDialog = ({
  conversationId,
  gatewayId,
  operator,
  onClose,
  open,
  onSubmit,
  ...rest
}) => {
  const { loading, data, error } = useQuery(GATEWAY_USERS_QUERY, {
    variables: {
      gatewayId,
    },
    skip: !open,
  });
  const [assignedOperator, setAssignedOperator] = useState(operator ?? '');

  useEffect(() => {
    // reset default value if the operator changes (ie. from unassigning them)
    if (!open && assignedOperator !== operator)
      setAssignedOperator(operator ?? '');
  }, [open, assignedOperator, setAssignedOperator, operator]);

  const operators =
    data?.sms.gatewayUsers.sort((a, b) =>
      a.display_name.localeCompare(b.display_name),
    ) ?? [];

  const handleChange = e => setAssignedOperator(e.target.value);
  const handleSubmit = () => onSubmit(assignedOperator);
  return (
    <Dialog onClose={onClose} open={open} {...rest}>
      <DialogTitle>Assign Operator</DialogTitle>
      <DialogContent>
        {loading ? (
          <Loading text="Loading operators" />
        ) : error ? (
          <ErrorDisplay error={error} action="Loading List of Operators" />
        ) : (
          <TextField
            select
            value={assignedOperator}
            label="Operator"
            onChange={handleChange}
            style={{ width: '100%' }}
            SelectProps={{ displayEmpty: true }}
            InputLabelProps={{ shrink: true }} // force shrink since we're displaying empty
          >
            <MenuItem value="">Unassigned</MenuItem>
            {operators.map(operator => (
              <MenuItem key={operator.username} value={operator.username}>
                {operator.display_name}
              </MenuItem>
            ))}
          </TextField>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          type="submit"
          disabled={assignedOperator === operator}
          onClick={handleSubmit}
        >
          Assign
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AssignOperatorDialog;
