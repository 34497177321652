import React from 'react';

import { FormControl, InputLabel, Select } from '@mui/material';

const DeviceSelector = ({ deviceOptions, onSelect, selected }) => (
  <FormControl fullWidth>
    <InputLabel>Select a camera device</InputLabel>
    <Select
      native
      variant="filled"
      onChange={e => onSelect && onSelect(e.target.value)}
      value={selected || ''}
    >
      <option value="" disabled></option>
      {deviceOptions &&
        deviceOptions.map(d => (
          <option value={d.deviceId}>{d.label || 'Unknown'}</option>
        ))}
    </Select>
  </FormControl>
);

export default DeviceSelector;
