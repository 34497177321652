import { useMutation } from '@apollo/react-hooks';

/* external */
import gql from 'graphql-tag';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';

/* Material UI */
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';

/* internal */
import Loading from 'components/MaterialUI/Loading';
import { useUserContext } from 'components/MaterialUI/UserContext';

import { Role } from 'constants.js';

const GENERATE_CONTRACT = gql`
  mutation generateContract($id: ID!) {
    concierge {
      generateContract(id: $id) {
        key
      }
    }
  }
`;

const GoldCardActions = ({
  goldCard,
  openInfoModal,
  openScheduleModal,
  openNotesModal,
  openVoidModal,
  openCancelModal,
  openVehicleModal,
  openFrequencyModal,
  openReschedulePaymentModal,
}) => {
  const { currentUser } = useUserContext();
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState(null);
  const [generateContract, { loading }] = useMutation(GENERATE_CONTRACT, {
    variables: {
      id: goldCard.id,
    },
    onCompleted: data => {
      //open window to pdfUrl
      window.open(data.concierge.generateContract.key, '_blank');
    },
    onError: e => {
      enqueueSnackbar(`Error generating PDF`, {
        variant: 'error',
      });
    },
  });

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuItemHandler = e => {
    e.stopPropagation();
    handleClose();
  };

  const handleGenerateContract = () => {
    generateContract();
  };

  if (loading) return <Loading />;

  const isControllerOrAdmin =
    currentUser?.role === Role.CONTROLLER ||
    currentUser?.role === Role.ORGANIZATION_ADMIN;

  const isService = Role.ALL_SERVICE.includes(currentUser?.role);
  const activeGoldCard = goldCard.status === 'Active';

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={1}
      style={{ marginLeft: '8px' }}
    >
      <Grid item>
        <IconButton size="small" onClick={handleClick} disabled={isService}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={event => menuItemHandler(event)}
        >
          <MenuItem
            onClick={event => {
              menuItemHandler(event);
              openInfoModal();
            }}
          >
            Payment Info
          </MenuItem>
          <MenuItem
            onClick={event => {
              menuItemHandler(event);
              openScheduleModal();
            }}
          >
            Payment Schedule
          </MenuItem>
          <MenuItem
            onClick={event => {
              menuItemHandler(event);
              openNotesModal();
            }}
          >
            Notes
          </MenuItem>
          <MenuItem
            onClick={event => {
              menuItemHandler(event);
              handleGenerateContract();
            }}
          >
            Contract
          </MenuItem>
          {isControllerOrAdmin && activeGoldCard && (
            <>
              <MenuItem
                onClick={event => {
                  menuItemHandler(event);
                  openVoidModal();
                }}
              >
                Void Package
              </MenuItem>
              <MenuItem
                onClick={event => {
                  menuItemHandler(event);
                  openCancelModal();
                }}
              >
                Cancel Package
              </MenuItem>
              <MenuItem
                onClick={event => {
                  menuItemHandler(event);
                  openReschedulePaymentModal();
                }}
              >
                Reschedule Payments
              </MenuItem>
              <MenuItem
                onClick={event => {
                  menuItemHandler(event);
                  openVehicleModal();
                }}
              >
                Edit Vehicle
              </MenuItem>
              <MenuItem
                onClick={event => {
                  menuItemHandler(event);
                  openFrequencyModal();
                }}
              >
                Edit Payment Frequency
              </MenuItem>
            </>
          )}
        </Menu>
      </Grid>
    </Grid>
  );
};

GoldCardActions.fragments = {
  package: gql`
    fragment GoldCardActionsPackage on Package {
      status
    }
  `,
};

export default GoldCardActions;
