import React, { useEffect } from 'react';

import ErrorDisplay from 'components/MaterialUI/ErrorDisplay';
import Loading from 'components/MaterialUI/Loading';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { formatPrice, numberWithCommas } from 'utils';

import { useQuery } from '@apollo/react-hooks';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';

import { formatEngineDescription, titleCase } from '../../utils';
import WizardImageCarousel from '../misc/WizardImageCarousel';
import GroupInventoryCBBDetails from '../vehicle/cbb/GroupInventoryCBBDetails';
import VehicleAttachmentsReadOnlyTable from './VehicleAttachmentsReadOnlyTable';

const VEHICLE_INFO = gql`
  query vehicleInfo($id: Int!) {
    inventory {
      getGroupVehicle(id: $id) {
        id
        cbb {
          condition
          equipment
          style
          trim
          vid
        }
        cost
        days_in_stock
        dealer_name
        displayName
        drive_type_name
        engine_config_name
        engine_cylinders
        engine_litres
        exterior_colour_name
        fuel_type_name
        grease_dealer {
          id
          address
          phone_number
        }
        group_age
        interior_colour_name
        list_price
        make_name
        model_name
        odometer
        passengers
        permissions {
          read_cost
        }
        photos {
          id
          cloudinary_public_id
        }
        published_trim
        stock_number
        stock_type
        transmission_name
        trim
        trim_variation
        upholstery_name
        vin
        year
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  sectionTitle: {
    fontWeight: 'bold',
    fontSize: '18px',
  },
  vehicleName: {
    fontWeight: 'bold',
    fontSize: '20px',
    paddingRight: '1rem',
  },
}));

const propTypes = {
  vehicleId: PropTypes.number,
  showCost: PropTypes.bool,
  showVehicleModal: PropTypes.bool,
  setShowVehicleModal: PropTypes.func,
};

const GroupVehicleModal = ({
  vehicleId,
  showCost,
  showVehicleModal,
  setShowVehicleModal,
}) => {
  const classes = useStyles();

  const vehicleQuery = useQuery(VEHICLE_INFO, {
    skip: !vehicleId,
    variables: { id: vehicleId },
  });

  const vehicle = vehicleQuery.data?.inventory?.getGroupVehicle || {};

  useEffect(() => {
    const script = document.createElement('script');

    script.src = '//badging.carproof.com/Scripts/CarProofBadges.min.js';
    script.setAttribute('data-thirdpartykey', 'aZcGWDqHpEpQGMD2ssGVBQ==');
    script.id = 'carproof-badge-js';
    script.type = 'text/javascript';

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  });

  const handleClose = () => setShowVehicleModal(false);

  if (vehicleQuery.loading)
    return (
      <Dialog
        open={showVehicleModal}
        onClose={() => setShowVehicleModal(false)}
        maxWidth="lg"
        fullWidth
      >
        <Box minHeight="120px">
          <Loading />
        </Box>
      </Dialog>
    );

  if (vehicleQuery.error)
    return (
      <Dialog
        open={showVehicleModal}
        onClose={() => setShowVehicleModal(false)}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>
          <h1> Error </h1>
        </DialogTitle>
        <ErrorDisplay
          action="fetching vehicle data"
          error={vehicleQuery.error}
        />
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );

  return (
    <Dialog
      open={showVehicleModal}
      onClose={() => setShowVehicleModal(false)}
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle>
        <h1>Vehicle Details</h1>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item container xs={7}>
            <Grid item xs={12}>
              <Grid container alignItems="center">
                <Grid
                  item
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                  }}
                >
                  <span className={classes.vehicleName}>
                    {vehicle.displayName}
                  </span>
                  {vehicle.trim_variation}
                </Grid>
                <Grid
                  item
                  style={{
                    paddingLeft: '1rem',
                  }}
                >
                  <Chip size="small" label={titleCase(vehicle.stock_type)} />
                </Grid>
              </Grid>
              <div>
                {vehicle.stock_number} / {vehicle.vin} /{' '}
                {numberWithCommas(vehicle.odometer)} km
              </div>
            </Grid>
            <Grid item container spacing={4} xs={12}>
              <Grid item xs={6}>
                <p>
                  <b>Days In Stock: </b>
                  {`${vehicle.days_in_stock || 'N/A'} (Group Age: ${
                    vehicle.group_age || 'N/A'
                  })`}
                </p>
                <p>
                  <b>Published Trim: </b>
                  {vehicle.published_trim || 'N/A'}
                </p>
                <p>
                  <b>Ext. Color: </b>
                  {vehicle.exterior_colour_name || 'N/A'}
                </p>
                <p>
                  <b>Int. Color: </b>
                  {vehicle.interior_colour_name || 'N/A'}
                </p>
                <p>
                  <b>Upholstery: </b>
                  {vehicle.upholstery_name || 'N/A'}
                </p>
                <p>
                  <b>Passengers: </b>
                  {vehicle.passengers || 'N/A'}
                </p>
              </Grid>
              <Grid item xs={6}>
                <p>
                  <b>Price: </b>
                  {formatPrice(vehicle.list_price, { nullDisplay: 'N/A' })}
                </p>
                {showCost && (
                  <p>
                    <b>Cost: </b>
                    {formatPrice(vehicle.cost, { nullDisplay: 'N/A' })}
                  </p>
                )}
                <p>
                  <b>Engine: </b>
                  {formatEngineDescription(
                    vehicle.engine_config_name,
                    vehicle.engine_cylinders,
                    vehicle.engine_litres,
                  ) || 'N/A'}
                </p>
                <p>
                  <b>Transmission: </b>
                  {vehicle.transmission_name || 'N/A'}
                </p>
                <p>
                  <b>Fuel: </b>
                  {vehicle.fuel_type_name || 'N/A'}
                </p>
                <p>
                  <b>Drive: </b>
                  {vehicle.drive_type_name || 'N/A'}
                </p>
              </Grid>
            </Grid>
            <Grid container item direction="column" xs={12} spacing={2}>
              <Grid item>
                <span className={classes.sectionTitle}>Location</span>
              </Grid>
              <Grid container item justifyContent="space-between">
                <Grid item xs={4}>
                  {vehicle.dealer_name || 'N/A'}
                </Grid>
                <Grid item xs={4}>
                  {vehicle.grease_dealer?.address || 'N/A'}
                </Grid>
                <Grid item xs={4}>
                  {vehicle.grease_dealer?.phone_number || 'N/A'}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container xs={5}>
            {/* minHeight to keep the Dialog from 'jumping' on each photo render */}
            <Grid item xs={12} style={{ minHeight: '350px' }}>
              <WizardImageCarousel photos={vehicle.photos} height="325px" />
            </Grid>
            <Grid item xs={12}>
              <div
                className="carproof detail carproof-badge"
                data-vin={vehicle.vin}
                data-type="slim"
                data-language="en"
              ></div>
            </Grid>
          </Grid>
        </Grid>
        <p className={classes.sectionTitle}>Canadian Black Book Details</p>
        <GroupInventoryCBBDetails vehicle={vehicle} />
        <p className={classes.sectionTitle}>Attachments</p>
        <Grid item xs={6}>
          <VehicleAttachmentsReadOnlyTable vin={vehicle.vin} />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

GroupVehicleModal.propTypes = propTypes;

export default GroupVehicleModal;
