import React from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { formatPrice } from '../../../utils';
import DealLogMoreMetricsBreakdown from './DealLogMoreMetricsBreakdown';
import DealLogTableMetricsRow from './DealLogTableMetricsRow';
import { displayValue, generateAverage, generatePercent } from './utils';

const useStyles = makeStyles({
  tableCell: {
    width: '100px',
    padding: '1rem',
  },
  inlineTableCell: {
    width: '100px',
    paddingLeft: '1rem',
  },
});

const DealLogTableMetrics = ({ dealer, metrics, roleMetrics }) => {
  const styles = useStyles();

  const totalNew =
    metrics.new_delivered_count +
    metrics.new_posted_count +
    metrics.new_signed_count +
    metrics.new_approved_count +
    metrics.new_cash_count +
    metrics.new_pending_count +
    metrics.new_tubed_count +
    metrics.new_carryover_count +
    metrics.new_pre_app_count;

  const totalUsed =
    metrics.used_delivered_count +
    metrics.used_posted_count +
    metrics.used_signed_count +
    metrics.used_approved_count +
    metrics.used_cash_count +
    metrics.used_pending_count +
    metrics.used_tubed_count +
    metrics.used_carryover_count +
    metrics.used_pre_app_count;

  const totalNewFrontGross =
    metrics.posted_new_frontend_gross +
    metrics.delivered_new_frontend_gross +
    metrics.signed_new_frontend_gross +
    metrics.approved_new_frontend_gross +
    metrics.pending_new_frontend_gross +
    metrics.carryover_new_frontend_gross +
    metrics.pre_app_new_frontend_gross;

  const totalNewBackGross =
    metrics.posted_new_backend_gross +
    metrics.delivered_new_backend_gross +
    metrics.signed_new_backend_gross +
    metrics.approved_new_backend_gross +
    metrics.pending_new_backend_gross +
    metrics.carryover_new_backend_gross +
    metrics.pre_app_new_backend_gross;

  const totalUsedFrontGross =
    metrics.posted_used_frontend_gross +
    metrics.delivered_used_frontend_gross +
    metrics.signed_used_frontend_gross +
    metrics.approved_used_frontend_gross +
    metrics.pending_used_frontend_gross +
    metrics.carryover_used_frontend_gross +
    metrics.pre_app_used_frontend_gross;

  const totalUsedBackGross =
    metrics.posted_used_backend_gross +
    metrics.delivered_used_backend_gross +
    metrics.signed_used_backend_gross +
    metrics.approved_used_backend_gross +
    metrics.pending_used_backend_gross +
    metrics.carryover_used_backend_gross +
    metrics.pre_app_used_backend_gross;

  return (
    <>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item>
              <Typography style={{ marginRight: 20 }}>
                New and Used Detailed Breakdown
              </Typography>
            </Grid>
            <Grid item>
              <DealLogMoreMetricsBreakdown
                dealer={dealer}
                metrics={metrics}
                roleMetrics={roleMetrics}
              />
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>New Vehicles</b>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell />
                  <TableCell />
                  <TableCell>Front End Avg</TableCell>
                  <TableCell>Back End Avg</TableCell>
                  <TableCell>Total Avg</TableCell>
                  <TableCell>Total Gross</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <b>Writes</b>
                  </TableCell>
                  <TableCell className={styles.inlineTableCell}>
                    {totalNew}
                  </TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                </TableRow>
                <DealLogTableMetricsRow metrics={metrics} stock_type={'new'} />
                <TableRow>
                  <TableCell>
                    <b>Signed</b>
                  </TableCell>
                  <TableCell className={styles.inlineTableCell}>
                    {metrics.new_signed_count}
                  </TableCell>
                  <TableCell className={styles.inlineTableCell}>
                    {generateAverage(
                      metrics.signed_new_frontend_gross,
                      metrics.new_signed_count + metrics.used_signed_count,
                      '-',
                    )}
                  </TableCell>
                  <TableCell className={styles.inlineTableCell}>
                    {generateAverage(
                      metrics.signed_new_backend_gross,
                      metrics.new_signed_count + metrics.used_signed_count,
                      '-',
                    )}
                  </TableCell>
                  <TableCell className={styles.inlineTableCell}>
                    {generateAverage(
                      metrics.signed_new_frontend_gross +
                        metrics.signed_new_backend_gross,
                      metrics.new_signed_count + metrics.used_signed_count,
                      '-',
                    )}
                  </TableCell>
                  <TableCell className={styles.inlineTableCell}>
                    {formatPrice(
                      metrics.signed_new_frontend_gross +
                        metrics.signed_new_backend_gross,
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Approved</b>
                  </TableCell>
                  <TableCell className={styles.inlineTableCell}>
                    {metrics.new_approved_count}
                  </TableCell>
                  <TableCell className={styles.inlineTableCell}>
                    {generateAverage(
                      metrics.approved_new_frontend_gross,
                      metrics.new_approved_count,
                      '-',
                    )}
                  </TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Pending</b>
                  </TableCell>
                  <TableCell className={styles.inlineTableCell}>
                    {metrics.new_pending_count}
                  </TableCell>
                  <TableCell className={styles.inlineTableCell}>
                    {generateAverage(
                      metrics.pending_new_frontend_gross,
                      metrics.new_pending_count,
                      '-',
                    )}
                  </TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Tubed</b>
                  </TableCell>
                  <TableCell className={styles.inlineTableCell}>
                    {metrics.new_tubed_count}
                  </TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Tubed %</b>
                  </TableCell>
                  <TableCell className={styles.inlineTableCell}>
                    {generatePercent(totalNew, metrics.new_tubed_count)}
                  </TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                </TableRow>
                <TableRow>
                  <TableCell />
                  <TableCell />
                  <TableCell className={styles.inlineTableCell}>
                    <b>{generateAverage(totalNewFrontGross, totalNew, '-')}</b>
                  </TableCell>
                  <TableCell className={styles.inlineTableCell}>
                    <b>{generateAverage(totalNewBackGross, totalNew, '-')}</b>
                  </TableCell>
                  <TableCell className={styles.inlineTableCell}>
                    <b>
                      {generateAverage(
                        totalNewFrontGross + totalNewBackGross,
                        totalNew,
                        '-',
                      )}
                    </b>
                  </TableCell>
                  <TableCell className={styles.inlineTableCell}>
                    <b>
                      {displayValue(totalNewFrontGross + totalNewBackGross)}
                    </b>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>Used Vehicles</b>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell />
                  <TableCell />
                  <TableCell>Front End Avg</TableCell>
                  <TableCell>Back End Avg</TableCell>
                  <TableCell>Total Avg</TableCell>
                  <TableCell>Total Gross</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <b>Writes</b>
                  </TableCell>
                  <TableCell className={styles.inlineTableCell}>
                    {' '}
                    {totalUsed}{' '}
                  </TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                </TableRow>
                <DealLogTableMetricsRow metrics={metrics} stock_type={'used'} />
                <TableRow>
                  <TableCell>
                    <b>Signed</b>
                  </TableCell>
                  <TableCell className={styles.inlineTableCell}>
                    {metrics.used_signed_count}
                  </TableCell>
                  <TableCell className={styles.inlineTableCell}>
                    {generateAverage(
                      metrics.signed_used_frontend_gross,
                      metrics.new_signed_count + metrics.used_signed_count,
                      '-',
                    )}
                  </TableCell>
                  <TableCell className={styles.inlineTableCell}>
                    {generateAverage(
                      metrics.signed_used_backend_gross,
                      metrics.new_signed_count + metrics.used_signed_count,
                      '-',
                    )}
                  </TableCell>
                  <TableCell className={styles.inlineTableCell}>
                    {generateAverage(
                      metrics.signed_used_frontend_gross +
                        metrics.signed_used_backend_gross,
                      metrics.new_signed_count + metrics.used_signed_count,
                      '-',
                    )}
                  </TableCell>
                  <TableCell className={styles.inlineTableCell}>
                    {formatPrice(
                      metrics.signed_used_frontend_gross +
                        metrics.signed_used_backend_gross,
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Approved</b>
                  </TableCell>
                  <TableCell className={styles.inlineTableCell}>
                    {metrics.used_approved_count}
                  </TableCell>
                  <TableCell className={styles.inlineTableCell}>
                    {generateAverage(
                      metrics.approved_used_frontend_gross,
                      metrics.used_approved_count,
                      '-',
                    )}
                  </TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Pending</b>
                  </TableCell>
                  <TableCell className={styles.inlineTableCell}>
                    {metrics.used_pending_count}
                  </TableCell>
                  <TableCell className={styles.inlineTableCell}>
                    {generateAverage(
                      metrics.pending_used_frontend_gross,
                      metrics.used_pending_count,
                      '-',
                    )}
                  </TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Tubed</b>
                  </TableCell>
                  <TableCell className={styles.inlineTableCell}>
                    {metrics.used_tubed_count}
                  </TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Tubed %</b>
                  </TableCell>
                  <TableCell className={styles.inlineTableCell}>
                    {generatePercent(totalUsed, metrics.used_tubed_count)}
                  </TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                </TableRow>
                <TableRow>
                  <TableCell />
                  <TableCell />
                  <TableCell className={styles.inlineTableCell}>
                    <b>
                      {generateAverage(totalUsedFrontGross, totalUsed, '-')}
                    </b>
                  </TableCell>
                  <TableCell className={styles.inlineTableCell}>
                    <b>{generateAverage(totalUsedBackGross, totalUsed, '-')}</b>
                  </TableCell>
                  <TableCell className={styles.inlineTableCell}>
                    <b>
                      {generateAverage(
                        totalUsedFrontGross + totalUsedBackGross,
                        totalUsed,
                        '-',
                      )}
                    </b>
                  </TableCell>
                  <TableCell className={styles.inlineTableCell}>
                    <b>
                      {displayValue(totalUsedFrontGross + totalUsedBackGross)}
                    </b>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default DealLogTableMetrics;
