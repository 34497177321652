import React from 'react';

/* external */
import { Route, Switch } from 'react-router-dom';

/* internal */
import app from '../../app';
import Syndication from './syndication/Syndication';
import CreateUnit from './create_unit/CreateUnit';
import Inventory from './inventory/Inventory';
import Dashboard from './dashboard/Dashboard';
import EditUnit from './edit_unit/EditUnit';

const NpvInventory = ({ match }) => {
  return (
    // TODO add and adjust required roles to check dealer ids? Or maybe a better way to do this?
    <app.components.Layout>
      <Switch>
        <Route exact path={`${match.url}/dashboard`} component={Dashboard} />
        <Route exact path={`${match.url}/inventory`} component={Inventory} />
        <Route
          exact
          path={`${match.url}/syndication`}
          component={Syndication}
        />
        <Route exact path={`${match.url}/create-unit`} component={CreateUnit} />
        <Route
          exact
          path={`${match.url}/:id(\\d+)`}
          render={({ match }) => (
            <EditUnit vehicleId={parseInt(match.params.id, 10)} />
          )}
        />
      </Switch>
    </app.components.Layout>
  );
};

export default NpvInventory;
