import React, { useState } from 'react';

/* external */
import moment from 'moment-timezone';

/* Material UI */
import { DateTimePicker } from '@mui/x-date-pickers';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';

const DatePickerDialog = ({
  arrivedAt = false,
  dateTimePickerProps = {},
  onClose,
  onConfirm,
  open,
  timezone,
  value,
  inTransit: _inTransit,
  ...rest
}) => {
  const [dateTime, setDateTime] = useState(
    value ? moment(value + 'Z').tz(timezone) : moment().tz(timezone),
  );

  const [inTransit, setInTransit] = useState(_inTransit);

  // Since the dateTime moment has the timezone, to ISOString converts it to
  // a UTC time.
  const handleOK = () => onConfirm(dateTime.toISOString(), inTransit);

  return (
    <Dialog open={open} onClose={onClose} style={{ padding: '0' }} {...rest}>
      <DialogContent style={{ padding: '0' }}>
        <DateTimePicker
          variant="static"
          onChange={value => setDateTime(value)}
          value={dateTime}
          {...dateTimePickerProps}
        />
        {arrivedAt && (
          <RadioGroup
            onChange={e => setInTransit(e.target.value === 'true')}
            style={{ display: 'flex', flexDirection: 'row', padding: '1rem' }}
            value={String(inTransit)}
          >
            <FormControlLabel
              value="true"
              control={<Radio />}
              label="In Transit"
            />
            <FormControlLabel
              value="false"
              control={<Radio />}
              label="On Ground (Arrived)"
            />
          </RadioGroup>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>CANCEL</Button>
        <Button onClick={handleOK}>OK</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DatePickerDialog;
