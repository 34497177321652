import React from 'react';

import { useDealerContext } from 'components/MaterialUI/DealerContext';
import { useLocation } from 'react-router-dom';

import Hidden from '@mui/material/Hidden';
import Tooltip from '@mui/material/Tooltip';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import { usePersistedState } from '../../utils';
import BulkActions from '../bulk_actions/BulkActions';
import VehiclesQueryContext from '../contexts/VehiclesQueryContext';
import InventoryCardTable from './InventoryCardTable';
import InventoryMobileTable from './InventoryMobileTable';
import InventoryTable from './InventoryTable';
import IconGroup from './IconGroup';

const InventoryList = () => {
  const [alignment, setAlignment] = usePersistedState('alignment', 'list');
  const { dealerId } = useDealerContext();

  const keywordsParams = new URLSearchParams(useLocation().search).get(
    'keywords',
  );

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const VEHICLE_FACETS_QUERIES = [
    {
      model: 'StockStatus',
      field: 'name',
    },
    {
      model: 'Vehicle',
      field: 'stock_type',
    },
    {
      model: 'Vehicle',
      field: 'days_in_stock_category',
    },
    {
      model: 'Vehicle',
      field: 'year',
    },
    {
      model: 'Make',
      field: 'name',
    },
    {
      model: 'Model',
      field: 'name',
    },
    {
      model: 'BodyType',
      field: 'name',
    },
    {
      model: 'Vehicle',
      field: 'is_decoded',
    },
    {
      model: 'Vehicle',
      field: 'has_uvi',
    },
    {
      model: 'Vehicle',
      field: 'is_missing_photos',
    },
    {
      model: 'Vehicle',
      field: 'is_missing_list_price',
    },
    {
      model: 'Vehicle',
      field: 'is_missing_description',
    },
    {
      model: 'Vehicle',
      field: 'trim',
    },
    {
      model: 'Vehicle',
      field: 'is_on_special',
    },
    {
      model: 'Vehicle',
      field: 'is_detailed_pricing',
    },
    {
      model: 'Vehicle',
      field: 'is_featured',
    },
    {
      model: 'Vehicle',
      field: 'is_loaner',
    },
    {
      model: 'Vehicle',
      field: 'is_demo_unit',
    },
    {
      model: 'Vehicle',
      field: 'is_certified',
    },
    {
      model: 'Vehicle',
      field: 'has_video',
    },
    {
      model: 'Vehicle',
      field: 'manufacturer_code',
    },
    {
      model: 'Vehicle',
      field: 'no_price_changes_at_7_days',
    },
    {
      model: 'Vehicle',
      field: 'no_price_changes_at_14_days',
    },
    {
      model: 'Vehicle',
      field: 'no_price_changes_at_21_days',
    },
    {
      model: 'Vehicle',
      field: 'no_price_changes_at_28_days',
    },
    {
      model: 'Vehicle',
      field: 'not_enough_photos',
    },
    {
      model: 'Vehicle',
      field: 'recon_complete',
    },
    {
      model: 'FuelType',
      field: 'name',
    },
    {
      model: 'Vehicle',
      field: 'engine_litres',
    },
  ];

  return (
    <VehiclesQueryContext
      facetsQueries={VEHICLE_FACETS_QUERIES}
      fragments={{
        InventoryTableVehicle: InventoryTable.fragments.vehicle,
        InventoryMobileTableVehicle: InventoryMobileTable.fragments.vehicle,
        InventoryCardTableVehicle: InventoryCardTable.fragments.vehicle,
        BulkActionsVehicle: BulkActions.fragments.vehicle,
        IconGroupVehicle: IconGroup.fragments.vehicle,
      }}
      dealerId={dealerId}
      keywordsParams={keywordsParams}
    >
      <Hidden smDown>
        <div style={{ width: '100%' }}>
          <ToggleButtonGroup
            value={alignment}
            exclusive
            onChange={handleAlignment}
            aria-label="text alignment"
            style={{ float: 'right', paddingRight: '1rem', paddingTop: '1rem' }}
          >
            <ToggleButton value="list">
              <Tooltip title="List View">
                <ViewListIcon />
              </Tooltip>
            </ToggleButton>
            <ToggleButton value="module">
              <Tooltip title="Card View">
                <ViewModuleIcon />
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        {alignment === 'list' ? <InventoryTable /> : <InventoryCardTable />}
      </Hidden>
      <Hidden smUp>
        <InventoryMobileTable />
      </Hidden>
    </VehiclesQueryContext>
  );
};

export default InventoryList;
