import React, { useState } from 'react';

/* Material UI */
import { Box, Button } from '@mui/material';

/* internal */
import AcceptBuyNowDialog from './AcceptBuyNowDialog';

const BuyNowListItem = ({ id, dealer, buyNowPrice, ...rest }) => {
  const [showAcceptBuyNowDialog, setShowAcceptBuyNowDialog] = useState(false);

  return (
    <Box display="flex" justifyContent="space-between" width="100%" {...rest}>
      <AcceptBuyNowDialog
        open={showAcceptBuyNowDialog}
        id={id}
        onClose={() => setShowAcceptBuyNowDialog(false)}
        buyNowPrice={buyNowPrice}
        dealerName={dealer.name}
      />
      <Box>{dealer.name} has offered to buy it now!</Box>
      <Box>
        <Button
          variant="filled"
          style={{ backgroundColor: 'green', color: 'white' }}
          onClick={() => setShowAcceptBuyNowDialog(true)}
        >
          Accept
        </Button>
      </Box>
    </Box>
  );
};

// TODO: fragment
export default BuyNowListItem;
