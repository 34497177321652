/* External */
import React from 'react';
import gql from 'graphql-tag';
import { capitalize } from 'lodash';

/* Material UI */
import { Avatar, Badge, Tooltip } from '@mui/material';
import { CheckCircle as CheckCircleIcon, Star } from '@mui/icons-material';

/* Internal */
import { CONFIRMED_STATUSES } from 'constants.js';
import { initials } from 'utils';

function getRank(percentile) {
  if (percentile < 0.02) return { name: 'platinum', color: '#E5E1E6' };
  if (percentile < 0.1) return { name: 'gold', color: '#da9100' };
  if (percentile < 0.2) return { name: 'silver', color: '#c0c0c0' };
  if (percentile < 0.3) return { name: 'bronze', color: '#967444' };
  return null;
}

function LTVBadge({ customer, children }) {
  const globalDealerId = sessionStorage.getItem('globalDealerId');
  const rank = getRank(
    globalDealerId && customer.lifetime_total_value?.[globalDealerId],
  );

  return (
    <Badge
      sx={{
        '& .MuiBadge-badge': { padding: 0, backgroundColor: 'transparent' },
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      overlap="circular"
      invisible={!rank}
      badgeContent={
        <Tooltip
          title={`${capitalize(rank?.name)} customer lifetime total value`}
        >
          <Star
            fontSize="small"
            stroke="#000000"
            strokeWidth="1px"
            style={{ color: rank?.color }}
          />
        </Tooltip>
      }
    >
      {children}
    </Badge>
  );
}

function AppBadge({ customer, children }) {
  const { app_account: goAutoAppAcct } = customer;
  // For a user to successfully sign in the Go Auto app account, they must be BOTH confirmed and enabled!
  // When a user signs up with an external provider the confirmed status is different.
  const customerHasConfirmedAppAcct =
    CONFIRMED_STATUSES.includes(goAutoAppAcct?.status) &&
    goAutoAppAcct?.isEnabled;
  const customerHasPendingAppAcct =
    goAutoAppAcct?.status === 'UNCONFIRMED' && goAutoAppAcct?.isEnabled;
  const customerHasGoAutoAppAcct =
    customerHasConfirmedAppAcct || customerHasPendingAppAcct;

  return (
    <Badge
      sx={{
        '& .MuiBadge-badge': { padding: 0, backgroundColor: 'transparent' },
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      overlap="circular"
      invisible={!customerHasGoAutoAppAcct}
      badgeContent={
        <Tooltip
          title={
            customerHasPendingAppAcct
              ? 'Go Auto App Account Not Verified'
              : 'Go Auto App Account Enabled'
          }
        >
          <CheckCircleIcon
            fontSize="small"
            style={{
              color: customerHasPendingAppAcct ? 'orange' : 'green',
            }}
          />
        </Tooltip>
      }
    >
      {children}
    </Badge>
  );
}

/**
 * This component is for displaying a customer avatar with their initials, an
 * indication if they have the app or not, and one for their lifetime total value
 */
const CustomerAvatar = ({ customer, ...rest }) => (
  <LTVBadge customer={customer}>
    <AppBadge customer={customer}>
      <Avatar {...rest}>{initials(customer.fullname) || 'CX'}</Avatar>
    </AppBadge>
  </LTVBadge>
);

CustomerAvatar.fragments = {
  customer: gql`
    fragment CustomerAvatarCustomer on Customer {
      fullname
      lifetime_total_value
      app_account {
        status
        isEnabled
      }
    }
  `,
};

export default CustomerAvatar;
