import React, { useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { useTheme } from '@mui/material';

import { useUserContext } from 'components/MaterialUI/UserContext';

import { NoteVisibility, NoteVisibilityDisplay } from 'constants.js';

const ReconNoteModal = ({ isOpen, onClose, onSubmit }) => {
  const theme = useTheme();
  const { currentUser } = useUserContext();
  const [visibility, setVisibility] = useState(NoteVisibility.STORE_ONLY);
  const [description, setDescription] = useState('');

  const handleVisibility = event => setVisibility(event.target.value);

  const handleDescription = event => setDescription(event.target.value);

  const handleSubmit = () => {
    onSubmit(prev => [
      ...prev,
      {
        created_by: currentUser.username,
        visibility,
        description,
      },
    ]);
    setVisibility(NoteVisibility.STORE_ONLY);
    setDescription('');
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth>
      <DialogTitle>Add Recon Note</DialogTitle>
      <DialogContent>
        <FormControl>
          <InputLabel shrink>Visibility</InputLabel>
          <Select value={visibility} onChange={handleVisibility}>
            <MenuItem value={NoteVisibility.STORE_ONLY}>
              {NoteVisibilityDisplay[NoteVisibility.STORE_ONLY]}
            </MenuItem>
            <MenuItem value={NoteVisibility.MANAGERS_ONLY}>
              {NoteVisibilityDisplay[NoteVisibility.MANAGERS_ONLY]}
            </MenuItem>
            <MenuItem value={NoteVisibility.ORG_WIDE}>
              {NoteVisibilityDisplay[NoteVisibility.ORG_WIDE]}
            </MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogContent>
        <TextField
          label="Note"
          value={description}
          onChange={handleDescription}
          multiline
          maxRows={8}
          style={{ display: 'flex' }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="outlined">
          Cancel
        </Button>
        <Button
          style={theme.actions.confirm}
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={description === ''}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReconNoteModal;
