import React from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { formatPrice } from 'utils';

import { tubedPercent } from './utils';

const useStyles = makeStyles({
  multiLine: {
    whiteSpace: 'pre-line',
    fontWeight: 'bold',
    fontSize: '18px',
  },
});

const DealLogMobileMetrics = ({ metrics }) => {
  const classes = useStyles();
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>Metrics</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid
          container
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Grid
            item
            style={{
              textAlign: 'center',
            }}
          >
            <Box marginBottom={2}>
              <div>
                Writes
                <div className={classes.multiLine}>
                  {(metrics?.total_count ?? 0) -
                    (metrics?.total_tubed_count ?? 0)}
                </div>
              </div>
            </Box>
            <Box marginBottom={2}>
              Posted
              <div className={classes.multiLine}>
                {metrics?.total_posted_count ?? 0}
              </div>
              <div className={classes.multiLine} style={{ fontSize: '14px' }}>
                {formatPrice(Math.round(metrics.total_posted_gross))}
              </div>
            </Box>
          </Grid>
          <Grid
            item
            style={{
              textAlign: 'center',
            }}
          >
            <Box marginBottom={2}>
              Pending
              <div className={classes.multiLine}>
                {metrics?.total_pending_count ?? 0}
              </div>
            </Box>
            <Box marginBottom={2}>
              Delivered
              <div className={classes.multiLine}>
                {metrics?.total_delivered_count ?? 0}
              </div>
              <div className={classes.multiLine} style={{ fontSize: '14px' }}>
                {formatPrice(Math.round(metrics.total_posted_gross))}
              </div>
            </Box>
          </Grid>
          <Grid
            item
            style={{
              textAlign: 'center',
            }}
          >
            <Box marginBottom={2}>
              Approved
              <div className={classes.multiLine}>
                {metrics?.total_approved_count ?? 0}
              </div>
            </Box>
            <Box marginBottom={2}>
              Tubed
              <div className={classes.multiLine}>
                {metrics?.total_tubed_count ?? 0}
              </div>
              <div className={classes.multiLine} style={{ fontSize: '14px' }}>
                {tubedPercent(metrics)}%
              </div>
            </Box>
          </Grid>
          <Grid
            item
            style={{
              textAlign: 'center',
            }}
          >
            <Box marginBottom={2}>
              Signed
              <div className={classes.multiLine}>
                {metrics?.total_signed_count ?? 0}
              </div>
            </Box>
            <Box marginBottom={2}>
              Total Gross
              <div className={classes.multiLine}>
                {formatPrice(Math.round(metrics.total_gross))}
              </div>
            </Box>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default DealLogMobileMetrics;
