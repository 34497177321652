import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import moment from 'moment';

import InputLabel from '@mui/material/InputLabel';
import { MobileDatePicker, StaticDatePicker } from '@mui/x-date-pickers';

const KeyboardDatePickerControl = ({
  control,
  label,
  name,
  InputLabelProps = {},
  variant = 'inline',
  datePickerProps = {},
  defaultValue = null,
  ...rest
}) => {
  const formContext = useFormContext();
  return (
    <>
      {/* as of mui v5, this (InputLabel) causes issues with 
    the "outlined" variant of the new Date Pickers */}
      {/* <InputLabel {...InputLabelProps}>{label}</InputLabel> */}
      <Controller
        control={control ?? formContext.control}
        defaultValue={defaultValue ? moment(defaultValue) : null}
        render={({ field: { ref, value, ...field } }) => {
          const PickerComponent =
            variant === 'inline' ? MobileDatePicker : StaticDatePicker;
          return (
            <PickerComponent
              autoOk
              variant={variant}
              format="YYYY-MM-DD"
              value={value ? moment(value) : null}
              {...datePickerProps}
              {...field}
              label={label}
            />
          );
        }}
        name={name}
        {...rest}
      />
    </>
  );
};

export default KeyboardDatePickerControl;
