import React, { useEffect } from 'react';

/* external */
import { useLazyQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useHistory } from 'react-router-dom';

/* Material UI */
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

/* internal */
import { getMediumDateTime } from 'utils';
import AssigneeSummaryCard from './AssigneeSummaryCard';
import CustomerCard from './CustomerCard';

const LEAD_QUERY = gql`
  query Lead($_id: ID!) {
    lead(_id: $_id) {
      _id
      created
      form_data {
        lead_source
        lead_direction
        lead_channel
        campaign_name
        campaign_source
        campaign_medium
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '200px',
    height: '100%',
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  container: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
    },
    margin: 0,
    width: '100%',
  },
}));

const OpportunitySummaryCards = ({
  customer,
  opportunity,
  refetchOpportunity,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const history = useHistory();
  const desktop = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const medium = useMediaQuery(theme => theme.breakpoints.between('sm', 'lg'));
  const [getLead, { loading, data }] = useLazyQuery(LEAD_QUERY);

  useEffect(() => {
    if (opportunity.crm_lead_ids.length !== 0) {
      getLead({ variables: { _id: opportunity.crm_lead_ids[0] } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Grid
        container
        direction={medium ? 'row' : desktop ? 'row' : 'column'}
        justifyContent={
          medium ? 'space-between' : desktop ? 'space-between' : 'center'
        }
        alignItems={medium ? 'stretch' : desktop ? 'stretch' : 'center'}
        className={classes.container}
        spacing={medium ? 1 : 2}
      >
        <Grid
          item
          xs={medium ? 6 : 4}
          style={desktop ? { minWidth: '300px' } : { minWidth: '100%' }}
        >
          <CustomerCard
            customer={customer}
            refetchOpportunity={refetchOpportunity}
          />
        </Grid>
        <Grid
          item
          xs={medium ? 6 : 4}
          style={desktop ? { minWidth: '300px' } : { minWidth: '100%' }}
        >
          <AssigneeSummaryCard
            opportunity={opportunity}
            refetchOpportunity={refetchOpportunity}
          />
        </Grid>

        <Grid
          item
          xs={medium ? 12 : 4}
          style={desktop ? { minWidth: '300px' } : { minWidth: '100%' }}
        >
          {desktop && (
            <Card className={classes.root}>
              <CardContent style={{ minHeight: '200px' }}>
                <span style={{ display: 'flex' }}>
                  <Typography className={classes.title} gutterBottom>
                    Lead Details
                  </Typography>
                  {opportunity.crm_lead_ids.length > 0 && (
                    <Typography
                      variant="body2"
                      gutterBottom
                      style={{
                        marginLeft: '5px',
                        alignSelf: 'center',
                        color: 'gray',
                      }}
                    >
                      {`(x${opportunity.crm_lead_ids.length})`}
                    </Typography>
                  )}
                </span>
                <Grid container direction="row" spacing={0}>
                  {data && !loading && (
                    <>
                      <Grid item xs={12} style={{ paddingBottom: '3px' }}>
                        <b>Received:</b>{' '}
                        {` ${getMediumDateTime(data.lead.created)}`}
                      </Grid>
                      <Grid item xs={12} style={{ paddingBottom: '3px' }}>
                        <b>Source:</b>
                        {` ${data.lead.form_data.lead_source || 'N/A'}`}
                      </Grid>
                      <Grid item xs={12} style={{ paddingBottom: '3px' }}>
                        <b>Channel:</b>
                        {` ${data.lead.form_data.lead_channel || 'N/A'}`}
                      </Grid>
                      <Grid item xs={12} style={{ paddingBottom: '3px' }}>
                        <b>Campaign Name:</b>
                        {` ${data.lead.form_data.campaign_name || 'N/A'}`}
                      </Grid>
                      <Grid item xs={12} style={{ paddingBottom: '3px' }}>
                        <b>Campaign Source:</b>
                        {` ${data.lead.form_data.campaign_source || 'N/A'}`}
                      </Grid>
                      <Grid item xs={12} style={{ paddingBottom: '3px' }}>
                        <b>Campaign Medium:</b>
                        {` ${data.lead.form_data.campaign_medium || 'N/A'}`}
                      </Grid>
                      <Grid item xs={12}>
                        <b>Interested Vehicle Stock Number:</b>
                        {` ${
                          data.lead.form_data.interested_vehicle_stock || 'N/A'
                        }`}
                      </Grid>
                    </>
                  )}
                  {!data && !loading && (
                    <>
                      <Grid item xs={12} style={{ paddingBottom: '3px' }}>
                        <b>Received: </b>
                        {` ${getMediumDateTime(opportunity.created)}`}
                      </Grid>
                      <Grid item xs={12} style={{ paddingBottom: '3px' }}>
                        <b>Source: </b>
                        {` ${opportunity.marketing.lead_source || 'N/A'}`}
                      </Grid>
                      <Grid item xs={12} style={{ paddingBottom: '3px' }}>
                        <b>Direction: </b>
                        {` ${opportunity.marketing.lead_direction || 'N/A'}`}
                      </Grid>
                      <Grid item xs={12} style={{ paddingBottom: '3px' }}>
                        <b>Channel: </b>
                        {` ${opportunity.marketing.lead_channel || 'N/A'}`}
                      </Grid>
                    </>
                  )}
                </Grid>
              </CardContent>
              <CardActions
                style={{ justifyContent: 'flex-end', verticalAlign: 'bottom' }}
              >
                {data?.lead?._id && (
                  <Button
                    size="small"
                    style={theme.actions.add}
                    disabled={!data?.lead?._id}
                    onClick={() =>
                      history.push(`/leads/${data?.lead?._id || ''}`)
                    }
                  >
                    View
                  </Button>
                )}
              </CardActions>
            </Card>
          )}
          {!desktop && (
            <Accordion defaultExpanded={true}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <span style={{ display: 'flex' }}>
                  <b>Lead Details</b>{' '}
                  {opportunity.crm_lead_ids.length > 0 && (
                    <>{`(x${opportunity.crm_lead_ids.length})`}</>
                  )}
                </span>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container direction="row" spacing={0}>
                  {data && !loading && (
                    <>
                      <Grid item xs={12}>
                        <b>Received:</b>{' '}
                        {` ${getMediumDateTime(data.lead.created)}`}
                      </Grid>
                      <Grid item xs={12}>
                        <b>Source:</b>
                        {` ${data.lead.form_data.lead_source || 'N/A'}`}
                      </Grid>
                      <Grid item xs={12}>
                        <b>Channel:</b>
                        {` ${data.lead.form_data.lead_channel || 'N/A'}`}
                      </Grid>
                      <Grid item xs={12}>
                        <b>Campaign Name:</b>
                        {` ${data.lead.form_data.campaign_name || 'N/A'}`}
                      </Grid>
                      <Grid item xs={12}>
                        <b>Campaign Source:</b>
                        {` ${data.lead.form_data.campaign_source || 'N/A'}`}
                      </Grid>
                      <Grid item xs={12}>
                        <b>Campaign Medium:</b>
                        {` ${data.lead.form_data.campaign_medium || 'N/A'}`}
                      </Grid>
                      <Grid item xs={12}>
                        <b>Interested Vehicle Stock Number:</b>
                        {` ${
                          data.lead.form_data.interested_vehicle_stock || 'N/A'
                        }`}
                      </Grid>
                    </>
                  )}
                  {!data && !loading && (
                    <>
                      <Grid item xs={12} style={{ paddingBottom: '3px' }}>
                        <b>Received: </b>
                        {` ${getMediumDateTime(opportunity.created)}`}
                      </Grid>
                      <Grid item xs={12} style={{ paddingBottom: '3px' }}>
                        <b>Source: </b>
                        {` ${opportunity.marketing.lead_source || 'N/A'}`}
                      </Grid>
                      <Grid item xs={12} style={{ paddingBottom: '3px' }}>
                        <b>Direction: </b>
                        {` ${opportunity.marketing.lead_direction || 'N/A'}`}
                      </Grid>
                      <Grid item xs={12} style={{ paddingBottom: '3px' }}>
                        <b>Channel: </b>
                        {` ${opportunity.marketing.lead_channel || 'N/A'}`}
                      </Grid>
                    </>
                  )}
                </Grid>
              </AccordionDetails>
              <AccordionActions>
                {data?.lead?._id && (
                  <Button
                    size="small"
                    style={theme.actions.add}
                    disabled={!data?.lead?._id}
                    onClick={() =>
                      history.push(`/leads/${data?.lead?._id || ''}`)
                    }
                  >
                    View
                  </Button>
                )}
              </AccordionActions>
            </Accordion>
          )}
        </Grid>
      </Grid>
    </>
  );
};

OpportunitySummaryCards.fragments = {
  opportunity: gql`
    fragment OpportunityMarketing on Opportunity {
      _id
      created
      marketing {
        lead_source
        lead_direction
        lead_channel
      }
      ...AssigneeSummaryCardOpportunity
    }
    ${AssigneeSummaryCard.fragments.opportunity}
  `,
};

export default OpportunitySummaryCards;
