import React from 'react';

/* external */
import gql from 'graphql-tag';

/* Material UI */
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';

/* internal */
import { formatPrice } from 'utils';

const useStyles = makeStyles(theme => ({
  center: {
    textAlign: 'center',
  },
  fullHeight: {
    height: '100%',
  },
}));

const MinimumBidCard = ({ item }) => {
  const { center, fullHeight } = useStyles();
  const { minimumBid } = item;
  return (
    <Card className={fullHeight}>
      <CardHeader
        title="Minimum Bid"
        titleTypographyProps={{
          align: 'center',
          color: 'textSecondary',
          variant: 'h6',
        }}
      />
      <CardContent className={center}>
        <Box fontSize="h5.fontSize">{formatPrice(minimumBid)}</Box>
      </CardContent>
    </Card>
  );
};

MinimumBidCard.fragments = {
  item: gql`
    fragment MinimumBidCardItem on AuctionItem {
      minimumBid
    }
  `,
};
export default MinimumBidCard;
