import React from 'react';

/* external */
import moment from 'moment';
import gql from 'graphql-tag';

/* material ui */
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Slide from '@mui/material/Slide';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  header: {
    fontWeight: 'bold',
  },
}));

const RedemptionHistory = ({ redemptions, title, open, onClose }) => {
  const classes = useStyles();
  const rowColor = index => {
    return index % 2 === 0 ? { backgroundColor: '#f5f5f5' } : null;
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      TransitionComponent={Slide}
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent
        style={{
          minHeight: '310px',
        }}
      >
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.header}>ID</TableCell>
                <TableCell className={classes.header}>RO #</TableCell>
                <TableCell className={classes.header}>Dealership</TableCell>
                <TableCell className={classes.header}>Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {redemptions?.map((redemption, index) => (
                <TableRow key={redemption.id} style={rowColor(index)}>
                  <TableCell>{redemption.id}</TableCell>
                  <TableCell>{redemption.roNumber}</TableCell>
                  <TableCell>{redemption.dealerId}</TableCell>
                  <TableCell>
                    {moment(redemption.roDate)?.format('YYYY-MM-DD')}
                  </TableCell>
                </TableRow>
              ))}
              {redemptions?.length === 0 && (
                <TableRow style={rowColor(0)}>
                  <TableCell colSpan={4} align="center">
                    No Records Found.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" autoFocus variant="outlined">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

RedemptionHistory.fragments = {
  redemption: gql`
    fragment RedemptionHistoryRedemption on Redemption {
      id
      roNumber
      dealerId
      roDate
    }
  `,
};

export default RedemptionHistory;
