import React, { useState } from 'react';

// Internal
import { getFullDateTime } from 'utils';
import TaskReschedule from '../../tasks/components/TaskReschedule';
import TaskReassign from '../../tasks/components/TaskReassign';

// Material UI
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import RefreshIcon from '@mui/icons-material/Refresh';
import PersonIcon from '@mui/icons-material/Person';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import EmailIcon from '@mui/icons-material/Email';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import PhoneIcon from '@mui/icons-material/Phone';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { useTheme } from '@mui/material';

const TaskStatus = {
  TODO: 0,
  COMPLETE: 1,
  CANCELLED: 2,
};

const SalesDashboardMobileRow = ({
  task,
  handleUpdateStatus,
  dealerName,
  buttonColor,
  statusColor,
  refetch,
}) => {
  const [rescheduleTaskModalOpen, setRescheduleTaskModalOpen] = useState(false);
  const [reassignTaskModalOpen, setReassignTaskModalOpen] = useState(false);
  const theme = useTheme();

  const handleRescheduleTask = ({ _id, status }) => {
    if (status === TaskStatus.CANCELLED) {
      handleUpdateStatus(_id, 0);
    }
  };

  return (
    <Paper elevation={0} style={{ border: '1px' }}>
      <Box>
        <Grid container direction="row" style={{ fontSize: '12px' }} xs={12}>
          <Grid
            item
            style={{
              backgroundColor: statusColor(0, task.due_date),
              height: '150px',
            }}
          >
            <Box
              style={{
                width: '10px',
              }}
            />
          </Grid>
          <Grid item style={{ display: 'flex', flexDirection: 'row' }} xs={1}>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: '2rem',
                marginBottom: 'auto',
                justifyContent: 'space-around',
              }}
            >
              {task.type === 'opportunity' ? (
                <ThumbUpIcon />
              ) : task.type === 'email' ? (
                <EmailIcon />
              ) : task.type === 'call' ? (
                <PhoneIcon />
              ) : (
                <HelpOutlineIcon />
              )}
            </Box>
          </Grid>
          <Grid container xs={10}>
            <Grid item xs={12}>
              <Box style={{ paddingTop: '1rem' }}>
                <b>
                  {getFullDateTime(task.due_date)} @ {dealerName}
                </b>
              </Box>
              <Box>{task.description}</Box>
              <Box>
                <b>Customer:</b> {task.customer.fullname}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: 'auto',
                  marginBottom: 'auto',
                  justifyContent: 'space-around',
                  paddingBottom: '0.25rem',
                }}
              >
                <Box>
                  <Button
                    style={theme.actions.info}
                    className={buttonColor}
                    size="small"
                    onClick={() => handleUpdateStatus(task._id, 1)}
                  >
                    <CheckIcon />
                  </Button>
                </Box>
                <Box>
                  <TaskReschedule
                    open={rescheduleTaskModalOpen}
                    setOpen={setRescheduleTaskModalOpen}
                    task={task}
                    action={handleRescheduleTask}
                  />
                  <Button
                    style={theme.actions.info}
                    className={buttonColor}
                    size="small"
                    onClick={() => setRescheduleTaskModalOpen(true)}
                  >
                    <RefreshIcon />
                  </Button>
                </Box>
                <Box>
                  <TaskReassign
                    open={reassignTaskModalOpen}
                    setOpen={setReassignTaskModalOpen}
                    task={task}
                    action={refetch}
                  />
                  <Button
                    style={theme.actions.info}
                    className={buttonColor}
                    size="small"
                    onClick={() => setReassignTaskModalOpen(true)}
                  >
                    <PersonIcon />
                  </Button>
                </Box>
                <Box>
                  <Button
                    style={theme.actions.info}
                    className={buttonColor}
                    size="small"
                    onClick={() => handleUpdateStatus(task._id, 2)}
                  >
                    <CloseIcon />
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default SalesDashboardMobileRow;
