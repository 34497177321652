import React from 'react';

/* external */
import gql from 'graphql-tag';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

/* Material UI */
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { useMediaQuery, useTheme } from '@mui/material';

/* internal */
import AddOpportunityModal from './AddOpportunityModal';
import DateDisplay from './DateDisplay';
import { DEAL_TYPE } from '../constant';
import { Status, Role, STATUS_ICON } from 'constants.js';
import { useUserContext } from 'components/MaterialUI/UserContext';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '50px',
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  listItem: {
    borderColor: '#d3d3d3',
    border: '1px',
    borderStyle: 'solid',
    paddingTop: 0,
    paddingBottom: 0,
  },
  chip: {
    fontSize: 10,
    paddingLeft: 2,
    paddingRight: 2,
  },
  unknown: {
    backgroundColor: '#808080',
  },
  new: {
    backgroundColor: '#8ba22c',
  },
  used: {
    backgroundColor: '#f89406',
  },
}));

const OpportunityHeader = ({ opportunity }) => {
  const classes = useStyles();
  return (
    <ListItemText
      primaryTypographyProps={{
        style: { fontSize: 14, fontWeight: 'bold' },
      }}
      secondaryTypographyProps={{
        style: { fontSize: 14, color: 'black' },
      }}
      primary={
        <>
          {`${opportunity.status_display} Opportunity at ${
            opportunity?.dealer?.dealer_name || 'Go Auto'
          }`}{' '}
          <Icon
            style={{
              fontSize: 16,
              color: STATUS_ICON[opportunity.status].color,
            }}
            className={STATUS_ICON[opportunity.status].icon}
          />
        </>
      }
      secondary={
        <>
          {opportunity.dms_deal?.year &&
          opportunity.dms_deal?.deal_type &&
          opportunity.dms_deal?.make
            ? `Vehicle: ${opportunity.dms_deal?.year ?? ''} ${
                opportunity.dms_deal?.make_name ?? ''
              } ${opportunity.dms_deal?.model_name ?? ''}`
            : ''}{' '}
          {opportunity.dms_deal?.year && opportunity.dms_deal?.make && (
            <Chip
              size="small"
              label={DEAL_TYPE[opportunity.dms_deal.deal_type]}
              color="primary"
              className={
                (classes.chip,
                DEAL_TYPE[opportunity.dms_deal.deal_type] === 'New'
                  ? classes.new
                  : DEAL_TYPE[opportunity.dms_deal.deal_type] === 'Used'
                  ? classes.used
                  : classes.unknown)
              }
            />
          )}
        </>
      }
    />
  );
};

const OpportunitiesBar = ({ customer }) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const theme = useTheme();
  const [showAddOppModal, setAddOppModal] = React.useState(false);
  const history = useHistory();
  const desktop = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const { currentUser } = useUserContext() || {};
  const isLocked = customer?.do_not_contact || false;
  const isHidden = currentUser.role !== Role.ORGANIZATION_ADMIN && isLocked;
  const openOpps = customer.allOppsEverywhere
    .filter(opp => Status.OPEN_OPPS.includes(opp.status))
    .sort((a, b) => b.created.localeCompare(a.created));

  return (
    <>
      <Dialog
        maxWidth="xs"
        open={showAddOppModal}
        onClose={() => setAddOppModal(false)}
        fullWidth={true}
      >
        <AddOpportunityModal
          handleClose={() => setAddOppModal(false)}
          customer={customer}
        />
      </Dialog>
      {desktop ? (
        <Card className={classes.root}>
          <CardContent>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
            >
              <Grid item>
                <Typography className={classes.title} gutterBottom>
                  Open Opportunities
                </Typography>
              </Grid>
              <Grid item>
                {!isHidden && (
                  <IconButton
                    size="small"
                    onClick={e => {
                      setAddOppModal(true);
                    }}
                  >
                    <AddIcon style={theme.actions.add} />
                  </IconButton>
                )}
              </Grid>
            </Grid>
            <List>
              {openOpps.length === 0 &&
                'There are no opportunities for this customer.'}
              {openOpps.map(opportunity => {
                const isCurrentUser = currentUser.dealer_ids.includes(
                  opportunity.dealer.dealer_id,
                );
                return (
                  <ListItem
                    key={opportunity._id}
                    className={classes.listItem}
                    disabled={isHidden || !isCurrentUser}
                    onClick={() =>
                      isCurrentUser &&
                      history.push(
                        `/opportunities/${opportunity._id}/${customer._id}/details`,
                      )
                    }
                  >
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item>
                        <DateDisplay date={opportunity.created} />
                      </Grid>
                      <Grid item xs={4}>
                        <OpportunityHeader opportunity={opportunity} />
                      </Grid>
                      <Grid item xs={2}>
                        <ListItemText
                          primaryTypographyProps={{
                            style: { fontSize: 14 },
                          }}
                          secondaryTypographyProps={{
                            style: { fontSize: 14, color: 'black' },
                          }}
                          primary={`Deal#: ${
                            opportunity.dms_deal?.deal_number ?? 'NA'
                          }`}
                          secondary={`Dealer: ${opportunity.dealer.dealer_name}`}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        style={{ display: 'flex', flexDirection: 'row' }}
                      >
                        <Box
                          style={{
                            display: 'flex',
                            marginTop: 'auto',
                            marginBottom: 'auto',
                          }}
                        >
                          <ListItemText
                            primaryTypographyProps={{
                              style: { fontSize: 14 },
                            }}
                            secondaryTypographyProps={{
                              style: { fontSize: 14, color: 'black' },
                            }}
                            primary={`Stock#: ${
                              opportunity.dms_deal?.stock_number ?? 'NA'
                            }`}
                            secondary={`VIN: ${
                              opportunity.dms_deal?.vin ?? 'NA'
                            }`}
                          />
                        </Box>
                        {opportunity.dms_deal?.vin && (
                          <Tooltip title="Copy VIN">
                            <IconButton
                              onClick={e => {
                                navigator.clipboard.writeText(
                                  opportunity.dms_deal?.vin,
                                );
                                e.stopPropagation();
                                enqueueSnackbar(
                                  'VIN Copied to clipboard successfully!',
                                );
                              }}
                              size="large"
                            >
                              <FileCopyIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Grid>
                    </Grid>
                  </ListItem>
                );
              })}
            </List>
          </CardContent>
        </Card>
      ) : (
        <Accordion defaultExpanded={true}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
            >
              <Grid item>
                <Typography className={classes.title} gutterBottom>
                  Open Opportunities
                </Typography>
              </Grid>
              <Grid item>
                {!isHidden && (
                  <IconButton
                    size="small"
                    onClick={e => {
                      setAddOppModal(true);
                    }}
                  >
                    <AddIcon style={theme.actions.add} />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails style={{ paddingTop: 0 }}>
            <List style={{ paddingTop: 0 }}>
              {openOpps.length === 0 &&
                'There are no opportunities for this customer.'}
              {openOpps.map(opportunity => {
                const isCurrentUser = currentUser.dealer_ids.includes(
                  opportunity.dealer.dealer_id,
                );
                return (
                  <ListItem
                    className={classes.listItem}
                    key={opportunity._id}
                    disabled={!isCurrentUser}
                    onClick={() =>
                      isCurrentUser &&
                      history.push(
                        `/opportunities/${opportunity._id}/${customer._id}/details`,
                      )
                    }
                  >
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item>
                        <DateDisplay date={opportunity.created} />
                      </Grid>
                      <Grid item xs={9}>
                        <OpportunityHeader opportunity={opportunity} />
                        <Typography style={{ fontSize: 14 }}>
                          {`Deal#: ${
                            opportunity.dms_deal?.deal_number ?? 'NA'
                          }`}
                        </Typography>
                        <Typography style={{ fontSize: 14 }}>
                          {`Dealer: ${opportunity.dealer.dealer_name}`}
                        </Typography>
                        <Typography style={{ fontSize: 14 }}>
                          {`Stock#: ${
                            opportunity.dms_deal?.stock_number ?? 'NA'
                          }`}
                        </Typography>
                        <Typography style={{ fontSize: 14 }}>
                          {`VIN: ${opportunity.dms_deal?.vin ?? 'NA'}`}
                        </Typography>
                      </Grid>
                    </Grid>
                  </ListItem>
                );
              })}
            </List>
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
};

OpportunitiesBar.fragments = {
  customer: gql`
    fragment OpportunitiesBarFragment on Customer {
      _id
      # Using -1 as first item in dealer_ids makes it fetch all dealers that exist
      allOppsEverywhere: opportunities(dealer_ids: [-1]) {
        _id
        dealer {
          dealer_name
          dealer_id
        }
        status_display
        status
        crm_lead_ids
        marketing {
          lead_source
          lead_channel
          lead_direction
        }
        sales_reps {
          display_name
        }
        finance_managers {
          display_name
        }
        sales_managers {
          display_name
        }
        stock_type
        created
        updated
        last_status_change
        dms_deal {
          vin
          deal_type
          deal_number
          stock_number
          year
          make_name
          model_name
        }
      }
    }
  `,
};

export default OpportunitiesBar;
