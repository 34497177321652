import React from 'react';

import { getMediumDateTimeWithWeekday, withSuffix } from 'utils';

import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

const SyndicationDesktop = ({ imports, exports, me, dealerId }) => {
  return (
    <>
      <Box component="div" marginBottom={3}>
        <Card style={{ padding: '1rem' }}>
          <Typography gutterBottom variant="h5">
            Imports
          </Typography>
          <Typography>
            Your vehicles are being imported from the following source(s):
          </Typography>
          <Table style={{ width: '100%' }}>
            <TableHead>
              <TableRow>
                <TableCell>Import Name</TableCell>
                <TableCell align="center">New Vehicles</TableCell>
                <TableCell align="center">Used Vehicles</TableCell>
                <TableCell align="center">Frequency</TableCell>
                <TableCell align="center">Updated Count</TableCell>
                <TableCell align="center">Last Import Ran</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {imports.map(row => {
                const dealer = row.dealers.find(
                  dealer => dealer.dealerId === dealerId,
                );
                return (
                  <TableRow key={row.id}>
                    <TableCell>{row.name}</TableCell>
                    <TableCell align="center">
                      {dealer?.updateNew ? (
                        <CheckBoxIcon />
                      ) : (
                        <CheckBoxOutlineBlankIcon />
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {dealer?.updateUsed ? (
                        <CheckBoxIcon />
                      ) : (
                        <CheckBoxOutlineBlankIcon />
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {row.intervalHours} Hour(s)
                    </TableCell>
                    <TableCell align="center">
                      {row?.lastImportLog?.updateCount ?? 0}
                    </TableCell>
                    <TableCell align="center">
                      {getMediumDateTimeWithWeekday(
                        withSuffix(row.lastRan, 'Z'),
                        me?.goUserProfile?.settings?.timezone,
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Card>
      </Box>
      <Box component="div">
        <Card style={{ padding: '1rem' }}>
          <Typography gutterBottom variant="h5">
            Exports
          </Typography>
          <Typography>
            Your vehicles are being exported to the following source(s):
          </Typography>
          <Table style={{ width: '100%' }}>
            <TableHead>
              <TableRow>
                <TableCell>Export Name</TableCell>
                <TableCell align="center">New Vehicles</TableCell>
                <TableCell align="center">Used Vehicles</TableCell>
                <TableCell align="center">Frequency</TableCell>
                <TableCell align="center">Last Export Ran</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {exports.map(row => {
                const dealer = row.dealers.find(
                  dealer => dealer.dealerId === dealerId,
                );
                return (
                  <TableRow key={row.id}>
                    <TableCell>{row.name}</TableCell>
                    <TableCell align="center">
                      {dealer.exportNew ? (
                        <CheckBoxIcon />
                      ) : (
                        <CheckBoxOutlineBlankIcon />
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {dealer.exportUsed ? (
                        <CheckBoxIcon />
                      ) : (
                        <CheckBoxOutlineBlankIcon />
                      )}
                    </TableCell>
                    <TableCell align="center">{row.frequency}</TableCell>
                    <TableCell align="center">
                      {getMediumDateTimeWithWeekday(
                        withSuffix(row.lastRan, 'Z'),
                        me?.goUserProfile?.settings?.timezone,
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Card>
      </Box>
    </>
  );
};

export default SyndicationDesktop;
