import React from 'react';

/* external */
import gql from 'graphql-tag';

/* Material UI */
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import VehicleBox from './VehicleBox';

const useStyles = makeStyles(theme => ({
  cardContent: {
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
}));
const PitchCard = ({
  customer,
  onEditPitch,
  opportunity,
  pitch,
  refetchOpportunity,
  ...rest
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const { vehicles } = pitch ?? {};

  return (
    <Card {...rest}>
      <CardContent className={classes.cardContent}>
        {vehicles?.map(v => (
          <VehicleBox
            customer={customer}
            key={v.id}
            onEditPitch={onEditPitch}
            opportunity={opportunity}
            pitch={pitch}
            pl={3}
            pt={1}
            refetchOpportunity={refetchOpportunity}
            vehicle={v}
          />
        ))}
      </CardContent>
    </Card>
  );
};

PitchCard.fragments = {
  pitch: gql`
    fragment PitchCardPitch on Pitch {
      id
      ...VehicleBoxPitch
      vehicles {
        id
        ...VehicleBoxVehicle
      }
    }
    ${VehicleBox.fragments.pitch}
    ${VehicleBox.fragments.vehicle}
  `,
};
export default PitchCard;
