import React from 'react';
import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const headCells = [
  {
    id: 'invoice_number',
    numeric: false,
    disablePadding: false,
    label: 'Invoice #:',
    sortable: true,
  },
  {
    id: 'customer_name',
    numeric: false,
    disablePadding: false,
    label: 'Customer',
    sortable: true,
    style: null,
  },
  {
    id: 'closed_date',
    numeric: false,
    disablePadding: false,
    label: 'Closed',
    sortable: true,
    style: { minWidth: '140px' },
  },
  {
    id: 'gocard.cardNumber',
    numeric: false,
    disablePadding: false,
    label: 'Go Card',
    sortable: false,
    style: null,
  },
  {
    id: 'cashier',
    numeric: false,
    disablePadding: false,
    label: 'Cashier #:',
    sortable: true,
    style: { minWidth: '200px' },
  },

  {
    id: 'comments',
    numeric: false,
    disablePadding: false,
    label: 'Comments',
    sortable: false,
    style: null,
  },
];

const PartsTableHeader = ({ order, orderBy, onRequestSort }) => {
  const { visuallyHidden } = useStyles();
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align="left"
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            style={headCell.style}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              disabled={headCell.sortable ? false : true}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default PartsTableHeader;
