import React from 'react';

import { TableRow, TableCell, IconButton, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { makeStyles } from '@mui/styles';
import { SMS_PERMISSION_TYPES } from 'modules/sms/components/Messaging/SMSActions/const';

const useStyles = makeStyles(theme => ({
  deleteButton: {
    backgroundColor: theme.actions.danger.backgroundColor,
    color: 'white',
  },
}));

const PermissionTableRow = ({
  permission,
  rolesData,
  setShowConfirmDialog,
  setSelectedPermissionId,
}) => {
  const classes = useStyles();
  return (
    <TableRow key={permission.id}>
      <TableCell style={{ padding: '21px' }}>{permission.dealerId}</TableCell>
      <TableCell style={{ padding: '21px' }}>
        {rolesData.roles.find(x => x.id === permission.role).name}
      </TableCell>
      <TableCell style={{ padding: '21px' }}>
        {
          SMS_PERMISSION_TYPES.find(x => x.id === permission.permissionType)
            .permission_name
        }
      </TableCell>
      <TableCell style={{ padding: '21px' }}>
        <IconButton
          className={classes.deleteButton}
          size="small"
          onClick={() => {
            setShowConfirmDialog(true);
            setSelectedPermissionId(parseInt(permission.id, 10));
          }}
        >
          <Tooltip title="Delete Permission">
            <DeleteIcon />
          </Tooltip>
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default PermissionTableRow;
