import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import useMediaQuery from '@mui/material/useMediaQuery';
import FolderIcon from '@mui/icons-material/Folder';

import { useDealerContext } from 'components/MaterialUI/DealerContext';
import { useUserContext } from 'components/MaterialUI/UserContext';

const ADD_ATTACHMENT_MUTATION = gql`
  mutation attachments(
    $dataURI: String!
    $label: String!
    $tags: String!
    $vin: String!
    $dealerId: Int!
    $created_by: String!
    $app_id: String!
    $extension: String!
  ) {
    attachment {
      createAttachment(
        dataURI: $dataURI
        label: $label
        tags: $tags
        vin: $vin
        dealer_id: $dealerId
        created_by: $created_by
        app_id: $app_id
        extension: $extension
      ) {
        id
      }
    }
  }
`;

const SET_VEHICLE_HAS_UVI = gql`
  mutation setHasUVI($id: Int!, $has_uvi: Boolean!) {
    inventory {
      updateUVIVehicle(id: $id, has_uvi: $has_uvi) {
        id
        has_uvi
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  input: {
    display: 'none',
  },
  backdrop: {
    zIndex: 1000,
    color: '#fff',
  },
}));

function getBase64(file) {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      resolve(reader.result);
    };
    reader.onerror = reject;
  });
}

const VehicleUVIModal = ({ handleClose, vehicle: { id, vin } }) => {
  const { input, backdrop } = useStyles();
  const [createAttachment, { loading: mutationLoading }] = useMutation(
    ADD_ATTACHMENT_MUTATION,
  );
  const [setVehicleHasUVI] = useMutation(SET_VEHICLE_HAS_UVI);
  const { dealerId } = useDealerContext();
  const { currentUser } = useUserContext();
  const mobile = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const [upload, setUpload] = useState({
    name: '',
    dataURI: '',
    extension: '',
  });
  const [label, setLabel] = useState('');
  const { control } = useFormContext();
  const { enqueueSnackbar } = useSnackbar();

  const currentVin = useWatch({ control, name: 'vin', defaultValue: vin });
  const created_by = currentUser.username;
  const app_id = 'smi.inventory';

  async function handleChange(event) {
    const files = event.target.files;
    const extension = files[0].name.split('.').slice(-1)[0];
    let base64file = await getBase64(files[0]);
    setUpload({
      name: files[0].name,
      dataURI: base64file,
      extension,
    });
  }

  const handleLabel = event => {
    setLabel(event.target.value);
  };

  return (
    <div style={{ padding: '0 0 1rem 1rem' }}>
      <DialogTitle>Upload a Used Vehicle Inspection</DialogTitle>
      <form style={{ paddingTop: '1rem' }}>
        <TextField
          variant="outlined"
          size="small"
          style={{
            maxWidth: `${mobile ? '175px' : '150px'}`,
            width: '100%',
          }}
          value={upload.name || ''}
          disabled
        />
        <span
          style={{
            paddingLeft: '1rem',
          }}
        >
          <input
            className={input}
            id="attachment-file"
            multiple
            type="file"
            onChange={handleChange}
          />
          <label htmlFor="attachment-file">
            <Button variant="contained" color="primary" component="span">
              <FolderIcon style={{ paddingRight: '5px' }} /> BROWSE
            </Button>
          </label>
        </span>
        <div style={{ padding: '1rem 0 1rem 0' }}>
          <TextField
            label="Label"
            size="small"
            style={{
              maxWidth: '250px',
              width: '100%',
            }}
            value={label}
            onChange={handleLabel}
          />
        </div>
        <FormControl>
          <InputLabel id="attachment-tags">Tags</InputLabel>
          <Select
            labelId="attachment-tags"
            value={'UVI'}
            style={{ width: '250px' }}
            disabled
          >
            <MenuItem value={'UVI'}>Used Vehicle Inspection</MenuItem>
          </Select>
        </FormControl>
        <div style={{ paddingTop: '1rem' }}>
          {!mutationLoading && (
            <Button
              variant="contained"
              color="primary"
              component="span"
              onClick={() =>
                currentVin && currentVin !== ''
                  ? createAttachment({
                      variables: {
                        dataURI: upload.dataURI,
                        label,
                        tags: 'UVI',
                        vin: currentVin,
                        dealerId,
                        created_by,
                        app_id,
                        extension: upload.extension,
                      },
                    })
                      .catch(e => {
                        console.log(e);
                      })
                      .then(r => {
                        if (r && r && r.data.attachment.createAttachment.id) {
                          setVehicleHasUVI({
                            variables: {
                              id,
                              has_uvi: true,
                            },
                          });
                          return enqueueSnackbar(
                            `Used Vehicle Inspection Successfully created!`,
                          );
                        } else {
                          return enqueueSnackbar(
                            `Error creating attachment. Please make sure all data is provided in the fields.`,
                          );
                        }
                      })
                      .then(handleClose)
                  : enqueueSnackbar('Must enter a VIN to upload a UVI.')
              }
            >
              Upload
            </Button>
          )}
        </div>
      </form>
      {mutationLoading && (
        <>
          <p>Uploading Used Vehicle Inspection.. Please wait.</p>
          <Backdrop className={backdrop} open={true}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </>
      )}
    </div>
  );
};

export default VehicleUVIModal;
