import React from 'react';

/* external */
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';

/* Material UI */
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material';

/* internal */
import TextFieldControl from 'components/MaterialUI/TextFieldControl';
import PackageNoteCard from './PackageNoteCard';

const CREATE_NOTE = gql`
  mutation CreateNote($id: ID!, $body: String!) {
    concierge {
      createNote(id: $id, body: $body) {
        id
        body
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  card: {
    marginBottom: '15px',
    marginTop: '15px',
  },
}));

const NotesModal = ({ goldCard, onClose, isOpen, refetch }) => {
  const theme = useTheme();
  const { notes } = goldCard;

  notes.sort((a, b) => {
    return new Date(b.dateCreated) - new Date(a.dateCreated);
  });

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { handleSubmit, control, reset, formState } = useForm({
    defaultValues: { body: '' },
  });

  const [createNote] = useMutation(CREATE_NOTE, {
    onCompleted: () => {
      enqueueSnackbar('Note added', { variant: 'success' });
      reset({ note: '' });
    },
    onError: () => {
      enqueueSnackbar('Error adding note', { variant: 'error' });
    },
  });

  const onSubmit = data => {
    const { body } = data;

    createNote({
      variables: {
        id: goldCard.id,
        body,
      },
    }).then(() => {
      refetch();
    });
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        onClose();
      }}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>Notes</DialogTitle>
      <>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <TextFieldControl
                  name="body"
                  label="New note..."
                  control={control}
                  multiline
                  fullWidth
                  rules={{
                    required: 'Note must not be empty',
                  }}
                />
              </form>
            </Grid>
            <Grid item xs={12}>
              <Button
                style={theme.actions.confirm}
                variant="contained"
                color="primary"
                disabled={formState.isSubmitting}
                type="submit"
                onClick={handleSubmit(onSubmit)}
              >
                Add note
              </Button>
            </Grid>
            <Grid item xs={12}>
              {notes.map(note => (
                <PackageNoteCard
                  className={classes.card}
                  key={note.id}
                  note={note}
                />
              ))}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} style={theme.actions.close} variant="text">
            Close
          </Button>
        </DialogActions>
      </>
    </Dialog>
  );
};

NotesModal.fragments = {
  note: gql`
    fragment NotesModalNote on ConciergeNote {
      dateCreated
    }
  `,
};

export default NotesModal;
