import React from 'react';

/* external */
import { Bar } from 'react-chartjs-2';
import moment from 'moment';
import _ from 'lodash';

/* Material UI */
import { DateTimePicker } from '@mui/x-date-pickers';
import Box from '@mui/material/Box';
import CancelIcon from '@mui/icons-material/Cancel';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

/* internal */
import { formatPrice } from 'utils';
import { hideCost } from 'modules/inventory/utils';
import { useUserContext } from 'components/MaterialUI/UserContext';

const VehicleHistoryGraph = ({
  opportunities,
  leads,
  history,
  toDateTime,
  setToDateTime,
  fromDateTime,
  setFromDateTime,
  clearFromArrivedDate,
  clearToArrivedDate,
}) => {
  const { currentUser } = useUserContext();
  let oppLeadLabels = [];
  const oppCountData = [];
  const leadCountData = [];
  const dateCountData = [];
  const cost_values = [];
  const regular_price_values = [];
  const special_price_values = [];
  const msrp_values = [];

  //   Sort and group the values into a [Date]: {List of Objects In this Date} type of scheme
  const sortValues = (object, key) => {
    const sortedValues = object?.sort(
      (a, b) =>
        moment(b[key] + 'Z').startOf('day') -
        moment(a[key] + 'Z').startOf('day'),
    );

    return _(sortedValues)
      .groupBy(v => moment(v[key] + 'Z').startOf('day'))
      .value();
  };

  const opportunityResults = sortValues(
    opportunities,
    'opportunity_updated_at',
  );
  const leadResults = sortValues(leads, 'lead_created_at');
  const historyResults = sortValues(history, 'date_modified');

  //   maxNumber => for the max number of leads/opportunities to dynamically set the yAxes on the right side
  //   sumOfOpps/Leads => for the 'Total Opportunities/Leads' data at the top of the file
  let maxNumber = 0;
  let sumOfOpportunities = 0;
  let sumOfLeads = 0;

  // Okay let me explain, I promise it makes sense.
  // We are storing the values in a [Date]: [#opps, #leads, cost, regular_price, special_price, msrp]
  // style of structure. Because in order to graph we need the length of each array in the future
  // To be the same length.
  for (const [key, value] of Object.entries(opportunityResults)) {
    // only push dates that are valid (not null)
    if (key !== 'Invalid date') {
      // Add the date to the labels list no matter what, because we've never seen it before
      oppLeadLabels.push(key);
      //   Add the key=date, and the number of opportunities grouped under the specific date, to the list
      dateCountData.push({
        [key]: [value.length, 0, 0, 0, 0, 0],
      });
      if (value.length > maxNumber) {
        maxNumber = value.length;
      }
      sumOfOpportunities += value.length;
    }
  }

  for (const [key, value] of Object.entries(leadResults)) {
    // only push dates that are valid (not null)
    if (key !== 'Invalid date') {
      // If we don't have the date already in our labels, then we haven't seen it before
      // push the key, push the leads length (number of leads grouped under this date)
      // into the array
      if (!oppLeadLabels.includes(key)) {
        oppLeadLabels.push(key);
        dateCountData.push({
          [key]: [0, value.length, 0, 0, 0, 0],
        });
      }
      // ****THIS IS WHERE MAGIC HAPPENS****
      // If we have seen the date already in our labels, we already have an entry with an opp# for it
      // so we find that key, and we add the length of our leads to the 2nd spot (1st index)
      // of the array
      if (oppLeadLabels.includes(key)) {
        const arrayToAdd = (dateCountData.find(
          x => Object.keys(x)[0] === key,
        ) || {})[key];
        arrayToAdd[1] = value.length;
      }
      if (value.length > maxNumber) {
        maxNumber = value.length;
      }
      sumOfLeads += value.length;
    }
  }

  for (const [key, value] of Object.entries(historyResults)) {
    // only push dates that are valid (not null)
    if (key !== 'Invalid date') {
      // okay, let me explain why we're sorting.. again.
      // we sometimes have a lot of records for 1 day, so we sort them with the
      // most recent record at the top (i.e. what the values were at at the END OF THE DAY)
      // and we push these into the list
      const sortedPrices2 = value?.sort(
        (a, b) => moment(b.date_modified) - moment(a.date_modified),
      );
      if (!oppLeadLabels.includes(key)) {
        oppLeadLabels.push(key);
        dateCountData.push({
          [key]: [
            0,
            0,
            sortedPrices2[0].cost,
            sortedPrices2[0].regular_price,
            sortedPrices2[0].special_price,
            sortedPrices2[0].msrp,
          ],
        });
      }
      if (oppLeadLabels.includes(key)) {
        // again, if we already have the key, we add our sorted prices again to the pre-existing array
        const arrayToAdd = (dateCountData.find(
          x => Object.keys(x)[0] === key,
        ) || {})[key];
        arrayToAdd[2] = sortedPrices2[0].cost;
        arrayToAdd[3] = sortedPrices2[0].regular_price;
        arrayToAdd[4] = sortedPrices2[0].special_price;
        arrayToAdd[5] = sortedPrices2[0].msrp;
      }
    }
  }

  // we re-sort because, why not?? I mean, we need to re-sort because in the cluster
  // above, some things get shuffled. Big thanks to Brandon for saving my brain with this one.
  const sortedDate = dateCountData?.sort((a, b) =>
    moment(Object.keys(a)[0]) > moment(Object.keys(b)[0]) ? 1 : -1,
  );

  //   We also have to sort our oppLabels so that they match up perfectly with the sortedDate info
  oppLeadLabels = oppLeadLabels.sort((a, b) =>
    moment(a) > moment(b) ? 1 : -1,
  );

  // From here, we filter the labels to make sure that they are in the date range
  // the user specified, and also add them to our FINAL labels list. (woo almost done)
  let labels = [];
  oppLeadLabels.forEach(date => {
    if (
      moment(date + 'Z').isBefore(toDateTime, 'day') &&
      moment(date + 'Z').isAfter(fromDateTime, 'day')
    )
      labels.push(moment(date).format('YYYY-MM-DD'));
  });

  // This is the fun part, promise.
  for (let i = 0; i < dateCountData.length; i++) {
    const values = Object.values(sortedDate[i]);
    const key = Object.keys(sortedDate[i]);
    // date filter
    if (
      moment(key[0] + 'Z').isBefore(toDateTime, 'day') &&
      moment(key[0] + 'Z').isAfter(fromDateTime, 'day')
    ) {
      if (values[0][2] > 0) {
        // If the cost > 0 that means we had a price change, we can just push these values to their respective arrays.
        // There are 6 data arrays, that all need to be the same length in order for the
        // graph to be able to understand what the heck is going on.
        oppCountData.push(values[0][0]);
        leadCountData.push(values[0][1]);
        cost_values.push(values[0][2]);
        regular_price_values.push(values[0][3]);
        special_price_values.push(values[0][4]);
        msrp_values.push(values[0][5]);
      } else if (values[0][2] === 0 && i > 0) {
        // IF the cost is 0, that means that there were no price changes on this day.
        // Therefore, if the index is > 0, we need to forward-fill (?) this data.
        // So we get the cost values from the point before this one, and fill those
        // this is so that our line doesnt go from 55k to 0, back to 56k, because pricing
        // wasn't changed on the day in the middle. so we just patch "lost" data.
        let values2 = Object.values(sortedDate[i - 1]);
        for (let j = i; j >= 0; j--) {
          if (Object.values(sortedDate[j])[0][2] > 0) {
            values2 = Object.values(sortedDate[j]);
            break;
          }
        }
        oppCountData.push(values[0][0]);
        leadCountData.push(values[0][1]);
        cost_values.push(values2[0][2]);
        regular_price_values.push(values2[0][3]);
        special_price_values.push(values2[0][4]);
        msrp_values.push(values2[0][5]);
      } else {
        //   If none of the above works, F it, it's gonna be 0.
        oppCountData.push(values[0][0]);
        leadCountData.push(values[0][1]);
        cost_values.push(values[0][2]);
        regular_price_values.push(values[0][3]);
        special_price_values.push(values[0][4]);
        msrp_values.push(values[0][5]);
      }
    }
  }

  const defaultValues = {
    fill: false,
    lineTension: 0.1,
    borderCapStyle: 'butt',
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: 'miter',
    pointBackgroundColor: '#fff',
    pointBorderWidth: 3,
    pointHoverRadius: 5,
    pointHoverBorderColor: 'rgba(220,220,220,1)',
    pointHoverBorderWidth: 2,
    pointRadius: 4,
    pointHitRadius: 10,
    cubicInterpolationMode: 'monotone',
  };

  const data = {
    labels: labels,
    datasets: [
      {
        ...defaultValues,
        label: 'Special Price',
        backgroundColor: 'rgba(0,128,0,0.4)',
        borderColor: 'rgba(0,128,0,1)',
        pointBorderColor: 'rgba(0,128,0,1)',
        pointHoverBackgroundColor: 'rgba(0,128,0,1)',
        data: special_price_values,
        type: 'line',
        yAxisID: 'A',
      },
      {
        ...defaultValues,
        label: 'Regular Price',
        backgroundColor: 'rgba(255,0,0,0.4)',
        borderColor: 'rgba(255,0,0,1)',
        pointBorderColor: 'rgba(255,0,0,1)',
        pointHoverBackgroundColor: 'rgba(255,0,0,1)',
        data: regular_price_values,
        type: 'line',
        yAxisID: 'A',
      },
      {
        ...defaultValues,
        label: 'MSRP',
        backgroundColor: 'rgba(0,0,255,0.4)',
        borderColor: 'rgba(0,0,255,1)',
        pointBorderColor: 'rgba(0,0,255,1)',
        pointHoverBackgroundColor: 'rgba(0,0,255,1)',
        data: msrp_values,
        type: 'line',
        yAxisID: 'A',
        hidden: true,
      },
      {
        type: 'bar',
        label: 'Opportunities',
        backgroundColor: 'rgba(135, 211, 124, 1)',
        data: oppCountData,
        yAxisID: 'B',
      },
      {
        type: 'bar',
        label: 'Leads',
        backgroundColor: 'rgb(75, 192, 192)',
        data: leadCountData,
        yAxisID: 'B',
      },
    ],
  };
  if (!hideCost(currentUser.role)) {
    data.datasets.push({
      ...defaultValues,
      type: 'line',
      label: 'Cost',
      backgroundColor: 'rgba(255,200,0,0.4)',
      borderColor: 'rgba(255,200,0,1)',
      pointBorderColor: 'rgba(255,200,0,1)',
      pointHoverBackgroundColor: 'rgba(255,200,0,1)',
      data: cost_values,
      yAxisID: 'A',
    });
  }
  return (
    <>
      <Grid container direction="row">
        <Grid item xs={6}>
          <Grid container direction="column">
            <Grid item xs={6} style={{ fontSize: '16px' }}>
              <b>Total Leads Created:</b> <u>{sumOfLeads}</u>
            </Grid>
            <Grid item xs={6} style={{ fontSize: '16px' }}>
              <b>Total Opportunities Created:</b> <u>{sumOfOpportunities}</u>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} style={{ textAlign: 'right' }}>
          <Box>
            <DateTimePicker
              onChange={value => setFromDateTime(value)}
              value={fromDateTime}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={e => {
                        e.stopPropagation();
                        clearFromArrivedDate();
                      }}
                      size="large"
                    >
                      <CancelIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              label={'Date From'}
              format="YYYY-MM-DD HH:mm A"
            />
          </Box>
          <DateTimePicker
            onChange={value => setToDateTime(value)}
            value={toDateTime}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={e => {
                      e.stopPropagation();
                      clearToArrivedDate();
                    }}
                    size="large"
                  >
                    <CancelIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            label={'Date To'}
            format="YYYY-MM-DD HH:mm A"
          />
        </Grid>
      </Grid>
      <Hidden smDown>
        <Bar
          data={data}
          options={{
            scales: {
              xAxes: [
                {
                  type: 'time',
                  time: {
                    unit: 'day',
                    displayFormats: {
                      day: 'MMM DD YYYY',
                    },
                  },
                },
              ],
              yAxes: [
                {
                  id: 'A',
                  type: 'linear',
                  position: 'left',
                  ticks: {
                    callback: function (value, index, values) {
                      return formatPrice(value);
                    },
                  },
                },
                {
                  id: 'B',
                  type: 'linear',
                  position: 'right',
                  ticks: {
                    max: maxNumber,
                    min: 0,
                    stepSize: 1,
                  },
                },
              ],
            },
            tooltips: {
              callbacks: {
                label: function (t, d) {
                  const label = d.datasets[t.datasetIndex].label;
                  if (t.datasetIndex < 4) {
                    return label + ': ' + formatPrice(t.yLabel);
                  } else {
                    return label + ': ' + t.yLabel;
                  }
                },
              },
            },
          }}
          width={100}
          height={50}
        />
      </Hidden>
      <Hidden smUp>
        <Bar
          data={data}
          options={{
            scales: {
              xAxes: [
                {
                  type: 'time',
                  time: {
                    unit: 'day',
                    displayFormats: {
                      day: 'MMM DD YYYY',
                    },
                  },
                  barPercentage: 1,
                },
              ],
              yAxes: [
                {
                  id: 'A',
                  type: 'linear',
                  position: 'left',
                  ticks: {
                    callback: function (value, index, values) {
                      return formatPrice(value);
                    },
                  },
                },
                {
                  id: 'B',
                  type: 'linear',
                  position: 'right',
                  ticks: {
                    max: maxNumber,
                    min: 0,
                    stepSize: 1,
                  },
                },
              ],
            },
            tooltips: {
              callbacks: {
                label: function (t, d) {
                  const label = d.datasets[t.datasetIndex].label;
                  if (t.datasetIndex < 4) {
                    return label + ': ' + formatPrice(t.yLabel);
                  } else {
                    return label + ': ' + t.yLabel;
                  }
                },
              },
            },
          }}
          width={100}
          height={100}
        />
      </Hidden>
    </>
  );
};

export default VehicleHistoryGraph;
