import React, { useState } from 'react';

/* external */
import gql from 'graphql-tag';
import { useTheme } from '@mui/material';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';

/* Material UI */
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const UPDATE_OPPORTUNITY_STOCK_TYPE = gql`
  mutation updateOpportunityStockType(
    $_id: ID!
    $input: UpdateOpportunityStockTypeInput!
  ) {
    updateOpportunityStockType(_id: $_id, input: $input) {
      _id
      stock_type
    }
  }
`;

const STOCK_TYPES = {
  New: 'New',
  Used: 'Used',
  Unknown: 'Unknown',
};

const UpdateOpportunityStockTypeModal = ({
  opportunity,
  onClose,
  isOpen,
  refetch,
}) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  var currentStockType = '';
  Object.keys(STOCK_TYPES)
    .filter(key => STOCK_TYPES[key] === opportunity.deal_type)
    .map(key => (currentStockType = key));
  const [stockType, setStockType] = useState(currentStockType);
  const [updateOpportunityStockType] = useMutation(
    UPDATE_OPPORTUNITY_STOCK_TYPE,
    {
      onCompleted: () => {
        onClose();
        refetch();
        enqueueSnackbar('Opportunity stock type updated!', {
          variant: 'success',
        });
      },
      onError: err => {
        enqueueSnackbar(`Could not update opportunity stock type: ${err}`, {
          variant: 'error',
        });
      },
    },
  );

  const handleChange = event => {
    setStockType(event.target.value);
  };

  const handleSubmit = () => {
    updateOpportunityStockType({
      variables: {
        _id: opportunity._id,
        input: {
          stock_type: stockType,
        },
      },
    });
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Update Opportunity Stock Type</DialogTitle>
      <DialogContent>
        <FormControl>
          <InputLabel>Stock Type</InputLabel>
          <Select
            value={stockType}
            onChange={handleChange}
            style={{ minWidth: 300 }}
          >
            {Object.keys(STOCK_TYPES).map(key => (
              <MenuItem key={key} value={key}>
                {STOCK_TYPES[key]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="outlined">
          Cancel
        </Button>
        <Button
          style={theme.actions.confirm}
          onClick={handleSubmit}
          variant="contained"
          color="primary"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateOpportunityStockTypeModal;
