import React from 'react';
import { Route, Switch } from 'react-router-dom';

import app from '../../app';
import Kiosks from './Kiosks';
import Opportunities from './Opportunities/Opportunities';
import PitchSheets from './PitchSheets';

const Admin = ({ match }) => (
  <app.components.Layout>
    <Switch>
      <Route exact path={`${match.url}/kiosks`} render={() => <Kiosks />} />
      <Route
        exact
        path={`${match.url}/pitch-settings`}
        render={() => <PitchSheets />}
      />
      <Route
        exact
        path={`${match.url}/opportunities`}
        render={() => <Opportunities />}
      />
    </Switch>
  </app.components.Layout>
);

export default Admin;
