import React from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

const LeadExtraDataCard = ({ extraData, classes }) => (
  <Card style={{ height: '100%' }}>
    <CardContent>
      <Typography style={{ padding: '0 0 .5rem 1rem', fontWeight: '700' }}>
        Lead Extra Data
      </Typography>
      <TableContainer>
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
        >
          <colgroup>
            <col style={{ width: '50%' }} />
            <col style={{ width: '50%' }} />
          </colgroup>
          <TableBody>
            {Object.entries(extraData).map(field => (
              <TableRow key={field}>
                <TableCell>{field[0]}</TableCell>
                <TableCell>
                  <b>{field[1] || '-'}</b>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </CardContent>
  </Card>
);

export default LeadExtraDataCard;
