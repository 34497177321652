import React from 'react';

/* external */
import { Switch, Route } from 'react-router-dom';

/* internal */
import app from 'modules/app';
import CarsenseListView from './carsense/CarsenseListView';

const Carsense = ({ match }) => {
  return (
    <app.components.Layout title="Carsense">
      <Switch>
        <Route exact path={`${match.url}/`} component={CarsenseListView} />
      </Switch>
    </app.components.Layout>
  );
};

export default Carsense;
