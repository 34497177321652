import React from 'react';

/* Material UI */
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';

// Renders a set of Radio buttons to be used with RadioControl2
const RenderableRadioGroup = ({
  onChange,
  value,
  RadioProps,
  options,
  optionValueKey = 'value',
  optionNameKey = 'name',
  ...rest
}) => (
  <RadioGroup
    onChange={e => onChange(e.target.value)}
    style={{ display: 'flex', flexDirection: 'row' }}
    value={String(value)}
    {...rest}
  >
    {options.map(option => (
      <FormControlLabel
        control={<Radio {...RadioProps} color="secondary" />}
        key={option[optionValueKey]}
        label={option[optionNameKey]}
        value={option[optionValueKey]}
      />
    ))}
  </RadioGroup>
);

export default RenderableRadioGroup;
