import React, { useState } from 'react';

/* external */
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useSnackbar } from 'notistack';

/* Material UI */
import { useMediaQuery } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Loading from 'components/MaterialUI/Loading';
import { useUserContext } from 'components/MaterialUI/UserContext';

/* internal */
import { Role } from 'constants.js';
import { initials } from 'utils';
import AssignRepsModal from './AssignRepsModal';

const REMOVE_SALES_REP = gql`
  mutation assignSalesRep($_id: ID!, $input: SalesRepsInput!) {
    unassignSalesRepsToOpportunity(_id: $_id, input: $input) {
      _id
      sales_reps {
        username
        display_name
      }
    }
  }
`;

const REMOVE_SALES_MANAGER = gql`
  mutation assignSalesMgr($_id: ID!, $input: SalesManagersInput!) {
    assignSalesManagersToOpportunity(_id: $_id, input: $input) {
      _id
    }
  }
`;

const REMOVE_FINANCE_REP = gql`
  mutation assignFinanceRep($_id: ID!, $input: FinanceManagersInput!) {
    assignFinanceManagersToOpportunity(_id: $_id, input: $input) {
      _id
    }
  }
`;

const REMOVE_BDC_REP = gql`
  mutation assignBDCReps($_id: ID!, $input: BDCRepsInput!) {
    assignBDCRepsToOpportunity(_id: $_id, input: $input) {
      _id
    }
  }
`;

const BULK_UPDATE_TASKS = gql`
  mutation UpdateTasks($ids: [ID!]!, $input: UpdateTaskInput!) {
    updateTasks(ids: $ids, input: $input) {
      _id
      assignee {
        username
        display_name
        role
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '200px',
    height: '100%',
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
  },
}));

const AssigneeSummaryCard = ({ opportunity, refetchOpportunity }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const desktop = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const [openAssigneesModal, setOpenAssigneesModal] = useState(false);
  const [reloading, setReloading] = useState(false);
  const { currentUser } = useUserContext();

  const [removeSalesRep] = useMutation(REMOVE_SALES_REP, {
    onCompleted: data => {
      enqueueSnackbar('Sales Rep Removed!', { variant: 'success' });
      refetchOpportunity();
      setReloading(false);
    },
    onError: e =>
      enqueueSnackbar(`An error has occurred: ${JSON.stringify(e)}`),
  });

  const [removeBdcRep] = useMutation(REMOVE_BDC_REP, {
    onCompleted: data => {
      enqueueSnackbar('Bdc Rep Removed!', { variant: 'success' });
      refetchOpportunity();
      setReloading(false);
    },
    onError: e =>
      enqueueSnackbar(`An error has occurred: ${JSON.stringify(e)}`),
  });

  const [removeSalesManager] = useMutation(REMOVE_SALES_MANAGER, {
    onCompleted: data => {
      enqueueSnackbar('Sales Manager Removed!', { variant: 'success' });
      refetchOpportunity();
      setReloading(false);
    },
    onError: e =>
      enqueueSnackbar(`An error has occurred: ${JSON.stringify(e)}`),
  });

  const [removeFinanceRep] = useMutation(REMOVE_FINANCE_REP, {
    onCompleted: data => {
      enqueueSnackbar('Finance Manager Removed!', { variant: 'success' });
      refetchOpportunity();
      setReloading(false);
    },
    onError: e =>
      enqueueSnackbar(`An error has occurred: ${JSON.stringify(e)}`),
  });

  const [bulkReassignTasks] = useMutation(BULK_UPDATE_TASKS, {
    onCompleted: data => {
      refetchOpportunity();
      enqueueSnackbar('Bulk reassign successfully', { variant: 'success' });
    },
    onError: err => {
      enqueueSnackbar(`Could not bulk reassign task error: ${err}`, {
        variant: 'error',
      });
    },
  });

  const salesReps = opportunity?.sales_reps || [];
  const salesManagers = opportunity?.sales_managers || [];
  const financeManagers = opportunity?.finance_managers || [];
  const bdcReps = opportunity?.bdc_reps || [];
  const noAssignees =
    salesReps.length === 0 &&
    salesManagers.length === 0 &&
    financeManagers.length === 0 &&
    bdcReps.length === 0;

  const handleModalClose = () => {
    setOpenAssigneesModal(false);
    refetchOpportunity();
  };

  const handleSalesRepDelete = username => {
    // Update assignees without removed rep
    const filteredReps = salesReps
      .map(x => x.username)
      .filter(x => x !== username);
    setReloading(true);
    removeSalesRep({
      variables: {
        _id: opportunity?._id,
        input: {
          sales_reps: filteredReps,
        },
      },
    });

    bulkReassignTasks({
      variables: {
        ids: opportunity?.tasks.filter(t => t.status === 0).map(t => t._id),
        input: {
          assignee: filteredReps.length ? filteredReps[0] : '',
        },
      },
    });
  };

  const handleBdcRepDelete = username => {
    // Update assignees without removed rep
    setReloading(true);
    removeBdcRep({
      variables: {
        _id: opportunity?._id,
        input: {
          bdc_reps: bdcReps.map(x => x.username).filter(x => x !== username),
        },
      },
    });
  };

  const handleManagerDelete = () => {
    setReloading(true);
    removeSalesManager({
      variables: {
        _id: opportunity?._id,
        input: { sales_managers: [] },
      },
    });
  };

  const handleFinanceDelete = () => {
    setReloading(true);
    removeFinanceRep({
      variables: {
        _id: opportunity?._id,
        input: { finance_managers: [] },
      },
    });
  };

  return (
    <>
      <Dialog
        maxWidth="sm"
        open={openAssigneesModal}
        onClose={() => {
          setOpenAssigneesModal(false);
          refetchOpportunity();
        }}
      >
        <AssignRepsModal
          opportunity={opportunity}
          handleClose={handleModalClose}
          refetchOpportunity={refetchOpportunity}
        />
      </Dialog>
      {desktop && (
        <Card className={classes.root}>
          <CardContent style={{ minHeight: '200px' }}>
            <Box display="flex" style={{ marginBottom: '10px' }}>
              <Typography className={classes.title} gutterBottom>
                Assignees
              </Typography>
              <IconButton
                onClick={() => setOpenAssigneesModal(true)}
                size="small"
                style={{ marginLeft: '15px' }}
              >
                <AddIcon style={theme.actions.add} />
              </IconButton>
            </Box>
            {reloading && <Loading />}
            {!reloading && (
              <Grid
                container
                direction={'column'}
                justifyContent={'space-between'}
                alignItems={'stretch'}
                spacing={1}
              >
                {noAssignees && (
                  <Typography variant="body2">
                    No assignees on the opportunity.
                  </Typography>
                )}
                {salesReps.length !== 0 &&
                  salesReps.map(salesRep => (
                    <Box
                      display="flex"
                      key={salesRep.username}
                      style={{ marginBottom: '5px' }}
                    >
                      <Box style={{ marginRight: '15px' }}>
                        <Avatar>{initials(salesRep.display_name)}</Avatar>
                      </Box>
                      <Grid item xs={10} style={{ whiteSpace: 'pre-line' }}>
                        <Typography variant="body2">
                          {salesRep.display_name}
                        </Typography>
                        <Typography variant="body2" style={{ color: 'gray' }}>
                          Sales Person
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <IconButton
                          size="small"
                          onClick={() => {
                            currentUser?.role === Role.SALES_REP
                              ? enqueueSnackbar(
                                  "Sales Person can't be unassigned from opportunity!",
                                  { variant: 'error' },
                                )
                              : handleSalesRepDelete(salesRep.username);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    </Box>
                  ))}
                {salesManagers.length !== 0 && (
                  <Box display="flex" style={{ marginBottom: '5px' }}>
                    <Box style={{ marginRight: '15px' }}>
                      <Avatar>{initials(salesManagers[0].display_name)}</Avatar>
                    </Box>
                    <Grid item xs={10} style={{ whiteSpace: 'pre-line' }}>
                      <Typography variant="body2">
                        {salesManagers[0].display_name}
                      </Typography>
                      <Typography variant="body2" style={{ color: 'gray' }}>
                        Sales Manager
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <IconButton
                        size="small"
                        onClick={() => handleManagerDelete()}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Box>
                )}
                {financeManagers.length !== 0 && (
                  <Box display="flex" style={{ marginBottom: '5px' }}>
                    <Box style={{ marginRight: '15px' }}>
                      <Avatar>
                        {initials(financeManagers[0].display_name)}
                      </Avatar>
                    </Box>
                    <Grid item xs={10} style={{ whiteSpace: 'pre-line' }}>
                      <Typography variant="body2">
                        {financeManagers[0].display_name}
                      </Typography>
                      <Typography variant="body2" style={{ color: 'gray' }}>
                        Finance Manager
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <IconButton
                        size="small"
                        onClick={() => handleFinanceDelete()}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Box>
                )}
                {bdcReps.length !== 0 &&
                  bdcReps.map(bdcRep => (
                    <Box
                      display="flex"
                      key={bdcRep.username}
                      style={{ marginBottom: '5px' }}
                    >
                      <Box style={{ marginRight: '15px' }}>
                        <Avatar>{initials(bdcRep.display_name)}</Avatar>
                      </Box>
                      <Grid item xs={10} style={{ whiteSpace: 'pre-line' }}>
                        <Typography variant="body2">
                          {bdcRep.display_name}
                        </Typography>
                        <Typography variant="body2" style={{ color: 'gray' }}>
                          Bdc Rep
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <IconButton
                          size="small"
                          onClick={() => handleBdcRepDelete(bdcRep.username)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    </Box>
                  ))}
              </Grid>
            )}
          </CardContent>
        </Card>
      )}
      {!desktop && (
        <Accordion defaultExpanded={true}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <span style={{ display: 'flex' }}>
              <b>Assignees</b>
              <IconButton
                onClick={() => setOpenAssigneesModal(true)}
                size="small"
                style={{ marginLeft: '15px' }}
              >
                <AddIcon style={theme.actions.add} />
              </IconButton>
            </span>
          </AccordionSummary>
          <AccordionDetails>
            {reloading && <Loading />}
            {!reloading && (
              <Grid container direction={'row'} spacing={1}>
                {noAssignees && (
                  <Typography variant="body2">
                    No assignees on the opportunity.
                  </Typography>
                )}
                {salesReps.length !== 0 &&
                  salesReps.map(salesRep => (
                    <React.Fragment key={salesRep.username}>
                      <Grid item xs={3}>
                        <Avatar>{initials(salesRep.display_name)}</Avatar>
                      </Grid>
                      <Grid item xs={8} style={{ whiteSpace: 'pre-line' }}>
                        <Typography variant="body2">
                          {salesRep.display_name}
                        </Typography>
                        <Typography variant="body2" style={{ color: 'gray' }}>
                          Sales Person
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <IconButton
                          size="small"
                          onClick={() =>
                            handleSalesRepDelete(salesRep.username)
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    </React.Fragment>
                  ))}
                {salesManagers.length !== 0 && (
                  <>
                    <Grid item xs={3}>
                      <Avatar>{initials(salesManagers[0].display_name)}</Avatar>
                    </Grid>
                    <Grid item xs={8} style={{ whiteSpace: 'pre-line' }}>
                      <Typography variant="body2">
                        {salesManagers[0].display_name}
                      </Typography>
                      <Typography variant="body2" style={{ color: 'gray' }}>
                        Sales Manager
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton
                        size="small"
                        onClick={() => handleManagerDelete()}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </>
                )}
                {financeManagers.length !== 0 && (
                  <>
                    <Grid item xs={3}>
                      <Avatar>
                        {initials(financeManagers[0].display_name)}
                      </Avatar>
                    </Grid>
                    <Grid item xs={8} style={{ whiteSpace: 'pre-line' }}>
                      <Typography variant="body2">
                        {financeManagers[0].display_name}
                      </Typography>
                      <Typography variant="body2" style={{ color: 'gray' }}>
                        Finance Manager
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton
                        size="small"
                        onClick={() => handleFinanceDelete()}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </>
                )}
                {bdcReps.length !== 0 &&
                  bdcReps.map(bdcRep => (
                    <>
                      <Grid item xs={3} key={bdcRep.username}>
                        <Avatar>{initials(bdcRep.display_name)}</Avatar>
                      </Grid>
                      <Grid item xs={8} style={{ whiteSpace: 'pre-line' }}>
                        <Typography variant="body2">
                          {bdcRep.display_name}
                        </Typography>
                        <Typography variant="body2" style={{ color: 'gray' }}>
                          Bdc Rep
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <IconButton
                          size="small"
                          onClick={() => handleBdcRepDelete(bdcRep.username)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    </>
                  ))}
              </Grid>
            )}
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
};

AssigneeSummaryCard.fragments = {
  opportunity: gql`
    fragment AssigneeSummaryCardOpportunity on Opportunity {
      _id
      dealer_id
      tasks {
        _id
        status
        assignee {
          username
        }
      }
      bdc_reps {
        username
        display_name
      }
      customer_reps {
        username
        display_name
      }
      sales_managers {
        username
        display_name
      }
      sales_reps {
        username
        display_name
      }
      finance_managers {
        username
        display_name
      }
    }
  `,
};

export default AssigneeSummaryCard;
