export const AppraisalType = {
  TRADE_IN: 'trade-in',
  USED_VEHICLE: 'used-vehicle',
};

export const YEAR_SORT = { model: 'Appraisal', field: 'year' };
export const MAKE_SORT = { model: 'Appraisal', field: 'make' };
export const MODEL_SORT = { model: 'Appraisal', field: 'model' };
export const ODOMETER_SORT = { model: 'Appraisal', field: 'odometer' };
export const CREATED_SORT = { model: 'Appraisal', field: 'createdAt' };
export const RECON_COST_SORT = {
  model: 'Appraisal',
  field: 'estimatedReconCost',
};
export const VALUE_SORT = { model: 'Appraisal', field: 'value' };

const up = sort => ({ ...sort, direction: 'asc' });
const dn = sort => ({ ...sort, direction: 'desc' });

const YMKMO = [YEAR_SORT, MAKE_SORT, MODEL_SORT];
const MKMOY = [MAKE_SORT, MODEL_SORT, YEAR_SORT];
const MOMKY = [MODEL_SORT, MAKE_SORT, YEAR_SORT];
const MOYMK = [MODEL_SORT, YEAR_SORT, MAKE_SORT];

export const VEHICLE_SORT_OPTIONS = [
  {
    sortOption: YMKMO.map(up),
    label: 'Year/Make/Model ASC',
    shortLabel: 'Yr/Mk/Mdl',
  },
  {
    sortOption: YMKMO.map(dn),
    label: 'Year/Make/Model DESC',
    shortLabel: 'Yr/Mk/Mdl',
  },
  {
    sortOption: MKMOY.map(up),
    label: 'Make/Model/Year ASC',
    shortLabel: 'Mk/Mdl/Yr',
  },
  {
    sortOption: MKMOY.map(dn),
    label: 'Make/Model/Year DESC',
    shortLabel: 'Mk/Mdl/Yr',
  },
  {
    sortOption: MOMKY.map(up),
    label: 'Model/Make/Year ASC',
    shortLabel: 'Mdl/Mk/Yr',
  },
  {
    sortOption: MOMKY.map(dn),
    label: 'Model/Make/Year DESC',
    shortLabel: 'Mdl/Mk/Yr',
  },
  {
    sortOption: MOYMK.map(up),
    label: 'Model/Year/Make ASC',
    shortLabel: 'Mdl/Yr/Mk',
  },
  {
    sortOption: MOYMK.map(dn),
    label: 'Model/Year/Make DESC',
    shortLabel: 'Mdl/Yr/Mk',
  },
  {
    sortOption: [up(ODOMETER_SORT)],
    label: 'Odometer ASC',
    shortLabel: 'Odom.',
  },
  {
    sortOption: [dn(ODOMETER_SORT)],
    label: 'Odometer DESC',
    shortLabel: 'Odom.',
  },
];

export const CBB_COMPLETE_FACET = {
  model: 'Appraisal',
  field: 'isCbbComplete',
};
export const NEEDS_NUMBERS_FACET = {
  model: 'Appraisal',
  field: 'needsNumbers',
};
export const MISSING_BOTH_FACET = {
  model: 'Appraisal',
  field: 'missingCbbAndNumbers',
};

export const CATEGORY_FILTERS = [
  {
    facet: null,
    value: null,
  },
  {
    facet: NEEDS_NUMBERS_FACET,
    value: true,
  },
  {
    facet: CBB_COMPLETE_FACET,
    value: false,
  },
  {
    facet: MISSING_BOTH_FACET,
    value: true,
  },
];
