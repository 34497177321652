import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import { CBB_CONDITIONS } from './index';
import { formatPrice, titleize } from 'utils';

const ValuationDesktop = ({
  condition,
  priceAdjustments,
  retailPrices,
  retailTotals,
  wholesalePrices,
  wholesaleTotals,
}) => {
  return (
    <TableContainer>
      <Table style={{ minWidth: '700px' }}>
        <TableHead>
          <TableRow>
            <TableCell rowSpan={1} />
            <TableCell colSpan={3} align="left">
              Wholesale
            </TableCell>
            <TableCell colSpan={3} align="left">
              Retail
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell rowSpan={1} />
            <TableCell style={{ textAlign: 'left' }}>Price</TableCell>
            <TableCell style={{ textAlign: 'left' }}>Adjustment</TableCell>
            <TableCell style={{ textAlign: 'left' }}>Total</TableCell>
            <TableCell style={{ textAlign: 'left' }}>Price</TableCell>
            <TableCell style={{ textAlign: 'left' }}>Adjustment</TableCell>
            <TableCell style={{ textAlign: 'left' }}>Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {CBB_CONDITIONS.map(key => (
            <TableRow selected={key === condition} key={key}>
              <TableCell
                style={key === condition ? { fontWeight: 'bold' } : {}}
              >
                {titleize(key)}
              </TableCell>
              <TableCell align="left">
                {formatPrice(wholesalePrices[key])}
              </TableCell>
              <TableCell align="left">
                {formatPrice(priceAdjustments(key))}
              </TableCell>
              <TableCell align="left">
                {formatPrice(wholesaleTotals[key])}
              </TableCell>
              <TableCell align="left">
                {formatPrice(retailPrices[key])}
              </TableCell>
              <TableCell align="left">
                {formatPrice(priceAdjustments(key))}
              </TableCell>
              <TableCell align="left">
                {formatPrice(retailTotals[key])}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ValuationDesktop;
