import React from 'react';

/* Material UI */
import { AccordionDetails, CardContent, useMediaQuery } from '@mui/material';

const ResponsiveCardContent = ({ children, ...rest }) =>
  useMediaQuery(theme => theme.breakpoints.down('sm')) ? (
    <AccordionDetails {...rest}>{children}</AccordionDetails>
  ) : (
    <CardContent {...rest}>{children}</CardContent>
  );

export default ResponsiveCardContent;
