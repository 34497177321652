import React from 'react';

/* Material UI */
import {
  Box,
  Dialog,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

// Got a list?  Need to select something from it?  Want it in a dialog?
// I'm your guy!

// Note, we're not using a value prop here; keeping it generic so you can do whatever
// you need when selecting a value, and determine if it's selected however you want.

const ListSelectDialog = ({
  isSelected,
  onClickOption,
  onClose,
  optionKey = 'key',
  optionLabelKey = 'label',
  options,
  title,
  ...rest
}) => (
  <Dialog onClose={onClose} {...rest}>
    <DialogTitle>
      <Box
        display="flex"
        justifyContent="space-between"
        width="100%"
        alignItems="center"
      >
        <Box>{title}</Box>
        <IconButton onClick={onClose} size="large">
          <CloseIcon />
        </IconButton>
      </Box>
    </DialogTitle>
    <List>
      {options.map((option, index) => (
        <ListItem
          button
          key={option[optionKey]}
          onClick={() => onClickOption(option, index)}
          selected={isSelected ? isSelected(option, index) : false}
        >
          <ListItemText primary={option[optionLabelKey]} />
        </ListItem>
      ))}
    </List>
  </Dialog>
);

export default ListSelectDialog;
