import React from 'react';

import { useUserContext } from 'components/MaterialUI/UserContext';
import gql from 'graphql-tag';
import MaterialTable from 'material-table';

import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import GetAppIcon from '@mui/icons-material/GetApp';

import { getFullDateTime, withSuffix } from 'utils';

const INSPECTION_QUERY = gql`
  query inspections($vin: String!) {
    inspection {
      getInspections(vin: $vin) {
        id
        created_at
        user {
          display_name
        }
        data
      }
    }
  }
`;

const GET_INSPECTION_URL = gql`
  query inspections($id: Int!) {
    inspection {
      getInspectionUrl(id: $id) {
        url
      }
    }
  }
`;

const VehicleInspections = ({ vehicle }) => {
  const { me } = useUserContext();
  const [getInspectionUrl] = useLazyQuery(GET_INSPECTION_URL, {
    onCompleted: data => {
      const url = data.inspection.getInspectionUrl.url;
      window.open(url, '_blank');
    },
  });

  const { vin } = vehicle;
  const { data } = useQuery(INSPECTION_QUERY, {
    variables: { vin },
  });
  const inspections = data?.inspection?.getInspections || [];

  const timezone =
    me?.goUserProfile?.settings?.timezone ||
    Intl.DateTimeFormat().resolvedOptions().timeZone;

  const getInspectionUrlForTable = id => {
    getInspectionUrl({ variables: { id } });
  };

  const columns = [
    {
      width: '1%',
      cellStyle: { whiteSpace: 'nowrap' },
      title: 'Actions',
      field: 'link_to_inspection',
      filtering: false,
      render: rowData => (
        <Tooltip title={'Download Inspection'}>
          <IconButton
            onClick={() => getInspectionUrlForTable(rowData.id)}
            size="large"
          >
            <GetAppIcon style={{ color: '#2196f3', fontSize: '22px' }} />
          </IconButton>
        </Tooltip>
      ),
    },
    {
      title: 'Created At',
      field: 'created_at',
      filtering: false,
      render: rowData =>
        getFullDateTime(withSuffix(rowData.created_at, 'Z'), timezone),
      defaultSort: 'desc',
    },
    {
      title: 'Created By',
      field: 'user.display_name',
      filtering: false,
    },
    {
      title: 'Checklist',
      field: 'data.form_type',
      filtering: false,
      cellStyle: {
        textTransform: 'uppercase',
      },
    },
  ];

  const tableOptions = {
    pageSize: 10,
    pageSizeOptions: [10, 25, 50, 100],
    filtering: false,
  };
  return (
    <MaterialTable
      title={'Vehicle Inspections'}
      data={inspections}
      columns={columns}
      options={tableOptions}
    />
  );
};

export default VehicleInspections;
