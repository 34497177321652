import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import React from 'react';

import { useDealerContext } from 'components/MaterialUI/DealerContext';
import { useUserContext } from 'components/MaterialUI/UserContext';
import DealerPicker from 'components/MaterialUI/DealerPicker';
import ErrorPage from 'components/MaterialUI/ErrorPage';
import Loading from 'components/MaterialUI/Loading';

import { Box, Typography, useMediaQuery } from '@mui/material';

import SyndicationMobile from './SyndicationMobile';
import SyndicationDesktop from './SyndicationDesktop';

const DEALER_SYNDICATION_QUERY = gql`
  query dealerSyndicationQuery($dealerId: JSON) {
    npv {
      getImports(
        filters: [
          {
            model: "ImportDealerSettings"
            field: "dealerId"
            op: "=="
            value: $dealerId
          }
        ]
        page: 1
        pageSize: 25
        sort: [{ model: "Imports", field: "name", direction: "desc" }]
      ) {
        results {
          id
          dealers {
            id
            dealerId
            updateNew
            updateUsed
          }
          name
          intervalHours
          lastRan
          lastImportLog(dealerId: $dealerId) {
            id
            createdCount
            updatedCount
          }
        }
      }
      getExports(
        filters: [
          {
            model: "ExportDealerSettings"
            field: "dealerId"
            op: "=="
            value: $dealerId
          }
        ]
        page: 1
        pageSize: 25
        sort: [{ model: "Export", field: "name", direction: "desc" }]
      ) {
        results {
          id
          dealers {
            id
            dealerId
            exportNew
            exportUsed
          }
          name
          intervalHours
          lastRan
        }
      }
    }
  }
`;

const Syndication = () => {
  const { dealerId } = useDealerContext();
  const { me } = useUserContext();
  const { data, loading, error } = useQuery(DEALER_SYNDICATION_QUERY, {
    variables: {
      dealerId,
    },
  });

  const mobile = useMediaQuery(theme => theme.breakpoints.down('md'));

  if (loading) return <Loading />;
  if (error)
    return <ErrorPage action="Loading RV Syndications" error={error} />;

  const imports = data?.npv?.getImports?.results || [];
  const exports = data?.npv?.getExports?.results || [];

  return (
    <Box margin={1}>
      <Box component="div" marginBottom={2}>
        <DealerPicker />
      </Box>
      <Box component="div" marginBottom={3}>
        <Typography variant="h4">RV Inventory</Typography>
      </Box>

      {mobile && (
        <SyndicationMobile
          imports={imports}
          exports={exports}
          me={me}
          dealerId={dealerId}
        />
      )}
      {!mobile && (
        <SyndicationDesktop
          imports={imports}
          exports={exports}
          me={me}
          dealerId={dealerId}
        />
      )}
    </Box>
  );
};

export default Syndication;
