import React, { useEffect } from 'react';
import moment from 'moment';
import gql from 'graphql-tag';

import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import CardActionArea from '@mui/material/CardActionArea';
import Badge from '@mui/material/Badge';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { makeStyles } from '@mui/styles';

import { CONFIRMED_STATUSES } from 'constants.js';
import { formatPhoneNumber, defaultTZ } from 'utils';
import { identityTypeIcon, initials } from '../../utils';

const useStyles = makeStyles(theme => ({
  left: {
    borderLeft: '1px solid black',
    borderBottom: '1px solid black',
  },
  middle: {
    borderLeft: '1px solid black',
    borderRight: '1px solid black',
  },
  bottom: {
    borderLeft: '1px solid black',
    borderBottom: '1px solid black',
    backgroundColor: '#ededed',
  },
  right: {
    borderRight: '1px solid black',
    borderBottom: '1px solid black',
    backgroundColor: '#ededed',
  },
  totalLeft: {
    borderLeft: '1px solid black',
    borderBottom: '0px solid black',
  },
  totalMiddle: {
    borderLeft: '1px solid black',
    borderRight: '1px solid black',
    borderBottom: '0px solid black',
  },
  totalRight: {
    borderRight: '1px solid black',
    borderBottom: '0px solid black',
    backgroundColor: '#ededed',
  },
  buttonColor: {
    color: '#FFFFFF',
  },
}));

const FIELDS = {
  salutation: 'Salutation',
  first_name: 'First Name',
  nickname: 'Alternative Name',
  last_name: 'Last Name',
  preferred_language: 'Preferred Language',
  company_name: 'Company Name',
  home_phone: 'Home Phone',
  cell_phone: 'Cell Phone',
  primary_email: 'Email Address',
  address: 'Address',
  date_of_birth: 'Birthday',
  drivers_license: 'Drivers License',
  drivers_license_province: 'Drivers License Province',
  drivers_license_expiry_date: 'Drivers License Expiry',
  assigned_salespeople: 'Assigned Salespeople',
};

const StepTwo = ({
  selectedCustomers,
  selectedFields,
  setSelectedFields,
  defaultCustomer,
}) => {
  const classes = useStyles();
  const renderField = (customer, field) => {
    if (field === 'assigned_salespeople') {
      return customer[field].length > 0 ? (
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: 'auto',
            marginBottom: 'auto',
          }}
        >
          {customer[field].map(({ user, dealer }) => (
            <Box>
              {user?.display_name} - {dealer?.dealer_name}
            </Box>
          ))}
        </Box>
      ) : null;
    } else {
      return customer[field];
    }
  };

  const multipleSalesPeople = () => {
    const result1 = selectedCustomers[0].assigned_salespeople;
    const result2 = selectedCustomers[1].assigned_salespeople;
    return (
      result1.filter(o1 => result2.some(o2 => o1.dealer_id === o2.dealer_id))
        .length > 0
    );
  };

  const isSelected = (field, value) => {
    if (field === 'assigned_salespeople') {
      return selectedFields[field][0]?.username === value[0]?.username;
    } else {
      return selectedFields[field] === value && value !== null;
    }
  };

  let defaultCustomerName = `${defaultCustomer.first_name} ${defaultCustomer.last_name}`;

  let selectedCustomerName = `${selectedFields.first_name || ''} ${
    selectedFields.last_name || ''
  }`;

  let displayName =
    selectedCustomerName !== ' ' ? selectedCustomerName : defaultCustomerName;

  useEffect(() => {
    if (!multipleSalesPeople()) {
      setSelectedFields(prev => ({
        ...prev,
        assigned_salespeople: [
          ...selectedCustomers[0].assigned_salespeople,
          ...selectedCustomers[1].assigned_salespeople,
        ],
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const keys = Object.keys(selectedFields);
    for (let i = 0; i < keys.length; i++) {
      if (selectedCustomers[0][keys[i]] === selectedCustomers[1][keys[i]]) {
        setSelectedFields(prev => ({
          ...prev,
          [keys[i]]: selectedCustomers[0][keys[i]],
        }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSelectedFields(prev => ({
      ...prev,
      vehicles: [
        ...selectedCustomers[0].vehicles,
        ...selectedCustomers[1].vehicles,
      ],
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderHeaderCell = (customer, lastCell = false) => {
    const renderName = lastCell ? displayName : customer?.fullname;
    const { app_account: goAutoAppAcct } = customer;
    const customerHasGoAutoAppAcct =
      CONFIRMED_STATUSES.includes(goAutoAppAcct?.status) &&
      goAutoAppAcct?.isEnabled;
    return (
      <Box>
        <Grid
          container
          direction="row"
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: 'auto',
            marginBottom: 'auto',
          }}
          xs={12}
        >
          <Grid
            item
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: 'auto',
              marginBottom: 'auto',
              paddingRight: '1rem',
            }}
            xs={2}
          >
            <Badge
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              overlap="circular"
              invisible={!customerHasGoAutoAppAcct}
              badgeContent={
                <CheckCircleIcon fontSize="small" style={{ color: 'green' }} />
              }
            >
              <Avatar>{initials(customer)}</Avatar>
            </Badge>
          </Grid>
          <Grid item xs={10}>
            <Box>{renderName}</Box>
            <Box
              style={{
                color: '#808080',
                fontSize: '11px',
              }}
            >
              <Grid
                container
                direction="row"
                style={{
                  display: 'flex',
                  marginTop: 'auto',
                  marginBottom: 'auto',
                }}
              >
                <Grid item xs={6}>
                  Profile Created {moment(customer.created).format('ll')}
                </Grid>
                {customerHasGoAutoAppAcct && (
                  <Grid item xs={6}>
                    {`Go Auto App Account Created: ${moment(
                      goAutoAppAcct.createdAt,
                    )
                      .tz(defaultTZ)
                      .format('MMM YYYY')}`}
                  </Grid>
                )}
              </Grid>
            </Box>
            <Box>
              <Grid container direction="row">
                {customer?.identities?.length === 0 && (
                  <Box>No Identities.</Box>
                )}
                {customer?.identities.map(identity => (
                  <Grid
                    item
                    xs={6}
                    style={{
                      fontSize: '14px',
                      display: 'flex',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                    }}
                  >
                    {identityTypeIcon(identity.identity_type)}
                    <Box style={{ paddingLeft: '0.5rem' }}>
                      {identity.identity_type === 'sms'
                        ? formatPhoneNumber(identity.identity_value)
                        : identity.identity_value}
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  };

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell style={{ width: '250px' }}></TableCell>
          {selectedCustomers.map(customer => (
            <TableCell style={{ minWidth: '400px' }} className={classes.middle}>
              {renderHeaderCell(customer)}
            </TableCell>
          ))}
          <TableCell>{renderHeaderCell(defaultCustomer, true)}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {Object.keys(FIELDS).map(field => (
          <TableRow>
            <TableCell
              className={
                field === 'assigned_salespeople'
                  ? classes.totalRight
                  : classes.right
              }
            >
              {FIELDS[field]}
            </TableCell>
            {selectedCustomers.map(customer => (
              <TableCell
                style={{
                  backgroundColor: `${
                    isSelected(field, customer[field]) ? '#ccff90' : ''
                  }`,
                }}
                className={classes.bottom}
              >
                <CardActionArea
                  onClick={() => {
                    setSelectedFields(prev => ({
                      ...prev,
                      [field]: customer[field],
                    }));
                  }}
                  disabled={
                    field === 'assigned_salespeople' && !multipleSalesPeople()
                  }
                >
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                      height: '50px',
                    }}
                  >
                    <Box
                      style={{
                        display: 'flex',
                        marginTop: 'auto',
                        marginBottom: 'auto',
                        flexDirection: 'column',
                      }}
                    >
                      {renderField(customer, field)}
                    </Box>
                  </Box>
                </CardActionArea>
              </TableCell>
            ))}
            <TableCell
              className={
                field === 'assigned_salespeople'
                  ? classes.totalLeft
                  : classes.left
              }
            >
              {renderField(selectedFields, field)}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

StepTwo.fragments = {
  customer: gql`
    fragment StepTwoFragment on Customer {
      first_name
      last_name
      home_phone
      cell_phone
      created
      primary_email
      address
      assigned_salespeople {
        user {
          display_name
          username
        }
        dealer {
          dealer_name
        }
        dealer_id
      }
      app_account {
        createdAt
        status
        isEnabled
      }
      identities {
        identity_type
        identity_value
      }
    }
  `,
};

export default StepTwo;
