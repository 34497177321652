import React, { useState } from 'react';

/* external */
import { isEmpty } from 'lodash';
import gql from 'graphql-tag';

/* Material UI */
import { makeStyles, withStyles } from '@mui/styles';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  Link,
  Typography,
  useTheme,
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PhoneIcon from '@mui/icons-material/Phone';

/* internal */
import { formatOdometer, formatPhoneNumber, formatPrice } from 'utils';
import {
  displayName,
  formatPhoto,
  goodOrBad,
  yesOrNo,
} from 'modules/used_vehicles/components/utils';
import { paveReportLink } from 'modules/appraisals/utils';
import { useCodeTablesContext } from 'components/MaterialUI/CodeTablesContext';
import FormatDateTZ from 'components/MaterialUI/FormatDateTZ';
import ImageCarousel from 'modules/used_vehicles/components/components/ImageCarousel';

const AccordionSummary = withStyles({
  expanded: {
    margin: '0 !important',
  },
})(MuiAccordionSummary);

const useStyles = makeStyles(theme => ({
  accordion: {
    marginBottom: theme.spacing(1),
  },
  accordionSummary: {
    expanded: {
      margin: '0 !important',
    },
  },
}));

const AccordionSection = ({ title, children }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <Accordion className={classes.accordion} defaultExpanded>
      <AccordionSummary
        className={classes.accordionSummary}
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography style={{ fontWeight: 'bold' }} variant="subtitle1">
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

const BuyNowCardDetailsDialog = ({ appraisal, onClose, ...rest }) => {
  const {
    codeTables: { bodyTypes = [] },
  } = useCodeTablesContext();
  const theme = useTheme();
  const [showImageCarousel, setShowImageCarousel] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const {
    arrivedAt,
    attachments,
    buyer,
    buyerUser,
    buyNowPrice,
    createdAt,
    exteriorColour,
    greaseBodyTypeId,
    hasLien,
    hasOpi,
    interiorColour,
    isGlassGood,
    isPaveSessionComplete,
    isTiresGood,
    notes,
    odometer,
    paveSessionId,
    photos,
    trimVariation,
    vin,
  } = appraisal;

  const {
    cell_phone: cell,
    desk_phone: desk,
    email_address: email,
  } = buyerUser?.profile ?? {};

  const phone = cell ?? desk;

  const bodyStyle = bodyTypes.find(({ id }) => id === greaseBodyTypeId)?.name;

  return (
    <Dialog onClose={onClose} {...rest}>
      <Dialog
        open={showImageCarousel}
        onClose={() => setShowImageCarousel(false)}
        maxWidth="md"
      >
        <DialogTitle>{'Vehicle Photos'}</DialogTitle>
        <DialogContent>
          <ImageCarousel photos={photos} index={photoIndex} />
        </DialogContent>
      </Dialog>
      <DialogTitle>
        <Box>
          <Box
            component="span"
            fontWeight="bold"
            marginRight={1}
            fontSize="16px"
          >
            {displayName(appraisal)}
          </Box>
          <Box component="span" fontSize="12px">
            {trimVariation}
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Box fontSize="11px">
            Created: <FormatDateTZ date={createdAt} />
          </Box>
          <Box fontSize="11px">
            Arrived: <FormatDateTZ date={arrivedAt} nullDisplay="N/A" />
          </Box>
        </Box>
        <Box>
          <Box component="span" fontWeight="bold">
            Buyer:{' '}
          </Box>
          <Box component="span">{buyer}</Box>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            {email && (
              <Box component="span" mr={1}>
                <Button
                  startIcon={<EmailIcon />}
                  href={`mailto:${email}`}
                  style={{ textTransform: 'none', paddingLeft: 0 }}
                  onClick={e => e.stopPropagation()}
                  small
                >
                  {email}
                </Button>
              </Box>
            )}
            {phone && (
              <Box component="span">
                <Button
                  startIcon={<PhoneIcon />}
                  href={`tel:${phone}`}
                  style={{ textTransform: 'none', paddingLeft: 0 }}
                  onClick={e => e.stopPropagation()}
                  small
                >
                  {formatPhoneNumber(phone)}
                </Button>
              </Box>
            )}
          </Box>
          <Box>
            <Box component="span" mr={1}>
              Price:
            </Box>
            <Box component="span" fontWeight="bold">
              {formatPrice(buyNowPrice)}
            </Box>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent style={{ padding: theme.spacing(1), paddingTop: 0 }}>
        <AccordionSection title="Appraisal Information">
          <Box>
            <Box>Notes/Damage Disclosure:</Box>
            <Box color="#999999" ml={2}>
              {notes}
            </Box>
            {isPaveSessionComplete && paveSessionId && (
              <Link
                style={{ textUnderlineOffset: '0.3em' }}
                href={paveReportLink(paveSessionId)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Box display="flex" alignItems="center">
                  View PAVE Report{' '}
                  <Icon
                    className="fas fa-road"
                    style={{ width: 'auto', marginLeft: '5px' }}
                  />
                </Box>
              </Link>
            )}
          </Box>
        </AccordionSection>
        <AccordionSection title="Vehicle Details">
          <Box>
            <Box>
              <Box
                component="span"
                fontWeight="bold"
                marginRight={1}
                fontSize="14px"
              >
                {displayName(appraisal)}
              </Box>
              <Box component="span" fontSize="10px">
                {trimVariation}
              </Box>
            </Box>
            <Box>{vin}</Box>
            <Box mt={1} display="flex" fontSize="12px">
              <Box>
                <Box>Body Style: {bodyStyle}</Box>
                <Box>Odometer: {formatOdometer(odometer)}</Box>
              </Box>
              <Box ml={2}>
                <Box>Exterior: {exteriorColour}</Box>
                <Box>Interior: {interiorColour}</Box>
              </Box>
            </Box>
            <Box mt={1} display="flex" fontSize="12px">
              <Box>
                <Box>Glass: {goodOrBad(isGlassGood)}</Box>
                <Box>Tires: {goodOrBad(isTiresGood)}</Box>
              </Box>
              <Box ml={2}>
                <Box>OPI: {yesOrNo(hasOpi)}</Box>
                <Box>Lien: {yesOrNo(hasLien)}</Box>
              </Box>
            </Box>
            <Box
              width="100%"
              pb={1}
              className="carproof detail carproof-badge"
              data-vin={vin}
              data-type="slim"
              data-language="en"
            />
          </Box>
        </AccordionSection>
        <AccordionSection title="Photos">
          <Grid
            container
            direction="row"
            spacing={2}
            justifyContent="space-evenly"
          >
            {isEmpty(photos) && (
              <Box margin={1} textAlign="left">
                No photos uploaded!
              </Box>
            )}
            {photos.map((photo, photoIndex) => (
              <Grid
                item
                onClick={() => {
                  setPhotoIndex(photoIndex);
                  setShowImageCarousel(true);
                }}
              >
                <img
                  alt={photo.id}
                  style={{ border: '1px solid grey', padding: 0 }}
                  src={formatPhoto(photo, {
                    width: 115,
                    height: 115,
                    crop: 'pad',
                  })}
                />
              </Grid>
            ))}
          </Grid>
        </AccordionSection>
        <AccordionSection title="Attachments">
          <Box>
            {attachments.map(
              ({
                id,
                url,
                date_created,
                tags,
                label,
                user: { display_name },
              }) => (
                <Box key={id} marginBottom={1}>
                  <Box>
                    <a href={url} target="_blank" rel="noopener noreferrer">
                      {label}
                    </a>
                    <FormatDateTZ
                      date={date_created}
                      style={{ marginLeft: theme.spacing(1) }}
                    />{' '}
                    {display_name ? `by ${display_name}` : ''}
                  </Box>
                  <Box>{tags}</Box>
                </Box>
              ),
            )}
          </Box>
        </AccordionSection>
      </DialogContent>
      <DialogActions>
        <Button
          style={{ color: theme.actions.add.backgroundColor }}
          onClick={onClose}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

BuyNowCardDetailsDialog.fragments = {
  appraisal: gql`
    fragment BuyNowCardDetailsDialogAppraisal on Appraisal {
      arrivedAt
      attachments {
        id
        date_created
        dealer_id
        label
        tags
        url
        user {
          display_name
        }
      }
      buyNowPrice
      buyer
      buyerUser {
        profile {
          cell_phone
          desk_phone
          email_address
        }
      }
      createdAt
      exteriorColour
      greaseBodyTypeId
      hasLien
      hasOpi
      interiorColour
      isGlassGood
      isPaveSessionComplete
      isTiresGood
      make
      model
      notes
      odometer
      paveSessionId
      photos {
        id
        cloudinaryPublicId
      }
      trim
      trimVariation
      vin
      year
    }
  `,
};

export default BuyNowCardDetailsDialog;
