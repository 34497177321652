import { useMutation, useQuery } from '@apollo/react-hooks';

/* external */
import gql from 'graphql-tag';
import React, { useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';

/* Material UI */
import { Box, Button, Grid } from '@mui/material';

import useYearMakeModel from 'components/hooks/useYearMakeModel';
import CheckboxControl from 'components/MaterialUI/CheckboxControl';
import { useDealerContext } from 'components/MaterialUI/DealerContext';

/* internal */
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';
import SelectControl from 'components/MaterialUI/SelectControl2';
import TextFieldControl from 'components/MaterialUI/TextFieldControl';

import { Regexps } from 'constants.js';

const GATEWAYS_QUERY = gql`
  query gateways {
    gateways {
      getGateways {
        gateways {
          id
          gateway_type
          name
          phone_number
        }
      }
    }
  }
`;

const CREATE_APPRAISAL = gql`
  mutation createAppraisal($appraisal: AppraisalInput!) {
    appraisals {
      createAppraisal(appraisal: $appraisal) {
        id
        paveSessionId
        isPaveSessionComplete
        paveSMSGatewayId
        paveSMSPhoneNumber
      }
    }
  }
`;

const GridRow = ({ children, ...rest }) => (
  <Grid container item xs={12} alignItems="flex-end" spacing={2} {...rest}>
    {children}
  </Grid>
);

const PaveFormBox = ({
  appraisal,
  onOpenPave,
  onCreate,
  onCancel,
  ...rest
}) => {
  const gatewaysQuery = useQuery(GATEWAYS_QUERY);
  const { dealers } = useDealerContext();

  const [createAppraisal, createAppraisalMutation] =
    useMutation(CREATE_APPRAISAL);

  const {
    appraisalType,
    customerId,
    dealerId = null,
    firstName,
    lastName,
    opportunityId,
  } = appraisal;

  const { control, formState, handleSubmit, register, setValue } = useForm({
    defaultValues: {
      appraisalType,
      customerId,
      dealerId,
      firstName,
      greaseMakeId: null,
      greaseModelId: null,
      lastName,
      make: '',
      model: '',
      opportunityId,
      year: null,
    },
    shouldUnregister: true,
  });
  const { errors } = formState;
  const {
    makes,
    models,
    years,
    loading: loadingYMM,
    setYear,
    setMakeId,
  } = useYearMakeModel({ selectedYear: null, selectedMake: null });

  const year = useWatch({ control, name: 'year' });
  const make = useWatch({ control, name: 'make' });
  const model = useWatch({ control, name: 'model' });
  const greaseMakeId = useWatch({ control, name: 'greaseMakeId' });
  const greaseModelId = useWatch({ control, name: 'greaseModelId' });
  const shouldSendSMS = useWatch({ control, name: 'shouldSendSMS' });

  const gateways = (
    gatewaysQuery.data?.gateways.getGateways.gateways ?? []
  ).sort((a, b) => a.name.localeCompare(b.name));
  const gatewayOptions = gateways.map(({ id, name, phone_number }) => ({
    value: id,
    name: `${name} (${phone_number})`,
  }));

  // For the ymm drop downs
  useEffect(() => {
    if (year) setYear(year);
  }, [year, setYear]);

  // sets makeId for drop downs and sets 'make' in form data with the text
  // value associated with greaseMakeId
  useEffect(() => {
    if (greaseMakeId) {
      setMakeId(greaseMakeId);
      const _make = makes.find(({ id }) => id === greaseMakeId)?.name;
      if (_make && make !== _make) setValue('make', _make);
    }
  }, [greaseMakeId, setMakeId, setValue, make, makes]);

  // sets 'model' in form data with the text value associated with greaseModelId
  useEffect(() => {
    if (greaseModelId) {
      const _model = models.find(({ id }) => id === greaseModelId)?.name;
      if (_model && model !== _model) setValue('model', _model);
    }
  }, [greaseModelId, setValue, model, models]);

  const onSubmit = ({ shouldSendSMS, ...appraisal }) => {
    createAppraisal({
      variables: {
        appraisal: {
          ...appraisal,
          includePave: true,
          sendPaveSms: shouldSendSMS,
        },
      },
    }).then(
      ({
        data: {
          appraisals: { createAppraisal: newAppraisal },
        },
      }) =>
        shouldSendSMS
          ? onCreate(newAppraisal, { closeEditor: true })
          : onOpenPave(newAppraisal),
    );
  };

  return (
    <Box {...rest}>
      <LoadingBackdrop open={loadingYMM}>
        Loading vehicle dropdowns
      </LoadingBackdrop>
      <LoadingBackdrop open={gatewaysQuery.loading}>
        Loading gateways
      </LoadingBackdrop>
      <LoadingBackdrop open={createAppraisalMutation.loading}>
        Creating appraisal
      </LoadingBackdrop>

      <form onSubmit={handleSubmit(onSubmit)}>
        {
          /* Hidden fields */
          [
            'customerId',
            'opportunityId',
            'appraisalType',
            'firstName',
            'lastName',
            'make',
            'model',
          ].map(name => (
            <input key={name} type="hidden" {...register(name)} />
          ))
        }
        <Grid container spacing={2}>
          <GridRow>
            <Grid item xs={12}>
              <SelectControl
                control={control}
                errors={errors}
                fullWidth
                label="Dealer"
                name="dealerId"
                noNull
                options={dealers.sort((a, b) => a.dealer_name.localeCompare(b))}
                optionValueKey="dealer_id"
                optionNameKey="dealer_name"
                rules={{ required: 'Dealer required!' }}
                variant="filled"
              />
            </Grid>
          </GridRow>
          <GridRow>
            <Grid item xs={12}>
              <SelectControl
                control={control}
                errors={errors}
                fullWidth
                label="Year"
                name="year"
                options={years
                  .sort((a, b) => b - a)
                  .map(y => ({ value: y, name: y }))}
                rules={{ required: 'Year required!' }}
                variant="filled"
              />
            </Grid>
          </GridRow>
          <GridRow>
            <Grid item xs={12}>
              <SelectControl
                control={control}
                errors={errors}
                fullWidth
                label="Make"
                name="greaseMakeId"
                options={makes}
                optionValueKey="id"
                rules={{ required: 'Make required!' }}
                variant="filled"
              />
            </Grid>
          </GridRow>
          <GridRow>
            <Grid item xs={12}>
              <SelectControl
                control={control}
                errors={errors}
                fullWidth
                label="Model"
                name="greaseModelId"
                options={models}
                optionValueKey="id"
                rules={{ required: 'Model required!' }}
                variant="filled"
              />
            </Grid>
          </GridRow>
          <GridRow>
            <Grid item xs={12}>
              <CheckboxControl
                control={control}
                name="shouldSendSMS"
                label="Send SMS"
              />
            </Grid>
          </GridRow>
          {shouldSendSMS && (
            <GridRow>
              <Grid item xs={12}>
                <SelectControl
                  control={control}
                  errors={errors}
                  fullWidth
                  label="Gateway"
                  name="paveSMSGatewayId"
                  options={gatewayOptions}
                  noNull
                  rules={{ required: 'Must choose gateway to send SMS.' }}
                  variant="filled"
                />
              </Grid>
            </GridRow>
          )}
          {shouldSendSMS && (
            <GridRow>
              <Grid item xs={12}>
                <TextFieldControl
                  control={control}
                  name="paveSMSPhoneNumber"
                  label="SMS Number"
                  fullWidth
                  rules={{
                    required: 'Must enter phone number to send SMS.',
                    pattern: {
                      // TODO: allow international phone numbers here?
                      value: Regexps.PhoneFormat.NANP,
                      message: 'Invalid phone number',
                    },
                  }}
                  muiVariant="filled"
                />
              </Grid>
            </GridRow>
          )}
        </Grid>

        <GridRow alignContent="flex-end" spacing={2}>
          <Grid item>
            <Button onClick={onCancel} variant="contained">
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button type="submit" variant="contained">
              {shouldSendSMS ? 'Send Pave SMS' : 'Open Pave Inspection'}
            </Button>
          </Grid>
        </GridRow>
      </form>
    </Box>
  );
};

export default PaveFormBox;
