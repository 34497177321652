import React, { useEffect, useState } from 'react';

/* external */
import { useMutation, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useSnackbar } from 'notistack';

/* Material UI */
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';

/* internal */
import ConfirmDialog from 'components/MaterialUI/ConfirmDialog';
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import Loading from 'components/MaterialUI/Loading';
import { Role } from 'constants.js';
import { useForm, useWatch } from 'react-hook-form';
import { ASSIGNEE_ROLES } from '../constants';
import SelectControl from 'components/MaterialUI/SelectControl2';

const useStyles = makeStyles(theme => ({
  button: {
    color: theme.colours.add,
    fontWeight: 'bold',
  },
}));

const USERS_FOR_DEALER = gql`
  query UsersForDealer($dealerIds: [Int]!) {
    users: users(
      dealer_ids: $dealerIds
      status: active
      roles: [
        "${Role.SALES_REP}"
        "${Role.SALES_MANAGER}"
        "${Role.FINANCE_MANAGER}"
        "${Role.FINANCE_DIRECTOR}"
        "${Role.BDC_REP}"
        "${Role.CSR}"
        "${Role.BDC_MANAGER}"
        "${Role.INTERNET_SALES_REP}"
      ]
    ) {
      display_name
      username
      role
      profile {
        image_id
      }
    }
  }
`;

const ASSIGN_SALES_REP = gql`
  mutation assignSalesRep($_id: ID!, $input: SalesRepsInput!) {
    assignSalesRepsToOpportunity(_id: $_id, input: $input) {
      _id
      sales_reps {
        username
        display_name
      }
    }
  }
`;

const ASSIGN_SALES_MANAGER = gql`
  mutation assignSalesMgr($_id: ID!, $input: SalesManagersInput!) {
    assignSalesManagersToOpportunity(_id: $_id, input: $input) {
      _id
      sales_managers {
        username
        display_name
      }
    }
  }
`;

const ASSIGN_CUSTOMER_REP = gql`
  mutation assignCustomerRep($_id: ID!, $input: CustomerRepsInput!) {
    assignCustomerRepsToOpportunity(_id: $_id, input: $input) {
      _id
      customer_reps {
        username
        display_name
      }
    }
  }
`;

const ASSIGN_BDC_REP = gql`
  mutation assignBDCReps($_id: ID!, $input: BDCRepsInput!) {
    assignBDCRepsToOpportunity(_id: $_id, input: $input) {
      _id
      bdc_reps {
        username
        display_name
      }
    }
  }
`;

const ASSIGN_FINANCE_REP = gql`
  mutation assignFinanceRep($_id: ID!, $input: FinanceManagersInput!) {
    assignFinanceManagersToOpportunity(_id: $_id, input: $input) {
      _id
      finance_managers {
        username
        display_name
      }
    }
  }
`;

const OpportunityBulkReassign = ({ handleClose, selected, opps }) => {
  const { dealerId } = useDealerContext();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const desktop = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const { control, handleSubmit } = useForm({
    defaultValues: { role: null },
    shouldUnregister: true,
  });
  const watchRole = useWatch({ control, name: 'role' });
  const watchReassignTo = useWatch({ control, name: 'reassign_to' });
  const [reassignFrom, setReassignFrom] = useState([]);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const [updateSalesManager] = useMutation(ASSIGN_SALES_MANAGER);

  const [updateSalesReps] = useMutation(ASSIGN_SALES_REP);

  const [updateCustomerRep] = useMutation(ASSIGN_CUSTOMER_REP);

  const [updateBdcReps] = useMutation(ASSIGN_BDC_REP);

  const [updateFinanceRep] = useMutation(ASSIGN_FINANCE_REP);

  const { data, loading } = useQuery(USERS_FOR_DEALER, {
    variables: {
      dealerIds: [dealerId],
    },
  });

  useEffect(() => {
    if (watchRole) {
      let toModify = opps
        .filter(opp => selected.includes(opp._id))
        .map(opp => opp[watchRole].map(o => o?.display_name))
        .flat();
      setReassignFrom(toModify);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchRole]);

  if (loading) {
    return <Loading />;
  }

  const users = data.users.sort((a, b) =>
    a.display_name.localeCompare(b.display_name),
  );

  const salesManagers = users.filter(({ role }) => role === Role.SALES_MANAGER);
  const customerReps = users.filter(({ role }) => role === Role.CSR);
  const salesReps = users.filter(({ role }) => Role.ALL_SALES.includes(role));
  const bdcReps = users.filter(({ role }) => Role.ALL_BDC.includes(role));
  const financeMangers = users.filter(({ role }) =>
    Role.ALL_FINANCE.includes(role),
  );

  const ROLE_MAP = {
    sales_reps: {
      users: salesReps,
      func: updateSalesReps,
    },
    sales_managers: {
      users: salesManagers,
      func: updateSalesManager,
    },
    customer_reps: {
      users: customerReps,
      func: updateCustomerRep,
    },
    bdc_reps: {
      users: bdcReps,
      func: updateBdcReps,
    },
    finance_managers: {
      users: financeMangers,
      func: updateFinanceRep,
    },
  };

  const onSubmit = input => {
    const toModify = opps.filter(opp => selected.includes(opp._id));

    Promise.all(
      toModify.map(opp => {
        const payload = {
          variables: {
            _id: opp._id,
            input: {
              [watchRole]: [
                watchReassignTo,
                ...opp[watchRole]
                  .map(user_obj => user_obj.username)
                  .filter(username => reassignFrom.includes(username)),
              ],
            },
          },
        };
        return ROLE_MAP[watchRole]['func'](payload);
      }),
    )
      .then(enqueueSnackbar('Bulk reassign complete', { variant: 'success' }))
      .catch(e =>
        enqueueSnackbar('Bulk reassign failed', { variant: 'error' }),
      );

    handleClose();
  };

  return (
    <>
      <ConfirmDialog
        text={`Are you sure you want to bulk reassign from: ${reassignFrom} to ${watchReassignTo}`}
        onConfirm={handleSubmit(onSubmit)}
        isOpen={confirmOpen}
        onClose={() => setConfirmOpen(false)}
      />
      <DialogTitle>
        <Typography variant="h5">Bulk Reassign</Typography>
      </DialogTitle>
      <DialogContent>
        <form>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={desktop ? 0 : 12}>
              <InputLabel shrink>Role</InputLabel>
              <SelectControl
                control={control}
                options={ASSIGNEE_ROLES}
                optionNameKey="display"
                inputProps={{ style: { width: 250 } }}
                name="role"
              />
            </Grid>
            <Grid item xs={desktop ? 0 : 12}>
              <SelectControl
                control={control}
                label="Reassign To"
                name="reassign_to"
                options={watchRole ? ROLE_MAP[watchRole]['users'] : []}
                optionValueKey="username"
                optionNameKey="display_name"
                inputProps={{ style: { width: 250 } }}
                disabled={watchRole == null}
              />
            </Grid>
            <Grid item xs={desktop ? 0 : 12}>
              Bulk reassigning from these user(s): {reassignFrom.join(', ')}
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button className={classes.button} onClick={handleClose}>
          Close
        </Button>
        <Button
          className={classes.button}
          onClick={() => setConfirmOpen(true)}
          disabled={watchRole && !watchReassignTo}
        >
          Reassign
        </Button>
      </DialogActions>
    </>
  );
};

export default OpportunityBulkReassign;
