/* external */
import React, { useState, useEffect } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import gql from 'graphql-tag';

/* Material UI */
import { Box } from '@mui/material';

/* internal */
import { LoadingBackdrop } from 'components/MaterialUI';
import { useSnackMutation } from 'utils/useSnackMutation';

import { MediaBox } from '../common';
import StepButtons from './StepButtons';

const UPLOAD_VIDEOS = gql`
  mutation uploadNpvVideos($id: Int!, $data: [NpvVideoInput!]!) {
    npv {
      createVehicleVideos(id: $id, data: $data) {
        id
        videos {
          id
          cloudinaryPublicId
          md5
          isWalkAround
          vehicleId
        }
      }
    }
  }
`;

const UnitMedia = ({ step, back, vehicle }) => {
  const history = useHistory();

  // Rip Videos out of vehicle
  const { id: vehicleId, videos = [] } = vehicle ?? {};

  // Form Stuff
  const formMethods = useForm({
    defaultValues: {
      videos: videos.map(
        ({ id, cloudinaryPublicId, md5, isWalkAround, vehicleId }) => ({
          id,
          cloudinaryPublicId,
          md5,
          isWalkAround,
          vehicleId,
        }),
      ),
    },
  });

  const videosFieldArray = useFieldArray({
    control: formMethods.control,
    name: 'videos',
    keyName: 'key',
  });

  const [videoList, setVideoList] = useState(videosFieldArray.fields);

  // Mutations
  const [uploadVideosMutation, { loading: videosLoading }] = useSnackMutation(
    UPLOAD_VIDEOS,
    'Videos uploaded',
    'Error uploading videos',
    () => history.push(`/home`), // TODO: Redirect to vehicle page
  );

  const onSubmit = data =>
    uploadVideosMutation({
      variables: {
        id: vehicleId,
        data: data.videos.map(
          ({ chosen, selected, __typename, key, ...rest }) => ({
            ...rest,
            vehicleId,
          }),
        ),
      },
    });

  // Whenever the videoList is changed, replace the form array
  useEffect(() => {
    videosFieldArray.replace(videoList);
    // eslint-disable-next-line
  }, [videoList]);

  return (
    <FormProvider {...formMethods}>
      <LoadingBackdrop open={videosLoading}>
        Uploading Videos...
      </LoadingBackdrop>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <Box margin={1}>
          <MediaBox
            vehicle={vehicle}
            videoList={videoList}
            setVideoList={setVideoList}
          />
        </Box>
        <StepButtons back={back} step={step} />
      </form>
    </FormProvider>
  );
};

UnitMedia.fragments = {
  vehicle: gql`
    fragment NpvVehicleVideos on NpvVehicle {
      id
      videos {
        cloudinaryPublicId
        id
        isWalkAround
        md5
        vehicleId
      }
      videoUploadParams {
        apiKey
        folder
        signature
        timestamp
        uploadPreset
        url
      }
      ...NpvVehicleMediaBox
    }
    ${MediaBox.fragments.vehicle}
  `,
};

export default UnitMedia;
