import React from 'react';
// Used to upload inventory if cdk is down.
// import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

import { useDealerContext } from 'components/MaterialUI/DealerContext';
import { useHistory } from 'react-router-dom';
import { useUserContext } from 'components/MaterialUI/UserContext';
import { useVehiclesQueryContext } from '../contexts/VehiclesQueryContext';
import DealerPicker from 'components/MaterialUI/DealerPicker';
import Loading from 'components/MaterialUI/Loading';
import DashboardItem from './DashboardItem';
import InventorySearch from '../tables/InventorySearch';
// Used to upload inventory if cdk is down.
// import UploadInventoryModal from './UploadInventoryModal';

import { Box, useMediaQuery } from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Bar } from 'react-chartjs-2';
import 'chart.js';

// Used to upload inventory if cdk is down.
// import { Button, Dialog } from '@material-ui/core';
// import { RoleGroup } from 'constants.js';
// import { stringify } from 'utils';

const DECIMAL_PLACES = 1;

const DAYS_IN_STOCK_ORDER = ['0-30', '31-60', '61-90', '91-120', '>120'];
const PRICE_CHANGES_ORDER = [
  'No Price Changes in 7 Days',
  'No Price Changes in 14 Days',
  'No Price Changes in 21 Days',
  'No Price Changes in 28 Days',
];

const getFieldFromLabel = label => {
  if (label.includes('7')) {
    return 'no_price_changes_at_7_days';
  } else if (label.includes('14')) {
    return 'no_price_changes_at_14_days';
  } else if (label.includes('21')) {
    return 'no_price_changes_at_21_days';
  } else {
    return 'no_price_changes_at_28_days';
  }
};

const DASHBOARD_QUERY = gql`
  query DashboardQuery($dealerId: JSON) {
    inventory {
      newVehicles: getVehicleFacets(
        filters: [
          { model: "Vehicle", field: "dealer_id", op: "eq", value: $dealerId }
          { model: "Vehicle", field: "stock_type", op: "eq", value: "NEW" }
          { model: "StockStatus", field: "name", op: "eq", value: "in stock" }
        ]
        facets: [
          { model: "StockStatus", field: "name" }
          { model: "Vehicle", field: "is_decoded" }
          { model: "Vehicle", field: "is_missing_description" }
          { model: "Vehicle", field: "is_missing_photos" }
          { model: "Vehicle", field: "is_on_special" }
          { model: "Vehicle", field: "days_in_stock_category" }
          { model: "Vehicle", field: "no_price_changes_at_7_days" }
          { model: "Vehicle", field: "no_price_changes_at_14_days" }
          { model: "Vehicle", field: "no_price_changes_at_21_days" }
          { model: "Vehicle", field: "no_price_changes_at_28_days" }
          { model: "Vehicle", field: "is_demo_unit" }
          { model: "Vehicle", field: "is_loaner" }
          { model: "Vehicle", field: "not_enough_photos" }
          { model: "Vehicle", field: "has_cost" }
        ]
      ) {
        data {
          value
          count
        }
        field
        model
      }
      usedVehicles: getVehicleFacets(
        filters: [
          { model: "Vehicle", field: "dealer_id", op: "eq", value: $dealerId }
          { model: "Vehicle", field: "stock_type", op: "eq", value: "USED" }
          { model: "StockStatus", field: "name", op: "eq", value: "in stock" }
        ]
        facets: [
          { model: "StockStatus", field: "name" }
          { model: "Vehicle", field: "is_booked" }
          { model: "Vehicle", field: "is_decoded" }
          { model: "Vehicle", field: "is_missing_description" }
          { model: "Vehicle", field: "is_missing_photos" }
          { model: "Vehicle", field: "is_on_special" }
          { model: "Vehicle", field: "days_in_stock_category" }
          { model: "Vehicle", field: "no_price_changes_at_7_days" }
          { model: "Vehicle", field: "no_price_changes_at_14_days" }
          { model: "Vehicle", field: "no_price_changes_at_21_days" }
          { model: "Vehicle", field: "no_price_changes_at_28_days" }
          { model: "Vehicle", field: "is_demo_unit" }
          { model: "Vehicle", field: "is_loaner" }
          { model: "Vehicle", field: "not_enough_photos" }
          { model: "Vehicle", field: "has_cost" }
          { model: "Vehicle", field: "recon_complete" }
          { model: "Vehicle", field: "has_uvi" }
        ]
      ) {
        data {
          value
          count
        }
        field
        model
      }
    }
  }
`;

const missingUviFilter = {
  model: 'Vehicle',
  field: 'has_uvi',
  value: 'False',
  options: {
    label: 'Missing UVI',
  },
};

const inStockFilter = {
  model: 'StockStatus',
  field: 'name',
  value: 'In Stock',
  options: {},
};

const onSpecialFilter = {
  model: 'Vehicle',
  field: 'is_on_special',
  value: 'True',
  options: {
    label: 'On Special',
  },
};

const usedFilter = {
  model: 'Vehicle',
  field: 'stock_type',
  value: 'USED',
  options: {
    label: 'Used',
  },
};

const newFilter = {
  model: 'Vehicle',
  field: 'stock_type',
  value: 'NEW',
  options: {
    label: 'New',
  },
};

// Seems weird to click the "Vehicles with Photos" card, then get
// to vehicles without photos. But, this is the way it currently works in
// app-inventory, so.... ?
const missingPhotosFilter = {
  model: 'Vehicle',
  field: 'not_enough_photos',
  value: 'True',
  options: {
    label: 'Missing Photos',
  },
};

const missingDescriptionFilter = {
  model: 'Vehicle',
  field: 'is_missing_description',
  value: 'True',
  options: {
    label: 'No Description',
  },
};

const isDemoFilter = {
  model: 'Vehicle',
  field: 'is_demo_unit',
  value: 'True',
  options: {
    label: 'Demo Vehicle',
  },
};

const isLoanerFilter = {
  model: 'Vehicle',
  field: 'is_loaner',
  value: 'True',
  options: {
    label: 'Loaner Vehicle',
  },
};

const hasCostFilter = {
  model: 'Vehicle',
  field: 'has_cost',
  value: 'True',
  options: {
    label: 'Has Cost',
  },
};

const reconCompleteFilter = {
  model: 'Vehicle',
  field: 'recon_complete',
  value: 'False',
  options: {
    label: 'Recon Incomplete',
  },
};

const USED_FACETS = [
  {
    title: 'In Stock',
    model: 'StockStatus',
    field: 'name',
    value: 'in stock',
    icon: 'fas fa-car',
    filters: [inStockFilter, usedFilter],
    destination: '/inventory',
  },
  {
    title: 'On Special',
    model: 'Vehicle',
    field: 'is_on_special',
    value: 'True',
    icon: 'fas fa-tag',
    filters: [onSpecialFilter, inStockFilter, usedFilter],
    destination: '/inventory',
  },
  {
    title: 'With Photos',
    model: 'Vehicle',
    field: 'not_enough_photos',
    value: 'False',
    icon: 'fas fa-camera',
    asPercentage: true,
    filters: [missingPhotosFilter, inStockFilter, usedFilter, hasCostFilter],
    destination: '/inventory',
  },
  {
    title: 'Decoded',
    model: 'Vehicle',
    field: 'is_decoded',
    value: 'True',
    icon: 'fas fa-magic',
    asPercentage: true,
    destination: '/inventory/decode-wizard',
  },
  {
    title: 'With Description',
    model: 'Vehicle',
    field: 'is_missing_description',
    value: 'False',
    icon: 'fas fa-quote-left',
    asPercentage: true,
    destination: '/inventory',
    filters: [missingDescriptionFilter, inStockFilter, usedFilter],
  },
  {
    title: 'Booked Out',
    model: 'Vehicle',
    field: 'is_booked',
    value: 'True',
    icon: 'fas fa-book',
    asPercentage: true,
    destination: '/inventory/cbb-wizard',
  },
  {
    title: 'Demo Vehicles',
    model: 'Vehicle',
    field: 'is_demo_unit',
    value: 'True',
    icon: 'fas fa-car-mechanic',
    filters: [isDemoFilter, inStockFilter, usedFilter],
    destination: '/inventory',
  },
  {
    title: 'Loaner Vehicles',
    model: 'Vehicle',
    field: 'is_loaner',
    value: 'True',
    icon: 'fas fa-exchange',
    filters: [isLoanerFilter, inStockFilter, usedFilter],
    destination: '/inventory',
  },

  {
    title: 'Recon Complete',
    model: 'Vehicle',
    field: 'recon_complete',
    value: 'True',
    icon: 'fas fa-check',
    filters: [reconCompleteFilter, inStockFilter, usedFilter],
    asPercentage: true,
    destination: '/inventory',
  },

  {
    title: 'With Inspection',
    model: 'Vehicle',
    field: 'has_uvi',
    value: 'True',
    icon: 'fas fa-road',
    filters: [inStockFilter, usedFilter, missingUviFilter],
    asPercentage: true,
    destination: '/inventory',
  },
];

const NEW_FACETS = [
  {
    title: 'In Stock',
    model: 'StockStatus',
    field: 'name',
    value: 'in stock',
    icon: 'fas fa-car',
    destination: '/inventory',
    filters: [inStockFilter, newFilter],
  },
  {
    title: 'On Special',
    model: 'Vehicle',
    field: 'is_on_special',
    value: 'True',
    icon: 'fas fa-tag',
    destination: '/inventory',
    filters: [onSpecialFilter, inStockFilter, newFilter],
  },
  {
    title: 'With Photos',
    model: 'Vehicle',
    field: 'not_enough_photos',
    value: 'False',
    icon: 'fas fa-camera',
    asPercentage: true,
    destination: '/inventory',
    filters: [missingPhotosFilter, inStockFilter, newFilter, hasCostFilter],
  },
  {
    title: 'Decoded',
    model: 'Vehicle',
    field: 'is_decoded',
    value: 'True',
    icon: 'fas fa-magic',
    asPercentage: true,
    destination: '/inventory/decode-wizard',
  },
  {
    title: 'With Description',
    model: 'Vehicle',
    field: 'is_missing_description',
    value: 'False',
    icon: 'fas fa-quote-left',
    asPercentage: true,
    destination: '/inventory',
    filters: [missingDescriptionFilter, inStockFilter, newFilter],
  },
  {
    title: 'Demo Vehicles',
    model: 'Vehicle',
    field: 'is_demo_unit',
    value: 'True',
    icon: 'fas fa-car-mechanic',
    filters: [isDemoFilter, inStockFilter, newFilter],
    destination: '/inventory',
  },
  {
    title: 'Loaner Vehicles',
    model: 'Vehicle',
    field: 'is_loaner',
    value: 'True',
    icon: 'fas fa-exchange',
    filters: [isLoanerFilter, inStockFilter, newFilter],
    destination: '/inventory',
  },
];

const InventoryDashboard = () => {
  const mobile = useMediaQuery(theme => theme.breakpoints.down('md'));
  const { dealerId } = useDealerContext();
  const {
    currentUser: { role },
  } = useUserContext();
  // Used to upload inventory if cdk is down.
  // const [uploadOpen, setUploadOpen] = useState(false);
  const { data, error, loading } = useQuery(DASHBOARD_QUERY, {
    variables: { dealerId },
    fetchPolicy: 'network-only',
  });
  const history = useHistory();

  if (loading) return <Loading />;
  if (error) return <>Error loading dashboard</>;

  const usedVehicles = data?.inventory?.usedVehicles || [];
  const newVehicles = data?.inventory?.newVehicles || [];

  const usedPricingChanges = usedVehicles.filter(obj => {
    return (
      obj.field === 'no_price_changes_at_7_days' ||
      obj.field === 'no_price_changes_at_14_days' ||
      obj.field === 'no_price_changes_at_21_days' ||
      obj.field === 'no_price_changes_at_28_days'
    );
  });

  let usedPricingData = [];
  usedPricingChanges.forEach(item => usedPricingData.push(item.data[0].count));

  const newPricingChanges = newVehicles.filter(obj => {
    return (
      obj.field === 'no_price_changes_at_7_days' ||
      obj.field === 'no_price_changes_at_14_days' ||
      obj.field === 'no_price_changes_at_21_days' ||
      obj.field === 'no_price_changes_at_28_days'
    );
  });

  let newPricingData = [];
  newPricingChanges.forEach(item => newPricingData.push(item.data[0].count));

  const usedInStockData = DAYS_IN_STOCK_ORDER.map(
    value =>
      usedVehicles
        ?.find(x => x.field === 'days_in_stock_category')
        ?.data.find(x => x.value === value) || { value, count: 0 },
  );

  const newInStockData = DAYS_IN_STOCK_ORDER.map(
    value =>
      newVehicles
        ?.find(x => x.field === 'days_in_stock_category')
        ?.data.find(x => x.value === value) || { value, count: 0 },
  );

  // not_enough_photos behaves slightly differently
  const usedCars = USED_FACETS.map(({ model, field, value, ...rest }) => ({
    number:
      field === 'not_enough_photos' // Filter out all vehicles without a cost
        ? (usedVehicles
            ?.find(x => x.field === field && x.model === model)
            .data.find(x => x.value === value)?.count || 0) -
          (usedVehicles
            ?.find(x => x.field === 'has_cost' && x.model === model)
            .data.find(x => x.value === value)?.count || 0)
        : usedVehicles
            ?.find(x => x.field === field && x.model === model)
            .data.find(x => x.value === value)?.count || 0,
    total:
      field === 'not_enough_photos' // Filter out all vehicles without a cost
        ? usedVehicles
            ?.find(x => x.field === field && x.model === model)
            .data.map(x => x.count)
            .reduce((acc, val) => acc + val, 0) -
            usedVehicles
              ?.find(x => x.field === 'has_cost' && x.model === model)
              .data.find(x => x.value === 'False')?.count || 0
        : usedVehicles
            ?.find(x => x.field === field && x.model === model)
            .data.map(x => x.count)
            .reduce((acc, val) => acc + val, 0),
    ...rest,
  }));

  // not_enough_photos behaves slightly differently
  const newCars = NEW_FACETS.map(({ model, field, value, ...rest }) => ({
    number:
      field === 'not_enough_photos' // Filter out all vehicles without a cost
        ? (newVehicles
            ?.find(x => x.field === field && x.model === model)
            .data.find(x => x.value === value)?.count || 0) -
          (newVehicles
            ?.find(x => x.field === 'has_cost' && x.model === model)
            .data.find(x => x.value === value)?.count || 0)
        : newVehicles
            ?.find(x => x.field === field && x.model === model)
            .data.find(x => x.value === value)?.count || 0,
    total:
      field === 'not_enough_photos' // Filter out all vehicles without a cost
        ? newVehicles
            ?.find(x => x.field === field && x.model === model)
            .data.map(x => x.count)
            .reduce((acc, val) => acc + val, 0) -
            newVehicles
              ?.find(x => x.field === 'has_cost' && x.model === model)
              .data.find(x => x.value === 'False')?.count || 0
        : newVehicles
            ?.find(x => x.field === field && x.model === model)
            .data.map(x => x.count)
            .reduce((acc, val) => acc + val, 0),
    ...rest,
  }));
  const displayNumber = (number, total, asPercentage) =>
    asPercentage
      ? Number.isNaN(number / total)
        ? ' - '
        : `${((number / total) * 100).toFixed(DECIMAL_PLACES)}%`
      : `${number}`;

  const displayColor = (number, total, asPercentage) =>
    asPercentage
      ? Number.isNaN(number / total)
        ? 'black'
        : number / total > 0.95
        ? 'green'
        : number / total <= 0.8
        ? 'red'
        : 'orange'
      : 'black';

  const clickTileHandler = (destination, filters) => () => {
    if (filters)
      sessionStorage.setItem('inventoryFacets', JSON.stringify(filters));
    if (destination) history.push(destination);
  };

  // Used to upload inventory if cdk is down.
  // const downloadSampleCSV = () => {
  //   const headers = [
  //     'stock_type',
  //     'stock_number',
  //     'is_certified',
  //     'vin',
  //     'year',
  //     'odometer',
  //     'regular_price',
  //     'cost',
  //     'exterior_colour_name',
  //     'msrp',
  //     'cdk_options',
  //   ];

  //   const csvContent =
  //     'data:text/csv;base64,' +
  //     btoa(unescape(encodeURIComponent(stringify([headers]))));

  //   const csvFileName = `inventory_headers_sample.csv`;
  //   const element = document.createElement('a');
  //   element.href = csvContent;
  //   element.download = csvFileName;
  //   document.body.appendChild(element);
  //   element.click();
  //   document.body.removeChild(element);
  // };

  return (
    <>
      {/* Used to upload inventory if cdk is down */}
      {/* <Dialog open={uploadOpen} onClose={() => setUploadOpen(false)}>
        <UploadInventoryModal onClose={() => setUploadOpen(false)} />
      </Dialog> */}
      <Box marginLeft={1} marginTop={1}>
        <DealerPicker />
        {/* Used to upload inventory if cdk is down.
        {role && RoleGroup.MANUALLY_CREATE_VEHICLE.includes(role) && (
          <Box marginTop={2}>
            <Button
              onClick={() => downloadSampleCSV()}
              style={{ marginRight: '1rem' }}
            >
              Sample csv
            </Button>
            <Button onClick={() => setUploadOpen(true)}>Upload csv</Button>
          </Box>
        )}
        */}
      </Box>
      <Box marginTop={3}>
        <Grid
          container
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            maxWidth: '1100px',
          }}
        >
          <Grid item style={{ paddingLeft: '2rem' }}>
            <Typography variant="h5">Inventory Dashboard</Typography>
          </Grid>
          <Grid item>
            <InventorySearch
              dashboard={true}
              queryContext={useVehiclesQueryContext}
            />
          </Grid>
        </Grid>
      </Box>
      <Grid container style={{ padding: '1rem', maxWidth: '1200px' }}>
        <Grid item lg={6} xs={12}>
          <div style={{ paddingLeft: '1rem' }}>
            <Typography variant="h6">Used Vehicles</Typography>
          </div>
          <Grid container style={{ marginRight: '2rem' }}>
            {usedCars.map(
              ({
                title,
                number,
                total,
                icon,
                asPercentage,
                destination,
                filters,
              }) => (
                <DashboardItem
                  key={title}
                  title={title}
                  icon={icon}
                  value={displayNumber(number, total, asPercentage)}
                  subValue={asPercentage ? `(${number}/${total})` : null}
                  color={displayColor(number, total, asPercentage)}
                  onClick={clickTileHandler(destination, filters)}
                  lg={6}
                  md={4}
                  sm={6}
                  xs={12}
                />
              ),
            )}
            <Grid item md={10} xs={12}>
              <Bar
                data={{
                  labels: usedInStockData.map(x => x.value),
                  datasets: [
                    {
                      backgroundColor: ({ dataIndex, dataset: { data } }) =>
                        data[dataIndex] === Math.max(...data)
                          ? 'blue'
                          : 'CornflowerBlue',
                      data: usedInStockData.map(x => x.count),
                    },
                  ],
                }}
                options={{
                  legend: {
                    display: false,
                  },
                  onClick: (e, elems) => {
                    if (elems?.[0]?._model?.label)
                      clickTileHandler('/inventory', [
                        usedFilter,
                        inStockFilter,
                        {
                          model: 'Vehicle',
                          field: 'days_in_stock_category',
                          value: elems[0]._model.label,
                          options: {},
                        },
                      ])();
                  },
                  title: {
                    display: true,
                    text: 'Days In Stock',
                  },
                }}
              />
            </Grid>
            <Grid item md={10} xs={12}>
              <Bar
                data={{
                  labels: PRICE_CHANGES_ORDER,
                  datasets: [
                    {
                      backgroundColor: ({ dataIndex, dataset: { data } }) =>
                        data[dataIndex] === Math.max(...data)
                          ? 'blue'
                          : 'CornflowerBlue',
                      data: usedPricingData,
                    },
                  ],
                }}
                options={{
                  legend: {
                    display: false,
                  },
                  onClick: (e, elems) => {
                    if (elems?.[0]?._model?.label)
                      clickTileHandler('/inventory', [
                        usedFilter,
                        inStockFilter,
                        {
                          model: 'Vehicle',
                          field: getFieldFromLabel(elems[0]._model.label),
                          value: true,
                          options: { label: elems[0]._model.label },
                        },
                      ])();
                  },
                  title: {
                    display: true,
                    text: 'Days Since Price Changes',
                  },
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={6} xs={12}>
          <div style={{ paddingLeft: '1rem' }}>
            <Typography variant="h6">New Vehicles</Typography>
          </div>
          <Grid container>
            {newCars.map(
              ({
                title,
                number,
                total,
                icon,
                asPercentage,
                destination,
                filters,
              }) => (
                <DashboardItem
                  key={title}
                  title={title}
                  icon={icon}
                  value={displayNumber(number, total, asPercentage)}
                  subValue={asPercentage ? `(${number}/${total})` : null}
                  color={displayColor(number, total, asPercentage)}
                  onClick={clickTileHandler(destination, filters)}
                  lg={6}
                  md={4}
                  sm={6}
                  xs={12}
                />
              ),
            )}
            {/* Add a spacer to make the grid look better. TODO: Remove when we have more cards */}
            <Grid
              item
              xs={12}
              style={mobile ? { height: '' } : { height: '183.2px' }}
            />
            <Grid item md={10} xs={12}>
              <Bar
                data={{
                  labels: newInStockData.map(x => x.value),
                  datasets: [
                    {
                      backgroundColor: ({ dataIndex, dataset: { data } }) =>
                        data[dataIndex] === Math.max(...data)
                          ? 'blue'
                          : 'CornflowerBlue',
                      data: newInStockData.map(x => x.count),
                    },
                  ],
                }}
                options={{
                  legend: {
                    display: false,
                  },
                  onClick: (e, elems) => {
                    if (elems?.[0]?._model?.label)
                      clickTileHandler('/inventory', [
                        newFilter,
                        inStockFilter,
                        {
                          model: 'Vehicle',
                          field: 'days_in_stock_category',
                          value: elems[0]._model.label,
                          options: {},
                        },
                      ])();
                  },
                  title: {
                    display: true,
                    text: 'Days In Stock',
                  },
                }}
              />
            </Grid>
            <Grid item md={10} xs={12}>
              <Bar
                data={{
                  labels: PRICE_CHANGES_ORDER,
                  datasets: [
                    {
                      backgroundColor: ({ dataIndex, dataset: { data } }) =>
                        data[dataIndex] === Math.max(...data)
                          ? 'blue'
                          : 'CornflowerBlue',
                      data: newPricingData,
                    },
                  ],
                }}
                options={{
                  legend: {
                    display: false,
                  },
                  onClick: (e, elems) => {
                    if (elems?.[0]?._model?.label)
                      clickTileHandler('/inventory', [
                        newFilter,
                        inStockFilter,
                        {
                          model: 'Vehicle',
                          field: getFieldFromLabel(elems[0]._model.label),
                          value: true,
                          options: { label: elems[0]._model.label },
                        },
                      ])();
                  },
                  title: {
                    display: true,
                    text: 'Days Since Price Changes',
                  },
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default InventoryDashboard;
