import React from 'react';
import moment from 'moment-timezone';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

const LeadAcquisitionSummaryCard = ({ lead, classes }) => (
  <Card style={{ height: '100%' }}>
    <CardContent>
      <Typography style={{ padding: '0 0 .5rem 1rem', fontWeight: '700' }}>
        Lead Acquisition Summary
      </Typography>
      <TableContainer>
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
        >
          <colgroup>
            <col style={{ width: '50%' }} />
            <col style={{ width: '50%' }} />
          </colgroup>
          <TableBody>
            <TableRow>
              <TableCell>Source</TableCell>
              <TableCell>
                <b>{lead.form_data?.lead_source || '-'}</b>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Form Name</TableCell>
              <TableCell>
                <b>{lead.form_data?.form_name || '-'}</b>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Dealer</TableCell>
              <TableCell>
                <b>{lead.dealer?.dealer_name || '-'}</b>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Channel</TableCell>
              <TableCell>
                <b>{lead.form_data?.lead_channel || '-'}</b>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Campaign Name</TableCell>
              <TableCell>
                <b>{lead.form_data?.campaign_name || '-'}</b>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Campaign Source</TableCell>
              <TableCell>
                <b>{lead.form_data?.campaign_source || '-'}</b>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Campaign Medium</TableCell>
              <TableCell>
                <b>{lead.form_data?.campaign_medium || '-'}</b>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Submitted Date</TableCell>
              <TableCell>
                <b>
                  {lead.form_data?.submitted_timestamp
                    ? moment(lead.form_data?.submitted_timestamp).format(
                        'MMM D, YYYY',
                      )
                    : '-'}
                </b>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Archived Date</TableCell>
              <TableCell>
                <b>
                  {lead.is_archived
                    ? moment(lead.archived).format('MMM D, YYYY')
                    : '-'}
                </b>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Preferred Date</TableCell>
              <TableCell>
                <b>
                  {lead.form_data?.preferred_date
                    ? moment(lead.form_data?.preferred_date).format(
                        'MMM D, YYYY',
                      )
                    : '-'}
                </b>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Preferred Time</TableCell>
              <TableCell>
                <b>{lead.form_data?.preferred_time || '-'}</b>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </CardContent>
  </Card>
);

export default LeadAcquisitionSummaryCard;
