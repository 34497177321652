import React from 'react';

/* external */
import gql from 'graphql-tag';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { useMutation } from '@apollo/react-hooks';

/* Material UI */
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Checkbox from '@mui/material/Checkbox';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { useMediaQuery } from '@mui/material';

/* internal */
import DoNotContactModal from './DoNotContactModal';
import OptInPreferencesModal from './OptInPreferencesModal';
import { Role } from 'constants.js';
import { useUserContext } from 'components/MaterialUI/UserContext';

const UPDATE_OPT_IN_PREFS = gql`
  mutation updateOptInPrefs($_id: ID!, $prefs: OptInPreferencesInput!) {
    updateCustomerOptInPrefs(_id: $_id, prefs: $prefs) {
      _id
      opt_in_preferences {
        global {
          text
          mail
          email
          phone
        }
      }
    }
  }
`;

const UPDATE_APPOINTMENT_REMINDER_PREFS = gql`
  mutation updateAppointmentReminderPrefs(
    $_id: ID!
    $prefs: CustomerAppointmentReminderPrefsInput!
  ) {
    updateCustomerAppointmentReminderPrefs(_id: $_id, prefs: $prefs) {
      _id
      appointment_reminder_preferences {
        service {
          sms
          email
        }
        sales {
          sms
          email
        }
        late {
          sms
          email
        }
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
  },
}));

const PrefsCheckbox = ({ control, handleUpdate, isHidden, name }) => (
  <Controller
    control={control}
    name={name}
    render={({ field: { onChange, name, onBlur, value } }) => (
      <Checkbox
        disabled={isHidden}
        onBlur={onBlur}
        onChange={e => {
          onChange(e.target.checked);
          handleUpdate(name);
        }}
        checked={value}
        color="secondary"
      />
    )}
  />
);

const OptInPreferences = ({ customer, refetch }) => {
  const classes = useStyles();
  const { currentUser } = useUserContext();
  const isLocked = customer?.do_not_contact || false;
  const isHidden = currentUser.role !== Role.ORGANIZATION_ADMIN && isLocked;

  // I added this in the last time I did this; not sure if it's necessary?
  const defaultValues = {
    opt_in_preferences: {
      global: {
        text: customer.opt_in_preferences.global.text,
        mail: customer.opt_in_preferences.global.mail,
        email: customer.opt_in_preferences.global.email,
        phone: customer.opt_in_preferences.global.phone,
      },
    },
    appointment_reminder_preferences: {
      service: {
        sms: customer.appointment_reminder_preferences.service?.sms,
        email: customer.appointment_reminder_preferences.service?.email,
      },
      sales: {
        sms: customer.appointment_reminder_preferences.sales?.sms,
        email: customer.appointment_reminder_preferences.sales?.email,
      },
      late: {
        sms: customer.appointment_reminder_preferences.late?.sms,
        email: customer.appointment_reminder_preferences.late?.email,
      },
    },
  };

  const { control } = useForm({
    defaultValues,
  });

  // Needed?
  const optInPrefsGlobal = useWatch({
    control,
    name: 'opt_in_preferences.global',
  });
  const apptReminderPrefs = useWatch({
    control,
    name: 'appointment_reminder_preferences',
  });

  const [updateOptInPrefs] = useMutation(UPDATE_OPT_IN_PREFS);
  const [updateAppointmentReminderPrefs] = useMutation(
    UPDATE_APPOINTMENT_REMINDER_PREFS,
  );
  const { enqueueSnackbar } = useSnackbar();

  const handleUpdate = name => {
    const updatePrefs = () => {
      if (name.includes('opt_in_preferences')) {
        const nameShort = name.split('.')[2].toLowerCase();
        return updateOptInPrefs({
          variables: {
            _id: customer._id,
            prefs: {
              [nameShort]: !optInPrefsGlobal[nameShort], // This is triggered before useWatch updates
            },
          },
        });
      } else {
        return updateAppointmentReminderPrefs({
          variables: {
            _id: customer._id,
            prefs: apptReminderPrefs,
          },
        });
      }
    };

    updatePrefs()
      .then(r => {
        enqueueSnackbar(`Successfully updated ${customer.fullname}`, {
          variant: 'success',
        });
      })
      .catch(e => {
        enqueueSnackbar(`Error in updating ${customer.fullname}`, {
          variant: 'error',
        });
      });
  };
  const desktop = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const medium = useMediaQuery(theme => theme.breakpoints.between('sm', 'lg'));

  const checkboxProps = { control, handleUpdate, isHidden };
  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={medium ? 6 : desktop ? 3 : 6}>
          <OptInPreferencesModal
            customer={customer}
            name={'opt_in_preferences.global.email'}
          />
        </Grid>
        <Grid item xs={medium ? 6 : desktop ? 3 : 6}>
          <OptInPreferencesModal
            customer={customer}
            name={'opt_in_preferences.global.text'}
          />
        </Grid>
        <Grid item xs={medium ? 6 : desktop ? 3 : 6}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <PrefsCheckbox
                {...checkboxProps}
                name="opt_in_preferences.global.phone"
              />
            </Grid>
            <Grid item xs={3}>
              <ListItemText primary={'Phone'} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={medium ? 6 : desktop ? 3 : 6}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <PrefsCheckbox
                {...checkboxProps}
                name="opt_in_preferences.global.mail"
              />
            </Grid>
            <Grid item xs={3}>
              <ListItemText primary={'Mail'} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={medium ? 6 : desktop ? 3 : 6}>
          <DoNotContactModal customer={customer} refetch={refetch} />
        </Grid>
      </Grid>
      <Typography className={classes.title} gutterBottom>
        Appointment Reminder Preferences
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <List>
            <ListItem style={{ padding: 0, margin: 0 }}>
              <ListItemText
                primaryTypographyProps={{
                  style: {
                    fontSize: '12px',
                    fontWeight: 'bold',
                    paddingLeft: '10px',
                  },
                }}
              >
                Service
              </ListItemText>
            </ListItem>
            <ListItem style={{ maxHeight: '30px', padding: 0, margin: 0 }}>
              <PrefsCheckbox
                {...checkboxProps}
                name="appointment_reminder_preferences.service.email"
              />
              Email
            </ListItem>
            <ListItem style={{ maxHeight: '30px', padding: 0, margin: 0 }}>
              <PrefsCheckbox
                {...checkboxProps}
                name="appointment_reminder_preferences.service.sms"
              />
              Text
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={4}>
          <List>
            <ListItem style={{ padding: 0, margin: 0 }}>
              <ListItemText
                primaryTypographyProps={{
                  style: {
                    fontSize: '12px',
                    fontWeight: 'bold',
                    paddingLeft: '10px',
                  },
                }}
              >
                Sales
              </ListItemText>
            </ListItem>
            <ListItem style={{ maxHeight: '30px', padding: 0, margin: 0 }}>
              <PrefsCheckbox
                {...checkboxProps}
                name="appointment_reminder_preferences.sales.email"
              />
              Email
            </ListItem>
            <ListItem style={{ maxHeight: '30px', padding: 0, margin: 0 }}>
              <PrefsCheckbox
                {...checkboxProps}
                name="appointment_reminder_preferences.sales.sms"
              />
              Text
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={4}>
          <List>
            <ListItem style={{ padding: 0, margin: 0 }}>
              <ListItemText
                primaryTypographyProps={{
                  style: {
                    fontSize: '12px',
                    fontWeight: 'bold',
                    paddingLeft: '10px',
                  },
                }}
              >
                Late/Missed Service
              </ListItemText>
            </ListItem>
            <ListItem style={{ maxHeight: '30px', padding: 0, margin: 0 }}>
              <PrefsCheckbox
                {...checkboxProps}
                name="appointment_reminder_preferences.late.email"
              />
              Email
            </ListItem>
            <ListItem style={{ maxHeight: '30px', padding: 0, margin: 0 }}>
              <PrefsCheckbox
                {...checkboxProps}
                name="appointment_reminder_preferences.late.sms"
              />
              Text
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </>
  );
};

// TODO: should be able to eliminate refetch
const OptInPreferencesCard = ({ customer, refetch }) => {
  const classes = useStyles();
  const desktop = useMediaQuery(theme => theme.breakpoints.up('sm'));
  return (
    <>
      {desktop ? (
        <Card className={classes.root}>
          <CardContent>
            <Typography className={classes.title} gutterBottom>
              Opt In Preferences
            </Typography>
            <OptInPreferences customer={customer} refetch={refetch} />
          </CardContent>
        </Card>
      ) : (
        <Accordion defaultExpanded={true}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.title} gutterBottom>
              Opt In Preferences
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{ paddingTop: 0 }}>
            <Box>
              <OptInPreferences customer={customer} refetch={refetch} />
            </Box>
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
};

OptInPreferencesCard.fragments = {
  customer: gql`
    fragment OptInPreferencesCardFragment on Customer {
      _id
      do_not_contact
      appointment_reminder_preferences {
        service {
          sms
          email
        }
        sales {
          sms
          email
        }
        late {
          sms
          email
        }
      }
      opt_in_preferences {
        global {
          text
          mail
          email
          phone
        }
      }
    }
  `,
};
export default OptInPreferencesCard;
