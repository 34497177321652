import React, { createContext, useContext, useEffect, useState } from 'react';

/* external */
import * as timesync from 'timesync';
import moment from 'moment-timezone';

/* Material UI */
import { Box } from '@mui/material';

/* internal */
import { pad2 } from 'utils';

/* A synchronizable clock context that updates on a specified time interval */

const TimeSyncContext = createContext({
  clock: null,
  serverTimeOffset: null, // TODO: remove once migrate old components
  isElapsed: () => {},
});

export const TimeSyncContextProvider = ({
  children,
  server,
  interval = 1000,
  syncInterval = 60000 * 10,
}) => {
  const [serverTimeOffset, setServerTimeOffset] = useState(null);
  const [clock, setClock] = useState(null);

  useEffect(() => {
    const ts = timesync.create({
      server,
      interval: syncInterval,
    });
    ts.on('change', offset => setServerTimeOffset(offset));
    return () => {
      ts.off('change');
      ts.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (serverTimeOffset !== undefined || serverTimeOffset !== null) {
      const timerId = setInterval(() => {
        setClock(moment().subtract(serverTimeOffset));
      }, interval);
      return () => clearInterval(timerId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serverTimeOffset]);

  const isElapsed = targetTime => moment(targetTime + 'Z').isBefore(clock);

  return (
    <TimeSyncContext.Provider value={{ clock, isElapsed, serverTimeOffset }}>
      {children}
    </TimeSyncContext.Provider>
  );
};

export const useTimeSyncContext = () => useContext(TimeSyncContext);

const defaultCountdownFormat = x =>
  `${
    x.days() ? `${x.days()} day${x.days() > 1 ? 's' : ''} ` : ''
  }${x.hours()}:${pad2(x.minutes())}:${pad2(Math.round(x.seconds() / 2) * 2)}`;

export const Countdown = ({
  endTime,
  elapsedDisplay = null,
  formatFunction = defaultCountdownFormat,
  runningPrefix,
  ...rest
}) => {
  const { clock } = useTimeSyncContext();
  const timeDiff = moment.duration(moment(endTime + 'Z').diff(clock));
  const isElapsed = timeDiff < 0;

  return (
    <Box {...rest}>
      {!isElapsed && runningPrefix}
      {!isElapsed && runningPrefix && ' '}
      {clock ? (isElapsed ? elapsedDisplay : formatFunction(timeDiff)) : null}
    </Box>
  );
};

export default TimeSyncContextProvider;
