import React from 'react';
import moment from 'moment';

import {
  Box,
  DialogTitle,
  DialogContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';

import { makeStyles } from '@mui/styles';

import { BULLETIN_TYPES, BULLETIN_STATUS_DISPLAY } from '../constant';
import { titleCase } from 'utils';

const useStyles = makeStyles(theme => ({
  caption: {
    fontSize: '12px',
    color: '#6c757d',
  },
}));

const EditBulletinContent = ({
  existingBulletin,
  bulletinType,
  handleType,
}) => {
  const classes = useStyles();
  return (
    <>
      <DialogTitle
        style={{
          fontSize: '16px',
          fontWeight: 'bold',
          padding: '16px 24px 0 24px',
        }}
      >
        Customer Bulletin
      </DialogTitle>
      <DialogContent style={{ padding: '0 24px 0 24px', overflow: 'visible' }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              style={{ fontSize: '20px', fontWeight: 'bold' }}
            >
              {titleCase(BULLETIN_STATUS_DISPLAY[existingBulletin.type])}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} style={{ paddingBottom: 0 }}>
            <Typography variant="caption" className={classes.caption}>
              {`Added: ${moment(existingBulletin.created).format(
                'MMMM Do, YYYY',
              )} by ${existingBulletin.created_by?.display_name}`}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="caption" className={classes.caption}>
              {`Edited: ${moment(existingBulletin.updated).format(
                'MMMM Do, YYYY',
              )}`}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box m="8px">
              <FormControl>
                <InputLabel shrink>Department</InputLabel>
                <Select
                  value={bulletinType}
                  onChange={handleType}
                  disabled
                  style={{ width: '100%' }}
                  label="Department"
                >
                  {BULLETIN_TYPES.map(({ value, text }) => (
                    <MenuItem key={value} value={value}>
                      {text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </>
  );
};

export default EditBulletinContent;
