import React from 'react';

import { Box, TableCell, TableRow } from '@mui/material';

const UserTableRow = ({ user, rolesData }) => {
  return (
    <TableRow key={user.username}>
      <TableCell>
        <Box>
          <b>{user.display_name}</b>
        </Box>{' '}
        <Box>{user.username}</Box>
      </TableCell>
      <TableCell>
        {rolesData.roles.find(x => x.id === user.role).name}
      </TableCell>
    </TableRow>
  );
};

export default UserTableRow;
