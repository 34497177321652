import React from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { CBB_CONDITIONS } from './index';
import { formatPrice, titleize } from 'utils';

const Section = ({ children }) => (
  <Accordion style={{ width: '100%', marginBottom: '2px' }}>
    {children}
  </Accordion>
);

const Label = ({ children }) => (
  <AccordionSummary
    expandIcon={<ExpandMoreIcon />}
    style={{ fontWeight: 'bold', fontSize: '16px' }}
  >
    {children}
  </AccordionSummary>
);

const Details = ({ children }) => (
  <AccordionDetails>{children}</AccordionDetails>
);

const ValuationMobile = ({ condition, retailPrices, wholesalePrices }) => (
  <Grid item xs={12}>
    <Section>
      <Label>Wholesale/Retail Pricing</Label>
      <Details>
        <Grid container style={{ width: '100%' }} spacing={2}>
          <Grid item xs={12}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2} style={{ fontWeight: 'bold' }}>
                    Wholesale
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {CBB_CONDITIONS.map(key => (
                  <TableRow selected={key === condition} key={key}>
                    <TableCell>{titleize(key)}</TableCell>
                    <TableCell>{formatPrice(wholesalePrices[key])}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
          <Grid item xs={12}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2} style={{ fontWeight: 'bold' }}>
                    Retail
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {CBB_CONDITIONS.map(key => (
                  <TableRow selected={key === condition} key={key}>
                    <TableCell>{titleize(key)}</TableCell>
                    <TableCell>{formatPrice(retailPrices[key])}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </Details>
    </Section>
  </Grid>
);

export default ValuationMobile;
