export const StockStatus = {
  IGNORE: 2,
  IN_STOCK: 3,
  OFFLINE: 5,
  RECON: 6,
  SOLD: 1,
  WHOLESALE: 4,
};

export const PriceModifierTypes = {
  REBATE: 'rebate',
  ADDON: 'addon',
  DISCOUNT: 'discount',
};

export const RateTypes = {
  FINANCE: 'Finance',
  LEASE: 'Lease Rate',
};
