import React from 'react';

/* external */
import { useHistory } from 'react-router-dom';
import gql from 'graphql-tag';

/* Material UI */
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Typography,
} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import PrintIcon from '@mui/icons-material/Print';

/* internal */
import { displayName } from '../utils';
import { formatPrice } from 'utils';

const DealerAccept = ({ appraisal, createBillOfSaleUrl }) => {
  const history = useHistory();

  const { price, trimVariation, vin } = appraisal;
  return (
    <>
      <Box margin={1}>
        <Grid container direction="column">
          <Grid item>
            <Typography style={{ width: '100%' }}>
              <b>{displayName(appraisal)}</b> {trimVariation}
            </Typography>
          </Grid>
          <Grid item style={{ paddingBottom: '1rem' }}>
            {vin}
          </Grid>
        </Grid>
      </Box>
      <Box margin={1}>
        <Paper
          style={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
            height: '250px',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: 'auto',
            marginBottom: 'auto',
            padding: '1rem',
          }}
        >
          <Typography variant="h5">
            Congratulations! <br />
            You have accepted this offer.
          </Typography>
          <Typography variant="h6">
            Price: <b>{formatPrice(price)}</b>
          </Typography>
          <Typography variant="p">
            {/* TODO: this should use docusign to print the bill of sale */}
            Print Bill of Sale{' '}
            <IconButton
              onClick={() =>
                createBillOfSaleUrl({ variables: { id: appraisal.id } }).then(
                  res => {
                    window.open(
                      res.data.appraisals.createBillOfSale.billOfSaleUrl,
                      '_blank',
                    );
                  },
                )
              }
              size="large"
            >
              <PrintIcon />
            </IconButton>
          </Typography>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'center',
              height: '200px',
              marginLeft: 'auto',
              marginRight: 'auto',
              padding: '1rem',
            }}
          >
            <Button
              variant="outlined"
              style={{ width: '100px' }}
              onClick={() => history.push('/used-vehicles/')}
            >
              <HomeIcon /> Home
            </Button>
          </div>
        </Paper>
      </Box>
    </>
  );
};

DealerAccept.fragments = {
  appraisal: gql`
    fragment DealerAcceptAppraisal on Appraisal {
      id
      make
      model
      price
      trim
      trimVariation
      vin
      year
    }
  `,
};

export default DealerAccept;
