// External
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';

// Material UI
import { ListItemIcon, Menu, MenuItem, Typography } from '@mui/material';
import { yellow } from '@mui/material/colors';
// MUI Icons
import {
  Archive,
  AssignmentInd,
  Drafts,
  Edit,
  Forward,
  Markunread,
  PersonAdd,
  RemoveCircle,
  Star,
  StarBorder,
  Unarchive,
} from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';

// Internal
import ConfirmDialog from 'components/MaterialUI/ConfirmDialog';
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';
import { useUserContext } from 'components/MaterialUI/UserContext';

import { CONVERSATIONS_QUERY } from '../queries';

import AssignOperatorDialog from './AssignOperatorDialog';
import EditClientDetailsDialog from './EditClientDetailsDialog';
import SendToDealerDialog from './SendToDealerDialog';

const CONVERSATION_FRAGMENT = gql`
  fragment ConversationHeaderConversation on Conversation {
    stars {
      id
      conversation_id
      username
    }
    operator_username
    operator_user {
      display_name
      username
    }
    archived
    has_unread_messages
  }
`;

const UPDATE_STAR = gql`
  mutation starConversation($gatewayId: Int!, $conversationId: Int!) {
    sms {
      starConversation(gatewayId: $gatewayId, conversationId: $conversationId) {
        id
        ...ConversationHeaderConversation
      }
    }
  }
  ${CONVERSATION_FRAGMENT}
`;

const UPDATE_CONVERSATION = gql`
  mutation updateConversation(
    $gatewayId: Int!
    $conversationId: Int!
    $data: UpdateConversationInput!
  ) {
    sms {
      updateConversation(
        gatewayId: $gatewayId
        conversationId: $conversationId
        data: $data
      ) {
        id
        ...ConversationHeaderConversation
      }
    }
  }
  ${CONVERSATION_FRAGMENT}
`;

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiMenuItem-root': {
      padding: '10px 15px',
      borderBottom: '1px solid #f2f2f2',
      '&:last-child': {
        borderBottom: 0,
      },
    },
    '& .MuiListItemIcon-root': {
      minWidth: '35px',
    },
  },
  appBarText: {
    textAlign: 'center',
    display: 'flex',
    color: 'white',
  },
}));

const ConversationActions = ({
  gatewayId,
  refetch,
  conversation,
  menuAnchor,
  setMenuAnchor,
  setSelectedChatId,
  conversationQueryVariables,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { currentUser } = useUserContext();

  const handleMenuClose = () => setMenuAnchor(null);

  const [showEditClientModal, setShowEditClientModal] = useState(false);
  const [showAssignOperatorDialog, setShowAssignOperatorDialog] =
    useState(false);
  const [showUnassignDialog, setShowUnassignDialog] = useState(false);
  const [showSendToDealerModal, setShowSendToDealerModal] = useState(false);

  const isArchived = conversation.archived;
  const isUnread = conversation.has_unread_messages;
  const isUnassigned = !conversation.operator_user;

  const conversationId = parseInt(conversation.id, 10);

  const conversationStarred = _conversation =>
    _conversation.stars
      .filter(x => x.username === currentUser.username)
      .some(x => x.conversation_id === conversationId);

  const isStarred = conversationStarred(conversation);

  const [updateStarredStatus, { loading: starring }] = useMutation(
    UPDATE_STAR,
    {
      onCompleted: data => {
        enqueueSnackbar(
          `Conversation ${
            conversationStarred(data.sms.starConversation)
              ? ''
              : 'removed from '
          } starred`,
          { variant: 'success' },
        );
      },
      onError: err => {
        enqueueSnackbar(`Unable to save changes: ${err}`, {
          variant: 'error',
        });
      },
    },
  );

  const [updateConversation, { loading: updatingConversation }] =
    useMutation(UPDATE_CONVERSATION);

  const closeStuff = () => {
    handleMenuClose();
    setShowAssignOperatorDialog(false);
    setShowUnassignDialog(false);
    setShowEditClientModal(false);
  };

  const handleSuccess = message => {
    enqueueSnackbar(message, { variant: 'success' });
    // close all the things
    closeStuff();
  };
  const handleEditClientClick = () => setShowEditClientModal(true);

  const handleAssignOperatorClick = () => setShowAssignOperatorDialog(true);

  const handleSendToDealerClick = () => setShowSendToDealerModal(true);

  const handleStarClick = () =>
    updateStarredStatus({
      variables: {
        gatewayId,
        conversationId,
      },
    });

  const handleArchiveClick = () => {
    updateConversation({
      variables: {
        gatewayId,
        conversationId,
        data: {
          archived: !conversation.archived,
        },
      },
      update: (cache, results) => {
        const id = results.data.sms.updateConversation.id;
        const { page, ...otherVars } = conversationQueryVariables;
        for (let curPage = 1; curPage < page + 1; curPage++) {
          const curCache = cache.readQuery({
            query: CONVERSATIONS_QUERY,
            variables: { ...otherVars, page: curPage },
          });
          if (curCache.sms.conversations.results.map(x => x.id).includes(id)) {
            const newCache = Object.assign({}, curCache);
            newCache.sms.conversations.results =
              newCache.sms.conversations.results.filter(x => x.id !== id);
            newCache.sms.conversations.pagination.total--;

            cache.writeQuery({
              query: CONVERSATIONS_QUERY,
              variables: { ...otherVars, page: curPage },
              data: newCache,
            });
          }
        }
      },
    }).then(
      () => {
        enqueueSnackbar(
          isArchived === false
            ? 'Conversation archived!'
            : 'Conversation unarchived!',
          { variant: 'success' },
        );
        setSelectedChatId(0);
      },
      err =>
        enqueueSnackbar(`Unable to save changes: ${err}`, {
          variant: 'error',
        }),
    );
  };

  const handleUnreadClick = () => {
    updateConversation({
      variables: {
        gatewayId,
        conversationId,
        data: {
          has_unread_messages: !conversation.has_unread_messages,
        },
      },
    }).then(
      ({ data }) => {
        enqueueSnackbar(
          data.sms.updateConversation.has_unread_messages
            ? 'Conversation marked as unread!'
            : 'Conversation marked as read!',
          { variant: 'success' },
        );
      },
      err => {
        enqueueSnackbar(`Unable to save changes: ${err}`, {
          variant: 'error',
        });
      },
    );
  };

  const handleCustomerProfileClick = () => {
    if (conversation.customer) {
      window.open(`/customers/${conversation.customer._id}/details`, '_blank');
    } else {
      enqueueSnackbar(
        'A profile for this customer has not been generated yet. Please try again later.',
        {
          variant: 'info',
        },
      );
    }
  };

  const handleAssignOperatorSubmit = operator_username => {
    updateConversation({
      variables: {
        gatewayId,
        conversationId,
        data: { operator_username },
      },
    }).then(
      ({ data }) =>
        handleSuccess(
          `Conversation assigned to ${
            data.sms.updateConversation.operator_user?.display_name ?? 'nobody'
          }`,
        ),
      err =>
        enqueueSnackbar(
          `Error assigning conversation: ${JSON.stringify(err)}`,
          { variant: 'error' },
        ),
    );
  };

  const handleUnassignOperator = () => {
    updateConversation({
      variables: {
        gatewayId,
        conversationId,
        data: { operator_username: null },
      },
    }).then(
      () => handleSuccess('Operator unassigned from conversation'),
      err =>
        enqueueSnackbar(`Error unassigning operator: ${JSON.stringify(err)}`, {
          variant: 'error',
        }),
    );
  };

  return (
    <>
      <LoadingBackdrop open={updatingConversation || starring}>
        Updating Conversation
      </LoadingBackdrop>
      <Menu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={handleMenuClose}
        className={classes.root}
      >
        <MenuItem
          onClick={handleEditClientClick}
          selected={showEditClientModal}
        >
          <ListItemIcon>
            <Edit />
          </ListItemIcon>
          <Typography>Edit Client Details</Typography>
        </MenuItem>
        <MenuItem
          onClick={handleAssignOperatorClick}
          selected={showAssignOperatorDialog}
        >
          <ListItemIcon>
            <PersonAdd />
          </ListItemIcon>
          <Typography>Assign Operator</Typography>
        </MenuItem>
        <MenuItem
          disabled={isUnassigned}
          onClick={() => setShowUnassignDialog(true)}
          selected={showUnassignDialog}
        >
          <ListItemIcon>
            <RemoveCircle />
          </ListItemIcon>
          <Typography>Unassign Operator</Typography>
        </MenuItem>
        {/* If chat is starred, show filled star & unstar chat. If not starred, show outlined star and Star Chat */}
        <MenuItem onClick={handleStarClick}>
          <ListItemIcon>
            {isStarred && <Star style={{ color: yellow[700] }} />}
            {!isStarred && <StarBorder />}
          </ListItemIcon>
          <Typography>{isStarred ? 'Unstar Chat' : 'Star Chat'}</Typography>
        </MenuItem>
        {/* If chat is not archived, show archive. If archived, show unarchived */}
        <MenuItem onClick={handleArchiveClick}>
          <ListItemIcon>
            {!isArchived && <Archive />}
            {isArchived && <Unarchive />}
          </ListItemIcon>
          <Typography>
            {!isArchived ? 'Archive Chat' : 'Unarchive Chat'}
          </Typography>
        </MenuItem>
        <MenuItem onClick={handleUnreadClick}>
          <ListItemIcon>
            {!isUnread && <Drafts />}
            {isUnread && <Markunread />}
          </ListItemIcon>
          <Typography>
            {isUnread ? 'Mark as Read' : 'Mark as Unread'}
          </Typography>
        </MenuItem>
        <MenuItem onClick={handleSendToDealerClick}>
          <ListItemIcon>
            <Forward />
          </ListItemIcon>
          <Typography>Send Lead to Dealer</Typography>
        </MenuItem>
        <MenuItem onClick={handleCustomerProfileClick}>
          <ListItemIcon>
            <AssignmentInd />
          </ListItemIcon>
          <Typography>Customer Profile</Typography>
        </MenuItem>
      </Menu>
      {/* DIALOGS */}
      <EditClientDetailsDialog
        conversation={conversation}
        gatewayId={gatewayId}
        onClose={closeStuff}
        open={showEditClientModal}
        refetch={refetch}
      />

      <AssignOperatorDialog
        gatewayId={gatewayId}
        operator={conversation.operator_username}
        onClose={closeStuff}
        onSubmit={handleAssignOperatorSubmit}
        open={showAssignOperatorDialog}
        maxWidth="xs"
        fullWidth
      />

      <ConfirmDialog
        onClose={closeStuff}
        isOpen={showUnassignDialog}
        titleText="Unassign Operator?"
        text=""
        confirmText="Unassign"
        abortText="Cancel"
        onConfirm={handleUnassignOperator}
      />

      <SendToDealerDialog
        conversationId={conversationId}
        open={showSendToDealerModal}
        onClose={() => setShowSendToDealerModal(false)}
      />
    </>
  );
};

export default ConversationActions;
