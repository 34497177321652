/* external */
import React from 'react';
import { useHistory, Link } from 'react-router-dom';

/* Material UI */
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Tooltip,
  IconButton,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import InfoIcon from '@mui/icons-material/Info';

/* internal */
import { titleCase, formatPrice, getFullDateTime, withSuffix } from 'utils';
import { useUserContext } from 'components/MaterialUI/UserContext';
import InventoryIconGroup from './InventoryIconGroup';
import InventoryTableHeader from './InventoryTableHeader';

const useStyles = makeStyles(theme => ({
  multiLine: {
    whiteSpace: 'pre-line',
    fontSize: '12px',
    color: '#999999',
  },
  colorMultipleLine: {
    whiteSpace: 'pre-line',
    fontSize: '14px',
    color: '#999999',
  },
  table: {
    flexGrow: 2,
  },
}));

const InventoryTableListView = ({
  onSelectAllClick,
  selectByFilter,
  selected,
  setSelected,
  setSortSelection,
  sortSelection,
  vehicles,
  setSelectByFilter,
}) => {
  const classes = useStyles();
  const { me } = useUserContext();
  const history = useHistory();
  const isSelected = ({ id }) => selected.includes(id) || selectByFilter;
  const handleClick = (e, { id }) => {
    // Add the new id in the same order as displayed vehicles.
    if (e.target.checked)
      setSelected(prev =>
        vehicles.map(v => v.id).filter(x => [...prev, id].includes(x)),
      );
    else setSelected(prev => prev.filter(x => x !== id));
    setSelectByFilter(false);
  };

  return (
    <Table stickyHeader aria-label="sticky table">
      <InventoryTableHeader
        numSelected={selected.length}
        selected={selected}
        sortSelection={sortSelection}
        setSortSelection={setSortSelection}
        onSelectAllClick={onSelectAllClick}
        rowCount={vehicles.length}
      />
      <TableBody>
        {vehicles.map(vehicle => {
          const isItemSelected = isSelected(vehicle);
          return (
            <TableRow
              hover
              key={vehicle.id}
              role="checkbox"
              selected={isItemSelected}
              onClick={() => history.push(`/npv-inventory/${vehicle.id}`)}
            >
              <TableCell padding="checkbox">
                <Checkbox
                  color="secondary"
                  checked={isItemSelected}
                  onClick={event => {
                    event.stopPropagation();
                    handleClick(event, vehicle);
                  }}
                />
              </TableCell>
              <TableCell>
                <Tooltip title="Click me to find out more about this vehicle!">
                  <IconButton size="large">
                    <Link to={`/npv-inventory/${vehicle.id}`}>
                      <InfoIcon
                        style={{ color: '#2196f3', fontSize: '22px' }}
                      />
                    </Link>
                  </IconButton>
                </Tooltip>
              </TableCell>
              <TableCell>
                {vehicle.stockNumber}
                <Typography className={classes.multiLine}>
                  {vehicle.stockType} /{' '}
                  {titleCase(vehicle?.stockStatus?.name) ?? ''}
                </Typography>
                <Typography>
                  <InventoryIconGroup vehicle={vehicle} />
                </Typography>
              </TableCell>
              <TableCell>
                {vehicle.displayName}
                <Typography className={classes.multiLine}>
                  {`Manufacturer: ${
                    vehicle?.model?.make?.manufacturer?.name ?? ''
                  }`}
                </Typography>
                <Typography className={classes.multiLine}>
                  {`Type: ${vehicle?.model?.npvType?.name ?? ''}`}
                </Typography>
                <Typography className={classes.multiLine}>
                  {`Model: ${vehicle?.model?.name ?? ''}`}
                </Typography>
              </TableCell>
              <TableCell>{formatPrice(vehicle.cost)}</TableCell>
              <TableCell>{formatPrice(vehicle.regularPrice)}</TableCell>
              <TableCell>
                {vehicle.isOnSpecial ? formatPrice(vehicle.specialPrice) : '-'}
              </TableCell>
              <TableCell>{vehicle.daysInStock}</TableCell>
              <TableCell>{vehicle.photoCount}</TableCell>
              <TableCell>
                {getFullDateTime(
                  withSuffix(vehicle.modifiedAt, 'Z'),
                  me?.goUserProfile?.settings?.timezone,
                )}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default InventoryTableListView;
