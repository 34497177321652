import { useLazyQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { produce } from 'immer';
import { useSnackbar } from 'notistack';
import React from 'react';

import { makeStyles } from '@mui/styles';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import MobileScreenShareIcon from '@mui/icons-material/MobileScreenShare';

import { getFullDateTime } from 'utils';

const ATTACHMENT_DOWNLOAD_URL = gql`
  query download($attachment_id: ID!) {
    rotracker {
      getPresignedDownloadURL(attachment_id: $attachment_id) {
        url
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  clickable: {
    cursor: 'pointer',
  },
  file_link: {
    color: 'blue',
    textDecoration: 'underline',
  },
}));

const ROAttachmentRow = ({
  attachment,
  sendAttachment,
  removeAttachment,
  updateQuery,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();
  const [fetchURL, { data }] = useLazyQuery(ATTACHMENT_DOWNLOAD_URL, {
    variables: {
      attachment_id: attachment.id,
    },
    onCompleted: data =>
      data?.rotracker?.getPresignedDownloadURL?.url !== null
        ? window.open(data.rotracker.getPresignedDownloadURL.url, '_self')
        : enqueueSnackbar('Failed to Download Attachment', {
            variant: 'error',
          }),
  });

  const getURL = () =>
    data
      ? window.open(data.rotracker.getPresignedDownloadURL.url, '_self')
      : fetchURL();

  return (
    <TableRow>
      <TableCell
        onClick={getURL}
        className={`${classes.file_link} ${classes.clickable}`}
      >
        {attachment?.file_name}
      </TableCell>
      <TableCell>{getFullDateTime(attachment.created_at)}</TableCell>
      <TableCell>
        <Tooltip title="Send attachment to customer SMS">
          <MobileScreenShareIcon
            style={{ color: 'blue' }}
            className={classes.clickable}
            onClick={() =>
              sendAttachment({
                variables: {
                  attachment_id: attachment.id,
                },
                errorPolicy: 'all',
              }).then(({ errors }) => {
                !errors
                  ? enqueueSnackbar('Message sent successfully', {
                      variant: 'success',
                    })
                  : enqueueSnackbar(
                      'Message failed to send. Is the customer on the Do Not Contact List?',
                      { variant: 'warning' },
                    );
              })
            }
          />
        </Tooltip>
        <Tooltip title="Delete attachment">
          <DeleteIcon
            style={{ color: 'red' }}
            className={classes.clickable}
            onClick={() =>
              removeAttachment({
                variables: {
                  attachment_id: attachment.id,
                },
              }).then(({ data: result }) => {
                updateQuery(prev =>
                  produce(prev, data => {
                    data.rotracker.ro.attachments =
                      result.rotracker.removeAttachment.attachments;
                  }),
                );
              })
            }
          />
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

export default ROAttachmentRow;
