import React from 'react';

import Button from '@mui/material/Button';
import { useForm } from 'react-hook-form';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';

/* Internal */
import TextFieldControl from 'components/MaterialUI/TextFieldControl';

const ServiceROForm = ({ isOpen, onClose, onSubmit, isLoading }) => {
  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm({
    defaultValues: { ro_number: '' },
  });
  const handleCancel = () => {
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={handleCancel}>
      <DialogTitle>Create Temporary Service RO</DialogTitle>
      {isLoading && (
        <>
          <Backdrop open={true}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </>
      )}
      {!isLoading && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextFieldControl
                  control={control}
                  label="RO Number"
                  name="ro_number"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button disabled={!isDirty} type="submit" color="primary">
              Submit
            </Button>
          </DialogActions>
        </form>
      )}
    </Dialog>
  );
};

export default ServiceROForm;
