import React, { useEffect, useState } from 'react';
import gql from 'graphql-tag';

import { NetworkStatus } from 'apollo-boost';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useDealerContext } from 'components/MaterialUI/DealerContext';
import CBBWizardCard from './CBBWizardCard';
import CBBWizardProgress from './CBBWizardProgress';
import DealerPicker from 'components/MaterialUI/DealerPicker';
import Loading from 'components/MaterialUI/Loading';

const UNBOOKED_VEHICLES = gql`
  query unbookedVehicles(
    $page: Int!
    $filters: [QueryFilter]
    $facetFilters: [QueryFilter]
  ) {
    inventory {
      vehiclesQuery: getVehicles(
        filters: $filters
        page: $page
        page_size: 10
      ) {
        results {
          id
          ...CBBWizardCardVehicle
        }
        pagination {
          page_size
          next_page
          total
        }
      }
      facets: getVehicleFacets(
        filters: $facetFilters
        facets: [{ model: "Vehicle", field: "is_booked" }]
      ) {
        data {
          count
          value
        }
        field
        model
      }
    }
  }
  ${CBBWizardCard.fragments.vehicle}
`;

const UPDATE_VEHICLE = gql`
  mutation updateVehicle($id: Int!, $data: GreaseInventoryVehicleInput!) {
    inventory {
      updateVehicle(id: $id, data: $data) {
        id
        is_booked
        make_name
        model_name
        stock_number
        trim
        year
        ...CBBWizardCardVehicle
      }
    }
  }
  ${CBBWizardCard.fragments.vehicle}
`;

const CBBWizard = () => {
  const { dealerId } = useDealerContext();
  const { enqueueSnackbar } = useSnackbar();
  const [vehicleIndex, setVehicleIndex] = useState(0);
  const mobile = useMediaQuery(theme => theme.breakpoints.up('sm'));

  const facetFilters = [
    {
      model: 'Vehicle',
      field: 'dealer_id',
      op: '==',
      value: dealerId,
    },
    {
      model: 'Vehicle',
      field: 'stock_type',
      op: '==',
      value: 'USED',
    },
    {
      model: 'StockStatus',
      field: 'name',
      value: 'In Stock',
    },
  ];

  const filters = [
    {
      model: 'Vehicle',
      field: 'is_booked',
      op: '==',
      value: false,
    },
    ...facetFilters,
  ];

  const {
    data: unbookedVehicles,
    loading: loadingVehicles,
    fetchMore: fetchMoreVehicles,
    networkStatus: vehiclesNetworkStatus,
    refetch,
  } = useQuery(UNBOOKED_VEHICLES, {
    variables: { dealerId, filters, facetFilters, page: 1 },
    notifyOnNetworkStatusChange: true,
  });

  const [updateVehicle] = useMutation(UPDATE_VEHICLE, {
    onCompleted: ({
      inventory: {
        updateVehicle: {
          year,
          make_name,
          model_name,
          trim,
          stock_number,
          is_booked,
        },
      },
    }) =>
      is_booked
        ? refetch().then(_ =>
            enqueueSnackbar(
              `Booked out ${stock_number} ${year} ${make_name} ${model_name} ${trim} successfully!`,
            ),
          )
        : enqueueSnackbar(
            `Saved vehicle ${stock_number} ${year} ${make_name} ${model_name} ${trim}, but IT IS NOT BOOKED!`,
          ),
  });

  const fetchingMore = vehiclesNetworkStatus === NetworkStatus.fetchMore;

  const { vehiclesQuery, facets } = unbookedVehicles?.inventory || {};
  const isBookedFacetData =
    facets?.find(x => x.field === 'is_booked').data || [];
  const isBookedTrueCount =
    isBookedFacetData.find(x => x.value === 'True')?.count || 0;
  const isBookedFalseCount =
    isBookedFacetData.find(x => x.value === 'False')?.count || 0;
  const unbookedVehiclesResults = vehiclesQuery?.results || [];
  const unbookedVehiclesPagination = vehiclesQuery?.pagination || {};
  const moveBackward = () => setVehicleIndex(prev => (prev > 0 ? prev - 1 : 0));
  const moveForward = () =>
    setVehicleIndex(prev =>
      prev >= unbookedVehiclesResults.length
        ? unbookedVehiclesResults.length - 1
        : prev + 1,
    );

  useEffect(() => {
    // ensure we haven't gone past the end of the list
    if (
      unbookedVehiclesResults.length > 0 &&
      vehicleIndex >= unbookedVehiclesResults.length - 1 &&
      !fetchingMore
    ) {
      // more vehicles to get? just get more
      const { next_page } = unbookedVehiclesPagination;
      if (next_page)
        fetchMoreVehicles({
          variables: { page: next_page },
          updateQuery: (prev, { fetchMoreResult: more }) => {
            if (!more.inventory.vehiclesQuery.results) return prev;
            return Object.assign({}, prev, {
              inventory: {
                __typename: prev.inventory.__typename,
                vehiclesQuery: {
                  __typename: prev.inventory.vehiclesQuery.__typename,
                  results: [
                    ...prev.inventory.vehiclesQuery.results,
                    ...more.inventory.vehiclesQuery.results,
                  ],
                  pagination: more.inventory.vehiclesQuery.pagination,
                },
                facets: more.inventory.facets,
              },
            });
          },
        });
      // No more vehicles to get, but we've moved past the end of the list,
      // so set the index to the last vehicle
      else if (vehicleIndex >= unbookedVehiclesResults.length)
        setVehicleIndex(unbookedVehiclesResults.length - 1);
    }
  }, [
    fetchMoreVehicles,
    fetchingMore,
    unbookedVehiclesPagination,
    unbookedVehiclesResults.length,
    vehicleIndex,
    vehiclesQuery,
  ]);

  const onSubmit = data =>
    updateVehicle({
      variables: {
        id: unbookedVehiclesResults[vehicleIndex].id,
        data: { cbb: data },
      },
    });

  const loading =
    (loadingVehicles && !fetchingMore) ||
    (vehicleIndex >= unbookedVehiclesResults.length &&
      unbookedVehiclesResults.length > 0);

  return (
    <>
      <div style={{ padding: '1rem 0 0 1rem' }}>
        <DealerPicker />
      </div>
      <Typography style={{ padding: '1rem' }} variant="h6">
        Book Out Wizard
      </Typography>
      {!loading && (
        <CBBWizardProgress
          bookedOutCount={isBookedTrueCount}
          total={isBookedFalseCount + isBookedTrueCount}
          index={vehicleIndex}
          mobile={mobile}
        />
      )}
      <div style={{ padding: '1rem' }}>
        {loading ? (
          <Paper
            style={{ width: `${mobile ? '80%' : '100%'}`, padding: '1.5rem' }}
          >
            <Loading />
          </Paper>
        ) : (
          <Paper
            style={{ width: `${mobile ? '80%' : '100%'}`, padding: '1.5rem' }}
          >
            {unbookedVehiclesResults.length === 0 ? (
              <>All booked out!</>
            ) : (
              <CBBWizardCard
                vehicle={unbookedVehiclesResults[vehicleIndex]}
                onSubmit={onSubmit}
                mobile={mobile}
              />
            )}
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
              }}
            >
              <Button
                disabled={unbookedVehiclesResults.length === 0}
                variant="contained"
                onClick={moveBackward}
                style={{ width: '90px' }}
                color="default"
              >
                {' '}
                <ArrowBackIosIcon /> Back
              </Button>
              <Button
                disabled={vehicleIndex >= unbookedVehiclesResults.length - 1}
                variant="contained"
                onClick={moveForward}
                style={{ width: '90px' }}
                color="default"
              >
                SKIP{' '}
                {fetchingMore ? (
                  <CircularProgress
                    size={20}
                    style={{ marginLeft: '4px', width: '20px' }}
                  />
                ) : (
                  <ArrowForwardIosIcon />
                )}
              </Button>
            </div>
          </Paper>
        )}
      </div>
    </>
  );
};

export default CBBWizard;
