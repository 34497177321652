import React from 'react';

/* external */
import moment from 'moment-timezone';

/* Material UI */
import { Box } from '@mui/material';

/* internal */
import { useUserContext } from 'components/MaterialUI/UserContext';
import { defaultTZ } from 'utils';

/* Yet another date formatter! This one looks for a usercontext to try to get
  the timezone.  Failing that, it uses the browser-detected timezone.
  You can use a string or a moment as the input object
  */
const FormatDateTZ = ({ date, format = 'll', nullDisplay, ...rest }) => {
  const { currentUser } = useUserContext();
  const timezone = currentUser?.goUserProfile?.settings?.timezone || defaultTZ;

  const _moment = date ? moment(date + 'Z') : null;

  return (
    <Box component="span" {...rest}>
      {_moment?.isValid() ? _moment.tz(timezone).format(format) : nullDisplay}
    </Box>
  );
};

export default FormatDateTZ;
