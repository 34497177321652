import React from 'react';

import { makeStyles } from '@mui/styles';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Typography from '@mui/material/Typography';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';

import { getMediumDateTimeWithWeekday, withSuffix } from 'utils';
import { useUserContext } from 'components/MaterialUI/UserContext';

const useStyles = makeStyles(theme => ({
  font: {
    padding: '0.5rem 0 0.5rem 0',
  },
}));

const SyndicationMobile = ({ imports, exports }) => {
  const { font } = useStyles();
  const { me } = useUserContext();

  return (
    <>
      <div style={{ paddingBottom: '1rem' }}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Imports</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ paddingTop: 0 }}>
            <Grid container xs={12} direction="column">
              <Typography style={{ paddingBottom: '1rem' }} variant="p">
                Your vehicles are being imported from the following sources:
              </Typography>
              {imports.map((row, index) => (
                <Grid item xs={12}>
                  <div className={font}>
                    <b>Importer:</b> {row.name}
                  </div>
                  <div className={font}>
                    <span
                      style={{
                        display: 'flex',
                        marginTop: 'auto',
                        marginBottom: 'auto',
                      }}
                    >
                      <b>New:</b>{' '}
                      {row.new ? (
                        <CheckBoxIcon />
                      ) : (
                        <CheckBoxOutlineBlankIcon />
                      )}
                      <b style={{ paddingLeft: '1rem' }}>Used:</b>{' '}
                      {row.used ? (
                        <CheckBoxIcon />
                      ) : (
                        <CheckBoxOutlineBlankIcon />
                      )}
                    </span>
                  </div>
                  <div className={font}>
                    <b>Frequency:</b> {row.frequency}
                  </div>
                  <div className={font}>
                    <b>Updated: </b>
                    {row.updated_count}
                  </div>
                  <div className={font}>
                    <b>Import Last Ran At:</b>{' '}
                    {getMediumDateTimeWithWeekday(
                      withSuffix(row.date_ended_at, 'Z'),
                      me?.goUserProfile?.settings?.timezone,
                    )}
                  </div>
                  {imports.length - 1 !== index && <hr />}
                </Grid>
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </div>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Exports</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ paddingTop: 0 }}>
          <Grid container xs={12} direction="column">
            <Typography style={{ paddingBottom: '1rem' }} variant="p">
              Your vehicles are being exported to the following destinations:
            </Typography>
            {exports.map((row, index) => (
              <Grid item key={row.snakeName} xs={12}>
                <div className={font}>
                  <b>Exporter:</b> {row.name}
                </div>
                <div className={font}>
                  <span
                    style={{
                      display: 'flex',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                    }}
                  >
                    <b>New:</b>{' '}
                    {row.new ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                    <b style={{ paddingLeft: '1rem' }}>Used:</b>{' '}
                    {row.used ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                  </span>
                </div>
                <div className={font}>
                  <b>Frequency:</b> {row.frequency}
                </div>
                <div className={font}>
                  <b>Export Last Ran At:</b>{' '}
                  {getMediumDateTimeWithWeekday(
                    withSuffix(row.date_ended_at, 'Z'),
                    me?.goUserProfile?.settings?.timezone,
                  )}
                </div>
                {exports.length - 1 !== index && <hr />}
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default SyndicationMobile;
