/* external */
import { useQuery } from '@apollo/react-hooks';
import { NetworkStatus } from 'apollo-boost';
import gql from 'graphql-tag';
import React, { useState } from 'react';

/* Material UI */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Alert from '@mui/material/Alert';

/* internal */
import Loading from 'components/MaterialUI/Loading';
import { useUserContext } from 'components/MaterialUI/UserContext';

import AmaMemberModal from './GoCard/AmaMemberModal';
import AttachGoCardModal from './GoCard/AttachGoCardModal';
import DetachGoCardModal from './GoCard/DetachGoCardModal';
import MergeCardsModal from './GoCard/MergeCardsModal';
import GoCardOverview from './GoCard/GoCardOverview';
import GoCardTable from './GoCard/GoCardTable';
import RedeemPointsModal from './GoCard/RedeemPointsModal';
import RevokeSharedGoCardModal from './GoCard/RevokeSharedGoCardModal';
import { RoleGroup } from 'constants.js';
import RvMemberModal from './GoCard/RvMemberModal';
import SharedGoCards from './GoCard/SharedGoCards';
import ShareGoCardModal from './GoCard/ShareGoCardModal';
import TransactionsModal from './GoCard/TransactionsModal';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    marginRight: '5px',
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 'bold',
    paddingTop: 25,
  },
}));

const GET_LOCATIONS = gql`
  query locations($filters: [QueryFilter]) {
    gocard {
      locations(filters: $filters, page: 1, pageSize: 100) {
        results {
          locationId
          locationName
          dealerId
        }
      }
    }
  }
`;

const GET_SHARED_GO_CARDS_QUERY = gql`
  query customerSharedGoCards($email: String!) {
    customerSharedGoCards(email: $email) {
      crm_id
      ...SharedGoCardsSharedGoCard
      account {
        cash_balance
        card_number
        first_name
        last_name
      }
    }
  }
  ${SharedGoCards.fragments.sharedGoCards}
`;

const GO_CARDS_QUERY = gql`
  query gocard(
    $filters: [QueryFilter]
    $page: Int!
    $sort: [QuerySortElement]
    $checkROs: Boolean!
  ) {
    gocard {
      accounts(filters: $filters, page: $page, pageSize: 10, sort: $sort) {
        results {
          cardId
          ...AmaMemberModalGoCard
          ...AttachGoCardModalGoCard
          ...DetachGoCardModalGoCard
          ...GoCardOverviewGoCard
          ...MergeCardsModalGoCard
          ...RedeemPointsModalGoCard
          ...RevokeSharedGoCardModalGoCard
          ...RvMemberModalGoCard
          ...SharedGoCardsGoCard
          ...ShareGoCardModalGoCard
          ...TransactionsModalGoCard
          openROs @include(if: $checkROs) {
            ...MergeCardsModalRo
          }
          transactions {
            ...GoCardTableGoCard
            amount
            code
            description
            transactionDate
            location {
              locationId
              locationName
              dealerId
            }
          }
          permissions {
            ...GoCardOverviewGoCardPermissions
          }
        }
        pagination {
          total
          page
        }
      }
    }
  }
  ${AmaMemberModal.fragments.gocard}
  ${AttachGoCardModal.fragments.gocard}
  ${DetachGoCardModal.fragments.gocard}
  ${GoCardOverview.fragments.gocard}
  ${GoCardOverview.fragments.gocardPermissions}
  ${GoCardTable.fragments.gocard}
  ${MergeCardsModal.fragments.gocard}
  ${MergeCardsModal.fragments.ro}
  ${RedeemPointsModal.fragments.gocard}
  ${RevokeSharedGoCardModal.fragments.gocard}
  ${RvMemberModal.fragments.gocard}
  ${SharedGoCards.fragments.gocard}
  ${ShareGoCardModal.fragments.gocard}
  ${TransactionsModal.fragments.gocard}
`;

const GoCard = ({ customer }) => {
  const desktop = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const classes = useStyles();
  const theme = useTheme();
  const { currentUser } = useUserContext();
  const dealers = currentUser?.dealers || [];
  const [selected, setSelected] = useState(0);
  const [attachModal, setAttachModal] = useState(false);
  const [hideInactive, setHideInactive] = useState(true);
  const checkROs = RoleGroup.ATTACH_GOCARD_ROLE.includes(currentUser.role);
  const isGoAutoEmployee = customer?.goauto_employee;
  const isWholesaler = customer?.gocards?.some(
    gocard => gocard?.accountV2?.isWholesaler,
  );

  const cardIds = customer.records
    .filter(record => record.includes('gocard'))
    .map(record => record.split(':').slice(-1)[0]);

  const { data: locationData, loading: locationLoading } = useQuery(
    GET_LOCATIONS,
    {
      variables: {
        filters: [
          {
            model: 'Location',
            field: 'dealerId',
            op: 'in',
            value: dealers.map(({ dealer_id }) => dealer_id),
          },
        ],
      },
      errorPolicy: 'all',
    },
  );

  const email = customer?.identities.find(
    ({ identity_type }) => identity_type === 'email',
  )?.identity_value;

  const {
    data: sharedGoCardData,
    loading: sharedGoCardLoading,
    refetch: sharedGoCardRefetch,
  } = useQuery(GET_SHARED_GO_CARDS_QUERY, {
    variables: {
      email,
    },
    skip: !email,
    errorPolicy: 'all',
  });

  const { data, loading, refetch, networkStatus } = useQuery(GO_CARDS_QUERY, {
    variables: {
      filters: [
        {
          model: 'GoCard',
          field: 'cardId',
          op: 'in',
          value: cardIds,
        },
      ],
      page: 1,
      checkROs,
    },
    notifyOnNetworkStatusChange: true,
  });

  const goCards = data?.gocard?.accounts?.results || [];
  const sharedGoCards = sharedGoCardData?.customerSharedGoCards || [];

  if (
    loading ||
    locationLoading ||
    sharedGoCardLoading ||
    networkStatus === NetworkStatus.refetch
  )
    return <Loading />;

  const locations = locationData?.gocard?.locations?.results || [];

  const handleChange = index => {
    if (index === selected) {
      setSelected(-1);
    } else {
      setSelected(index);
    }
  };

  const handleHideInactive = () => {
    setHideInactive(hideInactive => !hideInactive);
  };

  const inactiveCardCount = goCards.filter(c => c.active === false).length;

  const GoCardHeader = () => (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={2}
    >
      <Grid container item xs={6} alignItems="center">
        <Grid item xs={8} sm={4} md={3}>
          <Typography className={classes.title} gutterBottom>
            Go Card
          </Typography>
        </Grid>
        <Grid item xs={4} sm={2}>
          <FormControlLabel
            onClick={!desktop ? event => event.stopPropagation() : ''}
            onFocus={!desktop ? event => event.stopPropagation() : ''}
            control={
              <IconButton size="small" onClick={() => setAttachModal(true)}>
                <AddIcon style={theme.actions.add} />
              </IconButton>
            }
          />
        </Grid>
      </Grid>
      <Grid
        item
        xs={6}
        style={{
          textAlign: desktop ? 'right' : 'left',
          paddingRight: '20px',
        }}
      >
        <FormControlLabel
          onClick={event => event.stopPropagation()}
          onFocus={event => event.stopPropagation()}
          control={
            <Switch
              checked={hideInactive}
              onChange={handleHideInactive}
              size="small"
              color="secondary"
            />
          }
          label={
            hideInactive ? 'Inactive Cards Hidden' : 'Inactive Cards Visible'
          }
          labelPlacement={'start'}
        />
      </Grid>
      {isGoAutoEmployee && (
        <Grid item xs={12}>
          <Alert severity="warning">
            Customer is a Go Auto Employee and cannot be gifted or redeem Go
            Card points
          </Alert>
        </Grid>
      )}
      {isWholesaler && (
        <Grid item xs={12}>
          <Alert severity="warning">
            Customer is a Wholesaler and cannot be gifted or redeem Go Card
            points
          </Alert>
        </Grid>
      )}
    </Grid>
  );

  const GoCardContent = () => {
    return (
      <>
        {goCards.map((gocard, index) => (
          <>
            {(gocard.active || !hideInactive) && (
              <GoCardTable
                key={gocard.cardId}
                gocard={gocard}
                selected={selected === index}
                handleChange={() => handleChange(index)}
                locations={locations}
                refetch={refetch}
                gocards={goCards}
                customerId={customer._id}
                records={customer?.records || []}
                isGoAutoEmployee={isGoAutoEmployee}
              />
            )}
          </>
        ))}
        {inactiveCardCount === goCards.length &&
          goCards.length > 0 &&
          hideInactive && (
            <Typography variant="body2" style={{ marginTop: '5px' }}>
              There are no active Go Cards for this customer.
            </Typography>
          )}
        {goCards.length === 0 && (
          <Typography variant="body2">
            There are no Go Cards for this customer.
          </Typography>
        )}
      </>
    );
  };

  const SharedGoCardContent = () => {
    return (
      <>
        <Typography className={classes.subtitle} gutterBottom>
          Shared Cards
        </Typography>
        {sharedGoCards.map(sharedGoCard => (
          <SharedGoCards
            key={sharedGoCard?.gocard_id}
            sharedGoCardDetails={sharedGoCard}
            sharedGoCardRefetch={sharedGoCardRefetch}
          />
        ))}
        {sharedGoCards.length === 0 && (
          <Typography variant="body2">
            There are no Shared Go Cards for this customer.
          </Typography>
        )}
      </>
    );
  };

  return (
    <>
      <AttachGoCardModal
        customer={customer}
        isOpen={attachModal}
        onClose={() => setAttachModal(false)}
        refetch={refetch}
      />
      {desktop ? (
        <Card className={classes.root}>
          <CardContent>
            <GoCardHeader />
            <GoCardContent />
            <SharedGoCardContent />
          </CardContent>
        </Card>
      ) : (
        <Accordion defaultExpanded={true}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <GoCardHeader />
          </AccordionSummary>
          <GoCardContent />
          <AccordionDetails>
            <Grid item>
              <SharedGoCardContent />
            </Grid>
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
};

GoCard.fragments = {
  customer: gql`
    fragment GoCardFragment on Customer {
      emails {
        email
      }
      records
    }
  `,
};

export default GoCard;
