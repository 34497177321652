import React from 'react';

import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';

import { useSnackbar } from 'notistack';

const DealLogSubStatusModal = ({
  showSubStatus,
  setShowSubStatus,
  updateStatus,
  opportunity,
  setSubStatus,
  subStatus,
  isSalesRole,
  isServiceRole,
  handleClose,
  subStatusDropDown,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleSubStatusChange = event => {
    updateStatus({
      variables: {
        id: opportunity._id,
        statuses: {
          status: opportunity.status,
          sub_status: event.target.value,
        },
      },
    }).then(r => {
      setSubStatus(event.target.value);
      enqueueSnackbar('Updated Sub Status!');
    });
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      scroll="paper"
      open={showSubStatus}
      onClose={() => {
        setShowSubStatus(false);
      }}
    >
      <DialogTitle>
        <h2>Update Sub Status</h2>
      </DialogTitle>
      <DialogContent>
        <FormControl
          style={{ width: '100%' }}
          disabled={isSalesRole || isServiceRole}
        >
          <Select value={subStatus} onChange={handleSubStatusChange}>
            {subStatusDropDown.map(value => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DealLogSubStatusModal;
