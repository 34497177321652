import React, { useState } from 'react';
import gql from 'graphql-tag';
import moment from 'moment';
import { useQuery } from '@apollo/react-hooks';
import { cloneDeep, isEmpty } from 'lodash';

import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material';
import Card from '@mui/material/Card';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import FilterListIcon from '@mui/icons-material/FilterList';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';

import CommunicationCard from './CommunicationCard';
import CommunicationCardMobile from './CommunicationCardMobile';
import Loading from 'components/MaterialUI/Loading';

const useStyles = makeStyles(theme => ({
  filterButton: {
    padding: '0px',
    margin: '0px 5px 0px 10px',
  },
  paper: {
    padding: '10px',
    minHeight: '300px',
  },
  table: {
    padding: '10px',
  },
  mobile: {
    overflowY: 'auto',
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  card: {
    marginBottom: '15px',
    marginTop: '15px',
  },
}));

const COMM_HISTORY_QUERY = gql`
  query CommunicationHistory($_id: ID!) {
    customerConversationHistory(_id: $_id) {
      conversation_sid
      date_created
      dealer_id
      channel_type
      channel_number
      messages {
        to
        media
      }
      ...CommunicationCardConversationHistory
      ...CommunicationCardMobileConversationHistory
    }
  }
  ${CommunicationCard.fragments.conversationHistory}
  ${CommunicationCardMobile.fragments.conversationHistory}
`;

const CommunicationHistory = ({ customerId }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [filters, setFilters] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const { data, loading } = useQuery(COMM_HISTORY_QUERY, {
    variables: {
      _id: customerId,
    },
  });

  const handleFilters = event => {
    let updateFilters = cloneDeep(filters);
    const index = updateFilters.indexOf(event.target.name);
    if (index === -1) {
      updateFilters = [...updateFilters, event.target.name];
    } else {
      updateFilters.splice(index, 1);
    }
    setFilters([...updateFilters]);
  };

  const handleChipDelete = chipName => {
    const updateFilters = cloneDeep(filters);
    const index = updateFilters.indexOf(chipName);
    updateFilters.splice(index, 1);
    setFilters([...updateFilters]);
  };

  const handleFilterClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  if (loading) {
    return <Loading />;
  }

  const flexConversations = data?.customerConversationHistory || [];

  const results = flexConversations
    .filter(result =>
      !isEmpty(filters) ? filters.includes(result.channel_type) : true,
    )
    .sort((a, b) => {
      // sort by latest date
      return (
        new moment(
          b.messages.length > 0
            ? b.messages.slice().reverse()[0].date_created
            : b.date_created,
        ) -
        new moment(
          a.messages.length > 0
            ? a.messages.slice().reverse()[0].date_created
            : a.date_created,
        )
      );
    });

  return (
    <>
      <Paper className={classes.paper}>
        <span style={{ display: 'flex', padding: '10px' }}>
          <Typography className={classes.title} gutterBottom>
            Communication History
          </Typography>
          <IconButton
            className={classes.filterButton}
            color="primary"
            onClick={handleFilterClick}
            size="large"
          >
            <FilterListIcon style={theme.actions.filter} />
          </IconButton>
          <Menu
            id="filter-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleFilterClose}
          >
            <MenuItem>
              <FormControlLabel
                label="Facebook"
                control={
                  <Checkbox
                    name="facebook"
                    onChange={handleFilters}
                    checked={filters.includes('facebook')}
                    color="primary"
                  />
                }
              />
            </MenuItem>
            <MenuItem>
              <FormControlLabel
                label="SMS"
                control={
                  <Checkbox
                    name="sms"
                    onChange={handleFilters}
                    checked={filters.includes('sms')}
                    color="primary"
                  />
                }
              />
            </MenuItem>
          </Menu>
        </span>
        <span style={{ display: 'flex', paddingLeft: '10px' }}>
          {filters.map((filter, index) => (
            <Chip
              key={String(filter) + String(index)}
              name={filter}
              label={filter === 'sms' ? 'SMS' : 'Facebook'}
              onDelete={() => handleChipDelete(filter)}
              style={{ marginRight: '5px' }}
            />
          ))}
          {results.length === 0 && 'No communication history for this customer'}
        </span>
        <Hidden lgDown>
          {results.map((comm, index) => (
            <Card
              className={classes.card}
              key={`${comm.date_created}:${index}`}
            >
              <CommunicationCard comm={comm} />
            </Card>
          ))}
        </Hidden>
        <Hidden lgUp>
          {results.map((comm, index) => (
            <Card
              className={classes.card}
              key={`${comm.date_created}:${index}`}
            >
              <CommunicationCardMobile comm={comm} />
            </Card>
          ))}
        </Hidden>
      </Paper>
    </>
  );
};

export default CommunicationHistory;
