import {
  INITIALIZE,
  SET_TOKEN,
  SET_SENDING_REQUEST,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT,
  SET_WINDOW_WIDTH,
  SET_APP_ERROR,
  CLEAR_APP_ERROR,
  SET_NOTIFICATION,
  CLEAR_NOTIFICATION,
  CREATE_NOTIFICATION,
} from './actionTypes';

export const initialize = token => ({
  type: INITIALIZE,
  token,
});

/**
 * Auth
 */
export const setToken = token => ({
  type: SET_TOKEN,
  token,
});

export const setSendingRequest = sendingRequest => ({
  type: SET_SENDING_REQUEST,
  sendingRequest,
});

export const loginRequest = (username, password) => ({
  type: LOGIN_REQUEST,
  username,
  password,
});

export const loginSuccess = () => ({
  type: LOGIN_SUCCESS,
});

export const logout = () => ({
  type: LOGOUT,
});

/**
 * UI
 */
export const setWindowWidth = width => ({
  type: SET_WINDOW_WIDTH,
  windowWidth: width,
});

export const setAppError = (sourceId, error) => ({
  type: SET_APP_ERROR,
  appError: {
    sourceId,
    error,
  },
});

export const clearAppError = sourceId => ({
  type: CLEAR_APP_ERROR,
  sourceId,
});

/**
 * Notification
 */
export const setNotification = notification => ({
  type: SET_NOTIFICATION,
  notification,
});

export const clearNotification = id => ({
  type: CLEAR_NOTIFICATION,
  id,
});

export const createNotification = (
  id,
  source,
  message,
  type,
  isDismissable,
  timeout,
) => ({
  type: CREATE_NOTIFICATION,
  timeout,
  notification: {
    id,
    source,
    message,
    type,
    isDismissable,
  },
});
