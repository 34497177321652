import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import app from '../../app';
import NotesFeed from './NotesFeed';

const MyNotes = () => {
  const { data } = useQuery(gql`
    query MyNotes {
      currentUser {
        dealers {
          dealer_id
          dealer_name
        }
        username
      }
    }
  `);
  if (!data) return <app.components.Layout title="Loading" />;
  return (
    <app.components.Layout title="My Notes">
      <NotesFeed users={[data.currentUser.username]} />
    </app.components.Layout>
  );
};

export default MyNotes;
