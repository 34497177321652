import React from 'react';

/* Material UI */
import { makeStyles } from '@mui/styles';
import { Box, List, ListItem, ListItemText, Popover } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';

const useStyles = makeStyles(theme => ({
  iconSpacer: {
    paddingRight: '10px',
    width: '25px',
  },
  icon: {
    paddingRight: '10px',
    width: '25px',
    fontSize: 'small',
  },
}));

// Works with useSorting hook (just pass in the sortPopoverProps)
const SortPopover = ({
  sortOptions,
  onClose,
  isSelected,
  onClickOption,
  ...rest
}) => {
  const classes = useStyles();

  const clickOptionHandler = sortOption => () => {
    onClickOption(sortOption);
    onClose();
  };

  return (
    <Popover onClose={onClose} {...rest}>
      <List>
        {sortOptions.map(({ sortOption, label }, index) => (
          <ListItem
            button
            dense
            onClick={clickOptionHandler(sortOption)}
            selected={isSelected(sortOption)}
            key={index}
          >
            {isSelected(sortOption) ? (
              <DoneIcon className={classes.icon} />
            ) : (
              <Box className={classes.iconSpacer} />
            )}
            <ListItemText primary={label} />
          </ListItem>
        ))}
      </List>
    </Popover>
  );
};

export default SortPopover;
