import gql from 'graphql-tag';
import React, { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { ReactSortable } from 'react-sortablejs';

import {
  Badge,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
  useMediaQuery,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import PublishIcon from '@mui/icons-material/Publish';

import { cloudinaryCore } from 'utils';

import { cloudinaryConfig } from '../../../../constants';
import noIMG from '../../static/img/no-image.jpg';
import { usePermissionsContext } from '../contexts/PermissionsContext';

const VehiclePhotos = ({
  vehicle: { displayPhoto, photos, photoUploadParams },
  overlays,
}) => {
  const mobile = useMediaQuery('(max-width:575px)');
  const { control, register, setValue } = useFormContext();
  const { update: canUpdate } = usePermissionsContext();
  register('photos');
  const photosData = useWatch({
    control,
    name: 'photos',
    defaultValue: photos,
  });

  const hasOverlays = overlays.length > 0;
  const dealerExporterFeeds = overlays.map(overlay =>
    overlay.exporter_name.toLowerCase(),
  );
  const getUserOverlayIdChoice = (exporterOverlay, overlays) => {
    if (!exporterOverlay) return;
    // logic for grabbing multiple matches to be determined later
    return overlays.filter(
      overlay => overlay.exporter_name.toLowerCase() === exporterOverlay,
    )[0]?.cloudinary_overlay_id;
  };

  const [photoList, setPhotoList] = useState(photosData);
  const [largePhoto, setLargePhoto] = useState(displayPhoto);
  const [exporterOverlay, setExporterOverlay] = useState('');

  useEffect(() => {
    const _photoList = photoList.map(
      ({ chosen, selected, __typename, ...rest }) => rest,
    );
    const _photosData = photosData.map(({ __typename, ...rest }) => rest);
    if (JSON.stringify(_photosData) !== JSON.stringify(_photoList)) {
      setValue('photos', _photoList, { shouldDirty: true });
    }
    if (!largePhoto && photoList.length > 0) setLargePhoto(photoList[0]);
  }, [largePhoto, photoList, photosData, setValue]);

  useEffect(() => {
    const _photos = photos.map(({ __typename, ...rest }) => rest);
    setValue('photos', _photos, { shouldDirty: false });
    setPhotoList(_photos);
  }, [photos, setValue]);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmDeleteAll = () => {
    setPhotoList([]);
    setLargePhoto(null);
    setOpen(false);
  };
  const formatPhoto = (photo, options = {}) =>
    photo
      ? cloudinaryCore.url(photo.cloudinary_public_id, {
          width: options.width || 100,
          crop: 'fit',
        })
      : noIMG;

  const handleExportSelectChange = event => {
    setExporterOverlay(event.target.value);
  };

  // using Cloudinary legacy SDKs to position overlays wasn't working, so decided to apply overlays this way (for now)
  const formatPhotoWithOverlay = (photo, overlayId, options = {}) =>
    `https://res.cloudinary.com/${
      cloudinaryConfig.cloud_name
    }/image/upload/l_v1:overlays:${overlayId},w_1.0,fl_relative,c_fill/c_fit,w_${
      options.width ?? '100'
    }/v1/${photo.cloudinary_public_id}`;

  const uploadWidget = ({
    api_key,
    folder,
    signature,
    timestamp,
    upload_preset,
  }) => {
    const newPhotos = [];
    const widget = window.cloudinary.createUploadWidget(
      {
        cloudName: cloudinaryConfig.cloud_name,
        apiKey: api_key,
        uploadSignature: signature,
        uploadSignatureTimestamp: timestamp,
        folder,
        // Note that dealer_id will be populated from the vehicle since the
        // vehicle already exists (ie. we're not creating them here....yet)
        // Also, we are not allowing changing the dealer_id here.  If we change
        // to either create vehicles here, and/or allow changing the dealer_id,
        // we will have to change this.
        uploadPreset: upload_preset,
        maxFileSize: 5 * 1000 * 1000, // 5MB
        clientAllowedFormats: ['png', 'jpg', 'jpeg', 'jfif'],
        maxImageWidth: 1600,
        maxImageHeight: 1200,
      },
      (error, result) => {
        if (error || !result) return;

        if (result.event === 'success') {
          newPhotos.push({
            cloudinary_public_id: result.info.public_id,
            md5: result.info.etag,
            infoId: result?.info?.id,
          });
        } else if (result.event === 'close') {
          // Sort new photos by info id so they retain the original order.
          // localeCompare because the ids will be e.g. 'uw-file1' or 'uw-file10'
          newPhotos.sort((a, b) =>
            a?.infoId.localeCompare(b?.infoId, undefined, {
              numeric: true,
            }),
          );
          setPhotoList(prev => [
            ...prev,
            ...newPhotos.map(({ infoId, ...rest }) => rest),
          ]);
        }
      },
    );
    widget.open();
  };

  const displayLargePhoto = photo => {
    setLargePhoto(photo);
  };

  const deletePhoto = photo =>
    setPhotoList(prev => prev.filter(x => x.md5 !== photo.md5));

  return (
    <Paper style={{ paddingBottom: '2rem' }}>
      <Container>
        <Typography style={{ padding: '1rem 1rem 1rem 0' }} variant="h6">
          <div style={{ paddingBottom: '1rem' }}>Photos</div>
        </Typography>
        <Grid container direction="row" xs={12}>
          <Grid item xs={12} md={6}>
            <div style={{ marginRight: '20px' }}>
              <img
                alt="Vehicle"
                style={{
                  width: '100%',
                  objectFit: 'cover',
                }}
                src={
                  exporterOverlay
                    ? formatPhotoWithOverlay(
                        largePhoto,
                        getUserOverlayIdChoice(exporterOverlay, overlays),
                        { width: 500 },
                      )
                    : formatPhoto(largePhoto, { width: 500 })
                }
              />
            </div>
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            style={mobile ? { order: 4 } : { order: 2 }}
          >
            <div
              style={{
                paddingTop: '5px',
              }}
            >
              <ReactSortable list={photoList} setList={setPhotoList}>
                {photoList.map(photo => (
                  <span
                    style={{
                      marginRight: '10px',
                    }}
                    key={JSON.stringify(photo.id + photo.md5)}
                  >
                    <Badge
                      overlap="rectangular"
                      badgeContent={
                        <IconButton
                          onClick={() => deletePhoto(photo)}
                          size="large"
                        >
                          <CancelIcon style={{ fontSize: '32px' }} />
                        </IconButton>
                      }
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                    >
                      <img
                        alt="Vehicle"
                        style={{
                          objectFit: 'cover',
                          height: '75px',
                          width: '93.75px',
                          marginBottom: '15px',
                        }}
                        onClick={() => displayLargePhoto(photo)}
                        src={formatPhoto(photo)}
                      />
                    </Badge>
                  </span>
                ))}
              </ReactSortable>
            </div>
          </Grid>

          <Grid
            item
            xs={12}
            md={2}
            alignSelf="flex-start"
            style={mobile ? { order: 2 } : { order: 3 }}
          >
            <div
              style={{
                paddingLeft: '15px',
                paddingBottom: '10px',
              }}
            >
              <div>
                <Typography variant="caption" style={{ fontWeight: 'bold' }}>
                  Preview photo overlay for:
                </Typography>
              </div>
              {hasOverlays ? (
                <div alignItems="flex-end">
                  <FormControl>
                    <InputLabel id="export-select-label">
                      Select feed...
                    </InputLabel>
                    <Select
                      labelId="export-select-label"
                      id="export-select"
                      value={exporterOverlay}
                      onChange={handleExportSelectChange}
                      style={{ minWidth: 120 }}
                    >
                      {dealerExporterFeeds.map((dealerExporterFeed, index) => {
                        return (
                          <MenuItem key={index} value={dealerExporterFeed}>
                            {dealerExporterFeed}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  {exporterOverlay && (
                    <Button
                      onClick={() => {
                        setExporterOverlay('');
                      }}
                      style={{
                        color: '#26abff',
                        marginLeft: '-8px',
                        display: mobile ? 'block' : '',
                      }}
                    >
                      Clear Selection
                    </Button>
                  )}
                </div>
              ) : (
                <Typography variant="subtitle2">
                  There are no overlays set for this dealer to preview.
                </Typography>
              )}
            </div>
          </Grid>

          <Grid
            item
            xs={12}
            md={4}
            style={mobile ? { order: 3 } : { order: 4 }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: !mobile ? 'right' : 'left',
                flexDirection: 'row',
                alignItems: !mobile ? 'flex-end' : 'flex-start',
                padding: '1rem',
              }}
            >
              <div style={{ paddingRight: '1rem' }}>
                {canUpdate && (
                  <Button
                    variant="contained"
                    style={{
                      height: `${mobile ? '50px' : '35px'}`,
                      fontSize: `${mobile ? '14px' : '12px'}`,
                      maxWidth: '150px',
                      minWidth: '25%',
                      backgroundColor: '#26abff',
                      color: 'white',
                    }}
                    onClick={() => uploadWidget(photoUploadParams)}
                  >
                    <PublishIcon /> UPLOAD
                  </Button>
                )}
              </div>
              {canUpdate && (
                <Button
                  variant="contained"
                  style={{
                    height: `${mobile ? '50px' : '35px'}`,
                    fontSize: `${mobile ? '14px' : '12px'}`,
                    maxWidth: '150px',
                    minWidth: '25%',
                    backgroundColor: '#d0312d',
                    color: 'white',
                  }}
                  onClick={handleClickOpen}
                >
                  <DeleteIcon /> DELETE ALL
                </Button>
              )}
              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {'WAIT! Are you sure?'}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Pressing confirm will delete all of the photos for this
                    vehicle. Are you sure you want to delete all of the photos
                    for this vehicle?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                    Cancel
                  </Button>
                  <Button
                    onClick={handleConfirmDeleteAll}
                    color="primary"
                    autoFocus
                    variant="outlined"
                  >
                    Confirm
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </Grid>
        </Grid>
      </Container>
    </Paper>
  );
};

VehiclePhotos.fragments = {
  vehicle: gql`
    fragment VehiclePhotosVehicle on GreaseInventoryVehicle {
      photoUploadParams {
        api_key
        folder
        signature
        timestamp
        upload_preset
        url
      }
      displayPhoto {
        cloudinary_public_id
        id
      }
      photos {
        cloudinary_public_id
        id
        md5
      }
    }
  `,
};

export default VehiclePhotos;
