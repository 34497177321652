/* external */
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';

import { IconButton } from '@mui/material';

/* Material UI */
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

/* internal */
import ConfirmDialog from 'components/MaterialUI/ConfirmDialog';

import { formatPhoneNumber, formatPrice } from 'utils';

import CustomerMerge from '../../../customers/components/CustomerMerge/CustomerMerge';
import CustomerContactPrefs from '../CustomerContactPrefs';
import GoCardScanner from '../GoCardScanner';
import { snackBarSettings } from 'constants.js';
import gql from 'graphql-tag';

const RODetailsOverview = ({ isDesktop, ro, updateRO }) => {
  const customer_phone = ro.sms_contact_number || ro.customer_phone;
  const customer_email = ro.customer_email || ro.customer?.primary_email;
  const [goCard, setGoCard] = useState(ro.gocard);
  const [showMergeDialog, setShowMergeDialog] = useState(false);
  const [mergeCustomers, setMergeCustomers] = useState(false);
  const [mergeInProgress, setMergeInProgress] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const cashBalance = goCard ? goCard.cashBalance : 0;
  const cardNumber = goCard ? goCard.cardNumber : null;
  const { enqueueSnackbar } = useSnackbar();

  const handleConfirm = () => {
    setShowMergeDialog(false);
    setMergeInProgress(true);
  };
  const stopMerge = () => {
    setShowMergeDialog(false);
  };
  return (
    <Paper>
      {!mergeInProgress && (
        <Container maxWidth="xl">
          <Box
            paddingTop={2}
            paddingLeft={2}
            style={{ fontSize: '18px', fontWeight: 'bold' }}
          >
            Vehicle Overview
          </Box>
          <ConfirmDialog
            text={`Are you sure you want to detach Go Card from: ${ro.ro_number}`}
            onConfirm={() =>
              updateRO({
                variables: {
                  dealerId: ro.dealer_id,
                  roNumber: ro.ro_number,
                  update: { gocard_id: null },
                },
              })
                .then(() =>
                  enqueueSnackbar('Go Card removed!', snackBarSettings),
                )
                .then(setGoCard(null))
            }
            isOpen={openConfirm}
            onClose={() => setOpenConfirm(false)}
          />
          <Grid
            container
            direction={`${isDesktop ? 'row' : 'column'}`}
            style={{ padding: '1rem' }}
          >
            <Grid item xs={12} sm={6}>
              <Box paddingBottom={1} paddingTop={1}>
                <b>Vehicle:</b>{' '}
                {`${ro.vehicle_year} ${ro.vehicle_make} ${ro.vehicle_model}`}
              </Box>
              <Box paddingBottom={1} paddingTop={1}>
                <b>VIN:</b> {ro.vin}
              </Box>
              <Box paddingBottom={1} paddingTop={1}>
                <b>Service Advisor:</b>{' '}
                {ro?.serviceAdvisor?.display_name || ' No Service Advisor'}
              </Box>
              <Box paddingBottom={1} paddingTop={1}>
                <b>Go Card:</b>
                {!cardNumber && ro.is_open && (
                  <Box>
                    <GoCardScanner
                      ro={ro}
                      appointment={null}
                      refetch={null}
                      iconOnly={false}
                      updateOverview={setGoCard}
                      startMerge={setShowMergeDialog}
                      mergeCustomers={setMergeCustomers}
                    />
                  </Box>
                )}
                {cardNumber &&
                  `${cardNumber} / ${formatPrice(cashBalance, {
                    cents: true,
                    zeroIfNull: true,
                  })}`}
                {cardNumber && (
                  <IconButton onClick={() => setOpenConfirm(true)} size="large">
                    <HighlightOffIcon />
                  </IconButton>
                )}
              </Box>
              <Box paddingBottom={1} paddingTop={1}>
                <b>Photos:</b> {ro.photo_count}
              </Box>
              <Box paddingBottom={1} paddingTop={1}>
                <b>Videos:</b> {ro.video_count}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box>
                <CustomerContactPrefs
                  dealer={ro.dealer}
                  preferred_name={ro.preferred_name}
                  sms_contact_number={customer_phone}
                  customer_email={customer_email}
                  onSubmit={update => {
                    // Convert to numbers-only (non-pretty) format
                    update.sms_contact_number = formatPhoneNumber(
                      update.sms_contact_number,
                      false,
                    );
                    updateRO({
                      variables: {
                        dealerId: ro.dealer_id,
                        roNumber: ro.ro_number,
                        update,
                      },
                    }).then(r =>
                      enqueueSnackbar(
                        'Contact preferences updated.',
                        snackBarSettings,
                      ),
                    );
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      )}
      <ConfirmDialog
        text="The card you scanned is not currently linked to this profile. Would you like to merge the profiles?"
        onConfirm={handleConfirm}
        onClose={stopMerge}
        isOpen={showMergeDialog}
        confirmButtonProps={{
          variant: 'outlined',
        }}
        abortButtonProps={{
          variant: 'outlined',
        }}
      />
      {mergeInProgress && <CustomerMerge ids={mergeCustomers} />}
    </Paper>
  );
};

RODetailsOverview.fragments = {
  ro: gql`
    fragment RODetailsOverviewRO on ROTrackerRO {
      sms_contact_number
      customer_phone
      customer_email
      customer {
        _id
        primary_email
      }
      gocard {
        cashBalance
        cardNumber
      }
      ro_number
      dealer_id
      vehicle_year
      vehicle_make
      vehicle_model
      vin
      is_open
      photo_count
      video_count
      preferred_name
      serviceAdvisor {
        display_name
      }
    }
  `,
};

export default RODetailsOverview;
