import React, { useState } from 'react';

/* external */
import gql from 'graphql-tag';
import { useTheme } from '@mui/material';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';

/* Material UI */
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

/* internal */
import { useUserContext } from 'components/MaterialUI/UserContext';

const UPDATE_OPPORTUNITY_DEALER = gql`
  mutation updateOpportunityDealer($_id: ID!, $dealerId: Int!) {
    updateOpportunityDealer(_id: $_id, dealer_id: $dealerId) {
      _id
    }
  }
`;

const UpdateOpportunityDealerModal = ({
  opportunity,
  onClose,
  isOpen,
  refetch,
}) => {
  const theme = useTheme();
  const { currentUser } = useUserContext();
  const { enqueueSnackbar } = useSnackbar();
  const [dealerId, setDealerId] = useState(opportunity.dealer_id);
  const [updateOpportunityDealer] = useMutation(UPDATE_OPPORTUNITY_DEALER, {
    onCompleted: () => {
      onClose();
      refetch();
      enqueueSnackbar('Opportunity dealer updated!', { variant: 'success' });
    },
    onError: err => {
      enqueueSnackbar(`Could not update opportunity dealer: ${err}`, {
        variant: 'error',
      });
    },
  });

  const handleChange = event => {
    setDealerId(event.target.value);
  };

  const handleSubmit = () => {
    updateOpportunityDealer({
      variables: {
        _id: opportunity._id,
        dealerId,
      },
    });
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Update Opportunity Dealer</DialogTitle>
      <DialogContent>
        <FormControl>
          <InputLabel>Dealers</InputLabel>
          <Select value={dealerId} onChange={handleChange}>
            {currentUser.dealers.map(dealer => (
              <MenuItem key={dealer.dealer_id} value={dealer.dealer_id}>
                {dealer.dealer_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="outlined">
          Cancel
        </Button>
        <Button
          style={theme.actions.confirm}
          onClick={handleSubmit}
          variant="contained"
          color="primary"
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateOpportunityDealerModal;
