import React from 'react';

import gql from 'graphql-tag';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';

import { useMutation } from '@apollo/react-hooks';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material';

import { TaskStatus } from '../constants';
import TextFieldControl from 'components/MaterialUI/TextFieldControl';

const ADD_NOTE = gql`
  mutation addNote($input: NoteInput) {
    createNote(input: $input) {
      _id
    }
  }
`;

const UPDATE_TASK = gql`
  mutation UpdateTask($id: ID!, $input: UpdateTaskInput!) {
    updateTask(id: $id, input: $input) {
      _id
      due_date
      status
    }
  }
`;

const TaskCancel = ({ open, setOpen, task, action }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [addNote] = useMutation(ADD_NOTE);
  const [completeTask] = useMutation(UPDATE_TASK, {
    onCompleted: data => {
      action(data.updateTask.status);
      enqueueSnackbar('Task updated', { variant: 'success' });
    },
    onError: err => {
      enqueueSnackbar(`Could not update task error: ${err}`, {
        variant: 'error',
      });
    },
  });

  const handleClose = () => setOpen(false);
  const { control, handleSubmit } = useForm();

  const onSubmit = value => {
    completeTask({
      variables: {
        id: task._id,
        input: {
          status: TaskStatus.CANCELLED,
        },
      },
    }).then(() => {
      const noteData = {
        body: value.note,
        visibility: 'store_only',
        customer_id: task.customer_id,
        dealer_id: task.dealer_id,
      };
      if (noteData.body) {
        addNote({
          variables: {
            input: noteData,
          },
        });
      }
    });
    setOpen(false);
  };

  const theme = useTheme();
  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Cancel task</DialogTitle>
        <form
          onSubmit={handleSubmit(onSubmit)}
          onClick={event => event.stopPropagation()}
          onFocus={event => event.stopPropagation()}
        >
          <DialogContent>
            <TextFieldControl
              label="Notes"
              name="note"
              control={control}
              InputLabelProps={{
                shrink: true,
              }}
              defaultValue={null}
              style={{ width: '250px' }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" variant="outlined">
              Close
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              style={theme.actions.confirm}
            >
              Cancel task
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default TaskCancel;
