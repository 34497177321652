import React, { useEffect } from 'react';
import { Button } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';

import { useDealerContext } from 'components/MaterialUI/DealerContext';

const makeVINAppLink = (targetLabel, callback) => {
  const encodedParams = btoa(
    JSON.stringify({
      target_label: targetLabel,
    }),
  );
  const encodedCallback = window.encodeURIComponent(callback);
  return `io.goauto.markethelper://recordVin/${encodedParams}/${encodedCallback}`;
};

const HelperAppVINScanner = ({ onUploaded, disabled }) => {
  // Stash the dealer ID since the market helper app opens a new tab.
  // Since dealerId is stored in session storage in dealer context, we need
  // to stash it in local storage temporarily in order to keep the same selected
  // dealer id.  This would be better handled by adding the current dealer id
  // to the query string in the callback, but the helper does not currently
  // support this, since it just appends '?vin=ABCDEFGHIJ0123456' to the end
  // of the provided callback url.
  // TODO: Once (if) the helper app is updated to properly parse the query string
  // before adding the vin value, change this to set the current dealer id from
  // the query string param instead.
  const { dealerId, stashDealerId } = useDealerContext();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const vin = urlParams.get('vin');
    window.history.replaceState({}, '', window.location.pathname);
    if (vin) {
      onUploaded(vin);
    }
  });

  return (
    <>
      <Button
        disabled={disabled}
        variant="contained"
        color="secondary"
        style={{ padding: '0.5rem 0.75rem' }}
        onClick={() => {
          stashDealerId(dealerId);
          window.location.href = makeVINAppLink('VIN', window.location.href);
        }}
      >
        <StarIcon style={{ marginRight: '5px' }} />
        Scan
      </Button>
    </>
  );
};

export default HelperAppVINScanner;
