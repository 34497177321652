import React, { useEffect, useRef, useState } from 'react';

import { useDealerContext } from 'components/MaterialUI/DealerContext';

import { FormControl, InputLabel, Select } from '@mui/material';
import { makeStyles } from '@mui/styles';

/* Shamelessly ripped off from:
  https://material-ui.com/components/selects/#NativeSelects.js
*/
const useStyles = makeStyles(theme => ({
  formControl: {
    marginTop: theme.spacing(1),
    minWidth: 120,
  },
}));

const DealerPicker = ({ formControlProps, variant = 'outlined', ...props }) => {
  const { dealers, dealerId, setDealerId } = useDealerContext();
  const dealerOptions = dealers
    .sort((a, b) => a.dealer_name.localeCompare(b.dealer_name))
    .map(({ dealer_id, dealer_name }) => (
      <option key={dealer_id} value={dealer_id}>
        {dealer_name}
      </option>
    ));
  const classes = useStyles();
  const [labelWidth, setLabelWidth] = useState(0);
  const inputLabel = useRef(null);

  useEffect(() => {
    if (inputLabel && inputLabel.current)
      setLabelWidth(inputLabel.current.offsetWidth);
  }, [setLabelWidth]);

  const showSelect = dealers && dealers.length > 1;
  const showSingle = dealers && dealers.length === 1;

  const displayVariant = variant ?? 'outlined';

  return (
    <>
      {showSelect && (
        <FormControl
          variant={displayVariant}
          className={classes.formControl}
          style={{ width: props?.style?.width || 'auto' }}
          {...formControlProps}
        >
          <InputLabel ref={inputLabel} htmlFor="dealer-native-select">
            Active Dealer
          </InputLabel>
          <Select
            native
            inputProps={{ id: 'dealer-native-select' }}
            value={dealerId}
            onChange={e => setDealerId(parseInt(e.target.value))}
            {...props}
            label="Active Dealer"
          >
            {dealerOptions}
          </Select>
        </FormControl>
      )}
      {showSingle && (
        <FormControl
          variant={displayVariant}
          className={classes.formControl}
          {...formControlProps}
        >
          <InputLabel ref={inputLabel} htmlFor="dealer-native-select">
            Active Dealer
          </InputLabel>
          <Select
            native
            inputProps={{ id: 'dealer-native-select' }}
            value={dealerId}
            onChange={e => setDealerId(parseInt(e.target.value))}
            disabled
            label="Active Dealer"
            {...props}
          >
            {dealerOptions}
          </Select>
        </FormControl>
      )}
    </>
  );
};

export default DealerPicker;
