import React, { useState } from 'react';

/* Material UI */
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import ErrorDisplay from 'components/MaterialUI/ErrorDisplay';
import Loading from 'components/MaterialUI/Loading';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';

/* internal */
import Error from '../Error';

const DELETION_REASON_QUERY = gql`
  query DeletionReasons {
    auctions {
      deletionReasons {
        id
        reason
      }
    }
  }
`;

const DeleteItemForm = ({
  isOpen,
  onClose,
  onSubmit,
  submitErrors,
  title = 'Delete Auction Item',
}) => {
  const [deletionReason, setDeletionReason] = useState('');

  const { data, loading, error } = useQuery(DELETION_REASON_QUERY);
  const deletionReasons =
    data?.auctions?.deletionReasons?.map(reason => reason.reason) || [];
  const [validationError, setValidationError] = useState(null);

  const handleCancel = e => {
    onClose();
    e.stopPropagation();
  };

  const handleSubmit = e => {
    setValidationError(null);
    if (!deletionReason) {
      setValidationError('You must provide a reason.');
    } else {
      onSubmit({ deletionReason }, e);
      e.stopPropagation();
    }
  };

  if (error) return <ErrorDisplay error={error} action="Loading Values" />;
  if (loading) return <Loading />;

  return (
    <Dialog open={isOpen} onClose={handleCancel}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <Error error={submitErrors} />
          </Grid>
          <Grid item xs={12}>
            <FormControl style={{ width: '100%' }}>
              <InputLabel>Reason</InputLabel>
              <Select
                value={deletionReason}
                onChange={e => setDeletionReason(e.target.value)}
              >
                {deletionReasons.map((reason, index) => (
                  <MenuItem key={index} value={reason}>
                    {reason}
                  </MenuItem>
                ))}
              </Select>
              {validationError}
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button onClick={handleSubmit} disabled={!deletionReason}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteItemForm;
