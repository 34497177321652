import React, { useState } from 'react';

/* external */
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import gql from 'graphql-tag';

/* material ui */
import {
  Badge,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  IconButton,
  useTheme,
} from '@mui/material';
import { Cancel as CancelIcon, Add as AddIcon } from '@mui/icons-material';

/* internal */
// Not sure about need for importing these.  I think there's a context provider?
import { cloudinaryConfig } from 'constants.js';
import { cloudinaryCore } from 'utils';
import { errorHandler } from 'modules/pitches/utils'; // TODO: move to central location?
import noIMG from 'modules/inventory/static/img/no-image.jpg';
import ImageCarousel from 'modules/used_vehicles/components/components/ImageCarousel';
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';

const formatPhoto = (photo, options = {}) =>
  photo
    ? cloudinaryCore.url(photo.cloudinaryPublicId, {
        width: options.width || 100,
        height: options.height || 100,
        crop: 'pad',
        background: 'black',
      })
    : noIMG;

const FRAGMENT = gql`
  fragment TradePhotosFormBoxAppraisal on Appraisal {
    id
    photoUploadParams {
      apiKey
      folder
      signature
      timestamp
      uploadPreset
      url
    }
    photos {
      id
      appraisalId
      cloudinaryPublicId
      description
      md5
      photoType
    }
  }
`;

const UPDATE_APPRAISAL = gql`
  mutation updateAppraisal($id: Int!, $data: UpdateAppraisalInput!) {
    appraisals {
      updateAppraisal(id: $id, data: $data) {
        id
        ...TradePhotosFormBoxAppraisal
      }
    }
  }
  ${FRAGMENT}
`;
const TradePhotosFormBox = ({
  appraisal,
  onBack,
  onCancel,
  onUpdate,
  onSkip,
}) => {
  // Deconstruct appraisal!
  const { id, photoUploadParams, photos = [] } = appraisal;

  // states!
  const [open, setOpen] = useState(false);

  // hooks!
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { control, formState, handleSubmit, register, setError } = useForm({
    defaultValues: {
      photos: photos.map(({ id, cloudinaryPublicId, md5, photoType }) => ({
        id,
        cloudinaryPublicId,
        md5,
        photoType,
      })),
    },
  });
  const { isDirty } = formState;
  const photosFieldArray = useFieldArray({
    control,
    name: 'photos',
    keyName: 'key',
  });
  const [updateAppraisal, updateAppraisalMutation] = useMutation(
    UPDATE_APPRAISAL,
    {
      onCompleted: data => onUpdate(data.appraisals.updateAppraisal),
      onError: err => errorHandler(enqueueSnackbar, setError)(err),
    },
  );

  // functions and handlers!
  const uploadWidget = ({
    apiKey,
    folder,
    signature,
    timestamp,
    uploadPreset,
  }) => {
    const widget = window.cloudinary.createUploadWidget(
      {
        cloudName: cloudinaryConfig.cloud_name,
        apiKey,
        uploadSignature: signature,
        uploadSignatureTimestamp: timestamp,
        folder,
        uploadPreset,
        maxFileSize: 5 * 1000 * 1000, // 5MB
        clientAllowedFormats: ['png', 'jpg', 'jpeg', 'jfif'],
        maxImageWidth: 1600,
        maxImageHeight: 1200,
        multiple: true,
      },
      (error, result) => {
        if (!error && result && result.event === 'success') {
          photosFieldArray.append({
            cloudinaryPublicId: result.info.public_id,
            md5: result.info.etag,
            photoType: 'extra',
          });
        }
      },
    );
    widget.open();
  };

  const onSubmit = data => updateAppraisal({ variables: { id, data } });

  // render!
  return (
    <Box>
      <LoadingBackdrop open={updateAppraisalMutation.loading}>
        Saving...
      </LoadingBackdrop>
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md">
        <DialogTitle>{'Vehicle Photos'}</DialogTitle>
        <DialogContent>
          <ImageCarousel photos={photosFieldArray.fields} />
        </DialogContent>
      </Dialog>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container direction="row">
          {photosFieldArray.fields.map((photo, index) => {
            return (
              <Grid item xs={4} key={photo.key} onClick={() => setOpen(true)}>
                <Controller
                  control={control}
                  name={`photos.${index}.id`}
                  render={({ field: { onChange, value, ...field } }) => (
                    <input
                      {...field}
                      value={value}
                      onChange={e =>
                        onChange(
                          e.target.value === ''
                            ? undefined
                            : parseInt(e.target.value, 10),
                        )
                      }
                      type="hidden"
                    />
                  )}
                />

                <input
                  {...register(`photos[${index}].cloudinaryPublicId`)}
                  defaultValue={photo.cloudinaryPublicId}
                  type="hidden"
                />
                <input
                  {...register(`photos[${index}].md5`)}
                  defaultValue={photo.md5}
                  type="hidden"
                />
                <input
                  {...register(`photos[${index}].photoType`)}
                  defaultValue={photo.photoType}
                  type="hidden"
                />
                <Badge
                  overlap="rectangular"
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  badgeContent={
                    <IconButton
                      onClick={e => {
                        photosFieldArray.remove(index);
                        e.stopPropagation();
                      }}
                      size="large"
                    >
                      <CancelIcon style={{ fontSize: '32px' }} />
                    </IconButton>
                  }
                >
                  <img
                    alt="Vehicle"
                    style={{
                      objectFit: 'cover',
                      height: '100px',
                      width: '125px',
                      marginBottom: '20px',
                    }}
                    src={formatPhoto(photo)}
                  />
                </Badge>
              </Grid>
            );
          })}
          <Grid container style={{ paddingTop: '15px', paddingBottom: '15px' }}>
            <Fab
              variant="extended"
              style={theme.actions.confirm}
              onClick={() => uploadWidget(photoUploadParams)}
            >
              <AddIcon /> Add Photos
            </Fab>
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="flex-end" m={2} gap="8px">
          <Button variant="contained" onClick={onCancel} color="default">
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={onBack}
            disabled={isDirty}
            color="default"
          >
            Back
          </Button>
          {isDirty ? (
            <Button variant="contained" type="submit" color="default">
              Save & Continue
            </Button>
          ) : (
            <Button variant="contained" onClick={onSkip} color="default">
              Skip
            </Button>
          )}
        </Box>
      </form>
    </Box>
  );
};

TradePhotosFormBox.fragments = {
  appraisal: FRAGMENT,
};

export default TradePhotosFormBox;
