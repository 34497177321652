/* External */
import React from 'react';
import moment from 'moment-timezone';
import { useHistory } from 'react-router-dom';

/* Material UI */
import { TableCell, TableRow } from '@mui/material';

/* Internal */
import BulkSendStatusChip from './BulkSendStatusChip';

const BulkSendTableRows = ({ gatewayId, gatewayUsers, bulkSends }) => {
  const history = useHistory();

  return bulkSends.map(bulkSend => (
    <TableRow
      key={bulkSend.id}
      onClick={() =>
        history.push(
          `/sms/gateways/${gatewayId}/bulk-sends/${bulkSend.id}/details`,
        )
      }
    >
      <TableCell>
        <BulkSendStatusChip status={bulkSend.statusLabel} />
      </TableCell>
      <TableCell>{bulkSend.name}</TableCell>
      <TableCell>
        {/* if the username isn't in the list of gatewayUsers, default to the createdBy username */}
        {gatewayUsers.find(x => x.username === bulkSend.createdBy)
          ?.display_name ?? bulkSend.createdBy}
      </TableCell>
      <TableCell>
        {bulkSend.dateCreated
          ? moment(bulkSend.dateCreated).format('MMM Do YYYY, h:mm:ss a')
          : '—'}
      </TableCell>
      <TableCell>
        {bulkSend.scheduledStart
          ? moment(bulkSend.scheduledStart).format('MMM Do YYYY, h:mm:ss a')
          : '—'}
      </TableCell>
      <TableCell>
        {bulkSend.dateStarted
          ? moment(bulkSend.dateStarted).format('MMM Do YYYY, h:mm:ss a')
          : '—'}
      </TableCell>
      <TableCell>
        {bulkSend.dateFinished
          ? moment(bulkSend.dateFinished).format('MMM Do YYYY, h:mm:ss a')
          : '—'}
      </TableCell>
      <TableCell align="right">{bulkSend.scheduledCount}</TableCell>
      <TableCell align="right">{bulkSend.deliveredCount}</TableCell>
      <TableCell align="right">{bulkSend.totalCount}</TableCell>
      <TableCell align="right">{bulkSend.replyCount}</TableCell>
    </TableRow>
  ));
};

export default BulkSendTableRows;
