import React, { useState } from 'react';

/* External */
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';

/* Material UI */
import {
  Box,
  Button,
  darken,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
  Add as AddIcon,
  Block as BlockIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from '@mui/icons-material';

/* Internal */
import ConfirmDialog from 'components/MaterialUI/ConfirmDialog';
import ErrorDisplay from 'components/MaterialUI/ErrorDisplay';
import Loading from 'components/MaterialUI/Loading';
import CreateGatewayModal from './CreateGatewayModal';
import GatewayRow from './GatewayRow';
import { GATEWAYS_QUERY } from './Gateways';
import GlobalSuppressionModal from './GlobalSuppressionModal';
import { UPDATE_SETTINGS } from './SMSActions/GeneralSettings';
import { errorHandler } from 'modules/pitches/utils';

const useStyles = makeStyles(theme => ({
  headerRow: {
    '&>th': {
      fontWeight: 'bold',
      borderBottom: '2px solid black',
      paddingBottom: '5px',
      fontSize: '1rem',
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.875rem',
      },
    },
  },
}));

const ManageGateways = () => {
  const { headerRow } = useStyles();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const { data, loading, error, refetch } = useQuery(GATEWAYS_QUERY);

  const [openConfirmDialogId, setOpenConfirmDialogId] = useState(null);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openGlobalSuppressionModal, setOpenGlobalSuppressionModal] =
    useState(false);

  const openConfirmDialog = id => setOpenConfirmDialogId(id);
  const closeConfirmDialog = () => setOpenConfirmDialogId(null);

  const [updateSettings] = useMutation(UPDATE_SETTINGS, {
    onCompleted: () => {
      refetch();
      enqueueSnackbar('Gateway deleted successfully', {
        variant: 'success',
      });
    },
    onError: error => {
      errorHandler(enqueueSnackbar, null, { snackAll: true })(error);
    },
  });

  if (loading) return <Loading />;
  if (error) return <ErrorDisplay error={error} />;

  const actions = [
    {
      title: 'Edit Gateway',
      path: id => `/sms/gateways/${id}/settings`,
      icon: <EditIcon />,
    },
    {
      title: 'Delete Gateway',
      onClick: id => () => openConfirmDialog(id),
      style: { backgroundColor: theme.actions.danger.backgroundColor },
      icon: <DeleteIcon />,
    },
  ];

  const cells = [
    {
      content: gateway => <Box>{gateway.name}</Box>,
      clickable: false,
    },
    {
      content: gateway => <Box>{gateway.gateway_type}</Box>,
      clickable: false,
    },
    {
      content: gateway => <Box>{gateway.phone_number}</Box>,
      clickable: false,
    },
    {
      content: gateway => <Box>{gateway.username}</Box>,
      clickable: false,
    },
    {
      content: gateway => (
        <Box>{gateway.enable_casl === true ? 'True' : 'False'}</Box>
      ),
      clickable: false,
    },
    {
      content: gateway => <Box>{gateway.casl_stop_word}</Box>,
      clickable: false,
    },
  ];

  const gatewaysList = data.sms?.gateways || [];

  return (
    <>
      <Box margin="25px 20px" maxWidth="80%">
        <Box
          display="flex"
          justifyContent="space-between"
          flexWrap="wrap"
          width="100%"
        >
          <Typography
            variant="h5"
            style={{ fontWeight: 'bold', marginBottom: '10px' }}
          >
            Manage Gateways
          </Typography>
        </Box>
        <Box display="flex" justifyContent="flex-start" flexWrap="wrap">
          <Button
            variant="outlined"
            color="primary"
            style={{
              color: darken(theme.actions.confirm.backgroundColor, 0.2),
              borderColor: darken(theme.actions.confirm.backgroundColor, 0.2),
              marginRight: '20px',
            }}
            startIcon={<AddIcon />}
            onClick={() => setOpenCreateModal(true)}
          >
            Add New Gateway
          </Button>
          <Button
            variant="outlined"
            color="primary"
            style={{
              color: darken(theme.colours.add, 0.2),
              borderColor: darken(theme.colours.add, 0.2),
            }}
            startIcon={<BlockIcon />}
            onClick={() => setOpenGlobalSuppressionModal(true)}
          >
            Global Suppression
          </Button>
        </Box>
      </Box>
      <>
        <Table>
          <TableHead>
            <TableRow className={headerRow}>
              <TableCell>Actions</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Username or SID</TableCell>
              <TableCell>CASL Enabled</TableCell>
              <TableCell>Stop Word</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!gatewaysList.length && (
              <TableRow rowSpan={3}>
                <TableCell align="center" colSpan={3}>
                  No gateways available.
                </TableCell>
              </TableRow>
            )}
            {gatewaysList.map(gateway => (
              <GatewayRow
                key={gateway.id}
                gateway={gateway}
                actions={actions}
                cells={cells}
              >
                <ConfirmDialog
                  isOpen={gateway.id === openConfirmDialogId}
                  onClose={closeConfirmDialog}
                  title="Delete Gateway"
                  confirmText="Delete"
                  text="Are you sure you want to delete this gateway?"
                  onConfirm={() => {
                    updateSettings({
                      variables: {
                        gatewayId: gateway.id,
                        settings: {
                          enabled: false,
                        },
                      },
                    });
                  }}
                />
              </GatewayRow>
            ))}
          </TableBody>
        </Table>
      </>
      <CreateGatewayModal
        open={openCreateModal}
        onClose={() => setOpenCreateModal(false)}
        refetch={refetch}
      />
      <GlobalSuppressionModal
        open={openGlobalSuppressionModal}
        onClose={() => setOpenGlobalSuppressionModal(false)}
      />
    </>
  );
};

export default ManageGateways;
