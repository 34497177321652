import React, { useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
} from '@mui/material';

const OptionalPitchSheetDialog = ({
  optionalPitchSheets,
  generateOptionalPDF,
  generatePDF,
  pitchId,
  open,
  onClose,
}) => {
  const [selectedDealerId, setSelectedDealerId] = useState('');

  const handleSelectChange = event => setSelectedDealerId(event.target.value);

  const handleGeneratePDF = () => {
    if (selectedDealerId !== '') {
      generateOptionalPDF(pitchId, selectedDealerId);
    } else {
      generatePDF(pitchId);
    }
    onClose();
  };
  return (
    <Dialog open={open}>
      <DialogTitle>Generate Pitch Sheet</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Select the Pitch Sheet that you want to use:
        </DialogContentText>
        <FormControl>
          <Select
            value={selectedDealerId}
            onChange={handleSelectChange}
            style={{ width: '350px' }}
            displayEmpty
          >
            <MenuItem value="">Default Pitch Sheet</MenuItem>
            {optionalPitchSheets.map(
              ({ pitchSheetDealerId, pitchSheetDealer }) => (
                <MenuItem key={pitchSheetDealerId} value={pitchSheetDealerId}>
                  {pitchSheetDealer.name}
                </MenuItem>
              ),
            )}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleGeneratePDF()}>Generate PDF</Button>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default OptionalPitchSheetDialog;
