/* External */
import React from 'react';
import { useSnackbar } from 'notistack';

/* Material UI */
import {
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
  Check as CheckIcon,
  Publish as PublishIcon,
} from '@mui/icons-material';

/* Internal */

const useStyles = makeStyles(theme => ({
  phoneNumber: {
    fontWeight: 400,
    color: theme.colours.greys.greytext1,
    marginLeft: '10px',
  },
  h6: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
    marginBottom: '15px',
  },
  button: {
    backgroundColor: theme.colours.add,
    color: 'white',
  },
  input: {
    display: 'none',
  },
  errorMsg: {
    color: 'red',
    fontWeight: 'bold',
    margin: '10px 0 20px 0',
  },
  uploadButton: {
    color: theme.colours.add,
    border: '1px solid ' + theme.colours.add,
    backgroundColor: 'white',
    marginTop: '10px',
  },
  urlUploaded: {
    color: theme.actions.confirm.backgroundColor,
    border: '1px solid ' + theme.actions.confirm.backgroundColor,
    backgroundColor: 'white',
    marginTop: '10px',
  },
}));

function getBase64(file) {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.onload = function () {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsText(file);
  });
}

const BulkSendStep1 = ({
  bulkSendName,
  setBulkSendName,
  fileType,
  setFileType,
  upload,
  setUpload,
  urlForUpload,
  setUrlForUpload,
  setPlaceholderData,
  setRowData,
  setFile,
  urlUploaded,
  setUrlUploaded,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const extension = '.csv';

  const removeOuterQuotes = str => {
    // uploading a CSV via URL adds quotation marks around each string
    // Also, if you actually add quotation marks to a field, it adds an additional quotation mark
    let start = 0;
    let end = str.length;

    if (str.startsWith('""')) start = 2;
    else if (str.startsWith('"')) start = 1;

    if (str.endsWith('""')) end -= 2;
    else if (str.endsWith('"')) end -= 1;

    return str.substring(start, end);
  };

  const uploadFile = async event => {
    try {
      let data = '';
      // if the user is uploading a CSV via URL,
      // we need to grab the file and convert it to a blob so we can create a file from it
      if (fileType === 'url') {
        const response = await fetch(urlForUpload);
        data = await response.blob();
        setUrlUploaded(true);
      }
      const file =
        fileType === 'csv'
          ? event.target?.files[0]
          : new File([data], bulkSendName, { type: 'text/csv' });

      let base64File = await getBase64(file);
      // We want to get the "first row" aka the "header row" of data from the CSV file
      // for use in the placeholders list
      // Random carriage returns show up in the data set and in the actual content itself which we don't want
      // there's also the possibility of other random characters showing up that we don't want (see "removeOuterQuotes" above)
      let headerData = base64File
        .slice(0, base64File.indexOf('\n'))
        .replace(/[!@#$%^&*"'~\r]/g, '')
        .split(',');
      setPlaceholderData(headerData);
      // get the rest of the data "rows"
      let rowData = base64File
        .slice(base64File.indexOf('\n') + 1)
        .replace(/\r|\n$/g, '')
        .split('\n');

      if (fileType === 'url') {
        rowData = rowData.map(row =>
          row.split(',').map(item => removeOuterQuotes(item)),
        );
        rowData = rowData.map(row => row.join(','));
        setRowData(rowData);
        setFile(
          new File(
            [headerData.join(',') + '\n' + rowData.join('\n')],
            bulkSendName,
            { type: 'text/csv' },
          ),
        );
      } else {
        setRowData(rowData);
        setFile(file);
      }

      setUpload({
        name: file.name,
        dataURI: base64File,
        extension,
      });
    } catch (err) {
      enqueueSnackbar(`Could not upload CSV, error: ${err}`, {
        variant: 'error',
      });
    }
  };

  const radioButtonHandler = event => {
    setFileType(event.target.value);
    if (event.target.value === 'csv') {
      setUrlForUpload('');
      setUrlUploaded(false);
    }
    if (event.target.value === 'url') {
      setUpload({
        name: '',
        dataURI: '',
        extension: '',
      });
    }
  };

  return (
    <>
      <Grid item xs={12}>
        <TextField
          label="Bulk Send Name"
          value={bulkSendName}
          variant="outlined"
          helperText="*Required"
          style={{ width: '100%' }}
          onChange={e => setBulkSendName(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} style={{ borderBottom: '1px solid black' }}>
        <RadioGroup
          style={{ flexDirection: 'row', marginBottom: '10px' }}
          value={fileType}
          onChange={event => radioButtonHandler(event)}
        >
          <FormControlLabel
            value="csv"
            label="Upload a CSV File"
            control={<Radio />}
          />
          <FormControlLabel value="url" label="From URL" control={<Radio />} />
        </RadioGroup>
      </Grid>
      <Grid container item xs={12}>
        <Grid item xs={12} style={{ padding: '10px' }}>
          CSV files must contain a "Name" and "Number" column. <br />
          Additional columns can be included for use in the message template.
        </Grid>
        {fileType === 'csv' && (
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              alignItems: 'center',
              marginTop: '20px',
            }}
          >
            <span
              style={{
                paddingRight: '1rem',
              }}
            >
              <input
                className={classes.input}
                id="attachment-file"
                type="file"
                accept=".csv"
                onChange={uploadFile}
              />
              <label htmlFor="attachment-file">
                <Button variant="contained" color="primary" component="span">
                  Choose File
                </Button>
              </label>
            </span>
            <Typography style={{ fontStyle: 'italic' }}>
              {upload.name || 'No file chosen'}
            </Typography>
          </Grid>
        )}
        {fileType === 'url' && (
          <>
            <TextField
              label="URL"
              value={urlForUpload}
              variant="outlined"
              style={{ width: '100%' }}
              onChange={e => setUrlForUpload(e.target.value)}
            />
            <Button
              disabled={!urlForUpload}
              variant="outlined"
              endIcon={urlUploaded ? <CheckIcon /> : <PublishIcon />}
              onClick={event => uploadFile(event)}
              className={
                urlUploaded ? classes.urlUploaded : classes.uploadButton
              }
            >
              {`${urlUploaded ? 'Uploaded!' : 'Upload'}`}
            </Button>
          </>
        )}
      </Grid>
    </>
  );
};

export default BulkSendStep1;
