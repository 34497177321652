import React from 'react';

import moment from 'moment';

import { useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Grid';
import ListItemText from '@mui/material/ListItemText';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { defaultTZ } from 'utils';

const ActivityDateDisplay = ({ date }) => {
  const desktop = useMediaQuery(theme => theme.breakpoints.up('lg'));
  return (
    <Grid
      container
      direction={desktop ? 'row' : 'column'}
      justifyContent="flex-start"
      alignItems="center"
      spacing={desktop ? 1 : 0}
    >
      <Grid item>
        <DateRangeIcon />
      </Grid>
      <Grid item>
        <ListItemText
          primaryTypographyProps={{
            style: { fontSize: 12, overflowWrap: 'anywhere' },
          }}
          secondaryTypographyProps={{
            style: { fontSize: 12 },
          }}
          primary={moment(date).tz(defaultTZ).format('MMM DD, YYYY')}
          secondary={moment(date).tz(defaultTZ).format('h:MM A')}
        />
      </Grid>
    </Grid>
  );
};

export default ActivityDateDisplay;
