import React, { useState } from 'react';
import gql from 'graphql-tag';
import { startCase, toLower } from 'lodash';

import { useQuery, useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';

import Alert from '@mui/material/Alert';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';

import ErrorDisplay from 'components/MaterialUI/ErrorDisplay';

import { GET_STOCK_STATUSES } from '../../common/Queries';

const titleCase = x => startCase(toLower(x));

const UPDATE_STOCK_STATUS_ID = gql`
  mutation updateStockStatusID($filters: [QueryFilter!]!, $statusID: Int!) {
    npv {
      updateBulkVehicles(
        filters: $filters
        data: { stockStatusId: $statusID }
      ) {
        id
        stockStatusId
        stockStatus {
          id
          name
        }
      }
    }
  }
`;

const BulkUpdateStatus = ({
  ids,
  open,
  onClose,
  selectByFilter,
  vehicleFilters,
}) => {
  const { data, loading, error } = useQuery(GET_STOCK_STATUSES);
  const { enqueueSnackbar } = useSnackbar();
  const [statusID, setStatusID] = useState();

  const stockStatuses = data?.npv?.getStockStatuses || [];

  const [update, updateStatus] = useMutation(UPDATE_STOCK_STATUS_ID, {
    onCompleted: data => {
      onClose();
      enqueueSnackbar(
        `Successfully updated ${data.npv.updateBulkVehicles.length} vehicles' statuses.`,
      );
    },
    onError: () => null,
  });

  const filters = selectByFilter
    ? vehicleFilters
    : [{ model: 'Vehicle', field: 'id', op: 'in', value: ids }];
  const handleSubmit = () => update({ variables: { filters, statusID } });
  const handleChange = e => setStatusID(e.target.value);

  if (loading) return <>Loading NPV Statuses...</>;
  if (error) return <>Error{JSON.stringify(error)}</>;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Set Bulk Status</DialogTitle>
      <DialogContent>
        {updateStatus.error && (
          <Alert severity="warning">
            <ErrorDisplay
              error={updateStatus.error}
              action="Updating NPVs' Statuses"
            />
          </Alert>
        )}
        <FormControl sx={{ marginTop: '10px' }}>
          <InputLabel>Move to Status</InputLabel>
          <Select
            onChange={handleChange}
            style={{ width: '200px' }}
            value={statusID}
            label="Move to Status"
          >
            {stockStatuses.map(({ id, name }) => (
              <MenuItem value={id} key={id}>
                {titleCase(name)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        {updateStatus.loading ? (
          <CircularProgress
            size={20}
            style={{ marginLeft: '40px', marginRight: '30px' }}
          />
        ) : (
          <Button
            autoFocus
            onClick={handleSubmit}
            variant="contained"
            style={{
              backgroundColor: '#74B72E',
            }}
          >
            Submit
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default BulkUpdateStatus;
