import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useSnackbar } from 'notistack';

/* Material UI */
import {
  DialogContent,
  TextField,
  Grid,
  Box,
  Button,
  IconButton,
  DialogActions,
  DialogTitle,
  useTheme,
} from '@mui/material';
import {
  CloudUpload as CloudUploadIcon,
  InsertDriveFile as InsertDriveFileIcon,
  Delete as TrashIcon,
} from '@mui/icons-material';

/* internal */
import ConfirmDialog from 'components/MaterialUI/ConfirmDialog';
import { isEmpty } from 'underscore';
import Loading from 'components/MaterialUI/Loading';

const GET_ATTACHMENT_POST_URL = gql`
  mutation getAttachmentPostUrl($id: ID!) {
    attachmentPresignedPost(_id: $id) {
      url
      fields {
        AWSAccessKeyId
        key
        policy
        signature
      }
    }
  }
`;

const ADD_ATTACHMENT = gql`
  mutation uploadAttachment($id: ID!, $input: BulletinAttachmentUpload!) {
    uploadBulletinAttachment(_id: $id, input: $input) {
      _id
    }
  }
`;

const REMOVE_ATTACHMENT = gql`
  mutation removeAttachment($id: ID!, $attachmentId: ID!) {
    removeBulletinAttachment(_id: $id, attachmentId: $attachmentId) {
      _id
    }
  }
`;

const BulletinAttachments = ({ handleClose, bulletin, refetch }) => {
  const theme = useTheme();
  const [file, setFile] = useState(null);
  const [newLabel, setNewLabel] = useState('');
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmDeleteId, setConfirmDeleteId] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const [addAttachment, { loading: addLoading }] = useMutation(ADD_ATTACHMENT, {
    onCompleted: () => {
      enqueueSnackbar('Attachment Added!', { variant: 'success' });
      refetch();
      handleClose();
    },
    onError: err => {
      enqueueSnackbar(
        `Could not add Attachment error: ${JSON.stringify(err)}`,
        {
          variant: 'error',
        },
      );
    },
  });

  const [GetAttachmentPostUrl, { loading: postLoading }] = useMutation(
    GET_ATTACHMENT_POST_URL,
    {
      onCompleted: async data => {
        const { fields, url } = data.attachmentPresignedPost;
        const { __typename, ...uploadFields } = fields;
        const formData = new FormData();
        let s3key = '';
        Object.entries(uploadFields).forEach(([key, value]) => {
          if (key === 'key') {
            s3key = value;
          }
          formData.append(key, value);
        });

        formData.append('file', file, newLabel);
        try {
          await fetch(url, {
            method: 'POST',
            mode: 'no-cors',
            body: formData,
          });
          addAttachment({
            variables: {
              id: bulletin._id,
              input: {
                key: s3key,
                label: newLabel,
              },
            },
          });
        } catch (err) {
          enqueueSnackbar(
            `Could not save Attachment error: ${JSON.stringify(err)}`,
            {
              variant: 'error',
            },
          );
        }
      },
    },
  );

  const [removeAttachment, { loading: removeLoading }] = useMutation(
    REMOVE_ATTACHMENT,
    {
      onCompleted: () => {
        enqueueSnackbar('Attachment Removed!', { variant: 'success' });
        refetch();
        handleClose();
      },
      onError: err => {
        enqueueSnackbar(
          `Could not remove Attachment error: ${JSON.stringify(err)}`,
          {
            variant: 'error',
          },
        );
      },
    },
  );

  async function handleLabelChange(event) {
    const files = event.target.files;
    setFile(files[0]);
    setNewLabel(files[0].name);
  }

  const handleUpload = () => {
    GetAttachmentPostUrl({
      variables: {
        id: bulletin._id,
      },
    });
  };

  const handleDelete = () => {
    removeAttachment({
      variables: {
        id: bulletin._id,
        attachmentId: confirmDeleteId,
      },
    });
    setConfirmDelete(false);
  };

  const UploadComponent = ({ item }) => {
    const isUploaded = item?._id;
    return (
      <Box style={{ marginBottom: '0.5rem' }}>
        <Grid container direction="row" alignItems="center">
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              size="small"
              style={{
                maxWidth: '350px',
                width: '100%',
              }}
              disabled
              value={item?.label || newLabel}
            />
          </Grid>
          <Grid item xs={6}>
            <span
              style={{
                paddingLeft: '1rem',
              }}
            >
              <input
                style={{ display: 'none' }}
                id="attachment-file"
                type="file"
                onChange={handleLabelChange}
              />
            </span>
            {isUploaded ? (
              <>
                <IconButton style={{ marginRight: '0.5rem' }} size="small">
                  <InsertDriveFileIcon
                    onClick={() => {
                      window.open(item.url, '_blank');
                    }}
                  />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={() => {
                    setConfirmDelete(true);
                    setConfirmDeleteId(item._id);
                  }}
                >
                  <TrashIcon style={{ color: '#6c757d' }} />
                </IconButton>
              </>
            ) : (
              <label htmlFor="attachment-file">
                <Button variant="contained" color="primary" component="span">
                  <CloudUploadIcon
                    fontSize="small"
                    style={{ marginRight: '0.5rem' }}
                  />
                  Browse
                </Button>
              </label>
            )}
          </Grid>
        </Grid>
      </Box>
    );
  };
  if (addLoading || removeLoading || postLoading) return <Loading />;
  return (
    <>
      <ConfirmDialog
        isOpen={confirmDelete}
        onClose={() => setConfirmDelete(false)}
        onConfirm={handleDelete}
        text="Are you sure you want to delete this attachment?"
        confirmText="Delete"
        abortText="Cancel"
      />
      <DialogTitle>Attachments</DialogTitle>
      <DialogContent>
        {!isEmpty(bulletin.attachments) ? (
          [...bulletin.attachments, ...[{}]].map(
            (item, index) =>
              !item.deleted && <UploadComponent key={index} item={item} />,
          )
        ) : (
          <UploadComponent />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} style={theme.actions.cancel}>
          Cancel
        </Button>
        <Button
          onClick={handleUpload}
          color="primary"
          variant="contained"
          disabled={!file}
          style={!file ? theme.actions.disabled : theme.actions.confirm}
        >
          Save
        </Button>
      </DialogActions>
    </>
  );
};

export default BulletinAttachments;
