/* External */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

/* Material UI */
import { Button, Box, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  Message as MessageIcon,
  Notifications as NotificationsIcon,
  PanTool as PanToolIcon,
  Settings as SettingsIcon,
  Telegram as TelegramIcon,
} from '@mui/icons-material';

/* Internal */
import SMSNotificationsDialog from './SMSNotificationsDialog';

const useStyles = makeStyles(theme => ({
  compactAction: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
      cursor: 'pointer',
    },
    '& button': {
      padding: '6px 4px',
      minWidth: 'unset',
      // #24A0ED
      backgroundColor: theme.actions.info.backgroundColor,
    },
  },
  detailedAction: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    '& button': {
      // #24A0ED
      color: theme.actions.info.backgroundColor,
      margin: '5px',
    },
  },
  verticalRule: {
    borderLeft: '1px solid black',
    height: '20px',
  },
}));

const ActionsMenu = ({ gateway, version }) => {
  const classes = useStyles();
  const [openDialogId, setOpenDialogId] = useState(null);

  const openDialog = id => {
    setOpenDialogId(id);
  };

  const closeDialog = () => {
    setOpenDialogId(null);
  };
  const history = useHistory();

  const actions = [
    {
      title: 'Permissions',
      path: id => `/sms/gateways/${id}/permissions`,
      icon: <PanToolIcon />,
    },
    {
      title: 'Settings',
      path: id => `/sms/gateways/${id}/settings`,
      icon: <SettingsIcon />,
    },
    {
      title: 'Gateway Notifications',
      onClick: id => () => openDialog(id),
      icon: <NotificationsIcon />,
    },
    {
      title: 'Bulk Sends',
      path: id => `/sms/gateways/${id}/bulk-sends`,
      icon: <TelegramIcon />,
    },
    {
      title: 'Message Search',
      path: id => `/sms/gateways/${id}/message-search`,
      icon: <MessageIcon />,
    },
  ];
  const ActionButton = ({ title, path, icon, onClick }) => {
    return (
      <Tooltip title={title}>
        <Button
          variant="contained"
          color="primary"
          onClick={onClick || (() => history.push(path))}
        >
          {icon}
        </Button>
      </Tooltip>
    );
  };

  const DetailedActionButton = ({ title, path, icon, onClick }) => {
    return (
      <Button
        onClick={onClick || (() => history.push(path))}
        startIcon={icon}
        size="small"
      >
        {title}
      </Button>
    );
  };

  return (
    <>
      <Box
        className={
          version === 'compact' ? classes.compactAction : classes.detailedAction
        }
      >
        {actions.map((action, index) => (
          <>
            {version === 'compact' && (
              <ActionButton
                key={index}
                title={action.title}
                path={action.path ? action.path(gateway.id) : null}
                icon={action.icon}
                onClick={action.onClick ? action.onClick(gateway.id) : null}
              />
            )}
            {version === 'detailed' && (
              <>
                <DetailedActionButton
                  key={index}
                  title={action.title}
                  path={action.path ? action.path(gateway.id) : null}
                  icon={action.icon}
                  onClick={action.onClick ? action.onClick(gateway.id) : null}
                />
                {index < actions.length - 1 && (
                  <Box className={classes.verticalRule}></Box>
                )}
              </>
            )}
          </>
        ))}
      </Box>
      <SMSNotificationsDialog
        onClose={closeDialog}
        gatewayId={gateway.id}
        gatewayName={gateway.name}
        phoneNumber={gateway.phone_number}
        open={openDialogId === gateway.id}
        subscriptions={gateway.user_subscription}
      />
    </>
  );
};

export default ActionsMenu;
