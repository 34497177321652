import React, { useState } from 'react';
import { Regexps } from 'constants.js';
import Identities from '../../customers/components/CustomerDetails/Identities';

/* external */
import gql from 'graphql-tag';
import { useSnackbar } from 'notistack';

/* Material UI */
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
  MenuItem,
} from '@mui/material';
import { useTheme } from '@mui/material';
import { useMutation } from '@apollo/react-hooks';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';

const ADD_IDENTITY = gql`
  mutation addIdentity($identity: CustomerIdentityInput) {
    addCustomerIdentity(identity: $identity) {
      _id
      ...IdentitiesFragment
    }
  }
  ${Identities.fragments.customer}
`;

const CustomerIdentityEditorModal = ({
  opportunity,
  onClose,
  isOpen,
  setShowPendingModal,
}) => {
  const [addIdentityMutation] = useMutation(ADD_IDENTITY, {
    onCompleted: data => {
      enqueueSnackbar('Identity added', {
        variant: 'success',
      });
    },
    onError: err =>
      enqueueSnackbar(`Unable to add identity to this profile: ${err}`, {
        variant: 'error',
      }),
  });

  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const isValidEmail = email => Regexps.EmailFormat.test(email ?? '');
  const isValidPhoneNumber = num => Regexps.PhoneFormat.ALL.test(num ?? '');

  // Email identity set at the profile level.
  const email = opportunity?.customer?.identities.find(
    identity => identity?.identity_type === 'email',
  )?.identity_value;

  // Resetting the list of emails linked to the customer profile
  const initialEmails = [
    ...(opportunity?.customer?.emails || []).map(customer => ({
      value: customer.email,
      name: customer.email,
    })),
    { value: '+ Add new email', name: '+ Add new email' },
  ];
  const [emails, setEmails] = useState(initialEmails);

  // Phone identity set at the profile level.
  const sms = opportunity?.customer?.identities.find(
    identity => identity.identity_type === 'sms',
  )?.identity_value;

  // Resetting the list of phone numbers linked to the customer profile
  const initialPhoneNumbers = [
    ...[
      sms,
      opportunity?.customer?.phone,
      opportunity?.customer?.cell_phone,
      opportunity?.customer?.home_phone,
      opportunity?.customer?.work_phone,
    ]
      .filter(x => x)
      .map(phone => ({ value: phone, name: phone })),
    { value: '+ Add new phone', name: '+ Add new phone' },
  ];
  const [phoneNumbers, setPhoneNumbers] = useState(initialPhoneNumbers);

  // States for adding a new email or phone number to associate with the identity.
  const [newEmail, setNewEmail] = useState('');
  const [newPhoneNumber, setNewPhoneNumber] = useState('');

  const [isAddingNewEmail, setIsAddingNewEmail] = useState(false);
  const [isAddingNewPhone, setIsAddingNewPhone] = useState(false);

  const [selectedEmail, setSelectedEmail] = useState('');
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState('');

  const handleAddNewPhone = () => {
    if (newPhoneNumber) {
      const updatedPhoneNumbers = [...phoneNumbers];
      updatedPhoneNumbers.splice(phoneNumbers.length - 1, 0, {
        value: newPhoneNumber,
        name: newPhoneNumber,
      });
      setPhoneNumbers(updatedPhoneNumbers);
      setNewPhoneNumber('');
      setIsAddingNewPhone(false);
    }
  };

  const handleAddNewEmail = () => {
    if (newEmail) {
      const updatedEmails = [...emails];
      updatedEmails.splice(emails.length - 1, 0, {
        value: newEmail,
        name: newEmail,
      });
      setEmails(updatedEmails);
      setNewEmail('');
      setIsAddingNewEmail(false);
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle style={{ fontWeight: 'bold' }}>
        Identities for{' '}
        {opportunity?.customer_name ||
          opportunity?.customer?.company_name ||
          'Customer'}
      </DialogTitle>
      <DialogContent>
        <Grid item style={{ marginBottom: '20px' }}>
          <div
            style={{ width: '100%', marginBottom: '12px', fontWeight: 'bold' }}
          >
            <span
              style={{
                display: 'block',
                borderBottom: '1px solid black',
                fontSize: 'larger',
              }}
            >
              Email
            </span>
          </div>
          {/* If there are emails linked to the customer or if there is an identity email, show email list dropdown */}
          {(emails.length > 1 || email) && (
            <TextField
              select
              fullWidth
              value={selectedEmail}
              defaultValue={email}
              onChange={event => {
                setSelectedEmail(event.target.value);
                setIsAddingNewEmail(event.target.value === '+ Add new email');
              }}
            >
              {emails.map((email, index) => (
                <MenuItem key={index} value={email.value}>
                  {email.name}
                </MenuItem>
              ))}
            </TextField>
          )}
          {!isAddingNewEmail && !email && !selectedEmail && (
            <div style={{ marginTop: '2px' }}>
              <p>No email records found for the customer.</p>
              <Button
                style={theme.actions.add}
                variant="outlined"
                onClick={() => {
                  setIsAddingNewEmail(true);
                }}
              >
                <AddIcon /> ADD EMAIL
              </Button>
            </div>
          )}
        </Grid>

        {/* Adding new email textfield */}
        {isAddingNewEmail && (
          <Grid container alignItems="center" style={{ marginBottom: '20px' }}>
            <TextField
              value={newEmail}
              onChange={event => setNewEmail(event.target.value)}
              style={{ width: '60%' }}
            />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Button
                onClick={handleAddNewEmail}
                disabled={!newEmail || !isValidEmail(newEmail)}
              >
                <CheckIcon style={{ color: 'green' }} />
              </Button>
              <Button
                onClick={() => setIsAddingNewEmail(false)}
                color="primary"
              >
                <CloseIcon />
              </Button>
            </div>
          </Grid>
        )}
        <Grid item style={{ marginBottom: '20px' }}>
          <div
            style={{ width: '100%', marginBottom: '12px', fontWeight: 'bold' }}
          >
            <span
              style={{
                display: 'block',
                borderBottom: '1px solid black',
                fontSize: 'larger',
              }}
            >
              SMS
            </span>
          </div>
          {(phoneNumbers.length > 1 || sms) && (
            <TextField
              select
              fullWidth
              value={selectedPhoneNumber}
              defaultValue={sms}
              onChange={event => {
                setSelectedPhoneNumber(event.target.value);
                setIsAddingNewPhone(event.target.value === '+ Add new phone');
              }}
            >
              {phoneNumbers.map((phoneNumber, index) => (
                <MenuItem key={index} value={phoneNumber.value}>
                  {phoneNumber.name}
                </MenuItem>
              ))}
            </TextField>
          )}
          {phoneNumbers.length <= 1 &&
            !isAddingNewPhone &&
            !sms &&
            !selectedPhoneNumber && (
              <div style={{ marginTop: '2px' }}>
                <p>No SMS records found for the customer.</p>
                <Button
                  style={theme.actions.add}
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    setIsAddingNewPhone(true);
                  }}
                >
                  <AddIcon /> ADD SMS
                </Button>
              </div>
            )}
        </Grid>

        {/* Adding new phone number textfield */}
        {isAddingNewPhone && (
          <Grid container alignItems="center">
            <TextField
              value={newPhoneNumber}
              onChange={event => setNewPhoneNumber(event.target.value)}
              style={{ width: '60%' }}
            />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Button
                onClick={handleAddNewPhone}
                color="primary"
                disabled={
                  !newPhoneNumber || !isValidPhoneNumber(newPhoneNumber)
                }
              >
                <CheckIcon style={{ color: 'green' }} />
              </Button>
              <Button
                onClick={() => setIsAddingNewPhone(false)}
                color="primary"
              >
                <CloseIcon />
              </Button>
            </div>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          style={{
            ...theme.actions.confirm,
            marginTop: '10px',
            marginBottom: '10px',
          }}
          disabled={!selectedPhoneNumber && !selectedEmail}
          onClick={async () => {
            try {
              // adding email identity
              if (selectedEmail && selectedEmail !== '+ Add new email') {
                await addIdentityMutation({
                  variables: {
                    identity: {
                      customer_id: opportunity?.customer_id,
                      identity_type: 'email',
                      identity_value: selectedEmail,
                    },
                  },
                });
              } else {
                onClose();
              }
              // adding phone identity
              if (
                selectedPhoneNumber &&
                selectedPhoneNumber !== '+ Add new phone'
              ) {
                await addIdentityMutation({
                  variables: {
                    identity: {
                      customer_id: opportunity?.customer_id,
                      identity_type: 'sms',
                      identity_value: selectedPhoneNumber,
                    },
                  },
                });
              } else {
                onClose();
              }
            } catch (e) {}
            onClose();
          }}
          variant="contained"
        >
          SAVE & CONTINUE
        </Button>
        <Button
          onClick={() => {
            onClose();
            setPhoneNumbers(initialPhoneNumbers);
            setEmails(initialEmails);
            setSelectedEmail('');
          }}
          color="secondary"
          variant="contained"
        >
          CANCEL & CONTINUE
        </Button>
      </DialogActions>
      <DialogActions style={{ marginBottom: '10px' }}>
        <Button
          onClick={() => {
            onClose();
            setPhoneNumbers(initialPhoneNumbers);
            setEmails(initialEmails);
            setSelectedEmail('');
            enqueueSnackbar(
              'Cancelling the sending of the opportunity to the deal log!',
              {
                variant: 'warning',
              },
            );
            setShowPendingModal(false);
          }}
          variant="outlined"
        >
          CLOSE
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomerIdentityEditorModal;
