// External
import React from 'react';

import { useDealerContext } from 'components/MaterialUI/DealerContext';
import DealerPicker from 'components/MaterialUI/DealerPicker';
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';
import { useUserContext } from 'components/MaterialUI/UserContext';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useSnackbar } from 'notistack';

// Material UI
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { useTheme } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import TableRow from '@mui/material/TableRow';
import DeleteIcon from '@mui/icons-material/Delete';

const PAGE_SIZE = 100;

const TASK_TEMPLATE_QRY = gql`
  query TaskTemplateForDealer(
    $dealer_id: Int
    $page: Int
    $page_size: Int
    $organization_id: ID!
  ) {
    taskTemplates(
      dealer_id: $dealer_id
      page: $page
      page_size: $page_size
      organization_id: $organization_id
    ) {
      count
      task_templates {
        _id
        organization_id
        dealer_id
        label
        trigger
        task_description
        task_type
        date_offset
        create_in_series
        task_recurrence {
          frequency_units
          frequency
          duration_units
          duration
        }
      }
    }
  }
`;

const DELETE_TASK_TEMPLATE = gql`
  mutation DeleteTaskTemplate($id: ID!) {
    deleteTaskTemplate(id: $id) {
      _id
    }
  }
`;

const TaskTemplates = () => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const theme = useTheme();
  const { currentUser } = useUserContext();
  const { dealerId, dealers } = useDealerContext();

  const { data, loading, refetch } = useQuery(TASK_TEMPLATE_QRY, {
    variables: {
      dealer_id: dealerId,
      page_size: PAGE_SIZE,
      page: 1,
      organization_id: currentUser?.organization_id,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const [deleteTemplate] = useMutation(DELETE_TASK_TEMPLATE, {
    onCompleted: () =>
      enqueueSnackbar('Deleted Template Successfully', { variant: 'success' }),
    onError: e =>
      enqueueSnackbar(`An Error Occurred: ${e}`, { variant: 'error' }),
  });

  const handleDeleteTemplate = id => {
    deleteTemplate({ variables: { id: id } });
    refetch();
  };

  return (
    <Box m={1}>
      <Box>
        <DealerPicker />
      </Box>
      <Grid
        container
        direction="row"
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Grid
          item
          xs={12}
          style={{
            paddingRight: '1rem',
            paddingTop: '1rem',
            paddingBottom: '1rem',
          }}
        >
          <Typography style={{ fontSize: '20px', padding: '1rem' }}>
            {`Task Templates for ${
              dealers.filter(obj => obj.dealer_id === dealerId)[0]?.dealer_name
            }`}
          </Typography>
        </Grid>
        <Grid
          item
          style={{
            paddingRight: '1rem',
            paddingTop: '1rem',
            paddingBottom: '1rem',
          }}
        >
          <Tooltip title="Create Task Template">
            <Fab
              color="primary"
              onClick={() => history.push('/tasks/templates/create')}
              size="medium"
              style={theme.actions.confirm}
            >
              <AddIcon />
            </Fab>
          </Tooltip>
        </Grid>
        {loading && <LoadingBackdrop />}
        {!loading && (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Actions</TableCell>
                  <TableCell>Rule</TableCell>
                  <TableCell>Trigger</TableCell>
                  <TableCell>Due Date</TableCell>
                  <TableCell>Recurring</TableCell>
                  <TableCell>Task Type</TableCell>
                  <TableCell>Assign To</TableCell>
                  <TableCell>Task</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(data?.taskTemplates?.task_templates || []).map(
                  (task, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          <Grid item>
                            <IconButton
                              onClick={() =>
                                history.push(
                                  `/tasks/templates/create/${task._id}`,
                                )
                              }
                              size="large"
                            >
                              <EditIcon style={theme.actions.add} />
                            </IconButton>
                          </Grid>
                          <Grid item>
                            <IconButton
                              onClick={handleDeleteTemplate.bind(
                                this,
                                task._id,
                              )}
                              size="large"
                            >
                              <DeleteIcon style={theme.actions.add} />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell>{task?.label}</TableCell>
                      <TableCell>{task?.trigger}</TableCell>
                      <TableCell>
                        {moment.duration(task?.date_offset).humanize()}
                      </TableCell>
                      <TableCell>
                        {task?.create_in_series ? 'Yes' : 'No'}
                      </TableCell>
                      <TableCell>{task?.task_type}</TableCell>
                      <TableCell>{task?.task_type}</TableCell>
                      <TableCell>{task?.task_description}</TableCell>
                    </TableRow>
                  ),
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Grid>
    </Box>
  );
};

export default TaskTemplates;
