export const COST_SORT = { model: 'Vehicle', field: 'cost' };
export const PHOTO_COUNT_SORT = { model: 'Vehicle', field: 'photoCount' };
export const ID_SORT = { model: 'Vehicle', field: 'id' };
export const STOCK_NUMBER_SORT = { model: 'Vehicle', field: 'stockNumber' };
export const SPECIAL_PRICE_SORT = { model: 'Vehicle', field: 'specialPrice' };
export const DAYS_SORT = { model: 'Vehicle', field: 'daysInStock' };
export const UPDATED_SORT = { model: 'Vehicle', field: 'modifiedAt' };
export const YEAR_SORT = { model: 'Vehicle', field: 'year' };
export const MAKE_SORT = { model: 'Vehicle', field: 'makeName' };
export const MODEL_SORT = { model: 'Vehicle', field: 'modelName' };
export const MANUFACTURER_SORT = {
  model: 'Vehicle',
  field: 'manufacturerName',
};
export const PRICE_SORT = { model: 'Vehicle', field: 'regularPrice' };

export const VEHICLE_SORT_OPTIONS = [
  { ...YEAR_SORT, direction: 'asc' },
  { ...YEAR_SORT, direction: 'desc' },
  { ...MAKE_SORT, direction: 'asc' },
  { ...MAKE_SORT, direction: 'desc' },
  { ...MODEL_SORT, direction: 'asc' },
  { ...MODEL_SORT, direction: 'desc' },
  { ...ID_SORT, direction: 'asc' },
  { ...ID_SORT, direction: 'desc' },
];
