/* external */
import moment from 'moment';
import React, { useState } from 'react';
import gql from 'graphql-tag';

import { Box, Button, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import LaptopIcon from '@mui/icons-material/Laptop';
import RedeemIcon from '@mui/icons-material/Redeem';

/* internal */
import { REDEMPTION_OPCODES } from '../../constant';
import RedemptionHistory from './RedemptionHistory';

const useStyles = makeStyles(() => ({
  green: {
    backgroundColor: '#dff0d8',
    color: '#468847',
  },
  yellow: {
    backgroundColor: '#fcf8e3',
    color: '#c09853',
  },
  red: {
    backgroundColor: '#f2dede',
    color: '#b94a48',
  },
  tile: {
    fontWeight: 'bold',
    width: '90%',
    height: '100%',
    textAlign: 'center',
    border: '1px solid #fbeed5',
    borderRadius: '4px',
    paddingBottom: '1em',
    textTransform: 'none',
  },
}));

const RedemptionTiles = ({ goldCard }) => {
  const classes = useStyles();
  const [showDetailingModal, setShowDetailingModal] = useState(false);
  const [showWindshieldModal, setShowWindshieldModal] = useState(false);
  const [showAlignmentModal, setShowAlignmentModal] = useState(false);
  const [redemptionName, setRedemptionName] = useState('');

  const detailingHistory = goldCard.redemptions
    ?.filter(
      redemption => redemption.roLineOpcode === REDEMPTION_OPCODES.DETAILING,
    )
    .sort((a, b) => moment(b.roDate).diff(moment(a.roDate)));
  const windshieldHistory = goldCard.redemptions
    ?.filter(
      redemption => redemption.roLineOpcode === REDEMPTION_OPCODES.WINDSHIELD,
    )
    .sort((a, b) => moment(b.roDate).diff(moment(a.roDate)));
  const tireHistory = goldCard.redemptions
    ?.filter(
      redemption => redemption.roLineOpcode === REDEMPTION_OPCODES.ALIGNMENT,
    )
    .sort((a, b) => moment(b.roDate).diff(moment(a.roDate)));

  const checkRedemptionType = () => {
    if (showDetailingModal) {
      return detailingHistory;
    }
    if (showWindshieldModal) {
      return windshieldHistory;
    }
    if (showAlignmentModal) {
      return tireHistory;
    }
    return null;
  };

  const isDetailingBenefitUsed = goldCard.detailingTotalThisYear > 0;

  return (
    <>
      <RedemptionHistory
        title={redemptionName}
        open={showDetailingModal || showWindshieldModal || showAlignmentModal}
        onClose={() => {
          setShowDetailingModal(false);
          setShowWindshieldModal(false);
          setShowAlignmentModal(false);
        }}
        redemptions={checkRedemptionType()}
      />
      <Grid container style={{ padding: '1em' }}>
        <Grid item xs={3}>
          <Button
            className={
              isDetailingBenefitUsed
                ? `${classes.red} ${classes.tile}`
                : `${classes.green} ${classes.tile}`
            }
            style={{ paddingTop: '2em' }}
            onClick={() => {
              setRedemptionName('Detailing Replacement History:');
              setShowDetailingModal(true);
            }}
          >
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="caption" style={{ fontSize: '8' }}>
                  Detailing
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <>
                  <AvTimerIcon fontSize="large" />
                  <Typography variant="h6" style={{ fontSize: '12' }}>
                    {goldCard.detailingTotalThisYear > 0 ? (
                      <strong>{goldCard.detailingTotalThisYear}</strong>
                    ) : (
                      goldCard.detailingTotalThisYear
                    )}{' '}
                    / 1 / Yr.
                  </Typography>
                  <Typography variant="caption" style={{ fontSize: '8' }}>
                    {goldCard.detailingTotal} Total
                  </Typography>
                </>
              </Grid>
            </Grid>
          </Button>
        </Grid>
        <Grid item xs={3}>
          <Button
            className={`${classes.yellow} ${classes.tile}`}
            onClick={() => {
              setRedemptionName('Windshield Replacement History:');
              setShowWindshieldModal(true);
            }}
          >
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="caption" style={{ fontSize: '8' }}>
                  Windshield Replacement
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <>
                  <LaptopIcon fontSize="large" />
                  <Typography variant="h6" style={{ fontSize: '12' }}>
                    {goldCard.windshieldTotal} / *
                  </Typography>
                </>
              </Grid>
            </Grid>
          </Button>
        </Grid>
        <Grid item xs={3}>
          <Button
            className={`${classes.yellow} ${classes.tile}`}
            onClick={() => {
              setRedemptionName('Wheel Alignment History:');
              setShowAlignmentModal(true);
            }}
          >
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="caption" style={{ fontSize: '8' }}>
                  Wheel Alignment
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <>
                  <DirectionsCarIcon fontSize="large" />
                  <Typography variant="h6" style={{ fontSize: '12' }}>
                    {goldCard.alignmentTotal} / *
                  </Typography>
                </>
              </Grid>
            </Grid>
          </Button>
        </Grid>
        <Grid item xs={3}>
          <Box
            className={
              goldCard.klondikeDiscountRedeemed
                ? `${classes.red} ${classes.tile}`
                : `${classes.green} ${classes.tile}`
            }
            style={{ paddingTop: '2em' }}
          >
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="caption" style={{ fontSize: '8' }}>
                  Redeemed Klondike Discount
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <>
                  <RedeemIcon fontSize="large" />
                  <Typography variant="h6" style={{ fontSize: '12' }}>
                    {goldCard.klondikeDiscountRedeemed ? '1 / 1' : '0 / 1'}
                  </Typography>
                </>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

RedemptionTiles.fragments = {
  package: gql`
    fragment RedemptionTilesPackage on Package {
      redemptions {
        roLineOpcode
        roDate
      }
      detailingTotalThisYear
      detailingTotal
      windshieldTotal
      alignmentTotal
      klondikeDiscountRedeemed
    }
  `,
};

export default RedemptionTiles;
