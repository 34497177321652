// External
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';

// Material UI
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

// Internal
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import DealerPicker from 'components/MaterialUI/DealerPicker';
import Loading from 'components/MaterialUI/Loading';
import { useUserContext } from 'components/MaterialUI/UserContext';

import { usePersistedState } from 'utils';

import { TaskStatus, TaskStatusColor } from '../constants';
import {
  DASHBOARD_QUERY,
  GET_DASHBOARD_USERS_QUERY,
  UPDATE_TASK_QUERY,
} from './queries';
import SalesDashboardDesktop from './SalesDashboardDesktop';
import SalesDashboardMobile from './SalesDashboardMobile';

const useStyles = makeStyles(theme => ({
  buttonColor: {
    color: '#FFFFFF',
  },
}));

const statusColor = (status, due_date) => {
  const overdue = moment(due_date) < moment();
  const highLightColor =
    (overdue && TaskStatusColor.RED) ||
    (status === TaskStatus.TODO && TaskStatusColor.YELLOW) ||
    TaskStatusColor.GREY;
  return highLightColor;
};

const ManagerOverviewDashboard = () => {
  const { dealerId, dealers } = useDealerContext();
  const { currentUser } = useUserContext();
  const { buttonColor } = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const isDesktop = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const [assignee, setAssignee] = useState('none');
  const [displayName, setDisplayName] = useState('');

  const [dateFromFilter, setDateFromFilter] = usePersistedState(
    'salesDashboard.dateFromFilter',
    moment().startOf('month').format('YYYY-MM-DD'),
  );

  const [dateToFilter, setDateToFilter] = usePersistedState(
    'salesDashboard.dateToFilter',
    moment().format('YYYY-MM-DD'),
  );

  const handleDateFromChange = date => {
    const newFromDate =
      dateToFilter > moment(date).format('YYYY-MM-DD')
        ? moment(date).format('YYYY-MM-DD')
        : moment(dateToFilter).subtract(1, 'days').format('YYYY-MM-DD');
    setDateFromFilter(newFromDate);
    refetch({ date_from: newFromDate });
  };

  const handleDateToChange = date => {
    const newToDate =
      dateFromFilter < moment(date).format('YYYY-MM-DD')
        ? moment(date).format('YYYY-MM-DD')
        : moment(dateFromFilter).add(1, 'days').format('YYYY-MM-DD');
    setDateToFilter(newToDate);
    refetch({ date_to: newToDate });
  };

  const dealerName = dealers.find(x => x.dealer_id === dealerId)?.dealer_name;

  const { loading: userLoading, data: userData } = useQuery(
    GET_DASHBOARD_USERS_QUERY,
    {
      variables: {
        dealerId,
      },
    },
  );

  const users = userData?.users ?? [];
  const sortedUsers = users.sort((a, b) =>
    a.display_name.localeCompare(b.display_name),
  );

  const [getDashboard, { called, loading, data, refetch }] =
    useLazyQuery(DASHBOARD_QUERY);

  const [updateTask] = useMutation(UPDATE_TASK_QUERY, {
    onCompleted: data => {
      enqueueSnackbar('Task updated', { variant: 'success' });
      refetch();
    },
    onError: err => {
      enqueueSnackbar(`Could not update task error: ${err}`, {
        variant: 'error',
      });
    },
  });

  const handleUpdateStatus = (_id, status) => {
    updateTask({
      variables: {
        _id,
        input: {
          status: status,
        },
      },
    });
  };

  const handleChange = event => {
    setAssignee(event.target.value);
    const selectedUser = users.filter(
      user => user.username === event.target.value,
    );
    setDisplayName(selectedUser[0]?.display_name ?? currentUser.display_name);
    if (!called) {
      getDashboard({
        variables: {
          dealerId,
          assignee: event.target.value,
          date_from: dateFromFilter,
          date_to: dateToFilter,
        },
      });
    } else {
      // If assignee is None, refetch without an assignee variable
      const refetchUser =
        event.target.value !== 'none' ? event.target.value : undefined;
      refetch({ dealerId, assignee: refetchUser });
    }
  };

  if (userLoading)
    return <Loading text="Loading Your Dashboard! Please wait." />;

  return (
    <Box padding={1}>
      <Box>
        <DealerPicker />
      </Box>
      <Box paddingTop={4} paddingLeft={1}>
        <Typography variant="body2">
          To view one of your staff's dashboards, please select from the below
          drop down menu
        </Typography>
      </Box>
      <Box padding={1}>
        <FormControl style={{ minWidth: 240 }}>
          <Select variant="outlined" value={assignee} onChange={handleChange}>
            <MenuItem value="none">Select Assignee</MenuItem>
            {sortedUsers.map(user => (
              <MenuItem key={user.username} value={user.username}>
                {user.display_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box>
        {displayName !== '' && (
          <Box paddingTop={2} paddingLeft={1}>
            <Typography variant="h4" style={{ fontWeight: 'bold' }}>
              {`${displayName}'s Dashboard`}
            </Typography>
          </Box>
        )}
        {loading && <Loading text="Loading Dashboard! Please wait..." />}
        {isDesktop && called && !loading && (
          <SalesDashboardDesktop
            data={data}
            loading={loading}
            handleUpdateStatus={handleUpdateStatus}
            dealerName={dealerName}
            buttonColor={buttonColor}
            statusColor={statusColor}
            dealerId={dealerId}
            currentUser={currentUser}
            refetch={refetch}
            selectedUser={assignee !== 'none' ? assignee : currentUser.username}
            dateFromFilter={dateFromFilter}
            dateToFilter={dateToFilter}
            handleDateFromChange={handleDateFromChange}
            handleDateToChange={handleDateToChange}
          />
        )}
        {!isDesktop && called && !loading && (
          <SalesDashboardMobile
            data={data}
            loading={loading}
            handleUpdateStatus={handleUpdateStatus}
            dealerName={dealerName}
            buttonColor={buttonColor}
            statusColor={statusColor}
            dealerId={dealerId}
            currentUser={currentUser}
            refetch={refetch}
            selectedUser={assignee !== 'none' ? assignee : currentUser.username}
            dateFromFilter={dateFromFilter}
            dateToFilter={dateToFilter}
            handleDateFromChange={handleDateFromChange}
            handleDateToChange={handleDateToChange}
          />
        )}
      </Box>
    </Box>
  );
};

export default ManagerOverviewDashboard;
