import React, { useState } from 'react';

/* external */
import gql from 'graphql-tag';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useMutation, useQuery } from '@apollo/react-hooks';

/* Material UI */
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';

/* internal */
import Loading from 'components/MaterialUI/Loading';
import { TASK_ROLES } from '../constants';

const USERS_FOR_DEALER = gql`
  query UsersForDealer($dealerIds: [Int]!) {
    users: users(dealer_ids: $dealerIds, status: active, roles: [${TASK_ROLES.map(
      x => `"${x}"`,
    ).join(',')}]) {
      display_name
      username
    }
  }
`;

const ADD_TASK_FOR_OPP = gql`
  mutation createTaskForOpp($input: TaskInput!) {
    createTask(input: $input) {
      _id
    }
  }
`;

const OpportunityTasksModal = ({
  handleClose,
  onSave,
  opportunity,
  dealerId,
}) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [description, setDescription] = useState('');
  const [assignee, setAssignee] = useState(
    opportunity?.sales_reps.length !== 0
      ? opportunity.sales_reps[0].username
      : 'Assign Task',
  );
  const [selectedDate, setSelectedDate] = useState(moment());
  const { data, loading } = useQuery(USERS_FOR_DEALER, {
    variables: {
      dealerIds: [dealerId],
    },
  });

  const [createTask] = useMutation(ADD_TASK_FOR_OPP, {
    onCompleted: () => {
      enqueueSnackbar('Added task to opportunity');
      handleClose();
      onSave();
    },
    onError: err => enqueueSnackbar(`An error occurred: ${err}`),
  });

  const handleDescription = event => {
    setDescription(event.target.value);
  };

  const handleAssignee = event => {
    setAssignee(event.target.value);
  };

  const handleDate = date => {
    setSelectedDate(date);
  };

  const addTask = () => {
    if (assignee === 'Assign Task') {
      enqueueSnackbar('You must select an assignee');
    } else {
      const input = {
        assignee: assignee,
        customer_id: opportunity.customer._id,
        due_date: selectedDate,
        description: description,
        type: 'opportunity',
        organization_id: opportunity.organization_id,
        status: 0,
        dealer_id: opportunity.dealer_id,
        opportunity_id: opportunity._id,
      };
      createTask({ variables: { input } });
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <DialogTitle>Add a Task</DialogTitle>
      <DialogContent>
        <Grid container direction="row">
          <Grid item xs>
            <FormControl>
              <InputLabel shrink>Assignee</InputLabel>
              <Select value={assignee} onChange={handleAssignee}>
                <MenuItem value="Assign Task">Assign Task</MenuItem>
                {data.users.map(user => (
                  <MenuItem key={user.username} value={user.username}>
                    {user.display_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs>
            <DateTimePicker
              autoOk
              variant="inline"
              format="YYYY-MM-DD, hh:mm a"
              id="date-picker-inline"
              label="Due Date"
              value={selectedDate}
              onChange={handleDate}
              minDate={moment()}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogContent>
        <TextField
          label="Task Description"
          value={description}
          onChange={handleDescription}
          multiline
          maxRows={8}
          style={{ display: 'flex' }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="outlined">
          Cancel
        </Button>
        <Button
          style={theme.actions.confirm}
          onClick={addTask}
          variant="contained"
          color="primary"
        >
          Save
        </Button>
      </DialogActions>
    </>
  );
};

export default OpportunityTasksModal;
