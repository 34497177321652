import React from 'react';
import { isEmpty } from 'lodash';

// MaterialUI
import { Box, Grid } from '@mui/material';

// internal
import { formatPrice, getMediumDateTimeWithWeekday } from 'utils';
import { COLOUR_MATCH } from '../const';

const Pending = ({ vehicle }) => {
  const { myBid, status, bidResponseDueAt } = vehicle;
  return (
    <>
      <Grid
        container
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingBottom: '1rem',
        }}
        direction="row"
        xs={12}
      >
        <Grid
          item
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
          xs={6}
        >
          <Box>Bidding has now ended.</Box>
        </Grid>
        <Grid
          item
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
          xs={6}
        >
          <Box style={{ color: 'grey' }}>
            Your Bid:{' '}
            {isEmpty(myBid) ? "You Haven't Bid Yet" : formatPrice(myBid.amount)}
          </Box>
        </Grid>
      </Grid>
      <Box
        style={{
          fontWeight: 'bold',
          paddingRight: '1rem',
          paddingBottom: '0.5rem',
        }}
      >
        CUSTOMER RESPONSE -{' '}
        <span style={{ color: COLOUR_MATCH[status].backgroundColor }}>
          {status}
        </span>
      </Box>
      <Box
        style={{
          paddingRight: '1rem',
          paddingBottom: '0.5rem',
        }}
      >
        Customer has until{' '}
        {bidResponseDueAt &&
          getMediumDateTimeWithWeekday(bidResponseDueAt + 'Z')}{' '}
        to respond.
      </Box>
    </>
  );
};
export default Pending;
