import React from 'react';
import sortBy from 'lodash.sortby';
import MaterialTable from 'material-table';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import gql from 'graphql-tag';

const RODetailsTable = ({ ro, isDesktop }) => {
  const columns = [
    { title: 'Seq', field: 'sequence_number' },
    { title: 'Op Code', field: 'op_code' },
    {
      title: 'Description',
      field: 'service_request',
      render: d => d.service_request || d.op_code_description,
    },
    { title: 'Tech', field: 'tech_number' },
  ];
  const rows = sortBy(ro.details || [], ['sequence_number']);

  const tableOptions = {
    pageSize: rows.length,
    pageSizeOptions: [{ value: -1, label: 'All' }],
    filtering: false,
  };

  return (
    <Paper>
      <Container maxWidth="xl">
        <Box paddingTop={2} style={{ fontSize: '18px', fontWeight: 'bold' }}>
          Service RO Details
        </Box>
        {isDesktop && (
          <>
            <Grid
              container
              direction="row"
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
              xs={12}
            >
              <Grid
                item
                style={{
                  paddingTop: '1rem',
                  paddingBottom: '1rem',
                  fontSize: '14px',
                }}
                xs={6}
              >
                <Box>
                  <b>Vehicle: </b>{' '}
                  {` ${ro.vehicle_year} ${ro.vehicle_make} ${ro.vehicle_model}`}
                </Box>
                <Box>
                  <b>VIN: </b>
                  {ro.vin}
                </Box>
              </Grid>
              <Grid
                item
                style={{ paddingTop: '1rem', fontSize: '14px' }}
                xs={6}
              >
                <b>Service Advisor: </b>{' '}
                {` ${
                  ro.serviceAdvisor ? ro.serviceAdvisor.display_name : 'N/A'
                }`}
              </Grid>
            </Grid>
            <Box pb={3}>
              <MaterialTable
                title="RO Details"
                data={rows}
                columns={columns}
                options={tableOptions}
                localization={{
                  body: {
                    emptyDataSourceMessage: (
                      <Box style={{ padding: '1rem' }}>
                        <h1>There are no records for this vehicle.</h1>
                      </Box>
                    ),
                  },
                }}
              />
            </Box>
          </>
        )}
        {!isDesktop && (
          <>
            <Box style={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
              <Card>
                <CardContent>
                  <Grid
                    container
                    direction="row"
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                    }}
                    xs={12}
                  >
                    <Grid
                      item
                      style={{ padding: '1rem', fontSize: '14px' }}
                      xs={12}
                    >
                      <b>Vehicle: </b>{' '}
                      {` ${ro.vehicle_year} ${ro.vehicle_make} ${ro.vehicle_model}`}
                    </Grid>
                    <Grid
                      item
                      style={{ padding: '1rem', fontSize: '14px' }}
                      xs={12}
                    >
                      <b>Service Advisor: </b>{' '}
                      {` ${
                        ro.serviceAdvisor
                          ? ro.serviceAdvisor.display_name
                          : null
                      }`}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Box>
            {rows.map(row => (
              <Box style={{ paddingBottom: '1rem' }} key={row.sequence_number}>
                <Card>
                  <CardContent>
                    <Grid
                      container
                      direction="row"
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}
                      xs={12}
                    >
                      <Grid item style={{ padding: '1rem' }} xs={7}>
                        <Box pb={1}>
                          <b>Op Code: </b> {` ${row.op_code}`}
                        </Box>
                        <Box pb={1}>
                          <b>Tech No: </b> {` ${row.tech_number}`}
                        </Box>
                      </Grid>
                      <Grid item style={{ padding: '1rem' }} xs={5}>
                        <b>Sequence: </b> {` ${row.sequence_number}`}
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}
                      xs={12}
                    >
                      <Grid item xs={12}>
                        <Box pb={1}>
                          <b>Description: </b>{' '}
                          {` ${row.service_request || row.op_code_description}`}
                        </Box>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
            ))}
          </>
        )}
      </Container>
    </Paper>
  );
};

RODetailsTable.fragments = {
  ro: gql`
    fragment RODetailsTableRO on ROTrackerRO {
      vehicle_year
      vehicle_make
      vehicle_model
      vin
      serviceAdvisor {
        display_name
      }
      details {
        id
        sequence_number
        service_request
        op_code_description
        op_code
        tech_number
      }
    }
  `,
};

export default RODetailsTable;
