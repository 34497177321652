import React, { useEffect, useState } from 'react';

/* external */
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';

/* Material UI */
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import {
  Badge,
  Box,
  Fab,
  Grid,
  IconButton,
  Step,
  StepButton,
  StepContent,
  TextField,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import AddIcon from '@mui/icons-material/Add';

/* internal */
import { cloudinaryConfig } from 'constants.js';
import { cloudinaryCore } from 'utils';
import { errorHandler } from '../utils';
import carIMG from '../../static/img/car_image.jpg';
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';
import StepActions from './StepActions';

const useStyles = makeStyles(theme => ({
  absoluteButton: {
    color: 'green',
    fill: 'white',
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    msTransform: 'translate(-50%, -50%)',
    zIndex: 2,
  },
  opaque: {
    backgroundColor: 'white',
  },
}));

const photoType_NAMES = {
  front: 'Front',
  'driver-side': 'Driver Side',
  rear: 'Rear',
  'passenger-side': 'Passenger Side',
  extra: 'Extra Photo',
};

const CREATE_PHOTOS = gql`
  mutation createPhotos($photos: [AppraisalPhotoInput]) {
    appraisals {
      createPhotos(photos: $photos) {
        id
        appraisalId
        cloudinaryPublicId
        description
        md5
        photoType
      }
    }
  }
`;

const DELETE_PHOTO = gql`
  mutation deletePhoto($id: Int!) {
    appraisals {
      deletePhoto(id: $id) {
        id
      }
    }
  }
`;

const DELETE_CLOUDINARY_PHOTO = gql`
  mutation deleteCloudinaryPhoto($cloudinaryPublicId: String!) {
    appraisals {
      deleteCloudinaryPhoto(cloudinaryPublicId: $cloudinaryPublicId)
    }
  }
`;

const UPDATE_PHOTO = gql`
  mutation updatePhoto($id: Int!, $data: AppraisalPhotoInput!) {
    appraisals {
      updatePhoto(id: $id, data: $data) {
        id
        description
      }
    }
  }
`;

const StepFour = ({
  appraisal,
  label,
  onBack,
  onNext,
  setActiveStep,
  setIsDirty,
  stepDirty,
  ...rest
}) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { absoluteButton, opaque } = useStyles();
  const { photoUploadParams, photos = [] } = appraisal;
  const [photoList, setPhotoList] = useState(photos);
  const formatPhoto = (photo, options = {}) =>
    photo
      ? cloudinaryCore.url(photo.cloudinaryPublicId, {
          width: options.width || 100,
          height: options.height || 100,
          crop: 'pad',
          background: 'black',
        })
      : '';

  const hasRear = photoList.map(x => x.photoType).includes('rear');
  const hasFront = photoList.map(x => x.photoType).includes('front');
  const hasDriverSide = photoList.map(x => x.photoType).includes('driver-side');
  const hasPassengerSide = photoList
    .map(x => x.photoType)
    .includes('passenger-side');

  const [createPhotos, { loading: creatingPhotos }] =
    useMutation(CREATE_PHOTOS);
  const [updatePhoto, { loading: updatingPhoto }] = useMutation(UPDATE_PHOTO);
  const [deletePhoto, { loading: deletingPhoto }] = useMutation(DELETE_PHOTO);
  const [deleteCloudinaryPhoto, { loading: deletingCloudinaryPhoto }] =
    useMutation(DELETE_CLOUDINARY_PHOTO);

  useEffect(() => setPhotoList(appraisal?.photos || []), [appraisal]);

  const handleAddDescription = _md5 => event => {
    setPhotoList(
      photoList.map(({ md5, description, ...rest }) =>
        md5 === _md5
          ? { md5, description: event.target.value, dirty: true, ...rest }
          : { md5, description, ...rest },
      ),
    );
  };

  const uploadWidget = (
    { apiKey, folder, signature, timestamp, uploadPreset },
    photoType,
    multiple = false,
  ) => {
    const widget = window.cloudinary.createUploadWidget(
      {
        cloudName: cloudinaryConfig.cloud_name,
        apiKey,
        uploadSignature: signature,
        uploadSignatureTimestamp: timestamp,
        folder,
        uploadPreset,
        maxFileSize: 5 * 1000 * 1000, // 5MB
        clientAllowedFormats: ['png', 'jpg', 'jpeg', 'jfif'],
        maxImageWidth: 1600,
        maxImageHeight: 1200,
        multiple,
      },
      (error, result) => {
        if (!error && result && result.event === 'success') {
          setPhotoList(prev => [
            ...prev,
            {
              cloudinaryPublicId: result.info.public_id,
              md5: result.info.etag,
              photoType,
            },
          ]);
        }
      },
    );
    widget.open();
  };

  const handleNext = () => {
    const dirtyList = photoList.filter(p => p.dirty === true && p.id);
    createPhotos({
      variables: {
        photos: photoList
          .filter(p => !p.id)
          .map(({ dirty, ...photo }) => ({
            appraisalId: appraisal.id,
            ...photo,
          })),
      },
    }).then(
      r => {
        // Update any photos in the photo list with their ID.
        const newPhotos = r.data.appraisals.createPhotos;
        setPhotoList(prev =>
          prev.map(x =>
            x.id
              ? x
              : newPhotos.find(
                  newPhoto =>
                    newPhoto.cloudinaryPublicId === x.cloudinaryPublicId,
                ),
          ),
        );
        onNext();
      },
      err => errorHandler(enqueueSnackbar, () => {})(err),
    );
    dirtyList.forEach(({ dirty, id, __typename, ...data }) =>
      updatePhoto({
        variables: { id, data: { appraisalId: appraisal.id, ...data } },
      }).then(r =>
        setPhotoList(prev =>
          prev.map(({ dirty, ...x }) =>
            x.id === id ? { ...x, dirty: false } : { ...x, dirty },
          ),
        ),
      ),
    );
  };

  // Generates a handler to remove a photo
  const removePhotoHandler =
    ({ id, cloudinaryPublicId }) =>
    () => {
      if (id)
        deletePhoto({ variables: { id } }).then(
          () => setPhotoList(prev => prev.filter(x => x.id !== id)),
          err => errorHandler(enqueueSnackbar, () => {})(err),
        );
      else
        deleteCloudinaryPhoto({ variables: { cloudinaryPublicId } }).then(
          () =>
            setPhotoList(prev =>
              prev.filter(x => x.cloudinaryPublicId !== cloudinaryPublicId),
            ),
          err => errorHandler(enqueueSnackbar, () => {})(err),
        );
    };

  const isDirty = photoList.some(x => x.dirty || !x.id);

  useEffect(() => {
    if (isDirty && !stepDirty) setIsDirty();
    else {
      if (!isDirty && stepDirty) setIsDirty(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty, setIsDirty]);

  const loading =
    creatingPhotos || deletingPhoto || deletingCloudinaryPhoto || updatingPhoto;

  return (
    <Step {...rest} key={label}>
      <StepButton disabled={!appraisal.id} onClick={() => setActiveStep(3)}>
        {label}
      </StepButton>
      <StepContent>
        <LoadingBackdrop open={loading} />
        <Grid container direction="row">
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              flexDirection: 'row',
              alignItems: 'flex-end',
              width: '100%',
            }}
          >
            <Grid item xs={12} md={6}>
              <Box style={{ position: 'relative' }}>
                <img
                  alt="carImage"
                  style={{
                    width: '100%',
                    height: 'auto',
                    objectFit: 'cover',
                  }}
                  src={carIMG}
                />

                <IconButton
                  className={absoluteButton}
                  disabled={hasDriverSide}
                  onClick={() => uploadWidget(photoUploadParams, 'driver-side')}
                  style={{
                    top: '5%',
                    left: '50%',
                  }}
                  size="large"
                >
                  <AddBoxIcon className={opaque} viewBox="3 3 18 18" />
                </IconButton>

                <IconButton
                  className={absoluteButton}
                  disabled={hasPassengerSide}
                  onClick={() =>
                    uploadWidget(photoUploadParams, 'passenger-side')
                  }
                  style={{
                    top: '5%',
                    left: '60%',
                  }}
                  size="large"
                >
                  <AddBoxIcon className={opaque} viewBox="3 3 18 18" />
                </IconButton>

                <IconButton
                  className={absoluteButton}
                  disabled={hasFront}
                  onClick={() => uploadWidget(photoUploadParams, 'front')}
                  style={{
                    top: '50%',
                    left: '7%',
                  }}
                  size="large"
                >
                  <AddBoxIcon className={opaque} viewBox="3 3 18 18" />
                </IconButton>

                <IconButton
                  className={absoluteButton}
                  disabled={hasRear}
                  onClick={() => uploadWidget(photoUploadParams, 'rear')}
                  style={{
                    top: '50%',
                    left: '94%',
                  }}
                  size="large"
                >
                  <AddBoxIcon className={opaque} viewBox="3 3 18 18" />
                </IconButton>
              </Box>
            </Grid>
          </div>
          <Grid container style={{ paddingTop: '15px' }}>
            {photoList
              .filter(p => p.photoType !== 'extra')
              .map(photo => (
                <Grid
                  item
                  xs={6}
                  md={2}
                  key={JSON.stringify(photo.id + photo.md5)}
                >
                  <div>
                    {photoType_NAMES[photo.photoType] || 'Unknown Type'}
                  </div>
                  <Badge
                    overlap="rectangular"
                    badgeContent={
                      <IconButton
                        onClick={removePhotoHandler(photo)}
                        size="large"
                      >
                        <CancelIcon />
                      </IconButton>
                    }
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    <img
                      alt={photo.id}
                      style={{
                        objectFit: 'cover',
                        height: '100px',
                        width: '100px',
                        marginBottom: '20px',
                        zIndex: 1,
                      }}
                      src={formatPhoto(photo)}
                    />
                  </Badge>
                </Grid>
              ))}
          </Grid>
          <Grid container style={{ paddingTop: '15px' }}>
            {photoList
              .filter(p => p.photoType === 'extra')
              .map(photo => (
                <>
                  <Grid container direction="row">
                    <Grid item key={JSON.stringify(photo.id + photo.md5)}>
                      <div>
                        {photoType_NAMES[photo.photoType] || 'Unknown Type'}
                      </div>
                      <Badge
                        overlap="rectangular"
                        badgeContent={
                          <IconButton
                            onClick={removePhotoHandler(photo)}
                            size="large"
                          >
                            <CancelIcon />
                          </IconButton>
                        }
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                      >
                        <img
                          alt={photo.id}
                          style={{
                            objectFit: 'cover',
                            height: '100px',
                            width: '100px',
                            marginBottom: '20px',
                            zIndex: 1,
                          }}
                          src={formatPhoto(photo)}
                        />
                      </Badge>
                    </Grid>
                    <Grid
                      style={{
                        marginLeft: '1rem',
                        display: 'flex',
                        marginTop: 'auto',
                        marginBottom: 'auto',
                      }}
                    >
                      <TextField
                        label="Description"
                        value={photo.description}
                        onChange={handleAddDescription(photo.md5)}
                      />
                    </Grid>
                  </Grid>
                </>
              ))}
          </Grid>
          <Grid container style={{ paddingTop: '15px', paddingBottom: '15px' }}>
            <Fab
              variant="extended"
              style={theme.actions.confirm}
              onClick={() => uploadWidget(photoUploadParams, 'extra', true)}
            >
              <AddIcon /> Add More Photos
            </Fab>
          </Grid>
        </Grid>
        <StepActions
          onNext={handleNext}
          onBack={onBack}
          nextLabel={isDirty ? 'Save' : 'Next'}
        />
      </StepContent>
    </Step>
  );
};

StepFour.fragments = {
  appraisal: gql`
    fragment StepFourAppraisal on Appraisal {
      photoUploadParams {
        apiKey
        folder
        signature
        timestamp
        uploadPreset
        url
      }
      photos {
        id
        md5
        cloudinaryPublicId
        description
        photoType
      }
    }
  `,
};

export default StepFour;
