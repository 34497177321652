/* External */
import { useLazyQuery, useQuery, useSubscription } from '@apollo/react-hooks';
import { NetworkStatus } from 'apollo-boost';
import gql from 'graphql-tag';
import React, { useState } from 'react';

/* Material UI */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Dialog,
  Fab,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
  Add,
  Close as CloseIcon,
  FilterList,
  ExpandMore as ExpandMoreIcon,
  Search as SearchIcon,
} from '@mui/icons-material';

import ErrorDisplay from 'components/MaterialUI/ErrorDisplay';
import Loading from 'components/MaterialUI/Loading';
import { useUserContext } from 'components/MaterialUI/UserContext';

import { usePersistedState } from 'utils';

/* Internal */
import ConversationList from './ConversationList';
import ConversationListItem from './ConversationListItem';
import CreateChatModal from './CreateChatModal';
import FilterDrawer from './FilterDrawer';
import ActionsMenu from './SMSActions/ActionsMenu';
import { CONVERSATIONS_QUERY } from './queries';

const CONVERSATIONS_SUBSCRIPTION = gql`
  subscription onConversationsUpdates($gatewayId: Int!) {
    conversationsFeed(gateway_id: $gatewayId) {
      id
      conversation_id
      conversation {
        stars {
          conversation_id
          username
        }
        operator_username
        operator_user {
          display_name
          username
        }
        archived
        customer_phone
        ...ConversationListItem
      }
    }
  }
  ${ConversationListItem.fragments.conversation}
`;

const GATEWAY_USERS_QUERY = gql`
  query gatewayUsers($gatewayId: Int!) {
    sms {
      gatewayUsers(id: $gatewayId) {
        display_name
        username
      }
    }
  }
`;

const GATEWAY_QUERY = gql`
  query gatewayQuery($gatewayId: Int!) {
    sms {
      gateway(id: $gatewayId) {
        id
        name
        phone_number
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  fabButton: {
    [theme.breakpoints.down('sm')]: {
      boxShadow:
        '0px 3px 10px -1px rgb(0 0 0 / 80%), 0px 6px 10px 0px rgb(0 0 0 / 30%), 0px 1px 18px 0px rgb(0 0 0 / 12%)',
    },
    [theme.breakpoints.up('sm')]: {
      marginRight: '10px',
    },
  },
  actionsBox: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '15px',
  },
}));

const ConversationListContainer = ({ gatewayId }) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const { fabButton, actionsBox } = useStyles();
  const { currentUser } = useUserContext();
  const [tabIndex, setTabIndex] = usePersistedState(
    'conversationListTabIndex',
    '0',
  );
  const [selectedFilters, setSelectedFilters] = useState({
    date_from: undefined,
    date_to: undefined,
  });
  const [statusFilter, setStatusFilter] = useState('all');
  const [phoneSearch, setPhoneSearch] = useState('');
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
  const [operatorList, setOperatorList] = useState([]); // todo: rename to operatorFilterList or something?
  const [newMessages, setNewMessages] = useState(false);
  const [showNewChatModal, setShowNewChatModal] = useState(false);
  const [selectedChatId, setSelectedChatId] = useState(0);

  const PHONE_SEARCH = { customer_phone: phoneSearch };

  const [editPhoneSearchValue, setEditPhoneSearchValue] = useState(phoneSearch);

  const updatePhoneSearch = input =>
    setPhoneSearch((input ?? editPhoneSearchValue).replace(/\D/g, ''));

  const handleKeyPress = e => e.key === 'Enter' && updatePhoneSearch();

  const CATEGORY_FILTERS = [
    {},
    {
      operators: [currentUser.username],
    },
    { starred: true },
    { archived: true },
  ];

  const OPERATORS_FILTER = {
    operators: [
      ...(CATEGORY_FILTERS[tabIndex].operators ?? []),
      ...operatorList.map(x => x.username),
    ],
  };

  const filters = {
    ...selectedFilters,
    ...CATEGORY_FILTERS[tabIndex],
    ...OPERATORS_FILTER,
    ...PHONE_SEARCH,
  };

  const { refetch, loading, data, error, fetchMore, networkStatus } = useQuery(
    CONVERSATIONS_QUERY,
    {
      fetchPolicy: 'network-only',
      variables: {
        gatewayId,
        page: 1,
        filters,
      },
      notifyOnNetworkStatusChange: true,
    },
  );
  const fetchingMore = networkStatus === NetworkStatus.fetchMore;
  const filtering = networkStatus === NetworkStatus.setVariables;

  const {
    loading: gatewayUsersLoading,
    data: gatewayUsersData,
    error: gatewayUsersError,
  } = useQuery(GATEWAY_USERS_QUERY, {
    variables: {
      gatewayId,
    },
  });

  const {
    loading: gatewayLoading,
    data: gatewayData,
    error: gatewayError,
  } = useQuery(GATEWAY_QUERY, { variables: { gatewayId } });

  const conversationsList = data?.sms.conversations.results ?? [];
  const { page, has_next, total } = data?.sms.conversations.pagination ?? {};
  const conversationQueryVariables = { gatewayId, filters, page };

  const conversationIds = conversationsList.map(x => parseInt(x.id, 10)); // parseInt shouldn't be necessary....

  const [getLazyConversations] = useLazyQuery(CONVERSATIONS_QUERY, {
    fetchPolicy: 'no-cache', // prevent updating the cache automatically
    onCompleted: data =>
      setNewMessages(
        conversationsList?.[0]?.id !== data.sms.conversations.results?.[0]?.id,
      ),
  });

  useSubscription(CONVERSATIONS_SUBSCRIPTION, {
    variables: {
      gatewayId,
    },
    fetchPolicy: 'network-only',
    shouldResubscribe: true,
    onSubscriptionData: ({ subscriptionData }) =>
      conversationIds.includes(
        subscriptionData.data.conversationsFeed.conversation_id,
      )
        ? null // updating a message that is already loaded -> no need to do anything, apollo client cache will handle it
        : getLazyConversations({
            variables: {
              gatewayId,
              page: 1,
              filters,
            },
          }), // see if there's anything new by comparing the total # of conversations with the currently selected filters
  });

  function refetchMessages() {
    setNewMessages(false);
    refetch();
  }

  if (
    (loading &&
      !fetchingMore &&
      !filtering &&
      networkStatus !== NetworkStatus.refetch) ||
    gatewayUsersLoading ||
    gatewayLoading
  ) {
    return <Loading />;
  }

  if (error || gatewayUsersError || gatewayError) {
    return (
      <ErrorDisplay error={error} action="Loading List of Conversations" />
    );
  }

  const loadMoreResults = () =>
    has_next
      ? fetchMore({
          variables: { page: page + 1 },
          updateQuery: (prev, { fetchMoreResult: more }) => {
            if (!more.sms.conversations.results) return prev;
            const newData = {
              sms: {
                conversations: {
                  results: [
                    ...prev.sms.conversations.results,
                    ...more.sms.conversations.results,
                  ],
                  pagination: more.sms.conversations.pagination,
                  __typename: prev.sms.conversations.__typename,
                },
                __typename: prev.sms.__typename,
              },
            };
            return newData;
          },
        })
      : null;

  const gatewayUsers = gatewayUsersData?.sms.gatewayUsers ?? [];

  const handleNewChatClick = () => {
    setShowNewChatModal(showNewChatModal => !showNewChatModal);
  };

  return (
    <Box margin="0 20px" height="100%" display="flex" flexDirection="column">
      <Box
        display="flex"
        margin="20px 10px"
        flexWrap="wrap"
        alignItems="center"
        flexGrow="0"
      >
        {/* Render title/header based on tab selection */}
        <Typography
          variant="h5"
          style={{ fontWeight: 'bold', marginRight: '25px' }}
        >
          {tabIndex === '0' &&
            `All Chats for ${gatewayData?.sms.gateway.name} (${gatewayData?.sms.gateway.phone_number})`}
          {tabIndex === '1' && 'Your Chats'}
          {tabIndex === '2' && 'Starred Chats'}
          {tabIndex === '3' && 'Archived Chats'}
        </Typography>
        {!mobile && (
          <Box className={actionsBox}>
            <Box display="flex" marginRight="10px">
              <Tooltip title="Filter conversations">
                <Fab
                  color="primary"
                  onClick={() => setFilterDrawerOpen(true)}
                  size="medium"
                  className={fabButton}
                >
                  <FilterList />
                </Fab>
              </Tooltip>
              <Tooltip title="Start new chat">
                <Fab
                  style={theme.actions.confirm}
                  onClick={handleNewChatClick}
                  size="medium"
                  className={fabButton}
                >
                  <Add />
                </Fab>
              </Tooltip>
            </Box>
            <ActionsMenu
              gateway={gatewayData?.sms.gateway}
              version={'detailed'}
            />
          </Box>
        )}
        {mobile && (
          <>
            <Accordion style={{ marginTop: '10px' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                Gateway Actions
              </AccordionSummary>
              <AccordionDetails>
                <ActionsMenu
                  gateway={gatewayData?.sms.gateway}
                  version={'detailed'}
                />
              </AccordionDetails>
            </Accordion>
            <Box position="fixed" bottom="100px" right="15px">
              <Fab
                color="primary"
                onClick={() => setFilterDrawerOpen(true)}
                size="medium"
                className={fabButton}
              >
                <FilterList />
              </Fab>
            </Box>
            <Box position="fixed" bottom="35px" right="15px">
              <Fab
                style={theme.actions.confirm}
                onClick={handleNewChatClick}
                size="medium"
                className={fabButton}
              >
                <Add />
              </Fab>
            </Box>
          </>
        )}
        <Dialog
          maxWidth="md"
          open={showNewChatModal}
          onClose={() => {
            setShowNewChatModal(false);
          }}
        >
          <CreateChatModal
            handleClose={() => {
              setShowNewChatModal(false);
            }}
            gatewayId={gatewayId}
            setSelectedChatId={setSelectedChatId}
          />
        </Dialog>
        <Box mt="15px">
          <TextField
            placeholder="Phone Number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton
                    disabled={!editPhoneSearchValue}
                    onClick={() => updatePhoneSearch()}
                    size="large"
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    disabled={!editPhoneSearchValue}
                    onClick={() => {
                      setEditPhoneSearchValue('');
                      updatePhoneSearch('');
                    }}
                    size="large"
                  >
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={editPhoneSearchValue}
            onChange={e =>
              setEditPhoneSearchValue((e.target.value ?? '').replace(/\D/g, ''))
            }
            style={{
              paddingRight: '5px',
              textAlign: 'right',
            }}
            onKeyPress={handleKeyPress}
            variant="outlined"
          />
        </Box>
        <Box
          display="flex"
          alignItems="center"
          style={{ columnGap: '5px', rowGap: '5px' }}
          flexWrap="wrap"
        >
          {(selectedFilters.date_from || selectedFilters.date_to) && (
            <Chip
              onDelete={() =>
                setSelectedFilters({
                  ...selectedFilters,
                  date_to: null,
                  date_from: null,
                })
              }
              label={`${
                selectedFilters.date_from?.format('YYYY-MM-DD') ?? 'All'
              } - ${selectedFilters.date_to?.format('YYYY-MM-DD') ?? 'Now'}`}
            />
          )}
          {operatorList.map(({ display_name, username }) => (
            <Chip
              label={display_name}
              key={username}
              onDelete={() =>
                setOperatorList(prev =>
                  prev.filter(x => x.username !== username),
                )
              }
              size={mobile ? 'small' : 'medium'}
            />
          ))}
          {(selectedFilters.hasOwnProperty('has_unread_messages') ||
            selectedFilters.hasOwnProperty('not_replied')) && (
            <Chip
              onDelete={() => {
                delete selectedFilters?.has_unread_messages;
                delete selectedFilters?.not_replied;
                setSelectedFilters({
                  ...selectedFilters,
                });
              }}
              label={`${
                selectedFilters.not_replied
                  ? 'Unreplied Messages'
                  : selectedFilters.has_unread_messages
                  ? 'Unread Messages'
                  : 'Read Messages'
              }`}
              size={mobile ? 'small' : 'medium'}
            />
          )}
          {phoneSearch && (
            <Chip
              onDelete={() => {
                setPhoneSearch('');
                setEditPhoneSearchValue('');
              }}
              label={`${'Customer Phone - ' + phoneSearch}`}
              size={mobile ? 'small' : 'medium'}
            />
          )}
        </Box>
      </Box>
      {/* *** FILTER DRAWER *** */}
      <FilterDrawer
        anchor="left"
        open={filterDrawerOpen}
        onClose={() => setFilterDrawerOpen(false)}
        phoneSearch={phoneSearch}
        setPhoneSearch={setPhoneSearch}
        operators={gatewayUsers}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        statusFilter={statusFilter}
        setStatusFilter={setStatusFilter}
        operatorList={operatorList}
        setOperatorList={setOperatorList}
      />

      <ConversationList
        conversationQueryVariables={conversationQueryVariables}
        gatewayId={gatewayId}
        tabIndex={tabIndex}
        setTabIndex={setTabIndex}
        setSelectedChatId={setSelectedChatId}
        selectedChatId={selectedChatId}
        loadMoreResults={loadMoreResults}
        conversationsList={conversationsList}
        loading={loading}
        networkStatus={networkStatus}
        newMessages={newMessages}
        fetchingMore={fetchingMore}
        filtering={filtering}
        total={total}
        refetchMessages={refetchMessages}
        style={{ overflow: 'hidden', flexGrow: 1, marginBottom: '10px' }}
      />
    </Box>
  );
};

export default ConversationListContainer;
