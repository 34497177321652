import { useMutation } from '@apollo/react-hooks';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material';

/* Material UI */
import Alert from '@mui/material/Alert';

/* internal */
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';
import { useUserContext } from 'components/MaterialUI/UserContext';
import { Role } from 'constants.js';

/* external */
import gql from 'graphql-tag';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';

const UPDATE_CUSTOMER = gql`
  mutation updateCustomerContact($id: ID!, $input: UpdateCustomerInput!) {
    updateCustomer(_id: $id, input: $input) {
      _id
      do_not_contact
    }
  }
`;

const SET_GLOBAL_SUPPRESSION = gql`
  mutation createGlobalSuppression($number: String!) {
    sms {
      createGlobalSuppression(suppression: { number: $number }) {
        id
        number
        normalized_number
        suppressed
      }
    }
  }
`;

const DoNotContactModal = ({ customer, refetch }) => {
  const theme = useTheme();
  const { currentUser } = useUserContext();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [toSuppress, setToSuppress] = useState();
  const [updateDoNotContact, { loading }] = useMutation(UPDATE_CUSTOMER, {
    onCompleted: ({ updateCustomer }) =>
      enqueueSnackbar(
        updateCustomer.do_not_contact
          ? 'Customer set to "Do not contact"'
          : 'Customer "Do not contact" setting removed (NOTE: GLOBALLY SUPPRESSED NUMBERS MUST BE REMOVED MANUALLY)',
        { variant: updateCustomer.do_not_contact ? 'success' : 'warning' },
      ),
    onError: e =>
      enqueueSnackbar(`An error occurred updating customer: ${e}`, {
        variant: 'error',
      }),
  });

  const suppressed = number =>
    customer.global_suppressions
      ?.filter(x => x?.number === number)
      .some(x => x.suppressed);

  const { cell_phone, identities } = customer;

  const smsIdentityValue = identities?.find(
    identity => identity.identity_type === 'sms',
  )?.identity_value;

  const [setGlobalSuppression] = useMutation(SET_GLOBAL_SUPPRESSION, {
    onCompleted: ({ sms }) =>
      enqueueSnackbar(
        `Added ${sms.createGlobalSuppression.number} to global suppression`,
        {
          variant: 'success',
        },
      ),
    onError: () =>
      enqueueSnackbar(`Failed to add ${toSuppress} to global suppression`, {
        variant: 'error',
      }),
  });

  const [checked, setChecked] = useState(customer?.do_not_contact || false);

  const handleCheck = event => {
    if (event.target.checked) {
      setOpen(true);
      setToSuppress(
        // Suppress the identity if it exists and isn't suppressed
        // if it exists and IS suppressed already, don't do shit.
        // If there's no smsIdentity value at all, check the cell_phone
        // and suppress it if it's not already suppressed.
        smsIdentityValue && !suppressed(smsIdentityValue, customer)
          ? smsIdentityValue
          : smsIdentityValue
          ? null
          : cell_phone && !suppressed(cell_phone, customer)
          ? cell_phone
          : null,
      );
    } else {
      updateDoNotContact({
        variables: {
          id: customer._id,
          input: { do_not_contact: false },
        },
      }).then(() => {
        setChecked(false);
        setOpen(false);
        refetch();
      });
    }
  };

  const handleSubmit = () =>
    updateDoNotContact({
      variables: {
        id: customer._id,
        input: { do_not_contact: true },
      },
    })
      .then(() =>
        toSuppress
          ? setGlobalSuppression({ variables: { number: toSuppress } })
          : null,
      )
      .then(() => {
        setOpen(false);
        setChecked(true);
        refetch();
      });

  const isDisabled = checked && currentUser.role !== Role.ORGANIZATION_ADMIN;

  return (
    <Box>
      <Grid container spacing={2} style={{ paddingLeft: '10px' }}>
        <Grid item>
          <FormGroup>
            <FormControlLabel
              label="Do Not Contact"
              control={
                <Checkbox
                  checked={checked}
                  onChange={e => handleCheck(e)}
                  disabled={isDisabled}
                />
              }
            />
          </FormGroup>
        </Grid>
      </Grid>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Set Do Not Contact</DialogTitle>
        <DialogContent>
          <Alert severity="error">
            Setting a Customer Do Not Contact will LOCK this customer's profile
            and you will no longer be able to edit or view this customer's
            details. This will also add their SMS identity to global suppression
            (or their cell phone, if there's no SMS identity).
          </Alert>
          Are you sure you want to set this customer as Do Not Contact?
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpen(false)}
            color="primary"
            variant="outlined"
          >
            No
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            style={theme.actions.confirm}
            onClick={() => handleSubmit()}
          >
            Yes
          </Button>
        </DialogActions>
        <LoadingBackdrop open={loading} />
      </Dialog>
    </Box>
  );
};

DoNotContactModal.fragments = {
  customer: gql`
    fragment DoNotContactModalFragment on Customer {
      do_not_contact
      global_suppressions {
        id
        number
        suppressed
        normalized_number
      }
      identities {
        identity_type
        identity_value
      }
    }
  `,
};

export default DoNotContactModal;
