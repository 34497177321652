import React, { useMemo } from 'react';
import moment from 'moment';
import { Scatter } from 'react-chartjs-2';
import { Typography } from '@mui/material';
import { kFormatter } from '../../utils';

const MOVING_AVERAGE_WINDOW = 7;
const MOVING_AVERAGE_STEP = 7;
const VEHICLE_THRESHOLD = 50;

const PriceTrendChart = ({ listingsData }) => {
  const [chartData, trendlineData] = useMemo(() => {
    const start = moment().subtract(1, 'year');

    let totalPrices = new Array(365).fill(0);
    let totalVehicles = new Array(365).fill(0);
    listingsData.forEach(l => {
      if (!l?.price_history?.length) return;
      // eslint-disable-next-line no-unused-expressions
      l?.price_history?.sort((l1, l2) =>
        moment(l1?.change_date, 'M/D/YYYY').diff(
          moment(l2?.change_date, 'M/D/YYYY'),
          'days',
        ),
      );

      for (let i = 0; i < l?.price_history?.length; i++) {
        const startIdx = moment(
          l?.price_history[i]?.change_date,
          'M/D/YYYY',
        ).diff(start, 'days');

        if (startIdx < 0) continue;

        totalVehicles[startIdx] += 1;
        totalPrices[startIdx] += l?.price_history[i]?.price;
        if (i !== l?.price_history.length - 1) {
          const endIdx = moment(
            l?.price_history[i + 1]?.change_date,
            'M/D/YYYY',
          ).diff(start, 'days');
          totalVehicles[endIdx] -= 1;
          totalPrices[endIdx] -= l?.price_history[i]?.price;
        }
      }
    });
    for (let i = 1; i < 365; i++) {
      totalPrices[i] += totalPrices[i - 1];
      totalVehicles[i] += totalVehicles[i - 1];
    }

    const startVal = totalVehicles.findIndex(v => v >= VEHICLE_THRESHOLD);

    totalPrices = totalPrices.slice(startVal);
    totalVehicles = totalVehicles.slice(startVal);

    const newStart = moment(start).add(startVal, 'days');

    const data = [];

    for (let i = 0; i < totalPrices.length; i++) {
      data.push({
        x: moment(newStart)
          .add(i, 'days')
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
        y: totalPrices[i] / totalVehicles[i],
      });
    }

    // compute trend curve using a moving average

    const getTrendlineData = (data, i) => {
      const lo = Math.max(0, i - MOVING_AVERAGE_WINDOW);
      const hi = Math.min(data.length - 1, i + MOVING_AVERAGE_WINDOW);

      return {
        x: data[i].x,
        y: data.slice(lo, hi + 1).reduce((a, b) => a + b.y, 0) / (hi - lo + 1),
      };
    };

    const trendlineData = [];
    for (let i = 0; i < data.length - 1; i += MOVING_AVERAGE_STEP) {
      trendlineData.push(getTrendlineData(data, i));
    }
    trendlineData.push(getTrendlineData(data, data.length - 1));

    return [data, trendlineData];
  }, [listingsData]);

  return chartData?.length > 20 ? (
    <Scatter
      options={{
        scales: {
          xAxes: [
            {
              type: 'time',
              time: {
                unit: 'day',
              },
              scaleLabel: {
                display: true,
                labelString: 'Date',
                fontSize: 16,
                fontStyle: 'bold',
                fontColor: 'black',
              },
            },
          ],
          yAxes: [
            {
              type: 'linear',
              scaleLabel: {
                display: true,
                labelString: 'Price',
                fontSize: 16,
                fontStyle: 'bold',
                fontColor: 'black',
              },
              ticks: {
                callback: value => `$${kFormatter(value)}`,
              },
            },
          ],
        },
        animation: {
          duration: 0,
        },
      }}
      data={{
        datasets: [
          {
            label: 'Price Trend',
            radius: 0,
            hoverRadius: 0,
            fill: false,
            data: trendlineData,
            borderColor: '#ff5400',
            pointBorderColor: 'rgba(0, 0, 0, 0)',
            pointBackgroundColor: 'rgba(0, 0, 0, 0)',
            type: 'line',
          },
          {
            label: 'Price',
            radius: 0,
            hoverRadius: 0,
            fill: false,
            data: chartData,
            borderColor: 'rgba(0, 0, 0, 0.2)',
            borderWidth: 2,
            type: 'line',
            pointBackgroundColor: 'rgba(0, 0, 0, 0)',
            pointBorderColor: 'rgba(0, 0, 0, 0)',
          },
        ],
      }}
    />
  ) : (
    <Typography variant="body1">
      Insufficient Data for Pricing History.
    </Typography>
  );
};

export default PriceTrendChart;
