import React, { useEffect } from 'react';

/* external */
import gql from 'graphql-tag';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';

/* Material UI */
import Box from '@mui/material/Box';

/* internal */
import AppraisalDetailsHeaderBox from './AppraisalDetailsHeaderBox';
import DealerPicker from 'components/MaterialUI/DealerPicker';
import Loading from 'components/MaterialUI/Loading';
import ErrorPage from 'components/MaterialUI/ErrorPage';
import TradeFormContainer from 'modules/appraisals/components/TradeFormContainer';
import { useDealerContext } from 'components/MaterialUI/DealerContext';

const APPRAISAL_QUERY = gql`
  query appraisal($id: Int!) {
    appraisals {
      appraisal(id: $id) {
        id
        ...AppraisalDetailsHeaderBoxAppraisal
        ...TradeFormContainerAppraisal
      }
    }
  }
  ${AppraisalDetailsHeaderBox.fragments.appraisal}
  ${TradeFormContainer.fragments.appraisal}
`;

const AppraisalDetails = ({ id }) => {
  const { dealerId, setDealerId } = useDealerContext();
  const history = useHistory();
  const { data, error, loading } = useQuery(APPRAISAL_QUERY, {
    variables: { id },
  });

  const { appraisal } = data?.appraisals ?? {};

  // Set dealerId to appraisal's dealerId if the current dealerId is not the same
  // This can happen if someone links directly to the appraisal details url.
  useEffect(() => {
    if (dealerId && appraisal?.dealerId && appraisal.dealerId !== dealerId)
      setDealerId(appraisal.dealerId);
  }, [dealerId, setDealerId, appraisal]);

  if (loading) return <Loading />;
  if (error) return <ErrorPage action="Loading appraisal" error={error} />;

  const goToList = () => history.push('/appraisals');

  return (
    <Box m={1}>
      <DealerPicker disabled />
      <AppraisalDetailsHeaderBox appraisal={appraisal} mb={2} />
      <TradeFormContainer
        // This is causing problems because the form for appraisals sucks.  :(
        // TODO: make appraisals form suck less
        appraisal={appraisal}
        onCancel={goToList}
        onCreate={goToList}
        onUpdate={() => {}}
      />
    </Box>
  );
};

export default AppraisalDetails;
