import React from 'react';

/* external */
import { isEmpty } from 'lodash';

/* Material UI */
import { DatePicker } from '@mui/x-date-pickers';
import { makeStyles } from '@mui/styles';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DoneIcon from '@mui/icons-material/Done';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import moment from 'moment-timezone';

const useStyles = makeStyles(theme => ({
  panel: {
    width: '100%',
    paddingLeft: '25px',
    paddingRight: '25px',
    maxWidth: '400px',
  },
  title: {
    fontSize: '20px',
    paddingBottom: '10px',
    fontWeight: 'bold',
  },
  subPanel: {
    width: '100%',
    textTransform: 'capitalize',
  },
}));

const FilterDrawer = ({
  facetResults,
  createdFromFilter,
  createdUntilFilter,
  selectedFacets,
  setCreatedFromFilter,
  setCreatedUntilFilter,
  setSelectedFacets,
  loading,
}) => {
  const classes = useStyles();

  const FACET_OPTIONS = [
    {
      field: 'is_archived',
      model: 'Lead',
      title: 'Archived',
    },
    {
      field: 'assignee',
      model: 'Lead',
      title: 'Assignee',
    },
    {
      field: 'form_data_lead_channel',
      model: 'Lead',
      title: 'Channel',
    },
    {
      field: 'form_data_form_name',
      model: 'Lead',
      title: 'Form Name',
    },
    {
      field: 'is_junk',
      model: 'Lead',
      title: 'Junk',
    },
    {
      field: 'form_data_lead_source',
      model: 'Lead',
      title: 'Source',
    },
    {
      field: 'type',
      model: 'Lead',
      title: 'Type',
    },
  ];

  const deleteAllFacets = () => {
    setSelectedFacets([]);
    setCreatedFromFilter(null);
    setCreatedUntilFilter(null);
  };

  const getFacetResults = (model, field, options = {}) =>
    facetResults
      .find(x => x.field === field && (!model || x.model === model))
      ?.data.sort(options.sort || (_ => 0))
      .map(({ value, ...rest }) => ({
        value: (options.format || (x => x))(value),
        ...rest,
      })) || [];

  const isSelected = (_model, _field, _key, _value) =>
    selectedFacets.findIndex(
      ({ model, field, key, value }) =>
        model === _model &&
        field === _field &&
        key === _key &&
        value === _value,
    ) !== -1;

  const deleteFacet = (_model, _field, _key, _value) => {
    setSelectedFacets(prev =>
      prev.filter(
        ({ model, field, key, value }) =>
          model !== _model ||
          field !== _field ||
          key !== _key ||
          value !== _value,
      ),
    );
  };

  const setFacet = (model, field, key, value, options) => {
    setSelectedFacets(prev => [...prev, { model, field, key, value, options }]);
  };

  const toggleFacet = (model, field, key, value, options) => {
    if (isSelected(model, field, key, value))
      deleteFacet(model, field, key, value);
    else setFacet(model, field, key, value, options);
  };

  const presets = [
    { value: 'thisMonth', label: 'This Month' },
    { value: 'lastMonth', label: 'Last Month' },
    { value: 'last90d', label: 'Last 90 Days' },
    { value: 'last6m', label: 'Last 6 Months' },
  ];

  const toggleDatePreset = value => {
    const today = moment();
    const preset = presets.find(preset => preset.value === value);

    let start, end;

    switch (preset?.value) {
      case 'thisMonth':
        start = moment().startOf('month');
        end = moment().endOf('month');
        break;
      case 'lastMonth':
        start = moment().subtract(1, 'month').startOf('month');
        end = moment().subtract(1, 'month').endOf('month');
        break;
      case 'last90d':
        start = moment().subtract(90, 'days');
        end = today;
        break;
      case 'last6m':
        start = moment().subtract(6, 'months').startOf('month');
        end = today;
        break;
      default:
        return;
    }

    setCreatedFromFilter(start);
    setCreatedUntilFilter(end);
  };

  const showReset =
    !isEmpty(selectedFacets) || createdFromFilter || createdUntilFilter;
  return (
    <List className={classes.panel}>
      <Box className={classes.title}>Filters</Box>
      {showReset && (
        <Box style={{ paddingBottom: '10px' }}>
          <Button style={{ color: 'red' }} onClick={deleteAllFacets}>
            <HighlightOffIcon style={{ paddingRight: '5px' }} /> RESET ALL
          </Button>
        </Box>
      )}
      {FACET_OPTIONS.map(({ field, model, title, options = {} }) => (
        <Box style={{ paddingBottom: '10px' }} key={`${field}${model}${title}`}>
          <Accordion key={title}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              {title}
            </AccordionSummary>
            <AccordionDetails>
              <List component="div" disablePadding className={classes.subPanel}>
                {getFacetResults(model, field, options).map(
                  ({ count, key, value }) => (
                    <ListItem
                      disabled={loading}
                      dense
                      button
                      onClick={() =>
                        toggleFacet(model, field, key, value, options)
                      }
                      key={`${model}${field}${key}${value}`}
                      selected={isSelected(model, field, key, value)}
                    >
                      {isSelected(model, field, key, value) ? (
                        <DoneIcon
                          style={{
                            fontSize: 'small',
                            paddingRight: '10px',
                            width: '25px',
                          }}
                        />
                      ) : (
                        <div style={{ paddingRight: '10px', width: '25px' }}>
                          {' '}
                        </div>
                      )}
                      <ListItemText
                        primary={`${value || 'Unknown'} (${count})`}
                      />
                    </ListItem>
                  ),
                )}
              </List>
            </AccordionDetails>
          </Accordion>
        </Box>
      ))}
      <Box style={{ paddingBottom: '10px', maxWidth: '100%' }}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            Created
          </AccordionSummary>
          <AccordionDetails>
            <List
              component="div"
              disablePadding
              className={classes.subPanel}
              style={{ width: '100%' }}
            >
              {presets.map(preset => (
                <ListItem
                  key={preset.value}
                  dense
                  button
                  onClick={() => toggleDatePreset(preset.value)}
                >
                  {preset.label}
                </ListItem>
              ))}
              <Box
                style={{
                  display: 'flex',
                  marginTop: '15px',
                  marginBottom: 'auto',
                }}
              >
                <div>
                  <Box>From</Box>
                  <DatePicker
                    autoOk
                    clearable
                    emptyLabel=""
                    format="YYYY-MM-DD"
                    onChange={date => setCreatedFromFilter(date)}
                    value={createdFromFilter}
                    variant="dialog"
                    style={{ paddingLeft: '16px' }}
                  />
                </div>
                <div>
                  <Box>To</Box>

                  <DatePicker
                    autoOk
                    clearable
                    emptyLabel=""
                    format="YYYY-MM-DD"
                    onChange={date => setCreatedUntilFilter(date)}
                    value={createdUntilFilter}
                    variant="dialog"
                    style={{ paddingLeft: '16px' }}
                  />
                </div>
              </Box>
            </List>
          </AccordionDetails>
        </Accordion>
      </Box>
    </List>
  );
};

export default FilterDrawer;
