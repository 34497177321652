import React from 'react';

/* Material UI */
import { Box } from '@mui/material';

/* internal */
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import { WHOLESALER_DEALER_IDS } from 'constants.js';
import DealerDashboard from './Dealer/DealerDashboard';
import DealerPicker from 'components/MaterialUI/DealerPicker';
import WholesaleDashboard from './Wholesale/WholesaleDashboard';

const UsedVehiclesList = () => {
  const { dealerId } = useDealerContext();

  return (
    <Box height="100%">
      <Box padding={1}>
        <DealerPicker />
      </Box>
      {WHOLESALER_DEALER_IDS.includes(dealerId) ? (
        <WholesaleDashboard />
      ) : (
        <DealerDashboard />
      )}
    </Box>
  );
};

export default UsedVehiclesList;
