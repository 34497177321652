/* external */
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useSnackbar } from 'notistack';
import React from 'react';

/* Material UI */
import { MenuItem } from '@mui/material';

const DISABLE_ACCOUNT = gql`
  mutation disableAccount($cardId: ID!) {
    gocard {
      disableAccount(cardId: $cardId) {
        cardId
        active
      }
    }
  }
`;

const ACTIVATE_ACCOUNT = gql`
  mutation activateAccount($cardId: ID!) {
    gocard {
      activateAccount(cardId: $cardId) {
        cardId
        active
      }
    }
  }
`;

const UpdateCardStatus = ({ active, cardId, handleClose }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [disableAccount] = useMutation(DISABLE_ACCOUNT, {
    onCompleted: () => {
      enqueueSnackbar('Go Card disabled!', {
        variant: 'success',
      });
    },
    onError: e => {
      enqueueSnackbar(`An error occured: ${e}`, {
        variant: 'error',
      });
    },
  });

  const [activateAccount] = useMutation(ACTIVATE_ACCOUNT, {
    onCompleted: () => {
      enqueueSnackbar('Go Card activated!', {
        variant: 'success',
      });
    },
    onError: e => {
      enqueueSnackbar(`An error occured: ${e}`, {
        variant: 'error',
      });
    },
  });

  const menuItemHandler = event => {
    event.stopPropagation();
    handleClose();
  };

  const handleStatusUpdate = active => {
    const params = { variables: { cardId: parseInt(cardId, 10) } };
    active ? disableAccount(params) : activateAccount(params);
  };

  return (
    <MenuItem
      onClick={event => {
        menuItemHandler(event);
        handleStatusUpdate(active);
      }}
    >
      {`${active ? 'Disable Go Card' : 'Activate Go Card'}`}
    </MenuItem>
  );
};

export default UpdateCardStatus;
