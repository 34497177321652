// External
import React, { useEffect, useState } from 'react';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { Link, useHistory } from 'react-router-dom';

// Material UI
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from '@mui/material';

import { makeStyles } from '@mui/styles';

import MoreVertIcon from '@mui/icons-material/MoreVert';

// Internal
import Loading from 'components/MaterialUI/Loading';
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import { useUserContext } from 'components/MaterialUI/UserContext';
import DealerPicker from 'components/MaterialUI/DealerPicker';
import {
  LeadAcquisitionSummaryCard,
  LeadContactCard,
  LeadCustomerVehicleCard,
  LeadInterestedVehicleCard,
  LeadExtraDataCard,
} from './cards';

const GET_LEAD = gql`
  query getLead($leadId: ID) {
    lead(_id: $leadId) {
      _id
      is_archived
      archived
      created
      assigned
      typeName
      dealer_id
      tags
      is_junk
      first_response {
        response_type
        username
        responded
      }
      assigneeNode {
        username
        display_name
      }
      customer {
        _id
        fullname
        opportunities {
          _id
          created
          status_display
          crm_lead_ids
          dealer {
            dealer_id
            dealer_name
          }
          sales_rep_usernames
          sales_manager_usernames
          bdc_rep_usernames
          finance_manager_usernames
          permissions {
            can_add_opportunity_attachment
            can_assign_bdc_rep
            can_assign_salesperson
            can_assign_user
            can_delete_opportunity_attachment
            can_edit_deal_number
            can_edit_finance_checklist
            can_link_lead
            can_read_details
            can_update_carryover_date
          }
        }
      }
      dealer {
        dealer_name
        desking_settings {
          lead_setting
        }
      }
      form_data {
        submitted_timestamp
        casl_opt_in
        city
        customer_vehicle_amount_owing
        customer_vehicle_apr
        customer_vehicle_make
        customer_vehicle_model
        customer_vehicle_monthly_payment
        customer_vehicle_odometer
        customer_vehicle_trim
        customer_vehicle_year
        customer_vehicle_book_value
        email
        extra_data
        first_name
        form_name
        interested_vehicle_make
        interested_vehicle_model
        interested_vehicle_odometer
        interested_vehicle_price
        interested_vehicle_stock
        interested_vehicle_trim
        interested_vehicle_vehicle_type
        interested_vehicle_vin
        interested_vehicle_year
        last_name
        lead_channel
        lead_direction
        lead_source
        lead_type
        message
        postal_code
        preferred_contact_method
        primary_phone
        province
        secondary_phone
        street_address_1
        campaign_medium
        campaign_name
        campaign_source
        purchase_timeline_months
        preferred_date
        preferred_time
        country
      }
      permissions {
        can_create_or_link_opportunity
        can_assign_lead
        can_archive_lead
      }
    }
  }
`;

const USERS_FOR_DEALER = gql`
  query getUsers($dealerId: Int!) {
    users(dealer_ids: [$dealerId], status: active) {
      display_name
      username
      role
    }
  }
`;

const ASSIGN_LEAD = gql`
  mutation assignLead($leadId: ID!, $username: String!) {
    assignLead(_id: $leadId, username: $username) {
      _id
      assignee
      assigned
      assigneeNode {
        username
        display_name
      }
    }
  }
`;

const ARCHIVE_LEAD = gql`
  mutation archiveLead($leadId: ID!) {
    archiveLead(_id: $leadId) {
      _id
      is_archived
      archived
    }
  }
`;

const JUNK_LEAD = gql`
  mutation junkLead($leadId: ID!, $isJunk: Boolean!) {
    junkLead(_id: $leadId, is_junk: $isJunk) {
      _id
      is_junk
    }
  }
`;

const CREATE_OPPORTUNITY_FOR_LEAD = gql`
  mutation createOpportunityForLead($leadId: String!, $dealerId: Int!) {
    createOpportunityForLead(leadId: $leadId, input: { dealer_id: $dealerId }) {
      _id
      customer_id
    }
  }
`;

const useStyles = makeStyles(theme => ({
  actions: {
    '& ul li i': {
      marginRight: '.5rem',
    },
    '& > *': {
      margin: theme.spacing(0.5),
    },
    '& a': {
      textDecoration: 'none',
      color: '#333',
    },
  },
  customer: {
    '& a': {
      color: '#0093a7',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
}));

const LeadDetails = ({ leadId }) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const { dealerId, setDealerId } = useDealerContext();
  const { me } = useUserContext();

  const theme = useTheme();

  const { data, loading } = useQuery(GET_LEAD, {
    variables: {
      leadId: leadId,
    },
  });

  const lead = data?.lead || {};

  const { data: userData } = useQuery(USERS_FOR_DEALER, {
    variables: { dealerId: dealerId },
  });
  const userList = _.sortBy(userData?.users, ['display_name']);

  const [anchorEl, setAnchorEl] = useState(null);

  const menuClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const menuClose = () => {
    setAnchorEl(null);
  };

  // Set dealerID to lead dealer ID if the current dealerID is not the same
  // and if the dealer is in the user's dealers list.
  // This can happen if someone links directly to the lead details url.
  useEffect(() => {
    if (
      dealerId &&
      lead?.dealer_id &&
      dealerId !== lead?.dealer_id &&
      me.dealers.map(x => x.dealer_id).includes(lead?.dealer_id)
    ) {
      setDealerId(lead?.dealer_id);
      enqueueSnackbar(
        `Set current dealer to: ${
          me.dealers.find(x => x.dealer_id === lead?.dealer_id)?.dealer_name
        } `,
        { variant: 'warning' },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lead.dealer_id, me.dealers]);

  const [openAssignLead, setOpenAssignLead] = useState(false);
  const [username, setUsername] = useState('');

  const [assignLead] = useMutation(ASSIGN_LEAD, {
    onCompleted: () => {
      setUsername('');
      enqueueSnackbar(`Lead successfully assigned`, {
        variant: 'success',
      });
    },
    onError: () =>
      enqueueSnackbar(`Error when assigning lead`, {
        variant: 'error',
      }),
  });

  const handleOpenAssignLead = () => {
    setOpenAssignLead(true);
  };

  const handleClose = () => {
    setOpenAssignLead(false);
  };

  const handleChange = event => {
    setUsername(event.target.value);
  };

  const onSubmit = () => {
    if (username !== '') {
      assignLead({
        variables: {
          leadId: lead._id,
          username,
        },
      });
    }

    handleClose();
  };

  const [archiveLead] = useMutation(ARCHIVE_LEAD, {
    onCompleted: () => {
      enqueueSnackbar(`Lead successfully archived`, {
        variant: 'success',
      });
    },
    onError: () =>
      enqueueSnackbar(`Error when archiving lead`, {
        variant: 'error',
      }),
  });

  const onArchive = () => {
    archiveLead({
      variables: {
        leadId: lead._id,
      },
    });
  };

  const [junkLead] = useMutation(JUNK_LEAD, {
    onCompleted: () => {
      enqueueSnackbar(`Lead successfully updated`, {
        variant: 'success',
      });
    },
    onError: () =>
      enqueueSnackbar(`Error when marking lead`, {
        variant: 'error',
      }),
  });

  const junkOrNotJunk = isJunk => {
    junkLead({
      variables: {
        leadId: lead._id,
        isJunk,
      },
    });
  };

  const getOpportunityId = () => {
    let opportunity_id = '';
    lead.customer.opportunities.forEach(i => {
      if (i.crm_lead_ids.indexOf(lead._id) > -1) {
        opportunity_id = i._id;
      }
    });

    return opportunity_id;
  };

  const history = useHistory();
  const [createOpportunity, { loading: creatingOpportunity }] = useMutation(
    CREATE_OPPORTUNITY_FOR_LEAD,
    {
      onCompleted: data => {
        const { _id, customer_id } = data.createOpportunityForLead;
        history.push(`/opportunities/${_id}/${customer_id}/details`);
        enqueueSnackbar(`Opportunity successfully created`, {
          variant: 'success',
        });
      },
      onError: () =>
        enqueueSnackbar(`Error when creating opportunity`, {
          variant: 'error',
        }),
    },
  );

  const startOpportunity = () => {
    createOpportunity({
      variables: {
        leadId: lead._id,
        dealerId: dealerId,
      },
    });
  };

  return (
    <Box m={2}>
      <LoadingBackdrop open={creatingOpportunity}>
        Creating Opportunity...
      </LoadingBackdrop>
      <Box>
        <DealerPicker disabled />
        <Dialog open={openAssignLead} onClose={handleClose}>
          <DialogTitle>Assign Lead</DialogTitle>
          <DialogContent>
            <InputLabel>Assignee</InputLabel>
            <Select
              id="username"
              value={username}
              onChange={handleChange}
              style={{ width: 300 }}
            >
              {userList.map(i => (
                <MenuItem key={i.username} value={i.username}>
                  {i.display_name}
                </MenuItem>
              ))}
            </Select>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" variant="outlined">
              Cancel
            </Button>
            <Button
              onClick={onSubmit}
              color="primary"
              variant="contained"
              style={
                username === '' ? theme.actions.disabled : theme.actions.confirm
              }
              disabled={username === ''}
            >
              Assign
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
      {loading && <Loading />}
      {!loading && (
        <Box>
          <Typography
            style={{
              fontSize: '1.25rem',
              fontWeight: '700',
              marginTop: '.5rem',
            }}
          >
            {lead.typeName} Lead for{' '}
            {lead.form_data?.first_name + ' ' + lead.form_data?.last_name}
            <IconButton onClick={menuClick} size="large">
              <MoreVertIcon color="primary" />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={menuClose}
              style={{ width: '400px' }}
              className={classes.actions}
            >
              {lead.customer && (
                <MenuItem>
                  <Link to={`/customers/${lead.customer._id}/details`}>
                    <i className="fas fa-user fa-fw" />
                    Customer Profile
                  </Link>
                </MenuItem>
              )}
              {((!lead.tags.includes('opportunity') &&
                !lead.form_data?.lead_type?.includes('sale') &&
                !lead.is_archived) ||
                !lead.assigneeNode ||
                (!lead.tags?.includes('opportunity') &&
                  lead.form_data?.lead_type === 'contest')) && (
                <MenuItem onClick={() => startOpportunity()}>
                  <i className="fas fa-thumbs-up fa-fw" />
                  Start Opportunity
                </MenuItem>
              )}
              {lead.customer && lead.tags.includes('opportunity') && (
                <MenuItem>
                  <Link
                    to={`/opportunities/${getOpportunityId()}/${
                      lead.customer._id
                    }/details`}
                  >
                    <i className="fas fa-thumbs-up fa-fw" />
                    View Opportunity
                  </Link>
                </MenuItem>
              )}
              {!lead.tags?.includes('opportunity') && (
                <MenuItem onClick={() => handleOpenAssignLead()}>
                  <i className="fas fa-user-plus fa-fw" />
                  Assign Lead
                </MenuItem>
              )}
              {!lead.is_archived && (
                <MenuItem onClick={() => onArchive()}>
                  <i className="fas fa-folder fa-fw" />
                  Archive Lead
                </MenuItem>
              )}
              {!lead.is_junk && (
                <MenuItem onClick={() => junkOrNotJunk(true)}>
                  <i className="fas fa-exclamation fa-fw" />
                  Mark as Junk
                </MenuItem>
              )}
              {lead.is_junk && (
                <MenuItem onClick={() => junkOrNotJunk(false)}>
                  <i className="fas fa-exclamation-circle fa-fw" />
                  Mark as Not Junk
                </MenuItem>
              )}
            </Menu>
          </Typography>
          <Typography className={classes.customer}>
            {lead.customer && (
              <Link
                to={`/customers/${lead.customer._id}/details`}
                style={{ paddingRight: '1rem' }}
              >
                <i className="fas fa-user" /> {lead.customer?.fullname || ''}
              </Link>
            )}
            {lead.assigneeNode &&
              `Assigned to: ${lead.assigneeNode.display_name}`}
          </Typography>
          <Grid
            container
            direction="row"
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Grid
              item
              xs={12}
              md={6}
              style={{
                padding: '1rem 1rem 1rem 0',
              }}
            >
              <LeadContactCard lead={lead} classes={classes} />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              style={{
                padding: '1rem 1rem 1rem 0',
              }}
            >
              <LeadAcquisitionSummaryCard lead={lead} classes={classes} />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              style={{
                padding: '1rem 1rem 1rem 0',
              }}
            >
              <LeadCustomerVehicleCard lead={lead} classes={classes} />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              style={{
                padding: '1rem 1rem 1rem 0',
              }}
            >
              <LeadInterestedVehicleCard lead={lead} classes={classes} />
            </Grid>
            {lead.form_data?.extra_data && (
              <Grid
                item
                xs={12}
                md={6}
                style={{
                  padding: '1rem 1rem 1rem 0',
                }}
              >
                <LeadExtraDataCard
                  extraData={JSON.parse(lead.form_data?.extra_data)}
                  classes={classes}
                />
              </Grid>
            )}
            <Grid
              item
              md={12}
              style={{
                padding: '1rem 1rem 1rem 0',
                width: '100%',
              }}
            >
              <Card style={{ height: '100%' }}>
                <CardContent>
                  <Typography
                    style={{ padding: '0 0 .5rem 1rem', fontWeight: '700' }}
                  >
                    Message
                  </Typography>
                  <p style={{ padding: '0 1rem .5rem' }}>
                    {lead.form_data?.message}
                  </p>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default LeadDetails;
