import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Button, CircularProgress, Container } from '@mui/material';
import { Typography } from '@mui/material';
import DeviceSelector from './DeviceSelector';
import Camera from './Camera';
import { Status, EncodingPrefs } from './constants';

const VideoRecorder = ({ onRecorded, onQuit }) => {
  const [status, setStatus] = useState(Status.IDLE);
  const [playbackURL, setPlaybackURL] = useState(null);
  const [deviceOptions, setDeviceOptions] = useState([]);
  const [selectedDeviceId, setSelectedDeviceId] = useState(
    window._preferredMediaDeviceId,
  );
  const mediaDevice = useRef(null);
  const mediaRecorder = useRef(null);
  const mediaBlobs = useRef([]);
  const capturedBlob = useRef(null);

  const setAndRememberSelectedDeviceId = deviceId => {
    window._preferredMediaDeviceId = deviceId;
    setSelectedDeviceId(deviceId);
  };

  const getDevices = useCallback(async () => {
    await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
    try {
      setStatus(Status.ACQUIRING_DEVICE);
      const devices = await navigator.mediaDevices.enumerateDevices();
      const camDevices = devices
        .filter(d => d.kind === 'videoinput')
        .map(d => ({
          deviceId: d.deviceId,
          label: d.label,
        }));
      if (camDevices.length) {
        setDeviceOptions(camDevices);
        setStatus(Status.CONFIGURING);
      } else {
        throw new Error('No camera devices detected');
      }
    } catch (error) {
      setStatus(Status.DEVICE_UNAVAILABLE);
    }
  }, [setStatus, setDeviceOptions]);

  const getMedia = useCallback(async () => {
    try {
      setStatus(Status.ACQUIRING_DEVICE);
      const userMedia = await navigator.mediaDevices.getUserMedia({
        video: {
          deviceId: { exact: selectedDeviceId },
          // ...VideoDimensions,
        },
        audio: true,
      });
      mediaDevice.current = userMedia;
      setStatus(Status.DEVICE_READY);
    } catch (error) {
      setStatus(Status.DEVICE_UNAVAILABLE);
    }
  }, [selectedDeviceId, setStatus]);

  useEffect(() => {
    if (status === Status.IDLE && deviceOptions.length === 0) {
      getDevices();
    }
    if (
      status === Status.CONFIGURING &&
      selectedDeviceId &&
      !mediaDevice.current
    ) {
      getMedia();
    }
  });

  const startRecording = async () => {
    mediaBlobs.current.length = 0;
    const recorder = new MediaRecorder(mediaDevice.current, EncodingPrefs);
    recorder.ondataavailable = ({ data }) => {
      mediaBlobs.current.push(data);
    };
    recorder.onstop = () => {
      const captured = new Blob(mediaBlobs.current, {
        type: EncodingPrefs.mimeType,
      });
      capturedBlob.current = captured;
      setStatus(Status.STOPPED);
      const url = URL.createObjectURL(captured);
      setPlaybackURL(url);
    };
    recorder.onstart = () => {
      setStatus(Status.RECORDING);
    };
    recorder.onpause = () => {
      setStatus(Status.PAUSED);
    };
    recorder.onresume = () => {
      setStatus(Status.RECORDING);
    };
    mediaRecorder.current = recorder;
    mediaRecorder.current.start();
  };

  const stopRecording = async () => {
    mediaRecorder.current.stop();
  };

  const pauseRecording = async () => {
    mediaRecorder.current.pause();
  };

  const resumeRecording = async () => {
    mediaRecorder.current.resume();
  };

  if (status === Status.CONFIGURING) {
    return (
      <Container>
        <DeviceSelector
          deviceOptions={deviceOptions}
          onSelect={setAndRememberSelectedDeviceId}
          selected={selectedDeviceId}
        />
      </Container>
    );
  }

  if (status === Status.ACQUIRING_DEVICE) {
    return <CircularProgress />;
  }
  if (status === Status.ACQUIRING_DEVICE) {
    return <CircularProgress />;
  }
  if (status === Status.ERROR) {
    return (
      <Container>
        <Typography variant="h6">Error</Typography>
        <Button onClick={onQuit}>Quit</Button>
      </Container>
    );
  }
  const messageText = `Blobs ${mediaBlobs.current.length}`;
  return (
    <Camera
      userMedia={mediaDevice.current}
      status={status}
      onStart={startRecording}
      onStop={stopRecording}
      onPause={pauseRecording}
      onResume={resumeRecording}
      playbackURL={playbackURL}
      onClose={onQuit}
      onSave={() => onRecorded(capturedBlob.current)}
      message={messageText}
    />
  );
};

export default VideoRecorder;
