import React from 'react';

/* external */
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import gql from 'graphql-tag';

/* Material UI */
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

/* internal */
import { snackBarSettings } from 'constants.js';
import { useUserContext } from 'components/MaterialUI/UserContext';
import AuctionForm, { processAuctionData } from './AuctionForm';
import AuctionsTableRow from './AuctionsTableRow';
import ErrorPage from 'components/MaterialUI/ErrorPage';
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';

const CREATE_AUCTION = gql`
  mutation auction($auction: AuctionInput!) {
    auctions {
      createAuction(auction: $auction) {
        id
        ...AuctionsTableRowAuction
        ...AuctionsTableRowAuctionPermissions
      }
    }
  }
  ${AuctionsTableRow.fragments.auction}
  ${AuctionsTableRow.fragments.auctionPermissions}
`;

const AuctionCreate = () => {
  const { currentUser } = useUserContext();
  const { enqueueSnackbar: snackIt } = useSnackbar();
  const history = useHistory();
  const timezone = currentUser?.goUserProfile?.settings?.timezone;

  const [createAuction, { error, loading }] = useMutation(CREATE_AUCTION);

  if (!timezone)
    return (
      <ErrorPage
        error={{
          message:
            'You must have a timezone set in your user profile.  Contact Market Support if you need assistance.',
        }}
      />
    );

  const createSuccess = () => {
    snackIt('Created auction successfully!', {
      ...snackBarSettings,
      variant: 'success',
    });
    history.push('/auctions');
  };

  const handleSubmit = ({ isWeekly, isDaily, ...auction }) =>
    createAuction({
      variables: { auction: processAuctionData(auction) },
    }).then(createSuccess, () => {});

  return (
    <Box margin={1}>
      <Typography variant="h5">New Auction</Typography>
      <AuctionForm onSubmit={handleSubmit} submitErrors={error} />
      <LoadingBackdrop open={loading}>Creating New Auction...</LoadingBackdrop>
    </Box>
  );
};

export default AuctionCreate;
