import { createSelector } from 'reselect';

import { Role } from 'constants.js';
import { NAME } from './constants';
import { NetworkError, AuthenticationError, ServerError } from './errors';
import { verifyToken, decodeToken } from './utils';
export * from './propTypes';

/**
 * Auth
 */

export const getToken = state => state[NAME].token;

export const getIsLoggedIn = createSelector(getToken, token =>
  verifyToken(token),
);

export const getCurrentUser = createSelector(getToken, token =>
  decodeToken(token),
);

export const getIsSendingRequest = state => state[NAME].sendingRequest;

export const getCurrentUserRole = state => {
  const currentUser = getCurrentUser(state);
  return currentUser ? currentUser.role : null;
};

export const getIsSalesRep = state =>
  Boolean(getCurrentUserRole(state) === Role.SALES_REP);

export const getAllowedDealerIds = state => {
  const currentUser = getCurrentUser(state);
  return currentUser ? currentUser.dealer_ids : [];
};

export const getIsMultiDealer = state => getAllowedDealerIds(state).length > 1;

/**
 * UI
 */
export const getWindowWidth = state => state[NAME].windowWidth;

/**
 * Errors
 */

export const getAllErrors = state =>
  Object.keys(state[NAME].appErrors).map(
    sourceId => state[NAME].appErrors[sourceId],
  );

export const getNetworkErrors = state =>
  getAllErrors(state).filter(
    appError => appError.error instanceof NetworkError,
  );

export const isNetworkError = state => getNetworkErrors(state).length > 0;

export const getAuthenticationErrors = state =>
  getAllErrors(state).filter(
    appError => appError.error instanceof AuthenticationError,
  );

export const isAuthenticationError = state =>
  getAuthenticationErrors(state).length > 0;

export const getServerErrors = state =>
  getAllErrors(state).filter(appError => appError.error instanceof ServerError);

export const isServerError = state => getServerErrors(state).length > 0;

/**
 * Notifications
 */

export const getAllNotifications = state =>
  Object.keys(state[NAME].notifications).map(
    id => state[NAME].notifications[id],
  );

export const getNotifications = (state, source) => {
  let notifications = getAllNotifications(state);

  if (source) {
    notifications = notifications.filter(n => n.source === source);
  }

  return notifications;
};
