import React, { useState } from 'react';

import gql from 'graphql-tag';

/* Material UI */
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import EditIcon from '@mui/icons-material/Edit';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

/* internal */
import { formatPrice, formatNumber, normalizePostalCode } from 'utils';
import { DealerTrack } from 'modules/credit_apps/constants';
import GridCard from './GridCard';
import GridItem from './GridItem';
import DealerTrackSubmissionDialog from './DealerTrackSubmissionDialog';
import { useUserContext } from 'components/MaterialUI/UserContext';
import { Role } from 'constants.js';

const CAN_VIEW_EQUIFAX_ROLES = [
  Role.ORGANIZATION_ADMIN,
  Role.SALES_MANAGER,
  Role.FINANCE_DIRECTOR,
  Role.GENERAL_MANAGER,
];

export const CREDIT_APP_DETAILS_DT_SUB_FRAGMENT = gql`
  fragment CreditAppDetailsDialogDealerTrackSubmission on DealerTrackSubmission {
    id
    dateCreated
    createdByUser {
      display_name
    }
    modifiedByUser {
      display_name
    }
    dealerTrackDealId
    dealerTrackReferenceNum
    applicationStatus
    error
  }
`;
const CreditAppDetailsDialog = ({ creditApp, onClose, refetch, ...rest }) => {
  const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const {
    address,
    addressSuite,
    annualGrossIncome,
    city,
    dateOfBirth,
    dealerTrackSubmissions = [],
    dealerId,
    emailAddress,
    employerAddress,
    employerAddressSuite,
    employerCity,
    employerName,
    employerPostalCode,
    employerProvince,
    employmentStatus,
    firstName,
    hasVehicleTrade,
    homeOwnership,
    id: creditAppId,
    jobTitle,
    lastName,
    monthlyHomePayment,
    mortgageCompany,
    permissions,
    phoneNumber,
    postalCode,
    preferredLender,
    province,
    vehicleTradeMakeName,
    vehicleTradeModelName,
    vehicleTradeOdometer,
    vehicleTradeTrim,
    vehicleTradeYear,
    yearsWithEmployer,
    equifaxPrequalifyCreditScore,
    equifaxPrequalifyDate,
    equifaxNeighbourhoodViewScore,
    equifaxNeighbourhoodViewDate,
  } = creditApp ?? {};
  const { canSendToDealerTrack } = permissions ?? {};

  const dobDate = new Date(`${dateOfBirth}T00:00:00`);

  const newDealerTrackSubmissionDefaults = {
    creditAppId,
    dealerId,
    data: {
      language: 'EN',
      productId: 'LOAN', // Not sure if we want to go with LOAN or PRE-APPROVAL
      creditAppType: 'BAS',
      applicants: {
        primaryApplicant: {
          firstName,
          lastName,
          phone: phoneNumber?.replace(/\D/g, ''),
          mobilePhone: phoneNumber?.replace(/\D/g, ''), // we're just gonna assume the number they've entered is their mobile number
          email: emailAddress,
          birthDate: `${(dobDate.getMonth() + 1)
            .toString()
            .padStart(2, '0')}-${dobDate
            .getDate()
            .toString()
            .padStart(2, '0')}-${dobDate.getFullYear()}`, // Dealer track needs MM-dd-yyyy
          // maritalStatus: 'MR', // not required for BAS type
          // gender, # need to add to credit app! Either "Male" or "Female".  :(
          gender: 'Male',
          housing: {
            homeMortgageOwnership: mortgageCompany,
            homeMonthlyPayment: monthlyHomePayment,
            // homeMarketValue: null,
            // homeMortgageAmount: null,
            homeOwnershipType:
              homeOwnership === 'rent'
                ? DealerTrack.HomeOwnershipType.RENT
                : homeOwnership === 'own'
                ? monthlyHomePayment
                  ? DealerTrack.HomeOwnershipType.OWN_WITH_MORTGAGE
                  : DealerTrack.HomeOwnershipType.OWN_FREE_AND_CLEAR
                : undefined,
            // we've only been asking the user "rent" or "own".  Dealer track has a lot more options
            // landlordName: null,
          },
          currentAddress: {
            // yearsAtAddress: null,
            // monthsAtAddress: null,
            address: {
              addressType: DealerTrack.AddressType.STREET, // assume street address?
              /* Not sure how we're gonna deal with these */
              streetNumber: null,
              streetName: null,
              streetType: null,
              streetDirection: null,
              /**********************/
              city,
              province:
                DealerTrack.Province[province] ?? DealerTrack.Province.AB, // default to AB
              postalCode: normalizePostalCode(postalCode),
              // ruralRouteNumber,
              // concession,
              // lotNumber,
              // civicAddress,
              // poBoxNumber,
            },
          },
          // previousAddress: DealerTrackSubmissionAddressContainer
          income: {
            mainIncome: {
              annualGrossIncome: parseInt(annualGrossIncome),
            },
          },
          currentEmployment: {
            employmentStatus: DealerTrack.EmploymentStatus.FULLTIME,
            employmentType: null,
            employerName,
            occupation: jobTitle,
            // employerPhone,
            employerDurationYears: Math.floor(yearsWithEmployer),
            employerDurationMonths: Math.round(
              (yearsWithEmployer - Math.floor(yearsWithEmployer)) * 12,
            ),
            address: {
              addressType: DealerTrack.AddressType.STREET,
              streetNumber: null,
              streetName: null,
              streetType: null,
              streetDirection: null,
              city: employerCity,
              province:
                DealerTrack.Province[employerProvince] ??
                DealerTrack.Province.AB,
              postalCode: normalizePostalCode(employerPostalCode),
              // ruralRouteNumber,
              // concession,
              // lotNumber,
              // civicAddress,
              // poBoxNumber,
            },
          },
          // previousEmployment: DealerTrackSubmissionEmployment
          // hasAcceptedCEMConsent: Boolean
          hasAcceptedTermsAndConditions: true,
          // hasAcceptedPrivacyConsent: Boolean
        },
      },
      // desiredVehicle: {},
      // tradeIn: {},
    },
  };

  // TODO: Leaving in for now, but can be removed once dealers start using this
  // creditAppId: 6782,
  // dealerId: 4175
  // data: {
  //     partnerCode: "GTO"
  //     partnerDealershipId: "GTO7031"
  //     lenderId: "ABC"
  //     productId: "LOAN"
  //     language: "EN"
  //     creditAppType: "BAS"
  //     applicants: {
  //       primaryApplicant: {
  //         firstName: "Wes"
  //         lastName: "Schmidt"
  //         email: "wschmidt@goauto.ca"
  //         gender: "Male"
  //         birthDate: "03-05-1976"
  //         maritalStatus: "MR"
  //         phone: "7809046238"
  //         mobilePhone: "7809046238"
  //         hasAcceptedTermsAndConditions: true
  //       }
  // }

  const [dealerTrackSubmission, setDealerTrackSubmission] = useState();
  const { currentUser } = useUserContext();
  const role = currentUser?.role || 'none';

  return (
    <Dialog {...rest} onClose={onClose} fullScreen={mobile} maxWidth="xl">
      <DealerTrackSubmissionDialog
        creditApp={creditApp}
        dealerTrackSubmission={dealerTrackSubmission}
        open={Boolean(dealerTrackSubmission)}
        onClose={() => setDealerTrackSubmission(null)}
        onCreate={_ => {
          setDealerTrackSubmission(null);
          refetch();
        }}
        onUpdate={_ => {
          setDealerTrackSubmission(null);
          refetch();
        }}
        fullScreen={mobile}
        maxWidth="xl"
      />
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Credit Application Details</Typography>
          <IconButton onClick={onClose} size="large">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <GridCard title="Personal Information">
          <GridItem label="First Name" value={firstName} />
          <GridItem label="Last Name" value={lastName} />
          <GridItem label="Phone" value={phoneNumber} />
          <GridItem md={3} label="Email" value={emailAddress} />
          <GridItem label="Date of Birth" value={dateOfBirth} />
          <GridItem label="Street Address" value={address} />
          <GridItem label="Suite" value={addressSuite} />
          <GridItem label="City" value={city} />
          <GridItem md={3} label="Province" value={province} />
          <GridItem label="Postal Code" value={postalCode} />
        </GridCard>

        <GridCard title={`Employment & Income`}>
          <GridItem label="Employment Status" value={employmentStatus} />
          <GridItem label="Employer Name" value={employerName} />
          <GridItem
            label="Employer Address"
            value={
              <Box display="flex" flexDirection="column">
                <Box>
                  {employerAddressSuite && `${employerAddressSuite}, `}
                  {employerAddress}
                </Box>
                <Box>
                  {employerCity && `${employerCity}, `}
                  {employerProvince} {employerPostalCode}
                </Box>
              </Box>
            }
          />
          <GridItem label="Job Title" value={jobTitle} />
          <GridItem label="Years with Employer" value={yearsWithEmployer} />
          <GridItem
            label="Annual Gross Income"
            value={formatPrice(annualGrossIncome)}
          />
        </GridCard>

        <GridCard title="Mortgage or Rent">
          <GridItem label="Home Ownership Type" value={homeOwnership} />
          <GridItem
            label="Mortgage Company or Landlord"
            value={mortgageCompany}
          />
          <GridItem
            label="Monthly Rent or Payment"
            value={formatPrice(monthlyHomePayment)}
          />
          <GridItem label="Preferred Lender" value={preferredLender} />
        </GridCard>

        <GridCard title="Vehicle Trade-In">
          <GridItem
            label="Vehicle Trade-In?"
            value={hasVehicleTrade ? 'Yes' : 'No'}
          />
          <GridItem label="Year" value={vehicleTradeYear} />
          <GridItem label="Make" value={vehicleTradeMakeName} />
          <GridItem label="Model" value={vehicleTradeModelName} />
          <GridItem label="Trim" value={vehicleTradeTrim} />
          <GridItem
            label="Current KM"
            value={formatNumber(vehicleTradeOdometer)}
          />
        </GridCard>

        {/* For now, let's restrict this completely to users that can submit to dealer track */}
        {canSendToDealerTrack && (
          <GridCard
            title="Dealer Track Submissions"
            CardHeaderProps={{
              action: canSendToDealerTrack && (
                <IconButton
                  onClick={() =>
                    setDealerTrackSubmission(newDealerTrackSubmissionDefaults)
                  }
                  size="large"
                >
                  <AddIcon />
                </IconButton>
              ),
            }}
          >
            <Grid item xs={12}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell /> {/* Edit button */}
                    <TableCell>ID</TableCell>
                    <TableCell>Date Created</TableCell>
                    <TableCell>Created By</TableCell>
                    <TableCell>Modified By</TableCell>
                    <TableCell>Deal ID</TableCell>
                    <TableCell>Reference Number</TableCell>
                    <TableCell>Application Status</TableCell>
                    <TableCell>Error</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* dealerTrackSubmissions could be null if 403 */}
                  {dealerTrackSubmissions?.map(dts => (
                    <TableRow key={dts.id}>
                      <TableCell>
                        <IconButton
                          onClick={() => setDealerTrackSubmission(dts)}
                          size="large"
                        >
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                      <TableCell>{dts.id}</TableCell>
                      <TableCell>{dts.dateCreated}</TableCell>
                      <TableCell>{dts.createdByUser?.display_name}</TableCell>
                      <TableCell>{dts.modifiedByUser?.display_name}</TableCell>
                      <TableCell>{dts.dealerTrackDealId}</TableCell>
                      <TableCell>{dts.dealerTrackReferenceNum}</TableCell>
                      <TableCell>{dts.applicationStatus}</TableCell>
                      <TableCell>{dts.error}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
          </GridCard>
        )}
        {CAN_VIEW_EQUIFAX_ROLES.includes(role) && (
          <GridCard title="Equifax Pre-Qualify">
            <GridItem
              label="Pre-Qualify Credit Score"
              value={equifaxPrequalifyCreditScore}
              md={3}
            />
            <GridItem
              label="Pre-Qualify Date"
              value={equifaxPrequalifyDate}
              md={3}
            />
            <GridItem
              label="Neighbourhood View Score"
              value={equifaxNeighbourhoodViewScore}
              md={3}
            />
            <GridItem
              label="Neighbourhood View Date"
              value={equifaxNeighbourhoodViewDate}
              md={3}
            />
          </GridCard>
        )}
      </DialogContent>
    </Dialog>
  );
};

CreditAppDetailsDialog.fragments = {
  creditApp: gql`
    fragment CreditAppDetailsDialogCreditApp on CreditApp {
      id
      dealerId
      dealerTrackSubmissions {
        id
        ...DealerTrackSubmissionDialogDealerTrackSubmission
      }
      address
      addressSuite
      annualGrossIncome
      city
      dateOfBirth
      emailAddress
      employerAddress
      employerAddressSuite
      employerCity
      employerName
      employerPostalCode
      employerProvince
      employmentStatus
      firstName
      hasVehicleTrade
      homeOwnership
      jobTitle
      lastName
      monthlyHomePayment
      mortgageCompany
      permissions {
        canSendToDealerTrack
      }
      phoneNumber
      postalCode
      preferredLender
      province
      vehicleTradeMakeName
      vehicleTradeModelName
      vehicleTradeOdometer
      vehicleTradeTrim
      vehicleTradeYear
      yearsWithEmployer
      equifaxPrequalifyCreditScore
      equifaxPrequalifyDate
      equifaxNeighbourhoodViewScore
      equifaxNeighbourhoodViewDate
      ...DealerTrackSubmissionDialogCreditApp
    }
    ${DealerTrackSubmissionDialog.fragments.creditApp}
    ${DealerTrackSubmissionDialog.fragments.dealerTrackSubmission}
  `,
};

export default CreditAppDetailsDialog;
