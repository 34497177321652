import gql from 'graphql-tag';
import ConversationListItem from './ConversationListItem';

export const CONVERSATIONS_QUERY = gql`
  query conversationsQuery(
    $gatewayId: Int
    $page: Int
    $filters: ConversationFilters
  ) {
    sms {
      conversations(page: $page, gateway_id: $gatewayId, filters: $filters) {
        results {
          id
          stars {
            conversation_id
            username
          }
          operator_username
          operator_user {
            display_name
            username
          }
          archived
          customer_phone
          ...ConversationListItem
        }
        pagination {
          has_prev
          prev_num
          page
          has_next
          next_num
          total
          pages
        }
      }
    }
  }
  ${ConversationListItem.fragments.conversation}
`;
