import React from 'react';

/* Material UI */
import { makeStyles } from '@mui/styles';
import { Box, Chip } from '@mui/material';

const useStyles = makeStyles(theme => ({
  chipBox: {
    paddingRight: '5px',
    paddingBottom: '5px',
  },
}));

const FacetChips = ({ facets, onDeleteFacet, others = [] }) => {
  const classes = useStyles();
  return (
    <Box display="flex" flexWrap="wrap">
      {facets.map(({ field, model, value, options }) => (
        <Box
          component="span"
          key={`${model}${field}${value}`}
          className={classes.chipBox}
        >
          <Chip
            label={
              options?.label
                ? options.label
                : value === null && options?.nullLabel
                ? options.nullLabel
                : options?.format
                ? options.format(value)
                : value
            }
            value={value}
            onDelete={() => onDeleteFacet(model, field, value)}
          />
        </Box>
      ))}
      {others.map(({ label, onDelete }, index) => (
        <Box component="span" key={`${label}${index}`}>
          <Chip label={label} value={label} onDelete={onDelete} />
        </Box>
      ))}
    </Box>
  );
};

export default FacetChips;
