import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@mui/material';

const AutocompleteControl = ({
  control,
  defaultValue = null,
  helperText,
  InputLabelProps = {},
  label = '',
  name,
  options = [],
  optionLabelKey = 'label',
  optionValueKey = 'value',
  required,
  ...rest
}) => {
  const formContext = useFormContext();
  return (
    <Controller
      control={control ?? formContext.control}
      defaultValue={defaultValue}
      name={name}
      render={({
        field: { ref, onChange, value, ...field },
        formState: { errors },
      }) => (
        <Autocomplete
          {...field}
          onChange={(e, data) => onChange(data?.[optionValueKey])}
          options={options}
          isOptionEqualToValue={(option, val) =>
            option[optionValueKey] === val[optionValueKey]
          }
          value={options.find(option => option[optionValueKey] === value) ?? ''}
          renderInput={params => (
            <TextField
              {...params}
              error={Boolean(errors?.[name])}
              helperText={errors?.[name]?.message ?? helperText}
              InputLabelProps={InputLabelProps}
              label={label}
              required={required}
            />
          )}
          getOptionLabel={option => option?.[optionLabelKey] ?? ''}
          {...rest}
        />
      )}
    />
  );
};

export default AutocompleteControl;
