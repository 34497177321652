import React from 'react';

/* external */
import { cloneDeep, isArray, isObject, startCase } from 'lodash';

/* Material UI */
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';

/* internal */
import { Status, StatusDisplay } from 'constants.js';
import { formatChipDate } from '../utils';

const OpportunitiesChips = ({
  filters,
  updateFilters,
  users,
  refetch,
  updateDates,
  updateKeywords,
}) => {
  const chips = [];

  const getDisplayName = username => {
    let displayName = username;
    users.forEach(user => {
      if (user.username === username) {
        displayName = user.display_name;
      }
    });
    return displayName;
  };

  // Build Chips
  for (const [key, value] of Object.entries(filters)) {
    if (key === 'sort_by') {
      continue;
    }

    // An array
    if (isArray(value)) {
      value.forEach(item => {
        // Statuses
        if (key === 'statuses') {
          chips.push({
            filter: key,
            value: item,
            display: StatusDisplay[item],
          });
          // Assignees
        } else {
          chips.push({
            filter: key,
            value: item,
            display: getDisplayName(item),
          });
        }
      });
    } else if (isObject(value)) {
      chips.push({ filter: key, value: value, display: formatChipDate(value) });
    } else if (value !== undefined) {
      if (key === 'stock_type' && value === '') {
        chips.push({ filter: key, value: value, display: 'Unknown' });
      } else {
        chips.push({
          filter: key,
          value: value,
          display: startCase(value).replace('Sms', 'Text'),
        });
      }
    }
  }

  // When a chip is removed, refetch
  const removeFilter = ({ filter, value }) => {
    const updatedFilters = cloneDeep(filters);
    if (isArray(updatedFilters[filter])) {
      const index = updatedFilters[filter].indexOf(value);
      updatedFilters[filter].splice(index, 1);
      if (updatedFilters[filter].length === 0) {
        updatedFilters[filter] = undefined;
      }
    } else if (isObject(updatedFilters[filter])) {
      updatedFilters[filter] = undefined;
      updateDates({ date_to: null, date_from: null });
    } else {
      if (filter === 'keywords') {
        updateKeywords('');
      }
      updatedFilters[filter] = undefined;
    }

    const allStatuses = updatedFilters['statuses'] === undefined;
    if (allStatuses) {
      updatedFilters['statuses'] = Status.ALL;
    }

    refetch({
      ...updatedFilters,
    });

    if (allStatuses) {
      updatedFilters['statuses'] = undefined;
    }
    updateFilters(updatedFilters);
  };

  return (
    <Box display="flex" flexWrap="wrap">
      {chips.map(chip => (
        <Chip
          key={`${chip.filter}-${chip.value}`}
          label={chip.display}
          value={chip.value}
          style={{ marginRight: '2px' }}
          onDelete={() => removeFilter(chip)}
        ></Chip>
      ))}
    </Box>
  );
};

export default OpportunitiesChips;
