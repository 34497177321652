import UnknownIcon from '@mui/icons-material/Help';
import MailIcon from '@mui/icons-material/Mail';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import { startCase, toLower } from 'lodash';
import React from 'react';

// Utility functions used for customers

export function getUpdatedDate(fromFlex, messages) {
  // If its not a flex message we can ignore
  if (!fromFlex || messages.length === 0) {
    return null;
  }

  // Get the date from the most recent message
  return messages[0].date_created;
}

export const identityTypeIcon = identityType => {
  switch (identityType) {
    case 'email':
      return <MailIcon />;
    case 'sms':
      return <SmartphoneIcon />;
    default:
      return <UnknownIcon />;
  }
};

export const initials = customer =>
  customer.first_name && customer.last_name
    ? `${customer?.first_name?.charAt(0)}${customer?.last_name?.charAt(0)}`
    : 'CX';

export const titleCase = x => startCase(toLower(x));

export const hasNonWhiteSpaceCharacter = str => {
  const regex = /\S/;
  return str && regex.test(str);
};

// Returns if a number is in the global suppressions list
// (pass in number, customer)
export const suppressed = (number, { global_suppressions = [] }) =>
  global_suppressions
    ?.filter(x => x?.number === number)
    .some(x => x.suppressed);
