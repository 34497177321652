import React from 'react';

import { startCase, toLower } from 'lodash';
import { Controller } from 'react-hook-form';
import { makeStyles } from '@mui/styles';

import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';

import { CBB_CONDITIONS } from '../../const';
import { formatPrice } from 'utils';
import { NoValuationAlert } from '../alerts/CBBWizardAlerts';
import RadioControl from 'components/MaterialUI/RadioControl2';
import {
  CBBStyleRadioGroup,
  CBBConditionRadioGroup,
  CBBTrimRadioGroup,
} from '../vehicle/cbb/CBBRadioGroups';

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const CBBTable = ({
  selectedCondition,
  priceAdjustments,
  wholesalePrices,
  wholesaleTotals,
  retailPrices,
  retailTotals,
  control,
  styleNames,
  options,
  trimNames,
  noValuation,
}) => {
  const classes = useStyles();

  return (
    <>
      <Grid container>
        <Grid item sm>
          <fieldset>
            <FormLabel style={{ fontWeight: 'bold' }} component="legend">
              Trim
            </FormLabel>

            <RadioControl
              control={control}
              name="trim"
              as={<CBBTrimRadioGroup trimNames={trimNames} />}
            />
          </fieldset>
        </Grid>
        <Grid item sm>
          <fieldset>
            <FormLabel style={{ fontWeight: 'bold' }} component="legend">
              Style
            </FormLabel>
            <RadioControl
              control={control}
              name="style"
              as={<CBBStyleRadioGroup styleNames={styleNames} />}
            />
          </fieldset>
        </Grid>
        <Grid item sm>
          <FormControl component="fieldset">
            <FormLabel style={{ fontWeight: 'bold' }} component="legend">
              Equipment
            </FormLabel>
            <Controller
              control={control}
              name="equipment"
              render={({ field: { onChange, value } }) => {
                const selected = value ? JSON.parse(value) : [];
                const isSelected = option_code =>
                  selected.includes(option_code);
                const handleChange =
                  option_code =>
                  ({ target: { checked } }) =>
                    onChange(
                      JSON.stringify(
                        checked
                          ? [...selected, option_code]
                          : selected.filter(x => x !== option_code),
                      ),
                    );

                return (
                  <FormGroup>
                    {options.length === 0 && (
                      <div style={{ paddingTop: '10px' }}>
                        No additional equipment available.
                      </div>
                    )}
                    {options.map(({ option_code, description }) => (
                      <FormControlLabel
                        key={`${option_code}`}
                        label={description}
                        control={
                          <Checkbox
                            checked={isSelected(option_code)}
                            value={option_code}
                            onChange={handleChange(option_code)}
                          />
                        }
                      />
                    ))}
                  </FormGroup>
                );
              }}
            />
          </FormControl>
        </Grid>
        <Grid item sm>
          <fieldset>
            <FormLabel style={{ fontWeight: 'bold' }} component="legend">
              Condition
            </FormLabel>
            <RadioControl
              control={control}
              name="condition"
              as={<CBBConditionRadioGroup />}
            />
          </fieldset>
        </Grid>
      </Grid>
      {noValuation && <NoValuationAlert />}
      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell rowSpan={1} />
              <TableCell colSpan={3} align="left">
                Wholesale
              </TableCell>
              <TableCell colSpan={3} align="left">
                Retail
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell rowSpan={1} />
              <TableCell style={{ textAlign: 'left' }}>Price</TableCell>
              <TableCell style={{ textAlign: 'left' }}>Adjustment</TableCell>
              <TableCell style={{ textAlign: 'left' }}>Total</TableCell>
              <TableCell style={{ textAlign: 'left' }}>Price</TableCell>
              <TableCell style={{ textAlign: 'left' }}>Adjustment</TableCell>
              <TableCell style={{ textAlign: 'left' }}>Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {CBB_CONDITIONS.map(key => (
              <TableRow selected={key === selectedCondition} key={key}>
                <TableCell
                  style={
                    key === selectedCondition ? { fontWeight: 'bold' } : {}
                  }
                >
                  {startCase(toLower(key))}
                </TableCell>
                <TableCell align="left">
                  {formatPrice(wholesalePrices[key])}
                </TableCell>
                <TableCell align="left">
                  {formatPrice(priceAdjustments(key))}
                </TableCell>
                <TableCell align="left">
                  {formatPrice(wholesaleTotals[key])}
                </TableCell>
                <TableCell align="left">
                  {formatPrice(retailPrices[key])}
                </TableCell>
                <TableCell align="left">
                  {formatPrice(priceAdjustments(key))}
                </TableCell>
                <TableCell align="left">
                  {formatPrice(retailTotals[key])}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default CBBTable;
