import React from 'react';

/* external */
import moment from 'moment';
import gql from 'graphql-tag';

/* Material UI */
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { makeStyles } from '@mui/styles';
import { formatPrice } from '../../../../../utils';

const useStyles = makeStyles(() => ({
  success: {
    backgroundColor: '#dff0d8',
  },
  failed: {
    backgroundColor: '#f2dede',
  },
  processing: {
    backgroundColor: '#fcf8e3',
  },
}));

const PaymentScheduleModal = ({ goldCard, onClose, isOpen }) => {
  const classes = useStyles();
  const { payments } = goldCard;
  const rowColor = status => {
    return status === 'Success'
      ? classes.success
      : status === 'Failed'
      ? classes.failed
      : status === 'Processing'
      ? classes.processing
      : null;
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        onClose();
      }}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Payment Schedule</DialogTitle>

      <>
        <DialogContent>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Status</TableCell>
                  <TableCell>Scheduled</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Transacted</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {payments.map(payment => (
                  <TableRow
                    key={payment.id}
                    className={rowColor(payment.status)}
                  >
                    <TableCell>{payment.status}</TableCell>
                    <TableCell>
                      {payment.scheduledDate
                        ? moment(payment.scheduledDate).format('YYYY-MM-DD')
                        : ''}
                    </TableCell>
                    <TableCell>
                      {formatPrice(payment.amount, { cents: true })}
                    </TableCell>
                    <TableCell>
                      {payment.transactedOn
                        ? moment(payment.transactedOn).format('YYYY-MM-DD')
                        : ''}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary" variant="outlined">
            Cancel
          </Button>
        </DialogActions>
      </>
    </Dialog>
  );
};

PaymentScheduleModal.fragments = {
  payment: gql`
    fragment PaymentScheduleModalPayment on Payment {
      status
      scheduledDate
      amount
      transactedOn
    }
  `,
};

export default PaymentScheduleModal;
