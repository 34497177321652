import React from 'react';

/* external */
import { Switch, Route } from 'react-router-dom';

/* internal */
import app from 'modules/app';
import SellMyRideListView from './sell_my_ride/SellMyRideListView';

const SellMyRide = ({ match }) => {
  return (
    <app.components.Layout title="SellMyRide">
      <Switch>
        <Route exact path={`${match.url}/`} component={SellMyRideListView} />
      </Switch>
    </app.components.Layout>
  );
};

export default SellMyRide;
