import React from 'react';

/* Material UI */
import { Box } from '@mui/material';

const EmptyMessage = () => (
  <Box textAlign="left" paddingBottom={1} paddingTop={1}>
    There are no used vehicles!
  </Box>
);

export default EmptyMessage;
