import React, { useState } from 'react';
import gql from 'graphql-tag';

import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';

import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { titleCase } from '../../utils';
import { useCodeTablesContext } from 'components/MaterialUI/CodeTablesContext';
import { useVehiclesQueryContext } from '../contexts/VehiclesQueryContext';
import ErrorDisplay from 'components/MaterialUI/ErrorDisplay';

const UPDATE_STOCK_STATUS_ID = gql`
  mutation updateStockStatusID($filters: [QueryFilter!]!, $statusID: Int!) {
    inventory {
      updateBulkVehicles(
        filters: $filters
        data: { stock_status_id: $statusID }
      ) {
        id
        date_user_modified
        stock_status_id
        stock_status_name
      }
    }
  }
`;

const BulkVehicleStatus = ({ ids, open, onClose, selectByFilter }) => {
  const { filters: vehicleFilters, refetch } = useVehiclesQueryContext();
  const {
    codeTables: { stockStatuses = [] },
    loading: codeTablesLoading,
    error: codeTablesError,
  } = useCodeTablesContext();
  const { enqueueSnackbar } = useSnackbar();

  const [statusID, setStatusID] = useState();

  const [update, updateStatus] = useMutation(UPDATE_STOCK_STATUS_ID, {
    onCompleted: data => {
      // This _should_ refetch vehicles with the current sorting/filtering.
      refetch({ variables: { page: 1 } });
      onClose();
      enqueueSnackbar(
        `Successfully updated ${data.inventory.updateBulkVehicles.length} vehicles' statuses.`,
      );
      // https://english.stackexchange.com/questions/877/what-is-the-plural-form-of-status
      // https://www.merriam-webster.com/dictionary/status
    },
    onError: () => null,
  });

  const filters = selectByFilter
    ? vehicleFilters
    : [{ model: 'Vehicle', field: 'id', op: 'in', value: ids }];
  const handleSubmit = () => update({ variables: { filters, statusID } });
  const handleChange = e => setStatusID(e.target.value);

  if (codeTablesLoading) return <>Loading Code Tables...</>;
  if (codeTablesError) return <>Error{JSON.stringify(codeTablesError)}</>;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Set Bulk Vehicle Status</DialogTitle>
      <DialogContent>
        {updateStatus.error && (
          <Alert severity="warning">
            <ErrorDisplay
              error={updateStatus.error}
              action="Updating Vehicles' Statuses"
            />
          </Alert>
        )}
        <FormControl>
          <InputLabel>Move to Status</InputLabel>
          <Select
            onChange={handleChange}
            style={{ width: '200px' }}
            value={statusID}
          >
            {stockStatuses.map(({ id, name }) => (
              <MenuItem value={id} key={id}>
                {titleCase(name)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        {updateStatus.loading ? (
          <CircularProgress
            size={20}
            style={{ marginLeft: '40px', marginRight: '30px' }}
          />
        ) : (
          <Button
            autoFocus
            onClick={handleSubmit}
            variant="contained"
            style={{
              backgroundColor: '#74B72E',
            }}
          >
            Submit
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default BulkVehicleStatus;
