import React from 'react';

/* external */
import { groupBy, sortBy } from 'lodash';
import { useFormContext, useWatch } from 'react-hook-form';
import { useLazyQuery } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import gql from 'graphql-tag';

/* Material UI */
import { makeStyles } from '@mui/styles';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import DeleteIcon from '@mui/icons-material/Delete';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

/* internal */
import { DECODE_OPTIONS } from './VehicleOptions';
import { parseErrors } from '../../utils';
import Loading from 'components/MaterialUI/Loading';
import { generateOptionCodes } from '../../utils';

const useStyles = makeStyles(theme => ({
  alert: {
    width: '100%',
    padding: '0 5px 0px 5px',
  },
  heading: {
    fontSize: '16px',
    fontWeight: 'bold',
    textDecorationLine: 'underline',
    color: 'black',
    textTransform: 'uppercase',
  },
  subHeading: {
    fontSize: '14px',
    fontWeight: 'bold',
    textDecorationLine: 'underline',
    color: 'black',
    marginTop: '20px',
    marginBottom: '5px',
  },
  item: {
    padding: '5px',
  },
}));

// TODO: add button to categorize options from decode data?  (for vehicles pre-v2
// that do not have header/group in their options)
const VehicleEquipment = ({ vehicle }) => {
  const classes = useStyles();
  const shouldDirty = true;
  const { setValue, control } = useFormContext();
  const { enqueueSnackbar } = useSnackbar();

  const currentOptions = useWatch({
    control,
    name: 'options',
    defaultValue: vehicle.options,
  });
  const currentEquipment = useWatch({
    control,
    name: 'equipment',
    defaultValue: vehicle.equipment,
  });
  const currentStyleId = useWatch({
    control,
    name: 'style_id',
    defaultValue: vehicle.style_id,
  }); // style_id indicates the vehicle
  // has been decoded (get from form value in case vehicle hasn't been saved yet)
  const currentVin = useWatch({
    control,
    name: 'vin',
    defaultValue: vehicle.vin,
  });

  const [decodeOptions, decodeVehicleOptions] = useLazyQuery(DECODE_OPTIONS, {
    fetchPolicy: 'network-only',
    onCompleted: res =>
      setValue(
        'equipment',
        res?.inventory.decodeVehicleOptions.filter(x => !x.option_code),
        { shouldDirty },
      ),
    onError: err =>
      enqueueSnackbar(
        parseErrors(err).error?.join('\n') ||
          'An unknown error occured while decoding',
      ),
  });

  const grouped = groupBy(currentEquipment, x => x.group || 'Other');

  const deleteHandler =
    ({ option_value, group, header }) =>
    () =>
      setValue(
        'equipment',
        currentEquipment.filter(
          x =>
            x.header !== header ||
            x.option_value !== option_value ||
            x.group !== group,
        ),
        { shouldDirty },
      );

  const handleReset = () => {
    const optionCodes = generateOptionCodes(
      currentOptions,
      vehicle.cdk_options,
    );
    decodeOptions({
      variables: { currentStyleId, filters: { OEMOptionCode: optionCodes } },
    });
  };

  if (decodeVehicleOptions.loading) return <Loading />;

  return (
    <Paper style={{ width: '100%' }}>
      <Container>
        <Typography style={{ padding: '1rem 1rem 1rem 0' }} variant="h6">
          Equipment
        </Typography>
        {!currentStyleId && (
          <div style={{ paddingBottom: '1rem' }}>
            <Alert className={classes.alert} severity="warning">
              Vehicle is not decoded. Please decode vehicle to see available
              equipment.
            </Alert>
          </div>
        )}
        {currentStyleId && (
          <>
            <div style={{ paddingBottom: '1rem' }}>
              <Alert className={classes.alert} severity="info">
                This is a list of the equipment that is available for this
                vehicle. Equipment cannot be added and is based on decoding the
                vehicle. Equipment that is not present on the vehicle can be
                removed.
              </Alert>
            </div>
            <div style={{ width: '100%' }}>
              {sortBy(Object.keys(grouped)).map(group => (
                <div key={group} style={{ padding: '1rem 0 1rem 0' }}>
                  <FormLabel className={classes.heading} component="legend">
                    {group}
                  </FormLabel>
                  {sortBy(
                    Object.entries(
                      groupBy(
                        grouped[group],
                        x => x.header || 'Unknown Sub-category',
                      ),
                    ),
                    ([k, v]) => k,
                  ).map(([header, option]) => (
                    <span key={group + header}>
                      <FormLabel
                        className={classes.subHeading}
                        component="legend"
                      >
                        {header}
                      </FormLabel>
                      <Grid container>
                        {sortBy(option, x => x.option_value).map(x => (
                          <Grid
                            container
                            item
                            key={group + header + x.option_value}
                            xs={12}
                            md={6}
                            className={classes.item}
                          >
                            <Grid item xs={11} md={5}>
                              {x.option_value}
                            </Grid>
                            <Grid item xs={1}>
                              <IconButton
                                onClick={deleteHandler(x)}
                                size="small"
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                    </span>
                  ))}
                </div>
              ))}
            </div>
          </>
        )}
        {currentStyleId && currentVin && (
          <Grid
            container
            direction="row-reverse"
            sx={{ marginBottom: '20px', padding: '10px' }}
          >
            <Grid item>
              <Button onClick={handleReset} variant="contained" color="default">
                RESET
              </Button>
            </Grid>
          </Grid>
        )}
        {currentEquipment && currentEquipment.length === 0 && (
          <div style={{ paddingBottom: '1rem' }}>
            <Alert className={classes.alert} severity="warning">
              No equipment found.
            </Alert>
          </div>
        )}
      </Container>
    </Paper>
  );
};

VehicleEquipment.fragments = {
  vehicle: gql`
    fragment VehicleEquipmentVehicle on GreaseInventoryVehicle {
      options {
        id
        group
        header
        option_code
        option_value
        order
      }
      style_id
      vin
    }
  `,
};
export default VehicleEquipment;
