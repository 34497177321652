// External
import gql from 'graphql-tag';
import { isEmpty, startCase } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';

// Material UI
import {
  Box,
  FormControlLabel,
  Grid,
  IconButton,
  Menu,
  TableCell,
  Tooltip,
  Typography,
} from '@mui/material';
import { Email, Info, MoreVert, Smartphone } from '@mui/icons-material';

// Internal
import UpdateCardStatus from '../CustomerDetails/GoCard/UpdateCardStatus';

const AdvancedSearchResult = ({ fields, customer }) => {
  const {
    city,
    date_created,
    description,
    email_addresses,
    full_name,
    phone_numbers,
    province,
    urn,
  } = fields;

  const [anchorEl, setAnchorEl] = useState(null);
  const location =
    !isEmpty(city) && !isEmpty(province) ? `${city[0]}, ${province[0]}` : '';

  const date = !isEmpty(date_created)
    ? moment(date_created[0]).format('LL')
    : 'N/A';

  const goCardUrn =
    fields?.record_type?.[0] === 'gocard'
      ? urn
          .filter(r => r.startsWith('goauto.gocard:gocard:'))
          .map(x => x.split(':')[2])
          .map(id => ({ id }))
      : null;

  const goCardRecord = customer?.gocards?.filter(
    goCard => goCard?.id === parseInt(goCardUrn?.[0]?.id, 10),
  );

  const menuItemHandler = event => {
    event.stopPropagation();
    handleClose();
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <TableCell>
        <Tooltip title={!customer ? '' : 'Open In New Tab'}>
          {/* Add <span> wrapper to allow a tooltip with a button that is disabled
           (complains if there isn't a wrapper around it) */}
          <span>
            <IconButton
              disabled={!customer}
              size="small"
              onClick={e => {
                window.open(`/customers/${customer._id}/details`, '_blank');
                e.stopPropagation();
              }}
            >
              <Info
                style={{
                  color: customer ? '#2196f3' : 'grey',
                  fontSize: '22px',
                }}
              />
            </IconButton>
          </span>
        </Tooltip>
      </TableCell>
      <TableCell>
        <Typography>
          {startCase(full_name?.[0] ?? 'Unknown Customer')}
        </Typography>
        {location}
      </TableCell>
      <TableCell>
        <Typography>
          {startCase(fields?.record_type?.[0] ?? 'Unknown Type')}
        </Typography>
      </TableCell>
      <TableCell>
        <Grid container>
          {!isEmpty(phone_numbers) && (
            <>
              <Grid item xs={1}>
                <Smartphone fontSize="small" />
              </Grid>
              <Grid item xs={11}>
                <Typography>{phone_numbers[0]}</Typography>
              </Grid>
            </>
          )}
          {!isEmpty(email_addresses) && (
            <>
              <Grid item xs={1}>
                <Email fontSize="small" />
              </Grid>
              <Grid item xs={11}>
                <Typography>{email_addresses[0]}</Typography>
              </Grid>
            </>
          )}
        </Grid>
      </TableCell>
      <TableCell>
        <Typography>{description?.[0] ?? 'N/A'}</Typography>
        {/* GO CARD */}
        {fields?.record_type?.[0] === 'gocard' && (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant={'body2'}>
              <b>Card Status: </b>
              {goCardRecord?.[0].accountV2?.active ? 'Active' : 'Inactive'}
            </Typography>
            {goCardRecord?.[0].accountV2?.permissions?.manageAccounts && (
              <Box ml="5px">
                <FormControlLabel
                  onClick={event => event.stopPropagation()}
                  onFocus={event => event.stopPropagation()}
                  control={
                    <IconButton size="small" onClick={handleClick}>
                      <MoreVert />
                    </IconButton>
                  }
                />
                <Menu
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={event => menuItemHandler(event)}
                >
                  <UpdateCardStatus
                    active={goCardRecord[0]?.accountV2?.active}
                    cardId={goCardRecord[0]?.accountV2?.cardId}
                    setAnchorEl={setAnchorEl}
                  />
                </Menu>
              </Box>
            )}
          </Box>
        )}
      </TableCell>
      <TableCell>
        <Typography>{date}</Typography>
      </TableCell>
    </>
  );
};

AdvancedSearchResult.fragments = {
  fields: gql`
    fragment AdvancedSearchResultFragment on CustomerSearchHitFields {
      urn
      city
      date_created
      description
      email_addresses
      first_name
      full_name
      last_name
      phone_numbers
      province
      record_type
      resource_type
    }
  `,
  customer: gql`
    fragment AdvancedSearchResultCustomer on Customer {
      gocards {
        id
        accountV2 {
          active
          cardId
          permissions {
            manageAccounts
          }
        }
      }
    }
  `,
};

export default AdvancedSearchResult;
