import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Paper from '@mui/material/Paper';
import Loading from 'components/MaterialUI/Loading';
import { Typography } from '@mui/material';

import SegmentTraitDisplay from './SegmentTraitDisplay';
import SegmentGroupedTraitDisplay from './SegmentGroupedTraitDisplay';
import {
  CUSTOM_TRAITS_LIST,
  COMPUTED_TRAITS_LIST,
  SQL_TRAITS_LIST,
} from '../constant';

const useStyles = makeStyles(theme => ({
  tabPanel: {
    padding: theme.spacing(1),
  },
  paper: {
    padding: '20px',
    minHeight: '300px',
  },

  title: {
    fontSize: 16,
    fontWeight: 'bold',
  },
}));

const CUST_SEGMENT_TRAITS_QUERY = gql`
  query CustomerSegmentTraits($_id: ID!) {
    customerSegmentTraits(_id: $_id) {
      custom_traits {
        anonymousId
        name
        firstName
        lastName
        last_inbound_sms_message
        last_outbound_sms_message
        phone
        total_inbound_sms_messages
        total_outbound_sms_messages
        mobile
        address {
          street
          city
          state
          postalCode
        }
        casl
      }
      computed_traits {
        sent_lead_7_day_count
        viewed_vehicle_7_day_count
        viewed_vehicle_average_list_price
        viewed_vehicle_frequent_make
        viewed_vehicle_frequent_model
        viewed_vehicle_frequent_stock_type
      }
      sql_traits {
        active_opportunity_count
        email
        last_opportunity_created
        last_opportunity_updated
        started_opportunity_last_14_days
        veh1_basic_warranty_expires
        veh1_basic_warranty_months
        veh1_basic_warranty_kms
        veh1_computed_on
        veh1_date_purchased
        veh1_deal_type
        veh1_last_payment_date
        veh1_last_seen
        veh1_last_seen_at_dealer_id
        veh1_last_seen_at_dealer_name
        veh1_last_touch
        veh1_make
        veh1_model
        veh1_odometer
        veh1_payment_type
        veh1_purchased_at_dealer_id
        veh1_purchased_at_dealer_name
        veh1_vin
        veh1_year
        veh_total
        gocard_balance
        gocard_balance_date
        gocard_id
        gocard_number
        gocard_referral
        casl_implicit_consent_expires
        casl_implicit_consent_reason
        email_consent
        sms_consent
      }
    }
  }
`;

const SegmentProfile = ({ customerId }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { tabPanel } = useStyles(theme);
  const { data, loading } = useQuery(CUST_SEGMENT_TRAITS_QUERY, {
    variables: {
      _id: customerId,
    },
  });

  const SQLTraits = data?.customerSegmentTraits?.sql_traits || [];
  const customTraits = data?.customerSegmentTraits?.custom_traits || [];
  const computedTraits = data?.customerSegmentTraits?.computed_traits || [];

  const [currentTab, setCurrentTab] = useState('Custom');
  const handleTabChange = (e, value) => setCurrentTab(value);
  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Paper className={classes.paper}>
        <TabContext value={currentTab}>
          <Typography className={classes.title}>
            Segment Profile Details
          </Typography>
          <Typography variant="subtitle1">
            This shows the information collected for this customers profile in
            Segment.
          </Typography>
          <TabList
            onChange={handleTabChange}
            TabIndicatorProps={{ style: { background: '#ff5a00' } }}
          >
            <Tab value="Custom" label="CUSTOM TRAITS" />
            <Tab value="Computed" label="COMPUTED TRAITS" />
            <Tab value="SQL" label="SQL TRAITS" />
          </TabList>
          <TabPanel value="Custom" className={tabPanel} index={0}>
            <Typography variant="subtitle2">
              Customer information that is included in a Segment identify call.
            </Typography>
            <Typography variant="subtitle2">
              An identify call allows us to tie actions to individual customers.
            </Typography>
            <Grid
              container
              spacing={1}
              style={{ marginBottom: theme.spacing(1), padding: '20px' }}
              alignItems="center"
            >
              {customTraits?.firstName && (
                <SegmentGroupedTraitDisplay
                  groupedTraits={{
                    Name: customTraits?.name,
                    'First Name': customTraits?.firstName,
                    'Last Name': customTraits?.lastName,
                  }}
                  groupedTraitsName="Customer Details"
                />
              )}
              {customTraits?.phone && (
                <SegmentGroupedTraitDisplay
                  groupedTraits={{
                    Phone: customTraits?.phone,
                    Mobile: customTraits?.mobile,
                    CASL: customTraits?.casl,
                  }}
                  groupedTraitsName="Contact Information"
                />
              )}
              {customTraits?.address?.city && (
                <SegmentGroupedTraitDisplay
                  groupedTraits={{
                    City: customTraits?.address.city,
                    Street: customTraits?.address.street,
                    Province: customTraits?.address.state,
                    'Postal Code': customTraits?.address.postalCode,
                  }}
                  groupedTraitsName="Address"
                />
              )}

              {CUSTOM_TRAITS_LIST.map(
                (trait, index) =>
                  (customTraits[trait.value] ||
                    customTraits[trait.value] === 0) && (
                    <SegmentTraitDisplay
                      key={`${trait}:${index}`}
                      traitName={trait.text}
                      traitValue={customTraits[trait.value]}
                      fieldName={trait.value}
                    />
                  ),
              )}
            </Grid>
          </TabPanel>
          <TabPanel value="Computed" className={tabPanel} index={1}>
            <Typography variant="subtitle2">
              User-level computations that Segment keeps up-to-date over time.
            </Typography>
            <Typography variant="subtitle2">
              Unlike custom traits, they are not included in an identify call
              but can be used to build an audience or group of customers. This
              helps optimize ad spend and improve targeting.
            </Typography>
            <Grid
              container
              spacing={1}
              style={{ marginBottom: theme.spacing(1), padding: '20px' }}
              alignItems="center"
            >
              {COMPUTED_TRAITS_LIST.map(
                (trait, index) =>
                  (computedTraits[trait.value] ||
                    computedTraits[trait.value] === 0) && (
                    <SegmentTraitDisplay
                      key={`${trait}:${index}`}
                      traitName={trait.text}
                      traitValue={computedTraits[trait.value]}
                      fieldName={trait.value}
                    />
                  ),
              )}
            </Grid>
          </TabPanel>
          <TabPanel value="SQL" className={tabPanel} index={2}>
            <Typography variant="subtitle2">
              User traits imported from a data warehouse, such as Redshift, to
              enhance Segment data.
            </Typography>
            <Typography variant="subtitle2">
              They can be used to trigger marketing automations and personalize
              communications to a customer.
            </Typography>
            <Grid
              container
              spacing={1}
              style={{ marginBottom: theme.spacing(1), padding: '20px' }}
            >
              {SQL_TRAITS_LIST.map(
                (trait, index) =>
                  (SQLTraits[trait.value] || SQLTraits[trait.value] === 0) && (
                    <SegmentTraitDisplay
                      key={`${trait}:${index}`}
                      traitName={trait.text}
                      traitValue={SQLTraits[trait.value]}
                      fieldName={trait.value}
                    />
                  ),
              )}
            </Grid>
          </TabPanel>
        </TabContext>
      </Paper>
    </>
  );
};

export default SegmentProfile;
