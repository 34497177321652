import React from 'react';
import { makeStyles } from '@mui/styles';

import { Backdrop, CircularProgress } from '@mui/material';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer - 1,
    color: '#fff',
  },
}));

const LoadingBackdrop = ({ children, open, circularProgressProps = {} }) => {
  const { backdrop } = useStyles();

  return (
    <Backdrop className={backdrop} open={open}>
      {children}&nbsp;&nbsp;
      <CircularProgress color="inherit" {...circularProgressProps} />
    </Backdrop>
  );
};

export default LoadingBackdrop;
