import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

import DealerPicker from 'components/MaterialUI/DealerPicker';
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import InventoryTab from './InventoryTab';
import Loading from 'components/MaterialUI/Loading';
import ErrorDisplay from 'components/MaterialUI/ErrorDisplay';

import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';

const PRICE_CHANGES_ORDER = [
  'noPriceChangesAtSevenDays',
  'noPriceChangesAtFourteenDays',
  'noPriceChangesAtTwentyOneDays',
  'noPriceChangesAtTwentyEightDays',
];

const onSpecialFilter = {
  model: 'Vehicle',
  field: 'isOnSpecial',
  value: 'True',
  options: {
    label: 'On Special',
  },
};

const usedFilter = {
  model: 'Vehicle',
  field: 'stockType',
  value: 'USED',
  options: {
    label: 'Used',
  },
};

const newFilter = {
  model: 'Vehicle',
  field: 'stockType',
  value: 'NEW',
  options: {
    label: 'New',
  },
};

const missingPhotosFilter = {
  model: 'Vehicle',
  field: 'missingPhotos',
  value: 'True',
  options: {
    label: 'Missing Photos',
  },
};

const missingDescriptionFilter = {
  model: 'Vehicle',
  field: 'missingDescription',
  value: 'True',
  options: {
    label: 'No Description',
  },
};

const inStockFilter = {
  model: 'Vehicle',
  field: 'stockStatusName',
  value: 'In Stock',
  options: {},
};

const NEW_FACETS = [
  {
    title: 'In Stock',
    model: 'Vehicle',
    field: 'stockStatusName',
    value: 'in stock',
    icon: 'fas fa-bus',
    destination: '/npv-inventory/inventory',
    filters: [newFilter, inStockFilter],
  },
  {
    title: 'On Special',
    model: 'Vehicle',
    field: 'isOnSpecial',
    value: 'True',
    icon: 'fas fa-tag',
    destination: '/npv-inventory/inventory',
    filters: [newFilter, onSpecialFilter],
  },
  {
    title: 'With Photos',
    model: 'Vehicle',
    field: 'missingPhotos',
    value: 'False',
    icon: 'fas fa-camera',
    asPercentage: true,
    destination: '/npv-inventory/inventory',
    filters: [newFilter, missingPhotosFilter],
  },
  {
    title: 'With Description',
    model: 'Vehicle',
    field: 'missingDescription',
    value: 'False',
    icon: 'fas fa-quote-left',
    asPercentage: true,
    destination: '/npv-inventory/inventory',
    filters: [newFilter, missingDescriptionFilter],
  },
];

const USED_FACETS = [
  {
    title: 'In Stock',
    model: 'Vehicle',
    field: 'stockStatusName',
    value: 'in stock',
    icon: 'fas fa-bus',
    destination: '/npv-inventory/inventory',
    filters: [usedFilter, inStockFilter],
  },
  {
    title: 'On Special',
    model: 'Vehicle',
    field: 'isOnSpecial',
    value: 'True',
    icon: 'fas fa-tag',
    destination: '/npv-inventory/inventory',
    filters: [usedFilter, onSpecialFilter],
  },
  {
    title: 'With Photos',
    model: 'Vehicle',
    field: 'missingPhotos',
    value: 'False',
    icon: 'fas fa-camera',
    asPercentage: true,
    destination: '/npv-inventory/inventory',
    filters: [usedFilter, missingPhotosFilter],
  },
  {
    title: 'With Description',
    model: 'Vehicle',
    field: 'missingDescription',
    value: 'False',
    icon: 'fas fa-quote-left',
    asPercentage: true,
    destination: '/npv-inventory/inventory',
    filters: [usedFilter, missingDescriptionFilter],
  },
];

const DASHBOARD_QUERY = gql`
  query DashboardQuery($dealerId: JSON) {
    npv {
      newVehicles: getVehiclesFacets(
        filters: [
          { model: "Vehicle", field: "dealerId", op: "eq", value: $dealerId }
          { model: "Vehicle", field: "stockType", op: "eq", value: "NEW" }
          { model: "StockStatus", field: "name", op: "==", value: "in stock" }
        ]
        facets: [
          { model: "Vehicle", field: "stockStatusName" }
          { model: "Vehicle", field: "missingDescription" }
          { model: "Vehicle", field: "daysInStockCategory" }
          { model: "Vehicle", field: "missingPhotos" }
          { model: "Vehicle", field: "isOnSpecial" }
          { model: "Vehicle", field: "noPriceChangesAtSevenDays" }
          { model: "Vehicle", field: "noPriceChangesAtFourteenDays" }
          { model: "Vehicle", field: "noPriceChangesAtTwentyOneDays" }
          { model: "Vehicle", field: "noPriceChangesAtTwentyEightDays" }
        ]
      ) {
        data {
          value
          count
        }
        field
        model
      }
      usedVehicles: getVehiclesFacets(
        filters: [
          { model: "Vehicle", field: "dealerId", op: "eq", value: $dealerId }
          { model: "Vehicle", field: "stockType", op: "eq", value: "USED" }
          { model: "StockStatus", field: "name", op: "==", value: "in stock" }
        ]
        facets: [
          { model: "Vehicle", field: "stockStatusName" }
          { model: "Vehicle", field: "missingDescription" }
          { model: "Vehicle", field: "daysInStockCategory" }
          { model: "Vehicle", field: "missingPhotos" }
          { model: "Vehicle", field: "isOnSpecial" }
          { model: "Vehicle", field: "noPriceChangesAtSevenDays" }
          { model: "Vehicle", field: "noPriceChangesAtFourteenDays" }
          { model: "Vehicle", field: "noPriceChangesAtTwentyOneDays" }
          { model: "Vehicle", field: "noPriceChangesAtTwentyEightDays" }
        ]
      ) {
        data {
          value
          count
        }
        field
        model
      }
    }
  }
`;

const DAYS_IN_STOCK_ORDER = ['0-30', '31-60', '61-90', '91-120', '>120'];

const Dashboard = () => {
  const [currentTab, setCurrentTab] = useState('NEW');
  const isDesktop = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const handleTabChange = (e, value) => setCurrentTab(value);
  const { dealerId } = useDealerContext();
  const { data, error, loading } = useQuery(DASHBOARD_QUERY, {
    variables: { dealerId },
    fetchPolicy: 'network-only',
  });

  const usedVehicles = data?.npv?.usedVehicles || [];
  const newVehicles = data?.npv?.newVehicles || [];

  const usedInStockData = DAYS_IN_STOCK_ORDER.map(
    value =>
      usedVehicles
        ?.find(x => x.field === 'daysInStockCategory')
        ?.data.find(x => x.value === value) || { value, count: 0 },
  );

  const newInStockData = DAYS_IN_STOCK_ORDER.map(
    value =>
      newVehicles
        ?.find(x => x.field === 'daysInStockCategory')
        ?.data.find(x => x.value === value) || { value, count: 0 },
  );

  const usedPricingChanges = usedVehicles.filter(obj => {
    return (
      obj.field === 'noPriceChangesAtSevenDays' ||
      obj.field === 'noPriceChangesAtFourteenDays' ||
      obj.field === 'noPriceChangesAtTwentyOneDays' ||
      obj.field === 'noPriceChangesAtTwentyEightDays'
    );
  });

  const usedPricingData = PRICE_CHANGES_ORDER.map(
    f => usedVehicles.find(x => x.field === f)?.data[0]?.count,
  );

  const newPricingChanges = newVehicles.filter(obj => {
    return (
      obj.field === 'noPriceChangesAtSevenDays' ||
      obj.field === 'noPriceChangesAtFourteenDays' ||
      obj.field === 'noPriceChangesAtTwentyOneDays' ||
      obj.field === 'noPriceChangesAtTwentyEightDays'
    );
  });

  const newPricingData = PRICE_CHANGES_ORDER.map(
    f => newVehicles.find(x => x.field === f)?.data[0]?.count,
  );

  const mapDataToFacets = (vehicles, facets) => {
    const vehiclesToReturn = facets.map(({ model, field, value, ...rest }) => ({
      number:
        vehicles
          ?.find(x => x.field === field && x.model === model)
          ?.data.find(x => x.value === value)?.count || 0,
      total: vehicles
        ?.find(x => x.field === field && x.model === model)
        ?.data?.map(x => x.count)
        ?.reduce((acc, val) => acc + val, 0),
      ...rest,
    }));
    return vehiclesToReturn;
  };

  return (
    <>
      <Box marginLeft={2} marginTop={1}>
        <DealerPicker />
      </Box>
      <Box marginTop={3}>
        <Grid
          container
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            maxWidth: '1100px',
          }}
        >
          <Grid item style={{ paddingLeft: '1rem', paddingBottom: '2rem' }}>
            <Typography variant="h4">
              <Box style={{ fontWeight: 'bold' }}>Inventory Summary</Box>
            </Typography>
          </Grid>
        </Grid>
      </Box>
      {error && <ErrorDisplay action="fetching dashboard data" error={error} />}
      {loading ? (
        <Loading />
      ) : (
        <Box style={{ paddingLeft: `${isDesktop ? '1rem' : ''}` }}>
          <TabContext value={currentTab}>
            <TabList
              onChange={handleTabChange}
              variant="scrollable"
              indicatorColor="secondary"
            >
              <Tab
                value="NEW"
                label="New Inventory"
                style={{ width: isDesktop ? 500 : 200 }}
              />
              <Tab
                value="USED"
                label="Used Inventory"
                style={{ width: isDesktop ? 500 : 200 }}
              />
            </TabList>
            <TabPanel value="NEW" index={0}>
              <InventoryTab
                vehicles={mapDataToFacets(newVehicles, NEW_FACETS)}
                tabTitle={'New Inventory'}
                inStockData={newInStockData}
                stockTypeFilter={newFilter}
                inStockFilter={inStockFilter}
                pricingData={newPricingData}
              />
            </TabPanel>
            <TabPanel value="USED" index={1}>
              <InventoryTab
                vehicles={mapDataToFacets(usedVehicles, USED_FACETS)}
                tabTitle={'Used Inventory'}
                inStockData={usedInStockData}
                stockTypeFilter={usedFilter}
                inStockFilter={inStockFilter}
                pricingData={usedPricingData}
              />
            </TabPanel>
          </TabContext>
        </Box>
      )}
    </>
  );
};

export default Dashboard;
