import React, { useState } from 'react';

/* Material UI */
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';

const SearchField = ({ search, onChange, ...rest }) => {
  const [value, setValue] = useState(search ?? '');

  const handleKeyPress = e => (e.key === 'Enter' ? onChange(value) : null);

  const handleClear = () => {
    setValue('');
    onChange('');
  };

  return (
    <TextField
      placeholder="Search ..."
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton disabled={!value} onClick={handleClear} size="large">
              <CloseIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      value={value}
      onChange={e => setValue(e.target.value)}
      style={{ paddingRight: '5px', textAlign: 'right' }}
      onKeyPress={handleKeyPress}
      {...rest}
    />
  );
};

export default SearchField;
