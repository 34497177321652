import moment from 'moment';
import jwtDecode from 'jwt-decode';

export function verifyToken(token) {
  let isValid;

  try {
    const decoded = jwtDecode(token);
    const expiration = decoded.exp;
    const twoHours = moment()
      .add(2, 'hours')
      .unix();
    isValid = expiration > twoHours;
  } catch (error) {
    // Invalid or missing token
    isValid = false;
  }
  return isValid;
}

export function decodeToken(token) {
  let user;

  try {
    user = jwtDecode(token);
  } catch (error) {
    // Invalid or missing token
  }
  return user;
}
