import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';

import StepOne from './StepOne/StepOne';
import StepTwo from './StepTwo/StepTwo';
import StepThree from './StepThree';
import MergeSuccessful from './MergeSuccessful';
import { DEFAULTS } from '../constant';

const MERGE_CUSTOMERS = gql`
  mutation mergeCustomersWithData(
    $targetId: ID!
    $sourceId: ID!
    $data: MergeInput!
  ) {
    mergeCustomersWithData(
      targetId: $targetId
      sourceId: $sourceId
      data: $data
    ) {
      _id
    }
  }
`;

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  button: {
    color: 'white',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const steps = [
  'Select Customer Profiles',
  'Customer Information',
  'Review and Confirm',
];

const CustomerMerge = ({ ids }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [activeStep, setActiveStep] = React.useState(0);
  const [selectedCustomers, setSelectedCustomers] = React.useState([]);
  const [selectedFields, setSelectedFields] = useState(DEFAULTS);
  const [mergeCustomers] = useMutation(MERGE_CUSTOMERS, {
    onCompleted: () => handleNext(),
    onError: e =>
      enqueueSnackbar(`Could not merge customers: ${e}`, {
        variant: 'error',
      }),
  });

  const history = useHistory();
  const theme = useTheme();

  const defaultCustomer =
    selectedCustomers.filter(x => x.identities?.length > 0)?.length > 0
      ? selectedCustomers.filter(x => x.identities?.length > 0)[0]
      : JSON.stringify(selectedCustomers[0])?.length >
        JSON.stringify(selectedCustomers[1])?.length
      ? selectedCustomers[0]
      : selectedCustomers[1];

  const multipleIdentities =
    selectedCustomers.filter(x => x.identities?.length > 0)?.length >= 2;

  const getStepContent = step => {
    switch (step) {
      case 0:
        return (
          <StepOne
            ids={ids}
            selectedCustomers={selectedCustomers}
            setSelectedCustomers={setSelectedCustomers}
          />
        );
      case 1:
        return (
          <StepTwo
            selectedCustomers={selectedCustomers}
            selectedFields={selectedFields}
            setSelectedFields={setSelectedFields}
            defaultCustomer={defaultCustomer}
          />
        );
      case 2:
        return (
          <StepThree
            selectedFields={selectedFields}
            defaultCustomer={defaultCustomer}
          />
        );
      default:
        return 'Unknown step';
    }
  };

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const processSalespeople = salespeople => {
    let newSalesPeople = [];
    for (let i = 0; i < salespeople.length; i++) {
      newSalesPeople.push({
        dealer_id: salespeople[i].dealer_id,
        username: salespeople[i].username,
      });
    }
    return newSalesPeople;
  };

  const processData = () => {
    const { assigned_salespeople, vehicles, ...fields } = selectedFields;

    return {
      assigned_salespeople: processSalespeople(assigned_salespeople),
      ...fields,
    };
  };

  const handleMerge = () => {
    const data = processData();
    mergeCustomers({
      variables: {
        targetId: defaultCustomer._id,
        sourceId: selectedCustomers.filter(
          x => x._id !== defaultCustomer._id,
        )[0]._id,
        data,
      },
    });
  };

  const triggerAction = () => {
    activeStep === 2 ? handleMerge() : handleNext();
  };

  return (
    <div className={classes.root}>
      <Box style={{ padding: '1rem' }}>
        <Typography variant="h5" style={{ fontWeight: 'bold' }}>
          Merge Customers
        </Typography>
        <Typography variant="p">
          Use this Merge Wizard to merge two customer profiles into one customer
          profile.
        </Typography>
      </Box>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          return (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <Box
            style={{
              width: '50%',
              display: 'flex',
              flexDirection: 'row',
              marginRight: 'auto',
              marginLeft: 'auto',
              paddingTop: '2rem',
            }}
          >
            <MergeSuccessful
              defaultCustomer={defaultCustomer}
              selectedFields={selectedFields}
            />
          </Box>
        ) : (
          <Box>
            {multipleIdentities && (
              <Box>
                <Alert severity="warning">
                  Two Customers with Idenitities were selected to merge, please
                  remove the identities from one of the customers in order to
                  proceed.
                </Alert>
              </Box>
            )}
            <Typography className={classes.instructions}>
              {getStepContent(activeStep, ids)}
            </Typography>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                textAlign: 'right',
              }}
            >
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginLeft: 'auto',
                  marginRight: 0,
                  padding: '2rem',
                }}
              >
                <Button
                  onClick={() => history.goBack()}
                  className={classes.button}
                  style={{ backgroundColor: '#b90e0a' }}
                >
                  Cancel
                </Button>
                <Box style={{ paddingRight: '1rem', paddingLeft: '1rem' }}>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    variant="outlined"
                  >
                    Back
                  </Button>
                </Box>
                <Button
                  variant="contained"
                  onClick={triggerAction}
                  className={classes.button}
                  style={
                    activeStep === steps.length - 1
                      ? theme.actions.confirm
                      : theme.actions.info
                  }
                  disabled={
                    selectedCustomers.length !== 2 || multipleIdentities
                  }
                >
                  {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                </Button>
              </Box>
            </Box>
          </Box>
        )}
      </div>
    </div>
  );
};

export default CustomerMerge;
