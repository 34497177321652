import React, { useEffect, useState } from 'react';
import gql from 'graphql-tag';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { isEmpty } from 'lodash';

import {
  Badge,
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogTitle,
  FormControl,
  IconButton,
  Menu,
  MenuItem,
  Select,
  TableCell,
  Tooltip,
} from '@mui/material';

import { makeStyles } from '@mui/styles';

import {
  Adjust as AdjustIcon,
  CheckCircle as CheckCircleIcon,
  Computer as ComputerIcon,
  Edit as EditIcon,
  Info as InfoIcon,
  ListAlt as ListAltIcon,
  LocalShipping as LocalShippingIcon,
  MoreVert as MoreVertIcon,
  Publish as PublishIcon,
} from '@mui/icons-material';

import { useUserContext } from 'components/MaterialUI/UserContext';

import { formatPrice } from '../../../utils';
import { NonRetailDeals } from '../constants';

import AccountingChecklistModal from './AccountingChecklist/AccountingChecklistModal';
import PrevAccountingChecklistModal from './AccountingChecklist/PrevAccountingChecklistModal';
import DealLogAttachments from './DealLogAttachmentsModal';
import DealLogDelivery from './DealLogDelivery';
import GrossProfitModal from './DealLogGrossProfit';
import DealLogOpportunityNotes from './DealLogNoteModal';
import DealLogRDRPunch from './DealLogRDRPunch';
import TubeOpportunityModal from './TubeOpportunityModal';

import {
  AppointmentTypes,
  CONFIRMED_STATUSES,
  Role,
  Status,
  StatusDisplay,
  StatusSortingOrder,
} from 'constants.js';

import ReconCompleteIndicator from './ReconCompleteIndicator';

const useStyles = makeStyles({
  div: {
    textAlign: 'center',
  },
  divSpace: {
    textAlign: 'center',
    whiteSpace: 'pre-line',
    marginRight: '50px',
    width: '15px',
  },
  chip: {
    marginBottom: '2px',
  },
  menu: {
    '& ul': {
      display: 'flex',
    },
  },
});

const UPDATE_OPP_STATUSES = gql`
  mutation updateOppStatuses(
    $id: ID!
    $statuses: UpdateOpportunityStatusInput!
  ) {
    updateOpportunityStatus(_id: $id, input: $statuses) {
      _id
      status
      sub_status
    }
  }
`;

const DealLogTableRow = ({
  opportunity,
  subStatuses,
  userData,
  className,
  notASalesRole,
  notAServiceRole,
  onUpdateStatus,
}) => {
  const styles = useStyles();
  const history = useHistory();
  const [opportunityDelivery, setOpportunityDelivery] = useState(
    opportunity?.appointments.length > 0
      ? opportunity.appointments.find(
          app => app.type_id === AppointmentTypes.DELIVERY,
        )
      : null,
  );

  const { currentUser } = useUserContext();
  const [user, setUser] = React.useState(null);
  const [status, setStatus] = useState(opportunity.status);
  const [subStatus, setSubStatus] = useState(opportunity.sub_status);
  const [showNotes, setShowNotes] = useState(false);
  const [showAttachments, setShowAttachments] = useState(false);
  const [showTubedModal, setShowTubedModal] = useState(false);
  const [showProfit, setShowProfit] = useState(false);
  const [showDelivery, setShowDelivery] = useState(false);
  const [showRDR, setShowRDR] = useState(false);
  const [showChecklist, setShowChecklist] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [updateStatus] = useMutation(UPDATE_OPP_STATUSES, {
    onCompleted: r => {
      setStatus(r.updateOpportunityStatus.status);
      enqueueSnackbar('Updated Status!');
      onUpdateStatus();
    },
    onError: error => {
      enqueueSnackbar(`Error updating status: ${error}`, {
        variant: 'error',
      });
    },
  });
  const [updateSubStatus] = useMutation(UPDATE_OPP_STATUSES, {
    onCompleted: r => {
      setSubStatus(r.updateOpportunityStatus.sub_status);
      enqueueSnackbar('Updated Sub Status!');
      onUpdateStatus();
    },
    onError: error => {
      enqueueSnackbar(`Error updating Sub status: ${error}`, {
        variant: 'error',
      });
    },
  });
  const accountingData = opportunity.accounting_deal;
  const dmsData = opportunity.dms_deal;
  const isPostedAndNotOrgAdmin =
    opportunity.status === Status.POSTED &&
    currentUser.role !== Role.ORGANIZATION_ADMIN;
  const subStatusDropDown = [...subStatuses];
  const storeTotalGross = dmsData?.total_gross || 0;
  const frontendGross = accountingData?.frontend_gross?.value || 0;
  const backendGross = accountingData?.backend_gross?.value || 0;
  const postedTotalGross = frontendGross + backendGross;
  const totalGross = postedTotalGross - storeTotalGross;
  const isSalesRole = !notASalesRole;
  const isServiceRole = !notAServiceRole;
  const {
    customer: { app_account: goAutoAppAcct },
  } = opportunity;

  const dealLogStatusDisplay = StatusSortingOrder.map(x => [
    x,
    StatusDisplay[x],
  ]);
  const customerHasConfirmedAppAcct =
    CONFIRMED_STATUSES.includes(goAutoAppAcct?.status) &&
    goAutoAppAcct?.isEnabled;
  const customerHasPendingAppAcct =
    goAutoAppAcct?.status === 'UNCONFIRMED' && goAutoAppAcct?.isEnabled;
  const customerHasGoAutoAppAcct =
    customerHasConfirmedAppAcct || customerHasPendingAppAcct;
  const handleStatusChange = event => {
    // Update status regularly unless its TUBED
    if (Number(event.target.value) !== 9) {
      updateStatus({
        variables: {
          id: opportunity._id,
          statuses: {
            status: Number(event.target.value),
          },
        },
      });
    } else {
      setShowTubedModal(true);
    }
  };

  const handleSubStatusChange = event => {
    updateSubStatus({
      variables: {
        id: opportunity._id,
        statuses: {
          sub_status: event.target.value,
        },
      },
    });
  };

  let dates = Object.values(opportunity.last_status_change).map(d => moment(d));
  const lastUpdated = moment.max(dates);

  const numOfReps = opportunity.sales_reps.length;

  const salesManagers =
    opportunity.sales_managers.length > 0
      ? opportunity.sales_managers[0].display_name
      : null;

  const financeManagers =
    opportunity.finance_managers.length > 0
      ? opportunity.finance_managers[0].display_name
      : null;

  const sales_deal = dmsData?.sales_deal_type
    ? NonRetailDeals.includes(dmsData.sales_deal_type)
      ? dmsData.sales_deal_type
      : 'Retail'
    : 'Retail';

  const vehicleNameGlued = [dmsData.year, dmsData.make_name, dmsData.model_name]
    .filter(x => x)
    .join(' ');

  /* Factory orders: use the vehicleName string, glued string (year/make/model) as a fallback
   * Other orders: just use the glued string
   */
  const vehicleName =
    dmsData.stock_number === 'FACTORY'
      ? opportunity?.primary_pitch_deallog?.vehicles[0]?.vehicleName ??
        vehicleNameGlued
      : vehicleNameGlued;

  const tradeName = dmsData.trades
    ? [
        dmsData.trades[0]?.year,
        dmsData.trades[0]?.make_name,
        dmsData.trades[0]?.model_name,
      ]
        .filter(x => x)
        .join(' ')
    : '';

  const trades = dmsData.trades;

  const navigatePage = opp =>
    history.push(`/opportunities/${opp._id}/${opp.customer_id}/details`);

  useEffect(() => {
    if (opportunityDelivery) {
      setUser(
        userData.users.find(
          user => user.username === opportunityDelivery.attendee_usernames[0],
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opportunityDelivery]);

  const hasDeallogAttachments = ({ attachments }) =>
    attachments.some(a => a.deleted === false);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const displayAction = notASalesRole;

  const hasPrevAccountingChecklist =
    !isEmpty(opportunity.accounting_checklist) ||
    !isEmpty(opportunity.finance_checklist) ||
    !isEmpty(opportunity.extra_checklist);

  const goToCustomerPage = customer_id => {
    history.push(`/customers/${customer_id}/details`);
  };

  return (
    <>
      <Dialog
        maxWidth="md"
        open={showAttachments}
        onClose={() => {
          setShowAttachments(false);
        }}
      >
        <DealLogAttachments
          handleClose={() => {
            setShowAttachments(false);
          }}
          opportunityID={opportunity._id}
          dealNumber={opportunity.dms_deal?.deal_number || 'No deal found'}
          isServiceRole={isServiceRole}
        />
      </Dialog>
      <Dialog
        maxWidth="md"
        fullWidth={true}
        scroll="paper"
        open={showNotes}
        onClose={() => {
          setShowNotes(false);
        }}
      >
        <DealLogOpportunityNotes
          handleClose={() => {
            setShowNotes(false);
          }}
          opportunity={opportunity}
        />
      </Dialog>
      <Dialog
        maxWidth="md"
        fullWidth={true}
        scroll="paper"
        open={showProfit}
        onClose={() => {
          setShowProfit(false);
        }}
      >
        <DialogTitle id="GrossProfitScreen">
          <Box>
            Gross Profit on Deal #{opportunity.dms_deal.deal_number} -{' '}
            {opportunity.customer?.company_name || opportunity.customer_name}
          </Box>
        </DialogTitle>
        <GrossProfitModal
          opportunity={opportunity}
          includeGross={notASalesRole && notAServiceRole}
          handleClose={() => {
            setShowProfit(false);
          }}
        />
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth="xs"
        scroll="paper"
        open={showDelivery}
        onClose={() => {
          setShowDelivery(false);
        }}
      >
        <DealLogDelivery
          opportunity={opportunity}
          delivery={opportunityDelivery}
          setDelivery={setOpportunityDelivery}
          userData={userData}
          user={user}
          setUser={setUser}
          handleClose={() => {
            setShowDelivery(false);
          }}
        />
      </Dialog>
      <DealLogRDRPunch
        opportunity={opportunity}
        handleClose={() => {
          setShowRDR(false);
        }}
        setShowRDR={setShowRDR}
        showRDR={showRDR}
      />
      {!hasPrevAccountingChecklist && (
        <AccountingChecklistModal
          opportunity={opportunity}
          onClose={() => setShowChecklist(false)}
          isOpen={showChecklist}
          trades={trades}
        />
      )}
      {hasPrevAccountingChecklist && (
        <PrevAccountingChecklistModal
          opportunity={opportunity}
          onClose={() => setShowChecklist(false)}
          isOpen={showChecklist}
          tradeName={tradeName}
        />
      )}
      <TubeOpportunityModal
        opportunityId={opportunity._id}
        onClose={() => setShowTubedModal(false)}
        isOpen={showTubedModal}
        updateStatus={() => setStatus(Status.TUBED)}
      />
      <TableCell className={className}>
        <IconButton color="primary" onClick={handleClick} size="large">
          {hasDeallogAttachments(opportunity) ? (
            <Badge color="secondary" variant="dot" overlap="rectangular">
              <MoreVertIcon />
            </Badge>
          ) : (
            <MoreVertIcon />
          )}
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          className={styles.menu}
        >
          {displayAction && (
            <Tooltip title="G-Screen">
              <IconButton
                size="small"
                onClick={() => {
                  setShowProfit(true);
                  handleClose();
                }}
              >
                <ComputerIcon variant="outlined" />
              </IconButton>
            </Tooltip>
          )}
          {displayAction && (
            <div style={{ whiteSpace: 'pre-line' }}>
              <Tooltip title="Notes">
                <IconButton
                  size="small"
                  onClick={() => {
                    setShowNotes(true);
                    handleClose();
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </div>
          )}
          <div style={{ whiteSpace: 'pre-line' }}>
            <Tooltip title="Attachments">
              <IconButton
                disabled={isSalesRole}
                size="small"
                onClick={() => {
                  setShowAttachments(true);
                  handleClose();
                }}
              >
                {hasDeallogAttachments(opportunity) ? (
                  <Badge color="secondary" variant="dot" overlap="rectangular">
                    <PublishIcon />
                  </Badge>
                ) : (
                  <PublishIcon />
                )}
              </IconButton>
            </Tooltip>
          </div>
          {displayAction && (
            <div style={{ whiteSpace: 'pre-line' }}>
              <Tooltip title="Delivery">
                <IconButton
                  size="small"
                  onClick={() => {
                    setShowDelivery(true);
                    handleClose();
                  }}
                >
                  <LocalShippingIcon />
                </IconButton>
              </Tooltip>
            </div>
          )}
          <div style={{ whiteSpace: 'pre-line' }}>
            <Tooltip title="Open In New Tab">
              <IconButton
                size="small"
                onClick={e => {
                  window.open(
                    `/opportunities/${opportunity._id}/${opportunity.customer_id}/details`,
                    '_blank',
                  );
                  e.stopPropagation();
                }}
              >
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </div>
          {opportunity.deal_type === 'New' &&
            !opportunity?.rdr_punch?.punch_date &&
            !Role.ALL_SALES_REPS.includes(currentUser.role) && (
              <div style={{ whiteSpace: 'pre-line' }}>
                <Tooltip title="RDR Punch">
                  <IconButton
                    size="small"
                    onClick={() => {
                      setShowRDR(true);
                      handleClose();
                    }}
                  >
                    <AdjustIcon />
                  </IconButton>
                </Tooltip>
              </div>
            )}
          <div style={{ whiteSpace: 'pre-line' }}>
            <Tooltip title="Checklist">
              <IconButton
                size="small"
                onClick={() => {
                  setShowChecklist(true);
                  handleClose();
                }}
              >
                <ListAltIcon />
              </IconButton>
            </Tooltip>
          </div>
        </Menu>
      </TableCell>
      <TableCell
        className={className}
        onClick={() => navigatePage(opportunity)}
      >
        {opportunity.sent_to_fi_date !== null
          ? moment(opportunity.sent_to_fi_date).format('ll')
          : moment().format('ll')}
      </TableCell>
      <TableCell align={'left'} className={className}>
        <Button
          onClick={() => goToCustomerPage(opportunity.customer_id)}
          style={{ textAlign: 'left' }}
        >
          {customerHasGoAutoAppAcct ? (
            <>
              <div>
                {opportunity.customer?.company_name ||
                  opportunity.customer_name}
              </div>
              <Tooltip
                title={
                  customerHasPendingAppAcct
                    ? 'Go Auto App Account Not Verified'
                    : 'Go Auto App Account Enabled'
                }
              >
                <CheckCircleIcon
                  fontSize="inherit"
                  style={{
                    color: customerHasPendingAppAcct ? 'yellow' : 'green',
                  }}
                />
              </Tooltip>
            </>
          ) : (
            opportunity.customer?.company_name || opportunity.customer_name
          )}
        </Button>
      </TableCell>
      <TableCell
        className={className}
        onClick={() => navigatePage(opportunity)}
      >
        {dmsData.deal_number} {dmsData.payment_type}
        <div style={{ whiteSpace: 'pre-line' }}>
          {`${sales_deal} (${opportunity.deal_type || ''}) `}
        </div>
        <div style={{ whiteSpace: 'pre-line' }}>
          <Checkbox
            disabled
            style={{ color: 'black' }}
            checked={opportunity?.rdr_punch?.punch_date}
          />{' '}
          RDR
        </div>
      </TableCell>
      <TableCell
        className={opportunity.status === Status.SIGNED ? className : null}
        onClick={() => navigatePage(opportunity)}
      >
        {vehicleName}
        <Box>({dmsData.stock_number})</Box>
        {opportunity?.vehicle_recon_complete && <ReconCompleteIndicator />}
      </TableCell>
      <TableCell
        className={opportunity.status === Status.SIGNED ? className : null}
        onClick={() => navigatePage(opportunity)}
      >
        {tradeName}
      </TableCell>
      <TableCell
        className={opportunity.status === Status.SIGNED ? className : null}
        onClick={() => navigatePage(opportunity)}
      >
        {numOfReps > 0 &&
          opportunity.sales_reps.map(salesRep => (
            <Box key={salesRep.display_name}>
              <Chip
                size="small"
                label={`SP: ${salesRep.display_name}`}
                className={styles.chip}
              />
            </Box>
          ))}
        {salesManagers !== null && (
          <Box>
            <Chip
              size="small"
              label={`SM: ${salesManagers}`}
              className={styles.chip}
            />
          </Box>
        )}
        {financeManagers !== null && (
          <Box>
            <Chip
              size="small"
              label={`FM: ${financeManagers}`}
              className={styles.chip}
            />
          </Box>
        )}
      </TableCell>
      <TableCell>
        <FormControl
          sx={{ width: '100%' }}
          disabled={isPostedAndNotOrgAdmin || isSalesRole || isServiceRole}
        >
          <Select value={status} onChange={handleStatusChange}>
            {dealLogStatusDisplay.map(([key, value]) => (
              <MenuItem
                key={key}
                value={key}
                disabled={
                  (key === String(Status.POSTED) &&
                    currentUser.role !== Role.ORGANIZATION_ADMIN) ||
                  key === String(Status.FRESH)
                }
              >
                {value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </TableCell>
      <TableCell>
        <FormControl
          style={{ width: '150px' }}
          disabled={isSalesRole || isServiceRole}
        >
          <Select value={subStatus} onChange={handleSubStatusChange}>
            {subStatusDropDown.map(value => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </TableCell>
      <TableCell
        style={{ width: '200px', fontSize: '11pt', fontWeight: 'bold' }}
        onClick={() => navigatePage(opportunity)}
      >
        {notASalesRole && notAServiceRole && (
          <>
            {totalGross >= 0 ? (
              <div style={{ textAlign: 'center' }}>
                {formatPrice(totalGross)}
              </div>
            ) : (
              <div style={{ color: 'red', textAlign: 'center' }}>
                {formatPrice(totalGross)}
              </div>
            )}
            <br />
            <div style={{ whiteSpace: 'pre-line' }}>
              <table
                style={{ width: '100%', fontSize: '11pt', fontWeight: 'bold' }}
              >
                <tbody>
                  <tr>
                    <td style={{ textAlign: 'left' }}>Store:</td>
                    <td style={{ textAlign: 'right' }}>
                      {formatPrice(dmsData?.total_gross)}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'left' }}>Posted:</td>
                    <td style={{ textAlign: 'right' }}>
                      {formatPrice(postedTotalGross)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        )}
        {(isSalesRole || isServiceRole) && (
          <div style={{ textAlign: 'center' }}>N/A</div>
        )}
      </TableCell>
      <TableCell onClick={() => navigatePage(opportunity)}>
        {lastUpdated?.format('YYYY-MM-DD') ?? ''}
      </TableCell>
      <TableCell onClick={() => navigatePage(opportunity)}>
        {opportunityDelivery?.start_date !== undefined
          ? moment(opportunityDelivery.start_date).format('YYYY-MM-DD')
          : ''}
      </TableCell>
    </>
  );
};

export default DealLogTableRow;
