import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { ReactSortable } from 'react-sortablejs';

import {
  Badge,
  Box,
  Divider,
  Dialog,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Cancel as CancelIcon } from '@mui/icons-material';

import { cloudinaryConfig } from 'constants.js';
import { cloudinaryCore } from 'utils';
import { LoadingBackdrop } from 'components/MaterialUI';

import { AddButton, DeleteButton, ReplaceButton } from './ButtonWrappers';

const GET_PHOTO_LABELS = gql`
  query getPhotoLabels {
    npv {
      getLabels {
        id
        name
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  photoGrid: {
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: 'repeat(4, 1fr)',
    },
    maxWidth: '1000px',
    display: 'grid',
    columnGap: '10px',
    rowGap: '10px',
  },
}));

const formatPhoto = (photo, options = {}) =>
  photo
    ? cloudinaryCore.url(photo.cloudinaryPublicId, {
        width: options.width || 100,
        height: options.height || 100,
        crop: 'fit',
      })
    : null;

export default function PhotoBox({
  vehicle,
  photoList,
  setPhotoList,
  labelId,
  setLabelId,
}) {
  // Queries
  const { data: labelsData, loading: labelLoading } =
    useQuery(GET_PHOTO_LABELS);
  const labels = labelsData?.npv?.getLabels || [];
  const [floorPlan, setFloorPlan] = useState(
    photoList.find(x => x.isFloorPlan),
  );
  const [floorPlanIndex, setFloorPlanIndex] = useState(0);
  const [photoModalOpen, setPhotoModalOpen] = useState(false);
  const [photoModal, setPhotoModal] = useState(null);

  const vehicleId = vehicle?.id;

  const addPhoto = photo => {
    // Make sure floor plan is in correct position
    if (floorPlan) {
      setPhotoList(prevState => {
        const photos = prevState.filter(x => !x.isFloorPlan);
        return floorPlanIndex === 0
          ? [floorPlan, ...photos, photo]
          : [...photos, photo, floorPlan];
      });
    } else {
      setPhotoList(prevState => [...prevState, photo]);
    }
  };

  const deletePhoto = photo => {
    setPhotoList(prev => prev.filter(x => x.md5 !== photo.md5));
  };

  const rotateFloorPlanPosition = index => {
    setPhotoList(prevState => {
      const photos = prevState.filter(x => !x.isFloorPlan);
      return index === 0 ? [floorPlan, ...photos] : [...photos, floorPlan];
    });
  };

  // Cloudinary Widgets
  const uploadWidget = ({
    apiKey,
    folder,
    signature,
    timestamp,
    uploadPreset,
    multiple = true,
  }) => {
    const widget = window.cloudinary.createUploadWidget(
      {
        cloudName: cloudinaryConfig.cloud_name,
        apiKey,
        uploadSignature: signature,
        uploadSignatureTimestamp: timestamp,
        folder,
        uploadPreset,
        maxFileSize: 5e6, // 5MB
        clientAllowedFormats: ['png', 'jpg', 'jpeg', 'jfif'],
        maxImageWidth: 1600,
        maxImageHeight: 1200,
        multiple,
      },
      (error, result) => {
        if (!error && result && result.event === 'success') {
          const data = {
            cloudinaryPublicId: result.info.public_id,
            md5: result.info.etag,
            isFloorPlan: false,
            vehicleId,
          };
          if (multiple) {
            addPhoto(data);
          } else {
            const floorPlanData = { ...data, isFloorPlan: true };
            setFloorPlan(floorPlanData);
            setPhotoList(prevState =>
              floorPlanIndex === 0
                ? [floorPlanData, ...prevState]
                : [...prevState, floorPlanData],
            );
          }
        }
      },
    );
    widget.open();
  };

  const classes = useStyles();
  return (
    <Box>
      <LoadingBackdrop open={labelLoading} />
      <Typography variant="h6">Floor Plan</Typography>
      <Divider />
      {!floorPlan ? (
        <AddButton
          onClick={() =>
            uploadWidget({ ...vehicle.photoUploadParams, multiple: false })
          }
        >
          Add Floor Plan
        </AddButton>
      ) : (
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={3}>
            <img
              src={formatPhoto(floorPlan, { width: 300, height: 300 })}
              style={{ maxWidth: '100%', maxHeight: '100%' }}
              alt="Floor Plan"
            />
          </Grid>
          <Grid item xs={3}>
            <Grid
              container
              direction="column"
              justifyContent="space-between"
              spacing={3}
            >
              <Grid item>
                <ReplaceButton
                  onClick={() => {
                    setFloorPlan(null);
                    setPhotoList(prevState =>
                      prevState.filter(x => x.isFloorPlan !== true),
                    );
                    uploadWidget({
                      ...vehicle.photoUploadParams,
                      multiple: false,
                    });
                  }}
                />
                <DeleteButton
                  onClick={() => {
                    deletePhoto(floorPlan);
                    setFloorPlan(null);
                  }}
                />
              </Grid>
              <Grid item>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Display</InputLabel>
                  <Select
                    label="Display"
                    value={floorPlanIndex}
                    onChange={e => {
                      rotateFloorPlanPosition(e.target.value);
                      setFloorPlanIndex(e.target.value);
                    }}
                  >
                    <MenuItem value={0}>First</MenuItem>
                    <MenuItem value={1}>Last</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Label</InputLabel>
                  <Select
                    label="Label"
                    value={labelId}
                    onChange={e => {
                      setLabelId(e.target.value);
                    }}
                  >
                    {labels.map(label => (
                      <MenuItem key={label.id} value={label.id}>
                        {label.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3} />
        </Grid>
      )}
      <Typography variant="h6" style={{ marginTop: '20px' }}>
        Uploaded Photos
      </Typography>
      <Divider />
      <Typography style={{ marginTop: '15px', marginBottom: '15px' }}>
        <span style={{ fontSize: '18px', fontWeight: '700' }}>
          {photoList.length} Photos
        </span>{' '}
        (Drag to reorder, click to enlarge)
      </Typography>
      <AddButton onClick={() => uploadWidget(vehicle.photoUploadParams)}>
        Add Photos
      </AddButton>
      <Dialog
        open={photoModalOpen}
        onClose={() => setPhotoModalOpen(false)}
        aria-labelledby="enlarge-photo"
      >
        <img
          src={formatPhoto(photoModal, { width: 800, height: 800 })}
          style={{ maxWidth: '100%', maxHeight: '100%' }}
          alt="photo"
        />
      </Dialog>
      {photoList.length !== 0 && (
        <DeleteButton
          onClick={() => {
            setPhotoList(prevState => prevState.filter(x => x.isFloorPlan));
            setFloorPlan(null);
          }}
        >
          Delete All
        </DeleteButton>
      )}
      <Grid>
        <Box style={{ margin: '10px' }}>
          <ReactSortable
            list={photoList}
            setList={setPhotoList}
            className={classes.photoGrid}
          >
            {photoList.map(photo => (
              <Box
                style={{
                  marginRight: '10px',
                }}
                key={JSON.stringify(photo.id + photo.md5)}
              >
                <Badge
                  variant="div"
                  overlap="rectangular"
                  badgeContent={
                    <IconButton onClick={() => deletePhoto(photo)} size="large">
                      <CancelIcon style={{ fontSize: '32px' }} />
                    </IconButton>
                  }
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  style={{ height: '100%', width: '100%' }}
                >
                  <img
                    alt="Vehicle"
                    style={{
                      objectFit: 'cover',
                      height: '100%',
                      width: '100%',
                      marginBottom: '15px',
                      cursor: 'pointer',
                    }}
                    src={formatPhoto(photo)}
                    onClick={() => {
                      setPhotoModal(photo);
                      setPhotoModalOpen(true);
                    }}
                  />
                </Badge>
              </Box>
            ))}
          </ReactSortable>
        </Box>
      </Grid>
    </Box>
  );
}

PhotoBox.fragments = {
  vehicle: gql`
    fragment NpvVehiclePhotoBox on NpvVehicle {
      id
      photos {
        cloudinaryPublicId
        id
        labelId
        md5
        isFloorPlan
        vehicleId
      }
      photoUploadParams {
        apiKey
        folder
        signature
        timestamp
        uploadPreset
        url
      }
    }
  `,
};
