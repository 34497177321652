import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import React, { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { ReactSortable } from 'react-sortablejs';
import { isEmpty } from 'underscore';

import {
  Badge,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import PublishIcon from '@mui/icons-material/Publish';

import ConfirmDialog from 'components/MaterialUI/ConfirmDialog';

import { cloudinaryCore } from 'utils';

import { cloudinaryConfig } from '../../../../../constants';
import noIMG from '../../../static/img/no-image.jpg';

const PHOTO_PARAMS_QUERY = gql`
  query reconPhotoParams($dealer_id: Int!, $vin: String!) {
    inventory {
      getReconPhotoParams(dealer_id: $dealer_id, vin: $vin) {
        api_key
        folder
        signature
        timestamp
        upload_preset
        url
      }
    }
  }
`;

const ReconPhotosModal = ({
  recon,
  modalOpen,
  handleModalClose,
  vin,
  dealerId,
}) => {
  const mobile = useMediaQuery('(max-width:575px)');
  const theme = useTheme();
  const { control, register, setValue } = useFormContext();
  register('recon.photos');
  const photosData = useWatch({
    control,
    name: 'recon.photos',
    defaultValue: recon?.photos ?? [],
  });

  const [photoList, setPhotoList] = useState(photosData);
  const [largePhoto, setLargePhoto] = useState(recon?.displayPhoto ?? null);

  const { data, loading } = useQuery(PHOTO_PARAMS_QUERY, {
    variables: {
      vin,
      dealer_id: dealerId,
    },
  });

  const uploadParams = data?.inventory?.getReconPhotoParams ?? {};
  const photos = recon?.photos ?? [];
  useEffect(() => {
    const _photoList = photoList.map(
      ({ chosen, selected, __typename, ...rest }) => rest,
    );
    const _photosData = photosData.map(({ __typename, ...rest }) => rest);
    if (JSON.stringify(_photosData) !== JSON.stringify(_photoList)) {
      setValue('recon.photos', _photoList, { shouldDirty: true });
    }
    if (!largePhoto && photoList.length > 0) setLargePhoto(photoList[0]);
  }, [largePhoto, photoList, photosData, setValue]);

  useEffect(() => {
    if (photos.length > 0) {
      const _photos = photos.map(({ __typename, ...rest }) => rest);
      setValue('recon.photos', _photos, { shouldDirty: false });
      setPhotoList(_photos);
    }
  }, [photos, setValue]);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmDeleteAll = () => {
    setPhotoList([]);
    setLargePhoto(null);
    setOpen(false);
  };
  const formatPhoto = (photo, options = {}) =>
    photo
      ? cloudinaryCore.url(photo.cloudinary_public_id, {
          width: options.width || 100,
          crop: 'fit',
        })
      : noIMG;

  const uploadWidget = ({
    api_key,
    folder,
    signature,
    timestamp,
    upload_preset,
  }) => {
    const widget = window.cloudinary.createUploadWidget(
      {
        cloudName: cloudinaryConfig.cloud_name,
        apiKey: api_key,
        uploadSignature: signature,
        uploadSignatureTimestamp: timestamp,
        folder,
        uploadPreset: upload_preset,
        maxFileSize: 5 * 1000 * 1000, // 5MB
        clientAllowedFormats: ['png', 'jpg', 'jpeg', 'jfif'],
        maxImageWidth: 1600,
        maxImageHeight: 1200,
      },
      (error, result) => {
        if (!error && result && result.event === 'success') {
          setPhotoList(prev => [
            ...prev,
            {
              cloudinary_public_id: result.info.public_id,
              md5: result.info.etag,
            },
          ]);
        }
      },
    );
    widget.open();
  };

  const displayLargePhoto = photo => {
    setLargePhoto(photo);
  };

  const deletePhoto = photo =>
    setPhotoList(prev => prev.filter(x => x.md5 !== photo.md5));
  return (
    <Dialog open={modalOpen} onClose={handleModalClose}>
      <DialogTitle>Recon Photos</DialogTitle>
      <DialogContent>
        <Grid container direction="row" xs={12}>
          <Grid item xs={12} md={6}>
            <Box component="div" style={{ marginRight: '20px' }}>
              <img
                alt="Vehicle"
                style={{
                  width: '100%',
                  objectFit: 'cover',
                }}
                src={formatPhoto(largePhoto, { width: 500 })}
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={mobile ? { order: 4 } : { order: 2 }}
          >
            <Box component="div" style={{ paddingTop: '5px' }}>
              <ReactSortable list={photoList} setList={setPhotoList}>
                {photoList.map(photo => (
                  <Box
                    component="span"
                    style={{
                      marginRight: '10px',
                    }}
                    key={JSON.stringify(photo.id + photo.md5)}
                  >
                    <Badge
                      overlap="rectangular"
                      badgeContent={
                        <IconButton
                          onClick={() => deletePhoto(photo)}
                          size="large"
                        >
                          <CancelIcon style={{ fontSize: '32px' }} />
                        </IconButton>
                      }
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                    >
                      <img
                        alt="Vehicle"
                        style={{
                          objectFit: 'cover',
                          height: '75px',
                          width: '93.75px',
                          marginBottom: '15px',
                        }}
                        onClick={() => displayLargePhoto(photo)}
                        src={formatPhoto(photo)}
                      />
                    </Badge>
                  </Box>
                ))}
              </ReactSortable>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={mobile ? { order: 3 } : { order: 4 }}
          >
            <Box component="div">
              <Button
                disabled={loading && !isEmpty(uploadParams)}
                variant="contained"
                color="green"
                style={{
                  height: `${mobile ? '50px' : '35px'}`,
                  fontSize: `${mobile ? '14px' : '12px'}`,
                  maxWidth: '150px',
                  minWidth: '25%',
                  backgroundColor: '#26abff',
                  color: 'white',
                  marginRight: '5px',
                }}
                onClick={() => uploadWidget(uploadParams)}
              >
                <PublishIcon /> UPLOAD
              </Button>
              <Button
                variant="contained"
                color="red"
                style={{
                  height: `${mobile ? '50px' : '35px'}`,
                  fontSize: `${mobile ? '14px' : '12px'}`,
                  maxWidth: '150px',
                  minWidth: '25%',
                  backgroundColor: '#d0312d',
                  color: 'white',
                }}
                onClick={handleClickOpen}
              >
                <DeleteIcon /> DELETE ALL
              </Button>
              <ConfirmDialog
                abortText="Cancel"
                confirmText="Delete"
                isOpen={open}
                onConfirm={handleConfirmDeleteAll}
                onClose={handleClose}
                text="Pressing confirm will delete all of the photos for this
                vehicle. Are you sure you want to delete all of the photos
                for this vehicle?"
                titleText="WAIT! Are you sure?"
              />
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleModalClose()}
          style={theme.actions.close}
          variant="text"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReconPhotosModal;
