import { useQuery } from '@apollo/react-hooks';
import { NetworkStatus } from 'apollo-boost';
import gql from 'graphql-tag';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { Waypoint } from 'react-waypoint';

/* Material UI */
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Close as CloseIcon, Search as SearchIcon } from '@mui/icons-material';

/* internal */
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import ErrorDisplay from 'components/MaterialUI/ErrorDisplay';
import Loading from 'components/MaterialUI/Loading';
import { useUserContext } from 'components/MaterialUI/UserContext';

import SortPopover from 'modules/auctions/components/items/common/SortPopover';
import noIMG from 'modules/inventory/static/img/no-image.jpg';

import { cloudinaryCore } from 'utils';
import { formatOdometer, formatPrice } from 'utils';

import {
  GOAUTO_GROUP_ID,
  GOAUTO_ORG_ID,
  TEST_DEALERS_GROUP_ID,
  TEST_ORG_ID,
} from 'constants.js';

const useStyles = makeStyles(theme => ({
  boldHead: {
    '& th': {
      fontWeight: 'bold',
    },
  },
}));

const SORT_BY_ID = {
  model: 'Vehicle',
  field: 'id',
  direction: 'desc',
};

const DAYS_SORT = { model: 'Vehicle', field: 'days_in_stock' };
const DEALER_SORT = { model: 'Dealer', field: 'name' };
const MAKE_SORT = { model: 'Make', field: 'name' };
const MODEL_SORT = { model: 'Model', field: 'name' };
const ODOMETER_SORT = { model: 'Vehicle', field: 'odometer' };
const PRICE_SORT = { model: 'Vehicle', field: 'regular_price' };
const STOCK_NUMBER_SORT = { model: 'Vehicle', field: 'stock_number' };
const YEAR_SORT = { model: 'Vehicle', field: 'year' };

const VEHICLE_SORT_OPTIONS = [
  { ...YEAR_SORT, direction: 'asc' },
  { ...YEAR_SORT, direction: 'desc' },
  { ...MAKE_SORT, direction: 'asc' },
  { ...MAKE_SORT, direction: 'desc' },
  { ...MODEL_SORT, direction: 'asc' },
  { ...MODEL_SORT, direction: 'desc' },
];

const PAGE_SIZE = 20;

const INVENTORY_QUERY = gql`
    query inventoryQuery($filters: [QueryFilter], $page: Int!, $sort: [QuerySortElement]){
        inventory {
            getGroupVehicles(filters:$filters, page: $page, page_size: ${PAGE_SIZE}, sort: $sort){
                pagination {
                    total
                    next_page
                }
                results {
                    id
                    days_in_stock
                    dealer_id
                    dealer_name
                    displayPhoto {
                        id
                        cloudinary_public_id
                    }
                    exterior_colour_name
                    interior_colour_name
                    is_demo_unit
                    is_loaner
                    list_price
                    make_id
                    model_id
                    make_name
                    model_name
                    msrp
                    odometer
                    regular_price
                    stock_number
                    stock_type
                    trim
                    trim_variation
                    vin
                    year
                }
            }
        }
    }
`;

const formatPhoto = photo =>
  photo
    ? cloudinaryCore.url(photo.cloudinary_public_id, {
        width: 100,
        crop: 'fit',
      })
    : noIMG;

const VehicleSearchDialog = ({ onConfirm, onClose, ...rest }) => {
  const theme = useTheme();
  const { boldHead } = useStyles(theme);
  const {
    currentUser: { organization_id: orgId },
  } = useUserContext();

  const { dealerId } = useDealerContext();

  const [searchKeywords, setSearchKeywords] = useState('');
  const [searchFilters, setSearchFilters] = useState([]);
  const [sortSelection, setSortSelection] = useState();
  const [popoverAnchor, setPopoverAnchor] = useState();
  const [selectedVehicleId, setSelectedVehicleId] = useState();
  const [groupSearch, setGroupSearch] = useState(false);

  /*
    year: exact match,
    make: starts with keyword,
    model: starts with keyword,
    trim: starts with keyword,
    stock_number: contains keyword
  */
  const setSearchFiltersFromKeywords = () =>
    setSearchFilters(
      searchKeywords.split(' ').map(keyword => ({
        or: [
          {
            model: 'Make',
            field: 'name',
            op: 'ilike',
            value: `${keyword}%`,
          },
          {
            model: 'Model',
            field: 'name',
            op: 'ilike',
            value: `%${keyword}%`,
          },
          {
            model: 'Vehicle',
            field: 'stock_number',
            op: 'ilike',
            value: `%${keyword}%`,
          },
          {
            model: 'Vehicle',
            field: 'trim',
            op: 'ilike',
            value: `%${keyword}%`,
          },
          {
            model: 'Vehicle',
            field: 'trim_variation',
            op: 'ilike',
            value: `%${keyword}%`,
          },
          {
            model: 'Vehicle',
            field: 'vin',
            op: 'ilike',
            value: `%${keyword}%`,
          },
          {
            model: 'Vehicle',
            field: 'year',
            op: '==',
            value: parseInt(keyword) || 0,
          },
        ],
      })),
    );

  const handleSearch = e =>
    e.key === 'Enter' ? setSearchFiltersFromKeywords() : null;

  const inStockFilter = {
    model: 'StockStatus',
    field: 'name',
    op: '==',
    value: 'In Stock',
  };

  const inGroupFilter = {
    model: 'DealerGroupMember',
    field: 'group_id',
    op: '==',
    value:
      orgId === GOAUTO_ORG_ID
        ? GOAUTO_GROUP_ID
        : orgId === TEST_ORG_ID
        ? TEST_DEALERS_GROUP_ID
        : null,
  };

  const dealerFilter = {
    model: 'Vehicle',
    field: 'dealer_id',
    op: '==',
    value: dealerId,
  };

  const filters = [
    inStockFilter,
    groupSearch ? inGroupFilter : dealerFilter,
    ...searchFilters,
  ];
  const sort = [sortSelection, SORT_BY_ID].filter(x => x);

  const { data, error, fetchMore, loading, networkStatus } = useQuery(
    INVENTORY_QUERY,
    {
      skip: isEmpty(searchFilters),
      variables: {
        page: 1,
        filters,
        sort,
      },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    },
  );

  const vehicles = data?.inventory.getGroupVehicles.results ?? [];
  const { next_page: nextPage, total } =
    data?.inventory.getGroupVehicles.pagination ?? {};

  const handleClose = () => onClose();
  const handleConfirm = () =>
    onConfirm(vehicles.find(({ id }) => id === selectedVehicleId));

  const handleMoreVehicles = () =>
    nextPage
      ? fetchMore({
          variables: { page: nextPage },
          updateQuery: (prev, { fetchMoreResult: more }) => {
            if (!more.inventory.getGroupVehicles.results) return prev;
            return Object.assign({}, prev, {
              inventory: {
                __typename: prev.inventory.__typename,
                getGroupVehicles: {
                  __typename: prev.inventory.getGroupVehicles.__typename,
                  results: [
                    ...prev.inventory.getGroupVehicles.results,
                    ...more.inventory.getGroupVehicles.results,
                  ],
                  pagination: more.inventory.getGroupVehicles.pagination,
                },
              },
            });
          },
        })
      : null;

  const isSortingBy = ({ model, field, direction }) =>
    sortSelection?.field === field &&
    sortSelection?.model === model &&
    (!direction || sortSelection?.direction === direction);

  const sortDirection = sortSelection?.direction;
  const clickSortHandler =
    ({ model, field }) =>
    () =>
      setSortSelection(prev => ({
        model,
        field,
        direction:
          prev?.model === model &&
          prev?.field === field &&
          prev?.direction === 'asc'
            ? 'desc'
            : 'asc',
      }));

  const sortProps = sort => ({
    onClick: clickSortHandler(sort),
    active: isSortingBy(sort),
    direction: sortDirection,
  });

  return (
    <Dialog {...{ onClose, ...rest }} maxWidth="lg" fullWidth>
      <SortPopover
        anchorEl={popoverAnchor}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={Boolean(popoverAnchor)}
        onClose={() => setPopoverAnchor(null)}
        sortOptions={VEHICLE_SORT_OPTIONS}
        setSortSelection={setSortSelection}
        sortSelection={sortSelection}
      />
      <DialogTitle style={{ fontWeight: 'bold' }}>
        Search for a vehicle:
      </DialogTitle>
      <DialogContent>
        <Box
          display="flex"
          alignItems="flex-end"
          justifyContent="space-between"
        >
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={groupSearch}
                  onChange={() => setGroupSearch(x => !x)}
                />
              }
              label="Search Group"
            />

            <TextField
              placeholder="Search ..."
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      disabled={!searchKeywords}
                      onClick={() => {
                        setSearchKeywords('');
                        setSearchFilters([]);
                      }}
                      size="large"
                    >
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              value={searchKeywords}
              onChange={e => setSearchKeywords(e.target.value)}
              style={{ paddingRight: '5px', textAlign: 'right' }}
              onKeyPress={handleSearch}
            />
            <Button
              variant="contained"
              style={{
                backgroundColor: theme.actions.create.color,
                color: 'white',
              }}
              onClick={setSearchFiltersFromKeywords}
            >
              Search
            </Button>
          </Box>

          <Box fontSize="18px">
            {total ? `Showing ${vehicles.length} of ${total}` : 'No results'}
          </Box>
        </Box>
        <Table>
          <TableHead>
            <TableRow className={boldHead}>
              <TableCell>
                <TableSortLabel {...sortProps(DEALER_SORT)}>
                  Dealer
                </TableSortLabel>
              </TableCell>
              <TableCell /> {/* photo */}
              <TableCell>
                <TableSortLabel
                  onClick={e => setPopoverAnchor(e.currentTarget)}
                  active={VEHICLE_SORT_OPTIONS.some(isSortingBy)}
                  direction={sortDirection}
                >
                  Vehicle
                </TableSortLabel>
              </TableCell>
              <TableCell /> {/* int/ext colours */}
              <TableCell>
                <TableSortLabel {...sortProps(STOCK_NUMBER_SORT)}>
                  Stock #
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel {...sortProps(ODOMETER_SORT)}>
                  Odometer
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel {...sortProps(PRICE_SORT)}>
                  Price
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel {...sortProps(DAYS_SORT)}>Days</TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading && networkStatus !== NetworkStatus.fetchMore
              ? null
              : vehicles.map(
                  ({
                    id,
                    days_in_stock,
                    dealer_name,
                    exterior_colour_name,
                    interior_colour_name,
                    is_demo_unit,
                    is_loaner,
                    make_name,
                    model_name,
                    odometer,
                    displayPhoto,
                    list_price,
                    stock_number,
                    stock_type,
                    trim_variation,
                    trim,
                    year,
                  }) => (
                    <TableRow
                      hover
                      key={id}
                      onClick={() =>
                        setSelectedVehicleId(
                          selectedVehicleId !== id ? id : null,
                        )
                      }
                      selected={selectedVehicleId === id}
                    >
                      <TableCell>{dealer_name}</TableCell>
                      <TableCell>
                        <img
                          alt="Vehicle"
                          style={{ width: '100px', objectFit: 'cover' }}
                          src={formatPhoto(displayPhoto)}
                        />
                      </TableCell>
                      <TableCell>
                        <Box>
                          {year} {make_name} {model_name} {trim}
                        </Box>
                        <Box color="lightgrey">{trim_variation}</Box>
                      </TableCell>
                      <TableCell>
                        <Box>
                          {interior_colour_name ? (
                            `Int: ${interior_colour_name}`
                          ) : (
                            <br />
                          )}
                        </Box>
                        <Box color="lightgrey">
                          {exterior_colour_name
                            ? `Ext: ${exterior_colour_name}`
                            : ' '}
                        </Box>
                      </TableCell>
                      <TableCell>
                        {stock_number}
                        <br />
                        {[
                          (stock_type ?? '').toUpperCase(),
                          is_demo_unit ? 'DEMO' : null,
                          is_loaner ? 'LOANER' : null,
                        ]
                          .filter(x => x)
                          .join('/')}
                      </TableCell>
                      <TableCell>{formatOdometer(odometer)}</TableCell>
                      <TableCell>{formatPrice(list_price)}</TableCell>
                      <TableCell>{days_in_stock}</TableCell>
                    </TableRow>
                  ),
                )}
          </TableBody>
        </Table>
        {loading && networkStatus !== NetworkStatus.fetchMore && <Loading />}
        {!loading && <Waypoint onEnter={handleMoreVehicles} />}
        {loading && networkStatus === NetworkStatus.fetchMore ? (
          <Loading text="Loading more vehicles..." />
        ) : (
          nextPage && <Box height="6rem" />
        )}
        {error && <ErrorDisplay error={error} />}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          style={{ color: theme.actions.cancel.color }}
        >
          Cancel
        </Button>
        <Button
          disabled={!selectedVehicleId}
          style={{ color: theme.actions.create.color }}
          onClick={handleConfirm}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default VehicleSearchDialog;
