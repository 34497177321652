import { Status } from 'constants.js';
import moment from 'moment';

// Utility functions for Opportunities

export const getFullVehicle = dmsDeal => {
  const make = dmsDeal.make_name ?? null;
  const model = dmsDeal.model_name ?? null;
  const year = dmsDeal.year ?? null;
  if (make && model && year) {
    return `${year} ${make} ${model}`;
  }
  return '';
};

// Given a list of users filter based on a list of roles
export const sortUsers = (users, roles) => {
  const filteredUsers = users.filter(user => roles.includes(user.role));
  const sortedUsers = filteredUsers.sort((a, b) =>
    a.display_name.localeCompare(b.display_name),
  );
  return sortedUsers;
};

// See if two objects are the same
export const objectEquality = (first, second) => {
  let isSame = true;
  if (!typeof first === 'object' || !typeof second === 'object') {
    return false;
  }
  if (Object.keys(first).length !== Object.keys(second).length) {
    return false;
  }

  // If keys don't match return false
  Object.keys(first).map(key =>
    first[key] !== second[key] ? (isSame = false) : isSame,
  );

  // If all keys are the same
  return isSame;
};

// Given a date range return correct start and end date
export const getDateRange = range => {
  if (range === 'today') {
    return {
      date_to: moment().format('YYYY-MM-DD'),
      date_from: moment().format('YYYY-MM-DD'),
    };
  }
  // Current week starting Sunday
  if (range === 'week') {
    return {
      date_to: moment().endOf('week').format('YYYY-MM-DD'),
      date_from: moment().startOf('week').format('YYYY-MM-DD'),
    };
  }
  if (range === 'month') {
    return {
      date_to: moment().endOf('month').format('YYYY-MM-DD'),
      date_from: moment().startOf('month').format('YYYY-MM-DD'),
    };
  }
  if (range === 'quarter') {
    return {
      date_to: moment().endOf('quarter').format('YYYY-MM-DD'),
      date_from: moment().startOf('quarter').format('YYYY-MM-DD'),
    };
  }
};

export const formatChipDate = ({ date_from, date_to }) => {
  let chipString = '';
  if (date_from) {
    chipString += `From: ${moment(date_from).format('l')} `;
  }
  if (date_to) {
    chipString += `To: ${moment(date_to).format('l')}`;
  }
  return chipString;
};

export const buildDateRange = (startDate, endDate) => {
  if (startDate !== null && endDate !== null) {
    return {
      date_to: moment(endDate).format('YYYY-MM-DD'),
      date_from: moment(startDate).format('YYYY-MM-DD'),
    };
  }
  if (startDate !== null) {
    return {
      date_from: moment(startDate).format('YYYY-MM-DD'),
    };
  }
  if (endDate !== null) {
    return {
      date_to: moment(endDate).format('YYYY-MM-DD'),
    };
  }
  return {};
};

// Translate counts to their status keys
export const translateStatusCounts = statusCounts => {
  let counts = {};
  for (const [key, value] of Object.entries(statusCounts)) {
    if (key === 'fresh_count') {
      counts[Status.FRESH] = value;
    }
    if (key === 'desk_count') {
      counts[Status.DESK] = value;
    }
    if (key === 'fi_count') {
      counts[Status.FI] = value;
    }
    if (key === 'posted_count') {
      counts[Status.POSTED] = value;
    }
    if (key === 'pending_count') {
      counts[Status.PENDING] = value;
    }
    if (key === 'cash_count') {
      counts[Status.CASH] = value;
    }
    if (key === 'approved_count') {
      counts[Status.APPROVED] = value;
    }
    if (key === 'signed_count') {
      counts[Status.SIGNED] = value;
    }
    if (key === 'preapp_count') {
      counts[Status.PRE_APP] = value;
    }
    if (key === 'carry_count') {
      counts[Status.CARRYOVER] = value;
    }
    if (key === 'delivered_count') {
      counts[Status.DELIVERED] = value;
    }
    if (key === 'lost_count') {
      counts[Status.LOST] = value;
    }
    if (key === 'tubed_count') {
      counts[Status.TUBED] = value;
    }
    if (key === 'total_unassigned') {
      counts['unassigned'] = value;
    }
    // Map so we can use lead channels constants in filter
    if (key.includes('inbound') || key.includes('outbound')) {
      const translateKey = key.replace(/total_|_count/g, '');
      const finalKey = translateKey.replace('_', ' ');
      counts[finalKey] = value;
    }
    if (
      key.includes('new') ||
      key.includes('used') ||
      key.includes('unknown')
    ) {
      const translateKey = key.replace('_count', '');
      counts[translateKey] = value;
    }
  }
  return counts;
};

export const mapLeadSourceCounts = (counts, leadSources) => {
  const leadsWithCount = [];
  leadSources.forEach(leadSource => {
    const countObject = counts.find(count => count._id === leadSource);
    leadsWithCount.push(countObject ?? { _id: leadSource, count: 0 });
  });
  return leadsWithCount;
};

export const mapAssigneeCounts = (counts, users) => {
  // Iterate through users
  users.forEach(user => {
    const assigneeCount = counts.find(
      assignee => assignee._id === user.username,
    );
    user.count = assigneeCount?.count ?? 0;
  });
  return users;
};

export const mapSubStatusCounts = (counts, subStatuses) => {
  const subStatusCounts = [];
  subStatuses.forEach(subStatus => {
    const subStatusObject = counts.find(count => count._id === subStatus);
    subStatusCounts.push(
      subStatusObject !== undefined
        ? subStatusObject
        : { _id: subStatus, count: 0 },
    );
  });
  return subStatusCounts;
};

export const getTaskBorderColor = task => {
  // If task is completed use green
  if (task.status === 1) {
    return { borderColor: 'limegreen' };
  }
  if (task.status === 2) {
    return { borderColor: 'gray' };
  }
  if (task.is_overdue) {
    return { borderColor: 'red' };
  }
  return { borderColor: 'yellow' };
};

// functions from https://material-ui.com/components/tables/
export function descendingComparator(a, b, orderBy) {
  if (orderBy === 'fullname') {
    if (!a?.customer || !b?.customer) {
      return 0;
    }
    if (b.customer[orderBy] < a.customer[orderBy]) {
      return -1;
    }
    if (b.customer[orderBy] > a.customer[orderBy]) {
      return 1;
    }
  } else {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

export function getMultipleStatusCount(statuses, counts) {
  var total = 0;
  statuses.map(status => (total += counts[status] ?? 0));
  return total;
}
