import React from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Typography,
  Grid,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { usePersistedState, formatPrice } from 'utils';
import moment from 'moment';
import { useQuery } from '@apollo/react-hooks';
import { MANAGER_DASHBOARD_QUERY, INVENTORY_DASHBOARD_QUERY } from '../queries';
import { useUserContext } from 'components/MaterialUI/UserContext';
import { RoleGroup, AppointmentStatus } from 'constants.js';
import { flatten } from 'lodash';
import { sessionKeys } from 'modules/dashboard/constants';
import { useHistory } from 'react-router-dom';

export const AppointmentsTable = ({
  classes,
  opportunityDateFromFilter,
  opportunityDateToFilter,
  appt_table,
  appt_totals,
}) => (
  <Table stickyHeader size="small" style={{ height: 'max-content' }}>
    <TableHead>
      <TableRow>
        <TableCell className={classes.right}>
          <b>Sales Person</b>
        </TableCell>
        <TableCell className={classes.middle}>
          <b>Today</b>
        </TableCell>
        <TableCell className={classes.middle}>
          <b>Tomorrow</b>
        </TableCell>
        <TableCell className={classes.middle}>
          <b>Next 7 Days</b>
        </TableCell>
        <TableCell className={classes.left}>
          <b> {`${opportunityDateFromFilter} to ${opportunityDateToFilter}`}</b>
        </TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {appt_table?.map(appt => (
        <TableRow>
          <TableCell className={classes.right}>
            {appt.user?.display_name}
          </TableCell>
          <TableCell className={classes.middle}>{appt.today}</TableCell>
          <TableCell className={classes.middle}>{appt.tomorrow}</TableCell>
          <TableCell className={classes.middle}>{appt.next_7_days}</TableCell>
          <TableCell className={classes.left}>{appt.date_range}</TableCell>
        </TableRow>
      ))}
    </TableBody>
    <TableBody>
      <TableRow>
        <TableCell className={classes.totalRight}>
          <b>Total</b>
        </TableCell>
        <TableCell className={classes.totalMiddle}>
          {appt_totals.today}
        </TableCell>
        <TableCell className={classes.totalMiddle}>
          {appt_totals.tomorrow}
        </TableCell>
        <TableCell className={classes.totalMiddle}>
          {appt_totals.next_7_days}
        </TableCell>
        <TableCell className={classes.totalLeft}>
          {appt_totals.date_range}
        </TableCell>
      </TableRow>
    </TableBody>
  </Table>
);

export const DealTable = ({ classes, deallog_table, deallog_totals }) => (
  <Table stickyHeader size="small" style={{ height: 'max-content' }}>
    <TableHead>
      <TableRow>
        <TableCell className={classes.right}>
          <b>Sales Person</b>
        </TableCell>
        <TableCell className={classes.middle}>
          <b>Pending</b>
        </TableCell>
        <TableCell className={classes.middle}>
          <b>Booked</b>
        </TableCell>
        <TableCell className={classes.left}>
          <b>Sold</b>
        </TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {deallog_table?.map(opp => (
        <TableRow>
          <TableCell className={classes.right}>
            {opp.user?.display_name}
          </TableCell>
          <TableCell className={classes.middle}>{opp.pending}</TableCell>
          <TableCell className={classes.middle}>{opp.booked}</TableCell>
          <TableCell className={classes.left}>{opp.sold}</TableCell>
        </TableRow>
      ))}
    </TableBody>
    <TableBody>
      <TableRow>
        <TableCell className={classes.totalRight}>
          <b>Total</b>
        </TableCell>
        <TableCell className={classes.totalMiddle}>
          {deallog_totals.pending}
        </TableCell>
        <TableCell className={classes.totalMiddle}>
          {deallog_totals.booked}
        </TableCell>
        <TableCell className={classes.totalLeft}>
          {deallog_totals.sold}
        </TableCell>
      </TableRow>
    </TableBody>
  </Table>
);

export const useManagerDashboardLogic = (prefix, dealerId) => {
  const history = useHistory();

  const [opportunityDateFromFilter, setOpportunityDateFromFilter] =
    usePersistedState(
      `${prefix}.dateFromFilter`,
      moment().startOf('month').format('YYYY-MM-DD'),
    );

  const [opportunityDateToFilter, setOpportunityDateToFilter] =
    usePersistedState(`${prefix}.dateToFilter`, moment().format('YYYY-MM-DD'));

  const [inventoryDateFromFilter, setInventoryDateFromFilter] =
    usePersistedState(
      `${prefix}.inventoryDateFromFilter`,
      moment().subtract(120, 'days').format('YYYY-MM-DD'),
    );

  const inventoryDateToFilter = moment().format('YYYY-MM-DD');

  const { currentUser } = useUserContext();

  const isManager = RoleGroup.MANAGERS.includes(currentUser?.role);

  const { data, loading } = useQuery(MANAGER_DASHBOARD_QUERY, {
    variables: {
      dealerId,
      date_from: opportunityDateFromFilter,
      date_to: opportunityDateToFilter,
    },
  });

  const inventoryQuery = useQuery(INVENTORY_DASHBOARD_QUERY, {
    variables: { dealerId },
    fetchPolicy: 'network-only',
    skip: !isManager,
  });

  const results = data?.getManagerDashboardStatistics;

  return {
    opportunityDateFromFilter,
    opportunityDateToFilter,
    inventoryDateFromFilter,
    inventoryDateToFilter,
    handleDateFromChange: date =>
      opportunityDateToFilter > moment(date).format('YYYY-MM-DD')
        ? setOpportunityDateFromFilter(moment(date).format('YYYY-MM-DD'))
        : setOpportunityDateFromFilter(
            moment(opportunityDateToFilter)
              .subtract(1, 'days')
              .format('YYYY-MM-DD'),
          ),
    handleDateToChange: date =>
      opportunityDateFromFilter < moment(date).format('YYYY-MM-DD')
        ? setOpportunityDateToFilter(moment(date).format('YYYY-MM-DD'))
        : setOpportunityDateToFilter(
            moment(opportunityDateFromFilter)
              .add(1, 'days')
              .format('YYYY-MM-DD'),
          ),
    handleInventoryDateFromChange: date =>
      inventoryDateToFilter > moment(date).format('YYYY-MM-DD')
        ? setInventoryDateFromFilter(moment(date).format('YYYY-MM-DD'))
        : setInventoryDateFromFilter(
            moment(inventoryDateToFilter)
              .subtract(1, 'days')
              .format('YYYY-MM-DD'),
          ),
    managerQuery: { results, loading },
    inventoryQuery: inventoryQuery,
    opp_totals: {
      today: results?.opp_table?.reduce((a, b) => a + b.today, 0) ?? 0,
      month_to_date:
        results?.opp_table?.reduce((a, b) => a + b.month_to_date, 0) ?? 0,
      year_to_date:
        results?.opp_table?.reduce((a, b) => a + b.year_to_date, 0) ?? 0,
      date_range:
        results?.opp_table?.reduce((a, b) => a + b.date_range, 0) ?? 0,
    },
    opp_table: (results?.opp_table?.filter(x => x.year_to_date > 0) || []).sort(
      (a, b) => b.year_to_date - a.year_to_date,
    ),
    deallog_table: (results?.deallog_table?.filter(x => x.sold > 0) || []).sort(
      (a, b) => b.sold - a.sold,
    ),
    deallog_totals: {
      pending: results?.deallog_table?.reduce((a, b) => a + b.pending, 0) ?? 0,
      booked: results?.deallog_table?.reduce((a, b) => a + b.booked, 0) ?? 0,
      sold: results?.deallog_table?.reduce((a, b) => a + b.sold, 0) ?? 0,
    },
    appt_totals: {
      today: results?.appt_table?.reduce((a, b) => a + b.today, 0) ?? 0,
      tomorrow: results?.appt_table?.reduce((a, b) => a + b.tomorrow, 0) ?? 0,
      next_7_days:
        results?.appt_table?.reduce((a, b) => a + b.next_7_days, 0) ?? 0,
      date_range:
        results?.appt_table?.reduce((a, b) => a + b.date_range, 0) ?? 0,
    },
    inventoryFilterHandler: (...filters) => {
      window.sessionStorage.setItem(
        sessionKeys.inventoryFilters,
        JSON.stringify(flatten(filters)),
      );
    },
    handleAppointmentsCardClick: () => {
      window.sessionStorage.setItem(
        sessionKeys.createdUntilFilter,
        JSON.stringify(moment().endOf('day').toISOString()),
      );
      window.sessionStorage.setItem(
        sessionKeys.createdFromFilter,
        JSON.stringify(moment().startOf('day').toISOString()),
      );
      window.sessionStorage.setItem(sessionKeys.apptDealer, dealerId);
      history.push('/appointments');
    },
    handleUpcomingAppointmentsCardClick: () => {
      window.sessionStorage.setItem(
        sessionKeys.startDateFromFilter,
        JSON.stringify(moment().startOf('day')),
      );
      window.sessionStorage.setItem(sessionKeys.apptDealer, dealerId);
      history.push('/appointments');
    },
    handleAttendedAppointmentsCardClick: () => {
      window.sessionStorage.setItem(
        sessionKeys.apptFilterFacets,
        JSON.stringify([
          {
            model: 'Appointment',
            field: 'statuses',
            key: AppointmentStatus.ATTENDED,
            value: 'Attended',
            options: {},
          },
        ]),
      );
      window.sessionStorage.setItem(sessionKeys.apptDealer, dealerId);
      history.push('/appointments');
    },
    inventoryDateSince: moment(inventoryDateToFilter).diff(
      moment(inventoryDateFromFilter),
      'days',
    ),
  };
};

export const InventoryHeader = ({
  handleInventoryDateFromChange,
  inventoryDateFromFilter,
  inventoryDateToFilter,
}) => (
  <Box paddingTop={2} paddingLeft={1}>
    <Typography variant="h5" style={{ fontWeight: 'bold' }}>
      Inventory
    </Typography>
    <DatePicker
      disableToolbar
      variant="inline"
      format="MM/DD/YYYY"
      margin="normal"
      id="date-picker-inline"
      label="Date From"
      style={{
        padding: '1em',
      }}
      onChange={date => handleInventoryDateFromChange(date)}
      value={moment(inventoryDateFromFilter)}
    />
    <DatePicker
      disableToolbar
      disabled
      variant="inline"
      format="MM/DD/YYYY"
      margin="normal"
      id="date-picker-inline"
      label="Date To"
      style={{
        padding: '1em',
      }}
      value={moment(inventoryDateToFilter)}
    />
  </Box>
);

export const IncompletePhotos = ({ totalIncomplete }) => (
  <>
    <Box
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <Box style={{ fontSize: '24px' }}>
        <i className="fas fa-quote-left"></i>
      </Box>
      <Box style={{ fontSize: '24px' }}>
        <i className="fas fa-camera"></i>
      </Box>
      <Box style={{ fontSize: '20px', fontWeight: 'bold' }}>
        {totalIncomplete}
      </Box>
    </Box>
    <Box
      style={{
        fontSize: '18px',
        fontWeight: 'bold',
        textAlign: 'right',
      }}
    >
      Incomplete
    </Box>
    <Box
      style={{
        fontSize: '14px',
        fontWeight: 'bold',
        textAlign: 'right',
      }}
    >
      Missing Photos, Descriptions
    </Box>
  </>
);

export const OpportunityTable = ({
  opportunityDateFromFilter,
  opportunityDateToFilter,
  opp_table,
  opp_totals,
  mobile,
  classes,
}) => {
  const Body2Wrapper = !mobile ? TableBody : React.Fragment;
  return (
    <>
      <TableHead>
        <TableRow>
          <TableCell className={classes.right}>
            <b>{mobile ? 'S.P.' : 'Sales Person'}</b>
          </TableCell>
          <TableCell className={classes.middle}>
            <b>Today</b>
          </TableCell>
          <TableCell className={classes.middle}>
            <b>{mobile ? 'MTD' : 'Month To Date'}</b>
          </TableCell>
          <TableCell className={classes.middle}>
            <b>{mobile ? 'YTD' : 'Year To Date'}</b>
          </TableCell>
          <TableCell className={classes.left}>
            <b>
              {mobile && ' '}
              {`${opportunityDateFromFilter} to ${opportunityDateToFilter}`}
            </b>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {opp_table?.map(opp => (
          <TableRow key={opp._id}>
            <TableCell className={classes.right}>
              {opp.user?.display_name}
            </TableCell>
            <TableCell className={classes.middle}>{opp.today}</TableCell>
            <TableCell className={classes.middle}>
              {opp.month_to_date}
            </TableCell>
            <TableCell className={classes.middle}>{opp.year_to_date}</TableCell>
            <TableCell className={classes.left}>{opp.date_range}</TableCell>
          </TableRow>
        ))}
      </TableBody>
      <Body2Wrapper>
        <TableRow>
          <TableCell className={classes.totalRight}>
            <b>Total</b>
          </TableCell>
          <TableCell className={classes.totalMiddle}>
            {opp_totals.today}
          </TableCell>
          <TableCell className={classes.totalMiddle}>
            {opp_totals.month_to_date}
          </TableCell>
          <TableCell className={classes.totalMiddle}>
            {opp_totals.year_to_date}
          </TableCell>
          <TableCell className={classes.totalLeft}>
            {opp_totals.date_range}
          </TableCell>
        </TableRow>
      </Body2Wrapper>
    </>
  );
};

export const SalesHeader = ({
  handleDateFromChange,
  handleDateToChange,
  opportunityDateFromFilter,
  opportunityDateToFilter,
}) => (
  <Box paddingTop={2} paddingLeft={1}>
    <Typography variant="h5" style={{ fontWeight: 'bold' }}>
      Sales
    </Typography>
    <DatePicker
      disableToolbar
      variant="inline"
      format="MM/DD/YYYY"
      margin="normal"
      id="date-picker-inline"
      label="Date From"
      style={{
        padding: '1em',
      }}
      onChange={date => handleDateFromChange(date)}
      value={moment(opportunityDateFromFilter)}
    />
    <DatePicker
      disableToolbar
      variant="inline"
      format="MM/DD/YYYY"
      margin="normal"
      id="date-picker-inline"
      label="Date To"
      style={{
        padding: '1em',
      }}
      onChange={date => handleDateToChange(date)}
      value={moment(opportunityDateToFilter)}
    />
  </Box>
);

export const VehiclesInfo = ({
  totalVehicles,
  usedVehiclesInStock,
  newVehiclesInStock,
}) => (
  <>
    <Box style={{ fontSize: '14px' }}>In Stock</Box>
    <Box style={{ fontSize: '18px', fontWeight: 'bold' }}>
      {totalVehicles} Total
    </Box>
    <Box
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <Box>{usedVehiclesInStock} Used</Box>
      <Box>{newVehiclesInStock} New</Box>
    </Box>
  </>
);

export const PriceChanges = ({ lastPriceChangeLt, inventoryDateSince }) => (
  <>
    <Box
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <Box style={{ fontSize: '24px', paddingLeft: '1rem' }}>
        <i className="fas fa-surprise"></i>
      </Box>
      <Box style={{ fontSize: '20px', fontWeight: 'bold' }}>
        {lastPriceChangeLt}
      </Box>
    </Box>
    <Box
      style={{
        fontSize: '16px',
        textAlign: 'right',
        fontWeight: 'bold',
      }}
    >
      No Price Changes in {inventoryDateSince} Days
    </Box>
  </>
);

export const InStockGtView = ({
  inStockGt,
  totalVehicles,
  inventoryDateSince,
}) => (
  <>
    <Box
      style={{
        color: `${inStockGt > 0 ? 'red' : 'green'}`,
        fontSize: '18px',
        fontWeight: 'bold',
      }}
    >
      {Math.round((inStockGt / totalVehicles) * 100)}%
    </Box>
    <Box style={{ fontSize: '14px' }}>
      ({inStockGt} / {totalVehicles})
    </Box>
    <Box style={{ fontSize: '18px', fontWeight: 'bold' }}>
      Over {inventoryDateSince} Days
    </Box>
  </>
);

export const TotalGross = ({ results }) => (
  <Grid item style={{ paddingRight: '1rem' }}>
    <Box
      style={{
        textAlign: 'center',
        paddingTop: '1rem',
      }}
    >
      <Box>Total Potential Gross</Box>
      <Box style={{ fontSize: '18px', fontWeight: 'bold' }}>
        {formatPrice(results?.potential_gross, {
          cents: true,
          zeroIfNull: true,
        })}
      </Box>
    </Box>
  </Grid>
);

export const CompleteGross = ({ results }) => (
  <Box
    style={{
      textAlign: 'center',
      paddingTop: '1rem',
    }}
  >
    <Box>Total Complete Gross</Box>
    <Box style={{ fontSize: '18px', fontWeight: 'bold' }}>
      {formatPrice(results?.complete_gross, {
        cents: true,
        zeroIfNull: true,
      })}
    </Box>
  </Box>
);
