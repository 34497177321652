import React from 'react';

import PropTypes from 'prop-types';

import Checkbox from '@mui/material/Checkbox';
import { makeStyles } from '@mui/styles';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';

const useStyles = makeStyles(theme => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const headCells = [
  { label: 'Actions', id: null, numeric: false, sortable: false },
  { label: 'Type', id: 'type', numeric: false, sortable: true },
  { label: 'Customer', id: 'customer_id', numeric: false, sortable: false },
  { label: 'Task', id: 'description', numeric: false, sortable: false },
  { label: 'Assignee', id: 'assignee', numeric: false, sortable: true },
  { label: 'Created', id: 'created', numeric: false, sortable: true },
  { label: 'Due Date', id: 'due_date', numeric: false, sortable: true },
];

function TaskTableHeader(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;

  const { visuallyHidden } = useStyles();
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  const ORDER_MAP = {
    '-1': 'desc',
    1: 'asc',
  };

  return (
    <TableHead>
      <TableRow
        style={{
          borderLeftWidth: '5px',
          borderLeftColor: 'white',
          borderLeftStyle: 'solid',
        }}
      >
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'Select All Tasks' }}
          />
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={
              orderBy === headCell.id ? ORDER_MAP[String(order)] : false
            }
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={
                orderBy === headCell.id ? ORDER_MAP[String(order)] : 'desc'
              }
              onClick={createSortHandler(headCell.id)}
              disabled={!headCell.sortable}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={visuallyHidden}>
                  {order === -1 ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

TaskTableHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf([-1, 1]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default TaskTableHeader;
