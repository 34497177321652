import React from 'react';

/* external */
import PropTypes from 'prop-types';

/* Material UI */
import Checkbox from '@mui/material/Checkbox';
import { makeStyles } from '@mui/styles';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';

import cloneDeep from 'lodash/cloneDeep';

const useStyles = makeStyles(theme => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const headCells = [
  { label: '', id: 'badges', numeric: false, sortable: false },
  { label: 'Customer', id: 'fullname', numeric: false, sortable: true },
  { label: 'Vehicle', id: 'vehicle', numeric: false, sortable: false },
  { label: 'Details', id: 'details', numeric: false, sortable: false },
  { label: '', id: 'source', numeric: false, sortable: false },
  { label: 'Salespeople', id: 'salespeople', numeric: false, sortable: false },
  {
    label: 'Next Appointment',
    id: 'appointment',
    numeric: false,
    sortable: false,
  },
  { label: 'Created', id: 'created', numeric: false, sortable: true },
];

function OpportunitiesListHeaders(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    filters,
    updateFilters,
  } = props;

  const { visuallyHidden } = useStyles();
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  const sortByCreated = e => {
    const newSortFilters = cloneDeep(filters);
    newSortFilters['sort_by'][0].direction =
      newSortFilters['sort_by'][0].direction * -1;
    updateFilters({
      ...newSortFilters,
    });
    onRequestSort(e, 'created');
  };

  return (
    <TableHead>
      <TableRow>
        {/* Bulk actions not yet implemented */}
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'Select All Vehicles' }}
          />
        </TableCell>
        <TableCell>Actions</TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            alight="left"
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={() => {
                headCell.id === 'created'
                  ? sortByCreated()
                  : createSortHandler(headCell.id);
              }}
              disabled={!headCell.sortable}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

OpportunitiesListHeaders.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  filters: PropTypes.object.isRequired,
  updateFilters: PropTypes.func.isRequired,
};

export default OpportunitiesListHeaders;
