import React from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

const LeadContactCard = ({ lead, classes }) => (
  <Card>
    <CardContent>
      <Typography style={{ padding: '0 0 .5rem 1rem', fontWeight: '700' }}>
        Contact Information
      </Typography>
      <TableContainer>
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
        >
          <colgroup>
            <col style={{ width: '50%' }} />
            <col style={{ width: '50%' }} />
          </colgroup>
          <TableBody>
            <TableRow>
              <TableCell>Full Name</TableCell>
              <TableCell>
                <b>{`${lead.form_data?.first_name} ${lead.form_data?.last_name}`}</b>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>CASL</TableCell>
              <TableCell>
                <b>{lead.form_data?.casl_opt_in ? 'Opt In' : 'Opt Out'}</b>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Preferred Contact Method</TableCell>
              <TableCell>
                <b>{lead.form_data?.preferred_contact_method || '-'}</b>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Purchase Timeline</TableCell>
              <TableCell>
                <b>
                  {lead.form_data?.purchase_timeline_months
                    ? lead.form_data?.purchase_timeline_months + 'months'
                    : 'Unknown'}
                </b>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>
                <b>{lead.form_data?.email || '-'}</b>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Phone</TableCell>
              <TableCell>
                <b>{lead.form_data?.primary_phone || '-'}</b>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Address</TableCell>
              <TableCell>
                <b>{lead.form_data?.street_address_1 || '-'}</b>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>City</TableCell>
              <TableCell>
                <b>{lead.form_data?.city || '-'}</b>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Province</TableCell>
              <TableCell>
                <b>{lead.form_data?.province || '-'}</b>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Postal Code</TableCell>
              <TableCell>
                <b>{lead.form_data?.postal_code || '-'}</b>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Country</TableCell>
              <TableCell>
                <b>{lead.form_data?.country || '-'}</b>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </CardContent>
  </Card>
);

export default LeadContactCard;
