import React from 'react';

import { CANADA_POST_API_KEY } from 'constants.js';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Autocomplete from '@mui/material/Autocomplete';

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

const AddressAutocomplete = ({ defaultAddress, onChange }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(defaultAddress);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);

  const clearAddress = () => {
    setValue('');
    onChange('');
  };

  React.useEffect(() => {
    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    let requestUrl = `https://ws1.postescanada-canadapost.ca/AddressComplete/Interactive/Find/v2.10/json3.ws?Key=${CANADA_POST_API_KEY}`;

    requestUrl += '&SearchTerm=' + encodeURIComponent(inputValue);
    requestUrl += '&Country=' + encodeURIComponent('CAN');
    requestUrl += '&MaxSuggestions=' + encodeURIComponent(5);

    fetch(requestUrl)
      .then(response => response.json())
      .then(data => {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (data?.Items) {
          newOptions = [...newOptions, ...data.Items];
        }

        setOptions(newOptions);
      });
  }, [value, inputValue]);
  return (
    <Autocomplete
      freeSolo
      getOptionLabel={option =>
        typeof option === 'string'
          ? option
          : option.Text + ', ' + option.Description
      }
      filterOptions={x => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={(event, newValue) => {
        if (!options.includes(newValue)) {
          setOptions(newValue ? [newValue, ...options] : options);
        }
        if (newValue?.Description) {
          setValue(newValue.Text + ', ' + newValue.Description);
        } else if (newValue === null) {
          clearAddress();
        } else {
          // If not found still allow users to select it
          onChange(newValue);
        }
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
        onChange(newInputValue);
      }}
      renderInput={params => <TextField {...params} fullWidth />}
      renderOption={(props, option) => {
        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item>
                <LocationOnIcon className={classes.icon} />
              </Grid>
              <Grid item xs>
                <span style={{ fontWeight: 700 }}>{option?.Text}</span>
                <Typography variant="body2" color="textSecondary">
                  {option?.Description}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
};

export default AddressAutocomplete;
