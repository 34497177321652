/* external */
import React, { useEffect } from 'react';
import gql from 'graphql-tag';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';

/* Material UI */
import { FormControl, Step, StepButton, StepContent } from '@mui/material';
import GoodBadRadioGroup from 'components/MaterialUI/GoodBadRadioGroup';
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';
import RadioControl from 'components/MaterialUI/RadioControl2';
import YesNoRadioGroup from 'components/MaterialUI/YesNoRadioGroup';

/* internal */
import { errorHandler } from '../utils';
import StepActions from './StepActions';

const StepThree = ({
  appraisal,
  label,
  onNext,
  onBack,
  setActiveStep,
  setIsDirty,
  stepDirty,
  ...rest
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const UPDATE_APPRAISAL = gql`
    mutation updateAppraisal($id: Int!, $data: UpdateAppraisalInput!) {
      appraisals {
        updateAppraisal(id: $id, data: $data) {
          id
          ...StepThreeAppraisal
        }
      }
    }
    ${StepThree.fragments.appraisal}
  `;
  const [updateAppraisal, { loading: updating }] =
    useMutation(UPDATE_APPRAISAL);
  const { id } = appraisal;

  const { control, formState, handleSubmit, reset, setError } = useForm({
    defaultValues: appraisal,
  });
  const { isDirty } = formState;
  useEffect(() => {
    reset(appraisal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appraisal]);

  useEffect(() => {
    if (isDirty && !stepDirty) setIsDirty();
    else {
      if (!isDirty && stepDirty) setIsDirty(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty, setIsDirty]);

  const onSubmit = data => {
    const filteredData = {
      hasLien: data.hasLien,
      hasOpi: data.hasOpi,
      isGlassGood: data.isGlassGood,
      isTiresGood: data.isTiresGood,
    };
    if (isDirty)
      updateAppraisal({ variables: { id, data: filteredData } }).then(
        () => onNext(),
        err => errorHandler(enqueueSnackbar, setError)(err),
      );
    else onNext();
  };

  return (
    <Step {...rest} key={label}>
      <StepButton disabled={!appraisal.id} onClick={() => setActiveStep(2)}>
        {label}
      </StepButton>
      <StepContent>
        <LoadingBackdrop open={updating} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <FormControl component="fieldset">
              <RadioControl
                control={control}
                label="OPI"
                name="hasOpi"
                as={<YesNoRadioGroup />}
              />
            </FormControl>
          </div>
          <div>
            <FormControl component="fieldset">
              <RadioControl
                control={control}
                label="Lien"
                name="hasLien"
                as={<YesNoRadioGroup />}
              />
            </FormControl>
          </div>
          <div>
            <FormControl component="fieldset">
              <RadioControl
                control={control}
                label="Glass"
                name="isGlassGood"
                as={<GoodBadRadioGroup />}
              />
            </FormControl>
          </div>
          <div>
            <FormControl component="fieldset">
              <RadioControl
                control={control}
                label="Tires"
                name="isTiresGood"
                as={<GoodBadRadioGroup />}
              />
            </FormControl>
          </div>
          <StepActions
            onNext={() => {}}
            nextButtonProps={{ disabled: updating, type: 'submit' }}
            nextLabel={isDirty ? 'Save' : 'Next'}
            onBack={onBack}
          />
        </form>
      </StepContent>
    </Step>
  );
};

StepThree.fragments = {
  appraisal: gql`
    fragment StepThreeAppraisal on Appraisal {
      hasLien
      hasOpi
      isGlassGood
      isTiresGood
    }
  `,
};
export default StepThree;
