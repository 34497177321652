import React, { useRef, useState } from 'react';
import { withStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import { cloneDeep } from 'lodash';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import InspectionCheckboxes from '../InspectionCheckboxes';

const JaguarCPOForm = props => {
  const { enqueueSnackbar } = useSnackbar();

  // JaguarCPO form fields
  const vehicle = {
    j1: 'Was the vehicle built for sale in Canada?',
    j2: 'Does vehicle meet age, model year and mileage eligibility?',
    j3: 'Has the vehicle passed a CarProof Vehicle History and Mileage Check and is the vehicle free from any structural repair/frame damage?',
    j4: 'Is the vehicle free from any warranty restrictions?',
    j5: 'Is the vehicle free from current or evidence of non Jaguar approved aftermarket modifications?',
    j6: 'Does the vehicle have a complete service history?',
    j7: 'Does the windshield VIN plate number match the VIN number information on A/B pillar/ driver’s door area?',
    j8: 'Have all outstanding Recalls and Service Campaigns been satisfied?',
  };
  const preliminary = {
    j9: 'Owner’s Manual complete and present?',
    j10: 'All keys present and operational?',
  };
  const interior = {
    j11: 'General interior condition',
    j12: 'Veneer, wood, leather and headliner',
    j13: 'Glove box, console box and trim',
    j14: 'Ashtray and stowage compartment door',
    j15: '(IF EQUIPPED)  Roof opening panel and roof blind',
    j16: 'Sun visor and vanity mirror',
    j17: 'Restraint/airbag system',
    j18: 'Seat Occupant Sensing system',
    j19: 'Child safety door latches',
    j20: 'Seat belts',
    j21: 'Steering column and steering lock',
    j22: 'Door handles (interior)',
    j23: 'Seats',
    j24: 'Carpets and mats',
    j25: 'Headlining and trim',
    j26: 'Head restraint',
    j27: '(IF EQUIPPED) Rear and side window blinds',
    j28: '(IF EQUIPPED) Accessories',
  };
  const electrical = {
    j29: 'Stored Diagnostic Trouble Codes (DTC)',
    j30: 'Fuses (condition/proper rating)',
    j31: 'Warning lights',
    j32: 'Window operation',
    j33: 'Central locking system',
    j34: 'Seat adjustment',
    j35: 'Mirrors',
    j36: 'Interior lights',
    j37: 'Clocks',
    j38: 'Cigarette lighters, power sockets and USB Ports',
    j39: 'Horns',
    j40: 'Wipers',
    j41: 'Washer jets',
    j42: 'Instrument panel illumination',
    j43: 'Audio system',
    j44: 'InControl® Apps and InControl® Remote & Protect',
    j45: 'Speakers',
    j46: 'Battery',
    j47: 'Alarm system',
    j48: 'Warning chimes',
    j49: 'Interior mood lighting',
    j50: '(IF EQUIPPED) Surround cameras/venture camera',
    j51: '(IF EQUIPPED) Rear reversing camera',
    j52: '(IF EQUIPPED) Parking aid sensors',
    j53: '(IF EQUIPPED) Sunroof opening panel',
    j54: '(IF EQUIPPED) Rear seat entertainment systems',
    j55: '(IF EQUIPPED) Voice control activation system',
    j56: '(IF EQUIPPED) Multi-Function Display/Touch Screen',
    j57: '(IF EQUIPPED) Bluetooth connectivity',
    j58: 'Tire Pressure Monitoring System (TPMS)',
  };
  const heating = {
    j59: 'Automatic and manual modes',
    j60: 'Blower motor',
    j61: 'Air distribution and vent controls',
    j62: 'HVAC pollen filter',
    j63: 'Outlet temperature (heating and cooling)',
    j64: 'Exterior temperature display',
    j65: '(IF EQUIPPED) Heated windshields',
    j66: '(IF EQUIPPED) Heated seats',
    j67: '(IF EQUIPPED) Cooled seats',
  };
  const electricalTests = {
    j68: 'Headlights',
    j69: 'Parking lights',
    j70: 'Daytime Running Lights',
    j71: 'Tail lights',
    j72: '(IF EQUIPPED)  Fog lights',
    j73: 'Hazard warning lights',
    j74: 'Brake stop lights',
    j75: 'Reversing lights',
    j76: 'Turn signal indicators',
    j77: 'License plate lights',
    j78: '(IF EQUIPPED) Headlight levelling',
  };
  const exterior = {
    j79: 'General body and paintwork',
    j80: 'Panel alignment/fit',
    j81: 'Wheels',
    j82: 'Doors',
    j83: 'Bumpers and front fascia',
    j84: 'Front grille, trim and moldings',
    j85: 'Glass windshield',
    j86: 'Glass roof panel, if applicable',
    j87: 'Mirror glass (interior and exterior)',
    j88: 'Exterior lights and reflectors',
    j89: 'Fuel flap/fuel cap',
    j90: 'Fuel filler',
    j91: 'Badges and nameplates',
    j92: '(IF EQUIPPED) Convertible folding top',
    j93: '(IF EQUIPPED) Accessories',
  };
  const exteriorLuggage = {
    j94: 'Tool kit',
    j95: 'Spare tire or tire repair kit',
    j96: 'Trim and carpet',
    j97: 'Back of rear seats',
    j98: 'Trunk lid or tailgate',
    j99: '(IF EQUIPPED) Power tailgate',
  };
  const exteriorEngine = {
    j100: 'Hood latch',
    j101: 'Engine oil',
    j102: 'Power steering pump/hoses',
    j103: 'Cooling system fluid',
    j104: 'Coolant hoses',
    j105: 'Cooling fans and motors',
    j106: 'Brake master cylinder and booster',
    j107: 'Clutch master cylinder',
    j108: 'Automatic/manual transmission system',
    j109: 'Washer fluid',
    j110: 'Air filters',
    j111: 'Wiring',
    j112: 'Vacuum hoses',
    j113: 'Drive belts',
    j114: 'Underhood appearance',
  };
  const underCarriage = {
    j115: 'Frame/underbody',
    j116: 'Suspension',
    j117: 'Steering',
    j118: 'Fluid leaks',
    j119: 'Engine and transmission mounts',
    j120: 'Brake lines and couplings',
    j121: 'Exhaust system',
    j122: 'Wheel bearings',
    j123: 'Brake Pad Thickness (6 mm pad material min.) - Steel\nBrake Pad Thickness (between New and Min.) - Carbon Ceramic',
    j124: 'Brake Rotor Thickness (rotors 50% of wear limit) - Steel\nBrake Rotor Weight (must be between New and Min. Weight) - Carbon Ceramic',
    j125: 'Brake fluid',
    j126: 'Wheel specification',
    j127: 'Tire specifications',
    j128: 'Tire condition',
    j129: 'Tire tread depths (6/32” or 4.76 mm min.)',
    j130: 'Wheel nuts',
    j131: 'Differential fluid level (IF APPLICABLE)',
    j132: 'Universal joints/CV joints/CV boots',
  };
  const tests = {
    j133: 'Hot/cold start',
    j134: 'Engine noises',
    j135: 'Engine idling',
    j136: 'Fuel pump/lines/hoses',
    j137: 'Starter/alternator',
    j138: 'Instrument pack gauges',
    j139: 'Fluid leaks',
  };
  const testsRoad = {
    j140: '(IF EQUIPPED) Electronic Air Suspension',
    j141: 'Trip computer',
    j142: 'Engine performance',
    j143: 'Vibration',
    j144: 'Wind noise',
    j145: 'Stability and pull',
    j146: 'General handling',
    j147: 'Steering wheel alignment',
    j148: 'Brake pedal',
    j149: 'ABS braking system',
    j150: 'Emergency brake',
    j151: 'Squeaks and rattles',
    j152: '(IF EQUIPPED) Dynamic Stability Control (DSC) System and Electronic Traction Control (ETC) System',
    j153: 'Jaguar Drive Control  (IF EQUIPPED) Winter Mode',
    j154: 'Cruise Control',
    j155: '(IF EQUIPPED) Adaptive Cruise Control',
    j156: '(IF EQUIPPED) Forward Alert',
    j157: '(IF EQUIPPED) Head Up Display',
    j158: '(IF EQUIPPED) Stop/start system',
    j159: '(IF EQUIPPED) Automatic transmission',
    j160: '(IF EQUIPPED) Manual transmission and clutch',
    j161: '(IF EQUIPPED) Blind Spot Monitor',
    j162: '(IF EQUIPPED) Navigation system',
    j163: '(IF EQUIPPED) Deployable rear spoiler',
    j164: '(IF EQUIPPED) Convertible folding top',
  };
  const testsEmissions = {
    j165: 'Emissions tests',
  };

  let vehicleObj = cloneDeep(vehicle);
  let preliminaryObj = cloneDeep(preliminary);
  let interiorObj = cloneDeep(interior);
  let electricalObj = cloneDeep(electrical);
  let heatingObj = cloneDeep(heating);
  let electricalTestsObj = cloneDeep(electricalTests);
  let exteriorObj = cloneDeep(exterior);
  let exteriorLuggageObj = cloneDeep(exteriorLuggage);
  let exteriorEngineObj = cloneDeep(exteriorEngine);
  let underCarriageObj = cloneDeep(underCarriage);
  let testsObj = cloneDeep(tests);
  let testsRoadObj = cloneDeep(testsRoad);
  let testsEmissionsObj = cloneDeep(testsEmissions);

  Object.entries(vehicleObj).map(([name]) => (vehicleObj[name] = 'yes'));
  Object.entries(preliminaryObj).map(
    ([name]) => (preliminaryObj[name] = 'yes'),
  );
  Object.entries(interiorObj).map(([name]) => (interiorObj[name] = 'yes'));
  Object.entries(electricalObj).map(([name]) => (electricalObj[name] = 'yes'));
  Object.entries(heatingObj).map(([name]) => (heatingObj[name] = 'yes'));
  Object.entries(electricalTestsObj).map(
    ([name]) => (electricalTestsObj[name] = 'yes'),
  );
  Object.entries(exteriorObj).map(([name]) => (exteriorObj[name] = 'yes'));
  Object.entries(exteriorLuggageObj).map(
    ([name]) => (exteriorLuggageObj[name] = 'yes'),
  );
  Object.entries(exteriorEngineObj).map(
    ([name]) => (exteriorEngineObj[name] = 'yes'),
  );
  Object.entries(underCarriageObj).map(
    ([name]) => (underCarriageObj[name] = 'yes'),
  );
  Object.entries(testsObj).map(([name]) => (testsObj[name] = 'yes'));
  Object.entries(testsRoadObj).map(([name]) => (testsRoadObj[name] = 'yes'));
  Object.entries(testsEmissionsObj).map(
    ([name]) => (testsEmissionsObj[name] = 'yes'),
  );

  const [vehicleValues, setVehicleValues] = useState(vehicleObj);
  const [preliminaryValues, setPreliminaryValues] = useState(preliminaryObj);
  const [interiorValues, setInteriorValues] = useState(interiorObj);
  const [electricalValues, setElectricalValues] = useState(electricalObj);
  const [heatingValues, setHeatingValues] = useState(heatingObj);
  const [electricalTestsValues, setElectricalTestsValues] =
    useState(electricalTestsObj);
  const [exteriorValues, setExteriorValues] = useState(exteriorObj);
  const [exteriorLuggageValues, setExteriorLuggageValues] =
    useState(exteriorLuggageObj);
  const [exteriorEngineValues, setExteriorEngineValues] =
    useState(exteriorEngineObj);
  const [underCarriageValues, setUnderCarriageValues] =
    useState(underCarriageObj);
  const [testsValues, setTestsValues] = useState(testsObj);
  const [testsRoadValues, setTestsRoadValues] = useState(testsRoadObj);
  const [testsEmissionsValues, setTestsEmissionsValues] =
    useState(testsEmissionsObj);

  const handleVehicle = e => {
    const { name, value } = e.target;
    if (value === 'na') {
      e.target.closest('div').previousSibling.style.textDecoration =
        'line-through';
    } else {
      e.target.closest('div').previousSibling.style.textDecoration = 'none';
    }
    setVehicleValues(prevVehicleValues => ({
      ...prevVehicleValues,
      [name]: value,
    }));
  };
  const handlePreliminary = e => {
    const { name, value } = e.target;
    if (value === 'na') {
      e.target.closest('div').previousSibling.style.textDecoration =
        'line-through';
    } else {
      e.target.closest('div').previousSibling.style.textDecoration = 'none';
    }
    setPreliminaryValues(prevPreliminaryValues => ({
      ...prevPreliminaryValues,
      [name]: value,
    }));
  };
  const handleInterior = e => {
    const { name, value } = e.target;
    if (value === 'na') {
      e.target.closest('div').previousSibling.style.textDecoration =
        'line-through';
    } else {
      e.target.closest('div').previousSibling.style.textDecoration = 'none';
    }
    setInteriorValues(prevInteriorValues => ({
      ...prevInteriorValues,
      [name]: value,
    }));
  };
  const handleElectrical = e => {
    const { name, value } = e.target;
    if (value === 'na') {
      e.target.closest('div').previousSibling.style.textDecoration =
        'line-through';
    } else {
      e.target.closest('div').previousSibling.style.textDecoration = 'none';
    }
    setElectricalValues(prevElectricalValues => ({
      ...prevElectricalValues,
      [name]: value,
    }));
  };
  const handleHeating = e => {
    const { name, value } = e.target;
    if (value === 'na') {
      e.target.closest('div').previousSibling.style.textDecoration =
        'line-through';
    } else {
      e.target.closest('div').previousSibling.style.textDecoration = 'none';
    }
    setHeatingValues(prevHeatingValues => ({
      ...prevHeatingValues,
      [name]: value,
    }));
  };
  const handleElectricalTests = e => {
    const { name, value } = e.target;
    if (value === 'na') {
      e.target.closest('div').previousSibling.style.textDecoration =
        'line-through';
    } else {
      e.target.closest('div').previousSibling.style.textDecoration = 'none';
    }
    setElectricalTestsValues(prevElectricalTestsValues => ({
      ...prevElectricalTestsValues,
      [name]: value,
    }));
  };
  const handleExterior = e => {
    const { name, value } = e.target;
    if (value === 'na') {
      e.target.closest('div').previousSibling.style.textDecoration =
        'line-through';
    } else {
      e.target.closest('div').previousSibling.style.textDecoration = 'none';
    }
    setExteriorValues(prevExteriorValues => ({
      ...prevExteriorValues,
      [name]: value,
    }));
  };
  const handleExteriorLuggage = e => {
    const { name, value } = e.target;
    if (value === 'na') {
      e.target.closest('div').previousSibling.style.textDecoration =
        'line-through';
    } else {
      e.target.closest('div').previousSibling.style.textDecoration = 'none';
    }
    setExteriorLuggageValues(prevExteriorLuggageValues => ({
      ...prevExteriorLuggageValues,
      [name]: value,
    }));
  };
  const handleExteriorEngine = e => {
    const { name, value } = e.target;
    if (value === 'na') {
      e.target.closest('div').previousSibling.style.textDecoration =
        'line-through';
    } else {
      e.target.closest('div').previousSibling.style.textDecoration = 'none';
    }
    setExteriorEngineValues(prevExteriorEngineValues => ({
      ...prevExteriorEngineValues,
      [name]: value,
    }));
  };
  const handleUnderCarriage = e => {
    const { name, value } = e.target;
    if (value === 'na') {
      e.target.closest('div').previousSibling.style.textDecoration =
        'line-through';
    } else {
      e.target.closest('div').previousSibling.style.textDecoration = 'none';
    }
    setUnderCarriageValues(prevUnderCarriageValues => ({
      ...prevUnderCarriageValues,
      [name]: value,
    }));
  };
  const handleTests = e => {
    const { name, value } = e.target;
    if (value === 'na') {
      e.target.closest('div').previousSibling.style.textDecoration =
        'line-through';
    } else {
      e.target.closest('div').previousSibling.style.textDecoration = 'none';
    }
    setTestsValues(prevTestsValues => ({
      ...prevTestsValues,
      [name]: value,
    }));
  };
  const handleTestsRoad = e => {
    const { name, value } = e.target;
    if (value === 'na') {
      e.target.closest('div').previousSibling.style.textDecoration =
        'line-through';
    } else {
      e.target.closest('div').previousSibling.style.textDecoration = 'none';
    }
    setTestsRoadValues(prevTestsRoadValues => ({
      ...prevTestsRoadValues,
      [name]: value,
    }));
  };
  const handleTestsEmissions = e => {
    const { name, value } = e.target;
    if (value === 'na') {
      e.target.closest('div').previousSibling.style.textDecoration =
        'line-through';
    } else {
      e.target.closest('div').previousSibling.style.textDecoration = 'none';
    }
    setTestsEmissionsValues(prevTestsEmissionsValues => ({
      ...prevTestsEmissionsValues,
      [name]: value,
    }));
  };

  const [consent, setConsent] = useState(false);
  const handleConsent = () => {
    setConsent(consent => !consent);
  };

  const [confirm, setConfirm] = useState(false);
  const handleConfirm = () => {
    setConfirm(confirm => !confirm);
  };

  const wholeForm = useRef(null);

  const handleSubmit = e => {
    e.preventDefault();

    if (!wholeForm.current['amvic_number'].value) {
      enqueueSnackbar(`Please fill out 'Tech #'.`);
      return;
    }

    if (!consent) {
      enqueueSnackbar(`Please check 'I agree' and then submit the inspection!`);
      return;
    }

    preliminaryValues['j10_key'] = wholeForm.current['j10_key'].value;
    exteriorValues['j85_oem'] = wholeForm.current['j85_oem'].checked;
    exteriorValues['j86_oem'] = wholeForm.current['j86_oem'].checked;
    exteriorValues['j123_lf'] = wholeForm.current['j123_lf'].value;
    exteriorValues['j123_rf'] = wholeForm.current['j123_rf'].value;
    exteriorValues['j123_lr'] = wholeForm.current['j123_lr'].value;
    exteriorValues['j123_rr'] = wholeForm.current['j123_rr'].value;
    exteriorValues['j123_l'] = wholeForm.current['j123_l'].value;
    exteriorValues['j123_r'] = wholeForm.current['j123_r'].value;
    exteriorValues['j123_lf_oem'] = wholeForm.current['j123_lf_oem'].checked;
    exteriorValues['j123_rf_oem'] = wholeForm.current['j123_rf_oem'].checked;
    exteriorValues['j123_lr_oem'] = wholeForm.current['j123_lr_oem'].checked;
    exteriorValues['j123_rr_oem'] = wholeForm.current['j123_rr_oem'].checked;
    exteriorValues['j123_l_oem'] = wholeForm.current['j123_l_oem'].checked;
    exteriorValues['j123_r_oem'] = wholeForm.current['j123_r_oem'].checked;
    exteriorValues['j124_lf'] = wholeForm.current['j124_lf'].value;
    exteriorValues['j124_rf'] = wholeForm.current['j124_rf'].value;
    exteriorValues['j124_lr'] = wholeForm.current['j124_lr'].value;
    exteriorValues['j124_rr'] = wholeForm.current['j124_rr'].value;
    exteriorValues['j124_lf_oem'] = wholeForm.current['j124_lf_oem'].checked;
    exteriorValues['j124_rf_oem'] = wholeForm.current['j124_rf_oem'].checked;
    exteriorValues['j124_lr_oem'] = wholeForm.current['j124_lr_oem'].checked;
    exteriorValues['j124_rr_oem'] = wholeForm.current['j124_rr_oem'].checked;
    exteriorValues['j126_oem'] = wholeForm.current['j126_oem'].checked;
    exteriorValues['j127_oem'] = wholeForm.current['j127_oem'].checked;
    exteriorValues['j129_lf_i'] = wholeForm.current['j129_lf_i'].value;
    exteriorValues['j129_lf_c'] = wholeForm.current['j129_lf_c'].value;
    exteriorValues['j129_lf_o'] = wholeForm.current['j129_lf_o'].value;
    exteriorValues['j129_lr_i'] = wholeForm.current['j129_lr_i'].value;
    exteriorValues['j129_lr_c'] = wholeForm.current['j129_lr_c'].value;
    exteriorValues['j129_lr_o'] = wholeForm.current['j129_lr_o'].value;
    exteriorValues['j129_s_i'] = wholeForm.current['j129_s_i'].value;
    exteriorValues['j129_s_c'] = wholeForm.current['j129_s_c'].value;
    exteriorValues['j129_s_o'] = wholeForm.current['j129_s_o'].value;
    exteriorValues['j129_rr_i'] = wholeForm.current['j129_rr_i'].value;
    exteriorValues['j129_rr_c'] = wholeForm.current['j129_rr_c'].value;
    exteriorValues['j129_rr_o'] = wholeForm.current['j129_rr_o'].value;
    exteriorValues['j129_rf_i'] = wholeForm.current['j129_rf_i'].value;
    exteriorValues['j129_rf_c'] = wholeForm.current['j129_rf_c'].value;
    exteriorValues['j129_rf_o'] = wholeForm.current['j129_rf_o'].value;
    exteriorValues['j129_lf_oem'] = wholeForm.current['j129_lf_oem'].checked;
    exteriorValues['j129_lr_oem'] = wholeForm.current['j129_lr_oem'].checked;
    exteriorValues['j129_s_oem'] = wholeForm.current['j129_s_oem'].checked;
    exteriorValues['j129_rr_oem'] = wholeForm.current['j129_rr_oem'].checked;
    exteriorValues['j129_rf_oem'] = wholeForm.current['j129_rf_oem'].checked;

    const values = {
      ...vehicleValues,
      ...preliminaryValues,
      ...interiorValues,
      ...electricalValues,
      ...heatingValues,
      ...electricalTestsValues,
      ...exteriorValues,
      ...exteriorLuggageValues,
      ...exteriorEngineValues,
      ...underCarriageValues,
      ...testsValues,
      ...testsRoadValues,
      ...testsEmissionsValues,
    };
    const form_data = {
      jlr: values,
      technician_comments: wholeForm.current['technician_comments'].value,
      amvic_number: wholeForm.current['amvic_number'].value,
    };

    props.formData(form_data);
    props.formClose();
  };

  const GreenButton = withStyles({
    root: {
      color: '#fff',
      backgroundColor: '#198754',
      '&:hover': {
        backgroundColor: '#157347',
      },
    },
  })(Button);

  return (
    <form onSubmit={handleSubmit} ref={wholeForm}>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>VEHICLE ELIGIBILITY</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(vehicle).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={vehicleValues}
                      handleGroup={handleVehicle}
                      name={name}
                      orange={false}
                      white={true}
                      greenValue="yes"
                      redValue="no"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>PRELIMINARY CHECKS</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(preliminary).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={preliminaryValues}
                      handleGroup={handlePreliminary}
                      name={name}
                      orange={false}
                      white={true}
                      greenValue="yes"
                      redValue="no"
                    />
                  </Grid>
                  {name === 'j10' && (
                    <Grid
                      container
                      alignItems="center"
                      style={{ marginBottom: '1rem' }}
                    >
                      <Grid item xs={12} style={{ paddingLeft: '1rem' }}>
                        <RadioGroup
                          row
                          aria-label="position"
                          name="j10_key"
                          defaultValue="top"
                        >
                          <FormControlLabel
                            value="1"
                            control={<Radio color="primary" />}
                            label="1 key"
                            labelPlacement="start"
                          />
                          <FormControlLabel
                            value="2"
                            control={<Radio color="primary" />}
                            label="2 keys"
                            labelPlacement="start"
                          />
                        </RadioGroup>
                      </Grid>
                    </Grid>
                  )}
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>INTERIOR - GENERAL</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(interior).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={interiorValues}
                      handleGroup={handleInterior}
                      name={name}
                      orange={false}
                      white={true}
                      greenValue="yes"
                      redValue="no"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>ELECTRICAL TESTS</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(electrical).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={electricalValues}
                      handleGroup={handleElectrical}
                      name={name}
                      orange={false}
                      white={true}
                      greenValue="yes"
                      redValue="no"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>HEATING/AIR CONDITIONING SYSTEMS</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(heating).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={heatingValues}
                      handleGroup={handleHeating}
                      name={name}
                      orange={false}
                      white={true}
                      greenValue="yes"
                      redValue="no"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>ELECTRICAL TESTS - EXTERIOR</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(electricalTests).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={electricalTestsValues}
                      handleGroup={handleElectricalTests}
                      name={name}
                      orange={false}
                      white={true}
                      greenValue="yes"
                      redValue="no"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>EXTERIOR - GENERAL</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(exterior).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={exteriorValues}
                      handleGroup={handleExterior}
                      name={name}
                      orange={false}
                      white={true}
                      greenValue="yes"
                      redValue="no"
                    />
                  </Grid>
                  {name === 'j85' && (
                    <Grid
                      container
                      alignItems="center"
                      style={{ marginBottom: '1rem' }}
                    >
                      <Grid item xs={12} style={{ paddingLeft: '1rem' }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              value="oem"
                              name="j85_oem"
                            />
                          }
                          label="OEM*"
                          labelPlacement="start"
                        />
                      </Grid>
                    </Grid>
                  )}
                  {name === 'j86' && (
                    <Grid
                      container
                      alignItems="center"
                      style={{ marginBottom: '1rem' }}
                    >
                      <Grid item xs={12} style={{ paddingLeft: '1rem' }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              value="oem"
                              name="j86_oem"
                            />
                          }
                          label="OEM*"
                          labelPlacement="start"
                        />
                      </Grid>
                    </Grid>
                  )}
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>EXTERIOR - LUGGAGE COMPARTMENT</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(exteriorLuggage).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={exteriorLuggageValues}
                      handleGroup={handleExteriorLuggage}
                      name={name}
                      orange={false}
                      white={true}
                      greenValue="yes"
                      redValue="no"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>EXTERIOR - ENGINE AREA</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(exteriorEngine).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={exteriorEngineValues}
                      handleGroup={handleExteriorEngine}
                      name={name}
                      orange={false}
                      white={true}
                      greenValue="yes"
                      redValue="no"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>UNDERCARRIAGE/DRIVETRAIN</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(underCarriage).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={underCarriageValues}
                      handleGroup={handleUnderCarriage}
                      name={name}
                      orange={false}
                      white={true}
                      greenValue="yes"
                      redValue="no"
                    />
                  </Grid>
                  {name === 'j123' && (
                    <Grid
                      container
                      alignItems="center"
                      style={{ marginBottom: '1rem' }}
                    >
                      <Grid item xs={3} style={{ paddingLeft: '1rem' }}>
                        <TextField
                          label="Left Front(mm)"
                          fullWidth
                          name={'j123_lf'}
                        />
                      </Grid>
                      <Grid item xs={3} style={{ paddingLeft: '1rem' }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              value="oem"
                              name="j123_lf_oem"
                            />
                          }
                          label="OEM*"
                          labelPlacement="start"
                        />
                      </Grid>
                      <Grid item xs={3} style={{ paddingLeft: '1rem' }}>
                        <TextField
                          label="Right Front(mm)"
                          fullWidth
                          name={'j123_rf'}
                        />
                      </Grid>
                      <Grid item xs={3} style={{ paddingLeft: '1rem' }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              value="oem"
                              name="j123_rf_oem"
                            />
                          }
                          label="OEM*"
                          labelPlacement="start"
                        />
                      </Grid>
                      <Grid item xs={3} style={{ paddingLeft: '1rem' }}>
                        <TextField
                          label="Left Rear(mm)"
                          fullWidth
                          name={'j123_lr'}
                        />
                      </Grid>
                      <Grid item xs={3} style={{ paddingLeft: '1rem' }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              value="oem"
                              name="j123_lr_oem"
                            />
                          }
                          label="OEM*"
                          labelPlacement="start"
                        />
                      </Grid>
                      <Grid item xs={3} style={{ paddingLeft: '1rem' }}>
                        <TextField
                          label="Right Rear(mm)"
                          fullWidth
                          name={'j123_rr'}
                        />
                      </Grid>
                      <Grid item xs={3} style={{ paddingLeft: '1rem' }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              value="oem"
                              name="j123_rr_oem"
                            />
                          }
                          label="OEM*"
                          labelPlacement="start"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{ paddingLeft: '1rem', paddingTop: '1rem' }}
                      >
                        Separate Parking Brake (if applicable)
                      </Grid>
                      <Grid item xs={3} style={{ paddingLeft: '1rem' }}>
                        <TextField label="Left(mm)" fullWidth name={'j123_l'} />
                      </Grid>
                      <Grid item xs={3} style={{ paddingLeft: '1rem' }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              value="oem"
                              name="j123_l_oem"
                            />
                          }
                          label="OEM*"
                          labelPlacement="start"
                        />
                      </Grid>
                      <Grid item xs={3} style={{ paddingLeft: '1rem' }}>
                        <TextField
                          label="Right(mm)"
                          fullWidth
                          name={'j123_r'}
                        />
                      </Grid>
                      <Grid item xs={3} style={{ paddingLeft: '1rem' }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              value="oem"
                              name="j123_r_oem"
                            />
                          }
                          label="OEM*"
                          labelPlacement="start"
                        />
                      </Grid>
                    </Grid>
                  )}
                  {name === 'j124' && (
                    <Grid
                      container
                      alignItems="center"
                      style={{ marginBottom: '1rem' }}
                    >
                      <Grid item xs={3} style={{ paddingLeft: '1rem' }}>
                        <TextField
                          label="Left Front(mm/kg)"
                          fullWidth
                          name={'j124_lf'}
                        />
                      </Grid>
                      <Grid item xs={3} style={{ paddingLeft: '1rem' }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              value="oem"
                              name="j124_lf_oem"
                            />
                          }
                          label="OEM*"
                          labelPlacement="start"
                        />
                      </Grid>
                      <Grid item xs={3} style={{ paddingLeft: '1rem' }}>
                        <TextField
                          label="Right Front(mm/kg)"
                          fullWidth
                          name={'j124_rf'}
                        />
                      </Grid>
                      <Grid item xs={3} style={{ paddingLeft: '1rem' }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              value="oem"
                              name="j124_rf_oem"
                            />
                          }
                          label="OEM*"
                          labelPlacement="start"
                        />
                      </Grid>
                      <Grid item xs={3} style={{ paddingLeft: '1rem' }}>
                        <TextField
                          label="Left Rear(mm/kg)"
                          fullWidth
                          name={'j124_lr'}
                        />
                      </Grid>
                      <Grid item xs={3} style={{ paddingLeft: '1rem' }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              value="oem"
                              name="j124_lr_oem"
                            />
                          }
                          label="OEM*"
                          labelPlacement="start"
                        />
                      </Grid>
                      <Grid item xs={3} style={{ paddingLeft: '1rem' }}>
                        <TextField
                          label="Right Rear(mm/kg)"
                          fullWidth
                          name={'j124_rr'}
                        />
                      </Grid>
                      <Grid item xs={3} style={{ paddingLeft: '1rem' }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              value="oem"
                              name="j124_rr_oem"
                            />
                          }
                          label="OEM*"
                          labelPlacement="start"
                        />
                      </Grid>
                    </Grid>
                  )}
                  {name === 'j126' && (
                    <Grid
                      container
                      alignItems="center"
                      style={{ marginBottom: '1rem' }}
                    >
                      <Grid item xs={12} style={{ paddingLeft: '1rem' }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              value="oem"
                              name="j126_oem"
                            />
                          }
                          label="OEM*"
                          labelPlacement="start"
                        />
                      </Grid>
                    </Grid>
                  )}
                  {name === 'j127' && (
                    <Grid
                      container
                      alignItems="center"
                      style={{ marginBottom: '1rem' }}
                    >
                      <Grid item xs={12} style={{ paddingLeft: '1rem' }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              value="oem"
                              name="j127_oem"
                            />
                          }
                          label="OEM*"
                          labelPlacement="start"
                        />
                      </Grid>
                    </Grid>
                  )}
                  {name === 'j129' && (
                    <Grid
                      container
                      alignItems="center"
                      style={{ marginBottom: '1rem' }}
                    >
                      <Grid item xs={2} style={{ paddingLeft: '1rem' }}>
                        Location
                      </Grid>
                      <Grid item xs={2}>
                        Inside
                      </Grid>
                      <Grid item xs={2}>
                        Centre
                      </Grid>
                      <Grid item xs={2}>
                        Outside
                      </Grid>
                      <Grid item xs={2}>
                        OEM
                      </Grid>
                      <Grid item xs={2}></Grid>
                      <Grid item xs={2} style={{ paddingLeft: '1rem' }}>
                        Left Front
                      </Grid>
                      <Grid item xs={2}>
                        <TextField label="mm" fullWidth name={'j129_lf_i'} />
                      </Grid>
                      <Grid item xs={2}>
                        <TextField label="mm" fullWidth name={'j129_lf_c'} />
                      </Grid>
                      <Grid item xs={2}>
                        <TextField label="mm" fullWidth name={'j129_lf_o'} />
                      </Grid>
                      <Grid item xs={2}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              value="oem"
                              name="j129_lf_oem"
                            />
                          }
                        />
                      </Grid>
                      <Grid item xs={2}></Grid>
                      <Grid item xs={2} style={{ paddingLeft: '1rem' }}>
                        Left Rear
                      </Grid>
                      <Grid item xs={2}>
                        <TextField label="mm" fullWidth name={'j129_lr_i'} />
                      </Grid>
                      <Grid item xs={2}>
                        <TextField label="mm" fullWidth name={'j129_lr_c'} />
                      </Grid>
                      <Grid item xs={2}>
                        <TextField label="mm" fullWidth name={'j129_lr_o'} />
                      </Grid>
                      <Grid item xs={2}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              value="oem"
                              name="j129_lr_oem"
                            />
                          }
                        />
                      </Grid>
                      <Grid item xs={2}></Grid>
                      <Grid item xs={2} style={{ paddingLeft: '1rem' }}>
                        Spare
                      </Grid>
                      <Grid item xs={2}>
                        <TextField label="mm" fullWidth name={'j129_s_i'} />
                      </Grid>
                      <Grid item xs={2}>
                        <TextField label="mm" fullWidth name={'j129_s_c'} />
                      </Grid>
                      <Grid item xs={2}>
                        <TextField label="mm" fullWidth name={'j129_s_o'} />
                      </Grid>
                      <Grid item xs={2}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              value="oem"
                              name="j129_s_oem"
                            />
                          }
                        />
                      </Grid>
                      <Grid item xs={2}></Grid>
                      <Grid item xs={2} style={{ paddingLeft: '1rem' }}>
                        Right Rear
                      </Grid>
                      <Grid item xs={2}>
                        <TextField label="mm" fullWidth name={'j129_rr_i'} />
                      </Grid>
                      <Grid item xs={2}>
                        <TextField label="mm" fullWidth name={'j129_rr_c'} />
                      </Grid>
                      <Grid item xs={2}>
                        <TextField label="mm" fullWidth name={'j129_rr_o'} />
                      </Grid>
                      <Grid item xs={2}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              value="oem"
                              name="j129_rr_oem"
                            />
                          }
                        />
                      </Grid>
                      <Grid item xs={2}></Grid>
                      <Grid item xs={2} style={{ paddingLeft: '1rem' }}>
                        Right Front
                      </Grid>
                      <Grid item xs={2}>
                        <TextField label="mm" fullWidth name={'j129_rf_i'} />
                      </Grid>
                      <Grid item xs={2}>
                        <TextField label="mm" fullWidth name={'j129_rf_c'} />
                      </Grid>
                      <Grid item xs={2}>
                        <TextField label="mm" fullWidth name={'j129_rf_o'} />
                      </Grid>
                      <Grid item xs={2}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              value="oem"
                              name="j129_rf_oem"
                            />
                          }
                        />
                      </Grid>
                      <Grid item xs={2}></Grid>
                    </Grid>
                  )}
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>TESTS - STATIONARY</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(tests).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={testsValues}
                      handleGroup={handleTests}
                      name={name}
                      orange={false}
                      white={true}
                      greenValue="yes"
                      redValue="no"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>TESTS - ROAD</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(testsRoad).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={testsRoadValues}
                      handleGroup={handleTestsRoad}
                      name={name}
                      orange={false}
                      white={true}
                      greenValue="yes"
                      redValue="no"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>TESTS - EMISSION</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(testsEmissions).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={testsEmissionsValues}
                      handleGroup={handleTestsEmissions}
                      name={name}
                      orange={false}
                      white={true}
                      greenValue="yes"
                      redValue="no"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>

      <TextField
        label="Technician Comments"
        multiline
        minRows={2}
        variant="outlined"
        name={'technician_comments'}
        fullWidth
        style={{ marginTop: '15px' }}
      />
      <Typography style={{ marginTop: '15px' }}>
        Technician, Service Manager and Pre-Owned Manager signatures certify (i)
        the vehicle has been inspected against the Jaguar Approved Certified
        Pre-Owned program standards prior to being advertised and marketed as a
        Jaguar Approved Certified Pre-Owned vehicle (ii) prior to the sale of
        the vehicle, the retailer will provide the customer with a completed
        inspection report indicating all the components inspected, as required.
      </Typography>
      <FormControlLabel
        control={<Checkbox />}
        label="I agree"
        onChange={handleConsent}
        value={consent}
      />
      <br />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Technician Name"
            value={props.user.display_name}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Tech #"
            value={props.user.goUserProfile.profile.sales_licence}
            fullWidth
            name={'amvic_number'}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Date"
            value={new Date().toLocaleString()}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <Button
            type="button"
            variant="contained"
            style={{ marginRight: '15px' }}
            onClick={handleConfirm}
            color="default"
          >
            Cancel
          </Button>
          <Dialog
            open={confirm}
            onClose={handleConfirm}
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Current inspection will be closed and unsaved data will be gone.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleConfirm}>Cancel</Button>
              <Button onClick={props.formClose} color="secondary">
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
          <GreenButton type="submit" variant="contained">
            Save
          </GreenButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default JaguarCPOForm;
