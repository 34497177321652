import { useQuery } from '@apollo/react-hooks';
import { NetworkStatus } from 'apollo-boost';

/* external */
import gql from 'graphql-tag';
import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material';

/* Material UI */
import AddIcon from '@mui/icons-material/Add';
import FilterListIcon from '@mui/icons-material/FilterList';

/* internal */
import Loading from 'components/MaterialUI/Loading';

import OpportunityTaskCard from './OpportunityTaskCard';
import OpportunityTasksModal from './OpportunityTasksModal';

const useStyles = makeStyles(theme => ({
  addNote: {
    padding: '0px',
    margin: '0px 5px 0px 10px',
  },
  filterButton: {
    padding: '0px',
    margin: '0px 10px 0px 5px',
  },
  paper: {
    padding: '10px',
    margin: '10px',
    minHeight: '200px',
  },
  card: {
    marginBottom: '15px',
    marginTop: '15px',
  },
}));

const TASK_QUERY = gql`
  query getTasks($opportunity_id: ID) {
    tasks(opportunity_id: $opportunity_id) {
      results {
        _id
        assignee_username
        assignee {
          display_name
        }
        created
        customer_id
        dealer {
          dealer_name
        }
        dealer_id
        description
        due_date
        is_all_day_task
        is_due_today
        is_overdue
        opportunity_id
        organization_id
        permissions {
          can_update
        }
        status
        status_name
        type
      }
    }
  }
`;

const OpportunityTasks = ({ opportunity, dealerId }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [openModal, setOpenModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [hideCancelled, setHideCancelled] = useState(true);
  const [onlyOverdue, setOnlyOverdue] = useState(false);
  const [onlyUpcoming, setOnlyUpcoming] = useState(false);

  const { data, loading, refetch, networkStatus } = useQuery(TASK_QUERY, {
    variables: {
      opportunity_id: opportunity._id,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const handleFilterClick = event => {
    setAnchorEl(event.currentTarget);
  };

  // Filter the tasks
  const tasks = data?.tasks?.results || [];

  const filteredTasks = tasks.filter(task => {
    let match = true;
    if (hideCancelled && task.status === 2) {
      match = false;
    }
    if (onlyOverdue && !task.is_overdue) {
      match = false;
    }
    if (onlyUpcoming && !task.is_due_today) {
      match = false;
    }
    return match;
  });

  if (loading || networkStatus === NetworkStatus.refetch) {
    return <Loading text={'Fetching Tasks...'} />;
  }

  return (
    <>
      <Dialog
        maxWidth="sm"
        open={openModal}
        onClose={() => setOpenModal(false)}
        fullWidth={true}
      >
        <OpportunityTasksModal
          handleClose={() => setOpenModal(false)}
          onSave={() => refetch()}
          opportunity={opportunity}
          dealerId={dealerId}
        />
      </Dialog>
      <Paper className={classes.paper}>
        <Box display="flex" padding="10px">
          <h3>Tasks</h3>
          <IconButton
            className={classes.addNote}
            onClick={() => setOpenModal(true)}
            size="small"
          >
            <AddIcon style={theme.actions.add} />
          </IconButton>
          <IconButton
            className={classes.filterButton}
            color="primary"
            onClick={handleFilterClick}
            size="large"
          >
            <FilterListIcon style={theme.actions.filter} />
          </IconButton>
          <Menu
            id="filter-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            <MenuItem>
              <FormControlLabel
                label="Hide Cancelled Tasks"
                control={
                  <Checkbox
                    name="hide-cancelled"
                    onChange={() => setHideCancelled(prev => !prev)}
                    checked={hideCancelled}
                    color="primary"
                  />
                }
              />
            </MenuItem>
            <MenuItem>
              <FormControlLabel
                label="Only Overdue Tasks"
                control={
                  <Checkbox
                    name="only-overdue"
                    onChange={() => setOnlyOverdue(prev => !prev)}
                    checked={onlyOverdue}
                    color="primary"
                  />
                }
              />
            </MenuItem>
            <MenuItem>
              <FormControlLabel
                label="Only Upcoming Tasks"
                control={
                  <Checkbox
                    name="only-upcoming"
                    onChange={() => setOnlyUpcoming(prev => !prev)}
                    checked={onlyUpcoming}
                    color="primary"
                  />
                }
              />
            </MenuItem>
          </Menu>
        </Box>
        <Box display="flex" padding="10px">
          {hideCancelled && (
            <Chip
              label={'Hide Cancelled'}
              onDelete={() => setHideCancelled(false)}
              style={{ marginRight: '5px' }}
            />
          )}
          {onlyUpcoming && (
            <Chip
              label={'Upcoming Only'}
              onDelete={() => setOnlyUpcoming(false)}
              style={{ marginRight: '5px' }}
            />
          )}
          {onlyOverdue && (
            <Chip
              label={'Overdue Only'}
              onDelete={() => setOnlyOverdue(false)}
              style={{ marginRight: '5px' }}
            />
          )}
        </Box>
        {filteredTasks.length === 0 &&
          'There are no tasks for this opportunity'}
        {filteredTasks.map(task => (
          <OpportunityTaskCard
            key={task._id}
            task={task}
            refetch={refetch}
            dealerId={dealerId}
            setAddModalOpen={setOpenModal}
          />
        ))}
      </Paper>
    </>
  );
};

export default OpportunityTasks;
