import React from 'react';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import ActivityDateDisplay from './ActivityDateDisplay';
import gql from 'graphql-tag';

const useStyles = makeStyles(theme => ({
  details: {
    whiteSpace: 'pre-line',
    fontSize: '.75rem',
    overflowWrap: 'anywhere',
  },
  cardDefault: {
    padding: '4px 0px 0px 0px',
    alignItems: 'initial',
    whiteSpace: 'pre-line',
  },
  cardContent: {
    padding: '2px 0px 2px 0px',
  },
  card: {
    margin: '15px 1px 15px 1px',
    padding: '0px 10px 0px 10px',
  },
}));

const ActivityCardMobile = ({ act }) => {
  const classes = useStyles();
  const date = act?.timestamp;
  const event = act?.event;

  return (
    <>
      <CardHeader
        avatar={<ActivityDateDisplay date={date} />}
        title={event}
        titleTypographyProps={{
          style: { fontWeight: 'bold' },
        }}
      />
      <CardContent>
        <h5>Details:</h5>
        <Typography
          variant="body2"
          component="p"
          style={{ fontSize: '.75rem' }}
          className={classes.details}
        >
          {`${act.description}`}
        </Typography>
      </CardContent>
    </>
  );
};

ActivityCardMobile.fragments = {
  event: gql`
    fragment ActivityCardMobileFragment on CustomerEvent {
      description
      timestamp
      event
    }
  `,
};

export default ActivityCardMobile;
