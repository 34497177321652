// External
import React from 'react';
import gql from 'graphql-tag';

// MUI
import { Box, Divider, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

// Internal
import { getFullDateTime, withSuffix } from 'utils';

const useStyles = makeStyles(theme => ({
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px',
    textAlign: 'right',
  },
}));

const PanelTitle = ({ title, vehicle }) => {
  const classes = useStyles();
  const lastModified = getFullDateTime(
    withSuffix(vehicle.modifiedAt || vehicle.createdAt, 'Z'),
  );

  return (
    <Box pb="20px">
      <Box className={classes.flexContainer}>
        <Typography variant="h6">{title}</Typography>
        <Box>
          <Typography variant="body2">
            <b>Date in Stock: </b>{' '}
            {getFullDateTime(withSuffix(vehicle?.dateInStock, 'Z'))}
          </Typography>
          <Typography variant="body2">
            <b>Last Modified: </b>{' '}
            {` ${lastModified} by ${vehicle?.modifiedBy}`}
          </Typography>
        </Box>
      </Box>
      <Divider />
    </Box>
  );
};

PanelTitle.fragments = {
  vehicle: gql`
    fragment NpvVehiclePanelTitle on NpvVehicle {
      id
      dateInStock
      createdAt
      modifiedAt
      modifiedBy
    }
  `,
};

export default PanelTitle;
