import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material';
import TextField from '@mui/material/TextField';

import { TaskStatus } from '../constants';

const ADD_NOTE = gql`
  mutation addNote($input: NoteInput) {
    createNote(input: $input) {
      _id
    }
  }
`;

const ADD_NOTE_ON_OPP = gql`
  mutation addNoteOnOpportunity($_id: ID!, $input: AddNoteToOpportunityInput!) {
    addNoteOnOpportunity(_id: $_id, input: $input) {
      _id
    }
  }
`;

const UPDATE_TASK = gql`
  mutation UpdateTask($id: ID!, $input: UpdateTaskInput!) {
    updateTask(id: $id, input: $input) {
      _id
      due_date
      status
    }
  }
`;

const TaskComplete = ({
  open,
  setOpen,
  task,
  action,
  setOpenNew,
  isNoteOnOpportunity,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [addNew, setAddNew] = useState(false);
  const [addNote] = useMutation(ADD_NOTE);
  const [addNoteOnOpportunity] = useMutation(ADD_NOTE_ON_OPP);
  const [completeTask] = useMutation(UPDATE_TASK, {
    onCompleted: data => {
      action(data.updateTask.status);
      enqueueSnackbar('Task updated', { variant: 'success' });
      if (addNew) {
        setOpenNew(true);
      }
    },
    onError: err => {
      enqueueSnackbar(`Could not update task error: ${err}`, {
        variant: 'error',
      });
    },
  });

  const handleClose = () => setOpen(false);
  const { control, handleSubmit } = useForm();

  const onSubmit = value => {
    completeTask({
      variables: {
        id: task._id,
        input: {
          status: TaskStatus.COMPLETE,
        },
      },
    }).then(() => {
      const customerNoteData = {
        body: value.note,
        visibility: 'store_only',
        customer_id: task.customer_id,
        dealer_id: task.dealer_id,
      };

      const { body } = customerNoteData;

      if (value.note) {
        isNoteOnOpportunity
          ? addNoteOnOpportunity({
              variables: {
                _id: task.opportunity_id,
                input: {
                  body,
                  visibility: 'opp_assignees_only',
                },
              },
            })
          : addNote({
              variables: {
                input: customerNoteData,
              },
            });
      }
    });
    setOpen(false);
  };

  const handleCompleteAddNew = () => {
    setAddNew(true);
    handleSubmit(onSubmit)();
  };

  const theme = useTheme();
  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Complete task</DialogTitle>
        <form
          onSubmit={handleSubmit(onSubmit)}
          onClick={event => event.stopPropagation()}
          onFocus={event => event.stopPropagation()}
        >
          <DialogContent>
            <Controller
              render={({ field: { ref, ...field } }) => (
                <TextField
                  label="Notes"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  {...field}
                />
              )}
              defaultValue={null}
              name={'note'}
              control={control}
              style={{ width: '250px' }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" variant="outlined">
              Close
            </Button>
            <Button
              onClick={() => handleCompleteAddNew()}
              color="primary"
              variant="outlined"
              style={theme.actions.confirm}
            >
              Complete and add new
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              style={theme.actions.confirm}
            >
              Complete
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default TaskComplete;
