import React, { useState } from 'react';

import { useDealerContext } from 'components/MaterialUI/DealerContext';
import gql from 'graphql-tag';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';

import { useMutation, useQuery } from '@apollo/react-hooks';
import { useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import PersonIcon from '@mui/icons-material/Person';

import AutocompleteControl from 'components/MaterialUI/AutocompleteControl';
import { Role } from 'constants.js';
import { TaskStatus } from 'modules/tasks/constants';

const useStyles = makeStyles({
  center: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
});

const USERS_FOR_DEALER = gql`
  query UsersForDealer($dealerIds: [Int]!) {
    users: users(
      dealer_ids: $dealerIds
      roles: [
        "${Role.SALES_REP}"
        "${Role.SALES_MANAGER}"
        "${Role.FINANCE_MANAGER}"
        "${Role.INTERNET_SALES_REP}"
      ]
    ) {
      display_name
      username
      role
      profile {
        image_id
      }
    }
  }
`;

const BULK_UPDATE_TASKS = gql`
  mutation UpdateTasks($ids: [ID!]!, $input: UpdateTaskInput!) {
    updateTasks(ids: $ids, input: $input) {
      _id
      assignee {
        username
        display_name
        role
      }
    }
  }
`;

const UsernameAutocomplete = ({ control, dealer_id }) => {
  const { data: userData } = useQuery(USERS_FOR_DEALER, {
    variables: {
      dealerIds: [dealer_id],
    },
  });

  const desktop = useMediaQuery(theme => theme.breakpoints.up('sm'));
  return (
    <AutocompleteControl
      control={control}
      forcePopupIcon
      label="Username"
      name="username"
      options={userData?.users ?? []}
      optionValueKey="username"
      optionLabelKey="display_name"
      readOnly={!dealer_id}
      required
      style={{ width: desktop ? 300 : 250 }}
    />
  ); // Re-render on dealer_id change
};

const TasksBulkAction = ({
  refetch,
  anchorEl,
  selected,
  handleAnchorClose,
  setSelected,
}) => {
  const classes = useStyles();
  const [showReassign, setShowReassign] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { dealerId } = useDealerContext();
  const [bulkUpdateTasks] = useMutation(BULK_UPDATE_TASKS, {
    onCompleted: data => {
      refetch();
      setSelected([]);
      enqueueSnackbar('Bulk update successfully', { variant: 'success' });
    },
    onError: err => {
      enqueueSnackbar(`Could not bulk update task error: ${err}`, {
        variant: 'error',
      });
    },
  });

  const handleCompleteOrCancelBulkClick = status => {
    if (
      window.confirm(
        `Are you sure you want to bulk action ${
          status === TaskStatus.COMPLETE ? 'complete' : 'cancel'
        } tasks`,
      )
    )
      bulkUpdateTasks({
        variables: {
          ids: selected,
          input: {
            status: status,
          },
        },
      });
  };

  const handleClose = () => {
    setShowReassign(false);
    handleAnchorClose();
  };
  const { control, handleSubmit } = useForm();

  const onSubmit = value => {
    if (
      Array.isArray(selected) &&
      window.confirm('Are you sure you want to bulk reassign?')
    ) {
      bulkUpdateTasks({
        variables: {
          ids: selected,
          input: {
            assignee: value.username.username,
          },
        },
      });
    }
    setShowReassign(false);
    handleAnchorClose();
  };

  const theme = useTheme();

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleAnchorClose}
      >
        <MenuItem
          disabled={selected.length === 0}
          onClick={handleCompleteOrCancelBulkClick.bind(
            this,
            TaskStatus.COMPLETE,
          )}
        >
          <div className={classes.center}>
            <CheckIcon />
            <span>Complete</span>
          </div>
        </MenuItem>
        <MenuItem
          disabled={selected.length === 0}
          onClick={() => setShowReassign(true)}
        >
          <div className={classes.center}>
            <PersonIcon />
            <span>Reassign</span>
          </div>
        </MenuItem>
        <MenuItem
          disabled={selected.length === 0}
          onClick={handleCompleteOrCancelBulkClick.bind(
            this,
            TaskStatus.CANCELLED,
          )}
        >
          <div className={classes.center}>
            <CloseIcon />
            <span>Cancel</span>
          </div>
        </MenuItem>
      </Menu>
      <Dialog open={showReassign} onClose={handleClose}>
        <DialogTitle>Reassign Task currently assigned to: </DialogTitle>
        <form
          onSubmit={handleSubmit(onSubmit)}
          onClick={event => event.stopPropagation()}
          onFocus={event => event.stopPropagation()}
        >
          <DialogContent>
            <UsernameAutocomplete control={control} dealer_id={dealerId} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" variant="outlined">
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              style={theme.actions.confirm}
            >
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default TasksBulkAction;
