import React from 'react';

import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';

const UsedVehicleToolbar = ({ numSelected, onSelectAllFiltered, total }) => {
  return (
    <>
      {numSelected === 1 && (
        <Alert style={{ width: '500px' }} severity="warning">
          {numSelected} offer selected.
        </Alert>
      )}
      {numSelected > 1 && (
        <Alert severity="warning">
          {numSelected} offers selected.{' '}
          {total !== numSelected && (
            <Button color="primary" onClick={onSelectAllFiltered}>
              Select all {total} from your filtered results
            </Button>
          )}
        </Alert>
      )}
    </>
  );
};

export default UsedVehicleToolbar;
