export const ISO_DAYS_OF_WEEK = [
  { name: 'Monday', value: 1 },
  { name: 'Tuesday', value: 2 },
  { name: 'Wednesday', value: 3 },
  { name: 'Thursday', value: 4 },
  { name: 'Friday', value: 5 },
  { name: 'Saturday', value: 6 },
  { name: 'Sunday', value: 7 },
];

export const WEEKEND_DAYS = ['Saturday', 'Sunday'];

export const outOfProvinceWarning = (myProvince, theirProvince) =>
  `You are attempting to place a bid on a vehicle from ${theirProvince}, but your currently selected dealer is in ${myProvince}.  Are you sure you want to place the bid?`;
