import React from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Typography from '@mui/material/Typography';

import { getMediumDateTimeWithWeekday, withSuffix } from 'utils';
import { useUserContext } from 'components/MaterialUI/UserContext';

const SyndicationDesktop = ({ imports, exports }) => {
  const { me } = useUserContext();

  return (
    <>
      <Typography style={{ padding: '1rem' }} variant="h5">
        Imports
      </Typography>
      <Typography style={{ padding: '0 0 1rem 1rem' }} variant="p">
        Your vehicles are being imported from the following sources:
      </Typography>
      <div style={{ paddingBottom: '3rem' }}>
        <Table style={{ width: '100%' }}>
          <TableHead>
            <TableRow>
              <TableCell>Import Name</TableCell>
              <TableCell align="center">New Vehicles</TableCell>
              <TableCell align="center">Used Vehicles</TableCell>
              <TableCell align="center">Frequency</TableCell>
              <TableCell align="center">Updated Count</TableCell>
              <TableCell align="center">Last Import Ran:</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {imports.map(row => (
              <TableRow key={row.name}>
                <TableCell>{row.name}</TableCell>
                <TableCell align="center">
                  {row.new ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                </TableCell>
                <TableCell align="center">
                  {row.used ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                </TableCell>
                <TableCell align="center">{row.frequency}</TableCell>
                <TableCell align="center">{row.updated_count}</TableCell>
                <TableCell align="center">
                  {getMediumDateTimeWithWeekday(
                    withSuffix(row.date_ended_at, 'Z'),
                    me?.goUserProfile?.settings?.timezone,
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <div style={{ paddingBottom: '2rem' }}>
        <Typography style={{ padding: '1rem' }} variant="h5">
          Exports
        </Typography>
        <Typography style={{ padding: '0 0 1rem 1rem' }} variant="p">
          Your vehicles are being exported to the following destinations:
        </Typography>
        <Table style={{ width: '100%' }}>
          <TableHead>
            <TableRow>
              <TableCell>Export Name</TableCell>
              <TableCell align="center">New Vehicles</TableCell>
              <TableCell align="center">Used Vehicles</TableCell>
              <TableCell align="center">Frequency</TableCell>
              <TableCell align="center">Last Export Ran:</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {exports.map(row => (
              <TableRow key={row.snakeName}>
                <TableCell>{row.name}</TableCell>
                <TableCell align="center">
                  {row.new ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                </TableCell>
                <TableCell align="center">
                  {row.used ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                </TableCell>
                <TableCell align="center">{row.frequency}</TableCell>
                <TableCell align="center">
                  {getMediumDateTimeWithWeekday(
                    withSuffix(row.date_ended_at, 'Z'),
                    me?.goUserProfile?.settings?.timezone,
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </>
  );
};

export default SyndicationDesktop;
