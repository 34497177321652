// External
import { useMutation, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { groupBy, omit } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

// Material UI
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';

import { makeStyles } from '@mui/styles';

// Internal
import CheckboxControl from 'components/MaterialUI/CheckboxControl';
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import DealerPicker from 'components/MaterialUI/DealerPicker';
import Loading from 'components/MaterialUI/Loading';
import RadioControl from 'components/MaterialUI/RadioControl2';
import RenderableRadioGroup from 'components/MaterialUI/RenderableRadioGroup';
import SelectControl from 'components/MaterialUI/SelectControl2';
import TextFieldControl from 'components/MaterialUI/TextFieldControl';

import { titleize } from 'utils';

import { format_seconds } from '../utils.js';
import LeadConfigAdfEmail from './LeadConfigAdfEmail.jsx';
import { Role } from 'constants.js';

const GET_LEADS_CONFIG = gql`
  query getLeadsConfig($dealerId: Int!) {
    leadsConfigOptions(dealer_id: $dealerId) {
      lead_types
      distribution_types
    }
  }
`;

const GET_CONFIG_RULE = gql`
  query getLeadConfigRule($dealerId: Int!, $ruleId: ID!) {
    leadsConfigRule(dealer_id: $dealerId, rule_id: $ruleId) {
      _id
      distribution_type
      lead_types
      form_names
      sources
      usernames
      users {
        display_name
      }
      rapid_response_timeout
      prefer_assigned_sales_rep
      is_in_purchase_timeline
      is_out_purchase_timeline
      forwarding {
        email
        message_format
      }
    }
  }
`;

const GET_USERS = gql`
  query getUsers($dealerId: Int!) {
    users(
      dealer_ids: [$dealerId]
      roles: [
        "${Role.GENERAL_MANAGER}"
        "${Role.FINANCE_DIRECTOR}"
        "${Role.FINANCE_MANAGER}"
        "${Role.INTERNET_SALES_REP}"
        "${Role.ORGANIZATION_ADMIN}"
        "${Role.SALES_MANAGER}"
        "${Role.SALES_REP}"
        "${Role.SERVICE_ADVISOR}"
        "${Role.SERVICE_MANAGER}"
        "${Role.PARTS}"
      ]
      status: active
    ) {
      display_name
      username
      role
    }
  }
`;

const UPDATE_CONFIG_RULE = gql`
  mutation updateLeadsConfigRule(
    $dealerId: Int!
    $ruleId: ID!
    $input: ConfigRule
  ) {
    updateLeadsConfigRule(
      dealer_id: $dealerId
      rule_id: $ruleId
      input: $input
    ) {
      dealer_id
      distribution_rules {
        _id
        distribution_type
        lead_types
        form_names
        sources
        usernames
        users {
          display_name
        }
        rapid_response_timeout
        prefer_assigned_sales_rep
        is_in_purchase_timeline
        is_out_purchase_timeline
        forwarding {
          email
          message_format
        }
      }
    }
  }
`;

const ADD_CONFIG_RULE = gql`
  mutation addLeadsConfigRule($dealerId: Int!, $input: ConfigRule) {
    addLeadsConfigRule(dealer_id: $dealerId, input: $input) {
      dealer_id
      distribution_rules {
        _id
        distribution_type
        lead_types
        form_names
        sources
        usernames
        users {
          display_name
        }
        rapid_response_timeout
        prefer_assigned_sales_rep
        is_in_purchase_timeline
        is_out_purchase_timeline
        forwarding {
          email
          message_format
        }
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  form: {
    '& h5': {
      marginTop: '.5rem',
    },
  },
}));

const rapidResponseTimeoutOptions = [
  { value: 0, name: "Don't redistribute" },
  ...[900, 1800, 3600, 28800, 86400].map(value => ({
    name: format_seconds(value),
    value,
  })),
];

const FormatRadioGroup = props => (
  <RenderableRadioGroup
    {...props}
    options={[
      { name: 'ADF', value: 'adf' },
      { name: 'HTML', value: 'html' },
    ]}
  />
);

const LeadConfigRule = ({ ruleId }) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const theme = useTheme();

  const { dealerId } = useDealerContext();

  const history = useHistory();

  const { data, loading: configOptionsLoading } = useQuery(GET_LEADS_CONFIG, {
    variables: {
      dealerId: dealerId,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const leadsConfigOptions = data?.leadsConfigOptions || {};
  const leadTypes = omit(leadsConfigOptions?.lead_types, '*');
  const leadTypeOptions = useMemo(
    () =>
      Object.entries(leadTypes).map(([value, name]) => ({
        name,
        value,
      })),
    [leadTypes],
  );
  const distributionTypes = leadsConfigOptions?.distribution_types ?? {};
  const distributionTypeOptions = useMemo(
    () =>
      Object.entries(distributionTypes).map(([value, name]) => ({
        name,
        value,
      })),
    [distributionTypes],
  );

  const { data: configRule, loading: configRulesLoading } = useQuery(
    GET_CONFIG_RULE,
    {
      variables: {
        dealerId,
        ruleId,
      },
      skip: !ruleId,
    },
  );
  const rule = configRule?.leadsConfigRule || null;

  const { data: users } = useQuery(GET_USERS, {
    variables: {
      dealerId,
    },
  });
  const groupedUsers = groupBy(users?.users ?? [], 'role');

  const { control, handleSubmit, reset, setValue } = useForm({
    shouldUnregister: true,
  });

  useEffect(() => {
    if (configOptionsLoading || configRulesLoading) return;
    const isCatchAll = rule?.lead_types?.includes('*');
    if (rule) reset({ catch_all: isCatchAll, ...rule });
    else
      reset({
        catch_all: false,
        lead_types: [],
        sources: [],
        form_names: [],
        distribution_type: distributionTypeOptions?.[0]?.value,
        rapid_response_timeout: rapidResponseTimeoutOptions[0].value,
        forwarding: [],
        usernames: [],
      });
  }, [
    reset,
    rule,
    distributionTypeOptions,
    configOptionsLoading,
    configRulesLoading,
  ]);

  const catchAll = useWatch({ control, name: 'catch_all' });
  const leadTypesData = useWatch({
    control,
    name: 'lead_types',
    defaultValue: [],
  });
  const usernames = useWatch({ control, name: 'usernames' });

  useEffect(() => {
    if (configOptionsLoading || configRulesLoading) return;
    // Keep the other lead types selected in case they change their minds about catch-all
    // Just strip out the other lead types on submit if '*' is included.
    if (catchAll && !leadTypesData?.includes('*'))
      setValue('lead_types', [...leadTypesData, '*']);
    else if (!catchAll && leadTypesData?.includes('*'))
      setValue('lead_types', leadTypesData.filter(x => x !== '*') ?? []);
  }, [
    catchAll,
    leadTypesData,
    setValue,
    configOptionsLoading,
    configRulesLoading,
  ]);

  const selectAllRoleHandler = role => () =>
    setValue(
      'usernames',
      Array.from(
        new Set([...usernames, ...groupedUsers[role].map(x => x.username)]),
      ),
    );

  const unselectAllRoleHandler = role => () =>
    setValue(
      'usernames',
      usernames.filter(
        x => !groupedUsers[role].map(y => y.username).includes(x),
      ),
    );

  const [updateRule] = useMutation(UPDATE_CONFIG_RULE, {
    onCompleted: () => {
      enqueueSnackbar(`Leads distribution rule updated successfully`, {
        variant: 'success',
      });
      history.push('/leads/config');
    },
    onError: () =>
      enqueueSnackbar(`Error when updating leads distribution rule`, {
        variant: 'error',
      }),
  });

  const [addRule] = useMutation(ADD_CONFIG_RULE, {
    onCompleted: () => {
      enqueueSnackbar(`Leads distribution rule added successfully`, {
        variant: 'success',
      });
      history.push('/leads/config');
    },
    onError: () =>
      enqueueSnackbar(`Error when adding leads distribution rule`, {
        variant: 'error',
      }),
  });

  const onSubmit = ({
    __typename,
    catch_all,
    lead_types,
    forwarding,
    ...data
  }) => {
    const input = {
      ...data,
      forwarding: forwarding
        .filter(f => f.email.includes('@'))
        .map(f => ({ ...f, email: f.email.trim() })),
      lead_types: lead_types?.includes('*') ? ['*'] : lead_types,
    };
    if (rule) updateRule({ variables: { dealerId, ruleId, input } });
    else addRule({ variables: { dealerId, input } });
  };

  return (
    <Box m={2}>
      <Box>
        <DealerPicker disabled={Boolean(ruleId)} />
      </Box>
      {(configOptionsLoading || configRulesLoading) && <Loading />}
      {!configOptionsLoading && !configRulesLoading && (
        <Box>
          <LeadConfigAdfEmail />
          <hr />
          <Typography
            style={{
              fontSize: '1.5rem',
              fontWeight: '700',
              marginTop: '.5rem',
            }}
          >
            {rule ? 'Edit' : 'Add'} lead distribution rule
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
            <CheckboxControl
              name="catch_all"
              control={control}
              label="Make a catch-all rule"
            />

            {!catchAll && (
              <Box>
                <h5>Apply this rule to the following lead types</h5>
                <Controller
                  control={control}
                  name="lead_types"
                  render={({ field: { onChange, value } }) => (
                    <Box display="flex" flexWrap="wrap">
                      {leadTypeOptions.map(({ name: _name, value: _value }) => (
                        <Box key={_value} style={{ width: '200px' }}>
                          <FormControlLabel
                            control={<Checkbox size="small" />}
                            checked={Boolean(value?.includes(_value))}
                            label={_name}
                            onChange={e =>
                              onChange(
                                e.target.checked
                                  ? value?.concat(_value)
                                  : value?.filter(x => x !== _value),
                              )
                            }
                          />
                        </Box>
                      ))}
                    </Box>
                  )}
                />
                <h5>Additional requirements</h5>
                <Grid
                  container
                  direction="row"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    md={3}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      paddingRight: '.5rem',
                    }}
                  >
                    <label>Form names:</label>
                    <Controller
                      control={control}
                      name="form_names"
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          placeholder="Your rule will currently match any form name. To restrict this rule to specific forms, enter the form names below. One per line."
                          value={value?.join('\n')}
                          onChange={e =>
                            onChange(e.target.value?.split('\n') ?? [])
                          }
                          multiline
                          minRows={7}
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={3}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      paddingRight: '.5rem',
                    }}
                  >
                    <label>Lead sources:</label>
                    <Controller
                      control={control}
                      name="sources"
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          placeholder="Your rule will currently match any source. To restrict this rule to specific sources, enter them below. One per line."
                          value={value?.join('\n')}
                          onChange={e =>
                            onChange(e.target.value?.split('\n') ?? [])
                          }
                          multiline
                          minRows={7}
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <label>Purchase Timeline:</label>
                    <p>
                      Select the purchase timeline the rule should apply to. To
                      apply the rule when no purchase timeline was specified do
                      not select either option.
                    </p>
                    <CheckboxControl
                      CheckboxProps={{ size: 'small' }}
                      control={control}
                      name="is_in_purchase_timeline"
                      label="Under 1 month"
                    />
                    <CheckboxControl
                      CheckboxProps={{ size: 'small' }}
                      control={control}
                      name="is_out_purchase_timeline"
                      label="Over 1 month"
                    />
                  </Grid>
                </Grid>
              </Box>
            )}

            <h5>Distribute the lead using</h5>
            <SelectControl
              control={control}
              name="distribution_type"
              options={Object.entries(distributionTypes).map(
                ([value, name]) => ({
                  value,
                  name,
                }),
              )}
              noNull
              style={{ minHeight: '1.5rem' }}
            />

            <h5>Prefer assigned sales rep</h5>
            <CheckboxControl
              CheckboxProps={{ size: 'small' }}
              control={control}
              name="prefer_assigned_sales_rep"
              label="Auto assign to the customer's sales rep if one exists"
            />

            <h5>Time limit to respond</h5>
            <label>
              Redistribute the lead if the assignee does not respond within:
            </label>
            <br />
            <SelectControl
              control={control}
              options={rapidResponseTimeoutOptions}
              name="rapid_response_timeout"
              noNull
              variant="outlined"
              size="small"
            />
            <h5>Distribute leads to the selected users:</h5>
            <Grid
              container
              spacing={2}
              direction="row"
              style={{
                display: 'flex',
                flexDirection: 'row',
                rowGap: '30px',
                paddingBottom: theme.spacing(3),
              }}
            >
              <Controller
                control={control}
                defaultValue={[]}
                name="usernames"
                render={({ field: { onChange, value } }) =>
                  Object.entries(groupedUsers).map(([role, users]) => (
                    <Grid
                      item
                      key={role}
                      xs={6}
                      md={3}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        paddingRight: '.5rem',
                      }}
                    >
                      <Box
                        display="flex"
                        fontWeight="700"
                        style={{ textTransform: 'capitalize' }}
                        justifyContent="space-between"
                      >
                        {titleize(role)}
                        <Box>
                          <IconButton
                            onClick={selectAllRoleHandler(role)}
                            size="small"
                          >
                            <Icon
                              className="fa fa-check-circle"
                              style={{ width: 'auto', fontSize: '16px' }}
                            />
                          </IconButton>
                          <IconButton
                            onClick={unselectAllRoleHandler(role)}
                            size="small"
                          >
                            <Icon
                              className="fa fa-times-circle"
                              style={{ width: 'auto', fontSize: '16px' }}
                            />
                          </IconButton>
                        </Box>
                      </Box>
                      <TextField
                        // using native select since the MUI multiline select looks stupid
                        SelectProps={{
                          multiple: true,
                          native: true,
                          variant: 'outlined',
                        }}
                        select
                        style={{ height: '100px' }}
                        value={value.filter(x =>
                          users.map(x => x.username).includes(x),
                        )}
                        onClick={e =>
                          onChange(
                            value.includes(e.target.value)
                              ? value.filter(x => x !== e.target.value)
                              : [...value, e.target.value],
                          )
                        }
                      >
                        {users.map(({ username, display_name }) => (
                          <option key={username} value={username}>
                            {display_name}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                  ))
                }
              />
            </Grid>

            <h5>
              Forward a copy of the lead to the following email addresses:
            </h5>
            <TextFieldControl
              control={control}
              label="Email Address"
              name="forwarding.0.email"
            />
            <RadioControl
              control={control}
              name="forwarding.0.message_format"
              label="Format"
              as={<FormatRadioGroup />}
              defaultValue="adf"
            />
            <br />
            <TextFieldControl
              control={control}
              label="Email Address"
              name="forwarding.1.email"
            />
            <RadioControl
              control={control}
              name="forwarding.1.message_format"
              label="Format"
              as={<FormatRadioGroup />}
              defaultValue="adf"
            />
            <br />
            <TextFieldControl
              control={control}
              label="Email Address"
              name="forwarding.2.email"
            />
            <RadioControl
              control={control}
              name="forwarding.2.message_format"
              label="Format"
              as={<FormatRadioGroup />}
              defaultValue="adf"
            />

            <br />
            <Button
              type="submit"
              color="primary"
              variant="contained"
              style={{ margin: '1rem 0', ...theme.actions.confirm }}
            >
              Save
            </Button>
            <Button
              onClick={() => history.push('/leads/config')}
              style={{ margin: '1rem 0 1rem 1rem' }}
            >
              Cancel
            </Button>
          </form>
        </Box>
      )}
    </Box>
  );
};

export default LeadConfigRule;
