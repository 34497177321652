import React from 'react';

/* external */
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

/* Material UI */
import FavouriteIcon from '@mui/icons-material/Favorite';
import FavouriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';

const SET_FAVOURITE = gql`
  mutation favouriteItem($id: Int!, $isFavourite: Boolean!) {
    auctions {
      updateAuctionItem(id: $id, data: { isFavourite: $isFavourite }) {
        id
        isFavourite
      }
    }
  }
`;

const ItemFavouriteCell = ({ item }) => {
  const { id, isFavourite } = item;

  const [setFavourite] = useMutation(SET_FAVOURITE);

  const handleFavourite = e => {
    e.stopPropagation();
    e.preventDefault();
    setFavourite({ variables: { id, isFavourite: !isFavourite } });
  };

  return (
    <TableCell>
      <IconButton onClick={handleFavourite} size="large">
        {isFavourite ? (
          <FavouriteIcon style={{ color: '#F54978' }} />
        ) : (
          <FavouriteBorderIcon />
        )}
      </IconButton>
    </TableCell>
  );
};

ItemFavouriteCell.fragments = {
  auctionItem: gql`
    fragment ItemFavouriteCellAuctionItem on AuctionItem {
      id
      isFavourite
    }
  `,
};
export default ItemFavouriteCell;
