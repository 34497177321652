import React from 'react';

import Box from '@mui/material/Box';
import { Switch, Route } from 'react-router-dom';

import app from '../../app';

/* Material UI */
import AppointmentTable from './AppointmentTable';
import ServiceAppointment from './ServiceAppointment';

const Appointments = props => {
  const { match } = props;
  return (
    <app.components.Layout title="Appointments">
      <Switch>
        <Route exact path={`${match.url}/`}>
          <Box m={1}>
            <AppointmentTable />
          </Box>
        </Route>
        <Route exact path={`${match.url}/delivery`}>
          <Box m={1}>
            <AppointmentTable type="Delivery" />
          </Box>
        </Route>
        <Route exact path={`${match.url}/service`}>
          <Box m={1}>
            <ServiceAppointment />
          </Box>
        </Route>
      </Switch>
    </app.components.Layout>
  );
};

export default Appointments;
