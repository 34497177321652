import React from 'react';
import Carousel from 'react-material-ui-carousel';
import PropTypes from 'prop-types';
import { formatPhoto } from '../../utils';
import { styled } from '@mui/material';

const StyledImage = styled('img')(({ mobile, width }) => ({
  width: mobile ? width : 'auto',
  height: '250px',
}));

const propTypes = {
  photos: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      cloudinary_public_id: PropTypes.string,
    }),
  ),
  width: PropTypes.string,
  height: PropTypes.string,
  mobile: PropTypes.bool,
};

const WizardImageCarousel = ({ photos, width, height, mobile }) => {
  return (
    <>
      {photos.length > 0 && (
        <Carousel
          stopAutoPlayOnHover={true}
          sx={{ width: mobile ? width : 'auto' }}
        >
          {photos.map(photo => (
            <StyledImage
              alt={photo.id}
              key={photo.id}
              src={formatPhoto(photo)}
              mobile={mobile}
              width={width}
            />
          ))}
        </Carousel>
      )}
      {photos.length === 0 && (
        <StyledImage
          alt="Vehicle"
          src={formatPhoto(null)}
          mobile={mobile}
          width={width}
        />
      )}
    </>
  );
};

WizardImageCarousel.propTypes = propTypes;

export default WizardImageCarousel;
