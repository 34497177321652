import React, { useState } from 'react';
import gql from 'graphql-tag';

import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';

import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { Editor } from '@tinymce/tinymce-react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import { DatePicker } from '@mui/x-date-pickers';

import { processMoment } from 'utils';
import { tinyMCEApiUrl } from '../../../../constants';
import { useVehiclesQueryContext } from '../contexts/VehiclesQueryContext';
import ErrorDisplay from 'components/MaterialUI/ErrorDisplay';

const UPDATE_PROMOTIONAL_INFO = gql`
  mutation updatePromotionalInfo(
    $filters: [QueryFilter!]!
    $promotionalExpiryDate: String
    $promotionalInfo: String!
    $promotionalPosition: String!
    $promotionalStartDate: String
  ) {
    inventory {
      updateBulkVehicles(
        filters: $filters
        data: {
          promotional_expiry_date: $promotionalExpiryDate
          promotional_info: $promotionalInfo
          promotional_position: $promotionalPosition
          promotional_start_date: $promotionalStartDate
        }
      ) {
        id
        date_user_modified
        promotional_expiry_date
        promotional_info
        promotional_position
        promotional_start_date
      }
    }
  }
`;

const BulkVehiclePromotional = ({ ids, open, onClose, selectByFilter }) => {
  const { filters: vehicleFilters, refetch } = useVehiclesQueryContext();
  const { enqueueSnackbar } = useSnackbar();

  const [promotionalInfo, setPromotionalInfo] = useState();
  const [promotionalPosition, setPromotionalPosition] = useState('above');
  const [promotionalStartDate, setPromotionalStartDate] = useState();
  const [promotionalExpiryDate, setPromotionalExpiryDate] = useState();

  const [update, updateStatus] = useMutation(UPDATE_PROMOTIONAL_INFO, {
    onCompleted: data => {
      // This _should_ refetch vehicles with the current sorting/filtering.
      refetch({ variables: { page: 1 } });
      // Refetching since the date_user_modified has changed, and the user
      // _could_ be sorting by "Updated".  Maybe a more sophisticated update
      // could happen here?
      onClose();
      enqueueSnackbar(
        `Successfully updated ${data.inventory.updateBulkVehicles.length} vehicles' promotional information.`,
      );
    },
    onError: () => null,
  });

  const filters = selectByFilter
    ? vehicleFilters
    : [{ model: 'Vehicle', field: 'id', op: 'in', value: ids }];

  const handleSubmit = () =>
    update({
      variables: {
        filters,
        promotionalInfo,
        promotionalPosition,
        promotionalStartDate: processMoment(promotionalStartDate),
        promotionalExpiryDate: processMoment(promotionalExpiryDate),
      },
    });
  const handleEditorChange = x => setPromotionalInfo(x);
  const handlePositionChange = e => setPromotionalPosition(e.target.value);
  const handleStartDateChange = x => setPromotionalStartDate(x ?? undefined);
  const handleExpiryDateChange = x => setPromotionalExpiryDate(x ?? undefined);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Set Bulk Vehicle Promotional Information</DialogTitle>
      <DialogContent>
        {updateStatus.error && (
          <Alert severity="warning">
            <ErrorDisplay
              error={updateStatus.error}
              action="Updating Vehicles' Promotional Info"
            />
          </Alert>
        )}
        <Editor
          apiKey={tinyMCEApiUrl}
          onEditorChange={handleEditorChange}
          plugins="wordcount"
        />
        <Grid
          container
          style={{
            paddingTop: '1rem',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Grid item>
            <RadioGroup
              value={promotionalPosition}
              onChange={handlePositionChange}
            >
              <FormControlLabel
                control={<Radio />}
                value={'above'}
                label="Display Above Description"
              />
              <FormControlLabel
                control={<Radio />}
                value={'below'}
                label="Display Below Description"
              />
            </RadioGroup>
          </Grid>
          <Grid
            item
            style={{
              textAlign: 'right',
            }}
          >
            <div
              style={{
                display: 'flex',
                marginTop: 'auto',
                marginBottom: 'auto',
              }}
            >
              <DatePicker
                margin="normal"
                label="Display From"
                format="YYYY-MM-DD"
                onChange={handleStartDateChange}
                style={{ width: '175px', marginTop: 0 }}
                value={promotionalStartDate}
              />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'right',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'right',
                  marginRight: 0,
                  marginLeft: 'auto',
                }}
              >
                <DatePicker
                  margin="normal"
                  label="Display To"
                  format="YYYY-MM-DD"
                  onChange={handleExpiryDateChange}
                  style={{ width: '175px', marginTop: 0 }}
                  value={promotionalExpiryDate}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        {updateStatus.loading ? (
          <CircularProgress
            size={20}
            style={{ marginLeft: '40px', marginRight: '30px' }}
          />
        ) : (
          <Button
            autoFocus
            disabled={!promotionalInfo}
            onClick={handleSubmit}
            variant="contained"
            style={{
              backgroundColor: `${!promotionalInfo ? '#bfbebe' : '#74B72E'}`,
            }}
          >
            Submit
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default BulkVehiclePromotional;
