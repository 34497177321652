/* External */
import React, { useState, useEffect } from 'react';

/* Material UI */
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

/* Internal */

const useStyles = makeStyles(theme => ({
  h6: {
    fontSize: '1.125rem',
    fontWeight: 'bold',
    marginBottom: '5px',
  },
  previewButton: {
    color: theme.colours.add,
  },
}));

const BulkSendStep2 = ({
  placeholderData,
  rowData,
  setPreviewMessage,
  message,
  setMessage,
}) => {
  const classes = useStyles();

  const [placeholder, setPlaceholder] = useState('');
  const [previewStatus, setPreviewStatus] = useState(false);

  const handleClick = e => {
    const value = `{${e.target.value}}`;
    setPlaceholder(e.target.value);
    setMessage(message + value);
  };

  // split the first row of the CSV file into an array of values, treating quoted values as a single value
  const row = rowData[0]
    ?.match(/(".*?"|[^",]+)(?=\s*,|\s*$)/g)
    ?.map(item => item.replace(/^"|"$/g, ''));

  let previewMsg = message;
  placeholderData.forEach((placeholder, index) => {
    if (row) {
      previewMsg = previewMsg.replace(`{${placeholder}}`, row[index]);
    }
  });

  useEffect(() => {
    setPreviewMessage(previewMsg);
  });

  return (
    <>
      <Grid item xs={12} style={{ marginBottom: '15px' }}>
        <Typography className={classes.h6}>Message Template</Typography>
        <Typography>
          Create your message template below using the dropdown to add
          placeholders from the columns in your CSV file.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControl variant="outlined" style={{ width: '50%' }}>
          <InputLabel id="placeholders">Placeholders</InputLabel>
          <Select
            id="placeholders"
            label="Placeholders"
            value={placeholder}
            variant="outlined"
            onChange={e => handleClick(e)}
            disabled={previewStatus}
          >
            {placeholderData.map(placeholder => (
              <MenuItem value={placeholder} key={placeholder}>
                {placeholder}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} style={{ marginTop: '15px' }}>
        <TextField
          label="Message Preview"
          value={previewStatus ? previewMsg : message}
          onChange={e => {
            setMessage(e.target.value);
          }}
          multiline
          minRows={5}
          maxRows={5}
          style={{ width: '100%', padding: '5px' }}
          disabled={previewStatus}
        />
      </Grid>
      <Grid item>
        <Button
          className={classes.previewButton}
          onClick={() => setPreviewStatus(previewStatus => !previewStatus)}
        >
          {previewStatus ? 'Edit' : 'Preview'}
        </Button>
      </Grid>
    </>
  );
};

export default BulkSendStep2;
