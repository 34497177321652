export const defaultTZ = Intl.DateTimeFormat().resolvedOptions().timeZone;

/* ****** FYI LINUX DEVS ******
  there is an issue with the formatting on Chrome in Linux
    > getFullDateTime is not formatting the date as expected
    > should be: Friday, August 11, 2023 at 4:26 p.m.
    > but is returning: Friday, 11 August 2023 at 16:25
  It works as it should in Firefox, and on Chrome in Windows (not tested on Mac)
*/

/**
 * Wrapper function to try to create string from value and options.
 *
 * @param {string|number|Date} value
 * @param {Intl.DateTimeFormatOptions} options
 * @returns {string} converted value or empty string if `value` is empty/null/undefined
 */
function dateTimeWrapper(value, options) {
  if (!value && value !== 0) {
    return '';
  }

  const dtf = new Intl.DateTimeFormat(undefined, options);

  return dtf.format(new Date(value));
}

/**
 * Returns a string with full date and short time. Output depends on locale.
 * Example output: Friday, August 11, 2023 at 11:00 a.m.
 *
 * @param {string|number|Date} value
 * @param {string} [tz=defaultTZ] - The timezone to convert `value` to.
 * @returns {string} converted value or empty string if `value` is empty/null/undefined
 */
export const getFullDateTime = (value, tz = defaultTZ) =>
  dateTimeWrapper(value, {
    // Equivalent to dateStyle=full
    weekday: 'long',
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    // Equivalent to timeStyle=short
    hour: 'numeric',
    minute: 'numeric',
    timeZone: tz || defaultTZ,
  });

/**
 * Returns a string with weekday, medium date and short time. Output depends on locale.
 * Example output: Fri, Aug 11, 2023, 11:00 a.m.
 *
 * @param {string|number|Date} value
 * @param {string} [tz=defaultTZ] - The timezone to convert `value` to.
 * @returns {string} converted value or empty string if `value` is empty/null/undefined
 */
export const getMediumDateTimeWithWeekday = (value, tz = defaultTZ) =>
  dateTimeWrapper(value, {
    weekday: 'short',
    // Equivalent to dateStyle=medium
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    // Equivalent to timeStyle=short
    hour: 'numeric',
    minute: 'numeric',
    timeZone: tz || defaultTZ,
  });

/**
 * Returns a string with medium date and short time. Output depends on locale.
 * Example output: Aug 11, 2023, 11:00 a.m.
 *
 * @param {string|number|Date} value
 * @param {string} [tz=defaultTZ] - The timezone to convert `value` to.
 * @returns {string} converted value or empty string if `value` is empty/null/undefined
 */
export const getMediumDateTime = (value, tz = defaultTZ) =>
  dateTimeWrapper(value, {
    // Equivalent to dateStyle=medium
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    // Equivalent to timeStyle=short
    hour: 'numeric',
    minute: 'numeric',
    timeZone: tz || defaultTZ,
  });

/**
 * Returns a string with medium date. Output depends on locale.
 * Example output: Aug 11, 2023
 *
 * @param {string|number|Date} value
 * @param {string} [tz=defaultTZ] - The timezone to convert `value` to.
 * @returns {string} converted value or empty string if `value` is empty/null/undefined
 */
export const getMediumDate = (value, tz = defaultTZ) =>
  dateTimeWrapper(value, {
    // Equivalent to dateStyle=medium
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    timeZone: tz || defaultTZ,
  });
