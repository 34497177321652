import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import { NetworkStatus } from 'apollo-boost';

/* external */
import gql from 'graphql-tag';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';

import { useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

/* Material UI */
import AddIcon from '@mui/icons-material/Add';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DeleteIcon from '@mui/icons-material/Delete';

import ConfirmDialog from 'components/MaterialUI/ConfirmDialog';
import { useUserContext } from 'components/MaterialUI/UserContext';

/* internal */
import OpportunityAttachmentModal from './OpportunityAttachmentsModal';
import { RoleGroup } from 'constants.js';
import { defaultTZ } from 'utils';

const useStyles = makeStyles(theme => ({
  addIcon: {
    padding: '0px',
    margin: '0px 5px 0px 10px',
  },
  filterButton: {
    padding: '0px',
    margin: '0px 10px 0px 5px',
  },
  paper: {
    padding: '10px 20px',
    margin: '10px',
    minHeight: '200px',
  },
  table: {
    padding: '10px',
  },
}));

const GET_ATTACHMENTS = gql`
  query getOpportunityAttachments($_id: ID!) {
    opportunity(_id: $_id) {
      _id
      customer_id
      attachments {
        _id
        attachment_type
        key
        label
        created_by
        created_by_name
        file_hash
        content_type
        date_created
        deleted
        file_tag
      }
    }
  }
`;

const GET_ATTACHMENT_URL = gql`
  query getUrl($key: String!, $_id: ID!) {
    getAttachmentUrl(key: $key, _id: $_id) {
      url
    }
  }
`;

const REMOVE_ATTACHMENT_MUTATION = gql`
  mutation removeAttachment($_id: ID!, $attachmentID: ID!) {
    removeAttachment(_id: $_id, attachmentID: $attachmentID) {
      attachments {
        _id
      }
    }
  }
`;

const OpportunityAttachments = ({ opportunity }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { currentUser } = useUserContext();
  const { enqueueSnackbar } = useSnackbar();
  const desktop = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const [openModal, setOpenModal] = useState(false);
  const timezone = currentUser?.goUserProfile?.settings?.timezone || defaultTZ;
  const [removeAttachment] = useMutation(REMOVE_ATTACHMENT_MUTATION);
  const [getAttachmentUrl, attachmentUrlQuery] =
    useLazyQuery(GET_ATTACHMENT_URL);
  const { data, loading, refetch, networkStatus } = useQuery(GET_ATTACHMENTS, {
    variables: {
      _id: opportunity._id,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (attachmentUrlQuery.data) {
      const url = attachmentUrlQuery.data.getAttachmentUrl.url;
      window.open(url);
    }
  }, [attachmentUrlQuery]);

  const [deleteAttachment, setDeleteAttachment] = useState(null);

  if ((loading && !data) || networkStatus === NetworkStatus.refetch) {
    return (
      <Box textAlign="center" mt={4}>
        <CircularProgress />
        <Typography variant="subtitle1" gutterBottom>
          Loading
        </Typography>
      </Box>
    );
  }

  const handleViewItemClick = (attachmentKey, createdBy) => {
    if (
      RoleGroup.VIEW_OPPORTUNITY_ATTACHMENTS.includes(currentUser.role) ||
      currentUser.username === createdBy
    ) {
      getAttachmentUrl({
        variables: {
          key: attachmentKey,
          _id: opportunity._id,
        },
        fetchPolicy: 'network-only',
      });
    } else {
      enqueueSnackbar(
        'You do not have access to view opportunity attachments',
        { variant: 'error' },
      );
    }
  };

  const handleDeleteClick = (attachmentId, createdBy) => {
    if (
      RoleGroup.DELETE_OPPORTUNITY_ATTACHMENTS.includes(currentUser.role) ||
      currentUser.username === createdBy
    ) {
      removeAttachment({
        variables: {
          _id: opportunity._id,
          attachmentID: attachmentId,
        },
      })
        .catch(e => {
          enqueueSnackbar(`An error has occurred: ${JSON.stringify(e)}`, {
            variant: 'error',
          });
        })
        .then(r => {
          enqueueSnackbar('Attachment removed!', { variant: 'success' });
          setDeleteAttachment(null);
          refetch();
        });
    } else {
      enqueueSnackbar(
        'You do not have access to delete opportunity attachments',
        { variant: 'error' },
      );
    }
  };

  const attachments = data?.opportunity?.attachments ?? [];

  return (
    <>
      <Dialog
        maxWidth="sm"
        open={openModal}
        onClose={() => setOpenModal(false)}
      >
        <OpportunityAttachmentModal
          handleClose={() => setOpenModal(false)}
          opportunityId={opportunity._id}
          dealNumber={opportunity?.dms_deal?.deal_number || 'N/A'}
          refetch={refetch}
        />
      </Dialog>
      <Paper className={classes.paper}>
        <Box display="flex">
          <h3>Attachments</h3>
          <IconButton
            className={classes.addIcon}
            color="primary"
            onClick={() => setOpenModal(true)}
            size="large"
          >
            <AddIcon style={theme.actions.add} />
          </IconButton>
        </Box>
        {!desktop &&
          attachments.length !== 0 &&
          attachments.map(
            attachment =>
              !attachment.deleted && (
                <Card key={attachment._id}>
                  <CardContent>
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid item xs={6}>
                        <Typography variant="body2">
                          {attachment.label}
                        </Typography>
                        <Typography variant="body2" style={{ color: 'gray' }}>
                          {attachment.file_tag}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} style={{ textAlign: 'end' }}>
                        <Typography variant="body2">
                          {moment(attachment.date_created)
                            .tz(timezone)
                            .format('ll')}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} style={{ textAlign: 'end' }}>
                        <Typography variant="body2">
                          Created by: {attachment.created_by_name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardActions style={{ placeContent: 'flex-end' }}>
                    <IconButton
                      onClick={() =>
                        handleViewItemClick(
                          attachment.key,
                          attachment.created_by,
                        )
                      }
                      size="large"
                    >
                      <CloudDownloadIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      onClick={() => setDeleteAttachment(attachment)}
                      size="large"
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </CardActions>
                </Card>
              ),
          )}
        {desktop && (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>File</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>By</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {attachments.length !== 0 &&
                  attachments.map(
                    attachment =>
                      !attachment.deleted && (
                        <TableRow key={attachment._id}>
                          <TableCell>
                            <Typography variant="body2">
                              {attachment.label}
                            </Typography>
                            <Typography
                              variant="body2"
                              style={{ color: 'gray' }}
                            >
                              {attachment.file_tag}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2">
                              {moment(attachment.date_created)
                                .tz(timezone)
                                .format('ll')}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2">
                              {attachment.created_by_name}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <IconButton
                              onClick={() =>
                                handleViewItemClick(
                                  attachment.key,
                                  attachment.created_by,
                                )
                              }
                              size="large"
                            >
                              <CloudDownloadIcon fontSize="small" />
                            </IconButton>
                            <IconButton
                              onClick={() => setDeleteAttachment(attachment)}
                              size="large"
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ),
                  )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <ConfirmDialog
          abortText="Cancel"
          confirmText="Delete"
          isOpen={Boolean(deleteAttachment)}
          onClose={() => setDeleteAttachment(null)}
          onConfirm={() =>
            handleDeleteClick(
              deleteAttachment?._id,
              deleteAttachment?.created_by,
            )
          }
          text={`Are you sure you want to delete ${deleteAttachment?.label}?`}
        />
        {attachments.length === 0 && (
          <Typography variant="body2">No Attachments Found</Typography>
        )}
      </Paper>
    </>
  );
};

export default OpportunityAttachments;
