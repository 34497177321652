import React from 'react';

import DashboardItem from './DashboardItem';
import { useHistory } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { Bar } from 'react-chartjs-2';
import 'chart.js';

const DECIMAL_PLACES = 1;

const PRICE_CHANGES_ORDER = ['7 Days', '14 Days', '21 Days', '28 Days'];

const LABEL_TO_FIELD_MAPPING = {
  '7 Days': 'noPriceChangesAtSevenDays',
  '14 Days': 'noPriceChangesAtFourteenDays',
  '21 Days': 'noPriceChangesAtTwentyOneDays',
  '28 Days': 'noPriceChangesAtTwentyEightDays',
};

const InventoryTab = ({
  tabTitle,
  inStockData,
  pricingData,
  vehicles,
  stockTypeFilter,
  inStockFilter,
}) => {
  const history = useHistory();

  const displayNumber = (number, total, asPercentage) =>
    asPercentage
      ? Number.isNaN(number / total)
        ? ' - '
        : `${((number / total) * 100).toFixed(DECIMAL_PLACES)}%`
      : `${number}`;

  const displayColor = (number, total, asPercentage) =>
    asPercentage
      ? Number.isNaN(number / total)
        ? 'black'
        : number / total > 0.95
        ? 'green'
        : number / total <= 0.8
        ? 'red'
        : 'orange'
      : 'black';

  const clickTileHandler = (destination, filters) => () => {
    if (filters)
      sessionStorage.setItem('npv_inventory.filters', JSON.stringify(filters));
    if (destination) history.push(destination);
  };

  return (
    <>
      <Box style={{ paddingLeft: '1rem', paddingBottom: '2rem' }}>
        <Typography variant="h4">
          <Box style={{ fontWeight: 'bold' }}>{tabTitle}</Box>
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {vehicles?.map(
          ({
            title,
            number,
            total,
            icon,
            asPercentage,
            destination,
            filters,
          }) => (
            <DashboardItem
              key={title}
              title={title}
              icon={icon}
              value={displayNumber(number, total, asPercentage)}
              subValue={asPercentage ? `(${number}/${total})` : 'Units'}
              color={displayColor(number, total, asPercentage)}
              onClick={clickTileHandler(destination, filters)}
            />
          ),
        )}
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        spacing={3}
        style={{ marginTop: '10px' }}
      >
        <Grid item md={6} xs={12}>
          <Bar
            data={{
              labels: inStockData.map(x => x.value),
              datasets: [
                {
                  backgroundColor: ({ dataIndex, dataset: { data } }) =>
                    data[dataIndex] === Math.max(...data)
                      ? 'blue'
                      : 'CornflowerBlue',
                  data: inStockData.map(x => x.count),
                },
              ],
            }}
            options={{
              legend: {
                display: false,
              },
              onClick: (e, elems) => {
                if (elems?.[0]?._model?.label)
                  clickTileHandler('/npv-inventory/inventory', [
                    stockTypeFilter,
                    inStockFilter,
                    {
                      model: 'Vehicle',
                      field: 'daysInStockCategory',
                      value: elems[0]._model.label,
                      options: {},
                    },
                  ])();
              },
              title: {
                display: true,
                text: 'Days In Stock',
              },
              scales: {
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                    },
                  },
                ],
              },
            }}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Bar
            data={{
              labels: PRICE_CHANGES_ORDER,
              datasets: [
                {
                  backgroundColor: ({ dataIndex, dataset: { data } }) =>
                    data[dataIndex] === Math.max(...data)
                      ? 'blue'
                      : 'CornflowerBlue',
                  data: pricingData,
                },
              ],
            }}
            options={{
              legend: {
                display: false,
              },
              onClick: (e, elems) => {
                if (elems?.[0]?._model?.label)
                  clickTileHandler('/npv-inventory/inventory', [
                    stockTypeFilter,
                    inStockFilter,
                    {
                      model: 'Vehicle',
                      field: LABEL_TO_FIELD_MAPPING[elems[0]._model.label],
                      value: true,
                      options: { label: elems[0]._model.label },
                    },
                  ])();
              },
              title: {
                display: true,
                text: 'Days Since Price Changes',
              },
              scales: {
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                    },
                  },
                ],
              },
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default InventoryTab;
