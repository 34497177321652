import { useLazyQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { groupBy, sortBy } from 'lodash';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';

import Loading from 'components/MaterialUI/Loading';

import { generateOptionCodes } from '../../utils';
import { usePermissionsContext } from '../contexts/PermissionsContext';

export const DECODE_OPTIONS = gql`
  query decodeOptions($currentStyleId: Int!, $filters: VehicleDecodeFilters) {
    inventory {
      decodeVehicleOptions(style_id: $currentStyleId, filters: $filters) {
        header
        option_value
        option_code
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  heading: {
    fontSize: '16px',
    fontWeight: 'bold',
    textDecorationLine: 'underline',
    color: 'black',
    textTransform: 'uppercase',
  },
  itemLabel: {
    fontSize: '10px',
    paddingTop: '5px',
  },
}));

const VehicleOptions = ({ vehicle }) => {
  const { setValue, formState, watch } = useFormContext();
  const { dirtyFields } = formState;
  const { update: canUpdate } = usePermissionsContext();
  const classes = useStyles();

  const currentOptions = watch('options', vehicle.options);
  const currentStyleId = watch('style_id', vehicle.style_id); // style_id indicates the vehicle
  // has been decoded (get from form value in case vehicle hasn't been saved yet)

  const [decodeOptions, decodeOptionsQuery] = useLazyQuery(DECODE_OPTIONS, {
    onCompleted: res => {
      // only re-populate equipment if options have changed.
      if (dirtyFields.options) {
        setValue(
          'equipment',
          res?.inventory.decodeVehicleOptions.filter(x => !x.option_code),
          { shouldDirty },
        );
      }
    },
  });

  useEffect(() => {
    const optionCodes = generateOptionCodes(
      currentOptions,
      vehicle.cdk_options,
    );
    decodeOptions({
      variables: { currentStyleId, filters: { OEMOptionCode: optionCodes } },
    });
  }, [currentStyleId, decodeOptions, currentOptions, vehicle.cdk_options]);

  const groupedOptions = groupBy(
    decodeOptionsQuery.data?.inventory.decodeVehicleOptions || [],
    x => x.header,
  );

  const loading = decodeOptionsQuery?.loading;

  const sortedGroupOptionKeys = sortBy(Object.keys(groupedOptions));

  const optionLabel = ({ option_code, option_value }) =>
    option_code ? `${option_code} - ${option_value}` : option_value;

  const isSelected = ({ option_code, option_value }) =>
    currentOptions.some(
      x => x.option_code === option_code && x.option_value === option_value,
    );

  const shouldDirty = true;
  // returns a handler for clicking a given option
  const clickOptionHandler =
    ({ __typename, ...option }) =>
    () =>
      !isSelected(option)
        ? setValue('options', [...currentOptions, option], { shouldDirty })
        : setValue(
            'options',
            currentOptions.filter(
              x =>
                !(
                  x.option_code === option.option_code &&
                  x.option_value === option.option_value
                ),
            ),
            { shouldDirty },
          );

  return (
    <Paper style={{ width: '100%' }}>
      <Container>
        <Typography style={{ padding: '1rem 1rem 1rem 0' }} variant="h6">
          Installed Options
        </Typography>
        {loading && (
          <Loading text="Updating your vehicle equipment based on selections..." />
        )}
        {!currentStyleId && (
          <Alert
            style={{ width: '100%', padding: '0 5px 0 5px' }}
            severity="warning"
          >
            Vehicle is not decoded. Please decode vehicle to see available
            options.
          </Alert>
        )}
        {currentStyleId && (
          <div style={{ width: '100%' }}>
            {sortedGroupOptionKeys.map(header => (
              <div key={header} style={{ padding: '1rem 0 1rem 0' }}>
                <FormLabel component="legend" className={classes.heading}>
                  {header}
                </FormLabel>
                <Grid container>
                  {sortBy(groupedOptions[header], x => x.option_value).map(
                    option => (
                      <Grid
                        item
                        key={option.option_code + header + option.option_value}
                        xs={12}
                        md={6}
                      >
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={isSelected(option)}
                                disabled={!canUpdate}
                                onChange={clickOptionHandler(option)}
                                color="secondary"
                              />
                            }
                            label={optionLabel(option)}
                            className={classes.itemLabel}
                          />
                        </FormGroup>
                      </Grid>
                    ),
                  )}
                </Grid>
              </div>
            ))}
          </div>
        )}
      </Container>
    </Paper>
  );
};

VehicleOptions.fragments = {
  vehicle: gql`
    fragment VehicleOptionsVehicle on GreaseInventoryVehicle {
      options {
        id
        group
        header
        option_code
        option_value
        order
      }
      cdk_options
    }
  `,
};

export default VehicleOptions;
