import { NetworkStatus } from 'apollo-boost';
import gql from 'graphql-tag';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Drawer from '@mui/material/Drawer';
import Fab from '@mui/material/Fab';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';
import Tooltip from '@mui/material/Tooltip';
import CloseIcon from '@mui/icons-material/Close';
import FilterListIcon from '@mui/icons-material/FilterList';

import DealerPicker from 'components/MaterialUI/DealerPicker';
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';
import { useUserContext } from 'components/MaterialUI/UserContext';

import {
  formatPrice,
  numberWithCommas,
  getMediumDateTimeWithWeekday,
  withSuffix,
} from 'utils';

import { filtersToFacets, titleCase } from '../../utils';
import { useVehiclesQueryContext } from '../contexts/VehiclesQueryContext';
import FilterDropDown from './FilterDropDown';
import InventorySearch from './InventorySearch';
import VehiclePriceForm from './VehiclePriceForm';

const useStyles = makeStyles(theme => ({
  good: {
    color: '#74B72E',
  },
  stuff: {
    textAlign: 'right',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '10px',
  },
  header: {
    fontSize: '12px',
  },
  grey: {
    color: '#999999',
  },
  fab: {
    bottom: '10px',
    right: '10px',
    position: 'fixed',
  },
  container: {
    padding: '0.5rem',
    position: 'relative',
    minHeight: '100vh',
  },
  totalCount: {
    textAlign: 'right',
  },
}));

const InventoryMobileTable = () => {
  const classes = useStyles();
  const {
    facets,
    fetchMore,
    inventoryFilters,
    loading,
    networkStatus,
    pagination: { page = 0, page_size = 0, total },
    processor,
    setFacets,
    setInventoryFilters,
    updateVehicle,
    vehicles,
  } = useVehiclesQueryContext();
  const { me } = useUserContext();

  const [formOpen, setFormOpen] = useState(false);
  const [selectedVehicle, setVehicle] = useState({});
  const [state, setState] = useState({
    left: false,
  });
  const history = useHistory();

  const toggleDrawer = (anchor, open) => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const handleDeleteFacet = (_model, _field, _value) => () =>
    setFacets(prev =>
      prev.filter(
        ({ model, field, value }) =>
          model !== _model || field !== _field || value !== _value,
      ),
    );

  const handleDeleteFilter = (_model, _field, _value) => () =>
    setInventoryFilters(prev =>
      prev.filter(
        ({ model, field, value }) =>
          model !== _model || field !== _field || value !== _value,
      ),
    );

  const filterFacets = filtersToFacets(inventoryFilters);

  const [updateVehicleErrors, setUpdateVehicleErrors] = useState({});

  const openForm = data => {
    setVehicle(data);
    setFormOpen(true);
  };

  const handleSubmit = data => {
    updateVehicle({
      variables: {
        id: selectedVehicle.id,
        data: Object.fromEntries(
          Object.entries(data).map(([k, v]) => [k, processor(k)(v)]),
        ),
      },
    })
      .then(() => setFormOpen(false))
      .catch(setUpdateVehicleErrors);
  };

  return (
    <div>
      <div className={classes.container}>
        <Box marginLeft={1} marginTop={1}>
          <DealerPicker />
        </Box>
        <Box marginLeft={1} marginTop={1} marginBottom={2}>
          <InventorySearch
            mobile={true}
            queryContext={useVehiclesQueryContext}
          />
        </Box>
        <Box marginBottom={2}>
          {facets.map(({ model, field, value, options: { label } }) => (
            <span
              style={{ paddingRight: '5px' }}
              key={`${model}${field}${value}`}
            >
              <Chip
                key={`${model}${field}${value}`}
                label={label || value}
                value={value}
                onDelete={handleDeleteFacet(model, field, value)}
              />
            </span>
          ))}
          {filterFacets.map(({ model, field, value, options: { label } }) => (
            <span
              style={{
                paddingRight: '5px',
                display: 'flex',
                marginTop: 'auto',
                marginBottom: 'auto',
              }}
              key={`${model}${field}${value}`}
            >
              <Chip
                label={label || value}
                value={value}
                onDelete={handleDeleteFilter(model, field, value)}
              />
            </span>
          ))}
        </Box>
        <div className={classes.totalCount}>
          Showing {total < page * page_size ? total : page * page_size} of{' '}
          {total}
        </div>
        <VehiclePriceForm
          vehicle={selectedVehicle}
          isOpen={formOpen}
          onClose={() => setFormOpen(false)}
          onSubmit={handleSubmit}
          updateErrors={updateVehicleErrors}
        />
        {networkStatus === NetworkStatus.setVariables && (
          <>
            <div style={{ padding: '2rem', fontSize: '20px' }}>Sorting...</div>
            <LoadingBackdrop open={true} />
          </>
        )}
        {networkStatus !== NetworkStatus.setVariables && (
          <>
            {vehicles.map(vehicle => (
              <div style={{ paddingBottom: '0.5rem' }} key={vehicle.id}>
                <Card onClick={() => history.push(`/inventory/${vehicle.id}`)}>
                  <CardContent className={classes.cardContent}>
                    <div style={{ width: '75%' }}>
                      <div className={classes.header}>
                        {vehicle.displayName}{' '}
                        <span className={classes.grey}>
                          {vehicle.trim_variation}
                        </span>
                      </div>
                      <div>
                        {vehicle.stock_number} /{' '}
                        {numberWithCommas(vehicle.odometer)} km
                      </div>
                      <div>
                        <span className={classes.grey}>
                          {vehicle.stock_type} /{' '}
                          {titleCase(vehicle.stock_status_name)}
                        </span>{' '}
                      </div>
                      <div>
                        Int: {vehicle.interior_colour_name}{' '}
                        <span className={classes.grey}>
                          Ext: {vehicle.exterior_colour_name}
                        </span>
                      </div>
                    </div>
                    <div className={classes.stuff}>
                      <div
                        onClick={() => openForm(vehicle)}
                        className={classes.header}
                      >
                        <Tooltip
                          title={
                            vehicle.is_on_special ? 'Vehicle is On Special' : ''
                          }
                        >
                          <i
                            className={
                              vehicle.is_on_special
                                ? [classes.good, 'fas fa-tag'].join(' ')
                                : ''
                            }
                          />
                        </Tooltip>{' '}
                        {vehicle.is_on_special
                          ? formatPrice(vehicle.special_price)
                          : formatPrice(vehicle.regular_price)}
                      </div>
                      <div>{vehicle.days_in_stock} Days</div>
                      <div>{vehicle.photo_count} Photos</div>
                      {vehicle.date_user_modified ? (
                        <div style={{ width: '100%' }}>
                          Updated:{' '}
                          {getMediumDateTimeWithWeekday(
                            withSuffix(vehicle.date_user_modified, 'Z'),
                            me?.goUserProfile?.settings?.timezone,
                          )}
                        </div>
                      ) : (
                        <div style={{ width: '100%' }}>Updated: N/A</div>
                      )}
                    </div>
                  </CardContent>
                </Card>
              </div>
            ))}
          </>
        )}
        {!loading && <Waypoint onEnter={fetchMore} />}
        {loading &&
          networkStatus === NetworkStatus.fetchMore &&
          'Loading more vehicles...'}
      </div>
      <Fab
        color="primary"
        className={classes.fab}
        onClick={toggleDrawer('left', true)}
      >
        <Tooltip title="Filter list">
          <FilterListIcon />
        </Tooltip>
      </Fab>
      <Drawer
        anchor={'left'}
        open={state['left']}
        onClose={toggleDrawer('left', false)}
      >
        <div style={{ display: 'flex' }}>
          <IconButton
            style={{
              display: 'flex',
              marginLeft: 'auto',
              height: '50px',
              zIndex: '1000',
            }}
            onClick={toggleDrawer('left', false)}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </div>
        <FilterDropDown />
      </Drawer>
    </div>
  );
};

InventoryMobileTable.fragments = {
  vehicle: gql`
    fragment InventoryMobileTableVehicle on GreaseInventoryVehicle {
      id
      date_user_modified
      days_in_stock
      displayName
      exterior_colour_name
      interior_colour_name
      is_on_special
      odometer
      photo_count
      regular_price
      special_price
      stock_number
      stock_type
      stock_status_name
      trim_variation
    }
  `,
};

export default InventoryMobileTable;
