/* external */
import moment from 'moment';
import React, { useState } from 'react';
import gql from 'graphql-tag';

/* Material UI */
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

/* Internal */
import { titleCase } from '../../utils';
import CancelPackageModal from './CancelPackageModal';
import EditVehicleModal from './EditVehicleModal';
import GoldCardActions from './GoldCardActions';
import NotesModal from './NotesModal';
import PaymentFrequencyModal from './PaymentFrequencyModal';
import PaymentInfoModal from './PaymentInfoModal';
import PaymentScheduleModal from './PaymentScheduleModal';
import ReschedulePaymentsModal from './ReschedulePaymentsModal';
import VoidPackageModal from './VoidPackageModal';

const useStyles = makeStyles(() => ({
  data: {
    fontSize: 13,
  },
  divider: {
    borderRight: '1px solid rgba(0,0,0,0.2)',
  },
  label: {
    fontSize: 13,
    fontWeight: 'bold',
  },
  spacing: {
    padding: '0.5em 0 0 0',
  },
  title: {
    fontSize: 14,
    marginLeft: '1em',
  },
  active: {
    backgroundColor: '#dff0d8',
    borderColor: '#dbeccd',
    color: '#468847',
    fontWeight: 'bold',
    padding: '0.5em 1.5em 0.5em 1.5em',
    borderRadius: '4px',
  },
  expired: {
    backgroundColor: '#f2dede',
    borderColor: '#ebccd1',
    color: '#a94442',
    fontWeight: 'bold',
    padding: '0.5em 1.5em 0.5em 1.5em',
    borderRadius: '4px',
  },
}));

const GoldCardTable = ({ goldCard, refetch }) => {
  const classes = useStyles();
  const [infoModal, setInfoModal] = useState(false);
  const [scheduleModal, setScheduleModal] = useState(false);
  const [notesModal, setNotesModal] = useState(false);
  const [voidModal, setVoidModal] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [editVehicleModal, setEditVehicleModal] = useState(false);
  const [frequencyModal, setFrequencyModal] = useState(false);
  const [rescheduleModal, setRescheduleModal] = useState(false);

  const handleInfoModal = isOpen => {
    setInfoModal(isOpen);
  };

  const handleScheduleModal = isOpen => {
    setScheduleModal(isOpen);
  };

  const handleNotesModal = isOpen => {
    setNotesModal(isOpen);
  };

  const handleVoidModal = isOpen => {
    setVoidModal(isOpen);
  };

  const handleCancelModal = isOpen => {
    setCancelModal(isOpen);
  };

  const handleEditVehicleModal = isOpen => {
    setEditVehicleModal(isOpen);
  };

  const handleFrequencyModal = isOpen => {
    setFrequencyModal(isOpen);
  };

  const handleRescheduleModal = isOpen => {
    setRescheduleModal(isOpen);
  };

  return (
    <>
      <PaymentInfoModal
        goldCard={goldCard}
        isOpen={infoModal}
        onClose={() => setInfoModal(false)}
      />
      <PaymentScheduleModal
        goldCard={goldCard}
        isOpen={scheduleModal}
        onClose={() => setScheduleModal(false)}
        refetch={refetch}
      />
      <NotesModal
        goldCard={goldCard}
        isOpen={notesModal}
        onClose={() => setNotesModal(false)}
        refetch={refetch}
      />
      <VoidPackageModal
        goldCard={goldCard}
        isOpen={voidModal}
        onClose={() => setVoidModal(false)}
      />
      <CancelPackageModal
        goldCard={goldCard}
        isOpen={cancelModal}
        onClose={() => setCancelModal(false)}
      />
      <EditVehicleModal
        goldCard={goldCard}
        isOpen={editVehicleModal}
        onClose={() => setEditVehicleModal(false)}
      />
      <PaymentFrequencyModal
        goldCard={goldCard}
        isOpen={frequencyModal}
        onClose={() => setFrequencyModal(false)}
        refetch={refetch}
      />
      <ReschedulePaymentsModal
        goldCard={goldCard}
        isOpen={rescheduleModal}
        onClose={() => setRescheduleModal(false)}
        refetch={refetch}
      />

      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Grid container direction="row">
            <Grid item>
              <Typography className={classes.title}>
                <strong>Dealership </strong>: {goldCard.dealer?.dealerName}
              </Typography>
            </Grid>
            <Grid item>
              <GoldCardActions
                goldCard={goldCard}
                openInfoModal={() => handleInfoModal(true)}
                openScheduleModal={() => handleScheduleModal(true)}
                openNotesModal={() => handleNotesModal(true)}
                openVoidModal={() => handleVoidModal(true)}
                openCancelModal={() => handleCancelModal(true)}
                openVehicleModal={() => handleEditVehicleModal(true)}
                openFrequencyModal={() => handleFrequencyModal(true)}
                openReschedulePaymentModal={() => handleRescheduleModal(true)}
              />
            </Grid>
          </Grid>
        </Grid>
        {goldCard.status === 'Active' && (
          <Grid item>
            <Box className={classes.active}>Active</Box>
          </Grid>
        )}
        {goldCard.status === 'Expired' && (
          <Grid item>
            <Box className={classes.expired}>Expired</Box>
          </Grid>
        )}
      </Grid>
      <TableContainer className={classes.spacing}>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell className={classes.label}> Deal #:</TableCell>
              <TableCell className={`${classes.data} ${classes.divider}`}>
                {goldCard.dealNumber}
              </TableCell>
              <TableCell className={classes.label}>Cert #:</TableCell>
              <TableCell className={classes.data}>
                {goldCard.firstCanCert}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.label}>VIN:</TableCell>
              <TableCell className={`${classes.data} ${classes.divider}`}>
                {goldCard.vin}
              </TableCell>
              <TableCell className={classes.label}>Start Date:</TableCell>
              <TableCell className={classes.data}>
                {moment(goldCard.startDate).format('YYYY-MM-DD')}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.label}>Vehicle:</TableCell>
              <TableCell className={`${classes.data} ${classes.divider}`}>
                {goldCard.year} {goldCard.makeName} {goldCard.modelName}
              </TableCell>
              <TableCell className={classes.label}>Expiry Date:</TableCell>
              <TableCell className={classes.data}>
                {moment(goldCard.endDate).format('YYYY-MM-DD')}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.label}>
                Payment Frequency:
              </TableCell>
              <TableCell className={`${classes.data} ${classes.divider}`}>
                {titleCase(goldCard.paymentFrequency)}
              </TableCell>
              <TableCell className={classes.label}>Created By:</TableCell>
              <TableCell className={classes.data}>
                {goldCard.createdBy}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.label}>Payment Method:</TableCell>
              <TableCell className={`${classes.data} ${classes.divider}`}>
                {titleCase(goldCard.activePaymentMethod)}
              </TableCell>
              <TableCell className={classes.label}>Failed Payments:</TableCell>
              <TableCell className={classes.data}>
                {goldCard.failedPaymentCount}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

GoldCardTable.fragments = {
  package: gql`
    fragment GoldCardTablePackage on Package {
      dealer {
        dealerName
      }
      status
      dealNumber
      firstCanCert
      vin
      startDate
      year
      makeName
      modelName
      endDate
      paymentFrequency
      createdBy
      activePaymentMethod
      failedPaymentCount
    }
  `,
};

export default GoldCardTable;
