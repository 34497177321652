import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import CircularProgress from '@mui/material/CircularProgress';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { useUserContext } from 'components/MaterialUI/UserContext';
import { RoleGroup } from 'constants.js';

const LEADS_EXPORT_QUERY = gql`
  query getExportUrl($query: LeadQuery, $filters: LeadFilters) {
    leadsExport(query: $query, filters: $filters)
  }
`;

const LeadsListActions = ({ anchorEl, handleAnchorClose, query, filter }) => {
  const [creatingCSV, setCreatingCSV] = useState(false);

  const history = useHistory();

  const { data, loading } = useQuery(LEADS_EXPORT_QUERY, {
    variables: {
      query: query,
      filters: filter,
    },
    skip: !creatingCSV,
    fetchPolicy: 'network-only',
    onCompleted: () => setCreatingCSV(false),
  });

  if (data) {
    window.location.href = data.leadsExport;
  }

  const { currentUser } = useUserContext();
  const role = currentUser?.role || 'none';

  return (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={() => handleAnchorClose()}
      style={{ width: '200px' }}
    >
      {RoleGroup.VIEW_LEAD_CONFIG_ROLE.includes(role) && (
        <MenuItem onClick={() => history.push('leads/config')}>
          Distribution Rules
        </MenuItem>
      )}
      {loading ? (
        <MenuItem disabled>
          <ListItemIcon style={{ minWidth: '25px' }}>
            <CircularProgress size={20} />
          </ListItemIcon>
          <ListItemText primary="Generating CSV..." />
        </MenuItem>
      ) : (
        <MenuItem onClick={() => setCreatingCSV(true)}>Export CSV</MenuItem>
      )}
    </Menu>
  );
};

export default LeadsListActions;
