import moment from 'moment';
import React from 'react';
import gql from 'graphql-tag';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DateRangeIcon from '@mui/icons-material/DateRange';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';

import { AppointmentStatus } from 'constants.js';
import { defaultTZ } from 'utils';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '50px',
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
  },
}));

const DesktopAppointment = ({ appointments, updateStatus }) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            <Typography className={classes.title} gutterBottom>
              Appointments
            </Typography>
          </Grid>
        </Grid>
        <List>
          {appointments.length === 0 && (
            <Typography style={{ fontSize: 14 }}>
              There are no appointments for this customer.
            </Typography>
          )}
          {appointments.map(appointment => (
            <ListItem>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <ListItemAvatar>
                    <DateRangeIcon />
                  </ListItemAvatar>
                </Grid>
                <Grid item xs={4}>
                  <ListItemText
                    primary={`${appointment.type_name} @ ${appointment.dealer.dealer_name}`}
                    secondary={`${moment(appointment.start_date)
                      .tz(defaultTZ)
                      .format('LLL')}  Assigned to: ${
                      appointment.attendees?.[0]?.display_name
                    }`}
                  />
                </Grid>
                <Grid item>
                  <Button
                    onClick={() =>
                      updateStatus(appointment._id, AppointmentStatus.ATTENDED)
                    }
                    color="primary"
                    variant="contained"
                    style={{ backgroundColor: theme.actions.info }}
                  >
                    <CheckIcon />
                    {'SHOW'}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    onClick={() =>
                      updateStatus(appointment._id, AppointmentStatus.NO_SHOW)
                    }
                    color="primary"
                    variant="contained"
                    style={{ backgroundColor: theme.actions.info }}
                  >
                    <SentimentVeryDissatisfiedIcon />
                    {'NO SHOW'}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    onClick={() =>
                      updateStatus(appointment._id, AppointmentStatus.CANCELLED)
                    }
                    color="primary"
                    style={{ backgroundColor: theme.actions.info }}
                    variant="contained"
                  >
                    <CloseIcon />
                    {'CANCEL'}
                  </Button>
                </Grid>
              </Grid>
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  );
};

DesktopAppointment.fragments = {
  customer: gql`
    fragment DesktopAppointmentFragment on Customer {
      appointments {
        _id
        type_name
        dealer {
          dealer_name
        }
        start_date
        attendees {
          display_name
        }
      }
    }
  `,
};

export default DesktopAppointment;
