import React from 'react';

/* Material UI */
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';

const useStyles = makeStyles(theme => ({
  chipBox: {
    paddingRight: '5px',
    paddingBottom: '5px',
  },
}));

const FacetChips = ({ facets, onDeleteFacet, others = [] }) => {
  const classes = useStyles();

  return (
    <Box display="flex" flexWrap="wrap">
      {facets.map(({ field, model, value, options: { label } }) => (
        <Box
          component="span"
          key={`${model}${field}${value}`}
          className={classes.chipBox}
        >
          <Chip
            label={label || value || 'Unknown'}
            value={value}
            onDelete={() => onDeleteFacet(model, field, value)}
          />
        </Box>
      ))}
      {others.map(({ label, onDelete }, index) => (
        <Box
          component="span"
          key={`${label}${index}`}
          className={classes.chipBox}
        >
          <Chip label={label} value={label} onDelete={onDelete} />
        </Box>
      ))}
    </Box>
  );
};

export default FacetChips;
