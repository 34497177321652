import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import app from '../../app';
import { RequiresRoles } from '../../app/components';
import CallRecordingTable from './CallList';
import ChatFromNumber from './ChatFromNumber';
import { Gateways } from './Messaging';
import Conversation from './Messaging/Conversation';
import ConversationList from './Messaging/ConversationListContainer';
import SMSPermissions from './Messaging/SMSActions/SMSPermissions';
import SMSSettings from './Messaging/SMSActions/SMSSettings';
import BulkSends from './Messaging/SMSActions/BulkSends/BulkSends';
import BulkSendDetailView from './Messaging/SMSActions/BulkSends/BulkSendDetailView';
import MessageSearch from './Messaging/SMSActions/MessageSearch/MessageSearch';
import ManageGateways from './Messaging/ManageGateways';

import { Role, RoleGroup } from 'constants.js';

const propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
};

class SMS extends Component {
  render() {
    const { match } = this.props;
    return (
      <app.components.Layout>
        <Switch>
          <Route exact path={`${match.url}/gateways`} component={Gateways} />
          <Route
            exact
            path={`${match.url}/gateways/manage`}
            render={() => (
              <RequiresRoles
                requiredRoles={[Role.ORGANIZATION_ADMIN]}
                renderOnSuccess={() => <ManageGateways />}
              />
            )}
          />
          <Route
            exact
            path={`${match.url}/gateways/:gatewayId`}
            render={({ match: matched }) => (
              <ConversationList
                gatewayId={parseInt(matched.params.gatewayId, 10)}
              />
            )}
          />
          {/* SMS PERMISSIONS */}
          <Route
            exact
            path={`${match.url}/gateways/:gatewayId/permissions`}
            render={({ match: matched }) => (
              <SMSPermissions
                gatewayId={parseInt(matched.params.gatewayId, 10)}
              />
            )}
          />
          {/* SMS SETTINGS */}
          <Route
            exact
            path={`${match.url}/gateways/:gatewayId/settings`}
            render={({ match: matched }) => (
              <SMSSettings gatewayId={parseInt(matched.params.gatewayId, 10)} />
            )}
          />
          {/* BULK SENDS */}
          <Route
            exact
            path={`${match.url}/gateways/:gatewayId/bulk-sends`}
            render={({ match: matched }) => (
              <BulkSends gatewayId={parseInt(matched.params.gatewayId, 10)} />
            )}
          />
          {/* BULK SEND DETAIL VIEW */}
          <Route
            exact
            path={`${match.url}/gateways/:gatewayId/bulk-sends/:bulkSendId/details`}
            render={({ match: matched }) => (
              <BulkSendDetailView
                gatewayId={parseInt(matched.params.gatewayId, 10)}
                bulkSendId={parseInt(matched.params.bulkSendId, 10)}
              />
            )}
          />
          {/* MESSAGE SEARCH BY GATEWAY */}
          <Route
            exact
            path={`${match.url}/gateways/:gatewayId/message-search`}
            render={({ match: matched }) => (
              <MessageSearch
                gatewayId={parseInt(matched.params.gatewayId, 10)}
              />
            )}
          />
          {/* GLOBAL MESSAGE SEARCH */}
          <Route
            exact
            path={`${match.url}/message-search`}
            render={() => <MessageSearch gatewayId="" />}
          />
          <Route
            exact
            path={`${match.url}/gateways/:gatewayId/:conversationId`}
            render={({ match: matched }) => (
              <Conversation
                gatewayId={parseInt(matched.params.gatewayId, 10)}
                conversationId={parseInt(matched.params.conversationId, 10)}
              />
            )}
          />
          <Route
            exact
            path={`${match.url}/gateways/:gatewayId/number/:phoneNumber`}
            render={({ match: matched }) => (
              <ChatFromNumber
                gatewayId={parseInt(matched.params.gatewayId, 10)}
                phoneNumber={matched.params.phoneNumber}
              />
            )}
          />
          <RequiresRoles
            requiredRoles={[...RoleGroup.MANAGERS, Role.SERVICE_MANAGER]}
            renderOnSuccess={() => (
              <Route
                exact
                path={`${match.url}/calls`}
                component={CallRecordingTable}
              />
            )}
          />
        </Switch>
      </app.components.Layout>
    );
  }
}

SMS.propTypes = propTypes;

export default SMS;
