import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { cloneDeep, isEmpty } from 'lodash';

import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material';
import Card from '@mui/material/Card';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import FilterListIcon from '@mui/icons-material/FilterList';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Loading from 'components/MaterialUI/Loading';
import ActivityCard from './ActivityCard';
import ActivityCardMobile from './ActivityCardMobile';

const useStyles = makeStyles(theme => ({
  filterButton: {
    padding: '0px',
    margin: '0px 5px 0px 10px',
  },
  paper: {
    padding: '10px',
    minHeight: '300px',
  },
  table: {
    padding: '10px',
  },
  mobile: {
    overflowY: 'auto',
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  card: {
    marginBottom: '15px',
    marginTop: '15px',
  },
}));

const CUST_ACTIVITY_QUERY = gql`
  query CustomerActivity($_id: ID!) {
    customerActivity(_id: $_id) {
      activities {
        ...ActivityCardFragment
        ...ActivityCardMobileFragment
        item_key
      }
    }
  }
  ${ActivityCard.fragments.event}
  ${ActivityCardMobile.fragments.event}
`;

const CustomerActivity = ({ customerId }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [filters, setFilters] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const { data, loading } = useQuery(CUST_ACTIVITY_QUERY, {
    variables: {
      _id: customerId,
    },
  });

  const handleFilters = event => {
    let updateFilters = cloneDeep(filters);
    const index = updateFilters.indexOf(event.target.name);
    if (index === -1) {
      updateFilters = [...updateFilters, event.target.name];
    } else {
      updateFilters.splice(index, 1);
    }
    setFilters([...updateFilters]);
  };

  const handleChipDelete = chipName => {
    const updateFilters = cloneDeep(filters);
    const index = updateFilters.indexOf(chipName);
    updateFilters.splice(index, 1);
    setFilters([...updateFilters]);
  };

  const handleFilterClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  if (loading) {
    return <Loading />;
  }
  const activities = data?.customerActivity.activities || [];
  const results = activities.filter(result =>
    !isEmpty(filters) ? filters.includes(result.event) : true,
  );

  return (
    <>
      <Paper className={classes.paper}>
        <span style={{ display: 'flex', padding: '10px' }}>
          <Typography className={classes.title} gutterBottom>
            Customer Activities
          </Typography>
          <IconButton
            className={classes.filterButton}
            color="primary"
            onClick={handleFilterClick}
            size="large"
          >
            <FilterListIcon style={theme.actions.filter} />
          </IconButton>
          <Menu
            id="filter-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleFilterClose}
          >
            {Array.from(
              new Set(activities.map(activity => activity.event)),
            ).map((act, index) => (
              <MenuItem key={`${act}:${index}`}>
                <FormControlLabel
                  label={act}
                  control={
                    <Checkbox
                      name={act}
                      onChange={handleFilters}
                      checked={filters.includes(act)}
                      color="primary"
                    />
                  }
                />
              </MenuItem>
            ))}
          </Menu>
        </span>
        <span style={{ display: 'flex', paddingLeft: '10px' }}>
          {filters.map((filter, index) => (
            <Chip
              key={String(filter) + String(index)}
              name={filter}
              label={filter}
              onDelete={() => handleChipDelete(filter)}
              style={{ marginRight: '5px' }}
            />
          ))}
          {results.length === 0 && 'No activity for this customer'}
        </span>
        <Hidden lgDown>
          {results.map((act, index) => (
            <Card className={classes.card} key={`${act.timestamp}:${index}`}>
              <ActivityCard act={act} />
            </Card>
          ))}
        </Hidden>
        <Hidden lgUp>
          {results.map((act, index) => (
            <Card className={classes.card} key={`${act.timestamp}:${index}`}>
              <ActivityCardMobile act={act} />
            </Card>
          ))}
        </Hidden>
      </Paper>
    </>
  );
};

export default CustomerActivity;
