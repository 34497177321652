import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import React, { useState } from 'react';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import FolderIcon from '@mui/icons-material/Folder';

import ROAttachmentRow from './ROAttachmentRow';

const PRESIGNED_UPLOAD_URL_QUERY = gql`
  query getPresignedUploadURL($service_ro_id: ID!, $file_name: String!) {
    rotracker {
      getPresignedUploadURL(
        service_ro_id: $service_ro_id
        file_name: $file_name
      ) {
        url
        attachment_id
        fields
      }
    }
  }
`;

const FINALIZE_UPLOAD_MUTATION = gql`
  mutation finalize($attachment_id: ID!) {
    rotracker {
      finalizeAttachmentUpload(attachment_id: $attachment_id) {
        id
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  input: {
    display: 'none',
  },
}));

const RODetailsAttachments = ({ ro, refetch, ...mutations }) => {
  const mobile = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const classes = useStyles();
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const finalizeUpload = useMutation(FINALIZE_UPLOAD_MUTATION, {
    onCompleted: () => {
      refetch().then(() => {
        setLoading(false);
        setFile(null);
      });
    },
  })[0];

  const getUploadURL = useLazyQuery(PRESIGNED_UPLOAD_URL_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: data => {
      let { attachment_id, fields, url } = data.rotracker.getPresignedUploadURL;
      fields = JSON.parse(fields);

      let formData = new FormData();
      for (let [k, v] of Object.entries(fields)) {
        formData.append(k, v);
      }
      formData.append('file', file, file.name);

      fetch(url, {
        method: 'POST',
        body: formData,
        mode: 'no-cors',
      }).then(async data => {
        finalizeUpload({
          variables: {
            attachment_id: attachment_id,
          },
        });
      });
    },
  })[0];

  return (
    <Paper style={{ width: '100%', paddingBottom: '2rem' }}>
      <Container>
        <Typography style={{ padding: '1rem 1rem 1rem 0' }} variant="h6">
          <div style={{ paddingBottom: '1rem' }}>Attachments</div>
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} direction="column" xl={6}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={12}>
                {/*form, button inside*/}
                <form style={{ paddingTop: '1rem' }}>
                  <span style={{ paddingRight: '1rem' }}>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      style={{
                        maxWidth: `${mobile ? '250px' : '150px'}`,
                        width: '100%',
                      }}
                      value={file?.name || ''}
                      disabled
                    />
                  </span>

                  <input
                    className={classes.input}
                    id="attachment-file"
                    multiple
                    type="file"
                    onChange={event => setFile(event.target.files[0])}
                  />
                  <label htmlFor="attachment-file">
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                    >
                      <FolderIcon style={{ paddingRight: '5px' }} /> BROWSE
                    </Button>
                  </label>
                  <div style={{ paddingTop: '1rem' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      disabled={!file || loading}
                      onClick={() => {
                        if (!file || loading) return;
                        setLoading(true);
                        getUploadURL({
                          variables: {
                            service_ro_id: ro.id,
                            file_name: file.name,
                          },
                        });
                      }}
                    >
                      {loading ? (
                        <>
                          <CircularProgress size={20} /> <Box>Uploading...</Box>
                        </>
                      ) : (
                        <Box>Upload</Box>
                      )}
                    </Button>
                  </div>
                </form>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} xl={6}>
            <Grid container>
              <Grid item xs={12} md={12}>
                <Table style={{ minWidth: 300 }} aria-label="spanning table">
                  <TableHead>
                    <TableRow>
                      <TableCell>File</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  {ro?.attachments?.map(attachment => (
                    <ROAttachmentRow
                      attachment={attachment}
                      {...mutations}
                      key={attachment.id}
                    />
                  ))}
                </Table>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Paper>
  );
};

export default RODetailsAttachments;
