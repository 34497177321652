import React from 'react';

import { Checkbox, Grid, Link, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  checkbox: {
    textAlign: 'center',
  },
  text: {
    paddingTop: '9px',
  },
  clickable: {
    paddingTop: '9px',
    textDecoration: 'underline',
    color: 'blue',
  },
  subtitle: {
    fontWeight: 'bold',
  },
  indentCheckbox: {
    textAlign: 'center',
    marginLeft: '40px',
  },
  indentText: {
    marginLeft: '20px',
    paddingTop: '9px',
  },
});

const AFCheckboxGroup = ({
  item,
  isChecked,
  handleCheck,
  generatePrintablePDF,
  isLocked,
  opportunity,
}) => {
  const classes = useStyles();
  return (
    <>
      <Grid item xs={1} className={classes.checkbox}>
        <Checkbox
          checked={isChecked(item.a)}
          onChange={e => handleCheck(e)}
          name={item.a}
          disabled={isLocked}
          color="secondary"
        />
      </Grid>
      <Grid item xs={2} className={classes.checkbox}>
        <Checkbox
          checked={isChecked(item.f)}
          onChange={e => handleCheck(e)}
          name={item.f}
          disabled={isLocked}
          color="secondary"
        />
      </Grid>
      <Grid item xs={9}>
        {!item.pdf && (
          <Typography variant="body1" className={classes.text}>
            {item.desc}
          </Typography>
        )}
        {item.pdf && item.pdf !== false && (
          <Typography className={classes.clickable}>
            <Link
              href="#"
              variant="body1"
              color="inherit"
              onClick={e => {
                e.preventDefault();
                generatePrintablePDF(opportunity._id, item.pdf);
              }}
            >
              {item.desc}
            </Link>
          </Typography>
        )}
        {item.info && item.info !== false && (
          <Typography className={classes.clickable}>
            <Link
              href={item.info}
              target="_blank"
              rel="noopener"
              color="inherit"
              variant="body2"
            >
              (Info PDF)
            </Link>
          </Typography>
        )}
      </Grid>
    </>
  );
};

const SingleCheckboxGroup = ({ item, isChecked, handleCheck, isLocked }) => {
  const classes = useStyles();
  const indentItems = [
    'Number in CDK',
    'On the side',
    '3 months | 5,000km (used only)',
    'If Business, RIN (registrant identification number) attached',
  ];
  return (
    <>
      <Grid item xs={2} className={classes.checkbox}>
        <Checkbox
          checked={isChecked(item.a)}
          onChange={e => handleCheck(e)}
          name={item.a}
          disabled={isLocked}
          className={
            indentItems.includes(item.desc) ? classes.indentCheckbox : ''
          }
          color="secondary"
        />
      </Grid>
      <Grid item xs={10}>
        <Typography
          variant="body1"
          className={
            indentItems.includes(item.desc) ? classes.indentText : classes.text
          }
        >
          {item.desc}
        </Typography>
      </Grid>
    </>
  );
};

const YesNoCheckboxGroup = ({ item, isChecked, handleCheck, isLocked }) => {
  const classes = useStyles();
  return (
    <>
      <Grid item xs={2} className={classes.checkbox}>
        <Checkbox
          checked={isChecked(item.yes)}
          onChange={e => handleCheck(e)}
          name={item.yes}
          disabled={isLocked}
          color="secondary"
        />
      </Grid>
      <Grid item xs={1} className={classes.checkbox}>
        <Checkbox
          checked={isChecked(item.no)}
          onChange={e => handleCheck(e)}
          name={item.no}
          disabled={isLocked}
          color="secondary"
        />
      </Grid>
      <Grid item xs={2} className={classes.checkbox}>
        <Checkbox
          checked={isChecked(item.na)}
          onChange={e => handleCheck(e)}
          name={item.na}
          disabled={isLocked}
          color="secondary"
        />
      </Grid>
      <Grid item xs={7}>
        <Typography variant="body1" className={classes.text}>
          {item.desc}
        </Typography>
      </Grid>
    </>
  );
};

export { AFCheckboxGroup, SingleCheckboxGroup, YesNoCheckboxGroup };
