import { ZendeskAPI } from 'react-zendesk';
import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const ZendeskButton = () => {
  const openWidget = () => {
    ZendeskAPI('webWidget', 'show');
    ZendeskAPI('webWidget', 'open');
  };
  return (
    <Box onClick={openWidget}>
      <Button
        startIcon={<HelpOutlineIcon fontSize="large" />}
        style={{ color: 'white', textTransform: 'none' }}
      >
        <Typography variant="subtitle1">Help</Typography>
      </Button>
    </Box>
  );
};

export default ZendeskButton;
