import React from 'react';

import { startCase, toLower } from 'lodash';
import { Controller } from 'react-hook-form';
import { makeStyles } from '@mui/styles';

import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import TableContainer from '@mui/material/TableContainer';

import { CBB_CONDITIONS } from '../../const';
import { formatPrice } from 'utils';
import { titleCase } from '../../utils';
import { NoValuationAlert } from '../alerts/CBBWizardAlerts';
import RadioControl from 'components/MaterialUI/RadioControl2';
import {
  CBBTrimRadioGroup,
  CBBStyleRadioGroup,
  CBBConditionRadioGroup,
} from '../vehicle/cbb/CBBRadioGroups';

const useStyles = makeStyles(theme => ({
  expansionPanel: {
    paddingBottom: '0.5rem',
  },
}));

const CBBExpansionPanel = ({
  selectedCondition,
  priceAdjustments,
  wholesalePrices,
  wholesaleTotals,
  retailPrices,
  retailTotals,
  control,
  styleNames,
  options,
  trimNames,
  noValuation,
}) => {
  const { expansionPanel } = useStyles();

  return (
    <>
      <Typography style={{ padding: '1rem 0 1rem 0', fontSize: '12px' }}>
        <Grid container direction="row" spacing={2}>
          <Grid item xs container direction="column">
            <Grid item xs={12}>
              <div>
                <b>Condition:</b>
              </div>
              <div>
                <b>Total Adjustments:</b>
              </div>
              <div>
                <b>Wholesale/Retail:</b>
              </div>
            </Grid>
          </Grid>
          <Grid item xs container direction="column">
            <Grid item xs={12}>
              <div>{titleCase(selectedCondition)}</div>
              <div>{formatPrice(priceAdjustments(selectedCondition))}</div>
              <div>
                {formatPrice(wholesalePrices[selectedCondition])}/
                {formatPrice(retailPrices[selectedCondition])}
              </div>
            </Grid>
          </Grid>
        </Grid>
        <hr />
        <Grid container direction="row" spacing={2}>
          <Grid item xs container direction="column">
            <Grid item xs={12}>
              <div>
                <b>Total Wholesale:</b>
              </div>
              <div>
                <b>Total Retail:</b>
              </div>
            </Grid>
          </Grid>
          <Grid item xs container direction="column">
            <Grid item xs={12}>
              <div>
                <b>{formatPrice(wholesaleTotals[selectedCondition])}</b>
              </div>
              <div>
                <b>{formatPrice(retailTotals[selectedCondition])}</b>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Typography>
      {noValuation && <NoValuationAlert />}
      <Grid container>
        <Grid item xs={12} className={expansionPanel}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              Trim
            </AccordionSummary>
            <AccordionDetails>
              <RadioControl
                control={control}
                name="trim"
                as={<CBBTrimRadioGroup trimNames={trimNames} />}
              />
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12} className={expansionPanel}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              Style
            </AccordionSummary>
            <AccordionDetails>
              <RadioControl
                control={control}
                name="style"
                as={<CBBStyleRadioGroup styleNames={styleNames} />}
              />
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12} className={expansionPanel}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              Equipment
            </AccordionSummary>
            <AccordionDetails>
              <FormControl component="fieldset">
                <Controller
                  control={control}
                  name="equipment"
                  render={({ field: { onChange, value } }) => {
                    const selected = value ? JSON.parse(value) : [];
                    const isSelected = option_code =>
                      selected.includes(option_code);
                    const handleChange =
                      option_code =>
                      ({ target: { checked } }) =>
                        onChange(
                          JSON.stringify(
                            checked
                              ? [...selected, option_code]
                              : selected.filter(x => x !== option_code),
                          ),
                        );

                    return (
                      <FormGroup>
                        {options.map(({ option_code, description }) => (
                          <FormControlLabel
                            key={`${option_code}`}
                            label={description}
                            control={
                              <Checkbox
                                checked={isSelected(option_code)}
                                value={option_code}
                                onChange={handleChange(option_code)}
                              />
                            }
                          />
                        ))}
                      </FormGroup>
                    );
                  }}
                />
              </FormControl>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12} className={expansionPanel}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              Condition
            </AccordionSummary>
            <AccordionDetails>
              <RadioControl
                control={control}
                name="condition"
                as={<CBBConditionRadioGroup />}
              />
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              Wholesale/Retail Pricing
            </AccordionSummary>
            <AccordionDetails>
              <Grid item container xs={12}>
                {/* Wholesale Table */}
                <Grid item xs={12} style={{ paddingBottom: '2rem' }}>
                  <TableContainer xs={12}>
                    <Table xs={12}>
                      <TableHead>
                        <TableRow>
                          <TableCell>Wholesale</TableCell>
                          <TableCell rowSpan={1} />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {CBB_CONDITIONS.map(key => (
                          <TableRow
                            key={key}
                            selected={selectedCondition === key}
                          >
                            <TableCell
                              style={
                                selectedCondition === key
                                  ? { fontWeight: 'bold' }
                                  : {}
                              }
                            >
                              {startCase(toLower(key))}
                            </TableCell>
                            <TableCell>
                              {formatPrice(wholesaleTotals[key])}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>

                {/* Retail Table */}
                <Grid item xs={12} style={{ paddingBottom: '1rem' }}>
                  <Table xs={12}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Retail</TableCell>
                        <TableCell rowSpan={1} />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {CBB_CONDITIONS.map(key => (
                        <TableRow
                          key={key}
                          selected={selectedCondition === key}
                        >
                          <TableCell
                            style={
                              selectedCondition === key
                                ? { fontWeight: 'bold' }
                                : {}
                            }
                          >
                            {startCase(toLower(key))}
                          </TableCell>
                          <TableCell>
                            {formatPrice(retailTotals[key])}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </>
  );
};

export default CBBExpansionPanel;
