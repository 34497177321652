import React from 'react';
import { useHistory } from 'react-router-dom';
import gql from 'graphql-tag';

import Box from '@mui/material/Box';
import { useMediaQuery } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '50px',
    height: '100%',
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  pos: {
    marginBottom: 12,
  },
}));

const RenderAlert = ({ customer }) => {
  const history = useHistory();
  const mergeCandidates = customer?.merge_candidates;
  const selected = [...mergeCandidates.map(x => x._id), customer._id];

  return mergeCandidates.length > 0 ? (
    <Alert
      severity="warning"
      action={
        <Button
          color="inherit"
          size="small"
          onClick={() => history.push(`/customers/merge/${selected}`)}
        >
          Review Similar Profiles
        </Button>
      }
    >
      There are similar profiles that exist in Market. To review and merge,
      please review the similar profiles.
    </Alert>
  ) : (
    <Box>There are no alerts for this customer</Box>
  );
};

const Alerts = ({ customer }) => {
  const classes = useStyles();
  const desktop = useMediaQuery(theme => theme.breakpoints.up('sm'));

  return (
    <>
      {desktop ? (
        <Card className={classes.root}>
          <CardContent>
            <Typography className={classes.title} gutterBottom>
              Alerts
            </Typography>
            <RenderAlert customer={customer} />
          </CardContent>
        </Card>
      ) : (
        <Accordion defaultExpanded={true}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.title} gutterBottom>
              Alerts
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{ paddingTop: 0 }}>
            <RenderAlert customer={customer} />
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
};

Alerts.fragments = {
  customer: gql`
    fragment AlertsFragment on Customer {
      _id
      merge_candidates {
        _id
      }
    }
  `,
};

export default Alerts;
