import React, { useState } from 'react';

import { useMutation, useQuery } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import gql from 'graphql-tag';

/* Material UI */
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  Dialog,
  Fab,
  FormControlLabel,
  FormGroup,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/styles';

import AddLeadSourceModal from './AddLeadSourceModal';
import AddSubStatusModal from './AddSubStatusModal';
import Loading from 'components/MaterialUI/Loading';
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import DealerPicker from 'components/MaterialUI/DealerPicker';
import BosSettings from './BosSettings';
import { useMediaQuery } from '@mui/material';

const UPDATE_LEAD_SETTING = gql`
  mutation updateLeadSetting($dealerId: Int!, $input: Boolean!) {
    updateLeadSetting(dealer_id: $dealerId, input: $input) {
      dealer_id
      lead_setting
    }
  }
`;

const CURRENT_DEALER_QRY = gql`
  query Dealer($dealerId: Int!) {
    dealer(dealer_id: $dealerId) {
      desking_settings {
        dealer_id
        sub_statuses
        lead_setting
        bos_settings_used {
          admin_fee
          anti_theft_transfer_fee
          extended_service_contract_fee
          fed_air_tax
          federal_luxury_tax
          protection_package_fee
          tire_protection_fee
          tire_recycling_fee
        }
        bos_settings_new {
          admin_fee
          anti_theft_transfer_fee
          extended_service_contract_fee
          fed_air_tax
          federal_luxury_tax
          protection_package_fee
          tire_protection_fee
          tire_recycling_fee
        }
      }
    }
  }
`;

const LEAD_SOURCES = gql`
  query organizationLeadSources {
    organizationLeadSources
  }
`;

const UPDATE_SUBSTATUSES = gql`
  mutation updateSubStatuses($dealer_id: Int!, $input: [String]!) {
    updateSubStatuses(dealer_id: $dealer_id, input: $input) {
      dealer_id
      sub_statuses
    }
  }
`;

const DELETE_LEAD_SOURCE = gql`
  mutation deleteFromOrganizationDeskingSetting(
    $setting_key: String!
    $value: JSON!
  ) {
    deleteFromOrganizationDeskingSetting(
      setting_key: $setting_key
      value: $value
    ) {
      lead_sources
    }
  }
`;

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '200px',
    height: '100%',
  },
  paginationRoot: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  paginationRootMobile: {
    minWidth: '60%',
  },
  pageTitle: {
    fontWeight: 'bold',
    fontSize: '24px',
    paddingBottom: '15px',
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    paddingLeft: '10px',
  },
  mobileTitle: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  card: {
    marginTop: '30px',
  },
  cardContent: {
    marginLeft: '20px',
  },
  mobileCardContent: {
    marginLeft: '10px',
  },
  table: {
    maxWidth: '80%',
    paddingBottom: '10px',
  },
  mobileTable: {
    maxWidth: '90%',
    paddingBottom: '5px',
  },
  tableHeader: {
    borderBottom: '1.5px solid black',
  },
}));

// *** PAGINATION STUFF ***

function TablePaginationActions(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const mobile = useMediaQuery(theme => theme.breakpoints.up('sm'));

  const handleFirstPageButtonClick = event => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = event => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = event => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div
      className={mobile ? classes.paginationRoot : classes.paginationRootMobile}
    >
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
        style={mobile ? { padding: '12px' } : { padding: '3px' }}
        size="large"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>

      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
        style={mobile ? { padding: '12px' } : { padding: '0' }}
        size="large"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>

      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
        style={mobile ? { padding: '12px' } : { padding: '3px' }}
        size="large"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>

      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
        style={mobile ? { padding: '12px' } : { padding: '3px' }}
        size="large"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

// *** END PAGINATION STUFF ***

const Opportunities = () => {
  const { dealerId } = useDealerContext();

  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();

  const mobile = useMediaQuery(theme => theme.breakpoints.up('sm'));

  const {
    loading: dealerLoading,
    data: dealerData,
    refetch: refetchDealer,
  } = useQuery(CURRENT_DEALER_QRY, {
    variables: { dealerId },
  });

  const {
    loading: sourceLoading,
    data: leadSourceData,
    refetch,
  } = useQuery(LEAD_SOURCES);

  const leadSources = !sourceLoading
    ? leadSourceData.organizationLeadSources.sort((a, b) => {
        return a.localeCompare(b, undefined, { sensitivity: 'base' });
      })
    : [];

  const [updateLeadSetting] = useMutation(UPDATE_LEAD_SETTING, {
    onCompleted: data => {
      enqueueSnackbar('Auto opportunity creation setting updated!', {
        variant: 'success',
      });
    },
    onError: err => {
      enqueueSnackbar(
        `Could not update auto opportunity creation setting, error: ${err}`,
        {
          variant: 'error',
        },
      );
    },
  });

  const dealerSettings = dealerData?.dealer.desking_settings;

  const subStatuses = dealerSettings?.sub_statuses;

  const usedBosSettings = dealerSettings?.bos_settings_used;
  const newBosSettings = dealerSettings?.bos_settings_new;

  const [removeSubStatus] = useMutation(UPDATE_SUBSTATUSES, {
    onCompleted: data => {
      refetch();
      enqueueSnackbar('Sub Status Removed!', { variant: 'success' });
    },
    onError: err => {
      enqueueSnackbar(`Could not remove sub status error: ${err}`, {
        variant: 'error',
      });
    },
  });

  const [removeLeadSource] = useMutation(DELETE_LEAD_SOURCE, {
    onCompleted: data => {
      refetch();
      enqueueSnackbar('Lead Source Removed!', { variant: 'success' });
    },
    onError: err => {
      enqueueSnackbar(`Could not remove lead source error: ${err}`, {
        variant: 'error',
      });
    },
  });

  const handleRemoveSubStatus = ({ dealerId, index }) => {
    if (subStatuses.length > 0 && subStatuses[index] !== undefined) {
      subStatuses.splice(index, 1);
      removeSubStatus({
        variables: { dealer_id: dealerId, input: subStatuses },
      });
    }
  };

  const handleRemoveLeadSource = leadSource => {
    if (leadSources.length > 0 && leadSource !== undefined) {
      removeLeadSource({
        variables: { setting_key: 'lead_sources', value: leadSource },
      });
    }
  };

  const handleUpdateLeadSetting = e => {
    updateLeadSetting({
      variables: { input: e.target.checked, dealerId },
    });
  };

  //   Shamelessly borrowed expand/collapse from MaterialUI!
  const ExpandMore = styled(props => {
    const { expand, ...other } = props;
    return <IconButton {...other} size="large" />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  // There is probably a more concise way to change each state
  const [expanded1, setExpanded1] = React.useState(false);
  const [expanded2, setExpanded2] = React.useState(false);
  const [expanded3, setExpanded3] = React.useState(false);

  const handleExpandClick1 = () => {
    setExpanded1(!expanded1);
  };

  const handleExpandClick2 = () => {
    setExpanded2(!expanded2);
  };

  const handleExpandClick3 = () => {
    setExpanded3(!expanded3);
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };

  const [showSubStatusModal, setShowSubStatusModal] = useState(false);
  const [showLeadSourceModal, setShowLeadSourceModal] = useState(false);

  const handleSubStatusModalClick = () => {
    setShowSubStatusModal(prevShowModal => !prevShowModal);
  };

  const handleLeadSourceModalClick = () => {
    setShowLeadSourceModal(prevShowModal => !prevShowModal);
  };

  if (dealerLoading || sourceLoading) {
    return <Loading />;
  }

  return (
    <Box height="100%">
      <Box padding={1}>
        <DealerPicker />
      </Box>
      <Box
        padding={2}
        sx={
          mobile
            ? { minWidth: '600px', maxWidth: '80%' }
            : { minWidth: '450px', maxWidth: '90%' }
        }
      >
        <Typography className={classes.pageTitle}>Opportunities</Typography>
        <Box>
          {/* **** LEAD SETTING TOGGLE **** */}
          <FormGroup row>
            <FormControlLabel
              value="start"
              control={
                <Switch
                  checked={dealerSettings.lead_setting}
                  onChange={handleUpdateLeadSetting}
                  color="secondary"
                />
              }
              label={
                mobile ? (
                  <Typography>
                    Enable Auto Opportunity Creation for Leads
                  </Typography>
                ) : (
                  <Typography style={{ fontSize: '0.875rem' }}>
                    Enable Auto Opportunity Creation for Leads
                  </Typography>
                )
              }
              labelPlacement="start"
            />
          </FormGroup>
        </Box>
        {/* **** CONFIGURE SUB STATUS CARD **** */}
        <Card className={classes.card}>
          <CardHeader
            title="Configure Sub Status"
            style={{ display: 'none' }}
          />
          <CardActions disableSpacing>
            <ExpandMore
              expand={expanded1}
              onClick={handleExpandClick1}
              aria-expanded={expanded1}
              aria-label="show more"
              style={{ margin: 0 }}
            >
              <ExpandMoreIcon />
            </ExpandMore>
            <Typography
              className={mobile ? classes.title : classes.mobileTitle}
            >
              Configure Sub Status
            </Typography>
          </CardActions>
          <Collapse in={expanded1} timeout="auto" unmountOnExit>
            <CardContent
              className={
                mobile ? classes.cardContent : classes.mobileCardContent
              }
            >
              <Dialog
                maxWidth="sm"
                open={showSubStatusModal}
                onClose={() => {
                  setShowSubStatusModal(false);
                }}
              >
                <AddSubStatusModal
                  handleClose={() => {
                    setShowSubStatusModal(false);
                    refetch();
                  }}
                  refetch={refetchDealer}
                  subStatuses={subStatuses}
                />
              </Dialog>
              <Button
                variant="contained"
                onClick={handleSubStatusModalClick}
                style={{
                  backgroundColor: '#5bc236',
                  color: 'white',
                  marginBottom: '10px',
                }}
              >
                Add Sub Status
              </Button>
              <TableContainer style={{ paddingBottom: '20px' }}>
                <Table className={mobile ? classes.table : classes.mobileTable}>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableHeader}>
                        Sub Status
                      </TableCell>
                      <TableCell className={classes.tableHeader} align="right">
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {subStatuses?.map((subStatus, index) => (
                      <TableRow key={index}>
                        <TableCell>{subStatus}</TableCell>
                        <TableCell align="right">
                          <Fab
                            size="small"
                            style={{
                              backgroundColor: 'red',
                              color: 'white',
                              maxWidth: `${mobile ? '100%' : '35px'}`,
                              maxHeight: `${mobile ? '100%' : '30px'}`,
                            }}
                            onClick={() =>
                              handleRemoveSubStatus({ dealerId, index })
                            }
                          >
                            <DeleteIcon
                              style={
                                mobile
                                  ? { maxHeight: '100%', maxWidth: '100%' }
                                  : { maxHeight: '60%', maxWidth: '60%' }
                              }
                            />
                          </Fab>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Collapse>
        </Card>
        {/* **** CONFIGURE LEAD SOURCES CARD **** */}
        <Card className={classes.card}>
          <CardHeader
            title="Configure Lead Sources"
            style={{ display: 'none' }}
          />
          <CardActions disableSpacing>
            <ExpandMore
              expand={expanded2}
              onClick={handleExpandClick2}
              aria-expanded={expanded2}
              aria-label="show more"
              style={{ margin: 0 }}
            >
              <ExpandMoreIcon />
            </ExpandMore>
            <Typography
              className={mobile ? classes.title : classes.mobileTitle}
            >
              Configure Lead Sources
            </Typography>
          </CardActions>
          <Collapse in={expanded2} timeout="auto" unmountOnExit>
            <CardContent
              className={
                mobile ? classes.cardContent : classes.mobileCardContent
              }
            >
              <Dialog
                maxWidth="sm"
                open={showLeadSourceModal}
                onClose={() => {
                  setShowLeadSourceModal(false);
                }}
              >
                <AddLeadSourceModal
                  handleClose={() => {
                    setShowLeadSourceModal(false);
                  }}
                  refetch={refetch}
                  leadSources={leadSources}
                />
              </Dialog>
              <Button
                variant="contained"
                onClick={handleLeadSourceModalClick}
                style={{
                  backgroundColor: '#5bc236',
                  color: 'white',
                  marginBottom: '10px',
                }}
              >
                Add Lead Source
              </Button>
              <TableContainer style={{ paddingBottom: '20px' }}>
                <Table className={mobile ? classes.table : classes.mobileTable}>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableHeader}>
                        Lead Source
                      </TableCell>
                      <TableCell className={classes.tableHeader} align="right">
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {leadSources
                      ?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage,
                      )
                      .map((leadSource, index) => (
                        <TableRow key={index}>
                          <TableCell>{leadSource}</TableCell>
                          <TableCell align="right">
                            <Fab
                              size={'small'}
                              style={{
                                backgroundColor: 'red',
                                color: 'white',
                                maxWidth: `${mobile ? '100%' : '35px'}`,
                                maxHeight: `${mobile ? '100%' : '30px'}`,
                              }}
                              onClick={() => handleRemoveLeadSource(leadSource)}
                            >
                              <DeleteIcon
                                style={
                                  mobile
                                    ? { maxHeight: '100%', maxWidth: '100%' }
                                    : { maxHeight: '60%', maxWidth: '60%' }
                                }
                              />
                            </Fab>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        count={leadSources?.length}
                        rowsPerPageOptions={[]}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </CardContent>
          </Collapse>
        </Card>
        {/* **** BILL OF SALE SETTINGS CARD **** */}
        <Card className={classes.card}>
          <CardHeader
            title="Bill of Sale Settings"
            style={{ display: 'none' }}
          />
          <CardActions disableSpacing>
            <ExpandMore
              expand={expanded3}
              onClick={handleExpandClick3}
              aria-expanded={expanded3}
              aria-label="show more"
              style={{ margin: 0 }}
            >
              <ExpandMoreIcon />
            </ExpandMore>
            <Typography
              className={mobile ? classes.title : classes.mobileTitle}
            >
              Bill of Sale Settings
            </Typography>
          </CardActions>
          <Collapse in={expanded3} timeout="auto" unmountOnExit>
            <CardContent
              className={
                mobile ? classes.cardContent : classes.mobileCardContent
              }
            >
              <BosSettings
                used_bos_settings={usedBosSettings}
                new_bos_settings={newBosSettings}
                refetch={refetchDealer}
              />
            </CardContent>
          </Collapse>
        </Card>
      </Box>
    </Box>
  );
};

export default Opportunities;
