import React from 'react';

/* Material UI */
import {
  DialogTitle,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';

const AddBulletinContent = ({ bulletinOptions, type, handleType, desktop }) => {
  return (
    <>
      <DialogTitle>Add Customer Bulletin</DialogTitle>
      <DialogContent>
        <FormControl
          required
          style={{ width: { desktop } ? '50%' : '100%', marginTop: '10px' }}
        >
          <InputLabel>Department</InputLabel>
          <Select value={type} onChange={handleType} label="Department">
            {bulletinOptions.map(({ value, text }) => (
              <MenuItem key={value} value={value}>
                {text}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
    </>
  );
};

export default AddBulletinContent;
