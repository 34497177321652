import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';

import useMediaQuery from '@mui/material/useMediaQuery';
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import DealerPicker from 'components/MaterialUI/DealerPicker';
import TextFieldControl from 'components/MaterialUI/TextFieldControl';
import SelectControl from 'components/MaterialUI/SelectControl2';
import { useUserContext } from 'components/MaterialUI/UserContext';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';

import Loading from 'components/MaterialUI/Loading';

const DATE_CHOICES = [
  { value: 'days', label: 'Days' },
  { value: 'months', label: 'Months' },
  { value: 'years', label: 'Years' },
];

const TASK_TYPES = [
  { value: 'other', label: 'Other' },
  { value: 'call', label: 'Call' },
  { value: 'email', label: 'Email' },
  { value: 'opportunity', label: 'Opportunity' },
];

const USERS_FOR_DEALER = gql`
  query UsersForDealer($dealerIds: [Int]!) {
    users: users(dealer_ids: $dealerIds, status: active) {
      display_name
      username
      role
    }
  }
`;

const GET_TEMPLATE = gql`
  query GetTaskTemplate($id: ID!) {
    getTaskTemplate(id: $id) {
      _id
      assignee_from
      organization_id
      dealer_id
      label
      trigger
      task_description
      task_type
      date_offset
      create_in_series
      link_to_opportunity
      task_recurrence {
        frequency_units
        frequency
        duration_units
        duration
      }
    }
  }
`;

const ADD_TEMPLATE = gql`
  mutation AddTaskTemplate($input: TaskTemplateInput!) {
    createTaskTemplate(input: $input) {
      _id
    }
  }
`;

const UPDATE_TEMPLATE = gql`
  mutation UpdateTaskTemplate($id: ID!, $input: TaskTemplateInput!) {
    updateTaskTemplate(id: $id, input: $input) {
      _id
    }
  }
`;

const TASK_TRIGGERS = gql`
  query TaskTriggers($id: ID) {
    taskTriggers(id: $id) {
      count
      task_triggers {
        value
        label
        assignee_options {
          value
          label
        }
      }
    }
  }
`;

const AddTaskTemplate = ({ taskId }) => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const history = useHistory();
  const { currentUser } = useUserContext();
  const { dealerId } = useDealerContext();
  const isDesktop = useMediaQuery(theme => theme.breakpoints.up('sm'));

  let defaultValues = {
    label: '',
    trigger: '',
    date_offset: '',
    assignee_from: '',
    task_type: '',
    task_description: '',
    link_to_opportunity: false,
    task_recurrence: {
      frequency: 1,
      frequency_units: null,
      duration: 1,
      duration_units: null,
    },
    create_in_series: false,
  };

  const {
    control,
    handleSubmit,
    register,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues,
    shouldUnregister: true,
  });

  const selectedTaskTrigger = watch('trigger');

  if (!taskId) {
    register('date_offset_value');
    register('date_offset_units');
  }

  const revertFromISODate = date_offset => {
    const years = date_offset.get('years');
    const months = date_offset.get('months');
    const days = date_offset.get('days');

    if (years) {
      return { date_offset_value: years, date_offset_units: 'years' };
    } else if (months) {
      return { date_offset_value: months, date_offset_units: 'months' };
    } else if (days) {
      return { date_offset_value: days, date_offset_units: 'days' };
    }
  };

  const { data: users } = useQuery(USERS_FOR_DEALER, {
    variables: { dealerIds: [dealerId] },
  });

  const loadedUsers = users?.users || [];
  const sortedUsers = loadedUsers.sort((a, b) =>
    a.display_name.localeCompare(b.display_name),
  );

  let userRules = [];
  sortedUsers.forEach(({ display_name, role, username }) =>
    userRules.push({
      label: display_name,
      value: 'dealer.users.' + username,
    }),
  );

  const { loading: templateLoading } = useQuery(GET_TEMPLATE, {
    variables: { id: taskId },
    skip: !taskId,
    onCompleted: res => {
      // even when we skip, this for some reason gets evaluated, so skip it again if no taskId.
      if (taskId) {
        register('date_offset_value');
        register('date_offset_units');

        const taskTemplate = res?.getTaskTemplate || {};

        const {
          date_offset,
          link_to_opportunity,
          create_in_series,
          _id,
          ...template
        } = taskTemplate;

        const { date_offset_value, date_offset_units } = revertFromISODate(
          moment.duration(date_offset),
        );

        const value = {
          date_offset_value,
          date_offset_units,
          create_in_series: create_in_series ? 'true' : 'false',
          link_to_opportunity: link_to_opportunity ? 'true' : 'false',
          ...template,
        };
        reset(value);
      }
    },
  });

  const { data: triggers } = useQuery(TASK_TRIGGERS, {
    variables: { id: null },
  });

  const [updateTemplate] = useMutation(UPDATE_TEMPLATE, {
    onCompleted: () => {
      enqueueSnackbar('Successfully updated Task Template!', {
        variant: 'success',
      });
      history.push('/tasks/templates');
    },
    onError: e => enqueueSnackbar(e, { variant: 'error' }),
  });

  const [addTemplate] = useMutation(ADD_TEMPLATE, {
    onCompleted: () => {
      enqueueSnackbar('Success created Task Template!', { variant: 'success' });
      history.push('/tasks/templates');
    },
    onError: e => enqueueSnackbar(e, { variant: 'error' }),
  });

  const taskTriggers = triggers?.taskTriggers?.task_triggers || [];

  const onSubmit = data => {
    const {
      date_offset_value,
      date_offset_units,
      task_recurrence,
      link_to_opportunity,
      create_in_series,
      ...args
    } = data;
    const { duration, frequency, ...rest } = task_recurrence;
    const value = {
      date_offset: moment
        .duration(date_offset_value, date_offset_units)
        .toISOString(),
      task_recurrence: {
        duration: parseInt(duration, 10),
        frequency: parseInt(frequency, 10),
        ...rest,
      },
      creator: currentUser.username,
      dealer_id: dealerId,
      organization_id: currentUser.organization_id,
      create_in_series: create_in_series === 'true',
      link_to_opportunity: link_to_opportunity === 'true',
      ...args,
    };
    if (!taskId) {
      addTemplate({
        variables: {
          input: value,
        },
      });
    } else if (taskId) {
      updateTemplate({
        variables: {
          id: taskId,
          input: value,
        },
      });
    }
  };

  const [optionVal, setOptionVal] = useState([]);
  useEffect(() => {
    if (selectedTaskTrigger) {
      setOptionVal(
        taskTriggers
          ?.filter(x => x.value === selectedTaskTrigger)[0]
          ?.assignee_options?.concat(userRules)
          ?.map(x => ({
            name: x.label,
            value: x.value,
          })) || [],
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTaskTrigger, taskTriggers]);

  if (templateLoading) return <Loading text="Loading Task Template..." />;

  return (
    <Box style={{ padding: '1rem' }}>
      <Box style={{ paddingLeft: '1rem' }}>
        <DealerPicker />
      </Box>
      {Object.keys(errors).length > 0 && (
        <Box style={{ paddingTop: '1rem' }}>
          <Alert severity="error">{JSON.stringify(errors)}</Alert>
        </Box>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box style={{ padding: '1rem' }}>
          <Typography variant="h6">New Task Template Rule</Typography>
        </Box>
        <Box style={{ padding: '1rem' }}>
          <Typography variant="body2">
            Give this task template a name.
          </Typography>
          <Box style={{ paddingTop: '1rem' }}>
            <TextFieldControl
              label="Rule Name"
              name="label"
              control={control}
              style={{ width: isDesktop ? 550 : '100%' }}
              muiVariant="outlined"
            />
          </Box>
        </Box>
        <Box style={{ padding: '1rem' }}>
          <Grid container direction="row">
            <Grid item xs={12} md={4}>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Typography variant="body2" style={{ paddingBottom: '1rem' }}>
                  Select an event that will trigger this task to be created.
                </Typography>
                <SelectControl
                  control={control}
                  defaultValue=""
                  name="trigger"
                  label="Trigger"
                  noNull
                  required
                  rules={{ required: 'Required' }}
                  options={taskTriggers}
                  optionNameKey="label"
                  style={{
                    paddingRight: '1rem',
                    width: 300,
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Typography variant="body2" style={{ paddingBottom: '1rem' }}>
                  Who should this task be assigned to?
                </Typography>
                <SelectControl
                  control={control}
                  defaultValue=""
                  name="assignee_from"
                  label="Assign to"
                  noNull
                  required
                  rules={{ required: 'Required' }}
                  options={optionVal}
                  style={{ paddingRight: '1rem', width: 300 }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box style={{ padding: '1rem' }}>
          <Typography variant="body2" style={{ paddingBottom: '1rem' }}>
            Task Type
          </Typography>
          <Controller
            name={'link_to_opportunity'}
            control={control}
            render={({ field: { ref, value, onChange, ...field } }) => (
              <RadioGroup value={value} onChange={onChange}>
                <FormControlLabel
                  value={'true'}
                  label={
                    'Create Task on the opportunity (task will be closed when the opportunity is lost, tubed or posted.)'
                  }
                  control={<Radio color="secondary" {...field} />}
                />
                <FormControlLabel
                  value={'false'}
                  label={
                    'Create Task on customer (task will remain open when the opportunity is lost, tubed, or posted.)'
                  }
                  control={<Radio color="secondary" {...field} />}
                />
              </RadioGroup>
            )}
          />
        </Box>
        <Box style={{ padding: '1rem' }}>
          <Box style={{ paddingBottom: '1rem' }}>
            <Typography variant="body2" style={{ paddingBottom: '1rem' }}>
              Due Date for the Task
            </Typography>
          </Box>
          <Grid container direction="column">
            <Grid item>
              <TextFieldControl
                name="date_offset_value"
                control={control}
                type="number"
                style={{ width: 100, paddingRight: '1rem' }}
                muiVariant="outlined"
              />
              <SelectControl
                control={control}
                defaultValue=""
                name="date_offset_units"
                noNull
                required
                rules={{ required: 'Required' }}
                options={DATE_CHOICES}
                optionNameKey="label"
                style={{ paddingRight: '1rem', width: 300 }}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </Box>
        <Box style={{ padding: '1rem' }}>
          <Box style={{ paddingBottom: '1rem' }}>
            <Typography variant="body2">Repeat Task</Typography>
          </Box>
          <Grid container direction="row">
            <Grid item>
              <TextFieldControl
                name="task_recurrence.frequency"
                control={control}
                type="number"
                style={{ width: 100, paddingRight: '1rem' }}
                muiVariant="outlined"
              />
            </Grid>
            <Grid item>
              <SelectControl
                control={control}
                defaultValue=""
                name="task_recurrence.frequency_units"
                noNull
                required
                rules={{ required: 'Required' }}
                options={DATE_CHOICES}
                optionNameKey="label"
                style={{ paddingRight: '1rem', width: 300 }}
                variant="outlined"
              />
            </Grid>
            <Grid item style={{ display: 'flex', paddingRight: '1rem' }}>
              <Box
                style={{
                  display: 'flex',
                  marginTop: 'auto',
                  marginBottom: 'auto',
                }}
              >
                <Typography variant="body2">For</Typography>
              </Box>
            </Grid>
            <Grid item>
              <TextFieldControl
                name="task_recurrence.duration"
                control={control}
                type="number"
                style={{ width: 100, paddingRight: '1rem' }}
                muiVariant="outlined"
              />
            </Grid>
            <Grid item>
              <SelectControl
                control={control}
                defaultValue=""
                name="task_recurrence.duration_units"
                noNull
                required
                rules={{ required: 'Required' }}
                options={DATE_CHOICES}
                optionNameKey="label"
                style={{ paddingRight: '1rem', width: 300 }}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </Box>
        <Box style={{ padding: '1rem' }}>
          <Controller
            name={'create_in_series'}
            control={control}
            render={({ field: { ref, value, onChange, ...field } }) => (
              <RadioGroup value={value} onChange={onChange}>
                <FormControlLabel
                  value={'true'}
                  label={'Create all tasks in series.'}
                  control={<Radio color="secondary" {...field} />}
                />
                <FormControlLabel
                  value={'false'}
                  label={
                    'Only create the next task when the previous one is completed.'
                  }
                  control={<Radio color="secondary" {...field} />}
                />
              </RadioGroup>
            )}
          />
        </Box>
        <Box
          style={{ display: 'flex', flexDirection: 'column', padding: '1rem' }}
        >
          <Typography variant="body2">
            What type of activity does this task represent?
          </Typography>
          <SelectControl
            control={control}
            defaultValue=""
            name="task_type"
            label="Trigger"
            noNull
            required
            rules={{ required: 'Required' }}
            options={TASK_TYPES}
            optionNameKey="label"
            style={{
              paddingRight: '1rem',
              width: 300,
              marginTop: '1rem',
            }}
          />
        </Box>
        <Box style={{ padding: '1rem' }}>
          <Typography variant="body2">
            What is the task? (ie. "Schedule a time for a trade-in appraisal")
          </Typography>
          <Box>
            <TextFieldControl
              name="task_description"
              label="Task Description"
              control={control}
              style={{ width: isDesktop ? 550 : '100%', marginTop: '1rem' }}
              muiVariant="outlined"
            />
          </Box>
        </Box>
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
          <Box
            style={{
              display: 'flex',
              marginLeft: isDesktop ? 650 : '50%',
              marginRight: 'auto',
            }}
          >
            <Box style={{ paddingRight: '1rem' }}>
              <Button
                onClick={() => history.goBack()}
                variant="contained"
                color="default"
              >
                Cancel
              </Button>
            </Box>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              style={theme.actions.confirm}
            >
              {taskId ? 'Update' : 'Create'}
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default AddTaskTemplate;
