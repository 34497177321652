import React from 'react';

import { useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import PrintIcon from '@mui/icons-material/Print';
import FormatDateTZ from 'components/MaterialUI/FormatDateTZ';

const InspectionTiles = ({ value, handlePrint, printable }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  });

  return (
    <div style={{ marginBottom: '1rem' }}>
      <b style={{ textTransform: 'uppercase' }}>{value.data.form_type}</b>
      <p>
        <b>Completed:</b>
        <FormatDateTZ
          paddingLeft={1}
          date={value.created_at}
          format="MMM D YYYY @ h:mm a"
          style={{ marginRight: '2rem' }}
        />
        <b>By: </b>
        <span style={{ marginRight: '2rem' }}>{value.user.display_name}</span>
        {isMobile && <br />}
        <Button
          size="small"
          variant="contained"
          startIcon={<PrintIcon />}
          onClick={handlePrint}
          value={value.id}
          disabled={!printable}
          style={isMobile ? { marginTop: '15px' } : {}}
        >
          Print
        </Button>
      </p>
    </div>
  );
};
export default InspectionTiles;
