// External
import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import { Link, useHistory } from 'react-router-dom';

// Material UI
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';

import { useDealerContext } from 'components/MaterialUI/DealerContext';
import ConfirmDialog from 'components/MaterialUI/ConfirmDialog';
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';
import { debounce } from 'underscore';

const ARCHIVE_LEAD = gql`
  mutation archiveLead($leadId: ID!) {
    archiveLead(_id: $leadId) {
      _id
      is_archived
      archived
    }
  }
`;

const JUNK_LEAD = gql`
  mutation junkLead($leadId: ID!, $isJunk: Boolean!) {
    junkLead(_id: $leadId, is_junk: $isJunk) {
      _id
      is_junk
    }
  }
`;

const CREATE_OPPORTUNITY_FOR_LEAD = gql`
  mutation createOpportunityForLead($leadId: String!, $dealerId: Int!) {
    createOpportunityForLead(leadId: $leadId, input: { dealer_id: $dealerId }) {
      _id
      customer_id
    }
  }
`;

const useStyles = makeStyles(theme => ({
  action: {
    '& > *': {
      margin: theme.spacing(0.5),
    },
    '& a': {
      textDecoration: 'none',
    },
    '& button': {
      padding: '6px 4px',
      minWidth: 'unset',
      backgroundColor: '#2196f3',
    },
  },
}));

const LeadActions = ({ row, handleOpenAssignLead }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [confirmDialog, setConfirmDialog] = useState(false);
  const classes = useStyles();
  const [archiveLead] = useMutation(ARCHIVE_LEAD, {
    onCompleted: () => {
      enqueueSnackbar(`Lead successfully archived`, {
        variant: 'success',
      });
    },
    onError: () =>
      enqueueSnackbar(`Error when archiving lead`, {
        variant: 'error',
      }),
  });

  const onArchive = id => {
    archiveLead({
      variables: {
        leadId: id,
      },
    });
  };

  const [junkLead] = useMutation(JUNK_LEAD, {
    onCompleted: () => {
      enqueueSnackbar(`Lead successfully updated`, {
        variant: 'success',
      });
    },
    onError: () =>
      enqueueSnackbar(`Error when marking lead`, {
        variant: 'error',
      }),
  });

  const junkOrNotJunk = (id, isJunk) => {
    junkLead({
      variables: {
        leadId: id,
        isJunk,
      },
    });
  };

  const getOpportunityId = (id, customer) => {
    let opportunity_id = '';
    customer.opportunities.forEach(i => {
      if (i.crm_lead_ids.indexOf(id) > -1) {
        opportunity_id = i._id;
      }
    });

    return opportunity_id;
  };

  const { dealerId } = useDealerContext();
  const history = useHistory();
  const [createOpportunity, { loading }] = useMutation(
    CREATE_OPPORTUNITY_FOR_LEAD,
    {
      onCompleted: data => {
        const { _id, customer_id } = data.createOpportunityForLead;
        history.push(`/opportunities/${_id}/${customer_id}/details`);
        enqueueSnackbar(`Opportunity successfully created`, {
          variant: 'success',
        });
      },
      onError: err => {
        enqueueSnackbar(`Error when creating opportunity`, {
          variant: 'error',
        });
        if (err.message.includes('Lead is already assigned')) {
          setConfirmDialog(true);
        }
      },
    },
  );

  const startOpportunity = debounce(id => {
    createOpportunity({
      variables: {
        leadId: id,
        dealerId: dealerId,
      },
    });
  }, 1000);

  const refreshPage = () => {
    window.location.reload(false);
  };

  return (
    <Box className={classes.action}>
      <LoadingBackdrop open={loading}>Creating Opportunity...</LoadingBackdrop>
      <ConfirmDialog
        text={`This lead has already been claimed. Please, refresh your browser page.`}
        abortText="Close"
        confirmText="Refresh"
        onConfirm={refreshPage}
        isOpen={confirmDialog}
        onClose={() => setConfirmDialog(false)}
      />
      {row.customer && (
        <Tooltip title="Customer Profile">
          <Link to={`/customers/${row.customer._id}/details`}>
            <Button variant="contained" color="primary">
              <i className="fas fa-user fa-fw" />
            </Button>
          </Link>
        </Tooltip>
      )}
      {((!row.tags.includes('opportunity') &&
        !row.form_data.lead_type.includes('sale') &&
        !row.is_archived) ||
        (!row.tags.includes('opportunity') &&
          row.form_data.lead_type === 'contest')) && (
        <Tooltip title="Start Opportunity">
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              startOpportunity(row._id);
            }}
          >
            <i className="fas fa-thumbs-up fa-fw" />
          </Button>
        </Tooltip>
      )}
      {row.tags.includes('opportunity') && row?.customer && (
        <Tooltip title="View Opportunity">
          <Link
            to={`/opportunities/${getOpportunityId(row._id, row.customer)}/${
              row.customer._id
            }/details`}
          >
            <Button variant="contained" color="primary">
              <i className="fas fa-thumbs-up fa-fw" />
            </Button>
          </Link>
        </Tooltip>
      )}
      <Link to={`/leads/${row._id}`}>
        <Tooltip title="Lead Details">
          <Button variant="contained" color="primary">
            <i className="fas fa-info-circle fa-fw" />
          </Button>
        </Tooltip>
      </Link>
      {!row.tags.includes('opportunity') && (
        <Tooltip title="Assign Lead">
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleOpenAssignLead(row._id)}
          >
            <i className="fas fa-user-plus fa-fw" />
          </Button>
        </Tooltip>
      )}
      {!row.is_archived && (
        <Tooltip title="Archive Lead">
          <Button
            variant="contained"
            color="primary"
            onClick={() => onArchive(row._id)}
          >
            <i className="fas fa-folder fa-fw" />
          </Button>
        </Tooltip>
      )}
      {!row.is_junk && (
        <Tooltip title="Mark as Junk">
          <Button
            variant="contained"
            color="primary"
            onClick={() => junkOrNotJunk(row._id, true)}
          >
            <i className="fas fa-exclamation fa-fw" />
          </Button>
        </Tooltip>
      )}
      {row.is_junk && (
        <Tooltip title="Mark as Not Junk">
          <Button
            variant="contained"
            color="primary"
            onClick={() => junkOrNotJunk(row._id, false)}
          >
            <i className="fas fa-exclamation-circle fa-fw" />
          </Button>
        </Tooltip>
      )}
    </Box>
  );
};

export default LeadActions;
