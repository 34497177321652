import React from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const useStyles = makeStyles(theme => ({
  container: {
    [theme.breakpoints.up('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      width: 'fit-content',
    },
    borderRadius: '18px',
    borderColor: '#4da85a',
    borderStyle: 'solid',
    borderWidth: '1px',
    color: '#4da85a',
    backgroundColor: 'white',
    padding: '3px 2px',
    marginTop: '5px',
  },
}));

const ReconCompleteIndicator = () => {
  const styles = useStyles();

  return (
    <Box
      className={styles.container}
      display="flex"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
    >
      <CheckCircleIcon fontSize="small" />
      <span>Recon</span>
    </Box>
  );
};

export default ReconCompleteIndicator;
