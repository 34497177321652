import React from 'react';

/* external */
import gql from 'graphql-tag';

/* Material UI */
import TableCell from '@mui/material/TableCell';

/* internal */
import {
  Countdown,
  useTimeSyncContext,
} from 'components/MaterialUI/TimeSyncContext';
import FormatDateTZ from 'components/MaterialUI/FormatDateTZ';

const ItemStartTimeCell = ({
  auction,
  item,
  countdown,
  format = 'MMM D Y @ h:mm A',
}) => {
  const { isElapsed } = useTimeSyncContext();
  const { isUpcoming, startsAt } = item;
  const { isWeekly, weekly } = auction ?? {};
  const { nextBlind } = weekly ?? {};

  const showBlind = isWeekly && isUpcoming && !isElapsed(nextBlind);

  return (
    <TableCell>
      <FormatDateTZ date={startsAt} format={format} />
      {countdown && (
        <>
          <br />
          {showBlind ? (
            <Countdown
              component="span"
              elapsedDisplay=""
              endTime={nextBlind}
              runningPrefix="Blind bidding in"
            />
          ) : (
            <Countdown
              component="span"
              elapsedDisplay="Already Started"
              endTime={startsAt}
              runningPrefix="Starts in"
            />
          )}
        </>
      )}
    </TableCell>
  );
};

ItemStartTimeCell.fragments = {
  auctionItem: gql`
    fragment ItemStartTimeCellAuctionItem on AuctionItem {
      startsAt
      isUpcoming
    }
  `,
  auction: gql`
    fragment ItemStartTimeCellAuction on Auction {
      isWeekly
      weekly {
        id
        nextBlind
      }
    }
  `,
};

export default ItemStartTimeCell;
