import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';

import { Box, Typography } from '@mui/material';
import { Alert } from '@mui/material';

import { useDealerContext } from 'components/MaterialUI/DealerContext';
import ErrorDisplay from 'components/MaterialUI/ErrorDisplay';
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';
import { useUserContext } from 'components/MaterialUI/UserContext';

import UsedPricingToolVehicles from './UsedPricingToolVehicles';
import SearchCriteria from './SearchCriteria';
import ListingStats from './listing_stats/ListingStats';
import ListingStatsCard from './listing_stats/ListingStatsCard';
import ScatterChart from './listing_stats/charts/ScatterChart';
import BellCurveChart from './listing_stats/charts/BellCurveChart';
import VehicleRank from './listing_stats/VehicleRank';
import { usePersistedState } from 'utils';

const LISTINGS_QUERY = gql`
  query listings(
    $vin: String
    $postalCode: String
    $listingType: String
    $distance: Int
    $allTrims: Boolean
    $maxOdometer: Int
    $minOdometer: Int
    $orderBy: SortConditionInputType
    $organizationId: String!
  ) {
    listings(
      vin: $vin
      postal_code: $postalCode
      listing_type: $listingType
      radius_kilometres: $distance
      all_trims: $allTrims
      minimum_kilometres: $minOdometer
      maximum_kilometres: $maxOdometer
      order_by: [$orderBy]
      listings_per_page: 500
      page_number: 1
    ) {
      effective_parameters {
        page_number
      }
      page_count
      record_count
      listings {
        ...UsedPricingToolVehiclesListingsResult
        ...BellCurveChartListingsResult
        ...ScatterChartListingsResult
        ...ListingStatsListingsResult
        ...VehicleRankListingsResult
      }
    }
    listingsstatistics(
      vin: $vin
      postal_code: $postalCode
      radius_kilometres: $distance
      all_trims: $allTrims
      maximum_kilometres: $maxOdometer
    ) {
      listings_statistics {
        ...ListingStatsListingStatistics
        ...ListingStatsCardListingStatistics
      }
    }
    organization(organization_id: $organizationId) {
      id
      ...UsedPricingToolVehiclesOrganization
    }
  }
  ${UsedPricingToolVehicles.fragments.listings}
  ${UsedPricingToolVehicles.fragments.organization}
  ${BellCurveChart.fragments.listings}
  ${ScatterChart.fragments.listings}
  ${ListingStats.fragments.listingStatistics}
  ${ListingStats.fragments.listings}
  ${ListingStatsCard.fragments.listingStatistics}
  ${VehicleRank.fragments.listings}
`;

const UsedPricingTool = ({ open, vehicle }) => {
  const { vin } = vehicle;
  const { currentUser } = useUserContext();
  const {
    greaseDealer: { name: dealerName, postal_code: postalCode },
  } = useDealerContext();

  const [searchCriteria, setSearchCriteria] = usePersistedState(
    'used-pricing-tool-search-criteria',
    {
      distance: 2000,
      minOdometer: 0,
      maxOdometer: 300000,
      listingType: 'both',
      allTrims: true,
    },
  );

  const [orderBy, setOrderBy] = useState({ column: 'PRICE', direction: 'ASC' });

  const clickSortHandler = selColumn => () =>
    setOrderBy(({ column, direction }) => ({
      column: selColumn,
      direction: selColumn === column && direction === 'ASC' ? 'DESC' : 'ASC',
    }));

  const { data, error, loading } = useQuery(LISTINGS_QUERY, {
    variables: {
      vin,
      postalCode,
      ...searchCriteria,
      orderBy,
      pageNumber: 1,
      organizationId: currentUser?.organization_id,
    },
    skip: !open || !vin || isEmpty(postalCode) || !currentUser?.organization_id,
    notifyOnNetworkStatusChange: true,
  });

  const { listings = [], record_count: recordCount } = data?.listings ?? {};

  return (
    <Box style={{ width: '100%' }}>
      <LoadingBackdrop open={loading}>Loading...</LoadingBackdrop>
      <ErrorDisplay error={error} />
      {isEmpty(postalCode) ? (
        <Alert severity="error" variant="filled">
          No postal code set for dealer {dealerName} - could not retrieve
          listings.
        </Alert>
      ) : (
        !loading && (
          <>
            <Typography variant="h6" style={{ fontWeight: 'bold' }}>
              Vehicle Comparison Tool
            </Typography>
            <SearchCriteria
              searchCriteria={searchCriteria}
              setSearchCriteria={setSearchCriteria}
            />
            {listings?.length ? (
              <>
                <ListingStats
                  data={data}
                  searchFilters={searchCriteria}
                  vehicle={vehicle}
                />
                <UsedPricingToolVehicles
                  orderBy={orderBy}
                  clickSortHandler={clickSortHandler}
                  listings={listings}
                  recordCount={recordCount}
                  organization={data?.organization || {}}
                  myVin={vin}
                />
              </>
            ) : (
              <Typography variant="h6" style={{ textAlign: 'center' }}>
                No similar vehicles found. Please adjust your search filters.
              </Typography>
            )}
          </>
        )
      )}
    </Box>
  );
};

UsedPricingTool.fragments = {
  vehicle: gql`
    fragment UsedPricingToolVehicle on GreaseInventoryVehicle {
      ...ScatterChartVehicle
      ...ListingStatsVehicle
      ...ListingStatsCardVehicle
      ...VehicleRankVehicle
    }
    ${ScatterChart.fragments.vehicle}
    ${ListingStats.fragments.vehicle}
    ${ListingStatsCard.fragments.vehicle}
    ${VehicleRank.fragments.vehicle}
  `,
};

export default UsedPricingTool;
