import React, { useEffect } from 'react';

/* external */
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import { useForm, useWatch, Controller } from 'react-hook-form';

/* Material UI */
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

/* internal */
import Loading from 'components/MaterialUI/Loading';
import moment from 'moment';
import SelectControl2 from 'components/MaterialUI/SelectControl2';
import TextFieldControl from 'components/MaterialUI/TextFieldControl';
import { DatePicker } from '@mui/x-date-pickers';

const RESCHEDULE_PAYMENTS = gql`
  mutation ReschedulePayments($id: ID!, $input: ReschedulePaymentInput!) {
    concierge {
      reschedulePayments(id: $id, input: $input) {
        id
        paymentFrequency
        payments {
          id
          status
          amount
          scheduledDate
        }
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  textField: { width: '250px' },
  hiddenScroll: { overflow: 'hidden' },
  info: { marginBottom: '2em' },
}));

const ReschedulePaymentsModal = ({ goldCard, isOpen, onClose, refetch }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const formMethods = useForm({
    defaultValues: {
      paymentFrequency: '',
      startDate: moment().format('YYYY-MM-DD'),
      numberOfDays: 0,
    },
  });
  const { control, handleSubmit, resetField } = formMethods;
  const paymentFrequency = useWatch({ control, name: 'paymentFrequency' });

  const [reschedulePayments, { loading }] = useMutation(RESCHEDULE_PAYMENTS, {
    onCompleted: () => {
      enqueueSnackbar('Successfully rescheduled payments', {
        variant: 'success',
      });
      refetch();
      onClose();
    },
    onError: error => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  const onSubmit = data => {
    const { startDate, numberOfDays } = data;
    reschedulePayments({
      variables: {
        id: goldCard.id,
        input: {
          frequency: paymentFrequency,
          scheduledDate: startDate,
          numberOfDays: parseInt(numberOfDays, 10),
        },
      },
    });
  };

  useEffect(() => {
    resetField('numberOfDays');
  }, [paymentFrequency, resetField]);

  const textField = {
    control,
    className: classes.textField,
    InputLabelProps: { shrink: true },
  };

  if (loading) return <Loading />;

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        onClose();
      }}
      maxWidth="sm"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Reschedule Scheduled Payments</DialogTitle>
        <DialogContent className={classes.hiddenScroll}>
          <Alert severity="info" className={classes.info}>
            <Typography style={{ fontSize: '16px' }}>
              This will reschedule any scheduled payments for a given number of
              days. It will not affect any payments that have already been
              processed, cancelled, failed, etc.
            </Typography>
          </Alert>
          <Grid container spacing={4}>
            <Grid item xs={12} style={{ justifyContent: 'center' }}>
              <SelectControl2
                {...textField}
                label="Payment Frequency"
                name="paymentFrequency"
                optionNameKey="text"
                noNull
                options={[
                  { value: '', text: 'Number of Days' },
                  { value: 'first_of_month', text: 'First of Month' },
                  { value: 'last_of_month', text: 'Last of Month' },
                ]}
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldControl
                {...textField}
                label="Number of Days:"
                name="numberOfDays"
                required
                disabled={paymentFrequency}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                render={({ field: { ref, onChange, ...field } }) => (
                  <DatePicker
                    autoOk
                    variant="inline"
                    format="YYYY-MM-DD"
                    label="Reschedule after this date:"
                    disablePast
                    onChange={data =>
                      onChange(data?.format('YYYY-MM-DD') || '')
                    }
                    required
                    {...field}
                  />
                )}
                name="startDate"
                label="Start Date"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            size="small"
          >
            Reschedule Payments
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ReschedulePaymentsModal;
