import React, { useState } from 'react';

/* external */
import gql from 'graphql-tag';
import { useHistory } from 'react-router-dom';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';

/* Material UI */
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Input from '@mui/material/Input';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material';

/* internal */
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';
import { refCode } from 'utils';

const ATTACH_GOCARD_RECORD = gql`
  mutation attachGoCard($_id: ID!, $records: [String]!) {
    attachCustomerRecords(_id: $_id, records: $records) {
      records
    }
  }
`;

const SEARCH_GOCARD = gql`
  query gocard($filters: [QueryFilter], $sort: [QuerySortElement]) {
    gocard {
      accounts(filters: $filters, page: 1, pageSize: 10, sort: $sort) {
        results {
          cardId
          cashBalance
          cardNumber
          firstName
          lastName
          customer {
            _id
          }
        }
        pagination {
          total
          page
        }
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  text: {
    fontSize: 15,
  },
}));

const GoCardInfo = ({ gocard }) => {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      spacing={1}
      style={{ marginTop: '10px' }}
    >
      <Grid item xs={12}>
        Go Card Info:
      </Grid>
      <Grid item>
        <CreditCardIcon style={{ marginTop: '5px' }} />
      </Grid>
      <Grid item style={{ fontWeight: 'bold' }} className={classes.text}>
        ${gocard.cashBalance || 0}
      </Grid>
      <Grid item style={{ fontWeight: 'bold' }} className={classes.text}>
        {`${gocard.cardNumber}`}
      </Grid>
      <Grid
        item
        style={{ fontWeight: 'bold', color: '#999999' }}
        className={classes.text}
      >
        {`|`}
      </Grid>
      <Grid
        item
        style={{ fontWeight: 'bold', color: '#999999' }}
        className={classes.text}
      >
        {`${refCode(gocard.cardId)}`}
      </Grid>
      <Grid item xs={12} className={classes.text}>
        {`${gocard.firstName} ${gocard.lastName}`}
      </Grid>
    </Grid>
  );
};

const AttachGoCardModal = ({ customer, isOpen, onClose, refetch }) => {
  const theme = useTheme();
  const history = useHistory();
  const [cardNumber, setCardNumber] = useState('');
  const [goCard, setGoCard] = useState(null);
  const [step, setStep] = useState(1);
  const [error, setError] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [searchCard, { loading, data }] = useLazyQuery(SEARCH_GOCARD, {
    onCompleted: ({ gocard }) => {
      const results = gocard?.accounts?.results || [];
      const isAttachedToCustomer = results.some(result => result.customer?._id);
      setError(isAttachedToCustomer);
      if (results.length > 0) {
        setGoCard(results[0]);
      } else {
        setGoCard(null);
      }
    },
    onError: e =>
      enqueueSnackbar(`An error occurred: ${e}`, { variant: 'error' }),
    fetchPolicy: 'cache-and-network',
  });

  const [attachGoCard, { loading: attachLoading }] = useMutation(
    ATTACH_GOCARD_RECORD,
    {
      onCompleted: data => {
        enqueueSnackbar(`Go Card: ${cardNumber} attached!`, {
          variant: 'success',
        });
        // Refetch with new gocard included
        const card_ids = customer.records
          .filter(record => record.includes('gocard'))
          .map(record => record.split(':').slice(-1)[0]);
        card_ids.push(goCard.cardId);
        refetch({
          filters: [
            {
              model: 'GoCard',
              field: 'cardId',
              op: 'in',
              value: card_ids,
            },
            {
              model: 'GoCard',
              field: 'active',
              op: 'eq',
              value: true,
            },
          ],
        });
        onClose();
      },
      onError: e =>
        enqueueSnackbar(`Could not attach Go Card error: ${e}`, {
          variant: 'error',
        }),
    },
  );

  const handleChange = event => {
    setCardNumber(event.target.value);
  };

  const handleSearch = () => {
    searchCard({
      variables: {
        filters: [
          {
            model: 'GoCard',
            field: 'cardNumber',
            op: 'eq',
            value: cardNumber,
          },
          {
            model: 'GoCard',
            field: 'active',
            op: 'eq',
            value: true,
          },
        ],
      },
    });
  };

  const handleStep = next => {
    setStep(next);
  };

  const onSubmit = cardId => {
    attachGoCard({
      variables: {
        _id: customer._id,
        records: [`goauto.gocard:gocard:${cardId}`],
      },
    });
  };

  const handleClose = () => {
    setCardNumber('');
    setError(false);
    setStep(1);
    setGoCard(null);
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>Attach Go Card</DialogTitle>
      {step === 1 && (
        <>
          <DialogContent>
            Search for a Go Card by its card number.
            <FormControl fullWidth>
              <Input
                value={cardNumber}
                onChange={handleChange}
                onKeyDown={event => {
                  if (event.key === 'Enter') {
                    handleSearch();
                  }
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={() => handleSearch()} size="large">
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
              ></Input>
            </FormControl>
            {goCard !== null && error === true && (
              <>
                <Alert severity="error">
                  This Go Card is already attached to another Customer... Please
                  detach from that Customer before continuing...
                </Alert>
                <Button
                  onClick={() =>
                    history.push(`/customers/${goCard.customer._id}/details`)
                  }
                  style={{ marginTop: '10px' }}
                >
                  View Customer
                </Button>
              </>
            )}
            {data && data.gocard.accounts.results.length !== 0 && (
              <GoCardInfo gocard={data.gocard.accounts.results[0]} />
            )}
            {data && goCard === null && <>No Go Card found...</>}
          </DialogContent>
          <DialogActions>
            <Button
              style={theme.actions.close}
              onClick={onClose}
              variant="text"
            >
              Close
            </Button>
            <Button
              style={
                goCard === null || error
                  ? theme.actions.disabled
                  : theme.actions.confirm
              }
              onClick={() => handleStep(2)}
              variant="contained"
              color="primary"
              disabled={goCard === null || error}
            >
              Continue
            </Button>
          </DialogActions>
        </>
      )}
      {step === 2 && (
        <>
          <DialogContent>
            <Alert severity="info">
              Confirming will attach the Go Card to the customer.
            </Alert>
            <GoCardInfo gocard={data.gocard.accounts.results[0]} />
          </DialogContent>
          <DialogActions>
            <Button
              style={theme.actions.close}
              onClick={() => handleStep(1)}
              variant="text"
            >
              Back
            </Button>
            <Button
              style={theme.actions.confirm}
              onClick={() => onSubmit(data.gocard.accounts.results[0].cardId)}
              variant="contained"
              color="primary"
            >
              Attach
            </Button>
          </DialogActions>
        </>
      )}
      <LoadingBackdrop open={loading || attachLoading}>
        {attachLoading ? 'Attaching Go Card...' : 'Searching...'}
      </LoadingBackdrop>
    </Dialog>
  );
};

AttachGoCardModal.fragments = {
  gocard: gql`
    fragment AttachGoCardModalGoCard on GoCardAccountV2 {
      cashBalance
      cardNumber
      cardId
      firstName
      lastName
    }
  `,
};

export default AttachGoCardModal;
