import React, { useState } from 'react';

/* external */
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

/* Material UI */
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Box, Button, Grid, Paper, TextField, Typography } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';

/* internal */
import { displayName } from '../utils';
import { formatPrice } from 'utils';
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import DealerQuestionSubmitted from './DealerQuestionSubmitted';
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';

const ASK_QUESTION = gql`
  mutation askQuestion($message: MessageInput!) {
    appraisals {
      createMessage(message: $message) {
        id
        isQuestion
        fromDealer {
          name
        }
        toDealer {
          name
        }
        text
      }
    }
  }
`;

const DealerQuestion = ({
  offer,
  onSubmit,
  onCancel,
  setQuestion,
  refetch,
}) => {
  const [questionSubmitted, setSubmitted] = useState(false);
  const { dealerId } = useDealerContext();
  const [askQuestion, askQuestionMutation] = useMutation(ASK_QUESTION, {
    onCompleted: r => {
      setSubmitted(true);
      refetch();
    },
  });
  const [text, setText] = useState('');

  const { appraisal, isQuestionsPending } = offer;
  const { price, trimVariation, vin } = appraisal;

  const handleSubmit = () => {
    askQuestion({
      variables: {
        message: {
          fromDealerId: dealerId,
          toDealerId: appraisal.dealerId,
          text,
          offerId: offer.id,
          isQuestion: true,
        },
      },
    });
  };

  return (
    <>
      <LoadingBackdrop open={askQuestionMutation.loading}>
        Sending your question!
      </LoadingBackdrop>
      <Box margin={1}>
        <Grid container direction="column">
          <Grid item>
            <Typography style={{ width: '100%' }}>
              <b>{displayName(appraisal)}</b> {trimVariation}
            </Typography>
          </Grid>
          <Grid item style={{ paddingBottom: '1rem' }}>
            {vin}
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="space-between" padding={1}>
          <Typography variant="h6">
            Price: <b>{formatPrice(price)}</b>
          </Typography>
          {isQuestionsPending && (
            <Box fontSize="20px">
              <i style={{ color: 'blue' }} className="fas fa-question-circle" />
            </Box>
          )}
        </Box>
      </Box>
      {!questionSubmitted && (
        <>
          <Box margin={1}>
            <Paper
              style={{
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'center',
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: 'auto',
                marginBottom: 'auto',
                padding: '1rem',
              }}
            >
              <Typography variant="h5">What questions do you have?</Typography>
              <TextField
                label="Question(s)"
                value={text}
                onChange={e => setText(e.target.value)}
              />
            </Paper>
          </Box>
          <Box margin={1}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginRight: 0,
                marginLeft: 'auto',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginRight: 0,
                  marginLeft: 'auto',
                  paddingRight: '1rem',
                }}
              >
                <Button variant="outlined" onClick={() => setQuestion(false)}>
                  <ArrowBackIosIcon /> Back
                </Button>
              </div>
              <Button
                variant="contained"
                style={{ backgroundColor: 'green', color: 'white' }}
                onClick={handleSubmit}
              >
                <DoneIcon /> Send
              </Button>
            </div>
          </Box>
        </>
      )}
      {questionSubmitted && <DealerQuestionSubmitted />}
    </>
  );
};

DealerQuestion.fragments = {
  appraisal: gql`
    fragment DealerQuestionAppraisal on Appraisal {
      id
      dealerId
      make
      model
      price
      trimVariation
      vin
      year
    }
  `,
};

export default DealerQuestion;
