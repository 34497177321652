import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import ReconConditionModal from './ReconConditionModal';
import ReconNotes from './ReconNotes';
import ReconPhotosModal from './ReconPhotosModal';
import ReconRos from './ReconRos';
import SelectControl from 'components/MaterialUI/SelectControl2';
import KeyboardDatePickerControl from 'components/MaterialUI/KeyboardDatePickerControl';

const RECON_SOURCES = gql`
  query reconSources {
    inventory {
      getReconSources {
        id
        name
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  span: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    padding: '0 15px 15px 0',
  },
  headerSpan: {
    display: 'flex',
    justifyContent: 'start',
    flexWrap: 'wrap',
    padding: '0 15px 15px 0',
  },
}));

const ReconDetails = ({ vehicle: { dealer_id, recon, vin } }) => {
  const {
    formState: { errors },
    register,
  } = useFormContext();
  const reconSourcesData = useQuery(RECON_SOURCES);
  const reconSources = reconSourcesData?.data?.inventory?.getReconSources ?? [];
  const { span, headerSpan } = useStyles();
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState(null);
  const [conditionModalOpen, setConditionModalOpen] = useState(false);
  const [photoModalOpen, setPhotoModalOpen] = useState(false);
  const [roModalOpen, setRoModalOpen] = useState(false);

  register('recon.source_id');
  register('recon.date_arrived');
  register('recon.date_redeemed');

  const handleMenuClick = event => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  return (
    <Paper>
      <Container maxWidth="xl">
        <Box component="span" className={headerSpan}>
          <Typography variant="h6" style={{ alignSelf: 'center' }}>
            Recon
          </Typography>
          <IconButton size="large">
            <MoreVertIcon onClick={handleMenuClick} />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={() => setConditionModalOpen(true)}>
              Condition Report
            </MenuItem>
            <MenuItem onClick={() => setPhotoModalOpen(true)}>
              Add/View Photos
            </MenuItem>
          </Menu>
        </Box>
        <ReconConditionModal
          open={conditionModalOpen}
          handleClose={() => setConditionModalOpen(false)}
          vin={vin}
        />
        <ReconPhotosModal
          recon={recon}
          modalOpen={photoModalOpen}
          handleModalClose={() => setPhotoModalOpen(false)}
          vin={vin}
          dealerId={dealer_id}
        />
        <ReconRos
          dealerId={dealer_id}
          modalOpen={roModalOpen}
          handleModalClose={() => setRoModalOpen(false)}
          vin={vin}
        />
        <Box component="span" className={span}>
          <Box component="div" style={{ display: 'flex' }}>
            <Grid container spacing={2}>
              <Grid item>
                <FormControl>
                  <SelectControl
                    displayEmpty
                    error={errors.recon?.source_id}
                    name="recon.source_id"
                    style={{ width: '180px' }}
                    label="Recon Source"
                    options={reconSources}
                    optionValueKey="id"
                    nullDisplay="Unknown Source"
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <KeyboardDatePickerControl
                  label="Vehicle Arrival Date"
                  error={errors.recon?.date_arrived}
                  format="YYYY-MM-DD"
                  margin="normal"
                  name="recon.date_arrived"
                  style={{ width: '180px' }}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item>
                <KeyboardDatePickerControl
                  label="Vehicle Redeemed Date"
                  error={errors.recon?.date_arrived}
                  format="YYYY-MM-DD"
                  margin="normal"
                  name="recon.date_redeemed"
                  style={{ width: '180px' }}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid style={{ padding: '8px', alignSelf: 'center' }}>
                <Button
                  style={theme.actions.add}
                  onClick={() => setRoModalOpen(true)}
                >
                  View ROs
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
      <ReconNotes notes={recon?.notes ?? []} dealer_id={dealer_id} />
    </Paper>
  );
};

ReconDetails.fragments = {
  vehicle: gql`
    fragment ReconDetailsVehicle on GreaseInventoryVehicle {
      recon {
        id
        date_arrived
        date_created
        date_modified
        date_redeemed
        displayPhoto {
          cloudinary_public_id
          id
        }
        modified_by
        notes {
          id
          created_by
          date_created
          description
          visibility
        }
        photos {
          cloudinary_public_id
          id
          md5
        }
        source_id
        source_name
      }
    }
  `,
};

export default ReconDetails;
