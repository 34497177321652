import React, { useRef, useState } from 'react';
import { withStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import { cloneDeep } from 'lodash';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import InspectionCheckboxes from '../InspectionCheckboxes';

const AMVICForm = props => {
  const { enqueueSnackbar } = useSnackbar();

  // amvic form fields
  const powertrain = {
    accelerator: 'Accelerator',
    fuel_system: 'Fuel System',
    exhaust: 'Exhaust',
    transmission: 'Transmission',
    axles: 'Front/Rear/Spindles Axles',
    clutch: 'Clutch',
    fluid_levels: 'Fluid Levels',
    cv_joints: 'CV Joints',
  };
  const lamps = {
    hi_beam: 'Head Lamp Hi Beam',
    lo_beam: 'Head Lamp Lo Beam',
    location: 'Head Lamp Location',
    daytime_running: 'Daytime Running Lamps',
    tail: 'Tail Lamps',
    brake: 'Brake Lamps',
    turn_signal: 'Turn Signal Lamps',
    hazard_warning: 'Hazard Warning Lamps',
    licence_plate: 'Licence Plate Lamp',
    backup: 'Back-up Lamps',
  };
  const instruments = {
    odometer: 'Speedometer/Odometer',
    indicator_lamps: 'Indicator Lamps',
    horn: 'Horn',
    hi_beam_indicator: 'Hi Beam Indicator',
  };
  const brakes = {
    parking: 'Parking/Emergency Brake',
    hydraulic_system: 'Hydraulic System',
    vacuum_system: 'Vacuum System',
    drum: 'Drum Brakes',
    disc: 'Disc Brakes',
    pads: 'Shoes/Pads',
    antilock: 'Anti-Lock (if OEM equipped)',
  };
  const steering = {
    lash: 'Steering Lash',
    linkage: 'Steering Linkage',
    rack_pinion: 'Rack & Pinion',
    power_system: 'Power Steering System',
    king_pin: 'King Pin',
    ball_joints: 'Ball Joints',
  };
  const suspension = {
    leaf_springs: 'Leaf springs',
    struts_and_shocks: 'Struts and Shocks',
    coil_spring: 'Coil spring',
    torsion_bar: 'Torsion Bar',
    multilink_rear: 'Independent/Multilink Rear',
    computer_controlled: 'Computer Controlled',
  };
  const diagnostic = {
    trouble_codes: 'Diagnostic Trouble Codes',
  };
  const frame = {
    hood_latch: 'Hood Latch',
    door_latches: 'Door Latches & Hinges',
    bumpers: 'Bumpers',
    windshield_wipers: 'Windshield Wipers & Washer',
    rear_wiper: 'Rear Wiper & Washer',
    windshield: 'Windshield',
    windows: 'Windows',
    defrost: 'Defrost/Heaters',
    mirrors: 'Mirrors',
    seats: 'Seats',
    seat_belts: 'Seat Belts/Airbags',
    mudguards: 'Mudguards',
    window_glazing: 'Window Glazing',
    structural_integrity: 'Structural Integrity',
  };
  const tires = {
    tread_depth: 'Tread Depth',
    tread_section: 'Tread Section',
    sidewalls: 'Sidewalls',
    wheels: 'Wheels',
  };
  const electrical = {
    wiring: 'Wiring',
    battery: 'Battery',
    switches: 'Switches',
    alternator: 'Alternator',
  };

  let powertrainObj = cloneDeep(powertrain);
  let lampsObj = cloneDeep(lamps);
  let instrumentsObj = cloneDeep(instruments);
  let brakesObj = cloneDeep(brakes);
  let steeringObj = cloneDeep(steering);
  let suspensionObj = cloneDeep(suspension);
  let diagnosticObj = cloneDeep(diagnostic);
  let frameObj = cloneDeep(frame);
  let tiresObj = cloneDeep(tires);
  let electricalObj = cloneDeep(electrical);

  Object.entries(powertrainObj).map(([name]) => (powertrainObj[name] = 'c'));
  Object.entries(lampsObj).map(([name]) => (lampsObj[name] = 'c'));
  Object.entries(instrumentsObj).map(([name]) => (instrumentsObj[name] = 'c'));
  Object.entries(brakesObj).map(([name]) => (brakesObj[name] = 'c'));
  Object.entries(steeringObj).map(([name]) => (steeringObj[name] = 'c'));
  Object.entries(suspensionObj).map(([name]) => (suspensionObj[name] = 'c'));
  Object.entries(diagnosticObj).map(([name]) => (diagnosticObj[name] = 'c'));
  Object.entries(frameObj).map(([name]) => (frameObj[name] = 'c'));
  Object.entries(tiresObj).map(([name]) => (tiresObj[name] = 'c'));
  Object.entries(electricalObj).map(([name]) => (electricalObj[name] = 'c'));

  const [powertrainValues, setPowertrainValues] = useState(powertrainObj);
  const [lampsValues, setLampsValues] = useState(lampsObj);
  const [instrumentsValues, setInstrumentsValues] = useState(instrumentsObj);
  const [brakesValues, setBrakesValues] = useState(brakesObj);
  const [steeringValues, setSteeringValues] = useState(steeringObj);
  const [suspensionValues, setSuspensionValues] = useState(suspensionObj);
  const [diagnosticValues, setDiagnosticValues] = useState(diagnosticObj);
  const [frameValues, setFrameValues] = useState(frameObj);
  const [tiresValues, setTiresValues] = useState(tiresObj);
  const [electricalValues, setElectricalValues] = useState(electricalObj);

  const handlePowertrain = e => {
    const { name, value } = e.target;
    if (value === 'na') {
      e.target.closest('div').previousSibling.style.textDecoration =
        'line-through';
    } else {
      e.target.closest('div').previousSibling.style.textDecoration = 'none';
    }
    setPowertrainValues(prevPowertrainValues => ({
      ...prevPowertrainValues,
      [name]: value,
    }));
  };
  const handleLamps = e => {
    const { name, value } = e.target;
    if (value === 'na') {
      e.target.closest('div').previousSibling.style.textDecoration =
        'line-through';
    } else {
      e.target.closest('div').previousSibling.style.textDecoration = 'none';
    }
    setLampsValues(prevLampsValues => ({
      ...prevLampsValues,
      [name]: value,
    }));
  };
  const handleInstruments = e => {
    const { name, value } = e.target;
    if (value === 'na') {
      e.target.closest('div').previousSibling.style.textDecoration =
        'line-through';
    } else {
      e.target.closest('div').previousSibling.style.textDecoration = 'none';
    }
    setInstrumentsValues(prevInstrumentsValues => ({
      ...prevInstrumentsValues,
      [name]: value,
    }));
  };
  const handleBrakes = e => {
    const { name, value } = e.target;
    if (value === 'na') {
      e.target.closest('div').previousSibling.style.textDecoration =
        'line-through';
    } else {
      e.target.closest('div').previousSibling.style.textDecoration = 'none';
    }
    setBrakesValues(prevBrakesValues => ({
      ...prevBrakesValues,
      [name]: value,
    }));
  };
  const handleSteering = e => {
    const { name, value } = e.target;
    if (value === 'na') {
      e.target.closest('div').previousSibling.style.textDecoration =
        'line-through';
    } else {
      e.target.closest('div').previousSibling.style.textDecoration = 'none';
    }
    setSteeringValues(prevSteeringValues => ({
      ...prevSteeringValues,
      [name]: value,
    }));
  };
  const handleSuspension = e => {
    const { name, value } = e.target;
    if (value === 'na') {
      e.target.closest('div').previousSibling.style.textDecoration =
        'line-through';
    } else {
      e.target.closest('div').previousSibling.style.textDecoration = 'none';
    }
    setSuspensionValues(prevSuspensionValues => ({
      ...prevSuspensionValues,
      [name]: value,
    }));
  };
  const handleDiagnostic = e => {
    const { name, value } = e.target;
    if (value === 'na') {
      e.target.closest('div').previousSibling.style.textDecoration =
        'line-through';
    } else {
      e.target.closest('div').previousSibling.style.textDecoration = 'none';
    }
    setDiagnosticValues(prevDiagnosticValues => ({
      ...prevDiagnosticValues,
      [name]: value,
    }));
  };
  const handleFrame = e => {
    const { name, value } = e.target;
    if (value === 'na') {
      e.target.closest('div').previousSibling.style.textDecoration =
        'line-through';
    } else {
      e.target.closest('div').previousSibling.style.textDecoration = 'none';
    }
    setFrameValues(prevFrameValues => ({
      ...prevFrameValues,
      [name]: value,
    }));
  };
  const handleTires = e => {
    const { name, value } = e.target;
    if (value === 'na') {
      e.target.closest('div').previousSibling.style.textDecoration =
        'line-through';
    } else {
      e.target.closest('div').previousSibling.style.textDecoration = 'none';
    }
    setTiresValues(prevTiresValues => ({
      ...prevTiresValues,
      [name]: value,
    }));
  };
  const handleElectrical = e => {
    const { name, value } = e.target;
    if (value === 'na') {
      e.target.closest('div').previousSibling.style.textDecoration =
        'line-through';
    } else {
      e.target.closest('div').previousSibling.style.textDecoration = 'none';
    }
    setElectricalValues(prevElectricalValues => ({
      ...prevElectricalValues,
      [name]: value,
    }));
  };

  const [consent, setConsent] = useState(false);
  const handleConsent = () => {
    setConsent(consent => !consent);
  };

  const [confirm, setConfirm] = useState(false);
  const handleConfirm = () => {
    setConfirm(confirm => !confirm);
  };

  const wholeForm = useRef(null);

  const handleSubmit = e => {
    e.preventDefault();

    if (!wholeForm.current['amvic_number'].value) {
      enqueueSnackbar(`Please fill out 'Tech #'.`);
      return;
    }

    if (!consent) {
      enqueueSnackbar(`Please check 'I agree' and then submit the inspection!`);
      return;
    }

    const form_data = {
      powertrain: powertrainValues,
      lamps: lampsValues,
      instruments: instrumentsValues,
      brakes: brakesValues,
      steering: steeringValues,
      suspension: suspensionValues,
      diagnostic: diagnosticValues,
      frame: frameValues,
      tires: tiresValues,
      electrical: electricalValues,
      technician_comments: wholeForm.current['technician_comments'].value,
      amvic_number: wholeForm.current['amvic_number'].value,
      vehicle_type: wholeForm.current['vehicle_type'].value,
    };

    props.formData(form_data);
    props.formClose();
  };

  const GreenButton = withStyles({
    root: {
      color: '#fff',
      backgroundColor: '#198754',
      '&:hover': {
        backgroundColor: '#157347',
      },
    },
  })(Button);

  return (
    <form onSubmit={handleSubmit} ref={wholeForm}>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Vehicle Type:</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <RadioGroup row name="vehicle_type">
            <FormControlLabel value="Truck" control={<Radio />} label="Truck" />
            <FormControlLabel
              value="Motorcycle"
              control={<Radio />}
              label="Motorcycle"
            />
            <FormControlLabel value="Bus" control={<Radio />} label="Bus" />
            <FormControlLabel value="Van" control={<Radio />} label="Van" />
            <FormControlLabel
              value="Light Truck"
              control={<Radio />}
              label="Light Truck"
            />
            <FormControlLabel value="Auto" control={<Radio />} label="Auto" />
            <FormControlLabel
              value="Motorized RV"
              control={<Radio />}
              label="Motorized RV"
            />
            <FormControlLabel value="Other" control={<Radio />} label="Other" />
          </RadioGroup>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Powertrain</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(powertrain).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={powertrainValues}
                      handleGroup={handlePowertrain}
                      name={name}
                      orange={false}
                      white={true}
                      greenValue="c"
                      redValue="n"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Lamps</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(lamps).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={lampsValues}
                      handleGroup={handleLamps}
                      name={name}
                      orange={false}
                      white={true}
                      greenValue="c"
                      redValue="n"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Instruments</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(instruments).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={instrumentsValues}
                      handleGroup={handleInstruments}
                      name={name}
                      orange={false}
                      white={true}
                      greenValue="c"
                      redValue="n"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Brakes</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(brakes).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={brakesValues}
                      handleGroup={handleBrakes}
                      name={name}
                      orange={false}
                      white={true}
                      greenValue="c"
                      redValue="n"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Steering</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(steering).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={steeringValues}
                      handleGroup={handleSteering}
                      name={name}
                      orange={false}
                      white={true}
                      greenValue="c"
                      redValue="n"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Suspension</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(suspension).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={suspensionValues}
                      handleGroup={handleSuspension}
                      name={name}
                      orange={false}
                      white={true}
                      greenValue="c"
                      redValue="n"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Diagnostic</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(diagnostic).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={diagnosticValues}
                      handleGroup={handleDiagnostic}
                      name={name}
                      orange={false}
                      white={true}
                      greenValue="c"
                      redValue="n"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Frame & Body</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(frame).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={frameValues}
                      handleGroup={handleFrame}
                      name={name}
                      orange={false}
                      white={true}
                      greenValue="c"
                      redValue="n"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Tires & Wheels</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(tires).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={tiresValues}
                      handleGroup={handleTires}
                      name={name}
                      orange={false}
                      white={true}
                      greenValue="c"
                      redValue="n"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Electrical</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(electrical).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={electricalValues}
                      handleGroup={handleElectrical}
                      name={name}
                      orange={false}
                      white={true}
                      greenValue="c"
                      redValue="n"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <TextField
        label="Technician Comments"
        multiline
        minRows={2}
        variant="outlined"
        name={'technician_comments'}
        fullWidth
        style={{ marginTop: '15px' }}
      />
      <Typography style={{ marginTop: '15px' }}>
        This is to certify that I have assessed the vehicle described above in
        accordance with the Traffic Safety Act, Vehicle Equipment Regulation
        (A/R 122/2009). By signing this assessment, I verify the contents of
        this document are true and correct.
      </Typography>
      <FormControlLabel
        control={<Checkbox />}
        label="I agree"
        onChange={handleConsent}
        value={consent}
      />
      <br />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Technician Name"
            value={props.user.display_name}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Tech #"
            value={props.user.goUserProfile.profile.sales_licence}
            fullWidth
            name={'amvic_number'}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Date"
            value={new Date().toLocaleString()}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <Button
            type="button"
            variant="contained"
            style={{ marginRight: '15px' }}
            onClick={handleConfirm}
            color="default"
          >
            Cancel
          </Button>
          <Dialog
            open={confirm}
            onClose={handleConfirm}
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Current inspection will be closed and unsaved data will be gone.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleConfirm}>Cancel</Button>
              <Button onClick={props.formClose} color="secondary">
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
          <GreenButton type="submit" variant="contained">
            Save
          </GreenButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default AMVICForm;
