import React from 'react';

import { Role } from 'constants.js';
import { useUserContext } from 'components/MaterialUI/UserContext';

const RequiresDealerOnly = ({
  renderOnSuccess,
  renderOnFailure,
  dealerIds,
}) => {
  const {
    currentUser: { role, dealer_ids },
  } = useUserContext();
  if (
    (role && role === Role.ORGANIZATION_ADMIN) ||
    dealer_ids.some(dealerId => dealerIds.includes(dealerId))
  ) {
    return renderOnSuccess();
  } else {
    return renderOnFailure();
  }
};

export default RequiresDealerOnly;
