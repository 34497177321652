import React from 'react';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { withStyles } from '@mui/styles';

const GreenCheckbox = withStyles({
  root: {
    color: '#66bb6a',
    '&$checked': {
      color: '#43a047',
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

const OrangeCheckbox = withStyles({
  root: {
    color: '#FFA500',
    '&$checked': {
      color: '#FF8C00',
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

const RedCheckbox = withStyles({
  root: {
    color: '#FF0000',
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

const InspectionCheckboxes = ({
  valueGroup,
  handleGroup,
  name,
  green = true,
  orange = true,
  red = true,
  white = false,
  greenValue = 'Good',
  orangeValue = 'Service Required',
  redValue = 'Repaired',
  whiteValue = 'na',
  redAtSecond = false,
  greenOther = false,
  redOther = false,
  greenOtherValue = 'No',
  redOtherValue = 'Yes',
}) => {
  return (
    <>
      {green && (
        <GreenCheckbox
          checked={valueGroup[name] === greenValue}
          onChange={handleGroup}
          value={greenValue}
          name={name}
        />
      )}
      {red && redAtSecond && (
        <RedCheckbox
          checked={valueGroup[name] === redValue}
          onChange={handleGroup}
          value={redValue}
          name={name}
        />
      )}
      {orange && (
        <OrangeCheckbox
          checked={valueGroup[name] === orangeValue}
          onChange={handleGroup}
          value={orangeValue}
          name={name}
        />
      )}
      {red && !redAtSecond && (
        <RedCheckbox
          checked={valueGroup[name] === redValue}
          onChange={handleGroup}
          value={redValue}
          name={name}
        />
      )}
      {white && (
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={valueGroup[name] === whiteValue}
              onChange={handleGroup}
              value={whiteValue}
              name={name}
            />
          }
          label="N/A"
          labelPlacement="start"
        />
      )}
      {greenOther && !green && (
        <FormControlLabel
          control={
            <GreenCheckbox
              checked={valueGroup[name] === greenOtherValue}
              onChange={handleGroup}
              value={greenOtherValue}
              name={name}
            />
          }
          label="No"
          labelPlacement="start"
        />
      )}
      {redOther && !red && (
        <FormControlLabel
          control={
            <RedCheckbox
              checked={valueGroup[name] === redOtherValue}
              onChange={handleGroup}
              value={redOtherValue}
              name={name}
            />
          }
          label="Yes"
          labelPlacement="start"
        />
      )}
    </>
  );
};
export default InspectionCheckboxes;
