import React from 'react';

/* Material UI */
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';

const GoodBadRadioGroup = ({
  onChange,
  value,
  RadioProps,
  RadioGroupStyle = { display: 'flex', flexDirection: 'row' },
}) => (
  <RadioGroup
    onChange={e => onChange(e.target.value === 'true')}
    style={RadioGroupStyle}
    value={String(value)}
  >
    <FormControlLabel
      value="true"
      control={<Radio {...RadioProps} />}
      label="Good"
    />
    <FormControlLabel
      value="false"
      control={<Radio {...RadioProps} />}
      label="Bad"
    />
  </RadioGroup>
);

export default GoodBadRadioGroup;
