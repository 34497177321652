import React, { useEffect } from 'react';

/* external */
import { useForm } from 'react-hook-form';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { Alert } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Close as CloseIcon } from '@mui/icons-material';

/* internal */
import GridCard from './GridCard';
import SelectControl from 'components/MaterialUI/SelectControl2';
import { DealerTrackOptions } from 'modules/credit_apps/constants';
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import CheckboxControl from 'components/MaterialUI/CheckboxControl';
import TextFieldControl from 'components/MaterialUI/TextFieldControl';
import { errorHandler, parseErrors } from 'utils';
import { H2H_DEALER_ID } from 'constants.js';

import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';

const useStyles = makeStyles({
  textFieldBase: {
    '& input:read-only': {
      color: '#AAAAAA',
    },
    width: '100%',
  },
});

const DT_SUB_FRAGMENT = gql`
  fragment DealerTrackSubmissionDialogDealerTrackSubmission on DealerTrackSubmission {
    id
    creditAppId
    dealerId
    dateCreated
    createdByUser {
      display_name
    }
    modifiedByUser {
      display_name
    }
    dealerTrackDealId
    dealerTrackReferenceNum
    applicationStatus
    error
    data {
      applicants {
        primaryApplicant {
          birthDate
          currentAddress {
            address {
              addressType
              city
              postalCode
              province
              streetDirection
              streetName
              streetNumber
              streetType
            }
          }
          currentEmployment {
            address {
              addressType
              city
              postalCode
              province
              streetDirection
              streetName
              streetNumber
              streetType
            }
            employerDurationMonths
            employerDurationYears
            employerName
            employmentStatus
            employmentType
            occupation
          }
          email
          firstName
          gender
          hasAcceptedTermsAndConditions
          housing {
            homeMonthlyPayment
            homeMortgageOwnership
            homeOwnershipType
          }
          income {
            mainIncome {
              annualGrossIncome
            }
          }
          lastName
          mobilePhone
          phone
        }
      }
      creditAppType
      language
      productId
    }
  }
`;

const CREDIT_APP_FRAGMENT = gql`
  fragment DealerTrackSubmissionDialogCreditApp on CreditApp {
    address
    addressSuite
    city
    employerAddress
    employerAddressSuite
    employerCity
    employerPostalCode
    employerProvince
    postalCode
    province
  }
`;

const SUBMIT_TO_DEALER_TRACK = gql`
  mutation submitToDealerTrack(
    $dealerTrackSubmission: DealerTrackSubmissionInput!
  ) {
    creditApps {
      createDealerTrackSubmission(
        dealerTrackSubmission: $dealerTrackSubmission
      ) {
        id
        ...DealerTrackSubmissionDialogDealerTrackSubmission
      }
    }
  }
  ${DT_SUB_FRAGMENT}
`;

const UPDATE_DEALER_TRACK_SUBMISSION = gql`
  mutation updateDealerTrackSubmission(
    $id: Int!
    $data: DealerTrackSubmissionUpdate!
  ) {
    creditApps {
      updateDealerTrackSubmission(id: $id, data: $data) {
        id
        ...DealerTrackSubmissionDialogDealerTrackSubmission
      }
    }
  }
  ${DT_SUB_FRAGMENT}
`;

const DealerTrackSubmissionDialog = ({
  creditApp,
  dealerTrackSubmission,
  onClose,
  onCreate,
  onUpdate,
  open,
  ...rest
}) => {
  const { greaseDealers } = useDealerContext();
  const { textFieldBase } = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const {
    control,
    clearErrors,
    handleSubmit,
    register,
    reset,
    setError,
    formState: { dirtyFields },
  } = useForm({
    defaultValues: dealerTrackSubmission,
    shouldUnregister: true, // Setting to shouldUnregister so we don't get fields
    // in here that we don't have controls for (__typename__, resolved fields, etc.)
    // Mostly needed for when we're EDITING a dealerTrackSubmission
  });

  const edit = Boolean(dealerTrackSubmission?.id);

  const [createDealerTrackSubmission, { error, loading }] = useMutation(
    SUBMIT_TO_DEALER_TRACK,
    {
      onError: err => errorHandler(enqueueSnackbar, setError)(err),
      onCompleted: data =>
        onCreate(data.creditApps.createDealerTrackSubmission),
    },
  );

  const [
    updateDealerTrackSubmission,
    { error: updateError, loading: updating },
  ] = useMutation(UPDATE_DEALER_TRACK_SUBMISSION, {
    onError: err => errorHandler(enqueueSnackbar, setError)(err),
    onCompleted: data => onUpdate(data.creditApps.updateDealerTrackSubmission),
  });

  const variant = 'outlined';
  const textFieldProps = {
    muiVariant: variant,
    className: textFieldBase,
    control,
  };

  const selectProps = {
    variant,
  };

  const creditAppAddress = {
    address: creditApp?.address,
    addressSuite: creditApp?.addressSuite,
    city: creditApp?.city,
    province: creditApp?.province,
    postalCode: creditApp?.postalCode,
  };

  const creditAppEmployerAddress = {
    address: creditApp?.employerAddress,
    addressSuite: creditApp?.employerAddressSuite,
    city: creditApp?.employerCity,
    province: creditApp?.employerProvince,
    postalCode: creditApp?.employerPostalCode,
  };

  useEffect(() => {
    if (open) reset(dealerTrackSubmission);
  }, [open, reset]);

  const onSubmit = ({ id = null, ...dealerTrackSubmission }) => {
    if (id) {
      const {
        __typename,
        applicationStatus,
        createdBy,
        createdByUser,
        creditAppId,
        creditAppType,
        dateCreated,
        deal,
        dealer,
        dealerId,
        dealerTrackDealId,
        dealerTrackReferenceNum,
        error,
        language,
        modifiedByUser,
        productId,
        ...data
      } = dealerTrackSubmission;

      updateDealerTrackSubmission({
        variables: { id, data },
      });
    } else {
      createDealerTrackSubmission({ variables: { dealerTrackSubmission } });
    }
  };

  const parsedErrors = error && parseErrors(error);
  const parsedUpdateErrors = updateError && parseErrors(updateError);
  return (
    <Dialog onClose={onClose} open={open} {...rest}>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Submit to Dealer Track</Typography>
          <IconButton onClick={onClose} size="large">
            <CloseIcon />
          </IconButton>
        </Box>
        <LoadingBackdrop open={loading || updating}>
          {loading ? 'Submitting...' : 'Updating...'}
        </LoadingBackdrop>
        {error && (
          <Alert severity="error">
            {parsedErrors?.error ?? `Errors in fields below (marked in red)`}
          </Alert>
        )}
        {updateError && (
          <Alert severity="error">
            {parsedUpdateErrors?.error ??
              `Errors in fields below (marked in red)`}
          </Alert>
        )}
      </DialogTitle>
      <form
        onSubmit={e => {
          clearErrors();
          // Need to manually clear errors, since we've manually
          // used setError from server. handleSubmit will not fire otherwise.
          handleSubmit(onSubmit)(e); // Needed to prevent page navigate with data in url params
        }}
      >
        <DialogContent>
          <input type="hidden" {...register('id', { disabled: !edit })} />
          <input type="hidden" {...register('creditAppId')} />
          <input type="hidden" {...register('data.language')} />
          <input type="hidden" {...register('data.productId')} />
          <input type="hidden" {...register('data.creditAppType')} />
          <SelectControl
            style={{ width: '50%' }}
            control={control}
            name="dealerId"
            options={greaseDealers
              .map(x => ({
                value: x.id,
                name: x.name,
              }))
              .sort((a, b) => a.name.localeCompare(b.name))}
            label="Dealer"
            helperText={
              dirtyFields.dealerId
                ? "This will change the Credit Application's dealer and can only be done once."
                : undefined
            }
            inputProps={{
              readOnly: dealerTrackSubmission?.dealerId !== H2H_DEALER_ID,
            }}
          />
          <GridCard title="Personal Information">
            <Grid item xs={4}>
              <TextFieldControl
                {...textFieldProps}
                label="First Name"
                name="data.applicants.primaryApplicant.firstName"
              />
            </Grid>
            <Grid item xs={4}>
              <TextFieldControl
                {...textFieldProps}
                label="Last Name"
                name="data.applicants.primaryApplicant.lastName"
              />
            </Grid>
            <Grid item xs={4}>
              <TextFieldControl
                {...textFieldProps}
                label="Phone"
                name="data.applicants.primaryApplicant.phone"
              />
            </Grid>
            <Grid item xs={4}>
              <TextFieldControl
                {...textFieldProps}
                label="Mobile Phone"
                name="data.applicants.primaryApplicant.mobilePhone"
              />
            </Grid>
            <Grid item xs={4}>
              <TextFieldControl
                {...textFieldProps}
                label="Email"
                name="data.applicants.primaryApplicant.email"
              />
            </Grid>
            <Grid item xs={4}>
              <TextFieldControl
                {...textFieldProps}
                label="Birth Date"
                name="data.applicants.primaryApplicant.birthDate"
              />
            </Grid>
            <Grid item xs={4}>
              <SelectControl
                {...selectProps}
                className={textFieldBase}
                control={control}
                label="Gender"
                name="data.applicants.primaryApplicant.gender"
                noNull
                options={DealerTrackOptions.Gender}
              />
            </Grid>
          </GridCard>
          <GridCard
            title={
              <Box display="flex" style={{ gap: '10px' }}>
                <Box>Address</Box>
                <Box ml={4} style={{ color: 'black', fontSize: '14px' }}>
                  User entered:
                </Box>
                <Box
                  style={{
                    color: 'black',
                    border: '1px solid black',
                    fontSize: '12px',
                    fontWeight: 'normal',
                  }}
                >
                  <Box>{creditAppAddress.suite}</Box>
                  <Box>{creditAppAddress.address}</Box>
                  <Box>
                    {creditAppAddress.city} {creditAppAddress.province}{' '}
                    {creditAppAddress.postalCode}
                  </Box>
                </Box>
              </Box>
            }
          >
            {/* Street needs to be ST and Ave needs to be AV should we make a dropdown? */}
            <Grid item xs={4}>
              <SelectControl
                {...selectProps}
                className={textFieldBase}
                control={control}
                label="Address Type"
                name="data.applicants.primaryApplicant.currentAddress.address.addressType"
                options={DealerTrackOptions.AddressType}
              />
            </Grid>
            <Grid item xs={4}>
              <TextFieldControl
                {...textFieldProps}
                label="Street Number"
                name="data.applicants.primaryApplicant.currentAddress.address.streetNumber"
              />
            </Grid>
            <Grid item xs={4}>
              <TextFieldControl
                {...textFieldProps}
                label="Street Name"
                name="data.applicants.primaryApplicant.currentAddress.address.streetName"
              />
            </Grid>

            <Grid item xs={4}>
              <TextFieldControl
                {...textFieldProps}
                label="Street Type"
                name="data.applicants.primaryApplicant.currentAddress.address.streetType"
              />
            </Grid>
            <Grid item xs={4}>
              <SelectControl
                {...selectProps}
                className={textFieldBase}
                control={control}
                label="Street Direction"
                name="data.applicants.primaryApplicant.currentAddress.address.streetDirection"
                nullDisplay="&nbsp;"
                options={DealerTrackOptions.StreetDirection}
              />
            </Grid>
            <Grid item xs={4}>
              <TextFieldControl
                {...textFieldProps}
                label="City"
                name="data.applicants.primaryApplicant.currentAddress.address.city"
              />
            </Grid>

            <Grid item xs={4}>
              <SelectControl
                {...selectProps}
                className={textFieldBase}
                control={control}
                label="Province"
                name="data.applicants.primaryApplicant.currentAddress.address.province"
                nullDisplay="&nbsp;"
                options={DealerTrackOptions.Province}
              />
            </Grid>
            <Grid item xs={4}>
              <TextFieldControl
                {...textFieldProps}
                label="Postal Code"
                name="data.applicants.primaryApplicant.currentAddress.address.postalCode"
              />
            </Grid>
          </GridCard>
          <GridCard
            title={
              <Box display="flex" style={{ gap: '10px' }}>
                <Box>Employment & Income</Box>
                <Box ml={4} style={{ color: 'black', fontSize: '14px' }}>
                  User entered:
                </Box>
                <Box
                  style={{
                    color: 'black',
                    border: '1px solid black',
                    fontSize: '12px',
                    fontWeight: 'normal',
                  }}
                >
                  <Box>{creditAppEmployerAddress.suite}</Box>
                  <Box>{creditAppEmployerAddress.address}</Box>
                  <Box>
                    {creditAppEmployerAddress.city}{' '}
                    {creditAppEmployerAddress.province}{' '}
                    {creditAppEmployerAddress.postalCode}
                  </Box>
                </Box>
              </Box>
            }
          >
            <Grid item xs={4}>
              <TextFieldControl
                {...textFieldProps}
                type="number"
                label="Annual Gross Income"
                name="data.applicants.primaryApplicant.income.mainIncome.annualGrossIncome"
              />
            </Grid>
            <Grid item xs={4}>
              <SelectControl
                {...selectProps}
                className={textFieldBase}
                control={control}
                label="Employment Status"
                name="data.applicants.primaryApplicant.currentEmployment.employmentStatus"
                nullDisplay="&nbsp;"
                options={DealerTrackOptions.EmploymentStatus}
              />
            </Grid>
            <Grid item xs={4}>
              <SelectControl
                {...selectProps}
                className={textFieldBase}
                control={control}
                label="Employment Type"
                name="data.applicants.primaryApplicant.currentEmployment.employmentType"
                nullDisplay="&nbsp;"
                options={DealerTrackOptions.EmploymentType}
              />
            </Grid>
            <Grid item xs={4}>
              <TextFieldControl
                {...textFieldProps}
                label="Employer Name"
                name="data.applicants.primaryApplicant.currentEmployment.employerName"
              />
            </Grid>
            <Grid item xs={4}>
              <TextFieldControl
                {...textFieldProps}
                label="Occupation"
                name="data.applicants.primaryApplicant.currentEmployment.occupation"
              />
            </Grid>
            <Grid item xs={4}>
              <TextFieldControl
                {...textFieldProps}
                type="number"
                label="Years"
                name="data.applicants.primaryApplicant.currentEmployment.employerDurationYears"
              />
            </Grid>
            <Grid item xs={4}>
              <TextFieldControl
                {...textFieldProps}
                type="number"
                label="Months"
                name="data.applicants.primaryApplicant.currentEmployment.employerDurationMonths"
              />
            </Grid>
            <Grid item xs={4}>
              <SelectControl
                {...selectProps}
                className={textFieldBase}
                control={control}
                label="Address Type"
                name="data.applicants.primaryApplicant.currentEmployment.address.addressType"
                options={DealerTrackOptions.AddressType}
              />
            </Grid>
            <Grid item xs={4}>
              <TextFieldControl
                {...textFieldProps}
                label="Street Number"
                name="data.applicants.primaryApplicant.currentEmployment.address.streetNumber"
              />
            </Grid>
            <Grid item xs={4}>
              <TextFieldControl
                {...textFieldProps}
                label="Street Name"
                name="data.applicants.primaryApplicant.currentEmployment.address.streetName"
              />
            </Grid>
            <Grid item xs={4}>
              <TextFieldControl
                {...textFieldProps}
                label="Street Type"
                name="data.applicants.primaryApplicant.currentEmployment.address.streetType"
              />
            </Grid>
            <Grid item xs={4}>
              <SelectControl
                {...selectProps}
                className={textFieldBase}
                control={control}
                label="Street Direction"
                name="data.applicants.primaryApplicant.currentEmployment.address.streetDirection"
                nullDisplay="&nbsp;"
                options={DealerTrackOptions.StreetDirection}
              />
            </Grid>
            <Grid item xs={4}>
              <TextFieldControl
                {...textFieldProps}
                label="City"
                name="data.applicants.primaryApplicant.currentEmployment.address.city"
              />
            </Grid>
            <Grid item xs={4}>
              <SelectControl
                {...selectProps}
                className={textFieldBase}
                control={control}
                label="Province"
                name="data.applicants.primaryApplicant.currentEmployment.address.province"
                nullDisplay="&nbsp;"
                options={DealerTrackOptions.Province}
              />
            </Grid>
            <Grid item xs={4}>
              <TextFieldControl
                {...textFieldProps}
                label="Postal Code"
                name="data.applicants.primaryApplicant.currentEmployment.address.postalCode"
              />
            </Grid>
          </GridCard>
          <GridCard title="Mortgage or Rent">
            <Grid item xs={4}>
              <TextFieldControl
                {...textFieldProps}
                label="Mortgage Company"
                name="data.applicants.primaryApplicant.housing.homeMortgageOwnership"
              />
            </Grid>
            <Grid item xs={4}>
              <SelectControl
                {...selectProps}
                className={textFieldBase}
                control={control}
                label="Home Ownership Type"
                name="data.applicants.primaryApplicant.housing.homeOwnershipType"
                nullDisplay="&nbsp;"
                options={DealerTrackOptions.HomeOwnershipType}
              />
            </Grid>
            <Grid item xs={4}>
              <TextFieldControl
                {...textFieldProps}
                label="Monthly Payment"
                name="data.applicants.primaryApplicant.housing.homeMonthlyPayment"
                type="number"
              />
            </Grid>
          </GridCard>
          <CheckboxControl
            control={control}
            label="Has Accepted Terms and Conditions"
            name="data.applicants.primaryApplicant.hasAcceptedTermsAndConditions"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="contained">
            Cancel
          </Button>
          <Button color="primary" type="submit" variant="contained">
            Send It!
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

DealerTrackSubmissionDialog.fragments = {
  dealerTrackSubmission: DT_SUB_FRAGMENT,
  creditApp: CREDIT_APP_FRAGMENT,
};

export default DealerTrackSubmissionDialog;
