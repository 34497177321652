import { useMediaQuery } from '@mui/material';

/* Material UI */
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material';
import BlockIcon from '@mui/icons-material/Block';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HomeIcon from '@mui/icons-material/Home';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MailIcon from '@mui/icons-material/Mail';
import PhoneIcon from '@mui/icons-material/Phone';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import { useUserContext } from 'components/MaterialUI/UserContext';

/* internal */
import { Role } from 'constants.js';
import gql from 'graphql-tag';
import { suppressed } from 'modules/customers/components/utils';
import React from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '280px',
    height: '100%',
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  text: {
    fontSize: 14,
  },
  listItemAvatar: {
    minWidth: 40,
  },
}));

const ContactInformationList = ({ customer }) => {
  const classes = useStyles();
  const desktop = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const theme = useTheme();
  const { currentUser } = useUserContext() || {};
  const isLocked = customer?.do_not_contact || false;
  const isHidden = currentUser.role !== Role.ORGANIZATION_ADMIN && isLocked;
  const customerLocation =
    customer?.address && customer?.city && customer?.province
      ? `${customer.address}, ${customer.city}, ${customer.province}`
      : null;

  if (isHidden)
    return 'Contact information is hidden when a profile is do not contact.';

  const TitleAndIcon = ({ icon, children, ...rest }) => (
    <Box display="flex" alignItems="center">
      <Typography {...rest}>{children}</Typography>
      {icon}
    </Box>
  );
  return (
    <List dense={true} style={{ width: '100%', paddingTop: desktop ? 10 : 0 }}>
      <ListItem key={'Contact Information Email'}>
        <ListItemAvatar className={classes.listItemAvatar}>
          <MailIcon />
        </ListItemAvatar>
        <Typography className={classes.text}>
          {customer.emails?.[0]?.email || 'N/A'}
        </Typography>
      </ListItem>
      <ListItem>
        <ListItemAvatar className={classes.listItemAvatar}>
          <PhoneIcon />
        </ListItemAvatar>
        <TitleAndIcon
          className={classes.text}
          icon={
            suppressed(customer.phone, customer) && (
              <Tooltip title="Globally Suppressed">
                <BlockIcon
                  style={{ color: 'red', marginLeft: theme.spacing(1) }}
                />
              </Tooltip>
            )
          }
        >
          {customer.phone || 'N/A'}
        </TitleAndIcon>
      </ListItem>
      <ListItem>
        <ListItemAvatar className={classes.listItemAvatar}>
          <HomeIcon />
        </ListItemAvatar>
        <Typography className={classes.text}>
          {customer.home_phone || 'N/A'}
        </Typography>
      </ListItem>
      <ListItem>
        <ListItemAvatar className={classes.listItemAvatar}>
          <SmartphoneIcon />
        </ListItemAvatar>
        <Typography className={classes.text}>
          {customer.cell_phone || 'N/A'}
          {suppressed(customer.cell_phone, customer) && <BlockIcon />}
        </Typography>
      </ListItem>
      <ListItem>
        <ListItemAvatar className={classes.listItemAvatar}>
          <LocationOnIcon />
        </ListItemAvatar>
        <Typography className={classes.text}>
          {customerLocation || 'N/A'}
        </Typography>
      </ListItem>
    </List>
  );
};

const ContactInformationCard = ({ customer }) => {
  const classes = useStyles();
  const desktop = useMediaQuery(theme => theme.breakpoints.up('sm'));
  return (
    <>
      {desktop ? (
        <Card className={classes.root}>
          <CardContent>
            <Typography className={classes.title} gutterBottom>
              Contact Information
            </Typography>
            <ContactInformationList customer={customer} />
          </CardContent>
        </Card>
      ) : (
        <Accordion defaultExpanded={true}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.title} gutterBottom>
              Contact Information
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{ paddingTop: 0 }}>
            <ContactInformationList customer={customer} />
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
};

ContactInformationCard.fragments = {
  customer: gql`
    fragment ContactInformationCardFragment on Customer {
      do_not_contact
      address
      city
      province
      emails {
        email
      }
      phone
      home_phone
      cell_phone
    }
  `,
};

export default ContactInformationCard;
