import React from 'react';

/* Material UI */
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';

/* internal */
import { useUserContext } from 'components/MaterialUI/UserContext';
import { FACET_FILTER_DISPLAY } from '../constant';

const FilterChips = ({
  filters,
  updateFilters,
  advancedFilter,
  setAdvancedFilter,
}) => {
  const { currentUser } = useUserContext();
  const handleFilter = selectedFilter => {
    const updatedFilters = filters.map(filter => {
      if (filter.name === selectedFilter) {
        return { ...filter, value: null };
      }
      return filter;
    });
    updateFilters(updatedFilters);
  };

  return (
    <Box display="flex" flexWrap="wrap">
      {filters
        .filter(item => item.value !== null)
        .map(filter => (
          <Chip
            key={filter.name}
            label={
              filter.name === 'users' ? currentUser.display_name : filter.value
            }
            value={filter.value}
            style={{ marginRight: '2px' }}
            onDelete={() => handleFilter(filter.name)}
          />
        ))}
      {advancedFilter !== '' && (
        <Chip
          label={FACET_FILTER_DISPLAY[advancedFilter]}
          value={advancedFilter}
          style={{ marginRight: '2px' }}
          onDelete={() => setAdvancedFilter('')}
        />
      )}
    </Box>
  );
};

export default FilterChips;
