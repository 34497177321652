import React from 'react';

import ErrorPage from './ErrorPage';
import { useUserContext } from 'components/MaterialUI/UserContext';

const RequiresDealerIds = ({
  renderOnSuccess,
  requiredDealerIds,
  renderOnFailure,
}) => {
  const {
    currentUser: { dealer_ids },
  } = useUserContext();
  const elseReturn = renderOnFailure ? (
    renderOnFailure()
  ) : (
    <ErrorPage
      errorMessage={
        'You do not have access to this feature. Please contact Market Platform Support.'
      }
    />
  );
  const matchedDealer = dealer_id => requiredDealerIds.includes(dealer_id);
  return dealer_ids && dealer_ids.some(matchedDealer)
    ? renderOnSuccess()
    : elseReturn;
};

export default RequiresDealerIds;
