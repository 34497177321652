import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import Loading from 'components/MaterialUI/Loading';
import DealerPicker from 'components/MaterialUI/DealerPicker';
import DecodeWizardCard from './DecodeWizardCard';
import DecodeWizardProgress from './DecodeWizardProgress';

import Typography from '@mui/material/Typography';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Button from '@mui/material/Button';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Paper from '@mui/material/Paper';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useDealerContext } from 'components/MaterialUI/DealerContext';
import { NetworkStatus } from 'apollo-boost';
import { FinishedDecodingAlert } from '../alerts/DecodeWizardAlerts';

const NOT_DECODED_VEHICLES_QUERY = gql`
  query VehiclesQuery(
    $sort: [QuerySortElement]
    $filters: [QueryFilter]
    $allInStockFacetFilters: [QueryFilter]
    $decodedInStockFilters: [QueryFilter]
    $page: Int!
  ) {
    inventory {
      getVehicles(page: $page, page_size: 10, filters: $filters, sort: $sort) {
        results {
          id
          ...DecodeWizardCardVehicle
        }
        pagination {
          next_page
          page
          page_size
        }
      }
      allInStockFacets: getVehicleFacets(
        filters: $allInStockFacetFilters
        facets: [{ model: "Vehicle", field: "is_decoded" }]
      ) {
        data {
          count
          value
        }
        field
        model
      }
      decodedInStockFacets: getVehicleFacets(
        filters: $decodedInStockFilters
        facets: [{ model: "Vehicle", field: "stock_type" }]
      ) {
        data {
          count
          value
        }
        field
        model
      }
    }
  }
  ${DecodeWizardCard.fragments.vehicle}
`;

const DecodeWizard = () => {
  const { dealerId } = useDealerContext();
  const [stockTypeFilter, setStockTypeFilter] = useState('all');
  const [decodeIndex, setDecodeIndex] = useState(0);
  const [displaySuccess, setDisplaySuccess] = useState(false);
  const sort = {
    model: 'Vehicle',
    field: 'days_in_stock',
    direction: 'asc',
  };

  const allInStockFacetFilters = [
    { model: 'Vehicle', field: 'dealer_id', op: '==', value: dealerId },
    {
      model: 'Vehicle',
      field: 'stock_status_name',
      op: '==',
      value: 'in stock',
    },
  ];

  const filters = [
    ...allInStockFacetFilters,
    { model: 'Vehicle', field: 'is_decoded', op: '==', value: false },
    {
      model: 'Vehicle',
      field: 'stock_type',
      op: 'in',
      value:
        stockTypeFilter === 'new'
          ? ['NEW']
          : stockTypeFilter === 'used'
          ? ['USED']
          : ['USED', 'NEW'], // 'all'
    },
  ];

  const decodedInStockFilters = [
    ...allInStockFacetFilters,
    { model: 'Vehicle', field: 'is_decoded', op: '==', value: false },
  ];

  const {
    loading: vehiclesLoading,
    data: vehiclesData,
    fetchMore: fetchMoreVehicles,
    refetch,
    networkStatus: vehiclesNetworkStatus,
  } = useQuery(NOT_DECODED_VEHICLES_QUERY, {
    variables: {
      page: 1,
      sort,
      filters,
      allInStockFacetFilters,
      decodedInStockFilters,
    },
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    setDecodeIndex(0); // reset decodeIndex when change stockTypeFilter or dealerId
  }, [stockTypeFilter, dealerId]);

  const vehicleInfo = vehiclesData?.inventory.getVehicles.results || [];
  const pagination = vehiclesData?.inventory.getVehicles.pagination || {};

  useEffect(() => {
    const { next_page } = pagination;
    if (next_page && decodeIndex > vehicleInfo.length - 2 && !vehiclesLoading)
      fetchMoreVehicles({
        variables: {
          page: next_page,
        },
        updateQuery: (prev, { fetchMoreResult: more }) => {
          if (!more.inventory.getVehicles.results) return prev;
          return Object.assign({}, prev, {
            inventory: {
              __typename: prev.inventory.__typename,
              getVehicles: {
                __typename: prev.inventory.getVehicles.__typename,
                results: [
                  ...prev.inventory.getVehicles.results,
                  ...more.inventory.getVehicles.results,
                ],
                pagination: more.inventory.getVehicles.pagination,
              },
              allInStockFacets: more.inventory.allInStockFacets,
              decodedInStockFacets: more.inventory.decodedInStockFacets,
            },
          });
        },
      });
  }, [
    decodeIndex,
    fetchMoreVehicles,
    pagination,
    vehicleInfo.length,
    vehiclesData,
    vehiclesLoading,
  ]);

  useEffect(() => {
    const { next_page } = pagination;
    if (decodeIndex >= vehicleInfo.length && decodeIndex > 0 && !next_page)
      setDecodeIndex(vehicleInfo.length - 1);
  }, [decodeIndex, pagination, vehicleInfo.length, vehiclesData]);

  useEffect(() => {
    if (
      vehicleInfo &&
      vehicleInfo[decodeIndex] &&
      vehicleInfo[decodeIndex].is_decoded
    ) {
      refetch();
    }
  }, [decodeIndex, refetch, vehicleInfo]);

  const handleStockTypeFilter = (event, value) => {
    setStockTypeFilter(value);
  };

  const moveForward = () => {
    if (decodeIndex + 1 === vehicleInfo.length) {
      setDisplaySuccess(true);
    } else {
      setDecodeIndex(prev =>
        prev + 1 > vehicleInfo.length - 1 ? vehicleInfo.length - 1 : prev + 1,
      );
    }
  };
  const moveBackward = () => {
    setDecodeIndex(prev => (prev - 1 < 0 ? 0 : prev - 1));
  };

  const allInStockFacets = vehiclesData?.inventory.allInStockFacets || [];
  const decodedFacet = allInStockFacets.find(x => x.field === 'is_decoded');
  const decodedCount =
    decodedFacet?.data.find(x => x.value === 'True')?.count || 0;
  const notDecodedCount =
    decodedFacet?.data.find(x => x.value === 'False')?.count || 0;
  const total = decodedCount + notDecodedCount;

  const decodedInStockFacets =
    vehiclesData?.inventory.decodedInStockFacets || [];
  const stockTypeFacets = decodedInStockFacets.find(
    x => x.field === 'stock_type',
  );
  const usedCount = stockTypeFacets
    ? stockTypeFacets.data.find(x => x.value === 'USED')?.count || 0
    : 0;

  const newCount = stockTypeFacets
    ? stockTypeFacets.data.find(x => x.value === 'NEW')?.count || 0
    : 0;

  const initialVehiclesLoad =
    vehiclesLoading && vehiclesNetworkStatus !== NetworkStatus.fetchMore;
  // fetchMore in the background.
  const mobile = useMediaQuery(theme => theme.breakpoints.up('sm'));
  return (
    <>
      <div style={{ padding: '1rem 0 0 1rem' }}>
        <DealerPicker />
      </div>
      <Typography style={{ padding: '1rem' }} variant="h6">
        VIN Decode Wizard
      </Typography>
      {!initialVehiclesLoad && (
        <DecodeWizardProgress
          decodedCount={decodedCount}
          total={total}
          mobile={mobile}
        />
      )}
      {initialVehiclesLoad && (
        <div style={{ width: `${mobile ? '80%' : '100%'}` }}>
          <Loading />
        </div>
      )}
      <ToggleButtonGroup
        value={stockTypeFilter}
        exclusive
        onChange={handleStockTypeFilter}
        style={{ padding: '1rem' }}
      >
        <ToggleButton value="all">ALL ({usedCount + newCount})</ToggleButton>
        <ToggleButton value="new">NEW ({newCount})</ToggleButton>
        <ToggleButton value="used">USED ({usedCount})</ToggleButton>
      </ToggleButtonGroup>
      <div style={{ padding: '1rem' }}>
        {initialVehiclesLoad && (
          <Paper
            style={{
              width: `${mobile ? '80%' : '100%'}`,
              padding: '1rem',
              height: '50vh',
            }}
          >
            <Loading />
          </Paper>
        )}
        {!initialVehiclesLoad &&
          vehicleInfo.length > 0 &&
          decodeIndex < vehicleInfo.length &&
          !displaySuccess && (
            <Paper
              style={{ width: `${mobile ? '80%' : '100%'}`, padding: '1rem' }}
            >
              <DecodeWizardCard
                vehicle={vehicleInfo[decodeIndex]}
                mobile={mobile}
              />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'flex-end',
                }}
              >
                <Button
                  disabled={vehicleInfo.length === 0}
                  variant="contained"
                  onClick={moveBackward}
                  color="default"
                >
                  {' '}
                  <ArrowBackIosIcon /> {mobile ? 'BACK' : ''}
                </Button>
                <Button
                  disabled={decodeIndex >= vehicleInfo.length}
                  variant="contained"
                  onClick={moveForward}
                  color="default"
                >
                  {mobile ? 'SKIP' : ''} <ArrowForwardIosIcon />
                </Button>
              </div>
            </Paper>
          )}
        {displaySuccess && (
          <div
            style={{
              width: `${mobile ? '60%' : '100%'}`,
              padding: `${mobile ? 0 : '1rem 0 1rem 0'}`,
            }}
          >
            <FinishedDecodingAlert />
          </div>
        )}
        {!initialVehiclesLoad && vehicleInfo.length === 0 && (
          <Paper style={{ width: '80%', padding: '1rem ' }}>
            No vehicles to decode!
          </Paper>
        )}
      </div>
    </>
  );
};

export default DecodeWizard;
