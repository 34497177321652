// External
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

// Material UI
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  useMediaQuery,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

// Internal
import { parseErrors } from 'modules/sms/utils';

import { Regexps } from 'constants.js';

const CREATE_CONVERSATION = gql`
  mutation createOrGetConversation(
    $gatewayId: Int!
    $conversation: ConversationInput!
  ) {
    sms {
      createOrGetConversation(
        gatewayId: $gatewayId
        conversation: $conversation
      ) {
        id
        do_not_contact
      }
    }
  }
`;

const SEND_MESSAGE = gql`
  mutation sendMessage($gatewayId: Int!, $message: SMSMessageInput!) {
    sms {
      sendMessage(gatewayId: $gatewayId, message: $message) {
        id
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 25px',
  },
}));

const CreateChatModal = ({ gatewayId, handleClose, setSelectedChatId }) => {
  const { enqueueSnackbar } = useSnackbar();

  const openOnNewPage = useMediaQuery(theme => theme.breakpoints.down('md'));

  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm({ defaultValues: '', mode: 'onChange' });

  const [conversationId, setConversationId] = useState('');

  const history = useHistory();
  const classes = useStyles();

  const [createConversation] = useMutation(CREATE_CONVERSATION, {
    onCompleted: data => {
      setConversationId(data.sms.createOrGetConversation.id);
    },
    onError: err =>
      enqueueSnackbar(`Error creating conversation: ${parseErrors(err)}`, {
        variant: 'error',
      }),
  });

  const [sendMessage] = useMutation(SEND_MESSAGE, {
    onCompleted: () => {
      openOnNewPage
        ? history.push(`/sms/gateways/${gatewayId}/${conversationId}`)
        : setSelectedChatId(parseInt(conversationId, 10));
    },
    onError: err =>
      enqueueSnackbar(`Error sending message: ${parseErrors(err)}`, {
        variant: 'error',
      }),
  });

  const onSubmit = ({ customerPhone, customerName, newMessage }) => {
    createConversation({
      variables: {
        gatewayId: gatewayId,
        conversation: {
          customer_phone: customerPhone,
          customer_name: customerName,
        },
      },
    })
      .then(({ data }) => {
        if (data.sms.createOrGetConversation?.do_not_contact)
          enqueueSnackbar(
            `Cannot send message; conversation marked as "Do Not Contact"`,
            { variant: 'warning' },
          );
        else
          sendMessage({
            variables: {
              gatewayId: gatewayId,
              message: { sent_to: customerPhone, body: newMessage },
            },
          });
      })
      .then(() => {
        if (!openOnNewPage) {
          handleClose();
        }
      });
  };
  return (
    <>
      <DialogTitle>New Chat</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)} style={{ padding: '0 10px' }}>
        <DialogContent className={classes.root}>
          <Box display="flex" justifyContent="space-between" flexWrap="wrap">
            <TextField
              label="Customer Name"
              {...register('customerName')}
              name="customerName"
              variant="outlined"
              size="small"
              helperText="Optional"
              style={{ margin: '0 10px 15px 0' }}
            />
            <TextField
              placeholder="780-123-4567"
              label="Customer Phone Number"
              {...register('customerPhone', {
                required: { value: true, message: 'Required' },
                pattern: {
                  value: Regexps.PhoneFormat.NANP,
                  message: 'Invalid phone number',
                },
              })}
              type="tel"
              name="customerPhone"
              variant="outlined"
              size="small"
              error={Boolean(errors.customerPhone?.message)}
              helperText={errors.customerPhone?.message}
            />
          </Box>
          <Box mt="15px">
            <TextField
              label="Message"
              {...register('newMessage', {
                required: { value: true, message: 'Required' },
              })}
              name="newMessage"
              variant="outlined"
              size="medium"
              error={Boolean(errors.newMessage?.message)}
              helperText={errors.newMessage?.message}
              multiline
              maxRows={4}
              style={{ width: '100%' }}
              inputProps={{ maxLength: 2000 }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button type="submit" disabled={!isValid}>
            Create
          </Button>
        </DialogActions>
      </form>
    </>
  );
};

export default CreateChatModal;
