import React, { useEffect, useState } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

import { useSnackbar } from 'notistack';
import CircularProgress from '@mui/material/CircularProgress';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import moment from 'moment';

import { cloudinaryCore, stripHTML, escapeRegExp, stringify } from 'utils';
import { titleCase } from '../../utils';
import { useVehiclesQueryContext } from '../contexts/VehiclesQueryContext';
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import BulkVehicleDescription from './BulkVehicleDescription';
import BulkVehiclePricing from './BulkVehiclePricing';
import BulkVehicleMultiLinePricing from './BulkVehicleMultiLinePricing';
import BulkVehiclePromotional from './BulkVehiclePromotional';
import BulkVehicleStatus from './BulkVehicleStatus';

const csvFields = [
  // by default, will use titleCase for the header, and will render the value
  // given by vehicle[field]
  'id',
  'stock_type',
  'stock_number',
  'year',
  'make_name',
  'model_name',
  'trim',
  'trim_variation',
  'published_trim',
  'body_type_name',
  'fuel_type_name',
  'vin',
  'transmission_name',
  'drive_type_name',
  'engine_compressor_name',
  'engine_config_name',
  'engine_cylinders',
  'engine_litres',
  'interior_colour_name',
  'interior_colour_description',
  'published_notes',
  'msrp',
  'regular_price',
  'cost',
  'special_price',
  'special_price_expires',
  'odometer',
  'exterior_colour_description',
  'exterior_colour_name',
  'video_url',
  // 'carproof_selection', // Not sure if this is still applicable or not???
  'photo_count',
  'photos',
  'days_in_stock',
  'is_featured',
  'is_on_special',
  'is_certified',
  'private_notes',
  'promotional_start_date',
  'promotional_expiry_date',
  'promotional_info',
  'date_user_modified',
];

const csvCustom = {
  id: { name: 'Vehicle ID' },
  make_name: { name: 'Make' },
  model_name: { name: 'Model' },
  trim_variation: { name: 'Decoded Trim' },
  body_type_name: { name: 'Body Type' },
  fuel_type_name: { name: 'Fuel' },
  vin: { name: 'VIN' },
  transmission_name: { name: 'Transmission' },
  drive_type_name: { name: 'Drive' },
  engine_compressor_name: { name: 'Compressor' },
  engine_config_name: { name: 'Engine' },
  engine_cylinders: { name: 'Cylinders' },
  engine_litres: { name: 'Litres' },
  interior_colour_name: { name: 'Int. Colour' },
  interior_colour_value: { name: 'Int. Colour Description' },
  exterior_colour_name: { name: 'Ext. Colour' },
  exterior_colour_value: { name: 'Ext. Colour Description' },
  published_notes: {
    name: 'Vehicle Description',
    render: ({ published_notes }) => escapeRegExp(stripHTML(published_notes)),
  },
  msrp: { name: 'MSRP' },
  special_price_expires: { name: 'Special Expires' },
  video_url: { name: 'YouTube URL' },
  photo_count: { name: 'Number of Photos' }, // Is this really necessary?  Isn't Photo Count clear enough?
  photos: {
    name: 'Photo URL(s)',
    render: ({ photos }) =>
      `[${(
        photos?.map(({ cloudinary_public_id }) =>
          cloudinaryCore.url(cloudinary_public_id, {
            width: 600,
            height: 400,
          }),
        ) || []
      ).join(',')}]`,
  },
  promotional_start_date: { name: 'Promotion Notes Start Date' },
  promotional_expiry_date: { name: 'Promotion Notes End Date' },
  promotional_info: {
    name: 'Promotional Notes',
    render: ({ promotional_info }) => escapeRegExp(stripHTML(promotional_info)),
  },
};

const csvHeader = csvFields.map(
  field => csvCustom[field]?.name || titleCase(field),
);

const useImperativeQuery = query => {
  const { refetch } = useQuery(query, { skip: true });

  const imperativelyCallQuery = variables => {
    return refetch(variables);
  };

  return imperativelyCallQuery;
};

const BulkActions = ({
  anchorEl,
  selectByFilter,
  selected,
  handleAnchorClose,
}) => {
  const BULK_VEHICLES_QUERY = gql`
    query BulkVehiclesQuery($filters: [QueryFilter]) {
      inventory {
        getBulkVehicles(filters: $filters) {
          ...BulkActionsVehicle
        }
      }
    }
    ${BulkActions.fragments.vehicle}
  `;

  const PHOTO_URL_QUERY = gql`
    query PhotoURLQuery($prefixes: [String!]!) {
      inventory {
        downloadPhotosURL(prefixes: $prefixes)
      }
    }
  `;

  const { filters, pagination, vehicles } = useVehiclesQueryContext();
  const { dealerId } = useDealerContext();
  const { enqueueSnackbar } = useSnackbar();
  const [showDescription, setShowDescription] = useState(false);
  const [showPromo, setShowPromo] = useState(false);
  const [showPricing, setShowPricing] = useState(false);
  const [showMultiLinePricing, setShowMultiLinePricing] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [creatingCSV, setCreatingCSV] = useState(false);
  const [downloadingPhotos, setDownloadingPhotos] = useState(false);

  const busy = creatingCSV || downloadingPhotos;

  useEffect(() => {
    if (
      !(
        showDescription ||
        showPromo ||
        showPricing ||
        showMultiLinePricing ||
        showStatus
      )
    )
      handleAnchorClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    showDescription,
    showPromo,
    showPricing,
    showMultiLinePricing,
    showStatus,
  ]);

  let stockTypes = vehicles
    .filter(v => selected.includes(v.id))
    .map(({ stock_type }) => stock_type);

  let bothStockTypesExist = ['NEW', 'USED'].every(x => {
    return stockTypes.includes(x);
  });

  const callBulkQuery = useImperativeQuery(BULK_VEHICLES_QUERY);

  const callPhotosURLQuery = useImperativeQuery(PHOTO_URL_QUERY);

  const totalSelected = selectByFilter ? pagination.total : selected.length;

  useEffect(() => {
    const createCSV = async () => {
      let csvData = [];

      if (selectByFilter) {
        // TODO: Calling this query for large number of vehicles takes a bit of
        // time (~30 seconds for 300 vehicles).  It might be better to retrieve
        // a page at a time until all the data is retrieved.  This way we could:
        // - use the existing getVehicles query instead of having to call bulk
        // - provide progress feedback (ie. page x / total_pages)
        // TODO: Error handling.
        const { data } = await callBulkQuery({ filters });
        csvData = data.inventory.getBulkVehicles;
      } else {
        csvData = vehicles.filter(({ id }) => selected.includes(id));
      }

      const rows = [[...csvHeader]].concat(
        csvData.map(v =>
          csvFields.map(field => csvCustom[field]?.render?.(v) || v[field]),
        ),
      );

      const csvContent =
        'data:text/csv;base64,' +
        btoa(unescape(encodeURIComponent(stringify(rows))));
      const csvFileName = `inventory_export_${moment().format(
        'YYYYMMDD_HHmm',
      )}.csv`;

      const element = document.createElement('a');
      element.href = csvContent;
      element.download = csvFileName;
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);

      setCreatingCSV(false);
      handleAnchorClose();
    };

    if (creatingCSV) createCSV();
  }, [
    callBulkQuery,
    creatingCSV,
    filters,
    handleAnchorClose,
    selectByFilter,
    selected,
    vehicles,
  ]);

  useEffect(() => {
    const downloadPhotos = async () => {
      let stockNumbers = [];
      if (selectByFilter) {
        const { data } = await callBulkQuery({ filters });
        stockNumbers = data.inventory.getBulkVehicles.map(v => v.stock_number);
      } else
        stockNumbers = vehicles
          .filter(v => selected.includes(v.id))
          .map(v => v.stock_number);

      const prefixes = stockNumbers.map(
        stockNumber => `inventory/dealer/${dealerId}/${stockNumber}`,
      );

      let {
        data: {
          inventory: { downloadPhotosURL },
        },
      } = await callPhotosURLQuery({ prefixes });

      const element = document.createElement('a');
      element.href = downloadPhotosURL;
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);

      setDownloadingPhotos(false);
      handleAnchorClose();
    };

    if (downloadingPhotos && totalSelected > 20) {
      enqueueSnackbar(
        'Sorry, you may only choose up to a maximum of 20 vehicles for photo download.',
      );
      setDownloadingPhotos(false);
      handleAnchorClose();
    } else if (downloadingPhotos) downloadPhotos();
  }, [
    callBulkQuery,
    callPhotosURLQuery,
    dealerId,
    downloadingPhotos,
    enqueueSnackbar,
    filters,
    handleAnchorClose,
    selectByFilter,
    selected,
    totalSelected,
    vehicles,
  ]);

  const handleMultiLinePricing = () => {
    if (bothStockTypesExist) {
      enqueueSnackbar(
        'Sorry, you may only do bulk multiline pricing for one stock type at a time. Please select only NEW vehicles or only USED vehicles.',
      );
    } else {
      setShowMultiLinePricing(true);
    }
  };

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={!busy ? () => handleAnchorClose() : null}
        style={{ width: '300px' }}
      >
        <MenuItem
          disabled={selected.length === 0 || busy}
          onClick={() => setShowDescription(true)}
        >
          Update Description
        </MenuItem>
        <MenuItem
          disabled={selected.length === 0 || busy}
          onClick={() => setShowPromo(true)}
        >
          Update Promo Info
        </MenuItem>
        <MenuItem
          disabled={selected.length === 0 || busy}
          onClick={() => setShowPricing(true)}
        >
          Update Regular Pricing
        </MenuItem>
        <MenuItem
          disabled={selected.length === 0 || busy}
          onClick={() => handleMultiLinePricing()}
        >
          Update Multi-Line Pricing
        </MenuItem>
        <MenuItem
          disabled={selected.length === 0 || busy}
          onClick={() => setShowStatus(true)}
        >
          Update Status
        </MenuItem>
        {creatingCSV ? (
          <MenuItem disabled>
            <ListItemIcon style={{ minWidth: '25px' }}>
              <CircularProgress size={20} />
            </ListItemIcon>
            <ListItemText primary="Generating CSV..." />
          </MenuItem>
        ) : (
          <MenuItem
            disabled={selected.length === 0 || busy}
            onClick={() => setCreatingCSV(true)}
          >
            Export to CSV
          </MenuItem>
        )}
        {downloadingPhotos ? (
          <MenuItem disabled>
            <ListItemIcon style={{ minWidth: '25px' }}>
              <CircularProgress size={20} />
            </ListItemIcon>
            <ListItemText primary="Downloading..." />
          </MenuItem>
        ) : (
          <MenuItem
            disabled={selected.length === 0 || busy}
            onClick={() => setDownloadingPhotos(true)}
          >
            Download Photos
          </MenuItem>
        )}
      </Menu>
      <BulkVehicleDescription
        onClose={() => setShowDescription(false)}
        open={showDescription}
        ids={selected}
        selectByFilter={selectByFilter}
      />
      <BulkVehiclePromotional
        onClose={() => setShowPromo(false)}
        open={showPromo}
        ids={selected}
        selectByFilter={selectByFilter}
      />
      <BulkVehicleStatus
        onClose={() => setShowStatus(false)}
        open={showStatus}
        ids={selected}
        selectByFilter={selectByFilter}
      />
      <BulkVehiclePricing
        onClose={() => setShowPricing(false)}
        open={showPricing}
        ids={selected}
        selectByFilter={selectByFilter}
      />
      <BulkVehicleMultiLinePricing
        onClose={() => setShowMultiLinePricing(false)}
        open={showMultiLinePricing}
        ids={selected}
        selectByFilter={selectByFilter}
        stockTypes={stockTypes}
      />
    </>
  );
};

BulkActions.fragments = {
  vehicle: gql`
    fragment BulkActionsVehicle on GreaseInventoryVehicle {
      photos {
        cloudinary_public_id
      }
      ${csvFields.filter(x => x !== 'photos').join('\n')}
    }
  `,
};
export default BulkActions;
