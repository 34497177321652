/* External */
import React from 'react';

/* Material UI */
import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { DateTimePicker } from '@mui/x-date-pickers';

/* Internal */
import { CONVERSATION_ACTIVITY } from './const.js';

const useStyles = makeStyles(theme => ({
  h6: {
    fontSize: '1.125rem',
    fontWeight: 'bold',
    marginBottom: '5px',
  },
}));

const BulkSendStep3 = ({
  bulkSendName,
  messagePreview,
  messageCount,
  setSendScheduledDate,
  sendScheduledDate,
  setScheduleSend,
  inactiveDays,
  setInactiveDays,
}) => {
  const classes = useStyles();

  const handleSchedulingSend = date => {
    setSendScheduledDate(date);
    setScheduleSend(Boolean(date));
  };

  return (
    <>
      <Grid item xs={12} style={{ marginBottom: '15px' }}>
        <Typography className={classes.h6}>{bulkSendName}</Typography>
        <Typography>
          Your bulk message is ready to go with <b>{messageCount} messages</b>.
          You can send it now, or schedule it for later.
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ marginTop: '15px' }}>
        <TextField
          label="Message Preview"
          value={messagePreview}
          multiline
          minRows={5}
          maxRows={5}
          style={{ width: '100%', padding: '5px' }}
          disabled
        />
      </Grid>
      <Grid
        container
        item
        xs={12}
        spacing={1}
        alignItems="center"
        style={{ marginTop: '10px' }}
      >
        <Grid item xs={12} md={7}>
          <Typography>Skip numbers with conversation activity:</Typography>
        </Grid>
        <Grid item xs={12} md={5}>
          <FormControl variant="outlined" style={{ width: '100%' }}>
            <Select
              id="skip-activity"
              value={inactiveDays}
              onChange={e => setInactiveDays(e.target.value)}
            >
              {CONVERSATION_ACTIVITY.map(activity => (
                <MenuItem value={activity.value} key={activity.id}>
                  {activity.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={12}
        spacing={1}
        style={{ margin: '10px 0 20px 0' }}
      >
        <Grid
          container
          item
          style={{
            marginTop: '10px',
          }}
          alignItems="center"
          xs={12}
        >
          <Grid item xs={12} md={7}>
            <Typography>
              <b>Schedule the bulk send to start on:</b>
            </Typography>
          </Grid>
          <Grid item xs={12} md={5}>
            <DateTimePicker
              clearable
              variant="dialog"
              format="YYYY-MM-DD @ hh:mm a"
              id="date-picker-inline"
              value={sendScheduledDate}
              onChange={date => handleSchedulingSend(date)}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default BulkSendStep3;
