import React from 'react';

import RenderableRadioGroup from 'components/MaterialUI/RenderableRadioGroup';
import { CBB_CONDITIONS } from 'modules/inventory/const';

import { titleCase } from 'modules/inventory/utils';

export const CBBTrimRadioGroup = ({ trimNames, ...props }) => (
  <RenderableRadioGroup
    {...props}
    options={trimNames.map(trim => ({
      name: trim || 'Default',
      value: trim,
    }))}
  />
);

export const CBBStyleRadioGroup = ({ styleNames, ...props }) => (
  <RenderableRadioGroup
    {...props}
    options={styleNames.map(style => ({
      name: style,
      value: style,
    }))}
  />
);

export const CBBConditionRadioGroup = ({ ...props }) => (
  <RenderableRadioGroup
    {...props}
    options={CBB_CONDITIONS.map(_condition => ({
      name: titleCase(_condition),
      value: _condition,
    }))}
  />
);
