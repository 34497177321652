import React from 'react';
import { isEmpty } from 'lodash';

// MaterialUI
import { Box, Grid } from '@mui/material';

// internal
import { formatPrice, getMediumDateTimeWithWeekday } from 'utils';
import { COLOUR_MATCH } from '../const';

const Declined = ({ vehicle }) => {
  const { myBid, status, declineReason, winningBidAmount } = vehicle;
  return (
    <>
      <Grid
        container
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingBottom: '1rem',
        }}
        direction="row"
        xs={12}
      >
        <Grid
          item
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
          xs={6}
        >
          <Box>Bidding has now ended.</Box>
        </Grid>
        <Grid
          item
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
          xs={6}
        >
          <Box style={{ color: 'grey' }}>
            Highest Bid: {formatPrice(winningBidAmount, { nullDisplay: '-' })}
          </Box>
          <Box style={{ color: 'grey' }}>
            Your Bid ({myBid?.isCustomerDeclined ? 'Declined' : '?'}
            ): {isEmpty(myBid) ? 'You Did Not Bid' : formatPrice(myBid.amount)}
          </Box>
        </Grid>
      </Grid>
      <Box
        style={{
          fontWeight: 'bold',
          paddingRight: '1rem',
          paddingBottom: '0.5rem',
        }}
      >
        CUSTOMER RESPONSE -{' '}
        <span style={{ color: COLOUR_MATCH[status].backgroundColor }}>
          {status}
        </span>
      </Box>
      <Box style={{ display: 'flex', flexDirection: 'row' }}>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            paddingRight: '1rem',
          }}
        >
          <Box
            style={{
              paddingRight: '1rem',
              paddingBottom: '0.5rem',
              fontWeight: 'bold',
            }}
          >
            Declined On:
          </Box>
          {!isEmpty(myBid) && myBid.customerDeclinedDate
            ? getMediumDateTimeWithWeekday(myBid.customerDeclinedDate + 'Z')
            : '-'}
        </Box>
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
          <Box
            style={{
              paddingRight: '1rem',
              paddingBottom: '0.5rem',
              fontWeight: 'bold',
            }}
          >
            Reason:
          </Box>
          {declineReason}
        </Box>
      </Box>
    </>
  );
};
export default Declined;
