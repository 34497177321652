import React, { useEffect } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

import Grid from '@mui/material/Grid';

import CustomerCard from './CustomerCard';
import StepTwo from '../StepTwo/StepTwo';
import MergedCustomerCard from '../MergedCustomerCard';
import MergeSuccessful from '../MergeSuccessful';

const DETAILS_QUERY = gql`
  query customerDetails($ids: [ID]) {
    customers(ids: $ids) {
      _id
      app_account {
        username
        createdAt
        modifiedAt
      }
      primary_phone
      emails {
        email
      }
      assigned_salespeople {
        dealer_id
      }
      ...CustomerCardFragment
      ...StepTwoFragment
      ...MergedCustomerCardFragment
      ...MergeSuccessfulFragment
    }
  }
  ${CustomerCard.fragments.customer}
  ${StepTwo.fragments.customer}
  ${MergedCustomerCard.fragments.customer}
  ${MergeSuccessful.fragments.customer}
`;

const StepOne = ({ ids, selectedCustomers, setSelectedCustomers }) => {
  const splitIds = ids.split(',');
  const { data } = useQuery(DETAILS_QUERY, {
    variables: { ids: splitIds },
  });

  const customers = data?.customers || [];

  useEffect(() => {
    if (customers?.length === 2 && selectedCustomers.length !== 2) {
      setSelectedCustomers(customers);
    }
  });

  return (
    <Grid
      container
      direction="row"
      style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        padding: '2rem 0rem',
      }}
    >
      {customers.map((customer, index) => (
        <Grid item key={index} xs={6} style={{ padding: '0rem 1rem' }}>
          <CustomerCard
            customer={customer}
            selectedCustomers={selectedCustomers}
            setSelectedCustomers={setSelectedCustomers}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default StepOne;
