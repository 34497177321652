import React from 'react';

/* external */
import { useHistory } from 'react-router-dom';

import { useMutation, useQuery } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import gql from 'graphql-tag';

/* Material UI */
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

/* internal */
import { snackBarSettings } from 'constants.js';
import AuctionForm, { processAuctionData } from './AuctionForm';
import AuctionsTableRow from './AuctionsTableRow';
import ErrorPage from 'components/MaterialUI/ErrorPage';
import Loading from 'components/MaterialUI/Loading';
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';

const GET_AUCTION = gql`
  query auction($id: Int!) {
    auctions {
      auction(id: $id) {
        id
        ...AuctionFormAuction
      }
    }
  }
  ${AuctionForm.fragments.auction}
`;

const UPDATE_AUCTION = gql`
  mutation auction($id: Int!, $data: AuctionUpdate!) {
    auctions {
      updateAuction(id: $id, data: $data) {
        id
        ...AuctionsTableRowAuction
        ...AuctionsTableRowAuctionPermissions
      }
    }
  }
  ${AuctionsTableRow.fragments.auction}
  ${AuctionsTableRow.fragments.auctionPermissions}
`;
const AuctionEdit = ({ id }) => {
  const { data, error, loading } = useQuery(GET_AUCTION, {
    variables: { id },
    fetchPolicy: 'network-only',
  });
  const { enqueueSnackbar: snackIt } = useSnackbar();
  const history = useHistory();
  const auction = data?.auctions.auction || {};

  const [updateAuction, { error: submitErrors, loading: updating }] =
    useMutation(UPDATE_AUCTION);

  const updateSuccess = () => {
    snackIt('Created auction successfully!', {
      ...snackBarSettings,
      variant: 'success',
    });
    history.push('/auctions');
  };

  const handleSubmit = ({ isWeekly, isDaily, ...auction }) =>
    updateAuction({
      variables: { id, data: processAuctionData(auction) },
    }).then(updateSuccess, () => {});

  if (loading) return <Loading />;
  if (error) return <ErrorPage error={error} />;
  return (
    <Box margin={1}>
      <Typography variant="h5">Edit Auction</Typography>
      <AuctionForm
        auction={auction}
        onSubmit={handleSubmit}
        submitErrors={submitErrors}
      />
      <LoadingBackdrop open={updating}>Updating Auction...</LoadingBackdrop>
    </Box>
  );
};

export default AuctionEdit;
