import React, { useState } from 'react';

/* external */
import { useMutation, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useSnackbar } from 'notistack';

/* Material UI */
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { makeStyles } from '@mui/styles';
import DeleteIcon from '@mui/icons-material/Delete';

/* internal */
import Loading from 'components/MaterialUI/Loading';
import { Role } from 'constants.js';

const USERS_FOR_DEALER = gql`
  query UsersForDealer($dealerIds: [Int]!) {
    users: users(
      dealer_ids: $dealerIds
      status: active
      roles: [
        "${Role.SALES_REP}"
        "${Role.SALES_MANAGER}"
        "${Role.FINANCE_MANAGER}"
        "${Role.FINANCE_DIRECTOR}"
        "${Role.BDC_REP}"
        "${Role.CSR}"
        "${Role.BDC_MANAGER}"
        "${Role.INTERNET_SALES_REP}"
        "${Role.GENERAL_MANAGER}"
      ]
    ) {
      display_name
      username
      role
      profile {
        image_id
      }
    }
  }
`;

const ASSIGN_SALES_REP = gql`
  mutation assignSalesRep($_id: ID!, $input: SalesRepsInput!) {
    assignSalesRepsToOpportunity(_id: $_id, input: $input) {
      _id
      sales_reps {
        username
        display_name
      }
    }
  }
`;

const ASSIGN_SALES_MANAGER = gql`
  mutation assignSalesMgr($_id: ID!, $input: SalesManagersInput!) {
    assignSalesManagersToOpportunity(_id: $_id, input: $input) {
      _id
    }
  }
`;

const ASSIGN_CUSTOMER_REP = gql`
  mutation assignCustomerRep($_id: ID!, $input: CustomerRepsInput!) {
    assignCustomerRepsToOpportunity(_id: $_id, input: $input) {
      _id
    }
  }
`;

const ASSIGN_BDC_REP = gql`
  mutation assignBDCReps($_id: ID!, $input: BDCRepsInput!) {
    assignBDCRepsToOpportunity(_id: $_id, input: $input) {
      _id
    }
  }
`;

const ASSIGN_FINANCE_REP = gql`
  mutation assignFinanceRep($_id: ID!, $input: FinanceManagersInput!) {
    assignFinanceManagersToOpportunity(_id: $_id, input: $input) {
      _id
    }
  }
`;

const BULK_UPDATE_TASKS = gql`
  mutation UpdateTasks($ids: [ID!]!, $input: UpdateTaskInput!) {
    updateTasks(ids: $ids, input: $input) {
      _id
      assignee {
        username
        display_name
        role
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  icon: {
    alignSelf: 'flex-end',
    paddingBottom: '5px',
    paddingLeft: '20px',
  },
  button: {
    color: '#24A0ED',
    fontWeight: 'bold',
  },
}));

const AssignRepsModal = ({
  opportunity,
  handleClose,
  refetch,
  refetchOpportunity,
}) => {
  // We have a query for each rep so we will store each rep separately
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [salesRep1, setSalesRep1] = useState(
    opportunity?.sales_reps.length !== 0
      ? opportunity.sales_reps[0].username
      : '',
  );
  const [salesRep2, setSalesRep2] = useState(
    opportunity?.sales_reps.length === 2
      ? opportunity.sales_reps[1].username
      : '',
  );
  const [bdcRep1, setBdcRep1] = useState(
    opportunity.bdc_reps.length !== 0 ? opportunity.bdc_reps[0].username : '',
  );
  const [bdcRep2, setBdcRep2] = useState(
    opportunity.bdc_reps.length === 2 ? opportunity.bdc_reps[1].username : '',
  );
  const [salesManager, setSalesManager] = useState(
    opportunity.sales_managers.length !== 0
      ? opportunity.sales_managers[0].username
      : '',
  );
  const [financeManager, setFinanceManager] = useState(
    opportunity.finance_managers.length !== 0
      ? opportunity.finance_managers[0].username
      : '',
  );
  const [customerRep, setCustomerRep] = useState(
    opportunity.customer_reps.length !== 0
      ? opportunity.customer_reps[0].username
      : '',
  );
  const { data, loading } = useQuery(USERS_FOR_DEALER, {
    variables: {
      dealerIds: [opportunity.dealer_id],
    },
  });

  const [updateSalesManager] = useMutation(ASSIGN_SALES_MANAGER, {
    onCompleted: data => {
      refetchOpportunity();
      enqueueSnackbar('Updated Sales Manager', { variant: 'success' });
    },
  });

  const [updateSalesReps] = useMutation(ASSIGN_SALES_REP, {
    onCompleted: data =>
      enqueueSnackbar('Updated Sales Reps', { variant: 'success' }),
  });

  const [updateCustomerRep] = useMutation(ASSIGN_CUSTOMER_REP, {
    onCompleted: data =>
      enqueueSnackbar('Updated Customer Rep', { variant: 'success' }),
  });

  const [updateBdcReps] = useMutation(ASSIGN_BDC_REP, {
    onCompleted: data =>
      enqueueSnackbar('Updated BDC Reps', { variant: 'success' }),
  });

  const [updateFinanceRep] = useMutation(ASSIGN_FINANCE_REP, {
    onCompleted: data => {
      refetch();
      enqueueSnackbar('Updated Finance Producer', { variant: 'success' });
    },
  });

  const [bulkReassignTasks] = useMutation(BULK_UPDATE_TASKS, {
    onCompleted: data => {
      refetch();
      enqueueSnackbar('Bulk reassign successfully', { variant: 'success' });
    },
    onError: err => {
      enqueueSnackbar(`Could not bulk reassign task error: ${err}`, {
        variant: 'error',
      });
    },
  });

  const handleRepChange = (rep, first = true) => {
    // Conditionally render list based on which field was updated
    const reps = [first ? rep : salesRep1, !first ? rep : salesRep2].filter(
      r => r !== '',
    );

    updateSalesReps({
      variables: {
        _id: opportunity._id,
        input: { sales_reps: reps },
      },
    });

    if (first || reps.length !== 2) {
      bulkReassignTasks({
        variables: {
          ids: opportunity.tasks.filter(t => t.status === 0).map(t => t._id),
          input: {
            assignee: reps.length ? reps[0] : '',
          },
        },
      });
    }

    first ? setSalesRep1(rep) : setSalesRep2(rep);
  };

  const handleBdcChange = (rep, first = true) => {
    const bdcs = [first ? rep : bdcRep1, !first ? rep : bdcRep2].filter(
      r => r !== '',
    );
    updateBdcReps({
      variables: {
        _id: opportunity._id,
        input: { bdc_reps: bdcs },
      },
    });
    first ? setBdcRep1(rep) : setBdcRep2(rep);
  };

  const handleSalesMgrChange = rep => {
    updateSalesManager({
      variables: {
        _id: opportunity._id,
        input: { sales_managers: rep !== '' ? [rep] : [] },
      },
    });
  };

  const handleCustRepChange = rep => {
    updateCustomerRep({
      variables: {
        _id: opportunity._id,
        input: { customer_reps: rep !== '' ? [rep] : [] },
      },
    });
  };

  const handleFinanceRepChange = rep => {
    updateFinanceRep({
      variables: {
        _id: opportunity._id,
        input: { finance_managers: rep !== '' ? [rep] : [] },
      },
    });
  };

  if (loading) {
    return <Loading />;
  }

  const salesManagers = data.users
    .filter(
      user =>
        user.role === Role.SALES_MANAGER || user.role === Role.GENERAL_MANAGER,
    )
    .sort((a, b) => a.display_name.localeCompare(b.display_name));
  const customerReps = data.users
    .filter(user => user.role === Role.CSR || user.role === Role.SALES_MANAGER)
    .sort((a, b) => a.display_name.localeCompare(b.display_name));
  const salesReps = data.users
    .filter(user => Role.ALL_SALES.includes(user.role))
    .sort((a, b) => a.display_name.localeCompare(b.display_name));
  const bdcReps = data.users
    .filter(user => Role.ALL_BDC.includes(user.role))
    .sort((a, b) => a.display_name.localeCompare(b.display_name));
  const financeManagers = data.users
    .filter(
      user =>
        Role.ALL_FINANCE.includes(user.role) ||
        user.role === Role.GENERAL_MANAGER,
    )
    .sort((a, b) => a.display_name.localeCompare(b.display_name));

  return (
    <>
      <DialogTitle>Assign Reps</DialogTitle>
      <DialogContent style={{ minWidth: '200px' }}>
        <Grid container direction="row" spacing={0} alignItems="stretch">
          <Grid item xs={10} style={{ marginBottom: '10px' }}>
            <InputLabel>Sales Person</InputLabel>
            <Select
              value={salesRep1}
              onChange={e => {
                handleRepChange(e.target.value);
              }}
              style={{ width: '85%' }}
              placeholder="Assign"
            >
              <MenuItem value={''}>Assign</MenuItem>
              {salesReps &&
                salesReps.map(rep => (
                  <MenuItem key={`SR1${rep.username}`} value={rep.username}>
                    {rep.display_name}
                  </MenuItem>
                ))}
            </Select>
          </Grid>
          <Grid item xs={2} className={classes.icon}>
            <IconButton size="small" onClick={() => handleRepChange('')}>
              <DeleteIcon />
            </IconButton>
          </Grid>
          <Grid item xs={10} style={{ marginBottom: '10px' }}>
            <InputLabel>Sales Person</InputLabel>
            <Select
              value={salesRep2}
              onChange={e => handleRepChange(e.target.value, false)}
              style={{ width: '85%' }}
              placeholder="Assign"
            >
              <MenuItem value={''}>Assign</MenuItem>
              {salesReps &&
                salesReps.map(rep => (
                  <MenuItem key={`SR1${rep.username}`} value={rep.username}>
                    {rep.display_name}
                  </MenuItem>
                ))}
            </Select>
          </Grid>
          <Grid item xs={2} className={classes.icon}>
            <IconButton size="small" onClick={() => handleRepChange('', false)}>
              <DeleteIcon />
            </IconButton>
          </Grid>
          <Grid item xs={10} style={{ marginBottom: '10px' }}>
            <InputLabel>Sales Manager</InputLabel>
            <Select
              value={salesManager}
              onChange={e => {
                handleSalesMgrChange(e.target.value);
                setSalesManager(e.target.value);
              }}
              style={{ width: '85%' }}
              placeholder="Assign"
            >
              <MenuItem value={''}>Assign</MenuItem>
              {salesManagers &&
                salesManagers.map(rep => (
                  <MenuItem key={`SR1${rep.username}`} value={rep.username}>
                    {rep.display_name}
                  </MenuItem>
                ))}
            </Select>
          </Grid>
          <Grid item xs={2} className={classes.icon}>
            <IconButton
              size="small"
              onClick={() => {
                handleSalesMgrChange('');
                setSalesManager('');
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Grid>
          <Grid item xs={10} style={{ marginBottom: '10px' }}>
            <InputLabel>Customer Sales Rep</InputLabel>
            <Select
              value={customerRep}
              onChange={e => {
                handleCustRepChange(e.target.value);
                setCustomerRep(e.target.value);
              }}
              style={{ width: '85%' }}
              placeholder="Assign"
            >
              <MenuItem value={''}>Assign</MenuItem>
              {customerReps &&
                customerReps.map(rep => (
                  <MenuItem key={`SR1${rep.username}`} value={rep.username}>
                    {rep.display_name}
                  </MenuItem>
                ))}
            </Select>
          </Grid>
          <Grid item xs={2} className={classes.icon}>
            <IconButton
              size="small"
              onClick={() => {
                handleCustRepChange('');
                setCustomerRep('');
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Grid>
          <Grid item xs={10} style={{ marginBottom: '10px' }}>
            <InputLabel>BDC Rep</InputLabel>
            <Select
              value={bdcRep1}
              onChange={e => handleBdcChange(e.target.value)}
              style={{ width: '85%' }}
              placeholder="Assign"
            >
              <MenuItem value={''}>Assign</MenuItem>
              {bdcReps &&
                bdcReps.map(rep => (
                  <MenuItem key={`SR1${rep.username}`} value={rep.username}>
                    {rep.display_name}
                  </MenuItem>
                ))}
            </Select>
          </Grid>
          <Grid item xs={2} className={classes.icon}>
            <IconButton size="small" onClick={() => handleBdcChange('')}>
              <DeleteIcon />
            </IconButton>
          </Grid>
          <Grid item xs={10} style={{ marginBottom: '10px' }}>
            <InputLabel>BDC Rep</InputLabel>
            <Select
              value={bdcRep2}
              onChange={e => handleBdcChange(e.target.value, false)}
              style={{ width: '85%' }}
              placeholder="Assign"
            >
              <MenuItem value={''}>Assign</MenuItem>
              {bdcReps &&
                bdcReps.map(rep => (
                  <MenuItem key={`SR1${rep.username}`} value={rep.username}>
                    {rep.display_name}
                  </MenuItem>
                ))}
            </Select>
          </Grid>
          <Grid item xs={2} className={classes.icon}>
            <IconButton size="small" onClick={() => handleBdcChange('', false)}>
              <DeleteIcon />
            </IconButton>
          </Grid>
          <Grid item xs={10} style={{ marginBottom: '10px' }}>
            <InputLabel>Finance Producer</InputLabel>
            <Select
              value={financeManager}
              onChange={e => {
                handleFinanceRepChange(e.target.value);
                setFinanceManager(e.target.value);
              }}
              style={{ width: '85%' }}
              placeholder="Assign"
            >
              <MenuItem value={''}>Assign</MenuItem>
              {financeManagers &&
                financeManagers.map(rep => (
                  <MenuItem key={`SR1${rep.username}`} value={rep.username}>
                    {rep.display_name}
                  </MenuItem>
                ))}
            </Select>
          </Grid>
          <Grid item xs={2} className={classes.icon}>
            <IconButton
              size="small"
              onClick={() => {
                handleFinanceRepChange('');
                setFinanceManager('');
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button className={classes.button} onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </>
  );
};

export default AssignRepsModal;
