/* External */
import React from 'react';
import { capitalize } from 'lodash';
import moment from 'moment-timezone';

/* Material UI */
import {
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Info } from '@mui/icons-material';

/* Internal */

const SUPPRESSED_ERROR_MSGS = [
  'GSLException',
  'DNCException',
  'RateLimitError',
];

// ** STYLES **

const useStyles = makeStyles(theme => ({
  truncateText: {
    maxWidth: '300px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '3',
    WebkitBoxOrient: 'vertical',
  },
  infoButton: {
    color: theme.colours.add,
  },
}));

function StatusCell({ message }) {
  let deliveryStatus = capitalize(message.delivery_status);

  if (
    deliveryStatus === 'Undelivered' &&
    SUPPRESSED_ERROR_MSGS.includes(message.error_msg)
  ) {
    deliveryStatus = 'Suppressed';
  }

  return <TableCell>{deliveryStatus}</TableCell>;
}

const MessageSearchTableRows = ({ messages, gatewayId }) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <>
      {messages.map(message => (
        <TableRow key={message.id}>
          <TableCell>
            <Tooltip
              title={
                message.conversation_id
                  ? 'Click me to open the conversation in a new tab!'
                  : ''
              }
            >
              {/* Add <span> wrapper to allow a tooltip with a button that is disabled
         (complains if there isn't a wrapper around it) */}
              <span>
                <IconButton disabled={!message.conversation_id} size="large">
                  <a
                    href={`/sms/gateways/${gatewayId}/${message.conversation_id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Info
                      size="small"
                      style={{
                        color: message.conversation_id
                          ? theme.actions.info.backgroundColor
                          : theme.actions.disabled.backgroundColor,
                      }}
                    />
                  </a>
                </IconButton>
              </span>
            </Tooltip>
          </TableCell>
          <TableCell style={{ minWidth: '110px' }}>
            {message.date_created
              ? moment(message.date_created).format('YYYY-M-D kk:mm:ss')
              : '—'}
          </TableCell>
          <TableCell>
            {message.direction === 'inbound' ? 'Inbound' : 'Outbound'}
          </TableCell>
          <TableCell>
            {message.conversation?.operator_user && (
              <p>
                {message.conversation?.operator_user?.display_name} <br />
                {`(${message.conversation?.operator_user?.username})`}
              </p>
            )}
            {!message.conversation?.operator_user && <p>—</p>}
          </TableCell>
          <TableCell>
            {message.conversation?.customer?.fullname ?? 'Unknown Customer'}
          </TableCell>
          <TableCell>{message.sent_to}</TableCell>
          <TableCell>
            <p className={classes.truncateText}>{message.body}</p>
          </TableCell>
          <TableCell>{message.bulk_send_label ?? '—'}</TableCell>
          <StatusCell message={message} />
          <TableCell>
            {message.conversation?.archived
              ? message.conversation.archived
                ? 'Yes'
                : 'No'
              : '—'}
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};

export default MessageSearchTableRows;
