/* external */
import { Editor } from '@tinymce/tinymce-react';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import gql from 'graphql-tag';
import React, { useState } from 'react';

/* material-ui */
import Alert from '@mui/material/Alert';
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
} from '@mui/material';

/* internal */
import { tinyMCEApiUrl } from 'constants.js';
import ErrorDisplay from 'components/MaterialUI/ErrorDisplay';

const UPDATE_PUBLISHED_NOTES = gql`
  mutation updatePublishedNotes(
    $filters: [QueryFilter!]!
    $description: String!
  ) {
    npv {
      updateBulkVehicles(
        filters: $filters
        data: { description: $description }
      ) {
        id
        description
      }
    }
  }
`;

const BulkVehicleDescription = ({
  ids,
  onClose,
  open,
  selectByFilter,
  vehicleFilters,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [description, setDescription] = useState();

  const [update, updateStatus] = useMutation(UPDATE_PUBLISHED_NOTES, {
    onCompleted: data => {
      onClose();
      enqueueSnackbar(
        `Successfully updated ${data.npv.updateBulkVehicles.length} vehicles' descriptions.`,
      );
    },
    onError: () => null,
  });

  const filters = selectByFilter
    ? vehicleFilters
    : [{ model: 'Vehicle', field: 'id', op: 'in', value: ids }];
  const handleSubmit = () => update({ variables: { filters, description } });
  const handleEditorChange = x => setDescription(x);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{'Set Bulk Vehicle Description'}</DialogTitle>
      <DialogContent>
        {updateStatus.error && (
          <Alert severity="warning">
            <ErrorDisplay
              error={updateStatus.error}
              action="Updating Vehicles' Descriptions"
            />
          </Alert>
        )}
        <Editor
          apiKey={tinyMCEApiUrl}
          onEditorChange={handleEditorChange}
          plugins="wordcount"
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        {updateStatus.loading ? (
          <CircularProgress
            size={20}
            style={{ marginLeft: '40px', marginRight: '30px' }}
          />
        ) : (
          <Button
            autoFocus
            disabled={!description}
            onClick={handleSubmit}
            variant="contained"
            style={{
              backgroundColor: `${!description ? '#bfbebe' : '#74B72E'}`,
            }}
          >
            Save
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default BulkVehicleDescription;
