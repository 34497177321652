import React, { useState } from 'react';

import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import { useMediaQuery } from '@mui/material';
import { useDealerContext } from 'components/MaterialUI/DealerContext';

import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DealerPicker from 'components/MaterialUI/DealerPicker';
import Button from '@mui/material/Button';
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material';

const ADD_NOTE = gql`
  mutation addNote($input: NoteInput) {
    createNote(input: $input) {
      _id
    }
  }
`;

const AddNoteModal = ({ handleClose, onSave, customerID }) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const desktop = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const [visibility, setVisibility] = useState('store_only');
  const [note, setNote] = useState('');
  const { dealerId } = useDealerContext();
  const [addNote] = useMutation(ADD_NOTE, {
    onCompleted: () => {
      onSave();
      enqueueSnackbar('Note Saved!', { variant: 'success' });
      handleClose();
    },
    onError: err => {
      enqueueSnackbar(`Could not save note error: ${err}`, {
        variant: 'error',
      });
    },
  });

  const handleVisability = event => {
    setVisibility(event.target.value);
  };

  const handleNote = event => {
    setNote(event.target.value);
  };

  const handleSubmit = () => {
    const noteData = {
      body: note,
      visibility,
      customer_id: customerID,
      dealer_id: dealerId,
    };
    if (!noteData.body) {
      enqueueSnackbar('Did not save, note is empty.', { variant: 'error' });
    } else {
      addNote({
        variables: {
          input: noteData,
        },
      });
    }
  };

  return (
    <>
      <DialogTitle>Add a Note</DialogTitle>
      <DialogContent>
        <DealerPicker />
        <FormControl
          style={
            desktop ? { margin: '8px 0px 0px 25px' } : { marginTop: '8px' }
          }
        >
          <InputLabel shrink>Visibility</InputLabel>
          <Select value={visibility} onChange={handleVisability}>
            <MenuItem value="store_only">Store Only</MenuItem>
            <MenuItem value="managers_only">Managers Only</MenuItem>
            <MenuItem value="organization_wide">Organization Wide</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogContent>
        <TextField
          label="Note"
          value={note}
          onChange={handleNote}
          multiline
          maxRows={8}
          style={{ display: 'flex' }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="outlined">
          Cancel
        </Button>
        <Button
          style={theme.actions.confirm}
          onClick={handleSubmit}
          variant="contained"
          color="primary"
        >
          Save
        </Button>
      </DialogActions>
      <LoadingBackdrop open={false} />
    </>
  );
};

export default AddNoteModal;
