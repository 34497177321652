import React from 'react';

/* external */
import { Link } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

/* Material UI */
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Tooltip from '@mui/material/Tooltip';

/* internal */
import { formatPrice } from 'utils';
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import ItemDescriptionCell from './common/ItemDescriptionCell';
import ItemEndTimeCell from './common/ItemEndTimeCell';
import ItemFavouriteCell from './common/ItemFavouriteCell';
import ItemPhotoCell from './common/ItemPhotoCell';

const CREATE_BID = gql`
  mutation createBid($bid: BidInput!) {
    auctions {
      createBid(bid: $bid) {
        id
        amount
        auctionItemId
        dealerId
      }
    }
  }
`;

const WinningItemsRow = ({ item, carfax, ...rest }) => {
  const { dealerId } = useDealerContext();
  const theme = useTheme();
  const {
    id,
    bidCount,
    dealerBids = [],
    dealerId: itemDealerId,
    dealerProxyBids = [],
    deleted,
    deletionReason,
    isUpcoming,
    isActive,
    leadingBid,
    minimumBidIncrement,
    minimumStartingBid,
    reserveNotMet,
  } = item;
  const hasBids = !deleted && dealerBids.length > 0;
  const minimumBid = leadingBid
    ? leadingBid.amount + minimumBidIncrement
    : minimumStartingBid;
  const hasProxy =
    dealerProxyBids.length > 0 &&
    dealerProxyBids.sort((a, b) => b.id - a.id)[0].amount > 0;
  const isWinning =
    !deleted && leadingBid && leadingBid.dealer?.id === dealerId;
  const isOutbid = hasBids && !isWinning;
  const canBid =
    isActive && itemDealerId !== dealerId && !isWinning && !deleted;

  const [createBid, { loading: bidding }] = useMutation(CREATE_BID);

  const handleClickBid = e => {
    e.stopPropagation();
    createBid({
      variables: { bid: { dealerId, amount: minimumBid, auctionItemId: id } },
    });
  };

  return (
    <TableRow hover={!deleted} {...rest}>
      <TableCell>
        <Box display="flex" flexDirection="row" alignItems="start">
          <Tooltip title={'Click me to find out more about this auction item!'}>
            <IconButton size="large">
              <Link to={`/auctions/items/${id}`}>
                <InfoIcon
                  style={{
                    color: '#2196f3',
                    fontSize: '22px',
                  }}
                />
              </Link>
            </IconButton>
          </Tooltip>

          {canBid && (
            <Button
              disabled={bidding}
              variant="contained"
              onClick={handleClickBid}
              style={theme.actions.confirm}
            >
              Bid {formatPrice(minimumBid)}
            </Button>
          )}
        </Box>
      </TableCell>
      <ItemPhotoCell item={item} />
      <ItemDescriptionCell
        hasProxy={hasProxy}
        item={item}
        isWinning={isActive && isWinning}
        isOutbid={isActive && isOutbid}
        carfax={carfax}
      />
      {deleted ? (
        <TableCell colSpan={5}>Deleted because: "{deletionReason}"</TableCell>
      ) : (
        <>
          <ItemFavouriteCell item={item} />
          <ItemEndTimeCell item={item} countdown />
          <TableCell>{bidCount}</TableCell>
          <TableCell>{formatPrice(minimumStartingBid)}</TableCell>
          <TableCell>
            {formatPrice(leadingBid?.amount)}
            <br />
            {leadingBid?.dealer?.name}
            {!isActive && !isUpcoming && reserveNotMet && (
              <Box padding={0} fontSize="11px" color="red" fontWeight="bold">
                <br />
                RESERVE NOT MET
              </Box>
            )}
          </TableCell>
        </>
      )}
    </TableRow>
  );
};

WinningItemsRow.fragments = {
  auctionItem: gql`
    fragment WinningItemsRowAuctionItem on AuctionItem {
      id
      bidCount
      dealer {
        id
        name
      }
      dealerBids(dealerId: $dealerId) {
        id
        amount
      }
      dealerProxyBids(dealerId: $dealerId) {
        id
        amount
      }
      dealerId
      deleted
      deletionReason
      isActive
      reserveNotMet
      leadingBid {
        id
        dealer {
          id
          name
        }
        amount
      }
      minimumBidIncrement
      minimumStartingBid
      permissions {
        delete
        update
      }
      startsAt
      ...ItemDescriptionCellAuctionItem
      ...ItemEndTimeCellAuctionItem
      ...ItemFavouriteCellAuctionItem
      ...ItemPhotoCellAuctionItem
    }
    ${ItemDescriptionCell.fragments.auctionItem}
    ${ItemEndTimeCell.fragments.auctionItem}
    ${ItemFavouriteCell.fragments.auctionItem}
    ${ItemPhotoCell.fragments.auctionItem}
  `,
  auctionItemFeed: gql`
    fragment AuctionItemFeed on AuctionItem {
      id
      bidCount
      dealer {
        id
        name
      }
      dealerId
      deleted
      deletionReason
      isActive
      isUpcoming
      leadingBid {
        id
        dealer {
          id
          name
        }
        amount
      }
      minimumBidIncrement
      minimumStartingBid
      reserveNotMet
      startsAt
      vehicle {
        id
        year
        make
        model
        stockNumber
        odometer
      }
      ...ItemDescriptionCellAuctionItem
      ...ItemEndTimeCellAuctionItem
      ...ItemPhotoCellAuctionItem
    }
    ${ItemDescriptionCell.fragments.auctionItem}
    ${ItemEndTimeCell.fragments.auctionItem}
    ${ItemPhotoCell.fragments.auctionItem}
  `,
};
export default WinningItemsRow;
