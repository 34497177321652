import React, { useEffect, useState } from 'react';

/* external */
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';

/* Material UI */
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';

/* internal */
import Loading from 'components/MaterialUI/Loading';

const VOID_PACKAGE = gql`
  mutation cancelPackage($id: ID!, $input: CancelPackageInput!) {
    concierge {
      cancelPackage(id: $id, input: $input) {
        id
        cancelledReason
        payments {
          id
          status
          amount
        }
      }
    }
  }
`;

const CancelPackageModal = ({ goldCard, isOpen, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [cancelledReason, setCancelledReason] = useState('');

  const [cancelPackage, { loading }] = useMutation(VOID_PACKAGE, {
    onCompleted: data => {
      enqueueSnackbar('Package successfully cancelled.', {
        variant: 'success',
      });
      onClose();
    },
    onError: () => {
      enqueueSnackbar('Failed to cancel package.', { variant: 'error' });
    },
  });

  const handleSubmit = () => {
    cancelPackage({
      variables: { id: goldCard.id, input: { cancelledReason } },
    });
  };

  useEffect(() => {
    setCancelledReason('');
  }, [isOpen]);

  if (loading) {
    return <Loading />;
  }

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        onClose();
      }}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Cancel Package</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to cancel this package?
        </DialogContentText>
        <TextField
          label="Enter a reason:"
          name="cancelledReason"
          onChange={({ target: { value } }) => setCancelledReason(value)}
          required
        />
      </DialogContent>
      <DialogActions>
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          size="small"
          onClick={handleSubmit}
        >
          Complete Cancellation
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CancelPackageModal;
