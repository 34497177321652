/* External */
import { useMutation, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';

/* Material UI */
import {
  Box,
  Button,
  Grid,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import ErrorDisplay from 'components/MaterialUI/ErrorDisplay';
import Loading from 'components/MaterialUI/Loading';

/* Internal */
import ConfirmDialog from 'components/MaterialUI/ConfirmDialog';
import SelectControl from 'components/MaterialUI/SelectControl2';
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import PermissionTableRow from './PermissionTableRow';
import UserTableRow from './UserTableRow';
import SelectBillingDealer from './SelectBillingDealer';
import { SMS_PERMISSION_TYPES } from './const';
import ActionsMenu from './ActionsMenu';

const useStyles = makeStyles(theme => ({
  phoneNumber: {
    fontWeight: 400,
    color: theme.colours.greys.greytext1,
    marginLeft: '10px',
  },
  h6: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
    marginBottom: '15px',
  },
  button: {
    backgroundColor: theme.colours.add,
    color: 'white',
    marginLeft: '20px',
  },
}));

const PERMISSIONS_FRAGMENT = gql`
  fragment PermissionsGatewayData on Gateway {
    permissions {
      id
      dealerId
      gatewayId
      permissionType
      role
      dealer {
        id
        name
      }
    }
  }
`;

const SINGLE_PERMISSION_FRAGMENT = gql`
  fragment SinglePermissionGatewayData on GatewayPermissions {
    id
    dealerId
    gatewayId
    permissionType
    role
  }
`;

const GATEWAY_QUERY = gql`
  query gatewayQuery($gatewayId: Int!, $gatewayUsersId: Int!) {
    sms {
      gateway(id: $gatewayId) {
        id
        name
        phone_number
        billing_dealer_id
        ...PermissionsGatewayData
      }
      gatewayUsers(id: $gatewayUsersId) {
        display_name
        role
        username
      }
    }
  }
  ${PERMISSIONS_FRAGMENT}
`;

export const UPDATE_PERMISSIONS = gql`
  mutation updateGatewayPermissions(
    $gatewayId: Int!
    $data: GatewayPermissionsInput!
  ) {
    sms {
      updateGatewayPermissions(gatewayId: $gatewayId, data: $data) {
        ...SinglePermissionGatewayData
      }
    }
  }
  ${SINGLE_PERMISSION_FRAGMENT}
`;

const DELETE_PERMISSION = gql`
  mutation deleteGatewayPermission($permissionId: Int!) {
    sms {
      deleteGatewayPermission(permissionId: $permissionId) {
        ...SinglePermissionGatewayData
      }
    }
  }
  ${SINGLE_PERMISSION_FRAGMENT}
`;

const ROLES_QUERY = gql`
  query rolesQuery {
    roles {
      id
      name
    }
  }
`;

const SMSPermissions = ({ gatewayId }) => {
  const theme = useTheme();
  const { dealers } = useDealerContext();
  const { enqueueSnackbar } = useSnackbar();
  const desktop = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const classes = useStyles();

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [selectedPermissionId, setSelectedPermissionId] = useState(null);

  const { loading, data, error, refetch } = useQuery(GATEWAY_QUERY, {
    variables: {
      gatewayId,
      gatewayUsersId: gatewayId,
    },
  });

  const {
    loading: rolesLoading,
    data: rolesData,
    error: rolesError,
  } = useQuery(ROLES_QUERY);

  const formMethods = useForm({
    defaultValues: {
      dealerId: null,
      role: null,
      permissionType: null,
    },
  });

  const { control, handleSubmit } = formMethods;

  const [updatePermissions] = useMutation(UPDATE_PERMISSIONS, {
    onCompleted: () => {
      enqueueSnackbar('Permissions Updated!', { variant: 'success' });
      refetch();
    },
    onError: err => {
      enqueueSnackbar(`Could not update permissions, error: ${err}`, {
        variant: 'error',
      });
    },
  });

  const [deletePermission] = useMutation(DELETE_PERMISSION, {
    onCompleted: () => {
      enqueueSnackbar('Permission Deleted!', { variant: 'success' });
      setSelectedPermissionId(null);
      setShowConfirmDialog(false);
      refetch();
    },
    onError: err => {
      enqueueSnackbar(`Could not delete permission, error: ${err}`, {
        variant: 'error',
      });
    },
  });

  const handleDelete = () => {
    deletePermission({
      variables: {
        permissionId: selectedPermissionId,
      },
    });
  };

  if (loading || rolesLoading) {
    return <Loading />;
  }

  if (error || rolesError) {
    return (
      <ErrorDisplay
        error={error || rolesError}
        action={`Loading ${error ? 'Gateway' : 'Roles'} Data`}
      />
    );
  }

  const onSubmit = permission => {
    updatePermissions({
      variables: {
        gatewayId,
        data: {
          ...permission,
        },
      },
    });
  };

  const rolesOptions = rolesData.roles
    .sort((a, b) => a.name.localeCompare(b.name))
    .map(({ id, name }) => ({ value: id, name }));

  const dealerOptions = dealers
    .sort((a, b) => a.dealer_name.localeCompare(b.dealer_name))
    .map(({ dealer_id, dealer_name }) => ({
      value: dealer_id,
      name: dealer_name,
    }));

  const permissionTableData = data.sms.gateway.permissions ?? [];

  const usersTableData = data.sms.gatewayUsers ?? [];

  const permissionOptions = SMS_PERMISSION_TYPES.map(
    ({ id, permission_name }) => ({ value: id, name: permission_name }),
  );

  return (
    <>
      <ConfirmDialog
        isOpen={showConfirmDialog}
        onConfirm={handleDelete}
        onClose={() => setShowConfirmDialog(false)}
        titleText="Delete this permission?"
        text=""
        confirmText="Delete"
        abortText="Cancel"
        confirmButtonProps={{ style: theme.actions.danger }}
      />
      <Box margin="40px 20px 0 20px" maxWidth="100%">
        <Box display="flex" alignItems="flex-end" mb="30px" flexWrap="wrap">
          <Typography variant="h5" style={{ fontWeight: 'bold' }}>
            Permissions - {data?.sms.gateway.name}
          </Typography>
          <Typography variant="body1" className={classes.phoneNumber}>
            {data?.sms.gateway.phone_number}
          </Typography>
        </Box>
        <Box display="flex" alignItems="flex-end">
          <Typography className={classes.h6}>Billing Dealer</Typography>
          <Box marginLeft="auto">
            <ActionsMenu gateway={data?.sms.gateway} version="detailed" />
          </Box>
        </Box>
        <SelectBillingDealer
          billingDealerId={data?.sms.gateway.billing_dealer_id}
          gatewayId={gatewayId}
          dealerOptions={dealerOptions}
          classes={classes}
          isDesktop={desktop}
        />
      </Box>
      <Box margin="40px 20px 0 20px" maxWidth="90%">
        <Box mb="20px">
          <Typography className={classes.h6}>Permissions</Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3} alignItems="flex-end">
              <Grid item xs={12} sm={6} md={3}>
                <SelectControl
                  name="dealerId"
                  label="Dealer"
                  control={control}
                  options={dealerOptions}
                  fullWidth
                  required
                  noNull
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <InputLabel id="role-label">Role</InputLabel>
                <SelectControl
                  name="role"
                  control={control}
                  options={rolesOptions}
                  fullWidth
                  required
                  noNull
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <InputLabel id="permission-label">Permission</InputLabel>
                <SelectControl
                  name="permissionType"
                  control={control}
                  options={permissionOptions}
                  fullWidth
                  required
                  noNull
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <span>
                  <Button
                    type="submit"
                    variant="contained"
                    className={classes.button}
                    onClick={() => null}
                  >
                    Add Permission
                  </Button>
                </span>
              </Grid>
            </Grid>
          </form>
        </Box>
        <Grid container spacing={desktop ? 4 : 1}>
          <Grid item xs={12} md={7} style={{ maxHeight: '600px' }}>
            <TableContainer style={{ maxHeight: '90%' }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Dealer</TableCell>
                    <TableCell>Role</TableCell>
                    <TableCell>SMS Permission</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {permissionTableData.length === 0
                    ? 'No permissions to display'
                    : permissionTableData.map(permission => (
                        <PermissionTableRow
                          key={permission.id}
                          permission={permission}
                          rolesData={rolesData}
                          setShowConfirmDialog={setShowConfirmDialog}
                          setSelectedPermissionId={setSelectedPermissionId}
                        />
                      ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} md={5} style={{ maxHeight: '600px' }}>
            <TableContainer style={{ maxHeight: '90%' }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>User</TableCell>
                    <TableCell>Role</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {usersTableData.length === 0
                    ? 'No users to display'
                    : usersTableData.map(user => (
                        <UserTableRow
                          key={user.username}
                          user={user}
                          rolesData={rolesData}
                        />
                      ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

SMSPermissions.fragments = {
  gateway: PERMISSIONS_FRAGMENT,
  permission: SINGLE_PERMISSION_FRAGMENT,
};

export default SMSPermissions;
