/* external */
import gql from 'graphql-tag';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/react-hooks';

/* Material UI */
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  useMediaQuery,
} from '@mui/material';

import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material';

import {
  Check as CheckIcon,
  Close as CloseIcon,
  SentimentVeryDissatisfied as SentimentVeryDissatisfiedIcon,
  VerifiedUser as VerifiedUserIcon,
} from '@mui/icons-material';

import Autocomplete from '@mui/material/Autocomplete';
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import KeyboardDatePickerControl from 'components/MaterialUI/KeyboardDatePickerControl';

/* internal */
import Loading from 'components/MaterialUI/Loading';
import SelectControl from 'components/MaterialUI/SelectControl2';
import TextFieldControl from 'components/MaterialUI/TextFieldControl';
import { useUserContext } from 'components/MaterialUI/UserContext';
import { AppointmentStatus } from 'constants.js';
import { defaultTZ } from 'utils';
import { TIME_SLOTS } from './constant';

const useStyles = makeStyles(theme => ({
  div: {
    padding: '0px 1rem 1rem',
    display: 'flex',
    flexDirection: 'row',
  },
  textField: {
    maxWidth: '60%',
    width: '100%',
    display: 'inline-grid',
    margin: '8px 0px',
  },
  dialogTitle: {
    padding: '16px 38px',
  },
  closeButton: {
    fontWeight: '600',
    fontSize: 16,
    color: '#24a0ed',
  },
}));

const USERS_FOR_DEALER = gql`
  query UsersForDealer($dealerId: [Int]!) {
    users: users(dealer_ids: $dealerId, status: active) {
      display_name
      username
      role
    }
  }
`;

const UPDATE_APPOINTMENT = gql`
  mutation updateDeliveryAppointment(
    $_id: ID!
    $input: UpdateAppointmentInput!
  ) {
    updateAppointment(_id: $_id, input: $input) {
      _id
      start_date
      opportunity_id
      status
      type_id
      dealer {
        dealer_name
      }
      subject
      type_name
      status_name
      created
      created_by_username
      attendee_usernames
      attendees {
        display_name
        username
        role
        profile {
          image_id
        }
      }
    }
  }
`;

const CREATE_APPOINTMENT = gql`
  mutation createAppointment($input: AppointmentInput!) {
    createAppointment(input: $input) {
      _id
      attendees {
        display_name
        username
      }
      attendee_usernames
      created_by {
        username
      }
      dealer {
        dealer_name
      }
      status
      dealer_id
      status_name
      is_upcoming
      is_confirmed
      start_date
      type_id
      type_name
    }
  }
`;

const SET_DELIVERY_APPOINTMENT_ATTENDEES = gql`
  mutation setDeliveryAttendees($_id: ID!, $usernames: [String]!) {
    setAppointmentAttendees(_id: $_id, usernames: $usernames) {
      _id
      start_date
      dealer {
        dealer_name
      }
      opportunity_id
      status
      type_id
      subject
      type_name
      status_name
      created
      created_by_username
      attendee_usernames
      attendees {
        display_name
        username
        role
        profile {
          image_id
        }
      }
    }
  }
`;

const ADD_NOTE = gql`
  mutation addNote($input: NoteInput) {
    createNote(input: $input) {
      _id
    }
  }
`;

export const APPOINTMENT_TYPES = [
  { value: 1, text: 'Consultation' },
  { value: 2, text: 'Test Drive' },
  { value: 3, text: 'Finance' },
  { value: 4, text: 'Delivery' },
  { value: 5, text: 'Other' },
];

const OpportunityAppointmentModal = ({
  handleClose,
  customer,
  opportunity,
  updateStatus,
  appointment,
  refetch,
}) => {
  const isUpdate = appointment !== null;
  const isDesktop = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { dealerId } = useDealerContext();
  const { currentUser } = useUserContext();
  const classes = useStyles();
  const { control, handleSubmit, formState } = useForm({
    defaultValues: isUpdate
      ? {
          type: String(appointment.type_id),
          start_date: appointment.start_date,
          time_slot: String(
            moment(appointment.start_date).tz(defaultTZ).format('HH:mm'),
          ),
        }
      : {},
    shouldUnregister: true,
  });
  const { dirtyFields } = formState;
  const { data: userData, loading: userLoading } = useQuery(USERS_FOR_DEALER, {
    variables: { dealerId },
  });

  const [updateAttendees] = useMutation(SET_DELIVERY_APPOINTMENT_ATTENDEES, {
    onCompleted: data => {
      if (data) {
        enqueueSnackbar(`Successfully updated delivery attendees`, {
          variant: 'success',
        });
        refetch();
      }
    },
    onError: e =>
      enqueueSnackbar(`Error in updating delivery attendees`, {
        variant: 'error',
      }),
  });

  const [addNote] = useMutation(ADD_NOTE, {
    onCompleted: () => {
      enqueueSnackbar('Note Saved!', { variant: 'success' });
    },
    onError: err => {
      enqueueSnackbar(`Could not save note error: ${err}`, {
        variant: 'error',
      });
    },
  });

  const [createAppointment] = useMutation(CREATE_APPOINTMENT, {
    onCompleted: data => {
      enqueueSnackbar(`Successfully created appointment`, {
        variant: 'success',
      });
      refetch();
    },
    onError: e =>
      enqueueSnackbar(`Error in creating appointment, ${e}`, {
        variant: 'error',
      }),
  });

  const [updateAppointment] = useMutation(UPDATE_APPOINTMENT, {
    onCompleted: data => {
      if (data) {
        enqueueSnackbar(
          isUpdate
            ? `Appointment updated!`
            : `Successfully confirmed appointment`,
          {
            variant: 'success',
          },
        );
        refetch();
      }
    },
    onError: e =>
      enqueueSnackbar(
        isUpdate
          ? `Error updating appointment, ${e}`
          : `Error in confirming appointment, ${e}`,
        {
          variant: 'error',
        },
      ),
  });

  const onSubmit = formValue => {
    if (formValue?.notes !== '') {
      const noteData = {
        body: formValue.notes,
        customer_id: customer._id,
        dealer_id: opportunity.dealer_id,
        references: [{ type: 'Opportunity', value: opportunity._id }],
        visibility: 'store_only',
      };
      addNote({
        variables: {
          input: noteData,
        },
      });
    }

    const time = formValue.time_slot.split(':');
    const attendee = formValue?.created_by?.username
      ? [formValue?.created_by?.username]
      : [currentUser.username];

    const appointmentPayload = {
      attendee_usernames: attendee,
      customer_id: customer._id,
      dealer_id: opportunity.dealer_id,
      opportunity_id: opportunity._id,
      start_date: moment(formValue.start_date)
        .set('hour', parseInt(time[0]))
        .set('minute', parseInt(time[1]))
        .tz(defaultTZ)
        .toISOString(),
      subject: APPOINTMENT_TYPES.find(
        app => parseInt(app.value, 10) === parseInt(formValue.type, 10),
      ).text,
      type_id: parseInt(formValue.type),
    };

    if (isUpdate) {
      // Checking to see if any fields are dirty
      // so we aren't updating appointments unnecessarily
      if (Object.keys(dirtyFields).length > 0) {
        // check if the attendee field has been changed - if this is the only
        // field that has been changed, we won't need to update the appointment
        if (dirtyFields?.created_by) {
          updateAttendees({
            variables: {
              _id: appointment._id,
              usernames: [formValue?.created_by?.username],
            },
          });
        }
        // If there are more dirty fields in addition to the created_by field,
        // or if there are dirty field(s) and created_by is not one of them,
        // we'll update the appointment. This logic will prevent the appointment
        // from updating unnecessarily if only created_by has been edited
        if (
          Object.keys(dirtyFields).length > 1 ||
          !dirtyFields.hasOwnProperty('created_by')
        ) {
          updateAppointment({
            variables: {
              _id: appointment._id,
              input: appointmentPayload,
            },
          });
        }
      }
    } else {
      createAppointment({
        variables: {
          input: appointmentPayload,
        },
      });
    }
    handleClose();
  };

  const confirmAppointment = appointment_id => {
    updateAppointment({
      variables: {
        _id: appointment_id,
        input: {
          is_confirmed: true,
          confirmation: {
            dealer_id: dealerId,
            username: currentUser.username,
          },
        },
      },
    });
    handleClose();
  };

  if (userLoading) {
    return <Loading />;
  }

  return (
    <>
      <DialogTitle className={classes.dialogTitle}>
        <h2>{isUpdate ? 'Edit' : 'Book'} an Appointment</h2>
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <div className={isDesktop ? classes.div : ''}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              spacing={3}
            >
              {/* Left column */}
              <Grid item>
                <Grid
                  container
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={3}
                >
                  <Grid item xs={isDesktop ? 0 : 12}>
                    <SelectControl
                      control={control}
                      label="Type"
                      name="type"
                      options={APPOINTMENT_TYPES}
                      optionNameKey="text"
                      style={{ width: 250 }}
                      noNull
                      required
                    />
                  </Grid>
                  <Grid item>
                    <Controller
                      render={({ field: { onChange, ...props } }) => (
                        <Autocomplete
                          options={userData?.users || []}
                          forcePopupIcon={true}
                          getOptionLabel={option => {
                            return String(option.display_name);
                          }}
                          renderOption={(props, option) => (
                            <li {...props}>
                              <span value={option.username}>
                                {option.display_name}
                              </span>
                            </li>
                          )}
                          renderInput={params => (
                            <TextField
                              {...params}
                              style={{ width: 250 }}
                              label="Assigned To"
                            />
                          )}
                          onChange={(e, data) => onChange(data)}
                          {...props}
                        />
                      )}
                      onChange={([, data]) => data}
                      name="created_by"
                      defaultValue={
                        appointment
                          ? userData.users.find(
                              user =>
                                user.username ===
                                appointment.attendees?.[0]?.username,
                            )
                          : null
                      }
                      control={control}
                    />
                  </Grid>
                  <Grid item>
                    <TextFieldControl
                      control={control}
                      name="notes"
                      label="Added Notes"
                      inputProps={{ style: { width: 250 } }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {/* Calendar */}
              <Grid item xs={isDesktop ? 0 : 12}>
                <KeyboardDatePickerControl
                  control={control}
                  variant={isDesktop ? 'static' : 'inline'}
                  required
                  minDate={new Date()}
                  name="start_date"
                  placeholder="Date"
                />
              </Grid>
              {/* Time slots */}
              <Grid item xs={isDesktop ? 0 : 12}>
                <Grid
                  container
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={3}
                >
                  <Grid item>
                    <SelectControl
                      control={control}
                      name="time_slot"
                      label="Time Slots"
                      options={TIME_SLOTS}
                      optionNameKey="value"
                      optionValueKey="key"
                      style={{ width: 250 }}
                      nullDisplay=""
                      required
                    />
                  </Grid>
                  {isUpdate && (
                    <>
                      <Grid item>
                        <Grid
                          container
                          direction="column"
                          justifyContent="center"
                          alignItems="stretch"
                          spacing={2}
                        >
                          {!appointment.is_confirmed && (
                            <Grid item>
                              <Button
                                onClick={confirmAppointment.bind(
                                  this,
                                  appointment._id,
                                )}
                                color="primary"
                                variant="contained"
                                style={{
                                  backgroundColor: theme.actions.info,
                                  width: '100%',
                                }}
                              >
                                <VerifiedUserIcon />
                                {'CONFIRM'}
                              </Button>
                            </Grid>
                          )}
                          <Grid item>
                            <Button
                              onClick={() => {
                                updateStatus(
                                  appointment._id,
                                  AppointmentStatus.ATTENDED,
                                );
                                handleClose();
                              }}
                              color="primary"
                              variant="contained"
                              style={{
                                backgroundColor: theme.actions.info,
                                width: '100%',
                              }}
                            >
                              <CheckIcon />
                              {'SHOW'}
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              onClick={() => {
                                updateStatus(
                                  appointment._id,
                                  AppointmentStatus.NO_SHOW,
                                );
                                handleClose();
                              }}
                              color="primary"
                              variant="contained"
                              style={{
                                backgroundColor: theme.actions.info,
                                width: '100%',
                              }}
                            >
                              <SentimentVeryDissatisfiedIcon />
                              {'NO SHOW'}
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              onClick={() => {
                                updateStatus(
                                  appointment._id,
                                  AppointmentStatus.CANCELLED,
                                );
                                handleClose();
                              }}
                              color="primary"
                              style={{
                                backgroundColor: theme.actions.info,
                                width: '100%',
                              }}
                              variant="contained"
                            >
                              <CloseIcon />
                              {'CANCEL'}
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className={classes.closeButton}>
            Close
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            style={{ backgroundColor: '#74B72E' }}
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </>
  );
};

export default OpportunityAppointmentModal;
