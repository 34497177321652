import React, { useRef, useState } from 'react';
import { withStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import { cloneDeep } from 'lodash';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

const FCPPForm = props => {
  const { enqueueSnackbar } = useSnackbar();

  // fcpp form fields
  const engine = {
    change_oil: ['Change Oil, Lube Filter', '(Mandatory)', '', ''], //[title, secondary title, background color, indentation]
    noise: ['Noise (Lifter, Piston)', '(Hot and Cold)', 'grey', ''],
    motor_mounts: ['Motor Mounts', '', '', ''],
    turbo: ['Turbo/Supercharger', '(If Equipped)', '', ''],
    fluid_leaks: ['Fluid Leaks:', '', 'grey', ''],
    intake: ['Intake', '', 'grey', 'indent'],
    oil_pan: ['Oil Pan', '', 'grey', 'indent'],
    cylinder_heads: ['Cylinder Heads', '', 'grey', 'indent'],
    rear_main: ['Rear Main', '', 'grey', 'indent'],
    misc: ['Misc.', '', 'grey', 'indent'],
  };
  const transmission = {
    fluid_level: ['Fluid Level/Condition', '(Clean, Discoloured)', 'grey'],
    shift_quality: ["Shift Quality (Syncro's)", '(If Manual)', ''],
    shift_points: ['Shift Points', '(If Automatic)', ''],
    slipping: ['Slipping', '(If Automatic)', ''],
    fluid_leaks: ['Fluid Leaks/Seals/Gaskets', '', 'grey'],
  };
  const axle = {
    noise: 'Noise/Vibration',
    fluid_leaks: 'Fluid Leaks/Seals/Gaskets',
    cv_bearing: 'CV/U Joints/Drive Shaft/Steady Bearing',
    wheel_bearings: 'Wheel Bearings/Spindles',
    cv_boots: 'CV boots',
    locking_hubs: 'Locking Hubs',
    hub_assembly: 'Hub/Bearing Assembly',
  };
  const steering = {
    fluid_level: 'Fluid Level/Leaks',
    steering_rack: 'Steering Rack inc. Bellows',
    lines: 'Lines/Hoses/Pump',
    linkages: 'Linkages/Tie Rods/Couplings',
    idle_arms: 'Idle/Pitman Arms',
    steering_dampener: 'Steering Dampener',
    alignment_pull: 'Alignment Pull',
  };
  const brake = {
    master_cylinder: ['Master Cylinder/Fluid Level', ''],
    pump: ['Pump/Booster', ''],
    caliper: ['Caliper/Wheel Cylinders', ''],
    antilock: ['Anti-Lock Brakes', '(Is the Light On?)'],
    abs_check: ['ABS Check for Codes', ''],
    cracks: ['Inspect Brake Lines for Cracks', '(Steel/Rubber)'],
    pads_remaining: ["Pads MM's Remaining", ''],
    tires_remaining: ["Tires MM's Remaining", ''],
  };
  const ac = {
    blower_motor: ['Blower Motor', '(All Speeds)'],
    compressor: ['Compressor/Clutch', ''],
    condenser: ['Condenser/Evaporator', ''],
    idler_pulley: ['Idler pulley', ''],
  };
  const electrical = {
    alternator: 'Alternator',
    starter: 'Starter/Solenoid/Drive',
    power_windows: 'Power Windows/Seat Motor',
    wiper_motors: 'Wiper Motors/Washers',
    power_locks: 'Power Locks/Trunk Release',
    power_roof_motor: 'Power Roof Motor',
    power_antenna: 'Power Antenna',
    rear_defroster: 'Rear Defroster',
    all_manual_switches: 'All Manual Switches',
    cruise_control: 'Cruise Control',
    computer_scan_codes: 'Computer Scan Codes',
    odometer: 'Speedometer/Odometer',
    instrument_panel: 'Instrument Panel/Gauges/Lights',
    horn_indicators: 'Horn/Warning Indicators',
    radio: 'Radio/CD/Tape/Speakers/Amp',
  };
  const suspension = {
    control_arms: 'Control Arms/Bushings',
    ball_joints: 'Ball Joints',
    mounts: 'Mounts/Bushings',
    shocks: 'Shocks/Springs/Struts',
    torsion_bars: 'Torsion Bars/Radius Arms',
    stabilizer_shaft: 'Stabilizer Shaft/Links/Bushings',
    electronic_air_suspension: 'Electronic Air Suspension',
  };
  const cooling = {
    water_pump: 'Water Pump/Thermostat',
    engine_fan: 'Engine Fan/Module/Clutch',
    belts: 'Belts & Hoses',
    radiator: 'Radiator/Heater Core',
    coolant_level: 'Coolant Level/Condition/Leaks',
    coolant_strength: 'Coolant Strength/Condition',
    coolant_system_pressure_test: 'Coolant System Pressure Test',
    fuel_tank: 'Fuel Tank/Lines',
    fuel_injection_pump: 'Fuel Injection Pump/Vacuum Pump',
  };
  const roadtest = {
    engine: 'Engine Performance',
    transmission: 'Transmission Performance',
    shifting_smoothness: 'Up/Down Shifting Smoothness',
    transmission_noise: 'Transmission Noise/Vibration',
    rear_differential: 'Rear Differential Performance',
    rear_differential_noise: 'Rear Differential Noise/Vibration',
    transfer_case: 'Transfer Case Performance (4x4)',
    transfer_case_noise: 'Transfer Case Noise/Vibration (4x4)',
    brake: 'Brake Performance (Inc. ABS)',
    brake_noise: 'Brake Noise/Vibration',
    suspension_noise: 'Suspension Noise/Vibration',
  };
  const alterations = {
    lift_kit: 'Lift Kit',
    air_induction_system: 'Air Induction System Altered',
    tire_size_matches: 'Tire Size Matches Vehicle Build Plate',
  };

  let engineObj = cloneDeep(engine);
  let transmissionObj = cloneDeep(transmission);
  let axleObj = cloneDeep(axle);
  let steeringObj = cloneDeep(steering);
  let brakeObj = cloneDeep(brake);
  let acObj = cloneDeep(ac);
  let electricalObj = cloneDeep(electrical);
  let suspensionObj = cloneDeep(suspension);
  let coolingObj = cloneDeep(cooling);
  let roadtestObj = cloneDeep(roadtest);
  let alterationsObj = cloneDeep(alterations);

  Object.entries(engineObj).map(([name]) => (engineObj[name] = 'Good'));
  Object.entries(transmissionObj).map(
    ([name]) => (transmissionObj[name] = 'Good'),
  );
  Object.entries(axleObj).map(([name]) => (axleObj[name] = 'Good'));
  Object.entries(steeringObj).map(([name]) => (steeringObj[name] = 'Good'));
  Object.entries(brakeObj).map(([name]) => (brakeObj[name] = 'Good'));
  Object.entries(acObj).map(([name]) => (acObj[name] = 'Good'));
  Object.entries(electricalObj).map(([name]) => (electricalObj[name] = 'Good'));
  Object.entries(suspensionObj).map(([name]) => (suspensionObj[name] = 'Good'));
  Object.entries(coolingObj).map(([name]) => (coolingObj[name] = 'Good'));
  Object.entries(roadtestObj).map(([name]) => (roadtestObj[name] = 'Good'));
  Object.entries(alterationsObj).map(
    ([name]) => (alterationsObj[name] = 'Yes'),
  );

  const [engineValues, setEngineValues] = useState(engineObj);
  const [transmissionValues, setTransmissionValues] = useState(transmissionObj);
  const [axleValues, setAxleValues] = useState(axleObj);
  const [steeringValues, setSteeringValues] = useState(steeringObj);
  const [brakeValues, setBrakeValues] = useState(brakeObj);
  const [acValues, setAcValues] = useState(acObj);
  const [electricalValues, setElectricalValues] = useState(electricalObj);
  const [suspensionValues, setSuspensionValues] = useState(suspensionObj);
  const [coolingValues, setCoolingValues] = useState(coolingObj);
  const [roadtestValues, setRoadtestValues] = useState(roadtestObj);
  const [alterationsValues, setAlterationsValues] = useState(alterationsObj);

  const handleEngine = e => {
    const { name, value } = e.target;
    setEngineValues(prevEngineValues => ({
      ...prevEngineValues,
      [name]: value,
    }));
  };
  const handleTransmission = e => {
    const { name, value } = e.target;
    setTransmissionValues(prevTransmissionValues => ({
      ...prevTransmissionValues,
      [name]: value,
    }));
  };
  const handleAxle = e => {
    const { name, value } = e.target;
    setAxleValues(prevAxleValues => ({
      ...prevAxleValues,
      [name]: value,
    }));
  };
  const handleSteering = e => {
    const { name, value } = e.target;
    setSteeringValues(prevSteeringValues => ({
      ...prevSteeringValues,
      [name]: value,
    }));
  };
  const handleBrake = e => {
    const { name, value } = e.target;
    setBrakeValues(prevBrakeValues => ({
      ...prevBrakeValues,
      [name]: value,
    }));
  };
  const handleAc = e => {
    const { name, value } = e.target;
    setAcValues(prevAcValues => ({
      ...prevAcValues,
      [name]: value,
    }));
  };
  const handleElectrical = e => {
    const { name, value } = e.target;
    setElectricalValues(prevElectricalValues => ({
      ...prevElectricalValues,
      [name]: value,
    }));
  };
  const handleSuspension = e => {
    const { name, value } = e.target;
    setSuspensionValues(prevSuspensionValues => ({
      ...prevSuspensionValues,
      [name]: value,
    }));
  };
  const handleCooling = e => {
    const { name, value } = e.target;
    setCoolingValues(prevCoolingValues => ({
      ...prevCoolingValues,
      [name]: value,
    }));
  };
  const handleRoadtest = e => {
    const { name, value } = e.target;
    setRoadtestValues(prevRoadtestValues => ({
      ...prevRoadtestValues,
      [name]: value,
    }));
  };
  const handleAlterations = e => {
    const { name, value } = e.target;
    setAlterationsValues(prevAlterationsValues => ({
      ...prevAlterationsValues,
      [name]: value,
    }));
  };

  const [consent, setConsent] = useState(false);
  const handleConsent = () => {
    setConsent(consent => !consent);
  };

  const [confirm, setConfirm] = useState(false);
  const handleConfirm = () => {
    setConfirm(confirm => !confirm);
  };

  const wholeForm = useRef(null);

  const handleSubmit = e => {
    e.preventDefault();

    if (!wholeForm.current['amvic_number'].value) {
      enqueueSnackbar(`Please fill out 'Tech #'.`);
      return;
    }

    if (!consent) {
      enqueueSnackbar(`Please check 'I agree' and then submit the inspection!`);
      return;
    }

    engineValues['misc_field'] = wholeForm.current['misc_field'].value;
    brakeValues['pads_lf'] = wholeForm.current['pads_lf'].value;
    brakeValues['pads_rf'] = wholeForm.current['pads_rf'].value;
    brakeValues['pads_lr'] = wholeForm.current['pads_lr'].value;
    brakeValues['pads_rr'] = wholeForm.current['pads_rr'].value;
    brakeValues['tires_lf'] = wholeForm.current['tires_lf'].value;
    brakeValues['tires_rf'] = wholeForm.current['tires_rf'].value;
    brakeValues['tires_lr'] = wholeForm.current['tires_lr'].value;
    brakeValues['tires_rr'] = wholeForm.current['tires_rr'].value;
    acValues['temperature_reading'] =
      wholeForm.current['temperature_reading'].value;
    electricalValues['volts'] = wholeForm.current['volts'].value;
    electricalValues['amps'] = wholeForm.current['amps'].value;
    suspensionValues['lu'] = wholeForm.current['lu'].value;
    suspensionValues['ru'] = wholeForm.current['ru'].value;
    suspensionValues['ll'] = wholeForm.current['ll'].value;
    suspensionValues['rl'] = wholeForm.current['rl'].value;
    suspensionValues['rear_ll'] = wholeForm.current['rear_ll'].value;
    suspensionValues['rear_rl'] = wholeForm.current['rear_rl'].value;

    const form_data = {
      engine: engineValues,
      transmission: transmissionValues,
      axle: axleValues,
      steering: steeringValues,
      brake: brakeValues,
      ac: acValues,
      electrical: electricalValues,
      suspension: suspensionValues,
      cooling: coolingValues,
      road_test: roadtestValues,
      alterations: alterationsValues,
      amvic_number: wholeForm.current['amvic_number'].value,
    };

    props.formData(form_data);
    props.formClose();
  };

  const GreenCheckbox = withStyles({
    root: {
      color: '#66bb6a',
      '&$checked': {
        color: '#43a047',
      },
    },
    checked: {},
  })(props => <Checkbox color="default" {...props} />);

  const OrangeCheckbox = withStyles({
    root: {
      color: '#FFA500',
      '&$checked': {
        color: '#FF8C00',
      },
    },
    checked: {},
  })(props => <Checkbox color="default" {...props} />);

  const RedCheckbox = withStyles({
    root: {
      color: '#FF0000',
    },
    checked: {},
  })(props => <Checkbox color="default" {...props} />);

  const GreenButton = withStyles({
    root: {
      color: '#fff',
      backgroundColor: '#198754',
      '&:hover': {
        backgroundColor: '#157347',
      },
    },
  })(Button);

  return (
    <form onSubmit={handleSubmit} ref={wholeForm}>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Engine</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid item xs={6} />
            <Grid item xs={6}>
              <Grid
                container
                alignItems="center"
                style={{ fontSize: '0.8rem', textAlign: 'center' }}
              >
                <Grid item xs={3}>
                  <b>Good</b>
                </Grid>
                <Grid item xs={3}>
                  <b>Service Required</b>
                </Grid>
                <Grid item xs={3}>
                  <b>Repaired</b>
                </Grid>
                <Grid item xs={3}>
                  <b>N/A</b>
                </Grid>
              </Grid>
            </Grid>
            {Object.entries(engine).map(([name, display_name]) => {
              return (
                <Grid item xs={12} key={name}>
                  <Grid
                    container
                    alignItems="center"
                    style={
                      display_name[2] === 'grey'
                        ? { background: 'lightgrey' }
                        : {}
                    }
                  >
                    <Grid
                      item
                      xs={6}
                      style={
                        display_name[3] === 'indent'
                          ? { paddingLeft: '2rem' }
                          : { paddingLeft: '1rem' }
                      }
                    >
                      {name !== 'misc' && (
                        <Typography
                          style={{ fontSize: '0.8rem', lineHeight: '1.43' }}
                        >
                          {display_name[0]}
                          <br />
                          <span style={{ fontSize: '.8rem', color: 'grey' }}>
                            {display_name[1]}
                          </span>
                        </Typography>
                      )}
                      {name === 'misc' && (
                        <TextField label="Misc." name={'misc_field'} />
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <Grid
                        container
                        alignItems="center"
                        style={{ textAlign: 'center' }}
                      >
                        <Grid item xs={3}>
                          <GreenCheckbox
                            checked={engineValues[name] === 'Good'}
                            onChange={handleEngine}
                            value="Good"
                            name={name}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <OrangeCheckbox
                            checked={engineValues[name] === 'Service Required'}
                            onChange={handleEngine}
                            value="Service Required"
                            name={name}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <RedCheckbox
                            checked={engineValues[name] === 'Repaired'}
                            onChange={handleEngine}
                            value="Repaired"
                            name={name}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <Checkbox
                            color="primary"
                            checked={engineValues[name] === 'na'}
                            onChange={handleEngine}
                            value="na"
                            name={name}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Transmission/Transfer Case</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid item xs={6} />
            <Grid item xs={6}>
              <Grid
                container
                alignItems="center"
                style={{ fontSize: '0.8rem', textAlign: 'center' }}
              >
                <Grid item xs={3}>
                  <b>Good</b>
                </Grid>
                <Grid item xs={3}>
                  <b>Service Required</b>
                </Grid>
                <Grid item xs={3}>
                  <b>Repaired</b>
                </Grid>
                <Grid item xs={3}>
                  <b>N/A</b>
                </Grid>
              </Grid>
            </Grid>
            {Object.entries(transmission).map(([name, display_name]) => {
              return (
                <Grid item xs={12} key={name}>
                  <Grid
                    container
                    alignItems="center"
                    style={
                      display_name[2] === 'grey'
                        ? { background: 'lightgrey' }
                        : {}
                    }
                  >
                    <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                      <Typography
                        style={{ fontSize: '0.8rem', lineHeight: '1.43' }}
                      >
                        {display_name[0]}
                        <br />
                        <span style={{ fontSize: '.8rem', color: 'grey' }}>
                          {display_name[1]}
                        </span>
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid
                        container
                        alignItems="center"
                        style={{ textAlign: 'center' }}
                      >
                        <Grid item xs={3}>
                          <GreenCheckbox
                            checked={transmissionValues[name] === 'Good'}
                            onChange={handleTransmission}
                            value="Good"
                            name={name}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <OrangeCheckbox
                            checked={
                              transmissionValues[name] === 'Service Required'
                            }
                            onChange={handleTransmission}
                            value="Service Required"
                            name={name}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <RedCheckbox
                            checked={transmissionValues[name] === 'Repaired'}
                            onChange={handleTransmission}
                            value="Repaired"
                            name={name}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <Checkbox
                            color="primary"
                            checked={transmissionValues[name] === 'na'}
                            onChange={handleTransmission}
                            value="na"
                            name={name}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Drive Axle</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid item xs={6} />
            <Grid item xs={6}>
              <Grid
                container
                alignItems="center"
                style={{ fontSize: '0.8rem', textAlign: 'center' }}
              >
                <Grid item xs={3}>
                  <b>Good</b>
                </Grid>
                <Grid item xs={3}>
                  <b>Service Required</b>
                </Grid>
                <Grid item xs={3}>
                  <b>Repaired</b>
                </Grid>
                <Grid item xs={3}>
                  <b>N/A</b>
                </Grid>
              </Grid>
            </Grid>
            {Object.entries(axle).map(([name, display_name]) => {
              return (
                <Grid item xs={12} key={name}>
                  <Grid
                    container
                    alignItems="center"
                    style={
                      name === 'fluid_leaks' ? { background: 'lightgrey' } : {}
                    }
                  >
                    <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                      <Typography style={{ fontSize: '0.8rem' }}>
                        {display_name}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid
                        container
                        alignItems="center"
                        style={{ textAlign: 'center' }}
                      >
                        <Grid item xs={3}>
                          <GreenCheckbox
                            checked={axleValues[name] === 'Good'}
                            onChange={handleAxle}
                            value="Good"
                            name={name}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <OrangeCheckbox
                            checked={axleValues[name] === 'Service Required'}
                            onChange={handleAxle}
                            value="Service Required"
                            name={name}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <RedCheckbox
                            checked={axleValues[name] === 'Repaired'}
                            onChange={handleAxle}
                            value="Repaired"
                            name={name}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <Checkbox
                            color="primary"
                            checked={axleValues[name] === 'na'}
                            onChange={handleAxle}
                            value="na"
                            name={name}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Steering</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid item xs={6} />
            <Grid item xs={6}>
              <Grid
                container
                alignItems="center"
                style={{ fontSize: '0.8rem', textAlign: 'center' }}
              >
                <Grid item xs={3}>
                  <b>Good</b>
                </Grid>
                <Grid item xs={3}>
                  <b>Service Required</b>
                </Grid>
                <Grid item xs={3}>
                  <b>Repaired</b>
                </Grid>
                <Grid item xs={3}>
                  <b>N/A</b>
                </Grid>
              </Grid>
            </Grid>
            {Object.entries(steering).map(([name, display_name]) => {
              return (
                <Grid item xs={12} key={name}>
                  <Grid
                    container
                    alignItems="center"
                    style={
                      name === 'fluid_level' ? { background: 'lightgrey' } : {}
                    }
                  >
                    <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                      <Typography style={{ fontSize: '0.8rem' }}>
                        {display_name}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid
                        container
                        alignItems="center"
                        style={{ textAlign: 'center' }}
                      >
                        <Grid item xs={3}>
                          <GreenCheckbox
                            checked={steeringValues[name] === 'Good'}
                            onChange={handleSteering}
                            value="Good"
                            name={name}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <OrangeCheckbox
                            checked={
                              steeringValues[name] === 'Service Required'
                            }
                            onChange={handleSteering}
                            value="Service Required"
                            name={name}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <RedCheckbox
                            checked={steeringValues[name] === 'Repaired'}
                            onChange={handleSteering}
                            value="Repaired"
                            name={name}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <Checkbox
                            color="primary"
                            checked={steeringValues[name] === 'na'}
                            onChange={handleSteering}
                            value="na"
                            name={name}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Brake System</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid item xs={6} />
            <Grid item xs={6}>
              <Grid
                container
                alignItems="center"
                style={{ fontSize: '0.8rem', textAlign: 'center' }}
              >
                <Grid item xs={3}>
                  <b>Good</b>
                </Grid>
                <Grid item xs={3}>
                  <b>Service Required</b>
                </Grid>
                <Grid item xs={3}>
                  <b>Repaired</b>
                </Grid>
                <Grid item xs={3}>
                  <b>N/A</b>
                </Grid>
              </Grid>
            </Grid>
            {Object.entries(brake).map(([name, display_name]) => {
              return (
                <Grid item xs={12} key={name}>
                  <Grid container alignItems="center">
                    <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                      <Typography
                        style={{ fontSize: '0.8rem', lineHeight: '1.43' }}
                      >
                        {display_name[0]}
                        <br />
                        <span style={{ fontSize: '.8rem', color: 'grey' }}>
                          {display_name[1]}
                        </span>
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid
                        container
                        alignItems="center"
                        style={{ textAlign: 'center' }}
                      >
                        <Grid item xs={3}>
                          <GreenCheckbox
                            checked={brakeValues[name] === 'Good'}
                            onChange={handleBrake}
                            value="Good"
                            name={name}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <OrangeCheckbox
                            checked={brakeValues[name] === 'Service Required'}
                            onChange={handleBrake}
                            value="Service Required"
                            name={name}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <RedCheckbox
                            checked={brakeValues[name] === 'Repaired'}
                            onChange={handleBrake}
                            value="Repaired"
                            name={name}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <Checkbox
                            color="primary"
                            checked={brakeValues[name] === 'na'}
                            onChange={handleBrake}
                            value="na"
                            name={name}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {name === 'pads_remaining' && (
                    <Grid
                      container
                      alignItems="center"
                      style={{ marginBottom: '1rem' }}
                    >
                      <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                        <TextField label="LF" name={'pads_lf'} />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField label="RF" name={'pads_rf'} />
                      </Grid>
                      <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                        <TextField label="LR" name={'pads_lr'} />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField label="RR" name={'pads_rr'} />
                      </Grid>
                    </Grid>
                  )}
                  {name === 'tires_remaining' && (
                    <Grid
                      container
                      alignItems="center"
                      style={{ marginBottom: '1rem' }}
                    >
                      <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                        <TextField label="LF" name={'tires_lf'} />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField label="RF" name={'tires_rf'} />
                      </Grid>
                      <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                        <TextField label="LR" name={'tires_lr'} />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField label="RR" name={'tires_rr'} />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Air Conditioning</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid item xs={6} />
            <Grid item xs={6}>
              <Grid
                container
                alignItems="center"
                style={{ fontSize: '0.8rem', textAlign: 'center' }}
              >
                <Grid item xs={3}>
                  <b>Good</b>
                </Grid>
                <Grid item xs={3}>
                  <b>Service Required</b>
                </Grid>
                <Grid item xs={3}>
                  <b>Repaired</b>
                </Grid>
                <Grid item xs={3}>
                  <b>N/A</b>
                </Grid>
              </Grid>
            </Grid>
            {Object.entries(ac).map(([name, display_name]) => {
              return (
                <Grid item xs={12} key={name}>
                  <Grid container alignItems="center">
                    <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                      <Typography
                        style={{ fontSize: '0.8rem', lineHeight: '1.43' }}
                      >
                        {display_name[0]}
                        <br />
                        <span style={{ fontSize: '.8rem', color: 'grey' }}>
                          {display_name[1]}
                        </span>
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid
                        container
                        alignItems="center"
                        style={{ textAlign: 'center' }}
                      >
                        <Grid item xs={3}>
                          <GreenCheckbox
                            checked={acValues[name] === 'Good'}
                            onChange={handleAc}
                            value="Good"
                            name={name}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <OrangeCheckbox
                            checked={acValues[name] === 'Service Required'}
                            onChange={handleAc}
                            value="Service Required"
                            name={name}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <RedCheckbox
                            checked={acValues[name] === 'Repaired'}
                            onChange={handleAc}
                            value="Repaired"
                            name={name}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <Checkbox
                            color="primary"
                            checked={acValues[name] === 'na'}
                            onChange={handleAc}
                            value="na"
                            name={name}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    {name === 'idler_pulley' && (
                      <Grid
                        container
                        alignItems="center"
                        style={{ marginBottom: '1rem' }}
                      >
                        <Grid item xs={12} style={{ paddingLeft: '1rem' }}>
                          <TextField
                            label="A/C Temperature Reading"
                            fullWidth
                            name={'temperature_reading'}
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Electrical</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid item xs={6} />
            <Grid item xs={6}>
              <Grid
                container
                alignItems="center"
                style={{ fontSize: '0.8rem', textAlign: 'center' }}
              >
                <Grid item xs={3}>
                  <b>Good</b>
                </Grid>
                <Grid item xs={3}>
                  <b>Service Required</b>
                </Grid>
                <Grid item xs={3}>
                  <b>Repaired</b>
                </Grid>
                <Grid item xs={3}>
                  <b>N/A</b>
                </Grid>
              </Grid>
            </Grid>
            {Object.entries(electrical).map(([name, display_name]) => {
              return (
                <Grid item xs={12} key={name}>
                  <Grid container alignItems="center">
                    <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                      <Typography style={{ fontSize: '0.8rem' }}>
                        {display_name}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid
                        container
                        alignItems="center"
                        style={{ textAlign: 'center' }}
                      >
                        <Grid item xs={3}>
                          <GreenCheckbox
                            checked={electricalValues[name] === 'Good'}
                            onChange={handleElectrical}
                            value="Good"
                            name={name}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <OrangeCheckbox
                            checked={
                              electricalValues[name] === 'Service Required'
                            }
                            onChange={handleElectrical}
                            value="Service Required"
                            name={name}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <RedCheckbox
                            checked={electricalValues[name] === 'Repaired'}
                            onChange={handleElectrical}
                            value="Repaired"
                            name={name}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <Checkbox
                            color="primary"
                            checked={electricalValues[name] === 'na'}
                            onChange={handleElectrical}
                            value="na"
                            name={name}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {name === 'alternator' && (
                    <Grid
                      container
                      alignItems="center"
                      style={{ marginBottom: '1rem' }}
                    >
                      <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                        <TextField label="Volts" name={'volts'} />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField label="Amps" name={'amps'} />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>FRONT/REAR SUSPENSION</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid item xs={6} />
            <Grid item xs={6}>
              <Grid
                container
                alignItems="center"
                style={{ fontSize: '0.8rem', textAlign: 'center' }}
              >
                <Grid item xs={3}>
                  <b>Good</b>
                </Grid>
                <Grid item xs={3}>
                  <b>Service Required</b>
                </Grid>
                <Grid item xs={3}>
                  <b>Repaired</b>
                </Grid>
                <Grid item xs={3}>
                  <b>N/A</b>
                </Grid>
              </Grid>
            </Grid>
            {Object.entries(suspension).map(([name, display_name]) => {
              return (
                <Grid item xs={12} key={name}>
                  <Grid container alignItems="center">
                    <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                      <Typography style={{ fontSize: '0.8rem' }}>
                        {display_name}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid
                        container
                        alignItems="center"
                        style={{ textAlign: 'center' }}
                      >
                        <Grid item xs={3}>
                          <GreenCheckbox
                            checked={suspensionValues[name] === 'Good'}
                            onChange={handleSuspension}
                            value="Good"
                            name={name}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <OrangeCheckbox
                            checked={
                              suspensionValues[name] === 'Service Required'
                            }
                            onChange={handleSuspension}
                            value="Service Required"
                            name={name}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <RedCheckbox
                            checked={suspensionValues[name] === 'Repaired'}
                            onChange={handleSuspension}
                            value="Repaired"
                            name={name}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <Checkbox
                            color="primary"
                            checked={suspensionValues[name] === 'na'}
                            onChange={handleSuspension}
                            value="na"
                            name={name}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {name === 'ball_joints' && (
                    <Grid
                      container
                      alignItems="center"
                      style={{ marginBottom: '1rem' }}
                    >
                      <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                        <TextField label="LU" name={'lu'} />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField label="RU" name={'ru'} />
                      </Grid>
                      <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                        <TextField label="LL" name={'ll'} />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField label="RL" name={'rl'} />
                      </Grid>
                      <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                        <TextField label="Rear LL" name={'rear_ll'} />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField label="Rear RL" name={'rear_rl'} />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>COOLING/FUEL SYSTEM</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid item xs={6} />
            <Grid item xs={6}>
              <Grid
                container
                alignItems="center"
                style={{ fontSize: '0.8rem', textAlign: 'center' }}
              >
                <Grid item xs={3}>
                  <b>Good</b>
                </Grid>
                <Grid item xs={3}>
                  <b>Service Required</b>
                </Grid>
                <Grid item xs={3}>
                  <b>Repaired</b>
                </Grid>
                <Grid item xs={3}>
                  <b>N/A</b>
                </Grid>
              </Grid>
            </Grid>
            {Object.entries(cooling).map(([name, display_name]) => {
              return (
                <Grid item xs={12} key={name}>
                  <Grid
                    container
                    alignItems="center"
                    style={
                      [
                        'coolant_level',
                        'coolant_system_pressure_test',
                      ].includes(name)
                        ? { background: 'lightgrey' }
                        : {}
                    }
                  >
                    <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                      <Typography style={{ fontSize: '0.8rem' }}>
                        {display_name}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid
                        container
                        alignItems="center"
                        style={{ textAlign: 'center' }}
                      >
                        <Grid item xs={3}>
                          <GreenCheckbox
                            checked={coolingValues[name] === 'Good'}
                            onChange={handleCooling}
                            value="Good"
                            name={name}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <OrangeCheckbox
                            checked={coolingValues[name] === 'Service Required'}
                            onChange={handleCooling}
                            value="Service Required"
                            name={name}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <RedCheckbox
                            checked={coolingValues[name] === 'Repaired'}
                            onChange={handleCooling}
                            value="Repaired"
                            name={name}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <Checkbox
                            color="primary"
                            checked={coolingValues[name] === 'na'}
                            onChange={handleCooling}
                            value="na"
                            name={name}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Road Test</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid item xs={6} />
            <Grid item xs={6}>
              <Grid
                container
                alignItems="center"
                style={{ fontSize: '0.8rem', textAlign: 'center' }}
              >
                <Grid item xs={3}>
                  <b>Good</b>
                </Grid>
                <Grid item xs={3}>
                  <b>Service Required</b>
                </Grid>
                <Grid item xs={3}>
                  <b>Repaired</b>
                </Grid>
                <Grid item xs={3}>
                  <b>N/A</b>
                </Grid>
              </Grid>
            </Grid>
            {Object.entries(roadtest).map(([name, display_name]) => {
              return (
                <Grid item xs={12} key={name}>
                  <Grid
                    container
                    alignItems="center"
                    style={{ background: 'lightgrey' }}
                  >
                    <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                      <Typography style={{ fontSize: '0.8rem' }}>
                        {display_name}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid
                        container
                        alignItems="center"
                        style={{ textAlign: 'center' }}
                      >
                        <Grid item xs={3}>
                          <GreenCheckbox
                            checked={roadtestValues[name] === 'Good'}
                            onChange={handleRoadtest}
                            value="Good"
                            name={name}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <OrangeCheckbox
                            checked={
                              roadtestValues[name] === 'Service Required'
                            }
                            onChange={handleRoadtest}
                            value="Service Required"
                            name={name}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <RedCheckbox
                            checked={roadtestValues[name] === 'Repaired'}
                            onChange={handleRoadtest}
                            value="Repaired"
                            name={name}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <Checkbox
                            color="primary"
                            checked={roadtestValues[name] === 'na'}
                            onChange={handleRoadtest}
                            value="na"
                            name={name}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>ALTERATIONS/MODIFICATIONS</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid item xs={6} />
            <Grid item xs={6}>
              <Grid
                container
                alignItems="center"
                style={{ fontSize: '0.8rem', textAlign: 'center' }}
              >
                <Grid item xs={3}>
                  <b>Yes</b>
                </Grid>
                <Grid item xs={3}>
                  <b>No</b>
                </Grid>
                <Grid item xs={3}>
                  <b>N/A</b>
                </Grid>
              </Grid>
            </Grid>
            {Object.entries(alterations).map(([name, display_name]) => {
              return (
                <Grid item xs={12} key={name}>
                  <Grid container alignItems="center">
                    <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                      <Typography style={{ fontSize: '0.8rem' }}>
                        {display_name}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid
                        container
                        alignItems="center"
                        style={{ textAlign: 'center' }}
                      >
                        <Grid item xs={3}>
                          <GreenCheckbox
                            checked={alterationsValues[name] === 'Yes'}
                            onChange={handleAlterations}
                            value="Yes"
                            name={name}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <RedCheckbox
                            checked={alterationsValues[name] === 'No'}
                            onChange={handleAlterations}
                            value="No"
                            name={name}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <Checkbox
                            color="primary"
                            checked={alterationsValues[name] === 'na'}
                            onChange={handleAlterations}
                            value="na"
                            name={name}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Typography style={{ marginTop: '15px' }}>
        I verify the contents of this document are true and correct.
      </Typography>
      <FormControlLabel
        control={<Checkbox />}
        label="I agree"
        onChange={handleConsent}
        value={consent}
      />
      <br />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Technician Name"
            value={props.user.display_name}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Tech #"
            value={props.user.goUserProfile.profile.sales_licence}
            fullWidth
            name={'amvic_number'}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Date"
            value={new Date().toLocaleString()}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <Button
            type="button"
            variant="contained"
            style={{ marginRight: '15px' }}
            onClick={handleConfirm}
            color="default"
          >
            Cancel
          </Button>
          <Dialog
            open={confirm}
            onClose={handleConfirm}
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Current inspection will be closed and unsaved data will be gone.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleConfirm}>Cancel</Button>
              <Button onClick={props.formClose} color="secondary">
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
          <GreenButton type="submit" variant="contained">
            Save
          </GreenButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default FCPPForm;
