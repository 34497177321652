import React from 'react';

import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';

const DecodeWizardProgress = ({ decodedCount, total, mobile }) => {
  const progress = total === 0 ? 100 : (decodedCount / total) * 100;
  return (
    <Box style={{ padding: '1rem' }}>
      <Box
        minWidth={35}
        display="flex"
        alignItems="center"
        style={{ paddingBottom: '1rem' }}
      >
        <Typography
          variant={mobile ? 'h2' : 'h3'}
          color="textSecondary"
        >{`${progress.toFixed(1)}%`}</Typography>
        <div style={{ paddingLeft: '1rem' }}>
          <Typography variant="h6" color="textSecondary">
            Decoded Inventory
          </Typography>
          <div>
            <Typography variant="p" color="textSecondary">
              You have {decodedCount} out of {total} vehicles decoded.
            </Typography>
          </div>
        </div>
      </Box>
      <Box width={mobile ? '80%' : '100%'} mr={1}>
        <LinearProgress variant="determinate" value={progress} />
      </Box>
    </Box>
  );
};

export default DecodeWizardProgress;
