import React, { useState } from 'react';

import gql from 'graphql-tag';
import { useForm, useFieldArray, useWatch, Controller } from 'react-hook-form';
import { map } from 'lodash';
import { useSnackbar } from 'notistack';
import { useMutation, useQuery } from '@apollo/react-hooks';

import AddIcon from '@mui/icons-material/Add';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Fab from '@mui/material/Fab';
import FormControl from '@mui/material/FormControl';
import HelpIcon from '@mui/icons-material/Help';
import InputAdornment from '@mui/material/InputAdornment';
import { DatePicker } from '@mui/x-date-pickers';
import SelectControl from 'components/MaterialUI/SelectControl2';
import TextFieldControl from 'components/MaterialUI/TextFieldControl';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import ErrorDisplay from 'components/MaterialUI/ErrorDisplay';
import { processMoment } from 'utils';
import { useVehiclesQueryContext } from '../contexts/VehiclesQueryContext';
import CheckboxControl from 'components/MaterialUI/CheckboxControl';
const UPDATE_BULK_DETAILED_PRICING = gql`
  mutation updateBulkDetailedPriceMod(
    $filters: [QueryFilter!]!
    $data: [GreaseInventoryBulkDetailedPricingInput]!
  ) {
    inventory {
      updateBulkDetailedPricing(filters: $filters, data: $data) {
        id
      }
    }
  }
`;

const NO_REGULAR_PRICE_QUERY = gql`
  query noRegularPrice($filters: [QueryFilter], $sort: [QuerySortElement]) {
    inventory {
      getVehicles(filters: $filters, sort: $sort) {
        results {
          stock_number
        }
      }
    }
  }
`;

const BulkVehicleMultiLinePricing = ({
  ids,
  open,
  onClose,
  selectByFilter,
  stockTypes,
}) => {
  const { filters: vehicleFilters, refetch } = useVehiclesQueryContext();
  const methods = useForm({
    defaultValues: {},
    shouldUnregister: true,
  });
  const { handleSubmit, control } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'bulk_detailed_pricing',
    shouldUnregister: true,
  });

  const detailedPricing = useWatch({
    control,
    name: 'bulk_detailed_pricing',
    defaultValue: [],
  });

  const { enqueueSnackbar } = useSnackbar();

  const [update, updateStatus] = useMutation(UPDATE_BULK_DETAILED_PRICING, {
    onCompleted: data => {
      refetch({ variables: { page: 1 } });
      onClose();
      enqueueSnackbar(
        `Successfully updated ${data.inventory.updateBulkDetailedPricing.length} vehicles' multi-line pricing.`,
      );
    },
    onError: error =>
      enqueueSnackbar(
        `Could not update vehicle multi-line pricing error: ${error}`,
      ),
  });

  const baseFilters = selectByFilter
    ? vehicleFilters
    : [
        {
          model: 'Vehicle',
          field: 'id',
          op: 'in',
          value: ids,
        },
      ];

  const noRegPriceFilters = [
    ...baseFilters,
    {
      or: [
        {
          model: 'Vehicle',
          field: 'regular_price',
          op: 'is_null',
        },
        {
          model: 'Vehicle',
          field: 'regular_price',
          op: '<=',
          value: 0,
        },
      ],
    },
  ];

  const { data: noRegPriceData, loading: checkingForRegPrice } = useQuery(
    NO_REGULAR_PRICE_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      skip: !open,
      variables: {
        filters: [...noRegPriceFilters],
        sort: [
          {
            model: 'Vehicle',
            field: 'stock_number',
            direction: 'asc',
          },
        ],
      },
    },
  );

  const vehiclesNoRegPrice =
    noRegPriceData?.inventory?.getVehicles?.results || [];

  const regularPriceFilters = [
    {
      model: 'Vehicle',
      field: 'regular_price',
      op: '>',
      value: 0,
    },
    {
      model: 'Vehicle',
      field: 'regular_price',
      op: 'is_not_null',
    },
  ];

  const filters = [...baseFilters, ...regularPriceFilters];
  const [dialogWidth, setDialogWidth] = useState(false);

  const PRICE_MODIFIERS = [
    { value: 'addon', name: 'Add-on' },
    { value: 'discount', name: 'Discount' },
    { value: 'rebate', name: 'Rebate' },
  ];

  const onSubmit = data => {
    const parsed = data.bulk_detailed_pricing.map(price => ({
      ...price,
      value: (price.value ?? 0).toFixed(2),
      expiry: processMoment(price.expiry),
    }));
    update({
      variables: {
        filters,
        data: parsed,
      },
    });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth={dialogWidth === false ? 'sm' : 'md'}
      style={{ padding: '0 20px' }}
    >
      <DialogTitle>{'Set Bulk Vehicle Pricing'}</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          {updateStatus.error && (
            <Alert severity="warning">
              <ErrorDisplay
                error={updateStatus.error}
                action="Updating Vehicles' Pricing"
              />
            </Alert>
          )}

          {checkingForRegPrice && (
            <>
              <CircularProgress size={20} />
              Verifying selected vehicles' regular prices.
            </>
          )}
          {vehiclesNoRegPrice.length > 0 && (
            <Alert severity="warning">
              The following vehicles are missing a regular price and will not be
              updated. Stock numbers:{' '}
              {map(vehiclesNoRegPrice, 'stock_number').join(', ')}
            </Alert>
          )}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '20px',
            }}
          >
            <Typography style={{ fontWeight: 'bold' }}>
              Multi-Line Pricing
            </Typography>
            <Fab
              size="small"
              onClick={() => {
                setDialogWidth(true);
                append({
                  // Note that the default expiry in KeyboardDatePicker is
                  // today's date, BUT ALSO TODAY'S TIME.
                  cash: stockTypes?.[0] === 'USED',
                  expiry: null,
                  finance: stockTypes?.[0] === 'USED',
                  lease: false,
                  price_description: '',
                  price_modifier_type_name: 'addon',
                  value: 0,
                  show_on_website: false,
                  program_id: 'user-created',
                });
              }}
              style={{
                display: 'flex',
                margin: '0 0 0 20px',
                backgroundColor: '#5bc236',
                color: 'white',
              }}
            >
              <AddIcon />
            </Fab>
          </div>
          {fields.map((item, index) => {
            return (
              <div
                key={item.id}
                style={{
                  padding: '10px 0',
                }}
              >
                <FormControl
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                    alignContent: 'center',
                    width: '100%',
                    paddingBottom: '10px',
                  }}
                >
                  <div
                    style={{
                      width: '45%',
                    }}
                  >
                    <div style={{ display: 'flex' }}>
                      <SelectControl
                        control={control}
                        displayEmpty
                        defaultValue={item.price_modifier_type_name}
                        label="Type"
                        name={`bulk_detailed_pricing.${index}.price_modifier_type_name`}
                        style={{ width: '55%', marginBottom: '20px' }}
                        options={PRICE_MODIFIERS}
                        noNull
                      />
                    </div>
                    <div style={{ display: 'flex' }}>
                      <TextFieldControl
                        control={control}
                        defaultValue={item.price_description}
                        label="Name"
                        name={`bulk_detailed_pricing.${index}.price_description`}
                        style={{ width: '80%' }}
                      />
                      {detailedPricing[index]?.price_modifier_type_name ===
                        'rebate' && (
                        <span
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: '5px',
                          }}
                        >
                          <Tooltip
                            title={
                              !detailedPricing[index]?.extraDescription
                                ? 'No Rebate Information Available'
                                : detailedPricing[index]?.extraDescription
                            }
                          >
                            <HelpIcon
                              style={{
                                fontSize: '18px',
                              }}
                            />
                          </Tooltip>
                        </span>
                      )}
                    </div>
                  </div>
                  <div>
                    <Controller
                      control={control}
                      name={`bulk_detailed_pricing.${index}.expiry`}
                      defaultValue={item.expiry}
                      render={({ field: { ref, ...field } }) => (
                        <DatePicker
                          autoOk
                          variant="inline"
                          format="YYYY-MM-DD"
                          label="Expiry"
                          margin="normal"
                          style={{
                            width: '80%',
                            margin: 0,
                          }}
                          {...field}
                        />
                      )}
                    />

                    <div>
                      <CheckboxControl
                        control={control}
                        disabled={stockTypes?.[0] === 'USED'}
                        label={<Typography variant="body2">Cash</Typography>}
                        name={`bulk_detailed_pricing.${index}.cash`}
                        defaultValue={item.cash}
                      />
                      <br />
                      <CheckboxControl
                        control={control}
                        disabled={stockTypes?.[0] === 'USED'}
                        label={<Typography variant="body2">Finance</Typography>}
                        name={`bulk_detailed_pricing.${index}.finance`}
                        defaultValue={item.finance}
                      />
                      {stockTypes?.[0] === 'NEW' && (
                        <CheckboxControl
                          control={control}
                          label={<Typography variant="body2">Lease</Typography>}
                          name={`bulk_detailed_pricing.${index}.lease`}
                          defaultValue={item.finance}
                        />
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <div>
                      <TextFieldControl
                        control={control}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                        label="Value"
                        name={`bulk_detailed_pricing.${index}.value`}
                        style={{ width: '90%' }}
                        type="number"
                        inputProps={{ min: 0, step: '0.01' }}
                        defaultValue={item.value}
                      />
                      <br />
                      <CheckboxControl
                        control={control}
                        label={
                          <Typography variant="body2">
                            Show on Website
                          </Typography>
                        }
                        name={`bulk_detailed_pricing.${index}.show_on_website`}
                        style={{ marginRight: '10px' }}
                        defaultValue={item.show_on_website}
                      />
                    </div>
                    <div>
                      <Fab
                        size="small"
                        style={{
                          backgroundColor: 'red',
                          color: 'white',
                          marginLeft: '20px',
                        }}
                        onClick={() => {
                          remove(index);
                          if (fields.length <= 1) {
                            setDialogWidth(false);
                          }
                        }}
                      >
                        <DeleteIcon />
                      </Fab>
                    </div>
                  </div>
                </FormControl>
              </div>
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="outlined" color="primary">
            Cancel
          </Button>
          {updateStatus.loading ? (
            <CircularProgress
              size={20}
              style={{ marginLeft: '40px', marginRight: '30px' }}
            />
          ) : (
            <Button
              autoFocus
              disabled={fields.length <= 0}
              variant="contained"
              type="submit"
              style={{
                backgroundColor: `${
                  fields.length === 0 ? '#bfbebe' : '#74B72E'
                }`,
              }}
            >
              Submit
            </Button>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default BulkVehicleMultiLinePricing;
