import React, { useEffect } from 'react';

/* external */
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useForm, useWatch, FormProvider } from 'react-hook-form';
import { useSnackbar } from 'notistack';

/* Material UI */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

/* internal */
import CheckboxControl from 'components/MaterialUI/CheckboxControl';
import chequeImage from './static/cheque-ca.gif';
import Loading from 'components/MaterialUI/Loading';
import RenderableRadioGroup from 'components/MaterialUI/RenderableRadioGroup';
import RadioControl from 'components/MaterialUI/RadioControl2';
import SelectControl from 'components/MaterialUI/SelectControl2';
import TextFieldControl from 'components/MaterialUI/TextFieldControl';
import { useUserContext } from 'components/MaterialUI/UserContext';
import { Role } from 'constants.js';

const CREATE_PAD = gql`
  mutation CreatePreauthorizedDebit(
    $id: ID!
    $input: PreauthorizedDebitInput!
  ) {
    concierge {
      createPreauthorizedDebit(id: $id, input: $input) {
        id
        bankName
        accountNumber
        institutionNumber
        transitNumber
      }
    }
  }
`;

const GET_BANKS = gql`
  query getFinancialInstitutions {
    concierge {
      getFinancialInstitutions {
        bankName
        institutionNumber
      }
    }
  }
`;

const PaymentInfoModal = ({ goldCard, isOpen, onClose }) => {
  const { currentUser } = useUserContext();
  const isManagerOrAbove =
    Role.ALL_SALES_MANAGERS.includes(currentUser.role) ||
    currentUser.role === Role.ORGANIZATION_ADMIN;

  const { pad } = goldCard;
  const { enqueueSnackbar } = useSnackbar();
  const formMethods = useForm({
    defaultValues: {
      selectedPaymentMethod: goldCard.activePaymentMethod,
      bankName: pad?.bankName,
      accountNumber: pad?.accountNumberPlaintext,
      institutionNumber: pad?.institutionNumber,
      transitNumber: pad?.transitNumber,
      accountSameAsCustomer: true,
      payorPhone: pad?.payorPhone || goldCard.phone,
      payorAddress: pad?.payorAddress || goldCard.address,
      payorCity: pad?.payorCity || goldCard.city,
      payorProvince: pad?.payorProvince || goldCard.province,
      payorPostalCode: pad?.payorPostalCode || goldCard.postalCode,
      payorName:
        pad?.payorName || goldCard.firstName?.concat(' ', goldCard.lastName),
    },
    shouldUnregister: true,
  });

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitSuccessful },
    reset,
  } = formMethods;
  const bankName = useWatch({ control, name: 'bankName' });

  // default value on useWatches so the values are not undefined after submission
  const accountSameAsCustomer = useWatch({
    control,
    name: 'accountSameAsCustomer',
    defaultValue: true,
  });
  const selectedPaymentMethod = useWatch({
    control,
    name: 'selectedPaymentMethod',
    defaultValue: goldCard.activePaymentMethod,
  });

  const FormatRadioGroup = props => (
    <RenderableRadioGroup
      {...props}
      options={[
        { name: 'Preauthorized Debit', value: 'PREAUTHORIZED DEBIT' },
        { name: 'Paid In Full', value: 'PAID IN FULL' },
      ]}
    />
  );

  const [createPreauthorizedDebit, createPreauthorizedDebitMutation] =
    useMutation(CREATE_PAD, {
      onCompleted: () => {
        enqueueSnackbar('Preauthorized Debit Created', { variant: 'success' });
        onClose();
      },
      onError: error => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    });

  const bankQuery = useQuery(GET_BANKS);
  const banks = bankQuery.data?.concierge?.getFinancialInstitutions || [];

  const onSubmit = data => {
    const { selectedPaymentMethod, accountSameAsCustomer, ...input } = data;
    if (
      selectedPaymentMethod === 'PAID IN FULL' &&
      goldCard.activePaymentMethod === 'PREAUTHORIZED DEBIT'
    ) {
      enqueueSnackbar('Cannot Change Preauthorized Debit to Paid In Full', {
        variant: 'error',
      });
      return;
    }

    createPreauthorizedDebit({
      variables: {
        id: goldCard.id,
        input: {
          ...input,
        },
      },
    });
  };

  // some of the bankNames in the PAD are not in the list of banks, so check institutionNumber instead
  useEffect(() => {
    if (banks.length > 0) {
      const bank = banks.find(
        bank => bank.institutionNumber === pad?.institutionNumber,
      );
      if (bank) {
        setValue('bankName', bank.bankName);
      }
    }
  }, [banks, pad, setValue]);

  // set insitution number when bank name changes
  useEffect(() => {
    const bank = banks.find(bank => bank.bankName === bankName);
    if (bank) {
      setValue('institutionNumber', bank.institutionNumber);
    }
  }, [bankName, banks, setValue]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  const loading = [bankQuery, createPreauthorizedDebitMutation].some(
    x => x.loading,
  );
  if (loading) {
    return <Loading />;
  }

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        onClose();
      }}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Update Payment Info</DialogTitle>
      <DialogContent>
        <Typography>
          {' '}
          Choose a payment method, and fill in the details
        </Typography>
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid
              container
              direction="column"
              style={{ padding: '1em 1em 1em 0' }}
            >
              <Grid item xs="12">
                <RadioControl
                  name="selectedPaymentMethod"
                  as={<FormatRadioGroup />}
                />
              </Grid>
            </Grid>
            {goldCard.activePaymentMethod === 'PREAUTHORIZED DEBIT' &&
              selectedPaymentMethod === 'PREAUTHORIZED DEBIT' && (
                <>
                  {' '}
                  <Typography>
                    {' '}
                    <strong>Preauthorized Debit</strong> Details:
                  </Typography>
                  <Grid container row spacing={2}>
                    <Grid item xs="12">
                      <CheckboxControl
                        name="accountSameAsCustomer"
                        label="Account holder same as customer"
                        control={control}
                        labelPlacement="start"
                        defaultChecked
                        fontSize="10px"
                      />
                    </Grid>
                    <Grid item xs="12">
                      <Grid container direction="column" spacing={2}>
                        {!accountSameAsCustomer && (
                          <Grid item xs="12">
                            <Grid container spacing={2}>
                              <Grid item xs="12">
                                <TextFieldControl
                                  name="payorName"
                                  label="Name"
                                  control={control}
                                />
                              </Grid>
                              <Grid item xs="12">
                                <TextFieldControl
                                  name="payorPhone"
                                  label="Phone"
                                  control={control}
                                />
                              </Grid>
                              <Grid item xs="12">
                                <TextFieldControl
                                  name="payorAddress"
                                  label="Address"
                                  control={control}
                                />
                              </Grid>
                              <Grid item xs="12">
                                <TextFieldControl
                                  name="payorCity"
                                  label="City"
                                  control={control}
                                />
                              </Grid>
                              <Grid item xs="12">
                                <TextFieldControl
                                  name="payorProvince"
                                  label="Province"
                                  control={control}
                                />
                              </Grid>
                              <Grid item xs="12">
                                <TextFieldControl
                                  name="payorPostalCode"
                                  label="Postal Code"
                                  control={control}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                    <Grid item xs="12">
                      <SelectControl
                        name="bankName"
                        label="Bank Name"
                        control={control}
                        options={banks?.map(bank => ({
                          name: bank.bankName,
                          value: bank.bankName,
                          key: bank.institutionNumber,
                        }))}
                        fullWidth
                        rules={{ required: 'Bank name required' }}
                        noNull
                      />
                    </Grid>
                    <Grid item xs="6">
                      <Grid container direction="column" spacing={2}>
                        <Grid item xs="12">
                          <TextFieldControl
                            name="institutionNumber"
                            label="Institution Number"
                            control={control}
                            // institution number must be numeric, 3 digits long, and in the list of banks
                            rules={{
                              required: 'Institution number required',
                              pattern: {
                                value: /^[0-9]{3}$/,
                                message: 'invalid institution number',
                              },
                              validate: {
                                validInstitutionNumber: value => {
                                  const bank = banks.find(
                                    bank => bank.institutionNumber === value,
                                  );
                                  return bank
                                    ? true
                                    : 'invalid institution number';
                                },
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs="12">
                          <TextFieldControl
                            name="transitNumber"
                            label="Transit Number"
                            control={control}
                            rules={{ required: 'Transit number required' }}
                          />
                        </Grid>
                        {isManagerOrAbove && (
                          <Grid item xs="12">
                            <TextFieldControl
                              name="accountNumber"
                              label="Account Number"
                              control={control}
                              // account number must be numeric and between 7 to 12 digits
                              rules={{
                                pattern: {
                                  value: /^[0-9]{7,12}$/,
                                  message: 'invalid account number',
                                },
                                required: 'Account number required',
                              }}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                    <Grid item xs="6">
                      <Box>
                        <img
                          alt="Vehicle"
                          style={{
                            width: '100%',
                            objectFit: 'cover',
                          }}
                          src={chequeImage}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </>
              )}
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleSubmit(onSubmit)}
          disabled={!isManagerOrAbove}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

PaymentInfoModal.fragments = {
  package: gql`
    fragment PaymentInfoModalPackage on Package {
      activePaymentMethod
      phone
      address
      city
      province
      postalCode
      firstName
      lastName
    }
  `,
  pad: gql`
    fragment PaymentInfoModalPad on PreauthorizedDebit {
      bankName
      accountNumberPlaintext
      institutionNumber
      transitNumber
      payorPhone
      payorAddress
      payorCity
      payorProvince
      payorPostalCode
      payorName
    }
  `,
};

export default PaymentInfoModal;
