import { useLazyQuery, useMutation } from '@apollo/react-hooks';

/* external */
import gql from 'graphql-tag';
import { useSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';

/* Material UI */
import { Box, Button, Dialog, DialogContent, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';

/* internal */
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';

const ADD_GOCARD_TO_RO = gql`
  mutation updateRO($dealerId: Int!, $roNumber: String!, $update: ROUpdate!) {
    rotracker {
      updateRO(dealer_id: $dealerId, ro_number: $roNumber, update: $update) {
        id
        gocard_id
        gocard {
          cardId
          cardNumber
          cashBalance
        }
      }
    }
  }
`;

const GET_GOCARD = gql`
  query getGoCard($card_id: ID!) {
    gocard {
      accountById(card_id: $card_id) {
        cardId
        active
      }
    }
  }
`;

const ADD_GOCARD_TO_APPOINTMENT = gql`
  mutation attachGoCardToAppointment(
    $dealerId: Int!
    $appointmentId: String!
    $gocardId: ID!
  ) {
    rotracker {
      attachGoCardToAppointment(
        dealer_id: $dealerId
        appointment_id: $appointmentId
        gocard_id: $gocardId
      ) {
        id
        gocard_id
      }
    }
  }
`;

const GET_CUSTOMER_BY_URN = gql`
  query getCustomerByUrn($urn: String!) {
    customerByUrn(urn: $urn) {
      _id
    }
  }
`;

const useStyles = makeStyles(theme => ({
  whiteFont: {
    color: '#FFFFFF',
  },
}));

const MODE_IDLE = 'Idle';
const MODE_LOADING = 'Loading';
const MODE_DETECTING = 'Detecting';
const MODE_WAITING = 'Waiting';

export const GoCardScanner = ({
  ro,
  appointment,
  refetch,
  iconOnly,
  updateOverview,
  startMerge,
  mergeCustomers,
}) => {
  // TODO: MARMO-942: Refactor this component to use GoCardScanner.jsx in src/components/MaterialUI
  const theme = useTheme();
  const { whiteFont } = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { dealerId } = useDealerContext();

  const [value, setValue] = useState('');
  const [goCardId, setGoCardId] = useState('');
  const [buf, setBuf] = useState('');
  const [mode, setMode] = useState(MODE_IDLE);
  const video = useRef(null);
  const barcodeDetector = useRef(null);
  const captureRef = useRef();
  const bufTimeoutRef = useRef();

  const [getGoCard, { loading: cardLoading }] = useLazyQuery(GET_GOCARD, {
    onCompleted: ({ gocard }) => {
      const active = gocard.accountById.active;
      // Must be an active card
      if (active) {
        // Use an RO if we have one
        setGoCardId(gocard.accountById.cardId);
        if (ro !== null) {
          attachGoCardRO({
            variables: {
              dealerId: dealerId,
              roNumber: ro.ro_number,
              update: { gocard_id: gocard.accountById.cardId },
            },
          });
        } else {
          attachGoCardAppointment({
            variables: {
              dealerId: dealerId,
              appointmentId: appointment.cdk_appointment_id,
              gocardId: gocard.accountById.cardId,
            },
          });
        }
      } else {
        enqueueSnackbar('Go Card not active', { variant: 'error' });
      }
    },
    onError: e =>
      enqueueSnackbar('Go Card could not be found', { variant: 'error' }),
  });

  const [getCustomerByUrn, { data: customerData }] = useLazyQuery(
    GET_CUSTOMER_BY_URN,
    {
      onCompleted: () => {
        if (customerData?.customerByUrn?._id && appointment?.customer?._id) {
          if (customerData.customerByUrn._id !== appointment.customer._id) {
            startMerge(true);
            mergeCustomers(
              `${customerData.customerByUrn._id},`.concat(
                appointment.customer._id,
              ),
            );
          }
        }

        if (customerData?.customerByUrn?._id && ro?.customer?._id) {
          if (customerData.customerByUrn._id !== ro.customer._id) {
            startMerge(true);
            mergeCustomers(
              `${customerData.customerByUrn._id},`.concat(ro.customer._id),
            );
          }
        }
      },
    },
  );

  const [attachGoCardRO, { loading: ROLoading }] = useMutation(
    ADD_GOCARD_TO_RO,
    {
      onCompleted: data => {
        enqueueSnackbar('Go Card attached successfully to RO!', {
          variant: 'success',
        });
        if (refetch !== null) refetch();
        if (updateOverview !== null)
          updateOverview(data.rotracker.updateRO.gocard);
      },
      onError: e => {
        enqueueSnackbar(`Error attaching Go Card: ${e}`, { variant: 'error' });
        setValue('');
      },
    },
  );
  const [attachGoCardAppointment, { loading: AppointmentLoading }] =
    useMutation(ADD_GOCARD_TO_APPOINTMENT, {
      onCompleted: e => {
        enqueueSnackbar('Go Card attached successfully to Appointment!');
        refetch();
      },
      onError: e => {
        enqueueSnackbar(`Error attaching Go Card: ${e}`);
        setValue('');
      },
    });

  const parseQRPayload = payload => {
    const parsed = {
      cardId: null,
      valid: false,
      customerId: null,
      createdAt: null,
    };
    if (payload && payload.startsWith('GOCARD:')) {
      const parts = payload.split(':');
      parsed.cardId = Number(parts[1]);
      parsed.createdAt = new Date(Number(parts[2]) * 1000);
      parsed.valid = Math.abs(new Date() - parsed.createdAt) < 1000 * 60 * 15;
      parsed.customerId = Number(parts[3]);
    }

    return parsed;
  };

  const capture = async () => {
    if (barcodeDetector.current && video.current) {
      try {
        const barcodes = await barcodeDetector.current.detect(video.current);
        if (barcodes.length > 0) {
          setValue(barcodes[0].rawValue);
          setMode(MODE_IDLE);
        }
      } catch (e) {
        // pass
      }
    }
    captureRef.current = requestAnimationFrame(capture);
  };

  useEffect(() => {
    setMode(MODE_LOADING);
    const initBarcodeDetector = async () => {
      if ('BarcodeDetector' in window) {
        barcodeDetector.current = new window.BarcodeDetector();
      } else {
        barcodeDetector.current = null;
      }
      setMode(MODE_IDLE);
    };
    initBarcodeDetector();
  }, []);

  useEffect(() => {
    const start = async () => {
      const media = await navigator.mediaDevices.getUserMedia({
        audio: false,
        video: { facingMode: 'environment' },
      });
      video.current.srcObject = media;
      video.current.autoplay = true;
    };
    if (mode === MODE_DETECTING) {
      captureRef.current = requestAnimationFrame(capture);
      start();
    }
    return () => cancelAnimationFrame(captureRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, barcodeDetector.current]);

  useEffect(() => {
    const handleKey = e => {
      clearTimeout(bufTimeoutRef.current);
      if (e.key === 'Enter') {
        setValue(buf);
        setMode(MODE_IDLE);
      } else {
        setBuf(buf + e.key);
      }
      setTimeout(() => setBuf(''), 200);
    };
    if (mode === MODE_WAITING) {
      window.addEventListener('keypress', handleKey);
    }
    return () => window.removeEventListener('keypress', handleKey);
  }, [mode, buf]);

  const scan = () => {
    if (barcodeDetector.current) {
      setMode(MODE_DETECTING);
    } else {
      setMode(MODE_WAITING);
    }
  };

  useEffect(() => {
    if (goCardId) {
      getCustomerByUrn({
        variables: { urn: `goauto.gocard:gocard:${goCardId}` },
      });
    }
  }, [goCardId, getCustomerByUrn]);

  useEffect(() => {
    if (value) {
      const parsed = parseQRPayload(value);
      if (!parsed.valid) {
        enqueueSnackbar(
          'QR Code is not valid, refresh the QR code and try again',
          { variant: 'error' },
        );
      } else if (ro !== null && parsed.valid) {
        getGoCard({
          variables: {
            card_id: parsed.cardId,
          },
        });
      } else if (appointment && parsed.valid) {
        getGoCard({
          variables: {
            card_id: parsed.cardId,
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, setValue]);

  if (mode === MODE_IDLE) {
    return (
      <Box maxWidth={400} p={2}>
        <Button
          onClick={scan}
          variant="outlined"
          style={theme.actions.info}
          className={whiteFont}
        >
          <CenterFocusStrongIcon
            style={!iconOnly ? { paddingRight: '5px' } : null}
          />
          {!iconOnly && <>Scan Card</>}
        </Button>
        <LoadingBackdrop open={ROLoading || AppointmentLoading || cardLoading}>
          Attaching Go Card...
        </LoadingBackdrop>
      </Box>
    );
  }

  if (mode === MODE_LOADING) {
    return <div>Loading...</div>;
  }

  if (mode === MODE_DETECTING) {
    return (
      <Dialog open onClose={() => setMode(MODE_IDLE)}>
        <DialogContent>
          <p>Use your device camera to scan the QR code</p>
          <Box>
            <video ref={video} style={{ width: '100%' }}></video>
          </Box>
        </DialogContent>
      </Dialog>
    );
  }

  if (mode === MODE_WAITING) {
    return (
      <Dialog open onClose={() => setMode(MODE_IDLE)}>
        <DialogContent>
          <p>Use your scanner to scan the QR code</p>
        </DialogContent>
      </Dialog>
    );
  }
};

GoCardScanner.fragments = {
  ro: gql`
    fragment GoCardScannerRO on ROTrackerRO {
      ro_number
      customer {
        _id
      }
    }
  `,
  appointment: gql`
    fragment GoCardScannerAppointment on ROTrackerAppointment {
      cdk_appointment_id
      customer {
        _id
      }
    }
  `,
};

export default GoCardScanner;
