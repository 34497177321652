import React from 'react';

/* external */
import { useHistory } from 'react-router-dom';
import gql from 'graphql-tag';
import moment from 'moment-timezone';

/* Material UI */
import { Box, Chip, IconButton } from '@mui/material';

/* internal */
import {
  AppraisalStatus,
  AppraisalStatusColours,
} from 'modules/used_vehicles/const';

const StatusChip = ({ color, statusText, ...rest }) => {
  return (
    <Chip
      variant="outlined"
      style={{ color, borderColor: color }}
      label={statusText}
      {...rest}
    />
  );
};

const DashboardCardStatus = ({
  appraisal: {
    appraisalStatus,
    auctionItem,
    boughtNowAt,
    inTransit,
    isQuestionsPending,
    deliveredAt,
    soldAt,
  },
  dealer, // dealer or wholesaler - still not sure if I should just split the
  // DashboardCard component into dealer and wholesaler?
  showIcon,
  showChip,
  statusChipProps = {},
  offer,
  ...rest
}) => {
  const history = useHistory();
  const { auctionId } = auctionItem ?? {};
  let statusText = appraisalStatus;
  let color = AppraisalStatusColours[appraisalStatus];
  let icon = null;
  let iconColour = 'black';
  let onClick = null;

  const deliveryDelayed =
    !deliveredAt &&
    soldAt &&
    moment().subtract(7, 'days').isAfter(moment(soldAt));

  if (offer?.isQuestionsPending || (dealer && isQuestionsPending)) {
    icon = 'fas fa-question-circle';
    iconColour = 'blue';
  }

  if (dealer) {
    if (appraisalStatus === AppraisalStatus.SOLD)
      statusText = `BOUGHT${boughtNowAt ? ' (NOW)' : ''}`;
    if (appraisalStatus === AppraisalStatus.AUCTIONED)
      statusText = 'BOUGHT AT AUCTION';
  } else {
    if (appraisalStatus === AppraisalStatus.PENDING_AUCTION) {
      icon = 'fas fa-gavel';
      onClick = e => {
        if (auctionId) history.push(`/auctions/${auctionId}`);
        e.stopPropagation();
        e.preventDefault();
      };
    } else if (appraisalStatus === AppraisalStatus.SOLD)
      statusText = `${appraisalStatus}${boughtNowAt ? ' (NOW)' : ''}`;
  }

  // Show delivery status if sold or auctioned
  if (
    [AppraisalStatus.SOLD, AppraisalStatus.AUCTIONED].includes(appraisalStatus)
  ) {
    if (deliveredAt) {
      icon = 'fas fa-check-circle';
      iconColour = 'mediumseagreen';
    } else {
      icon = 'fas fa-exclamation-triangle';
      if (deliveryDelayed || !dealer) iconColour = 'red';
      else iconColour = 'orange';
    }
  }

  return (
    <Box
      paddingBottom="0.5rem"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      {...rest}
    >
      {showChip && statusText && (
        <Box>
          <StatusChip
            statusText={statusText}
            color={color}
            {...statusChipProps}
          />
        </Box>
      )}
      {showIcon && (
        <Box display="flex" flexDirection="column" alignItems="flex-end">
          {icon && (
            <Box fontSize="20px" color={iconColour || color}>
              {onClick ? (
                <IconButton onClick={onClick} size="large">
                  <i className={icon} />
                </IconButton>
              ) : (
                <i className={icon} />
              )}
            </Box>
          )}
          {inTransit &&
            [
              AppraisalStatus.OWNED,
              AppraisalStatus.SOLD,
              AppraisalStatus.AUCTIONED,
              AppraisalStatus.PENDING_OFFER,
            ].includes(appraisalStatus) && (
              /* pad if the icon is clickable to ensure the right edges line up */
              <Box paddingRight={onClick ? '12px' : ''}>
                <i
                  className="fas fa-shipping-timed"
                  style={{ fontSize: '16px', paddingRight: '5px' }}
                />
                <span style={{ fontSize: '12px' }}>In Transit</span>
              </Box>
            )}
        </Box>
      )}
    </Box>
  );
};

// not used:
// arrived_at
// is_customer_appraisal
// offered_to_dealer_id
// purchasing_dealer_id
DashboardCardStatus.fragments = {
  appraisal: gql`
    fragment DashboardCardStatusAppraisal on Appraisal {
      appraisalStatus
      arrivedAt
      auctionItem {
        id
        auctionId
      }
      boughtNowAt
      inTransit
      isCustomerAppraisal
      isQuestionsPending
      offeredToDealerId
      purchasingDealerId
      deliveredAt
      soldAt
    }
  `,
};
export default DashboardCardStatus;
