import { useLazyQuery } from '@apollo/react-hooks';
import { NetworkStatus } from 'apollo-boost';
import gql from 'graphql-tag';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import Drawer from '@mui/material/Drawer';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import FilterListIcon from '@mui/icons-material/FilterList';
import InfoIcon from '@mui/icons-material/Info';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import WatchLaterIcon from '@mui/icons-material/WatchLater';

import { InlineEdit } from 'components/MaterialUI';
import DealerPicker from 'components/MaterialUI/DealerPicker';
import FormatDateTZ from 'components/MaterialUI/FormatDateTZ';
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';
import { useUserContext } from 'components/MaterialUI/UserContext';

import {
  getFullDateTime,
  withSuffix,
  formatPrice,
  numberWithCommas,
} from 'utils';

import { filtersToFacets, titleCase } from '../../utils';
import BulkActions from '../bulk_actions/BulkActions';
import { useVehiclesQueryContext } from '../contexts/VehiclesQueryContext';
import FilterDropDown from './FilterDropDown';
import IconGroup from './IconGroup';
import InventoryHeader from './InventoryHeader';
import InventorySearch from './InventorySearch';
import InventoryToolbar from './InventoryToolbar';
import QuickHistoryPopover from './QuickHistoryPopover';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  multipleLine: {
    whiteSpace: 'pre-line',
    fontSize: '12px',
    color: '#999999',
  },
  colorMultipleLine: {
    whiteSpace: 'pre-line',
    fontSize: '14px',
    color: '#999999',
  },
  textFields: {
    fontSize: '12px',
  },
  right: {
    display: 'absolute',
    right: '0px',
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
}));

const VEHICLE_HISTORY_QUERY = gql`
  query InventoryQuery($sort: [QuerySortElement], $filters: [QueryFilter]) {
    inventory {
      getVehicleHistory(
        page: 1
        page_size: 20
        filters: $filters
        sort: $sort
      ) {
        results {
          id
          cost
          cost_delta
          is_special_price_enabled
          leads_count
          list_price
          list_price_delta
          list_price_fields_changed
          opportunities_count
          regular_price
          regular_price_delta
          special_price
          special_price_delta
          special_price_expires
          msrp
          msrp_delta
          modified_by {
            display_name
          }
          date_modified
          username
        }
      }
    }
  }
`;

const InventoryTable = () => {
  const classes = useStyles();
  const { me } = useUserContext();
  const [selected, setSelected] = useState([]);
  const [selectByFilter, setSelectByFilter] = useState(false);
  const [state, setState] = useState({
    left: false,
  });
  const {
    clearUpdateErrorHandler,
    facets,
    fetchMore,
    getUpdateError,
    inventoryFilters,
    isSaving,
    loading,
    networkStatus,
    order,
    orderBy,
    pagination,
    setFacets,
    setInventoryFilters,
    setOrder,
    setOrderBy,
    updateFieldHandler,
    vehicles,
  } = useVehiclesQueryContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverAnchors, setPopoverAnchors] = useState({});
  const [historyArray, setHistoryArray] = useState([]);
  const [vehicleHistory, { loading: historyLoading }] = useLazyQuery(
    VEHICLE_HISTORY_QUERY,
    {
      fetchPolicy: 'network-only',
      onCompleted: data => {
        setHistoryArray(
          (data && data.inventory.getVehicleHistory.results) || [],
        );
      },
    },
  );

  const handleAnchorClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleAnchorClose = () => {
    setAnchorEl(null);
  };

  const setPopoverAnchorHandler = _id => e => {
    setPopoverAnchors(prev =>
      Object.assign({}, prev, {
        [_id]: e.currentTarget,
      }),
    );

    const filters = [
      {
        model: 'VehicleHistoryNew',
        field: 'vehicle_id',
        op: '==',
        value: _id,
      },
      {
        model: 'VehicleHistoryNew',
        field: 'list_price_fields_changed',
        op: 'eq',
        value: true,
      },
    ];
    const sort = [
      {
        model: 'VehicleHistoryNew',
        field: 'date_modified',
        direction: 'desc',
      },
    ];

    vehicleHistory({
      variables: {
        sort,
        filters,
      },
    });
  };

  const clearPopoverAnchorHandler = _id => () => {
    setPopoverAnchors(prev =>
      Object.assign({}, prev, {
        [_id]: null,
      }),
    );
    setHistoryArray([]);
  };

  const toggleDrawer = (anchor, open) => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = e => {
    setSelected(e.target.checked ? vehicles.map(n => n.id) : []);
    setSelectByFilter(false);
  };

  const handleClick = (e, { id }) => {
    // Add the new id in the same order as displayed vehicles.
    if (e.target.checked)
      setSelected(prev =>
        vehicles.map(v => v.id).filter(x => [...prev, id].includes(x)),
      );
    else setSelected(prev => prev.filter(x => x !== id));
    setSelectByFilter(false);
  };

  const isSelected = ({ id }) => selected.includes(id) || selectByFilter;

  const handleDeleteFacet = (_model, _field, _value) => () =>
    setFacets(prev =>
      prev.filter(
        ({ model, field, value }) =>
          model !== _model || field !== _field || value !== _value,
      ),
    );

  const handleDeleteFilter = (_model, _field, _value) => () =>
    setInventoryFilters(prev =>
      prev.filter(
        ({ model, field, value }) =>
          model !== _model || field !== _field || value !== _value,
      ),
    );

  const filterFacets = filtersToFacets(inventoryFilters);

  useEffect(() => {
    // Vehicles could have reloaded, making some selected ids invalid.
    const vehicleIDs = vehicles.map(v => v.id);
    setSelected(prev => prev.filter(x => vehicleIDs.includes(x)));
  }, [vehicles]);

  return (
    <Paper className={classes.paper}>
      <Box marginLeft={1} marginTop={1}>
        <DealerPicker />
      </Box>
      <Box marginLeft={1} marginTop={1}>
        <Grid
          container
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
          }}
        >
          <Grid item>
            <div
              style={{
                display: 'flex',
              }}
            >
              <div style={{ paddingRight: '1rem' }}>
                <Tooltip title="Select Vehicles to use Bulk Actions">
                  <Fab
                    color="primary"
                    onClick={handleAnchorClick}
                    size="medium"
                  >
                    <MoreVertIcon />
                  </Fab>
                </Tooltip>
                <BulkActions
                  anchorEl={anchorEl}
                  handleAnchorClose={handleAnchorClose}
                  selected={selected}
                  selectByFilter={selectByFilter}
                />
              </div>
              <div style={{ paddingRight: '1rem' }}>
                <Tooltip title="Filter list">
                  <Fab
                    color="primary"
                    onClick={toggleDrawer('left', true)}
                    size="medium"
                  >
                    <FilterListIcon />
                  </Fab>
                </Tooltip>
              </div>
              <Drawer
                anchor={'left'}
                open={state['left']}
                onClose={toggleDrawer('left', false)}
                sx={{ zIndex: 1500 }}
              >
                <div style={{ display: 'flex' }}>
                  <IconButton
                    style={{
                      display: 'flex',
                      marginLeft: 'auto',
                      height: '50px',
                      zIndex: '1000',
                    }}
                    onClick={toggleDrawer('left', false)}
                    size="large"
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
                <FilterDropDown />
              </Drawer>
              {facets.map(({ model, field, value, options: { label } }) => (
                <span
                  style={{
                    paddingRight: '5px',
                    display: 'flex',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                  }}
                  key={`${model}${field}${value}`}
                >
                  <Chip
                    label={label || value}
                    value={value}
                    onDelete={handleDeleteFacet(model, field, value)}
                  />
                </span>
              ))}
              {filterFacets.map(
                ({ model, field, value, options: { label } }) => (
                  <span
                    style={{
                      paddingRight: '5px',
                      display: 'flex',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                    }}
                    key={`${model}${field}${value}`}
                  >
                    <Chip
                      label={label || value}
                      value={value}
                      onDelete={handleDeleteFilter(model, field, value)}
                    />
                  </span>
                ),
              )}
            </div>
          </Grid>
          <Grid
            item
            style={{
              display: 'flex',
              marginRight: 0,
              marginLeft: 'auto',
              marginBottom: 0,
            }}
          >
            <InventorySearch
              style={{
                display: 'flex',
                marginRight: 0,
                marginLeft: 'auto',
                marginBottom: 0,
              }}
              queryContext={useVehiclesQueryContext}
            />
          </Grid>
        </Grid>
      </Box>
      <Box marginLeft={1} marginTop={1} marginRight={1}>
        <Grid
          container
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
          }}
        >
          <Grid item>
            <div
              style={{
                display: 'flex',
              }}
            >
              {selected.length > 0 && (
                <InventoryToolbar
                  numSelected={
                    selectByFilter ? pagination.total : selected.length
                  }
                  total={pagination.total}
                  onSelectAllFiltered={() => setSelectByFilter(true)}
                />
              )}
            </div>
          </Grid>
          <Grid item>
            <div
              style={{
                textAlign: 'right',
                width: '100%',
                fontSize: '18px',
                alignItems: 'flex-end',
                alignSelf: 'flex-end',
              }}
            >
              Showing {vehicles.length} of {pagination.total}
            </div>
          </Grid>
        </Grid>
      </Box>
      <TableContainer>
        <Table className={classes.table}>
          <InventoryHeader
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={vehicles.length}
          />
          {networkStatus !== NetworkStatus.setVariables && (
            <TableBody>
              {vehicles.map((vehicle, index) => {
                const isItemSelected = isSelected(vehicle);
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={vehicle.id}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        onClick={event => handleClick(event, vehicle)}
                        color="secondary"
                      />
                    </TableCell>
                    <TableCell>
                      <Tooltip
                        title={'Click me to find out more about this vehicle!'}
                      >
                        <IconButton size="large">
                          <Link to={`/inventory/${vehicle.id}`}>
                            <InfoIcon
                              style={{ color: '#2196f3', fontSize: '22px' }}
                            />
                          </Link>
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      {vehicle.stock_number}
                      <Typography className={classes.multipleLine}>
                        {vehicle.stock_type} /{' '}
                        {titleCase(vehicle.stock_status_name)}
                      </Typography>
                      <Typography className={classes.multipleLineIcons}>
                        <IconGroup vehicle={vehicle} />
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {vehicle.displayName}
                      <Typography className={classes.multipleLine}>
                        {vehicle.trim_variation}
                      </Typography>
                      <Typography className={classes.multipleLine}>
                        {vehicle.manufacturer_code
                          ? 'Model Code: ' + vehicle.manufacturer_code
                          : ''}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {vehicle.permissions.read_cost
                        ? formatPrice(vehicle.cost)
                        : 'XXXX'}
                    </TableCell>
                    <TableCell
                      style={{
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <WatchLaterIcon
                        onMouseEnter={setPopoverAnchorHandler(vehicle.id)}
                        onMouseLeave={clearPopoverAnchorHandler(vehicle.id)}
                        style={{ paddingRight: '5px' }}
                      />
                      <QuickHistoryPopover
                        id={vehicle.id}
                        anchorEl={popoverAnchors[vehicle.id]}
                        onClose={clearPopoverAnchorHandler(vehicle.id)}
                        history={historyArray}
                        loading={historyLoading}
                        leads={vehicle.leads_count}
                        opps={vehicle.opportunities_count}
                      />
                      {formatPrice(vehicle.regular_price)}
                    </TableCell>
                    <TableCell
                      style={{
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {formatPrice(vehicle.special_price)}

                      <Typography
                        className={classes.multipleLine}
                        style={{
                          whiteSpace: 'nowrap',
                        }}
                      >
                        Exp:{' '}
                        {vehicle.special_price_expires ? (
                          <FormatDateTZ date={vehicle.special_price_expires} />
                        ) : (
                          ''
                        )}
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <InlineEdit
                        error={getUpdateError(vehicle.id, 'odometer')}
                        value={numberWithCommas(vehicle.odometer)}
                        id={`odometer_${vehicle.id}`}
                        saving={isSaving(vehicle.id, 'odometer')}
                        onSubmit={updateFieldHandler(vehicle.id, 'odometer')}
                        placeholder="Odometer"
                        onCancel={clearUpdateErrorHandler(vehicle.id)}
                        inputProps={{ readOnly: !vehicle.permissions.update }}
                        style={{
                          minWidth: '10em',
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      Ext: {vehicle.exterior_colour_name}
                      <Typography className={classes.colorMultipleLine}>
                        Int: {vehicle.interior_colour_name}
                      </Typography>
                    </TableCell>
                    <TableCell>{vehicle.days_in_stock}</TableCell>
                    <TableCell>{vehicle.leads_count}</TableCell>
                    <TableCell>{vehicle.opportunities_count}</TableCell>
                    <TableCell>{vehicle.photo_count}</TableCell>
                    <TableCell>
                      {getFullDateTime(
                        withSuffix(vehicle.date_user_modified, 'Z'),
                        me?.goUserProfile?.settings?.timezone,
                      )}
                    </TableCell>
                    <TableCell>{vehicle?.recon_source_name ?? 'N/A'}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {networkStatus === NetworkStatus.setVariables && (
        <>
          <div style={{ padding: '2rem', fontSize: '20px' }}>Sorting...</div>
          <LoadingBackdrop open={true} />
        </>
      )}
      {!loading && (
        <Box style={{ height: 1 }}>
          <Waypoint onEnter={fetchMore} />
        </Box>
      )}
      {loading && networkStatus === NetworkStatus.fetchMore && (
        <>
          <div style={{ padding: '2rem', fontSize: '20px' }}>
            Loading more vehicles...
          </div>
          <LoadingBackdrop open={true} />
        </>
      )}
    </Paper>
  );
};

InventoryTable.fragments = {
  vehicle: gql`
    fragment InventoryTableVehicle on GreaseInventoryVehicle {
      id
      cost
      date_user_modified
      days_in_stock
      displayName
      exterior_colour_name
      has_uvi
      interior_colour_name
      is_certified
      is_decoded
      is_detailed_pricing
      is_featured
      is_on_special
      leads_count
      manufacturer_code
      odometer
      opportunities_count
      permissions {
        read_cost
        update
      }
      photo_count
      recon_source_name
      regular_price
      special_price
      special_price_expires
      stock_number
      stock_type
      stock_status_name
      trim_variation
    }
  `,
};
export default InventoryTable;
