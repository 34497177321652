import React, { useState } from 'react';

import Loading from 'components/MaterialUI/Loading';
import gql from 'graphql-tag';
import { useSnackbar } from 'notistack';
import { formatPrice, numberWithCommas } from 'utils';

import { useMutation, useQuery } from '@apollo/react-hooks';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { formatPhoto, titleCase } from '../../utils';
import {
  NoStylesErrorAlert,
  NoVINErrorAlert,
} from '../alerts/DecodeWizardAlerts';
import WizardImageCarousel from '../misc/WizardImageCarousel';

const UPDATE_DECODE_VEHICLE = gql`
  mutation updateVehicle($id: Int!, $data: GreaseInventoryVehicleInput!) {
    inventory {
      updateVehicle(id: $id, data: $data) {
        id
        is_decoded
      }
    }
  }
`;

const DECODE_VEHICLE = gql`
  query decodeVehicle($vin: String) {
    inventory {
      decodeVehicle(vin: $vin) {
        styles {
          acode
          body_type_id
          cab_type_id
          doors
          drive_type_id
          engines {
            engine_config_id
            engine_cylinders
            engine_litres
            engine_compressor_id
            fuel_type_id
          }
          make_id
          make_name
          manufacturer_code
          model_id
          model_name
          name
          options {
            group
            header
            option_code
            option_value
          }
          passengers
          style_id
          transmissions {
            id
            name
          }
          trim_variation
          trim
          upholstery_id
          upholstery_name
          year
        }
      }
    }
  }
`;

const DecodeWizardCard = ({
  vehicle: {
    cost,
    days_in_stock,
    displayName,
    displayPhoto,
    id,
    odometer,
    published_trim,
    photos,
    stock_number,
    stock_type,
    trim_variation,
    vin,
  },
  mobile,
}) => {
  const { loading: stylesLoading, data: stylesData } = useQuery(
    DECODE_VEHICLE,
    { variables: { vin } },
  );
  const { enqueueSnackbar } = useSnackbar();
  const [selectedStyleId, setSelectedStyleId] = useState();
  const decodeStyles = stylesData?.inventory.decodeVehicle.styles || [];
  // TODO: updateVehicle error handling.
  const [updateVehicle] = useMutation(UPDATE_DECODE_VEHICLE, {
    onCompleted: r =>
      enqueueSnackbar(
        r.inventory.updateVehicle.is_decoded
          ? `Vehicle Successfully Decoded!`
          : `Error Decoding Vehicle.`,
      ),
  });

  const clickStyleHandler = style => () => {
    setSelectedStyleId(style.style_id);
    let {
      __typename,
      engines,
      make_name,
      model_name,
      name,
      options,
      transmission_ids,
      transmissions,
      upholstery_id,
      upholstery_name,
      ...data
    } = style;
    if (transmissions?.length === 1) data.transmission_id = transmissions[0].id;

    if (upholstery_id) data.upholstery_id = upholstery_id;
    if (engines.length === 1) {
      const { engineData } = engines[0];
      Object.assign(data, engineData);
    }
    data.options = options
      .filter(x => !x.option_code)
      .map(({ __typename, ...rest }) => rest);
    data.passengers = data.passengers[0];
    updateVehicle({
      variables: {
        id,
        data,
      },
    });
  };

  return (
    <div>
      <Grid
        container
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Grid item>
          <Grid container>
            <Grid item style={{ maxWidth: `${mobile ? '100%' : ''}` }}>
              <div style={{ display: 'flex', marginBottom: 0 }}>
                <span>
                  <span
                    style={{
                      fontWeight: 'bold',
                      fontSize: '20px',
                      paddingRight: '5px',
                    }}
                  >{` ${displayName} `}</span>
                  {` ${trim_variation || ''} `}
                </span>
                <span style={{ paddingLeft: '5px' }}>
                  <Chip size="small" label={titleCase(stock_type)}></Chip>
                </span>
              </div>
              <div style={{ paddingBottom: '5px' }}>
                {stock_number} / {vin ?? <strong>No VIN</strong>} /{' '}
                {numberWithCommas(odometer)} km
              </div>
              <div style={{ paddingBottom: '5px' }}>
                <b>Published Trim:</b> {published_trim || 'N/A'}
              </div>
              <div style={{ paddingBottom: '5px' }}>
                <span style={{ paddingRight: '30px', paddingBottom: '5px' }}>
                  <b>Days in Stock:</b> {days_in_stock}
                </span>{' '}
                <b>Cost:</b> {formatPrice(cost)}
              </div>
              <WizardImageCarousel
                photos={photos}
                width={'400px'}
                mobile={mobile}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          style={{
            width: `${mobile ? '50%' : ''}`,
            marginTop: `${decodeStyles.length === 0 && mobile ? '100px' : ''}`,
            marginBottom: `${decodeStyles.length === 0 ? 'auto' : ''}`,
          }}
        >
          {stylesLoading && <Loading />}
          {!stylesLoading && decodeStyles.length === 0 && !vin && (
            <div
              style={{
                width: '100%',
                padding: `${mobile ? 0 : '1rem 0 1rem 0'}`,
              }}
            >
              <NoVINErrorAlert />
            </div>
          )}
          {!stylesLoading && decodeStyles.length === 0 && vin && (
            <div
              style={{
                width: '100%',
                padding: `${mobile ? 0 : '1rem 0 1rem 0'}`,
              }}
            >
              <NoStylesErrorAlert vin={vin} />
            </div>
          )}
          {!stylesLoading && decodeStyles.length > 0 && (
            <List style={{ marginTop: `${mobile ? '85px' : '10px'}` }}>
              {decodeStyles.map(decodeStyle => (
                <ListItem
                  key={decodeStyle.style_id}
                  selected={decodeStyle.style_id === selectedStyleId}
                >
                  <Hidden smDown>
                    <Grid container>
                      <Grid item>
                        <img
                          alt="Stock"
                          style={{ width: '150px', paddingRight: '1rem' }}
                          src={formatPhoto(displayPhoto)}
                        ></img>
                      </Grid>
                      <Grid
                        item
                        direction="column"
                        style={{
                          paddingRight: '1rem',
                          display: 'block',
                          marginTop: 'auto',
                          marginBottom: 'auto',
                        }}
                      >
                        <div>
                          <div style={{ fontSize: '16px' }}>
                            {decodeStyle.year} {decodeStyle.make_name}{' '}
                            {decodeStyle.model_name} {decodeStyles.trim}
                          </div>
                          <div style={{ fontSize: '12px', color: '#999999' }}>
                            {decodeStyle.name}
                          </div>
                        </div>
                      </Grid>
                      <Grid
                        item
                        style={{
                          display: 'block',
                          marginTop: 'auto',
                          marginBottom: 'auto',
                        }}
                      >
                        <Button
                          onClick={clickStyleHandler(decodeStyle)}
                          style={{
                            backgroundColor: '#5bc236',
                            color: 'white',
                          }}
                        >
                          <CheckCircleIcon style={{ paddingRight: '5px' }} />{' '}
                          SELECT
                        </Button>
                      </Grid>
                    </Grid>
                  </Hidden>
                  <Hidden smUp>
                    <Grid container>
                      <Grid
                        item
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          flexDirection: 'row',
                          width: '100%',
                        }}
                      >
                        <img
                          alt="Stock"
                          style={{ width: '150px', paddingRight: '2rem' }}
                          src={formatPhoto(displayPhoto)}
                        ></img>
                        <Button
                          onClick={clickStyleHandler(decodeStyle)}
                          style={{
                            backgroundColor: '#5bc236',
                            color: 'white',
                            height: '30px',
                            marginTop: 'auto',
                            marginBottom: 'auto',
                            display: 'flex',
                          }}
                        >
                          <CheckCircleIcon />
                        </Button>
                      </Grid>
                      <Grid
                        item
                        direction="column"
                        style={{ paddingRight: '1rem' }}
                      >
                        <div style={{ fontSize: '14px' }}>
                          {decodeStyle.year} {decodeStyle.make_name}{' '}
                          {decodeStyle.model_name} {decodeStyles.trim}
                        </div>
                        <div style={{ fontSize: '12px', color: '#999999' }}>
                          {decodeStyle.name}
                        </div>
                      </Grid>
                    </Grid>
                  </Hidden>
                </ListItem>
              ))}
            </List>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

DecodeWizardCard.fragments = {
  vehicle: gql`
    fragment DecodeWizardCardVehicle on GreaseInventoryVehicle {
      id
      cost
      date_user_modified
      days_in_stock
      displayName
      displayPhoto {
        id
        cloudinary_public_id
      }
      exterior_colour_name
      has_uvi
      interior_colour_name
      is_certified
      is_decoded
      is_detailed_pricing
      is_featured
      is_on_special
      odometer
      photos {
        id
        cloudinary_public_id
      }
      published_trim
      regular_price
      special_price
      special_price_expires
      stock_number
      stock_type
      trim_variation
      vin
    }
  `,
};

export default DecodeWizardCard;
