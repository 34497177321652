import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';

import AddIcon from '@mui/icons-material/Add';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import { green } from '@mui/material/colors';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import SearchIcon from '@mui/icons-material/Search';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';

import AddCustomerFlexModal from './AddCustomerFlexModal';
import ConfirmDialog from 'components/MaterialUI/ConfirmDialog';
import { Waypoint } from 'react-waypoint';
import SearchResult from '../CustomerSearch/SearchResult';
import SearchResultMobile from '../CustomerSearch/SearchResultMobile';

const PAGE_SIZE = 25;

const SEARCH_QUERY = gql`
  query customerSearch($q: String!, $cursor: String!) {
    customerSearch(
      params: {
        query: $q
        size: ${PAGE_SIZE}
        cursor: $cursor
        filterQuery: "(and resource_type:'smi.crm:customer')"
        sort: "_score desc"
      }
    ) {
      hits {
        found
        cursor
        hit {
          id
          customer {
            _id
            ...SearchResultFragment
            ...SearchResultMobileCustomer
          }
        }
      }
    }
  }
  ${SearchResult.fragments.customer}
  ${SearchResultMobile.fragments.customer}
`;

const ADD_RECORD = gql`
  mutation addRecordToCustomer($_id: ID!, $records: [String]!) {
    attachCustomerRecords(_id: $_id, records: $records) {
      _id
      records
    }
  }
`;

const useStyles = makeStyles(theme => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
  },
  filterGrid: {
    paddingTop: '1rem !important',
  },
  searchGrid: {
    padding: '24px 12px 0px 24px !important',
  },
  searchField: {
    display: 'flex',
    padding: '2px 4px',
    flex: 1,
  },
  button: {
    alignSelf: 'center',
    padding: '24px 12px 0px 12px !important',
  },
  fabAbove: {
    bottom: '55px',
    right: '10px',
    position: 'fixed',
    backgroundColor: green[500],
  },
  fabBelow: {
    bottom: '10px',
    right: '10px',
    position: 'fixed',
    backgroundColor: green[500],
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '10px',
  },
  results: {
    alignSelf: 'flex-end',
    textAlign: 'right',
    paddingRight: '10px !important',
  },
  mTextField: {
    width: '100%',
    paddingLeft: '4px',
    paddingRight: '4px',
  },
  mHeader: {
    paddingLeft: '10px',
    paddingTop: '5px',
  },
  mRoot: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  },
  mResults: {
    alignSelf: 'flex-end',
    textAlign: 'right',
    fontSize: '10px',
    paddingRight: '20px',
    paddingBottom: '5px',
  },
}));

const CustomerFlexSearch = props => {
  const location = useLocation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [customerId, setCustomerId] = useState();
  const [showModal, setShowModal] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [attachRecord] = useMutation(ADD_RECORD, {
    onCompleted: data => {
      enqueueSnackbar(`Successfully attached record`, {
        variant: 'success',
      });
      history.push(`/customers/${data.attachCustomerRecords._id}/details`);
    },
    onError: e =>
      enqueueSnackbar(`Error attaching record`, {
        variant: 'error',
      }),
  });

  // Seperate parameters
  const params = {};
  decodeURIComponent(location.search)
    .substring(1)
    .split('&')
    .forEach(pair => {
      let [key, value] = pair.split('=');
      params[key] = value;
    });

  const messengerId = params?.messenger_id || null;
  const searchText = params?.customer || null;
  const searched = searchText !== null;
  const searchInput = React.useRef();
  const classes = useStyles();
  const { width } = props;
  const qry = useQuery(SEARCH_QUERY, {
    variables: { q: searchText, cursor: 'initial' },
  });
  const { data, loading, fetchMore } = qry;
  const hits = (data && data.customerSearch.hits.hit) || [];
  const cursor = (data && data.customerSearch.hits.cursor) || 'initial';
  const found = (data && data.customerSearch.hits.found) || 0;
  const loadMoreResults = () =>
    fetchMore({
      variables: { q: searchText, cursor },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        const newData = {
          ...fetchMoreResult,
        };
        newData.customerSearch.hits.hit = [
          ...prev.customerSearch.hits.hit,
          ...fetchMoreResult.customerSearch.hits.hit,
        ];
        return newData;
      },
    });

  const handleCloseDialog = () => {
    setShowDialog(false);
  };

  const handleOpenDialog = customer => {
    setCustomerId(customer._id);
    setShowDialog(true);
  };

  const handleAttach = () => {
    if (messengerId) {
      attachRecord({
        variables: {
          _id: customerId,
          records: `flex:facebook_message:${messengerId}`,
        },
      });
    } else {
      enqueueSnackbar(`No facebook message id provided`, {
        variant: 'error',
      });
    }
  };

  const handleModalClick = () => {
    setShowModal(prevShowModal => !prevShowModal);
  };

  return (
    <>
      <ConfirmDialog
        text="Are you sure you want to attach a record to this customer?"
        onConfirm={handleAttach}
        isOpen={showDialog}
        onClose={handleCloseDialog}
      />
      <Dialog
        maxWidth="sm"
        open={showModal}
        onClose={() => {
          setShowModal(false);
        }}
      >
        <AddCustomerFlexModal
          handleClose={() => {
            setShowModal(false);
          }}
          messengerId={messengerId}
        />
      </Dialog>
      <Hidden lgDown>
        <div style={{ overflowX: 'hidden' }}>
          <Box m={[0, 1]}>
            <Grid container spacing={3}>
              <Grid item xs={9} className={classes.searchGrid}>
                <Paper
                  style={{ backgroundColor: '#FAFAFA' }}
                  elevation={0}
                  component="form"
                  className={classes.root}
                  onSubmit={e => {
                    e.preventDefault();
                    history.push({
                      search: `customer=${searchInput.current.value}&messenger_id=${messengerId}`,
                    });
                  }}
                >
                  <TextField
                    className={classes.searchField}
                    inputRef={searchInput}
                    placeholder="Search by name, phone or email"
                    defaultValue={searchText}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={e => {
                              e.preventDefault();
                              searchInput.current.value = '';
                              history.push({
                                search: `messenger_id=${messengerId}`,
                              });
                            }}
                            size="large"
                          >
                            <CloseIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Paper>
              </Grid>
              <Grid item xs={3} className={classes.button}></Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={2} className={classes.filterGrid}></Grid>
              <Grid item xs={10} className={classes.results}>
                <Typography>
                  Showing {hits.length} of {found}
                </Typography>
              </Grid>
            </Grid>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2}>Customer Name</TableCell>
                  <TableCell>Contact</TableCell>
                  <TableCell>Vehicles</TableCell>
                  <TableCell>Created</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {hits.map((hit, n) => (
                  <>
                    {(hit.customer && (
                      <TableRow
                        key={`${hit.id}:row`}
                        hover
                        onClick={() => handleOpenDialog(hit.customer)}
                      >
                        <SearchResult customer={hit.customer} />
                      </TableRow>
                    )) || (
                      <Alert
                        severity="error"
                        elevation={1}
                        key={`${hit.id}:alert`}
                      >
                        Search result customer does not exist ({hit.id})
                      </Alert>
                    )}
                    {n === hits.length - 10 && ['lg', 'xl'].includes(width) && (
                      <Waypoint onEnter={loadMoreResults} />
                    )}
                  </>
                ))}
              </TableBody>
            </Table>
            {!hits.length && !loading && (
              <Box textAlign="center" mt={4}>
                <SearchIcon fontSize="large" />
                <Typography variant="subtitle1" gutterBottom>
                  No Results
                </Typography>
                <Typography variant="subtitle2">
                  {searched
                    ? 'Create a new customer'
                    : 'Search for a customer...'}
                </Typography>
              </Box>
            )}
            {searched && !hits.length && !loading && (
              <Box textAlign="center" mt={4}>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={() => {
                    if (messengerId !== null) {
                      handleModalClick();
                    } else {
                      enqueueSnackbar(`No facebook message id provided`, {
                        variant: 'error',
                      });
                    }
                  }}
                  style={{ backgroundColor: green[500] }}
                >
                  New Customer
                </Button>
              </Box>
            )}

            {!hits.length && loading && (
              <Box textAlign="center" mt={4}>
                <CircularProgress />
                <Typography variant="subtitle1" gutterBottom>
                  Loading
                </Typography>
              </Box>
            )}
          </Box>
        </div>
      </Hidden>
      <Hidden lgUp>
        <div>
          <Box>
            <h3 className={classes.mHeader}>Customer Search</h3>
            <Paper
              style={{ backgroundColor: '#FAFAFA' }}
              elevation={0}
              component="form"
              className={classes.mRoot}
              onSubmit={e => {
                e.preventDefault();
                history.push({
                  search: `customer=${searchInput.current.value}&messenger_id=${messengerId}`,
                });
              }}
            >
              <TextField
                className={classes.mTextField}
                inputRef={searchInput}
                placeholder="Search by name, phone or email"
                defaultValue={searchText}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={e => {
                          e.preventDefault();
                          searchInput.current.value = '';
                          history.push({
                            search: `messenger_id=${messengerId}`,
                          });
                        }}
                        size="large"
                      >
                        <CloseIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Paper>
          </Box>
          <Grid container>
            <Grid item xs={12} className={classes.mResults}>
              Showing {hits.length} of {found}
            </Grid>
          </Grid>
          {hits.map((hit, n) => (
            <>
              {(hit.customer && (
                <div style={{ padding: '5px 10px 5px 10px' }} key={hit.id}>
                  <Card onClick={() => handleOpenDialog(hit.customer)}>
                    <SearchResultMobile customer={hit.customer} />
                  </Card>
                </div>
              )) || (
                <Alert severity="error" elevation={1}>
                  Search result customer does not exist ({hit.id})
                </Alert>
              )}
              {n === hits.length - 10 && !['lg', 'xl'].includes(width) && (
                <Waypoint onEnter={loadMoreResults} />
              )}
            </>
          ))}
          {!hits.length && !loading && (
            <Box textAlign="center" mt={4}>
              <SearchIcon fontSize="large" />
              <Typography variant="subtitle1" gutterBottom>
                No Results
              </Typography>
              <Typography variant="subtitle2">
                {searched
                  ? 'Create a new customer'
                  : 'Search for a customer...'}
              </Typography>
            </Box>
          )}
          {!hits.length && loading && (
            <Box textAlign="center" mt={4}>
              <CircularProgress />
              <Typography variant="subtitle1" gutterBottom>
                Loading
              </Typography>
            </Box>
          )}
          {searched && !hits.length && !loading && (
            <Box textAlign="center" mt={4}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={() => {
                  if (messengerId !== null) {
                    handleModalClick();
                  } else {
                    enqueueSnackbar(`No facebook message id provided`, {
                      variant: 'error',
                    });
                  }
                }}
                style={{ backgroundColor: green[500] }}
              >
                New Customer
              </Button>
            </Box>
          )}
        </div>
      </Hidden>
    </>
  );
};

export default CustomerFlexSearch;
