// External
import { useMutation, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useHistory } from 'react-router-dom';

// Material UI
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';

// Internal
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import DealerPicker from 'components/MaterialUI/DealerPicker';
import Loading from 'components/MaterialUI/Loading';
import { useUserContext } from 'components/MaterialUI/UserContext';
import { RoleGroup } from 'constants.js';
import { format_seconds } from '../utils.js';
import LeadConfigAdfEmail from './LeadConfigAdfEmail.jsx';

const GET_LEADS_CONFIG = gql`
  query getLeadsConfig($dealerId: Int!) {
    leadsConfig(dealer_id: $dealerId) {
      dealer_id
      distribution_rules {
        _id
        distribution_type
        lead_types
        form_names
        sources
        usernames
        users {
          display_name
        }
        rapid_response_timeout
        prefer_assigned_sales_rep
        is_in_purchase_timeline
        is_out_purchase_timeline
      }
    }
  }
`;

const UPDATE_RULE_ORDER = gql`
  mutation updateRuleOrder($dealerId: Int!, $input: RuleOrder) {
    updateRuleOrder(dealer_id: $dealerId, input: $input) {
      dealer_id
      distribution_rules {
        _id
        distribution_type
        lead_types
        form_names
        sources
        usernames
        users {
          display_name
        }
        rapid_response_timeout
        prefer_assigned_sales_rep
        is_in_purchase_timeline
        is_out_purchase_timeline
      }
    }
  }
`;

const DELETE_CONFIG_RULE = gql`
  mutation deleteLeadsConfigRule($dealerId: Int!, $ruleId: ID!) {
    deleteLeadsConfigRule(dealer_id: $dealerId, rule_id: $ruleId) {
      dealer_id
      distribution_rules {
        _id
      }
    }
  }
`;

const LeadConfig = () => {
  const { enqueueSnackbar } = useSnackbar();

  const { dealerId } = useDealerContext();
  const { currentUser } = useUserContext();
  const role = currentUser?.role || 'none';
  const disabled = !RoleGroup.EDIT_LEAD_CONFIG_ROLE.includes(role);
  const history = useHistory();

  const { data, loading, refetch } = useQuery(GET_LEADS_CONFIG, {
    variables: {
      dealerId: dealerId,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const leadConfig = data?.leadsConfig || {};

  const moveRule = (from, to) => {
    if (
      leadConfig.distribution_rules &&
      leadConfig.distribution_rules.length > 1
    ) {
      var configRuleOrder = leadConfig.distribution_rules.map(({ _id }) => _id);
      var elem = configRuleOrder.splice(from, 1)[0];
      configRuleOrder.splice(to, 0, elem);
      submitRuleOrder(configRuleOrder);
    }
  };

  const [updateRuleOrder] = useMutation(UPDATE_RULE_ORDER, {
    onCompleted: () => {
      enqueueSnackbar(`Leads distribution rule order updated`, {
        variant: 'success',
      });
      refetch();
    },
    onError: () =>
      enqueueSnackbar(`Error when updating leads distribution rule order`, {
        variant: 'error',
      }),
  });

  const submitRuleOrder = ruleIds => {
    updateRuleOrder({
      variables: {
        dealerId: dealerId,
        input: {
          rule_ids: ruleIds,
        },
      },
    });
  };

  const [deleteRule] = useMutation(DELETE_CONFIG_RULE, {
    onCompleted: () => {
      enqueueSnackbar(`Leads distribution rule deleted`, {
        variant: 'success',
      });
      refetch();
    },
    onError: () =>
      enqueueSnackbar(`Error when deleting leads distribution rule`, {
        variant: 'error',
      }),
  });

  const onDelete = ruleId => {
    deleteRule({
      variables: {
        dealerId: dealerId,
        ruleId: ruleId,
      },
    });
  };

  return (
    <Box m={2}>
      <Box>
        <DealerPicker />
      </Box>
      {loading && <Loading />}
      {!loading && (
        <Box>
          <LeadConfigAdfEmail disabled={disabled} />
          <hr />
          <Typography
            style={{
              fontSize: '1.5rem',
              fontWeight: '700',
              marginTop: '.5rem',
            }}
          >
            Lead Distribution Rules
          </Typography>
          When a new lead arrives, it will be checked against the rules in the
          order they appear below. Only the first matching rule will be used to
          distribute the lead.
          <br />
          <Button
            disabled={disabled}
            color="primary"
            variant="contained"
            style={{ marginTop: '.5rem' }}
            onClick={() => history.push('/leads/config/rule')}
          >
            <i className="fas fa-plus" />
            &nbsp;New Rule
          </Button>
          {leadConfig.distribution_rules.map((rule, index, row) => (
            <Box key={index}>
              <hr />
              {rule.lead_types?.[0] === '*' && (
                <Alert severity="warning" style={{ marginBottom: '.5rem' }}>
                  <strong>Please Note: </strong> The Catch-All rule will only be
                  applied when the lead type does not match any of the other
                  rules defined above.
                </Alert>
              )}
              <Grid
                container
                direction="row"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Grid
                  item
                  style={{
                    padding: '1rem',
                    backgroundColor: '#f0f0f0',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {index + 1}
                </Grid>
                <Grid
                  item
                  style={{
                    paddingLeft: '.5rem',
                    textTransform: 'capitalize',
                    flex: '1',
                  }}
                >
                  Lead types:{' '}
                  {rule.lead_types?.map((type, i, row) => (
                    <b key={i}>
                      {' '}
                      {type === '*' ? 'Catch-All' : type.split('_').join(' ')}
                      {i + 1 !== row.length && ','}
                    </b>
                  ))}
                  <br />
                  Form names:{' '}
                  {rule.form_names?.length === 0 ? (
                    <b>{' Any form'}</b>
                  ) : (
                    rule.form_names.map((name, i, row) => (
                      <b key={i}>
                        {' '}
                        {name.split('_').join(' ')}
                        {i + 1 !== row.length && ','}
                      </b>
                    ))
                  )}
                  <br />
                  Sources:{' '}
                  {rule.sources?.length === 0 ? (
                    <b>{' Any source'}</b>
                  ) : (
                    rule.sources.map((source, i, row) => (
                      <b key={i}>
                        {' '}
                        {source.split('_').join(' ')}
                        {i + 1 !== row.length && ','}
                      </b>
                    ))
                  )}
                  <br />
                  Purchase Timeline:{' '}
                  <b>
                    {rule.is_in_purchase_timeline
                      ? '1 month or less'
                      : rule.is_out_purchase_timeline
                      ? 'Over 1 month'
                      : 'Any timeline'}
                  </b>
                  <br />
                  Distribute:{' '}
                  <b>{rule.distribution_type?.split('-').join(' ')}</b>
                  <br />
                  Time limit to respond:{' '}
                  <b>
                    {rule.rapid_response_timeout
                      ? format_seconds(rule.rapid_response_timeout)
                      : 'No limit'}
                  </b>
                  <br />
                  Prefer assigned sales rep when available:{' '}
                  <b>{rule.prefer_assigned_sales_rep ? 'Yes' : 'No'}</b>
                  <br />
                  <Box
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      marginTop: '.5rem',
                    }}
                  >
                    {rule.users?.length > 0 &&
                      rule.users.map((name, i) => (
                        <Chip
                          key={i}
                          label={name.display_name}
                          style={{ margin: '.1rem .25rem' }}
                        />
                      ))}
                  </Box>
                  <Button
                    disabled={disabled}
                    size="small"
                    onClick={() =>
                      history.push(`/leads/config/rule/${rule._id}`)
                    }
                  >
                    <i className="fas fa-edit"></i>&nbsp;Edit
                  </Button>
                  {row.length > 1 && index !== 0 && (
                    <span>
                      {' '}
                      |{' '}
                      <Button
                        disabled={disabled}
                        size="small"
                        onClick={() => moveRule(index, index - 1)}
                      >
                        <i className="fas fa-angle-up"></i>&nbsp;Move Up
                      </Button>
                      |{' '}
                      <Button
                        disabled={disabled}
                        size="small"
                        onClick={() => moveRule(index, 0)}
                      >
                        <i className="fas fa-angle-double-up"></i>&nbsp;Move To
                        Top
                      </Button>
                    </span>
                  )}
                  {row.length > 1 && index + 1 !== row.length && (
                    <span>
                      {' '}
                      |{' '}
                      <Button
                        disabled={disabled}
                        size="small"
                        onClick={() => moveRule(index, index + 1)}
                      >
                        <i className="fas fa-angle-down"></i>&nbsp;Move Down
                      </Button>
                      |{' '}
                      <Button
                        disabled={disabled}
                        size="small"
                        onClick={() => moveRule(index, row.length - 1)}
                      >
                        <i className="fas fa-angle-double-down"></i>&nbsp;Move
                        To Bottom
                      </Button>
                    </span>
                  )}
                  <span> | </span>
                  <Button
                    disabled={disabled}
                    size="small"
                    onClick={() => onDelete(rule._id)}
                  >
                    <i className="fas fa-trash-alt"></i>&nbsp;Delete
                  </Button>
                </Grid>
              </Grid>
            </Box>
          ))}
          <hr />
        </Box>
      )}
    </Box>
  );
};

export default LeadConfig;
