import React, { useState } from 'react';

/* external */
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { every } from 'lodash';
import { useSnackbar } from 'notistack';

/* Material UI */
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material';
import TextField from '@mui/material/TextField';

/* internal */
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';

const CREATE_TRANSACTION = gql`
  mutation createTransaction($input: createTransactionInput!) {
    gocard {
      createTransaction(input: $input) {
        transactionId
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  dropDown: {
    width: '45%',
  },
  mobileDropDown: {
    width: '70%',
  },
}));

const ReferPointsModal = ({ gocard, isOpen, onClose, locations, refetch }) => {
  const theme = useTheme();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const desktop = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const [locationId, setLocationId] = useState('');
  const [referralCode, setReferralCode] = useState('');
  const [amount, setAmount] = useState(0);
  const [desc, setDesc] = useState('');
  const [errors, setErrors] = useState({
    dealerSelect: true,
    referCode: true,
    amountSelect: true,
    descSelect: true,
  });

  const [createTransaction, { loading }] = useMutation(CREATE_TRANSACTION, {
    onCompleted: e => {
      enqueueSnackbar('Referral points awarded!!', {
        variant: 'success',
      });
      onClose();
      refetch();
    },
    onError: e =>
      enqueueSnackbar(`Error: Ensure referral code is correct.`, {
        variant: 'error',
      }),
  });

  const amountCheck = input => input < 0;

  const dealerChange = event => {
    setErrors({ ...errors, dealerSelect: !event.target.value });
    setLocationId(event.target.value);
  };

  const amountChange = event => {
    setErrors({ ...errors, amountSelect: amountCheck(event.target.value) });
    setAmount(event.target.value);
  };

  const descChange = event => {
    setErrors({ ...errors, descSelect: event.target.value === '' });
    setDesc(event.target.value);
  };

  const referChange = event => {
    setErrors({ ...errors, referCode: event.target.value === '' });
    setReferralCode(event.target.value);
  };

  const isFalse = bool => {
    return bool === false;
  };

  const onSubmit = () => {
    // Validation before submitting
    const isValid = every(errors, isFalse);
    const payload = {
      cardId: gocard.cardId,
      locationId: locationId,
      amount: Number(amount),
      code: 'RCRED',
      referralCode: referralCode,
      description: desc,
    };
    if (!isValid) {
      enqueueSnackbar('Missing required fields', { variant: 'error' });
    } else {
      createTransaction({
        variables: { input: payload },
      });
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Award Referral Credit</DialogTitle>
      <DialogContent>
        <FormControl
          className={desktop ? classes.dropDown : classes.mobileDropDown}
          error={errors.dealerSelect}
        >
          <InputLabel>Location</InputLabel>
          <Select value={locationId} onChange={dealerChange}>
            {locations.map(location => (
              <MenuItem key={location.locationId} value={location.locationId}>
                {location.locationName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogContent>
        <FormControl
          className={desktop ? classes.dropDown : classes.mobileDropDown}
        >
          <TextField
            label="Referred Account"
            placeholder="Referral Code"
            value={referralCode}
            onChange={referChange}
            error={errors.referCode}
          />
        </FormControl>
      </DialogContent>
      <DialogContent>
        <FormControl
          className={desktop ? classes.dropDown : classes.mobileDropDown}
          error={errors.amountSelect}
        >
          <InputLabel>Amount</InputLabel>
          <Input
            value={amount}
            type="number"
            onChange={amountChange}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
          />
        </FormControl>
        <FormControl fullWidth>
          <TextField
            label="Description"
            value={desc}
            onChange={descChange}
            multiline
            maxRows={2}
            error={errors.descSelect}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button style={theme.actions.close} onClick={onClose} variant="text">
          Close
        </Button>
        <Button
          style={theme.actions.confirm}
          onClick={() => onSubmit()}
          variant="contained"
          color="primary"
        >
          Save
        </Button>
      </DialogActions>
      <LoadingBackdrop open={loading}>Awarding Points...</LoadingBackdrop>
    </Dialog>
  );
};

export default ReferPointsModal;
