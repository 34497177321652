import React, { useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import { cloneDeep } from 'lodash';

import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';

import { withStyles } from '@mui/material';

import SuperAccordion from './SuperAccordion';

const VWPDIID4Form = props => {
  const { enqueueSnackbar } = useSnackbar();

  const initialInspection = {
    vw1: 'Was the vehicle delivered free of damage and was the by-pack opened?',
    vw1A: 'Note damage and missing items relating to the transportation using the AIAG 5-digit damage code',
    vw1B: 'If the by-pack bag is found open in any way and parts are missing, a transportation claim should be filed',
    vw1C: 'If the bag is completely sealed and parts are missing, a claim should be filed under warranty',
    vw1D: 'Document the condition of the by-pack whether it is sealed (undisturbed) or opened (disturbed) on the transportation delivery form',
    vw1E: 'If the by-pack is opened (disturbed) list the parts that are missing on the transportation form',
    vw1F: 'Pictures of the condition of the by-pack bag are important when submitting a warranty or transportation claim',
    vw2: 'Perform a 12-Volt battery test',
    vw2A: 'The vehicle must not have been put into ready mode for 12 hours prior to the battery test. The VAS 6161 is the only approved Volkswagen tool that can be used to test the batteries in Volkswagen vehicles',
    vw2B: 'If the battery requires charging, use the VAS 3000 GRX or VAS 5908',
    vw3: 'Inspect the interior for damages and cleanliness',
    vw3A: 'Check the interior for dirt or damage. Especially, if the vehicle has been used for a demo drive. If you find any defects, contact your Service Manager to schedule immediate repair',
    vw4: 'Inspect the exterior for damage and cleanliness',
    vw4A: 'Make sure the vehicle exterior is clean and free of environmental damage, scratches, dents, and dings. Especially, if the vehicle has been used for a demo drive. If you find any defects, contact your Service Manager to schedule immediate repair',
    vw5: 'Check tire pressure',
    vw5A: "For vehicles that have been PDI'd and are on your lot, the tires should be at the normal operational settings. For vehicles in off-site storage, set the tire pressures to the transportation settings to help prevent tire flat spotting",
    vw6: 'Read HV battery state of charge from the charging screen on the central display. If below 30%, charge the HV battery to at least 50% - Level 2 recommended based on speed of charge',
    vw7: 'Drive the vehicle for a minimum of 2 km. At 30 km/h, gradually apply the brakes several times. This eliminates brake rotor deposits and prevents flat spotting of the tires',
  };

  const technicalInspection = {
    vw8: 'Make note of condition of BY-Pack and label at the time of receipt. Verify that all items are accounted for. Make note (i.e sealed, torn open, missing part, etc.) on the RO',
    vw8A: 'For more information please reference Technical Bulletin 2054073 - By-Pack at Delivery for additional information',
    vw9: 'Review the contents label on the by-pack and verify all items are accounted for',
    vw10: 'Place tow hook and wheel lock adapter (if equipped) in the tool kit. Discard 2nd tow hook (if applicable)',
    vw11: 'Remove Wrap Guard',
    vw11A:
      'Ensure that the wrap guard and any remaining adhesive are completely removed and that any damages claimed at vehicle reception have been repaired',
    vw12: 'Check hood latch and safety catch',
    vw12A:
      'Test the battery with the Midtronics VAS 6161 in warranty mode within 48 hours of vehicle arrival at dealership. Record and document test code. If the battery requires charging, use use the VAS 3000 GRX or VAS 5908',
    vw13: 'Using the ODIS guided functions, disable vehicle transport mode',
    vw14: 'Check coolant level (cold). Do not exceed max fill line',
    vw15: 'Top-off windshield washer solvent',
    vw16: 'Check brake fluid levels. Do not exceed max fill line',
    vw17: 'Torque wheels to specification. (Refer to Elsa)',
    vw18: 'Adjust tire pressure to specification on vehicle sticker at "B" Pillar',
    vw19: 'Install front license plate bracket, if required',
    vw20: 'Raise vehicle and perform a visual inspection for leaks and/or under body damage. Install rear tow hook cover (if applicable)',
  };

  const interiorInspection = {
    vw21: 'Check seat belts, seat adjusters, heated seats, ventilated seats (if applicable)',
    vw22: 'Check 2nd row seat functionality, including folding the seat',
    vw23: 'Verify child safety lock is set to unlocked position',
    vw24: 'Install floor mats (only install ONE floor mat per seating position, do not double stack floor mats)',
    vw25: "Confirm the online Warranty/Maintenance & Tire Booklet location card is included in the owner's kit",
    vw26: 'Check that all keys and remotes lock, unlock, and start vehicle',
    vw27: 'Check proper trunk lid operation using the remote and the exterior button',
    vw28: 'Check all instrument cluster warning lights by cycling the ignition from off to on',
    vw29: 'Set clock, date, and year (if applicable)',
    vw30: 'Check operation of all exterior lights and AFS using a mirror or another technician',
    vw31: 'Check function of all power windows, including the sunroof (if equipped); program One-Touch feature, and check function of pinch protection feature',
    vw32: 'Check proper charging door operation',
    vw33: 'Check front windshield wiper/washer functions',
    vw34: 'Verify radio operates in AM, FM, CD, MP3 and Satellite Radio modes using applicable radio buttons and steering wheel controls.',
    vw35: 'Verify HD Radio is turned "off" in Radio/Settings menu',
  };

  const onHoist = {
    vw36: 'Conduct a visual inspection of visible high-voltage components in motor compartment, underbody, and rear',
  };

  const roadTest = {
    vw37: { type: 'odometer', text: 'Odometer reading' },
    vw37A: 'Ensure vehicle is driven outside for more than 5 minutes',
    vw38: 'Check electric motor performance and acceleration',
    vw39: 'Check automatic transmission operation, including automatic shift lock (Ensure that a gear will not engage unless the brake pedal is pressed)',
    vw40: 'Check steering for pulling, vibration and steering wheel alignment',
    vw41: 'Check climate control for mode functions (Defrost/Vent/Floor), air conditioning operation and heater operation',
    vw42: 'Check brake system function (including ABS)/Electronic parking brake operation engage and disengage',
    vw43: 'Test for battery recuperation',
  };

  const afterRoadTest = {
    vw44: 'Verify no warning lights are illuminated',
    vw45: "Retrieve fault memory, correct any DTC's, set readiness code (if necessary). Save and attach printout to Repair Order.",
    vw46: 'Plug vehicle into a charger to ensure HV Battery is 100% charged before delivering vehicle to customer - Level 2 recommended based on speed of charge',
    vw46A:
      'Ensure enough time is alloted for battery to reach 100% charge by the scheduled delivery appointment',
    vw47: 'Confirm if the customer is purchasing an Electrify Canada Home Charger, and confirm the order for this VIN has been placed',
    vw48: 'Install Roadside sticker on the very top left corner of the inside of the windshield',
    vw49: 'Re-enable transport mode (for unsold vehicles)',
  };

  const vehicleUpdate = {
    vw50: 'All product updates for this VIN have been checked and verified in Elsa',
    vw51: 'Dealer 30-day maintenance process has been initiated',
  };

  const preDelivery = {
    vw52: 'Pre-delivery, when scheduling appointment or sending confirmation email/message add training links',
    vw52A:
      'Customers completing pre-delivery training helps to improve thoroughness of in-person or over the phone delivery tutorial and/or reduces the time the customer requires before heading out',
    vw53: 'Install dealer accessories package',
  };

  const detailerInterior = {
    vw54: 'Install front/rear floor mats, check colour match. If monster mats are installed, please put factory mats in trunk (if applicable)',
    vw54A: 'Install all Accessories',
    vw55: 'Verify charge cable is included and operable',
    vw56: 'Technician PDI completed',
    vw57: 'Ensure all unnecessary stickers and all protective film/plastic. WARNING! Do not remove airbag warning triangle/warning labels',
    vw58: 'Ensure final detail was completed, including installation of front license plate bracket (if required or requested by customer)',
    vw58A:
      'Confirm prior to delivery if the customer would like front plate bracket installed',
    vw59: 'Confirm there are no open campaigns, recalls or required vehicle updates',
    vw60: 'Verify air bag warning triangle is affixed',
    vw61: 'Verify charge cable is included and operable',
    vw62: 'Plug vehicle into a charger to ensure HV Battery is 100% charged before delivering vehicle to customer - Level 2 or 3 recommended based on speed of charge',
    vw62A:
      'Ensure enough time is alloted for battery to reach 100% charge by the scheduled delivery appointment. Charge HV Battery to a maximum of 80% to minimize risk of premature aging of the battery if left unused for more than 12 hours',
    vw63: 'Check interior for cleanliness, grease marks and damage. Address all issues',
  };

  const detailerExterior = {
    vw64: 'Check vehicle exterior for damage, dings, dents, scratches and cleanliness (incl. wheels & windows). Address all issues.',
    vw65: 'Check tires for obvious damage or over/under inflation. Address all issues',
    vw66: 'Check for tire repair kit',
    vw67: 'Confirm if this customer will be or has purchased a Winter Wheel and Tire Package, and determine if this vehicle will be delivered with Winter Package installed prior to delivery (During winter months only)',
    vw68: 'Test function of all remote keys',
    vw69: 'Set multi-function display to correct language and units of measurement (C)',
    vw70: 'Set clock to correct time',
    vw71: 'Set memory for AM, FM stations with good reception',
    vw72: 'Activate SiriusXM satellite ratio trial; pre-set channels',
  };

  function generateDefaults(objToCopy) {
    const newObj = cloneDeep(objToCopy);
    Object.entries(newObj).map(([name]) => (newObj[name] = 'Yes'));
    return newObj;
  }

  const [initialInspectionValues, setInitialInspectionValues] = useState(
    generateDefaults(initialInspection),
  );
  const [technicalInspectionValues, setTechnicalInspectionValues] = useState(
    generateDefaults(technicalInspection),
  );
  const [interiorInspectionValues, setInteriorInspectionValues] = useState(
    generateDefaults(interiorInspection),
  );
  const [onHoistValues, setOnHoistValues] = useState(generateDefaults(onHoist));
  const [roadTestValues, setRoadTestValues] = useState(
    generateDefaults(roadTest),
  );
  const [afterRoadTestValues, setAfterRoadTestValues] = useState(
    generateDefaults(afterRoadTest),
  );
  const [vehicleUpdateValues, setVehicleUpdateValues] = useState(
    generateDefaults(vehicleUpdate),
  );
  const [preDeliveryValues, setPreDeliveryValues] = useState(
    generateDefaults(preDelivery),
  );
  const [detailerInteriorValues, setDetailerInteriorValues] = useState(
    generateDefaults(detailerInterior),
  );
  const [detailerExteriorValues, setDetailerExteriorValues] = useState(
    generateDefaults(detailerExterior),
  );

  const handler = setter => e => {
    const { name, value } = e.target;
    setter(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleInitialInspection = handler(setInitialInspectionValues);
  const handleTechnicalInspection = handler(setTechnicalInspectionValues);
  const handleInteriorInspection = handler(setInteriorInspectionValues);
  const handleOnHoist = handler(setOnHoistValues);
  const handleRoadTest = handler(setRoadTestValues);
  const handleAfterRoadTest = handler(setAfterRoadTestValues);
  const handleVehicleUpdate = handler(setVehicleUpdateValues);
  const handlePreDelivery = handler(setPreDeliveryValues);
  const handleDetailerInterior = handler(setDetailerInteriorValues);
  const handleDetailerExterior = handler(setDetailerExteriorValues);

  const [service_product_updates, setServiceProductUpdates] = useState(false);
  const handleServiceProductUpdates = () => {
    setServiceProductUpdates(
      service_product_updates => !service_product_updates,
    );
  };

  const [service_30_day, setService30Day] = useState(false);
  const handleService30Day = () => {
    setService30Day(service_30_day => !service_30_day);
  };

  const [detailer_product_updates, setDetailerProductUpdates] = useState(false);
  const handleDetailerProductUpdates = () => {
    setDetailerProductUpdates(
      detailer_product_updates => !detailer_product_updates,
    );
  };

  const [detailer_30_day, setDetailer30Day] = useState(false);
  const handleDetailer30Day = () => {
    setDetailer30Day(detailer_30_day => !detailer_30_day);
  };

  const [consent, setConsent] = useState(false);
  const handleConsent = () => {
    setConsent(consent => !consent);
  };

  const [confirm, setConfirm] = useState(false);
  const handleConfirm = () => {
    setConfirm(confirm => !confirm);
  };

  const wholeForm = useRef(null);

  const handleSubmit = e => {
    e.preventDefault();

    if (!wholeForm.current['amvic_number'].value) {
      enqueueSnackbar(`Please fill out 'Tech #'.`);
      return;
    }

    if (!consent) {
      enqueueSnackbar(`Please check 'I agree' and then submit the inspection!`);
      return;
    }

    const values = {
      ...initialInspectionValues,
      ...technicalInspectionValues,
      ...interiorInspectionValues,
      ...onHoistValues,
      ...roadTestValues,
      ...afterRoadTestValues,
      ...vehicleUpdateValues,
      ...preDeliveryValues,
      ...detailerInteriorValues,
      ...detailerExteriorValues,
    };
    const form_data = {
      vw: values,
      amvic_number: wholeForm.current['amvic_number'].value,
      odometer_before: wholeForm.current['odometer_before'].value,
      odometer_after: wholeForm.current['odometer_after'].value,
      service_product_updates: service_product_updates,
      service_30_day: service_30_day,
      technician_name: wholeForm.current['technician_name'].value,
      detailer_product_updates: detailer_product_updates,
      detailer_30_day: detailer_30_day,
      detailer_name: wholeForm.current['detailer_name'].value,
    };
    props.formData(form_data);
    props.formClose();
  };

  const GreenButton = withStyles({
    root: {
      color: '#fff',
      backgroundColor: '#198754',
      '&:hover': {
        backgroundColor: '#157347',
      },
    },
  })(Button);

  return (
    <form onSubmit={handleSubmit} ref={wholeForm}>
      <Card>
        <CardContent>
          <Typography variant="h6">
            <b>Technician Inspection</b>
          </Typography>
          <SuperAccordion
            title="Initial Inspection (upon receiving the vehicle)"
            fields={initialInspection}
            checkboxValues={initialInspectionValues}
            checkboxHandleFunc={handleInitialInspection}
          />
          <SuperAccordion
            title="Technical Initial Inspection (upon receiving the vehicle)"
            fields={technicalInspection}
            checkboxValues={technicalInspectionValues}
            checkboxHandleFunc={handleTechnicalInspection}
          />
          <SuperAccordion
            title="
                  Interior Inspection (make sure hands and clothing are clean
                  before entering the vehicle)
                "
            fields={interiorInspection}
            checkboxValues={interiorInspectionValues}
            checkboxHandleFunc={handleInteriorInspection}
          />
          <SuperAccordion
            title="On Hoist"
            fields={onHoist}
            checkboxValues={onHoistValues}
            checkboxHandleFunc={handleOnHoist}
          />
          <SuperAccordion
            title="Road Test"
            fields={roadTest}
            checkboxValues={roadTestValues}
            checkboxHandleFunc={handleRoadTest}
          />
          <SuperAccordion
            title="After Road Test"
            fields={afterRoadTest}
            checkboxValues={afterRoadTestValues}
            checkboxHandleFunc={handleAfterRoadTest}
          />
          <SuperAccordion
            title="Critical Vehicle Update"
            fields={vehicleUpdate}
            checkboxValues={vehicleUpdateValues}
            checkboxHandleFunc={handleVehicleUpdate}
          />
          <Box>
            <Typography style={{ marginTop: '15px' }}>
              I certify that all operations have been completed and that this
              vehicle has been prepared in accordance with Volkswagen procedures
              and quality standards.
            </Typography>
            <FormControlLabel
              control={<Checkbox />}
              label="All product updates have been checked"
              onChange={handleServiceProductUpdates}
              value={service_product_updates}
            />
            <FormControlLabel
              control={<Checkbox />}
              label="Dealer 30-day maintenance process has been initiated"
              onChange={handleService30Day}
              value={service_30_day}
            />
            <TextField
              label="Technician Name"
              fullWidth
              name="technician_name"
            />
          </Box>
        </CardContent>
      </Card>
      <Card style={{ marginTop: '20px' }}>
        <CardContent>
          <Typography variant="h6">
            <b>Detailer Inspection</b>
          </Typography>
          <SuperAccordion
            title="Pre-Delivery"
            fields={preDelivery}
            checkboxValues={preDeliveryValues}
            checkboxHandleFunc={handlePreDelivery}
          />
          <SuperAccordion
            title="Interior"
            fields={detailerInterior}
            checkboxValues={detailerInteriorValues}
            checkboxHandleFunc={handleDetailerInterior}
          />
          <SuperAccordion
            title="Exterior"
            fields={detailerExterior}
            checkboxValues={detailerExteriorValues}
            checkboxHandleFunc={handleDetailerExterior}
          />
          <Box>
            <Typography style={{ marginTop: '15px' }}>
              I certify that all operations have been completed and that this
              vehicle has been prepared in accordance with Volkswagen procedures
              and quality standards.
            </Typography>
            <FormControlLabel
              control={<Checkbox />}
              label="All product updates have been checked"
              onChange={handleDetailerProductUpdates}
              value={detailer_product_updates}
            />
            <FormControlLabel
              control={<Checkbox />}
              label="Dealer 30-day maintenance process has been initiated"
              onChange={handleDetailer30Day}
              value={detailer_30_day}
            />
            <TextField label="Detailer Name" fullWidth name="detailer_name" />
          </Box>
        </CardContent>
      </Card>

      <Typography style={{ marginTop: '15px' }}>
        The above listed vehicle has been inspected and all items marked as Pass
        or Re-inspection/Pass are in good mechanical condition. All repairs that
        were required to qualify for Re-inspection/Pass have been performed and
        meet this dealership’s satisfaction.
      </Typography>
      <FormControlLabel
        control={<Checkbox />}
        label="I agree"
        onChange={handleConsent}
        value={consent}
      />
      <br />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Technician Name"
            value={props.user.display_name}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Tech #"
            value={props.user.goUserProfile.profile.sales_licence}
            fullWidth
            name={'amvic_number'}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Date"
            value={new Date().toLocaleString()}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <Button
            type="button"
            variant="contained"
            style={{ marginRight: '15px' }}
            onClick={handleConfirm}
          >
            Cancel
          </Button>
          <Dialog
            open={confirm}
            onClose={handleConfirm}
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Current inspection will be closed and unsaved data will be gone.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleConfirm}>Cancel</Button>
              <Button onClick={props.formClose} color="secondary">
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
          <GreenButton type="submit" variant="contained">
            Save
          </GreenButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default VWPDIID4Form;
