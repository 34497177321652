import React from 'react';
import PropTypes from 'prop-types';

import GoHeader from 'components/GoHeader';

import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  goTemplate: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    margin: '0 1em',
    marginTop: '15vh',
  },
}));

const propTypes = {
  children: PropTypes.any.isRequired,
  showHeader: PropTypes.bool,
};

const defaultProps = {
  showHeader: true,
};

function GoTemplate({ children, showHeader }) {
  const classes = useStyles();
  return (
    <Box className={`${classes.goTemplate} ms-font-xl`}>
      {showHeader && <GoHeader />}
      {children}
    </Box>
  );
}

GoTemplate.propTypes = propTypes;
GoTemplate.defaultProps = defaultProps;

export default GoTemplate;
