import { useQuery, useSubscription } from '@apollo/react-hooks';
// External
import gql from 'graphql-tag';
import React, { useEffect, useRef, useState } from 'react';

// Material UI
import { Box } from '@mui/material';
import Alert from '@mui/material/Alert';
import makeStyles from '@mui/styles/makeStyles';

// Internal
import ErrorDisplay from 'components/MaterialUI/ErrorDisplay';
import Loading from 'components/MaterialUI/Loading';

import ConversationFooter from './ConversationFooter';
import ConversationHeaderDesktop from './ConversationHeaderDesktop';
import ConversationMessageItem from './ConversationMessageItem';

const CONVERSATION_MESSAGES_SUBSCRIPTION = gql`
  subscription onConversationMessagesUpdates($conversationId: Int!) {
    conversationFeed(conversation_id: $conversationId) {
      id
      direction
      date_sent
      sent_to
      sent_from
      media {
        content_type
        url
      }
      body
      operator_username
      operator_user {
        display_name
        username
      }
      conversation {
        id
        customer_name
        customer_phone
        operator_user {
          display_name
          username
        }
      }
    }
  }
`;

const CONVERSATION_MESSAGES_QUERY = gql`
  query conversationMessagesQuery($gatewayId: Int!, $conversationId: Int!) {
    sms {
      conversationMessages(
        gatewayId: $gatewayId
        conversationId: $conversationId
      ) {
        conversation {
          id
          customer {
            _id
            fullname
            identities {
              identity_type
              identity_value
            }
          }
          customer_name
          customer_phone
          customer_email
          operator_user {
            display_name
            username
          }
          do_not_contact
          skip_bulk_emails_until
          stars {
            id
            conversation_id
            username
          }
          archived
          has_unread_messages
          ...ConversationHeaderConversation
        }
        messages {
          id
          direction
          date_sent
          sent_to
          sent_from
          media {
            content_type
            url
          }
          body
          operator_username
          operator_user {
            display_name
            username
          }
          delivery_status
          error_msg
        }
      }
    }
  }
  ${ConversationHeaderDesktop.fragments.conversation}
`;

const useStyles = makeStyles(theme => ({
  appBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: '9',
  },
}));

const ConversationDesktop = ({
  conversationId,
  gatewayId,
  selectedChatId,
  setSelectedChatId,
  conversationQueryVariables,
}) => {
  const classes = useStyles();
  const endOfMessages = useRef();

  const [scrollOptions, setScrollOptions] = useState({});

  const { refetch, loading, data, error } = useQuery(
    CONVERSATION_MESSAGES_QUERY,
    {
      fetchPolicy: 'network-only',
      variables: {
        gatewayId,
        conversationId,
      },
    },
  );

  useSubscription(CONVERSATION_MESSAGES_SUBSCRIPTION, {
    variables: {
      conversationId,
    },
    fetchPolicy: 'network-only',
    shouldResubscribe: true,
    onSubscriptionData: () => refetch(), // hacky way of doing this for now.
  });

  const scrollToBottom = () => {
    return endOfMessages.current?.scrollIntoView(scrollOptions);
  };

  const conversationData = data?.sms.conversationMessages ?? {};

  const { conversation, messages = [] } = conversationData;
  const customer = conversation?.customer;

  const customerName = customer?.fullname || 'Unknown Customer';
  const customerPhone = conversation?.customer_phone;

  const desktopDisplay = true;

  useEffect(() => {
    if (!loading) {
      scrollToBottom();
      // Instant scroll for initial loading of messages,
      // but smooth scrolling for new/appended messages.
      setScrollOptions({ behavior: 'smooth' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationData]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <ErrorDisplay error={error} action="Loading Conversation Details" />;
  }

  // Need to reverse the order of the messages array from newest first to oldest first
  const conversationMessages = [...messages].reverse();

  return (
    <Box height="100%">
      {selectedChatId !== 0 && (
        <Box display="flex" flexDirection="column" height="100%">
          <Box boxShadow={3} style={{ zIndex: 99 }}>
            <Box
              className={classes.appBar}
              py="10px"
              px="20px"
              top="20px"
              bgcolor="primary.main"
            >
              <ConversationHeaderDesktop
                conversationQueryVariables={conversationQueryVariables}
                customerName={customerName}
                customerPhone={customerPhone}
                gatewayId={gatewayId}
                refetch={refetch}
                conversation={conversation}
                setSelectedChatId={setSelectedChatId}
              />
            </Box>
            <Alert
              icon={false}
              severity="info"
              style={{ justifyContent: 'center', padding: 0 }}
            >
              Operator:{' '}
              {conversation?.operator_user?.display_name || 'Unassigned'}
            </Alert>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            p="10px 30px"
            height="58vh"
            overflow="scroll"
            style={{ overflowX: 'hidden' }}
            flexGrow="1"
          >
            {conversationMessages.map(message => (
              <ConversationMessageItem
                message={message}
                key={message.id}
                desktopDisplay={desktopDisplay}
                customerName={customerName}
              />
            ))}
            <Box ref={endOfMessages}></Box>
          </Box>
          <Box className={classes.appBar} boxShadow={3} p={'20px'}>
            <ConversationFooter
              gatewayId={gatewayId}
              customerPhone={customerPhone}
              doNotContact={conversation?.do_not_contact}
              refetch={refetch}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ConversationDesktop;
