import React from 'react';

import gql from 'graphql-tag';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import { useMutation } from '@apollo/react-hooks';
import { useMediaQuery } from '@mui/material';

import DesktopAppointment from './DesktopAppointment';
import MobileAppointment from './MobileAppointment';

const UPDATE_APPOINTMENT = gql`
  mutation updateAppointment($id: ID!, $input: UpdateAppointmentInput!) {
    updateAppointment(_id: $id, input: $input) {
      _id
      attendees {
        username
        display_name
        profile {
          cell_phone
          desk_phone
          email_address
          first_name
          last_name
          image_id
        }
      }
      created_by_username
      customer_id
      dealer_id
      dealer {
        dealer_name
      }
      is_confirmed
      is_upcoming
      opportunity_id
      start_date
      status
      subject
      type_id
      type_name
      status_name
    }
  }
`;

const AppointmentBar = ({ customer }) => {
  const desktop = useMediaQuery(theme => theme.breakpoints.up('sm'));

  const [updateAppointment] = useMutation(UPDATE_APPOINTMENT, {
    onCompleted: data =>
      enqueueSnackbar(`Successfully updated appointment`, {
        variant: 'success',
      }),
    onError: e =>
      enqueueSnackbar(`Error in updating appointmnet`, {
        variant: 'error',
      }),
  });

  const { enqueueSnackbar } = useSnackbar();
  const updateStatus = (id, value) => {
    updateAppointment({
      variables: {
        id: id,
        input: {
          status: value,
        },
      },
    });
  };
  const appointments = customer.appointments
    .filter(appointment => appointment.is_upcoming)
    .sort((a, b) => moment(a.start_date).isSameOrAfter(b.start_date, 'day'));
  return (
    <>
      {desktop ? (
        <DesktopAppointment
          appointments={appointments}
          updateStatus={updateStatus}
        />
      ) : (
        <MobileAppointment
          appointments={appointments}
          updateStatus={updateStatus}
        />
      )}
    </>
  );
};

AppointmentBar.fragments = {
  customer: gql`
    fragment AppointmentBarFragment on Customer {
      appointments {
        is_upcoming
        start_date
      }
    }
  `,
};

export default AppointmentBar;
