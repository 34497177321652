import React, { useRef, useState } from 'react';

/* external */
import { useSnackbar } from 'notistack';
import { cloneDeep } from 'lodash';

/* Material UI */
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';

/* internal */
import InspectionCheckboxes from '../InspectionCheckboxes';

const CourtesyChecklistForm = props => {
  const { enqueueSnackbar } = useSnackbar();

  // form fields
  const sealant = {
    s1: 'Sealant condition on moldings',
    s2: 'Sealant condition on windows',
    s3: 'Sealant condition on compartment doors',
    s4: 'Sealant condition on entry doors',
  };
  const wall = {
    w1: 'Wall condition and delamination',
  };
  const entry = {
    e1: 'Entry door function',
    e2: 'Entry step function',
  };
  const roof = {
    r1: 'Roof structure and sealant',
  };
  const visual = {
    v1: 'Visual suspension check',
    v2: 'Tire condition, abnormal wear & tread depth (check spare)',
    v3: 'Running lights and brake draw',
    v4: 'Tongue jacks/landing gear',
    v5: 'Stab jacks',
  };
  const floor = {
    f1: 'Slideout function',
    f2: 'Floor areas (dust entry, moisture)',
  };

  let sealantObj = cloneDeep(sealant);
  let wallObj = cloneDeep(wall);
  let entryObj = cloneDeep(entry);
  let roofObj = cloneDeep(roof);
  let visualObj = cloneDeep(visual);
  let floorObj = cloneDeep(floor);

  Object.entries(sealantObj).map(([name]) => (sealantObj[name] = 'Pass'));
  Object.entries(wallObj).map(([name]) => (wallObj[name] = 'Pass'));
  Object.entries(entryObj).map(([name]) => (entryObj[name] = 'Pass'));
  Object.entries(roofObj).map(([name]) => (roofObj[name] = 'Pass'));
  Object.entries(visualObj).map(([name]) => (visualObj[name] = 'Pass'));
  Object.entries(floorObj).map(([name]) => (floorObj[name] = 'Pass'));

  const [sealantValues, setSealantValues] = useState(sealantObj);
  const [wallValues, setWallValues] = useState(wallObj);
  const [entryValues, setEntryValues] = useState(entryObj);
  const [roofValues, setRoofValues] = useState(roofObj);
  const [visualValues, setVisualValues] = useState(visualObj);
  const [floorValues, setFloorValues] = useState(floorObj);

  const handleSealant = e => {
    const { name, value } = e.target;
    setSealantValues(prevSealantValues => ({
      ...prevSealantValues,
      [name]: value,
    }));
  };
  const handleWall = e => {
    const { name, value } = e.target;
    setWallValues(prevWallValues => ({
      ...prevWallValues,
      [name]: value,
    }));
  };
  const handleEntry = e => {
    const { name, value } = e.target;
    setEntryValues(prevEntryValues => ({
      ...prevEntryValues,
      [name]: value,
    }));
  };
  const handleRoof = e => {
    const { name, value } = e.target;
    setRoofValues(prevRoofValues => ({
      ...prevRoofValues,
      [name]: value,
    }));
  };
  const handleVisual = e => {
    const { name, value } = e.target;
    setVisualValues(prevVisualValues => ({
      ...prevVisualValues,
      [name]: value,
    }));
  };
  const handleFloor = e => {
    const { name, value } = e.target;
    setFloorValues(prevFloorValues => ({
      ...prevFloorValues,
      [name]: value,
    }));
  };

  const [consent, setConsent] = useState(false);
  const handleConsent = () => {
    setConsent(consent => !consent);
  };

  const [confirm, setConfirm] = useState(false);
  const handleConfirm = () => {
    setConfirm(confirm => !confirm);
  };

  const wholeForm = useRef(null);

  const handleSubmit = e => {
    e.preventDefault();

    if (!consent) {
      enqueueSnackbar(`Please check 'I agree' and then submit the inspection!`);
      return;
    }

    Object.entries(sealantObj).map(
      ([name]) =>
        (sealantValues[name + '_hours'] =
          wholeForm.current[name + '_hours'].value),
    );
    Object.entries(wallObj).map(
      ([name]) =>
        (wallValues[name + '_hours'] =
          wholeForm.current[name + '_hours'].value),
    );
    Object.entries(entryObj).map(
      ([name]) =>
        (entryValues[name + '_hours'] =
          wholeForm.current[name + '_hours'].value),
    );
    Object.entries(roofObj).map(
      ([name]) =>
        (roofValues[name + '_hours'] =
          wholeForm.current[name + '_hours'].value),
    );
    Object.entries(visualObj).map(
      ([name]) =>
        (visualValues[name + '_hours'] =
          wholeForm.current[name + '_hours'].value),
    );
    Object.entries(floorObj).map(
      ([name]) =>
        (floorValues[name + '_hours'] =
          wholeForm.current[name + '_hours'].value),
    );

    const values = {
      ...sealantValues,
      ...wallValues,
      ...entryValues,
      ...roofValues,
      ...visualValues,
      ...floorValues,
    };
    const form_data = {
      courtesy: values,
      technician_comments: wholeForm.current['technician_comments'].value,
      amvic_number: wholeForm.current['amvic_number'].value,
    };

    props.formData(form_data);
    props.formClose();
  };

  const GreenButton = withStyles({
    root: {
      color: '#fff',
      backgroundColor: '#198754',
      '&:hover': {
        backgroundColor: '#157347',
      },
    },
  })(Button);

  return (
    <form onSubmit={handleSubmit} ref={wholeForm}>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}></AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(sealant).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6} style={{ display: 'flex' }}>
                    <InspectionCheckboxes
                      valueGroup={sealantValues}
                      handleGroup={handleSealant}
                      name={name}
                      orange={false}
                      greenValue="Pass"
                      redValue="Fail"
                    />
                    <TextField
                      label="Hours"
                      name={name + '_hours'}
                      fullWidth
                      style={{ marginLeft: '.5rem' }}
                      defaultValue="0"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}></AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(wall).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6} style={{ display: 'flex' }}>
                    <InspectionCheckboxes
                      valueGroup={wallValues}
                      handleGroup={handleWall}
                      name={name}
                      orange={false}
                      greenValue="Pass"
                      redValue="Fail"
                    />
                    <TextField
                      label="Hours"
                      name={name + '_hours'}
                      fullWidth
                      style={{ marginLeft: '.5rem' }}
                      defaultValue="0"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}></AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(entry).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6} style={{ display: 'flex' }}>
                    <InspectionCheckboxes
                      valueGroup={entryValues}
                      handleGroup={handleEntry}
                      name={name}
                      orange={false}
                      greenValue="Pass"
                      redValue="Fail"
                    />
                    <TextField
                      label="Hours"
                      name={name + '_hours'}
                      fullWidth
                      style={{ marginLeft: '.5rem' }}
                      defaultValue="0"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}></AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(roof).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6} style={{ display: 'flex' }}>
                    <InspectionCheckboxes
                      valueGroup={roofValues}
                      handleGroup={handleRoof}
                      name={name}
                      orange={false}
                      greenValue="Pass"
                      redValue="Fail"
                    />
                    <TextField
                      label="Hours"
                      name={name + '_hours'}
                      fullWidth
                      style={{ marginLeft: '.5rem' }}
                      defaultValue="0"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}></AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(visual).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6} style={{ display: 'flex' }}>
                    <InspectionCheckboxes
                      valueGroup={visualValues}
                      handleGroup={handleVisual}
                      name={name}
                      orange={false}
                      greenValue="Pass"
                      redValue="Fail"
                    />
                    <TextField
                      label="Hours"
                      name={name + '_hours'}
                      fullWidth
                      style={{ marginLeft: '.5rem' }}
                      defaultValue="0"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}></AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(floor).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6} style={{ display: 'flex' }}>
                    <InspectionCheckboxes
                      valueGroup={floorValues}
                      handleGroup={handleFloor}
                      name={name}
                      orange={false}
                      greenValue="Pass"
                      redValue="Fail"
                    />
                    <TextField
                      label="Hours"
                      name={name + '_hours'}
                      fullWidth
                      style={{ marginLeft: '.5rem' }}
                      defaultValue="0"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>

      <TextField
        label="Notes"
        multiline
        minRows={2}
        variant="outlined"
        name={'technician_comments'}
        fullWidth
        style={{ marginTop: '15px' }}
      />
      <Typography style={{ marginTop: '15px' }}>
        I verify the contents of this document are true and correct.
      </Typography>
      <FormControlLabel
        control={<Checkbox />}
        label="I agree"
        onChange={handleConsent}
        value={consent}
      />
      <br />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Technician Name"
            value={props.user.display_name}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Tech #"
            value={props.user.goUserProfile.profile.sales_licence}
            fullWidth
            name={'amvic_number'}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Date"
            value={new Date().toLocaleString()}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <Button
            type="button"
            variant="contained"
            style={{ marginRight: '15px' }}
            onClick={handleConfirm}
            color="default"
          >
            Cancel
          </Button>
          <Dialog
            open={confirm}
            onClose={handleConfirm}
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Current inspection will be closed and unsaved data will be gone.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleConfirm}>Cancel</Button>
              <Button onClick={props.formClose} color="secondary">
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
          <GreenButton type="submit" variant="contained">
            Save
          </GreenButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default CourtesyChecklistForm;
