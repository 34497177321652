import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import Hidden from '@mui/material/Hidden';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import { makeStyles } from '@mui/styles';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import HelpIcon from '@mui/icons-material/Help';

import { useCodeTablesContext } from 'components/MaterialUI/CodeTablesContext';
import SelectControl from 'components/MaterialUI/SelectControl2';
import TextFieldControl from 'components/MaterialUI/TextFieldControl';

import { usePermissionsContext } from '../../contexts/PermissionsContext';
import Controller from '../../contexts/PermissionsController';

export const EXT_COLOURS = gql`
  query decodeVehicleExteriorColours($style_id: Int!) {
    inventory {
      decodeVehicleExteriorColours(style_id: $style_id) {
        colour_code
        colour_name
        generic_colours
        rgb_value
      }
    }
  }
`;

const EngineDetails = ({ styleId }) => {
  const {
    codeTables: {
      driveTypes,
      engineCompressors,
      engineConfigs,
      exteriorColours,
      fuelTypes,
      interiorColours,
      transmissions,
      upholsteries,
    },
    loading: codeTablesLoading,
    error: codeTablesError,
  } = useCodeTablesContext();

  // Skip the styleId query if there is no styleId --MGRAPH-74
  const extColoursData = useQuery(EXT_COLOURS, {
    variables: {
      style_id: styleId,
    },
    skip: !styleId,
  });

  let oemColorOptions = [];
  if (extColoursData.data) {
    oemColorOptions =
      extColoursData.data.inventory.decodeVehicleExteriorColours;
  }

  let extendedExteriorColours = [];
  exteriorColours.forEach(({ id, name, rgb_value }) => {
    extendedExteriorColours.push({ id, name, desc: '', rgb_value });
    if (oemColorOptions.length > 0) {
      oemColorOptions
        .filter(option => option.generic_colours.includes(name))
        .forEach(({ colour_code, colour_name, generic_colours, rgb_value }) => {
          extendedExteriorColours.push({
            id: id + ' ' + colour_code,
            colour_code,
            name,
            desc: colour_name,
            rgb_value,
          });
        });
    }
  });

  const mobile = useMediaQuery(theme => theme.breakpoints.up('sm'));

  const useStyles = makeStyles(theme => ({
    span: {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      padding: '0 15px 15px 0',
    },
    mobileClass: {
      display: 'flex',
      width: `${mobile ? '40%' : '100%'}`,
      flexWrap: 'wrap',
    },
  }));

  const { update } = usePermissionsContext();
  const {
    formState: { errors },
    setValue,
    control,
  } = useFormContext();

  const handleChange = exteriorColourId => {
    // If exteriorColourId has a colour_code in it, we split the id and the
    // colour_code and set them on the vehicle.
    if (typeof exteriorColourId === 'string') {
      const exteriorValues = exteriorColourId.split(' ');
      setValue('exterior_colour_id', parseInt(exteriorValues[0]), {
        shouldDirty: true,
      });
      setValue('exterior_colour_option_code', exteriorValues[1], {
        shouldDirty: true,
      });

      // We map the colour_code to the colour_code that was selected and we
      // set the rgb_value on the vehicle accordingly.
      oemColorOptions.forEach(({ colour_code, colour_name, rgb_value }) => {
        if (colour_code === exteriorValues[1]) {
          setValue('exterior_colour_description', colour_name, {
            shouldDirty: true,
          });
          if (rgb_value)
            setValue('exterior_colour_rgb_value', rgb_value, {
              shouldDirty: true,
            });
        }
      });
    } else {
      // If the value that is selected is just one of our generic colour codes
      // we set the generic rgb_value on the vehicle and wipe out the description
      // and the colour code if it was previously set.
      setValue('exterior_colour_description', '', { shouldDirty: true });
      setValue('exterior_colour_option_code', '', { shouldDirty: true });
      extendedExteriorColours.forEach(({ id, rgb_value }) => {
        if (id === exteriorColourId) {
          setValue('exterior_colour_rgb_value', rgb_value, {
            shouldDirty: true,
          });
        }
      });
    }
  };

  const { span, mobileClass } = useStyles();

  if (codeTablesLoading) return <>Loading Code Tables...</>;
  if (codeTablesError) return <>Error{JSON.stringify(codeTablesError)}</>;

  return (
    <Paper>
      <Container maxWidth="xl">
        <Typography style={{ padding: '15px 15px 15px 0' }} variant="h6">
          Engine/Drive/Colourization
        </Typography>
        <span className={span}>
          <div style={{ display: 'flex' }}>
            <FormControl>
              <SelectControl
                control={control}
                displayEmpty
                error={errors.transmission_id}
                name="transmission_id"
                label="Transmission"
                style={{ width: '125px', marginRight: '20px' }}
                options={transmissions}
                optionValueKey="id"
                disabled={!update}
              />
            </FormControl>
            <FormControl>
              <SelectControl
                control={control}
                displayEmpty
                error={errors.drive_type_id}
                label="Drive"
                name="drive_type_id"
                style={{ width: '125px', marginRight: '20px' }}
                options={driveTypes}
                optionValueKey="id"
                disabled={!update}
              />
            </FormControl>
          </div>
          <Hidden smDown>
            <div className={mobileClass}>
              <FormControl>
                <InputLabel>Ext. Colour</InputLabel>
                <Controller
                  onClick={e => e.stopPropagation()}
                  render={({ field: { onChange, value, name, ...props } }) => {
                    return (
                      <Select
                        onChange={e => {
                          // ******This is so bad******
                          // But we need to tell the difference between 'Black'
                          // and 'Pearl Black', and since they have the same id
                          // from chrome, we have to add the colour_code to the
                          // id, and parse it out here to set the rgb/colour_desc
                          // accurately. So we reset the value in the
                          // event target to the id number only after we retrieve
                          // the colour code in order to set the desc/rgb_value etc.
                          handleChange(e.target.value);
                          let variable = Object.assign({}, e);
                          if (typeof e.target.value === 'string') {
                            variable.target = {
                              value: parseInt(e.target.value.split()[0]),
                              name: 'exterior_colour_id',
                            };
                          }
                          onChange(variable);
                        }}
                        value={value}
                        name={name}
                        style={{ width: '120px', marginRight: '20px' }}
                        label="Ext. Colour"
                        {...props}
                      >
                        <MenuItem value={null}>Unknown</MenuItem>
                        {extendedExteriorColours?.map(
                          ({ id, name, desc }, index) => (
                            <MenuItem value={id} key={index}>
                              {name} {desc && ` (${desc})`}
                            </MenuItem>
                          ),
                        )}
                      </Select>
                    );
                  }}
                  displayEmpty
                  onChange={([, data]) => data}
                  error={errors.exterior_colour_id}
                  name="exterior_colour_id"
                  control={control}
                ></Controller>
              </FormControl>

              <TextFieldControl
                control={control}
                defaultValue=""
                error={errors.exterior_colour_description}
                label="Ext. Colour Description"
                name="exterior_colour_description"
                style={{ width: `${mobile ? '175px' : '200px'}` }}
                disabled={!update}
              />
              {mobile && (
                <span
                  style={{
                    display: 'flex',
                    marginTop: 'auto',
                    marginBottom: '5px',
                    paddingLeft: '5px',
                  }}
                >
                  <Tooltip title={'CDK Field is Color'}>
                    <HelpIcon style={{ fontSize: '18px' }} />
                  </Tooltip>
                </span>
              )}
            </div>
          </Hidden>
        </span>
        <span className={span}>
          <div style={{ display: 'flex' }}>
            <FormControl>
              <SelectControl
                control={control}
                displayEmpty
                error={errors.engine_compressor_id}
                label="Compressor"
                name="engine_compressor_id"
                style={{ width: '125px', marginRight: '20px' }}
                optionNameKey="name"
                optionValueKey="id"
                options={engineCompressors}
                disabled={!update}
                nullDisplay="None"
              />
            </FormControl>
            <FormControl>
              <SelectControl
                control={control}
                displayEmpty
                error={errors.fuel_type_id}
                name="fuel_type_id"
                label="Fuel"
                style={{ width: '125px', marginRight: '20px' }}
                options={fuelTypes}
                optionValueKey="id"
                disabled={!update}
              />
            </FormControl>
          </div>
          <Hidden smDown>
            <div className={mobileClass}>
              <FormControl>
                <SelectControl
                  control={control}
                  displayEmpty
                  error={errors.interior_colour_id}
                  name="interior_colour_id"
                  label="Int. Colour"
                  style={{ width: '125px', marginRight: '20px' }}
                  options={interiorColours}
                  optionValueKey="id"
                  disabled={!update}
                />
              </FormControl>
              <TextFieldControl
                control={control}
                defaultValue=""
                error={errors.interior_colour_description}
                label="Int. Colour Description"
                name="interior_colour_description"
                style={{
                  width: `${mobile ? '175px' : '200px'}`,
                }}
                disabled={!update}
              />
            </div>
          </Hidden>
        </span>
        <span className={span}>
          <div style={{ display: 'flex' }}>
            <div>
              <FormControl>
                <SelectControl
                  control={control}
                  displayEmpty
                  error={errors.engine_config_id}
                  label="Engine"
                  name="engine_config_id"
                  style={{ width: '125px', marginRight: '20px' }}
                  options={engineConfigs}
                  disabled={!update}
                  optionValueKey="id"
                />
              </FormControl>
            </div>
            {/* defaultValue required to be "" or else label does not shrink. 
            https://github.com/react-hook-form/react-hook-form/issues/220 */}
            <FormControl>
              <TextFieldControl
                control={control}
                defaultValue=""
                error={errors.engine_cylinders}
                label="Cylinders"
                name="engine_cylinders"
                style={{ width: '45%', marginRight: '5%' }}
                type="number"
                disabled={!update}
              />
            </FormControl>
            <TextFieldControl
              control={control}
              defaultValue=""
              error={errors.engine_litres}
              label="Litres"
              name="engine_litres"
              style={{ width: '45%', marginRight: '5%' }}
              type="number"
              inputProps={{ step: '0.1' }}
              disabled={!update}
            />
          </div>
          <div style={{ display: 'flex', width: '40%' }}>
            <FormControl>
              <SelectControl
                control={control}
                displayEmpty
                error={errors.upholstery_id}
                name="upholstery_id"
                label="Upholstery"
                style={{ width: '120px', marginRight: '20px' }}
                options={upholsteries}
                disabled={!update}
                optionValueKey="id"
              />
            </FormControl>
          </div>
          {/* TODO: MAKE THIS MORE EFFICIENT - SHOULDNT NEED TO C/P THIS COMPONENT */}
          <Hidden smUp>
            <div className={mobileClass}>
              <FormControl>
                <InputLabel shrink>Int. Colour</InputLabel>
                <SelectControl
                  control={control}
                  displayEmpty
                  error={errors.interior_colour_id}
                  name="interior_colour_id"
                  style={{ width: '120px', marginRight: '20px' }}
                  options={interiorColours}
                  disabled={!update}
                  optionValueKey="id"
                />
              </FormControl>
              <TextFieldControl
                control={control}
                defaultValue=""
                error={errors.interior_colour_description}
                label="Int. Colour Description"
                name="interior_colour_description"
                style={{
                  width: `${mobile ? '175px' : '200px'}`,
                }}
                disabled={!update}
              />
            </div>
            <div className={mobileClass}>
              <FormControl>
                <SelectControl
                  control={control}
                  displayEmpty
                  error={errors.exterior_colour_id}
                  name="exterior_colour_id"
                  label="Ext. Colour"
                  style={{ width: '125px', marginRight: '20px' }}
                  options={exteriorColours}
                  disabled={!update}
                  optionValueKey="id"
                />
              </FormControl>

              <TextFieldControl
                control={control}
                defaultValue=""
                error={errors.exterior_colour_description}
                label="Ext. Colour Description"
                name="exterior_colour_description"
                style={{ width: `${mobile ? '175px' : '200px'}` }}
                disabled={!update}
              />

              <TextFieldControl
                control={control}
                defaultValue=""
                type="hidden"
                error={errors.exterior_colour_option_code}
                name="exterior_colour_option_code"
                disabled={control}
              />

              <TextFieldControl
                control={control}
                defaultValue=""
                type="hidden"
                error={errors.exterior_colour_rgb_value}
                name="exterior_colour_rgb_value"
                disabled={!update}
              />
            </div>
          </Hidden>
        </span>
      </Container>
    </Paper>
  );
};

EngineDetails.fragments = {
  vehicle: gql`
    fragment EngineDetailsVehicle on GreaseInventoryVehicle {
      drive_type_id
      engine_compressor_id
      engine_config_id
      engine_cylinders
      engine_litres
      exterior_colour_rgb_value
      exterior_colour_description
      exterior_colour_option_code
      exterior_colour_id
      fuel_type_id
      interior_colour_description
      interior_colour_id
      transmission_id
      upholstery_id
    }
  `,
};

export default EngineDetails;
