/* internal */
import { errorHandler } from '../utils';
import StepActions from './StepActions';
import { useMutation } from '@apollo/react-hooks';

/* Material UI */
import { Step, StepButton, StepContent } from '@mui/material';
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';
import TextFieldControl from 'components/MaterialUI/TextFieldControl';
import gql from 'graphql-tag';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';

/* external */
import { useForm } from 'react-hook-form';

const StepFive = ({
  appraisal,
  label,
  onNext,
  onBack,
  setActiveStep,
  setIsDirty,
  stepDirty,
  ...rest
}) => {
  const { id } = appraisal;
  const UPDATE_APPRAISAL = gql`
    mutation updateAppraisal($id: Int!, $data: UpdateAppraisalInput!) {
      appraisals {
        updateAppraisal(id: $id, data: $data) {
          id
          ...StepFiveAppraisal
        }
      }
    }
    ${StepFive.fragments.appraisal}
  `;
  const {
    control,
    formState: { isDirty },
    handleSubmit,
    reset,
    setError,
  } = useForm({
    defaultValues: appraisal,
    shouldUnregister: true,
  });

  const [updateAppraisal, { loading: updating }] =
    useMutation(UPDATE_APPRAISAL);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    reset(appraisal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appraisal]);

  useEffect(() => {
    if (isDirty && !stepDirty) setIsDirty();
    else {
      if (!isDirty && stepDirty) setIsDirty(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty, setIsDirty]);

  const onSubmit = ({ value, ...rest }) => {
    if (isDirty) {
      updateAppraisal({
        variables: { id, data: { value: parseInt(value, 10), ...rest } },
      }).then(
        () => onNext(),
        err => errorHandler(enqueueSnackbar, setError)(err),
      );
    } else onNext();
  };

  return (
    <Step key={label} {...rest}>
      <StepButton disabled={!appraisal.id} onClick={() => setActiveStep(4)}>
        {label}
      </StepButton>
      <StepContent>
        <LoadingBackdrop open={updating} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <TextFieldControl
              name="value"
              label="Appraised Value"
              control={control}
              variant="currency"
              rules={{
                required: 'Value is required',
                min: { value: 100, message: 'Must be at least $100' },
                max: { value: 1000000, message: 'Max value $1,000,000' },
              }}
            />
          </div>
          <div style={{ width: '100%', paddingTop: '0.5rem' }}>
            <TextFieldControl
              name="notes"
              label="Notes/Damage Disclosure"
              control={control}
              multiline
              minRows={4}
              style={{ width: '100%' }}
            />
          </div>
          <StepActions
            nextButtonProps={{ type: 'submit' }}
            nextLabel={isDirty ? 'Save' : 'Next'}
            onBack={onBack}
            onNext={() => {}}
          />
        </form>
      </StepContent>
    </Step>
  );
};

StepFive.fragments = {
  appraisal: gql`
    fragment StepFiveAppraisal on Appraisal {
      id
      notes
      value
    }
  `,
};
export default StepFive;
