import React from 'react';

/* external */
import { Switch, Route } from 'react-router-dom';

/* internal */
import AppraisalDetails from './AppraisalDetails';
import AppraisalsList from './AppraisalsList';
import Layout from 'modules/app/components/Layout';

const Appraisals = ({ match }) => (
  <Layout title="Appraisals">
    <Switch>
      <Route exact path={`${match.url}/`} component={AppraisalsList} />
      <Route
        exact
        path={`${match.url}/:id(\\d+)`}
        render={({ match: matched }) => (
          <AppraisalDetails id={parseInt(matched.params.id, 10)} />
        )}
      />
    </Switch>
  </Layout>
);

export default Appraisals;
