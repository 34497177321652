import React from 'react';

/* external */
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';

/* Material UI */
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

/* internal */
import Loading from 'components/MaterialUI/Loading';

const VOID_PACKAGE = gql`
  mutation voidPackage($id: ID!) {
    concierge {
      voidPackage(id: $id) {
        id
        status
        payments {
          id
          status
          amount
        }
      }
    }
  }
`;

const VoidPackageModal = ({ goldCard, isOpen, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [voidPackage, { loading }] = useMutation(VOID_PACKAGE, {
    onCompleted: data => {
      enqueueSnackbar('Package successfully voided.', { variant: 'success' });
      onClose();
    },
    onError: () => {
      enqueueSnackbar('Failed to void package.', { variant: 'error' });
    },
  });

  const handleVoid = () => {
    return voidPackage({ variables: { id: goldCard.id } });
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        onClose();
      }}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Void Package</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to void this package?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleVoid} variant="contained" color="secondary">
          OK
        </Button>
        <Button onClick={onClose} color="primary" variant="outlined">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default VoidPackageModal;
