import moment from 'moment-timezone';

export const format_seconds = seconds => {
  let unit = '';
  let value = 0;
  if (seconds >= 60 * 60 * 24) {
    unit = 'Day';
    value = Math.floor(seconds / (60 * 60 * 24));
  } else if (seconds >= 60 * 60) {
    unit = 'Hour';
    value = Math.floor(seconds / (60 * 60));
  } else if (seconds >= 60) {
    unit = 'Minute';
    value = Math.floor(seconds / 60);
  } else {
    unit = 'Second';
    value = seconds;
  }
  if (value > 1) {
    unit += 's';
  }
  return `${value} ${unit}`;
};

export const get_age = created_at => {
  const seconds = moment
    .duration(moment().diff(moment(created_at)))
    .asSeconds();
  if (seconds < 60 * 60 * 24) {
    return format_seconds(seconds);
  }
  return moment(created_at).format('MMM D, YYYY @ h:mma');
};
