/* external */
import { useMutation, useQuery } from '@apollo/react-hooks';

/* Material UI */
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material';
import Alert from '@mui/material/Alert';

/* internal */
import CheckboxControl from 'components/MaterialUI/CheckboxControl';
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';
import SelectControl from 'components/MaterialUI/SelectControl2';
import TextFieldControl from 'components/MaterialUI/TextFieldControl';
import { Role } from 'constants.js';
import gql from 'graphql-tag';
import { isEmpty } from 'lodash';
import { errorHandler } from 'modules/pitches/utils';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo } from 'react';
import { useForm, useWatch } from 'react-hook-form';

const MANAGERS_FOR_DEALER = gql`
  query UsersForDealer($dealerId: Int!) {
    users: users(
      dealer_ids: [$dealerId]
      status: active
      roles: ["${Role.SALES_MANAGER}", "${Role.GENERAL_MANAGER}"]
    ) {
      display_name
      username
      role
    }
  }
`;

const PUB_FRAGMENT = gql`
  fragment PitchPublishDialogPublishing on PitchPublishing {
    id
    createdByUser {
      display_name
    }
    customer1Email
    customer1Name
    customer1SMS
    customer2Email
    customer2Name
    customer2SigningEnabled
    customer2SMS
    dateExpires
    dateKioskExpires
    envelopeId
    isExpired
    isKioskExpired
    kioskId
    managerSignerUsername
    managerSignerUser {
      display_name
    }
  }
`;

const KIOSKS = gql`
  query kiosks($filters: [QueryFilter]) {
    pitches {
      kiosks(filters: $filters, pageSize: 50) {
        results {
          id
          currentPublishing {
            ...PitchPublishDialogPublishing
          }
          currentPublishingId
          dealerId
          kioskNumber
        }
      }
    }
  }
  ${PUB_FRAGMENT}
`;

const CREATE_PUBLISH = gql`
  mutation publishPitch($publishing: PitchPublishingInput!) {
    pitches {
      createPublishing(publishing: $publishing) {
        ...PitchPublishDialogPublishing
        kiosk {
          id
          dealer {
            id
            name
          }
          kioskNumber
        }
      }
    }
  }
  ${PUB_FRAGMENT}
`;

const GENERATE_CARFAX_PDF = gql`
  mutation generateCarfax($vehicleId: Int!) {
    inventory {
      generateCarfaxPDF(id: $vehicleId) {
        id
        carfax_pdf_url
      }
    }
  }
`;

const UPDATE_PUBLISH = gql`
  mutation updatePublishing(
    $publishingId: Int!
    $data: PitchPublishingUpdate!
  ) {
    pitches {
      updatePublishing(id: $publishingId, data: $data) {
        ...PitchPublishDialogPublishing
        kiosk {
          id
          dealer {
            id
            name
          }
          kioskNumber
        }
      }
    }
  }
  ${PUB_FRAGMENT}
`;

const PitchPublishDialog = ({
  customer,
  onClose,
  open,
  opportunity,
  pitch,
  refetchOpportunity,
  ...rest
}) => {
  const { sales_manager_usernames } = opportunity;
  const { currentPublishing, id, carfaxPdfUrl, inspectionUrl } = pitch;
  const { envelopeId } = currentPublishing ?? {};

  /* Hooks! */
  const { dealerId } = useDealerContext();
  const { enqueueSnackbar } = useSnackbar();

  const defaultValues = useMemo(
    () =>
      currentPublishing
        ? {
            ...currentPublishing,
            publishRemote: !currentPublishing?.isExpired,
          }
        : {
            kioskId: 0,
            publishRemote: true,
            customer1Name: customer.fullname,
            customer1Email: customer.identities?.find(
              x => x.identity_type === 'email',
            )?.identity_value,
            customer1SMS: customer.identities?.find(
              x => x.identity_type === 'sms',
            )?.identity_value,
            managerSignerUsername: sales_manager_usernames?.[0] ?? '',
          },
    [currentPublishing, customer, sales_manager_usernames],
  );

  const { clearErrors, control, handleSubmit, reset, setError, setValue } =
    useForm({
      defaultValues,
    });

  useEffect(
    // If the opportunity has changed, we probably need to reset the form.
    // This will allow us to pre-set the manager signer to the assigned sales manager
    () => reset(defaultValues),
    [customer, defaultValues, opportunity, reset],
  );

  const theme = useTheme();

  const kioskId = useWatch({ control, name: 'kioskId' });
  const customer2SigningEnabled = useWatch({
    control,
    name: 'customer2SigningEnabled',
  });

  const filters = [
    {
      model: 'Kiosk',
      field: 'dealerId',
      op: '==',
      value: dealerId,
    },
  ];
  const { data, loading } = useQuery(KIOSKS, {
    fetchPolicy: 'network-only',
    skip: !open,
    variables: { filters },
  });

  const managersQuery = useQuery(MANAGERS_FOR_DEALER, {
    fetchPolicy: 'network-only',
    skip: !open,
    variables: {
      dealerId,
    },
  });

  const [publishPitch, publishPitchMutation] = useMutation(CREATE_PUBLISH);
  const [updatePublish, updatePublishMutation] = useMutation(UPDATE_PUBLISH);
  const [, generateCarfaxPDFMutation] = useMutation(GENERATE_CARFAX_PDF);

  const kiosks =
    data?.pitches.kiosks.results.sort(
      (a, b) => a.kioskNumber - b.kioskNumber,
    ) ?? [];

  const inUse = ({ currentPublishingId }) => Boolean(currentPublishingId);

  useEffect(() => {
    if (kiosks.length > 0 && !kioskId && !currentPublishing) {
      const newKioskId = kiosks.find(k => !inUse(k))?.id || 0;
      if (kioskId !== newKioskId) setValue('kioskId', newKioskId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kiosks, setValue]);

  const processData = ({
    customer1Email,
    customer1Name,
    customer1SMS,
    customer2Email,
    customer2Name,
    customer2SMS,
    customer2SigningEnabled,
    kioskId,
    managerSignerUsername,
    publishRemote,
  }) => ({
    customer1Email: isEmpty(customer1Email) ? null : customer1Email, // blank -> null
    customer1Name,
    customer1SMS,
    customer2Email: customer2SigningEnabled ? customer2Email : undefined,
    customer2Name: customer2SigningEnabled ? customer2Name : undefined,
    customer2SMS: customer2SigningEnabled ? customer2SMS : undefined,
    customer2SigningEnabled,
    dateKioskExpires: kioskId === '' || kioskId === null ? null : undefined,
    dateExpires: publishRemote ? undefined : null,
    kioskId: kioskId === '' || kioskId === null ? undefined : kioskId,
    // Don't include kioskId in data if we're setting it to None - just set the dateKioskExpires
    managerSignerUsername,
  });

  //  A lot of time was spent trying to update the cache all fancy-like so
  // we didn't have to refetch the opportunity.  It ended up being too complicated, so
  // we'll just refetch the opportunity <GrumpySmurf>I HATE refetching!</GrumpySmurf>
  const onSubmitOk = () => {
    refetchOpportunity();
    onClose();
  };

  const onSubmit = data => {
    clearErrors();
    if (currentPublishing)
      updatePublish({
        variables: {
          publishingId: currentPublishing.id,
          data: processData(data),
        },
      }).then(onSubmitOk, errorHandler(enqueueSnackbar, setError));
    else
      publishPitch({
        variables: {
          publishing: { pitchId: id, ...processData(data) },
        },
      }).then(onSubmitOk, errorHandler(enqueueSnackbar, setError));
  };

  const managerSelections = managersQuery.data?.users.map(
    ({ username, display_name }) => ({
      name: display_name,
      value: username,
    }),
  );

  if (loading || managersQuery.loading)
    return (
      <LoadingBackdrop open={loading}>
        Checking for open kiosks....
      </LoadingBackdrop>
    );

  const kioskOptions = kiosks.map(
    ({ id, kioskNumber, currentPublishing, currentPublishingId }) => ({
      id,
      name: `Kiosk ${kioskNumber} ${
        currentPublishing
          ? currentPublishing?.createdByUser?.display_name ?? 'UNKNOWN'
          : ''
      }`,
      currentPublishingId,
    }),
  );

  return (
    <Dialog open={open} onClose={onClose} {...rest}>
      {!carfaxPdfUrl && (
        <Alert severity="warning">
          This pitch has no CarFax for the customer to sign.
        </Alert>
      )}
      {!inspectionUrl && (
        <Alert severity="warning">
          This pitch has no Inspection for the customer to sign.
        </Alert>
      )}
      <LoadingBackdrop
        open={publishPitchMutation.loading || updatePublishMutation.loading}
      >
        Saving...
      </LoadingBackdrop>
      <LoadingBackdrop open={generateCarfaxPDFMutation.loading}>
        Generating Carfax PDF...
      </LoadingBackdrop>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Publish Pitch</DialogTitle>
        {kiosks.length === 0 ? (
          <DialogContent>
            No kiosks are configured for this dealer.
          </DialogContent>
        ) : (
          <DialogContent>
            <TextFieldControl
              control={control}
              disabled={Boolean(envelopeId)}
              fullWidth
              label="Customer1 Name"
              name="customer1Name"
            />
            <TextFieldControl
              control={control}
              disabled={Boolean(envelopeId)}
              fullWidth
              label="Customer1 Email"
              name="customer1Email"
            />
            <TextFieldControl
              control={control}
              disabled={Boolean(envelopeId)}
              fullWidth
              label="Customer1 SMS"
              name="customer1SMS"
            />
            <CheckboxControl
              control={control}
              label="Enable Second Signer"
              name="customer2SigningEnabled"
            />

            {customer2SigningEnabled && (
              <>
                <TextFieldControl
                  control={control}
                  disabled={Boolean(envelopeId)}
                  fullWidth
                  label="Customer2 Name"
                  name="customer2Name"
                />
                <TextFieldControl
                  control={control}
                  disabled={Boolean(envelopeId)}
                  fullWidth
                  label="Customer2 Email"
                  name="customer2Email"
                />
                <TextFieldControl
                  control={control}
                  disabled={Boolean(envelopeId)}
                  fullWidth
                  label="Customer2 SMS"
                  name="customer2SMS"
                />
              </>
            )}

            <SelectControl
              control={control}
              disabled={Boolean(envelopeId)}
              fullWidth
              label="Sales Manager to Sign"
              name="managerSignerUsername"
              options={managerSelections}
              required
              noNull
            />

            <SelectControl
              control={control}
              fullWidth
              label="Kiosk"
              name="kioskId"
              nullDisplay="None"
              options={kioskOptions}
              optionValueKey="id"
              MenuItemProps={{
                disabled: ({ currentPublishingId }) =>
                  currentPublishingId &&
                  currentPublishingId !== pitch.currentPublishingId,
                // disable option if it's got a current publishing id.  Unless it's this one.
              }}
            />

            <CheckboxControl
              control={control}
              name="publishRemote"
              label="Remotely Accessible"
            />
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          {kiosks.length > 0 && (
            <Button
              style={theme.actions.confirm}
              type="submit"
              variant="contained"
            >
              {currentPublishing ? 'Update' : 'Publish'}
            </Button>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};

PitchPublishDialog.fragments = {
  pitch: gql`
    fragment PitchPublishDialogPitch on Pitch {
      carfaxPdfUrl
      currentPublishingId
      currentPublishing {
        ...PitchPublishDialogPublishing
      }
      inspectionUrl
    }
    ${PUB_FRAGMENT}
  `,
};

export default PitchPublishDialog;
