import PaymentOptionsTable from './PaymentOptionsTable';
import PitchPublishDialog from './PitchPublishDialog';
import VehicleBoxDetails from './VehicleBoxDetails';
import VehicleTitle from './VehicleTitle';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';

/* Material UI */
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import LinkIcon from '@mui/icons-material/Link';
import Alert from '@mui/material/Alert';
import ConfirmDialog from 'components/MaterialUI/ConfirmDialog';
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';
import gql from 'graphql-tag';
import { formatPhoto } from 'modules/inventory/utils';

/* internal */
import { PUBLISHING_STATUS_TEXT } from 'modules/pitches/const';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';

/* external */
import { sortBy } from 'underscore';
import OptionalPitchSheetDialog from './OptionalPitchSheetDialog';

const GENERATE_CARFAX_PDF = gql`
  mutation generateCarfaxPDF($pitchId: Int!) {
    pitches {
      generateCarfaxPDF(id: $pitchId) {
        id
        carfaxPdfUrl
      }
    }
  }
`;

const GENERATE_PDF = gql`
  mutation generatePDF($pitchId: Int!, $pitchSheetDealerId: Int) {
    pitches {
      generatePDF(id: $pitchId, pitchSheetDealerId: $pitchSheetDealerId) {
        id
        pdfUrl
      }
    }
  }
`;

const SYNC_TO_DMS_DEAL = gql`
  mutation syncToDMSDeal($pitchId: Int!) {
    pitches {
      syncToDMSDeal(id: $pitchId) {
        id
        dealNumber
      }
    }
  }
`;

const PUBLISHING_URL = gql`
  query publishingURL($id: Int!) {
    pitches {
      publishingURL(id: $id) {
        url
      }
    }
  }
`;

const DEALER_SETTINGS_QUERY = gql`
  query dealerSettingsQuery($dealerId: Int!) {
    pitches {
      dealerSettings(dealerId: $dealerId) {
        id
        dealer {
          id
          name
        }
        optionalPitchSheets {
          id
          allowNewVehicles
          allowUsedVehicles
          pitchSheetDealerId
          pitchSheetDealer {
            name
          }
        }
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  mainBox: {
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      padding: theme.spacing(1),
    },
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(1),
    },
    border: '1px solid #C8C8C8',
  },
  paymentOptionsBox: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0.5),
    },
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2),
    },
  },
  paymentOptionsTitle: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
      fontWeight: 'bold',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '20px',
      marginBottom: theme.spacing(1),
    },
  },
}));

const VehicleBox = ({
  customer,
  onEditPitch,
  opportunity,
  pitch,
  refetchOpportunity,
  vehicle,
  ...rest
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { enqueueSnackbar } = useSnackbar();
  const { inventoryVehicle } = vehicle;
  const { photos } = inventoryVehicle ?? {};
  const {
    id: pitchId,
    carfaxPdfUrl,
    currentPublishingId,
    dealerId,
    isImport,
    pdfUrl,
    publishings,
  } = pitch;

  const {
    primary_new_pitch_id,
    sales_manager_usernames = [],
    deal_type,
  } = opportunity;

  const isPrimaryPitch =
    primary_new_pitch_id && primary_new_pitch_id === pitchId;

  const { loading: settingsLoading, data: settingsData } = useQuery(
    DEALER_SETTINGS_QUERY,
    { variables: { dealerId } },
  );

  const pitchSheets =
    settingsData?.pitches?.dealerSettings?.optionalPitchSheets ?? [];
  const optionalPitchSheets = pitchSheets.filter(
    sheet =>
      (deal_type === 'Used' && sheet.allowUsedVehicles) ||
      (deal_type === 'New' && sheet.allowNewVehicles),
  );

  const [generateCarfaxPDF, generateCarfaxPDFMutation] =
    useMutation(GENERATE_CARFAX_PDF);
  const [generatePDF, generatePDFMutation] = useMutation(GENERATE_PDF);
  const [syncToDMSDeal, { loading: syncing }] = useMutation(SYNC_TO_DMS_DEAL);
  const [getPublishUrl] = useLazyQuery(PUBLISHING_URL, {
    onCompleted: data => {
      navigator.clipboard.writeText(data.pitches.publishingURL.url);
      enqueueSnackbar('Copied link to clipboard');
    },
    onError: err => enqueueSnackbar('Error generating url'),
  });
  const [selectPitchSheetDialog, setSelectPitchSheetDialog] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showPublishDialog, setShowPublishDialog] = useState(false);

  const displayPhoto = photos?.[0];

  const handleClickFavourite = () => setShowConfirmDialog(true);

  const handleClickLink = () =>
    getPublishUrl({
      variables: { id: currentPublishingId },
    });

  const handleClickShare = () =>
    sales_manager_usernames.length === 0
      ? alert(
          'There must be a sales manager assigned to the opportunity in order to share the pitch.',
        )
      : setShowPublishDialog(true);

  const handleFavouritePitch = () => {
    syncToDMSDeal({ variables: { pitchId } }).then(refetchOpportunity, error =>
      enqueueSnackbar(
        `Error syncing pitch to DMS: ${
          error?.graphQLErrors?.[0]?.extensions?.response?.body?.message ??
          error?.message ??
          'Unknown error'
        }`,
        {
          variant: 'error',
        },
      ),
    );
  };

  const getErrorMessage = ({ graphQLErrors, networkError }) =>
    graphQLErrors?.[0]?.extensions?.response?.body?.message ??
    graphQLErrors?.[0]?.message ??
    networkError ??
    'Unknown error';

  const onGenerateCarfaxPDF = pitchId =>
    generateCarfaxPDF({ variables: { pitchId } }).then(
      () => enqueueSnackbar(`Generated Carfax PDF OK`, { variant: 'success' }),
      error =>
        enqueueSnackbar(
          `Error generating Carfax PDF: ${getErrorMessage(error)}`,
          {
            variant: 'error',
          },
        ),
    );

  const onGeneratePDF = pitchId =>
    generatePDF({ variables: { pitchId } }).then(
      ({ data }) => window.open(data.pitches.generatePDF.pdfUrl, '_blank'),
      error =>
        enqueueSnackbar(`Error generating PDF: ${getErrorMessage(error)}`, {
          variant: 'error',
        }),
    );

  const onGenerateOptionalPDF = (pitchId, optionalId = null) =>
    generatePDF({
      variables: { pitchId, pitchSheetDealerId: optionalId },
    }).then(
      ({ data }) => window.open(data.pitches.generatePDF.pdfUrl, '_blank'),
      error =>
        enqueueSnackbar(`Error generating PDF: ${getErrorMessage(error)}`, {
          variant: 'error',
        }),
    );

  const handleGeneratePDF = () => {
    if (optionalPitchSheets.length > 0) {
      setSelectPitchSheetDialog(true);
    } else {
      onGeneratePDF(pitchId);
    }
  };

  const latestPublishing = sortBy(publishings, x => -x.id)?.[0] ?? null; //descending by id

  const activeKiosk =
    latestPublishing && !latestPublishing.isKioskExpired
      ? latestPublishing.kiosk
      : null;

  const isSharedRemote = latestPublishing && !latestPublishing.isExpired;

  const status = latestPublishing?.status;

  return (
    <Box className={classes.mainBox} {...rest}>
      {isImport && (
        <Alert severity="warning">
          Imported from desking. Some information may not be editable.
        </Alert>
      )}

      {(isSharedRemote || activeKiosk) && (
        <Alert severity="warning">
          {activeKiosk && (
            <Box>
              Shared with Kiosk{' '}
              {`${activeKiosk.dealer?.name} #${activeKiosk.kioskNumber}`}{' '}
            </Box>
          )}
          {isSharedRemote && (
            <Box>
              Shared via remote link{' '}
              <IconButton onClick={handleClickLink} size="large">
                <LinkIcon />
              </IconButton>
            </Box>
          )}
        </Alert>
      )}
      {latestPublishing?.isRejected && (
        <Alert severity="info">Customer declined</Alert>
      )}
      {(latestPublishing?.isAccepted || latestPublishing?.isStarted) && (
        <Alert severity="success">{PUBLISHING_STATUS_TEXT[status]}</Alert>
      )}
      <LoadingBackdrop open={settingsLoading}>Loading...</LoadingBackdrop>
      <LoadingBackdrop open={syncing}>Creating Deal in CDK</LoadingBackdrop>
      <LoadingBackdrop open={generatePDFMutation.loading}>
        Generating PDF
      </LoadingBackdrop>
      <LoadingBackdrop open={generateCarfaxPDFMutation.loading}>
        Generating Carfax PDF
      </LoadingBackdrop>
      <ConfirmDialog
        abortButtonProps={{ style: theme.actions.cancel }}
        confirmButtonProps={{ style: theme.actions.confirm }}
        abortText="Cancel"
        confirmText="Favourite"
        content={
          <>
            This will:
            <ul>
              <li>Create or update a deal in CDK</li>
              <li>Set the primary pitch to this one</li>
              <li>Advance the opportunity status to "Sent to Desk"</li>
            </ul>
          </>
        }
        isOpen={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
        onConfirm={handleFavouritePitch}
        text=""
        titleText="Set pitch as favourite?"
      />
      <OptionalPitchSheetDialog
        open={selectPitchSheetDialog}
        optionalPitchSheets={optionalPitchSheets}
        generateOptionalPDF={onGenerateOptionalPDF}
        generatePDF={onGeneratePDF}
        pitchId={pitchId}
        onClose={() => setSelectPitchSheetDialog(false)}
      />
      <PitchPublishDialog
        customer={customer}
        onClose={() => setShowPublishDialog(false)}
        open={showPublishDialog}
        opportunity={opportunity}
        pitch={pitch}
        fullWidth
        maxWidth="sm"
        refetchOpportunity={refetchOpportunity}
      />

      <Grid container>
        {!mobile && (
          <Grid item xs={12} md={2}>
            <img
              alt="Vehicle"
              style={{ width: '150px', objectFit: 'cover' }}
              src={formatPhoto(displayPhoto)}
            />
          </Grid>
        )}
        <Grid container item xs={12} md={10}>
          <VehicleTitle
            primaryPitchID={primary_new_pitch_id}
            isPrimaryPitch={isPrimaryPitch}
            onFavourite={handleClickFavourite}
            onShare={handleClickShare}
            onEditPitch={() => onEditPitch(pitchId)}
            onGenerateCarfaxPDF={() => onGenerateCarfaxPDF(pitchId)}
            onGeneratePDF={() => handleGeneratePDF()}
            vehicle={vehicle}
            pdfUrl={pdfUrl}
            carfaxPdfUrl={carfaxPdfUrl}
          />
          {mobile && (
            <Grid item xs={2}>
              <img
                alt="Vehicle"
                style={{ width: '100%', objectFit: 'cover' }}
                src={formatPhoto(displayPhoto, 150)}
              />
            </Grid>
          )}
          <Grid item xs={10} sm={12}>
            <VehicleBoxDetails vehicle={vehicle} pitch={pitch} />
          </Grid>
        </Grid>
        {vehicle.paymentOptions.length > 0 && (
          <Grid item xs={12}>
            <Box padding={2}>
              <Box className={classes.paymentOptionsTitle}>Payment Options</Box>
              <PaymentOptionsTable vehicle={vehicle} />
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
VehicleBox.fragments = {
  pitch: gql`
    fragment VehicleBoxPitch on Pitch {
      id
      appraisals {
        id
        value
      }
      carfaxPdfUrl
      currentPublishingId
      isImport
      dealerId
      dealNumber
      pdfUrl
      publishings {
        id
        areDocumentsPrepared
        arePaymentOptionsInitialed
        customer2SigningEnabled
        dateAccepted
        dateRejected
        isAccepted
        isExpired
        isKioskExpired
        isRejected
        isSigned
        isSignedByManager
        isSignedBySecondSigner
        isStarted
        kiosk {
          id
          kioskNumber
          dealer {
            id
            name
          }
        }
        rejectionReason
        status
      }
      ...PitchPublishDialogPitch
    }
    ${PitchPublishDialog.fragments.pitch}
  `,

  vehicle: gql`
    fragment VehicleBoxVehicle on PitchVehicle {
      id
      accessories {
        id
        title
        price
      }
      discounts {
        id
        title
        price
      }
      inventoryVehicle {
        id
        stock_number
        days_in_stock
        msrp
        odometer
        regular_price
        stock_type
        photos {
          id
          cloudinary_public_id
        }
      }
      odometer
      paymentOptions {
        id
        ...PaymentOptionsTablePaymentOption
      }
      salePrice
      type
      vin
      ...VehicleTitleVehicle
    }
    ${VehicleTitle.fragments.vehicle}
    ${PaymentOptionsTable.fragments.paymentOption}
  `,
};

export default VehicleBox;
