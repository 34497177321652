import gql from 'graphql-tag';
import React, { useState } from 'react';

import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Paper,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material';
import {
  Cancel as CancelIcon,
  Edit as EditIcon,
  Message as MessageIcon,
} from '@mui/icons-material';

import { formatPhoneNumber } from 'utils';
import { useSnackMutation } from 'utils/useSnackMutation';

import {
  ROStatus,
  LogType,
  LogRefType,
  cadfmt,
  LogStatus,
  TransactionStatus,
} from './constants';
import { LogBox } from './LogBox';
import { EditAmountDialog } from './EditAmountDialog';

const useStyles = makeStyles({
  largeText: { padding: '1rem', fontSize: '18px' },
  sendPaymentGrid: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginLeft: 'auto',
    marginRight: 0,
  },
  sendPaymentMessageAndButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginLeft: 'auto',
    marginRight: 0,
    marginTop: 'auto',
    marginBottom: 'auto',
    minWidth: '120px',
  },
  vehicleAndServiceAdvisorGrid: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

const SEND_PAYMENT_REQUEST = gql`
  mutation PaymentURL($dealer_id: Int!, $ro_number: String!, $amount: String!) {
    rotracker {
      sendPaymentUrl(
        dealer_id: $dealer_id
        ro_number: $ro_number
        amount: $amount
      ) {
        id
        last_transaction_status
        last_transaction_settling_status
        last_transaction {
          id
          amount
          created_at
          successful_sms
        }
      }
    }
  }
`;

const CANCEL_PAYMENT_REQUEST = gql`
  mutation updateTransaction(
    $transaction_id: ID!
    $update: TransactionUpdate!
  ) {
    rotracker {
      updateTransaction(transaction_id: $transaction_id, update: $update) {
        id
        share_key
        status
      }
    }
  }
`;

const RODetailsLegacyPayments = ({ ro, isDesktop, refetch }) => {
  const classes = useStyles();
  const customer_phone = ro.sms_contact_number || ro.customer_phone;
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [customerPayManual, setCustomerPayManual] = useState(ro.customer_pay);

  const [sendPaymentRequest, { loading: isSending }] = useSnackMutation(
    SEND_PAYMENT_REQUEST,
    'Payment request SMS sent!',
    'Error sending SMS, please try again later or contact support.',
    _ => refetch(),
    _ => refetch(),
  );
  const [cancelPaymentRequest] = useSnackMutation(
    CANCEL_PAYMENT_REQUEST,
    'Payment request cancelled!',
    'Error cancelling payment request, please try again later or contact support.',
    _ => refetch(),
    _ => refetch(),
  );

  function handleSubmit(amount) {
    setCustomerPayManual(amount);
  }

  const smsFailed =
    ro?.last_transaction_status === TransactionStatus.PENDING &&
    !ro?.last_transaction.successful_sms;

  const transactionFailed =
    ro?.last_transaction_status === TransactionStatus.ERROR ||
    ro?.last_transaction_status === TransactionStatus.FAILURE;

  const paymentPending =
    ro?.last_transaction_status === TransactionStatus.PENDING ||
    (ro?.last_transaction_status === TransactionStatus.SUCCESS &&
      ro?.last_transaction_settling_status === 'PENDING');

  const showSMSButton =
    ro.status === ROStatus.PRE_INVOICED &&
    customer_phone &&
    (ro?.last_transaction_status === null ||
      ro?.last_transaction_status === TransactionStatus.CANCELLED ||
      transactionFailed ||
      smsFailed);

  const showCancelPaymentButton =
    ro?.last_transaction_status === TransactionStatus.PENDING &&
    !ro?.last_transaction?.payment_form_opened;

  const logEntries = [];
  if (ro.last_transaction) {
    if (ro.last_transaction_status !== TransactionStatus.CANCELLED) {
      logEntries.push(
        Object({
          id: ro.last_transaction.id + 'p',
          created_at: ro.last_transaction.created_at,
          type: LogType.PAYMENT,
          ref_type: LogRefType.GLOBAL_PAYMENTS,
          status: paymentPending
            ? LogStatus.PENDING
            : transactionFailed
            ? LogStatus.FAILED
            : LogStatus.SUCCESS,
          cents: -ro.last_transaction.amount,
        }),
      );
    }

    logEntries.push(
      Object({
        id: ro.last_transaction.id + 's',
        created_at: ro.last_transaction.created_at,
        type: LogType.SMS,
        status: smsFailed ? LogStatus.FAILED : LogStatus.SUCCESS,
      }),
    );
  }

  return (
    <Paper>
      <Container maxWidth="xl">
        legacy
        <Grid
          container
          direction={isDesktop ? 'row' : 'column'}
          className={classes.vehicleAndServiceAdvisorGrid}
          xs={12}
          item={true}
        >
          <Grid item className={classes.largeText} xs={6}>
            <Box>
              <b>Vehicle: </b>
              {`${ro.vehicle_year} ${ro.vehicle_make} ${ro.vehicle_model}`}
            </Box>
            <Box>
              <b>VIN: </b>
              {ro.vin}
            </Box>
          </Grid>
          <Grid item className={classes.largeText} xs={6}>
            <b>Service Advisor: </b>
            {`${ro.serviceAdvisor?.display_name ?? 'N/A'}`}
          </Grid>
        </Grid>
        <Grid
          container
          direction={isDesktop ? 'row' : 'column'}
          style={{
            display: 'flex',
            flexDirection: `${isDesktop ? 'row' : 'column'}`,
          }}
          xs={12}
          item={true}
        >
          <Grid item style={{ padding: '1rem' }} xs={12} md={4}>
            <Box>
              <b>Customer Pay Total: </b>
              {cadfmt.format(customerPayManual)}{' '}
              <Button
                disabled={!showSMSButton}
                size="small"
                variant="outlined"
                startIcon={<EditIcon />}
                onClick={() => setOpen(true)}
              >
                Edit
              </Button>
            </Box>
            <Box>
              <b>Warranty Pay Total: </b>
              {cadfmt.format(ro.warranty_pay)}
            </Box>
            <Box>
              <b>Internal Pay Total: </b>
              {cadfmt.format(ro.internal_pay)}
            </Box>
          </Grid>
          <Grid item style={{ padding: '1rem' }} xs={12} md={4}>
            <Grid container direction="column">
              <Grid item xs={12} className={classes.sendPaymentGrid}>
                <Box className={classes.sendPaymentMessageAndButton}>
                  {showSMSButton && (
                    <Button
                      variant="contained"
                      style={theme.actions.confirm}
                      startIcon={
                        isSending ? <CircularProgress /> : <MessageIcon />
                      }
                      disabled={isSending}
                      onClick={() =>
                        sendPaymentRequest({
                          variables: {
                            ro_number: ro.ro_number,
                            dealer_id: ro.dealer_id,
                            amount: customerPayManual.toString(),
                          },
                        })
                      }
                    >
                      {isSending ? (
                        <>Sending SMS&hellip;</>
                      ) : (
                        <>
                          Send Payment Request to Customer at{' '}
                          {formatPhoneNumber(customer_phone) || customer_phone}
                        </>
                      )}
                    </Button>
                  )}
                  {showCancelPaymentButton && (
                    <Button
                      variant="contained"
                      style={theme.actions.cancel}
                      startIcon={<CancelIcon />}
                      disabled={isSending}
                      onClick={() =>
                        /* Cancelling a payment:
                         * - set transaction status to 'cancelled'
                         * - append '-cancel' to the share_key so it's invalid
                         */
                        cancelPaymentRequest({
                          variables: {
                            transaction_id: ro.last_transaction.id,
                            update: {
                              share_key:
                                ro.last_transaction.share_key + '-cancel',
                              status: TransactionStatus.CANCELLED,
                            },
                          },
                        })
                      }
                    >
                      Cancel Payment Request
                    </Button>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <LogBox
          entries={logEntries}
          isPaymentRequestCancelled={
            ro?.last_transaction_status === TransactionStatus.CANCELLED
          }
          hasPendingPayments={paymentPending}
        />
      </Container>
      <EditAmountDialog
        open={open}
        handleClose={() => setOpen(false)}
        handleSubmit={handleSubmit}
        amount={ro.customer_pay}
        maxAmount={ro.customer_pay}
      />
    </Paper>
  );
};

export default RODetailsLegacyPayments;
