import React, { useState } from 'react';

import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';

const useStyles = makeStyles(theme => ({
  searchWidth: {
    width: '100%',
  },
}));

const TasksSearch = ({ updateFilter, keywords }) => {
  const classes = useStyles();
  const [searchKeywords, setSearchKeywords] = useState(keywords);

  const handleChange = event => {
    setSearchKeywords(event.target.value);
  };

  const handleSearch = event => {
    if (event.key === 'Enter') {
      updateFilter(searchKeywords);
    }
  };

  return (
    <Box display="flex">
      <TextField
        placeholder="Search Tasks..."
        className={classes.searchWidth}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                disabled={!searchKeywords}
                onClick={() => {
                  setSearchKeywords('');
                  updateFilter('');
                }}
                size="large"
              >
                <CloseIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        value={searchKeywords || ''}
        onChange={handleChange}
        style={{ paddingRight: '5px' }}
        onKeyPress={handleSearch}
      />
    </Box>
  );
};

export default TasksSearch;
