import React from 'react';

import { parseValuation } from './utils';
import ValuationDesktop from './ValuationDesktop';
import ValuationMobile from './ValuationMobile';
import ValuationMobileSummary from './ValuationMobileSummary';

const ValuationResults = ({
  valuation,
  cbbData,
  summary = false,
  mobile = false,
}) => {
  const { condition } = cbbData;

  const dataProps = {
    condition,
    ...parseValuation(valuation, cbbData.equipment),
  };

  if (summary) return mobile ? <ValuationMobileSummary {...dataProps} /> : null;
  else
    return mobile ? (
      <ValuationMobile {...dataProps} />
    ) : (
      <ValuationDesktop {...dataProps} />
    );
};

export default ValuationResults;
