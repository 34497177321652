import React, { useEffect } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';

import app from '../../app';
import CustomerDetails from './CustomerDetails';
import CustomerSearch from './CustomerSearch';
import CustomerFlexSearch from './CustomerFlexSearch';
import CustomerMerge from './CustomerMerge/CustomerMerge';

const Customers = ({ match }) => {
  const { pathname, hash } = useLocation();

  // check for an anchor point and ensure its in view
  useEffect(() => {
    if (hash === '') {
      window.scrollTo(0, 0);
    } else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <Switch>
      <Route
        exact
        path={`${match.url}/:customer_id/details`}
        render={({ match }) => (
          <app.components.Layout title="Customers">
            <CustomerDetails customer_id={match.params.customer_id} />
          </app.components.Layout>
        )}
      />
      <Route
        exact
        path={`${match.url}/search`}
        render={() => (
          <app.components.Layout title="Customer Search">
            <CustomerSearch />
          </app.components.Layout>
        )}
      />
      <Route
        exact
        path={`${match.url}/merge/:ids`}
        render={({ match }) => (
          <app.components.Layout title="Customer Merge">
            <CustomerMerge ids={match.params.ids} />
          </app.components.Layout>
        )}
      />
      <Route
        exact
        path={`${match.url}/flex-search`}
        render={() => (
          <app.components.Layout title="Customer Flex Search">
            <CustomerFlexSearch />
          </app.components.Layout>
        )}
      />
      <Route
        exact
        path={`${match.url}/:customer_id/details/notes`}
        render={({ match }) => (
          <app.components.Layout title="Customers">
            <CustomerDetails
              customer_id={match.params.customer_id}
              openModal={{ tab: 1 }}
            />
          </app.components.Layout>
        )}
      />
      <Route
        exact
        path={`${match.url}/:customer_id/details/notes/add`}
        render={({ match }) => (
          <app.components.Layout title="Customers">
            <CustomerDetails
              customer_id={match.params.customer_id}
              openModal={{ tab: 1, modal: 'notes' }}
            />
          </app.components.Layout>
        )}
      />
      <Route
        exact
        path={`${match.url}/:customer_id/details/opportunities`}
        render={({ match }) => (
          <app.components.Layout title="Customers">
            <CustomerDetails
              customer_id={match.params.customer_id}
              openModal={{ tab: 2 }}
            />
          </app.components.Layout>
        )}
      />
      <Route
        exact
        path={`${match.url}/:customer_id/details/sales-history`}
        render={({ match }) => (
          <app.components.Layout title="Customers">
            <CustomerDetails
              customer_id={match.params.customer_id}
              openModal={{ tab: 3 }}
            />
          </app.components.Layout>
        )}
      />
      <Route
        exact
        path={`${match.url}/:customer_id/details/service-history`}
        render={({ match }) => (
          <app.components.Layout title="Customers">
            <CustomerDetails
              customer_id={match.params.customer_id}
              openModal={{ tab: 4 }}
            />
          </app.components.Layout>
        )}
      />
      <Route
        exact
        path={`${match.url}/:customer_id/details/marketing`}
        render={({ match }) => (
          <app.components.Layout title="Customers">
            <CustomerDetails
              customer_id={match.params.customer_id}
              openModal={{ tab: 5 }}
            />
          </app.components.Layout>
        )}
      />
      <Route
        exact
        path={`${match.url}/:customer_id/details/communication`}
        render={({ match }) => (
          <app.components.Layout title="Customers">
            <CustomerDetails
              customer_id={match.params.customer_id}
              openModal={{ tab: 6 }}
            />
          </app.components.Layout>
        )}
      />
      <Route
        exact
        path={`${match.url}/:customer_id/details/leads`}
        render={({ match }) => (
          <app.components.Layout title="Customers">
            <CustomerDetails
              customer_id={match.params.customer_id}
              openModal={{ tab: 7 }}
            />
          </app.components.Layout>
        )}
      />
    </Switch>
  );
};

export default Customers;
