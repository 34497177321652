import {
  TOGGLE_DEALLOG_FILTERS,
  SET_SHOW_DEALLOG_FILTERS,
  SET_DEALLOG_DATE_FILTER,
  SET_FILTERS,
  RESET_FILTERS,
} from './actionTypes';

export const toggleDealLogFilters = () => ({
  type: TOGGLE_DEALLOG_FILTERS,
});

export const setFilters = (filterType, values) => ({
  type: SET_FILTERS,
  filterType,
  values,
});

export const resetFilters = () => ({
  type: RESET_FILTERS,
});

export const setShowDealLogFilters = showFilters => ({
  type: SET_SHOW_DEALLOG_FILTERS,
  showFilters,
});

export const setDealLogDateFilter = (year, month) => ({
  type: SET_DEALLOG_DATE_FILTER,
  year,
  month,
});
