import React from 'react';

/* external */
import gql from 'graphql-tag';
import moment from 'moment';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import { useForm, Controller } from 'react-hook-form';

/* Material UI */
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import { DatePicker } from '@mui/x-date-pickers';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

/* internal */
import Loading from 'components/MaterialUI/Loading';
import SelectControl from 'components/MaterialUI/SelectControl2';

const MODIFY_PACKAGE_FREQUENCY = gql`
  mutation ModifyPackageFrequency($id: ID!, $input: PackageFrequencyInput!) {
    concierge {
      modifyPackageFrequency(id: $id, input: $input) {
        id
        paymentFrequency
        payments {
          id
          status
          amount
          scheduledDate
        }
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  textField: { width: '250px' },
  hiddenScroll: { overflow: 'hidden' },
  info: { marginBottom: '2em' },
}));

const PaymentFrequencyModal = ({ goldCard, isOpen, onClose, refetch }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const formMethods = useForm({
    defaultValues: {
      paymentFrequency: goldCard.paymentFrequency,
      startDate: moment().format('YYYY-MM-DD'),
    },
  });
  const { control, handleSubmit, reset, formState } = formMethods;
  const { isSubmitted } = formState;

  const [modifyPackageFrequency, { loading }] = useMutation(
    MODIFY_PACKAGE_FREQUENCY,
    {
      onCompleted: data => {
        enqueueSnackbar('Package successfully updated.', {
          variant: 'success',
        });
        refetch();
        onClose();
      },
      onError: () => {
        enqueueSnackbar('Failed to update package.', { variant: 'error' });
      },
    },
  );

  const textField = {
    control,
    className: classes.textField,
    InputLabelProps: { shrink: true },
  };

  const onSubmit = data => {
    const { paymentFrequency, startDate } = data;
    modifyPackageFrequency({
      variables: {
        id: goldCard.id,
        input: {
          paymentFrequency,
          startDate,
        },
      },
    });
    if (isSubmitted) {
      reset({ paymentFrequency: goldCard.paymentFrequency, startDate: '' });
      onClose();
    }
  };

  if (loading) return <Loading />;

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        onClose();
      }}
      maxWidth="sm"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Edit Payment Frequency</DialogTitle>
        <DialogContent className={classes.hiddenScroll}>
          <Alert severity="info" className={classes.info}>
            <Typography style={{ fontSize: '16px' }}>
              This will change the payment frequency for the package, starting
              from the date selected below. All outstanding payments after the
              date selected below will be removed and new payments will be
              re-created. Any payments already processed (successful, cancelled,
              failed, processing) will not be affected.
            </Typography>
          </Alert>
          <Grid container spacing={4}>
            <Grid item xs={12} style={{ justifyContent: 'center' }}>
              <SelectControl
                {...textField}
                label="Payment Frequency"
                name="paymentFrequency"
                optionNameKey="text"
                noNull
                options={[
                  { value: 'monthly', text: 'Monthly' },
                  { value: 'biweekly', text: 'Biweekly' },
                ]}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                rules={{ required: true }}
                render={({ field: { ref, onChange, ...field } }) => (
                  <DatePicker
                    autoOk
                    variant="inline"
                    format="YYYY-MM-DD"
                    disablePast
                    onChange={data => onChange(data?.format('YYYY-MM-DD'))}
                    {...field}
                  />
                )}
                name="startDate"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            size="small"
          >
            Update Payment Frequency
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

PaymentFrequencyModal.fragments = {
  package: gql`
    fragment PaymentFrequencyModalPackage on Package {
      paymentFrequency
    }
  `,
};

export default PaymentFrequencyModal;
