import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import React, { useState } from 'react';

/* Material UI */
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';

/* internal */
import DealerPicker from 'components/MaterialUI/DealerPicker';
import GoCardScanner from 'components/MaterialUI/GoCardScanner';
import { useUserContext } from 'components/MaterialUI/UserContext';

import RvMemberModal from '../../customers/components/CustomerDetails/GoCard/RvMemberModal';
import gorv from '../static/gorv.svg';

const GET_LOCATIONS = gql`
  query locations($filters: [QueryFilter]) {
    gocard {
      locations(filters: $filters, page: 1, pageSize: 100) {
        results {
          locationId
          locationName
          dealerId
        }
      }
    }
  }
`;

const RVGocardScanner = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const { currentUser } = useUserContext();
  const dealers = currentUser?.dealers || [];
  const [gocard, setGocard] = useState({});
  const [showAMA, setShowAMA] = useState(false);

  const { data: locationData } = useQuery(GET_LOCATIONS, {
    variables: {
      filters: [
        {
          model: 'Location',
          field: 'dealerId',
          op: 'in',
          value: dealers.map(({ dealer_id }) => dealer_id),
        },
      ],
    },
    errorPolicy: 'all',
  });

  const locations = locationData?.gocard?.locations?.results || [];

  const handleScan = () => {
    setShowAMA(true);
  };

  return (
    <Box m={3}>
      {gocard && (
        <RvMemberModal
          gocard={gocard?.accountById}
          isOpen={showAMA}
          onClose={() => setShowAMA(false)}
          locations={locations}
          allowSignup={false}
          scannedCard={true}
        />
      )}
      <Box>
        <DealerPicker
          {...(isMobile
            ? { style: { width: '100%', marginBottom: theme.spacing(1) } }
            : {})}
        />
      </Box>
      <Grid container>
        <Grid item xs={12}>
          <Box>
            <img
              alt="RV logo"
              style={{
                height: '150px',
                width: '150px',
                margin: '1em',
              }}
              src={gorv}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="h5"
            style={{ fontWeight: 'bold', marginLeft: '0.6em' }}
            gutterBottom
          >
            RV Adventure Club
          </Typography>
          <Grid item xs={12} md={6}>
            <Typography paragraph gutterButtom style={{ marginLeft: '1em' }}>
              With every GO RV Purchase, customers receive a FREE Adventure Club
              membership which entitles them to free firewood, propane, toilet
              paper, and sani chemicals for the life of their RV.
            </Typography>
          </Grid>
        </Grid>
        <GoCardScanner
          setGocard={setGocard}
          iconOnly={false}
          onScan={handleScan}
        />
      </Grid>
    </Box>
  );
};

export default RVGocardScanner;
