import React from 'react';
import { Switch, Route } from 'react-router-dom';
import app from '../../app';

import LeadDetails from './LeadDetails';
import LeadTable from './LeadTable';
import LeadConfig from './LeadConfig';
import LeadConfigRule from './LeadConfigRule';
import LeadDashboard from './LeadDashboard';

const Leads = props => {
  const { match } = props;
  return (
    <app.components.Layout title="Leads">
      <Switch>
        <Route exact path={`${match.url}`} render={() => <LeadTable />} />
        <Route
          exact
          path={`${match.url}/dashboard`}
          render={() => <LeadDashboard />}
        />
        <Route
          exact
          path={`${match.url}/config`}
          render={() => <LeadConfig />}
        />
        <Route
          exact
          path={`${match.url}/config/rule`}
          render={() => <LeadConfigRule />}
        />
        <Route
          exact
          path={`${match.url}/config/rule/:ruleId`}
          render={({ match }) => (
            <LeadConfigRule ruleId={match.params.ruleId} />
          )}
        />
        <Route
          exact
          path={`${match.url}/:leadId`}
          render={({ match }) => <LeadDetails leadId={match.params.leadId} />}
        />
      </Switch>
    </app.components.Layout>
  );
};

export default Leads;
