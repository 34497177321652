import React, { useState } from 'react';

/* external */
import { useMutation } from '@apollo/react-hooks';
import { useTheme } from '@mui/material';
import gql from 'graphql-tag';
import { useSnackbar } from 'notistack';

/* Material UI */
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

// internal
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';

const ADD_BUYER_NOTE_ON_APPRAISAL = gql`
  mutation createBuyerNote($buyernote: AppraisalBuyerNoteInput!) {
    appraisals {
      createBuyerNote(buyernote: $buyernote) {
        id
        appraisalId
        createdBy
        note
      }
    }
  }
`;

const AddAppraisalBuyerNoteModal = ({
  handleClose,
  onSave,
  appraisalId,
  dealerId,
}) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [note, setNote] = useState('');

  const [addBuyerNote, { loading: addLoading }] = useMutation(
    ADD_BUYER_NOTE_ON_APPRAISAL,
    {
      onCompleted: () => {
        onSave();
        enqueueSnackbar('Note Saved!', { variant: 'success' });
        handleClose();
      },
      onError: err => {
        enqueueSnackbar(`Could not save note error: ${err}`, {
          variant: 'error',
        });
      },
    },
  );

  const handleNote = event => {
    setNote(event.target.value);
  };

  const handleSubmit = () => {
    const noteData = {
      appraisalId,
      dealerId,
      note,
    };
    addBuyerNote({
      variables: { buyernote: noteData },
    });
  };

  return (
    <>
      <LoadingBackdrop open={addLoading}>Saving...</LoadingBackdrop>
      <DialogTitle>Add a Note</DialogTitle>
      <DialogContent>
        <TextField
          label="Note"
          value={note}
          onChange={handleNote}
          multiline
          maxRows={8}
          style={{ display: 'flex' }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="outlined">
          Cancel
        </Button>
        <Button
          style={
            note
              ? theme.actions.confirm
              : { backgroundColor: theme.palette.action.disabledBackground }
          }
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={!note}
        >
          Save
        </Button>
      </DialogActions>
    </>
  );
};

export default AddAppraisalBuyerNoteModal;
