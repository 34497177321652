import React from 'react';
import gql from 'graphql-tag';

import { GridWithLabel, GridItem, UintItem } from './GridWrappers';

export default function ExteriorGrid() {
  return (
    <GridWithLabel label="Exterior">
      <GridItem label="Exterior Colour" name="exteriorColourName" />
      <UintItem label="Awning Count" name="specAwningCount" />
      <UintItem label="Slides" name="specSlideCount" />
      <GridItem label="Awning Info" name="specAwningInfo" />
    </GridWithLabel>
  );
}

ExteriorGrid.fragments = {
  vehicle: gql`
    fragment NpvVehicleSpecsExterior on NpvVehicle {
      id
      exteriorColourName
      specSlideCount
      specAwningCount
      specAwningInfo
    }
  `,
};
