import gql from 'graphql-tag';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useQuery } from '@apollo/react-hooks';
import { isEqual } from 'lodash';

import { useMediaQuery } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import InputAdornment from '@mui/material/InputAdornment';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

import { formatPrice } from 'utils';
import { RateTypes } from '../constants';

import { usePermissionsContext } from '../../contexts/PermissionsContext';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTableCell-root': {
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.75rem',
        padding: '2px 7px',
      },
    },
    '& .MuiInputBase-root': {
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.75rem',
      },
    },
  },
  mobileTableHeader: {
    maxWidth: '90px',
    lineHeight: '1rem',
  },
}));

const PurchaseRates = ({
  stock_type: stockType,
  financeRates,
  setFinanceRates,
  leaseRates,
  setLeaseRates,
  defaultNewFinanceRates,
  id,
  staleRates,
  rates,
  setRates,
  lease,
  setLease,
  nameSelect,
  setNameSelect,
  leaseNameSelect,
  setLeaseNameSelect,
  ratePrograms,
  leaseRatePrograms,
  allRates,
  getRate,
}) => {
  const { register, setValue, getValues } = useFormContext({
    reValidateMode: 'onChange',
    shouldUnregister: false,
  });
  const theme = useTheme();
  const [editIndex, setEditIndex] = useState(null);
  const [editLeaseIndex, setEditLeaseIndex] = useState(null);

  const { update_detailed_pricing: canUpdateDetailPrice } =
    usePermissionsContext();

  const classes = useStyles();
  const mobile = useMediaQuery(theme => theme.breakpoints.up('sm'));

  const handleChange = (e, financeRate, isRate = false, isName = false) => {
    // persist event due to: https://stackoverflow.com/a/44708693
    e.persist();
    setRates(prev => {
      const tempItems = [...prev];

      const index = rates.findIndex(x => x.months === financeRate.months);

      if (index !== -1) {
        if (isRate) {
          const rate = tempItems[index];
          if (e.target.value[e.target.value.length - 1] === '.') {
            rate.rate = e.target.value;
          } else {
            rate.rate = parseFloat(e.target.value);
          }
        }
        if (isName) {
          const name = tempItems[index];
          name.name = e.target.value;
        }
      }
      return tempItems;
    });
  };

  const handleLeaseChange = (e, leaseRate, isRate = false, isName = false) => {
    // persist event due to: https://stackoverflow.com/a/44708693
    e.persist();
    setLease(prev => {
      const tempItems = [...prev];

      const index = lease.findIndex(x => x.months === leaseRate.months);

      if (index !== -1) {
        if (isRate) {
          const rate = tempItems[index];
          if (e.target.value[e.target.value.length - 1] === '.') {
            rate.rate = e.target.value;
          } else {
            rate.rate = parseFloat(e.target.value);
          }
        }
        if (isName) {
          const name = tempItems[index];
          name.name = e.target.value;
        }
      }
      return tempItems;
    });
  };

  const handleConfirm = (e, financeRate) => {
    setFinanceRates(rates);
  };

  const handleLeaseConfirm = () => {
    setLeaseRates(lease);
  };

  const resetToOldRate = (index, program_id, type) => {
    let oldProgram = staleRates.filter(x => x.program_id === program_id);

    if (oldProgram.length === 1 && oldProgram[0].rates) {
      let oldRate = oldProgram[0].rates[index];
      let tempRates = type === RateTypes.FINANCE ? rates : lease;
      tempRates[index] = oldRate;
      if (type === RateTypes.FINANCE) {
        setRates(tempRates);
      } else {
        setLease(tempRates);
      }
    }
  };

  const handleCheckboxChange = (e, financeRate, finance = false) => {
    let index;
    let tempItems = [];
    if (finance) {
      tempItems = [...rates];
      index = rates.findIndex(x => x.months === financeRate.months);
    } else {
      tempItems = [...lease];
      index = lease.findIndex(x => x.months === financeRate.months);
    }
    if (index !== -1) {
      let rate = tempItems[index];
      rate.show_on_website = e.target.checked;
      if (finance) {
        setRates(tempItems);
        setFinanceRates(tempItems);
      } else {
        setLease(tempItems);
        setLeaseRates(tempItems);
      }
    }
  };

  useEffect(() => {
    // This is necessary for the finance rates list.
    // It's initially populated with the default field values,
    // then updated when the user changes a field,
    // both of which are done in this effect
    // Only set the field dirty if there is a difference
    // between the current value and the value from the vehicle
    setValue('finance_rates', financeRates, {
      shouldDirty:
        financeRates && !isEqual(financeRates, getValues('finance_rates')),
    });
    setValue('lease_rates', leaseRates, {
      shouldDirty: leaseRates && !isEqual(leaseRates, getValues('lease_rates')),
    });
  }, [setValue, financeRates, getValues, leaseRates]);

  register('finance_rates');
  register('lease_rates');

  const financePayments = financeRates
    ?.filter(x => x.rate > 0)
    ?.map(({ bi_weekly_payment }) => bi_weekly_payment);

  const leasePayments = leaseRates?.map(
    ({ bi_weekly_payment }) => bi_weekly_payment,
  );

  const lowestFinancePayment = financePayments
    ? Math.min(...financePayments)
    : 0;
  const lowestLeasePayment = leasePayments ? Math.min(...leasePayments) : 0;

  const percentEnd = {
    endAdornment: <InputAdornment position="end">%</InputAdornment>,
  };

  const onlyOneDefaultRate =
    financeRates?.filter(x => x.rate > 0)?.length === 1;

  const turnOnEditMode = index => {
    setEditIndex(index);
  };

  const turnOffEditMode = () => {
    setEditIndex(null);
  };

  const turnOnLeaseEditMode = index => {
    setEditLeaseIndex(index);
  };

  const turnOffLeaseEditMode = () => {
    setEditLeaseIndex(null);
  };

  return (
    <Box style={{ paddingTop: '25px' }}>
      <div style={{ paddingBottom: '35px' }}>
        <Typography
          variant="h5"
          style={{ fontWeight: 'bold', marginBottom: '5px' }}
        >
          Purchase Finance Rates
        </Typography>
        {stockType === 'NEW' && (
          <>
            <Typography
              variant="h6"
              style={{ fontWeight: 'bold', marginBottom: '5px' }}
            >
              Adjust Rates
            </Typography>
            <Box style={{ display: 'flex', flexDirection: 'row' }}>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  paddingRight: '1rem',
                  marginBottom: 0,
                  marginTop: 'auto',
                }}
              >
                <Typography
                  variant="p"
                  style={{ fontWeight: 'bold', marginBottom: '5px' }}
                >
                  Choose a rate program to adjust ALL Finance Rates.
                </Typography>
              </Box>
              <Select
                value={nameSelect}
                onChange={e => {
                  setNameSelect(e.target.value);
                  getRate(e.target.value, RateTypes.FINANCE);
                }}
              >
                {ratePrograms.map(([key, value]) => {
                  return <MenuItem value={key}>{value}</MenuItem>;
                })}
              </Select>
            </Box>
          </>
        )}
        {rates?.length > 0 ? (
          <TableContainer>
            <>
              {mobile && (
                <Table>
                  <TableHead>
                    <TableCell>Term</TableCell>
                    <TableCell>Rate</TableCell>
                    <TableCell>Est. Biweekly Payment</TableCell>
                    <TableCell>Show On Website</TableCell>
                    {stockType === 'NEW' && <TableCell>Expiry Date</TableCell>}
                    <TableCell>Notes</TableCell>
                    <TableCell>Edit</TableCell>
                  </TableHead>
                  <TableBody>
                    {rates?.map((financeRate, index) => (
                      <TableRow>
                        <TableCell>{financeRate.months}</TableCell>
                        <TableCell>
                          {editIndex !== index && <>{financeRate.rate} %</>}
                          {editIndex === index && (
                            <TextField
                              disabled={!canUpdateDetailPrice}
                              value={financeRate.rate || 0}
                              style={{
                                textAlign: 'center',
                                width: '100px',
                              }}
                              onChange={e => handleChange(e, financeRate, true)}
                              InputProps={percentEnd}
                              className={classes.root}
                            />
                          )}
                        </TableCell>
                        <TableCell>
                          {formatPrice(financeRate.bi_weekly_payment)}
                        </TableCell>
                        <TableCell>
                          <Checkbox
                            disabled={
                              !canUpdateDetailPrice ||
                              (financeRate.rate > 0 &&
                                onlyOneDefaultRate &&
                                financeRate.show_on_website)
                            }
                            checked={financeRate.show_on_website}
                            onChange={e =>
                              handleCheckboxChange(e, financeRate, true)
                            }
                            color="secondary"
                          />
                        </TableCell>
                        {stockType === 'NEW' && (
                          <TableCell>{financeRate.expiry}</TableCell>
                        )}
                        <TableCell>
                          {editIndex === index && (
                            <TextField
                              disabled={!canUpdateDetailPrice}
                              value={financeRate.name || ''}
                              style={{
                                textAlign: 'center',
                                width: '150px',
                              }}
                              onChange={e =>
                                handleChange(e, financeRate, false, true)
                              }
                              className={classes.root}
                            />
                          )}
                          {editIndex !== index && <>{financeRate.name}</>}
                        </TableCell>
                        <TableCell>
                          {editIndex !== index && (
                            <IconButton
                              style={{
                                backgroundColor:
                                  theme.actions.info.backgroundColor,
                                color: 'white',
                              }}
                              onClick={() => {
                                turnOnEditMode(index);
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                          )}
                          {editIndex === index && (
                            <Box
                              style={{ display: 'flex', flexDirection: 'row' }}
                            >
                              <Box style={{ paddingRight: '1rem' }}>
                                <IconButton
                                  style={theme.actions.confirm}
                                  onClick={() => {
                                    turnOffEditMode();
                                    handleConfirm();
                                  }}
                                >
                                  <CheckIcon />
                                </IconButton>
                              </Box>
                              <IconButton
                                style={theme.actions.danger}
                                onClick={() => {
                                  turnOffEditMode();
                                  resetToOldRate(
                                    index,
                                    financeRate.program_id,
                                    RateTypes.FINANCE,
                                  );
                                }}
                              >
                                <CloseIcon />
                              </IconButton>
                            </Box>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
              {!mobile && (
                <Table className={classes.root}>
                  <TableHead>
                    <TableRow style={{ verticalAlign: 'bottom' }}>
                      <>
                        <TableCell
                          align="center"
                          className={classes.mobileTableHeader}
                        >
                          Term
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.mobileTableHeader}
                        >
                          Rate
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.mobileTableHeader}
                        >
                          Est. Bi-Weekly
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.mobileTableHeader}
                        >
                          Show on Website
                        </TableCell>
                      </>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    <>
                      {rates?.map((financeRate, index) => (
                        <TableRow>
                          <TableCell
                            key={`${index}-months`}
                            align="center"
                            style={{
                              backgroundColor: `${
                                financeRate.bi_weekly_payment ===
                                lowestFinancePayment
                                  ? '#EAE9E9'
                                  : '#fff'
                              }`,
                              fontWeight: 'bold',
                            }}
                          >
                            {financeRate.months}
                          </TableCell>
                          {stockType === 'NEW' && !defaultNewFinanceRates && (
                            <TableCell
                              key={`${index}-rate`}
                              align="center"
                              style={
                                financeRate.bi_weekly_payment ===
                                lowestFinancePayment
                                  ? { backgroundColor: '#EAE9E9' }
                                  : { backgroundColor: '#fff' }
                              }
                            >
                              {financeRate.rate}%
                            </TableCell>
                          )}
                          {(stockType === 'USED' || defaultNewFinanceRates) && (
                            <TableCell
                              key={`${index}-rate`}
                              align="center"
                              style={{
                                backgroundColor: `${
                                  financeRate.bi_weekly_payment ===
                                  lowestFinancePayment
                                    ? '#EAE9E9'
                                    : '#fff'
                                }`,
                              }}
                            >
                              <TextField
                                disabled={!canUpdateDetailPrice}
                                value={financeRate.rate || 0}
                                style={{
                                  backgroundColor: `${
                                    financeRate.bi_weekly_payment ===
                                    lowestFinancePayment
                                      ? '#EAE9E9'
                                      : '#fff'
                                  }`,
                                  textAlign: 'center',
                                  maxWidth: '50px',
                                }}
                                onChange={e => handleChange(e, financeRate)}
                                InputProps={percentEnd}
                                className={classes.root}
                              />
                            </TableCell>
                          )}
                          <TableCell
                            key={`${index}-payment`}
                            align="center"
                            style={
                              financeRate.bi_weekly_payment ===
                              lowestFinancePayment
                                ? { backgroundColor: '#EAE9E9' }
                                : { backgroundColor: '#fff' }
                            }
                          >
                            {formatPrice(financeRate.bi_weekly_payment)}
                          </TableCell>
                          <TableCell
                            key={`${index}-website`}
                            align="center"
                            style={
                              financeRate.bi_weekly_payment ===
                              lowestFinancePayment
                                ? { backgroundColor: '#EAE9E9' }
                                : { backgroundColor: '#fff' }
                            }
                          >
                            <Checkbox
                              disabled={
                                !canUpdateDetailPrice ||
                                (financeRate.rate > 0 &&
                                  onlyOneDefaultRate &&
                                  financeRate.show_on_website)
                              }
                              checked={financeRate.show_on_website}
                              onChange={e =>
                                handleCheckboxChange(e, financeRate, true)
                              }
                              size="small"
                              color="secondary"
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  </TableBody>
                </Table>
              )}
            </>
          </TableContainer>
        ) : (
          <>
            There are No Finance Rates for this vehicle, try populating rates.
          </>
        )}
      </div>

      {stockType === 'NEW' && (
        <div style={{ paddingBottom: '25px' }}>
          <Typography style={{ fontWeight: 'bold', marginBottom: '5px' }}>
            Purchase Lease Rates
          </Typography>
          <Typography
            variant="h6"
            style={{ fontWeight: 'bold', marginBottom: '5px' }}
          >
            Adjust Rates
          </Typography>
          <Box style={{ display: 'flex', flexDirection: 'row' }}>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                paddingRight: '1rem',
                marginBottom: 0,
                marginTop: 'auto',
              }}
            >
              <Typography
                variant="p"
                style={{
                  fontWeight: 'bold',
                  marginBottom: '5px',
                }}
              >
                Choose a rate program to adjust ALL Lease Rates.
              </Typography>
            </Box>
            <Select
              value={leaseNameSelect}
              onChange={e => {
                setLeaseNameSelect(e.target.value);
                getRate(e.target.value, 'Lease');
              }}
            >
              {leaseRatePrograms.map(([key, value]) => {
                return <MenuItem value={key}>{value}</MenuItem>;
              })}
            </Select>
          </Box>
          {lease?.length > 0 ? (
            <TableContainer>
              {/* *** DESKTOP LEASE TABLE *** */}
              {mobile && (
                <Table>
                  <caption
                    style={{
                      padding: '5px 0 0 0',
                      fontSize: `${mobile ? '' : '0.75rem'}`,
                    }}
                  >
                    **Based on 18,000km annual allowance
                  </caption>
                  <TableHead>
                    <TableCell>Term</TableCell>
                    <TableCell>Rate</TableCell>
                    <TableCell>Est. Biweekly Payment</TableCell>
                    <TableCell>Residual</TableCell>
                    <TableCell>Show On Website</TableCell>
                    <TableCell>Expiry Date</TableCell>
                    <TableCell>Notes</TableCell>
                    <TableCell>Edit</TableCell>
                  </TableHead>
                  <TableBody>
                    {lease?.map((leaseRate, index) => (
                      <TableRow>
                        <TableCell>{leaseRate.months}</TableCell>
                        <TableCell>
                          {editLeaseIndex !== index && <>{leaseRate.rate} %</>}
                          {editLeaseIndex === index && (
                            <TextField
                              disabled={!canUpdateDetailPrice}
                              value={leaseRate.rate || 0}
                              style={{
                                textAlign: 'center',
                                width: '100px',
                              }}
                              onChange={e =>
                                handleLeaseChange(e, leaseRate, true)
                              }
                              InputProps={percentEnd}
                              className={classes.root}
                            />
                          )}
                        </TableCell>
                        <TableCell>
                          {formatPrice(leaseRate.bi_weekly_payment)}
                        </TableCell>
                        <TableCell>
                          {formatPrice(leaseRate.residual_amount)}
                        </TableCell>
                        <TableCell>
                          <Checkbox
                            disabled={
                              !canUpdateDetailPrice ||
                              (leaseRate.rate > 0 &&
                                onlyOneDefaultRate &&
                                leaseRate.show_on_website)
                            }
                            checked={leaseRate.show_on_website}
                            onChange={e => handleCheckboxChange(e, leaseRate)}
                            color="secondary"
                          />
                        </TableCell>
                        <TableCell>{leaseRate.expiry}</TableCell>
                        {/* TODO: change this to a notes field on the rate itself. */}
                        <TableCell>
                          {editLeaseIndex === index && (
                            <TextField
                              disabled={!canUpdateDetailPrice}
                              value={leaseRate.name || ''}
                              style={{
                                textAlign: 'center',
                                width: '100px',
                              }}
                              onChange={e =>
                                handleLeaseChange(e, leaseRate, false, true)
                              }
                              className={classes.root}
                            />
                          )}
                          {editLeaseIndex !== index && <>{leaseRate.name}</>}
                        </TableCell>
                        <TableCell>
                          {editLeaseIndex !== index && (
                            <IconButton
                              style={{
                                backgroundColor:
                                  theme.actions.info.backgroundColor,
                                color: 'white',
                              }}
                              onClick={() => {
                                turnOnLeaseEditMode(index);
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                          )}
                          {editLeaseIndex === index && (
                            <Box
                              style={{ display: 'flex', flexDirection: 'row' }}
                            >
                              <Box style={{ paddingRight: '1rem' }}>
                                <IconButton
                                  style={theme.actions.confirm}
                                  onClick={() => {
                                    turnOffLeaseEditMode();
                                    handleLeaseConfirm();
                                  }}
                                >
                                  <CheckIcon />
                                </IconButton>
                              </Box>
                              <IconButton
                                style={theme.actions.danger}
                                onClick={() => {
                                  turnOffLeaseEditMode();
                                  resetToOldRate(
                                    index,
                                    leaseRate.program_id,
                                    RateTypes.LEASE,
                                  );
                                }}
                              >
                                <CloseIcon />
                              </IconButton>
                            </Box>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
              {/* *** MOBILE LEASE TABLE *** */}
              {!mobile && (
                <Table className={classes.root}>
                  <caption
                    style={{
                      padding: '5px 0 0 0',
                      fontSize: `${mobile ? '' : '0.75rem'}`,
                    }}
                  >
                    **Based on 18,000km annual allowance
                  </caption>
                  <TableHead>
                    <TableRow style={{ verticalAlign: 'bottom' }}>
                      <>
                        <TableCell
                          align="center"
                          className={classes.mobileTableHeader}
                        >
                          Term
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.mobileTableHeader}
                        >
                          Rate
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.mobileTableHeader}
                        >
                          Est. Bi-Weekly
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.mobileTableHeader}
                        >
                          Residual
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.mobileTableHeader}
                        >
                          Show on Website
                        </TableCell>
                      </>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <>
                      {lease.map((leaseRate, index) => (
                        <TableRow>
                          <TableCell
                            key={`${index}-lease-month`}
                            align="center"
                            style={{
                              backgroundColor: `${
                                leaseRate.bi_weekly_payment ===
                                lowestLeasePayment
                                  ? '#EAE9E9'
                                  : '#fff'
                              }`,
                              fontWeight: 'bold',
                            }}
                          >
                            {leaseRate.months}
                          </TableCell>
                          <TableCell
                            key={`${index}-lease-rate`}
                            align="center"
                            style={
                              leaseRate.bi_weekly_payment === lowestLeasePayment
                                ? { backgroundColor: '#EAE9E9' }
                                : { backgroundColor: '#fff' }
                            }
                          >
                            {leaseRate.rate}%
                          </TableCell>
                          <TableCell
                            key={`${index}-lease-payment`}
                            align="center"
                            style={
                              leaseRate.bi_weekly_payment === lowestLeasePayment
                                ? { backgroundColor: '#EAE9E9' }
                                : { backgroundColor: '#fff' }
                            }
                          >
                            {formatPrice(leaseRate.bi_weekly_payment)}
                          </TableCell>
                          <TableCell
                            key={`${index}-lease-residual`}
                            align="center"
                            style={
                              leaseRate.bi_weekly_payment === lowestLeasePayment
                                ? { backgroundColor: '#EAE9E9' }
                                : { backgroundColor: '#fff' }
                            }
                          >
                            {formatPrice(leaseRate.residual_amount)}
                          </TableCell>
                          <TableCell
                            key={`${index}-lease-website`}
                            align="center"
                            style={
                              leaseRate.bi_weekly_payment === lowestLeasePayment
                                ? { backgroundColor: '#EAE9E9' }
                                : { backgroundColor: '#fff' }
                            }
                          >
                            <Checkbox
                              checked={leaseRate.show_on_website}
                              onChange={e => handleCheckboxChange(e, leaseRate)}
                              size="small"
                              color="secondary"
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  </TableBody>
                </Table>
              )}
            </TableContainer>
          ) : (
            <>
              There are no Lease Rates available for this vehicle, please try
              populating some.
            </>
          )}
        </div>
      )}
    </Box>
  );
};

PurchaseRates.fragments = {
  vehicle: gql`
    fragment PurchaseRatesVehicle on GreaseInventoryVehicle {
      id
      cost
      detailed_pricing_base
      finance_rates {
        id
        months
        name
        expiry
        show_on_website
        bi_weekly_payment
        rate
        program_id
      }
      is_certified
      is_special_price_enabled
      lease_rates {
        id
        months
        name
        expiry
        show_on_website
        bi_weekly_payment
        rate
        residual_amount
        program_id
        residual_percent
        km_allowance
      }
      msrp
      regular_price
      special_price
      special_price_expires
    }
  `,
};

export default PurchaseRates;
