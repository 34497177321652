import React from 'react';

/* external */
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { cloneDeep, isEmpty, isEqual } from 'lodash';

/* Material UI */
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import {
  default as ExpandMore,
  default as ExpandMoreIcon,
} from '@mui/icons-material/ExpandMore';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { DatePicker } from '@mui/x-date-pickers';

/* internal */
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import { Role, Status, StatusDisplay, STATUS_ICON } from 'constants.js';
import { DATE_RANGE, LEAD_CHANNELS, STOCK_TYPES } from '../constants';
import {
  buildDateRange,
  getDateRange,
  getMultipleStatusCount,
  mapAssigneeCounts,
  mapLeadSourceCounts,
  mapSubStatusCounts,
  objectEquality,
  sortUsers,
  translateStatusCounts,
} from '../utils';

// Queries
const SUBSTATUS_QRY = gql`
  query Dealer($dealerId: Int!) {
    dealer(dealer_id: $dealerId) {
      dealer_name
      desking_settings {
        sub_statuses
      }
    }
  }
`;

const USERS_FOR_DEALER = gql`
  query UsersForDealer($dealerId: Int!) {
    users: users(
      dealer_ids: [$dealerId]
      status: active
      roles: [
        "${Role.SALES_REP}"
        "${Role.SALES_MANAGER}"
        "${Role.INTERNET_SALES_REP}"
        "${Role.BDC_REP}"
        "${Role.BDC_MANAGER}"
        "${Role.FINANCE_MANAGER}"
        "${Role.FINANCE_DIRECTOR}"
      ]
    ) {
      display_name
      username
      role
    }
  }
`;

const useStyles = makeStyles({
  panel: {
    width: '100%',
    paddingLeft: '25px',
    paddingRight: '25px',
  },
  title: {
    fontSize: '20px',
    paddingBottom: '10px',
    paddingLeft: '25px',
    fontWeight: 'bold',
  },
  margin: {
    marginBottom: '10px',
  },
  indent: {
    marginLeft: '10px',
  },
  drawer: {
    '&> .MuiDrawer-paper': {
      maxWidth: '410px',
    },
  },
});

const OpportunitiesFilters = ({
  filters,
  updateFilters,
  isOpen,
  closeFilters,
  filterDates,
  updateDates,
  leadSources,
  filterCounts,
  vehicleMakes,
  vehicleModels,
  vehicleYears,
}) => {
  const classes = useStyles();

  const SimpleFilterAccordion = ({ filterTitle, filterName, values }) => {
    return (
      <Accordion className={classes.margin}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          {filterTitle}
        </AccordionSummary>
        <List className={classes.indent}>
          {values.map(value => (
            <ListItem
              key={value}
              dense
              button
              onClick={() => toggleFilter(filterName, value)}
              selected={isSelected(filterName, value)}
            >
              <ListItemText primary={value} />
            </ListItem>
          ))}
        </List>
      </Accordion>
    );
  };

  const { dealerId } = useDealerContext();
  const { ...counts } = filterCounts.hasOwnProperty('status_results')
    ? filterCounts.status_results.length === 1
      ? filterCounts.status_results[0]
      : {}
    : {};
  const mappedCounts = translateStatusCounts(counts);
  const leadSourcesCounts = filterCounts.hasOwnProperty('lead_results')
    ? mapLeadSourceCounts(filterCounts.lead_results, leadSources)
    : [];
  const toggleStartDate = (date, filter) => {
    const dates = buildDateRange(date, filterDates.date_to);
    toggleFilter(filter, dates, false, true);
    updateDates({ ...filterDates, date_from: date });
  };

  const toggleEndDate = (date, filter) => {
    const dates = buildDateRange(filterDates.date_from, date);
    toggleFilter(filter, dates, false, true);
    updateDates({ ...filterDates, date_to: date });
  };

  const { loading, data } = useQuery(SUBSTATUS_QRY, {
    variables: { dealerId },
  });
  const subStatuses = !loading
    ? mapSubStatusCounts(
        filterCounts.sub_results,
        data.dealer.desking_settings.sub_statuses,
      )
    : [];

  const { loading: usersLoading, data: usersData } = useQuery(
    USERS_FOR_DEALER,
    { variables: { dealerId } },
  );

  const mappedAssignees = mapAssigneeCounts(
    filterCounts.user_results,
    usersData?.users || [],
  );

  const closeDrawer = () => {
    closeFilters();
  };

  // Toggle a filter on or off
  const toggleFilter = (
    filterName,
    filterValue,
    isList = false,
    isObj = false,
  ) => {
    // Don't manipulate actual state
    const newFilters = cloneDeep(filters);
    // Single value filter
    if (!isList && !isObj) {
      if (
        newFilters.hasOwnProperty(filterName) &&
        newFilters[filterName] === filterValue
      ) {
        // If key exists undefine it
        newFilters[filterName] = undefined;
        // delete newFilters[filterName];
      } else {
        // Add key and value
        newFilters[filterName] = filterValue;
      }
    }
    if (isList) {
      // Array value filter
      const filterArray = newFilters[filterName] ?? [];
      if (filterArray.includes(filterValue)) {
        // Get index of element and remove
        const index = filterArray.indexOf(filterValue);
        newFilters[filterName].splice(index, 1);
        if (newFilters[filterName].length === 0) {
          newFilters[filterName] = undefined;
        }
      } else {
        // Add Element, creating array if needed
        if (!newFilters.hasOwnProperty(filterName)) {
          newFilters[filterName] = [filterValue];
        } else {
          if (newFilters[filterName] === undefined) {
            newFilters[filterName] = [];
          }
          newFilters[filterName].push(filterValue);
        }
      }
    }
    if (isObj) {
      if (newFilters.hasOwnProperty(filterName)) {
        // If undefined, apply filter
        // If objects the same, delete
        // Otherwise apply filter
        if (newFilters[filterName] === undefined) {
          newFilters[filterName] = filterValue;
        } else if (
          objectEquality(newFilters[filterName], filterValue) ||
          isEmpty(filterValue)
        ) {
          newFilters[filterName] = undefined;
        } else {
          newFilters[filterName] = filterValue;
        }
        // If key doesn't exist add it
      } else {
        // newFilters[filterName] = filterValue
        if (!isEmpty(filterValue)) {
          newFilters[filterName] = filterValue;
        }
      }
    }

    // If no statuses applied use all statuses
    const allStatuses = newFilters['statuses'] === undefined;
    if (allStatuses) {
      newFilters['statuses'] = Status.ALL;
    }

    if (allStatuses) {
      newFilters['statuses'] = undefined;
    }

    // Update state of filters with
    updateFilters(newFilters);
  };

  // Handle Completed and Open status filters
  const toggleGroupStatusFilter = statuses => {
    // Deep copy filters
    const newFilters = cloneDeep(filters);
    const removeStatuses = isEqual(newFilters['statuses'], statuses);
    if (removeStatuses) {
      newFilters['statuses'] = Status.ALL;
    } else {
      newFilters['statuses'] = statuses;
    }

    if (removeStatuses) {
      newFilters['statuses'] = undefined;
    }
    updateFilters(newFilters);
  };

  const isGroupSelected = statuses => {
    const filterStatuses = filters['statuses'] ?? [];
    return isEqual(filterStatuses, statuses);
  };

  const isSelected = (
    filterName,
    filterValue,
    isList = false,
    isObj = false,
  ) => {
    // Check if a filter is selected
    // Single Value Filter
    if (!isList && !isObj) {
      if (filters[filterName] === filterValue) {
        return true;
      }
      return false;
    }

    if (isList) {
      // Grab the list and check if filter is included
      const filterValues = filters[filterName] ?? [];
      return filterValues.includes(filterValue);
    }
    if (isObj) {
      // compare List
      const objectValues = filters[filterName] ?? {};
      return objectEquality(objectValues, filterValue);
    }
  };

  // Reset all filters applied
  const resetFilters = () => {
    const resetFilters = cloneDeep(filters);
    const keys = Object.keys(resetFilters);
    // preserve sort_by
    keys
      .filter(key => key !== 'sort_by')
      .map(key => (resetFilters[key] = undefined));
    updateDates({ date_from: null, date_to: null });
    updateFilters(resetFilters);
  };

  return (
    <Drawer
      anchor="left"
      open={isOpen}
      onClose={closeDrawer}
      className={classes.drawer}
    >
      <div style={{ display: 'flex' }}>
        <IconButton
          style={{
            display: 'flex',
            marginLeft: 'auto',
            height: '50px',
            zIndex: '1000',
          }}
          onClick={closeDrawer}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </div>
      <div className={classes.title}>Filters</div>
      <div style={{ paddingBottom: '10px' }}>
        <Button
          style={{ color: 'red', paddingLeft: '25px' }}
          onClick={() => resetFilters()}
        >
          <HighlightOffIcon style={{ paddingRight: '5px' }} /> RESET ALL
        </Button>
      </div>
      <List className={classes.panel}>
        <Accordion className={classes.margin}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            Last Status Change
          </AccordionSummary>
          <List className={classes.indent}>
            {DATE_RANGE.map(date => (
              <ListItem
                key={date.value}
                dense
                button
                onClick={() => {
                  toggleFilter(
                    'status_date',
                    getDateRange(date.value),
                    false,
                    true,
                  );
                  updateDates({ date_from: null, date_to: null });
                }}
                selected={isSelected(
                  'status_date',
                  getDateRange(date.value),
                  false,
                  true,
                )}
              >
                <ListItemText primary={date.display} />
              </ListItem>
            ))}
            <ListItem>
              <Box flexDirection="column" alignItems="stretch">
                <Box>From</Box>
                <Box>
                  <DatePicker
                    autoOk
                    clearable
                    emptyLabel=""
                    format="YYYY-MM-DD"
                    onChange={date => toggleStartDate(date, 'status_date')}
                    value={filterDates.date_from}
                    variant="dialog"
                  />
                </Box>
                <Box>To</Box>
                <Box>
                  <DatePicker
                    autoOk
                    clearable
                    emptyLabel=""
                    format="YYYY-MM-DD"
                    onChange={date => toggleEndDate(date, 'status_date')}
                    value={filterDates.date_to}
                    variant="dialog"
                  />
                </Box>
              </Box>
            </ListItem>
          </List>
        </Accordion>
        <Accordion className={classes.margin}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            Date Created
          </AccordionSummary>
          <List className={classes.indent}>
            <ListItem>
              <Box flexDirection="column" alignItems="stretch">
                <Box>From</Box>
                <Box>
                  <DatePicker
                    autoOk
                    clearable
                    emptyLabel=""
                    format="YYYY-MM-DD"
                    onChange={date => toggleStartDate(date, 'created')}
                    value={filterDates.date_from}
                    variant="dialog"
                  />
                </Box>
                <Box>To</Box>
                <Box>
                  <DatePicker
                    autoOk
                    clearable
                    emptyLabel=""
                    format="YYYY-MM-DD"
                    onChange={date => toggleEndDate(date, 'created')}
                    value={filterDates.date_to}
                    variant="dialog"
                  />
                </Box>
              </Box>
            </ListItem>
          </List>
        </Accordion>
        <Accordion className={classes.margin}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            Stock Type
          </AccordionSummary>
          <List
            disablePadding
            className={classes.indent}
            style={{ width: '100%' }}
          >
            {STOCK_TYPES.map(stockType => (
              <ListItem
                key={stockType.value}
                dense
                button
                onClick={() => toggleFilter('stock_type', stockType.value)}
                selected={isSelected('stock_type', stockType.value)}
              >
                <ListItemText primary={stockType.display} />
                <ListItemSecondaryAction>
                  <Typography variant="caption">
                    (
                    {
                      mappedCounts[
                        stockType.value === '' ? 'unknown' : stockType.value
                      ]
                    }
                    )
                  </Typography>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Accordion>
        <Accordion className={classes.margin}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            Status
          </AccordionSummary>
          <List
            disablePadding
            className={classes.indent}
            style={{ width: '100%' }}
          >
            {Status.ALL.map(status => (
              <ListItem
                key={status}
                dense
                button
                onClick={() => toggleFilter('statuses', status, true)}
                selected={isSelected('statuses', status, true)}
              >
                <ListItemIcon style={{ minWidth: '30px' }}>
                  <Icon
                    style={{
                      fontSize: '.85rem',
                      color: STATUS_ICON[status].color,
                    }}
                    className={STATUS_ICON[status].icon}
                  />
                </ListItemIcon>
                <ListItemText primary={StatusDisplay[status]} />
                <ListItemSecondaryAction>
                  <Typography variant="caption">
                    ({mappedCounts[status]})
                  </Typography>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
            <ListItem
              dense
              button
              onClick={() => toggleGroupStatusFilter(Status.COMPLETED)}
              selected={isGroupSelected(Status.COMPLETED)}
            >
              <ListItemIcon style={{ minWidth: '30px' }}>
                <Icon
                  style={{
                    fontSize: '.85rem',
                    color: 'blue',
                  }}
                  className="fa fa-folder"
                />
              </ListItemIcon>
              <ListItemText primary="Completed" />
              <ListItemSecondaryAction>
                <Typography variant="caption">
                  ({getMultipleStatusCount(Status.COMPLETED, mappedCounts)})
                </Typography>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem
              dense
              button
              onClick={() => toggleGroupStatusFilter(Status.OPEN_OPPS)}
              selected={isGroupSelected(Status.OPEN_OPPS)}
            >
              <ListItemIcon style={{ minWidth: '30px' }}>
                <Icon
                  style={{
                    fontSize: '.85rem',
                    color: 'blue',
                  }}
                  className="fa fa-folder-open"
                />
              </ListItemIcon>
              <ListItemText primary="Open" />
              <ListItemSecondaryAction>
                <Typography variant="caption">
                  ({getMultipleStatusCount(Status.OPEN_OPPS, mappedCounts)})
                </Typography>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </Accordion>
        <Accordion className={classes.margin}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            Sub Status
          </AccordionSummary>
          {!loading &&
            subStatuses.map(subStatus => (
              <ListItem
                key={subStatus._id}
                dense
                button
                onClick={() => toggleFilter('sub_status', subStatus._id)}
                selected={isSelected('sub_status', subStatus._id)}
              >
                <ListItemText primary={subStatus._id} />
                <ListItemSecondaryAction>
                  <Typography variant="caption">({subStatus.count})</Typography>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
        </Accordion>
        <Accordion className={classes.margin}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            Sale Person
          </AccordionSummary>
          <List className={classes.indent}>
            <ListItem
              dense
              button
              onClick={() => toggleFilter('assignees', 'unassigned', true)}
            >
              <ListItemText primary="Unassigned" />
              <ListItemSecondaryAction>
                <Typography variant="caption">
                  ({mappedCounts['unassigned']})
                </Typography>
              </ListItemSecondaryAction>
            </ListItem>
            {!usersLoading &&
              sortUsers(mappedAssignees, [
                Role.SALES_REP,
                Role.INTERNET_SALES_REP,
                Role.CUSTOMER_REP,
              ]).map(user => (
                <ListItem
                  key={user.username}
                  dense
                  button
                  onClick={() =>
                    toggleFilter('sales_assignees', user.username, true)
                  }
                  selected={isSelected('sales_assignees', user.username, true)}
                >
                  <ListItemText primary={user.display_name} />
                  <ListItemSecondaryAction>
                    <Typography variant="caption">({user.count})</Typography>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
          </List>
        </Accordion>
        <Accordion className={classes.margin}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            Sales Managers
          </AccordionSummary>
          <List className={classes.indent}>
            <ListItem
              dense
              button
              onClick={() => toggleFilter('assignees', 'unassigned', true)}
            >
              <ListItemText primary="Unassigned" />
              <ListItemSecondaryAction>
                <Typography variant="caption">
                  ({mappedCounts['unassigned']})
                </Typography>
              </ListItemSecondaryAction>
            </ListItem>
            {!usersLoading &&
              sortUsers(mappedAssignees, [
                Role.SALES_MANAGER,
                Role.FINANCE_MANAGER,
              ]).map(user => (
                <ListItem
                  key={user.username}
                  dense
                  button
                  onClick={() =>
                    toggleFilter('manager_assignees', user.username, true)
                  }
                  selected={isSelected(
                    'manager_assignees',
                    user.username,
                    true,
                  )}
                >
                  <ListItemText primary={user.display_name} />
                  <ListItemSecondaryAction>
                    <Typography variant="caption">({user.count})</Typography>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
          </List>
        </Accordion>
        <Accordion className={classes.margin}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            BDC Rep
          </AccordionSummary>
          <List className={classes.indent}>
            {!usersLoading &&
              sortUsers(mappedAssignees, [Role.BDC_REP, Role.BDC_MANAGER]).map(
                user => (
                  <ListItem
                    key={user.username}
                    dense
                    button
                    onClick={() =>
                      toggleFilter('bdc_assignees', user.username, true)
                    }
                    selected={isSelected('bdc_assignees', user.username, true)}
                  >
                    <ListItemText primary={user.display_name} />
                    <ListItemSecondaryAction>
                      <Typography variant="caption">({user.count})</Typography>
                    </ListItemSecondaryAction>
                  </ListItem>
                ),
              )}
          </List>
        </Accordion>
        <Accordion className={classes.margin}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            Finance Producer
          </AccordionSummary>
          <List className={classes.indent}>
            {!usersLoading &&
              sortUsers(mappedAssignees, [
                Role.FINANCE_MANAGER,
                Role.FINANCE_DIRECTOR,
              ]).map(user => (
                <ListItem
                  key={user.username}
                  dense
                  button
                  onClick={() =>
                    toggleFilter('finance_assignees', user.username, true)
                  }
                  selected={isSelected(
                    'finance_assignees',
                    user.username,
                    true,
                  )}
                >
                  <ListItemText primary={user.display_name} />
                  <ListItemSecondaryAction>
                    <Typography variant="caption">({user.count})</Typography>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
          </List>
        </Accordion>
        <Accordion className={classes.margin}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            Lead Channel
          </AccordionSummary>
          <List className={classes.indent}>
            {LEAD_CHANNELS.map(channel => (
              <ListItem
                key={channel.value}
                dense
                button
                onClick={() => toggleFilter('lead_dir_channel', channel.value)}
                selected={isSelected('lead_dir_channel', channel.value)}
              >
                <ListItemText primary={channel.display} />
                <ListItemSecondaryAction>
                  <Typography variant="caption">
                    ({mappedCounts[channel.value]})
                  </Typography>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Accordion>
        <Accordion className={classes.margin}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            Lead Source
          </AccordionSummary>
          <List className={classes.indent}>
            {leadSourcesCounts.map(
              (source, index) =>
                source !== undefined && (
                  <ListItem
                    key={
                      source._id === null || source._id === ''
                        ? `$filter-${index}`
                        : source._id
                    }
                    dense
                    button
                    onClick={() => toggleFilter('lead_source', source._id)}
                    selected={isSelected('lead_source', source._id)}
                  >
                    <ListItemText
                      primary={source._id === '' ? 'No Source' : source._id}
                    />
                    <ListItemSecondaryAction>
                      <Typography variant="caption">
                        ({source.count})
                      </Typography>
                    </ListItemSecondaryAction>
                  </ListItem>
                ),
            )}
          </List>
        </Accordion>
        <SimpleFilterAccordion
          filterTitle="Vehicle Year"
          filterName="dms_deal_year"
          values={vehicleYears.filter(x => x).sort((a, b) => a < b)}
        />
        <SimpleFilterAccordion
          filterTitle="Vehicle Make"
          filterName="dms_deal_make_name"
          values={vehicleMakes.sort((a, b) => a.localeCompare(b))}
        />
        <SimpleFilterAccordion
          filterTitle="Vehicle Model"
          filterName="dms_deal_model_name"
          values={vehicleModels.sort((a, b) => a.localeCompare(b))}
        />
      </List>
    </Drawer>
  );
};

export default OpportunitiesFilters;
