import React from 'react';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { URL } from 'constants.js';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { debounce } from 'underscore';

const LeadClaim = () => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const urlParams = new URLSearchParams(window.location.search);
  const lead_id = urlParams.get('lead_id');
  const key = urlParams.get('key');

  const claimLead = debounce(() => {
    axios
      .post(URL.marketGraphLeadClaim, {
        key,
        lead_id,
      })
      .then(function (res) {
        enqueueSnackbar(`Lead Claimed`, {
          variant: 'success',
        });
        history.push(`/leads/${res.data.data._id}`);
      })
      .catch(function (error) {
        enqueueSnackbar(
          `Lead Claim failed, somebody else has claimed the lead before you.`,
          {
            variant: 'error',
          },
        );
      });
  }, 1500);

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'row',
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginLeft: 'auto',
          marginRight: 'auto',
          marginTop: '10rem',
          textAlign: 'center',
        }}
      >
        <Typography variant="h2" color="secondary">
          Don't Forget to Claim The Lead!
        </Typography>
        <Typography variant="h5" style={{ paddingBottom: '1rem' }}>
          In order to claim the lead press the button below!
        </Typography>
        <Button
          style={{ marginLeft: '1rem', marginRight: '1rem', height: '50px' }}
          color="secondary"
          variant="contained"
          onClick={claimLead}
        >
          Claim Lead
        </Button>
      </Box>
    </Box>
  );
};

export default LeadClaim;
