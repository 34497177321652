import React from 'react';
import { Controller } from 'react-hook-form';

import { usePermissionsContext } from './PermissionsContext';

// A permissions-context-aware version of react-hook-form Controller.
// Sets readOnly for inputProps since some components in material-ui
// don't pass down readOnly from top level.
// Intercepts onChange in render function since checkboxes and radiobuttons
// don't support readOnly

const PermissionsController = ({ children, onChange, render, ...props }) => {
  const permissions = usePermissionsContext();
  return (
    <Controller
      disabled={props.disabled || !permissions.update}
      render={
        render
          ? ({ onChange, ...rest }) =>
              render({
                onChange: permissions.update ? onChange : () => {},
                ...rest,
              })
          : null
      }
      {...props}
    >
      {children}
    </Controller>
  );
};

export default PermissionsController;
