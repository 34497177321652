import React, { useRef, useState } from 'react';
import { withStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import { cloneDeep } from 'lodash';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import InspectionCheckboxes from '../InspectionCheckboxes';

const VHCForm = props => {
  const { enqueueSnackbar } = useSnackbar();

  // Vehicle History Checklist fields
  const checklist = {
    v1: 'Was the vehicle ever bought back by the manufacturer?',
    v2: 'Was the vehicle ever damaged by ﬁre?',
    v3: 'Was the vehicle ever damaged by ﬂooding?',
    v4: 'Was the vehicle ever used as a police or emergency vehicle?',
    v5: 'Was the vehicle ever used as a taxi or limo?',
    v6: 'Was the vehicle ever owned by a vehicle rental business or used as a rental vehicle?',
    v7: 'Was the vehicle ever declared a salvage vehicle or equivalent under another jurisdiction?',
    v8: 'Was the vehicle ever declared a non-repairable vehicle or equivalent under another jurisdiction?',
    v9: 'Was the vehicle ever declared an unsafe vehicle or equivalent under another jurisdiction?',
    v10: 'Was the vehicle ever in need of repairs that cost more than $3,000 including parts and labour? If yes, and those repairs were completed by the business operator, the total to complete the repairs was:',
    v11: 'Was the vehicle previously registered in a different jurisdiction? If yes, name the province/country:',
    v12: 'If the vehicle was registered in another jurisdiction, was it required to be inspected prior to being registered in Alberta? If yes, did the vehicle pass or fail any inspection?',
    v13: 'Was all of the above vehicle history information provided in writing to the consumer prior to purchase?',
  };

  let checklistObj = cloneDeep(checklist);

  Object.entries(checklistObj).map(([name]) => (checklistObj[name] = 'Yes'));

  const [checklistValues, setChecklistValues] = useState(checklistObj);

  const handleChecklist = e => {
    const { name, value } = e.target;
    setChecklistValues(prevChecklistValues => ({
      ...prevChecklistValues,
      [name]: value,
    }));
  };

  const [consent, setConsent] = useState(false);
  const handleConsent = () => {
    setConsent(consent => !consent);
  };

  const [confirm, setConfirm] = useState(false);
  const handleConfirm = () => {
    setConfirm(confirm => !confirm);
  };

  const wholeForm = useRef(null);

  const handleSubmit = e => {
    e.preventDefault();

    if (!wholeForm.current['amvic_number'].value) {
      enqueueSnackbar(`Please fill out 'Tech #'.`);
      return;
    }

    if (!consent) {
      enqueueSnackbar(`Please check 'I agree' and then submit the inspection!`);
      return;
    }

    checklistValues['v10_field'] = wholeForm.current['v10_field'].value;
    checklistValues['v11_field'] = wholeForm.current['v11_field'].value;
    checklistValues['v12_field'] = wholeForm.current['v12_field'].value;

    const values = {
      ...checklistValues,
    };
    const form_data = {
      vhc: values,
      amvic_number: wholeForm.current['amvic_number'].value,
    };

    props.formData(form_data);
    props.formClose();
  };

  const GreenButton = withStyles({
    root: {
      color: '#fff',
      backgroundColor: '#198754',
      '&:hover': {
        backgroundColor: '#157347',
      },
    },
  })(Button);

  return (
    <form onSubmit={handleSubmit} ref={wholeForm}>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>Review the list below and check all that apply.</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            {Object.entries(checklist).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid
                    item
                    xs={6}
                    style={{ paddingLeft: '1rem', paddingBottom: '.5rem' }}
                  >
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={checklistValues}
                      handleGroup={handleChecklist}
                      name={name}
                      orange={false}
                      greenValue="Yes"
                      redValue="No"
                      white
                    />
                  </Grid>
                  {name === 'v10' && (
                    <Grid container style={{ marginBottom: '1rem' }}>
                      <Grid item xs={12} style={{ padding: '0 1rem' }}>
                        <TextField
                          label="Repair cost $"
                          name={'v10_field'}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  )}
                  {name === 'v11' && (
                    <Grid container style={{ marginBottom: '1rem' }}>
                      <Grid item xs={12} style={{ padding: '0 1rem' }}>
                        <TextField
                          label="Province/Country"
                          name={'v11_field'}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  )}
                  {name === 'v12' && (
                    <Grid container style={{ marginBottom: '1rem' }}>
                      <Grid item xs={12} style={{ padding: '0 1rem' }}>
                        <TextField
                          label="Details"
                          name={'v12_field'}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  )}
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Typography style={{ marginTop: '15px' }}>
        I verify the contents of this document are true and correct.
      </Typography>
      <FormControlLabel
        control={<Checkbox />}
        label="I agree"
        onChange={handleConsent}
        value={consent}
      />
      <br />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Technician Name"
            value={props.user.display_name}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Tech #"
            value={props.user.goUserProfile.profile.sales_licence}
            fullWidth
            name={'amvic_number'}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Date"
            value={new Date().toLocaleString()}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <Button
            type="button"
            variant="contained"
            style={{ marginRight: '15px' }}
            onClick={handleConfirm}
            color="default"
          >
            Cancel
          </Button>
          <Dialog
            open={confirm}
            onClose={handleConfirm}
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Current inspection will be closed and unsaved data will be gone.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleConfirm}>Cancel</Button>
              <Button onClick={props.formClose} color="secondary">
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
          <GreenButton type="submit" variant="contained">
            Save
          </GreenButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default VHCForm;
