import React from 'react';

/* external */
import { Image } from 'cloudinary-react';
import gql from 'graphql-tag';

/* Material UI */
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';

const ItemPhotoCell = ({ item }) => (
  <TableCell>
    {item.vehicle?.photos?.length > 0 ? (
      <Image
        publicId={item.vehicle.photos[0].cloudinaryPublicId}
        width="125"
        crop="scale"
      />
    ) : (
      <Box width="125px" height="100%" textAlign="center" fontWeight={300}>
        No picture
      </Box>
    )}
  </TableCell>
);

ItemPhotoCell.fragments = {
  auctionItem: gql`
    fragment ItemPhotoCellAuctionItem on AuctionItem {
      vehicle {
        photos {
          id
          cloudinaryPublicId
        }
      }
    }
  `,
};
export default ItemPhotoCell;
