import React, { useState } from 'react';

/* external */
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import gql from 'graphql-tag';

/* Material UI */
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Tooltip } from '@mui/material';

/* internal */
import ConfirmDialog from 'components/MaterialUI/ConfirmDialog';
import FormatDateTZ from 'components/MaterialUI/FormatDateTZ';
import { errorHandler } from 'modules/auctions/utils';

const SET_AUCTION_ACTIVE = gql`
  mutation setAuctionActive($id: Int!, $active: Boolean!) {
    auctions {
      updateAuction(id: $id, data: { active: $active }) {
        id
        active
      }
    }
  }
`;

const DELETE_AUCTION = gql`
  mutation deleteAuction($id: Int!) {
    auctions {
      deleteAuction(id: $id) {
        id
        name
      }
    }
  }
`;

const AuctionsTableRow = ({ auction }) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [setAuctionActive] = useMutation(SET_AUCTION_ACTIVE);
  const [deleteAuction] = useMutation(DELETE_AUCTION);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const {
    active,
    activeItemsCount,
    canBid,
    canBlindBid,
    id,
    name,
    permissions = {}, // subscription updates won't have this, since it's context-based
    statusText,
    upcomingItemsCount,
    weekly,
  } = auction;

  const { nextAdd, nextBlind, nextStart } = weekly ?? {};

  const handleClickActivate = e => {
    e.stopPropagation();
    setAuctionActive({ variables: { id, active: !active } });
  };

  const handleClickDelete = e => {
    e.stopPropagation();
    setShowConfirmDelete(true);
  };

  const handleClickEdit = e => {
    e.stopPropagation();
    history.push(`/auctions/edit/${id}`);
  };

  const handleConfirmDelete = () =>
    deleteAuction({ variables: { id } }).then(
      r =>
        enqueueSnackbar(
          `Deleted ${JSON.stringify(
            r.data.auctions.deleteAuction.name,
          )} auction`,
        ),
      err => errorHandler(enqueueSnackbar, () => {})(err),
    );

  const handleCloseDeleteConfirm = () => setShowConfirmDelete(false);

  return (
    /* TODO: bold on/off formatting on times */
    <TableRow hover={true} onClick={() => history.push(`/auctions/${id}`)}>
      <TableCell>
        {permissions.delete && (
          <Tooltip title="Delete" enterDelay={250}>
            <IconButton onClick={handleClickDelete} size="large">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
        {permissions.update && (
          <Tooltip title="Edit" enterDelay={250}>
            <IconButton onClick={handleClickEdit} size="large">
              <EditIcon />
            </IconButton>
          </Tooltip>
        )}
        {permissions.update && (
          <Tooltip title={active ? 'Deactivate' : 'Activate'} enterDelay={250}>
            <IconButton onClick={handleClickActivate} size="large">
              <PowerSettingsNewIcon
                style={{ color: active ? 'green' : 'red' }}
              />
            </IconButton>
          </Tooltip>
        )}
      </TableCell>
      <TableCell>{name}</TableCell>
      <TableCell>{statusText}</TableCell>
      <TableCell>{activeItemsCount}</TableCell>
      <TableCell>{upcomingItemsCount}</TableCell>
      <TableCell>
        {weekly && !canBid && !canBlindBid && (
          <FormatDateTZ date={nextAdd} format="lll" />
        )}
      </TableCell>
      <TableCell>
        {weekly && !canBid && <FormatDateTZ date={nextBlind} format="lll" />}
      </TableCell>
      <TableCell>
        {weekly && <FormatDateTZ date={nextStart} format="lll" />}
      </TableCell>
      <ConfirmDialog
        text={`Are you sure you want to delete the ${name} auction?`}
        onConfirm={handleConfirmDelete}
        isOpen={showConfirmDelete}
        onClose={handleCloseDeleteConfirm}
      />
    </TableRow>
  );
};

AuctionsTableRow.fragments = {
  auction: gql`
    fragment AuctionsTableRowAuction on Auction {
      id
      active
      activeItemsCount
      canAdd
      canBid
      canBlindBid
      name
      statusText
      upcomingItemsCount
      weekly {
        id
        lastEnd
        nextEnd
        nextStart
        nextAdd
        nextBlind
        durationMinutes
        addItemsMinutes
        blindBidMinutes
      }
    }
  `,
  auctionPermissions: gql`
    fragment AuctionsTableRowAuctionPermissions on Auction {
      id
      permissions {
        delete
        update
      }
    }
  `,
};
export default AuctionsTableRow;
