import gql from 'graphql-tag';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React from 'react';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import { green } from '@mui/material/colors';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';
import TableCell from '@mui/material/TableCell';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import EmailIcon from '@mui/icons-material/Email';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import InfoIcon from '@mui/icons-material/Info';
import SmartphoneIcon from '@mui/icons-material/Smartphone';

import CustomerAvatar from 'components/CustomerAvatar';

const useStyles = makeStyles(theme => ({
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  opportunityChips: {
    backgroundColor: green[500],
    color: 'white',
  },

  identity: { backgroundColor: green[400] },
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  avatarCell: {
    whiteSpace: 'pre-line',
    width: '1rem',
  },
  nameCell: {
    whiteSpace: 'pre-line',
    width: '25%',
  },
}));

const identityTypeIcon = identityType => {
  switch (identityType) {
    case 'email':
      return <EmailIcon fontSize="small" />;
    case 'sms':
      return <SmartphoneIcon fontSize="small" />;
    default:
      return <EmailIcon fontSize="small" />;
  }
};

const SearchResult = ({ customer, isItemSelected, handleClick }) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const location =
    customer.city && customer.province
      ? `${customer.city}, ${customer.province}`
      : '';

  // For a user to see if an open opportunity was attached
  const { opportunities } = customer;
  const customerHasOpenOpportunity = opportunities.length > 0;

  return (
    <>
      <TableCell padding="checkbox">
        <Checkbox
          checked={isItemSelected}
          onClick={event => {
            handleClick(event, customer);
            event.stopPropagation();
          }}
        />
      </TableCell>
      <TableCell>
        <Tooltip title="Open In New Tab">
          <IconButton
            size="small"
            onClick={e => {
              window.open(`/customers/${customer._id}/details`, '_blank');
              e.stopPropagation();
            }}
          >
            <InfoIcon style={{ color: '#2196f3', fontSize: '22px' }} />
          </IconButton>
        </Tooltip>
      </TableCell>
      <TableCell className={classes.avatarCell}>
        <CustomerAvatar customer={customer} className={classes.avatar} />
      </TableCell>
      <TableCell className={classes.nameCell}>
        <Typography>{customer.fullname}</Typography>
        {customer.nickname && <Typography>({customer.nickname})</Typography>}
        {location ? location.concat('\n') : ''}
        {customerHasOpenOpportunity && (
          <Chip
            label="New Opportunity"
            className={classes.opportunityChips}
            size="small"
          />
        )}
      </TableCell>
      <TableCell style={{ width: '25%' }}>
        {customer.identities &&
          customer.identities.map(ident => (
            <Grid container key={`${customer._id}-${ident.identity_type}`}>
              <Grid item xs={1}>
                {identityTypeIcon(ident.identity_type)}
              </Grid>
              <Grid item xs={11}>
                <Typography>{ident.identity_value}</Typography>
              </Grid>
            </Grid>
          ))}
      </TableCell>
      <TableCell>
        {customer.vehicles &&
          customer.vehicles
            .filter((v, index) => index < 2)
            .map((v, index) => {
              return (
                <Grid container key={`${index}-${v.year}-${v.make}`}>
                  <Grid item xs={1}>
                    <DriveEtaIcon />
                  </Grid>
                  <Grid item xs={11}>
                    <Typography>{`${v.year} ${v.make} ${v.model}`}</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ display: 'flex', flexDirection: 'row' }}
                  >
                    <Box
                      style={{
                        display: 'flex',
                        marginTop: 'auto',
                        marginBottom: 'auto',
                      }}
                    >
                      <Typography
                        style={{ color: 'grey' }}
                      >{`VIN: ${v.vin}`}</Typography>
                    </Box>
                    {v.vin && (
                      <Tooltip title="Copy VIN">
                        <IconButton
                          onClick={e => {
                            navigator.clipboard.writeText(v.vin);
                            e.stopPropagation();
                            enqueueSnackbar(
                              'VIN Copied to clipboard successfully!',
                            );
                          }}
                          size="large"
                        >
                          <FileCopyIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Grid>
                </Grid>
              );
            })}
      </TableCell>
      <TableCell>
        <Typography>{moment(customer.created).format('LL')}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{moment(customer.updated).format('LL')}</Typography>
      </TableCell>
    </>
  );
};

SearchResult.fragments = {
  customer: gql`
    fragment SearchResultFragment on Customer {
      _id
      fullname
      first_name
      last_name
      nickname
      city
      province
      created
      updated
      app_account {
        username
        createdAt
        modifiedAt
        isEnabled
        status
      }
      identities {
        identity_type
        identity_value
      }
      vehicles {
        year
        make
        model
        vin
      }
      opportunities(statuses: [0, 1, 2, 6, 7, 8, 10, 11]) {
        _id
      }
      ...CustomerAvatarCustomer
    }
    ${CustomerAvatar.fragments.customer}
  `,
};

export default SearchResult;
