import React, { useState } from 'react';

import { IconButton, InputAdornment, TextField } from '@mui/material';
import { Close as CloseIcon, Search as SearchIcon } from '@mui/icons-material';

export default function SearchField({ handleSearch }) {
  const [searchKeywords, setSearchKeywords] = useState('');

  return (
    <TextField
      placeholder="Search by name or phone"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconButton
              onClick={() => handleSearch(searchKeywords)}
              size="large"
            >
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              disabled={!searchKeywords}
              onClick={() => {
                setSearchKeywords('');
                handleSearch('');
              }}
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      value={searchKeywords}
      onChange={e => setSearchKeywords(e.target.value)}
      style={{ width: '22em', textAlign: 'right' }}
      onKeyDown={e => (e.key === 'Enter' ? handleSearch(searchKeywords) : null)}
    />
  );
}
