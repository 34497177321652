import gql from 'graphql-tag';
import React from 'react';

/* external */
import { useFormContext, useWatch } from 'react-hook-form';

/* Material UI */
import Box from '@mui/material/Box';

import CheckboxControl from 'components/MaterialUI/CheckboxControl';
import TextFieldControl from 'components/MaterialUI/TextFieldControl';

/* internal */
import { ISO_DAYS_OF_WEEK, WEEKEND_DAYS } from 'modules/auctions/const';

/*
    Note: we're not setting timezone here, since the auctions-service will set it
    automatically based on the user's setting.  AuctionForm shows an error
    message if the user has not set their timezone.
*/

const DailyAuctionForm = () => {
  const { control } = useFormContext();

  const isDaily = useWatch({ control, name: 'isDaily' });

  if (!isDaily) return null;

  const required = 'This is required';

  return (
    <Box display="flex" flexDirection="column">
      <Box margin={1}>
        <TextFieldControl
          inputProps={{ min: 0 }}
          label="Cutoff Days"
          name="daily.cutoffDays"
          required
          rules={{ required }}
          variant="number"
        />
      </Box>

      <Box margin={1}>
        <TextFieldControl
          inputProps={{ min: 1 }}
          label="Duration Minutes"
          name="daily.durationMinutes"
          required
          rules={{ required }}
          variant="number"
        />
      </Box>

      {ISO_DAYS_OF_WEEK.map(({ name: day }) => (
        <Box key={day} m={1}>
          <CheckboxControl
            label={`Skip ${day}`}
            name={`daily.skip${day}`}
            defaultValue={WEEKEND_DAYS.includes(day)} // default to skip weekend days
          />
        </Box>
      ))}

      <Box margin={1}>
        <TextFieldControl
          defaultValue="12:00"
          inputProps={{ step: 60 }}
          label="Cutoff Time"
          name="daily.cutoffTime"
          required
          rules={{ required }}
          type="time"
        />
      </Box>

      <Box margin={1}>
        <TextFieldControl
          defaultValue="12:00"
          inputProps={{ step: 60 }}
          label="Start Time"
          name="daily.startTime"
          required
          rules={{ required }}
          type="time"
        />
      </Box>
    </Box>
  );
};

DailyAuctionForm.fragments = {
  auction: gql`
    fragment DailyAuctionFormAuction on Auction {
      daily {
        id
        cutoffDays
        cutoffTime
        durationMinutes
        skipMonday
        skipTuesday
        skipWednesday
        skipThursday
        skipFriday
        skipSaturday
        skipSunday
        startTime
        timezone
      }
    }
  `,
};
export default DailyAuctionForm;
