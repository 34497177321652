import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';

import { CircularProgress, Fab, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import ConfirmDialog from 'components/MaterialUI/ConfirmDialog';

const DELETE_BY_FILTERS = gql`
  mutation delete($filters: [QueryFilter]) {
    appraisals {
      deleteAppraisalsByFilters(filters: $filters) {
        id
      }
    }
  }
`;

const BulkActions = ({
  filters,
  selectByFilter,
  selected,
  afterDelete,
  amount,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleAnchorClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleAnchorClose = () => setAnchorEl(null);

  const filterData = selectByFilter
    ? filters
    : [
        {
          model: 'Appraisal',
          field: 'id',
          op: 'in',
          value: selected,
        },
      ];

  const { enqueueSnackbar } = useSnackbar();

  const [deleteByFilter, { loading: deleteLoading }] = useMutation(
    DELETE_BY_FILTERS,
    {
      onCompleted: data => {
        handleAnchorClose();
        afterDelete(data);
        if (amount) {
          enqueueSnackbar(
            `Successfully deleted ${amount} ${
              amount === 1 ? 'vehicle' : 'vehicles'
            }.`,
            {
              variant: 'success',
            },
          );
        }
      },
      variables: { filters: filterData },
    },
  );

  const [showConfirmation, setShowConfirmation] = useState(false);

  return (
    <>
      <Fab color="primary" onClick={handleAnchorClick} size="medium">
        <MoreVertIcon onClick={handleAnchorClick} />
      </Fab>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => handleAnchorClose()}
        style={{ width: '200px' }}
        disableScrollLock={true}
      >
        <MenuItem
          disabled={deleteLoading}
          onClick={() => {
            setShowConfirmation(true);
          }}
        >
          {deleteLoading ? 'Deleting...' : 'Delete Selected'}
          {deleteLoading && (
            <CircularProgress size={18} style={{ marginLeft: '1rem' }} />
          )}
        </MenuItem>
      </Menu>
      <ConfirmDialog
        abortText="Cancel"
        confirmText="Delete"
        isOpen={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        onConfirm={() => {
          deleteByFilter();
        }}
        text={`This will delete ${amount} vehicles. Are you sure?`}
      />
    </>
  );
};

export default BulkActions;
