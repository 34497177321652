import React from 'react';

/* external */
import gql from 'graphql-tag';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';

/* Material UI */
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import EmailIcon from '@mui/icons-material/Email';
import Grid from '@mui/material/Grid';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { useMediaQuery } from '@mui/material';

/* internal */
import Loading from 'components/MaterialUI/Loading';
import { formatPhoneNumber, initials } from 'utils';

const useStyles = makeStyles(theme => ({
  sizeAvatar: {
    height: theme.spacing(7),
    width: theme.spacing(7),
  },
  button: {
    color: '#24A0ED',
    fontWeight: 'bold',
  },
}));

const GO_CARDS_QUERY = gql`
  query gocard(
    $filters: [QueryFilter]
    $page: Int!
    $sort: [QuerySortElement]
  ) {
    gocard {
      accounts(filters: $filters, page: $page, pageSize: 10, sort: $sort) {
        results {
          cardId
          cashBalance
          cardNumber
          transactions {
            amount
            code
            description
            transactionDate
            location {
              locationName
              dealerId
            }
          }
        }
        pagination {
          total
          page
        }
      }
    }
  }
`;

const CustomerDetailsModal = ({ customer, handleClose }) => {
  const desktop = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const classes = useStyles();
  const history = useHistory();
  const cardIds = customer.records
    .filter(record => record.includes('gocard'))
    .map(record => record.split(':').slice(-1)[0]);

  const { data, loading } = useQuery(GO_CARDS_QUERY, {
    variables: {
      filters: [
        {
          model: 'GoCard',
          field: 'cardId',
          op: 'in',
          value: cardIds,
        },
        {
          model: 'GoCard',
          field: 'active',
          op: 'eq',
          value: true,
        },
      ],
      page: 1,
    },
    errorPolicy: 'all',
  });

  if (loading) return <Loading />;

  const gocard = data?.gocard?.accounts?.results.sort(
    (a, b) => b.cashBalance - a.cashBalance,
  )?.[0];
  const transactions = gocard?.transactions ?? [];
  const vehicles = customer?.vehicles ?? [];

  return (
    <>
      <DialogTitle>Customer Details</DialogTitle>
      <DialogContent>
        <Grid
          container
          direction={'row'}
          justifyContent={'space-between'}
          spacing={2}
          alignItems={'stretch'}
        >
          {desktop && (
            <>
              <Grid item xs={2}>
                <Avatar className={classes.sizeAvatar}>
                  {initials(customer.fullname)}
                </Avatar>
              </Grid>
              <Grid item xs={10}>
                <Grid
                  container
                  direction={'row'}
                  alignItems={'stretch'}
                  justifyContent={'space-between'}
                  spacing={2}
                  xs={12}
                >
                  <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom>
                      {customer.fullname}
                    </Typography>
                  </Grid>
                  {customer.identities?.map(ident => {
                    if (ident.identity_type === 'sms') {
                      return (
                        <Grid item xs={4} key="ident-sms">
                          <Typography variant="body1" gutterBottom>
                            <SmartphoneIcon
                              style={{ fontSize: '1rem', marginRight: '5px' }}
                            />
                            {formatPhoneNumber(ident.identity_value)}
                          </Typography>
                        </Grid>
                      );
                    } else {
                      return (
                        <Grid item xs={8} key="ident-email">
                          <Typography variant="body1" gutterBottom>
                            <EmailIcon
                              style={{ fontSize: '1rem', marginRight: '5px' }}
                            />
                            {ident.identity_value}
                          </Typography>
                        </Grid>
                      );
                    }
                  })}
                </Grid>
              </Grid>
            </>
          )}
          {!desktop && (
            <>
              <Grid item xs={3}>
                <Avatar className={classes.sizeAvatar}>
                  {initials(customer.fullname)}
                </Avatar>
              </Grid>
              <Grid item xs={9}>
                <Grid
                  container
                  direction={'row'}
                  alignItems={'stretch'}
                  justifyContent={'space-between'}
                  spacing={2}
                  xs={12}
                >
                  <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                      {customer.fullname}
                    </Typography>
                  </Grid>
                  {customer.identities?.map(ident => {
                    if (ident.identity_type === 'sms') {
                      return (
                        <Grid item xs={12} key="ident-sms">
                          <Typography variant="body2" gutterBottom>
                            <SmartphoneIcon
                              style={{
                                fontSize: '.8rem',
                                marginRight: '5px',
                              }}
                            />
                            {formatPhoneNumber(ident.identity_value)}
                          </Typography>
                        </Grid>
                      );
                    } else {
                      return (
                        <Grid item xs={12} key="ident-email">
                          <Typography variant="body2" gutterBottom>
                            <EmailIcon
                              style={{
                                fontSize: '.8rem',
                                marginRight: '5px',
                              }}
                            />
                            {ident.identity_value}
                          </Typography>
                        </Grid>
                      );
                    }
                  })}
                </Grid>
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Typography
              variant={desktop ? 'h5' : 'h6'}
              gutterBottom
              style={desktop ? { fontSize: '1rem' } : { fontSize: '.85rem' }}
            >
              Address
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={desktop ? { fontSize: '1rem' } : { fontSize: '.8rem' }}
            >
              {customer?.address || ''}{' '}
              {`${customer.city || ''}, ${customer.province || ''} ${
                customer.postal_code || ''
              }`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant={desktop ? 'h5' : 'h6'}
              gutterBottom
              style={desktop ? { fontSize: '1rem' } : { fontSize: '.85rem' }}
            >
              Go Card
            </Typography>
            {!gocard && (
              <Typography
                variant="body2"
                style={desktop ? { fontSize: '1rem' } : { fontSize: '.7rem' }}
              >
                Customer has no go card
              </Typography>
            )}
            {gocard && (
              <Box display="flex">
                <CreditCardIcon style={{ marginRight: '5px' }} />
                <Typography variant="body2">${gocard.cashBalance}</Typography>
                <Typography variant="body2" style={{ marginLeft: '20px' }}>
                  {gocard.cardNumber}
                </Typography>
              </Box>
            )}
          </Grid>
          {gocard && (
            <Grid item xs={12}>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Location</TableCell>
                      <TableCell>Code</TableCell>
                      <TableCell>Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {transactions.map((transaction, index) => (
                      <TableRow key={`gocard-transaction-${index}`}>
                        <TableCell>
                          {moment(transaction.transactionDate).format('L')}
                        </TableCell>
                        <TableCell>
                          {transaction.location.locationName}
                        </TableCell>
                        <TableCell>{transaction.code}</TableCell>
                        <TableCell>{transaction.amount}</TableCell>
                      </TableRow>
                    ))}
                    {transactions.length === 0 && (
                      <Typography variant="caption">No Transactions</Typography>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography
              variant={desktop ? 'h5' : 'h6'}
              style={desktop ? { fontSize: '1rem' } : { fontSize: '.85rem' }}
            >
              Vehicles
            </Typography>
          </Grid>
          {vehicles.length === 0 && (
            <Grid item xs={12}>
              <Typography
                variant="body2"
                style={desktop ? { fontSize: '1rem' } : { fontSize: '.7rem' }}
              >
                This customer has no vehicles
              </Typography>
            </Grid>
          )}
          {vehicles.length > 0 &&
            vehicles.map(vehicle => (
              <Grid item xs={12} key={vehicle.vin}>
                <span style={{ display: 'flex' }}>
                  <DriveEtaIcon
                    style={{ marginRight: '5px', fontSize: '.85rem' }}
                  />
                  <Typography
                    variant="body2"
                    style={
                      desktop ? { fontSize: '1rem' } : { fontSize: '.7rem' }
                    }
                  >
                    {`${vehicle.year} ${vehicle.make} ${vehicle.model} ${
                      vehicle.trim === 'None' ? '' : vehicle.trim
                    }`}
                  </Typography>
                </span>
              </Grid>
            ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button className={classes.button} onClick={handleClose}>
          Close
        </Button>
        <Button
          onClick={() => history.push(`/customers/${customer._id}/details`)}
          className={classes.button}
        >
          View full profile
        </Button>
      </DialogActions>
    </>
  );
};

export default CustomerDetailsModal;
