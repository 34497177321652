import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  paddingIcon: {
    paddingRight: '2px',
  },
  good: {
    color: '#74B72E',
  },
  bad: {
    color: 'red',
  },
}));

const InventoryIconGroup = ({ vehicle: { isFeatured, description } }) => {
  const { paddingIcon, good, bad } = useStyles();
  return (
    <>
      <Tooltip title={isFeatured ? 'Featured' : 'Not Featured'}>
        <i
          className={`fas fa-star ${paddingIcon} ${isFeatured ? good : bad}`}
        />
      </Tooltip>
      <Tooltip title={description ? 'Has Description' : 'Missing Description'}>
        <i
          className={`fas fa-pencil ${paddingIcon} ${description ? good : bad}`}
        />
      </Tooltip>
    </>
  );
};

export default InventoryIconGroup;
