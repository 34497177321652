/* external */
import gql from 'graphql-tag';
import moment from 'moment';
import React from 'react';

/* Material UI */
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import { useUserContext } from 'components/MaterialUI/UserContext';
import CustomerAvatar from 'components/CustomerAvatar';

import { defaultTZ } from 'utils';
import { CONFIRMED_STATUSES, Role } from 'constants.js';

import { hasNonWhiteSpaceCharacter } from '../utils';

/* internal */
import EditCustomerDetails from './EditCustomerDetails';

const useStyles = makeStyles(theme => ({
  mainTitle: {
    flexGrow: 1,
    fontWeight: 'bold',
    padding: theme.spacing(2),
  },
  fullName: {
    fontWeight: 'bold',
    fontSize: '20px',
  },
  createdDate: {
    fontSize: '12px',
  },
  appCreatedDate: {
    fontSize: '10px',
  },
  sizeAvatar: {
    height: theme.spacing(7),
    width: theme.spacing(7),
  },
  goAutoEmployee: {
    fontSize: '10px',
  },
}));

const Header = ({ customer, refetch }) => {
  const classes = useStyles();
  const { currentUser } = useUserContext();
  const { app_account: goAutoAppAcct } = customer;
  const isLocked = customer?.do_not_contact || false;
  const isHidden = currentUser.role !== Role.ORGANIZATION_ADMIN && isLocked;
  // For a user to successfully sign in the Go Auto app account, they must be BOTH confirmed and enabled!
  const customerHasConfirmedAppAcct =
    CONFIRMED_STATUSES.includes(goAutoAppAcct?.status) &&
    goAutoAppAcct?.isEnabled;
  const customerHasPendingAppAcct =
    goAutoAppAcct?.status === 'UNCONFIRMED' && goAutoAppAcct?.isEnabled;
  const customerHasGoAutoAppAcct =
    customerHasConfirmedAppAcct || customerHasPendingAppAcct;
  const customerIsGoAutoEmployee = customer?.goauto_employee;
  const isWholesaler = customer?.gocards?.some(
    gocard => gocard?.accountV2?.isWholesaler,
  );

  return (
    <Box style={{ marginBottom: '15px' }}>
      <Typography variant="h5" className={classes.mainTitle}>
        Customer Profile
      </Typography>
      <Toolbar>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            <CustomerAvatar
              customer={customer}
              className={classes.sizeAvatar}
            />
          </Grid>
          <Grid item xs={9}>
            <Grid container spacing={0}>
              <Grid item xs={12} className={classes.fullName}>
                {`${customer?.first_name}${
                  hasNonWhiteSpaceCharacter(customer?.nickname)
                    ? ` (${customer.nickname}) `
                    : ' '
                }${customer?.last_name}`}
                &nbsp; &nbsp;
                {!isHidden && (
                  <IconButton size="small">
                    <EditCustomerDetails
                      customer={customer}
                      refetch={refetch}
                    />
                  </IconButton>
                )}
              </Grid>
              <Grid item className={classes.createdDate}>
                {`Profile Created: ${moment(customer.created)
                  .tz(defaultTZ)
                  .format('MMM YYYY')}`}
              </Grid>
            </Grid>
            {customerHasGoAutoAppAcct && (
              <Grid item className={classes.appCreatedDate}>
                {`Go Auto App Account Created: ${moment(goAutoAppAcct.createdAt)
                  .tz(defaultTZ)
                  .format('MMM YYYY')}`}
              </Grid>
            )}
            {customerIsGoAutoEmployee && (
              <Grid item className={classes.goAutoEmployee}>
                {`Go Auto Employee`}
              </Grid>
            )}
            {isWholesaler && (
              <Grid item className={classes.goAutoEmployee}>
                {`Wholesaler`}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Toolbar>
    </Box>
  );
};

Header.fragments = {
  customer: gql`
    fragment CustomerDetailsHeaderFragment on Customer {
      app_account {
        username
        createdAt
        modifiedAt
        isEnabled
        status
      }
      gocards {
        id
        accountV2 {
          active
          cardId
          isWholesaler
        }
      }
      ...EditCustomerDetailsFragment
      ...CustomerAvatarCustomer
    }
    ${EditCustomerDetails.fragments.customer}
    ${CustomerAvatar.fragments.customer}
  `,
};

export default Header;
