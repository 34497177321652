import React from 'react';

/* external */
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

/* Material UI */
import Alert from '@mui/material/Alert';
import { Box, Paper } from '@mui/material';

/* internal */
import { AppraisalStatus } from 'modules/used_vehicles/const';
import { formatOdometer } from 'utils';
import AuctionVehicle from './AuctionVehicle';
import BidOrOwn from './BidOrOwn';
import Loading from 'components/MaterialUI/Loading';
import SellOrAuction from './SellOrAuction';
import SellVehicle from './SellVehicle';
import Title from '../components/Title';
import Own from './Own';

const displayName = appraisal =>
  `${appraisal.year} ${appraisal.make} ${appraisal.model} ${appraisal.trim}`;

const CreateSuccess = ({ id, auction, sell }) => {
  const GET_APPRAISAL = gql`
    query getAppraisal($id: Int!) {
      appraisals {
        appraisal(id: $id) {
          id
          ...AuctionVehicleAppraisal
          ...CreateSuccessAppraisal
          ...SellVehicleAppraisal
        }
      }
    }
    ${AuctionVehicle.fragments.appraisal}
    ${CreateSuccess.fragments.appraisal}
    ${SellVehicle.fragments.appraisal}
  `;

  const { data, loading } = useQuery(GET_APPRAISAL, {
    variables: { id },
    fetchPolicy: 'network-only', // Re-fetch the appraisal so that we ensure
    // we get all the latest info (ie. photos!)
  });

  const appraisal = data?.appraisals.appraisal || {};
  const {
    appraisalStatus,
    bodyType,
    exteriorColour,
    interiorColour,
    odometer,
    seller,
    trimVariation,
    vin,
  } = appraisal;

  const [showAuctioned, setShowAuctioned] = React.useState(auction);
  const [showSold, setShowSold] = React.useState(sell);
  const showOwned = ![AppraisalStatus.OWNED, AppraisalStatus.BIDDING].includes(
    appraisalStatus,
  );

  if (loading) return <Loading />;

  return (
    <Box height="100%">
      {showOwned && (
        <Box padding={2} paddingBottom={0}>
          <Alert severity="success">Vehicle Created Successfully!</Alert>
        </Box>
      )}
      <Title
        paddingTop={2}
        marginBottom={2}
        marginLeft={2}
        marginRight={2}
        title={
          showAuctioned
            ? 'Send to Auction'
            : showSold
            ? 'Offer to Dealer(s)'
            : showOwned
            ? 'Bid or Own'
            : 'Sell or Auction'
        }
      />
      <Box margin={2}>
        <div>
          <b style={{ paddingRight: '0.5rem' }}>{displayName(appraisal)}</b>
          <span>{trimVariation}</span>
        </div>
        <div>{vin}</div>
      </Box>
      <Box
        margin={2}
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <div>Body Style: {bodyType}</div>
          <div>Odometer: {formatOdometer(odometer)}</div>
          <div>Seller: {seller?.name}</div>
        </div>
        <div>
          <div>Exterior: {exteriorColour || 'n/a'}</div>
          <div>Interior: {interiorColour || 'n/a'}</div>
        </div>
      </Box>
      <Box margin={2}>
        <Paper>
          {appraisalStatus !== AppraisalStatus.BIDDING && (
            <>
              {showOwned && !showAuctioned && (
                <BidOrOwn appraisal={appraisal} />
              )}

              {!showOwned && !showAuctioned && !showSold && (
                <SellOrAuction
                  setShowSold={setShowSold}
                  setShowAuctioned={setShowAuctioned}
                />
              )}
              {showAuctioned && (
                <AuctionVehicle
                  appraisal={appraisal}
                  setShowAuctioned={setShowAuctioned}
                />
              )}
              {showSold && (
                <SellVehicle appraisal={appraisal} setShowSold={setShowSold} />
              )}
            </>
          )}
          {appraisalStatus === AppraisalStatus.BIDDING && (
            <Own appraisal={appraisal} />
          )}
        </Paper>
      </Box>
    </Box>
  );
};

CreateSuccess.fragments = {
  appraisal: gql`
    fragment CreateSuccessAppraisal on Appraisal {
      id
      appraisalStatus
      bodyType
      exteriorColour
      interiorColour
      make
      model
      odometer
      seller {
        name
      }
      trim
      trimVariation
      vin
      year
    }
  `,
};

export default CreateSuccess;
