import React from 'react';

/* external */
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

/* Material UI */
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';

/* internal */
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import ErrorDisplay from 'components/MaterialUI/ErrorDisplay';

const GET_CARFAX_REPORT = gql`
  query getCarFaxReports($vin: String!) {
    inventory {
      getCarFaxReports(vin: $vin) {
        Reports {
          ReportLinkUrl
          ReportExpiryDate
          ReportType
        }
      }
    }
  }
`;

const VehicleCarFaxReport = ({ handleClose, vehicle: { vin } }) => {
  const { dealerId } = useDealerContext();
  const { data, error, loading } = useQuery(GET_CARFAX_REPORT, {
    variables: { dealerId, vin },
  });
  const reports = data?.inventory?.getCarFaxReports?.Reports || [];

  const reportTypes = {
    2: 'Verified',
    3: 'VerifiedBC',
    4: 'Claims',
    5: 'Lien',
    6: 'Lien Express',
    9: 'ClaimsBC',
  };

  function formatDateString(dateString) {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const dateForm = new Date(dateString);
    dateForm.setFullYear(dateForm.getFullYear() - 1);
    return dateForm.toLocaleDateString('en-US', options);
  }

  if (error)
    return <ErrorDisplay error={error} action="Loading CarFax Reports" />;

  return (
    <div style={{ padding: '1rem 1rem 1rem 1rem' }}>
      <DialogTitle style={{ paddingLeft: '1rem' }}>CarFax Report</DialogTitle>
      {loading && (
        <>
          <p style={{ paddingLeft: '1rem' }}>Fetching Reports...</p>
          <LoadingBackdrop open={true} />
        </>
      )}
      {reports.length === 0 && !loading && (
        <p style={{ paddingLeft: '1rem' }}>
          No report(s) are available for that VIN
        </p>
      )}
      {!loading && (
        <List>
          {reports.map(report => (
            <ListItem style={{ paddingLeft: '1rem' }}>
              <ListItemText>
                {reportTypes[report.ReportType]} Report Issued On:{' '}
                {formatDateString(report.ReportExpiryDate)}
              </ListItemText>
              <Button
                variant="contained"
                color="primary"
                component="span"
                onClick={() => window.open(report.ReportLinkUrl, '_blank')}
                style={{
                  marginLeft: '1rem',
                }}
              >
                View
              </Button>
            </ListItem>
          ))}
        </List>
      )}
      <Button
        onClick={handleClose}
        variant="outlined"
        color="primary"
        style={{ marginLeft: '1rem' }}
      >
        Close
      </Button>
    </div>
  );
};

export default VehicleCarFaxReport;
