import React, { useState } from 'react';

/* external */
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import gql from 'graphql-tag';

/* Material UI */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  TextField,
} from '@mui/material';

/* internal */
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';

const ACCEPT_BUY_NOW = gql`
  mutation acceptBuyNow($id: Int!, $data: AcceptBuyNowInput) {
    appraisals {
      acceptBuyNowOffer(id: $id, data: $data) {
        id
        offerStatus
        acceptedAt
        acceptedBy
        appraisal {
          id
          price
          appraisalStatus
          soldAt
          purchasingDealerId
        }
      }
    }
  }
`;

const AcceptBuyNowDialog = ({ open, onClose, id, dealerName, buyNowPrice }) => {
  const [acceptBuyNow, acceptBuyNowMutation] = useMutation(ACCEPT_BUY_NOW);
  const [price, setPrice] = useState(buyNowPrice);
  const { enqueueSnackbar } = useSnackbar();

  const successHandler = () => {
    enqueueSnackbar(`Accepted buy now offer from ${dealerName}!`, {
      variant: 'success',
    });
    onClose();
  };

  // TODO: update error handling
  const failureHandler = err =>
    enqueueSnackbar(err.message ?? JSON.stringify(err), { variant: 'error' });

  const handleClickConfirm = () =>
    acceptBuyNow({
      variables: { id, data: { price: price ? parseFloat(price) : null } },
    }).then(successHandler, failureHandler);

  return (
    <Dialog open={open} onClose={onClose}>
      <LoadingBackdrop open={acceptBuyNowMutation.loading}>
        Accepting...
      </LoadingBackdrop>
      <DialogTitle>Accept Offer From {dealerName}</DialogTitle>
      <DialogContent>
        <TextField
          label="Price"
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          value={price}
          type="number"
          onChange={e => setPrice(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleClickConfirm}>Accept</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AcceptBuyNowDialog;
