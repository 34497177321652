/* external */
import gql from 'graphql-tag';
import React from 'react';

/* Material UI */
import { Box, ButtonBase, Chip, Icon, TableCell, Tooltip } from '@mui/material';
import {
  LocalParking as LocalParkingIcon,
  Star as StarIcon,
  Warning as WarningIcon,
} from '@mui/icons-material';

/* internal */
import { formatOdometer, newWindowUrlHandler } from 'utils';

const IconSpacer = () => (
  <Box component="span" width="24px" paddingLeft="24px" />
);

const ItemDescriptionCell = ({
  carfax,
  item,
  isWinning,
  isOutbid,
  hasProxy,
}) => {
  const {
    vehicle: {
      appraisalTransitStatus,
      make,
      model,
      odometer,
      stockNumber,
      vin,
      year,
      locatedProvince,
    },
  } = item;

  const { badgeUrl, reportUrl } = carfax ?? {};

  const { arrivedAt, inTransit } = appraisalTransitStatus ?? {};

  return (
    <TableCell>
      <Box fontWeight="bold">
        {year} {make} {model}
      </Box>
      <Box>
        {vin} /{' '}
        <Box component="span" color="#999999">
          {formatOdometer(odometer)}
        </Box>
        {inTransit && !arrivedAt && (
          <Box>
            <Chip
              icon={
                <Icon
                  className="fas fa-shipping-timed"
                  style={{
                    paddingLeft: '2px',
                    color: 'white',
                    fontSize: '15px',
                    width: '20px',
                  }}
                />
              }
              size="small"
              label="In Transit"
              style={{ backgroundColor: 'orange', color: 'white' }}
            />
          </Box>
        )}
        {!inTransit && arrivedAt && (
          <Box>
            <Chip
              icon={
                <Icon
                  className="fas fa-garage-car"
                  style={{
                    color: 'white',
                    fontSize: '15px',
                    paddingLeft: '2px',
                    width: '20px',
                  }}
                />
              }
              size="small"
              label="On Ground"
              style={{ backgroundColor: 'green', color: 'white' }}
            />
          </Box>
        )}
        <Box>
          <b>Located:</b> {locatedProvince ?? ''}
        </Box>
        {stockNumber ? ` / ${stockNumber}` : null}
        <Box>
          {isWinning ? (
            <Tooltip title="#winning">
              <StarIcon style={{ color: 'green' }} />
            </Tooltip>
          ) : isOutbid ? (
            <Tooltip title="outbid">
              <WarningIcon color="secondary" />
            </Tooltip>
          ) : (
            <IconSpacer />
          )}
          {hasProxy ? (
            <Tooltip title="proxy">
              <LocalParkingIcon color="primary" />
            </Tooltip>
          ) : (
            <IconSpacer />
          )}
        </Box>
      </Box>
      <Box paddingBottom="3px">
        {badgeUrl ? (
          <ButtonBase onClick={newWindowUrlHandler(reportUrl)}>
            <img src={badgeUrl} alt="badgeImage" />
          </ButtonBase>
        ) : (
          <Box height="30px">&nbsp;</Box>
        )}
      </Box>
    </TableCell>
  );
};
ItemDescriptionCell.fragments = {
  auctionItem: gql`
    fragment ItemDescriptionCellAuctionItem on AuctionItem {
      vehicle {
        id
        make
        model
        odometer
        stockNumber
        vin
        year
        locatedProvince
        appraisalTransitStatus {
          id
          arrivedAt
          inTransit
          appraisalStatus
        }
      }
    }
  `,
};

export default ItemDescriptionCell;
