import React from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';

import CircularProgress from '@mui/material/CircularProgress';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { CSV_DEALER_OPPORTUNITIES } from './queries';

const DealLogBulkActions = ({
  anchorEl,
  filters,
  generateFilters,
  handleAnchorClose,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [getCSV, { loading }] = useLazyQuery(CSV_DEALER_OPPORTUNITIES, {
    onCompleted: data => {
      window.open(data.opportunitiesCSV.key, '_blank');
      enqueueSnackbar('CSV downloaded successfully', {
        variant: 'success',
      });
      handleAnchorClose();
    },
    onError: e => {
      enqueueSnackbar(`Error generating CSV: ${e}`, {
        variant: 'error',
      });
    },
  });

  return (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={() => handleAnchorClose()}
      style={{ width: '200px' }}
    >
      {loading ? (
        <MenuItem disabled>
          <ListItemIcon style={{ minWidth: '25px' }}>
            <CircularProgress size={20} />
          </ListItemIcon>
          <ListItemText primary="Generating CSV..." />
        </MenuItem>
      ) : (
        <MenuItem
          disabled={loading}
          onClick={() => getCSV({ variables: { ...generateFilters(filters) } })}
        >
          Export CSV
        </MenuItem>
      )}
    </Menu>
  );
};

export default DealLogBulkActions;
