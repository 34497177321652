import moment from 'moment';

import { AppointmentStatus, Status } from 'constants.js';

export const getSalesDashboardNumbers = data => {
  let openOpportunitiesCount = 0;
  let completedOpportunitiesCount = 0;
  let tubedOpportunitiesCount = 0;
  let lostOpportunitiesCount = 0;
  let pendingDealsCount = 0;
  let bookedDealsCount = 0;
  let opp_facets = [];
  let lost_facets = [];
  let openOpps = [];
  let completedOpps = [];
  let tasks = [];
  let appointments = [];
  let upcomingCount = [];
  let createdToday = [];
  let attendedCount = [];
  let createdTodayCount = 0;
  let tasksCount = 0;
  let appointmentDateRangeCount = 0;

  opp_facets = data?.getSalesDashboardStatistics?.open_opportunity_facets;
  lost_facets = data?.getSalesDashboardStatistics?.lost_opportunity_facets;

  for (let i = 0; i < opp_facets?.length; i++) {
    if (Status.OPEN_OPPS.includes(parseInt(opp_facets[i]?.status_id, 10))) {
      openOpportunitiesCount += opp_facets[i].total;
    }
    if (Status.COMPLETED.includes(parseInt(opp_facets[i]?.status_id, 10))) {
      completedOpportunitiesCount += opp_facets[i].total;
    }
    if (Status.TUBED === parseInt(opp_facets[i]?.status_id, 10)) {
      tubedOpportunitiesCount += opp_facets[i].total;
    }
    if (Status.LOST === parseInt(lost_facets[i]?.status_id, 10)) {
      lostOpportunitiesCount += lost_facets[i].total;
    }
    if (Status.PENDING === parseInt(opp_facets[i]?.status_id, 10)) {
      pendingDealsCount += opp_facets[i].total;
    }
    if (Status.OPEN.includes(parseInt(opp_facets[i]?.status_id, 10))) {
      bookedDealsCount += opp_facets[i].total;
    }
  }

  openOpps = data?.getSalesDashboardStatistics?.top_ten_open_opps || [];
  completedOpps = data?.getSalesDashboardStatistics?.top_ten_posted_opps || [];
  tasks = data?.getSalesDashboardStatistics?.top_ten_pending_tasks || [];
  appointments = data?.getSalesDashboardStatistics?.todays_appointments || [];
  tasksCount =
    data?.getSalesDashboardStatistics?.pending_tasks_count?.status?.find(
      x => x._id === '0',
    )?.total || 0;

  upcomingCount =
    data?.getSalesDashboardStatistics?.appointment_counts.filter(
      x => x._id === AppointmentStatus.UNKNOWN,
    )[0]?.upcoming || 0;
  createdToday = data?.getSalesDashboardStatistics?.appointment_counts;
  attendedCount =
    data?.getSalesDashboardStatistics?.appointment_counts.filter(
      x => x._id === AppointmentStatus.ATTENDED,
    )[0]?.last7days || 0;

  for (let i = 0; i < createdToday?.length; i++) {
    createdTodayCount += createdToday[i].today;
    appointmentDateRangeCount += createdToday[i].date_range;
  }

  return {
    openOpportunitiesCount,
    completedOpportunitiesCount,
    tubedOpportunitiesCount,
    lostOpportunitiesCount,
    pendingDealsCount,
    bookedDealsCount,
    openOpps,
    completedOpps,
    tasks,
    appointments,
    tasksCount,
    upcomingCount,
    attendedCount,
    createdTodayCount,
    appointmentDateRangeCount,
  };
};

export const getManagerDashboardStatistics = data => {
  let upcomingCount = [];
  let createdToday = [];
  let attendedCount = [];
  let createdTodayCount = 0;

  upcomingCount =
    data?.appointment_counts.filter(x => x._id === AppointmentStatus.UNKNOWN)[0]
      ?.upcoming || 0;
  createdToday = data?.appointment_counts;
  attendedCount =
    data?.appointment_counts.filter(
      x => x._id === AppointmentStatus.ATTENDED,
    )[0]?.last7days || 0;
  for (let i = 0; i < createdToday?.length; i++) {
    createdTodayCount += createdToday[i].today;
  }

  return {
    upcomingCount,
    attendedCount,
    createdTodayCount,
  };
};

export const getInventoryNumbers = (inventoryData, days) => {
  const { newVehicles, usedVehicles } = inventoryData?.inventory || {};
  const getNumbers = (field, op, vehicles) =>
    vehicles
      ?.find(x => x.field === field)
      ?.data?.filter(op)
      ?.reduce((acc, curr) => acc + curr.count, 0) || 0;

  const op_gt = yvalue => x => x.value > yvalue;
  const op_lt = yvalue => x => x.value < yvalue;
  const op_eq = yvalue => x => x.value === yvalue;

  const newInStock = getNumbers('name', op_eq('in stock'), newVehicles);
  const usedInStock = getNumbers('name', op_eq('in stock'), usedVehicles);
  const totalIncomplete =
    getNumbers('is_missing_description', op_eq('True'), newVehicles) +
    getNumbers('is_missing_description', op_eq('True'), usedVehicles) +
    getNumbers('is_missing_photos', op_eq('True'), newVehicles) +
    getNumbers('is_missing_photos', op_eq('True'), usedVehicles);

  const inStockGt =
    getNumbers('days_in_stock', op_gt(days), newVehicles) +
    getNumbers('days_in_stock', op_gt(days), usedVehicles);

  const lastPriceChangeLt =
    getNumbers(
      'last_price_change',
      op_lt(moment().subtract(days, 'days').format('YYYY-MM-DD')),
      newVehicles,
    ) +
    getNumbers(
      'last_price_change',
      op_lt(moment().subtract(days, 'days').format('YYYY-MM-DD')),
      usedVehicles,
    );

  return {
    newVehiclesInStock: newInStock,
    usedVehiclesInStock: usedInStock,
    totalIncomplete,
    inStockGt,
    lastPriceChangeLt,
  };
};

export const getDaysInStockFilter = days => [
  {
    model: 'Vehicle',
    field: 'days_in_stock',
    op: '>',
    value: days,
  },
];

export const getLastPriceChangeFilter = date => [
  {
    model: 'Vehicle',
    field: 'last_price_change',
    op: '<',
    value: date,
  },
];
