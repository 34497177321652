// External
import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import moment from 'moment-timezone';
import { useHistory } from 'react-router-dom';

// Material UI
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import useMediaQuery from '@mui/material/useMediaQuery';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';

// Internal
import Loading from 'components/MaterialUI/Loading';
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import DealerPicker from 'components/MaterialUI/DealerPicker';

const LEADS_SUMMARY_BY_TYPE = gql`
  query LeadsByType($dealerIds: [Int]!) {
    activeLeads: leads(
      page_size: 1
      page: 1
      query: { is_archived: false, created: {gte: "${moment()
        .startOf('month')
        .toISOString()}" } }
      filters: { dealer_id: $dealerIds, type: [] }
      with_facets: true
    ) {
      total
      facets {
        type {
          value
          total
          typeName
        }
      }
    }
    activeLeadsNotReplied: leads(
      page_size: 1
      page: 1
      query: { is_archived: false, is_responded: false, created: {gte: "${moment()
        .startOf('month')
        .toISOString()}" } }
      filters: { dealer_id: $dealerIds, type: [] }
      with_facets: true
    ) {
      total
      facets {
        type {
          value
          total
          typeName
        }
      }
    }
    newLeads: leads(
      page_size: 1
      page: 1
      query: { created: {gte: "${moment().startOf('day').toISOString()}" } }
      filters: { dealer_id: $dealerIds, type: [] }
      with_facets: true
    ) {
      total
      facets {
        type {
          value
          total
          typeName
        }
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 750,
  },
  table: {
    [theme.breakpoints.up('md')]: {
      minWidth: 750,
    },
  },
  card: {
    minWidth: 200,
    '& .MuiCardContent-root:last-child': {
      paddingBottom: 16,
    },
    '& h1': {
      marginBottom: 10,
    },
  },
}));

const LeadDashboard = () => {
  const classes = useStyles();
  const { dealers, dealerId } = useDealerContext();
  const isDesktop = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const history = useHistory();

  const [{ dealer_name }] = dealers.filter(
    dealer => dealer.dealer_id === dealerId,
  );

  const { data, loading } = useQuery(LEADS_SUMMARY_BY_TYPE, {
    variables: {
      dealerIds: [dealerId],
    },
    notifyOnNetworkStatusChange: true,
  });

  const { activeLeads, activeLeadsNotReplied, newLeads } = data || [];

  const [rows, setRows] = useState(null);

  useEffect(() => {
    if (activeLeads) {
      const leadTypes = [
        ...new Set([
          ...activeLeads?.facets.type.map(f => f.value),
          ...activeLeadsNotReplied?.facets.type.map(f => f.value),
          ...newLeads?.facets.type.map(f => f.value),
        ]),
      ];
      setRows(
        leadTypes
          .sort()
          .map(type => ({ type }))
          .map(leadType => {
            const facet = activeLeads.facets.type.find(
              f => f.value === leadType.type,
            );
            return {
              ...leadType,
              typeName: facet && facet.typeName,
              activeLeads: (facet && facet.total) || 0,
            };
          })
          .map(leadType => {
            const facet = activeLeadsNotReplied.facets.type.find(
              f => f.value === leadType.type,
            );
            return {
              ...leadType,
              typeName: (facet && facet.typeName) || leadType.typeName,
              activeLeadsNotReplied: (facet && facet.total) || 0,
            };
          })
          .map(leadType => {
            const facet = newLeads.facets.type.find(
              f => f.value === leadType.type,
            );
            return {
              ...leadType,
              typeName: (facet && facet.typeName) || leadType.typeName,
              newLeads: (facet && facet.total) || 0,
            };
          }),
      );
    }
  }, [activeLeads, activeLeadsNotReplied, newLeads, setRows]);

  return (
    <Box m={1}>
      <Box>
        <DealerPicker />
      </Box>
      <Box className={classes.root}>
        {loading && <Loading />}
        {!loading && (
          <Box>
            <Typography
              style={{
                fontWeight: '700',
                fontSize: '1.5rem',
                padding: '0.5rem 0',
              }}
            >
              Leads Summary for {dealer_name} (MTD)
            </Typography>
            <Grid
              container
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Grid item sm={4}>
                <ButtonBase focusRipple>
                  <Card
                    onClick={() => history.push('/leads?filters=today')}
                    className={classes.card}
                  >
                    <CardContent>
                      <h1>{newLeads?.total}</h1>
                      <h3>Total New Today</h3>
                    </CardContent>
                  </Card>
                </ButtonBase>
              </Grid>
              <Grid item sm={4} style={{ textAlign: 'center' }}>
                <ButtonBase focusRipple>
                  <Card
                    onClick={() => history.push('/leads?filters=active')}
                    className={classes.card}
                  >
                    <CardContent>
                      <h1>{activeLeads.total}</h1>
                      <h3>Total Active</h3>
                    </CardContent>
                  </Card>
                </ButtonBase>
              </Grid>
              <Grid
                item
                sm={4}
                style={{
                  textAlign: 'right',
                  marginTop: `${isDesktop ? 0 : '.5rem'}`,
                }}
              >
                <ButtonBase focusRipple>
                  <Card
                    onClick={() => history.push('/leads?filters=notReplied')}
                    className={classes.card}
                  >
                    <CardContent>
                      <h1>{activeLeadsNotReplied.total}</h1>
                      <h3>Total Not Replied</h3>
                    </CardContent>
                  </Card>
                </ButtonBase>
              </Grid>
            </Grid>
            {rows && rows.length > 0 && (
              <TableContainer>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ borderBottom: 'none' }}></TableCell>
                      <TableCell
                        align="center"
                        style={{ fontSize: '.8rem', borderBottom: 'none' }}
                      >
                        New Today
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ fontSize: '.8rem', borderBottom: 'none' }}
                      >
                        Active
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ fontSize: '.8rem', borderBottom: 'none' }}
                      >
                        Not Replied
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows?.map((row, index) => (
                      <TableRow
                        hover
                        key={index}
                        onClick={() =>
                          history.push(`/leads?filters=${row.type}`)
                        }
                      >
                        <TableCell align="center">
                          <b>{row.typeName ?? row.type}</b>
                        </TableCell>
                        <TableCell align="center">{row.newLeads}</TableCell>
                        <TableCell align="center">{row.activeLeads}</TableCell>
                        <TableCell align="center">
                          {row.activeLeadsNotReplied}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default LeadDashboard;
