export const SMS_PERMISSION_TYPES = [
  { id: 'operator', permission_name: 'Operator' },
  { id: 'manager', permission_name: 'Manager' },
];

export const GATEWAY_TYPES = [
  { name: 'Twilio', value: 'twilio' },
  { name: 'Flex', value: 'flex' },
  { name: 'External', value: 'external' },
  { name: 'Dummy', value: 'dummy' },
];
