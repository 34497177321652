import React from 'react';
import Carousel from 'react-material-ui-carousel';
import { formatPhoto } from '../utils';
import { useMediaQuery } from '@mui/material';

const ImageCarousel = ({ photos, formatPhotoOptions = {}, ...rest }) => {
  /* TODO: Move this to a common location, or replace with a better common component */
  const isDesktop = useMediaQuery(theme => theme.breakpoints.up('sm'));

  const options = {
    width: `${isDesktop ? 750 : 300}`,
    ...formatPhotoOptions,
  };

  return (
    <Carousel {...rest}>
      {photos.map(photo => (
        <img
          alt="Vehicle"
          key={photo.id}
          style={{
            paddingTop: '1rem',
          }}
          src={formatPhoto(photo, options)}
        />
      ))}
    </Carousel>
  );
};

export default ImageCarousel;
