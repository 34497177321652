import React from 'react';

/* external */
import { useSubscription } from '@apollo/react-hooks';
import gql from 'graphql-tag';

/* Material UI */
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material';

/* internal */
import PitchCard from 'modules/pitches/components/PitchCard';
import PitchForm from 'modules/pitches/components/PitchForm';
import { lengthOrNull } from 'utils';
import {
  ResponsiveCard,
  ResponsiveCardContent,
  ResponsiveCardHeader,
} from 'components/MaterialUI/ResponsiveCard';

const PITCHES_SUBSCRIPTION = gql`
  subscription pitchesSubscription($pitchIds: [Int!]!) {
    pitchUpdated(pitchIds: $pitchIds) {
      type
      pitch {
        id
        dateCreated
        ...PitchCardPitch
        ...PitchFormPitch
      }
    }
  }
  ${PitchCard.fragments.pitch}
  ${PitchForm.fragments.pitch}
`;

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '50px',
  },
  title: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 18,
    },
    fontWeight: 'bold',
  },
  addIcon: {
    padding: '0px',
    margin: '0px 5px 0px 10px',
  },
}));

const OpportunityPitches = ({
  customer,
  onAddPitch,
  onEditPitch,
  opportunity,
  pitches,
  refetchOpportunity,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const pitchIds = pitches.map(({ id }) => id);
  // There's probably a really cool way of merging in the nested data for publishings
  // into the cache, but honestly, it's just not worth it.  If we get a subscription
  // message that the pitch has updated, reload the opp.  Easy.
  // Maybe not efficient, but easy.
  useSubscription(PITCHES_SUBSCRIPTION, {
    variables: { pitchIds },
    onSubscriptionData: () => refetchOpportunity(),
  });

  const { primary_new_pitch_id: primaryPitchId } = opportunity;

  const pitchSorter = (a, b) =>
    // "favourite" (ie. primary) first, then order by newest
    String(a.id) === primaryPitchId
      ? -1
      : String(b.id) === primaryPitchId
      ? 1
      : b.dateCreated.localeCompare(a.dateCreated);

  return (
    <ResponsiveCard>
      <ResponsiveCardHeader style={{ marginBottom: '10px' }}>
        <Box display="flex" alignItems="center">
          <Box component="span" className={classes.title}>
            Pitches {lengthOrNull(pitches)}
          </Box>
          <Box component="span" marginLeft={3}>
            <IconButton onClick={onAddPitch} size="small">
              <AddIcon style={theme.actions.add} />
            </IconButton>
          </Box>
        </Box>
      </ResponsiveCardHeader>
      <ResponsiveCardContent>
        {!pitches?.length && 'There are no pitches for this opportunity'}
        {pitches
          .filter(x => x)
          .sort(pitchSorter)
          .map(pitch => (
            <PitchCard
              customer={customer}
              key={pitch.id}
              opportunity={opportunity}
              onEditPitch={onEditPitch}
              pitch={pitch}
              refetchOpportunity={refetchOpportunity}
            />
          ))}
      </ResponsiveCardContent>
    </ResponsiveCard>
  );
};

OpportunityPitches.fragments = {
  pitch: gql`
    fragment OpportunityPitchesPitch on Pitch {
      id
      dateCreated
      ...PitchCardPitch
      ...PitchFormPitch
    }
    ${PitchCard.fragments.pitch}
    ${PitchForm.fragments.pitch}
  `,
  opportunity: gql`
    fragment OpportunityPitchesOpportunity on Opportunity {
      primary_new_pitch_id
    }
  `,
};

export default OpportunityPitches;
