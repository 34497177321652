import React, { useState } from 'react';

import gql from 'graphql-tag';
import { useSnackbar } from 'notistack';
import { formatPhoneNumber } from 'utils';

import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';

import Identities from './Identities';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

const ADD_IDENTITY = gql`
  mutation addIdentity($identity: CustomerIdentityInput) {
    addCustomerIdentity(identity: $identity) {
      _id
      ...IdentitiesFragment
    }
  }
  ${Identities.fragments.customer}
`;

const CUSTOMER_BY_IDENTITY = gql`
  query customerByIdentity($identityType: String!, $identityValue: String!) {
    customerByIdentity(
      identityType: $identityType
      identityValue: $identityValue
    ) {
      _id
    }
  }
`;

const REMOVE_IDENTITY = gql`
  mutation removeIdentity($identity: CustomerIdentityInput) {
    removeCustomerIdentity(identity: $identity) {
      _id
      ...IdentitiesFragment
    }
  }
  ${Identities.fragments.customer}
`;

const UPDATE_CUSTOMER = gql`
  mutation updateCustomerDetails($id: ID!, $input: UpdateCustomerInput!) {
    updateCustomer(_id: $id, input: $input) {
      _id
      address
      cell_phone
      company_name
      city
      emails {
        email
      }
      first_name
      fullname
      home_phone
      last_name
      postal_code
      province
      salutation
      work_phone
      phone
      date_of_birth
    }
  }
`;

const Profile = ({ customer, refetch }) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [modalOpen, setModalOpen] = useState(false);
  const handleModalClose = () => setModalOpen(false);
  const [updateCustomer] = useMutation(UPDATE_CUSTOMER, {
    onCompleted: data => {
      enqueueSnackbar('Successfully updated customer profile.', {
        variant: 'success',
      });
    },
    onError: e =>
      enqueueSnackbar(`Error in updating customer profile`, {
        variant: 'error',
      }),
  });

  const [getCustomerByIdentity, getCustomerByIdentityQuery] =
    useLazyQuery(CUSTOMER_BY_IDENTITY);

  const [addIdentity] = useMutation(ADD_IDENTITY, {
    onCompleted: data => {
      enqueueSnackbar('Identity added', {
        variant: 'success',
      });
      refetch();
    },
  });
  const [removeIdentity] = useMutation(REMOVE_IDENTITY, {
    onCompleted: data => {
      enqueueSnackbar('Identity Removed', {
        variant: 'success',
      });
      refetch();
    },
    onError: e =>
      enqueueSnackbar(`Unable to remove identity for this profile.`, {
        variant: 'error',
      }),
  });

  const handleAddIdentity = (identityType, identityValue) => {
    addIdentity({
      variables: {
        identity: {
          customer_id: customer._id,
          identity_type: identityType,
          identity_value: identityValue,
        },
      },
    }).catch(e => {
      // 409 is the error code for duplicate identity
      if (e.message.includes('409')) {
        getCustomerByIdentity({
          variables: {
            identityType,
            identityValue,
          },
        });
        setModalOpen(true);
      }
    });
  };

  const handleRemoveIdentity = (identityType, identityValue) => {
    if (identityType === 'sms') {
      ['home_phone', 'cell_phone', 'phone'].forEach(item => {
        if (
          formatPhoneNumber(customer[item]) === formatPhoneNumber(identityValue)
        ) {
          if (
            window.confirm(
              'Do you also want to remove this phone number from customer profile?',
            )
          ) {
            updateCustomer({
              variables: {
                id: customer._id,
                input: {
                  [item]: '',
                },
              },
            });
          }
        }
      });
    }
    if (
      identityType === 'email' &&
      identityValue === customer?.emails[0]?.email
    ) {
      if (
        window.confirm(
          'Do you also want to remove this email from customer profile?',
        )
      ) {
        updateCustomer({
          variables: {
            id: customer._id,
            input: {
              emails: [{ email: '' }],
            },
          },
        });
      }
    }

    removeIdentity({
      variables: {
        identity: {
          customer_id: customer._id,
          identity_type: identityType,
          identity_value: identityValue,
        },
      },
    });
  };

  const handleRouteMerge = () => {
    const otherCustomerId =
      getCustomerByIdentityQuery?.data?.customerByIdentity?._id;

    const ids = [customer._id, otherCustomerId];
    history.push(`/customers/merge/${ids}`);
  };

  return (
    <>
      <Dialog open={modalOpen} onClose={handleModalClose} maxWidth="md">
        <DialogTitle>Merge Customers?</DialogTitle>
        <DialogContent>
          The identity you tried to add is already attached to a customer. Would
          you like to merge this customer with the pre-existing one?
        </DialogContent>
        <DialogActions style={{ margin: '5px' }}>
          <Button onClick={handleModalClose} color="primary" variant="outlined">
            No
          </Button>
          <Button
            onClick={handleRouteMerge}
            color="primary"
            variant="contained"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Identities
        modalOpen={modalOpen}
        handleModalClose={handleModalClose}
        customer={customer}
        onAdd={handleAddIdentity}
        onDelete={(identityType, identityValue) =>
          handleRemoveIdentity(identityType, identityValue)
        }
      />
    </>
  );
};

Profile.fragments = {
  customer: gql`
    fragment ProfileFragment on Customer {
      _id
      ...IdentitiesFragment
    }
    ${Identities.fragments.customer}
  `,
};
export default Profile;
