/* external */
import { FormProvider, useForm } from 'react-hook-form';
import gql from 'graphql-tag';
import React from 'react';

import { Box } from '@mui/material';

import { LoadingBackdrop, useDealerContext } from 'components/MaterialUI';

import {
  ServicesGrid,
  SizesWeightsGrid,
  ExteriorGrid,
  InteriorGrid,
} from '../common';

import StepButtons from './StepButtons';

const UnitSpecs = ({ back, updateLoading, updateNpvVehicle, vehicle }) => {
  const { dealerId } = useDealerContext();
  const formMethods = useForm({
    defaultValues: {
      dealerId,
      specGenerator: vehicle?.specGenerator,
      specFreshWaterGallons: vehicle?.specFreshWaterGallons,
      specGreyWaterGallons: vehicle?.specGreyWaterGallons,
      specBlackWaterGallons: vehicle?.specBlackWaterGallons,
      specElectricalServiceAmps: vehicle?.specElectricalServiceAmps,
      specFurnaceBtu: vehicle?.specFurnaceBtu,
      specAcBtu: vehicle?.specAcBtu,
      specLpTankCount: vehicle?.specLpTankCount,
      specLpTankPounds: vehicle?.specLpTankPounds,
      specWaterHeaterGallons: vehicle?.specWaterHeaterGallons,
      specWaterHeaterType: vehicle?.specWaterHeaterType,
      specLengthInches: vehicle?.specLengthInches,
      specLengthFeet: vehicle?.specLengthFeet,
      specExtWidthInches: vehicle?.specExtWidthInches,
      specExtWidthFeet: vehicle?.specExtWidthFeet,
      specExtHeightInches: vehicle?.specExtHeightInches,
      specExtHeightFeet: vehicle?.specExtHeightFeet,
      specIntHeightInches: vehicle?.specIntHeightInches,
      specIntHeightFeet: vehicle?.specIntHeightFeet,
      specTireSize: vehicle?.specTireSize,
      specHitchPounds: vehicle?.specHitchPounds,
      specAxlePounds: vehicle?.specAxlePounds,
      specAxleCount: vehicle?.specAxleCount,
      specGvwrPounds: vehicle?.specGvwrPounds,
      specDryWeightPounds: vehicle?.specDryWeightPounds,
      specCargoPounds: vehicle?.specCargoPounds,
      exteriorColourName: vehicle?.exteriorColourName,
      specSlideCount: vehicle?.specSlideCount,
      specAwningCount: vehicle?.specAwningCount,
      specAwningInfo: vehicle?.specAwningInfo,
      interiorColourName: vehicle?.interiorColourName,
      specSleepCount: vehicle?.specSleepCount,
      specAvailableBeds: vehicle?.specAvailableBeds,
      specBunkCount: vehicle?.specBunkCount,
      specShowerSize: vehicle?.specShowerSize,
      specShowerType: vehicle?.specShowerType,
      specFridgeType: vehicle?.specFridgeType,
      specFridgeCuFt: vehicle?.specFridgeCuFt,
      specConvectionOven: vehicle?.specConvectionOven ?? false,
      specCooktopBurnerCount: vehicle?.specCooktopBurnerCount,
      specWasherAvailable: vehicle?.specWasherAvailable ?? false,
      specDryerAvailable: vehicle?.specDryerAvailable ?? false,
      specTvInfo: vehicle?.specTvInfo,
    },
  });

  const onSubmit = data => {
    const variables = {
      id: vehicle.id,
      data,
    };
    updateNpvVehicle({ variables });
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <LoadingBackdrop open={updateLoading} />
        <Box>
          <ServicesGrid />
          <SizesWeightsGrid />
          <ExteriorGrid />
          <InteriorGrid />
        </Box>
        <StepButtons back={back} />
      </form>
    </FormProvider>
  );
};

UnitSpecs.fragments = {
  vehicle: gql`
    fragment NpvVehicleSpecs on NpvVehicle {
      id
      ...NpvVehicleSpecsServices
      ...NpvVehicleSpecsSizesWeights
      ...NpvVehicleSpecsExterior
      ...NpvVehicleSpecsInterior
    }
    ${ServicesGrid.fragments.vehicle}
    ${SizesWeightsGrid.fragments.vehicle}
    ${ExteriorGrid.fragments.vehicle}
    ${InteriorGrid.fragments.vehicle}
  `,
};

export default UnitSpecs;
