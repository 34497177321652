import { startCase, toLower } from 'lodash';

const ascSorter = ({ value: a }, { value: b }) => a.localeCompare(b);
const descSorter = (a, b) => -ascSorter(a, b);
const DAYS_IN_STOCK_ORDER = ['>120', '91-120', '61-90', '31-60', '0-30'];
const daysInStockSorter = ({ value: a }, { value: b }) =>
  DAYS_IN_STOCK_ORDER.findIndex(x => x === a) <
  DAYS_IN_STOCK_ORDER.findIndex(x => x === b)
    ? -1
    : 1;
const titleCase = x => startCase(toLower(x));

export const GROUP_INVENTORY_FACET_OPTIONS = [
  {
    field: 'name',
    model: 'Dealer',
    title: 'Dealership',
  },
  {
    field: 'city_name',
    model: 'Dealer',
    title: 'City',
  },
  {
    field: 'stock_type',
    model: 'Vehicle',
    title: 'Stock Type',
    options: {
      format: titleCase,
    },
  },
  {
    field: 'name',
    model: 'StockStatus',
    title: 'Stock Status',
    options: {
      format: titleCase,
    },
  },
  {
    field: 'days_in_stock_category',
    model: 'Vehicle',
    title: 'Days in Stock',
    options: {
      sort: daysInStockSorter,
    },
  },
  {
    field: 'list_price',
    model: 'Vehicle',
    title: 'Price',
    options: {
      rangeInput: true,
    },
  },
  {
    field: 'year',
    model: 'Vehicle',
    title: 'Year',
    options: {
      sort: descSorter,
    },
  },
  {
    field: 'name',
    model: 'Make',
    title: 'Make',
  },
  {
    field: 'name',
    model: 'Model',
    title: 'Model',
  },
  {
    field: 'name',
    model: 'BodyType',
    title: 'Body Type',
  },
  {
    field: 'passengers',
    model: 'Vehicle',
    title: 'Passengers',
  },
];

export const INVENTORY_FACET_OPTIONS = [
  {
    field: 'stock_type',
    model: 'Vehicle',
    title: 'Stock Type',
    options: {
      format: titleCase,
    },
  },
  {
    field: 'days_in_stock_category',
    model: 'Vehicle',
    title: 'Days in Stock',
    options: {
      sort: daysInStockSorter,
    },
  },
  {
    field: 'name',
    model: 'StockStatus',
    title: 'Stock Status',
    options: {
      format: titleCase,
    },
  },
  {
    field: 'year',
    model: 'Vehicle',
    title: 'Year',
    options: {
      sort: descSorter,
    },
  },
  {
    field: 'name',
    model: 'Make',
    title: 'Make',
  },
  {
    field: 'name',
    model: 'Model',
    title: 'Model',
  },
  {
    field: 'manufacturer_code',
    model: 'Vehicle',
    title: 'Manufacturer Code',
    options: {
      comboBox: true,
      comboBoxTitle: 'Code',
      filterNull: true,
    },
  },
  {
    field: 'trim',
    model: 'Vehicle',
    title: 'Trim',
  },
  {
    field: 'name',
    model: 'BodyType',
    title: 'Body Type',
  },
  {
    field: 'name',
    model: 'FuelType',
    title: 'Fuel',
  },
  {
    field: 'engine_litres',
    model: 'Vehicle',
    title: 'Engine Litres',
  },
];
