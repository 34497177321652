import { createTheme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#2D3047',
    },
    secondary: {
      // this is the same as GoAuto Orange #FF5A00
      main: 'rgb(255, 90, 0)',
    },
    default: {
      // Add a custom default button color (mui v4)
      main: grey[300],
      dark: grey[400],
      contrastText: 'black',
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          margin: '8px',
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'contained', color: 'default' },
          style: {
            color: 'black',
            backgroundColor: grey[300],
            '&:hover': {
              backgroundColor: grey[400],
            },
          },
        },
      ],
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: 'rgba(255, 90, 0, 0.08)', // colour from mui v4
            '&:hover': {
              backgroundColor: 'rgba(255, 90, 0, 0.08)',
            },
            '& .MuiTableCell-root': {
              color: '#000',
            },
          },
        },
      },
    },
  },
  zIndex: {
    appBar: 1200,
    drawer: 1300,
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Open Sans"',
      '"Graphik"',
      'sans-serif',
    ].join(','),
  },
  colours: {
    goAutoOrange: '#FF5A00',
    add: '#24A0ED',
    greys: {
      grey1: '#333333',
      grey2: '#666666',
      grey3: '#cccccc',
      grey4: '#d7d7d7',
      grey5: '#fafafa',
      grey6: '#f2f2f2',
      grey7: '#f3f3f3',
      greytext1: '#666666',
      greytext2: '#D6D6D6',
    },
  },
  actions: {
    add: {
      backgroundColor: '#24A0ED',
      fill: 'white',
      color: 'white',
      borderRadius: '2px',
    },
    confirm: {
      color: 'white',
      backgroundColor: '#74B72E',
    },
    create: {
      backgroundColor: 'white',
      color: '#2196F3',
    },
    info: {
      backgroundColor: '#2196f3',
    },
    filter: {
      backgroundColor: '#2D3047',
      fill: 'white',
      borderRadius: '2px',
    },
    danger: {
      color: '#fff',
      backgroundColor: '#b94a48',
    },
    // Disabled should be a pseudo selector on confirm, but I can't figure out
    // how to make that work.
    disabled: {
      backgroundColor: '#BFBEBE',
      color: 'white',
    },
    // TODO: MPAUC-94: Someone with a better eye for design could probably choose
    // some more appropriate colors here
    cancel: {
      color: 'black',
      backgroundColor: 'lightGray',
    },
    close: {
      color: '#24A0ED',
      fontWeight: 'bold',
    },
  },
});

export default theme;
