export const CONVERSATION_ACTIVITY = [
  { id: 1, title: 'in the last 24 hours', value: 1 },
  { id: 2, title: 'in the last 7 days', value: 7 },
  { id: 3, title: 'in the last 30 days', value: 30 },
  { id: 4, title: "don't skip anyone", value: 0 },
];

export const UNEDITABLE_STATUSES = [
  'Finished',
  'Sending',
  'Scheduled',
  'Cancelled',
  'Running',
];

export const CANCELLABLE_STATUSES = ['Sending', 'Scheduled', 'Running'];

export const WHEN_TO_SEND = [
  { id: 'send_later', title: 'Send at scheduled time', value: 'schedule' },
  { id: 'save', title: 'Save, but do not send', value: 'save' },
];
