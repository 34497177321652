import React, { useEffect } from 'react';

// External
import gql from 'graphql-tag';
import { isEmpty } from 'lodash';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';

// Material UI
import { useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

// Internal
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import CheckboxControl from 'components/MaterialUI/CheckboxControl';
import TextFieldControl from 'components/MaterialUI/TextFieldControl';

const GET_LEADS_CONFIG = gql`
  query getLeadsConfig($dealerId: Int!) {
    leadsConfig(dealer_id: $dealerId) {
      adf_forward_email
      adf_forward_enabled
    }
  }
`;

const UPDATE_CONFIG_ADF = gql`
  mutation updateConfigAdf($dealerId: Int!, $input: ConfigAdf) {
    updateConfigAdf(dealer_id: $dealerId, input: $input) {
      adf_forward_email
      adf_forward_enabled
    }
  }
`;

const LeadConfigAdfEmail = ({ disabled }) => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const { dealerId } = useDealerContext();

  const { data } = useQuery(GET_LEADS_CONFIG, {
    variables: { dealerId },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const leadConfig = data?.leadsConfig || {};

  const formMethods = useForm();
  const {
    handleSubmit,
    reset,
    control,
    formState: { isSubmitSuccessful },
  } = formMethods;

  useEffect(() => {
    reset(leadConfig);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful, data]);

  const [updateConfigAdf] = useMutation(UPDATE_CONFIG_ADF, {
    onCompleted: () => {
      enqueueSnackbar(`Leads config adf updated`, {
        variant: 'success',
      });
    },
    onError: () =>
      enqueueSnackbar(`Error when updating leads config adf`, {
        variant: 'error',
      }),
  });

  const onSubmit = ({ adf_forward_enabled, adf_forward_email }) => {
    const sanitizedEmail = isEmpty(adf_forward_email)
      ? null
      : adf_forward_email.trim();
    updateConfigAdf({
      variables: {
        dealerId,
        input: {
          adf_forward_enabled,
          adf_forward_email: sanitizedEmail,
        },
      },
    });
  };

  return (
    <Box>
      <Typography
        style={{ fontSize: '2rem', fontWeight: '700', marginTop: '.5rem' }}
      >
        Leads Settings
      </Typography>
      Have 3rd party leads sent to: <b>{dealerId}@adf.goauto.io</b>
      <Grid
        container
        direction="row"
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginBottom: '.5rem',
        }}
      >
        <Grid
          item
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: `${isDesktop ? '0 .5rem 0 0' : '.5rem 0'}`,
          }}
        >
          Forward a copy of all leads in ADF format to:
        </Grid>
        <Grid item>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextFieldControl
              label="Email"
              name="adf_forward_email"
              control={control}
            />

            <CheckboxControl
              name="adf_forward_enabled"
              control={control}
              label="Enabled"
            />

            <Button
              disabled={disabled}
              style={
                disabled
                  ? {
                      margin: `${isDesktop ? '0' : '.5rem 0'}`,
                      ...theme.actions.disabled,
                    }
                  : {
                      margin: `${isDesktop ? '0' : '.5rem 0'}`,
                      ...theme.actions.confirm,
                    }
              }
              type="submit"
              color="primary"
              variant="contained"
            >
              Save Changes
            </Button>
          </form>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LeadConfigAdfEmail;
