import React from 'react';

/* external */
import gql from 'graphql-tag';

/* Material UI */
import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';

/* internal */
import {
  Countdown,
  useTimeSyncContext,
} from 'components/MaterialUI/TimeSyncContext';

const useStyles = makeStyles(theme => ({
  center: {
    textAlign: 'center',
  },
  fullHeight: {
    height: '100%',
  },
}));

const RemainingTimeCard = ({ item }) => {
  const { isElapsed } = useTimeSyncContext();
  const { center, fullHeight } = useStyles();
  const { endsAt, startsAt } = item;

  const showStartTime = !isElapsed(startsAt);
  const showEndTime = !showStartTime && !isElapsed(endsAt);

  const title = showStartTime
    ? 'Bidding in'
    : showEndTime
    ? 'Ends In'
    : 'Ended';

  return (
    <Card className={fullHeight}>
      <CardHeader
        title={title}
        titleTypographyProps={{
          align: 'center',
          color: 'textSecondary',
          gutterBottom: true,
          variant: 'h6',
        }}
      />

      <CardContent className={center}>
        {showStartTime && (
          <Countdown endTime={startsAt} fontSize="h5.fontSize" />
        )}
        {showEndTime && <Countdown endTime={endsAt} fontSize="h5.fontSize" />}
      </CardContent>
    </Card>
  );
};

// TODO: get blind bid start time from item (not available yet - maybe set
//on item on creation so we don't have to query the auction when returning
// the item )
RemainingTimeCard.fragments = {
  item: gql`
    fragment RemainingTimeCardItem on AuctionItem {
      startsAt
      endsAt
    }
  `,
};

export default RemainingTimeCard;
