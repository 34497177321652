import React from 'react';
import { useSnackbar } from 'notistack';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Modal from '@mui/material/Modal';
import VideoRecorder from '../../../components/VideoRecorder';
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
  adaptV4Theme,
} from '@mui/material/styles';
import { snackBarSettings } from '../../../constants';
import gql from 'graphql-tag';
const modalStyle = {
  backgroundColor: '#000',
  height: '100vh',
  overflow: 'hidden',
};

const VideoUploader = ({ params, onUploaded }) => {
  // const [useNative, setNativeMode] = React.useState(true);
  const useNative = true;
  const [uploading, setUploading] = React.useState(false);
  const [cameraOpen, setCameraOpen] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleUpload = e => {
    const newFile = e.target.files.item(0);
    upload(newFile);
  };

  const upload = file => {
    setUploading(true);
    setCameraOpen(false);
    enqueueSnackbar(
      'Uploading. Do not navigate away until upload completes.',
      snackBarSettings,
    );
    processFile(file);
  };

  const processFile = file => {
    var size = file.size;
    var start = 0;
    var uniqueUploadId = new Date();
    loop();

    function loop() {
      var end = start + 10000000; // ~10MB, chunked size need to be between 5MB - 20MB

      if (end > size) {
        end = size;
      }
      var s = slice(file, start, end);
      send(s, start, end - 1, size, uniqueUploadId);
      if (end < size) {
        start += 10000000;
        loop();
      }
    }
  };

  function send(piece, start, end, size, uniqueUploadId) {
    const formData = new FormData();
    formData.append('file', piece);
    formData.append('folder', params.folder);
    formData.append('upload_preset', params.upload_preset);
    formData.append('timestamp', params.timestamp);
    formData.append('api_key', params.api_key);
    formData.append('signature', params.signature);

    fetch(params.url, {
      method: 'post',
      headers: {
        'X-Unique-Upload-Id': uniqueUploadId,
        'Content-Range': 'bytes ' + start + '-' + end + '/' + size,
      },
      body: formData,
    })
      .then(r => r.json())
      .then(data => {
        if (data.public_id) {
          setTimeout(function () {
            setUploading(false);
            enqueueSnackbar('Upload finished.', snackBarSettings);
            onUploaded(data.public_id);
          }, 3000); // wait for 3 seconds for async transformation to be done
        }
      });
  }

  function slice(file, start, end) {
    var slice = file.mozSlice
      ? file.mozSlice
      : file.webkitSlice
      ? file.webkitSlice
      : file.slice
      ? file.slice
      : noop;

    return slice.bind(file)(start, end);
  }

  function noop() {}

  if (uploading) {
    return (
      <Button
        variant="contained"
        component="label"
        startIcon={<CircularProgress size={24} />}
        disabled
      >
        Uploading...
      </Button>
    );
  }
  return (
    <>
      {useNative ? (
        <Button
          variant="contained"
          component="label"
          startIcon={<CloudUploadIcon />}
        >
          Upload Video...
          <input
            type="file"
            style={{ display: 'none' }}
            accept="video/*"
            onChange={handleUpload}
          />
        </Button>
      ) : (
        <Button
          variant="contained"
          onClick={() => setCameraOpen(true)}
          startIcon={<CloudUploadIcon />}
        >
          Record Video
        </Button>
      )}

      <StyledEngineProvider injectFirst>
        <ThemeProvider
          theme={createTheme(adaptV4Theme({ palette: { mode: 'dark' } }))}
        >
          <Modal open={cameraOpen} disableEnforceFocus>
            <div style={modalStyle}>
              <VideoRecorder
                onRecorded={upload}
                onQuit={() => setCameraOpen(false)}
              />
            </div>
          </Modal>
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  );
};

VideoUploader.fragments = {
  videoUploadParams: gql`
    fragment VideoUploaderVideoUploadParams on CloudinaryUploadParams {
      folder
      upload_preset
      timestamp
      api_key
      signature
      url
    }
  `,
};

export default VideoUploader;
