import React from 'react';
import gql from 'graphql-tag';

import { Box, Paper } from '@mui/material';

import {
  FeatureGrids,
  GridWithLabel,
  ServicesGrid,
  SizesWeightsGrid,
  ExteriorGrid,
  InteriorGrid,
} from '../common';

import PanelTitle from './PanelTitle';

const EditSpecsFeatures = ({ vehicle }) => (
  <Paper>
    <Box>
      <PanelTitle title="Specifications & Features" vehicle={vehicle} />
    </Box>
    <Box padding="20px">
      <ServicesGrid />
      <SizesWeightsGrid />
      <ExteriorGrid />
      <InteriorGrid />
    </Box>
    <Box padding="20px">
      <GridWithLabel label="Features">
        <FeatureGrids />
      </GridWithLabel>
    </Box>
  </Paper>
);

EditSpecsFeatures.fragments = {
  vehicle: gql`
    fragment NpvVehicleSpecsFeatures on NpvVehicle {
      id
      ...NpvVehicleSpecsServices
      ...NpvVehicleSpecsSizesWeights
      ...NpvVehicleSpecsExterior
      ...NpvVehicleSpecsInterior
      ...NpvVehicleFeatures
    }
    ${ServicesGrid.fragments.vehicle}
    ${SizesWeightsGrid.fragments.vehicle}
    ${ExteriorGrid.fragments.vehicle}
    ${InteriorGrid.fragments.vehicle}
    ${FeatureGrids.fragments.vehicle}
  `,
};

export default EditSpecsFeatures;
