import React from 'react';
import moment from 'moment';
import {
  Avatar,
  CardContent,
  CardHeader,
  Grid,
  Hidden,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { purple, grey } from '@mui/material/colors';
import { initials } from 'utils';

const useStyles = makeStyles(theme => ({
  userAvatar: {
    backgroundColor: purple[400],
  },
  systemAvatar: {
    backgroundColor: grey[400],
  },
  cardDesktop: {
    marginBottom: '15px',
    marginTop: '15px',
  },
  cardBorder: {
    borderBottomStyle: 'inset',
    borderBottomWidth: 'thin',
    paddingBottom: '10px',
  },
  avatarCell: {
    maxWidth: '60px',
    width: '40px',
    verticalAlign: 'initial',
  },
  avatarSmall: {
    width: '35px',
    height: '35px',
  },
}));

const SimpleNoteCard = ({ buyernote }) => {
  const classes = useStyles();
  const { note, createdAt, createdByUser, dealer } = buyernote;
  const displayName = createdByUser ? createdByUser.display_name : 'System';
  const displayTime = moment(createdAt).isBefore(moment().subtract(1, 'd'))
    ? moment(createdAt).format('MMM DD, YYYY h:mm a')
    : moment(createdAt).fromNow();
  return (
    <>
      <Hidden lgDown>
        <CardContent style={{ paddingBottom: '5px', paddingTop: '5px' }}>
          <Grid
            container
            direction="row"
            justifyContent={'space-between'}
            alignItems="flex-start"
            spacing={0}
          >
            <Grid item xs={1} style={{ flexBasis: '0px', maxWidth: '0px' }}>
              <Avatar>{initials(displayName)}</Avatar>
            </Grid>
            <Grid item xs={7}>
              <h4>{displayName}</h4>
              <Typography variant="body2" component="p">
                {note}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2" align="right">
                {`${displayTime} @ ${dealer.name}`}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Hidden>
      <Hidden lgUp>
        <CardHeader
          avatar={
            <Avatar className={classes.avatarSmall}>
              {initials(displayName)}
            </Avatar>
          }
          title={displayName}
          subheader={`${displayTime} @ ${dealer.dealer_name}`}
          style={{ padding: '6px 0px 0px 6px' }}
        />
        <CardContent className={classes.cardBorder}>
          <Typography variant="body2">{note}</Typography>
        </CardContent>
      </Hidden>
    </>
  );
};

export default SimpleNoteCard;
