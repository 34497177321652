import React, { useState } from 'react';

/* external */
import { useSnackbar } from 'notistack';

/* Material UI */
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import { useTheme } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import CustomerIdentityEditorModal from './CustomerIdentityEditorModal';

const CustomerIdentityCheckerModal = ({
  opportunity,
  onClose,
  isOpen,
  setShowPendingModal,
}) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const [customerIdentityEditor, setCustomerIdentityEditor] = useState(false);

  const email = opportunity?.customer?.identities?.some(
    identity => identity.identity_type === 'email',
  );
  const sms = opportunity?.customer?.identities?.some(
    identity => identity.identity_type === 'sms',
  );

  return (
    <>
      <CustomerIdentityEditorModal
        opportunity={opportunity}
        onClose={() => setCustomerIdentityEditor(false)}
        isOpen={customerIdentityEditor}
        setShowPendingModal={setShowPendingModal}
      />
      <Dialog open={isOpen} onClose={onClose}>
        <DialogTitle
          style={{
            whiteSpace: 'pre-line',
            display: 'flex',
            backgroundColor: 'rgba(255, 165, 0, 0.25)',
            fontWeight: 'bold',
          }}
        >
          <WarningIcon />{' '}
          {!email && !sms
            ? 'Customer is missing email and SMS identities!'
            : !email
            ? 'Customer is missing an email identity!'
            : !sms
            ? 'Customer is missing an SMS identity!'
            : 'Customer identities are complete!'}
        </DialogTitle>
        <DialogContent style={{ textAlign: 'center' }}>
          <DialogContentText>Would you like to add them now?</DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button
            style={theme.actions.add}
            onClick={() => {
              setCustomerIdentityEditor(true);
              onClose();
            }}
            variant="contained"
            color="primary"
          >
            YES
          </Button>
          <Button
            style={theme.actions.add}
            onClick={() => {
              onClose();
              enqueueSnackbar('Continuing without adding identities!', {
                variant: 'warning',
              });
            }}
            color="primary"
            variant="outlined"
          >
            NO
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CustomerIdentityCheckerModal;
