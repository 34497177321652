import React, { useState } from 'react';

/* external */
import gql from 'graphql-tag';
import moment from 'moment-timezone';
import { NetworkStatus } from 'apollo-boost';
import { startCase, toLower } from 'lodash';
import { useQuery } from '@apollo/react-hooks';
import { Waypoint } from 'react-waypoint';

/* Material UI */
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { DatePicker } from '@mui/x-date-pickers';
import SearchIcon from '@mui/icons-material/Search';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import { useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

/* internal */
import Loading from 'components/MaterialUI/Loading';
import DealerPicker from 'components/MaterialUI/DealerPicker';
import PartsTableHeader from './PartsTableHeader';
import { useDealerContext } from 'components/MaterialUI/DealerContext';

const PAGE_SIZE = 50;

const CDK_PARTS_SALES = gql`
  query cdkPartsSales(
    $filters: [QueryFilter]
    $page: Int
    $sort: [QuerySortElement]
  ) {
    rotracker {
      getCDKPartsSales(filters: $filters, page: $page, page_size: ${PAGE_SIZE}, sort: $sort) {
        results {
          id
          comments
          cashier
          closed_date
          customer_name
          home_phone
          invoice_number
          gocard_id
          gocard {
            cardId
            cardNumber
          }
        }
        pagination {
          next_page
          total
        }
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 750,
  },
  dateCell: {
    whiteSpace: 'pre-line',
    paddingRight: '50px',
  },
  customerCell: {
    whiteSpace: 'pre-line',
    '& a': {
      color: '#0093a7',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  icon: {
    verticalAlign: 'text-bottom',
    marginRight: '5px',
  },
}));

const PartsTable = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const isDesktop = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const theme = useTheme();
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('closed_date');
  const [keywords, setKeywords] = useState('');
  const [keywordFilters, setKeywordFilters] = useState([]);
  const [sort, setSort] = useState([
    { model: 'CDKPartsSales', field: 'closed_date', direction: 'desc' },
  ]);
  const [partsDate, setPartsDate] = useState(moment().format('YYYY-MM-DD'));

  const { dealerId } = useDealerContext();

  const handleRequestSort = (event, property) => {
    const value = [
      {
        model: 'CDKPartsSales',
        field: property,
        direction: order === 'asc' ? 'desc' : 'asc',
      },
    ];
    setSort(value);
    setOrder(order === 'asc' ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const setSearchFiltersFromKeywords = () =>
    setKeywordFilters(
      keywords.split(' ').map(keyword => ({
        or: [
          {
            model: 'CDKPartsSales',
            field: 'customer_name',
            op: 'ilike',
            value: `%${keyword}%`,
          },
          {
            model: 'CDKPartsSales',
            field: 'home_phone',
            op: 'ilike',
            value: `%${keyword}%`,
          },
        ],
      })),
    );

  const handleSearch = e =>
    e.key === 'Enter' ? setSearchFiltersFromKeywords() : null;

  const filters = [
    { model: 'CDKPartsSales', field: 'dealer_id', op: '==', value: dealerId },
    {
      model: 'CDKPartsSales',
      field: 'closed_date',
      op: '==',
      value: partsDate,
    },
    ...keywordFilters,
  ];
  const { data, loading, networkStatus, fetchMore } = useQuery(
    CDK_PARTS_SALES,
    {
      variables: {
        filters,
        sort,
        page: 1,
      },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    },
  );

  const rows = data?.rotracker.getCDKPartsSales.results || [];
  const pagination = data?.rotracker.getCDKPartsSales.pagination || {};
  const { next_page, total } = pagination;

  const fetchMoreHandler = () => {
    if (next_page) {
      fetchMore({
        variables: {
          page: next_page,
        },
        updateQuery: (prev, { fetchMoreResult: newData }) => {
          if (!newData.rotracker.getCDKPartsSales.results) return prev;
          return Object.assign({}, prev, {
            rotracker: {
              __typename: prev.rotracker.__typename,
              getCDKPartsSales: {
                __typename: prev.rotracker.getCDKPartsSales.__typename,
                results: [
                  ...prev.rotracker.getCDKPartsSales.results,
                  ...newData.rotracker.getCDKPartsSales.results,
                ],
                pagination: newData.rotracker.getCDKPartsSales.pagination,
              },
            },
          });
        },
      });
    }
  };

  return (
    <Box m={1}>
      <Box>
        <DealerPicker
          {...(isMobile
            ? { style: { width: '100%', marginBottom: theme.spacing(1) } }
            : {})}
        />
      </Box>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="bottom"
      >
        <Grid item xs={isDesktop ? 6 : 12}>
          <Box
            style={{
              margin: '1em',
              display: 'flex',
              justifyContent: isDesktop ? 'flex-start' : 'flex-end',
            }}
          >
            <DatePicker
              autoOk
              clearable
              label="Selected Date"
              format="YYYY-MM-DD"
              onChange={date => setPartsDate(moment(date).format('YYYY-MM-DD'))}
              value={moment(partsDate)}
              variant="dialog"
              disableFuture
              style={{ width: `${isDesktop ? '' : '100%'}` }}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          style={{
            display: 'flex',
          }}
        >
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginLeft: `${isDesktop ? 'auto' : ''}`,
              marginRight: `${isDesktop ? 0 : ''}`,
              width: `${isDesktop ? '' : '100%'}`,
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              style={{ width: `${isDesktop ? '' : '100%'}` }}
            >
              <Box>
                <TextField
                  placeholder="Search ..."
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          disabled={!keywords}
                          onClick={() => {
                            setKeywords('');
                            setKeywordFilters([]);
                          }}
                          size="large"
                        >
                          <CloseIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  value={keywords}
                  onChange={e => setKeywords(e.target.value)}
                  style={{
                    paddingRight: '5px',
                    textAlign: 'right',
                    width: `${isDesktop ? '' : '100%'}`,
                  }}
                  onKeyPress={handleSearch}
                  variant={`${isDesktop ? 'standard' : 'outlined'}`}
                />
              </Box>
              <Box
                style={{
                  display: 'flex',
                  marginLeft: 'auto',
                  marginRight: 0,
                  fontSize: '18px',
                  paddingTop: '1rem',
                }}
              >
                Showing {rows.length} of {total || '0'}
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {isDesktop && (
        <Paper>
          <TableContainer>
            <Table className={classes.table}>
              <PartsTableHeader
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              {loading && networkStatus !== NetworkStatus.fetchMore && (
                <Loading />
              )}
              {rows.length === 0 && !loading && (
                <Box style={{ fontSize: '20px', padding: '1rem' }}>
                  This dealer has no parts sales.
                </Box>
              )}
              <TableBody>
                {rows.map(row => (
                  <TableRow key={row.id}>
                    <TableCell>{row.invoice_number}</TableCell>
                    <TableCell className={classes.customerCell}>
                      <Typography variant="body1">
                        {row?.customer_name
                          ? startCase(toLower(row.customer_name))
                          : 'N/A'}
                      </Typography>
                      <Box>
                        <Typography variant="caption">
                          <SmartphoneIcon
                            fontSize="small"
                            className={classes.icon}
                          />
                          {row?.home_phone || ''}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell className={classes.dateCell}>
                      {`${moment(`${row.closed_date}`).format('lll')}`}
                    </TableCell>
                    <TableCell> {row.gocard?.cardNumber || 'N/A'} </TableCell>
                    <TableCell>{row?.cashier || ''}</TableCell>
                    <TableCell>{row?.comments}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {!loading && (
            <Box height="1px">
              <Waypoint onEnter={fetchMoreHandler} />
            </Box>
          )}
          {networkStatus === NetworkStatus.fetchMore && (
            <Box p={2} style={{ fontSize: '16px' }}>
              Loading more parts sales...
            </Box>
          )}
        </Paper>
      )}
      {!isDesktop && (
        <>
          {loading && networkStatus !== NetworkStatus.fetchMore && <Loading />}
          {rows.length === 0 && !loading && (
            <Box style={{ fontSize: '20px', padding: '1rem' }}>
              This dealer has no parts sales.
            </Box>
          )}
          <>
            {rows.map(row => (
              <Box style={{ paddingBottom: '1rem' }} key={row.id}>
                <Card>
                  <CardHeader
                    title={`${
                      row?.customer_name
                        ? startCase(toLower(row.customer_name))
                        : 'N/A'
                    }`}
                    subheader={`${moment(`${row.closed_date}`).format('lll')}
                    `}
                  />
                  <CardContent>
                    <Grid container direction="row">
                      <Grid item xs={12}>
                        {row?.home_phone && (
                          <Box>
                            <Typography variant="caption">
                              <SmartphoneIcon
                                fontSize="small"
                                className={classes.icon}
                              />
                              {row.home_phone}
                            </Typography>
                          </Box>
                        )}
                      </Grid>
                      <Grid item xs={12} style={{ paddingTop: '10px' }}>
                        <strong>Invoice #</strong>:{' '}
                        {row.invoice_number || 'N/A'}
                      </Grid>
                      <Grid item xs={12} style={{ paddingTop: '10px' }}>
                        <strong>Go Card</strong>:{' '}
                        {row.gocard?.cardNumber || 'N/A'}
                      </Grid>
                      <Grid item xs={12} style={{ paddingTop: '10px' }}>
                        <strong>Cashier #</strong>: {row?.cashier || 'N/A'}
                      </Grid>
                      <Grid item xs={12} style={{ paddingTop: '10px' }}>
                        Comments:
                      </Grid>
                      <Grid item xs={12}>
                        {row?.comments}
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
                {!loading && (
                  <Box height="1px">
                    <Waypoint onEnter={fetchMoreHandler} />
                  </Box>
                )}
                {networkStatus === NetworkStatus.fetchMore && (
                  <Box p={2} style={{ fontSize: '16px' }}>
                    Loading more parts sales...
                  </Box>
                )}
              </Box>
            ))}
          </>
        </>
      )}
    </Box>
  );
};

export default PartsTable;
