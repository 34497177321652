import React from 'react';

/* Material UI */
import { Button, Typography } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import { useHistory } from 'react-router-dom';

const SellOrAuction = ({ setShowSold, setShowAuctioned }) => {
  const history = useHistory();
  return (
    <div style={{ padding: '1rem', display: 'flex', flexDirection: 'column' }}>
      <Typography variant="h6">
        Great! You want to own this vehicle. Do you want to Sell it or Auction
        it?
      </Typography>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '75%',
          marginLeft: 'auto',
          marginRight: 'auto',
          marginTop: '1rem',
          marginBottom: '1rem',
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => setShowSold(true)}
          style={{ width: '85px' }}
        >
          SELL
        </Button>
        <Typography variant="h6">OR</Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setShowAuctioned(true)}
          style={{ width: '85px' }}
        >
          AUCTION
        </Button>
      </div>
      <div
        style={{
          display: 'flex',
          marginLeft: 'auto',
          marginRight: 'auto',
          padding: '1rem',
        }}
      >
        <Button
          variant="outlined"
          style={{ width: '100px' }}
          onClick={() => history.push('/used-vehicles/')}
        >
          <HomeIcon /> Home
        </Button>
      </div>
    </div>
  );
};

export default SellOrAuction;
