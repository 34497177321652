import React from 'react';

import { Chip } from '@mui/material';
import { useMediaQuery, useTheme } from '@mui/material';

const BulkSendStatusChip = ({ status }) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme => theme.breakpoints.down('md'));

  const chipColours = {
    Draft: { backgroundColor: theme.colours.greys.grey3, color: 'black' },

    Sending: { backgroundColor: theme.colours.add, color: 'white' },

    Finished: {
      backgroundColor: theme.actions.confirm.backgroundColor,
      color: 'white',
    },

    // We don't have a colour in our theme for yellorange - we should add one
    Scheduled: { backgroundColor: '#faa700', color: 'white' },

    Cancelled: {
      backgroundColor: theme.actions.danger.backgroundColor,
      color: 'white',
    },
  };

  return (
    <Chip
      size="small"
      label={status}
      style={{
        ...chipColours[status],
        marginTop: mobile ? '10px' : 0,
      }}
    />
  );
};

export default BulkSendStatusChip;
