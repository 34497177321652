import React from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DoneIcon from '@mui/icons-material/Done';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { makeStyles } from '@mui/styles';

import GroupInventoryPriceFilter from './GroupInventoryPriceFilter';
import { useGroupVehiclesQueryContext } from '../contexts/GroupVehiclesQueryContext';
import { GROUP_INVENTORY_FACET_OPTIONS } from './constants';

const useStyles = makeStyles(theme => ({
  panel: {
    width: '100%',
    paddingLeft: '25px',
    paddingRight: '25px',
  },
  title: {
    fontSize: '20px',
    paddingBottom: '10px',
    fontWeight: 'bold',
  },
}));

const FilterDropDown = () => {
  const classes = useStyles();
  const { facetResults, facets, loading, setFacets } =
    useGroupVehiclesQueryContext();

  const getFacetResults = (model, field, options = {}) =>
    facetResults
      .find(x => x.field === field && (!model || x.model === model))
      ?.data.sort(options.sort || (_ => 0))
      .map(({ value, ...rest }) => ({
        value: (options.format || (x => x))(value),
        ...rest,
      })) || [];

  const isSelected = (_model, _field, _value) =>
    facets.findIndex(
      ({ model, field, value }) =>
        model === _model && field === _field && value === _value,
    ) !== -1;

  const deleteFacet = (_model, _field, _value) =>
    setFacets(prev =>
      prev.filter(
        ({ model, field, value }) =>
          model !== _model || field !== _field || value !== _value,
      ),
    );

  const deleteAllFacets = () => {
    setFacets([
      {
        model: 'StockStatus',
        field: 'name',
        value: 'In Stock',
        options: {},
      },
    ]);
  };

  const setLabel = (model, field, value, options) => {
    let newLabel;
    let newOptions = options;

    if (model === 'Vehicle') {
      if (field === 'days_in_stock_category') newLabel = `${value} days`;
      else if (field === 'passengers') {
        if (value === null) newLabel = 'unknown passengers';
        else if (value === 1) newLabel = '1 passenger';
        else newLabel = `${value} passengers`;
      }
    }

    if (newLabel !== undefined) {
      newOptions = { ...options, ...{ label: newLabel } };
    }

    return newOptions;
  };

  const setFacet = (model, field, value, options) => {
    const newOptions = setLabel(model, field, value, options);
    setFacets(prev => [...prev, { model, field, value, options: newOptions }]);
  };

  const toggleFacet = (model, field, value, options) => {
    if (isSelected(model, field, value)) deleteFacet(model, field, value);
    else setFacet(model, field, value, options);
  };

  return (
    <List className={classes.panel}>
      <div className={classes.title}>Filters</div>
      <Box paddingBottom="10px">
        <Button style={{ color: 'red' }} onClick={() => deleteAllFacets()}>
          <HighlightOffIcon style={{ paddingRight: '5px' }} /> RESET ALL
        </Button>
      </Box>
      {GROUP_INVENTORY_FACET_OPTIONS.map(
        ({ field, model, title, options = {} }) => (
          <div
            style={{ paddingBottom: '10px' }}
            key={`${field}${model}${title}`}
          >
            <Accordion key={title}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                {title}
              </AccordionSummary>
              <AccordionDetails>
                {options.rangeInput ? (
                  <GroupInventoryPriceFilter
                    model={model}
                    field={field}
                    options={options}
                  />
                ) : (
                  <List
                    component="div"
                    disablePadding
                    style={{ width: '100%' }}
                  >
                    {getFacetResults(model, field, options).map(
                      ({ count, value }) => (
                        <ListItem
                          disabled={loading}
                          dense
                          button
                          onClick={() =>
                            toggleFacet(model, field, value, options)
                          }
                          key={`${model}${field}${value}`}
                          selected={isSelected(model, field, value)}
                        >
                          {isSelected(model, field, value) ? (
                            <DoneIcon
                              style={{
                                fontSize: 'small',
                                paddingRight: '10px',
                                width: '25px',
                              }}
                            />
                          ) : (
                            <Box paddingRight="10px" width="25px">
                              {' '}
                            </Box>
                          )}
                          <ListItemText
                            primary={`${value || 'Unknown'} (${count})`}
                          />
                        </ListItem>
                      ),
                    )}
                  </List>
                )}
              </AccordionDetails>
            </Accordion>
          </div>
        ),
      )}
    </List>
  );
};

export default FilterDropDown;
