import React, { useState } from 'react';

/* external */
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useSnackbar } from 'notistack';

/* Material UI */
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ErrorIcon from '@mui/icons-material/Error';
import Alert from '@mui/material/Alert';

/* internal */
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';
import { refCode } from 'utils';

const useStyles = makeStyles(theme => ({
  padding: {
    paddingLeft: '10px',
  },
  selected: {
    border: 'double',
    borderColor: 'lightskyblue',
    borderWidth: 'thin',
    borderRadius: '5px',
  },
  deactivated: {
    border: 'double',
    borderColor: 'red',
    borderWidth: 'thin',
    borderRadius: '5px',
    backgroundColor: '#FFF7F7',
    width: '280px',
  },
  updated: {
    border: 'double',
    borderColor: 'green',
    borderWidth: 'thin',
    borderRadius: '5px',
    backgroundColor: '#8fbc8f1a',
    width: '280px',
  },
}));

const TRANSFER_BALANCE = gql`
  mutation transferBalance($input: transferBalanceInput!) {
    gocard {
      transferBalance(input: $input) {
        cardId
      }
    }
  }
`;

const DISABLE_ACCOUNT = gql`
  mutation disableAccount($cardId: ID!) {
    gocard {
      disableAccount(cardId: $cardId) {
        cardId
      }
    }
  }
`;

const MergeCardsModal = ({ gocard, gocards, isOpen, onClose, refetch }) => {
  const theme = useTheme();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [step, setStep] = useState(1);
  const [selectedCard, setSelectedCard] = useState('');
  const [mergeToId, setMergeToId] = useState('');
  const cards = gocards.filter(card => card.cardId !== gocard.cardId);
  const openROs = gocard?.openROs?.pagination?.total || 0;
  const hasOpenROs = openROs !== 0;
  const inactiveCards = cards.filter(card => card.active === false);
  const [disableAccount, { loading: accountLoading }] = useMutation(
    DISABLE_ACCOUNT,
    {
      onCompleted: e => {
        enqueueSnackbar('Transfer complete and account is disabled!', {
          variant: 'success',
        });
        refetch();
        onClose();
      },
      onError: e => {
        enqueueSnackbar(`An error occured: ${e}`);
      },
    },
  );

  const [transferBalance, { loading }] = useMutation(TRANSFER_BALANCE, {
    onCompleted: data => {
      disableAccount({
        variables: { cardId: data.gocard.transferBalance.cardId },
      });
    },
    onError: e => {
      enqueueSnackbar(`An error occurred: ${e}`);
    },
  });

  const handleSelect = newCard => {
    setSelectedCard(newCard);
  };

  const handleStep = newStep => {
    setStep(newStep);
  };

  const handleMergeCard = mergeCardId => {
    setMergeToId(mergeCardId);
  };

  const handleSubmit = () => {
    const payload = {
      toCardId: mergeToId,
      fromCardId:
        mergeToId === gocard.cardId ? selectedCard.cardId : gocard.cardId,
    };
    transferBalance({
      variables: {
        input: payload,
      },
    });
  };

  const hasOpenRO = gocard => {
    const total = gocard?.openROs?.pagination?.total || 0;
    return total !== 0;
  };

  const disableCheckbox = gocard =>
    hasOpenRO(gocard) ||
    inactiveCards.some(card => card.cardId === gocard.cardId);

  const deactivateMainCard = mergeToId === gocard.cardId;

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        Merge Cards - {step === 3 ? 'Final Step' : `Step ${step}`}
      </DialogTitle>
      {step === 1 && (
        <>
          <DialogContent>
            Below is a list of other Go Cards associated with the customer's
            profile. Please select the card you would like to merge, then press
            NEXT.
          </DialogContent>
          {hasOpenROs && (
            <DialogContent>
              <Alert severity="error">
                The selected Go Card is currently attached to an open RO and
                cannot be merged.
              </Alert>
            </DialogContent>
          )}
          <DialogContent>
            {cards.map(card => (
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
                key={card.cardId}
                style={{ paddingBottom: '10px' }}
              >
                {hasOpenRO(card) && (
                  <Grid item xs={12}>
                    <Alert severity="error">
                      {`Go Card #${card.cardNumber} is attached to an open RO and cannot be merged.`}
                    </Alert>
                  </Grid>
                )}
                <Grid item xs={1}>
                  <Checkbox
                    checked={selectedCard.cardId === card.cardId}
                    onChange={() => handleSelect(card)}
                    disabled={disableCheckbox(card)}
                  />
                </Grid>
                <Grid
                  container
                  item
                  direction="row"
                  alignItems="center"
                  xs={11}
                  className={
                    selectedCard.cardId === card.cardId
                      ? classes.selected
                      : null
                  }
                >
                  <Grid item xs={1} className={classes.padding}>
                    <CreditCardIcon />
                  </Grid>
                  <Grid item xs={2}>
                    {card?.cashBalance || 0}
                  </Grid>
                  <Grid
                    container
                    item
                    direction="column"
                    className={classes.padding}
                    xs={4}
                  >
                    <Grid item xs={4}>
                      {card.cardNumber}
                    </Grid>

                    <Grid item xs={4} style={{ color: 'grey' }}>
                      {refCode(card.cardId)}
                    </Grid>
                  </Grid>

                  <Grid item xs={2} sm={4}>
                    <b>Card Status: </b>{' '}
                    {`${card.active ? ' Active' : ' Inactive'}`}
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </DialogContent>
          <DialogActions>
            <Button
              style={theme.actions.close}
              onClick={onClose}
              variant="text"
            >
              Close
            </Button>
            {!hasOpenROs && (
              <Button
                style={theme.actions.confirm}
                onClick={() => handleStep(2)}
                variant="contained"
                color="primary"
                disabled={selectedCard === ''}
              >
                Next
              </Button>
            )}
          </DialogActions>
        </>
      )}
      {step === 2 && (
        <>
          <DialogContent>
            Select the card you would like the balances merged to.
          </DialogContent>
          <DialogContent>
            {[selectedCard, gocard].map(card => (
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
                key={card.cardId}
                style={{ paddingBottom: '10px' }}
              >
                <Grid item xs={1}>
                  <Checkbox
                    checked={mergeToId === card.cardId}
                    onChange={() => handleMergeCard(card.cardId)}
                  />
                </Grid>
                <Grid
                  item
                  xs={11}
                  className={
                    mergeToId === card.cardId ? classes.selected : null
                  }
                  style={{ maxWidth: '280px' }}
                >
                  <Grid container direction="row">
                    <Grid item xs={2} className={classes.padding}>
                      <CreditCardIcon />
                    </Grid>
                    <Grid item xs={10}>
                      ${card?.cashBalance || 0}
                    </Grid>
                    <Grid item className={classes.padding}>
                      {card.cardNumber}
                    </Grid>
                    <Grid
                      item
                      style={{ paddingLeft: '3px', paddingRight: '3px' }}
                    >
                      |
                    </Grid>
                    <Grid item style={{ color: 'grey' }}>
                      {refCode(card.cardId)}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </DialogContent>
          <DialogActions>
            <Button
              style={theme.actions.close}
              onClick={onClose}
              variant="text"
            >
              Close
            </Button>
            <Button
              style={theme.actions.close}
              onClick={() => handleStep(1)}
              variant="text"
            >
              Back
            </Button>
            <Button
              style={theme.actions.confirm}
              onClick={() => handleStep(3)}
              variant="contained"
              color="primary"
              disabled={mergeToId === ''}
            >
              Next
            </Button>
          </DialogActions>
        </>
      )}
      {step === 3 && (
        <>
          <DialogContent>
            <Alert severity="info">
              Pressing SAVE will complete the merge of the cards below into a
              single card based on your selection in the previous step. This
              action cannot be undone!
            </Alert>
          </DialogContent>
          <DialogContent>
            Balance of $
            {deactivateMainCard ? selectedCard.cashBalance : gocard.cashBalance}{' '}
            will be transferred and the card below will be deactivated:
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              style={{ paddingBottom: '10px', paddingTop: '10px' }}
            >
              <Grid item className={classes.deactivated}>
                <Grid container direction="row">
                  <Grid item xs={2} style={{ textAlign: 'center' }}>
                    <CreditCardIcon />
                  </Grid>
                  <Grid item xs={4}>
                    $
                    {deactivateMainCard
                      ? selectedCard.cashBalance
                      : gocard.cashBalance}
                  </Grid>
                  <Grid item xs={1} style={{ color: '#ff00005e' }}>
                    <ErrorIcon />
                  </Grid>
                  <Grid
                    item
                    xs={5}
                    style={{ color: '#ff00005e', paddingLeft: '5px' }}
                  >
                    DEACTIVATED
                  </Grid>
                  <Grid item style={{ paddingLeft: '10px' }}>
                    {deactivateMainCard
                      ? selectedCard.cardNumber
                      : gocard.cardNumber}
                  </Grid>
                  <Grid
                    item
                    style={{ paddingLeft: '3px', paddingRight: '3px' }}
                  >
                    |
                  </Grid>
                  <Grid item style={{ color: 'grey' }}>
                    {refCode(
                      deactivateMainCard ? selectedCard.cardId : gocard.cardId,
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogContent>
            <b>Updated Card Information:</b>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              style={{ paddingBottom: '10px', paddingTop: '10px' }}
            >
              <Grid item className={classes.updated}>
                <Grid container direction="row">
                  <Grid item xs={2} style={{ textAlign: 'center' }}>
                    <CreditCardIcon />
                  </Grid>
                  <Grid item xs={10}>
                    ${Number(selectedCard.cashBalance + gocard.cashBalance)}
                  </Grid>
                  <Grid item style={{ paddingLeft: '10px' }}>
                    {!deactivateMainCard
                      ? selectedCard.cardNumber
                      : gocard.cardNumber}
                  </Grid>
                  <Grid
                    item
                    style={{ paddingLeft: '3px', paddingRight: '3px' }}
                  >
                    |
                  </Grid>
                  <Grid item style={{ color: 'grey' }}>
                    {refCode(
                      !deactivateMainCard ? selectedCard.cardId : gocard.cardId,
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              style={theme.actions.close}
              onClick={onClose}
              variant="text"
            >
              Close
            </Button>
            <Button
              style={theme.actions.close}
              onClick={() => handleStep(2)}
              variant="text"
            >
              Back
            </Button>
            <Button
              style={theme.actions.confirm}
              onClick={() => handleSubmit()}
              variant="contained"
              color="primary"
            >
              Save
            </Button>
          </DialogActions>
        </>
      )}
      <LoadingBackdrop open={loading || accountLoading}>
        Transferring Points...
      </LoadingBackdrop>
    </Dialog>
  );
};

MergeCardsModal.fragments = {
  gocard: gql`
    fragment MergeCardsModalGoCard on GoCardAccountV2 {
      cashBalance
      cardNumber
    }
  `,
  ro: gql`
    fragment MergeCardsModalRo on RoTrackerQueryResult {
      pagination {
        total
      }
    }
  `,
};

export default MergeCardsModal;
