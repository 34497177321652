import React from 'react';

import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { green, red } from '@mui/material/colors';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';

const useStyles = makeStyles(() => ({
  greenButton: {
    backgroundColor: green[500],
    color: 'white',
    margin: '10px',
  },
  redButton: {
    backgroundColor: red[500],
    color: 'white',
    margin: '10px',
  },
}));

export function AddButton({ children, ...rest }) {
  const { greenButton } = useStyles();
  if (!children) children = 'Add';
  return (
    <Button
      className={greenButton}
      startIcon={<AddIcon />}
      variant="contained"
      {...rest}
    >
      {children}
    </Button>
  );
}

export function ReplaceButton({ children, ...rest }) {
  if (!children) children = 'Replace';
  return <AddButton {...rest}>{children}</AddButton>;
}

export function DeleteButton({ children, ...rest }) {
  const { redButton } = useStyles();
  if (!children) children = 'Delete';
  return (
    <Button
      className={redButton}
      startIcon={<DeleteIcon />}
      variant="contained"
      {...rest}
    >
      {children}
    </Button>
  );
}
