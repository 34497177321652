import React, { useState } from 'react';

/* external */
import gql from 'graphql-tag';

/* Material UI */
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';

/* internal */
import { formatPrice } from 'utils';
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import BidForm from './BidForm';

const useStyles = makeStyles(theme => ({
  center: {
    textAlign: 'center',
  },
  card: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
  },
}));

const MyBidCard = ({ item, refetch }) => {
  const { dealerId } = useDealerContext();
  const { card, center } = useStyles();
  const theme = useTheme();
  const [bidFormOpen, setBidFormOpen] = useState(false);

  const {
    dealerId: itemDealerId,
    dealerBids,
    isActive,
    leadingBidId,
    reserveNotMet,
  } = item;
  const myTopBid = dealerBids.sort((a, b) => b.amount - a.amount)?.[0];
  const isWinning = leadingBidId && myTopBid?.id === leadingBidId;
  const isWinner = myTopBid?.isWinner;
  const enableBid = isActive && !isWinning && itemDealerId !== dealerId;

  const bidStatus = isActive
    ? isWinning
      ? 'Winning'
      : 'Losing'
    : isWinner
    ? 'Won'
    : isWinning && reserveNotMet
    ? 'Reserve Not Met'
    : 'Lost';

  return (
    <Card className={card}>
      <BidForm
        open={bidFormOpen}
        onClose={() => setBidFormOpen(false)}
        auctionItem={item}
        refetch={refetch}
      />
      <CardHeader
        title="My Bid"
        titleTypographyProps={{
          align: 'center',
          color: 'textSecondary',
          variant: 'h6',
        }}
      />
      <CardContent className={center}>
        {myTopBid && (
          <Box fontSize="h5.fontSize">{formatPrice(myTopBid.amount)}</Box>
        )}
        {myTopBid && <Box>{bidStatus}</Box>}
      </CardContent>
      <CardActions style={{ textAlign: 'center' }}>
        {enableBid && (
          <Button
            size="small"
            style={theme.actions.confirm}
            onClick={() => setBidFormOpen(true)}
          >
            Place Bid
          </Button>
        )}
      </CardActions>
    </Card>
  );
};

MyBidCard.fragments = {
  item: gql`
    fragment MyBidCardItem on AuctionItem {
      isActive
      dealerId
      leadingBidId
      reserveNotMet
      dealerBids(dealerId: $dealerId) {
        id
        amount
        isWinner
      }
      ...BidFormAuctionItemFragment
    }
    ${BidForm.fragments.auctionItem}
  `,
};

export default MyBidCard;
