import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import qs from 'qs';

const withSupportHref = WrappedComponent => {
  const propTypes = {
    location: PropTypes.object.isRequired,
  };

  class WithSupportHref extends Component {
    renderEmailHref() {
      const { location } = this.props;

      const subject = "Hello, I'm having issues with Market Mobile";

      let body = '\n\n\n';
      body += '##### Please Do Not Modify Below This Line #####\n\n';
      body += `Location: ${location.pathname}\n`;
      body += `User Agent: ${window.navigator.userAgent}\n\n`;
      body += '##### End Debug Info #####\n\n';

      return `mailto:MarketSupport@goauto.ca?${qs.stringify({
        subject,
        body,
      })}`;
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          supportHref={this.renderEmailHref()}
        />
      );
    }
  }

  WithSupportHref.propTypes = propTypes;

  const wrappedComponentName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component';

  WithSupportHref.displayName = `withSupportHref(${wrappedComponentName})`;

  return withRouter(WithSupportHref);
};

export default withSupportHref;
