import React, { createContext, useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import ErrorPage from 'components/MaterialUI/ErrorPage';
import Loading from 'components/MaterialUI/Loading';

const CODE_TABLES = gql`
  query codeTableQuery {
    inventory {
      bodyTypes: getBodyTypes {
        category
        id
        name
      }
      cabTypes: getCabTypes {
        id
        name
      }
      certifiedLines: getCertifiedLines {
        id
        name
      }
      doors: getDoors {
        description
        id
        name
      }
      driveTypes: getDriveTypes {
        description
        id
        name
      }
      engineCompressors: getEngineCompressors {
        id
        name
      }
      engineConfigs: getEngineConfigs {
        id
        name
      }
      exteriorColours: getExteriorColours {
        id
        name
        rgb_value
      }
      fuelTypes: getFuelTypes {
        description
        id
        name
      }
      interiorColours: getInteriorColours {
        id
        name
      }
      stockStatuses: getStockStatuses {
        id
        name
      }
      transmissions: getTransmissions {
        description
        id
        name
      }
      upholsteries: getUpholsteries {
        id
        name
      }
    }
  }
`;

const CodeTablesContext = createContext({
  codeTables: {},
  loading: false,
  error: false,
  refetch: () => {},
});

export const CodeTablesContextProvider = ({ children }) => {
  const { data, loading, error, refetch } = useQuery(CODE_TABLES);

  const codeTables = data?.inventory || {};

  if (error) return <ErrorPage action="Loading Code Tables" error={error} />;
  if (loading || !data) return <Loading />;

  return (
    <CodeTablesContext.Provider
      value={{
        codeTables,
        loading,
        error,
        refetch: () => refetch({ fetchPolicy: 'network-only' }),
      }}
    >
      {children}
    </CodeTablesContext.Provider>
  );
};

export const useCodeTablesContext = () => useContext(CodeTablesContext);

export default CodeTablesContextProvider;
