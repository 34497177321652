import React, { useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import { cloneDeep } from 'lodash';

import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';

import { withStyles } from '@mui/material';

import SuperAccordion from './SuperAccordion';

const VWPDIBaseForm = ({
  initialInspection,
  technicalInspection,
  interiorInspection,
  roadTest,
  afterRoadTest,
  vehicleUpdate,
  detailerProcess,
  detailerInterior,
  detailerExterior,
  ...props
}) => {
  const { enqueueSnackbar } = useSnackbar();

  function generateDefaults(objToCopy) {
    const newObj = cloneDeep(objToCopy);
    Object.entries(newObj).map(([name]) => (newObj[name] = 'Yes'));
    return newObj;
  }

  const [initialInspectionValues, setInitialInspectionValues] = useState(
    generateDefaults(initialInspection),
  );
  const [technicalInspectionValues, setTechnicalInspectionValues] = useState(
    generateDefaults(technicalInspection),
  );
  const [interiorInspectionValues, setInteriorInspectionValues] = useState(
    generateDefaults(interiorInspection),
  );
  const [roadTestValues, setRoadTestValues] = useState(
    generateDefaults(roadTest),
  );
  const [afterRoadTestValues, setAfterRoadTestValues] = useState(
    generateDefaults(afterRoadTest),
  );
  const [vehicleUpdateValues, setVehicleUpdateValues] = useState(
    generateDefaults(vehicleUpdate),
  );
  const [detailerProcessValues, setDetailerProcessValues] = useState(
    generateDefaults(detailerProcess),
  );
  const [detailerInteriorValues, setDetailerInteriorValues] = useState(
    generateDefaults(detailerInterior),
  );
  const [detailerExteriorValues, setDetailerExteriorValues] = useState(
    generateDefaults(detailerExterior),
  );

  const handler = setter => e => {
    const { name, value } = e.target;
    setter(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleInitialInspection = handler(setInitialInspectionValues);
  const handleTechnicalInspection = handler(setTechnicalInspectionValues);
  const handleInteriorInspection = handler(setInteriorInspectionValues);
  const handleRoadTest = handler(setRoadTestValues);
  const handleAfterRoadTest = handler(setAfterRoadTestValues);
  const handleVehicleUpdate = handler(setVehicleUpdateValues);
  const handleDetailerProcess = handler(setDetailerProcessValues);
  const handleDetailerInterior = handler(setDetailerInteriorValues);
  const handleDetailerExterior = handler(setDetailerExteriorValues);

  const [service_product_updates, setServiceProductUpdates] = useState(false);
  const handleServiceProductUpdates = () => {
    setServiceProductUpdates(
      service_product_updates => !service_product_updates,
    );
  };

  const [service_30_day, setService30Day] = useState(false);
  const handleService30Day = () => {
    setService30Day(service_30_day => !service_30_day);
  };

  const [detailer_product_updates, setDetailerProductUpdates] = useState(false);
  const handleDetailerProductUpdates = () => {
    setDetailerProductUpdates(
      detailer_product_updates => !detailer_product_updates,
    );
  };

  const [detailer_30_day, setDetailer30Day] = useState(false);
  const handleDetailer30Day = () => {
    setDetailer30Day(detailer_30_day => !detailer_30_day);
  };

  const [consent, setConsent] = useState(false);
  const handleConsent = () => {
    setConsent(consent => !consent);
  };

  const [confirm, setConfirm] = useState(false);
  const handleConfirm = () => {
    setConfirm(confirm => !confirm);
  };

  const wholeForm = useRef(null);

  const handleSubmit = e => {
    e.preventDefault();

    if (!wholeForm.current['amvic_number'].value) {
      enqueueSnackbar(`Please fill out 'Tech #'.`);
      return;
    }

    if (!consent) {
      enqueueSnackbar(`Please check 'I agree' and then submit the inspection!`);
      return;
    }

    const values = {
      ...initialInspectionValues,
      ...technicalInspectionValues,
      ...interiorInspectionValues,
      ...roadTestValues,
      ...afterRoadTestValues,
      ...vehicleUpdateValues,
      ...detailerProcessValues,
      ...detailerInteriorValues,
      ...detailerExteriorValues,
    };
    const form_data = {
      vw: values,
      amvic_number: wholeForm.current['amvic_number'].value,
      odometer_before: wholeForm.current['odometer_before'].value,
      odometer_after: wholeForm.current['odometer_after'].value,
      service_product_updates: service_product_updates,
      service_30_day: service_30_day,
      technician_name: wholeForm.current['technician_name'].value,
      detailer_product_updates: detailer_product_updates,
      detailer_30_day: detailer_30_day,
      detailer_name: wholeForm.current['detailer_name'].value,
    };
    props.formData(form_data);
    props.formClose();
  };

  const GreenButton = withStyles({
    root: {
      color: '#fff',
      backgroundColor: '#198754',
      '&:hover': {
        backgroundColor: '#157347',
      },
    },
  })(Button);

  return (
    <form onSubmit={handleSubmit} ref={wholeForm}>
      <Card>
        <CardContent>
          <Typography variant="h6">
            <b>Technician Inspection</b>
          </Typography>
          <SuperAccordion
            title="Initial Inspection (upon receiving the vehicle)"
            fields={initialInspection}
            checkboxValues={initialInspectionValues}
            checkboxHandleFunc={handleInitialInspection}
          />
          <SuperAccordion
            title="Technical Initial Inspection (upon receiving the vehicle)"
            fields={technicalInspection}
            checkboxValues={technicalInspectionValues}
            checkboxHandleFunc={handleTechnicalInspection}
          />
          <SuperAccordion
            title="Interior Inspection (make sure hands and clothing are clean before entering the vehicle)"
            fields={interiorInspection}
            checkboxValues={interiorInspectionValues}
            checkboxHandleFunc={handleInteriorInspection}
          />
          <SuperAccordion
            title="Road Test"
            fields={roadTest}
            checkboxValues={roadTestValues}
            checkboxHandleFunc={handleRoadTest}
          />
          <SuperAccordion
            title="After Road Test"
            fields={afterRoadTest}
            checkboxValues={afterRoadTestValues}
            checkboxHandleFunc={handleAfterRoadTest}
          />
          <SuperAccordion
            title="Critical Vehicle Update"
            fields={vehicleUpdate}
            checkboxValues={vehicleUpdateValues}
            checkboxHandleFunc={handleVehicleUpdate}
          />

          <Box>
            <Typography style={{ marginTop: '15px' }}>
              I certify that all operations have been completed and that this
              vehicle has been prepared in accordance with Volkswagen procedures
              and quality standards.
            </Typography>
            <FormControlLabel
              control={<Checkbox />}
              label="All product updates have been checked"
              onChange={handleServiceProductUpdates}
              value={service_product_updates}
            />
            <FormControlLabel
              control={<Checkbox />}
              label="Dealer 30-day maintenance process has been initiated"
              onChange={handleService30Day}
              value={service_30_day}
            />
            <TextField
              label="Technician Name"
              fullWidth
              name="technician_name"
            />
          </Box>
        </CardContent>
      </Card>
      <Card style={{ marginTop: '20px' }}>
        <CardContent>
          <Typography variant="h6">
            <b>Detailer Inspection</b>
          </Typography>
          <SuperAccordion
            title="Detailer Process"
            fields={detailerProcess}
            checkboxValues={detailerProcessValues}
            checkboxHandleFunc={handleDetailerProcess}
          />
          <SuperAccordion
            title="Interior"
            fields={detailerInterior}
            checkboxValues={detailerInteriorValues}
            checkboxHandleFunc={handleDetailerInterior}
          />
          <SuperAccordion
            title="Exterior"
            fields={detailerExterior}
            checkboxValues={detailerExteriorValues}
            checkboxHandleFunc={handleDetailerExterior}
          />
          <Box>
            <Typography style={{ marginTop: '15px' }}>
              I certify that all operations have been completed and that this
              vehicle has been prepared in accordance with Volkswagen procedures
              and quality standards.
            </Typography>
            <FormControlLabel
              control={<Checkbox />}
              label="All product updates have been checked"
              onChange={handleDetailerProductUpdates}
              value={detailer_product_updates}
            />
            <FormControlLabel
              control={<Checkbox />}
              label="Dealer 30-day maintenance process has been initiated"
              onChange={handleDetailer30Day}
              value={detailer_30_day}
            />
            <TextField label="Detailer Name" fullWidth name="detailer_name" />
          </Box>
        </CardContent>
      </Card>

      <Typography style={{ marginTop: '15px' }}>
        The above listed vehicle has been inspected and all items marked as Pass
        or Re-inspection/Pass are in good mechanical condition. All repairs that
        were required to qualify for Re-inspection/Pass have been performed and
        meet this dealership’s satisfaction.
      </Typography>
      <FormControlLabel
        control={<Checkbox />}
        label="I agree"
        onChange={handleConsent}
        value={consent}
      />
      <br />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Technician Name"
            value={props.user.display_name}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Tech #"
            value={props.user.goUserProfile.profile.sales_licence}
            fullWidth
            name={'amvic_number'}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Date"
            value={new Date().toLocaleString()}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <Button
            type="button"
            variant="contained"
            style={{ marginRight: '15px' }}
            onClick={handleConfirm}
          >
            Cancel
          </Button>
          <Dialog
            open={confirm}
            onClose={handleConfirm}
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Current inspection will be closed and unsaved data will be gone.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleConfirm}>Cancel</Button>
              <Button onClick={props.formClose} color="secondary">
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
          <GreenButton type="submit" variant="contained">
            Save
          </GreenButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default VWPDIBaseForm;
