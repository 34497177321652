/* external */
import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

/* Material UI */
import { Grid, MenuItem, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Loading, TextFieldControl } from 'components/MaterialUI';

/* internal */

const GET_NPV_TAGS = gql`
  query getTags {
    npv {
      getTags {
        id
        name
        assetLink
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  tagDD: {
    width: '90%',
    textTransform: 'capitalize',
  },
  imgInDD: {
    maxWidth: '15%',
    marginRight: '10px',
  },
}));

// opted to have two selects, instead of 1 multi-select, as per the mockups
// this will preserve the order of the tags (1 & 2) and be easier to
// manage the selection of only 2 tags max
const TagsContainer = () => {
  const classes = useStyles();

  const { data, loading } = useQuery(GET_NPV_TAGS);

  const vehicleTags = data?.npv?.getTags || [];

  if (loading) return <Loading />;

  return (
    <Grid container item xs={12} spacing={2} style={{ marginTop: '10px' }}>
      <Grid item xs={12}>
        <Typography>
          <b>Tags</b> (select up to 2)
        </Typography>
      </Grid>
      <Grid item xs={12} sm={5}>
        {/* Tag 1 */}
        <TextFieldControl
          select
          name="tag1Id"
          muiVariant="outlined"
          className={classes.tagDD}
        >
          <MenuItem value={0}>No Tag</MenuItem>
          {vehicleTags.map(tag => (
            <MenuItem
              key={tag.id}
              value={tag.id}
              style={{ textTransform: 'capitalize' }}
            >
              <img
                src={tag.assetLink}
                alt={tag.name}
                className={classes.imgInDD}
              />
              {tag.name.replace(/-/g, ' ')}
            </MenuItem>
          ))}
        </TextFieldControl>
      </Grid>
      <Grid item xs={12} sm={5}>
        {/* Tag 2 */}
        <TextFieldControl
          select
          name="tag2Id"
          muiVariant="outlined"
          className={classes.tagDD}
        >
          <MenuItem value={0}>No Tag</MenuItem>
          {vehicleTags.map(tag => (
            <MenuItem
              key={tag.id}
              value={tag.id}
              style={{ textTransform: 'capitalize' }}
            >
              <img
                src={tag.assetLink}
                alt={tag.name}
                className={classes.imgInDD}
              />
              {tag.name.replace(/-/g, ' ')}
            </MenuItem>
          ))}
        </TextFieldControl>
      </Grid>
    </Grid>
  );
};

TagsContainer.fragments = {
  vehicle: gql`
    fragment NpvVehicleTagsContainer on NpvVehicle {
      id
      tag1Id
      tag2Id
    }
  `,
};

export default TagsContainer;
