import gql from 'graphql-tag';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';

/* external */
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

/* Material UI */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import CheckboxControl from 'components/MaterialUI/CheckboxControl';
import TextFieldControl from 'components/MaterialUI/TextFieldControl';

/* internal */
import { errorHandler } from 'modules/auctions/utils';

import DailyAuctionForm from './DailyAuctionForm';
import WeeklyAuctionForm from './WeeklyAuctionForm';

export const processAuctionData = auction => {
  const { weekly, daily, ...rest } = auction;
  let returnAuction = rest;
  if (weekly) {
    const {
      addItemsMinutes,
      blindBidMinutes,
      durationMinutes,
      mustAuctionDays,
      ...restWeekly
    } = weekly;
    returnAuction.weekly = {
      addItemsMinutes: parseInt(addItemsMinutes, 10),
      blindBidMinutes: parseInt(blindBidMinutes, 10),
      durationMinutes: parseInt(durationMinutes, 10),
      mustAuctionDays: parseInt(mustAuctionDays, 10),
      ...restWeekly,
    };
  }
  if (daily) {
    const { cutoffDays, durationMinutes, ...restDaily } = daily;
    returnAuction.daily = {
      cutoffDays: parseInt(cutoffDays, 10),
      durationMinutes: parseInt(durationMinutes, 10),
      ...restDaily,
    };
  }
  return returnAuction;
};

const AuctionForm = ({ auction, onSubmit, submitErrors }) => {
  const { weekly, daily } = auction ?? {};
  const methods = useForm({
    defaultValues: {
      active: auction?.active ?? true,
      name: auction?.name ?? '',
      isWeekly: auction?.isWeekly ?? false,
      isDaily: auction?.isDaily ?? false,
      weekly: weekly
        ? {
            addItemsMinutes: weekly.addItemsMinutes,
            blindBidMinutes: weekly.blindBidMinutes,
            durationMinutes: weekly.durationMinutes,
            mustAuctionDays: weekly.mustAuctionDays,
            startDay: weekly.startDay,
            startTime: weekly.startTime,
          }
        : undefined,
      daily: daily
        ? {
            cutoffDays: daily.cutoffDays,
            cutoffTime: daily.cutoffTime,
            durationMinutes: daily.durationMinutes,
            skipMonday: daily.skipMonday,
            skipTuesday: daily.skipTuesday,
            skipWednesday: daily.skipWednesday,
            skipThursday: daily.skipThursday,
            skipFriday: daily.skipFriday,
            skipSaturday: daily.skipSaturday,
            skipSunday: daily.skipSunday,
            startTime: daily.startTime,
          }
        : undefined,
    },
    shouldUnregister: true,
  });
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const { control, handleSubmit, setError, setValue } = methods;
  const isDailyWatch = useWatch({ control, name: 'isDaily' });
  const isWeeklyWatch = useWatch({ control, name: 'isWeekly' });

  const handleCancel = () => history.push('/auctions');

  useEffect(() => {
    if (isDailyWatch && isWeeklyWatch)
      setValue('isWeekly', false, { shouldDirty: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDailyWatch]);

  useEffect(() => {
    if (isDailyWatch && isWeeklyWatch)
      setValue('isDaily', false, { shouldDirty: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWeeklyWatch]);

  useEffect(() => {
    if (submitErrors) {
      errorHandler(enqueueSnackbar, setError)(submitErrors);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitErrors]);

  const required = 'This is required';

  return (
    <Box margin={1}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box margin={1}>
            <TextFieldControl
              autoFocus
              label="Name"
              name="name"
              required
              rules={{ required }}
            />
          </Box>
          <Box margin={1}>
            <CheckboxControl label="Active" name="active" />
          </Box>
          <Box margin={1}>
            <CheckboxControl
              disabled={Boolean(auction)} // Checkbox does not support readOnly
              label="Weekly (Aged)"
              name="isWeekly"
            />
          </Box>
          <Box margin={1}>
            <CheckboxControl
              disabled={Boolean(auction)}
              label="Daily (Used Vehicles)"
              name="isDaily"
            />
          </Box>
          <WeeklyAuctionForm />
          <DailyAuctionForm />
          <Button onClick={handleCancel}>Cancel</Button>
          <Button variant="contained" type="submit" color="primary">
            Submit
          </Button>
        </form>
      </FormProvider>
    </Box>
  );
};

AuctionForm.fragments = {
  auction: gql`
    fragment AuctionFormAuction on Auction {
      id
      active
      isDaily
      isWeekly
      name
      ...DailyAuctionFormAuction
      ...WeeklyAuctionFormAuction
    }
    ${DailyAuctionForm.fragments.auction}
    ${WeeklyAuctionForm.fragments.auction}
  `,
};

export default AuctionForm;
