import React, { useEffect } from 'react';

/* external */
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';
import gql from 'graphql-tag';

/* Material UI */
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';

/* internal */
import { errorHandler } from 'modules/pitches/utils';

const KIOSK_FRAGMENT = gql`
  fragment KioskFormDialogKiosk on PitchKiosk {
    dealerId
    kioskNumber
    username
    password
  }
`;

const UPDATE_KIOSK = gql`
  mutation updateKiosk($id: Int!, $data: PitchKioskUpdate!) {
    pitches {
      updateKiosk(id: $id, data: $data) {
        id
        ...KioskFormDialogKiosk
      }
    }
  }
  ${KIOSK_FRAGMENT}
`;

const CREATE_KIOSK = gql`
  mutation createKiosk($kiosk: PitchKioskInput!) {
    pitches {
      createKiosk(kiosk: $kiosk) {
        id
        ...KioskFormDialogKiosk
      }
    }
  }
  ${KIOSK_FRAGMENT}
`;

const KioskFormDialog = ({ kiosk, onCreate, onClose, ...rest }) => {
  const { dealerId } = useDealerContext();
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit, reset, setError } = useForm();
  const [createKiosk] = useMutation(CREATE_KIOSK);
  const [updateKiosk] = useMutation(UPDATE_KIOSK);

  useEffect(
    () =>
      // reset form when dialog opens
      reset(kiosk),
    [reset, kiosk],
  );

  const onSubmit = ({ kioskNumber, username, password }) => {
    if (kiosk.id)
      updateKiosk({
        variables: { id: kiosk.id, data: { kioskNumber, username, password } },
      }).then(() => onClose(), errorHandler(enqueueSnackbar, setError));
    else
      createKiosk({
        variables: { kiosk: { dealerId, kioskNumber, username, password } },
      }).then(() => onCreate(), errorHandler(enqueueSnackbar, setError));
  };

  return (
    <Dialog open={Boolean(kiosk)} {...rest}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{isEmpty(kiosk) ? 'New ' : 'Edit'} Kiosk</DialogTitle>
        <DialogContent>
          <Controller
            control={control}
            defaultValue={1}
            name="kioskNumber"
            render={({
              field: { onChange, value, ...field },
              fieldState: { error },
            }) => (
              <TextField
                {...field}
                error={Boolean(error)}
                helperText={error?.message}
                style={{ minWidth: '200px' }}
                label="Kiosk Number"
                type="number"
                onChange={e =>
                  onChange(
                    isNaN(e.target.valueAsNumber) || e.target.value === null
                      ? ''
                      : e.target.valueAsNumber,
                  )
                }
                value={isNaN(value) || value === null ? '' : value.toString()}
                InputProps={{
                  inputProps: { min: '1', max: '100' },
                }}
              />
            )}
          />
          <br />

          <Controller
            control={control}
            defaultValue=""
            name="username"
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                error={Boolean(error)}
                helperText={error?.message}
                style={{ minWidth: '200px' }}
                label="Username"
              />
            )}
          />
          <br />

          <Controller
            control={control}
            defaultValue=""
            name="password"
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                error={Boolean(error)}
                helperText={error?.message}
                style={{ minWidth: '200px' }}
                label="Password"
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit">Save</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

KioskFormDialog.fragments = {
  kiosk: KIOSK_FRAGMENT,
};

export default KioskFormDialog;
