import React from 'react';

import Alert from '@mui/material/Alert';
// import Button from '@mui/material/Button';

const TasksToolbar = ({ numSelected, onSelectAllFiltered, total }) => {
  return (
    <>
      {numSelected === 1 && (
        <Alert severity="warning">{numSelected} task selected.</Alert>
      )}
      {numSelected > 1 && (
        <Alert severity="warning">
          {numSelected} tasks selected. {/*{total !== numSelected && (*/}
          {/*  <Button color="primary" onClick={onSelectAllFiltered}>*/}
          {/*    Select all {total} from your filtered results*/}
          {/*  </Button>*/}
          {/*)}*/}
        </Alert>
      )}
    </>
  );
};

export default TasksToolbar;
