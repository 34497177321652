// External
import moment from 'moment-timezone';
import React from 'react';
import { Link } from 'react-router-dom';

// Material UI
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';

// Internal
import Loading from 'components/MaterialUI/Loading';
import EstimateBalanceOwing from './EstimateBalanceOwing';
import ServiceAppointmentActions from './ServiceAppointmentActions';

const headCells = [
  {
    id: 'actions',
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: 'Actions',
  },
  {
    id: 'appointment_date',
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: 'Scheduled For',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: 'Customer',
  },
  {
    id: 'vehicle',
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: 'Vehicle',
  },
  {
    id: 'sales_person',
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: 'Assignee',
  },
  {
    id: 'service_person',
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: 'Service Advisor',
  },
  {
    id: 'balance_owing',
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: 'Balance Owing',
  },
];

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  action: {
    '& > *': {
      margin: theme.spacing(0.5),
    },
    '& button': {
      padding: '6px 4px',
      minWidth: 'unset',
      backgroundColor: '#2196f3',
    },
  },
  customer: {
    '& a': {
      color: '#0093a7',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
}));

const ServiceAppointmentTable = ({
  loading,
  rows,
  handleOpenUpdateSalesperson,
  formatName,
  dealerId,
  order,
  orderBy,
  handleRequestSort,
}) => {
  const classes = useStyles();

  const createSortHandler = property => event => {
    handleRequestSort(event, property);
  };

  const getVehicle = row =>
    row?.customer?.vehicles.find(vehicle => vehicle.vin === row.vin);

  const displayLatestASPMatchingDealerIdOrEmpty = (
    salesPeople,
    currentdealerId,
  ) => {
    // 'ASP' in the function name means 'assigned_salesperson'
    if (!salesPeople) return '';

    // There can be multiple salespeople that match the dealer_id, hence .filter > .find...
    const salesPeopleMatchedByDealerId = salesPeople.filter(
      salesPerson =>
        parseInt(salesPerson.dealer_id) === parseInt(currentdealerId),
    );

    if (salesPeopleMatchedByDealerId.length > 0) {
      // ...since there can be multiple matches, we want the latest one in that list.
      const latestMatchingSalesPerson = salesPeopleMatchedByDealerId.length - 1;
      return salesPeopleMatchedByDealerId[latestMatchingSalesPerson].user
        ?.display_name;
    } else {
      return '';
    }
  };

  return (
    <TableContainer>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {headCells.map(headCell => (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? 'right' : 'left'}
                padding={headCell.disablePadding ? 'none' : 'normal'}
                sortDirection={
                  orderBy === headCell.id ? (order > 0 ? 'asc' : 'desc') : false
                }
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={
                    orderBy === headCell.id
                      ? order > 0
                        ? 'asc'
                        : 'desc'
                      : 'asc'
                  }
                  onClick={createSortHandler(headCell.id)}
                  disabled={!headCell.sortable}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === -1 ? 'sorted descending' : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {loading && <Loading />}
        {rows.length === 0 && !loading && (
          <Box fontSize="20px" padding="1rem">
            No Appointments Found.
          </Box>
        )}
        <TableBody>
          {rows.map(row => (
            <TableRow hover key={row.appointment_id}>
              <TableCell>
                <ServiceAppointmentActions
                  classes={classes}
                  handleOpenUpdateSalesperson={handleOpenUpdateSalesperson}
                  appointment={row}
                />
              </TableCell>
              <TableCell>
                {`${moment
                  .utc(row.appointment_date)
                  .format('MMM D, YYYY @ h:mma')}`}
              </TableCell>
              <TableCell className={classes.customer}>
                {row.customer ? (
                  <>
                    <Link to={`/customers/${row.customer._id}/details`}>
                      {row.customer.fullname || ''}
                    </Link>
                    {row.customer.primary_email ? (
                      <>
                        <br />
                        <i className="fas fa-envelope fa-fw"></i>{' '}
                        {row.customer.primary_email || ''}
                      </>
                    ) : (
                      ''
                    )}
                    {row.customer.primary_phone ? (
                      <>
                        <br />
                        <i className="fas fa-mobile-alt fa-fw"></i>{' '}
                        {row.customer.primary_phone || ''}
                      </>
                    ) : (
                      ''
                    )}
                  </>
                ) : row.first_name ? (
                  row.first_name + ' '
                ) : '' + row.last_name ? (
                  row.last_name
                ) : (
                  ''
                )}
              </TableCell>
              <TableCell>
                {row.year} {row.make_name} {row.model_name}
              </TableCell>
              <TableCell>
                {displayLatestASPMatchingDealerIdOrEmpty(
                  row.customer?.assigned_salespeople,
                  dealerId,
                )}
              </TableCell>
              <TableCell>
                {row.service_person && formatName(row.service_person)}
              </TableCell>
              <TableCell>
                <EstimateBalanceOwing vehicle={getVehicle(row)} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ServiceAppointmentTable;
