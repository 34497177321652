import React from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import useMediaQuery from '@mui/material/useMediaQuery';
import ButtonBase from '@mui/material/ButtonBase';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiCardContent-root': {
      padding: 0,
    },
  },
}));

const DashboardItem = ({
  title,
  value,
  icon,
  color,
  onClick,
  subValue,
  ...rest
}) => {
  const isDesktop = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const classes = useStyles();
  return (
    <Grid item xs={6} md={3} {...rest}>
      <ButtonBase focusRipple style={{ width: '100%' }}>
        <Card
          className={classes.root}
          onClick={onClick}
          style={{ width: isDesktop ? '90%' : '100%' }}
        >
          <CardContent>
            <Grid
              container
              direction="row"
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                flexWrap: 'nowrap',
              }}
            >
              <Grid
                item
                style={{
                  paddingLeft: `${isDesktop ? '1rem' : ''}`,
                  height: '100%',
                }}
              >
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '50px',
                    height: '130px',
                  }}
                >
                  <Box
                    style={{
                      backgroundColor: '#2c2c2c',
                      height: '100%',
                      width: 50,
                      display: 'flex',
                      flexDirection: 'column',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                    }}
                  >
                    <i
                      className={icon}
                      style={{
                        fontSize: `${isDesktop ? '30px' : '16px'}`,
                        color: 'white',
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: 'auto',
                        marginBottom: 'auto',
                      }}
                    ></i>
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                style={{
                  display: 'flex',
                  marginTop: 'auto',
                  marginBottom: 'auto',
                  flexDirection: 'column',
                }}
              >
                <Box
                  style={{
                    padding: '1rem 1rem 1rem 0',
                    height: '100%',
                    display: 'flex',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                    flexDirection: 'column',
                  }}
                >
                  <Box
                    style={{
                      fontSize: `${isDesktop ? '36px' : '16px'}`,
                      color,
                      textAlign: 'right',
                      fontWeight: 'bold',
                    }}
                  >
                    {value}
                  </Box>
                  <Box
                    style={{
                      paddingTop: '0.2rem',
                      textAlign: 'right',
                      fontSize: `${isDesktop ? '14px' : '8px'}`,
                      color: '#a9a9a9',
                    }}
                  >
                    {subValue || ' '}
                  </Box>
                  <div
                    style={{
                      textAlign: 'right',
                      fontSize: `${isDesktop ? '16px' : '10px'}`,
                      fontWeight: 'bold',
                      paddingBottom: '1rem',
                      color: '#555555',
                    }}
                  >
                    {title}
                  </div>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </ButtonBase>
    </Grid>
  );
};

export default DashboardItem;
