import { useMutation, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import {
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import FolderIcon from '@mui/icons-material/Folder';

import { useDealerContext } from 'components/MaterialUI/DealerContext';
import Loading from 'components/MaterialUI/Loading';
import { useUserContext } from 'components/MaterialUI/UserContext';

import { getFullDateTime, withSuffix } from 'utils';

const hasNoUVI = item => item.tags !== 'UVI';

const ATTACHMENT_QUERY = gql`
  query attachments($vin: String!) {
    attachment {
      getAttachments(vin: $vin) {
        id
        tags
        label
        created_by
        date_created
        url
        user {
          display_name
        }
      }
    }
  }
`;

const DELETE_ATTACHMENT_MUTATION = gql`
  mutation attachments($id: Int!) {
    attachment {
      deleteAttachment(id: $id) {
        id
      }
    }
  }
`;

const ADD_ATTACHMENT_MUTATION = gql`
  mutation attachments(
    $dataURI: String!
    $label: String!
    $tags: String!
    $vin: String!
    $dealer_id: Int!
    $created_by: String!
    $app_id: String!
    $extension: String!
  ) {
    attachment {
      createAttachment(
        dataURI: $dataURI
        label: $label
        tags: $tags
        vin: $vin
        dealer_id: $dealer_id
        created_by: $created_by
        app_id: $app_id
        extension: $extension
      ) {
        id
      }
    }
  }
`;

const SET_VEHICLE_HAS_UVI = gql`
  mutation setHasUVI($id: Int!, $has_uvi: Boolean!) {
    inventory {
      updateUVIVehicle(id: $id, has_uvi: $has_uvi) {
        id
        has_uvi
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
  multipleLine: {
    whiteSpace: 'pre-line',
    fontSize: '12px',
    color: '#999999',
  },
}));

const TAGS = [
  ['UVI', 'Used Vehicle Inspection'],
  ['VHR', 'Vehicle History Report'],
  ['CFAX', 'Carfax'],
  ['OoTI', 'Out of Town Inspection'],
  ['AMVIC', 'AMVIC'],
  ['OTHER', 'Other'],
];

function getBase64(file) {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      resolve(reader.result);
    };
    reader.onerror = reject;
  });
}

const VehicleAttachments = ({ vehicle }) => {
  const classes = useStyles();
  const [tags, setTags] = useState('');
  const [upload, setUpload] = useState({
    name: '',
    dataURI: '',
    extension: '',
  });
  const [label, setLabel] = useState('');
  const [deleteAttachment] = useMutation(DELETE_ATTACHMENT_MUTATION, {
    onCompleted: () => {
      refetch().then(res => {
        // Only set it if we have no UVI's in the new attachment list AND
        // has_uvi on the vehicle is true.
        if (
          res?.data?.attachment?.getAttachments?.every(hasNoUVI) &&
          vehicle.has_uvi
        ) {
          setVehicleHasUVI({
            variables: {
              id: vehicle.id,
              has_uvi: false,
            },
          });
        }
      });
    },
  });
  const [createAttachment, { loading: mutationLoading }] = useMutation(
    ADD_ATTACHMENT_MUTATION,
    { onCompleted: () => refetch() },
  );
  const { dealerId } = useDealerContext();
  const { currentUser } = useUserContext();
  const mobile = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const { enqueueSnackbar } = useSnackbar();
  const { watch } = useFormContext();
  const [setVehicleHasUVI] = useMutation(SET_VEHICLE_HAS_UVI);

  const currentVin = watch('vin', vehicle.vin);

  const { loading, data, refetch } = useQuery(ATTACHMENT_QUERY, {
    variables: { vin: currentVin },
    skip: !currentVin,
  });
  const attachments = (data && data.attachment.getAttachments) || [];

  const handleTags = event => {
    setTags(event.target.value);
  };

  const handleLabel = event => {
    setLabel(event.target.value);
  };

  async function handleChange(event) {
    const files = event.target.files;
    const extension = files[0].name.split('.').slice(-1)[0];
    let base64file = await getBase64(files[0]);
    setUpload({
      name: files[0].name,
      dataURI: base64file,
      extension,
    });
  }

  const resetForm = () => {
    setTags('');
    setUpload({ name: '', dataURI: '' });
    setLabel('');
  };

  return (
    <Paper style={{ width: '100%', paddingBottom: '2rem' }}>
      <Container>
        <Typography style={{ padding: '1rem 1rem 1rem 0' }} variant="h6">
          <div style={{ paddingBottom: '1rem' }}>Attachments</div>
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} direction="column" md={6}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={12}>
                <form style={{ paddingTop: '1rem' }}>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    style={{
                      maxWidth: `${mobile ? '250px' : '150px'}`,
                      width: '100%',
                    }}
                    value={upload.name || ''}
                    disabled
                  />
                  <span
                    style={{
                      paddingLeft: '1rem',
                    }}
                  >
                    <input
                      className={classes.input}
                      id="attachment-file"
                      multiple
                      type="file"
                      onChange={handleChange}
                    />
                    <label htmlFor="attachment-file">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                      >
                        <FolderIcon style={{ paddingRight: '5px' }} /> BROWSE
                      </Button>
                    </label>
                  </span>
                  <div style={{ padding: '1rem 0 1rem 0' }}>
                    <TextField
                      id="outlined-basic"
                      label="Label"
                      size="small"
                      style={{
                        maxWidth: '250px',
                        width: '100%',
                      }}
                      value={label}
                      onChange={handleLabel}
                    />
                  </div>
                  <FormControl>
                    <InputLabel id="attachment-tags">Tags</InputLabel>
                    <Select
                      labelId="attachment-tags"
                      value={tags}
                      onChange={handleTags}
                      style={{ width: '250px' }}
                      label="Tags"
                    >
                      {TAGS.map(([key, value]) => {
                        return <MenuItem value={key}>{value}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                  <div style={{ paddingTop: '1rem' }}>
                    {!mutationLoading && (
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        onClick={() =>
                          currentVin && currentVin !== ''
                            ? tags !== ''
                              ? createAttachment({
                                  variables: {
                                    dataURI: upload.dataURI,
                                    label,
                                    tags,
                                    vin: currentVin,
                                    dealer_id: dealerId,
                                    created_by: currentUser.username,
                                    app_id: 'smi.inventory',
                                    extension: upload.extension,
                                  },
                                })
                                  .catch(e => {
                                    console.log(e);
                                  })
                                  .then(r => {
                                    const attachmentCreated =
                                      r &&
                                      r.data.attachment.createAttachment.id;

                                    if (attachmentCreated && tags === 'UVI') {
                                      enqueueSnackbar(
                                        `Used Vehicle Inspection Successfully created!`,
                                      );
                                      // only set this if vehicle.has_uvi is false.
                                      if (!vehicle.has_uvi) {
                                        setVehicleHasUVI({
                                          variables: {
                                            id: vehicle.id,
                                            has_uvi: true,
                                          },
                                        });
                                      }
                                    } else {
                                      attachmentCreated
                                        ? enqueueSnackbar(
                                            `Attachment Successfully created!`,
                                          )
                                        : enqueueSnackbar(
                                            `Error creating attachment. Please make sure all data is provided in the fields.`,
                                          );
                                    }
                                  })
                                  .then(() => resetForm())
                              : enqueueSnackbar(
                                  `Please add a tag to upload an attachment.`,
                                )
                            : enqueueSnackbar(
                                `Please provide a VIN to upload an attachment`,
                              )
                        }
                      >
                        Upload
                      </Button>
                    )}
                  </div>
                </form>
                {mutationLoading && (
                  <>
                    <p>
                      <CircularProgress /> Uploading Attachment.. Please Wait.
                    </p>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container>
              <Grid item xs={12} md={12}>
                <Table style={{ minWidth: 300 }} aria-label="spanning table">
                  <TableHead>
                    <TableRow>
                      <TableCell>File</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>By</TableCell>
                    </TableRow>
                  </TableHead>
                  {loading && (
                    <div>
                      <Loading />
                    </div>
                  )}
                  {!loading && (
                    <TableBody>
                      {attachments
                        .filter(attachment => attachment.tags !== 'RECON')
                        .map((attachment, key) => (
                          <TableRow key={key}>
                            <TableCell>
                              <a href={attachment.url}>{attachment.label}</a>
                              <Typography className={classes.multipleLine}>
                                {attachment.tags}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              {getFullDateTime(
                                withSuffix(attachment.date_created, 'Z'),
                                currentUser?.goUserProfile?.settings?.timezone,
                              )}
                            </TableCell>
                            <TableCell>
                              {attachment.user.display_name}
                              <IconButton
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-around',
                                }}
                                onClick={() =>
                                  deleteAttachment({
                                    variables: { id: attachment.id },
                                  }).then(r =>
                                    r.data.attachment.deleteAttachment.id
                                      ? enqueueSnackbar(
                                          `Attachment Successfully deleted!`,
                                        )
                                      : enqueueSnackbar(
                                          `Error deleting attachment.`,
                                        ),
                                  )
                                }
                                size="large"
                              >
                                <DeleteIcon style={{ color: 'red' }} />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  )}
                </Table>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Paper>
  );
};

export default VehicleAttachments;
