import React from 'react';

import { RoleGroup } from 'constants.js';
import { useUserContext } from 'components/MaterialUI/UserContext';

/* Wrapper component for hiding children from non-auction roles */

const AuctionRoleOnly = ({ children, className }) => {
  const {
    currentUser: { role },
  } = useUserContext();
  if (role && RoleGroup.AUCTION_ROLE.includes(role)) {
    return <div className={className}>{children}</div>;
  }

  return null;
};

export default AuctionRoleOnly;
