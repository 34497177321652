/* external */
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import moment from 'moment';
import React, { useState } from 'react';

/* Material UI */
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

/* internal */
import Loading from 'components/MaterialUI/Loading';

import AmaMemberModal from './AmaMemberModal';
import DetachGoCardModal from './DetachGoCardModal';
import GiftPointsModal from './GiftPointsModal';
import GoCardOverview from './GoCardOverview';
import GoldCard from './GoldCard';
import GoldCardActions from './GoldCardActions';
import GoldCardTable from './GoldCardTable';
import NotesModal from './NotesModal';
import PackageNoteCard from './PackageNoteCard';
import PaymentFrequencyModal from './PaymentFrequencyModal';
import PaymentInfoModal from './PaymentInfoModal';
import PaymentScheduleModal from './PaymentScheduleModal';
import MergeCardsModal from './MergeCardsModal';
import RedeemPointsModal from './RedeemPointsModal';
import RedemptionHistory from './RedemptionHistory';
import ReferPointsModal from './ReferPointsModal';
import RevokeSharedGoCardModal from './RevokeSharedGoCardModal';
import RedemptionTiles from './RedemptionTiles';
import RvMemberModal from './RvMemberModal';
import ShareGoCardModal from './ShareGoCardModal';
import TransactionsModal from './TransactionsModal';

const PACKAGES_QUERY = gql`
  query packagesByGoCard($gocardId: Int!) {
    concierge {
      packagesByGoCard(gocardId: $gocardId) {
        id
        ...GoldCardActionsPackage
        ...GoldCardTablePackage
        ...PaymentFrequencyModalPackage
        ...PaymentInfoModalPackage
        ...RedemptionTilesPackage
        dealerId
        gocardId
        payments {
          id
          ...PaymentScheduleModalPayment
          failedPaymentReason
        }
        pad {
          ...PaymentInfoModalPad
        }
        notes {
          id
          ...NotesModalNote
          ...PackageNoteCardNote
        }
        redemptions {
          id
          ...RedemptionHistoryRedemption
        }
      }
    }
  }
  ${GoldCardActions.fragments.package}
  ${GoldCardTable.fragments.package}
  ${NotesModal.fragments.note}
  ${PackageNoteCard.fragments.note}
  ${PaymentFrequencyModal.fragments.package}
  ${PaymentInfoModal.fragments.package}
  ${PaymentInfoModal.fragments.pad}
  ${PaymentScheduleModal.fragments.payment}
  ${RedemptionHistory.fragments.redemption}
  ${RedemptionTiles.fragments.package}
`;

const GoCardTable = ({
  gocard,
  selected,
  handleChange,
  locations,
  refetch,
  gocards,
  customerId,
  records,
  isGoAutoEmployee,
}) => {
  const [amaModal, setAmaModal] = useState(false);
  const [transactionModal, setTransactionModal] = useState(false);
  const [redeemModal, setRedeemModal] = useState(false);
  const [giftModal, setGiftModal] = useState(false);
  const [referralModal, setReferralModal] = useState(false);
  const [mergeModal, setMergeModal] = useState(false);
  const [detachModal, setDetachModal] = useState(false);
  const [rvMemberModal, setRvMemberModal] = useState(false);
  const [shareModal, setShareGoCard] = useState(false);
  const [revokeModal, setRevokeGoCard] = useState(false);

  const handleTransactionModal = isOpen => {
    setTransactionModal(isOpen);
  };

  const handleRedeemModal = isOpen => {
    setRedeemModal(isOpen);
  };

  const handleGiftModal = isOpen => {
    setGiftModal(isOpen);
  };

  const handleReferralModal = isOpen => {
    setReferralModal(isOpen);
  };

  const handleMergeModal = isOpen => {
    setMergeModal(isOpen);
  };

  const handleDetachModal = isOpen => {
    setDetachModal(isOpen);
  };

  const handleShareModal = isOpen => {
    setShareGoCard(isOpen);
  };

  const handleRevokeModal = isOpen => {
    setRevokeGoCard(isOpen);
  };

  const handleAmaMemberModal = isOpen => {
    setAmaModal(isOpen);
  };

  const handleRvMemberModal = isOpen => {
    setRvMemberModal(isOpen);
  };

  const {
    data,
    loading,
    refetch: refetchPackages,
  } = useQuery(PACKAGES_QUERY, {
    variables: {
      gocardId: parseInt(gocard.cardId, 10),
    },
  });

  if (loading) return <Loading />;
  const packages = data?.concierge?.packagesByGoCard || [];
  const hasGoldCard = packages.length > 0;

  return (
    <>
      <TransactionsModal
        gocard={gocard}
        isOpen={transactionModal}
        onClose={() => setTransactionModal(false)}
        refetch={refetch}
      />
      <RedeemPointsModal
        gocard={gocard}
        isOpen={redeemModal}
        onClose={() => setRedeemModal(false)}
        locations={locations}
        refetch={refetch}
      />
      <GiftPointsModal
        gocard={gocard}
        isOpen={giftModal}
        onClose={() => setGiftModal(false)}
        locations={locations}
        refetch={refetch}
      />
      <ReferPointsModal
        gocard={gocard}
        isOpen={referralModal}
        onClose={() => setReferralModal(false)}
        locations={locations}
        refetch={refetch}
      />
      <MergeCardsModal
        gocard={gocard}
        gocards={gocards}
        isOpen={mergeModal}
        onClose={() => setMergeModal(false)}
        refetch={refetch}
      />
      <DetachGoCardModal
        gocard={gocard}
        customerId={customerId}
        isOpen={detachModal}
        onClose={() => setDetachModal(false)}
        records={records}
        refetch={refetch}
      />
      <ShareGoCardModal
        gocard={gocard}
        customerId={customerId}
        isOpen={shareModal}
        onClose={() => setShareGoCard(false)}
      />
      <RevokeSharedGoCardModal
        gocard={gocard}
        isOpen={revokeModal}
        onClose={() => setRevokeGoCard(false)}
      />
      <AmaMemberModal
        gocard={gocard}
        isOpen={amaModal}
        onClose={() => setAmaModal(false)}
        locations={locations}
      />
      <RvMemberModal
        gocard={gocard}
        isOpen={rvMemberModal}
        onClose={() => setRvMemberModal(false)}
        locations={locations}
        allowSignup={true}
      />
      <Accordion expanded={selected} onChange={handleChange}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <GoCardOverview
            gocard={gocard}
            totalCards={gocards.length}
            openTransactionModal={() => handleTransactionModal(true)}
            openRedeemModal={() => handleRedeemModal(true)}
            openGiftModal={() => handleGiftModal(true)}
            openReferralModal={() => handleReferralModal(true)}
            openMergeModal={() => handleMergeModal(true)}
            openDetachModal={() => handleDetachModal(true)}
            openShareModal={() => handleShareModal(true)}
            openRevokeModal={() => handleRevokeModal(true)}
            openAmaModal={() => handleAmaMemberModal(true)}
            openRvModal={() => handleRvMemberModal(true)}
            isGoAutoEmployee={isGoAutoEmployee}
          />
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid item xs={12}>
              {(gocard?.transactions || []).length === 0 && (
                <Typography variant="caption">No Transactions</Typography>
              )}
              {(gocard?.transactions || []).length !== 0 && (
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>Location</TableCell>
                        <TableCell>Code&nbsp;</TableCell>
                        <TableCell>Amount&nbsp;</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(gocard?.transactions || []).map((row, index) => (
                        <TableRow key={row.transactionId}>
                          <TableCell>
                            {moment(row.transactionDate).format('YYYY-MM-DD')}
                          </TableCell>
                          <TableCell>{row.location.locationName}</TableCell>
                          <TableCell>{row.code}</TableCell>
                          <TableCell>{row.amount}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Grid>
            {hasGoldCard && (
              <Grid item xs={12} style={{ padding: '1em' }}>
                <GoldCard goldCards={packages} refetch={refetchPackages} />
              </Grid>
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

GoCardTable.fragments = {
  gocard: gql`
    fragment GoCardTableGoCard on GoCardTransactionV2 {
      transactionId
    }
  `,
};

export default GoCardTable;
