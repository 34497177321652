import {
  Grid,
  Paper,
  Typography,
  TextField,
  Slider,
  RadioGroup,
  Radio,
  FormControlLabel,
  Box,
} from '@mui/material';
import React, { useState } from 'react';
import {
  DriveEta as DriveEtaIcon,
  PinDropOutlined as PinDropIcon,
  Speed as SpeedIcon,
} from '@mui/icons-material';

const MAX_ODOMETER = 500000;
const MAX_DISTANCE = 20000;

const SearchCriteria = ({ searchCriteria, setSearchCriteria }) => {
  const [searchCriteriaBuffer, setSearchCriteriaBuffer] =
    useState(searchCriteria);

  const keyUpHandler =
    attribute =>
    ({ key }) => {
      if (key === 'Enter') saveBuffer(attribute);
      else if (key === 'Escape') resetBuffer(attribute);
    };

  const saveBuffer = attribute =>
    setSearchCriteria(prev => ({
      ...prev,
      [attribute]: searchCriteriaBuffer[attribute],
    }));

  const resetBuffer = attribute =>
    setSearchCriteriaBuffer(prev => ({
      ...prev,
      [attribute]: searchCriteria[attribute],
    }));

  const handleChangeSlideDistance = (e, v) => setDistance(v);
  const handleChangeSlideOdometer = (e, v) => setOdometerRange(v);

  const setDistance = distance =>
    setSearchCriteriaBuffer(prev => ({ ...prev, distance }));
  const setOdometerRange = ([minOdometer, maxOdometer]) =>
    setSearchCriteriaBuffer(prev => ({
      ...prev,
      minOdometer,
      maxOdometer,
    }));
  const setMinOdometer = minOdometer =>
    setSearchCriteriaBuffer(prev => ({ ...prev, minOdometer }));
  const setMaxOdometer = maxOdometer =>
    setSearchCriteriaBuffer(prev => ({ ...prev, maxOdometer }));

  const handleChangeDistance = e =>
    setDistance(parseInt(e.target.value || 0, 10));

  const handleChangeMinOdometer = e => {
    e.persist();
    // Required because React Version < 17 https://legacy.reactjs.org/docs/legacy-event-pooling.html
    // I'm not sure why it's required here, but not in handleChangeDistance
    const newMin = Math.min(parseInt(e.target.value || 0, 10), MAX_ODOMETER);
    if (newMin > maxOdometer) setMaxOdometer(newMin);
    setMinOdometer(newMin);
  };
  const handleChangeMaxOdometer = e => {
    e.persist();
    const newMax = Math.min(parseInt(e.target.value || 0, 10), MAX_ODOMETER);
    setMaxOdometer(newMax);
    if (newMax < minOdometer) setMinOdometer(newMax);
  };

  const { distance, minOdometer, maxOdometer } = searchCriteriaBuffer;
  return (
    <Paper style={{ padding: '10px 15px' }} elevation={4}>
      <Typography style={{ fontWeight: 'bold' }} variant="h6">
        Search Criteria Filters
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={3}>
          <Box
            display="flex"
            alignItems="center"
            spacing={1}
            style={{ fontWeight: 'bold', margin: '10px 0' }}
          >
            <PinDropIcon style={{ width: '25px', height: '25px' }} />
            <Typography variant="body2">Distance (km)</Typography>
          </Box>
          <TextField
            fullWidth
            onBlur={() => saveBuffer('distance')}
            onChange={handleChangeDistance}
            onKeyUp={keyUpHandler('distance')}
            type="number"
            value={distance}
            variant="outlined"
            style={{ fontSize: '9px' }}
            inputProps={{ style: { padding: '8px 10px' } }}
            size="small"
            onKeyDown={e => {
              if (e.key === 'Enter') e.preventDefault();
            }}
          />
          <Slider
            color="secondary"
            value={distance}
            min={1}
            step={1}
            max={MAX_DISTANCE}
            onChange={handleChangeSlideDistance}
            onChangeCommitted={() => saveBuffer('distance')}
            style={{ width: '100%' }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Box
            display="flex"
            alignItems="center"
            spacing={1}
            style={{ fontWeight: 'bold', margin: '10px 0' }}
          >
            <SpeedIcon style={{ width: '25px', height: '25px' }} />
            <Typography variant="body2">Odometer</Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="space-evenly"
            style={{ gap: '8px' }}
          >
            <Box flexGrow="1" width={0}>
              <TextField
                label="From"
                inputProps={{
                  min: 0,
                  max: MAX_ODOMETER,
                  style: { padding: '8px 10px' },
                }}
                onBlur={() => saveBuffer('minOdometer')}
                onChange={handleChangeMinOdometer}
                onKeyUp={keyUpHandler('minOdometer')}
                type="number"
                value={minOdometer}
                variant="outlined"
                style={{ fontSize: '9px', width: '100%' }}
                onKeyDown={e => {
                  if (e.key === 'Enter') e.preventDefault();
                }}
              />
            </Box>
            <Box flexGrow="1" width={0}>
              <TextField
                label="To"
                inputProps={{
                  min: 0,
                  max: MAX_ODOMETER,
                  style: { padding: '8px 10px' },
                }}
                onBlur={() => saveBuffer('maxOdometer')}
                onChange={handleChangeMaxOdometer}
                onKeyUp={keyUpHandler('maxOdometer')}
                value={maxOdometer}
                variant="outlined"
                style={{ fontSize: '9px', width: '100%' }}
                onKeyDown={e => {
                  if (e.key === 'Enter') e.preventDefault();
                }}
              />
            </Box>
          </Box>
          <Slider
            color="secondary"
            value={[minOdometer, maxOdometer]}
            min={0}
            step={100}
            max={MAX_ODOMETER}
            onChange={handleChangeSlideOdometer}
            onChangeCommitted={() => {
              saveBuffer('minOdometer');
              saveBuffer('maxOdometer');
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Box
            display="flex"
            alignItems="center"
            spacing={1}
            style={{ fontWeight: 'bold', margin: '10px 0' }}
          >
            <DriveEtaIcon style={{ width: '25px', height: '25px' }} />
            <Typography variant="body2">Show All Trims?</Typography>
          </Box>
          <RadioGroup
            value={searchCriteria.allTrims}
            onChange={e =>
              setSearchCriteria(prev => ({
                ...prev,
                allTrims: e?.target?.value === 'true',
              }))
            }
            row
          >
            <FormControlLabel value={true} control={<Radio />} label="Yes" />
            <FormControlLabel value={false} control={<Radio />} label="No" />
          </RadioGroup>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default SearchCriteria;
