import React from 'react';

import Box from '@mui/material/Box';
import { Switch, Route } from 'react-router-dom';

import ROTable from './ROTable';
import RODetails from './RODetails';
import ServiceSettings from './ServiceSettings';
import app from '../../app';

const ROTracker = props => {
  const { match } = props;
  return (
    <app.components.Layout title="Service">
      <Switch>
        <Route
          path={`${match.url}/:dealerId/:roNumber`}
          render={({ match }) => (
            <RODetails
              roNumber={match.params.roNumber}
              dealerId={match.params.dealerId}
            />
          )}
        />
        <Route exact path={`${match.url}/`}>
          <Box m={1}>
            <ROTable />
          </Box>
        </Route>
        <Route exact path={`${match.url}/settings`}>
          <Box m={1}>
            <ServiceSettings />
          </Box>
        </Route>
      </Switch>
    </app.components.Layout>
  );
};

export default ROTracker;
