export const BID_COUNT_SORT = { model: 'AuctionItem', field: 'bidCount' };
export const DEALER_SORT = { model: 'AuctionItem', field: 'dealerName' };
export const ENDS_AT_SORT = { model: 'AuctionItem', field: 'endsAt' };
export const LEADING_BID_SORT = {
  model: 'AuctionItem',
  field: 'leadingBidAmount',
};
export const MAKE_SORT = { model: 'Vehicle', field: 'make' };
export const MIN_START_BID_SORT = {
  model: 'AuctionItem',
  field: 'minimumStartingBid',
};
export const MODEL_SORT = { model: 'Vehicle', field: 'model' };
export const ODOMETER_SORT = { model: 'Vehicle', field: 'odometer' };
export const RESERVE_SORT = { model: 'AuctionItem', field: 'reserve' };
export const SORT_BY_ID = {
  model: 'AuctionItem',
  field: 'id',
  direction: 'desc',
};
export const STARTS_AT_SORT = { model: 'AuctionItem', field: 'startsAt' };
export const STOCK_NUMBER_SORT = { model: 'Vehicle', field: 'stockNumber' };
export const YEAR_SORT = { model: 'Vehicle', field: 'year' };
export const VEHICLE_SORT_OPTIONS = [
  { ...YEAR_SORT, direction: 'asc' },
  { ...YEAR_SORT, direction: 'desc' },
  { ...MAKE_SORT, direction: 'asc' },
  { ...MAKE_SORT, direction: 'desc' },
  { ...MODEL_SORT, direction: 'asc' },
  { ...MODEL_SORT, direction: 'desc' },
  {
    ...STOCK_NUMBER_SORT,
    direction: 'asc',
    options: { headerLabel: '(Stk#)' },
  },
  {
    ...STOCK_NUMBER_SORT,
    direction: 'desc',
    options: { headerLabel: '(Stk#)' },
  },
  { ...ODOMETER_SORT, direction: 'asc' },
  { ...ODOMETER_SORT, direction: 'desc' },
];
export const IS_FAVOURITE_SORT = { model: 'AuctionItem', field: 'isFavourite' };
export const FAVE_FIRST_SORT = { ...IS_FAVOURITE_SORT, direction: 'desc' };
