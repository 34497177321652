import React from 'react';
import PropTypes from 'prop-types';

import GoTemplate from '../GoTemplate';
import goLogo from '../../../../assets/go.svg';

import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    // TODO replace this with a pre-defined theme color (TBD)
    color: '#f26524',
    marginBottom: '1rem',
  },
  logo: {
    height: '75px',
    width: '75px',
  },
}));

const propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

function NotFound404() {
  const classes = useStyles();
  return (
    <GoTemplate showHeader={false}>
      <Box className={classes.header}>
        <Box>
          <img src={goLogo} alt="Go Auto Logo" className={classes.logo} />
        </Box>
        <Box className="ms-fontSize-su">404</Box>
      </Box>
      <Box className="ms-font-xl">Oops! Page not found!</Box>
    </GoTemplate>
  );
}

NotFound404.propTypes = propTypes;

export default NotFound404;
