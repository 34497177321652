import React, { useEffect, useState } from 'react';
import gql from 'graphql-tag';
import { Waypoint } from 'react-waypoint';
import { NetworkStatus } from 'apollo-boost';
import { Link } from 'react-router-dom';

import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import FilterListIcon from '@mui/icons-material/FilterList';
import { cloudinaryCore } from 'utils';
import 'react-image-gallery/styles/css/image-gallery.css';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import DealerPicker from 'components/MaterialUI/DealerPicker';
import InventorySearch from './InventorySearch';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import FilterDropDown from './FilterDropDown';
import Chip from '@mui/material/Chip';
import Drawer from '@mui/material/Drawer';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import InputAdornment from '@mui/material/InputAdornment';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Grid from '@mui/material/Grid';
import Fab from '@mui/material/Fab';

import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import { useVehiclesQueryContext } from '../contexts/VehiclesQueryContext';
import noIMG from '../../static/img/no-image.jpg';
import IconGroup from './IconGroup';
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';
import QuickHistoryPopover from './QuickHistoryPopover';

import { titleCase } from '../../utils';
import {
  formatPrice,
  numberWithCommas,
  getFullDateTime,
  withSuffix,
} from 'utils';
import InventoryToolbar from './InventoryToolbar';
import BulkActions from '../bulk_actions/BulkActions';
import { useUserContext } from 'components/MaterialUI/UserContext';

const useStyles = makeStyles(theme => ({
  maybe: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    fontSize: '20px',
    width: '100%',
  },
  smallText: {
    fontSize: '14px',
  },
  paddingIcon: {
    paddingRight: '5px',
    fontSize: '20px',
    display: 'flex',
    alignItems: 'center',
  },
}));

const formatPhoto = photo => {
  const displayPhoto = photo
    ? cloudinaryCore.url(photo.cloudinary_public_id, {
        width: 200,
        crop: 'fit',
      })
    : noIMG;

  return displayPhoto;
};

const InventoryCardTable = () => {
  const classes = useStyles();
  const [selected, setSelected] = useState([]);
  const {
    facets,
    fetchMore,
    isSaving,
    loading,
    networkStatus,
    pagination,
    setFacets,
    updateFieldHandler,
    vehicles,
  } = useVehiclesQueryContext();
  const { me } = useUserContext();

  const [state, setState] = useState({
    left: false,
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const handleAnchorClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleAnchorClose = () => {
    setAnchorEl(null);
  };

  const toggleDrawer = (anchor, open) => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const handleDeleteFacet = (_model, _field, _value) => () =>
    setFacets(prev =>
      prev.filter(
        ({ model, field, value }) =>
          model !== _model || field !== _field || value !== _value,
      ),
    );

  const isSelected = ({ id }) => selected.includes(id);

  const handleClick = (e, { id }) => {
    // Add the new id in the same order as displayed vehicles.
    if (e.target.checked)
      setSelected(prev =>
        vehicles.map(v => v.id).filter(x => [...prev, id].includes(x)),
      );
    else setSelected(prev => prev.filter(x => x !== id));
  };

  const handleSelectAllClick = e =>
    setSelected(e.target.checked ? vehicles.map(n => n.id) : []);

  useEffect(() => {
    // Vehicles could have reloaded, making some selected ids invalid.
    const vehicleIDs = vehicles.map(v => v.id);
    setSelected(prev => prev.filter(x => vehicleIDs.includes(x)));
  }, [vehicles]);

  return (
    <div style={{ padding: '0.5rem' }}>
      <Box marginLeft={1} marginTop={1}>
        <DealerPicker />
      </Box>
      <Box marginLeft={1} marginTop={1}>
        <Grid
          container
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
          }}
        >
          <Grid item>
            <div
              style={{
                display: 'flex',
              }}
            >
              <div style={{ paddingRight: '1rem' }}>
                <Tooltip title="Select Vehicles to use Bulk Actions">
                  <Fab
                    color="primary"
                    onClick={handleAnchorClick}
                    size="medium"
                  >
                    <MoreVertIcon />
                  </Fab>
                </Tooltip>
                <BulkActions
                  anchorEl={anchorEl}
                  handleAnchorClose={handleAnchorClose}
                  selected={selected}
                />
              </div>
              <div style={{ paddingRight: '1rem' }}>
                <Tooltip title="Filter list">
                  <Fab
                    color="primary"
                    onClick={toggleDrawer('left', true)}
                    size="medium"
                  >
                    <FilterListIcon />
                  </Fab>
                </Tooltip>
              </div>
              <Drawer
                anchor={'left'}
                open={state['left']}
                onClose={toggleDrawer('left', false)}
              >
                <div style={{ display: 'flex' }}>
                  <IconButton
                    style={{
                      display: 'flex',
                      marginLeft: 'auto',
                      height: '50px',
                      zIndex: '1000',
                    }}
                    onClick={toggleDrawer('left', false)}
                    size="large"
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
                <FilterDropDown />
              </Drawer>
              {facets.map(({ model, field, value, options: { label } }) => (
                <span
                  style={{
                    paddingRight: '5px',
                    display: 'flex',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                  }}
                  key={`${model}${field}${value}`}
                >
                  <Chip
                    label={label || value}
                    value={value}
                    onDelete={handleDeleteFacet(model, field, value)}
                  />
                </span>
              ))}
            </div>
          </Grid>
          <Grid
            item
            style={{
              display: 'flex',
              marginRight: 0,
              marginLeft: 'auto',
              marginBottom: 0,
            }}
          >
            <InventorySearch
              style={{
                display: 'flex',
                marginRight: 0,
                marginLeft: 'auto',
                marginBottom: 0,
              }}
              queryContext={useVehiclesQueryContext}
            />
          </Grid>
        </Grid>
      </Box>
      <Box marginLeft={1} marginTop={1} marginRight={1}>
        <Grid
          container
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
          }}
        >
          <Grid item>
            <div
              style={{
                display: 'flex',
              }}
            >
              {selected.length > 0 && (
                <InventoryToolbar numSelected={selected.length} />
              )}
            </div>
          </Grid>
          <Grid item>
            <div
              style={{
                textAlign: 'right',
                width: '100%',
                fontSize: '18px',
                alignItems: 'flex-end',
                alignSelf: 'flex-end',
              }}
            >
              Showing {vehicles.length} of {pagination.total}
            </div>
          </Grid>
        </Grid>
      </Box>
      <div
        style={{
          textAlign: 'right',
          width: '100%',
          fontSize: '18px',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <span>
          <Checkbox
            checked={vehicles.length > 0 && vehicles.length === selected.length}
            onClick={handleSelectAllClick}
            color="secondary"
          />{' '}
          Actions
        </span>
      </div>
      {networkStatus === NetworkStatus.setVariables && (
        <>
          <LoadingBackdrop open={true} />
        </>
      )}
      {networkStatus !== NetworkStatus.setVariables && (
        <>
          {vehicles.map((vehicle, index) => {
            const isItemSelected = isSelected(vehicle);
            const labelId = `inventory-table-checkbox-${index}`;
            return (
              <div style={{ paddingBottom: '0.5rem' }} key={vehicle.id}>
                <span style={{ display: 'flex', flexDirection: 'row' }}>
                  <Checkbox
                    checked={isItemSelected}
                    inputProps={{ 'aria-labelledby': labelId }}
                    onClick={event => handleClick(event, vehicle)}
                    style={{
                      display: 'block',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                      marginRight: '10px',
                    }}
                    color="secondary"
                  />
                  <Tooltip
                    title={'Click me to find out more about this vehicle!'}
                  >
                    <IconButton
                      style={{
                        display: 'block',
                        marginTop: 'auto',
                        marginBottom: 'auto',
                        marginRight: '10px',
                      }}
                      size="large"
                    >
                      <Link to={`/inventory/${vehicle.id}`}>
                        <InfoIcon
                          style={{
                            color: '#2196f3',
                            fontSize: '24px',
                          }}
                        />
                      </Link>
                    </IconButton>
                  </Tooltip>
                  <Card
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%',
                      justifyContent: 'space-between',
                    }}
                  >
                    <CardContent className={classes.maybe}>
                      <div style={{ display: 'flex' }}>
                        <Badge
                          overlap="rectangular"
                          color="secondary"
                          badgeContent={vehicle.photo_count + '+'}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                        >
                          <img
                            alt="Vehicle"
                            style={{ width: '150px', objectFit: 'cover' }}
                            src={formatPhoto(vehicle.displayPhoto)}
                          />
                        </Badge>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            paddingLeft: '25px',
                          }}
                        >
                          <div>
                            {vehicle.displayName}{' '}
                            <span className={classes.smallText}>
                              {vehicle.trim_variation}{' '}
                            </span>
                          </div>
                          <div className={classes.smallText}>
                            {vehicle.stock_number} /{' '}
                            {vehicle.vin || <b>No VIN</b>} /{' '}
                            {vehicle.stock_type} /{' '}
                            {titleCase(vehicle.stock_status_name)}
                          </div>
                          <div className={classes.smallText}>
                            {numberWithCommas(vehicle.odometer)} km
                          </div>
                          <div className={classes.smallText}>
                            {vehicle.interior_colour_name} - Interior /{' '}
                            {vehicle.exterior_colour_name} - Exterior
                          </div>
                          <div className={classes.paddingIcon}>
                            <IconGroup vehicle={vehicle} />
                            <div style={{ paddingLeft: '5px' }}>
                              {vehicle.is_detailed_pricing ? (
                                <Alert
                                  style={{
                                    width: '100%',
                                    padding: '0 5px 0 5px',
                                  }}
                                  severity="info"
                                >
                                  Can't apply special pricing. Vehicle has
                                  detailed pricing.
                                </Alert>
                              ) : (
                                ''
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-end',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div>{vehicle.days_in_stock} Days in Stock</div>
                        <span
                          className={classes.smallText}
                          style={{ display: 'flex' }}
                        >
                          <TextField
                            label=""
                            disabled={true}
                            id={`special_price_${vehicle.id}`}
                            value={formatPrice(vehicle.special_price)}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              ),
                            }}
                          />
                          <FormControlLabel
                            control={
                              <Switch
                                checked={vehicle.is_special_price_enabled}
                                disabled={true}
                                onChange={
                                  vehicle.permissions.update
                                    ? updateFieldHandler(
                                        vehicle.id,
                                        'is_special_price_enabled',
                                      )
                                    : null
                                }
                              />
                            }
                            label={
                              isSaving(
                                vehicle.id,
                                'is_special_price_enabled',
                              ) ? (
                                <CircularProgress />
                              ) : (
                                'On Special'
                              )
                            }
                            style={{ width: '150px', paddingLeft: '10px' }}
                          />
                        </span>
                        <div
                          className={classes.smallText}
                          style={{ paddingTop: '5px' }}
                        >
                          <span style={{ paddingRight: '5px' }}>
                            <QuickHistoryPopover id={vehicle.id} />
                          </span>
                          <b>Reg. Price:</b>{' '}
                          {formatPrice(vehicle.regular_price, {
                            nullDisplay: 'No Price',
                          })}
                        </div>
                        <div className={classes.smallText}>
                          <b>Cost:</b>{' '}
                          {!vehicle.permissions.read_cost
                            ? 'XXX'
                            : formatPrice(vehicle.cost, {
                                nullDisplay: 'No Cost',
                              })}
                        </div>
                        {vehicle.date_user_modified ? (
                          <div className={classes.smallText}>
                            <b>Updated</b>{' '}
                            {getFullDateTime(
                              withSuffix(vehicle.date_user_modified, 'Z'),
                              me?.goUserProfile?.settings?.timezone,
                            )}
                          </div>
                        ) : (
                          <div className={classes.smallText}>
                            <b>Updated</b> N/A
                          </div>
                        )}
                      </div>
                    </CardContent>
                  </Card>
                </span>
              </div>
            );
          })}
        </>
      )}
      {!loading && <Waypoint onEnter={fetchMore} />}
      {loading &&
        networkStatus === NetworkStatus.fetchMore &&
        'Loading more vehicles...'}
    </div>
  );
};
InventoryCardTable.fragments = {
  vehicle: gql`
    fragment InventoryCardTableVehicle on GreaseInventoryVehicle {
      id
      cost
      date_user_modified
      days_in_stock
      displayName
      displayPhoto {
        id
        cloudinary_public_id
      }
      exterior_colour_name
      has_uvi
      interior_colour_name
      is_certified
      is_decoded
      is_detailed_pricing
      is_featured
      is_on_special
      is_special_price_enabled
      odometer
      permissions {
        read_cost
        update
      }
      photo_count
      regular_price
      special_price
      special_price_expires
      stock_number
      stock_type
      stock_status_name
      trim_variation
      vin
    }
  `,
};

export default InventoryCardTable;
