import React from 'react';

/* external */
import gql from 'graphql-tag';

/* Material UI */
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';

/* internal */
import { formatPrice } from 'utils';

const useStyles = makeStyles(theme => ({
  center: {
    textAlign: 'center',
  },
  fullHeight: {
    height: '100%',
  },
}));

const WinningBidCard = ({ item }) => {
  const { center, fullHeight } = useStyles();
  const { amount, dealer } = item.leadingBid || {};
  const { name: dealerName } = dealer || {};

  return (
    <Card className={fullHeight}>
      <CardHeader
        title="High Bid"
        titleTypographyProps={{
          align: 'center',
          color: 'textSecondary',
          variant: 'h6',
        }}
      />
      <CardContent className={center}>
        {amount && <Box fontSize="h5.fontSize">{formatPrice(amount)}</Box>}
        <Box>{dealerName}</Box>
      </CardContent>
    </Card>
  );
};

WinningBidCard.fragments = {
  item: gql`
    fragment WinningBidCardItem on AuctionItem {
      leadingBid {
        id
        amount
        dealer {
          id
          name
        }
      }
    }
  `,
};

export default WinningBidCard;
