import React from 'react';
import { Redirect } from 'react-router-dom';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import {
  AUTHENTICATE_URL,
  CLIENT_ID,
  AUTH_CALLBACK_URL,
} from '../../../../constants';
import { getUrlParameter } from '../../../../utils';
import { useEffect } from 'react';
import { Typography } from '@mui/material';
import { useUserContext } from 'components/MaterialUI/UserContext';

const MarketLogin = ({ location }) => {
  const { isLoggedIn, token } = useUserContext();
  const isIframe = window.top !== window.self;
  const isChrome = navigator.userAgent.indexOf('Chrome') > -1;
  const isSafari = navigator.userAgent.indexOf('Safari') > -1 && !isChrome;
  const isLoginWindow =
    window.opener && window.opener.location.host === window.location.host;
  const next = getUrlParameter('next') || '/home';
  const path = location?.state?.from?.pathname ?? '/';
  const search = location?.state?.from?.search ?? '';
  const redirect_uri = `${AUTH_CALLBACK_URL}?next=${encodeURIComponent(
    path + search,
  )}`;
  const authUrl = `${AUTHENTICATE_URL}?client_id=${CLIENT_ID}&response_type=jwt&redirect_uri=${encodeURIComponent(
    redirect_uri,
  )}`;

  useEffect(() => {
    if (!isLoggedIn && !(isIframe && isSafari)) {
      window.location.replace(authUrl);
    }
  });

  useEffect(() => {
    if (isLoggedIn && isLoginWindow) {
      window.opener.location.href = `/login?jwt=${token}`;
      window.close();
    }
  });

  if (isLoggedIn && !isLoginWindow) {
    return <Redirect to={next} />;
  }

  return (
    <Container maxWidth="sm">
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        height="95vh"
        textAlign="center"
      >
        {isLoginWindow ? (
          <>
            <Typography variant="h4" gutterBottom>
              Signing in...
            </Typography>
          </>
        ) : (
          <>
            <Typography variant="h4" gutterBottom>
              Sign in to Market
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              If you are not signed in automatically, click the button below...
            </Typography>
            <Box paddingTop={2} />
            <Button
              onClick={() => window.open(window.location.href)}
              variant="outlined"
              color="secondary"
            >
              Sign In
            </Button>
          </>
        )}
      </Box>
    </Container>
  );
};

export default MarketLogin;
