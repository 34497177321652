import React from 'react';

/* external */
import gql from 'graphql-tag';
import { Link, useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useMutation } from '@apollo/react-hooks';

/* Material UI */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ClearIcon from '@mui/icons-material/Clear';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { useMediaQuery, useTheme } from '@mui/material';

/* internal */
import ConfirmDialog from 'components/MaterialUI/ConfirmDialog';
import DateDisplay from './DateDisplay';
import { capitalize, formatPhoneNumber, memoize } from 'utils';
import { STATUS_ICON } from 'constants.js';
import { useDealerContext } from 'components/MaterialUI/DealerContext';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '300px',
    paddingBottom: 0,
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  oppMobileHeader: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  oppMobileText: {
    fontSize: 14,
  },
  tableCellBold: {
    paddingBottom: 0,
    paddingTop: 0,
    border: 'none',
    fontWeight: 'bold',
  },
  tableCellDefault: {
    paddingBottom: 0,
    paddingTop: 0,
    border: 'none',
  },
  bottomLeftBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  bold: {
    fontWeight: 600,
  },
  tableColumnSmall: {
    minWidth: '150px',
    maxWidth: '150px',
    width: '150px',
  },
  tableColumnMedium: {
    minWidth: '200px',
    maxWidth: '200px',
    width: '200px',
  },
  tableColumnLarge: {
    minWidth: '250px',
    maxWidth: '250px',
    width: '250px',
  },
  mobileButtonText: {
    fontSize: 10,
  },
  defaultButtonText: {
    fontSize: 14,
  },
  listItem: {
    paddingLeft: '0px',
    paddingRight: '0px',
  },
}));

const DETACH_CUSTOMER_RECORD = gql`
  mutation detachCustomerRecords($_id: ID!, $records: [String]!) {
    detachCustomerRecords(_id: $_id, records: $records) {
      records
    }
  }
`;

const CREATE_OPPORTUNITY_FOR_LEAD = gql`
  mutation createOpportunityForLead($leadId: String!, $dealerId: Int!) {
    createOpportunityForLead(leadId: $leadId, input: { dealer_id: $dealerId }) {
      _id
      customer_id
    }
  }
`;

const caslDisplay = val => (val ? 'Opted In' : 'Opted Out');

const Leads = ({ customer, isHidden }) => {
  const desktop = useMediaQuery(theme => theme.breakpoints.up('sm'));

  const interestedVehicleText = lead =>
    lead.form_data?.interested_vehicle_year !== 0 &&
    lead.form_data?.interested_vehicle_make !== '' &&
    `${lead.form_data?.interested_vehicle_year ?? ''} ${
      lead.form_data?.interested_vehicle_make ?? ''
    } ${lead.form_data?.interested_vehicle_model ?? ''}`;
  const { enqueueSnackbar } = useSnackbar();
  const { dealerId } = useDealerContext();
  const theme = useTheme();
  const history = useHistory();
  const [records, setRecords] = React.useState(customer.records || []);
  const [record, setRecord] = React.useState();
  const [detachRecord] = useMutation(DETACH_CUSTOMER_RECORD, {
    onCompleted: data => {
      setRecords(data.detachCustomerRecords.records);
      enqueueSnackbar(`Successfully detached record`, {
        variant: 'success',
      });
    },
    onError: e =>
      enqueueSnackbar(`Error in detaching record`, {
        variant: 'error',
      }),
  });
  const [showDetachConfirm, setShowDetachConfirm] = React.useState(false);
  const [createOpportunity] = useMutation(CREATE_OPPORTUNITY_FOR_LEAD, {
    onCompleted: data => {
      const { _id, customer_id } = data.createOpportunityForLead;
      history.push(`/opportunities/${_id}/${customer_id}/details`);
      enqueueSnackbar(`Opportunity successfully created`, {
        variant: 'success',
      });
    },
    onError: () =>
      enqueueSnackbar(`Error when creating opportunity`, {
        variant: 'error',
      }),
  });

  const handleCloseDialog = () => {
    setShowDetachConfirm(false);
  };
  const handleOpenDialog = record => {
    setRecord(record);
    setShowDetachConfirm(true);
  };

  const findOpp = memoize(lead_id =>
    customer.allOpps.find(opp => opp.crm_lead_ids.includes(lead_id)),
  );
  const handleClear = () => {
    detachRecord({
      variables: {
        _id: customer._id,
        records: [record],
      },
    });
  };

  const classes = useStyles();

  const startOpportunity = lead => {
    createOpportunity({
      variables: {
        leadId: lead._id,
        dealerId: dealerId,
      },
    });
  };

  return (
    <>
      <ConfirmDialog
        text="Are you sure you want to remove this from the customer?"
        onConfirm={handleClear}
        isOpen={showDetachConfirm}
        onClose={handleCloseDialog}
        confirmButtonProps={{
          style: theme.actions.confirm,
          variant: 'outlined',
        }}
        abortButtonProps={{
          variant: 'outlined',
        }}
      />
      <Card className={classes.root}>
        <CardContent>
          <Typography className={classes.title} gutterBottom>
            Leads
          </Typography>
          {customer.leads.length === 0 && 'No leads for this customer'}
          {customer.leads
            .filter(lead =>
              Boolean(
                records.filter(record => record.split(':').pop() === lead._id)
                  .length,
              ),
            )
            .sort((a, b) => -a.created.localeCompare(b.created))
            .map(lead => {
              return (
                <Card style={{ marginBottom: '15px', marginTop: '15px' }}>
                  <CardContent>
                    <Grid
                      container
                      direction="row"
                      justifyContent={'space-between'}
                      alignItems="flex-start"
                    >
                      <Grid
                        item
                        style={{
                          paddingRight: 0,
                          paddingTop: desktop ? 8 : 6,
                        }}
                        xs={1}
                      >
                        <DateDisplay date={lead.created} />
                      </Grid>
                      <Hidden lgUp>
                        <Grid item xs={7} style={{ paddingLeft: 0 }}>
                          <ListItemText
                            primaryTypographyProps={{
                              style: { fontWeight: 'bold' },
                            }}
                            primary={`${lead.form_data.lead_type
                              .split('_')
                              .map(w => capitalize(w))
                              .join(' ')} Lead for ${
                              lead.dealer?.dealer_name
                            } from ${lead.form_data.lead_source}`}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          {!isHidden && (
                            <IconButton size="small">
                              <ClearIcon
                                onClick={handleOpenDialog.bind(
                                  this,
                                  records.find(
                                    record =>
                                      record.split(':').pop() === lead._id,
                                  ),
                                )}
                              />
                            </IconButton>
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <List style={{ paddingTop: 0 }} dense={true}>
                            {findOpp(lead._id) && (
                              <ListItem className={classes.oppMobileHeader}>
                                <Grid
                                  container
                                  direction="row"
                                  justifyContent="flex-start"
                                  alignItems="center"
                                  spacing={2}
                                >
                                  <Grid item>
                                    <Box>
                                      {`${
                                        findOpp(lead._id).status_display
                                      } Opportunity at ${
                                        findOpp(lead._id).dealer.dealer_name
                                      }`}
                                    </Box>
                                  </Grid>
                                  <Grid item>
                                    <Icon
                                      style={{
                                        fontSize: 16,
                                        color:
                                          STATUS_ICON[findOpp(lead._id).status]
                                            .color,
                                      }}
                                      className={
                                        STATUS_ICON[findOpp(lead._id).status]
                                          .icon
                                      }
                                    />
                                  </Grid>
                                </Grid>
                              </ListItem>
                            )}
                            <ListItem className={classes.listItem}>
                              <Box mr={1} display="inline">
                                <Typography className={classes.oppMobileHeader}>
                                  Assigned to:
                                </Typography>
                              </Box>
                              <Typography className={classes.oppMobileText}>
                                {lead.assigneeNode?.display_name ?? ''}
                              </Typography>
                            </ListItem>
                            <ListItem className={classes.listItem}>
                              <Box mr={1} display="inline">
                                <Typography className={classes.oppMobileHeader}>
                                  Email:
                                </Typography>
                              </Box>
                              <Typography className={classes.oppMobileText}>
                                {lead.form_data?.email ?? ''}
                              </Typography>
                            </ListItem>
                            <ListItem className={classes.listItem}>
                              <Box mr={1} display="inline">
                                <Typography className={classes.oppMobileHeader}>
                                  Phone:
                                </Typography>
                              </Box>
                              <Typography className={classes.oppMobileText}>
                                {formatPhoneNumber(
                                  lead.form_data?.primary_phone,
                                ) ?? ''}
                              </Typography>
                            </ListItem>
                            <ListItem className={classes.listItem}>
                              <Box mr={1} display="inline">
                                <Typography className={classes.oppMobileHeader}>
                                  Address:
                                </Typography>
                              </Box>
                              <Typography className={classes.oppMobileText}>
                                {lead.form_data?.street_address_1 ?? ''}
                              </Typography>
                            </ListItem>
                            <ListItem className={classes.listItem}>
                              <Box mr={1} display="inline">
                                <Typography className={classes.oppMobileHeader}>
                                  City / Province:
                                </Typography>
                              </Box>
                              <Typography className={classes.oppMobileText}>{`${
                                lead.form_data?.city ?? ''
                              } ${lead.form_data?.province ?? ''}`}</Typography>
                            </ListItem>
                            <ListItem className={classes.listItem}>
                              <Box mr={1} display="inline">
                                <Typography className={classes.oppMobileHeader}>
                                  Customer's Vehicle:
                                </Typography>
                              </Box>
                              <Typography className={classes.oppMobileText}>
                                {' '}
                                {`${
                                  lead.form_data?.customer_vehicle_year ?? ''
                                } ${
                                  lead.form_data?.customer_vehicle_make ?? ''
                                } ${
                                  lead.form_data?.customer_vehicle_model ?? ''
                                }`}
                              </Typography>
                            </ListItem>
                            <ListItem className={classes.listItem}>
                              <Box mr={1} display="inline">
                                <Typography className={classes.oppMobileHeader}>
                                  Interested Vehicle:
                                </Typography>
                              </Box>
                              <Typography className={classes.oppMobileText}>
                                {interestedVehicleText(lead)}
                              </Typography>
                            </ListItem>
                            <ListItem className={classes.listItem}>
                              <Box mr={1} display="inline">
                                <Typography className={classes.oppMobileHeader}>
                                  CASL Opt In:
                                </Typography>
                              </Box>
                              <Typography className={classes.oppMobileText}>
                                {caslDisplay(lead.form_data?.casl_opt_in)}
                              </Typography>
                            </ListItem>
                            <ListItem className={classes.listItem}>
                              <Box mr={1} display="inline">
                                <Typography className={classes.oppMobileHeader}>
                                  Message:
                                </Typography>
                              </Box>
                            </ListItem>
                            <ListItem className={classes.listItem}>
                              {lead.form_data.message && (
                                <Typography
                                  style={{
                                    wordWrap: 'break-word',
                                    width: '60vw',
                                    fontSize: '14px',
                                    whiteSpace: 'break-spaces',
                                  }}
                                >
                                  {lead.form_data.message.replace(
                                    /^\s+|\s+$/g,
                                    '',
                                  )}
                                </Typography>
                              )}
                            </ListItem>
                          </List>
                        </Grid>
                      </Hidden>
                      <Hidden lgDown>
                        <Grid item xs={10}>
                          <List style={{ paddingTop: 0 }} dense={true}>
                            <ListItem className={classes.title}>
                              {`${lead.form_data?.lead_type
                                .split('_')
                                .map(w => capitalize(w))
                                .join(' ')} Lead for ${
                                lead.dealer?.dealer_name
                              } from ${lead.form_data.lead_source}`}
                            </ListItem>
                            {findOpp(lead._id) && (
                              <ListItem className={classes.oppSubtitle}>
                                <Grid
                                  container
                                  direction="row"
                                  justifyContent="flex-start"
                                  alignItems="center"
                                  spacing={2}
                                >
                                  <Grid item>
                                    <Box>
                                      {`${
                                        findOpp(lead._id).status_display
                                      } Opportunity at ${
                                        findOpp(lead._id).dealer.dealer_name
                                      }`}
                                    </Box>
                                  </Grid>
                                  <Grid item>
                                    <Icon
                                      style={{
                                        fontSize: 16,
                                        color:
                                          STATUS_ICON[findOpp(lead._id).status]
                                            .color,
                                      }}
                                      className={
                                        STATUS_ICON[findOpp(lead._id).status]
                                          .icon
                                      }
                                    />
                                  </Grid>
                                </Grid>
                              </ListItem>
                            )}
                          </List>
                          <TableContainer style={{ minWidth: '100%' }}>
                            <Table className={classes.table}>
                              <TableBody>
                                <TableRow>
                                  <TableCell
                                    className={`${classes.tableCellBold}`}
                                    style={{
                                      minWidth: '150px',
                                      maxWidth: '150px',
                                      width: '150px',
                                    }}
                                    align="left"
                                  >
                                    Assigned to:
                                  </TableCell>
                                  <TableCell
                                    className={`${classes.tableCellDefault}`}
                                    align="left"
                                    style={{
                                      minWidth: '250px',
                                      maxWidth: '250px',
                                      width: '250px',
                                    }}
                                  >
                                    {lead.assigneeNode?.display_name ?? ''}
                                  </TableCell>
                                  <TableCell
                                    className={`${classes.tableCellBold}`}
                                    align="left"
                                    style={{
                                      minWidth: '150px',
                                      maxWidth: '150px',
                                      width: '150px',
                                    }}
                                  >
                                    Address:
                                  </TableCell>
                                  <TableCell
                                    className={`${classes.tableCellDefault}`}
                                    align="left"
                                    style={{
                                      minWidth: '200px',
                                      maxWidth: '20px',
                                      width: '200px',
                                    }}
                                  >
                                    {lead.form_data?.street_address_1 ?? ''}
                                  </TableCell>
                                  <TableCell
                                    className={`${classes.tableCellBold}`}
                                    align="left"
                                    style={{
                                      minWidth: '200px',
                                      maxWidth: '200px',
                                      width: '200px',
                                    }}
                                  >
                                    Customer's Vehicle:
                                  </TableCell>
                                  <TableCell
                                    className={`${classes.tableCellDefault}`}
                                    align="left"
                                  >
                                    {`${
                                      lead.form_data?.customer_vehicle_year ??
                                      ''
                                    } ${
                                      lead.form_data?.customer_vehicle_make ??
                                      ''
                                    } ${
                                      lead.form_data?.customer_vehicle_model ??
                                      ''
                                    }`}
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    className={`${classes.tableCellBold}`}
                                    align="left"
                                  >
                                    Email:
                                  </TableCell>
                                  <TableCell
                                    className={`${classes.tableCellDefault}`}
                                    align="left"
                                  >
                                    {lead.form_data?.email ?? ''}
                                  </TableCell>
                                  <TableCell
                                    className={`${classes.tableCellBold}`}
                                    align="left"
                                  >
                                    City/Province:
                                  </TableCell>
                                  <TableCell
                                    className={`${classes.tableCellDefault}`}
                                    align="left"
                                  >
                                    {`${lead.form_data?.city ?? ''} ${
                                      lead.form_data?.province ?? ''
                                    }`}
                                  </TableCell>
                                  <TableCell
                                    className={`${classes.tableCellBold}`}
                                    align="left"
                                  >
                                    Interested Vehicle:
                                  </TableCell>
                                  <TableCell
                                    className={`${classes.tableCellDefault}`}
                                    align="left"
                                  >
                                    {interestedVehicleText(lead)}
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    className={`${classes.tableCellBold}`}
                                    align="left"
                                  >
                                    Phone:
                                  </TableCell>
                                  <TableCell
                                    className={`${classes.tableCellDefault}`}
                                    align="left"
                                  >
                                    {formatPhoneNumber(
                                      lead.form_data?.primary_phone,
                                    ) ?? ''}
                                  </TableCell>
                                  <TableCell
                                    className={`${classes.tableCellBold}`}
                                    align="left"
                                  ></TableCell>
                                  <TableCell
                                    className={`${classes.tableCellBold}`}
                                    align="left"
                                  ></TableCell>
                                  <TableCell
                                    className={`${classes.tableCellBold}`}
                                    align="left"
                                  >
                                    CASL Opt In:
                                  </TableCell>
                                  <TableCell
                                    className={`${classes.tableCellDefault}`}
                                    align="left"
                                  >
                                    {caslDisplay(lead.form_data?.casl_opt_in)}
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    className={`${classes.tableCellBold}`}
                                    align="left"
                                  >
                                    Message:
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                            {lead.form_data.message && (
                              <Table>
                                <TableRow>
                                  <TableCell style={{ borderBottom: 'none' }}>
                                    <Typography
                                      style={{
                                        wordWrap: 'break-word',
                                        width: '60vw',
                                        fontSize: '14px',
                                        whiteSpace: 'break-spaces',
                                      }}
                                    >
                                      {lead.form_data?.message.replace(
                                        /^\s+|\s+$/g,
                                        '',
                                      )}
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              </Table>
                            )}
                          </TableContainer>
                        </Grid>
                        <Grid item xs={1}>
                          {!isHidden && (
                            <IconButton size="small">
                              <ClearIcon
                                onClick={handleOpenDialog.bind(
                                  this,
                                  records.find(
                                    record =>
                                      record.split(':').pop() === lead._id,
                                  ),
                                )}
                              />
                            </IconButton>
                          )}
                        </Grid>
                      </Hidden>
                    </Grid>
                    <Box
                      component="span"
                      m={1}
                      className={`${classes.bottomLeftBox}`}
                    >
                      <Link
                        to={`/leads/${lead._id}`}
                        style={{ textDecoration: 'none', marginRight: 10 }}
                      >
                        <Button
                          variant="contained"
                          style={theme.actions.add}
                          className={
                            desktop
                              ? classes.defaultButtonText
                              : classes.mobileButtonText
                          }
                        >
                          View Lead
                        </Button>
                      </Link>
                      {((!lead.tags.includes('opportunity') &&
                        !lead.form_data.lead_type.includes('sale') &&
                        !lead.is_archived) ||
                        (!lead.tags.includes('opportunity') &&
                          lead.form_data.lead_type === 'contest')) && (
                        <Box style={{ paddingLeft: '1rem' }}>
                          <Button
                            variant="contained"
                            style={theme.actions.add}
                            className={
                              desktop
                                ? classes.defaultButtonText
                                : classes.mobileButtonText
                            }
                            onClick={() => startOpportunity(lead)}
                          >
                            Create Opportunity
                          </Button>
                        </Box>
                      )}
                      {findOpp(lead._id) && (
                        <Button
                          onClick={() =>
                            history.push(
                              `/opportunities/${findOpp(lead._id)._id}/details`,
                            )
                          }
                          variant="contained"
                          style={theme.actions.add}
                          className={
                            desktop
                              ? classes.defaultButtonText
                              : classes.mobileButtonText
                          }
                        >
                          View Opportunity
                        </Button>
                      )}
                    </Box>
                  </CardContent>
                </Card>
              );
            })}
        </CardContent>
      </Card>
    </>
  );
};

Leads.fragments = {
  customer: gql`
    fragment LeadsFragment on Customer {
      _id
      allOpps: opportunities {
        _id
        dealer {
          dealer_name
          dealer_id
        }
        status_display
        status
        crm_lead_ids
        marketing {
          lead_source
          lead_channel
          lead_direction
        }
      }
      leads {
        _id
        customer_id
        assignee
        assigneeNode {
          username
          display_name
        }
        created
        dealer {
          dealer_name
          dealer_id
        }
        assigned
        dealer_id
        organization_id
        type
        typeName
        archived
        is_archived
        is_responded
        tags
        form_data {
          lead_type
          lead_source
          casl_opt_in
          primary_phone
          submitted_timestamp
          email
          primary_phone
          street_address_1
          city
          province
          postal_code
          customer_vehicle_year
          customer_vehicle_make
          customer_vehicle_model
          interested_vehicle_year
          interested_vehicle_make
          interested_vehicle_model
          message
        }
      }
    }
  `,
};

export default Leads;
