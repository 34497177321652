/* external */
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import React, { useEffect } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';

/* Material UI */
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import XIcon from '@mui/icons-material/Close';
import CarIcon from '@mui/icons-material/DriveEta';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import EditIcon from '@mui/icons-material/Edit';

import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';

import AppraisalSummary from 'modules/appraisals/components/AppraisalSummary';
import TradeFormContainer from 'modules/appraisals/components/TradeFormContainer';

/* internal */
import { AppraisalType } from 'modules/appraisals/const';
import { pitchStyles } from 'modules/pitches/utils';

import { vehicleTitle } from 'utils';

const PITCH_FRAGMENT = gql`
  fragment TradeInStepPitch on Pitch {
    id
    appraisalIds
    appraisals {
      id
      ...AppraisalSummaryAppraisal
      ...TradeFormContainerAppraisal
    }
    hideTrade
    hideCBBPrice
  }
  ${AppraisalSummary.fragments.appraisal}
  ${TradeFormContainer.fragments.appraisal}
`;

const UPDATE_PITCH = gql`
  mutation updatePitch($id: Int!, $data: PitchUpdate!) {
    pitches {
      updatePitch(id: $id, data: $data) {
        id
        ...TradeInStepPitch
      }
    }
  }
  ${PITCH_FRAGMENT}
`;

const useStyles = makeStyles(theme => ({
  button: pitchStyles.responsiveButton(theme),
  cancelButton: {
    margin: theme.spacing(1),
    ...theme.actions.cancel,
  },
  deleteIcon: pitchStyles.roundedRedIconButton(theme),
  iconButton: pitchStyles.roundedBlueIconButton(theme),
  nextButton: {
    backgroundColor: theme.actions.add.backgroundColor,
    color: 'white',
    margin: theme.spacing(1),
  },
  paragraph: pitchStyles.responsiveParagraph(theme),
}));

const TradeInStep = ({
  customerVehicles,
  editAppraisal,
  onCancel,
  onClose,
  onCreate, // ignore
  onFinish, // ignore
  onNext,
  onPrevious,
  onSkip,
  onUpdate,
  opportunity,
  pitch,
  refetch,
  setEditAppraisal,
  setShowTradeIn,
  showTradeIn,
  ...rest
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const [updatePitch, updatePitchMutation] = useMutation(UPDATE_PITCH);

  const { appraisalIds, appraisals, hideTrade, hideCBBPrice } = pitch;
  const { customer, appraisals: oppAppraisals } = opportunity;
  const { vehicles = [], appraisals: custAppraisals = [] } = customer;

  const dedupeById = things =>
    [...new Set(things.map(x => x.id))].map(uniqId =>
      things.find(({ id }) => id === uniqId),
    );

  const availableAppraisals = dedupeById(
    oppAppraisals
      .concat(custAppraisals)
      .filter(({ id }) => !appraisalIds.includes(id)),
  ).sort((a, b) => b.createdAt.localeCompare(a.createdAt));

  const defaultAppraisal = {
    opportunityId: opportunity._id,
    dealerId: opportunity.dealer_id,
    appraisalType: AppraisalType.TRADE_IN,
    firstName: customer.first_name,
    lastName: customer.last_name,
  };

  useEffect(() => {
    // hide tradein box if we're not editing an appraisal
    if (editAppraisal && !showTradeIn) setShowTradeIn(true);
    else if (!editAppraisal && showTradeIn) setShowTradeIn(false);
  }, [setShowTradeIn, showTradeIn, editAppraisal]);

  /* handlers */
  const handleCreateAppraisal = (appraisal, options = {}) => {
    updatePitch({
      variables: {
        id: pitch.id,
        data: { appraisalIds: [...appraisalIds, appraisal.id] },
      },
    }).then(() => onUpdate());
    if (options.closeEditor) setEditAppraisal(null);
    else setEditAppraisal(appraisal);
  };

  const handleRemoveAppraisal = appraisal =>
    updatePitch({
      variables: {
        id: pitch.id,
        data: { appraisalIds: appraisalIds.filter(x => x !== appraisal.id) },
      },
    }).then(onUpdate);

  // If we've selected a vin, just set the editAppraisal to the defaultAppraisal + the vin.
  // The components in TradeFormContainer should know what to do if they get an appraisal
  // with a vin but no ID.

  const handleSelectByVin = vin =>
    setEditAppraisal({ ...defaultAppraisal, vin });

  const handleUpdateAppraisal = () => {}; // do nothing

  const handleAddAppraisal = appraisal =>
    // Does the same thing as create appraisal, so let's just do that.
    handleCreateAppraisal(appraisal, { closeEditor: true });

  const handleClickHideTrade = e =>
    updatePitch({
      variables: {
        id: pitch.id,
        data: { hideTrade: e.target.checked },
      },
    });

  const handleClickHideCBBPrice = e =>
    updatePitch({
      variables: {
        id: pitch.id,
        data: { hideCBBPrice: e.target.checked },
      },
    });

  const handleClickNew = () => setEditAppraisal(defaultAppraisal);
  return (
    <Box {...rest}>
      <LoadingBackdrop open={updatePitchMutation.loading}>
        Saving...
      </LoadingBackdrop>
      {editAppraisal && (
        <TradeFormContainer
          appraisal={editAppraisal}
          onCancel={() => setEditAppraisal(null)}
          onCreate={handleCreateAppraisal}
          onUpdate={handleUpdateAppraisal}
        />
      )}
      {!editAppraisal && (
        <>
          <Box mt={3} mb={3}>
            <FormControlLabel
              checked={hideTrade}
              control={<Checkbox color="secondary" />}
              label="Hide Trade on Pitch Sheet"
              onChange={handleClickHideTrade}
            />
          </Box>
          <Box mt={3} mb={3}>
            <FormControlLabel
              checked={hideCBBPrice}
              control={<Checkbox color="secondary" />}
              label="Hide CBB Price on Pitch Sheet"
              onChange={handleClickHideCBBPrice}
            />
          </Box>
          {/* Customer vehicles */}
          {vehicles.length > 0 && (
            <Box mt={3} mb={3}>
              <Typography>
                Is the customer trading in one of their currently owned
                vehicles? If yes, please select one of the vehicles from the
                list below.
              </Typography>
              <Box display="flex" flexWrap="wrap" mt={2}>
                <Typography
                  variant="h6"
                  component="span"
                  style={{ marginRight: theme.spacing(2) }}
                >
                  Currently Owned:
                </Typography>
                {vehicles.map(vehicle => (
                  <Button
                    key={vehicle.vin}
                    className={`${classes.button}`}
                    disabled={!vehicle.vin}
                    onClick={() => handleSelectByVin(vehicle.vin)}
                    variant="contained"
                    style={{
                      marginRight: theme.spacing(2),
                      marginBottom: theme.spacing(2),
                      backgroundColor: 'PaleTurquoise',
                      color: 'black',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    <DriveEtaIcon />
                    {vehicleTitle(vehicle)}
                  </Button>
                ))}
              </Box>
            </Box>
          )}

          {/* New Trade-In Appraisal */}
          <Box mt={3} mb={3}>
            <Box fontSize="16px" fontWeight="bold">
              Start a new Trade-In Appraisal
            </Box>
            <Button
              variant="contained"
              onClick={handleClickNew}
              style={{
                ...theme.actions.add,
                marginTop: theme.spacing(2),
                marginLeft: theme.spacing(5),
              }}
            >
              New Trade
            </Button>
          </Box>

          {availableAppraisals.length > 0 && (
            <Box mb={5}>
              <Box fontSize="16px" fontWeight="bold">
                or Select An Existing Appraisal
              </Box>

              <Grid container spacing={2}>
                {availableAppraisals.map(appraisal => (
                  <Grid item key={appraisal.id} xs={12} sm={6}>
                    <Card onClick={() => handleAddAppraisal(appraisal)}>
                      <CardContent>
                        <AppraisalSummary appraisal={appraisal} noControls />
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}

          <hr />
          <Typography variant="h5" style={{ marginTop: theme.spacing(2) }}>
            Trade-Ins on This Pitch
          </Typography>
          {appraisals.map(appraisal => (
            <Box display="flex" alignItems="center" key={appraisal.id}>
              <Box flexBasis="48px" flexGrow={0} flexShrink={0}>
                <CarIcon />
              </Box>
              <AppraisalSummary appraisal={appraisal} flexGrow={1} />
              <Box flexBasis="48px" flexGrow={0} flexShrink={0}>
                <IconButton
                  className={classes.iconButton}
                  onClick={() => setEditAppraisal(appraisal)}
                  size="small"
                >
                  <EditIcon />
                </IconButton>
              </Box>

              <Box flexBasis="48px" flexGrow={0} flexShrink={0}>
                <IconButton
                  size="small"
                  className={classes.deleteIcon}
                  onClick={() => handleRemoveAppraisal(appraisal)}
                >
                  <XIcon />
                </IconButton>
              </Box>
            </Box>
          ))}
        </>
      )}
      {!editAppraisal && (
        <Box
          display="flex"
          justifyContent={mobile ? 'flex-start' : 'flex-end'}
          m={1}
        >
          <Button
            className={`${classes.cancelButton} ${classes.button}`}
            variant="contained"
            color="secondary"
            onClick={onCancel}
          >
            Cancel
          </Button>
          {!mobile && (
            <Button
              className={`${classes.cancelButton} ${classes.button}`}
              onClick={onPrevious}
              variant="contained"
            >
              Back
            </Button>
          )}
          <Button
            className={`${classes.nextButton} ${classes.button}`}
            disabled={updatePitchMutation.loading}
            onClick={onNext}
            variant="contained"
          >
            Next
          </Button>
          <Button
            className={`${classes.nextButton} ${classes.button}`}
            disabled={updatePitchMutation.loading}
            onClick={onSkip}
            variant="contained"
          >
            Skip
          </Button>
        </Box>
      )}
    </Box>
  );
};

TradeInStep.fragments = {
  pitch: PITCH_FRAGMENT,
  opportunity: gql`
    fragment TradeInStepOpportunity on Opportunity {
      _id
      customer {
        _id
        first_name
        last_name
        vehicles {
          make
          model
          trim
          vin
          year
        }
        appraisals {
          id
          createdAt
          ...AppraisalSummaryAppraisal
        }
      }
      customer_id
      dealer_id
      appraisals {
        id
        createdAt
        ...AppraisalSummaryAppraisal
      }
    }
    ${AppraisalSummary.fragments.appraisal}
  `,
};
TradeInStep.label = 'Trade In';
TradeInStep.optional = true;

export default TradeInStep;
