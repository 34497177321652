import React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';

import { formatPrice } from '../../../utils';

const useStyles = makeStyles(theme => ({
  blackBorder: {
    border: '2px',
    borderStyle: 'solid',
    borderColor: 'black',
    borderRadius: '0px',
  },
  bold: {
    fontWeight: 'bold',
  },
}));

const VerticalCenteredStackedText = ({ array }) => {
  const classes = useStyles();
  return (
    <Box style={{ width: '100%', height: '100%' }}>
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        alignItems="stretch"
      >
        {array.map((obj, index) => (
          <Box
            style={{
              width: '100%',
              height: '100%',
              marginBottom: index === 0 ? 10 : 0,
            }}
            className={classes.blackBorder}
            key={obj.text}
          >
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>
                <Typography style={{ padding: 2 }}>{obj.text}</Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.bold} style={{ padding: 2 }}>
                  {obj.value}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        ))}
      </Grid>
    </Box>
  );
};

const VerticalCenteredText = ({ text, value }) => {
  const classes = useStyles();
  return (
    <Box
      style={{ padding: 10, width: '100%', height: '100%', maxWidth: 150 }}
      className={classes.blackBorder}
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <Typography style={{ textAlign: 'center' }}>{text}</Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.bold} variant="h6">
            {value}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

const VerticalCenteredList = ({ array }) => {
  const classes = useStyles();
  return (
    <Box style={{ width: '100%', height: '100%', minWidth: 175 }}>
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        alignItems="stretch"
        spacing={4}
        style={{ padding: 0, marginTop: 0, marginBottom: 0, height: '100%' }}
      >
        {array.map((obj, index) => (
          <Grid
            item
            style={{
              paddingTop: index & 1 ? 5 : 0,
              paddingBottom: index & 1 ? 5 : 0,
              marginTop: 0,
              marginBottom: 0,
            }}
            key={obj.title}
          >
            <Typography
              className={classes.blackBorder}
              style={{ padding: 5 }}
            >{`${obj.value} ${obj.title}`}</Typography>
          </Grid>
        ))}
        {array.length === 2 && <Grid item></Grid>}
      </Grid>
    </Box>
  );
};

const BreakdownSummaryHeader = ({ metrics, stock_type }) => {
  const classes = useStyles();

  const totalOpenCount =
    metrics[`${stock_type}_pending_count`] +
    metrics[`${stock_type}_cash_count`] +
    metrics[`${stock_type}_approved_count`] +
    metrics[`${stock_type}_signed_count`];

  const totalCompletedCount =
    metrics[`${stock_type}_posted_count`] +
    metrics[`${stock_type}_delivered_count`];

  const getTotalGross = status =>
    metrics[`${status}_${stock_type}_frontend_gross`] +
    metrics[`${status}_${stock_type}_backend_gross`];

  const pendingGross = getTotalGross('pending');
  const cashGross = getTotalGross('cash');
  const approvedGross = getTotalGross('approved');
  const signedGross = getTotalGross('signed');

  const openFrontGross =
    metrics[`pending_${stock_type}_frontend_gross`] +
    metrics[`cash_${stock_type}_frontend_gross`] +
    metrics[`approved_${stock_type}_frontend_gross`] +
    metrics[`signed_${stock_type}_frontend_gross`];

  const openBackGross =
    metrics[`pending_${stock_type}_backend_gross`] +
    metrics[`cash_${stock_type}_backend_gross`] +
    metrics[`approved_${stock_type}_backend_gross`] +
    metrics[`signed_${stock_type}_backend_gross`];

  const completeFrontGross =
    metrics[`delivered_${stock_type}_frontend_gross`] +
    metrics[`posted_${stock_type}_frontend_gross`];

  const completeBackGross =
    metrics[`delivered_${stock_type}_backend_gross`] +
    metrics[`posted_${stock_type}_backend_gross`];

  return (
    <>
      <Typography className={classes.bold} variant="h5">
        {stock_type === 'new' ? 'New' : 'Used'}
      </Typography>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="stretch"
        spacing={2}
        style={{ margin: 5 }}
      >
        <Grid item>
          <VerticalCenteredText
            style={{ height: '100%', width: '100%', alignContent: 'center' }}
            text={'Open'}
            value={totalOpenCount}
          />
        </Grid>
        <Grid item>
          <VerticalCenteredList
            array={[
              {
                value: metrics[`${stock_type}_pending_count`] ?? 0,
                title: `(Pending ${formatPrice(pendingGross)})`,
              },
              {
                value: metrics[`${stock_type}_cash_count`] ?? 0,
                title: `(Cash ${formatPrice(cashGross)})`,
              },
              {
                value: metrics[`${stock_type}_approved_count`] ?? 0,
                title: `(Approved ${formatPrice(approvedGross)})`,
              },
              {
                value: metrics[`${stock_type}_signed_count`] ?? 0,
                title: `(Signed ${formatPrice(signedGross)})`,
              },
            ]}
          />
        </Grid>
        <Grid item>
          <VerticalCenteredStackedText
            array={[
              {
                text: 'Pre-App',
                value: metrics[`${stock_type}_pre_app_count`] ?? 0,
              },
              {
                text: 'Carry-Over',
                value: metrics[`${stock_type}_carryover_count`] ?? 0,
              },
            ]}
          />
        </Grid>
        <Grid item>
          <VerticalCenteredText
            text={'Completed'}
            value={totalCompletedCount}
          />
        </Grid>
        <Grid item>
          <VerticalCenteredList
            array={[
              {
                value: metrics[`${stock_type}_delivered_count`] ?? 0,
                title: 'Delivered',
              },
              {
                value: metrics[`${stock_type}_posted_count`] ?? 0,
                title: 'Posted',
              },
            ]}
          />
        </Grid>
        <Grid item>
          <VerticalCenteredText
            text={'Tubed'}
            value={metrics[`${stock_type}_tubed_count`] ?? 0}
          />
        </Grid>
        <Grid item>
          <VerticalCenteredText
            text={'Total Potential Gross'}
            value={formatPrice(
              pendingGross + approvedGross + cashGross + signedGross,
            )}
          />
        </Grid>
        <Grid item>
          <VerticalCenteredStackedText
            array={[
              {
                text: 'Back-Gross',
                value: formatPrice(openBackGross),
              },
              {
                text: 'Front-Gross',
                value: formatPrice(openFrontGross),
              },
            ]}
          />
        </Grid>
        <Grid item>
          <VerticalCenteredText
            text={'Total Complete Gross'}
            value={formatPrice(completeFrontGross + completeBackGross)}
          />
        </Grid>
        <Grid item>
          <VerticalCenteredStackedText
            array={[
              {
                text: 'Back-Gross',
                value: formatPrice(completeBackGross),
              },
              {
                text: 'Front-Gross',
                value: formatPrice(completeFrontGross),
              },
            ]}
          />
        </Grid>
        <Grid item>
          <VerticalCenteredList
            array={[
              {
                value: formatPrice(
                  metrics[`delivered_${stock_type}_frontend_gross`] +
                    metrics[`delivered_${stock_type}_backend_gross`],
                ),
                title: `Delivered`,
              },
              {
                value: formatPrice(
                  metrics[`posted_${stock_type}_frontend_gross`] +
                    metrics[`posted_${stock_type}_backend_gross`],
                ),
                title: `Posted`,
              },
              {
                value: formatPrice(metrics[`${stock_type}_variance_gross`]),
                title: `Variance`,
              },
            ]}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default BreakdownSummaryHeader;
