import React from 'react';
import gql from 'graphql-tag';

/* external */
import { useHistory } from 'react-router-dom';

/* Material UI */
import AddIcon from '@mui/icons-material/Add';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { useMediaQuery, useTheme } from '@mui/material';

/* internal */
import AddOpportunityModal from './AddOpportunityModal';
import DateDisplay from './DateDisplay';
import { DEAL_TYPE } from '../constant';
import { STATUS_ICON } from 'constants.js';
import { useUserContext } from 'components/MaterialUI/UserContext';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '300px',
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  tableCellBold: {
    paddingBottom: 0,
    paddingTop: 0,
    border: 'none',
    fontWeight: 'bold',
  },
  tableCell: {
    paddingBottom: 0,
    paddingTop: 0,
    border: 'none',
  },
  chip: {
    fontSize: 10,
    paddingLeft: 2,
    paddingRight: 2,
  },
  unknown: {
    backgroundColor: '#808080',
  },
  new: {
    backgroundColor: '#8ba22c',
  },
  used: {
    backgroundColor: '#f89406',
  },
}));

const OpportunitiesTab = ({ customer, isHidden }) => {
  const classes = useStyles();
  const history = useHistory();
  const desktop = useMediaQuery(theme => theme.breakpoints.up('lg'));
  const { currentUser } = useUserContext() || {};
  const [showAddOppModal, setAddOppModal] = React.useState(false);
  const theme = useTheme();
  const hasVehicleInDMSDeal = dms_deal => dms_deal.year && dms_deal.make_name;
  const stockText = dms_deal => `Stock#: ${dms_deal?.stock_number ?? 'NA'}`;
  const dealText = dms_deal => `Deal#: ${dms_deal?.deal_number ?? 'NA'}`;
  const dealerText = dealer => `Dealer: ${dealer.dealer_name}`;
  const vinText = dms_deal => `VIN: ${dms_deal?.vin ?? 'NA'}`;
  const oppText = opportunity =>
    `${opportunity.status_display} Opportunity at ${opportunity.dealer.dealer_name} `;

  return (
    <>
      <Dialog
        maxWidth="xs"
        open={showAddOppModal}
        onClose={() => setAddOppModal(false)}
        fullWidth={true}
      >
        <AddOpportunityModal
          handleClose={() => setAddOppModal(false)}
          customer={customer}
        />
      </Dialog>
      <Card className={classes.root}>
        <CardContent>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <Typography className={classes.title} gutterBottom>
                Open Opportunities
              </Typography>
            </Grid>
            <Grid item>
              {!isHidden && (
                <IconButton
                  size="small"
                  onClick={e => {
                    setAddOppModal(true);
                  }}
                >
                  <AddIcon style={theme.actions.add} />
                </IconButton>
              )}
            </Grid>
          </Grid>
          {customer.allOppsEverywhere.length === 0 &&
            'There are no opportunities for this customer'}
          {customer.allOppsEverywhere
            .sort((a, b) => b.created.localeCompare(a.created))
            .map(opportunity => {
              const isCurrentUser = currentUser.dealer_ids.includes(
                opportunity.dealer.dealer_id,
              );
              return (
                <Card
                  key={opportunity._id}
                  style={{ marginBottom: '15px', marginTop: '15px' }}
                  disabled={!isCurrentUser}
                  onClick={() =>
                    isCurrentUser &&
                    history.push(
                      `/opportunities/${opportunity._id}/${customer._id}/details`,
                    )
                  }
                >
                  <CardContent
                    style={{ paddingBottom: '5px', paddingTop: '5px' }}
                  >
                    <Grid
                      container
                      direction="row"
                      justifyContent={'flex-start'}
                      alignItems="flex-start"
                      spacing={1}
                    >
                      <Grid
                        item
                        style={{
                          paddingRight: 0,
                          paddingTop: desktop ? 8 : 16,
                        }}
                        xs={1}
                      >
                        <DateDisplay date={opportunity.created} />
                      </Grid>
                      <Hidden lgUp>
                        <Grid item xs={11} style={{ paddingLeft: 10 }}>
                          <List style={{ paddingTop: 0 }} dense={true}>
                            <ListItemText
                              primaryTypographyProps={{
                                style: { fontSize: 16, fontWeight: 'bold' },
                              }}
                              primary={
                                <>
                                  {oppText(opportunity)}
                                  <Icon
                                    style={{
                                      fontSize: 12,
                                      color:
                                        STATUS_ICON[opportunity.status].color,
                                    }}
                                    className={
                                      STATUS_ICON[opportunity.status].icon
                                    }
                                  />
                                </>
                              }
                            />
                            <ListItemText
                              primaryTypographyProps={{
                                style: { fontSize: 14, fontWeight: 'normal' },
                              }}
                              primary={
                                <>
                                  {hasVehicleInDMSDeal(opportunity.dms_deal)
                                    ? `Vehicle: ${
                                        opportunity.dms_deal?.year ?? ''
                                      } ${
                                        opportunity.dms_deal?.make_name ?? ''
                                      } ${
                                        opportunity.dms_deal?.model_name ?? ''
                                      } `
                                    : ' '}
                                  {hasVehicleInDMSDeal(opportunity.dms_deal) &&
                                    opportunity.dms_deal.deal_type && (
                                      <Chip
                                        size="small"
                                        label={
                                          DEAL_TYPE[
                                            opportunity.dms_deal.deal_type
                                          ]
                                        }
                                        color="primary"
                                        className={
                                          (classes.chip,
                                          DEAL_TYPE[
                                            opportunity.dms_deal.deal_type
                                          ] === 'New'
                                            ? classes.new
                                            : DEAL_TYPE[
                                                opportunity.dms_deal.deal_type
                                              ] === 'Used'
                                            ? classes.used
                                            : classes.unknown)
                                        }
                                      />
                                    )}
                                </>
                              }
                            />
                            <ListItem
                              style={{ paddingLeft: 0, paddingRight: 0 }}
                            >
                              <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                spacing={2}
                              >
                                <Grid item style={{ fontSize: 14 }} xs={6}>
                                  {dealText(opportunity.dms_deal)}
                                </Grid>
                                <Grid item style={{ fontSize: 14 }}>
                                  {stockText(opportunity.dms_deal)}
                                </Grid>
                              </Grid>
                            </ListItem>

                            <ListItem
                              style={{ paddingLeft: 0, paddingRight: 0 }}
                            >
                              <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                spacing={2}
                              >
                                <Grid item style={{ fontSize: 14 }} xs={6}>
                                  {dealerText(opportunity.dealer)}
                                </Grid>
                                <Grid item style={{ fontSize: 14 }}>
                                  {vinText(opportunity.dms_deal)}
                                </Grid>
                              </Grid>
                            </ListItem>
                          </List>
                        </Grid>
                      </Hidden>
                      <Hidden lgDown>
                        <Grid item xs={6}>
                          <List style={{ paddingTop: 0 }} dense={true}>
                            <ListItem className={classes.title}>
                              <ListItemText
                                primaryTypographyProps={{
                                  style: { fontSize: 14, fontWeight: 'bold' },
                                }}
                                secondaryTypographyProps={{
                                  style: { fontSize: 14, color: 'black' },
                                }}
                                primary={
                                  <>
                                    {oppText(opportunity)}
                                    <Icon
                                      style={{
                                        fontSize: 14,
                                        color:
                                          STATUS_ICON[opportunity.status].color,
                                      }}
                                      className={
                                        STATUS_ICON[opportunity.status].icon
                                      }
                                    />
                                  </>
                                }
                                secondary={
                                  <>
                                    {hasVehicleInDMSDeal(opportunity.dms_deal)
                                      ? `Vehicle: ${
                                          opportunity.dms_deal?.year ?? ''
                                        } ${
                                          opportunity.dms_deal?.make_name ?? ''
                                        } ${
                                          opportunity.dms_deal?.model_name ?? ''
                                        } `
                                      : ' '}
                                    {hasVehicleInDMSDeal(
                                      opportunity.dms_deal,
                                    ) &&
                                      opportunity.dms_deal.deal_type && (
                                        <Chip
                                          size="small"
                                          label={
                                            DEAL_TYPE[
                                              opportunity.dms_deal.deal_type
                                            ]
                                          }
                                          color="primary"
                                          className={
                                            (classes.chip,
                                            DEAL_TYPE[
                                              opportunity.dms_deal.deal_type
                                            ] === 'New'
                                              ? classes.new
                                              : DEAL_TYPE[
                                                  opportunity.dms_deal.deal_type
                                                ] === 'Used'
                                              ? classes.used
                                              : classes.unknown)
                                          }
                                        />
                                      )}
                                  </>
                                }
                              />
                            </ListItem>
                          </List>
                        </Grid>
                        <Grid item xs={2}>
                          <ListItemText
                            primaryTypographyProps={{
                              style: { fontSize: 14 },
                            }}
                            secondaryTypographyProps={{
                              style: { fontSize: 14, color: 'black' },
                            }}
                            primary={dealText(opportunity.dms_deal)}
                            secondary={dealerText(opportunity.dealer)}
                          />
                        </Grid>
                        <Grid item>
                          <ListItemText
                            primaryTypographyProps={{
                              style: { fontSize: 14 },
                            }}
                            secondaryTypographyProps={{
                              style: { fontSize: 14, color: 'black' },
                            }}
                            primary={stockText(opportunity.dms_deal)}
                            secondary={vinText(opportunity.dms_deal)}
                          />
                        </Grid>
                      </Hidden>
                    </Grid>
                  </CardContent>
                </Card>
              );
            })}
        </CardContent>
      </Card>
    </>
  );
};

OpportunitiesTab.fragments = {
  customer: gql`
    fragment OpportunitiesTabFragment on Customer {
      _id
      # Using -1 as first item in dealer_ids makes it fetch all dealers that exist
      allOppsEverywhere: opportunities(dealer_ids: [-1]) {
        _id
        dealer {
          dealer_name
          dealer_id
        }
        status_display
        status
        stock_type
        created
        dms_deal {
          vin
          deal_type
          deal_number
          stock_number
          year
          make_name
          model_name
        }
      }
    }
  `,
};

export default OpportunitiesTab;
