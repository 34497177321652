import React from 'react';

/* external */
import gql from 'graphql-tag';

/* Material UI */
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles(theme => ({
  contentPadding: {
    padding: theme.spacing(2),
  },
  fullHeight: {
    height: '100%',
  },
  labelCell: {
    width: '33%',
  },
}));

const ItemSummary = ({ item }) => {
  const { contentPadding, fullHeight, labelCell } = useStyles();
  const { dealer, createdByUser } = item;
  const { name: dealerName } = dealer || {};
  const { profile } = createdByUser || {};
  const {
    first_name: firstName,
    last_name: lastName,
    email_address: email,
    cell_phone: cell,
  } = profile || {};

  return (
    <Paper className={fullHeight}>
      <Box className={contentPadding}>
        <Typography variant="h5">Auction Item Details</Typography>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className={labelCell}>Seller</TableCell>
              <TableCell>{dealerName}</TableCell>
            </TableRow>
            {createdByUser && (
              <TableRow>
                <TableCell className={labelCell}>Contact</TableCell>
                <TableCell>
                  <Box>
                    {firstName} {lastName}
                  </Box>
                  {email && <Box>e. {email}</Box>}
                  {cell && <Box>c. {cell}</Box>}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Box>
    </Paper>
  );
};

ItemSummary.fragments = {
  item: gql`
    fragment ItemSummaryItem on AuctionItem {
      endsAt
      dealer {
        id
        name
      }
      createdByUser {
        profile {
          cell_phone
          email_address
          first_name
          last_name
        }
      }
    }
  `,
};
export default ItemSummary;
