import React, { useRef, useState } from 'react';

/* external */
import { useSnackbar } from 'notistack';
import { cloneDeep } from 'lodash';

/* Material UI */
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';

/* external */
import InspectionCheckboxes from '../InspectionCheckboxes';

const MOPARCPOForm = props => {
  const { enqueueSnackbar } = useSnackbar();

  // MOPAR form fields
  const initial = {
    c10: 'Vehicle is less than six years of age from original in-service date.',
    c11: 'Vehicle has less than 180,000 km.',
    c12: 'Odometer shows no sign of tampering and the metreage is supported by the body computer and by the general condition of the vehicle.',
    c13: 'On-board diagnostic system fault codes, if any, have been repaired.',
  };
  const hood = {
    c20: 'Engine block, cylinder head(s), intake and exhaust manifolds are free of cracks, damage or obvious improper repair.',
    c21: 'Oil and filter have been changed at the time of inspection.',
    c22: 'Oil leaks, if any, have been corrected.',
    c23: 'Automatic transmission fluid is at prescribed level and shows no signs of additives or foreign material, nor is it discoloured.',
    c24: 'Cooling system has been pressure-tested and shows no signs of leaks, additives or defective hoses.',
    c25: 'Power steering pump and gear assembly operate properly, show no sign of leaks and fluid is at prescribed level. Condition of fluid is good.',
    c26: 'Brake master cylinder shows no signs of leaks or damaged lines and fluid is a prescribed level.',
    c27: 'Air conditioning system shows no signs or leaks or abnormal noises and cools vehicle properly.',
  };
  const vehicle = {
    c30: 'Transmission case is free of cracks or damage.',
    c31: 'All fluids are at the prescribed levels and show no sign of additives.',
    c32: 'Fluid and oil leaks, if any, have been corrected.',
    c33: 'There is no sign of physical damage that could cause a future mechanical failure.',
    c34: 'Upper and lower ball joints are lubricated and show no sign of excessive freeplay.',
    c35: 'Disc brake calipers, wheel cylinders, brake lines and flexible hoses show no sign of leakage or deterioration.',
    c36: 'Struts display no sign of leakage or weakness.',
    c37: 'Suspension is not modified (e.g. oversized tires, lift kit).',
  };
  const road = {
    c40: 'Engine is operating smoothly at idle and normal driving speeds and has no abnormal noises.',
    c41: 'Engine oil pressure and water temperature are within specifications.',
    c42: 'Automatic or standard transmission shifts properly under all driving conditions and has no abnormal noises.',
    c43: 'Vibrations, excessive backlash, abnormal whine or noises, if any, have been corrected.',
    c44: 'Vehicle does not exhibit signs of excessive oil consumption (blue exhaust smoke, oil-fouled spark plug(s)).',
    c45: 'Brake pedal application effort is normal and no brake pull or noise is evident.',
    c46: 'Steering effort shows no sign of excessive play, wander or effort.',
  };
  const recalls = {
    c50: 'All outstanding recalls have been completed.',
  };

  let initialObj = cloneDeep(initial);
  let hoodObj = cloneDeep(hood);
  let vehicleObj = cloneDeep(vehicle);
  let roadObj = cloneDeep(road);
  let recallsObj = cloneDeep(recalls);

  Object.entries(initialObj).map(([name]) => (initialObj[name] = 'yes'));
  Object.entries(hoodObj).map(([name]) => (hoodObj[name] = 'yes'));
  Object.entries(vehicleObj).map(([name]) => (vehicleObj[name] = 'yes'));
  Object.entries(roadObj).map(([name]) => (roadObj[name] = 'yes'));
  Object.entries(recallsObj).map(([name]) => (recallsObj[name] = 'yes'));

  const [initialValues, setInitialValues] = useState(initialObj);
  const [hoodValues, setHoodValues] = useState(hoodObj);
  const [vehicleValues, setVehicleValues] = useState(vehicleObj);
  const [roadValues, setRoadValues] = useState(roadObj);
  const [recallsValues, setRecallsValues] = useState(recallsObj);

  const handleInitial = e => {
    const { name, value } = e.target;
    setInitialValues(prevInitialValues => ({
      ...prevInitialValues,
      [name]: value,
    }));
  };
  const handleHood = e => {
    const { name, value } = e.target;
    setHoodValues(prevHoodValues => ({
      ...prevHoodValues,
      [name]: value,
    }));
  };
  const handleVehicle = e => {
    const { name, value } = e.target;
    setVehicleValues(prevVehicleValues => ({
      ...prevVehicleValues,
      [name]: value,
    }));
  };
  const handleRoad = e => {
    const { name, value } = e.target;
    setRoadValues(prevRoadValues => ({
      ...prevRoadValues,
      [name]: value,
    }));
  };
  const handleRecalls = e => {
    const { name, value } = e.target;
    setRecallsValues(prevRecallsValues => ({
      ...prevRecallsValues,
      [name]: value,
    }));
  };

  const [consent, setConsent] = useState(false);
  const handleConsent = () => {
    setConsent(consent => !consent);
  };

  const [confirm, setConfirm] = useState(false);
  const handleConfirm = () => {
    setConfirm(confirm => !confirm);
  };

  const wholeForm = useRef(null);

  const handleSubmit = e => {
    e.preventDefault();

    if (!consent) {
      enqueueSnackbar(`Please check 'I agree' and then submit the inspection!`);
      return;
    }

    const values = {
      ...initialValues,
      ...hoodValues,
      ...vehicleValues,
      ...roadValues,
      ...recallsValues,
    };
    const form_data = {
      mopar: values,
      technician_comments: wholeForm.current['technician_comments'].value,
      amvic_number: wholeForm.current['amvic_number'].value,
    };

    props.formData(form_data);
    props.formClose();
  };

  const GreenButton = withStyles({
    root: {
      color: '#fff',
      backgroundColor: '#198754',
      '&:hover': {
        backgroundColor: '#157347',
      },
    },
  })(Button);

  return (
    <form onSubmit={handleSubmit} ref={wholeForm}>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>1. INITIAL INSPECTION</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(initial).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={initialValues}
                      handleGroup={handleInitial}
                      name={name}
                      orange={false}
                      greenValue="yes"
                      redValue="no"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>2. UNDER HOOD</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(hood).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={hoodValues}
                      handleGroup={handleHood}
                      name={name}
                      orange={false}
                      greenValue="yes"
                      redValue="no"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>3. UNDER VEHICLE</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(vehicle).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={vehicleValues}
                      handleGroup={handleVehicle}
                      name={name}
                      orange={false}
                      greenValue="yes"
                      redValue="no"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>4. ROAD TEST</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(road).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={roadValues}
                      handleGroup={handleRoad}
                      name={name}
                      orange={false}
                      greenValue="yes"
                      redValue="no"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>5. RECALLS</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(recalls).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={recallsValues}
                      handleGroup={handleRecalls}
                      name={name}
                      orange={false}
                      greenValue="yes"
                      redValue="no"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>

      <TextField
        label="Technician Comments"
        multiline
        minRows={2}
        variant="outlined"
        name={'technician_comments'}
        fullWidth
        style={{ marginTop: '15px' }}
      />
      <Typography style={{ marginTop: '15px' }}>
        I verify the contents of this document are true and correct.
      </Typography>
      <FormControlLabel
        control={<Checkbox />}
        label="I agree"
        onChange={handleConsent}
        value={consent}
      />
      <br />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Technician Name"
            value={props.user.display_name}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Tech #"
            value={props.user.goUserProfile.profile.sales_licence}
            fullWidth
            name={'amvic_number'}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Date"
            value={new Date().toLocaleString()}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <Button
            type="button"
            variant="contained"
            style={{ marginRight: '15px' }}
            onClick={handleConfirm}
            color="default"
          >
            Cancel
          </Button>
          <Dialog
            open={confirm}
            onClose={handleConfirm}
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Current inspection will be closed and unsaved data will be gone.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleConfirm}>Cancel</Button>
              <Button onClick={props.formClose} color="secondary">
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
          <GreenButton type="submit" variant="contained">
            Save
          </GreenButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default MOPARCPOForm;
