// *************************************************************************************
// THIS IS THE CODE FOR THE ORIGINAL ACCOUNTING CHECKLIST (not province or OEM specific).
// THIS WILL EVENTUALLY BE DEPRECATED
// BUT WE WANT TO LEAVE IT IN HERE FOR NOW IN CASE THEY WANT TO REFER BACK
// TO THE 'OLD' CHECKLIST

import React, { useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { cloneDeep, get } from 'lodash';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
  Typography,
  useTheme,
} from '@mui/material';

import { makeStyles } from '@mui/styles';

import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';

import {
  PREV_ACCOUNTING_LIST,
  PREV_ADDS_CHECKLIST,
  PREV_CAR_POLICY,
  PREV_GST_EXEMPT_LIST,
  PREV_NO_TITLE_CHECKLIST,
  PREV_STANDARD_CHECKLIST,
  PREV_STIPS_CHECKLIST,
  PREV_TRADE_LIST,
  PREV_US_DISCLAIMER,
  PREV_USED_ONLY_LIST,
} from './const';
import { Status } from 'constants.js';

const GET_CHECKLIST_DETAILS = gql`
  query getChecklistData($_id: ID!) {
    opportunity(_id: $_id) {
      _id
      finance_checklist
      accounting_checklist
      extra_checklist
    }
  }
`;

const GENERATE_PRINTABLE_PDF = gql`
  mutation generateDeallogPDF($id: ID!, $pdf_type: String!) {
    generateDeallogPDF(_id: $id, pdf_type: $pdf_type) {
      key
    }
  }
`;

const GENERATE_ACCOUNTING_PDF = gql`
  mutation generateAccountingPDF($id: ID!, $fields: AccountingChecklist!) {
    generateAccountingPDF(_id: $id, fields: $fields) {
      key
    }
  }
`;

const useStyles = makeStyles({
  checkbox: {
    textAlign: 'center',
  },
  text: {
    paddingTop: '9px',
  },
  clickable: {
    paddingTop: '9px',
    textDecoration: 'underline',
    color: 'blue',
  },
  customerInfo: {
    backgroundColor: 'lightgrey',
    padding: '5px',
  },
  alignRight: {
    textAlign: 'right',
  },
  indentCheckbox: {
    textAlign: 'center',
    paddingLeft: '15px',
  },
  indentText: {
    paddingTop: '9px',
    paddingLeft: '15px',
  },
  columnPadding: {
    paddingTop: '15px',
  },
});

const TitleGrid = () => {
  const styles = useStyles();
  return (
    <Grid container direction="row">
      <Grid item xs={1} className={styles.checkbox}>
        <Typography variant="h6">A</Typography>
      </Grid>
      <Grid item xs={1} className={styles.checkbox}>
        <Typography variant="h6">F</Typography>
      </Grid>
      <Grid item xs={10} />
    </Grid>
  );
};

const PrevAccountingChecklistModal = ({
  opportunity,
  onClose,
  isOpen,
  tradeName,
}) => {
  const theme = useTheme();
  const styles = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [fields, setFields] = useState({
    accounting_checklist: {},
    finance_checklist: {},
    extra_checklist: {},
  });

  const [fetchData, { data, loading }] = useLazyQuery(GET_CHECKLIST_DETAILS, {
    variables: { _id: opportunity._id },
    onCompleted: d => {
      setFields({
        accounting_checklist: d?.opportunity?.accounting_checklist ?? {},
        finance_checklist: d?.opportunity?.finance_checklist ?? {},
        extra_checklist: d?.opportunity?.extra_checklist ?? {},
      });
    },
  });

  const [generatePDF, generatePDFMutation] = useMutation(
    GENERATE_PRINTABLE_PDF,
  );
  const [accountingPDF, accountPDFMutation] = useMutation(
    GENERATE_ACCOUNTING_PDF,
  );

  const generatePrintablePDF = (id, pdf_type) => {
    generatePDF({ variables: { id, pdf_type } }).then(
      ({ data }) => window.open(data.generateDeallogPDF.key, '_blank'),
      error =>
        enqueueSnackbar(`Error generating PDF: ${JSON.stringify(error)}`, {
          variant: 'error',
        }),
    );
  };

  const generateAccountingPDF = (id, fields) => {
    accountingPDF({ variables: { id, fields } }).then(
      ({ data }) => window.open(data.generateAccountingPDF.key, '_blank'),
      error =>
        enqueueSnackbar(`Error generating PDF: ${JSON.stringify(error)}`, {
          variant: 'error',
        }),
    );
  };

  const handleCheck = (event, title) => {
    const updateFields = cloneDeep(fields);
    updateFields[title][event.target.name] = event.target.checked;
    setFields(updateFields);
  };

  const isChecked = (field, title) => {
    return get(fields, `${title}.${field}`, false);
  };

  // Only fetch if we don't have data
  if (isOpen && data === undefined && !loading) {
    fetchData();
  }

  const isLocked = Status.DEALLOG_LOCKED.includes(opportunity.status);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="lg"
      fullWidth={true}
      scroll="paper"
    >
      <DialogTitle>
        <Typography variant="h4">
          Deal #{opportunity.dms_deal?.deal_number || 'N/A'}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <LoadingBackdrop
          open={
            generatePDFMutation.loading || accountPDFMutation.loading || loading
          }
        >
          Generating PDF
        </LoadingBackdrop>
        <Grid container spacing={1}>
          {/* Left Column */}
          <Grid item xs={6} style={{ paddingLeft: '15px' }}>
            <Grid container spacing={1} direction="column">
              <Typography variant="h6">Accounting Checklist</Typography>
              <TitleGrid />
              {PREV_ACCOUNTING_LIST.map(item => (
                <Grid container key={item.desc}>
                  <Grid item xs={1} className={styles.checkbox}>
                    <Checkbox
                      checked={isChecked(item.a, 'accounting_checklist')}
                      onChange={e => handleCheck(e, 'accounting_checklist')}
                      name={item.a}
                      disabled={isLocked}
                    />
                  </Grid>
                  <Grid item xs={1} className={styles.checkbox}>
                    <Checkbox
                      checked={isChecked(item.f, 'finance_checklist')}
                      onChange={e => handleCheck(e, 'finance_checklist')}
                      name={item.f}
                      disabled={isLocked}
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <Typography variant="body1" className={styles.text}>
                      {item.desc}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
              {tradeName !== '' && (
                <>
                  <Typography variant="h6">Trade - {tradeName}</Typography>
                  <TitleGrid />
                  {PREV_TRADE_LIST.map(item => (
                    <Grid container key={item.desc}>
                      <Grid item xs={1} className={styles.checkbox}>
                        <Checkbox
                          checked={isChecked(item.a, 'accounting_checklist')}
                          onChange={e => handleCheck(e, 'accounting_checklist')}
                          name={item.a}
                          disabled={isLocked}
                        />
                      </Grid>
                      <Grid item xs={1} className={styles.checkbox}>
                        <Checkbox
                          checked={isChecked(item.f, 'finance_checklist')}
                          onChange={e => handleCheck(e, 'finance_checklist')}
                          name={item.f}
                          disabled={isLocked}
                        />
                      </Grid>
                      <Grid item xs={10}>
                        <Typography variant="body1" className={styles.text}>
                          {item.desc}
                        </Typography>
                      </Grid>
                    </Grid>
                  ))}
                </>
              )}
              {opportunity.deal_type === 'Used' && (
                <>
                  <Typography variant="h6">Used Deals Only</Typography>
                  <TitleGrid />
                  {PREV_USED_ONLY_LIST.map(item => (
                    <Grid container key={item.desc}>
                      <Grid item xs={1} className={styles.checkbox}>
                        <Checkbox
                          checked={isChecked(item.a, 'accounting_checklist')}
                          onChange={e => handleCheck(e, 'accounting_checklist')}
                          name={item.a}
                          disabled={isLocked}
                        />
                      </Grid>
                      <Grid item xs={1} className={styles.checkbox}>
                        <Checkbox
                          checked={isChecked(item.f, 'finance_checklist')}
                          onChange={e => handleCheck(e, 'finance_checklist')}
                          name={item.f}
                          disabled={isLocked}
                        />
                      </Grid>
                      <Grid item xs={10}>
                        <Typography
                          variant="body1"
                          className={
                            item.pdf === false ? styles.text : styles.clickable
                          }
                          onClick={() => {
                            if (item.pdf !== false) {
                              generatePrintablePDF(opportunity._id, item.pdf);
                            }
                          }}
                        >
                          {item.desc}
                        </Typography>
                      </Grid>
                    </Grid>
                  ))}
                </>
              )}
              <Typography variant="h6">2 Car Deal Policy</Typography>
              <TitleGrid />
              {PREV_CAR_POLICY.map(item => (
                <Grid container key={item.desc}>
                  <Grid item xs={1} className={styles.checkbox}>
                    <Checkbox
                      checked={isChecked(item.a, 'accounting_checklist')}
                      onChange={e => handleCheck(e, 'accounting_checklist')}
                      name={item.a}
                      disabled={isLocked}
                    />
                  </Grid>
                  <Grid item xs={1} className={styles.checkbox}>
                    <Checkbox
                      checked={isChecked(item.f, 'finance_checklist')}
                      onChange={e => handleCheck(e, 'finance_checklist')}
                      name={item.f}
                      disabled={isLocked}
                    />
                  </Grid>
                  <Grid item xs={10}>
                    {item.pdf && (
                      <Typography className={styles.clickable}>
                        <Link
                          href="#"
                          variant="body1"
                          color="inherit"
                          onClick={e => {
                            e.preventDefault();
                            generatePrintablePDF(opportunity._id, item.pdf);
                          }}
                        >
                          {item.desc}
                        </Link>
                      </Typography>
                    )}
                    {!item.pdf && (
                      <Typography className={styles.text} variant="body1">
                        {item.desc}
                      </Typography>
                    )}
                    {item.info !== false && (
                      <Typography className={styles.clickable}>
                        <Link
                          href={item.info}
                          target="_blank"
                          rel="noopener"
                          color="inherit"
                          variant="body2"
                        >
                          (Info PDF)
                        </Link>
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              ))}
              <Typography variant="h6">US Disclaimer</Typography>
              <TitleGrid />
              {PREV_US_DISCLAIMER.map(item => (
                <Grid container key={item.desc}>
                  <Grid item xs={1} className={styles.checkbox}>
                    <Checkbox
                      checked={isChecked(item.a, 'accounting_checklist')}
                      onChange={e => handleCheck(e, 'accounting_checklist')}
                      name={item.a}
                      disabled={isLocked}
                    />
                  </Grid>
                  <Grid item xs={1} className={styles.checkbox}>
                    <Checkbox
                      checked={isChecked(item.f, 'finance_checklist')}
                      onChange={e => handleCheck(e, 'finance_checklist')}
                      name={item.f}
                      disabled={isLocked}
                    />
                  </Grid>
                  <Grid item xs={10}>
                    {item.pdf && (
                      <Typography className={styles.clickable}>
                        <Link
                          href="#"
                          variant="body1"
                          color="inherit"
                          onClick={e => {
                            e.preventDefault();
                            generatePrintablePDF(opportunity._id, item.pdf);
                          }}
                        >
                          {item.desc}
                        </Link>
                      </Typography>
                    )}
                    {!item.pdf && (
                      <Typography className={styles.text} variant="body1">
                        {item.desc}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              ))}
              <Typography variant="h6">First Nations GST Exempt</Typography>
              <TitleGrid />
              {PREV_GST_EXEMPT_LIST.map(item => (
                <Grid container key={item.desc}>
                  <Grid item xs={1} className={styles.checkbox}>
                    <Checkbox
                      checked={isChecked(item.a, 'accounting_checklist')}
                      onChange={e => handleCheck(e, 'accounting_checklist')}
                      name={item.a}
                      disabled={isLocked}
                    />
                  </Grid>
                  <Grid item xs={1} className={styles.checkbox}>
                    <Checkbox
                      checked={isChecked(item.f, 'finance_checklist')}
                      onChange={e => handleCheck(e, 'finance_checklist')}
                      name={item.f}
                      disabled={isLocked}
                    />
                  </Grid>
                  <Grid item xs={10}>
                    {item.pdf && (
                      <Typography className={styles.clickable}>
                        <Link
                          href="#"
                          variant="body1"
                          color="inherit"
                          onClick={e => {
                            e.preventDefault();
                            generatePrintablePDF(opportunity._id, item.pdf);
                          }}
                        >
                          {item.desc}
                        </Link>
                      </Typography>
                    )}
                    {!item.pdf && (
                      <Typography className={styles.text} variant="body1">
                        {item.desc}
                      </Typography>
                    )}
                    {item.info !== false && (
                      <Typography className={styles.clickable}>
                        <Link
                          href={item.info}
                          target="_blank"
                          rel="noopener"
                          color="inherit"
                          variant="body2"
                        >
                          (Info PDF)
                        </Link>
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
          {/* Right Column */}
          <Grid item xs={6}>
            <Grid container spacing={1} direction="column">
              <Grid
                container
                direction="row"
                spacing={1}
                justifyContent="space-between"
                alignItems="center"
                className={styles.customerInfo}
              >
                <Grid item xs={6}>
                  <Typography variant="h6">Customer:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" align="right">
                    {opportunity.customer_name ||
                      opportunity.customer?.company_name}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h6">Date:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" align="right">
                    {moment().format('MM-DD-YYYY')}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h6">Deal Number:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" align="right">
                    {opportunity.dms_deal?.deal_number || 'N/A'}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h6">Stock Number:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" align="right">
                    {opportunity.dms_deal?.stock_number || 'N/A'}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h6">Salesperson 1:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" align="right">
                    {opportunity.sales_reps[0]?.display_name || ''}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h6">Salesperson 2:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" align="right">
                    {opportunity.sales_reps[1]?.display_name || ''}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h6">Business Manager:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" align="right">
                    {opportunity.finance_managers[0]?.display_name || ''}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h6">Sales Manager:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" align="right">
                    {opportunity.sales_managers[0]?.display_name || ''}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container direction="row" className={styles.columnPadding}>
                <Grid item xs={4}>
                  {PREV_NO_TITLE_CHECKLIST.map(item => (
                    <Grid container direction="row" key={item.desc}>
                      <Grid item xs={3} className={styles.checkbox}>
                        <Checkbox
                          checked={isChecked(item.a, 'extra_checklist')}
                          onChange={e => handleCheck(e, 'extra_checklist')}
                          name={item.a}
                          disabled={isLocked}
                        />
                      </Grid>
                      <Grid item xs={9}>
                        <Typography variant="body1" className={styles.text}>
                          {item.desc}
                        </Typography>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
                <Grid item xs={4}>
                  <Grid container direction="row">
                    <Grid item xs={12}>
                      <Typography variant="h6">Standard</Typography>
                    </Grid>
                    {PREV_STANDARD_CHECKLIST.map(item => (
                      <Grid container direction="row" key={item.desc}>
                        <Grid item xs={3} className={styles.checkbox}>
                          <Checkbox
                            checked={isChecked(item.a, 'extra_checklist')}
                            onChange={e => handleCheck(e, 'extra_checklist')}
                            name={item.a}
                            disabled={isLocked}
                          />
                        </Grid>
                        <Grid item xs={9}>
                          <Typography variant="body1" className={styles.text}>
                            {item.desc}
                          </Typography>
                        </Grid>
                        {item.desc === 'Go Card Activated' && (
                          <>
                            <Grid item xs={3} className={styles.indentCheckbox}>
                              <Checkbox
                                checked={isChecked(
                                  'Button1',
                                  'extra_checklist',
                                )}
                                onChange={e =>
                                  handleCheck(e, 'extra_checklist')
                                }
                                name="Button1"
                                disabled={isLocked}
                              />
                            </Grid>
                            <Grid item xs={9}>
                              <Typography
                                variant="body1"
                                className={styles.indentText}
                              >
                                Number in ADP
                              </Typography>
                            </Grid>
                          </>
                        )}
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Grid container direction="row">
                    <Grid item xs={12}>
                      <Typography variant="h6">Adds</Typography>
                    </Grid>
                    {PREV_ADDS_CHECKLIST.map(item => (
                      <Grid container direction="row" key={item.desc}>
                        <Grid
                          item
                          xs={3}
                          key={item.desc}
                          className={styles.checkbox}
                        >
                          <Checkbox
                            checked={isChecked(item.a, 'extra_checklist')}
                            onChange={e => handleCheck(e, 'extra_checklist')}
                            name={item.a}
                            disabled={isLocked}
                          />
                        </Grid>
                        <Grid item xs={9}>
                          <Typography variant="body1" className={styles.text}>
                            {item.desc}
                          </Typography>
                        </Grid>
                        {item.desc === 'Warranty' && (
                          <>
                            <Grid item xs={3} className={styles.indentCheckbox}>
                              <Checkbox
                                checked={isChecked(
                                  'Button2',
                                  'extra_checklist',
                                )}
                                onChange={e =>
                                  handleCheck(e, 'extra_checklist')
                                }
                                name="Button2"
                                disabled={isLocked}
                              />
                            </Grid>
                            <Grid item xs={9}>
                              <Typography
                                variant="body1"
                                className={styles.indentText}
                                disabled={isLocked}
                              >
                                on the side
                              </Typography>
                            </Grid>
                            <Grid item xs={3} className={styles.indentCheckbox}>
                              <Checkbox
                                checked={isChecked(
                                  'Button3',
                                  'extra_checklist',
                                )}
                                onChange={e =>
                                  handleCheck(e, 'extra_checklist')
                                }
                                name="Button3"
                                disabled={isLocked}
                              />
                            </Grid>
                            <Grid item xs={9}>
                              <Typography
                                variant="body1"
                                className={styles.indentText}
                              >
                                3 months / 5000km (used only)
                              </Typography>
                            </Grid>
                          </>
                        )}
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container direction="row">
                    <Grid item xs={12}>
                      <Typography variant="h6">Stips</Typography>
                    </Grid>
                    {PREV_STIPS_CHECKLIST.map(item => (
                      <Grid container direction="row" key={item.desc}>
                        <Grid item xs={3} className={styles.checkbox}>
                          <Checkbox
                            checked={isChecked(item.a, 'extra_checklist')}
                            onChange={e => handleCheck(e, 'extra_checklist')}
                            name={item.a}
                            disabled={isLocked}
                          />
                        </Grid>
                        <Grid item xs={9}>
                          <Typography variant="body1" className={styles.text}>
                            {item.desc}
                          </Typography>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="outlined">
          Close
        </Button>
        <Button
          style={theme.actions.confirm}
          onClick={() => generateAccountingPDF(opportunity._id, fields)}
          variant="contained"
          color="primary"
        >
          Download
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PrevAccountingChecklistModal;
