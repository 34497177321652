import { useMutation, useQuery } from '@apollo/react-hooks';
import { useMediaQuery } from '@mui/material';

/* Material UI */
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/AddCircle';
import BlockIcon from '@mui/icons-material/Block';
import DeleteIcon from '@mui/icons-material/Delete';
import EmailIcon from '@mui/icons-material/Email';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import UnknownIcon from '@mui/icons-material/Help';
import MailIcon from '@mui/icons-material/Mail';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import SmsIcon from '@mui/icons-material/Sms';
import { useUserContext } from 'components/MaterialUI/UserContext';
import { Role, snackBarSettings, Regexps } from 'constants.js';

/* external */
import gql from 'graphql-tag';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';

/* internal */
import { formatPhoneNumber } from 'utils';

const GATEWAYS_QUERY = gql`
  query gateways {
    gateways {
      getGateways {
        gateways {
          id
          gateway_type
          name
          phone_number
        }
      }
    }
  }
`;

const SEND_SMS_MESSAGE_MUTATION = gql`
  mutation sendSMS($gateway_id: ID!, $message: SMSMessageInput!) {
    sendSMS(gateway_id: $gateway_id, message: $message) {
      message {
        id
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  bottomLeftBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  text: {
    fontSize: 14,
  },
  listItemAvatar: {
    minWidth: 40,
  },
  mobileButtonText: {
    fontSize: 10,
    marginLeft: 5,
    marginRight: 5,
  },
}));

const identityTypeIcon = identityType => {
  switch (identityType) {
    case 'email':
      return <MailIcon />;
    case 'sms':
      return <SmartphoneIcon />;
    default:
      return <UnknownIcon />;
  }
};

const IdentityList = ({ customer, onDelete, isHidden }) => {
  const classes = useStyles();
  const theme = useTheme();
  const desktop = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const [confirmModal, setConfirmModal] = useState(false);
  const [sendSMSModal, setSMSModal] = useState(false);
  const [selectedIdentity, setSelectedIdentity] = useState({});
  const [gateway, setGateway] = React.useState('');
  const { data: gateData } = useQuery(GATEWAYS_QUERY);
  const [createSMSMessage] = useMutation(SEND_SMS_MESSAGE_MUTATION);
  const { enqueueSnackbar } = useSnackbar();

  const suppressed = number =>
    customer.global_suppressions
      ?.filter(x => x?.number === number)
      .some(x => x.suppressed);

  const gateways =
    gateData?.gateways.getGateways?.gateways.sort((a, b) =>
      a.name.localeCompare(b.name),
    ) || [];

  const handleChange = event => {
    setGateway(event.target.value);
  };

  const [textMessage, setTextMessage] = React.useState('');
  const handleTextChange = event => {
    setTextMessage(event.target.value);
  };

  const sendSMSMessage = () => {
    let message = {};
    if (
      customer.identities.filter(x => x.identity_type === 'sms').length === 1 &&
      (customer.fullname || (customer.first_name && customer.last_name)) &&
      gateway &&
      textMessage
    ) {
      message = {
        sent_to: customer.identities.filter(x => x.identity_type === 'sms')[0]
          .identity_value,
        body: textMessage,
      };
      // SMS Mutation here
      createSMSMessage({ variables: { gateway_id: gateway, message } }).then(
        res => {
          if (res?.data?.sendSMS?.message?.id) {
            enqueueSnackbar(
              `SMS Message successfully sent to ${customer.first_name} ${customer.last_name}`,
              {
                ...snackBarSettings,
                variant: 'success',
              },
            );
            setSMSModal(false);
            setTextMessage('');
            setGateway('');
          } else {
            enqueueSnackbar(
              'Error sending SMS, please try again later or contact support.',
              {
                ...snackBarSettings,
                variant: 'error',
              },
            );
            setSMSModal(false);
            setTextMessage('');
            setGateway('');
          }
        },
      );
    } else {
      enqueueSnackbar(
        'Please make sure Customer first and last name, gateway, and text message fields are filled.',
        { ...snackBarSettings, variant: 'error' },
      );
    }
  };

  return (
    <>
      <Dialog
        open={confirmModal}
        onClose={() => setConfirmModal(false)}
        maxWidth="xs"
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography variant="body2">
            {selectedIdentity.identity_type === 'email'
              ? 'Removing this identity email address will DISCONNECT the customer’s profile from their Go Auto App account. If you proceed with this action the customer will see an empty profile on their next login.'
              : 'Are you sure you want to delete this identity from the customer?'}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setConfirmModal(false)}
            color="primary"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            style={{ backgroundColor: 'firebrick' }}
            onClick={() => {
              onDelete(
                selectedIdentity.identity_type,
                selectedIdentity.identity_value,
              );
              setConfirmModal(false);
            }}
            variant="contained"
            color="primary"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={sendSMSModal}
        onClose={() => setSMSModal(false)}
        maxWidth="xs"
      >
        <DialogTitle>Send SMS to Customer</DialogTitle>
        <DialogContent>
          <FormControl variant="outlined" style={{ width: '250px' }}>
            <InputLabel id="demo-simple-select-outlined-label">
              Gateways
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={gateway}
              onChange={handleChange}
              label="Gateway"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {gateways.map(gate => (
                <MenuItem value={gate.id} key={gate.id}>
                  {gate.name} - {gate.gateway_type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <br /> <br />
          <TextField
            id="outlined-name"
            label="SMS Message"
            multiline
            rows={4}
            value={textMessage}
            onChange={handleTextChange}
            variant="outlined"
            style={{ width: '250px' }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setSMSModal(false)}
            color="primary"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={() => sendSMSMessage()}
            variant="contained"
            style={theme.actions.confirm}
          >
            Send Text
          </Button>
        </DialogActions>
      </Dialog>
      {!isHidden ? (
        <List
          dense={true}
          style={{
            alignitems: 'stretch',
            width: '100%',
            paddingTop: desktop ? 10 : 0,
          }}
        >
          {customer.identities
            .sort((a, b) => {
              if (a.identity_type === 'email') {
                return -1;
              }
              return 1;
            })
            .map(identity => (
              <ListItem key={JSON.stringify(identity)}>
                <ListItemAvatar className={classes.listItemAvatar}>
                  {identityTypeIcon(identity.identity_type)}
                </ListItemAvatar>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography className={classes.text}>
                      {identity.identity_type === 'sms'
                        ? formatPhoneNumber(identity.identity_value)
                        : identity.identity_value}
                    </Typography>
                    {identity.identity_type === 'sms' &&
                      suppressed(identity.identity_value, customer) && (
                        <Tooltip title="Globally Suppressed">
                          <BlockIcon
                            style={{
                              color: 'red',
                              marginLeft: theme.spacing(1),
                            }}
                          />
                        </Tooltip>
                      )}
                  </Grid>
                  <Grid item>
                    {identity.identity_type === 'sms' && (
                      <IconButton
                        size="medium"
                        onClick={() => {
                          setSMSModal(true);
                        }}
                      >
                        <SmsIcon />
                      </IconButton>
                    )}
                    <IconButton
                      size="small"
                      onClick={() => {
                        setConfirmModal(true);
                        setSelectedIdentity(identity);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </ListItem>
            ))}
        </List>
      ) : (
        'Identities are hidden when a profile is listed as do not contact'
      )}
    </>
  );
};

const EmailIdentityModal = ({ emails, open, onClose, onSelect }) => {
  const [error, setError] = useState(false);
  const emailInput = React.useRef();
  const handleSelect = email => {
    if (Regexps.EmailFormat.test(email)) {
      setError(false);
      onSelect(email?.trim());
      onClose();
    } else {
      setError(true);
    }
  };
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add Identity</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Select an email address to use as the identity for this customer
          profile.
        </DialogContentText>
      </DialogContent>
      <List>
        {emails.map(item => (
          <ListItem
            button
            onClick={() => handleSelect(item.email)}
            key={item.email}
          >
            <ListItemAvatar>
              <Avatar>
                <EmailIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText>{item.email}</ListItemText>
          </ListItem>
        ))}
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <AddIcon
                onClick={() => {
                  if (emailInput.current.value !== '') {
                    handleSelect(emailInput.current.value);
                  }
                }}
              />
            </Avatar>
          </ListItemAvatar>
          <TextField
            fullWidth
            type="email"
            inputRef={emailInput}
            error={error}
            helperText={error ? 'Please enter a valid email' : ''}
          />
        </ListItem>
      </List>
    </Dialog>
  );
};

const SMSIdentityModal = ({ phoneNumbers, open, onClose, onSelect }) => {
  const [error, setError] = useState(false);
  const phoneInput = React.useRef();
  const handleSelect = phoneNumber => {
    if (Regexps.PhoneFormat.NANP.test(phoneNumber)) {
      setError(false);
      onSelect(phoneNumber);
      onClose();
    } else {
      setError(true);
    }
  };
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add Identity</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Select a phone to use as the identity for this customer profile. The
          number must be unique to this customer and will be used for SMS
          communication.
        </DialogContentText>
      </DialogContent>
      <List>
        {phoneNumbers.map(phone => (
          <ListItem button onClick={() => handleSelect(phone)} key={phone}>
            <ListItemAvatar>
              <Avatar>
                <SmartphoneIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText>{phone}</ListItemText>
          </ListItem>
        ))}
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <AddIcon onClick={() => handleSelect(phoneInput.current.value)} />
            </Avatar>
          </ListItemAvatar>
          <TextField
            fullWidth
            type="phone"
            inputRef={phoneInput}
            error={error}
            helperText={error ? 'Please enter a valid phone number' : ''}
          />
        </ListItem>
      </List>
    </Dialog>
  );
};

const Identities = ({ customer, onAdd, onDelete }) => {
  const theme = useTheme();
  const classes = useStyles();
  const desktop = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const { currentUser } = useUserContext() || {};
  const isLocked = customer?.do_not_contact || false;
  const isHidden = currentUser.role !== Role.ORGANIZATION_ADMIN && isLocked;
  const modes = {
    view: 1,
    selectEmail: 2,
    addEmail: 3,
    selectPhone: 4,
  };

  const [mode, setMode] = React.useState(modes.view);

  const hasEmailIdentity = customer.identities
    .map(i => i.identity_type)
    .includes('email');
  const hasSMSIdentity = customer.identities
    .map(i => i.identity_type)
    .includes('sms');

  const phoneNumbers = [
    customer.phone,
    customer.cell_phone,
    customer.home_phone,
    customer.work_phone,
  ].filter(x => x);

  return (
    <>
      <EmailIdentityModal
        emails={customer.emails}
        open={mode === modes.selectEmail}
        onClose={() => setMode(modes.view)}
        onSelect={email => onAdd('email', email)}
      />
      <SMSIdentityModal
        phoneNumbers={phoneNumbers}
        open={mode === modes.selectPhone}
        onClose={() => setMode(modes.view)}
        onSelect={phoneNumber => {
          onAdd('sms', phoneNumber);
        }}
      />
      {desktop ? (
        <Card className={classes.root}>
          <CardContent>
            <Typography className={classes.title} gutterBottom>
              Identities
            </Typography>
            {!(hasEmailIdentity || hasSMSIdentity) && (
              <Grid container direction="row" alignItems="center" spacing={2}>
                <Grid item>
                  <ErrorOutlineIcon
                    fontSize="large"
                    style={{ color: '#ff5a00' }}
                  />
                </Grid>
                <Grid item xs={10}>
                  There are no identities associated with this customer.
                </Grid>
              </Grid>
            )}
            <IdentityList
              customer={customer}
              onDelete={onDelete}
              isHidden={isHidden}
            />
          </CardContent>
          <CardActions className={`${classes.bottomLeftBox}`}>
            {!hasEmailIdentity && !isHidden && (
              <Button
                variant="contained"
                size="small"
                style={theme.actions.add}
                onClick={() => setMode(modes.selectEmail)}
              >
                Add email identity
              </Button>
            )}
            {!hasSMSIdentity && !isHidden && (
              <Button
                variant="contained"
                size="small"
                style={theme.actions.add}
                onClick={() => setMode(modes.selectPhone)}
              >
                Add sms identity
              </Button>
            )}
          </CardActions>
        </Card>
      ) : (
        <Accordion defaultExpanded={true}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.title} gutterBottom>
              Identities
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{ paddingTop: 0 }}>
            {!(hasEmailIdentity || hasSMSIdentity) && (
              <Grid container direction="row" alignItems="center" spacing={2}>
                <Grid item>
                  <ErrorOutlineIcon
                    fontSize="large"
                    style={{ color: '#ff5a00' }}
                  />
                </Grid>
                <Grid item xs={10}>
                  There are no identities associated with this customer.
                </Grid>
              </Grid>
            )}
            {customer.identities.length > 0 && (
              <IdentityList
                customer={customer}
                onDelete={onDelete}
                isHidden={isHidden}
              />
            )}
          </AccordionDetails>
          <AccordionDetails
            style={{ paddingTop: 0 }}
            className={`${classes.bottomLeftBox}`}
          >
            {!hasEmailIdentity && !isHidden && (
              <Button
                variant="contained"
                size="small"
                style={theme.actions.add}
                onClick={() => setMode(modes.selectEmail)}
                className={classes.mobileButtonText}
              >
                Add email identity
              </Button>
            )}
            {!hasSMSIdentity && !isHidden && (
              <Button
                variant="contained"
                size="small"
                style={theme.actions.add}
                onClick={() => setMode(modes.selectPhone)}
                className={classes.mobileButtonText}
              >
                Add sms identity
              </Button>
            )}
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
};

Identities.fragments = {
  customer: gql`
    fragment IdentitiesFragment on Customer {
      _id
      verified_address
      fullname
      city
      province
      phone
      cell_phone
      home_phone
      work_phone
      identities {
        identity_type
        identity_value
      }
      emails {
        consent
        email
        primary
      }
      merge_candidates {
        _id
      }
    }
  `,
};

export default Identities;
