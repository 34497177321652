/* external */
import React, { useEffect, useState } from 'react';
import gql from 'graphql-tag';
import moment from 'moment-timezone';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { Controller, useForm } from 'react-hook-form';

/* Material UI */
import {
  Button,
  Dialog,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Step,
  StepButton,
  StepContent,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import CancelIcon from '@mui/icons-material/Cancel';
import Alert from '@mui/material/Alert';
import { DateTimePicker } from '@mui/x-date-pickers';
import { useCodeTablesContext } from 'components/MaterialUI/CodeTablesContext';
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';
import RadioControl from 'components/MaterialUI/RadioControl2';
import RenderableRadioGroup from 'components/MaterialUI/RenderableRadioGroup';
import SelectControl from 'components/MaterialUI/SelectControl2';
import TextFieldControl from 'components/MaterialUI/TextFieldControl';
import { useUserContext } from 'components/MaterialUI/UserContext';
import { useSnackbar } from 'notistack';

/* internal */
import {
  oneIntOrNull,
  oneOrNull,
  toIntNullOrUndefined,
  toFloatNullOrUndefined,
} from 'utils';
import { errorHandler } from '../utils';
import StepActions from './StepActions';
import useYearMakeModel from 'components/hooks/useYearMakeModel';
import TrimSelector from 'modules/inventory/components/vehicle/vehicle_info/TrimSelector';
import { PlaceholderVin, Provinces } from 'modules/used_vehicles/const';

// TODO: add fragment to TrimSelector for use here
const DECODE = gql`
  query decodeVehicle($vin: String) {
    inventory {
      decodeVehicle(vin: $vin) {
        body_type_ids
        code_tables_stale
        drilldowns_stale
        drive_type_ids
        engine_compressor_ids
        engine_config_ids
        engine_cylinders
        engine_litres
        exterior_colours {
          desc
          ids
        }
        fuel_type_ids
        interior_colours {
          desc
          ids
        }
        make_ids
        model_ids
        style_ids
        styles {
          body_type_id
          drive_type_id
          engines {
            engine_config_id
            engine_cylinders
            engine_litres
            engine_compressor_id
            fuel_type_id
          }
          make_id
          make_name
          model_id
          model_name
          name
          stock_image {
            url
          }
          stock_photos {
            results {
              cloudinary_public_id
            }
          }
          style_id
          transmissions {
            id
            name
          }
          trim_variation
          trim
          year
        }
        transmission_ids
        trims
        trim_variations
        years
      }
    }
  }
`;

const InTransitRadioGroup = props => (
  <RenderableRadioGroup
    {...props}
    options={[
      { name: 'In Transit', value: 'true' },
      { name: 'On Ground (Arrived)', value: 'false' },
    ]}
  />
);

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '90%',
  },
}));

const StepTwo = ({
  appraisal,
  label,
  onBack,
  onNext,
  setActiveStep,
  setIsDirty,
  stepDirty,
  ...rest
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const UPDATE_APPRAISAL = gql`
    mutation updateAppraisal($id: Int!, $data: UpdateAppraisalInput!) {
      appraisals {
        updateAppraisal(id: $id, data: $data) {
          id
          ...StepTwoAppraisal
        }
      }
    }
    ${StepTwo.fragments.appraisal}
  `;
  const { id, arrivedAt } = appraisal;

  const [showTrimSelect, setShowTrimSelect] = useState(false);
  const { currentUser } = useUserContext();
  const timezone =
    currentUser?.goUserProfile?.settings?.timezone ||
    Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [dateTime, setDateTime] = useState(
    arrivedAt ? moment(arrivedAt + 'Z').tz(timezone) : null,
  );

  const {
    codeTables: {
      driveTypes = [],
      engineConfigs = [],
      bodyTypes = [],
      engineCompressors = [],
      exteriorColours = [],
      fuelTypes = [],
      interiorColours = [],
      transmissions = [],
    } = {},
    loading: codeTablesLoading,
  } = useCodeTablesContext();

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    register,
    reset,
    setError,
    setValue,
    watch,
  } = useForm({
    defaultValues: appraisal,
    shouldUnregister: true,
  });

  register('styleId');
  register('trimVariation');

  const year = watch('year');
  const makeId = watch('greaseMakeId');
  const modelId = watch('greaseModelId');
  const styleId = watch('styleId');
  const vin = watch('vin');

  useEffect(() => {
    reset(appraisal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appraisal]);

  const [decodeVehicle, decodeVehicleQuery] = useLazyQuery(DECODE, {
    onCompleted: ({ inventory: { decodeVehicle } }) => {
      const shouldDirty = true;
      const {
        body_type_ids,
        drive_type_ids,
        engine_compressor_ids,
        engine_config_ids,
        engine_cylinders,
        engine_litres,
        fuel_type_ids,
        make_ids,
        model_ids,
        style_ids,
        transmission_ids,
        trim_variations,
        trims,
        years,
      } = decodeVehicle;

      setValue('engineCylinders', oneOrNull(engine_cylinders), {
        shouldDirty,
      });
      setValue('engineLitres', oneOrNull(engine_litres), { shouldDirty });
      setValue('year', oneOrNull(years), { shouldDirty });
      setValue('greaseDriveTypeId', oneOrNull(drive_type_ids), {
        shouldDirty,
      }); // use drive type name for drive_type
      setValue('greaseEngineCompressorId', oneOrNull(engine_compressor_ids), {
        shouldDirty,
      });
      setValue('greaseEngineConfigId', oneOrNull(engine_config_ids), {
        shouldDirty,
      });
      setValue('greaseFuelTypeId', oneOrNull(fuel_type_ids), {
        shouldDirty,
      });
      setValue('greaseMakeId', oneIntOrNull(make_ids), { shouldDirty });
      setValue('greaseModelId', oneIntOrNull(model_ids), { shouldDirty });
      setValue('greaseBodyTypeId', oneOrNull(body_type_ids), {
        shouldDirty,
      });
      setValue('greaseTransmissionId', oneOrNull(transmission_ids), {
        shouldDirty,
      });
      setValue('trim', trims.length > 0 ? trims[0] : null, { shouldDirty }); // set the trim to
      // the first option, but allow the user to change it
      setValue(
        'trimVariation',
        trim_variations.length > 0 ? trim_variations[0] : null,
        { shouldDirty },
      );
      setValue('styleId', style_ids.length > 0 ? style_ids[0] : null, {
        shouldDirty,
      });
      setValue('year', oneIntOrNull(years), { shouldDirty });
    },
  });

  const [updateAppraisal, { loading: updating }] =
    useMutation(UPDATE_APPRAISAL);

  useEffect(() => {
    // decode if there's a VIN and no style_id AND no make/model/year etc.
    // selected.  This is so that if the vehicle was manually entered, we don't
    // wipe out any existing data.
    // The user can always decode manually by pressing the "Decode" button (to be implemented)

    // TODO: Figure out what to do if vehicle doesn't decode properly
    // eg)
    //    - If we manually change the year/make/model, we should wipe out the style_id
    //    - Need to be able to enter the trim manually
    const {
      vin,
      styleId,
      greaseMakeId,
      greaseModelId,
      greaseBodyTypeId,
      year,
      trim,
    } = appraisal;
    if (
      vin &&
      !(
        styleId ||
        greaseMakeId ||
        greaseModelId ||
        greaseBodyTypeId ||
        year ||
        trim
      )
    ) {
      decodeVehicle({ variables: { vin }, fetchPolicy: 'network-only' });
    }
  }, [appraisal, decodeVehicle]);
  const decodeStyles =
    decodeVehicleQuery.data?.inventory.decodeVehicle.styles || [];

  const {
    makes,
    models,
    years,
    loading: loadingYMM,
    setYear,
    setMakeId,
  } = useYearMakeModel({ selectedYear: year, selectedMakeId: makeId });

  useEffect(() => {
    if (dateTime) setValue('arrivedAt', moment(arrivedAt + 'Z').tz(timezone));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arrivedAt, dateTime, setDateTime]);

  useEffect(() => {
    if (year) setYear(year);
  }, [year, setYear]);

  useEffect(() => {
    if (makeId) setMakeId(makeId);
  }, [makeId, setMakeId]);

  useEffect(() => {
    // Sometimes we get customer appraisals (from app or website) that have make set but
    // not makeId.  So, let's fix that here.
    if (appraisal.make && !makeId) {
      const _make = makes?.find(x => x.name === appraisal.make);
      if (_make) setValue('greaseMakeId', _make.id);
    }
  }, [appraisal.make, makeId, makes, setValue]);

  useEffect(() => {
    if (appraisal.model && !modelId) {
      const _model = models?.find(x => x.name === appraisal.model);
      if (_model) setValue('greaseModelId', _model.id);
    }
  }, [appraisal.model, modelId, models, setValue]);

  const handleStyleSelect = style => {
    const shouldDirty = true;
    setValue('greaseDriveTypeId', style.drive_type_id, { shouldDirty });
    if (style.engines?.length === 1) {
      const engine = style.engines[0];
      setValue('greaseEngineCompressorId', engine.engine_compressor_id, {
        shouldDirty,
      });
      setValue('greaseEngineConfigId', engine.engine_config_id, {
        shouldDirty,
      });
      setValue('engineCylinders', engine.engine_cylinders, { shouldDirty });
      setValue('engineLitres', engine.engine_litres, { shouldDirty });
      setValue('greaseFuelTypeId', engine.fuel_type_id, { shouldDirty });
    } else;
    setValue('styleId', style.style_id, { shouldDirty });
    setValue('greaseMakeId', style.make_id, { shouldDirty });
    setValue('greaseModelId', style.model_id, { shouldDirty });
    setValue('greaseBodyTypeId', style.body_type_id, { shouldDirty });
    setValue('year', style.year, { shouldDirty });
    if (style.transmissions?.length === 1)
      setValue('greaseTransmissionId', style.transmissions[0].id, {
        shouldDirty,
      });
    else;
    setValue('trim', style.trim, { shouldDirty });
    setValue('trimVariation', style.trim_variation, { shouldDirty });
    setShowTrimSelect(false);
  };

  useEffect(() => {
    if (isDirty && !stepDirty) setIsDirty();
    else {
      if (!isDirty && stepDirty) setIsDirty(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty, setIsDirty]);

  const processData = ({
    odometer,
    engineCylinders,
    engineLitres,
    inTransit,
    ...data
  }) => ({
    bodyType:
      bodyTypes.find(({ id }) => id === data.greaseBodyTypeId)?.name || '',
    driveType:
      driveTypes.find(({ id }) => id === data.greaseDriveTypeId)?.name || '',
    engineCompressor:
      engineCompressors.find(({ id }) => id === data.greaseEngineCompressorId)
        ?.name || '',
    engineConfig:
      engineConfigs.find(({ id }) => id === data.greaseEngineConfigId)?.name ||
      '',
    engineCylinders: toIntNullOrUndefined(engineCylinders),
    engineLitres: toFloatNullOrUndefined(engineLitres),
    exteriorColour:
      exteriorColours.find(({ id }) => id === data.greaseExteriorColourId)
        ?.name || '',
    fuelType:
      fuelTypes.find(({ id }) => id === data.greaseFuelTypeId)?.name || '',
    interiorColour:
      interiorColours.find(({ id }) => id === data.greaseInteriorColourId)
        ?.name || '',
    odometer: parseInt(odometer, 10),
    make: makes.find(({ id }) => id === data.greaseMakeId)?.name || '',
    model: models.find(({ id }) => id === data.greaseModelId)?.name || '',
    transmission:
      transmissions.find(({ id }) => id === data.greaseTransmissionId)?.name ||
      '',
    inTransit: inTransit === 'true',
    ...data,
  });

  const onSubmit = data => {
    if (isDirty) {
      updateAppraisal({ variables: { id, data: processData(data) } }).then(
        () => onNext(),
        err => errorHandler(enqueueSnackbar, setError)(err),
      );
    } else onNext();
  };
  const loading = codeTablesLoading || decodeVehicleQuery.loading || updating;

  return (
    <Step key={label} {...rest}>
      <StepButton disabled={!appraisal.id} onClick={() => setActiveStep(1)}>
        {label}
      </StepButton>
      <StepContent>
        <LoadingBackdrop open={loading} />
        {!styleId && vin && vin !== PlaceholderVin && (
          <Alert severity="warning">
            Vehicle has not been decoded - would you like to decode now?{' '}
            <Button
              style={{ marginLeft: '16px' }}
              variant="contained"
              onClick={() => decodeVehicle({ variables: { vin } })}
            >
              Decode!
            </Button>{' '}
          </Alert>
        )}

        {vin === PlaceholderVin && (
          <Alert severity="warning">
            This vehicle does not have a VIN - would you like to add one now?
            <Button onClick={onBack}>Add VIN</Button>
          </Alert>
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl className={classes.formControl}>
                <SelectControl
                  control={control}
                  error={errors.year}
                  name="year"
                  label="Year"
                  InputLabelProps={{ shrink: true }}
                  defaultValue=""
                  noNull
                  disabled={loadingYMM}
                  options={years
                    .sort((a, b) => b - a)
                    .map(year => ({
                      name: year,
                      value: year,
                    }))}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl className={classes.formControl}>
                <SelectControl
                  control={control}
                  error={errors.greaseMakeId}
                  name="greaseMakeId"
                  label="Make"
                  InputLabelProps={{ shrink: true }}
                  defaultValue=""
                  disabled={loadingYMM}
                  options={makes.map(({ id, name }) => ({
                    name,
                    value: id,
                  }))}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl className={classes.formControl}>
                <SelectControl
                  control={control}
                  error={errors.greaseModelId}
                  name="greaseModelId"
                  label="Model"
                  InputLabelProps={{ shrink: true }}
                  defaultValue=""
                  disabled={loadingYMM}
                  options={models.map(({ id, name }) => ({
                    name,
                    value: id,
                  }))}
                />
              </FormControl>
            </Grid>
            <Grid container item xs={12} sm={6} md={4} alignItems="center">
              <Grid item xs={8}>
                <TextFieldControl
                  name="trim"
                  label="Decoded Trim"
                  control={control}
                  InputLabelProps={{ shrink: true }}
                  className={classes.formControl}
                />
              </Grid>

              <Grid item xs={3}>
                <Button
                  onClick={() => setShowTrimSelect(true)}
                  variant="contained"
                  disabled={decodeStyles.length <= 1}
                >
                  Change
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl className={classes.formControl}>
                <SelectControl
                  control={control}
                  error={errors.greaseBodyTypeId}
                  name="greaseBodyTypeId"
                  label="Body Type"
                  InputLabelProps={{ shrink: true }}
                  defaultValue={null}
                  options={bodyTypes.map(({ id, name }) => ({
                    name,
                    value: id,
                  }))}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl className={classes.formControl}>
                <SelectControl
                  control={control}
                  error={errors.greaseTransmissionId}
                  name="greaseTransmissionId"
                  label="Transmission"
                  InputLabelProps={{ shrink: true }}
                  defaultValue={null}
                  options={transmissions.map(({ id, name }) => ({
                    name,
                    value: id,
                  }))}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl className={classes.formControl}>
                <SelectControl
                  control={control}
                  error={errors.greaseDriveTypeId}
                  name="greaseDriveTypeId"
                  label="Drive"
                  InputLabelProps={{ shrink: true }}
                  defaultValue={null}
                  options={driveTypes.map(({ id, name }) => ({
                    name,
                    value: id,
                  }))}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl className={classes.formControl}>
                <SelectControl
                  control={control}
                  error={errors.greaseFuelTypeId}
                  name="greaseFuelTypeId"
                  label="Fuel"
                  InputLabelProps={{ shrink: true }}
                  defaultValue={null}
                  options={fuelTypes.map(({ id, name }) => ({
                    name,
                    value: id,
                  }))}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl className={classes.formControl}>
                <SelectControl
                  control={control}
                  error={errors.greaseEngineConfigId}
                  name="greaseEngineConfigId"
                  label="Engine"
                  InputLabelProps={{ shrink: true }}
                  defaultValue={null}
                  options={engineConfigs.map(({ id, name }) => ({
                    name,
                    value: id,
                  }))}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl className={classes.formControl}>
                <TextFieldControl
                  name="engineCylinders"
                  label="Cylinders"
                  control={control}
                  type="number"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextFieldControl
                name="engineLitres"
                label="Litres"
                control={control}
                type="number"
                inputProps={{ step: '0.1' }}
                className={classes.formControl}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl className={classes.formControl}>
                <SelectControl
                  control={control}
                  error={errors.greaseEngineCompressorId}
                  name="greaseEngineCompressorId"
                  label="Compressor"
                  InputLabelProps={{ shrink: true }}
                  defaultValue={null}
                  options={engineCompressors.map(({ id, name }) => ({
                    name,
                    value: id,
                  }))}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl className={classes.formControl}>
                <SelectControl
                  control={control}
                  error={errors.greaseExteriorColourId}
                  name="greaseExteriorColourId"
                  label="Ext. Colour"
                  InputLabelProps={{ shrink: true }}
                  defaultValue={null}
                  options={exteriorColours.map(({ id, name }) => ({
                    name,
                    value: id,
                  }))}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl className={classes.formControl}>
                <SelectControl
                  control={control}
                  error={errors.greaseInteriorColourId}
                  name="greaseInteriorColourId"
                  label="Int. Colour"
                  InputLabelProps={{ shrink: true }}
                  defaultValue={null}
                  options={interiorColours.map(({ id, name }) => ({
                    name,
                    value: id,
                  }))}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextFieldControl
                name="odometer"
                label="Odometer"
                control={control}
                type="number"
                className={classes.formControl}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} style={{ paddingTop: '1rem' }}>
              <FormControl component="fieldset">
                <RadioControl
                  control={control}
                  name="inTransit"
                  label="In Transit"
                  as={<InTransitRadioGroup />}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl component="fieldset" className={classes.formControl}>
                <SelectControl
                  control={control}
                  error={errors.locatedProvince}
                  name="locatedProvince"
                  label="Located Province"
                  InputLabelProps={{ shrink: true }}
                  defaultValue={null}
                  options={Provinces.map(province => ({
                    name: province,
                    value: province,
                  }))}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl className={classes.formControl}>
                <Controller
                  control={control}
                  name="arrivedAt"
                  error={errors.arrivedAt}
                  render={({ field: { ref, onChange, value, ...field } }) => (
                    <DateTimePicker
                      label="Arrived At"
                      variant="inline"
                      format="YYYY-MM-DD HH:mm:ss"
                      onChange={value => setDateTime(value)}
                      value={dateTime}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={e => {
                                e.stopPropagation();
                                setDateTime(null);
                              }}
                              size="large"
                            >
                              <CancelIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      {...field}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Dialog
              open={showTrimSelect}
              onClose={() => {
                setShowTrimSelect(false);
              }}
            >
              <TrimSelector
                decodeStyles={decodeStyles}
                selectedStyleId={styleId}
                handleStyleSelect={handleStyleSelect}
              />
            </Dialog>
            <StepActions
              nextButtonProps={{ type: 'submit' }}
              nextLabel={isDirty ? 'Save' : 'Next'}
              onNext={() => {}}
              onBack={onBack}
            />
          </Grid>
        </form>
      </StepContent>
    </Step>
  );
};

StepTwo.fragments = {
  appraisal: gql`
    fragment StepTwoAppraisal on Appraisal {
      arrivedAt
      bodyType
      driveType
      engineCompressor
      engineConfig
      engineCylinders
      engineLitres
      exteriorColour
      fuelType
      interiorColour
      make
      model
      greaseBodyTypeId
      greaseDriveTypeId
      greaseEngineCompressorId
      greaseEngineConfigId
      greaseExteriorColourId
      greaseFuelTypeId
      greaseInteriorColourId
      greaseMakeId
      greaseModelId
      greaseTransmissionId
      inTransit
      locatedProvince
      odometer
      styleId
      trim
      year
    }
  `,
};
export default StepTwo;
