// Financial calculations module
export class Finance {
  static payment(
    principal,
    apr,
    term,
    finalValue = 0,
    type = 0, // payments due at:  0 = end of period, 1 = beginning?
    termsPerYear = 12.0,
  ) {
    const P = principal;
    const R = apr / termsPerYear;
    const N = term;
    const F = -finalValue;
    const T = type;
    const Q =
      R === 0
        ? F + (P * Math.pow(1 + R, N)) / N
        : (F + P * Math.pow(1 + R, N)) /
          ((1 + R * T) * ((Math.pow(1 + R, N) - 1) / R));

    return Q;
  }

  static principal(
    payment,
    apr,
    term,
    finalValue = 0,
    type = 0,
    termsPerYear = 12.0,
  ) {
    const Q = payment;
    const R = apr / termsPerYear;
    const N = term;
    const F = -finalValue;
    const T = type;
    const P =
      R === 0
        ? Q * N + F
        : (Q * (1 + R * T) * ((Math.pow(1 + R, N) - 1) / R) - F) /
          Math.pow(1 + R, N);
    return P;
  }

  static term(principal, payment, apr, finalValue = 0, type = 0) {
    const P = principal;
    const Q = payment;
    const R = apr / 12;
    const F = -finalValue;
    const T = type;
    const N =
      Math.log((Q * T + F + Q / R) / (Q * T + Q / R - P)) / Math.log(1 + R);
    return N;
  }

  static apr(principal, payment, term, finalValue, type) {
    const P = principal;
    const Q = payment;
    const N = term;
    const F = -finalValue;
    const T = type;
    let lastGuess = 0;
    let iterations = 30;
    const deltaTarget = 0.0001;
    let newGuess = 0.1;
    let fR = 0; // f(R)
    let fRp = 0; // df(R)/dR aka f(R) prime
    let R;
    // Newton's method, since there is no closed solution for rate
    while (iterations > 0 && Math.abs(newGuess - lastGuess) > deltaTarget) {
      lastGuess = newGuess;
      R = lastGuess;
      fR = (P - Q / R - Q * T) * Math.pow(1 + R, N) + Q * T + F + Q / R;
      fRp =
        (P - Q / R - Q * T) * N * Math.pow(1 + R, N - 1) -
        Q / Math.pow(R, 2) +
        Math.pow(1 + R, N) * (Q / Math.pow(R, 2));
      newGuess = R - fR / fRp;
      iterations = iterations - 1;
    }
    if (
      (iterations === 0 && Math.abs(newGuess - lastGuess) > deltaTarget) ||
      newGuess < 0
    )
      R = NaN;
    // failure
    else R = newGuess * 12;

    return R;
  }

  static leasePayment(
    principal,
    apr,
    term,
    residualAmount,
    termsPerYear = 12,
    taxRate = 0,
  ) {
    const P = parseFloat(principal);
    const R = parseFloat(apr);
    const N = parseInt(term, 10); // in months
    const A = parseFloat(residualAmount);
    const F = parseInt(termsPerYear);
    const MF = R / 24; // Money Factor

    const DF = (P - A) / ((F * N) / 12); // Depreciation Fee
    const FF = (P + A) * MF * (12 / F); // Finance Fee

    return (DF + FF) * (1 + taxRate);
  }
}
