import React from 'react';

/* External */
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';

/* Material UI */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

// Internal
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';
import SelectControl from 'components/MaterialUI/SelectControl2';
import TextFieldControl from 'components/MaterialUI/TextFieldControl';
import { formatPhoneNumber } from 'utils';
import { GATEWAY_TYPES } from './SMSActions/const';
import { errorHandler } from 'modules/pitches/utils';

const useStyles = makeStyles(theme => ({
  boldTitle: {
    fontWeight: 'bold',
  },
  textField: {
    width: '90%',
    margin: '5px 0',
  },
}));

const CREATE_GATEWAY = gql`
  mutation CreateGateway($data: GatewayInput!) {
    sms {
      createGateway(data: $data) {
        id
        name
        phone_number
        username
        enable_casl
        casl_stop_word
      }
    }
  }
`;

const CreateGatewayModal = ({ open, onClose, refetch }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit, watch, setError } = useForm({
    defaultValues: {
      gatewayType: 'twilio',
    },
  });
  const watchGatewayName = watch('name');

  const [createGateway, { loading }] = useMutation(CREATE_GATEWAY, {
    onCompleted: () => {
      enqueueSnackbar('Gateway created successfully', {
        variant: 'success',
      });
      refetch();
      onClose();
    },
    onError: error => {
      errorHandler(enqueueSnackbar, setError, { snackAll: true })(error);
    },
  });
  const isDisabled = !watchGatewayName || loading;

  const onSubmit = data => createGateway({ variables: { data } });

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Add New Gateway</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <SelectControl
            control={control}
            name="gatewayType"
            label="Gateway Type"
            required
            noNull
            options={GATEWAY_TYPES}
            className={classes.textField}
            style={{ width: '50%' }}
            variant="outlined"
          />
          <TextFieldControl
            control={control}
            name="name"
            label="Gateway Name"
            required
            className={classes.textField}
            muiVariant="outlined"
          />
          <TextFieldControl
            control={control}
            name="phoneNumber"
            label="Phone Number"
            rules={{
              validate: value =>
                Boolean(formatPhoneNumber(value)) || 'Invalid phone number',
            }}
            className={classes.textField}
            muiVariant="outlined"
          />
          <TextFieldControl
            control={control}
            name="username"
            label="Username or SID"
            className={classes.textField}
            muiVariant="outlined"
          />
          <TextFieldControl
            control={control}
            name="password"
            label="Password or Token"
            className={classes.textField}
            muiVariant="outlined"
          />
          <DialogActions>
            <Button
              type="submit"
              style={isDisabled ? theme.actions.disabled : theme.actions.add}
              variant="contained"
              disabled={isDisabled}
            >
              Add
            </Button>
            <Button variant="outlined" onClick={onClose}>
              Cancel
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
      <LoadingBackdrop open={loading}>Creating...</LoadingBackdrop>
    </Dialog>
  );
};

export default CreateGatewayModal;
