import Bulletins from '../../customers/components/CustomerDetails/Bulletins';
import CloudinaryPhotoUploader from './CloudinaryPhotoUploader';
import CustomerContactPrefs from './CustomerContactPrefs';
import GoCardScanner from './GoCardScanner';
import HelperAppVideo from './HelperAppVideo';
import RODetailsOverview from './RODetailsComponents/RODetailsOverview';
import RODetailsPayments from './RODetailsComponents/RODetailsPayments/Payments';
import RODetailsPhotos from './RODetailsComponents/RODetailsPhotos';
import RODetailsTable from './RODetailsComponents/RODetailsTable';
import RODetailsVideos from './RODetailsComponents/RODetailsVideos';
import ROPhotoCard from './RODetailsComponents/ROPhotoCard';
import ROVideoCard from './RODetailsComponents/ROVideoCard';
import VehicleChecklists from './VehicleChecklists';
import VehicleChecklistsHeaderTable from './VehicleChecklistsHeaderTable';
import VideoUploader from './VideoUploader';
import gql from 'graphql-tag';

export const RO_DETAILS = gql`
  query ro($dealerId: Int!, $roNumber: String!, $dart: Boolean) {
    dealer(dealer_id: $dealerId) {
      dealer_name
      dealer_id
    }
    rotracker {
      ro(dealer_id: $dealerId, ro_number: $roNumber, dart: $dart) {
        id
        
        adp_customer_urn
        appointment_date
        cdk_appointment_id
        cdk_customer_number
        customer {
          _id
          ...BulletinsFragment
        }
        customer_name
        dealer {
          ...CustomerContactPrefsDealerData
        }
        gocard {
          cardId
        }

        gocard_id
        # From market-rotracker

        opened_date
        photos {
          ...ROPhotoCardPhoto
        }
        photoUploadParams {
          ...CloudinaryPhotoUploaderUploadParams
        }
        preferred_name
        remarks
        status
        transactions {
          id
          amount
          created_at
          status
        }
        videos {
          ...ROVideoCardVideo
        }

        customer_email
        attachments {
          id
          file_name
          created_by
          created_at
        }

        videoUploadParams {
          ...VideoUploaderVideoUploadParams
        }
        welcomeSMS {
          id
          error_msg
        }

        welcome_sms_error_msg
        # From market-sms
        smsIdentity {
          customer_id
          customer {
            _id
            ...BulletinsFragment
          }
        }

        emailIdentity {
          customer_id
          customer {
            _id
            ...BulletinsFragment
          }
        }

        uses_billing_system

        ...GoCardScannerRO
        ...HelperAppVideoRO
        ...RODetailsOverviewRO
        ...RODetailsPaymentsRO
        ...RODetailsPhotosRO
        ...RODetailsTableRO
        ...RODetailsVideosRO
        ...VehicleChecklistsHeaderTableRO
        ...VehicleChecklistsRO
      }
    }
  }
  ${ROVideoCard.fragments.video},
  ${ROPhotoCard.fragments.photo}
  ${CloudinaryPhotoUploader.fragments.photoUploadParams}
  ${CustomerContactPrefs.fragments.dealer}
  ${HelperAppVideo.fragments.ro}
  ${VehicleChecklists.fragments.ro}
  ${VehicleChecklistsHeaderTable.fragments.ro}
  ${VideoUploader.fragments.videoUploadParams}
  ${GoCardScanner.fragments.ro}
  ${RODetailsOverview.fragments.ro}
  ${RODetailsPayments.fragments.ro}
  ${RODetailsPhotos.fragments.ro}
  ${RODetailsTable.fragments.ro}
  ${RODetailsVideos.fragments.ro}
  ${Bulletins.fragments.customer}
`;
