import React from 'react';

import Box from '@mui/material/Box';
import Hidden from '@mui/material/Hidden';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import GroupInventoryMobileTable from './GroupInventoryMobileTable';
import GroupInventoryTable from './GroupInventoryTable';
import GroupVehiclesQueryContext from '../contexts/GroupVehiclesQueryContext';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    marginBottom: theme.spacing(2),
    width: '100%',
  },
}));

const GroupInventoryList = () => {
  const classes = useStyles();

  const GROUP_VEHICLES_FACETS_QUERIES = [
    {
      model: 'Dealer',
      field: 'name',
    },
    {
      model: 'Dealer',
      field: 'city_name',
    },
    {
      model: 'StockStatus',
      field: 'name',
    },
    {
      model: 'Vehicle',
      field: 'stock_type',
    },
    {
      model: 'Vehicle',
      field: 'days_in_stock_category',
    },
    {
      model: 'Vehicle',
      field: 'year',
    },
    {
      model: 'Make',
      field: 'name',
    },
    {
      model: 'Model',
      field: 'name',
    },
    {
      model: 'BodyType',
      field: 'name',
    },
    {
      model: 'Vehicle',
      field: 'passengers',
    },
  ];

  return (
    <Box>
      <Hidden smDown>
        <GroupVehiclesQueryContext
          facetsQueries={GROUP_VEHICLES_FACETS_QUERIES}
          fragments={{
            GroupInventoryTableVehicle: GroupInventoryTable.fragments.vehicle,
          }}
        >
          <Paper className={classes.paper}>
            <Typography
              style={{ padding: '1rem', fontWeight: 'bold' }}
              variant="h5"
            >
              Group Inventory
            </Typography>
            <GroupInventoryTable />
          </Paper>
        </GroupVehiclesQueryContext>
      </Hidden>
      <Hidden smUp>
        <GroupVehiclesQueryContext
          facetsQueries={GROUP_VEHICLES_FACETS_QUERIES}
          fragments={{
            GroupInventoryMobileTableVehicle:
              GroupInventoryMobileTable.fragments.vehicle,
          }}
        >
          <GroupInventoryMobileTable />
        </GroupVehiclesQueryContext>
      </Hidden>
    </Box>
  );
};

export default GroupInventoryList;
