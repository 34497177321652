import React from 'react';

import { useDealerContext } from 'components/MaterialUI/DealerContext';
import { Role } from 'constants.js';
import gql from 'graphql-tag';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';

import { useMutation, useQuery } from '@apollo/react-hooks';
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material';

import AutocompleteControl from 'components/MaterialUI/AutocompleteControl';

const USERS_FOR_DEALER = gql`
  query UsersForDealer($dealerIds: [Int]!) {
    users: users(
      dealer_ids: $dealerIds
      roles: [
        "${Role.SALES_REP}"
        "${Role.SALES_MANAGER}"
        "${Role.FINANCE_MANAGER}"
        "${Role.INTERNET_SALES_REP}"
      ]
    ) {
      display_name
      username
      role
      profile {
        image_id
      }
    }
  }
`;

const REASSIGN_TASK_QUERY = gql`
  mutation ReassignTask($id: ID!, $input: ReassignTaskInput!) {
    reassignTask(id: $id, input: $input) {
      _id
      assignee {
        username
        display_name
        role
      }
    }
  }
`;

const BULK_UPDATE_TASKS = gql`
  mutation UpdateTasks($ids: [ID!]!, $input: UpdateTaskInput!) {
    updateTasks(ids: $ids, input: $input) {
      _id
      due_date
      status
      assignee {
        username
        display_name
        role
      }
    }
  }
`;

const UsernameAutocomplete = ({ control, dealer_id }) => {
  const { data: userData } = useQuery(USERS_FOR_DEALER, {
    variables: {
      dealerIds: [dealer_id],
    },
  });

  const desktop = useMediaQuery(theme => theme.breakpoints.up('sm'));
  return (
    <AutocompleteControl
      control={control}
      forcePopupIcon
      label="Username"
      name="username"
      options={userData?.users ?? []}
      optionValueKey="username"
      optionLabelKey="display_name"
      readOnly={!dealer_id}
      required
      style={{ width: desktop ? 300 : 250 }}
    />
  ); // Re-render on dealer_id change
};

const TaskReassign = ({ open, setOpen, task, action, refetch }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { dealerId } = useDealerContext();
  const [reassignTask] = useMutation(REASSIGN_TASK_QUERY, {
    onCompleted: data => {
      action(data.reassignTask);
      enqueueSnackbar('Task updated', { variant: 'success' });
    },
    onError: err => {
      enqueueSnackbar(`Could not update task error: ${err}`, {
        variant: 'error',
      });
    },
  });

  const [bulkReassignTasks] = useMutation(BULK_UPDATE_TASKS, {
    onCompleted: data => {
      refetch({ fetchPolicy: 'network-only' });
      enqueueSnackbar('Bulk reassign successfully', { variant: 'success' });
    },
    onError: err => {
      enqueueSnackbar(`Could not bulk reassign task error: ${err}`, {
        variant: 'error',
      });
    },
  });

  const handleClose = () => setOpen(false);
  const { control, handleSubmit } = useForm();

  const onSubmit = value => {
    if (!Array.isArray(task)) {
      reassignTask({
        variables: {
          id: task._id,
          input: {
            assignee: value.username,
          },
        },
      });
    }

    if (
      Array.isArray(task) &&
      window.confirm('Are you sure you want to bulk reassign?')
    ) {
      bulkReassignTasks({
        variables: {
          ids: task,
          input: {
            assignee: value.username,
          },
        },
      });
    }
    setOpen(false);
  };

  const theme = useTheme();
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Reassign Task currently assigned to: </DialogTitle>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onClick={event => event.stopPropagation()}
        onFocus={event => event.stopPropagation()}
      >
        <DialogContent>
          <UsernameAutocomplete control={control} dealer_id={dealerId} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="outlined">
            Cancel
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            style={theme.actions.confirm}
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default TaskReassign;
