/* External */
import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';

import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
/* Material UI */
import { Box, Button, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

/* Internal */
import Loading from 'components/MaterialUI/Loading';
import { snackBarSettings } from 'constants.js';

const useStyles = makeStyles(theme => ({
  h6: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
    marginBottom: '5px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
  titleLine: {
    borderBottom: '1px solid' + theme.colours.greys.grey3,
    marginBottom: '15px',
  },
  textField: {
    width: '90%',
    margin: '5px 0',
  },
  settingsContainer: {
    width: '80%',
    paddingBottom: '20px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  uploadButton: {
    color: 'white',
    backgroundColor: theme.colours.add,
  },
  downloadButton: {
    color: theme.colours.add,
  },
}));

const UPLOAD_DNC_CSV = gql`
  mutation uploadDncCsv($gatewayId: Int!, $input: SMSDncInput!) {
    sms {
      uploadDncCsv(gatewayId: $gatewayId, input: $input)
    }
  }
`;

const DOWNLOAD_DNC_CSV = gql`
  mutation downloadDncCsv($gatewayId: Int!) {
    sms {
      downloadDncCsv(gatewayId: $gatewayId)
    }
  }
`;

const Unsubscribes = ({ gateway }) => {
  const classes = useStyles();
  const { enqueueSnackbar: snackIt } = useSnackbar();
  const DEFAULT_UPLOAD = {
    label: '',
    dataURI: '',
    extension: '',
  };
  const [uploadData, setUploadData] = useState(DEFAULT_UPLOAD);
  const [uploadDncCsv, { loading: uploadLoading }] = useMutation(
    UPLOAD_DNC_CSV,
    {
      onCompleted: () => {
        snackIt('DNC List Uploaded!', {
          ...snackBarSettings,
          variant: 'success',
        });
        setUploadData(DEFAULT_UPLOAD);
      },
      onError: err => {
        snackIt(`Could not upload DNC List error: ${err}`, {
          ...snackBarSettings,
          variant: 'error',
        });
      },
    },
  );

  const [downloadDncCsv, { loading: downloadLoading, data }] = useMutation(
    DOWNLOAD_DNC_CSV,
    {
      onCompleted: () => {
        snackIt('DNC List Downloaded!', {
          ...snackBarSettings,
          variant: 'success',
        });
        // set the upload data to default since downloading will clear the label for any file that has been inputted.
        setUploadData(DEFAULT_UPLOAD);
      },
      onError: err => {
        snackIt(`Could not download DNC List error: ${err}`, {
          ...snackBarSettings,
          variant: 'error',
        });
      },
    },
  );

  const gatewayData = gateway?.sms?.gateway;

  const getBase64 = file => {
    return new Promise((resolve, reject) => {
      let baseURL = '';
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
      };
      reader.onerror = reject;
      reader.onloadend = () => {
        resolve(baseURL);
      };
    });
  };

  async function handleLabelChange(event) {
    const files = event.target.files;
    const extension = files[0].name.split('.').slice(-1)[0];
    let base64file = await getBase64(files[0]);
    const data = {
      label: files[0].name,
      dataURI: base64file,
      extension: extension,
    };
    setUploadData(data);
  }

  const handleUpload = () => {
    uploadDncCsv({
      variables: {
        gatewayId: gatewayData.id,
        input: {
          filename: uploadData.label,
          data: uploadData.dataURI,
          extension: uploadData.extension,
        },
      },
    });
  };

  const handleDownload = () => {
    downloadDncCsv({
      variables: {
        gatewayId: gatewayData.id,
      },
    });
  };

  useEffect(() => {
    if (data) {
      const element = document.createElement('a');
      const file = new Blob([data.sms.downloadDncCsv], {
        type: 'text/csv',
      });
      element.href = URL.createObjectURL(file);
      element.download = `${gatewayData.name}-DNC.csv`;
      document.body.appendChild(element);
      element.click();
    }
  }, [data, gatewayData]);

  if (uploadLoading || downloadLoading) {
    return <Loading />;
  }

  return (
    <Box>
      <Box className={classes.titleLine}>
        <Typography className={classes.h6}>Unsubscribes</Typography>
      </Box>
      <Grid container spacing={2} className={classes.settingsContainer}>
        <Grid item xs={12}>
          <Typography>
            Upload a CSV file containing at least a “Number” column. All numbers
            in the CSV will be set to no longer receive mesages on this gateway.
          </Typography>
          <form>
            <input
              className={classes.input}
              id="attachment-file"
              type="file"
              onChange={handleLabelChange}
              style={{ margin: '30px 10px 30px 0' }}
            />

            <Grid container item xs={12} direction="column" spacing={2}>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  className={classes.uploadButton}
                  onClick={handleUpload}
                  disabled={uploadData.label === ''}
                >
                  Upload DNC List
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  component="span"
                  className={classes.downloadButton}
                  onClick={handleDownload}
                >
                  Download DNC List
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Unsubscribes;
