import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { makeStyles } from '@mui/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { DatePicker } from '@mui/x-date-pickers';

import { Role, Status, StatusDisplay } from 'constants.js';

const useStyles = makeStyles({
  panel: {
    width: '100%',
    paddingLeft: '25px',
    paddingRight: '25px',
  },
  title: {
    fontSize: '20px',
    paddingBottom: '10px',
    fontWeight: 'bold',
  },
  section: {
    paddingBottom: '10px',
    outlineColor: 'black',
  },
});

const DEALLOG_STATUSES = [...Status.OPEN, ...Status.CLOSED];

const DEFAULT_FACET_CHECK_BOXES = [
  {
    field: 'saved_deal_statuses',
    title: 'Save a Deal',
    value: Status.SAVE_DEAL_STATUSES,
  },
  {
    field: 'dms_deal_types',
    title: 'Retail Deals',
    value: 'retail',
  },
  {
    field: 'dms_deal_types',
    title: 'Wholesale Deals',
    value: 'wholesale',
  },
  {
    field: 'dms_deal_types',
    title: 'Fleet Deals',
    value: 'fleet',
  },
  {
    field: 'hide_statuses',
    title: 'Hide Tubed Deals',
    value: Status.TUBED,
  },
  {
    field: 'hide_statuses',
    title: 'Hide Carryover Deals',
    value: Status.CARRYOVER,
  },
  {
    field: 'has_variance',
    title: 'Only Deals with Variance',
    value: true,
  },
  {
    field: 'missing_attachments',
    title: 'Missing Attachments',
    value: false,
  },
];

const DEFAULT_FACET_OPTIONS = [
  {
    field: 'deal_types',
    title: 'Deal Type',
    choices: [
      { value: 'new', display: 'New' },
      { value: 'used', display: 'Used' },
      { value: 'unknown', display: 'Unknown' },
    ],
  },
  {
    field: 'selected_statuses',
    title: 'Status',
    choices: DEALLOG_STATUSES.map(status => ({
      value: status,
      display: StatusDisplay[status],
    })),
  },
];

export const createDateFacet = () => {
  const yearToDate = {
    value: moment().startOf('year').format('M YYYY'),
    display: 'Year to Date',
  };
  const monthToDate = {
    value: moment().format('M YYYY'),
    display: 'Month to Date',
  };
  let dateChoices = [yearToDate, monthToDate];

  const dateFacet = {
    field: 'date_options',
    title: 'Date',
    choices: dateChoices,
  };
  return dateFacet;
};

export const createFacets = (subStatuses, users) => {
  let facets = DEFAULT_FACET_OPTIONS;
  let salesReps = [];
  let salesManager = [];
  let financeManager = [];

  const dates = createDateFacet();
  facets = [dates, ...facets];

  users.forEach(({ username, role, display_name }) => {
    if (role === Role.SALES_REP || role === Role.INTERNET_SALES_REP) {
      salesReps.push({ value: username, display: display_name });
    }
    if (role === Role.SALES_MANAGER) {
      salesManager.push({ value: username, display: display_name });
    }
    if (role === Role.FINANCE_MANAGER || role === Role.FINANCE_DIRECTOR) {
      financeManager.push({ value: username, display: display_name });
    }
  });

  const subStatusFacet = {
    field: 'sub_statuses',
    title: 'Sub Status',
    choices: subStatuses.map(status => ({ value: status, display: status })),
  };

  const salesRepFacet = {
    field: 'assignees',
    title: 'Sales Person',
    choices: salesReps.sort((a, b) =>
      String(a?.display).localeCompare(String(b?.display)),
    ),
  };

  const salesMngFacet = {
    field: 'assignees',
    title: 'Sales Manager',
    choices: salesManager.sort((a, b) =>
      String(a?.display).localeCompare(String(b?.display)),
    ),
  };

  const financeMngFacet = {
    field: 'assignees',
    title: 'Finance Manager',
    choices: financeManager.sort((a, b) =>
      String(a?.display).localeCompare(String(b?.display)),
    ),
  };

  facets = [
    ...facets,
    subStatusFacet,
    salesRepFacet,
    salesMngFacet,
    financeMngFacet,
  ];

  return facets;
};

const DropDownFilters = ({
  subStatuses,
  users,
  filters = {},
  updateFilter,
  resetFilters,
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(true);
  const facets = createFacets(subStatuses, users);
  const monthDate = moment().format('M YYYY');

  const [selectedDate, setSelectedDate] = useState(
    filters.date_options.value || moment(),
  );
  const handleExpansion = (close = true) => {
    if (close) {
      setExpanded(!expanded);
    }
  };

  const isSelected = (field, value) => {
    if (isEmpty(filters)) {
      return false;
    }
    if (Object.keys(filters).includes(field)) {
      let filterValues = filters[field].map(({ value }) => value);
      return filterValues.includes(value);
    }
    return false;
  };

  const isCheckboxSelected = (field, title) => {
    if (isEmpty(filters)) {
      return false;
    }
    if (Object.keys(filters).includes(field)) {
      let filterValues = filters[field].map(({ title }) => title);
      return filterValues.includes(title) ? true : false;
    }
    return false;
  };

  const isDateSelected = (field, value, display) => {
    if (isEmpty(filters) || !Object.keys(filters).includes('date_options')) {
      return value === monthDate && display === 'Month to Date';
    }
    if (Object.keys(filters).includes(field)) {
      return filters[field].includes(value);
    }
    return false;
  };

  const renderDate = title => {
    return title === 'Date';
  };

  const onDateChange = date => {
    const value = date.format('M YYYY');
    const display = date.format('MMMM YYYY');
    updateFilter('date_options', value, display);
    setSelectedDate(date);
  };

  return (
    <List className={classes.panel}>
      <div className={classes.title}>Filters</div>
      <div style={{ paddingBottom: '10px' }}>
        <Button style={{ color: 'red' }} onClick={() => resetFilters()}>
          <HighlightOffIcon style={{ paddingRight: '5px' }} /> RESET ALL
        </Button>
      </div>
      {facets.map(({ field, title, choices = [] }) => (
        <div style={{ paddingBottom: '10px' }} key={`${field}${title}`}>
          <Accordion key={title}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              {title}
            </AccordionSummary>
            <AccordionDetails>
              <List
                component="div"
                disablePadding
                className={classes.subPanel}
                style={{ width: '100%' }}
              >
                {choices.map(({ value, display = {} }) => (
                  <div key={`${value}${field}${display}`}>
                    {renderDate(title) ? (
                      <ListItem
                        dense
                        button
                        onClick={() => updateFilter(field, value, display)}
                        selected={isDateSelected(field, value, display)}
                      >
                        <ListItemText primary={`${display || 'Unknown'} `} />
                      </ListItem>
                    ) : (
                      <ListItem
                        dense
                        button
                        onClick={() => updateFilter(field, value, display)}
                        selected={isSelected(field, value)}
                      >
                        <ListItemText primary={`${display || 'Unknown'} `} />
                      </ListItem>
                    )}
                  </div>
                ))}
                {title === 'Date' && (
                  <DatePicker
                    autoOk
                    openTo="year"
                    style={{ paddingLeft: '16px' }}
                    views={['year', 'month']}
                    value={selectedDate}
                    onChange={onDateChange}
                  />
                )}
              </List>
            </AccordionDetails>
          </Accordion>
        </div>
      ))}
      <Accordion key="Other Filters" expanded={expanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          onClick={handleExpansion}
        >
          Other Filters
        </AccordionSummary>
        <AccordionDetails>
          <List
            component="div"
            disablePadding
            className={classes.subPanel}
            style={{ width: '100%' }}
          >
            {DEFAULT_FACET_CHECK_BOXES.map(({ field, value, title }) => (
              <div style={{ paddingBottom: '10px' }} key={`${field}${title}`}>
                <ListItem dense disabled={false}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isCheckboxSelected(field, title)}
                        onClick={() => updateFilter(field, value, title)}
                        color="secondary"
                      />
                    }
                    label={title}
                  />
                </ListItem>
              </div>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
    </List>
  );
};

export default DropDownFilters;
