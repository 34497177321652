import React, { useState } from 'react';

/* external */
import { Link } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

/* Material UI */
import Box from '@mui/material/Box';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Tooltip from '@mui/material/Tooltip';

/* internal */
import { formatPrice } from 'utils';
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import DeleteItemForm from '../items/DeleteItemForm';
import ItemDescriptionCell from './common/ItemDescriptionCell';
import ItemFavouriteCell from './common/ItemFavouriteCell';
import ItemPhotoCell from './common/ItemPhotoCell';
import ItemStartTimeCell from './common/ItemStartTimeCell';

const DELETE_AUCTION_ITEM = gql`
  mutation deleteAuctionItem($id: Int!, $deletionReason: String!) {
    auctions {
      deleteAuctionItem(id: $id, deletionReason: $deletionReason) {
        id
        deleted
        deletionReason
      }
    }
  }
`;

const MyItemsRow = ({ auction, carfax, item, showReserveColumn, ...rest }) => {
  const { dealerId } = useDealerContext();
  const [showDeleteForm, setShowDeleteForm] = useState(false);
  const {
    bidCount,
    dealer,
    dealerId: itemDealerId,
    deleted,
    deletionReason,
    id,
    leadingBid,
    minimumStartingBid,
    permissions,
    reserve,
  } = item;
  const [
    deleteAuctionItem,
    { loading: deletingItem, errors: deletingItemErrors },
  ] = useMutation(DELETE_AUCTION_ITEM);

  const canDelete =
    dealerId === dealer.id && permissions.delete && !deletingItem && !deleted;

  const handleSubmitDelete = ({ deletionReason }) => {
    deleteAuctionItem({
      variables: { id, deletionReason },
    }).then(() => setShowDeleteForm(false));
  };

  const handleClickDelete = e => {
    setShowDeleteForm(true);
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <TableRow hover={!deleted} {...rest}>
      <DeleteItemForm
        isOpen={showDeleteForm}
        submitErrors={deletingItemErrors}
        onClose={() => setShowDeleteForm(false)}
        onSubmit={handleSubmitDelete}
      />
      <TableCell>
        <Box display="flex" flexDirection="row" alignItems="start">
          <Tooltip title={'Click me to find out more about this auction item!'}>
            <IconButton size="large">
              <Link to={`/auctions/items/${id}`}>
                <InfoIcon
                  style={{
                    color: '#2196f3',
                    fontSize: '22px',
                  }}
                />
              </Link>
            </IconButton>
          </Tooltip>
          {canDelete ? (
            <IconButton onClick={handleClickDelete} size="large">
              <DeleteIcon />
            </IconButton>
          ) : (
            <Box component="span" width="24px" paddingLeft="24px" />
          )}
        </Box>
      </TableCell>
      <ItemPhotoCell item={item} />
      <ItemDescriptionCell carfax={carfax} item={item} />
      {deleted ? (
        <TableCell colSpan={showReserveColumn ? 7 : 6}>
          Deleted because: "{deletionReason}"
        </TableCell>
      ) : (
        <>
          <ItemFavouriteCell item={item} />
          <TableCell>{dealer?.name}</TableCell>
          <ItemStartTimeCell auction={auction} item={item} countdown />
          <TableCell>{bidCount}</TableCell>
          <TableCell>{formatPrice(minimumStartingBid)}</TableCell>
          <TableCell>
            {formatPrice(leadingBid?.amount)}
            <br />
            {leadingBid?.dealer?.name}
          </TableCell>
          {showReserveColumn && (
            <TableCell>
              {dealerId === itemDealerId && reserve !== null
                ? formatPrice(reserve)
                : null}
            </TableCell>
          )}
        </>
      )}
    </TableRow>
  );
};

MyItemsRow.fragments = {
  auction: gql`
    fragment MyItemsRowAuction on Auction {
      ...ItemStartTimeCellAuction
    }
    ${ItemStartTimeCell.fragments.auction}
  `,
  auctionItem: gql`
    fragment MyItemsRowAuctionItem on AuctionItem {
      id
      bidCount
      dealer {
        id
        name
      }
      dealerId
      deleted
      deletionReason
      endsAt
      isActive
      isClosed
      isUpcoming
      leadingBid {
        id
        dealer {
          id
          name
        }
        amount
      }
      minimumBidIncrement
      minimumStartingBid
      permissions {
        delete
        update
      }
      reserve
      startsAt
      ...ItemDescriptionCellAuctionItem
      ...ItemFavouriteCellAuctionItem
      ...ItemPhotoCellAuctionItem
      ...ItemStartTimeCellAuctionItem
    }
    ${ItemDescriptionCell.fragments.auctionItem}
    ${ItemFavouriteCell.fragments.auctionItem}
    ${ItemPhotoCell.fragments.auctionItem}
    ${ItemStartTimeCell.fragments.auctionItem}
  `,
  auctionItemFeed: gql`
    fragment AuctionItemFeed on AuctionItem {
      id
      bidCount
      dealer {
        id
        name
      }
      dealerId
      deleted
      deletionReason
      endsAt
      isActive
      isClosed
      isUpcoming
      leadingBid {
        id
        dealer {
          id
          name
        }
        amount
      }
      minimumBidIncrement
      minimumStartingBid
      reserve
      startsAt
      ...ItemDescriptionCellAuctionItem
      ...ItemPhotoCellAuctionItem
      ...ItemStartTimeCellAuctionItem
    }
    ${ItemDescriptionCell.fragments.auctionItem}
    ${ItemPhotoCell.fragments.auctionItem}
    ${ItemStartTimeCell.fragments.auctionItem}
  `,
};
export default MyItemsRow;
