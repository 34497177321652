import React, { useEffect } from 'react';

/* external */
import { useQuery, useSubscription } from '@apollo/react-hooks';
import gql from 'graphql-tag';

/* Material UI */
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material';

/* internal */
import { usePersistedState } from 'utils';
import { useTimeSyncContext } from 'components/MaterialUI/TimeSyncContext';
import ActiveItemsTable from '../items/ActiveItemsTable';
import AgedInventoryTable from '../vehicles/AgedInventoryTable';
import AuctionStatusCountdown from './AuctionStatusCountdown';
import DealerPicker from 'components/MaterialUI/DealerPicker';
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';
import MyItemsTable from '../items/MyItemsTable';
import UpcomingItemsTable from '../items/UpcomingItemsTable';
import WinningItemsTable from '../items/WinningItemsTable';

const useStyles = makeStyles(theme => ({
  tabPanel: {
    padding: theme.spacing(1),
  },
}));

const AUCTION_FRAGMENT = gql`
  fragment AuctionFragment on Auction {
    active
    activeItemsCount
    canAdd
    canBid
    canBlindBid
    isWeekly
    name
    upcomingItemsCount
    weekly {
      id
      lastEnd
      nextAdd
      nextBlind
      nextStart
      nextEnd
    }
    ...ActiveItemsTableAuction
    ...AgedInventoryTableAuction
    ...AuctionStatusCountdownAuction
    ...MyItemsTableAuction
    ...UpcomingItemsTableAuction
  }
  ${ActiveItemsTable.fragments.auction}
  ${AgedInventoryTable.fragments.auction}
  ${AuctionStatusCountdown.fragments.auction}
  ${MyItemsTable.fragments.auction}
  ${UpcomingItemsTable.fragments.auction}
`;
const AUCTION_QUERY = gql`
  query getAuction($id: Int!) {
    auctions {
      auction(id: $id) {
        id
        ...AuctionFragment
      }
    }
  }
  ${AUCTION_FRAGMENT}
`;

const AUCTION_SUBSCRIPTION = gql`
  subscription onAuctionUpdated($ids: [Int!]!) {
    auctionFeed(ids: $ids) {
      type
      auction {
        id
        ...AuctionFragment
      }
    }
  }
  ${AUCTION_FRAGMENT}
`;

const AuctionDetails = ({ id }) => {
  const theme = useTheme();
  const { tabPanel } = useStyles(theme);
  const { clock, isElapsed } = useTimeSyncContext();

  const { data, loading, refetch } = useQuery(AUCTION_QUERY, {
    variables: { id },
    fetchPolicy: 'network-only',
  });
  const [currentTab, setCurrentTab] = usePersistedState(
    'auctions.components.auctions.AuctionDetails',
    'active',
  );

  const auction = data?.auctions.auction || {};
  const { isWeekly, name } = auction;
  useSubscription(AUCTION_SUBSCRIPTION, { variables: { ids: [id] } });

  const handleTabChange = (e, value) => setCurrentTab(value);

  useEffect(() => {
    if (data && !isWeekly && currentTab === 'aged') setCurrentTab('active');
  }, [data, currentTab, isWeekly, setCurrentTab]);

  const isAuctionStale = _auction => {
    const { active, activeItemsCount, canAdd, canBid, canBlindBid, weekly } =
      _auction;
    const { lastEnd, nextAdd, nextStart, nextBlind } = weekly || {};

    if (weekly && active) {
      if (!canAdd && !canBlindBid && !canBid && isElapsed(nextAdd)) return true;

      if (!canBlindBid && !canBid && isElapsed(nextBlind)) return true;

      if (!canBid && isElapsed(nextStart)) return true;
      /* Note: won't reload after auction is completed, since we want to 
      show completed items. */
      if (canBid && isElapsed(lastEnd) && activeItemsCount === 0) return true;
    }
    return false;
  };

  useEffect(() => {
    if (isAuctionStale(auction)) refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clock, auction]);

  if (loading) return <LoadingBackdrop open />;

  return (
    <Box m={1}>
      <Box marginBottom={1}>
        <DealerPicker />
      </Box>
      <Box m={1}>
        <Typography variant="h5">{name}</Typography>
        <AuctionStatusCountdown auction={auction} />
      </Box>
      <TabContext value={currentTab}>
        <TabList onChange={handleTabChange} indicatorColor="secondary">
          <Tab
            label={`Active (${data?.auctions.auction.activeItemsCount})`}
            value="active"
          />
          <Tab
            label={`Upcoming (${data?.auctions.auction.upcomingItemsCount})`}
            value="upcoming"
          />
          <Tab label={`My Bids/Won Items`} value="winning" />
          <Tab label={`My Items`} value="mine" />
          <Tab disabled={!isWeekly} label={`Aged Inventory`} value="aged" />
        </TabList>
        <TabPanel className={tabPanel} value="active">
          <ActiveItemsTable auction={auction} />
        </TabPanel>
        <TabPanel className={tabPanel} value="upcoming">
          <UpcomingItemsTable auction={auction} />
        </TabPanel>
        <TabPanel className={tabPanel} value="winning">
          <WinningItemsTable id={id} />
        </TabPanel>
        <TabPanel className={tabPanel} value="mine">
          <MyItemsTable auction={auction} />
        </TabPanel>
        <TabPanel className={tabPanel} value="aged">
          {isWeekly && <AgedInventoryTable auction={auction} />}
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default AuctionDetails;
