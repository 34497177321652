import React, { useState } from 'react';

// MaterialUI
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import MailIcon from '@mui/icons-material/Mail';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import CloseIcon from '@mui/icons-material/Close';

// internal
import { formatOdometer, formatPhoneNumber } from 'utils';
import { COLOUR_MATCH, STATUS, cloudinaryCore } from '../const';
import BiddingSubmit from './BiddingSubmit';
import ConfirmDialog from 'components/MaterialUI/ConfirmDialog';
import FormatDateTZ from 'components/MaterialUI/FormatDateTZ';
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';
import Pending from './Pending';
import Declined from './Declined';
import Accepted from './Accepted';
import ImageCarousel from 'modules/used_vehicles/components/components/ImageCarousel';
import noIMG from 'modules/inventory/static/img/no-image.jpg';
import { paveReportLink } from 'modules/appraisals/utils';

// external
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(theme => ({
  flexBox: {
    display: 'flex',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  disclosureLine: {
    display: 'flex',
    flexDirection: 'row',
  },
}));

const CARFAX_URL_QUERY = gql`
  query vehicleCarfaxUrl($id: Int!) {
    carSense {
      vehicleCarfaxUrl(vehicleId: $id) {
        url
      }
    }
  }
`;

const PURCHASE_CARFAX_REPORT = gql`
  mutation purchaseCarfaxReport($id: Int!) {
    carSense {
      purchaseCarfaxReport(vehicleId: $id) {
        url
      }
    }
  }
`;

const PURCHASE_VEHICLE = gql`
  mutation purchaseVehicle($id: Int!) {
    carSense {
      purchaseVehicle(id: $id) {
        id
        purchasedAt
        status
      }
    }
  }
`;

const CarsenseDetailView = ({
  row,
  handleClose: handleCloseDetails,
  refetch,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const classes = useStyles();
  const {
    id,
    attachments = [],
    year,
    make,
    model,
    odometer,
    trim,
    trimVariation,
    status,
    vin,
    disclosure,
    biddingStartsAt,
    biddingEndsAt,
    bids,
    hasRegistration,
    hasLicence,
    photos,
    myBid,
    isPaveSessionComplete,
    paveSessionId,
  } = row;
  const {
    beenInAccident,
    hasBeenSmokedIn,
    hasExistingInteriorDamage,
    hasExistingExteriorDamage,
    hasWindshieldDamage,
    isFinanced,
    isLeased,
    isOwned,
    numberOfKeys,
  } = disclosure || {};
  const [getCarfaxUrl, { loading }] = useLazyQuery(CARFAX_URL_QUERY, {
    onCompleted: res => {
      const url = res.carSense.vehicleCarfaxUrl?.url;
      if (url) {
        window.open(url, '_blank');
      } else {
        enqueueSnackbar('An error occured, please try again later.', {
          variant: 'error',
        });
      }
    },
    onError: res => {
      enqueueSnackbar('No Carfax Found for VIN.', { variant: 'error' });
      setShowPurchaseCarfax(true);
    },
  });
  const [purchaseCarfaxUrl] = useMutation(PURCHASE_CARFAX_REPORT, {
    onCompleted: res => {
      const url = res.carSense.purchaseCarfaxReport?.url;
      if (url) {
        window.open(url, '_blank');
      } else {
        enqueueSnackbar('An error occured, please try again later.', {
          variant: 'error',
        });
      }
    },
    onError: res => {
      enqueueSnackbar('An error occured, please contact support.', {
        variant: 'error',
      });
      setShowPurchaseCarfax(false);
    },
  });
  const [purchaseVehicle, { loading: markAsPurchasedLoading }] = useMutation(
    PURCHASE_VEHICLE,
    {
      onCompleted: () =>
        enqueueSnackbar('Vehicle marked as purchased.', { variant: 'success' }),
      onError: error => enqueueSnackbar(error.message, { variant: 'error' }),
    },
  );

  const desktop = useMediaQuery(data => data.breakpoints.up('sm'));
  const [bidAmount, setBidAmount] = useState(null);
  const [open, setOpen] = useState(false);
  const [showPurchaseCarfax, setShowPurchaseCarfax] = useState(false);
  const [showConfirmPurchase, setShowConfirmPurchase] = useState(false);
  const ownershipStatus = isFinanced
    ? 'Financed'
    : isLeased
    ? 'Leased'
    : isOwned
    ? 'Owned Outright'
    : 'Unknown';

  const returnYesOrNo = field => (field ? 'Yes' : 'No');

  const formatPhoto = (photo, options = {}) =>
    photo
      ? cloudinaryCore.url(photo.cloudinaryPublicId, {
          width: options.width || 100,
          crop: 'fit',
        })
      : noIMG;

  const firstPhoto = photos[0];
  const displayPhotos = photos.slice(1, 4);

  const latestRegistration = attachments
    .filter(x => x.attachmentType === 'registration')
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))[0];

  const latestLicence = attachments
    .filter(x => x.attachmentType === 'licence')
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))[0];

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmPurchase = () => {
    setShowConfirmPurchase(false);
    purchaseVehicle({ variables: { id } });
  };
  return (
    <>
      <LoadingBackdrop open={markAsPurchasedLoading}>
        Marking Vehicle as Purchased...
      </LoadingBackdrop>
      <Dialog open={open} onClose={handleClose} maxWidth="md">
        <DialogTitle>Vehicle Photos</DialogTitle>
        <DialogContent>
          <ImageCarousel
            photos={photos}
            formatPhotoOptions={{ cloudinaryCore }}
          />
        </DialogContent>
      </Dialog>
      <ConfirmDialog
        isOpen={showConfirmPurchase}
        onClose={() => setShowConfirmPurchase(false)}
        onConfirm={handleConfirmPurchase}
        content={
          <>
            <p>
              {'Please ensure that: '}
              <ul>
                <li>The vehicle is in your possession.</li>
                <li>
                  You and the customer have signed all of the required
                  paperwork.
                </li>
                <li>You have all the key fobs for the vehicle.</li>
              </ul>
            </p>
            <p>
              Do you want to set this vehicle to purchased? This action cannot
              be undone.
            </p>
          </>
        }
        text="Mark Vehicle As Purchased"
      />
      <DialogTitle>
        <Grid container direction="row">
          <Grid item style={{ paddingRight: '1rem' }}>
            <Typography variant="h4">
              {year} {make} {model} {trim} (#{id})
            </Typography>
          </Grid>
          <Grid item className={classes.flexBox}>
            <Box style={{ paddingRight: '1rem' }}>
              <Chip
                label={status}
                size={!desktop ? 'small' : 'medium'}
                style={{
                  backgroundColor: COLOUR_MATCH[status].backgroundColor,
                  color: COLOUR_MATCH[status].color,
                }}
              />
            </Box>
            {isPaveSessionComplete && (
              <Chip
                label={
                  <Box
                    style={{
                      display: 'flex',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                      fontSize: '16px',
                    }}
                  >
                    <CheckCircleIcon style={{ marginRight: '0.25rem' }} /> Pave{' '}
                  </Box>
                }
                variant="outlined"
                size={!desktop ? 'small' : 'medium'}
                style={{ color: 'green', borderColor: 'green' }}
              />
            )}
          </Grid>
          <Grid
            item
            style={{
              flexGrow: '2',
              textAlign: 'right',
            }}
          >
            <IconButton onClick={handleCloseDetails} size="large">
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          direction="row"
          className={classes.flexBox}
          style={{ justifyContent: 'space-between' }}
        >
          <Grid item>
            <Box style={{ color: 'grey' }}>
              <Typography variant="h6">{trimVariation || '-'}</Typography>
            </Box>
            <Box>
              <Typography variant="h6">
                {vin || '-'} / {formatOdometer(odometer) || '-'}
              </Typography>
            </Box>
            <Box
              className={classes.flexBox}
              style={{
                flexDirection: 'row',
                fontSize: '16px',
                paddingBottom: '0.5rem',
              }}
            >
              <Box
                style={{
                  fontWeight: 'bold',
                  paddingRight: '0.25rem',
                }}
              >
                Submitted:
              </Box>
              <Box
                className={classes.flexBox}
                style={{
                  flexDirection: 'row',
                }}
              >
                <FormatDateTZ
                  date={row.createdAt}
                  format="ll"
                  nullDisplay="-"
                />
              </Box>
            </Box>
            {vin && (
              <Box
                className={classes.flexBox}
                style={{
                  flexDirection: 'row',
                  fontSize: '16px',
                  paddingBottom: '0.5rem',
                }}
              >
                <Box
                  className={classes.flexBox}
                  style={{
                    flexDirection: 'row',
                  }}
                >
                  <Button
                    disabled={!vin || loading}
                    onClick={() => getCarfaxUrl({ variables: { id } })}
                    style={{ color: '#2196f3' }}
                    endIcon={
                      loading ? (
                        <CircularProgress />
                      ) : (
                        <OpenInNewIcon style={{ color: '#2196f3' }} />
                      )
                    }
                  >
                    View Carfax Report
                  </Button>
                  {showPurchaseCarfax && (
                    <IconButton
                      style={{ marginLeft: '0.5rem' }}
                      onClick={() => purchaseCarfaxUrl({ variables: { id } })}
                      disabled={!vin}
                      size="large"
                    >
                      <MonetizationOnIcon style={{ color: 'green' }} />
                    </IconButton>
                  )}
                </Box>
              </Box>
            )}
            {isPaveSessionComplete && (
              <Box
                className={classes.flexBox}
                style={{
                  flexDirection: 'row',
                  fontSize: '16px',
                  paddingBottom: '0.5rem',
                }}
              >
                <Button
                  style={{ color: '#2196f3' }}
                  disabled={!isPaveSessionComplete}
                  onClick={() =>
                    window.open(paveReportLink(paveSessionId), '_blank')
                  }
                  endIcon={<OpenInNewIcon style={{ color: '#2196f3' }} />}
                >
                  Open Pave Inspection
                </Button>
              </Box>
            )}
            <Box
              className={classes.flexBox}
              style={{
                flexDirection: 'column',
                paddingBottom: '0.5rem',
              }}
            >
              <Box
                style={{
                  fontSize: '18px',
                  fontWeight: 'bold',
                  paddingBottom: '0.5rem',
                }}
              >
                Customer
              </Box>
              <Box style={{ fontSize: '18px', paddingBottom: '0.5rem' }}>
                {row.customer.firstName} {row.customer.lastName}
              </Box>
              <Grid
                container
                direction="row"
                className={classes.flexBox}
                style={{
                  flexDirection: 'row',
                  paddingBottom: '1rem',
                }}
              >
                <Grid item>
                  <Box
                    className={classes.flexBox}
                    style={{
                      flexDirection: 'row',
                      paddingRight: '1rem',
                    }}
                  >
                    <SmartphoneIcon style={{ marginRight: '0.5rem' }} />
                    {formatPhoneNumber(row.customer.phoneNumber)}
                  </Box>
                  <Box
                    className={classes.flexBox}
                    style={{
                      flexDirection: 'row',
                    }}
                  >
                    <LocationOnIcon style={{ marginRight: '0.5rem' }} />
                    {row.customer.address}
                  </Box>
                </Grid>
                <Grid item>
                  <Box
                    className={classes.flexBox}
                    style={{
                      flexDirection: 'row',
                    }}
                  >
                    <MailIcon style={{ marginRight: '0.5rem' }} />
                    {row.customer.email}
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box
              className={classes.flexBox}
              style={{
                flexDirection: 'column',
                paddingBottom: '0.5rem',
              }}
            >
              <Box
                style={{
                  fontSize: '18px',
                  fontWeight: 'bold',
                  paddingBottom: '0.5rem',
                }}
              >
                Uploads
              </Box>
              <Box
                className={classes.flexBox}
                style={{
                  flexDirection: 'row',
                  paddingBottom: '0.5rem',
                }}
              >
                <Box
                  className={classes.flexBox}
                  style={{
                    flexDirection: 'row',
                    paddingRight: '2rem',
                    color: hasRegistration
                      ? theme.actions.confirm.backgroundColor
                      : theme.actions.danger.backgroundColor,
                  }}
                >
                  {hasRegistration ? (
                    <CheckCircleIcon style={{ marginRight: '0.5rem' }} />
                  ) : (
                    <CancelIcon style={{ marginRight: '0.5rem' }} />
                  )}{' '}
                  {latestRegistration?.attachmentUrl ? (
                    <Link
                      href={latestRegistration?.attachmentUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Registration
                    </Link>
                  ) : (
                    'Registration'
                  )}
                </Box>
                <Box
                  className={classes.flexBox}
                  style={{
                    flexDirection: 'row',
                    color: hasLicence
                      ? theme.actions.confirm.backgroundColor
                      : theme.actions.danger.backgroundColor,
                  }}
                >
                  {hasLicence ? (
                    <CheckCircleIcon style={{ marginRight: '0.5rem' }} />
                  ) : (
                    <CancelIcon style={{ marginRight: '0.5rem' }} />
                  )}{' '}
                  {latestLicence?.attachmentUrl ? (
                    <Link
                      href={latestLicence?.attachmentUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Driver's Licence
                    </Link>
                  ) : (
                    "Driver's Licence"
                  )}
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item>
            <Box onClick={handleClickOpen}>
              <img
                alt="Vehicle"
                style={{
                  width: 400,
                  objectFit: 'cover',
                }}
                src={formatPhoto(firstPhoto, {
                  width: 300,
                })}
              />
            </Box>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: photos.length >= 4 ? 'space-between' : '',
              }}
            >
              {displayPhotos.map((photo, index) => (
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                  onClick={handleClickOpen}
                >
                  {index !== 2 && photos.length >= 4 ? (
                    <img
                      alt="Vehicle"
                      style={{
                        width: 100,
                        objectFit: 'cover',
                      }}
                      src={formatPhoto(photo, {
                        width: 300,
                      })}
                    />
                  ) : (
                    index !== 2 &&
                    photos.length < 4 && (
                      <Box style={{ paddingRight: '3rem' }}>
                        <img
                          alt="Vehicle"
                          style={{
                            width: 100,
                            objectFit: 'cover',
                          }}
                          src={formatPhoto(photo, {
                            width: 300,
                          })}
                        />
                      </Box>
                    )
                  )}
                  {index === 2 && photos.length <= 4 ? (
                    <img
                      alt="Vehicle"
                      style={{
                        width: 100,
                        objectFit: 'cover',
                      }}
                      src={formatPhoto(photo, {
                        width: 300,
                      })}
                    />
                  ) : (
                    index === 2 &&
                    photos.length > 4 && (
                      <Box style={{ position: 'relative', width: '100%' }}>
                        <img
                          alt="Vehicle"
                          style={{
                            width: 100,
                            objectFit: 'cover',
                          }}
                          src={formatPhoto(photo, {
                            width: 300,
                          })}
                        />
                        <Box
                          style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                          }}
                        >
                          <Box
                            style={{
                              color: 'white',
                              background: 'rgba(0, 0, 0, 0.4)',
                              padding: '10px',
                              width: '100%',
                              height: '100%',
                              display: 'flex',
                              textAlign: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <Box
                              style={{
                                display: 'flex',
                                marginTop: 'auto',
                                marginBottom: 'auto',
                                fontSize: '16px',
                              }}
                            >
                              {photos.length > 4 && (
                                <Box>+ {photos.length - 4} more</Box>
                              )}
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    )
                  )}
                </Box>
              ))}
            </Box>
          </Grid>
        </Grid>
        <Box
          style={{
            fontSize: '18px',
            fontWeight: 'bold',
            paddingBottom: '0.5rem',
          }}
        >
          Disclosures
        </Box>
        <hr />
        <Grid
          container
          style={{ paddingBottom: '1rem' }}
          direction="row"
          xs={12}
        >
          <Grid
            item
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
            xs={6}
          >
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <Box className={classes.disclosureLine}>
                <Box style={{ fontWeight: 'bold', paddingRight: '1rem' }}>
                  Ownership:
                </Box>
                {ownershipStatus}
              </Box>
              <Box className={classes.disclosureLine}>
                <Box style={{ fontWeight: 'bold', paddingRight: '1rem' }}>
                  Has Vehicle Been in An Accident?
                </Box>
                {returnYesOrNo(beenInAccident)}
              </Box>
              <Box className={classes.disclosureLine}>
                <Box style={{ fontWeight: 'bold', paddingRight: '1rem' }}>
                  Does vehicle have exterior damage?
                </Box>
                {returnYesOrNo(hasExistingExteriorDamage)}
              </Box>
              <Box className={classes.disclosureLine}>
                <Box style={{ fontWeight: 'bold', paddingRight: '1rem' }}>
                  Does vehicle have interior damage?
                </Box>
                {returnYesOrNo(hasExistingInteriorDamage)}
              </Box>
              <Box className={classes.disclosureLine}>
                <Box style={{ fontWeight: 'bold', paddingRight: '1rem' }}>
                  Has vehicle been smoked in?
                </Box>
                {returnYesOrNo(hasBeenSmokedIn)}
              </Box>
              <Box className={classes.disclosureLine}>
                <Box style={{ fontWeight: 'bold', paddingRight: '1rem' }}>
                  Does vehicle have chips in the windshield?
                </Box>
                {returnYesOrNo(hasWindshieldDamage)}
              </Box>
            </Box>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingRight: '1rem',
              }}
            >
              <Box style={{ fontWeight: 'bold', paddingRight: '1rem' }}>
                Key Fobs:
              </Box>
              {numberOfKeys}
            </Box>
          </Grid>
          <Grid
            item
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
            xs={6}
          >
            <Box style={{ fontWeight: 'bold', paddingRight: '1rem' }}>
              Comments:
            </Box>
          </Grid>
        </Grid>
        <Box
          style={{
            fontSize: '18px',
            fontWeight: 'bold',
            paddingBottom: '0.5rem',
          }}
        >
          Bidding
        </Box>
        <hr />
        <Grid
          container
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingBottom: '2rem',
          }}
          direction="row"
          xs={12}
        >
          <Grid
            item
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
            xs={6}
          >
            <Box style={{ fontWeight: 'bold', paddingRight: '1rem' }}>
              Start Date:
            </Box>
            <FormatDateTZ
              date={biddingStartsAt}
              format="ddd, D MMM YYYY, h:mm:ss a"
              nullDisplay="-"
              style={{ whiteSpace: 'nowrap' }}
            />
          </Grid>
          <Grid
            item
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
            xs={6}
          >
            <Box style={{ fontWeight: 'bold', paddingRight: '1rem' }}>
              End Date:
            </Box>
            <FormatDateTZ
              date={row.biddingEndsAt}
              format="ddd, D MMM YYYY, h:mm:ss a"
              nullDisplay="-"
              style={{ whiteSpace: 'nowrap' }}
            />
          </Grid>
        </Grid>
        {status === STATUS.BIDDING ? (
          <BiddingSubmit
            bidAmount={bidAmount}
            setBidAmount={setBidAmount}
            biddingEndsAt={biddingEndsAt}
            bids={bids}
            myBid={myBid}
            vehicleId={id}
            refetch={refetch}
          />
        ) : status === STATUS.NEW ? (
          <Box
            className={classes.flexBox}
            style={{
              flexDirection: 'row',
              fontWeight: 'bold',
              marginLeft: 'auto',
              marginRight: 'auto',
              paddingBottom: '3rem',
            }}
          >
            <Box
              className={classes.flexBox}
              style={{
                flexDirection: 'row',
                fontWeight: 'bold',
                marginLeft: 'auto',
                marginRight: 'auto',
                fontSize: '18px',
              }}
            >
              Once bidding is open for this vehicle you can come back here and
              place a bid.
            </Box>
          </Box>
        ) : status === STATUS.PENDING ? (
          <Pending vehicle={row} />
        ) : status === STATUS.DECLINED ? (
          <Declined vehicle={row} />
        ) : status === STATUS.ACCEPTED ? (
          <Accepted
            vehicle={row}
            onClickPurchase={() => setShowConfirmPurchase(true)}
          />
        ) : (
          <></>
        )}
      </DialogContent>
    </>
  );
};

export default CarsenseDetailView;
