// External
import React from 'react';
import moment from 'moment-timezone';

// Material UI
import Box from '@mui/material/Box';

const EstimateBalanceOwing = ({ vehicle }) => {
  const estimateBalanceOwing = () => {
    const termDays = moment(vehicle.last_payment_date).diff(
      moment(vehicle.date_purchased),
      'days',
    );
    const remainingDays = moment(vehicle.last_payment_date).diff(
      moment(),
      'days',
    );
    const balance = vehicle.finance_amount * (remainingDays / termDays);
    return remainingDays > 0
      ? `$${Math.round(balance).toLocaleString()}`
      : '$0';
  };

  return (
    <>
      {(vehicle?.equity || vehicle?.finance_amount) && (
        <Box>
          {estimateBalanceOwing()} (Equity:{' '}
          <span
            style={{
              color: `${
                vehicle.equity
                  ? vehicle.equity >= 0
                    ? 'green'
                    : 'red'
                  : 'black'
              }`,
            }}
          >
            {vehicle.equity ? (vehicle.equity >= 0 ? '$' : '-$') : ' '}
            {Math.abs(Math.round(vehicle.equity)).toLocaleString()}
          </span>
          )
        </Box>
      )}
      {vehicle?.date_purchased && vehicle?.last_payment_date && (
        <span style={{ color: '#666666', fontSize: '.8rem' }}>
          Term: {moment(vehicle.date_purchased).format('MMM, YYYY')} -{' '}
          {moment(vehicle.last_payment_date).format('MMM, YYYY')}
        </span>
      )}
    </>
  );
};

export default EstimateBalanceOwing;
