import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import app from '../../app';
import DealLogTable from './DealLogTable';
import { RoleGroup } from 'constants.js';

const { RequiresRoles } = app.components;

const propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
};

class DealLog extends Component {
  render() {
    const { match } = this.props;

    return (
      <app.components.Layout>
        <RequiresRoles
          requiredRoles={RoleGroup.DEAL_LOG_ROLE}
          renderOnSuccess={() => (
            <Switch>
              <Route exact path={`${match.url}`} component={DealLogTable} />
            </Switch>
          )}
        />
      </app.components.Layout>
    );
  }
}

DealLog.propTypes = propTypes;

export default DealLog;
