// External
import { useMutation, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useMemo } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AppBar from '@mui/material/AppBar';
// Material UI
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import Hidden from '@mui/material/Hidden';
import SelectControl from 'components/MaterialUI/SelectControl2';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import { useDealerContext } from 'components/MaterialUI/DealerContext';
import DealerPicker from 'components/MaterialUI/DealerPicker';
import ErrorDisplay from 'components/MaterialUI/ErrorDisplay';
// Internal
import Loading from 'components/MaterialUI/Loading';
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';
import { useUserContext } from 'components/MaterialUI/UserContext';

import BlackoutPeriod from './ServiceAppointmentSettings/BlackoutPeriod';
import BookableService from './ServiceAppointmentSettings/BookableService';
import SchedulePeriod from './ServiceAppointmentSettings/SchedulePeriod';
import TransportationOption from './ServiceAppointmentSettings/TransportationOption';
import { Role } from 'constants.js';

const CAN_MANAGE_SETTING_ROLES = [
  Role.ORGANIZATION_ADMIN,
  Role.GENERAL_MANAGER,
  Role.SERVICE_MANAGER,
  Role.ADMINISTRATION,
];

const GET_ALL_SETTINGS = gql`
  query getAllServices($dealerId: Int!) {
    rotracker {
      dealer: dealer(dealer_id: $dealerId) {
        id
        schedule_timeslot_size
      }
      schedules: getSchedulePeriods(dealer_id: $dealerId) {
        id
        weekday
        max_appointments
        appointments_per_slot
        last_slot
        first_slot
      }
      blackouts: getBlackoutPeriods(dealer_id: $dealerId) {
        id
        dealer_id
        start_date
        start_time
        end_date
        end_time
      }
      transportationOptions: getTransportationOptions(dealer_id: $dealerId) {
        id
        cdk_transportation_code
        description
        instructions
        daily_limit
        name
      }
      bookableServices: getBookableServices(dealer_id: $dealerId) {
        id
        created_at
        op_code
        dispatch_code
        name
        description
        starting_price
        special_price
        labor_type
        is_special
      }
    }
  }
`;

const UPDATE_SCHEDULE_PERIOD = gql`
  mutation updateSchedulePeriod(
    $dealerId: Int!
    $id: ID!
    $weekday: Int
    $first_slot: String
    $last_slot: String
    $appointments_per_slot: Int
    $max_appointments: Int
  ) {
    rotracker {
      updateSchedulePeriod(
        dealer_id: $dealerId
        id: $id
        weekday: $weekday
        first_slot: $first_slot
        last_slot: $last_slot
        appointments_per_slot: $appointments_per_slot
        max_appointments: $max_appointments
      ) {
        id
      }
    }
  }
`;

const ADD_SCHEDULE_PERIOD = gql`
  mutation addSchedulePeriod(
    $dealerId: Int!
    $weekday: Int!
    $first_slot: String!
    $last_slot: String!
    $appointments_per_slot: Int!
    $max_appointments: Int!
  ) {
    rotracker {
      addSchedulePeriod(
        dealer_id: $dealerId
        weekday: $weekday
        first_slot: $first_slot
        last_slot: $last_slot
        appointments_per_slot: $appointments_per_slot
        max_appointments: $max_appointments
      ) {
        id
      }
    }
  }
`;

const DELETE_SCHEDULE_PERIOD = gql`
  mutation deleteSchedulePeriod($dealerId: Int!, $id: ID!) {
    rotracker {
      deleteSchedulePeriod(dealer_id: $dealerId, id: $id) {
        id
      }
    }
  }
`;

const UPDATE_BLACKOUT_PERIOD = gql`
  mutation updateBlackoutPeriod(
    $dealer_id: Int!
    $id: ID!
    $start_date: String
    $start_time: String
    $end_date: String
    $end_time: String
  ) {
    rotracker {
      updateBlackoutPeriod(
        dealer_id: $dealer_id
        id: $id
        start_date: $start_date
        start_time: $start_time
        end_date: $end_date
        end_time: $end_time
      ) {
        id
      }
    }
  }
`;

const ADD_BLACKOUT_PERIOD = gql`
  mutation addBlackoutPeriod(
    $dealerId: Int!
    $start_date: String!
    $start_time: String!
    $end_date: String!
    $end_time: String!
  ) {
    rotracker {
      addBlackoutPeriod(
        dealer_id: $dealerId
        start_date: $start_date
        start_time: $start_time
        end_date: $end_date
        end_time: $end_time
      ) {
        id
      }
    }
  }
`;
const DELETE_BLACKOUT_PERIOD = gql`
  mutation deleteBlackoutPeriod($dealerId: Int!, $id: ID!) {
    rotracker {
      deleteBlackoutPeriod(dealer_id: $dealerId, id: $id) {
        id
      }
    }
  }
`;

const UPDATE_TRANSPORTATION_OPTION = gql`
  mutation updateTransportationOption(
    $dealerId: Int!
    $id: ID!
    $name: String
    $description: String
    $instructions: String
    $daily_limit: Int
  ) {
    rotracker {
      updateTransportationOption(
        dealer_id: $dealerId
        id: $id
        name: $name
        description: $description
        instructions: $instructions
        daily_limit: $daily_limit
      ) {
        id
      }
    }
  }
`;

const ADD_TRANSPORTATION_OPTION = gql`
  mutation addTransportationOption(
    $dealerId: Int!
    $cdk_transportation_code: String
    $name: String
    $description: String
    $instructions: String
    $daily_limit: Int
  ) {
    rotracker {
      addTransportationOption(
        dealer_id: $dealerId
        cdk_transportation_code: $cdk_transportation_code
        name: $name
        description: $description
        instructions: $instructions
        daily_limit: $daily_limit
      ) {
        id
      }
    }
  }
`;

const DELETE_TRANSPORTATION_OPTION = gql`
  mutation deleteTransportationOption($dealerId: Int!, $id: ID!) {
    rotracker {
      deleteTransportationOption(dealer_id: $dealerId, id: $id) {
        id
      }
    }
  }
`;

const UPDATE_BOOKABLE_SERVICE = gql`
  mutation updateBookableService(
    $dealerId: Int!
    $id: ID!
    $op_code: String
    $dispatch_code: String
    $name: String
    $description: String
    $starting_price: Float
    $special_price: Float
    $labor_type: String
    $is_special: Boolean
  ) {
    rotracker {
      updateBookableService(
        dealer_id: $dealerId
        id: $id
        op_code: $op_code
        dispatch_code: $dispatch_code
        name: $name
        description: $description
        starting_price: $starting_price
        special_price: $special_price
        labor_type: $labor_type
        is_special: $is_special
      ) {
        id
      }
    }
  }
`;

const ADD_BOOKABLE_SERVICE = gql`
  mutation addBookableService(
    $dealerId: Int!
    $op_code: String
    $dispatch_code: String
    $name: String
    $description: String
    $starting_price: Float
    $special_price: Float
    $labor_type: String
    $is_special: Boolean
  ) {
    rotracker {
      addBookableService(
        dealer_id: $dealerId
        op_code: $op_code
        dispatch_code: $dispatch_code
        name: $name
        description: $description
        starting_price: $starting_price
        special_price: $special_price
        labor_type: $labor_type
        is_special: $is_special
      ) {
        id
      }
    }
  }
`;

const DELETE_BOOKABLE_SERVICE = gql`
  mutation deleteBookableService($dealerId: Int!, $id: ID!) {
    rotracker {
      deleteBookableService(dealer_id: $dealerId, id: $id) {
        id
      }
    }
  }
`;

const UPDATE_DEALER = gql`
  mutation updateDealer($dealerId: Int!, $update: DealerUpdate) {
    rotracker {
      updateDealer(dealer_id: $dealerId, update: $update) {
        id
      }
    }
  }
`;

const ServiceSettings = () => {
  const { enqueueSnackbar } = useSnackbar();

  const { dealerId } = useDealerContext();
  const { currentUser } = useUserContext();
  const role = currentUser?.role || 'none';

  const canUpdate = CAN_MANAGE_SETTING_ROLES.includes(role);

  const slotSizeMenuOptions = [15, 20, 30, 60];

  const formatTimeOption = x =>
    `${Math.floor(x / 60)
      .toString()
      .padStart(2, '0')}:${(x % 60).toString().padStart(2, '0')}:00`;

  const errorHandler = unableTo => err =>
    enqueueSnackbar(`Unable to ${unableTo}, please try refreshing the page.`, {
      variant: 'error',
    });

  const [updateSchedulePeriod, { loading: updatingSchedules }] = useMutation(
    UPDATE_SCHEDULE_PERIOD,
    {
      onError: errorHandler('update schedule period'),
    },
  );
  const [updateDealer, { loading: updatingDealer }] = useMutation(
    UPDATE_DEALER,
    {
      onError: errorHandler('update dealer timeslot size'),
    },
  );
  const [addSchedulePeriod, { loading: addingSchedules }] = useMutation(
    ADD_SCHEDULE_PERIOD,
    {
      onError: errorHandler('add schedule period'),
    },
  );
  const [deleteSchedulePeriod, { loading: deletingSchedules }] = useMutation(
    DELETE_SCHEDULE_PERIOD,
    {
      onError: errorHandler('delete schedule period'),
    },
  );

  const [updateBlackoutPeriod, { loading: updatingBlackouts }] = useMutation(
    UPDATE_BLACKOUT_PERIOD,
    {
      onError: errorHandler('update blackout period'),
    },
  );
  const [addBlackoutPeriod, { loading: addingBlackouts }] = useMutation(
    ADD_BLACKOUT_PERIOD,
    {
      onError: errorHandler('add blackout period'),
    },
  );
  const [deleteBlackoutPeriod, { loading: deletingBlackouts }] = useMutation(
    DELETE_BLACKOUT_PERIOD,
    {
      onError: errorHandler('delete blackout period'),
    },
  );

  const [
    updateTransportationOption,
    { loading: updatingTransportationOptions },
  ] = useMutation(UPDATE_TRANSPORTATION_OPTION, {
    onError: errorHandler('update transportation option'),
  });
  const [addTransportationOption, { loading: addingTransportationOptions }] =
    useMutation(ADD_TRANSPORTATION_OPTION, {
      onError: errorHandler('add transportation option'),
    });
  const [
    deleteTransportationOption,
    { loading: deletingTransportationOptions },
  ] = useMutation(DELETE_TRANSPORTATION_OPTION, {
    onError: errorHandler('delete transportation option'),
  });

  const [updateBookableService, { loading: updatingBookableServices }] =
    useMutation(UPDATE_BOOKABLE_SERVICE, {
      onError: errorHandler('update bookable service'),
    });
  const [addBookableService, { loading: addingBookableServices }] = useMutation(
    ADD_BOOKABLE_SERVICE,
    {
      onError: errorHandler('add bookable service'),
    },
  );
  const [deleteBookableService, { loading: deletingBookableServices }] =
    useMutation(DELETE_BOOKABLE_SERVICE, {
      onError: errorHandler('delete bookable service'),
    });

  const { data, error, loading, refetch } = useQuery(GET_ALL_SETTINGS, {
    variables: {
      dealerId,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,

    // Once we get the data from the query, let's reset the form values
    onCompleted: data =>
      reset({
        ...data.rotracker,
        selectedSlotSize: data.rotracker.dealer?.schedule_timeslot_size ?? 15,
      }),
  });

  const { blackouts, schedules, transportationOptions, bookableServices } =
    data?.rotracker ?? {};

  const formMethods = useForm();
  const { reset, formState, handleSubmit, control } = formMethods;

  const defaultSlotSize = data?.rotracker?.dealer?.schedule_timeslot_size ?? 15;
  const selectedSlotSize = useWatch({
    control,
    name: 'selectedSlotSize',
    defaultValue: defaultSlotSize,
  });

  const timeOptions = useMemo(
    () =>
      [...Array((24 * 60) / selectedSlotSize)]
        .map((_, x) => x * selectedSlotSize)
        .map(formatTimeOption),
    [selectedSlotSize],
  ); // memoizing this so that it only regenerates when slotSize changes, and not on every re-render

  const onSubmit = data => {
    // delete, update, create any modifed service settings
    // This is pretty complicated - it might be better to have a mutation accept the entire
    // data structure and handle remove/update/create in the back end.
    // If we're going to leave the mutations the way they are, we should probably do these
    // steps in promises (or batches of promises).

    const blackoutIdsToDelete = blackouts
      .filter(x => !data.blackouts.map(y => y.id).includes(x.id))
      .map(x => x.id);

    const blackoutsToUpdate = data.blackouts.filter(
      x =>
        x.id &&
        !_.isEqual(
          x,
          blackouts.find(y => y.id === x.id),
        ),
    );

    const blackoutsToCreate = data.blackouts.filter(x => !x.id);

    const scheduleIdsToDelete = schedules
      .filter(x => !data.schedules.map(y => y.id).includes(x.id))
      .map(x => x.id);

    const schedulesToUpdate = data.schedules.filter(
      x =>
        x.id &&
        !_.isEqual(
          x,
          schedules.find(y => y.id === x.id),
        ),
    );

    const schedulesToCreate = data.schedules.filter(x => !x.id);

    const transportationOptionIdsToDelete = transportationOptions
      .filter(x => !data.transportationOptions.map(y => y.id).includes(x.id))
      .map(x => x.id);

    const transportationOptionsToUpdate = data.transportationOptions.filter(
      x =>
        x.id &&
        !_.isEqual(
          x,
          transportationOptions.find(y => y.id === x.id),
        ),
    );

    const transportationOptionsToCreate = data.transportationOptions.filter(
      x => !x.id,
    );

    const bookableServiceIdsToDelete = bookableServices
      .filter(x => !data.bookableServices.map(y => y.id).includes(x.id))
      .map(x => x.id);

    const bookableServicesToUpdate = data.bookableServices.filter(
      x =>
        x.id &&
        !_.isEqual(
          x,
          bookableServices.find(y => y.id === x.id),
        ),
    );

    const bookableServicesToCreate = data.bookableServices.filter(x => !x.id);

    const timeSlotSizeToUpdate = [data.selectedSlotSize].filter(
      x => x !== defaultSlotSize,
    );

    Promise.all(
      blackoutIdsToDelete.map(id =>
        deleteBlackoutPeriod({ variables: { dealerId, id } }),
      ),
      scheduleIdsToDelete.map(id =>
        deleteSchedulePeriod({ variables: { dealerId, id } }),
      ),
      transportationOptionIdsToDelete.map(id =>
        deleteTransportationOption({ variables: { dealerId, id } }),
      ),
      bookableServiceIdsToDelete.map(id =>
        deleteBookableService({ variables: { dealerId, id } }),
      ),
    )
      .then(() =>
        Promise.all(
          blackoutsToUpdate.map(
            (
              { __typename, ...variables }, // this should include id and dealer_id in variables
            ) => updateBlackoutPeriod({ variables }),
          ),
          schedulesToUpdate.map(
            ({
              appointments_per_slot,
              max_appointments,
              __typename,
              ...variables
            }) =>
              updateSchedulePeriod({
                variables: {
                  dealerId,
                  appointments_per_slot: parseInt(appointments_per_slot),
                  max_appointments: parseInt(max_appointments),
                  ...variables,
                },
              }),
          ),
          transportationOptionsToUpdate.map(
            ({ daily_limit, __typename, ...variables }) =>
              updateTransportationOption({
                variables: {
                  dealerId,
                  daily_limit: parseInt(daily_limit),
                  ...variables,
                },
              }),
          ),
          bookableServicesToUpdate.map(
            ({ starting_price, special_price, __typename, ...variables }) =>
              updateBookableService({
                variables: {
                  dealerId,
                  starting_price: parseFloat(starting_price) || null,
                  special_price: parseFloat(special_price) || null,
                  ...variables,
                },
              }),
          ),
          timeSlotSizeToUpdate.map(timeSlot =>
            updateDealer({
              variables: {
                dealerId,
                update: {
                  schedule_timeslot_size: timeSlot,
                },
              },
            }),
          ),
        ),
      )
      .then(() =>
        Promise.all(
          blackoutsToCreate.map(blackout =>
            addBlackoutPeriod({ variables: { dealerId, ...blackout } }),
          ),
          schedulesToCreate.map(
            ({ appointments_per_slot, max_appointments, ...variables }) =>
              addSchedulePeriod({
                variables: {
                  dealerId,
                  appointments_per_slot: parseInt(appointments_per_slot),
                  max_appointments: parseInt(max_appointments),
                  ...variables,
                },
              }),
          ),
          transportationOptionsToCreate.map(({ daily_limit, ...variables }) =>
            addTransportationOption({
              variables: {
                dealerId,
                daily_limit: parseInt(daily_limit),
                ...variables,
              },
            }),
          ),
          bookableServicesToCreate
            .sort((a, b) => a.created_at.localeCompare(b.created_at))
            .map(({ starting_price, special_price, ...variables }) =>
              addBookableService({
                variables: {
                  dealerId,
                  starting_price: parseFloat(starting_price) || null,
                  special_price: parseFloat(special_price) || null,
                  ...variables,
                },
              }),
            ),
        ),
      )
      .then(() => refetch())
      .catch(error =>
        enqueueSnackbar(`Error updating settings: ${JSON.stringify(error)}`, {
          variant: 'error',
        }),
      );
  };

  if (loading) return <Loading />;

  return (
    <Box m={2}>
      <Box>
        <DealerPicker />
      </Box>
      <LoadingBackdrop
        open={[
          updatingBlackouts,
          deletingBlackouts,
          addingBlackouts,
          updatingSchedules,
          deletingSchedules,
          addingSchedules,
          updatingTransportationOptions,
          deletingTransportationOptions,
          addingTransportationOptions,
          updatingBookableServices,
          deletingBookableServices,
          addingBookableServices,
          updatingDealer,
        ].some(x => x)}
      >
        Saving
      </LoadingBackdrop>
      {loading && <Loading />}
      {error && (
        <ErrorDisplay error={error} action="Loading service settings" />
      )}
      {/* check for data before displaying the forms. If there's an error, we can then just
      display an error message (including the dealer picker so they can still pick another one) */}
      {!loading && !error && (
        <Box mt={2}>
          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Accordion expanded>
                <AccordionSummary>
                  <Typography style={{ lineHeight: '1.75' }}>
                    <b>Schedule Timeslot Size: </b>
                  </Typography>
                  <SelectControl
                    control={control}
                    defaultValue={defaultSlotSize}
                    name="selectedSlotSize"
                    options={slotSizeMenuOptions.map(option => ({
                      value: option,
                      name: option,
                    }))}
                    style={{ marginLeft: '1rem' }}
                    disabled={!canUpdate}
                    noNull
                  />
                </AccordionSummary>
              </Accordion>
              <SchedulePeriod canUpdate={canUpdate} timeOptions={timeOptions} />
              <BlackoutPeriod timeOptions={timeOptions} canUpdate={canUpdate} />
              <TransportationOption canUpdate={canUpdate} />
              <BookableService canUpdate={canUpdate} />
              <Box m={10}> </Box>
              {canUpdate && (
                <Hidden smDown>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'right',
                      width: '100%',
                    }}
                  >
                    <AppBar
                      position="fixed"
                      style={{
                        top: 'auto',
                        bottom: 0,
                        zIndex: 1000,
                        backgroundColor: '#eae9e9',
                      }}
                    >
                      <Toolbar>
                        <Typography
                          style={{
                            bottom: '15px',
                            right: '40%',
                            position: 'fixed',
                            zIndex: 1000,
                            width: '400px',
                            color: 'black',
                            textAlign: 'right',
                          }}
                          variant="h6"
                        >
                          {!formState.isDirty
                            ? 'No changes to save.'
                            : 'Remember to save your changes!'}
                        </Typography>
                        <Button
                          disabled={!formState.isDirty}
                          variant="contained"
                          type="submit"
                          style={{
                            bottom: '15px',
                            right: '30%',
                            position: 'fixed',
                            zIndex: 1000,
                            width: '100px',
                            backgroundColor: `${
                              !formState.isDirty ? '#bfbebe' : '#74B72E'
                            }`,
                          }}
                        >
                          Save
                        </Button>
                      </Toolbar>
                    </AppBar>
                  </div>
                </Hidden>
              )}
              {canUpdate && (
                <Hidden smUp>
                  <Fab
                    disabled={!formState.isDirty}
                    color="primary"
                    style={{
                      bottom: '10px',
                      right: '10px',
                      position: 'fixed',
                      zIndex: 1000,
                      backgroundColor: `${
                        !formState.isDirty ? 'grey' : '#5bc236'
                      }`,
                    }}
                    type="submit"
                  >
                    SAVE
                  </Fab>
                </Hidden>
              )}
            </form>
          </FormProvider>
        </Box>
      )}
    </Box>
  );
};

export default ServiceSettings;
