import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import gql from 'graphql-tag';

const useStyles = makeStyles(theme => ({
  paddingIcon: {
    paddingRight: '2px',
  },
  good: {
    color: '#74B72E',
  },
  bad: {
    color: 'red',
  },
}));

const IconGroup = ({
  vehicle: {
    is_featured,
    is_decoded,
    is_certified,
    appraisal_url,
    has_uvi,
    is_detailed_pricing,
    is_on_special,
    recon_complete,
  },
}) => {
  const { paddingIcon, good, bad } = useStyles();
  return (
    <>
      <Tooltip title={is_featured ? 'Featured' : 'Not Featured'}>
        <i
          className={`fas fa-star ${paddingIcon} ${is_featured ? good : bad}`}
        />
      </Tooltip>
      <Tooltip title={is_decoded ? 'Decoded' : 'Not Decoded'}>
        <i className={`fas fa-car ${paddingIcon} ${is_decoded ? good : bad}`} />
      </Tooltip>
      <Tooltip title={is_certified ? 'Certified' : 'Not Certified'}>
        <i
          className={`fas fa-edit ${paddingIcon} ${is_certified ? good : bad}`}
        />
      </Tooltip>
      <Tooltip title={appraisal_url ? 'Appraised' : 'Not Appraised'}>
        <i
          className={`fas fa-font ${paddingIcon} ${appraisal_url ? good : bad}`}
        />
      </Tooltip>
      <Tooltip
        title={
          has_uvi
            ? 'Used Vehicle Inspection Uploaded'
            : 'No Used Vehicle Inspection Uploaded'
        }
      >
        <i
          className={`fas fa-cloud-upload ${paddingIcon} ${
            has_uvi ? good : bad
          }`}
        />
      </Tooltip>
      <Tooltip
        title={
          is_detailed_pricing ? 'Has Detailed Pricing' : 'No Detailed Pricing'
        }
      >
        <i
          className={`fas fa-list-ol ${paddingIcon} ${
            is_detailed_pricing ? good : bad
          }`}
        />
      </Tooltip>
      <Tooltip title={is_on_special ? 'Vehicle is On Special' : ''}>
        <i
          className={is_on_special ? `fas fa-tag ${paddingIcon} ${good}` : ''}
        />
      </Tooltip>
      <Tooltip title={recon_complete ? 'Recon Complete' : ''}>
        <i
          className={
            recon_complete ? `fas fa-solid fa-wrench fa-rotate-270 ${good}` : ''
          }
        />
      </Tooltip>
    </>
  );
};

IconGroup.fragments = {
  vehicle: gql`
    fragment IconGroupVehicle on GreaseInventoryVehicle {
      is_featured
      is_decoded
      is_certified
      has_uvi
      is_detailed_pricing
      is_on_special
      recon_complete
    }
  `,
};

export default IconGroup;
