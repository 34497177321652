/* external */
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { every } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { debounce } from 'underscore';

/* Material UI */
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';

/* internal */
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';

import { GOCARD_REDEEM_CODES, RV_DEALER_IDS } from 'constants.js';

const CREATE_TRANSACTION = gql`
  mutation createTransaction($input: createTransactionInput!) {
    gocard {
      createTransaction(input: $input) {
        transactionId
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  dropDown: {
    width: '45%',
  },
  mobileDropDown: {
    width: '70%',
  },
}));

const RedeemPointsModal = ({ gocard, isOpen, onClose, locations, refetch }) => {
  const theme = useTheme();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const desktop = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [redemption, setRedemption] = useState(
    GOCARD_REDEEM_CODES.redeemParts.code,
  );
  const [amount, setAmount] = useState(0);
  const [desc, setDesc] = useState('');
  const [errors, setErrors] = useState({
    dealerSelect: true,
    amountSelect: true,
    descSelect: true,
  });

  const [createTransaction, { loading }] = useMutation(CREATE_TRANSACTION, {
    onCompleted: e => {
      enqueueSnackbar('Transaction completed successfully!', {
        variant: 'success',
      });
      onClose();
      refetch();
    },
    onError: e =>
      enqueueSnackbar(`Error completing transaction: ${e}`, {
        variant: 'error',
      }),
  });

  // A valid amount check needs to return false so flip the result
  const amountCheck = input => !(input > 0 && input <= gocard.cashBalance);

  const dealerChange = event => {
    setErrors({ ...errors, dealerSelect: event.target.value === null });
    setSelectedIndex(event.target.value);
  };

  const redemptionChange = event => {
    setRedemption(event.target.value);
  };

  const amountChange = event => {
    setErrors({ ...errors, amountSelect: amountCheck(event.target.value) });
    setAmount(event.target.value);
  };

  const descChange = event => {
    setErrors({ ...errors, descSelect: event.target.value === '' });
    setDesc(event.target.value);
  };

  const isFalse = bool => {
    return bool === false;
  };

  const selectedLocation = selectedIndex ? locations[selectedIndex] : {};
  const isRVDealer = RV_DEALER_IDS.includes(selectedLocation?.dealerId);

  const onSubmit = debounce(() => {
    // Validation before submitting
    const isValid = every(errors, isFalse);
    const payload = {
      cardId: gocard.cardId,
      locationId: locations[selectedIndex].locationId,
      amount: Number(amount) * -1,
      code: redemption,
      description: desc,
    };
    if (!isValid) {
      enqueueSnackbar('Missing required fields', { variant: 'error' });
    } else {
      createTransaction({
        variables: { input: payload },
      });
    }
  }, 1500);

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Redeem Points</DialogTitle>
      <DialogContent>
        <Alert severity="info">
          This will deduct points from the customer's account
        </Alert>
      </DialogContent>
      <DialogContent>
        <FormControl
          className={desktop ? classes.dropDown : classes.mobileDropDown}
          error={errors.dealerSelect}
        >
          <InputLabel>Location</InputLabel>
          <Select value={selectedIndex} onChange={dealerChange}>
            {locations.map((location, index) => (
              <MenuItem key={location.locationId} value={index}>
                {location.locationName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogContent>
        <FormControl
          className={desktop ? classes.dropDown : classes.mobileDropDown}
        >
          <InputLabel>Redemption Type</InputLabel>
          <Select value={redemption} onChange={redemptionChange}>
            <MenuItem value={GOCARD_REDEEM_CODES.redeemParts.code}>
              {GOCARD_REDEEM_CODES.redeemParts.text}
            </MenuItem>
            <MenuItem value={GOCARD_REDEEM_CODES.redeemVehicle.code}>
              {GOCARD_REDEEM_CODES.redeemVehicle.text}
            </MenuItem>
            {isRVDealer && (
              <MenuItem value={GOCARD_REDEEM_CODES.redeemRV.code}>
                {GOCARD_REDEEM_CODES.redeemRV.text}
              </MenuItem>
            )}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogContent>
        <FormControl
          className={desktop ? classes.dropDown : classes.mobileDropDown}
          error={errors.amountSelect}
        >
          <InputLabel>Amount (Max: ${gocard.cashBalance})</InputLabel>
          <Input
            value={amount}
            type="number"
            onChange={amountChange}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
          />
        </FormControl>
        <FormControl fullWidth>
          <TextField
            label="Description"
            value={desc}
            onChange={descChange}
            multiline
            maxRows={2}
            error={errors.descSelect}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button style={theme.actions.close} onClick={onClose} variant="text">
          Close
        </Button>
        <Button
          style={theme.actions.confirm}
          onClick={() => onSubmit()}
          variant="contained"
          color="primary"
          disabled={loading}
        >
          Save
        </Button>
      </DialogActions>
      <LoadingBackdrop open={loading}>Redeeming Points...</LoadingBackdrop>
    </Dialog>
  );
};

RedeemPointsModal.fragments = {
  gocard: gql`
    fragment RedeemPointsModalGoCard on GoCardAccountV2 {
      cardId
      cashBalance
    }
  `,
};

export default RedeemPointsModal;
