import React from 'react';

/* external */
import gql from 'graphql-tag';
import { Link } from 'react-router-dom';

/* Material UI */
import Box from '@mui/material/Box';
import DeleteIcon from '@mui/icons-material/Delete';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import LaunchIcon from '@mui/icons-material/Launch';
import makeStyles from '@mui/styles/makeStyles';
import MUILink from '@mui/material/Link';
import PrintIcon from '@mui/icons-material/Print';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';

/* internal */
import { formatOdometer, vehicleTitle, formatPrice } from 'utils';
import { paveReportLink } from 'modules/appraisals/utils';
import CustomerDisplay from 'modules/appraisals/components/common/CustomerDisplay';
import FormatDateTZ from 'components/MaterialUI/FormatDateTZ';

const useStyles = makeStyles(theme => ({
  infoIcon: {
    color: theme.actions.info.backgroundColor,
    fontSize: '22px',
  },
  icon: {
    fontSize: '22px',
  },
  launchIcon: {
    '& *': {
      color: theme.actions.info.backgroundColor,
      fontSize: '22px',
    },
    '&:disabled *': {
      color: 'rgba(0,0,0,0.26)',
    },
    '& div': {
      fontSize: '9px',
    },
  },
}));

const ItemRow = ({
  appraisal,
  onClickDetails, // unused now
  onClickDelete,
  onClickPrint,
  ...rest
}) => {
  const {
    createdAt,
    customerAppraisalSource,
    dealer,
    estimatedReconCost,
    id,
    isPaveSessionComplete,
    make,
    model,
    odometer,
    paveSessionId,
    permissions,
    trim,
    trimVariation,
    value,
    vautoDeepLink,
    vin,
    year,
  } = appraisal;
  const { icon, infoIcon, launchIcon } = useStyles();

  const { name: dealerName } = dealer ?? {};

  return (
    <TableRow {...rest}>
      <TableCell>
        <Box display="flex" flexWrap="wrap" alignItems="baseline">
          <Tooltip title="Click me to find out more about this appraisal">
            <IconButton size="large">
              <Link to={`/appraisals/${id}`}>
                <InfoIcon className={infoIcon} />
              </Link>
            </IconButton>
          </Tooltip>
          <IconButton
            disabled={!vautoDeepLink}
            className={launchIcon}
            size="large"
          >
            <MUILink
              href={vautoDeepLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <LaunchIcon />
              <Box>vAuto</Box>
            </MUILink>
          </IconButton>
          <IconButton
            disabled={!isPaveSessionComplete}
            className={launchIcon}
            size="large"
          >
            <MUILink
              href={paveReportLink(paveSessionId)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon className="fas fa-road" style={{ width: 'auto' }} />
              <Box>PAVE</Box>
            </MUILink>
          </IconButton>
          <IconButton onClick={onClickPrint} size="large">
            <PrintIcon className={icon} />
          </IconButton>
          <IconButton
            disabled={!permissions?.delete}
            onClick={onClickDelete}
            size="large"
          >
            <DeleteIcon className={icon} />
          </IconButton>
        </Box>
      </TableCell>
      <TableCell>
        <Box fontWeight="bold">{vehicleTitle({ year, make, model, trim })}</Box>
        <Box>{trimVariation}</Box>
        <Box color="#999999" fontSize="10px">
          {vin}
        </Box>
        <Box color="#999999" fontSize="10px">
          {formatOdometer(odometer)}
        </Box>
        {customerAppraisalSource && (
          <Box color="#999999" fontSize="12px" mt="5px">
            Source: {customerAppraisalSource}
          </Box>
        )}
      </TableCell>
      <TableCell>{dealerName}</TableCell>
      <TableCell>
        <CustomerDisplay appraisal={appraisal} clickable small />
      </TableCell>
      <TableCell>{formatPrice(value)}</TableCell>
      <TableCell>{formatPrice(estimatedReconCost, { cents: true })}</TableCell>
      <TableCell>
        <FormatDateTZ date={createdAt} />
      </TableCell>
    </TableRow>
  );
};

ItemRow.fragments = {
  appraisal: gql`
    fragment AppraisalsListItemRowAppraisal on Appraisal {
      createdAt
      opportunity {
        _id
        customer_name
      }
      customer {
        _id
        fullname
        identities {
          identity_type
          identity_value
        }
        phone
        cell_phone
        home_phone
        work_phone
        primary_email
        primary_phone
        emails {
          email
          primary
        }
      }
      customerAppraisalSource
      dealer {
        name
        id
      }
      estimatedReconCost
      firstName
      isPaveSessionComplete
      lastName
      make
      model
      odometer
      paveSessionId
      permissions {
        update
      }
      trim
      trimVariation
      value
      vautoDeepLink
      vin
      year
      ...CustomerDisplayAppraisal
    }
    ${CustomerDisplay.fragments.appraisal}
  `,
};

export default ItemRow;
