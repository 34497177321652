/* external */
import { useFieldArray, useFormContext } from 'react-hook-form';
import React from 'react';
import gql from 'graphql-tag';

/* material-ui */
import {
  Box,
  Divider,
  Fab,
  Grid,
  Typography,
  useMediaQuery,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

/* internal */
import { TextFieldControl } from 'components/MaterialUI';

import DetailedPricing from './DetailedPricing';
import PricingPurchaseOptions from './PricingPurchaseOptions';

function MoneyItem({
  label,
  name,
  viewonly = false,
  required = false,
  ...rest
}) {
  const rules = required ? { required: `${label} is required!` } : {};

  return (
    <Grid item xs={6}>
      <TextFieldControl
        label={label}
        fullWidth
        muiVariant="outlined"
        variant="currency"
        rules={rules}
        inputProps={{ min: 0 }}
        name={viewonly ? '' : name}
        disabled={viewonly}
        {...rest}
      />
    </Grid>
  );
}
export default function PricingBox({ vehicle, showOnWebsite }) {
  const { watch, control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'detailedPricing',
    keyName: 'key',
  });
  const mobile = useMediaQuery(theme => theme.breakpoints.down('md'));

  const enableAddSpecialPricing =
    watch('cost') && watch('msrp') && watch('regularPrice');
  const priceLineWarningMessage = !enableAddSpecialPricing
    ? 'Cost, MSRP, and Regular Price must be added before creating a detailed price line!'
    : fields.length === 0 && 'Add a price line to enable special pricing!';

  function handleAddClick() {
    append({
      priceModifierTypeName: 'addon',
      priceDescription: '',
      value: 0,
      expiry: null,
      noExpiry: false,
      paymentType: 'BOTH',
      showOnWebsite: showOnWebsite ? 'true' : 'false',
    });
  }

  return (
    <Box>
      <Box margin={1}>
        <Grid
          container
          spacing={2}
          direction={mobile ? 'column-reverse' : 'row'}
        >
          <Grid container item xs={12} md={6} spacing={2}>
            <MoneyItem label="Cost" name="cost" required />
            <MoneyItem label="MSRP" name="msrp" required />
            <MoneyItem label="Regular Price" name="regularPrice" required />
            <MoneyItem label="Default Down Payment" name="defaultDownPayment" />
            <MoneyItem
              label="Special Price (Cash)"
              value={vehicle?.specialPriceCash}
              viewonly
            />
            <MoneyItem
              label="Special Price (Finance)"
              value={vehicle?.specialPriceFinance}
              viewonly
            />
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={6}
            spacing={2}
            style={{ justifyContent: 'center' }}
          >
            <PricingPurchaseOptions vehicle={vehicle || {}} />
          </Grid>
        </Grid>
      </Box>
      <Box
        margin={1}
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginBottom: '20px',
          marginTop: '20px',
          columnGap: '20px',
        }}
      >
        <Typography variant="h6">Special Pricing</Typography>
        <Fab
          disabled={!enableAddSpecialPricing}
          size="small"
          onClick={() => handleAddClick()}
          style={{
            display: 'flex',
            backgroundColor: enableAddSpecialPricing ? '#5bc236' : 'grey',
            color: 'white',
          }}
        >
          <AddIcon />
        </Fab>
      </Box>
      <Box margin={1} style={{ paddingBottom: '20px' }}>
        <Divider style={{ marginBottom: '10px' }} />
        {priceLineWarningMessage}
        <DetailedPricing fields={fields} remove={remove} />
      </Box>
    </Box>
  );
}

PricingBox.fragments = {
  vehicle: gql`
    fragment NpvVehiclePricingBox on NpvVehicle {
      id
      biWeeklyFinancePayment
      cost
      defaultDownPayment
      isOnSpecial
      msrp
      regularPrice
      specialPriceCash
      specialPriceCashAddOns
      specialPriceCashDiscounts
      specialPriceFinance
      specialPriceFinanceAddOns
      specialPriceFinanceDiscounts
      ...NpvVehicleDetailedPricing
    }
    ${DetailedPricing.fragments.vehicle}
  `,
};
