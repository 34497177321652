/* external */
import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';

/* material-ui */
import {
  CircularProgress,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';

/* internal */
import { useCSVGenerator } from './CSVGenerator';
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import BulkVehicleDescription from './BulkVehicleDescription';
import BulkNpvMultiLinePricing from './BulkNpvMultiLinePricing';
import BulkUpdateStatus from './BulkUpdateStatus';
import BulkVehiclePricing from './BulkVehiclePricing';

const PHOTO_URL_QUERY = gql`
  query PhotoURLQuery($prefixes: [String!]!) {
    npv {
      downloadPhotosURL(prefixes: $prefixes)
    }
  }
`;

const useImperativeQuery = query => {
  const { refetch } = useQuery(query, { skip: true });

  const imperativelyCallQuery = variables => {
    return refetch(variables);
  };

  return imperativelyCallQuery;
};

const BulkActions = ({
  anchorEl,
  filters,
  handleAnchorClose,
  refetch,
  selectByFilter,
  selected,
  total,
  vehicles,
}) => {
  const { dealerId } = useDealerContext();
  const { enqueueSnackbar } = useSnackbar();

  const [creatingCSV, setCreatingCSV] = useState(false);
  const [downloadingPhotos, setDownloadingPhotos] = useState(false);
  const [showDescription, setShowDescription] = useState(false);
  const [showMultiLinePricing, setShowMultiLinePricing] = useState(false);
  const [showRegularPricing, setShowRegularPricing] = useState(false);
  const [showStatus, setShowStatus] = useState(false);

  const BULK_VEHICLES_QUERY = gql`
    query BulkVehiclesQuery($filters: [QueryFilter]) {
      npv {
        getBulkVehicles(filters: $filters) {
          ...NpvBulkActionsVehicle
        }
      }
    }
    ${BulkActions.fragments.vehicle}
  `;

  const callBulkQuery = useImperativeQuery(BULK_VEHICLES_QUERY);
  const callPhotosURLQuery = useImperativeQuery(PHOTO_URL_QUERY);

  const totalSelected = selectByFilter ? total : selected.length;

  useEffect(() => {
    const downloadPhotos = async () => {
      let stockNumbers = [];
      if (selectByFilter) {
        const { data } = await callBulkQuery({ filters });
        stockNumbers = data.npv.getBulkVehicles.map(v => v.stockNumber);
      } else
        stockNumbers = vehicles
          .filter(v => selected.includes(v.id))
          .map(v => v.stockNumber);

      const prefixes = stockNumbers.map(
        stockNumber => `npv_inventory/${dealerId}/${stockNumber}`,
      );

      let {
        data: {
          npv: { downloadPhotosURL },
        },
      } = await callPhotosURLQuery({ prefixes });

      const element = document.createElement('a');
      element.href = downloadPhotosURL;
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);

      setDownloadingPhotos(false);
      handleAnchorClose();
    };

    if (downloadingPhotos && totalSelected > 20) {
      enqueueSnackbar(
        'Sorry, you may only choose up to a maximum of 20 vehicles for photo download.',
      );
      setDownloadingPhotos(false);
      handleAnchorClose();
    } else if (downloadingPhotos) downloadPhotos();
  }, [
    callBulkQuery,
    callPhotosURLQuery,
    dealerId,
    downloadingPhotos,
    enqueueSnackbar,
    filters,
    handleAnchorClose,
    selectByFilter,
    selected,
    totalSelected,
    vehicles,
  ]);

  useCSVGenerator(
    creatingCSV,
    setCreatingCSV,
    handleAnchorClose,
    selectByFilter,
    callBulkQuery,
    filters,
    selected,
    vehicles,
  );

  const busy = creatingCSV || downloadingPhotos;

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={!busy ? handleAnchorClose : null}
        style={{ width: '300px' }}
      >
        <MenuItem
          disabled={selected.length === 0 || busy}
          onClick={() => setShowDescription(true)}
        >
          Update Description
        </MenuItem>
        <MenuItem
          disabled={selected.length === 0 || busy}
          onClick={() => setShowRegularPricing(true)}
        >
          Update Regular Pricing
        </MenuItem>
        <MenuItem
          disabled={selected.length === 0 || busy}
          onClick={() => setShowMultiLinePricing(true)}
        >
          Update Multi-Line Pricing
        </MenuItem>
        <MenuItem
          disabled={selected.length === 0 || busy}
          onClick={() => setShowStatus(true)}
        >
          Update Status
        </MenuItem>
        {creatingCSV ? (
          <MenuItem disabled>
            <ListItemIcon style={{ minWidth: '25px' }}>
              <CircularProgress size={20} />
            </ListItemIcon>
            <ListItemText primary="Generating CSV..." />
          </MenuItem>
        ) : (
          <MenuItem
            disabled={selected.length === 0 || busy}
            onClick={() => setCreatingCSV(true)}
          >
            Export to CSV
          </MenuItem>
        )}
        {downloadingPhotos ? (
          <MenuItem disabled>
            <ListItemIcon style={{ minWidth: '25px' }}>
              <CircularProgress size={20} />
            </ListItemIcon>
            <ListItemText primary="Downloading..." />
          </MenuItem>
        ) : (
          <MenuItem
            disabled={selected.length === 0 || busy}
            onClick={() => setDownloadingPhotos(true)}
          >
            Download Photos
          </MenuItem>
        )}
      </Menu>
      <BulkNpvMultiLinePricing
        onClose={() => setShowMultiLinePricing(false)}
        open={showMultiLinePricing}
        ids={selected}
        selectByFilter={selectByFilter}
        vehicleFilters={filters}
        refetch={refetch}
      />
      <BulkVehiclePricing
        open={showRegularPricing}
        onClose={() => setShowRegularPricing(false)}
        ids={selected}
        selectByFilter={selectByFilter}
        vehicleFilters={filters}
      />
      <BulkUpdateStatus
        onClose={() => setShowStatus(false)}
        open={showStatus}
        ids={selected}
        selectByFilter={selectByFilter}
        vehicleFilters={filters}
      />
      <BulkVehicleDescription
        onClose={() => setShowDescription(false)}
        open={showDescription}
        ids={selected}
        selectByFilter={selectByFilter}
        vehicleFilters={filters}
      />
    </>
  );
};

BulkActions.fragments = {
  vehicle: gql`
    fragment NpvBulkActionsVehicle on NpvVehicle {
      id
      stockType
      stockNumber
      trim
      vin
      interiorColourName
      msrp
      regularPrice
      cost
      specialPrice
      odometer
      exteriorColourName
      photoCount
      photos {
        cloudinaryPublicId
      }
      daysInStock
      isFeatured
      isOnSpecial
      model {
        id
        name
        make {
          name
          year
        }
      }
    }
  `,
};

export default BulkActions;
