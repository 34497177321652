import React, { useState } from 'react';

/* external */
import { isEqual } from 'lodash';

/* Material UI */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CategoryIcon from '@mui/icons-material/Category';
import Chip from '@mui/material/Chip';
import DownIcon from '@mui/icons-material/ArrowDownward';
import Fab from '@mui/material/Fab';
import FilterListIcon from '@mui/icons-material/FilterList';
import makeStyles from '@mui/styles/makeStyles';
import SortIcon2 from '@mui/icons-material/Sort';
import Tooltip from '@mui/material/Tooltip';
import UpIcon from '@mui/icons-material/ArrowUpward';
import { useTheme } from '@mui/material';

/* internal */
import {
  CREATED_SORT,
  RECON_COST_SORT,
  VALUE_SORT,
  VEHICLE_SORT_OPTIONS,
} from 'modules/appraisals/const';
import { categoryOptions } from 'modules/appraisals/utils';
import FilterChipsBox from 'components/MaterialUI/FilterChipsBox';
import SearchField from './SearchField';
import ListSelectDialog from 'components/MaterialUI/ListSelectDialog';

const useStyles = makeStyles(theme => ({
  fab: {
    bottom: '10px',
    right: '10px',
    position: 'fixed',
    zIndex: 999,
  },
}));

const up = sort => ({ ...sort, direction: 'asc' });
const dn = sort => ({ ...sort, direction: 'desc' });

const sorts = [
  {
    sortOption: [up(CREATED_SORT)],
    label: 'Created ASC',
    shortLabel: 'Created',
  },
  {
    sortOption: [dn(CREATED_SORT)],
    label: 'Created DESC',
    shortLabel: 'Created',
  },
  {
    sortOption: [up(RECON_COST_SORT)],
    label: 'Recon Cost ASC',
    shortLabel: '$Recon',
  },
  {
    sortOption: [dn(RECON_COST_SORT)],
    label: 'Recon Cost DESC',
    shortLabel: '$Recon',
  },
  { sortOption: [up(VALUE_SORT)], label: 'Value ASC', shortLabel: '$Value' },
  { sortOption: [dn(VALUE_SORT)], label: 'Value DESC', shortLabel: '$Value' },
  ...VEHICLE_SORT_OPTIONS,
];

const MobileHeader = ({
  categoryFiltersIndex,
  categoryStats,
  dateFilters,
  facetFilters,
  length,
  onChangeSearch,
  onClickFilter,
  search,
  setCategoryFiltersIndex,
  setDateFilters,
  setFacetFilters,
  setSort,
  sort,
  total,
  ...rest
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const [sortDialogOpen, setSortDialogOpen] = useState(false);
  const [statsDialogOpen, setStatsDialogOpen] = useState(false);

  return (
    <Box {...rest}>
      <ListSelectDialog
        fullScreen
        isSelected={(_, index) => categoryFiltersIndex === index}
        onClickOption={(_, index) => setCategoryFiltersIndex(index)}
        onClose={() => setStatsDialogOpen(false)}
        open={statsDialogOpen}
        optionLabelKey="mobileLabel"
        options={categoryOptions(categoryStats).map((x, key) => ({
          ...x,
          key, // WARNING: using index as key can be bad.
        }))}
        title="Choose Category"
      />

      <ListSelectDialog
        fullScreen
        isSelected={({ sortOption }) => isEqual(sortOption, sort)}
        onClickOption={({ sortOption }) => {
          setSort(sortOption);
          setSortDialogOpen(false);
        }}
        onClose={() => setSortDialogOpen(false)}
        open={sortDialogOpen}
        options={sorts.map(({ sortOption, label }, key) => ({
          label,
          sortOption,
          key, // WARNING: using index as key can be bad.
        }))}
        title="Choose Sort"
      />

      <Box display="flex" justifyContent="space-between">
        <Box flexBasis="0" flexGrow="1">
          <Button
            startIcon={<SortIcon2 />}
            variant="outlined"
            onClick={() => setSortDialogOpen(true)}
            style={{ width: '100%' }}
            endIcon={sort?.[0].direction === 'asc' ? <UpIcon /> : <DownIcon />}
          >
            {
              sorts.find(({ sortOption }) => isEqual(sort, sortOption))
                ?.shortLabel
            }
          </Button>
        </Box>
        <Box flexBasis="0" flexGrow="1">
          <Button
            variant="outlined"
            style={{ width: '100%' }}
            startIcon={<CategoryIcon />}
            onClick={() => setStatsDialogOpen(true)}
          >
            {categoryOptions(categoryStats)[categoryFiltersIndex].mobileLabel}
          </Button>
        </Box>
      </Box>
      <SearchField
        onChange={onChangeSearch}
        search={search}
        fullWidth
        style={{ marginTop: theme.spacing(1) }}
      />
      <FilterChipsBox
        filters={facetFilters}
        marginBottom={1}
        marginTop={1}
        setFilters={setFacetFilters}
      >
        {(dateFilters.to || dateFilters.from) && (
          <Chip
            onDelete={() => setDateFilters({ to: null, from: null })}
            label={`${dateFilters.from?.format('YYYY-MM-DD') ?? 'All'} - ${
              dateFilters.to?.format('YYYY-MM-DD') ?? 'Now'
            }`}
          />
        )}
      </FilterChipsBox>

      <Box textAlign="right">
        Showing {length} of {total}
      </Box>
      <Fab
        color="primary"
        className={classes.fab}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          onClickFilter();
        }}
      >
        <Tooltip title="Filter list">
          <FilterListIcon />
        </Tooltip>
      </Fab>
    </Box>
  );
};

export default MobileHeader;
