import React, { useEffect, useMemo, useState } from 'react';

/* external */
import { NetworkStatus } from 'apollo-boost';
import { startCase } from 'lodash';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import { Waypoint } from 'react-waypoint';
import gql from 'graphql-tag';
import moment from 'moment';

/* Material UI */
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import Fab from '@mui/material/Fab';
import FilterListIcon from '@mui/icons-material/FilterList';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';

/* internal */
import {
  CBB_CONDITION_SORT,
  CBB_VALUE_SORT,
  COST_SORT,
  DAYS_IN_STOCK_SORT,
  PHOTO_COUNT_SORT,
  PRICE_SORT,
  SORT_BY_ID,
  VEHICLE_SORT_OPTIONS,
} from './const';
import { filtersFromFacets } from 'modules/auctions/components/items/utils';
import { greaseVehicleToAuctionsVehicle } from 'modules/auctions/utils';
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import { usePersistedState } from 'utils';
import AgedInventoryRow from './AgedInventoryRow';
import FilterChips from 'modules/auctions/components/items/common/FilterChips';
import FilterDrawer from './FilterDrawer';
import Loading from 'components/MaterialUI/Loading';
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';
import SortPopover from 'modules/auctions/components/items/common/SortPopover';

const PAGE_SIZE = 20;

const useStyles = makeStyles(theme => ({
  auctionFab: {
    margin: 0,
    top: 'auto',
    position: 'fixed',
    backgroundColor: 'green',
    color: 'white',
    zIndex: 10,
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  auctionFabIcon: {
    marginRight: theme.spacing(1),
  },
}));

const AGED_INVENTORY_QUERY = gql`
    query agedInventory($filters: [QueryFilter], $page: Int!, $sort: [QuerySortElement]) {
      inventory {
        getVehicles( filters: $filters, page: $page, page_size:${PAGE_SIZE}, sort: $sort){
          pagination {
            total
            next_page
          }
          results {
            id
            date_in_stock
            days_in_stock
            is_auctionable_without_cbb
            open_auction_items {
              id
              isActive
              isUpcoming
            }
            preselected_aged_inventory_vehicle {
              id
            }
            ...AgedInventoryRowVehicle
          }
        }
      }
    }
    ${AgedInventoryRow.fragments.vehicle}
  `;

const BULK_AGED_INVENTORY_QUERY = gql`
  query bulkAgedInventory($filters: [QueryFilter]) {
    inventory {
      getBulkVehicles(filters: $filters) {
        id
        date_in_stock
        days_in_stock
        is_auctionable_without_cbb
        open_auction_items {
          id
          isActive
          isUpcoming
          vehicle {
            id
            sendingService
            sendingServiceId
          }
        }
        ...AgedInventoryRowVehicle
      }
    }
  }
  ${AgedInventoryRow.fragments.vehicle}
`;

// This is using a lot of resolvers....especially since it's kinda
// going full circle.  But it's necessary to get the updated inventory vehicle
// (to update status)
const CREATE_ITEM = gql`
  mutation createItem($auctionItem: AuctionItemInput!) {
    auctions {
      createAuctionItem(auctionItem: $auctionItem) {
        id
        vehicle {
          id
          inventoryVehicle {
            id
            ...AgedInventoryRowVehicle
          }
        }
      }
    }
  }
  ${AgedInventoryRow.fragments.vehicle}
`;

const isAged = (vehicle, auction) => {
  const { weekly } = auction ?? {};
  const { mustAuctionDays, nextStart } = weekly ?? {};

  const minDaysInStock =
    mustAuctionDays +
    moment
      .duration(
        moment(nextStart + 'Z')
          .startOf('Day')
          .diff(moment().startOf('Day')),
      )
      .days();

  return vehicle.days_in_stock >= minDaysInStock;
};

const AgedInventoryTable = ({ auction }) => {
  const { dealerId } = useDealerContext();
  const theme = useTheme();
  const classes = useStyles(theme);
  const { enqueueSnackbar: snackIt } = useSnackbar();
  const [createItem] = useMutation(CREATE_ITEM);

  const [sortSelection, setSortSelection] = usePersistedState(
    'auctions.components.vehicles.AgedInventoryTable.sortSelection',
    null,
  );
  const [searchKeywords, setSearchKeywords] = usePersistedState(
    'auctions.components.vehicles.AgedInventoryTable.searchKeywords',
    '',
  );
  const [facetFilters, setFacetFilters] = usePersistedState(
    'auctions.components.vehicles.AgedInventoryTable.facetFilters',
    [],
  );
  const [agedToSend, setAgedToSend] = useState([]);
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
  const [popoverAnchor, setPopoverAnchor] = useState();
  const [preselectedIds, setPreselectedIds] = useState([]);
  const [searchFilters, setSearchFilters] = useState([]);
  const [sendToAuction, setSendToAuction] = useState(false);
  const [totalToSend, setTotalToSend] = useState();

  /*
    year: exact match,
    make: starts with keyword,
    model: starts with keyword,
    stock_number: contains keyword
  */
  const setSearchFiltersFromKeywords = () =>
    setSearchFilters(
      searchKeywords.split(' ').map(keyword => ({
        or: [
          {
            model: 'Make',
            field: 'name',
            op: 'ilike',
            value: `${keyword}%`,
          },
          {
            model: 'Model',
            field: 'name',
            op: 'ilike',
            value: `${keyword}%`,
          },
          {
            model: 'Vehicle',
            field: 'stock_number',
            op: 'ilike',
            value: `%${keyword}%`,
          },
          {
            model: 'Vehicle',
            field: 'year',
            op: '==',
            value: parseInt(keyword) || 0,
          },
        ],
      })),
    );

  useEffect(() => {
    if (searchKeywords) setSearchFiltersFromKeywords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { canAdd } = auction;

  const [getAgedInventory, { data: agedQueryData, loading: loadingAged }] =
    useLazyQuery(BULK_AGED_INVENTORY_QUERY, {
      fetchPolicy: 'network-only',
    });

  const byDealerFilter = {
    model: 'Vehicle',
    field: 'dealer_id',
    op: '==',
    value: dealerId,
  };
  const inStockFilter = {
    model: 'Vehicle',
    field: 'stock_status_name',
    op: '==',
    value: 'In Stock',
  };
  const notDemoFilter = {
    model: 'Vehicle',
    field: 'is_demo_unit',
    op: '==',
    value: false,
  };
  const notLoanerFilter = {
    model: 'Vehicle',
    field: 'is_loaner',
    op: '==',
    value: false,
  };

  const filters = [
    byDealerFilter,
    inStockFilter,
    notDemoFilter,
    notLoanerFilter,
    ...searchFilters,
    ...filtersFromFacets(facetFilters),
  ];

  const sort = [sortSelection, SORT_BY_ID].filter(x => x);

  const { data, fetchMore, networkStatus, loading } = useQuery(
    AGED_INVENTORY_QUERY,
    {
      variables: {
        filters,
        page: 1,
        sort,
      },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    },
  );

  const vehicles = data?.inventory.getVehicles.results ?? [];
  const { next_page: nextPage, total } =
    data?.inventory.getVehicles.pagination ?? {};

  const handleMoreVehicles = () =>
    nextPage
      ? fetchMore({
          variables: { page: nextPage },
          updateQuery: (prev, { fetchMoreResult: more }) => {
            if (!more.inventory.getVehicles.results) return prev;
            return Object.assign({}, prev, {
              inventory: {
                __typename: prev.inventory.__typename,
                getVehicles: {
                  __typename: prev.inventory.getVehicles.__typename,
                  results: [
                    ...prev.inventory.getVehicles.results,
                    ...more.inventory.getVehicles.results,
                  ],
                  pagination: more.inventory.getVehicles.pagination,
                },
              },
            });
          },
        })
      : null;

  const handleSearch = e =>
    e.key === 'Enter' ? setSearchFiltersFromKeywords() : null;

  const isSortingBy = ({ model, field, direction }) =>
    sortSelection?.field === field &&
    sortSelection?.model === model &&
    (!direction || sortSelection?.direction === direction);

  const sortDirection = sortSelection?.direction;
  const clickSortHandler =
    ({ model, field }) =>
    () =>
      setSortSelection(prev => ({
        model,
        field,
        direction:
          prev?.model === model &&
          prev?.field === field &&
          prev?.direction === 'asc'
            ? 'desc'
            : 'asc',
      }));

  const vehicleSort = VEHICLE_SORT_OPTIONS.find(isSortingBy);

  const vehicleSortLabel = vehicleSort
    ? vehicleSort.options?.headerLabel ?? `(${startCase(vehicleSort.field)})`
    : null;

  const handleSendToAuction = () => setSendToAuction(true);

  useEffect(() => {
    // TODO: Would be nice to load these in one page at a time
    if (sendToAuction && !agedQueryData) {
      getAgedInventory({
        variables: {
          filters: [inStockFilter, byDealerFilter],
        },
      });
    }

    if (sendToAuction && agedQueryData) {
      const inventoryVehicles = agedQueryData.inventory.getBulkVehicles;

      // verify that all aged vehicles can be sent to auction.
      const aged = inventoryVehicles.filter(v => isAged(v, auction));
      // TODO: MPAUC-94: Indicate to user reasons why vehicles not auctionable
      if (aged.some(v => !v.is_auctionable_without_cbb)) {
        snackIt(
          'Unable to send to auction.  Some aged vehicles are not auctionable.',
          { variant: 'error' },
        );
        setSendToAuction(false);
      } else {
        const _agedToSend = aged.filter(x => x.open_auction_items.length === 0);
        const _preselectedIds = inventoryVehicles
          .filter(x => x.open_auction_items.length === 0)
          .filter(x => x.preselected_aged_inventory_vehicle)
          .map(x => x.preselected_aged_inventory_vehicle.id);

        setAgedToSend(_agedToSend);
        setPreselectedIds(_preselectedIds);
        setTotalToSend(_agedToSend.length + _preselectedIds.length);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendToAuction, agedQueryData]);

  useEffect(() => {
    if (agedToSend.length > 0) {
      // Pop the first aged vehicle, then send it.  Since we're using react
      // states, we can't actually use array.pop().
      const agedVehicle = agedToSend[0];
      createItem({
        variables: {
          auctionItem: {
            auctionId: auction.id,
            dealerId,
            vehicle: greaseVehicleToAuctionsVehicle(agedVehicle), // TODO: make a
            // fragment or something in utils to ensure we are getting all the fields
            // necessary to send to auctions
          },
        },
      });
      setAgedToSend(prev => prev.filter(x => x.id !== agedVehicle.id));
    } else if (preselectedIds.length > 0) {
      const preselectedId = preselectedIds[0];
      createItem({
        variables: {
          auctionItem: {
            auctionId: auction.id,
            dealerId,
            vehicleId: preselectedId,
          },
        },
      });
      setPreselectedIds(prev => prev.filter(x => x !== preselectedId));
    } else if (totalToSend > 0 && agedQueryData) {
      setTotalToSend(0);
      setSendToAuction(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    agedToSend,
    preselectedIds,
    sendToAuction,
    setSendToAuction,
    setPreselectedIds,
    setAgedToSend,
    agedQueryData,
  ]);

  const renderedVehicles = useMemo(
    () =>
      vehicles.map(vehicle => (
        <AgedInventoryRow key={vehicle.id} vehicle={vehicle} />
      )),
    [vehicles],
  );

  // we used to check if there were any preselected or any aged, but we can't really
  // do that anymore, since we aren't loading in all the vehicles first.
  // I guess we can look at what's loaded and see if any are aged and not auctionable

  const disableSendToAuction = useMemo(
    () =>
      !canAdd ||
      vehicles.some(v => isAged(v, auction) && v.is_auctionable_without_cbb),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [canAdd, vehicles],
  );

  if (loading && networkStatus !== NetworkStatus.fetchMore) return <Loading />;

  return (
    <>
      <LoadingBackdrop
        open={sendToAuction}
        circularProgressProps={
          totalToSend > 0
            ? {
                variant: 'determinate',
                value:
                  ((totalToSend - agedToSend.length - preselectedIds.length) /
                    totalToSend) *
                  100,
              }
            : {}
        }
      >
        {loadingAged && `Loading Aged Inventory....`}
        {totalToSend > 0 &&
          `Sending ${
            totalToSend - agedToSend.length - preselectedIds.length
          } / ${totalToSend} `}
      </LoadingBackdrop>
      <FilterDrawer
        anchor="left"
        open={filterDrawerOpen}
        onClose={() => setFilterDrawerOpen(false)}
        filters={filters}
        facetFilters={facetFilters}
        setFacetFilters={setFacetFilters}
      />
      <SortPopover
        anchorEl={popoverAnchor}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={Boolean(popoverAnchor)}
        onClose={() => setPopoverAnchor(null)}
        sortOptions={VEHICLE_SORT_OPTIONS}
        setSortSelection={setSortSelection}
        sortSelection={sortSelection}
      />
      <Box
        display="flex"
        width="100%"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box paddingRight={2}>
          <Tooltip title="Filter list">
            <Fab
              color="primary"
              onClick={() => setFilterDrawerOpen(true)}
              size="medium"
            >
              <FilterListIcon />
            </Fab>
          </Tooltip>
        </Box>
        <Box padding={1} flexGrow={2}>
          <FilterChips filters={facetFilters} setFilters={setFacetFilters} />
        </Box>
        <Box display="flex" flexDirection="column" alignItems="flex-end">
          <Box>
            <TextField
              placeholder="Search ..."
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      disabled={!searchKeywords}
                      onClick={() => {
                        setSearchKeywords('');
                        setSearchFilters([]);
                      }}
                      size="large"
                    >
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              value={searchKeywords}
              onChange={e => setSearchKeywords(e.target.value)}
              style={{ paddingRight: '5px', textAlign: 'right' }}
              onKeyPress={handleSearch}
            />
          </Box>

          <Box paddingTop={1} fontSize="18px">
            Showing {vehicles.length} of {total}
          </Box>
        </Box>
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            {/* actions */}
            <TableCell />
            {/* Status (Preselected, At Auction, Upcoming */}
            <TableCell />
            {/* Auctionability chips */}
            <TableCell />
            <TableCell>
              <TableSortLabel
                onClick={e => setPopoverAnchor(e.currentTarget)}
                active={VEHICLE_SORT_OPTIONS.some(isSortingBy)}
                direction={sortDirection}
              >
                Vehicle {vehicleSortLabel}
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={isSortingBy(PRICE_SORT)}
                direction={sortDirection}
                onClick={clickSortHandler(PRICE_SORT)}
              >
                Price
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={isSortingBy(COST_SORT)}
                direction={sortDirection}
                onClick={clickSortHandler(COST_SORT)}
              >
                Cost
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={isSortingBy(PHOTO_COUNT_SORT)}
                direction={sortDirection}
                onClick={clickSortHandler(PHOTO_COUNT_SORT)}
              >
                Pics
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={isSortingBy(DAYS_IN_STOCK_SORT)}
                direction={sortDirection}
                onClick={clickSortHandler(DAYS_IN_STOCK_SORT)}
              >
                Days
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={isSortingBy(CBB_CONDITION_SORT)}
                direction={sortDirection}
                onClick={clickSortHandler(CBB_CONDITION_SORT)}
              >
                CBB Condition
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={isSortingBy(CBB_VALUE_SORT)}
                direction={sortDirection}
                onClick={clickSortHandler(CBB_VALUE_SORT)}
              >
                CBB Value
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{renderedVehicles}</TableBody>
      </Table>
      {!loading && <Waypoint onEnter={handleMoreVehicles} />}
      {loading && networkStatus === NetworkStatus.fetchMore ? (
        <Loading text="Loading more vehicles..." />
      ) : (
        nextPage && <Box height="6rem" />
      )}
      <Tooltip title="Send preselected items to auction">
        <Box component="span">
          <Fab
            disabled={disableSendToAuction}
            onClick={handleSendToAuction}
            className={classes.auctionFab}
            variant="extended"
          >
            <Icon className={`fa fa-gavel ${classes.auctionFabIcon}`} />
            Send To Auction
          </Fab>
        </Box>
      </Tooltip>
    </>
  );
};

AgedInventoryTable.fragments = {
  auction: gql`
    fragment AgedInventoryTableAuction on Auction {
      canAdd
      weekly {
        id
        mustAuctionDays
        nextStart
      }
    }
  `,
};
export default AgedInventoryTable;
