import { TextField } from '@mui/material';
import React, { useCallback, useEffect } from 'react';

/**
 * ValidationTextField is a wrapper for TextField that adds data validation
 * @param {onValidate} Function that gets called to validate data. This function should return an error message on failure and null when successful.
 */
const ValidationTextField = ({
  // Dummies for onChange and onValidate, so there's no need to check for null later
  onChange = () => null,
  onValidate = () => null,
  ...props
}) => {
  const [error, setError] = React.useState();

  const validate = useCallback(
    value => {
      setError(onValidate(value ?? ''));
    },
    [onValidate],
  );

  useEffect(() => {
    validate(props.value);
  }, [props.value, validate]);

  return (
    <TextField
      // The error prop always overrides any errors we set here
      error={Boolean(props.error) || Boolean(error)}
      helperText={props.error || error}
      onChange={e => {
        // Call parent onchange (or the dummy as supplied above)
        onChange(e);

        validate(e.target.value);
      }}
      {...props}
    />
  );
};

export default ValidationTextField;
