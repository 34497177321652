/* external */
import { isEmpty } from 'lodash';
import React from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';

/* Material UI */
import { makeStyles } from '@mui/styles';
import {
  Done as DoneIcon,
  ExpandMore as ExpandMoreIcon,
  HighlightOff as HighlightOffIcon,
} from '@mui/icons-material';

const useStyles = makeStyles(theme => ({
  panel: {
    width: '100%',
    paddingLeft: '25px',
    paddingRight: '25px',
  },
  title: {
    fontSize: '20px',
    paddingBottom: '10px',
    fontWeight: 'bold',
  },
  subPanel: {
    width: '100%',
  },
}));

/**
 * Note: We are using this filter drawer differently than the other filter drawers
 * since the service appointments are stored in vehicle-sales-history and the sales_person
 * field on service appointments are not the ones we want to filter by. Instead, we want to
 * use the assigned_salespeople field array through the customer resolver on the service appointment.
 */
const ServiceFilterDrawer = ({
  dealerId,
  assignees,
  appointments,
  assigneeFilter,
  setAssigneeFilter,
  loading,
}) => {
  const classes = useStyles();

  // makes sure we only filter one assignee at a time
  const assigneeList = assigneeFilter.length ? assigneeFilter : assignees;

  const deleteAllFilters = () => setAssigneeFilter([]);

  const showReset = !isEmpty(assigneeFilter);

  const toggleFilter = assignee =>
    setAssigneeFilter(prev => (prev.includes(assignee) ? [] : [assignee]));

  const dealerIdMatches = salesperson =>
    parseInt(salesperson.dealer_id, 10) === dealerId;

  const isAssigneeAndCorrectDealer = (salesperson, assignee) =>
    dealerIdMatches(salesperson) &&
    salesperson?.user?.display_name === assignee;

  const isInactiveOrWrongDealer = salesperson =>
    !dealerIdMatches(salesperson) ||
    !salesperson?.user?.display_name ||
    !salesperson?.user;

  const getDefinedAssigneesCount = assignee =>
    appointments.filter(
      appointment =>
        appointment?.customer?.assigned_salespeople?.filter(salesperson =>
          isAssigneeAndCorrectDealer(salesperson, assignee),
        ).length > 0,
    ).length;

  const getUndefinedAssigneesCount = assignee =>
    appointments.filter(
      appointment =>
        appointment?.customer?.assigned_salespeople?.filter(salesperson =>
          isInactiveOrWrongDealer(salesperson, assignee),
        ).length === appointment?.customer?.assigned_salespeople?.length,
    ).length;

  const countAssignee = assignee => {
    return assignee
      ? getDefinedAssigneesCount(assignee)
      : getUndefinedAssigneesCount(assignee);
  };
  return (
    <List className={classes.panel}>
      <Box className={classes.title}>Filters</Box>
      {showReset && (
        <Box style={{ paddingBottom: '10px' }}>
          <Button style={{ color: 'red' }} onClick={deleteAllFilters}>
            <HighlightOffIcon style={{ paddingRight: '5px' }} /> RESET ALL
          </Button>
        </Box>
      )}
      <Box style={{ paddingBottom: '10px' }}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            Assignee
          </AccordionSummary>
          <AccordionDetails>
            <List component="div" disablePadding className={classes.subPanel}>
              {assigneeList.map(assignee => (
                <ListItem
                  key={assignee}
                  disabled={loading}
                  dense
                  button
                  onClick={() => toggleFilter(assignee)}
                  selected={assigneeFilter.includes(assignee)}
                >
                  {assigneeFilter.includes(assignee) ? (
                    <DoneIcon
                      style={{
                        fontSize: 'small',
                        paddingRight: '10px',
                        width: '25px',
                      }}
                    />
                  ) : (
                    <div style={{ paddingRight: '10px', width: '25px' }}> </div>
                  )}
                  <ListItemText
                    primary={`${assignee || 'Unknown'} (${countAssignee(
                      assignee,
                    )})`}
                  />
                </ListItem>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
      </Box>
    </List>
  );
};

export default ServiceFilterDrawer;
