/* external */
import React, { useEffect, useState } from 'react';
import { isEmpty, every } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import moment from 'moment-timezone';
import { NetworkStatus } from 'apollo-boost';
import { Waypoint } from 'react-waypoint';
import { produce } from 'immer';

/* Material UI */
import {
  Box,
  Checkbox,
  Drawer,
  Fab,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { makeStyles } from '@mui/styles';

import {
  Add as AddIcon,
  Close as CloseIcon,
  FilterList as FilterListIcon,
  Search as SearchIcon,
} from '@mui/icons-material';

/* internal */
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';
import { useUserContext } from 'components/MaterialUI/UserContext';
import { usePersistedState, defaultTZ } from 'utils';
import { filtersFromFacets } from '../utils';
import DashboardCard from '../components/DashboardCard';
import EmptyMessage from '../components/EmptyMessage';
import FacetChips from '../components/FacetChips';
import FilterDropDown from './FilterDropDown';
import Title from '../components/Title';
import UsedVehicleToolbar from './UsedVehicleToolbar';
import UsedVehicleBulkActions from './UsedVehicleBulkActions';

const PAGE_SIZE = 20;

const queryFacets = [
  {
    model: 'Appraisal',
    field: 'year',
  },
  {
    model: 'Appraisal',
    field: 'hasPaveSessionId',
  },
  {
    model: 'Appraisal',
    field: 'make',
  },
  {
    model: 'Appraisal',
    field: 'model',
  },
  {
    model: 'Appraisal',
    field: 'appraisalStatus',
  },
  {
    model: 'Appraisal',
    field: 'bodyType',
  },
  {
    model: 'Appraisal',
    field: 'buyer',
  },
  {
    model: 'Seller',
    field: 'name',
  },
  {
    model: 'Appraisal',
    field: 'isBuyNow',
  },
  {
    model: 'Appraisal',
    field: 'locatedProvince',
  },
  {
    model: 'Appraisal',
    field: 'createdBy',
  },
  {
    model: 'Appraisal',
    field: 'customerAppraisalSource',
  },
];

const modelToField = {
  Seller: 'seller',
};

const APPRAISALS_QUERY = gql`
  query AppraisalsQuery(
    $queryFacets: [QueryFacet]!
    $filters: [QueryFilter]
    $sort: [QuerySortElement]
    $page: Int!
  ) {
    appraisals {
      appraisals(filters: $filters, page: $page, pageSize: ${PAGE_SIZE}, sort: $sort) {
        results {
          id
          ...DashboardCardAppraisal
        }
        pagination {
          nextPage
          total
        }
      }
      appraisalsFacets(filters: $filters, facets: $queryFacets)
      {
        data {
          count
          value
        }
        field
        model
      }
    }
  }
  ${DashboardCard.fragments.appraisal}
`;

const useStyles = makeStyles(theme => ({
  roomForFABs: {
    paddingBottom: `${75 + 48}px`,
  },
}));

const APPRAISALS_SUBSCRIPTION_FEED = gql`
  subscription appraisalFeed {
    appraisalFeed {
      type
      appraisal {
        id
        ...DashboardCardAppraisal
      }
    }
  }
  ${DashboardCard.fragments.appraisal}
`;

const GET_APPRAISAL_USERS_QUERY = gql`
  query AppraisalsUserQuery($dealer_ids: [Int]) {
    users(dealer_ids: $dealer_ids, status: active) {
      display_name
      username
    }
  }
`;

const WholesaleDashboard = () => {
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const desktop = useMediaQuery(data => data.breakpoints.up('sm'));
  const [showFilterDrawer, setShowFilterDrawer] = useState(false);
  const [filterFacets, setFilterFacets] = usePersistedState('usedFilters', []);
  const [createdAtFromFilter, setCreatedAtFromFilter] = useState(null);
  const [createdAtToFilter, setCreatedAtToFilter] = useState(null);
  const [searchFilters, setSearchFilters] = useState([]);
  const [searchKeywords, setSearchKeywords] = useState('');
  const { currentUser } = useUserContext();
  const { dealerId } = useDealerContext();
  const timezone = currentUser?.goUserProfile?.settings?.timezone || defaultTZ;
  const [recently, setRecently] = useState();
  const { data: displayNames } = useQuery(GET_APPRAISAL_USERS_QUERY, {
    variables: { dealer_ids: [dealerId] },
  });
  // Note that this will only setRecently once.  If the filterFacets are changed,
  // recently will not be updated.  This is in order to eliminate multiple queries
  // triggered when variables change in the appraisals query (change facetFilters,
  // component re-renders, query called, during that re-render "recently" is updated,
  // causing the component to be re-rendered again and the query called again.)
  useEffect(
    () =>
      setRecently(
        moment().tz(timezone).utc().subtract(10, 'days').toISOString(),
      ),
    [setRecently, timezone],
  );

  /*
    year: exact match,
    make: starts with keyword,
    model: starts with keyword,
    vin: contains keyword
  */
  const setSearchFiltersFromKeywords = () =>
    setSearchFilters(
      searchKeywords.split(' ').map(keyword => ({
        or: [
          {
            model: 'Appraisal',
            field: 'make',
            op: 'ilike',
            value: `${keyword}%`,
          },
          {
            model: 'Appraisal',
            field: 'model',
            op: 'ilike',
            value: `${keyword}%`,
          },
          {
            model: 'Appraisal',
            field: 'vin',
            op: 'ilike',
            value: `%${keyword}%`,
          },
          {
            model: 'Appraisal',
            field: 'year',
            op: '==',
            value: parseInt(keyword) || 0,
          },
        ],
      })),
    );

  useEffect(() => {
    if (searchKeywords) setSearchFiltersFromKeywords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const myAppraisalsFilter = {
    model: 'Appraisal',
    field: 'dealerId',
    op: '==',
    value: dealerId,
  };
  // Only show appraisals that have not been delivered, or were delivered "recently"
  const notOrRecentlyDelivered = [
    {
      or: [
        {
          model: 'Appraisal',
          field: 'deliveredAt',
          op: '>=',
          value: recently,
        },
        {
          model: 'Appraisal',
          field: 'deliveredAt',
          op: 'is_null',
        },
      ],
    },
  ];

  const sort = [{ model: 'Appraisal', field: 'createdAt', direction: 'desc' }];

  let dateFilters = [];
  let otherFacets = [];

  if (createdAtFromFilter) {
    dateFilters.push({
      model: 'Appraisal',
      field: 'createdAt',
      op: '>=',
      value: createdAtFromFilter.startOf('day').tz(timezone).toISOString(),
    });
    otherFacets.push({
      label: `> ${createdAtFromFilter.format('YYYY-MM-DD')}`,
      onDelete: () => setCreatedAtFromFilter(null),
    });
  }

  if (createdAtToFilter) {
    dateFilters.push({
      model: 'Appraisal',
      field: 'createdAt',
      op: '<=',
      value: createdAtToFilter.startOf('day').tz(timezone).toISOString(),
    });
    otherFacets.push({
      label: `< ${createdAtToFilter.format('YYYY-MM-DD')}`,
      onDelete: () => setCreatedAtToFilter(null),
    });
  }

  const filters = [
    myAppraisalsFilter,
    ...notOrRecentlyDelivered,
    ...filtersFromFacets(filterFacets),
    ...dateFilters,
    ...searchFilters,
  ];

  const {
    data,
    error,
    fetchMore,
    loading,
    networkStatus,
    subscribeToMore,
    refetch,
  } = useQuery(APPRAISALS_QUERY, {
    variables: {
      page: 1,
      sort,
      queryFacets,
      filters,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    skip: !recently, // wait until we set recently value to query
  });

  const appraisals = data?.appraisals.appraisals.results || [];
  const appraisalsFacets = data?.appraisals.appraisalsFacets || [];
  const { nextPage, total } = data?.appraisals.appraisals.pagination || {};

  const createdByMapping = displayNames?.users.reduce((obj, val) => {
    obj[val.username] = val.display_name;
    return obj;
  }, {});

  // check that an incoming appraisal matches the current filters
  const appraisalMatchesFilters = appraisal =>
    every(
      filterFacets.map(
        f =>
          (appraisal?.[modelToField?.[f.model]]?.[f.field] ||
            appraisal?.[f.field]) === f.value,
      ),
    ) &&
    (!createdAtFromFilter ||
      moment(appraisal.createdAt).isAfter(createdAtFromFilter)) &&
    (!createdAtToFilter ||
      moment(appraisal.createdAt).isBefore(createdAtToFilter));

  useEffect(() => {
    return subscribeToMore({
      document: APPRAISALS_SUBSCRIPTION_FEED,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        const { type, appraisal } = subscriptionData.data.appraisalFeed;
        if (type === 'created' && appraisalMatchesFilters(appraisal)) {
          return produce(prev, draft => {
            draft.appraisals.appraisals.results.unshift(appraisal);
          });
        }

        if (type === 'updated') {
          // if the appraisal is "deleted" it's considered an "update" since we are
          // only soft deleting, so on update we filter out the appraisal
          if (
            appraisal.isDeleted ||
            (!appraisalMatchesFilters(appraisal) &&
              prev.appraisals.appraisals.results.some(
                a => a.id === appraisal.id,
              ))
          ) {
            return produce(prev, draft => {
              draft.appraisals.appraisals.results =
                draft.appraisals.appraisals.results.filter(
                  a => a.id !== appraisal.id,
                );
            });
          } else if (appraisalMatchesFilters(appraisal)) {
            // if the appraisal was already in the list, just update it
            // If not, then add it to the list
            return prev.appraisals.appraisals.results.some(
              a => a.id === appraisal.id,
            )
              ? produce(prev, draft => {
                  draft.appraisals.appraisals.results.map(a =>
                    a.id === appraisal.id ? appraisal : a,
                  );
                })
              : produce(prev, draft => {
                  draft.appraisals.appraisals.results.unshift(appraisal);
                });
          }
        }
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createdAtFromFilter, createdAtToFilter, filterFacets, subscribeToMore]);

  const fetchMoreHandler = () => {
    if (nextPage)
      fetchMore({
        variables: { page: nextPage },
        updateQuery: (prev, { fetchMoreResult: more }) => {
          if (!more.appraisals.appraisals.results) return prev;

          return Object.assign({}, prev, {
            appraisals: {
              __typename: prev.appraisals.__typename,
              appraisals: {
                __typename: prev.appraisals.appraisals.__typename,
                results: [
                  ...prev.appraisals.appraisals.results,
                  ...more.appraisals.appraisals.results,
                ],
                pagination: more.appraisals.appraisals.pagination,
              },
              appraisalsFacets: more.appraisals.appraisalsFacets,
            },
          });
        },
      });
  };

  const handleDeleteFacet = (_model, _field, _value) => {
    setDeletedAppraisals([]);
    return setFilterFacets(prev =>
      prev.filter(
        ({ model, field, value }) =>
          model !== _model || field !== _field || value !== _value,
      ),
    );
  };

  const handleSearch = e =>
    e.key === 'Enter' ? setSearchFiltersFromKeywords() : null;

  const [selected, setSelected] = useState([]);
  const [deletedAppraisals, setDeletedAppraisals] = useState([]);

  useEffect(() => {
    setSelected([]);
    setDeletedAppraisals([]);
  }, [filterFacets, searchFilters, createdAtFromFilter, createdAtToFilter]);

  const handleClicked = id => {
    setSelectByFilter(false);
    setSelected(
      selected.includes(id)
        ? selected.filter(x => x !== id)
        : [...selected, id],
    );
  };

  const [selectByFilter, setSelectByFilter] = useState(false);

  // Ugly error message, but not likely to occur here anyway.
  if (error) return <>Error loading appraisals: {JSON.stringify(error)}</>;

  if (loading && networkStatus !== NetworkStatus.fetchMore)
    return <LoadingBackdrop open={loading} />;

  const VehicleBulkActions = () => (
    <UsedVehicleBulkActions
      filters={filters}
      selectByFilter={selectByFilter}
      selected={selected}
      amount={
        selectByFilter ? total - deletedAppraisals.length : selected.length
      }
      afterDelete={data => {
        setSelected([]);
        setDeletedAppraisals(prev => [
          ...prev,
          ...data.appraisals.deleteAppraisalsByFilters.map(x => x.id),
        ]);
        if (selectByFilter) {
          setFilterFacets([]);
          setSelectByFilter(false);
          setSearchKeywords('');
          setCreatedAtFromFilter(null);
          setCreatedAtToFilter(null);
          refetch({
            filters: [myAppraisalsFilter, ...notOrRecentlyDelivered],
            page: 1,
            queryFacets,
            sort,
          });
        }
      }}
    />
  );

  return (
    <Box
      className={classes.roomForFABs}
      marginLeft={1}
      marginTop={1}
      marginRight={1}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Title title="Used Vehicles" paddingBottom={1} />
      </Box>
      <Box display="flex" flexDirection="row">
        {desktop && (
          <>
            <Box>
              <VehicleBulkActions />
            </Box>
            <Box marginLeft="12px">
              <Fab
                size="medium"
                color="primary"
                onClick={() => setShowFilterDrawer(true)}
              >
                <FilterListIcon />
              </Fab>
            </Box>
            <Box marginLeft="12px">
              <Fab
                style={theme.actions.confirm}
                onClick={() => history.push('/used-vehicles/create-vehicle')}
                size="medium"
              >
                <AddIcon />
              </Fab>
            </Box>
          </>
        )}

        {!isEmpty(filterFacets) && (
          <Box
            paddingTop="0.5rem"
            paddingBottom="0.5rem"
            marginLeft={desktop && '12px'}
          >
            <FacetChips
              facets={filterFacets}
              onDeleteFacet={handleDeleteFacet}
              others={otherFacets}
              createdByMapping={createdByMapping}
            />
          </Box>
        )}
        <Box display="flex" flexGrow={1} />
        <Box display="flex" flexDirection="column">
          <Box>
            Showing {Math.max(appraisals.length - deletedAppraisals.length, 0)}{' '}
            of {Math.max(total - deletedAppraisals.length, 0)}
          </Box>
          <Box>
            <TextField
              placeholder="Search ..."
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      disabled={!searchKeywords}
                      onClick={() => {
                        setSearchKeywords('');
                        setSearchFilters([]);
                      }}
                      size="large"
                    >
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              value={searchKeywords}
              onChange={e => setSearchKeywords(e.target.value)}
              style={{ paddingRight: '5px', textAlign: 'right' }}
              onKeyPress={handleSearch}
            />
          </Box>
        </Box>
      </Box>

      {isEmpty(appraisals) && <EmptyMessage />}
      {selected.length > 0 && (
        <UsedVehicleToolbar
          numSelected={
            selectByFilter ? total - deletedAppraisals.length : selected.length
          }
          onSelectAllFiltered={() => setSelectByFilter(true)}
          total={total - deletedAppraisals.length}
        />
      )}
      {desktop ? (
        <Table>
          <TableBody>
            {appraisals.map(
              appraisal =>
                !deletedAppraisals.includes(appraisal.id) && (
                  <TableRow
                    key={appraisal.id}
                    selected={selectByFilter || selected.includes(appraisal.id)}
                  >
                    <TableCell>
                      <Checkbox
                        checked={
                          selectByFilter || selected.includes(appraisal.id)
                        }
                        onClick={() => handleClicked(appraisal.id)}
                      />
                    </TableCell>
                    <TableCell width="100%">
                      <DashboardCard appraisal={appraisal} />
                    </TableCell>
                  </TableRow>
                ),
            )}
          </TableBody>
        </Table>
      ) : (
        <div>
          {appraisals.map(
            appraisal =>
              !deletedAppraisals.includes(appraisal.id) && (
                <DashboardCard appraisal={appraisal} key={appraisal.id} />
              ),
          )}
        </div>
      )}

      <Waypoint onEnter={fetchMoreHandler} />
      {networkStatus === NetworkStatus.fetchMore && (
        <Box>Loading more appraisals...</Box>
      )}
      <Drawer
        anchor="left"
        open={showFilterDrawer}
        onClose={() => setShowFilterDrawer(false)}
      >
        <Box display="flex">
          <IconButton
            style={{ marginLeft: 'auto', height: '50px', zIndex: '1000' }}
            onClick={() => setShowFilterDrawer(false)}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <FilterDropDown
          createdByMapping={createdByMapping}
          createdAtFromFilter={createdAtFromFilter}
          createdAtToFilter={createdAtToFilter}
          facetResults={appraisalsFacets}
          selectedFacets={filterFacets}
          setCreatedAtFromFilter={setCreatedAtFromFilter}
          setCreatedAtToFilter={setCreatedAtToFilter}
          setSelectedFacets={setFilterFacets}
        />
      </Drawer>

      {!desktop && (
        <>
          <Box position="fixed" bottom="75px" right="10px">
            <Fab
              style={theme.actions.confirm}
              onClick={() => history.push('/used-vehicles/create-vehicle')}
              size="medium"
            >
              <AddIcon />
            </Fab>
          </Box>
          <Box position="fixed" bottom="10px" right="10px">
            <Fab
              size="medium"
              color="primary"
              onClick={() => setShowFilterDrawer(true)}
            >
              <FilterListIcon />
            </Fab>
          </Box>
        </>
      )}
    </Box>
  );
};

export default WholesaleDashboard;
