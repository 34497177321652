import React from 'react';

/* external */
import { isEmpty } from 'lodash';

/* Material UI */
import { makeStyles } from '@mui/styles';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Drawer,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';

import {
  Close as CloseIcon,
  Done as DoneIcon,
  ExpandMore as ExpandMoreIcon,
  HighlightOff as HighlightOffIcon,
} from '@mui/icons-material';

const useStyles = makeStyles(theme => ({
  panel: {
    width: '100%',
    paddingLeft: '25px',
    paddingRight: '25px',
  },
  title: {
    fontSize: '20px',
    paddingBottom: '10px',
    fontWeight: 'bold',
  },
  section: {
    paddingBottom: '10px',
    outlineColor: 'black',
  },
  subPanel: {
    width: '100%',
  },
}));

const FilterDrawer = ({
  facetOptions,
  facetResults,
  selectedFacets,
  setSelectedFacets,
  onClose,
  loading = false, // default to false just in case we forget to pass it in
  ...rest
}) => {
  const classes = useStyles();

  const deleteAllFacets = () => setSelectedFacets([]);
  const getFacetResults = (model, field, options = {}) =>
    facetResults
      .find(x => x.field === field && (!model || x.model === model))
      ?.data.sort(options.sort || (_ => 0))
      .map(({ value, ...rest }) => ({
        value,
        ...rest,
      })) || [];

  const isSelected = (_model, _field, _value) =>
    selectedFacets.findIndex(
      ({ model, field, value }) =>
        model === _model && field === _field && value === _value,
    ) !== -1;

  const deleteFacet = (_model, _field, _value) =>
    setSelectedFacets(prev =>
      prev.filter(
        ({ model, field, value }) =>
          model !== _model || field !== _field || value !== _value,
      ),
    );

  const setFacet = (model, field, value, options) =>
    setSelectedFacets(prev => [...prev, { model, field, value, options }]);

  const toggleFacet = (model, field, value, options) => {
    if (isSelected(model, field, value)) deleteFacet(model, field, value);
    else setFacet(model, field, value, options);
  };

  const getCheckboxCount = (model, field, options) =>
    getFacetResults(model, field, options).find(
      x => options.countValue === x.value,
    )?.count;

  const regularFacetOptions = facetOptions.filter(x => !x?.options?.checkbox);
  const otherFacetOptions = facetOptions.filter(x => x?.options?.checkbox);
  const checkboxFacetOptions = otherFacetOptions.filter(
    x => x?.options?.checkbox,
  ); // Putting this in cause in the future there will probably be other "other" facet options (like date range selects and stuff)

  const showReset = !isEmpty(selectedFacets);
  return (
    <Drawer onClose={onClose} {...rest} style={{ zIndex: '1300' }}>
      <Box display="flex">
        <IconButton
          style={{ marginLeft: 'auto', height: '50px', zIndex: '1000' }}
          onClick={onClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <List className={classes.panel}>
        <Box className={classes.title}>Filters</Box>
        {showReset && (
          <Box style={{ paddingBottom: '10px' }}>
            <Button style={{ color: 'red' }} onClick={deleteAllFacets}>
              <HighlightOffIcon style={{ paddingRight: '5px' }} /> RESET ALL
            </Button>
          </Box>
        )}
        {regularFacetOptions.map(({ field, model, title, options = {} }) => (
          <Box pb="10px" key={`${field}${model}${title}`}>
            <Accordion key={title}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                {title}
              </AccordionSummary>
              <AccordionDetails>
                <List
                  component="div"
                  disablePadding
                  className={classes.subPanel}
                >
                  {getFacetResults(model, field, options).map(
                    ({ count, value }) => (
                      <ListItem
                        disabled={loading}
                        dense
                        button
                        onClick={() =>
                          toggleFacet(model, field, value, options)
                        }
                        key={`${model}${field}${value}`}
                        selected={isSelected(model, field, value)}
                      >
                        {isSelected(model, field, value) ? (
                          <DoneIcon
                            style={{
                              fontSize: 'small',
                              paddingRight: '10px',
                              width: '25px',
                            }}
                          />
                        ) : (
                          <div style={{ paddingRight: '10px', width: '25px' }}>
                            {' '}
                          </div>
                        )}
                        <ListItemText
                          primary={`${
                            (options.format || (x => x))(value) || 'Unknown'
                          } (${count})`}
                        />
                      </ListItem>
                    ),
                  )}
                </List>
              </AccordionDetails>
            </Accordion>
          </Box>
        ))}
        {otherFacetOptions && (
          <Box pb="10px">
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                Other
              </AccordionSummary>
              <AccordionDetails>
                <List className={classes.subPanel}>
                  {checkboxFacetOptions.map(
                    ({ model, field, title, options = {} }) => (
                      <ListItem
                        dense
                        disabled={false}
                        key={`${model}${field}${title}`}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="secondary"
                              checked={isSelected(
                                model,
                                field,
                                options?.countValue === 'true',
                              )}
                              onClick={() =>
                                toggleFacet(
                                  model,
                                  field,
                                  options?.countValue === 'true',
                                  options,
                                )
                              }
                            />
                          }
                          label={`${title} (${getCheckboxCount(
                            model,
                            field,
                            options,
                          )})`}
                        />
                      </ListItem>
                    ),
                  )}
                </List>
              </AccordionDetails>
            </Accordion>
          </Box>
        )}
      </List>
    </Drawer>
  );
};

export default FilterDrawer;
