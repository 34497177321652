import { isEmpty, startCase, toLower } from 'lodash';
import moment from 'moment-timezone';
import React, { useEffect } from 'react';

import { cloudinaryCore, defaultTZ } from 'utils';

import noIMG from './static/img/no-image.jpg';
import { Role } from 'constants.js';

export const formatPlaces = (value, places = 2, zeroIfNull = false) =>
  value ? value.toFixed(places) : zeroIfNull ? (0).toFixed(places) : null;

export const titleCase = x => startCase(toLower(x));

export const parseErrors = ({ graphQLErrors, networkError, message }) => {
  // Go through the errors and return an object with {
  // specific_field: [error_messages]
  // error: [generic_errors]
  // }

  let returnErrors = {};
  if (!isEmpty(graphQLErrors))
    graphQLErrors.forEach(({ message, location, path, extensions }) => {
      if (extensions.json)
        returnErrors = Object.assign({}, returnErrors, extensions.json);
      else if (message)
        returnErrors.error = [
          message + ' ' + extensions?.response?.body?.errors?.join(',') || '',
        ];
    });
  else if (!isEmpty(networkError))
    returnErrors.error = networkError.result?.errors.map(x => x.message);
  else if (!isEmpty(message)) returnErrors.error = [message];
  else returnErrors.error = ['An unknown error occured.'];
  return returnErrors;
};

export const formatPhoto = (photo, photoWidth = 500) => {
  const displayPhotoURL = photo
    ? cloudinaryCore.url(photo.cloudinary_public_id, {
        width: photoWidth,
        crop: 'fit',
      })
    : noIMG;

  return displayPhotoURL;
};

//--------------- Detailed Pricing Calculations -------------------------------
const expired = ({ expiry }, tz = defaultTZ) => {
  const userTimeZone = tz || defaultTZ;
  return (
    expiry && moment().tz(userTimeZone).isAfter(moment.tz(expiry, userTimeZone))
  );
};

export const cashAddonTotal = (detailedPricing = [], tz = defaultTZ) =>
  detailedPricing
    ?.filter(
      x => x.cash && !expired(x, tz) && x.price_modifier_type_name === 'addon',
    )
    ?.reduce((total, { value }) => total + (parseFloat(value) || 0), 0);

export const cashDiscountTotal = (detailedPricing = [], tz = defaultTZ) =>
  detailedPricing
    .filter(
      x =>
        x.cash && !expired(x, tz) && x.price_modifier_type_name === 'discount',
    )
    .reduce((total, { value }) => total + (parseFloat(value) || 0), 0);

export const cashRebateTotal = (detailedPricing = [], tz = defaultTZ) =>
  detailedPricing
    .filter(
      x => x.cash && !expired(x, tz) && x.price_modifier_type_name === 'rebate',
    )
    .reduce((total, { value }) => total + (parseFloat(value) || 0), 0);

export const financeAddonTotal = (detailedPricing = [], tz = defaultTZ) =>
  detailedPricing
    .filter(
      x =>
        x.finance && !expired(x, tz) && x.price_modifier_type_name === 'addon',
    )
    .reduce((total, { value }) => total + (parseFloat(value) || 0), 0);

export const financeDiscountTotal = (detailedPricing = [], tz = defaultTZ) =>
  detailedPricing
    .filter(
      x =>
        x.finance &&
        !expired(x, tz) &&
        x.price_modifier_type_name === 'discount',
    )
    .reduce((total, { value }) => total + (parseFloat(value) || 0), 0);

export const financeRebateTotal = (detailedPricing = [], tz = defaultTZ) =>
  detailedPricing
    .filter(
      x =>
        x.finance && !expired(x, tz) && x.price_modifier_type_name === 'rebate',
    )
    .reduce((total, { value }) => total + (parseFloat(value) || 0), 0);

export const leaseAddonTotal = (detailedPricing = [], tz = defaultTZ) =>
  detailedPricing
    .filter(
      x => x.lease && !expired(x, tz) && x.price_modifier_type_name === 'addon',
    )
    .reduce((total, { value }) => total + (parseFloat(value) || 0), 0);

export const leaseDiscountTotal = (detailedPricing = [], tz = defaultTZ) =>
  detailedPricing
    .filter(
      x =>
        x.lease && !expired(x, tz) && x.price_modifier_type_name === 'discount',
    )
    .reduce((total, { value }) => total + (parseFloat(value) || 0), 0);

export const leaseRebateTotal = (detailedPricing = [], tz = defaultTZ) =>
  detailedPricing
    .filter(
      x =>
        x.lease && !expired(x, tz) && x.price_modifier_type_name === 'rebate',
    )
    .reduce((total, { value }) => total + (parseFloat(value) || 0), 0);

export const cashAdjustments = (detailedPricing = [], tz = defaultTZ) => {
  return (
    cashAddonTotal(detailedPricing, tz) -
    (cashDiscountTotal(detailedPricing, tz) +
      cashRebateTotal(detailedPricing, tz))
  );
};

export const financeAdjustments = (detailedPricing = [], tz = defaultTZ) => {
  return (
    financeAddonTotal(detailedPricing, tz) -
    (financeDiscountTotal(detailedPricing, tz) +
      financeRebateTotal(detailedPricing, tz))
  );
};

export const leaseAdjustments = (detailedPricing = [], tz = defaultTZ) => {
  return (
    leaseAddonTotal(detailedPricing, tz) -
    (leaseDiscountTotal(detailedPricing, tz) +
      leaseRebateTotal(detailedPricing, tz))
  );
};
//-----------------------------------------------------------------------------

// pretty cool little function yoinked from
// https://dev.to/selbekk/persisting-your-react-state-in-9-lines-of-code-9go
export function usePersistedState(key, defaultValue) {
  const [state, setState] = React.useState(
    JSON.parse(sessionStorage.getItem(key)) || defaultValue,
  );
  useEffect(() => {
    sessionStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);
  return [state, setState];
}

export const stripHTML = string => string?.replace(/(<([^>]+)>)/gi, '') || '';

export const formatEngineDescription = (
  engineConfigName,
  engineCylinders,
  engineLitres,
) => {
  const configNameStr = engineConfigName ? `${engineConfigName} ` : '';
  const cylinderStr = engineCylinders ? `${engineCylinders} Cyl. ` : '';
  const litresStr = engineLitres ? `${engineLitres}L` : '';

  return configNameStr + cylinderStr + litresStr;
};

export const hideCost = role => {
  return Role.ALL_SALES_REPS.includes(role);
};

export const generateOptionCodes = (currentOptions, cdk_options) => {
  let optionCodes = [];
  currentOptions.forEach(({ option_code }) => {
    if (option_code) optionCodes.push(option_code);
  });
  if (optionCodes.length === 0 && cdk_options !== null) {
    let tempOptions = cdk_options.split(' ');
    tempOptions.forEach(item => {
      optionCodes.push(item.trim());
    });
  }
  return optionCodes;
};

export const filtersToFacets = (filters = []) => {
  const facets = filters.map(({ model, field, op, value }) => ({
    model,
    field,
    value,
    options: {
      label: `${titleCase(field)} ${op} ${value}`,
    },
  }));

  return facets;
};
