import React, { createContext, useContext } from 'react';

const PermissionsContext = createContext(null);

export const PermissionsProvider = ({
  children,
  permissions: { __typename, ...permissions },
}) => (
  <PermissionsContext.Provider value={permissions}>
    {children}
  </PermissionsContext.Provider>
);

export const usePermissionsContext = () => useContext(PermissionsContext);

export default PermissionsProvider;
