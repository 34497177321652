import React from 'react';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';

const useStyles = makeStyles(theme => ({
  data: {
    fontSize: 14,
  },
  label: {
    fontSize: '14px',
    fontWeight: 'bold',
  },
  underline: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.5)',
  },
}));

const SegmentGroupedTraitDisplay = ({ groupedTraits, groupedTraitsName }) => {
  const classes = useStyles();
  return (
    <>
      <Grid item xs={12} md={3} className={classes.label} alignItems="center">
        {groupedTraitsName}
      </Grid>
      <Grid item xs={12} md={3} className={classes.data}>
        {Object.keys(groupedTraits).map(
          (trait, index) =>
            groupedTraits[trait] && (
              <Typography className={classes.data} key={`${trait}:${index}`}>
                {trait}: {String(groupedTraits[trait])}
              </Typography>
            ),
        )}
      </Grid>
      <Grid item md={6} />
      <Grid item xs={12} md={6} className={classes.underline} />
      <Grid item md={6} />
    </>
  );
};

export default SegmentGroupedTraitDisplay;
