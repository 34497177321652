import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';

import { Box, Button, Dialog, Grid, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';

import useYearMakeModel from 'components/hooks/useYearMakeModel';
import { useCodeTablesContext } from 'components/MaterialUI/CodeTablesContext';
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import GoodBadRadioGroup from 'components/MaterialUI/GoodBadRadioGroup';
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';
import RadioControl from 'components/MaterialUI/RadioControl2';
import SelectControl from 'components/MaterialUI/SelectControl2';
import TextFieldControl from 'components/MaterialUI/TextFieldControl';
import YesNoRadioGroup from 'components/MaterialUI/YesNoRadioGroup';

import TrimSelector from 'modules/inventory/components/vehicle/vehicle_info/TrimSelector';

/* internal */
import { errorHandler, pitchStyles } from 'modules/pitches/utils';

import { countryFromVin } from '../utils';
// TODO: move to central location?
import DecodeButton from './DecodeButton';

const useStyles = makeStyles(theme => ({
  paragraph: pitchStyles.responsiveParagraph(theme),
  sectionTitle: pitchStyles.sectionTitle(theme),
  sectionSubTitle: pitchStyles.sectionSubTitle(theme),
  button: pitchStyles.responsiveButton(theme),
  changeStyleButton: {
    backgroundColor: '#5bc236', // todo: add to theme
    '&:disabled': {
      backgroundColor: theme.actions.disabled.backgroundColor,
    },
    color: 'white',
  },
}));

const FRAGMENT = gql`
  fragment TradeDetailsFormBoxAppraisal on Appraisal {
    id
    appraisalType
    bodyType
    countryOfOrigin
    dealerId
    driveType
    engineCompressor
    engineConfig
    engineCylinders
    engineLitres
    enhancementDetails
    exteriorColour
    firstName
    fuelType
    greaseBodyTypeId
    greaseDriveTypeId
    greaseEngineCompressorId
    greaseEngineConfigId
    greaseExteriorColourId
    greaseFuelTypeId
    greaseInteriorColourId
    greaseMakeId
    greaseModelId
    greaseTransmissionId
    hasEnhancements
    hasLien
    interiorColour
    isGlassGood
    isRegisteredOutOfProvince
    isTiresGood
    lastName
    lienAmount
    make
    model
    notes
    odometer
    opportunityId
    registrationLocation
    styleId
    transmission
    trim
    trimVariation
    vin
    year
  }
`;

const CREATE_APPRAISAL = gql`
  mutation createAppraisal($data: AppraisalInput!) {
    appraisals {
      createAppraisal(appraisal: $data) {
        id
        ...TradeDetailsFormBoxAppraisal
      }
    }
  }
  ${FRAGMENT}
`;

const UPDATE_APPRAISAL = gql`
  mutation updateAppraisal($id: Int!, $data: UpdateAppraisalInput!) {
    appraisals {
      updateAppraisal(id: $id, data: $data) {
        id
        ...TradeDetailsFormBoxAppraisal
      }
    }
  }
  ${FRAGMENT}
`;

const DECODE = gql`
  query decodeVehicle($vin: String) {
    inventory {
      decodeVehicle(vin: $vin) {
        code_tables_stale
        drilldowns_stale
        styles {
          body_type_id
          drive_type_id
          engines {
            engine_compressor_id
            engine_config_id
            engine_cylinders
            engine_litres
            fuel_type_id
          }
          exterior_colours {
            desc
            ids
          }
          interior_colours {
            desc
            ids
          }
          make_id
          model_id
          style_id
          transmissions {
            id
            name
          }
          trim
          trim_variation
          year
        }
        ...TrimSelectorVINDecodeResult
      }
    }
  }
  ${TrimSelector.fragments.VINDecodeResult}
`;

const GridRow = ({ children, ...rest }) => (
  <Grid container item xs={12} alignItems="flex-end" spacing={2} {...rest}>
    {children}
  </Grid>
);

const TradeDetailsFormBox = ({
  autoDecode,
  appraisal,
  onCancel,
  onCreate,
  onSkip,
  onUpdate,
  ...rest
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { dealers } = useDealerContext();
  const {
    codeTables: {
      bodyTypes,
      driveTypes,
      engineCompressors,
      engineConfigs,
      exteriorColours,
      fuelTypes,
      interiorColours,
      transmissions,
    },
    loading: codeTablesLoading,
  } = useCodeTablesContext();
  const {
    makes,
    models,
    years,
    setYear,
    setMakeId,
    loading: ymmLoading,
  } = useYearMakeModel({
    selectedYear: null,
    selectedMake: null,
  });
  const { changeStyleButton, sectionTitle } = useStyles();

  const [showTrimSelect, setShowTrimSelect] = useState(false);

  const [createAppraisal, createAppraisalMutation] = useMutation(
    CREATE_APPRAISAL,
    {
      onCompleted: data => onCreate(data.appraisals.createAppraisal),
      onError: err => errorHandler(enqueueSnackbar, setError)(err),
    },
  );
  const [updateAppraisal, updateAppraisalMutation] = useMutation(
    UPDATE_APPRAISAL,
    {
      onCompleted: data => onUpdate(data.appraisals.updateAppraisal),
      onError: err => errorHandler(enqueueSnackbar, setError)(err),
    },
  );

  // Deconstruct appraisal and set default values for form use
  const {
    id,
    appraisalType, // shouldn't be null
    bodyType = '',
    dealerId = null,
    driveType = '',
    customerId,
    engineCompressor = '',
    engineConfig = '',
    engineCylinders = null,
    engineLitres = null,
    enhancementDetails = '',
    exteriorColour = '',
    firstName = '',
    fuelType = '',
    greaseBodyTypeId = null,
    greaseDriveTypeId = null,
    greaseEngineCompressorId = null,
    greaseEngineConfigId = null,
    greaseExteriorColourId = null,
    greaseFuelTypeId = null,
    greaseInteriorColourId = null,
    greaseMakeId = null,
    greaseModelId = null,
    greaseTransmissionId = null,
    hasEnhancements = false,
    hasLien = false,
    interiorColour = '',
    isGlassGood = true,
    isRegisteredOutOfProvince = false,
    isTiresGood = true,
    lastName = '',
    lienAmount = null,
    make = '',
    model = '',
    notes = '',
    odometer = null,
    opportunityId, // I don't think we want this to be null
    registrationLocation = '',
    styleId = null,
    transmission = '',
    trim = '',
    trimVariation = '',
    vin = '',
    year = null,
  } = appraisal;

  const {
    clearErrors,
    control,
    handleSubmit,
    register,
    formState,
    setError,
    setValue,
  } = useForm({
    defaultValues: {
      appraisalType,
      bodyType,
      customerId,
      dealerId,
      driveType,
      engineCompressor,
      engineConfig,
      engineCylinders,
      engineLitres,
      enhancementDetails,
      exteriorColour,
      firstName,
      fuelType,
      greaseBodyTypeId,
      greaseDriveTypeId,
      greaseEngineCompressorId,
      greaseEngineConfigId,
      greaseExteriorColourId,
      greaseFuelTypeId,
      greaseInteriorColourId,
      greaseMakeId,
      greaseModelId,
      greaseTransmissionId,
      hasEnhancements,
      hasLien,
      id,
      interiorColour,
      isGlassGood,
      isRegisteredOutOfProvince,
      isTiresGood,
      lastName,
      lienAmount,
      make,
      model,
      notes,
      odometer,
      opportunityId,
      registrationLocation,
      styleId,
      transmission,
      trim,
      trimVariation,
      vin,
      year,
    },
  });

  const { errors, isDirty } = formState;

  // TODO: find some vehicles that decode with multiple engine options per style and
  // update the trim/style select to handle those too! (bonus points for getting
  // multiple transmission options too)

  // TODO: should be able to see the decoded style info. Maybe in an info icon popover?

  // TODO: fix colour selection to include specific colours
  const setVehicleDataFromStyle = style => {
    setValue('greaseBodyTypeId', style.body_type_id);
    setValue('greaseDriveTypeId', style.drive_type_id);
    setValue('greaseMakeId', style.make_id);
    setValue('greaseModelId', style.model_id);
    setValue('styleId', style.style_id);
    setValue('trim', style.trim);
    setValue('trimVariation', style.trim_variation);
    setValue('year', style.year);
    if (style.engines?.length === 1) {
      setValue('engineCylinders', style.engines[0].engine_cylinders);
      setValue('engineLitres', style.engines[0].engine_litres);
      setValue(
        'greaseEngineCompressorId',
        style.engines[0].engine_compressor_id,
      );
      setValue('greaseEngineConfigId', style.engines[0].engine_config_id);
      setValue('greaseFuelTypeId', style.engines[0].fuel_type_id);
      if (style.transmissions?.length === 1) {
        setValue('greaseTransmissionId', style.transmissions[0].id);
      }
    }
  };

  const [decodeVehicle, decodeVehicleQuery] = useLazyQuery(DECODE, {
    onCompleted: ({ inventory: { decodeVehicle } }) => {
      if (decodeVehicle.styles?.length > 0) {
        // Set data based on the first style
        setVehicleDataFromStyle(decodeVehicle.styles[0]);
      }
    },
    fetchPolicy: 'network-only',
  });
  const decodeStyles =
    decodeVehicleQuery.data?.inventory.decodeVehicle.styles || [];

  const formWatch = {
    bodyType: useWatch({ control, name: 'bodyType' }),
    driveType: useWatch({ control, name: 'driveType' }),
    engineConfig: useWatch({ control, name: 'engineConfig' }),
    engineCompressor: useWatch({ control, name: 'engineCompressor' }),
    exteriorColour: useWatch({ control, name: 'exteriorColour' }),
    fuelType: useWatch({ control, name: 'fuelType' }),
    greaseBodyTypeId: useWatch({ control, name: 'greaseBodyTypeId' }),
    greaseDriveTypeId: useWatch({ control, name: 'greaseDriveTypeId' }),
    greaseEngineCompressorId: useWatch({
      control,
      name: 'greaseEngineCompressorId',
    }),
    greaseEngineConfigId: useWatch({ control, name: 'greaseEngineConfigId' }),
    greaseExteriorColourId: useWatch({
      control,
      name: 'greaseExteriorColourId',
    }),
    greaseFuelTypeId: useWatch({ control, name: 'greaseFuelTypeId' }),
    greaseInteriorColourId: useWatch({
      control,
      name: 'greaseInteriorColourId',
    }),
    greaseMakeId: useWatch({ control, name: 'greaseMakeId' }),
    greaseModelId: useWatch({ control, name: 'greaseModelId' }),
    greaseTransmissionId: useWatch({ control, name: 'greaseTransmissionId' }),
    interiorColour: useWatch({ control, name: 'interiorColour' }),
    make: useWatch({ control, name: 'make' }),
    model: useWatch({ control, name: 'model' }),
    styleId: useWatch({ control, name: 'styleId' }),
    transmission: useWatch({ control, name: 'transmission' }),
    trim: useWatch({ control, name: 'trim' }),
    vin: useWatch({ control, name: 'vin' }),
    year: useWatch({ control, name: 'year' }),
  };

  // For the ymm drop downs
  useEffect(() => {
    setYear(formWatch.year);
  }, [formWatch.year, setYear]);

  // Set the makeId in the ymm hook
  // Set the make (text value)
  // This will clear the make/model if no year is selected
  // It will also clear the model if no make is selected.
  useEffect(() => {
    if (!formWatch.year) {
      setMakeId(null);
      if (formWatch.greaseMakeId) setValue('greaseMakeId', null);
      if (formWatch.greaseModelId) setValue('greaseModelId', null);
      if (formWatch.make !== '') setValue('make', '');
    } else if (makes.length) {
      setMakeId(formWatch.greaseMakeId);
      const makeText =
        makes.find(x => x.id === formWatch.greaseMakeId)?.name ?? '';
      if (formWatch.make !== makeText) setValue('make', makeText);
      if (!formWatch.greaseMakeId && formWatch.greaseModelId)
        setValue('greaseModelId', null);
    }
  }, [
    formWatch.greaseMakeId,
    formWatch.greaseModelId,
    formWatch.make,
    formWatch.year,
    makes,
    setMakeId,
    setValue,
  ]);

  // This is kind of a pain.  We need to set the text value AND the id value, but there's not
  // really a nice way to make a reusable component that does both.  Hmmmm... maybe a custom hook would do it?

  // sets 'model' in form data with the text value associated with greaseModelId
  useEffect(() => {
    const modelText =
      models.find(x => x.id === formWatch.greaseModelId)?.name ?? '';
    if (formWatch.model !== modelText) setValue('model', modelText);
  }, [models, formWatch.greaseModelId, formWatch.model, setValue]);

  // sets bodyType in form data with the text value associated with greaseBodyTypeId
  useEffect(() => {
    const bodyTypeText =
      bodyTypes.find(x => x.id === formWatch.greaseBodyTypeId)?.name ?? '';
    if (bodyTypeText !== formWatch.bodyType) setValue('bodyType', bodyTypeText);
  }, [bodyTypes, formWatch.bodyType, formWatch.greaseBodyTypeId, setValue]);

  // sets driveType in form data with the text value associated with greaseDriveTypeId
  useEffect(() => {
    const driveTypeText =
      driveTypes.find(x => x.id === formWatch.greaseDriveTypeId)?.name ?? '';
    if (driveTypeText !== formWatch.driveType)
      setValue('driveType', driveTypeText);
  }, [driveTypes, formWatch.driveType, formWatch.greaseDriveTypeId, setValue]);

  // sets engineCompressor in form data with the text value associated with greaseEngineCompressorId
  useEffect(() => {
    const compressorText =
      engineCompressors.find(x => x.id === formWatch.greaseEngineCompressorId)
        ?.name ?? '';
    if (compressorText !== formWatch.engineCompressor)
      setValue('engineCompressor', compressorText);
  }, [
    engineCompressors,
    formWatch.engineCompressor,
    formWatch.greaseEngineCompressorId,
    setValue,
  ]);

  // sets engineConfig in form data with the text value associated with greaseEngineConfigId
  useEffect(() => {
    const configText =
      engineConfigs.find(x => x.id === formWatch.greaseEngineConfigId)?.name ??
      '';
    if (configText !== formWatch.engineConfig)
      setValue('engineConfig', configText);
  }, [
    engineConfigs,
    formWatch.engineConfig,
    formWatch.greaseEngineConfigId,
    setValue,
  ]);

  // sets exteriorColour in form data with the text value associated with greaseExteriorColourId
  useEffect(() => {
    const exteriorColourText =
      exteriorColours.find(x => x.id === formWatch.greaseExteriorColourId)
        ?.name ?? '';
    if (exteriorColourText !== formWatch.exteriorColour)
      setValue('exteriorColour', exteriorColourText);
  }, [
    exteriorColours,
    formWatch.greaseExteriorColourId,
    formWatch.exteriorColour,
    setValue,
  ]);

  // sets fuelType in form data with the text value associated with greaseFuelTypeId
  useEffect(() => {
    const fuelTypeText =
      fuelTypes.find(x => x.id === formWatch.greaseFuelTypeId)?.name ?? '';
    if (fuelTypeText !== formWatch.fuelType) setValue('fuelType', fuelTypeText);
  }, [fuelTypes, formWatch.greaseFuelTypeId, formWatch.fuelType, setValue]);

  // sets interiorColour in form data with the text value associated with greaseInteriorColourId
  useEffect(() => {
    const interiorColourText =
      interiorColours.find(x => x.id === formWatch.greaseInteriorColourId)
        ?.name ?? '';
    if (interiorColourText !== formWatch.interiorColour)
      setValue('interiorColour', interiorColourText);
  }, [
    interiorColours,
    formWatch.greaseInteriorColourId,
    formWatch.interiorColour,
    setValue,
  ]);

  // sets transmission in form data with the text value associated with greaseDriveTypeId
  useEffect(() => {
    const transmissionText =
      transmissions.find(x => x.id === formWatch.greaseTransmissionId)?.name ??
      '';
    if (transmissionText !== formWatch.transmission)
      setValue('transmission', transmissionText);
  }, [
    transmissions,
    formWatch.greaseTransmissionId,
    formWatch.transmission,
    setValue,
  ]);

  useEffect(() => {
    if (autoDecode && vin && !styleId) decodeVehicle({ variables: { vin } });
  }, [autoDecode, decodeVehicle, vin, styleId]);

  /*
  Fields that get automatically set based by choosing related id from a drop down list
  OR in the background from style selection/decoding

  bodyType
  driveType
  engineCompressor
  engineConrig
  exteriorColour
  fuelType
  interiorColour
  make
  model
  transmission

*/
  /*********************** */
  const disableDealer = Boolean(id || opportunityId);

  // updateAppraisal doesn't get dealerId nor customerId, so strip 'em off here.
  const onSubmit = ({ id, dealerId, customerId, ...data }) =>
    id
      ? updateAppraisal({ variables: { id, data } })
      : createAppraisal({
          variables: { data: { ...data, dealerId, customerId } },
        });

  const [country, setCountry] = useState(appraisal.countryOfOrigin);

  const handleClickDecode = () => {
    decodeVehicle({ variables: { vin: formWatch.vin } });
    setCountry(countryFromVin(formWatch.vin));
  };

  const handleStyleSelect = style => {
    setVehicleDataFromStyle(style);
    setShowTrimSelect(false);
  };

  return (
    <Box {...rest}>
      <LoadingBackdrop open={codeTablesLoading} />
      <LoadingBackdrop open={createAppraisalMutation.loading}>
        Creating appraisal
      </LoadingBackdrop>
      <LoadingBackdrop open={updateAppraisalMutation.loading}>
        Updating appraisal
      </LoadingBackdrop>
      <Dialog open={showTrimSelect} onClose={() => setShowTrimSelect(false)}>
        <TrimSelector
          decodeStyles={decodeStyles}
          handleStyleSelect={handleStyleSelect}
          selectedStyleId={formWatch.styleId}
        />
      </Dialog>
      <form
        onSubmit={e => {
          clearErrors(); // Need to manually clear errors, since we've manually
          // used setError from server. handleSubmit will not fire otherwise.
          handleSubmit(onSubmit)(e); // Needed to prevent page navigate with data in url params
        }}
      >
        {/* hidden fields */}
        {[
          'appraisalType',
          'bodyType',
          'customerId',
          'dealerId',
          'driveType',
          'engineCompressor',
          'engineConfig',
          'engineCylinders',
          'engineLitres',
          'exteriorColour',
          'fuelType',
          'id',
          'interiorColour',
          'make',
          'model',
          'opportunityId',
          'styleId',
          'transmission',
        ].map(name => (
          <input key={name} type="hidden" {...register(name)} />
        ))}

        <Grid container direction="row" spacing={3}>
          {/* Column 1 */}

          <Grid
            container
            direction="row"
            item
            xs={12}
            md={6}
            alignContent="flex-start"
            spacing={2}
          >
            <Grid item xs={12}>
              <Box className={sectionTitle}>Vehicle Details</Box>
            </Grid>
            <GridRow>
              <Grid item xs={8}>
                <SelectControl
                  control={control}
                  errors={errors}
                  InputProps={{
                    readOnly: disableDealer,
                    style: disableDealer ? { color: 'grey' } : null,
                  }}
                  fullWidth
                  label="Dealer"
                  name="dealerId"
                  noNull
                  options={dealers}
                  optionValueKey="dealer_id"
                  optionNameKey="dealer_name"
                  rules={{ required: 'Dealer required!' }}
                  size="small"
                  variant="filled"
                />
              </Grid>
            </GridRow>
            <GridRow>
              <Grid item xs={8}>
                <TextFieldControl
                  control={control}
                  errors={errors}
                  fullWidth
                  label="VIN"
                  name="vin"
                  muiVariant="filled"
                  size="small"
                />
              </Grid>
              <Grid item xs={4}>
                <DecodeButton
                  isDecoded={!isEmpty(formWatch.styleId)}
                  loading={decodeVehicleQuery.loading}
                  onClick={handleClickDecode}
                />
              </Grid>
            </GridRow>
            {/* Year row */}
            <GridRow>
              <Grid item xs={6}>
                <SelectControl
                  control={control}
                  errors={errors}
                  fullWidth
                  label="Year"
                  name="year"
                  options={years
                    .sort((a, b) => b - a)
                    .map(y => ({ value: y, name: y }))}
                  variant="filled"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  defaultValue="Unknown"
                  label="Country of Origin"
                  variant="filled"
                  style={{ width: '100%' }}
                  value={country}
                  inputProps={{ readOnly: true }}
                ></TextField>
              </Grid>
            </GridRow>
            {/* Make, Model row */}
            <GridRow>
              <Grid item xs={6}>
                <SelectControl
                  control={control}
                  errors={errors}
                  disabled={ymmLoading}
                  fullWidth
                  label="Make"
                  name="greaseMakeId"
                  options={makes}
                  optionValueKey="id"
                  variant="filled"
                />
              </Grid>
              <Grid item xs={6}>
                <SelectControl
                  control={control}
                  errors={errors}
                  disabled={ymmLoading}
                  fullWidth
                  label="Model"
                  name="greaseModelId"
                  options={models}
                  optionValueKey="id"
                  variant="filled"
                />
              </Grid>
            </GridRow>
            {/* Trim select row */}
            <GridRow alignItems="center">
              <Grid item xs={6}>
                <TextFieldControl
                  control={control}
                  errors={errors}
                  inputProps={{ readOnly: true }}
                  InputLabelProps={{ shrink: Boolean(formWatch.trim) }}
                  label="Decoded Trim"
                  name="trim"
                  muiVariant="filled"
                />
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => setShowTrimSelect(true)}
                  disabled={decodeStyles.length < 1}
                  className={changeStyleButton}
                >
                  Change
                </Button>
              </Grid>
            </GridRow>
            {/* transmission & drive type row */}
            <GridRow>
              <Grid item xs={6}>
                <SelectControl
                  control={control}
                  errors={errors}
                  fullWidth
                  label="Transmission"
                  name="greaseTransmissionId"
                  options={transmissions}
                  optionValueKey="id"
                  variant="filled"
                />
              </Grid>
              <Grid item xs={6}>
                <SelectControl
                  control={control}
                  errors={errors}
                  fullWidth
                  label="Drive Type"
                  name="greaseDriveTypeId"
                  options={driveTypes}
                  optionValueKey="id"
                  variant="filled"
                />
              </Grid>
            </GridRow>
            {/* Colours row */}
            <GridRow>
              <Grid item xs={6}>
                <SelectControl
                  control={control}
                  errors={errors}
                  fullWidth
                  label="Ext. Colour"
                  name="greaseExteriorColourId"
                  options={exteriorColours}
                  optionValueKey="id"
                  variant="filled"
                />
              </Grid>
              <Grid item xs={6}>
                <SelectControl
                  control={control}
                  errors={errors}
                  fullWidth
                  label="Int. Colour"
                  name="greaseInteriorColourId"
                  options={interiorColours}
                  optionValueKey="id"
                  variant="filled"
                />
              </Grid>
            </GridRow>
            <GridRow>
              {/* odometer row */}
              <Grid item xs={6}>
                <TextFieldControl
                  control={control}
                  errors={errors}
                  rules={{ required: 'Odometer reading required' }}
                  label="Odometer"
                  muiVariant="filled"
                  variant
                  name="odometer"
                  type="number"
                />
              </Grid>
              <Grid item xs={6}>
                <TextFieldControl
                  control={control}
                  errors={errors}
                  muiVariant="filled"
                  fullWidth
                  label="Trade Payoff"
                  name="lienAmount"
                  variant="currencyDollars"
                />
              </Grid>
            </GridRow>
          </Grid>
          {/* COLUMN 2 */}
          <Grid
            container
            item
            xs={12}
            md={6}
            alignContent="flex-start"
            spacing={2}
          >
            <Grid item xs={12}>
              <Box className={sectionTitle} whiteSpace="nowrap">
                Additional Information
              </Box>
              <GridRow>
                {/* registration info row */}
                <Grid item xs={12} sm={6}>
                  <RadioControl
                    control={control}
                    as={<YesNoRadioGroup />}
                    label="Registered Out of Province?"
                    name="isRegisteredOutOfProvince"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextFieldControl
                    control={control}
                    errors={errors}
                    muiVariant="filled"
                    label="Where?"
                    name="registrationLocation"
                  />
                </Grid>
              </GridRow>
              <GridRow>
                {/* lien row */}
                <Grid item xs={12} sm={6}>
                  <RadioControl
                    as={<YesNoRadioGroup />}
                    control={control}
                    label="Has Lien"
                    name="hasLien"
                  />
                </Grid>
              </GridRow>
              <GridRow>
                {/* glass row */}
                <Grid item xs={12} sm={6}>
                  <RadioControl
                    control={control}
                    as={<GoodBadRadioGroup />}
                    defaultValue={true}
                    label="Glass"
                    name="isGlassGood"
                  />
                </Grid>
              </GridRow>
              <GridRow>
                {/* tires row */}
                <Grid item xs={12} sm={6}>
                  <RadioControl
                    as={<GoodBadRadioGroup />}
                    control={control}
                    label="Tires"
                    name="isTiresGood"
                  />
                </Grid>
              </GridRow>
              <GridRow>
                {/* hasEnhancements row */}
                <Grid item xs={12} sm={6}>
                  <RadioControl
                    as={<YesNoRadioGroup />}
                    control={control}
                    label="Enhancements Done to Vehicle?"
                    name="hasEnhancements"
                  />
                </Grid>
              </GridRow>
              <GridRow>
                {/* enhancementDetails row */}
                <Grid item xs={12}>
                  <TextFieldControl
                    control={control}
                    fullWidth
                    label="If yes, please explain in detail"
                    name="enhancementDetails"
                    muiVariant="filled"
                  />
                </Grid>
              </GridRow>
              <GridRow>
                {/* notes row */}
                <Grid item xs={12}>
                  <TextFieldControl
                    control={control}
                    errors={errors}
                    fullWidth
                    label="Notes/Damage Disclosure"
                    multiline
                    name="notes"
                    minRows={4}
                    muiVariant="filled"
                  />
                </Grid>
              </GridRow>
            </Grid>
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="flex-end" m={2} gap="8px">
          <Button variant="contained" onClick={onCancel} color="default">
            Cancel
          </Button>
          {isDirty && (
            <Button variant="contained" type="submit" color="default">
              Save & Continue
            </Button>
          )}
          {id && !isDirty && (
            <Button variant="contained" onClick={onSkip} color="default">
              Skip
            </Button>
          )}
        </Box>
      </form>
    </Box>
  );
};

TradeDetailsFormBox.fragments = {
  appraisal: FRAGMENT,
};
export default TradeDetailsFormBox;
