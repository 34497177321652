import React, { useEffect, useState } from 'react';

/* external */
import { cloneDeep } from 'lodash';

/* Material UI */
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import SearchIcon from '@mui/icons-material/Search';

/* internal */
import { Status } from 'constants.js';

const OpportunitiesSearchBar = ({
  keywords,
  setKeywords,
  filters,
  updateFilters,
  refetch,
}) => {
  const [searchWords, setSearchWords] = useState(keywords);

  // If search keyword chip is deleted we have to update searchWords
  useEffect(() => {
    if (!keywords && searchWords !== keywords) {
      setSearchWords(keywords);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keywords]);

  const handleKeywordChange = e => {
    setSearchWords(e.target.value);
  };

  const addKeywordsToFilters = e => {
    if (e.key === 'Enter') {
      setKeywords(e.target.value);
      const newFilters = cloneDeep(filters);
      newFilters['keywords'] =
        e.target.value === '' ? undefined : e.target.value;
      const allStatuses = newFilters['statuses'] === undefined;
      if (allStatuses) {
        newFilters['statuses'] = Status.ALL;
      }
      refetch({
        ...newFilters,
      });
      if (allStatuses) {
        newFilters['statuses'] = undefined;
      }
      updateFilters({
        ...newFilters,
      });
    }
  };

  return (
    <FormControl fullWidth>
      <InputLabel>Search Opps</InputLabel>
      <Input
        value={searchWords}
        onChange={handleKeywordChange}
        endAdornment={
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        }
        onKeyPress={e => addKeywordsToFilters(e)}
      />
    </FormControl>
  );
};

export default OpportunitiesSearchBar;
