import React from 'react';
import moment from 'moment';
import gql from 'graphql-tag';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Badge from '@mui/material/Badge';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { CONFIRMED_STATUSES } from 'constants.js';
import { formatPhoneNumber, defaultTZ } from 'utils';
import { identityTypeIcon, initials } from '../utils';

const MergedCustomerCard = ({ selectedFields, defaultCustomer }) => {
  const customer = { ...defaultCustomer, ...selectedFields };

  const identities = customer?.identities || [];
  const vehicles = customer?.vehicles || [];
  const assigned_salespeople = customer?.assigned_salespeople || [];
  const gocards = customer?.gocards || [];

  const { app_account: goAutoAppAcct } = customer;
  const customerHasGoAutoAppAcct =
    CONFIRMED_STATUSES.includes(goAutoAppAcct?.status) &&
    goAutoAppAcct?.isEnabled;

  return (
    <Card
      style={{
        margin: '1rem',
        width: '100%',
      }}
    >
      <CardHeader
        title={'Merged Customer'}
        titleTypographyProps={{
          align: 'center',
          color: 'textSecondary',
          variant: 'h6',
        }}
      />
      <CardContent>
        <Box>
          <Grid container direction="row">
            <Grid item xs={1}>
              <Badge
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                overlap="circular"
                invisible={!customerHasGoAutoAppAcct}
                badgeContent={
                  <CheckCircleIcon
                    fontSize="small"
                    style={{ color: 'green' }}
                  />
                }
              >
                <Avatar>{initials(customer)}</Avatar>
              </Badge>
            </Grid>
            <Grid item xs={11}>
              <Box>{customer?.fullname} </Box>
              <Box
                style={{
                  color: '#808080',
                  fontSize: '12px',
                  paddingBottom: '1rem',
                }}
              >
                <Grid
                  container
                  direction="row"
                  style={{
                    display: 'flex',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                  }}
                >
                  <Grid item xs={6}>
                    Profile Created {moment(customer.created).format('ll')}
                  </Grid>
                  {customerHasGoAutoAppAcct && (
                    <Grid item xs={6}>
                      {`Go Auto App Account Created: ${moment(
                        goAutoAppAcct.createdAt,
                      )
                        .tz(defaultTZ)
                        .format('MMM YYYY')}`}
                    </Grid>
                  )}
                </Grid>
              </Box>
              <Box
                style={{
                  display: 'flex',
                  marginTop: 'auto',
                  marginBottom: 'auto',
                }}
              >
                <Grid
                  container
                  direction="row"
                  style={{
                    display: 'flex',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                  }}
                >
                  {identities.map(identity => (
                    <Grid
                      item
                      style={{
                        fontSize: '14px',
                        display: 'flex',
                        marginTop: 'auto',
                        marginBottom: 'auto',
                        paddingRight: '1rem',
                      }}
                      key={identity.identity_type}
                    >
                      {identityTypeIcon(identity.identity_type)}
                      <Box style={{ paddingLeft: '0.5rem' }}>
                        {identity.identity_type === 'sms'
                          ? formatPhoneNumber(identity.identity_value)
                          : identity.identity_value}
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Grid container direction="column">
          <Grid
            item
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Box paddingTop={1} paddingBottom={1} style={{ width: '50%' }}>
              <Box>
                <Typography variant="h6">Address</Typography>
              </Box>
              <Box>
                {customer.address} {customer.city},{customer.province}{' '}
                {customer.postal_code}
              </Box>
            </Box>
            <Box paddingTop={1} paddingBottom={1} style={{ width: '50%' }}>
              <Box>
                <Typography variant="h6">Go Card</Typography>
              </Box>
              <Box>
                <>{gocards.length === 0 && <>No GoCards for this Customer</>}</>
                <>
                  {gocards.map(gocard => (
                    <Box key={gocard.id}>
                      {gocard.cash_balance} - {gocard?.account?.card_number}
                    </Box>
                  ))}
                </>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Box paddingTop={1} paddingBottom={1} style={{ width: '50%' }}>
              <Box>
                <Typography variant="h6">Current Vehicle(s)</Typography>
              </Box>
              <Box>
                <>
                  {vehicles.length === 0 && <>No Vehicles for this Customer</>}
                </>
                <>
                  {vehicles.map(vehicle => (
                    <Box key={vehicle._id}>
                      {vehicle.year} {vehicle.make} {vehicle.model}{' '}
                      {vehicle.trim}
                    </Box>
                  ))}
                </>
              </Box>
            </Box>
            <Box paddingTop={1} paddingBottom={1} style={{ width: '50%' }}>
              <Box>
                <Typography variant="h6">Assigned Salespeople</Typography>
              </Box>
              <Box>
                {assigned_salespeople.length === 0 && (
                  <>No Assigned Salespeople for this Customer</>
                )}
                {assigned_salespeople.map(sp => (
                  <Box key={sp.username}>
                    {sp?.user?.display_name} - {sp?.dealer?.dealer_name}
                  </Box>
                ))}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

MergedCustomerCard.fragments = {
  customer: gql`
    fragment MergedCustomerCardFragment on Customer {
      identities {
        identity_type
        identity_value
      }
      vehicles {
        _id
        year
        make
        model
        trim
      }
      assigned_salespeople {
        username
        user {
          display_name
        }
        dealer {
          dealer_name
        }
      }
      gocards {
        id
        cash_balance
        account {
          card_number
        }
      }
      app_account {
        status
        isEnabled
      }
      first_name
      last_name
      fullname
      address
      city
      province
      postal_code
      created
    }
  `,
};

export default MergedCustomerCard;
