import React, { useState } from 'react';

/* external */
import gql from 'graphql-tag';
import moment from 'moment';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';

/* Material UI */
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { DatePicker } from '@mui/x-date-pickers';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material';

/* internal */
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';

const HAS_AMA_TRANSACTION = gql`
  query transactions(
    $filters: [QueryFilter]
    $page: Int!
    $sort: [QuerySortElement]
  ) {
    gocard {
      transactionsByCardId(
        filters: $filters
        page: $page
        pageSize: 10
        sort: $sort
      ) {
        results {
          amount
          cardId
          code
          createBy
          description
          transactionId
          transactionDate
          oldBalance
          newBalance
          location {
            locationName
            locationId
          }
        }
        pagination {
          total
          page
        }
      }
    }
  }
`;

const UPDATE_GOCARD_MEMBERSHIPS = gql`
  mutation updateAccountById($cardId: ID!, $input: GoCardMembershipInput!) {
    gocard {
      updateAccountById(cardId: $cardId, input: $input) {
        cardId
        amaMembership
        rvClubMembership
      }
    }
  }
`;

const CREATE_TRANSACTION = gql`
  mutation createTransaction($input: createTransactionInput!) {
    gocard {
      createTransaction(input: $input) {
        transactionId
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  width: {
    width: '42%',
  },
}));

const AmaMemberModal = ({ gocard, isOpen, onClose, locations }) => {
  const theme = useTheme();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const amaMembership = gocard?.amaMembership || '';
  const [amaMembershipInput, setAmaMembershipInput] = useState(
    gocard?.amaMembership || '',
  );
  const [amaLocationId, setAmaLocationId] = useState('');
  const [roNumber, setRONumber] = useState('');
  const [roDate, setRODate] = useState(moment().format('YYYY-MM-DD'));
  // Only query if we have a membership attached
  const { data, loading, refetch } = useQuery(HAS_AMA_TRANSACTION, {
    variables: {
      filters: [
        {
          field: 'cardId',
          model: 'CashTransaction',
          op: 'eq',
          value: gocard.cardId,
        },
        {
          field: 'createDate',
          model: 'CashTransaction',
          op: '>=',
          value: '2022-01-01',
        },
        {
          field: 'createDate',
          model: 'CashTransaction',
          op: '<=',
          value: '2022-12-31',
        },
        {
          field: 'code',
          model: 'CashTransaction',
          op: '==',
          value: 'AMA',
        },
      ],
      page: 1,
    },
    skip: !isOpen,
    errorPolicy: 'all',
  });

  const [updateAccount, { loading: accountLoading }] = useMutation(
    UPDATE_GOCARD_MEMBERSHIPS,
    {
      onCompleted: e =>
        enqueueSnackbar('Updated account successfully!', {
          variant: 'success',
        }),
      onError: e =>
        enqueueSnackbar(`Error updating account: ${e}`, {
          variant: 'error',
        }),
    },
  );

  const [createTransaction, { loading: transactionLoading }] = useMutation(
    CREATE_TRANSACTION,
    {
      onCompleted: e => {
        enqueueSnackbar('Updated account successfully!', {
          variant: 'success',
        });
        refetch();
      },
      onError: e =>
        enqueueSnackbar(`Error creating transaction: ${e}`, {
          variant: 'error',
        }),
    },
  );

  const transaction = data?.gocard?.transactionsByCardId?.results || [];

  const onSubmit = () => {
    let update = false;
    let input = {};
    if (amaMembership !== amaMembershipInput) {
      input['amaMembership'] = amaMembershipInput;
      update = true;
    }
    if (update) {
      // Update AMA memberships
      updateAccount({
        variables: {
          cardId: gocard.cardId,
          input,
        },
      });
    }
    // Only create a transaction if one hasn't been created yet
    if (amaMembershipInput && amaLocationId && roNumber) {
      // Create AMA transaction
      const input = {
        cardId: gocard.cardId,
        locationId: amaLocationId,
        amount: Number(0.0),
        code: 'AMA',
        description: `Detail completed. RO# ${roNumber} - ${moment(
          roDate,
        ).format('YYYY-MM-DD')}`,
      };
      createTransaction({ variables: { input } });
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth>
      <DialogTitle>AMA Membership</DialogTitle>
      <DialogContent>
        <Typography variant="body2" gutterBottom>
          Go Card customers that have an AMA membership saved on their account
          are entitled to one detail before December 31, 2022
        </Typography>
        <TextField
          label="Membership #"
          variant="outlined"
          onChange={event => setAmaMembershipInput(event.target.value)}
          type="text"
          value={amaMembershipInput}
        />
      </DialogContent>
      {transaction.length !== 0 && (
        <DialogContent>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item xs={6}>
              <b>Created On:</b>
              {` ${moment(transaction[0].transactionDate).format(
                'YYYY-MM-DD',
              )}`}
            </Grid>
            <Grid item xs={6}>
              <b>Created By:</b>
              {` ${transaction[0]?.createBy || 'N/A'}`}
            </Grid>
            <Grid item xs={6}>
              <b>Location:</b>
              {` ${transaction[0].location.locationName}`}
            </Grid>
            <Grid item xs={6}>
              <b>Code:</b>
              {` ${transaction[0].code}`}
            </Grid>
            <Grid item xs={12}>
              <b>Description:</b>
              {` ${transaction[0].description}`}
            </Grid>
          </Grid>
        </DialogContent>
      )}
      {!loading && transaction.length === 0 && amaMembership && (
        <DialogContent>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item xs={12}>
              <FormControl className={classes.width}>
                <InputLabel>Location</InputLabel>
                <Select
                  value={amaLocationId}
                  onChange={e => setAmaLocationId(e.target.value)}
                  disabled={amaMembershipInput === ''}
                >
                  {locations.map(location => (
                    <MenuItem
                      key={location.locationId}
                      value={location.locationId}
                    >
                      {location.locationName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: '5px' }}>
              <FormControl style={{ width: '42%' }}>
                <TextField
                  label="RO Number"
                  value={roNumber}
                  onChange={e => setRONumber(e.target.value)}
                  disabled={amaMembershipInput === ''}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <DatePicker
                autoOk
                clearable
                emptyLabel=""
                format="YYYY-MM-DD"
                onChange={date => setRODate(date)}
                value={roDate}
                variant="dialog"
                disabled={amaMembershipInput === ''}
                className={classes.width}
              />
            </Grid>
          </Grid>
        </DialogContent>
      )}
      <DialogActions>
        <Button style={theme.actions.close} onClick={onClose} variant="text">
          Close
        </Button>
        <Button
          style={theme.actions.confirm}
          onClick={() => onSubmit()}
          variant="contained"
          color="primary"
        >
          Save
        </Button>
      </DialogActions>
      <LoadingBackdrop open={accountLoading || loading}>
        {accountLoading
          ? 'Updating Go Card Memberships'
          : 'Getting Go Card Data'}
        ...
      </LoadingBackdrop>
      <LoadingBackdrop open={transactionLoading}>
        Creating transaction...
      </LoadingBackdrop>
    </Dialog>
  );
};

AmaMemberModal.fragments = {
  gocard: gql`
    fragment AmaMemberModalGoCard on GoCardAccountV2 {
      cardId
      amaMembership
    }
  `,
};

export default AmaMemberModal;
