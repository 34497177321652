import React from 'react';

import { DATE_NO_TIME_FORMAT, StatusDisplay } from 'constants.js';
import gql from 'graphql-tag';
import { useHistory } from 'react-router-dom';
import { dateToFormatString } from 'utils';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import { blue } from '@mui/material/colors';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import ErrorIcon from '@mui/icons-material/ErrorOutline';

const formatDate = d => dateToFormatString(d, DATE_NO_TIME_FORMAT);

const useStyles = makeStyles(theme => ({
  vehicleAvatar: {
    backgroundColor: blue[400],
  },
}));

const OpportunityListItem = ({ opportunity }) => {
  const classes = useStyles();
  const history = useHistory();
  const dealDescription = opportunity.dms_deal.deal_number
    ? `${opportunity.dms_deal.year} ${opportunity.dms_deal.make_name} ${opportunity.dms_deal.model_name} / Deal #${opportunity.dms_deal.deal_number}`
    : 'No deal #';
  const title = `${StatusDisplay[opportunity.status]} opportunity @ ${
    opportunity.dealer.dealer_name
  }`;
  const subtitle = `${formatDate(opportunity.created)} / ${dealDescription}`;
  return (
    <ListItem
      button
      onClick={() => history.push(`/crm/opportunities/${opportunity._id}`)}
    >
      <ListItemAvatar>
        <Avatar className={classes.vehicleAvatar}>
          <DriveEtaIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={title} secondary={subtitle} />
    </ListItem>
  );
};

const Opportunities = ({ customer }) => {
  return (
    <Card>
      <CardHeader title="Opportunities" />
      {customer.opportunities && customer.opportunities.length > 0 ? (
        <CardMedia>
          <List>
            {customer.opportunities.map(opportunity => (
              <OpportunityListItem
                opportunity={opportunity}
                key={opportunity._id}
              />
            ))}
          </List>
        </CardMedia>
      ) : (
        <CardContent>
          <Box textAlign="center">
            <ErrorIcon fontSize="large" />
            <Typography variant="subtitle1" gutterBottom>
              No Opportunities
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              There are no opportunities for this customer
            </Typography>
          </Box>
        </CardContent>
      )}
      <CardActions>
        <Button
          size="small"
          color="primary"
          onClick={() => alert('Not Implimented')}
        >
          Start opportunity...
        </Button>
      </CardActions>
    </Card>
  );
};

Opportunities.fragments = {
  customer: gql`
    fragment OpportunitiesFragment on Customer {
      _id
      opportunities(statuses: [6, 7, 8, 10, 11]) {
        _id
        dealer {
          dealer_name
          dealer_id
        }
        sales_reps {
          display_name
        }
        finance_managers {
          display_name
        }
        sales_managers {
          display_name
        }
        status_display
        status
        stock_type
        created
        updated
        last_status_change
        dms_deal {
          vin
          deal_type
          deal_number
          stock_number
          year
          make_name
          model_name
        }
      }
    }
  `,
};

export default Opportunities;
