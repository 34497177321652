/* External */
import React from 'react';

/* Material UI */
import { Box, Button, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { LibraryAddCheck } from '@mui/icons-material';

import ConversationListTabs from './ConversationListTabs';

/* Internal */
import MultiSelect from './MultiSelect';

const useStyles = makeStyles(theme => ({
  containingBox: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      backgroundColor: 'white',
      marginRight: '10px',
      zIndex: 9,
      boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.16)',
    },
  },
}));

const ConversationListSubHeader = ({
  selectedItemsIds,
  setSelectedItemsIds,
  conversationsList,
  total,
  tabIndex,
  setTabIndex,
  handleMultiSelect,
  setHandleMultiSelect,
  gatewayId,
  conversationQueryVariables,
  setSelectedChatId,
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const multiSelectButtonClick = () => {
    setHandleMultiSelect(handleMultiSelect => !handleMultiSelect);
  };

  return (
    <Box className={classes.containingBox}>
      <ConversationListTabs
        tabIndex={tabIndex}
        setTabIndex={setTabIndex}
        setHandleMultiSelect={setHandleMultiSelect}
      />
      <Box p="10px" display="flex" justifyContent="space-between">
        <Button
          onClick={() => multiSelectButtonClick()}
          startIcon={<LibraryAddCheck />}
          style={{
            color: !handleMultiSelect ? theme.default : theme.colours.add,
          }}
        >
          Multi Select
        </Button>
        <p style={{ textAlign: 'right' }}>
          Showing {conversationsList.length} of {total}
        </p>
      </Box>
      {handleMultiSelect && (
        <MultiSelect
          setHandleMultiSelect={setHandleMultiSelect}
          selectedItemsIds={selectedItemsIds}
          setSelectedItemsIds={setSelectedItemsIds}
          gatewayId={gatewayId}
          conversationQueryVariables={conversationQueryVariables}
          tabIndex={tabIndex}
          setSelectedChatId={setSelectedChatId}
        />
      )}
    </Box>
  );
};

export default ConversationListSubHeader;
