import React from 'react';
import moment from 'moment';

import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  customerInfo: {
    backgroundColor: 'lightgrey',
    padding: '10px',
    marginBottom: '20px',
  },
  subtitle: {
    fontWeight: 'bold',
  },
});

const CustomerInfo = ({ opportunity }) => {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      spacing={2}
      alignItems="center"
      className={classes.customerInfo}
    >
      <Grid container item xs={5} spacing={1} alignItems="center">
        <Grid item xs={6}>
          <Typography className={classes.subtitle}>Customer:</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1" align="right">
            {opportunity.customer_name || opportunity.customer?.company_name}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.subtitle}>Date:</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1" align="right">
            {moment().format('MM-DD-YYYY')}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.subtitle}>Deal Number:</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1" align="right">
            {opportunity.dms_deal?.deal_number || 'N/A'}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.subtitle}>Stock Number:</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1" align="right">
            {opportunity.dms_deal?.stock_number || 'N/A'}
          </Typography>
        </Grid>
      </Grid>
      <Grid container item xs={5} spacing={1} alignItems="center">
        <Grid item xs={6}>
          <Typography className={classes.subtitle}>Salesperson 1:</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1" align="right">
            {opportunity.sales_reps[0]?.display_name || ''}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.subtitle}>Salesperson 2:</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1" align="right">
            {opportunity.sales_reps[1]?.display_name || ''}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.subtitle}>
            Business Manager:
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1" align="right">
            {opportunity.finance_managers[0]?.display_name || ''}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.subtitle}>Sales Manager:</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1" align="right">
            {opportunity.sales_managers[0]?.display_name || ''}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CustomerInfo;
