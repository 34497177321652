import React, { useEffect, useState } from 'react';

/* external */
import { isEmpty } from 'lodash';
import { NetworkStatus } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import { Waypoint } from 'react-waypoint';
import gql from 'graphql-tag';

/* Material UI */
import { makeStyles } from '@mui/styles';
import {
  Box,
  Drawer,
  Fab,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';

/* internal */
import { AppraisalStatus } from 'modules/used_vehicles/const';
import { filtersFromFacets } from '../utils';
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import { usePersistedState } from 'utils';
import BuyNowCard from './BuyNowCard';
import BuyNowFilterDropDown from './BuyNowFilterDropDown';
import ErrorDisplay from 'components/MaterialUI/ErrorDisplay';
import FacetChips from '../components/FacetChips';
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';
import Title from '../components/Title';

const EmptyMessage = () => (
  <Box textAlign="left" paddingBottom={1} paddingTop={1}>
    There are no buy now vehicles!
  </Box>
);

const useStyles = makeStyles(theme => ({
  roomForFABs: {
    paddingBottom: `${48 + 10 + 10}px`,
  },
  FABs: {
    position: 'fixed',
    bottom: '10px',
    right: '10px',
  },
}));

const PAGE_SIZE = 20;

const queryFacets = [
  {
    model: 'Appraisal',
    field: 'year',
  },
  {
    model: 'Appraisal',
    field: 'make',
  },
  {
    model: 'Appraisal',
    field: 'model',
  },
  {
    model: 'Appraisal',
    field: 'bodyType',
  },
  {
    model: 'Appraisal',
    field: 'buyer',
  },
];

const BUY_NOW_QUERY = gql`
    query BuyNowQuery($dealerId: Int!, $queryFacets: [QueryFacet]!, $filters: [QueryFilter], $page: Int!, $sort: [QuerySortElement]){
        appraisals {
            appraisals(filters: $filters, page: $page, pageSize: ${PAGE_SIZE}, sort: $sort)
            {
                results {
                    id
                    ...BuyNowCardAppraisal
                }
                pagination {
                  total
                  nextPage
                }
            }
            appraisalsFacets(filters: $filters, facets: $queryFacets)
            {
              data {
                count
                value
              }
              field
              model
            }
        }
    }
    ${BuyNowCard.fragments.appraisal}
`;
const BuyNowDashboard = () => {
  const { dealerId } = useDealerContext();
  const classes = useStyles();
  const [showFilterDrawer, setShowFilterDrawer] = useState(false);
  const [searchKeywords, setSearchKeywords] = useState('');
  const [searchFilters, setSearchFilters] = useState([]);
  const [filterFacets, setFilterFacets] = usePersistedState(
    'buyNowFilters',
    [],
  );

  const setSearchFiltersFromKeywords = () =>
    setSearchFilters(
      searchKeywords.split(' ').map(keyword => ({
        or: [
          {
            model: 'Appraisal',
            field: 'make',
            op: 'ilike',
            value: `${keyword}%`,
          },
          {
            model: 'Appraisal',
            field: 'model',
            op: 'ilike',
            value: `${keyword}%`,
          },
          {
            model: 'Appraisal',
            field: 'vin',
            op: 'ilike',
            value: `%${keyword}%`,
          },
          {
            model: 'Appraisal',
            field: 'year',
            op: '==',
            value: parseInt(keyword) || 0,
          },
        ],
      })),
    );

  useEffect(() => {
    if (searchKeywords) setSearchFiltersFromKeywords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const buyNowFilters = [
    {
      model: 'Appraisal',
      field: 'isBuyNow',
      op: '==',
      value: true,
    },
    {
      model: 'Appraisal',
      field: 'appraisalStatus',
      op: '==',
      value: AppraisalStatus.OWNED,
    },
  ];

  let filters = [
    ...buyNowFilters,
    ...searchFilters,
    ...filtersFromFacets(filterFacets),
  ];
  const sort = [{ model: 'Appraisal', field: 'id', direction: 'desc' }];

  const { data, error, fetchMore, loading, networkStatus } = useQuery(
    BUY_NOW_QUERY,
    {
      variables: { dealerId, filters, page: 1, sort, queryFacets },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
    },
  );

  const appraisals = data?.appraisals?.appraisals.results ?? [];
  const appraisalsFacets = data?.appraisals.appraisalsFacets || [];
  const pagination = data?.appraisals?.appraisals.pagination ?? {};
  const { total, nextPage } = pagination;

  const fetchMoreHandler = () => {
    if (nextPage)
      fetchMore({
        variables: { page: nextPage },
        updateQuery: (prev, { fetchMoreResult: more }) => {
          if (!more.appraisals.appraisals.results) return prev;

          return Object.assign({}, prev, {
            appraisals: {
              __typename: prev.appraisals.__typename,
              appraisals: {
                __typename: prev.appraisals.appraisals.__typename,
                results: [
                  ...prev.appraisals.appraisals.results,
                  ...more.appraisals.appraisals.results,
                ],
                pagination: more.appraisals.appraisals.pagination,
              },
              appraisalsFacets: more.appraisals.appraisalsFacets,
            },
          });
        },
      });
  };

  const handleDeleteFacet = (_model, _field, _value) =>
    setFilterFacets(prev =>
      prev.filter(
        ({ model, field, value }) =>
          model !== _model || field !== _field || value !== _value,
      ),
    );

  const handleSearch = e =>
    e.key === 'Enter' ? setSearchFiltersFromKeywords() : null;

  if (error) return <ErrorDisplay error={error} />;

  if (loading && !networkStatus === NetworkStatus.fetchMore)
    return <LoadingBackdrop open={loading} />;

  return (
    <Box
      className={classes.roomForFABs}
      marginLeft={1}
      marginTop={1}
      marginRight={1}
    >
      <Box width="100%">
        <Title
          title="Used Vehicles - Buy Now!"
          paddingBottom={1}
          width="100%"
        />
        <Box>
          <TextField
            placeholder="Search ..."
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            value={searchKeywords}
            onChange={e => setSearchKeywords(e.target.value)}
            style={{ paddingRight: '5px', textAlign: 'right' }}
            onKeyPress={handleSearch}
            variant="outlined"
            fullWidth
          />
        </Box>
        <Box>
          <Box display="flex" flexDirection="row-reverse" mt={1} mb={1}>
            <Box>
              Showing {appraisals.length} of {total}
            </Box>
          </Box>
        </Box>
        {!isEmpty(filterFacets) && (
          <Box paddingTop="0.5rem" paddingBottom="0.5rem">
            <FacetChips
              facets={filterFacets}
              onDeleteFacet={handleDeleteFacet}
            />
          </Box>
        )}
        {isEmpty(appraisals) && <EmptyMessage />}
        {appraisals.map(appraisal => (
          <BuyNowCard key={appraisal.id} appraisal={appraisal} />
        ))}
        <Waypoint onEnter={fetchMoreHandler} />
        {networkStatus === NetworkStatus.fetchMore && (
          <Box>Loading more buy now vehicles...</Box>
        )}
        <Drawer
          anchor="left"
          open={showFilterDrawer}
          onClose={() => setShowFilterDrawer(false)}
        >
          <Box display="flex">
            <IconButton
              style={{ marginLeft: 'auto', height: '50px', zIndex: '1000' }}
              onClick={() => setShowFilterDrawer(false)}
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <BuyNowFilterDropDown
            facetResults={appraisalsFacets}
            selectedFacets={filterFacets}
            setSelectedFacets={setFilterFacets}
          />
        </Drawer>
        <Box className={classes.FABs}>
          <Fab
            size="medium"
            color="primary"
            onClick={() => setShowFilterDrawer(true)}
          >
            <FilterListIcon />
          </Fab>
        </Box>
      </Box>
    </Box>
  );
};

export default BuyNowDashboard;
