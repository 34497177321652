/* External */
import React from 'react';

/* Material UI */
import { Box, Tab, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { TabContext, TabList } from '@mui/lab';

const useStyles = makeStyles(theme => ({
  tabs: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: '1250px',
      marginBottom: '20px',
    },
  },
}));

const ConversationListTabs = ({
  tabIndex,
  setTabIndex,
  setHandleMultiSelect,
}) => {
  const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
    setHandleMultiSelect(false);
  };

  return (
    <Box className={classes.tabs}>
      <Box bgcolor="white" padding="10px" borderBottom="2px solid #d7d7d7">
        <TabContext value={tabIndex}>
          <TabList
            onChange={handleChange}
            variant={mobile ? 'scrollable' : 'standard'}
          >
            <Tab label="All Chats" value={'0'} style={{ minWidth: '25%' }} />
            <Tab label="Your Chats" value={'1'} style={{ minWidth: '25%' }} />
            <Tab label="Starred" value={'2'} style={{ minWidth: '25%' }} />
            <Tab label="Archived" value={'3'} style={{ minWidth: '25%' }} />
          </TabList>
        </TabContext>
      </Box>
    </Box>
  );
};

export default ConversationListTabs;
