import React from 'react';
import gql from 'graphql-tag';

import { GridWithLabel, GridItem, UintItem, BoolItem } from './GridWrappers';

export default function InteriorGrid() {
  return (
    <GridWithLabel label="Interior">
      <GridItem label="Interior Colour" name="interiorColourName" />
      <GridItem label="Refrigerator Type" name="specFridgeType" />
      <UintItem label="Sleeps" name="specSleepCount" />
      <UintItem label="Refrigerator Size" name="specFridgeCuFt" />
      <GridItem label="Available Beds" name="specAvailableBeds" />
      <BoolItem label="Convection Cooking?" name="specConvectionOven" />
      <UintItem label="Number of Bunks" name="specBunkCount" />
      <UintItem label="Cooktop Burners" name="specCooktopBurnerCount" />
      <BoolItem label="Washer Available?" name="specWasherAvailable" />
      <GridItem label="Shower Type" name="specShowerType" />
      <BoolItem label="Dryer Available?" name="specDryerAvailable" />
      <GridItem label="Shower Size" name="specShowerSize" />
      <GridItem label="TV Info" name="specTvInfo" />
    </GridWithLabel>
  );
}

InteriorGrid.fragments = {
  vehicle: gql`
    fragment NpvVehicleSpecsInterior on NpvVehicle {
      id
      interiorColourName
      specSleepCount
      specAvailableBeds
      specBunkCount
      specShowerWidthInches
      specShowerLengthInches
      specShowerSize
      specShowerType
      specFridgeType
      specFridgeCuFt
      specConvectionOven
      specCooktopBurnerCount
      specWasherAvailable
      specDryerAvailable
      specTvInfo
    }
  `,
};
