import React, { useState } from 'react';

/* external */
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import gql from 'graphql-tag';

/* Material UI */
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';

/* internal */
import { formatPrice, usePersistedState } from 'utils';
import { outOfProvinceWarning } from 'modules/auctions/const';
import { useDealerContext } from 'components/MaterialUI/DealerContext';
import { errorHandler } from 'modules/auctions/utils';
import ConfirmDialog from 'components/MaterialUI/ConfirmDialog';

const useStyles = makeStyles(theme => ({
  center: {
    textAlign: 'center',
  },
  card: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
  },
  form: {
    display: 'flex',
  },
}));

const CREATE_PROXY_BID = gql`
  mutation createProxyBid($proxyBid: ProxyBidInput!) {
    auctions {
      createProxyBid(proxyBid: $proxyBid) {
        id
        amount
        dealerId
      }
    }
  }
`;

const ProxyBidCard = ({ item, refetch }) => {
  const { center, card, form } = useStyles();
  const { dealerId, dealerProvinceName } = useDealerContext();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const [createProxyBid] = useMutation(CREATE_PROXY_BID);
  const [showConfirmBidDialog, setShowConfirmBidDialog] = useState(false);
  const [hideWarningSelection, setHideWarningSelection] = useState(false);

  const {
    dealerProxyBids,
    isClosed,
    dealerId: itemDealerId,
    id,
    vehicle,
  } = item;

  const { locatedProvince } = vehicle;
  const proxyAmount =
    dealerProxyBids.sort((a, b) => b.id - a.id)?.[0]?.amount || 0;
  const [amount, setAmount] = useState(proxyAmount || '');

  const canProxy = itemDealerId !== dealerId && !isClosed;
  const enableSubmit = canProxy && amount !== proxyAmount;

  const [ignoreWarningItemIds, setIgnoreWarningItemIds] = usePersistedState(
    'ignoreOutOfProvinceWarningAuctionItemIds',
    '[]',
  );

  const ignoreWarning = JSON.parse(ignoreWarningItemIds)?.includes(id);

  const addToIgnoreList = () =>
    setIgnoreWarningItemIds(
      JSON.stringify([id, ...JSON.parse(ignoreWarningItemIds)]),
    );

  const handleCreateProxyBid = () => {
    if (hideWarningSelection) addToIgnoreList();
    createProxyBid({
      variables: {
        proxyBid: {
          amount: parseInt(amount, 10),
          auctionItemId: item.id,
          dealerId,
        },
      },
    })
      .then(refetch)
      .catch(errorHandler(enqueueSnackbar, () => {}));
  };

  const handleSubmit = e => {
    if (enableSubmit) {
      e.preventDefault();
      if (dealerProvinceName !== locatedProvince && !ignoreWarning)
        setShowConfirmBidDialog(true);
      else handleCreateProxyBid();
    }
  };

  return (
    <Card className={card}>
      <ConfirmDialog
        isOpen={showConfirmBidDialog}
        onClose={() => setShowConfirmBidDialog(false)}
        text={outOfProvinceWarning(dealerProvinceName, locatedProvince)}
        onConfirm={handleCreateProxyBid}
        content={
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={hideWarningSelection}
                  onChange={() => setHideWarningSelection(p => !p)}
                />
              }
              label="Don't show this warning again for this item."
            />
          </Box>
        }
      />
      <CardHeader
        title="My Proxy"
        titleTypographyProps={{
          align: 'center',
          color: 'textSecondary',
          variant: 'h6',
        }}
      />
      <CardContent className={center}>
        <Box fontSize="h5.fontSize">{formatPrice(proxyAmount)}</Box>
      </CardContent>
      <CardActions>
        <form className={form} onSubmit={handleSubmit}>
          <FormControl>
            <InputLabel>Amount</InputLabel>
            <Input
              startAdornment={
                <InputAdornment position="start">$</InputAdornment>
              }
              name="amount"
              type="number"
              value={amount}
              onChange={e => setAmount(e.target.value)}
              style={{ width: 'auto' }}
            />
          </FormControl>
          <Box display="flex" flexDirection="column" justifyContent="flex-end">
            <Button
              style={enableSubmit ? theme.actions.confirm : null}
              disabled={!enableSubmit}
              size="small"
              type="submit"
            >
              Set
            </Button>
          </Box>
        </form>
      </CardActions>
    </Card>
  );
};

ProxyBidCard.fragments = {
  item: gql`
    fragment ProxyBidCardItem on AuctionItem {
      id
      dealerId
      vehicle {
        locatedProvince
      }
      isClosed
      dealerProxyBids(dealerId: $dealerId) {
        id
        amount
      }
    }
  `,
};
export default ProxyBidCard;
