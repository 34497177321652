import React from 'react';

import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';

import { useSnackbar } from 'notistack';
import { Role, Status, StatusDisplay } from 'constants.js';
import { useUserContext } from 'components/MaterialUI/UserContext';

const DealLogStatusModal = ({
  showStatus,
  setShowStatus,
  setShowTubedModal,
  updateStatus,
  opportunity,
  setStatus,
  status,
  isPostedAndNotOrgAdmin,
  isSalesRole,
  isServiceRole,
  handleClose,
}) => {
  const { currentUser } = useUserContext();
  const { enqueueSnackbar } = useSnackbar();

  const handleStatusChange = event => {
    // Update status regularly unless its TUBED
    if (Number(event.target.value) !== 9) {
      updateStatus({
        variables: {
          id: opportunity._id,
          statuses: {
            status: Number(event.target.value),
            sub_status: opportunity.sub_status,
          },
        },
      }).then(r => {
        setStatus(event.target.value);
        enqueueSnackbar('Updated Status!');
      });
    } else {
      setShowTubedModal(true);
    }
  };
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      scroll="paper"
      open={showStatus}
      onClose={() => {
        setShowStatus(false);
      }}
    >
      <DialogTitle>
        <h2>Update Status</h2>
      </DialogTitle>
      <DialogContent>
        <FormControl
          style={{ width: '100%' }}
          disabled={isPostedAndNotOrgAdmin || isSalesRole || isServiceRole}
        >
          <Select value={status} onChange={handleStatusChange}>
            {Object.entries(StatusDisplay).map(([key, value]) => (
              <MenuItem
                key={key}
                value={key}
                disabled={
                  (key === String(Status.POSTED) &&
                    currentUser.role !== Role.ORGANIZATION_ADMIN) ||
                  key === String(Status.FRESH)
                }
              >
                {value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DealLogStatusModal;
