import React, { useState } from 'react';

import { getMediumDateTimeWithWeekday, withSuffix } from 'utils';

import {
  Box,
  Card,
  CardActions,
  CardContent,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/styles';
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material';

const useStyles = makeStyles(theme => ({
  alignItems: {
    display: 'flex',
    alignItems: 'center',
  },
  divider: {
    margin: '10px 0',
  },
  marginBottom: {
    marginBottom: '10px',
  },
}));
const SyndicationMobile = ({ imports, exports, me, dealerId }) => {
  const classes = useStyles();
  const [openImports, setOpenImports] = useState(false);
  const [openExports, setOpenExports] = useState(false);

  const ExpandMore = styled(props => {
    const { expand, ...other } = props;
    return <IconButton {...other} size="large" />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));
  return (
    <>
      <Box component="div" marginBottom={3}>
        <Card style={{ padding: '1rem' }}>
          <CardActions disableSpacing>
            <Typography variant="h5">Imports</Typography>
            <ExpandMore
              expand={openImports}
              onClick={() => setOpenImports(!openImports)}
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </CardActions>
          <Collapse in={openImports} timeout="auto" unmountOnExit>
            <CardContent>
              <Typography>
                Your vehicles are being imported from the following source(s):
              </Typography>
              {imports.map(row => {
                const dealer = row.dealers.find(
                  dealer => dealer.dealerId === dealerId,
                );
                return (
                  <Box key={row.id}>
                    <Divider className={classes.divider} />
                    <Grid
                      container
                      spacing={2}
                      className={classes.marginBottom}
                    >
                      <Grid item xs={12}>
                        <b>Import Name: </b> {row.name}
                      </Grid>
                      <Grid item xs={6} className={classes.alignItems}>
                        <b>New Vehicles: </b>
                        {dealer.updateNew ? (
                          <CheckBoxIcon />
                        ) : (
                          <CheckBoxOutlineBlankIcon />
                        )}
                      </Grid>
                      <Grid item xs={6} className={classes.alignItems}>
                        <b>Used Vehicles: </b>
                        {dealer.updateUsed ? (
                          <CheckBoxIcon />
                        ) : (
                          <CheckBoxOutlineBlankIcon />
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        <b>Frequency: </b>
                        {row.intervalHours} Hour(s)
                      </Grid>
                      <Grid item xs={6}>
                        <b>Updated Count: </b>
                        {row?.lastImportLog?.updateCount ?? 0}
                      </Grid>
                      <Grid item xs={12}>
                        <b>Last Import Ran: </b>
                        {getMediumDateTimeWithWeekday(
                          withSuffix(row.lastRan, 'Z'),
                          me?.goUserProfile?.settings?.timezone,
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                );
              })}
            </CardContent>
          </Collapse>
        </Card>
      </Box>
      <Box component="div">
        <Card style={{ padding: '1rem' }}>
          <CardActions disableSpacing>
            <Typography variant="h5">Exports</Typography>
            <ExpandMore
              expand={openExports}
              onClick={() => setOpenExports(!openExports)}
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </CardActions>
          <Collapse in={openExports} timeout="auto" unmountOnExit>
            <CardContent>
              <Typography>
                Your vehicles are being exported to the following source(s):
              </Typography>

              {exports.map(row => {
                const dealer = row.dealers.find(
                  dealer => dealer.dealerId === dealerId,
                );
                return (
                  <>
                    <Divider className={classes.divider} />
                    <Grid
                      container
                      key={row.id}
                      spacing={2}
                      className={classes.marginBottom}
                    >
                      <Grid item xs={12}>
                        <b>Export Name: </b> {row.name}
                      </Grid>
                      <Grid item xs={6} className={classes.alignItems}>
                        <b>New Vehicles: </b>
                        {dealer.exportNew ? (
                          <CheckBoxIcon />
                        ) : (
                          <CheckBoxOutlineBlankIcon />
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <b>Used Vehicles: </b>
                        {dealer.exportUsed ? (
                          <CheckBoxIcon />
                        ) : (
                          <CheckBoxOutlineBlankIcon />
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        <b>Frequency: </b> {row.frequency}
                      </Grid>
                      <Grid item xs={12}>
                        <b>Last Export Ran: </b>
                        {getMediumDateTimeWithWeekday(
                          withSuffix(row.lastRan, 'Z'),
                          me?.goUserProfile?.settings?.timezone,
                        )}
                      </Grid>
                    </Grid>
                  </>
                );
              })}
            </CardContent>
          </Collapse>
        </Card>
      </Box>
    </>
  );
};

export default SyndicationMobile;
