import React from 'react';

import ConfirmDialog from 'components/MaterialUI/ConfirmDialog';
import gql from 'graphql-tag';
import { useSnackbar } from 'notistack';
import { formatPrice } from 'utils';
import { Role } from 'constants.js';

import { useMutation } from '@apollo/react-hooks';
import { useMediaQuery } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import ClearIcon from '@mui/icons-material/Clear';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BuildIcon from '@mui/icons-material/Build';
import { useUserContext } from 'components/MaterialUI/UserContext';
import DateDisplay from './DateDisplay';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '300px',
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  mobileHeader: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  mobileText: {
    fontSize: 14,
  },
  tableCellBold: {
    paddingBottom: 0,
    paddingTop: 0,
    border: 'none',
    fontWeight: 'bold',
  },
  tableCell: {
    paddingBottom: 0,
    paddingTop: 0,
    border: 'none',
  },
}));

const DETACH_CUSTOMER_RECORD = gql`
  mutation detachCustomerRecords($_id: ID!, $records: [String]!) {
    detachCustomerRecords(_id: $_id, records: $records) {
      records
    }
  }
`;

const ATTACH_CUSTOMER_RECORD = gql`
  mutation attachCustomerRecords($_id: ID!, $records: [String]!) {
    attachCustomerRecords(_id: $_id, records: $records) {
      records
    }
  }
`;
const ServiceHistory = ({ customer }) => {
  const desktop = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const [record, setRecord] = React.useState();
  const { currentUser } = useUserContext();
  const [detachRecord] = useMutation(DETACH_CUSTOMER_RECORD, {
    onCompleted: data => {
      enqueueSnackbar(`Successfully detached record`, {
        variant: 'success',
      });
    },
    onError: e =>
      enqueueSnackbar(`Error in detaching record`, {
        variant: 'error',
      }),
  });

  const [attachRecord] = useMutation(ATTACH_CUSTOMER_RECORD, {
    onCompleted: data => {
      setRecord(data.attachCustomerRecords.records);
      enqueueSnackbar(`Successfully attached record`, {
        variant: 'success',
      });
    },
    onError: e =>
      enqueueSnackbar(`Error in attaching record`, {
        variant: 'error',
      }),
  });

  const { enqueueSnackbar } = useSnackbar();
  const [showDetachConfirm, setShowDetachConfirm] = React.useState(false);
  const [showRebuildConfirm, setShowRebuildConfirm] = React.useState(false);
  const handleCloseDialog = () => {
    setShowDetachConfirm(false);
  };
  const handleOpenDialog = record => {
    setRecord(record);
    setShowDetachConfirm(true);
  };
  const handleCloseDialogRebuild = () => {
    setShowRebuildConfirm(false);
  };
  const handleOpenDialogRebuild = record => {
    setRecord(record);
    setShowRebuildConfirm(true);
  };
  const handleClear = () => {
    detachRecord({
      variables: {
        _id: customer._id,
        records: [record],
      },
    });
  };
  const handleRebuild = async () => {
    await detachRecord({
      variables: {
        _id: customer._id,
        records: record,
      },
    });
    attachRecord({
      variables: {
        _id: customer._id,
        records: record,
      },
    });
  };

  const classes = useStyles();
  const theme = useTheme();
  return (
    <>
      <ConfirmDialog
        text="Are you sure you want to remove this from the customer?"
        onConfirm={handleClear}
        isOpen={showDetachConfirm}
        onClose={handleCloseDialog}
        confirmButtonProps={{
          style: theme.actions.confirm,
          variant: 'outlined',
        }}
        abortButtonProps={{
          variant: 'outlined',
        }}
      />
      <ConfirmDialog
        text="Are you sure you want to rebuild the service history for the customer?"
        onConfirm={handleRebuild}
        isOpen={showRebuildConfirm}
        onClose={handleCloseDialogRebuild}
        confirmButtonProps={{
          style: theme.actions.confirm,
          variant: 'outlined',
        }}
        abortButtonProps={{
          variant: 'outlined',
        }}
      />
      <Card className={classes.root}>
        <CardContent>
          <Grid container direction="row" spacing={2}>
            <Grid item>
              <Typography className={classes.title} gutterBottom>
                Service History
              </Typography>
            </Grid>
            <Grid item>
              {currentUser.role === Role.ORGANIZATION_ADMIN && (
                <IconButton
                  size="small"
                  onClick={handleOpenDialogRebuild.bind(
                    this,
                    customer.records.filter(x => x.includes('vehicle_service')),
                  )}
                >
                  <BuildIcon />
                </IconButton>
              )}
            </Grid>
          </Grid>
          {customer.service_history.length === 0 &&
            'No service history for this customer'}
          {customer.service_history
            .sort((a, b) => -a.close_date?.localeCompare(b.close_date))
            .map(service_history => {
              return (
                <Card
                  key={service_history.record_urn}
                  style={{ marginBottom: '15px', marginTop: '15px' }}
                >
                  <CardContent>
                    <Grid
                      container
                      direction="row"
                      justifyContent={'space-between'}
                      alignItems="flex-start"
                      spacing={2}
                    >
                      <Grid
                        item
                        style={{
                          paddingRight: 0,
                          paddingTop: desktop ? 8 : 16,
                        }}
                      >
                        <DateDisplay date={service_history.close_date} />
                      </Grid>
                      <Hidden lgUp>
                        <Grid item xs={7} style={{ paddingLeft: 0 }}>
                          <ListItemText
                            primaryTypographyProps={{
                              style: { fontWeight: 'bold' },
                            }}
                            primary={`RO# ${service_history.ro_number} @ ${
                              service_history?.dealer?.dealer_name ||
                              'Unknown Dealer'
                            }`}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <IconButton
                            size="small"
                            onClick={handleOpenDialog.bind(
                              this,
                              service_history.record_urn,
                            )}
                          >
                            <ClearIcon />
                          </IconButton>
                        </Grid>
                        <Grid item xs={12}>
                          <List style={{ paddingTop: 0 }} dense={true}>
                            <ListItem>
                              <Typography className={classes.mobileText}>
                                {`${service_history.year} ${service_history.make_name} ${service_history.model_name}`}
                              </Typography>
                            </ListItem>
                            <ListItem>
                              <ListItemText
                                primary={`VIN: ${service_history.vin}`}
                                style={{ color: '#999999' }}
                              />
                            </ListItem>

                            <ListItem>
                              <Box mr={1} display="inline">
                                <Typography className={classes.mobileHeader}>
                                  Odometer:
                                </Typography>
                              </Box>
                              <Typography className={classes.oppMobileText}>
                                {formatPrice(service_history.odometer, {
                                  leading: '',
                                })}
                              </Typography>
                            </ListItem>
                            <ListItem>
                              <Box mr={1} display="inline">
                                <Typography className={classes.mobileHeader}>
                                  Customer Interal Pay:
                                </Typography>
                              </Box>
                              <Typography className={classes.oppMobileText}>
                                {`${formatPrice(
                                  service_history.ro_sale_customer_pay,
                                  {
                                    cents: true,
                                  },
                                )}`}
                              </Typography>
                            </ListItem>
                            <ListItem>
                              <Box mr={1} display="inline">
                                <Typography className={classes.mobileHeader}>
                                  Warranty Internal Pay:
                                </Typography>
                              </Box>
                              <Typography className={classes.oppMobileText}>
                                {`${formatPrice(
                                  service_history.ro_sale_warranty_pay,
                                  {
                                    cents: true,
                                  },
                                )}`}
                              </Typography>
                            </ListItem>
                            <ListItem>
                              <Box mr={1} display="inline">
                                <Typography className={classes.mobileHeader}>
                                  Internal Pay Total:
                                </Typography>
                              </Box>
                              <Typography className={classes.oppMobileText}>
                                {`${formatPrice(
                                  service_history.ro_sale_internal_pay,
                                  {
                                    cents: true,
                                  },
                                )}`}
                              </Typography>
                            </ListItem>
                          </List>
                        </Grid>
                      </Hidden>
                      <Grid item xs={11}>
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="flex-start"
                        >
                          <Hidden lgDown>
                            <Grid item xs={4}>
                              <List style={{ paddingTop: 0 }} dense={true}>
                                <ListItem className={classes.title}>
                                  {`RO# ${service_history.ro_number} @ ${
                                    service_history?.dealer?.dealer_name ||
                                    'Unknown Dealer'
                                  }`}
                                </ListItem>
                              </List>
                              <TableContainer style={{ width: '75%' }}>
                                <Table>
                                  <TableBody>
                                    <TableRow>
                                      <TableCell
                                        className={classes.tableCellBold}
                                        align="left"
                                      >
                                        Customer Pay Total:
                                      </TableCell>
                                      <TableCell
                                        className={classes.tableCell}
                                        align="right"
                                      >
                                        {`${formatPrice(
                                          service_history.ro_sale_customer_pay,
                                          {
                                            cents: true,
                                          },
                                        )}`}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell
                                        className={classes.tableCellBold}
                                        align="left"
                                      >
                                        Warranty Pay Total:
                                      </TableCell>
                                      <TableCell
                                        className={classes.tableCell}
                                        align="right"
                                      >
                                        {`${formatPrice(
                                          service_history.ro_sale_warranty_pay,
                                          {
                                            cents: true,
                                          },
                                        )}`}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell
                                        className={classes.tableCellBold}
                                        align="left"
                                      >
                                        Internal Pay Total:
                                      </TableCell>
                                      <TableCell
                                        className={classes.tableCell}
                                        align="right"
                                      >
                                        {`${formatPrice(
                                          service_history.ro_sale_internal_pay,
                                          {
                                            cents: true,
                                          },
                                        )}`}
                                      </TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Grid>
                            <Grid item xs={4}>
                              <List style={{ paddingTop: 0 }} dense={true}>
                                <ListItem className={classes.title}>
                                  {`${service_history.year} ${service_history.make_name} ${service_history.model_name}`}
                                </ListItem>
                              </List>
                              <TableContainer>
                                <Table style={{ maxWidth: 250 }}>
                                  <TableBody>
                                    <TableRow>
                                      <TableCell
                                        className={classes.tableCellBold}
                                        align="left"
                                      >
                                        Odometer
                                      </TableCell>
                                      <TableCell
                                        className={classes.tableCell}
                                        align="right"
                                      >
                                        {formatPrice(service_history.odometer, {
                                          leading: '',
                                        })}
                                      </TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Grid>
                            <Grid item>
                              <ListItemText
                                primary={`VIN: ${service_history.vin}`}
                                style={{ color: '#999999' }}
                              />
                            </Grid>
                            <Grid item>
                              <IconButton
                                size="small"
                                onClick={handleOpenDialog.bind(
                                  this,
                                  service_history.record_urn,
                                )}
                              >
                                <ClearIcon />
                              </IconButton>
                            </Grid>
                          </Hidden>
                          <Grid item xs={12}>
                            <Accordion
                              style={{
                                backgroundColor: 'WhiteSmoke',
                                marginTop: '10px',
                              }}
                            >
                              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography className={classes.heading}>
                                  Service Details
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Typography>
                                  {service_history.details.map(
                                    (detail, index) => (
                                      <ListItemText
                                        key={`${detail.date_created}:${index}`}
                                        primary={`• ${
                                          detail.service_request !== ''
                                            ? detail.service_request
                                            : detail.op_code_description
                                        }`}
                                      />
                                    ),
                                  )}
                                </Typography>
                              </AccordionDetails>
                            </Accordion>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              );
            })}
        </CardContent>
      </Card>
    </>
  );
};

ServiceHistory.fragments = {
  customer: gql`
    fragment ServiceHistoryFragment on Customer {
      service_history {
        close_date
        dealer_id
        dealer {
          dealer_name
        }
        details {
          date_created
          service_request
          op_code_description
        }
        make_name
        model_name
        year
        vin
        odometer
        op_codes
        ro_number
        record_urn
        ro_sale_customer_pay
        ro_sale_internal_pay
        ro_sale_warranty_pay
      }
    }
  `,
};

export default ServiceHistory;
