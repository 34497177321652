// PropTypes for selectors.js
import PropTypes from 'prop-types';

const { string, number, arrayOf, shape } = PropTypes;

export const getCurrentUserPropTypes = shape({
  username: string.isRequired,
  user_id: number,
  organization_id: string.isRequired,
  role: string.isRequired,
  exp: number.isRequired,
  display_name: string.isRequired,
  dealer_ids: arrayOf(number).isRequired,
});
