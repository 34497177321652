/* External */
import React from 'react';

/* Material UI */
import {
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

/* Internal */

const useStyles = makeStyles(theme => ({
  cardTotalsCount: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem',
    },
  },
}));

const CountCard = ({
  name,
  count,
  filter,
  handleCardClick,
  cardClass,
  actionAreaDisabled,
}) => {
  const classes = useStyles();

  return (
    <Grid item>
      <Card className={cardClass}>
        <CardActionArea
          onClick={() => handleCardClick(filter)}
          disabled={actionAreaDisabled}
        >
          <CardContent>
            <Typography className={classes.cardTotalsCount}>{count}</Typography>
            <Typography>{name}</Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

export default CountCard;
