import React, { useRef, useState } from 'react';
import { withStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import { cloneDeep } from 'lodash';

import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';

import CheckBox from '@mui/icons-material/CheckBox';

import SuperAccordion from './SuperAccordion';

function FormAccordion({ ...rest }) {
  return (
    <SuperAccordion
      xs1={6}
      xs2={6}
      white
      orange
      greenValue="Pass"
      redValue="Needs Service"
      orangeValue="Reinsp"
      {...rest}
    />
  );
}

const VWInspectionForm = props => {
  const { enqueueSnackbar } = useSnackbar();

  const engine = {
    vw1: 'Noise',
    vw2: 'Motor Mounts',
    vw3: 'Engine Modifications',
    vw4: 'Oil Level/Condition',
    vw5: 'Oil Level Leaks/Seals/Gaskets',
    vw6: 'Turbo/Superchargers (if Equipped)',
    vw7: 'Smoke',
    vw8: 'Driveability, Hesitation, Stalling',
  };
  const transmissionTransferCase = {
    vw9: 'Fluid Level/Condition',
    vw10: 'Shift Points (if Automatic)',
    vw11: 'Slipping (if Automatic)',
    vw12: 'Noise',
    vw13: 'Transmission Mounts',
    vw14: 'Fluid Level Leaks/Seals/Gaskets',
  };
  const driveAxle = {
    vw15: 'Vibration',
    vw16: 'Noise',
    vw17: 'Fluid Level/Leaks/Gaskets',
    vw18: 'CV/U Joints/Drive Shaft',
    vw19: 'CV Boots',
    vw20: 'Locking Hubs',
  };
  const steering = {
    vw21: 'Fluid Level/Leaks',
    vw22: 'Rack/Steering Box',
    vw23: 'Lines/Hoses/Pump',
    vw24: 'Linkages/Tie Rods/Couplings',
    vw25: 'Tilt Wheel',
  };
  const brakeSystem = {
    vw26: 'Fluid/Leaks/Lines/Hoses',
    vw27: 'Master Cylinder/Fluid Level',
    vw28: 'Pump/Booster',
    vw29: 'Calipers with Cylinders',
    vw30: 'ABS Components',
  };
  const electricalSystem = {
    vw31: 'Alternator/Regulator',
    vw32: 'Starter/Solenoid/Drive',
    vw33: 'Power Windows/Seat Motor',
    vw34: 'Wiper Motors',
    vw35: 'Power Locks',
    vw36: 'Power Roof Motor/Antenna',
    vw37: 'All Manual Switches',
    vw38: 'Cruise Control',
    vw39: 'Speedometer/Odometer',
    vw40: 'Instrument Panel/Gauges/Lights',
    vw41: 'Horn/Warning Indicators',
  };

  const airConditioning = {
    vw42: 'Blower Motor',
    vw43: 'Compressor/Clutch (Noise)',
    vw44: 'Condenser/Evaporator',
    vw45: 'Idler Pulley',
    vw46: 'Air Duct Temperature (General Operation)',
  };

  const frontRearSuspension = {
    vw47: 'Control Arms/Ball Joints',
    vw48: 'Wheel Bearings/Spindles',
    vw49: 'Torsion Bards/Radius Arms',
    vw50: 'Stabilizer Shaft & Links',
    vw51: 'Electronic/Air Suspension',
  };

  const coolingFuelSystem = {
    vw52: 'Water Pump/Thermostat',
    vw53: 'Engine Fan/Module/Clutch',
    vw54: 'Belts & Hoses',
    vw55: 'Radiator/Heater Core',
    vw56: 'Coolant Level/Condition/Leaks',
    vw57: 'Fuel Tank/Lines/Injectors',
    vw58: 'Fuel/Injection Pump/Vacuum Pump',
  };

  const vehicleModifications = {
    vw59: 'Tire Size',
    vw60: 'Suspension/Engine',
    vw61: 'Emissions Equipment',
    vw62: 'Electrical/Remote Starter',
  };

  function generateDefaults(objToCopy) {
    const newObj = cloneDeep(objToCopy);
    Object.entries(newObj).map(([name]) => (newObj[name] = 'Pass'));
    return newObj;
  }

  const [engineValues, setEngineValues] = useState(generateDefaults(engine));
  const [transmissionTransferCaseValues, setTransmissionTransferCaseValues] =
    useState(generateDefaults(transmissionTransferCase));
  const [driveAxleValues, setDriveAxleValues] = useState(
    generateDefaults(driveAxle),
  );
  const [steeringValues, setSteeringValues] = useState(
    generateDefaults(steering),
  );
  const [brakeSystemValues, setBrakeSystemValues] = useState(
    generateDefaults(brakeSystem),
  );
  const [electricalSystemValues, setElectricalSystemValues] = useState(
    generateDefaults(electricalSystem),
  );
  const [airConditioningValues, setAirConditioningValues] = useState(
    generateDefaults(airConditioning),
  );
  const [frontRearSuspensionValues, setFrontRearSuspensionValues] = useState(
    generateDefaults(frontRearSuspension),
  );
  const [coolingFuelSystemValues, setCoolingFuelSystemValues] = useState(
    generateDefaults(coolingFuelSystem),
  );
  const [vehicleModificationsValues, setVehicleModificationsValues] = useState(
    generateDefaults(vehicleModifications),
  );

  const handler = setter => e => {
    const { name, value } = e.target;
    e.target.closest('div').previousSibling.style.textDecoration =
      value === 'na' ? 'line-through' : 'none';
    setter(prev => ({ ...prev, [name]: value }));
  };

  const handleEngine = handler(setEngineValues);
  const handleTransmission = handler(setTransmissionTransferCaseValues);
  const handleDriveAxle = handler(setDriveAxleValues);
  const handleSteering = handler(setSteeringValues);
  const handleBrakeSystem = handler(setBrakeSystemValues);
  const handleElectricalSystem = handler(setElectricalSystemValues);
  const handleAirConditioning = handler(setAirConditioningValues);
  const handleSuspension = handler(setFrontRearSuspensionValues);
  const handleCoolingFuelSystem = handler(setCoolingFuelSystemValues);
  const handleVehicleModifications = handler(setVehicleModificationsValues);

  const [consent, setConsent] = useState(false);
  const handleConsent = () => {
    setConsent(consent => !consent);
  };

  const [confirm, setConfirm] = useState(false);
  const handleConfirm = () => {
    setConfirm(confirm => !confirm);
  };

  const wholeForm = useRef(null);

  const handleSubmit = e => {
    e.preventDefault();

    if (!wholeForm.current['amvic_number'].value) {
      enqueueSnackbar(`Please fill out 'Tech #'.`);
      return;
    }

    if (!consent) {
      enqueueSnackbar(`Please check 'I agree' and then submit the inspection!`);
      return;
    }

    const values = {
      ...engineValues,
      ...transmissionTransferCaseValues,
      ...driveAxleValues,
      ...steeringValues,
      ...brakeSystemValues,
      ...electricalSystemValues,
      ...airConditioningValues,
      ...frontRearSuspensionValues,
      ...coolingFuelSystemValues,
      ...vehicleModificationsValues,
    };
    const form_data = {
      vw: values,
      amvic_number: wholeForm.current['amvic_number'].value,
      explain_modification_1: wholeForm.current['explain_modification_1'].value,
      explain_modification_2: wholeForm.current['explain_modification_2'].value,
    };

    props.formData(form_data);
    props.formClose();
  };

  const GreenButton = withStyles({
    root: {
      color: '#fff',
      backgroundColor: '#198754',
      '&:hover': {
        backgroundColor: '#157347',
      },
    },
  })(Button);

  return (
    <form onSubmit={handleSubmit} ref={wholeForm}>
      <Card>
        <CardContent>
          <Box mb="10px">
            <Typography>
              <b>Legend</b>
            </Typography>
          </Box>
          <Box display="flex" p="10px">
            <Box display="flex" mr="20px">
              <CheckBox style={{ color: '#43a047', marginRight: '5px' }} />{' '}
              <Typography>Pass</Typography>
            </Box>
            <Box display="flex" mr="20px">
              <CheckBox style={{ color: '#FF8C00', marginRight: '5px' }} />{' '}
              <Typography>Reinsp/Pass</Typography>
            </Box>
            <Box display="flex" mr="20px">
              <CheckBox style={{ color: '#FF0000', marginRight: '5px' }} />{' '}
              <Typography>Needs Service</Typography>
            </Box>
          </Box>
        </CardContent>
      </Card>
      <FormAccordion
        title="Engine"
        fields={engine}
        checkboxValues={engineValues}
        checkboxHandleFunc={handleEngine}
      />
      <FormAccordion
        title="Transmission/Transfer Case"
        fields={transmissionTransferCase}
        checkboxValues={transmissionTransferCaseValues}
        checkboxHandleFunc={handleTransmission}
      />
      <FormAccordion
        title="Drive Axle"
        fields={driveAxle}
        checkboxValues={driveAxleValues}
        checkboxHandleFunc={handleDriveAxle}
      />
      <FormAccordion
        title="Steering"
        fields={steering}
        checkboxValues={steeringValues}
        checkboxHandleFunc={handleSteering}
      />
      <FormAccordion
        title="Brake System"
        fields={brakeSystem}
        checkboxValues={brakeSystemValues}
        checkboxHandleFunc={handleBrakeSystem}
      />
      <FormAccordion
        title="Electrical System"
        fields={electricalSystem}
        checkboxValues={electricalSystemValues}
        checkboxHandleFunc={handleElectricalSystem}
      />
      <FormAccordion
        title="Air Conditioning"
        fields={airConditioning}
        checkboxValues={airConditioningValues}
        checkboxHandleFunc={handleAirConditioning}
      />
      <FormAccordion
        title="Front & Rear Suspension"
        fields={frontRearSuspension}
        checkboxValues={frontRearSuspensionValues}
        checkboxHandleFunc={handleSuspension}
      />
      <FormAccordion
        title="Cooling & Fuel System"
        fields={coolingFuelSystem}
        checkboxValues={coolingFuelSystemValues}
        checkboxHandleFunc={handleCoolingFuelSystem}
      />
      <FormAccordion
        title="Vehicle Modifications"
        fields={vehicleModifications}
        checkboxValues={vehicleModificationsValues}
        checkboxHandleFunc={handleVehicleModifications}
      >
        <Grid item xs={12} style={{ padding: '1rem' }}>
          <TextField
            label="Explain Modification"
            name="explain_modification_1"
            style={{ width: '100%' }}
          />
        </Grid>
        <Grid item xs={12} style={{ padding: '1rem' }}>
          <TextField
            label="Explain Modification"
            name="explain_modification_2"
            style={{ width: '100%' }}
          />
        </Grid>
      </FormAccordion>
      <Typography style={{ marginTop: '15px' }}>
        The above listed vehicle has been inspected and all items marked as Pass
        or Re-inspection/Pass are in good mechanical condition. All repairs that
        were required to qualify for Re-inspection/Pass have been performed and
        meet this dealership’s satisfaction.
      </Typography>
      <FormControlLabel
        control={<Checkbox />}
        label="I agree"
        onChange={handleConsent}
        value={consent}
      />
      <br />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Technician Name"
            value={props.user.display_name}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Tech #"
            value={props.user.goUserProfile.profile.sales_licence}
            fullWidth
            name={'amvic_number'}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Date"
            value={new Date().toLocaleString()}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <Button
            type="button"
            variant="contained"
            style={{ marginRight: '15px' }}
            onClick={handleConfirm}
            color="default"
          >
            Cancel
          </Button>
          <Dialog
            open={confirm}
            onClose={handleConfirm}
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Current inspection will be closed and unsaved data will be gone.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleConfirm}>Cancel</Button>
              <Button onClick={props.formClose} color="secondary">
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
          <GreenButton type="submit" variant="contained">
            Save
          </GreenButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default VWInspectionForm;
