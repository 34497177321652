import React from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Drawer,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Radio,
  RadioGroup,
  useMediaQuery,
} from '@mui/material';

import { makeStyles } from '@mui/styles';

import {
  Close as CloseIcon,
  Done as DoneIcon,
  ExpandMore as ExpandMoreIcon,
  HighlightOff as HighlightOffIcon,
} from '@mui/icons-material';

/* external */

/* Material UI */
import { DatePicker } from '@mui/x-date-pickers';

const useStyles = makeStyles(theme => ({
  panel: {
    width: '100%',
    paddingLeft: '25px',
    paddingRight: '25px',
  },
  title: {
    fontSize: '20px',
    paddingBottom: '10px',
    fontWeight: 'bold',
  },
  section: {
    marginBottom: '10px',
    outlineColor: 'black',
  },
  iconSpacer: {
    paddingRight: '10px',
    width: '25px',
  },
  icon: {
    paddingRight: '10px',
    width: '25px',
    fontSize: 'small',
  },
  accordion: {
    marginBottom: '10px',
  },
  red: {
    color: 'red',
  },
}));

const READ_FILTER = { has_unread_messages: false };
const UNREAD_FILTER = { has_unread_messages: true };
const UNREPLIED_FILTER = { not_replied: true };

const STATUS_FILTERS = {
  all: {},
  read: READ_FILTER,
  unread: UNREAD_FILTER,
  unreplied: UNREPLIED_FILTER,
};

const FilterDrawer = ({
  operators,
  onClose,
  open,
  selectedFilters,
  setSelectedFilters,
  statusFilter,
  setStatusFilter,
  operatorList,
  setOperatorList,
  ...rest
}) => {
  const classes = useStyles();

  const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const handleStatusFilterChange = e => {
    setStatusFilter(e.target.value);
    delete selectedFilters?.has_unread_messages;
    delete selectedFilters?.not_replied;
    setSelectedFilters({
      ...selectedFilters,
      ...STATUS_FILTERS[e.target.value],
    });
  };

  const isSelected = _operator =>
    Boolean(
      operatorList?.find(
        operator => operator.display_name === _operator.display_name,
      ),
    );

  const addOperatorFilter = operator =>
    setOperatorList(prev => [...prev, operator]);

  const deleteOperatorFilter = operator =>
    setOperatorList(prev => prev.filter(x => x.username !== operator.username));

  const toggleOperator = operator =>
    isSelected(operator)
      ? deleteOperatorFilter(operator)
      : addOperatorFilter(operator);

  const handleClose = () => {
    onClose();
  };
  const handleResetButton = () => {
    setSelectedFilters({});
    setOperatorList(null);
  };

  return (
    <Drawer
      onClose={handleClose}
      open={open}
      PaperProps={mobile ? { style: { width: '100%' } } : undefined}
      {...rest}
    >
      <Box display="flex" justifyContent="flex-end">
        <IconButton onClick={handleClose} size="large">
          <CloseIcon />
        </IconButton>
      </Box>
      <Box className={classes.panel}>
        <Box className={classes.title}>Filters</Box>
        <Box color="red" className={classes.section}>
          <Button className={classes.red} onClick={() => handleResetButton()}>
            <HighlightOffIcon style={{ paddingRight: '5px' }} />
            RESET ALL
          </Button>
        </Box>
        <Box>
          <FormControl className={classes.section}>
            <FormLabel>Status</FormLabel>
            <RadioGroup
              value={statusFilter}
              onChange={handleStatusFilterChange}
            >
              <FormControlLabel value={'all'} control={<Radio />} label="All" />
              <FormControlLabel
                value={'unread'}
                control={<Radio />}
                label="Unread"
              />
              <FormControlLabel
                value={'read'}
                control={<Radio />}
                label="Read"
              />
              <FormControlLabel
                value={'unreplied'}
                control={<Radio />}
                label="Unreplied"
              />
            </RadioGroup>
          </FormControl>
          <Box className={classes.section}></Box>
          <Accordion className={classes.section}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              Operator
            </AccordionSummary>
            <AccordionDetails>
              <List>
                {operators
                  .sort((a, b) => a.display_name.localeCompare(b.display_name))
                  .map(operator => (
                    <ListItem
                      button
                      dense
                      selected={isSelected(operator)}
                      onClick={() => toggleOperator(operator)}
                      key={operator.username}
                    >
                      {isSelected(operator) ? (
                        <DoneIcon className={classes.icon} />
                      ) : (
                        <Box className={classes.iconSpacer} />
                      )}
                      <ListItemText
                        primary={`${operator.display_name || 'Unknown'}`}
                        style={{ minWidth: '140px' }}
                      />
                    </ListItem>
                  ))}
              </List>
            </AccordionDetails>
          </Accordion>

          <Accordion className={classes.section}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              Created Date
            </AccordionSummary>
            <AccordionDetails>
              <Box flexDirection="column" alignItems="stretch">
                <Box marginBottom="10px">
                  <DatePicker
                    autoOk
                    clearable
                    label="From"
                    format="YYYY-MM-DD"
                    onChange={date => {
                      delete selectedFilters?.date_from;
                      setSelectedFilters(({ from, ...rest }) => ({
                        date_from: date,
                        ...rest,
                      }));
                    }}
                    value={selectedFilters.date_from ?? null}
                    variant="dialog"
                  />
                </Box>
                <Box>
                  <DatePicker
                    autoOk
                    clearable
                    label="To"
                    format="YYYY-MM-DD"
                    onChange={date => {
                      delete selectedFilters?.date_to;
                      setSelectedFilters(({ to, ...rest }) => ({
                        date_to: date,
                        ...rest,
                      }));
                    }}
                    value={selectedFilters.date_to ?? null}
                    variant="dialog"
                  />
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
    </Drawer>
  );
};

export default FilterDrawer;
