import React from 'react';

import { formatPrice } from 'utils';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { useMediaQuery } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import gql from 'graphql-tag';

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  grossTotalHeader: {
    fontSize: 15,
    fontWeight: 'normal !important',
    wordWrap: 'break-word',
    paddingBottom: '5px',
  },
  grossTotalAmount: {
    fontSize: 15,
    fontWeight: 'bold',
    wordWrap: 'break-word',
  },
  gridAxis: {
    padding: theme.spacing(2, 2, 2, 2),
    textAlign: 'left',
    border: '1px',
    borderStyle: 'solid',
    borderColor: '#e0e0e0',
    borderRadius: '0px',
    boxShadow: 'none',
    fontSize: '16px',
    fontWeight: '600',
    wordWrap: 'break-word',
  },
  centerText: {
    textAlign: 'center',
  },
  tableCell: {
    maxWidth: '50px',
  },
}));

const HEADERS = {
  totalFrontGross: 'Total Front End Gross ',
  totalBackGross: 'Total Back End Gross',
  totalDealerGross: 'Total Dealer Gross',
  costAdjustmentsBackItems: 'Cost Adjustments (Front)',
  costAdjustmentsFrontItems: 'Cost Adjustments (Back)',
  serviceContract: 'Service Contract',
  insuranceItems: 'Insurance',
};

const DealRecap = ({ vehicle }) => {
  const classes = useStyles();
  const desktop = useMediaQuery(theme => theme.breakpoints.up('sm'));
  if (!vehicle.deal_recap) return null;
  return (
    <>
      <Grid
        container
        spacing={2}
        style={{ marginTop: '10px', marginBottom: '10px' }}
      >
        {['totalFrontGross', 'totalBackGross', 'totalDealerGross'].map(key => (
          <Grid item xs={4} style={{ minWidth: '250px' }}>
            <Box className={`${classes.gridAxis} ${classes.centerText}`}>
              <Box className={classes.grossTotalHeader}>{HEADERS[key]}</Box>
              <Box className={classes.grossTotalAmount}>
                {formatPrice(vehicle.deal_recap[key], { cents: true })}
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
      {[
        'costAdjustmentsBackItems',
        'costAdjustmentsFrontItems',
        'serviceContract',
        'insuranceItems',
      ].map(key => {
        return (
          <>
            <Typography className={classes.title} gutterBottom key={key}>
              {HEADERS[key]}
            </Typography>
            <TableContainer style={{ marginBottom: '20px' }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    {['Item', 'Sale', 'Cost', 'Gross'].map(field => (
                      <TableCell
                        align={field === 'Item' ? 'left' : 'right'}
                        className={classes.tableCell}
                      >
                        {field}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {key !== 'serviceContract' &&
                    vehicle.deal_recap[key] &&
                    vehicle.deal_recap[key].length > 0 &&
                    vehicle.deal_recap[key].map(obj => (
                      <TableRow>
                        {['name', 'sale', 'cost', 'gross'].map(field => (
                          <TableCell
                            align={field === 'name' ? 'left' : 'right'}
                            className={classes.tableCell}
                            style={
                              desktop
                                ? { padding: '6px 24px 6px 16px' }
                                : { padding: '2px 6px 2px 4px' }
                            }
                          >
                            {obj[field]}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  {key === 'serviceContract' &&
                    vehicle.deal_recap[key] != null && (
                      <TableRow>
                        {['name', 'sale', 'cost', 'gross'].map(field => (
                          <TableCell
                            align={field === 'name' ? 'left' : 'right'}
                            className={classes.tableCell}
                          >
                            {vehicle.deal_recap[key][field]}
                          </TableCell>
                        ))}
                      </TableRow>
                    )}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        );
      })}
    </>
  );
};

DealRecap.fragments = {
  customer: gql`
    fragment DealRecapFragment on Customer {
      vehicles {
        deal_recap {
          totalFrontGross
          totalBackGross
          totalDealerGross
          costAdjustmentsBackItems {
            name
            sale
            cost
            gross
          }
          costAdjustmentsFrontItems {
            name
            sale
            cost
            gross
          }
          serviceContract {
            name
            sale
            cost
            gross
          }
          insuranceItems {
            name
            sale
            cost
            gross
          }
        }
      }
    }
  `,
};

export default DealRecap;
