import axios from 'axios';
import qs from 'qs';
import * as Sentry from '@sentry/react';

import { URL } from '../../constants';
import { AuthenticationError, NetworkError, ServerError } from './errors';

export default class BaseApi {
  static handleError(error) {
    /* eslint-disable no-console */
    console.log('Error', error.message);
    console.log(error.config);
    /* eslint-enable no-console */
    let exception;
    if (error.response) {
      // The request was made, but the server responded with a status code
      // that falls out of the range of 2xx
      if (error.response.status === 401) {
        // Username/password is incorrect
        exception = new AuthenticationError(error);
      } else if (error.response.status >= 500) {
        exception = new ServerError(error);
      }
    } else {
      // Something happened in setting up the request that triggered an Error
      exception = new NetworkError(error);
    }
    Sentry.captureException(exception, {
      extra: {
        stuff: error,
      },
    });
    throw exception;
  }
}

const client = axios.create({
  baseURL: URL.auth,
});

/**
 *   @summary Api wrapper for communicating with auth service
 */
export class AuthApi extends BaseApi {
  static authenticate(username: string, password: string): Promise<string> {
    return client
      .post('/jwt', qs.stringify({ username, password }))
      .then((response: Object): string => response.data.access_token)
      .catch(error => AuthApi.handleError(error));
  }
}
