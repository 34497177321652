import React from 'react';

/* Material UI */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material';

const PaveOrManualSelectionBox = ({
  onClickPave,
  onClickManual,
  onCancel,
  ...rest
}) => {
  const theme = useTheme();
  return (
    <Box {...rest}>
      <Typography variant="h6">
        Create a new trade-in using one of the two methods below:
      </Typography>
      <Box textAlign="center" m={4}>
        <Button
          variant="contained"
          onClick={onClickPave}
          style={{ marginRight: theme.spacing(4) }}
          color="secondary"
        >
          Use PAVE
        </Button>
        <Button
          variant="contained"
          onClick={onClickManual}
          color="secondary"
          style={{ marginRight: theme.spacing(4) }}
        >
          Manual
        </Button>
        <Button
          variant="contained"
          onClick={onCancel}
          style={theme.actions.cancel}
        >
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

export default PaveOrManualSelectionBox;
