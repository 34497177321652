import React from 'react';

/* external */
import { Switch, Route } from 'react-router-dom';

/* internal */
import { URL } from '../../../constants';
import app from '../../app';
import AuctionCreate from './auctions/AuctionCreate';
import AuctionDetails from './auctions/AuctionDetails';
import AuctionEdit from './auctions/AuctionEdit';
import AuctionsTable from './auctions/AuctionsTable';
import ItemDetails from './items/ItemDetails';
import TimeSyncContext from 'components/MaterialUI/TimeSyncContext';

const Auctions = ({ match }) => {
  return (
    <TimeSyncContext interval={2000} server={URL.auctionsTime}>
      <app.components.Layout title="Auctions">
        <Switch>
          <Route exact path={`${match.url}/`} component={AuctionsTable} />
          <Route
            exact
            path={`${match.url}/:id(\\d+)`}
            render={({ match: matched }) => (
              <AuctionDetails id={parseInt(matched.params.id, 10)} />
            )}
          />
          <Route
            exact
            path={`${match.url}/items/:id`}
            render={({ match: matched }) => (
              <ItemDetails id={parseInt(matched.params.id, 10)} />
            )}
          />
          <Route exact path={`${match.url}/new`} component={AuctionCreate} />
          <Route
            exact
            path={`${match.url}/edit/:id`}
            render={({ match: matched }) => (
              <AuctionEdit id={parseInt(matched.params.id, 10)} />
            )}
          />
        </Switch>
      </app.components.Layout>
    </TimeSyncContext>
  );
};

export default Auctions;
