import gql from 'graphql-tag';
import React from 'react';
import { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';

import {
  Box,
  CircularProgress,
  Fab,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { escapeRegExp, stripHTML } from 'utils';

import { errorHandler } from 'modules/auctions/utils';

import DeleteItemForm from './DeleteItemForm';
import CSVExporter from './CSVExporter';

const ITEMS_QUERY = gql`
  query getItems(
    $page: Int!
    $pageSize: Int
    $filters: [QueryFilter]
    $sort: [QuerySortElement]
  ) {
    auctions {
      items(page: $page, pageSize: $pageSize, filters: $filters, sort: $sort) {
        results {
          id
          vehicle {
            id
            make
            model
            cost
            vin
            year
            odometer
            dealer {
              name
            }
            transmission
            driveType
            engineConfig
            engineCompressor
            engineCylinders
            engineLitres
            fuelType
            exteriorColourName
            interiorColourName
            notes
            trim
          }
          minimumBid
          startsAt
          createdByUser {
            display_name
            profile {
              email_address
            }
          }
        }
        pagination {
          nextPage
          total
        }
      }
    }
  }
`;

const DELETE_BULK_AUCTION_ITEMS = gql`
  mutation deleteBulkAuctionItems($ids: [Int!]!, $deletionReason: String!) {
    auctions {
      deleteBulkAuctionItems(ids: $ids, deletionReason: $deletionReason) {
        id
        deleted
        deletionReason
      }
    }
  }
`;

const csvFields = new Map([
  ['Make', data => data.vehicle?.make],
  ['Model', data => data.vehicle?.model],
  ['Year', data => data.vehicle?.year],
  ['Trim', data => data.vehicle?.trim],
  ['Odometer', data => data.vehicle?.odometer],
  ['Starting Bid', data => data.minimumBid],
  ['VIN', data => data.vehicle?.vin],
  ['Dealership', data => data.vehicle?.dealer.name],
  ['Transmission', data => data.vehicle?.transmission],
  ['Drive Type', data => data.vehicle?.driveType],
  [
    'Engine',
    data =>
      [
        data.vehicle.engineLitres ? `${data.vehicle.engineLitres}L` : '',
        data.vehicle.engineConfig && data.vehicle.engineCylinders
          ? `${data.vehicle.engineConfig}${data.vehicle.engineCylinders}`
          : ' ',
        data.vehicle.engineCompressor,
      ].join(' '),
  ],
  ['Fuel Type', data => data.vehicle?.fuelType],
  ['Exterior Colour', data => data.vehicle?.exteriorColourName],
  ['Interior Colour', data => data.vehicle?.interiorColourName],
  ['Notes', data => escapeRegExp(stripHTML(data.vehicle?.notes))],
  ['Start Date', data => data.startsAt],
  ['Posted By', data => data.createdByUser?.display_name],
  ['Email', data => data.createdByUser?.profile?.email_address],
]);

export const AuctionBulkActions = ({ checkedIds, ...props }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [showDeleteForm, setShowDeleteForm] = useState(false);
  const { enqueueSnackbar: snackIt } = useSnackbar();

  const [
    deleteBulkAuctionItems,
    { loading: deletingItems, errors: deletingItemsErrors },
  ] = useMutation(DELETE_BULK_AUCTION_ITEMS, {
    onError: err => errorHandler(snackIt, () => {})(err),
  });

  const handleAnchorClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleAnchorClose = () => {
    setAnchorEl(null);
  };

  const [progress, setProgress] = useState(0);

  const handleClickDelete = e => {
    setShowDeleteForm(true);
    handleAnchorClose();
  };

  const handleSubmitDelete = ({ deletionReason }) => {
    const ids = Array.from(checkedIds);
    deleteBulkAuctionItems({
      variables: { ids, deletionReason },
    }).then(() => setShowDeleteForm(false));
  };
  return (
    <>
      <DeleteItemForm
        isOpen={showDeleteForm}
        submitErrors={deletingItemsErrors}
        onClose={() => setShowDeleteForm(false)}
        onSubmit={handleSubmitDelete}
      />
      <Box paddingRight={2}>
        <Tooltip title="Bulk Actions">
          <Fab color="primary" onClick={handleAnchorClick} size="medium">
            <MoreVertIcon />
          </Fab>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleAnchorClose}
      >
        <CSVExporter
          render={({ loadCSV, pending }) => (
            <>
              {!pending && (
                <MenuItem onClick={loadCSV}>
                  <ListItemText>Generate CSV</ListItemText>
                </MenuItem>
              )}
              {pending && (
                <MenuItem disabled>
                  <ListItemIcon style={{ minWidth: '25px' }}>
                    <CircularProgress size={20} />
                  </ListItemIcon>
                  <ListItemText
                    primary={`Generating CSV (${Math.round(progress)}%)`}
                  />
                </MenuItem>
              )}
            </>
          )}
          onProgress={progress => setProgress(100 * progress)}
          query={ITEMS_QUERY}
          getResults={data => data.auctions.items.results}
          getPagination={data => data.auctions.items.pagination}
          csvFields={csvFields}
          {...props}
        />
        <MenuItem
          disabled={checkedIds.size === 0 || deletingItems}
          onClick={handleClickDelete}
        >
          Delete items
        </MenuItem>
      </Menu>
    </>
  );
};
