import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { green } from '@mui/material/colors';
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';
import TextField from '@mui/material/TextField';

const CREATE_CUSTOMER = gql`
  mutation addCustomer($customer: CreateCustomerInput!) {
    createCustomer(input: $customer) {
      _id
    }
  }
`;

const ADD_RECORD = gql`
  mutation addRecordToCustomer($_id: ID!, $records: [String]!) {
    attachCustomerRecords(_id: $_id, records: $records) {
      _id
      records
    }
  }
`;

const useStyles = makeStyles(theme => ({
  closeButton: {
    color: '#24a0ed',
  },
  saveButton: {
    backgroundColor: green[500],
  },
}));

const AddCustomerFlexModal = ({ handleClose, messengerId }) => {
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [firstError, setFirstError] = useState(false);
  const [lastError, setLastError] = useState(false);
  const [addRecord] = useMutation(ADD_RECORD, {
    onCompleted: data => {
      enqueueSnackbar('Customer created and record attached!', {
        variant: 'success',
      });
      history.push(`/customers/${data.attachCustomerRecords._id}/details`);
    },
    onError: error =>
      enqueueSnackbar(`An error occured: ${error}`, {
        variant: 'error',
      }),
  });
  const [addCustomer, { loading }] = useMutation(CREATE_CUSTOMER, {
    onCompleted: data => {
      if (messengerId) {
        addRecord({
          variables: {
            _id: data.createCustomer._id,
            records: [`flex:facebook_message:${messengerId}`],
          },
        });
      } else {
        enqueueSnackbar(`Customer created, but no record attached`, {
          variant: 'info',
        });
      }
    },
    onError: error => {
      enqueueSnackbar(`An Error occured: ${error}`, {
        variant: 'error',
      });
    },
  });

  const handleFirstName = event => {
    setFirstName(event.target.value);
  };

  const handleLastName = event => {
    setLastName(event.target.value);
  };

  const handleCompanyName = event => {
    setCompanyName(event.target.value);
  };

  const handleSubmit = () => {
    firstName === '' ? setFirstError(true) : setFirstError(false);
    lastName === '' ? setLastError(true) : setLastError(false);
    if (firstName !== '' && lastName !== '') {
      addCustomer({
        variables: {
          customer: {
            first_name: firstName,
            last_name: lastName,
            company_name: companyName !== '' ? companyName : undefined,
          },
        },
      });
    } else {
      enqueueSnackbar('New Customer requires first and last name');
    }
  };

  return (
    <>
      <DialogTitle>Create a new Customer</DialogTitle>
      <DialogContent>
        <TextField
          label="First Name"
          value={firstName}
          onChange={handleFirstName}
          error={firstError}
        />
      </DialogContent>
      <DialogContent>
        <TextField
          label="Last Name"
          value={lastName}
          onChange={handleLastName}
          error={lastError}
        />
      </DialogContent>
      <DialogContent>
        <TextField
          label="Company Name"
          value={companyName}
          onChange={handleCompanyName}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} className={classes.closeButton}>
          Close
        </Button>
        <Button
          onClick={handleSubmit}
          style={theme.actions.confirm}
          variant="contained"
        >
          Create
        </Button>
      </DialogActions>
      <LoadingBackdrop open={loading} />
    </>
  );
};

export default AddCustomerFlexModal;
