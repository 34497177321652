import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/react-hooks';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import CBBValueTable from '../../tables/CBBValueTable';

const VALUATION_VEHICLES_QUERY = gql`
  query ValuationVehiclesQuery(
    $vin: String
    $year: Int
    $make_name: String
    $model_name: String
    $trim: String
    $style: String
  ) {
    valuation {
      getVehicles(
        vin: $vin
        year: $year
        make: $make_name
        model: $model_name
        trim: $trim
        style: $style
      ) {
        options {
          option_code
          description
        }
      }
    }
  }
`;

const propTypes = {
  vehicle: PropTypes.shape({
    cbb: PropTypes.shape({
      condition: PropTypes.string,
      equipment: PropTypes.string,
      style: PropTypes.string,
      trim: PropTypes.string,
      vid: PropTypes.number,
    }),
    make_name: PropTypes.string,
    model_name: PropTypes.string,
    odometer: PropTypes.number,
    vin: PropTypes.string,
    year: PropTypes.number,
  }),
};

const defaultProps = {
  vehicle: {},
};

const GroupInventoryCBBDetails = ({ vehicle }) => {
  const { make_name, model_name, odometer, vin, year } = vehicle || {};

  // Can't destructure if nested object is null
  const cbbCondition = vehicle?.cbb?.condition;
  const cbbEquipment = vehicle?.cbb?.equipment;
  const cbbStyle = vehicle?.cbb?.style;
  const cbbTrim = vehicle?.cbb?.trim;
  const cbbVid = vehicle?.cbb?.vid;

  const [equipmentSelections, setEquipmentSelections] = useState([]);
  const [valuationVehicles, setValuationVehicles] = useState([]);

  const [getValuationVehicles, { loading, data }] = useLazyQuery(
    VALUATION_VEHICLES_QUERY,
  );

  useEffect(() => {
    if (vehicle) {
      // Some models have no (empty) trims
      if (cbbStyle && (cbbTrim || cbbTrim === '')) {
        getValuationVehicles({
          variables: {
            make_name,
            model_name,
            style: cbbStyle,
            trim: cbbTrim,
            year,
          },
          oncompleted: data => {
            if (data && data.valuation.getVehicles.length === 0) {
              // try again with just vin and year
              getValuationVehicles({
                variables: {
                  year,
                  vin,
                },
              });
            }
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getValuationVehicles, vehicle]);

  useEffect(() => {
    if (data) setValuationVehicles(data.valuation.getVehicles);
  }, [data]);

  useEffect(() => {
    if (valuationVehicles && valuationVehicles.length > 0) {
      // We always use the first vehicle, even if there are multiple results
      setEquipmentSelections(valuationVehicles[0].options);
    }
  }, [valuationVehicles]);

  const formatEquipment = () => {
    const equipmentDescriptions = equipmentSelections;

    if (loading) return 'Loading...';
    else if (cbbEquipment && equipmentDescriptions) {
      return equipmentSelections
        .filter(x => cbbEquipment.includes(x.option_code))
        .map(x => x.description)
        .join(', ');
    }

    return '';
  };

  return (
    <Box>
      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item xs={12} lg={2}>
          <p>
            <b>Trim: </b>
            {cbbTrim || 'N/A'}
          </p>
          <p>
            <b>Style: </b>
            {cbbStyle || 'N/A'}
          </p>
          <p>
            <b>Equipment: </b>
            {formatEquipment() || 'N/A'}
          </p>
        </Grid>
        <Grid item xs={12} lg={10}>
          <CBBValueTable
            condition={cbbCondition}
            equipment={cbbEquipment}
            odometer={odometer}
            vid={cbbVid}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

GroupInventoryCBBDetails.propTypes = propTypes;
GroupInventoryCBBDetails.defaultProps = defaultProps;

export default GroupInventoryCBBDetails;
