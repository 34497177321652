/* external */
import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';

/* Material UI */
import { Box, Grid } from '@mui/material';

/* internal */
import { LoadingBackdrop, useDealerContext } from 'components/MaterialUI';

import { FeatureGrids } from '../common';

import StepButtons from './StepButtons';

const UnitFeatures = ({ back, updateLoading, updateNpvVehicle, vehicle }) => {
  const { dealerId } = useDealerContext();
  const formMethods = useForm({
    defaultValues: {
      dealerId,
      featBath15: vehicle?.featBath15 ?? false,
      featBunkhouse: vehicle?.featBunkhouse ?? false,
      featFrontBath: vehicle?.featFrontBath ?? false,
      featFrontBedroom: vehicle?.featFrontBedroom ?? false,
      featFrontCargoDeck: vehicle?.featFrontCargoDeck ?? false,
      featFrontEntertainment: vehicle?.featFrontEntertainment ?? false,
      featFrontKitchen: vehicle?.featFrontKitchen ?? false,
      featFrontLivingArea: vehicle?.featFrontLivingArea ?? false,
      featKitchenIsland: vehicle?.featKitchenIsland ?? false,
      featLoft: vehicle?.featLoft ?? false,
      featMurphyBed: vehicle?.featMurphyBed ?? false,
      featOutdoorEntertainment: vehicle?.featOutdoorEntertainment ?? false,
      featOutdoorKitchen: vehicle?.featOutdoorKitchen ?? false,
      featRearBath: vehicle?.featRearBath ?? false,
      featRearBedroom: vehicle?.featRearBedroom ?? false,
      featRearEntertainment: vehicle?.featRearEntertainment ?? false,
      featRearKitchen: vehicle?.featRearKitchen ?? false,
      featRearLivingArea: vehicle?.featRearLivingArea ?? false,
      featRearTwin: vehicle?.featRearTwin ?? false,
      featSideRamp: vehicle?.featSideRamp ?? false,
      featTwoEntryExitDoors: vehicle?.featTwoEntryExitDoors ?? false,
      featTwoFullBaths: vehicle?.featTwoFullBaths ?? false,
      featWheelchairAccessible: vehicle?.featWheelchairAccessible ?? false,
    },
  });

  const onSubmit = data => {
    const variables = {
      id: vehicle.id,
      data,
    };
    updateNpvVehicle({ variables });
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <LoadingBackdrop open={updateLoading} />
        <Box margin={1}>
          <Grid container spacing={2}>
            <FeatureGrids />
          </Grid>
        </Box>
        <StepButtons back={back} />
      </form>
    </FormProvider>
  );
};

UnitFeatures.fragments = FeatureGrids.fragments;

export default UnitFeatures;
