import React from 'react';
import gql from 'graphql-tag';

import { GridWithLabel, GridItem, UintItem } from './GridWrappers';

export default function ServicesGrid() {
  return (
    <GridWithLabel label="Services">
      <GridItem label="Generator" name="specGenerator" />
      <UintItem label="A/C BTU" name="specAcBtu" />
      <UintItem label="Fresh Water Capacity" name="specFreshWaterGallons" />
      <UintItem label="Number of LP Tanks" name="specLpTankCount" />
      <UintItem label="Grey Water Capacity" name="specGreyWaterGallons" />
      <UintItem label="LP Tank Capacity" name="specLpTankPounds" />
      <UintItem label="Black Water Capacity" name="specBlackWaterGallons" />
      <UintItem label="Water Heater Capacity" name="specWaterHeaterGallons" />
      <UintItem label="Electrical Service" name="specElectricalServiceAmps" />
      <GridItem label="Water Heater Type" name="specWaterHeaterType" />
      <UintItem label="Furnace BTU" name="specFurnaceBtu" />
    </GridWithLabel>
  );
}

ServicesGrid.fragments = {
  vehicle: gql`
    fragment NpvVehicleSpecsServices on NpvVehicle {
      id
      specGenerator
      specFreshWaterGallons
      specGreyWaterGallons
      specBlackWaterGallons
      specElectricalServiceAmps
      specFurnaceBtu
      specAcBtu
      specLpTankCount
      specLpTankPounds
      specWaterHeaterGallons
      specWaterHeaterType
    }
  `,
};
