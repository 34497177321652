import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { displayValue, generateAverage } from './utils';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  inlineTableCell: {
    width: '100px',
    paddingLeft: '1rem',
  },
});

const DealLogTableMetricsRow = ({ metrics, stock_type }) => {
  const [open, setOpen] = useState(false);
  const styles = useRowStyles();
  const { inlineTableCell } = useRowStyles();

  const combinedMetrics = {
    writes:
      metrics[`${stock_type}_posted_count`] +
      metrics[`${stock_type}_delivered_count`],
    postedFrontAvg: generateAverage(
      metrics[`posted_${stock_type}_frontend_gross`],
      metrics[`${stock_type}_posted_count`],
    ),
    deliveredFrontAvg: generateAverage(
      metrics[`delivered_${stock_type}_frontend_gross`],
      metrics[`${stock_type}_delivered_count`],
    ),
    postedBackAvg: generateAverage(
      metrics[`posted_${stock_type}_backend_gross`],
      metrics[`${stock_type}_posted_count`],
    ),
    deliveredBackAvg: generateAverage(
      metrics[`delivered_${stock_type}_backend_gross`],
      metrics[`${stock_type}_delivered_count`],
    ),
  };

  return (
    <>
      <TableRow className={styles.root}>
        <TableCell>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          <b>Sold</b>
        </TableCell>
        <TableCell className={inlineTableCell}>
          {combinedMetrics.writes}
        </TableCell>
        <TableCell className={inlineTableCell}>
          {displayValue(
            combinedMetrics.postedFrontAvg + combinedMetrics.deliveredFrontAvg,
          )}
        </TableCell>
        <TableCell className={inlineTableCell}>
          {displayValue(
            combinedMetrics.postedBackAvg + combinedMetrics.deliveredBackAvg,
          )}
        </TableCell>
        <TableCell className={inlineTableCell}>
          {displayValue(
            combinedMetrics.postedFrontAvg +
              combinedMetrics.deliveredFrontAvg +
              combinedMetrics.postedBackAvg +
              combinedMetrics.deliveredBackAvg,
          )}
        </TableCell>
        <TableCell className={inlineTableCell}>
          {displayValue(
            metrics[`posted_${stock_type}_frontend_gross`] +
              metrics[`delivered_${stock_type}_frontend_gross`] +
              metrics[`posted_${stock_type}_backend_gross`] +
              metrics[`delivered_${stock_type}_backend_gross`],
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <Table size="small">
                <TableBody style={{ width: '100%' }}>
                  <TableRow style={{ width: '100%' }}>
                    <TableCell style={{ paddingLeft: '35px' }}>
                      Posted
                    </TableCell>
                    <TableCell className={inlineTableCell}>
                      {metrics[`${stock_type}_posted_count`]}
                    </TableCell>
                    <TableCell className={inlineTableCell}>
                      {displayValue(combinedMetrics.postedFrontAvg)}
                    </TableCell>
                    <TableCell className={inlineTableCell}>
                      {displayValue(combinedMetrics.postedBackAvg)}
                    </TableCell>
                    <TableCell className={inlineTableCell}>
                      {displayValue(
                        combinedMetrics.postedFrontAvg +
                          combinedMetrics.postedBackAvg,
                      )}
                    </TableCell>
                    <TableCell className={inlineTableCell}>
                      {displayValue(
                        metrics[`posted_${stock_type}_frontend_gross`] +
                          metrics[`posted_${stock_type}_backend_gross`],
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow style={{ width: '100%' }}>
                    <TableCell style={{ paddingLeft: '35px' }}>
                      Delivered
                    </TableCell>
                    <TableCell className={inlineTableCell}>
                      {metrics[`${stock_type}_delivered_count`]}
                    </TableCell>
                    <TableCell className={inlineTableCell}>
                      {displayValue(combinedMetrics.deliveredFrontAvg)}
                    </TableCell>
                    <TableCell className={inlineTableCell}>
                      {displayValue(combinedMetrics.deliveredBackAvg)}
                    </TableCell>
                    <TableCell className={inlineTableCell}>
                      {displayValue(
                        combinedMetrics.deliveredFrontAvg +
                          combinedMetrics.deliveredBackAvg,
                      )}
                    </TableCell>
                    <TableCell className={inlineTableCell}>
                      {displayValue(
                        metrics[`delivered_${stock_type}_frontend_gross`] +
                          metrics[`delivered_${stock_type}_backend_gross`],
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default DealLogTableMetricsRow;
