import React, { useEffect } from 'react';
import moment from 'moment';

/* external */
import { camelCase, startCase } from 'lodash';
import { useLazyQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import makeStyles from '@mui/styles/makeStyles';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import Drawer from '@mui/material/Drawer';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { DatePicker } from '@mui/x-date-pickers';

const FACETS_QUERY = gql`
  query vehiclesFacets($filters: [QueryFilter], $facets: [QueryFacet!]!) {
    carSense {
      vehiclesFacets(filters: $filters, facets: $facets) {
        field
        model
        data {
          value
          count
        }
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  panel: {
    width: '100%',
    paddingLeft: '25px',
    paddingRight: '25px',
  },
  title: {
    fontSize: '20px',
    paddingBottom: '10px',
    fontWeight: 'bold',
  },
  section: {
    paddingBottom: '10px',
    outlineColor: 'black',
  },
  iconSpacer: {
    paddingRight: '10px',
    width: '25px',
  },
  icon: {
    paddingRight: '10px',
    width: '25px',
    fontSize: 'small',
  },
  accordion: {
    marginBottom: '10px',
  },
  red: {
    color: 'red',
  },
}));

const FACET_DEFINITIONS = [{ model: 'Vehicle', field: 'status' }];

const FilterDrawer = ({
  facetDefinitions = FACET_DEFINITIONS,
  filters,
  setFacetFilters,
  facetFilters,
  onClose,
  open,
  biddingStartsAtFilter,
  biddingEndsAtFilter,
  setBiddingStartsAtFilter,
  setBiddingEndsAtFilter,
  ...rest
}) => {
  const classes = useStyles();
  const facets = facetDefinitions.map(({ model, field }) => ({ model, field }));
  const [getFacets, { data }] = useLazyQuery(FACETS_QUERY, {
    variables: {
      filters,
      facets,
    },
    fetchPolicy: 'network-only',
  });

  // Reload the facets whenever we open the drawer
  useEffect(() => {
    if (open) getFacets();
  }, [getFacets, open]);

  const facetResults = data?.carSense.vehiclesFacets || [];

  const isSelected = (_model, _field, _value) =>
    Boolean(
      facetFilters.find(
        ({ model, field, value }) =>
          model === _model && field === _field && value === _value,
      ),
    );

  const addFacetFilter = (model, field, value) =>
    setFacetFilters(prev => [...prev, { model, field, value }]);

  const deleteFacetFilter = (_model, _field, _value) =>
    setFacetFilters(prev =>
      prev.filter(
        ({ model, field, value }) =>
          model !== _model || field !== _field || value !== _value,
      ),
    );
  const toggleFacet = (_model, _field, _value) =>
    isSelected(_model, _field, _value)
      ? deleteFacetFilter(_model, _field, _value)
      : addFacetFilter(_model, _field, _value);

  const getOptions = (_model, _field) =>
    facetDefinitions.find(x => x.model === _model && x.field === _field)
      ?.options;

  return (
    <Drawer onClose={onClose} open={open} {...rest}>
      <Box display="flex" justifyContent="flex-end">
        <IconButton onClick={onClose} size="large">
          <CloseIcon />
        </IconButton>
      </Box>
      <Box className={classes.panel}>
        <Box className={classes.title}>Filters</Box>
        <Box color="red">
          <Button className={classes.red} onClick={() => setFacetFilters([])}>
            <HighlightOffIcon style={{ paddingRight: '5px' }} />
            RESET ALL
          </Button>
        </Box>
        <Box>
          {facetResults.map(({ field, model, data }) => (
            <Accordion key={field + model} className={classes.accordion}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                {getOptions(model, field)?.label ?? startCase(camelCase(field))}
              </AccordionSummary>
              <AccordionDetails>
                <List>
                  {data.map(({ value, count }) => (
                    <ListItem
                      button
                      dense
                      selected={isSelected(model, field, value)}
                      onClick={() => toggleFacet(model, field, value)}
                      key={value}
                    >
                      {isSelected(model, field, value) ? (
                        <DoneIcon className={classes.icon} />
                      ) : (
                        <Box className={classes.iconSpacer} />
                      )}
                      <ListItemText
                        primary={`${value || 'Unknown'} (${count})`}
                        style={{ minWidth: '140px' }}
                      />
                    </ListItem>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
        <Box style={{ paddingBottom: '10px', maxWidth: '100%' }}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              Bidding
            </AccordionSummary>
            <AccordionDetails>
              <Box flexDirection="column" alignItems="stretch">
                <Box>Starts At</Box>
                <Box>
                  <DatePicker
                    autoOk
                    clearable
                    emptyLabel=""
                    format="YYYY-MM-DD"
                    onChange={date => setBiddingStartsAtFilter(date)}
                    value={moment(biddingStartsAtFilter)}
                    variant="dialog"
                  />
                </Box>
                <Box>Ends At</Box>
                <Box>
                  <DatePicker
                    autoOk
                    clearable
                    emptyLabel=""
                    format="YYYY-MM-DD"
                    onChange={date => setBiddingEndsAtFilter(date)}
                    value={moment(biddingEndsAtFilter)}
                    variant="dialog"
                  />
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
    </Drawer>
  );
};

export default FilterDrawer;
