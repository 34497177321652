/* external */
import React from 'react';
import { useHistory } from 'react-router-dom';

/* Material UI */
import {
  Card,
  CardContent,
  Divider,
  Grid,
  Hidden,
  Typography,
} from '@mui/material';

/* internal */
import { titleCase, formatPrice, getFullDateTime, withSuffix } from 'utils';
import { useUserContext } from 'components/MaterialUI/UserContext';
import InventoryIconGroup from './InventoryIconGroup';

const InventoryListMobile = ({ vehicles }) => {
  const history = useHistory();
  const { me } = useUserContext();

  function LabelValueGrid({ label, children, ...rest }) {
    return (
      <Grid item xs={4} {...rest}>
        <Typography variant="subtitle2" component="p">
          <strong>{label}</strong>
        </Typography>
        <Typography variant="subtitle1" component="span">
          {children}
        </Typography>
      </Grid>
    );
  }

  return (
    <>
      {vehicles.map(vehicle => (
        <Card
          style={{ marginBottom: '10px' }}
          key={vehicle.id}
          onClick={() => history.push(`/npv-inventory/${vehicle.id}`)}
        >
          <CardContent>
            <Grid container>
              <Grid item xs={8} sm={4}>
                <Typography variant="subtitle1">
                  <strong>{vehicle.displayName}</strong>
                </Typography>
                <Typography variant="subtitle2">
                  {`Manufacturer: ${vehicle?.model?.make?.manufacturer?.name}`}
                </Typography>
                <Typography variant="subtitle2">
                  {`Type: ${vehicle?.model?.npvType?.name} / Model: ${vehicle?.model?.name}`}
                </Typography>
              </Grid>
              <Grid item xs={4} style={{ textAlign: 'center' }}>
                <Typography variant="subtitle2">
                  {vehicle.stockNumber}
                </Typography>
                <Typography variant="subtitle2" style={{ color: '#999999' }}>
                  {`${vehicle.stockType} / ${titleCase(
                    vehicle?.stockStatus?.name,
                  )}`}
                </Typography>
              </Grid>
              {/* Hide on smallest screens */}
              <Hidden smDown>
                <Grid item xs={4}>
                  <Typography variant="subtitle1">
                    <strong>Photos:</strong>
                    {` ${vehicle?.photoCount}`}
                  </Typography>
                  <Typography variant="subtitle1" component="p">
                    <strong>Days in Stock:</strong> {`${vehicle?.daysInStock}`}
                  </Typography>
                </Grid>
              </Hidden>
              {/* Use for smallest screens*/}
              <Hidden smUp>
                <Grid item xs={4}>
                  <Typography variant="subtitle2" component="p">
                    <strong>Photos:</strong>
                    {` ${vehicle?.photoCount}`}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="subtitle2" component="p">
                    <strong>Days in Stock:</strong> {`${vehicle?.daysInStock}`}
                  </Typography>
                </Grid>
              </Hidden>
            </Grid>
          </CardContent>
          <Divider />
          <CardContent>
            <Grid container>
              {/* Hide on smallest screens */}
              <Hidden smDown>
                <Grid item xs={4}>
                  <Typography variant="subtitle" component="p">
                    <strong>Cost:</strong>
                    {` ${formatPrice(vehicle?.cost)}`}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="subtitle1" component="p">
                    <strong>Price:</strong>
                    {` ${formatPrice(vehicle?.regularPrice)}`}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="subtitle1" component="p">
                    <strong>Special Price:</strong>
                    {vehicle.isOnSpecial
                      ? ` ${formatPrice(vehicle?.specialPrice)}`
                      : `-`}
                  </Typography>
                </Grid>
              </Hidden>
              {/* Use on smallest screens */}
              <Hidden smUp>
                <LabelValueGrid label="Cost:">
                  {formatPrice(vehicle?.cost)}
                </LabelValueGrid>
                <LabelValueGrid label="Price:">
                  {formatPrice(vehicle?.regularPrice)}
                </LabelValueGrid>
                <LabelValueGrid label="Special Price:">
                  {vehicle.isOnSpecial
                    ? formatPrice(vehicle?.specialPrice)
                    : '-'}
                </LabelValueGrid>
              </Hidden>
            </Grid>
          </CardContent>
          <Divider />
          <CardContent>
            <Grid container>
              <Grid item xs={4}>
                <InventoryIconGroup vehicle={vehicle} />
              </Grid>
              {/* Hide on smallest screens */}
              <Hidden smDown>
                <Grid item xs={8}>
                  <Typography variant="subtitle1">
                    <strong>Updated:</strong>
                    {` ${getFullDateTime(
                      withSuffix(vehicle.modifiedAt, 'Z'),
                      me?.goUserProfile?.settings?.timezone,
                    )}`}
                  </Typography>
                </Grid>
              </Hidden>
              <Hidden smUp>
                <Grid item xs={8}>
                  <Typography variant="subtitle2">
                    <strong>Updated:</strong>
                  </Typography>
                  <Typography variant="subtitle2">
                    {getFullDateTime(
                      withSuffix(vehicle.modifiedAt, 'Z'),
                      me?.goUserProfile?.settings?.timezone,
                    )}
                  </Typography>
                </Grid>
              </Hidden>
            </Grid>
          </CardContent>
        </Card>
      ))}
    </>
  );
};

export default InventoryListMobile;
