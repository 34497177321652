import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

import withRequiresLogin from './withRequiresLogin';
import withRequiresUserRole from './withRequiresUserRole';
import * as Sentry from '@sentry/react';

// Create Custom Sentry Route component
const SentryRoute = Sentry.withSentryRouting(Route);

const propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};

const PrivateRoute = ({ component, ...rest }) => (
  <SentryRoute
    {...rest}
    render={props =>
      React.createElement(
        withRequiresLogin(withRequiresUserRole(component)),
        props,
      )
    }
  />
);

PrivateRoute.propTypes = propTypes;

export default PrivateRoute;
