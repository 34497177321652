import React from 'react';

/* external */
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

/* internal */
import ErrorPage from 'components/MaterialUI/ErrorPage';
import Loading from 'components/MaterialUI/Loading';
import PitchForm from './PitchForm';

const GET_PITCH = gql`
  query getPitch($id: Int!) {
    pitches {
      pitch(id: $id) {
        id
        ...PitchFormPitch
      }
    }
  }
  ${PitchForm.fragments.pitch}
`;

const PitchFormContainer = ({
  customerVehicles,
  id,
  onCancel,
  opportunity,
  pitchEditState,
  setPitchEditState,
  refetch,
}) => {
  const { data, error, loading } = useQuery(GET_PITCH, {
    variables: { id },
    skip: !id,
    fetchPolicy: 'network-only',
  });

  const pitch = data?.pitches.pitch;

  if (loading) return <Loading />;
  if (error) return <ErrorPage error={error} action="Loading Pitch" />;

  return (
    <PitchForm
      onCancel={onCancel}
      opportunity={opportunity}
      pitch={pitch}
      customerVehicles={customerVehicles}
      pitchEditState={pitchEditState}
      setPitchEditState={setPitchEditState}
      refetch={refetch}
    />
  );
};

PitchFormContainer.fragments = {
  opportunity: gql`
    fragment PitchFormContainerOpportunity on Opportunity {
      ...PitchFormOpportunity
    }
    ${PitchForm.fragments.opportunity}
  `,
};

export default PitchFormContainer;
