import React, { useMemo, useCallback } from 'react';

import { Scatter } from 'react-chartjs-2';
import {
  linearRegression,
  kFormatter,
  phoneStrip,
  dealerPhoneNumbers,
} from '../../utils';
import gql from 'graphql-tag';

const ScatterChart = ({ listingsData, dealers, vehicle, listPrice }) => {
  const orgPhoneNumbers = useMemo(() => dealerPhoneNumbers(dealers), [dealers]);

  const isGoAutoDealer = useCallback(
    listing => orgPhoneNumbers.includes(phoneStrip(listing.dealer_phone)),
    [orgPhoneNumbers],
  );

  const bestFitData = useMemo(() => {
    let x = listingsData.map(l => l.mileage);
    let y = listingsData.map(l => l.price);
    const f = linearRegression(x, y);
    return listingsData
      .map(l => ({
        x: l.mileage,
        y: f(l.mileage),
      }))
      .concat([{ x: vehicle.odometer, y: f(vehicle.odometer) }])
      .sort((a, b) => a.x - b.x);
  }, [listingsData, vehicle.odometer]);

  const options = useMemo(
    () => ({
      tooltips: {
        filter: item => item?.datasetIndex !== 3,
        callbacks: {
          label: () => '',
          afterBody: ([item]) => {
            let listingVehicle = null;

            if (item?.datasetIndex === 0) {
              return [
                vehicle?.dealer?.dealer_name || vehicle?.dealer_name,
                `List Price: $${listPrice.toLocaleString('en-US')}`,
                `Odometer ${vehicle?.odometer?.toLocaleString('en-US')}`,
                `VIN: ${vehicle?.vin}`,
              ];
            } else if (item?.datasetIndex === 1) {
              listingVehicle = listingsData.filter(l => isGoAutoDealer(l))[
                item.index
              ];
            } else if (item?.datasetIndex === 2) {
              listingVehicle = listingsData.filter(l => !isGoAutoDealer(l))[
                item.index
              ];
            }

            if (!listingVehicle) return [];

            return [
              listingVehicle.dealer_name,
              `List Price: $${listingVehicle?.price?.toLocaleString('en-US')}`,
              `Odometer ${listingVehicle?.mileage?.toLocaleString('en-US')}`,
              `VIN: ${listingVehicle?.vin}`,
            ];
          },
        },
      },
      legend: {
        position: 'bottom',
        labels: {
          usePointStyle: true,
          filter: legendItem => legendItem.text !== 'Trendline',
        },
      },
      scales: {
        xAxes: [
          {
            type: 'linear',
            scaleLabel: {
              display: true,
              labelString: 'Odometer',
              fontSize: 16,
              fontStyle: 'bold',
              fontColor: 'black',
            },
            position: 'bottom',
            ticks: {
              callback: value => kFormatter(value),
            },
          },
        ],
        yAxes: [
          {
            type: 'linear',
            scaleLabel: {
              display: true,
              labelString: 'Price',
              fontSize: 16,
              fontStyle: 'bold',
              fontColor: 'black',
            },
            ticks: {
              callback: value => `$${kFormatter(value)}`,
            },
          },
        ],
      },
      animation: {
        duration: 0,
      },
    }),
    [listingsData, isGoAutoDealer, listPrice, vehicle],
  );

  return (
    <Scatter
      options={options}
      data={{
        labels: listingsData.map(l => l.mileage),
        datasets: [
          {
            label: 'Current Vehicle',
            backgroundColor: '#2E96EB',
            borderColor: '#2E96EB',
            pointHoverColor: '#2E96EB',
            pointHoverBackgroundColor: '#2E96EB',
            type: 'scatter',
            data: [{ x: vehicle.odometer, y: listPrice }],
            radius: 7,
            hoverRadius: 9,
          },
          {
            label: 'Go Auto Vehicle',
            backgroundColor: '#F9810F',
            borderColor: '#F9810F',
            pointHoverColor: '#F9810F',
            pointHoverBackgroundColor: '#F9810F',
            type: 'scatter',
            data: listingsData
              .filter(l => isGoAutoDealer(l))
              .map(l => ({ x: l.mileage, y: l.price })),
            radius: 4,
            hoverRadius: 6,
          },
          {
            label: 'Competitor Vehicle',
            backgroundColor: 'black',
            borderColor: 'black',
            pointHoverColor: 'black',
            pointHoverBackgroundColor: 'black',
            type: 'scatter',
            data: listingsData
              .filter(l => !isGoAutoDealer(l))
              .map(l => ({ x: l.mileage, y: l.price })),
            radius: 4,
            hoverRadius: 6,
          },

          {
            label: 'Trendline',
            backgroundColor: 'darkgray',
            type: 'line',
            data: bestFitData,
            pointStyle: false,
            pointRadius: 0,
            fill: false,
          },
        ],
      }}
    />
  );
};

ScatterChart.fragments = {
  vehicle: gql`
    fragment ScatterChartVehicle on GreaseInventoryVehicle {
      dealer_name
      dealer {
        dealer_name
      }
      odometer
      vin
    }
  `,
  listings: gql`
    fragment ScatterChartListingsResult on ListingType {
      mileage
      price
      dealer_name
    }
  `,
};

export default ScatterChart;
