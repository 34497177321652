import React from 'react';
import { Alert, AlertTitle } from '@mui/material';

export const FinishedDecodingAlert = () => {
  return (
    <Alert severity="success">
      <AlertTitle>No More Vehicles to Decode</AlertTitle>
      Either you skipped all of the vehicles or have decoded 100% of your
      inventory.
    </Alert>
  );
};

export const NoVINErrorAlert = () => {
  return (
    <Alert severity="error">
      <AlertTitle>Decoding Error</AlertTitle>
      No VIN Number for Vehicle, can't display styles
    </Alert>
  );
};

export const NoStylesErrorAlert = ({ vin }) => {
  return (
    <Alert severity="error">
      <AlertTitle>Decoding Error</AlertTitle>
      No styles for vehicle VIN: {vin}
    </Alert>
  );
};
