import React from 'react';

/* Material UI */
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

/* internal */
import GoldCardTable from './GoldCardTable';
import RedemptionTiles from './RedemptionTiles';

const useStyles = makeStyles(theme => ({
  blackDivider: {
    backgroundColor: 'black',
    margin: theme.spacing(1, 0, 1, 0),
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    padding: '1em 0 1em 0',
  },
}));

const GoldCard = ({ goldCards, refetch }) => {
  const classes = useStyles();
  return (
    <>
      <Typography className={classes.title}>
        Gold Card ({goldCards.length})
      </Typography>
      {goldCards.map((goldCard, index) => (
        <>
          <GoldCardTable goldCard={goldCard} refetch={refetch} />
          <RedemptionTiles goldCard={goldCard} />
          {goldCards[index + 1] ? (
            <Divider className={classes.blackDivider} />
          ) : (
            ''
          )}
        </>
      ))}
    </>
  );
};

export default GoldCard;
