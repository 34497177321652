import React from 'react';
import { Route, Switch } from 'react-router-dom';

import CodeTablesContext from 'components/MaterialUI/CodeTablesContext';
import { RoleGroup } from 'constants.js';
import { RequiresRoles } from 'modules/app/components';
import app from '../../app';
import CBBWizard from './cbb_wizard/CBBWizard';
import InventoryDashboard from './dashboard/InventoryDashboard';
import DecodeWizard from './decode_wizard/DecodeWizard';
import Syndication from './syndication/Syndication';
import GroupInventoryList from './tables/GroupInventoryList';
import InventoryList from './tables/InventoryList';
import GroupVehicleInfoMobile from './vehicle/GroupVehicleInfoMobile';
import VehicleInfo from './vehicle/VehicleInfo';

const Inventory = ({ match }) => (
  <CodeTablesContext>
    <Switch>
      <Route
        exact
        path={`${match.url}/`}
        render={() => (
          <app.components.Layout title="Inventory">
            <InventoryList />
          </app.components.Layout>
        )}
      />
      <Route
        exact
        path={`${match.url}/:id(\\d+)`}
        render={({ match }) => (
          <app.components.Layout title="Vehicle Details">
            <VehicleInfo id={parseInt(match.params.id, 10)} />
          </app.components.Layout>
        )}
      />
      <Route
        exact
        path={`${match.url}/decode-wizard`}
        render={() => (
          <app.components.Layout title="Decode Wizard">
            <DecodeWizard />
          </app.components.Layout>
        )}
      />
      <Route
        exact
        path={`${match.url}/cbb-wizard`}
        render={() => (
          <app.components.Layout title="Book Out Wizard">
            <CBBWizard />
          </app.components.Layout>
        )}
      />
      <Route
        exact
        path={`${match.url}/syndication`}
        render={() => (
          <app.components.Layout title="Inventory Syndication">
            <Syndication />
          </app.components.Layout>
        )}
      />
      <Route
        exact
        path={`${match.url}/dashboard`}
        render={() => (
          <app.components.Layout title="Inventory Dashboard">
            <InventoryDashboard />
          </app.components.Layout>
        )}
      />
      <Route
        exact
        path={`${match.url}/group/:id(\\d+)`}
        render={({ match }) => (
          <RequiresRoles
            requiredRoles={RoleGroup.GROUP_INVENTORY_ROLE}
            renderOnSuccess={() => (
              <app.components.Layout title="Group Inventory">
                <GroupVehicleInfoMobile
                  vehicleId={parseInt(match.params.id, 10)}
                />
              </app.components.Layout>
            )}
          />
        )}
      />
      <Route
        exact
        path={`${match.url}/group`}
        render={() => (
          <RequiresRoles
            requiredRoles={RoleGroup.GROUP_INVENTORY_ROLE}
            renderOnSuccess={() => (
              <app.components.Layout title="Group Inventory">
                <GroupInventoryList />
              </app.components.Layout>
            )}
          />
        )}
      />
    </Switch>
  </CodeTablesContext>
);

export default Inventory;
