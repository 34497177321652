export default [
  'vid',
  'dealer_id',
  'recon',
  'recon_complete',
  'selected_price',
  'is_detailed_pricing',
  'finance_rates',
  'lease_rates',
  'photos',
  'cbb',
  'style_id',
  'manufacturer_code',
  'trim_variation',
  'make_name',
  'model_name',
  'default_down_payment',
  'detailed_pricing',
  'bulk_detailed_pricing',
  'trim',
  'style',
  'condition',
  'regular_price',
  'special_price',
  'special_price_expires',
  'transmission_id',
  'drive_type_id',
  'exterior_colour_id',
  'exterior_colour_description',
  'exterior_colour_option_code',
  'exterior_colour_rgb_value',
  'engine_compressor_id',
  'fuel_type_id',
  'interior_colour_id',
  'interior_colour_description',
  'engine_config_id',
  'engine_cylinders',
  'engine_litres',
  'upholstery_id',
  'cost',
  'msrp',
  'promotional_position',
  'promotional_info',
  'promotinoal_start_date',
  'promotional_expiry_date',
  'private_notes',
  'stock_status_id',
  'is_loaner',
  'is_demo_unit',
  'in_transit',
  'is_featured',
  'is_certified',
  'certified_line_id',
  'video_url',
  'vin',
  'year',
  'make_id',
  'model_id',
  'trim',
  'published_trim',
  'published_notes',
  'body_type_id',
  'odometer',
  'equipment',
];

export const reconFields = [
  'date_arrived',
  'date_redeemed',
  'notes',
  'photos',
  'source_id',
];
