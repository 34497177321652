import React from 'react';
import moment from 'moment';
import gql from 'graphql-tag';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import DoneIcon from '@mui/icons-material/Done';
import Badge from '@mui/material/Badge';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { CONFIRMED_STATUSES } from 'constants.js';
import { formatPhoneNumber, defaultTZ } from 'utils';
import { identityTypeIcon, initials } from '../../utils';

const CustomerCard = ({
  customer,
  selectedCustomers,
  setSelectedCustomers,
}) => {
  const identities = customer?.identities || [];
  const vehicles = customer?.vehicles || [];
  const assigned_salespeople = customer?.assigned_salespeople || [];
  const gocards = customer?.gocards || [];
  const { app_account: goAutoAppAcct } = customer;
  const customerHasGoAutoAppAcct =
    CONFIRMED_STATUSES.includes(goAutoAppAcct?.status) &&
    goAutoAppAcct?.isEnabled;

  const isSelected = customer =>
    selectedCustomers.find(sc => sc._id === customer._id);

  const setCustomers = customer => {
    if (selectedCustomers.find(sc => sc._id === customer._id)) {
      setSelectedCustomers(prev => prev.filter(sc => sc._id !== customer._id));
    } else {
      setSelectedCustomers(prev => [...prev, customer]);
    }
  };

  return (
    <Card
      style={{
        margin: '1rem',
        backgroundColor: `${isSelected(customer) ? '#ccff90' : ''}`,
        minHeight: '600px',
      }}
    >
      <CardActionArea
        style={{ minHeight: '600px', marginTop: 0 }}
        onClick={() => setCustomers(customer)}
      >
        <CardHeader
          title={
            isSelected(customer) ? (
              <>
                <DoneIcon /> Selected Customer
              </>
            ) : (
              'Customer Details'
            )
          }
          titleTypographyProps={{
            align: 'center',
            color: 'textSecondary',
            variant: 'h6',
          }}
        />
        <CardContent>
          <Box>
            <Grid container direction="row">
              <Grid item xs={1}>
                <Badge
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  overlap="circular"
                  invisible={!customerHasGoAutoAppAcct}
                  badgeContent={
                    <CheckCircleIcon
                      fontSize="small"
                      style={{ color: 'green' }}
                    />
                  }
                >
                  <Avatar>{initials(customer)}</Avatar>
                </Badge>
              </Grid>
              <Grid item xs={11}>
                <Box>{customer?.fullname} </Box>
                <Box
                  style={{
                    color: '#808080',
                    fontSize: '12px',
                    paddingBottom: '1rem',
                  }}
                >
                  <Grid
                    container
                    direction="row"
                    style={{
                      display: 'flex',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                    }}
                  >
                    <Grid item xs={6}>
                      Profile Created {moment(customer.created).format('ll')}
                    </Grid>
                    {customerHasGoAutoAppAcct && (
                      <Grid item xs={6}>
                        {`Go Auto App Account Created: ${moment(
                          goAutoAppAcct.createdAt,
                        )
                          .tz(defaultTZ)
                          .format('MMM YYYY')}`}
                      </Grid>
                    )}
                  </Grid>
                </Box>
                <Box
                  style={{
                    display: 'flex',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                  }}
                >
                  <Grid
                    container
                    direction="row"
                    style={{
                      display: 'flex',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                    }}
                  >
                    {identities.map(identity => (
                      <Grid
                        item
                        xs={6}
                        style={{
                          fontSize: '14px',
                          display: 'flex',
                          marginTop: 'auto',
                          marginBottom: 'auto',
                        }}
                        key={identity.identity_type}
                      >
                        {identityTypeIcon(identity.identity_type)}
                        <Box style={{ paddingLeft: '0.5rem' }}>
                          {identity.identity_type === 'sms'
                            ? formatPhoneNumber(identity.identity_value)
                            : identity.identity_value}
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box paddingTop={1} paddingBottom={1}>
            <Box>
              <Typography variant="h6">Address</Typography>
            </Box>
            <Box>
              {customer.address} {customer.city},{customer.province}{' '}
              {customer.postal_code}
            </Box>
          </Box>
          <Box paddingTop={1} paddingBottom={1}>
            <Box>
              <Typography variant="h6">Go Card</Typography>
            </Box>
            <Box>
              {gocards.length === 0 && <>No GoCards for this Customer</>}
              {gocards.map(gocard => (
                <Box key={gocard.id}>
                  {gocard.cash_balance} - {gocard?.account?.card_number}
                </Box>
              ))}
            </Box>
          </Box>
          <Box paddingTop={1} paddingBottom={1}>
            <Box>
              <Typography variant="h6">Current Vehicle(s)</Typography>
            </Box>
            <Box>
              {vehicles.length === 0 && <>No Vehicles for this Customer</>}
              {vehicles.map(vehicle => (
                <Box key={vehicle._id}>
                  {vehicle.year} {vehicle.make} {vehicle.model} {vehicle.trim}
                </Box>
              ))}
            </Box>
          </Box>
          <Box paddingTop={1} paddingBottom={1}>
            <Box>
              <Typography variant="h6">Assigned Salespeople</Typography>
            </Box>
            <Box>
              {assigned_salespeople.length === 0 && (
                <>No Assigned Salespeople for this Customer</>
              )}
              {assigned_salespeople.map(sp => (
                <Box key={sp.username}>
                  {sp?.user?.display_name} - {sp?.dealer?.dealer_name}
                </Box>
              ))}
            </Box>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

CustomerCard.fragments = {
  customer: gql`
    fragment CustomerCardFragment on Customer {
      _id
      created
      fullname
      address
      postal_code
      city
      province
      gocards {
        id
        cash_balance
        account {
          card_number
        }
      }
      vehicles {
        _id
        year
        make
        model
        trim
      }
      assigned_salespeople {
        username
        user {
          display_name
        }
        dealer {
          dealer_name
        }
      }
      identities {
        identity_type
        identity_value
      }
      app_account {
        status
        isEnabled
        createdAt
      }
    }
  `,
};

export default CustomerCard;
