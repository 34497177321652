import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import GoTemplate from './GoTemplate';

const propTypes = {
  errorMessage: PropTypes.string,
  location: PropTypes.shape({
    state: PropTypes.shape({
      message: PropTypes.string,
    }),
  }).isRequired,
  showHeader: PropTypes.bool,
};

const defaultProps = {
  errorMessage: 'An error has occurred.',
  showHeader: true,
};

function ErrorPage(props) {
  const { errorMessage } = props.location.state || props;

  return (
    <GoTemplate showHeader={props.showHeader}>
      <div>{errorMessage}</div>
    </GoTemplate>
  );
}

ErrorPage.propTypes = propTypes;
ErrorPage.defaultProps = defaultProps;

export default withRouter(ErrorPage);
