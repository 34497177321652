/* External */
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import React from 'react';

/* Material UI */
import {
  Box,
  MenuItem,
  Tab,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import ErrorDisplay from 'components/MaterialUI/ErrorDisplay';
import Loading from 'components/MaterialUI/Loading';

/* Internal */
import { usePersistedState } from 'utils';

import GeneralSettings from './GeneralSettings';
import AutomaticLeadGeneration from './AutoLeadGeneration';
import CASLCompliance from './CASLCompliance';
import AutoResponders from './AutoResponders';
import Unsubscribes from './Unsubscribes';
import SMSPermissions from './SMSPermissions';
import ActionsMenu from './ActionsMenu';

const useStyles = makeStyles(theme => ({
  tabPanel: {
    margin: '20px 20px 0 20px',
    padding: 0,
    '& .MuiTabPanel-root': {
      padding: 0,
    },
  },
  phoneNumber: {
    fontWeight: 400,
    color: theme.colours.greys.greytext1,
    marginLeft: '10px',
  },
}));

const GATEWAY_QUERY = gql`
  query gatewayQuery($gatewayId: Int!) {
    sms {
      gateway(id: $gatewayId) {
        id
        name
        phone_number
        ...GeneralSettingsGatewayData
        ...AutoLeadGenerationGatewayData
        ...CASLComplianceGatewayData
        ...AutoRespondersGatewayData
        ...PermissionsGatewayData
      }
    }
  }
  ${GeneralSettings.fragments.gateway}
  ${AutomaticLeadGeneration.fragments.gateway}
  ${CASLCompliance.fragments.gateway}
  ${AutoResponders.fragments.gateway}
  ${SMSPermissions.fragments.gateway}
`;

const SMSSettings = ({ gatewayId }) => {
  const classes = useStyles();
  const mobile = useMediaQuery(theme => theme.breakpoints.down('md'));

  const { loading, data, error, refetch } = useQuery(GATEWAY_QUERY, {
    variables: {
      gatewayId,
    },
  });

  const tabMenuItems = [
    { value: '0', label: 'General Settings' },
    { value: '1', label: 'Automatic Lead Generation' },
    { value: '2', label: 'CASL Compliance' },
    { value: '3', label: 'Auto Responders' },
    { value: '4', label: 'Unsubscribes' },
  ];

  const [tabIndex, setTabIndex] = usePersistedState('SMSSettingsTabIndex', '0');

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <ErrorDisplay error={error} action="Loading Gateway Data" />;
  }

  return (
    <Box margin="20px 10px">
      {/* Page Title */}
      <Box
        display="flex"
        alignItems="flex-end"
        mx="20px"
        my="30px"
        flexWrap="wrap"
      >
        <Typography variant="h5" style={{ fontWeight: 'bold' }}>
          Settings - {data?.sms.gateway.name}
        </Typography>
        <Typography variant="body1" className={classes.phoneNumber}>
          {data?.sms.gateway.phone_number}
        </Typography>
      </Box>

      <TabContext value={tabIndex}>
        {/* Tab menus are sometimes goofy on mobile. To combat this, 
        we'll render a select on mobile devices and a traditional 
        tab menu on desktop */}
        {mobile && (
          <>
            <TextField
              id="tab-select"
              select
              variant="outlined"
              value={tabIndex}
              onChange={e => setTabIndex(e.target.value)}
              label="Menu"
              style={{
                width: '80%',
              }}
            >
              {tabMenuItems.map(({ value, label }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </TextField>
            <ActionsMenu gateway={data?.sms.gateway} version="detailed" />
          </>
        )}
        {!mobile && (
          <TabList
            indicatorColor="secondary"
            textColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
            onChange={handleChange}
            value={tabIndex}
          >
            {tabMenuItems.map(({ value, label }) => (
              <Tab key={value} label={label} value={value} />
            ))}
            <Box marginLeft="auto">
              <ActionsMenu gateway={data?.sms.gateway} version="detailed" />
            </Box>
          </TabList>
        )}
        <TabPanel value="0" className={classes.tabPanel}>
          <GeneralSettings gateway={data} />
        </TabPanel>
        <TabPanel value="1" className={classes.tabPanel}>
          <AutomaticLeadGeneration gateway={data} />
        </TabPanel>
        <TabPanel value="2" className={classes.tabPanel}>
          <CASLCompliance gateway={data} />
        </TabPanel>
        <TabPanel value="3" className={classes.tabPanel}>
          <AutoResponders gateway={data} refetch={refetch} />
        </TabPanel>
        <TabPanel value="4" className={classes.tabPanel}>
          <Unsubscribes gateway={data} />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default SMSSettings;
