import React, { useRef, useState } from 'react';
import { withStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import { cloneDeep } from 'lodash';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import InspectionCheckboxes from '../InspectionCheckboxes';

const FordCPOForm = props => {
  const { enqueueSnackbar } = useSnackbar();

  // FordCPO form fields
  const vehicle = {
    f1: 'VIN Inspection',
    f2: 'Service Recalls (OASIS) Performed',
    f3: 'Validate Service History and Warranty Records',
    f4: 'Scheduled Maintenance Performed',
    f5: 'Clean CarProof (less than $2,000 in cosmetic damage is acceptable)',
  };
  const road = {
    f6: 'Engine Starts and Idles Properly',
    f7: 'Remote Start System Operation',
    f8: 'Engine Accelerates and Cruises Properly/Smoothly',
    f9: 'Engine Noise Normal (Cold/Hot & High/Low Speeds)',
    f10: 'Auto/Manual Transmission/Transaxle Operation – Cold and Hot Shift Quality',
    f11: 'Auto/Manual Transmission/Transaxle Noise Normal – Cold and Hot',
    f12: 'Shift Interlock Operates Properly',
    f13: 'Drive Axle/Transfer Case Operation Noise Normal',
    f14: 'Clutch Operates Properly',
    f15: 'Steers Normally (Response, Centering and Free Play)',
    f16: 'Body and Suspension Squeaks and Rattles',
    f17: 'Struts/Shocks Operate Properly',
    f18: 'Brakes/ABS Operate Properly',
    f19: 'Cruise Control',
    f20: 'Gauges Operate Properly',
    f21: 'Driver Select/Memory Profile Systems',
    f22: 'No Abnormal Wind Noise',
  };
  const exterior = {
    f23: 'No Evidence of Flood, Fire, Major or Hail Damage',
    f24: 'Body Panel Inspection',
    f25: 'Bumper/Fascia Inspection',
    f26: 'Doors, Hood, Decklid/Tailgate and Roof Inspection',
    f27: 'Doors, Hood, Decklid/Tailgate Alignment',
    f28: 'Automatic/Manual Release Mechanisms, Hinges, Prop Rod/Gas Struts Operate Properly',
    f29: 'Power Liftgate, Power-Sliding Door Operation',
    f30: 'Grille, Trim and Roof Rack Inspection',
    f31: 'Deployable Running Boards',
    f32: 'Windshield, Side and Rear-Window Glass Inspection',
    f33: 'Wiper Blade Replacement',
    f34: 'Outside Mirror Inspection',
    f35: 'Front-End Exterior Lights',
    f36: 'Back-End Exterior Lights',
    f37: 'Side Exterior Lights',
    f38: 'Hazard Lights',
    f39: 'Auto On/Off Lighting',
    f40: 'Trailer Lamp Connector Operation',
  };
  const interior = {
    f41: 'Airbags',
    f42: 'Safety Belts',
    f43: 'Radio, Cassette, CD and Speakers',
    f44: 'Antenna',
    f45: 'Alarm/Theft-Deterrent System',
    f46: 'Navigation System',
    f47: 'A/C Blows Cold (record temp settings at the vents when A/C and A/C Max is selected)',
    f48: 'Heating System',
    f49: 'Defog/Defrost',
    f50: 'Clock',
    f51: 'Tilt/Telescopic Steering Wheel',
    f52: 'Steering Column Lock',
    f53: 'Steering Wheel Controls',
    f54: 'Horn',
    f55: 'Warning Chimes',
    f56: 'Instrument Panel and Warning Lights',
    f57: 'Wipers',
    f58: 'Washers',
    f59: 'Interior Courtesy, Dome and Map Lights',
    f60: 'Manual Outside Rearview Mirrors, Power Outside Rearview Mirrors and Auto-dimming Rearview Mirror',
    f61: 'BLIS® (Blind Spot Information System)',
    f62: 'Rear View Camera',
    f63: 'SYNC® System',
    f64: 'MyFord Touch®',
    f65: 'Active Park Assist',
    f66: 'Rear Entertainment System',
    f67: 'Power Outlets and Lighter',
    f68: 'Ashtrays',
    f69: 'Glove Box and Center Armrest/Console',
    f70: 'Sun Visors, Vanity Mirror and Light',
    f71: 'Adjustable Pedals (if equipped)',
    f72: 'Interior Odor-Free',
    f73: 'Carpet',
    f74: 'Floor Mats',
    f75: 'Door Trim and Door Panels',
    f76: 'Headliner',
    f77: 'Seat Upholstery',
    f78: 'Seat and Head Restraint Adjustment',
    f79: 'Folding Seats',
    f80: 'Heated Seats',
    f81: 'Integrated Child Safety Seats',
    f82: 'Sunroof/Moonroof',
    f83: 'Convertible Top',
    f84: 'Door Handles and Release Mechanisms',
    f85: 'Remote Entry System and Push-Button Start System',
    f86: 'Door Locks',
    f87: 'Child Safety Locks',
    f88: 'Window Controls',
    f89: 'Remote Decklid and Fuel Filler Door Release',
    f90: 'Carpet, Trim and Cargo Net',
    f91: 'Luggage Compartment/Cargo Area Light',
    f92: 'Vehicle Jack and Tool Kit',
    f93: 'Spare Tire Size/Type and Sidewall Inspection',
    f94: 'Spare Tire Tread Depth/Air Pressure Inspection',
    f95: 'Tire Inflator Kit',
    f96: 'Emergency Trunk-Lid Release',
  };
  const diagnostics = {
    f97: 'Perform Self-Test for all CMDTCs',
  };
  const underhood = {
    f98: 'Engine Oil/Filter Change and Chassis Lube',
    f99: 'Coolant',
    f100: 'Brake Fluid',
    f101: 'Automatic Transaxle/Transmission Fluid',
    f102: 'Transfer Case Fluid',
    f103: 'Drive Axle Fluid',
    f104: 'Power Steering Fluid',
    f105: 'Manual Transaxle/Transmission Hydraulic Clutch Fluid',
    f106: 'Washer Fluid (top-up washer fluid)',
    f107: 'Air Conditioning System Charge (visual inspection of A/C components)',
    f108: 'Fluid Leaks',
    f109: 'Hoses, Lines and Fittings',
    f110: 'Belts',
    f111: 'Wiring',
    f112: 'Oil in Air Cleaner Housing',
    f113: 'Water, Sludge or Engine Coolant in Oil',
    f114: 'Oil Pressure',
    f115: 'Complete Power Balance Test/Power Balance Readings (check if necessary)',
    f116: 'Timing Belt',
    f117: 'Engine Mounts',
    f118: 'Inspect Turbocharger Air Cooler',
    f119: 'Radiator',
    f120: 'Pressure-Test Radiator Cap and Radiator',
    f121: 'Cooling Fans, Clutches and Motors',
    f122: 'Water Pump',
    f123: 'Coolant Recovery Tank',
    f124: 'Cabin Air Filter',
    f125: 'Fuel Pump Noise Normal',
    f126: 'Perform a DTC Test and System Running Test (to check for fuel pressure faults)',
    f127: 'Fuel Filter',
    f128: 'Engine Air Filter',
    f129: 'Starter Operation',
    f130: 'Ignition System',
    f131: 'Battery',
    f132: 'Alternator Output',
    f133: 'Diesel Glow Plug System',
  };
  const hybrid = {
    f134: 'Hybrid Cooling System',
    f135: 'Switchable Powertrain Mount',
    f136: 'Hybrid Entertainment and Information Display',
    f137: '110V Power Outlet',
  };
  const underbody = {
    f138: 'Frame Damage',
    f139: 'Fuel Supply System',
    f140: 'Exhaust System Condition',
    f141: 'Emissions Control Test',
    f142: 'Automatic Transmission/Transaxle',
    f143: 'Manual Transmission/Transaxle, Differential and Transfer Case',
    f144: '4x4 Hub Operation',
    f145: 'Universal Joints, CV Joints and CV Joint Boots',
    f146: 'Transmission Mounts (not cracked, broken or oil soaked)',
    f147: 'Differential/Drive Axle',
    f148: 'Tires and Wheels Match and Are Correct Size',
    f149: 'Tire Tread Depth',
    f150: 'Normal Tire Wear',
    f151: 'Tire Pressure',
    f152: 'Tire Pressure Monitoring System',
    f153: 'Wheels',
    f154: 'Wheel Covers and Center Caps',
    f155: 'Rack-and-Pinion, Linkage and Boots',
    f156: 'Control Arms, Bushings and Ball Joints',
    f157: 'Tie-Rods and Idler Arm',
    f158: 'Sway Bars, Links and Bushings',
    f159: 'Springs',
    f160: 'Struts and Shocks',
    f161: 'Wheel Alignment (check if necessary)',
    f162: 'Power Steering Pump',
    f163: 'Calipers and Wheel Cylinders',
    f164: 'Brake Pads and Shoes',
    f165: 'Rotors and Drums',
    f166: 'Brake Lines, Hoses and Fittings',
    f167: 'Parking Brake',
    f168: 'Master Cylinder and Booster',
  };
  const convenience = {
    f169: 'Owner’s Guide',
    f170: 'Keys and Remote Controls',
    f171: 'Universal Transmitter (Garage Door Opener)',
    f172: 'Full Fuel Level',
  };

  let vehicleObj = cloneDeep(vehicle);
  let roadObj = cloneDeep(road);
  let exteriorObj = cloneDeep(exterior);
  let interiorObj = cloneDeep(interior);
  let diagnosticsObj = cloneDeep(diagnostics);
  let underhoodObj = cloneDeep(underhood);
  let hybridObj = cloneDeep(hybrid);
  let underbodyObj = cloneDeep(underbody);
  let convenienceObj = cloneDeep(convenience);

  Object.entries(vehicleObj).map(([name]) => (vehicleObj[name] = 'Passed'));
  Object.entries(roadObj).map(([name]) => (roadObj[name] = 'Passed'));
  Object.entries(exteriorObj).map(([name]) => (exteriorObj[name] = 'Passed'));
  Object.entries(interiorObj).map(([name]) => (interiorObj[name] = 'Passed'));
  Object.entries(diagnosticsObj).map(
    ([name]) => (diagnosticsObj[name] = 'Passed'),
  );
  Object.entries(underhoodObj).map(([name]) => (underhoodObj[name] = 'Passed'));
  Object.entries(hybridObj).map(([name]) => (hybridObj[name] = 'Passed'));
  Object.entries(underbodyObj).map(([name]) => (underbodyObj[name] = 'Passed'));
  Object.entries(convenienceObj).map(
    ([name]) => (convenienceObj[name] = 'Passed'),
  );

  const [vehicleValues, setVehicleValues] = useState(vehicleObj);
  const [roadValues, setRoadValues] = useState(roadObj);
  const [exteriorValues, setExteriorValues] = useState(exteriorObj);
  const [interiorValues, setInteriorValues] = useState(interiorObj);
  const [diagnosticsValues, setDiagnosticsValues] = useState(diagnosticsObj);
  const [underhoodValues, setUnderhoodValues] = useState(underhoodObj);
  const [hybridValues, setHybridValues] = useState(hybridObj);
  const [underbodyValues, setUnderbodyValues] = useState(underbodyObj);
  const [convenienceValues, setConvenienceValues] = useState(convenienceObj);

  const handleVehicle = e => {
    const { name, value } = e.target;
    setVehicleValues(prevVehicleValues => ({
      ...prevVehicleValues,
      [name]: value,
    }));
  };
  const handleRoad = e => {
    const { name, value } = e.target;
    setRoadValues(prevRoadValues => ({
      ...prevRoadValues,
      [name]: value,
    }));
  };
  const handleExterior = e => {
    const { name, value } = e.target;
    setExteriorValues(prevExteriorValues => ({
      ...prevExteriorValues,
      [name]: value,
    }));
  };
  const handleInterior = e => {
    const { name, value } = e.target;
    setInteriorValues(prevInteriorValues => ({
      ...prevInteriorValues,
      [name]: value,
    }));
  };
  const handleDiagnostics = e => {
    const { name, value } = e.target;
    setDiagnosticsValues(prevDiagnosticsValues => ({
      ...prevDiagnosticsValues,
      [name]: value,
    }));
  };
  const handleUnderhood = e => {
    const { name, value } = e.target;
    setUnderhoodValues(prevUnderhoodValues => ({
      ...prevUnderhoodValues,
      [name]: value,
    }));
  };
  const handleHybrid = e => {
    const { name, value } = e.target;
    setHybridValues(prevHybridValues => ({
      ...prevHybridValues,
      [name]: value,
    }));
  };
  const handleUnderbody = e => {
    const { name, value } = e.target;
    setUnderbodyValues(prevUnderbodyValues => ({
      ...prevUnderbodyValues,
      [name]: value,
    }));
  };
  const handleConvenience = e => {
    const { name, value } = e.target;
    setConvenienceValues(prevConvenienceValues => ({
      ...prevConvenienceValues,
      [name]: value,
    }));
  };

  const [consent, setConsent] = useState(false);
  const handleConsent = () => {
    setConsent(consent => !consent);
  };

  const [confirm, setConfirm] = useState(false);
  const handleConfirm = () => {
    setConfirm(confirm => !confirm);
  };

  const wholeForm = useRef(null);

  const handleSubmit = e => {
    e.preventDefault();

    if (!wholeForm.current['amvic_number'].value) {
      enqueueSnackbar(`Please fill out 'Tech #'.`);
      return;
    }

    if (!consent) {
      enqueueSnackbar(`Please check 'I agree' and then submit the inspection!`);
      return;
    }

    interiorValues['f43_esn'] = wholeForm.current['f43_esn'].value;

    const values = {
      ...vehicleValues,
      ...roadValues,
      ...exteriorValues,
      ...interiorValues,
      ...diagnosticsValues,
      ...underhoodValues,
      ...hybridValues,
      ...underbodyValues,
      ...convenienceValues,
    };
    const form_data = {
      ford: values,
      amvic_number: wholeForm.current['amvic_number'].value,
    };

    props.formData(form_data);
    props.formClose();
  };

  const GreenButton = withStyles({
    root: {
      color: '#fff',
      backgroundColor: '#198754',
      '&:hover': {
        backgroundColor: '#157347',
      },
    },
  })(Button);

  return (
    <form onSubmit={handleSubmit} ref={wholeForm}>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>VEHICLE HISTORY</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(vehicle).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={vehicleValues}
                      handleGroup={handleVehicle}
                      name={name}
                      white={true}
                      greenValue="Passed"
                      orangeValue="Repaired"
                      redValue="Replaced"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>ROAD TEST</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(road).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={roadValues}
                      handleGroup={handleRoad}
                      name={name}
                      white={true}
                      greenValue="Passed"
                      orangeValue="Repaired"
                      redValue="Replaced"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>VEHICLE EXTERIOR</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(exterior).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={exteriorValues}
                      handleGroup={handleExterior}
                      name={name}
                      white={true}
                      greenValue="Passed"
                      orangeValue="Repaired"
                      redValue="Replaced"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>VEHICLE INTERIOR</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(interior).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={interiorValues}
                      handleGroup={handleInterior}
                      name={name}
                      white={true}
                      greenValue="Passed"
                      orangeValue="Repaired"
                      redValue="Replaced"
                    />
                  </Grid>
                  {name === 'f43' && (
                    <Grid
                      container
                      alignItems="center"
                      style={{ marginBottom: '1rem' }}
                    >
                      <Grid
                        item
                        xs={12}
                        style={{ paddingLeft: '1rem', paddingRight: '1rem' }}
                      >
                        <TextField
                          label="SiriusXM Satellite Radio ESN (Electronic Serial Number)"
                          fullWidth
                          name={'f43_esn'}
                        />
                      </Grid>
                    </Grid>
                  )}
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>VEHICLE DIAGNOSTICS</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(diagnostics).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={diagnosticsValues}
                      handleGroup={handleDiagnostics}
                      name={name}
                      white={true}
                      greenValue="Passed"
                      orangeValue="Repaired"
                      redValue="Replaced"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>UNDERHOOD</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(underhood).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={underhoodValues}
                      handleGroup={handleUnderhood}
                      name={name}
                      white={true}
                      greenValue="Passed"
                      orangeValue="Repaired"
                      redValue="Replaced"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>HYBRID</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(hybrid).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={hybridValues}
                      handleGroup={handleHybrid}
                      name={name}
                      white={true}
                      greenValue="Passed"
                      orangeValue="Repaired"
                      redValue="Replaced"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>UNDERBODY</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(underbody).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={underbodyValues}
                      handleGroup={handleUnderbody}
                      name={name}
                      white={true}
                      greenValue="Passed"
                      orangeValue="Repaired"
                      redValue="Replaced"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <b>CONVENIENCE</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center">
            {Object.entries(convenience).map(([name, display_name]) => {
              return (
                <React.Fragment key={name}>
                  <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
                    {display_name}
                  </Grid>
                  <Grid item xs={6}>
                    <InspectionCheckboxes
                      valueGroup={convenienceValues}
                      handleGroup={handleConvenience}
                      name={name}
                      white={true}
                      greenValue="Passed"
                      orangeValue="Repaired"
                      redValue="Replaced"
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Typography style={{ marginTop: '15px' }}>
        I verify the contents of this document are true and correct.
      </Typography>
      <FormControlLabel
        control={<Checkbox />}
        label="I agree"
        onChange={handleConsent}
        value={consent}
      />
      <br />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Technician Name"
            value={props.user.display_name}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Tech #"
            value={props.user.goUserProfile.profile.sales_licence}
            fullWidth
            name={'amvic_number'}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Date"
            value={new Date().toLocaleString()}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <Button
            type="button"
            variant="contained"
            style={{ marginRight: '15px' }}
            onClick={handleConfirm}
            color="default"
          >
            Cancel
          </Button>
          <Dialog
            open={confirm}
            onClose={handleConfirm}
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Current inspection will be closed and unsaved data will be gone.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleConfirm}>Cancel</Button>
              <Button onClick={props.formClose} color="secondary">
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
          <GreenButton type="submit" variant="contained">
            Save
          </GreenButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default FordCPOForm;
