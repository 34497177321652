import React, { useState } from 'react';
import gql from 'graphql-tag';
import { ReactSortable } from 'react-sortablejs';

import {
  Badge,
  Box,
  Divider,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { Cancel as CancelIcon } from '@mui/icons-material';

import { cloudinaryConfig } from 'constants.js';
import { cloudinaryCore } from 'utils';

import { AddButton, DeleteButton, ReplaceButton } from './ButtonWrappers';

const walkAroundVideoParams = {
  width: 640,
  height: 480,
  resource_type: 'video',
  crop: 'fit',
  format: 'mp4',
};

const otherVideoParams = {
  width: 320,
  height: 240,
  resource_type: 'video',
  crop: 'fit',
  format: 'mp4',
};

export default function MediaBox({ vehicle, videoList, setVideoList }) {
  const [walkAround, setWalkAround] = useState(
    videoList.find(x => x.isWalkAround) ?? null,
  );

  const vehicleId = vehicle?.id;

  const uploadWidget = ({
    apiKey,
    folder,
    signature,
    timestamp,
    uploadPreset,
    multiple = true,
  }) => {
    const widget = window.cloudinary.createUploadWidget(
      {
        cloudName: cloudinaryConfig.cloud_name,
        apiKey,
        uploadSignature: signature,
        uploadSignatureTimestamp: timestamp,
        folder,
        uploadPreset,
        maxFileSize: 5e6, // 5MB
        clientAllowedFormats: ['mp4', 'mpv', 'avi', 'wmv'],
        maxImageWidth: 1600,
        maxImageHeight: 1200,
        multiple,
      },
      (error, result) => {
        if (!error && result && result.event === 'success') {
          const data = {
            cloudinaryPublicId: result.info.public_id,
            md5: result.info.etag,
            isWalkAround: false,
            vehicleId,
          };
          if (multiple) {
            setVideoList(prevState => [...prevState, data]);
          } else {
            const walkAroundData = { ...data, isWalkAround: true };
            setWalkAround(walkAroundData);
            setVideoList(prevState => [...prevState, walkAroundData]);
          }
        }
      },
    );
    widget.open();
  };

  const deleteVideo = video => {
    setVideoList(prev => prev.filter(x => x.md5 !== video.md5));
  };

  return (
    <Box>
      <Typography variant="h6" style={{ marginTop: '20px' }}>
        360 Walk Around
      </Typography>
      <Divider />
      {walkAround ? (
        <>
          <ReplaceButton
            onClick={() => {
              setWalkAround(null);
              setVideoList(prevState =>
                prevState.filter(x => x.isWalkAround !== true),
              );
              uploadWidget({
                ...vehicle.videoUploadParams,
                multiple: false,
              });
            }}
          />
          <DeleteButton
            onClick={() => {
              deleteVideo(walkAround);
              setWalkAround(null);
            }}
          />
        </>
      ) : (
        <AddButton
          onClick={() =>
            uploadWidget({ ...vehicle.videoUploadParams, multiple: false })
          }
        >
          Add Walk Around
        </AddButton>
      )}
      {walkAround && (
        <Grid container alignItems="center">
          <Grid item xs={6}>
            <video style={{ maxWidth: '100%', maxHeight: '100%' }} controls>
              <source
                src={cloudinaryCore.url(
                  walkAround.cloudinaryPublicId,
                  walkAroundVideoParams,
                )}
                type="video/mp4"
              />
            </video>
          </Grid>
          <Grid item xs={6} />
        </Grid>
      )}
      <Typography variant="h6" style={{ marginTop: '20px' }}>
        Videos
      </Typography>
      <Divider />
      <AddButton onClick={() => uploadWidget(vehicle.videoUploadParams)}>
        Add Videos
      </AddButton>
      {videoList.length !== 0 && (
        <DeleteButton
          onClick={() => {
            setVideoList(prevState => prevState.filter(x => x.isWalkAround));
            setWalkAround(null);
          }}
        >
          Delete All
        </DeleteButton>
      )}
      <Box style={{ margin: '10px' }}>
        <ReactSortable list={videoList} setList={setVideoList}>
          {videoList
            .filter(_video => !_video.isWalkAround)
            .map(video => (
              <span
                style={{
                  marginRight: '10px',
                }}
                key={JSON.stringify(video.id + video.md5)}
              >
                <Badge
                  overlap="rectangular"
                  badgeContent={
                    <IconButton onClick={() => deleteVideo(video)} size="large">
                      <CancelIcon style={{ fontSize: '32px' }} />
                    </IconButton>
                  }
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <video
                    controls
                    style={{
                      objectFit: 'cover',
                      height: '320px',
                      width: '240px',
                      marginBottom: '15px',
                    }}
                  >
                    <source
                      src={cloudinaryCore.url(
                        video.cloudinaryPublicId,
                        otherVideoParams,
                      )}
                      type="video/mp4"
                    />
                  </video>
                </Badge>
              </span>
            ))}
        </ReactSortable>
      </Box>
    </Box>
  );
}

MediaBox.fragments = {
  vehicle: gql`
    fragment NpvVehicleMediaBox on NpvVehicle {
      id
      videos {
        cloudinaryPublicId
        id
        isWalkAround
        md5
        vehicleId
      }
      videoUploadParams {
        apiKey
        folder
        signature
        timestamp
        uploadPreset
        url
      }
    }
  `,
};
