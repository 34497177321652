import React from 'react';

/* external */
import gql from 'graphql-tag';

/* Material UI */
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { useTheme } from '@mui/material';

/* internal */
import FormatDateTZ from 'components/MaterialUI/FormatDateTZ';
import StatusBox from './StatusBox';

const ItemCard = ({
  creditApp: { firstName, lastName, isCompleted, dateCreated, dealer },
  onClickDetails,
  ...rest
}) => (
  <Card style={{ marginBottom: useTheme().spacing(2) }} {...rest}>
    <CardActionArea onClick={onClickDetails}>
      <CardHeader
        style={{
          paddingTop: 2,
          paddingBottom: 2,
        }}
        title={`${firstName} ${lastName}`}
        subheader={<StatusBox isCompleted={isCompleted} />}
      />
      <CardContent>
        <Box
          display="flex"
          justifyContent="space-between"
          style={{ marginBottom: 16 }}
        >
          <Box>Dealership:</Box>
          <Box>{dealer?.name}</Box>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Box>Date Started:</Box>
          <FormatDateTZ date={dateCreated} />
        </Box>
      </CardContent>
    </CardActionArea>
  </Card>
);

ItemCard.fragments = {
  creditApp: gql`
    fragment ItemCardCreditApp on CreditApp {
      dateCreated
      dealer {
        name
      }
      firstName
      isCompleted
      lastName
    }
  `,
};
export default ItemCard;
