import React, { useState } from 'react';

import { NetworkStatus } from 'apollo-boost';
import LoadingBackdrop from 'components/MaterialUI/LoadingBackdrop';
import { useUserContext } from 'components/MaterialUI/UserContext';
import gql from 'graphql-tag';
import { useHistory } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import { formatPrice, numberWithCommas } from 'utils';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Drawer from '@mui/material/Drawer';
import Fab from '@mui/material/Fab';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import FilterListIcon from '@mui/icons-material/FilterList';

import { getMediumDate, withSuffix } from 'utils';
import { titleCase } from '../../utils';
import { useGroupVehiclesQueryContext } from '../contexts/GroupVehiclesQueryContext';
import FilterDropDown from './GroupInventoryFilterDropDown';
import InventorySearch from './InventorySearch';

const useStyles = makeStyles(theme => ({
  cardContent: {
    fontSize: '10px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  container: {
    minHeight: '25vh',
    padding: '0.5rem',
    position: 'relative',
  },
  filterFab: {
    bottom: '10px',
    position: 'fixed',
    right: '10px',
  },
  grey: {
    color: '#999999',
  },
  largeText: {
    fontSize: '12px',
  },
  progressNotificationText: {
    fontSize: '20px',
    padding: '2rem',
  },
  rightSide: {
    alignItems: 'flex-end',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'right',
  },
  vehicleDisplayCountText: {
    alignItems: 'flex-end',
    alignSelf: 'flex-end',
    padding: '0.5rem',
    textAlign: 'right',
    width: '100%',
  },
}));

const GroupInventoryMobileTable = () => {
  const classes = useStyles();
  const [filterDrawerState, setFilterDrawerState] = useState({
    left: false,
  });
  const {
    facets,
    fetchMore,
    loading,
    networkStatus,
    pagination,
    setFacets,
    vehicles,
  } = useGroupVehiclesQueryContext();
  const { me } = useUserContext();

  const history = useHistory();

  const handleDeleteFacet = (_model, _field, _value) => () =>
    setFacets(prev =>
      prev.filter(
        ({ model, field, value }) =>
          model !== _model || field !== _field || value !== _value,
      ),
    );

  const toggleDrawer = (anchor, open) => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setFilterDrawerState({ ...filterDrawerState, [anchor]: open });
  };

  return (
    <Box>
      <Box className={classes.container}>
        <Box marginTop={2} marginBottom={2}>
          <InventorySearch mobile queryContext={useGroupVehiclesQueryContext} />
        </Box>
        <Box marginBottom={2} marginTop={2}>
          {facets.map(({ model, field, value, options: { label } }) => (
            <span
              style={{ paddingRight: '5px' }}
              key={`${model}${field}${value}`}
            >
              <Chip
                label={label || value}
                style={{ marginTop: '5px' }}
                onDelete={handleDeleteFacet(model, field, value)}
              />
            </span>
          ))}
        </Box>
        <Box className={classes.vehicleDisplayCountText}>
          Showing {vehicles.length} of {pagination.total}
        </Box>
        {networkStatus !== NetworkStatus.setVariables && (
          <Box>
            {vehicles.map(vehicle => (
              <Box paddingBottom="0.5rem" key={vehicle.id}>
                <Card
                  onClick={() => history.push(`/inventory/group/${vehicle.id}`)}
                >
                  <CardContent className={classes.cardContent}>
                    <Box width="65%">
                      <div className={classes.largeText}>
                        {vehicle.displayName}
                      </div>
                      <div className={classes.largeText}>
                        <span className={classes.grey}>
                          {vehicle.trim_variation}
                        </span>
                      </div>
                      <div>
                        {vehicle.stock_number} /{' '}
                        {numberWithCommas(vehicle.odometer)} km
                      </div>
                      <div className={classes.grey}>
                        {vehicle.stock_type} /{' '}
                        {titleCase(vehicle.stock_status_name)}
                      </div>
                      <div>
                        Int: {vehicle.interior_colour_name || 'N/A'}{' '}
                        <span className={classes.grey}>
                          Ext: {vehicle.exterior_colour_name || 'N/A'}
                        </span>
                      </div>
                    </Box>
                    <Box className={classes.rightSide}>
                      <div className={classes.largeText}>
                        {formatPrice(vehicle.list_price)}
                      </div>
                      <div className={classes.largeText}>
                        {vehicle.dealer_name}
                      </div>
                      <div>{`${vehicle.days_in_stock} Days`}</div>
                      <div>{`Group Age: ${vehicle.group_age}`}</div>
                      {vehicle.date_user_modified ? (
                        <div style={{ width: '100%' }}>
                          Updated:{' '}
                          {getMediumDate(
                            withSuffix(vehicle.date_user_modified, 'Z'),
                            me?.goUserProfile?.settings?.timezone,
                          )}
                        </div>
                      ) : (
                        <div style={{ width: '100%' }}>Updated: N/A</div>
                      )}
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            ))}
          </Box>
        )}
        {networkStatus === NetworkStatus.setVariables && (
          <Box>
            <Box className={classes.progressNotificationText}>Sorting...</Box>
            <LoadingBackdrop open={true} />
          </Box>
        )}
        {!loading && <Waypoint onEnter={fetchMore} bottomOffset="-10%" />}
        {loading && networkStatus === NetworkStatus.fetchMore && (
          <Box>
            <Box className={classes.progressNotificationText}>
              Loading more vehicles...
            </Box>
            <LoadingBackdrop open={true} />
          </Box>
        )}
      </Box>
      <Fab
        color="primary"
        className={classes.filterFab}
        onClick={toggleDrawer('left', true)}
      >
        <FilterListIcon />
      </Fab>
      <Drawer
        anchor="left"
        open={filterDrawerState['left']}
        onClose={toggleDrawer('left', false)}
      >
        <Box display="flex">
          <IconButton
            style={{
              display: 'flex',
              marginLeft: 'auto',
              zIndex: '1000',
            }}
            onClick={toggleDrawer('left', false)}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <FilterDropDown />
      </Drawer>
    </Box>
  );
};

GroupInventoryMobileTable.fragments = {
  vehicle: gql`
    fragment GroupInventoryMobileTableVehicle on GreaseInventoryVehicle {
      id
      date_user_modified
      days_in_stock
      dealer_name
      displayName
      exterior_colour_name
      group_age
      interior_colour_name
      list_price
      odometer
      photo_count
      stock_number
      stock_status_name
      stock_type
      trim_variation
    }
  `,
};

export default GroupInventoryMobileTable;
