import React, { useState } from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import DoneIcon from '@mui/icons-material/Done';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Autocomplete from '@mui/material/Autocomplete';

import { useVehiclesQueryContext } from '../contexts/VehiclesQueryContext';
import { INVENTORY_FACET_OPTIONS } from './constants';

const useStyles = makeStyles(theme => ({
  panel: {
    width: '100%',
    paddingLeft: '25px',
    paddingRight: '25px',
  },
  title: {
    fontSize: '20px',
    paddingBottom: '10px',
    fontWeight: 'bold',
  },
  section: {
    paddingBottom: '10px',
    outlineColor: 'black',
  },
}));

const FilterDropDown = () => {
  const classes = useStyles();
  const { facetResults, facets, loading, setFacets } =
    useVehiclesQueryContext();
  const [expanded, setExpanded] = useState(true);

  const handleExpansion = () => {
    setExpanded(!expanded);
  };

  const getFacetResults = (model, field, options = {}) => {
    const results =
      facetResults.find(x => x.field === field && (!model || x.model === model))
        ?.data || [];
    results.sort(options.sort || (_ => 0));
    return results.map(({ value, ...rest }) => ({
      value: (options.format || (x => x))(value),
      ...rest,
    }));
  };

  const isSelected = (_model, _field, _value) =>
    facets.findIndex(
      ({ model, field, value }) =>
        model === _model && field === _field && value === _value,
    ) !== -1;

  const deleteFacet = (_model, _field, _value) =>
    setFacets(prev =>
      prev.filter(
        ({ model, field, value }) =>
          model !== _model || field !== _field || value !== _value,
      ),
    );

  const deleteAllFacets = () => {
    setFacets([
      {
        model: 'StockStatus',
        field: 'name',
        value: 'In Stock',
        options: {},
      },
    ]);
  };

  const setFacet = (model, field, value, options) =>
    setFacets(prev => [...prev, { model, field, value, options }]);

  const toggleFacet = (model, field, value, options) => {
    if (isSelected(model, field, value)) deleteFacet(model, field, value);
    else setFacet(model, field, value, options);
  };

  const isFacetInResults = (model, field, value) =>
    getFacetResults(model, field).findIndex(x => x.value === value) !== -1;

  const updateFacetValues = (model, field, values, options) =>
    setFacets(prev => [
      ...prev.filter(x => x.model !== model || x.field !== field),
      ...values.map(value => ({ model, field, value, options })),
    ]);

  return (
    <List className={classes.panel}>
      <div className={classes.title}>Filters</div>
      <div style={{ paddingBottom: '10px' }}>
        <Button style={{ color: 'red' }} onClick={() => deleteAllFacets()}>
          <HighlightOffIcon style={{ paddingRight: '5px' }} /> RESET ALL
        </Button>
      </div>
      {INVENTORY_FACET_OPTIONS.map(({ field, model, title, options = {} }) => (
        <div style={{ paddingBottom: '10px' }} key={`${field}${model}${title}`}>
          <Accordion key={title}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              {title}
            </AccordionSummary>
            <AccordionDetails>
              {options.comboBox ? (
                <Autocomplete
                  multiple
                  options={getFacetResults(model, field, options)
                    .filter(x => (options.filterNull ? x.value : true))
                    .map(({ value }) => value)}
                  getOptionLabel={option =>
                    `${option} (${
                      getFacetResults(model, field, options).find(
                        ({ value }) => value === option,
                      ).count
                    })`
                  }
                  onChange={(e, values) =>
                    updateFacetValues(model, field, values, options)
                  }
                  renderInput={params => (
                    <TextField
                      {...params}
                      label={options.comboBoxTitle || title}
                      variant="outlined"
                    />
                  )}
                  style={{ width: '200px' }}
                />
              ) : (
                <List
                  component="div"
                  disablePadding
                  className={classes.subPanel}
                  style={{ width: '100%' }}
                >
                  {getFacetResults(model, field, options).map(
                    ({ count, value }) => (
                      <ListItem
                        disabled={loading}
                        dense
                        button
                        onClick={() =>
                          toggleFacet(model, field, value, options)
                        }
                        key={`${model}${field}${value}`}
                        selected={isSelected(model, field, value)}
                      >
                        {isSelected(model, field, value) ? (
                          <DoneIcon
                            style={{
                              fontSize: 'small',
                              paddingRight: '10px',
                              width: '25px',
                            }}
                          />
                        ) : (
                          <div style={{ paddingRight: '10px', width: '25px' }}>
                            {' '}
                          </div>
                        )}
                        <ListItemText
                          primary={`${value || 'Unknown'} (${count})`}
                        />
                      </ListItem>
                    ),
                  )}
                </List>
              )}
            </AccordionDetails>
          </Accordion>
        </div>
      ))}
      <Accordion
        key="Other Filters"
        expanded={expanded}
        onClick={handleExpansion}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          Other Filters
        </AccordionSummary>
        <AccordionDetails>
          <List
            component="div"
            disablePadding
            className={classes.subPanel}
            style={{ width: '100%' }}
          >
            {isFacetInResults('Vehicle', 'is_decoded', 'False') && (
              <ListItem dense disabled={loading}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="secondary"
                      checked={isSelected('Vehicle', 'is_decoded', 'False')}
                      onClick={() =>
                        toggleFacet('Vehicle', 'is_decoded', 'False', {
                          label: 'Not Decoded',
                        })
                      }
                    />
                  }
                  label={`Not VIN Decoded (${
                    getFacetResults('Vehicle', 'is_decoded').find(
                      x => x.value === 'False',
                    ).count
                  })`}
                />
              </ListItem>
            )}
            {isFacetInResults('Vehicle', 'has_uvi', 'False') && (
              <ListItem dense disabled={loading}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="secondary"
                      checked={isSelected('Vehicle', 'has_uvi', 'False')}
                      onClick={() =>
                        toggleFacet('Vehicle', 'has_uvi', 'False', {
                          label: 'No UVI',
                        })
                      }
                    />
                  }
                  label={`Missing UVI (${
                    getFacetResults('Vehicle', 'has_uvi').find(
                      x => x.value === 'False',
                    ).count
                  })`}
                />
              </ListItem>
            )}
            {isFacetInResults('Vehicle', 'not_enough_photos', 'True') && (
              <ListItem dense disabled={loading}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="secondary"
                      checked={isSelected(
                        'Vehicle',
                        'not_enough_photos',
                        'True',
                      )}
                      onClick={() =>
                        toggleFacet('Vehicle', 'not_enough_photos', 'True', {
                          label: 'Missing Photos',
                        })
                      }
                    />
                  }
                  label={`Missing Photos (${
                    getFacetResults('Vehicle', 'not_enough_photos').find(
                      x => x.value === 'True',
                    ).count
                  })`}
                />
              </ListItem>
            )}
            {isFacetInResults('Vehicle', 'is_missing_description', 'True') && (
              <ListItem dense disabled={loading}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="secondary"
                      checked={isSelected(
                        'Vehicle',
                        'is_missing_description',
                        'True',
                      )}
                      onClick={() =>
                        toggleFacet(
                          'Vehicle',
                          'is_missing_description',
                          'True',
                          {
                            label: 'No Description',
                          },
                        )
                      }
                    />
                  }
                  label={`Missing Description (${
                    getFacetResults('Vehicle', 'is_missing_description').find(
                      x => x.value === 'True',
                    ).count
                  })`}
                />
              </ListItem>
            )}
            {isFacetInResults('Vehicle', 'is_missing_list_price', 'True') && (
              <ListItem dense disabled={loading}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="secondary"
                      checked={isSelected(
                        'Vehicle',
                        'is_missing_list_price',
                        'True',
                      )}
                      onClick={() =>
                        toggleFacet(
                          'Vehicle',
                          'is_missing_list_price',
                          'True',
                          {
                            label: 'No Price',
                          },
                        )
                      }
                    />
                  }
                  label={`Missing Price (${
                    getFacetResults('Vehicle', 'is_missing_list_price').find(
                      x => x.value === 'True',
                    ).count
                  })`}
                />
              </ListItem>
            )}
            {isFacetInResults('Vehicle', 'is_on_special', 'True') && (
              <ListItem dense disabled={loading}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="secondary"
                      checked={isSelected('Vehicle', 'is_on_special', 'True')}
                      onClick={() =>
                        toggleFacet('Vehicle', 'is_on_special', 'True', {
                          label: 'On Special',
                        })
                      }
                    />
                  }
                  label={`On Special (${
                    getFacetResults('Vehicle', 'is_on_special').find(
                      x => x.value === 'True',
                    ).count
                  })`}
                />
              </ListItem>
            )}
            {isFacetInResults('Vehicle', 'is_detailed_pricing', 'True') && (
              <ListItem dense disabled={loading}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="secondary"
                      checked={isSelected(
                        'Vehicle',
                        'is_detailed_pricing',
                        'True',
                      )}
                      onClick={() =>
                        toggleFacet('Vehicle', 'is_detailed_pricing', 'True', {
                          label: 'Detailed Pricing',
                        })
                      }
                    />
                  }
                  label={`Detailed Pricing (${
                    getFacetResults('Vehicle', 'is_detailed_pricing').find(
                      x => x.value === 'True',
                    ).count
                  })`}
                />
              </ListItem>
            )}
            {isFacetInResults('Vehicle', 'is_featured', 'True') && (
              <ListItem dense disabled={loading}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="secondary"
                      checked={isSelected('Vehicle', 'is_featured', 'True')}
                      onClick={() =>
                        toggleFacet('Vehicle', 'is_featured', 'True', {
                          label: 'Featured',
                        })
                      }
                    />
                  }
                  label={`Featured (${
                    getFacetResults('Vehicle', 'is_featured').find(
                      x => x.value === 'True',
                    ).count
                  })`}
                />
              </ListItem>
            )}
            {isFacetInResults('Vehicle', 'is_loaner', 'True') && (
              <ListItem dense disabled={loading}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="secondary"
                      checked={isSelected('Vehicle', 'is_loaner', 'True')}
                      onClick={() =>
                        toggleFacet('Vehicle', 'is_loaner', 'True', {
                          label: 'Loaner Vehicle',
                        })
                      }
                    />
                  }
                  label={`Loaner Vehicle (${
                    getFacetResults('Vehicle', 'is_loaner').find(
                      x => x.value === 'True',
                    ).count
                  })`}
                />
              </ListItem>
            )}
            {isFacetInResults('Vehicle', 'is_demo_unit', 'True') && (
              <ListItem dense disabled={loading}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="secondary"
                      checked={isSelected('Vehicle', 'is_demo_unit', 'True')}
                      onClick={() =>
                        toggleFacet('Vehicle', 'is_demo_unit', 'True', {
                          label: 'Demo Vehicle',
                        })
                      }
                    />
                  }
                  label={`Demo Vehicle (${
                    getFacetResults('Vehicle', 'is_demo_unit').find(
                      x => x.value === 'True',
                    ).count
                  })`}
                />
              </ListItem>
            )}
            {isFacetInResults('Vehicle', 'is_certified', 'True') && (
              <ListItem dense disabled={loading}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="secondary"
                      checked={isSelected('Vehicle', 'is_certified', 'True')}
                      onClick={() =>
                        toggleFacet('Vehicle', 'is_certified', 'True', {
                          label: 'Certified',
                        })
                      }
                    />
                  }
                  label={`Certified (${
                    getFacetResults('Vehicle', 'is_certified').find(
                      x => x.value === 'True',
                    ).count
                  })`}
                />
              </ListItem>
            )}
            {isFacetInResults('Vehicle', 'has_video', 'True') && (
              <ListItem dense disabled={loading}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="secondary"
                      checked={isSelected('Vehicle', 'has_video', 'True')}
                      onClick={() =>
                        toggleFacet('Vehicle', 'has_video', 'True', {
                          label: 'Has Video',
                        })
                      }
                    />
                  }
                  label={`Has a Video (${
                    getFacetResults('Vehicle', 'has_video').find(
                      x => x.value === 'True',
                    ).count
                  })`}
                />
              </ListItem>
            )}
            {isFacetInResults(
              'Vehicle',
              'no_price_changes_at_7_days',
              'True',
            ) && (
              <ListItem dense disabled={loading}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="secondary"
                      checked={isSelected(
                        'Vehicle',
                        'no_price_changes_at_7_days',
                        'True',
                      )}
                      onClick={() =>
                        toggleFacet(
                          'Vehicle',
                          'no_price_changes_at_7_days',
                          'True',
                          {
                            label: 'No Price Changes in 7 Days',
                          },
                        )
                      }
                    />
                  }
                  label={`No Price Changes in 7 Days (${
                    getFacetResults(
                      'Vehicle',
                      'no_price_changes_at_7_days',
                    ).find(x => x.value === 'True').count
                  })`}
                />
              </ListItem>
            )}
            {isFacetInResults(
              'Vehicle',
              'no_price_changes_at_14_days',
              'True',
            ) && (
              <ListItem dense disabled={loading}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="secondary"
                      checked={isSelected(
                        'Vehicle',
                        'no_price_changes_at_14_days',
                        'True',
                      )}
                      onClick={() =>
                        toggleFacet(
                          'Vehicle',
                          'no_price_changes_at_14_days',
                          'True',
                          {
                            label: 'No Price Changes in 14 Days',
                          },
                        )
                      }
                    />
                  }
                  label={`No Price Changes in 14 Days (${
                    getFacetResults(
                      'Vehicle',
                      'no_price_changes_at_14_days',
                    ).find(x => x.value === 'True').count
                  })`}
                />
              </ListItem>
            )}
            {isFacetInResults(
              'Vehicle',
              'no_price_changes_at_21_days',
              'True',
            ) && (
              <ListItem dense disabled={loading}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="secondary"
                      checked={isSelected(
                        'Vehicle',
                        'no_price_changes_at_21_days',
                        'True',
                      )}
                      onClick={() =>
                        toggleFacet(
                          'Vehicle',
                          'no_price_changes_at_21_days',
                          'True',
                          {
                            label: 'No Price Changes in 21 Days',
                          },
                        )
                      }
                    />
                  }
                  label={`No Price Changes in 21 Days (${
                    getFacetResults(
                      'Vehicle',
                      'no_price_changes_at_21_days',
                    ).find(x => x.value === 'True').count
                  })`}
                />
              </ListItem>
            )}
            {isFacetInResults(
              'Vehicle',
              'no_price_changes_at_28_days',
              'True',
            ) && (
              <ListItem dense disabled={loading}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="secondary"
                      checked={isSelected(
                        'Vehicle',
                        'no_price_changes_at_28_days',
                        'True',
                      )}
                      onClick={() =>
                        toggleFacet(
                          'Vehicle',
                          'no_price_changes_at_28_days',
                          'True',
                          {
                            label: 'No Price Changes in 28 Days',
                          },
                        )
                      }
                    />
                  }
                  label={`No Price Changes in 28 Days (${
                    getFacetResults(
                      'Vehicle',
                      'no_price_changes_at_28_days',
                    ).find(x => x.value === 'True').count
                  })`}
                />
              </ListItem>
            )}
            {isFacetInResults('Vehicle', 'recon_complete', 'True') && (
              <ListItem dense disabled={loading}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="secondary"
                      checked={isSelected('Vehicle', 'recon_complete', 'True')}
                      onClick={() =>
                        toggleFacet('Vehicle', 'recon_complete', 'True', {
                          label: 'Recon Complete',
                        })
                      }
                    />
                  }
                  label={`Recon Complete (${
                    getFacetResults('Vehicle', 'recon_complete').find(
                      x => x.value === 'True',
                    ).count
                  })`}
                />
              </ListItem>
            )}
            {isFacetInResults('Vehicle', 'recon_complete', 'False') && (
              <ListItem dense disabled={loading}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="secondary"
                      checked={isSelected('Vehicle', 'recon_complete', 'False')}
                      onClick={() =>
                        toggleFacet('Vehicle', 'recon_complete', 'False', {
                          label: 'Recon Incomplete',
                        })
                      }
                    />
                  }
                  label={`Recon Incomplete (${
                    getFacetResults('Vehicle', 'recon_complete').find(
                      x => x.value === 'False',
                    ).count
                  })`}
                />
              </ListItem>
            )}
          </List>
        </AccordionDetails>
      </Accordion>
    </List>
  );
};

export default FilterDropDown;
