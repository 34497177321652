import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useTheme } from '@mui/material';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { Status, LOST_REASONS } from '../../../constants';

const LOSE_OPPORTUNITY = gql`
  mutation lostOpportunity($id: ID!, $input: LostOpportunityInput!) {
    loseOpportunity(_id: $id, input: $input) {
      _id
      status
    }
  }
`;

const TubeOpportunityModal = ({
  opportunityId,
  onClose,
  isOpen,
  updateStatus,
}) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [lostReason, setLostReason] = useState('');
  const [loseOpportunity] = useMutation(LOSE_OPPORTUNITY, {
    onCompleted: () => {
      updateStatus();
      enqueueSnackbar('Opportunity Tubed!', { variant: 'success ' });
      onClose();
    },
    onError: err => {
      enqueueSnackbar('Opportunity could not be tubed.', {
        variant: 'error',
      });
    },
  });

  const handleChange = event => {
    setLostReason(event.target.value);
  };

  const handleSubmit = () => {
    if (lostReason === '') {
      enqueueSnackbar('A reason is required', {
        variant: 'error',
      });
    } else {
      loseOpportunity({
        variables: {
          id: opportunityId,
          input: {
            status: Status.TUBED,
            lost_reason: lostReason,
          },
        },
      });
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Tube Opportunity</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Select a reason why the opportunity was tubed.
        </DialogContentText>
        <FormControl style={{ width: '100%' }}>
          <InputLabel shrink>Reason</InputLabel>
          <Select value={lostReason} onChange={handleChange}>
            <MenuItem value="">Select Reason</MenuItem>
            {LOST_REASONS.map(reason => (
              <MenuItem value={reason} key={reason}>
                {reason}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="outlined">
          Cancel
        </Button>
        <Button
          style={theme.actions.confirm}
          onClick={handleSubmit}
          variant="contained"
          color="primary"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TubeOpportunityModal;
