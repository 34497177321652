// External
import moment from 'moment';
import React, { useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
// Material UI
import { useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DatePicker } from '@mui/x-date-pickers';

import CheckBoxControl from 'components/MaterialUI/CheckboxControl';
// Internal
import ConfirmDialog from 'components/MaterialUI/ConfirmDialog';
import TextFieldControl from 'components/MaterialUI/TextFieldControl';

const BookableService = ({ canUpdate }) => {
  const theme = useTheme();
  const { control } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'bookableServices',
    keyName: 'key',
  });

  const [confirmRemoveItemIndex, setConfirmRemoveItemIndex] = useState(null);

  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>
          <b>Bookable Services</b>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Button
              type="button"
              variant="contained"
              onClick={() =>
                append({
                  created_at: moment().format('YYYY-MM-DD'),
                  op_code: '',
                  dispatch_code: '',
                  name: '',
                  description: '',
                  starting_price: '',
                  special_price: '',
                  laber_type: '',
                  is_special: false,
                })
              }
              style={canUpdate ? theme.actions.confirm : theme.actions.disabled}
              disabled={!canUpdate}
            >
              Add Service
            </Button>
          </Grid>
          {fields.map((bookableService, index) => (
            <Grid item xs={12} key={bookableService.key}>
              <Grid container spacing={3} alignItems="stretch">
                <Grid item xs={10} md={11}>
                  <Grid container spacing={3}>
                    <Grid item xs={6} md={3}>
                      <Controller
                        control={control}
                        render={({
                          field: { ref, onChange, value, ...field },
                        }) => (
                          <DatePicker
                            label="Created At"
                            autoOk
                            variant="inline"
                            format="YYYY-MM-DD"
                            disabled
                            value={moment(value)}
                            {...field}
                          />
                        )}
                        name={`bookableServices.${index}.created_at`}
                      />
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <TextFieldControl
                        label="OP Code"
                        name={`bookableServices.${index}.op_code`}
                        required
                        disabled={!canUpdate}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <TextFieldControl
                        label="Dispatch Code"
                        name={`bookableServices.${index}.dispatch_code`}
                        required
                        disabled={!canUpdate}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <TextFieldControl
                        label="Name"
                        name={`bookableServices.${index}.name`}
                        required
                        disabled={!canUpdate}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextFieldControl
                        label="Description"
                        name={`bookableServices.${index}.description`}
                        disabled={!canUpdate}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <TextFieldControl
                        label="Starting Price"
                        name={`bookableServices.${index}.starting_price`}
                        disabled={!canUpdate}
                        fullWidth
                        type="number"
                      />
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <TextFieldControl
                        label="Labor Type"
                        name={`bookableServices.${index}.labor_type`}
                        required
                        disabled={!canUpdate}
                        fullWidth
                      />
                    </Grid>
                    <Grid item md={3}>
                      <CheckBoxControl
                        label="Special"
                        name={`bookableServices.${index}.is_special`}
                        disabled={!canUpdate}
                        fullWidth
                      />
                    </Grid>
                    <Grid item md={3}>
                      <TextFieldControl
                        label="Special Price"
                        name={`bookableServices.${index}.special_price`}
                        disabled={!canUpdate || !bookableService.is_special}
                        fullWidth
                        type="number"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={2} md={1} style={{ margin: 'auto' }}>
                  <Button
                    type="button"
                    color="primary"
                    variant="contained"
                    onClick={() =>
                      bookableService.id
                        ? setConfirmRemoveItemIndex(index)
                        : remove(index)
                    }
                    style={{
                      backgroundColor: 'red',
                      minWidth: '36px',
                      padding: '10px',
                    }}
                    disabled={!canUpdate}
                  >
                    <i className="fas fa-trash"></i>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
        <ConfirmDialog
          isOpen={confirmRemoveItemIndex !== null}
          onConfirm={() => {
            remove(confirmRemoveItemIndex);
          }}
          onClose={() => setConfirmRemoveItemIndex(null)}
          text="Are you sure you want to remove that bookable service?"
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default BookableService;
