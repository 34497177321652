import React, { useEffect, useState } from 'react';

import { assignInWith, isEmpty } from 'lodash';
import { useForm, useWatch } from 'react-hook-form';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import CBBExpansionPanel from './CBBExpansionPanel';
import CBBTable from './CBBTable';

import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Hidden from '@mui/material/Hidden';
import { NotEnoughDataAlert } from '../alerts/CBBWizardAlerts';
import WizardImageCarousel from '../misc/WizardImageCarousel';

import { CBB_CONDITIONS } from '../../const';
import { titleCase, stripHTML } from '../../utils';

import { formatPrice, numberWithCommas } from 'utils';

const VALUATION_TRIM_QUERY = gql`
  query ValuationTrimQuery(
    $make_name: String!
    $model_name: String!
    $year: Int!
  ) {
    valuation {
      getTrims(make: $make_name, model: $model_name, year: $year)
    }
  }
`;

const VALUATION_STYLE_QUERY = gql`
  query ValuationStyleQuery(
    $make_name: String!
    $model_name: String!
    $trim: String!
    $year: Int!
  ) {
    valuation {
      getStyles(make: $make_name, model: $model_name, trim: $trim, year: $year)
    }
  }
`;

// needed to get the vid and available options
// can query with either vin and year or make, model, trim, style, year
const VALUATION_VEHICLES_QUERY = gql`
  query ValuationVehiclesQuery(
    $vin: String
    $year: Int
    $make_name: String
    $model_name: String
    $trim: String
    $style: String
  ) {
    valuation {
      getVehicles(
        vin: $vin
        year: $year
        make: $make_name
        model: $model_name
        trim: $trim
        style: $style
      ) {
        options {
          option_code
          description
        }
        make_name
        model_name
        style_name
        trim_name
        vid
        year
      }
    }
  }
`;

const VALUATION_VALUE_QUERY = gql`
  query ValuationValueQuery($vid: Int!, $odometer: Int) {
    valuation {
      getValue(vid: $vid, kilometers: $odometer) {
        condition
        options {
          average
          clean
          description
          extra_clean
          option_code
          rough
        }
        wholesale_prices {
          average
          clean
          extra_clean
          rough
        }
        retail_prices {
          average
          clean
          extra_clean
          rough
        }
      }
    }
  }
`;

const shouldDirty = true; // default setting dirty state to true when setValue

const CBBWizardCard = ({
  vehicle: {
    id,
    displayName,
    displayPhoto,
    year,
    make_name,
    model_name,
    odometer,
    stock_type,
    stock_number,
    published_notes,
    published_trim,
    days_in_stock,
    cost,
    trim_variation,
    cbb,
    vin,
    photos,
  },
  onSubmit,
  mobile,
}) => {
  const trimQuery = useQuery(VALUATION_TRIM_QUERY, {
    variables: {
      year,
      make_name,
      model_name,
    },
    skip: !(year && make_name && model_name),
  });
  const [getStyles, styleQuery] = useLazyQuery(VALUATION_STYLE_QUERY);
  const [getValuationVehicles, valuationVehiclesQuery] = useLazyQuery(
    VALUATION_VEHICLES_QUERY,
  );
  const [getVehicleValue, vehicleValueQuery] = useLazyQuery(
    VALUATION_VALUE_QUERY,
    {
      onCompleted: data => {
        if (data?.valuation?.getValue?.condition)
          setValue('condition', data?.valuation?.getValue?.condition, {
            shouldDirty,
          });
      },
    },
  );

  const [trimNames, setTrimNames] = useState([]);
  const [styleNames, setStyleNames] = useState([]);
  const [options, setOptions] = useState([]);
  const [vehicleValues, setVehicleValues] = useState({});

  const defaultValues = {
    average_price: cbb?.average_price || null,
    clean_price: cbb?.clean_price || null,
    condition: cbb?.condition || null,
    equipment: cbb?.equipment || null,
    extra_clean_price: cbb?.extra_clean_price || null,
    rough_price: cbb?.rough_price || null,
    selected_price: cbb?.selected_price || null,
    style: cbb?.style || null,
    trim: cbb?.trim || null,
    vid: cbb?.vid || null,
  };

  const { control, formState, handleSubmit, register, reset, setValue } =
    useForm({
      defaultValues,
      shouldUnregister: true,
    });
  // TODO: revisit this in later versions of react-hook-form (shouldn't need
  // to check dirtyFields).  Current version 6.8.1
  // Update 2023-01-25: We haven't gotten to this yet, but when we do, there's a new
  // CBB component that you can just wrap in a Controller. Huzzah!
  const dirty =
    formState.dirty || Object.keys(formState.dirtyFields).length > 0;
  register('vid');
  register('selected_price');
  CBB_CONDITIONS.forEach(condition => register(`${condition}_price`));

  const selectedTrim = useWatch({ control, name: 'trim' });
  const selectedStyle = useWatch({ control, name: 'style' });
  const selectedEquipment = useWatch({
    control,
    name: 'equipment',
    defaultValue: '[]',
  });
  const selectedCondition = useWatch({ control, name: 'condition' });
  const selectedVid = useWatch({ control, name: 'vid' });

  useEffect(() => {
    // reset form on new vehicle
    reset();
  }, [id, reset]);

  useEffect(() => {
    if (trimQuery.data) {
      setTrimNames(trimQuery.data.valuation.getTrims);
      setStyleNames([]);
      setOptions([]);
      setVehicleValues({});
    }
  }, [trimQuery]);

  useEffect(() => {
    if (styleQuery.data) {
      setStyleNames(styleQuery.data.valuation.getStyles);
      setOptions([]);
    }
  }, [styleQuery]);

  useEffect(() => {
    if (valuationVehiclesQuery.data) {
      setOptions(
        valuationVehiclesQuery.data.valuation.getVehicles[0]?.options.map(
          ({ option_code, description }) => ({ option_code, description }),
        ) || [],
      );
    }
  }, [valuationVehiclesQuery]);

  useEffect(() => {
    if (vehicleValueQuery.data) {
      const _vehicleValues = vehicleValueQuery.data.valuation.getValue;

      setVehicleValues(_vehicleValues);
      setValue('condition', _vehicleValues.condition);
      setOptions(_vehicleValues.options);
    }
  }, [setValue, vehicleValueQuery]);

  const wholesalePrices = vehicleValues?.wholesale_prices || {};
  const retailPrices = vehicleValues?.retail_prices || {};
  const priceAdjustments = key =>
    selectedEquipment !== null
      ? options
          .filter(x => JSON.parse(selectedEquipment).includes(x.option_code))
          .reduce((total, option) => total + option[key], 0)
      : 0;
  const wholesaleTotals = assignInWith(
    {},
    wholesalePrices,
    (_, val, key) => val + priceAdjustments(key),
  );
  const retailTotals = assignInWith(
    {},
    retailPrices,
    (_, val, key) => val + priceAdjustments(key),
  );
  // Sometimes there is no valuation info for a style id.  Them's the breaks.
  const noValuation =
    selectedStyle &&
    valuationVehiclesQuery.data &&
    !vehicleValueQuery.loading &&
    isEmpty(vehicleValues);

  useEffect(() => {
    // When select trim, get styles
    // Some models have no (empty) trims
    if (trimNames && (selectedTrim || selectedTrim === ''))
      getStyles({
        variables: { year, make_name, model_name, trim: selectedTrim },
      });
    else {
      setStyleNames([]);
      setOptions([]);
    }
  }, [getStyles, make_name, model_name, selectedTrim, trimNames, year]);

  useEffect(() => {
    // When select trim and style, get valuation
    // Some models have no (empty) trims
    if (selectedStyle && (selectedTrim || selectedTrim === '')) {
      getValuationVehicles({
        variables: {
          year,
          make_name,
          model_name,
          trim: selectedTrim,
          style: selectedStyle,
        },
      });
      setValue('equipment', '[]');
    } else {
      // try again with just vin and year
      getValuationVehicles({
        variables: {
          year,
          vin,
        },
      });
    }
  }, [
    getValuationVehicles,
    make_name,
    model_name,
    selectedStyle,
    selectedTrim,
    setValue,
    vin,
    year,
  ]);

  useEffect(() => {
    // Got a vid as a result of valuations?  Set it!
    const { vid } = valuationVehiclesQuery.data?.valuation.getVehicles[0] || {};
    setValue('vid', vid, { shouldDirty });
  }, [setValue, valuationVehiclesQuery]);

  useEffect(() => {
    // Got a vid?  Get price info!
    if (selectedVid)
      getVehicleValue({
        variables: {
          vid: selectedVid,
          odometer: odometer ? parseInt(odometer, 10) : null,
        },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVid]);

  useEffect(() => {
    // Set the selected price and <condition>_price when get new values or
    // select a condition
    setValue('selected_price', wholesaleTotals[selectedCondition], {
      shouldDirty,
    });
    CBB_CONDITIONS.forEach(condition =>
      setValue(`${condition}_price`, wholesaleTotals[condition], {
        shouldDirty,
      }),
    );
  }, [selectedCondition, setValue, vehicleValueQuery, wholesaleTotals]);

  return (
    <Container style={{ paddingLeft: 0, paddingRight: 0 }} maxWidth="xl">
      <Grid
        container
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingBottom: '1rem',
        }}
      >
        <Grid item style={{ width: `${mobile ? '50%' : '100%'}` }}>
          <Grid container>
            <Grid item style={{ maxWidth: `${mobile ? '100%' : ''}` }}>
              <div style={{ display: 'flex', marginBottom: 0 }}>
                <span>
                  <span
                    style={{
                      fontWeight: 'bold',
                      fontSize: '20px',
                      paddingRight: '5px',
                    }}
                  >{` ${displayName} `}</span>
                  {` ${trim_variation || ''} `}
                </span>
                <span style={{ paddingLeft: '5px' }}>
                  <Chip size="small" label={titleCase(stock_type)}></Chip>
                </span>
              </div>
              <div style={{ paddingBottom: '5px' }}>
                {stock_number} / {vin ?? <strong>No VIN</strong>} /{' '}
                {numberWithCommas(odometer)} km
              </div>
              <div style={{ paddingBottom: '5px' }}>
                <b>Published Trim:</b> {published_trim || 'N/A'}
              </div>
              <div style={{ paddingBottom: '5px' }}>
                <span style={{ paddingRight: '30px', paddingBottom: '5px' }}>
                  <b>Days in Stock:</b> {days_in_stock}
                </span>{' '}
                <b>Cost:</b> {formatPrice(cost)}
              </div>
              <div
                style={{
                  paddingBottom: '5px',
                  marginRight: 0,
                  width: '100%',
                  overflow: 'hidden',
                }}
              >
                {published_notes?.length > 400
                  ? stripHTML(published_notes).slice(0, 400) + ' ...'
                  : stripHTML(published_notes)}
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <WizardImageCarousel
            photos={photos}
            width={'300px'}
            mobile={mobile}
          />
        </Grid>
      </Grid>
      {isEmpty(trimNames) ? (
        <div style={{ paddingBottom: '1rem' }}>
          <NotEnoughDataAlert
            year={year}
            make_name={make_name}
            model_name={model_name}
          />
        </div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Hidden smDown>
            <CBBTable
              selectedCondition={selectedCondition}
              priceAdjustments={priceAdjustments}
              wholesalePrices={wholesalePrices}
              wholesaleTotals={wholesaleTotals}
              retailPrices={retailPrices}
              retailTotals={retailTotals}
              control={control}
              styleNames={styleNames}
              options={options}
              trimNames={trimNames}
              noValuation={noValuation}
            />
          </Hidden>
          <Hidden smUp>
            <CBBExpansionPanel
              selectedCondition={selectedCondition}
              priceAdjustments={priceAdjustments}
              wholesalePrices={wholesalePrices}
              wholesaleTotals={wholesaleTotals}
              retailPrices={retailPrices}
              retailTotals={retailTotals}
              control={control}
              styleNames={styleNames}
              options={options}
              trimNames={trimNames}
              noValuation={noValuation}
            />
          </Hidden>
          <Button
            disabled={!dirty || !selectedVid}
            variant="contained"
            style={{
              display: 'block',
              marginLeft: 'auto',
              marginTop: '20px',
              marginBottom: '20px',
              backgroundColor: `${dirty && selectedVid ? '#5bc236' : 'grey'}`,
              width: '90px',
            }}
            type="submit"
          >
            Save
          </Button>
        </form>
      )}
    </Container>
  );
};
CBBWizardCard.fragments = {
  vehicle: gql`
    fragment CBBWizardCardVehicle on GreaseInventoryVehicle {
      id
      displayName
      displayPhoto {
        id
        cloudinary_public_id
      }
      year
      make_name
      model_name
      odometer
      stock_type
      stock_number
      published_notes
      published_trim
      days_in_stock
      cost
      trim_variation
      photos {
        id
        cloudinary_public_id
      }
      vin
      cbb {
        average_price
        clean_price
        condition
        equipment
        extra_clean_price
        rough_price
        selected_price
        style
        trim
        vid
      }
    }
  `,
};
export default CBBWizardCard;
