import React from 'react';

/* external */
import { Controller, useFormContext } from 'react-hook-form';

/* Material UI */
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from '@mui/material';

/* For use with react-hook-form forms.  Will use control and errors from formContext
if available and not otherwise provided.

NOTE: MUST BE USED WITH FORMS USING RHF VERSION 7 AND UP!!!!!
 */
const CheckboxControl = ({
  control,
  disabled,
  errors,
  helperText,
  label = '',
  name,
  CheckboxProps = {},
  defaultValue = undefined,
  ...rest
}) => {
  const formContext = useFormContext();
  const { formState } = formContext ?? {};
  const _errors = errors ?? formState?.errors;

  return (
    <Controller
      control={control ?? formContext.control}
      defaultValue={defaultValue}
      name={name}
      render={({ field: { ref, ...field } }) => (
        <FormControl>
          <FormControlLabel
            control={<Checkbox {...CheckboxProps} color="secondary" />}
            name={field.name} // necessary?
            checked={Boolean(field.value)}
            onChange={e => (disabled ? null : field.onChange(e.target.checked))}
            label={label}
            {...rest}
          />
          <FormHelperText>
            {_errors?.[name]?.message ?? helperText}
          </FormHelperText>
        </FormControl>
      )}
    />
  );
};

export default CheckboxControl;
